import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "view", "asset", "liability", "revenues", "taxes", "expenses", "result", "9999",
                    "balanceTitle", "balanceCards", "resultTitle", "resultCards"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.controllerName = `operations--products--clinics--managements--balances--closing`
    this.canSetClosing = true
    // this.doBalancesDashboard()
  }

  doClosingGrid() {
    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 px-2">
                    <div class="row my-2 w-100">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center pointer" data-${this.controllerName}-target="balanceTitle">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-${this.controllerName}-target="balanceCards">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="row my-2 w-100">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center pointer" data-${this.controllerName}-target="resultTitle">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-${this.controllerName}-target="resultCards">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--balances--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.current_date) {
        controller.doCalculationCards()
      }
    })
  }

  doCalculationCards() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span data-action="click->${this.controllerName}#refreshBalances">Balanço Patrimonial - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.balanceTitleTarget.innerHTML = title
    }

    var balanceHtml = ``
    balanceHtml += `<div class="col-6 my-2 px-2">
                <div class="card">
                  <div class="card-header p-1 text-center f-070">
                    <span>Ativo | <strong data-${this.controllerName}-target="asset">${this.loader}</strong></span>
                  </div>
                  <div class="card-body text-center f-070 px-1 py-2">
                    
                  </div>
                </div>
              </div>
              <div class="col-6 my-2 px-2">
                <div class="card">
                  <div class="card-header p-1 text-center f-070">
                    <span>Passivo | <strong data-${this.controllerName}-target="liability">${this.loader}</strong></span>
                  </div>
                  <div class="card-body text-center f-070 px-1 py-2">
                    
                  </div>
                </div>
              </div>
              <div class="col-12 mb-5">`

    this.balanceCardsTarget.innerHTML = balanceHtml

    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span data-action="click->${this.controllerName}#refreshBalances">Resultados - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.resultTitleTarget.innerHTML = title
    }


    var resultHtml = ``
    resultHtml += `<div class="col-6 offset-3 my-2 px-2">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Receitas | <strong data-${this.controllerName}-target="revenues">${this.loader}</strong></span>
                        </div>
                        <div class="card-body text-center f-070 px-1 py-2"></div>
                      </div>
                    </div>
                    <div class="col-6 offset-3 my-2 px-2">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Despesas | <strong data-${this.controllerName}-target="expenses">${this.loader}</strong></span>
                        </div>
                        <div class="card-body text-center f-070 px-1 py-2"></div>
                      </div>
                    </div>
                    <div class="col-6 offset-3 my-2 px-2">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Impostos | <strong data-${this.controllerName}-target="taxes">${this.loader}</strong></span>
                        </div>
                        <div class="card-body text-center f-070 px-1 py-2"></div>
                      </div>
                    </div>
                    <div class="col-6 offset-3 my-2 px-2">
                      <hr>
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Resultado | <strong data-${this.controllerName}-target="result">${this.loader}</strong></span>
                        </div>
                        <div class="card-body text-center f-070 px-1 py-2"></div>
                      </div>
                    </div>`
    this.resultCardsTarget.innerHTML = resultHtml

    var controller = this
    setTimeout(() => {
      if (controller.canSetClosing) {
        controller.setClosingAccounts()
      }
    }, 200)
  }

  setClosingAccounts() {

    var current_balances = []
    var closing = { asset: 0, liability: 0, revenues: 0, taxes: 0, expenses: 0, result: 0 }

    this.application.balances.forEach(balance => {
      if (this.application.current_date.id == balance.date_id) {
        current_balances = balance.data
      }
    })

    current_balances.forEach(element => {
      if (element.chart_master == `asset`) {
        closing.asset += Number(element.balance)
      } else if (element.chart_master == `liability`) {
        closing.liability += Number(element.balance)
      } else if (element.chart_master == `revenues`) {
        closing.revenues += Number(element.balance)
      } else if (element.chart_master == `taxes`) {
        closing.taxes += Number(element.balance)
      } else if (element.chart_master == `expenses`) {
        closing.expenses += Number(element.balance)
      } else if (element.chart_master == `result`) {
        closing.result += Number(element.balance)
      }
    });

    this.assetTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.asset)
    this.liabilityTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.liability)
    this.revenuesTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.revenues)
    this.taxesTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.taxes)
    this.expensesTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.expenses)
    this.resultTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(closing.result)
  }

  refreshBalances() {
    this.getControllerByIdentifier(`operations--products--clinics--managements--balances--dashboard`).refreshBalances()
  }

  cardGridPreloader() {
    var html = `<div class="col-12 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-12 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
