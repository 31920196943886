import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "8888", "9999"]

  connect() {
    this.controllerName = `operations--ongoings--customers--index--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 8
    this.sort = { mode: `asc`, field: `account_name` }
    this.hasShow = false
    this.filter = ``
    this.hasFilter = false
    this.dataLoaded = false
  }

  showClient(ev) {
    var controllerShow = this.getControllerByIdentifier(`operations--ongoings--customers--index--show`)
    this.checked_id = ev.target.closest(".itemRow").dataset.id

    this.application.clients.forEach(element => {
      if (element.id == this.checked_id) {
        controllerShow.current_client = element
      }
    })

    controllerShow.account_id = controllerShow.current_client.account_id
    controllerShow.doClientHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Clientes</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-2 d-flex align-items-center">
                      <span class="mc-tooltip ml-auto mr-2 pointer" data-action="click->${this.controllerName}#refeshClients">
                        <span class="material-icons md-200 md-dark">refresh</span>
                        <span class="mc-tooltiptext">Atualizar as Clientes</span>
                      </span>
                    </div>
                  </div>
                  <div class="card-body py-0 card-overflow">
                    <div class="row">
                      <div class="col p-0">
                        <div class="mc-table-responsive">
                          <table class="table table-sm mc-table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Planner</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">IRPF</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Livro-Caixa</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">PJ Médica</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">DP</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Gestão de Recebimentos</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Faturamento</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--ongoings--customers--index--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.innerHTML = controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 4)
      if (controller.getControllerByIdentifier("operations--ongoings--customers--index--dashboard").dataLoaded) {
        controller.doDataTable()
      }
    })
  }

  doDataTable() {
    var current_clients = []
    current_clients = this.application.clients

    if (this.sort.mode == `asc`) {
      var clients = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_clients, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var clients = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_clients, this.sort.field)
    }

    if (clients.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(clients.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (clients[x] !== undefined) {
            internPage[internPage.length] = clients[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: clients.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Clientes! :(</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.clientTablePartial(element, data.length))
      });
    }
  }

  clientTablePartial(element, length) {


    if (element.has_tax_return) {
      var returnIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Planner</span>
                        </span>`
    } else {
      var returnIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_booking) {
      var bookingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Livro-Caixa</span>
                        </span>`
    } else {
      var bookingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_clinic) {
      var legalIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente PJ Médica</span>
                        </span>`
    } else {
      var legalIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_billing) {
      var billingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Faturamento</span>
                        </span>`
    } else {
      var billingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_receivement) {
      var receivementIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Gestão de Recebimentos</span>
                        </span>`
    } else {
      var receivementIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_tax_filing) {
      var filingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente IRPF</span>
                        </span>`
    } else {
      var filingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_banking) {
      var bankingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Conta Bancária</span>
                        </span>`
    } else {
      var bankingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_payroll) {
      var payrollIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente Folha de Pagamento</span>
                        </span>`
    } else {
      var payrollIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }


    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }


    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showClient">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${returnIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${filingIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${bookingIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${legalIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${payrollIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${receivementIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${billingIcon}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  refeshClients() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 4)
    this.filter = ``
    this.hasFilter = false

    const data = { account: { account: true }, current_user: { current_user_id: this.application.current_user.id, feature: `operations_ongoing_accounts` } }
    const url = "/operations/accounts/products/list_with_accounts"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.clients = response.data.cln
        controller.doDataTable()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
