import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--cards--bills--index`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.hasShow = false
    this.closeBill = false
    this.payBill = false
  }

  showBill(ev) {
    var controllerShow = this.getControllerByIdentifier("financials--books--cards--bills--show")

    if (this.checked_id == ev.target.closest(".itemRow").dataset.id) {
      this.hasShow = false
    } else {
      this.hasShow = true
      this.checked_id = ev.target.closest(".itemRow").dataset.id
    }

    if (this.hasShow) {
      this.checkboxShowTargets.forEach(element => {
        if (checkBoxElement != element) {
          element.checked = false
        }
      })
      this.application.opportunities.forEach(element => {
        if (element.id == this.checked_id) {
          controllerShow.current_opportunity = element
        }
      })
      controllerShow.doBillHtml()
    } else {
      if (controllerShow.hasPreviewCardTarget) {
        controllerShow.doViewHtml()
      } else {
        controllerShow.doBillHtml()
      }
    }
  }

  addBill() {
    this.getControllerByIdentifier("financials--books--cards--bills--save").current_bill = {}
    this.getControllerByIdentifier("financials--books--cards--bills--save").actionMode = "new"
    this.getControllerByIdentifier("financials--books--cards--bills--save").doFormHtml()
  }

  editBill(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.bills.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--cards--bills--save").current_bill = element
        this.getControllerByIdentifier("financials--books--cards--bills--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--cards--bills--save").doFormHtml()
      }
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos as Faturas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addBillBtn" data-action="click->${this.controllerName}#addBill" type="button">
                        <span class="material-icons md-dark">add</span>
                      </button>
                      <span class="mc-tooltiptext">Novo Canal</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle">
                                Fatura
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Fechamento
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Pagamento
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Valor</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Pagamento</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--cards--bills--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(7, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_bills = this.application.bills

    if (this.sort.mode == `asc`) {
      var bills = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_bills, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var bills = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_bills, this.sort.field)
    }

    if (bills.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(bills.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (bills[x] !== undefined) {
            internPage[internPage.length] = bills[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: bills.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Faturas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.billTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  billTablePartial(element, length) {

    // if (this.application.permissions.financial_card_bills.can_select) {
    //   var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
    //                 <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showBill">
    //                 <label class="custom-control-label pointer" for="check-${element.id}"></label>
    //               </div>`
    // } else {
    //   var check = ''
    //     `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${check}</td>`
    // }

    if (this.application.permissions.financial_card_bills.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editBill" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    // if (element.invoice == `open`) {
    //   var invoice = `<span class="mc-tooltip badge badge-secondary-dark">
    //                   <span class="material-icons md-sm default">credit_card</span>
    //                   <span class="mc-tooltiptext">Aberta</span>
    //                 </span>`
    // } else {
    //   var invoice = `<span class="mc-tooltip badge badge-secondary-danger">
    //                   <span class="material-icons md-sm default">credit_card_off</span>
    //                   <span class="mc-tooltiptext">Fechada</span>
    //                 </span>`
    // }

    var today = new Date()
      
    if ((element.invoice == `open`) && ((element.closing_date_to_time * 1000) < Date.parse(today))) {
      var invoice = `<span class="f-070 badge badge-secondary-dark pointer" data-action="click->${this.controllerName}#closingBill">Aberta</span>`
    } else if (element.invoice == `open`) {
      var invoice = `<span class="f-070 badge badge-secondary-dark default">Aberta</span>`
    } else {
      var invoice = `<span class="f-070 badge badge-secondary-danger default">Fechada</span>`
    }

    if (element.paid) {
      var paid = `<span class="f-070 badge badge-secondary-dark">Pago</span>`
    } else if (element.invoice == `closed`) {
      var paid = `<span class="f-070 badge badge-secondary-danger pointer" data-action="click->${this.controllerName}#payingBill">Não Pago</span>`
    } else {
      var paid = `<span class="f-070 badge badge-secondary-danger default">Não Pago</span>`
    }

    // if (element.paid) {
    //   var paid = `<span class="mc-tooltip badge badge-success">
    //                   <span class="material-icons md-sm md-white default">price_check</span>
    //                   <span class="mc-tooltiptext">Pago</span>
    //                 </span>`
    // } else {
    //   var paid = `<span class="mc-tooltip badge badge-danger">
    //                   <span class="material-icons md-sm md-white default">money_off_csred</span>
    //                   <span class="mc-tooltiptext">Não Pago</span>
    //                 </span>`
    // }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.closing_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.due_date}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.balance * 100))}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${invoice}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${paid}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  closingBill(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    var bill = {}
    if (!this.closeBill) {

      this.current_bills.forEach(element => {
        if (element.id == id) {
          bill = element
        }
      })

      var r = confirm(`Você confirma o fechamento da fatura ${bill.name}?`)
      if (r) {
        this.closeBill = true
        this.actionMode = `close_bill`
        this.send_data = { bill: {}, current_user: {} }

        this.send_data.bill.id = bill.id
        this.send_data.bill.invoice = "closed"

        this.send_data.current_user.current_user_id = this.application.current_user.id

        this.requestSave()
      }
    }
  }

  payingBill(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    var bill = {}
    if (!this.closeBill) {

      this.current_bills.forEach(element => {
        if (element.id == id) {
          bill = element
        }
      })

      var r = confirm(`Você confirma o pagamento da fatura ${bill.name}?`)
      if (r) {
        this.closeBill = true
        this.actionMode = `pay_bill`
        this.send_data = { bill: {}, current_user: {} }

        this.send_data.bill.id = bill.id
        this.send_data.bill.paid = true

        this.send_data.current_user.current_user_id = this.application.current_user.id

        console.log(this.send_data)
        // this.requestSave()
      }
    }
  }

  requestSave() {
    if (this.actionMode == `close_bill`) {
      var url = "/financials/books/cards/bills/close_bill"
      var method = "PUT"
    } else if (this.actionMode == `pay_bill`) {
      var url = "/financials/books/cards/bills/update_paid"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var bill = response.data.cln
          controller.application.bills.forEach((element, i) => {
            if (element.id == bill.id) {
              controller.application.bills.splice(controller.application.bills.indexOf(element), 1, bill)
            }
          })
          controller.doDataTable()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        controller.closeBill = false
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
