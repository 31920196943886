
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["listCol", "showCol", "bodyTable", "bodyTablePreloader", "viewTitle", "membersList", "addMemberSelector",
    "viewSquadBody", "memberSquadInput", "memberSquadDropdown", "membersChips", "viewSquadCard", "checkboxShow"]

  connect() {
    this.controllerName = `users--squads--index`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    this.getCurrentUserPermissions()
    var controller = this
    setTimeout(function () {
      controller.fetchAllUsers()
    }, 2000)
  }

  newSquadModal() {
    document.querySelector(`#newSquadModal`).usersSquadsModal.modalHtml()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;">
                  <div class="card-header d-flex align-items-center" style="padding-left:16px;padding-right:0px;padding-top:0px;padding-bottom:0px;display:absolute;top:0px;left:0px;">
                    <div class="custom-control custom-checkbox pl-2"><input type="checkbox" class="custom-control-input handling-all-rows-selector" id="handlingAllRowsSelector">
                      <label class="custom-control-label pointer" for="handlingAllRowsSelector" id="handlingAllRowsSelectorLabelCheck"></label>
                    </div>
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">Todas as Squads</h6>
                    <input class="form-control s-title-0p7rem w-50 ml-auto input-search-table" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      <div class="dropdown" id="cardFilterEventDropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
                        <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
                      </div>
                      <div class="dropdown" id="eventsActionDropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="actionEventDrop" type="button"><i class="material-icons">more_vert</i></button>
                        <div aria-labelledby="actionEventDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span class="dropdown-item pointer" id="newSquadModal" data-action="click->${this.controllerName}#newSquadModal" data-controller="users--squads--modal">Nova Squad</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                        <table class="table table-sm table-hover table-search" style="font-size:80%;" id="searchEventTable">
                          <thead>
                            <tr>
                              <th class="table-3"></th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">Nome</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle text-center">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center">Líder</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center"># Membros</th>
                              <th class="table-3"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable" data-reload="false">
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listColTarget.innerHTML = html)
    }).then(() => {
      controller.getSquads()
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.doDashboardTablePreloader())
    })
  }

  doViewListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewSquadCard">
                  <div class="card-header d-flex align-items-center" style="padding-left:16px;padding-right:0px;padding-top:0px;padding-bottom:0px;display:absolute;top:0px;left:0px;">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Squad Selecionada</h6>
                  </div>
                </div>`

    this.showColTarget.innerHTML = html
  }

  addMember() {
    this.addMemberSelectorTarget.classList.remove("d-none")
  }

  showSquad(ev) {
    this.checkboxShowTargets.forEach(target => {
      if (ev.target.id != target.id) {
        target.checked = false
      }  
    });
    
    if (ev.target.checked) {
      if (this.hasViewSquadBodyTarget) {
        this.viewSquadBodyTarget.remove()
        this.viewTitleTarget.innerText = ""
      }
      var id = ev.target.closest(".itemRow").dataset.id
      var squad = ``
      allSquadList.forEach(element => {
        if (element.id == id) {
          squad = element
        }
      });
      var controller = this
      this.viewTitleTarget.innerText = squad.name

      new Promise(function (resolve) {
        resolve(controller.viewSquadCardTarget.insertAdjacentHTML("beforeend", controller.viewSquadHtml(squad)))
      }).then(() => {
        controller.fetchMemberList()
        tooltip()
      })
    } else {
      this.viewTitleTarget.innerText = "Nenhuma Squad Selecionada"
      this.viewSquadBodyTarget.remove()
    }
  }

  viewSquadHtml(element) {
    var memberHtml = ``
    element.members.forEach(member => {
      memberHtml += this.memberChipPartial(member)
    });
    var html = `<div class="card-body pt-0" data-${this.controllerName}-target="viewSquadBody" data-id="${element.id}">
                  <div class="row d-flex align-items-center" style="height:4rem">
                    <div class="col-6 d-flex align-items-center">
                      <span class="mr-2">Membros</span>
                      <span class="material-icons mr-1 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Adicionar Membro" data-action="click->${this.controllerName}#addMember">person_add</span>
                    </div>
                    <div class="col-6 px-1 d-none" data-${this.controllerName}-target="addMemberSelector">
                      <div class="form-group board-task-filter my-0">
                        <div class="floating-label floating-label-sm">
                          <label>Membros</label>
                          <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="memberSquadDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" data-${this.controllerName}-target="memberSquadInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="membersList"></ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex align-items-center">
                    <div class="col-12 d-flex align-items-center px-0" data-${this.controllerName}-target="membersChips" style="flex-flow:wrap;">
                      ${memberHtml}
                    </div>
                  </div>
                </div>`

    return html
  }

  selectMember(ev) {
    var squadId = this.viewSquadBodyTarget.dataset.id
    var memberId = ev.target.dataset.id
    var memberName = ev.target.dataset.name
    ev.target.classList.remove("li-selected")
    this.addMemberSelectorTarget.classList.add("d-none")
    this.cleanAddMemberSelector(this)
    

    allSquadList.forEach(element => {
      if (element.id == squadId && element.member_ids.includes(memberId) == false) {
        var member = { name: memberName, id: memberId }
        this.membersChipsTarget.insertAdjacentHTML("beforeend", this.memberChipPartial(member))
        tooltip()    
        const data = {
          squad: { id: squadId, member_id: memberId, mode: "add" },
          current_user: { current_user_id: currentUser.id }
        }
        this.requestUpdate(data)
      }
    });
    
  }

  cleanAddMemberSelector(controller) {
    setTimeout(function () {
      controller.memberSquadInputTarget.removeAttribute("data-action")
      controller.memberSquadInputTarget.removeAttribute("data-id")
      controller.memberSquadInputTarget.removeAttribute("data-name")
      controller.memberSquadInputTarget.innerText = ""
      controller.memberSquadDropdownTarget.value = ""
      floatingLabel()
    }, 500)
  }

  removeMember(ev) {
    var result = confirm("Tem certeza que deseja remover a pessoa?")
    if (result) {
      var squadId = this.viewSquadBodyTarget.dataset.id
      var memberId = ev.target.dataset.id
      
      const data = {
        squad: { id: squadId, member_id: memberId, mode: "remove" },
        current_user: { current_user_id: currentUser.id }
      }
      this.requestUpdate(data)
    }
  }

  memberChipPartial(member) {
    var html = `<div class="chip fade show mr-2 mb-2" id="chipDismissible-${member.id}">
                  <span class="material-icons mr-1 default" data-toggle="tooltip" data-placement="top" title data-original-title="${member.name}">face</span>
                  <button class="close" data-dismiss="alert" data-target="#chipDismissible-${member.id}" type="button" data-toggle="tooltip" data-placement="top" title data-original-title="Remover da Squad"><span class="material-icons md-150" data-id="${member.id}" data-action="click->${this.controllerName}#removeMember">cancel</span></button>
                </div>`
    
    return html
  }

  squadPartial(element) {
    if ($("#currentUserPermisisonContainer").data("squad_entities").can_select) {
      
      var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showSquad">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`
    } else {
      var check = ''
    }
    var html = `<tr class="itemRow" data-id="${element.id}">
                  <td class="table-3">${check}</td>
                  <td style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">${element.name}</td>
                  <td style="font-size:80%;" scope="col" class="p-1 table-40 align-middle text-center">${element.description}</td>
                  <td style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center">${element.leader_name}</td>
                  <td style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">${element.total_members}</td>
                  <td class="table-3"></td>
                </tr>`

    return html
  }

  doDashboardTablePreloader() {
    const columns = 6
    const rows = 4
    var columnPreloaderHtml = ''
    for (var i = 0; i < columns; i++) {
      columnPreloaderHtml += `<td class='animated-background animated-background-td'></td>`
    }
    var tablePreloaderHtml = ''
    for (var i = 0; i < rows; i++) {
      tablePreloaderHtml += `<tr class='timeline-item' data-${this.controllerName}-target="bodyTablePreloader">` + columnPreloaderHtml + `</tr>`
    }
    // this.bodyTableTarget.innerHTML = tablePreloaderHtml
    return tablePreloaderHtml
  }

  fetchAllUsers() {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/list_team"
    const data = { user: { account_kind: "team" }, current_user: { current_user_id: currentUser.id } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        allUsers = response.data.cln
      })
  }

  fetchMemberList() {
    var membersListHtml = ``
    allUsers.forEach(user => {
      membersListHtml += `<li data-action="click->${this.controllerName}#selectMember" data-id="${user.id}" data-name="${user.nickname}" class="li-selector dark">${user.nickname}</li>`
    });
    this.membersListTarget.innerHTML = membersListHtml
  }

  getCurrentUserPermissions() {
    const featureNames = ["squad_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          $("#currentUserPermisisonContainer").data(permission.name, permission.data)
        });
        controller.doIndexListHtml()
        controller.doViewListHtml()
      })
  }

  getSquads() {
    const token = $('meta[name=csrf-token]').attr('content');
    const data = {
      squad: { active: true },
      current_user: { current_user_id: currentUser.id }
    }
    const url = "/users/squads/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    var targetElement = this.bodyTableTarget
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          allSquadList = response.data.cln
          controller.bodyTablePreloaderTargets.forEach(target => {
            target.remove()
          });

          allSquadList.forEach(element => {
            const html = controller.squadPartial(element)
            targetElement.insertAdjacentHTML("beforeend", html)
          });

          tooltip()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
  }

  requestUpdate(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/squads/entities/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var task = response.data.cln
          allSquadList.forEach((element, i) => {
            if (element.id == task.id) {
              allSquadList.splice(allSquadList.indexOf(element), 1, task)
            }
          });
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
  }
}
