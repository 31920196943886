import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "list", "mainCard", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--sites--index--main`
    this.application.permissions = {}
    this.application.sites = []
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []

    this.sort = { mode: `desc`, field: `due_date_to_time` }
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--marketing--sites--index--dashboard

                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.sites.length == 0) {
        controller.getCurrentUserPermissions()
      } else {
        controller.getControllerByIdentifier("commercial--marketing--sites--index--dashboard").permission = `marketing_site_entity`
        controller.getControllerByIdentifier("commercial--marketing--sites--index--dashboard").doSitesDashboard()
      }
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier("commercial--marketing--sites--index--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("commercial--marketing--sites--index--dashboard").permission = `marketing_site_entity`
    this.getControllerByIdentifier("commercial--marketing--sites--index--dashboard").doSitesDashboard()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getSites() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Lista de Sites`, value: 55 })

    const data = { site: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `marketing_site_entity` } }
    const url = `/commercial/marketing/sites/entities/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sites = response.data.cln

        controller.finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `marketing_site` }, current_user: { current_user_id: this.application.current_user.id, feature: `marketing_site_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`marketing_site_permissions`]

        controller.getSites()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}