import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "summary", "board", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--process--dashboard`
  }

  doProcessDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--tax-filings--process--index"></div>
                  <div class="col-3 pl-1" data-${this.controllerName}-target="summary" data-controller="operations--products--tax-filings--process--summary"></div>
                  <div class="col-1 pl-1" data-${this.controllerName}-target="board" data-controller="operations--products--tax-filings--process--board"></div>
                </div>`
    this.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  translateJourney(journey) {
    if (journey == `awaiting_documentation`) {
      return `Aguardando Documentação`
    } else if (journey == `documentation_sent`) {
      return `Documentação Enviada`
    } else if (journey == `in_process`) {
      return `Em Processo`
    } else if (journey == `draft_sent`) {
      return `Rascunho Enviado`
    } else if (journey == `awaiting_transmission`) {
      return `Aguardando Transmissão`
    } else if (journey == `transmitted`) {
      return `Transmitido`
    } else if (journey == `rectification`) {
      return `Retificado`
    } else if (journey == `enquiry`) {
      return `Consulta Processamento`
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
