import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--trackers--projects--dashboard`
  }

  doProjectsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--trackers--projects--index"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--trackers--projects--save"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--trackers--main--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`users--works--trackers--projects--index`).permission = `user_works_trackers_projects`
      controller.getControllerByIdentifier(`users--works--trackers--projects--index`).gridElement = controller.listTarget
      controller.getControllerByIdentifier(`users--works--trackers--projects--save`).permission = `user_works_trackers_projects`
      controller.getControllerByIdentifier(`users--works--trackers--projects--save`).gridElement = controller.viewTarget

      controller.getControllerByIdentifier(`users--works--trackers--projects--index`).doProjectList()
      controller.getControllerByIdentifier(`users--works--trackers--projects--save`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
