import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "preloaderVideoCard", "title", "subtitle", "video", "navbarSpacing"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `mobile--commercial--marketing--events--live--thank-you`
    this.application.event_token = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    if (this.application.current_user_id != null || this.application.current_user_id != undefined) {
      this.application.public_view = false
    } else {
      this.application.public_view = true
    }
    this.setNavbarSpacing()
    // window.onbeforeunload = function () {
    //   return "Do you really want to close?";
    // }
  }

  setPage() {
    this.titleTarget.innerHTML = `<h5 class="display-4">Obrigado por participar da nossa edição do MedSchool</h5>`
    this.subtitleTarget.innerHTML = `<h5>Acompanhe a MedCapital em @medcapital e stay tune no Descanso Médico</h5>`
    this.videoTarget.innerHTML = `<div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/sAEWBdKBw0s?rel=0&modestbranding=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>`
  }

  doPreload() {
    var videoPreloaderHtml = this.videoPreloader()

    var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 text-center mb-3" data-${this.controllerName}-target="title">
                    ${this.loader}
                    ${this.loader}
                    ${this.loader}
                  </div>
                  <div class="col-12 text-center mb-3" data-${this.controllerName}-target="subtitle">
                    ${this.loader}
                    ${this.loader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 text-center" data-${this.controllerName}-target="video">
                    ${videoPreloaderHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.preloaderVideoCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getEvent()
    })
  }

  getEvent() {
    var data = { event: { slug: this.application.event_token } }
    const url = "/commercial/marketing/events/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.event = response.data.cln
          controller.setPage()

        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setNavbarSpacing() {
    var navbarHeight = Number($('#navMobile').height());
    var navbarPadding = Number($('#navMobile').css('padding-top').replace("px", "")) + Number($('#navMobile').css('padding-bottom').replace("px", ""));
    var navbarSpacing = navbarHeight + navbarPadding
    this.navbarSpacingTarget.style.marginTop = navbarSpacing + "px"
    this.doPreload()
  }

  videoPreloader() {
    var html = `<div class="card" data-${this.controllerName}-target="preloaderVideoCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body py-0">
                    <div class="row h-70">
                      <div class="col-12">
                        <div class="preloader-wrapper">
                          <div class="preloader-circle"></div>
                          <div class="preloader-circle"></div>
                          <div class="preloader-circle"></div>
                          <div class="preloader-shadow"></div>
                          <div class="preloader-shadow"></div>
                          <div class="preloader-shadow"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-center">
                        <span class="f-1p25 f-bold">Conheça o Descanso Médico ... </span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  layout() {
    
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}