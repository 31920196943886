
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "submenuMedBtn"]

  connect() {
    this.controllerName = `financials--books--statements--dashboard--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank');
  }

  goToDashboard() {
    this.getControllerByIdentifier("financials--books--statements--dashboard--main").doMainDashboard()
  }

  goToBalances() {
    this.getControllerByIdentifier("financials--books--statements--balances--dashboard").doBalancesDashboard()
  }

  goToTransactions() {
    this.getControllerByIdentifier("financials--books--statements--transactions--dashboard").doTransactionsDashboard()
  }

  goToDaily() {
    this.getControllerByIdentifier("financials--books--statements--daily--dashboard").doBalancesDashboard()
  }

  goToAnnual() {
    this.getControllerByIdentifier("financials--books--statements--annual--dashboard").doBalancesDashboard()
  }

  goToCashFlow() {
    this.getControllerByIdentifier("financials--books--cash-flows--compilations--dashboard").doCashFlowDashboard()
  }


  doSubmenuHtml() {
    var html = ``
    var medBtn = ``
    
    medBtn += `<button type="button" data-kind="group" data-action="${this.controllerName}#setMed ${this.controllerName}#toggleMedBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuMedBtn">Grupo</button>`
    this.application.meds.forEach(element => {
      medBtn += `<button type="button" data-kind="company" data-med-id="${element.id}" data-action="${this.controllerName}#setMed ${this.controllerName}#toggleMedBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuMedBtn">${element.name}</button>`
    })

    // html += `<div class="btn-group mr-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="medsBtnGroup">
    //           ${medBtn}
    //         </div>`

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var payablesBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/contas-a-pagar" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas à Pagar</button>`
    var receivablesBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/contas-a-receber" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas à Receber</button>`

    if (this.application.permissions.financial_statement_balances.can_index) {
      var balanceBtn = `<button type="button" data-action="${this.controllerName}#goToBalances ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas de Balanço</button>`
    } else {
      var balanceBtn = ``
    }

    if (this.application.permissions.financial_statement_balances.can_index) {
      var dailyBtn = `<button type="button" data-action="${this.controllerName}#goToDaily ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Diário</button>`
    } else {
      var dailyBtn = ``
    }

    if (this.application.permissions.financial_statement_balances.can_index) {
      var annualBtn = `<button type="button" data-action="${this.controllerName}#goToAnnual ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anual</button>`
    } else {
      var annualBtn = ``
    }

    if (this.application.permissions.financial_statement_cash_flows.can_index) {
      var cashFlowBtn = `<button type="button" data-action="${this.controllerName}#goToCashFlow ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Fluxo de Caixa</button>`
    } else {
      var cashFlowBtn = ``
    }

    if (this.application.permissions.financial_statement_transactions.can_index) {
      var transactionBtn = `<button type="button" data-action="${this.controllerName}#goToTransactions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn transactionCount">Transações</button>`
    } else {
      var transactionBtn = ``
    }

    html += `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${balanceBtn}
                  ${cashFlowBtn}
                  ${dailyBtn}
                  ${transactionBtn}
                  ${payablesBtn}
                  ${receivablesBtn}
                </div>`

    
    // this.mainTarget.innerHTML = submenuHtml
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {
    if (this.checkSubmenu(ev.currentTarget.innerText)) {
      this.submenuBtnTargets.forEach(element => {
        element.classList.add(`btn-flat-dark`)
        element.classList.remove(`btn-primary`)
      })
      
      ev.currentTarget.classList.remove(`btn-flat-dark`)
      ev.currentTarget.classList.add(`btn-primary`)
    }
  }

  dashboardToggleBtn(title) {
    var btn = ``

    this.submenuBtnTargets.forEach(element => {
      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  checkSubmenu(text) {
    var menu = [`CONTAS À RECEBER`, `CONTAS À PAGAR`]

    if (menu.includes(text)) {
      return false
    } else {
      return true
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
