import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "objAttach", "invoiceCheckboxPdf"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--emails--send-taker-invoice`
  }

  sendEmail() {
    this.send_data = { current_obj: {}, tracker: {}, current_user: {} }

    this.send_data.current_obj.id = this.current_obj.id
    this.send_data.current_obj.type = `clinic_fin_receivables`
    this.send_data.current_obj.message = `E-mail com a NF foi enviado para processamento!`
    this.send_data.current_obj.clinic_id = this.application.clinic.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_receivables`

    var receivers = []
    var objs = []
    this.current_receivers.forEach(receiver => {
      if (receiver.status && receiver.operational && this.sendController.nameTarget(`receiverCheckbox-${receiver.id}`).checked) {
        receivers[receivers.length] = receiver.id
      }
    })

    this.objAttachTargets.forEach(element => {
      if (element.checked) {
        objs[objs.length] = { obj_id: element.dataset.objId, obj_type: element.dataset.objType }
      }
    });

    this.send_data.current_obj.objs = objs
    this.send_data.current_obj.action = `send_partner_apuration`

    this.send_data.tracker.receivers = receivers
    this.send_data.tracker.sender_id = this.application.current_user.account_id
    this.send_data.tracker.sender_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.tracker.domain_id = this.current_clinic.id
    this.send_data.tracker.domain_type = `operation_products`

    var url = "/operations/products/clinics/managements/emails/send_receivable_emails"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).closeEmailModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setAttachments() {

    var attachsHtml = ``

    var invoice = this.current_obj.main_invoice
    var tooltiptextPdf = ``
    invoice_number_pretty
    if (invoice.has_pdf) {
      tooltiptextPdf += `NF: #${this.current_obj.main_invoice.invoice_number_pretty} | `
      tooltiptextPdf += `Bruto: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_obj.total_amount)}`
      var checkPdf = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                        <input type="checkbox" class="custom-control-input" data-obj-id="${transfer.id}" data-obj-type="clinic_fin_transfers" id="check-${transfer.id}" data-${this.controllerName}-target="transferCheckbox-${transfer.id} objAttach">
                        <label class="custom-control-label f-065 pointer ml-3" for="check-${transfer.id}">
                          <span class="mc-tooltip pointer">
                            <span class="f-065">${transfer.kind_pretty}</span>
                            <span class="mc-tooltiptext mc-tooltiptext-right">${tooltiptext}</span>
                          </span>
                        </label>
                      </div>`
      var pdf = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
    } else {
      var checkPdf = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                        <input disabled type="checkbox" class="custom-control-input" data-obj-id="${invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-obj-kind="pdf" id="checkPDF" data-${this.controllerName}-target="invoiceCheckboxPdf">
                        <label class="custom-control-label f-065 pointer ml-3" for="checkPDF">PDF</label>
                      </div>`
      var pdf = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
    }

    if (invoice.has_xml) {
      var xml = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
    } else {
      var xml = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
    }

    attachsHtml += `<div class="row mb-3 d-flex align-items-center">
                      <div class="col-10">
                        ${checkPdf}
                      </div>
                      <div class="col-2 px-0">
                        ${pdf}
                      </div>
                    </div>`

    


    this.mainTarget.innerHTML = attachsHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}