import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "linkedinLink", "mainCard", "instagramLink", "facebookLink",
                    "body", "behanceLink", "figmaLink", "githubLink"]

  connect() {
    this.controllerName = `users--works--recruitments--applicants--links-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doListHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doListHtml() {

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#close" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

      // `<div class="col-12 text-center mc-tooltip grab">
      //   <a class="f-normal f-1p5 grab" href="${this.current_applicant.linkedin}" target="blank" data-controller="app--helpers--copy" data-copy="${this.current_applicant.linkedin}" data-action="click->app--helpers--copy#copy"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
      //   <span class="mc-tooltiptext mc-tooltiptext-left">LinkedIn</span>
      // </div>`

      // `<div class="col-12 text-center mc-tooltip">
      //   <a class="f-normal f-1p5" href="https://api.whatsapp.com/send?phone=55${this.current_applicant.whatsapp}&amp;text=Olá ${this.current_applicant.name.split(" ")[0]}!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
      //   <span class="mc-tooltiptext mc-tooltiptext-left">WhatsApp</span>
      // </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list text-center" data-${this.controllerName}-target="cardTitle">Links Externos</h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row my-3" data-${this.controllerName}-target="linkedinLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.linkedin}" data-url="${this.current_applicant.linkedin}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-linkedin-in white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">LinkedIn</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3" data-${this.controllerName}-target="instagramLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.instagram}" data-url="${this.current_applicant.instagram}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-instagram white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">Instagram</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3" data-${this.controllerName}-target="facebookLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.facebook}" data-url="${this.current_applicant.facebook}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-facebook white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">Facebook</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3" data-${this.controllerName}-target="behanceLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.behance}" data-url="${this.current_applicant.behance}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-behance white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">Behance</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3" data-${this.controllerName}-target="figmaLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.figma}" data-url="${this.current_applicant.figma}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-figma white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">Figma</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3" data-${this.controllerName}-target="githubLink">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_applicant.github}" data-url="${this.current_applicant.github}" data-action="click->app--helpers--copy#copy click->${this.controllerName}#goToURL" class="f-normal f-1p5"><i class="fab fa-github white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">Github</span>
                        </small>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 text-center">
                        <small class="mb-0 mc-tooltip grab">
                          <span data-action="click->${this.controllerName}#sendWhatsAppApi" class="f-normal f-1p5"><i class="fab fa-whatsapp white-text mr-2"></i></span>
                          <span class="mc-tooltiptext mc-tooltiptext-left">WhatsApp</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.dashboardController.listTarget.innerHTML = html)
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.45) + "px"

      if (controller.current_applicant.linkedin == ``) {
        controller.linkedinLinkTarget.classList.add(`d-none`)
      }
      if (controller.current_applicant.instagram == ``) {
        controller.instagramLinkTarget.classList.add(`d-none`)
      }
      if (controller.current_applicant.facebook == ``) {
        controller.facebookLinkTarget.classList.add(`d-none`)
      }
      if (controller.current_applicant.behance == ``) {
        controller.behanceLinkTarget.classList.add(`d-none`)
      }
      if (controller.current_applicant.figma == ``) {
        controller.figmaLinkTarget.classList.add(`d-none`)
      }
      if (controller.current_applicant.github == ``) {
        controller.githubLinkTarget.classList.add(`d-none`)
      }


    })
  }

  sendWhatsAppApi() {    
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.application.vacancy.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `recruitment_vacancies`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Vaga ${this.application.vacancy.name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = this.current_applicant.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = this.current_applicant.prefix
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = this.current_applicant.phone
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = this.current_applicant.uniq_phone
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `user_works_recruitments_calls`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}