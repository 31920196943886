import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable",
                    "body", "searchInput", "searchTable", "sourceInput"]

  connect() {
    this.controllerName = `users--works--recruitments--applicants--phone-action`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doActionHtml()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doActionHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addSource" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Fonte</span>`
    } else {
      var addLine = ``
    }

    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshSources" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Fontes</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#close" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Escolha a Ação</h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row my-4">
                      <div class="col-6">
                        <button type="button" class="btn btn-flat-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#actionWhatsApp">WhatsApp</button>
                      </div>
                      <div class="col-6">
                        <button type="button" class="btn btn-flat-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#actionCall">Ligar</button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.15) + "px"
    })
  }

  actionWhatsApp() {
    this.close()
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.application.vacancy.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `recruitment_vacancies`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Vaga ${this.application.vacancy.name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = this.current_applicant.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = this.current_applicant.prefix
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = this.current_applicant.phone
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = this.current_applicant.uniq_phone
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `user_works_recruitments_calls`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  actionCall(ev) {
    this.close()

    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_id = this.application.vacancy.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_type = `recruitment_vacancies`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_name = `Vaga ${this.application.vacancy.name}`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_id = this.current_applicant.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_type = `recruitment_applicants`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_name = this.current_applicant.name
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).dial_number = this.current_applicant.whatsapp
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).phone_number = this.current_applicant.full_phone_pretty
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).caller_id = this.application.caller.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).kind = `recruitment`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).permission = `user_works_recruitments_calls`


    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).openModal({ message: `Iniciar Chamada`, phone: this.current_applicant.full_phone_pretty })

  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}