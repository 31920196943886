import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--principal`
  }

  doHtml() {
    var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(this.application.site.header_link_cta)
    var classLeft = ``
    var classRight = ``
    var imgWidth = ``
    var btnMargin = ``

    if (this.application.size == `large`) {
      classLeft = `col-5 d-flex align-items-center`
      classRight = `col-7 d-flex justify-content-center align-items-center`
      imgWidth = `550`
    } else if (this.application.size == `medium` || this.application.size == `small`) {
      classLeft = `col-12 d-flex align-items-center text-center`
      classRight = `col-12 d-flex justify-content-center align-items-center mt-5`
      imgWidth = `400`
      btnMargin = `mx-auto`
    }

    var html = `<section class="ws-section-principal ws-bg-circle d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="${classLeft}">
                        <div class="columns">
                          <h1 class="ws-title-action">${this.application.site.principal_title}</h1>
                          <p class="ws-text-action">
                            ${this.application.site.principal_description}
                          </p>
                          <a class="ws-btn-action mt-5 pointer ${btnMargin}" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.principal_cta}</a>
                        </div>
                      </div>
                      <div class="${classRight}">
                        <img src="${this.application.site.principal_image}" width="${imgWidth}" alt="">
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-section-principal ws-bg-circle d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex align-items-center col-lg-5 col-sm-12">
                        <div class="columns">
                          <h1 class="ws-title-action">Facilitamos a vida financeira do médico de ponta a ponta.</h1>
                          <p class="ws-text-action">
                            Da abertura e regularização da sua PJ Médica, ao Imposto de Renda Médico, 
                            Livro-Caixa e Gestão de Faturamento e Recebimentos. Conta com a MedCapital!
                          </p>
                          <a class="ws-btn-action mt-5 pointer" data-link="https://conteudo.medcapital.com.br/consultoria" data-action="click->${this.controllerName}#goToCTA">Agendar consultoria</a>
                        </div>
                      </div>
                      <div class="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center">
                        <img src="https://medcapital-site-main.s3.sa-east-1.amazonaws.com/image_main_sector.png" width="550" alt="">
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
