import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cardBody", "bodyTable", "footerTable", "main", "list", "show", "checkboxShow", "viewDateCard", "viewTitle",
    "viewPeriodBody", "viewPeriodFooter", "publishStatus", "monthDropdown", "monthInput", "monthList", "yearDropdown", "yearInput", "yearList",
    "saveBtn", "deleteBtn", "dateOpen", "general", "cardGeneral", "hasPermissions", "editPermissionsBtn"]



  connect() {
    this.controllerName = `operations--products--receivements--entities--settings`
    this.receivement_token = location.pathname.split("/").pop()
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.doPageGrid()
    this.getCurrentUserPermissions()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-4" data-${this.controllerName}-target="list"></div>
                  <div class="col-4" data-${this.controllerName}-target="show"></div>
                  <div class="col-4" data-${this.controllerName}-target="general"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  cancelAction() {
    if (this.hasViewPeriodBodyTarget) {
      this.viewPeriodBodyTarget.remove()
    }
    if (this.hasViewPeriodFooterTarget) {
      this.viewPeriodFooterTarget.remove()
    }
    if (this.hasViewTitleTarget) {
      this.viewTitleTarget.innerText = "Nenhum Período de Recebimento Selecionado"
    }
  }

  newDate() {
    this.cancelAction()
    this.actionMode = "new"
    var date = {}
    this.viewTitleTarget.innerText = "Nova Competência para Recebimento"
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewDateCardTarget.insertAdjacentHTML("beforeend", controller.viewDateHtml(date)))
    }).then(() => {
      selector()
    })
    selector()
  }

  editDate(ev) {
    this.cancelAction()
    var dateId = ev.target.closest(".itemRow").dataset.id
    this.actionMode = "edit"
    if (this.hasViewPeriodBodyTarget) {
      this.viewPeriodBodyTarget.remove()
    }
    if (this.hasViewPeriodFooterTarget) {
      this.viewPeriodFooterTarget.remove()
    }

    var date = ``
    this.application.dates.forEach(element => {
      if (element.id == dateId) {
        date = element
      }
    });
    this.viewTitleTarget.innerText = date.name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewDateCardTarget.insertAdjacentHTML("beforeend", controller.viewDateHtml(date)))
    }).then(() => {
      selector()
    })
  }

  viewDateHtml(date) {
    if (this.application.product_dates.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary" data-action="click->${this.controllerName}#saveDate" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.product_dates.can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger" data-action="click->${this.controllerName}#deleteDate" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.actionMode == "edit") {
      if (date.open) {
        var inputHtml = `<input class="custom-control-input custom-control-input-permission" id="switchDateOpen" data-${this.controllerName}-target="dateOpen" type="checkbox" value="true" checked>`
      } else {
        var inputHtml = `<input class="custom-control-input custom-control-input-permission" id="switchDateOpen" data-${this.controllerName}-target="dateOpen" type="checkbox" value="false">`
      }
      var dateId = date.id
      var datePeriod = ``
      var switchDateOpen = `<div class="row">
                              <div class="col-4 my-3">
                                <span>Fechado</span>
                              </div>
                              <div class="col-4 my-3">
                                <div class="custom-control custom-switch d-flex align-items-center">
                                  ${inputHtml}
                                  <span class="custom-control-track"></span>
                                  <label class="custom-control-label" for="switchDateOpen"></label>
                                </div>
                              </div>
                              <div class="col-4 my-3">
                                <span>Aberto</span>
                              </div>
                            </div>`
    } else {
      var dateId = ``
      var datePeriod = `<div class="row d-flex align-items-center mt-4">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Mês de Referência</label>
                                <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="monthDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="monthInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="monthList">
                                      <li data-month="1" class="li-selector dark">Janeiro</li>
                                      <li data-month="2" class="li-selector dark">Fevereiro</li>
                                      <li data-month="3" class="li-selector dark">Março</li>
                                      <li data-month="4" class="li-selector dark">Abril</li>
                                      <li data-month="5" class="li-selector dark">Maio</li>
                                      <li data-month="6" class="li-selector dark">Junho</li>
                                      <li data-month="7" class="li-selector dark">Julho</li>
                                      <li data-month="8" class="li-selector dark">Agosto</li>
                                      <li data-month="9" class="li-selector dark">Setembro</li>
                                      <li data-month="10" class="li-selector dark">Outubro</li>
                                      <li data-month="11" class="li-selector dark">Novembro</li>
                                      <li data-month="12" class="li-selector dark">Dezembro</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Ano de Referência</label>
                                <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="yearDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="yearInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="yearList">
                                      <li data-year="2019" class="li-selector dark">2019</li>
                                      <li data-year="2020" class="li-selector dark">2020</li>
                                      <li data-year="2021" class="li-selector dark">2021</li>
                                      <li data-year="2022" class="li-selector dark">2022</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`
      var switchDateOpen = ``
    }

    var html = `<div class="card-body pt-0" data-${this.controllerName}-target="viewPeriodBody" data-id="${dateId}">
                  ${datePeriod}
                  ${switchDateOpen}
                </div>
                <div class="modal-footer border-top" data-${this.controllerName}-target="viewPeriodFooter">
                  <button type="button" class="btn btn-secondary" data-action="click->${this.controllerName}#cancelAction">Cancelar</button>
                  ${deleteBtnHtml}
                  ${saveBtnHtml}
                </div>`

    return html
  }

  saveDate() {
    if (this.validateSelectors().valid) {
      this.saveBtnTarget.disabled = true
      if (this.hasDeleteBtnTarget) {
        this.deleteBtnTarget.disabled = true
      }
      if (this.actionMode == "edit") {
        var data = {
          product: { product_token: this.receivement_token },
          date: { id: this.viewPeriodBodyTarget.dataset.id, open: this.dateOpenTarget.checked },
          current_user: { current_user_id: this.application.current_user.id }
        }
      } else if (this.actionMode == "new") {
        var data = {
          product: { product_token: this.receivement_token },
          date: { month: this.monthInputTarget.dataset.month, year: this.yearInputTarget.dataset.year },
          current_user: { current_user_id: this.application.current_user.id }
        }
      }
      this.requestSave(data)
    } else {
      alert(this.validateSelectors().error)
    }
  }

  deleteDate() {
    var data = {
      product: { product_token: this.receivement_token },
      date: { id: this.viewPeriodBodyTarget.dataset.id, active: false },
      current_user: { current_user_id: this.application.current_user.id }
    }
    this.requestSave(data)
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    if (this.actionMode == "edit") {
      var url = "/operations/products/dates/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/dates/create"
      var method = "POST"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var date = response.data.cln
          this.cancelAction()
          if (this.actionMode == "edit") {
            if (date.active) {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1, date)
                }
              })
            } else {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.dates[this.application.dates.length] = date
          }
          this.doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        processingSnackbar(response.type, response.message, device)
      })
  }

  doGeneralHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="cardGeneral">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">Configurações Gerais</h6>
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row" data-${this.controllerName}-target="hasPermissions">
                      <div class="col-8">
                        <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.generalTarget.innerHTML = html)
    }).then(() => {
      controller.cardGeneralTarget.style.height = ($(window).height() * 0.65) + "px"
    })
  }

  doViewListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewDateCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Período de Recebimento Selecionado</h6>
                  </div>
                </div>`

    this.showTarget.innerHTML = html
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="cardBody">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Períodos</h6>
                    <div class="card-actions ml-auto py-0">
                      <div class="dropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="actionReceivementDateDrop" type="button"><i class="material-icons">more_vert</i></button>
                        <div aria-labelledby="actionReceivementDateDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span class="dropdown-item pointer" id="newDate" data-action="click->${this.controllerName}#newDate">Nova Competência</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                        <table class="table table-sm table-hover table-search" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th class="table-3"></th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">Período</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">Status</th>
                              <th class="table-3"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.cardBodyTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(4,4))
      controller.getReceivementDates()
    })
  }

  getReceivementDates() {
    var data = { date: { product_token: this.receivement_token }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln.dates
          controller.application.receivement = response.data.cln.product
          controller.doDataTable()
          controller.checkIfUserHasPermissions()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doDataTable() {
    var dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.dates, "uniq_product_date")

    if (dates.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(dates.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (dates[x] !== undefined) {
            internPage[internPage.length] = dates[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: dates.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="p-5 align-middle text-center" style="font-size:200%;">
                      <span class="fa-stack fa-2x">
                        <i class="fas fa-list fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <h5>Não existem Períodos de Recebimentos</h5>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.dateTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  dateTablePartial(element) {
    if (this.application.product_dates.can_select) {
      // var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
      //               <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showDate">
      //               <label class="custom-control-label pointer" for="check-${element.id}"></label>
      //             </div>`
      var check = ''
    } else {
      var check = ''
    }

    if (this.application.product_dates.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editDate" type="button" class="btn btn-sm btn-table editAuthor p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>`
    } else {
      var edit = ``
    }

    if (element.open) {
      var statusOpen = `<span style="color:#26C485;"><i class="fas fa-check-double" data-toggle="tooltip" data-placement="top" title data-original-title="Aberto">`
    } else {
      var statusOpen = `<span style="color:#F25F5C;"><i class="fas fa-times-circle" data-toggle="tooltip" data-placement="top" title data-original-title="Fechado">`
    }

    var rowHtml = `<tr class="itemRow" data-id="${element.id}">
                    <th class="table-3">${check}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">${element.date_pretty}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">${statusOpen}</th>
                    <th class="table-3">${edit}</th>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getCurrentUserPermissions() {
    const featureNames = ["medfat", "product_dates"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });

        if (controller.application.medfat.can_read) {
          controller.doViewListHtml()
          controller.doIndexListHtml()
          controller.doGeneralHtml()
        }
      })
  }

  savePermissions() {
    const data = { product: { kind: "receivement" }, account: { account_id: this.application.receivement.account_id }, current_user: { current_user_id: currentUser.id, permission: "update", feature: "user_permissions" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/save_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var editPermission = `<span style="color:#26C485;"><i class="fas fa-check-double md-400" data-toggle="tooltip" data-placement="top" title data-original-title="Tem as Permissões">`
        } else {
          var editPermission = `<button data-action="click->${this.controllerName}#savePermissions" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Gerar Permissões"><span class="material-icons md-sm md-dark">slideshow</span></button>`
        }
        controller.editPermissionsBtnTarget.innerHTML = editPermission
      })
  }

  checkIfUserHasPermissions() {
    const featureNames = ["medfat", "product_dates", "transfers", "transfer_values", "pjtaker", "guidelines", "receivement_papers", "receivement_entry_forms", "receivement_entries", "receivement_calculations"]
    const data = { permission: { features: featureNames }, account: { account_id: this.application.receivement.account_id }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/check_if_has_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.data.cln.has_permissions) {
          var editPermission = `<span style="color:#26C485;"><i class="fas fa-check-double md-400" data-toggle="tooltip" data-placement="top" title data-original-title="Tem as Permissões">`
        } else {
          var editPermission = `<button data-action="click->${this.controllerName}#savePermissions" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Gerar Permissões"><span class="material-icons md-sm md-dark">slideshow</span></button>`
        }
        var html = `<div class="col-8">
                      Permissões de Acesso
                    </div>
                    <div class="col-4 text-center" data-${this.controllerName}-target="editPermissionsBtn">
                      ${editPermission}
                    </div>`
        controller.hasPermissionsTarget.innerHTML = html
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  validateSelectors() {
    if (this.hasMonthInputTarget && this.monthInputTarget.innerText == "") {
      return { valid: false, error: "Mês de Referência não pode ficar em branco" }
    } else if (this.hasYearInputTarget && this.yearInputTarget.innerText == "") {
      return { valid: false, error: "Ano de Referência não pode ficar em branco" }
    } else {
      return { valid: true }
    }
  }

}
