import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "previewTitle", "body", "loader", "mainRow"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `financials--books--apurations--regimes--modal`
    this.controllerSaveClient = `financials--books--apurations--regimes--save`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  setCurrentRegime() {
    this.current_regime = {}
    
    this.application.regimes.forEach(regime => {
      if (regime.id == this.regime_id) {
        this.current_regime = regime
      }
    })
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.setCurrentRegime()
      controller.doModalHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  editRegime() {
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).current_med = this.current_med
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).current_regime = this.current_regime
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).current_date = this.current_date
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).gridElement = this.bodyTarget
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).permission = this.permission
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).actionMode = `edit`
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).mode = `single`
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).doFormHtml()
  }

  deleteRegime() {
    var r = confirm("Desejar apagar o Regime Tributário?")
    this.deleteRegimeBtnTarget.disabled = true

    if (r) {
      this.actionMode = "delete"

      this.send_data.regime.id = this.current_regime.id
      this.send_data.regime.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.notification.domain_id = this.application.clinic.id
      this.send_data.notification.domain_type = "operation_products"
      this.send_data.notification.date_id = this.current_date.id
      this.send_data.notification.date_type = "operation_dates"
      this.send_data.notification.kind = `mode_${this.actionMode}`
      this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o pagamento ${this.current_regime.chart}, no valor de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_regime.amount)} para a competência de ${this.current_date.month_pretty}.`
      this.send_data.notification.account_name = this.application.current_user.name
      this.send_data.notification.account_id = this.application.current_user.account_id

      this.requestSave()
    } else {
      this.deleteRegimeBtnTarget.disabled = false
    }

  }

  requestSave() {

    var url = "/operations/products/clinics/regimes/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var regime = response.data.cln

          if (regime.active) {
            controller.application.regimes.forEach((element, i) => {
              if (element.id == regime.id) {
                controller.application.regimes.splice(controller.application.regimes.indexOf(element), 1, regime)
              }
            })
          } else {
            controller.application.regimes.forEach((element, i) => {
              if (element.id == regime.id) {
                controller.application.regimes.splice(controller.application.regimes.indexOf(element), 1)
              }
            })
          }

          controller.getControllerByIdentifier("financials--books--apurations--regimes--index").doDataTable()
          controller.send_data = { current_user: {}, regime: {}, notification: {} }
        } else {
          controller.deleteRegimeBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }


  doModalHtml() {
    this.getControllerByIdentifier(`financials--books--apurations--regimes--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_delete && this.current_date.open && this.current_date.has_no_receivables) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteRegimeBtn" data-action="click->${this.controllerName}#deleteRegime" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Regime Tributário</span>`
    } else {
      var deleteBtnHtml = ``
    }

    // if (this.application.permissions.medclinic_regimes.can_update && this.current_date.open && this.current_date.has_no_receivables) {
    if (this.application.permissions[this.permission].can_update && this.current_date.open) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editRegimeBtn" data-action="click->${this.controllerName}#editRegime" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Regime Tributário</span>`
    } else {
      var editBtnHtml = ``
    }

    if (this.current_regime.iss_type == `aliquot`) {
      var issValue = `${this.getControllerByIdentifier("app--helpers--numbers").percentMask(Math.round(this.current_regime.iss_rate * 100))}`
    } else if (this.current_regime.iss_type == `fixed`) {
      var issValue = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(Math.round(this.current_regime.iss_amount * 100))}`
    }

    if (this.current_regime.open) {
      var openStatus = `Aberto para Automatização`
    } else {
      var openStatus = `Fechado para Automatização`
    }


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Regime Tributário</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Regime Tributário</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_regime.tax_regime_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Sub-regime</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_regime.sub_tax_regime_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Natureza Legal</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_regime.legal_nature_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Regime Especial</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_regime.special_tax_regime_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Últimos 12 Meses</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_regime.last_twelve_months_sum)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Mês Competência</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_regime.accrual_month)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Alíquota Efetiva</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.current_regime.effective_total_tax, { scale: 4 })}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>ISS</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_regime.iss_type_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Valor ISS</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${issValue}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="5" class="f-065 align-middle px-0">
                                <strong>Status</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${openStatus}
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      // controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Regime Tributário ${controller.current_regime.accrual_date_pretty} | ${controller.current_regime.med_name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}