import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "saveBtn", "nameInput", "emailInput", "councilInput", "councilStateInput",
                    "prefixInput", "phoneInput", "mainCard"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--search`
  }

  doSearchHtml() {
    var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm btn-table f-065 pointer" data-action="click->${this.controllerName}#searchSalesLead" data-${this.controllerName}-target="saveBtn">Procurar SQL</button>`

    // `<li data-source="landing" data-source-type="landing_conversion" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing</li>
    // <li data-source="event" data-source-type="event_conversion" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Evento</li>
    // <li data-source="session" data-source-type="session_conversion" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sessões</li>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-6 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameSearchForm" data-${this.controllerName}-target="nameInput" data-action="keyup->${this.controllerName}#enterStage" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="emailForm">E-mail</label>
                            <input aria-describedby="emailFormHelp" class="form-control" id="emailSearchForm" data-${this.controllerName}-target="emailInput" placeholder="E-mail" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    <div class="row my-2">
                      <div class="col-3 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="councilForm">CRM</label>
                            <input aria-describedby="councilFormHelp" class="form-control" id="councilForm" data-${this.controllerName}-target="councilInput" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" placeholder="CRM" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pl-2 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="councilStateForm">UF CRM</label>
                            <input aria-describedby="councilStateFormHelp" class="form-control" id="councilStateForm" data-${this.controllerName}-target="councilStateInput" placeholder="UF CRM" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 pl-2 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="prefixForm">DDD</label>
                            <input aria-describedby="prefixFormHelp" class="form-control" id="prefixForm" data-${this.controllerName}-target="prefixInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="DDD" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="phoneForm">Telefone</label>
                            <input aria-describedby="phoneFormHelp" class="form-control" id="phoneForm" data-${this.controllerName}-target="phoneInput" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask" placeholder="Número" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065 pointer" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--leads--entities--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.viewTitleTarget.innerText = `Procurando SQL`
      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // controller.refreshSaveBtn()
    })
  }

  crmMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCrm(ev)
  }

  cancelSave() {
    this.application.searchMode = false
    this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).clearList()
  }

  prefixMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  enterStage(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.searchSalesLead()
    }
  }

  searchSalesLead() {
    this.saveBtnTarget.disabled = true

    this.search_data = {}

    this.search_data.name = this.nameInputTarget.value.trim().toUpperCase()
    this.search_data.council_state = this.councilStateInputTarget.value.trim().toUpperCase()
    this.search_data.council_number = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.councilInputTarget.value.trim())

    this.search_data.prefix = this.prefixInputTarget.value.trim()
    this.search_data.phone = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value.trim())
    this.search_data.email = this.emailInputTarget.value.trim().toUpperCase()

    this.requestSearch()
  }

  requestSearch() {
    this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).bodyTableTarget.insertAdjacentHTML("beforeend", this.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
    this.application.searchMode = true
    this.application.search_leads = []

    
    var filterName = this.search_data.name
    var filterNameUnaccent = filterName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.application.leads.forEach((element, i) => {

      if (this.search_data.prefix && this.search_data.phone) {
        if (element.prefix == this.search_data.prefix && element.phone == this.search_data.phone) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      } else if (this.search_data.phone) {
        if (element.phone == this.search_data.phone) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      } else if (this.search_data.prefix) {
        if (element.prefix == this.search_data.prefix) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      }

      if (this.search_data.council_state && this.search_data.council_number) {
        if (element.council_state.toUpperCase() == this.search_data.council_state && element.council_number == this.search_data.council_number) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      } else if (this.search_data.council_number) {
        if (element.council_number == this.search_data.council_number) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      } else if (this.search_data.council_state) {
        if (element.council_state.toUpperCase() == this.search_data.council_state) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      }

      if (this.search_data.email) {
        if (element.email.toUpperCase() == this.search_data.email) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      }

      if (this.search_data.name) {
        var elementName = element.name
        var elementNameUnaccent = elementName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        if (elementName.toUpperCase().search(filterName) > -1 || elementNameUnaccent.toUpperCase().search(filterNameUnaccent) > -1) {
          this.application.search_leads[this.application.search_leads.length] = element
        }
      }
    })

    this.saveBtnTarget.disabled = false
    this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).doDataTable()
    // this.cancelSave()


    // this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)

    
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
