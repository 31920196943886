import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "siteName", "siteDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `commercial--marketing--sites--show--main`
    this.application.site_slug = location.pathname.split("/").pop()
    this.application.permissions = {}
    this.application.site = []
    this.application.services = []
    this.application.bios = []
    this.application.questions = []
    this.application.testimonials = []
    this.application.differentials = []
    
    this.doSiteGrid()

  }

  doSiteGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--marketing--sites--show--dashboard
                                                            commercial--marketing--sites--principal--dashboard
                                                            commercial--marketing--sites--headers--dashboard
                                                            commercial--marketing--sites--services--dashboard
                                                            commercial--marketing--sites--teams--dashboard
                                                            commercial--marketing--sites--platform--dashboard
                                                            commercial--marketing--sites--oncall--dashboard
                                                            commercial--marketing--sites--bios--dashboard
                                                            commercial--marketing--sites--testimonials--dashboard
                                                            commercial--marketing--sites--differentials--dashboard
                                                            
                                                            commercial--marketing--sites--questions--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier("commercial--marketing--sites--show--dashboard").doSiteDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.site.name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.site.name
      }, 500);
    }

    this.setSiteName()
    this.doSubtitle()

    this.getControllerByIdentifier("commercial--marketing--sites--show--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("commercial--marketing--sites--principal--dashboard").doPrincipalDashboard()
    
    this.stopRefreshing()
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getSite() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Site`, value: 10 })

    var data = { site: { slug: this.application.site_slug }, current_user: { current_user_id: this.application.current_user.id, feature: `marketing_site_entity` } }
    const url = `/commercial/marketing/sites/entities/read`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.site = response.data.cln
          controller.application.services = controller.application.site.services
          controller.application.bios = controller.application.site.teams
          controller.application.questions = controller.application.site.questions
          controller.application.testimonials = controller.application.site.testimonials
          controller.application.differentials = controller.application.site.differentials

          controller.finishLoadPage()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `marketing_site` }, current_user: { current_user_id: this.application.current_user.id, feature: `marketing_site_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`marketing_site_permissions`]

        controller.getSite()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  setSiteName() {
    var html = `<strong>
                  Site MedCapital
                </strong>
                <span> | </span>
                <strong>
                  ${this.application.site.name}
                </strong>`

    this.siteNameTarget.innerHTML = html
  }

  doSubtitle() {
    this.subTitleTarget.innerText = `Gestão do Conteúdo do Site da MedCapital | ${this.application.site.name}`
  }

  refreshLoadValue() {
    var controller = this
    var value = 10
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Informações da Competência ${controller.application.current_date.name}`, value: value })
      value += 1
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}