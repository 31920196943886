
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "payableCount", "totalReceivables",
                    "receivableCount", "receivableCountTooltip", "submenuBtn", "totalTickets", "payableCount",
                    "totalBillings", "billingCount", "billingCountTooltip", "ticketCountTooltip", "asdf"]

  connect() {
    this.controllerName = `financials--books--receivables--dashboard--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank');
  }

  goToDashboard() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--main").doMainDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("financials--books--receivables--tickets--dashboard").doTicketsDashboard()
  }

  goToContracts() {
    this.getControllerByIdentifier("financials--books--receivables--contracts--dashboard").doContractsDashboard()
  }

  goToConciliations() {
    this.getControllerByIdentifier("financials--books--receivables--conciliations--dashboard").doConciliationsDashboard()
  }

  goToBillings() {
    this.getControllerByIdentifier("financials--books--receivables--billings--dashboard").doBillingsDashboard()
  }

  goToReceivables() {
    this.getControllerByIdentifier("financials--books--receivables--entities--dashboard").doReceivablesDashboard()
  }

  goToLiquidations() {
    this.getControllerByIdentifier("financials--books--receivables--liquidations--dashboard").doLiquidationsDashboard()
  }

  goToAnnual() {
    this.getControllerByIdentifier("financials--books--receivables--annual--dashboard").doReceivablesDashboard()
  }



  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var statementsBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/demonstrativos-financeiros" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Demonstrativos</button>`

    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    if (this.application.permissions.financial_receivable_contracts.can_index) {
      var contractBtn = `<button type="button" data-action="${this.controllerName}#goToContracts ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contratos</button>`
    } else {
      var contractBtn = ``
    }

    if (this.application.permissions.financial_receivable_conciliations.can_index) {
      var conciliationBtn = `<button type="button" data-action="${this.controllerName}#goToConciliations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Conciliação</button>`
    } else {
      var conciliationBtn = ``
    }

    if (this.application.permissions.financial_receivable_entities.can_index) {
      var receivablesBtn = `<span class="mc-tooltip">
                              <button type="button" data-action="${this.controllerName}#goToReceivables ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn receivableCount">Recebíveis</button>
                              <span class="mc-tooltiptext" data-${this.controllerName}-target="receivableCountTooltip"></span>
                            </span>`
    } else {
      var receivablesBtn = ``
    }

    if (this.application.permissions.financial_receivable_entities.can_index) {
      var liquidationBtn = `<button type="button" data-action="${this.controllerName}#goToLiquidations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Liquidação</button>`
    } else {
      var liquidationBtn = ``
    }

    var annualBtn = `<button type="button" data-action="${this.controllerName}#goToAnnual ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anual</button>`

    // var billingsBtn = `<button type="button" data-action="${this.controllerName}#goToBillings ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn billingCount">Faturas</button>`
    var billingsBtn = `<span class="mc-tooltip">
                          <button type="button" data-action="${this.controllerName}#goToBillings ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn billingCount">Faturas</button>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="billingCountTooltip"></span>
                        </span>`


    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${annualBtn}
                  ${receivablesBtn}
                  ${billingsBtn}
                  ${conciliationBtn}
                  ${liquidationBtn}
                  ${ticketBtn}
                  ${statementsBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doBadgeCounts()
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
    this.doReceivablesCount()
    this.doBillingsCount()
  }

  toggleBtn(ev) {
    if (this.checkSubmenu(ev.currentTarget.innerText)) {
      this.submenuBtnTargets.forEach(element => {
        element.classList.add(`btn-flat-dark`)
        element.classList.remove(`btn-primary`)
      })
      
      ev.currentTarget.classList.remove(`btn-flat-dark`)
      ev.currentTarget.classList.add(`btn-primary`)
    }
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        ticketCount += 1
        if (element.stage == `in_process`) {
          ticketInProcessCount += 1
        }
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketInProcessCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doBillingsCount() {
    var billingCount = 0
    var billingUnconciliatedCount = 0

    this.application.billings.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        billingCount += 1
        if (element.conciliated == false) {
          billingUnconciliatedCount += 1
        }
      }
    })

    if (this.hasBillingCountTarget) {
      var billingBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalBillings">${billingUnconciliatedCount}</span>`
      if (this.totalBillingsTargets[0]) {
        this.totalBillingsTargets[0].remove()
      }
      this.billingCountTarget.insertAdjacentHTML("beforeend", billingBadgeHtml)
      this.billingCountTooltipTarget.innerText = `${billingUnconciliatedCount}/${billingCount} Fatura(s) não Conciliadas`
    }
  }

  doReceivablesCount() {
    var receivableCount = 0
    var receivableUnconciliatedCount = 0

    this.application.receivables.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        receivableCount += 1
        if (element.conciliated == false) {
          receivableUnconciliatedCount += 1
        }
      }
    })

    var addonCount = 0
    var addonWithoutReceivableCount = 0
    this.application.addons.forEach(addon => {
      addonCount += 1

      var addonReceivablesCount = 0
      addon.receivables.forEach(receivable => {
        if (this.application.current_date.id == receivable.date_id) {
          addonReceivablesCount += 1
        }
      })

      if (addonReceivablesCount == 0) {
        addonWithoutReceivableCount += 1
      }
    })

    if (this.hasReceivableCountTarget) {
      var receivableBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalReceivables">${addonWithoutReceivableCount}</span>`
      if (this.totalReceivablesTargets[0]) {
        this.totalReceivablesTargets[0].remove()
      }
      this.receivableCountTarget.insertAdjacentHTML("beforeend", receivableBadgeHtml)
      this.receivableCountTooltipTarget.innerText = `${addonWithoutReceivableCount}/${addonCount} Recebíveis(s) não Criados na ${this.application.current_date.name}`
    }
  }

  checkSubmenu(text) {
    var menu = [`DEMONSTRATIVOS`]

    if (menu.includes(text)) {
      return false
    } else {
      return true
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
