import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "cardBody", "footerTable", "checkboxProcess", "cardTitle",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "statusHelperSpan",
                    "addJourneyCard", "addJourneyTitle", "saveBtn", "dateInput", "statusInput", "statusFilter",
                    "statusFilterItem", "addStatusBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--journey--index`
    this.doIndexListHtml()
  }

  addStatus() {
    this.actionMode = `new`

    if (this.application.current_date.open) {
      if (this.hasAddJourneyCardTarget == false) {
        this.addJourneyHtml()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, this.application.current_date.message, 3000)
    }
  }

  deleteJourney(ev) {
    this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
    this.actionMode = `delete`
    var journeyId = ev.currentTarget.dataset.id

    var journey = {}
    this.application.journeys.forEach(element => {
      if (element.id == journeyId) {
        journey = element
      }
    });
    
    this.send_data.journey.id = journey.id
    this.send_data.journey.active = false

    var currentStatus = this.getControllerByIdentifier(`operations--products--tax-filings--process--dashboard`).translateJourney(journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `destroyed`
    this.send_data.tracker.observations = `Apagou a Jornada ${currentStatus} do IRPF ${this.application.current_date.financial_year}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    var r = confirm(`Tem certeza que deseja apagar esse status da Jornada?`)

    if (r) {
      this.requestSave()
    }
  }

  doIndexListHtml() {
    
    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center f-065 py-0 pr-0">
                    <h6 class="card-title mb-0 f-075">Jornada do IR</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button class="btn btn-outline my-0 btn-table py-2" aria-expanded="false" aria-haspopup="true" data-${this.controllerName}-target="addStatusBtn" data-action="click->${this.controllerName}#addStatus" type="button"><span class="material-icons md-dark md-sm">add</span></button>
                      <span class="mc-tooltiptext">Adicionar um novo Status na Jornada</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBody"></div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.addStatusBtnTarget.classList.add("d-none")
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_journeys = []
    this.application.journeys.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_journeys[this.current_journeys.length] = element
      }
    })

    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_journeys, `date_to_time`)
    
    this.listData(journeys)
  }

  listData(data) {
    this.cardBodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="10" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">A Jornada ainda não começou</span>
                      </td>
                    </tr>`

      this.cardBodyTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.cardBodyTarget.insertAdjacentHTML("beforeend", this.journeyTablePartial(element, data.length))
      });
      this.getControllerByIdentifier("operations--products--tax-filings--journey--save").last_journey = data[0]
      this.getControllerByIdentifier("operations--products--tax-filings--journey--save").doJourneyStatus()
      if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
        this.getControllerByIdentifier("operations--products--tax-filings--journey--summary").last_journey = data[0]
        this.getControllerByIdentifier("operations--products--tax-filings--journey--summary").doSummaryHTML()
      }
      
    }
  }

  journeyTablePartial(element, length) {

    if (this.application.permissions[this.application.journey_permission].can_delete && element.id == this.current_journeys[0].id) {
      var destroy = `<button data-action="click->${this.controllerName}#deleteJourney" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">delete</span>
                      <span class="mc-tooltiptext">Apagar</span>
                    </button>`
    } else {
      var destroy = ``
    }
    // var destroy = ``

    if (element.period == 1) {
      var period = `${element.period} dia`
    } else {
      var period = `${element.period} dias`
    }

    if (this.application.permissions[this.application.files_permission].can_show) {
      var document = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
    } else {
      var document = `<span class="mc-tooltip">
                        <span class="material-icons md-200 md-danger default">link_off</span>
                        <span class="mc-tooltiptext">Não há Arquivo</span>
                      </span>`
    }

    if (element.has_file) {
      var colFile = 4
      if (element.status == `draft_sent`) {
        var fileBtn = ``
        if (element.has_booking) {
          fileBtn += `<button data-kind="booking" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Livro-Caixa</span>
                      </button>`
        }
        fileBtn += `<button data-kind="draft" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Rascunho</span>
                    </button>`
      } else if (element.status == `awaiting_transmission`) {
      } else if (element.status == `transmitted`) {
        var fileBtn = ``
        if (element.has_darf) {
          fileBtn += `<button data-kind="darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf</span>
                      </button>`
        }
        if (element.has_booking) {
          fileBtn += `<button data-kind="booking" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Livro-Caixa</span>
                      </button>`
        }
        if (element.has_senior_darf) {
          fileBtn += `<button data-kind="senior_darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf de Doação Idoso</span>
                      </button>`
        }
        if (element.has_child_darf) {
          fileBtn += `<button data-kind="child_darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf de Doação ECA</span>
                      </button>`
        }
        fileBtn += `<button data-kind="receipt" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Recibo</span>
                    </button>
                    <button data-kind="filing" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Declaração</span>
                    </button>`
      } else if (element.status == `rectification`) {
        var fileBtn = ``
        if (element.has_booking) {
          fileBtn += `<button data-kind="booking" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Livro-Caixa</span>
                      </button>`
        }
        if (element.has_darf) {
          fileBtn += `<button data-kind="darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf de Retificação</span>
                      </button>`
        }
        if (element.has_senior_darf) {
          fileBtn += `<button data-kind="senior_darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf de Doação Idoso</span>
                      </button>`
        }
        if (element.has_child_darf) {
          fileBtn += `<button data-kind="child_darf" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Darf de Doação ECA</span>
                      </button>`
        }
        fileBtn += `<button data-kind="receipt" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Recibo de Retificação</span>
                    </button>
                    <button data-kind="filing" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                      <span class="material-icons md-sm md-dark">launch</span>
                      <span class="mc-tooltiptext">Ver Declaração de Retificação</span>
                    </button>`
        
      } else if (element.status == `enquiry`) {
        var fileBtn = `<button data-kind="enquiry" data-obj-id="${element.id}" data-obj-type="tax_filing_journeys" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto mr-2">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Consulta</span>
                      </button>`
      }
      
    } else {
      var colFile = 6
      var fileBtn = ``
    }

    var cardHtml = `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                      <div class="col-12 px-1">
                        <div class="card">
                          <div class="card-body px-0 py-1 f-065 pointer">
                            <div class="row my-2">
                              <div class="col-6 px-1">
                                <div class="card-show-dropdown w-100">
                                  ${element.status_pretty}
                                  <div class="card-show-dropdown-content text-left">
                                    <p class="mb-0 f-065">${element.date_pretty}  ${element.date_time}</p>
                                    <p class="mb-0 f-065">${period}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6 text-right">
                                ${fileBtn}
                                ${destroy}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`

    return cardHtml
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.hasAddJourneyCardTarget) {
      this.addJourneyCardTarget.remove()
    }
  }

  saveJourney() {
    this.send_data = { current_user: {}, journey: {}, notification: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.send_data.journey.date_pretty = this.dateInputTarget.value
    this.send_data.journey.status = this.statusInputTarget.dataset.filter
    this.send_data.journey.status_pretty = this.statusInputTarget.dataset.text

    this.send_data.notification.domain_id = this.application.tax_filing.id
    this.send_data.notification.domain_type = "operation_products"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "operation_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status ${this.statusInputTarget.dataset.text} na Jornada do IRPF ${this.application.current_date.year + 1}`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
    
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/products/tax_filings/journeys/create"
      var method = "POST"
    } else if (this.actionMode == `delete`) {
      var url = "/operations/products/tax_filings/journeys/destroy"
      var method = "DELETE"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var journey = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.journeys[controller.application.journeys.length] = journey
          } else {
            if (journey.active) {
              controller.application.journeys.forEach((element, i) => {
                if (element.id == journey.id) {
                  controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1, journey)
                }
              })
            } else {
              controller.application.journeys.forEach((element, i) => {
                if (element.id == journey.id) {
                  controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1)
                }
              })
            }
          }
          controller.cancelSave()
          controller.doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  addJourneyHtml() {
    
    this.stopRefreshing()

    if (this.application.permissions[this.application.journey_permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveJourney" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="addJourneyCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center f-065 py-2 pr-0">
                    <h6 class="card-title mb-0 f-075" data-${this.controllerName}-target="addJourneyTitle"></h6>
                  </div>
                  <div class="card-body py-0 d-flex align-items-center" style="overflow:auto;">
                    <div class="row w-100 my-2">
                      <div class="col-6 px-0">
                        <div class="row w-100">
                          <div class="col-12">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dateForm">Data</label>
                                <input aria-describedby="dateFormHelp" class="form-control form-valid-control" id="dateForm" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="statusForm">Status</label>
                                <input aria-describedby="statusFormHelp" class="form-control form-valid-control" id="statusForm" data-filter-mode="simple" data-${this.controllerName}-target="statusInput" data-action="focus->${this.controllerName}#statusFilter keyup->${this.controllerName}#statusFilter blur->${this.controllerName}#hideList" placeholder="Status" type="text" required>
                                <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="statusFilter"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-0">
                        <div class="row w-100">
                          <div class="col-12">
                            <span class="f-065 text-bold">Aguardando Documentação</span><br>
                            <span class="f-065 text-bold">Documentação Enviada</span><br>
                            <span class="f-065 text-bold">Em Processo</span><br>
                            <span class="f-065 text-bold">Rascunho Enviado</span><br>
                            <span class="f-065 text-bold">Aguardando Transmissão</span><br>
                            <span class="f-065 text-bold">Transmitido</span><br>
                            <span class="f-065 text-bold">Retificado</span>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    // controller.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").journeyTarget.insertAdjacentHTML("beforeend", html)
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").addJourneyTarget.innerHTML = html)
    }).then(() => {
      // controller.addJourneyCardTarget.style.height = ($(window).height() * 0.25) + "px"
      var yearDate = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dateInputTarget), "yearly", yearDate.getFullYear())
      controller.listStatus()
      if (controller.application.permissions[this.application.journey_permission].can_create) { controller.refreshSaveBtn() }
      if (controller.actionMode == `new`) {
        controller.addJourneyTitleTarget.innerText = `Adicionando Jornada`
      } else if (controller.actionMode == `edit`) {
      }

      controller.getControllerByIdentifier("app--helpers--elements").popover()
    })
  }

  statusHelper() {

    // `<div class="col-1 pl-1 pr-0 d-flex align-items-center">
    //   <span data-action="click->${this.controllerName}#statusHelper" data-${this.controllerName}-target="statusHelperSpan" class="material-icons md-sm md-dark pointer">help_outline</span>
    // </div>`
    var notes = `<span>Aguardando Documentação</span><br>
                 <span>Documentação Enviada</span><br>
                 <span>Em Processo</span><br>
                 <span>Rascunho Enviado</span><br>
                 <span>Aguardando Transmissão</span><br>
                 <span>Transmitido</span><br>
                 <span>Retificado</span>`


    $(this.statusHelperSpanTarget).popover({ content: `${notes}`, html: true, placement: "right" }); 
  }

  listStatus() {
    var html = `<li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Aguardando Documentação" data-filter="awaiting_documentation" class="li-selector dark f-065">Aguardando Documentação</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Documentação Enviada" data-filter="documentation_sent" class="li-selector dark f-065">Documentação Enviada</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Em Processo" data-filter="in_process" class="li-selector dark f-065">Em Processo</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Rascunho Enviado" data-filter="draft_sent" class="li-selector dark f-065">Rascunho Enviado</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Aguardando Transmissão" data-filter="awaiting_transmission" class="li-selector dark f-065">Aguardando Transmissão</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Transmitido" data-filter="transmitted" class="li-selector dark f-065">Transmitido</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Retificado" data-filter="rectification" class="li-selector dark f-065">Retificado</li>`

    this.statusFilterTarget.innerHTML = html
  }

  statusFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectStatus(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.application.files_permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  cleanCheckBox() {
    this.checkboxProcessTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (controller.dateInputTarget.value == ``) {
        len += 1
      }
      if (controller.statusInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
