import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["title", "board", "balanceHeader", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `financials--books--statements--dashboard--balance`
  }

  doBalanceCards() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span data-action="click->${this.controllerName}#refreshCompilation">Balanço Patrimonial ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.titleTarget.innerHTML = title
    }

    if (this.application.current_med.id) {
      this.application.current_date.statement_calculations.forEach(calculation => {
        if (calculation.med_id == this.application.current_med.id) {
          this.calculation = calculation
        }
      })
    } else {
      this.calculation = this.application.current_date.statement_compilation
    }

    var html = ``    
    html += `<div class="col-6 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070" data-${this.controllerName}-target="balanceHeader">
                      <span>Ativo | <strong>${this.numberController.currencyOptionMask(this.calculation.total_asset)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>
                <div class="col-6 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070" data-${this.controllerName}-target="balanceHeader">
                      <span>Passivo | <strong>${this.numberController.currencyOptionMask(this.calculation.total_liability)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-5"></div>`

    html += `<div class="col-6 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Disponibilidades | <strong>${this.numberController.currencyOptionMask(this.calculation.cash_and_cash_equivalents)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        ${this.setAvalabilitiesHtml()}
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>À Receber | <strong>${this.numberController.currencyOptionMask(this.calculation.receivables)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        ${this.setReceivablesHtml()}
                      </div>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Mútuos À Receber | <strong>${this.numberController.currencyOptionMask(this.calculation.asset_mutual_inside_group)}</strong></span>
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Imobilizado | <strong>${this.setAssetLongTerm()}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                </div>
                <div class="col-6 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Contas à Pagar | <strong>${this.numberController.currencyOptionMask(this.calculation.payables)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Reembolsos e Estornos à Fazer | <strong>${this.numberController.currencyOptionMask(this.calculation.refund_and_reversal_payables)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Cartões de Crédito e Empréstimos Curto Prazo | <strong>${this.numberController.currencyOptionMask(this.calculation.short_term_financial_obligations)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Impostos no Curto Prazo | <strong>${this.numberController.currencyOptionMask(this.calculation.short_term_taxes_and_others_obligations)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Empréstimos Longo Prazo | <strong>${this.numberController.currencyOptionMask(this.calculation.long_term_financial_obligations)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Impostos no Longo Prazo | <strong>${this.numberController.currencyOptionMask(this.calculation.long_term_taxes_and_others_obligations)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Mútuos a Pagar | <strong>${this.numberController.currencyOptionMask(this.calculation.liability_mutual_inside_group)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                  <div class="card mt-2">
                    <div class="card-header p-1 text-center f-070">
                      <span>Patrimônio Líquido | <strong>${this.setNetEquity()}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">                      
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.boardTarget.innerHTML = html)
    }).then(() => {

      if (this.numberController.currencyOptionMask(this.calculation.total_asset) != this.numberController.currencyOptionMask(this.calculation.total_liability)) { 
        controller.balanceHeaderTargets.forEach(element => {
          element.classList.add(`bg-light-danger`)
        })
      }
    }) 
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`financials--books--statements--dashboard--main`).refreshCompilation()
  }

  setAssetLongTerm() {
    var assetLongTermCharts = []
    var assetLongTermBalance = 0

    this.application.chart_accounts.forEach(chart_account => {
      if (chart_account.group == `tangible`) {
        assetLongTermCharts[assetLongTermCharts.length] = chart_account
      }
    })

    var assetLongTermChartIds = this.mapElements(assetLongTermCharts, `id`)
    
    this.application.balances.forEach(balance => {
      if (this.application.current_med.id) {
        if (balance.med_id == this.application.current_med.id && balance.date_id == this.application.current_date.id && assetLongTermChartIds.includes(balance.chart_id)) {
          assetLongTermBalance += Number(balance.balance)
        }
      } else {
        if (balance.date_id == this.application.current_date.id && assetLongTermChartIds.includes(balance.chart_id)) {
          assetLongTermBalance += Number(balance.balance)    
        }
      }
    })

    return this.numberController.currencyOptionMask(assetLongTermBalance)
  }

  setNetEquity() {
    var netEquityCharts = []
    var netEquityBalance = 0

    this.application.chart_accounts.forEach(chart_account => {
      if (chart_account.master_group == `net_equity`) {
        netEquityCharts[netEquityCharts.length] = chart_account
      }
    })

    var netEquityChartIds = this.mapElements(netEquityCharts, `id`)
    
    this.application.balances.forEach(balance => {
      if (this.application.current_med.id) {
        if (balance.med_id == this.application.current_med.id && balance.date_id == this.application.current_date.id && netEquityChartIds.includes(balance.chart_id)) {
          netEquityBalance += Number(balance.balance)
        }
      } else {
        if (balance.date_id == this.application.current_date.id && netEquityChartIds.includes(balance.chart_id)) {
          netEquityBalance += Number(balance.balance)    
        }
      }
    })

    return this.numberController.currencyOptionMask(netEquityBalance)
  }

  setAvalabilitiesHtml() {
    var availabilitiesChannels = []
    var availabilitiesHtml = ``

    this.application.chart_accounts.forEach(chart_account => {
      if (chart_account.group == `cash_and_cash_equivalents`) {
        availabilitiesChannels[availabilitiesChannels.length] = chart_account
      }
    })

    availabilitiesChannels = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(availabilitiesChannels, `chart_name`)
    
    var channelChartIds = this.mapElements(availabilitiesChannels, `id`)
    this.application.balances = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.balances, `chart_name`)

    this.application.balances.forEach(balance => {
      if (this.application.current_med.id) {
        // if (balance.med_id == this.application.current_med.id && balance.date_id == this.application.current_date.id && channelChartIds.includes(balance.chart_id) && balance.balance > 0) {
        if (balance.med_id == this.application.current_med.id && balance.date_id == this.application.current_date.id && channelChartIds.includes(balance.chart_id)) {
          availabilitiesHtml += `<div class="col-12 mb-3 text-left"><span>${balance.chart_name.replace(`Disponibilidades | `,``)} | <strong>${this.numberController.currencyOptionMask(balance.balance)}</strong></span></div>`
        }
      } else {
        if (balance.date_id == this.application.current_date.id && channelChartIds.includes(balance.chart_id)) {
          availabilitiesHtml += `<div class="col-12 mb-3 text-left"><span>${balance.chart_name.replace(`Disponibilidades | `,``)} | <strong>${this.numberController.currencyOptionMask(balance.balance)}</strong></span></div>`
        }
      }  
    })
    
    return availabilitiesHtml
  }

  setReceivablesHtml() {
    var receivablesChannels = []
    var receivablesHtml = ``

    this.application.chart_accounts.forEach(chart_account => {
      if (chart_account.group == `receivables`) {
        receivablesChannels[receivablesChannels.length] = chart_account
      }
    })

    receivablesChannels = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(receivablesChannels, `id`)

    var chartAccountIds = this.mapElements(receivablesChannels, `id`)

    this.application.balances.forEach(balance => {
      if (this.application.current_med.id) {
        if (balance.med_id == this.application.current_med.id && balance.date_id == this.application.current_date.id && chartAccountIds.includes(balance.chart_id) && balance.balance > 0) {
          receivablesHtml += `<div class="col-12 mb-3 text-left"><span>${balance.chart_name.replace(`Disponibilidades | `,``)} | <strong>${this.numberController.currencyOptionMask(balance.balance)}</strong></span></div>`
        }
      } else {
        if (balance.date_id == this.application.current_date.id && chartAccountIds.includes(balance.chart_id) && balance.balance > 0) {
          receivablesHtml += `<div class="col-12 mb-3 text-left"><span>${balance.chart_name.replace(`Disponibilidades | `,``)} | <strong>${this.numberController.currencyOptionMask(balance.balance)}</strong></span></div>`
        }
      }  
    })
    
    return receivablesHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
