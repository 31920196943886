import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["modal", "body", "time", "output"]

  connect() {
    this.controllerName = `app--communications--connection--login`
    // this.sessionTime = 900
    // this.countDownBegin = 780
    // this.sessionTime = 10
    // this.countDownBegin = 8
    // this.checkLogin()
    // this.inactiveTime = 900
    // this.inactiveTime = 1800
    this.inactiveTime = 3600
    this.heartBeatActivated = false
    this.getRoom()
    this.heartBeat()
  }

  checkLogin() {
    this.stopCountRefreshing()
    this.application.loginCount = 0

    var controller = this
    this.refreshCountTimer = setInterval(() => {
      controller.application.loginCount += 1
      controller.doSessionCountDown()

      if (controller.application.loginCount > controller.sessionTime) {
        // window.location.reload()
        controller.stopCountRefreshing()
        controller.checkSessionTimetout()
      } else if (controller.application.loginCount > controller.countDownBegin) {
        controller.countDown = controller.sessionTime - Number(controller.application.loginCount)
        if (controller.getControllerByIdentifier("app--communications--connection--login-modal")) {
          controller.getControllerByIdentifier("app--communications--connection--login-modal").timeTarget.innerText = controller.countDown
        }
      } else if (controller.application.loginCount == controller.countDownBegin) {
        controller.doModalHtml()
      } else {
        if (controller.getControllerByIdentifier("app--communications--connection--login-modal")) {
          controller.getControllerByIdentifier("app--communications--connection--login-modal").close()
        }
      }
      
    }, 1000);
  }

  doSessionCountDown() {
    var distance = this.sessionTime - this.application.loginCount
    var minutes = Math.floor((distance % (60 * 60)) / 60);
    var seconds = Math.floor(distance % (60));

    if (minutes < 0) {
      minutes = `00`
    } else if (minutes < 10) {
      minutes = `0${minutes}`
    }

    if (seconds < 0) {
      seconds = `00`
    } else if (seconds < 10) {
      seconds = `0${seconds}`
    }

    var countDown = `${minutes}:${seconds}`

    if (this.getControllerByIdentifier(`app--navigation--desktop--navbar`)) {
      this.getControllerByIdentifier(`app--navigation--desktop--navbar`).sessionTimerTarget.innerText = countDown
    } else if (this.getControllerByIdentifier(`mobile--app--navigation--navbar`)) {
      this.getControllerByIdentifier(`mobile--app--navigation--navbar`).sessionTimerTarget.innerText = countDown
    }
  }

  renewalLogin() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/check_user_log_in"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => {
        controller.responseClone = response.clone()
        return response.json()
      })
      .then(response => {
        controller.application.loginCount = 0
        if (controller.getControllerByIdentifier(`app--communications--connection--login-modal`)) {
          controller.getControllerByIdentifier(`app--communications--connection--login-modal`).close()
        }
        if (controller.responseClone.status == 401 && controller.responseClone.redirected) {
          window.location.reload()
        } else if (controller.responseClone.status == 422) {
          window.location.reload()
        }
      }, function (rejectionReason) { // 3
        if (controller.responseClone.status == 401 && controller.responseClone.redirected) {
          window.location.reload()
        } else if (controller.responseClone.status == 422) {
          window.location.reload()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkSessionTimetout() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    // const url = "/users/sessions/timeouts/check_session_timeout"
    const url = "/api/v1/users/sessions/timeouts/check_session_timeout"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => {
        controller.responseClone = response.clone()
        return response.json()
      })
      .then(response => {
        if (response.logout) {
          window.location.reload()
        } else {
          controller.checkLogin()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }, function (rejectionReason) { // 3
        if (controller.responseClone.status == 401 && controller.responseClone.redirected) {
          window.location.reload()
        } else if (controller.responseClone.status == 422) {
          window.location.reload()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  logOut() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    // const url = "/users/sessions/timeouts/check_session_timeout"
    const url = "/sair"
    const init = { method: "DELETE", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => {
        controller.responseClone = response.clone()
        return response.json()
      })
      .then(response => {
        window.location.reload()
      }, function (rejectionReason) { // 3
        window.location.reload()
        if (controller.responseClone.status == 401 && controller.responseClone.redirected) {
        } else if (controller.responseClone.status == 422) {
          window.location.reload()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    var data = { user: { user_id: this.application.current_user.id }, current_user: { current_user_id: this.application.current_user.id, feature: `users` } }
    const url = "/users/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.login_room = response.data.cln.login

          controller.sessionTime = response.timeout_in
          controller.countDownBegin = parseInt(response.timeout_in * 0.9)
          controller.checkLogin()
          controller.broadcastLoginChannel()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
          window.location.reload()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  broadcastLoginChannel() {
    var controller = this;
    this.application.userChannel = createChannel({ channel: 'Users::LoginChannel', id: this.login_room.id, user_id: this.application.current_user_id }, {
      connected() {
        controller.loginListen()
        // if (controller.getControllerByIdentifier("commercial--marketing--events--live--login")) {
        //   controller.getControllerByIdentifier("commercial--marketing--events--live--login").connectionOn()
        // }
      },
      received(data) {
        if (data.logout) {
          window.location.reload()
        } else {
          controller.application.loginCount = Number(controller.sessionTime) - Number(data.timeout_in)
          controller.lastActive = Date.now()
        }
      },
      disconnected() {
        
      }
    });
  }

  loginListen() {
    if (this.application.userChannel) {
      this.application.userChannel.perform('follow', { id: this.login_room.id })
    }
  }

  heartBeat() {
    this.lastActive = new Date().valueOf();
    this.refreshActive();
    // document.addEventListener('DOMContentLoaded', () => {
    // });
  }

  initHeartBeat() {
    if (!this.heartBeatActivated) {
      ['mousemove', 'scroll', 'click', 'keydown'].forEach((activity) => {
        document.addEventListener(activity, (ev) => {
          this.lastActive = ev.timeStamp + performance.timing.navigationStart
        }, false);
      });
      this.heartBeatActivated = true;
    }
  }

  refreshActive() {
    var controller = this
    this.initHeartBeat()
    this.refreshActiveTimer = setInterval(() => {
      controller.initHeartBeat()

      if ((Date.now() - controller.lastActive) > (controller.inactiveTime * 1000)) {
        controller.logOut()
        // controller.checkSessionTimetout()
      }

    }, 30000);
  }

  
  // class HeartBeat {
  //   constructor() {
  //     document.addEventListener('DOMContentLoaded', () => {
  //       this.initHeartBeat();
  //     });
  //   }
  //   initHeartBeat() {
  //     this.lastActive = new Date().valueOf();
  //     if (!heartBeatActivated) {
  //       ['mousemove', 'scroll', 'click', 'keydown'].forEach((activity) => {
  //         document.addEventListener(activity, (ev) => {
  //           this.lastActive = ev.timeStamp + performance.timing.navigationStart;
  //         }, false);
  //       });
  //       heartBeatActivated = true;
  //     }
  //   }
  // }

  doModalHtml() {
    var html = `<div class="modal fade" data-controller="app--communications--connection--login-modal" data-app--communications--connection--login-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Encerramento da Sessão</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-app--communications--connection--login-modal-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Sua sessão se encerrará em <span class="f-bold f-1" data-app--communications--connection--login-modal-target="time">${this.sessionTime - Number(this.application.loginCount)}</span>s</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center pointer" data-action="click->app--communications--connection--login-modal#renewalLogin">
                            <span class="material-icons md-dark md-lg">login</span>
                            <span class="f-075">Renovar Sessão</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  stopCountRefreshing() {
    if (this.refreshCountTimer) {
      clearInterval(this.refreshCountTimer)
    }
  }

  stopActiveRefreshing() {
    if (this.refreshActiveTimer) {
      clearInterval(this.refreshActiveTimer)
    }
  }

}