import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "realTime", "duration", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--requests--dashboard`
  }

  doRequestsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8" data-${this.controllerName}-target="duration" data-controller="app--stats--trackers--requests--duration"></div>
                </div>`

    // this.getControllerByIdentifier("app--stats--trackers--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--stats--trackers--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.submenuController) {
        controller.submenuController.dashboardToggleBtn(`REQUESTS`)
      }

      // controller.getControllerByIdentifier("app--stats--trackers--requests--index").domain_id = controller.domain_id
      // controller.getControllerByIdentifier("app--stats--trackers--requests--index").domain_type = controller.domain_type
      // controller.getControllerByIdentifier("app--stats--trackers--requests--index").feature_name = controller.feature_name
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
