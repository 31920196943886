import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "paidCard", "dueCard", "indicatorHeader", "indicators", "paidHeader", "dueHeader",
                    "actionCards", "partiallyPaidHeader", "externallyPaidHeader", "partiallyRefundedHeader"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--indicators`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.calculation = {}
    this.indicatorsPreload()
  }


  doIndicatorsCalculation() {

    this.calculation.total_billings = 0
    this.calculation.total = 0
    this.calculation.total_paid = 0
    this.calculation.total_paid_with_credit_card = 0
    this.calculation.total_paid_with_bank_slip = 0

    this.billings.forEach(billing => {

      this.calculation.total_billings += 1
      this.calculation.total += Number(billing.total_cents / 100)
      this.calculation.total_paid += Number(billing.total_paid_cents / 100)
      
      if (billing.payment_method == `credit_card`) {
        this.calculation.total_paid_with_credit_card += Number(billing.total_paid_cents / 100)
      } else if (billing.payment_method == `bank_slip`) {
        this.calculation.total_paid_with_bank_slip += Number(billing.total_paid_cents / 100)
      }

    })

    this.doIndicatorsHtml()
  }

  doIndicatorsHtml() {

              //     `<div class="col-12 px-2">
              //       <div class="card pointer" data-status="paid" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards paidCard">
              //         <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
              //           <span data-${this.controllerName}-target="paidHeader">Pagas no Dia ${this.current_day}</span>
              //         </div>
              //       </div>
              //     </div>
              //     <div class="col-12 px-2 mt-3">
              //       <div class="card pointer" data-status="refunded" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
              //         <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
              //           <span data-${this.controllerName}-target="partiallyPaidHeader">Pagas Parcialmente no Dia ${this.current_day}</span>
              //         </div>
              //       </div>
              //     </div>
              //     <div class="col-12 px-2 mt-3">
              //       <div class="card pointer" data-status="partially_refunded" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
              //         <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
              //           <span data-${this.controllerName}-target="partiallyRefundedHeader">Reembolsadas Parcial no Dia ${this.current_day}</span>
              //         </div>
              //       </div>
              //     </div>
              //     <div class="col-12 px-2 mt-3">
              //       <div class="card pointer" data-status="externally_paid" data-mode="paid_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
              //         <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
              //           <span data-${this.controllerName}-target="externallyPaidHeader">Pagas Externamente no Dia ${this.current_day}</span>
              //         </div>
              //       </div>
              //     </div>
              //     <div class="col-12 px-2 mt-3">
              //       <div class="card pointer" data-mode="due_date" data-action="click->${this.controllerName}#getBillings" data-${this.controllerName}-target="actionCards dueCard">
              //         <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
              //           <span data-${this.controllerName}-target="dueHeader">Vencidas no Dia ${this.current_day}</span>
              //         </div>
              //       </div>
              //     </div>`


    var html = `<div class="row">
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer" data-action="click->${this.controllerName}#showPanelModal" data-${this.controllerName}-target="panelModal">
                      <div class="card-header p-1 text-center f-065 f-bold">
                        <span>Buscar Faturas do Dia ${this.current_day}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total de Faturas</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.calculation.total_billings}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Faturado</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total) }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Recebido</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_paid) }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Recebido CC</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_paid_with_credit_card) }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Recebido Boleto</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_paid_with_bank_slip) }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).indicatorsTarget.innerHTML = html)
    }).then(() => {
      // controller.indicatorHeaderTargets[0].classList.add(`bg-primary`)
      if (controller.mode == `due_date`) {
        controller.dueCardTarget.classList.add(`bg-primary`)
      } else if (controller.mode == `paid_date`) {
        controller.paidCardTarget.classList.add(`bg-primary`)
      }
    })
  }

  indicatorsPreload() {
    var html = `<div class="row">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).indicatorsTarget.innerHTML = html
  }

  showPanelModal(ev) {
    var modal = `financials--books--receivables--conciliations--panel-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).current_day = controller.current_day
      controller.getControllerByIdentifier(modal).open()
    })
  }

  getBillings(ev) {
    this.mode = ev.currentTarget.dataset.mode
    this.status = ev.currentTarget.dataset.status
    
    this.actionCardsTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).mode = this.mode
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).status = this.status
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).goToDay()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}