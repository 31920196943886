import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "saveBtn", "sourceDropdown", "sourceDropdownBtn", "sourceInput", "sourceList", "nameInput",
                    "startedAtInput", "stageDropdown", "stageDropdownBtn", "stageInput", "stageList", "notesInput", "formCardBody", "body",
                    "setupCheckbox", "recurringCheckbox", "modal",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList", "productAccrualDiv"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--save-modal`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    var html = `<div class="modal-backdrop fade show"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doFormHtml() {
    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveOpportunity" data-${this.controllerName}-target="saveBtn">Criar Oportunidade</button>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="main">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">

                    <div class="row my-2">
                      <div class="col-6 px-0">
                        <div class="row my-2">
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Data Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Data Início" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Fonte</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sourceDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sourceInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="sourceList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Estágio</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="stageDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="stageDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="stageInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="stageList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="4"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6 px-0">
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Produto</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList">
                                      <li data-product-name="medclinic" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                      <li data-product-name="medbooking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                      <li data-product-name="medfiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Declaração IRPF</li>
                                      <li data-product-name="medreceivement" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestão de Recebimentos</li>
                                      <li data-product-name="medbiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                      <li data-product-name="medpayroll" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Folha de Pagamento</li>
                                      <li data-product-name="medinsurance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                      <li data-product-name="medprev" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Previdência Privada</li>
                                      <li data-product-name="medloan" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito</li>
                                      <li data-product-name="medoutsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">BPO</li>
                                      <li data-product-name="medconsulting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço Avulso</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Serviço</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-6 d-none" data-${this.controllerName}-target="productPlanDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Plano</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 d-none" data-${this.controllerName}-target="productAccrualDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Competência</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-5 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="setupCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="setupCheck">Avulso</label>
                            </div>
                          </div>
                          <div class="col-5 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="recurringCheck">Recorrência</label>
                            </div>
                          </div>
                          <div class="col-2 d-flex align-items-center" data-${this.controllerName}-target="purchaseKind" data-kind="none"></div>
                        </div>


                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.getLead()
    })
  }

  setForm() {
    var date = new Date()
    this.startedAtInputTarget.value = this.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
    this.startedAtInputTarget.disabled = true

    this.stageInputTarget.innerText = `Prospecção`
    this.stageInputTarget.dataset.stage = `prospecting`
    this.stageDropdownTarget.value = `prospecting`
    this.stageDropdownBtnTarget.disabled = true

    this.sourceInputTarget.innerText = `Interna`
    this.sourceInputTarget.dataset.source = `internal`
    this.sourceDropdownTarget.value = `internal`
    this.sourceDropdownBtnTarget.disabled = true

    this.viewTitleTarget.innerText = this.title

    if (this.current_lead.id) {
      this.nameInputTarget.value = this.current_lead.name
      this.nameInputTarget.disabled = true
    } else {
      this.nameInputTarget.value = this.current_obj.account_name
      this.nameInputTarget.disabled = true
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.refreshSaveBtn()
  }

  cancelSave() {
    this.controllerForm.clearList()
    this.stopRefreshing()
    this.close()
  }

  saveOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.actionMode = `new`

    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    this.send_data = { current_user: {}, lead: {}, opportunity: {}, notification: {}, note: {}, product: {}, tracker: { manual: true } }

    this.send_data.lead.account_id = this.current_obj.account_id
    if (this.current_lead.id) {
      this.send_data.lead.id = this.current_lead.id
    }

    this.send_data.opportunity.status = `hot`
    this.send_data.opportunity.stage = this.stageInputTarget.dataset.stage
    this.send_data.opportunity.source = this.sourceInputTarget.dataset.source
    this.send_data.opportunity.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtInputTarget.value)
    this.send_data.opportunity.notes = this.notesInputTarget.value.trim()
    this.send_data.opportunity.obj_id = this.current_obj.id
    this.send_data.opportunity.obj_type = this.current_obj.type
    this.send_data.opportunity.campaign = `upsell_base`

    this.send_data.note.account_id = this.application.current_user.account_id
    // this.send_data.note.domain_id = this.application.opportunity.id
    this.send_data.note.domain_type = `sales_opportunities`
    this.send_data.note.domain_name = `sales_opportunities`
    // this.send_data.note.date_id = this.application.opportunity.date_id
    this.send_data.note.date_type = `commercial_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} criou a Oportunidade de Venda com Lead ${this.nameInputTarget.value}. Observações: ${this.notesInputTarget.value.trim()}`
    this.send_data.note.title = `Atualização`

    this.send_data.product.name = this.productNameInputTarget.dataset.productName
    this.send_data.product.plan = this.productKindInputTarget.dataset.productKind
    this.send_data.product.kind = this.productServiceInputTarget.dataset.productService
    this.send_data.product.amount = 0
    
    if (this.setupCheckboxTarget.checked) {
      this.send_data.product.recurring = false
    } else if (this.recurringCheckboxTarget.checked) {
      this.send_data.product.recurring = true
    }

    this.send_data.tracker.record_id = this.current_obj.id
    this.send_data.tracker.record_type = this.current_obj.type
    this.send_data.tracker.obj_id = this.current_obj.date_id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `${this.send_data.note.owner_name} adicionou uma Oportunidade de ${this.productNameInputTarget.innerText}: ${this.current_obj.observations}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/sales/opportunities/entities/create_from_modal"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln
          controller.application.opportunities[controller.application.opportunities.length] = opportunity
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.cancelSave()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        controller.cancelSave()
      })
  }

  uniqKind(ev) {
    var kind = ev.currentTarget.dataset.kind

    if (kind == `setup`) {
      this.purchaseKindTarget.dataset.kind = `setup`
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.purchaseKindTarget.dataset.kind = `recurring`
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  selectProductName(ev) {
    this.product_name = ev.currentTarget.dataset.productName

    if (this.product_name == `medfiling`) {
      this.productAccrualDivTarget.classList.remove(`d-none`)
      this.productPlanDivTarget.classList.remove(`d-none`)
      this.setProductAccrualList()
      this.setProductPlanList()
    } else {
      this.productAccrualDivTarget.classList.add(`d-none`)
      this.productPlanDivTarget.classList.add(`d-none`)
    }

    this.listProductKind()
    this.listKind()
  }

  listProductKind() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medfiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`

      // this.productKindDropdownBtnTarget.disabled = false
      // this.productKindDropdownTarget.value = ``
      // this.productKindInputTarget.dataset.productKind = ``
      // this.productKindInputTarget.innerText = ``
    } else if (this.product_name == `medbooking`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medclinic`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="home" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">eSocial</li>`
      html += `<li data-product-kind="employee" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Funcionário</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `none`
      this.productKindInputTarget.dataset.productKind = `none`
      this.productKindInputTarget.innerText = `Nenhum`
      html += `<li data-product-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`
    } else if (this.product_name == `medprev`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="pgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PGBL</li>`
      html += `<li data-product-kind="vgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">VGBL</li>`
    } else if (this.product_name == `medloan`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="alimony" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesada</li>`
      html += `<li data-product-kind="debt_refinancing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financiamento</li>`
      html += `<li data-product-kind="prepayment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="finance_outsourcing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
      html += `<li data-product-kind="virtual_office" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Escritório Virtual</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="life_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vida</li>`
      html += `<li data-product-kind="income_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DIT</li>`
      html += `<li data-product-kind="responsability_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">RCP</li>`
      html += `<li data-product-kind="health_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saúde</li>`
    }

    this.productKindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listKind() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Planner`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medfiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Declaração IRPF`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbooking`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medclinic`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="pos_opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pós-Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="amendment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>`
      // html += `<li data-product-service="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Gestão de Recebimentos`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Faturamento`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `separate`
      this.productServiceInputTarget.dataset.productService = `separate`
      this.productServiceInputTarget.innerText = `Avulso`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medprev`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medloan`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    }

    this.productServiceListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setProductAccrualList() {
    var html = ``

    html += `<li data-product-accrual="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>`
    html += `<li data-product-accrual="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>`
    html += `<li data-product-accrual="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>`
    html += `<li data-product-accrual="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>`
    html += `<li data-product-accrual="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>`
    html += `<li data-product-accrual="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2018</li>`

    this.productAccrualListTarget.innerHTML = html
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="basic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Basic</li>`
    html += `<li data-product-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>`

    this.productPlanListTarget.innerHTML = html
  }

  getLead() {
    
    if (this.current_obj.account_email) {

      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      var data = { lead: { email: this.current_obj.account_email }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const url = `/commercial/sales/leads/entities/read_by_email`
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            controller.current_lead = response.data.cln
          }

          controller.setForm()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor pedir para o CS atualizar o email do Cliente!`, 5000)
      alert(`Favor pedir para o CS atualizar o email do Cliente!`)
      this.close()
    }

    
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasFormCardBodyTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.stageInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.notesInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasProductNameInputTarget && controller.productNameInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.hasProductPlanInputTarget && controller.productKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.hasProductServiceInputTarget && controller.productServiceInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.setupCheckboxTarget.checked == false && controller.recurringCheckboxTarget.checked == false) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
