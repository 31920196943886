import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--receivements--entities--dates`
    this.application.receivement_token = location.pathname.split("/").pop()
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1
    this.doCalendarHTML(month, year)
    this.getReceivementDates()
  }

  changeDate(ev) {
    if (ev.target.classList.contains("li-selector")) {
      month = ev.target.dataset.month
      year = ev.target.dataset.year
      this.setReceivementDate(month, year)
    }
  }

  // listPapers(paperCollection, paperPermission)

  setReceivementDate(month, year) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.month == month && element.year == year) {
        findElement = true
        currentReceivementDate = element
      }
    });

    if (findElement == false) {
      currentReceivementDate = { open: false, not_defined: true, message: `O Período está fechado`}
    }
    this.listPapers()
  }

  listPapers() {
    if (currentReceivementDate.not_defined) {
      listPapers([], paperPermission)
    } else {
      var collection = []
      paperCollection.forEach(element => {
        if (element.date_id == currentReceivementDate.id) {
          collection[collection.length] = element
        }
      })
      currentPaperCollection = collection
      listPapers(collection, paperPermission)
    }
  }

  getReceivementDates() {
    var data = { date: { product_token: this.application.receivement_token }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln.dates
          currentReceivementDate = response.data.cln[0]
          if (currentReceivementDate) {
            controller.dateInputTarget.innerText = currentReceivementDate.date_pretty
            controller.dateDropdownTarget.value = currentReceivementDate.date_pretty
            controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
          } else {
            currentReceivementDate = { open: false, not_defined: true, message: `O Período está fechado` }
          }
          controller.listPapers()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doCalendarHTML(month, year) {
    var data = { report: { month: month, year: year, current_date: true }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/general/dates/last_twelve_months"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var rowHtml = ``
        response.data.cln.forEach(element => {
          rowHtml += `<li data-month="${element.month}" data-year="${element.year}" data-action="click->${this.controllerName}#changeDate" class="li-selector dark">${element.date}</li>`
        });
        var html = `<div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label>Período de Referência</label>
                          <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="dateDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="dateInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="dateList">
                                ${rowHtml}
                              </ul>
                            </div>
                          </div>
                        </div>`
        controller.mainTarget.innerHTML = html
        selector()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
