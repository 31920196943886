import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "sendBtn", "receivers", "channelDropdown", "channelDropdownBtn", "channelInput",
    "channelList", "sendBtn", "modalBody"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--emails--send`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.sendController = this.getControllerByIdentifier(this.sendControllerName)
    this.setReceivers()
    this.setAttachments()
    this.refreshSendBtn()
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sendEmail() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.sendController.sendEmail()
  }

  setAttachments() {
    this.sendController.current_obj = this.current_obj
    this.sendController.current_clinic = this.current_clinic
    this.sendController.current_date = this.current_date
    this.sendController.current_receivers = this.current_receivers
    this.sendController.sendController = this
    this.sendController.setAttachments()
  }

  setReceivers() {

    var receiverHtml = ``
    // var receivers = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_receivers, `name`)
    var receivers = this.current_receivers

    receivers.forEach(receiver => {
      if (receiver.status && receiver.operational && receiver.councils.length > 0) {
        receiverHtml += `<div class="row mb-3">
                          <div class="col-12 px-1">
                            <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                              <input type="checkbox" class="custom-control-input" data-receiver-id="${receiver.id}" id="check-${receiver.id}" data-${this.controllerName}-target="receiverCheckbox-${receiver.id}">
                              <label class="custom-control-label f-065 pointer ml-3" for="check-${receiver.id}">${receiver.name}</label>
                            </div>
                          </div>
                        </div>`
      } else if (receiver.status && receiver.operational && receiver.doctor == false) {
        receiverHtml += `<div class="row mb-3">
                          <div class="col-12 px-1">
                            <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                              <input type="checkbox" class="custom-control-input" data-receiver-id="${receiver.id}" id="check-${receiver.id}" data-${this.controllerName}-target="receiverCheckbox-${receiver.id}">
                              <label class="custom-control-label f-065 pointer ml-3" for="check-${receiver.id}">${receiver.name}</label>
                            </div>
                          </div>
                        </div>`

      } else {
        var message = `Se o Médico Sócio não aparece ou (i) ele não é operacional ou (ii) está faltando alguma informação, como o CRM.
                        Favor voltar na Regularização e atualizar os dados`
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 5000)
      }
    })

    this.receiversTarget.innerHTML = receiverHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSendBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      var hasReceiver = false
      controller.current_receivers.forEach(receiver => {
        if (controller.nameTarget(`receiverCheckbox-${receiver.id}`) && controller.nameTarget(`receiverCheckbox-${receiver.id}`).checked) {
          hasReceiver = true
        }
      })
      if (hasReceiver == false) {
        len += 1  
      }

      var hasObj = false
      controller.sendController.objAttachTargets.forEach(element => {
        if (element.checked) {
          hasObj = true
        }
      })
      if (hasObj == false) {
        len += 1
      }

      if (len == 0) {
        controller.sendBtnTarget.disabled = false
      } else {
        controller.sendBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}