
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "totalTickets"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--show--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--show--dashboard").doClinicDashboard()
  }

  goToClinic() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--dashboard`).doEntitiesDashboard()
  }

  goToPartners() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").doPartnersDashboard()
  }

  goToTakers() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard").doTakersDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--tickets--dashboard").doTicketsDashboard()
  }

  goToPermissions() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--permissions--dashboard").doPermissionsDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--notes--dashboard").doNotesDashboard()
  }

  goToBanks() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--banks--dashboard").doBanksDashboard()
  }

  goToCertificates() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").doCertificatesDashboard()
  }

  goToIncorporations() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--dashboard").doIncorporationsDashboard()
  }

  goToActivities() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--activities--dashboard").doActivitiesDashboard()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`

    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    var clinicBtn = `<button type="button" data-action="${this.controllerName}#goToClinic ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">PJ Médica</button>`
    
    if (this.application.permissions.medclinic_regularization_partners.can_index) {
      var partnerBtn = `<button type="button" data-action="${this.controllerName}#goToPartners ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Quadro Societário</button>`
    } else {
      var partnerBtn = ``
    }
   
    if (this.application.permissions.medclinic_regularization_takers.can_index) {
      var takerBtn = `<button type="button" data-action="${this.controllerName}#goToTakers ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tomadores</button>`
    } else {
      var takerBtn = ``
    }

    if (this.application.permissions.medclinic_regularization_notes.can_index) {
      var noteBtn = `<button type="button" data-action="${this.controllerName}#goToNotes ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anotações</button>`
    } else {
      var noteBtn = ``
    }

    if (this.application.permissions.medclinic_regularization_banks.can_index) {
      var bankBtn = `<button type="button" data-action="${this.controllerName}#goToBanks ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas Bancárias</button>`
    } else {
      var bankBtn = ``
    }

    if (this.application.permissions.medclinic_regularization_certificates.can_index) {
      var certificateBtn = `<button type="button" data-action="${this.controllerName}#goToCertificates ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Certificado Digital</button>`
    } else {
      var certificateBtn = ``
    }

    if (this.application.permissions.medclinic_regularization_incorporations.can_index) {
      var incorporationBtn = `<button type="button" data-action="${this.controllerName}#goToIncorporations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contrato Social</button>`
    } else {
      var incorporationBtn = ``
    }

    if (this.application.permissions.medclinic_regularization_activities.can_index) {
      var activityBtn = `<button type="button" data-action="${this.controllerName}#goToActivities ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Atividades Econômicas</button>`
    } else {
      var activityBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${clinicBtn}
                  ${partnerBtn}
                  ${takerBtn}
                  ${bankBtn}
                  ${certificateBtn}
                  ${incorporationBtn}
                  ${activityBtn}
                  ${ticketBtn}
                  ${noteBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doTicketsCount()
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
      // element.style.width = `8rem`
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }  
      }
      
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketInProcessCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
