import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["validGroup", "cpfInput", "userNewPassword", "passwordMatch", "userConfirmationNewPassword", "main", "passwordInput", "signInBtn",
                    "passwordLength", "showPassword"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--users--auth--passwords--edit`
    this.canValidateCpf = true
    this.valid_cpf = false

    this.doHtml()
  }

  doHtml() {

    var html = `<div class="col-md-5 col-sm-12 d-flex align-items-center justify-content-center" style=" position: initial; height:100%;background-color:#FBFCFF;">
                  <div class="row">
                    <div class="col-12 text-center py-4 my-4">
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" style="width: 25rem;" alt="Logo MedCapital">
                      
                    </div>
                    <div class="col-12 text-center">
                      <h2 class="s-title-2rem s-title-053B5E">Facilitando sua vida financeira de ponta a ponta</h2>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 col-sm-12 d-flex justify-content-center align-items-center">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="forms-group">
                        <div class="floating-label">
                          <input data-${this.controllerName}-target="userNewPassword" data-action="focus->${this.controllerName}#cleanValidations
                                                                                                          blur->${this.controllerName}#fakeAction
                                                                                                          keyup->${this.controllerName}#fakeAction
                                                                                                          blur->${this.controllerName}#fakeAction" type="password" class="form-no-border ws-input-form" placeholder="Digite sua Senha" required>
                          <span class="material-icons ws-input-icon pointer" data-field="password" data-action="click->${this.controllerName}#showPassword">lock</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mb-3" data-${this.controllerName}-target="passwordLength">
                      <p class="text-muted"><small>Senha tem que ter mais de 7 caracteres</small></p>
                    </div>
                    <div class="col-12 text-center">
                      <div class="forms-group">
                        <div class="floating-label">
                          <input data-${this.controllerName}-target="userConfirmationNewPassword" data-action="keyup->${this.controllerName}#validateUserPassword
                                                                                                                        blur->${this.controllerName}#validateUserPassword
                                                                                                                        keyup->${this.controllerName}#changePassword
                                                                                                                        focus->${this.controllerName}#cleanValidations" type="password" class="form-no-border ws-input-form" placeholder="Confirme sua nova Senha" required>
                          <span class="material-icons ws-input-icon pointer" data-field="confirmation" data-action="click->${this.controllerName}#showPassword">lock</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mb-3" data-${this.controllerName}-target="passwordMatch">
                      <p class="text-muted"><small>As senhas não são iguais</small></p>
                    </div>
                    <div class="col-12 text-center my-5">
                      <a class="ws-button-disabled mx-auto default" data-${this.controllerName}-target="signInBtn" data-action="click->${this.controllerName}#changePassword">Salvar Nova Senha</a>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.userNewPasswordTarget.focus()

      controller.refreshSignInBtn()
      controller.getAfterLogin()
    })
  }

  validateUserPassword(ev) {
    var passwordLength = this.userNewPasswordTarget.value.length
    if ((ev.type == "keyup" && ev.key != "Enter") || ev.type == "blur") {
      var password = this.userNewPasswordTarget.value
      var passwordConfirmation = this.userConfirmationNewPasswordTarget.value

      var url = "/validations/forms/validate_user_password"
      var method = "POST"
      var data = { validations: { password: password, password_confirmation: passwordConfirmation } }
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.valid) {
            this.cleanValidations(ev)
          } else {
            // ev.target.classList.add("invalid-field")
            // const html = `<div class="invalid-warning my-1 py-1" data-users--auth--forgotten-target="invalid"></div>`
            // response.message.forEach(message => {
            //   if (ev.target.nextElementSibling) {
            //     ev.target.nextElementSibling.innerText = message
            //   } else {
            //     ev.target.parentElement.insertAdjacentHTML("beforeend", html);
            //     ev.target.nextElementSibling.insertAdjacentHTML("beforeend", message);
            //   }
            // });
          }
        })
    } else {
      this.cleanValidations(ev)
    }
  }

  changePassword(ev) {
    var passwordLength = this.userNewPasswordTarget.value.length
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && ev.target.value.length == passwordLength) || ev.type == "click") {
      this.stopRefreshing()
      this.signInBtnTarget.disabled = true
      var passwordParams = new URLSearchParams(window.location.search)
      var resetPasswordToken = passwordParams.get('reset_password_token')
      var password = this.userNewPasswordTarget.value
      var passwordConfirmation = this.userConfirmationNewPasswordTarget.value
      var data = { user: { password: password, password_confirmation: passwordConfirmation, reset_password_token: resetPasswordToken } }

      var url = "/senha"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            window.open(response.location, `_self`)
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            controller.refreshChangePasswordBtn()
            controller.signInBtnTarget.disabled = false
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 2000)
        })
    }
  }

  refreshSignInBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.userNewPasswordTarget.value.length < 7) {
        controller.passwordLengthTarget.classList.remove(`d-none`)
        len += 1
      } else {
        controller.passwordLengthTarget.classList.add(`d-none`)
      }

      if (controller.userNewPasswordTarget.value != controller.userConfirmationNewPasswordTarget.value) {
        controller.passwordMatchTarget.classList.remove(`d-none`)
        len += 1
      } else {
        controller.passwordMatchTarget.classList.add(`d-none`)
      }

      if (len == 0) {
        controller.toggleSignInBtn(true)
      } else {
        controller.toggleSignInBtn(false)
      }
    }, 200);
  }

  toggleSignInBtn(value) {
    this.signInBtnTarget.dataset.valid = value

    if (value) {
      this.signInBtnTarget.classList.remove(`default`)
      this.signInBtnTarget.classList.add(`pointer`)

      this.signInBtnTarget.classList.remove(`ws-button-disabled`)
      this.signInBtnTarget.classList.add(`ws-button`)
    } else {
      this.signInBtnTarget.classList.add(`default`)
      this.signInBtnTarget.classList.remove(`pointer`)

      this.signInBtnTarget.classList.add(`ws-button-disabled`)
      this.signInBtnTarget.classList.remove(`ws-button`)
    }

  }

  cleanValidations(ev) {
    ev.target.classList.remove("invalid-field")

    // this.cpfErrorInputTarget.innerHTML = ``
    // this.cpfInputTarget.classList.remove(`ws-invalid-field`)
    // this.passwordInputTarget.disabled = true
    this.toggleSignInBtn(false)
    // while (ev.target.nextElementSibling) {
    //   ev.target.nextElementSibling.remove()
    // }
  }

  showPassword(ev) {
    var element = ev.currentTarget
    if (element.innerText == `lock_open`) {
      if (element.dataset.field == `password`) {
        this.userNewPasswordTarget.type = `password`
      } else if (element.dataset.field == `confirmation`) {
        this.userConfirmationNewPasswordTarget.type = `password`
      }
      element.innerText = `lock`
    } else if (element.innerText == `lock`) {
      if (element.dataset.field == `password`) {
        this.userNewPasswordTarget.type = `text`
      } else if (element.dataset.field == `confirmation`) {
        this.userConfirmationNewPasswordTarget.type = `text`
      }
      element.innerText = `lock_open`
    }
  }

  getAfterLogin() {
    if (window.location.href.split("?")[1]) {
      if (window.location.href.split("?")[1].split("=")) {
        this.afterLogin = decodeURIComponent(window.location.href.split("?")[1].split("=")[1])
      }
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  fakeAction() { }

}
