import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--receivables--list`
    this.tableCols = 15
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `issued_on_to_time` }
    this.hasShow = false
    this.dateController = this.getControllerByIdentifier(`app--helpers--date`) 
    this.days = 7
  }

  showReceivable(ev) {
    var controllerShow = this.getControllerByIdentifier(`operations--products--clinics--financials--add--show`)
    var receivableId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.all_receivables.forEach(element => {
      if (element.id == receivableId) {
        controllerShow.current_receivable = element
        controllerShow.doRevisionFormHtml()
      }
    })
  }

  addReceivable() {
    // if (this.application.current_date.open == false) {
    //   alert(`Competência está fechada. Favor abrí-la para emitir um novo Recibo PJ/Nota Fiscal.`)
    //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para emitir um novo Recibo PJ/Nota Fiscal.`, 5000)
    // } else {
    //   if (this.application.canInvoice) {
    //     this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").doMainFormHtml()
    //   } else {
    //     this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A PJ não está regularizada para emissão de Nota Fiscal`, 4000)
    //   }
    // }

    this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--save").doMainFormHtml()

  }

  updateStatus(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.all_receivables.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--update-status").current_receivable = element
      }
    })
    this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--update-status").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--update-status").doFormHtml()
  }

  destroyReceivable(ev) {
    var receivableId = ev.currentTarget.closest(".itemRow").dataset.id
    var providerName = ev.currentTarget.closest(".itemRow").dataset.providerName
    var takerName = ev.currentTarget.closest(".itemRow").dataset.takerName
    var amount = ev.currentTarget.closest(".itemRow").dataset.amount
    this.send_data = { current_user: {}, receivable: {}, tracker: { log: true } }

    var receivable = {}
    this.application.all_receivables.forEach(element => {
      if (element.id == receivableId) {
        receivable = element
      }
    })

    this.send_data.receivable.id = receivable.id
    this.send_data.receivable.active = false

    this.send_data.tracker.action = `destroyed`
    this.send_data.tracker.record_id = receivable.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = receivable.date_id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.observations = `Nota Fiscal da ${providerName} para ${takerName} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja apagar o Recibo PJ?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      var url = "/operations/products/clinics/financials/receivables/destroy"
      var method = "DELETE"
      var controller = this
      var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var receivable = response.data.cln
            controller.application.all_receivables.forEach((element, i) => {
              if (element.id == receivable.id) {
                controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1)
              }
            })
            controller.doDataTable()
          }
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var currentDate = new Date()
    var firstDate = this.dateController.getFirstDate(currentDate, this.days)

    currentDate = this.dateController.transformPrettyDate(currentDate)
    firstDate = this.dateController.transformPrettyDate(firstDate)

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addReceivable" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Recibo PJ</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#getReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Recibos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Recibos / Notas Fiscais (Período de ${firstDate} até ${currentDate})</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle">#</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Data</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">PJ Médica</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Tomador</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle">Tipo</th>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr. Bruto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Imp. Retidos</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Outras Retenções</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr. Líquido</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Nota Fiscal</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">PDF</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">XML</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      if (controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").receivablesLoaded) {
        controller.doDataTable()
      } else {
        controller.getReceivables()
      }
    })
  }

  doDataTable() {
    var current_receivables = []

    this.application.receivables = []
    this.application.canceled_receivables = []

    this.application.all_receivables.forEach(element => {
      if (element.invoice_status == "canceled" || element.invoice_status == "substituted") {
        this.application.canceled_receivables[this.application.canceled_receivables.length] = element
      } else {
        this.application.receivables[this.application.receivables.length] = element
      }
    })

    current_receivables = this.application.receivables

    if (this.sort.mode == `asc`) {
      var receivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_receivables, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var receivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_receivables, this.sort.field)
    }

    if (receivables.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(receivables.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (receivables[x] !== undefined) {
            internPage[internPage.length] = receivables[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: receivables.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Recibos / Notas Fiscais</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.receivableTablePartial(element, data.length))
      });
    }
  }

  receivableTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_manage && element.invoice_status != `emitted`) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">delete</span>
                      <span class="mc-tooltiptext">Apagar</span>
                    </button>`
    } else if (this.application.permissions[this.permission].can_delete && element.invoice_status != `emitted` && element.integration_job == `error`) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else if (this.application.current_user.super_admin && element.invoice_status != `emitted`) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else {
      var destroy = ``
    }

    // var destroy = ``

    if (element.main_invoice.has_pdf) {
      var mainInvoicePDF = `<span class="mc-tooltip pointer">
                              <span class="material-icons md-200 md-success" data-kind="pdf" data-obj-id="${element.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">launch</span>
                              <span class="mc-tooltiptext">Download PDF</span>
                            </span>`
    } else {
      // var mainInvoicePDF = `<span class="mc-tooltip badge badge-danger">
      //                         <span class="material-icons md-200 md-white default">link_off</span>
      //                         <span class="mc-tooltiptext">Não há Arquivo</span>
      //                       </span>`

      var mainInvoicePDF = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">link_off</span>
                              <span class="mc-tooltiptext">Não há Arquivo</span>
                            </span>`
    }

    if (element.main_invoice.has_xml && element.invoice_status != `canceled`) {
      var mainInvoiceXML = `<span class="mc-tooltip pointer">
                              <span class="material-icons md-200 md-success" data-kind="xml" data-obj-id="${element.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">launch</span>
                              <span class="mc-tooltiptext">Download XML</span>
                            </span>`
    } else {
      var mainInvoiceXML = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">link_off</span>
                              <span class="mc-tooltiptext">Não há Arquivo</span>
                            </span>`
    }

    if (element.legal) {
      var legal = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Pago</span></span>`
    } else {
      var legal = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">Não Pago</span></span>`
    }

    if (element.operational) {
      var operational = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Pago</span></span>`
    } else {
      var operational = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">Não Pago</span></span>`
    }

    if (element.receivement_status == `received`) {
      var receivedStatus = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">price_check</span>
                      <span class="mc-tooltiptext">Recebida</span>
                    </span>`
    } else if (element.receivement_status == `not_received`) {
      var receivedStatus = `<span class="mc-tooltip badge badge-danger">
                              <span class="material-icons md-200 md-white pointer" data-action="click->${this.controllerName}#updateStatus">money_off_csred</span>
                              <span class="mc-tooltiptext">Não Recebida</span>
                            </span>`
    }

    if (element.integration_job == `waiting`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-primary default">hourglass_empty</span>
                          <span class="mc-tooltiptext">Aguardando Início da Automação</span>
                        </span>`
    } else if (element.integration_job == `in_process`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">autorenew</span>
                          <span class="mc-tooltiptext">Automatização Em Processo</span>
                        </span>`
    } else if (element.integration_job == `finished`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">sync_disabled</span>
                          <span class="mc-tooltiptext">Automatização Finalizada</span>
                        </span>`
    } else if (element.integration_job == `none`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">thumb_up_off_alt</span>
                          <span class="mc-tooltiptext">Emissão Manual</span>
                        </span>`
    } else if (element.integration_job == `error`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">sync_problem</span>
                          <span class="mc-tooltiptext">Erro na Emissão</span>
                        </span>`
    }

    if (element.invoice_status == `not_transmitted`) {
      var invoice = `<span class="mc-tooltip badge badge-warning">
                      <span class="material-icons md-200 md-white default">portable_wifi_off</span>
                      <span class="mc-tooltiptext">Não Transmitida</span>
                    </span>`
    } else if (element.invoice_status == `transmitted`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">podcasts</span>
                      <span class="mc-tooltiptext">Transmitida</span>
                    </span>`
    } else if (element.invoice_status == `in_process`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">hourglass_bottom</span>
                      <span class="mc-tooltiptext">Em Processo</span>
                    </span>`
    } else if (element.invoice_status == `emitted`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">done_all</span>
                      <span class="mc-tooltiptext">Emitida</span>
                    </span>`
    } else if (element.invoice_status == `not_emitted`) {
      var invoice = `<span class="mc-tooltip badge badge-danger">
                      <span class="material-icons md-200 md-white default">clear</span>
                      <span class="mc-tooltiptext">Não Emitida</span>
                    </span>`
    } else if (element.invoice_status == `uploaded_invoice`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">done_all</span>
                      <span class="mc-tooltiptext">Emitida Manualmente</span>
                    </span>`
    } else if (element.invoice_status == `canceled`) {
      var receivedStatus = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">clear</span>
                              <span class="mc-tooltiptext">NF Cancelada</span>
                            </span>`
      var invoice = `<span class="mc-tooltip">
                      <span class="material-icons md-200 md-danger default">clear</span>
                      <span class="mc-tooltiptext">NF Cancelada</span>
                    </span>`
    }

    if (element.main_invoice.invoice_number_pretty) {
      var invoiceNumber = element.main_invoice.invoice_number_pretty
    } else {
      var invoiceNumber = `S/N`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-company-name="${element.provider_name}" data-taker-name="${element.taker_name}" data-amount="${element.total_amount}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">
                      <span class="mb-0 mc-tooltip pointer">
                        <span>${invoiceNumber}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">${element.issued_on_full_pretty}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">
                      <span class="mb-0 mc-tooltip pointer">
                        <span>${element.issued_on_pretty}</span>
                        <span class="mc-tooltiptext">${element.issued_on_full_pretty}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">${element.provider_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">${element.taker_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.taker_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_tax_withheld)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.others_amount_withheld)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.net_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${invoice}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${jobService}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${mainInvoicePDF}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${mainInvoiceXML}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${destroy}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 15)

    var date = new Date()
    // var data = { receivable: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() - 7 }, current_user: { current_user_id: this.application.current_user.id } }
    var data = { receivable: { days_before: this.days }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/operations/products/clinics/financials/receivables/list_by_range"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.all_receivables = response.data.cln
          controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").receivablesLoaded = true
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
