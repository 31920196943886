import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "processNameFilter", "processWhomFilter", "performerFilter", "dropdown", "dropdownBtn",
    "fromFilter", "toFilter", "processNameFilterInput", "processWhomFilterInput", "performerFilterInput"]

  connect() {
    this.controllerName = `users--tasks--board--filter`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    this.fetchAllUsers()
  }

  disconnect() {
  }

  fetchAllUsers() {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/list_team"
    const data = { user: { account_kind: "team" }, current_user: { current_user_id: currentUser.id } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        allUsers = response.data.cln
      })
  }

  filter() {
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "stage") {
      var controller = document.querySelector('#stageContentIndexRow').usersTasksBoardStage
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "calendar") {
      var controller = document.querySelector('#calendarContentIndexRow').usersTasksBoardCalendar
    }
    var filterCollection = []
    var filterCollectionFrom = []
    var filterCollectionTo = []
    var filterCollectionName = []
    var filterCollectionWhom = []
    var filterCollectionPerformer = []
    var fromFilterValue = Date.parse(this.translateMonth(this.fromFilterTarget.value))
    var toFilterValue = Date.parse(this.translateMonth(this.toFilterTarget.value));
    var processNameFilterValue = this.processNameFilterInputTarget.value
    var processWhomFilterValue = this.processWhomFilterInputTarget.value
    var performerFilterValue = this.performerFilterInputTarget.value
    
    controller.cleanTasks()
    
    if (isNaN(fromFilterValue) == false) {
      allBoardTasks.forEach(element => {
        var dueDateFilter = Date.parse(this.translateMonth(element.due_date_pretty))
        if (dueDateFilter >= fromFilterValue) {
          filterCollectionFrom[filterCollectionFrom.length] = element
        }
      })
    } else {
      filterCollectionFrom = allBoardTasks
    }

    if (isNaN(toFilterValue) == false) {
      filterCollectionFrom.forEach(element => {
        var dueDateFilter = Date.parse(this.translateMonth(element.due_date_pretty))
        if (dueDateFilter <= toFilterValue) {
          filterCollectionTo[filterCollectionTo.length] = element
        }
      })
    } else {
      filterCollectionTo = filterCollectionFrom
    }
    if (processNameFilterValue) {
      filterCollectionTo.forEach(element => {
        if (element.process_name == processNameFilterValue) {
          filterCollectionName[filterCollectionName.length] = element
        }
      })
    } else {
      filterCollectionName = filterCollectionTo
    }

    if (processWhomFilterValue) {
      filterCollectionName.forEach(element => {
        if (element.process_whom == processWhomFilterValue) {
          filterCollectionWhom[filterCollectionWhom.length] = element
        }
      })
    } else {
      filterCollectionWhom = filterCollectionName
    }

    if (performerFilterValue) {
      filterCollectionWhom.forEach(element => {
        if (element.performer_name == performerFilterValue) {
          filterCollectionPerformer[filterCollectionPerformer.length] = element
        }
      })
    } else {
      filterCollectionPerformer = filterCollectionWhom
    }

    filterCollection = filterCollectionPerformer
    controller.listTasks(filterCollection)
    this.cleanDropdownFilter()
    
  }

  doDropdownFilter() {
    const html = `<div class="dropdown">
                    <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" id="cardFilterPaperBtn" data-toggle="dropdown" type="button" data-${this.controllerName}-target="dropdownBtn"><i class="material-icons">filter_list</i></button>
                    <div aria-labelledby="cardFilterPaperBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterPaperDiv">
                      <span class="default dropdown-item py-1">Prazo de Entrega</span>
                      <div class="row my-2">
                        <div class="col px-2">
                          <div class="floating-label floating-label-sm">
                            <label for="fromDueDate">De:</label>
                            <input class="form-control form-valid-control text-center" id="fromDueDate" placeholder="Data Início" type="date" required data-${this.controllerName}-target="fromFilter">
                          </div>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col px-2">
                          <div class="floating-label floating-label-sm">
                            <label for="toDueDate">Até:</label>
                            <input class="form-control form-valid-control text-center" id="toDueDate" placeholder="Data Final" type="date" required data-${this.controllerName}-target="toFilter">
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col px-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="processNameFilter">Tipo de Processo</label>
                              <input class="form-control input-filter" id="processNameFilter" placeholder="Tipo de Processo" type="text" filter-mode="simple" required data-${this.controllerName}-target="processNameFilterInput">
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="processNameFilter"></ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col px-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="processWhomFilter">Qual Processo</label>
                              <input class="form-control input-filter" id="processWhomFilter" placeholder="Qual Processo" type="text" filter-mode="simple" required data-${this.controllerName}-target="processWhomFilterInput">
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="processWhomFilter"></ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col px-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="performerFilter">Executante</label>
                              <input class="form-control input-filter" id="performerFilter" placeholder="Executante" type="text" filter-mode="simple" required data-${this.controllerName}-target="performerFilterInput">
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="performerFilter"></ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col px-2 text-center">
                          <button type="button" class="btn btn-primary btn-sm btn-table" id="btnFilterPapersList" data-action="click->${this.controllerName}#filter">Filtrar</button>
                        </div>
                      </div>
                    </div>
                  </div>`
    var thisController = this
    new Promise(function (resolve) {
      resolve(thisController.dropdownTarget.innerHTML = html)
    }).then(() => {
      this.fetchUsersFilter()
      this.fetchProcessNameFilter()
      this.fetchProcessWhomFilter()
      pickDateStimulus($(`#fromDueDate`))
      pickDateStimulus($(`#toDueDate`))
      filterInput()
    })
  }

  fetchUsersFilter() {
    var html = ``
    allUsers.forEach(user => {
      html += `<li data-id="${user.id}" class="li-filter dark" data-action="mousedown->${this.controllerName}#preventDefault">${user.nickname}</li>`
    });
    this.performerFilterTarget.innerHTML = html
  }

  fetchProcessNameFilter() {
    var html = ``
    allProcessName.forEach(process => {
      html += `<li class="li-filter dark" data-action="mousedown->${this.controllerName}#preventDefault">${process}</li>`
    });
    this.processNameFilterTarget.innerHTML = html
  }

  fetchProcessWhomFilter() {
    var html = ``
    allProcessWhom.forEach(process => {
      html += `<li class="li-filter dark" data-action="mousedown->${this.controllerName}#preventDefault">${process}</li>`
    });
    this.processWhomFilterTarget.innerHTML = html
  }

  preventDefault(e) {
    
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  fetchAllLabels() {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/labels/list"
    const data = {}
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(data => {
        allLabels = data.cln
      })
  }

  translateMonth(date) {
    const monthPT = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
    const monthEN = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    date = date.replace(date.split("/")[1], monthEN[monthPT.indexOf(date.split("/")[1])]) 
    return date
  }

  cleanDropdownFilter() {
    this.fromFilterTarget.value = ""
    this.toFilterTarget.value = ""
    this.processNameFilterInputTarget.value = ""
    this.processWhomFilterInputTarget.value = ""
    this.performerFilterInputTarget.value = ""
    floatingLabel()
  }

  
}