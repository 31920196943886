import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--helpers--boolean`
  }

  transformStringToBoolean(string) {
    
    if (string == true || string == `true`) {
      return true
    } else if (string == false || string == `false`) {
      return false
    }

  }

}