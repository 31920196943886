import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPayableCard", "viewTitle", "viewPayableCardBody", "previewCard", "editPayableBtn", "deletePayableBtn", "previewTitle", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--entities--show`
    // this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPayableCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Pagamento Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewPayableCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--entities--dashboard").viewTarget.innerHTML = html
  }

  closeShow() {
    this.doViewHtml()
  }

  editPayable() {
    if (this.application.current_date.open) {
      if (this.checkPayable()) {
        this.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = this.current_payable
        this.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 2000)
    }
  }

  deletePayable() {
    if (this.checkPayable()) {
      this.send_data = { current_user: {}, payable: {} }

      this.send_data.payable.id = this.current_payable.id
      this.send_data.payable.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_payable_entities`

      var r = confirm("Tem certeza que deseja Apagar a Conta a Pagar?")
      if (r) {
        this.requestDestroy()
      }

    }
  }

  doPayableHtml() {
    this.getControllerByIdentifier(`financials--books--payables--entities--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    var closeBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-action="click->${this.controllerName}#closeShow" type="button">
                            <span class="material-icons f-1p25">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>`

    if (this.application.permissions.financial_payable_entities.can_delete && this.application.current_date.open && this.current_payable.paid == false) {
      var deleteBtnHtml = `<span class="mc-tooltip">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="deletePayableBtn" data-action="click->${this.controllerName}#deletePayable" type="button">
                              <span class="material-icons f-1p25">delete</span>
                            </button>
                            <span class="mc-tooltiptext">Apagar Despesa</span>
                          </span>`
    } else {
      var deleteBtnHtml = ``
    }

    var deleteBtnHtml = `<span class="mc-tooltip">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="deletePayableBtn" data-action="click->${this.controllerName}#deletePayable" type="button">
                              <span class="material-icons f-1p25">delete</span>
                            </button>
                            <span class="mc-tooltiptext">Apagar Despesa</span>
                          </span>`

    if (this.application.permissions.financial_payable_entities.can_update && this.application.current_date.open && this.current_payable.paid == false) {
      var editBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="editPayableBtn" data-action="click->${this.controllerName}#editPayable" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Despesa</span>
                        </span>`
    } else {
      var editBtnHtml = ``
    }

    if (this.current_payable.method == `bank_slip`) {
      var methodTitle = `Boleto`
      var methodDescription = this.current_payable.bank_line
    } else if (this.current_payable.method == `transfer`) {
      var methodTitle = `Transferência`

      var methodDescription = this.current_payable.bank_account
    } else if (this.current_payable.method == `pix`) {
      var methodTitle = `Chave Pix`
      var methodDescription = this.current_payable.pix_key
    } else if (this.current_payable.method == `credit_card`) {
      var methodTitle = `Cartão Crédito`
      var splitAmount = Number(this.current_payable.amount) / Number(this.current_payable.installment)
      var methodDescription = `${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.amount)} em ${this.current_payable.installment}x de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(splitAmount)}`
    }

    var clientsHtml = ``
    // this.application.partners.forEach(element => {
    //   if (this.current_payable.partner_ids.includes(element.id.toString())) {
    //     clientsHtml += `<h6 class="mb-0 f-065" data-${this.controllerName}-target="partnerName-${element.id}">${element.name}</h6>`
    //   }
    // })

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${editBtnHtml}
                      ${deleteBtnHtml}
                      ${closeBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Lançamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.accrual_date_pretty} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Vencimento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.due_date_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Principal</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.amount)}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Total</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.total_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Descrição</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.description}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Conta</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.chart_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Fornecedor</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.provider_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Canal</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.channel_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${methodTitle}</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${methodDescription}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Sócio(s)</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${clientsHtml}
                              </td>
                            </tr>
                            

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--entities--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `${controller.current_payable.description}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  checkPayable() {
    if (this.current_payable.token_tree == null && this.current_payable.token_tree == undefined) {
      return true
    } else {
      if (this.current_payable.token_tree[0] == `r`) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Despesa em questão não pode ser editada!`, 5000)
      } else if (this.current_payable.token_tree[0] == `t`) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Despesa deve ser atualizado/editado na área de Repasses!`, 5000)
      }
      return false
    }
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var url = "/financials/books/payables/entities/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--save`).stopRefreshing()
          var payable = response.data.cln
          controller.application.payables.forEach((element, i) => {
            if (element.id == payable.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
            }
          })

          controller.doViewHtml()
          controller.getControllerByIdentifier("financials--books--payables--entities--index").doDataTable()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
