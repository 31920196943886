import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "numberInput", "branchInput", "accountInput", "pixInput", "saveBtn"]


  connect() {
    this.controllerName = `operations--products--clinics--managements--agents--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--managements--agents--show").doViewHtml()
  }

  saveAgent() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, agent: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.agent.id = this.current_agent.id
    }
    this.send_data.agent.clinic_id = this.application.clinic.id
    this.send_data.agent.account_name = this.nameInputTarget.innerText
    this.send_data.agent.account_id = this.nameInputTarget.dataset.accountId
    this.send_data.agent.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.agent.kind = this.kindInputTarget.dataset.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_agents`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/clinics/managements/agents/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/clinics/managements/agents/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var agent = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.agents[controller.application.agents.length] = agent
          } else if (this.actionMode == `edit`) {
            controller.application.agents.forEach((element, i) => {
              if (element.id == agent.id) {
                controller.application.agents.splice(controller.application.agents.indexOf(element), 1, agent)
              }
            })
          }

          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--clinics--managements--agents--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medclinic_management_agents.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveAgent" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }
    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveAgent" data-${this.controllerName}-target="saveBtn">Salvar</button>`

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Time</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="nameDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="nameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="nameInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="nameList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Função</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList">
                                  <li data-product-kind="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>
                                  <li data-product-kind="financial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contábil/Fiscal</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="primary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Primário</li>
                                  <li data-kind="secondary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Secundário</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--agents--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Agente`

        // controller.productKindDropdownBtnTarget.disabled = true
        // controller.productKindDropdownTarget.value = `regularization`
        // controller.productKindInputTarget.innerText = `Regularização`
        // controller.productKindInputTarget.dataset.status = `regularization`


      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Agente`

        controller.nameDropdownTarget.value = controller.current_agent.account_name
        controller.nameInputTarget.innerText = controller.current_agent.account_name
        controller.nameInputTarget.dataset.name = controller.current_agent.account_name
        controller.nameInputTarget.dataset.id = controller.current_agent.account_id

        controller.productKindDropdownTarget.value = controller.current_agent.product_kind
        controller.productKindInputTarget.innerText = controller.current_agent.product_kind_pretty
        controller.productKindInputTarget.dataset.productKind = controller.current_agent.product_kind

        controller.kindDropdownTarget.value = controller.current_agent.kind
        controller.kindInputTarget.innerText = controller.current_agent.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_agent.kind

        // controller.kindDropdownTarget.value = controller.current_agent.kind
        // controller.kindInputTarget.innerText = controller.current_agent.kind_pretty
        // controller.kindInputTarget.dataset.kind = controller.current_agent.kind

      }

      controller.setAccountList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  setAccountList() {
    var html = ``

    this.application.team_list.forEach(element => {
      html += `<li data-account-id="${element.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
    })

    this.nameListTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.nameInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.productKindInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }
        
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
