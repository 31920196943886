import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "ticket", "addJourney", "save", "5555", "summary", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--journey--summary`
  }

  doSummaryHTML() {
    if (this.last_journey.status == `awaiting_transmission`) {
      var summary = {}
      this.application.summaries.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          summary = element
        }
      })

      if (summary.statement == `to_pay`) {
        if (summary.form == `debt`) {
          var info = `<p class="mb-2 f-065">Débito em Conta</p>
                      <p class="mb-2 f-065">Banco: ${summary.bank_code}</p>
                      <p class="mb-2 f-065">Agência: ${summary.branch_code}</p>
                      <p class="mb-2 f-065">Conta: ${summary.account_number}</p>
                      <p class="mb-2 f-065">Parcelas: ${summary.installments}</p>`
        } else {
          var info = `<p class="mb-2 f-065">DARF</p>
                      <p class="mb-2 f-065">Parcelas: ${summary.installments}</p>`
        }
        
      } else if (summary.statement == `to_refund`) {
        if (summary.form == `debt`) {
          var info = `<p class="mb-2 f-065">Débito em Conta</p>
                      <p class="mb-2 f-065">Banco: ${summary.bank_code}</p>
                      <p class="mb-2 f-065">Agência: ${summary.branch_code}</p>
                      <p class="mb-2 f-065">Conta: ${summary.account_number}</p>`  
        } else if (summary.form == `pix`) {
          var info = `<p class="mb-2 f-065">PIX</p>
                      <p class="mb-2 f-065">Chave: CPF</p>
                      <p class="mb-2 f-065">
                        Códgio PIX:
                        <span class="mb-0 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${summary.account_number}" data-action="click->app--helpers--copy#copy">${summary.account_number_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                        </span>
                      </p>`  
        }
        
      } else if (summary.statement == `do_nothing`) {
        var info = ``
      }


      var html = `<div class="row my-2 w-100" data-id="${summary.id}" data-${this.controllerName}-target="card-${summary.id}">
                      <div class="col-12 px-1">
                        <div class="card">
                          <div class="card-body px-0 py-1 f-065 pointer">
                            <div class="row my-2">
                              <div class="col-8 px-1">
                                <div class="card-show-dropdown w-100">
                                  <h5 class="mb-3 f-075">Dados para ${summary.statement_pretty}</h5>
                                  ${info}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`
    } else {
      var html = ``
    }

    this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").summaryTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
