import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "asdf", "asdf"]

  connect() {
    this.controllerName = `users--works--notes--list-check-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 50
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `desc`, field: `created_at_to_time` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doIndexListHtml()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doIndexListHtml() {

    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshNotes" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Anotações</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Anotações</h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Data</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-80 align-middle">Check</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.dashboardController.listTarget.innerHTML = html)
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(2, 8))

      controller.doDataTable()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  doDataTable() {
    this.current_notes = []
    this.application.notes.forEach(element => {

      if (element.domain_id == this.note_board.domain_id) {
        this.current_notes[this.current_notes.length] = element
      }

    })

    var notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_notes, this.sort.field)
    
    if (notes.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {

      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(notes.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (notes[x] !== undefined) {
            internPage[internPage.length] = notes[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: notes.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Anotações para ${this.note_board.domain_name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.noteTablePartial(element, data.length))
      })
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  noteTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.chapter_pretty == undefined) { element.chapter_pretty = `Não Definido` }
    if (element.term_pretty == undefined) { element.term_pretty = `Não Definido` }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer trix-content">${element.body}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("users--works--notes--save").stopRefreshing()
    this.getControllerByIdentifier("users--works--notes--show").doNoteViewHtml()

    this.doDataTable()
  }

  refreshNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { note: { domain_ids: this.application.event_ids, domain_type: `marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_notes` } }
    const url = "/users/accounts/notes/list_check_by_domains"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}