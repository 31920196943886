import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "masterNameInput", "groupInput", "saveBtn",
                    "masterGroupInput", "masterInput", "accountingIdInput", "masterCheckbox",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "masterDropdown", "masterDropdownBtn", "masterInput", "masterList",
                    "masterGroupDropdown", "masterGroupDropdownBtn", "masterGroupInput", "masterGroupList",
                    "groupDropdown", "groupDropdownBtn", "groupInput", "groupList",
                    "masterNameDropdown", "masterNameDropdownBtn", "masterNameInput", "masterNameList",
                    "tokenInput"]


  connect() {
    this.controllerName = `operations--products--clinics--managements--chart-accounts--save`
    this.send_data = { current_user: {}, chart_account: {} }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--managements--chart-accounts--index").doIndexListHtml()
  }

  saveChartAccount() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.chart_account.id = this.current_chart_account.id
    } else if (this.actionMode == `new`) {
      this.send_data.chart_account.master_name = this.chart_account.master_name
      this.send_data.chart_account.group = this.chart_account.group
      this.send_data.chart_account.master_group = this.chart_account.master_group
      this.send_data.chart_account.master = this.chart_account.master
      this.send_data.chart_account.kind = this.chart_account.kind
      this.send_data.chart_account.code = this.chart_account.code
      this.send_data.chart_account.deductibility = 1
    }
    
    this.send_data.chart_account.name = this.nameInputTarget.value.trim()
    this.send_data.chart_account.accounting_id = this.accountingIdInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_chart_accounts`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/products/clinics/financials/chart_accounts/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/products/clinics/financials/chart_accounts/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var chart_account = response.data.cln
          controller.stopRefreshing()
          if (controller.actionMode == `new`) {
            controller.application.chart_accounts[controller.application.chart_accounts.length] = chart_account
          } else if (controller.actionMode == `edit`) {
            controller.application.chart_accounts.forEach((element, i) => {
              if (element.id == chart_account.id) {
                controller.application.chart_accounts.splice(controller.application.chart_accounts.indexOf(element), 1, chart_account)
              }
            })
          }
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medclinic_management_chart_accounts.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveChartAccount" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-8 offset-2 my-1">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row my-3">
                          <div class="col-8">
                            <span class="f-070">Selecione o Tipo de Conta</span>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="assetAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="asset" data-number="1" data-kind="balance" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="assetAccountCheck">Ativo</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="liabilityAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="liability" data-number="2" data-kind="balance" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="liabilityAccountCheck">Passivo</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="revenuesAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="revenues" data-number="3" data-kind="statement" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="revenuesAccountCheck">Receita</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="expensesAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="expenses" data-number="4" data-kind="statement" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="expensesAccountCheck">Despesas</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="resultAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="result" data-number="5" data-kind="statement" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="resultAccountCheck">Resultado</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="taxesAccountCheck" data-${this.controllerName}-target="masterCheckbox" data-type="taxes" data-number="6" data-kind="statement" data-action="click->${this.controllerName}#setMasterGroup">
                              <label class="custom-control-label f-065 pointer" for="taxesAccountCheck">Impostos</label>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-4 my-1">
                            <span class="f-070">Selecione a Categoria</span>
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Categoria</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="masterGroupDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="masterGroupDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="masterGroupInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="masterGroupList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 my-1">
                            <span class="f-070">Selecione o Grupo</span>
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Grupo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="groupDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="groupDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="groupInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="groupList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 my-1">
                            <span class="f-070">Selecione o Tipo</span>
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="masterNameDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="masterNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="masterNameInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="masterNameList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-4 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="tokenForm">Código</label>
                                <input aria-describedby="tokenFormHelp" class="form-control" id="tokenForm" data-${this.controllerName}-target="tokenInput" placeholder="Código" type="text" required disabled>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accountingForm">Parametrização Contábil</label>
                                <input aria-describedby="accountingFormHelp" class="form-control" id="accountingForm" data-${this.controllerName}-target="accountingIdInput" placeholder="Parametrização Contábil" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var html1 = `<div class="row">
                  <div class="col-8 offset-2 my-1">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row">
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accountingForm">Parametrização</label>
                                <input aria-describedby="accountingFormHelp" class="form-control" id="accountingForm" data-${this.controllerName}-target="accountingIdInput" placeholder="Parametrização" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="masterNameForm">Tipo</label>
                                <input disabled aria-describedby="masterNameFormHelp" class="form-control" id="masterNameForm" data-${this.controllerName}-target="masterNameInput" placeholder="Tipo" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="groupForm">Grupo</label>
                                <input disabled aria-describedby="groupFormHelp" class="form-control" id="groupForm" data-${this.controllerName}-target="groupInput" placeholder="Grupo" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="masterGroupForm">Categoria</label>
                                <input disabled aria-describedby="masterGroupFormHelp" class="form-control" id="masterGroupForm" data-${this.controllerName}-target="masterGroupInput" placeholder="Categoria" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Master</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="masterDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="masterDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="masterInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="masterList" data-app--helpers--search-target="searchList">
                                      <li data-master="asset" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                      <li data-master="liability" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Passivo</li>
                                      <li data-master="revenues" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Receitas</li>
                                      <li data-master="expenses" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Despesas</li>
                                      <li data-master="result" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Resultado</li>
                                      <li data-master="taxes" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Impostos</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 my-1">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Classificação</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="statement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Resultado</li>
                                      <li data-kind="balance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Patrimonial</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--chart-accounts--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.55) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Plano de Contas`

        this.masterCheckboxTargets.forEach(element => {
          if (element.dataset.type == controller.current_chart_account.master) {
            element.checked = true
          }
          element.disabled = true
        });

        controller.masterGroupInputTarget.innerText = controller.current_chart_account.master_group_pretty
        controller.masterGroupDropdownTarget.value = controller.current_chart_account.master_group
        controller.masterGroupDropdownBtnTarget.disabled = true

        controller.masterNameInputTarget.innerText = controller.current_chart_account.master_name_pretty
        controller.masterNameDropdownTarget.value = controller.current_chart_account.master_name
        controller.masterNameDropdownBtnTarget.disabled = true

        controller.groupInputTarget.innerText = controller.current_chart_account.group_pretty
        controller.groupDropdownTarget.value = controller.current_chart_account.group
        controller.groupDropdownBtnTarget.disabled = true

        controller.tokenInputTarget.value = controller.current_chart_account.token
        controller.accountingIdInputTarget.value = controller.current_chart_account.accounting_id
        controller.nameInputTarget.value = controller.current_chart_account.name
        

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Conta`
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.chart_account = {}
    })
  }

  setMasterGroup(ev) {

    this.masterCheckboxTargets.forEach(element => {
      element.checked = false
    });
    ev.currentTarget.checked = true

    this.chart_account.master = ev.currentTarget.dataset.type
    this.chart_account.kind = ev.currentTarget.dataset.kind
    this.chart_account.token = ev.currentTarget.dataset.number.concat(".")
    this.chart_account.master_number = ev.currentTarget.dataset.number.concat(".")

    var items = []
    this.application.chart_accounts.forEach(element => {
      if (element.master == this.chart_account.master) {
        items[items.length] = {pretty: element.master_group_pretty, kind: element.master_group, number: element.master_group_number}
      }
    });
    items = items.filter((value, index, self) => self.map(x => x.kind).indexOf(value.kind) == index)

    var html = ``
    items.forEach(element => {
      html += `<li data-kind="${element.kind}" data-number="${element.number}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setGroup" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.pretty}</li>`
    });

    this.masterGroupListTarget.innerHTML = html
  }

  setGroup(ev) {

    this.chart_account.master_group = ev.currentTarget.dataset.kind
    this.chart_account.master_group_number = ev.currentTarget.dataset.number.concat(".")
    this.chart_account.token = this.chart_account.token.concat(ev.currentTarget.dataset.number.concat("."))

    var items = []
    this.application.chart_accounts.forEach(element => {
      if (element.master == this.chart_account.master && element.master_group == this.chart_account.master_group) {
        items[items.length] = {pretty: element.group_pretty, kind: element.group, number: element.group_number}
      }
    });
    items = items.filter((value, index, self) => self.map(x => x.kind).indexOf(value.kind) == index)

    var html = ``
    items.forEach(element => {
      html += `<li data-kind="${element.kind}" data-number="${element.number}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setMasterName" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.pretty}</li>`
    });

    this.groupListTarget.innerHTML = html
  }

  setMasterName(ev) {

    this.chart_account.group = ev.currentTarget.dataset.kind
    this.chart_account.group_number = ev.currentTarget.dataset.number.concat(".")
    this.chart_account.token = this.chart_account.token.concat(ev.currentTarget.dataset.number.concat("."))

    var items = []
    this.application.chart_accounts.forEach(element => {
      if (element.master == this.chart_account.master && element.master_group == this.chart_account.master_group && element.group == this.chart_account.group) {
        items[items.length] = {pretty: element.master_name_pretty, kind: element.master_name, number: element.master_name_number}
      }
    });
    items = items.filter((value, index, self) => self.map(x => x.kind).indexOf(value.kind) == index)

    var html = ``
    items.forEach(element => {
      html += `<li data-kind="${element.kind}" data-number="${element.number}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setName" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.pretty}</li>`
    });

    this.masterNameListTarget.innerHTML = html
  }

  setName(ev ) {

    this.chart_account.master_name = ev.currentTarget.dataset.kind
    this.chart_account.master_name_number = ev.currentTarget.dataset.number.concat(".")
    this.chart_account.token = this.chart_account.token.concat(ev.currentTarget.dataset.number.concat("."))

    var items = []
    this.application.chart_accounts.forEach(element => {
      if (element.master == this.chart_account.master && element.master_group == this.chart_account.master_group && element.group == this.chart_account.group && element.master_name == this.chart_account.master_name) {
        items[items.length] = { pretty: element.master_name_pretty, kind: element.master_name, number: element.master_name_number }
      }
    });
    
    if (items.length < 10) {
      this.chart_account.code = `0${items.length + 1}`
    } else {
      this.chart_account.code = `${items.length + 1}`
    }


    this.chart_account.token = this.chart_account.master_number.concat(this.chart_account.master_group_number).concat(this.chart_account.group_number).concat(this.chart_account.master_name_number).concat(this.chart_account.code)
    // this.chart_account.token = this.chart_account.token.concat(this.chart_account.code)
    this.tokenInputTarget.value = this.chart_account.token

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }


  setList(kind, element, items) {

    var html = ``
    items.forEach(element => {
      html += `<li data-kind="${element.kind}" data-number="${element.number}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setGroup" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.pretty}</li>`
    });

    element.innerHTML = html
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this

    if (this.hasSaveBtnTarget) {      
      this.refreshTimer = setInterval(function () {
        var len = 0
        
        if (controller.hasAccountingInputTarget && controller.accountingIdInputTarget.value == ``) {
          len += 1
        }
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }, 200);
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
