import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addBillingBtn", "pendencyCount", "sideBarReport", "billingCount",
                    "clearListBtn", "optionBtn", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--billings`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.tableCols = 12
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `created_at_iugu_to_time` }
  }

  doNoBilling() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewBillingCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhum Cliente Selecionado</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).listTarget.innerHTML = html
  }

  addBilling() {
    if (this.application.current_date.open) {
      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)

      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").controllerDashboard = this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`)
      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").gridElement = this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).viewTarget
      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").current_client = this.current_client
      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").current_billing = {}
      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--receivables--conciliations--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  checkForEditBilling() {
    if (this.application.current_date.open) {
      this.kind = `edit_contract`
      this.doDataTable()

      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  editBilling(ev) {
    var current_contract = {}
    var contractId = ev.currentTarget.dataset.id

    this.application.client_contracts.forEach(contract => {
      if (contract.id == contractId) {
        current_contract = contract
      }
    });

    this.getControllerByIdentifier("financials--books--receivables--conciliations--save").current_client = this.current_client
    this.getControllerByIdentifier("financials--books--receivables--conciliations--save").current_contract = current_contract
    this.getControllerByIdentifier("financials--books--receivables--conciliations--save").actionMode = "edit"
    this.getControllerByIdentifier("financials--books--receivables--conciliations--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doBillingList() {
    this.iugu_billings = this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).iugu_billings

    if (this.application.permissions.financial_receivable_contracts.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addBilling" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_receivable_contracts.can_update) {
      var editLine = `<span data-action="click->${this.controllerName}#checkForEditBilling" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Contrato</span>`
    } else {
      var editLine = ``
    }

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${editLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    submenuHtml = ``
    refreshBtnHtml = ``

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto help">
                          <span data-${this.controllerName}-target="billingCount">Todas as Faturas</span>
                        </h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-30 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                    Med
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Dt Vencimento</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Dt Pagamento</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Dt Compensação</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Ocorrência</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle text-center">Valor Faturado</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle text-center">Valor Pago</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center" colspan="2">Status</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Método</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))

      controller.doDataTable()
      // controller.getControllerByIdentifier("financials--books--receivables--conciliations--show").doNoBilling()
    })
  }

  doDataTable() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()

    var totalBillings = 0
    var conciliatedBillings = 0
    this.iugu_billings.forEach(element => {
      totalBillings += 1
      if (element.conciliated) {
        conciliatedBillings += 1
      }
    })

    this.billingCountTarget.innerText = `Todas as Faturas ${totalBillings}/${conciliatedBillings} Fatura(s) Conciliada(s)`

    if (this.sort.mode == `asc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.iugu_billings, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.iugu_billings, this.sort.field)
    }

    if (billings.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(billings.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (billings[x] !== undefined) {
            internPage[internPage.length] = billings[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: billings.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Faturas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.billingTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  billingTablePartial(element, length) {

    if (element.status == `paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Pago</span>`
    } else if (element.status == `partially_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Parcialmente</span>`
    } else if (element.status == `externally_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Externamente</span>`
    } else if (element.status == `partially_refunded`) {
      var status = `<span class="f-070 badge badge-secondary-success">Reembolsada Parcialmente</span>`
    } else if (element.status == `pending`) {
      var status = `<span class="f-070 badge badge-secondary-warning">Pendente</span>`
    } else if (element.status == `canceled`) {
      var status = `<span class="f-070 badge badge-secondary-danger">Cancelado</span>`
    } else if (element.status == `expired`) {
      var status = `<span class="f-070 badge badge-secondary-dark">Expirado</span>`
    } else if (element.status == `executed`) {
      var status = `<span class="f-070 badge badge-secondary-success">Liquidada</span>`
    } else {
      var status = `<span class="f-070 badge badge-secondary-danger default">Não Pago</span>`
    }

    if (element.late_payment) {
      var latePayment = `<span class="f-070 badge badge-secondary-danger default">Atrasado</span>`
    } else {
      var latePayment = `<span class="f-070 badge badge-secondary-success">Em Dia</span>`
    }

    if (element.conciliated) {
      var conciliated = `<span class="f-070 badge badge-secondary-success">Conciliação Ok</span>`
    } else {
      var conciliated = `<span class="f-070 badge badge-secondary-danger">Não Conciliado</span>`
    }

    if (element.payment_method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `pix`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">pix</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `bank_debt`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `cash`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else {
      var method = `Sem Pagamento`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editBilling">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    check = ``

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#concilationModal">${element.customer_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.due_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.occurrence_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.paid_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${latePayment}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${this.controllerNumber.currencyOptionMask(element.total_cents / 100)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${this.controllerNumber.currencyOptionMask(element.total_paid_cents / 100)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${conciliated}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getBillings() {
    if (this.hasBodyTableTarget) {
      this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    }

    const data = { billing: { active: true, client_id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_billings` } }
    const url = "/financials/books/clients/billings/list_by_client"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.client_billings = response.data.cln
        controller.doBillingList()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  concilationModal(ev) {
    // var element = ev.currentTarget.dataset.element
    var invoiceIdIugu = ev.currentTarget.closest(`.itemRow`).dataset.idIugu

    var html = `<div class="modal fade" data-controller="financials--books--receivables--conciliations--modal--main" data-financials--books--receivables--conciliations--modal--main-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--receivables--conciliations--modal--main-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).iugu_billings = controller.iugu_billings
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).invoice_id_iugu = invoiceIdIugu
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).open()
    })
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.iugu_billings = this.application.client_billings
    this.kind = false

    this.doDataTable()
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--show`).doNoBilling()
  }

  

  showAddons(billingId) {
    var current_billing = {}

    this.application.client_billings.forEach(billing => {
      if (billing.id == billingId) {
        current_billing = billing
      }
    })

    this.getControllerByIdentifier(`financials--books--clients--addons--list`).current_billing = current_billing
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).getAddons()

    this.iugu_billings = [current_billing]
    this.doDataTable()
  }

  refreshList() {
    this.refreshListBtnTarget.classList.add(`d-none`)
    this.iugu_billings = this.application.client_billings
    this.clearList()

    this.getControllerByIdentifier(`financials--books--receivables--conciliations--show`).doNoBilling()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
