
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "expenseCount", "expenseCountTooltip",
    "receivableCount", "receivableCountTooltip", "taxCount", "taxCountTooltip",
    "totalReceivables", "totalTaxes", "totalExpenses", "totalTickets"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--show--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--clinics--managements--show--dashboard").doClinicDashboard()
  }

  goToApurations() {
    this.getControllerByIdentifier("operations--products--clinics--managements--apurations--dashboard").doApurationsDashboard()
  }

  goToHelpers() {
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").contactPermission = `medclinic_management_helper_contacts`
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").infoPermission = `medclinic_management_helper_infos`
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").profilePermission = `medclinic_management_helper_profiles`
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").mainPermission = `medclinic_management_helpers`
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").doHelpersDashboard()
  }

  goToTransactions() {
    this.getControllerByIdentifier("operations--products--clinics--managements--transactions--dashboard").doTransactionsDashboard()
  }

  goToIntegrations() {
    this.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doIntegrationsDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier("operations--products--clinics--managements--regimes--dashboard").doRegimesDashboard()
  }

  goToBalances() {
    this.getControllerByIdentifier("operations--products--clinics--managements--balances--dashboard").doBalancesDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("operations--products--clinics--managements--tickets--dashboard").doTicketsDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--clinics--managements--notes--dashboard").doNotesDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--clinics--managements--notes--dashboard").doNotesDashboard()
  }

  goToDates() {
    this.getControllerByIdentifier("operations--products--clinics--managements--dates--dashboard").doDatesDashboard()
  }

  goToAgents() {
    this.getControllerByIdentifier("operations--products--clinics--managements--agents--dashboard").doAgentsDashboard()
  }

  // goToLogs() {
  //   this.getControllerByIdentifier("operations--products--clinics--managements--logs--dashboard").doLogsDashboard()
  // }

  goToLogs() {
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_filter = true
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_id = this.application.clinic.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_type = `operation_products`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_id = this.application.current_date.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_type = `operation_dates`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").permission = `medclinic_managements`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").gridElement = this.getControllerByIdentifier("operations--products--clinics--managements--show--main").contentTarget
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").title = `${this.application.clinic.name} ${this.application.current_date.name}`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").submenuController = this
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").noLogsMessage = `Não há Logs para a ${this.application.current_date.name}`

    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").doLogsDashboard()
  }

  goToEmails() {
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").date_filter = true
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").domain_id = this.application.clinic.id
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").domain_type = `operation_products`
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").permission = `medclinic_managements`
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").dashboardController = this.getControllerByIdentifier("operations--products--clinics--managements--show--main")
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").submenuController = this

    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").doEmailsDashboard()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`   

    if (this.application.permissions.medclinic_management_main_apurations.can_index) {
      var apurationBtn = `<button type="button" data-action="${this.controllerName}#goToApurations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Apurações</button>`
    } else {
      var apurationBtn = ``
    }

    if (this.application.permissions.medclinic_management_helpers.can_index) {
      var helperBtn = `<button type="button" data-action="${this.controllerName}#goToHelpers ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Assistentes</button>`
    } else {
      var helperBtn = ``
    }

    if (this.application.permissions.medclinic_management_integration_companies.can_index) {
      var integrationsBtn = `<button type="button" data-action="${this.controllerName}#goToIntegrations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Integrações</button>`
    } else {
      var integrationsBtn = ``
    }
    
    var regimeBtn = ``

    if (this.application.permissions.medclinic_management_transactions.can_index) {
      var transactionsBtn = `<button type="button" data-action="${this.controllerName}#goToTransactions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Transações</button>`
    } else {
      var transactionsBtn = ``
    }

    if (this.application.permissions.medclinic_management_dates.can_index) {
      var datesBtn = `<button type="button" data-action="${this.controllerName}#goToDates ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Competências</button>`
    } else {
      var datesBtn = ``
    }

    if (this.application.permissions.medclinic_management_balances.can_index) {
      var balancesBtn = `<button type="button" data-action="${this.controllerName}#goToBalances ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas de Balanço</button>`
    } else {
      var balancesBtn = ``
    }

    if (this.application.permissions.medclinic_management_regimes.can_index) {
      var regimesBtn = `<button type="button" data-action="${this.controllerName}#goToRegimes ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tributário</button>`
    } else {
      var regimesBtn = ``
    }

    if (this.application.permissions.medclinic_management_agents.can_index) {
      var agentBtn = `<button type="button" data-action="${this.controllerName}#goToAgents ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Agentes</button>`
    } else {
      var agentBtn = ``
    }

    if (this.application.permissions.medclinic_managements.can_manage) {
      var logsBtn = `<button type="button" data-action="${this.controllerName}#goToLogs ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Logs</button>`
    } else {
      var logsBtn = ``
    }

    if (this.application.permissions.medclinic_managements.can_manage) {
      var emailsBtn = `<button type="button" data-action="${this.controllerName}#goToEmails ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Emails</button>`
    } else {
      var emailsBtn = ``
    }

    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    if (this.application.permissions.medclinic_management_notes.can_index) {
      var noteBtn = `<button type="button" data-action="${this.controllerName}#goToNotes ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anotações</button>`
    } else {
      var noteBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${apurationBtn}
                  ${regimeBtn}
                  ${balancesBtn}
                  ${transactionsBtn}
                  ${regimesBtn}
                  ${datesBtn}
                  ${integrationsBtn}
                  ${helperBtn}
                  ${agentBtn}
                  ${logsBtn}
                  ${emailsBtn}
                  ${ticketBtn}
                  ${noteBtn}
                  
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doBadgeCounts()
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (this.hasExpenseCountTarget && element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (this.hasReceivableCountTarget && element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (this.hasTaxCountTarget && element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketInProcessCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
