import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "allowances", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payrolls--compilations--dashboard`
  }

  doCompilationsDashboard() {
    // `<div class="col-2 px-1" data-${this.controllerName}-target="resume" data-controller="financials--books--payrolls--compilations--resume"></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="allowances" data-controller="financials--books--payrolls--compilations--allowances"></div>
                  <div class="col-10" data-${this.controllerName}-target="list" data-controller="financials--books--payrolls--compilations--list"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payrolls--compilations--allowances`).permission = `financial_payroll_remunerations`
      controller.getControllerByIdentifier(`financials--books--payrolls--compilations--allowances`).doAllowanceList()

      controller.getControllerByIdentifier(`financials--books--payrolls--compilations--list`).permission = `financial_payroll_remunerations`
      controller.getControllerByIdentifier(`financials--books--payrolls--compilations--list`).doNoAllowanceHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
