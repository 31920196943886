import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    
  }

  doAccountsKindHtml() {
    var kindCard = ``

    this.application.account.kind_pretty.forEach((element, i) => {

      if (this.application.account.kind[i] == this.application.user.account_kind) {
        kindCard += `<div class="col-6 p-0 py-2 my-2">
                      <span class="f-bold pointer badge badge-secondary bg-shadow">${element}</span>
                    </div>`        
      } else {
        kindCard += `<div class="col-6 p-0 py-2 my-2">
                      <span class="f-bold pointer badge badge-secondary-dark bg-shadow" data-kind="${this.application.account.kind[i]}" data-action="click->users--profile--kind#saveUser">${element}</span>
                    </div>`
      }

    })

    var html = `<div class="row">
                  ${kindCard}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getAccount() {
    const data = { account: { id: this.application.user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `users` } }

    const url = "/users/accounts/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.account = response.data.cln
          this.getControllerByIdentifier("users--profile--kind").doAccountsKindHtml()
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  saveUser(ev) {
    this.send_data = { current_user: {}, user: {} }

    this.send_data.user.id = this.application.user.id
    this.send_data.user.account_kind = ev.currentTarget.dataset.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `users`

    this.requestSave()
  }

  requestSave() {

    var url = "/users/update"
    // var url = "/users/update_kind"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          window.open('/dashboard', '_self')
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
