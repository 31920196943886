import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--dashboard--dates`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doDatesPreloader()
  }

  changeDate(ev) {
    var id = ev.currentTarget.dataset.id
    this.setFinancialDate(id)
    this.toggleDateBtn(id)
  }

  setFinancialDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        this.application.current_due_date = element
        this.application.current_receivable_compilation = element.receivable_compilation
        this.application.current_regimes = element.regimes
      }
    })

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.getControllerByIdentifier("financials--books--receivables--dashboard--main").reload = true
    this.getControllerByIdentifier("financials--books--receivables--dashboard--main").loadDateData()
  }

  runDashboardsHtml() {
    this.cleanDashboardForms()
    this.runDashboardHtml()
    this.runContractHtml()
    this.runConciliationHtml()
    this.runBillingHtml()
    this.runTicketHtml()
    this.runReceivableHtml()
    this.runLiquidationHtml()
    this.runAnnualHtml()
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("financials--books--receivables--dashboard--main").hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--dashboard--main").doMainDashboard()
    }
  }

  runReceivableHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--entities--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--entities--dashboard").doReceivablesDashboard()
    }
  }

  runLiquidationHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--liquidations--dashboard").doLiquidationsDashboard()
    }
  }

  runContractHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--contracts--dashboard").doContractsDashboard()
    }
  }

  runConciliationHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--conciliations--dashboard").doConciliationsDashboard()
    }
  }

  runBillingHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--billings--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--billings--dashboard").doBillingsDashboard()
    }
  }

  runAnnualHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--annual--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--annual--dashboard").doReceivablesDashboard()
    }
  }

  runTicketHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--tickets--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--tickets--dashboard").doTicketsDashboard()
    }
  }

  cleanDashboardForms() {
  }

  setCurrents() {
  }

  doDatesHTML() {
    var datesHtml = ``
    var currentDates = []
    var today = new Date()

    this.application.dates.forEach(element => {
      // if (this.application.current_user.super_admin) {
      //   datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065 mc-tooltip" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">
      //                   ${element.date_pretty}
      //                   <span class="mc-tooltiptext mc-tooltiptext-bottom">ID ${element.id}</span>
      //                 </button>`
      // } else {
      //   datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
      // }
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    })

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)

      }
    });
  }

  doDatesPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
