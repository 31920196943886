import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "infoView", "infoCard", "receivableView", "receivableCard",
                    "actionView", "actionCard", "checkListDescriptionPartner", "apurationView", "taxesView",
                    "apurationCard", "taxesCard", "checkListActivity", "checkListDescriptionActivity",
                    "checkListBank", "checkListDescriptionBank", "invoiceBtn", "checkCanInvoice", "checkListPartner",
                    "checkListCertificate", "checkListDescriptionCertificate", "checkListIntegration", "checkListDescriptionIntegration", 
                    "regimeView", "regimeTitle"]

  connect() {
    this.spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--clinics--financials--show--dashboard`
  }

  doClinicDashboardPreloader() {
    // `<div class="col-2 card-body" data-${this.controllerName}-target="regimeView" style="overflow:auto;">`
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="actionView">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2" data-${this.controllerName}-target="infoView">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="infoCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-2" data-${this.controllerName}-target="receivableView">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="receivableCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2" data-${this.controllerName}-target="apurationView">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="apurationCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-2" data-${this.controllerName}-target="taxesView">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="taxesCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-2">
                      <div class="row mb-2 text-center d-flex justify-content-center">
                        <span class="f70" data-${this.controllerName}-target="regimeTitle">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="card-body py-1 px-1" style="overflow:auto;" data-${this.controllerName}-target="regimeView">
                        <div class="row mb-2">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                      </div>
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.infoCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.receivableCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.apurationCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.taxesCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.regimeViewTarget.style.height = ($(window).height() * 0.55) + "px"
    })

  }

  doClinicDashboard() {
    this.doClinicDashboardPreloader()
    this.doActionCard()
    this.doReceivableCard()
    this.doTakersCard()
    this.doRegimeCard()
    this.doApurationCard()
    this.doTaxCard()
  }

  goToReceivables() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).doReceivablesDashboard()
  }

  goToApurations() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--apurations--dashboard`).doApurationsDashboard()
  }

  goToTaxes() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--taxes--dashboard`).doTaxesDashboard()
  }

  goToExpenses() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--expenses--dashboard`).doExpensesDashboard()
  }

  goToTransfers() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--dashboard`).doTransfersDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--regimes--dashboard`).doRegimesDashboard()
  }

  emitReceivable() {
    if (this.canInvoice) {
      this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`).doAddsDashboard()
    }
  }

  doActionCard() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--check-invoice`).permission = `medclinic_financials`
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--check-invoice`).doCheckCard()
  }

  emitTakerReceivable(ev) {
    if (this.application.canReceivable) {
      var takerId = ev.currentTarget.closest(`.itemRow`).dataset.id
      var idType = ev.currentTarget.closest(`.itemRow`).dataset.idType

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`).doReceivablesDashboard())
      }).then(() => {
        setTimeout(() => {
          controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).actionMode = `edit`
          controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).kind = idType
          controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).reviewTaker(takerId)
        }, 200);
      })
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A PJ não está regularizada para emissão de Nota Fiscal`, 4000)
    }
  }

  doRegimeCard() {
    var html = ``
    var lastRegimes = []
    lastRegimes = this.application.regimes.slice(0, 4)
    lastRegimes = [this.application.current_regime]
    // lastRegimes = this.application.regimes
    lastRegimes.forEach(regime => {
      html += `<div class="row mb-2 w-100">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col-12 pointer f-bold" data-action="click->${this.controllerName}#goToRegimes">${regime.accrual_date_pretty}</div></div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row mb-2">
                          <div class="col-12 px-0 text-left">
                            ${regime.tax_regime_pretty}
                          </div>
                          <div class="col-12 px-0 text-left">
                            ${regime.sub_tax_regime_pretty}
                          </div>
                          <div class="col-12 px-0 text-left">
                            ${this.getControllerByIdentifier("app--helpers--numbers").percentMask(Math.round(regime.effective_total_tax * 100))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })


    this.regimeViewTarget.innerHTML = html
    this.regimeTitleTarget.innerHTML = `Últimos Regimes`
  }

  doTakersCard() {
    var takerHtml = ``

    var current_takers = []

    this.application.takers.forEach(taker => {
      if (taker.status) {
        current_takers[current_takers.length] = taker
      }
    })

    if (current_takers.length > 0) {
      current_takers.forEach(element => {
        if (current_takers.length == 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:25px;" data-id-type="${element.id_type}">`
        } else if (current_takers.length > 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}" data-id-type="${element.id_type}">`
        }

        takerHtml += `${tableRow}
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#emitTakerReceivable">
                        <span class="mc-tooltip">
                          <span>${element.name}</span>
                          <span class="mc-tooltiptext">Clique para Emitir NF</span>
                        </span>
                      </td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                        <span class="mc-tooltip">
                          <span data-controller="app--helpers--copy" data-copy="${element.id_number}" data-action="click->app--helpers--copy#copy">${element.id_number_pretty}</span>
                          <span class="mc-tooltiptext">Clique para copiar</span>
                        </span>
                      </td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${element.id_type_pretty}</td>
                    </tr>`

      })
    } else {
      takerHtml += `</tr>
                      <td colspan="3" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tomadores</span>
                      </td>
                    </tr>`
    }


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="infoCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col f-bold">Principais Tomadores de Serviço</div></div>  
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle">Tomador</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">ID</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Tipo</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${takerHtml}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>`

    this.infoViewTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.infoViewTarget.innerHTML = html)
    }).then(() => {
      controller.infoCardTarget.style.height = ($(window).height() * 0.3) + "px"
    })
  }

  doReceivableCard() {
    var receivableHtml = ``

    var current_receivables = []
    this.application.receivables.forEach(element => {
      if (element.date_id == this.application.current_date.id && element.invoice_status != `canceled`) {
        current_receivables[current_receivables.length] = element
      }
    })

    if (current_receivables.length > 0) {
      current_receivables.forEach(element => {
        if (current_receivables.length == 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:25px;">`
        } else if (current_receivables.length > 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}">`
        }

        receivableHtml += `${tableRow}
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${element.taker_name}</td>
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                              ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}
                            </td>
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                              ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.net_amount)}
                            </td>
                          </tr>`

      })
    } else {
      receivableHtml += `</tr>
                          <td colspan="3" class="p-3 align-middle text-center" style="font-size:100%;">
                            <span class="fa-stack fa-1x">
                              <i class="fas fa-list fa-stack-1x"></i>
                            </span>
                            <span class="f-075 align-middle">Não há Notas Fiscais emitidas na ${this.application.current_date.name}</span>
                          </td>
                        </tr>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="receivableCard">
                  <div class="card-header py-0">
                    <div class="row my-2"><div class="col pointer f-bold" data-action="click->${this.controllerName}#goToReceivables">Notas Fiscais | ${this.application.current_date.name}</div></div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <thead>
                        <tr>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle">Tomador</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Valor Bruto</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Valor Líquido</th>
                        </tr>
                      </thead>
                      <tbody>
                        ${receivableHtml}
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.receivableViewTarget.innerHTML = html)
    }).then(() => {
      controller.receivableCardTarget.style.height = ($(window).height() * 0.3) + "px"
    })
  }

  doApurationCard() {

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="apurationCard">
                  <div class="card-header py-0">
                    <div class="row my-2"><div class="col pointer f-bold" data-action="click->${this.controllerName}#goToApurations">Apuração | ${this.application.current_date.name}</div></div>
                  </div>
                  <div class="card-body py-0 px-2">
                    <div class="row my-3">
                      <div class="col-6">
                        <div class="" data-${this.controllerName}-target="revenueCard">
                          <div class="p-0">
                            <div class="row my-1 px-0"><div class="col-12 text-center px-0">Total de Receitas</div></div>
                          </div>
                          <div class="py-0 px-2 d-flex align-items-center justify-content-center f-1 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_main_apuration.total_revenues)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="" data-${this.controllerName}-target="revenueCard">
                          <div class="p-0">
                            <div class="row my-1 px-0"><div class="col-12 text-center px-0 pointer" data-action="click->${this.controllerName}#goToTaxes">Total de Impostos</div></div>
                          </div>
                          <div class="py-0 px-2 d-flex align-items-center justify-content-center f-1 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_main_apuration.total_due)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6">
                        <div class="" data-${this.controllerName}-target="revenueCard">
                          <div class="p-0">
                            <div class="row my-1 px-0"><div class="col-12 text-center px-0 pointer" data-action="click->${this.controllerName}#goToExpenses">Total de Despesas</div></div>
                          </div>
                          <div class="py-0 px-2 d-flex align-items-center justify-content-center f-1 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_main_apuration.total_expenses)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="" data-${this.controllerName}-target="revenueCard">
                          <div class="p-0">
                            <div class="row my-1 px-0"><div class="col-12 text-center px-0 pointer" data-action="click->${this.controllerName}#goToTransfers">Total Repasse Sócios</div></div>
                          </div>
                          <div class="py-0 px-2 d-flex align-items-center justify-content-center f-1 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_main_apuration.total_transfer)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.apurationViewTarget.innerHTML = html)
    }).then(() => {
      controller.apurationCardTarget.style.height = ($(window).height() * 0.3) + "px"
    })
  }

  doTaxCard() {
    var taxHtml = ``

    var current_taxes = []
    this.application.taxes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_taxes[current_taxes.length] = element
      }
    })

    if (current_taxes.length > 0) {
      current_taxes.forEach(element => {
        if (current_taxes.length == 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:25px;">`
        } else if (current_taxes.length > 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}">`
        }

        if (element.status == `paid`) {
          var paidStatus = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">price_check</span>
                      <span class="mc-tooltiptext">Pago dia ${element.paid_at_pretty}</span>
                    </span>`
        } else if (element.status == `not_paid`) {
          var paidStatus = `<span class="mc-tooltip badge badge-danger">
                              <span class="material-icons md-200 md-white">money_off_csred</span>
                              <span class="mc-tooltiptext">Não Pago</span>
                            </span>`
        }

        taxHtml += `${tableRow}
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${element.description}</td>
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                              ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}
                            </td>
                            <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${paidStatus}</td>
                          </tr>`

      })
    } else {
      taxHtml += `</tr>
                          <td colspan="3" class="p-3 align-middle text-center" style="font-size:100%;">
                            <span class="fa-stack fa-1x">
                              <i class="fas fa-list fa-stack-1x"></i>
                            </span>
                            <span class="f-075 align-middle">Não há Guias de Impostos na ${this.application.current_date.name}</span>
                          </td>
                        </tr>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="taxesCard">
                  <div class="card-header py-0">
                    <div class="row my-2"><div class="col pointer f-bold" data-action="click->${this.controllerName}#goToTaxes">Impostos | ${this.application.current_date.name}</div></div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <thead>
                        <tr>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle">Imposto</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Valor Total</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle"></th>
                        </tr>
                      </thead>
                      <tbody>
                        ${taxHtml}
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.taxesViewTarget.innerHTML = html)
    }).then(() => {
      controller.taxesCardTarget.style.height = ($(window).height() * 0.3) + "px"
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}