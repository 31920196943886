import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "days", "resume", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--communications--voips--calls--dashboard`
  }

  doCallsDashboard() {
    var controllerCallers = `app--communications--voips--dashboard--callers`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 ml-auto pt-3 pb-1" data-${this.controllerName}-target="days" data-controller="app--communications--voips--calls--days" style="overflow: auto;"></div>
                  <div class="col-12" data-${this.controllerName}-target="resume" data-controller="app--communications--voips--calls--resume"></div>
                  <div class="col-2" data-${this.controllerName}-target="callers" data-controller="app--communications--voips--dashboard--callers">
                    <h6 class="mb-0 text-center" data-${controllerCallers}-target="callersTitle"></h6>
                    <hr class="my-1">
                    <div class="row" data-${controllerCallers}-target="callersBoard"></div>
                  </div>
                  <div class="col-10 px-1" data-${this.controllerName}-target="list" data-controller="app--communications--voips--calls--index"></div>
                </div>`

    // this.getControllerByIdentifier("app--communications--voips--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--communications--voips--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`app--communications--voips--dashboard--submenu`).dashboardToggleBtn(`LIGAÇÕES`)

      controller.getControllerByIdentifier(controllerCallers).permission = `app_comunication_voip_calls`
      controller.getControllerByIdentifier(controllerCallers).grid = `call_table`
      controller.getControllerByIdentifier(controllerCallers).doCallersCard()
      
      controller.getControllerByIdentifier(`app--communications--voips--calls--days`).doMenuPreloader()
      controller.getControllerByIdentifier(`app--communications--voips--calls--index`).permission = `app_comunication_voip_calls`
      controller.getControllerByIdentifier(`app--communications--voips--calls--index`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
