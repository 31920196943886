import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "subTitle", "dates"]

  connect() {
    this.controllerName = `users--accounts--entities--main`
    this.account_kind = this.application.current_user.account_kind
    this.time_in = Date.now()
    this.application.canEmitInvoice = false
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    // this.application.accounts = {kind: ``, accounts: []}
    this.application.accounts = []

    this.doAccountGrid()

    // getCurrentUserPermissions
    // getAccount


  }

  doAccountGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--accounts--entities--dashboard
                                                            users--accounts--entities--xxxxx
                                                            
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--accounts--entities--dashboard").doAccountsPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  setAccountName() {
    var html = `<span class="mb-0 mc-tooltip pointer">
                  <strong>
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </strong>
                </span>
                <span> | CNPJ: </span>
                <span class="mb-0 mc-tooltip pointer">
                  <strong>
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </strong>
                </span>`

    this.clinicNameTarget.innerHTML = html
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
    this.getControllerByIdentifier("users--accounts--entities--submenu").kindLoaded.doctor = true
  }

  getAccounts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas | Médicos`, value: 20 })

    const data = { account: { active: true, kind: `doctor` }, current_user: { current_user_id: this.application.current_user.id, feature: "account_entities" } }
    const url = "/users/accounts/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.accounts = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `account_entities` }, current_user: { current_user_id: this.application.current_user.id, feature: `account_entity_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          });

          controller.getControllerByIdentifier(`users--accounts--entities--submenu`).doSubmenuHtml()
          controller.application.domain_permission = controller.application.permissions[`account_entity_permissions`]

          controller.getAccounts()
        } else {
          window.open(`/dashboard`, `_self`)
        }

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}