
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    // this.doSubmenuHtml()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--bookings--entities--dashboard").reconnect()
  }

  goToAchievements() {
    this.getControllerByIdentifier("operations--products--bookings--achievements--dashboard").doAchievementsDashboard()
  }

  goToReceipts() {
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doReceiptsDashboard()
  }
  
  goToProvisions() {
    this.getControllerByIdentifier("operations--products--bookings--provisions--dashboard").doProvisionsDashboard()
  }

  goToPayments() {
    this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").doPaymentsDashboard()
  }

  goToDocuments() {
    this.getControllerByIdentifier("operations--products--bookings--documents--dashboard").doDocumentsDashboard()
  }

  goToPersonzalitions() {
    this.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").doPersonalizationsDashboard()
  }

  goToPatients() {
    this.getControllerByIdentifier("operations--products--bookings--patients--dashboard").doPatientsDashboard()
  }

  goToHelpers() {
    this.getControllerByIdentifier("operations--products--bookings--helpers--dashboard").doHelpersDashboard()
  }

  goToSetttings() {
    this.getControllerByIdentifier("operations--products--bookings--settings--dashboard").doSettingsDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("operations--products--bookings--tickets--dashboard").doTicketsDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--bookings--notes--dashboard").doNotesDashboard()
  }


  doSubmenuHtml() {

    var dashboardLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToDashboard" class="dropdown-item py-1 pointer dropdown-submenu-item">Dashboard</span>`

    if (this.application.permissions.medbooking_receipts.can_index) {
      var receiptLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToReceipts" class="dropdown-item py-1 pointer dropdown-submenu-item">Recibos</span>`
    } else {
      var receiptLink = ``
    }

    if (this.application.permissions.medbooking_patients.can_index) {
      var patientLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToPatients" class="dropdown-item py-1 pointer dropdown-submenu-item">Pacientes</span>`
    } else {
      var patientLink = ``
    }

    if (this.application.permissions.medbooking_helpers.can_index) {
      var helperLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToHelpers" class="dropdown-item py-1 pointer dropdown-submenu-item">Assistentes</span>`
    } else {
      var helperLink = ``
    }

    if (this.application.permissions.medbooking_payments.can_index) {
      var paymentLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToPayments" class="dropdown-item py-1 pointer dropdown-submenu-item">Pagamentos</span>`
    } else {
      var paymentLink = ``
    }

    if (this.application.permissions.medbooking_receipts.can_index) {
      var personalizationLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToPersonzalitions" class="dropdown-item py-1 pointer dropdown-submenu-item">Personalização</span>`
    } else {
      var personalizationLink = ``
    }

    if (this.application.permissions.medbooking_provisions.can_index) {
      var provisionLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToProvisions" class="dropdown-item py-1 pointer dropdown-submenu-item">Provisões</span>`
    } else {
      var provisionLink = ``
    }
    
    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var settingsLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToSetttings" class="dropdown-item py-1 pointer dropdown-submenu-item">Configurações</span>`
    } else {
      var settingsLink = ``
    }

    if ((this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") && this.application.permissions.medbooking_tickets.can_index) {
      var ticketLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToTickets" class="dropdown-item py-1 pointer dropdown-submenu-item">Tickets</span>`
    } else {
      var ticketLink = ``
    }

    if ((this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") && this.application.permissions.medbooking_notes.can_index) {
      var noteLink = `<span data-action="click->operations--products--bookings--entities--submenu#goToNotes" class="dropdown-item py-1 pointer dropdown-submenu-item">Anotações</span>`
    } else {
      var noteLink = ``
    }
    // ${notesLink}

    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${dashboardLink}
                          ${receiptLink}
                          ${paymentLink}
                          ${provisionLink}
                          ${patientLink}
                          ${helperLink}
                          ${personalizationLink}
                          ${ticketLink}
                          ${noteLink}
                          ${settingsLink}
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
