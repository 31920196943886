import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "upload", "list", "labelUpload", "divUpload", "documentationSentBtn", "journeyComment", "journeyQuestion", "uploadBtn", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--uploads--add`
    this.controllerList = `operations--products--tax-filings--uploads--list`
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "rtf", "docx", "DEC", "REC", ".DEC", ".REC", "DBK", ".DBK",
                                "vnd.openxmlformats-officedocument.wordprocessingml.document", "txt", "plain", "PDF", "PNG", "JPEG", ".XLSX", ".DOCX", ".TXT"]
    this.files = []
    // this.files = this.application.files

    this.setFiles()
    this.doUploadHtml()
  }

  disconnect() {
    this.stopRefreshing()
  }

  setFiles() {
    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.files[this.files.length] = element
      }
    })
  }

  doUploadHtml() {
    if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
      var uploadBtn = `<div class="col-12 px-0">
                        <div class="card pointer" data-action="click->${this.controllerName}#uploadFiles" data-${this.controllerName}-target="uploadBtn">
                          <div class="card-header p-1 text-center f-1 bg-primary">
                            Salvar Arquivos na Plataforma
                          </div>
                        </div>
                      </div>`
    } else {
      var uploadBtn = ``
    }

    var html = `<div class="row" data-${this.controllerName}-target="divUpload">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;">
                      <div class="card-header card-header-title f-065 text-center mc-tooltip">
                        <h6 class="card-title px-2" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="journeyComment"></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0 mt-2">
                    <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-5">
                        <div class="col p-0 my-5 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                          <span class="mx-3 f-075 align-middle text-center">Upload de Documentos Declaração do Ano-Exercício ${this.application.current_date.year + 1}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="font-weight-bold">*Clique ou arraste arquivos para área pontilhada</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="font-weight-bold">*O tamanho dos arquivos deve ser menor que 5MB</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  
                </div>`

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").uploadTarget.innerHTML = html)
    }).then(() => {
      controller.divUploadTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.checkCurrentCalculation()
      controller.refreshCheckUploadedFiles()
    })
  }

  uploadFiles() {
    this.getControllerByIdentifier("operations--products--tax-filings--uploads--list").uploadDocuments()
  }

  checkCurrentCalculation() {
    if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
      // this.documentationSentBtnTarget.classList.remove("d-none")
      if (this.application.files.length == 0) {
        var comment = `Após adicionar os arquivos na área abaixo, clique na Nuvem Azul no canto direito para fazer o Upload!`
      } else {
        var comment = `Quando finalizar todo o Upload, aperte o play para iniciarmos a sua Declaração!
                        <button data-action="click->${this.controllerName}#goToDocumentationSent" type="button" class="btn btn-sm btn-table btn-flat p-0 mc-tooltip">
                          <span class="material-icons md-dark md-300 pointer">slideshow</span>
                          <span class="mc-tooltiptext">Liberar Documentação</span>
                        </button>`
      }
      this.checkUploadedFilesAndWrite()
      this.labelUploadTarget.classList.remove("not-allowed")
      this.labelUploadTarget.classList.add("copy")
      this.uploadFeature = true
    } else if (this.application.current_tax_filing_calculation.journey_status == `documentation_sent`) {
      
      var pauseBtn = `<button data-action="click->${this.controllerName}#goToAwaitingDocumentation ${this.controllerList}#goToAwaitingDocumentation" type="button" class="btn btn-sm btn-table btn-flat p-0 my-0">
                        <span class="material-icons md-danger md-300 pointer">pause_presentation</span>
                      </button>
                      <span class="mc-tooltiptext">Adicionar Mais Documentos</span>`
      this.getControllerByIdentifier(`operations--products--tax-filings--uploads--list`).journeyCommentTarget.innerHTML = pauseBtn
      this.journeyCommentTarget.innerHTML = `Documentação enviada. <br>Se desejar adicionar mais documentos, pause o processamento!
                                            ${pauseBtn}`
      this.labelUploadTarget.classList.remove("copy")
      this.labelUploadTarget.classList.add("not-allowed")
      this.uploadFeature = false
    } else {
      // this.documentationSentBtnTarget.classList.add("d-none")
      this.journeyCommentTarget.innerText = `O Upload de Arquivos está desabilitado`
      this.labelUploadTarget.classList.remove("copy")
      this.labelUploadTarget.classList.add("not-allowed")
      this.uploadFeature = false
    }
  }

  goToAwaitingDocumentation() {
    if (this.application.current_agent.account_id) {
      this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
      this.send_data.journey.status = `awaiting_documentation`
      var r = confirm(`Tem certeza que deseja adicionar novos Arquivos?`)
      if (r) {
        this.createNewJourney()
      }
    } else {
      alert(`Não é possível Liberar a Documentação. Favor entrar em contato com a MedCapital e informar erro Código 044.`)
    }
  }

  goToDocumentationSent() {
    if (this.application.current_agent.account_id) {
      if (this.checkUploadedFiles()) {
        alert(`Antes de avançar, clique na Nuvem Azul para fazer o Upload dos arquivos!`)
      } else {
        this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
        this.send_data.journey.status = `documentation_sent`
        var r = confirm(`Tem certeza que deseja liberar a Documentação para análise?`)
        if (r) {
          this.createNewJourney()
        }
      }
    } else {
      alert(`Não é possível Liberar a Documentação. Favor entrar em contato com a MedCapital e informar erro Código 044.`)
    }
  }

  createNewJourney() {
    var date = new Date()

    this.actionMode = `new`
    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.date = date.toString()

    var newStatus = this.getControllerByIdentifier(`operations--products--tax-filings--process--dashboard`).translateJourney(this.send_data.journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `changed`
    this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    if (this.application.files.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar os arquivos antes de liberar a declaração!`, 5000)
    } else {
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/operations/products/tax_filings/journeys/create"
    var method = "POST"
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          controller.application.journeys[controller.application.journeys.length] = journey

          var hourglass = `<span class="material-icons md-dark md-sm">hourglass_empty</span>`
          controller.journeyCommentTarget.innerHTML = `Aguarde um instante ... ${hourglass}`
          controller.getControllerByIdentifier(`operations--products--tax-filings--uploads--list`).journeyCommentTarget.innerHTML = hourglass

          if (controller.hasUploadBtnTarget) {
            controller.uploadBtnTarget.classList.add("d-none")
          }
          if (controller.getControllerByIdentifier("operations--products--tax-filings--uploads--list").hasUploadDocumentBtnTarget) {
            controller.getControllerByIdentifier("operations--products--tax-filings--uploads--list").uploadDocumentBtnTarget.classList.add("d-none")
          }
          controller.sendJourneyEmailNotification(journey)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendJourneyEmailNotification(journey) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = `operation_dates`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
    this.send_data.notification.body = journey.status_pretty
    this.send_data.notification.product_token = this.application.tax_filing.token

    if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
      this.send_data.notification.receiver_id = this.application.tax_filing.account_id
      this.send_data.notification.receiver_name = this.application.tax_filing.account_name
      this.send_data.notification.receiver_kind = `doctor`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.send_data.notification.receiver_id = this.application.current_agent.account_id
      this.send_data.notification.receiver_name = this.application.current_agent.account_name
      this.send_data.notification.receiver_kind = `team`
    }

    var url = "/operations/products/tax_filings/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();


    if (this.application.current_date.open) { 
      if (this.uploadFeature) {
        if (ev.type == "drop") {
          if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
              this.found = false
              if (ev.dataTransfer.items[i].kind === 'file') {
                var file = ev.dataTransfer.items[i].getAsFile();
                for (var j = 0; j < this.files.length; j++) {
                  if (this.files[j].file_name == file.name) {
                    if (this.files[j].file_size == file.size) {
                      if (this.files[j].status != `denied`) {
                        this.found = true
                        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O arquivo ${this.files[j].file_name} já está na lista de Uploads ou já foi feito o seu Upload anteriormente.`, 3000)
                        break
                      }
                    }
                  }
                }

                if (!this.found) {

                  var element = ev.dataTransfer.items[i].getAsFile()
                  var obj = {}
                  var date = new Date()

                  obj.index = this.application.files.length
                  obj.file_name = element.name
                  obj.file_size = element.size
                  obj.file_type = element.type
                  obj.file_last_modified = element.lastModified
                  obj.uploaded = false
                  obj.message_erro = ""
                  obj.created_at_time = parseInt(date.getTime() / 1000)
                  if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
                    obj.can_upload = true
                  } else {
                    if ((element.size / 1000000).toFixed(2) > 5) {
                      obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
                    }

                    if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
                      obj.message_erro += "Formato do arquivo não é permitido."
                    }
                    obj.can_upload = false
                  }
                  obj.file = element
                  obj.date_id = this.application.current_date.id
                  this.application.files[this.application.files.length] = obj
                }
              }
            }
          } else {
            
          }

        } else if (ev.type == "change") {
          for (var i = 0; i < ev.target.files.length; i++) {

            this.found = false

            for (var j = 0; j < this.files.length; j++) {
              if (this.files[j].file_name == ev.target.files[i].name) {
                if (this.files[j].file_size == ev.target.files[i].size) {
                  this.found = true
                  this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O arquivo ${this.files[j].file_name} já está na lista de Uploads ou já foi feito o seu Upload anteriormente.`, 3000)
                  break
                }
              }
            }

            if (!this.found) {
              var element = ev.target.files[i]

              var obj = {}
              var date = new Date()
              obj.index = this.application.files.length
              obj.file_name = element.name
              obj.file_size = element.size
              obj.file_type = element.type
              obj.file_last_modified = element.lastModified
              obj.uploaded = false
              obj.message_erro = ""
              obj.created_at_time = parseInt(date.getTime() / 1000)
              if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || element.name.substr(element.name.lastIndexOf('.') + 1))) {
                obj.can_upload = true
              } else {
                if ((element.size / 1000000).toFixed(2) > 5) {
                  obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
                }

                if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
                  obj.message_erro += "Formato do arquivo não é permitido."
                }
                obj.can_upload = false
              }
              obj.file = element
              obj.date_id = this.application.current_date.id
              this.application.files[this.application.files.length] = obj
            }
          }
        }

        this.setFiles()
        this.getControllerByIdentifier("operations--products--tax-filings--uploads--list").doDataTable()
        this.getControllerByIdentifier("operations--products--tax-filings--uploads--list").uploadDocuments()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 3000)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshCheckUploadedFiles() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.getControllerByIdentifier(`operations--products--tax-filings--uploads--list`)) {
        controller.checkUploadedFilesAndWrite()
      } else {
        controller.stopRefreshing()
      }
    }, 3000);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  checkUploadedFiles() {
    var uploadedFiles = this.mapElements(this.application.files, `uploaded`)

    if (uploadedFiles.includes(false) || uploadedFiles.length == 0) {
      return true
    } else {
      return false
    }
  }

  checkUploadedFilesAndWrite() {
    var uploadedFiles = this.mapElements(this.application.files, `uploaded`)
    var playBtn = `<button data-action="click->${this.controllerName}#goToDocumentationSent ${this.controllerList}#goToDocumentationSent" type="button" class="btn btn-sm btn-table btn-flat p-0 my-0">
                    <span class="material-icons md-success md-300">slideshow</span>
                  </button>
                  <span class="mc-tooltiptext">Liberar Documentação</span>`

    if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation` && !this.journeyCommentTarget.innerText.includes(`Aguarde um instante`)) {
      if (uploadedFiles.includes(false) || uploadedFiles.length == 0) {
        var comment = `Após adicionar os arquivos na área abaixo, aperte o play para iniciarmos a sua Declaração!`
      } else {
        var comment = `Quando finalizar todo o Upload, aperte o play para iniciarmos a sua Declaração!
                        ${[playBtn]}`
      }

      this.getControllerByIdentifier(`operations--products--tax-filings--uploads--list`).journeyCommentTarget.innerHTML = playBtn
      this.journeyCommentTarget.innerHTML = comment
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
