import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn", "multipleCheck", "downloadBtn"]

  connect() {
    this.controllerName = `users--works--permissions--policies--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.tableCols = 8
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `updated_at_to_time` }
    this.hasShow = false
    this.all_policies = false
    this.current_subdomain = {}
    this.objIds = []
  }

  showPolicy(ev) {
    var controllerShow = this.getControllerByIdentifier("users--works--permissions--policies--show")
    var policyId = ev.target.closest(".itemRow").dataset.id

    this.application.policies.forEach(element => {
      if (element.id == policyId) {
        controllerShow.current_policy = element
      }
    })

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    controllerShow.doPolicyHtml()
  }

  addPolicy() {
    if (this.current_subdomain.id) {
      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)

      this.getControllerByIdentifier("users--works--permissions--policies--save").actionMode = "new"
      this.getControllerByIdentifier("users--works--permissions--policies--save").current_subdomain = this.current_subdomain
      this.getControllerByIdentifier("users--works--permissions--policies--save").current_policy = {}
      this.getControllerByIdentifier("users--works--permissions--policies--save").doFormHtml() 
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor Selecionar o Domínio`, 3000)
    }
  }

  updatePolicy(ev) {
    var policyId = ev.target.closest(".itemRow").dataset.id
    var policies = []

    this.application.policies.forEach(element => {
      if (element.id == policyId) {

        this.application.policies.forEach(policy => {
          if (policy.feature_id == element.feature_id && policy.subdomain_id == this.current_subdomain.id) {
            policies[policies.length] = policy
          }
        })

        this.getControllerByIdentifier("users--works--permissions--policies--save").actionMode = "edit"
        this.getControllerByIdentifier("users--works--permissions--policies--save").current_subdomain = this.current_subdomain
        this.getControllerByIdentifier("users--works--permissions--policies--save").current_policy = element
        this.getControllerByIdentifier("users--works--permissions--policies--save").current_policies = policies
        this.getControllerByIdentifier("users--works--permissions--policies--save").doFormHtml()

        this.clearListBtnTarget.classList.remove(`d-none`)
        this.optionBtnTarget.classList.add(`d-none`)
      }
    })
  }

  uploadPolicy() {
    this.getControllerByIdentifier("app--helpers--pre-upload").url_upload = `/users/permissions/policies/upload`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/users/permissions/policies/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download_model = `/users/permissions/policies/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `PUT`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `Políticas`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `user_permissions_policies`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = []
    this.getControllerByIdentifier(`app--helpers--pre-upload`).openUploadModal()
  }

  downloadPolicy() {
    this.multipleCheckTargets.forEach(element => {
      if (element.checked) {
        this.objIds[this.objIds.length] = element.dataset.id
      }
    })

    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/users/permissions/policies/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `POST`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `Políticas`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `user_permissions_policies`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = []
    this.getControllerByIdentifier("app--helpers--pre-upload").objIds = this.objIds
    this.getControllerByIdentifier(`app--helpers--pre-upload`).downloadData()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var kindRow = ``
    if (this.all_policies) {
      kindRow = `<th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Tipo</th>`
    }

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addPolicy" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Política</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions[this.permission].can_download) {
      var downloadLine = `<span data-action="click->${this.controllerName}#checkForDownloadPolicies" class="dropdown-item py-1 pointer dropdown-submenu-item">Download de Políticas</span>`
    } else {
      var downloadLine = ``
    }

    if (this.application.permissions[this.permission].can_upload) {
      var uploadLine = `<span data-action="click->${this.controllerName}#uploadPolicy" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload de Políticas</span>`
    } else {
      var uploadLine = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var deleteLine = `<span data-action="click->${this.controllerName}#checkForDeletePolicies" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Políticas</span>`
    } else {
      var deleteLine = ``
    }
    
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshPolicy" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Políticas</span>`
    
    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${downloadLine}
                          ${uploadLine}
                          ${refreshLine}
                          ${deleteLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Políticas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Criado Em
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="created_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="created_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Atualização
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="updated_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="updated_at_to_time" data-mode="asc">north</span>
                              </th>
                              ${kindRow}
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="feature_humanize" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="feature_humanize" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">
                                Enum
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="feature_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="feature_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--policies--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_policies = []
    var subdomain_policies = []
    if (this.current_subdomain.id) {
      this.application.policies.forEach(policy => {
        if (policy.subdomain_id == this.current_subdomain.id) {
        // if (policy.subdomain_id == this.current_subdomain.id && policy.policy_kind == `basic`) {
          subdomain_policies[subdomain_policies.length] = policy
        }
      })

      subdomain_policies.forEach(policy => {
        if (policy.policy_kind == `basic`) {
          current_policies[current_policies.length] = policy
        }
      })

      current_policies.forEach(policy => {
        var current_subdomain_policies = []

        subdomain_policies.forEach(subdomain_policy => {
          if (subdomain_policy.feature_id == policy.feature_id) {
            current_subdomain_policies[current_subdomain_policies.length] = subdomain_policy
          }
        })

        var subdomain_has_policies = this.mapElements(current_subdomain_policies, `has_policies`)
        // policy.has_policies = subdomain_has_policies.every(checkHasPolicies)
        policy.has_policies = subdomain_has_policies.some(checkHasPolicies)

        function checkHasPolicies(hasPolicies) {
          return hasPolicies == true
        }
      })
    } else {
      if (this.all_policies) {
        current_policies = this.application.policies
      } else {
        current_policies = []
      }
    }

    if (this.sort.mode == `asc`) {
      var policies = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_policies, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var policies = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_policies, this.sort.field)
    }

    if (policies.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(policies.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (policies[x] !== undefined) {
            internPage[internPage.length] = policies[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: policies.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var message = ``
      if (this.current_subdomain.humanize) {
        message += `Não há Modelo de Políticas | ${this.current_subdomain.humanize}`
      } else {
        message += `Não há Modelo de Políticas`
      }
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.policyTablePartial(element, data.length))
      });
    }
  }

  policyTablePartial(element, length) {

    if (element.has_policies) {
      var hasPolicies = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Tem Política</span></span>`
    } else {
      var hasPolicies = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não tem Política Definida</span></span>`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `destroy_policies`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `download_policies`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`

    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var columns = ``
    if (this.all_policies) {
      columns = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.policy_kind_pretty}</td>
                <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.account_kind_pretty}</td>
                <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.feature_humanize}</td>`
    } else {
      columns = `<td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#updatePolicy">${element.account_kind_pretty}</td>
                <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#updatePolicy">${element.feature_humanize}</td>`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#updatePolicy">${element.policy_kind_pretty}</td>`
    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.created_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.updated_at_pretty}</td>
                    ${columns}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.feature_name}" data-action="click->app--helpers--copy#copy">${element.feature_name}</span>
                        <span class="mc-tooltiptext">Clique para copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${hasPolicies}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
    if (this.getControllerByIdentifier("users--works--permissions--policies--save")) {
      this.getControllerByIdentifier("users--works--permissions--policies--save").stopRefreshing()
    }
    this.getControllerByIdentifier("users--works--permissions--policies--dashboard").viewTarget.innerHTML = ``
  }

  refreshPolicy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var data = { policy: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions_policies` } }
    const url = "/users/permissions/policies/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.policies = response.data.cln
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkForDeletePolicies() {
    this.kind = `destroy_policies`
    this.doDataTable()
    this.doDestroyPoliciesFooter()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForDownloadPolicies() {
    if (this.current_subdomain.id || this.all_policies) {
      this.kind = `download_policies`
      this.doDataTable()
      this.doDownloadPoliciesFooter()

      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)

      this.kind = false
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor Selecionar o Domínio`, 3000)
    }
  }

  doDestroyPoliciesFooter() {
    var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065 pointer text-right w-100" data-action="click->${this.controllerName}#deletePolicies" data-${this.controllerName}-target="deleteBtn">Apagar</button>`

    this.footerTableTarget.innerHTML = deleteBtnHtml
  }

  doDownloadPoliciesFooter() {
    var downloadBtnHtml = `<button type="button" class="btn btn-flat-primary btn-sm py-2 btn-table f-065 pointer text-right w-100" data-action="click->${this.controllerName}#downloadPolicy" data-${this.controllerName}-target="downloadBtn">Download</button>`

    this.footerTableTarget.innerHTML = downloadBtnHtml
  }

  deletePolicies() {
    var r = confirm("Tem certeza que deseja Apagar as Políticas selecionadas?")
    if (r) {

      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requests = []
      var policiesArray = []
      this.multipleCheckTargets.forEach(check => {
        if (check.checked) {
          policiesArray[policiesArray.length] = check.dataset.id
        }
      });

      for (var index = 0; index < policiesArray.length; index++) {
        this.send_data = { current_user: {}, policy: {} }
        this.send_data.policy.id = policiesArray[index]
        this.send_data.policy.active = false

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `user_permissions_policies`

        var init = { method: "DELETE", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
        this.requests[this.requests.length] = init
      }

      this.requestNext()
    }
  }

  requestNext() {
    var url = "/users/permissions/policies/destroy"
    var controller = this

    if (this.requests.length) {
      fetch(url, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var policies = response.data.cln
            policies.forEach(policy => {
              controller.application.policies.forEach((element, i) => {
                if (element.id == policy.id) {
                  controller.application.policies.splice(controller.application.policies.indexOf(element), 1)
                }
              })
            })

            controller.requestNext()
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      var response = { type: `success`, message: `Processo Finalizado` }
      this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      this.doDataTable()
      this.clearList()
    }
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  // checkHasPolicies(hasPolicies) {
  //   return hasPolicies == true
  // }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
