import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "submessage", "duration", "progressUploadBar", "progressUploadCounter"]

  connect() {
    this.modalController = `app--helpers--call-modal`
    this.controllerName = `app--helpers--pre-call-modal`
  }

  openModal(options = { message: `Chamada em Andamento`, submessage: ``, phone: `(XX) X XXXX-XXXX` }) {

    if (options.message == undefined) {
      options.message = `Chamada em Andamento`
    }
    if (options.submessage == undefined) {
      options.submessage = ``
    }
    if (options.value == undefined) {
      options.value = 0
    }

    this.doProcessModalHtml(options)
  }

  doProcessModalHtml(options) {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="${this.modalController}" data-${this.modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${this.modalController}-target="body">
                        <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.modalController}-target="mainCard">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4 card-title-table-list" data-${this.modalController}-target="titleCard">${options.message}</h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.modalController}#close" type="button">
                                <span class="material-icons md-dark">clear</span>
                              </button>
                              <span class="mc-tooltiptext">Fechar</span>
                            </div>
                          </div>
                          <div class="card-body py-3" style="overflow:auto;">
                            <div class="row w-100 d-flex align-items-end">
                              <div class="col-12">
                                <h6><strong class="f-075">${this.phone_number}</strong></h6>
                              </div>
                              <div class="col-12">
                                <h6>Duração da Chamada: <strong class="f-075" data-${this.modalController}-target="duration"></strong></h6>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer border-top text-right py-1">
                            <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065 d-none" data-${this.modalController}-target="endBtn" data-action="click->${this.modalController}#endCall">Encerrar</button>
                            <button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065 d-none" data-${this.modalController}-target="finishBtn" data-action="click->${this.modalController}#forceEndCall">Desligar</button>
                            <button type="button" class="btn btn-success btn-sm py-2 btn-table f-065" data-${this.modalController}-target="callBtn" data-action="click->${this.modalController}#doCall">Ligar</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    
    
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      
      controller.getControllerByIdentifier(controller.modalController).record_id = controller.record_id
      controller.getControllerByIdentifier(controller.modalController).record_type = controller.record_type
      controller.getControllerByIdentifier(controller.modalController).record_name = controller.record_name
      controller.getControllerByIdentifier(controller.modalController).obj_id = controller.obj_id
      controller.getControllerByIdentifier(controller.modalController).obj_type = controller.obj_type
      controller.getControllerByIdentifier(controller.modalController).person_id = controller.person_id
      controller.getControllerByIdentifier(controller.modalController).person_type = controller.person_type
      controller.getControllerByIdentifier(controller.modalController).person_name = controller.person_name
      controller.getControllerByIdentifier(controller.modalController).dial_number = controller.dial_number
      controller.getControllerByIdentifier(controller.modalController).phone_number = controller.phone_number
      controller.getControllerByIdentifier(controller.modalController).caller_id = controller.caller_id
      controller.getControllerByIdentifier(controller.modalController).kind = controller.kind
      controller.getControllerByIdentifier(controller.modalController).permission = controller.permission

      controller.getControllerByIdentifier(controller.modalController).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}