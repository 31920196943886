import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["certificatesCard", "certificates", "bodyTable", "certificatesCardBody", "4444", "5555", "addIntegrationBtn",
                    "assignCertificateDiv", "assignDropdown", "assignDropdownBtn", "assignInput", "assignList"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--integrations--certificates`
    this.tableCols = 6
  }

  selectCertificate(ev) {
    var selectCertificate = ``
    this.application.certificates.forEach(element => {
      if (element.id == ev.currentTarget.dataset.certificateId) {
        selectCertificate = element
      }
    })

    var canUpload = true
    this.application.integration_companies.forEach(integration_company => {
      if (integration_company.certificate && integration_company.certificate.certificate_id == selectCertificate.id) {
        canUpload = false
      }
    });

    if (canUpload) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.actionMode = "edit"
      this.send_data = { current_user: {}, certificate: {} }

      this.send_data.certificate.id = this.current_integration_certificate_id
      this.send_data.certificate.certificate_id = selectCertificate.id
      this.send_data.certificate.certificate_pass = selectCertificate.certificate_pass
      this.send_data.certificate.status = `pending_certificate`
      this.send_data.current_user.current_user_id = this.application.current_user.id
      
      var url = "/operations/products/clinics/integrations/accountings/certificates/update_certificate"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_certificate = response.data.cln
          controller.application.integration_companies.forEach(element => {
            if (element.id == integration_certificate.company_id) {
              element.certificate = integration_certificate
            }
          })
        }
        controller.closeSelect()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
    } else {
      this.closeSelect()
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Certificado já está atribuído!`, 2000)
    }
  }

  closeSelect() {
    this.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
  }

  uploadCertificate(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.current_integration_certificate_id = ev.currentTarget.closest(".itemRow").dataset.id

    this.actionMode = "edit"
    this.send_data = { current_user: {}, certificate: {} }

    this.send_data.certificate.id = this.current_integration_certificate_id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    var url = "/operations/products/clinics/integrations/accountings/certificates/save_integration"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_certificate = response.data.cln
          controller.application.integration_companies.forEach(element => {
            if (element.id == integration_certificate.company_id) {
              element.certificate = integration_certificate
            }
          })
        }
        controller.closeSelect()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  assignCertificate(ev) {
    this.current_integration_certificate_id = ev.currentTarget.closest(".itemRow").dataset.id
    var lineHtml = ``
    
    this.application.certificates.forEach(element => {
      if (element.status == `active`) {
        lineHtml += `<li data-certificate-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectCertificate" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name} | ${element.kind_pretty} | Venc.: ${element.expiration_date_pretty}</li>`
      }
    })

    var html = `<div class="row d-flex align-items-center" data-${this.controllerName}-target="assignCertificateDiv">
                  <div class="col-11 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Certificado</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="assignDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="assignDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="assignInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="assignList" data-app--helpers--search-target="searchList">
                              ${lineHtml}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 px-2">
                    <button data-action="click->${this.controllerName}#closeSelect" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">clear</span>
                      <span class="mc-tooltiptext">Cancelar</span>
                    </button>
                  </div>
                </div>`


    // this.certificatesCardBodyTarget.insertAdjacentHTML("beforeend", html)
    this.certificatesCardBodyTarget.innerHTML = html

  }

  doCertificatesCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="certificatesCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Integrações de Certificados</h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="certificatesCardBody">
                    <div class="row">
                      <div class="col-12 p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Integração</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-50 align-middle text-center" colspan="2">Certificado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center" colspan="2">Ação</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").certificatesTarget.innerHTML = html)
    }).then(() => {
      controller.certificatesCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {

    this.listData(this.application.integration_companies)

  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Integrações</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.certificateTablePartial(element, data.length))
      });
    }
  }

  certificateTablePartial(integration_company, length) {
    var element = integration_company.certificate

    if (element.status_pretty) {
      var certificateStatus = element.status_pretty
      // var certificateStatus = `<span class="mc-tooltip badge badge-success">
      //                           <span class="material-icons md-150 md-white default">done</span>
      //                           <span class="mc-tooltiptext">${element.status_pretty}</span>
      //                         </span>`
    } else {
      var certificateStatus = `<span class="mc-tooltip badge badge-danger">
                                <span class="material-icons md-150 md-white default">clear</span>
                                <span class="mc-tooltiptext">Não Integrado</span>
                              </span>`
    }
    var fiscalStatus = ``

    if (element.certificate_id && element.status == "active_certificate") {
      var actionStatus = `<span class="mc-tooltip badge badge-success">
                            <span class="material-icons md-200 md-white default">domain_verification</span>
                            <span class="mc-tooltiptext">Integrado</span>
                          </span>`
    } else if (element.certificate_id && element.status != "active_certificate") {
      if (integration_company.integration_id) {
        var actionStatus = `<button data-action="click->${this.controllerName}#uploadCertificate" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">file_upload</span>
                            <span class="mc-tooltiptext">Upload Certificado</span>
                          </button>`
      } else {
        var actionStatus = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">hourglass_empty</span>
                              <span class="mc-tooltiptext">Aguardando Integração NFe.io</span>
                            </button>`
      }
    } else {
      var actionStatus = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">hourglass_empty</span>
                            <span class="mc-tooltiptext">Aguardando Atribuição</span>
                          </button>`
    }

    var assignAction = `<button data-action="click->${this.controllerName}#assignCertificate" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">post_add</span>
                            <span class="mc-tooltiptext">Atribuir Certificado</span>
                          </button>`


    if (element.certificate_id) {
      var certificateName = `<span class="mc-tooltip">
                                ${element.certificate_name}
                                <span class="mc-tooltiptext">${element.certificate_kind_pretty} | Venc.: ${element.expiration_date_pretty}</span>
                              </span>`
    } else {
      var certificateName = `Aguardando Atribuição`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:75px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center default">${certificateName}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${certificateStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${actionStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${assignAction}</td>
                  </tr>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
