import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nav", "main", "breadcrumbName", "breadcrumbNestName", "5555", "breadcrumbNestLink", "7777", "8888", "9999"]

  connect() {
    this.getBreadcrumb()
  }

  getBreadcrumb() {
    const data = { path: { path: this.mainTarget.dataset.url } }
    const url = "/app/general/navigations/breadcrumbs/translate"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var breadcrumbData = response.data
          controller.doBreadcrumb(breadcrumbData)
        }
      })
      .catch(error => {
        window.location.reload()
      })
  }

  doBreadcrumb(breadcrumbData) {
    var html = ``

    breadcrumbData.paths.forEach(path => {
      // `<span class="f-070 badge badge-secondary-dark">Expirado</span>`
      if (path[0] == `Início`) {
        html += `<li class="breadcrumb-item f-075 badge badge-primary"><a class="a-dark" href="${path[1]}">${path[0]}</a></li>`
      } else {
        html += `<li class="breadcrumb-item f-075"><a href="${path[1]}">${path[0]}</a></li>`
      }
    })

    if (breadcrumbData.nested) {
      html += `<li class="breadcrumb-item f-075"><a data-app--navigation--desktop--breadcrumb-target="breadcrumbNestLink" href=""><span data-app--navigation--desktop--breadcrumb-target="breadcrumbNestName"></span></a></li>`
    }

    html += `<li class="breadcrumb-item f-075 active" aria-current="page" id="breadcrumbName" data-app--navigation--desktop--breadcrumb-target="breadcrumbName">${breadcrumbData.name}</li>`

    this.navTarget.innerHTML = html
    if (breadcrumbData && breadcrumbData.controller == false && (breadcrumbData.name == "" || breadcrumbData.name == undefined)) {
      $('#breadcrumbName').html(breadcrumbNameValue);
    }
  }

}
