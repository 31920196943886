import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "recognizedAtInput", "saveBtn", "totalAmountInput", "descriptionInput", "recognizedAmountInput", "body"]

  connect() {
    this.controllerName = `financials--books--payables--entities--recognized-modal`
    // this.open()
  }

  open(payableId) {
    this.refreshSaveBtn()
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doHtml(payableId)
    })
    
  }

  doHtml(payableId) {
    var html = `<div class="row my-2">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="descriptionForm">Descrição</label>
                        <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalAmountForm">Vlr Total</label>
                        <input disabled aria-describedby="totalAmountFormHelp" class="form-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" placeholder="Valor Total" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="recognizedAmountForm">Vlr Reconhecido</label>
                        <input aria-describedby="recognizedAmountFormHelp" class="form-control" id="recognizedAmountForm" data-${this.controllerName}-target="recognizedAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Reconhecido" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="recognizedAtForm">Reconhecida</label>
                        <input aria-describedby="recognizedAtFormHelp" class="form-control" id="recognizedAtForm" data-${this.controllerName}-target="recognizedAtInput" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>`

    this.bodyTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.setCurrentPayable(payableId)

      var lastDay = controller.getControllerByIdentifier(`app--helpers--date`).getMonthLength(controller.application.current_date.month)
      // var date = new Date(controller.application.current_date.year, controller.application.current_date.month - 1, 1)
      var date = new Date(controller.application.current_date.year, controller.application.current_date.month, 0)      
      controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.recognizedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)


      controller.descriptionInputTarget.value = `${controller.current_payable.description} | ${controller.current_payable.provider_name}`
      controller.recognizedAtInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)

      controller.totalAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_payable.total_amount)
      controller.recognizedAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_payable.amount)

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  setCurrentPayable(payableId) {
    this.application.payables.forEach(element => {
      if (element.id == payableId) {
        this.current_payable = element
      }
    })
  }

  updateRecognized() {
    this.send_data = { current_user: {}, payable: {}, tracker: { log: true } }

    this.send_data.payable.id = this.current_payable.id
    this.send_data.payable.recognized = true
    this.send_data.payable.recognized_date = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.recognizedAtInputTarget.value)    
    this.send_data.payable.recognized_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.recognizedAmountInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_entities`

    var r = confirm(`A Conta foi efetivamente Reconhecida?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/financials/books/payables/entities/update_recognized"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var payable = response.data.cln
          controller.application.payables.forEach((element, i) => {
            if (element.id == payable.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
            }
          })
        }
        controller.close()
        if (controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`)) {
          controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doDataTable()
        } else if (controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`)) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).current_payable = payable
          controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).doPayableHtml()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para reconhecer a Conta`)
      })
  }

  setChannels() {
    var html = ``

    if (this.current_payable.subkind == `loan_expenses`) {
      var medId = this.current_payable.med_id
      var borrowerId = this.current_payable.borrower_id

      this.current_payable.med_id = borrowerId
      this.current_payable.borred_id = medId

      this.application.channels.forEach(channel => {
        if (channel.status && channel.med_id == borrowerId) {
          html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectChannel click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`

          if (channel.id == this.current_payable.channel_id) {
            this.channelDropdownTarget.value = channel.id
            this.channelInputTarget.innerText = `${channel.name}`
            this.channelInputTarget.dataset.channelId = channel.id
          }
        }
      })
    } else {
      this.application.channels.forEach(channel => {
        if (channel.status) {
          html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectChannel click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`

          if (channel.id == this.current_payable.channel_id) {
            this.channelDropdownTarget.value = channel.id
            this.channelInputTarget.innerText = `${channel.name}`
            this.channelInputTarget.dataset.channelId = channel.id
          }
        }
      })
    }

    this.channelListTarget.innerHTML = html
  }

  selectChannel(ev) {
    var channelId = ev.currentTarget.dataset.channelId
    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  bindTotalAmount(ev) {
    var amount = Number(this.current_payable.amount)
    var interests = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(ev.currentTarget.value))

    var totalAmount = amount + interests
    this.totalAmountInputTarget.value = this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(totalAmount)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.recognizedAmountInputTarget.value == ``) {
        len += 1
      }

      if (controller.recognizedAtInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}