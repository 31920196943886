import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["productAmount", "productGain", "productLost", "modal", "body", "saveBtn", "reasonDropdown", "reasonDropdownBtn", "reasonInput", "reasonList"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--closing`
    this.stage = ``
    this.open()
  }

  selectReason(ev) {
    var reason = ev.currentTarget.dataset.reason

    this.reasonInputTarget.dataset.reason = reason
  }

  saveOpportunity() {
    this.send_data = { current_user: {}, product: {} }
    // this.send_data = { current_user: {}, opportunity: {} }

    if (this.application.opportunity.products[0]) {
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.product.id = this.application.opportunity.products[0].id
      this.send_data.product.reason = this.reasonInputTarget.dataset.reason  

      var url = "/commercial/sales/opportunities/products/update"
      var method = "PUT"
    } else {
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.product.opportunity_id = this.application.opportunity.id
      this.send_data.product.date_id = this.application.opportunity.date_id
      this.send_data.product.name = `medreturn`
      this.send_data.product.plan = `receivement`
      this.send_data.product.kind = `opening`
      this.send_data.product.reason = this.reasonInputTarget.dataset.reason
      this.send_data.product.lost = true

      var url = "/commercial/sales/opportunities/products/create"
      var method = "POST"
    }
    
    // this.send_data.opportunity.id = this.application.opportunity.id
    // this.send_data.opportunity.reason = this.reasonInputTarget.dataset.reason
    // var url = "/commercial/sales/opportunities/entities/update"
    // var url = "/commercial/sales/opportunities/products/update"
    // var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        window.location.reload()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        controller.close()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  closeModal() {
    this.close()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
