import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "tableTitle", "clearListBtn", 
                    "optionBtn", "bindSum", "multipleCheck"]

  connect() {
    this.controllerName = `financials--books--cards--transactions--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 7
    this.sort = { mode: `asc`, field: `date_to_time` }
    this.current_bill = {}
    this.hasCheck = false
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var adjustLine = ``
    var deleteLine = ``
    var sumLine = ``

    if (this.current_bill.id) {
      adjustLine = `<span data-action="click->${this.controllerName}#checkForAdjustment" class="dropdown-item py-1 pointer dropdown-submenu-item">Ajuste de Parcela</span>`
      deleteLine = `<span data-action="click->${this.controllerName}#checkForDelete" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Transações</span>`
      sumLine = `<span data-action="click->${this.controllerName}#checkForBindSum" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Transações</span>`
    }
    
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshBills" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Faturas</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${adjustLine}
                          ${deleteLine}
                          ${sumLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="tableTitle">Selecione uma Fatura</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Parcela</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Valor</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Primeiro Pgt</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Último Pgt</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--cards--bills--dashboard").transactionsTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.bodyTableTarget.innerHTML = controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10)
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_transactions = []

    this.application.card_transactions.forEach(transaction => {
      if (transaction.bill_id == this.current_bill.id) {
        current_transactions[current_transactions.length] = transaction
      }
    })

    if (this.sort.mode == `asc`) {
      var transactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_transactions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var transactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_transactions, this.sort.field)
    }

    if (transactions.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(transactions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (transactions[x] !== undefined) {
            internPage[internPage.length] = transactions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: transactions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      if (this.current_bill.id) {
        var message = `Não há Transações na ${this.current_bill.name}`  
      } else {
        var message = `Selecione uma Fatura`
      }
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.transactionTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  transactionTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `adjust_transaction`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="check-${element.id}" data-action="click->${this.controllerName}#createTransaction">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `destroy_transaction`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `sum_transactions`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mb-0 f-065 mc-tooltip help">
                        <span>${element.description}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">${element.provider_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.quota}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.controllerNumber.currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.first_pay_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.last_pay_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable" data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getTransactions(bill_id) {
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)

    const data = { transaction: { active: true, bill_id: bill_id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_card_transactions` } }
    const url = "/financials/books/cards/transactions/list_by_bill"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.card_transactions = response.data.cln.transactions
        controller.current_bill = response.data.cln.bill

        controller.application.bills.forEach((element, i) => {
          if (element.id == controller.current_bill.id) {
            controller.application.bills.splice(controller.application.bills.indexOf(element), 1, controller.current_bill)
          }
        })

        controller.tableTitleTarget.innerText = controller.current_bill.name
        controller.getControllerByIdentifier(`financials--books--cards--bills--show`).current_bill = controller.current_bill
        controller.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill()
        controller.doIndexListHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshBills() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { bill: { active: true, month: this.application.current_date.month, year: this.application.current_date.year }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_card_bills` } }
    const url = "/financials/books/cards/bills/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentBillIds = controller.mapElements(controller.application.bills, `id`)
          var refreshBillIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(bill => {
            if (currentBillIds.includes(bill.id) && refreshBillIds.includes(bill.id)) {
              controller.application.bills.forEach((element, i) => {
                if (element.id == bill.id) {
                  controller.application.bills.splice(controller.application.bills.indexOf(element), 1, bill)
                }
              })
            } else if (currentBillIds.includes(bill.id) && !refreshBillIds.includes(bill.id)) {
              controller.application.bills.forEach((element, i) => {
                if (element.id == bill.id) {
                  controller.application.bills.splice(controller.application.bills.indexOf(element), 1)
                }
              })
            } else if (!currentBillIds.includes(bill.id) && refreshBillIds.includes(bill.id)) {
              controller.application.bills[controller.application.bills.length] = bill
            }
          })
        }

        controller.getControllerByIdentifier("financials--books--cards--bills--dashboard").doBillsDashboard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getBills() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas de Cartão de Crédito`, value: 85 })

    const data = { bill: { active: true, month: this.application.current_date.month, year: this.application.current_date.year }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_card_bills` } }
    const url = "/financials/books/cards/bills/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.bills[controller.application.bills.length] = element
          })
        }

        controller.finishLoadPage()
        controller.application.current_date.data_loaded = true
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  checkForAdjustment() {
    this.kind = `adjust_transaction`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForDelete() {
    this.kind = `destroy_transaction`
    this.doDataTable()
    this.doDestroyMultipleFooter()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
    
    this.kind = false
  }

  checkForBindSum() {
    this.kind = `sum_transactions`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  bindSum() {
    var bindSum = 0
    var transactionsArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        transactionsArray[transactionsArray.length] = Number(check.dataset.id)
      }
    });

    this.application.card_transactions.forEach(transaction => {
      if (transactionsArray.includes(transaction.id)) {
        bindSum += Number(transaction.amount)
      }
    });

    this.bindSumTarget.innerText = `Valor: ${this.controllerNumber.currencyOptionMask(bindSum)}`
  }

  doDestroyMultipleFooter() {
    var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065 pointer text-right w-100" data-action="click->${this.controllerName}#deleteTransactions" data-${this.controllerName}-target="deleteBtn">Apagar</button>`

    this.footerTableTarget.innerHTML = deleteBtnHtml
  }

  createTransaction(ev) {
    if (this.application.current_date.open) {
      var transactionId = ev.currentTarget.closest(`.itemRow`).dataset.id

      var current_transaction = {}
      this.application.card_transactions.forEach(transaction => {
        if (transaction.id == transactionId) {
          current_transaction = transaction
        }
      })

      this.getControllerByIdentifier("financials--books--cards--transactions--adjust").current_transaction = current_transaction
      this.getControllerByIdentifier("financials--books--cards--transactions--adjust").actionMode = "new"
      this.getControllerByIdentifier("financials--books--cards--transactions--adjust").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  deleteTransactions() {
    if (this.application.current_date.open) {
      var r = confirm("Tem certeza que deseja Apagar as Transações selecionados?")
      if (r) {

        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.requests = []
        var transactionsArray = []
        this.multipleCheckTargets.forEach(check => {
          if (check.checked) {
            transactionsArray[transactionsArray.length] = check.dataset.id
          }
        });

        for (var index = 0; index < transactionsArray.length; index++) {
          this.send_data = { current_user: {}, transaction: {} }
          this.send_data.transaction.id = transactionsArray[index]
          this.send_data.transaction.active = false
          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `financial_payable_card_transactions`

          var init = { method: "DELETE", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
          this.requests[this.requests.length] = init
        }

        this.requestNext()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  requestNext() {
    var url = "/financials/books/cards/transactions/destroy"
    var controller = this

    if (this.requests.length) {
      fetch(url, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            controller.current_bill = response.data.cln.bill
            controller.application.bills.forEach((element, i) => {
              if (element.id == controller.current_bill.id) {
                controller.application.bills.splice(controller.application.bills.indexOf(element), 1, controller.current_bill)
              }
            })

            var transaction = response.data.cln.transaction
            controller.application.card_transactions.forEach((element, i) => {
              if (element.id == transaction.id) {
                controller.application.card_transactions.splice(controller.application.card_transactions.indexOf(element), 1)
              }
            })

            controller.requestNext()
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {

      this.tableTitleTarget.innerText = this.current_bill.name
      this.getControllerByIdentifier(`financials--books--cards--bills--show`).current_bill = this.current_bill
      this.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill()
      this.doDataTable()

      var response = { type: `success`, message: `Processo Finalizado` }
      this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      this.clearList()
    }
  }

  deleteTransaction(ev) {
    if (this.application.current_date.open) {
      var transactionId = ev.currentTarget.closest(`.itemRow`).dataset.id

      this.send_data = { current_user: {}, transaction: {} }

      this.send_data.transaction.id = transactionId
      this.send_data.transaction.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_payable_card_transactions`
      var r = confirm("Tem certeza que deseja Apagar a Transação de Cartão?")
      if (r) {
        this.requestDestroy()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var url = "/financials/books/cards/transactions/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.current_bill = response.data.cln.bill
          controller.application.bills.forEach((element, i) => {
            if (element.id == controller.current_bill.id) {
              controller.application.bills.splice(controller.application.bills.indexOf(element), 1, controller.current_bill)
            }
          })

          var transaction = response.data.cln.transaction
          controller.application.card_transactions.forEach((element, i) => {
            if (element.id == transaction.id) {
              controller.application.card_transactions.splice(controller.application.card_transactions.indexOf(element), 1)
            }
          })

          controller.tableTitleTarget.innerText = controller.current_bill.name
          controller.getControllerByIdentifier(`financials--books--cards--bills--show`).current_bill = controller.current_bill
          controller.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.doDataTable()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
