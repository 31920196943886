import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "save", "filters", "header", "subheader", "squad", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--trackers--stories--notes`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.notes = []
    this.canRequest = true
    this.notesPreloader()
  }

  doNotesHeader() {
    if (this.application.permissions.user_works_trackers_notes.can_create) {
      var title = `<span class="ml-auto">Anotações</span>
                        <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addNote" data-${this.controllerName}-target="addNoteBtn">
                          <span class="material-icons md-sm md-dark">add</span>
                          <span class="mc-tooltiptext">Adicionar Anotação</span>
                        </span>`
    } else {
      var title = `<span class="mx-auto">Anotações</span>`
    }

    this.titleTarget.innerHTML = title
  }

  setNotes() {
    var html = ``
    var ordered_notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.notes, `date_time`)
    ordered_notes.unshift(ordered_notes.pop())
    var notes = ordered_notes
    
    
    if (notes.length == 0) {
      var html = `<div class="row w-100" style="height:100px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Anotações</span>
                      </div>
                    </div>`

      this.mainTarget.innerHTML = html
    } else {
      notes.forEach(element => {

        if (this.userCanUpdate() && this.application.permissions.user_works_trackers_notes.can_delete) {
          var deleteBtn = `<button data-action="click->${this.controllerName}#destroyNote" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar</span>
                        </button>`
        } else {
          var deleteBtn = ``
        }

        if (this.userCanUpdate() && this.application.permissions.user_works_trackers_notes.can_update) {
          var editBtn = `<button data-action="click->${this.controllerName}#editNote" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">edit</span>
                          <span class="mc-tooltiptext">Editar</span>
                        </button>`
        } else {
          var editBtn = ``
        }

        if (element.kind == `minutes`) {
          html += `<div class="row my-2 w-100 noteCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-header p-0 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-9 pl-2 pr-0 d-flex align-items-center">
                            <div class="card-show-dropdown w-100">
                              ${element.title}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065"><strong>Em ${element.date_pretty}, ${element.owner_name} adicionou a seguinte nota:</strong></p> 
                                <div class="trix-content px-3">
                                  ${element.body}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            ${editBtn}
                          </div>
                          <div class="col-1">
                            ${deleteBtn}
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-0 f-065 pointer d-none" data-${this.controllerName}-target="editBody-${element.id}">
                        <div class="row my-2">
                          <div class="col-4 pr-2">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dateForm-${element.id}">Data</label>
                                <input disabled aria-describedby="dateFormHelp-${element.id}" class="form-control form-valid-control" id="dateForm-${element.id}" data-${this.controllerName}-target="dateInput-${element.id}" placeholder="Data" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="titleForm-${element.id}">Título</label>
                                <input aria-describedby="titleFormHelp-${element.id}" class="form-control form-valid-control" id="titleForm-${element.id}" data-${this.controllerName}-target="titleInput-${element.id}" placeholder="Título" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <input aria-describedby="notesFormHelp-${element.id}" type="hidden" class="form-control" id="notesForm-${element.id}" placeholder="Texto" data-${this.controllerName}-target="bodyInput-${element.id}">
                                <trix-editor input="notesForm-${element.id}" data-trix="true" data-field="description" class="trix-content trix-med trix-editor-notes textarea p-1 f-075" rows="15" placeholder="Faça aqui as anotações ... " data-${this.controllerName}-target="bodyInputTrix-${element.id}"></trix-editor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1 d-none" data-${this.controllerName}-target="editFooter-${element.id}">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveNote" data-${this.controllerName}-target="saveBtn-${element.id}">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`
        } else if (element.kind == `updating`) {
          html += `<div class="row my-2 w-100 noteCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-header p-0 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-12 pl-2 pr-0 d-flex align-items-center">
                            <div class="card-show-dropdown w-100">
                              ${element.title}: ${element.body}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">Atualizado em ${element.full_date_time_pretty}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
        }
      })

      this.mainTarget.innerHTML = html
    }
  }

  cancelSave(ev) {
    var id = ev.currentTarget.closest(".noteCard").dataset.id
    this.nameTarget(`editBody-${id}`).classList.add("d-none")
    this.nameTarget(`editFooter-${id}`).classList.add("d-none")
  }

  editNote(ev) {
    var id = ev.currentTarget.closest(".noteCard").dataset.id
    var note = {}
    this.application.notes.forEach(element => {
      if (element.id == id) {
        note = element
      }
    })

    this.nameTarget(`editBody-${id}`).classList.remove("d-none")
    this.nameTarget(`editFooter-${id}`).classList.remove("d-none")
    this.nameTarget(`dateInput-${id}`).value = note.date_pretty
    this.nameTarget(`titleInput-${id}`).value = note.title
    this.nameTarget(`bodyInputTrix-${id}`).value = note.body

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  addNote() {

    if (this.canRequest) {
      this.actionMode = `new`
      this.canRequest = false
      this.send_data = { current_user: {}, note: {}, notification: {} }

      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_id = this.application.story.id
      this.send_data.note.domain_type = `tracker_stories`
      this.send_data.note.domain_name = `Entregável ${this.application.story.name}`
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `minutes`
      this.send_data.note.date = new Date()
      this.send_data.note.body = `Nova Anotação`
      this.send_data.note.title = `Nova Anotação`

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.requestSave()
    }
  }

  saveUpdateNote(message) {
    this.actionMode = `new`
    this.canRequest = false
    this.send_data = { current_user: {}, note: {}, notification: {} }

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.story.id
    this.send_data.note.domain_type = `tracker_stories`
    this.send_data.note.domain_name = `Entregável ${this.application.story.name}`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = message
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  saveNote(ev) {
    this.send_data = { current_user: {}, note: {}, notification: {} }
    var id = ev.currentTarget.closest(".noteCard").dataset.id

    if (this.canRequest) {
      this.actionMode = `edit`
      this.canRequest = false
      this.send_data.note.id = id
      this.send_data.note.title = this.nameTarget(`titleInput-${id}`).value.trim()
      this.send_data.note.body = this.nameTarget(`bodyInput-${id}`).value.trim()
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.requestSave()
    }
  }

  destroyNote(ev) {
    this.send_data = { current_user: {}, note: {}, notification: {} }
    var id = ev.currentTarget.closest(".noteCard").dataset.id

    if (this.canRequest) {
      this.actionMode = `edit`
      this.canRequest = false
      this.send_data.note.id = id
      this.send_data.note.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/trackers/notes/create"
      var method = "POST"
    } else {
      var url = "/users/works/trackers/notes/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var note = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.notes[controller.application.notes.length] = note
          } else {
            if (note.active) {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1, note)
                }
              })
            } else {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1)
                }
              })
            }
          }
          controller.setNotes()
          controller.canRequest = true
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getNotes() {
    var data = { note: { domain_id: this.application.story.id, domain_type: "tracker_stories" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/works/trackers/notes/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln
        controller.setNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  notesPreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.bodyCardLoader}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  userCanUpdate() {
    var allowed = this.mapElements(this.application.story.project.squads, `team_id`)

    const hasTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasTeam) || (allowed.includes(this.application.current_user.account_id))) {
      return true
    } else {
      return false
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
