import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "body", "dateInput", "dateList", "dateDropdownBtn", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--show--dates`
    this.sort = {}
    this.sort.mode = `desc`
  }

  changeDate(ev) {
    var id = ev.currentTarget.closest(".cardDate").dataset.id
    this.setTaxFilingDate(id)
  }

  setTaxFilingDate(id) {
    
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        if (this.application.current_date.open == false) {
          this.application.current_date.message = `O Ano-Exercício ${this.application.current_date.year + 1} está fechado.`
        }
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `O Exercício está fechado` }
    }

    // this.application.tax_filing_calculations.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     this.application.current_tax_filing_calculation = element
    //   }
    // })

    // this.goToDashboard()
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").reload = true
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").loadDateData()
  }

  setCurrents() {
    this.application.agents.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_agent = element
      }
    })

    this.application.tax_filing_calculations.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_tax_filing_calculation = element
      }
    })

    this.application.summaries.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_summary = element
      }
    })
  }

  runDashboardsHtml() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  doDatesDashboard() {

    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Escolha o Ano-Exercício</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="body"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`mobile--operations--products--tax-filings--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    if (this.sort.mode == `asc`) {
      var dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.dates, `year`)
    } else if (this.sort.mode == `desc`) {
      var dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.dates, `year`)
    }
    
    this.listData(dates)
  }

  listData(data) {
    this.bodyTarget.innerHTML = ""

    data.forEach(element => {
      this.bodyTarget.insertAdjacentHTML("beforeend", this.dateTablePartial(element, data.length))
    });
  }

  dateTablePartial(element, length) {


    var rowHtml = `<div class="col-10 offset-2">
                    <div class="card my-3 cardDate" data-action="click->${this.controllerName}#changeDate" data-id=${element.id}>
                      <div class="media d-flex align-items-center py-3">
                        <span class="material-icons md-lg md-primary mx-3">description</span>
                        <div class="media-body">
                          <h5 class="mb-0">${element.name}</h5>
                          <span class="text-black-secondary">Ano-Calendário ${element.year}</span>
                        </div>
                      </div>
                    </div>
                  </div>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
