import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "form", "title", "info", "confirmBtn", "optInLanding", "nameInput", "saveBtn",
                    "phone", "errorEmailCode", "email", "mainFrame", "checkboxAcademic", "checkboxDoctor",
                    "graduation", "checkboxNotDoctor", "academicRow", "doctorRow", "crmInput", "school", "crmStateInput"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `mobile--commercial--marketing--landings--publics--save`
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.first_interaction = true
    this.invalidEmail = true
    this.searchArray = []
    this.optIn = {}
    if (window.location.search.split("?")[1]) {
      this.searchArray = window.location.search.split("?")[1].split("&")
    }

    // window.onbeforeunload = function () {
    //   return "Do you really want to close?";
    // }
  }

  doFormHtml() {
    var html = `<div class="card card-lp-bg-primary lp-card mt-6r">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 my-2 text-center px-0">
                        <h1 class="lp-card-title">Preencha o formulário</h1>
                      </div>
                      <div class="col-12 my-2">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form" placeholder="Nome" required data-${this.controllerName}-target="nameInput" data-field="name" data-action="blur->${this.controllerName}#saveInteraction">
                            <span class="material-icons lp-input-icon">account_circle</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="mail" class="form-no-border lp-input-form" placeholder="E-mail" required data-${this.controllerName}-target="email" data-field="email" data-action="blur->${this.controllerName}#saveInteraction blur->${this.controllerName}#validateEmail focus->${this.controllerName}#removeInvalid">
                            <span class="material-icons lp-input-icon ">email</span>
                            <div data-${this.controllerName}-target="errorEmailCode"></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form" placeholder="(XX) X XXXX-XXXX" data-field="uniq_number" data-${this.controllerName}-target="phone" data-action="blur->${this.controllerName}#saveInteraction blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask">
                            <span class="material-icons lp-input-icon">phone</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 mb-3 mt-4 d-flex">
                        <div class="form-group form-check d-flex align-items-center mb-0">
                          <input type="checkbox" class="form-check-input mr-1" name="doctorLanding" data-${this.controllerName}-target="checkboxDoctor" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="doctorLanding" style="margin-left:1rem;" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">Médico</label>
                        </div>
                        <div class="form-group form-check d-flex align-items-center mb-0">
                          <input type="checkbox" class="form-check-input mr-1" name="academicLanding" data-${this.controllerName}-target="checkboxAcademic" data-kind="academic" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="academicLanding" style="margin-left:1rem;" data-kind="academic" data-action="click->${this.controllerName}#checkKind">Acadêmico</label>
                        </div>
                        <div class="form-group form-check d-flex align-items-center mb-0">
                          <input type="checkbox" class="form-check-input mr-1" name="notDoctorLanding" data-${this.controllerName}-target="checkboxNotDoctor" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="notDoctorLanding" style="margin-left:1rem;" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">Outro</label>
                        </div>
                      </div>
                      <div class="col-6 my-1 pr-0 d-none" data-${this.controllerName}-target="academicRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form lp-input-form-w-90" placeholder="Faculdade" data-${this.controllerName}-target="school">
                            <span class="material-icons lp-input-icon">school</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 pl-0 d-none" data-${this.controllerName}-target="academicRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form lp-input-form-w-90 lp-input-form-pl-10" placeholder="Ano Formatura" data-${this.controllerName}-target="graduation">
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 pr-0 d-none" data-${this.controllerName}-target="doctorRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form lp-input-form-w-90" placeholder="UF" data-field="crm_state" data-${this.controllerName}-target="crmStateInput" data-action="blur->${this.controllerName}#saveInteraction keyup->${this.controllerName}#upcase">
                            <span class="material-icons lp-input-icon">medication</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 pl-0 d-none" data-${this.controllerName}-target="doctorRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form lp-input-form-w-90 lp-input-form-pl-10" placeholder="CRM" data-field="crm" data-${this.controllerName}-target="crmInput" data-action="blur->${this.controllerName}#saveInteraction keyup->${this.controllerName}#upcase">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 d-flex my-3">
                        <div class="form-check d-flex align-items-center">
                          <input type="checkbox" class="form-check-input" name="optInLanding" data-${this.controllerName}-target="optInLanding" data-confirm="false" data-action="click->${this.controllerName}#confirmOptIn">
                          <label class="form-check-label ml-0" for="optInLanding" style="margin-left:1rem;"> Eu concordo com os <a class="a-white" href="/termos-de-uso-e-politica-privacidade" target="blank">Termos de Uso e Política de Privacidade</a></label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="forms-group">
                          <button disabled type="submit" class="lp-btn-primary" href="#" data-${this.controllerName}-target="saveBtn" data-action="click->${this.controllerName}#leadSave">
                            ${this.application.landing.final_cta_btn_pretty}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
    })

  }

  checkKind(ev) {
    this.kind = ev.currentTarget.dataset.kind

    if (this.kind == `academic`) {
      this.checkboxAcademicTarget.checked = true
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (this.kind == `doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = true
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

    } else if (this.kind == `not_doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = true

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    }
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToFullPhone(ev)
  }

  validateEmail(ev) {
    if (ev.type == "blur") {
      var email = ev.currentTarget.value
      if (!email.includes("@")) {
        this.invalidEmail = true
        this.errorEmailCodeTarget.classList.add("invalid-warning", "mt-1", "pt-1", "f-1")
        this.errorEmailCodeTarget.style.zIndex = 10
        this.emailTarget.classList.add("invalid-field")
        this.errorEmailCodeTarget.innerText = `Formato errado de e-mail, faltou o @`
      } else if (email.length < 11) {
        this.invalidEmail = true
        this.errorEmailCodeTarget.classList.add("invalid-warning", "mt-1", "pt-1", "f-1")
        this.errorEmailCodeTarget.style.zIndex = 10
        this.emailTarget.classList.add("invalid-field")
        this.errorEmailCodeTarget.innerText = `Formato errado de e-mail, email tem menos de 11 caracteres`
      } else {
        this.invalidEmail = false
      }
    }
  }

  removeInvalid(ev) {
    this.errorEmailCodeTarget.classList.remove("invalid-warning", "my-1", "py-1", "f-1")
    this.errorEmailCodeTarget.style.zIndex = 0
    this.emailTarget.classList.remove("invalid-field")
    this.errorEmailCodeTarget.innerText = ""
  }

  upcase(ev) {
    var value = ev.currentTarget.value
    ev.currentTarget.value = value.toUpperCase()
  }

  confirmOptIn(ev) {
    this.send_data = { current_visit: {}, opt: {} }

    this.send_data.current_visit.current_visit_id = this.application.current_visit_id
    this.send_data.current_visit.current_visit_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visit")
    this.send_data.current_visit.has_data = true
    this.send_data.opt.id = ""
    this.send_data.opt.opt_in = true
    this.send_data.opt.opt_in_at = new Date()
    this.send_data.opt.name = this.application.landing.name
    this.send_data.opt.obj_id = this.application.landing.id
    this.send_data.opt.obj_type = "landing_entity"
    this.send_data.opt.user_id = this.application.current_visit_id
    this.send_data.opt.user_type = "enrollment_attendee"

    this.saveOptIn()
  }

  saveOptIn() {
    const url = "/users/opts/save"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    if (this.application.current_user) {
      this.optIn = { created_at: new Date() }
      this.optInLandingTarget.disabled = true
      this.optInLandingTarget.dataset.confirm = true
    } else {
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            controller.optIn = response.data.cln
            controller.optInLandingTarget.disabled = true
            controller.optInLandingTarget.dataset.confirm = true
          }
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }

  }

  saveInteraction(ev) {
    if (this.application.current_user == undefined) {
      this.send_data = { landing: {}, current_visit: {} }

      this.send_data.current_visit.current_visit_id = this.application.current_visit_id
      this.send_data.current_visit.current_visit_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visit")
      this.send_data.landing.id = this.application.landing.id
      this.send_data.landing.first_interaction = this.first_interaction
      this.send_data.landing.field = ev.currentTarget.dataset.field
      this.send_data.landing.value = ev.currentTarget.value.trim()

      const url = "/commercial/marketing/landings/entities/save_interaction"
      const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      var element = ev.currentTarget
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (!response.check) {
            element.value = ``
          }
          controller.first_interaction = false
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  leadSave() {
    this.send_data = { landing: {}, lead: {}, campaign: {}, current_visit: {} }

    this.send_data.current_visit.current_visit_id = this.application.current_visit_id
    this.send_data.current_visit.current_visit_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visit")
    this.send_data.current_visit.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.send_data.landing.landing_id = this.application.landing.id

    var phone = this.phoneTarget.value.trim()

    if (phone.length == 16) {
      var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(0, 2)
      var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(2, 11)
    } else {
      var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
      var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
    }

    this.searchArray.forEach(element => {
      var utm = element.split("=")

      this.send_data.campaign[utm[0]] = utm[1]
    })

    if (this.send_data.campaign[`utm_mc_campaign`]) {
      this.send_data.campaign.tag = this.send_data.campaign[`utm_mc_campaign`]
    } else {
      this.send_data.campaign.tag = `organic_conversion`
      this.send_data.campaign[`utm_mc_source`] = `Orgânico`
    }

    this.send_data.lead.name = this.nameInputTarget.value.trim()
    this.send_data.lead.email = this.emailTarget.value.trim().toLowerCase()
    this.send_data.lead.ddd = ddd
    this.send_data.lead.number = number
    this.send_data.lead.uniq_number = this.phoneTarget.value.trim()
    this.send_data.lead.opted_in_at = this.optIn.created_at
    this.send_data.lead.kind = this.kind
    if (this.kind == `doctor`) {
      this.send_data.lead.crm = this.crmInputTarget.value.trim()
      this.send_data.lead.crm_state = this.crmStateInputTarget.value.trim()
      this.send_data.lead.uniq_crm = `${this.crmStateInputTarget.value.trim()}-${this.crmInputTarget.value.trim()}`
    } else if (this.kind == `academic`) {
      this.send_data.lead.uniq_crm = `${this.schoolTarget.value.trim()}-${this.graduationTarget.value.trim()}`
    }

    if (this.application.current_user) {
      this.lead = {}
      this.goToCta()
    } else {
      this.requestSave()
    }
  }

  requestSave() {
    this.stopRefreshing()
    var url = "/commercial/marketing/landings/leads/save_public"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.lead = response.data.cln
          controller.goToCta()
        } else {
          controller.refreshSaveBtn()
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToCta() {
    var url = ``

    if (this.application.landing.final_cta == `download`) {
      url = `/downloads/${this.application.landing.final_cta_path}?conversion=true`
    } else if (this.application.landing.final_cta == `calculator`) {
      url = `/c/${this.application.landing.final_cta_path}?conversion=true`
    } else if (this.application.landing.final_cta == `course` || this.application.landing.final_cta == `lesson` || this.application.landing.final_cta == `talk`) {
      url = `/medschool/${this.application.landing.final_cta_path}?conversion=true`
    } else if (this.application.landing.final_cta == `schedule`) {
      url = `/agendamento?medico=${this.lead.id}&conversion=${this.lead.conversion_id}`
    }

    window.open(url, `_self`)
  }


  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.invalidEmail || controller.emailTarget.value == ``) {
        len += 1
      }

      if (controller.phoneTarget.value == `` || controller.phoneTarget.length < 16) {
        len += 1
      }

      if (controller.checkboxDoctorTarget.checked && controller.checkboxAcademicTarget.checked && controller.checkboxNotDoctorTarget.checked) {
        len += 1
      }

      if (controller.checkboxDoctorTarget.checked && controller.crmInputTarget.value == ``) {
        len += 1
      }

      if (controller.checkboxAcademicTarget.checked && controller.schoolTarget.value == `` && controller.graduationTarget.value == ``) {
        len += 1
      }

      if (controller.optInLandingTarget.dataset.confirm == true || controller.optInLandingTarget.dataset.confirm == "true") {
        len += 0
      } else {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
