import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "cardBody", "footerTable", "main", "saveBtn", "dateOpen", "7777", "8888", "9999",
                    "userNameShow", "userNameEdit", "userNameEditInput", "userNameFilter", "userNameFilterItem", "saveBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--settings--agent`
    this.doAgentListHtml()
  }

  listData() {
    var agents = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.agents, "year")
    this.cardBodyTarget.innerHTML = ""
    
    if (agents == undefined || agents == [] || agents.length == 0) {
      var noData = `<div class="row d-flex align-items-center">
                      <div class="col-12 my-3 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <h5 class="mb-0 f-065">Não existem Responsável dentro do Time</h5>
                      </div>
                    </div>`

      this.cardBodyTarget.innerHTML = noData
    } else {
      agents.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.cardBodyTarget.insertAdjacentHTML("beforeend", this.agentPartial(element))
        }
      })
    }
  }

  agentPartial(element) {
    // `<span class="mc-tooltiptext">Responsável pela Declaração IRPF</span>`
    var html = `<div class="row d-flex align-items-center rowItem" data-id="${element.id}">
                  <div class="col-2 my-3 px-1">
                    <span>${Number(element.year) + 1}</span>
                  </div>
                  <div class="col-10 px-0 d-flex align-items-center pointer mc-tooltip" data-${this.controllerName}-target="userNameShow-${element.id}" data-action="click->${this.controllerName}#inlineEditUserName">
                    ${element.account_name}
                  </div>
                  <div class="col-10 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="userNameEdit-${element.id}">
                    <div class="form-group w-100">
                      <div class="floating-label floating-label-sm">
                        <label for="userNameForm">Nome Responsável</label>
                        <input id="userNameForm" aria-describedby="userNameFormHelp" class="form-control f-075" autofocus data-${this.controllerName}-target="userNameEditInput-${element.id}" data-filter-mode="simple" data-action="focus->${this.controllerName}#userNameFilter keyup->${this.controllerName}#userNameFilter keyup->${this.controllerName}#enterItem blur->${this.controllerName}#hideList" type="text" required>
                        <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="userNameFilter"></ul>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doAgentListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Responsáveis por Ano-Exercício</h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="cardBody">
                  </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").agentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.35) + "px"
      controller.listData()
    })
  }

  listUserName(id) {
    var html = ``
    Array.from(this.application.team_list).forEach(element => {
      html += `<li data-agent-id="${id}" data-action="click->${this.controllerName}#selectUserName" data-${this.controllerName}-target="userNameFilterItem-${id}" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.userNameFilterTarget.innerHTML = html
  }

  userNameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectUserName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.saveUserName(ev)
  }

  enterItem(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.saveUserName(ev)

    }
  }

  inlineEditUserName(ev) {
    var id = ev.currentTarget.closest(".rowItem").dataset.id
    this.listUserName(id)
    if (this.userCanUpdate()) {
      if (ev.type == "click") {
        var value = this.nameTarget(`userNameShow-${id}`).innerText
        this.nameTarget(`userNameShow-${id}`).classList.add("d-none")
        this.nameTarget(`userNameEdit-${id}`).classList.remove("d-none")
        this.nameTarget(`userNameEditInput-${id}`).value = value
        this.nameTarget(`userNameEditInput-${id}`).focus()
      } else {
        this.nameTarget(`userNameShow-${id}`).classList.remove("d-none")
        this.nameTarget(`userNameEdit-${id}`).classList.add("d-none")
      }
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  saveUserName(ev) {
    this.send_data = { current_user: {}, agent: {}, tracker: { manual: true } }
    // if (ev.type == "keyup" && ev.key == "Enter") {
    //   var id = ev.currentTarget.dataset.filter
    // } else if (ev.type == "click") {
    //   var id = ev.currentTarget.dataset.agentId
    // }
    
    var agentId = ev.currentTarget.closest(".rowItem").dataset.id


    var value = this.nameTarget(`userNameEditInput-${agentId}`).value.trim()
    
    if (value != this.nameTarget(`userNameShow-${agentId}`).innerText.trim()) {
      this.actionMode = `edit`

      this.send_data.agent.id = agentId
      this.send_data.agent.account_id = this.nameTarget(`userNameEditInput-${agentId}`).dataset.filter
      this.send_data.agent.account_name = this.nameTarget(`userNameEditInput-${agentId}`).dataset.text

      this.send_data.tracker.record_id = this.application.tax_filing.id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.application.current_date.id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.action = `changed`
      this.send_data.tracker.observations = `Adicionou ${this.send_data.agent.account_name} como Responsável do IRPF ${this.application.current_date.financial_year}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.application.agent_permission

      this.requestSave()
    } else {
      var agent = { id: agentId, account_name: value }
      this.closeEditUserName(agent)    
    }
  }

  closeEditUserName(agent) {
    this.nameTarget(`userNameShow-${agent.id}`).classList.remove("d-none")
    this.nameTarget(`userNameEdit-${agent.id}`).classList.add("d-none")
    this.nameTarget(`userNameShow-${agent.id}`).innerText = agent.account_name
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/operations/products/tax_filings/agents/update"
    var method = "PUT"
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var agent = response.data.cln
          controller.application.agents.forEach((element, i) => {
            if (element.id == agent.id) {
              controller.application.agents.splice(controller.application.agents.indexOf(element), 1, agent)
              controller.application.current_agent = agent
            }
          })
          
          controller.closeEditUserName(agent)
        } else {
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }
  
  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (controller.yearInputTarget.innerText == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  userCanUpdate() {
    if (this.application.permissions[this.application.product_permission].can_manage) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `operations--products--tax-filings--settings--agent`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
