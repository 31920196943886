import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--expenses--index`
    this.tableCols = 8
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `status_pretty` }
    this.hasShow = false

    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
    }

    // this.doIndexListHtml()
  }

  showExpense(ev) {
    var expenseId = ev.target.closest(".itemRow").dataset.id
    var controllerShow = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--show")
    this.application.expenses.forEach(element => {
      if (element.id == expenseId) {
        controllerShow.current_expense = element
      }
    })

    controllerShow.doExpenseHtml()
  }

  addExpense() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").actionMode = "new"
      this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").current_expense = {}
      this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 4000)
    }
  }

  uploadExpense(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.expenses.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--upload").current_expense = element
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--upload").actionMode = "edit"
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--upload").getExpense()
      }
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addExpense" class="dropdown-item py-1 pointer dropdown-submenu-item">Nova Despesa</span>`
    } else {
      var addLine = ``
    }
    
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshExpenses" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Despesas</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    // `<th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center" colspan="2">Método</th>`
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos as Despesas | ${this.application.current_date.name}</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Venc.
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="due_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="due_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle">Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Canal</th>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Valor</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 8))
      controller.doDataTable()
      
    })
  }

  doDataTable() {
    var current_expenses = []
    // this.application.expenses.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     console.log(element)
    //     current_expenses[current_expenses.length] = element
    //   }
    // })

    if (this.application.current_user.account_kind == `helper`) {
      this.application.expenses.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          current_expenses[current_expenses.length] = element
        }
      })
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.application.expenses.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          if (element.partner_ids.includes(this.application.current_partner.id.toString())) {
            current_expenses[current_expenses.length] = element
          }
        }
      })
    } else {
      this.application.expenses.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          current_expenses[current_expenses.length] = element
        }
      })
    }

    if (this.sort.mode == `asc`) {
      var expenses = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_expenses, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var expenses = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_expenses, this.sort.field)
    }

    if (expenses.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(expenses.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (expenses[x] !== undefined) {
            internPage[internPage.length] = expenses[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: expenses.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Despesas na ${this.application.current_date.name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.expenseTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  expenseTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_update) {
      var file = `<button data-action="click->${this.controllerName}#uploadExpense" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">attach_file</span>
                    <span class="mc-tooltiptext">NF/Comprovante</span>
                  </button>`
    } else {
      var file = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.paid) {
      var paid = `<span class="mc-tooltip badge badge-success">
                    <span class="material-icons md-200 md-white default">price_check</span>
                    <span class="mc-tooltiptext">Pago dia ${element.paid_at_pretty}</span>
                  </span>`
    } else {
      var paid = `<span class="mc-tooltip badge badge-danger">
                    <span class="material-icons md-200 md-white pointer" data-action="click->${this.controllerName}#checkDate">money_off_csred</span>
                    <span class="mc-tooltiptext">Não Pago</span>
                  </span>`
    }

    if (element.method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `transfer`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    }

    if (element.method == `bank_slip`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer" data-controller="app--helpers--copy" data-copy="${element.bank_line}" data-action="click->app--helpers--copy#copy">align_vertical_center</span>
                      <span class="mc-tooltiptext">Copiar Código de Barras</span>
                    </span>`
    } else if (element.method == `transfer`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">Transferência: ${element.bank_account}</span>
                    </span>`
    } else if (element.method == `pix`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">PIX: ${element.pix_key}</span>
                    </span>`
    } else if (element.method == `credit_card`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">Cartão: ${element.channel_name}</span>
                    </span>`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
    //                 <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${barcode}</td>`
    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showExpense">${element.due_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showExpense">${element.description}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.channel_name}</td>
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${paid}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${file}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  checkDate(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      this.findObj = true
      var expenseId = ev.currentTarget.closest(".itemRow").dataset.id
      this.application.expenses.forEach(element => {
        if (expenseId == element.id && element.token_tree && element.token_tree[0] == `r`) {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Despesa será quitada no momento do recebimento da Nota Fiscal!`, 5000)
          this.findObj = false
        }
      })
    }

    if (this.findObj) {
      this.doUpdatePaidModalHtml(expenseId)
    }
  }

  doUpdatePaidModalHtml(expenseId) {
    var modalController = `operations--products--clinics--financials--expenses--paid-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Pagamento de Despesa</strong></h6><br>
                      </div>
                      <div class="modal-body p-3">
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="principalAmountForm">Vlr Principal</label>
                                <input disabled aria-describedby="principalAmountFormHelp" class="form-control" id="principalAmountForm" data-${modalController}-target="principalAmountInput" placeholder="Valor Principal" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="finesAmountForm">Vlr Multa</label>
                                <input aria-describedby="finesAmountFormHelp" class="form-control" id="finesAmountForm" data-${modalController}-target="finesAmountInput" data-action="blur->${modalController}#currencyMask keypress->${modalController}#currencyMask keyup->${modalController}#currencyMask keyup->${modalController}#bindTotalAmount" placeholder="Valor Multa" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="interestsAmountForm">Vlr Juros</label>
                                <input aria-describedby="interestsAmountFormHelp" class="form-control" id="interestsAmountForm" data-${modalController}-target="interestsAmountInput" data-action="blur->${modalController}#currencyMask keypress->${modalController}#currencyMask keyup->${modalController}#currencyMask keyup->${modalController}#bindTotalAmount" placeholder="Valor Juros" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="totalAmountForm">Vlr Total</label>
                                <input disabled aria-describedby="totalAmountFormHelp" class="form-control" id="totalAmountForm" data-${modalController}-target="totalAmountInput" placeholder="Valor Total" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueAtForm">Vencimento</label>
                                <input disabled aria-describedby="dueAtFormHelp" class="form-control" id="dueAtForm" data-${modalController}-target="dueAtInput" placeholder="Agência" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="paidAtForm">Pagamento</label>
                                <input aria-describedby="paidAtFormHelp" class="form-control" id="paidAtForm" data-${modalController}-target="paidAtInput" placeholder="Agência" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Canal Pagamento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${modalController}-target="channelDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${modalController}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${modalController}-target="channelInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${modalController}-target="channelList" data-app--helpers--search-target="searchList">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updatePaid" data-${modalController}-target="saveBtn">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--expenses--paid-modal`).permission = this.permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--expenses--paid-modal`).open(expenseId)
    })

    // document.body.insertAdjacentHTML("beforeend", html)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--financials--expenses--show").doViewHtml()

    this.doDataTable()
  }

  refreshExpenses() { 
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 7)

    var data = { expense: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = `/operations/products/clinics/financials/expenses/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentIds = controller.mapElements(controller.application.expenses, `id`)
          var responseIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(responseElement => {
            if (currentIds.includes(responseElement.id) && responseIds.includes(responseElement.id)) {
              controller.application.expenses.forEach((element, i) => {
                if (element.id == responseElement.id) {
                  controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, responseElement)
                }
              })
            } else if (currentIds.includes(responseElement.id)) {
              controller.application.expenses.forEach((element, i) => {
                if (element.id == responseElement.id) {
                  controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1)
                }
              })
            } else if (responseIds.includes(responseElement.id)) {
              controller.application.expenses[controller.application.expenses.length] = responseElement
            }
          })

          controller.submenuController.doExpensesCount()
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
