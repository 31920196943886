import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "addPolicyDiv", "editPolicyDiv",
                    "featureDropdown", "featureDropdownBtn", "featureInput", "featureList",
                    "accountDropdown", "accountDropdownBtn", "accountInput", "accountList",
                    "policiesTable", "humanizeInput", "nameInput", "notesInput", "saveBtn", "deleteBtn",
                    "createTr", "updateTr", "deleteTr", "readTr", "listTr", "showTr",
                    "indexTr", "reportTr", "selectTr", "assignTr", "copyTr", "copy_textTr",
                    "downloadTr", "uploadTr", "manageTr", "emailTr", "notificationTr"]


  connect() {
    this.controllerName = `users--works--permissions--policies--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    // this.stopRefreshing()
    // this.getControllerByIdentifier("users--works--permissions--policies--dashboard").viewTarget.innerHTML = ``
    this.getControllerByIdentifier("users--works--permissions--policies--index").clearList()
  }

  addPolicy() {
    this.send_data = { current_user: {}, policy: {} }

    this.send_data.policy.feature_id = this.featureInputTarget.dataset.featureId
    this.send_data.policy.subdomain_id = this.current_subdomain.id
    this.send_data.policy.account_kind = this.accountInputTarget.dataset.accountKind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_permissions_policies`

    this.requestSave()
  }

  destroyPolicy(ev) {
    this.send_data = { current_user: {}, policy: {} }
    this.actionMode = `delete`

    this.send_data.policy.id = ev.currentTarget.dataset.policyId
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_permissions_policies`

    var r = confirm(`Tem certeza que deseja apagar a Política?`)
    if (r) {
      this.requestSave()
    }
  }

  savePolicy(ev) {
    this.send_data = { current_user: {}, policy: {} }

    this.send_data.policy.id = ev.currentTarget.dataset.policyId
    this.send_data.policy.action = ev.currentTarget.closest(`.actionRow`).dataset.action
    this.send_data.policy.value = ev.currentTarget.checked
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_permissions_policies`

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = "/users/permissions/policies/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/permissions/policies/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/permissions/policies/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          if (this.actionMode == `new`) {
            var policies = response.data.cln
            policies.forEach(policy => {
              controller.application.policies[controller.application.policies.length] = policy
            })

            controller.cancelSave()
            controller.getControllerByIdentifier("users--works--permissions--policies--index").doDataTable()
          } else if (this.actionMode == `edit`) {
            // var policy = response.data.cln
            var policies = response.data.cln
            policies.forEach(policy => {
              controller.application.policies.forEach((element, i) => {
                if (element.id == policy.id) {
                  controller.application.policies.splice(controller.application.policies.indexOf(element), 1, policy)
                }
              })
              controller.current_policies.forEach((element, i) => {
                if (element.id == policy.id) {
                  controller.current_policies.splice(controller.current_policies.indexOf(element), 1, policy)
                }
              })
            })

            controller.setPolicies()
            controller.getControllerByIdentifier(`users--works--permissions--policies--index`).doDataTable()
          } else if (this.actionMode == `delete`) {
            var policies = response.data.cln
            policies.forEach(policy => {
              controller.application.policies.forEach((element, i) => {
                if (element.id == policy.id) {
                  controller.application.policies.splice(controller.application.policies.indexOf(element), 1)
                }
              })
              controller.current_policies.forEach((element, i) => {
                if (element.id == policy.id) {
                  controller.current_policies.splice(controller.current_policies.indexOf(element), 1)
                }
              })
            })

            controller.cancelSave()
            controller.getControllerByIdentifier("users--works--permissions--policies--index").doDataTable()
          }
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create && this.actionMode == `new`) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#addPolicy" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyPolicy" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center d-none" data-${this.controllerName}-target="addPolicyDiv">
                      <div class="col-12 mb-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Funcionalidade</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="featureDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="featureDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="featureInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="featureList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo de Conta</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="accountDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="accountDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="accountInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="accountList" data-app--helpers--search-target="searchList">
                                  <li data-account-kind="admin" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Administrador</li>
                                  <li data-account-kind="team" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Time</li>
                                  <li data-account-kind="doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Médico</li>
                                  <li data-account-kind="helper" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assistente</li>
                                  <li data-account-kind="patient" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Paciente</li>
                                  <li data-account-kind="taker" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Tomador</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2 d-flex align-items-center d-none" data-${this.controllerName}-target="editPolicyDiv">                      
                      <div class="col-1 text-right px-0">
                        <span class="p-0 mc-tooltip help">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right" data-${this.controllerName}-target="notesInput"></span>
                        </span>
                      </div>
                      

                      <div class="col-11">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Enum</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Enum" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-12" data-${this.controllerName}-target="policiesTable"></div>
                    </div>
                    
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--policies--dashboard").viewTarget.innerHTML = html)
    }).then(() => {

      // `<div class="col-6">
      //   <div class="form-group">
      //     <div class="floating-label floating-label-sm">
      //       <label for="humanizeForm">Nome</label>
      //       <input aria-describedby="humanizeFormHelp" class="form-control form-valid-control" id="humanizeForm" data-${this.controllerName}-target="humanizeInput" placeholder="Nome" type="text" required>
      //     </div>
      //   </div>
      // </div>`

      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Modelo de Política | ${this.current_subdomain.humanize}`
        controller.addPolicyDivTarget.classList.remove(`d-none`)

        controller.setFeatures()
        controller.refreshSaveBtn()

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Modelo de Política | ${this.current_policy.account_kind_pretty} | ${this.current_subdomain.humanize}`

        // controller.humanizeInputTarget.value = controller.current_policy.feature_humanize
        controller.nameInputTarget.value = controller.current_policy.feature_name
        controller.notesInputTarget.innerText = controller.current_policy.feature_notes

        // controller.humanizeInputTarget.disabled = true
        controller.nameInputTarget.disabled = true
        // controller.notesInputTarget.disabled = true

        if (controller.hasDeleteBtnTarget) {
          controller.deleteBtnTarget.dataset.policyId = controller.current_policy.id
        }
        controller.editPolicyDivTarget.classList.remove(`d-none`)
        controller.setPolicies()

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  setFeatures() {
    var html = ``

    this.application.features.forEach(feature => {
      html += `<li data-feature-id="${feature.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${feature.humanize} | ${feature.name}</li>`
    });

    this.featureListTarget.innerHTML = html
  }

  setPolicies() {

    var html = `<table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                  <thead>
                    <tr>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-40 align-middle">Ação</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Básico</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Simples</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Avançado</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Master</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="actionRow" data-${this.controllerName}-target="createTr" data-action="create"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="updateTr" data-action="update"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="deleteTr" data-action="delete"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="readTr" data-action="read"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="listTr" data-action="list"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="showTr" data-action="show"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="indexTr" data-action="index"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="manageTr" data-action="manage"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="assignTr" data-action="assign"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="downloadTr" data-action="download"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="uploadTr" data-action="upload"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="reportTr" data-action="report"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="emailTr" data-action="email"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="notificationTr" data-action="notification"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="selectTr" data-action="select"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="copyTr" data-action="copy"></tr>
                    <tr class="actionRow" data-${this.controllerName}-target="copy_textTr" data-action="copy_text"></tr>
                  </tbody>
                </table>`

    this.policiesTableTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.policiesTableTarget.innerHTML = html)
    }).then(() => {
      controller.setPoliciesValues()
    })
  }

  setPoliciesValues() {
    var actions = ["create", "update", "delete", "read", "list", "show", "index", "report", "select", "assign", "copy", "copy_text", "download", "upload", "manage", "email", "notification"]
    var kinds = ["basic", "simple", "advanced", "master"]

    actions.forEach(action => {
      var tdHtml = `<td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                        ${this.actionTranslate(action)}  
                      </td>`
      this.nameTarget(`${action}Tr`).insertAdjacentHTML("beforeend", tdHtml)
    })

    
    kinds.forEach(kind => {
      actions.forEach(action => {
        this.current_policies.forEach(policy => {
          if (policy.policy_kind == kind) {
            var tdHtml = `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="check-${policy.id}-${action}" data-${this.controllerName}-target="check-${policy.id}-${action}" data-policy-id="${policy.id}" data-action="click->${this.controllerName}#savePolicy">
                          <label class="custom-control-label f-065 pointer" for="check-${policy.id}-${action}"></label>
                        </div>
                      </td>`

            this.nameTarget(`${action}Tr`).insertAdjacentHTML("beforeend", tdHtml)
          }
        })
      })
    })

    this.current_policies.forEach(policy => {
      actions.forEach(action => {
        this.nameTarget(`check-${policy.id}-${action}`).checked = policy[`can_${action}`]
      })
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.featureInputTarget && controller.featureInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.accountInputTarget && controller.accountInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  actionTranslate(action) {
    var translate = ``
    var actions = [
                    { name: "create", translate: "Criar" },
                    { name: "update", translate: "Atualizar" },
                    { name: "delete", translate: "Apagar" },
                    { name: "read", translate: "Ler" },
                    { name: "list", translate: "Listar" },
                    { name: "show", translate: "Show" },
                    { name: "index", translate: "Index" },
                    { name: "report", translate: "Relatórios" },
                    { name: "select", translate: "Selecionar" },
                    { name: "assign", translate: "Atribuir" },
                    { name: "copy", translate: "Copiar" },
                    { name: "copy_text", translate: "Copiar Texto" },
                    { name: "download", translate: "Download" },
                    { name: "upload", translate: "Upload" },
                    { name: "manage", translate: "Equipe/Gerenciar" },
                    { name: "email", translate: "E-mail" },
                    { name: "notification", translate: "Notification" },
                  ]


    actions.forEach(element => {
      if (element.name == action) {
        translate = element.translate
      }
    });

    return translate
  }

}
