import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "title", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--trackers--stories--tickets`
    this.blockedAdd = false
  }

  doTicketsDashboard() {
    var html = `<div class="col-12 pl-1" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.titleTarget.innerHTML = html)
    }).then(() => {

      var teamList = []
      controller.application.story.project.squads.forEach(element => {
        teamList[teamList.length] = { name: element.team_name, id: element.team_id }
      })

      controller.application.team_list = teamList

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = `user_works_trackers_tickets`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).blockedAdd = this.blockedAdd
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).sharing = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).kind = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).term = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 3
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: this.application.story.id,
        board_type: `tracker_stories`,
        // board_subtype: `${this.application.story.product_name}`,
        board_path: window.location.pathname,
        board_name: `Projeto: ${this.application.story.project.name} | Entregável: ${this.application.story.name}`,
        // date_id: this.application.story.date_id,
        // date_type: `operation_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: this.application.story.id,
        board_type: `tracker_stories`,
        // date_id: this.application.story.date_id,
        // date_type: `operation_dates`
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}