import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewActivityCard", "viewTitle", "viewActivityCardBody", "principalShow", "contactShow", "principalTab", "infoTab", "contactTab", "profileTab",
                    "viewActivityCardFooter", "infoShow", "profileShow"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--activities--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewActivityCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Atividade Econômica Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewActivityCardBody" id="cardContactBody">
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--regularizations--activities--dashboard").viewTarget.innerHTML = html
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewActivityCard"]
    var identifier = `${this.controllerName}`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
