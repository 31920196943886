
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "mainRow", "content", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `web--commercial--marketing--content--search--main`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.application.size = ``
    this.doPageGrid()
  }

  loadPage() {
    this.getControllerByIdentifier("web--site--principal--frames--header").doHtml()
    this.getControllerByIdentifier("web--commercial--marketing--content--search--principal").gridDisplay()
    this.getControllerByIdentifier("web--site--principal--frames--footer").doHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  loadMobilePage() {
    this.getControllerByIdentifier("mobile--site--principal--frames--header").doHtml()
    this.getControllerByIdentifier("mobile--commercial--marketing--content--search--principal").gridDisplay()
    this.getControllerByIdentifier("mobile--site--principal--frames--footer").doHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  doPageGrid() {
    var html = `<div class="row" data-action="resize@window->${this.controllerName}#setLayout" data-${this.controllerName}-target="mainRow"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doLoadModalHtml()
      controller.getSite()
    })
  }

  doLoadModalHtml() {
    var controller = `web--site--principal--frames--preloader`

    var html = `<div class="modal fade" data-hour="bottom" data-controller="${controller}" data-${controller}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-${controller}-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Carregando a melhor experiência!</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <img class="mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/favicon.png" alt="logo-medcapital" style="width:5rem;">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  getSite() {
    this.send_data = { site: {} }
    this.send_data.site.kind = `blog`

    var url = "/commercial/marketing/sites/entities/read_blog_public"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.site = response.data.cln
          controller.getRoom()
        } else {
          controller.setLayout()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setLayout() {
    var x = window.matchMedia("(max-width: 576px)");

    if (x.matches) { // If media query matches
      this.application.size = `mobile`
    } else {
      x = window.matchMedia("(max-width: 768px)");
      if (x.matches) { // If media query matches
        this.application.size = `small`
      } else {
        x = window.matchMedia("(max-width: 992px)");
        if (x.matches) { // If media query matches
          this.application.size = `medium`
        } else {
          this.application.size = `large`
        }
      }
    }
    this.doSizeHtml()
  }

  doSizeHtml() {
    var html = ``
    if (this.application.size == `mobile`) {
      html += `<div class="col-12 px-0" data-controller="mobile--site--principal--frames--header
                                                         mobile--commercial--marketing--content--search--principal
                                                         mobile--site--principal--frames--footer" data-${this.controllerName}-target="content"></div>`
    } else {
      html += `<div class="col-12 px-0" data-controller="web--site--principal--frames--header
                                                         web--commercial--marketing--content--search--principal
                                                         web--site--principal--frames--footer" data-${this.controllerName}-target="content"></div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainRowTarget.innerHTML = html)
    }).then(() => {
      controller.contentTarget.innerHTML = ``
      if (controller.application.size == `mobile`) {
        if (controller.application.site) {
          controller.loadMobilePage()
        } else {
          controller.loadMobileBackupPage()
        }
      } else {
        if (controller.application.site) {
          controller.loadPage()
        } else {
          controller.loadBackupPage()
        }
      }
    })
  }

  loadBackupPage() {
    this.getControllerByIdentifier("web--site--principal--frames--header").doBackupHtml()
    this.getControllerByIdentifier("web--site--principal--frames--footer").doBackupHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  loadMobileBackupPage() {
    this.getControllerByIdentifier("mobile--site--principal--frames--header").doHtml()
    this.getControllerByIdentifier("mobile--site--principal--frames--principal").doHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  setLink(originalLink) {
    var link = ``

    if (originalLink.includes("https://")) {
      link = originalLink
    } else {
      link = `https://${originalLink}`
    }

    return link
  }

  getRoom() {
    var controllerBroadcast = this.getControllerByIdentifier(`web--commercial--marketing--content--search--broadcast`)

    var data = { room: { kind: `blog` } }
    const url = "/commercial/marketing/sites/entities/get_principal_public_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controllerBroadcast.application.rooms[controller.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln
          controllerBroadcast.broadcastUpdateChannel()

          controller.setLayout()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
