import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--sites--bios--dashboard`
    this.controllerName = `commercial--marketing--sites--bios--dashboard`
  }

  doBiosDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-7 pr-1" data-${this.controllerName}-target="list" data-controller="commercial--marketing--sites--bios--index"></div>
                  <div class="col-5 pl-1" data-${this.controllerName}-target="view" data-controller="commercial--marketing--sites--bios--save commercial--marketing--sites--bios--show"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--sites--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--marketing--sites--show--submenu`).dashboardToggleBtn(`MINI-BIOS`)

      controller.getControllerByIdentifier(`commercial--marketing--sites--bios--index`).permission = `marketing_site_teams`
      controller.getControllerByIdentifier(`commercial--marketing--sites--bios--show`).permission = `marketing_site_teams`
      controller.getControllerByIdentifier(`commercial--marketing--sites--bios--save`).permission = `marketing_site_teams`

      controller.getControllerByIdentifier(`commercial--marketing--sites--bios--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`commercial--marketing--sites--bios--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
