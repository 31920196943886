import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["achievementDashboard", "taxReturnDashboard", "mainDashboard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--entities--dashboard`
  }

  reconnect() {
    this.doTaxReturnDashboard()
    this.doAchievementsHtml()
    this.doTaxReturnCalculationsHtml()
  }

  doAchievementsHtml() {

    if (this.application.achievements) {
      this.application.achievements.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_achievement = element
        }
      });
    } else {
      this.current_achievement = { total_work_incomes: 0, saving_rate_goal: 0, saving_rate_real: 0 }
    }

    var html = `<h6 class="mb-0 text-center">Meu Planner Financeiro</h6>
                  <hr class="mt-1">
                  <div class="row">
                    <div class="col-12 px-0">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Total Rendimentos</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_achievement.total_work_incomes)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6 pl-0">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Minha Meta</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_goal * 10000).toString())}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-0">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Aporte Real</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_real * 10000).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-5">
                    <div class="col-12 px-0">
                      <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToAchievements"><a class="a-dark">Veja seus Objetivos</a></button>
                    </div>
                  </div>`

    this.achievementDashboardTarget.innerHTML = html
  }

  doTaxReturnCalculationsHtml() {

    if (this.application.calculations) {
      this.application.calculations.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_calculation = element
        }
      });
    } else {
      this.current_calculation = { legal_income: 0, individual_income: 0, exempt_income: 0, booking: 0, health: 0, pension: 0, social: 0, others: 0, total_tax: 0, adjusted_tax: 0, tax_rate: 0, tax_statement_pretty: "" }
    }

    if (this.current_calculation.total_tax - this.current_calculation.adjusted_tax > 0) {
      var finalTax = this.current_calculation.total_tax - this.current_calculation.adjusted_tax
    } else {
      var finalTax = this.current_calculation.adjusted_tax - this.current_calculation.total_tax
    }

    var html = `<div class="row">
                  <div class="col-2 pl-0">
                    <h6 class="mb-0 text-center pointer" data-action="click->${this.controllerName}#goToIncomes">Rendimentos</h6>
                    <hr class="mt-1">
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Rend. Trib. PJ</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.legal_income)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Rend. Trib. PF</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.individual_income)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Rend. Isentos</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.exempt_income)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <h6 class="mb-0 text-center pointer" data-action="click->${this.controllerName}#goToPayments">Despesas Dedutíveis</h6>
                    <hr class="mt-1">
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Livro-Caixa</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.booking)}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Saúde</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.health)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Previdência Privada</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.pension)}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Previdência Social</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.total_social)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Educação</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.education)}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Outros</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.others)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <h6 class="mb-0 text-center">Impostos</h6>
                    <hr class="mt-1">
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Retido/Pago</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.total_tax)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Devido</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_calculation.adjusted_tax)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <h6 class="mb-0 text-center">Apuração</h6>
                    <hr class="mt-1">
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Ajuste Final</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(finalTax)}</span>
                            <br>
                            <span class="text-right text-mute mt-5"><strong>${this.current_calculation.tax_statement_pretty}</strong></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>Alíquota Efetiva</span>
                          </div>
                          <div class="card-body text-center f-070 px-1">
                            <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_calculation.tax_rate * 100).toString())}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.taxReturnDashboardTarget.innerHTML = html
  }

  doTaxReturnDashboard() {
    var loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-3" data-${this.controllerName}-target="achievementDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-9" data-${this.controllerName}-target="taxReturnDashboard">
                    <div class="row">
                      <div class="col-2 pl-0">
                        <h6 class="mb-0 text-center">${loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span >${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <h6 class="mb-0 text-center">${loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-3">
                          <div class="col-6 pl-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-6 pl-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pl-0 my-3">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-0 my-3">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <h6 class="mb-0 text-center">${loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 pr-0">
                        <h6 class="mb-0 text-center">${loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                                <br>
                                <span class="text-right text-mute mt-5">${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-12 px-0">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-070">
                                <span>${loader}</span>
                              </div>
                              <div class="card-body text-center f-070 px-1">
                                <span>${loader}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--entities--show").contentTarget.innerHTML = html
  }

  goToAchievements() {
    this.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").doAchievementsDashboard()
  }

  goToIncomes() {
    this.getControllerByIdentifier("operations--products--tax-returns--incomes--dashboard").doIncomesDashboard()
  }

  goToPayments() {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").doPaymentsDashboard()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
