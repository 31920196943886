import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "viewBillCard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--cards--bills--show`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.closeBill = false
    this.payBill = false
  }

  doNoBill() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewBillCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhuma Fatura Selecionada</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--cards--bills--dashboard").showTarget.innerHTML = html
  }

  showBill() {
    var html = ``
    var openBillHtml = ``

    if (this.current_bill.invoice == `closed`) {
      openBillHtml += `<span class="mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-outline my-0 py-0" data-action="click->${this.controllerName}#openBill" type="button">
                        <span class="material-icons md-dark">lock_open</span>
                      </button>
                      <span class="mc-tooltiptext">Abrir Fatura</span>
                    </span>`
    }

    html += `<div class="card pointer mt-3">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Status</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075 mc-tooltip">
                  <span data-action="click->${this.controllerName}#updateBill"><span class="badge badge-${this.current_bill.invoice_css_class} mx-2">${this.current_bill.invoice_pretty}</span></span>
                  <span class="mc-tooltiptext mc-tooltiptext-right">${this.current_bill.invoice_next_pretty}</span>
                </span>
                ${openBillHtml}
              </div>
            </div>
            <div class="card pointer mt-3">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Fechamento</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center" data-action="click->${this.controllerName}#updateClosingDate">
                <span class="mb-0 f-075">
                  ${this.current_bill.closing_date_pretty}
                </span>
              </div>
            </div>
            <div class="card pointer mt-3">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Vencimento</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.current_bill.due_date_pretty}
                </span>
              </div>
            </div>
            <div class="card pointer mt-3">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Total</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_bill.balance)}
                </span>
              </div>
            </div>`

    this.getControllerByIdentifier("financials--books--cards--bills--dashboard").showTarget.innerHTML = html
  }

  updateBill() {
    if (this.current_bill.invoice == `closed`) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor confirmar o pagamento na área de Pagamentos`, 4000)
    } else if (this.current_bill.invoice == `paid`) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`success`, `Fatura já está paga!`, 4000)
    } else if (this.current_bill.invoice == `open`) {
    
      var r = confirm(this.current_bill.invoice_next_pretty)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.actionMode = `close_bill`

        this.send_data = { bill: {}, current_user: {}, ticket: {} }
        
        this.send_data.bill.id = this.current_bill.id
        this.send_data.bill.invoice = "closed"

        this.send_data.ticket.board_id = this.application.current_date.id
        this.send_data.ticket.board_type = `financial_payables`
        this.send_data.ticket.board_subtype = ``
        this.send_data.ticket.board_path = window.location.pathname
        this.send_data.ticket.board_name = `Contas à Pagar - ${this.application.current_date.name}`
        this.send_data.ticket.date_id = this.application.current_date.id
        this.send_data.ticket.date_type = `financials_dates`
        this.send_data.ticket.owner_id = this.application.current_user.account_id
        this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
        this.send_data.ticket.performer_id = this.application.current_user.account_id
        this.send_data.ticket.performer_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
        this.send_data.ticket.stage = `in_process`
        this.send_data.ticket.status = `in_time`
        this.send_data.ticket.sharing = `internal`
        this.send_data.ticket.term = `quick`
        this.send_data.ticket.priority = `maximum`
        this.send_data.ticket.body = `Pgt. Ref. ${this.current_bill.name} | ${this.controllerNumber.currencyOptionMask(this.current_bill.balance) }`
        this.send_data.ticket.due_at = this.current_bill.due_date
        this.send_data.ticket.started_at = this.current_bill.closing_date
        this.send_data.ticket.flag = `blue`
        this.send_data.ticket.kind = `task`

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `financial_payable_card_bills`

        this.requestSave()
      }
    }    
  }

  openBill() {
    if (this.current_bill.invoice == `closed`) {
      var r = confirm(`Confirma a Abertura da Fatura?`)

      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.actionMode = `open_bill`

        this.send_data = { bill: {}, current_user: {} }

        this.send_data.bill.id = this.current_bill.id
        this.send_data.bill.invoice = "open"

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `financial_payable_card_bills`

        this.requestSave()
      }
    }    
  }

  requestSave() {
    if (this.actionMode == `close_bill`) {
      var url = "/financials/books/cards/bills/close_bill"
      var method = "PUT"
    } else if (this.actionMode == `open_bill`) {
      var url = "/financials/books/cards/bills/open_bill"
      var method = "PUT"
    } else if (this.actionMode == `pay_bill`) {
      var url = "/financials/books/cards/bills/update_paid"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.current_bill = response.data.cln
          controller.application.bills.forEach((element, i) => {
            if (element.id == controller.current_bill.id) {
              controller.application.bills.splice(controller.application.bills.indexOf(element), 1, controller.current_bill)
            }
          })
          controller.showBill()
          controller.closeBill = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateClosingDate() {
    var modalController = `financials--books--cards--bills--update-closing-date`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Fatura ${this.current_bill.name}</strong></h6><br>
                      </div>
                      <div class="modal-body p-3">
                        <div class="row my-2">
                          <div class="col-8 offset-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="closingForm">Data Fechamento</label>
                                <input aria-describedby="closingFormHelp" class="form-control" id="closingForm" data-${modalController}-target="closingInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updateClosingDate" data-${modalController}-target="saveBtn">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalController).open(this.current_bill.id)
    })
  }

  showBillLoader() {
    var html = `<div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110">${this.loader}</h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center" style="overflow:auto;">
                    ${this.loader}
                  </div>
                </div>
                <div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110">${this.loader}</h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center" style="overflow:auto;">
                    ${this.loader}
                  </div>
                </div>
                <div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110">${this.loader}</h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center" style="overflow:auto;">
                    ${this.loader}
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--cards--bills--dashboard").showTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
