import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "kindDropdown", "cpfCheckbox", "saveBtn",
                    "cnpjCheckbox", "idNumberInput", "cnpjInput", "cnpj", "cpfInput", "cpf", "iuguMainInput", "errorMessage",
                    "emailInput", "startedAtInput", "mainCard", "footerTable", "ibgeInput", "bodyTable",
                    "zipInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "streetInput", "numberInput",
                    "complementInput", "districtInput", "stateInput", "stateFilter", "cityInput", "cityFilter", "saveBtn",
                    "iuguIdsRow", "iuguIdsMain", "iuguSecondaryInput"]


  connect() {
    this.controllerName = `financials--books--clients--entities--save`
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
  }

  cancelSave() {    
    if (this.getControllerByIdentifier(`financials--books--dashboards--clients--dashboard`)) {
      this.stopRefreshing()
      this.getControllerByIdentifier("financials--books--clients--entities--index").doClientList()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--client`)) {
      this.getControllerByIdentifier("financials--books--receivables--conciliations--modal--main").setClient()
    } else if (this.getControllerByIdentifier(`financials--books--clients--entities--dashboard`)) {
      this.getControllerByIdentifier("financials--books--clients--entities--dashboard").doClientsDashboard()
    }
  }

  saveClient() {
    this.send_data = { current_user: {}, client: {}, contact: {}, tracker: { manual: true } }
    this.saveBtnTarget.disabled = true
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.client.id = this.current_client.id
      this.send_data.client.record_id = this.current_client.record_id
      this.send_data.contact.record_id = this.current_client.record_id
      if (this.current_client.email) {
        this.send_data.contact.email_id = this.current_client.email.id
      }
      if (this.current_client.address) {
        this.send_data.contact.address_id = this.current_client.address.id
      }

      this.send_data.tracker.record_id = this.current_client.id
      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou Cliente: ${this.nameInputTarget.value}`
    } else {
      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou Cliente: ${this.nameInputTarget.value}`
    }

    if (this.record.address) { this.send_data.contact.address_id = this.record.address.id }
    this.send_data.contact.postal_code = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value.trim())
    this.send_data.contact.street = this.streetInputTarget.value.trim()
    this.send_data.contact.ibge = this.ibgeInputTarget.value.trim()
    this.send_data.contact.number = this.numberInputTarget.value.trim()
    this.send_data.contact.complement = this.complementInputTarget.value.trim()
    this.send_data.contact.district = this.districtInputTarget.value.trim()
    this.send_data.contact.city = this.cityInputTarget.value
    this.send_data.contact.state = this.stateInputTarget.dataset.filter
    this.send_data.contact.country = `Brasil`
    this.send_data.contact.kind_address = this.kindInputTarget.dataset.kind
    this.send_data.contact.is_main = true
    this.send_data.contact.notes = ``
    this.send_data.contact.record_id = this.record.id

    if (this.record.email && this.record.email.id) {
      this.send_data.contact.email_id = this.record.email.id
      if (this.emailInputTarget.dataset.previousValue != this.emailInputTarget.value.trim()) {
        this.send_data.contact.email_action = `update`
        this.send_data.contact.email_address = this.emailInputTarget.value.trim()
      } else {
        this.send_data.contact.email_action = `do_nothing`
      }
    } else if (this.send_data.contact.email_id) {
      if (this.emailInputTarget.dataset.previousValue != this.emailInputTarget.value.trim()) {
        this.send_data.contact.email_action = `update`
        this.send_data.contact.email_address = this.emailInputTarget.value.trim()
      } else {
        this.send_data.contact.email_action = `do_nothing`
      }
    } else {
      if (this.emailInputTarget.value != ``) {
        this.send_data.contact.email_action = `create`
        this.send_data.contact.email_address = this.emailInputTarget.value.trim()
        this.send_data.contact.kind_email = `personal`
        this.send_data.contact.is_main_email = true
      } else {
        this.send_data.contact.email_action = `do_nothing`
      }
    }

    if (this.cpfCheckboxTarget.checked) {
      this.send_data.client.person_type = "individual"
      this.send_data.client.record_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfTarget.value)
      this.send_data.contact.record_kind = `account_entities`
    } else if (this.cnpjCheckboxTarget.checked) {
      this.send_data.client.person_type = "legal"
      this.send_data.client.record_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cnpjTarget.value)
      this.send_data.contact.record_kind = `company_entities`
    }

    if (this.record.id) {
      this.send_data.client.record_id = this.record.id
    }

    this.send_data.client.record_name = this.nameInputTarget.value
    this.send_data.client.record_email = this.emailInputTarget.value.trim()
    this.send_data.client.id_iugu = this.iuguMainInputTarget.value
    this.send_data.client.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.startedAtInputTarget.value}`)
    this.send_data.client.status = `active`

    var iuguIds = []
    this.iuguSecondaryInputTargets.forEach(element => {
      if (element.dataset.kind == `span`) {
        iuguIds[iuguIds.length] = element.innerText
      } else {
        iuguIds[iuguIds.length] = element.value
      }
    })

    this.send_data.client.iugu_ids = iuguIds

    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_id = ``
    this.send_data.tracker.obj_type = ``
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/clients/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/clients/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var client = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.clients[controller.application.clients.length] = client
          } else if (controller.actionMode == `edit`) {
            controller.application.clients.forEach((element, i) => {
              if (element.id == client.id) {
                controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
              }
            })
          }

          if (controller.getControllerByIdentifier(`financials--books--dashboards--clients--dashboard`)) {
            controller.cancelSave()
          } else if (controller.getControllerByIdentifier(`financials--books--clients--entities--dashboard`)) {
            controller.cancelSave()
          } else if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--client`)) {
            controller.getControllerByIdentifier("financials--books--receivables--conciliations--modal--main").setClient()
          }
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()
    this.record = {}

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveClient" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var addHtml = ``
    if (this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--client`)) {
      addHtml += `<div class="card-actions ml-auto py-0 mc-tooltip">
                   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline btn-sm my-0" data-action="click->financials--books--receivables--conciliations--modal--client#copyClient" type="button">
                     <span class="material-icons md-dark">copy_all</span>
                   </button>
                   <span class="mc-tooltiptext">Preencher com Dados do Cliente</span>
                 </div>`
    }

    var html = `<div class="col-12">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      ${addHtml}
                    </div>
                    <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row mt-2">
                        <div class="col-5 px-0">
                          <div class="row mt-2">
                            <div class="col-6 d-flex align-items-center" data-${this.controllerName}-target="idNumberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                            <div class="col-6 d-flex align-items-center">
                              <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                                <input type="checkbox" class="custom-control-input" id="clientCpfCheck" data-kind="individual" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheckEv">
                                <label class="custom-control-label f-065" for="clientCpfCheck">CPF</label>
                              </div>
                              <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                                <input type="checkbox" class="custom-control-input" id="clientCnpjCheck" data-kind="legal" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheckEv">
                                <label class="custom-control-label f-065" for="clientCnpjCheck">CNPJ</label>
                              </div>
                            </div>
                            <div class="col-6 pr-0 d-none" data-${this.controllerName}-target="cnpjInput">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="cnpjForm">CNPJ do Cliente</label>
                                  <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate keyup->${this.controllerName}#searchAccountEv blur->${this.controllerName}#searchAccountEv" placeholder="CNPJ do Cliente" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 pr-0 d-none" data-${this.controllerName}-target="cpfInput">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="cpfForm">CPF do Cliente</label>
                                  <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate keyup->${this.controllerName}#searchAccountEv blur->${this.controllerName}#searchAccountEv" placeholder="CPF do Cliente" type="text" required>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12 my-2">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="nameForm">Nome</label>
                                  <input disabled aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-8 my-2 pr-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="iuguForm">ID Principal Conta Iugu</label>
                                  <input aria-describedby="iuguFormHelp" class="form-control" id="iuguForm" data-${this.controllerName}-target="iuguMainInput" placeholder="ID Principal Conta Iugu" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-4 my-2">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="startedAtForm">Início °</label>
                                  <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="startedAtInput" placeholder="MM/AAAA" type="text" required>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2" data-${this.controllerName}-target="iuguIdsRow"></div>
                        </div>
                        <div class="col-6 px-0">
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="emailForm">E-mail</label>
                                  <input disabled aria-describedby="emailFormHelp" class="form-control" id="emailForm" data-${this.controllerName}-target="emailInput" placeholder="E-mail" type="text" required>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-6" data-${this.controllerName}-target="takerInfo">
                              <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                                <tbody>
                                  <tr class="itemRow">
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                  </tr>
                                  <tr class="itemRow">
                                    <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="zipInput">CEP</label>
                                        <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask focus->${this.controllerName}#searchZip blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                                      </div>
                                    </td>
                                    <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label>Tipo</label>
                                        <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                                            <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                            <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                            <ul class="ul-select" data-${this.controllerName}-target="kindList">
                                              <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                              <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle pl-4">
                                      <div class="floating-label floating-label-sm">
                                        <label for="ibgeInput">IBGE</label>
                                        <input id="ibgeInput" data-${this.controllerName}-target="ibgeInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                                      </div>
                                    </td>
                                    <td colspan="1" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                    </td>
                                  </tr>
                                  <tr class="itemRow">
                                    <td colspan="5" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="streetInput">Logradouro</label>
                                        <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                      </div>
                                    </td>
                                    <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="numberInput">Número</label>
                                        <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                      </div>
                                    </td>
                                    <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="complementInput">Complemento</label>
                                        <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="itemRow">
                                    <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="districtInput">Bairro</label>
                                        <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                      </div>
                                    </td>
                                    <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="stateForm">UF</label>
                                        <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                                        <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                                          <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                                          <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                                          <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                                          <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                                          <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                                          <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                                          <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                                          <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                                          <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                                          <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                                          <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                                          <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                                          <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                                          <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                                          <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                                          <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                                          <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                                          <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                                          <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                                          <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                                          <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                                          <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                                          <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                                          <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                                          <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                                          <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                                          <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                      <div class="floating-label floating-label-sm">
                                        <label for="cityForm">Cidade</label>
                                        <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                                        <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          
                        </div>
                      </div>




                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      ${saveBtnHtml}
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--client`)) {
        controller.formCardTarget.classList.add(`mt-4`)
      }

      controller.formCardTarget.style.height = ($(window).height() * 0.55) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Cliente`
        controller.nameInputTarget.value = controller.current_client.record_name
        controller.emailInputTarget.value = controller.current_client.record_email
        controller.iuguMainInputTarget.value = controller.current_client.id_iugu
        controller.startedAtInputTarget.value = controller.current_client.started_at_pretty
        controller.cpfCheckboxTarget.disabled = true
        controller.cnpjCheckboxTarget.disabled = true
        controller.cnpjTarget.disabled = true
        controller.cpfTarget.disabled = true
        controller.nameInputTarget.disabled = false
        controller.emailInputTarget.disabled = false

        if (controller.current_client.person_type == `individual`) {
          controller.cpfCheckboxTarget.checked = true
          controller.cnpjCheckboxTarget.checked = false
          controller.idNumberInputTarget.classList.add("d-none")
          controller.cnpjInputTarget.classList.add("d-none")
          controller.cpfInputTarget.classList.remove("d-none")
          controller.cnpjTarget.value = ``
          controller.cpfTarget.value = controller.current_client.record_number_pretty
        } else if (controller.current_client.person_type == `legal`) {
          controller.cpfCheckboxTarget.checked = false
          controller.cnpjCheckboxTarget.checked = true
          controller.idNumberInputTarget.classList.add("d-none")
          controller.cnpjInputTarget.classList.remove("d-none")
          controller.cpfInputTarget.classList.add("d-none")
          controller.cnpjTarget.value = controller.current_client.record_number_pretty
          controller.cpfTarget.value = ``
        }

        if (controller.current_client.address) {
          controller.zipInputTarget.value = controller.current_client.address.postal_code_pretty
          controller.ibgeInputTarget.value = controller.current_client.address.ibge
          controller.streetInputTarget.value = controller.current_client.address.street
          controller.numberInputTarget.value = controller.current_client.address.number
          controller.complementInputTarget.value = controller.current_client.address.complement
          controller.districtInputTarget.value = controller.current_client.address.district
          controller.cityInputTarget.value = controller.current_client.address.city
          controller.cityInputTarget.dataset.filter = controller.current_client.address.filter
          controller.stateInputTarget.value = controller.current_client.address.state
          controller.stateInputTarget.dataset.filter = controller.current_client.address.state
          controller.kindInputTarget.dataset.kind = controller.current_client.address.kind
          controller.kindInputTarget.innerText = controller.current_client.address.kind_pretty
          controller.kindDropdownTarget.value = controller.current_client.address.kind_pretty
        }

        // controller.searchAccount(controller.current_client.record_number)

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Cliente`

        if (controller.application.current_date && controller.application.current_date.date_accrual) {
          controller.startedAtInputTarget.value = controller.application.current_date.date_accrual
        }

      }

      // controller.setIuguIds()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setIuguIds() {
    var idHtml = ``
    if (this.actionMode == `edit`) {
      this.current_client.iugu_ids.forEach((iugu_id, i) => {
        var kind = ``
        if (i == 0) {
          kind = `Principal`
        } else {
          kind = `Secundário`
        }

        // idHtml += `<div class="row mt-2">
        //               <div class="col-9 px-0">
        //                 <span><small data-kind="span" data-${this.controllerName}-target="iuguSecondaryInput">${iugu_id}</small></span>
        //               </div>
        //               <div class="col-3 px-0">
        //                 <span><small>${kind}</small></span>
        //               </div>
        //             </div>`

        idHtml += `<div class="row mt-2 row mt-2 d-flex align-items-center">
                    <div class="col-9 px-0">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="iuguForm">ID Secundário Conta Iugu</label>
                          <input aria-describedby="iuguFormHelp" class="form-control" id="iuguForm" data-${this.controllerName}-target="iuguSecondaryInput" placeholder="ID Secundário Conta Iugu" type="text" required>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 px-0">
                      <span><small>${kind}</small></span>
                    </div>
                  </div>`
      })
    } else if (this.actionMode == `new`) {
      var kind = `Principal`
      idHtml += `<div class="row mt-2 row mt-2 d-flex align-items-center">
                    <div class="col-9 px-0">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="iuguForm">ID Secundário Conta Iugu</label>
                          <input aria-describedby="iuguFormHelp" class="form-control" id="iuguForm" data-${this.controllerName}-target="iuguSecondaryInput" placeholder="ID Secundário Conta Iugu" type="text" required>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 px-0">
                      <span><small>${kind}</small></span>
                    </div>
                  </div>`

      idHtml = ``
    }

    var html = `<div class="col-8">
                  <span><small>IDs Secundários Iugu</small></span>
                </div>
                <div class="col-4 px-2">
                  <button type="button" class="btn btn-secondary btn-sm py-2 px-2 btn-table f-065" data-action="click->${this.controllerName}#addIuguIds">Novo ID</button>
                </div>
                <div class="col-12" data-${this.controllerName}-target="iuguIdsMain">${idHtml}</div>`

    // this.iuguIdsRowTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.iuguIdsRowTarget.innerHTML = html)
    }).then(() => {
      if (controller.actionMode == `edit`) {
        controller.current_client.iugu_ids.forEach((iugu_id, i) => {
          controller.iuguSecondaryInputTargets[i].value = iugu_id
        })
        
        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
    }) 
  }

  addIuguIds() {
    var html = `<div class="row mt-2">
                  <div class="col-9 px-0">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="iuguForm">ID Secundário Conta Iugu</label>
                        <input aria-describedby="iuguFormHelp" class="form-control" id="iuguForm" data-${this.controllerName}-target="iuguSecondaryInput" placeholder="ID Secundário Conta Iugu" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>`

    this.iuguIdsMainTarget.insertAdjacentHTML("beforeend", html)
  }

  setIuguIdsValue() {

    if (this.actionMode == `edit`) {
      this.iuguMainInputTarget.value = this.current_client.id_iugu
      this.startedAtInputTarget.value = this.current_client.started_at_pretty
    } else if (this.actionMode == `new`) {
      
    }
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.setIuguIds())
    }).then(() => {
      new Promise(function (resolve) {
        resolve(controller.addIuguIds())
      }).then(() => {
        if (controller.iuguId) {
          controller.iuguSecondaryInputTargets[controller.iuguSecondaryInputTargets.length - 1].value = controller.iuguId
        }
      })
    })
  }

  kindCheckEv(ev) {
    var kind = ev.target.dataset.kind

    this.kindCheck(kind)
  }

  kindCheck(kind) {
    if (this.hasIdNumberInputTarget) {
      this.idNumberInputTarget.remove()
    }

    this.cnpjInputTarget.value = ``
    this.cpfInputTarget.value = ``
    this.nameInputTarget.value = ``
    this.emailInputTarget.value = ``
    this.iuguMainInputTarget.value = ``

    if (kind == "individual") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (kind == "legal") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfTarget.classList.add("f-danger")
        this.cpfTarget.classList.remove("f-normal")
      } else {
        this.cpfTarget.classList.add("f-normal")
        this.cpfTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfTarget.classList.add("f-normal")
      this.cpfTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjTarget.classList.add("f-danger")
        this.cnpjTarget.classList.remove("f-normal")
      } else {
        this.cnpjTarget.classList.add("f-normal")
        this.cnpjTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjTarget.classList.add("f-normal")
      this.cnpjTarget.classList.remove("f-danger")
    }
  }

  searchAccountEv(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    ev.stopImmediatePropagation()
    if (this.actionMode == `new`) {
      if (this.cpfCheckboxTarget.checked) {
        var cleanIdNumber = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
        var maxNumber = 11
      } else if (this.cnpjCheckboxTarget.checked) {
        var cleanIdNumber = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
        var maxNumber = 14
      }

      if (ev.type == "keyup" && ev.shiftKey == false && cleanIdNumber.length == maxNumber && this.canRequest) {
        this.canRequest = false
        this.searchAccount(cleanIdNumber)      
      }
    }
  }

  searchAccount(idNumber) {
    if (this.cpfCheckboxTarget.checked) {
      this.id_number_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(idNumber)
      var data = { account: { cpf: idNumber, kind: "doctor" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/users/accounts/entities/read"
    } else if (this.cnpjCheckboxTarget.checked) {
      this.id_number_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(idNumber)
      var data = { company: { cnpj: idNumber, kind: "clinic" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/users/companies/entities/read"
    }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    if (this.id_number_result.invalid == false) {
      var isClient = this.checkIfIsClient(idNumber)
      if (isClient) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Já tem um cliente cadastrado com este CPF/CNPJ", 4000)
        this.setErrorMessage("Já tem um cliente cadastrado com este CPF/CNPJ")
        // this.stopRefreshing()
        // this.saveBtnTarget.classList.add(`d-none`)
        this.actionMode = `edit`
      } else {
        if (this.iuguId) {
          this.iuguMainInputTarget.value = this.iuguId
        }
      }
      // this.setIuguIdsValue()

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          controller.canRequest = true
          if (response.process) {
            controller.record = response.data.cln

            if (controller.record.id) {
              // controller.record_id = controller.record.id
              if (controller.record.class_type == `company_entities`) {
                controller.nameInputTarget.value = controller.record.trade_name
              } else if (controller.record.class_type == `account_entities`) {
                controller.nameInputTarget.value = controller.record.name
              }
              controller.nameInputTarget.disabled = true

              if (controller.record.email.address) {
                controller.current_client.email = controller.record.email
                controller.emailInputTarget.value = controller.record.email.address
                controller.emailInputTarget.disabled = false
              } else {
                controller.emailInputTarget.disabled = false
              }

              if (controller.record.address.id) {
                controller.current_client.address = controller.record.address
                controller.zipInputTarget.value = controller.record.address.postal_code_pretty
                controller.ibgeInputTarget.value = controller.record.address.ibge
                controller.streetInputTarget.value = controller.record.address.street
                controller.numberInputTarget.value = controller.record.address.number
                controller.complementInputTarget.value = controller.record.address.complement
                controller.districtInputTarget.value = controller.record.address.district
                controller.cityInputTarget.value = controller.record.address.city
                controller.cityInputTarget.dataset.filter = controller.record.address.filter
                controller.stateInputTarget.value = controller.record.address.state
                controller.stateInputTarget.dataset.filter = controller.record.address.state

                controller.kindInputTarget.dataset.kind = controller.record.address.kind
                controller.kindInputTarget.innerText = controller.record.address.kind_pretty
                controller.kindDropdownTarget.value = controller.record.address.kind_pretty
              } else {
                controller.zipInputTarget.value = ``
                controller.ibgeInputTarget.value = ``
                controller.streetInputTarget.value = ``
                controller.numberInputTarget.value = ``
                controller.complementInputTarget.value = ``
                controller.districtInputTarget.value = ``
                controller.cityInputTarget.value = ``
                controller.cityInputTarget.dataset.filter = ``
                controller.stateInputTarget.value = ``
                controller.stateInputTarget.dataset.filter = ``

                controller.kindInputTarget.dataset.kind = `personal`
                controller.kindInputTarget.innerText = `Pessoal`
                controller.kindDropdownTarget.value = `personal`
              }

              controller.formTitleTarget.innerText = `Editando Cliente`
            } else {
              controller.nameInputTarget.disabled = true
              controller.emailInputTarget.disabled = true
              controller.iuguMainInputTarget.disabled = true
              controller.startedAtInputTarget.disabled = true

              controller.zipInputTarget.disabled = true
              controller.ibgeInputTarget.disabled = true
              controller.streetInputTarget.disabled = true
              controller.numberInputTarget.disabled = true
              controller.complementInputTarget.disabled = true
              controller.districtInputTarget.disabled = true
              controller.cityInputTarget.disabled = true
              controller.cityInputTarget.disabled = true
              controller.stateInputTarget.disabled = true
              controller.stateInputTarget.disabled = true

              controller.zipInputTarget.value = ``
              controller.ibgeInputTarget.value = ``
              controller.streetInputTarget.value = ``
              controller.numberInputTarget.value = ``
              controller.complementInputTarget.value = ``
              controller.districtInputTarget.value = ``
              controller.cityInputTarget.value = ``
              controller.cityInputTarget.dataset.filter = ``
              controller.stateInputTarget.value = ``
              controller.stateInputTarget.dataset.filter = ``

              controller.kindInputTarget.dataset.kind = `personal`
              controller.kindInputTarget.innerText = `Pessoal`
              controller.kindDropdownTarget.value = `personal`

              var message = `Favor adicionar a Conta primeiro, favor procurar o time de Sucesso do Cliente.`
              this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 4000)
              this.setErrorMessage(message)
            }

            controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    }
  }

  getClient() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { client: { active: true, id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/clients/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.current_client = response.data.cln
          controller.doFormHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

    // this.doAliasCard()
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  checkIfIsClient(idNumber) {
    var isClient = false
    if (this.actionMode == `new`) {
      this.application.clients.forEach(element => {
        if (element.record_number == idNumber) {
          isClient = true
          this.current_client = element
        }
      })
    }

    return isClient
  }

  dateMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToMonthDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(`01/${ev.target.value}`)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(`01/${ev.target.value}`)
      if (this.checkDate.valid) {
        this.startedAtInputTarget.classList.add("f-normal")
        this.startedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.startedAtInputTarget.classList.add("f-danger")
        this.startedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.startedAtInputTarget.classList.add("f-normal")
      this.startedAtInputTarget.classList.remove("f-danger")
    }
  }

  zipMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.ibgeInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    // this.stateDropdownBtnTarget.disabled = false
    // this.cityDropdownBtnTarget.disabled = false
    this.stateInputTarget.disabled = false
    this.cityInputTarget.disabled = false
  }

  closeForm() {
    this.streetInputTarget.value = ``
    this.ibgeInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = true
    this.districtInputTarget.disabled = true
    // this.stateDropdownBtnTarget.disabled = true
    // this.cityDropdownBtnTarget.disabled = true
    this.stateInputTarget.disabled = true
    this.cityInputTarget.disabled = true
  }

  searchZip(ev) {

    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                // controller.stateDropdownTarget.value = dados.uf
                // controller.stateInputTarget.innerText = dados.uf
                // controller.stateInputTarget.dataset.state = dados.uf
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                // controller.cityDropdownTarget.value = dados.localidade
                // controller.cityInputTarget.innerText = dados.localidade
                // controller.cityInputTarget.dataset.city = dados.localidade
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
              }
              if (dados.ibge === "") {
                controller.ibgeInputTarget.disabled = false
                controller.ibgeInputTarget.disabled = false
              } else {
                controller.ibgeInputTarget.value = dados.ibge
                controller.ibgeInputTarget.disabled = true
              }
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        this.cleanForm();
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    } else if (ev.type == "focus" && this.zipInputTarget.value.length < 10) {
      this.closeForm();
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
          // this.cityListTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasNameInputTarget && controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasEmailInputTarget && controller.emailInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasZipInputTarget && controller.zipInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasNumberInputTarget && controller.numberInputTarget.value == ``) {
        len += 1
      }

      // if (controller.hasIuguSecondaryInputTarget && (controller.iuguSecondaryInputTargets[0].value != controller.iuguMainInputTarget.value)) {
      //   len += 1
      // }

      if (controller.hasCpfCheckboxTarget && controller.cpfCheckboxTarget.checked) {
        if (controller.cpfTarget.value == ``) {
          len += 1
        }
      } else if (controller.hasCnpjCheckboxTarget && controller.cnpjCheckboxTarget.checked) {
        if (controller.cnpjTarget.value == ``) {
          len += 1
        }
      } else {
        len += 1
      }

      if (controller.cpf_result.invalid) {
        len += 1
      }

      if (controller.hasSaveBtnTarget) {
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
