import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--questions`
  }

  doHtml() {
    var html = `<section class="ws-section-questions">
                  <div class="container">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="lp-content">
                        <p class="lp-text mt-2r text-center">
                          ${this.application.site.questions_subtitle}
                        </p>
                        <h1 class="lp-title text-center mt-m-1r">
                          ${this.application.site.questions_title}
                        </h1>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 ws-questions-div" data-${this.controllerName}-target="cards">

                    
                    
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setQuestions()
    })
  }

  setQuestions() {
    var html = ``
    var current_questions = []

    this.application.site.questions.forEach(question => {
      if (question.status) {
        current_questions[current_questions.length] = question
      }
    })
    current_questions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_questions, `order`)

    current_questions.forEach(question => {
      var colClass = ``

      if (this.application.size == `large`) {
        colClass += `col-8 offset-2`
      } else if (this.application.size == `medium`) {
        colClass += `col-10 offset-1`
      } else if (this.application.size == `small`) {
        colClass += `col-12`
      }

      html += `<div class="${colClass} mt-5">
                  <div class="ws-card-question pointer" data-question-id="${question.id}"data-action="click->${this.controllerName}#goToAnswer">
                    <div class="container">
                      <h3 class="ws-card-title mb-0">${question.title}</h3>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.doCarouselIndicators()
    })
  }

  goToAnswer(ev) {
    var questionId = ev.currentTarget.dataset.questionId

    this.application.site.questions.forEach(question => {
      if (question.id == questionId) {
        this.setAnswer(question)
      }
    })
  }

  setAnswer(question) {
    var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(question.link_cta)
    var colClass = ``

    if (this.application.size == `large`) {
      colClass += `col-8 offset-2`
    } else if (this.application.size == `medium`) {
      colClass += `col-10 offset-1`
    } else if (this.application.size == `small`) {
      colClass += `col-12`
    }

    var html = `<div class="${colClass} mt-5">
                  <div class="ws-card-question">
                    <div class="container">
                      <h3 class="ws-card-title d-flex align-items-center mb-0">
                        ${question.title}
                        <span class="mc-tooltip ml-auto">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#closeAnswer" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>
                      </h3>                      
                    </div>
                  </div>
                </div>
                <div class="${colClass} mt-4">
                  <div class="card-body ws-card-text">
                    ${question.description}
                  </div>
                  <div class="card-footer ws-card-text">
                    <a class="ws-card-link pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${question.cta}</a>
                  </div>
                </div>`

    this.cardsTarget.innerHTML = html
  }

  closeAnswer() {
    this.cardsTarget.innerHTML = ``
    this.setQuestions()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-section-questions">
                  <div class="container">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="lp-content">
                        <p class="lp-text mt-2r text-center">
                          Perguntas Frequentes
                        </p>
                        <h1 class="lp-title text-center mt-m-1r">
                          Estamos aqui para te ajudar
                        </h1>
                        <div class="col-lg-8">
                          <div class="accordion mt-3r" id="questions">
                            <a class="expansion-panel-toggler collapsed lp-collapse-card  lp-m-1r" type="button" data-toggle="collapse" data-target="#question-1" aria-expanded="true" aria-controls="question-1">
                              Como essa consultoria irá me ajudar?
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>      
                            </a>
                            <div id="question-1" class="collapse" aria-labelledby="headingOne" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                Através de uma conversa com um dos nossos especialistas, 
                                vamos buscar compreender melhor o seu perfil e seus cenários, 
                                e assim,  buscar as melhores práticas para te ajudar.
                              </div>
                            </div>
                            <a class="lp-collapse-card lp-m-1r expansion-panel-toggler collapsed lp-title-colapse" type="button" data-toggle="collapse" data-target="#question-2" aria-expanded="false" aria-controls="question-2">
                              Como é feita a consultoria?
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>       
                            </a>
                            <div id="question-2" class="collapse" aria-labelledby="headingTwo" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                Pode ser realizada da forma mais prática possível, seja através de video-chamada, contato telefônico ou presencialmente na nossa sede. 
                                Assim, conseguimos atender médicos de todo o Brasil. Estamos situados na Rua Ilacir Pereira Lima, 195 - Sala 506 - Bairro: Silveira -
                                Belo Horizonte, Minas Gerais. 
                              </div>
                            </div>
                            <a class="expansion-panel-toggler collapsed lp-collapse-card lp-m-1r" type="button" data-toggle="collapse" data-target="#question-3" aria-expanded="false" aria-controls="question-3">
                              Quanto tempo dura a consultoria gratuita ?
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div> 
                            </a>  
                            <div id="question-3" class="collapse" aria-labelledby="headingThree" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                Em média, 30 minutos são suficientes para esclarecer todas as dúvidas e passar todas as informações necessárias. 
                              </div>
                            </div>
                            <a class="expansion-panel-toggler lp-collapse-card collapsed lp-m-1r" type="button" data-toggle="collapse" data-target="#question-4" aria-expanded="false" aria-controls="question-4">
                              Quais são as cidades atendidas pela MedCapital?
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div> 
                            </a>
                            <div id="question-4" class="collapse" aria-labelledby="headingThree" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                <p>
                                  Realizamos nossos serviços online de Departamento Pessoal, 
                                  Declaração de Imposto de Renda, Seguros e Livro-Caixa para médicos e consultórios em todo Brasil.
                                  Os serviços de abertura e regularização de empresas médicas (PJ) e médicos autônomos 
                                  (PF), atualmente, já são realizados em:
                                </p>
                                <b>MINAS GERAIS:</b>
                                <p>
                                  Belo Horizonte, Nova Lima, Contagem, Betim, Ribeirão das Neves, Santa Luzia, Ibirité, Sabará, Uberlândia, 
                                  Uberada, Juiz de Fora, Montes Claros, Governador Valadares, Ipatinga, Sete Lagoas, Divinópolis, Poços de Caldas, 
                                  Patos de Minas, Pouso Alegre, Teófilo Otoni e Barbacena.
                                </p>
                                <b>SÃO PAULO:</b>
                                <p>
                                  São Paulo, Campinas, Guarulhos, São Bernardo do Campo, Santo André, São José dos Campos, Osasco, Ribeirão Preto, 
                                  Sorocaba, Mauá, São José do Rio Preto, Mogi das Cruzes, Diadema, Jundiaí e Piracicaba. 
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
