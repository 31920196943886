
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.application.permissions = {}
    this.greeting()
    this.getCurrentUserPermissions()
  }

  welcomeChat() {
    const html = `<div class="row my-4" data-controller="users--notifications--welcome">
                    <div class="col-6 offset-3">
                      <div class="card border-top-primary">
                        <div class="card-body" data-users--notifications--welcome-target="messageBody" id="messageBody" style="overflow:scroll;"></div>
                      </div>
                    </div>
                  </div>`


    this.contentTarget.innerHTML = html
    var height = $(window).height();
    $('.card').css('height', height * 0.5);
  }

  greeting() {

    const html = `<div class="row" data-controller="users--dashboards--main--greeting">
                    <div class="col-12">
                      <div class="media d-flex align-items-center">
                        <span class="mr-3" style="font-size:4em;color:#086bac;"><i class="fas fa-chart-line"></i></span>
                        <div class="media-body" data-users--dashboards--main--greeting-target="media">
                        </div>
                      </div>
                    </div>
                  </div>`

    this.contentTarget.innerHTML = html
    
  }

  teamDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12" data-controller="users--dashboards--main--team" data-users--dashboards--main--team-target="content"></div>
                  </div>`
    
    var controller = this
    setTimeout(function () {
      controller.contentTarget.insertAdjacentHTML("beforeend", html)
    }, 2000)

  }

  doctorDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12" data-controller="users--dashboards--main--doctor" data-users--dashboards--main--doctor-target="content"></div>
                  </div>`

    var controller = this
    setTimeout(function () {
      controller.contentTarget.insertAdjacentHTML("beforeend", html)
    }, 2000)
  }

  helperDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12" data-controller="users--dashboards--main--helper" data-users--dashboards--main--helper-target="content"></div>
                  </div>`

    var controller = this
    setTimeout(function () {
      controller.contentTarget.insertAdjacentHTML("beforeend", html)
    }, 2000)
  }

  getCurrentUserPermissions() {
    const featureNames = ["financial_purchase_entities", "user_works_trackers_projects", "company_entities", "account_entities", "posts", "user_permissions_features",
                          "operation_onboard_entities", "financial_settings", "operations", "medclinic_financial_receivables", "commercial_main_dashboard",
                          "app_stats_tracker_general", "medclinic_doctor_clients", "medclinic_helper_clients", "operation_ongoings",
                          "user_works_recruitments_vacancies"]

    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        if (currentUser.account_kind == "doctor") {
          controller.doctorDashboard()
        } else if (currentUser.account_kind == "helper") {
          controller.helperDashboard()
        } else if (currentUser.account_kind == "admin" || currentUser.account_kind == "team") {
          if (firstLogin) {
            controller.welcomeChat()
          } else {
            controller.teamDashboard()
          }
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}
