import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "eventLeadName", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `commercial--marketing--events--admissions--list`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `updated_at_to_time` }
    this.hasShow = false
  }

  addAdmission() {
    this.getControllerByIdentifier("commercial--marketing--events--admissions--save").actionMode = `new`
    this.getControllerByIdentifier("commercial--marketing--events--admissions--save").current_admission = {}
    this.getControllerByIdentifier("commercial--marketing--events--admissions--save").doFormHtml()
  }

  editAdmission(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.admissions.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("commercial--marketing--events--admissions--save").actionMode = `edit`
        this.getControllerByIdentifier("commercial--marketing--events--admissions--save").current_admission = element
        this.getControllerByIdentifier("commercial--marketing--events--admissions--save").doFormHtml()
      }
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addAdmission" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Participante</span>`
    } else {
      var addLine = ``
    }

    if (this.application.current_user.super_admin) {
      var downloadLine = `<span data-action="click->${this.controllerName}#downloadAdmissions" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Participantes</span>`
    } else {
      var downloadLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshAdmissions" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Participantes</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                          ${downloadLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Inscrições</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="updated_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="updated_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                E-mail
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                Telefone
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Fonte</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Qualificação</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Presença</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--admissions--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 6))
      controller.doDataTable()
      // if (controller.application.admissions.length > 0) {
      // }
    })
  }

  doDataTable() {
    var current_admissions = this.application.admissions

    if (this.sort.mode == `asc`) {
      var admissions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_admissions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var admissions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_admissions, this.sort.field)
    }

    if (admissions.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(admissions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (admissions[x] !== undefined) {
            internPage[internPage.length] = admissions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: admissions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Inscrições</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.admissionTablePartial(element, data.length))
      })
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  admissionTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editAdmission" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.checked_in) {
      var checkedIn = `<span class="mc-tooltip pointer">
                        <span class="material-icons md-sm md-success">done</span>
                        <span class="mc-tooltiptext">Check In com sucesso</span>
                      </span>`
    } else {
      var checkedIn = `<span class="mc-tooltip pointer">
                        <span class="material-icons md-sm md-danger">clear</span>
                        <span class="mc-tooltiptext">Não fez Check In</span>
                      </span>`
    }

    if (this.application.permissions[this.permission].can_update && element.is_lead == false) {
      var leadBtn = `<button data-action="click->${this.controllerName}#addLead" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">add_circle_outline</span>
                            <span class="mc-tooltiptext">Gerar Lead</span>
                          </button>`  
    } else {
      var leadBtn = `<span class="mc-tooltip">
                        <span class="material-icons md-sm md-primary">done_all</span>
                        <span class="mc-tooltiptext">Já é Lead</span>
                      </span>`
    }

    var notifcationBtn = ``
    if (this.application.event.finished == false) {
      var notifcationBtn = `<button data-action="click->${this.controllerName}#sendNotification" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">email</span>
                            <span class="mc-tooltiptext">Enviar Notificação</span>
                          </button>`
    }

    var ticketBtn = `<button data-action="click->${this.controllerName}#addTicket" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">task</span>
                      <span class="mc-tooltiptext">Adicionar Ticket</span>
                    </button>`
    

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.kind == `academic`) {
      var qualification = element.graduation
    } else if (element.kind == `doctor`) {
      if (element.crm_pretty) {
        var qualification = element.crm_pretty
      } else if (element.crm_input) {
        var qualification = element.crm_input
      }
    } else {
      var qualification = ``
    }
    
    if (element.utm_mc_source && element.utm_mc_campaign) {
      var source = `${element.utm_mc_campaign}#${element.utm_mc_source}`
    } else if (element.utm_mc_campaign) {
      var source = `${element.utm_mc_campaign}`
    } else if (element.utm_mc_source) {
      var source = `${element.utm_mc_source}`
    } else {
      var source = `N/D`
    }

    var searchBtn = `<button data-action="click->${this.controllerName}#searchCRM" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">search</span>
                        <span class="mc-tooltiptext">Procurar CRM</span>
                      </button>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editAdmission">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editAdmission">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <p class="mb-0 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.email}" data-action="click->app--helpers--copy#copy">${element.email}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <p class="mb-0 mc-tooltip pointer">
                        <span data-action="click->${this.controllerName}#sendWhatsAppApi">${element.phone_pretty}</span>
                        <span class="mc-tooltiptext">Clique para enviar WhatsApp</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${source}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${qualification}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${checkedIn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${leadBtn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${ticketBtn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${notifcationBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  searchCRM(ev) {
    var admissionId = ev.currentTarget.closest(".itemRow").dataset.id

    var lead = {}
    this.application.admissions.forEach(element => {
      if (element.id == admissionId) {
        lead = element
      }
    })

    if (lead.crm && lead.crm_state) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=&uf=${lead.crm_state}&crm=${lead.crm}&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.crm) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=&uf=&crm=${lead.crm}&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.name && lead.crm_state) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=${lead.name}&uf=${lead.crm_state}&crm=&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.name) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=${lead.name}&uf=&crm=&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    }
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("commercial--marketing--events--admissions--save").stopRefreshing()
    this.getControllerByIdentifier(`commercial--marketing--events--admissions--show`).doViewHtml()
    this.doDataTable()
  }

  refreshAdmissions() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var data = { admission: { event_id: this.application.event.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/commercial/marketing/events/admissions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.admissions = response.data.cln
        }

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  downloadAdmissions() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { admission: { active: true, event_id: this.application.event.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/commercial/marketing/events/admissions/download"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var date = new Date()
        var fileName = `base-leads-${date.getTime()}`
        var fileExtension = `csv`

        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendNotification(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    var admissionId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var r = confirm(`Tem certeza que deseja enviar a Notificação por Email?`)
    if (r) {
      var data = { admission: { id: admissionId }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
      const url = "/commercial/marketing/events/admissions/send_notification"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
    }
  }

  addLead(ev) {
    this.showClearBtn()
    var admissionId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var currentAdmission = {}

    this.application.admissions.forEach(element => {
      if (element.id == admissionId) {
        currentAdmission = element
      }
    })

    this.getControllerByIdentifier("commercial--sales--leads--entities--save").actionMode = `admisssion`
    this.getControllerByIdentifier("commercial--sales--leads--entities--save").current_admission = currentAdmission
    this.getControllerByIdentifier("commercial--sales--leads--entities--save").doFormHtml()
  }

  sendWhatsAppApi(ev) {
    var admissionId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var currentAdmission = {}

    this.application.admissions.forEach(element => {
      if (element.id == admissionId) {
        currentAdmission = element
      }
    })

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.application.event.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `marketing_events`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Evento ${this.application.event.name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.application.event.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `marketing_events`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = currentAdmission.attendee_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `marketing_attendees`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = currentAdmission.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = currentAdmission.ddd
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = currentAdmission.number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = currentAdmission.whatsapp
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = this.permission
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  addTicket(ev) {
    var currentAdmission = {}
    this.application.admissions.forEach(element => {
      if (element.id == ev.currentTarget.closest(`.itemRow`).dataset.id) {
        currentAdmission = element
      }
    })

    var controllerModal = `users--works--activities--shared--modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).obj_type = `event`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.application.event.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.application.event.title} - ${currentAdmission.name}`
      controller.getControllerByIdentifier(controllerModal).permission = `commercial_event_tickets`
      controller.getControllerByIdentifier(controllerModal).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(controllerModal).ticket_board = {
        board_id: controller.application.event.id,
        board_type: `marketing_events`,
        board_subtype: `marketing_events`,
        board_body: currentAdmission.name,
        board_path: window.location.pathname,
        board_name: `Evento | ${this.application.event.title}`,
        // date_id: controller.application.current_date.id,
        // date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(controllerModal).comment_board = {
        board_id: controller.application.event.id,
        board_type: `marketing_events`,
        // date_id: controller.application.current_date.id,
        // date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
