import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "regimeInput", "progressUpload", "progressUploadBar", "progressUploadCounter", "progressUploadDiv",
                    "dateDropdown", "dateDropdownBtn", "dateInput", "dateList", 
                    "billDiv", "billLabelUpload", "billName", "receiptDiv", "receiptLabelUpload", "receiptName", "editReceiptDiv",
                    "principalInput", "interestsInput", "totalAmountInput", "pixInput", "saveBtn", "editBillDiv", 
                    "deleteBtn", "editBtn", "fineInput"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--taxes--update`
    this.upload_files = []
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadReady = false
    this.uploadFile = false
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--financials--taxes--show").doViewHtml()
  }

  saveTax() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    if (this.uploadFile) {
      this.progressCount(0)
    }

    this.send_data = { current_user: {}, tax: {}, tracker: { log: true } }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    
    this.send_data.tax.id = this.current_tax.id
    this.send_data.tax.principal = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.principalInputTarget.value)
    this.send_data.tax.interests = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.interestsInputTarget.value)
    this.send_data.tax.fine = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.fineInputTarget.value)
    this.send_data.tax.total_amount = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.totalAmountInputTarget.value)

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `${this.current_tax.description} da competência ${this.current_tax.date_accrual} da PJ ${this.application.clinic.company_name}, sendo Principal: ${this.principalInputTarget.value}, Juros: ${this.interestsInputTarget.value}, Multa: ${this.fineInputTarget.value} e Total: ${this.totalAmountInputTarget.value}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/financials/taxes/update"
    var method = "PUT"
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var tax = response.data.cln
          if (controller.uploadFile) {
            controller.requestUpload(tax.id, controller.upload_files)
          } else {
            controller.stopRefreshing()
            controller.application.taxes.forEach((element, i) => {
              if (element.id == tax.id) {
                controller.application.taxes.splice(controller.application.taxes.indexOf(element), 1, tax)
              }
            })
          }
          
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doTaxesCount()
          controller.getControllerByIdentifier("operations--products--clinics--financials--taxes--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestUpload(tax_id, files) {
    var i = 1
    files.forEach(element => {
      var formData = new FormData()
      formData.append('file', element.file)
      formData.append('field', element.field)
      formData.append('tax_id', tax_id)
      formData.append('current_user_id', this.application.current_user.id)
      formData.append('feature', this.permission)

      formData.append('tracker_log', true)
      formData.append('tracker_action', `uploaded`)
      formData.append('tracker_record_id', this.application.clinic.id)
      formData.append('tracker_record_type', `operation_products`)
      formData.append('tracker_observations', `Upload ${element.field_pretty} ${this.current_tax.kind_pretty} da competência ${this.current_tax.date_accrual} da PJ ${this.application.clinic.company_name} no valor total de ${this.totalAmountInputTarget.value}`)

      var url = "/operations/products/clinics/financials/taxes/save_upload"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var tax = response.data.cln
            controller.application.taxes.forEach(element => {
              if (element.id == tax.id) {
                controller.application.taxes.splice(controller.application.taxes.indexOf(element), 1, tax)
              }
            })
            if (i == files.length) {
              controller.stopRefreshing()
              controller.progressCount(100)
              controller.getControllerByIdentifier("operations--products--clinics--financials--taxes--index").doDataTable()
            }
            controller.uploadSwitch(false)
            i += 1
          }
          controller.cancelSave()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    })
  }

  sendEmail() {
    this.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).sendController = `operations--products--clinics--managements--emails--send-tax`

    // var modalController = `operations--products--clinics--managements--shared--send-email`
    // var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    //               <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    //                 <div class="modal-content">
    //                   <div class="modal-header border-bottom py-0">
    //                     <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Selecione o(s) Sócio(s) para enviar o Email</strong></h6><br>
    //                   </div>
    //                   <div class="modal-body p-3" data-${modalController}-target="modalBody"></div>
    //                   <div class="modal-footer">
    //                     <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
    //                     <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-func="this.sendTaxEmail()" data-action="click->${modalController}#sendEmail" data-${modalController}-target="sendBtn">Enviar</button>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>`
                
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).openEmailModal())
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_obj = controller.application.current_main_apuration
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_clinic = controller.application.clinic
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_date = controller.application.current_date
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_partners = controller.application.partners
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).open()
    })
  }

  editFile(ev) {
    var field = ev.currentTarget.dataset.field

    if (field == `bill`) {
      this.editBillDivTarget.classList.remove(`d-none`)
      this.billDivTarget.classList.add(`d-none`)
    } else if (field == `receipt`) {
      this.editReceiptDivTarget.classList.remove(`d-none`)
      this.receiptDivTarget.classList.add(`d-none`)
    }
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTax" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var deleteBtnHtml = `<button data-action="click->${this.controllerName}#deleteFile" data-${this.controllerName}-target="deleteBtn" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Documento</span>
                          </button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.current_tax.has_bill) {
      var bill = `<span class="f-085 text-bold mr-5">Guia de Imposto ${this.current_tax.kind_pretty}</span>
                  <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-kind="bill" data-obj-id="${this.current_tax.id}" data-obj-type="clinic_fin_taxes" data-action="click->${this.controllerName}#goToFile">
                    <span class="material-icons md-sm md-dark">launch</span>
                    <span class="mc-tooltiptext">Ver Guia</span>
                  </button>
                  <button data-action="click->${this.controllerName}#sendEmail" data-id="${this.current_tax.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                    <span class="material-icons md-sm md-dark">send</span>
                    <span class="mc-tooltiptext">Enviar Email</span>
                  </button>
                  <button data-action="click->${this.controllerName}#editFile" data-${this.controllerName}-target="editBtn" data-field="bill" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Upload de Nova Guia</span>
                  </button>
                  ${deleteBtnHtml}`
    } else {
      var bill = `<span class="f-085 text-bold">Sem Arquivo Boleto</span>`
    }

    if (this.current_tax.has_receipt) {
      var receipt = `<span class="f-085 text-bold mr-5">Comprovante de Pagamento ${this.current_tax.kind_pretty}</span>
                      <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-kind="receipt" data-obj-id="${this.current_tax.id}" data-obj-type="clinic_fin_taxes" data-action="click->${this.controllerName}#goToFile">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Comprovante</span>
                      </button>
                      <button data-action="click->${this.controllerName}#sendEmail" data-id="${this.current_tax.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                        <span class="material-icons md-sm md-dark">send</span>
                        <span class="mc-tooltiptext">Enviar Email</span>
                      </button>
                      <button data-action="click->${this.controllerName}#editFile" data-${this.controllerName}-target="editBtn" data-field="receipt" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Upload de Novo Comprovante</span>
                      </button>
                      ${deleteBtnHtml}`
    } else {
      var receipt = `<span class="f-085 text-bold">Sem Comprovante</span>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Competência</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dateList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="regimeForm">Regime Tributário</label>
                            <input disabled aria-describedby="regimeFormHelp" class="form-control form-valid-control" id="regimeForm" data-${this.controllerName}-target="regimeInput" placeholder="Agência" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="principalForm">Valor Principal</label>
                            <input aria-describedby="principalFormHelp" class="form-control" id="principalForm" data-${this.controllerName}-target="principalInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="Valor Principal" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="interestsForm">Valor Juros</label>
                            <input aria-describedby="interestsFormHelp" class="form-control" id="interestsForm" data-${this.controllerName}-target="interestsInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="Valor Juros" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="fineForm">Valor Multa</label>
                            <input aria-describedby="fineFormHelp" class="form-control" id="fineForm" data-${this.controllerName}-target="fineInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="Valor Multa" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalAmountForm">Valor Total</label>
                            <input disabled aria-describedby="totalAmountFormHelp" class="form-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Agência" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center rowFile" data-${this.controllerName}-target="editBillDiv" data-field="bill" data-field-pretty="Guia de Pagamento">
                      <div class="col-5">
                        <span class="f-065">Guia de Pagamento</span>
                        <label class="drop-area copy" for="billDropzoneArea" data-${this.controllerName}-target="billLabelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">request_quote</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="billDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-7 px-0">
                        <span data-${this.controllerName}-target="billName"></span>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center" data-${this.controllerName}-target="billDiv">
                      <div class="col-12 my-3" data-field="bill">
                        ${bill}
                      </div>
                    </div>

                    <div class="row d-flex align-items-center rowFile" data-${this.controllerName}-target="editReceiptDiv" data-field="receipt" data-field-pretty="Comprovante de Pagamento">
                      <div class="col-5">
                        <span class="f-065">Comprovante de Pagamento</span>
                        <label class="drop-area copy" for="receiptDropzoneArea" data-${this.controllerName}-target="receiptLabelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">receipt_long</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="receiptDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-7 px-0">
                        <span data-${this.controllerName}-target="receiptName"></span>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center" data-${this.controllerName}-target="receiptDiv">
                      <div class="col-12 my-3" data-field="receipt">
                        ${receipt}
                      </div>
                    </div>

                    <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="progressUploadDiv">
                      <div class="col-12">
                        <div class="form-group my-0 text-center w-100">
                          <h7 class="progress">
                            <span class="progress_count"></span>
                          </h7>
                          <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                          </div>
                          <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.upload_files = []
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      controller.formTitleTarget.innerText = `Editando Imposto ${controller.current_tax.kind_pretty}`
      controller.application.dates.forEach(element => {
        if (element.id == controller.current_tax.date_id) {
          controller.dateDropdownTarget.value = element.id
          controller.dateInputTarget.innerText = element.name
          controller.dateInputTarget.dataset.id = element.id
          controller.setRegime(element.id)
        }
      })

      controller.principalInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_tax.principal)
      controller.interestsInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_tax.interests)
      controller.fineInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_tax.fine)
      controller.totalAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_tax.total_amount)
      controller.bindAmount()

      if (controller.current_tax.status == `paid`) {
        controller.principalInputTarget.disabled = true
        controller.interestsInputTarget.disabled = true
        controller.fineInputTarget.disabled = true
        controller.totalAmountInputTarget.disabled = true

        controller.deleteBtnTargets.forEach(element => {
          element.remove()
        })
        controller.editBtnTargets.forEach(element => {
          element.remove()
        })

      } else {
        controller.principalInputTarget.disabled = false
        controller.interestsInputTarget.disabled = false
        controller.fineInputTarget.disabled = false
        controller.totalAmountInputTarget.disabled = false
      }

      if (this.current_tax.principal > 0) {
        if (this.current_tax.has_bill) {
          this.editBillDivTarget.classList.add(`d-none`)
          this.billDivTarget.classList.remove(`d-none`)
        } else {
          this.editBillDivTarget.classList.remove(`d-none`)
          this.billDivTarget.classList.add(`d-none`)
        }

        if (this.current_tax.has_receipt) {
          this.editReceiptDivTarget.classList.add(`d-none`)
          this.receiptDivTarget.classList.remove(`d-none`)
        } else {
          this.editReceiptDivTarget.classList.remove(`d-none`)
          this.receiptDivTarget.classList.add(`d-none`)
        }
      } else {
        this.editBillDivTarget.classList.add(`d-none`)
        this.billDivTarget.classList.add(`d-none`)
        this.editReceiptDivTarget.classList.add(`d-none`)
        this.receiptDivTarget.classList.add(`d-none`)
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // controller.refreshSaveBtn()
    })

  }

  bindAmount() {
    
    var principal = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.principalInputTarget.value)
    var interests = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.interestsInputTarget.value)
    var fine = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.fineInputTarget.value)
    var totalAmount = 0

    totalAmount = Number(principal) + Number(interests) + Number(fine)
    this.totalAmountInputTarget.value = this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(totalAmount)
  }

  setRegime(dateId) {
    this.application.regimes.forEach(element => {
      if (element.date_id == dateId) {
        this.current_regime = element
      }
    })

    this.regimeInputTarget.value = this.current_regime.tax_regime_pretty
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  checkIsAmount(ev) {
    this.bindAmount()
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.field = ev.currentTarget.closest(".rowFile").dataset.field
        obj.field_pretty = ev.currentTarget.closest(".rowFile").dataset.fieldPretty
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
          obj.file = element
          this.uploadReady = true
          if (obj.field == `bill`) {
            this.progressUploadDivTarget.classList.remove("d-none")
            this.billNameTarget.innerText = obj.file_name
          } else if (obj.field == `receipt`) {
            this.progressUploadDivTarget.classList.remove("d-none")
            this.receiptNameTarget.innerText = obj.file_name
          }

          this.upload_files[this.upload_files.length] = obj
          this.uploadFile = true
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (this.hasUploadBtnTarget) {
      if (value) {
        this.uploadBtnTarget.classList.remove("bg-disabled")
        this.uploadBtnTarget.classList.add("pointer")
        this.uploadBtnTarget.classList.add("bg-primary")
        this.canUploadFile = true
      } else {
        this.uploadBtnTarget.classList.add("bg-disabled")
        this.uploadBtnTarget.classList.remove("pointer")
        this.uploadBtnTarget.classList.remove("bg-primary")
        this.canUploadFile = false
      }
    }
  }

  progressCount(value) {
    var controller = this
    if (this.hasProgressUploadBarTarget) {
      this.progressUploadBarTarget.style.width = value + `%`
      var i = 0
      if (value != 100) {
        this.progressTimer = setInterval(function () {
          controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
          controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
          i++
          if (i == 95) {
            i = 94
          }
        }, 500);
      } else {
        this.stopRefreshing()
        this.progressUploadCounterTarget.innerText = value + `%`
      }
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      // if (controller.nameInputTarget.innerText == ``) {
      //   len += 1
      // }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

}
