import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["player", "bodyCard", "startTime", "endTime", "playBtn", "pauseBtn", "progressCircular", "progressBar", "modal"]

  connect() {
    this.controllerName = `app--helpers--audio-modal`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doAudioHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    this.modalTarget.remove()
  }

  doAudioHtml() {
    var html = `<div class="row audio-player" style="background-color:#086bac;border-radius:5rem;">
                  <div class="col-2 d-flex align-items-center my-3">
                    <div class="progress-circular ml-4" data-${this.controllerName}-target="progressCircular">
                      <div class="progress-circular-wrapper">
                        <div class="progress-circular-inner">
                          <div class="progress-circular-left">
                            <div class="progress-circular-spinner" style="border:0.25rem solid #fbfcff;"></div>
                          </div>
                          <div class="progress-circular-gap" style="border-top:0.125rem solid #fbfcff;"></div>
                          <div class="progress-circular-right">
                            <div class="progress-circular-spinner" style="border:0.25rem solid #fbfcff;border-bottom-color:transparent;"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="player-controls">
                      <button disabled class="btn btn-primary play-btn d-none" data-${this.controllerName}-target="playBtn" data-action="click->${this.controllerName}#playAudio"><span style="font-size:3rem;color:#fbfcff;" class="material-icons">play_arrow</span></button>
                      <button disabled class="btn btn-primary pause-btn d-none" data-${this.controllerName}-target="pauseBtn" data-action="click->${this.controllerName}#pauseAudio"><span style="font-size:3rem;color:#fbfcff;" class="material-icons">pause</span>
                    </div>
                  </div>
                  <div class="col-10 d-flex align-items-center">
                    <div class="audio-wrapper" id="player-container">
                      <audio class="player" data-${this.controllerName}-target="player" data-action="timeupdate->${this.controllerName}#timeUpdate">
                        <source src="${this.current_audio.service_url}" type="${this.current_audio.content_type}">
                      </audio>
                    </div>
                    <div class="player-controls scrubber w-100">
                      <div class="row">
                        <div class="col-12">
                          <div class="progress" style="border-radius:5rem;">
                            <div class="progress-bar-audio progress-bar progress-bar-striped" data-${this.controllerName}-target="progressBar" data-action="click->${this.controllerName}#clickBar mouseover->${this.controllerName}#mouseOverBar" style="cursor:pointer;border-bottom:0.25rem solid #fbfcff;" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div class="col-6 text-left" style="color:#fbfcff;"><span class="start-time" data-${this.controllerName}-target="startTime">00:00</span></div>
                        <div class="col-6 text-right" style="color:#fbfcff;"><span class="end-time" data-${this.controllerName}-target="endTime"></span></div>
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyCardTarget.innerHTML = html)
    }).then(() => {

      controller.playBtnTarget.disabled = false
      controller.playBtnTarget.classList.remove(`d-none`)
      controller.pauseBtnTarget.disabled = false
      controller.progressCircularTarget.remove()

      controller.loadAudio()

    })
  }

  loadAudio() {
    setTimeout(() => {
      var length = this.playerTarget.duration
      var current_time = this.playerTarget.currentTime
      var currentSrc = this.playerTarget.currentSrc

      // calculate total length of value
      var totalLength = this.calculateTotalValue(length)
      if (totalLength == "NaN:Na") {
        this.endTimeTarget.innerHTML = `Calculando ...`
      } else {
        this.endTimeTarget.innerHTML = totalLength
      }

      // calculate current value time
      var currentTime = this.calculateCurrentValue(current_time)
      this.startTimeTarget.innerHTML = currentTime
    }, 200)
  }

  pauseAudio() {
    this.playerTarget.pause()
    this.progressBarTarget.classList.remove(`progress-bar-animated`)
    this.playBtnTarget.classList.remove(`d-none`)
    this.pauseBtnTarget.classList.add(`d-none`)
  }

  playAudio() {
    this.playerTarget.play()
    this.progressBarTarget.classList.add(`progress-bar-animated`)
    this.playBtnTarget.classList.add(`d-none`)
    this.pauseBtnTarget.classList.remove(`d-none`)
  }

  timeUpdate() {
    var length = this.playerTarget.duration
    var current_time = this.playerTarget.currentTime

    // calculate total length of value
    var totalLength = this.calculateTotalValue(length)
    if (totalLength == "NaN:Na") {
      this.endTimeTarget.innerHTML = `Calculando ...`
    } else {
      this.endTimeTarget.innerHTML = totalLength
    }

    // calculate current value time
    var currentTime = this.calculateCurrentValue(current_time)
    this.startTimeTarget.innerHTML = currentTime

    // calculate progress bar width
    // var currentWidth = (this.playerTarget.currentTime / this.playerTarget.duration) * 100 + "%"
    var currentWidth = (this.playerTarget.currentTime / this.playerTarget.duration) * 100
    // this.progressBarTarget.width = currentWidth

    if (this.playerTarget.currentTime == this.playerTarget.duration) {
      this.pauseAudio()
      // $('.pause-btn').click();
    }

  }

  mouseOverBar(ev) {
    var percent = ev.offsetX / this.progressBarTarget.width
    var overTime = percent * this.playerTarget.duration
  }

  clickBar(ev) {
    var percent = ev.offsetX / this.progressBarTarget.width
    // this.playerTarget.currentTime = percent * this.playerTarget.duration
    // var currentWidth = (this.playerTarget.currentTime / this.playerTarget.duration) * 100 + "%"
    // this.progressBarTarget.width = currentWidth
  }

  calculateTotalValue(length) {
    var current_hour = parseInt(length / 3600) % 24
    var current_minute = parseInt(length / 60) % 60
    var current_seconds_long = length % 60
    var current_seconds = current_seconds_long.toFixed()
    var length = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds)

    return length
  }

  calculateCurrentValue(currentTime) {
    var current_hour = parseInt(currentTime / 3600) % 24
    var current_minute = parseInt(currentTime / 60) % 60
    var current_seconds_long = currentTime % 60
    var current_seconds = current_seconds_long.toFixed()
    var current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds)

    return current_time
  }

}