
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `users--works--activities--tickets--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("users--works--activities--tickets--main").contentTarget

    // this.getControllerByIdentifier("users--works--permissions--entities--user").doPageGrid(element)
    // this.getControllerByIdentifier("users--works--permissions--entities--user").get_route = "/users/works/activities/permissions/list_team_permissions"
    // this.getControllerByIdentifier("users--works--permissions--entities--user").new_route = "/users/works/activities/permissions/create_permissions"
    // this.getControllerByIdentifier("users--works--permissions--entities--user").destroy_route = "/users/works/activities/permissions/destroy_permissions"
    // this.getControllerByIdentifier("users--works--permissions--entities--user").subdomain = "tickets"

    // this.getControllerByIdentifier("users--works--permissions--entities--user").subdomain = "work_tickets"
    // this.getControllerByIdentifier("users--works--permissions--entities--user").account_kinds = ["team"]
    // this.getControllerByIdentifier("users--works--permissions--entities--user").current_account_kind = "team"
    // this.getControllerByIdentifier("users--works--permissions--entities--user").doPageGrid(element)

    var element = this.getControllerByIdentifier("users--works--activities--tickets--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "work_tickets"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)

    this.dashboardToggleBtn(`PERMISSÕES`)
    
  }

  goToSquads() {
    this.getControllerByIdentifier("users--works--activities--squads--dashboard").doPageGrid()
  }

  goToTickets() {
    // this.getControllerByIdentifier("users--works--activities--tickets--dashboard").doPageGrid()
    this.getControllerByIdentifier("users--works--activities--tickets--main").doMainGrid()
  }

  doSubmenuHtml() {
    var ticketsBtn = `<button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tickets</button>`

    if (this.application.permissions.user_work_activity_squads.can_manage) {
      this.getControllerByIdentifier(`users--works--activities--tickets--main`).getSquads()
      var squadsBtn = `<button type="button" data-action="${this.controllerName}#goToSquads ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Squads</button>`
    } else {
      var squadsBtn = ``
    }

    if (this.application.permissions.user_work_activity_permissions.can_assign) {
      this.application.domain_permission = this.application.permissions[`user_work_activity_permissions`]
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${ticketsBtn}
                  ${squadsBtn}
                  ${permissionBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
