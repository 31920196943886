import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "previewCard", "previewTitle", "checkboxShow", "tableTitle", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--installments--show`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.loan_installments = []
    this.current_loan = {}
    // this.current_installment = {}
  }

  doNoInstallment() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewInstallmentCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhum Empréstimo/Parcelamento Selecionado</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").installmentsTarget.innerHTML = html
  }

  doDataCard() {
    var show = false
    this.application.loan_installments.forEach(installment => {
      if (installment.loan_id == this.current_loan.id && installment.date_id == this.application.current_date.id) {
        this.current_installment = installment
        show = true
      }
    })

    if (show) {
      this.showInstallment()
    } else {
      this.doNoDateInstallment()
    }
  }

  doNoDateInstallment() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewInstallmentCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Não há Parcela para a Competência</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").installmentsTarget.innerHTML = html
  }

  editInstallment() {
    if (this.application.current_date.open) {
      if (this.application.permissions.financial_payable_installments.can_update && this.current_installment.status == `not_paid`) {
        this.getControllerByIdentifier("financials--books--payables--installments--save").current_installment = this.current_installment
        this.getControllerByIdentifier("financials--books--payables--installments--save").current_loan = this.current_loan
        this.getControllerByIdentifier("financials--books--payables--installments--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--payables--installments--save").doFormHtml()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Essa conta está Inativa e não pode ser Editada`, 5000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  deleteInstallment() {
    if (this.application.current_date.open) {
      this.send_data = { current_user: {}, installment: {} }

      this.actionMode = `delete`
      this.send_data.installment.id = this.current_installment.id
      this.send_data.installment.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_payable_installments`

      var r = confirm(`Tem certeza que deseja apagar a Parcela?`)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.getControllerByIdentifier("financials--books--payables--installments--save").send_data = this.send_data
        this.getControllerByIdentifier("financials--books--payables--installments--save").actionMode = `delete`
        this.getControllerByIdentifier("financials--books--payables--installments--save").requestSave()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  showInstallment() {
    this.getControllerByIdentifier(`financials--books--payables--installments--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    // if (this.application.permissions.financial_payable_installments.can_delete && this.current_installment.status != `paid`) {
    if (this.application.permissions.financial_payable_installments.can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteInstallmentBtn" data-action="click->${this.controllerName}#deleteInstallment" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Empréstimo/Parcelamento</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.financial_payable_installments.can_update && this.current_installment.status != `paid`) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editInstallmentBtn" data-action="click->${this.controllerName}#editInstallment" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Empréstimo/Parcelamento</span>`
    } else {
      var editBtnHtml = ``
    }

    if ((this.application.permissions.financial_payable_installments.can_delete && this.current_installment.status != `paid`) || this.application.current_user.super_admin) {
      var deleteLine = `<span data-action="click->${this.controllerName}#deleteInstallment" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Parcela</span>`
    } else {
      var deleteLine = ``
    }

    if (this.application.permissions.financial_payable_installments.can_update && this.current_installment.status != `paid`) {
      var editLine = `<span data-action="click->${this.controllerName}#editInstallment" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Parcela</span>`
    } else {
      var editLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${editLine}
                          ${deleteLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Status</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_installment.status_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Data Vencimento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_installment.due_date_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Parcela</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_installment.due_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Amortizado</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_installment.amortization_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Juros</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_installment.interests_amount)}
                              </td>
                            </tr>

                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Tipo</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_installment.kind_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Forma Pagamento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_installment.method_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Canal Pagamento</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_installment.channel_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Fornecedor</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_loan.provider_name}
                              </td>
                            </tr>

                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--loans--dashboard").installmentsTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `${controller.current_installment.description}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getInstallments(loan_id) {
    this.showInstallmentLoader()

    const data = { installment: { active: true, loan_id: loan_id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_installments` } }
    const url = "/financials/books/payables/installments/list_by_loan"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.loan_installments = response.data.cln.installments
        controller.current_loan = response.data.cln.loan

        controller.application.loans.forEach((element, i) => {
          if (element.id == controller.current_loan.id) {
            controller.application.loans.splice(controller.application.loans.indexOf(element), 1, controller.current_loan)
          }
        })

        controller.getControllerByIdentifier(`financials--books--payables--loans--show`).current_loan = controller.current_loan
        controller.getControllerByIdentifier(`financials--books--payables--loans--show`).showLoan()
        controller.doDataCard()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  showInstallmentLoader() {
    var html = `<div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110">${this.loader}</h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center" style="overflow:auto;">
                    ${this.loader}
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").showTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
