import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "mainCard", "pipelineCard", "footerTable", "refreshCalculationBtn", "dateInput", "dateDropdown", "clearListBtn", "optionBtn",
                    "awaiting_documentation", "documentation_sent", "in_process", "draft_sent", "awaiting_transmission", "transmitted", "dateDropdown",
                    "clearListBtn", "optionBtn", "rectification"]

  connect() {
    this.controllerName = `operations--products--tax-filings--reports--pipeline`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    // this.doCalculationListHtml()
  }

  doPipelineHtml() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row mb-3 d-flex align-items-center">
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Aguardando Documentação</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Documentação Enviada</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Em Processo</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Rascunho Enviado</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Aguardando Transmissão</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="pipelineCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Transmitido</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 px-2" data-${this.controllerName}-target="awaiting_documentation" style="overflow:auto;"></div>
                  <div class="col-2 px-2" data-${this.controllerName}-target="documentation_sent" style="overflow:auto;"></div>
                  <div class="col-2 px-2" data-${this.controllerName}-target="in_process" style="overflow:auto;"></div>
                  <div class="col-2 px-2" data-${this.controllerName}-target="draft_sent" style="overflow:auto;"></div>
                  <div class="col-2 px-2" data-${this.controllerName}-target="awaiting_transmission" style="overflow:auto;"></div>
                  <div class="col-2 px-2" data-${this.controllerName}-target="transmitted rectification" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--reports--dashboard`).reportContentTarget.innerHTML = html)
    }).then(() => {
      controller.awaiting_documentationTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.documentation_sentTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.in_processTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.draft_sentTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.awaiting_transmissionTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.transmittedTarget.style.height = ($(window).height() * 0.65) + "px"

      controller.doPipelineReport()
    })
  }

  doPipelineReport() {
    this.application.tax_filings.forEach(elementProduct => {

      if (elementProduct.calculations.length > 0) {
        elementProduct.calculations.forEach(elementCalc => {
          if (Number(elementCalc.date_token.substr(5, 4)) == Number(this.current_date.year - 1)) {
            var html = `<div class="card pointer mb-1" data-product-path="${elementProduct.product_path}" data-action="click->${this.controllerName}#goToShowPage">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;">
                              ${elementProduct.account_name}
                            </h6>
                          </div>
                        </div>`

            this.nameTarget(`${elementCalc.journey_status}`).insertAdjacentHTML("beforeend", html) // Dentro do elemento, após seu último filho (childNode).
          }
        })
      }

    })
  }

  goToShowPage(ev) {
    var url = ev.currentTarget.dataset.productPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}