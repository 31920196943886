import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addContractBtn", "pendencyCount", "sideBarReport", "XXXBtn",
                    "clearListBtn", "optionBtn", "mainCardSubAccounts", "bodyTableSubAccounts"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `financials--books--receivables--contracts--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 4
    this.sort = { mode: `asc`, field: `name` }
  }

  doNoContract() {
    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewContractCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhum Cliente Selecionado</strong></h6>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).listTarget.innerHTML = html
  }

  addContract(ev) {
    if (this.application.current_date.open) {
      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)

      var kindModel = ev.currentTarget.dataset.model
      
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").controllerDashboard = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`)
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").permission = `financial_receivable_contracts`
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_client = this.current_client
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_contract = {}
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").kindModel = kindModel
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  checkForEditContract() {
    this.kind = `edit_contract`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  editContract(ev) {
    var current_contract = {}
    var contractId = ev.currentTarget.dataset.id

    this.application.client_contracts.forEach(contract => {
      if (contract.id == contractId) {
        current_contract = contract
      }
    });

    this.getControllerByIdentifier("financials--books--receivables--contracts--save").controllerDashboard = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`)
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").permission = `financial_receivable_contracts`
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_client = this.current_client
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_contract = current_contract
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").actionMode = "edit"
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").doFormHtml()
  }

  destroyContract(ev) {
    var current_contract = {}
    var contractId = ev.currentTarget.dataset.id

    this.application.client_contracts.forEach(contract => {
      if (contract.id == contractId) {
        current_contract = contract
      }
    })

    this.nameTarget(`check-${contractId}`).disabled = true

    this.send_data = { current_user: {}, contract: {} }

    this.send_data.contract.id = contractId
    this.send_data.contract.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_contracts`

    this.getControllerByIdentifier("financials--books--receivables--contracts--save").controllerDashboard = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`)
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").permission = `financial_receivable_contracts`
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").actionMode = `destroy`
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").send_data = this.send_data
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_client = this.current_client
    this.getControllerByIdentifier("financials--books--receivables--contracts--save").current_contract = current_contract

    var r = confirm(`Tem certeza que deseja apagar o Contrato?`)
    if (r) {
      this.getControllerByIdentifier("financials--books--receivables--contracts--save").requestSave()
    }
  }

  checkForDestroyContract() {
    this.kind = `destroy_contract`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doContractList() {
    this.current_contracts = this.application.client_contracts

    if (this.application.permissions.financial_receivable_contracts.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_pos_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Pós-Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_amendment" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Alteração PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medfiling_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Declaração IRPF</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_receivable_contracts.can_update) {
      var editLine = `<span data-action="click->${this.controllerName}#checkForEditContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Contrato</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions.financial_receivable_contracts.can_delete) {
      var destroyLine = `<span data-action="click->${this.controllerName}#checkForDestroyContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Contrato</span>`
    } else {
      var destroyLine = ``
    }

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${editLine}
                          ${destroyLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`


    var addAccountLine = `<span data-action="click->${this.controllerName}#addAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Subconta</span>`
    var submenuAccountHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addAccountLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto help">
                          <span class="mc-tooltip">
                            <span>Todos os Contratos</span>
                            <span class="mc-tooltiptext">${this.current_client.record_name}</span>
                          </span>
                        </h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center pointer">Início</th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center pointer">Valor</th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center pointer">Status</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    html += `<div class="row mt-3">
                <div class="col-12 px-0">
                  <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCardSubAccounts">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title f-065 mb-0 mr-auto">
                        Subcontas | ${this.current_client.record_name}
                      </h6>
                      <div class="card-actions ml-auto py-0">
                        ${submenuAccountHtml}
                        ${refreshBtnHtml}
                      </div>
                    </div>
                    <div class="card-body py-0 px-2" style="overflow:auto;">
                      <div class="row">
                        <div class="col p-0">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">ID Iugu</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-50 align-middle text-center">Email</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTableSubAccounts">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.35) + "px"
      controller.mainCardSubAccountsTarget.style.height = ($(window).height() * 0.25) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))

      controller.doDataTable()
      controller.doSubAccountsTable()
      controller.getControllerByIdentifier("financials--books--receivables--contracts--show").doNoContract()
    })
  }

  doDataTable() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    if (this.sort.mode == `asc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_contracts, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_contracts, this.sort.field)
    }

    if (contracts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(contracts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (contracts[x] !== undefined) {
            internPage[internPage.length] = contracts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: contracts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contratos para ${this.current_client.record_name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.contractTablePartial(element, data.length))
      })
    }
  }

  contractTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editContract">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `destroy_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#destroyContract">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`

    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showAddonsEv">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showAddonsEv">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showAddonsEv">${this.controllerNumber.currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.financial_status_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-12 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doSubAccountsTable() {
    this.bodyTableSubAccountsTarget.innerHTML = ""
    
    if (this.current_client.iugu_accounts instanceof Array) {
      this.current_client.iugu_accounts.forEach((element, index) => {

        var editBtn = `<button data-action="click->${this.controllerName}#editClient" data-index="${index}" data-iugu-id="${element.iugu_id}" data-iugu-email="${element.iugu_email}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar</span>
                      </button>`

        if (this.current_client.iugu_accounts.length == 1) {
          var tableRow = `<tr class="itemRow" style="height:50px;">`
        } else {
          var tableRow = `<tr class="itemRow">`
        }

        var rowHtml = `${tableRow}
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer">
                        <small class="mb-0 f-065 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${element.iugu_id}" data-action="click->app--helpers--copy#copy">${element.iugu_id}</span>
                          <span class="mc-tooltiptext">Clique para Copiar</span>
                        </small>
                      </td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer">
                        <small class="mb-0 f-065 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${element.iugu_email}" data-action="click->app--helpers--copy#copy">${element.iugu_email}</span>
                          <span class="mc-tooltiptext">Clique para Copiar</span>
                        </small>
                      </td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer">${editBtn}</td>
                    </tr>`

        this.bodyTableSubAccountsTarget.insertAdjacentHTML("beforeend", rowHtml)
      })
    } else {
      // throw new TypeError(`The value is not an Array`)
    }
    
  }

  addAccount() {
    var modalClient = `financials--books--receivables--clients--iugu-modal`
    var index = false

    var html = `<div class="modal fade" data-controller="${modalClient}" data-${modalClient}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalClient}-target="body"></div>
                      <div class="modal-footer border-top" data-${modalClient}-target="footer"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalClient).controllerForm = controller
      controller.getControllerByIdentifier(modalClient).current_client = this.current_client
      controller.getControllerByIdentifier(modalClient).iuguId = ``
      controller.getControllerByIdentifier(modalClient).iuguEmail = ``
      controller.getControllerByIdentifier(modalClient).index = index
      controller.getControllerByIdentifier(modalClient).actionMode = `new`
      controller.getControllerByIdentifier(modalClient).feature = `financial_receivable_clients`
      controller.getControllerByIdentifier(modalClient).open()
    })
  }

  editClient(ev) {
    var modalClient = `financials--books--receivables--clients--iugu-modal`
    var iuguId = ev.currentTarget.dataset.iuguId
    var iuguEmail = ev.currentTarget.dataset.iuguEmail
    var index = ev.currentTarget.dataset.index

    var html = `<div class="modal fade" data-controller="${modalClient}" data-${modalClient}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalClient}-target="body"></div>
                      <div class="modal-footer border-top" data-${modalClient}-target="footer"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalClient).controllerForm = controller
      controller.getControllerByIdentifier(modalClient).current_client = this.current_client
      controller.getControllerByIdentifier(modalClient).iuguId = iuguId
      controller.getControllerByIdentifier(modalClient).iuguEmail = iuguEmail
      controller.getControllerByIdentifier(modalClient).index = index
      controller.getControllerByIdentifier(modalClient).actionMode = `edit`
      controller.getControllerByIdentifier(modalClient).feature = `financial_receivable_clients`
      controller.getControllerByIdentifier(modalClient).open()
    })
  }

  getContracts() {
    if (this.hasBodyTableTarget) {
      this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    }

    const data = { contract: { active: true, client_id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_contracts` } }
    const url = "/financials/books/clients/contracts/list_by_client"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        controller.application.client_contracts = response.data.cln
        controller.doContractList()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.current_contracts = this.application.client_contracts
    this.kind = false

    this.doDataTable()
    this.getControllerByIdentifier(`financials--books--receivables--contracts--show`).doNoContract()
  }

  showAddonsEv(ev) {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    var contractId = ev.currentTarget.closest(`.itemRow`).dataset.id
    this.showAddons(contractId)
  }

  showAddons(contractId) {
    var current_contract = {}

    this.application.client_contracts.forEach(contract => {
      if (contract.id == contractId) {
        current_contract = contract
      }
    })

    this.getControllerByIdentifier(`financials--books--clients--addons--list`).permission = `financial_receivable_addons`
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).current_contract = current_contract
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).getAddons()

    this.current_contracts = [current_contract]
    this.doDataTable()
  }

  refreshList() {
    this.refreshListBtnTarget.classList.add(`d-none`)
    this.current_contracts = this.application.client_contracts
    this.clearList()

    this.getControllerByIdentifier(`financials--books--receivables--contracts--show`).doNoContract()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
