import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--products--purchases--dashboard--broadcast`
    this.application.commercial_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.commercialUpdateChannel = createChannel({ channel: 'Financials::Products::PurchaseIndexChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "purchase") {
          var purchase = data.cln
          var purchaseIds = controller.mapElements(controller.application.purchases, `id`)

          if (purchaseIds.includes(purchase.id)) {
            controller.application.purchases.forEach(element => {
              if (element.id == purchase.id) {
                if (purchase.active) {
                  controller.application.purchases.splice(controller.application.purchases.indexOf(element), 1, purchase)
                } else {
                  controller.application.purchases.splice(controller.application.purchases.indexOf(element), 1)
                }
              }
            })
          } else {
            controller.application.purchases[controller.application.purchases.length] = purchase
          }

          if (controller.getControllerByIdentifier("financials--products--purchases--dashboard--index").hasMainCardTarget) {
            new Promise(function (resolve) {
              resolve(controller.getControllerByIdentifier("financials--products--purchases--dashboard--index").doPageGrid())
            }).then(() => {
              setTimeout(() => {
                controller.getControllerByIdentifier("financials--products--purchases--dashboard--index").doCalculation()
                controller.getControllerByIdentifier("financials--products--purchases--dashboard--index").doIndexListHtml()
              }, 500);
            })
          }
        } else if (data.kind == "calculation") {
          
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  updateListen() {
    if (this.commercialUpdateChannel) {
      this.commercialUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
