
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "main", "gain", "lost", "dailyChart", "accrualChart", "typeCard", "chart", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "dailyTitleChart", "accrualTitleChart", "dailyChartBodyTable", "accrualChartBodyTable", "bodyDailyChart", "bodyAccrualChart", "accrualChart",
    "dailyChartPreloader", "accrualChartPreloader", "accrualChartCard", "reportPage", "dailyChartSource",]

  connect() {
    this.controllerName = `commercial--sales--opportunities--reports--schedules`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.reports = {}
  }

  doChartHtml() {

    var html = `<div class="row"><div class="col-12 d-none px-0" data-${this.controllerName}-target="dailyChart"></div></div>
                <div class="row"><div class="col-12 d-none px-0" data-${this.controllerName}-target="accrualChart"></div></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--reports--chart`).areaTarget.innerHTML = html)
    }).then(() => {
      if (controller.typeCard == "daily") {
        controller.dailyChartTarget.classList.remove(`d-none`)
        controller.accrualChartTarget.classList.add(`d-none`)
        controller.doDailyChartArea()
      } else if (controller.typeCard == "accrual") {
        controller.dailyChartTarget.classList.add(`d-none`)
        controller.accrualChartTarget.classList.remove(`d-none`)
        controller.doAccrualChartArea()
      }
    })
  }

  doDailyChartArea() {
    var html = `<div class="row">
                  <div class="col-sm-12 px-0">
                    <div class="card" id="weeklyVisitsCard" data-controller="${this.controllerName}2">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="dailyTitleChart">Agendamentos</h6>
                            <div class="d-flex align-items-center justify-content-center" id="weeklyVisits" style="height:250px;" data-${this.controllerName}-target="bodyDailyChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="dailyChartBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" data-${this.controllerName}-target="dailyChartSource">
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dailyChartTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader(controller.typeCard)
    })
  }

  doAccrualChartArea() {
    var html = ``
    var html = `<div class="row">
                  <div class="col-sm-12 px-0">
                    <div class="card" id="weeklyVisitsCard" data-controller="${this.controllerName}2">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="accrualTitleChart">Agendamentos</h6>
                            <div class="d-flex align-items-center justify-content-center" id="weeklyVisits" style="height:250px;" data-${this.controllerName}-target="bodyAccrualChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm mb-0" style="font-size:80%;">
                              <tbody data-${this.controllerName}-target="accrualChartBodyTable"></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.accrualChartTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader(controller.typeCard)
    })
  }

  doChartPreloader(kind) {
    if (kind == `daily`) {
      this.dailyTitleChartTarget.innerHTML = `${this.loader}`
      this.dailyChartBodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(20, 1)
      var dailyChartPreloader = `<div class="loading" data-${this.controllerName}-target="dailyChartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`
      this.bodyDailyChartTarget.innerHTML = dailyChartPreloader
      this.requestDailyChartData()
    } else if (kind == `accrual`) {
      this.accrualTitleChartTarget.innerHTML = `${this.loader}`
      this.accrualChartBodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(20, 1)
      var accrualChartPreloader = `<div class="loading" data-${this.controllerName}-target="accrualChartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`
      this.bodyAccrualChartTarget.innerHTML = accrualChartPreloader
      this.requestAccrualChartData()
    }
  }

  requestAccrualChartData() {
    const data = { chart: { year: this.year, month: this.month, account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/reports/schedules_accrual_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.accrualChartPreloaderTarget.remove()
        if (response.process) {
          controller.accrualTitleChartTarget.innerText = `Agendamentos ${controller.application.current_date.name}`
          new Chartkick["LineChart"](controller.bodyAccrualChartTarget, [response.data.cln[1], response.data.cln[2], response.data.cln[3], response.data.cln[4], response.data.cln[5]], {
            "colors": ["#086BAC", "#84D2F6", "#26C485", "#F25F5C", "#50514F"],
            "download": true,
            "prefix": "",
            "thousands": ".",
            "decimal": ","
          });
          var htmlHeader = ``
          var htmlBody = ``
          response.data.cln[0].data.forEach(element => {
            htmlHeader += `<td style="font-size:80%;height:1rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="p-1 align-middle text-center">${element[0]}</td>`
            htmlBody += `<td style="font-size:80%;height:1rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").numberMask(element[1])}</td>`
          })
          var row = `</tr>
                      <td style="font-size:120%;" scope="col" rowspan="2" class="p-1 align-middle">Leads</td>
                      ${htmlHeader}
                    </tr>
                    </tr>
                      ${htmlBody}
                    </tr>`
          this.accrualChartBodyTableTarget.innerHTML = row
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        }
      })
  }

  requestDailyChartData() {
    const data = { chart: { year: this.year, month: this.month, account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/reports/schedules_daily_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.dailyChartPreloaderTarget.remove()
        if (response.process) {
          controller.dailyTitleChartTarget.innerText = `Agendamentos ${controller.application.current_date.name}`
          new Chartkick["LineChart"](controller.bodyDailyChartTarget, [response.data.cln[1], response.data.cln[2], response.data.cln[3], response.data.cln[4], response.data.cln[5]], {
            "colors": ["#086BAC", "#84D2F6", "#26C485", "#F25F5C", "#50514F"],
            "download": true,
            "prefix": "",
            "thousands": ".",
            "decimal": ","
          });
          var htmlHeader = ``
          var htmlBody = ``
          response.data.cln[0].data.forEach(element => {
            htmlHeader += `<td style="font-size:80%;height:1rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="p-1 align-middle text-center">${element[0]}</td>`
            htmlBody += `<td style="font-size:80%;height:1rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").numberMask(element[1])}</td>`
          })
          var row = `</tr>
                      <td style="font-size:120%;" scope="col" rowspan="2" class="p-1 align-middle">Leads</td>
                      ${htmlHeader}
                    </tr>
                    </tr>
                      ${htmlBody}
                    </tr>`
          this.dailyChartBodyTableTarget.innerHTML = row
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        }
      })
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
