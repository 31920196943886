import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--dashboards--dates`
  }

  changeDate(ev) {
    var id = ev.currentTarget.dataset.id
    this.setCommercialcDate(id)
    this.toggleDateBtn(id)
  }

  setCommercialcDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        this.application.current_calculation = element.calculation
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.cleanDashboardForms()
    this.getControllerByIdentifier("commercial--dashboards--main").doCalculationCards()
  }

  cleanDashboardForms() {
    if (this.getControllerByIdentifier("commercial--sales--opportunities--entities--save")) {
      this.getControllerByIdentifier("commercial--sales--opportunities--entities--save").cancelSave()
    }

    if (this.getControllerByIdentifier("commercial--sales--leads--entities--save")) {
      this.getControllerByIdentifier("commercial--sales--leads--entities--save").cancelSave()
    }
  }

  doDatesHTML() {
    var datesHtml = ``

    this.application.dates.forEach(element => {
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    });

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)

      }
    });
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
