import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "title", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `users--accounts--entities--index`
    this.tableCols = 5
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.hasShow = false
    this.doIndexListHtml()
  }

  showAccount(ev) {
    var controllerShow = this.getControllerByIdentifier("users--accounts--entities--show")

    var accountId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.application.accounts.forEach(element => {
      if (element.id == accountId) {
        controllerShow.current_account = element
        this.application.account_contact = {}
        this.application.account_contact.account_id = element.id
        this.application.account_contact.record_type = `account_entities`
      }
    })

    controllerShow.showAccount = true
    controllerShow.doViewHtml()
  }

  addAccount() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.getControllerByIdentifier("users--accounts--entities--save").actionMode = "new"
    this.getControllerByIdentifier("users--accounts--entities--save").kind = this.kind
    this.getControllerByIdentifier("users--accounts--entities--save").kind_pretty = this.translateKind(this.kind)
    this.getControllerByIdentifier("users--accounts--entities--save").current_account = {}
    this.getControllerByIdentifier("users--accounts--entities--save").doFormHtml()
  }

  uploadAccount() {
    this.getControllerByIdentifier("app--helpers--pre-upload").url_upload = `/users/accounts/entities/upload`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/users/accounts/entities/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download_model = `/users/accounts/entities/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `PUT`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `CPFs`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `account_entities`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = []
    this.getControllerByIdentifier(`app--helpers--pre-upload`).openUploadModal()
  }

  downloadAccount() {
    var attrs = ["name", "trade_name", "cnpj", "kind", "subkind", "opened_at", "municipal_number",
      "postal_code", "street", "number", "complement", "district", "city", "state", "country", "ibge", "kind_address"]
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/users/accounts/entities/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `POST`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `CPFs`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `account_entities`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = []
    this.getControllerByIdentifier(`app--helpers--pre-upload`).downloadData()
  }
  
  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions.account_entities.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Nova Conta CPF</span>`
    } else {
      var addLine = ``
    }

    // if (this.application.permissions.account_entities.can_download) {
    if (this.application.current_user.super_admin) {
      var downloadLine = `<span data-action="click->${this.controllerName}#downloadAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Download de Contas</span>`
    } else {
      var downloadLine = ``
    }

    if (this.application.permissions.account_entities.can_upload) {
      var uploadLine = `<span data-action="click->${this.controllerName}#uploadAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload de Contas</span>`
    } else {
      var uploadLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${downloadLine}
                          ${uploadLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="title"></h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Nome</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">CPF</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Aniversário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Usuário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--accounts--entities--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.titleTarget.innerText = `Todas as Contas Tipo ${controller.getControllerByIdentifier(`users--works--permissions--entities--list-users`).kindTranslate(controller.kind)}`
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_accounts = []

    if (this.kind == `team`) {
      if (this.application.current_user.super_admin) {
        this.application.accounts.forEach(element => {
          if (element.kind.includes(this.kind)) {
            current_accounts[current_accounts.length] = element
          }
        })
      } else {
        this.application.accounts.forEach(element => {
          if (element.kind.includes(this.kind) && element.blocked == false) {
            current_accounts[current_accounts.length] = element
          }
        })
      }
    } else {
      this.application.accounts.forEach(element => {
        if (element.kind.includes(this.kind) && element.kind.includes(`team`) == false) {
          current_accounts[current_accounts.length] = element
        }
      })
    }

    if (this.sort.mode == `asc`) {
      var accounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_accounts, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var accounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_accounts, this.sort.field)
    }

    if (accounts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(accounts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (accounts[x] !== undefined) {
            internPage[internPage.length] = accounts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: accounts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contas CPF</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.accountTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  accountTablePartial(element, length) {

    if (element.has_user) {
      var blocked = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Tem Usuário</span></span>`
    } else {
      var blocked = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não Tem Usuário</span></span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showAccount">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">
                      <span class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.cpf}" data-action="click->app--helpers--copy#copy">${element.cpf_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.birthdate_day}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${blocked}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.blocked_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.current_contracts = this.application.client_contracts
    this.kind = false
    this.doDataTable()

    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  translateKind(kind) {

    if (kind == `team`) {
      return `Time MedCapital`
    } else if (kind == `taker`) {
      return `Agente do Tomador`
    } else if (kind == `doctor`) {
      return `Médico`
    } else if (kind == `non_doctor`) {
      return `Sócio Não-Médico`
    } else if (kind == `helper`) {
      return `Assistente`
    } else if (kind == `patient`) {
      return `Paciente`
    } else {

    }

  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
