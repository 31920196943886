import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "saveBtn", "chooseType", "errorMessage",
                    "sexDropdown", "sexDropdownBtn", "sexInput", "sexList", "helperCheck", "sponsorCheck", "pjTakerInfo",
                    "financialCheckbox", "administrativeCheckbox", "sameCityCheckbox", "legalCheckbox", "technicalCheckbox", "operationalCheckbox",
                    "employmentInput", "notesInput", "descriptionInput", "pfTakerCheckbox", "pjTakerCheckbox",
                    "pfTakerInfo", "cnpjInput", "cpfInputDiv", "cnpjInputDiv", "pfTakerCheckbox", "pjTakerCheckbox",
                    "tradeNameInput", "fantasyNameInput", "takerInfo", "municipalNumberInput"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--takers--save`
    this.search = true

    if (this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard")
    }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--index").clearList()
  }

  saveTaker() {
    this.send_data = { current_user: {}, account: {}, company: {}, taker: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.pfTakerCheckboxTarget.checked) {
      this.send_data.account.is_account = true
      this.send_data.company.is_company = false
      this.send_data.taker.id_type = "individual"
      this.send_data.taker.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.taker.name = this.nameInputTarget.value.trim()
      // if (this.sameCityCheckboxTarget.checked) {
      //   this.send_data.taker.taxation_type = `within_city`
      // } else {
      //   this.send_data.taker.taxation_type = `outside_city`
      // }

      this.send_data.taker.taxation_type = `within_city`
      if (this.account.id) {
        this.send_data.account.id = this.account.id
        this.send_data.taker.record_id = this.account.id
      } else {
        this.send_data.account.name = this.nameInputTarget.value.trim()
        this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
        this.send_data.account.sex = this.sexInputTarget.dataset.sex
        if (this.birthdateInputTarget.value == ``) {
          this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/01/1900`)
        } else {
          this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.birthdateInputTarget.value)
        }
      }

      this.send_data.account.kind = `patient`

    } else if (this.pjTakerCheckboxTarget.checked) {
      this.send_data.account.is_account = false
      this.send_data.company.is_company = true
      this.send_data.taker.id_type = "legal"
      this.send_data.taker.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value.trim())
      this.send_data.taker.name = this.tradeNameInputTarget.value.trim()
      this.send_data.taker.municipal_number = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.municipalNumberInputTarget.value.trim())
      
      if (this.sameCityCheckboxTarget.checked) {
        this.send_data.taker.taxation_type = `within_city`
      } else {
        this.send_data.taker.taxation_type = `outside_city`
      }

      if (this.company.id) {
        this.send_data.company.id = this.company.id
        this.send_data.taker.record_id = this.company.id
      } else {
        this.send_data.company.trade_name = this.tradeNameInputTarget.value.trim()
        this.send_data.company.name = this.fantasyNameInputTarget.value.trim()
        this.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value)
        this.send_data.company.municipal_number = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.municipalNumberInputTarget.value.trim())
      }

      this.send_data.company.kind = `taker`
    }


    this.send_data.taker.clinic_id = this.application.clinic.id
    this.send_data.taker.description = this.descriptionInputTarget.value
    this.send_data.taker.notes = this.notesInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/regularizations/takers/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var taker = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.takers[controller.application.takers.length] = taker
          } else if (controller.actionMode == `edit`) {
            controller.application.takers.forEach((element, i) => {
              if (element.id == taker.id) {
                controller.application.takers.splice(controller.application.takers.indexOf(element), 1, taker)
              }
            })
          }
          
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--clinics--regularizations--takers--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTaker" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }
    // if (this.application.medclinic_chart_accounts.can_delete && this.actionMode == "edit") {
    //   var deleteBtnHtml = `<button disabled type="button" class="btn btn-danger btn-sm" data-action="click->${this.controllerName}#deleteHelper" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    // } else {
    //   var deleteBtnHtml = ``
    // }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Tomador</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row mt-3 d-flex align-items-center" style="height:60px;">
                      <div class="col-4">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="pfTakerCheck" data-${this.controllerName}-target="pfTakerCheckbox" data-type="individual" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="pfTakerCheck">Tomador PF</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="pjTakerCheck" data-${this.controllerName}-target="pjTakerCheckbox" data-type="legal" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="pjTakerCheck">Tomador PJ</label>
                        </div>
                      </div>
                      <div class="col-4 px-2 d-flex align-items-center" data-${this.controllerName}-target="chooseType">
                        <span class="f-065">Escolha o Tipo de Tomador</span>
                      </div>
                      <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF Tomador</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount" placeholder="CPF Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ Tomador</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany" placeholder="CNPJ Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="pfTakerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Tomador</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pfTakerInfo">
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="birthdateForm">Data Nascimento</label>
                            <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sexo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                  <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                  <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pjTakerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="tradeNameForm">Razão Social</label>
                            <input aria-describedby="tradeNameFormHelp" class="form-control form-valid-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pjTakerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="fantasyNameForm">Nome Fantasia</label>
                            <input aria-describedby="fantasyNameFormHelp" class="form-control form-valid-control" id="fantasyNameForm" data-${this.controllerName}-target="fantasyNameInput" placeholder="Nome Fantasia" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="municipalNumberForm">Inscrição Municipal</label>
                            <input aria-describedby="municipalNumberFormHelp" class="form-control form-valid-control" id="municipalNumberForm" data-${this.controllerName}-target="municipalNumberInput" placeholder="Inscrição Municipal" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-none" data-${this.controllerName}-target="takerInfo">
                      <div class="col-6">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="sameCityCheck" data-${this.controllerName}-target="sameCityCheckbox">
                          <label class="custom-control-label f-065 pointer" for="sameCityCheck">Mesma Cidade</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Mesma cidade?</span>
                        </div>
                      </div>
                    </div>

                    <div class="row d-none" data-${this.controllerName}-target="takerInfo">
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição NF</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição NF" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notesForm">Observações Contrato</label>
                            <textarea rows="3" aria-describedby="notesFormHelp" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Observações Contrato" type="text" required></textarea>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.refreshSaveBtn()

      if (controller.actionMode == `new`) {
        controller.descriptionInputTarget.value = `Prestação de Serviços Médicos`
      } else {
        
      }

      if (controller.pfTakerCheckboxTarget.checked) {
        controller.chooseTypeTarget.classList.remove("d-none")
        controller.cpfInputDivTarget.classList.remove("d-none")
        controller.cnpjInputDivTarget.classList.add("d-none")
        controller.takerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pfTakerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pjTakerInfoTargets.forEach(element => {
          element.classList.add("d-none")
        })
        controller.nameInputTarget.disabled = true
        controller.birthdateInputTarget.disabled = true
        controller.sexDropdownBtnTarget.disabled = true
        controller.sameCityCheckboxTarget.disabled = false
        controller.notesInputTarget.disabled = true
      } else if (controller.pjTakerCheckboxTarget.checked) {
        controller.chooseTypeTarget.classList.remove("d-none")
        controller.takerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pfTakerInfoTargets.forEach(element => {
          element.classList.add("d-none")
        })
        controller.pjTakerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.cpfInputDivTarget.classList.add("d-none")
        controller.cnpjInputDivTarget.classList.remove("d-none")
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  typeCheck(ev) {
    var type = ev.currentTarget.dataset.type

    if (type == "individual") {
      this.pfTakerCheckboxTarget.checked = true
      this.pjTakerCheckboxTarget.checked = false
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")

      this.takerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pfTakerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pjTakerInfoTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.sameCityCheckboxTarget.disabled = true
      this.sameCityCheckboxTarget.checked = true
      this.notesInputTarget.disabled = true
      this.cpfInputTarget.focus()
    } else if (type == "legal") {
      this.takerInfoTarget.classList.remove("d-none")
      this.pfTakerCheckboxTarget.checked = false
      this.pjTakerCheckboxTarget.checked = true
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")

      this.takerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pfTakerInfoTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pjTakerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.sameCityCheckboxTarget.disabled = false
      this.notesInputTarget.disabled = false
      this.cnpjInputTarget.focus()
    }
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  numberMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToNumber(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""

      this.nameInputTarget.disabled = false
      this.birthdateInputTarget.disabled = false
      this.sexDropdownBtnTarget.disabled = false
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "" && this.search) {
      this.search = false
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "doctor" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
        var hasTaker = this.checkIfHasTaker(`individual`, cleanCpf)
        if (hasTaker) {
          // this.setErrorMessage("message")
        } else {
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              controller.search = true
              if (response.process) {
                controller.account = response.data.cln
                controller.nameInputTarget.disabled = false
                if (controller.account.id) {
                  controller.nameInputTarget.value = controller.account.name
                  controller.birthdateInputTarget.value = controller.account.birthdate_pretty
                  controller.sexInputTarget.innerText = controller.account.sex_pretty
                  controller.sexInputTarget.dataset.sex = controller.account.sex
                  controller.sexDropdownTarget.value = controller.account.sex

                  controller.nameInputTarget.disabled = true
                  controller.birthdateInputTarget.disabled = true
                  controller.sexDropdownBtnTarget.disabled = true

                } else {
                  controller.nameInputTarget.value = "Favor adicionar o Nome do Tomador"

                  controller.nameInputTarget.disabled = false
                  controller.birthdateInputTarget.disabled = false
                  controller.sexDropdownBtnTarget.disabled = false
                }
                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }

      }
    }
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 14) {
      this.fantasyNameInputTarget.value = ""
      this.tradeNameInputTarget.value = ""
      this.municipalNumberInputTarget.value = ""
      // this.openedAtTarget.value = ""

      this.fantasyNameInputTarget.disabled = true
      this.tradeNameInputTarget.disabled = true
      this.municipalNumberInputTarget.disabled = true
      // this.openedAtTarget.disabled = true

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "") {
    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      var data = { company: { cnpj: cleanCnpj, kind: "" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      if (this.cnpj_result.invalid == false) {
        var hasTaker = this.checkIfHasTaker(`legal`, cleanCnpj)
        if (hasTaker) {
          // this.setErrorMessage("O PJ Médica já possui o produto Declaração de Ajuste Anual")
        } else {
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              if (response.process) {
                controller.company = response.data.cln

                if (controller.company.id) {
                  controller.fantasyNameInputTarget.value = controller.company.name
                  controller.tradeNameInputTarget.value = controller.company.trade_name
                  controller.municipalNumberInputTarget.value = controller.company.municipal_number
                  if (controller.company.opened_at_pretty) {
                    // controller.openedAtTarget.value = controller.company.opened_at_pretty
                  } else {
                    // controller.openedAtTarget.value = ``
                  }

                  controller.fantasyNameInputTarget.disabled = true
                  controller.tradeNameInputTarget.disabled = true
                  controller.municipalNumberInputTarget.disabled = true
                  // controller.openedAtTarget.disabled = false

                  // controller.actionMode = `edit`
                } else {
                  controller.tradeNameInputTarget.value = "Favor adicionar a Razão Social da PJ Médica"
                  controller.fantasyNameInputTarget.value = "Favor adicionar o Nome Fantasia"
                  controller.municipalNumberInputTarget.value = "Favor adicionar a Inscrição Municipal"
                  // controller.openedAtTarget.value = ""

                  controller.fantasyNameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalNumberInputTarget.disabled = false
                  // controller.openedAtTarget.disabled = false

                  // controller.actionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }
      }
    }
  }

  checkIfHasTaker(type, number) {
    var diffHelper = true
    this.application.takers.forEach(element => {
      if (element.id_number == number) {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        var message = `O Tomador já está cadastrado`
        this.setErrorMessage(message)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 2000)
        diffHelper = false
      } else {
        if (diffHelper) {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
      }
    });
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length > 11) || ev.type == "blur" && cleanCpf.length > 11) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CPF Inválido`, 3000)
      this.cpfInputTarget.classList.add("f-danger")
      this.cpfInputTarget.classList.remove("f-normal")
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.pfTakerCheckboxTarget.checked) {
          if (controller.cpf_result && controller.cpf_result.invalid) {
            len += 1
          }

          if (controller.cpfInputTarget.value == ``) {
            len += 1
          }

          if (controller.nameInputTarget.value == ``) {
            len += 1
          }

          // if (controller.birthdateInputTarget.value == ``) {
          //   len += 1
          // }

          if (controller.sexInputTarget.innerText == ``) {
            len += 1
          }
        } else if (controller.pjTakerCheckboxTarget.checked) {
          if (controller.cnpj_result && controller.cnpj_result.invalid) {
            len += 1
          }

          if (controller.cnpjInputTarget.value == ``) {
            len += 1
          }

          if (controller.tradeNameInputTarget.value == `` || controller.tradeNameInputTarget.value ==  `Favor adicionar a Razão Social da PJ Médica`) {
            len += 1
          }

          if (controller.fantasyNameInputTarget.value == `` || controller.fantasyNameInputTarget.value == `Favor adicionar o Nome Fantasia`) {
            len += 1
          }

          if (controller.municipalNumberInputTarget.value == `Favor adicionar a Inscrição Municipal`) {
            len += 1
          }

        }


        // if (controller.descriptionInputTarget.innerText == ``) {
        //   len += 1
        // }

        // if (controller.notesInputTarget.innerText == ``) {
        //   len += 1
        // }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
