import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "setupCheckbox", "recurringCheckbox", "saveBtn",
                    "startedAtInput", "iuguIdInput", "iuguEmailInput", "cnpj", "expiresAtInput", "amountInput", "descriptionInput", "errorMessage",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "productIntervalDropdown", "productIntervalDropdownBtn", "productIntervalInput", "productIntervalList",
                    "financialStatusDropdown", "financialStatusDropdownBtn", "financialStatusInput", "financialStatusList", "financialStatusDiv",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList", "productAccrualDiv",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "addonForm", "contractStatusForm", "churnDescriptionInput",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList", "churnDescriptionDiv",
                    "planDropdown", "planDropdownBtn", "planInput", "planList", "notesInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "dueDayDropdown", "dueDayDropdownBtn", "dueDayInput", "dueDayList"]


  connect() {
    this.controllerName = `financials--books--clients--contracts--save`
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
  }

  cancelSave() {
    this.stopRefreshing()

    if (this.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
      this.getControllerByIdentifier("financials--books--receivables--contracts--show").doNoContract()
      this.getControllerByIdentifier("financials--books--receivables--contracts--list").clearList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--dashboards--clients--dashboard`) {
      this.getControllerByIdentifier("financials--books--clients--entities--index").doClientList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--contracts`) {
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).doContractList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--clients--contracts--save-modal`) {
      this.getControllerByIdentifier(`financials--books--clients--contracts--save-modal`).close()
    } else if (this.controllerDashboard.context.identifier == `financials--books--clients--contracts--settings`) {
      this.getControllerByIdentifier(`financials--books--clients--contracts--settings`).contractsTarget.remove()
      this.getControllerByIdentifier(`financials--books--clients--contracts--settings`).setContractInput()
    }
  }

  saveContract() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, contract: {}, addon: {}, tracker: { manual: true } }
    var contractName = `${this.productNameInputTarget.innerText} | ${this.productKindInputTarget.innerText} | ${this.productServiceInputTarget.innerText}`

    if (this.actionMode == `edit`) {
      this.send_data.contract.id = this.current_contract.id
      this.send_data.contract.status = this.statusInputTarget.dataset.status
      this.send_data.contract.churned_description = this.churnDescriptionInputTarget.value.trim()
      this.send_data.contract.financial_status = this.financialStatusInputTarget.dataset.financialStatus

      if (this.expiresAtInputTarget.value && this.expiresAtInputTarget.value != `N/D`) {
        this.send_data.contract.expires_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.expiresAtInputTarget.value}`)
        if (this.send_data.contract.status == `canceled`) {
          this.send_data.contract.churned_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.expiresAtInputTarget.value}`)
        }
      }

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou o Contrato ${this.current_contract.name}`
      this.send_data.tracker.obj_id = this.current_contract.id

    } else if (this.actionMode == `new`) {
      this.send_data.contract.status = `active`

      this.send_data.addon.client_id = this.current_client.id
      this.send_data.addon.status = `active`
      this.send_data.addon.kind = this.kindInputTarget.dataset.kind
      this.send_data.addon.subkind = this.subkindInputTarget.dataset.subkind
      this.send_data.addon.plan = this.planInputTarget.dataset.plan
      this.send_data.addon.description = this.descriptionInputTarget.value.trim()
      this.send_data.addon.notes = this.notesInputTarget.value.trim()
      this.send_data.addon.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.startedAtInputTarget.value}`)
      this.send_data.addon.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
      if (this.hasExpiresAtInputTarget && this.expiresAtInputTarget.value) {
        this.send_data.addon.expires_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.expiresAtInputTarget.value}`)
      }

      this.send_data.addon.client_iugu_id = this.iuguIdInputTarget.value.trim()
      this.send_data.addon.client_iugu_email = this.iuguEmailInputTarget.value.trim()
      this.send_data.contract.financial_status = `not_defaulting`

      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou o Contrato ${contractName} ${this.descriptionInputTarget.value.trim()} para o Cliente ${this.current_client.record_name}`

    }

    this.send_data.contract.client_id = this.current_client.id
    this.send_data.contract.product_name = this.productNameInputTarget.dataset.productName
    this.send_data.contract.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.contract.product_service = this.productServiceInputTarget.dataset.productService
    this.send_data.contract.product_interval = this.productIntervalInputTarget.dataset.productInterval

    this.send_data.contract.due_day = Number(this.dueDayInputTarget.dataset.dueDay)
    this.send_data.contract.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/${this.startedAtInputTarget.value}`)

    if (this.send_data.contract.product_name == `medfiling`) {
      this.send_data.contract.product_accrual = this.productAccrualInputTarget.dataset.productAccrual
      this.send_data.contract.product_plan = this.productPlanInputTarget.dataset.productPlan
    }

    if (this.setupCheckboxTarget.checked) {
      this.send_data.contract.recurring_kind = `setup`
      this.send_data.addon.recurring = false
    } else if (this.recurringCheckboxTarget.checked) {
      this.send_data.contract.recurring_kind = `recurring`
      this.send_data.addon.recurring = true
    }

    this.send_data.tracker.record_id = this.current_client.id
    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_type = `financial_client_contracts`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = "/financials/books/clients/contracts/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/clients/contracts/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/clients/contracts/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {

          var contract = response.data.cln.contract
          var client = response.data.cln.client

          if (controller.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {

            if (controller.actionMode == `new`) {
              controller.application.client_contracts[controller.application.client_contracts.length] = contract
            } else if (controller.actionMode == `edit`) {
              controller.application.client_contracts.forEach((element, i) => {
                if (element.id == contract.id) {
                  controller.application.client_contracts.splice(controller.application.client_contracts.indexOf(element), 1, contract)
                }
              })
            } else if (controller.actionMode == `destroy`) {
              controller.application.client_contracts.forEach((element, i) => {
                if (element.id == contract.id) {
                  controller.application.client_contracts.splice(controller.application.client_contracts.indexOf(element), 1)
                }
              })
            }

            controller.application.clients.forEach((element, i) => {
              if (element.id == client.id) {
                controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
              }
            })

          } else if (controller.controllerDashboard.context.identifier == `financials--books--dashboards--clients--dashboard`) {

            controller.application.clients.forEach((element, i) => {
              if (element.id == client.id) {
                controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
              }
            })

          } else if (controller.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--contracts`) {

            controller.application.clients.forEach((element, i) => {
              if (element.id == client.id) {
                controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
              }
            })

            var controllerModalContracts = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`)
            controllerModalContracts.current_client_contracts[controllerModalContracts.current_client_contracts.length] = contract
          } else if (controller.controllerDashboard.context.identifier == `financials--books--clients--contracts--save-modal` || controller.controllerDashboard.context.identifier == `financials--books--clients--contracts--settings`) {

            controller.application.clients.forEach((element, i) => {
              if (element.id == client.id) {
                controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
              }
            })

            if (controller.actionMode == `new`) {
              controller.application.contracts[controller.application.contracts.length] = contract
            } else if (controller.actionMode == `edit`) {
              controller.application.contracts.forEach((element, i) => {
                if (element.id == contract.id) {
                  controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, contract)
                }
              })
            } else if (controller.actionMode == `destroy`) {
              controller.application.contracts.forEach((element, i) => {
                if (element.id == contract.id) {
                  controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1)
                }
              })
            }

          }


        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        if (controller.hasSaveBtnTarget) {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    // var contractPermission = this.application.permissions.financial_receivable_contracts || this.application.permissions.financial_settings_clients
    var contractPermission = this.application.permissions[this.permission]

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveContract" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row my-2">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="startedAtInput" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="expiresAtForm">Expiração</label>
                                <input aria-describedby="expiresAtFormHelp" class="form-control" id="expiresAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="expiresAtInput" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="sourceCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="sourceCheck">Avulso</label>
                            </div>
                          </div>
                          <div class="col-3 d-flex align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="recurringCheck">Recorrência</label>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Produto</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList">
                                      <li data-product-name="medclinic" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                      <li data-product-name="medbooking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                      <li data-product-name="medfiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Declaração IRPF</li>
                                      <li data-product-name="medreceivement" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestão de Recebimentos</li>
                                      <li data-product-name="medbiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                      <li data-product-name="medpayroll" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Folha de Pagamento</li>
                                      <li data-product-name="medinsurance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                      <li data-product-name="medprev" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Previdência Privada</li>
                                      <li data-product-name="medloan" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito</li>
                                      <li data-product-name="medoutsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">BPO</li>
                                      <li data-product-name="medreturn" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Planner</li>
                                      <li data-product-name="medconsulting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductNameEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço Avulso</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Serviço</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4" data-${this.controllerName}-target="productPlanDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Plano</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4 d-none" data-${this.controllerName}-target="productAccrualDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Competência</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Periodicidade</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productIntervalDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productIntervalDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productIntervalInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productIntervalList" data-app--helpers--search-target="searchList">
                                      <li data-product-interval="monthly" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mensal</li>
                                      <li data-product-interval="yearly" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anual</li>
                                      <li data-product-interval="unique" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Único</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="chartForm1">Dia Vencimento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dueDayDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dueDayInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="dueDayList" data-app--helpers--search-target="searchList">
                                      <li data-due-day="5" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">5</li>
                                      <li data-due-day="10" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">10</li>
                                      <li data-due-day="15" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">15</li>
                                      <li data-due-day="20" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">20</li>
                                      <li data-due-day="25" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">25</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-4 d-none" data-${this.controllerName}-target="financialStatusDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status Financeiro</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="financialStatusDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="financialStatusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="financialStatusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="financialStatusList" data-app--helpers--search-target="searchList">
                                      <li data-financial-status="not_defaulting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Dia</li>
                                      <li data-financial-status="defaulter" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Inadimplente</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        
                        <div class="row my-2 d-none" data-${this.controllerName}-target="contractStatusForm">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="active" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                      <li data-status="paid_off" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Quitado</li>
                                      <li data-status="suspend" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Suspenso</li>
                                      <li data-status="canceled" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Churn</li>
                                      <li data-status="expired" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Expirado</li>
                                      <li data-status="write_off" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anulado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 d-none" data-${this.controllerName}-target="churnDescriptionDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="churnDescriptionForm">Descrição do Churn</label>
                                <input aria-describedby="churnDescriptionFormHelp" class="form-control" id="churnDescriptionForm" data-${this.controllerName}-target="churnDescriptionInput" placeholder="Descrição do Churn" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2" data-${this.controllerName}-target="addonForm">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="revenue" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Receita</li>
                                      <li data-kind="refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reembolso</li>
                                      <li data-kind="discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Desconto</li>
                                      <li data-kind="reversal" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkindListEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estorno</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Subtipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subkindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subkindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subkindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="subkindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Periodicidade</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="planDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="planDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="planInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="planList" data-app--helpers--search-target="searchList">
                                      <li data-plan="monthly" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mensal</li>
                                      <li data-plan="yearly" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anual</li>
                                      <li data-plan="unique" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Único</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control" id="amountForm" data-${this.controllerName}-target="amountInput" placeholder="Valor" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <input aria-describedby="notesFormHelp" class="form-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2 d-flex align-items-center w-100 px-3">
                            <div class="col-1 text-right px-0">
                              <button data-action="click->${this.controllerName}#showAccounts" data-element="iuguIdInput" data-${this.controllerName}-target="accountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">help_center</span>
                                <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Contas</span>
                              </button>
                            </div>
                            <div class="col-11">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="iuguIdForm">Iugu ID</label>
                                  <input aria-describedby="iuguIdFormHelp" class="form-control" id="iuguIdForm" data-${this.controllerName}-target="iuguIdInput" placeholder="Iugu ID" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-1 text-right px-0">
                            </div>
                            <div class="col-11">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="iuguEmailForm">Iugu Email</label>
                                  <input aria-describedby="iuguEmailFormHelp" class="form-control" id="iuguEmailForm" data-${this.controllerName}-target="iuguEmailInput" placeholder="Iugu Email" type="text" required>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`)) {
        controller.formCardTarget.classList.add(`mt-4`)
      } else {
        controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      }

      if (controller.actionMode == `edit`) {
        controller.addonFormTarget.remove()
        controller.contractStatusFormTarget.classList.remove(`d-none`)
        controller.formTitleTarget.innerText = `Editando Contrato`
        controller.financialStatusDivTarget.classList.remove(`d-none`)

        if (controller.application.permissions[controller.permission].can_manage) {
          controller.setupCheckboxTarget.disabled = false
          controller.recurringCheckboxTarget.disabled = false
          controller.startedAtInputTarget.disabled = false
          controller.productNameDropdownBtnTarget.disabled = false
          controller.productKindDropdownBtnTarget.disabled = false
          controller.productServiceDropdownBtnTarget.disabled = false
        } else {
          controller.setupCheckboxTarget.disabled = true
          controller.recurringCheckboxTarget.disabled = true
          controller.startedAtInputTarget.disabled = true
          controller.productNameDropdownBtnTarget.disabled = true
          controller.productKindDropdownBtnTarget.disabled = true
          controller.productServiceDropdownBtnTarget.disabled = true
        }

        controller.financialStatusDropdownBtnTarget.disabled = false

        if (controller.current_contract.recurring_kind == `recurring`) {
          controller.recurringCheckboxTarget.checked = true
        } else if (controller.current_contract.recurring_kind == `setup`) {
          controller.setupCheckboxTarget.checked = true
        }

        controller.startedAtInputTarget.value = controller.current_contract.started_at_pretty

        controller.productNameDropdownTarget.value = controller.current_contract.product_name
        controller.productNameInputTarget.dataset.productName = controller.current_contract.product_name
        controller.productNameInputTarget.innerText = controller.current_contract.product_name_pretty

        controller.productKindDropdownTarget.value = controller.current_contract.product_kind
        controller.productKindInputTarget.dataset.productKind = controller.current_contract.product_kind
        controller.productKindInputTarget.innerText = controller.current_contract.product_kind_pretty

        controller.productServiceDropdownTarget.value = controller.current_contract.product_service
        controller.productServiceInputTarget.dataset.productService = controller.current_contract.product_service
        controller.productServiceInputTarget.innerText = controller.current_contract.product_service_pretty

        controller.productPlanDropdownTarget.value = controller.current_contract.product_plan
        controller.productPlanInputTarget.dataset.productPlan = controller.current_contract.product_plan
        controller.productPlanInputTarget.innerText = controller.current_contract.product_plan_pretty

        controller.productIntervalDropdownTarget.value = controller.current_contract.product_interval
        controller.productIntervalInputTarget.dataset.productInterval = controller.current_contract.product_interval
        controller.productIntervalInputTarget.innerText = controller.current_contract.product_interval_pretty

        controller.financialStatusDropdownTarget.value = controller.current_contract.financial_status
        controller.financialStatusInputTarget.dataset.financialStatus = controller.current_contract.financial_status
        controller.financialStatusInputTarget.innerText = controller.current_contract.financial_status_pretty

        controller.statusDropdownTarget.value = controller.current_contract.status
        controller.statusInputTarget.dataset.status = controller.current_contract.status
        controller.statusInputTarget.innerText = controller.current_contract.status_pretty

        controller.dueDayDropdownTarget.value = controller.current_contract.due_day
        controller.dueDayInputTarget.dataset.dueDay = controller.current_contract.due_day
        controller.dueDayInputTarget.innerText = controller.current_contract.due_day

        if (controller.current_contract.product_name == `medfiling`) {
          controller.productAccrualDropdownTarget.value = controller.current_contract.product_accrual
          controller.productAccrualInputTarget.dataset.productAccrual = controller.current_contract.product_accrual
          controller.productAccrualInputTarget.innerText = controller.current_contract.product_accrual
          controller.productAccrualDivTarget.classList.remove(`d-none`)
          // controller.productPlanDivTarget.classList.remove(`d-none`)
          controller.setProductAccrualList()
        } else {
          controller.productAccrualDivTarget.classList.add(`d-none`)
          // controller.productPlanDivTarget.classList.add(`d-none`)
        }
        controller.setProductPlanList()

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Contrato | ${controller.current_client.record_name} - ${controller.current_client.record_type_pretty}: ${controller.current_client.record_number_pretty} | Início Cliente: ${controller.current_client.started_at_pretty}`
        controller.financialStatusDivTarget.classList.add(`d-none`)

        controller.financialStatusDropdownTarget.value = `not_defaulting`
        controller.financialStatusDropdownBtnTarget.disabled = true
        controller.financialStatusInputTarget.dataset.financialStatus = `not_defaulting`
        controller.financialStatusInputTarget.innerText = `Em Dia`

        var clientIuguIds = controller.mapElements(controller.current_client.accounts, `iugu_id`)

        if (controller.current_billing) {
          controller.iuguIdInputTarget.value = controller.current_billing.client_id_iugu
          controller.iuguEmailInputTarget.value = controller.current_billing.email_iugu
          // } else if (controller.current_client.iugu_ids.length == 1) {
        } else if (clientIuguIds.length == 1) {
          controller.iuguIdInputTarget.value = controller.current_client.id_iugu
          controller.iuguEmailInputTarget.value = controller.current_client.record_email
        }

        if (controller.kindModel) {
          controller.setKindModel()
        }

      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  uniqKind(ev) {
    var kind = ev.currentTarget.dataset.kind

    if (kind == `setup`) {
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  selectProductNameEv(ev) {
    this.product_name = ev.currentTarget.dataset.productName

    this.selectProductName()
  }

  selectProductName() {

    if (this.product_name == `medfiling`) {
      this.productAccrualDivTarget.classList.remove(`d-none`)
      // this.productPlanDivTarget.classList.remove(`d-none`)
      this.setProductAccrualList()
    } else {
      this.productAccrualDivTarget.classList.add(`d-none`)
      // this.productPlanDivTarget.classList.add(`d-none`)
    }
    
    this.setProductPlanList()
    this.listProductKind()
    this.listKind()
  }

  setKindModel() {

    this.startedAtInputTarget.value = this.application.current_date.date_accrual
    this.amountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

    this.kindDropdownTarget.value = `revenue`
    this.kindInputTarget.dataset.kind = `revenue`
    this.kindInputTarget.innerText = `Receitas`

    this.subkindDropdownTarget.value = `revenue_amount`
    this.subkindInputTarget.dataset.subkind = `revenue_amount`
    this.subkindInputTarget.innerText = `Prestação de Serviços`

    if (this.kindModel == `medclinic_opening` || this.kindModel == `medclinic_pos_opening` || this.kindModel == `medclinic_amendment`) {
      this.product_name = `medclinic`
      this.selectProductName()

      this.productNameDropdownTarget.value = `medclinic`
      this.productNameInputTarget.dataset.productName = `medclinic`
      this.productNameInputTarget.innerText = `PJ Médica`

      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`

    } else if (this.kindModel == `medpayroll_opening`) {
      this.product_name = `medpayroll`
      this.selectProductName()

      this.productNameDropdownTarget.value = `medpayroll`
      this.productNameInputTarget.dataset.productName = `medpayroll`
      this.productNameInputTarget.innerText = `Folha de Pagamento`

      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = `employee`
      this.productKindInputTarget.dataset.productKind = `employee`
      this.productKindInputTarget.innerText = `Funcionário`
      
    } else if (this.kindModel == `medfiling_opening`) {
      this.product_name = `medfiling`
      this.selectProductName()

      this.productNameDropdownTarget.value = `medfiling`
      this.productNameInputTarget.dataset.productName = `medfiling`
      this.productNameInputTarget.innerText = `Declaração IRPF`

      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`

      this.productPlanDropdownTarget.value = `basic`
      this.productPlanInputTarget.dataset.productPlan = `basic`
      this.productPlanInputTarget.innerText = `Pro`

      this.productAccrualDropdownTarget.value = `2024`
      this.productAccrualInputTarget.dataset.productAccrual = `2024`
      this.productAccrualInputTarget.innerText = `2024`

    } else {

    }


    if (this.kindModel == `medclinic_opening`) {


      this.recurringCheckboxTarget.checked = false
      this.recurringCheckboxTarget.disabled = true
      this.setupCheckboxTarget.checked = true
      this.setupCheckboxTarget.disabled = true

      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`

      this.productIntervalDropdownTarget.value = `unique`
      this.productIntervalInputTarget.dataset.productInterval = `unique`
      this.productIntervalInputTarget.innerText = `Único`

      this.planDropdownTarget.value = `unique`
      this.planInputTarget.dataset.plan = `unique`
      this.planInputTarget.innerText = `Único`


    } else if (this.kindModel == `medclinic_pos_opening`) {

      this.recurringCheckboxTarget.checked = true
      this.recurringCheckboxTarget.disabled = true
      this.setupCheckboxTarget.checked = false
      this.setupCheckboxTarget.disabled = true

      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `pos_opening`
      this.productServiceInputTarget.dataset.productService = `pos_opening`
      this.productServiceInputTarget.innerText = `Pós-Abertura`

      this.productIntervalDropdownTarget.value = `monthly`
      this.productIntervalInputTarget.dataset.productInterval = `monthly`
      this.productIntervalInputTarget.innerText = `Mensal`

      this.planDropdownTarget.value = `monthly`
      this.planInputTarget.dataset.plan = `monthly`
      this.planInputTarget.innerText = `Mensal`

    } else if (this.kindModel == `medfiling_opening`) {
      this.product_name = `medfiling`

      this.recurringCheckboxTarget.checked = false
      // this.recurringCheckboxTarget.disabled = true
      this.setupCheckboxTarget.checked = true
      // this.setupCheckboxTarget.disabled = true

      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`

      this.productIntervalDropdownTarget.value = `unique`
      this.productIntervalInputTarget.dataset.productInterval = `unique`
      this.productIntervalInputTarget.innerText = `Único`

      this.planDropdownTarget.value = `unique`
      this.planInputTarget.dataset.plan = `unique`
      this.planInputTarget.innerText = `Único`
      this.amountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(550)

    } else if (this.kindModel == `medclinic_amendment`) {

      this.recurringCheckboxTarget.checked = false
      this.recurringCheckboxTarget.disabled = true
      this.setupCheckboxTarget.checked = true
      this.setupCheckboxTarget.disabled = true

      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `amendment`
      this.productServiceInputTarget.dataset.productService = `amendment`
      this.productServiceInputTarget.innerText = `Alteração`

      this.productIntervalDropdownTarget.value = `unique`
      this.productIntervalInputTarget.dataset.productInterval = `unique`
      this.productIntervalInputTarget.innerText = `Único`

      this.planDropdownTarget.value = `unique`
      this.planInputTarget.dataset.plan = `unique`
      this.planInputTarget.innerText = `Único`

    } else if (this.kindModel == `medpayroll_opening`) {

      this.recurringCheckboxTarget.checked = true
      this.recurringCheckboxTarget.disabled = true
      this.setupCheckboxTarget.checked = false
      this.setupCheckboxTarget.disabled = true

      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`

      this.productIntervalDropdownTarget.value = `monthly`
      this.productIntervalInputTarget.dataset.productInterval = `monthly`
      this.productIntervalInputTarget.innerText = `Mensal`

      this.planDropdownTarget.value = `monthly`
      this.planInputTarget.dataset.plan = `monthly`
      this.planInputTarget.innerText = `Mensal`

    } else if (this.kindModel == `asdf`) {

    }

    this.setDescription()

  }

  listProductKind() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medfiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbooking`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medclinic`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="home" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">e-Social</li>`
      html += `<li data-product-kind="employee" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Funcionário</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `none`
      this.productKindInputTarget.dataset.productKind = `none`
      this.productKindInputTarget.innerText = `Nenhum`
      html += `<li data-product-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`
    } else if (this.product_name == `medprev`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="pgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PGBL</li>`
      html += `<li data-product-kind="vgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">VGBL</li>`
    } else if (this.product_name == `medloan`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="alimony" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesada</li>`
      html += `<li data-product-kind="debt_refinancing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financiamento</li>`
      html += `<li data-product-kind="prepayment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="finance_outsourcing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
      html += `<li data-product-kind="virtual_office" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Escritório Virtual</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="life_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vida</li>`
      html += `<li data-product-kind="income_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DIT</li>`
      html += `<li data-product-kind="responsability_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">RCP</li>`
      html += `<li data-product-kind="health_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saúde</li>`
    }

    this.productKindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listKind() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medfiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="rectification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Retificação</li>`
    } else if (this.product_name == `medbooking`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medclinic`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="pos_opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pós-Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="amendment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
      // html += `<li data-product-service="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `separate`
      this.productServiceInputTarget.dataset.productService = `separate`
      this.productServiceInputTarget.innerText = `Avulso`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medprev`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medloan`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    }

    this.productServiceListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setProductAccrualList() {
    var html = ``

    html += `<li data-product-accrual="2024" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2024</li>`
    html += `<li data-product-accrual="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>`
    html += `<li data-product-accrual="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>`
    html += `<li data-product-accrual="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>`
    html += `<li data-product-accrual="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>`
    html += `<li data-product-accrual="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>`
    html += `<li data-product-accrual="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2018</li>`

    this.productAccrualListTarget.innerHTML = html
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="basic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>`
    html += `<li data-product-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>`

    this.productPlanListTarget.innerHTML = html
  }

  setSubkindListEv(ev) {
    var kind = ev.currentTarget.dataset.kind

    this.subkindDropdownTarget.value = ``
    this.subkindInputTarget.dataset.subkind = ``
    this.subkindInputTarget.innerText = ``
    this.planDropdownTarget.value = ``
    this.planInputTarget.dataset.plan = ``
    this.planInputTarget.innerText = ``
    this.descriptionInputTarget.value = ``
    this.amountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

    this.setDescription()
    this.setSubkindList(kind)
  }

  setSubkindList(kind) {
    var html = ``
    if (kind == `revenue`) {
      html += `<li data-subkind="revenue_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Prestação de Serviços</li>`
    } else if (kind == `refund`) {
      html += `<li data-subkind="refund_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação de Valores</li>`
    } else if (kind == `discount`) {
      html += `<li data-subkind="commercial_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
              <li data-subkind="retention_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Retenção</li>
              <li data-subkind="error_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Erro Operacional</li>
              <li data-subkind="financial_discount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
    } else if (kind == `reversal`) {
      html += `<li data-subkind="reversal_amount" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDescription" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estorno</li>`
    }

    this.subkindListTarget.innerHTML = html
  }

  showAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--clients--accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).current_client = controller.current_client
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  setDescription() {
    var kind = this.kindInputTarget.innerText
    var subkind = this.subkindInputTarget.innerText
    var plan = this.planInputTarget.innerText

    this.descriptionInputTarget.value = `${kind} | ${subkind} | ${plan}`

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectStatus(ev) {
    var status = ev.currentTarget.dataset.status

    if (status == `canceled`) {
      this.churnDescriptionDivTarget.classList.remove(`d-none`)
    } else {
      this.churnDescriptionDivTarget.classList.add(`d-none`)
    }
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToMonthDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(`01/${ev.target.value}`)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(`01/${ev.target.value}`)
      if (this.checkDate.valid) {
        this.startedAtInputTarget.classList.add("f-normal")
        this.startedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.startedAtInputTarget.classList.add("f-danger")
        this.startedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.startedAtInputTarget.classList.add("f-normal")
      this.startedAtInputTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {


        if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.actionMode == `edit`) {
          if (controller.hasExpiresAtInputTarget && controller.expiresAtInputTarget.value == `` && controller.statusInputTarget.dataset.status != `active`) {
            len += 1
          }
        }

        if (controller.hasNumberInputTarget && controller.numberInputTarget.value == ``) {
          len += 1
        }

        if (controller.productNameInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.productKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.productServiceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.dueDayInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.productIntervalInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.setupCheckboxTarget.checked == false && controller.recurringCheckboxTarget.checked == false) {
          len += 1
        }

        if (!controller.productAccrualDivTarget.classList.contains(`d-none`)) {
          if (controller.hasProductAccrualInputTarget && controller.productAccrualInputTarget.innerText == ``) {
            len += 1
          }
        }

        if (!controller.productPlanDivTarget.classList.contains(`d-none`)) {
          if (controller.hasProductPlanInputTarget && controller.productPlanInputTarget.innerText == ``) {
            len += 1
          }
        }

        if (controller.actionMode == `new`) {

          if (controller.kindInputTarget.innerText == ``) {
            len += 1
          }

          if (controller.subkindInputTarget.innerText == ``) {
            len += 1
          }

          if (controller.planInputTarget.innerText == ``) {
            len += 1
          }

          if (controller.amountInputTarget.value == ``) {
            len += 1
          }

          if (controller.iuguIdInputTarget.value == ``) {
            len += 1
          }

          if (controller.iuguEmailInputTarget.value == ``) {
            len += 1
          }

        }



        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
