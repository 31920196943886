import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "boardCard", "listCard", "filterCol", "resumeCard", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--activities--tickets--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    // this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-2">
                    <div class="col-2 px-2" data-${this.controllerName}-target="filterCol" style="overflow:auto;">
                      <div class="row my-2" data-controller="users--works--activities--tickets--filter">
                        <div class="col-12">
                          <h6 class="mb-0 text-center d-flex" data-users--works--activities--tickets--filter-target="title">${this.loader}</h6>
                          <hr class="my-1">
                          <div class="row" data-users--works--activities--tickets--filter-target="main"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-10 px-2">
                      <div class="row w-100">
                        <div class="col-4">
                          <div class="card bg-primary pointer" data-${this.controllerName}-target="resumeCard" data-feature="resume" data-action="click->${this.controllerName}#toggleTicketEv">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1 mb-0 f-075"><strong>Resumo</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="card pointer" data-${this.controllerName}-target="listCard" data-feature="list" data-action="click->${this.controllerName}#toggleTicketEv">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1 mb-0 f-075"><strong>Lista de Tickets</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="card pointer" data-${this.controllerName}-target="boardCard" data-feature="board" data-action="click->${this.controllerName}#toggleTicketEv">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1 mb-0 f-075"><strong>Quadro de Tickets</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-12 px-0" data-controller="users--works--activities--tickets--board users--works--activities--tickets--list users--works--activities--tickets--resume" data-${this.controllerName}-target="main">
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--activities--tickets--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.filterColTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.getControllerByIdentifier(`users--works--activities--tickets--resume`).gridElement = controller.mainTarget
      if (this.application.tickets.length > 0) {
        controller.getControllerByIdentifier(`users--works--activities--tickets--filter`).doFilterHeader()
        // controller.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardHeader()
      }
    })
  }

  toggleTicketEv(ev) {
    this.feature = ev.currentTarget.dataset.feature
    this.toggleTicket()
  }

  toggleTicket() {
    this.getControllerByIdentifier(`users--works--activities--tickets--filter`).feature = this.feature

    if (this.feature == `board`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardGrid()
      this.resumeCardTarget.classList.remove(`bg-primary`)
      this.listCardTarget.classList.remove(`bg-primary`)
      this.boardCardTarget.classList.add(`bg-primary`)
    } else if (this.feature == `list`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--list`).doListHtml()
      this.resumeCardTarget.classList.remove(`bg-primary`)
      this.listCardTarget.classList.add(`bg-primary`)
      this.boardCardTarget.classList.remove(`bg-primary`)
    } else if (this.feature == `resume`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--resume`).doResumeHtml(`col-4 mt-5 pt-5`)
      this.resumeCardTarget.classList.add(`bg-primary`)
      this.listCardTarget.classList.remove(`bg-primary`)
      this.boardCardTarget.classList.remove(`bg-primary`)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
