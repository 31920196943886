import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--meds--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
  }

  showMed(ev) {
    var controllerShow = this.getControllerByIdentifier("financials--books--dashboards--meds--show")
    var medId = ev.target.closest(".itemRow").dataset.id

    this.application.meds.forEach(element => {
      if (element.id == this.checked_id) {
        controllerShow.current_med = element
      }
    })

    controllerShow.doMedHtml()

  }

  addMed() {
    this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").actionMode = `new`
    this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").step = `company`
    this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").doFormHtml()
  }

  editMed(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.meds.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").current_med = element
        this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").actionMode = `edit`
        this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").step = `company`
        this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").doFormHtml()
      }
    })
  }

  showMed(ev) {
    var medId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var modalName = `financials--books--dashboards--meds--show-modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body">
                        <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${modalName}-target="mainCard">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4 card-title-table-list" data-${modalName}-target="med"></h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${modalName}#close" type="button">
                                <span class="material-icons md-dark">clear</span>
                              </button>
                              <span class="mc-tooltiptext">Fechar</span>
                            </div>
                          </div>
                          <div class="card-body py-0" style="overflow:auto;">
                            <div class="row mt-3" data-${modalName}-target="mainDashboard">
                              <div class="col-7 pr-1" data-${modalName}-target="companies"></div>
                              <div class="col-5 pr-1" data-${modalName}-target="certificates"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--show-modal`).mainCardTarget.style.height = `inherit`
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--show-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--show-modal`).medId = medId
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--show-modal`).open()
    })
  }

  destroyMed(ev) {
    var r = confirm(`Tem certeza que deseja apagar a Med?`)
    if (r) {
      var id = ev.currentTarget.closest(".itemRow").dataset.id
      this.application.meds.forEach(element => {
        if (element.id == id) {
          this.current_med = element
          this.requestDestroy()
        }
      })
    }
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, med: {} }

    this.send_data.med.id = this.current_med.id
    this.send_data.med.active = false
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_meds`

    var url = "/financials/config/meds/destroy"
    var method = "DELETE"
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var med = response.data.cln

          controller.application.meds.forEach((element, i) => {
            if (element.id == med.id) {
              controller.application.meds.splice(controller.application.meds.indexOf(element), 1)
            }
          })

          controller.doDataTable()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions.financial_meds.can_create) {
      var addBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addMedBtn" data-action="click->${this.controllerName}#addMed" type="button">
                            <span class="material-icons md-dark">add</span>
                          </button>
                          <span class="mc-tooltiptext">Adicionar MedCapital</span>
                        </span>`
    } else {
      var addBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">MedCapital</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    ${addBtnHtml}
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-40 align-middle">Razão Social</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Apelido</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">CNPJ</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--meds--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(7, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_meds = this.application.meds

    if (this.sort.mode == `asc`) {
      var meds = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_meds, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var meds = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_meds, this.sort.field)
    }

    if (meds.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(meds.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (meds[x] !== undefined) {
            internPage[internPage.length] = meds[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: meds.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há MedCapital</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.medTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  medTablePartial(element, length) {

    if (this.application.permissions.financial_meds.can_update && element.has_tax_regimes) {
      var edit = ``
      var edit = `<button data-action="click->${this.controllerName}#editMed" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = `<button data-action="click->${this.controllerName}#editMed" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    }
    // var edit = ``

    if (this.application.permissions.financial_meds.can_delete && this.application.current_user.super_admin) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyMed" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else {
      var destroy = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showMed">${element.company_trade_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showMed">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.cnpj}" data-action="click->app--helpers--copy#copy">${element.cnpj_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${destroy}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
