import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "dateYearDropdown", "dateYearDropdownBtn", "dateYearInput", "dateYearList",
                    "taxRegimeDropdown", "taxRegimeDropdownBtn", "taxRegimeInput", "taxRegimeList", "specialTaxRegime", "specialTaxRegimeDropdown",
                    "specialTaxRegimeDropdownBtn", "specialTaxRegimeInput", "specialTaxRegimeList", "", "aliquotCheckbox", "fixedCheckbox",
                    "issAliquotDiv", "issFixedDiv", "issAliquot", "issFixed", "legalNatureDropdown", "legalNatureDropdownBtn", "saveBtn",

                    "dateMonthDropdown", "dateMonthDropdownBtn", "dateMonthInput", "dateMonthList", "legalNatureInput", "legalNatureList",
                    "subRegimeDropdown", "subRegimeDropdownBtn", "subRegimeInput", "subRegimeList", "accrualDate",

                    "totalInvoiceRow", "lastTwelveMonthsInput", "accrualMonthInput", "openCheckbox"]


  connect() {
    this.controllerName = `financials--books--apurations--regimes--save`
    this.numbersController = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).close()
  }

  saveRegime() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, regime: {}, tracker: { log: true } }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.regime.id = this.current_regime.id
    this.send_data.regime.med_id = this.current_med.id
    this.send_data.regime.tax_regime = this.taxRegimeInputTarget.dataset.regime
    this.send_data.regime.sub_tax_regime = this.subRegimeInputTarget.dataset.subRegime
    this.send_data.regime.special_tax_regime = this.specialTaxRegimeInputTarget.dataset.specialRegime
    this.send_data.regime.legal_nature = this.legalNatureInputTarget.dataset.legalNature
    this.send_data.regime.year = this.dateYearInputTarget.dataset.year
    this.send_data.regime.month = this.dateMonthInputTarget.dataset.month
    this.send_data.regime.last_twelve_months_sum = this.numbersController.fromCurrencyToNumber(this.lastTwelveMonthsInputTarget.value)
    this.send_data.regime.accrual_month = this.numbersController.fromCurrencyToNumber(this.accrualMonthInputTarget.value)
    this.send_data.regime.open = this.openCheckboxTarget.checked

    if (this.aliquotCheckboxTarget.checked) {
      this.send_data.regime.iss_type = `aliquot`
      this.send_data.regime.iss_rate = this.numbersController.fromPercentToNumber(this.issAliquotTarget.value)
      this.send_data.regime.iss_amount = 0
    } else if (this.fixedCheckboxTarget.checked) {
      this.send_data.regime.iss_type = `fixed`
      this.send_data.regime.iss_rate = 0
      this.send_data.regime.iss_amount = this.numbersController.fromCurrencyToNumber(this.issFixedTarget.value)
    }

    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `financial_apuration_regimes`
    this.send_data.tracker.action = this.actionMode
    this.send_data.tracker.observations = `Regime Tributário da competência ${this.current_regime.accrual_date_pretty} da Med ${this.current_med.name}, sendo ISS Fixo: ${this.send_data.regime.iss_amount}, ISS Alíquota: ${this.send_data.regime.iss_rate}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/financials/books/apurations/regimes/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/financials/books/apurations/regimes/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()

        var regime = response.data.cln
        this.application.regimes.forEach(element => {
          if (regime.id == element.id) {
            controller.application.regimes.splice(controller.application.regimes.indexOf(element), 1, regime)
          }
        })

        // var regimes = response.data.cln
        // regimes.forEach(element => {
        //   this.application.regimes.forEach(regime => {
        //     if (regime.id == element.id) {
        //       controller.application.regimes.splice(controller.application.regimes.indexOf(element), 1, element)
        //     }
        //   })
        // })
        
        controller.cancelSave()
        controller.getControllerByIdentifier("financials--books--apurations--regimes--index").doDataTable()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRegime" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Ano</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateYearDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateYearDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateYearInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dateYearList" data-app--helpers--search-target="searchList">
                                  <li data-year="2021" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>
                                  <li data-year="2020" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>
                                  <li data-year="2019" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Mês</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateMonthDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateMonthDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateMonthInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dateMonthList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="accrualForm">Competência</label>
                            <input aria-describedby="accrualFormHelp" class="form-control form-valid-control" id="accrualForm" data-${this.controllerName}-target="accrualDate" placeholder="Competência" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Regime Tributário</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="taxRegimeDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="taxRegimeDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="taxRegimeInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="taxRegimeList" data-app--helpers--search-target="searchList">
                                  <li data-regime="simple_national" data-action="click->${this.controllerName}#chooseSubRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Simples Nacional</li>
                                  <li data-regime="presumed_profit" data-action="click->${this.controllerName}#chooseSubRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Lucro Presumido</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sub-regime</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subRegimeDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subRegimeDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subRegimeInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="subRegimeList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Natureza Legal</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="legalNatureDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="legalNatureDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="legalNatureInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="legalNatureList" data-app--helpers--search-target="searchList">
                                  <li data-legal-nature="pure_simple_society" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Simples Pura</li>
                                  <li data-legal-nature="limited_simple_society" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Simples Limitada</li>
                                  <li data-legal-nature="limited_business_company" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Empresaria Limitada</li>
                                  <li data-legal-nature="eireli_natureza_empresaria" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eireli Natureza Empresaria</li>
                                  <li data-legal-nature="eireli_natureza_simples" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eireli Natureza Simples</li>
                                  <li data-legal-nature="entrepreneur" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Empresário Individual</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Regime Especial</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="specialTaxRegimeDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="specialTaxRegimeDropdownBtn">
                                <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="specialTaxRegimeInput"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="specialTaxRegimeList" data-app--helpers--search-target="searchList">
                                  <li data-special-regime="automatic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Automatico</li>
                                  <li data-special-regime="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
                                  <li data-special-regime="professional_society" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade De Profissionais</li>
                                  <li data-special-regime="microenterprise_individual" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempreendedor Individual</li>
                                  <li data-special-regime="microenterprise_small_company" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempresario Empresa Pequeno Porte</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2 d-flex align-items-center" style="height:60px;">
                      <div class="col-6 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 mc-tooltip d-flex align-items center">
                          <input type="checkbox" class="custom-control-input" id="issAliquotCheckbox" data-kind="aliquot" data-${this.controllerName}-target="aliquotCheckbox" data-action="click->${this.controllerName}#checkIssType">
                          <label class="custom-control-label f-065 pointer" for="issAliquotCheckbox">Alíquota</label>
                          <span class="mc-tooltiptext">ISS por Alíquota?</span>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 mc-tooltip d-flex align-items center">
                          <input type="checkbox" class="custom-control-input" id="issFixedCheckbox" data-kind="fixed" data-${this.controllerName}-target="fixedCheckbox" data-action="click->${this.controllerName}#checkIssType">
                          <label class="custom-control-label f-065 pointer" for="issFixedCheckbox">Fixo</label>
                          <span class="mc-tooltiptext">ISS Fixo?</span>
                        </div>
                      </div>

                      <div class="col-6 d-none" data-${this.controllerName}-target="issAliquotDiv">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="issAliquotForm">ISS Porcentagem</label>
                            <input aria-describedby="issAliquotFormHelp" class="form-control form-valid-control" id="issAliquotForm" data-${this.controllerName}-target="issAliquot" data-action="blur->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask" placeholder="ISS Porcentagem" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-none" data-${this.controllerName}-target="issFixedDiv">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="issFixedForm">ISS Fixo</label>
                            <input aria-describedby="issFixedFormHelp" class="form-control form-valid-control" id="issFixedForm" data-${this.controllerName}-target="issFixed" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="ISS Fixo" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="lastTwelveMonthsForm">Soma dos Últimos 12 meses</label>
                            <input aria-describedby="lastTwelveMonthsFormHelp" class="form-control form-valid-control" id="lastTwelveMonthsForm" data-${this.controllerName}-target="lastTwelveMonthsInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Soma..." type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="accrualMonthForm">Soma do mês atual</label>
                            <input aria-describedby="accrualMonthFormHelp" class="form-control form-valid-control" id="accrualMonthForm" data-${this.controllerName}-target="accrualMonthInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Soma..." type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 mc-tooltip d-flex align-items center">
                          <input type="checkbox" class="custom-control-input" id="openCheckbox" data-${this.controllerName}-target="openCheckbox">
                          <label class="custom-control-label f-065 pointer" for="openCheckbox">Automaticação</label>
                          <span class="mc-tooltiptext">Aberto para Automaticação?</span>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.lastTwelveMonthsInputTarget.disabled = true
      controller.accrualDateTarget.disabled = true
      
      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Regime Tributário`
        controller.issAliquotTarget.value = controller.numbersController.percentMask(0)
        controller.issFixedTarget.value = controller.numbersController.currencyMask(0)
        controller.lastTwelveMonthsInputTarget.value = controller.numbersController.currencyMask(0)
        controller.accrualMonthInputTarget.value = controller.numbersController.currencyMask(0)
        controller.openCheckboxTarget.checked = true
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Regime Tributário | ${controller.current_regime.tax_regime_pretty} | ${controller.current_med.name}`
        controller.taxRegimeDropdownTarget.value = controller.current_regime.tax_regime
        controller.taxRegimeInputTarget.dataset.regime = controller.current_regime.tax_regime
        controller.taxRegimeInputTarget.innerText = controller.current_regime.tax_regime_pretty

        controller.openCheckboxTarget.checked = controller.current_regime.open
        controller.accrualDateTarget.value = controller.current_regime.accrual_date_pretty

        controller.dateYearDropdownTarget.value = controller.current_regime.year
        controller.dateYearInputTarget.dataset.year = controller.current_regime.year
        controller.dateYearInputTarget.innerText = controller.current_regime.year_pretty

        controller.dateMonthDropdownTarget.value = controller.current_regime.month
        controller.dateMonthInputTarget.dataset.month = controller.current_regime.month
        controller.dateMonthInputTarget.innerText = controller.current_regime.month_pretty

        controller.dateYearDropdownBtnTarget.disabled = true
        controller.dateMonthDropdownBtnTarget.disabled = true

        if (controller.application.current_date.open) {
          controller.taxRegimeDropdownBtnTarget.disabled = false
          controller.subRegimeDropdownBtnTarget.disabled = false
          controller.specialTaxRegimeDropdownBtnTarget.disabled = false
          controller.legalNatureDropdownBtnTarget.disabled = false
        } else {
          controller.taxRegimeDropdownBtnTarget.disabled = true
          controller.subRegimeDropdownBtnTarget.disabled = true
          controller.specialTaxRegimeDropdownBtnTarget.disabled = true
          controller.legalNatureDropdownBtnTarget.disabled = true
        }

        controller.lastTwelveMonthsInputTarget.value = controller.numbersController.currencyOptionMask(controller.current_regime.last_twelve_months_sum)
        controller.accrualMonthInputTarget.value = controller.numbersController.currencyOptionMask(controller.current_regime.accrual_month)



        controller.setSubRegime(controller.current_regime.tax_regime)

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  listMonths(ev) {
    var date = new Date();
    var currentYear = date.getFullYear();
    var accrualMonth = date.getMonth();
    var selectYear = ev.target.dataset.year

    this.dateMonthDropdownTarget.value = ``
    this.dateMonthInputTarget.dataset.month = ``
    this.dateMonthInputTarget.innerText = ``

    var all_months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    var listHtml = ``
    if (currentYear == selectYear) {
      for (let index = 0; index <= accrualMonth; index++) {
        listHtml += `<li data-month="${index + 1}" data-app--helpers--selector-target="select" data-action="click->${this.controllerName}#setAccrualDate click->app--helpers--selector#select click->operations--products--bookings--settings--dates#refreshSaveBtn" class="li-selector dark">${all_months[index]}</li>`
      }
    } else {
      for (let index = 0; index < 12; index++) {
        listHtml += `<li data-month="${index + 1}" data-app--helpers--selector-target="select" data-action="click->${this.controllerName}#setAccrualDate click->app--helpers--selector#select click->operations--products--bookings--settings--dates#refreshSaveBtn" class="li-selector dark">${all_months[index]}</li>`
      }
    }

    this.dateMonthListTarget.innerHTML = listHtml
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setAccrualDate() {
    // var controller = this
    // setTimeout(() => {
    //   controller.accrualDateTarget.value = `${controller.dateMonthInputTarget.innerText}-${controller.dateYearInputTarget.innerText}`
    //   controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    // }, 100);
  }

  chooseSubRegime(ev) {
    var regime = ev.currentTarget.dataset.regime

    this.setSubRegime(regime)
  }

  setSubRegime(regime) {
    // var html1 = `<li data-special-regime="automatic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Automatico</li>
    //              <li data-special-regime="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
    //              <li data-special-regime="professional_society" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade De Profissionais</li>
    //              <li data-special-regime="microenterprise_individual" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempreendedor Individual</li>
    //              <li data-special-regime="microenterprise_small_company" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempresario Empresa Pequeno Porte</li>`

    this.regime = regime
    var listHtml = ``
    var specialHtml = ``

    if (this.actionMode == `new`) {
      this.subRegimeDropdownTarget.value = ``
      this.subRegimeInputTarget.dataset.month = ``
      this.subRegimeInputTarget.innerText = ``

      this.legalNatureInputTarget.dataset.specialRegime = ``
      this.legalNatureDropdownTarget.value = ``
      this.legalNatureInputTarget.innerText = ``
    } else if (this.actionMode == `edit`) {
      this.subRegimeDropdownTarget.value = this.current_regime.sub_tax_regime
      this.subRegimeInputTarget.dataset.subRegime = this.current_regime.sub_tax_regime
      this.subRegimeInputTarget.innerText = this.current_regime.sub_tax_regime_pretty

      this.legalNatureDropdownTarget.value = this.current_regime.legal_nature
      this.legalNatureInputTarget.dataset.legalNature = this.current_regime.legal_nature
      this.legalNatureInputTarget.innerText = this.current_regime.legal_nature_pretty
    }

    if (this.regime == `simple_national`) {
      listHtml += `<li data-sub-regime="attachment_3" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anexo 3</li>
                   <li data-sub-regime="attachment_5" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anexo 5</li>`

      this.setIssType(`aliquot`)
      this.aliquotCheckboxTarget.disabled = true
      this.fixedCheckboxTarget.disabled = true
      this.issAliquotTarget.disabled = true

      this.issAliquotTarget.value = this.numbersController.percentOptionMask(this.current_regime.iss_rate)

      specialHtml += `<li data-special-regime="microenterprise_small_company" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempresario Empresa Pequeno Porte</li>`
      this.specialTaxRegimeInputTarget.dataset.specialRegime = `microenterprise_small_company`
      this.specialTaxRegimeDropdownTarget.value = `microenterprise_small_company`
      this.specialTaxRegimeInputTarget.innerText = `Microempresario Empresa Pequeno Porte`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true


    } else if (this.regime == `presumed_profit`) {
      listHtml += `<li data-sub-regime="normal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Presunção Normal</li>
                   <li data-sub-regime="hospital_match" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Equiparação Hospitalar</li>`

      // this.aliquotCheckboxTarget.disabled = false
      // this.fixedCheckboxTarget.disabled = false
      // this.issAliquotTarget.disabled = false

      specialHtml += `<li data-kind="aliquot" data-special-regime="none" data-action="click->${this.controllerName}#checkIssType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
                      <li data-kind="fixed" data-special-regime="professional_society" data-action="click->${this.controllerName}#checkIssType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade De Profissionais</li>`


      if (this.actionMode == `new`) {
        this.setIssType(`none`)
        this.specialTaxRegimeInputTarget.dataset.specialRegime = ``
        this.specialTaxRegimeDropdownTarget.value = ``
        this.specialTaxRegimeInputTarget.innerText = ``
        this.specialTaxRegimeDropdownBtnTarget.disabled = false
      } else {
        this.setIssType(this.current_regime.iss_type)
        this.specialTaxRegimeDropdownTarget.value = this.current_regime.special_tax_regime
        this.specialTaxRegimeInputTarget.dataset.specialRegime = this.current_regime.special_tax_regime
        this.specialTaxRegimeInputTarget.innerText = this.current_regime.special_tax_regime_pretty
        this.specialTaxRegimeDropdownBtnTarget.disabled = true

        if (this.application.current_date.open) {
          this.aliquotCheckboxTarget.disabled = false
          this.fixedCheckboxTarget.disabled = false
          this.issAliquotTarget.disabled = false
        } else {
          this.aliquotCheckboxTarget.disabled = true
          this.fixedCheckboxTarget.disabled = true
          this.issAliquotTarget.disabled = true
        }

        if (this.current_regime.iss_type == `aliquot`) {
          this.issAliquotTarget.value = this.numbersController.percentOptionMask(this.current_regime.iss_rate)
          this.aliquotCheckboxTarget.checked = true
          this.fixedCheckboxTarget.checked = false
          this.issFixedDivTarget.classList.add("d-none")
          this.issAliquotDivTarget.classList.remove("d-none")
        } else if (this.current_regime.iss_type == `fixed`) {
          this.issFixedTarget.value = this.numbersController.currencyOptionMask(this.current_regime.iss_amount)
          this.aliquotCheckboxTarget.checked = false
          this.fixedCheckboxTarget.checked = true
          this.issFixedDivTarget.classList.remove("d-none")
          this.issAliquotDivTarget.classList.add("d-none")
        }
      }
    }

    this.subRegimeListTarget.innerHTML = listHtml
    this.specialTaxRegimeListTarget.innerHTML = specialHtml
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setSpecialRegime(ev) {

    var legalNature = ev.currentTarget.dataset.legalNature

    this.regime = this.taxRegimeInputTarget.dataset.regime

    if (this.regime == `presumed_profit` && (legalNature == `limited_business_company` || legalNature == `eireli_natureza_empresaria` || legalNature == `entrepreneur`)) {
      this.setIssType(`aliquot`)
      // this.aliquotCheckboxTarget.disabled = true
      // this.fixedCheckboxTarget.disabled = true
      this.specialTaxRegimeInputTarget.dataset.specialRegime = `none`
      this.specialTaxRegimeDropdownTarget.value = `none`
      this.specialTaxRegimeInputTarget.innerText = `Nenhum`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true
      this.issAliquotTarget.disabled = false
    } else if (this.regime == `presumed_profit`) {
      this.setIssType(`none`)
      // this.aliquotCheckboxTarget.disabled = false
      // this.fixedCheckboxTarget.disabled = false
      this.specialTaxRegimeInputTarget.dataset.specialRegime = ``
      this.specialTaxRegimeDropdownTarget.value = ``
      this.specialTaxRegimeInputTarget.innerText = ``
      this.specialTaxRegimeDropdownBtnTarget.disabled = false
    } else if (this.regime == `simple_national`) {
      this.setIssType(`aliquot`)
      this.aliquotCheckboxTarget.disabled = true
      this.fixedCheckboxTarget.disabled = true
      this.issAliquotTarget.disabled = true

      this.specialTaxRegimeInputTarget.dataset.specialRegime = `microenterprise_small_company`
      this.specialTaxRegimeDropdownTarget.value = `microenterprise_small_company`
      this.specialTaxRegimeInputTarget.innerText = `Microempresario Empresa Pequeno Porte`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  checkIssType(ev) {
    var kind = ev.target.dataset.kind
    this.setIssType(kind)
  }

  setIssType(kind) {
    if (kind == "aliquot") {
      this.aliquotCheckboxTarget.checked = true
      this.fixedCheckboxTarget.checked = false
      this.issFixedDivTarget.classList.add("d-none")
      this.issAliquotDivTarget.classList.remove("d-none")
      this.issAliquotTarget.value = `0,00 %`
      this.issAliquotTarget.focus()
    } else if (kind == "fixed") {
      this.aliquotCheckboxTarget.checked = false
      this.fixedCheckboxTarget.checked = true
      this.issAliquotDivTarget.classList.add("d-none")
      this.issFixedDivTarget.classList.remove("d-none")
      this.issFixedTarget.value = `R$ 0,00`
      this.issFixedTarget.focus()
    } else if (kind == "none") {
      this.aliquotCheckboxTarget.checked = false
      this.fixedCheckboxTarget.checked = false
      this.issAliquotDivTarget.classList.add("d-none")
      this.issFixedDivTarget.classList.add("d-none")
    }
  }

  percentMask(ev) {
    this.numbersController.changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.numbersController.changeNumberToCurrency(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.numbersController.fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        if (this.cpfCheckboxTarget.checked == true) {
          this.birthdateInputTarget.classList.add("f-normal")
          this.birthdateInputTarget.classList.remove("f-danger")
        } else if (this.cnpjCheckboxTarget.checked == true) {
          this.openedAtTarget.classList.add("f-normal")
          this.openedAtTarget.classList.remove("f-danger")
        }
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        if (this.cpfCheckboxTarget.checked == true) {
          this.birthdateInputTarget.classList.add("f-danger")
          this.birthdateInputTarget.classList.remove("f-normal")
        } else if (this.cnpjCheckboxTarget.checked == true) {
          this.openedAtTarget.classList.add("f-danger")
          this.openedAtTarget.classList.remove("f-normal")
        }
      }
    } else {
      if (this.cpfCheckboxTarget.checked == true) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else if (this.cnpjCheckboxTarget.checked == true) {
        this.openedAtTarget.classList.add("f-normal")
        this.openedAtTarget.classList.remove("f-danger")
      }
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.taxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.subRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.specialTaxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.legalNatureInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateYearInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateMonthInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked == false && controller.fixedCheckboxTarget.checked == false) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked && controller.issAliquotTarget.value == ``) {
        len += 1
      }

      if (controller.fixedCheckboxTarget.checked && controller.issFixedTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
