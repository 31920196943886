import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "body", "bottom", "top", "formCard", "formTitle", "formCardBody",
                    "accrualDate", "sameDayCheckbox", "dueDate", "saveBtn",
                    "chartAccountModal", "chartInput", "borrowerCol", "medModal",
                    "channelModal", "channelInput", "borrowerInput", "descriptionInput",
                    "recurringCheckbox", "variableCheckbox", "ticketCheckbox", "statusList", "contractStatusForm",
                    "costModal", "costInput", "projectModal", "projectInput",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList",
                    "amountInput", "borrowerModal", "medInput", "finishedAt",
                    "amountInput", "allowanceModal", "providerModal", "asdf",
                    "cardDiv", "totalAmountInput", "installmentInput", "amountSplitInput", "bankLineDiv",
                    "bankLineInput", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKindDropdown",
                    "pixKindDropdownBtn", "pixKindInput", "pixKindList", "pixKeyInput"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--transaction-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.finishLoadPage()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  cancelSave() {
    this.close()
  }

  savePayable() {
    this.saveBtnTarget.disabled = true
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()
    this.send_data = { current_user: {}, transaction: {}, tracker: { manual: true } }

    this.send_data.transaction.date_id = this.current_date.id

    var fromChart = {}
    var toChart = {}

    this.application.chart_accounts.forEach(chart => {
      if (chart.id == this.current_remuneration.chart_id) {
        fromChart = chart
      }
      if (chart.id == this.chartInputTarget.dataset.filter) {
        toChart = chart
      }
    })

    var amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value))
    var fromAmount = 0
    var toAmount = 0

    if (fromChart.master == `liability` || fromChart.master == `revenues` || fromChart.master == `result`) {
      fromAmount = Number(amount)
    } else {
      fromAmount = -Number(amount)
    }

    if (toChart.master == `asset` || toChart.master == `expenses` || toChart.master == `taxes`) {
      toAmount = Number(amount)
    } else {
      toAmount = -Number(amount)
    }

    var transactionKind = ``
    transactionKind = `balance`

    this.send_data.transaction.date_id = this.current_date.id
    // this.send_data.transaction.med_id = this.current_med.id
    this.send_data.transaction.med_id = this.medInputTarget.dataset.medId
    this.send_data.transaction.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.transaction.channel_name = this.channelInputTarget.value
    this.send_data.transaction.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.transaction.description = this.descriptionInputTarget.value.trim()
    this.send_data.transaction.method = this.methodInputTarget.dataset.method
    this.send_data.transaction.provider_id = this.current_contract.provider_id
    this.send_data.transaction.provider_name = this.current_contract.provider_name
    this.send_data.transaction.from_id = fromChart.id
    this.send_data.transaction.from_master_name = fromChart.master_name
    this.send_data.transaction.from_group = fromChart.group
    this.send_data.transaction.from_master_group = fromChart.master_group
    this.send_data.transaction.from_master = fromChart.master
    this.send_data.transaction.to_id = toChart.id
    this.send_data.transaction.to_master_name = toChart.master_name
    this.send_data.transaction.to_group = toChart.group
    this.send_data.transaction.to_master_group = toChart.master_group
    this.send_data.transaction.to_master = toChart.master
    this.send_data.transaction.amount = amount
    this.send_data.transaction.from_amount = fromAmount
    this.send_data.transaction.to_amount = toAmount
    this.send_data.transaction.kind = transactionKind
    this.send_data.transaction.source = `direct`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.send_data.tracker.record_id = this.current_date.id
    this.send_data.tracker.record_type = `financials_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `Lançamento Transação Ref. ${this.descriptionInputTarget.value.trim()} | ${this.amountInputTarget.value} | ${toChart.chart_name} | ${this.channelInputTarget.innerText}`

    this.requestSave()
  }

  requestSave() {
    var url = "/financials/books/statements/transactions/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var transaction = response.data.cln

          controller.updateRemuneration(transaction)
        } else {
          controller.saveBtnTarget.disabled = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.cancelSave()
      })
      .catch(error => {
        controller.saveBtnTarget.disabled = false
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateRemuneration(transaction) {
    var send_data = { current_user: {}, remuneration: {}, tracker: { manual: true } }

    send_data.tracker.action = `updated`
    send_data.tracker.observations = `Adicionou Transação do item ${this.current_remuneration.allowance} da Remuneração ${this.current_contract.account_name}`
    send_data.tracker.record_id = this.current_contract.account_id
    send_data.tracker.record_type = `account_entities`
    send_data.tracker.obj_id = this.current_remuneration.id
    send_data.tracker.obj_type = `financial_payroll_remunerations`

    send_data.remuneration.id = this.current_remuneration.id
    send_data.remuneration.obj_id = transaction.id
    send_data.remuneration.obj_type = `financial_statement_transactions`
    send_data.remuneration.status = `done`

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").send_data = send_data
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").actionMode = `edit`
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").requestSave()
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accrualDateForm">Lançamento</label>
                                <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="chartInput" data-${this.controllerName}-target="chartAccountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Destino</label>
                                <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" type="text" placeholder="Destino" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        

                        <div class="row my-2 mt-3 d-flex align-items-center">

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-7">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal Pagamento</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal Pagamento" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.formTitleTarget.innerText = `Nova Transação`

      var date = new Date(controller.current_date.year, controller.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(controller.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      
      controller.accrualDateTarget.value = controller.current_remuneration.accrual_date_pretty
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_remuneration.amount)

      controller.application.meds.forEach(element => {
        if (element.id == controller.current_contract.med_id) {
          controller.current_contract_med = element
          controller.setMainChannel(element)
        }
      })

      controller.medModalTarget.disabled = true
      controller.medInputTarget.dataset.medId = controller.current_contract_med.id
      controller.medInputTarget.value = controller.current_contract_med.name

      controller.descriptionInputTarget.value = `${controller.current_remuneration.kind_input_pretty} ${controller.current_remuneration.allowance} - @${controller.current_remuneration.account_name}`

      var method = `provision`
      var methodPretty = `Provisão`
      controller.methodDropdownTarget.value = method
      controller.methodInputTarget.dataset.method = method
      controller.methodInputTarget.innerText = methodPretty

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setMainChannel(med) {
    var mainChannel = {}
    this.application.channels.forEach(channel => {
      if (channel.med_id == med.id && channel.subkind == `main`) {
        mainChannel = channel
      }
    })

    this.channelInputTarget.value = mainChannel.name
    this.channelInputTarget.dataset.channelId = mainChannel.id
    this.setMethodList(mainChannel)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    methodList += `<li data-method="provision" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`
    this.methodListTarget.innerHTML = methodList
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = true
      controller.getControllerByIdentifier(modalName).permission = `financial_payroll_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = []
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payroll_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  finishLoadPage() {
    this.doFormHtml()

    var response = { type: `success`, message: `Informações carregadas com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  saveOpportunity(allowance) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.opportunity_id
    this.send_data.opportunity.allowance = allowance

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    var url = "/commercial/sales/opportunities/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln

          if (controller.dashboardController.context.identifier == `commercial--sales--opportunities--entities--show`) {
            controller.application.opportunity = opportunity
            controller.dashboardController.finishLoadPage()
          } else if (controller.dashboardController.context.identifier == `commercial--sales--opportunities--index--list`) {
            controller.application.all_opportunities.forEach((element, i) => {
              if (element.id == opportunity.id) {
                controller.application.all_opportunities.splice(controller.application.all_opportunities.indexOf(element), 1, opportunity)
              }
            })
            controller.dashboardController.doDataTable()
          }

          controller.close()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkChartAccount(chartKind) {
    // var chartArray = [
    //   "staff_payables",
    //   "labor_obligations_payables",
    //   "benefits_payables",
    // ]

    if (this.current_contract.role == `technology`) {
      var chartArray = [
        "staff_technology",
      ]
    } else if (this.current_contract.role == `accounting`) {
      var chartArray = [
        "staff_accounting",
      ]
    } else if (this.current_contract.role == `investment`) {
      var chartArray = [
        "staff_investment",
      ]
    } else if (this.current_contract.role == `protection`) {
      var chartArray = [
        "staff_protection",
      ]
    } else if (this.current_contract.role == `legal`) {
      var chartArray = [
        "staff_legal",
      ]
    } else if (this.current_contract.role == `customer_success`) {
      var chartArray = [
        "staff_customer_success",
      ]
    } else if (this.current_contract.role == `performance`) {
      var chartArray = [
        "staff_performance",
      ]
    } else if (this.current_contract.role == `administrative`) {
      var chartArray = [
        "staff_administrative",
      ]
    } else if (this.current_contract.role == `board`) {
      var chartArray = [
        "staff_board",
      ]
    } else if (this.current_contract.role == `general`) {
      var chartArray = [
        "staff_general",
      ]
    } else if (this.current_contract.role == `biling`) {
      var chartArray = [
        "staff_biling",
      ]
    }

    return chartArray.includes(chartKind)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {

        if (controller.hasAccrualDateInputTarget && controller.accrualDateInputTarget.value == ``) {
          len += 1
        }

        if (controller.channelInputTarget.value == ``) {
          len += 1
        }

        if (controller.chartInputTarget.value == ``) {
          len += 1
        }

        if (controller.methodInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}