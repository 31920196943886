import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--platform`
  }

  doHtml() {
    this.application.site.platform_image = `https://medcapital-site-main.s3.sa-east-1.amazonaws.com/apuration_print.png`
    var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(this.application.site.platform_link_cta)
    var aligItem = ``
    var classLeft = ``
    var classRight = ``
    var imgWidth = ``
    var btnMargin = ``
    var leftCol = ``
    var rightCol = ``

    if (this.application.size == `large`) {
      classLeft = `col-7 d-flex justify-content-center align-items-center`
      classRight = `col-5 d-flex align-items-center`
      imgWidth = `550`
      aligItem = `align-items-center`

      leftCol = `<div class="${classLeft}">
                  <img src="${this.application.site.platform_image}" width="550" alt="">
                </div>`
      rightCol = `<div class="${classRight}">
                    <div class="columns">
                      <h1 class="ws-title-action">${this.application.site.platform_title}</h1>
                      <p class="ws-text-action">
                        ${this.application.site.platform_description}
                      </p>
                      <a class="ws-btn-action mt-5 pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.platform_cta}</a>
                    </div>
                  </div>`
    } else if (this.application.size == `medium` || this.application.size == `small`) {
      classLeft = `col-12 d-flex align-items-center text-center`
      classRight = `col-12 d-flex justify-content-center align-items-center mt-5`
      imgWidth = `400`
      btnMargin = `mx-auto`
      aligItem = `align-items-center`

      leftCol = `<div class="${classLeft}">
                    <div class="columns">
                      <h1 class="ws-title-action">${this.application.site.platform_title}</h1>
                      <p class="ws-text-action">
                        ${this.application.site.platform_description}
                      </p>
                      <a class="ws-btn-action mt-5 pointer ${btnMargin}" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.platform_cta}</a>
                    </div>
                  </div>`
      rightCol = `<div class="${classRight}">
                    <img src="${this.application.site.platform_image}" width="550" alt="">
                  </div>`
    }

    var html = `<section class="ws-section-platform d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      ${leftCol}
                      ${rightCol}
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  setLink(originalLink) {
    var link = ``

    if (originalLink.includes("https://")) {
      link = originalLink
    } else {
      link = `https://${originalLink}`
    }

    return link
  }

  doBackupHtml() {
    // <img src="https://medcapital-site-main.s3.sa-east-1.amazonaws.com/invoice_print.png" width="550" alt="">
    var html = `<section class="ws-section-platform d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center">
                        
                        <img src="https://medcapital-site-main.s3.sa-east-1.amazonaws.com/apuration_print.png" width="550" alt="">
                      </div>
                      <div class="d-flex align-items-center col-lg-5 col-sm-12">
                        <div class="columns">
                          <h1 class="ws-title-action">Plataforma feita para facilitar a vida financeira do médico</h1>
                          <p class="ws-text-action">
                            Nosso time facilita o seu dia-a-dia e você tem o controle e as informações da sua PJ Médica,
                            Livro-Caixa, Imposto de Renda e muito mais em tempo real.
                          </p>
                          <a class="ws-btn-action mt-5" href="">Pedir uma Demo</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
