import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "printCardElement", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--apurations--dashboard`
  }

  doApurationsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--financials--apurations--list"></div>
                  <div class="col-10 pr-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--financials--apurations--principal operations--products--clinics--financials--apurations--partner"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--show--submenu`).dashboardToggleBtn(`APURAÇÕES`)
      controller.getControllerByIdentifier(`operations--products--clinics--financials--apurations--list`).permission = `medclinic_management_main_apurations`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--apurations--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
