import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenuBtn", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--dashboard--meds`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  changeMed(ev) {
    var medId = Number(ev.currentTarget.dataset.medId)
    var kind = ev.currentTarget.dataset.kind
    this.setMed(kind, medId)
    // this.toggleBtn(medId)
  }

  setMed(kind, id) {
    if (kind == `group`) {
      this.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }
    } else if (kind == `company`) {
      this.application.meds.forEach(element => {
        if (element.id == id) {
          this.application.current_med = element
        }
      })
    }

    this.runDashboardsHtml()
  }

  setCurrents() {
    // this.application.regimes.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     this.application.current_regime = element
    //   }
    // })

    // this.application.main_apurations.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     this.application.current_main_apuration = element
    //   }
    // })
  }

  runDashboardsHtml() {
    this.runDashboardHtml()
    this.runPayableHtml()
    this.runBillHtml()
    this.runLoanHtml()
    this.runAnnualHtml()
    this.runLiquidationsHtml()
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("financials--books--payables--dashboard--main").hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--dashboard--main").doMainDashboard()
    }
  }

  runPayableHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--entities--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard()
    }
  }

  runBillHtml() {
    if (this.getControllerByIdentifier(`financials--books--cards--bills--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--cards--bills--dashboard").doBillsDashboard()
    }
  }

  runAnnualHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--annual--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--annual--dashboard").doPayablesDashboard()
    }
  }

  runLoanHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--loans--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--loans--dashboard").doLoansDashboard()
    }
  }

  runLiquidationsHtml() {
    if (this.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--receivables--liquidations--dashboard").doLiquidationsDashboard()
    }
  }

  doMedHtml() {
    var html = ``
    var medBtn = ``

    medBtn += `<button type="button" data-kind="group" data-action="${this.controllerName}#changeMed ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Grupo</button>`
    this.application.meds.forEach(element => {
      medBtn += `<button type="button" data-kind="company" data-med-id="${element.id}" data-action="${this.controllerName}#changeMed ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">${element.name}</button>`
    })

    html += `<div class="btn-group ml-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="medsBtnGroup">
              ${medBtn}
            </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {
    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
