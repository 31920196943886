import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "nextBtn", "errorMessage", "9999",
    "sexDropdown", "sexDropdownBtn", "sexInput", "sexList", "patientCheck", "sponsorCheck", "saveAccountBtn"]

  connect() {
    this.controllerName = `operations--products--bookings--add--account`
    this.account = {}
    this.send_data = { current_user: {}, account: {}, kind: {} }
    this.doAccountFormHtml()
  }

  saveAccount() {
    this.saveAccountBtnTarget.disabled = true
    this.stopAccountRefreshing()

    if (this.actionMode == "edit") { this.send_data.account.id = this.account.id }
    this.send_data.account.name = this.nameInputTarget.value
    this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
    this.send_data.account.sex = this.sexInputTarget.dataset.sex
    this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformNumberDate(this.birthdateInputTarget.value)
    this.send_data.kind.kind = `doctor`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medfiling_accounts`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/accounts/entities/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/accounts/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var account = response.data.cln
          controller.getControllerByIdentifier("operations--products--bookings--add--dashboard").account = account
          controller.saveAccountBtnTarget.innerText = `Confirmado`
          controller.saveAccountBtnTarget.disabled = true
          controller.accountConfirmed = true
        } else {
          controller.saveAccountBtnTarget.innerText = `Salvar`
          controller.saveAccountBtnTarget.disabled = false
          controller.accountConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--bookings--entities--index").goToDashboard()
  }

  nextStep() {
    this.nextBtnTarget.disabled = true
    this.stopRefreshing()

    var controller = this.getControllerByIdentifier("operations--products--bookings--add--dashboard")

    if (this.account.id) {
      controller.send_product_data.account.id = this.account.id
    }
    controller.send_product_data.account.name = this.nameInputTarget.value
    controller.send_product_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
    controller.send_product_data.account.cpf_pretty = this.cpfInputTarget.value
    controller.send_product_data.account.sex = this.sexInputTarget.dataset.sex
    controller.send_product_data.account.sex_pretty = this.sexInputTarget.innerText
    controller.send_product_data.account.kind = `doctor`
    controller.send_product_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformNumberDate(this.birthdateInputTarget.value)
    controller.send_product_data.account.birthdate_pretty = this.birthdateInputTarget.value

    controller.send_product_data.current_user.current_user_id = this.application.current_user.id

    this.getControllerByIdentifier("operations--products--bookings--add--contacts").doContactFormHtml()
  }

  doAccountFormHtml() {
    var html = this.stopRefreshing()

    var html = `<div class="col-6 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando ou Editando Médico</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="cpfForm">CPF Médico</label>
                              <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF Médico" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="nameForm">Nome Médico</label>
                              <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Médico" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="birthdateForm">Data Nascimento</label>
                              <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Sexo</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                    <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                    <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2 mb-4">
                        <div class="col-12 px-1 text-right">
                          <button type="button" class="btn btn-primary btn-sm py-2 f-065 d-none" data-action="click->${this.controllerName}#saveAccount" data-${this.controllerName}-target="saveAccountBtn"></button>
                        </div>
                      </div>               
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#nextStep operations--products--bookings--add--dashboard#changeStep" data-next="1" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.nameInputTarget.disabled = true
      controller.birthdateInputTarget.disabled = true
      controller.sexDropdownBtnTarget.disabled = true

      var product_data = controller.getControllerByIdentifier("operations--products--bookings--add--dashboard").send_product_data

      if (product_data.account.name != undefined) { controller.nameInputTarget.value = product_data.account.name }
      if (product_data.account.cpf != undefined) { controller.cpfInputTarget.value = product_data.account.cpf_pretty }
      if (product_data.account.birthdate != undefined) { controller.birthdateInputTarget.value = product_data.account.birthdate_pretty }
      if (product_data.account.sex != undefined) { controller.sexInputTarget.innerText = product_data.account.sex_pretty }
      if (product_data.account.sex != undefined) { controller.sexInputTarget.dataset.sex = product_data.account.sex }
      if (product_data.account.sex != undefined) { controller.sexDropdownTarget.value = product_data.account.sex }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.setErrorMessage(this.cpf_result.message)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""

      this.nameInputTarget.disabled = true
      this.birthdateInputTarget.disabled = true
      this.sexDropdownBtnTarget.disabled = true


      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "") {
    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "doctor" }, current_user: { current_user_id: this.application.current_user.id, feature: "medfiling_accounts" } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

      if (this.cpf_result.invalid == false) {
        var hasProduct = this.checkIfHasProduct(cleanCpf)

        if (hasProduct) {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O Médico já possui o produto Declaração de Ajuste Anual", 2000)
          this.setErrorMessage("O Médico já possui o produto Declaração de Ajuste Anual")
        } else {
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              if (response.process) {
                controller.account = response.data.cln

                if (controller.account.id) {
                  controller.nameInputTarget.value = controller.account.name
                  controller.birthdateInputTarget.value = controller.account.birthdate_pretty
                  controller.sexInputTarget.innerText = controller.account.sex_pretty
                  controller.sexInputTarget.dataset.sex = controller.account.sex
                  controller.sexDropdownTarget.value = controller.account.sex

                  controller.nameInputTarget.disabled = true
                  controller.sexDropdownBtnTarget.disabled = true
                  if (controller.birthdateInputTarget.value == controller.account.birthdate_pretty) {
                    controller.birthdateInputTarget.disabled = true
                  } else {
                    controller.birthdateInputTarget.disabled = false
                  }
                  controller.formTitleTarget.innerText = `Editando Médico`
                  controller.saveAccountBtnTarget.classList.remove("d-none")
                  controller.saveAccountBtnTarget.innerText = `Confirmar`
                  controller.actionMode = `edit`
                } else {
                  controller.formTitleTarget.innerText = `Criando Médico`
                  controller.nameInputTarget.value = "Favor adicionar o Nome do Médico"
                  controller.birthdateInputTarget.value = ""
                  controller.sexDropdownTarget.value = ""
                  controller.sexInputTarget.innerText = ""
                  controller.sexInputTarget.dataset.sex = ""

                  controller.nameInputTarget.disabled = false
                  controller.birthdateInputTarget.disabled = false
                  controller.sexDropdownBtnTarget.disabled = false

                  controller.saveAccountBtnTarget.classList.remove("d-none")
                  controller.saveAccountBtnTarget.innerText = `Salvar`
                  controller.actionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }

      }

    }
  }

  checkIfHasProduct(cpf) {
    var diffAccount = true
    var hasProduct = false
    this.application.bookings.forEach(element => {
      if (element.account_cpf == cpf) {
        this.nameInputTarget.value = element.account_name
        this.birthdateInputTarget.value = element.account_birthdate_pretty
        this.sexInputTarget.innerText = element.account_sex_pretty
        this.sexInputTarget.dataset.sex = element.account_sex
        this.sexDropdownTarget.value = element.account_sex
        this.nextBtnTarget.disabled = true
        this.nextBtnTarget.classList.add("d-none")
        this.setErrorMessage("O Médico já possui o Produto")
        diffAccount = false
        hasProduct = true
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      } else {
        if (diffAccount) {
          this.nextBtnTarget.disabled = false
          this.nextBtnTarget.classList.remove("d-none")
        }
      }
    });

    return hasProduct
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshAccountTimer = setInterval(function () {
      var len = 0

      if (controller.cpf_result && controller.cpf_result.invalid) {
        len += 1
      }

      if (controller.cpfInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == `` || controller.nameInputTarget.value == `Favor adicionar o Nome do Médico`) {
        len += 1
      }

      if (controller.birthdateInputTarget.value == ``) {
        len += 1
      }

      if (controller.sexInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveAccountBtnTarget.disabled = false
      } else {
        controller.saveAccountBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.accountConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopAccountRefreshing() {
    if (this.refreshAccountTimer) {
      clearInterval(this.refreshAccountTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
