import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "filter", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--entities--dashboard`
  }

  doReceivablesDashboard() {
    var html = `<div data-${this.controllerName}-target="mainDashboard">
                  <div class="row">
                    <div class="col-1 pr-1" data-${this.controllerName}-target="filter" data-controller="financials--books--receivables--entities--filter"></div>
                    <div class="col-11" data-${this.controllerName}-target="list" data-controller="financials--books--receivables--entities--index"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--books--receivables--entities--index`).permission = `financial_receivable_entities`
      controller.getControllerByIdentifier(`financials--books--receivables--entities--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`financials--books--receivables--entities--filter`).doFilterHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}