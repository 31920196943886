import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "leaderBodyTable", "inputTopic", "saveTopicBtn", "checkboxDone", "characterCountSpan", "teamBodyTable", "leaderCardBody", "teamCardBody"]

  connect() {
    this.controllerName = `users--works--meetings--boards--topics`
  }

  characterCount(ev) {
    var count = ev.target.value.length
    var sibling = ev.target.nextElementSibling
    if (count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${count} caracteres`
    }

    sibling.textContent = message

    if (count > 140) {
      sibling.classList.add("text-danger")
    } else {
      sibling.classList.remove("text-danger")
    }
  }

  doTopicHtml() {

    if (this.application.weeklies.length > 0) {
      if (this.application.board.leader_id == this.application.current_user.account_id) {
        var leaderFooter = `<div class="card-footer border-top text-right p-0">
                            <div class="row my-1 d-flex align-items-center">
                              <div class="col-10 px-1 text-left">
                                <input type="text" class="form-control f-065" placeholder="Adicione um tópico ..." aria-label="Adicione um tópico ..." data-${this.controllerName}-target="inputTopic" data-action="keyup->${this.controllerName}#saveTopic keyup->${this.controllerName}#characterCount">
                                <span class="f-065" data-${this.controllerName}-target="characterCountSpan">0 caracteres</span>
                              </div>
                              <div class="col-2 px-2 mc-tooltip">
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#saveTopic" data-${this.controllerName}-target="saveTopicBtn">playlist_add</span>
                                <span class="mc-tooltiptext">Enviar</span>
                              </div>
                            </div>
                          </div>`
        var teamFooter = ``
      } else if (this.application.board.team_id == this.application.current_user.account_id) {
        var leaderFooter = ``
        var teamFooter = `<div class="card-footer border-top text-right p-0">
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-10 px-1 text-left">
                              <input type="text" class="form-control f-065" placeholder="Adicione um tópico ..." aria-label="Adicione um tópico ..." data-${this.controllerName}-target="inputTopic" data-action="keyup->${this.controllerName}#saveTopic keyup->${this.controllerName}#characterCount">
                              <span class="f-065" data-${this.controllerName}-target="characterCountSpan">0 caracteres</span>
                            </div>
                            <div class="col-2 px-2 mc-tooltip">
                              <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#saveTopic" data-${this.controllerName}-target="saveTopicBtn">playlist_add</span>
                              <span class="mc-tooltiptext">Enviar</span>
                            </div>
                          </div>
                        </div>`
      }


      var html = `<div class="row w-100">
                  <div class="col-6 px-1">
                    <div class="card">
                      <div class="card-header f-065 p-0 text-center">
                        <h6 class="card-title display-4 py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.application.board.leader_name}</strong></h6>
                      </div>
                      <div class="card-body p-0 f-065 text-center card-overflow" data-${this.controllerName}-target="leaderCardBody">
                        <div class="row">
                          <div class="col p-0">
                            <div class="table-responsive">
                              <table class="table table-sm table-hover" style="font-size:80%;">
                                <thead>
                                  <tr>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                  </tr>
                                </thead>
                                <tbody data-${this.controllerName}-target="leaderBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      ${leaderFooter}
                    </div>
                  </div>

                  <div class="col-6 px-1">
                    <div class="card">
                      <div class="card-header f-065 p-0 text-center">
                        <h6 class="card-title display-4 py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.application.board.team_name}</strong></h6>
                      </div>
                      <div class="card-body p-0 f-065 text-center card-overflow" data-${this.controllerName}-target="teamCardBody">
                        <div class="row">
                          <div class="col p-0">
                            <div class="table-responsive">
                              <table class="table table-sm table-hover" style="font-size:80%;">
                                <thead>
                                  <tr>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                    <th style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></th>
                                  </tr>
                                </thead>
                                <tbody data-${this.controllerName}-target="teamBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      ${teamFooter}
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.mainTarget.innerHTML = html)
      }).then(() => {
        controller.leaderCardBodyTarget.style.height = ($(window).height() * 0.4) + "px"
        controller.teamCardBodyTarget.style.height = ($(window).height() * 0.4) + "px"
        controller.leaderBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(6, 4))
        controller.doDataTable()
        controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      })
    } else {
      var html = `<div class="row my-2 w-100 weeklyCard">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-2 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-9 px-1">
                            Adicione a 1ª Reunião Semanal
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

      this.mainTarget.innerHTML = html
    }

    
  }

  doDataTable() {
    var current_leader_topics = []
    var current_team_topics = []
    this.application.topics.forEach(element => {
      if (element.weekly_id == this.application.current_weekly.id && element.owner_id == this.application.board.leader_id) {
        current_leader_topics[current_leader_topics.length] = element
      } else if (element.weekly_id == this.application.current_weekly.id && element.owner_id == this.application.board.team_id) {
        current_team_topics[current_team_topics.length] = element
      }
    });
    var leader_topics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_leader_topics, "done")
    var team_topics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_team_topics, "done")

    this.listData(leader_topics, "leader")
    this.listData(team_topics, "team")
    
  }

  listData(data, table) {
    if (table == "leader") {
      this.leaderBodyTableTarget.innerHTML = ""
      if (data == undefined || data == [] || data.length == 0) {
        var noData = `</tr>
                      <td colspan="9" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tópicos</span>
                      </td>
                    </tr>`

        this.leaderBodyTableTarget.innerHTML = noData
      } else {
        data.forEach(element => {
          this.leaderBodyTableTarget.insertAdjacentHTML("beforeend", this.topicTablePartial(element, data.length))
        });
      }  
    } else if (table == "team") {
      this.teamBodyTableTarget.innerHTML = ""
      if (data == undefined || data == [] || data.length == 0) {
        var noData = `</tr>
                      <td colspan="9" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tópicos</span>
                      </td>
                    </tr>`

        this.teamBodyTableTarget.innerHTML = noData
      } else {
        data.forEach(element => {
          this.teamBodyTableTarget.insertAdjacentHTML("beforeend", this.topicTablePartial(element, data.length))
        });
      }
    }
    
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
    this.rollTopics()
  }

  topicTablePartial(element, length) {
    if (element.done) {
      if (element.owner_id == this.application.current_user.account_id) { 
        var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                      <input checked type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxDone" data-action="click->${this.controllerName}#doneTopic">
                      <label class="custom-control-label pointer" for="check-${element.id}"></label>
                    </div>`
        
        if (this.application.permissions.work_meeting_topics.can_update) {
          var destroy = `<button data-action="click->${this.controllerName}#destroyTopic" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">delete</span>
                      <span class="mc-tooltiptext">Editar</span>
                    </button>`
        } else {
          var destroy = ``
        }
      } else {
        var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input disabled checked type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxDone" data-action="click->${this.controllerName}#doneTopic">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`

        var destroy = ``
      }
      var body = `<s>${element.body}</s>`
    } else {
      if (element.owner_id == this.application.current_user.account_id) {
        var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                      <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxDone" data-action="click->${this.controllerName}#doneTopic">
                      <label class="custom-control-label pointer" for="check-${element.id}"></label>
                    </div>`

        if (this.application.permissions.work_meeting_topics.can_update) {
          var destroy = `<button data-action="click->${this.controllerName}#destroyTopic" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">delete</span>
                      <span class="mc-tooltiptext">Editar</span>
                    </button>`
        } else {
          var destroy = ``
        }
      } else {
        var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input disabled type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxDone" data-action="click->${this.controllerName}#doneTopic">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`

        var destroy = ``
      }
      
      var body = `${element.body}`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td colspan="1" scope="col" class="p-1 align-middle pointer f-075">${check}</td>
                    <td colspan="7" scope="col" class="p-1 align-middle pointer f-075 text-left">${body}</td>
                    <td colspan="1" scope="col" class="p-1 align-middle pointer f-075">${destroy}</td>
                  </tr>`

    return rowHtml
  }

  saveTopic(ev) {
    this.send_data = { current_user: {}, topic: {} }
    this.actionMode = `new`
    var count = this.inputTopicTarget.value.length

    if (count > 140) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && count > 0) {
        this.send_data.topic.board_id = this.application.board.id
        this.send_data.topic.weekly_id = this.application.current_weekly.id
        this.send_data.topic.owner_id = this.application.current_user.account_id
        this.send_data.topic.owner_name = this.application.current_user.name
        this.send_data.topic.body = this.inputTopicTarget.value
        this.send_data.topic.priority = `low`

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `work_meeting_topics`

        this.requestSave()
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        ev.target.value = ""
      }
    }
  }

  doneTopic(ev) {
    var topicId = ev.currentTarget.closest(".itemRow").dataset.id
    
    this.send_data = { current_user: {}, topic: {} }
    this.actionMode = `edit`

    this.send_data.topic.id = topicId
    this.send_data.topic.done = ev.currentTarget.checked
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_topics`

    this.requestSave()
  }

  destroyTopic(ev) {
    var topicId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data = { current_user: {}, topic: {} }
    this.actionMode = `destroy`

    this.send_data.topic.id = topicId
    this.send_data.topic.active = false
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_topics`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/works/meetings/topics/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/works/meetings/topics/update"
      var method = "PUT"
    } else if (this.actionMode == "destroy") {
      var url = "/users/works/meetings/topics/destroy"
      var method = "DELETE"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var topic = response.data.cln
          if (controller.actionMode == "destroy") {
            controller.application.topics.forEach((element, i) => {
              if (element.id == topic.id) {
                controller.application.topics.splice(controller.application.topics.indexOf(element), 1)
              }
            })
          } else if (controller.actionMode == "new") {
            controller.application.topics[controller.application.topics.length] = topic
          } else if (controller.actionMode == "edit") {
            if (topic.active) {
              controller.application.topics.forEach((element, i) => {
                if (element.id == topic.id) {
                  controller.application.topics.splice(controller.application.topics.indexOf(element), 1, topic)
                }
              })
            } else {
              controller.application.topics.forEach((element, i) => {
                if (element.id == topic.id) {
                  controller.application.topics.splice(controller.application.topics.indexOf(element), 1)
                }
              })
            }
          }
          // if (controller.actionMode == "new") {
          //   controller.application.topics[controller.application.topics.length] = topic
          // } else if (controller.actionMode == "edit") {
          //   if (topic.active) {
          //     controller.application.topics.forEach((element, i) => {
          //       if (element.id == topic.id) {
          //         controller.application.topics.splice(controller.application.topics.indexOf(element), 1, topic)
          //       }
          //     })
          //   } else {
          //     controller.application.topics.forEach((element, i) => {
          //       if (element.id == topic.id) {
          //         controller.application.topics.splice(controller.application.topics.indexOf(element), 1)
          //       }
          //     })
          //   }
          // }
          
          controller.inputTopicTarget.value = ""
          controller.characterCountSpanTarget.textContent = ""
          controller.getControllerByIdentifier(`users--works--meetings--boards--topics`).doDataTable()
          controller.rollTopics()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  rollTopics() {
    $(this.leaderCardBodyTarget).stop().animate({ scrollTop: $(this.leaderCardBodyTarget)[0].scrollHeight }, 1000);
    $(this.teamCardBodyTarget).stop().animate({ scrollTop: $(this.teamCardBodyTarget)[0].scrollHeight }, 1000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getIds(element) {
    return element.id
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
