
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["place", "result"]

  connect() {
    this.doSubmenuHtml()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  doSubmenuHtml() {
    // `<span data-url="/a/buscas" data-action="click->marketing-dashboard-submenu#goTo" class="dropdown-item py-1 pointer">Buscas</span>`
    // `<span data-url="/a/visitas" data-action="click->marketing-dashboard-submenu#goTo" class="dropdown-item py-1  pointer">Visitas</span>`
    var submenuHtml = `<div class="dropdown" data-toggle="tooltip" data-placement="top" data-original-title="Submenu">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="marketingMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="marketingMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span data-url="/a/marketing-vendas" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Dashboard</span>
                          <span data-url="/a/leads" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Leads</span>
                          <span data-url="/a/visitas-sessoes" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Visitas & Sessões</span>
                          <span data-url="/a/landings" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Landings</span>
                          <span data-url="/a/campanhas" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Campanhas</span>
                          <span data-url="/a/calculadoras" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Calculadoras</span>
                          <span data-url="/a/downloads" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Downloads</span>
                          <span data-url="/a/eventos" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Eventos</span>
                          <span data-url="/a/cursos" data-action="click->marketing--dashboard--submenu#goTo" class="dropdown-item py-1 pointer">Cursos</span>
                        </div>
                      </div>`

    this.placeTarget.innerHTML = submenuHtml
    tooltip()
  }

  

}
