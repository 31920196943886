
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    this.controllerName = `commercial--sales--leads--meetings--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToMeetings() {
    this.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").doMeetingGrid()
  }

  goToSlots() {
    this.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").doSlotGrid()
  }

  doSubmenuHtml() {

    var meetingsLink = `<span data-action="click->${this.controllerName}#goToMeetings" class="dropdown-item py-1 pointer dropdown-submenu-item">Consultorias</span>`
    var slotsLink = `<span data-action="click->${this.controllerName}#goToSlots" class="dropdown-item py-1 pointer dropdown-submenu-item">Horários</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${meetingsLink}
                          ${slotsLink}
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
