import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "uploads", "signs", "labelUpload", "uploadBtn", "fileName", "fileUpload",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "agreementLink", "summaryLink",
                    "progressUpload", "progressUploadBar", "progressUploadCounter", "link", "linkEdit", "linkInput"]


  connect() {
    this.controllerName = `financials--products--purchases--entities--contracts`
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "rtf", "docx", "DEC", "REC", ".DEC", ".REC",
                                "vnd.openxmlformats-officedocument.wordprocessingml.document", "txt", "plain", "PDF", "PNG", "JPEG", ".XLSX", ".DOCX", ".TXT"]
    this.uploadFeature = true
    this.uploadReady = false
  }

  setFiles() {
    this.application.purchase.contracts.forEach(element => {
      if (element.kind == `agreement`) {
        this.agreementFile = element
      } else if (element.kind == `summary`) {
        this.summaryFile = element
      }
    })

    this.doUploadHtml()
  }

  doContractsHtml() {
    var html = `<div class="row my-3 w-100">
                  <div class="col-12" data-${this.controllerName}-target="link"></div>
                </div>
                <div class="row my-2 w-100">
                  <div class="col-12" data-${this.controllerName}-target="uploads"></div>
                </div>
                <div class="row my-2 w-100">
                  <div class="col-12" data-${this.controllerName}-target="signs"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.setLink()
      controller.setFiles()
      controller.setSigns()
    })
  }

  setLink() {
    if (this.application.purchase.link) {
      var html = `<div class="row w-100 d-flex align-items-center">
                    <div class="col-5 px-2 d-flex">
                      <span class="f-085 text-bold">Link do Contrato no Drive</span>
                      <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.purchase.link}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Documento</span>
                      </button>
                      <button data-action="click->${this.controllerName}#editLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar Link</span>
                      </button>
                    </div>
                    <div class="col-7 px-2 d-flex align-items-center d-none" data-${this.controllerName}-target="linkEdit">
                      <div class="form-group w-75 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="linkInput" type="text" placeholder="Link Contrato" required>
                      </div>
                      <div class="ml-auto mb-0">
                        <button data-action="click->${this.controllerName}#saveLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                          <span class="material-icons md-sm md-dark">save</span>
                          <span class="mc-tooltiptext">Salvar Link</span>
                        </button>
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">clear</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    } else {
      var html = `<div class="row w-100 d-flex align-items-center">
                    <div class="col-5 px-2 d-flex">
                      <span class="f-085 text-bold">Link do Contrato no Drive</span>
                      <button data-action="click->${this.controllerName}#editLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar Link</span>
                      </button>
                    </div>
                    <div class="col-7 px-2 d-flex align-items-center d-none" data-${this.controllerName}-target="linkEdit">
                      <div class="form-group w-75 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="linkInput" type="text" placeholder="Link Contrato" required>
                      </div>
                      <div class="ml-auto mb-0">
                        <button data-action="click->${this.controllerName}#saveLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                          <span class="material-icons md-sm md-dark">save</span>
                          <span class="mc-tooltiptext">Salvar Link</span>
                        </button>
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">clear</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }
    
    if (this.application.permissions.financial_purchase_contracts.can_show) {
      this.linkTarget.innerHTML = html
    }
  }

  editLink() {
    this.linkEditTarget.classList.remove("d-none")
    this.linkInputTarget.value = this.application.purchase.link
  }

  hideEditor() {
    this.linkEditTarget.classList.add("d-none")
  }

  saveLink() {
    this.send_data = { current_user: {}, purchase: {}}

    this.send_data.purchase.id = this.application.purchase.id
    this.send_data.purchase.link = this.linkInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var url = "/financials/products/purchases/entities/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var purchase = response.data.cln
          controller.application.purchase = purchase
          controller.setLink()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doUploadHtml() {

    if (this.agreementFile.has_agreement) {
      var agreement = `<span class="f-085 text-bold">Contrato</span>
                          <button data-action="click->${this.controllerName}#goToURL" data-url="${this.agreementFile.agreement_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Contrato</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.agreementFile.id}" data-field="agreement" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Contrato</span>
                          </button>`
    } else {
      var agreement = `<span class="f-085 text-bold">Sem Arquivo Contrato</span>`
    }

    if (this.summaryFile.has_summary) {
      var summary = `<span class="f-085 text-bold">Sumário</span>
                         <button data-action="click->${this.controllerName}#goToURL" data-url="${this.summaryFile.summary_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Sumário</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.summaryFile.id}" data-field="summary" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Sumário</span>
                          </button>`
    } else {
      var summary = `<span class="f-085 text-bold">Sem Arquivo Sumário</span>`
    }

    var html = `<div class="row w-100">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center justify-content-center">
                        <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-6 d-flex align-items-center justify-content-center">
                        <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                          <div class="row my-1">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-white">cloud_upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-flex align-items-center">
                    <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                      <div class="col-12 my-2 d-flex" data-${this.controllerName}-target="agreementLink">
                        ${agreement}
                      </div>
                      <div class="col-12 my-2 d-flex" data-${this.controllerName}-target="summaryLink">
                        ${summary}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
                  <div class="col-6">
                    <span data-${this.controllerName}-target="fileName"></span>
                  </div>
                  <div class="col-3">
                    <div class="form-group mb-0">
                      <div class="floating-label floating-label-sm">
                        <label>Tipo</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                              <li data-kind="agreement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contrato</li>
                              <li data-kind="summary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sumário</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.uploadsTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
    })
  }

  setSigns() {
    var html = ``

    this.application.purchase.parties.forEach(element => {

      if (element.party_kind_obj.sex == "male") {
        var address = `residente e domiciliado`
        // var maritalStatus = this.setMaritalStatusGender(element.party_kind_info.marital_status, "male")
      } else if (element.party_kind_obj.sex == "female") {
        var address = `residente e domiciliada`
        // var maritalStatus = this.setMaritalStatusGender(element.party_kind_info.marital_status, "female")
      }

      if (element.party_address.complement == undefined || element.party_address.complement == ``) {
        var complement = ``
      } else {
        var complement = ` ${element.party_address.complement}, `
      }

      if (element.party_email_pretty == "Sem e-mail") {
        var email = ``
      } else {
        var email = element.party_email_pretty
      }

      if (element.party_kind == `individual`) {
        var copy = `${element.party_name}, ${element.party_kind_info.source}, ${element.party_kind_info.marital_status_pretty}, ${element.party_kind_info.occupation}, CPF nº ${element.party_number_pretty}, ${address} em ${element.party_address.street}, nº ${element.party_address.number}, ${complement} bairro ${element.party_address.district}, cidade de ${element.party_address.city}/${element.party_address.state}, CEP ${element.party_address.postal_code_pretty}, e-mail ${email}`

        html += `<div class="row my-2 d-flex align-items-center">
                  <div class="col-11">
                    <span> CONTRATANTE: ${element.party_name}, ${element.party_kind_info.source}, ${element.party_kind_info.marital_status_pretty}, ${element.party_kind_info.occupation}, CPF nº ${element.party_number_pretty}, ${address} em
                      ${element.party_address.street}, nº ${element.party_address.number}, ${complement}
                      bairro ${element.party_address.district}, cidade de ${element.party_address.city}/${element.party_address.state},
                      CEP ${element.party_address.postal_code_pretty}, e-mail ${email}
                    </span>
                  </div>
                  <div class="col-1">
                    <span class="mc-tooltip pointer">
                      <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${copy}" data-action="click->app--helpers--copy#copy">file_copy</span>
                      <span class="mc-tooltiptext">Copiar</span>
                    </span>
                  </div>
                </div>`

      } else if (element.party_kind == `legal`) {
        // neste ato representada em conformidade com o disposto no seu Contrato Social,
        var copy = `${element.party_name}, inscrita no CNPJ sob nº ${element.party_number_pretty}, estabelecida em ${element.party_address.street}, nº ${element.party_address.number}, ${complement} bairro ${element.party_address.district}, cidade de ${element.party_address.city}/${element.party_address.state}, CEP ${element.party_address.postal_code_pretty}`

        html += `<div class="row my-2 d-flex align-items-center">
                  <div class="col-11">
                    <span> CONTRATANTE: ${element.party_name}, inscrita no CNPJ sob nº ${element.party_number_pretty}, estabelecida em
                      ${element.party_address.street}, nº ${element.party_address.number}, ${complement}
                      bairro ${element.party_address.district}, cidade de ${element.party_address.city}/${element.party_address.state},
                      CEP ${element.party_address.postal_code_pretty}
                    </span>
                  </div>
                  <div class="col-1">
                    <span class="mc-tooltip pointer">
                      <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${copy}" data-action="click->app--helpers--copy#copy">file_copy</span>
                      <span class="mc-tooltiptext">Copiar</span>
                    </span>
                  </div>
                </div>`
      }

    })

    this.signsTarget.innerHTML = html
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]
        
        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
        obj.file = element
        this.upload_file = obj
        this.uploadReady = true
        this.fileUploadTarget.classList.remove("d-none")
        this.fileNameTarget.innerText = this.upload_file.file_name
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`


      if (this.kindInputTarget.dataset.kind == `agreement`) {
        this.agreementLinkTarget.innerHTML = progressSpan

        this.upload_file.id = this.agreementFile.id
        this.upload_file.kind = this.agreementFile.kind
      } else if (this.kindInputTarget.dataset.kind == `summary`) {
        this.summaryLinkTarget.innerHTML = progressSpan

        this.upload_file.id = this.summaryFile.id
        this.upload_file.kind = this.summaryFile.kind
      }

      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.actionMode = `new`

    if (this.upload_file.kind == `agreement`) {
      var contractKindPretty = `Contrato Assinado`
    } else if (this.upload_file.kind == `summary`) {
      var contractKindPretty = `Sumário das Assinaturas`
    }

    this.progressCount(0)
    this.send_data = { current_user: {}, contract: {}, note: {} }

    this.send_data.contract.id = this.upload_file.id
    this.send_data.contract.name = this.upload_file.file_name
    this.send_data.contract.file_name = this.upload_file.file_name
    this.send_data.contract.file_size = this.upload_file.file_size
    this.send_data.contract.file_type = this.upload_file.file_type
    this.send_data.contract.file_last_modified = this.upload_file.file_last_modified

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.purchase.id
    this.send_data.note.domain_type = `products_purchases`
    this.send_data.note.domain_name = `products_purchases`
    this.send_data.note.date_id = this.application.purchase.date_id
    this.send_data.note.date_type = `financials_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} fez o upload do ${contractKindPretty}`
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var url = "/financials/products/purchases/contracts/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          controller.application.purchase.contracts.forEach(element => {
            if (element.id == file.id) {
              controller.application.purchase.contracts.splice(controller.application.purchase.contracts.indexOf(element), 1, file)
            }
          })
          controller.requestUpload(file.id, this.upload_file.file, file.kind)
        }

        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }


  requestUpload(contract_id, file, kind) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', kind)
    formData.append('contract_id', contract_id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/financials/products/purchases/contracts/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          var file = response.data.cln
          controller.application.purchase.contracts.forEach(element => {
            if (element.id == file.id) {
              controller.application.purchase.contracts.splice(controller.application.purchase.contracts.indexOf(element), 1, file)
            }
          })
          controller.uploadSwitch(false)
          controller.setFiles()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile(ev) {
    var fileId = ev.currentTarget.dataset.id
    var field = ev.currentTarget.dataset.field

    this.send_data = { current_user: {}, contract: {}, note: {} }

    this.send_data.contract.id = fileId
    this.send_data.contract.field = field

    if (field == `agreement`) {
      var contractKindPretty = `Contrato Assinado`
    } else if (field == `summary`) {
      var contractKindPretty = `Sumário das Assinaturas`
    }

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.purchase.id
    this.send_data.note.domain_type = `products_purchases`
    this.send_data.note.domain_name = `products_purchases`
    this.send_data.note.date_id = this.application.purchase.date_id
    this.send_data.note.date_type = `financials_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} apagou o ${contractKindPretty}`
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var url = "/financials/products/purchases/contracts/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          controller.application.purchase.contracts.forEach(element => {
            if (element.id == file.id) {
              controller.application.purchase.contracts.splice(controller.application.purchase.contracts.indexOf(element), 1, file)
            }
          })
          controller.setFiles()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  userCanUpdate() {
    var allowed = this.mapElements(this.application.purchase_team, `id`)
    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.purchase.journey_status != "finished" && this.application.purchase.journey_status != "canceled")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (controller.uploadReady == false) {
        len += 1
      }

      if (controller.kindInputTarget && controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.uploadSwitch(true)
      } else {
        controller.uploadSwitch(false)
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
