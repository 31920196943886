import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "errorMessage", "saveAccountBtn", "startedAtInput", "sourceDropdown", "sourceDropdownBtn", "sourceInput",
                    "sourceList", "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "nextBtn", "setupCheckbox", "recurringCheckbox", "opportunityTokenInput",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv", "productNameModal",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList", "productAccrualDiv"]

  connect() {
    this.controllerName = `financials--products--purchases--add--product`
    this.doProductFormHtml()
  }

  cancelSave() {
    this.getControllerByIdentifier("financials--products--purchases--dashboard--index").goToDashboard()
  }

  doProductFormHtml() {

    // `<div class="col-3">
    //   <div class="form-group">
    //     <div class="floating-label floating-label-sm">
    //       <label>Produto</label>
    //       <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
    //         <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
    //         <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
    //           <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
    //           <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList">
    //             <li data-product-name="medclinic" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
    //             <li data-product-name="medbooking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
    //             <li data-product-name="medfiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Declaração IRPF</li>
    //             <li data-product-name="medreceivement" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestão de Recebimentos</li>
    //             <li data-product-name="medbiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
    //             <li data-product-name="medpayroll" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Folha de Pagamento</li>
    //             <li data-product-name="medinsurance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
    //             <li data-product-name="medprev" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Previdência Privada</li>
    //             <li data-product-name="medloan" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito</li>
    //             <li data-product-name="medoutsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Terceirização</li>
    //             <li data-product-name="medreturn" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Planner</li>
    //             <li data-product-name="medconsulting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço Avulso</li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>`

    var html = `<div class="col-10 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Produto</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row mt-3">
                        <div class="col-12">
                          <span class="mb-0 f-065">Origem da Contratação</span>
                          <hr class="my-1">
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-3">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Fonte</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sourceDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sourceInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="sourceList" data-app--helpers--search-target="searchList">
                                    <li data-source="site" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Site</li>
                                    <li data-source="base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Base</li>
                                    <li data-source="referrer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                    <li data-source="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                    <li data-source="landing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing</li>
                                    <li data-source="event" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Evento</li>
                                    <li data-source="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Direto</li>
                                    <li data-source="google" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Google</li>
                                    <li data-source="facebook" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Facebook</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Área MedCapital</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList">
                                    <li data-channel="sales" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                    <li data-channel="customer_success" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sucesso do Cliente</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="opportunityTokenForm">Token Oportunidade</label>
                              <input aria-describedby="opportunityTokenFormHelp" class="form-control f-065" id="opportunityTokenForm" data-${this.controllerName}-target="opportunityTokenInput" placeholder="Link" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <span class="mb-0 f-065">Detalhes do Produto</span>
                          <hr class="my-1">
                        </div>
                      </div>

                      <div class="row my-2 d-flex align-items-center">
                        <div class="col-1 text-right px-0">
                          <button data-action="click->${this.controllerName}#showProducts" data-element="productNameInput" data-${this.controllerName}-target="productNameModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">help_center</span>
                            <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Produtos</span>
                          </button>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Produto</label>
                              <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="productNameInput" type="text" placeholder="Produto" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Tipo</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                                <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Serviço</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                                <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2" data-${this.controllerName}-target="productPlanDiv">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Plano</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 d-none" data-${this.controllerName}-target="productAccrualDiv">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Exercício</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row my-2">
                        <div class="col-1"></div>
                        <div class="col-2">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="startedAtForm">Data Contratação</label>
                              <input aria-describedby="startedAtFormHelp" class="form-control f-065" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Data Início" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 d-flex justify-content-center align-items-center">
                          <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex">
                            <input type="checkbox" class="custom-control-input" id="setupCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKind">
                            <label class="custom-control-label f-065" for="setupCheck">Avulso</label>
                          </div>
                        </div>
                        <div class="col-3 d-flex justify-content-center align-items-center">
                          <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex">
                            <input type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKind">
                            <label class="custom-control-label f-065" for="recurringCheck">Recorrência</label>
                          </div>
                        </div>
                        <div class="col-2 d-flex align-items-center" data-${this.controllerName}-target="purchaseKind" data-kind="none">
                        </div>
                      </div>
                      
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Cancelar</button>
                      <button disabled type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#nextStep" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.startedAtInputTarget), "yearly", date.getFullYear())

      var purchase = controller.application.purchase

      if (purchase.id) {
        controller.actionMode = `edit`

        controller.sourceDropdownTarget.value = purchase.source
        controller.sourceInputTarget.dataset.source = purchase.source
        controller.sourceInputTarget.innerText = purchase.source_pretty
        controller.channelDropdownTarget.value = purchase.channel
        controller.channelInputTarget.dataset.channel = purchase.channel
        controller.channelInputTarget.innerText = purchase.channel_pretty
        controller.opportunityTokenInputTarget.value = purchase.opportunity_token
        controller.startedAtInputTarget.value = purchase.started_at_pretty
        controller.setupCheckboxTarget.checked = purchase.has_setup
        controller.recurringCheckboxTarget.checked = purchase.has_recurring
        controller.purchaseKindTarget.dataset.kind = purchase.kind

        // controller.productNameDropdownTarget.value = purchase.product_name
        controller.productNameInputTarget.dataset.productName = purchase.product_name
        controller.productNameInputTarget.value = purchase.product_name_pretty
        controller.productNameModalTarget.classList.add(`d-none`)

        controller.productKindDropdownTarget.value = purchase.product_kind
        controller.productKindInputTarget.dataset.productKind = purchase.product_kind
        controller.productKindInputTarget.innerText = purchase.product_kind_pretty

        if (purchase.product_plan) {
          controller.productPlanDropdownTarget.value = purchase.product_plan_pretty
          controller.productPlanInputTarget.dataset.productPlan = purchase.product_plan
          controller.productPlanInputTarget.innerText = purchase.product_plan_pretty
        }

        controller.productServiceDropdownTarget.value = purchase.product_service
        controller.productServiceInputTarget.dataset.productService = purchase.product_service
        controller.productServiceInputTarget.innerText = purchase.product_service_pretty

        controller.setProductPlanList()
        if (purchase.product_name == `medfiling`) {
          controller.productAccrualDropdownTarget.value = purchase.product_accrual
          controller.productAccrualInputTarget.dataset.productAccrual = purchase.product_accrual
          controller.productAccrualInputTarget.innerText = purchase.product_accrual
          controller.productAccrualDivTarget.classList.remove(`d-none`)
          controller.setProductAccrualList()
        } else {
          controller.productAccrualDivTarget.classList.add(`d-none`)
        }


      } else {
        controller.actionMode = `new`

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }) 
  }

  uniqKind(ev) {
    var kind = ev.currentTarget.dataset.kind

    this.setKind(kind)

    // if (kind == `setup`) {
    //   this.purchaseKindTarget.dataset.kind = `setup`
    //   this.setupCheckboxTarget.checked = true
    //   this.recurringCheckboxTarget.checked = false
    // } else if (kind == `recurring`) {
    //   this.purchaseKindTarget.dataset.kind = `recurring`
    //   this.setupCheckboxTarget.checked = false
    //   this.recurringCheckboxTarget.checked = true
    // }
  }

  setKind(kind) {
    if (kind == `setup`) {
      this.purchaseKindTarget.dataset.kind = `setup`
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.purchaseKindTarget.dataset.kind = `recurring`
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  // selectProductName(ev) {
  selectProductName(productName) {
    // this.product_name = ev.currentTarget.dataset.productName
    this.product_name = productName

    this.setProductPlanList()
    if (this.product_name == `medfiling`) {
      this.productAccrualDivTarget.classList.remove(`d-none`)
      this.setProductAccrualList()
    } else {
      this.productAccrualDivTarget.classList.add(`d-none`)
    }

    this.listProductKind()
    this.listProductServices()
  }

  listProductKind() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medfiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      // this.productKindDropdownBtnTarget.disabled = false
      // this.productKindDropdownTarget.value = ``
      // this.productKindInputTarget.dataset.productKind = ``
      // this.productKindInputTarget.innerText = ``
    } else if (this.product_name == `medbooking`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medclinic`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="home" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">eSocial</li>`
      html += `<li data-product-kind="employee" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Funcionário</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `none`
      this.productKindInputTarget.dataset.productKind = `none`
      this.productKindInputTarget.innerText = `Nenhum`
      html += `<li data-product-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`
    } else if (this.product_name == `medprev`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="pgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PGBL</li>`
      html += `<li data-product-kind="vgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">VGBL</li>`
    } else if (this.product_name == `medloan`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="alimony" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesada</li>`
      html += `<li data-product-kind="debt_refinancing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financiamento</li>`
      html += `<li data-product-kind="prepayment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="finance_outsourcing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">BPO</li>`
      html += `<li data-product-kind="virtual_office" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Escritório Virtual</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="life_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vida</li>`
      html += `<li data-product-kind="income_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DIT</li>`
      html += `<li data-product-kind="responsability_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">RCP</li>`
      html += `<li data-product-kind="health_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saúde</li>`
    } else if (this.product_name == `medoncall`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medcontent`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    }

    this.productKindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listProductServices() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Planner`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medfiling`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Declaração`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura Declaração</li>`
      html += `<li data-product-service="rectification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Retificação Declaração</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`

      this.setKind(`setup`)
      this.setupCheckboxTarget.disabled = true
      this.recurringCheckboxTarget.disabled = true
    } else if (this.product_name == `medbooking`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medclinic`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="pos_opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pós-Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="amendment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>`
      // html += `<li data-product-service="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Gestão de Recebimentos`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Faturamento`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = ``
      this.productServiceInputTarget.dataset.productService = ``
      this.productServiceInputTarget.innerText = ``
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `separate`
      this.productServiceInputTarget.dataset.productService = `separate`
      this.productServiceInputTarget.innerText = `Avulso`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medprev`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medloan`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productServiceDropdownBtnTarget.disabled = true
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoncall`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura Gestão de Recebimentos`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medcontent`) {
      this.productServiceDropdownBtnTarget.disabled = false
      this.productServiceDropdownTarget.value = `opening`
      this.productServiceInputTarget.dataset.productService = `opening`
      this.productServiceInputTarget.innerText = `Abertura`
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    }

    if (this.product_name != `medfiling`) {
      html += `<li data-product-service="renewal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Renovação</li>`
      html += `<li data-product-service="cancellation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Distrato</li>`
      html += `<li data-product-service="additive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aditivo</li>`  
    }
    
    this.productServiceListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setProductAccrualList() {
    var html = ``

    html += `<li data-product-accrual="2025" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2025</li>`
    html += `<li data-product-accrual="2024" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2024</li>`
    html += `<li data-product-accrual="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>`
    html += `<li data-product-accrual="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>`
    html += `<li data-product-accrual="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>`
    html += `<li data-product-accrual="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>`
    html += `<li data-product-accrual="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>`
    html += `<li data-product-accrual="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2018</li>`

    this.productAccrualListTarget.innerHTML = html
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="pro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>`
    html += `<li data-product-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>`
    html += `<li data-product-plan="enterprise" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Enterprise</li>`

    this.productPlanListTarget.innerHTML = html
  }

  nextStep() {
    this.stopRefreshing()
    this.nextBtnTarget.disabled = true

    this.send_data = { current_user: {}, purchase: {}, note: {} }

    if (this.actionMode == "edit") {
      this.send_data.purchase.id = this.application.purchase.id
      var r = true
    } else {
      var r = confirm(`Confirma o início da Contratação?`)
      this.send_data.purchase.status = `going`
      this.send_data.purchase.journey_status = `to_send_contract`

      this.send_data.purchase.generator_id = this.application.current_user.account_id
      this.send_data.purchase.generator_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}` 
    }

    this.send_data.purchase.product_name = this.productNameInputTarget.dataset.productName

    if (this.send_data.purchase.product_name == `medfiling`) {
      this.send_data.purchase.product_accrual = this.productAccrualInputTarget.dataset.productAccrual
    }
    this.send_data.purchase.product_plan = this.productPlanInputTarget.dataset.productPlan

    this.send_data.purchase.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.purchase.product_service = this.productServiceInputTarget.dataset.productService
    this.send_data.purchase.source = this.sourceInputTarget.dataset.source
    this.send_data.purchase.channel = this.channelInputTarget.dataset.channel
    this.send_data.purchase.opportunity_token = this.opportunityTokenInputTarget.value
    this.send_data.purchase.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtInputTarget.value)
    this.send_data.purchase.has_setup = this.setupCheckboxTarget.checked
    this.send_data.purchase.has_recurring = this.recurringCheckboxTarget.checked
    this.send_data.purchase.kind = this.purchaseKindTarget.dataset.kind

    if (this.actionMode == `new`) {
      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_id = this.application.purchase.id
      this.send_data.note.domain_type = `products_purchases`
      this.send_data.note.domain_name = `products_purchases`
      this.send_data.note.date_id = this.application.purchase.date_id
      this.send_data.note.date_type = `financials_dates`
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `updating`
      this.send_data.note.date = new Date()
      this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} criou a Contratação`
      this.send_data.note.title = `Atualização`
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_entities`

    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    } else {
      this.nextBtnTarget.disabled = false
      this.refreshSaveBtn()
    }
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/financials/products/purchases/entities/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/financials/products/purchases/entities/update"
      var method = "PUT"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.getControllerByIdentifier("financials--products--purchases--add--dashboard").changeStep(1)
          var purchase = response.data.cln
          controller.application.purchase = purchase
          if (controller.actionMode == "new") {
            controller.application.purchases[controller.application.purchases.length] = purchase
          } else if (controller.actionMode == "edit") {
            controller.application.purchase = purchase
            controller.application.purchases.forEach((element, i) => {
              if (element.id == purchase.id) {
                controller.application.purchases.splice(controller.application.purchases.indexOf(element), 1, purchase)
              }
            })
          }
          controller.getControllerByIdentifier("financials--products--purchases--add--parties").doPartiesFormHtml()
        } else {
          controller.nextBtnTarget.disabled = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }


  showProducts(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--products--purchases--add--products-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasNextBtnTarget) {
        if (controller.hasSourceInputTarget && controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.purchaseKindTarget.dataset.kind == `none`) {
          len += 1
        }

        if (controller.hasIntakeInputTarget && controller.channelInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasProductNameInputTarget && controller.productNameInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasProductPlanInputTarget && controller.productKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.hasProductServiceInputTarget && controller.productServiceInputTarget.innerText == ``) {
          len += 1
        }

        if (!controller.productAccrualDivTarget.classList.contains(`d-none`)) {
          if (controller.hasProductAccrualInputTarget && controller.productAccrualInputTarget.innerText == ``) {
            len += 1
          }
        }

        if (!controller.productPlanDivTarget.classList.contains(`d-none`)) {
          if (controller.hasProductPlanInputTarget && controller.productPlanInputTarget.innerText == ``) {
            len += 1
          }
        }

        if (len == 0) {
          controller.nextBtnTarget.disabled = false
        } else {
          controller.nextBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
