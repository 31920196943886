import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "productCard", "editBtn", "cancelBtn", "saveBtn", "product", "6666", "7777", "8888", "9999",
                    "statusDiv", "statusInputDiv", "statusInput",
                    "kindDiv", "kindInputDiv", "kindInput",
                    "startedAtDiv", "startedAtInputDiv", "startedAtInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "startedAtDiv", "startedAtInputDiv", "startedAtInput", "startedAtRow"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--entities--product`
  }

  doClinicCard() {
    // `<input data-${this.controllerName}-target="statusInput" class="form-control p-1 s-title-0p6rem" type="text" required>`
    
    if (this.application.permissions[this.permission].can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editProduct" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
    }

    var html = `<div class="card" data-${this.controllerName}-target="productCard">
                        <div class="card-header py-0">
                          <div class="row my-2"><div class="col f-bold">Informações do Produto</div></div>  
                        </div>
                        <div class="card-body py-0 px-2" style="overflow:auto;">
                          <div class="col-12 px-2">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              </tr>
                              <tr data-field="kind" data-${this.controllerName}-target="kindRow">
                                <th colspan="3" class="px-0 f-065 align-middle"><strong>Tipo</strong></th>
                                <td colspan="5" class="px-0 f-065 align-middle">
                                  <div data-${this.controllerName}-target="kindDiv">
                                    PJ ${this.application.clinic.kind_pretty}
                                  </div>
                                  <div class="d-none" data-${this.controllerName}-target="kindInputDiv">
                                    <div class="form-group ml-3 mb-0 w-100">
                                      <div class="floating-label floating-label-sm">
                                        <label>Status</label>
                                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                                            <span class="mr-auto w-100 selected-item f-065" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                            <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-kind="receivement" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Recebimento</li>
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-kind="practice" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Consultório</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td colspan="1" class="align-middle text-center" data-copy="${this.application.clinic.kind_pretty}">${copyPath}</td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                  ${cancel}
                                </td>
                              </tr>
                              <tr data-field="status" data-${this.controllerName}-target="statusRow">
                                <th colspan="3" class="px-0 f-065 align-middle"><strong>Status</strong></th>
                                <td colspan="5" class="px-0 f-065 align-middle">
                                  <div data-${this.controllerName}-target="statusDiv">
                                    ${this.application.clinic.status_pretty}
                                  </div>
                                  <div class="d-none" data-${this.controllerName}-target="statusInputDiv">
                                    <div class="form-group ml-3 mb-0 w-100">
                                      <div class="floating-label floating-label-sm">
                                        <label>Status</label>
                                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn">
                                            <span class="mr-auto w-100 selected-item f-065" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span>
                                          </button>
                                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                            <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-status="going" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-status="onboard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Onboard</li>
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-status="renewal" data-app--helpers--selector-target="select" class="li-selector dark f-065">Renovação</li>
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-status="blocked" data-app--helpers--selector-target="select" class="li-selector dark f-065">Bloqueado</li>
                                              <li data-action="click->${this.controllerName}#closeInput1 click->app--helpers--selector#select" data-status="cancel" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td colspan="1" class="align-middle text-center" data-copy="${this.application.clinic.status_pretty}">${copyPath}</td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                  ${cancel}
                                </td>
                              </tr>

                              <tr data-field="startedAt" data-${this.controllerName}-target="startedAtRow">
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>Início da Responsabilidade</strong> <br>
                                </th>
                                <td colspan="5" class="f-065 align-middle px-0 text-left">
                                  <div data-${this.controllerName}-target="startedAtDiv">
                                    ${this.application.clinic.started_at_pick_date}
                                  </div>
                                  <div class="d-none" data-${this.controllerName}-target="startedAtInputDiv">
                                    <input data-${this.controllerName}-target="startedAtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                  </div>
                                </td>
                                <td colspan="1" class="align-middle text-center"></td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                  ${cancel}
                                </td>
                              </tr>

                              <tr>
                                <td colspan="10" class="text-right">
                                  <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveProduct" data-${this.controllerName}-target="saveBtn">Salvar</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </div>
                      </div>`

    // this.productTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--dashboard`).productTarget.innerHTML = html)
    }).then(() => {
      controller.productCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.kindInputTarget.dataset.kind = controller.application.clinic.kind
      controller.statusInputTarget.dataset.status = controller.application.clinic.status
    })
  }
  
  editProduct(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "kind") {
      this.kindDivTarget.classList.add("d-none")
      this.kindInputDivTarget.classList.remove("d-none")
      this.kindDropdownTarget.value = this.kindDivTarget.innerText.trim()
      this.kindDropdownBtnTarget.value = this.kindDivTarget.innerText.trim()
      this.kindInputTarget.innerText = this.kindDivTarget.innerText.trim()
    } else if (this.field == "status") {
      this.statusDivTarget.classList.add("d-none")
      this.statusInputDivTarget.classList.remove("d-none")
      this.statusDropdownTarget.value = this.statusDivTarget.innerText.trim()
      this.statusDropdownBtnTarget.value = this.statusDivTarget.innerText.trim()
      this.statusInputTarget.innerText = this.statusDivTarget.innerText.trim()
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.add("d-none")
      this.tradeNameInputDivTarget.classList.remove("d-none")
      this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
      this.tradeNameInputTarget.focus()
    } else if (this.field == "startedAt") {
      this.startedAtDivTarget.classList.add("d-none")
      this.startedAtInputDivTarget.classList.remove("d-none")
      this.startedAtInputTarget.value = this.startedAtDivTarget.innerText.trim()
      this.startedAtInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.startedAtInputTarget), { max: false, months: true, years: 60 })
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "startedAt") {
      this.startedAtDivTarget.classList.remove("d-none")
      this.startedAtInputDivTarget.classList.add("d-none")
      this.startedAtDivTarget.innerText = this.application.clinic.started_at_pick_date
    } else if (this.field == "kind") {
      this.kindDivTarget.classList.remove("d-none")
      this.kindInputDivTarget.classList.add("d-none")
      this.kindDivTarget.innerText = `PJ ${this.application.clinic.kind_pretty}`
    } else if (this.field == "status") {
      this.statusDivTarget.classList.remove("d-none")
      this.statusInputDivTarget.classList.add("d-none")
      this.statusDivTarget.innerText = this.application.clinic.status_pretty
    }

  }

  closeInput1() {}

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.kindDivTarget.classList.contains("d-none")) {
      this.kindDivTarget.classList.remove("d-none")
      this.kindInputDivTarget.classList.add("d-none")
      this.kindDivTarget.innerText = this.kindInputTarget.innerText
    }

    if (this.statusDivTarget.classList.contains("d-none")) {
      this.statusDivTarget.classList.remove("d-none")
      this.statusInputDivTarget.classList.add("d-none")
      this.statusDivTarget.innerText = this.statusInputTarget.innerText
    }

    if (this.startedAtDivTarget.classList.contains("d-none")) {
      this.startedAtDivTarget.classList.remove("d-none")
      this.startedAtInputDivTarget.classList.add("d-none")
      this.startedAtDivTarget.innerText = this.startedAtInputTarget.value
    }

    // if (this.startedAtDivTarget.classList.contains("d-none")) {
    //   this.startedAtDivTarget.classList.remove("d-none")
    //   this.startedAtInputDivTarget.classList.add("d-none")
    //   this.startedAtDivTarget.innerText = this.startedAtInputTarget.value
    // }

    // if (this.sharesDivTarget.classList.contains("d-none")) {
    //   this.sharesDivTarget.classList.remove("d-none")
    //   this.sharesInputDivTarget.classList.add("d-none")
    //   this.sharesDivTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.sharesInputTarget.value)
    // }
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  saveProduct() {
    this.send_data = { current_user: {}, product: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.stopRefreshing()
      controller.send_data.current_user.current_user_id = controller.application.current_user.id
      controller.send_data.current_user.feature = `medclinic_regularizations`

      var startedAt = controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.startedAtInputTarget.value)
      var newStartedAt = new Date(startedAt)

      controller.send_data.product.id = controller.application.clinic.id
      controller.send_data.product.name = controller.application.clinic.name
      controller.send_data.product.kind = controller.kindInputTarget.dataset.kind
      controller.send_data.product.status = controller.statusInputTarget.dataset.status
      controller.send_data.product.started_at = startedAt
      controller.send_data.product.month_started_at = newStartedAt.getMonth() + 1
      controller.send_data.product.year_started_at = newStartedAt.getFullYear()

      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/operations/products/entities/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var clinic = response.data.cln
          if (clinic.kind != controller.application.clinic.kind) {
            window.open(clinic.product_path, `_self`)
          } else {
            controller.application.clinic = clinic
          }

        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.kindInputTarget.classList.contains("d-none") && controller.kindInputTarget.value == "") {
        len += 1
      }

      if (controller.statusInputTarget.classList.contains("d-none") && controller.statusInputTarget.value == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}