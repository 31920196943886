import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "resume", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--dashboard`
  }

  doRemunerationsDashboard() {
    // `<div class="col-2 px-1" data-${this.controllerName}-target="resume" data-controller="financials--books--payrolls--remunerations--resume"></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-3 pr-1" data-${this.controllerName}-target="list" data-controller="financials--books--payrolls--remunerations--contracts"></div>
                  
                  <div class="col-9 pl-1" data-${this.controllerName}-target="view" data-controller="financials--books--payrolls--remunerations--save financials--books--payrolls--remunerations--list"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payrolls--dashboard--submenu`).dashboardToggleBtn(`REMUNERAÇÕES`)

      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--contracts`).permission = `financial_payroll_remunerations`
      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--contracts`).doContractList()

      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--list`).permission = `financial_payroll_remunerations`
      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--list`).doNoContractHtml()
      
      // controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--resume`).doNoContractHtml()
      
      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).current_date = controller.application.current_date
      controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).permission = `financial_payroll_remunerations`
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
