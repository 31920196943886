import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "bodyTable", "streetInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "mainCheckbox",
                    "cancelBtn", "saveBtn", "zipInput", "numberInput", "complementInput", "districtInput", "cityInput", "stateInput",
                    "cityDropdown", "cityDropdownBtn", "cityFilter", "cityList", "stateDropdown", "stateDropdownBtn", "stateFilter", "stateList",
                    "ibgeInput"]

  connect() {
    this.controllerName = `users--accounts--contacts--address`
    this.current_address = {}
    this.send_data = { current_user: {}, record: {}, address: {} }
    this.doTableHtml()
  }

  cancelForm() {
    this.doTableHtml()
    this.stopRefreshing()
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
  }

  edit(ev) {
    this.address_list.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_address = element
      }
    });
    this.actionMode = "edit"
    this.formHtml()
  }

  save() {

    if (this.actionMode == "edit") { this.send_data.address.id = this.current_address.id }
    this.send_data.address.postal_code = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value.trim())
    this.send_data.address.street = this.streetInputTarget.value.trim()
    this.send_data.address.number = this.numberInputTarget.value.trim()
    this.send_data.address.complement = this.complementInputTarget.value.trim()
    this.send_data.address.district = this.districtInputTarget.value.trim()
    // this.send_data.address.city = this.cityInputTarget.dataset.city
    // this.send_data.address.city = this.cityInputTarget.dataset.filter
    this.send_data.address.city = this.cityInputTarget.value.trim()
    // this.send_data.address.state = this.stateInputTarget.dataset.state
    this.send_data.address.state = this.stateInputTarget.dataset.filter
    this.send_data.address.country = `Brasil`
    this.send_data.address.ibge = this.ibgeInputTarget.value.trim()
    this.send_data.address.kind = this.kindInputTarget.dataset.kind
    this.send_data.address.is_main = this.mainCheckboxTarget.checked
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permissions.feature_name
    this.send_data.record.account_id = this.application.account_contact.account_id
    this.send_data.record.company_id = this.application.account_contact.company_id
    this.send_data.record.record_type = this.application.account_contact.record_type

    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/accounts/addresses/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/accounts/addresses/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var address = response.data.cln
          if (controller.actionMode == "new") {
            controller.address_list[controller.address_list.length] = address
          } else if (controller.actionMode == "edit") {
            if (address.active) {
              controller.address_list.forEach((element, i) => {
                if (element.id == address.id) {
                  controller.address_list.splice(controller.address_list.indexOf(element), 1, address)
                }
              })
            } else {
              controller.address_list.forEach((element, i) => {
                if (element.id == address.id) {
                  controller.address_list.splice(controller.address_list.indexOf(element), 1)
                }
              })
            }
          }
          controller.doTableHtml()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  formHtml() {
    this.stopRefreshing()
    var html = `<table class="table table-sm table-borderless my-0" style="font-size:80%;">
                  <tbody>
                    <tr class="itemRow">
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    </tr>
                    <tr class="itemRow">
                      <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="zipInput">CEP</label>
                          <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask focus->${this.controllerName}#searchZip blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                        </div>
                      </td>
                      <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label>Tipo</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                              <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="kindList">
                                <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle pl-4">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex align-items-center mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="checkAddressMain" data-${this.controllerName}-target="mainCheckbox">
                          <label class="custom-control-label f-065 pointer" for="checkAddressMain"></label>
                          <span class="mc-tooltiptext">É o endereço principal?</span>
                        </div>
                      </td>
                      <td colspan="1" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelForm" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Cancelar"><span aria-hidden="true">&times;</span></button>
                      </td>
                    </tr>
                    <tr class="itemRow">
                      <td colspan="8" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="streetInput">Logradouro</label>
                          <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                        </div>
                      </td>
                      <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="numberInput">Número</label>
                          <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                        </div>
                      </td>
                      
                    </tr>
                    <tr class="itemRow">
                      <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="complementInput">Complemento</label>
                          <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                        </div>
                      </td>
                      <td colspan="6" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="districtInput">Bairro</label>
                          <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                        </div>
                      </td>
                    </tr>
                    <tr class="itemRow">
                      <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="stateForm">UF</label>
                          <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                          <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                            <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                            <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                            <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                            <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                            <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                            <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                            <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                            <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                            <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                            <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                            <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                            <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                            <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                            <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                            <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                            <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                            <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                            <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                            <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                            <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                            <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                            <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                            <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                            <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                            <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                            <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                            <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                          </ul>
                        </div>
                      </td>
                      <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="cityForm">Cidade</label>
                          <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                          <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                        </div>
                      </td>
                      <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                        <div class="floating-label floating-label-sm">
                          <label for="ibgeInput">IBGE</label>
                          <input id="ibgeInput" data-${this.controllerName}-target="ibgeInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="10" class="text-right px-0 pt-0">
                        <button disabled type="button" class="btn btn-primary btn-sm f-065 py-2" data-action="click->${this.controllerName}#save" data-${this.controllerName}-target="saveBtn">Salvar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>`

                // `<div class="floating-label floating-label-sm">
                //   <label for="cityInput">Cidade</label>
                //   <input id="cityInput" data-${this.controllerName}-target="cityInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                // </div>`

                // `<div class="floating-label floating-label-sm">
                //   <label>Cidade</label>
                //   <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="cityDropdown">
                //     <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="cityDropdownBtn">
                //       <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="cityInput"></span>
                //     </button>
                //     <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                //       <input class="form-control form-control-selector dropdown-search-input f-065" type="text" data-app--helpers--search-target="searchInput data-action="keyup->app--helpers--search#doSearchList" placeholder="Buscar ...">
                //       <ul class="ul-select" data-${this.controllerName}-target="cityList" data-app--helpers--search-target="searchList"></ul>
                //     </div>
                //   </div>
                // </div>`

                // `<div class="floating-label floating-label-sm">
                //   <label for="stateInput">UF</label>
                //   <input id="stateInput" data-${this.controllerName}-target="stateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                // </div>`

                // `<div class="floating-label floating-label-sm">
                //   <label>UF</label>
                //   <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="stateDropdown">
                //     <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="stateDropdownBtn">
                //       <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="stateInput"></span>
                //     </button>
                //     <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                //       <input class="form-control form-control-selector f-065" type="text" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" placeholder="Buscar ...">
                //       <ul class="ul-select" data-${this.controllerName}-target="stateList" data-app--helpers--search-target="searchList">
                //         <li data-state="MG" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MG</li>
                //         <li data-state="SP" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SP</li>
                //         <li data-state="AC" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AC</li>
                //         <li data-state="AL" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AL</li>
                //         <li data-state="AP" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AP</li>
                //         <li data-state="AM" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AM</li>
                //         <li data-state="BA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">BA</li>
                //         <li data-state="CE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">CE</li>
                //         <li data-state="DF" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">DF</li>
                //         <li data-state="ES" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">ES</li>
                //         <li data-state="GO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">GO</li>
                //         <li data-state="MA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MA</li>
                //         <li data-state="MT" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MT</li>
                //         <li data-state="MS" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MS</li>
                //         <li data-state="PA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PA</li>
                //         <li data-state="PB" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PB</li>
                //         <li data-state="PR" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PR</li>
                //         <li data-state="PE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PE</li>
                //         <li data-state="PI" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PI</li>
                //         <li data-state="RJ" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RJ</li>
                //         <li data-state="RN" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RN</li>
                //         <li data-state="RS" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RS</li>
                //         <li data-state="RO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RO</li>
                //         <li data-state="RR" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RR</li>
                //         <li data-state="SC" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SC</li>
                //         <li data-state="SE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SE</li>
                //         <li data-state="TO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">TO</li>
                //       </ul>
                //     </div>
                //   </div>
                // </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.streetInputTarget.disabled = true
      controller.districtInputTarget.disabled = true
      // controller.stateDropdownBtnTarget.disabled = true
      // controller.cityDropdownBtnTarget.disabled = true
      controller.stateInputTarget.disabled = true
      controller.cityInputTarget.disabled = true
      
      if (controller.actionMode == "new") {

      } else if (controller.actionMode == "edit") {
        
        controller.zipInputTarget.value = controller.current_address.postal_code_pretty
        controller.streetInputTarget.value = controller.current_address.street
        controller.numberInputTarget.value = controller.current_address.number
        controller.complementInputTarget.value = controller.current_address.complement
        controller.districtInputTarget.value = controller.current_address.district
        // controller.cityDropdownTarget.value = controller.current_address.city
        // controller.cityInputTarget.innerText = controller.current_address.city
        // controller.cityInputTarget.dataset.city = controller.current_address.city
        controller.ibgeInputTarget.value = controller.current_address.ibge
        controller.cityInputTarget.value = controller.current_address.city
        controller.cityInputTarget.dataset.filter = controller.current_address.filter
        // controller.stateDropdownTarget.value = controller.current_address.state
        // controller.stateInputTarget.innerText = controller.current_address.state
        // controller.stateInputTarget.dataset.state = controller.current_address.state
        controller.stateInputTarget.value = controller.current_address.state
        controller.stateInputTarget.dataset.filter = controller.current_address.state

        controller.kindInputTarget.dataset.kind = controller.current_address.kind
        controller.kindInputTarget.innerText = controller.current_address.kind_pretty
        controller.kindDropdownTarget.value = controller.current_address.kind_pretty
        controller.mainCheckboxTarget.checked = controller.current_address.is_main
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // $("#cardContactBody").stop().animate({ scrollTop: $("#cardContactBody")[0].scrollHeight }, 500);
    })
  }


  doTableHtml() {
    var html = `<div class="table-responsive">
                  <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody data-${this.controllerName}-target="bodyTable">
                    </tbody>
                  </table>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.listData(controller.address_list)
    })
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="px-0 align-middle text-left" style="font-size:80%;">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-065">Sem endereço cadastrado</span>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.rowTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  rowTablePartial(element, length) {
    if (this.permissions.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">file_copy</i></button>`
    } else {
      var copyPath = ``
    }

    if (this.permissions.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#edit" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="left" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.is_main) {
      var mainStatus = `<span style="color:#26C485;"><i class="fas fa-check-double" data-toggle="tooltip" data-placement="left" title data-original-title="E-mail Principal"></i></span>`
    } else {
      var mainStatus = ``
    }

    var rowHtml = `<tr>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    
                  </tr>
                  <tr class="itemRow" data-id="${element.id}">
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">CEP</td>
                    <td colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.postal_code_pretty}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle" data-copy="${element.postal_code_pretty}">${copyPath}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${mainStatus}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>
                  <tr>
                    <td colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.street}</td>
                    <td colspan="2" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.number}</td>
                    <td colspan="2" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.complement}</td>
                  </tr>
                  <tr>
                    <td colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.district}</td>
                    <td colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.city}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${element.state}</td>
                  </tr>
                  <tr>
                    <td colspan="7" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  </tr>`

    return rowHtml
  }

  zipMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    // this.stateDropdownBtnTarget.disabled = false
    // this.cityDropdownBtnTarget.disabled = false
    this.stateInputTarget.disabled = false
    this.cityInputTarget.disabled = false

    this.send_data.address.postal_code = ``
    this.send_data.address.street = ``
    this.send_data.address.number = ``
    this.send_data.address.complement = ``
    this.send_data.address.district = ``
    this.send_data.address.city = ``
    this.send_data.address.state = ``
    this.send_data.address.country = ``
    this.send_data.address.ibge = ``
  }

  closeForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = true
    this.districtInputTarget.disabled = true
    // this.stateDropdownBtnTarget.disabled = true
    // this.cityDropdownBtnTarget.disabled = true
    this.stateInputTarget.disabled = true
    this.cityInputTarget.disabled = true

    this.send_data.address.postal_code = ``
    this.send_data.address.street = ``
    this.send_data.address.number = ``
    this.send_data.address.complement = ``
    this.send_data.address.district = ``
    this.send_data.address.city = ``
    this.send_data.address.state = ``
    this.send_data.address.country = ``
    this.send_data.address.ibge = ``
  }

  searchZip(ev) {
    
    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
                controller.send_data.address.street = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
                controller.send_data.address.district = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                // controller.stateDropdownTarget.value = dados.uf
                // controller.stateInputTarget.innerText = dados.uf
                // controller.stateInputTarget.dataset.state = dados.uf
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
                controller.send_data.address.state = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                // controller.cityDropdownTarget.value = dados.localidade
                // controller.cityInputTarget.innerText = dados.localidade
                // controller.cityInputTarget.dataset.city = dados.localidade
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
                controller.send_data.address.city = dados.localidade
              }
              if (dados.ibge === "") {
                controller.ibgeInputTarget.disabled = false
              } else {
                controller.ibgeInputTarget.value = dados.ibge
                controller.send_data.address.ibge = dados.ibge
              }
              controller.send_data.address.country = `Brasil`
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        this.cleanForm();
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    } else if (ev.type == "focus" && this.zipInputTarget.value.length < 10) {
      this.closeForm();
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          })
          this.cityFilterTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }
  
  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.streetInputTarget.value == "") {
        len += 1
      }
      if (controller.zipInputTarget.value == "") {
        len += 1
      }
      if (controller.numberInputTarget.value == "") {
        len += 1
      }
      if (controller.districtInputTarget.value == "") {
        len += 1
      }
      if (controller.cityInputTarget.value == "") {
        len += 1
      }
      if (controller.stateInputTarget.value == "") {
        len += 1
      }
      if (controller.kindInputTarget.innerText == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
