import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "titleInput", "bodyInput", "saveBtn", "idNameInput", "idNumberInput",
    "dateInput", "bodyInput", "bodyInputTrix"]


  connect() {
    this.controllerName = `users--works--notes--save`
    this.send_data = { current_user: {}, note: {} }
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.dashboardController.context.identifier == `users--works--notes--save-check-modal`) {
      this.dashboardController.close()
    } else {
      this.getControllerByIdentifier("users--works--notes--index").clearList()
      this.getControllerByIdentifier("users--works--notes--index").doDataTable()
    }
  }

  saveNote() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.note.id = this.current_note.id
    } else {
      this.send_data.note.account_id = this.note_board.account_id
      this.send_data.note.domain_id = this.note_board.domain_id
      this.send_data.note.domain_type = this.note_board.domain_type
      this.send_data.note.domain_name = this.note_board.domain_name
      this.send_data.note.date_id = this.note_board.date_id
      this.send_data.note.date_type = this.note_board.date_type
      this.send_data.note.obj_id = this.note_board.obj_id
      this.send_data.note.obj_type = this.note_board.obj_type


      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      if (this.actionMode == `new`) {
        this.send_data.note.kind = `minutes`
      } else if (this.actionMode == `check`) {
        this.send_data.note.kind = `check`
      }
    }

    this.send_data.note.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.send_data.note.title = this.titleInputTarget.value
    this.send_data.note.body = this.bodyInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {

    if (this.actionMode == `new` || this.actionMode == `check`) {
      var url = "/users/accounts/notes/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/accounts/notes/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var note = response.data.cln
          if (controller.actionMode == `new` || this.actionMode == `check`) {
            controller.application.notes[controller.application.notes.length] = note
          } else if (controller.actionMode == `edit`) {
            if (note.active) {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1, note)
                }
              })
            } else {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1)
                }
              })
            }
          }
          controller.send_data = { current_user: {}, note: {} }
          controller.cancelSave()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065 py-2" data-action="click->${this.controllerName}#saveNote" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input disabled aria-describedby="dateFormHelp" class="form-control form-valid-control" id="dateForm" data-filter-mode="simple" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="titleForm">Título</label>
                            <input aria-describedby="titleFormHelp" class="form-control form-valid-control" id="titleForm" data-${this.controllerName}-target="titleInput" placeholder="Título" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <input aria-describedby="notesFormHelp" type="hidden" class="form-control" id="notesForm" placeholder="Texto" data-${this.controllerName}-target="bodyInput">
                            <trix-editor input="notesForm" data-trix="true" data-field="description" class="trix-content trix-med textarea p-1 f-075 trix-editor-notes" rows="15" placeholder="Faça aqui as anotações ... " data-${this.controllerName}-target="bodyInputTrix"></trix-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065 py-2" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {

      // `<label for="notesForm">Anotações</label>
      // <textarea aria-describedby="notesFormHelp" rows="20" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="bodyInput" placeholder="Anotações" type="text" required></textarea>`

      controller.formCardTarget.style.height = ($(window).height() * 0.55) + "px"
      var date = new Date()
      var year = 0

      if (controller.application.current_date) {
        year = controller.application.current_date.year
      } else {
        year = date.getFullYear()
      }

      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dateInputTarget), "yearly", year)
      controller.refreshSaveBtn()

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Anotação`
        controller.setDate()
      } else if (controller.actionMode == `check`) {
        controller.formTitleTarget.innerText = controller.title
        controller.setDate()
        if (controller.noteTitle) {
          controller.titleInputTarget.value = controller.noteTitle
        } else {
          controller.titleInputTarget.value = `Check`
        }
        controller.dateInputTarget.disabled = true
        controller.titleInputTarget.disabled = true
      } else {
        controller.formTitleTarget.innerText = `Editando Anotação`
        controller.dateInputTarget.value = controller.current_note.date_pretty
        controller.titleInputTarget.value = controller.current_note.title
        controller.bodyInputTarget.value = controller.current_note.body
        controller.bodyInputTrixTarget.value = controller.current_note.body
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setDate() {
    var date = new Date()

    var newDate = this.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
    this.dateInputTarget.value = newDate
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.hasDateInputTarget && controller.dateInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasTitleInputTarget && controller.titleInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasBodyInputTarget && controller.bodyInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}