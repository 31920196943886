import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "addNewPurchaseBtn",
                    "indicators", "toSendContractCard", "sentContractCard", "signedContractCard", "toSendInvoiceCard", "sentInvoiceCard",
                    "paidInvoiceCard"]

  connect() {
    this.controllerName = `financials--products--purchases--dashboard--index`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `started_at_to_time` }
    this.hasFilter = false
    this.filter = ``
    this.reload = false
    this.application.purchases = []
    this.calculation = {}
    this.application.permissions = {}
    this.tableRow = 11
  }

  addNewPurchase() {
    var html = `<div class="row">
                  <div class="col-12" data-controller="financials--products--purchases--add--dashboard" data-financials--products--purchases--add--dashboard-target="main"></div>
                </div>`

    this.getControllerByIdentifier(`financials--products--purchases--dashboard--main`).contentTarget.innerHTML = html
  }

  editPurchase(ev) {
    var purchase = {}
    
    this.application.purchases.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        purchase = element
      }
    })

    var html = `<div class="row">
                  <div class="col-12" data-controller="financials--products--purchases--add--dashboard" data-financials--products--purchases--add--dashboard-target="main"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--products--purchases--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.application.purchase = purchase
      controller.application.parties = purchase.parties
    })
  }


  doPageGrid() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row" data-${this.controllerName}-target="indicators">
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                </div>
                <div class="row my-3">
                  <div class="col-12 pl-2 pr-2" data-${this.controllerName}-target="list">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--products--purchases--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    // if (this.application.permissions[this.permission].can_create) {
    //   var addLine = `<span data-action="click->${this.controllerName}#addNewPurchase" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Contratação</span>`
    // } else {
    //   var addLine = ``
    // }
    
    var addLine = `<span data-action="click->${this.controllerName}#addNewPurchase" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Contratação</span>`
    var refreshLine = `<span data-action="click->${this.controllerName}#allPurchases" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar as Contratações</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-0 f-065">
                    <h6 class="card-title display-4 py-0 mb-0 f-085">Todas as Contratações</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle">
                                  Início
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">
                                  Partes Envolvidas
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Jornada
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center">
                                  Valor
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="amount" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="amount" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center">
                                  Tipo
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Produto
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Serviço
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Tipo/Plano
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_kind_plan_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_kind_plan_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Canal
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Gerador
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="generator_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="generator_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Próximo Ticket
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableRow, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_purchases = []
    if (this.hasFilter) {
      this.application.purchases.forEach(element => {
        if (element.journey_status == this.filter) {
          current_purchases[current_purchases.length] = element
        }
      })
    } else {
      current_purchases = this.application.purchases
    }

    if (this.sort.mode == `asc`) {
      var purchases = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_purchases, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var purchases = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_purchases, this.sort.field)
    }

    if (purchases.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(purchases.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (purchases[x] !== undefined) {
            internPage[internPage.length] = purchases[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: purchases.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableRow}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contratações em Andamento</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.purchaseTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  purchaseTablePartial(element) {
    element.name = ``
    element.parties.forEach(party => {
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="purchasePartyName">${party.party_name}</p>`
    })

    // if (this.application.permissions.financial_purchase_entities.can_update && [`to_send_contract`, `sent_contract`].includes(element.journey_status)) {
    if (this.application.permissions.financial_purchase_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editPurchase" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.next_ticket.has_ticket) {
      if (element.next_ticket.delay) {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-danger">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      } else {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-dark">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      }
    } else {
      var nextTicket = `Não há Próximo Ticket`
    }

    var purchasePath = `<button data-action="click->${this.controllerName}#goToShowPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Ir para Contratação</span>
                        </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.id}" data-slug="${element.slug}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">${element.journey_status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_accrual_name_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_service_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_kind_plan_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.channel_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.generator_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${nextTicket}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToShowPage(ev) {
    var token = ev.target.closest(".itemRow").dataset.slug
    // window.open(`/a/contratacoes/${token}`, `_self`)
    window.open(`/a/contratacoes/${token}`, `_blank`)
  }

  doIndicatorsHtml() {
    var html = `<div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="to_send_contract" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="toSendContractCard">
                      <span>Contratos a Enviar</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_to_send_contract}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="sent_contract" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="sentContractCard">
                      <span>Contratos Enviados</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_sent_contract}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="signed_contract" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="signedContractCard">
                      <span>Contratos Assinados</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_signed_contract}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="to_send_invoice" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="toSendInvoiceCard">
                      <span>Faturas a Enviar</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_to_send_invoice}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="sent_invoice" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="sentInvoiceCard">
                      <span>Faturas Enviadas</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_sent_invoice}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="paid_invoice" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="paidInvoiceCard">
                      <span>Faturas Pagas</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_paid_invoice}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.indicatorsTarget.innerHTML = html
  }

  stageFilter(ev) {
    var stage = ev.currentTarget.dataset.stage
    this.filter = stage
    this.hasFilter = true
    this.doDataTable()

    if (stage == `to_send_contract`) {
      this.toSendContractCardTarget.classList.add("bg-primary")
      this.sentContractCardTarget.classList.remove("bg-primary")
      this.signedContractCardTarget.classList.remove("bg-primary")
      this.toSendInvoiceCardTarget.classList.remove("bg-primary")
      this.sentInvoiceCardTarget.classList.remove("bg-primary")
      this.paidInvoiceCardTarget.classList.remove("bg-primary")
    } else if (stage == `sent_contract`) {
      this.toSendContractCardTarget.classList.remove("bg-primary")
      this.sentContractCardTarget.classList.add("bg-primary")
      this.signedContractCardTarget.classList.remove("bg-primary")
      this.toSendInvoiceCardTarget.classList.remove("bg-primary")
      this.sentInvoiceCardTarget.classList.remove("bg-primary")
      this.paidInvoiceCardTarget.classList.remove("bg-primary")
    } else if (stage == `signed_contract`) {
      this.toSendContractCardTarget.classList.remove("bg-primary")
      this.sentContractCardTarget.classList.remove("bg-primary")
      this.signedContractCardTarget.classList.add("bg-primary")
      this.toSendInvoiceCardTarget.classList.remove("bg-primary")
      this.sentInvoiceCardTarget.classList.remove("bg-primary")
      this.paidInvoiceCardTarget.classList.remove("bg-primary")
    } else if (stage == `to_send_invoice`) {
      this.toSendContractCardTarget.classList.remove("bg-primary")
      this.sentContractCardTarget.classList.remove("bg-primary")
      this.signedContractCardTarget.classList.remove("bg-primary")
      this.toSendInvoiceCardTarget.classList.add("bg-primary")
      this.sentInvoiceCardTarget.classList.remove("bg-primary")
      this.paidInvoiceCardTarget.classList.remove("bg-primary")
    } else if (stage == `sent_invoice`) {
      this.toSendContractCardTarget.classList.remove("bg-primary")
      this.sentContractCardTarget.classList.remove("bg-primary")
      this.signedContractCardTarget.classList.remove("bg-primary")
      this.toSendInvoiceCardTarget.classList.remove("bg-primary")
      this.sentInvoiceCardTarget.classList.add("bg-primary")
      this.paidInvoiceCardTarget.classList.remove("bg-primary")
    } else if (stage == `paid_invoice`) {
      this.toSendContractCardTarget.classList.remove("bg-primary")
      this.sentContractCardTarget.classList.remove("bg-primary")
      this.signedContractCardTarget.classList.remove("bg-primary")
      this.toSendInvoiceCardTarget.classList.remove("bg-primary")
      this.sentInvoiceCardTarget.classList.remove("bg-primary")
      this.paidInvoiceCardTarget.classList.add("bg-primary")
    }
  }

  allPurchases() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Atualizando Contratações` })
    this.hasFilter = false
    this.filter = ``
    this.reload = true
    this.getControllerByIdentifier(`financials--products--purchases--dashboard--main`).getPurchases()

    // this.toSendContractCardTarget.classList.remove("bg-primary")
    // this.sentContractCardTarget.classList.remove("bg-primary")
    // this.signedContractCardTarget.classList.remove("bg-primary")
    // this.toSendInvoiceCardTarget.classList.remove("bg-primary")
    // this.sentInvoiceCardTarget.classList.remove("bg-primary")
    // this.paidInvoiceCardTarget.classList.remove("bg-primary")
  }

  goToDashboard() {
    this.doPageGrid()
    this.doCalculation()
    this.doIndexListHtml()
  }

  goToRecords() {
    var url = `/a/todas-contratacoes`
    window.open(url, `_self`)
  }

  doCalculation() {
    this.calculation.total_to_send_contract = 0
    this.calculation.setup_to_send_contract = 0
    this.calculation.monthly_to_send_contract = 0
    this.calculation.yearly_to_send_contract = 0
    this.calculation.total_sent_contract = 0
    this.calculation.setup_sent_contract = 0
    this.calculation.monthly_sent_contract = 0
    this.calculation.yearly_sent_contract = 0
    this.calculation.total_signed_contract = 0
    this.calculation.setup_signed_contract = 0
    this.calculation.monthly_signed_contract = 0
    this.calculation.yearly_signed_contract = 0
    this.calculation.total_to_send_invoice = 0
    this.calculation.setup_to_send_invoice = 0
    this.calculation.monthly_to_send_invoice = 0
    this.calculation.yearly_to_send_invoice = 0
    this.calculation.total_sent_invoice = 0
    this.calculation.setup_sent_invoice = 0
    this.calculation.monthly_sent_invoice = 0
    this.calculation.yearly_sent_invoice = 0
    this.calculation.total_paid_invoice = 0
    this.calculation.setup_paid_invoice = 0
    this.calculation.monthly_paid_invoice = 0
    this.calculation.yearly_paid_invoice = 0

    this.application.purchases.forEach(purchase => {
      if (purchase.journey_status == `to_send_contract`) {
        this.calculation.total_to_send_contract += 1
        this.calculation.setup_to_send_contract += Number(purchase.total_setup)
        this.calculation.monthly_to_send_contract += Number(purchase.total_monthly)
        this.calculation.yearly_to_send_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `sent_contract`) {
        this.calculation.total_sent_contract += 1
        this.calculation.setup_sent_contract += Number(purchase.total_setup)
        this.calculation.monthly_sent_contract += Number(purchase.total_monthly)
        this.calculation.yearly_sent_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `signed_contract`) {
        this.calculation.total_signed_contract += 1
        this.calculation.setup_signed_contract += Number(purchase.total_setup)
        this.calculation.monthly_signed_contract += Number(purchase.total_monthly)
        this.calculation.yearly_signed_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `to_send_invoice`) {
        this.calculation.total_to_send_invoice += 1
        this.calculation.setup_to_send_invoice += Number(purchase.total_setup)
        this.calculation.monthly_to_send_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_to_send_invoice += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `sent_invoice`) {
        this.calculation.total_sent_invoice += 1
        this.calculation.setup_sent_invoice += Number(purchase.total_setup)
        this.calculation.monthly_sent_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_sent_invoice += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `paid_invoice`) {
        this.calculation.total_paid_invoice += 1
        this.calculation.setup_paid_invoice += Number(purchase.total_setup)
        this.calculation.monthly_paid_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_paid_invoice += Number(purchase.total_yearly)
      }
      
    })

    this.doIndicatorsHtml()
  }


  // to_send_contract
  // sent_contract
  // signed_contract
  // to_send_invoice
  // sent_invoice
  // paid_invoice
  // finished
  // cancel

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
