import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--dashboard--dates`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doDatesPreloader()
  }

  changeDate(ev) {
    var id = ev.currentTarget.dataset.id
    this.setFinancialDate(id)
    this.toggleDateBtn(id)
  }

  setFinancialDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        this.application.current_due_date = element
        this.application.current_payable_compilation = element.payable_compilation
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.getControllerByIdentifier("financials--books--payables--dashboard--main").reload = true
    this.getControllerByIdentifier("financials--books--payables--dashboard--main").loadDateData()
    // this.getControllerByIdentifier("financials--books--payables--dashboard--main").doSubtitle()
    this.getControllerByIdentifier("financials--books--payables--dashboard--submenu").doBadgeCounts()

  }

  runDashboardsHtml() {
    this.cleanDashboardForms()
    this.runDashboardHtml()
    this.runPayableHtml()
    this.runBillHtml()
    this.runLoanHtml()
    this.runAnnualHtml()
    this.runTicketHtml()
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("financials--books--payables--dashboard--main").hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--dashboard--main").doMainDashboard()
    }
  }

  runPayableHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--entities--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard()
    }
  }

  runBillHtml() {
    if (this.getControllerByIdentifier(`financials--books--cards--bills--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--cards--bills--dashboard").doBillsDashboard()
    }
  }

  runLoanHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--loans--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--loans--dashboard").doLoansDashboard()
    }
  }

  runAnnualHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--annual--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--annual--dashboard").doPayablesDashboard()
    }
  }

  runTicketHtml() {
    if (this.getControllerByIdentifier(`financials--books--payables--tickets--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("financials--books--payables--tickets--dashboard").doTicketsDashboard()
    }
  }

  cleanDashboardForms() {
    // if (this.getControllerByIdentifier("financials--sales--opportunities--entities--save")) {
    //   this.getControllerByIdentifier("financials--sales--opportunities--entities--save").cancelSave()
    // }

    // if (this.getControllerByIdentifier("financials--sales--leads--entities--save")) {
    //   this.getControllerByIdentifier("financials--sales--leads--entities--save").cancelSave()
    // }
  }

  setCurrents() {
    // this.application.regimes.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     this.application.current_regime = element
    //   }
    // })

    // this.application.main_apurations.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     this.application.current_main_apuration = element
    //   }
    // })
  }

  doDatesHTML() {
    var datesHtml = ``
    var currentDates = []
    var today = new Date()

    // this.application.dates.forEach(element => {
    //   if (today.getFullYear() > element.year) {
    //     currentDates[currentDates.length] = element
    //   } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
    //     currentDates[currentDates.length] = element
    //   }
    // });

    this.application.dates.forEach(element => {
      // if (this.application.current_user.super_admin) {
      //   datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065 mc-tooltip" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">
      //                   ${element.date_pretty}
      //                   <span class="mc-tooltiptext mc-tooltiptext-bottom">ID ${element.id}</span>
      //                 </button>`
      // } else {
      //   datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
      // }
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    })

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${this.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)

      }
    });
  }

  doDatesPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
