import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--oncall`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    }
  }

  doHtml() {
    var link = this.siteController.setLink(this.application.site.oncall_link_cta)

    var html = `<section class="mws-section-oncall ws-bg-circle d-flex justify-content-center align-items-center mt-5">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex align-items-center text-center col-12 my-3">
                        <h1 class="mws-title-action mx-auto">${this.application.site.oncall_title}</h1>
                      </div>
                      <div class="d-flex align-items-center text-center col-12 my-3">
                        <p class="mws-text-action">
                           ${this.application.site.oncall_description}
                        </p>
                      </div>
                      <div class="d-flex align-items-center text-center col-12 my-3">
                        <a class="ws-btn-action mws-btn-action mt-5 pointer mx-auto" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.oncall_cta}</a>
                      </div>
                      <div class="col-12 d-flex justify-content-center align-items-center my-5">
                        <img src="${this.application.site.oncall_image}" width="300" alt="">
                      </div>
                      
                    </div>
                  </div>
                </section>`

    this.siteController.contentTarget.insertAdjacentHTML("beforeend", html)
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
