import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "companyCard", "editBtn", "cancelBtn", "saveBtn", "nameDiv", "nameInputDiv", "nameInput", "main", "9999",
                    "tradeNameDiv", "tradeNameInputDiv", "tradeNameInput", "municipalDiv", "municipalInputDiv", "municipalInput",
                    "municipalNumberRow", "municipalNumberDiv", "municipalNumberInputDiv", "municipalNumberInput",
                    "openedAtRow", "openedAtDiv", "openedAtInputDiv", "openedAtInput", "clinicCheckbox", "takerCheckbox"]


  connect() {
    this.controllerName = `users--companies--entities--principal`
    this.current_company = this.getControllerByIdentifier(`users--companies--entities--show`).current_company
    this.doClinicCard()
  }

  doClinicCard() {

    if (this.application.permissions.company_entities.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editCompany" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-2">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>

                        </tr>
                        <tr data-field="tradeName" data-${this.controllerName}-target="tradeNameRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Razão Social</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="tradeNameDiv">
                              ${this.current_company.trade_name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="tradeNameInputDiv">
                              <input data-${this.controllerName}-target="tradeNameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="name" data-${this.controllerName}-target="nameRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Nome Fantasia</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="nameDiv">
                              ${this.current_company.name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                              <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        
                        <tr>
                          <th colspan="3" class="f-065 align-middle px-0">
                            <strong>CNPJ</strong> <br>
                          </th>
                          <td colspan="7" class="f-065 align-middle px-0 text-left">
                            <span class="mb-0 f-065 mc-tooltip pointer">
                              <span data-controller="app--helpers--copy" data-copy="${this.current_company.cnpj}" data-action="click->app--helpers--copy#copy">${this.current_company.cnpj_pretty}</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                            </span>
                          </td>
                        </tr>

                        <tr data-field="municipalNumber" data-${this.controllerName}-target="municipalNumberRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Inscrição Municipal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="municipalNumberDiv">
                              ${this.current_company.municipal_number}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="municipalNumberInputDiv">
                              <input data-${this.controllerName}-target="municipalNumberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="openedAt" data-${this.controllerName}-target="openedAtRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Abertura</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="openedAtDiv">
                              ${this.current_company.opened_at_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="openedAtInputDiv">
                              <input data-${this.controllerName}-target="openedAtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          <th colspan="5" class="f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="clinicCheck" data-${this.controllerName}-target="clinicCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="clinicCheck">Prestador de Serviços</label>
                              <span class="mc-tooltiptext">É Prestador de Serviços?</span>
                            </div>
                          </th>
                          <th colspan="5" class="px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="takerCheck" data-${this.controllerName}-target="takerCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="takerCheck">Tomador</label>
                              <span class="mc-tooltiptext">É Tomador de Serviços?</span>
                            </div>
                          </th>
                        </tr>

                        <tr>
                          <td colspan="10" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveCompany" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      if (this.current_company.kind.includes(`clinic`)) {
        controller.clinicCheckboxTarget.checked = true
      } else {
        controller.clinicCheckboxTarget.checked = false
      }
      if (this.current_company.kind.includes(`taker`)) {
        controller.takerCheckboxTarget.checked = true
      } else {
        controller.takerCheckboxTarget.checked = false
      }
    })
    
  }

  editCompany(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.add("d-none")
      this.tradeNameInputDivTarget.classList.remove("d-none")
      this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
      this.tradeNameInputTarget.focus()
    
    } else if (this.field == "municipalNumber") {
      this.municipalNumberDivTarget.classList.add("d-none")
      this.municipalNumberInputDivTarget.classList.remove("d-none")
      this.municipalNumberInputTarget.value = this.municipalNumberDivTarget.innerText.trim()
      this.municipalNumberInputTarget.focus()

    // } else if (this.field == "sex") {
    //   this.sexDivTarget.classList.add("d-none")
    //   this.sexInputDivTarget.classList.remove("d-none")
    //   this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
    //   this.sexInputTarget.focus()
    // } else if (this.field == "shares") {
    //   this.sharesDivTarget.classList.add("d-none")
    //   this.sharesInputDivTarget.classList.remove("d-none")
    //   this.sharesInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.sharesDivTarget.innerText.trim())
    //   this.sharesInputTarget.focus()
    } else if (this.field == "openedAt") {
      this.openedAtDivTarget.classList.add("d-none")
      this.openedAtInputDivTarget.classList.remove("d-none")
      this.openedAtInputTarget.value = this.openedAtDivTarget.innerText.trim()
      this.openedAtInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.openedAtInputTarget), { max: false, months: true, years: 60 })
    // } else if (this.field == "tradeName") {
    //   this.tradeNameDivTarget.classList.add("d-none")
    //   this.tradeNameInputDivTarget.classList.remove("d-none")
    //   this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
    //   this.tradeNameInputTarget.focus()
    // } else if (this.field == "fantasyName") {
    //   this.fantasyNameDivTarget.classList.add("d-none")
    //   this.fantasyNameInputDivTarget.classList.remove("d-none")
    //   this.fantasyNameInputTarget.value = this.fantasyNameDivTarget.innerText.trim()
    //   this.fantasyNameInputTarget.focus()
    // } else if (this.field == "cnpj") {
    //   this.cnpjDivTarget.classList.add("d-none")
    //   this.cnpjInputDivTarget.classList.remove("d-none")
    //   this.cnpjInputTarget.value = this.cnpjDivTarget.innerText.trim()
    //   this.cnpjInputTarget.focus()
    // }
    }
  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.current_company.name
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.current_company.trade_name
    
    // } else if (this.field == "fantasyName") {
    //   this.fantasyNameDivTarget.classList.remove("d-none")
    //   this.fantasyNameInputDivTarget.classList.add("d-none")
    //   this.fantasyNameDivTarget.innerText = this.current_company.fantasy_name
    // } else if (this.field == "cpf") {
    //   this.cpfDivTarget.classList.remove("d-none")
    //   this.cpfInputDivTarget.classList.add("d-none")
    //   this.cpfDivTarget.innerText = this.application.clinic.id_number_pretty
    // } else if (this.field == "cnpj") {
    //   this.cnpjDivTarget.classList.remove("d-none")
    //   this.cnpjInputDivTarget.classList.add("d-none")
    //   this.cnpjDivTarget.innerText = this.application.clinic.id_number_pretty
    } else if (this.field == "municipalNumber") {
      this.municipalNumberDivTarget.classList.remove("d-none")
      this.municipalNumberInputDivTarget.classList.add("d-none")
      this.municipalNumberDivTarget.innerText = this.application.clinic.municipal_number
    // } else if (this.field == "sex") {
    //   this.sexDivTarget.classList.remove("d-none")
    //   this.sexInputDivTarget.classList.add("d-none")
    //   this.sexDivTarget.innerText = this.application.clinic.account_sex_pretty
    // } else if (this.field == "shares") {
    //   this.sharesDivTarget.classList.remove("d-none")
    //   this.sharesInputDivTarget.classList.add("d-none")
    //   this.sharesDivTarget.innerText = this.application.clinic.shares
    } else if (this.field == "openedAt") {
      this.openedAtDivTarget.classList.remove("d-none")
      this.openedAtInputDivTarget.classList.add("d-none")
      this.openedAtDivTarget.innerText = this.application.clinic.opened_at_pretty
    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.tradeNameDivTarget.classList.contains("d-none")) {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.tradeNameInputTarget.value
    }

    if (this.nameDivTarget.classList.contains("d-none")) {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.nameInputTarget.value
    }

    if (this.openedAtDivTarget.classList.contains("d-none")) {
      this.openedAtDivTarget.classList.remove("d-none")
      this.openedAtInputDivTarget.classList.add("d-none")
      this.openedAtDivTarget.innerText = this.openedAtInputTarget.value
    }

    if (this.municipalNumberDivTarget.classList.contains("d-none")) {
      this.municipalNumberDivTarget.classList.remove("d-none")
      this.municipalNumberInputDivTarget.classList.add("d-none")
      this.municipalNumberDivTarget.innerText = this.municipalNumberInputTarget.value
    }

    // if (this.sharesDivTarget.classList.contains("d-none")) {
    //   this.sharesDivTarget.classList.remove("d-none")
    //   this.sharesInputDivTarget.classList.add("d-none")
    //   this.sharesDivTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.sharesInputTarget.value)
    // }
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  saveCompany() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true
    this.send_data = { current_user: {}, company: {}, kind: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.current_user.feature = `company_entities`
      controller.send_data.kind.kind = this.current_company.kind

      controller.send_data.company.id = this.current_company.id
      controller.send_data.company.trade_name = controller.tradeNameDivTarget.innerText.trim()
      controller.send_data.company.name = controller.nameDivTarget.innerText.trim()
      controller.send_data.company.municipal_number = controller.municipalNumberDivTarget.innerText.trim()
      controller.send_data.company.opened_at = controller.getControllerByIdentifier("app--helpers--date").transformFullDate(controller.openedAtDivTarget.innerText.trim())

      if (controller.clinicCheckboxTarget.checked && controller.takerCheckboxTarget.checked) {
        controller.send_data.company.kind = [`taker`, `clinic`]
      } else if (controller.clinicCheckboxTarget.checked) {
        controller.send_data.company.kind = [`clinic`]
      } else if (controller.takerCheckboxTarget.checked) {
        controller.send_data.company.kind = [`taker`]
      }

      controller.stopRefreshing()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/users/companies/entities/update_entity"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var company = response.data.cln
          controller.current_company.name = company.name
          controller.current_company.trade_name = company.trade_name
          controller.current_company.municipal_number = company.municipal_number
          controller.current_company.opened_at = company.opened_at
          controller.current_company.opened_at_pretty = company.opened_at_pretty

          controller.application.companies.forEach((element, i) => {
            if (element.id == company.id) {
              controller.application.companies.splice(controller.application.companies.indexOf(element), 1, company)
            }
          })

        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
        len += 1
      }

      if (controller.tradeNameInputTarget.classList.contains("d-none") && controller.tradeNameInputTarget.value == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}