
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "loader", "menuCard", "menuRows"]

  connect() {
    this.controllerName = `web--commercial--marketing--events--publics--index`
    this.feature = `next`
  }

  btnGoLive(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  btnGoEnrollment(ev) {
    var url = ev.target.dataset.url
    if (url.includes("http")) {
      window.open(url, '_blank')
    } else if (url.includes("www")) {
      window.open('https://' + url, '_blank')
    }
  }

  getEvents() {
    var data = { event: { sharing: `public` } }
    var url = "/commercial/marketing/events/entities/public_list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.loaderTargets.forEach(loader => {
          loader.remove()
        })

        controller.events = response.data.cln
        controller.doDataTable()
      })
  }

  doDataTable() {
    var events = []

    if (this.feature == `next`) {
      this.events.forEach(element => {
        if (element.finished == false) {
          events[events.length] = element
        }
      })

      events = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(events, `date_to_time`)
    } else if (this.feature == `done`) {
      this.events.forEach(element => {
        if (element.finished) {
          events[events.length] = element
        }
      })

      events = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(events, `date_to_time`)
    }


    this.listData(events)
  }

  listData(data) {
    this.listTarget.innerHTML = ``
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="row d-flex align-items-center justify-content-center py-3" style="">
                      <div class="col-12 w-90">
                        <div class="card-invisible">
                          <div class="card-body">
                            <div class="row d-flex align-items-center">
                              
                              <div class="col-12">
                                <strong>
                                  Não há Eventos em Destaque
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`

      this.listTarget.innerHTML = noData
    } else {
      data.forEach(event => {
        this.listTarget.insertAdjacentHTML("beforeend", this.eventPartial(event))
      })
    }
  }

  eventPartial(event) {
    if (!event.blocked) {
      var actionBtn = `<button type="button" class="btn btn-primary btnGoLive w-100 mb-3" data-url="${event.live_path}" data-action="click->${this.controllerName}#btnGoLive">
                        <i class="material-icons md-sm md-light" style="margin-right: 0.5rem;">cast_for_education</i>
                        Acessar Evento
                      </button>
                      <button type="button" class="btn btn-primary w-100 btnGoEnrollment" data-url="${event.enrollment_link}" data-action="click->${this.controllerName}#btnGoEnrollment">
                        Link Inscrição
                      </button>`
    } else if (event.finished) {
      var actionBtn = `<button class="btn btn-lg btn-primary mb-3 w-100 not-event" disabled>
                          Evento Finalizado, assista no Descanso Médico
                        </button>
                        <button type="button" class="btn btn-primary w-100 btnGoEnrollment" data-url="${event.enrollment_link}" data-action="click->${this.controllerName}#btnGoEnrollment">
                          Link Inscrição
                        </button>`
    } else if (event.published) {
      var actionBtn = `<button class="btn btn-lg btn-primary mb-3 w-100 not-event" disabled>
                          Não Iniciado
                        </button>
                        <button type="button" class="btn btn-primary w-100 btnGoEnrollment" data-url="${event.enrollment_link}" data-action="click->${this.controllerName}#btnGoEnrollment">
                          Link Inscrição
                        </button>`
    }

    var partialHtml = `<div class="row d-flex align-items-center justify-content-center py-3" style="">
                          <div class="col-12 w-90">
                            <div class="card-invisible">
                              <div class="card-body">
                                <div class="row d-flex align-items-center">
                                  <div class="col-lg-3 col-sm-12">
                                    <img class="card-img-event mb-4" src="${event.cover_url}"  class="rounded float-left" alt="${event.title}" width="4rem;">
                                  </div>
                                  <div class="col-lg-6 col-sm-12">
                                    <strong>
                                      ${event.date_pretty}
                                    </strong>
                                    <h1 class="event-title mb-2">
                                      <strong>             
                                        ${event.title}
                                      </strong>
                                    </h1>
                                    <p class="text-center">
                                      ${event.description}
                                    </p>
                                  </div>
                                  <div class="col-lg-3 col-sm-12 text-center">
                                    ${actionBtn}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>`

    return partialHtml
  }

  doMainHtml() {
    var mainHtml = `<section>
                      <div class="row my-5 py-5" id="eventNavbar"></div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                        </div>
                      </div>
                    </section>

                    <section class="frame-white mb-5">
                      <div class="row d-flex align-items-center justify-content-center py-5">
                        <div class="col-10 text-center">
                          <h1 class="display-4 s-title-053B5E s-title-3rem s-title-bold">
                            A Plataforma de Ensino da MedCapital
                          </h1>
                          <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                            Veja abaixo os aulas e cursos disponíveis em nossa plataforma e clique para acessar
                          </p>
                        </div>
                      </div>
                      <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 px-2">
                          <div class="card bg-primary pointer" data-${this.controllerName}-target="nextCard menuCard" data-feature="next" data-action="click->${this.controllerName}#toggleCard">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1 mb-0 f-075"><strong>Eventos em Destaque</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 px-2">
                          <div class="card pointer" data-${this.controllerName}-target="doneCard menuCard" data-feature="done" data-action="click->${this.controllerName}#toggleCard">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1 mb-0 f-075"><strong>Eventos Realizados</strong></h6>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="row my-3">
                        <div class="col-12 px-2">
                          <div class="col-12 text-center" data-${this.controllerName}-target="list"></div>
                        </div>
                      </div>
                    </section>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`).contentTarget.insertAdjacentHTML("beforeend", mainHtml))
    }).then(() => {
      controller.eventPreloader()
      controller.getEvents()
    })
  }


  toggleCard(ev) {
    var element = ev.currentTarget
    this.feature = element.dataset.feature

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    // this.menuRowsTargets.forEach(element => {
    //   element.classList.add(`d-none`)
    // })

    element.classList.add(`bg-primary`)
    // this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)
    this.doDataTable()
  }

  eventPreloader() {
    var htmlPreloader = ``

    for (var i = 0; i < 3; i++) {
      htmlPreloader += `<div class="row d-flex align-items-center justify-content-center py-3" data-${this.controllerName}-target="loader">
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body">
                                <div class="row d-flex align-items-center">
                                  <div class="col-4">
                                    <div class='timeline-item'>
                                      <div class='animated-background animated-background-20'>
                                        <div class='background-masker title'></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div class='timeline-item'>
                                      <div class='animated-background animated-background-20'>
                                        <div class='background-masker title'></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <div class='timeline-item'>
                                      <div class='animated-background animated-background-20'>
                                        <div class='background-masker title'></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`
    }

    this.listTarget.innerHTML = htmlPreloader
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
