import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dates", "calculations", "listOffices", "showOffice", "listSignatures", "showSignature", "mainDashboard", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--settings--dashboard`
  }

  doSettingsDashboard() {
    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
    } else {
      var datesHtml = ``
    }
    
    var datesHtml = `<div class="col-4 pr-1" data-${this.controllerName}-target="dates" data-controller="operations--products--bookings--settings--dates"></div>`
    var calculationHtml = `<div class="col-8 pr-1" data-${this.controllerName}-target="calculations" data-controller="operations--products--bookings--settings--calculations"></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  ${datesHtml}
                  ${calculationHtml}
                </div>`
    this.getControllerByIdentifier("operations--products--bookings--entities--show").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--bookings--settings--dashboard"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
