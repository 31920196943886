import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["main", "content", "mainCard", "cardBody", "footerTable", "checkboxProcess", "cardTitle",
										"documentationBtn", "inProcessBtn", "draftBtn", "transmittBtn", "rectificationBtn",
										"enquiryBtn", "waitingBtn", "saveBtn", "uploadArea", "uploadBtn", "fileUpload", "fileDraftName",
										"statementDropdown", "statementDropdownBtn", "statementInput", "statementList", "uploadBtn",
										"progressUpload", "progressUploadBar", "progressUploadCounter", "fileEnquiryName", "cancelTicketBtn",
										"labelFilingUpload", "fileFilingName", "labelReceiptUpload", "fileReceiptName", "saveTicketBtn",
										"startBtn", "addMoreBtn", "transmissionBtn", "actionArea", "ticketDraftBtn", "bodyEditInput",
										"branchCode", "accountNumber", "installmentData", "installments", "approvedDraftCard", "xxxx",
										"paymentForm", "debtCheckbox", "darfCheckbox", "bankData", "bankCode", "bankCodeFilter",
										"darfDiv", "labelDarfUpload", "fileDarfName", "fileBookingName", "labelDraftUpload", "labelBookingUpload",
										"refundForm", "bankCheckbox", "pixCheckbox", "pixData", "pixCode", "xxxx"]


	connect() {
		this.controllerName = `operations--products--tax-filings--journey--save`
		this.upload_files = []
		this.filesPermittedTypes = ["pdf", "png", "jpeg", "DEC", "REC", ".DEC", ".REC", "PDF", "PNG", "JPEG"]
		this.uploadReady = false
	}

	setCurrentSummary() {
		this.application.summaries.forEach(element => {
			if (element.date_id == this.application.current_date.id) {
				this.current_summary = element
			}
		})
	}

	doJourneyStatus() {
		var btnClass = `col-12 mc-tooltip text-center px-0 mb-3`
		if (this.application.current_user.account_kind == `doctor`) {
			var html = `<div class="row">
										<div class="col-2 px-1">
											<div class="row">
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToDocumentationSent" data-${this.controllerName}-target="startBtn">
													<span class="material-icons md-dark md-300 pointer">slideshow</span>
													<span class="mc-tooltiptext">Liberar Documentação</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToAwaitingDocumentation" data-${this.controllerName}-target="addMoreBtn">
													<span class="material-icons md-dark md-300 pointer">pause_presentation</span>
													<span class="mc-tooltiptext">Adicionar Mais Documentos</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToAwaitingTransmission" data-${this.controllerName}-target="transmissionBtn">
													<span class="material-icons md-dark md-300 pointer">check_circle_outline</span>
													<span class="mc-tooltiptext">Aprovar Rascunho</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#ticketDraftHtml" data-${this.controllerName}-target="ticketDraftBtn">
													<span class="material-icons md-dark md-300 pointer">rule</span>
													<span class="mc-tooltiptext">Gerar Pendência</span>
												</div>
											</div>
										</div>
										<div class="col-10" data-${this.controllerName}-target="actionArea"></div>
									</div>`
		} else if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
			// apagar botão Aprovar Rascunho para time
			var html = `<div class="row">
										<div class="col-2 px-1">
											<div class="row">
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToAwaitingDocumentation" data-${this.controllerName}-target="documentationBtn">
													<span class="material-icons md-dark md-300 pointer">note_add</span>
													<span class="mc-tooltiptext">Pedir Documentação</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToInProcess" data-${this.controllerName}-target="inProcessBtn">
													<span class="material-icons md-dark md-300 pointer">play_circle_outline</span>
													<span class="mc-tooltiptext">Processar Documentação</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToDraftSent" data-${this.controllerName}-target="draftBtn">
													<span class="material-icons md-dark md-300 pointer">drafts</span>
													<span class="mc-tooltiptext">Enviar Rascunho</span>
												</div>


												<div class="${btnClass}" data-action="click->${this.controllerName}#goToAwaitingTransmission" data-${this.controllerName}-target="transmissionBtn">
													<span class="material-icons md-dark md-300 pointer">check_circle_outline</span>
													<span class="mc-tooltiptext">Aprovar Rascunho</span>
												</div>


												<div class="${btnClass}" data-action="click->${this.controllerName}#goToTransmitted" data-${this.controllerName}-target="transmittBtn">
													<span class="material-icons md-dark md-300 pointer">done</span>
													<span class="mc-tooltiptext">Transmitir</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToRetification" data-${this.controllerName}-target="rectificationBtn">
													<span class="material-icons md-dark md-300 pointer">done_all</span>
													<span class="mc-tooltiptext">Retificar</span>
												</div>
												<div class="${btnClass}" data-action="click->${this.controllerName}#goToEnquiry" data-${this.controllerName}-target="enquiryBtn">
													<span class="material-icons md-dark md-300 pointer">screen_search_desktop</span>
													<span class="mc-tooltiptext">Consultar</span>
												</div>
											</div>
										</div>
										<div class="col-10" data-${this.controllerName}-target="actionArea"></div>
										<div class="col-10" data-${this.controllerName}-target="uploadArea">
											<div class="row">
											</div>
										</div>
									</div>`
		}
		
		var controller = this
		new Promise(function (resolve) {
			resolve(controller.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").saveTarget.innerHTML = html)
		}).then(() => {
			controller.setCurrentSummary()
			if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {

				// apagar botão Aprovar Rascunho para time
				controller.actionAreaTarget.style.height = ($(window).height() * 0.55) + "px"

				controller.uploadAreaTarget.style.height = ($(window).height() * 0.55) + "px"
				controller.doTeamJourneyProcess()
			} else if (this.application.current_user.account_kind == `doctor`) {
				controller.actionAreaTarget.style.height = ($(window).height() * 0.55) + "px"
				controller.doDoctorJourneyProcess()
				controller.journeyInfoHtml()
			}
		})
	}

	journeyInfoHtml() {
		if (this.last_journey.status == `draft_sent`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p>O Rascunho da sua <strong>Declaração de Imposto de Renda ${this.application.current_date.year + 1}</strong> está pronto!</p>
											<p>É indispensável os seguintes items:</p>
											<ol>
												<li>Verificar as <strong>fontes pagadoras</strong> e o <strong>pagamentos dedutíveis</strong>;</li>
												<li>Verificar a <strong>evolução patrimonial</strong> no campo de <strong>bens e direitos</strong>;</li>
												<li>Conferir os <strong>dados cadastrais</strong> na primeira página.</li>
											</ol>
											<p>Após a conferência, caso tenha algum ajuste a ser feito, nos notifique.</p>
											<p>Ao contrário, aguardamos sua autorização para transmitir clicando no botão ao lado.</p>
										</div>
									</div>`
		} else if (this.last_journey.status == `awaiting_transmission`) {
			var html = `<div class="row">
									<div class="col-12 px-1">
										<p>A sua declaração já está na <strong>fila de transmissão</strong>, assim for transmitido, você será notificado por e-mail.</p>
									</div>
								</div>`
		} else if (this.last_journey.status == `transmitted`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p>A sua declaração já <strong>foi Transmitida</strong>!</p>
											<p>É indispensável os seguintes items:</p>
											<ol>
												<li>Declaração de IRPF ${this.application.current_date.year + 1}</li>
												<li>Recibo de entrega da Declaração de IRPF ${this.application.current_date.year + 1}</li>
											</ol>
										</div>
									</div>`
		} else if (this.last_journey.status == `enquiry`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p>Fizemos uma <strong>consulta na Receita Federal</strong> sobre a sua declaração, veja o resultado no arquivo anexo.</p>
										</div>
									</div>`
		} else if (this.last_journey.status == `awaiting_documentation`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p>Estamos <strong>aguardando você enviar</strong> as suas informações para a Declaração de IRPF ${this.application.current_date.year + 1}</p>
										</div>
									</div>`
		} else if (this.last_journey.status == `documentation_sent`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p><strong>Recebemos as suas informações</strong> e em breve começaremos a sua Declaração de IRPF ${this.application.current_date.year + 1}</p>
										</div>
									</div>`
		} else if (this.last_journey.status == `in_process`) {
			var html = `<div class="row">
										<div class="col-12 px-1">
											<p><strong>Começamos a processar</strong> a sua Declaração de IRPF ${this.application.current_date.year + 1}.</p>
											<p>Qualquer pendência, a enviaremos por aqui na plataforma e você será notificado no seu email.</p>
										</div>
									</div>`
		} else {
			var html = ``
		}
		

		this.actionAreaTarget.innerHTML = html
	}

	doDoctorJourneyProcess() {
		if (this.last_journey.status == `awaiting_documentation`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `documentation_sent`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `in_process`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `draft_sent`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `awaiting_transmission`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `transmitted`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `enquiry`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `rectification`) {
			this.startBtnTarget.classList.add("d-none")
			this.addMoreBtnTarget.classList.add("d-none")
			this.transmissionBtnTarget.classList.add("d-none")
			this.ticketDraftBtnTarget.classList.add("d-none")
		}
	}

	doTeamJourneyProcess() {
		if (this.last_journey.status == `awaiting_documentation`) {
			this.inProcessBtnTarget.classList.add("d-none")
			this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.enquiryBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `documentation_sent`) {
			// this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.enquiryBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `in_process`) {
			this.inProcessBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.enquiryBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `draft_sent`) {
			// this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			// temporally change
			// this.transmittBtnTarget.classList.add("d-none")
			if (this.application.current_tax_filing_calculation.transmitted) {
				this.transmittBtnTarget.classList.add("d-none")
				this.rectificationBtnTarget.classList.remove("d-none")
			} else {
				this.transmittBtnTarget.classList.remove("d-none")
				this.rectificationBtnTarget.classList.add("d-none")
			}
			// this.rectificationBtnTarget.classList.add("d-none")
			this.enquiryBtnTarget.classList.add("d-none")

		} else if (this.last_journey.status == `awaiting_transmission`) {

			this.inProcessBtnTarget.classList.add("d-none")
			// this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			if (this.application.current_tax_filing_calculation.transmitted) {
				this.transmittBtnTarget.classList.add("d-none")
			} else {
				this.rectificationBtnTarget.classList.add("d-none")
			}
			this.enquiryBtnTarget.classList.add("d-none")

		} else if (this.last_journey.status == `transmitted`) {
			this.inProcessBtnTarget.classList.add("d-none")
			// this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `enquiry`) {
			this.inProcessBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
		} else if (this.last_journey.status == `rectification`) {
			this.inProcessBtnTarget.classList.add("d-none")
			// this.documentationBtnTarget.classList.add("d-none")
			this.draftBtnTarget.classList.add("d-none")
			this.rectificationBtnTarget.classList.add("d-none")
			this.transmittBtnTarget.classList.add("d-none")
		}
	}

	ticketDraftHtml() {
		var uploadTitle = `Pendências na Declaração IRPF ${this.application.current_date.year + 1}`

		var html = `<div class="row w-100 d-flex align-items-center mb-3">
										<div class="col-12 text-center">
											<h6>${uploadTitle}</h6>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 d-flex align-items-center" data-${this.controllerName}-target="bodyEdit">
											<textarea rows="7" autofocus data-${this.controllerName}-target="bodyEditInput" class="form-control p-1 f-085" type="text" required></textarea>
										</div>
										<div class="col-6 text-center my-3">
											<div class="card my-3 w-100 bg-secondary py-2 pointer" data-${this.controllerName}-target="cancelTicketBtn" data-action="click->${this.controllerName}#cancelTicket">
												<h5 class="my-auto f-1">Cancelar</h5>
											</div>
										</div>
										<div class="col-6 text-center my-3">
											<div class="card my-3 w-100 bg-primary py-2 pointer" data-${this.controllerName}-target="saveTicketBtn" data-action="click->${this.controllerName}#saveTicket">
												<h5 class="my-auto f-1">Salvar Pendência</h5>
											</div>
										</div>
									</div>`

		this.actionAreaTarget.innerHTML = html
	}

	saveTicket() {
		this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
		this.actionMode = `new`
		this.saveTicketBtnTarget.classList.add("d-none")
		this.send_data = { current_user: {}, ticket: {}, tracker: { manual: true } }

		this.send_data.ticket.board_id = this.application.tax_filing.id
		this.send_data.ticket.board_type = `operation_products`
		this.send_data.ticket.board_path = window.location.pathname
		this.send_data.ticket.board_name = `${this.application.current_tax_filing_calculation.name} ${this.application.tax_filing.account_name}`
		this.send_data.ticket.date_id = this.application.current_date.id
		this.send_data.ticket.date_type = `operation_dates`
		this.send_data.ticket.owner_id = this.application.current_user.account_id
		this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
		this.send_data.ticket.performer_id = this.application.current_agent.account_id
		this.send_data.ticket.performer_name = this.application.current_agent.account_name
		this.send_data.ticket.stage = `in_process`
		this.send_data.ticket.status = `in_time`
		this.send_data.ticket.sharing = `external`
		this.send_data.ticket.term = `quick`
		this.send_data.ticket.priority = `maximum`
		this.send_data.ticket.body = this.bodyEditInputTarget.value
		this.send_data.ticket.due_at = new Date()
		this.send_data.ticket.started_at = new Date()
		this.send_data.ticket.flag = `blue`
		this.send_data.ticket.kind = `task`

		this.send_data.tracker.record_id = this.application.tax_filing.id
		this.send_data.tracker.record_type = `operation_products`
		this.send_data.tracker.obj_id = this.application.current_date.id
		this.send_data.tracker.obj_type = `operation_dates`
		this.send_data.tracker.action = `created`
		this.send_data.tracker.observations = `Adicionou Ticket ${this.bodyEditInputTarget.value}`

		this.send_data.current_user.current_user_id = this.application.current_user.id
		this.send_data.current_user.feature = this.application.ticket_permission

		this.requestSaveTicket()
	}

	requestSaveTicket() {
		var url = `/users/works/activities/tickets/create`
		var method = "POST"

		const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				if (response.save) {
					var ticket = response.data.cln
					controller.application.tickets[controller.application.tickets.length] = ticket
				}

				controller.sendTicketEmailNotification(ticket.id)
				controller.cancelTicket()
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
			})
			.catch(error => {
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
			})
	}

	sendTicketEmailNotification(ticket_id) {
		this.send_data = { current_user: {}, notification: {} }

		this.send_data.current_user.current_user_id = this.application.current_user.id
		this.send_data.current_user.feature = this.application.ticket_permission

		this.send_data.notification.ticket_id = ticket_id
		this.send_data.notification.product_id = this.application.tax_filing.id
		this.send_data.notification.product_token = this.application.tax_filing.token
		this.send_data.notification.product_name = this.application.tax_filing.product_name
		this.send_data.notification.date_id = this.application.current_date.id
		this.send_data.notification.date_type = `operation_dates`
		this.send_data.notification.receiver_id = this.application.current_agent.account_id
		this.send_data.notification.receiver_name = this.application.current_agent.account_name
		this.send_data.notification.receiver_kind = `team`
		this.send_data.notification.sender_id = this.application.current_user.account_id
		this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
		this.send_data.notification.action = `adicionou a seguinte pendência no IRPF ${this.application.current_date.financial_year}`
		this.send_data.notification.body = this.bodyEditInputTarget.value

		var url = "/users/works/activities/tickets/send_email_notification"
		var method = "POST"

		const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
			})
			.catch(error => {
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
			})
	}

	cancelTicket() {
		this.journeyInfoHtml()
		// this.actionAreaTarget.innerHTML = ``
	}

	approvedDraftHtml() {

		var html = `<div class="row w-100 d-flex align-items-center mb-3">
									<div class="col-12 text-center">
										<div class="card card-no-shadow my-3">
											<h5 class="mb-0 f-085">Dados para ${this.current_summary.statement_pretty}</h5>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="paymentForm">
										<div class="col-12 d-flex my-3 px-0 justify-content-center">
											<div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
												<input type="checkbox" class="custom-control-input" id="paymentDebtCheck" data-form="debt" data-${this.controllerName}-target="debtCheckbox" data-action="click->${this.controllerName}#paymentCheck">
												<label class="custom-control-label f-085 ml-3" for="paymentDebtCheck">Débito</label>
											</div>
											<div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
												<input type="checkbox" class="custom-control-input" id="paymentDarfCheck" data-form="darf" data-${this.controllerName}-target="darfCheckbox" data-action="click->${this.controllerName}#paymentCheck">
												<label class="custom-control-label f-085 ml-3" for="paymentDarfCheck">Darf</label>
											</div>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="refundForm">
										<div class="col-12 d-flex my-3 px-0 justify-content-center">
											<div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
												<input type="checkbox" class="custom-control-input" id="refundDebtCheck" data-form="bank" data-${this.controllerName}-target="bankCheckbox" data-action="click->${this.controllerName}#refundCheck">
												<label class="custom-control-label f-085 ml-3" for="refundDebtCheck">Débito</label>
											</div>
											<div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
												<input type="checkbox" class="custom-control-input" id="refundPixCheck" data-form="pix" data-${this.controllerName}-target="pixCheckbox" data-action="click->${this.controllerName}#refundCheck">
												<label class="custom-control-label f-085 ml-3" for="refundPixCheck">PIX</label>
											</div>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="bankData">
										<div class="col-10 offset-1 text-center">
											<div class="form-group my-2">
												<div class="floating-label floating-label-md">
													<label class="f-085" for="bankCode">Banco</label>
													<input aria-describedby="bankCodeHelp" class="form-control f-085 w-100" id="bankCode" type="text" data-filter-mode="simple" data-${this.controllerName}-target="bankCode" data-action="focus->${this.controllerName}#bankFilter keyup->${this.controllerName}#bankFilter keyup->${this.controllerName}#enterBank blur->${this.controllerName}#hideList">
													<ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="bankCodeFilter">
												</div>
											</div>
										</div>
										<div class="col-10 offset-1 text-center">
											<div class="form-group my-2">
												<div class="floating-label floating-label-md">
													<label class="f-085" for="branchCode">Agência sem DV</label>
													<input aria-describedby="branchCodeHelp" class="form-control f-085 w-100" id="branchCode" type="tel" data-${this.controllerName}-target="branchCode">
												</div>
											</div>
										</div>
										<div class="col-10 offset-1 text-center">
											<div class="form-group my-2">
												<div class="floating-label floating-label-md">
													<label class="f-085" for="accountNumber">Conta Corrente com DV</label>
													<input aria-describedby="accountNumberHelp" class="form-control f-085 w-100" id="accountNumber" type="text" data-${this.controllerName}-target="accountNumber">
												</div>
											</div>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="pixData">
										<div class="col-10 offset-1 text-center">
											<div class="form-group my-2">
												<div class="floating-label floating-label-md">
													<label class="f-085" for="pixCode">PIX</label>
													<input disabled aria-describedby="pixCodeHelp" class="form-control f-085 w-100" id="pixCode" type="tel" data-${this.controllerName}-target="pixCode">
												</div>
											</div>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="installmentData">
										<div class="col-10 offset-1 text-center">
											<div class="form-group my-2">
												<div class="floating-label floating-label-md">
													<label class="f-085" for="installments">Parcelas (máximo 8 parcelas)</label>
													<input aria-describedby="installmentsHelp" class="form-control f-085 w-100" id="installments" type="tel" data-${this.controllerName}-target="installments">
												</div>
											</div>
										</div>
									</div>
									<div class="w-100 d-none" data-${this.controllerName}-target="approvedDraftCard">
										<div class="col-10 offset-1 text-center px-2">
											<div class="card my-3 w-100 bg-primary py-2 pointer" data-action="click->${this.controllerName}#approvedDraft">
												<h5 class="my-auto f-085 f-bold">Enviar Dados</h5>
											</div>
										</div>
									</div>
                </div>`

		var controller = this
		new Promise(function (resolve) {
			resolve(controller.actionAreaTarget.innerHTML = html)
		}).then(() => {
			controller.listBanks()
			if (controller.current_summary.statement == `to_pay`) {
				controller.paymentFormTarget.classList.remove("d-none")
			} else if (controller.current_summary.statement == `to_refund`) {
				controller.refundFormTarget.classList.remove("d-none")
				// controller.bankDataTarget.classList.remove("d-none")
				// controller.approvedDraftCardTarget.classList.remove("d-none")
			} else if (controller.current_summary.statement == `do_nothing`) {
				controller.approvedDraftCardTarget.classList.remove("d-none")
			}
		})
	}

	paymentCheck(ev) {
		var form = ev.currentTarget.dataset.form

		if (form == `debt`) {
			this.darfCheckboxTarget.checked = false
			this.debtCheckboxTarget.checked = true
			this.bankDataTarget.classList.remove("d-none")
		} else if (form == `darf`) {
			this.darfCheckboxTarget.checked = true
			this.debtCheckboxTarget.checked = false
			this.bankDataTarget.classList.add("d-none")
		}
		this.installmentDataTarget.classList.remove("d-none")
		this.installmentsTarget.value = 1
		this.approvedDraftCardTarget.classList.remove("d-none")
		this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
	}

	refundCheck(ev) {
		var form = ev.currentTarget.dataset.form

		if (form == `bank`) {
			this.pixCheckboxTarget.checked = false
			this.bankCheckboxTarget.checked = true
			this.bankDataTarget.classList.remove("d-none")
			this.pixDataTarget.classList.add("d-none")
		} else if (form == `pix`) {
			this.pixCheckboxTarget.checked = true
			this.bankCheckboxTarget.checked = false
			this.bankDataTarget.classList.add("d-none")
			this.pixDataTarget.classList.remove("d-none")

			this.pixCodeTarget.value = this.application.tax_filing.account_cpf_pretty
		}
		
		this.approvedDraftCardTarget.classList.remove("d-none")
		this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
	}

	listBanks() {
		var html = `<li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="001 - Banco do Brasil" data-filter="001 - Banco do Brasil">001 - Banco do Brasil</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="237 - Bradesco" data-filter="237 - Bradesco">237 - Bradesco</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="104 - Caixa Econômica Federal" data-filter="104 - Caixa Econômica Federal" class="li-selector dark f-065 text-left">104 - Caixa Econômica Federal</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="341 - Itaú" data-filter="341 - Itaú">341 - Itaú</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="033 - Santander" data-filter="033 - Santander">033 - Santander</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="260 - Nubank" data-filter="260 - Nubank">260 - Nubank</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="077 - Inter" data-filter="077 - Inter">077 - Inter</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="756 - Sicoob" data-filter="756 - Sicoob">756 - Sicoob</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="212 - Original" data-filter="212 - Original">212 - Original</li>
                <li class="li-selector dark f-085 text-left" data-action="click->${this.controllerName}#selectBank" data-text="Outro Informar por Email" data-filter="Outro Informar por Email">Outro Informar por Email</li>`

		this.bankCodeFilterTarget.innerHTML = html
	}

	bankFilter(ev) {
		this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
	}

	selectBank(ev) {
		this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
	}

	enterBank(ev) {
		if (ev.type == "keyup" && ev.key == "Enter") {
			this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
		}
	}

	hideList(ev) {
		// this.getControllerByIdentifier("app--helpers--input").hideList(ev)
	}

	onlyNumbers(ev) {
		this.getControllerByIdentifier("app--helpers--numbers").onlyNumbers(ev)
	}

	approvedDraft() {
		// 987543334
		if (this.current_summary.statement == `to_pay`) {
			if (this.debtCheckboxTarget.checked) {
				if (this.bankCodeTarget.value == ``) {
					alert(`O Banco não pode ficar em branco.`)
				} else if (this.branchCodeTarget.value == ``) {
					alert(`A Agência não pode ficar em branco.`)
				} else if (this.accountNumberTarget.value == ``) {
					alert(`O Número de Conta não pode ficar em branco.`)
				} else {
					var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
					if (r) {
						this.saveDoctorSummary()
					}
				}
			} else if (this.darfCheckboxTarget.checked) {
				if (this.installmentsTarget.value == ``) {
					alert(`A quantidade de parcelas não pode ficar em branco.`)
				} else {
					var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
					if (r) {
						this.saveDoctorSummary()
					}
				}
			}

		} else if (this.current_summary.statement == `to_refund`) {

			if (this.bankCheckboxTarget.checked) {
				if (this.bankCodeTarget.value == ``) {
					alert(`O Banco não pode ficar em branco.`)
				} else if (this.branchCodeTarget.value == ``) {
					alert(`A Agência não pode ficar em branco.`)
				} else if (this.accountNumberTarget.value == ``) {
					alert(`O Número de Conta não pode ficar em branco.`)
				} else {
					var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
					if (r) {
						this.saveDoctorSummary()
					}
				}	
			} else if (this.pixCheckboxTarget.checked) {
				if (this.pixCodeTarget.value == ``) {
					alert(`O PIX não pode ficar em branco.`)
				} else {
					var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
					if (r) {
						this.saveDoctorSummary()
					}
				}
			}
			
		} else if (this.current_summary.statement == `do_nothing`) {
			var r = confirm(`Você confirma a aprovação do Rascunho da Declaração IRPF ${this.application.current_date.financial_year} e confirma os dados?`)
			if (r) {
				this.saveDoctorSummary()
			}
		}
	}

	saveDoctorSummary() {
		this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
		this.send_summary_data = { summary: {}, current_user: {}, tracker: { manual: true } }
		this.approvedDraftCardTarget.classList.add("d-none")

		this.send_summary_data.tracker.record_id = this.application.tax_filing.id
		this.send_summary_data.tracker.record_type = `operation_products`
		this.send_summary_data.tracker.obj_id = this.application.current_date.id
		this.send_summary_data.tracker.obj_type = `operation_dates`
		this.send_summary_data.tracker.action = `updated`
		
		this.send_summary_data.summary.id = this.current_summary.id
		if (this.current_summary.statement == `to_pay`) {
			if (this.debtCheckboxTarget.checked) {
				this.send_summary_data.summary.form = `debt`
				this.send_summary_data.summary.bank_code = this.bankCodeTarget.value
				this.send_summary_data.summary.branch_code = this.branchCodeTarget.value
				this.send_summary_data.summary.account_number = this.accountNumberTarget.value
			} else if (this.darfCheckboxTarget.checked) {
				this.send_summary_data.summary.form = `darf`
			}
			this.send_summary_data.tracker.observations = `Aprovou o Rascunho e atualizou a forma de Pagamento do IRPF ${this.application.current_date.financial_year}`
			this.send_summary_data.summary.installments = this.installmentsTarget.value
		} else if (this.current_summary.statement == `to_refund`) {

			if (this.bankCheckboxTarget.checked) {
				this.send_summary_data.summary.form = `debt`
				this.send_summary_data.summary.bank_code = this.bankCodeTarget.value
				this.send_summary_data.summary.branch_code = this.branchCodeTarget.value
				this.send_summary_data.summary.account_number = this.accountNumberTarget.value
			} else if (this.pixCheckboxTarget.checked) {
				this.send_summary_data.summary.form = `pix`
				this.send_summary_data.summary.account_number = this.getControllerByIdentifier(`app--helpers--numbers`).fromCpfToNumber(this.pixCodeTarget.value)
			}

			this.send_summary_data.tracker.observations = `Aprovou o Rascunho e atualizou a forma de Restituição do IRPF ${this.application.current_date.financial_year}`
		} else if (this.current_summary.statement == `do_nothing`) {
			this.send_summary_data.summary.form = `none`
			this.send_summary_data.summary.bank_code = `Não se Aplica`
			this.send_summary_data.summary.branch_code = `Não se Aplica`
			this.send_summary_data.summary.account_number = `Não se Aplica`
			this.send_summary_data.tracker.observations = `Aprovou o Rascunho do IRPF ${this.application.current_date.financial_year}`
		}

		this.send_summary_data.current_user.current_user_id = this.application.current_user_id
		this.send_summary_data.current_user.feature = this.application.summary_permission

		const url = "/operations/products/tax_filings/summaries/update"
		const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_summary_data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				if (response.save) {
					var summary = response.data.cln
					controller.application.current_summary = summary
					controller.application.summaries.forEach(element => {
						if (element.id == summary.id) {
							controller.application.summaries.splice(controller.application.summaries.indexOf(element), 1, summary)
						}
					})
					controller.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
					controller.send_data.journey.status = `awaiting_transmission`
					controller.createNewJourney()
				}
			})
			.catch(error => {
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
			})
	}

	cancelSave() {
		this.stopRefreshing()
		if (this.hasAddJourneyCardTarget) {
				this.addJourneyCardTarget.remove()
		}
	}

	goToInProcess() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `in_process`
		var r = confirm(`Tem certeza que deseja Processar a Declaração?`)
		if (r) {
			this.createNewJourney()
		}
	}

	goToAwaitingDocumentation() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `awaiting_documentation`
		var r = confirm(`Tem certeza que deseja pedir nova documentação?`)
		if (r) {
			this.createNewJourney()
		}
	}

	// goToDraftSent() {
	// 	this.actionMode = `new`
	// 	this.uploadFile = false
	// 	this.send_data = { current_user: {}, journey: {}, notification: {} }
	// 	this.send_data.journey.status = `draft_sent`
	// 	this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status de Rascunho Enviado na Jornada do IRPF ${Number(this.application.current_date.year) + 1}`
	// 	this.doUploadHtml()
	// }

	goToDraftSent() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `draft_sent`

		var modalName = `operations--products--tax-filings--summaries--save`

		var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

		var controller = this
		new Promise(function (resolve) {
			resolve(document.body.insertAdjacentHTML("beforeend", html))
		}).then(() => {
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).journey_status = `draft_sent`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).title = `Rascunho IRPF ${this.application.current_date.financial_year} | ${this.application.tax_filing.account_name} CPF: ${this.application.tax_filing.account_cpf_pretty}`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
		})
	}

	requestDraftSent() {
		if (this.hasStatementInputTarget && this.statementInputTarget.innerText == ``) {
			alert(`Favor informar a Apuração Final: se há Pagamento de Imposto, Restituição de Imposto ou se não tem Ajuste.`)
		} else {
			var r = confirm(`Tem certeza que deseja enviar o Rascunho da Declaração?`)
			if (r) {
				this.uploadFile = true
				this.createNewJourney()
			}
		}
	}

	goToAwaitingTransmission() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, notification: {} }
		this.send_data.journey.status = `awaiting_transmission`
		this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status de Pedir Documentação na Jornada do IRPF ${Number(this.application.current_date.year) + 1}`
		this.approvedDraftHtml()
	}

	// goToTransmitted() {
	// 	this.actionMode = `new`
	// 	this.uploadFile = false
	// 	this.send_data = { current_user: {}, journey: {}, notification: {} }
	// 	this.send_data.journey.status = `transmitted`
	// 	this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status de Rascunho Enviado na Jornada do IRPF ${Number(this.application.current_date.year) + 1}`
	// 	this.doUploadHtml()
	// }

	goToTransmitted() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `transmitted`

		var modalName = `operations--products--tax-filings--summaries--save`

		var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

		var controller = this
		new Promise(function (resolve) {
			resolve(document.body.insertAdjacentHTML("beforeend", html))
		}).then(() => {
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).journey_status = `transmitted`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).title = `Transmissão IRPF ${this.application.current_date.financial_year} | ${this.application.tax_filing.account_name} CPF: ${this.application.tax_filing.account_cpf_pretty}`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
		})
	}

	requestTransmitted() {
		var r = confirm(`Tem certeza que deseja Transmitir a Declaração?`)
		if (r) {
			this.uploadFile = true
			this.createNewJourney()
		}
	}

	// goToEnquiry() {
	// 	this.actionMode = `new`
	// 	this.send_data = { current_user: {}, journey: {}, notification: {} }
	// 	this.send_data.journey.status = `enquiry`
	// 	this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status de Consulta de Processamento na Jornada IRPF ${Number(this.application.current_date.year) + 1}`
	// 	this.doUploadHtml()
	// }

	goToEnquiry() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `enquiry`

		var modalName = `operations--products--tax-filings--summaries--save`

		var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

		var controller = this
		new Promise(function (resolve) {
			resolve(document.body.insertAdjacentHTML("beforeend", html))
		}).then(() => {
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).journey_status = `enquiry`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).title = `Consulta IRPF ${this.application.current_date.financial_year} | ${this.application.tax_filing.account_name} CPF: ${this.application.tax_filing.account_cpf_pretty}`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
		})
	}

	requestEnquiry() {
		var r = confirm(`Tem certeza que deseja adicionar a Consulta de Processamento?`)
		if (r) {
			this.uploadFile = true
			this.createNewJourney()
		}
	}

	// goToRetification() {
	// 	this.actionMode = `new`
	// 	this.uploadFile = false
	// 	this.send_data = { current_user: {}, journey: {}, notification: {} }
	// 	this.send_data.journey.status = `rectification`
	// 	this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um novo Status de Retificação na Jornada do IRPF ${Number(this.application.current_date.year) + 1}`
	// 	this.doUploadHtml()
	// }

	goToRetification() {
		this.actionMode = `new`
		this.uploadFile = false
		this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
		this.send_data.journey.status = `rectification`

		var modalName = `operations--products--tax-filings--summaries--save`

		var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

		var controller = this
		new Promise(function (resolve) {
			resolve(document.body.insertAdjacentHTML("beforeend", html))
		}).then(() => {
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).journey_status = `rectification`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).title = `Retificação IRPF ${this.application.current_date.financial_year} | ${this.application.tax_filing.account_name} CPF: ${this.application.tax_filing.account_cpf_pretty}`
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
			controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
		})
	}

	requestRetification() {
		var r = confirm(`Tem certeza que deseja Retificar a Declaração?`)
		if (r) {
			this.uploadFile = true
			this.createNewJourney()
		}
	}

  createNewJourney() {
		if (this.uploadFile) {
			this.progressCount(0)
		}

		this.send_data.journey.tax_filing_id = this.application.tax_filing.id
		this.send_data.journey.date_id = this.application.current_date.id
		this.send_data.journey.date = new Date()

		var newStatus = this.getControllerByIdentifier(`operations--products--tax-filings--process--dashboard`).translateJourney(this.send_data.journey.status)
		this.send_data.tracker.record_id = this.application.tax_filing.id
		this.send_data.tracker.record_type = `operation_products`
		this.send_data.tracker.obj_id = this.application.current_date.id
		this.send_data.tracker.obj_type = `operation_dates`
		this.send_data.tracker.action = `changed`
		this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

		this.send_data.current_user.current_user_id = this.application.current_user.id
		this.send_data.current_user.feature = this.application.journey_permission

		this.requestSave()
	}

	requestSave() {
		var url = "/operations/products/tax_filings/journeys/create"
		var method = "POST"

		const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				if (response.save) {
					var journey = response.data.cln
					if (controller.actionMode == `new`) {
						controller.application.journeys[controller.application.journeys.length] = journey
					}
					if (controller.uploadFile) {
						controller.requestUpload(journey, this.upload_files)
					} else {
						controller.sendJourneyEmailNotification(journey)
						controller.doWatingBoardHtml()
					}
				}
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
			})
			.catch(error => {
				controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
			})
	}

	requestUpload(journey, files) {
		var i = 1
		var journey_id = journey.id
		files.forEach(element => {
			var formData = new FormData()
			formData.append('file', element.file)
			formData.append('field', element.field)
			formData.append('journey_id', journey_id)
			formData.append('journey_status', journey.status)
			formData.append('current_user_id', this.application.current_user.id)

			var url = "/operations/products/tax_filings/journeys/save_upload"
			var method = "PUT"
			const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

			var controller = this
			fetch(url, init)
				.then(response => response.json())
				.then(response => {
					if (response.save) {
						var journey = response.data.cln
						controller.application.journeys.forEach(element => {
							if (element.id == journey.id) {
								controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1, journey)
							}
						})
						if (i == files.length) {
							controller.progressCount(100)
							controller.stopRefreshing()
							controller.doWatingBoardHtml()
							controller.sendJourneyEmailNotification(journey)
						}
						controller.uploadSwitch(false)
						controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
						i += 1
					} else {
						controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
					}
				})
				.catch(error => {
					controller.getControllerByIdentifier("app--helpers--console").console(error)
					controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
				})
		})
	}

	sendJourneyEmailNotification(journey) {
		this.send_data = { current_user: {}, notification: {} }

		this.send_data.current_user.current_user_id = this.application.current_user.id
		this.send_data.current_user.feature = this.application.journey_permission

		this.send_data.notification.journey_id = journey.id
		this.send_data.notification.product_id = this.application.tax_filing.id
		this.send_data.notification.product_token = this.application.tax_filing.token
		this.send_data.notification.product_name = this.application.tax_filing.product_name
		this.send_data.notification.date_id = this.application.current_date.id
		this.send_data.notification.date_type = `operation_dates`
		this.send_data.notification.sender_id = this.application.current_user.account_id
		this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
		this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
		this.send_data.notification.body = journey.status_pretty

		if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
			this.send_data.notification.receiver_id = this.application.tax_filing.account_id
			this.send_data.notification.receiver_name = this.application.tax_filing.account_name
			this.send_data.notification.receiver_kind = `doctor`
		} else if (this.application.current_user.account_kind == `doctor`) {
			this.send_data.notification.receiver_id = this.application.current_agent.account_id
			this.send_data.notification.receiver_name = this.application.current_agent.account_name
			this.send_data.notification.receiver_kind = `team`
		}

		var url = "/operations/products/tax_filings/journeys/send_email_notification"
		var method = "POST"

		const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
			})
			.catch(error => {
				controller.getControllerByIdentifier("app--helpers--console").console(error)
				controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
			})
	}

	doWatingBoardHtml() {
		
		var html = `<div class="row mb-4">
                  <div class="col px-0 text-center" data-${this.controllerName}-target="waitingBtn">
                    <span class="f-065 text-bold">Aguarde um instante ... </span>
                    <span class="material-icons md-dark md-300">hourglass_empty</span>
                  </div>
                </div>`

		this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").saveTarget.innerHTML = html
	}


  doUploadHtml() {
		if (this.send_data.journey.status == `draft_sent`) {
			var uploadTitle = `Upload Rascunho`
			
			var html = `<div class="row w-100 mb-3">
										<div class="col-12 pl-0 pr-2">
											<div class="form-group">
												<div class="floating-label floating-label-sm">
													<label>Apuração Final</label>
													<div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statementDropdown">
														<button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statementDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statementInput"></span></button>
														<div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
															<input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
															<ul class="ul-select" data-${this.controllerName}-target="statementList" data-app--helpers--search-target="searchList">
																<li data-statement="do_nothing" data-action="click->${this.controllerName}#selectSummary click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Ajuste</li>
																<li data-statement="to_pay" data-action="click->${this.controllerName}#selectSummary click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pagamento</li>
																<li data-statement="to_refund" data-action="click->${this.controllerName}#selectSummary click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Restituição</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-center mb-3">
										<div class="col-9 text-center">
											<h6>${uploadTitle}</h6>
										</div>
										<div class="col-3 px-1 d-flex align-items-center justify-content-center">
											<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestDraftSent" data-${this.controllerName}-target="uploadBtn">
												<div class="row my-1">
													<div class="col-12 d-flex align-items-center justify-content-center">
														<span class="material-icons md-white">cloud_upload</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="draft">
												<div class="col-6">
													<span class="f-065">Rascunho</span>
													<label class="drop-area copy" for="draftDropzoneArea" data-${this.controllerName}-target="labelDraftUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="draftDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileDraftName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="booking">
												<div class="col-6">
												<span class="f-065">Livro-Caixa</span>
													<label class="drop-area copy" for="bookingDropzoneArea" data-${this.controllerName}-target="labelBookingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="bookingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileBookingName"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`

		} else if (this.send_data.journey.status == `transmitted` || this.send_data.journey.status == `rectification`) {
			var uploadTitle = `Upload Arquivos Finais`
			if (this.send_data.journey.status == `transmitted`) {
				var uploadBtn = `<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestTransmitted" data-${this.controllerName}-target="uploadBtn">`
			} else if (this.send_data.journey.status == `rectification`) {
				var uploadBtn = `<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestRetification" data-${this.controllerName}-target="uploadBtn">`
			}

			var html = `<div class="row w-100 d-flex align-items-center mb-3">
										<div class="col-9 text-center">
											<h6>${uploadTitle}</h6>
										</div>
										<div class="col-3 px-1 d-flex align-items-center justify-content-center">
											${uploadBtn}
												<div class="row my-1">
													<div class="col-12 d-flex align-items-center justify-content-center">
														<span class="material-icons md-white">cloud_upload</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="filing">
												<div class="col-6">
													<span class="f-065">Declaração</span>
													<label class="drop-area copy" for="filingDropzoneArea" data-${this.controllerName}-target="labelFilingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="filingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileFilingName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="receipt">
												<div class="col-6">
													<span class="f-065">Recibo Declaração</span>
													<label class="drop-area copy" for="receiptDropzoneArea" data-${this.controllerName}-target="labelReceiptUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="receiptDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileReceiptName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="booking">
												<div class="col-6">
												<span class="f-065">Livro-Caixa</span>
													<label class="drop-area copy" for="bookingDropzoneArea" data-${this.controllerName}-target="labelBookingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="bookingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileBookingName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="darf" data-${this.controllerName}-target="darfDiv">
												<div class="col-6">
													<span class="f-065">DARF Declaração</span>
													<label class="drop-area copy" for="darfDropzoneArea" data-${this.controllerName}-target="labelDarfUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="darfDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileDarfName"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`
		} else if (this.send_data.journey.status == `enquiry`) {
			var uploadTitle = `Upload Consulta IRPF`

			var html = `<div class="row w-100 d-flex align-items-center mb-3">
										<div class="col-9 text-center">
											<h6>${uploadTitle}</h6>
										</div>
										<div class="col-3 px-1 d-flex align-items-center justify-content-center">
											<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestEnquiry" data-${this.controllerName}-target="uploadBtn">
												<div class="row my-1">
													<div class="col-12 d-flex align-items-center justify-content-center">
														<span class="material-icons md-white">cloud_upload</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="enquiry">
												<div class="col-6 d-flex align-items-center justify-content-center">
													<label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="dropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-6 px-0">
													<span data-${this.controllerName}-target="fileEnquiryName"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`
		}

		
		var controller = this
		new Promise(function (resolve) {
			resolve(controller.uploadAreaTarget.innerHTML = html)
		}).then(() => {
			controller.refreshSaveBtn()
			if (controller.current_summary.form == `debt` && (this.send_data.journey.status == `transmitted` || this.send_data.journey.status == `rectification`)) {
				controller.darfDivTarget.classList.add("d-none")
			}
		})
	}

	selectSummary(ev) {
		setTimeout(() => {
			this.saveSummary()
		}, 500);
	}

	saveSummary() {
		var summary = {}
		this.application.summaries.forEach(element => {
			if (element.date_id == this.application.current_date.id) {
				summary = element
			}
		})

		var data = { summary: { id: summary.id, statement: this.statementInputTarget.dataset.statement }, current_user: { current_user_id: this.application.current_user_id } }
		const url = "/operations/products/tax_filings/summaries/update"
		const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
		var controller = this
		fetch(url, init)
			.then(response => response.json())
			.then(response => {
				if (response.save) {
					var summary = response.data.cln
					controller.application.summaries.forEach(element => {
						if (element.id == summary.id) {
							controller.application.summaries.splice(controller.application.summaries.indexOf(element), 1, summary)
						}
					})
				} else {
					controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
				}
			})
			.catch(error => {
				controller.getControllerByIdentifier("app--helpers--console").console(error)
				controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
			})
	}

	dragOverHandler(ev) {
		ev.preventDefault();
	}

	dropHandler(ev) {
		ev.preventDefault();

		if (ev.target.files.length > 0) {
			if (ev.type == "change") {
				this.file = ev.target.files[0]

				var element = this.file
				var obj = {}
				var date = new Date()
				obj.file_name = element.name
				obj.file_size = element.size
				obj.file_type = element.type
				obj.file_last_modified = element.lastModified
				obj.field = ev.currentTarget.closest(".rowFile").dataset.field
				obj.uploaded = false
				obj.message_erro = ""
				obj.created_at_time = parseInt(date.getTime() / 1000)
				if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
					obj.can_upload = true
					obj.file = element
					this.uploadReady = true
					this.fileUploadTarget.classList.remove("d-none")
					if (obj.field == `draft`) {
						this.fileDraftNameTarget.innerText = obj.file_name
					} else if (obj.field == `receipt`) {
						this.fileReceiptNameTarget.innerText = obj.file_name
					} else if (obj.field == `filing`) {
						this.fileFilingNameTarget.innerText = obj.file_name
					} else if (obj.field == `darf`) {
						this.fileDarfNameTarget.innerText = obj.file_name
					} else if (obj.field == `enquiry`) {
						this.fileEnquiryNameTarget.innerText = obj.file_name
					} else if (obj.field == `booking`) {
						this.fileBookingNameTarget.innerText = obj.file_name
					}
					
					this.upload_files[this.upload_files.length] = obj
				} else {
					if ((element.size / 1000000).toFixed(2) > 5) {
						obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
					}

					if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
						obj.message_erro += "Formato do arquivo não é permitido."
					}
					obj.can_upload = false
					this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
				}
			}
		} else {
			this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
		}
	}

	uploadSwitch(value) {
		if (this.hasUploadBtnTarget) {
			if (value) {
				this.uploadBtnTarget.classList.remove("bg-disabled")
				this.uploadBtnTarget.classList.add("pointer")
				this.uploadBtnTarget.classList.add("bg-primary")
				this.canUploadFile = true
			} else {
				this.uploadBtnTarget.classList.add("bg-disabled")
				this.uploadBtnTarget.classList.remove("pointer")
				this.uploadBtnTarget.classList.remove("bg-primary")
				this.canUploadFile = false
			}
		}
	}

	progressCount(value) {
		var controller = this
		this.progressUploadBarTarget.style.width = value + `%`
		var i = 0
		if (value != 100) {
			this.progressTimer = setInterval(function () {
				controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
				controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
				i++
				if (i == 95) {
					i = 94
				}
			}, 500);
		} else {
			this.stopRefreshing()
			this.progressUploadCounterTarget.innerText = value + `%`
		}
	}

	goToURL(ev) {
		var url = ev.currentTarget.dataset.url
		window.open(url, `_blank`)
	}

	refreshSaveBtn() {
		var controller = this
		this.refreshTimer = setInterval(function () {
			var len = 0

			if (controller.uploadReady == false) {
				len += 1
			}

			if (len == 0) {
				controller.uploadSwitch(true)
			} else {
				controller.uploadSwitch(false)
			}
		}, 200);
	}

	stopRefreshing() {
		if (this.refreshTimer) {
			clearInterval(this.refreshTimer)
		}

		if (this.progressTimer) {
			clearInterval(this.progressTimer)
		}
	}

	getControllerByIdentifier(identifier) {
		return this.application.controllers.find(controller => {
				return controller.context.identifier === identifier;
		});
	}

	nameTarget(target) {
		return this.targets.find(target)
	}

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // })

}
