import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--chart-accounts--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
    this.hasShow = false
  }

  showChartAccount(ev) {
    var controllerShow = this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--show")

    if (this.checked_id == ev.target.closest(".itemRow").dataset.id) {
      this.hasShow = false
    } else {
      this.hasShow = true
      this.checked_id = ev.target.closest(".itemRow").dataset.id
    }

    if (this.hasShow) {
      this.checkboxShowTargets.forEach(element => {
        if (checkBoxElement != element) {
          element.checked = false
        }
      })
      this.application.opportunities.forEach(element => {
        if (element.id == this.checked_id) {
          controllerShow.current_opportunity = element
        }
      })
      controllerShow.doChartAccountHtml()
    } else {
      if (controllerShow.hasPreviewCardTarget) {
        controllerShow.doViewHtml()
      } else {
        controllerShow.doChartAccountHtml()
      }
    }
  }

  addChartAccount() {
    this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--save").actionMode = "new"
    this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--save").doFormHtml()
  }

  editChartAccount(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.chart_accounts.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--save").current_chart_account = element
        this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--dashboards--chart-accounts--save").doFormHtml()
      }
    })
  }

  uploadChartAccount() {
    this.getControllerByIdentifier("app--helpers--pre-upload").url_upload = `/financials/books/statements/chart_accounts/upload`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/financials/books/statements/chart_accounts/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download_model = `/financials/books/statements/chart_accounts/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `PUT`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `Plano de Contas`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `financial_settings_chart_accounts`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = ["id", "code", "name", "master_name", "group", "master_group", "master", "kind", "accounting"]
    this.getControllerByIdentifier(`app--helpers--pre-upload`).openUploadModal()
  }

  downloadChartAccount() {
    this.getControllerByIdentifier("app--helpers--pre-upload").url_upload = `/financials/books/statements/chart_accounts/upload`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download = `/financials/books/statements/chart_accounts/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").url_download_model = `/financials/books/statements/chart_accounts/download`
    this.getControllerByIdentifier("app--helpers--pre-upload").method = `POST`
    this.getControllerByIdentifier("app--helpers--pre-upload").feature = `Plano de Contas`
    this.getControllerByIdentifier("app--helpers--pre-upload").permission = `financial_settings_chart_accounts`
    this.getControllerByIdentifier("app--helpers--pre-upload").model_fields = ["id", "token", "code", "name", "master_name", "group", "master_group", "master", "kind", "accounting"]
    this.getControllerByIdentifier(`app--helpers--pre-upload`).downloadData()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions.financial_settings_chart_accounts.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addChartAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Conta</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_settings_chart_accounts.can_upload) {
      var uploadLine = `<span data-action="click->${this.controllerName}#uploadChartAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload do Plano de Contas</span>`
    } else {
      var uploadLine = ``
    }

    if (this.application.permissions.financial_settings_chart_accounts.can_download) {
      var downloadLine = `<span data-action="click->${this.controllerName}#downloadChartAccount" class="dropdown-item py-1 pointer dropdown-submenu-item">Download do Plano de Contas</span>`
    } else {
      var downloadLine = ``
    }

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${uploadLine}
                          ${downloadLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Plano de Contas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Código
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chart_code" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chart_code" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Grupo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Categoria</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Master</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Alocação</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--chart-accounts--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(7, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_chart_accounts = this.application.chart_accounts

    if (this.sort.mode == `asc`) {
      var chart_accounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_chart_accounts, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var chart_accounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_chart_accounts, this.sort.field)
    }

    if (chart_accounts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(chart_accounts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (chart_accounts[x] !== undefined) {
            internPage[internPage.length] = chart_accounts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: chart_accounts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Plano de Contas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.chartAccountTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  chartAccountTablePartial(element, length) {

    // if (this.application.permissions.financial_settings_chart_accounts.can_select) {
    //   var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
    //                 <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showChartAccount">
    //                 <label class="custom-control-label pointer" for="check-${element.id}"></label>
    //               </div>`
    // } else {
    //   var check = ''
    //     `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${check}</td>`
    // }

    if (this.application.permissions.financial_settings_chart_accounts.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editChartAccount" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
                        <span class="mc-tooltiptext">Clique para copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.name}" data-action="click->app--helpers--copy#copy">${element.name}</span>
                        <span class="mc-tooltiptext">Clique para copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.master_name_pretty}" data-action="click->app--helpers--copy#copy">${element.master_name_pretty}</span>
                        <span class="mc-tooltiptext">Clique para copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.group_pretty}" data-action="click->app--helpers--copy#copy">${element.group_pretty}</span>
                        <span class="mc-tooltiptext">Clique para copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip pointer">
                        <span>${element.master_group_pretty}</span>
                        <span class="mc-tooltiptext">${element.id}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.master_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
