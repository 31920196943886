import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "cardBody", "footerTable", "checkboxProcess", "cardTitle",
                    "documentationBtn", "inProcessBtn", "draftBtn", "transmittBtn", "rectificationBtn",
                    "enquiryBtn", "waitingBtn", "saveBtn", "uploadArea", "uploadBtn", "fileUpload", "fileDraftName",
                    "statementDropdown", "statementDropdownBtn", "statementInput", "statementList", "uploadBtn",
                    "finalAdjustedDropdown", "finalAdjustedDropdownBtn", "finalAdjustedInput", "finalAdjustedList",
                    "progressUpload", "progressUploadBar", "progressUploadCounter", "fileEnquiryName", "cancelTicketBtn",
                    "labelFilingUpload", "fileFilingName", "labelReceiptUpload", "fileReceiptName", "saveTicketBtn",
                    "startBtn", "addMoreBtn", "transmissionBtn", "actionArea", "ticketDraftBtn", "bodyEditInput",
                    "branchCode", "accountNumber", "installmentData", "installments", "approvedDraftCard", "finalAdjusted",
                    "paymentForm", "debtCheckbox", "darfCheckbox", "bankData", "bankCode", "bankCodeFilter",
                    "darfDiv", "labelDarfUpload", "fileDarfName", "fileBookingName", "labelDraftUpload", "labelBookingUpload",
                    "darfSeniorDiv", "labelDarfSeniorUpload", "fileDarfSeniorName", "darfChildDiv", "labelDarfChildUpload", "fileDarfChildName"]

  connect() {
    this.controllerName = `operations--products--tax-filings--journey--upload`
    this.upload_files = []
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "DEC", "REC", ".DEC", ".REC", "PDF", "PNG", "JPEG"]
    this.uploadReady = false
  }


  prepareToRequestSave() {
    this.getControllerByIdentifier(`operations--products--tax-filings--journey--save`).upload_files = this.upload_files

    if (this.journey_status == `draft_sent`) {
      if (this.fileDraftNameTarget.innerText == ``) {
        alert(`Favor anexar o Rascunho IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else {
        var r = confirm(`Tem certeza que deseja enviar o Rascunho da Declaração?`)
        if (r) {
          this.uploadFile = true
          this.createNewJourney()
        }
      }
    } else if (this.journey_status == `transmitted`) {
      if (this.fileFilingNameTarget.innerText == ``) {
        alert(`Favor anexar a Declaração IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else if (this.fileReceiptNameTarget.innerText == ``) {
        alert(`Favor anexar o Recibo da Declaração IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else {
        var r = confirm(`Tem certeza que deseja enviar a Declaração Transmitida?`)
        if (r) {
          this.uploadFile = true
          this.createNewJourney()
        }
      }
    } else if (this.journey_status == `enquiry`) {
      if (this.fileEnquiryNameTarget.innerText == ``) {
        alert(`Favor anexar a Consulta do IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else {
        var r = confirm(`Tem certeza que deseja enviar a Consulta do IRPF?`)
        if (r) {
          this.uploadFile = true
          this.createNewJourney()
        }
      }
    } else if (this.journey_status == `rectification`) {
      if (this.fileFilingNameTarget.innerText == ``) {
        alert(`Favor anexar a Retificação do IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else if (this.fileReceiptNameTarget.innerText == ``) {
        alert(`Favor anexar o Recibo da Retificação do IRPF ${this.application.current_date.financial_year} para Envio!`)
      } else {
        var r = confirm(`Tem certeza que deseja enviar a Retificação Transmitida?`)
        if (r) {
          this.uploadFile = true
          this.createNewJourney()
        }
      }
    }

  }

  createNewJourney() {
    if (this.uploadFile) {
      this.progressCount(0)
    }

    this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
    this.send_data.journey.status = this.journey_status

    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.date = new Date()

    var newStatus = this.getControllerByIdentifier(`operations--products--tax-filings--process--dashboard`).translateJourney(this.send_data.journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `changed`
    this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/tax_filings/journeys/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          controller.application.journeys[controller.application.journeys.length] = journey

          if (controller.uploadFile) {
            controller.requestUpload(journey, this.upload_files)
          } else {
            controller.sendJourneyEmailNotification(journey)
          }
        }

        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestUpload(journey, files) {
    var i = 1
    this.requests = []
    files.forEach(element => {
      var formData = new FormData()
      formData.append('file', element.file)
      formData.append('field', element.field)
      formData.append('journey_id', journey.id)
      formData.append('journey_status', journey.status)
      formData.append('current_user_id', this.application.current_user.id)
      formData.append('feature', this.application.journey_permission)

      var init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }
      this.requests[this.requests.length] = init
    })

    this.requestNext(journey)
  }

  requestNext(journey) {
    var url = "/operations/products/tax_filings/journeys/save_upload"
    var controller = this

    if (this.requests.length) {
      fetch(url, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var journey = response.data.cln
            controller.application.journeys.forEach(element => {
              if (element.id == journey.id) {
                controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1, journey)
              }
            })
          }

          controller.requestNext(journey)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      this.progressCount(100)
      this.stopRefreshing()
      this.sendJourneyEmailNotification(journey)

      this.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).requestSave()
      this.getControllerByIdentifier(`operations--products--tax-filings--journey--save`).doWatingBoardHtml()
    }
  }

  sendJourneyEmailNotification(journey) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_token = this.application.tax_filing.token
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = `operation_dates`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
    this.send_data.notification.body = journey.status_pretty

    if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
      this.send_data.notification.receiver_id = this.application.tax_filing.account_id
      this.send_data.notification.receiver_name = this.application.tax_filing.account_name
      this.send_data.notification.receiver_kind = `doctor`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.send_data.notification.receiver_id = this.application.current_agent.account_id
      this.send_data.notification.receiver_name = this.application.current_agent.account_name
      this.send_data.notification.receiver_kind = `team`
    }

    var url = "/operations/products/tax_filings/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  doUploadHtml() {

    // `<div class="form-group form-valid-group">
    //   <div class="floating-label floating-label-sm">
    //     <label for="finalAdjusted">Ajuste Final</label>
    //     <input aria-describedby="finalAdjustedHelp" class="form-control" id="finalAdjusted" data-${this.controllerName}-target="finalAdjusted" placeholder="Ajuste Final" type="text" readonly>
    //   </div>
    // </div>`

    if (this.journey_status == `draft_sent`) {
      var uploadTitle = `Upload Rascunho`

      var html = `<div class="row w-100 mb-3">
										<div class="col-12">
                      <div class="floating-label floating-label-sm">
                        <label>Ajuste Final</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="finalAdjustedDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="finalAdjustedDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="finalAdjustedInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="finalAdjustedList">
                              <li data-statement="to_pay" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saldo de Imposto à Pagar</li>
                              <li data-statement="to_refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Imposto à Restituir</li>
                              <li data-statement="do_nothing" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Ajuste</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
									</div>
									<div class="row w-100 d-flex align-items-center mb-3">
										<div class="col-12 text-center">
											<h6>${uploadTitle}</h6>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="draft">
												<div class="col-12 px-0">
													<span class="f-065">Rascunho</span>
													<label class="drop-area copy" for="draftDropzoneArea" data-${this.controllerName}-target="labelDraftUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="draftDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileDraftName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="booking">
												<div class="col-12 px-0">
												<span class="f-065">Livro-Caixa</span>
													<label class="drop-area copy" for="bookingDropzoneArea" data-${this.controllerName}-target="labelBookingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="bookingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileBookingName"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`

    } else if (this.journey_status == `transmitted` || this.journey_status == `rectification`) {
      var uploadTitle = `Upload Arquivos Finais`
      if (this.journey_status == `transmitted`) {
        var uploadBtn = `<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestTransmitted" data-${this.controllerName}-target="uploadBtn">`
      } else if (this.journey_status == `rectification`) {
        var uploadBtn = `<div class="card bg-disabled w-70 h-50" data-action="click->${this.controllerName}#requestRetification" data-${this.controllerName}-target="uploadBtn">`
      }

      // `<div class="col-12">
      //   <div class="form-group form-valid-group">
      //     <div class="floating-label floating-label-sm">
      //       <label for="finalAdjusted">Ajuste Final</label>
      //       <input aria-describedby="finalAdjustedHelp" class="form-control" id="finalAdjusted" data-${this.controllerName}-target="finalAdjusted" placeholder="Ajuste Final" type="text" readonly>
      //     </div>
      //   </div>
      // </div>`

      var html = `<div class="row w-100 d-flex align-items-center mb-3">
                    

                    <div class="col-12">
                      <div class="floating-label floating-label-sm">
                        <label>Ajuste Final</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="finalAdjustedDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="finalAdjustedDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="finalAdjustedInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="finalAdjustedList">
                              <li data-statement="to_pay" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saldo de Imposto à Pagar</li>
                              <li data-statement="to_refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Imposto à Restituir</li>
                              <li data-statement="do_nothing" data-action="click->app--helpers--selector#select click->${this.controllerName}#setFinalAdjustment" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Ajuste</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

										<div class="col-12 text-center">
											<h6>${uploadTitle}</h6>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="filing">
												<div class="col-12 px-0">
													<span class="f-065">Declaração</span>
													<label class="drop-area copy" for="filingDropzoneArea" data-${this.controllerName}-target="labelFilingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="filingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileFilingName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="receipt">
												<div class="col-12 px-0">
													<span class="f-065">Recibo Declaração</span>
													<label class="drop-area copy" for="receiptDropzoneArea" data-${this.controllerName}-target="labelReceiptUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="receiptDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileReceiptName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="booking">
												<div class="col-12 px-0">
												<span class="f-065">Livro-Caixa</span>
													<label class="drop-area copy" for="bookingDropzoneArea" data-${this.controllerName}-target="labelBookingUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="bookingDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileBookingName"></span>
												</div>
											</div>
										</div>
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="darf" data-${this.controllerName}-target="darfDiv">
												<div class="col-12 px-0">
													<span class="f-065">DARF Declaração</span>
													<label class="drop-area copy" for="darfDropzoneArea" data-${this.controllerName}-target="labelDarfUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="darfDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileDarfName"></span>
												</div>
											</div>
										</div>

                    <div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="senior_darf" data-${this.controllerName}-target="darfSeniorDiv">
												<div class="col-12 px-0">
													<span class="f-065">DARF Doação Idoso</span>
													<label class="drop-area copy" for="darfSeniorDropzoneArea" data-${this.controllerName}-target="labelDarfSeniorUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="darfSeniorDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileDarfSeniorName"></span>
												</div>
											</div>
										</div>

                    <div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="child_darf" data-${this.controllerName}-target="darfChildDiv">
												<div class="col-12 px-0">
													<span class="f-065">DARF Doação ECA</span>
													<label class="drop-area copy" for="darfChildDropzoneArea" data-${this.controllerName}-target="labelDarfChildUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="darfChildDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12 px-0">
													<span class="f-065 f-bold" data-${this.controllerName}-target="fileDarfChildName"></span>
												</div>
											</div>
										</div>

									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`
    } else if (this.journey_status == `enquiry`) {
      var uploadTitle = `Upload Consulta IRPF`

      var html = `<div class="row w-100 d-flex align-items-center mb-3">
                    <div class="col-12">
                      <div class="form-group form-valid-group">
                        <div class="floating-label floating-label-sm">
                          <label for="finalAdjusted">Ajuste Final</label>
                          <input aria-describedby="finalAdjustedHelp" class="form-control" id="finalAdjusted" data-${this.controllerName}-target="finalAdjusted" placeholder="Ajuste Final" type="text" readonly>
                        </div>
                      </div>
                    </div>
										<div class="col-12 text-center">
											<h6>${uploadTitle}</h6>
										</div>
									</div>
									<div class="row w-100">
										<div class="col-12 mb-3">
											<div class="row d-flex align-items-center rowFile" data-field="enquiry">
												<div class="col-12 d-flex align-items-center justify-content-center">
													<label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
														<div class="row my-2">
															<div class="col p-0 d-flex align-items-center justify-content-center">
																<span class="material-icons md-300 md-dark mx-3">add_a_photo</span>
															</div>
														</div>
													</label>
													<input class="file-input mt-0" type="file" id="dropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
												</div>
												<div class="col-12">
													<span data-${this.controllerName}-target="fileEnquiryName"></span>
												</div>
											</div>
										</div>
									</div>
									<div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
										<div class="col-12">
											<div class="form-group my-0 text-center w-100">
												<h7 class="progress">
													<span class="progress_count"></span>
												</h7>
												<div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
													<div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
												</div>
												<span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
											</div>
										</div>
									</div>`
    }


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.uploadAreaTarget.innerHTML = html)
    }).then(() => {
      // controller.refreshSaveBtn()
      if (controller.application.current_summary.form == `debt` && (this.journey_status == `transmitted` || this.journey_status == `rectification`)) {
        controller.darfDivTarget.classList.add("d-none")
        controller.darfSeniorDivTarget.classList.add("d-none")
        controller.darfDivChildTarget.classList.add("d-none")
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setFinalAdjustment(ev) {

    var statement = ev.currentTarget.dataset.statement

    if (statement == `to_pay`) {
      var finalAdjustedLabelText = `Saldo de Imposto à Pagar`
      var finalAdjustedLabelStatement = `to_pay`
    } else if (statement == `to_refund`) {
      var finalAdjustedLabelText = `Imposto à Restituir`
      var finalAdjustedLabelStatement = `to_refund`
    } else if (statement == `do_nothing`) {
      var finalAdjustedLabelText = `Sem Ajuste`
      var finalAdjustedLabelStatement = `do_nothing`
    }

    if (this.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`)) {
      this.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).finalAdjustedLabelTarget.innerText = finalAdjustedLabelText
      this.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).finalAdjustedLabelTarget.dataset.statement = finalAdjustedLabelStatement
    }

  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.field = ev.currentTarget.closest(".rowFile").dataset.field
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
          obj.file = element
          this.uploadReady = true
          this.fileUploadTarget.classList.remove("d-none")
          if (obj.field == `draft`) {
            this.fileDraftNameTarget.innerText = obj.file_name
          } else if (obj.field == `receipt`) {
            this.fileReceiptNameTarget.innerText = obj.file_name
          } else if (obj.field == `filing`) {
            this.fileFilingNameTarget.innerText = obj.file_name
          } else if (obj.field == `darf`) {
            this.fileDarfNameTarget.innerText = obj.file_name
          } else if (obj.field == `senior_darf`) {
            this.fileDarfSeniorNameTarget.innerText = obj.file_name
          } else if (obj.field == `child_darf`) {
            this.fileDarfChildNameTarget.innerText = obj.file_name
          } else if (obj.field == `enquiry`) {
            this.fileEnquiryNameTarget.innerText = obj.file_name
          } else if (obj.field == `booking`) {
            this.fileBookingNameTarget.innerText = obj.file_name
          }

          this.upload_files[this.upload_files.length] = obj
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (this.hasUploadBtnTarget) {
      if (value) {
        this.uploadBtnTarget.classList.remove("bg-disabled")
        this.uploadBtnTarget.classList.add("pointer")
        this.uploadBtnTarget.classList.add("bg-primary")
        this.canUploadFile = true
      } else {
        this.uploadBtnTarget.classList.add("bg-disabled")
        this.uploadBtnTarget.classList.remove("pointer")
        this.uploadBtnTarget.classList.remove("bg-primary")
        this.canUploadFile = false
      }
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.uploadReady == false) {
        len += 1
      }

      if (len == 0) {
        controller.uploadSwitch(true)
      } else {
        controller.uploadSwitch(false)
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
