import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "companies", "certificates", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--integrations--dashboard`
  }

  doIntegrationsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-7 pr-1" data-${this.controllerName}-target="companies" data-controller="operations--products--clinics--managements--integrations--companies"></div>
                  <div class="col-5 pr-1" data-${this.controllerName}-target="certificates" data-controller="operations--products--clinics--managements--integrations--certificates"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--managements--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--show--submenu`).dashboardToggleBtn(`INTEGRAÇÕES`)
      
      controller.doCards()
    })
  }

  doCards() {
    this.getControllerByIdentifier("operations--products--clinics--managements--integrations--companies").doCompaniesCard()
    this.getControllerByIdentifier("operations--products--clinics--managements--integrations--certificates").doCertificatesCard()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
