
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["listCol", "dashboardCol", "mainCard", "total", "repository", "todo", "doing", "delayed", "current_day_tasks",
                    "next_days_tasks", "jobs", "totalCount", "repositoryCount", "todoCount", "doingCount",
                    "delayedCount", "current_day_tasksCount", "next_days_tasksCount", "jobsCount", "dashboardHeaderPreloader",
                    "bodyTable", "bodyTablePreloader", "squadList", "squadPreloader", "mainListCard"]

  connect() {
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    this.getCurrentUserPermissions()
    this.doSquadListHtml()
  }

  doSquadListHtml() {
    // var html = `<div class="card border-top-primary" data-controller="users--squads--board--list" data-users--squads--board--list-target="mainCard">
    var html = `<div class="card border-top-primary" data-users--squads--show-target="mainListCard">
                  <div class="card-body" data-users--squads--show-target="squadList">
                    <div class="row mb-4">
                      <div class="col px-1 d-flex align-items-center justify-content-between">
                        <h6 class="my-0">Olá ${user.name}, estas são suas Squads</h6>
                      </div>
                    </div>
                  </div>
                </div>`
    //<h6 class="my-0">Olá ${currentUser.name.split(" ")[0]}, estas são suas Squads</h6>
    // `<button type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Nova Squad" data-action="click->users--squads--board--list#newSquad"><span class="material-icons md-sm md-dark">add</span></button>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listColTarget.innerHTML = html)
    }).then(() => {
      controller.doSquadPreloader()
      controller.cardHeight()
    })
  }

  doSquadDashboardHtml(squad) {
    if (this.hasMainCard) {
      this.hasMainCard.remove()
    }

    var html = `<div class="row" data-users--squads--show-target="mainCard"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardColTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.insertAdjacentHTML("beforeend", this.doDashboardHeaderPreloader())
      setTimeout(function () {
        controller.dashboardHeaderHtml(squad)
      }, 1000)
    })
  }

  dashboardHeaderHtml(squad) {
    var html = `<div class="col-12 d-flex align-items-center">
                  <h6 class="my-0 mr-3">${squad.name}</h6>`
        squad.members.forEach(member => {
          html += `<span class="material-icons mr-1 default" data-toggle="tooltip" data-placement="top" title data-original-title="${member.name}">face</span>`
        });
        html += `</div>`
    this.dashboardHeaderPreloaderTarget.remove()
    this.mainCardTarget.insertAdjacentHTML("beforeend", html)
    tooltip()
    this.dashboardTableHtml(squad)
  }

  dashboardTableHtml(squad) {
    var html = `<div class="col-12 my-3 d-flex align-items-center">
                  <div class="card w-100 h-100">
                    <div class="card-body">
                      <table class="table table-sm table-hover table-search" style="font-size:80%;">
                        <thead>
                          <tr>
                            <th class="td-w-20" style="width:20%;"><strong>Nome</strong></th>
                            <th class="td-w-10 cell-p text-center td-boarder-left" style="width:10%;"><strong>Total</strong></th>
                            <th class="td-w-10 cell-p text-center td-boarder-left" style="width:10%;"><strong>Repositório</strong></th>
                            <th class="td-w-10 cell-p text-center" style="width:10%;"><strong>Para Fazer</strong></th>
                            <th class="td-w-10 cell-p text-center" style="width:10%;"><strong>Fazendo</strong></th>
                            <th class="td-w-10 cell-p text-center td-boarder-left" style="width:10%;"><strong>Atrasadas</strong></th>
                            <th class="td-w-10 cell-p text-center" style="width:10%;"><strong>Para Hoje</strong></th>
                            <th class="td-w-10 cell-p text-center" style="width:10%;"><strong>Próximos Dias</strong></th>
                            <th class="td-w-10 cell-p text-center td-boarder-left td-boarder-right" style="width:10%;"><strong>Tarefas</strong></th>
                          </tr>
                        </thead>
                        <tbody data-users--squads--show-target="bodyTable">
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>`

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainCardTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", this.doDashboardTablePreloader())
      setTimeout(function () {
        controller.doDashboardTableBody(squad)
      }, 1000)
    })
  }

  doDashboardTableBody(squad) {
    var html = ``
    squad.members.forEach(member => {
      html += `<tr>
                <td>${member.name}</td>
                <td class="text-center td-boarder-left" data-users--squads--show-target="total">${member.total}</td>
                <td class="text-center td-boarder-left" data-users--squads--show-target="repository">${member.repository}</td>
                <td class="text-center" data-users--squads--show-target="todo">${member.todo}</td>
                <td class="text-center" data-users--squads--show-target="doing">${member.doing}</td>
                <td class="text-center td-boarder-left" data-users--squads--show-target="delayed">${member.delayed}</td>
                <td class="text-center" data-users--squads--show-target="current_day_tasks">${member.current_day_tasks}</td>
                <td class="text-center" data-users--squads--show-target="next_days_tasks">${member.next_days_tasks}</td>
                <td class="text-center td-boarder-left td-boarder-right" data-users--squads--show-target="jobs">${member.jobs}</td>
              </tr>`
    });
    html += `<tr class="border-top-gray">
              <td></td>
              <td class="text-center td-boarder-left" data-users--squads--show-target="totalCount"></td>
              <td class="text-center td-boarder-left" data-users--squads--show-target="repositoryCount"></td>
              <td class="text-center" data-users--squads--show-target="todoCount"></td>
              <td class="text-center" data-users--squads--show-target="doingCount"></td>
              <td class="text-center td-boarder-left" data-users--squads--show-target="delayedCount"></td>
              <td class="text-center" data-users--squads--show-target="current_day_tasksCount"></td>
              <td class="text-center" data-users--squads--show-target="next_days_tasksCount"></td>
              <td class="text-center td-boarder-left td-boarder-right" data-users--squads--show-target="jobsCount"></td>
            </tr>`

    this.bodyTableTarget.innerHTML = html
    this.countTasks()
  }

  getSquads() {
    const token = $('meta[name=csrf-token]').attr('content');
    const data = {
      squad: { active: true, user_slug: user.slug },
      current_user: { current_user_id: currentUser.id }
    }
    const url = "/users/squads/entities/list_squads"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    var targetElement = this.squadListTarget
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          allSquadList = response.data.cln
          controller.squadPreloaderTargets.forEach(target => {
            target.remove()
          });

          allSquadList.forEach(element => {
            const html = controller.squadPartial(element)
            targetElement.insertAdjacentHTML("beforeend", html)
          });
          
          tooltip()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["squad_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          $("#currentUserPermisisonContainer").data(permission.name, permission.data)
        });
        document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.doSubMenuHtml()
      })
  }

  doSquadPreloader() {
    const html = `<div class='card timeline-item my-2' data-users--squads--show-target="squadPreloader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>
                  <div class='card timeline-item my-2' data-users--squads--show-target="squadPreloader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>
                  <div class='card timeline-item my-2' data-users--squads--show-target="squadPreloader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>`
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.squadListTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getSquads()
    })
  }

  squadPartial(element) {
    var html = `<div id="card-${element.id}" class="card p-0 my-2 board-flag-${element.flag}">
                  <div class="card-body px-2">
                    <div class="row">
                      <div class="col-2 px-1 mr-2" data-toggle="tooltip" data-placement="top" title data-original-title="Ver Squad">
                        <span class="pointer material-icons md-125" data-action="click->users--squads--show#showSquad" data-id="${element.id}">group_work</span>
                      </div>
                      <div class="col px-1 d-flex align-items-center">
                        <span class="pointer s-title-0p6rem" data-action="click->users--squads--show#showSquad" data-id="${element.id}">${element.name}</span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  showSquad(ev) {
    const id = ev.target.dataset.id
    var squad = {}
    allSquadList.forEach(element => {
      if (element.id == id) {
        squad = element
      }
    });
    this.doSquadDashboardHtml(squad)
  }

  countTasks() {
    var totalCount = 0
    this.totalTargets.forEach(target => {
      totalCount += parseInt(target.innerText)
    });
    this.totalCountTarget.innerText = totalCount

    var repositoryCount = 0
    this.repositoryTargets.forEach(target => {
      repositoryCount += parseInt(target.innerText)
    });
    this.repositoryCountTarget.innerText = repositoryCount

    var todoCount = 0
    this.todoTargets.forEach(target => {
      todoCount += parseInt(target.innerText)
    });
    this.todoCountTarget.innerText = todoCount

    var doingCount = 0
    this.doingTargets.forEach(target => {
      doingCount += parseInt(target.innerText)
    });
    this.doingCountTarget.innerText = doingCount

    var delayedCount = 0
    this.delayedTargets.forEach(target => {
      delayedCount += parseInt(target.innerText)
    });
    this.delayedCountTarget.innerText = delayedCount

    var current_day_tasksCount = 0
    this.current_day_tasksTargets.forEach(target => {
      current_day_tasksCount += parseInt(target.innerText)
    });
    this.current_day_tasksCountTarget.innerText = current_day_tasksCount

    var next_days_tasksCount = 0
    this.next_days_tasksTargets.forEach(target => {
      next_days_tasksCount += parseInt(target.innerText)
    });
    this.next_days_tasksCountTarget.innerText = next_days_tasksCount

    var jobsCount = 0
    this.jobsTargets.forEach(target => {
      jobsCount += parseInt(target.innerText)
    });
    this.jobsCountTarget.innerText = jobsCount

  }

  doDashboardHeaderPreloader() {
    const html = `<div class="col-12 d-flex align-items-center">
                    <div class='card timeline-item ml-0 w-100' data-users--squads--show-target="dashboardHeaderPreloader">
                        <div class='animated-background animated-background-20'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>
                    </div>`
    return html
    // this.dashboardColTarget.insertAdjacentHTML("beforeend", html)
  }

  doDashboardTablePreloader() {
    const columns = 9
    const rows = 4
    var columnPreloaderHtml = ''
    for (var i = 0; i < columns; i++) {
      columnPreloaderHtml += `<td class='animated-background animated-background-td'></td>`
    }
    var tablePreloaderHtml = ''
    for (var i = 0; i < rows; i++) {
      tablePreloaderHtml += `<tr class='timeline-item' data-users--squads--show-target="bodyTablePreloader">` + columnPreloaderHtml + `</tr>`
    }
    // this.bodyTableTarget.innerHTML = tablePreloaderHtml
    return tablePreloaderHtml
  }

  cardHeight() {
    var height = $(window).height();
    this.mainListCardTarget.style.height = (height * 0.5) + 'px'
  }
  
}
