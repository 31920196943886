import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "chartInput", "toInput", "saveBtn",
    "dueAmountInput", "interestsAmountInput", "accrualDate", "dueDate", "providerInput", "providerFilter",
    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "dueDay", "purchaseAmountInput",
    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "descriptionInput", "bankLineInput",
    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput", "bankLineInput",
    "bankLineDiv", "bankAccountDiv", "quotaInput", "pixKeyDiv", "pixKeyInput", "accrualDate",
    "recurringCheckbox", "adjustedPaidInstallmentsInput", "deleteBtn", "dueDay", "amortizationAmountInput",
    "totalAmountInput", "splitInput", "installmentInput",
    "subkindCard", "subkind", "installmentInput"]


  connect() {
    this.controllerName = `financials--books--payables--installments--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--payables--loans--save").stopRefreshing()
    this.getControllerByIdentifier("financials--books--payables--installments--show").doDataCard()
  }

  saveTransaction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, installment: {} }
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_installments`

    if (this.actionMode == `edit`) {
      this.send_data.installment.id = this.current_installment.id
    }

    this.send_data.installment.date_id = this.application.current_date.id
    this.send_data.installment.med_id = this.current_med.id
    this.send_data.installment.loan_id = this.current_loan.id
    this.send_data.installment.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.installment.channel_name = this.channelInputTarget.value
    this.send_data.installment.accrual_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.installment.due_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.installment.quota = this.quotaInputTarget.value
    this.send_data.installment.description = this.descriptionInputTarget.value.trim()
    this.send_data.installment.method = this.methodInputTarget.dataset.method
    this.send_data.installment.kind = this.current_loan.kind
    this.send_data.installment.status = `not_paid`

    this.send_data.installment.due_amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.dueAmountInputTarget.value))
    this.send_data.installment.interests_amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.interestsAmountInputTarget.value))
    this.send_data.installment.amortization_amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amortizationAmountInputTarget.value))
    
    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/payables/installments/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/payables/installments/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/financials/books/payables/installments/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var loan = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.loan_installments[controller.application.loan_installments.length] = loan
          } else if (this.actionMode == `edit`) {
            controller.application.loan_installments.forEach((element, i) => {
              if (element.id == loan.id) {
                controller.application.loan_installments.splice(controller.application.loan_installments.indexOf(element), 1, loan)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.loan_installments.forEach((element, i) => {
              if (element.id == loan.id) {
                controller.application.loan_installments.splice(controller.application.loan_installments.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_payable_installments.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTransaction" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input readonly aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accrualDateForm">Lançamento</label>
                                <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueDateForm">Vencimento</label>
                                <input aria-describedby="dueDateFormHelp" class="form-control form-valid-control" id="dueDateForm" data-${this.controllerName}-target="dueDate" placeholder="Vencimento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="quotaForm">Parcela</label>
                                <input aria-describedby="quotaFormHelp" class="form-control f-065" id="quotaForm" data-${this.controllerName}-target="quotaInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask keyup->${this.controllerName}#numberMask" placeholder="# Parcelas" type="tel" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueAmountForm">Valor Parcela</label>
                                <input aria-describedby="dueAmountFormHelp" class="form-control f-065" id="dueAmountForm" data-${this.controllerName}-target="dueAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#bindInterests" placeholder="Valor Parcela" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amortizationAmountForm">Valor Amortização</label>
                                <input aria-describedby="amortizationAmountFormHelp" class="form-control f-065" id="amortizationAmountForm" data-${this.controllerName}-target="amortizationAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#bindInterests" placeholder="Valor Parcela" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="interestsAmountForm">Valor Juros</label>
                                <input readonly aria-describedby="interestsAmountFormHelp" class="form-control f-065" id="interestsAmountForm" data-${this.controllerName}-target="interestsAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="text" required>
                              </div>
                            </div>
                          </div>

                          
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-center px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-7 px-0">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--loans--dashboard").installmentsTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      controller.application.meds.forEach(med => {
        if (controller.current_loan.med_id == med.id) {
          controller.current_med = med
        }
      })

      var date = new Date(controller.application.current_date.year, controller.application.current_date.month - 1, 1)
      var dueDate = new Date(controller.application.current_date.year, controller.application.current_date.month - 1, controller.current_loan.due_day)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }

      controller.accrualDateTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
      controller.dueDateTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(dueDate)

      controller.application.channels.forEach(element => {
        if (element.id == controller.current_loan.channel_id) {
          controller.setMethodList(element)
        }
      })

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando ${controller.current_installment.description}`

        controller.channelInputTarget.dataset.channelId = controller.current_installment.channel_id
        controller.channelInputTarget.value = controller.current_installment.channel_name
        
        controller.accrualDateTarget.value = controller.current_installment.due_date_pretty
        controller.dueDateTarget.value = controller.current_installment.accrual_date_pretty
        controller.quotaInputTarget.value = controller.current_installment.quota
        controller.descriptionInputTarget.value = controller.current_installment.description
        controller.dueAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_installment.due_amount)
        controller.interestsAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_installment.interests_amount)
        controller.amortizationAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_installment.amortization_amount)

        controller.methodDropdownTarget.value = controller.current_installment.method
        controller.methodInputTarget.dataset.method = controller.current_installment.method
        controller.methodInputTarget.innerText = controller.current_installment.method_pretty

      } else if (controller.actionMode == `new`) {
        var quota = Number(controller.current_loan.paid_installments) + 1
        controller.formTitleTarget.innerText = `${controller.current_loan.description} Parcela ${quota}_${controller.current_loan.installments}`
        controller.descriptionInputTarget.value = `${controller.current_loan.description} Parcela ${quota}_${controller.current_loan.installments}`

        controller.channelInputTarget.dataset.channelId = controller.current_loan.channel_id
        controller.channelInputTarget.value = controller.current_loan.channel_name

        controller.methodDropdownTarget.value = controller.current_loan.method
        controller.methodInputTarget.dataset.method = controller.current_loan.method
        controller.methodInputTarget.innerText = controller.current_loan.method_pretty

        controller.interestsAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.quotaInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").numberMask(quota)
        controller.quotaInputTarget.disabled = true
        
        controller.dueAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.amortizationAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        // controller.dueAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_loan.amortization_amount)
        // controller.amortizationAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_loan.amortization_amount)
        // controller.bindInterests()
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.dueAmountInputTarget.focus()
    })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  numberMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToNumber(ev)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (channel.kind == `bank_account`) {
      methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
      methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
      methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

      this.methodDropdownTarget.value = ``
      this.methodInputTarget.innerText = ``
      this.methodInputTarget.dataset.method = ``
    } else if (channel.kind == `credit_card`) {
      methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Cartão de Crédito`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    } else if (channel.kind == `cash`) {
      methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Dinheiro`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    }

    this.methodListTarget.innerHTML = methodList
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = [`bank_account`]
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payable_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_payable_providers`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = false
      controller.getControllerByIdentifier(modalName).permission = `financial_payable_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  bindInterests() {
    var dueAmount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.dueAmountInputTarget.value))
    var amortizationAmount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amortizationAmountInputTarget.value))
    
    var interests = dueAmount - amortizationAmount
    this.interestsAmountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(interests)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.dueDateTarget.value == ``) {
        len += 1
      }

      if (controller.accrualDateTarget.value == ``) {
        len += 1
      }

      if (controller.quotaInputTarget.value == ``) {
        len += 1
      }

      if (controller.dueAmountInputTarget.value == `R$0,00`) {
        len += 1
      }

      if (controller.amortizationAmountInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.interestsAmountInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.methodInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.channelInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
