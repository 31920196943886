import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--searchs--entities--list`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `searched_at_to_time` }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  refreshSearchs() {
    this.getSearchs()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-2 f-065">
                    <h6 class="card-title mb-0 py-2 f-075">Todas as Buscas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 py-0" data-${this.controllerName}-target="refreshSearchsBtn" data-action="click->${this.controllerName}#refreshSearchs" type="button">
                        <span class="material-icons md-dark md-sm">refresh</span>
                      </button>
                      <span class="mc-tooltiptext">Atualizar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="searched_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="searched_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Horário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-40 align-middle">Palavras-Chaves</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center"># Palavras</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Resultados</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Tipo
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="searcher_id" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="searcher_id" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                ID
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="searcher_type" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="searcher_type" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Compartilhamento</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Tempo de Busca</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--searchs--entities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_searchs = this.application.searchs

    if (this.sort.mode == `asc`) {
      var searchs = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_searchs, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var searchs = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_searchs, this.sort.field)
    }

    if (searchs.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(searchs.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (searchs[x] !== undefined) {
            internPage[internPage.length] = searchs[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: searchs.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="9" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Buscas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.searchTablePartial(element, data.length))
      });
    }
  }

  searchTablePartial(element, length) {

    if (element.status) {
      var statusPretty = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-success pointer">wifi</span>
                            <span class="mc-tooltiptext">Online</span>
                          </span>`
    } else {
      var statusPretty = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-danger pointer">wifi_off</span>
                            <span class="mc-tooltiptext">Offline</span>
                          </span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.searched_at_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.searched_at_time_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.keywords}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.keywords_length}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.results_length}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.searcher_type}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.searcher_id}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.sharing_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.time_span_seconds}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getSearchs() {
    var data = { search: { active: true, permitted: true }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/commercial/marketing/searchs/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.searchs = response.data.cln
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        controller.getControllerByIdentifier("commercial--marketing--searchs--entities--submenu").doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
