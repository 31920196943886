import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "urlInput", "duration", "methodDropdown", "methodDropdownBtn", "methodInput", "methodList",
                    "urlInput", "permissionInput", "actionInput", "routeInputs", "paramsInputs", "requestBtn", "timesInput", "batchInput", "9999",
                    "objInput", "keyInput", "valueInput", "saveBtn", "formCard", "formTitle", "7777", "8888", "9999",
                    "9999", "9999", "9999", "9999", "9999", "9999", "9999", "9999", "9999"]


  connect() {
    this.controllerName = `app--stats--builders--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.copyRequest = false
    this.stopRefreshing()
    this.getControllerByIdentifier("app--stats--builders--list").clearList()
  }

  saveRequest() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, request: {}, parameters: {} }

    if (this.copyRequest) {
      this.actionMode = `new`
      this.current_request = {}
      this.copyRequest = false
    }

    if (this.actionMode == `edit`) {
      this.send_data.request.id = this.current_request.id
    }

    this.send_data.request.url = this.urlInputTarget.value
    this.send_data.request.method = this.methodInputTarget.dataset.method
    this.send_data.request.action = this.actionInputTarget.value
    this.send_data.request.permission = this.permissionInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var parameters = []
    this.paramsCountArray.forEach(count => {
      if (this.nameTarget(`paramsRow-${count}`)) {

        var parameter = { parobj: ``, parkey: ``, parvalue: `` }

        parameter.parobj = this.nameTarget(`objInput-${count}`).value.trim()
        parameter.parkey = this.nameTarget(`keyInput-${count}`).value.trim()
        parameter.parvalue = this.nameTarget(`valueInput-${count}`).value.trim()

        parameters[parameters.length] = parameter

      }
    })

    this.send_data.parameters.objs = parameters
    
    this.requestSave()
  }

  destroyRequest() {
    var r = confirm(`Tem certeza que deseja apagar a Request?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, request: {} }
      this.saveBtnTarget.disabled = true
      if (this.hasDestroyBtnTarget) {
        this.destroyBtnTarget.disabled = true
      }
      this.actionMode = `delete`
      this.stopRefreshing()

      this.send_data.request.id = this.current_request.id
      this.send_data.request.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/app/stats/builders/requests/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/app/stats/builders/requests/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/app/stats/builders/requests/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var request = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.requests[controller.application.requests.length] = request
          } else if (this.actionMode == `edit`) {
            controller.application.requests.forEach((element, i) => {
              if (element.id == request.id) {
                controller.application.requests.splice(controller.application.requests.indexOf(element), 1, request)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.requests.forEach((element, i) => {
              if (element.id == request.id) {
                controller.application.requests.splice(controller.application.requests.indexOf(element), 1)
              }
            })
          }
          controller.cancelSave()
        } else {
          this.saveBtnTarget.disabled = false
          this.refreshSaveBtn()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()
    this.paramsCount = 0
    this.paramsCountArray = []

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRequest" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit` && !this.copyRequest) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyRequest" data-${this.controllerName}-target="destroyBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    
                    
                    <div class="row my-2">
                      <div class="col-3 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Método</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList">
                                  <li data-method="POST" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">POST</li>
                                  <li data-method="PUT" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PUT</li>
                                  <li data-method="DELETE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DELETE</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-9 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="urlForm">URL</label>
                            <input aria-describedby="urlFormHelp" class="form-control form-valid-control" id="urlForm" data-${this.controllerName}-target="urlInput" placeholder="URL" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="permissionForm">Permissão</label>
                            <input aria-describedby="permissionFormHelp" class="form-control form-valid-control" id="permissionForm" data-${this.controllerName}-target="permissionInput" placeholder="Permissão" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="actionForm">Ação</label>
                            <input aria-describedby="actionFormHelp" class="form-control form-valid-control" id="actionForm" data-${this.controllerName}-target="actionInput" placeholder="Ação" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-0" data-${this.controllerName}-target="paramsInputs"></div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6 px-2 text-center">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#addParamsKeyValue">Adicionar Key-Value</button>
                      </div>
                    </div>

                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRequest" data-${this.controllerName}-target="saveBtn">Salvar Request</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--stats--builders--dashboard").requestsTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Request`

        // controller.urlInputTarget.value = `/app/stats/builders/requests/list`
        // controller.permissionInputTarget.value = `app_stats_builders_requests`
        controller.actionInputTarget.value = `list`
        controller.methodDropdownTarget.value = `POST`
        controller.methodInputTarget.innerText = `POST`
        controller.methodInputTarget.dataset.method = `POST`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Request`

        controller.urlInputTarget.value = controller.current_request.url
        controller.permissionInputTarget.value = controller.current_request.permission
        controller.actionInputTarget.value = controller.current_request.action

        controller.methodDropdownTarget.value = controller.current_request.method
        controller.methodInputTarget.innerText = controller.current_request.method
        controller.methodInputTarget.dataset.method = controller.current_request.method

        controller.setParameters()
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  addParamsKeyValue() {

    var html = `<div class="row d-flex align-items-center" data-${this.controllerName}-target="paramsRow-${this.paramsCount}">
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="objForm">Objeto</label>
                        <input aria-describedby="objFormHelp" class="form-control form-valid-control" id="objForm" data-${this.controllerName}-target="objInput-${this.paramsCount}" placeholder="Objeto" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="keyForm">Chave</label>
                        <input aria-describedby="keyFormHelp" class="form-control form-valid-control" id="keyForm" data-${this.controllerName}-target="keyInput-${this.paramsCount}" placeholder="Chave" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="valueForm">Valor</label>
                        <input aria-describedby="valueFormHelp" class="form-control form-valid-control" id="valueForm" data-${this.controllerName}-target="valueInput-${this.paramsCount}" placeholder="Valor" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-right px-0 d-flex align-items-center justify-content-end">
                    <button data-action="click->${this.controllerName}#destroyRow" data-${this.controllerName}-target="destroyBtn-${this.paramsCount}" data-count="${this.paramsCount}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">clear</span>
                      <span class="mc-tooltiptext mc-tooltiptext">Apagar Linha</span>
                    </button>
                  </div>
                </div>`

    var controller = this
    controller.paramsCountArray[controller.paramsCountArray.length] = controller.paramsCount
    controller.paramsCount += 1

    new Promise(function (resolve) {
      resolve(controller.paramsInputsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.paramsCountArray[controller.paramsCountArray.length] = controller.paramsCount
      // controller.paramsCount += 1
    })
  }

  setParameters() {
    this.current_request.parameters.forEach((parameter, index) => {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.addParamsKeyValue())
      }).then(() => {
        // controller.nameTarget(`objInput-${controller.paramsCount - 1}`).value = parameter.parobj
        // controller.nameTarget(`keyInput-${controller.paramsCount - 1}`).value = parameter.parkey
        // controller.nameTarget(`valueInput-${controller.paramsCount - 1}`).value = parameter.parvalue

        controller.nameTarget(`objInput-${index}`).value = parameter.parobj
        controller.nameTarget(`keyInput-${index}`).value = parameter.parkey
        controller.nameTarget(`valueInput-${index}`).value = parameter.parvalue
      
        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      })
    })
  }

  destroyRow(ev) {
    this.stopRefreshing()
    var count = ev.currentTarget.dataset.count

    var r = confirm("Certeza que deseja remover a Linha?")
    if (r) {
      this.nameTarget(`paramsRow-${count}`).remove()
      this.paramsCountArray.forEach((element, i) => {
        if (element == count) {
          this.paramsCountArray.splice(this.paramsCountArray.indexOf(element), 1)
        }
      })
    }
    this.refreshSaveBtn()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.urlInputTarget.value == ``) {
        len += 1
      }

      if (controller.actionInputTarget.value == ``) {
        len += 1
      }

      if (controller.permissionInputTarget.value == ``) {
        len += 1
      }

      if (controller.methodInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.paramsCountArray.length == 0) {
        len += 1
      } else {
        controller.paramsCountArray.forEach(count => {
          if (controller.nameTarget(`objInput-${count}`).value == ``) {
            len += 1
          }

          if (controller.nameTarget(`keyInput-${count}`).value == ``) {
            len += 1
          }

          if (controller.nameTarget(`valueInput-${count}`).value == ``) {
            len += 1
          }
        })
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
