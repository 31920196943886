import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "fromInput", "toInput", "saveBtn",
    "medDropdown", "medDropdownBtn", "medInput", "medList", "providerInput", "providerFilter",
    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "dueDate", "amountInput",
    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput", "bankLineInput",
    "bankLineDiv", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKeyInput", "payDay",
    "recurringCheckbox", "variableCheckbox", "deleteBtn", "cardDiv",
    "totalAmountInput", "splitInput", "amountSplitInput", "installmentInput",
    "subkindCard", "subkind", "amountSplitInput", "installmentInput",
    "adjustKindDropdown", "adjustKindDropdownBtn", "adjustKindInput", "adjustKindList"]


  connect() {
    this.controllerName = `financials--books--cards--transactions--adjust`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--cards--transactions--list").doIndexListHtml()
  }

  saveTransaction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, transaction: {} }
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_card_transactions`

    this.send_data.transaction.card_id = this.current_transaction.card_id
    this.send_data.transaction.bill_id = this.current_transaction.bill_id
    this.send_data.transaction.provider_id = this.current_transaction.provider_id
    this.send_data.transaction.chart_id = this.current_transaction.chart_id
    this.send_data.transaction.chart_name = this.current_transaction.chart_name
    this.send_data.transaction.installment = this.current_transaction.installment
    this.send_data.transaction.quota = this.current_transaction.quota
    this.send_data.transaction.token_tree = this.current_transaction.token_tree

    this.send_data.transaction.date = this.current_transaction.date
    this.send_data.transaction.first_pay = this.current_transaction.first_pay
    this.send_data.transaction.last_pay = this.current_transaction.last_pay
    this.send_data.transaction.pay_day = this.current_transaction.pay_day
    this.send_data.transaction.closing_day = this.current_transaction.closing_day

    this.send_data.transaction.description = this.descriptionInputTarget.value

    if (this.adjustKindInputTarget.dataset.kind == `increase`) {
      this.send_data.transaction.amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value))
    } else if (this.adjustKindInputTarget.dataset.kind == `decrease`) { 
      this.send_data.transaction.amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)) * Number(-1)
    }

    // this.send_data.transaction.pay_day = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.payDayTarget.value)
    this.requestSave()
  }

  requestSave() {
    var url = "/financials/books/cards/transactions/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var transaction = response.data.cln.transaction
          controller.application.card_transactions[controller.application.card_transactions.length] = transaction
          controller.current_bill = response.data.cln.bill

          controller.application.bills.forEach((element, i) => {
            if (element.id == controller.current_bill.id) {
              controller.application.bills.splice(controller.application.bills.indexOf(element), 1, controller.current_bill)
            }
          })

          controller.getControllerByIdentifier(`financials--books--cards--bills--show`).current_bill = controller.current_bill
          controller.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill()
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_payable_card_transactions.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTransaction" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row mt-2 d-flex align-items-center">
                      <div class="col-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="payDayForm">Dt Fatura</label>
                            <input aria-describedby="payDayFormHelp" class="form-control form-valid-control" id="payDayForm" data-${this.controllerName}-target="payDay" placeholder="Dt Fatura" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="adjustKindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="adjustKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="adjustKindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="adjustKindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="increase" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Soma</li>
                                  <li data-kind="decrease" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Subtração</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--cards--bills--dashboard").transactionsTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.payDayTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

      controller.formTitleTarget.innerText = `Transação de Ajuste de Fatura de Cartão`
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
      controller.payDayTarget.value = controller.current_transaction.pay_day_pretty
      controller.descriptionInputTarget.value = `Ajuste de Parcela ${controller.current_transaction.description}`

      controller.payDayTarget.disabled = true
      controller.descriptionInputTarget.disabled = true

      if (controller.application.current_med.id) {
        controller.current_med = controller.application.current_med
        controller.medInputTarget.dataset.medId = controller.application.current_med.id
        controller.medInputTarget.value = controller.application.current_med.name
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (channel.kind == `bank_account`) {
      methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
      methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
      methodList += `<li data-method="provision" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`

      this.methodDropdownTarget.value = ``
      this.methodInputTarget.innerText = ``
      this.methodInputTarget.dataset.method = ``
    } else if (channel.kind == `credit_card`) {
      methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Cartão de Crédito`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    } else if (channel.kind == `cash`) {
      methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`
      methodList += `<li data-method="provisão" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Dinheiro`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    }

    this.methodListTarget.innerHTML = methodList
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = [`credit_card`]
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_statement_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_statement_providers`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).permission = `financial_payable_chart_accounts`
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = false
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {
        if (controller.payDayTarget.value == ``) {
          len += 1
        }

        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.adjustKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
