import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "nameDiv", "nameInputDiv", "nameInput", "cpfDiv", "cpfInputDiv", "cpfInput",
                    "birthdateDiv", "birthdateInputDiv", "birthdateInput", "saveBtn", "editBtn", "cancelBtn",
                    "sexDiv", "sexInputDiv", "sexDropdown", "sexDropdownBtn", "sexInput", "sexList",
                    "entriedAtDiv", "entriedAtInputDiv", "entriedAtInput", "roleList",
                    "financialCheckbox", "administrativeCheckbox", "doctorCheckbox", "legalCheckbox",

                    "cpfRow", "cnpjRow", "cnpjDiv", "cnpjInputDiv", , "cnpjRow", "cnpjDiv",
                    "cnpjInput", "nameRow", "brithdateRow", "sexRow",
                    "tradeNameRow", "tradeNameDiv", "tradeNameInputDiv", "tradeNameInput",
                    "fantasyNameRow", "fantasyNameDiv", "fantasyNameInputDiv", "fantasyNameInput",


                    "sharesDiv", "sharesInputDiv", "sharesInput", "technicalCheckbox", "operationalCheckbox"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--partners--principal`
    this.current_partner = this.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--show`).current_partner
  }

  editPartner(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }

    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cpfInputTarget.value = this.cpfDivTarget.innerText.trim()
      this.cpfInputTarget.focus()
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.add("d-none")
      this.birthdateInputDivTarget.classList.remove("d-none")
      this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
      this.birthdateInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.add("d-none")
      this.sexInputDivTarget.classList.remove("d-none")
      this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
      this.sexInputTarget.focus()
    } else if (this.field == "shares") {
      this.sharesDivTarget.classList.add("d-none")
      this.sharesInputDivTarget.classList.remove("d-none")
      this.sharesInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.sharesDivTarget.innerText.trim())
      this.sharesInputTarget.focus()
    } else if (this.field == "entriedAt") {
      this.entriedAtDivTarget.classList.add("d-none")
      this.entriedAtInputDivTarget.classList.remove("d-none")
      this.entriedAtInputTarget.value = this.entriedAtDivTarget.innerText.trim()
      this.entriedAtInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.entriedAtInputTarget), { max: false, months: true, years: 5 })
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.add("d-none")
      this.tradeNameInputDivTarget.classList.remove("d-none")
      this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
      this.tradeNameInputTarget.focus()
    } else if (this.field == "fantasyName") {
      this.fantasyNameDivTarget.classList.add("d-none")
      this.fantasyNameInputDivTarget.classList.remove("d-none")
      this.fantasyNameInputTarget.value = this.fantasyNameDivTarget.innerText.trim()
      this.fantasyNameInputTarget.focus()
    } else if (this.field == "cnpj") {
      this.cnpjDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")
      this.cnpjInputTarget.value = this.cnpjDivTarget.innerText.trim()
      this.cnpjInputTarget.focus()
    }
  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.current_partner.name
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.current_partner.name
    } else if (this.field == "fantasyName") {
      this.fantasyNameDivTarget.classList.remove("d-none")
      this.fantasyNameInputDivTarget.classList.add("d-none")
      this.fantasyNameDivTarget.innerText = this.current_partner.company_fantasy_name
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.current_partner.id_number_pretty
    } else if (this.field == "cnpj") {
      this.cnpjDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")
      this.cnpjDivTarget.innerText = this.current_partner.id_number_pretty
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.current_partner.account_birthdate_pretty
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.current_partner.account_sex_pretty
    } else if (this.field == "shares") {
      this.sharesDivTarget.classList.remove("d-none")
      this.sharesInputDivTarget.classList.add("d-none")
      this.sharesDivTarget.innerText = this.current_partner.shares
    } else if (this.field == "entriedAt") {
      this.entriedAtDivTarget.classList.remove("d-none")
      this.entriedAtInputDivTarget.classList.add("d-none")
      this.entriedAtDivTarget.innerText = this.current_partner.entried_at_pretty
    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.current_partner.id_type == `individual`) {
      if (this.nameDivTarget.classList.contains("d-none")) {
        this.nameDivTarget.classList.remove("d-none")
        this.nameInputDivTarget.classList.add("d-none")
        this.nameDivTarget.innerText = this.nameInputTarget.value
      }

      if (this.cpfDivTarget.classList.contains("d-none")) {
        this.cpfDivTarget.classList.remove("d-none")
        this.cpfInputDivTarget.classList.add("d-none")
        this.cpfDivTarget.innerText = this.cpfInputTarget.value
      }

      if (this.birthdateDivTarget.classList.contains("d-none")) {
        this.birthdateDivTarget.classList.remove("d-none")
        this.birthdateInputDivTarget.classList.add("d-none")
        this.birthdateDivTarget.innerText = this.birthdateInputTarget.value
      }

      if (this.sexDivTarget.classList.contains("d-none")) {
        this.sexDivTarget.classList.remove("d-none")
        this.sexInputDivTarget.classList.add("d-none")
        this.sexDivTarget.innerText = this.sexInputTarget.innerText
      }
    } else if (this.current_partner.id_type == `legal`) {
      if (this.tradeNameDivTarget.classList.contains("d-none")) {
        this.tradeNameDivTarget.classList.remove("d-none")
        this.tradeNameInputDivTarget.classList.add("d-none")
        this.tradeNameDivTarget.innerText = this.tradeNameInputTarget.value
      }

      if (this.fantasyNameDivTarget.classList.contains("d-none")) {
        this.fantasyNameDivTarget.classList.remove("d-none")
        this.fantasyNameInputDivTarget.classList.add("d-none")
        this.fantasyNameDivTarget.innerText = this.fantasyNameInputTarget.value
      }

      if (this.cnpjDivTarget.classList.contains("d-none")) {
        this.cnpjDivTarget.classList.remove("d-none")
        this.cnpjInputDivTarget.classList.add("d-none")
        this.cnpjDivTarget.innerText = this.cnpjInputTarget.value
      }
    }

    if (this.entriedAtDivTarget.classList.contains("d-none")) {
      this.entriedAtDivTarget.classList.remove("d-none")
      this.entriedAtInputDivTarget.classList.add("d-none")
      this.entriedAtDivTarget.innerText = this.entriedAtInputTarget.value
    }

    if (this.sharesDivTarget.classList.contains("d-none")) {
      this.sharesDivTarget.classList.remove("d-none")
      this.sharesInputDivTarget.classList.add("d-none")
      this.sharesDivTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.sharesInputTarget.value)
    }
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  savePartner() {
    this.send_data = { current_user: {}, partner: {}, account: {}, company: {} }
    
    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.current_user.feature = this.permission

      controller.send_data.partner.id = this.current_partner.id
      controller.send_data.partner.record_id = controller.current_partner.record_id
      
      controller.send_data.partner.shares = controller.getControllerByIdentifier("app--helpers--numbers").cleanNumber(controller.sharesDivTarget.innerText.trim())
      controller.send_data.partner.entried_at = controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.entriedAtDivTarget.innerText.trim())
      controller.send_data.partner.financial = controller.financialCheckboxTarget.checked
      controller.send_data.partner.administrative = controller.administrativeCheckboxTarget.checked
      controller.send_data.partner.doctor = controller.doctorCheckboxTarget.checked
      controller.send_data.partner.legal = controller.legalCheckboxTarget.checked
      controller.send_data.partner.technical = controller.technicalCheckboxTarget.checked
      controller.send_data.partner.operational = controller.operationalCheckboxTarget.checked
      controller.send_data.partner.name = controller.nameDivTarget.innerText.trim()

      if (controller.current_partner.id_type == `individual`) {
        controller.send_data.account.is_account = true
        controller.send_data.company.is_company = false

        controller.send_data.account.id = controller.current_partner.record_id
        controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
        controller.send_data.account.cpf = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
        controller.send_data.account.birthdate = controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.birthdateDivTarget.innerText.trim())
        controller.send_data.account.sex = controller.sexInputTarget.dataset.sex

        controller.send_data.partner.id_type = "individual"
        controller.send_data.partner.id_number = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
        controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
      } else if (controller.current_partner.id_type == `legal`) {
        controller.send_data.account.is_account = false
        controller.send_data.company.is_company = true

        controller.send_data.company.id = controller.current_partner.record_id
        controller.send_data.company.trade_name = controller.tradeNameDivTarget.innerText.trim()
        controller.send_data.company.name = controller.fantasyNameDivTarget.innerText.trim()
        controller.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(controller.cnpjDivTarget.innerText.trim())

        controller.send_data.partner.id_type = "legal"
        controller.send_data.partner.id_number = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cnpjDivTarget.innerText.trim())
        controller.send_data.account.name = controller.tradeNameDivTarget.innerText.trim()
      }

      controller.stopRefreshing()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/operations/products/clinics/regularizations/partners/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var partner = response.data.cln
          controller.current_partner = partner
          if (partner.active) {
            controller.application.partners.forEach((element, i) => {
              if (element.id == partner.id) {
                controller.application.partners.splice(controller.application.partners.indexOf(element), 1, partner)
              }
            })
          } else {
            controller.application.partners.forEach((element, i) => {
              if (element.id == partner.id) {
                controller.application.partners.splice(controller.application.partners.indexOf(element), 1)
              }
            })
          }

          controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--show").viewTitleTarget.innerText = controller.current_partner.name

          var controllerShow = controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--show")
          controllerShow.current_partner = partner
          controllerShow.doPartnerHtml()
          controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--index").doDataTable()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }


  doPrincipalHtml() {
    if (this.application.permissions[this.permission].can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editPartner" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.current_partner.status) {
      var statusBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-status="${this.current_partner.status}" data-action="click->${this.controllerName}#changePartnerStatus">
                          <span class="material-icons md-sm md-success">toggle_on</span>
                          <span class="mc-tooltiptext">O médico deixará de ser Sócio da PJ?</span>
                        </button>`
    } else {
      var statusBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-status="${this.current_partner.status}" data-action="click->${this.controllerName}#changePartnerStatus">
                          <span class="material-icons md-sm md-danger">toggle_off</span>
                          <span class="mc-tooltiptext">O médico voltará a ser Sócio da PJ?</span>
                        </button>`
    }

    var statusRow = `<tr data-field="status" data-${this.controllerName}-target="statusRow">
                        <th class="table-25 px-0 f-065 align-middle"><strong>Status</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="statusDiv">
                            ${this.current_partner.status_pretty}
                          </div>
                        </td>
                        <td class="table-15 align-middle"></td>
                        <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                          ${statusBtn}
                        </td>
                      </tr>`

    if (this.current_partner.id_type == `individual`) {
      var nameRow = `<tr data-field="name" data-${this.controllerName}-target="nameRow">
                        <th class="table-25 px-0 f-065 align-middle"><strong>Nome</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="nameDiv">
                            ${this.current_partner.name}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                            <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_partner.name}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit}
                          ${cancel}
                        </td>
                      </tr>`
      var cpfRow = `<tr data-field="cpf" data-${this.controllerName}-target="cpfRow">
                      <th class="table-30 px-0 f-065 align-middle"><strong>CPF</strong></th>
                      <td class="table-45 f-065 align-middle">
                        <div data-${this.controllerName}-target="cpfDiv">
                          ${this.current_partner.id_number_pretty}
                        </div>
                        <div class="d-none" data-${this.controllerName}-target="cpfInputDiv">
                          <input data-${this.controllerName}-target="cpfInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask">
                        </div>
                      </td>
                      <td class="align-middle text-center" data-copy="${this.current_partner.cpf}">${copyPath}</td>
                      <td class="align-middle text-center">
                        ${edit} 
                        ${cancel}
                      </td>
                    </tr>`
      var birthdateRow = `<tr data-field="birthdate" data-${this.controllerName}-target="brithdateRow">
                            <th class="table-30 px-0 f-065 align-middle"><strong>DN</strong></th>
                            <td class="table-45 f-065 align-middle">
                              <div data-${this.controllerName}-target="birthdateDiv">
                                ${this.current_partner.account_birthdate_pretty}
                              </div>
                              <div class="d-none" data-${this.controllerName}-target="birthdateInputDiv">
                                <input data-${this.controllerName}-target="birthdateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                              </div>
                            </td>
                            <td class="align-middle text-center" data-copy="${this.current_partner.cpf}">${copyPath}</td>
                            <td class="align-middle text-center">
                              ${edit}
                              ${cancel}
                            </td>
                          </tr>`
      var sexRow = `<tr data-field="sex" data-${this.controllerName}-target="sexRow">
                      <th class="table-30 px-0 f-065 align-middle"><strong>Sexo</strong></th>
                      <td class="table-45 f-065 align-middle">
                        <div data-${this.controllerName}-target="sexDiv">
                          ${this.current_partner.account_sex_pretty}
                        </div>
                        <div class="d-none" data-${this.controllerName}-target="sexInputDiv">
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item f-075" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td></td>
                      <td class="align-middle text-center">
                        ${edit}
                        ${cancel}
                      </td>
                    </tr>`

      var tradeRow = ``
      var fantasyRow = ``
      var cnpjRow = ``
    } else if (this.current_partner.id_type == `legal`) {
      var tradeRow = `<tr data-field="tradeName" data-${this.controllerName}-target="tradeNameRow">
                        <th class="table-30 px-0 f-065 align-middle"><strong>Razão Social</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="tradeNameDiv">
                            ${this.current_partner.name}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="tradeNameInputDiv">
                            <input data-${this.controllerName}-target="tradeNameInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_partner.cpf}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit} 
                          ${cancel}
                        </td>
                      </tr>`
      var fantasyRow = `<tr data-field="fantasyName" data-${this.controllerName}-target="fantasyNameRow">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Nome Fantasia</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="fantasyNameDiv">
                              ${this.current_partner.company_fantasy_name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="fantasyNameInputDiv">
                              <input data-${this.controllerName}-target="fantasyNameInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.current_partner.cpf}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit} 
                            ${cancel}
                          </td>
                        </tr>`
      var cnpjRow = `<tr data-field="cnpj" data-${this.controllerName}-target="cnpjRow">
                        <th class="table-30 px-0 f-065 align-middle"><strong>CNPJ</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="cnpjDiv">
                            ${this.current_partner.id_number_pretty}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="cnpjInputDiv">
                            <input data-${this.controllerName}-target="cnpjInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask">
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_partner.cpf}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit} 
                          ${cancel}
                        </td>
                      </tr>`

      var nameRow = ``
      var cpfRow = ``
      var birthdateRow = ``
      var sexRow = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search" style="font-size:80%;">
                      <tbody>
                        
                        ${nameRow}
                        ${cpfRow}
                        ${birthdateRow}
                        ${sexRow}
                        ${tradeRow}
                        ${fantasyRow}
                        ${cnpjRow}

                        <tr data-field="entriedAt">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Data de Entrada</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="entriedAtDiv">
                              ${this.current_partner.entried_at_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="entriedAtInputDiv">
                              <input data-${this.controllerName}-target="entriedAtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="shares">
                          <th class="table-25 px-0 f-065 align-middle"><strong>Cotas</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="sharesDiv">
                              ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.current_partner.shares)}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="sharesInputDiv">
                              <input data-${this.controllerName}-target="sharesInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        ${statusRow}

                        <tr>
                          <th class="table-25 px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="doctorCheck" data-${this.controllerName}-target="doctorCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="doctorCheck">Médico</label>
                              <span class="mc-tooltiptext">É médico?</span>
                            </div>
                          </th>
                          <td class="table-45 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="operationalCheck" data-${this.controllerName}-target="operationalCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="operationalCheck">Operacional</label>
                              <span class="mc-tooltiptext">Emite NF pela PJ Médica?</span>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <th class="table-25 px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="financialCheck" data-${this.controllerName}-target="financialCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="financialCheck">Financeiro</label>
                              <span class="mc-tooltiptext">É financeiro da PJ?</span>
                            </div>
                          </th>
                          <td class="table-45 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="administrativeCheck" data-${this.controllerName}-target="administrativeCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="administrativeCheck">Administrador</label>
                              <span class="mc-tooltiptext">É o Sócio-Administrador?</span>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          <th class="table-25 px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="legalCheck" data-${this.controllerName}-target="legalCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="legalCheck">Legal</label>
                              <span class="mc-tooltiptext">É o Responsável-Legal?</span>
                            </div>
                          </th>
                          <td class="table-45 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="technicalCheck" data-${this.controllerName}-target="technicalCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="technicalCheck">Técnico</label>
                              <span class="mc-tooltiptext">É o Responsável Técnico?</span>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        
                        <tr>
                          <td colspan="4" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#savePartner" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {

      if (controller.current_partner.id_type == `individual`) {
        controller.sexInputTarget.dataset.sex = controller.current_partner.account_sex
        controller.financialCheckboxTarget.checked = controller.current_partner.financial
        controller.administrativeCheckboxTarget.checked = controller.current_partner.administrative
        controller.doctorCheckboxTarget.checked = controller.current_partner.doctor
        controller.legalCheckboxTarget.checked = controller.current_partner.legal
        controller.technicalCheckboxTarget.checked = controller.current_partner.technical
        controller.operationalCheckboxTarget.checked = controller.current_partner.operational

      } else if (controller.current_partner.id_type == `legal`) {

        
      }
    })
  }

  changePartnerStatus(ev) {
    var actualStatus = this.getControllerByIdentifier("app--helpers--strings").fromStringToBoolean(ev.currentTarget.dataset.status)
    this.actionMode = `edit`
    this.send_data = { current_user: {}, partner: {}, account: {}, company: {} }
    this.send_data.account.is_account = false
    this.send_data.company.is_company = false

    this.send_data.partner.id = this.current_partner.id
    if (actualStatus) {
      this.send_data.partner.status = false
      this.send_data.partner.financial = false
      this.send_data.partner.administrative = false
      this.send_data.partner.legal = false
      this.send_data.partner.technical = false
      this.send_data.partner.operational = false
      var r = confirm(`Tem certeza que o Médico deixou de ser Sócio da PJ?`)
    } else {
      this.send_data.partner.status = true
      var r = confirm(`Tem certeza que o Médico voltou a ser Sócio da PJ?`)
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (r) {
      this.requestSave()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
    if (ev.type == "blur") {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)

      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    }
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
    if (ev.type == "blur") {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    }
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.current_partner.id_type == `individual`) {
        if (controller.nameDivTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
          len += 1
        }
        if ((controller.cpfDivTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") || (controller.cpfDivTarget.classList.contains("d-none") && controller.cpf_result && controller.cpf_result.invalid)) {
          len += 1
        }
        if (controller.birthdateDivTarget.classList.contains("d-none") && controller.birthdateInputTarget.value == "") {
          len += 1
        }
      } else if (controller.current_partner.id_type == `legal`) {
        if (controller.tradeNameDivTarget.classList.contains("d-none") && controller.tradeNameInputTarget.value == "") {
          len += 1
        }
        if ((controller.cnpjDivTarget.classList.contains("d-none") && controller.cnpjInputTarget.value == "") || (controller.cnpjDivTarget.classList.contains("d-none") && controller.cnpj_result && controller.cnpj_result.invalid)) {
          len += 1
        }
        if (controller.fantasyNameDivTarget.classList.contains("d-none") && controller.fantasyNameInputTarget.value == "") {
          len += 1
        }
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
