import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx"]

  connect() {
    this.controllerName = "operations--products--clinics--clients--receivables--add--revision"
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.spaceRow = `<td colspan="5" style="height:0rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--save`)
    }
  }

  doRevisionFormHtml() {
    this.revision_data = this.saveController.send_data

    var html = `<div class="row my-3">
                  <div class="col-4">
                    ${this.doInfoHtml()}
                  </div>
                  <div class="col-4">
                    ${this.doPartnersHtml()}
                  </div>
                  <div class="col-4">
                    ${this.doTaxesHtml()}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.saveController.formTitleTarget.innerText = `Revisão Final da Nota Fiscal | Regime ${this.saveController.current_regime.tax_regime_pretty}`

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doTaxesHtml() {
    var otherDeductions = Number(this.saveController.send_data.receivable.unconditioned_amount) + Number(this.saveController.send_data.receivable.conditioned_amount) + Number(this.saveController.send_data.receivable.others_amount_withheld)
    // `${this.numberController.currencyOptionMask(this.revision_data.receivable.others_amount_withheld, { scale: 2 })}`

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Valor dos Serviços e Valores de Impostos
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Condicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.conditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Incondicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.unconditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Base de Cálculo</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.calculation_basis, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>Retenções Federais</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) PIS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.pis_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) COFINS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.cofins_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) IRPJ
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.irpj_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) CSLL
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.csll_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Outras Retenções / Descontos
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(otherDeductions, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Retenções Federais
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) ISS Retido
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.municipal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Valor Líquido</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.net_amount, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total Devido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.total_tax_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(-) Total Retido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask((this.revision_data.receivable.total_tax_amount - this.revision_data.receivable.total_tax_payable))}
                      </td>
                    </tr>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total à Pagar de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.revision_data.receivable.total_tax_payable)}
                      </td>
                    </tr>

                  </tbody>
                </table>`

    return html
  }

  doPartnersHtml() {
    var partnerHtml = ``

    this.revision_data.performers.performers.forEach(element => {
      partnerHtml += `<tr>
                        <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          ${element.name}
                        </td>
                        <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          <strong>Valor</strong>: ${this.numberController.currencyOptionMask(element.amount)}
                        </td>
                      </tr>`
    })

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Médicos
                      </td>
                    </tr>

                    ${partnerHtml}
                  </tbody>
                </table>`

    return html
  }

  doInfoHtml() {

    if (this.revision_data.receivable.taker_type == `legal`) {
      if (this.revision_data.receivable.taker_municipal_tax_number) {
        var municipalNumber = `IM: ${this.revision_data.receivable.taker_municipal_tax_number}`
      } else {
        var municipalNumber = `Sem Inscrição Municipal`
      }

      var federalNumber = `CNPJ: ${this.revision_data.receivable.taker_federal_tax_number_pretty}`
    } else if (this.revision_data.receivable.taker_type == `individual`) {
      var municipalNumber = ``
      var federalNumber = `CPF: ${this.revision_data.receivable.taker_federal_tax_number_pretty}`
    }
    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="6" class="f-065 align-middle px-0 text-left">
                        <strong>Competência</strong>: ${this.revision_data.receivable.date_pretty} <br>
                        <strong>Data</strong>: ${this.revision_data.receivable.issued_on_pretty}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="text-left f-bold f-085 px-0">
                        Tomador
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                        ${this.revision_data.receivable.taker_name} <br>
                        ${federalNumber} <br>
                        ${municipalNumber} <br>
                        <small>
                          ${this.revision_data.receivable.taker_street}, ${this.revision_data.receivable.taker_number}, ${this.revision_data.receivable.taker_complement},
                          ${this.revision_data.receivable.taker_district}, <br> ${this.revision_data.receivable.taker_city_name}/${this.revision_data.receivable.taker_state},
                          CEP: ${this.revision_data.receivable.taker_postal_code_pretty}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="f-065 align-middle px-0 text-left">
                        <strong>Descrição</strong>: ${this.revision_data.receivable.description}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="text-left f-bold f-085 px-0">
                        CTISS
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="f-065 align-middle px-0 text-left">
                        <strong>Código</strong>: ${this.revision_data.receivable.ctiss_code} <br>
                        <strong>Descrição</strong>: ${this.revision_data.receivable.ctiss_description}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Natureza Operação
                      </td>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Cidade Tributação
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.revision_data.receivable.taker_taxation_type_pretty}
                      </td>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.revision_data.receivable.service_city_name}
                      </td>
                    </tr>

                  </tbody>
                </table>`

    return html
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}
