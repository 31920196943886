import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "dates", "days", "mainCard", "bodyTable", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--apurations--regimes--dashboard`
  }

  doRegimesDashboard() {
    var html = `<div data-${this.controllerName}-target="mainDashboard">
                  <div class="row">
                    <div class="col-12 ml-auto py-2" data-${this.controllerName}-target="dates" data-controller="financials--books--apurations--regimes--dates" style="overflow: auto;"></div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 px-2" data-${this.controllerName}-target="list" data-controller="financials--books--apurations--regimes--index"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--dashboards--main--page`).settingsTarget.innerHTML = html)
    }).then(() => {
      
      controller.doIndexPreloader()
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--dates`).controllerDash = controller
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--dates`).doDatesHTML()
      controller.getRegimes()

    })
  }

  getRegimes() {

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Carregando Regimes Tributário da ${this.current_date.name}` })
    this.refreshLoadValue()

    const data = { regime: { active: true, date_id: this.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_regimes` } }
    const url = "/financials/books/apurations/regimes/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentRegimeIds = controller.mapElements(controller.application.regimes, `id`)
          var refreshRegimeIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(regime => {
            if (currentRegimeIds.includes(regime.id) && refreshRegimeIds.includes(regime.id)) {
              controller.application.regimes.forEach((element, i) => {
                if (element.id == regime.id) {
                  controller.application.regimes.splice(controller.application.regimes.indexOf(element), 1, regime)
                }
              })
            } else if (!currentRegimeIds.includes(regime.id) && refreshRegimeIds.includes(regime.id)) {
              controller.application.regimes[controller.application.regimes.length] = regime
            }
          })
        }

        controller.getControllerByIdentifier(`financials--books--apurations--regimes--index`).current_date = controller.current_date
        controller.getControllerByIdentifier(`financials--books--apurations--regimes--index`).controllerDash = controller
        controller.getControllerByIdentifier(`financials--books--apurations--regimes--index`).permission = `financial_settings_regimes`
        controller.getControllerByIdentifier(`financials--books--apurations--regimes--index`).doIndexListHtml()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.stopRefreshing()
      })
      .catch(error => {
        controller.stopRefreshing()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshLoadValue() {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Carregando Regimes Tributário da ${controller.current_date.name}`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()

        var response = { type: `danger`, message: `Tivemos um problema para carrega os Regimes Tributários` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 500);
  }

  doIndexPreloader() {
    var loader = this.getControllerByIdentifier(`app--helpers--loaders`).loader()

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    ${loader}
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      var tableCols = 8
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(tableCols, 10))
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
