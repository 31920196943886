
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `commercial--marketing--sites--show--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToPrincipal() {
    this.getControllerByIdentifier("commercial--marketing--sites--principal--dashboard").doPrincipalDashboard()
  }

  goToHeader() {
    this.getControllerByIdentifier("commercial--marketing--sites--headers--dashboard").doHeaderDashboard()
  }

  goToTeams() {
    this.getControllerByIdentifier("commercial--marketing--sites--teams--dashboard").doTeamsDashboard()
  }

  goToPlatform() {
    this.getControllerByIdentifier("commercial--marketing--sites--platform--dashboard").doPlatformDashboard()
  }

  goToOncall() {
    this.getControllerByIdentifier("commercial--marketing--sites--oncall--dashboard").doOncallDashboard()
  }

  goToServices() {
    this.getControllerByIdentifier("commercial--marketing--sites--services--dashboard").doServicesDashboard()
  }

  goToBios() {
    this.getControllerByIdentifier("commercial--marketing--sites--bios--dashboard").doBiosDashboard()
  }

  goToQuestions() {
    this.getControllerByIdentifier("commercial--marketing--sites--questions--dashboard").doQuestionsDashboard()
  }

  goToTestimonials() {
    this.getControllerByIdentifier("commercial--marketing--sites--testimonials--dashboard").doTestimonialsDashboard()
  }

  goToDifferentials() {
    this.getControllerByIdentifier("commercial--marketing--sites--differentials--dashboard").doDifferentialsDashboard()
  }


  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var principalBtn = `<button type="button" data-action="${this.controllerName}#goToPrincipal ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Principal</button>`
    var headerBtn = `<button type="button" data-action="${this.controllerName}#goToHeader ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Navbar</button>`
    var teamsBtn = `<button type="button" data-action="${this.controllerName}#goToTeams ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Time</button>`
    var platformBtn = `<button type="button" data-action="${this.controllerName}#goToPlatform ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Plataforma</button>`
    var oncallBtn = `<button type="button" data-action="${this.controllerName}#goToOncall ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Descanso Médico</button>`

    if (this.application.permissions.marketing_site_services.can_update) {
      var servicesBtn = `<button type="button" data-action="${this.controllerName}#goToServices ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Serviços</button>`
    } else {
      var servicesBtn = ``
    }

    if (this.application.permissions.marketing_site_teams.can_update) {
      var biosBtn = `<button type="button" data-action="${this.controllerName}#goToBios ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Mini-Bios</button>`
    } else {
      var biosBtn = ``
    }

    if (this.application.permissions.marketing_site_questions.can_update) {
      var questionsBtn = `<button type="button" data-action="${this.controllerName}#goToQuestions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Perguntas</button>`
    } else {
      var questionsBtn = ``
    }

    if (this.application.permissions.marketing_site_testimonials.can_update) {
      var testimonialsBtn = `<button type="button" data-action="${this.controllerName}#goToTestimonials ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Depoimentos</button>`
    } else {
      var testimonialsBtn = ``
    }

    if (this.application.permissions.marketing_site_differentials.can_update) {
      var differentialsBtn = `<button type="button" data-action="${this.controllerName}#goToDifferentials ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Diferenciais</button>`
    } else {
      var differentialsBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${principalBtn}
                  ${headerBtn}
                  ${servicesBtn}
                  ${teamsBtn}
                  ${biosBtn}
                  ${platformBtn}
                  ${testimonialsBtn}
                  ${differentialsBtn}
                  ${oncallBtn}
                  ${questionsBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

    this.stopAllRefreshing()
  }

  dashboardToggleBtn(title) {
    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  stopAllRefreshing() {
    this.getControllerByIdentifier("commercial--marketing--sites--principal--dashboard").stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--headers--dashboard").stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--teams--dashboard").stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--platform--dashboard").stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--oncall--dashboard").stopRefreshing()
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
