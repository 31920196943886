import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "chartAccount", "users", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--entities--settings`
    this.doSettingsHtml()
  }

  doSettingsHtml() {
    var html = `<div class="row">
                  <div class="col-12 px-1" data-${this.controllerName}-target="users" data-controller="operations--products--tax-filings--entities--users"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
