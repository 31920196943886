import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "nameDiv", "nameInputDiv", "nameInput", "cpfDiv", "cpfInputDiv", "cpfInput",
    "birthdateDiv", "birthdateInputDiv", "birthdateInput", "saveBtn", "editBtn", "cancelBtn",
    "sexDiv", "sexInputDiv", "sexDropdown", "sexDropdownBtn", "sexInput", "sexList",
    "descriptionDiv", "descriptionInputDiv", "descriptionInput", "roleList",
    "financialCheckbox", "administrativeCheckbox", "sameCityCheckbox", "legalCheckbox",

    "cpfRow", "cnpjRow", "cnpjDiv", "cnpjInputDiv", , "cnpjRow", "cnpjDiv",
    "cnpjInput", "nameRow", "brithdateRow", "sexRow",
    "tradeNameRow", "tradeNameDiv", "tradeNameInputDiv", "tradeNameInput",
    "fantasyNameRow", "fantasyNameDiv", "fantasyNameInputDiv", "fantasyNameInput",
    "municipalNumberRow", "municipalNumberDiv", "municipalNumberInputDiv", "municipalNumberInput",


    "notesDiv", "notesInputDiv", "notesInput", "technicalCheckbox", "operationalCheckbox"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--takers--principal`
    this.current_taker = this.getControllerByIdentifier(`operations--products--clinics--regularizations--takers--show`).current_taker
  }

  editTaker(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cpfInputTarget.value = this.cpfDivTarget.innerText.trim()
      this.cpfInputTarget.focus()
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.add("d-none")
      this.birthdateInputDivTarget.classList.remove("d-none")
      this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
      this.birthdateInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.add("d-none")
      this.sexInputDivTarget.classList.remove("d-none")
      this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
      this.sexInputTarget.focus()
    } else if (this.field == "notes") {
      this.notesDivTarget.classList.add("d-none")
      this.notesInputDivTarget.classList.remove("d-none")
      this.notesInputTarget.value = this.notesDivTarget.innerText.trim()
      this.notesInputTarget.focus()
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.add("d-none")
      this.descriptionInputDivTarget.classList.remove("d-none")
      this.descriptionInputTarget.value = this.descriptionDivTarget.innerText.trim()
      this.descriptionInputTarget.focus()
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.add("d-none")
      this.tradeNameInputDivTarget.classList.remove("d-none")
      this.tradeNameInputTarget.value = this.tradeNameDivTarget.innerText.trim()
      this.tradeNameInputTarget.focus()
    } else if (this.field == "fantasyName") {
      this.fantasyNameDivTarget.classList.add("d-none")
      this.fantasyNameInputDivTarget.classList.remove("d-none")
      this.fantasyNameInputTarget.value = this.fantasyNameDivTarget.innerText.trim()
      this.fantasyNameInputTarget.focus()
    } else if (this.field == "municipalNumber") {
      this.municipalNumberDivTarget.classList.add("d-none")
      this.municipalNumberInputDivTarget.classList.remove("d-none")
      this.municipalNumberInputTarget.value = this.municipalNumberDivTarget.innerText.trim()
      this.municipalNumberInputTarget.focus()
    } else if (this.field == "cnpj") {
      this.cnpjDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")
      this.cnpjInputTarget.value = this.cnpjDivTarget.innerText.trim()
      this.cnpjInputTarget.focus()
    }
  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.current_taker.name
    } else if (this.field == "tradeName") {
      this.tradeNameDivTarget.classList.remove("d-none")
      this.tradeNameInputDivTarget.classList.add("d-none")
      this.tradeNameDivTarget.innerText = this.current_taker.name
    } else if (this.field == "fantasyName") {
      this.fantasyNameDivTarget.classList.remove("d-none")
      this.fantasyNameInputDivTarget.classList.add("d-none")
      this.fantasyNameDivTarget.innerText = this.current_taker.company_fantasy_name
    } else if (this.field == "municipalNumber") {
      this.municipalNumberDivTarget.classList.remove("d-none")
      this.municipalNumberInputDivTarget.classList.add("d-none")
      this.municipalNumberDivTarget.innerText = this.current_taker.company_municipal_number
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.current_taker.id_number_pretty
    } else if (this.field == "cnpj") {
      this.cnpjDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")
      this.cnpjDivTarget.innerText = this.current_taker.id_number_pretty
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.current_taker.account_birthdate_pretty
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.current_taker.account_sex_pretty
    } else if (this.field == "notes") {
      this.notesDivTarget.classList.remove("d-none")
      this.notesInputDivTarget.classList.add("d-none")
      this.notesDivTarget.innerText = this.current_taker.notes
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.current_taker.description
    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.current_taker.id_type == `individual`) {
      if (this.nameDivTarget.classList.contains("d-none")) {
        this.nameDivTarget.classList.remove("d-none")
        this.nameInputDivTarget.classList.add("d-none")
        this.nameDivTarget.innerText = this.nameInputTarget.value
      }

      if (this.cpfDivTarget.classList.contains("d-none")) {
        this.cpfDivTarget.classList.remove("d-none")
        this.cpfInputDivTarget.classList.add("d-none")
        this.cpfDivTarget.innerText = this.cpfInputTarget.value
      }

      if (this.birthdateDivTarget.classList.contains("d-none")) {
        this.birthdateDivTarget.classList.remove("d-none")
        this.birthdateInputDivTarget.classList.add("d-none")
        this.birthdateDivTarget.innerText = this.birthdateInputTarget.value
      }

      if (this.sexDivTarget.classList.contains("d-none")) {
        this.sexDivTarget.classList.remove("d-none")
        this.sexInputDivTarget.classList.add("d-none")
        this.sexDivTarget.innerText = this.sexInputTarget.innerText
      }
    } else if (this.current_taker.id_type == `legal`) {
      if (this.tradeNameDivTarget.classList.contains("d-none")) {
        this.tradeNameDivTarget.classList.remove("d-none")
        this.tradeNameInputDivTarget.classList.add("d-none")
        this.tradeNameDivTarget.innerText = this.tradeNameInputTarget.value
      }

      if (this.fantasyNameDivTarget.classList.contains("d-none")) {
        this.fantasyNameDivTarget.classList.remove("d-none")
        this.fantasyNameInputDivTarget.classList.add("d-none")
        this.fantasyNameDivTarget.innerText = this.fantasyNameInputTarget.value
      }

      if (this.municipalNumberDivTarget.classList.contains("d-none")) {
        this.municipalNumberDivTarget.classList.remove("d-none")
        this.municipalNumberInputDivTarget.classList.add("d-none")
        this.municipalNumberDivTarget.innerText = this.municipalNumberInputTarget.value
      }

      if (this.cnpjDivTarget.classList.contains("d-none")) {
        this.cnpjDivTarget.classList.remove("d-none")
        this.cnpjInputDivTarget.classList.add("d-none")
        this.cnpjDivTarget.innerText = this.cnpjInputTarget.value
      }
    }

    if (this.descriptionDivTarget.classList.contains("d-none")) {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.descriptionInputTarget.value
    }

    if (this.notesDivTarget.classList.contains("d-none")) {
      this.notesDivTarget.classList.remove("d-none")
      this.notesInputDivTarget.classList.add("d-none")
      this.notesDivTarget.innerText = this.notesInputTarget.value
    }
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  enterName(ev) {
    if (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) {
      this.saveTaker()
    }
  }

  saveTaker() {
    this.send_data = { current_user: {}, taker: {}, account: {}, company: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.current_user.feature = this.permission


      controller.send_data.taker.id = this.current_taker.id
      controller.send_data.taker.record_id = controller.current_taker.record_id

      controller.send_data.taker.notes = controller.notesDivTarget.innerText.trim()
      controller.send_data.taker.description = controller.descriptionDivTarget.innerText.trim()
      if (controller.sameCityCheckboxTarget.checked) {
        controller.send_data.taker.taxation_type = `within_city`
      } else {
        controller.send_data.taker.taxation_type = `outside_city`
      }

      if (controller.current_taker.id_type == `individual`) {
        controller.send_data.account.is_account = true
        controller.send_data.company.is_company = false

        controller.send_data.account.id = controller.current_taker.record_id
        controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
        controller.send_data.account.cpf = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
        controller.send_data.account.birthdate = controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.birthdateDivTarget.innerText.trim())
        controller.send_data.account.sex = controller.sexInputTarget.dataset.sex

        controller.send_data.taker.id_type = "individual"
        controller.send_data.taker.id_number = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
        controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
        controller.send_data.account.kind = `patient`
      } else if (controller.current_taker.id_type == `legal`) {
        controller.send_data.account.is_account = false
        controller.send_data.company.is_company = true

        controller.send_data.company.id = controller.current_taker.record_id
        controller.send_data.company.trade_name = controller.tradeNameDivTarget.innerText.trim()
        controller.send_data.company.name = controller.fantasyNameDivTarget.innerText.trim()
        controller.send_data.company.municipal_number = controller.municipalNumberDivTarget.innerText.trim()
        controller.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(controller.cnpjDivTarget.innerText.trim())
        controller.send_data.company.kind = `taker`

        controller.send_data.taker.id_type = "legal"
        controller.send_data.taker.id_number = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cnpjDivTarget.innerText.trim())
        controller.send_data.account.name = controller.tradeNameDivTarget.innerText.trim()
      }

      controller.stopRefreshing()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/operations/products/clinics/regularizations/takers/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          var taker = response.data.cln
          this.current_taker = taker
          if (taker.active) {
            this.application.takers.forEach((element, i) => {
              if (element.id == taker.id) {
                this.application.takers.splice(this.application.takers.indexOf(element), 1, taker)
              }
            })
          } else {
            this.application.takers.forEach((element, i) => {
              if (element.id == taker.id) {
                this.application.takers.splice(this.application.takers.indexOf(element), 1)
              }
            })
          }
          this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--show").viewTitleTarget.innerText = this.current_taker.name

          var controllerShow = this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--show")
          controllerShow.current_taker = taker
          controllerShow.doTakerHtml()
          this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  doPrincipalHtml() {
    if (this.application.permissions[this.permission].can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editTaker" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.current_taker.status) {
      var statusBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-status="${this.current_taker.status}" data-action="click->${this.controllerName}#changeTakerStatus">
                          <span class="material-icons md-sm md-success">toggle_on</span>
                          <span class="mc-tooltiptext">Contrato Foi Encerrado?</span>
                        </button>`
    } else {
      var statusBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-status="${this.current_taker.status}" data-action="click->${this.controllerName}#changeTakerStatus">
                          <span class="material-icons md-sm md-danger">toggle_off</span>
                          <span class="mc-tooltiptext">Contrato Será Reativado?</span>
                        </button>`
    }

    var statusRow = `<tr data-field="status" data-${this.controllerName}-target="statusRow">
                        <th class="table-25 px-0 f-065 align-middle"><strong>Status Contrato</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="statusDiv">
                            ${this.current_taker.status_pretty}
                          </div>
                        </td>
                        <td class="table-15 align-middle"></td>
                        <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                          ${statusBtn}
                        </td>
                      </tr>`

    if (this.current_taker.id_type == `individual`) {

      var nameRow = `<tr data-field="name" data-${this.controllerName}-target="nameRow">
                        <th class="table-25 px-0 f-065 align-middle"><strong>Nome</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="nameDiv">
                            ${this.current_taker.name}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                            <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterName" required>
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_taker.name}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit}
                          ${cancel}
                        </td>
                      </tr>`
      var cpfRow = `<tr data-field="cpf" data-${this.controllerName}-target="cpfRow">
                      <th class="table-30 px-0 f-065 align-middle"><strong>CPF</strong></th>
                      <td class="table-45 f-065 align-middle">
                        <div data-${this.controllerName}-target="cpfDiv">
                          ${this.current_taker.id_number_pretty}
                        </div>
                        <div class="d-none" data-${this.controllerName}-target="cpfInputDiv">
                          <input data-${this.controllerName}-target="cpfInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask">
                        </div>
                      </td>
                      <td class="align-middle text-center" data-copy="${this.current_taker.cpf}">${copyPath}</td>
                      <td class="align-middle text-center">
                        ${edit} 
                        ${cancel}
                      </td>
                    </tr>`
      var birthdateRow = `<tr data-field="birthdate" data-${this.controllerName}-target="brithdateRow">
                            <th class="table-30 px-0 f-065 align-middle"><strong>DN</strong></th>
                            <td class="table-45 f-065 align-middle">
                              <div data-${this.controllerName}-target="birthdateDiv">
                                ${this.current_taker.account_birthdate_pretty}
                              </div>
                              <div class="d-none" data-${this.controllerName}-target="birthdateInputDiv">
                                <input data-${this.controllerName}-target="birthdateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                              </div>
                            </td>
                            <td class="align-middle text-center" data-copy="${this.current_taker.cpf}">${copyPath}</td>
                            <td class="align-middle text-center">
                              ${edit}
                              ${cancel}
                            </td>
                          </tr>`
      var sexRow = `<tr data-field="sex" data-${this.controllerName}-target="sexRow">
                      <th class="table-30 px-0 f-065 align-middle"><strong>Sexo</strong></th>
                      <td class="table-45 f-065 align-middle">
                        <div data-${this.controllerName}-target="sexDiv">
                          ${this.current_taker.account_sex_pretty}
                        </div>
                        <div class="d-none" data-${this.controllerName}-target="sexInputDiv">
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="sexList">
                                <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td></td>
                      <td class="align-middle text-center">
                        ${edit}
                        ${cancel}
                      </td>
                    </tr>`

      var tradeRow = ``
      var fantasyRow = ``
      var cnpjRow = ``
      var municipalRow = ``
    } else if (this.current_taker.id_type == `legal`) {
      var tradeRow = `<tr data-field="tradeName" data-${this.controllerName}-target="tradeNameRow">
                        <th class="table-30 px-0 f-065 align-middle"><strong>Razão Social</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="tradeNameDiv">
                            ${this.current_taker.name}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="tradeNameInputDiv">
                            <input data-${this.controllerName}-target="tradeNameInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_taker.name}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit} 
                          ${cancel}
                        </td>
                      </tr>`
      var fantasyRow = `<tr data-field="fantasyName" data-${this.controllerName}-target="fantasyNameRow">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Nome Fantasia</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="fantasyNameDiv">
                              ${this.current_taker.company_fantasy_name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="fantasyNameInputDiv">
                              <input data-${this.controllerName}-target="fantasyNameInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.current_taker.company_fantasy_name}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit} 
                            ${cancel}
                          </td>
                        </tr>`
      var municipalRow = `<tr data-field="municipalNumber" data-${this.controllerName}-target="municipalNumberRow">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Inscrição Municipal</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="municipalNumberDiv">
                              ${this.current_taker.company_municipal_number}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="municipalNumberInputDiv">
                              <input data-${this.controllerName}-target="municipalNumberInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.current_taker.company_municipal_number}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit} 
                            ${cancel}
                          </td>
                        </tr>`
      var cnpjRow = `<tr data-field="cnpj" data-${this.controllerName}-target="cnpjRow">
                        <th class="table-30 px-0 f-065 align-middle"><strong>CNPJ</strong></th>
                        <td class="table-45 f-065 align-middle">
                          <div data-${this.controllerName}-target="cnpjDiv">
                            ${this.current_taker.id_number_pretty}
                          </div>
                          <div class="d-none" data-${this.controllerName}-target="cnpjInputDiv">
                            <input data-${this.controllerName}-target="cnpjInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask">
                          </div>
                        </td>
                        <td class="align-middle text-center" data-copy="${this.current_taker.id_number_pretty}">${copyPath}</td>
                        <td class="align-middle text-center">
                          ${edit} 
                          ${cancel}
                        </td>
                      </tr>`

      var nameRow = ``
      var cpfRow = ``
      var birthdateRow = ``
      var sexRow = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search" style="font-size:80%;">
                      <tbody>
                        
                        ${nameRow}
                        ${cpfRow}
                        ${birthdateRow}
                        ${sexRow}
                        ${tradeRow}
                        ${fantasyRow}
                        ${cnpjRow}
                        ${municipalRow}

                        <tr data-field="description">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Descrição</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="descriptionDiv">
                              ${this.current_taker.description}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="descriptionInputDiv">
                              <input data-${this.controllerName}-target="descriptionInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="notes">
                          <th class="table-25 px-0 f-065 align-middle"><strong>Observações</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="notesDiv">
                              ${this.current_taker.notes}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="notesInputDiv">
                              <textarea data-${this.controllerName}-target="notesInput" class="form-control p-1 s-title-0p6rem" type="text" required></textarea>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        ${statusRow}

                        <tr>
                          <th class="table-25 px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="sameCityCheck" data-${this.controllerName}-target="sameCityCheckbox" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="sameCityCheck">Mesma Cidade</label>
                              <span class="mc-tooltiptext">São da mesma cidade?</span>
                            </div>
                          </th>
                          <td class="table-45 f-065 align-middle">
                            
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        
                        
                        <tr>
                          <td colspan="4" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveTaker" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {

      if (controller.current_taker.taxation_type == `within_city`) {
        controller.sameCityCheckboxTarget.checked = true
      } else if (controller.current_taker.taxation_type == `outside_city`) {
        controller.sameCityCheckboxTarget.checked = false
      }

      if (controller.current_taker.id_type == `individual`) {
        controller.sexInputTarget.dataset.sex = controller.current_taker.account_sex
      } else if (controller.current_taker.id_type == `legal`) {


      }

    })
  }

  changeTakerStatus(ev) {
    var actualStatus = this.getControllerByIdentifier("app--helpers--strings").fromStringToBoolean(ev.currentTarget.dataset.status)
    this.actionMode = `edit`
    this.send_data = { current_user: {}, taker: {}, account: {}, company: {} }
    this.send_data.account.is_account = false
    this.send_data.company.is_company = false

    this.send_data.taker.id = this.current_taker.id
    if (actualStatus) {
      this.send_data.taker.status = false
      var r = confirm(`Tem certeza que o Contrato foi Encerrado?`)
    } else {
      this.send_data.taker.status = true
      var r = confirm(`Tem certeza que o Contrato foi Reativado?`)
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (r) {
      this.requestSave()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
    if (ev.type == "blur") {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)

      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    }
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
    if (ev.type == "blur") {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    }
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.current_taker.id_type == `individual`) {
        if (controller.nameDivTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
          len += 1
        }

        if ((controller.cpfDivTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") || (controller.cpfDivTarget.classList.contains("d-none") && controller.cpf_result && controller.cpf_result.invalid)) {
          len += 1
        }

        if (controller.birthdateDivTarget.classList.contains("d-none") && controller.birthdateInputTarget.value == "") {
          len += 1
        }
      } else if (controller.current_taker.id_type == `legal`) {
        if (controller.tradeNameDivTarget.classList.contains("d-none") && controller.tradeNameInputTarget.value == "") {
          len += 1
        }

        if ((controller.cnpjDivTarget.classList.contains("d-none") && controller.cnpjInputTarget.value == "") || (controller.cnpjDivTarget.classList.contains("d-none") && controller.cnpj_result && controller.cnpj_result.invalid)) {
          len += 1
        }

        if (controller.fantasyNameDivTarget.classList.contains("d-none") && controller.fantasyNameInputTarget.value == "") {
          len += 1
        }

        if (controller.municipalNumberDivTarget.classList.contains("d-none") && controller.municipalNumberInputTarget.value == `Favor adicionar a Inscrição Municipal`) {
          len += 1
        }
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
