
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--dealflow--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToProspecting() {
    window.open(`/a/fluxo-prospeccao`, `_self`)
  }

  goToClosing() {
    window.open(`/a/fluxo-fechamento`, `_self`)
  }

  goToRecords() {
    window.open(`/a/todas-oportunidades`, `_self`)
  }


  doSubmenuHtml() {

    if (this.application.permissions.sales_opportunity_prospection_flow.can_index) {
      var prospectingLink = `<span data-action="click->${this.controllerName}#goToProspecting" class="dropdown-item py-1 pointer dropdown-submenu-item">Fluxo Prospecção</span>`
    } else {
      var prospectingLink = ``
    }

    if (this.application.permissions.sales_opportunity_closing_flow.can_index) {
      var closingLink = `<span data-action="click->${this.controllerName}#goToClosing" class="dropdown-item py-1 pointer dropdown-submenu-item">Fluxo Fechamento</span>`
    } else {
      var closingLink = ``
    }

    if (this.application.permissions.sales_opportunities_entities.can_index) {
      var recordLink = `<span data-action="click->${this.controllerName}#goToRecords" class="dropdown-item py-1 pointer dropdown-submenu-item">Todas as Oportunidades</span>`
    } else {
      var recordLink = ``
    }

    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${prospectingLink}
                          ${closingLink}
                          ${recordLink}
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
