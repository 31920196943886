import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--testimonials`
    this.pageNumber = 0
    this.numberPages = []

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    }
  }

  doHtml() {
    var html = `<section class="mws-section-testimonials d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="mws-title-section mb-5">${this.application.site.testimonials_title}</h1>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-center align-items-center">
                      <div class="row w-100" data-${this.controllerName}-target="cards"></div>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.siteController.contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setTestimonials()
    })
  }

  setTestimonials() {
    var current_testimonials = []

    this.application.site.testimonials.forEach(testimonial => {
      if (testimonial.status) {
        current_testimonials[current_testimonials.length] = testimonial
      }
    })
    current_testimonials = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_testimonials, `order`)
    this.itemsOnPage = 1

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_testimonials.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_testimonials[x] !== undefined) {
          internPage[internPage.length] = current_testimonials[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    if (pages.length > 0) {
      this.doCarouselData(pages[pageNumber])
    }
  }

  doCarouselData(data) {
    var html = ``

    data.forEach(testimonial => {
      var link = this.siteController.setLink(testimonial.link_cta)
      var colClass = `col-12 d-flex justify-content-center text-center`
      var cardClass = `ws-card-depo mws-card-depo`
      // <div class="${cardClass} pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">

      html += `<div class="${colClass}">
                  <div class="${cardClass} pointer">
                    <div class="container">
                      <h3 class="mws-card-title">${testimonial.title}</h3>
                      <p class="mws-card-text-small">
                        ${testimonial.description}
                      </p>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon mws-carousel-indicator"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5 d-flex">
                  <span class="material-icons md-dark mr-auto pointer" data-action="click->${this.controllerName}#previousPage">arrow_back_ios</span>
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                  <span class="material-icons md-dark ml-auto pointer" data-action="click->${this.controllerName}#nextPage">arrow_forward_ios</span>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      if (controller.carouselIndicatorTargets.length > 8) {

        controller.carouselIndicatorTargets.forEach((element, i) => {
          element.classList.add(`d-none`)
        })

        for (var index = 0; index < 3; index++) {
          if (controller.carouselIndicatorTargets[controller.pageNumber - index]) {
            controller.carouselIndicatorTargets[controller.pageNumber - index].classList.remove(`d-none`)
          }
          if (controller.carouselIndicatorTargets[controller.pageNumber + index]) {
            controller.carouselIndicatorTargets[controller.pageNumber + index].classList.remove(`d-none`)
          }
        }
      }

      controller.carouselIndicatorTargets[controller.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = Number(ev.currentTarget.dataset.slideTo)

    this.setTestimonials()
  }

  previousPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
    }
    this.setTestimonials()
  }

  nextPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
    }
    this.setTestimonials()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
