import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "postCardBody", "calculator", "postRow", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `users--dashboards--main--content`
    this.contents = []
    this.maxHeight = 0
    this.contentPreloader()
    this.calculatorPreloader()
    this.getPosts()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  doContentCard() {
    var cardHtml = ``
    var contents = []

    this.contents.forEach(element => {
      if (element.published) {
        contents[contents.length] = element
      }
    })
    
    contents = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(contents, `published_at_to_time`)

    contents.forEach(element => {

      if (element.kind == `text`) {
        if (element.has_cover) {
          var postIcon = `<img class="mr-2" src="${element.cover_url}" alt="descancomedico" style="width:2rem;">`
        } else {
          var postIcon = `<span class="material-icons md-dark mr-2">article</span>`
        }
        var postTooltip = `<span class="mc-tooltiptext">Ler Artigo</span>`
      } else if (element.kind == `podcast`) {
        if (element.has_cover) {
          var postIcon = `<img class="mr-2" src="${element.cover_url}" alt="descancomedico" style="width:2rem;">`
        } else {
          var postIcon = `<span class="material-icons md-dark mr-2">radio</span>`
        }
        var postTooltip = `<span class="mc-tooltiptext">Ouvir Podcast</span>`
      } else if (element.kind == `video`) {
        if (element.has_cover) {
          var postIcon = `<img class="mr-2" src="${element.cover_url}" alt="descancomedico" style="width:2rem;">`
        } else {
          var postIcon = `<span class="material-icons md-dark mr-2">videocam</span>`
        }
        var postTooltip = `<span class="mc-tooltiptext">Ver Vídeo</span>`
      }

      cardHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.published_at_full}</span>
                      </div>
                      <div class="card-body mc-tooltip pointer" data-url="${element.blog_url}" data-action="click->${this.controllerName}#goToURL" data-${this.controllerName}-target="postCardBody">
                        <div class="media d-flex align-items-center">
                          ${postIcon}
                          <span class="text-left f-065">${element.title}</span>
                        </div>
                        ${postTooltip}
                      </div>
                    </div>
                  </div>`
    })


    var html = `<h6 class="mb-0 text-center">Últimas do Descanso Médico</h6>
                <hr class="mt-1">
                <div class="row o-scroll" data-${this.controllerName}-target="postRow">
                  ${cardHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.postRowTarget.style.height = ($(window).height() * 0.5) + "px"
      var bodyHeights = []
      controller.postCardBodyTargets.forEach(element => {
        if (element.offsetHeight > this.maxHeight) {
          this.maxHeight = element.offsetHeight
        }
        bodyHeights[bodyHeights.length] = element.offsetHeight
      })
      controller.postCardBodyTargets.forEach(element => {
        element.style.height = controller.maxHeight + `px`
      })

      if (controller.getControllerByIdentifier("users--dashboards--main--doctor") && controller.getControllerByIdentifier("users--dashboards--main--doctor").hasProductCardBodyTarget) {
        controller.getControllerByIdentifier("users--dashboards--main--doctor").productCardBodyTargets.forEach(element => {
          element.style.height = controller.maxHeight + `px`
        })
      }

      if (controller.getControllerByIdentifier("users--dashboards--main--helper") && controller.getControllerByIdentifier("users--dashboards--main--helper").hasProductCardBodyTarget) {
        controller.getControllerByIdentifier("users--dashboards--main--helper").productCardBodyTargets.forEach(element => {
          element.style.height = controller.maxHeight + `px`
        })
      }
      
    })
  }

  doCalculatorCard() {

    var calcClinic = `https://www.medcapital.com.br/c/calculadora-abertura-pj-medica`
    var calcReturn = `https://www.medcapital.com.br/c/calculadora-completa-de-imposto-de-renda`
    var calcBooking = `https://www.medcapital.com.br/c/calculadora-livro-caixa`

    var html = `<h6 class="mb-0 text-center">Calculadoras</h6>
                <hr class="mt-1">
                <div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>PJ Médica</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${calcClinic}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">calculate</span>
                        <span class="mc-tooltiptext">Calculadora PJ Médica</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>Livro-Caixa Médico</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${calcBooking}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">calculate</span>
                        <span class="mc-tooltiptext">Calculadora Livro-Caixa Médico</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>IRPF Médico</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${calcReturn}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">calculate</span>
                        <span class="mc-tooltiptext">Calculadora IRPF Médico</span>
                      </div>
                    </div>
                  </div>
                </div>`

    this.calculatorTarget.innerHTML = html
  }

  getPosts() {
    var data = { post: { active: true }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/contents/posts/entities/list_dashboard"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.contents = response.data.cln
          controller.doContentCard()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.doCalculatorCard()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  contentPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-6 pl-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-6 pr-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-6 pr-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-6 pr-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.contentTarget.innerHTML = html
  }

  calculatorPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.calculatorTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
