import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `app--webhooks--dashboard--main`
    this.account_kind = this.application.current_user.account_kind
    this.application.permissions = {}
    this.application.webhooks = []

    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="app--webhooks--hooks--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier("app--webhooks--dashboard--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("app--webhooks--hooks--dashboard").doWebhooksDashboard()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getWebhooks() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Webhooks`, value: 80 })

    var data = { webhook: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `app_webhook_urls` } }
    var url = "/app/webhooks/hooks/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.webhooks = response.data.cln

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `app_webhooks` }, current_user: { current_user_id: this.application.current_user.id, feature: `app_webhook_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`app_webhook_permissions`]

        controller.getWebhooks()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}