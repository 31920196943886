import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "asdf", "pendencyCount", "sideBarReport",
                    "refreshListBtn", "optionBtn", "searchTable", "accrualStageBtn", "dueStageBtn",
                    "bindSum", "clearListBtn", "multipleCheck", "masterMultipleCheckDiv", "masterMultipleCheck",
                    "searchInput"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 8
    this.sort = { mode: `desc`, field: `accrual_date_to_time` }
    this.dateKind = `accrual`
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doRemunerationList() {
    var accrualBtn = `<span class="badge badge-secondary-primary mx-2 pointer" data-date-kind="accrual" data-action="click->${this.controllerName}#setRemunerationsDateEv" data-${this.controllerName}-target="accrualStageBtn">Competência</span>`
    var dueBtn = `<span class="badge badge-secondary-success mx-2 pointer" data-date-kind="due" data-action="click->${this.controllerName}#setRemunerationsDateEv" data-${this.controllerName}-target="dueStageBtn">Vencimento</span>`

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addRemuneration" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Remuneração</span>`
      var copyLine = `<span data-action="click->${this.controllerName}#checkToCopyRemuneration" class="dropdown-item py-1 pointer dropdown-submenu-item">Copiar Remuneração</span>`
    } else {
      var addLine = ``
      var copyLine = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var destroyLine = `<span data-action="click->${this.controllerName}#checkToDestroyRemuneration" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Remuneração</span>`
    } else {
      var destroyLine = ``
    }


    var sumRemunerations = `<span data-action="click->${this.controllerName}#checkToSumRemunerations" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Remunerações</span>`
    sumRemunerations += `<div class="dropdown-divider"></div>`
    if (this.application.permissions[this.permission].can_assign) {
      var payLine = `<span data-action="click->${this.controllerName}#checkToAddPayables" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Contas à Pagar</span>`
    } else {
      var payLine = ``
    }
    if (this.application.permissions[this.permission].can_assign) {
      var provisionLine = `<span data-action="click->${this.controllerName}#checkToAddTransactions" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Transação</span>`
    } else {
      var provisionLine = ``
    }

    var refreshDueLine = `<span data-action="click->${this.controllerName}#refreshRemunerations" data-date-kind="due" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Remunerações Vencimento</span>`
    var refreshAccrualLine = `<span data-action="click->${this.controllerName}#refreshRemunerations" data-date-kind="accrual" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Remunerações Competência</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${copyLine}
                          ${refreshAccrualLine}
                          ${refreshDueLine}
                          ${destroyLine}
                          ${sumRemunerations}
                          ${payLine}
                          ${provisionLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="refreshListBtn" data-action="click->${this.controllerName}#refreshList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 card-title-table-list">Remunerações | ${accrualBtn} | ${dueBtn}</h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable keyup->${this.controllerName}#enterShowRemunerations" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-2 align-middle">
                                    <div class="custom-control custom-checkbox px-2 d-none" data-${this.controllerName}-target="masterMultipleCheckDiv">
                                      <input type="checkbox" class="custom-control-input" id="masterCheck" data-${this.controllerName}-target="masterMultipleCheck" data-action="click->${this.controllerName}#checkAll">
                                      <label class="custom-control-label f-065" for="masterCheck"></label>
                                    </div>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                    Competência
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="accrual_date_to_time" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="accrual_date_to_time" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                    Pagamento
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                    Tipo
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="allowance" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="allowance" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">I/O</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Valor</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Conta</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center" colspan="2">Status</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))

      // controller.doDataTable()
      controller.setRemunerationsDate()
    })
  }

  doDataTable() {
    var current_remunerations = []

    this.application.remunerations.forEach(element => {
      var dateId = ``
      if (this.dateKind == `accrual`) {
        dateId = element.date_id
      } else if (this.dateKind == `due`) {
        dateId = element.due_date_id
      }

      if (element.contract_id == this.current_contract.id && dateId == this.application.current_date.id) {
        current_remunerations[current_remunerations.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var remunerations = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_remunerations, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var remunerations = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_remunerations, this.sort.field)
    }

    if (remunerations.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(remunerations.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (remunerations[x] !== undefined) {
            internPage[internPage.length] = remunerations[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: remunerations.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Remunerações para ${this.application.current_date.name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.remunerationTablePartial(element, data.length))
      })
    }
  }

  remunerationTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var statusPretty = ``
    var badgePretty = ``
    var iconPretty = ``
    if (element.status_pretty) {
      badgePretty = `badge-success`
      iconPretty = `price_check`
      if (element.kind == `payable`) {
        statusPretty = `Pago`
      } else if (element.kind == `to_provision`) {
        statusPretty = `Provisionado`
      }
    } else {
      badgePretty = `badge-danger`
      iconPretty = `money_off_csred`
      if (element.kind == `payable`) {
        statusPretty = `Não Pago`
      } else if (element.kind == `to_provision`) {
        statusPretty = `Não Provisionado`
      }
    }

    var status = `<span class="mc-tooltip badge ${badgePretty}">
                    <span class="material-icons md-sm md-white pointer">${iconPretty}</span>
                    <span class="mc-tooltiptext">${statusPretty}</span>
                  </span>`

    if (element.has_obj) {
      var disabledTag = `disabled`
      badgePretty = `badge-success`
      iconPretty = `done`
      statusPretty = `Configurado`
    } else {
      var disabledTag = ``
      badgePretty = `badge-danger`
      iconPretty = `clear`
      statusPretty = `Não Configurado`
    }    

    var objIcon = `<span class="mc-tooltip badge ${badgePretty}">
                      <span class="material-icons md-sm md-white pointer">${iconPretty}</span>
                      <span class="mc-tooltiptext">${statusPretty}</span>
                    </span>`

    var disabledKind = ``
    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `sum_remunerations`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `add_payables`) {
      if (element.kind == `payable`) {
        disabledKind = ``
      } else if (element.kind == `to_provision`) {
        disabledKind = `disabled`
      }
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input ${disabledTag} ${disabledKind} type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#uniqueRemuneration">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `add_transactions`) {
      if (element.kind == `payable`) {
        disabledKind = `disabled`
      } else if (element.kind == `to_provision`) {
        disabledKind = ``
      }
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input ${disabledTag} ${disabledKind} type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#uniqueRemuneration">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `destroy_remuneration`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#destroyRemunerationEv">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `copy_remuneration`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#copyRemunerationEv">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
    //   <small class="mb-0 mc-tooltip grab">
    //     <span data-controller="app--helpers--copy" data-copy="${element.record_number}" data-action="click->app--helpers--copy#copy">${element.record_number_pretty}</span>
    //     <span class="mc-tooltiptext">Clique para Copiar</span>
    //   </small>
    // </td>`


    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#editRemunerationEv">${element.accrual_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#editRemunerationEv">${element.due_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#editRemunerationEv">${element.allowance}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.kind_input_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${objIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1500">1.500</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  setRemunerationsDateEv(ev) {
    this.dateKind = ev.currentTarget.dataset.dateKind

    this.setRemunerationsDate()
  }

  setRemunerationsDate() {
    if (this.dateKind == `accrual`) {
      this.date_kind_pretty = `Competência`
      this.accrualStageBtnTarget.classList.remove(`badge-secondary-primary`)
      this.accrualStageBtnTarget.classList.add(`badge-primary`)
      this.dueStageBtnTarget.classList.add(`badge-secondary-success`)
      this.dueStageBtnTarget.classList.remove(`badge-success`)
    } else if (this.dateKind == `due`) {
      this.date_kind_pretty = `Vencimento`
      this.accrualStageBtnTarget.classList.add(`badge-secondary-primary`)
      this.accrualStageBtnTarget.classList.remove(`badge-primary`)
      this.dueStageBtnTarget.classList.remove(`badge-secondary-success`)
      this.dueStageBtnTarget.classList.add(`badge-success`)
    }


    // this.doDataTable()
    this.refreshList()
  }

  enterShowRemunerations(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var remunerationIds = []

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          remunerationIds[remunerationIds.length] = tr[i].dataset.id
        }
      }

      if (remunerationIds.length) {
        this.editRemuneration(remunerationIds[0])
      }
    }
  }

  checkToCopyRemuneration() {
    this.kind = `copy_remuneration`
    this.doDataTable()

    this.refreshListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  copyRemunerationEv(ev) {
    var remunerationId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var current_remuneration = {}
    this.application.remunerations.forEach(remuneration => {
      if (remuneration.id == remunerationId) {
        current_remuneration = remuneration
      }
    })

    this.showDate(current_remuneration)
  }

  showDate(current_remuneration) {
    var modalController = `financials--books--dashboards--dates--modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Escolha a Competência</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalController).controllerForm = controller
      controller.getControllerByIdentifier(modalController).current_obj = current_remuneration
      controller.getControllerByIdentifier(modalController).current_contract = this.current_contract
      controller.getControllerByIdentifier(modalController).open()
    })
  }

  editRemunerationEv(ev) {
    var remunerationId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.editRemuneration(remunerationId)
  }

  editRemuneration(remunerationId) {

    var current_remuneration = {}
    this.application.remunerations.forEach(remuneration => {
      if (remuneration.id == remunerationId) {
        current_remuneration = remuneration
      }
    })

    if (current_remuneration.has_obj) {
      alert(`Antes de editar a Remuneração, favor apagar o pagamento em Contas à Pagar.`)
    } else {
      this.optionBtnTarget.classList.add(`d-none`)
      this.refreshListBtnTarget.classList.remove(`d-none`)
      
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").current_contract = this.current_contract
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").current_remuneration = current_remuneration
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").actionMode = `edit`
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").doFormHtml()      
    }

  }

  destroyRemunerationEv(ev) {
    var remunerationId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.destroyRemuneration(remunerationId)
  }

  destroyRemuneration(remunerationId) {
    this.optionBtnTarget.classList.add(`d-none`)
    this.refreshListBtnTarget.classList.remove(`d-none`)

    var current_remuneration = {}
    this.application.remunerations.forEach(remuneration => {
      if (remuneration.id == remunerationId) {
        current_remuneration = remuneration
      }
    })

    this.send_data = { current_user: {}, remuneration: {}, tracker: { manual: true } }

    this.send_data.remuneration.id = current_remuneration.id

    this.send_data.tracker.action = `destroyed`
    this.send_data.tracker.observations = `Apagou o item ${current_remuneration.allowance} da Remuneração ${this.current_contract.account_name}`
    this.send_data.tracker.record_id = this.current_contract.account_id
    this.send_data.tracker.record_type = `account_entities`
    this.send_data.tracker.obj_id = current_remuneration.id
    this.send_data.tracker.obj_type = `financial_payroll_remunerations`

    var r = confirm(`Tem certeza que deseja Apagar o item de Remuneração?`)
    if (r) {
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").send_data = this.send_data
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").actionMode = `destroy`
      this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").requestSave()
    } else {
      this.refreshList()
    }
  }

  addRemuneration() {
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").current_contract = this.current_contract
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").current_remuneration = {}
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").actionMode = `new`
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").doFormHtml()
  }

  checkToSumRemunerations() {
    this.kind = `sum_remunerations`
    this.doDataTable()
    this.doSumRemunerationsFooter()

    this.masterMultipleCheckDivTarget.classList.remove(`d-none`)
    this.refreshListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkToAddPayables() {
    this.kind = `add_payables`
    this.doDataTable()
    this.doAddPayablesFooter()

    this.refreshListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkToAddTransactions() {
    this.kind = `add_transactions`
    this.doDataTable()
    this.doAddTransactionsFooter()

    this.refreshListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkToDestroyRemuneration() {
    this.kind = `destroy_remuneration`
    this.doDataTable()

    this.refreshListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  uniqueRemuneration(ev) {

    this.multipleCheckTargets.forEach(element => {
      element.checked = false
    })

    ev.currentTarget.checked = true
  }

  doSumRemunerationsFooter() {
    var sumBtnHtml = `<div class="row py-1">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-8 d-flex justify-content-end">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <button disabled type="button" class="btn btn-flat-primary btn-sm py-2 btn-table f-065 pointer text-right w-100">Soma Remunerações</button>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = sumBtnHtml
  }

  doAddPayablesFooter() {
    var payBtnHtml = `<div class="row py-1">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-8 d-flex justify-content-end">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <button type="button" class="btn btn-flat-primary btn-sm py-2 btn-table f-065 pointer text-right w-100" data-mode="pay" data-action="click->${this.controllerName}#setPayable" data-${this.controllerName}-target="addPayableBtn">Gerar Contas à Pagar</button>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = payBtnHtml
  }

  setPayable(ev) {
    this.action = ev.currentTarget.dataset.mode

    if (this.application.current_date.open) {

      var remunerationsArray = []
      var remunerationsIds = []
      var currentRemuneration = {}
      this.multipleCheckTargets.forEach(check => {
        if (check.checked) {
          // remunerationsIds[remunerationsIds.length] = Number(check.dataset.id)
          this.application.remunerations.forEach(element => {
            if (element.id == Number(check.dataset.id)) {
              // remunerationsArray[remunerationsArray.length] = element
              currentRemuneration = element
            }
          })
        }
      })

      var modal = `financials--books--payrolls--remunerations--payable-modal`
      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`
      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = this.permission
        controller.getControllerByIdentifier(modal).current_remuneration = currentRemuneration
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).current_date = controller.application.current_date
        controller.getControllerByIdentifier(modal).open()
      })
      
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  doAddTransactionsFooter() {
    var provisionBtnHtml = `<div class="row py-1">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-8 d-flex justify-content-end">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <button type="button" class="btn btn-flat-primary btn-sm py-2 btn-table f-065 pointer text-right w-100" data-mode="provision" data-action="click->${this.controllerName}#setTransaction" data-${this.controllerName}-target="addTransactionBtn">Gerar Transação</button>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = provisionBtnHtml
  }

  setTransaction(ev) {
    this.action = ev.currentTarget.dataset.mode

    if (this.application.current_date.open) {

      var remunerationsArray = []
      var remunerationsIds = []
      var currentRemuneration = {}
      this.multipleCheckTargets.forEach(check => {
        if (check.checked) {
          // remunerationsIds[remunerationsIds.length] = Number(check.dataset.id)
          this.application.remunerations.forEach(element => {
            if (element.id == Number(check.dataset.id)) {
              // remunerationsArray[remunerationsArray.length] = element
              currentRemuneration = element
            }
          })
        }
      })

      var modal = `financials--books--payrolls--remunerations--transaction-modal`
      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`
      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = this.permission
        controller.getControllerByIdentifier(modal).current_remuneration = currentRemuneration
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).current_date = controller.application.current_date
        controller.getControllerByIdentifier(modal).open()
      })
      
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  checkAll(ev) {
    var checkElement = ev.currentTarget

    this.multipleCheckTargets.forEach(element => {
      if (this.searchInputTarget.value == ``) {
        element.checked = checkElement.checked
      } else {
        if (element.closest(`.itemRow`).classList.contains(`filter-row`)) {
          element.checked = checkElement.checked
        }
      }
    })

    this.bindSum()
  }

  bindSum() {
    var bindSum = 0
    var remunerationsArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        remunerationsArray[remunerationsArray.length] = Number(check.dataset.id)
      }
    })

    this.application.remunerations.forEach(remuneration => {
      if (remunerationsArray.includes(remuneration.id)) {
        if (remuneration.kind_input == `inflow` || remuneration.kind_input == `prepaidflow` || remuneration.kind_input == `refundflow`) {
          bindSum += Number(remuneration.amount)
        } else if (remuneration.kind_input == `outflow` || remuneration.kind_input == `laborflow`) {
          bindSum -= Number(remuneration.amount)
        }
      }
    })

    // this.bindSumTarget.innerText = `Valor Total do Contas à Receber: ${this.controllerNumber.currencyOptionMask(bindSum)}`
    this.bindSumTarget.innerHTML = `<small class="mb-0 mc-tooltip grab">
                                      <span data-controller="app--helpers--copy" data-copy="${parseInt(bindSum * 100)}" data-action="click->app--helpers--copy#copy">Valor Total do Contas à Receber: ${this.controllerNumber.currencyOptionMask(bindSum)}</span>
                                      <span class="mc-tooltiptext">Clique para Copiar</span>
                                    </small>`
  }

  refreshList() {
    this.masterMultipleCheckDivTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.refreshListBtnTarget.classList.add(`d-none`)
    this.current_remunerations = this.application.remunerations
    this.doDataTable()

    // this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").doRemunerationsDashboard()
  }

  refreshRemunerations(ev) {
    var dateKind = ev.currentTarget.dataset.dateKind

    if (dateKind == `accrual`) {
      var data = { remuneration: { active: true, date_id: this.application.current_date.id, contract_id: this.current_contract.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/financials/books/payrolls/remunerations/list_by_date_and_contract"
    } else if (dateKind == `due`) {
      var data = { remuneration: { active: true, date_id: this.application.current_date.id, contract_id: this.current_contract.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/financials/books/payrolls/remunerations/list_by_due_date_and_contract"
    }
    
    this.requestRefreshRemunerations(dateKind, data, url)
  }

  requestRefreshRemunerations(dateKind, data, url) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentRemunerationIds = controller.mapElements(controller.application.remunerations, `id`)
        var refreshRemunerationIds = controller.mapElements(response.data.cln, `id`)

        if (response.process) {
          controller.application.remunerations.forEach(remuneration => {
            var dateId = ``
            if (dateKind == `accrual`) {
              dateId = remuneration.date_id
            } else if (dateKind == `due`) {
              dateId = remuneration.due_date_id
            }

            if (remuneration.contract_id == controller.current_contract.id && currentRemunerationIds.includes(remuneration.id) && !refreshRemunerationIds.includes(remuneration.id) && (dateId == this.application.current_date.id)) {
              controller.application.remunerations.forEach((element, i) => {
                if (element.id == remuneration.id) {
                  controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1)
                }
              })
            }
          })

          response.data.cln.forEach(remuneration => {
            if (currentRemunerationIds.includes(remuneration.id) && refreshRemunerationIds.includes(remuneration.id)) {
              controller.application.remunerations.forEach((element, i) => {
                if (element.id == remuneration.id) {
                  controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1, remuneration)
                }
              })
            } else if (!currentRemunerationIds.includes(remuneration.id) && refreshRemunerationIds.includes(remuneration.id)) {
              controller.application.remunerations[controller.application.remunerations.length] = remuneration
            }
          })
        } else if (response.data.cln.length == 0) {
          controller.application.remunerations.forEach((element, i) => {
            var dateId = ``
            if (dateKind == `accrual`) {
              dateId = element.date_id
            } else if (dateKind == `due`) {
              dateId = element.due_date_id
            }

            if (dateId == controller.application.current_date.id) {
              controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1)
            }
          })
        }

        controller.setRemunerationsDate()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doNoContractHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewContractCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Contrato Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewContractCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").viewTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

}
