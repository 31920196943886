import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
    "medDropdown", "medDropdownBtn", "medInput", "medList", "chartInput", "chartFilter", "statusCheck",
    "cardDropdown", "cardDropdownBtn", "cardInput", "cardList", "cardDiv", "saveBtn", "subkindCheck"]


  connect() {
    this.controllerName = `financials--books--dashboards--costs--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--dashboards--costs--dashboard").doCostsDashboard()
  }

  saveCost() {
    this.send_data = { current_user: {}, cost: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.cost.id = this.current_cost.id
    }
    this.send_data.cost.name = this.nameInputTarget.value.trim()
    this.send_data.cost.kind = this.kindInputTarget.dataset.kind
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_costs`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/payables/costs/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/payables/costs/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/payables/costs/destroy"
      var method = "DELETE"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var cost = response.data.cln

          if (controller.actionMode == `edit`) {
            controller.application.costs.forEach((element, i) => {
              if (element.id == cost.id) {
                controller.application.costs.splice(controller.application.costs.indexOf(element), 1, cost)
              }
            })
          } else if (controller.actionMode == `destroy`) {
            controller.application.costs.forEach((element, i) => {
              if (element.id == cost.id) {
                controller.application.costs.splice(controller.application.costs.indexOf(element), 1)
              }
            })
          } else if (controller.actionMode == `new`) {
            controller.application.costs[controller.application.costs.length] = cost
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_settings_costs.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCost" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-8 offset-2">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-3">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="project" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Projeto</li>
                                      <li data-kind="cost_center" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Centro de Custo</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--costs--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Classificação`
        controller.nameInputTarget.value = controller.current_cost.name

        controller.kindInputTarget.innerText = controller.current_cost.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_cost.kind
        controller.kindDropdownTarget.value = controller.current_cost.kind

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Classificação`
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }


  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
