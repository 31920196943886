import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "upload", "list", "bodyTable", "uploadDocumentBtn", "footerTable", "progressUploadReceipt",
                    "progressUploadReceiptBar", "progressUploadReceiptCounter",
                    "buttonDisable", "spanEdit", "spanSave", "inputName", "spanName", "buttonRemove", "journeyComment"]

  connect() {
    this.controllerName = `operations--products--tax-filings--uploads--list`
    this.tableCols = 8
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    
    this.files = [] // LIST OF FILES SELECTED BY USERS, EVEN THE FILES NOT ALLOWED
    this.filesNames = [] // TO EDIT THE FILES NAMES WE NEED ANOTHER ARRAY BECAUSE FILES TYPE DON HAVE SETTERS
    this.canUpload = [] // ARRAY THAT DETERMINES IF THE FILES CAN BE LOADED
    this.alreadyUploaded = [] // ARRAY WITH WITH UPLOADED FLAG
    
    this.filesPermittedTypes = ["pdf", "png", "jpeg", ".xlsx", ".docx", ".txt", "PDF", "PNG", "JPEG", ".XLSX", ".DOCX", ".TXT"] // DEFINE ALLOWED EXTENSIONS
    this.indexFetch = 0 // INDEX TO INCREASE IN THE FETCH RECURSIVE CALL
    
    this.sort = { mode: `asc`, field: `created_at_time` }
    this.doListHtml()
  }

  goToDocumentationSent() {
    if (this.current_uploads.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `Favor adicionar um novo arquivo para Upload`, 3000)
    } else {
      this.getControllerByIdentifier(`operations--products--tax-filings--uploads--add`).goToDocumentationSent()
    }
  }

  goToAwaitingDocumentation() {
    this.getControllerByIdentifier(`operations--products--tax-filings--uploads--add`).goToAwaitingDocumentation()
  }

  doListHtml() {

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshFiles" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Documentos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var uploadBtn = `<div class="mc-tooltip my-auto" data-${this.controllerName}-target="journeyComment"></div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Arquivos para upload</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                      ${uploadBtn}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;white-space:nowrap;table-layout:fixed;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-5"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                  Salvo Em
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="created_at_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="created_at_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-45 align-middle">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-5"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                  Tamanho
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="file_size" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="file_size" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                  Tipo
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="file_type" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="file_type" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" colspan="2" scope="col" class="pointer p-1 table-20 align-middle text-center">Progresso</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_uploads = []
    // this.current_uploads = this.application.files

    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_uploads[this.current_uploads.length] = element
      }
    });
    
    if (this.sort.mode == `asc`) {
      var uploads = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_uploads, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var uploads = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_uploads, this.sort.field)
    }

    if (uploads.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(uploads.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (uploads[x] !== undefined) {
            internPage[internPage.length] = uploads[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: uploads.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Arquivos para a Declaração do Ano-Exercício ${this.application.current_date.year + 1}. Clique ou arraste para a área lateral.</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.uploadTablePartial(element, data.length))
      });
    }
  }

  uploadTablePartial(element, length) {
    var index = element.index
    
    if (this.files.length == 1) {
      var tableRow = `<tr data-index="${index}" data-id="${element.id}" data-${this.controllerName}-target="tableRow-${index}" class="itemRow" style="height:50px;">`
    } else {
      var tableRow = `<tr data-index="${index}" data-id="${element.id}" data-${this.controllerName}-target="tableRow-${index}" class="itemRow">`
    }

    if (element.status == "denied") {
      var elementStatus = `<td style="font-size:80%;" scope="col" class="p-1 align-middle help">
                            <span class="mc-tooltip">
                              ${element.status_pretty}
                              <span class="mc-tooltiptext">${element.notes}</span>
                            </span>
                          </td>`
    } else {
      var elementStatus = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            ${element.status_pretty}
                          </td>`
    }

    if (element.id && element.uploaded && element.has_document) {
      var progressSpan = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            Arquivo já salvo
                          </td>
                          ${elementStatus}`
      var editBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark" data-${this.controllerName}-target="spanEdit-${index}" data-url="${element.document_url}" data-action="click->${this.controllerName}#goToDocument">launch</span>
                      <span class="material-icons md-sm md-dark d-none" data-${this.controllerName}-target="spanSave-${index}" data-action="click->${this.controllerName}#saveFileName">save</span>
                      <span class="mc-tooltiptext">Ver Documento</span>
                    </button>`
      // var launchBtn = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${element.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
      //                 <span class="material-icons md-sm md-dark">launch</span>
      //                 <span class="mc-tooltiptext">Ver Documento</span>
      //               </button>`
      var launchBtn = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
      var deleteBtn = ``
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-sm md-bolder md-primary">done_all</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Documento Salvo</span>
                      </span>`
      if (element.name) {
        var nameSpan = `<span class="text-bold justify pointer" data-${this.controllerName}-target="spanName-${index}" data-action="click->${this.controllerName}#editFileName">${element.name}</span>`
        // var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = element.document_name.substr(element.document_name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
    } else if (element.id && element.has_document == false) {
      var progressSpan = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            Arquivo não foi salvo
                          </td>
                          ${elementStatus}`
      // var launchBtn = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${element.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
      //                 <span class="material-icons md-sm md-dark">launch</span>
      //                 <span class="mc-tooltiptext">Ver Documento</span>
      //               </button>`
      var launchBtn = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
      var editBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark" data-${this.controllerName}-target="spanEdit-${index}" data-url="${element.document_url}" data-action="click->${this.controllerName}#goToDocument">launch</span>
                      <span class="material-icons md-sm md-dark d-none" data-${this.controllerName}-target="spanSave-${index}" data-action="click->${this.controllerName}#saveFileName">save</span>
                      <span class="mc-tooltiptext">Ver Documento</span>
                    </button>`
      var deleteBtn = `<button type="button" class="btn btn-sm btn-table p-0" data-${this.controllerName}-target="buttonDisable buttonRemove-${index}" data-action="click->${this.controllerName}#deleteFile">
                        <span class="material-icons md-sm md-dark">delete</span>
                      </button>
                      <span class="mc-tooltiptext">Apagar o Arquivo</span>`
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-sm md-bolder md-danger">clear</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Documento Não Salvo, favor Apagar</span>
                      </span>`
      if (element.name) {
        // var nameSpan = `<span class="text-bold justify pointer" data-${this.controllerName}-target="spanName-${index}" data-action="click->${this.controllerName}#editFileName">${element.name}</span>`
        var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = ``
    } else {
      if (element.can_upload) {
        var progressSpan = `<td style="font-size:80%;" colspan="2" scope="col" class="p-1 align-middle">
                              <div class="form-group form-valid-group my-0 text-center">
                                <h7 class="progress">
                                  <span class="progress_count"></span>
                                </h7>
                                <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUploadReceipt">
                                  <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadReceiptBar-${index}"></div>
                                </div>
                                <span data-${this.controllerName}-target="progressUploadReceiptCounter-${index}" class="d-block"></span>
                              </div>
                            </td>`

        var editBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-${this.controllerName}-target="buttonDisable buttonRemove-${index}">
                        <span class="material-icons md-sm md-dark" data-${this.controllerName}-target="spanEdit-${index}" data-action="click->${this.controllerName}#editFileName">edit</span>
                        <span class="material-icons md-sm md-dark d-none" data-${this.controllerName}-target="spanSave-${index}" data-action="click->${this.controllerName}#saveFileName">save</span>
                        <span class="mc-tooltiptext">Editar o Nome do Arquivo</span>
                      </button>`
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-bolder md-success">done</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Documento Ok</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify pointer" data-${this.controllerName}-target="spanName-${index}" data-action="click->${this.controllerName}#editFileName">${element.file_name}</span>`
      } else {
        var progressSpan = `<td style="font-size:80%;" colspan="2" scope="col" class="p-1 align-middle">
                              O arquivo não pode ser salvo
                            </td>`
        var editBtn = ``
        var deleteBtn = ``
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-bolder md-danger">clear</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">${element.message_erro}</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = element.file.name.substr(element.file.name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
      var deleteBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-${this.controllerName}-target="buttonDisable buttonRemove-${index}" data-action="click->${this.controllerName}#removeFile">
                        <span class="material-icons md-sm md-dark">delete</span>
                        <span class="mc-tooltiptext">Apagar o Arquivo</span>
                      </button>`
    }
    
    var size = ((element.file_size / 1000000).toFixed(2) + "MB") // CALCULATE SIZE

    if (this.application.permissions[this.application.files_permission].can_show) {
      var document = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
    } else {
      var document = `<span class="mc-tooltip">
                        <span class="material-icons md-200 md-danger default">link_off</span>
                        <span class="mc-tooltiptext">Não há Arquivo</span>
                      </span>`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle help">${spanError}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.created_at_pretty}</td>
                    <td style="font-size:80%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" scope="col" class="p-1 align-middle">
                      ${nameSpan}
                      <input autofocus data-field="order" data-action="keyup->${this.controllerName}#saveFileName" class="form-control textarea p-1 f-065 d-none" type="string" required data-${this.controllerName}-target="inputName-${index}">
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${launchBtn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${size}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${type}</td>
                    ${progressSpan}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle mc-tooltip">${deleteBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToDocument(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.application.files_permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  editFileName(ev) {
    // var index = ev.currentTarget.parentElement.parentElement.id
    var index = ev.currentTarget.closest(".itemRow").dataset.index
    var element = this.nameTarget(`spanName-${index}`).innerText
    var noExtentionName = element.slice(0, element.lastIndexOf('.'))
    var type = element.substr(element.lastIndexOf('.') + 1)

    if (this.nameTarget(`spanEdit-${index}`)) {
      this.nameTarget(`spanEdit-${index}`).classList.add("d-none")
    }

    if (this.nameTarget(`spanSave-${index}`)) {
      this.nameTarget(`spanSave-${index}`).classList.remove("d-none")
    }

    this.nameTarget(`spanName-${index}`).classList.add("d-none")
    this.nameTarget(`inputName-${index}`).classList.remove("d-none")
    this.nameTarget(`inputName-${index}`).value = noExtentionName
    this.nameTarget(`inputName-${index}`).focus()
  }

  saveFileName(ev) {
    var index = ev.currentTarget.closest(".itemRow").dataset.index

    var span = this.nameTarget(`spanName-${index}`)
    var input = this.nameTarget(`inputName-${index}`)
    var spanText = span.innerText
    var extention = spanText.substr(spanText.lastIndexOf('.') + 1)

    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      span.classList.remove("d-none")
      input.classList.add("d-none")
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      var value = input.value.trim()
      var field = input.dataset.field
      span.classList.remove("d-none")
      input.classList.add("d-none")
      if (value != span.innerText && value != "") {
        var current_upload = {}
        var newName = value.concat(".", extention)

        this.current_uploads.forEach(element => {
          if (element.index == index) {
            current_upload = element
          }
        })

        this.bindOutput(span, field, newName, index)
        this.requestUpdateName(current_upload, newName)
      } else {
        this.nameTarget(`spanSave-${index}`).classList.add("d-none")
        if (this.nameTarget(`spanEdit-${index}`)) {this.nameTarget(`spanEdit-${index}`).classList.remove("d-none")}
      }
    }
  }

  bindOutput(span, field, value, index) { // REPLACE NEW FILE NAME
    
    if (this.nameTarget(`spanEdit-${index}`)) {
      this.nameTarget(`spanEdit-${index}`).classList.remove("d-none")
    }
    if (this.nameTarget(`spanSave-${index}`)) {
      this.nameTarget(`spanSave-${index}`).classList.add("d-none")  
    }

    if (field == "order") {
      span.innerText = value
    } else {
      span.innerText = value
    }
  }

  removeFile(ev) { // REMOVE BUTTON DATA-ACTION
    if (ev.type == "click") {
      var index = ev.currentTarget.closest(".itemRow").dataset.index
    } else {
      var index = ev
    }
    if (index > -1) {
      this.application.files.forEach(element => {
        if (element.index == index) {
          this.application.files.splice(this.application.files.indexOf(element), 1)
        }
      })
    }

    this.doDataTable()
  }

  deleteFile(ev) {
    this.actionMode = `edit`
    var fileId = ev.currentTarget.closest(".itemRow").dataset.id
    var current_file = {}

    this.send_data = { current_user: {}, file: {}, tracker: { manual: true } }

    this.application.files.forEach(element => {
      if (element.id == fileId) {
        current_file = element
      }
    })

    this.send_data.file.id = current_file.id
    this.send_data.file.active = false

    var dateToday = new Date()
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `delete`
    this.send_data.tracker.observations = `Apagou o Arquivo ${current_file.file_name} no dia ${this.getControllerByIdentifier(`app--helpers--date`).transformPrettyDateTime(dateToday)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.files_permission

    var url = "/operations/products/tax_filings/files/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          if (file.active == false) {
            controller.application.files.forEach((element, i) => {
              if (element.index == file.index) {
                controller.application.files.splice(controller.application.files.indexOf(element), 1)
              }
            })
          }
          controller.doDataTable()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  refreshFiles() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 10)

    var data = { file: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.files_permission } }
    const url = "/operations/products/tax_filings/files/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentFileIds = controller.mapElements(controller.application.files, `id`)
        var refreshFileIds = controller.mapElements(response.data.cln, `id`)

        if (response.process) {
          controller.application.files.forEach(file => {
            if (currentFileIds.includes(file.id) && !refreshFileIds.includes(file.id)) {
              controller.application.files.forEach((element, i) => {
                if (element.id == file.id) {
                  controller.application.files.splice(controller.application.files.indexOf(element), 1)
                }
              })
            }
          })

          response.data.cln.forEach(file => {
            if (currentFileIds.includes(file.id) && refreshFileIds.includes(file.id)) {
              controller.application.files.forEach((element, i) => {
                if (element.id == file.id) {
                  controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
                }
              })
            } else if (!currentFileIds.includes(file.id) && refreshFileIds.includes(file.id)) {
              controller.application.files[controller.application.files.length] = file
            }
          })
        } else if (response.data.cln.length == 0) {
          controller.application.files.forEach((element, i) => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.files.splice(controller.application.files.indexOf(element), 1)
            }
          })
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  uploadDocuments() {
    this.indexFetch = 0
    this.files_to_upload = []
    this.current_uploads.forEach(element => {
      if (element.uploaded == false && element.can_upload) {
        this.files_to_upload[this.files_to_upload.length] = element
      }
    })

    if (this.files_to_upload.length == 1) {
      this.total_uploads_notification = `1 novo Arquivo`
    } else {
      this.total_uploads_notification = `${this.files_to_upload.length} novos Arquivos`
    }

    this.disableButtons()
    this.requestSave()
  }

  disableButtons() { // DONT ALLOW USER TO PRESS BUTTON WHILE UPLOADING TO SERVER
    this.buttonDisableTargets.forEach(element => {
      element.children[0].style.color = "#fbfcff"
      element.children[0].classList.remove("md-dark")
      element.disabled = true
    });
  }

  requestUpdateName(element, value) {
    var url = "/operations/products/tax_filings/files/update"
    var method = "PUT"

    this.send_data = { current_user: {}, file: {}, notification: { domain_id: this.application.tax_filing.id } }
    
    this.send_data.file.id = element.id
    this.send_data.file.name = value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.files_permission

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          controller.application.files.forEach((element, i) => {
            if (element.id == file.id) {
              // controller.current_uploads.splice(controller.current_uploads.indexOf(element), 1, file)
              controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
            }
          })
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestSave() {
    this.actionMode = `new`
    var objUpload = this.files_to_upload[this.indexFetch]

    if (this.current_uploads.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `Favor adicionar um novo arquivo para Upload`, 3000)
    } else if (this.current_uploads.length > 0 && this.files_to_upload.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("success", `Todos os arquivos já foram salvos. Favor adicionar um novo arquivo para Upload`, 3000)
    } else if (this.indexFetch <= this.files_to_upload.length - 1) {
      if (objUpload.can_upload) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
        
        this.progressCount(0, objUpload.index)
        this.send_data = { current_user: {}, file: {}, notification: {}, info: {} }

        this.send_data.file.tax_filing_id = this.application.tax_filing.id
        this.send_data.file.date_id = this.application.current_date.id
        this.send_data.file.index = objUpload.index
        this.send_data.file.name = objUpload.file_name
        this.send_data.file.file_name = objUpload.file_name
        this.send_data.file.file_size = objUpload.file_size
        this.send_data.file.file_type = objUpload.file_type
        this.send_data.file.file_last_modified = objUpload.file_last_modified
        this.send_data.file.status = `not_revised`

        if (this.indexFetch == this.files_to_upload.length - 1) {
          this.send_data.info.last_file = true
        } else {
          this.send_data.info.last_file = false
        }
          
        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.application.files_permission


        var url = "/operations/products/tax_filings/files/create"
        var method = "POST"
        const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
        
        var controller = this
        fetch(url, init)
          .then(response => response.json())
          .then(response => {
            if (response.save) {
              var file = response.data.cln
              controller.application.files.forEach((element, i) => {
                if (element.index == file.index) {
                  controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
                  controller.requestUpload(file.id, element.file)
                }
              })
            }
            
          })
          .catch(error => {
            controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
          })
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`success`, `Upload de todos os Arquivos finalizado`, 2000)
    }
  }


  requestUpload(file_id, file) {
    var formData = new FormData()
    var dateToday = new Date()
    formData.append('document', file)
    formData.append('file_id', file_id)

    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', this.application.files_permission)

    formData.append('tracker_manual', true)
    formData.append('tracker_action', `uploaded`)
    formData.append('tracker_record_id', this.application.tax_filing.id)
    formData.append('tracker_record_type', `operation_products`)
    formData.append('tracker_obj_id', this.application.current_date.id)
    formData.append('tracker_obj_type', `operation_dates`)
    formData.append('tracker_observations', `Upload do Arquivo ${this.send_data.file.file_name} no dia ${this.getControllerByIdentifier(`app--helpers--date`).transformPrettyDateTime(dateToday)}`)

    var url = "/operations/products/tax_filings/files/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          controller.application.files.forEach((element, i) => {
            if (element.index == file.index) {
              controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
            }
          })
          controller.tagUploadedFiles(file.index)
          if (controller.send_data.info.last_file == true) {
            controller.doDataTable()
          }

        }

        controller.indexFetch++
        controller.getControllerByIdentifier("operations--products--tax-filings--uploads--add").setFiles()
        controller.getControllerByIdentifier("operations--products--tax-filings--show--submenu").doBadgeCounts()
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.requestSave()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  tagUploadedFiles(index) { // SET UPLOADED FLAG TRUE AND PROGRESS BAR TO 100%
    this.nameTarget(`buttonRemove-${index}`).remove()
    this.progressCount(100, index) // PROGRESS BAR TO 100%
    this.stopRefreshing()
  }

  enableButtons() { // WHEN UPLOADS END ALLOW USER TO PRESS BUTTON 
    this.buttonDisableTargets.forEach(element => {
      element.children[0].classList.add("md-dark")
      element.children[0].style.color = "#fbfcff"
      element.disabled = false
    });
  }

  progressCount(value, index) { // FAKE COUNTER PROGRESS
    var controller = this
    this.nameTarget(`progressUploadReceiptBar-${index}`).style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.nameTarget(`progressUploadReceiptBar-${index}`).style.width = Math.floor(i + 1) + `%`
        controller.nameTarget(`progressUploadReceiptCounter-${index}`).innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.nameTarget(`progressUploadReceiptCounter-${index}`).innerText = value + `%`
    }
  }

  stopRefreshing() {
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
