import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "destroyBtn", "nameDropdownBtn", "titleInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusKind",
                    "linkCTAInput", "linkFooterInput", "kindInput", "kindList", "nameForm",
                    "titleInput", "ctaInput", "descriptionInput", "orderInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `commercial--marketing--sites--differentials--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--differentials--index").clearList()
  }

  saveDifferential() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data = { current_user: {}, differential: {} }
    this.saveBtnTarget.disabled = true
    if (this.hasDestroyBtnTarget) {
      this.destroyBtnTarget.disabled = true
    }
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.differential.id = this.current_differential.id
    }

    this.send_data.differential.site_id = this.application.site.id
    this.send_data.differential.title = this.titleInputTarget.value.trim()
    // this.send_data.differential.subtitle = this.titleInputTarget.value.trim()
    this.send_data.differential.cta = this.ctaInputTarget.value.trim()
    this.send_data.differential.order = this.orderInputTarget.value.trim()
    this.send_data.differential.description = this.descriptionInputTarget.value.trim()
    this.send_data.differential.link_cta = this.linkCTAInputTarget.value.trim()
    this.send_data.differential.status = this.statusKindTarget.checked
    // this.send_data.differential.image = this.imageInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyDifferential() {
    var r = confirm(`Tem certeza que deseja apagar o Diferencial?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, differential: {} }
      this.saveBtnTarget.disabled = true
      if (this.hasDestroyBtnTarget) {
        this.destroyBtnTarget.disabled = true
      }
      this.actionMode = `delete`
      this.stopRefreshing()

      this.send_data.differential.id = this.current_differential.id
      this.send_data.differential.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/sites/differentials/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/sites/differentials/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/commercial/marketing/sites/differentials/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var differential = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.differentials[controller.application.differentials.length] = differential
          } else if (this.actionMode == `edit`) {
            controller.application.differentials.forEach((element, i) => {
              if (element.id == differential.id) {
                controller.application.differentials.splice(controller.application.differentials.indexOf(element), 1, differential)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.differentials.forEach((element, i) => {
              if (element.id == differential.id) {
                controller.application.differentials.splice(controller.application.differentials.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveDifferential" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyDifferential" data-${this.controllerName}-target="destroyBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-5">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="titleForm">Título</label>
                            <input aria-describedby="titleFormHelp" class="form-control form-valid-control" id="titleForm" data-${this.controllerName}-target="titleInput" placeholder="Título" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ctaForm">CTA</label>
                            <input aria-describedby="ctaFormHelp" class="form-control form-valid-control" id="ctaForm" data-${this.controllerName}-target="ctaInput" placeholder="CTA" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="orderForm">Ordem</label>
                            <input aria-describedby="orderFormHelp" class="form-control form-valid-control" id="orderForm" data-${this.controllerName}-target="orderInput" placeholder="Ordem" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <textarea aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="linkCTAForm">Link CTA</label>
                            <input aria-describedby="linkCTAFormHelp" class="form-control form-valid-control" id="linkCTAForm" data-${this.controllerName}-target="linkCTAInput" placeholder="Link CTA" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="statusDoctor" data-${this.controllerName}-target="statusKind">
                          <label class="custom-control-label f-065 pointer" for="statusDoctor">Mostrar no Site</label>
                          <span class="mc-tooltiptext">Mostrar ao Vivo no Site?</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--sites--differentials--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Diferencial`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Diferencial`

        controller.titleInputTarget.value = controller.current_differential.title
        controller.ctaInputTarget.value = controller.current_differential.cta
        controller.descriptionInputTarget.value = controller.current_differential.description
        controller.orderInputTarget.value = controller.current_differential.order
        controller.linkCTAInputTarget.value = controller.current_differential.link_cta
        controller.statusKindTarget.checked = controller.current_differential.status

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.titleInputTarget.value == ``) {
        len += 1
      }

      if (controller.ctaInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.orderInputTarget.value == ``) {
        len += 1
      }

      if (controller.linkCTAInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
