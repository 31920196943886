import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["list", "input", "characterCount", "comment", "collapse", "saveBtn", "bodyInputTrix", "attachBtn", "fileName"]

  connect() {
    this.controllerName = `users--works--activities--shared--comments`
    this.ticketController = this.getControllerByIdentifier(`users--works--activities--shared--tickets`)
    this.requestPermission = true
    this.upload_file = {}
    this.uploadFeature = true
    this.uploadReady = false
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "rtf", "docx", "DEC", "REC", ".DEC", ".REC", "DBK", ".DBK",
      "vnd.openxmlformats-officedocument.wordprocessingml.document", "txt", "plain", "PDF", "PNG", "JPEG", ".XLSX", ".DOCX", ".TXT"]
  }

  characterCount(ev) {
    var count = this.nameTarget(`input-${this.ticket_id}`).value.length
    if (count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${count} caracteres`
    }

    this.nameTarget(`characterCount-${this.ticket_id}`).textContent = message

    if (count > 140) {
      this.nameTarget(`characterCount-${this.ticket_id}`).classList.add("text-danger")
    } else {
      this.nameTarget(`characterCount-${this.ticket_id}`).classList.remove("text-danger")
    }
  }

  clickLink(e) {
    var link = e.target.innerText
    if (e.target.innerText.includes("http")) {
      window.open(link, '_blank')
    } else if (e.target.innerText.includes("www")) {
      window.open('https://' + link, '_blank')
    }
  }

  saveComment(ev) {
    this.send_data = { current_user: {}, comment: {} }
    var count = this.nameTarget(`input-${this.ticket_id}`).value.length

    if (count > 140) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && count > 0) {
        this.send_data.comment.board_id = this.ticketController.comment_board.board_id
        this.send_data.comment.board_type = this.ticketController.comment_board.board_type
        this.send_data.comment.date_id = this.ticketController.comment_board.date_id
        this.send_data.comment.date_type = this.ticketController.comment_board.date_type
        this.send_data.comment.ticket_id = this.nameTarget(`collapse-${this.ticket_id}`).dataset.ticketId
        this.send_data.comment.account_id = this.application.current_user.account_id
        this.send_data.comment.account_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
        this.send_data.comment.body = this.nameTarget(`input-${this.ticket_id}`).value
        // this.send_data.comment.body = this.nameTarget(`bodyInputTrix-${this.ticket_id}`).innerHTML

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.ticketController.permission
        

        if (this.requestPermission) {
          this.requestSave()
        }
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        this.nameTarget(`input-${this.ticket_id}`).value = ""
      }
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/users/works/activities/comments/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var comment = response.data.cln
          
          if (controller.uploadReady) {
            controller.requestUpload(comment.id, controller.upload_file.file)
          } else {
            controller.application.tickets.forEach(element => {
              if (element.id == comment.ticket_id) {
                element.comments[element.comments.length] = comment
                element.total_comments += 1
                controller.getTargetByIdentifier(`totalComments-${controller.ticket_id}`).innerText = element.total_comments
                controller.getTargetByIdentifier(`totalComments-${controller.ticket_id}`).classList.remove("d-none")
              }
            })

            var html = controller.commentPartial(comment)
            controller.nameTarget(`list-${controller.ticket_id}`).insertAdjacentHTML("beforeend", html)
            controller.nameTarget(`input-${controller.ticket_id}`).value = ""
            controller.nameTarget(`characterCount-${controller.ticket_id}`).textContent = ""
            controller.rollComments()

            controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
          }
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doCommentHtml() {

    // `<input aria-describedby="notesFormHelp" type="hidden" class="" id="notesForm" placeholder="Texto" data-${this.controllerName}-target="bodyInput">
    // <trix-editor input="notesForm" data-trix="true" data-field="description" class="p-1 f-075 trix-editor-comments" placeholder="Faça aqui as anotações ... " data-${this.controllerName}-target="bodyInputTrix-${this.ticket_id} input-${this.ticket_id}" data-action="keyup->${this.controllerName}#characterCount"></trix-editor>`

    if (this.application.permissions[this.ticketController.permission].can_create) {
      var createField = `<div class="row my-1 d-flex align-items-center">
                          <div class="col-8 px-1 text-left">
                            <input type="text" class="form-control f-065" placeholder="Digite seu comentário ..." aria-label="Digite seu comentário ..." data-${this.controllerName}-target="input-${this.ticket_id}" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount paste@document->${this.controllerName}#pasteHandler">
                            <span class="f-065" data-${this.controllerName}-target="characterCount-${this.ticket_id}"></span>
                          </div>
                          <div class="col-2 px-1 mc-tooltip d-flex align-items-center">
                            <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                              <div class="row my-2">
                                <div class="col p-0 d-flex align-items-center justify-content-center">
                                  <span class="material-icons md-150 md-primary mx-3" data-${this.controllerName}-target="attachBtn-${this.ticket_id}">attach_file</span>
                                </div>
                              </div>
                            </label>
                            <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                            <span class="mc-tooltiptext" data-${this.controllerName}-target="fileName">Anexar</span>
                          </div>
                          <div class="col-2 px-1 mc-tooltip">
                            <span class="material-icons md-sm md-primary pointer" data-action="click->${this.controllerName}#saveComment" data-${this.controllerName}-target="saveBtn-${this.ticket_id}">send</span>
                            <span class="mc-tooltiptext">Enviar</span>
                          </div>
                        </div>`
      
        // `<span class="material-icons md-sm md-primary pointer" data-action="click->${this.controllerName}#uploadDocument" data-${this.controllerName}-target="attachBtn-${this.ticket_id}">attach_file</span>
        // <span class="mc-tooltiptext">Anexar</span>`


      // var createField = `<div class="row my-1 d-flex align-items-center">
      //                     <div class="col-10 px-1 text-left">
      //                       <input type="text" class="form-control f-065" placeholder="Digite seu comentário ..." aria-label="Digite seu comentário ..." data-${this.controllerName}-target="input-${this.ticket_id}" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount">
      //                       <span class="f-065" data-${this.controllerName}-target="characterCount-${this.ticket_id}"></span>
      //                     </div>
      //                     <div class="col-2 px-1 mc-tooltip">
      //                       <span class="material-icons md-sm md-primary pointer" data-action="click->${this.controllerName}#saveComment" data-${this.controllerName}-target="saveBtn-${this.ticket_id}">send</span>
      //                       <span class="mc-tooltiptext">Enviar</span>
      //                     </div>
      //                   </div>`
    } else {
      var createField = ``
    }

    var html = `<div class="card m-1">
                  <div class="card-body comment-card px-1 py-0" id="commentBody-${this.ticket_id}" data-${this.controllerName}-target="list-${this.ticket_id}"></div>
                  <div class="card-footer p-0 pb-1">
                    ${createField}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.nameTarget(`collapse-${controller.ticket_id}`).innerHTML = html)
    }).then(() => {
      controller.doCommentTable()
      controller.characterCount()
      // controller.nameTarget(`bodyInputTrix-${controller.ticket_id}`).previousElementSibling.remove()
    })
  }

  doCommentTable() {

    this.ticket = {}

    this.application.tickets.forEach(element => {
      if (element.id == this.ticket_id) {
        this.ticket = element
      }
    })

    var commentsHtml = ``
    this.ticket.comments.forEach(comment => {
      commentsHtml += this.commentPartial(comment)
    })

    this.nameTarget(`list-${this.ticket_id}`).innerHTML = commentsHtml
    this.rollComments()


    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.nameTarget(`list-${controller.ticket_id}`).innerHTML = commentsHtml)
    // }).then(() => {
    //   controller.rollComments()
    // }) 
  }

  commentPartial(comment) {
    if (comment.has_document) {
      var commentHtml = `<span class="text-scroll text-left pointer f-065" style="overflow:auto;" data-kind="document" data-obj-id="${comment.id}" data-obj-type="activity_comments" data-action="click->${this.controllerName}#goToFile">${comment.body}</span>`
    } else {
      if (comment.body.includes("http") || comment.body.includes("www")) {
        var commentHtml = `<span class="text-scroll text-left pointer f-065" style="overflow:auto;" data-action="click->${this.controllerName}#clickLink">${comment.body}</span>`
      } else {
        var commentHtml = `<span class="text-scroll text-left f-065" style="overflow:auto;">${comment.body}</span>`
      }
    }
    
    var html = `<div class="row my-1 py-1">
                  <div class="col-9 px-1 d-flex align-items-center">${commentHtml}</div>
                  <div class="col-3 px-0 text-center my-auto mc-tooltip">
                    <span class="d-block f-065">${comment.created_at_pretty}</span>
                    <span class="material-icons pointer f-085">face</span>
                    <span class="mc-tooltiptext mc-tooltiptext-bottom">${comment.account_name}</span>
                  </div>           
                </div>`

    return html
  }

  rollComments() {
    $(this.nameTarget(`list-${this.ticket_id}`)).stop().animate({ scrollTop: $(this.nameTarget(`list-${this.ticket_id}`))[0].scrollHeight }, 1000);
  }

  request(permission) {
    this.requestPermission = permission
    if (permission) {
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.add("md-primary")
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.remove("md-dark")
      this.nameTarget(`attachBtn-${this.ticket_id}`).classList.add("md-primary")
      this.nameTarget(`attachBtn-${this.ticket_id}`).classList.remove("md-dark")
    } else {
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.remove("md-primary")
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.add("md-dark")
      this.nameTarget(`attachBtn-${this.ticket_id}`).classList.remove("md-primary")
      this.nameTarget(`attachBtn-${this.ticket_id}`).classList.add("md-dark")
    }
  }

  pasteHandler(ev) {

    // var items = (ev.clipboardData || ev.originalEvent.clipboardData).items
    // console.log(JSON.stringify(items)); // will give you the mime types

    // var blob = null;
    // for (var i = 0; i < items.length; i++) {
    //   if (items[i].type.indexOf("image") === 0) {
    //     blob = items[i].getAsFile();
    //   }
    // }
    // // load image if there is a pasted image
    // if (blob !== null) {
    //   this.nameTarget(`input-${this.ticket_id}`).value = `PrintScreen.png`
    //   var reader = new FileReader();
    //   reader.onload = function (event) {
    //     // console.log(event.target.result); // data url!
    //     event.target.result
    //   }
    //   reader.readAsDataURL(blob);
    //   this.file = reader
    //   this.file.type = `image/png`
    //   this.file.size = 500000
    //   this.setUploadFile()
    // }


    // document.onpaste = function (event) {
    //    // use event.originalEvent.clipboard for newer chrome versions
    //    var items = (event.clipboardData || event.originalEvent.clipboardData).items;
    //    console.log(JSON.stringify(items)); // will give you the mime types
    //    // find pasted image among pasted items
    //    var blob = null;
    //    for (var i = 0; i < items.length; i++) {
    //      if (items[i].type.indexOf("image") === 0) {
    //        blob = items[i].getAsFile();
    //      }
    //    }
    //    // load image if there is a pasted image
    //    if (blob !== null) {
    //      var reader = new FileReader();
    //      reader.onload = function (event) {
    //        console.log(event.target.result); // data url!
    //      };
    //      reader.readAsDataURL(blob);
    //    }
    // }
  }

  dragOverHandler(ev) {
    ev.preventDefault()
  }

  dropHandler(ev) {
    ev.preventDefault()

    if (this.uploadFeature) {
      if (ev.type == "change") {
        if (ev.target.files && ev.target.files[0]) {
          this.file = ev.target.files[0]
          this.setUploadFile()
        }
      } else if (ev.type == "drop") {
        if (ev.dataTransfer.items) {
          if (ev.dataTransfer.items[0] && ev.dataTransfer.items[0].kind === 'file') {
            this.file = ev.dataTransfer.items[0].getAsFile()
            this.setUploadFile()
          }
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  setUploadFile() {
    var element = this.file
    console.log(element)
    var obj = {}
    var date = new Date()
    obj.file_name = element.name
    obj.file_size = element.size
    obj.file_type = element.type
    obj.file_last_modified = element.lastModified
    obj.uploaded = false
    obj.message_erro = ""
    obj.created_at_time = parseInt(date.getTime() / 1000)
    console.log(element.size)
    console.log(element.type.split("/")[1])
    if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
      obj.can_upload = true

      obj.file = element
      this.upload_file = obj
      this.uploadReady = true
      this.fileNameTarget.innerText = this.upload_file.file_name
      this.nameTarget(`input-${this.ticket_id}`).value = this.upload_file.file_name
    } else {
      if ((element.size / 1000000).toFixed(2) > 5) {
        obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
      }

      if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
        obj.message_erro += "Formato do arquivo não é permitido."
      }
      obj.can_upload = false
      this.uploadReady = false
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
    }
  }

  requestUpload(comment_id, file) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', `document`)
    formData.append('comment_id', comment_id)
    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', this.ticketController.permission)

    var url = "/users/works/activities/comments/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var comment = response.data.cln

          controller.application.tickets.forEach(element => {
            if (element.id == comment.ticket_id) {
              element.comments[element.comments.length] = comment
              element.total_comments += 1
              controller.getTargetByIdentifier(`totalComments-${controller.ticket_id}`).innerText = element.total_comments
              controller.getTargetByIdentifier(`totalComments-${controller.ticket_id}`).classList.remove("d-none")
            }
          })

          var html = controller.commentPartial(comment)
          controller.nameTarget(`list-${controller.ticket_id}`).insertAdjacentHTML("beforeend", html)
          controller.nameTarget(`input-${controller.ticket_id}`).value = ""
          controller.nameTarget(`characterCount-${controller.ticket_id}`).textContent = ""
          controller.rollComments()

          controller.upload_file = {}
          controller.uploadFeature = true
          controller.uploadReady = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile() {
    var r = confirm(`Tem certeza que deseja apagar a Proposta?`)
    if (r) {
      this.send_data = { current_user: {}, renewal: {} }

      this.send_data.renewal.id = this.current_renewal.id
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.ticketController.permission

      var url = "/financials/books/clients/renewals/destroy_upload"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            controller.current_renewal = response.data.cln

            controller.stopRefreshing()
            controller.cancelProposal()
          }

          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressCountTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.ticketController.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  getCurrentDate(date) {
    const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]

    if (date.getDate() < 10) {
      return `0${date.getDate()}/${month[date.getMonth()]}`
    } else {
      return `${date.getDate()}/${month[date.getMonth()]}`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

}