import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "preloaderVideoCard", "title", "info", "liveMeeting", "navbarSpacing", "preloadCheckInCard", "totalMeeting"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--events--live--dashboard`
    this.application.event_token = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.application.admissions = []
    this.application.messages = []
    if (this.application.current_user_id != null || this.application.current_user_id != undefined) {
      this.application.public_view = false
    } else {
      this.application.public_view = true
    }
    this.setNavbarSpacing()
    // window.onbeforeunload = function () {
    //   return "Do you really want to close?";
    // }
  }

  setPageTitle() {
    if (this.getControllerByIdentifier("app--navigation--desktop--breadcrumb").hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier("app--navigation--desktop--breadcrumb").breadcrumbNameTarget.innerText = this.application.event.title
    } else {
      var controller = this
      setTimeout(() => {
        controller.getControllerByIdentifier("app--navigation--desktop--breadcrumb").breadcrumbNameTarget.innerText = controller.application.event.title
      }, 4000);
    }
    this.titleTarget.innerText = this.application.event.title
    this.infoTarget.innerHTML = `<span class="f-085">Total Inscritos: <span class="f-085 f-bold">${this.application.event.admissions_count}</span> |</span>
                                 <span class="f-085">Total Participantes: <span class="f-085 f-bold" data-${this.controllerName}-target="totalMeeting">${this.application.event.admissions_checked_count}</span> |</span>
                                 <span class="f-085">Total Ao Vivo: <span class="f-085 f-bold" data-${this.controllerName}-target="liveMeeting">${this.application.event.uniq_live}</span> |</span>
                                 <span class="f-085">Compartilhamento: <span class="f-085 f-bold">${this.application.event.sharing_pretty}</span></span>`
  }

  doPublicPageGrid() {
    var html = ``

    if (this.application.event.finished) {
      var html = `<div class="row my-5">
                      <div class="col-3 text-center">
                        <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                      </div>
                      <div class="col-9 text-left">
                        <h5 class="display-4">${this.application.event.title}</h5>
                        <h5>${this.application.event.copy}</h5>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2 d-flex align-items-center justify-content-center" data-controller="commercial--marketing--events--live--streaming" data-commercial--marketing--events--live--streaming-target="main"></div>
                    </div>`
    } else {
      if (this.application.event.midia_kind == `virtual_meet`) {
        window.open(`https://${this.application.event.midia_link}`, `_self`)
      } else if (this.application.event.midia_kind == `streaming`) {
        var html = `<div class="row my-5">
                      <div class="col-3 text-center">
                        <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                      </div>
                      <div class="col-9 text-left">
                        <h5 class="display-4">${this.application.event.title}</h5>
                        <h5>${this.application.event.copy}</h5>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-8 px-2 d-flex align-items-center justify-content-center" data-controller="commercial--marketing--events--live--streaming" data-commercial--marketing--events--live--streaming-target="main"></div>
                      <div class="col-4 px-2" data-controller="commercial--marketing--events--live--chat" data-commercial--marketing--events--live--chat-target="main"></div>
                    </div>`
      }
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--events--live--broadcast").getRoom()
    })
  }

  doPageGrid() {
    if (this.application.public_view) {
      var html = ``
    } else {
      var html = `<div class="row my-3">
                    <div class="col-8 px-2 d-flex align-items-center justify-content-center" data-controller="commercial--marketing--events--live--streaming" data-commercial--marketing--events--live--streaming-target="main"></div>
                    <div class="col-4 px-2" data-controller="commercial--marketing--events--live--panel" data-commercial--marketing--events--live--panel-target="main"></div>
                  </div>`

    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--events--live--broadcast").getRoom()
    })
  }

  doCheckInPreload() {
    var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                </div>
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper mc-stepper active">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                      <div class="stepper mc-stepper">
                        <div class="text-center w-100">
                          <span class="material-icons">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                      
                      <div class="stepper mc-stepper">
                        <div class="text-center w-100">
                          <span class="material-icons">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-5">
                  <div class="col-4 offset-4 d-flex align-items-center justify-content-center">
                    <div class="card card-live w-100" data-step="0" data-${this.controllerName}-target="preloadCheckInCard">
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <div class="row w-100">
                          <div class="col-12 my-4 text-center px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 my-4 px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                            ${this.loader}
                            ${this.loader}
                            <div class="card pointer w-100 bg-primary d-none">
                              ${this.loader}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.preloadCheckInCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doEventPreload() {
    var videoPreloaderHtml = this.videoPreloader()
    if (this.application.public_view) {
      var header = `<div class="row mb-4">
                      <div class="col-12 text-center">
                        <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                      </div>
                    </div>`
    } else {
      var header = ``
    }

    var html = `${header}
                <div class="row my-3">
                  <div class="col-8 px-2">
                    ${videoPreloaderHtml}
                  </div>
                  <div class="col-4 px-2">
                    <div class="row d-flex justify-content-between">
                      <div class="col-12 mb-3">
                        ${this.cardLoader}
                      </div>
                      <div class="col-12 mb-3">
                        ${this.cardLoader}
                      </div>
                      <div class="col-12 mb-3">
                        ${this.cardLoader}
                      </div>
                      <div class="col-12 mb-3">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.preloaderVideoCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  getMeeting() {
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    var data = { meeting: { event_id: this.application.event.id, visitor_token: this.application.current_visitor_token } }
    const url = "/commercial/marketing/events/meetings/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meeting = response.data.cln.meeting
          controller.application.admission = response.data.cln.admission
          controller.application.attendee = response.data.cln.attendee
          controller.doPublicPageGrid()
        } else {
          controller.getControllerByIdentifier("commercial--marketing--events--live--meeting").doCheckinHtml()
        }
      })
      .catch(error => {
        // controller.getMeeting()
        window.location.reload()
        // controller.getControllerByIdentifier("app--helpers--console").console(error)
        // controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getAdmissions() {
    var data = { admission: { event_id: this.application.event.id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/marketing/events/admissions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.admissions = response.data.cln
          controller.getControllerByIdentifier("commercial--marketing--events--live--admissions").doDataTable()
          
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getMessages(chatId) {
    var data = { room: { id: chatId } }
    const url = "/commercial/marketing/events/messages/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.messages = response.data.cln
          controller.getControllerByIdentifier("commercial--marketing--events--live--chat").doMessageTable()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getEvent() {
    var data = { event: { slug: this.application.event_token } }
    const url = "/commercial/marketing/events/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.event = response.data.cln
          controller.setLink()
          
          if (controller.application.public_view) {
            controller.getMeeting()
          } else {
            controller.doPageGrid()
            controller.setPageTitle()
            controller.getAdmissions()
          }

        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        // controller.getEvent()
        // controller.getControllerByIdentifier("app--helpers--console").console(error)
        // controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setLink() {
    this.application.event.links.forEach(element => {
      if (element.status == true) {
        this.application.link = element
      }
    })
  }

 
  videoPreloader() {
    var html = `<div class="card" data-${this.controllerName}-target="preloaderVideoCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body py-0">
                    <div class="row h-70">
                      <div class="col-12">
                        <div class="preloader-wrapper">
                          <div class="preloader-circle"></div>
                          <div class="preloader-circle"></div>
                          <div class="preloader-circle"></div>
                          <div class="preloader-shadow"></div>
                          <div class="preloader-shadow"></div>
                          <div class="preloader-shadow"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-center">
                        <span class="f-1p25 f-bold">Carregando Streaming ... </span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  setNavbarSpacing() {
    if (this.application.public_view) {
      // var navbarHeight = Number($('.navbar').height());
      // var navbarPadding = Number($('.navbar').css('padding-top').replace("px", "")) + Number($('.navbar').css('padding-bottom').replace("px", ""));
      // var navbarSpacing = navbarHeight + navbarPadding
      // this.navbarSpacingTarget.style.marginTop = navbarSpacing + "px"
      this.doCheckInPreload()
    } else {
      this.doEventPreload()
    }
    this.getEvent()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
