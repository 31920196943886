import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--show--broadcast`
    this.application.tax_filing_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.taxFilingUpdateChannel = createChannel({ channel: 'Operations::Products::TaxFilingChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "calculation") {
          controller.application.tax_filing_calculations.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.tax_filing_calculations.splice(controller.application.tax_filing_calculations.indexOf(element), 1, data.cln)
              controller.application.current_tax_filing_calculation = data.cln
            }
          })

          if (controller.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("operations--products--tax-filings--uploads--add").doUploadHtml()
            controller.getControllerByIdentifier("operations--products--tax-filings--uploads--list").doListHtml()
          }

          if (controller.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("operations--products--tax-filings--process--board").doBoardHtml()
            controller.getControllerByIdentifier("operations--products--tax-filings--process--summary").doSummaryHtml()
            controller.getControllerByIdentifier("operations--products--tax-filings--process--index").doDataTable()
          }
        } else if (data.kind == "journey") {
          // controller.application.journeys = data.cln

          var currentJourneyIds = controller.mapElements(controller.application.journeys, `id`)
          var refreshJourneyIds = controller.mapElements(data.cln, `id`)

          if (data.cln.length == 0) {
            controller.application.journeys.forEach((element, i) => {
              if (element.date_id == controller.application.current_date.id) {
                controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1)
              }
            })
          } else {
            controller.application.journeys.forEach(journey => {
              if (currentJourneyIds.includes(journey.id) && !refreshJourneyIds.includes(journey.id)) {
                controller.application.journeys.forEach((element, i) => {
                  if (element.id == journey.id) {
                    controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1)
                  }
                })
              }
            })

            data.cln.forEach(journey => {
              if (currentJourneyIds.includes(journey.id) && refreshJourneyIds.includes(journey.id)) {
                controller.application.journeys.forEach((element, i) => {
                  if (element.id == journey.id) {
                    controller.application.journeys.splice(controller.application.journeys.indexOf(element), 1, journey)
                  }
                })
              } else if (!currentJourneyIds.includes(journey.id) && refreshJourneyIds.includes(journey.id)) {
                controller.application.journeys[controller.application.journeys.length] = journey
              }
            })
          }

          if (controller.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("operations--products--tax-filings--journey--index").doDataTable()
            controller.getControllerByIdentifier("operations--products--tax-filings--journey--save").doJourneyStatus()
          }
        }

        if (controller.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").reconnect()
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.taxFilingChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      },
      appear(data) {
        this.perform('appear')
      },
    });
  }

  updateListen() {
    if (this.taxFilingUpdateChannel) {
      this.taxFilingUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.taxFilingChatChannel) {
      this.taxFilingChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
