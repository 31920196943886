import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "productKindInput", "productPlanInput", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "purchaseInput",
                    "policyDropdown", "policyDropdownBtn", "policyInput", "policyList",
                    "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "teamModalCol", "teamInput", "teamModal", "roleList",
                    "setupCheckbox", "recurringCheckbox", "grossAmountInput", "percentageInput", "netAmountInput",
                    "productNameInput", "productServiceInput", "signedAtInput", "chargedAtInput", "saveBtn", "paidAtInput"]


  connect() {
    this.controllerName = `financials--products--commissions--entities--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    // this.getControllerByIdentifier("financials--products--commissions--entities--index").clearList()
    this.getControllerByIdentifier("financials--products--commissions--entities--index").doIndexListHtml()
  }

  saveCommission() {
    this.send_data = { current_user: {}, commission: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.commission.id = this.current_commission.id
    } else if (this.actionMode == `copy`) {
      this.send_data.commission.purchase_id = this.current_commission.purchase_id
    }

    this.send_data.commission.policy_id = this.policyInputTarget.dataset.policyId
    this.send_data.commission.account_id = this.teamInputTarget.dataset.teamId
    this.send_data.commission.status = this.statusInputTarget.dataset.status
    this.send_data.commission.gross_amount = this.controllerNumber.fromCurrencyToNumber(this.grossAmountInputTarget.value)
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyCommission() {
    this.send_data = { current_user: {}, commission: {} }
    this.actionMode = `delete`

    this.send_data.commission.id = this.current_commission.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar a Comissão?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.actionMode == `new`) {
      var url = "/financials/products/commissions/entities/create"
      var method = "POST"
    } else if (this.actionMode == `copy`) {
      var url = "/financials/products/commissions/entities/copy_commission"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/products/commissions/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/financials/products/commissions/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var commission = response.data.cln

          if (controller.actionMode == `new` || controller.actionMode == `copy`) {
            controller.application.commissions[controller.application.commissions.length] = commission
          } else if (controller.actionMode == `edit`) {
            controller.application.commissions.forEach((element, i) => {
              if (element.id == commission.id) {
                controller.application.commissions.splice(controller.application.commissions.indexOf(element), 1, commission)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.commissions.forEach((element, i) => {
              if (element.id == commission.id) {
                controller.application.commissions.splice(controller.application.commissions.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()


    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyCommission" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCommission" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row my-2 d-flex align-items-center">
                  <div class="col-10 offset-1">
                      <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>

                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
      
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm" data-${this.controllerName}-target="purchaseInput">
                                <label for="purchaseForm">Contratação</label>
                                <input disabled aria-describedby="purchaseFormHelp" class="form-control form-valid-control"  placeholder="Contratação" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="productNameForm">Produto</label>
                                <input disabled aria-describedby="productNameFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productNameInput" placeholder="Produto" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="productServiceForm">Serviço</label>
                                <input disabled aria-describedby="productServiceFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productServiceInput" placeholder="Serviço" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="productKindForm">Tipo</label>
                                <input disabled aria-describedby="productKindFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productKindInput" placeholder="Tipo" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="productPlanForm">Plano</label>
                                <input disabled aria-describedby="productPlanFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productPlanInput" placeholder="Plano" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-2">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="signedAtForm">Assinado</label>
                                <input disabled aria-describedby="signedAtFormHelp" class="form-control form-valid-control" id="signedAtForm" data-${this.controllerName}-target="signedAtInput" placeholder="Início" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="chargedAtForm">Cobrado</label>
                                <input disabled aria-describedby="chargedAtFormHelp" class="form-control form-valid-control" id="chargedAtForm" data-${this.controllerName}-target="chargedAtInput" placeholder="Finalização" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="paidAtForm">Pago</label>
                                <input disabled aria-describedby="paidAtFormHelp" class="form-control form-valid-control" id="paidAtForm" data-${this.controllerName}-target="paidAtInput" placeholder="Finalização" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="not_validated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Validada</li>
                                      <li data-status="validated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Validação Ok</li>
                                      <li data-status="paid" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pago</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label>Área</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                                  <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                                      <li data-kind="sales" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                      <li data-kind="customer_success" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Successo do Cliente</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3 d-flex align-items-center">
                          <div class="col-1 text-right px-0" data-${this.controllerName}-target="teamModalCol">
                            <button data-action="click->${this.controllerName}#showContracts" data-element="teamInput" data-${this.controllerName}-target="teamModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Parceiros</span>
                            </button>
                          </div>
                          <div class="col-5" data-${this.controllerName}-target="teamModalCol">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Parceiro</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="teamInput" type="text" placeholder="Parceiro" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-3">
                          <div class="col-3 d-flex justify-content-center align-items-center">
                            <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex mx-5">
                              <input disabled type="checkbox" class="custom-control-input" id="setupCheck" data-kind="setup" data-${this.controllerName}-target="setupCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="setupCheck">Avulso</label>
                            </div>
                            <div class="custom-control custom-checkbox pr-0 pl-2 my-0 d-flex">
                              <input disabled type="checkbox" class="custom-control-input" id="recurringCheck" data-kind="recurring" data-${this.controllerName}-target="recurringCheckbox" data-action="click->${this.controllerName}#uniqKind">
                              <label class="custom-control-label f-065" for="recurringCheck">Recorrência</label>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Política de Comissão</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="policyDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="policyDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="policyInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="policyList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="grossAmountForm">Vlr Bruto</label>
                                <input aria-describedby="grossAmountFormHelp" class="form-control" id="grossAmountForm" data-${this.controllerName}-target="grossAmountInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindNetAmount keypress->${this.controllerName}#bindNetAmount" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="percentageForm">Porcentagem</label>
                                <input disabled aria-describedby="percentageFormHelp" class="form-control" id="percentageForm" data-${this.controllerName}-target="percentageInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="netAmountForm">Vlr Líquido</label>
                                <input disabled aria-describedby="netAmountFormHelp" class="form-control" id="netAmountForm" data-${this.controllerName}-target="netAmountInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${deleteBtnHtml}
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--commissions--entities--dashboard").mainTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      controller.application.purchases.forEach(purchase => {
        if (purchase.id == controller.current_commission.purchase_id) {
          var purchaseNameHtml = ``
          
          purchase.parties.forEach(party => {
            purchaseNameHtml += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="purchasePartyName">${party.party_name}</p>`
          })

          controller.purchaseInputTarget.innerHTML = purchaseNameHtml
          controller.current_purchase = purchase
        }
      })


      if (controller.actionMode == `copy`) {
        controller.formTitleTarget.innerText = `Nova Comissão`

        controller.productNameInputTarget.value = controller.current_commission.product_name_pretty
        controller.productServiceInputTarget.value = controller.current_commission.product_service_pretty
        controller.productKindInputTarget.value = controller.current_commission.product_kind_pretty
        controller.productPlanInputTarget.value = controller.current_commission.product_plan_pretty
        controller.signedAtInputTarget.value = controller.current_commission.signed_at_pretty
        controller.chargedAtInputTarget.value = controller.current_commission.charged_at_pretty
        controller.paidAtInputTarget.value = controller.current_commission.paid_at_pretty

        controller.statusDropdownTarget.value = controller.current_commission.status
        controller.statusInputTarget.innerText = controller.current_commission.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_commission.status

        controller.roleDropdownTarget.value = controller.current_commission.role
        controller.roleInputTarget.innerText = controller.current_commission.role_pretty
        controller.roleInputTarget.dataset.role = controller.current_commission.role

        // if (controller.current_commission.policy_id) {
        //   controller.policyDropdownTarget.value = controller.current_commission.policy_name
        //   controller.policyInputTarget.innerText = controller.current_commission.policy_name
        //   controller.policyInputTarget.dataset.policyId = controller.current_commission.policy_id
        // }

        if (controller.current_commission.kind == `setup`) {
          controller.setupCheckboxTarget.checked = true
          controller.recurringCheckboxTarget.checked = false
        } else if (controller.current_commission.kind == `recurring`) {
          controller.setupCheckboxTarget.checked = false
          controller.recurringCheckboxTarget.checked = true
        }


        if (controller.current_commission.gross_amount == 0) {
          controller.grossAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_purchase.total_amount)
          controller.current_commission.gross_amount = controller.current_purchase.total_amount
        } else {
          controller.grossAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_commission.gross_amount)
        }
        controller.percentageInputTarget.value = controller.controllerNumber.percentOptionMask(controller.current_commission.percentage)
        controller.netAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_commission.net_amount)

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Comissão`

        controller.productNameInputTarget.value = controller.current_commission.product_name_pretty
        controller.productServiceInputTarget.value = controller.current_commission.product_service_pretty
        controller.productKindInputTarget.value = controller.current_commission.product_kind_pretty
        controller.productPlanInputTarget.value = controller.current_commission.product_plan_pretty
        controller.signedAtInputTarget.value = controller.current_commission.signed_at_pretty
        controller.chargedAtInputTarget.value = controller.current_commission.charged_at_pretty
        controller.paidAtInputTarget.value = controller.current_commission.paid_at_pretty

        controller.statusDropdownTarget.value = controller.current_commission.status
        controller.statusInputTarget.innerText = controller.current_commission.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_commission.status

        // controller.kindDropdownTarget.value = controller.current_commission.kind
        // controller.kindInputTarget.innerText = controller.current_commission.kind_pretty
        // controller.kindInputTarget.dataset.kind = controller.current_commission.kind

        controller.roleDropdownTarget.value = controller.current_commission.role
        controller.roleInputTarget.innerText = controller.current_commission.role_pretty
        controller.roleInputTarget.dataset.role = controller.current_commission.role

        if (controller.current_commission.policy_id) {
          controller.policyDropdownTarget.value = controller.current_commission.policy_name
          controller.policyInputTarget.innerText = controller.current_commission.policy_name
          controller.policyInputTarget.dataset.policyId = controller.current_commission.policy_id
        }

        if (controller.current_commission.account_id) {
          controller.teamInputTarget.value = controller.current_commission.account_name
          controller.teamInputTarget.dataset.teamId = controller.current_commission.account_id
        }

        if (controller.current_commission.kind == `setup`) {
          controller.setupCheckboxTarget.checked = true
          controller.recurringCheckboxTarget.checked = false
        } else if (controller.current_commission.kind == `recurring`) {
          controller.setupCheckboxTarget.checked = false
          controller.recurringCheckboxTarget.checked = true
        }
        

        if (controller.current_commission.gross_amount == 0) {
          controller.grossAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_purchase.total_amount)
          controller.current_commission.gross_amount = controller.current_purchase.total_amount
        } else {
          controller.grossAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_commission.gross_amount)
        }
        controller.percentageInputTarget.value = controller.controllerNumber.percentOptionMask(controller.current_commission.percentage)
        controller.netAmountInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_commission.net_amount)

      }

      controller.setPolicyList()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  setPolicyList() {
    var policyHtml = ``
    var activePolicies = []

    this.application.policies.forEach(element => {
      if (element.status == `active` && element.role == this.current_commission.role) {
        activePolicies[activePolicies.length] = element
      }
    })

    activePolicies.forEach(element => {
      policyHtml += `<li data-policy-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setPolicy" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
    })

    this.policyListTarget.innerHTML = policyHtml
  }

  setPolicy(ev) {
    var policyId = ev.currentTarget.dataset.policyId
    this.current_policy = {}

    this.application.policies.forEach(element => {
      if (element.id == policyId) {
        this.current_policy = element
      }
    })

    this.bindNetAmount()
  }

  bindNetAmount() {
    var netAmount = 0
    var percent = 0

    if (this.current_policy.id) {
      this.current_commission.gross_amount = this.controllerNumber.fromCurrencyToNumber(this.grossAmountInputTarget.value)
      if (this.current_commission.kind == `setup`) {
        netAmount = Number(this.current_commission.gross_amount * this.current_policy.setup_percentage / 100)
        percent = Number(this.current_policy.setup_percentage)
      } else if (this.current_commission.kind == `recurring`) {
        netAmount = Number(this.current_commission.gross_amount * this.current_policy.recurring_percentage / 100)
        percent = Number(this.current_policy.recurring_percentage)
      }
    }

    this.netAmountInputTarget.value = this.controllerNumber.currencyOptionMask(netAmount)
    this.percentageInputTarget.value = this.controllerNumber.percentOptionMask(percent)
  }

  showTeams(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `users--accounts--entities--list-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).kind = `team`
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showContracts(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--books--payrolls--contracts--list-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).roles = [controller.current_commission.role]
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).open()
    })
  }

  uniqKind(ev) {
    var kind = ev.currentTarget.dataset.kind

    if (kind == `setup`) {
      this.setupCheckboxTarget.checked = true
      this.recurringCheckboxTarget.checked = false
    } else if (kind == `recurring`) {
      this.setupCheckboxTarget.checked = false
      this.recurringCheckboxTarget.checked = true
    }
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewBankCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Comissão Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewBankCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--products--commissions--entities--dashboard").viewTarget.innerHTML = html
  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  percentMask(ev) {
    this.controllerNumber.changeNumberToPercent(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.policyInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.teamInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
