import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cnpjInput", "nameInput", "tradeNameInput", "openedAt",
    "nextBtn", "errorMessage", "saveBtn", "municipalInput"]

  connect() {
    this.controllerName = `users--companies--entities--save`
    this.search = true
    // this.doCompanyFormHtml()
  }

  saveCompany() {
    this.stopCompanyRefreshing()
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.send_data = { current_user: {}, company: {}, kind: {} }
    this.saveBtnTarget.disabled = true

    if (this.actionMode == "edit") {
      this.send_data.company.id = this.current_company.id
    }
    this.send_data.company.name = this.nameInputTarget.value.trim()
    this.send_data.company.trade_name = this.tradeNameInputTarget.value.trim()
    this.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value.trim())
    this.send_data.company.municipal_number = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.municipalInputTarget.value.trim())
    this.send_data.company.opened_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.openedAtTarget.value)
    this.send_data.kind.kind = this.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `company_entities`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/companies/entities/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/companies/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var company = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.companies[controller.application.companies.length] = company
          } else if (this.actionMode == `edit`) {
            controller.application.companies.forEach((element, i) => {
              if (element.id == company.id) {
                controller.application.companies.splice(controller.application.companies.indexOf(element), 1, company)
              }
            })
          }

          controller.cancelSave()
          controller.getControllerByIdentifier("users--companies--entities--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  doFormHtml() {
    this.stopRefreshing()

    var html = `<div class="col-12">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando Nova Empresa Tipo: ${this.kind_pretty}</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="cnpjForm">Número CNPJ</label>
                              <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="Número CNPJ" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="tradeNameForm">Razão Social</label>
                              <input aria-describedby="tradeNameFormHelp" class="form-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col-12">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="nameForm">Nome Fantasia</label>
                              <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Fantasia" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row my-2">
                        <div class="col-8">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="municipalForm">Inscrição Municipal</label>
                              <input aria-describedby="municipalFormHelp" class="form-control" id="municipalForm" data-${this.controllerName}-target="municipalInput" placeholder="Inscrição Municipal" type="text" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="openedAtForm">Data de Abertura</label>
                              <input aria-describedby="openedAtFormHelp" class="form-control" id="openedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="openedAt" placeholder="DD/MM/AAAA" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveCompany" data-${this.controllerName}-target="saveBtn">Salvar</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--companies--entities--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"

      if (controller.actionMode == `new`) {
        controller.nameInputTarget.disabled = true
        controller.tradeNameInputTarget.disabled = true
        controller.municipalInputTarget.disabled = true
        controller.openedAtTarget.disabled = true  
      } else if (controller.actionMode == `edit`) {
        controller.cnpjInputTarget.value = controller.current_company.cnpj_pretty
        controller.nameInputTarget.value = controller.current_company.name
        controller.tradeNameInputTarget.value = controller.current_company.trade_name
        controller.municipalInputTarget.value = controller.current_company.municipal_number
        if (controller.current_company.opened_at) {
          controller.openedAtTarget.value = controller.current_company.opened_at_pretty
        }

        controller.saveBtnTarget.classList.remove(`d-none`)
      }
      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.openedAtTarget.classList.add("f-normal")
        this.openedAtTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.openedAtTarget.classList.add("f-danger")
        this.openedAtTarget.classList.remove("f-normal")
      }
    } else {
      this.openedAtTarget.classList.add("f-normal")
      this.openedAtTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.setErrorMessage(this.cnpj_result.message)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 14) {
      this.nameInputTarget.value = ""
      this.tradeNameInputTarget.value = ""
      this.openedAtTarget.value = ""

      this.nameInputTarget.disabled = true
      this.tradeNameInputTarget.disabled = true
      this.openedAtTarget.disabled = true


      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "") {
    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14 && this.search) {
      this.search = false
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      // this.cnpj_result = {}
      // this.cnpj_result.invalid = false
      var data = { company: { cnpj: cleanCnpj, kind: "" }, current_user: { current_user_id: this.application.current_user.id, feature: "company_entities" } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      if (this.cnpj_result.invalid == false) {
        var hasCompany = this.checkIfExist(cleanCnpj)
        if (hasCompany) {
          this.setErrorMessage("A Empresa já está cadastrada!")
          this.saveBtnTarget.remove()
        } else {
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              this.search = true
              if (response.process) {
                controller.company = response.data.cln

                if (controller.company.id) {
                  controller.nameInputTarget.value = controller.company.name
                  controller.tradeNameInputTarget.value = controller.company.trade_name
                  controller.municipalInputTarget.value = controller.company.municipal_number
                  if (controller.company.opened_at_pretty) {
                    controller.openedAtTarget.value = controller.company.opened_at_pretty
                  } else {
                    controller.openedAtTarget.value = ``
                  }

                  controller.nameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalInputTarget.disabled = false
                  controller.openedAtTarget.disabled = false

                  // controller.formTitleTarget.innerText = `Editando PJ Médica`
                  controller.saveBtnTarget.classList.remove("d-none")
                  controller.saveBtnTarget.innerText = `Confirmar`
                  controller.actionMode = `edit`
                } else {
                  // controller.formTitleTarget.innerText = `Criando PJ Médica`
                  controller.tradeNameInputTarget.value = "Favor adicionar a Razão Social da PJ Médica"
                  controller.nameInputTarget.value = "Favor adicionar o Nome Fantasia"
                  controller.municipalInputTarget.value = "Favor adicionar a Inscrição Municipal"
                  controller.openedAtTarget.value = ""

                  controller.nameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalInputTarget.disabled = false
                  controller.openedAtTarget.disabled = false

                  controller.saveBtnTarget.classList.remove("d-none")
                  controller.saveBtnTarget.innerText = `Salvar`
                  controller.actionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }

      }

    }
  }

  checkIfExist(cnpj) {
    var diffCompany = true
    var hasCompany = false
    this.application.companies.forEach(element => {
      if (element.cnpj == cnpj) {
        this.nameInputTarget.value = element.name
        this.tradeNameInputTarget.value = element.trade_name
        this.municipalInputTarget.value = element.municipal_number
        this.openedAtTarget.value = element.opened_at_pretty
        this.setErrorMessage("A Empresa já está cadastrada!")
        diffCompany = false
        hasCompany = true
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      } else {
        if (diffCompany) {
          diffCompany = true
          hasCompany = false
        }
      }
    });

    return hasCompany
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshCompanyTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {
        if (controller.cnpj_result && controller.cnpj_result.invalid) {
          len += 1
        }
        
        if (controller.cnpjInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.nameInputTarget.value == `` || controller.nameInputTarget.value == `Favor adicionar o Nome do PJ Médica`) {
          
          len += 1
        }
        
        if (controller.tradeNameInputTarget.value == `` || controller.tradeNameInputTarget.value == `Favor adicionar o Nome do PJ Médica`) {
          
          len += 1
        }
        
        if (controller.openedAtTarget.value == ``) {
          len += 1
        }
        
        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);

  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopCompanyRefreshing() {
    if (this.refreshCompanyTimer) {
      clearInterval(this.refreshCompanyTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
