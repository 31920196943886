import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "frameOne", "frameTwo", "frameQuestions", "navbarSpacing", "formCard", "frameFooter", "9999"]

  connect() {
    this.controllerName = `mobile--commercial--marketing--landings--publics--page`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.landing_slug = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.searchArray = []
    if (window.location.search.split("?")[1]) {
      this.searchArray = window.location.search.split("?")[1].split("&")
    }
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-${this.controllerName}-target="content"
                                           data-controller="mobile--commercial--marketing--landings--publics--XXX
                                                            mobile--commercial--marketing--landings--publics--XXX
                                                            mobile--commercial--marketing--landings--publics--XXX">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.mainTarget.style.height = ($(window).height()) + "px"
      controller.setNavbarSpacing()
    })
  }

  doFrameOne() {
    var html = `<section class="section-90" id="forms">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12">
                        <img
                          class="mt-6r"
                          src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png"
                          alt="Logo da Medcapital"
                          width="237"
                        >
                      </div>
                      <div class="col-12">
                        <h1 class="lp-title mt-6r">
                          ${this.application.field.title_one}
                        </h1>
                        <p class="lp-text mt-2r">
                          ${this.application.field.subtitle_one}
                        </p>
                      </div>
                      <div class="col-12 px-0">
                        <div class="d-flex justify-content-center" data-controller="mobile--commercial--marketing--landings--publics--save" data-mobile--commercial--marketing--landings--publics--save-target="form"></div>
                      </div>
                    </div>
                  </div>
                </section>`

    // this.frameOneTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.frameOneTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`mobile--commercial--marketing--landings--publics--save`).doFormHtml()
    })
  }

  doFrameTwo() {

    var midiaHTML = ``

    if (this.application.field.midia == `video`) {
      midiaHTML = `<iframe
                      class="mt-2r lp-video"
                      src="https://www.youtube.com/embed/${this.application.field.video}"
                      title="${this.application.landing.name}"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>`
    } else if (this.application.field.midia == `cover`) {
      midiaHTML = `<img src="${this.application.field.cover_url}" alt="${this.application.landing.name}" class="imagem-head w-100" style="width:25rem">`
    }

    var html = `<section class="full-vh bg-primary-linear d-flex align-items-center justify-content-center" id="lpAbout">
                  <div class="container">
                    <div class="column">
                      <div class="col-12 px-0">
                        <div class="lp-content">
                          <h1 class="col-12 px-0 lp-title text-center mt-3r" >
                            ${this.application.field.title_two}
                          </h1>
                          <p class="col-lg-5 col-md-8 col-sm-12 lp-text mt-2r text-center">
                            ${this.application.field.subtitle_two}
                          </p>
                          <div class="col-lg-7 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                            ${midiaHTML}
                          </div>
                          <a class="lp-btn-primary mt-3r" href="#forms">
                            ${this.application.landing.final_cta_btn_pretty}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.frameTwoTarget.innerHTML = html
  }

  doFrameQuestions() {
    var html = `<section class="" id="question">
                  <div class="container">
                    <div class="col-12 px-0">
                      <div class="lp-content">
                        <p class="lp-text mt-2r text-center">
                          Perguntas Frequentes
                        </p>
                        <h1 class="lp-title text-center mt-m-1r">
                          Estamos aqui para te ajudar
                        </h1>
                        <div class="col-12 px-0">
                          <div class="accordion mt-3r" id="questions">
                            <a class="expansion-panel-toggler collapsed lp-collapse-card" type="button" data-toggle="collapse" data-target="#question-1" aria-expanded="true" aria-controls="question-1">
                              ${this.application.field.question_one}
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>
                            </a>
                            <div id="question-1" class="collapse" aria-labelledby="headingOne" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                ${this.application.field.answer_one}
                              </div>
                            </div>
                            <a class="lp-collapse-card expansion-panel-toggler collapsed lp-title-colapse" type="button" data-toggle="collapse" data-target="#question-2" aria-expanded="false" aria-controls="question-2">
                              ${this.application.field.question_two}
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>
                            </a>
                            <div id="question-2" class="collapse" aria-labelledby="headingTwo" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                ${this.application.field.answer_two}
                              </div>
                            </div>
                            <a class="expansion-panel-toggler collapsed lp-collapse-card" type="button" data-toggle="collapse" data-target="#question-3" aria-expanded="false" aria-controls="question-3">
                              ${this.application.field.question_three}
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>
                            </a>
                            <div id="question-3" class="collapse" aria-labelledby="headingThree" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                ${this.application.field.answer_three}
                              </div>
                            </div>
                            <a class="expansion-panel-toggler lp-collapse-card collapsed" type="button" data-toggle="collapse" data-target="#question-4" aria-expanded="false" aria-controls="question-4">
                              ${this.application.field.question_four}
                              <div class="expansion-panel-icon ml-3 text-black-secondary">
                                <i class="collapsed-show material-icons">keyboard_arrow_down</i>
                                <i class="collapsed-hide material-icons">keyboard_arrow_up</i>
                              </div>
                            </a>
                            <div id="question-4" class="collapse" aria-labelledby="headingFour" data-parent="#questions">
                              <div class="card-body ml-2rem">
                                ${this.application.field.answer_four}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.frameQuestionsTarget.innerHTML = html
  }

  doFrameFooter() {
    var html = `<div class="lp-footer bg-primary-linear d-flex align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="text-footer col-lg-7 col-md-10 col-sm-12">
                            © MedCapital 2016-2021.
                          </p>
                          <div class="d-flex align-items-center justify-content-end col-lg-5 col-md-2 col-sm-12">
                            <a class="s-subtitle-footer a-dark" href="https://www.facebook.com/medcap/" target="blank"><i class="fab fa-facebook-f white-text mr-2"> </i> </a>
                            <a class="s-subtitle-footer a-dark" href="https://www.linkedin.com/company/medcapital/" target="blank"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
                            <a class="s-subtitle-footer a-dark" href="https://www.instagram.com/medcapital/" target="blank"><i class="fab fa-instagram white-tex mr-2"> </i> </a>
                            <a class="s-subtitle-footer a-dark" href="https://api.whatsapp.com/send?phone=5531995181912&amp;text=Olá!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    var html = `<section class="frame-site-footer py-4 w-100">
                  <div class="row d-flex align-items-start justify-content-between w-100">
                      <div class="col-sm-3 col-lg-3 my-auto text-center">
                        <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital-invert.png" style="width: 10rem;" >
                      </div>
                      <div class="col-sm-3 col-lg-3 mx-auto py-3 mb-3">
                        <h5 class="s-title-footer">Serviços </h5>
                        <p><a class="s-subtitle-footer a-dark" href="/imposto-de-renda-para-medicos">Declaração de Imposto de Renda</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/abertura-empresa-medica">Abertura PJ Médica</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/migracao-pj-medica">Migração PJ Médica</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/controle-pessoa-fisica-livro-caixa">Controle Livro-Caixa</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/faturamento-convenio-medico">Faturamento de Convênios</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/seguros">Seguros e Proteção</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/consultoria">Departamento Pessoal</a> </p>
                      </div>
                      <div class="col-sm-3 col-lg-3 mx-auto py-3 mb-3">
                        <h5 class="s-title-footer">Contatos </h5>
                        <p><a class="s-subtitle-footer a-dark" href="mailto:atendimento@medcapital.com.br?Subject=Olá!">atendimento@medcapital.com.br</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="https://api.whatsapp.com/send?phone=5531995181912&text=Olá!" target="blank">Converse conosco no WhatsApp</a> </p>
                        <p><a class="s-subtitle-footer a-dark" href="/vagas" target="blank">Faça parte do time</a> </p>
                      </div>
                      <div class="col-sm-3 col-lg-3 mx-auto py-3 mb-3">
                        <h5 class="s-title-footer">Descanso Médico </h5>
                        <p class="s-subtitle-footer a-dark"> A plataforma de conteúdo financeiro da MedCapital para facilitar a vida financeira do médico </p>
                        <p><button class="btn btn-success btnContent w-100">Aprenda Mais</button></p>
                      </div>
                    </div>
                    <div class="row d-flex align-items-center">
                      <div class="col-sm-2 col-lg-2 text-center"></div>
                      <div class="col-sm-8 col-lg-8 text-center">
                        <h6 class="mb-0 s-subtitle-footer" id="creditFooter">© MedCapital 2016-2021. CNPJ 23.448.747/0001-02. Todos os direitos reservados. <br> Feito com <i style="color:#F25F5C;" class="fas fa-heart"></i> e <i style="color:#AFAFAF;" class="fas fa-coffee"></i> em Belo Horizonte-MG. </h6>
                      </div>
                      <div class="col-sm-2 col-lg-2 text-center">
                        <a class="s-subtitle-footer a-dark" href="/descanso-medico" target="blank"><img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/Prancheta+1.png" alt="descancomedico" style="width:7%;cursor:pointer;"></a>
                        <a class="s-subtitle-footer a-dark" href="https://www.facebook.com/medcap/" target="blank"><i class="fab fa-facebook-f white-text mr-2"> </i> </a>
                        <a class="s-subtitle-footer a-dark" href="https://www.linkedin.com/company/medcapital/" target="blank"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
                        <a class="s-subtitle-footer a-dark" href="https://www.instagram.com/medcapital/" target="blank"><i class="fab fa-instagram white-tex mr-2"> </i> </a>
                        <a class="s-subtitle-footer a-dark" href="https://api.whatsapp.com/send?phone=5531995181912&text=Olá!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
                      </div>
                    </div>
                </section>`

    this.frameFooterTarget.innerHTML = html
  }

  doPreloaderHTML() {
    var html = `<div data-${this.controllerName}-target="frameOne">
                  <section class="section-90" id="forms">
                    <div class="container px-0">
                      <div class="row">
                        <div class="col-12 px-0">
                          <img
                            class="mt-6r"
                            src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png"
                            alt="Logo da Medcapital"
                            width="237"
                          >
                          <h1 class="lp-title mt-6r">
                            ${this.loader}
                            ${this.loader}
                          </h1>
                          <p class="lp-text mt-2r my-5">
                            ${this.loader}
                            ${this.loader}
                            ${this.loader}
                          </p>
                        </div>
                        <div class="col-12 px-0">
                          <div class="d-flex justify-content-center">
                            <div class="card card-lp-bg-primary lp-card mt-6r" data-${this.controllerName}-target="formCard">
                              <div class="card-body">
                                <h1 class="lp-card-title">Preencha o formulário</h1>
                                <div class="forms-group my-3">
                                  <div class="floating-label w-70">
                                    ${this.loader}
                                  </div>
                                </div>
                                <div class="forms-group my-3">
                                  <div class="floating-label w-70">
                                    ${this.loader}
                                  </div>
                                </div>
                                <div class="forms-group my-3">
                                  <div class="floating-label w-70">
                                    ${this.loader}
                                  </div>
                                </div>
                                
                                <div class="forms-group my-5">
                                  <div class="floating-label w-70">
                                    ${this.bodyCardLoader}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div data-${this.controllerName}-target="frameTwo"></div>
                <div data-${this.controllerName}-target="frameQuestions"></div>
                <div data-${this.controllerName}-target="frameFooter"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getLanding()
    })
  }

  getLanding() {
    const data = { landing: { slug: this.application.landing_slug } }
    const url = "/commercial/marketing/landings/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.landing = response.data.cln
        controller.application.field = response.data.cln.field
        controller.doFrameOne()
        controller.doFrameTwo()
        controller.doFrameQuestions()
        controller.doFrameFooter()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setNavbarSpacing() {
    // var navbarHeight = Number($('.navbar').height());
    // var navbarPadding = Number($('.navbar').css('padding-top').replace("px", "")) + Number($('.navbar').css('padding-bottom').replace("px", ""));
    // var navbarSpacing = navbarHeight + navbarPadding
    // this.navbarSpacingTarget.style.marginTop = navbarSpacing + "px"
    this.doPreloaderHTML()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
