import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "permissions", "cardPermissions", "permissionCard", "editPermissionsBtn", "permissionCardPreloader", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--profile--product`

    if (this.application.user.account_kind == `admin` || this.application.user.account_kind == `team` || this.application.user.account_kind == `doctor`) {
      this.products = ["receivement", "medreturn", "medbooking", "medfiling"]
      this.products_pretty = ["Recebimento", "Planner", "Livro-Caixa", "Declaração IRPF"]  
    } else if (this.application.user.account_kind == `helper`) {
      this.products = ["medbooking"]
      this.products_pretty = ["Livro-Caixa"]
    }
    
    this.receivement_features = ["medfat", "product_dates", "transfers", "transfer_values", "pjtaker", "guidelines", "receivement_papers", "receivement_entry_forms",
                                 "receivement_entries", "receivement_calculations"]
    this.medreturn_features = ["medreturn", "medreturn_dates", "medreturn_achievements", "medreturn_goals", "medreturn_goal_transactions", "medreturn_chart_accounts",
                               "medreturn_companies", "medreturn_patients", "medreturn_incomes", "medreturn_payments", "medreturn_documents", "medreturn_calculations",
                               "medreturn_members"]
    this.medbooking_features = ["medbooking", "medbooking_dates", "medbooking_calculations", "medbooking_patients", "medbooking_patient_contacts", "medbooking_patient_infos",
                                "medbooking_patient_healthcares", "medbooking_patient_accounts", "medbooking_offices", "medbooking_signatures", "medbooking_chart_accounts",
                                "medbooking_receipts", "medbooking_payments", "medbooking_notes", "medbooking_documents", "medbooking_provisions", "medbooking_helpers",
                                "medbooking_helper_accounts", "medbooking_helper_contacts", "medbooking_helper_infos", "medbooking_helper_profiles"]
    this.medfiling_features = ["medfiling", "medfiling_dates", "medfiling_calculations", "medfiling_files", "medfiling_notes", "medfiling_journeys",
                               "medfiling_tickets", "medfiling_agents", "medfiling_messages", "medfiling_comments"]

    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-4 pl-0" data-${this.controllerName}-target="permissions"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doGeneralHtml()
    })
  }

  doGeneralHtml() {
    var html = `<div class="card my-3" style="width:100%;display:relative;" data-${this.controllerName}-target="cardPermissions">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">Produtos</h6>
                  </div>
                  <div class="card-body" style="overflow:scroll;" data-${this.controllerName}-target="permissionCard">
                    <div class="row" data-${this.controllerName}-target="permissionCardPreloader">
                      <div class="col-8">
                        <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.permissionsTarget.innerHTML = html)
    }).then(() => {
      controller.cardPermissionsTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.checkIfUserHasPermissions()
    })
  }

  savePermissions(ev) {
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
    const data = { product: { kind: ev.currentTarget.dataset.product }, account: { account_id: this.application.user.account_id }, current_user: { current_user_id: this.application.current_user.id, permission: "update", feature: "product_permissions" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/save_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.permissionCardTarget.innerHTML = ``
        controller.checkIfUserHasPermissions()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  destroyPermissions(ev) {
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
    const data = { product: { kind: ev.currentTarget.dataset.product }, account: { account_id: this.application.user.account_id }, current_user: { current_user_id: this.application.current_user.id, permission: "delete", feature: "product_permissions" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/delete_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.permissionCardTarget.innerHTML = ``
        controller.checkIfUserHasPermissions()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  checkIfUserHasPermissions() {
    this.requests = []
    for (let index = 0; index < this.products.length; index++) {
      var product = this.products[index]
      // const data = { permission: { features: this[`${product}_features`] }, account: { account_id: this.application.user.account_id }, current_user: { current_user_id: this.application.current_user.id } }
      const data = { product: { kind: product }, account: { account_id: this.application.user.account_id, account_kind: this.application.user.account_kind  }, current_user: { current_user_id: this.application.current_user.id } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/permissions/check_if_has_product_permissions"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      this.requests[this.requests.length] = { url: url, init: init, product: product, product_pretty: this.products_pretty[index] }
    }
    this.requestNext()
  }

  requestNext() {
    var controller = this
    if (this.requests.length) {
      var request = this.requests.shift()
      fetch(request.url, request.init)
        .then(response => response.json())
        .then(response => {
          if (response.data.cln.has_permissions) {
            var editPermission = `<span class="mc-tooltip">
                                    <span class="fas fa-check-double md-400 md-success"></span>
                                    <span class="mc-tooltiptext">Tem as Permissões</span>
                                  </span>`
          } else {
            if (this.application.product_permissions.can_create) {
              var editPermission = `<button data-product="${request.product}" data-action="click->${this.controllerName}#savePermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                      <span class="material-icons md-sm md-dark">slideshow</span>
                                      <span class="mc-tooltiptext">Gerar Permissões</span>
                                    </button>`
            } else {
              var editPermission = `<span class="mc-tooltip">
                                      <span class="fas fa-times-circle md-400 md-danger"></span>
                                      <span class="mc-tooltiptext">Não Tem as Permissões</span>
                                    </span>`
            }
          }
          var destroyPermission = `<button data-product="${request.product}" data-action="click->${this.controllerName}#destroyPermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">delete</span>
                                    <span class="mc-tooltiptext">Apagar Permissões</span>
                                  </button>`

          var html = `<div class="row my-3">
                      <div class="col-8 px-0 f-065">
                        Acesso ${request.product_pretty}
                      </div>
                      <div class="col-2 px-0 text-center" data-${this.controllerName}-target="editPermissionsBtn">
                        ${editPermission}
                      </div>
                      <div class="col-2 px-0 text-center" data-${this.controllerName}-target="editPermissionsBtn">
                        ${destroyPermission}
                      </div>
                    </div>`
          if (controller.hasPermissionCardPreloaderTarget) {
            controller.permissionCardPreloaderTarget.remove()
          }
          
          new Promise(function (resolve) {
            resolve(controller.permissionCardTarget.insertAdjacentHTML("beforeend", html))
          }).then(() => {
            controller.getControllerByIdentifier("app--helpers--elements").tooltip()
            controller.requestNext()
          })
        })
        // .then(this.requestNext());
    }      
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
