import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "newPaper", "indicatorPeriod", "periodDropdown", "periodInput", "periodList", "7777", "8888", "9999",
    "duePapers", "transferPapers", "duePapersAmount", "transferPapersAmount", "openPapersAmount", "averateTerm"]

  connect() {
    this.controllerName = `mobile--operations--products--receivements--entities--show`
    this.application.receivement_token = location.pathname.split("/").pop()
    this.application.current_receivement_date = []
    this.preloaderDashboard()
    this.getCurrentUserPermissions()
  }

  reconnect() {
    this.preloaderDashboard()
    this.doReceivementDashboard()
    this.doAllCalculations()
    this.doListOfDates()
  }

  doNewPaper() {
    var html = `<div data-controller="mobile--operations--products--receivements--papers--new" data-mobile--operations--products--receivements--papers--new-target="main"></div>`

    this.mainTarget.innerHTML = html
  }

  changeDate(ev) {

    if (ev.target.classList.contains("li-selector")) {
      var dateId = ev.target.dataset.id
      this.application.dates.forEach(element => {
        if (element.id == dateId) {
          this.application.current_receivement_date = element
        }
      })
      this.application.calculations.forEach(element => {
        if (element.date_id == dateId) {
          this.application.current_calculation = element
        }
      })
      this.indicatorPeriodTarget.innerText = this.application.current_receivement_date.date_pretty
      this.doCalculation()
      this.doNewPaperBtn()
    }
  }

  doNewPaperBtn() {
    if (this.application.current_receivement_date.open) {
      this.newPaperTarget.disabled = false
    } else {
      this.newPaperTarget.disabled = true
    }
  }

  doCalculation() {
    var duePapers = parseInt( this.application.current_calculation.due_papers * 100 )
    var transferPapers = parseInt( this.application.current_calculation.transfer_papers * 100 )
    var duePapersAmount = parseInt( this.application.current_calculation.paper_due_amount * 100 )
    var transferPapersAmount = parseInt( this.application.current_calculation.paper_transfer_amount * 100 )
    var openPapersAmount = duePapersAmount - transferPapersAmount
    var averateTerm = parseInt(this.application.current_calculation.average_term * 100)

    this.duePapersTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(duePapers / 100))
    this.transferPapersTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(transferPapers / 100))
    this.duePapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(duePapersAmount / 100)
    this.transferPapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(transferPapersAmount / 100)
    this.openPapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(openPapersAmount / 100)
    this.averateTermTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(averateTerm / 100))
  }

  doAllCalculations() {
    var duePapers = 0
    var transferPapers = 0
    var duePapersAmount = 0
    var transferPapersAmount = 0
    var openPapersAmount = 0
    var averateTerm = 0

    this.application.calculations.forEach(element => {
      duePapers += parseInt(element.due_papers * 100)
      transferPapers += parseInt(element.transfer_papers * 100)
      duePapersAmount += parseInt(element.paper_due_amount * 100)
      transferPapersAmount += parseInt(element.paper_transfer_amount * 100)
      averateTerm += parseInt(element.average_term * 100)
    });
    openPapersAmount = duePapersAmount - transferPapersAmount

    this.duePapersTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(duePapers / 100))
    this.transferPapersTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(transferPapers / 100))
    this.duePapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(duePapersAmount / 100)
    this.transferPapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(transferPapersAmount / 100)
    this.openPapersAmountTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(openPapersAmount / 100)
    this.averateTermTarget.innerText = Intl.NumberFormat('pt-BR', { style: 'decimal' }).format(Math.floor(averateTerm / 100))
  }

  getReceivementDates() {
    var data = { date: { product_token: this.application.receivement_token }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln.dates
          controller.application.receivement = response.data.cln.product
          controller.application.current_receivement_date = response.data.cln.dates[0]
          controller.doListOfDates()
          controller.getEntryForms()
          controller.getOperators()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.getControllerByIdentifier("mobile--operations--products--receivements--entities--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getReceivementCalculations() {
    var data = { calculation: { product_token: this.application.receivement_token }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/receivements/calculations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.calculations = response.data.cln
          controller.doAllCalculations()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getEntryForms() {
    var data = { form: { receivement_id: this.application.receivement.id }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/receivements/forms/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.forms = response.data.cln.collection
          controller.getProcedures()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getOperators() {
    var data = { company: { active: true, kind: "pjtaker", subkind: "operator" }, current_user: { current_user_id: this.application.current_user.id, feature: "pjtaker" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/companies/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.operators = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getProcedures() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/receivements/procedures/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.procedures = response.data.cln
          if (controller.application.procedures.length == 0) {
            controller.getProcedures()
          }
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["course_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });
        controller.doReceivementDashboard()
        controller.getReceivementDates()
        controller.getReceivementCalculations()
      })
  }

  doReceivementDashboard() {
    var cardLoader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                        <div class='animated-background animated-background-5'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>`

    var html = `<div class="row mt-5">
                  <div class="col-12">
                    <h5>${this.application.user_name}, esse é o seu Dashboard de Recebimentos que são faturados por terceiros. Só selecionar um mês para acompanhar a sua <strong>Produção</strong> mensal ou lançar um novo <strong>Recebimento</strong>.</h5>
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col-2">
                    <span class="material-icons md-primary">date_range</span>
                  </div>
                  <div class="col-4 pl-0">
                    <div class="form-group">
                      <div class="floating-label floating-label-md">
                        <label>Mês</label>
                        <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="periodDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item f-1p25" id="selected" data-${this.controllerName}-target="periodInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="periodList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <button disabled type="submit" class="btn btn-flat-primary btn-sm my-3 s-title-1rem w-100" data-${this.controllerName}-target="newPaper" data-action="click->${this.controllerName}#doNewPaper">Novo Lançamento</button>
                  </div>
                </div>
                <div class="row dashboard-content my-3">
                  <div class="col-12">
                    <h6>Meu Faturamento Médico e Recebimentos - <span data-${this.controllerName}-target="indicatorPeriod">Todos</span></h6>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">local_hospital</span>
                      <span class="my-2">Procedimentos Realizados</span>
                      <span class="my-2" data-${this.controllerName}-target="duePapers">
                        ${cardLoader}
                      </span>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">local_hospital</span>
                      <span class="my-2">Procedimentos Recebidos</span>
                      <span class="my-2" data-${this.controllerName}-target="transferPapers"></span>
                    </div>
                  </div>

                  <div class="col-6 mt-3">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">local_atm</span>
                      <span class="my-2">Produção Realizada</span>
                      <span class="my-2" data-${this.controllerName}-target="duePapersAmount"></span>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">local_atm</span>
                      <span class="my-2">Produção Recebida</span>
                      <span class="my-2" data-${this.controllerName}-target="transferPapersAmount"></span>
                    </div>
                  </div>

                  <div class="col-6 mt-3">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">local_atm</span>
                      <span class="my-2">Produção em Aberto</span>
                      <span class="my-2" data-${this.controllerName}-target="openPapersAmount"></span>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class="card card-products-mobile">
                      <span class="material-icons md-primary md-400">schedule</span>
                      <span class="my-2">Prazo Médio</span>
                      <span class="my-2" data-${this.controllerName}-target="averateTerm">75 dias</span>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
    selector()
  }

  doListOfDates() {
    var listHtml = ``
    this.application.dates.forEach(element => {
      listHtml += `<li data-id="${element.id}" data-month="${element.month}" data-year="${element.year}" data-action="click->${this.controllerName}#changeDate" class="li-selector dark my-2 f-1p25">${element.date_pretty}</li>`
    });
    this.periodListTarget.innerHTML = listHtml
    this.periodInputTarget.innerText = this.application.current_receivement_date.date_pretty
    this.periodDropdownTarget.value = this.application.current_receivement_date.date_pretty
    this.doNewPaperBtn()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  preloaderDashboard() {
    var html = `<div class="row" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:3rem">
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
