import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["frames", "questions", "title", "submenu", "uploadBtn", "fileUpload", "fileName", "kindInput", "bookLink", "coverLink",
                    "beginBannerLink", "errorBannerLink", "progressUploadBar", "progressUploadCounter", "videoLinkCard",
                    "midiaDropdown", "midiaDropdownBtn", "midiaInput", "midiaList", "videoLinkCard"]

  connect() {
    this.controllerName = `commercial--marketing--events--show--landing`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadFeature = true
    this.uploadReady = false
  }

  doLandingGrid() {
    var html = `<div class="row">
                  <div class="col-6" data-${this.controllerName}-target="frames"></div>
                  <div class="col-6" data-${this.controllerName}-target="questions"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--events--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doFramesHtml()
      controller.doQuestionsHtml()
    })
  }

  inlineDisplay(ev) {
    var editor = ev.currentTarget.closest(".editorRow")
    var display = ev.currentTarget.closest(".editorRow").previousElementSibling

    display.classList.remove("d-none")
    editor.classList.add("d-none")

  }

  inlineEditor(ev) {
    var display = ev.currentTarget.closest(".displayRow")
    var editor = ev.currentTarget.closest(".displayRow").nextElementSibling
    var field = editor.dataset.field
    this.nameTarget(`${field}Input`).value = this.application.event[field]

    display.classList.add("d-none")
    editor.classList.remove("d-none")
  }

  saveEvent(ev) {
    this.send_data = { current_user: {}, event: {} }
    this.inlineDisplay(ev)
    var field = ev.currentTarget.closest(".editorRow").dataset.field


    this.send_data.event.id = this.application.event.id
    this.send_data.event.field = field
    this.send_data.event.value = this.nameTarget(`${field}Input`).value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_entities`

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/marketing/events/entities/update_field"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.event = response.data.cln
          controller.doFramesHtml()
          controller.doQuestionsHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFramesHtml() {
    if (this.application.permissions.commercial_event_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.commercial_event_entities.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveEvent" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>Frames</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Título Frame #1</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.event.frame_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="frame_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="frame_oneInput" type="text" placeholder="Título Frame #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Subtítulo Frame #1</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.event.subframe_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="subframe_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="subframe_oneInput" type="text" placeholder="Subtítulo Frame #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Título Frame #2</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.event.frame_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="frame_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="frame_twoInput" type="text" placeholder="Título Frame #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Subtítulo Frame #2</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.event.subframe_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="subframe_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="subframe_twoInput" type="text" placeholder="Subtítulo Frame #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="row d-flex align-items-center">
                          <div class="col-6 f-075 px-2 text-left">
                            Mídia Frame #2
                          </div>
                          <div class="col-6 f-075 px-2 text-center">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Mídia</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="midiaDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="midiaDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="midiaInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="midiaList" data-app--helpers--search-target="searchList">
                                      <li data-midia="video" data-action="click->${this.controllerName}#selectMidia click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vídeo</li>
                                      <li data-midia="cover" data-action="click->${this.controllerName}#selectMidia click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cover</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card" data-${this.controllerName}-target="videoLinkCard">
                          <div class="card-header p-1 text-center f-070">
                            <span>Vídeo Link</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.event.video_link}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="video_link">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="video_linkInput" type="text" placeholder="Mídia Frame #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.framesTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.event.midia == `video`) {
        controller.videoLinkCardTarget.classList.remove("d-none")
      } else {
        controller.videoLinkCardTarget.classList.add("d-none")
      }

      controller.midiaDropdownTarget.value = controller.application.event.midia
      controller.midiaInputTarget.dataset.midia = controller.application.event.midia
      controller.midiaInputTarget.innerText = controller.application.event.midia_pretty
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }) 
  }

  selectMidia(ev) {
    this.send_data = { current_user: {}, event: {} }

    this.send_data.event.id = this.application.event.id
    this.send_data.event.field = `midia`
    this.send_data.event.value = ev.currentTarget.dataset.midia

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_entities`

    this.requestSave()
  }

  doQuestionsHtml() {
    if (this.application.permissions.commercial_event_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.commercial_event_entities.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveEvent" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>Perguntas Frequentes</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #1: </span>
                                ${this.application.event.question_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_oneInput" type="text" placeholder="Pergunta #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #1: </span>
                                ${this.application.event.answer_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_oneInput" type="text" placeholder="Resposta #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #2: </span>
                                ${this.application.event.question_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_twoInput" type="text" placeholder="Pergunta #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #2: </span>
                                ${this.application.event.answer_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_twoInput" type="text" placeholder="Resposta #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #3: </span>
                                ${this.application.event.question_three}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_three">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_threeInput" type="text" placeholder="Pergunta #3" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #3: </span>
                                ${this.application.event.answer_three}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_three">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_threeInput" type="text" placeholder="Resposta #3" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      
                      
                    </div>
                  </div>
                </div>`

    this.questionsTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasKindInputTarget) {
        if (controller.uploadReady == false) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
