import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `app--stats--trackers--emails--index`
    this.tableCols = 8
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `updated_at_to_time` }
    this.hasShow = false
    this.doIndexListHtml()
  }

  showEmail(ev) {
    var controllerShow = this.getControllerByIdentifier("app--stats--trackers--emails--show")

    var emailId = ev.target.closest(".itemRow").dataset.id

    this.application.emails.forEach(element => {
      if (element.id == emailId) {
        controllerShow.current_email = element
      }
    })

    if (controllerShow.hasPreviewEmailTarget) {
      controllerShow.doViewHtml()
    } else {
      controllerShow.doEmailHtml()
    }

    // if (this.checked_id == ev.target.closest(".itemRow").dataset.id) {
    //   this.hasShow = false
    // } else {
    //   this.hasShow = true
    //   this.checked_id = ev.target.closest(".itemRow").dataset.id
    // }
    // if (!controllerShow.hasViewEmailCardTarget) {
    //   controllerShow.doViewHtml()
    // }
    // if (this.hasShow) {
    //   this.checkboxShowTargets.forEach(element => {
    //     if (checkBoxElement != element) {
    //       element.checked = false
    //     }
    //   })
    //   this.application.emails.forEach(element => {
    //     if (element.id == this.checked_id) {
    //       controllerShow.current_email = element
    //       this.application.account_contact = {}
    //       if (element.id_type == `individual`) {
    //         this.application.account_contact.account_id = element.record_id
    //         this.application.account_contact.record_type = `account_entities`
    //       } else if (element.id_type == `legal`) {
    //         this.application.account_contact.company_id = element.record_id
    //         this.application.account_contact.record_type = `company_entities`
    //       }
    //     }
    //   })
    //   controllerShow.doEmailHtml()
    // } else {
    // }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var refreshLine = `<span data-action="click->${this.controllerName}#getEmails" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Emails</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Emails Enviados</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Assunto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Remetente</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Destinatário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Email</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Anexo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Enviado</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Aberto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Clicado</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--stats--trackers--emails--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 7))
      // if (controller.application.emails.length == 0) {
      //   controller.getEmails()
      // } else {
      //   controller.doDataTable()
      // }
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_emails = []

    if (this.date_filter) {
      this.application.emails.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          current_emails[current_emails.length] = element
        }
      })
    } else {
      current_emails = this.application.emails
    }

    if (this.sort.mode == `asc`) {
      var emails = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_emails, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var emails = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_emails, this.sort.field)
    }

    if (emails.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(emails.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (emails[x] !== undefined) {
            internPage[internPage.length] = emails[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: emails.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Emails Enviados</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.emailTablePartial(element, data.length))
      });
    }
  }

  emailTablePartial(element, length) {

    if (element.sent_at) {
      var sentAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">${element.sent_at_pretty}</span></span>`
    } else {
      var sentAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Email não Enviado</span></span>`
    }

    if (element.opened_at) {
      var openedAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">${element.opened_at_pretty}</span></span>`
    } else {
      var openedAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Email não Lido</span></span>`
    }

    if (element.clicked_at) {
      var clickedAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">${element.clicked_at_pretty}</span></span>`
    } else {
      var clickedAt = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Email não clicado</span></span>`
    }

    if (element.has_attachment) {
      var attachmentName = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext mc-tooltiptext-break">${element.name_attachments}</span></span>`
    } else {
      var attachmentName = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext mc-tooltiptext-break">Não tem Anexo</span></span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showEmail">${element.subject}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${element.sender_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.receiver_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.address}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${attachmentName}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${sentAt}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${openedAt}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${clickedAt}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getEmails() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { tracker_email: { domain_id: this.domain_id, domain_type: this.domain_type }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = `/app/stats/trackers/emails/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.emails = response.data.cln
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
