import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--onboards--entities--dashboard`
  }

  doOnboardsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="list" data-controller="operations--onboards--entities--index"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="operations--onboards--entities--save operations--onboards--entities--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--onboards--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--onboards--dashboard--submenu`).dashboardToggleBtn(`TODOS`)

      controller.getControllerByIdentifier(`operations--onboards--entities--index`).permission = `operation_onboard_entities`
      controller.getControllerByIdentifier(`operations--onboards--entities--show`).permission = `operation_onboard_entities`
      controller.getControllerByIdentifier(`operations--onboards--entities--save`).permission = `operation_onboard_entities`

      controller.getControllerByIdentifier(`operations--onboards--entities--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`operations--onboards--entities--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
