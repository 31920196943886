import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addProjectBtn", "pendencyCount", "sideBarReport", "clearListBtn", "optionBtn", "projectCheckBox"]

  connect() {
    this.controllerName = `users--works--trackers--projects--index`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pendencies = 0
    this.pageNumber = 0
    this.tableCols = 8
    this.numberPages = []
    this.updateContract = false
    this.sort = { mode: `asc`, field: `name` }
  }

  addProject() {
    this.getControllerByIdentifier("users--works--trackers--projects--save").current_project = {}
    this.getControllerByIdentifier("users--works--trackers--projects--save").actionMode = `new`
    this.getControllerByIdentifier("users--works--trackers--projects--save").doFormHtml()
  }

  editProject(ev) {
    var element = ev.currentTarget
    var projectId = element.closest(".itemRow").dataset.id

    this.application.projects.forEach(element => {
      if (element.id == projectId) {
        this.getControllerByIdentifier("users--works--trackers--projects--save").current_project = element
        this.getControllerByIdentifier("users--works--trackers--projects--save").actionMode = `edit`
        this.getControllerByIdentifier("users--works--trackers--projects--save").doFormHtml()
      }
    })
  }

  showProject(ev) {
    var element = ev.currentTarget
    var projectUrl = element.closest(".itemRow").dataset.url

    window.open(projectUrl, `_blank`)
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doProjectList() {
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshProject" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Projetos</span>`

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addProject" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Projeto</span>`
    } else {
      var addLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="row">
                  <div class="col-12">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto">Todos os Projetos</h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${clearBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Tickets</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Entregáveis</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Time</th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">
                                    Área
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="unit_pretty" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="unit_pretty" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">
                                    Tipo
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">
                                    Status
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_projects = this.application.projects

    if (this.sort.mode == `asc`) {
      var projects = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_projects, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var projects = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_projects, this.sort.field)
    }

    if (projects.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(projects.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (projects[x] !== undefined) {
            internPage[internPage.length] = projects[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: projects.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Projetos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.projectTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  projectTablePartial(element, length) {
    
    var squadHtml = ``
    element.squads.forEach(squad => {
      if (element.squads.length == 1) {
        squadHtml += `${this.getControllerByIdentifier("app--visits--users").getInitials(squad.team_name)}`  
      } else if (element.squads[element.squads.length - 1] == squad) {
        squadHtml += ` ${this.getControllerByIdentifier("app--visits--users").getInitials(squad.team_name)}`  
      } else {
        squadHtml += ` ${this.getControllerByIdentifier("app--visits--users").getInitials(squad.team_name)} |`  
      }
    })

    if (this.application.permissions[this.permission].can_manage) {
      var editBtn = `<button data-action="click->${this.controllerName}#editProject" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var editBtn = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-url="${element.project_path}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-url="${element.project_path}">`
    }

    // `<small class="mb-0 f-065 mc-tooltip grab">
    //   <span data-controller="app--helpers--copy" data-copy="${element.record_name}" data-action="click->app--helpers--copy#copy">${element.record_name}</span>
    //   <span class="mc-tooltiptext">Clique para Copiar</span>
    // </small>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showProject">
                      ${element.name}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.total_in_process}/${element.total_tickets}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.total_stories}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${squadHtml}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.unit_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${editBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  refreshProject() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Atualizando Projetos!` })

    const data = { project: { active: true, project_ids: this.application.project_ids }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_projects` } }
    const url = "/users/works/trackers/projects/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.projects = response.data.cln
        
        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
