import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--helpers--date`
    this.setConstants()
  }

  transformAnualDate(date) {

    // 2022-09-16 -> 16/Set/2022
    var all_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    if (date != null && date != undefined && date != ``) {
      var day = date.split("-")[2]
      var month = Number(date.split("-")[1])
      var year = date.split("-")[0]

      month = all_months[month - 1]

      return `${day}/${month}/${year}`
    }
  }

  transformAnualDateToISO(date) {

    // 2022-09-16 -> 16/Set/2022
    var all_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    if (date != null && date != undefined && date != ``) {
      var day = Number(date.split("-")[2])
      var month = Number(date.split("-")[1])
      var year = Number(date.split("-")[0])

      var new_date = new Date(year, month - 1, day)
      
      return new_date.toISOString()
    }
  }

  transformPrettyDate(date) {
    // Tue Jul 26 2022 00:04:33 GMT-0300 -> 16/Set/2022

    var all_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    if (date != null && date != undefined && date != ``) {
      var day = date.getDate()
      var month = date.getMonth()
      var year = date.getFullYear()

      month = all_months[month]
      if (day < 10) {
       day = `0${day}` 
      }

      return `${day}/${month}/${year}`
    }
  }

  transformPrettyDateTime(date) {
    // Tue Jul 26 2022 00:04:33 GMT-0300 -> 16/Set/2022 às 00:04:33

    var all_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    if (date != null && date != undefined && date != ``) {
      var seconds = date.getSeconds()
      var minutes = date.getMinutes()
      var hours = date.getHours()
      var day = date.getDate()
      var month = date.getMonth()
      var year = date.getFullYear()

      month = all_months[month]
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hours < 10) {
        hours = `0${hours}`
      }
      if (day < 10) {
        day = `0${day}`
      }

      return `${day}/${month}/${year} às ${hours}:${minutes}:${seconds}`
    }
  }

  transformOrderedDate(date) {
    // 2022-07-26T03:00:12.000Z -> 20220726030012

    var all_months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    if (date != null && date != undefined && date != ``) {
      var seconds = date.getSeconds()
      var minutes = date.getMinutes()
      var hours = date.getHours()
      var day = date.getDate()
      var month = date.getMonth()
      var year = date.getFullYear()

      month = all_months[month]
      if (seconds < 10) {
       seconds = `0${seconds}` 
      }
      if (minutes < 10) {
       minutes = `0${minutes}` 
      }
      if (hours < 10) {
       hours = `0${hours}` 
      }
      if (day < 10) {
       day = `0${day}` 
      }

      return `${year}${month}${day}${hours}${minutes}${seconds}`
    }
  }

  transformAllKindDate(date) {
    if (date != null && date != undefined && date != ``) {
      var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      var all_enUS_months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var day = Number(date.split("/")[0])
      var month = date.split("/")[1]
      var year = Number(date.split("/")[2])

      if (all_ptBR_months.includes(month)) {

        if (this.application.browserName == `safari`) {
          var new_date = new Date(year, all_ptBR_months.indexOf(month), day)
        } else {
          var new_month = all_enUS_months[all_ptBR_months.indexOf(month)]
          var new_date = new Date(date.replace(month, new_month))
        }

        return new_date.toISOString()
      } else {
        month = Number(month)
        var new_date = new Date(year, month - 1, day)
        return new_date.toISOString()
      }

    }
  }

  transformSafariAllKindDate(date) {
    if (date != null && date != undefined && date != ``) {
      var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      var all_enUS_months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      var day = Number(date.split("/")[0])
      var month = date.split("/")[1]
      var year = Number(date.split("/")[2])

      if (all_ptBR_months.includes(month)) {
        var new_month = all_enUS_months[all_ptBR_months.indexOf(month)]
        var new_date = new Date(year, all_ptBR_months.indexOf(month), day)

        return new_date.toISOString()
      } else {
        month = Number(month)
        var new_date = new Date(year, month - 1, day)
        return new_date.toISOString()
      }

    }
  }

  transformToTokenDate(date) {

    // MM/YYYY => YYYYMM

    if (date != null && date != undefined && date != `` && date != `Não Definida`) {

      var month = Number(date.split("/")[0])
      var year = Number(date.split("/")[1])

      if (month < 10) {
        var token_date = `${year}0${month}`
      } else { 
        var token_date = `${year}${month}`
      }
      
      return token_date
    }
  }

  transformNumberDate(date) {
    if (date != null && date != undefined && date != `` && date != `Não Definida`) {
      var day = Number(date.split("/")[0])
      var month = Number(date.split("/")[1])
      var year = Number(date.split("/")[2])

      var new_date = new Date(year, month - 1, day)
      return new_date.toISOString()
    }
  }
  
  transformFullDate(date) {
    if (date != null && date != undefined && date != ``) {
      var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      var all_enUS_months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      
      var month = date.split("/")[1]
      var new_month = all_enUS_months[all_ptBR_months.indexOf(month)]
      var new_date = new Date(date.replace(month, new_month))

      return new_date.toISOString()
    } else {
    }
  }

  transformFullMobileDate(date) {
    if (date != null && date != undefined && date != ``) {
      var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      
      var day = Number(date.split("/")[0])
      var month = Number(all_ptBR_months.indexOf(date.split("/")[1]))
      var year = Number(date.split("/")[2])
      var new_date = new Date(year, month, day)
      return new_date.toISOString()
    } else {
    }
  }

  getShortMonth(month) {
    var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    return all_ptBR_months[month]
  }

  getOrderedMonth(month) {
    var all_months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

    return all_months[month]
  }

  mountDate(month, year) {

    // 3, 2022 -> Mar/2022

    var all_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    var new_date = `${all_months[month - 1]}/${year.toString().slice(-2)}`

    return new_date
  }

  pickDate(ev) {
    ev.target.pickdate({
      today: 'Hoje',
      ok: 'Selecionar',
      cancel: 'Fechar',
      closeOnCancel: true,
      closeOnSelect: true,
      container: 'body',
      containerHidden: 'body',
      firstDay: 0,
      format: 'dd/mmm/yy',
      formatSubmit: 'yyyy-mm-dd',
      // hiddenPrefix: 'pickdate-input-' + input,
      hiddenPrefix: 'pickdate-input-stimulus',
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      labelMonthSelect: 'Escolha um mês do menu',
      labelYearSelect: 'Escolha um ano do menu',
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      showMonthsFull: true,
      showMonthsShort: true,
      showWeekdaysFull: true,
      showWeekdaysShort: true,
      selectMonths: true,
      selectYears: true,
      onClose: function (e) {
      },
    });

    element.on('mousedown', function (e) {
      e.preventDefault();
      // $(this).dblclick()
    });
  }

  fullExtension(date) {
    var all_ptBR_months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    var all_ptBR_full_months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

    var day = date.split("/")[0]
    var month = all_ptBR_full_months[all_ptBR_months.indexOf(date.split("/")[1])]
    var year = date.split("/")[2]

    return `${day} de ${month} de ${year}`
  }

  checkDate(date, checkFutureDate = true) {
    var currentDate = new Date()
    var check = {}

    var day = Number(date.split("/")[0])
    var month = Number(date.split("/")[1])
    var year = Number(date.split("/")[2])

    if (month > 12) {
      check.valid = false
      check.message = `Data Inválida - Mês inválido`
    } else if ((year == currentDate.getFullYear()) && (month > currentDate.getMonth() + 1)) {
      check.valid = false
      check.message = `Data Inválida - Mês inválido`
    } else if (this.checkMonthLength(day, month) == false) {
      check.valid = false
      check.message = `Data Inválida - Dia inválido`
    } else if (checkFutureDate && year > currentDate.getFullYear()) {
      check.valid = false
      check.message = `Data Inválida - Ano Superior ao Ano Atual`
    // } else if (year < 2016) {
    //   check.valid = false
    //   check.message = `Data Inválida - Ano inválido`
    } else {
      check.valid = true
      check.message = ``
    }

    return check
  }

  checkMonthLength(day, month) {
    var monthLength = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31']

    if (day > monthLength[month - 1]) {
      return false
    } else {
      return true
    }
  }

  getMonthLength(month) {
    var monthLength = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31']

    return monthLength[month - 1]
  }

  setConstants() {
    this.monthCalendar = [
      { month: 0, month_name: `Janeiro`},
      { month: 1, month_name: `Fevereiro`,},
      { month: 2, month_name: `Março`,},
      { month: 3, month_name: `Abril`,},
      { month: 4, month_name: `Maio`,},
      { month: 5, month_name: `Junho`,},
      { month: 6, month_name: `Julho`,},
      { month: 7, month_name: `Agosto`,},
      { month: 8, month_name: `Setembro`,},
      { month: 9, month_name: `Outubro`,},
      { month: 10, month_name: `Novembro`,},
      { month: 11, month_name: `Dezembro`},
    ]

    // { days: [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `10`, `11`, `12`, `13`, `14`, `15`, `16`, `17`, `18`, `19`, `20`, `21`, `22`, `23`, `24`, `25`, `26`, `27`, `28`, `29`, `30`, `31`] },
    this.dayCalendar = [
      { month: 4, days: [`3`, `4`, `5`, `6`, `7`, `10`, `11`, `12`, `13`, `14`, `17`, `18`, `19`, `20`, `21`, `24`, `25`, `26`, `27`, `28`, `31`] },
      { month: 5, days: [`1`, `2`, `4`, `7`, `8`, `9`, `10`, `11`, `14`, `15`, `16`, `17`, `18`, `21`, `22`, `23`, `24`, `25`, `28`, `29`, `30`] },
      { month: 6, days: [`1`, `2`, `5`, `6`, `7`, `8`, `9`, `12`, `13`, `14`, `15`, `16`, `19`, `20`, `21`, `22`, `23`, `26`, `27`, `28`, `29`, `30`] },
      { month: 7, days: [`2`, `3`, `4`, `5`, `6`, `9`, `10`, `11`, `12`, `13`, `16`, `17`, `18`, `19`, `20`, `23`, `24`, `25`, `26`, `27`, `30`, `31`] },
      { month: 8, days: [`1`, `2`, `3`, `6`, `8`, `9`, `10`, `13`, `14`, `15`, `16`, `17`, `20`, `21`, `22`, `23`, `24`, `27`, `28`, `29`, `30`] },
      { month: 9, days: [`1`, `4`, `5`, `6`, `7`, `8`, `11`, `13`, `14`, `15`, `18`, `19`, `20`, `21`, `22`, `25`, `26`, `27`, `28`, `29`] },
      { month: 10, days: [`1`, `3`, `4`, `5`, `8`, `9`, `10`, `11`, `12`, `16`, `17`, `18`, `19`, `22`, `23`, `24`, `25`, `26`, `29`, `30`] },
      { month: 11, days: [`1`, `2`, `3`, `6`, `7`, `8`, `9`, `10`, `13`, `14`, `15`, `16`, `17`, `20`, `21`, `22`, `23`, `27`, `28`, `29`, `30`] },
    ]
  }

  daysInMonth(month, year) {
    var date = new Date(year, month, 32)
    var days = 32 - date.getDate()
    return days
  }

  getWeekDay(day) {
    var weekdaysFull = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],

    weekDay = weekdaysFull[day]

    return weekDay
  }

  getFirstDate(date, days) {

    var firstDate = ``

    var currentDateDay = date.getDate()
    var currentDateMonth = date.getMonth()
    var currentDateYear = date.getFullYear()

    var firstDateDay = currentDateDay - days
    var firstDateMonth = date.getMonth()
    var firstDateYear = date.getFullYear()

    if (Number(currentDateDay - days) > 0) {
      firstDate = new Date(currentDateYear, currentDateMonth, firstDateDay)
    } else if (Number(currentDateDay - days) <= 0) {
      firstDateMonth = Number(firstDateMonth - 1)
      if (firstDateMonth < 0) {
        firstDate = new Date(Number(currentDateYear) - 1, 11, Number(this.getMonthLength(11)) + Number(currentDateDay) - days)
      } else {
        firstDate = new Date(currentDateYear, firstDateMonth, Number(this.getMonthLength(firstDateMonth + 1)) + Number(currentDateDay) - days)
      }
    }

    return firstDate
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}