
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    this.controllerName = `commercial--marketing--searchs--entities--submenu`
    // this.doSubmenuHtml()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("commercial--marketing--searchs--entities--summary").doSummaryGrid()
  }

  goToSearchs() {
    this.getControllerByIdentifier("commercial--marketing--searchs--entities--list").doIndexListHtml()
  }



  doSubmenuHtml() {

    var dashboardLink = `<span data-action="click->${this.controllerName}#goToDashboard" class="dropdown-item py-1 pointer dropdown-submenu-item">Dashboard</span>`
    var searchsLink = `<span data-action="click->${this.controllerName}#goToSearchs" class="dropdown-item py-1 pointer dropdown-submenu-item">Buscas</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${dashboardLink}
                          ${searchsLink}
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
