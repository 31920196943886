import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carousel", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--about`
    this.pageNumber = 0
    this.numberPages = []
  }

  doHtml() {
    var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(this.application.site.team_link_cta)
    var aligItem = ``
    var classLeft = ``
    var classRight = ``
    var imgWidth = ``
    var btnMargin = ``

    if (this.application.size == `large`) {
      classLeft = `col-5 d-flex align-items-center`
      classRight = `col-7 d-flex justify-content-center align-items-center`
      imgWidth = `550`
      aligItem = `align-items-center`
    } else if (this.application.size == `medium` || this.application.size == `small`) {
      classLeft = `col-12 d-flex align-items-center text-center`
      classRight = `col-12 d-flex justify-content-center align-items-center mt-5`
      imgWidth = `400`
      btnMargin = `mx-auto`
      aligItem = `align-items-center`
    }

    var html = `<section class="ws-section-about d-flex ${aligItem} ws-bg-circle">
                  <div class="container">
                    <div class="row">
                      <div class="${classLeft}">
                        <div class="columns w-100">
                          <h1 class="ws-title-action">${this.application.site.team_title}</h1>
                          <p class="ws-text-action">
                            ${this.application.site.team_description}
                          </p>
                          <a class="ws-btn-action mt-5 pointer ${btnMargin}" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.team_cta}</a>
                        </div>
                      </div>
                      <div class="${classRight}">
                        <div class="row" data-${this.controllerName}-target="cards"></div>
                      </div>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setTeam()
    })
  }

  setTeam() {
    var current_teams = []

    this.application.site.teams.forEach(team => {
      if (team.status) {
        current_teams[current_teams.length] = team
      }
    })
    current_teams = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_teams, `order`)

    if (this.application.size == `large`) {
      this.itemsOnPage = 4
    } else if (this.application.size == `medium`) {
      this.itemsOnPage = 2
    } else if (this.application.size == `small`) {
      this.itemsOnPage = 2
    }

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_teams.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_teams[x] !== undefined) {
          internPage[internPage.length] = current_teams[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    this.doCarouselData(pages[pageNumber])
  }

  doCarouselData(data) {
    var html = `<div class="col-lg-12 text-center">
                  <h1 class="ws-title-section">Conheça nosso Time</h1>
                </div>`
             
    var html = ``                
    data.forEach(team => {
      var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(team.link_cta)
      var colClass = ``

      if (this.application.size == `large`) {
        colClass += `col-6`
      } else if (this.application.size == `medium`) {
        colClass += `col-6`
      } else if (this.application.size == `small`) {
        colClass += `col-6`
      }

      html += `<div class="${colClass}">
                  <div class="ws-card-services pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">
                    <div class="container">
                      <h3 class="ws-card-title">${team.title}</h3>
                      <p class="ws-card-text">
                        ${team.description}
                      </p>
                      <a class="ws-card-link">${team.cta}</a>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5">
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.carouselIndicatorTargets[this.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = ev.currentTarget.dataset.slideTo

    this.setTeam()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-section-about d-flex align-items-center ws-bg-circle">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex align-items-center col-lg-5 col-sm-12">
                        <div class="columns">
                          <h1 class="ws-title-action">Somos uma empresa de pessoas para pessoas.</h1>
                          <p class="ws-text-action">
                            Fundada por médicos, engenheiros e contadores, a MedCapital é a 1ª empresa
                            com foco exclusivo no Médico. Somos referência em finanças, controle financeiro
                            e contabilidade para Saúde.
                          </p>
                          <a class="ws-btn-action mt-5" href="">Agendar consultoria</a>
                        </div>
                      </div>
                      <div class="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <h1 class="ws-title-section">Conheça nosso Time</h1>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <div class="ws-card-services pointer">
                              <div class="container">
                                <h3 class="ws-card-title">Gustavo Bonfim</h3>
                                <p class="ws-card-text">
                                  Engenheiro formado no ITA. Trabalhou mais de 10 anos no Mercado Financeiro antes
                                  de iniciar a MedCapital. CEO e Chefe de Tecnologia
                                </p>
                                <a class="ws-card-link" href="">Saiba mais</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <div class="ws-card-services pointer">
                              <div class="container">
                                <h3 class="ws-card-title">Aléxica Dias</h3>
                                <p class="ws-card-text">
                                  Formada em Ciências Contábeis pelo Ibmec-MG e em Controladoria e Finanças na UFMG. É Mestre pela UFMG.
                                  Trabalhou na área contábil de grandes empresas antes de iniciar a MedCapital.
                                  Contadora e Chefe de Operações
                                </p>
                                <a class="ws-card-link" href="">Saiba mais</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <div class="ws-card-services pointer">
                              <div class="container">
                                <h3 class="ws-card-title">Marlon Madson</h3>
                                <p class="ws-card-text">
                                  Médico formado pela UFMG, com residência em Cirurgia Vascular pelo Hospital Odilon Behrens e Endovascular pelo HC-UFMG.
                                </p>
                                <a class="ws-card-link" href="">Saiba mais</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <div class="ws-card-services pointer">
                              <div class="container">
                                <h3 class="ws-card-title">Vinícius Paulino</h3>
                                <p class="ws-card-text">
                                  Médico formado pela UFMG, com residência em Radiologia pelo HC-UFMG.
                                </p>
                                <a class="ws-card-link" href="">Saiba mais</a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 text-center mt-5">
                            <ol class="carousel-indicators">
                              <li data-target="#carouselExampleIndicators" data-slide-to="0" class="carousel-indicator-icon active"></li>
                              <li data-target="#carouselExampleIndicators" data-slide-to="1" class="carousel-indicator-icon"></li>
                              <li data-target="#carouselExampleIndicators" data-slide-to="2" class="carousel-indicator-icon"></li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
