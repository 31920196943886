import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxShow", "cardTitle", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--files--index`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.doIndexListHtml()
  }

  editDocument(ev) {
    this.checked_id = ev.target.closest(".itemRow").dataset.id

    var checkBoxElement = ``
    if (ev.target.tagName == "INPUT") {
      checkBoxElement = ev.target
    } else if (ev.target.tagName == "TD" || ev.target.tagName == "SPAN" || ev.target.tagName == "BUTTON") {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          checkBoxElement = element
        }
      })

      if (checkBoxElement.checked) {
        checkBoxElement.checked = false
      } else {
        checkBoxElement.checked = true
      }
    }

    if (checkBoxElement.checked) {
      this.checkboxShowTargets.forEach(element => {
        if (checkBoxElement != element) {
          element.checked = false
        }
      })
      this.application.files.forEach(element => {
        if (element.id == this.checked_id) {
          this.getControllerByIdentifier("operations--products--tax-filings--files--save").current_file = element
        }
      })
      this.getControllerByIdentifier("operations--products--tax-filings--files--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("operations--products--tax-filings--files--show").doViewHtml()
      this.getControllerByIdentifier("operations--products--tax-filings--files--save").stopRefreshing()
    }

  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    // `<div class="dropdown">
    //   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
    //   <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
    // </div>`
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle"></h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-45 align-middle">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                  Status
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                  Capítulo
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chapter_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chapter_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Tempo</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Anotações</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3" colspan="2"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_files = []
    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_files[this.current_files.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_files, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_files, this.sort.field)
    }

    if (files.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
      this.cardTitleTarget.innerText = `Total de ${files.length} Documentos`
    } else {

      if (files.length == 1) {
        this.cardTitleTarget.innerText = `Total de ${files.length} Documento`
      } else {
        this.cardTitleTarget.innerText = `Total de ${files.length} Documentos`
      }

      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(files.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (files[x] !== undefined) {
            internPage[internPage.length] = files[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: files.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Documentos para a Declaração do Ano-Exercício ${this.application.current_date.year + 1}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.fileTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  fileTablePartial(element, length) {
    if (this.application.permissions[this.application.files_permission].can_select) {
      var check = `<div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#editDocument">
                      <label class="custom-control-label f-065 pointer" for="check-${element.id}"></label>
                    </div>`
      // var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
      //               <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#editDocument">
      //               <label class="custom-control-label pointer" for="check-${element.id}"></label>
      //             </div>`
    } else {
      var check = ''
    }

    if (this.application.permissions[this.application.files_permission].can_update) {
      if (element.status == `denied` || element.status == `processed`) {
        var edit = ``
      } else {
        var edit = `<button data-action="click->${this.controllerName}#editDocument" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">edit</span>
                      <span class="mc-tooltiptext">Editar</span>
                    </button>`
      }
    } else {
      var edit = ``
    }

    // if (this.application.permissions[this.application.files_permission].can_show) {
    //   var document = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${element.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
    //                     <span class="material-icons md-sm md-dark">launch</span>
    //                     <span class="mc-tooltiptext">Ver Documento</span>
    //                   </button>`
    // } else {
    //   var document = ``
    // }

    if (this.application.permissions[this.application.files_permission].can_show) {
      var document = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
    } else {
      var document = `<span class="mc-tooltip">
                        <span class="material-icons md-200 md-danger default">link_off</span>
                        <span class="mc-tooltiptext">Não há Arquivo</span>
                      </span>`
    }

    var refresh = ``

    if (element.notes) {
      var elementNotes = element.notes
    } else {
      var elementNotes = `Sem Anotações`
    }

    var notes = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                  <span class="material-icons md-sm md-dark">speaker_notes</span>
                  <span class="mc-tooltiptext mc-tooltiptext-break">${elementNotes}</span>
                </button>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.chapter_pretty == undefined) { element.chapter_pretty = `Não Definido` }
    if (element.term_pretty == undefined) { element.term_pretty = `Não Definido` }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${check}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editDocument">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editDocument">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editDocument">${element.chapter_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center" data-action="click->${this.controllerName}#editDocument">${element.term_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${notes}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center" data-${this.controllerName}-target="file-${element.id}">${document}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${refresh}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${edit}</td>
                    
                    
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="150">150</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="200">200</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.application.files_permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  refreshLink(ev) {
    var fileId = ev.currentTarget.closest(".itemRow").dataset.id
    var data = { file: { id: fileId }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/operations/products/tax_filings/files/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var file = response.data.cln
          var document = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${file.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Documento</span>
                          </button>`
          controller.nameTarget(`file-${fileId}`).innerHTML = document
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToDocument(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
