import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "saveBtn", "lastFourInput", "limitInput",
                    "medDropdown", "medDropdownBtn", "medInput", "medList", "channelNameInput", "closingDayInput",
                    "providerDropdown", "providerDropdownBtn", "providerInput", "providerList", "payDayInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "providerModal", "providerInput", "medModal", "medInput"]


  connect() {
    this.controllerName = `financials--books--cards--entities--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--cards--entities--dashboard").doCardsDashboard()
  }

  saveCard() {
    this.send_data = { current_user: {}, card: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.card.id = this.current_card.id
    }
    this.send_data.card.channel_name = this.channelNameInputTarget.value.trim()
    this.send_data.card.closing_day = this.closingDayInputTarget.value
    this.send_data.card.pay_day = this.payDayInputTarget.value
    this.send_data.card.last_four = this.lastFourInputTarget.value
    this.send_data.card.credit_limit = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.limitInputTarget.value)
    this.send_data.card.med_id = this.medInputTarget.dataset.medId
    this.send_data.card.provider_id = this.providerInputTarget.dataset.providerId
    this.send_data.card.status = this.statusInputTarget.dataset.status

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_card_entities`
  
    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/cards/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/cards/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var card = response.data.cln
          controller.stopRefreshing()
          if (controller.actionMode == `new`) {
            controller.application.cards[controller.application.cards.length] = card
          } else if (controller.actionMode == `edit`) {
            controller.application.cards.forEach((element, i) => {
              if (element.id == card.id) {
                controller.application.cards.splice(controller.application.cards.indexOf(element), 1, card)
              }
            })
          }
          controller.cancelSave()
          // controller.getControllerByIdentifier("financials--books--cards--entities--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_settings_card_entities.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCard" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-10 offset-1">
                      <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-3">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="closeForm">Fechamento</label>
                                <input aria-describedby="closeFormHelp" class="form-control" id="closeForm" data-${this.controllerName}-target="closingDayInput" placeholder="Fechamento" type="number" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="payForm">Pagamento</label>
                                <input aria-describedby="payFormHelp" class="form-control" id="payForm" data-${this.controllerName}-target="payDayInput" placeholder="Pagamento" type="number" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="lastFourForm">Final</label>
                                <input aria-describedby="lastFourFormHelp" class="form-control" id="lastFourForm" data-${this.controllerName}-target="lastFourInput" placeholder="Número Final" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="limitForm">Limite Crédito</label>
                                <input aria-describedby="limitFormHelp" class="form-control" id="limitForm" data-${this.controllerName}-target="limitInput" placeholder="Limite Crédito" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showProviders" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Fornecedor</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-9">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelNameForm">Nome</label>
                                <input disabled aria-describedby="channelNameFormHelp" class="form-control" id="channelNameForm" data-${this.controllerName}-target="channelNameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="good" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Válido</li>
                                      <li data-status="expired" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Expirado</li>
                                      <li data-status="canceled" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--cards--entities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Cartão de Crédito`
        
        controller.channelNameInputTarget.value = controller.current_card.channel_name
        controller.closingDayInputTarget.value = controller.current_card.closing_day
        controller.payDayInputTarget.value = controller.current_card.pay_day
        controller.lastFourInputTarget.value = controller.current_card.last_four
        controller.limitInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_card.credit_limit)

        controller.medInputTarget.value = controller.current_card.med_name
        controller.medInputTarget.dataset.medId = controller.current_card.med_id
        // controller.medDropdownTarget.value = controller.current_card.med_id

        controller.providerInputTarget.value = controller.current_card.provider_name
        controller.providerInputTarget.dataset.providerId = controller.current_card.provider_id
        // controller.providerDropdownTarget.value = controller.current_card.provider_id

        controller.statusInputTarget.innerText = controller.current_card.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_card.status
        controller.statusDropdownTarget.value = controller.current_card.status

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Cartão de Crédito`
        controller.limitInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        controller.statusInputTarget.innerText = `Válido`
        controller.statusInputTarget.dataset.status = `good`
        controller.statusDropdownTarget.value = `good`
        controller.statusDropdownBtnTarget.disabled = true
      }

      controller.refreshSaveBtn()
      controller.refreshChannelName()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  bindChannelName() {
    var number = this.lastFourInputTarget.value
    var medNickname = ``
    var providerNickname = ``

    this.application.providers.forEach(provider => {
      if (provider.id == Number(this.providerInputTarget.dataset.providerId)) {
        providerNickname = provider.nickname
      }
    })

    this.application.meds.forEach(med => {
      if (med.id == Number(this.medInputTarget.dataset.medId)) {
        medNickname = med.name
      }
    })

    this.channelNameInputTarget.value = `${medNickname} - Cartão ${providerNickname}-${number}`
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_settings_card_entities`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshChannelName() {
    var controller = this
    this.refreshChannelNameTimer = setInterval(function () {
      
      controller.bindChannelName()

    }, 200);
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.channelNameInputTarget.value == ``) {
        len += 1
      }

      if (controller.closingDayInputTarget.value == ``) {
        len += 1
      }

      if (controller.payDayInputTarget.value == ``) {
        len += 1
      }

      if (controller.lastFourInputTarget.value == ``) {
        len += 1
      }

      if (controller.limitInputTarget.value == ``) {
        len += 1
      }

      if (controller.medInputTarget.value == ``) {
        len += 1
      }

      if (controller.providerInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.refreshChannelNameTimer) {
      clearInterval(this.refreshChannelNameTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
