import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "upload", "list", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--uploads--dashboard`
  }

  doUploadsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard" data-action="dragover->${this.controllerName}#dragOverHandler">
                  <div class="col-4 pr-1" data-${this.controllerName}-target="upload" data-controller="operations--products--tax-filings--uploads--add"></div>
                  <div class="col-8 pl-1" data-${this.controllerName}-target="list" data-controller="operations--products--tax-filings--uploads--list"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`).dashboardToggleBtn(`DOCUMENTOS`)
    }) 
  }

  dragOverHandler(ev) {
    ev.preventDefault();
    // ev.target.classList.add("not-allowed")
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
