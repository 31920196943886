import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "content", "actionView", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--receivables--dashboard`
  }

  doReceivablesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="actionView"></div>
                  <div class="col-10 pl-0" data-${this.controllerName}-target="content" data-controller="operations--products--clinics--financials--receivables--index
                                                                                                         operations--products--clinics--financials--receivables--update-status
                                                                                                         operations--products--clinics--financials--add--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.setReceivables()
      controller.getControllerByIdentifier(`operations--products--clinics--financials--show--submenu`).dashboardToggleBtn(`NOTA FISCAL`)
      controller.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`).doActionCard()

      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--index`).permission = `medclinic_financial_receivables`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).permission = `medclinic_financial_receivables`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).integration_permission = `medclinic_financial_integration_invoices`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--update-status`).permission = `medclinic_financial_receivables`

      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--index`).doIndexListHtml()
    })
  }

  emitReceivable() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`).emitReceivable()
  }

  setReceivables() {
    this.application.receivables = []
    this.application.canceled_receivables = []
    
    this.application.all_receivables.forEach(element => {
      if (element.invoice_status == "canceled" || element.invoice_status == "substituted") {
        this.application.canceled_receivables[this.application.canceled_receivables.length] = element
      } else {
        this.application.receivables[this.application.receivables.length] = element
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
