import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "bodyTable", "contracts", "receivables", "client", "sideBarReport", "addons",
                    "clearListBtn", "optionBtn", "conciliation", "conciliationCheck", "conciliationBtn", 
                    "conciliationTotal"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--modal--receivables`
    this.controllerMain = `financials--books--receivables--conciliations--modal--main`
    this.controllerBilling = `financials--books--receivables--conciliations--modal--billing`
    this.controllerClient = `financials--books--receivables--conciliations--modal--client`
    this.controllerContracts = `financials--books--receivables--conciliations--modal--contracts`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.current_contract_receivables = []
    this.receivable_item_ids = []
    this.conciliationProcess = false
    this.conciliationProcessId = ``
  }

  doReceivablesList() {
    var activeReceivables = []

    if (this.conciliationProcess) {
      this.current_contract_receivables.forEach(receivable => {
        if (receivable.id == this.conciliationProcessId) {
          activeReceivables[activeReceivables.length] = receivable
        }
      })
    } else {
      this.current_contract_receivables.forEach(receivable => {
        if (receivable.status == `pending`) {
          activeReceivables[activeReceivables.length] = receivable
        }
      })
    }

    var html = ``
    var activeReceivableHtml = ``

    if (activeReceivables.length > 0) {
      var hasNoClientReceivable = true
      var orderedActiveReceivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(activeReceivables, `date_token`)
      orderedActiveReceivables.forEach(receivable => {

        if (receivable.client_iugu_id == this.getControllerByIdentifier(this.controllerBilling).current_billing.client_id_iugu) {
          hasNoClientReceivable = false

          var cardShow = `<p class="mb-0 f-065">Iugu ID: ${receivable.client_iugu_id} <br>Email: ${receivable.client_iugu_email}</p>`
          receivable.items.forEach(item => {
            cardShow += `<p class="mb-0 f-065">${item.description}</p>
                       <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(item.amount)} <br>Liquidação: ${receivable.channel_kind_pretty} | ${receivable.channel_name}</p>`

          })

          this.application.dates.forEach(date => {
            if (receivable.date_id == date.id) {
              receivable.date_due_name = date.due_name
            }
          })

          if (receivable.conciliated) {
            var destroyBtn = ``
          } else {
            var destroyBtn = `<div class="col-2">
                              <span class="mc-tooltip">
                                <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-id="${receivable.id}" data-${this.controllerName}-target="destroyBtn-${receivable.id}" data-action="click->${this.controllerName}#deleteReceivable" type="button">
                                  <span class="material-icons f-1p25">delete</span>
                                </button>
                                <span class="mc-tooltiptext">Apagar Recebível</span>
                              </span>
                            </div>`
          }


          activeReceivableHtml += `<div class="col-12">
                                  <div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="receivableCard-${receivable.id}">
                                    <div class="card-body p-0 f-065">
                                      <div class="row d-flex align-items-center">
                                        <div class="col-8">
                                          <div class="card-show-dropdown w-100">
                                            <p class="mb-0 f-065">${receivable.description}</p>
                                            <p class="mb-0 f-065">${receivable.date_due_name} | Valor Cobrado: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(receivable.total_amount_charged)}</p>
                                            <div class="card-show-dropdown-content text-left w-100">
                                              ${cardShow}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          <span class="mc-tooltip">
                                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-id="${receivable.id}" data-action="click->${this.controllerName}#doConciliationHtml" type="button">
                                              <span class="material-icons md-dark">currency_exchange</span>
                                            </button>
                                            <span class="mc-tooltiptext">Fazer a Conciliação</span>
                                          </span>
                                        </div>
                                        ${destroyBtn}
                                      </div>
                                    </div>
                                  </div>
                                </div>`
        }
      })
    } else {
      activeReceivableHtml += `<div class="col-12 text-center">
                                  <span class="f-085"><strong>Não há Recebíveis Pendentes</strong></span>
                                </div>`
    }

    if (hasNoClientReceivable) {
      activeReceivableHtml += `<div class="col-12 text-center">
                                  <span class="f-085"><strong>Não há Recebíveis Pendentes</strong></span>
                                </div>`
    }

    html += `<div class="row mt-5 d-flex align-items-center">
              ${activeReceivableHtml}
              <div class="col-12 px-0 mt-5" data-${this.controllerName}-target="conciliation"></div>
            </div>`

    this.getControllerByIdentifier(this.controllerClient).receivablesTarget.innerHTML = html
  }

  doConciliationHtml(ev) {
    this.getControllerByIdentifier(this.controllerBilling).checkMode = `conciliation`
    this.getControllerByIdentifier(this.controllerBilling).billing_item_ids = []
    this.receivable_item_ids = []
    var receivableId = ev.currentTarget.dataset.id
    
    this.current_receivable = {}

    this.current_contract_receivables.forEach(element => {
      if (element.id == receivableId) {
        this.current_receivable = element
      }
    })

    this.conciliationProcessId = receivableId
    this.conciliationProcess = true
    this.doReceivablesList()

    this.doReceivableItemsList()
    this.getControllerByIdentifier(this.controllerBilling).enableItemsCheck()
  }

  doReceivableItemsList() {


    var html = `<table class="table table-sm table-hover" style="font-size:80%;">
                  <thead>
                    <tr>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Competência</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle">Descrição</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle text-right">Valor</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Status</th>
                    </tr>
                  </thead>
                  <tbody data-${this.controllerName}-target="bodyTable">
                  </tbody>
                </table>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.conciliationTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    var receivableItems = this.current_receivable.items

    this.listData(receivableItems)
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="5" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Itens no Recebível</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.receivableItemTablePartial(element, data.length))
      })

      var html = `<tr>
                    <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle text-left" data-${this.controllerName}-target="conciliationTotal"></td>
                    <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle text-right">
                      <span class="mc-tooltip">
                        <button disabled aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0 d-none" data-${this.controllerName}-target="conciliationBtn" data-action="click->${this.controllerName}#itemConciliation" type="button">
                          <span class="material-icons f-1p25">price_check</span>
                        </button>
                        <span class="mc-tooltiptext">Conciliar Item</span>
                      </span>
                    </td>
                  </tr>`

      this.bodyTableTarget.insertAdjacentHTML("beforeend", html)

      this.current_receivable.items.forEach(element => {
        if (element.conciliated) {
          this.nameTarget(`checkReceivable-${element.id}`).disabled = true
          this.nameTarget(`checkReceivable-${element.id}`).checked = true
        }
      })

    }

  }

  receivableItemTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    this.application.dates.forEach(date => {
      if (element.date_id == date.id) {
        element.date_short_due_name = date.short_due_name
      }
    })

    if (element.conciliated) {
      var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-success default">done</span>
                      <span class="mc-tooltiptext">Item Conciliado</span>
                    </span>`
    } else {
      var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-danger default">clear</span>
                      <span class="mc-tooltiptext">Item não Conciliado</span>
                    </span>`
    }

    if (element.notes) {
      var elementNotes = element.notes
    } else {
      var elementNotes = element.contract_name
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                        <input type="checkbox" class="custom-control-input" id="checkReceivable-${element.id}" data-${this.controllerName}-target="checkReceivable-${element.id} conciliationCheck" data-id="${element.id}" data-amount="${element.amount}" data-kind="${element.kind}" data-conciliated="${element.conciliated}" data-action="click->${this.controllerName}#conciliateCheck ${this.controllerName}#bindSum" >
                        <label class="custom-control-label f-065" for="checkReceivable-${element.id}"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_short_due_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="p-0 mc-tooltip help">
                        <span>${element.description}</span>
                        <span class="mc-tooltiptext">${elementNotes}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-right">${this.controllerNumber.currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    
                  </tr>`

    return rowHtml

      // `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
      //   <span class="mc-tooltip">
      //     <button disabled aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0 d-none" data-amount="${element.amount}" data-id="${element.id}" data-${this.controllerName}-target="conciliationBtn-${element.id} conciliationBtn" data-action="click->${this.controllerName}#itemConciliation" type="button">
      //       <span class="material-icons f-1p25">price_check</span>
      //     </button>
      //     <span class="mc-tooltiptext">Conciliar Item</span>
      //   </span>
      // </td>`
  }

  itemConciliation(ev) {
    if (this.current_receivable.channel_kind == `payment`) {
      var r = confirm(`Confirma a Conciliação dos Itens?`)
      if (r) {
        if (this.getControllerByIdentifier(this.controllerBilling).billingItemTarget) {
          var receivableItemTarget = ev.currentTarget

          this.getControllerByIdentifier(this.controllerBilling).billingItemTarget.disabled = true
          this.getControllerByIdentifier(this.controllerBilling).billingItemTarget.dataset.conciliated = true

          // this.nameTarget(`checkReceivable-${receivableItemTarget.dataset.id}`).disabled = true
          // this.nameTarget(`checkReceivable-${receivableItemTarget.dataset.id}`).dataset.conciliated = true

          this.send_data = { billing_item: {}, receivable_item: {}, billing: {}, receivable: {}, current_user: {} }

          this.send_data.billing_item.ids = this.getControllerByIdentifier(this.controllerBilling).billing_item_ids
          this.send_data.receivable_item.ids = this.receivable_item_ids

          // this.send_data.billing_item.id = this.getControllerByIdentifier(this.controllerBilling).billingItemTarget.dataset.id
          // this.send_data.receivable_item.id = receivableItemTarget.dataset.id
          this.send_data.billing.id = this.getControllerByIdentifier(this.controllerBilling).current_billing.id
          this.send_data.receivable.id = this.current_receivable.id

          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `financial_receivable_entities`

          this.requestConciliation()

          // var billingItemAmount = this.getControllerByIdentifier(this.controllerBilling).billingItemTarget.dataset.amount
          // var receivableItemAmount = receivableItemTarget.dataset.amount
          // if (Math.abs(billingItemAmount) == Math.abs(receivableItemAmount)) {
          // } else {
          //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Os valores de conciliação não conferem! Favor verificar!`, 4000)
          // }
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor selecionar um Item da Fatura para a conciliação`, 4000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Recebíveis recebidos direto na Conta Bancária ainda não são conciliados`, 4000)
    }
    
  }

  requestConciliation() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Fazendo conciliação, aguarde!` })
    this.refreshLoadValue({ submessage: `Fazendo conciliação, aguarde!`, value: 10})
    // this.getControllerByIdentifier(this.controllerMain).addLoader()

    const url = "/financials/books/receivables/entities/conciliation_receivable_billing"

    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.conciliationBtnTargets.forEach(element => {
            element.disabled = true
            element.classList.add(`d-none`)
          })
          controller.getControllerByIdentifier(controller.controllerMain).removeLoader()

          var receivable = response.data.cln.receivable
          var receivableItems = response.data.cln.receivable_items
          var billing = response.data.cln.billing
          var billingItems = response.data.cln.billing_items

          controller.application.receivables.forEach((element, i) => {
            if (element.id == receivable.id) {
              controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1, receivable)
            }
          })

          controller.application.billings.forEach((element, i) => {
            if (element.id == billing.id) {
              controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
            }
          })

          if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)) {
            var billingController = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)

            billingController.iugu_billings.forEach((element, i) => {
              if (element.id == billing.id) {
                billingController.iugu_billings.splice(billingController.iugu_billings.indexOf(element), 1, billing)
              }
            })
          }

          if (controller.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`)) {
            controller.application.billing_dates.forEach((element, i) => {
              if (element.id == billing.id) {
                controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(element), 1, billing.billing_dates[0])
              }
            })
          }

          controller.current_receivable = receivable
          controller.current_contract_receivables.forEach(element => {
            if (element.id == receivable.id) {
              controller.current_contract_receivables.splice(controller.current_contract_receivables.indexOf(element), 1, receivable)
            }
          })

          receivableItems.forEach(receivableItem => {
            controller.current_receivable.items.forEach(element => {
              if (element.id == receivableItem.id) {
                controller.current_receivable.items.splice(controller.current_receivable.items.indexOf(element), 1, receivableItem)
              }
            })
          })

          controller.doReceivableItemsList()
          if (receivable.conciliated) {
            controller.nameTarget(`destroyBtn-${receivable.id}`).remove()
          }
          controller.getControllerByIdentifier(controller.controllerBilling).current_billing = billing

          billingItems.forEach(billingItem => {
            controller.getControllerByIdentifier(controller.controllerBilling).current_billing.items.forEach(element => {
              if (element.id == billingItem.id) {
                controller.getControllerByIdentifier(controller.controllerBilling).current_billing.items.splice(controller.getControllerByIdentifier(controller.controllerBilling).current_billing.items.indexOf(element), 1, billingItem)
              }
            })  
          })
          
          controller.getControllerByIdentifier(controller.controllerBilling).doBillingHtml()
        }

        controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  conciliateCheck(ev) {
    var itemTarget = ev.currentTarget

    // itemTarget.checked = true
    if (itemTarget.checked) {
      this.receivable_item_ids[this.receivable_item_ids.length] = itemTarget.dataset.id 
    } else {
      this.receivable_item_ids.forEach((element, i) => {
        if (element == itemTarget.dataset.id) {
          this.receivable_item_ids.splice(this.receivable_item_ids.indexOf(element), 1)
        }
      })
    }

    if (this.receivable_item_ids.length > 0) {
      this.conciliationBtnTarget.disabled = false
      this.conciliationBtnTarget.classList.remove(`d-none`)
    } else {
      this.conciliationBtnTarget.disabled = true
      this.conciliationBtnTarget.classList.add(`d-none`)
    }

  }

  bindSum() {
    var totalAmount = 0

    this.conciliationCheckTargets.forEach(conciliationCheck => {
      if (conciliationCheck.checked && conciliationCheck.dataset.conciliated == `false`) {

        if (conciliationCheck.dataset.kind == `revenue` || conciliationCheck.dataset.kind == `fine_interests` || conciliationCheck.dataset.kind == `refund` || conciliationCheck.dataset.kind == `advance`) {
          totalAmount += Number(conciliationCheck.dataset.amount)
        } else if (conciliationCheck.dataset.kind == `discount` || conciliationCheck.dataset.kind == `reversal`) {
          totalAmount -= Number(conciliationCheck.dataset.amount)
        }
        // @receivable.total_amount_invoice = @receivable.total_amount_revenue + @receivable.total_amount_fines_and_interests - @receivable.total_amount_discount
        // @receivable.total_amount_charged = @receivable.total_amount_invoice + @receivable.total_amount_refund

      }
    })

    this.conciliationTotalTarget.innerText = `Total para Conciliar: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(totalAmount)}`
  }

  getContractReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.getControllerByIdentifier(this.controllerMain).addLoader()

    const data = { receivable: { active: true, contract_id: this.current_contract.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_entities` } }
    const url = "/financials/books/receivables/entities/list_by_contract"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.current_contract_receivables = response.data.cln
        } else {
          controller.current_contract_receivables = []
        }

        controller.doReceivablesList()
        controller.getControllerByIdentifier(controller.controllerMain).removeLoader()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getContractAddons() {
    if (this.hasBodyTableTarget) {
      this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.getControllerByIdentifier(this.controllerMain).addLoader()
    }

    const data = { addon: { active: true, contract_id: this.current_contract.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_addons` } }
    const url = "/financials/books/clients/addons/list_by_contract"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.current_contract_addons = response.data.cln
          controller.controllerSaveReceivable = controller.getControllerByIdentifier("financials--books--receivables--entities--save-from-conciliation")

          controller.application.meds.forEach(element => {
            if (element.id == controller.current_billing.med_id) {
              controller.controllerSaveReceivable.current_med = element
            }
          })

          controller.controllerSaveReceivable.controllerDashboard = controller
          controller.controllerSaveReceivable.permission = `financial_receivable_entities`
          controller.controllerSaveReceivable.gridElement = controller.receivablesTarget
          controller.controllerSaveReceivable.current_billing = controller.current_billing
          controller.controllerSaveReceivable.current_client = controller.current_client
          controller.controllerSaveReceivable.current_contract = controller.current_contract
          controller.controllerSaveReceivable.current_contract_addons = controller.current_contract_addons
          controller.controllerSaveReceivable.channel_kind = [`payment`]
          controller.controllerSaveReceivable.actionMode = `new`
          controller.controllerSaveReceivable.doFormHtml()
        } else {
          alert(`Contrato sem Complemento Ativo. Favor adicionar um Complemento.`)
        }

        // controller.doAddonsList()

        controller.getControllerByIdentifier(controller.controllerMain).removeLoader()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteReceivable(ev) {
    var r = confirm(`Tem certeza que deseja apagar o Recebível`)
    if (r) {

      var receivableId = ev.currentTarget.dataset.id

      var html = `<div class="row mt-3">
                    <div class="col-12 px-0" data-${this.controllerName}-target="receivables" data-controller="financials--books--receivables--entities--save-from-conciliation"></div>
                  </div>`

      // this.formTarget.innerHTML = html
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier(controller.controllerClient).receivablesTarget.innerHTML = html)
      }).then(() => {
        controller.controllerSaveReceivable = controller.getControllerByIdentifier("financials--books--receivables--entities--save-from-conciliation")

        controller.controllerSaveReceivable.controllerDashboard = controller
        controller.controllerSaveReceivable.permission = `financial_receivable_entities`

        controller.controllerSaveReceivable.send_data = { current_user: {}, receivable: {} }
        controller.controllerSaveReceivable.actionMode = `destroy`

        controller.controllerSaveReceivable.send_data.receivable.id = receivableId
        controller.controllerSaveReceivable.send_data.receivable.active = false

        controller.controllerSaveReceivable.send_data.current_user.current_user_id = controller.application.current_user.id
        controller.controllerSaveReceivable.send_data.current_user.feature = `financial_receivable_entities`

        controller.controllerSaveReceivable.requestSave()
      })
    }
  }

  cleanConciliationProcess() {
    this.conciliationProcessId = ``
    this.conciliationProcess = false
  }

  doReceivablesLoader() {
    var html = `<div class="card-body py-0" style="overflow:auto;">
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
              </div>`

    this.getControllerByIdentifier(this.controllerClient).receivablesTarget.innerHTML = html
  }

  refreshLoadValue(submessage, value) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: submessage, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()

        var response = { type: `danger`, message: `Tivemos um problema para conciliar a Fatura` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 500);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}