import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "title", "metric", "event", "notesRow", "ticketsRow", "menuCard", "menuRows"]

  connect() {
    this.controllerName = `commercial--marketing--events--show--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.event_slug = location.pathname.split("/").pop()
    this.application.permissions = {}
    this.application.attendees = []
    this.application.tasks = []
    this.application.admissions = []
  }

  publishEvent(ev) {
    this.send_data = { current_user: {}, event: {} }
    var publish = ev.currentTarget.dataset.publish
    var action = ``
    if (publish == `publish`) {
      this.send_data.event.published = true
      this.send_data.event.published_at = new Date()
      action = `Publicar`
    } else {
      this.send_data.event.published = false
      this.send_data.event.published_at = null
      action = `Despublicar`
    }

    this.send_data.event.id = this.application.event.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_entities`

    var r = confirm(`Tem certeza que deseja ${action} o evento?`)
    if (r) {
      this.requestSave()
    }
  }

  blockEvent(ev) {
    this.send_data = { current_user: {}, event: {} }
    var block = ev.currentTarget.dataset.block
    var action = ``
    if (block == `block`) {
      this.send_data.event.blocked = true
      // this.send_data.event.blocked_at = new Date()
      action = `Bloquear`
    } else {
      this.send_data.event.blocked = false
      // this.send_data.event.blocked_at = null
      action = `Desbloquear`
    }

    this.send_data.event.id = this.application.event.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_entities`

    var r = confirm(`Tem certeza que deseja ${action} o evento?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    
    var url = "/commercial/marketing/events/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.event = response.data.cln
          controller.doEventCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }


  doMetricsCards() {
    var html = `<div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Visitas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.event.total_visits)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Interações</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.event.total_interactions)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Inscrições</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.event.total_enrollments)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.metricTarget.innerHTML = html
  }

  doEventCards() {

    if (this.application.event.published) {
      var publishedText = `${this.application.event.published_at_full}`
      if (this.application.event.finished) {
        var publishedIcon = ``
      } else {
        var publishedIcon = `<span class="mc-tooltip pointer ml-auto" data-publish="unpublish" data-action="click->${this.controllerName}#publishEvent">
                              <span class="material-icons md-sm md-success">check_circle</span>
                              <span class="mc-tooltiptext">Deseja despublicar?</span>
                            </span>`
      }
    } else {
      var publishedText = `<span>Evento não publicado</span>`
      if (this.application.event.finished) {
        var publishedIcon = ``
      } else {
        var publishedIcon = `<span class="mc-tooltip pointer ml-auto" data-publish="publish" data-action="click->${this.controllerName}#publishEvent">
                                <span class="material-icons md-sm md-danger">unpublished</span>
                                <span class="mc-tooltiptext">Deseja publicar?</span>
                              </span>`
      }
    }

    if (this.application.event.blocked) {
      var blockedText = `<span>Evento não liberado</span>`
      if (this.application.event.finished) {
        var blockedIcon = ``
      } else {
        var blockedIcon = `<span class="mc-tooltip pointer ml-auto" data-block="unblock" data-action="click->${this.controllerName}#blockEvent">
                            <span class="material-icons md-sm md-danger">block</span>
                            <span class="mc-tooltiptext">Deseja Liberar?</span>
                          </span>`
      }
    } else {
      var blockedText = `<p class="mb-0 f-075 mc-tooltip grab">
                            <span data-controller="app--helpers--copy" data-copy="${this.application.event.live_path}" data-action="click->app--helpers--copy#copy">Página da Transmissão</span>
                            <span class="mc-tooltiptext">Clique para Copiar</span>
                          </p>`
      // var blockedText = `<span class="ml-auto">Evento liberado</span>
      //                     <span class="mc-tooltip pointer ml-auto" data-action="click->${this.controllerName}#goToLive">
      //                       <span class="material-icons md-sm md-dark">cast_for_education</span>
      //                       <span class="mc-tooltiptext">Ir para Transmissão</span>
      //                     </span>`
      if (this.application.event.finished) {
        var blockedIcon = ``
      } else {
        var blockedIcon = `<span class="mc-tooltip pointer ml-auto" data-block="block" data-action="click->${this.controllerName}#blockEvent">
                            <span class="material-icons md-sm md-success">check_circle</span>
                            <span class="mc-tooltiptext">Deseja Bloquear?</span>
                          </span>`
      }
    }

    if (this.application.event.started) {
      var startedText = `<span>Evento iniciado</span>`
    } else {
      var startedText = `<span>Evento não iniciado</span>`
    }

    if (this.application.event.broadcast) {
      var broadcastText = `<span>Ao Vivo</span>`
    } else {
      var broadcastText = `<span>Não está Ao Vivo</span>`
    }

    if (this.application.event.finished) {
      var finishedText = `<span>Evento Finalizado</span>`
    } else {
      var finishedText = `<span>Evento não finalizado</span>`
    }

    var destroyCard = ``
    if (this.application.current_user.super_admin) {
      destroyCard = `<div class="col-12 my-2 px-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070 d-flex">
                          <span class="ml-auto">Apagar Evento</span>
                        </div>
                        <div class="card-body text-center px-0 py-2">
                          <div class="row">
                            <div class="col-12 text-center f-075 f-bold px-1">
                              <span class="mc-tooltip pointer mx-auto" data-action="click->${this.controllerName}#destroyEvent">
                                <span class="material-icons md-sm md-dark">delete</span>
                                <span class="mc-tooltiptext">Deseja Apagar?</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`
    }

    var dateText = `${this.application.event.date_at_full}`
    
    var html = `<div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070 d-flex">
                      <span class="ml-auto">Publicação</span>
                      ${publishedIcon}
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          ${publishedText}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070 d-flex">
                      <span class="ml-auto">Data Evento</span>
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          ${dateText}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Inscrição</span>
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          <p class="mb-0 f-075 mc-tooltip grab">
                            <span data-controller="app--helpers--copy" data-copy="${this.application.event.enrollment_link}" data-action="click->app--helpers--copy#copy">Página de Inscrição</span>
                            <span class="mc-tooltiptext">Clique para Copiar</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070 d-flex">
                      <span class="ml-auto">Liberação</span>
                      ${blockedIcon}
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          ${blockedText}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ${destroyCard}`

    this.eventTarget.innerHTML = html
  }

  goToLive() {
    var url = this.application.event.live_path
    window.open(url, `_self`)
  }

  doDashboardHtml() {

    // `<div class="col-2">
    //   <div class="row">
    //     <div class="col-12 px-0">
    //       <h6 class="mb-0 d-flex align-items-center"><span>Métricas de Inscrição</span></h6>
    //       <hr class="my-1">
    //     </div>
    //   </div>
    //   <div class="row" data-${this.controllerName}-target="metric">
    //     <span class="w-100 my-2">${this.bodyCardLoader}</span>
    //     <span class="w-100 my-2">${this.bodyCardLoader}</span>
    //     <span class="w-100 my-2">${this.bodyCardLoader}</span>
    //   </div>
    // </div>`

    // `<div class="w-100" data-commercial--marketing--events--tickets--dashboard-target="ticketBoard" data-controller="commercial--marketing--events--tickets--dashboard">
    //   <span class="w-100">${this.loader}</span>
    // </div>`

    var html = `<div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row" data-controller="commercial--marketing--events--squads--show">
                      <div class="col-12">
                        <h6 class="mb-0 text-center d-flex" data-commercial--marketing--events--squads--show-target="title">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-commercial--marketing--events--squads--show-target="main"></div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center"><span>Evento</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="event">
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row w-100">
                      <div class="col-3 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard menuCard" data-feature="tickets" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard menuCard" data-feature="notes" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        <div class="row" data-controller="commercial--marketing--events--show--tickets" data-${this.controllerName}-target="ticketsRow menuRows"></div>
                        <div class="row d-none" data-controller="commercial--marketing--events--show--notes" data-${this.controllerName}-target="notesRow menuRows"></div>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--events--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      if (this.application.event) {
        // controller.doMetricsCards()
        controller.getControllerByIdentifier(`commercial--marketing--events--show--tickets`).doTicketsDashboard()
        controller.getControllerByIdentifier(`commercial--marketing--events--show--notes`).doNotesDashboard()
        controller.getControllerByIdentifier(`commercial--marketing--events--squads--show`).doSquadsHeader()
        controller.getControllerByIdentifier(`commercial--marketing--events--squads--show`).doSquadsHtml()
        controller.doEventCards()
      }
    })
  }

  toggleCard(ev) {
    var element = ev.currentTarget

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.menuRowsTargets.forEach(element => {
      element.classList.add(`d-none`)
    })

    element.classList.add(`bg-primary`)
    this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)
  }

  destroyEvent(ev) {
    this.send_data = { current_user: {}, event: {} }

    this.send_data.event.id = this.application.event.id
    this.send_data.event.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_entities`

    var r = confirm(`Tem certeza que deseja apagar o Evento?`)
    if (r) {
      this.requestDestroy()
    }
  }

  requestDestroy() {
    var url = "/commercial/marketing/events/entities/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          window.open(`/a/eventos`, '_self')
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  cardsExample() {
    // `<div class="col-12 my-2 px-0">
    //   <div class="card">
    //     <div class="card-header p-1 text-center f-070">
    //       <span>Início</span>
    //     </div>
    //     <div class="card-body text-center px-0 py-2">
    //       <div class="row">
    //         <div class="col-12 text-center f-075 f-bold px-1">
    //           ${startedText}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div class="col-12 my-2 px-0">
    //   <div class="card">
    //     <div class="card-header p-1 text-center f-070">
    //       <span>Transmissão</span>
    //     </div>
    //     <div class="card-body text-center px-0 py-2">
    //       <div class="row">
    //         <div class="col-12 text-center f-075 f-bold px-1">
    //           ${broadcastText}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div class="col-12 my-2 px-0">
    //   <div class="card">
    //     <div class="card-header p-1 text-center f-070">
    //       <span>Finalizado</span>
    //     </div>
    //     <div class="card-body text-center px-0 py-2">
    //       <div class="row">
    //         <div class="col-12 text-center f-075 f-bold px-1">
    //           ${finishedText}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>`
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
