import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "requests", "responses", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--builders--dashboard`
  }

  doBuildersDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-5">
                    <div class="col-12 px-0">
                      <h6 class="mb-0 text-center">
                        <span class="mc-tooltip help">
                          <span>Requests</span>
                          <span class="mc-tooltiptext">Listar, Adicionar as Requests e Parâmetros</span>
                        </span>
                      </h6>
                      <hr class="mt-1">
                    </div>
                    <div class="col-12 px-0" data-${this.controllerName}-target="requests" data-controller="app--stats--builders--list app--stats--builders--save app--stats--builders--perform"></div>
                  </div>
                  <div class="col-7">
                    <div class="col-12 px-0">
                      <h6 class="mb-0 text-center">
                        <span class="mc-tooltip help">
                          <span>Respostas</span>
                          <span class="mc-tooltiptext">Respostas</span>
                        </span>
                      </h6>
                      <hr class="mt-1">
                    </div>
                    <div class="col-12 px-0" data-${this.controllerName}-target="responses" data-controller="app--stats--builders--responses app--stats--builders--show"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--stats--trackers--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`app--stats--trackers--dashboard--submenu`).dashboardToggleBtn(`REQUESTS BUILD`)

      controller.getControllerByIdentifier(`app--stats--builders--list`).permission = `app_stats_builders_requests`
      controller.getControllerByIdentifier(`app--stats--builders--save`).permission = `app_stats_builders_requests`
      
      controller.getControllerByIdentifier(`app--stats--builders--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`app--stats--builders--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
