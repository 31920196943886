import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewCnaeCard", "viewTitle", "viewCnaeCardBody", "previewCard", "previewTitle", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--cnaes--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCnaeCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum CNAE Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCnaeCardBody"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--dashboard").viewTarget.innerHTML = html
  }

  editCnae() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").showClearBtn()

    if (this.application.permissions[this.permission].can_update) {
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).current_cnae = this.current_cnae
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).actionMode = "edit"
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).doFormHtml()
    }
  }

  deleteCnae() {
    this.send_data = { current_user: {}, cnae: {} }

    this.actionMode = `delete`
    this.send_data.cnae.id = this.current_cnae.id
    this.send_data.cnae.active = false
    this.send_data.cnae.status = `inactive`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar CNAE ${this.current_cnae.code}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).send_data = this.send_data
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).actionMode = `delete`
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).requestSave()
    }
  }

  addCityCode() {
    this.showClearBtn()

    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).actionMode = "new"
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).current_cnae = this.current_cnae
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).current_city_code = {}
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).permission = this.permission
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).doFormHtml()
  }

  editCityCodeEv() {
    this.check = `edit_city_code`

    this.doShowTable()
  }

  editCityCode(ev) {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").showClearBtn()
    this.showClearBtn()

    var cityCodeId = ev.currentTarget.dataset.id
    this.current_cnae.city_codes.forEach(element => {
      if (element.id == cityCodeId) {
        this.current_city_code = element
      }
    })

    if (this.application.permissions[this.permission].can_update) {
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).actionMode = `edit`
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).current_cnae = this.current_cnae
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).current_city_code = this.current_city_code
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).permission = this.permission
      this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save-city-code`).doFormHtml()
    }
  }

  doCnaeHtml() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addCityCode" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Código Municipal</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editLine = `<span data-action="click->${this.controllerName}#editCityCodeEv" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Código Municipal</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editCnaeLine = `<span data-action="click->${this.controllerName}#editCnae" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar CNAE</span>`
    } else {
      var editCnaeLine = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var deleteLine = `<span data-action="click->${this.controllerName}#deleteCnae" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Código Municipal</span>`
    } else {
      var deleteLine = ``
    }

    // var refreshLine = `<span data-action="click->${this.controllerName}#refreshCnaes" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Código Municipals</span>`
    var closeLine = `<span data-action="click->${this.controllerName}#clearList" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${editCnaeLine}
                          ${addLine}
                          ${editLine}
                          ${deleteLine}
                          ${closeLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="viewCnaeCardBody">
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `CNAE | ${controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_cnae.full_code)}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.doShowTable()
    })
  }

  doShowTable() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var cityCodesHtml = ``
    var currentCityCodes = []
    currentCityCodes = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_cnae.city_codes, `city`)

    if (currentCityCodes.length > 0) {
      currentCityCodes.forEach(city_code => {
        if (this.current_cnae.city_codes.length == 1) {
          var tableRow = `<tr class="itemRow" data-id="${city_code.id}" style="height:50px;">`
        } else {
          var tableRow = `<tr class="itemRow" data-id="${city_code.id}">`
        }

        var check = ``
        if (this.check == `edit_city_code`) {
          check += `<div class="custom-control custom-checkbox px-2 w-50 mt-3">
                    <input type="checkbox" class="custom-control-input" id="check-${city_code.id}" data-${this.controllerName}-target="check-${city_code.id}" data-id="${city_code.id}" data-action="click->${this.controllerName}#editCityCode">
                    <label class="custom-control-label f-065" for="check-${city_code.id}"></label>
                  </div>`
        }

        cityCodesHtml += `${tableRow}
                          <th colspan="1" class="f-065 align-middle px-0">
                            ${check}
                          </th>
                          <th colspan="1" class="f-065 align-middle px-0">
                            <strong>Código</strong>
                          </th>
                          <td colspan="2" class="f-065 align-middle px-0 text-left">
                            ${city_code.municipal_code}
                          </td>
                          <th colspan="1" class="f-065 align-middle px-0">
                            <strong>Cidade</strong>
                          </th>
                          <td colspan="5" class="f-065 align-middle px-0 text-left">
                           ${city_code.city}/${city_code.state}
                          </td>
                        </tr>`
      });  
    } else {
      cityCodesHtml += `<tr>
                          <th colspan="10" class="f-065 align-middle px-0">
                            <strong>Não há Códigos Municipais Cadastrados</strong>
                          </th>
                        </tr>`
    }
    

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                        </tr>
                        <tr>
                          <th colspan="3" class="f-065 align-middle px-0">
                            <strong>Código</strong>
                          </th>
                          <td colspan="7" class="f-065 align-middle px-0 text-left">
                            ${this.getControllerByIdentifier(`app--helpers--numbers`).codeMask(this.current_cnae.full_code)}
                          </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="f-065 align-middle px-0">
                            <strong>Descrição</strong>
                          </th>
                          <td colspan="7" class="f-065 align-middle px-0 text-left">
                            ${this.current_cnae.description}
                          </td>
                        </tr>
                        
                        
                        <tr>
                          ${spaceRow}
                        </tr>
                        
                        <tr>
                          ${cityCodesHtml}
                        </tr>

                        
                      </tbody>
                    </table>
                  </div>
                </div>`

    this.viewCnaeCardBodyTarget.innerHTML = html
    this.check = false
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--show").doViewHtml()
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").doDataTable()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
