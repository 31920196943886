import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carousel", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--about`
    this.itemsOnPage = 1
    this.pageNumber = 0
    this.numberPages = []

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    }
  }

  doHtml() {
    var link = this.siteController.setLink(this.application.site.team_link_cta)

    var html = `<section class="mws-section-about d-flex align-items-center ws-bg-circle">
                  <div class="container">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center">
                        <div class="columns w-100">
                          <h1 class="mws-title-action text-center">${this.application.site.team_title}</h1>
                          <p class="mws-text-action text-center">
                            ${this.application.site.team_description}
                          </p>
                          <a class="ws-btn-action mws-btn-action mt-5 pointer mx-auto" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.team_cta}</a>
                        </div>
                      </div>
                      <div class="col-12 d-flex justify-content-center align-items-center mt-5">
                        <div class="row" data-${this.controllerName}-target="cards"></div>
                      </div>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.siteController.contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setTeam()
    })
  }

  setTeam() {
    var current_teams = []

    this.application.site.teams.forEach(team => {
      if (team.status) {
        current_teams[current_teams.length] = team
      }
    })
    current_teams = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_teams, `order`)

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_teams.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_teams[x] !== undefined) {
          internPage[internPage.length] = current_teams[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    this.doCarouselData(pages[pageNumber])
  }

  doCarouselData(data) {
    var html = `<div class="col-lg-12 text-center">
                  <h1 class="ws-title-section">Conheça nosso Time</h1>
                </div>`

    var html = ``
    data.forEach(team => {
      var link = this.siteController.setLink(team.link_cta)

      html += `<div class="col-12">
                  <div class="ws-card-services mws-card-services pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">
                    <div class="container">
                      <h3 class="mws-card-title">${team.title}</h3>
                      <p class="mws-card-text">
                        ${team.description}
                      </p>
                      <a class="mws-card-link">${team.cta}</a>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon mws-carousel-indicator"></li>`
    })

      // `<ol class="carousel-indicators">
      //   ${indicatorsHtml}
      // </ol>`

    var html = `<div class="col-lg-12 text-center mt-5 d-flex">
                  <span class="material-icons md-dark mr-auto pointer" data-action="click->${this.controllerName}#previousPage">arrow_back_ios</span>
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                  <span class="material-icons md-dark ml-auto pointer" data-action="click->${this.controllerName}#nextPage">arrow_forward_ios</span>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      if (controller.carouselIndicatorTargets.length > 8) {

        controller.carouselIndicatorTargets.forEach((element, i) => {
          element.classList.add(`d-none`)
        })

        for (var index = 0; index < 3; index++) {
          if (controller.carouselIndicatorTargets[controller.pageNumber - index]) {
            controller.carouselIndicatorTargets[controller.pageNumber - index].classList.remove(`d-none`)
          }
          if (controller.carouselIndicatorTargets[controller.pageNumber + index]) {
            controller.carouselIndicatorTargets[controller.pageNumber + index].classList.remove(`d-none`)
          }
        }
      }

      controller.carouselIndicatorTargets[controller.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = Number(ev.currentTarget.dataset.slideTo)

    this.setTeam()
  }

  previousPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
    }
    this.setTeam()
  }

  nextPage(ev) {
    this.cardsTarget.innerHTML = ``
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
    }
    this.setTeam()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
