import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "compilationTitle", "compilationBoard", "billingsTitle", "billingsTable", "mainCard",
    "bodyTable", "footerTable", "content", "goalLeadInput", "mainDashboard"]

  connect() {
    this.controllerName = `financials--books--clients--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reload = false
    this.load = false
    this.application.permissions = {}
    this.application.permissions = []
    this.application.meds = []
    this.application.tickets = []
    this.application.addons = []
    this.application.logs = []
    this.application.receivables = []
    this.application.renewals = []
    this.application.radars = []

    this.application.clients = []
    this.application.contracts = []
    this.doMainGrid()

  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                    <div class="col-6 px-2">
                      <div class="row my-2 w-100">
                        <div class="col-12">
                          <h6 class="mb-0 text-center pointer" data-action="click->${this.controllerName}#refreshCompilation" data-${this.controllerName}-target="compilationTitle">${this.loader}</h6>
                          <hr class="my-1">
                          <div class="row" data-${this.controllerName}-target="compilationBoard" data-controller="financials--books--clients--dashboard--compilation">
                            ${this.cardGridPreloader()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 px-2">
                      <div class="row my-2 w-100">
                        <div class="col-12 px-0">
                          <h6 class="mb-0 text-center pointer" data-${this.controllerName}-target="billingsTitle">${this.loader}</h6>
                          <hr class="mt-1">
                          <div class="row my-2" data-${this.controllerName}-target="billingsTable" data-controller="financials--books--clients--dashboard--compilation">
                            ${this.cardGridPreloader()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.load) {
        // controller.getControllerByIdentifier(`financials--books--clients--dashboard--compilation`).doCompilationCard()
      }
    })
  }

  cardGridPreloader() {
    var html = `<div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--books--clients--entities--dashboard
                                                            financials--books--clients--contracts--dashboard
                                                            financials--books--clients--renewals--dashboard
                                                            financials--books--clients--radars--dashboard

                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doMainDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  refreshCompilation() {
    this.getControllerByIdentifier("financials--books--clients--dashboard--compilation").refreshCompilation()
  }

  refreshBillings() {
    this.getControllerByIdentifier(`financials--books--clients--dashboard--billings`).refreshBillings()
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    if (this.reload) {
      this.getControllerByIdentifier("financials--books--clients--dashboard--dates").runDashboardsHtml()
      // this.getControllerByIdentifier("financials--books--clients--dashboard--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("financials--books--clients--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("financials--books--clients--dashboard--submenu").doSubmenuHtml()
      // this.getControllerByIdentifier("financials--books--clients--dashboard--meds").doMedHtml()
    }
    this.application.current_date.data_loaded = true

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      // if (!this.getControllerByIdentifier("app--helpers--page-load")) {
      //   this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
      // }

      this.finishLoadPage()
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Finalizando`, value: 85 })
      this.finishLoadPage()
    }
  }

  getFinancialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 75 })

    const data = { date: { active: true, subdomain: `receivables` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_dates` } }
    const url = "/financials/config/dates/list_with_subdomain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        })

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]

        controller.application.current_receivable_compilation = currentDates[0].receivable_compilation
        controller.application.current_regimes = currentDates[0].regimes

        controller.getControllerByIdentifier("financials--books--clients--dashboard--dates").doDatesHTML()
        controller.loadDateData()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRenewals() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Renovações`, value: 60 })

    const data = { renewal: { active: true, status: `active` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_renewals` } }
    const url = "/financials/books/clients/renewals/list_by_status"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.renewals = response.data.cln
        }

        controller.getFinancialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClientTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Clientes`, value: 60 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_entities` } }
    const url = "/financials/config/teams/list_client_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.team_list = response.data.cln
        controller.getRenewals()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClients() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Clientes`, value: 55 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_entities` } }
    const url = "/financials/books/clients/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clients = response.data.cln
        }
        controller.getClientTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 20 })
    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_meds` } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }

        controller.getClients()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_book_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getMeds()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }


  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `financials--books--clients--dashboard--main`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.updatePayble) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}