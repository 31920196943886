import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "uploadBtn", "buttonDisable", "progressUploadReceipt", "labelUpload", "journeyComment", "mainDashboard", "8888", "9999"]

  connect() {
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "xlsx", "vnd.openxmlformats-officedocument.spreadsheetml.sheet", "rtf", "docx", "DEC", "REC", ".DEC", ".REC",
                                "vnd.openxmlformats-officedocument.wordprocessingml.document", "txt", "plain", "PDF", "PNG", "JPEG", ".XLSX", ".DOCX", ".TXT"]
    this.controllerName = `mobile--operations--products--tax-filings--files--add`
    this.uploadFeature = true
    this.files = []
    this.upload_files = []
    this.sort = { mode: `asc`, field: `name` }
    this.elementIndex = 0
    this.canUploadFiles = true

  }

  setFiles() {
    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.files[this.files.length] = element
      }
    })
  }

  goToDashboard() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  doAddDashboard() {
    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Upload de Arquivos IRPF ${this.application.current_date.year + 1}</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12">
                    <div class="card" style="width:100%;display:relative;">
                      <div class="card-header card-header-title f-065 text-center">
                        <h6 class="card-title px-2 f-1" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="journeyComment"></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-2">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  <div class="col-6 d-none">
                    <div class="card bg-disabled" data-action="click->${this.controllerName}#uploadFiles" data-${this.controllerName}-target="uploadBtn">
                      <div class="row my-1">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-white md-lg mx-3">cloud_upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 p-0 d-flex align-items-center justify-content-center">
                    <span class="font-weight-bold f-1">*O tamanho dos arquivos deve ser menor que 5MB*</span>
                  </div>
                  <div class="col-12 p-0 d-flex align-items-center justify-content-center">
                    <span class="font-weight-bold f-1">*Os formatos permitidos são PDF, PNG, JPEG, XLS, RTF, DOC e TXT*</span>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12" data-${this.controllerName}-target="body">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doUploadTable()
      controller.checkCurrentCalculation()
    }) 
  }

  checkCurrentCalculation() {
    if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
      this.journeyCommentTarget.innerHTML = `Quando finalizar o Upload, aperte o play!
                                            <button data-action="click->${this.controllerName}#goToDocumentationSent" type="button" class="btn btn-sm btn-table btn-flat p-0">
                                              <span class="material-icons md-success md-300">slideshow</span>
                                            </button>`
      this.labelUploadTarget.classList.remove("not-allowed")
      this.labelUploadTarget.classList.add("copy")
      this.uploadFeature = true
    } else if (this.application.current_tax_filing_calculation.journey_status == `documentation_sent`) {
      this.journeyCommentTarget.innerHTML = `Documentação enviada. <br>Se desejar adicionar mais documentos, pause o processamento!
                                            <button data-action="click->${this.controllerName}#goToAwaitingDocumentation" type="button" class="btn btn-sm btn-table btn-flat p-0">
                                              <span class="material-icons md-danger md-300">pause_presentation</span>
                                            </button>`
      this.labelUploadTarget.classList.remove("copy")
      this.labelUploadTarget.classList.add("not-allowed")
      this.uploadFeature = false
    } else {
      this.journeyCommentTarget.innerText = `O Upload de Arquivos está desabilitado`
      this.labelUploadTarget.classList.remove("copy")
      this.labelUploadTarget.classList.add("not-allowed")
      this.uploadFeature = false
    }
  }

  goToAwaitingDocumentation() {
    if (this.application.current_agent.account_id) {
      this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
      this.send_data.journey.status = `awaiting_documentation`
      var r = confirm(`Tem certeza que deseja adicionar novos Arquivos?`)
      if (r) {
        this.createNewJourney()
      }
    } else {
      alert(`Não é possível Liberar a Documentação. Favor entrar em contato com a MedCapital e informar erro Código 044.`)
    }
  }

  goToDocumentationSent() {
    if (this.application.current_agent.account_id) {
      this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
      this.send_data.journey.status = `documentation_sent`
      var r = confirm(`Tem certeza que deseja enviar a Documentação?`)
      if (r) {
        this.createNewJourney()
      }
    } else {
      alert(`Não é possível Liberar a Documentação. Favor entrar em contato com a MedCapital e informar erro Código 044.`)
    }
  }

  createNewJourney() {
    var date = new Date()

    this.actionMode = `new`
    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.date = date.toString()

    var newStatus = this.getControllerByIdentifier(`mobile--operations--products--tax-filings--show--dashboard`).translateJourney(this.send_data.journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `changed`
    this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    if (this.application.files.length == 0) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar os arquivos antes de liberar a declaração!`, 5000)
    } else {
      this.requestJourneySave()
    }
  }

  requestJourneySave() {
    var url = "/operations/products/tax_filings/journeys/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.journeys[controller.application.journeys.length] = journey
          }
          controller.journeyCommentTarget.innerHTML = `Aguarde um instante ... <span class="material-icons md-dark md-sm">hourglass_empty</span>`
          controller.sendJourneyEmailNotification(journey)
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendJourneyEmailNotification(journey) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_token = this.application.tax_filing.token
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = `operation_dates`
    this.send_data.notification.receiver_id = this.application.current_agent.account_id
    this.send_data.notification.receiver_name = this.application.current_agent.account_name
    this.send_data.notification.receiver_kind = `team`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
    this.send_data.notification.body = journey.status_pretty

    var url = "/operations/products/tax_filings/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  checkUploadBtn() {
    if (this.upload_files.length == 0) {
      this.disableUploadBtn()
    } else {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("bg-primary")
    }
  }

  disableUploadBtn() {
    this.uploadBtnTarget.classList.add("bg-disabled")
    this.uploadBtnTarget.classList.remove("bg-primary")
  }

  dropHandler(ev) {
    ev.preventDefault();
    this.files = this.application.files
    this.elementIndex++

    if (this.application.current_date.open) {
      if (this.uploadFeature) {
        if (ev.type == "change") {
          this.upload_files = []
          for (var i = 0; i < ev.target.files.length; i++) {

            this.found = false

            for (var j = 0; j < this.files.length; j++) {
              if (this.files[j].file_name == ev.target.files[i].name) {
                if (this.files[j].file_size == ev.target.files[i].size) {
                  this.found = true
                  this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O arquivo ${this.files[j].file_name} já está na lista de Uploads ou já foi feito o seu Upload anteriormente.`, 3000)
                  break
                }
              }
            }

            if (!this.found) {
              var element = ev.target.files[i]
              var obj = {}
              var date = new Date()
              obj.index = this.elementIndex
              obj.file_name = element.name
              obj.file_size = element.size
              obj.file_type = element.type
              obj.file_last_modified = element.lastModified
              obj.uploaded = false
              obj.message_erro = ""
              obj.created_at_time = parseInt(date.getTime() / 1000)
              if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
                obj.can_upload = true
              } else {
                if ((element.size / 1000000).toFixed(2) > 5) {
                  obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
                }

                if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
                  obj.message_erro += "Formato do arquivo não é permitido."
                }
                obj.can_upload = false
                this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
              }
              obj.file = element
              this.upload_files[this.upload_files.length] = obj
            }
          }
        }

        this.setFiles()
        this.doUploadTable()
        this.uploadFiles()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 3000)
    }
  }

  doUploadTable() {
    this.checkUploadBtn()
    this.current_files = []
    this.current_files = this.upload_files

    if (this.sort.mode == `asc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_files, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_files, this.sort.field)
    }

    this.listData(files)
  }

  listData(data) {
    this.bodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="card my-3">
                      <div class="media d-flex align-items-center py-3">
                        <span class="material-icons md-400 mx-2 md-primary">description</span>
                        <div class="media-body">
                          <h5 class="mb-0">Não há Arquivos para fazer o Upload.</h5>
                        </div>
                      </div>
                    </div>`
      this.bodyTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTarget.insertAdjacentHTML("beforeend", this.fileTablePartial(element, data.length))
      });
    }
  }

  fileTablePartial(element, length) {
    var index = element.index

    if (element.id && element.uploaded && element.has_document) {
      var uploadSpan = `Arquivo já salvo`
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-400 mx-2 md-bolder md-primary">done_all</span>
                        <span class="mc-tooltiptext-mobile mc-tooltiptext-right">Documento Salvo</span>
                      </span>`
      if (element.name) {
        var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = element.document_name.substr(element.document_name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
    } else if (element.id && element.has_document == false) {
      var uploadSpan = `Arquivo não foi salvo`
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-400 mx-2 md-bolder md-danger">clear</span>
                        <span class="mc-tooltiptext-mobile mc-tooltiptext-right">Documento Não Salvo, favor Apagar</span>
                      </span>`
      if (element.name) {
        var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = ``
    } else {
      if (element.can_upload) {
        var uploadSpan = `Arquivo pronto para Upload`
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-400 mx-2 md-bolder md-success">done</span>
                          <span class="mc-tooltiptext-mobile mc-tooltiptext-right">Documento Ok</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify pointer" data-${this.controllerName}-target="spanName-${index}" data-action="click->${this.controllerName}#editFileName">${element.file_name}</span>
                        <input autofocus data-action="keyup->${this.controllerName}#saveFileName blur->${this.controllerName}#saveFileName" class="form-control textarea p-1 f-1 d-none" type="text" required data-${this.controllerName}-target="inputName-${index}">`
        var editBtn = `<div class="p-0" data-${this.controllerName}-target="buttonDisable buttonRemove-${index}">
                        <span class="material-icons md-200 md-dark" data-${this.controllerName}-target="spanEdit-${index}" data-action="click->${this.controllerName}#editFileName">edit</span>
                        <span class="material-icons md-200 md-dark d-none" data-${this.controllerName}-target="spanSave-${index}" data-action="click->${this.controllerName}#saveFileName">save</span>
                      </div>`
        var progressSpan = `<div class="form-group my-0 text-center">
                              <h7 class="progress">
                                <span class="progress_count"></span>
                              </h7>
                              <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUploadReceipt">
                                <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadReceiptBar-${index}"></div>
                              </div>
                              <span data-${this.controllerName}-target="progressUploadReceiptCounter-${index}" class="d-block"></span>
                            </div>`
      } else {
        var uploadSpan = `O arquivo não pode ser salvo`
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-400 mx-2 md-bolder md-danger">clear</span>
                          <span class="mc-tooltiptext-mobile mc-tooltiptext-right">${element.message_erro}</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
        var editBtn = ``
      }
      var type = element.file.name.substr(element.file.name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
      var deleteBtn = `<div class="p-0" data-${this.controllerName}-target="buttonDisable buttonRemove-${index}" data-action="click->${this.controllerName}#removeFile">
                        <span class="material-icons md-200 md-dark">delete</span>
                      </div>`
    }

    var size = ((element.file_size / 1000000).toFixed(2) + "MB") // CALCULATE SIZE

    var rowHtml = `<div class="card my-3" data-index="${index}">
                    <div class="row d-flex align-items-center">
                      <div class="col-10 px-0">
                        <div class="media d-flex align-items-center py-3">
                          ${spanError}
                          <div class="media-body">
                            <h5 class="mb-0">${nameSpan}</h5>
                            <span class="text-black-secondary">${uploadSpan}</span>
                            ${progressSpan}
                          </div>
                        </div>
                      </div>
                      <div class="col-1 px-0 text-center">
                        ${editBtn}
                      </div>
                      <div class="col-1 px-0 text-center">
                        ${deleteBtn}
                      </div>
                    </div>
                  </div>`

    return rowHtml
  }

  editFileName(ev) {
    // var index = ev.currentTarget.parentElement.parentElement.id
    var index = ev.currentTarget.closest(".card").dataset.index

    this.nameTarget(`spanEdit-${index}`).classList.add("d-none")
    this.nameTarget(`spanSave-${index}`).classList.remove("d-none")

    this.nameTarget(`spanName-${index}`).classList.add("d-none")
    this.nameTarget(`inputName-${index}`).classList.remove("d-none")
    this.nameTarget(`inputName-${index}`).value = this.nameTarget(`spanName-${index}`).innerText
    this.nameTarget(`inputName-${index}`).focus()
  }

  saveFileName(ev) {
    var index = ev.currentTarget.closest(".card").dataset.index

    var span = this.nameTarget(`spanName-${index}`)
    var input = this.nameTarget(`inputName-${index}`)

    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      span.classList.remove("d-none")
      input.classList.add("d-none")
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur" || ev.type == "click") {
      const value = input.value.trim()
      const field = input.dataset.field
      span.classList.remove("d-none")
      input.classList.add("d-none")
      if (value != span.innerText && value != "") {
        this.upload_files.forEach(element => {
          if (element.index == index) {
            element.file_name = value
          }
        })
        this.bindOutput(span, field, value, index)
      } else {
        this.nameTarget(`spanSave-${index}`).classList.add("d-none")
        this.nameTarget(`spanEdit-${index}`).classList.remove("d-none")
      }
    }
  }

  bindOutput(span, field, value, index) { // REPLACE NEW FILE NAME
    this.nameTarget(`spanSave-${index}`).classList.add("d-none")
    this.nameTarget(`spanEdit-${index}`).classList.remove("d-none")

    if (field == "order") {
      span.innerText = value
    } else {
      span.innerText = value
    }
  }

  removeFile(ev) {
    if (ev.type == "click") {
      var index = ev.currentTarget.closest(".card").dataset.index
    } else {
      var index = ev
    }

    if (index > -1) {
      this.upload_files.forEach(element => {
        if (element.index == index) {
          this.upload_files.splice(this.upload_files.indexOf(element), 1)
        }
      })
    }

    this.doUploadTable()
  }

  uploadFiles() {
    if (this.canUploadFiles) {
      this.disableUploadBtn()
      this.canUploadFiles = false
      this.indexFetch = 0
      this.files_to_upload = []
      this.upload_files.forEach(element => {
        if (element.uploaded == false && element.can_upload) {
          this.files_to_upload[this.files_to_upload.length] = element
        }
      })
      
      if (this.files_to_upload.length == 1) {
        this.total_uploads_notification = `1 novo Arquivo`
      } else {
        this.total_uploads_notification = `${this.files_to_upload.length} novos Arquivos`
      }
      
      this.disableButtons()
      this.requestSave()
    }
  }

  disableButtons() { // DONT ALLOW USER TO PRESS BUTTON WHILE UPLOADING TO SERVER
    this.buttonDisableTargets.forEach(element => {
      element.children[0].style.color = "#fbfcff"
      element.children[0].classList.remove("md-dark")
      element.disabled = true
    });
  }

  requestSave() {
    this.actionMode = `new`
    this.obj_upload = this.files_to_upload[this.indexFetch]

    if (this.files_to_upload.length == 0) {
      this.canUploadFiles = true
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `Favor adicionar um novo arquivo para Upload`, 3000)
    } else if (this.indexFetch <= this.files_to_upload.length - 1) {
      if (!this.getControllerByIdentifier(`app--helpers--modal`)) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
      }

      if (this.obj_upload.can_upload) {
        this.progressCount(0, this.obj_upload.index)
        this.send_data = { current_user: {}, file: {}, notification: {}, info: {} }

        this.send_data.file.tax_filing_id = this.application.tax_filing.id
        this.send_data.file.date_id = this.application.current_date.id
        this.send_data.file.index = this.obj_upload.index
        this.send_data.file.name = this.obj_upload.file_name
        this.send_data.file.file_name = this.obj_upload.file_name
        this.send_data.file.file_size = this.obj_upload.file_size
        this.send_data.file.file_type = this.obj_upload.file_type
        this.send_data.file.file_last_modified = this.obj_upload.file_last_modified
        this.send_data.file.status = `not_revised`

        this.send_data.notification.domain_id = this.application.tax_filing.id
        this.send_data.notification.domain_type = "operation_products"
        this.send_data.notification.date_id = this.application.current_date.id
        this.send_data.notification.date_type = "operation_dates"
        this.send_data.notification.kind = `mode_${this.actionMode}`
        this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% ${this.total_uploads_notification}, para a Declaração Ano-Exercício ${this.application.current_date.year + 1}`
        this.send_data.notification.account_name = this.application.current_user.name
        this.send_data.notification.account_id = this.application.current_user.account_id

        if (this.indexFetch == this.files_to_upload.length - 1) {
          this.send_data.info.last_file = true
        } else {
          this.send_data.info.last_file = false
        }

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.application.files_permission


        var url = "/operations/products/tax_filings/files/create"
        var method = "POST"
        const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

        var controller = this
        fetch(url, init)
          .then(response => response.json())
          .then(response => {
            if (response.save) {
              var file = response.data.cln
              controller.application.files[controller.application.files.length] = file
              controller.requestUpload(file.id, this.obj_upload.file)
              // controller.upload_files.forEach((element, i) => {
              //   if (element.id == file.index) {
              //     controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
              //   }
              // })
            }
          })
          .catch(error => {
            controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
          })
      } else {
        this.canUploadFiles = true
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`success`, `Upload de todos os Arquivos finalizado`, 2000)
    }
  }


  requestUpload(file_id, file) {
    var formData = new FormData()
    formData.append('document', file)
    formData.append('file_id', file_id)

    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', this.application.files_permission)

    var url = "/operations/products/tax_filings/files/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          controller.application.files.forEach((element, i) => {
            if (element.id == file.id) {
              controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
            }
          })
          controller.tagUploadedFiles(file.index)
          if (controller.send_data.info.last_file == true) {
            controller.canUploadFiles = true
            controller.upload_files = []
            controller.doUploadTable()
            controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
          }

        }
        
        controller.indexFetch++
        controller.requestSave()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  tagUploadedFiles(index) { // SET UPLOADED FLAG TRUE AND PROGRESS BAR TO 100%
    this.progressCount(100, index) // PROGRESS BAR TO 100%
    this.stopRefreshing()
  }

  enableButtons() { // WHEN UPLOADS END ALLOW USER TO PRESS BUTTON 
    this.buttonDisableTargets.forEach(element => {
      element.children[0].classList.add("md-dark")
      element.children[0].style.color = "#fbfcff"
      element.disabled = false
    });
  }

  progressCount(value, index) { // FAKE COUNTER PROGRESS
    var controller = this
    this.nameTarget(`progressUploadReceiptBar-${index}`).style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.nameTarget(`progressUploadReceiptBar-${index}`).style.width = Math.floor(i + 1) + `%`
        controller.nameTarget(`progressUploadReceiptCounter-${index}`).innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.nameTarget(`progressUploadReceiptCounter-${index}`).innerText = value + `%`
    }
  }

  stopRefreshing() {
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
