import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "receivement", "userName", "userCPF", "userEmail", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--users--profile--main`
    this.user_token = location.pathname.split("/").pop()
    this.doProfileHtml()
  }

  goToDashboard() {
    window.open(`/dashboard`, `_self`)
  }

  doProfileHtml() {
    const html = `<div class="row my-5">
                    <div class="col-12 text-center" data-${this.controllerName}-target="icon">
                      <span class="material-icons md-lg md-primary">account_box</span>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-12 text-center" data-${this.controllerName}-target="icon">
                      <h6 class="f-1p5"><strong data-${this.controllerName}-target="userName"></strong></h6>
                      <span class="d-block text-black-secondary f-1p5" data-${this.controllerName}-target="userCPF"></span>
                      <span class="d-block text-black-secondary f-1p5" data-${this.controllerName}-target="userEmail"></span>
                    </div>
                  </div>
                  <div class="row my-5">
                    <div class="col-6 offset-3">
                      <div class="card pointer" data-action="click->${this.controllerName}#goToDashboard">
                        <div class="card-header text-center f-1p5 bg-primary">
                          Dashboard
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    }) 
  }

  setTopMargin() {
    var height = this.getControllerByIdentifier(`mobile--app--navigation--navbar`).headerTarget.style.height
    this.mainTarget.style = `90px`
  }

  getUser() {
    const data = { user: { token: this.user_token }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.user = response.data.cln
          this.userNameTarget.innerText = this.application.user.name
          this.userCPFTarget.innerText = this.application.user.cpf_pretty
          this.userEmailTarget.innerText = this.application.user.email
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["slots", "sidebar_permissions", "product_permissions", "product_entities", "users"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });
        controller.getUser()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
