import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "xxxx", "xxxx", "xxxx", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--dashboard--billings`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 7
    this.updateBilling = false
    this.sort = { mode: `asc`, field: `due_at_iugu_to_time` }
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doBillingList() {

    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Faturas em Aberto ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Faturas em Aberto</span>
                  </span>`
      this.getControllerByIdentifier(`financials--books--receivables--dashboard--main`).billingsTitleTarget.innerHTML = title
    }

    if (this.application.current_med.id) {
      this.application.current_date.receivable_calculations.forEach(calculation => {
        if (calculation.med_id == this.application.current_med.id) {
          this.calculation = calculation
        }
      })
    } else {
      this.calculation = this.application.current_date.receivable_compilation
    }

    var title = `<h6 class="mb-0 text-center pointer">Faturas - ${this.application.current_date.name}</h6>`
    this.getControllerByIdentifier(`financials--books--receivables--dashboard--main`).billingsTitleTarget.innerHTML = title

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshBillings" data-status="pending" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Faturas Pendentes</span>`
    var returnDatesLine = `<span data-action="click->${this.controllerName}#refreshReturnDates" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Liquidação das Faturas</span>`
    var readLine = `<span data-action="click->${this.controllerName}#readInvoice" class="dropdown-item py-1 pointer dropdown-submenu-item">Buscar Fatura</span>`

    refreshLine = ``
    returnDatesLine = ``

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${readLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`


    var html = `<div class="row w-100">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 card-title-table-list">Faturas em Aberto</h6>
                        <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${clearBtnHtml}
                          ${submenuHtml}
                        </div>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Vencimento</th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-50 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle text-center">Valor</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Status</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Conciliação</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Método</th>
                                  
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--dashboard--main`).billingsTableTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.doDataTable()
      // if (controller.application.billings.length > 0) {
      // } else {
      //   controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))
      // }
    })
  }

  doDataTable() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    var currentBillings = []

    this.application.billings.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        currentBillings[currentBillings.length] = element
      }
    });
    
    if (this.sort.mode == `asc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(currentBillings, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var contracts = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(currentBillings, this.sort.field)
    }

    if (contracts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(contracts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (contracts[x] !== undefined) {
            internPage[internPage.length] = contracts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: contracts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Faturas em Aberto</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.contractTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  contractTablePartial(element, length) {

    if (element.status == `paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Pago</span>`
    } else if (element.status == `partially_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Parcialmente</span>`
    } else if (element.status == `externally_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Externamente</span>`
    } else if (element.status == `partially_refunded`) {
      var status = `<span class="f-070 badge badge-secondary-success">Reembolsada Parcialmente</span>`
    } else if (element.status == `pending`) {
      var status = `<span class="f-070 badge badge-secondary-warning">Pendente</span>`
    } else if (element.status == `canceled`) {
      var status = `<span class="f-070 badge badge-secondary-danger">Cancelado</span>`
    } else if (element.status == `expired`) {
      var status = `<span class="f-070 badge badge-secondary-dark">Expirado</span>`
    } else if (element.status == `executed`) {
      var status = `<span class="f-070 badge badge-secondary-success">Liquidada</span>`
    } else {
      var status = `<span class="f-070 badge badge-secondary-danger default">Não Pago</span>`
    }

    if (element.conciliated) {
      var conciliation = `<span class="mc-tooltip"> 
                            <span class="material-icons md-250 md-success default">done</span>
                            <span class="mc-tooltiptext">Fatura Conciliada</span>
                          </span>`
    } else {
      var conciliation = `<span class="mc-tooltip"> 
                            <span class="material-icons md-250 md-danger default">clear</span>
                            <span class="mc-tooltiptext">Fatura não Conciliada</span>
                          </span>`
    }

    if (element.payment_method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `pix`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">pix</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `bank_debt`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `cash`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else {
      var method = `Sem Pagamento`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editBilling">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#concilationModal">${element.due_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#concilationModal">${element.customer_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${this.controllerNumber.currencyOptionMask(element.total)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${conciliation}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
                    
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  paidBilling(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    var billing = {}
    if (!this.updateBilling) {

      this.current_billings.forEach(element => {
        if (element.id == id) {
          billing = element
        }
      })

      var r = confirm(`Você confirma o recebimento da fatura no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(billing.total_amount * 100))}?`)
      if (r) {
        this.updateBilling = true
        this.send_data = { billing: {}, current_user: {} }

        this.send_data.billing.id = billing.id
        this.send_data.billing.paid = true

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.requestSave()
      }
    }
  }

  concilationModal(ev) {
    // var element = ev.currentTarget.dataset.element
    var invoiceIdIugu = ev.currentTarget.closest(`.itemRow`).dataset.idIugu

    var html = `<div class="modal fade" data-controller="financials--books--receivables--conciliations--modal--main" data-financials--books--receivables--conciliations--modal--main-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--receivables--conciliations--modal--main-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).iugu_billings = this.application.billings
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).invoice_id_iugu = invoiceIdIugu
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).open()
    })
  }

  refreshBillings(ev) {
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    var createdAt = `${this.application.current_date.year}-${this.application.current_date.month}-01`
    var status = ev.currentTarget.dataset.status

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Carregando Faturas em Aberto` })
    this.refreshLoadValue()

    const data = { billing: { mode: `pending_date`, date: createdAt, status: status, limit: 30 }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_billings` } }
    const url = "/financials/integrations/bankings/iugu/billings/refresh_pending_by_range"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentBillingIds = controller.mapElements(controller.application.billings, `id`)
        var refreshBillingIds = controller.mapElements(response.data.cln, `id`)

        if (response.process) {
          response.data.cln.forEach(billing => {
            if (currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings.forEach((element, i) => {
                if (element.id == billing.id) {
                  controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
                }
              })
            } else if (!currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings[controller.application.billings.length] = billing
            }
          })
        }

        controller.stopRefreshing()
        controller.doDataTable()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshReturnDates(ev) {
    var createdAt = `${this.application.current_date.year}-${this.application.current_date.month}-01`

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Atualizando a Liquidação das Faturas` })
    this.refreshLoadValue()

    const data = { billing: { returned_date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_billings` } }
    const url = "/financials/integrations/bankings/iugu/billings/refresh_billing_dates"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        controller.stopRefreshing()
        controller.doDataTable()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  readInvoice() {
    var controllerModal = `financials--books--receivables--conciliations--modal--invoice`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`${controllerModal}`).open()
    })
  }

  doBillingPreloader() {

    var title = `<h6 class="mb-0 text-center pointer">${this.loader}</h6>`
    this.getControllerByIdentifier(`financials--books--receivables--dashboard--main`).billingsTitleTarget.innerHTML = title

    var html = `<div class="row my-2 w-100">
                  <div class="col-12">
                    <div class="row my-2">
                      ${this.cardGridPreloader()}
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--receivables--dashboard--main`).billingsTableTarget.innerHTML = html
  }

  cardGridPreloader() {
    var html = `<div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  refreshLoadValue() {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Carregando Faturas em Aberto`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()

        var response = { type: `danger`, message: `Tivemos um problema para carrega as Faturas` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 300);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

}
