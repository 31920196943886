import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "image", "text", "dates", "dateInput", "steps", "firstStep", "secondStep", "thirdStep",
                    "cardTitle", "cardBody", "buttonHour", "schedule", "cardDate", "timetable", "cardBodyRow"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = "commercial--marketing--landings--publics--schedule"
    this.lead_token = location.search.split("?")[1].split("&")[0].split("=")[1]
    this.conversion_token = location.search.split("?")[1].split("&")[1].split("=")[1]

    // if (window.location.search.split("?")[1]) {
    //   this.searchArray = window.location.search.split("?")[1].split("&")
    // }
    this.doPreloadHtml()
  }

  doImageHtml() {
    var html = `<img
                    class="mt-5 imagem-head w-20"
                    src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png"
                    alt="Logo da Medcapital"
                    style="width:15rem;"
                  >`

    this.imageTarget.innerHTML = html
  }

  doTextHtml() {
    var html = `<h2 class="f-dark"><b>Obrigado ${this.getControllerByIdentifier("app--visits--users").getFirstname(this.lead)}, agende aqui sua consultoria gratuita!</b></h2>`
    this.textTarget.innerHTML = html
  }

  doStepsHtml() {
    
    var html = `<div class="row">
                  <div class="col-12 my-3 ml-5" data-${this.controllerName}-target="firstStep">
                    <span>
                      <h3 class="mb-0"><span class="chip-icon chip-icon-meeting mb-0">1</span><strong class="f-1p25">Selecione uma data</strong></h3>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 my-3 ml-5" data-${this.controllerName}-target="secondStep">
                    <span>
                      <h3 class="mb-0"><span class="chip-icon chip-icon-meeting mb-0">2</span><strong class="f-1p25">Selecione um horário</strong></h3>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 my-3 ml-5" data-${this.controllerName}-target="thirdStep">
                    <span>
                      <h3 class="mb-0"><span class="chip-icon chip-icon-meeting mb-0">3</span><strong class="f-1p25">Confirme seu agendamento</strong></h3>
                    </span>
                  </div>
                </div>`

    this.stepsTarget.innerHTML = html
  }

  doDateHtml() {
    var html = `<div class="card" data-${this.controllerName}-target="cardDate">
                  <div class="card-header py-2 d-flex align-items-center" data-${this.controllerName}-target="cardTitle">
                    <h4 class="f-dark mb-0 text-center"><strong>Escolha a melhor data para sua consultoria</strong></h4>
                  </div>
                  <div class="card-body px-0 o-scroll" data-${this.controllerName}-target="cardBody">
                    <div class="row my-5 py-5">
                      <div class="col-12 text-center">
                        <button data-${this.controllerName}-target="dateInput" class="btn btn-success btn-schedule-date f-1p25 py-3">Selecione uma data</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mx-auto my-2">Não encontrou um horário ideal? <span class="ml-1 pointer f-success" data-action="click->${this.controllerName}#sendWhatsAppApi">Clique aqui</span> e fale diretamente com um consultor.</div>`
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.datesTarget.innerHTML = html)
    }).then(() => {
      controller.cardDateTarget.style.height = ($(window).height() * 0.4) + "px"
      var date = new Date()
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.dateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      controller.pickOnlyFuture($(controller.dateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      // controller.pickOnlyFuture($(controller.dateInputTarget))
    })
  }

  changeDate() {
    this.send_slot_data = { slot: {} }
    this.send_schedule_data = { schedule: {} }

    var selectedDate = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    selectedDate = new Date(selectedDate)
    
    this.selectedDate = { date: this.dateInputTarget.value, day: this.getControllerByIdentifier("app--helpers--date").getWeekDay(selectedDate.getDay()) }
    this.send_slot_data.slot.date = selectedDate

    if (selectedDate != `Invalid Date`) {
      var url = "/users/works/schedules/slots/list_public"
      var method = "POST"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_slot_data) }
      var controller = this
      fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.slots = response.data.cln
        this.doHourHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })    
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Selecione uma data", 4000)
    }
  }

  doHourHtml() {
    if (this.dateInputTarget.value) {
      this.firstStepTarget.classList.add("f-light-dark")
      this.secondStepTarget.classList.remove("f-light-dark")
      this.thirdStepTarget.classList.remove("f-light-dark")

      this.cardTitleTarget.innerHTML = `<h6 class="f-dark mb-0 text-center"><strong>Você selecionou ${this.selectedDate.day}, dia ${this.selectedDate.date}</strong></h6>
                                        <div class="card-actions py-0 ml-auto mc-tooltip">
                                          <button data-${this.controllerName}-target="dateInput" class="btn btn-primary btn-change-date f-065">Trocar data</button>
                                        </div>`

      this.cardBodyTarget.innerHTML = `<div class="row mb-3" data-${this.controllerName}-target="cardBodyRow">
                                          <div class="col-12">
                                            <h4 class="f-dark mb-0 text-center"><strong>Selecione um horário</strong></h4>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-12 text-center" data-${this.controllerName}-target="timetable"></div>
                                        </div>`

      this.pickOnlyFuture($(this.dateInputTarget))
      this.doTimeTable()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Selecione uma data", 4000)
    }
  }

  doTimeTable() {
    var bodyHtml = ``
    if (this.application.slots.length == 0) {
      bodyHtml = `<span class="fa-stack fa-1x">
                    <i class="fas fa-list fa-lg"></i>
                  </span>
                  <span class="ml-4"><strong>Não há horários disponíveis nessa data</strong></span>`

    } else {
      this.application.slots.forEach(element => {
        bodyHtml += `<button data-id="${element.id}" data-action="click->${this.controllerName}#selectHour" data-${this.controllerName}-target="buttonHour-${element.id}" class="btn btn-success btn-choose-hour f-1p25 text-center m-2">${element.timetable}</button>`
      });
    }

    this.timetableTarget.innerHTML = bodyHtml
  }

  selectHour(ev) {

    this.application.slots.forEach(element => {
      if (element.id == ev.currentTarget.dataset.id) {
        this.selectedTime = element
      }
    })

    this.secondStepTarget.classList.add("f-light-dark")

    this.cardTitleTarget.innerHTML = `<h6 class="f-dark mb-0 text-center"><strong>Confirme seu agendamento</strong></h6>
                                      <div class="card-actions py-0 ml-auto mc-tooltip">
                                        <button data-${this.controllerName}-target="dateInput" class="btn btn-primary btn-change-date f-065">Trocar data</button>
                                      </div>`

    this.cardBodyTarget.innerHTML = `<div class="row mb-3" data-${this.controllerName}-target="cardBodyRow">
                                          <div class="col-12">
                                            <h4 class="f-dark mb-0 text-center"><strong>Você escolheu ${this.selectedDate.day}, dia ${this.selectedDate.date}, às ${this.selectedTime.timetable}</strong></h4>
                                          </div>
                                        </div>
                                        <div class="row my-5">
                                          <div class="col-12 text-center my-4">
                                            <button data-action="click->${this.controllerName}#saveSchedule" class="btn btn-success btn-schedule-confirm py-3 f-1p25">Confirmar</button>
                                          </div>
                                        </div>`

    this.pickOnlyFuture($(this.dateInputTarget))
  }

  saveSchedule() {
    this.send_schedule_data.schedule.account_id = this.selectedTime.account_id
    this.send_schedule_data.schedule.slot_id = this.selectedTime.id
    this.send_schedule_data.schedule.schedule_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.selectedDate.date)
    this.send_schedule_data.schedule.timetable = this.selectedTime.timetable
    this.send_schedule_data.schedule.day = this.selectedTime.day
    this.send_schedule_data.schedule.kind = `sales`
    this.send_schedule_data.schedule.status = `booked`
    this.send_schedule_data.schedule.conversion_id = this.conversion.id
    this.send_schedule_data.schedule.guest_id = this.lead.id
    this.send_schedule_data.schedule.guest_name = this.lead.name
    this.send_schedule_data.schedule.guest_type = `lead_entities`
    
    this.thirdStepTarget.classList.add("f-light-dark")
    this.cardTitleTarget.remove()
    this.cardBodyTarget.innerHTML = `<div class="row mb-3" data-${this.controllerName}-target="cardBodyRow">
                                        <div class="col-12">
                                          <h4 class="f-dark mb-0 text-center"><strong>Obrigado. Sua consultoria foi agendada com sucesso. Entraremos em contato.</strong></h4>
                                        </div>
                                      </div>
                                      <div class="row my-5">
                                        <div class="col-12 text-center my-4">
                                          <span class="material-icons md-success md-lg">thumb_up</span>
                                        </div>
                                      </div>`


    if (this.lead.id) {
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/users/works/schedules/entities/save_public"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_schedule_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          window.open(window.location.origin, `_self`)
        } else {
          controller.doMainHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        // window.open(window.location.origin, `_self`)
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getConversion() {
    const data = { conversion: { id: this.conversion_token } }
    const url = "/commercial/marketing/landings/conversions/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.conversion = response.data.cln
        this.doMainHtml()
      })
      .catch(error => {
        window.location.reload()
        // controller.getControllerByIdentifier("app--helpers--console").console(error)
        // controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getLead() {
    const data = { lead: { id: this.lead_token } }
    const url = "/commercial/marketing/landings/leads/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.lead = response.data.cln
        } else {
          controller.lead = { name: `` }
        }
        this.getConversion()
      })
      .catch(error => {
        window.location.reload()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  // construir grid da página
  doMainHtml() {
    var html = `<div class="">
                  <div class="row d-flex justify-content-center" data-${this.controllerName}-target="image"></div>
                  <div class="row my-5 px-5">
                    <div class="col-12 text-center" data-${this.controllerName}-target="text"></div>
                  </div>
                  <div class="row d-flex align-items-center my-5 px-5" data-${this.controllerName}-target="schedule">
                    <div class="col-6" data-${this.controllerName}-target="steps"></div>
                    <div class="col-6" data-${this.controllerName}-target="dates"></div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.scheduleTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.doImageHtml()
      controller.doTextHtml()
      controller.doStepsHtml()
      controller.doDateHtml()
    })
  }

  doPreloadHtml() {
    var html = `<div class="">
                  <div class="row d-flex justify-content-center my-5">
                    ${this.loader}
                  </div>
                  <div class="row d-flex justify-content-center my-5">
                    ${this.loader}
                  </div>
                  <div class="row d-flex align-items-center my-5" data-${this.controllerName}-target="schedule">
                    <div class="col-4 offset-1">
                      <div class="row my-5"><div class="col-12">${this.loader}${this.loader}</div></div>
                      <div class="row my-5"><div class="col-12">${this.loader}${this.loader}</div></div>
                      <div class="row my-5"><div class="col-12">${this.loader}${this.loader}</div></div>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-4 offset-1">
                      ${this.cardLoader}
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.scheduleTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getLead()
    })
  }

  // funcao para gerar popup calendario na pagina
  pickOnlyFuture(element, kind, year, month) {

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth() + 1

    if (kind == "monthly") {
      if (currentMonth > month && currentYear == year) {
        var maxDate = new Date(year, month, 0)
      } else {
        var maxDate = false
      }
      var minDate = 1
    } else if (kind == "yearly") {
      if (currentYear > year) {
        var maxDate = new Date(year, 12, 0)
      } else {
        var maxDate = false
      }
      // var minDate = new Date(year, 11, currentDate.getDate())
      var minDate = new Date()
    } else {
      var maxDate = false
      // var minDate = new Date(year, 11, currentDate.getDate())
      var minDate = new Date()
    }

    var controller = this

    element.pickdate({
      today: 'Hoje',
      ok: 'Selecionar',
      cancel: 'Fechar',
      closeOnCancel: true,
      closeOnSelect: true,
      container: 'body',
      containerHidden: 'body',
      firstDay: 0,
      format: 'dd/mmm/yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenPrefix: 'pickdate-input',
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      labelMonthSelect: 'Escolha um mês do menu',
      labelYearSelect: 'Escolha um ano do menu',
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      showMonthsFull: true,
      showMonthsShort: true,
      showWeekdaysFull: true,
      showWeekdaysShort: true,
      selectMonths: true,
      selectYears: true,
      max: maxDate,
      min: minDate,
      disable: [1,
        7,
        new Date(2022, 5, 3),
        new Date(2022, 7, 15),
        new Date(2022, 8, 7),
        new Date(2022, 9, 12),
        new Date(2022, 10, 2),
        new Date(2022, 10, 15),
        new Date(2022, 11, 8),
        new Date(2022, 11, 24),
        new Date(2022, 11, 25),
        new Date(2022, 11, 31),
        new Date(2023, 0, 1),],
      onClose: function (e) {
        // funcao para guardar a data selecionada pelo cliente
        controller.changeDate();
      },
    });

    element.on('mousedown', function (e) {
      e.preventDefault();
      $(this).dblclick()
    });
  }

  pickOnlyFutureOld2(element) {

    var controller = this

    element.pickdate({
      today: 'Hoje',
      ok: 'Selecionar',
      cancel: 'Fechar',
      closeOnCancel: true,
      closeOnSelect: true,
      container: 'body',
      containerHidden: 'body',
      firstDay: 0,
      format: 'dd/mmm/yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenPrefix: 'pickdate-input',
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      labelMonthSelect: 'Escolha um mês do menu',
      labelYearSelect: 'Escolha um ano do menu',
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      showMonthsFull: true,
      showMonthsShort: true,
      showWeekdaysFull: true,
      showWeekdaysShort: true,
      selectMonths: true,
      selectYears: true,
      min: true,
      max: new Date(2022, 11, 31),
      disable: [1,
        7,
        new Date(2022, 5, 3),
        new Date(2022, 7, 15),
        new Date(2022, 8, 7),
        new Date(2022, 9, 12),
        new Date(2022, 10, 2),
        new Date(2022, 10, 15),
        new Date(2022, 11, 8),
        new Date(2022, 11, 24),
        new Date(2022, 11, 25),
        new Date(2022, 11, 31),
        new Date(2023, 0, 1),],
      onClose: function (e) {
        // funcao para guardar a data selecionada pelo cliente
        controller.changeDate();
      },
    });

    element.on('mousedown', function (e) {
      e.preventDefault();
      $(this).dblclick()
    });
  }

  sendMessage() {
    var url = `https://api.whatsapp.com/send?phone=5531996421912&amp;text=Olá,%20quero%20agendar%20uma%20consultoria`
    window.open(url, '_blank')
  }

  sendWhatsAppApi(ev) {

    
    console.log(this.lead)

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = currentPurchase.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `marketing_landing_leads`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Contratação ${currentPurchase.product_service_pretty} ${currentPurchase.product_accrual_name_pretty} ${currentPurchase.product_kind_plan_pretty}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = currentPurchase.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `marketing_landing_leads`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = currentParty.party_kind_obj.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `marketing_landing_leads`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = currentParty.party_kind_obj.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = this.lead.ddd
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = this.lead.number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = `${this.lead.ddd}${this.lead.number}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `sales`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = ``
    // this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}