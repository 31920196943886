import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "view", "days", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--statements--daily--dashboard`
  }

  doBalancesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 ml-auto pt-3 pb-1" data-${this.controllerName}-target="days" data-controller="financials--books--statements--daily--days" style="overflow: auto;"></div>
                  <div class="col-12 pr-1" data-${this.controllerName}-target="content" data-controller="financials--books--statements--daily--index"></div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.application.daily_balances = []
      controller.getControllerByIdentifier(`financials--books--statements--daily--days`).doMenuPreloader()
      controller.getControllerByIdentifier("financials--books--statements--daily--index").doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
