import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "nameForm",
    "nameInput", "pathInput", "accountInput", "pixInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `commercial--marketing--sites--index--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--sites--index--list").clearList()
  }

  saveSite() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data = { current_user: {}, site: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.site.id = this.current_site.id
    }
    
    this.send_data.site.name = this.nameInputTarget.value
    this.send_data.site.path = this.pathInputTarget.value
    this.send_data.site.kind = this.kindInputTarget.dataset.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/sites/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/sites/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/commercial/marketing/sites/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var site = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.sites[controller.application.sites.length] = site
          } else if (this.actionMode == `edit`) {
            controller.application.sites.forEach((element, i) => {
              if (element.id == site.id) {
                controller.application.sites.splice(controller.application.sites.indexOf(element), 1, site)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSite" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="pathForm">URL</label>
                            <input aria-describedby="pathFormHelp" class="form-control form-valid-control" id="pathForm" data-${this.controllerName}-target="pathInput" placeholder="URL" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="principal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Principal</li>
                                  <li data-kind="school" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">MedSchool</li>
                                  <li data-kind="jobs" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vagas</li>
                                  <li data-kind="blog" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Blog</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--sites--index--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Site`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Site`

        controller.nameInputTarget.value = controller.current_site.name
        controller.pathInputTarget.value = controller.current_site.path

        controller.kindDropdownTarget.value = controller.current_site.kind
        controller.kindInputTarget.innerText = controller.current_site.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_site.kind

        controller.nameInputTarget.disabled = false
        controller.pathInputTarget.disabled = false

      }

      if (controller.application.permissions[controller.permission].can_create) {
        controller.refreshSaveBtn()
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.pathInputTarget.value == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
