
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "main", "gain", "lost", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--reports--persona`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.reports = {}
  }

  doReportHtml() {    
    var html = `<div class="row">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center">Ganhos por Personas</h6>
                    <hr class="m-1">
                  </div>
                  <div class="col-12 px-0">
                    <div class="row" data-${this.controllerName}-target="gain"></div>
                  </div>
                  <div class="col-12 px-1 mt-1">
                    <h6 class="mb-0 text-center">Perdas por Personas</h6>
                    <hr class="m-1">
                  </div>
                  <div class="col-12 px-0">
                    <div class="row" data-${this.controllerName}-target="lost"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.setRequest()
    })
  }

  setReports() {
    var gainHtml = ``
    var lostHtml = ``
    this.reports.report_by_gain.forEach(report => {
      gainHtml += `<div class="col-2 px-1 my-2">
                              <div class="card border-top-primary">
                                <div class="card-header p-1 text-center f-065">
                                  <span>${report.header_name}</span>
                                </div>
                                <div class="card-body text-center py-2 mc-tooltip pointer">
                                  <span class="f-065">Total Recorrente: ${report.total_recurring}</span><br>
                                  <span class="f-065 f-bold">Valor Recorrente: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount_recurring)}</span><br>
                                  <span class="f-065">Prazo Médio: ${report.term_recurring}</span><br>
                                  <hr class="m-1">
                                  <span class="f-065">Total Avulso: ${report.total_separate}</span><br>
                                  <span class="f-065 f-bold">Valor Avulso: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount_separate)}</span><br>
                                  <span class="f-065">Prazo Médio: ${report.term_separate}</span><br>
                                </div>
                              </div>
                            </div>`
    })
    this.reports.report_by_lost.forEach(report => {
      lostHtml += `<div class="col-2 px-1 my-2">
                              <div class="card border-top-primary">
                                <div class="card-header p-1 text-center f-065">
                                  <span>${report.header_name}</span>
                                </div>
                                <div class="card-body text-center py-2 mc-tooltip pointer">
                                  <span class="f-065">Total Recorrente: ${report.total_recurring}</span><br>
                                  <span class="f-065 f-bold">Valor Recorrente: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount_recurring)}</span><br>
                                  <span class="f-065">Prazo Médio: ${report.term_recurring}</span><br>
                                  <hr class="m-1">
                                  <span class="f-065">Total Avulso: ${report.total_separate}</span><br>
                                  <span class="f-065 f-bold">Valor Avulso: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount_separate)}</span><br>
                                  <span class="f-065">Prazo Médio: ${report.term_separate}</span><br>
                                </div>
                              </div>
                            </div>`
    })
    this.gainTarget.innerHTML = gainHtml
    this.lostTarget.innerHTML = lostHtml
  }
  
  setRequest() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var date = new Date(this.year, this.month - 1, 1)
    const data = { report: { date_string: date, account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/reports/persona_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        response.data.cln.forEach(report => {
          controller.reports[report.name] = report.data
        })

        controller.setReports()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
