import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "infoView", "infoCard", "partnerView", "partnerCard",
                    "sideView", "regimeCard", "actionView", "actionCard", "checkCanInvoice", "checkListPartner",
                    "checkListDescriptionPartner", "checkListTaker", "checkListDescriptionTaker", "checkListRegime",
                    "checkListDescriptionRegime", "checkListActivity", "checkListDescriptionActivity", "checkListBank",
                    "checkListDescriptionBank", "invoiceBtn", "checkListCertificate", "checkListDescriptionCertificate"]

  connect() {
    this.spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--clinics--regularizations--show--dashboard`
    this.canInvoice = false
  }

  doClinicDashboardPreloader() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="actionView">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                  <div class="col-4" data-${this.controllerName}-target="infoView">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="infoCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" data-${this.controllerName}-target="partnerView">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="partnerCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>

                  <div class="col-2" data-${this.controllerName}-target="sideView">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.infoCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.partnerCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doClinicDashboard() {
    this.doClinicDashboardPreloader()
    this.doActionCard()
    this.doInfoCard()
    this.doPartnerCard()
    this.doSideCard()
  }

  goToPartners() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").doPartnersDashboard()
  }

  goToTakers() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard").doTakersDashboard()
  }

  goToCertificates() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").doCertificatesDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier("operations--products--clinics--financials--regimes--dashboard").doRegimesDashboard()
  }

  goToHelpers() {
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard").doHelpersDashboard()
  }

  doActionCard() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--check-invoice`).permission = `medclinic_regularizations`
    this.getControllerByIdentifier("operations--products--clinics--regularizations--show--check-invoice").doCheckCard()
  }

  doPartnerCard() {
    var actualPartnersHtml = ``
    var oldPartnersHtml = ``

    var actualPartners = []
    var oldPartners = []

    this.application.partners.forEach(partner => {
      if (partner.status) {
        actualPartners[actualPartners.length] = partner
      } else {
        oldPartners[oldPartners.length] = partner
      }
    })

    if (actualPartners.length > 0) {
      actualPartners.forEach(partner => {
        actualPartnersHtml += `<tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <strong>${partner.name}</strong>
                          </th>
                        </tr>`
      })
    } else {
      actualPartnersHtml += `<tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <strong>Não há sócios cadastrados</strong>
                          </th>
                        </tr>`
    }

    if (oldPartners.length > 0) {
      oldPartners.forEach(partner => {
        oldPartnersHtml += `<tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <strong>${partner.name}</strong>
                          </th>
                        </tr>`
      })
    } else {
      oldPartnersHtml += `<tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <strong>Não há ex-sócios da PJ</strong>
                          </th>
                        </tr>`
    }

    var html = `<div class="card" data-${this.controllerName}-target="partnerCard">
                  <div class="card-header py-0">
                    <div class="row my-2"><div class="col-12 pointer f-bold" data-action="click->${this.controllerName}#goToPartners">Quadro Societário</div></div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;">
                    <div class="col-12 px-2">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                        </tr>
                        <tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <p class="mb-0 px-2">Médicos Sócios Atuais</p>
                            <hr class="my-1">
                          </th>
                        </tr>
                        ${actualPartnersHtml}
                        <tr>
                          ${this.spaceRow}
                        </tr>

                        <tr>
                          <th colspan="8" class="f-065 align-middle px-0">
                            <p class="mb-0 px-2">Médicos Ex-Sócios</p>
                            <hr class="my-1">
                          </th>
                        </tr>
                        ${oldPartnersHtml}
                        <tr>
                          ${this.spaceRow}
                        </tr>


                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>`

    this.partnerViewTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.partnerViewTarget.innerHTML = html)
    }).then(() => {
      controller.partnerCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doInfoCard() {
    var address = this.application.clinic.address_list[0]

    var html = `<div class="card" data-${this.controllerName}-target="infoCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col f-bold">Informações da PJ Médica</div></div>  
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <div class="col-12 px-2">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Razão Social</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_trade_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_trade_name}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Nome Fantasia</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>CNPJ</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Inscrição Municipal</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_municipal_number}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_municipal_number}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Tipo</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                PJ ${this.application.clinic.kind_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Abertura</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.application.clinic.company_opened_at_pretty}
                              </td>
                            </tr>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Início da Responsabilidade</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.application.clinic.started_at_pick_date}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="f-065 px-0 align-middle pb-0">
                                <strong>Endereço</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 pb-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                ${address.street}, ${address.number}, ${address.complement}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="px-0 align-middle"></th>
                              <td colspan="5" class="f-065 align-middle p-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                ${address.district},
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="px-0 align-middle"></th>
                              <td colspan="5" class="f-065 align-middle p-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                ${address.city}/${address.state}
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>`

    this.infoViewTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.infoViewTarget.innerHTML = html)
    }).then(() => {
      controller.infoCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doSideCard() {

    var expirationDate = ``
    var notifiyAlert = ``

    if (this.application.certificates[0]) {
      expirationDate = this.application.certificates[0].expiration_date_pretty
      if (this.application.certificates[0].notify_alert) {
        notifiyAlert = `<span class="mc-tooltip badge badge-secondary-danger">Alerta de Vencimento</span>`
      } else {
        notifiyAlert = ``
      }
    } else {
      expirationDate = `Não possui Certificado Digital`
      notifiyAlert = ``
    }

    if (this.application.helpers.length == 0) {
      var helperText = `Nenhum Assistente Registrado`
    } else if (this.application.helpers.length == 1) {
      var helperText = `${this.application.helpers.length} Assistente Registrado`
    } else {
      var helperText = `${this.application.helpers.length} Assistentes Registrados`
    }


    var html = `<div class="row mb-3">
                  <div class="col-12 px-0">
                    <div class="card w-100">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col pointer f-bold px-0" data-action="click->${this.controllerName}#goToTakers">Tomadores</div></div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row my-2">
                          <div class="col-12 px-0 text-center">
                            ${this.getControllerByIdentifier(`app--helpers--numbers`).numberMask(this.application.takers.length)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 px-0">
                    <div class="card w-100">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col pointer f-bold px-0" data-action="click->${this.controllerName}#goToCertificates">Certificado Digital</div></div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row my-2">
                          <div class="col-12 px-0 text-center mb-2">
                            <span class="f-065">Vencimento</span><br>
                            ${expirationDate}
                          </div>
                          <div class="col-12 px-0 text-center">
                            ${notifiyAlert}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 px-0">
                    <div class="card w-100">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col pointer f-bold px-0" data-action="click->${this.controllerName}#goToHelpers">Assistentes</div></div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row my-2">
                          <div class="col-12 px-0 text-center mb-2">
                            ${helperText}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    


    this.sideViewTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}