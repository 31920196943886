import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "title", "submenu", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--landings--conversions--dashboard`
  }

  doConversionGrid() {
    var html = `<div class="row">
                  <div class="col-8 pr-2" data-${this.controllerName}-target="list" data-controller="commercial--marketing--landings--conversions--list">conversion</div>
                  <div class="col-4 pl-2" data-${this.controllerName}-target="view" data-controller="commercial--marketing--landings--conversions--show commercial--marketing--landings--conversions--save">form</div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--landings--show--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--marketing--landings--conversions--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`commercial--marketing--landings--conversions--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
