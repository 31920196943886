import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "ibgeInput",
                    "cityInput", "cityFilter", "stateInput", "stateFilter",
                    "municipalCodeInput", "federalCodeInput", "kindInput", "kindList",
                    "numberInput", "codeInput", "descriptionInput", "saveBtn"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--cnaes--save-city-code`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").clearList()
  }

  saveBank() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, city_code: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.city_code.id = this.current_city_code.id
    }
    
    this.send_data.city_code.description = this.descriptionInputTarget.value.trim()
    this.send_data.city_code.cnae_id = this.current_cnae.id
    this.send_data.city_code.city = this.cityInputTarget.value.trim()
    this.send_data.city_code.city_code = this.ibgeInputTarget.value.trim()
    this.send_data.city_code.state = this.stateInputTarget.value.trim()
    this.send_data.city_code.municipal_code = this.getControllerByIdentifier(`app--helpers--numbers`).fromCodeToNumber(this.municipalCodeInputTarget.value.trim())
    this.send_data.city_code.federal_code = this.getControllerByIdentifier(`app--helpers--numbers`).fromCodeToNumber(this.federalCodeInputTarget.value.trim())

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/app/datas/cnaes/city_codes/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/app/datas/cnaes/city_codes/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/app/datas/cnaes/city_codes/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var cnae = response.data.cln

          controller.application.cnaes.forEach((element, i) => {
            if (element.id == cnae.id) {
              controller.application.cnaes.splice(controller.application.cnaes.indexOf(element), 1, cnae)
            }
          })
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--index`).doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveBank" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row mt-3">
                      <div class="col-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="stateForm">UF</label>
                            <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterState" placeholder="UF" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                              <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                              <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                              <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                              <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                              <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                              <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                              <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                              <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                              <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                              <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                              <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                              <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                              <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                              <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                              <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                              <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                              <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                              <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                              <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                              <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                              <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                              <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                              <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                              <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                              <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                              <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                              <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="floating-label floating-label-sm">
                          <label for="cityForm">Cidade</label>
                          <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterCity" placeholder="Cidade" type="text" required>
                          <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ibgeForm">IBGE</label>
                            <input aria-describedby="ibgeFormHelp" class="form-control" id="ibgeForm" data-${this.controllerName}-target="ibgeInput" placeholder="IBGE" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="codeForm">Código Municipal</label>
                            <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="municipalCodeInput" placeholder="Código Municipal" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="codeForm">Código Federal</label>
                            <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="federalCodeInput" placeholder="Código Federal" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Código Municipal do CNAE ${controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_cnae.full_code)}`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Código Municipal do CNAE ${controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_cnae.full_code)}`

        controller.descriptionInputTarget.value = controller.current_city_code.description
        controller.current_cnae.id = controller.current_city_code.cnae_id
        controller.cityInputTarget.value = controller.current_city_code.city
        controller.ibgeInputTarget.value = controller.current_city_code.city_code
        controller.stateInputTarget.value = controller.current_city_code.state
        // controller.municipalCodeInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).codeMask(controller.current_city_code.municipal_code)
        controller.municipalCodeInputTarget.value = controller.current_city_code.municipal_code
        controller.federalCodeInputTarget.value = controller.current_city_code.federal_code

        controller.municipalCodeInputTarget.disabled = false
        controller.federalCodeInputTarget.disabled = false
        controller.descriptionInputTarget.disabled = false

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterState(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.getCitiesByState(this.stateInputTarget.value)
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/accounts/addresses/cities_ibge_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            listHtml += `<li data-city="${element[0]}" data-filter="${element[1]}" data-text="${element[0]}" data-ibge="${element[1]}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element[0]}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)

    var ibge = ev.currentTarget.dataset.ibge
    this.ibgeInputTarget.value = ibge
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.saveBtnTarget.classList.remove(`d-none`)
    this.validateCityCode(ibge)
  }

  enterCity(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      var ibge = ev.currentTarget.dataset.filter
      this.ibgeInputTarget.value = ibge
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      this.saveBtnTarget.classList.remove(`d-none`)
      this.validateCityCode(ibge)
    }
  }

  codeMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCode(ev)
  }

  validateCityCode(cityCode) {
    var city = this.cityInputTarget.value.trim()
    var state = this.stateInputTarget.value.trim()

    this.current_cnae.city_codes.forEach(element => {
      if (element.city_code == cityCode) {
        alert(`Já existe um Código de ${city}/${state} cadastrado para este CNAE!`)
        this.saveBtnTarget.classList.add(`d-none`)
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.stateInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.cityInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.ibgeInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.municipalCodeInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.federalCodeInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }
        
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
