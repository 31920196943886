import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addBillingBtn", "pendencyCount", "sideBarReport", "XXXBtn",
                    "clearListBtn", "optionBtn", "billingCount", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--liquidations--billings`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.tableCols = 13
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `client_name` }
  }

  checkForEditBilling() {
    if (this.application.current_date.open) {
      this.kind = `edit_contract`
      this.doDataTable()

      this.clearListBtnTarget.classList.remove(`d-none`)
      this.optionBtnTarget.classList.add(`d-none`)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doBillingList() {

    var returnDatesLine = `<span data-action="click->${this.controllerName}#refreshReturnDatesEv" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Liquidação das Faturas</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${returnDatesLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto help">
                          <span data-${this.controllerName}-target="billingCount">Todas as Faturas</span>
                        </h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">
                                    Cliente
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="client_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="client_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Med</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Dt Vencimento</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Dt Pagamento</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Dt Estimada</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Dt Liquidação</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr Total</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr Liquidado</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Tx Fatura</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Tx Adiantamento</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center" colspan="2">Status</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">Método</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))

      controller.doDataTable()
    })
  }

  doDataTable() {
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--indicators`).doIndicatorsCalculation()
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    var current_billings = []

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (this.current_day < 10) {
      var currentDay = `0${this.current_day}`
    } else {
      var currentDay = `${this.current_day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    this.application.billing_dates.forEach(element => {
      if (element.executed_token == currentToken) {

        if (this.application.current_med.id) {
          if (element.med_id == this.application.current_med.id) {
            current_billings[current_billings.length] = element
          }
        } else {
          current_billings[current_billings.length] = element
        }

        // current_billings[current_billings.length] = element
      }
    })

    console.log(current_billings)

    var totalBillings = 0
    var liquidatedBillings = 0
    current_billings.forEach(element => {
      totalBillings += 1
      if (element.executed) {
        liquidatedBillings += 1
      }
    })

    this.billingCountTarget.innerText = `Todas as Faturas ${totalBillings}/${liquidatedBillings} Fatura(s) Liquidadas(s)`

    if (this.sort.mode == `asc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_billings, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_billings, this.sort.field)
    }

    if (billings.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(billings.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (billings[x] !== undefined) {
            internPage[internPage.length] = billings[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: billings.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${this.message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.billingTablePartial(element, data.length))
      })
    }

  }

  billingTablePartial(element, length) {

    if (element.conciliated) {
      var conciliated = `<span class="f-070 badge badge-secondary-success">Conciliação Ok</span>`
    } else {
      var conciliated = `<span class="f-070 badge badge-secondary-danger">Não Conciliada</span>`
    }

    if (element.executed) {
      var executed = `<span class="f-070 badge badge-secondary-success">Liquidação Ok</span>`
    } else {
      var executed = `<span class="f-070 badge badge-secondary-danger" data-action="click->${this.controllerName}#returnBillingEv">Não Liquidada</span>`
    }

    if (element.method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `pix`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">pix</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `bank_debt`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `cash`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else {
      var method = `Sem Pagamento`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-id-iugu="${element.invoice_id_iugu}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-id-iugu="${element.invoice_id_iugu}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editBilling">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-left">
    //   <span class="mc-tooltip grab">
    //     <span data-controller="app--helpers--copy" data-copy="${element.client_name}" data-action="click->app--helpers--copy#copy">${element.client_name}</span>
    //     <span class="mc-tooltiptext">Clique para Copiar</span>
    //   </span>
    // </td>`

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#concilationModal">${element.client_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.med_nickname}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.due_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.paid_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.return_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.executed_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.total)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.fee)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.advance_fee)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${conciliated}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer">${executed}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  concilationModal(ev) {
    // var element = ev.currentTarget.dataset.element
    var invoiceIdIugu = ev.currentTarget.closest(`.itemRow`).dataset.idIugu
    var controllerModal = `financials--books--receivables--conciliations--modal--main`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`${controllerModal}`).iugu_billings = this.application.billings
      controller.getControllerByIdentifier(`${controllerModal}`).controllerForm = controller
      controller.getControllerByIdentifier(`${controllerModal}`).invoice_id_iugu = invoiceIdIugu
      controller.getControllerByIdentifier(`${controllerModal}`).open()
    })
  }

  returnBillingEv(ev) {
    var billingId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var current_billing_date = {}

    this.application.billing_dates.forEach(element => {
      if (element.id == billingId) {
        current_billing_date = element
      }
    })

    if (current_billing_date.executed) {
      alert(`Fatura já foi Liquidada!`)
    } else if (current_billing_date.conciliated == false) {
      alert(`Favor Conciliar a Fatura primeiro, antes de liquidá-la!`)
    } else {
      var r = confirm(`Tem certeza que deseja Liquidar a Fatura?`)
      if (r) {
        this.returnBilling(current_billing_date.billing_id)
      }
    }
  }

  returnBilling(billingId) {
    this.actionMode = `liquidation`
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { billing: { id: billingId }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_billings` } }
    const url = "/financials/integrations/bankings/iugu/billing_dates/liquidation"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

    this.requestFetch(url, init)
  }

  requestFetch(url, init) {
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var billing = response.data.cln

          // controller.application.billings.forEach(element => {
          //   if (element.id == billing.id) {
          //     controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
          //   }
          // })
          // controller.application.billing_dates.forEach((element, i) => {
          //   if (element.billing_id == billing.id) {
          //     controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(element), 1, billing.billing_dates[0])
          //   }
          // })

          controller.refreshData(billing)
          controller.doDataTable()
          // controller.getControllerByIdentifier(`financials--books--receivables--liquidations--days`).goToDay()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshReturnDatesEv(ev) {
    var billingConciliations = []

    this.application.billing_dates.forEach(element => {
      billingConciliations[billingConciliations.length] = element.conciliated
    })

    if (this.application.billing_dates.length == 0) {
      alert(`Não há Faturas do dia ${this.current_day} para Liquidar!`)
    } else if (billingConciliations.some(checkConciliation)) {
      alert(`Favor Conciliar a(s) Fatura(s) primeiro, antes de liquidá-la(s)!`)
    } else {
      var r = confirm(`Tem certeza que deseja Liquidar as Faturas do dia ${this.current_day}?`)
      if (r) {
        this.refreshReturnDates()
      }
    }

    function checkConciliation(conciliated) {
      return conciliated == false
    }
  }

  refreshReturnDates() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Liquidando Faturas do dia ${this.current_day}-${this.application.current_date.month_short}` })
    this.refreshLoadValue(this.current_day)
    var billingDatesIds = []
    this.requests = []
    
    this.application.billing_dates.forEach(element => {
      billingDatesIds[billingDatesIds.length] = element.id

      var data = { billing: { id: element.billing_id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_billings` } }
      var url = "/financials/integrations/bankings/iugu/billing_dates/liquidation"
      var init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

      this.requests[this.requests.length] = { url: url, init: init }
    })


    this.requestNext()
  }

  requestNext() {
    var controller = this
    if (this.requests.length > 0) {

      var request = this.requests.shift()
      fetch(request.url, request.init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            var billing = response.data.cln

            // controller.application.billings.forEach(element => {
            //   if (element.id == billing.id) {
            //     controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
            //   }
            // })
            // controller.application.billing_dates.forEach((element, i) => {
            //   if (element.billing_id == billing.id) {
            //     controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(element), 1, billing.billing_dates[0])
            //   }
            // })

            controller.refreshData(billing)
            controller.doDataTable()
            controller.requestNext()
          }
        })
        .catch(error => {
          controller.stopRefreshing()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      var response = { type: `success`, message: `Faturas liquidadas com sucesso!` }
      this.stopRefreshing()
      this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      this.doDataTable()
    }
  }

  refreshData(billing) {
    this.application.billings.forEach(element => {
      if (element.id == billing.id) {
        this.application.billings.splice(this.application.billings.indexOf(element), 1, billing)
      }
    })

    this.application.billing_dates.forEach((element, i) => {
      if (element.billing_id == billing.id) {
        this.application.billing_dates.splice(this.application.billing_dates.indexOf(element), 1, billing.billing_dates[0])
      }
    })
  }

  refreshLoadValue(currentDay) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      value += 1

      if (value == 99) {
        value -= 1
        // controller.stopRefreshing()
        // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
        
        // var response = { type: `danger`, message: `Ainda estamos processando as faturas` }
        // controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        
        var message = `Ainda estamos processando as faturas`
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: message, value: value })
      } else {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Liquidando Faturas do dia ${currentDay}-${controller.application.current_date.month_short}`, value: value })
      }

    }, 3000);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.iugu_billings = this.application.client_billings
    this.kind = false

    this.doDataTable()
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--show`).doNoBilling()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
