import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "saveBtn", "8888", "9999",
                    "opportunityProspectorInput", "prospectorFilter", "opportunityCloserInput", "closerFilter", "opportunityStageInput",
                    "stageFilter", "checkboxRecurring", "opportunityProductNameEdit", "opportunityProductNameInput", "productNameFilter",
                    "opportunityProductPlanEdit", "opportunityProductPlanInput", "productPlanFilter",
                    "kindFilterItem", "opportunityKindInput", "kindFilter",
                    "opportunityProductKindEdit", "opportunityProductKindInput", "productKindFilter", "opportunityProductDescriptionEdit",
                    "opportunityProductDescriptionInput", "opportunityProductAmountEdit", "opportunityProductAmountInput"]


  connect() {
    this.controllerName = `commercial--sales--opportunities--records--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("commercial--sales--opportunities--records--show").doViewHtml()
    this.getControllerByIdentifier("commercial--sales--opportunities--records--index").cleanCheckBox()
  }

  saveOpportunity() {
    // this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    // this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    this.send_data = { current_user: {}, opportunity: {}, journey: {}, product: {} }

    this.send_data.opportunity.id = this.current_opportunity.id
    this.send_data.opportunity.prospector_id = this.opportunityProspectorInputTarget.dataset.filter
    this.send_data.opportunity.prospector_name = this.opportunityProspectorInputTarget.dataset.text
    this.send_data.opportunity.closer_id = this.opportunityCloserInputTarget.dataset.filter
    this.send_data.opportunity.closer_name = this.opportunityCloserInputTarget.dataset.text
    this.send_data.opportunity.stage = this.opportunityStageInputTarget.dataset.filter
    this.send_data.opportunity.kind = this.opportunityKindInputTarget.dataset.filter
    this.send_data.journey.id = this.current_opportunity.journeys[0].id
    this.send_data.journey.stage = this.opportunityStageInputTarget.dataset.filter

    if (this.current_opportunity.products[0]) {
      this.send_data.product.id = this.current_opportunity.products[0].id
    }

    this.send_data.product.opportunity_id = this.current_opportunity.id
    this.send_data.product.recurring = this.checkboxRecurringTarget.checked
    this.send_data.product.name = this.opportunityProductNameInputTarget.dataset.filter
    if (this.checkboxRecurringTarget.checked) {
      this.send_data.product.plan = this.opportunityProductPlanInputTarget.dataset.filter
      this.send_data.product.kind = this.opportunityProductKindInputTarget.dataset.filter
      this.send_data.product.description = ``
    } else {
      this.send_data.product.plan = `none`
      this.send_data.product.kind = `separate`
      this.send_data.product.description = this.opportunityProductDescriptionInputTarget.value
    }
    this.send_data.product.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.opportunityProductAmountInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/sales/opportunities/entities/update_record"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var opportunity = response.data.cln
          controller.application.opportunities.forEach((element, i) => {
            if (element.id == opportunity.id) {
              controller.application.opportunities.splice(controller.application.opportunities.indexOf(element), 1, opportunity)
            }
          })
          controller.cancelSave()
          controller.getControllerByIdentifier("commercial--sales--opportunities--records--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.sales_opportunities_entities.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveOpportunity" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Editando Oportunidade</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">SDR</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="opportunityProspectorInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#prospectorFilter keyup->${this.controllerName}#prospectorFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterProspector" type="text" placeholder="Prospector" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="prospectorFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Closer</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="opportunityCloserInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#closerFilter keyup->${this.controllerName}#closerFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterCloser" type="text" placeholder="Closer" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="closerFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Estágio</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="opportunityStageInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#stageFilter keyup->${this.controllerName}#stageFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterStage" type="text" placeholder="Estágio" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stageFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Persona</label>
                            <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityKindInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#kindFilter keyup->${this.controllerName}#kindFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterStage" type="text" placeholder="Persona" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="kindFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2 w-100" data-${this.controllerName}-target="opportunityProductAddCard">
                      <div class="col-12 px-1">
                        <div class="card">
                          <div class="card-body p-0 f-065 pointer">
                            <div class="row my-1 mt-3">
                              <div class="col-12 px-2 pointer">
                                <div class="custom-control custom-checkbox pl-3 d-flex align-items-center">
                                  <input type="checkbox" class="custom-control-input" id="checkRecurring" data-${this.controllerName}-target="checkboxRecurring" data-action="click->${this.controllerName}#opportunityRecurringField">
                                  <label class="custom-control-label pointer f-075" for="checkRecurring">Recorrente</label>
                                </div>
                              </div>
                            </div>
                            <div class="row my-1">
                              <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductNameEdit">
                                <div class="form-group w-100 mb-0">
                                  <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductNameInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Nome" required>
                                  <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productNameFilter"></ul>
                                </div>
                              </div>
                            </div>
                            <div class="row my-1 d-flex align-items-center">
                              <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductPlanEdit">
                                <div class="form-group w-100 mb-0">
                                  <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductPlanInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Plano" required>
                                  <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productPlanFilter"></ul>
                                </div>
                              </div>
                            </div>
                            <div class="row my-1 d-flex align-items-center">
                              <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductKindEdit">
                                <div class="form-group w-100 mb-0">
                                  <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductKindInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Tipo" required>
                                  <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productKindFilter"></ul>
                                </div>
                              </div>
                            </div>
                            <div class="row my-1 d-flex align-items-center">
                              <div class="col-12 px-3 d-flex align-items-center d-none" data-${this.controllerName}-target="opportunityProductDescriptionEdit">
                                <div class="form-group w-100 mb-0">
                                  <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductDescriptionInput" type="text" placeholder="Descrição Avulso" required>
                                </div>
                              </div>
                            </div>
                            <div class="row my-1 d-flex align-items-center">
                              <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductAmountEdit">
                                <div class="form-group w-100 mb-0">
                                  <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductAmountInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" type="tel" placeholder="Valor" required>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--opportunities--records--dashboard").viewTarget.innerHTML = html)
    }).then(() => {

      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.opportunityProspectorInputTarget.value = controller.current_opportunity.prospector_name
      controller.opportunityProspectorInputTarget.dataset.filter = controller.current_opportunity.prospector_id
      controller.opportunityProspectorInputTarget.dataset.text = controller.current_opportunity.prospector_name
      controller.opportunityCloserInputTarget.value = controller.current_opportunity.closer_name
      controller.opportunityCloserInputTarget.dataset.filter = controller.current_opportunity.closer_id
      controller.opportunityCloserInputTarget.dataset.text = controller.current_opportunity.closer_name
      controller.opportunityStageInputTarget.value = controller.current_opportunity.stage_pretty
      controller.opportunityStageInputTarget.dataset.filter = controller.current_opportunity.stage

      if (controller.current_opportunity.products[0]) {
        if (controller.current_opportunity.products[0].recurring) {
          controller.checkboxRecurringTarget.checked = true
        } else {
          controller.checkboxRecurringTarget.checked = false
        }
        
        controller.opportunityProductNameInputTarget.value = controller.current_opportunity.products[0].name_pretty
        controller.opportunityProductPlanInputTarget.value = controller.current_opportunity.products[0].plan_pretty
        controller.opportunityProductKindInputTarget.value = controller.current_opportunity.products[0].kind_pretty
        controller.opportunityProductDescriptionInputTarget.value = controller.current_opportunity.products[0].description
        controller.opportunityProductAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_opportunity.products[0].amount)
        
        controller.opportunityProductNameInputTarget.dataset.filter = controller.current_opportunity.products[0].name
        controller.opportunityProductPlanInputTarget.dataset.filter = controller.current_opportunity.products[0].plan
        controller.opportunityProductKindInputTarget.dataset.filter = controller.current_opportunity.products[0].kind
      }

      controller.listProduct()
      controller.listStage()
      controller.listKind()
      controller.listCloser()
      controller.listProspector()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  opportunityRecurringField(ev) {
    var checked = ev.currentTarget.checked

    if (checked) {
      this.opportunityProductPlanEditTarget.classList.remove("d-none")
      this.opportunityProductKindEditTarget.classList.remove("d-none")
      this.opportunityProductDescriptionEditTarget.classList.add("d-none")
    } else {
      this.opportunityProductPlanEditTarget.classList.add("d-none")
      this.opportunityProductKindEditTarget.classList.add("d-none")
      this.opportunityProductDescriptionEditTarget.classList.remove("d-none")
    }
  }

  listProduct() {

    var productHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="PJ Médica" data-filter="medclinic" class="li-selector dark f-065">PJ Médica</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Funcionário" data-filter="medemployee" class="li-selector dark f-065">Funcionário</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Faturamento" data-filter="medbiling" class="li-selector dark f-065">Faturamento</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Gestão de Recebimentos" data-filter="medreceivement" class="li-selector dark f-065">Gestão de Recebimentos</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Seguros" data-filter="medseg" class="li-selector dark f-065">Seguros</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Seguros" data-filter="medprev" class="li-selector dark f-065">Previdência Privada</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Planner" data-filter="medreturn" class="li-selector dark f-065">Planner</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Livro-Caixa" data-filter="medbooking" class="li-selector dark f-065">Livro-Caixa</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Declaração IRPF" data-filter="medfiling" class="li-selector dark f-065">Declaração IRPF</li>`

    this.productNameFilterTarget.innerHTML = productHtml

    var planHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Recebimento" data-filter="receivement" class="li-selector dark f-065">Recebimento</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Consultório" data-filter="practice" class="li-selector dark f-065">Consultório</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="PGBL" data-filter="pgbl" class="li-selector dark f-065">PGBL</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="VGBL" data-filter="vgbl" class="li-selector dark f-065">VGBL</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Vida" data-filter="life" class="li-selector dark f-065">Vida</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="DIT" data-filter="income" class="li-selector dark f-065">DIT</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Responsabilidade Civil" data-filter="responsability" class="li-selector dark f-065">Responsabilidade Civil</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Saúde" data-filter="health" class="li-selector dark f-065">Saúde</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Light" data-filter="light" class="li-selector dark f-065">Light</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Basic" data-filter="basic" class="li-selector dark f-065">Basic</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Premium" data-filter="premium" class="li-selector dark f-065">Premium</li>`

    this.productPlanFilterTarget.innerHTML = planHtml

    var kindHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Abertura" data-filter="opening" class="li-selector dark f-065">Abertura</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Migração" data-filter="migration" class="li-selector dark f-065">Migração</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Alteração" data-filter="amendment" class="li-selector dark f-065">Alteração</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Regularização" data-filter="regularization" class="li-selector dark f-065">Regularização</li>`

    this.productKindFilterTarget.innerHTML = kindHtml
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  productFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectItem(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterItem(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listStage() {
    var html = `<li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Prospecção" data-filter="prospecting" class="li-selector dark f-065">Prospecção</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Qualificação" data-filter="qualification" class="li-selector dark f-065">Qualificação</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Agendamento" data-filter="booking" class="li-selector dark f-065">Agendamento</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Consultoria" data-filter="meeting" class="li-selector dark f-065">Consultoria</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Proposta" data-filter="proposal" class="li-selector dark f-065">Proposta</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Fechamento" data-filter="closing" class="li-selector dark f-065">Fechamento</li>`

    this.stageFilterTarget.innerHTML = html
  }

  stageFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectStage(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterStage(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }
  
  listKind() {
    var html = `<li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Recém-Formado" data-filter="graduated" class="li-selector dark f-065">Recém-Formado</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Residente" data-filter="resident" class="li-selector dark f-065">Residente</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Especialista" data-filter="market" class="li-selector dark f-065">Especialista</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Consultório" data-filter="consulting" class="li-selector dark f-065">Consultório</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Clínica" data-filter="clinic" class="li-selector dark f-065">Clínica</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Não Médico" data-filter="non_doctor" class="li-selector dark f-065">Não Médico</li>`

    this.kindFilterTarget.innerHTML = html
  }

  kindFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectKind(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterKind(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listCloser() {
    var html = ``
    this.application.sales_team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectCloser" data-${this.controllerName}-target="closerFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.closerFilterTarget.innerHTML = html
  }

  closerFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectCloser(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterCloser(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listProspector() {
    var html = ``
    this.application.sales_team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectProspector" data-${this.controllerName}-target="prospectorFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.prospectorFilterTarget.innerHTML = html
  }

  prospectorFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectProspector(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterProspector(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.opportunityProspectorInputTarget.value == ``) {
        len += 1
      }

      if (controller.opportunityCloserInputTarget.value == ``) {
        len += 1
      }

      if (controller.opportunityStageInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
