import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body",
    "searchInput", "searchTable"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--index--update-campaign-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.tableCols = 1
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      if (controller.kind == `project`) {
        controller.kindPretty = `Projetos`
      } else if (controller.kind == `cost_center`) {
        controller.kindPretty = `Centro de Custos`
      }

      controller.doIndexListHtml()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list mr-auto">Atualizando Campanha</h6>
                    <button type="button" class="close mx-auto" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    var campaign = this.opportunityCampaign()

    campaign.forEach(element => {
      this.bodyTableTarget.insertAdjacentHTML("beforeend", this.campaignTablePartial(element, campaign.length))
    })

  }

  campaignTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-name="${element.name}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-name="${element.name}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center" data-action="click->${this.controllerName}#saveOpportunityEv" data-name="${element.name}">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.name_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Selecionar</span>
                      </span>
                    </td>
                  </tr>`

    return rowHtml
  }

  saveOpportunityEv(ev) {
    var element = ev.currentTarget

    var obj_name = element.dataset.name
    this.saveOpportunity(obj_name)
  }

  saveOpportunity(obj_name) {

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.opportunity_id
    this.send_data.opportunity.campaign = obj_name

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.feature

    var url = "/commercial/sales/opportunities/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln

          controller.application.all_opportunities.forEach((element, i) => {
            if (element.id == opportunity.id) {
              controller.application.all_opportunities.splice(controller.application.all_opportunities.indexOf(element), 1, opportunity)
            }
          })

          controller.close()
          controller.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doDataTable()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  opportunityCampaign() {

    var campaign = [
                      {
                        name: `upsell_base`,
                        name_pretty: `Upsell Base`,
                      },
                      {
                        name: `recommendation`,
                        name_pretty: `Indicação`,
                      },
                      {
                        name: `reactivation`,
                        name_pretty: `Reativação de Leads`,
                      },
                      {
                        name: `graduation`,
                        name_pretty: `Recém-Formados`,
                      },
                      {
                        name: `outbounding`,
                        name_pretty: `Outbound`,
                      },
                      {
                        name: `direct_contact`,
                        name_pretty: `Contato Direto`,
                      },
                      {
                        name: `market_events`,
                        name_pretty: `Eventos de Mercado`,
                      },
                    ]

    return campaign
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}