import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "cardTitle", "addNewPartyBtn"]

  connect() {
    this.controllerName = `operations--onboards--show--parties--account--index`
    this.sort = { mode: `asc`, field: `name` }
    this.doIndexListHtml()
  }

  addNewParty() {
    this.getControllerByIdentifier("operations--onboards--show--parties").partiesActions(true)
    this.getControllerByIdentifier("operations--onboards--show--parties--account--save").party = {}
    this.getControllerByIdentifier("operations--onboards--show--parties--account--save").doAccountFormHtml()
  }

  showParty(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.parties.forEach(element => {
      if (element.id == partyId) {
        this.getControllerByIdentifier("operations--onboards--show--parties").partiesActions(true)
        this.getControllerByIdentifier("operations--onboards--show--parties--account--save").party = element
        this.getControllerByIdentifier("operations--onboards--show--parties--account--save").doAccountFormHtml()
      }
    })
  }

  doIndexListHtml() {
    var html = `<div class="" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center f-065 p-0">
                    <h6 class="card-title f-065 p-1 mb-1" data-${this.controllerName}-target="cardTitle">Médicos</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline m-0 p-1" data-${this.controllerName}-target="addNewPartyBtn" data-action="click->${this.controllerName}#addNewParty" type="button"><i class="material-icons md-sm">add</i></button>
                      <span class="mc-tooltiptext">Adicionar Médico</span>
                    </div>
                  </div>
                  <div class="card-body p-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--onboards--show--parties").accountPartyBodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(controller.getControllerByIdentifier("operations--onboards--show--parties").formBodyTarget)[0].offsetHeight * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(2, 3))
      controller.doDataTable()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  doDataTable() {
    this.current_parties = []

    this.application.parties.forEach(element => {
      if (element.party_kind == `individual`) {
        this.current_parties[this.current_parties.length] = element
      }
    })

    this.listData(this.current_parties)
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<tr>
                      <td colspan="2" class="p-3 align-middle text-center" style="font-size:80%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-065 align-middle">Não há Médicos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.accountTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

  }

  accountTablePartial(element, length) {
    var deleteBtn = `<button data-action="click->${this.controllerName}#deleteParty" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">person_remove</span>
                      <span class="mc-tooltiptext mc-tooltiptext-left">Remover</span>
                    </button>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showParty">${element.party_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${deleteBtn}</td>
                  </tr>`

    return rowHtml
  }

  deleteParty(ev) {
    var r = confirm(`Tem certeza que deseja a Parte do Contrato?`)
    if (r) {
      this.getControllerByIdentifier("operations--onboards--show--parties").partiesActions(true)
      this.send_data = { current_user: {}, party: {} }
      var partyId = ev.currentTarget.closest(".itemRow").dataset.id

      this.send_data.party.id = partyId
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `operation_onboard_parties`

      this.getControllerByIdentifier("operations--onboards--show--parties").requestDestroy(this.send_data)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
