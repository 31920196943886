import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["remunerationTitle", "remunerationBoard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `financials--books--payables--dashboard--remunerations`
  }

  doRemunerationCard() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Remunerações ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`

      this.remunerationTitleTarget.innerHTML = title
    }

    // `${this.numberController.currencyOptionMask(this.calculation.total_paid_amount)}`

    var html = `<div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Remunerações</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Remuneração</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.remuneration)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Bonificações</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.bonus)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Comissões</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.commissions)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Benefícios e Reembolsos</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Benefícios</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.benefits)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Reembolsos</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.refunds)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Encargos e Rescissões</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Encargos</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.labor_law)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Rescissões</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.rescission)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Outros</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.others)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.remunerationBoardTarget.innerHTML = html
  }

  setRemunerationCalculation() {
    this.current_remunerations = []

    this.application.remunerations.forEach(element => {
      if (this.application.current_med.id) {
        if (element.med_id == this.application.current_med.id && element.date_id == this.application.current_date.id) {
          this.current_remunerations[this.current_remunerations.length] = element
        }
      } else {
        if (element.date_id == this.application.current_date.id) {
          this.current_remunerations[this.current_remunerations.length] = element
        }
      }
    })

    this.calculation = {}

    this.calculation.remuneration = 0
    this.calculation.refunds = 0
    this.calculation.bonus = 0
    this.calculation.commissions = 0
    this.calculation.benefits = 0
    this.calculation.rescission = 0
    this.calculation.others = 0
    this.calculation.labor_law = 0

    var chart_names = ["Remunerações", "Reembolsos", "Bonificações", "Comissões", "Encargos Trabalhistas", "Benefícios", "Rescisões", "Outros", "Pró-Labore"]
    this.current_remunerations.forEach(element => {
      var bindSum = 0
      if (element.chart_name.split(" | ")[1]) {
        if (chart_names.includes(element.chart_name.split(" | ")[1])) {
          if (element.kind_input == `inflow` || element.kind_input == `prepaidflow` || element.kind_input == `refundflow`) {
            bindSum += Number(element.amount)
          // } else if (element.kind_input == `outflow` || element.kind_input == `laborflow`) {
          } else if (element.kind_input == `outflow`) {
            bindSum -= Number(element.amount)
          }
        }

        this.setCalculation(element.chart_name.split(" | ")[1], bindSum)
      }
    })

    this.doRemunerationCard()
  }

  goToRemunerations() {
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").doRemunerationsDashboard()
  }

  setCalculation(chartName, bindSum) {
    var chart_names = ["Remunerações", "Reembolsos", "Bonificações", "Comissões", "Encargos Trabalhistas", "Benefícios", "Rescisões", "Outros"]

    if (chartName == `Remunerações` || chartName == `Pró-Labore`) {
      this.calculation.remuneration += bindSum
    } else if (chartName == `Reembolsos`) {
      this.calculation.refunds += bindSum
    } else if (chartName == `Bonificações`) {
      this.calculation.bonus += bindSum
    } else if (chartName == `Comissões`) {
      this.calculation.commissions += bindSum
    } else if (chartName == `Benefícios`) {
      this.calculation.benefits += bindSum
    } else if (chartName == `Rescisões`) {
      this.calculation.rescission += bindSum
    } else if (chartName == `Outros`) {
      this.calculation.others += bindSum
    } else if (chartName == `Encargos Trabalhistas`) {
      this.calculation.labor_law += bindSum
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
