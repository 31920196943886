import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["contentCol", "contentRow", "listLoader", "repository", "todo", "doing", "done", "taskPreloader"]

  connect() {
    this.controllerName = `users--tasks--board--stage`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    this.doBoardHTML()
    this.doBoardListPreloader()
    this.doBoardLoader()
    document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view = "stage"
    this.getCurrentUserPermissions()
  }

  disconnect() {
  }

  doBoardLoader() {
    const user_slug = window.location.href.split("/").reverse().shift().split("#")[0]
    if (window.location.href.split("/").reverse().shift() != currentUser.slug) {
    } else {  
    }
    const data = { task: { user_slug: user_slug }, current_user: { current_user_id: currentUser.id } }
    this.request(data)
  }

  request(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/entities/list_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.listLoaderTargets.forEach(listLoader => {
          listLoader.remove()
        }),
        allProcessName = response.data.cln.process_name,
        allProcessWhom = response.data.cln.process_whom,
        document.querySelector('#tasksFilters').usersTasksBoardFilter.doDropdownFilter(),
        allBoardTasks = response.data.cln.collection,
        this.listTasks(allBoardTasks)
      })
  }

  cleanTasks() {
    ["repository", "todo", "doing", "done"].forEach(target => {
      while (this.nameTarget(target).children.length > 1) {
        this.nameTarget(target).removeChild(this.nameTarget(target).lastChild)
      }
    })
  }

  listTasks(tasks) {
    tasks.forEach(element => {
      this.nameTarget(element.stage).insertAdjacentHTML("beforeend", document.querySelector('#newTaskBtn').usersTasksBoardUnit.taskPartial(element))
      setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setPriority(element.priority); }, 100)
      setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setDuration(element.duration); }, 100)
      setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.countDoneJobs(); }, 100)
      tooltip()
    })
  }

  doBoardHTML() {
    const html = `<div>
                    <section class="grid-board-task grid-template-columns-board-task">
                      <div id="listRepository" class="board-task-list" stage="repository" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-${this.controllerName}-target="repository">
                        <div class="board-task-list-title d-flex justify-content-between">Repositório <span class="material-icons pointer" data-action="click->users--tasks--board--unit#new" id="newTaskBtn" data-controller="users--tasks--board--unit users--tasks--board--comment users--tasks--board--job users--tasks--board--modal">add</span></div>
                      </div>
                      <div id="listTodo" class="board-task-list" stage="todo" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-${this.controllerName}-target="todo">
                        <div class="board-task-list-title d-flex justify-content-between">Para Fazer</div>
                      </div>
                      <div id="listDoing" class="board-task-list" stage="doing" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-${this.controllerName}-target="doing">
                        <div class="board-task-list-title d-flex justify-content-between">Fazendo</div>
                      </div>
                      <div id="listDone" class="board-task-list" stage="done" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-${this.controllerName}-target="done">
                        <div class="board-task-list-title d-flex justify-content-between">Finalizada</div>
                      </div>
                    </section>
                  </div>`
    this.contentColTarget.insertAdjacentHTML("beforeend", html)
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  fetchAllLabels() {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/labels/list"
    const data = {}
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(data => {
        allLabels = data.cln
      })
  }

  doBoardListPreloader() {
    const items = 4
    var rowHtml = ''
    for (var i = 0; i < items; i++) {
      rowHtml += `<div class='card timeline-item my-2' data-${this.controllerName}-target="listLoader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>`
    }
    ["repository", "todo", "doing", "done"].forEach(target => {
      this.nameTarget(target).insertAdjacentHTML("beforeend", rowHtml)
    })
  }

  getCurrentUserPermissions() {
    const featureNames = ["squad_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          $("#currentUserPermisisonContainer").data(permission.name, permission.data)
        });
        document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.doSubMenuHtml()
      })
  }
}