
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["ticketCard", "noteCard", "ticketRow", "noteRow", "accountCard", "accountRow",
                    "logCard", "logRow", "cardRow", "cardBtn"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--workings`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doMenuHtml() {
    var html = `<div class="row my-3">
                  <div class="col-3 px-2">
                    <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketCard cardBtn" data-feature="ticket" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="noteCard cardBtn" data-feature="note" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="accountCard cardBtn" data-feature="account" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Contas</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="logCard cardBtn" data-feature="log" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Logs</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-0">
                    <div class="row" data-controller="financials--books--clients--contracts--tickets" data-${this.controllerName}-target="ticketRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--contracts--notes" data-${this.controllerName}-target="noteRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--accounts--dashboard" data-${this.controllerName}-target="accountRow cardRow"></div>
                    <div class="row d-none" data-controller="app--stats--trackers--logs--dashboard" data-${this.controllerName}-target="logRow cardRow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--clients--contracts--dashboard`).workingsTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).current_contract = controller.current_contract
      controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).current_client = controller.current_client
      controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).current_contract = controller.current_contract
      controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).current_client = controller.current_client
      
      controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).doTicketsDashboard()
      controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).doNotesDashboard()
      
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).current_client = controller.current_client
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).gridElement = controller.accountRowTarget
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).clientDash = false
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).doAccountsDashboard()

      controller.goToLogs()
    })
  }

  toggleCard(ev) {
    var feature = ev.currentTarget.dataset.feature

    this.cardRowTargets.forEach(element => {
      element.classList.add(`d-none`)
    })
    this.cardBtnTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.nameTarget(`${feature}Card`).classList.add(`bg-primary`)
    this.nameTarget(`${feature}Row`).classList.remove(`d-none`)
  }

  goToLogs() {
    this.application.logs = []

    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_filter = true
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_id = this.current_client.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_type = `financial_client_entities`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_id = this.current_contract.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_type = `financial_client_contracts`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").permission = `financial_client_contracts`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").gridElement = this.logRowTarget
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").title = `${this.current_contract.name}`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").noLogsMessage = `Não há Logs para o Contrato ${this.current_contract.name}`

    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").doLogsDashboard()
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Informações carregadas com sucesso!` }

    this.doMenuHtml()
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    // this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.current_contract.id, domain_type: `financial_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    // this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 60 })
    this.application.tickets = []

    var data = { ticket: { board_id: this.current_contract.id, board_type: `financial_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAddons() {
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).current_contract = this.current_contract
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).getAddons()

    this.getTickets()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}