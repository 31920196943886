import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "navBotton", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--header`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)
    } else if (this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)
    } else if (this.getControllerByIdentifier(`mobile--commercial--marketing--events--publics--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--commercial--marketing--events--publics--main`)
    }

  }

  doHtml() {
    var link = this.siteController.setLink(this.application.site.header_link_cta)
    // <li class="nav-item delay-3"><a class="nav-link a-dark" href="/medschool">MedSchool</a></li><br>

    var html = `<header>
                  <nav class="navbar box-shadow ws-nav-bar">
                    <div class="container">
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                      <a class="navbar-brand mr-5 " href="/">
                      </a>
                      <a class="nav-button" data-${this.controllerName}-target="navButton" data-action="click->${this.controllerName}#toggleClass"><span id="nav-icon3"><span></span><span></span><span></span><span></span></span></a>
                      <div class="fixed-top main-menu">
                        <div class="flex-center p-5">
                          <ul class="nav navmob flex-column">
                            
                            <li class="nav-item delay-3"><a class="nav-link a-dark" href="/descanso-medico">Descanso Médico</a></li><br>
                            <li class="nav-item delay-3"><a class="nav-link a-dark" href="/medschool">MedSchool</a></li><br>
                            <li class="nav-item delay-3"><a class="nav-link a-dark" href="/entrar">Entrar</a></li><br>

                            <li class="nav-item delay-4">
                              <a class="ws-button mws-button pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.header_cta}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </header>`

    this.siteController.contentTarget.insertAdjacentHTML("beforeend", html)
  }

  toggleClass() {
    if (document.body.classList.contains(`nav-open`)) {
      document.body.classList.remove(`nav-open`)
    } else {
      document.body.classList.add(`nav-open`)
    }
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    // <li class="nav-item delay-3"><a class="nav-link a-dark" href="/medschool">MedSchool</a></li><br>

    var html = `<header>
                  <nav class="navbar box-shadow ws-nav-bar">
                    <div class="container">
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                      <a class="navbar-brand mr-5 " href="/">
                      </a>
                      <a class="nav-button" data-${this.controllerName}-target="navButton" data-action="click->${this.controllerName}#toggleClass"><span id="nav-icon3"><span></span><span></span><span></span><span></span></span></a>
                      <div class="fixed-top main-menu">
                        <div class="flex-center p-5">
                          <ul class="nav navmob flex-column">
                            
                            <li class="nav-item delay-3"><a class="nav-link a-dark" href="/descanso-medico">Descanso Médico</a></li><br>
                            <li class="nav-item delay-3"><a class="nav-link a-dark" href="/entrar">Entrar</a></li><br>

                            <li class="nav-item delay-4">
                              <a class="ws-button mws-button pointer" data-link="/consultoria" data-action="click->${this.controllerName}#goToCTA">Agendar Consultoria</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </header>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
