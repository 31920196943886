
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dayBtn", "totalCalls", "callCount", "callCountTooltip"]

  connect() {
    this.controllerName = `app--communications--voips--calls--days`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.setCurrentDay = false
  }

  doMenuHtml() {

    var daysHtml = ``
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)

    for (var day = 1; day <= lastDay; day++) {

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, day)
      var weekDay = date.getDay()
      var weekDayName = this.controllerDate.getWeekDay(weekDay)

      daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn callCount-${day}">
                    ${day}-${this.application.current_date.month_short}
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
                  </button>`

    }
    var html = `<div class="btn-group btn-group-sm" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${daysHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--communications--voips--calls--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      if (controller.application.current_date.year == date.getFullYear() && controller.application.current_date.month == (date.getMonth() + 1)) {
        var day = date.getDate()
        controller.current_day = date.getDate()
      } else {
        var day = 1
        controller.current_day = 1
      }
    })
  }

  checkBadgeCount() {
    if (this.setCurrentDay) {
      this.doBadgeCount()
    }
  }

  doBadgeCount() {
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)
    var day = this.current_day
    var currentFilterCalls = []
    var currentCalls = []

    for (var indexDay = 1; indexDay <= lastDay; indexDay++) {
      if (this.nameTarget(`totalCalls-${indexDay}`)) {
        this.nameTarget(`totalCalls-${indexDay}`).remove()
      }
    }

    this.application.calls.forEach(element => {
      if (this.application.current_caller_id) {
        if (element.date_id == this.application.current_date.id && element.caller_id == this.application.current_caller_id) {
          currentCalls[currentCalls.length] = element
        }
      } else {
        if (element.date_id == this.application.current_date.id) {
          currentCalls[currentCalls.length] = element
        }
      }
      
    })

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (day < 10) {
      var currentDay = `0${day}`
    } else {
      var currentDay = `${day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    currentCalls.forEach(element => {
      if (element.started_at_token == currentToken) {
        currentFilterCalls[currentFilterCalls.length] = element
      }
    })

    if (this.nameTarget(`callCount-${day}`)) {
      var callBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalCalls-${day}">${currentFilterCalls.length}</span>`
      if (this.nameTarget(`totalCalls-${day}`)) {
        this.nameTarget(`totalCalls-${day}`).remove()
      }
      this.nameTarget(`callCount-${day}`).insertAdjacentHTML("beforeend", callBadgeHtml)
    }
  }

  goToDayEv(ev) {
    var day = ev.currentTarget.dataset.day

    this.current_day = day
    this.setCurrentDay = true
    this.doBadgeCount()
    this.goToDay()
  }

  goToDay() {

    this.getControllerByIdentifier(`app--communications--voips--calls--index`).dayFilter = true
    this.getControllerByIdentifier(`app--communications--voips--calls--index`).current_day = this.current_day
    this.getControllerByIdentifier(`app--communications--voips--calls--index`).doDataTable()

  }

  toggleBtn(ev) {

    this.dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.dayBtnTargets.forEach(element => {

      if (this.hasCallCountTarget && element == this.callCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doCallsCount() {
    var callCount = 0
    var callUnconciliatedCount = 0

    this.application.calls.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        callCount += 1
      }
    })

    if (this.hasCallCountTarget) {
      var callBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalCalls">${callCount}</span>`
      if (this.totalCallsTargets[0]) {
        this.totalCallsTargets[0].remove()
      }
      this.callCountTarget.insertAdjacentHTML("beforeend", callBadgeHtml)
      this.callCountTooltipTarget.innerText = `${callCount} Ligações`
    }
  }

  doMenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    // this.getControllerByIdentifier(`app--communications--voips--calls--dashboard`).daysTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--communications--voips--calls--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      controller.doMenuHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
