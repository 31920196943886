import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn",
                    "bindSum", "multipleCheck", "generalCheck", "generalCheckDiv", "billingCount"]

  connect() {
    this.controllerName = `financials--books--receivables--billings--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 12
    this.sort = { mode: `asc`, field: `due_at_iugu_to_time` }
    this.hasShow = false
    this.dayFilter = false
  }

  showBilling(ev) {
    var controllerShow = this.getControllerByIdentifier("financials--books--receivables--billings--show")
    var billingId = ev.target.closest(".itemRow").dataset.id

    this.application.billings.forEach(element => {
      if (element.id == billingId) {
        controllerShow.current_billing = element
      }
    })

    controllerShow.doBillingHtml()
  }

  addBilling() {
    this.showClearBtn()

    this.getControllerByIdentifier("financials--books--receivables--billings--save").actionMode = "new"
    this.getControllerByIdentifier("financials--books--receivables--billings--save").current_billing = {}
    this.getControllerByIdentifier("financials--books--receivables--billings--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var notPaid = `<span class="mc-tooltip badge badge-secondary-dark pointer" data-action="click->${this.controllerName}#filterNotConciliated">
                      <span class="material-icons md-sm">money_off_csred</span>
                      <span class="mc-tooltiptext">Filtrar Não Conciliados</span>
                    </span>`

    if (this.application.permissions[this.permission].can_download) {
      var downloadReceivedLine = `<span data-action="click->${this.controllerName}#downloadReceivedBillings" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Faturas Recebidas/Fiscal</span>`
    } else {
      var downloadReceivedLine = ``
    }

    if (this.application.permissions[this.permission].can_download) {
      var downloadAccrualLine = `<span data-action="click->${this.controllerName}#downloadAccrualBillings" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Faturas Competência</span>`
    } else {
      var downloadAccrualLine = ``
    }

    if (this.application.permissions[this.permission].can_download) {
      var downloadReturnedLine = `<span data-action="click->${this.controllerName}#downloadReturnedBillings" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Faturas Liquidadas</span>`
    } else {
      var downloadReturnedLine = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.application.current_user.super_admin) {
      var destroyLine = `<span data-action="click->${this.controllerName}#destroyReceivedBillings" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Faturas Recebidas/Fiscal</span>`
    } else {
      var destroyLine = ``
    }

    var bindSumLine = `<span data-action="click->${this.controllerName}#checkForBindSum" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Faturas</span>`
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshBillings" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Faturas</span>`
    var readLine = `<span data-action="click->${this.controllerName}#readInvoice" class="dropdown-item py-1 pointer dropdown-submenu-item">Buscar Fatura</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                          ${bindSumLine}
                          ${readLine}
                          ${downloadReceivedLine}
                          ${downloadAccrualLine}
                          ${downloadReturnedLine}
                          ${destroyLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="billingCount">Todas as Faturas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    ${notPaid}
                    <div class="card-actions py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-30 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="payer_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center">
                                Med
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Dt Vencimento
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="due_at_iugu_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="due_at_iugu_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Dt Pagamento
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="occurrence_at_iugu_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="occurrence_at_iugu_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Dt Compensação
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="paid_at_iugu_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="paid_at_iugu_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Ocorrência</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle text-center">Valor Faturado</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle text-center">Valor Pago</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center" colspan="2">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Método</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--billings--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    this.current_billings = []
    this.current_filter_billings = []

    this.application.billings.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_billings[this.current_billings.length] = element
      }

      if (this.application.current_med.id) {
        this.current_med_billings = []

        this.current_billings.forEach(element => {
          if (element.med_id == this.application.current_med.id) {
            this.current_med_billings[this.current_med_billings.length] = element
          }
        })

        this.current_billings = this.current_med_billings
      }

      if (this.hasFilterNotConciliated) {
        this.current_filter_billings_not_conciliated = []

        this.current_billings.forEach(element => {
          if (element.conciliated == false) {
            this.current_filter_billings_not_conciliated[this.current_filter_billings_not_conciliated.length] = element
          }
        })

        this.current_billings = this.current_filter_billings_not_conciliated
      }
    })

    if (this.dayFilter) {
      if (this.application.current_date.month < 10) {
        var currentMonth = `0${this.application.current_date.month}`
      } else {
        var currentMonth = `${this.application.current_date.month}`
      }

      if (this.current_day < 10) {
        var currentDay = `0${this.current_day}`
      } else {
        var currentDay = `${this.current_day}`
      }

      var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

      this.current_billings.forEach(element => {
        if (element.paid_date_token == currentToken) {
          this.current_filter_billings[this.current_filter_billings.length] = element
        }
      })

      if (this.hasFilterNotConciliated) {
        this.current_filter_billings_not_conciliated = []

        this.current_filter_billings.forEach(element => {
          if (element.conciliated == false) {
            this.current_filter_billings_not_conciliated[this.current_filter_billings_not_conciliated.length] = element
          }
        })

        this.current_billings = this.current_filter_billings_not_conciliated
      } else {
        this.current_billings = this.current_filter_billings
      }
    }

    this.hasFilterNotConciliated = false

    if (this.sort.mode == `asc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_billings, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var billings = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_billings, this.sort.field)
    }

    if (billings.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(billings.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (billings[x] !== undefined) {
            internPage[internPage.length] = billings[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: billings.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
    
    this.setTotalBillings()
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var message = ``
      if (this.dayFilter) {
        message = `Não há Faturas Pagas em ${this.current_day}/${this.application.current_date.short_name}`
      } else {
        message = `Não há Faturas na ${this.application.current_date.name}`
      }
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.billingTablePartial(element, data.length))
      })
    }
  }

  billingTablePartial(element, length) {

    if (element.status == `paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Pago</span>`
    } else if (element.status == `partially_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Parcialmente</span>`
    } else if (element.status == `externally_paid`) {
      var status = `<span class="f-070 badge badge-secondary-success">Paga Externamente</span>`
    } else if (element.status == `partially_refunded`) {
      var status = `<span class="f-070 badge badge-secondary-success">Reembolsada Parcialmente</span>`
    } else if (element.status == `pending`) {
      var status = `<span class="f-070 badge badge-secondary-warning">Pendente</span>`
    } else if (element.status == `canceled`) {
      var status = `<span class="f-070 badge badge-secondary-danger">Cancelado</span>`
    } else if (element.status == `expired`) {
      var status = `<span class="f-070 badge badge-secondary-dark">Expirado</span>`
    } else if (element.status == `executed`) {
      var status = `<span class="f-070 badge badge-secondary-success">Liquidada</span>`
    } else {
      var status = `<span class="f-070 badge badge-secondary-danger default">Não Pago</span>`
    }

    if (element.late_payment) {
      var latePayment = `<span class="f-070 badge badge-secondary-danger default">Atrasado</span>`
    } else {
      var latePayment = `<span class="f-070 badge badge-secondary-success">Em Dia</span>`
    }

    if (element.conciliated) {
      var conciliated = `<span class="f-070 badge badge-secondary-success">Conciliação Ok</span>`
    } else {
      var conciliated = `<span class="f-070 badge badge-secondary-danger">Não Conciliado</span>`
    }

    if (element.payment_method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `pix`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">pix</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `bank_debt`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.payment_method == `cash`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else {
      var method = `Sem Pagamento`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}" data-client-id-iugu="${element.client_id_iugu}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id-iugu="${element.invoice_id_iugu}" data-client-id-iugu="${element.client_id_iugu}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editBilling">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#concilationModal">
                      <span class="mc-tooltip">
                        <span>${element.customer_name}</span>
                        <span class="mc-tooltiptext">${element.client_id_iugu}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.due_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.occurrence_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${element.paid_at_iugu_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${latePayment}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${this.controllerNumber.currencyOptionMask(element.total_cents / 100)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#concilationModal">${this.controllerNumber.currencyOptionMask(element.total_paid_cents / 100)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      <span class="mc-tooltip grab">
                        <span>${conciliated}</span>
                        <span class="mc-tooltiptext">${element.invoice_id_iugu}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  setTotalBillings() {
    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (this.current_day < 10) {
      var currentDay = `0${this.current_day}`
    } else {
      var currentDay = `${this.current_day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    var current_billings = []
    this.application.billings.forEach(element => {
      if (this.current_day) {
        if (element.paid_date_token == currentToken) {
          current_billings[current_billings.length] = element
        }
      } else {
        if (this.application.current_date.id == element.date_id) {
          current_billings[current_billings.length] = element
        }
      }
    })

    var totalBillings = 0
    var conciliatedBillings = 0
    current_billings.forEach(element => {
      totalBillings += 1
      if (element.conciliated) {
        conciliatedBillings += 1
      }
    })

    this.billingCountTarget.innerText = `Todas as Faturas ${totalBillings}/${conciliatedBillings} Fatura(s) Conciliadas(s)`
  }

  concilationModal(ev) {
    // var element = ev.currentTarget.dataset.element
    var invoiceIdIugu = ev.currentTarget.closest(`.itemRow`).dataset.idIugu
    var controllerModal = `financials--books--receivables--conciliations--modal--main`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`${controllerModal}`).iugu_billings = controller.current_billings
      controller.getControllerByIdentifier(`${controllerModal}`).controllerForm = controller
      controller.getControllerByIdentifier(`${controllerModal}`).invoice_id_iugu = invoiceIdIugu
      controller.getControllerByIdentifier(`${controllerModal}`).open()
    })
  }

  readInvoice() {
    var controllerModal = `financials--books--receivables--conciliations--modal--invoice`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`${controllerModal}`).open()
    })
  }

  downloadReceivedBillings() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, billing: {} }

    this.send_data.billing.received_date_id = this.application.current_date.id
    this.send_data.billing.kind = `received`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-faturas-recebimento`
      this.send_data.billing.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-faturas-recebimento-fiscal`
      this.send_data.billing.med_id = ``
    }

    this.requestDownload(fileName)
  }

  downloadAccrualBillings() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, billing: {} }

    this.send_data.billing.date_id = this.application.current_date.id
    this.send_data.billing.kind = `accrual`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-faturas-competência`
      this.send_data.billing.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-faturas-competência`
      this.send_data.billing.med_id = ``
    }

    this.requestDownload(fileName)
  }

  downloadReturnedBillings() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, billing: {} }

    this.send_data.billing.returned_date_id = this.application.current_date.id
    this.send_data.billing.kind = `returned`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-faturas-liquidacao`
      this.send_data.billing.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-faturas-liquidacao`
      this.send_data.billing.med_id = ``
    }

    this.requestDownload(fileName)
  }

  requestDownload(fileName) {
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/financials/integrations/bankings/iugu/billings/download_billings"
    var fileExtension = `csv`
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyReceivedBillings() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, billing: {} }

    this.send_data.billing.received_date_id = this.application.current_date.id
    if (this.application.current_med.id) {
      this.send_data.billing.kind = `with_med`
      this.send_data.billing.med_id = this.application.current_med.id
    } else {
      this.send_data.billing.kind = `without_med`
      this.send_data.billing.med_id = ``
    }

    var r = confirm(`Tem certeza que deseja Apagar as Faturas Recebidas?`)
    if (r) {
      var r2 = confirm(`Tem certeza mesmo?`)
      if (r2) {
        this.requestDestroy()
      }
    }
  }

  requestDestroy() {
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/financials/integrations/bankings/iugu/billings/destroy_batch_billings"

    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {

          response.data.cln.forEach(billing => {
            controller.application.billings.forEach(element => {
              if (element.id == billing.id) {
                controller.application.billings.splice(controller.application.billings.indexOf(element), 1)
              }
            })
          })

        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  checkForBindSum() {
    this.kind = `sum_billings`
    this.generalCheckDivTarget.classList.remove(`d-none`)
    this.generalCheckTarget.checked = false
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  generalBindSum(ev) {

    if (ev.currentTarget.checked) {
      this.multipleCheckTargets.forEach(check => {
        if (check.closest(`.itemRow`).style.display == "") {
          check.checked = true
        }
      })
    } else {
      this.multipleCheckTargets.forEach(check => {
        check.checked = false
      })
    }

    this.bindSum()

  }

  bindSum() {
    var bindSum = 0
    var bindCount = 0
    var billingArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        billingArray[billingArray.length] = Number(check.dataset.id)
      }
    })

    this.application.billings.forEach(billing => {
      if (billingArray.includes(billing.id)) {
        bindSum += Number(billing.total)
        bindCount += 1
      }
    })

    this.bindSumTarget.innerText = `Total de Faturas: ${this.controllerNumber.numberMask(bindCount)} | Valor Total das Fatuas Selecionadas: ${this.controllerNumber.currencyOptionMask(bindSum)}`
  }

  refreshBillings() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { billing: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/integrations/bankings/iugu/billings/list_by_date"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentBillingIds = controller.mapElements(controller.application.billings, `id`)
          var refreshBillingIds = controller.mapElements(response.data.cln, `id`)

          controller.application.billings.forEach(billing => {
            if (currentBillingIds.includes(billing.id) && !refreshBillingIds.includes(billing.id) && billing.date_id == this.application.current_date.id) {
              controller.application.billings.forEach((element, i) => {
                if (element.id == billing.id) {
                  controller.application.billings.splice(controller.application.billings.indexOf(element), 1)
                }
              })
            }
          })

          response.data.cln.forEach(billing => {
            if (currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings.forEach((element, i) => {
                if (element.id == billing.id) {
                  controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
                }
              })
            } else if (!currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings[controller.application.billings.length] = billing
            }
          })
        } else if (response.data.cln.length == 0) {
          controller.application.billings.forEach((element, i) => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.billings.splice(controller.application.billings.indexOf(element), 1)
            }
          })
        }

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  filterNotConciliated() {
    this.hasFilterNotConciliated = true
    this.doDataTable()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
