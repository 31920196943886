import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "footer", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body",
                    "iuguId", "iuguEmail", "saveBtn"]

  connect() {
    this.controllerName = `financials--books--receivables--clients--iugu-modal`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }


  doFormHtml() {

    var footerHtml = `<button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${this.controllerName}#close">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-${this.controllerName}-target="saveBtn" data-action="click->${this.controllerName}#saveClient">Salvar</button>`

    var html = `<div class="row my-2 d-flex align-items-center">
                  <div class="col-12 mb-3 d-flex align-items-center">
                    <div class="form-group w-100 mb-0">
                      <div class="floating-label floating-label-sm">
                        <label for="iuguIdForm">Iugu ID</label>
                        <input id="iuguIdForm" class="form-control f-075 pt-0" data-${this.controllerName}-target="iuguId" type="text" placeholder="Iugu ID" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-3 d-flex align-items-center">
                    <div class="form-group w-100 mb-0">
                      <div class="floating-label floating-label-sm">
                        <label for="iuguEmailForm">Iugu Email</label>
                        <input id="iuguEmailForm" class="form-control f-075 pt-0" data-${this.controllerName}-target="iuguEmail" type="text" placeholder="Iugu Email" required>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      
      controller.iuguIdTarget.value = controller.iuguId
      controller.iuguEmailTarget.value = controller.iuguEmail

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()

      controller.footerTarget.innerHTML = footerHtml
    })
  }

  saveClient() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true
    this.send_data = { current_user: {}, client: {} }

    this.send_data.client.id = this.current_client.id
    this.send_data.client.index = this.index
    this.send_data.client.iugu_id = this.iuguIdTarget.value.trim()
    this.send_data.client.iugu_email = this.iuguEmailTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.feature

    this.requestSave()
  }

  requestSave() {
    const url = "/financials/books/clients/entities/update_iugu_account"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        if (response.save) {
          var client = response.data.cln

          controller.controllerForm.current_client = client
          controller.controllerForm.doSubAccountsTable()
        }

        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}