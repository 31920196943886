import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "title", "body", "saveBtn", "fileName", "leaderSquadDropdown", "leaderSquadInput", "kindSquadDropdown", "kindSquadInput", "memberSquadDropdown", "memberSquadInput",
    "leaderList", "kindList", "membersList", "name", "description"]

  connect() {
    this.controllerName = `users--squads--modal`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  changeInput() {
    var fileInput = document.querySelector('input[type=file]');
    var images = fileInput.files;
    for (var i = 0; i < images.length; i++) {
      $('.fileNameForm').append('<span class="mx-2">(' + (i + 1) + ') ' + images[i].name + ' | ' + parseInt(images[i].size / 1000000) + ' Mb</span><br>')
    }
  }

  saveSquad() {
    // this.saveBtnTarget.disabled = true
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/squads/entities/create"
    const data = { 
                  squad: {
                            name: this.nameTarget.value,
                            description: this.descriptionTarget.value,
                            kind: this.kindSquadInputTarget.dataset.kind,
                            leader_name: this.leaderSquadInputTarget.innerText,
                            leader_id: this.leaderSquadInputTarget.dataset.userid,
                            member_ids: this.memberSquadInputTarget.dataset.userids
                          },
                  current_user: {
                            current_user_id: currentUser.id
                          }
                  }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var modalController = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          modalController.close()
          var squad = response.data.cln
          var controller = document.querySelector(`#squadContentIndexRow`).usersSquadsIndex
          const html = controller.squadPartial(squad)
          controller.bodyTableTarget.insertAdjacentHTML("beforeend", html)
          allSquadList[allSquadList.length] = squad
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        } else {
          this.saveBtnTarget.disabled = false
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })

  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    // this.modalHtml()

    var leaderListHtml = ``
    var membersListHtml = ``
    allUsers.forEach(user => {
      leaderListHtml += `<li data-user-id="${user.id}" class="li-selector dark">${user.nickname}</li>`
      membersListHtml += `<li data-user-ids="${user.id}" class="li-selector dark">${user.nickname}</li>`
    });
    this.leaderListTarget.innerHTML = leaderListHtml
    this.membersListTarget.innerHTML = membersListHtml
    floatingLabel();
    selector()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  modalHtml() {
    var html = `<div class="modal fade" data-controller="${this.controllerName}" data-${this.controllerName}-target="modal" id="squadModal" tabindex="-1" role="dialog" aria-labelledby="squadModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-bottom">
                    <h6 class="modal-title"><strong>Nova Squad</strong></h6><br>
                    <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body p-3" data-${this.controllerName}-target="body">
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group form-valid-group" is-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="nameSquadForm">Nome</label>
                            <input aria-describedby="nameSquadFormHelp" class="form-control form-valid-control" id="nameSquadForm" data-${this.controllerName}-target="name" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group board-task-filter my-0">
                          <div class="floating-label floating-label-sm">
                            <label>Líder</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="leaderSquadDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="leaderSquadInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="leaderList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group board-task-filter my-0">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="kindSquadDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="kindSquadInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList">
                                  <li data-kind="sales" class="li-selector dark">Vendas</li>
                                  <li data-kind="marketing" class="li-selector dark">Marketing</li>
                                  <li data-kind="customer_success" class="li-selector dark">Sucesso do Cliente</li>
                                  <li data-kind="support" class="li-selector dark">Atendimento</li>
                                  <li data-kind="accounting" class="li-selector dark">Controle</li>
                                  <li data-kind="billing" class="li-selector dark">Recebimento</li>
                                  <li data-kind="people" class="li-selector dark">Pessoas</li>
                                  <li data-kind="finance" class="li-selector dark">Financeiro</li>
                                  <li data-kind="legal" class="li-selector dark">Jurídico</li>
                                  <li data-kind="development" class="li-selector dark">Tecnologia</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group form-valid-group" is-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionSquadForm">Descrição</label>
                            <input aria-describedby="descriptionSquadFormHelp" class="form-control form-valid-control" id="descriptionSquadForm" data-${this.controllerName}-target="description" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group board-task-filter my-0">
                          <div class="floating-label floating-label-sm">
                            <label>Membros</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector multiple" data-${this.controllerName}-target="memberSquadDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" data-${this.controllerName}-target="memberSquadInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="membersList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer border-top">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>
                    <button type="button" class="btn btn-primary" data-action="click->${this.controllerName}#saveSquad" data-${this.controllerName}-target="saveBtn">Salvar</button>
                  </div>
                </div>
              </div>
            </div>`
    new Promise(function (resolve) {
      resolve($('body').append(html))
    }).then(() => {
      document.querySelector('#squadModal').usersSquadsModal.open()
    })
  }



}