import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "addBoardBtn", "side", "report", "indicators",
                    "dateDropdown", "dateInput", "dateList", "list", "frozenBtn", "activeBtn"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `users--works--meetings--dashboard--index`
    this.application.permissions = {}
    this.application.boards = []
    this.filter = `active`
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--meetings--dashboard--xx
                                                            users--works--meetings--reports--main
                                                            users--works--permissions--entities--list-users
                                                            users--works--permissions--entities--user" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.teamDashboard()
    })
  }

  addBoard() {
    this.getControllerByIdentifier("users--works--meetings--dashboard--save").actionMode = `new`
    this.getControllerByIdentifier("users--works--meetings--dashboard--save").doFormHtml()
  }

  editBoard(ev) {
    var id = ev.currentTarget.closest(".boardCard").dataset.id

    this.application.boards.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("users--works--meetings--dashboard--save").current_board = element
        this.getControllerByIdentifier("users--works--meetings--dashboard--save").actionMode = `edit`
        this.getControllerByIdentifier("users--works--meetings--dashboard--save").doFormHtml()
      }
    })
  }

  teamDashboard() {
                  `<div class="row my-2">
                    <div class="col-12" data-${this.controllerName}-target="indicators"></div>
                  </div>`
    var html = `
                  <div class="row my-2">
                    <div class="col-8" data-${this.controllerName}-target="list"></div>
                    <div class="col-4" data-${this.controllerName}-target="side" data-controller="users--works--meetings--dashboard--save"></div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.contentTarget.insertAdjacentHTML("beforeend", html))
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.boardPreloader()
      if (this.application.boards.length == 0) {
        controller.getCurrentUserPermissions()
      } else {
        controller.doBoardsHtml()
      }
    }) 
  }

  doBoardsHtml() {

    var current_boards = []
    this.application.boards.forEach(board => {
      if (board.status == this.filter) {
        current_boards[current_boards.length] = board
      }
    });

    var cards = ``
    if (current_boards.length > 0) {
      current_boards.forEach(element => {
        if (element.leader_id == this.application.current_user.account_id) {
          cards += `<div class="col-3 px-2 my-2">
                    <div class="card border-top-primary boardCard" data-id="${element.id}">
                      <div class="card-header p-1 text-center f-065 d-flex">
                        <span class="ml-auto">1:1 com ${element.team_name}</span>
                        <span class="mc-tooltip ml-auto pointer" data-${this.controllerName}-target="editBoardBtn" data-action="click->${this.controllerName}#editBoard">
                           <span class="material-icons md-sm md-dark">edit</span>
                           <span class="mc-tooltiptext">Editar Semanal</span>
                         </span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/semanal/${element.slug}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">event_available</span>
                        <span class="mc-tooltiptext">1:1 com ${element.team_name}</span>
                      </div>
                    </div>
                  </div>`
        } else if (element.team_id == this.application.current_user.account_id) {
          cards += `<div class="col-3 px-2 my-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-065">
                        <span>1:1 com ${element.leader_name}</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/semanal/${element.slug}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">event_available</span>
                        <span class="mc-tooltiptext">1:1 com ${element.leader_name}</span>
                      </div>
                    </div>
                  </div>`
        }
      })
    } else {
      cards += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>Não há Salas de Semanais</span>
                      </div>
                    </div>
                  </div>`
    }

    if (this.application.permissions.work_meeting_boards.can_create) {
      var addBoardBtn = `<span class="mc-tooltip pointer" data-action="click->${this.controllerName}#addBoard" data-${this.controllerName}-target="addBoardBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Semanal</span>
                          </span>`
    } else {
      var addBoardBtn = ``
    }

    var frozenBtn = `<span class="mc-tooltip ml-auto pointer mx-3" data-action="click->${this.controllerName}#showFrozen" data-${this.controllerName}-target="frozenBtn">
                       <span class="material-icons md-sm md-dark">toggle_on</span>
                       <span class="mc-tooltiptext">Ver Congelados</span>
                     </span>`
    var activeBtn = `<span class="mc-tooltip ml-auto pointer mx-3" data-action="click->${this.controllerName}#showActive" data-${this.controllerName}-target="activeBtn">
                       <span class="material-icons md-sm md-dark">toggle_off</span>
                       <span class="mc-tooltiptext">Ver Ativos</span>
                     </span>`

    var html = `<h6 class="mb-0 d-flex align-items-center">
                  <span>Minhas Semanais 1:1</span>
                  ${frozenBtn}
                  ${activeBtn}
                  ${addBoardBtn}
                </h6>
                <hr class="mt-1">
                <div class="row my-3">
                  ${cards}
                </div>`

    this.listTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      if (controller.filter == `active`) {
        this.frozenBtnTarget.classList.remove("d-none")
        this.activeBtnTarget.classList.add("d-none")
      } else if (controller.filter == `frozen`) {
        this.frozenBtnTarget.classList.add("d-none")
        this.activeBtnTarget.classList.remove("d-none")
      }
    })
  }

  showFrozen() {
    this.frozenBtnTarget.classList.add("d-none")
    this.activeBtnTarget.classList.remove("d-none")
    this.filter = `frozen`
    this.doBoardsHtml()
  }

  showActive() {
    this.frozenBtnTarget.classList.remove("d-none")
    this.activeBtnTarget.classList.add("d-none")
    this.filter = `active`
    this.doBoardsHtml()
  }


  getNewReport() {
    this.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.getReport()
  }

  doIndicatorsHtml() {

    var html = `<div class="col-3">
                    <div class="row d-flex align-items-center">
                      <div class="col-8">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input aria-describedby="dateFormHelp" class="form-control" id="dateForm" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <button type="button" class="btn btn-secondary btn-sm p-0 btn-table f-065" data-action="click->${this.controllerName}#getNewReport"><span class="material-icons md-sm md-dark">search</span></button>
                      </div>
                    </div>
                  </div>
                <div class="col-3 pr-2">
                  <div class="card border-top-primary">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span># 1:1</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-5 text-right px-1">
                          total
                        </div>
                        <div class="col-6 offset-1 text-left f-1p25 px-1">
                          ${this.application.reports.weeklies}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 px-2">
                  <div class="card border-top-primary">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span>Tópicos</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-5 text-right px-1">
                          total
                        </div>
                        <div class="col-6 offset-1 text-left f-1p25 px-1">
                          ${this.application.reports.topics}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 pl-2">
                  <div class="card border-top-primary">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span>Tickets</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-5 text-right px-1">
                          total
                        </div>
                        <div class="col-6 offset-1 text-left f-1p25 px-1">
                          ${this.application.reports.tickets}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.reportTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.reportTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dateInputTarget), "year", date.getFullYear())
      controller.dateInputTarget.value = controller.application.reports.date_pretty
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }) 
  }

  reportPreloader() {
    var html = `<div class="row my-3 d-flex justify-content-around" data-${this.controllerName}-target="report">
                  <div class="col-3">
                    <div class="row d-flex align-items-center">
                      <div class="col-8">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input aria-describedby="dateFormHelp" class="form-control" id="dateForm" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <button type="button" class="btn btn-secondary btn-sm p-0 btn-table f-065" data-action="click->${this.controllerName}#getNewReport"><span class="material-icons md-sm md-dark">search</span></button>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    ${this.cardLoader}
                  </div>
                  <div class="col-3">
                    ${this.cardLoader}
                  </div>
                  <div class="col-3">
                    ${this.cardLoader}
                  </div>
                </div>
              </div>`

    // this.indicatorsTarget.innerHTML = html
  }

  boardPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-3 px-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.cardLoader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.listTarget.innerHTML = html
  }

  finishLoadPage() {
    this.doBoardsHtml()
    this.getControllerByIdentifier("users--works--meetings--dashboard--submenu").doSubmenuHtml()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getBoards() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Semanais`, value: 80 })

    const data = { board: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    const url = "/users/works/meetings/boards/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.boards = response.data.cln

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time MedCapital`, value: 55 })

    const url = "/users/works/meetings/boards/list_team"
    const data = { user: { account_kind: "team" }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team = response.data.cln

        controller.getBoards()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_meetings` }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`work_meeting_permissions`]

        if (controller.application.permissions.work_meeting_boards.can_create) {
          controller.getTeam()
        } else {
          controller.getBoards()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
