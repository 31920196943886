import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["weeklyVisits", "view", "main", "chartPreloader", "weeklyVisitsTitleChart", "weeklyVisitsChart", "submenu", "indicatorsCards",
    "sessionsBodyTable", "interactionsBodyTable", "leadsBodyTable", "9999", "9999", "9999", "9999", "9999", "9999"]



  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--visits--pageviews--summary`
  }

  doSummaryGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8" data-${this.controllerName}-target="weeklyVisits"></div>
                  <div class="col-4" data-${this.controllerName}-target="indicatorsCards">
                    <div class="col-12 mb-3 px-1">
                      ${this.cardLoader}
                    </div>
                    <div class="col-12 mb-3 px-1">
                      ${this.cardLoader}
                    </div>
                    <div class="col-12 mb-3 px-1">
                      ${this.cardLoader}
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--visits--pageviews--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doWeeklyVisitsArea()
    })
  }

  doWeeklyVisitsArea() {
    var html = `<div class="row w-100">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="weeklyVisitsTitleChart"></h6>
                            <div class="d-flex align-items-center justify-content-center" style="height:250px;" data-${this.controllerName}-target="weeklyVisitsChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="dailyChartBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.weeklyVisitsTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  doIndicatorsCards() {
    var html = `<div class="col-12 mb-2 px-0">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title-sm f-085">Sessões</h6>
                      <table class="table table-borderless table-hover table-sm">
                        <tbody data-${this.controllerName}-target="sessionsBodyTable"></tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2 px-0">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title-sm f-085">Interações</h6>
                      <table class="table table-borderless table-hover table-sm">
                        <tbody data-${this.controllerName}-target="interactionsBodyTable"></tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2 px-0">
                  <div class="card">
                    <div class="card-body">
                      <h6 class="card-title-sm f-085">Conversões</h6>
                      <table class="table table-borderless table-hover table-sm">
                        <tbody data-${this.controllerName}-target="leadsBodyTable"></tbody>
                      </table>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorsCardsTarget.innerHTML = html)
    }).then(() => {
      controller.sessionsBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(3, 2))
      controller.interactionsBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(3, 2))
      controller.leadsBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(3, 2))
      controller.requestSessionsTableData()
      controller.requestInteractionsTableData()
      controller.requestLeadsTableData()
    })
  }

  requestSessionsTableData() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/visits/pageviews/sessions_comparison"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var data = response.data.cln[0]
          this.sessionsBodyTableTarget.innerHTML = this.sessionsTablePartial(data)
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }

      })
  }

  sessionsTablePartial(data) {
    if (data.comparison_today > 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_today_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today == 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_today_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today < 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_today_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    if (data.comparison_week > 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_week_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week == 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_week_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week < 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.visits_week_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    var html = `<tr>
                  <th class="table-40">Hoje</th>
                  <td class="table-20">${data.visits_today_value}</td>
                  <td class="table-40 text-right">${comparisonToday}</td>
                </tr>
                <tr>
                  <th>Últimos 7 Dias</th>
                  <td>${data.visits_week_value}</td>
                  <td class="text-right">${comparisonWeek}</td>
                </tr>`

    return html
  }

  requestInteractionsTableData() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/visits/pageviews/interactions_comparison"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var data = response.data.cln[0]
          this.interactionsBodyTableTarget.innerHTML = this.interactionsTablePartial(data)
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  interactionsTablePartial(data) {
    if (data.comparison_today > 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_today_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today == 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_today_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today < 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_today_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    if (data.comparison_week > 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_week_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week == 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_week_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week < 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.interactions_week_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    var html = `<tr>
                  <th class="table-40">Hoje</th>
                  <td class="table-20">${data.interactions_today_value}</td>
                  <td class="table-40 text-right">${comparisonToday}</td>
                </tr>
                <tr>
                  <th>Últimos 7 Dias</th>
                  <td>${data.interactions_week_value}</td>
                  <td class="text-right">${comparisonWeek}</td>
                </tr>`

    return html
  }

  requestLeadsTableData() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/visits/pageviews/leads_comparison"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var data = response.data.cln[0]
          this.leadsBodyTableTarget.innerHTML = this.leadsTablePartial(data)
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  leadsTablePartial(data) {
    if (data.comparison_today > 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_today_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today == 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_today_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_today < 0) {
      var comparisonToday = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_today_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    if (data.comparison_week > 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-success">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_week_percentage) * 10000))}</span>
                              <span class="material-icons md-success md-sm">north</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week == 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-dark">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_week_percentage) * 10000))}</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    } else if (data.comparison_week < 0) {
      var comparisonWeek = `<span class="mc-tooltip default">
                              <span class="f-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(Math.abs(data.leads_week_percentage) * 10000))}</span>
                              <span class="material-icons md-danger md-sm">south</span>
                              <span class="mc-tooltiptext">Comparação período anterior</span>
                            </span>`
    }

    var html = `<tr>
                  <th class="table-40">Hoje</th>
                  <td class="table-20">${data.leads_today_value}</td>
                  <td class="table-40 text-right">${comparisonToday}</td>
                </tr>
                <tr>
                  <th>Últimos 7 Dias</th>
                  <td>${data.leads_week_value}</td>
                  <td class="text-right">${comparisonWeek}</td>
                </tr>`

    return html
  }

  requestChartData() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/visits/pageviews/sessions_week_comparison"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.chartPreloaderTarget.remove()
        this.weeklyVisitsTitleChartTarget.innerText = `Visitas & Sessões`
        if (response.process) {
          new Chartkick["LineChart"](this.weeklyVisitsChartTarget, [response.data.cln[0], response.data.cln[1]], {
            "colors": ["#086BAC", "#84D2F6"],
            "download": true,
            "prefix": "",
            "thousands": ".",
            "decimal": ","
          })
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  doChartPreloader() {
    this.weeklyVisitsTitleChartTarget.innerHTML = `${this.loader}`
    var chartPreloader = `<div class="loading" data-${this.controllerName}-target="chartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.weeklyVisitsChartTarget.innerHTML = chartPreloader)
    }).then(() => {
      controller.requestChartData()
      controller.doIndicatorsCards()
    })
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })


}
