import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--footer`
  }

  doHtml() {
    var serviceHtml = ``
    var colClass = ``
    var colLogoClass = ``
    var current_services = []

    this.application.site.services.forEach(service => {
      if (service.status) {
        current_services[current_services.length] = service
      }
    })
    current_services = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_services, `order`)

    current_services.forEach(service => {
      if (this.getControllerByIdentifier(`web--site--principal--main`)) {
        var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(service.link_footer)
      } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`)) {
        var link = this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`).setLink(service.link_footer)
      } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`)) {
        var link = this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`).setLink(service.link_footer)
      } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
        var link = this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).setLink(service.link_footer)
      }

      serviceHtml += `<p> <a class="ws-text-footer pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${service.title}</a> </p>`
    })

    if (this.application.size == `large`) {
      colClass += `col-3`
      colLogoClass += `col-3 text-center`
    } else if (this.application.size == `medium`) {
      colClass += `col-3`
      colLogoClass += `col-3 text-center`
    } else if (this.application.size == `small`) {
      colClass += `col-4`
      colLogoClass += `col-12 text-center my-5`
    }

    // <p> <a class="ws-text-footer pointer" href="/vagas" target="blank">Faça parte do time</a> </p>

    var html = `<section class="ws-footer">
                  <div class="container">
                    <div class="row">
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Serviços</h1>
                          ${serviceHtml}
                      </div>
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Contatos</h1>
                        <p> <a class="ws-text-footer pointer" href="mailto:atendimento@medcapital.com.br?Subject=Olá!">atendimento@medcapital.com.br</a> </p>
                        <p> <a class="ws-text-footer pointer" data-action="click->${this.controllerName}#goToWhatsApp">Converse conosco no WhatsApp</a> </p>
                        
                      </div>
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Conteúdos</h1>
                        <p> <a class="ws-text-footer" target="blank" href="/descanso-medico">Descanso Médico</a> </p>
                        <p> <a class="ws-text-footer" target="blank" href="/medscholl">MedSchool</a> </p>
                      </div>
                      <div class="${colLogoClass}">
                        <a class="navbar-brand mr-5 " href="/">
                          <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                        </a>
                      </div>
                    </div>
                    <div class="ws-cop">
                      <div class="row">
                        <p class="ws-cop-text"> Copyright © 2016-2024 MedCapital CNPJ 23.448.747/0001-02. Todos os direitos reservados. </p> <br>
                        <p class="ws-cop-text"> Feito com <span class="material-icons md-danger">favorite</span> e <span class="material-icons-two-tone">coffee</span> em Belo Horizonte-MG. </p>
                        <div>
                          <a class="ws-icon-footer" href="/descanso-medico" target="blank"><img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/Prancheta+1.png" alt="descancomedico" style="width:7%;cursor:pointer;"></a>
                          <a class="ws-icon-footer" href="https://www.facebook.com/medcap/" target="blank"><i class="fab fa-facebook-f white-text mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://www.linkedin.com/company/medcapital/" target="blank"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://www.instagram.com/medcapital/" target="blank"><i class="fab fa-instagram white-tex mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://api.whatsapp.com/send?phone=5531995181912&amp;text=Olá!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    }
  }

  goToWhatsApp() {
    var url = `https://api.whatsapp.com/send?phone=5531995181912&text=Olá!`
    window.open(url, `_blank`)
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-footer">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <h1 class="ws-title-footer">Serviços</h1>
                          
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Controle PJ Médica</a> </p>
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Faturamento de Convênios</a> </p>
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Declaração de Imposto de Renda</a> </p>
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Controle Livro-Caixa</a> </p>
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Seguros e Proteção</a> </p>
                          <p> <a class="ws-text-footer" href="https://conteudo.medcapital.com.br/consultoria">Departamento Pessoal</a> </p>
                      </div>
                      <div class="col">
                        <h1 class="ws-title-footer">Contatos</h1>
                        <p> <a class="ws-text-footer" href="">atendimento@medcapital.com.br</a> </p>
                        <p> <a class="ws-text-footer" href="">Converse conosco no WhatsApp</a> </p>
                        <p> <a class="ws-text-footer" href="">Faça parte do time</a> </p>
                      </div>
                      <div class="col">
                        <h1 class="ws-title-footer">Conteúdos</h1>
                        <p> <a class="ws-text-footer" href="">Descanso Médico</a> </p>
                        <p> <a class="ws-text-footer" href="">MedSchool</a> </p>
                      </div>
                      <div class="col">
                        <a class="navbar-brand mr-5 " href="/">
                          <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                        </a>
                      </div>
                    </div>
                    <div class="ws-cop">
                      <div class="row">

                        <p class="ws-cop-text"> Copyright © 2016-2024 MedCapital CNPJ 23.448.747/0001-02. Todos os direitos reservados. Feito com <span class="material-icons md-danger">favorite</span> e <span class="material-icons-two-tone">coffee</span> em Belo Horizonte-MG. </p>
                        <div>
                          <a class="ws-icon-footer" href="/descanso-medico" target="blank"><img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/Prancheta+1.png" alt="descancomedico" style="width:7%;cursor:pointer;"></a>
                          <a class="ws-icon-footer" href="https://www.facebook.com/medcap/" target="blank"><i class="fab fa-facebook-f white-text mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://www.linkedin.com/company/medcapital/" target="blank"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://www.instagram.com/medcapital/" target="blank"><i class="fab fa-instagram white-tex mr-2"> </i> </a>
                          <a class="ws-icon-footer" href="https://api.whatsapp.com/send?phone=5531995181912&amp;text=Olá!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--operations--products--bookings--validations--main`)) {
      this.getControllerByIdentifier(`web--operations--products--bookings--validations--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
