import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "save", "view", "list", "content", "invoicesTitleChart", "chartPreloader", "invoicesChart", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--reports--logs--invoices`
    this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--logs--dashboard`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doInvoicesArea() {
    var html = `<div class="row w-100 mb-3">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="invoicesTitleChart"></h6>
                            <div class="d-flex align-items-center justify-content-center" style="height:250px;" data-${this.controllerName}-target="invoicesChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="dailyChartBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.dashboardController.invoicesTarget.insertAdjacentHTML("afterend", html))
      // resolve(controller.dashboardController.invoicesTarget.insertAdjacentHTML("beforeend", html))
      resolve(controller.dashboardController.invoicesTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  doChartPreloader() {
    // this.getControllerByIdentifier("operations--products--clinics--financials--index--reports--dashboard").contentTarget.innerHTML = `${this.loader}`
    this.invoicesTitleChartTarget.innerHTML = `${this.loader}`
    var chartPreloader = `<div class="loading" data-${this.controllerName}-target="chartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.invoicesChartTarget.innerHTML = chartPreloader)
    }).then(() => {
      controller.doChart()
    })
  }

  doChart() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/clinics/reports/logs/invoice_activities"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.chartPreloaderTarget.remove()
        controller.invoicesTitleChartTarget.innerText = `Emissores de NFs`
        var chartData = [response.data.cln[0], response.data.cln[1]]
        if (response.process) {
          new Chartkick["LineChart"](controller.invoicesChartTarget, response.data.cln, {
            "colors": ["#086BAC", "#84D2F6"],
            "download": false,
            "prefix": "",
            "thousands": ".",
            "decimal": ","
          })
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
