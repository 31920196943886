import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addClientBtn", "pendencyCount", "sideBarReport", "clearListBtn", "optionBtn", "clientCheckBox"]

  connect() {
    this.controllerName = `financials--books--clients--entities--index`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pendencies = 0
    this.pageNumber = 0
    this.tableCols = 7
    this.numberPages = []
    this.updateContract = false
    this.sort = { mode: `asc`, field: `record_name` }
  }

  addClient() {
    this.getControllerByIdentifier("financials--books--clients--entities--save").controllerDashboard = this.controllerDashboard
    // this.getControllerByIdentifier("financials--books--clients--entities--save").gridElement = this.gridElement
    this.getControllerByIdentifier("financials--books--clients--entities--save").current_client = {}
    this.getControllerByIdentifier("financials--books--clients--entities--save").actionMode = `new`
    this.getControllerByIdentifier("financials--books--clients--entities--save").doFormHtml()
  }

  addContract(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.clients.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--clients--contracts--save").controllerDashboard = this.controllerDashboard
        this.getControllerByIdentifier("financials--books--clients--contracts--save").permission = `financial_settings_clients`
        // this.getControllerByIdentifier("financials--books--clients--contracts--save").gridElement = this.gridElement
        this.getControllerByIdentifier("financials--books--clients--contracts--save").current_client = element
        this.getControllerByIdentifier("financials--books--clients--contracts--save").current_contract = {}
        this.getControllerByIdentifier("financials--books--clients--contracts--save").actionMode = `new`
        this.getControllerByIdentifier("financials--books--clients--contracts--save").doFormHtml()
      }
    })
  }

  editClient(ev) {
    var element = ev.currentTarget
    var clientId = element.closest(".itemRow").dataset.id

    this.clientCheckBoxTargets.forEach(checkbox => {
      checkbox.checked = false
    })

    element.checked = true

    this.application.clients.forEach(element => {
      if (element.id == clientId) {
        // this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
        this.getControllerByIdentifier("financials--books--clients--entities--save").current_client = element
        this.getControllerByIdentifier("financials--books--clients--entities--save").actionMode = `edit`
        this.getControllerByIdentifier("financials--books--clients--entities--save").getClient()
      }
    })
  }

  showClient(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.clients.forEach(element => {
      if (element.id == id) {
        // this.getControllerByIdentifier("financials--books--clients--show--dashboard").current_client = element
        // this.getControllerByIdentifier("financials--books--clients--show--dashboard").doClientDashboard()
      }
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doClientList() {
    var refreshLine = `<span data-action="click->${this.controllerName}#refreshClient" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Clientes</span>`

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addClient" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Cliente</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editLine = `<span data-action="click->${this.controllerName}#selectEditClient" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Cliente</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var contractLine = `<span data-action="click->${this.controllerName}#selectAddClientContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato de Cliente</span>`
    } else {
      var contractLine = ``
    }

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${editLine}
                          ${contractLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="row">
                  <div class="col-12">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto">Todos os Clientes</h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${clearBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-30 align-middle">
                                    Nome
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center" data-${this.controllerName}-target="pendencyCount"></th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">ID</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Total Contratos</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Integração</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Ações Integração</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier(`financials--books--clients--entities--dashboard`).listTarget.innerHTML = html)
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_clients = this.application.clients

    if (this.sort.mode == `asc`) {
      var clients = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_clients, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var clients = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_clients, this.sort.field)
    }

    if (clients.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(clients.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (clients[x] !== undefined) {
            internPage[internPage.length] = clients[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: clients.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Clientes</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.clientTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  clientTablePartial(element, length) {
    // verificar se pagamento vence no dia
    var noCalculation = true
    var invoiceAlert = ``
    // element.calculations.forEach(calculation => {
    //   if (calculation.date_id == this.application.current_date.id && noCalculation) {
    //     noCalculation = false
    //     if (calculation.has_invoice == false) {
    //       this.pendencies += 1
    //       invoiceAlert = `<div class="col-1 px-0 mc-tooltip pointer">
    //                     <span class="material-icons md-sm md-danger">warning</span>
    //                     <span class="mc-tooltiptext">Atenção! Cliente sem fatura!</span>
    //                   </div>`
    //     } else if (calculation.status_alert) {
    //       this.pendencies += 1
    //       invoiceAlert = `<div class="col-1 px-0 mc-tooltip pointer">
    //                     <span class="material-icons md-sm md-danger">warning</span>
    //                     <span class="mc-tooltiptext">Atenção! Cliente com fatura em aberto!</span>
    //                   </div>`
    //     } else {
    //       invoiceAlert = ``
    //     }
    //   } else {
    //     if (noCalculation) {
    //       invoiceAlert = ``
    //     }
    //   }
    // })

    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editClient" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.has_iugu_account) {

      var integrationStatus = `<span class="mc-tooltip grab"> 
                                <span class="material-icons md-250 md-success" data-controller="app--helpers--copy" data-copy="${element.id_iugu}" data-action="click->app--helpers--copy#copy">domain_verification</span>
                                <span class="mc-tooltiptext">Integrado! Clique para Copiar o ID Iugu</span>
                              </span>`

      if (this.application.permissions[this.permission].can_assign) {
        if (this.application.current_user.super_admin) {
          var destroyIntegration = `<button data-action="click->${this.controllerName}#destroyIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">clear</span>
                                    <span class="mc-tooltiptext">Apagar Integração</span>
                                  </button>`
        } else {
          var destroyIntegration = ``
        }

        var integrationActions = `<button data-action="click->${this.controllerName}#updateIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">refresh</span>
                                    <span class="mc-tooltiptext">Clique para Atualizar</span>
                                  </button>
                                  ${destroyIntegration}`
      } else {
        var integrationActions = `<span>Sem Ações</span>`
      }

    } else {
      var integrationStatus = `<span class="mc-tooltip"> 
                                  <span class="material-icons md-250 md-danger default">clear</span>
                                  <span class="mc-tooltiptext">Não Integrado</span>
                                </span>`

      if (this.application.permissions[this.permission].can_assign) {
        var integrationActions = `<button data-action="click->${this.controllerName}#createIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">launch</span>
                                    <span class="mc-tooltiptext">Clique para Integrar</span>
                                  </button>`
      } else {
        var integrationActions = `<span>Sem Ações</span>`
      }
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_client`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="check-${element.id} clientCheckBox" data-action="click->${this.controllerName}#editClient">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `new_contract`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="check-${element.id}" data-action="click->${this.controllerName}#addContract">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }


    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showClient">
                      <small class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.record_name}" data-action="click->app--helpers--copy#copy">${element.record_name}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${invoiceAlert}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left">
                      <small class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.record_number}" data-action="click->app--helpers--copy#copy">${element.record_number_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.calculation.total_contracts}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${integrationStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${integrationActions}</td>
                  </tr>`

    // if (this.pendencies > 0) {
    //   this.pendencyCountTarget.innerHTML = `<div class="col-1 px-0 mc-tooltip pointer">
    //                                           <span class="material-icons md-sm md-danger">warning</span>
    //                                           <span class="mc-tooltiptext mc-tooltiptext-right">${this.pendencies} cliente(s) com pendências</span>
    //                                         </div>`
    // }

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  selectEditClient() {
    this.kind = `edit_client`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  selectAddClientContract() {
    this.kind = `new_contract`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  destroyIntegration(ev) {
    this.actionMode = `delete`
    this.send_data = { current_user: {}, customer: {} }
    var clientId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data.customer.client_id = clientId
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveIntegration()
  }

  updateIntegration(ev) {
    this.actionMode = `edit`
    this.send_data = { current_user: {}, customer: {} }
    var clientId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data.customer.client_id = clientId
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveIntegration()
  }

  createIntegration(ev) {
    this.actionMode = `new`
    this.send_data = { current_user: {}, customer: {} }
    var clientId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data.customer.client_id = clientId
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_client_iugu_integration`

    this.requestSaveIntegration()
  }

  requestSaveIntegration() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/integrations/bankings/iugu/customers/create_integration"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/integrations/bankings/iugu/customers/update_integration"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/financials/books/integrations/bankings/iugu/customers/destroy_integration"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var client = response.data.cln
          controller.application.clients.forEach((element, i) => {
            if (element.id == client.id) {
              controller.application.clients.splice(controller.application.clients.indexOf(element), 1, client)
            }
          })
          controller.doDataTable()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshClient() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Atualizando Clientes!` })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_clients` } }
    const url = "/financials/books/clients/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clients = response.data.cln
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
