import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "simplePGDAS",
                    "dateDropdown", "dateDropdownBtn", "dateInput", "dateList", "regimeInput", "saveBtn",
                    "amountWithWithheldInput", "amountWithoutWithheldInput", "totalAmountInput", "totalAmountPayableInput", "totalPayrollInput", "saveBtn",
                    "irpjAmountInput", "csllAmountInput", "cofinsAmountInput", "pisAmountInput", "inssAmountInput", "issAmountInput",]

  connect() {
    this.controllerName = `operations--products--clinics--financials--taxes--create`
    
    if (this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
      this.mainController = this.getControllerByIdentifier("operations--products--clinics--financials--show--main")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--taxes--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--taxes--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
      this.mainController = this.getControllerByIdentifier("operations--products--clinics--clients--show--main")
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--clinics--financials--taxes--index").clearList()
  }

  saveTax() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, tax: {}, tracker: { log: true } }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.tax.id = this.current_tax.id
    }
    this.send_data.tax.clinic_id = this.application.clinic.id
    this.send_data.tax.date_id = this.dateInputTarget.dataset.id
    this.send_data.tax.regime_id = this.current_regime.id
    this.send_data.tax.tax_regime = this.current_regime.tax_regime

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `Guias Impostos ${this.current_regime.tax_regime_pretty} da ${this.application.clinic.company_name} da ${this.application.current_date.name}`
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/financials/taxes/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var taxes = response.data.cln
          var tax_ids = controller.mapElements(controller.application.taxes, `id`)
          taxes.forEach(tax => {
            if (tax_ids.includes(tax.id)) {
              controller.application.taxes.splice(controller.application.taxes.indexOf(tax), 1, tax)
            } else {
              controller.application.taxes[controller.application.taxes.length] = tax
            }
          })
          
          controller.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doTaxesCount()
          controller.getControllerByIdentifier("operations--products--clinics--financials--taxes--index").doDataTable()
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTax" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Competência</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dateList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="regimeForm">Regime Tributário</label>
                            <input disabled aria-describedby="regimeFormHelp" class="form-control form-valid-control" id="regimeForm" data-${this.controllerName}-target="regimeInput" placeholder="Regime Tributário" type="text" required>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="row" data-${this.controllerName}-target="simplePGDAS">
                      <h6 class="mb-0 text-center f-bold f-085">Declaração Mensal - PGDAS-D</h6>
                    </div>
                    <div class="row my-2" data-${this.controllerName}-target="simplePGDAS">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalAmountForm">Bruto Total</label>
                            <input disabled aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" placeholder="Bruto Total" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountWithoutWithheldForm">Bruto SEM Retenção</label>
                            <input disabled aria-describedby="amountWithoutWithheldFormHelp" class="form-control form-valid-control" id="amountWithoutWithheldForm" data-${this.controllerName}-target="amountWithoutWithheldInput" placeholder="Bruto SEM Retenção" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountWithWithheldForm">Bruto COM Retenção</label>
                            <input disabled aria-describedby="amountWithWithheldFormHelp" class="form-control form-valid-control" id="amountWithWithheldForm" data-${this.controllerName}-target="amountWithWithheldInput" placeholder="Bruto COM Retenção" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="simplePGDAS">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="irpjAmountForm">IRPJ à Pagar</label>
                            <input disabled aria-describedby="irpjAmountFormHelp" class="form-control form-valid-control" id="irpjAmountForm" data-${this.controllerName}-target="irpjAmountInput" placeholder="IRPJ à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="csllAmountForm">CSLL à Pagar</label>
                            <input disabled aria-describedby="csllAmountFormHelp" class="form-control form-valid-control" id="csllAmountForm" data-${this.controllerName}-target="csllAmountInput" placeholder="CSLL à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cofinsAmountForm">CONFINS à Pagar</label>
                            <input disabled aria-describedby="cofinsAmountFormHelp" class="form-control form-valid-control" id="cofinsAmountForm" data-${this.controllerName}-target="cofinsAmountInput" placeholder="CONFINS à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="pisAmountForm">PIS à Pagar</label>
                            <input disabled aria-describedby="pisAmountFormHelp" class="form-control form-valid-control" id="pisAmountForm" data-${this.controllerName}-target="pisAmountInput" placeholder="PIS à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="inssAmountForm">INSS à Pagar</label>
                            <input disabled aria-describedby="inssAmountFormHelp" class="form-control form-valid-control" id="inssAmountForm" data-${this.controllerName}-target="inssAmountInput" placeholder="INSS à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="issAmountForm">ISS à Pagar</label>
                            <input disabled aria-describedby="issAmountFormHelp" class="form-control form-valid-control" id="issAmountForm" data-${this.controllerName}-target="issAmountInput" placeholder="ISS à Pagar" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="simplePGDAS">
                      <div class="col-6 px-0">
                        <h6 class="mb-0 f-bold f-085">Folha de Salários</h6>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="totalPayrollForm"></label>
                              <input disabled aria-describedby="totalPayrollFormHelp" class="form-control form-valid-control" id="totalPayrollForm" data-${this.controllerName}-target="totalPayrollInput" placeholder="Total Folha de Salários" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-0">
                        <h6 class="mb-0 f-bold f-085">Total à Pagar</h6>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="totalAmountPayableForm"></label>
                              <input disabled aria-describedby="totalAmountPayableFormHelp" class="form-control form-valid-control" id="totalAmountPayableForm" data-${this.controllerName}-target="totalAmountPayableInput" placeholder="Total à Pagar" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.formTitleTarget.innerText = `Criando Impostos da Competência`

      controller.dateDropdownBtnTarget.disabled = true
      controller.dateDropdownTarget.value = controller.application.current_date.id
      controller.dateInputTarget.innerText = controller.application.current_date.name
      controller.dateInputTarget.dataset.id = controller.application.current_date.id
      controller.setRegime(controller.application.current_date.id)
      controller.setMainApuration(controller.application.current_date.id)

      if (controller.current_regime.tax_regime == `presumed_profit`) {
        
        controller.simplePGDASTargets.forEach(element => {
          element.classList.add(`d-none`)
        })

      } else if (controller.current_regime.tax_regime == `simple_national`) {

        controller.simplePGDASTargets.forEach(element => {
          element.classList.remove(`d-none`)
        })

        controller.totalAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.total_revenues)
        controller.amountWithWithheldInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.total_revenues_with_tax_withheld)
        controller.amountWithoutWithheldInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.total_revenues_without_tax_withheld)
        controller.irpjAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_irpj)
        controller.csllAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_csll)
        controller.cofinsAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_cofins)
        controller.pisAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_pis)
        controller.inssAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_inss)
        controller.issAmountInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.payable_iss)
        controller.totalPayrollInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.total_payroll)
        controller.totalAmountPayableInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_main_apuration.total_payable)
      }

      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.doDateList()
      controller.refreshSaveBtn()
    })
  }

  doDateList() {
    var listHtml = ``
    this.application.dates.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-year="${element.year}" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectDate" class="li-selector dark f-065">${element.name}</li>`
    });
    this.dateListTarget.innerHTML = listHtml
  }

  selectDate(ev) {
    var dateId = ev.currentTarget.dataset.id

    this.setRegime(dateId)
  }

  setRegime(dateId) {
    this.application.regimes.forEach(element => {
      if (element.date_id == dateId) {
        this.current_regime = element
      }
    })

    this.regimeInputTarget.value = this.current_regime.tax_regime_pretty
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setMainApuration(dateId) {
    this.application.main_apurations.forEach(element => {
      if (element.date_id == dateId) {
        this.current_main_apuration = element
      }
    })
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.hasFormCardTarget) {
          if (controller.dateInputTarget.innerText == ``) {
            len += 1
          }

          if (controller.regimeInputTarget.value == ``) {
            len += 1
          }

          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        } else {
          controller.stopRefreshing()
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
