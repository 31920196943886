
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dailyChart", "bodyChart", "chartPreloader", "bodyTable", "calendar", "list", "dailyChartCard", "tableCard", "table", "indicators",
                    "dateDropdown", "dateDropdownBtn", "dateInput", "dateList", "footerData", "titleChart", "mainCard", "footerTable", "chartBodyTable", "totalChartCard",
                    "dailyTitleChart", "accrualTitleChart", "dailyChartBodyTable", "accrualChartBodyTable", "bodyDailyChart", "bodyAccrualChart", "accrualChart",
                    "dailyChartPreloader", "accrualChartPreloader", "accrualChartCard", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]




  
  

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    var date = new Date()
    this.year = date.getFullYear()
    this.month = date.getMonth() + 1
    this.controllerName = `commercial--sales--opportunities--dealflow--report`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `finished_at_to_time` }
    this.application.permissions = {}
    this.application.opportunities = []
    this.application.all_opportunities = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-4 w-100">
                  <div class="col-6"></div>
                  <div class="col-2 pr-2">
                    <div class="card bg-primary pointer" data-${this.controllerName}-target="dailyChartCard" data-feature="daily" data-action="click->${this.controllerName}#toggleChartTable">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Evolução Por Dia</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="accrualChartCard" data-feature="accrual" data-action="click->${this.controllerName}#toggleChartTable">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Evolução Acumulada</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pl-2">
                    <div class="card pointer" data-${this.controllerName}-target="tableCard" data-feature="table" data-action="click->${this.controllerName}#toggleChartTable">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Ganhos</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" data-${this.controllerName}-target="dailyChart"></div>
                </div>
                <div class="row">
                  <div class="col-12 d-none" data-${this.controllerName}-target="accrualChart"></div>
                </div>
                <div class="row">
                  <div class="col-12 d-none px-0" data-${this.controllerName}-target="table">
                    <div class="row">
                      <div class="col-10 pr-2" data-${this.controllerName}-target="list"></div>
                      <div class="col-2 pl-2" data-${this.controllerName}-target="indicators">
                        <div class="col-12 mb-4">
                          ${this.cardLoader}
                        </div>
                        <div class="col-12 mb-4">
                          ${this.cardLoader}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
      controller.doDailyChartArea()
      controller.doAccrualChartArea()
      controller.doIndexListHtml()
    })
  }

  toggleChartTable(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "daily") {
      this.dailyChartCardTarget.classList.add("bg-primary")
      this.dailyChartTarget.classList.remove("d-none")
      this.accrualChartCardTarget.classList.remove("bg-primary")
      this.accrualChartTarget.classList.add("d-none")
      this.tableCardTarget.classList.remove("bg-primary")
      this.tableTarget.classList.add("d-none")
    } else if (feature == "accrual") {
      this.dailyChartCardTarget.classList.remove("bg-primary")
      this.dailyChartTarget.classList.add("d-none")
      this.accrualChartCardTarget.classList.add("bg-primary")
      this.accrualChartTarget.classList.remove("d-none")
      this.tableCardTarget.classList.remove("bg-primary")
      this.tableTarget.classList.add("d-none")
    } else if (feature == "table") {
      this.dailyChartCardTarget.classList.remove("bg-primary")
      this.dailyChartTarget.classList.add("d-none")
      this.accrualChartCardTarget.classList.remove("bg-primary")
      this.accrualChartTarget.classList.add("d-none")
      this.tableCardTarget.classList.add("bg-primary")
      this.tableTarget.classList.remove("d-none")
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Oportunidades Conquistadas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Token</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                  Leads
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center"># Leads</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                  SDR
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="prospector_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="prospector_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                  Closer
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="closer_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="closer_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">MRR Oportunidade</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">MRR Ganho</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Avulso</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                  Data Ganho
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="finished_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="finished_at_to_time" data-mode="asc">north</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
    })
  }

  doDataTable() {
    var current_opportunities = this.application.opportunities

    if (this.sort.mode == `asc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_opportunities, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_opportunities, this.sort.field)
    }

    if (opportunities.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(opportunities.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (opportunities[x] !== undefined) {
            internPage[internPage.length] = opportunities[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: opportunities.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="9" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Oportunidades</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.opportunityTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  opportunityTablePartial(element, length) {

    element.name = ``
    element.ticket_name = ``
    element.leads.forEach(lead => {
      element.ticket_name += `<p class="mb-0 f-065">${lead.lead_name}</p>`
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityLeadName">${lead.lead_name}</p>`
    })

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.prospector_name == null || element.prospector_name == undefined) {
      element.prospector_name = `Não Definido`
    }

    if (element.closer_name == null || element.closer_name == undefined) {
      element.closer_name = `Não Definido`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:60%;" scope="col" class="p-1 align-middle">
                      <p class="mb-0 f-065 mc-tooltip pointer">
                        <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.total_leads}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.prospector_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.closer_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_amount_recurring * 100))}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_gain_recurring * 100))}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_gain_separate * 100))}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.finished_at_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doDataIndicators() {
    var recurring = 0
    var nonRecurring = 0
    this.application.opportunities.forEach(element => {
      // recurring += Number(element.total_amount)
      recurring += Number(element.total_gain_recurring)
      nonRecurring += Number(element.total_gain_separate)
    })

    var html = `<div class="row">
                  <div class="col-12 mb-4">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Recorrente</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-3 d-flex align-items-center">
                          <div class="col-12 f-1 px-1">
                            ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(recurring * 100))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Não-Recorrente</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-3 d-flex align-items-center">
                          <div class="col-12 f-1 px-1">
                            ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(nonRecurring * 100))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.indicatorsTarget.innerHTML = html
  }

  doDailyChartArea() {
    var html = `<div class="row">
                  <div class="col-sm-12 px-0">
                    <div class="card" id="weeklyVisitsCard" data-controller="${this.controllerName}2">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="dailyTitleChart">Oportunidades</h6>
                            <div class="d-flex align-items-center justify-content-center" id="weeklyVisits" style="height:250px;" data-${this.controllerName}-target="bodyDailyChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="dailyChartBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dailyChartTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  doAccrualChartArea() {
    var html = ``
    var html = `<div class="row">
                  <div class="col-sm-12 px-0">
                    <div class="card" id="weeklyVisitsCard" data-controller="${this.controllerName}2">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="accrualTitleChart">Oportunidades</h6>
                            <div class="d-flex align-items-center justify-content-center" id="weeklyVisits" style="height:250px;" data-${this.controllerName}-target="bodyAccrualChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm mb-0" style="font-size:80%;">
                              <tbody data-${this.controllerName}-target="accrualChartBodyTable"></tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.accrualChartTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  doChartPreloader() {
    this.dailyTitleChartTarget.innerHTML = `${this.loader}`
    this.accrualTitleChartTarget.innerHTML = `${this.loader}`
    this.dailyChartBodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(20, 1)
    this.accrualChartBodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(20, 1)
    var dailyChartPreloader = `<div class="loading" data-${this.controllerName}-target="dailyChartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`
    var accrualChartPreloader = `<div class="loading" data-${this.controllerName}-target="accrualChartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`

    var controller = this
    this.bodyDailyChartTarget.innerHTML = dailyChartPreloader
    this.bodyAccrualChartTarget.innerHTML = accrualChartPreloader
    // new Promise(function (resolve) {
    //   resolve(controller.bodyChartTarget.innerHTML = chartPreloader)
    // }).then(() => {
    //   
    // })
  }

  doCalendarHTML() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Competência</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="dateList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.calendarTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.current_date) {
        controller.dateDropdownTarget.value = controller.application.current_date.name
        controller.dateInputTarget.innerText = controller.application.current_date.name
        controller.doDateList()
      } else {
        controller.dateDropdownTarget.value = "Favor Adicionar uma Competência"
        controller.dateInputTarget.innerText = "Favor Adicionar uma Competência"
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doDateList() {
    var listHtml = ``
    this.application.dates.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-year="${element.year}" data-month="${element.month}" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeDate" class="li-selector dark f-065">${element.name}</li>`
    });
    this.dateListTarget.innerHTML = listHtml
  }

  setOpportunities() {
    this.application.opportunities = []
    this.application.all_opportunities.forEach(element => {
      if (element.stage == `gain` && element.date_id == this.application.current_date.id) {
        this.application.opportunities[this.application.opportunities.length] = element
      }
    })
    
    this.doDataTable()
    this.doDataIndicators()
  }

  changeDate(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var id = ev.target.dataset.id
      this.year = ev.target.dataset.year
      this.month = ev.target.dataset.month
      this.doDailyChartArea()
      this.doAccrualChartArea()
      this.setCurrentDate(id)
    }
  }

  setCurrentDate(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.application.current_date = element
        this.application.current_calculation = element.calculation
        this.requestAccrualChartData()
        this.requestDailyChartData()
        this.setOpportunities()
      }
    });
  }

  

  getOpportunities() {
    const data = { transaction: { active: true, stage: `gain` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/sales/opportunities/transactions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.all_opportunities = response.data.cln
        controller.setOpportunities()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCommercialDates() {
    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.dates = response.data.cln
        controller.application.current_date = response.data.cln[0]
        controller.application.current_calculation = response.data.cln[0].calculation
        controller.requestAccrualChartData()
        controller.requestDailyChartData()
        controller.doCalendarHTML()
        controller.getOpportunities()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["sales_opportunities_entities", "sales_opportunities_journeys", "sales_opportunity_prospection_flow", "sales_opportunity_closing_flow"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.getCommercialDates()
        controller.getControllerByIdentifier("commercial--sales--opportunities--dealflow--submenu").doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
