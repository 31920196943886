import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "streaming", "link", "addLinkBtn", "broadcastLinkBtn", "linkBodyCard", "codeForm", "chapterForm", "channelForm",
                    "saveLinkBtn", "linkFooterCard", "addLinkShow", "addLinkForm", "channelFilter", "channelFilterItem", "eventFinishWarning",
                    "eventActionBtns", "streamingPlayBtn", "streamingPlayText", "streamingPauseBtn", "streamingPauseText", "streamingFinishBtn",
                    "streamingFinishText"]


  connect() {
    this.canEventRequest = true
    this.canLinkRequest = true
    this.controllerName = `commercial--marketing--events--live--action`
    this.doActionHtml()
  }

  doActionHtml() {
    var html = `<div class="row my-3">
                  <div class="col-12 px-0" data-commercial--marketing--events--live--action-target="streaming"></div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-0" data-commercial--marketing--events--live--action-target="link"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doLinkCard()
      controller.doStreamingCard()
    })
  }

  doLinkCard() {

    if (this.application.link) {
      var link = this.application.link
      var broadcastBtn = `<span class="mc-tooltip pointer">
                            <span class="material-icons md-sm md-dark" data-link-id="${link.id}" data-action="click->${this.controllerName}#broadcastLink">online_prediction</span>
                            <span class="mc-tooltiptext" data-${this.controllerName}-target="broadcastLinkBtn">Transmitir</span>
                          </span>`
      var deleteBtn = `<span class="mc-tooltip pointer">
                          <span class="material-icons md-sm md-dark" data-link-id="${link.id}" data-action="click->${this.controllerName}#deleteLink">delete</span>
                          <span class="mc-tooltiptext">Apagar Link</span>
                        </span>`
    } else {
      var link = { code: `Sem Link de Transmissão`, published: false}
      var broadcastBtn = ``
      var deleteBtn = ``
    }

    if (link.published) {
      var alert = `<span class="mc-tooltip pointer">
                    <span class="material-icons md-sm md-success">done</span>
                    <span class="mc-tooltiptext">Link publicado</span>
                  </span>`
    } else {
      var alert = `<span class="mc-tooltip pointer">
                    <span class="material-icons md-sm md-danger">warning</span>
                    <span class="mc-tooltiptext">Link não publicado</span>
                  </span>`
    }

    var html = `<div class="card border-left-primary" data-controller="app--helpers--search" data-${this.controllerName}-target="linkCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center f-085 p-2 px-3">
                    <span>Links</span>
                    <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addLink" data-${this.controllerName}-target="addLinkBtn">
                      <span class="material-icons md-sm md-dark">add</span>
                      <span class="mc-tooltiptext">Adicionar Link</span>
                    </span>
                  </div>
                  <div class="card-body py-3" data-${this.controllerName}-target="linkBodyCard">
                    <div class="row" data-${this.controllerName}-target="addLinkShow">
                      <div class="col-6">
                        <span>${link.code}</span>
                      </div>
                      <div class="col-2 px-2 text-center">
                        <span>${alert}</span>
                      </div>
                      <div class="col-2 px-2 text-center">
                        ${broadcastBtn}
                      </div>
                      <div class="col-2 px-2 text-center">
                        ${deleteBtn}
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1 d-none" data-${this.controllerName}-target="linkFooterCard">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelLinkSave">Fechar</button>
                    <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#createLink" data-${this.controllerName}-target="saveLinkBtn">Salvar</button>
                  </div>
                </div>`

    this.linkTarget.innerHTML = html
  }

  doStreamingCard() {
    var html = `<div class="card border-left-primary" data-controller="app--helpers--search" data-${this.controllerName}-target="streamingCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center f-085 p-2 px-3">
                    <span>Ações</span>
                  </div>
                  <div class="card-body py-3">
                    <div class="row" data-${this.controllerName}-target="eventActionBtns">
                      <div class="col-6 text-center">
                        <span class="mc-tooltip pointer" data-${this.controllerName}-target="streamingPlayBtn">
                          <span class="material-icons md-300 md-dark" data-streaming="play" data-action="click->${this.controllerName}#streamingAction">play_circle_outline</span>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="streamingPlayText"></span>
                        </span>
                        <span class="mc-tooltip pointer d-none" data-${this.controllerName}-target="streamingPauseBtn">
                          <span class="material-icons md-300 md-dark" data-streaming="pause" data-action="click->${this.controllerName}#streamingAction">pause_circle_outline</span>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="streamingPauseText"></span>
                        </span>
                      </div>
                      <div class="col-6 text-center">
                        <span class="mc-tooltip pointer" data-${this.controllerName}-target="streamingFinishBtn">
                          <span class="material-icons md-300 md-dark" data-streaming="finish" data-action="click->${this.controllerName}#streamingAction">clear</span>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="streamingFinishText"></span>
                        </span>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="eventFinishWarning">
                      <div class="col-12 text-center">
                        <span class="f-1p25 f-bold">Evento Finalizado</span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.streamingTarget.innerHTML = html)
    }).then(() => {
      controller.setStreamingBtns()
    })
  }

  setStreamingBtns() {
    var event = this.application.event

    if (event.finished) {
      this.eventActionBtnsTarget.classList.add("d-none")
      this.eventFinishWarningTarget.classList.remove("d-none")
    } else if (event.started && event.broadcast) {
      this.streamingPlayBtnTarget.classList.add("d-none")
      this.streamingPlayTextTarget.innerText = `Começar a Transmissão`
      this.streamingPauseBtnTarget.classList.remove("d-none")
      this.streamingPauseTextTarget.innerText = `Pausar a Transmissão`
      this.streamingFinishBtnTarget.classList.remove("d-none")
      this.streamingFinishTextTarget.innerText = `Encerrar a Transmissão`
    } else if (event.started) {
      this.streamingPlayBtnTarget.classList.remove("d-none")
      this.streamingPlayTextTarget.innerText = `Continuar a Transmissão`
      this.streamingPauseBtnTarget.classList.add("d-none")
      this.streamingPauseTextTarget.innerText = `Pausar a Transmissão`
      this.streamingFinishBtnTarget.classList.remove("d-none")
      this.streamingFinishTextTarget.innerText = `Encerrar a Transmissão`
    } else {
      this.streamingPlayBtnTarget.classList.remove("d-none")
      this.streamingPlayTextTarget.innerText = `Começar a Transmissão`
      this.streamingPauseBtnTarget.classList.add("d-none")
      this.streamingPauseTextTarget.innerText = `Pausar a Transmissão`
      this.streamingFinishBtnTarget.classList.add("d-none")
      this.streamingFinishTextTarget.innerText = `Encerrar a Transmissão`
    }

  }

  addLink() {
    if (this.application.event.broadcast) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor pausar a transmissão para inserir um novo link!`, 5000)
    } else {
      this.addLinkShowTarget.classList.add("d-none")
      this.linkFooterCardTarget.classList.remove("d-none")
      if (this.hasAddLinkFormTarget) {
        this.addLinkFormTarget.remove()
      }

      var html = `<div class="row" data-${this.controllerName}-target="addLinkForm">
                    <div class="col-4 px-2">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="floating-label floating-label-sm">
                            <label>Código</label>
                            <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="codeForm" type="text" placeholder="Código" required>
                          </div>
                          <div class="input-group-append">
                            <span class="input-group-text mc-tooltip">
                              <span class="badge badge-pill badge-light pointer">?</span>
                              <span class="mc-tooltiptext">Código Embedded do Streaming</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 px-2">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="floating-label floating-label-sm">
                            <label>Parte</label>
                            <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="chapterForm" type="text" placeholder="Parte" required>
                          </div>
                          <div class="input-group-append">
                            <span class="input-group-text mc-tooltip">
                              <span class="badge badge-pill badge-light pointer">?</span>
                              <span class="mc-tooltiptext">Parte do Streaming</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 px-2">
                      <div class="form-group">
                        <div class="input-group input-group-sm">
                          <div class="floating-label floating-label-sm">
                            <label>Plataforma</label>
                            <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="channelForm" data-filter-mode="simple" data-action="focus->${this.controllerName}#channelFilter keyup->${this.controllerName}#channelFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterChannel" type="text" placeholder="Plataforma" required>
                            <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="channelFilter"></ul>
                          </div>
                          <div class="input-group-append">
                            <span class="input-group-text mc-tooltip">
                              <span class="badge badge-pill badge-light pointer">?</span>
                              <span class="mc-tooltiptext">Plataforma do Streaming</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.linkBodyCardTarget.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.refreshSaveBtn()
        controller.listChannel()
      })
    }
  }

  listChannel() {
    var html = ``
    html += `<li data-action="click->${this.controllerName}#selectChannel" data-${this.controllerName}-target="channelFilterItem" data-text="Youtube" data-filter="youtube" class="li-selector dark f-065">Youtube</li>`

    this.channelFilterTarget.innerHTML = html
  }

  channelFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectChannel(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterChannel(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  cancelLinkSave() {
    this.stopRefreshing()
    this.canLinkRequest = true
    this.saveLinkBtnTarget.disabled = true
    this.linkFooterCardTarget.classList.add("d-none")
    if (this.hasAddLinkFormTarget) {
      this.addLinkFormTarget.remove()
    }
    this.addLinkShowTarget.classList.remove("d-none")
  }

  deleteLink(ev) {
    this.linkMode = `delete`
    this.send_data = { link: {}, current_user: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_links`

    this.send_data.link.id = ev.currentTarget.dataset.linkId
    this.send_data.link.active = false

    if (this.canLinkRequest) {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.canLinkRequest = false)
      }).then(() => {
        controller.linkRequest()
      })
    }
  }

  createLink() {
    this.linkMode = `new`
    this.send_data = { link: {}, current_user: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_links`
    
    this.send_data.link.event_id = this.application.event.id
    this.send_data.link.code = this.codeFormTarget.value
    this.send_data.link.chapter = this.chapterFormTarget.value
    this.send_data.link.channel = this.channelFormTarget.value
    this.send_data.link.status = true
    this.send_data.link.published = false

    if (this.canLinkRequest) {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.canLinkRequest = false)
      }).then(() => {
        controller.linkRequest()
      })
    }
  }

  broadcastLink(ev) {
    this.linkMode = `edit`
    this.send_data = { link: {}, current_user: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_links`
    
    this.send_data.link.id = ev.currentTarget.dataset.linkId
    this.send_data.link.status = true
    this.send_data.link.published = true

    if (this.canLinkRequest) {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.canLinkRequest = false)
      }).then(() => {
        controller.linkRequest()
      })
    }
  }

  streamingAction(ev) {
    if (this.application.link && this.application.link.published && this.application.link.status) {
      this.send_data = { event: {}, current_user: {} }
      var action = ev.currentTarget.dataset.streaming

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `commercial_event_entities`

      this.send_data.event.id = this.application.event.id
      if (action == `play`) {
        this.send_data.event.started = true
        this.send_data.event.broadcast = true
      } else if (action == `pause`) {
        this.send_data.event.broadcast = false
      } else if (action == `finish`) {
        this.send_data.event.broadcast = false
        this.send_data.event.finished = true
      }

      if (this.canEventRequest) {
        var controller = this
        new Promise(function (resolve) {
          resolve(controller.canEventRequest = false)
        }).then(() => {
          controller.eventRequest()
        })
      }
    } else if (this.application.link && this.application.link.status) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor publicar o link antes de iniciarmos a transmissão!`, 5000)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor inserir um link válido para iniciarmos a transmissão!`, 5000)
    }
  }

  eventRequest() {
    var url = "/commercial/marketing/events/entities/broadcast_event"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.canEventRequest = true
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  linkRequest() {
    if (this.linkMode == `new`) {
      var url = "/commercial/marketing/events/links/create"
      var method = "POST"
    } else if (this.linkMode == `edit`) {
      var url = "/commercial/marketing/events/links/broadcast_link"
      var method = "PUT"
    } else if (this.linkMode == `delete`) {
      var url = "/commercial/marketing/events/links/destroy"
      var method = "DELETE"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.cancelLinkSave()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshLinkTimer = setInterval(function () {
      var len = 0
      
      if (controller.hasCodeFormTarget && controller.codeFormTarget.value == ``) {
        len += 1
      }

      if (controller.hasChapterFormTarget && controller.chapterFormTarget.value == ``) {
        len += 1
      }

      if (controller.hasChannelFormTarget && controller.channelFormTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveLinkBtnTarget.disabled = false
      } else {
        controller.saveLinkBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLinkTimer) {
      clearInterval(this.refreshLinkTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
