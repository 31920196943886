import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "list", "mainCard", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--tax-filings--index--dashboard`
    this.application.permissions = {}
    this.application.chart_accounts = []
    this.application.tax_filings = []

    this.doMainGrid()

    // 1 - this.getCurrentUserPermissions
    // 2 - this.getProducts

  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--tax-filings--index--list
                                                            operations--products--tax-filings--reports--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--index--list`).permission = `medfiling_teams`


      controller.doPageGrid()
      controller.getCurrentUserPermissions()
    })
  }

  doPageGrid() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row">
                  <div class="col-12" data-${this.controllerName}-target="list">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("operations--products--tax-filings--index--list").doIndexListHtml()
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier("operations--products--tax-filings--index--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("operations--products--tax-filings--index--list").doDataTable()

    if (!this.application.permissions.medfiling_teams.can_create) {
      this.getControllerByIdentifier("operations--products--tax-filings--index--list").addTaxFilingBtnTarget.remove()
    }

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  // getChartAccounts() {
  //   this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Contas`, value: 80 })
  //   const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_management_chart_accounts` } }
  //   const url = "/operations/products/clinics/financials/chart_accounts/list"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       controller.application.chart_accounts = response.data.cln
  //       controller.finishLoadPage()
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
  //     })
  // }

  // getClinicTeam() {
  //   this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Operação`, value: 60 })
  //   const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_managements` } }
  //   const url = "/operations/products/config/teams/list_clinic_team"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       controller.application.team_list = response.data.cln
  //       controller.getChartAccounts()
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
  //     })
  // }

  getProducts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Declarações de IRPF`, value: 20 })

    const data = { product: { name: `medfiling`, kind: `` }, current_user: { current_user_id: this.application.current_user.id, feature: `medfiling_teams` } }
    const url = `/operations/products/entities/list_medfilings`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_filings = response.data.cln
          controller.getControllerByIdentifier("operations--products--tax-filings--index--submenu").dataLoaded.tax_filing = true
        }

        // controller.getClinicTeam()
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_tax_filings` }, current_user: { current_user_id: this.application.current_user.id, feature: `medfiling_team_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.application.domain_permission = controller.application.permissions[`medfiling_team_permissions`]

        controller.getProducts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}