import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxShow", "addReceiptBtn", "saveBtn", "modal",
                    "upload", "labelUpload", "cancelUploadBtn", "uploadFile", "fileName",
                    "showProgressUpload", "progressUpload", "progressUploadBar", "progressUploadCounter"]

  connect() {
    this.controllerName = `operations--products--bookings--settings--calculations`
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.send_download_data = { current_user: {}, download: {}, notification: {} }
    this.send_update_data = { current_user: {}, calculation: {}, notification: {} }
    this.doIndexListHtml()
  }

  updateCalculation(ev) {
    var calculationId = ev.currentTarget.closest(".itemRow").dataset.id
    var calculation = {}

    this.application.booking_calculations.forEach(element => {
      if (element.id == calculationId) {
        calculation = element
      }
    });

    this.actionMode = `upload`
    this.send_update_data.current_user.current_user_id = this.application.current_user.id
    
    this.send_update_data.calculation.id = calculationId
    this.send_update_data.calculation.field = ev.target.dataset.field
    this.send_update_data.calculation.value = ev.target.checked

    var url = "/operations/products/bookings/calculations/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_update_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var calculation = response.data.cln
          controller.application.booking_calculations.forEach((element, i) => {
            if (element.id == calculation.id) {
              controller.application.booking_calculations.splice(controller.application.booking_calculations.indexOf(element), 1, calculation)
            }
          }) 
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  goToUpload(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  downloadBooking(ev) {
    var calculationId = ev.currentTarget.closest(".itemRow").dataset.id
    var downloadKind = ev.currentTarget.dataset.kind
    var calculation = {}

    this.application.booking_calculations.forEach(element => {
      if (element.id == calculationId) {
        calculation = element
      }
    });

    this.actionMode = `download`
    this.send_download_data.current_user.current_user_id = this.application.current_user.id


    this.send_download_data.download.booking_id = this.application.booking.id
    this.send_download_data.download.date_id = calculation.date_id

    this.send_download_data.notification.domain_id = this.application.booking.id
    this.send_download_data.notification.domain_type = "operation_products"
    this.send_download_data.notification.date_id = this.application.current_date.id
    this.send_download_data.notification.date_type = "operation_dates"
    this.send_download_data.notification.kind = `mode_${this.actionMode}`
    this.send_download_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% as transações do ${calculation.name}.`
    this.send_download_data.notification.account_name = this.application.current_user.name
    this.send_download_data.notification.account_id = this.application.current_user.account_id

    var doctorName = calculation.name.split(" ").join("").split("/").join("").split("-").join("")
    // var fileName = `${this.application.booking.account_cpf}-${this.application.current_date.year}-${doctorName}`

    if (downloadKind == `receipts`) {
      var url = "/operations/products/bookings/transactions/download_receipts"
      var fileName = `${this.application.booking.account_cpf}-${doctorName}-recibos`
      var fileExtension = `csv`
    } else if (downloadKind == `payments`) {
      var url = "/operations/products/bookings/transactions/download_payments"
      var fileName = `${this.application.booking.account_cpf}-${doctorName}-pagamentos`
      var fileExtension = `csv`
    }

    // var url = "/operations/products/bookings/transactions/download"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_download_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          // controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
          controller.processingCsv(response, fileName, fileExtension, downloadKind)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doIndexListHtml() {
    
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos as Apurações</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-7 align-middle">Competência</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-7 align-middle">Aberto/Fechado</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-7 align-middle">IR Pago</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-7 align-middle">INSS Pago</th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 table-10 align-middle">GPS</th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 table-10 align-middle">DARF</th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 table-10 align-middle">Livro</th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 table-10 align-middle">Demonstrativo</th>
                                <th colspan="3" style="font-size:80%;" scope="col" class="text-center p-1 table-10 align-middle">Download Carnê-Leão</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--settings--dashboard").calculationsTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(14, 5))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_calculations = []
    this.application.booking_calculations.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_calculations[current_calculations.length] = element
      }
    });
    var calculations = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.booking_calculations, "uniq_product_date")

    if (calculations.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(calculations.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (calculations[x] !== undefined) {
            internPage[internPage.length] = calculations[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: calculations.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="7" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Apurações</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.calculationTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  calculationTablePartial(element, length) {

    if (element.has_social_invoice) {
      var socialInvoiceBtn = `<button data-action="click->${this.controllerName}#goToUpload" data-url="${element.social_invoice_url}" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>`
    } else {
      var socialInvoiceBtn = `<span class="material-icons md-sm md-dark">highlight_off</span>`
    }

    if (element.has_tax_invoice) {
      var taxInvoiceBtn = `<button data-action="click->${this.controllerName}#goToUpload" data-url="${element.tax_invoice_url}" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>`
    } else {
      var taxInvoiceBtn = `<span class="material-icons md-sm md-dark">highlight_off</span>`
    }

    if (element.has_cashbook) {
      var cashbookBtn = `<button data-action="click->${this.controllerName}#goToUpload" data-url="${element.cashbook_url}" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>`
    } else {
      var cashbookBtn = `<span class="material-icons md-sm md-dark">highlight_off</span>`
    }

    if (element.has_closure) {
      var closureBtn = `<button data-action="click->${this.controllerName}#goToUpload" data-url="${element.closure_url}" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>`
    } else {
      var closureBtn = `<span class="material-icons md-sm md-dark">highlight_off</span>`
    }

    if (this.application.permissions.medbooking_calculations.can_upload && element.closed) {
      var uploadGpsBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-file="social_invoice" data-action="click->${this.controllerName}#uploadFile" type="button">
                            <span class="material-icons f-1">publish</span>
                            <span class="mc-tooltiptext">Upload GPS</span>
                          </button>`
      var uploadDarfBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-file="tax_invoice" data-action="click->${this.controllerName}#uploadFile" type="button">
                            <span class="material-icons f-1">publish</span>
                            <span class="mc-tooltiptext">Upload DARF</span>
                          </button>`
      var uploadCashbookBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-file="cashbook" data-action="click->${this.controllerName}#uploadFile" type="button">
                            <span class="material-icons f-1">publish</span>
                            <span class="mc-tooltiptext">Upload Livro-Caixa</span>
                          </button>`
      var uploadClosureBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-file="closure" data-action="click->${this.controllerName}#uploadFile" type="button">
                            <span class="material-icons f-1">publish</span>
                            <span class="mc-tooltiptext">Upload Demonstrativo</span>
                          </button>`
    } else {
      var uploadGpsBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
      var uploadDarfBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
      var uploadCashbookBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
      var uploadClosureBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
    }

    if (this.application.permissions.medbooking_calculations.can_download && element.closed) {
      var downloadReceiptBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-kind="receipts" data-action="click->${this.controllerName}#downloadBooking" type="button">
                                  <span class="material-icons f-1">get_app</span>
                                  <span class="mc-tooltiptext">Recibos</span>
                                </button>`
      var downloadPaymentBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table btn-outline p-1 my-0 mc-tooltip" data-kind="payments" data-action="click->${this.controllerName}#downloadBooking" type="button">
                                  <span class="material-icons f-1">get_app</span>
                                  <span class="mc-tooltiptext">Pagamentos</span>
                                </button>`
    } else {
      var downloadReceiptBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
      var downloadPaymentBtn = '<span class="material-icons md-sm md-dark">highlight_off</span>'
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.closed) {
      if (this.application.permissions.medbooking_calculations.can_update) {
        var inputClosedHtml = `<input class="custom-control-input" id="switchClosed-${element.id}" data-field="closed" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      } else {
        var inputClosedHtml = `<input disabled class="custom-control-input" id="switchClosed-${element.id}" data-field="closed" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      }
    } else {
      var inputClosedHtml = `<input disabled class="custom-control-input" id="switchClosed-${element.id}" data-field="closed" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
    }

    if (element.closed) {
      if (this.application.permissions.medbooking_calculations.can_update) {
        if (element.tax_paid) {
          var inputTaxHtml = `<input class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
        } else {
          var inputTaxHtml = `<input class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
        }
        if (element.social_paid) {
          var inputSocialHtml = `<input class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
        } else {
          var inputSocialHtml = `<input class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
        }
      } else {
        if (element.tax_paid) {
          var inputTaxHtml = `<input disabled class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
        } else {
          var inputTaxHtml = `<input disabled class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
        }
        if (element.social_paid) {
          var inputSocialHtml = `<input disabled class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
        } else {
          var inputSocialHtml = `<input disabled class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
        }
      }
    } else {
      if (element.tax_paid) {
        var inputTaxHtml = `<input disabled class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      } else {
        var inputTaxHtml = `<input disabled class="custom-control-input" id="switchTax-${element.id}" data-field="tax_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      }
      if (element.social_paid) {
        var inputSocialHtml = `<input disabled class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      } else {
        var inputSocialHtml = `<input disabled class="custom-control-input" id="switchSocial-${element.id}" data-field="social_paid" data-action="click->${this.controllerName}#updateCalculation" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      }
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="align-middle px-0">
                      <div class="custom-control custom-switch d-flex align-items-center">
                        ${inputClosedHtml}
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="switchClosed-${element.id}"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="align-middle px-0">
                      <div class="custom-control custom-switch d-flex align-items-center">
                        ${inputTaxHtml}
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="switchTax-${element.id}"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="align-middle px-0">
                      <div class="custom-control custom-switch d-flex align-items-center">
                        ${inputSocialHtml}
                        <span class="custom-control-track"></span>
                        <label class="custom-control-label" for="switchSocial-${element.id}"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${socialInvoiceBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${uploadGpsBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${taxInvoiceBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${uploadDarfBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${cashbookBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${uploadCashbookBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${closureBtn}</td>
                    <td style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${uploadClosureBtn}</td>
                    <td colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${downloadReceiptBtn}</td>
                    <td colspan="2" style="font-size:80%;" scope="col" class="text-center p-1 align-middle">${downloadPaymentBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  uploadFile(ev) {
    this.application.booking_calculations.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_booking = element
      }
    });

    this.current_booking.field = ev.currentTarget.dataset.file

    this.modalHtml()
  }

  modalHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medbooking_calculations.can_update) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#saveCalculation" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="modal fade" data-${this.controllerName}-target="modal" id="courseModal" tabindex="-1" role="dialog" aria-labelledby="courseModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0 pl-0">
                        <h6 class="modal-title display-4 card-title-table-list"><strong class="f-075">Upload ${this.bookingFilePretty(this.current_booking.field)} | ${this.current_booking.name}</strong></h6><br>
                        <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body p-3" data-${this.controllerName}-target="body">
                        <div class="row my-2">
                          <div class="col-5 my-1 px-0 d-flex align-items-center justify-content-between d-none w-100" data-${this.controllerName}-target="upload">
                            <div class="form-group form-valid-group my-0 text-center">
                              <input type="file" id="calculationFile" name="calculationFile" class="file-input" data-action="change->${this.controllerName}#saveUpload">
                              <label for="calculationFile" id="calculationFileLabelForm" class="btn btn-primary btn-sm text-left px-1 f-065" data-${this.controllerName}-target="labelUpload" style="font-size:80%;">${this.bookingFilePretty(this.current_booking.field)}</label>
                            </div>
                            <button type="button" class="close ml-3 d-none" data-action="click->${this.controllerName}#cancelUpload" data-${this.controllerName}-target="cancelUploadBtn" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="col-7 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="uploadFile">
                            <span data-${this.controllerName}-target="fileName" class="ml-2 mr-auto f-065 my-auto" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"></span>
                          </div>

                          <div class="col-12 px-0 d-flex align-items-center justify-content-between w-100" data-${this.controllerName}-target="showProgressUpload">
                            <div class="progress w-100 d-none" style="height: 6px;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="height: 6px;width:20%;border-bottom:0.5rem solid #053B5E;" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span class="d-none" data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainCardTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.open()
      controller.refreshSaveBtn()
      if (controller.current_booking.field == "tax_invoice" && controller.current_booking.has_tax_invoice) {
        controller.fileNameTarget.innerText = controller.current_booking.tax_invoice_name
      }
      if (controller.current_booking.field == "social_invoice" && controller.current_booking.has_social_invoice) {
        controller.fileNameTarget.innerText = controller.current_booking.social_invoice_name
      }
      if (controller.current_booking.field == "cashbook" && controller.current_booking.has_cashbook) {
        controller.fileNameTarget.innerText = controller.current_booking.cashbook_name
      }
      if (controller.current_booking.field == "closure" && controller.current_booking.has_closure) {
        controller.fileNameTarget.innerText = controller.current_booking.closure_name
      }

      controller.uploadTarget.classList.remove("d-none")
      controller.uploadFileTarget.classList.remove("d-none")
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  open() {
    document.body.classList.add("modal-open");
    this.modalTarget.setAttribute("style", "display: block;");
    this.modalTarget.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.modalTarget.removeAttribute("style");
    this.modalTarget.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  bookingFilePretty(file) {
    var fileKind = ["tax_invoice", "social_invoice", "cashbook", "closure"]
    var fileKindPretty = ["DARF", "GPS", "Livro-Caixa", "Demonstrativo"]

    return fileKindPretty[fileKind.indexOf(file)]
  }

  saveUpload(ev) {
    if (ev.target.files.length > 0) {
      this.current_booking.file = ev.target.files[0]
      this.fileNameTarget.innerText = ev.target.files[0].name
    }
  }

  saveCalculation() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    var formData = new FormData()
    formData.append('file', this.current_booking.file)
    formData.append('field', this.current_booking.field)
    formData.append('calculation_id', this.current_booking.id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/operations/products/bookings/calculations/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

    this.progressUploadTarget.classList.remove("d-none")
    this.progressUploadCounterTarget.classList.remove("d-none")
    this.labelUploadTarget.classList.remove("btn-primary")
    this.labelUploadTarget.classList.add("btn-disabled")
    this.progressCount(0)
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          var booking_calculation = response.data.cln
          controller.application.booking_calculations.forEach((element, i) => {
            if (element.id == booking_calculation.id) {
              controller.application.booking_calculations.splice(controller.application.booking_calculations.indexOf(element), 1, booking_calculation)
            }
          })
          controller.doDataTable()
          controller.close()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--bookings--settings--calculations"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  processingCsv(response, fileName, fileExtension, downloadKind) {

    var rows = response.data.cln
    var csvContent = ``
    var row = ``

    rows.forEach(function (rowArray) {
      if (downloadKind == `receipts`) {
        // var url = "/operations/products/bookings/transactions/download_receipts"
        // var fileName = `${this.application.booking.account_cpf}-${doctorName}-recibos`
        // var fileExtension = `csv`
      } else if (downloadKind == `payments`) {
        // rowArray[0] = `${rowArray[0]}`
        // rowArray[2] = `"${rowArray[2]}"`
      }
      // rowArray.forEach((element, i) => {
      //   // element = `"${element}"`
      // });
      row = rowArray.join(";");
      csvContent += row + "\r\n";
    });
    var universalBOM = "\uFEFF"
    // var encodedUri = encodeURI(csvContent);
    var encodedUri = encodeURIComponent(universalBOM + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodedUri);
    link.setAttribute("download", fileName + `.${fileExtension}`);
    document.body.appendChild(link);

    link.click();

    link.remove();

  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
