import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--show--main`
    this.account_kind = this.application.current_user.account_kind
    this.reload = false
    this.application.canEmitInvoice = false
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.clinic_token = location.pathname.split("/").pop()
    this.application.dates = []
    this.application.main_apurations = []
    this.application.partners = []
    this.application.takers = []
    this.application.regimes = []
    this.application.all_activities = []
    this.application.activities = []
    this.application.notes = []
    this.application.tickets = []
    this.application.banks = []
    this.application.all_receivables = []
    this.application.receivables = []
    this.application.canceled_receivables = []
    this.application.channels = []
    this.application.chart_accounts = []
    this.application.transfers = []
    this.application.balances = []
    this.application.taxes = []
    this.application.expenses = []
    this.application.transfers = []
    this.application.providers = []
    this.application.integration_companies = []
    this.application.agents = []
    this.application.transactions = []
    this.application.certificates = []
    this.application.emails = []
    this.application.helpers = []
    this.setAccountPermission()
    this.doClinicGrid()

    // getCurrentUserPermissions
    // getClinic
    // getClinicDates
    // getMainApuration
    // getClinicTeam
    // getReceivables
    // getPartners
    // getTransfers
    // getTakers
    // getRegimes
    // getActivities
    // getBanks
    // getCertificates
    // getIntegrationCompanies
    // getChartAccounts
    // getChannels
    // getBalances
    // getTaxes
    // getTickets
    // getNotes


  }

  doClinicGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--clients--show--dashboard
                                                            operations--products--clinics--clients--show--logs
                                                            operations--products--clinics--clients--apurations--dashboard
                                                            operations--products--clinics--clients--receivables--dashboard
                                                            operations--products--clinics--clients--receivables--add--dashboard
                                                            operations--products--clinics--clients--expenses--dashboard
                                                            operations--products--clinics--clients--taxes--dashboard
                                                            operations--products--clinics--clients--takers--dashboard
                                                            operations--products--clinics--clients--helpers--dashboard
                                                            operations--products--clinics--clients--transfers--dashboard

                                                            operations--products--clinics--regularizations--show--check-invoice" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--clients--show--dashboard").doClinicDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  setClinicName() {
    var html = `<strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>
                <span> | CNPJ: </span>
                <strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>`

    this.clinicNameTarget.innerHTML = html

    this.application.domain_type = "PJ Médica"
    this.application.domain.type = "PJ Médica"
    this.application.domain.id = this.application.clinic.id
  }

  finishLoadPage() {
    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
      }, 500);
    }

    
    this.getControllerByIdentifier("operations--products--clinics--clients--show--dates").setCurrents()
    if (this.reload) {
      this.getControllerByIdentifier("operations--products--clinics--clients--show--dates").runDashboardsHtml()
      this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--clients--show--dashboard").doClinicDashboard()
      this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("operations--products--clinics--clients--show--dates").doDatesHTML()
    }
    this.application.current_date.data_loaded = true

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getExpenses() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Despesas`, value: 95 })

    var data = { expense: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.expense_permission } }
    const url = `/operations/products/clinics/financials/expenses/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.expenses[controller.application.expenses.length] = element
          })
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTransfers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Repasses da PJ`, value: 85 })

    var data = { transfer: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.transfer_permission } }
    const url = "/operations/products/clinics/financials/transfers/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.transfers[controller.application.transfers.length] = element
          })
        }

        controller.getExpenses()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Impostos`, value: 80 })

    var data = { tax: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.taxes_permission } }
    const url = "/operations/products/clinics/financials/taxes/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.taxes[controller.application.taxes.length] = element
          })
        }

        controller.getTransfers()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Recibos PJ e NFs`, value: 75 })

    var data = { receivable: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.receivable_permission } }
    const url = "/operations/products/clinics/financials/receivables/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.all_receivables[controller.application.all_receivables.length] = element
          })
        }

        controller.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).setReceivables()
        controller.getTaxes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRegimes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Regimes Tributários`, value: 70 })

    var data = { regime: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.regime_permission } }
    const url = `/operations/products/clinics/financials/regimes/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.regimes[controller.application.regimes.length] = element
          })

          controller.application.regimes.forEach(element => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.current_regime = element
            }
          })
        }

        controller.getReceivables()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMainApuration() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Apurações da PJ`, value: 65 })

    const data = { apuration: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.main_apuration_permission } }
    const url = `/operations/products/clinics/financials/main_apurations/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.main_apurations[controller.application.main_apurations.length] = element
          })
        }

        controller.getRegimes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
        // this.refreshLoadValue()
      }
      this.getMainApuration()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--clients--show--dates").setCurrents()
      this.getControllerByIdentifier("operations--products--clinics--clients--show--dates").runDashboardsHtml()
    }
  }

  getProviders() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 60 })

    var data = { provider: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.provider_permission } }
    const url = "/operations/products/clinics/financials/expenses/list_providers"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.providers = response.data.cln
        }

        controller.loadDateData()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time Operacional MedCapital`, value: 55 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: this.product_permission } }
    const url = "/operations/products/config/teams/list_clinic_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.team_list = response.data.cln
        }

        controller.getProviders()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getHelpers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Assistentes`, value: 50 })

    var data = { helper: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.helper_permission } }
    const url = "/operations/products/clinics/managements/helpers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.helpers = response.data.cln
        }

        controller.getClinicTeam()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Contas`, value: 45 })

    var data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: this.chart_account_permission } }
    const url = "/operations/products/clinics/financials/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.chart_accounts = response.data.cln
        }

        controller.getHelpers()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPartners() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Sócios da PJ`, value: 40 })

    var data = { partner: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.partner_permission } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.partners = response.data.cln

          if (controller.application.current_user.account_kind == `doctor`) {
            controller.application.partners.forEach(partner => {
              if (partner.account_id == controller.application.current_user.account_id) {
                controller.application.current_partner = partner
              }
            })
          }

        }

        controller.getChartAccounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTakers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tomadores`, value: 35 })

    var data = { taker: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.taker_permission } }
    const url = "/operations/products/clinics/regularizations/takers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.takers = response.data.cln
        }

        controller.getPartners()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Atividades Econômicas`, value: 30 })

    var data = { activity: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.activities_permission } }
    const url = "/operations/products/clinics/regularizations/activities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.activities = response.data.cln
        }

        controller.getTakers()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 25 })

    var data = { channel: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.channel_permission } }
    const url = "/operations/products/clinics/financials/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.channels = response.data.cln
        }

        controller.getActivities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    var data = { date: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.date_permission } }
    const url = `/operations/products/clinics/managements/dates/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.application.main_current_date = response.data.cln[0]
            controller.setClinicName()
            controller.doSubtitle()

            if (controller.application.current_date.open == false) {
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }

            controller.getChannels()
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar a Competência, no Submenu > Competências`, 3000)
          }

        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canal de Transmissão`, value: 15 })
    var controllerBroadcast = this.getControllerByIdentifier(`operations--products--clinics--clients--show--broadcast`)

    var data = { product: { id: this.application.clinic.id, name: this.product_permission }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controllerBroadcast.application.rooms[controllerBroadcast.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln.update
          controllerBroadcast.chat_room = response.data.cln.chat
          controllerBroadcast.application.chat_room = response.data.cln.chat
          controllerBroadcast.broadcastUpdateChannel()
          controllerBroadcast.broadcastChatChannel()

          controller.getClinicDates()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinic() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando PJ Médica`, value: 10 })

    var data = { product: { token: this.application.clinic_token }, current_user: { current_user_id: this.application.current_user.id, feature: this.product_permission } }
    const url = `/operations/products/entities/read_show`

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clinic = response.data.cln
          controller.getRoom()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_clients` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permissions_permission, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getClinic()
        } else {
          // window.open(`/dashboard`, `_self`)
        }

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  doSubtitle() {
    this.subTitleTarget.innerText = `Emissão de NF's e Apuração | ${this.application.current_date.name}`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  setAccountPermission() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      this.product_permission = `medclinic_clients`
      this.permissions_permission = `medclinic_client_permissions`
      this.date_permission = `medclinic_client_dates`
      this.channel_permission = `medclinic_client_channels`
      this.activities_permission = `medclinic_client_activities`
      this.taker_permission = `medclinic_client_takers`
      this.partner_permission = `medclinic_client_partners`
      this.chart_account_permission = `medclinic_client_chart_accounts`
      this.helper_permission = `medclinic_client_helpers`
      this.provider_permission = `medclinic_client_providers`
      this.main_apuration_permission = `medclinic_client_main_apurations`
      this.regime_permission = `medclinic_client_regimes`
      this.receivable_permission = `medclinic_client_receivables`
      this.taxes_permission = `medclinic_client_taxes`
      this.expense_permission = `medclinic_client_expenses`
      this.transfer_permission = `medclinic_client_transfers`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.product_permission = `medclinic_doctor_clients`
      this.permissions_permission = `medclinic_doctor_client_permissions`
      this.date_permission = `medclinic_doctor_client_dates`
      this.channel_permission = `medclinic_doctor_client_channels`
      this.activities_permission = `medclinic_doctor_client_activities`
      this.taker_permission = `medclinic_doctor_client_takers`
      this.partner_permission = `medclinic_doctor_client_partners`
      this.chart_account_permission = `medclinic_doctor_client_chart_accounts`
      this.helper_permission = `medclinic_doctor_client_helpers`
      this.provider_permission = `medclinic_doctor_client_providers`
      this.main_apuration_permission = `medclinic_doctor_client_main_apurations`
      this.regime_permission = `medclinic_doctor_client_regimes`
      this.receivable_permission = `medclinic_doctor_client_receivables`
      this.taxes_permission = `medclinic_doctor_client_taxes`
      this.expense_permission = `medclinic_doctor_client_expenses`
      this.transfer_permission = `medclinic_doctor_client_transfers`
    } else if (this.application.current_user.account_kind == `helper`) {
      this.product_permission = `medclinic_helper_clients`
      this.permissions_permission = `medclinic_helper_client_permissions`
      this.date_permission = `medclinic_helper_client_dates`
      this.channel_permission = `medclinic_helper_client_channels`
      this.activities_permission = `medclinic_helper_client_activities`
      this.taker_permission = `medclinic_helper_client_takers`
      this.partner_permission = `medclinic_helper_client_partners`
      this.chart_account_permission = `medclinic_helper_client_chart_accounts`
      this.helper_permission = `medclinic_helper_client_helpers`
      this.provider_permission = `medclinic_helper_client_providers`
      this.main_apuration_permission = `medclinic_helper_client_main_apurations`
      this.regime_permission = `medclinic_helper_client_regimes`
      this.receivable_permission = `medclinic_helper_client_receivables`
      this.taxes_permission = `medclinic_helper_client_taxes`
      this.expense_permission = `medclinic_helper_client_expenses`
      this.transfer_permission = `medclinic_helper_client_transfers`
    }
  }
}