import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "progressUploadDiv", "progressUploadBar", "progressUploadCounter", "submessage"]

  connect() {
    this.controllerName = `app--helpers--modal`
    this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.spinHour()
    })
  }

  progressCount(value) {
    if (this.hasProgressUploadBarTarget) {
      this.progressUploadBarTarget.style.width = value + `%`
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  progressCountMessage(options = { submessage: ``, value: 0 }) {
    
    if (options.submessage == undefined) {
      options.submessage = ``
    }
    if (options.value == undefined) {
      options.value = 0
    }

    if (this.hasSubmessageTarget) {
      this.submessageTarget.innerText = options.submessage
    }

    if (this.hasProgressUploadBarTarget) {
      this.progressUploadDivTarget.classList.remove(`d-none`)
      this.progressUploadBarTarget.style.width = options.value + `%`
      this.progressUploadCounterTarget.innerText = options.value + `%`
    }
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    this.modalTarget.remove()
  }

  spinHour() {
    var controller = this
    var value = ``
    this.spinTimer = setInterval(function () {
      value = controller.modalTarget.dataset.hour

      if (value == `bottom`) {
        controller.bottomTarget.classList.remove("d-none")
        controller.topTarget.classList.add("d-none")
        controller.modalTarget.dataset.hour = `top`
      } else if (value == `top`) {
        controller.bottomTarget.classList.add("d-none")
        controller.topTarget.classList.remove("d-none")
        controller.modalTarget.dataset.hour = `bottom`
      }

    }, 500);
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  
}