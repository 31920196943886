import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName",
                    "countOneCheckbox", "countOne", "countTwoCheckbox", "balanceHeader",
                    "assets", "liabilities", "revenues", "expenses", "taxes", "results"]

  connect() {
    this.controllerName = `financials--books--statements--daily--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
    this.hasShow = false
    this.tableCols = 12
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var clearBtnHtml = ``

    var html = `<div class="row">
                  <div class="col-11 px-1">
                    <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 card-title-table-list">Contas de Balanço</h6>
                        <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${clearBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                    Empresa
                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="med_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                    Data
                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_pretty" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_pretty" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                    Conta de Balanço
                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Grupo de Conta</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Tipo</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Saldo Inicial</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Origem</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Destino</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Saldo Final</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Tipo de Conta</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                  <div class="col-1 px-1">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070" data-${this.controllerName}-target="balanceHeader">
                        <span><strong>Ativo</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="assets"></span>
                      </div>
                    </div>
                    <div class="card mt-2">
                      <div class="card-header p-1 text-center f-070" data-${this.controllerName}-target="balanceHeader">
                        <span><strong>Passivo</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="liabilities"></span>
                      </div>
                    </div>
                    <div class="card mt-5">
                      <div class="card-header p-1 text-center f-070">
                        <span><strong>Receitas</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="revenues"></span>
                      </div>
                    </div>
                    <div class="card mt-2">
                      <div class="card-header p-1 text-center f-070">
                        <span><strong>Despesas</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="expenses"></span>
                      </div>
                    </div>
                    <div class="card mt-2">
                      <div class="card-header p-1 text-center f-070">
                        <span><strong>Impostos</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="taxes"></span>
                      </div>
                    </div>
                    <div class="card mt-2">
                      <div class="card-header p-1 text-center f-070">
                        <span><strong>Resultados</strong></span>
                      </div>
                      <div class="card-body text-center f-070 px-1 py-2">
                        <span data-${this.controllerName}-target="results"></span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--daily--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
      controller.assetsTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      controller.liabilitiesTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      controller.revenuesTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      controller.expensesTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      controller.taxesTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      controller.resultsTarget.innerText = controller.controllerNumber.currencyOptionMask(0)
      
      controller.doIndicators()
    })
  }

  doDataTable() {
    this.current_balances = []

    this.application.daily_balances.forEach(balance => {
      if (this.application.current_med.id) {
        if (this.application.current_date.id == balance.date_id && this.application.current_med.id == balance.med_id) {
          this.current_balances[this.current_balances.length] = balance
        }
      } else {
        if (this.application.current_date.id == balance.date_id) {
          this.current_balances[this.current_balances.length] = balance
        }
      }
    })

    if (this.sort.mode == `asc`) {
      var balances = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_balances, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var balances = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_balances, this.sort.field)
    }

    if (balances.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(balances.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (balances[x] !== undefined) {
            internPage[internPage.length] = balances[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: balances.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contas de Balanço, favor selecionar o Dia.</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.balanceTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  balanceTablePartial(element, length) {

    if (this.application.permissions.financial_statement_balances.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editBalance" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mb-0 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.chart_name}" data-action="click->app--helpers--copy#copy">${element.chart_name}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.group_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.master_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.previous_balance)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.from_amount)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.to_amount)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.balance)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doIndicators() {

    this.calculation = {}
    this.calculation.assets = 0
    this.calculation.liabilities = 0
    this.calculation.revenues = 0
    this.calculation.expenses = 0
    this.calculation.taxes = 0
    this.calculation.results = 0

    this.current_balances.forEach(element => {
      if (element.chart_master == `asset`) {
        this.calculation.assets += Number(element.balance)
      }
      
      if (element.chart_master == `liability`) {
        this.calculation.liabilities += Number(element.balance)
      }

      if (element.chart_master == `revenues`) {
        this.calculation.revenues += Number(element.balance)
      }

      if (element.chart_master == `expenses`) {
        this.calculation.expenses += Number(element.balance)
      }

      if (element.chart_master == `taxes`) {
        this.calculation.taxes += Number(element.balance)
      }

      if (element.chart_master == `result`) {
        this.calculation.results += Number(element.balance)
      }
    })

    this.assetsTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.assets)
    this.liabilitiesTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.liabilities)
    this.revenuesTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.revenues)
    this.expensesTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.expenses)
    this.taxesTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.taxes)
    this.resultsTarget.innerText = this.controllerNumber.currencyOptionMask(this.calculation.results)

    if (this.controllerNumber.currencyOptionMask(this.calculation.assets) != this.controllerNumber.currencyOptionMask(this.calculation.liabilities)) {
      this.balanceHeaderTargets.forEach(element => {
        element.classList.add(`bg-light-danger`)
      })
    }
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
