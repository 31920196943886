
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "result", "userName", "userCPF", "userEmail"]

  connect() {
    this.user_token = location.pathname.split("/").pop()
    this.getCurrentUserPermissions()
    this.getUser()
  }

  getUser() {
    const data = { user: { token: this.user_token }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.user = response.data.cln
          this.userNameTarget.innerText = this.application.user.name
          this.userCPFTarget.innerText = this.application.user.cpf_pretty
          this.userEmailTarget.innerText = this.application.user.email
          this.getControllerByIdentifier("users--profile--kind").getAccount()
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }
  
  getCurrentUserPermissions() {
    const featureNames = [ "slots", "sidebar_permissions", "product_permissions", "product_entities", "users" ]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          $("#currentUserPermisisonContainer").data(permission.name, permission.data)
        });     
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });   
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}
