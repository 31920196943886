import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "previewCard", "previewTitle", "logBody", "logResponse", "contracts", "bodyTable", "footerTable", "body", "searchTable", "searchInput"]

  connect() {
    this.controllerName = `app--stats--trackers--logs--show-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 750
    this.tableCols = 4
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-3">
                      <div class="col-6 trix-content">
                        <pre data-${this.controllerName}-target="logBody"></pre>
                      </div>
                      <div class="col-6 trix-content">
                        <pre data-${this.controllerName}-target="logResponse"></pre>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Log | ${controller.current_log.title}`
      
      controller.logBodyTarget.textContent = JSON.stringify(controller.current_log.body, undefined, 4)
      controller.logResponseTarget.textContent = JSON.stringify(controller.current_log.response, undefined, 4)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}