import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["leaderInput", "leaderFilter", "leaderFilterItem", "teamInput", "teamFilter", "teamFilterItem", "formCard", "formCardBody",
                    "formTitle", "saveBtn", "freezeBoardBtn", "destroyBoardBtn"]

  connect() {
    this.controllerName = `users--works--meetings--dashboard--save`
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.work_meeting_boards.can_update) {
      if (this.current_board.status == `frozen`) {
        var freezeBoardBtn = `<span class=" px-3 mc-tooltip ml-auto pointer d-none" data-${this.controllerName}-target="freezeBoardBtn" data-action="click->${this.controllerName}#unfreezeBoard">
                              <span class="material-icons f-1">cached</span>
                              <span class="mc-tooltiptext">Descongelar Semanal</span>
                            </span>`
      } else if (this.current_board.status == `active`) {
        var freezeBoardBtn = `<span class=" px-3 mc-tooltip ml-auto pointer d-none" data-${this.controllerName}-target="freezeBoardBtn" data-action="click->${this.controllerName}#freezeBoard">
                              <span class="material-icons f-1">ac_unit</span>
                              <span class="mc-tooltiptext">Congelar Semanal</span>
                            </span>`
      }
    } else {
      var freezeBoardBtn = ``
    }

    if (this.application.permissions.work_meeting_boards.can_delete) {
      var destroyBoardBtn = `<span class=" px-3 mc-tooltip pointer d-none" data-${this.controllerName}-target="destroyBoardBtn" data-action="click->${this.controllerName}#destroyBoard">
                              <span class="material-icons f-1">delete</span>
                              <span class="mc-tooltiptext">Apagar Semanal</span>
                            </span>`
    } else {
      var destroyBoardBtn = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                    ${freezeBoardBtn}
                    ${destroyBoardBtn}
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-12">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="leaderForm">Líder</label>
                            <input aria-describedby="leaderFormHelp" class="form-control form-valid-control" id="leaderForm" data-filter-mode="simple" data-${this.controllerName}-target="leaderInput" data-action="focus->${this.controllerName}#leaderFilter keyup->${this.controllerName}#leaderFilter keyup->${this.controllerName}#enterLeader blur->${this.controllerName}#hideList" placeholder="Líder" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="leaderFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-12">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="teamForm">Liderado</label>
                            <input aria-describedby="teamFormHelp" class="form-control form-valid-control" id="teamForm" data-filter-mode="simple" data-${this.controllerName}-target="teamInput" data-action="focus->${this.controllerName}#teamFilter keyup->${this.controllerName}#teamFilter keyup->${this.controllerName}#enterTeam blur->${this.controllerName}#hideList" placeholder="Liderado" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="teamFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveBoard" data-${this.controllerName}-target="saveBtn">Salvar</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--meetings--dashboard--index").sideTarget.innerHTML = html)
    }).then(() => {

      if (controller.actionMode == `edit`) {
        controller.leaderInputTarget.dataset.filter = controller.current_board.leader_id
        controller.leaderInputTarget.dataset.text = controller.current_board.leader_name
        controller.leaderInputTarget.value = controller.current_board.leader_name
        controller.teamInputTarget.dataset.filter = controller.current_board.team_id
        controller.teamInputTarget.dataset.text = controller.current_board.team_name
        controller.teamInputTarget.value = controller.current_board.team_name
        controller.teamInputTarget.disabled = true

        if (controller.current_board.leader_id == this.application.current_user.account_id) {
          controller.freezeBoardBtnTarget.classList.remove("d-none")
          if (controller.hasDestroyBoardBtnTarget) {
            controller.destroyBoardBtnTarget.classList.remove("d-none")
          }
        }

        controller.formTitleTarget.innerText = `Editando Quadro Semanal`
        controller.saveBtnTarget.classList.add("d-none")
        controller.stopRefreshing()
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Quadro Semanal`
        controller.refreshSaveBtn()
      }

      controller.listTeam()
      controller.listLeader()

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("users--works--meetings--dashboard--index").sideTarget.innerHTML = ``
  }

  leaderFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  teamFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listLeader() {
    var html = ``

    if (this.application.current_user.account_kind == "admin") {
      this.application.team.forEach(element => {
        html += `<li data-filter="${element.id}" data-text="${element.nickname}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectLeader" data-${this.controllerName}-target="leaderFilterItem">${element.nickname}</li>`
      });
    } else {
      var leader = {}
      leader.account_id = this.application.current_user.account_id
      leader.nickname = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      html += `<li data-filter="${leader.account_id}" data-text="${leader.nickname}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectLeader" data-${this.controllerName}-target="leaderFilterItem">${leader.nickname}</li>`

      this.leaderInputTarget.dataset.filter = leader.account_id
      this.leaderInputTarget.dataset.text = leader.nickname
      this.leaderInputTarget.value = leader.nickname
      this.leaderInputTarget.disabled = true
    }

    this.leaderFilterTarget.innerHTML = html
  }

  listTeam() {
    var html = ``
    this.application.team.forEach(element => {
      if (element.kind.includes(`admin`) == false) {
        html += `<li data-filter="${element.id}" data-text="${element.nickname}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectTeam" data-${this.controllerName}-target="teamFilterItem">${element.nickname}</li>`
      }
    });

    this.teamFilterTarget.innerHTML = html
  }

  selectLeader(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterLeader(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  selectTeam(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterTeam(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  unfreezeBoard() {
    this.send_data = { current_user: {}, board: {} }

    this.send_data.board.id = this.current_board.id
    this.send_data.board.status = `active`
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_boards`

    var r = confirm("Certeza que deseja descongelar a Semanal?")
    if (r) {
      this.requestSave()
    }
  }

  freezeBoard() {
    this.send_data = { current_user: {}, board: {} }

    this.send_data.board.id = this.current_board.id
    this.send_data.board.status = `frozen`
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_boards`

    var r = confirm("Certeza que deseja congelar a Semanal?")
    if (r) {
      this.requestSave()
    }
  }

  destroyBoard() {
    this.send_data = { current_user: {}, board: {} }
    this.actionMode = `delete`

    this.send_data.board.id = this.current_board.id
    this.send_data.board.active = false
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_boards`

    var r = confirm("Certeza que deseja apagar a Semanal?")
    if (r) {
      this.requestSave()
    }
  }

  saveBoard() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    this.send_data = { current_user: {}, board: {} }

    this.send_data.board.leader_id = this.leaderInputTarget.dataset.filter
    this.send_data.board.leader_name = this.leaderInputTarget.dataset.text
    this.send_data.board.team_id = this.teamInputTarget.dataset.filter
    this.send_data.board.team_name = this.teamInputTarget.dataset.text
    this.send_data.board.status = `active`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_boards`

    if (this.send_data.board.team_id == this.send_data.board.leader_id) {
      this.saveBtnTarget.disabled = false
      this.refreshSaveBtn()
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Semanal tem que ser entre pessoas diferentes :/`, 5000)
    } else { 
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/meetings/boards/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/meetings/boards/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/meetings/boards/destroy"
      var method = "DELETE"
    }
    
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var board = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.boards[controller.application.boards.length] = board
          } else if (controller.actionMode == `edit`) {
            controller.application.boards.forEach((element, i) => {
              if (element.id == board.id) {
                controller.application.boards.splice(controller.application.boards.indexOf(element), 1, board)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.boards.forEach((element, i) => {
              if (element.id == board.id) {
                controller.application.boards.splice(controller.application.boards.indexOf(element), 1)
              }
            })
          }

          controller.getControllerByIdentifier("users--works--meetings--dashboard--index").doBoardsHtml()
          controller.cancelSave()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (controller.leaderInputTarget.value == ``) {
        len += 1
      }

      if (controller.teamInputTarget.dataset.text == undefined) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
