import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "chartInput", "toInput", "saveBtn",
                    "medDropdown", "medDropdownBtn", "medInput", "medList", "providerInput", "providerFilter",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "dueDay", "purchaseAmountInput",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "descriptionInput", "bankLineInput",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput", "bankLineInput",
                    "bankLineDiv", "bankAccountDiv", "installmentsInput", "pixKeyDiv", "pixKeyInput", "purchaseDate",
                    "recurringCheckbox", "adjustedPaidInstallmentsInput", "deleteBtn", "dueDay", "amortizationAmountInput",
                    "totalAmountInput", "splitInput", "installmentInput", "subkindCard", "subkind", "installmentInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList"]


  connect() {
    this.controllerName = `financials--books--payables--loans--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--payables--loans--list").doLoanList()
    this.getControllerByIdentifier("financials--books--payables--loans--show").doNoLoan()
    this.getControllerByIdentifier("financials--books--payables--installments--save").stopRefreshing()
    this.getControllerByIdentifier("financials--books--payables--installments--show").doNoInstallment()
  }

  saveTransaction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, loan: {} }
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_loans`

    if (this.actionMode == `edit`) {
      this.send_data.loan.id = this.current_loan.id
      
    } else {
    }

    var chartId = this.chartInputTarget.dataset.filter
    var chart = {}
    this.application.chart_accounts.forEach(element => {
      if (element.id == chartId) {
        chart = element
      }
    })

    this.send_data.loan.date_id = this.application.current_date.id
    this.send_data.loan.med_id = this.medInputTarget.dataset.medId
    this.send_data.loan.med_name = this.medInputTarget.value
    this.send_data.loan.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.loan.channel_name = this.channelInputTarget.value
    this.send_data.loan.provider_id = this.providerInputTarget.dataset.providerId
    this.send_data.loan.provider_name = this.providerInputTarget.value
    this.send_data.loan.purchase_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.purchaseDateTarget.value)
    this.send_data.loan.due_day = this.dueDayTarget.value
    this.send_data.loan.installments = this.installmentsInputTarget.value
    this.send_data.loan.adjusted_paid_installments = this.adjustedPaidInstallmentsInputTarget.value
    this.send_data.loan.paid_installments = this.adjustedPaidInstallmentsInputTarget.value
    this.send_data.loan.description = this.descriptionInputTarget.value.trim()
    this.send_data.loan.method = this.methodInputTarget.dataset.method
    this.send_data.loan.kind = this.kindInputTarget.dataset.kind
    this.send_data.loan.chart_id = chart.id
    this.send_data.loan.chart_account = chart.chart_account
    this.send_data.loan.chart_name = chart.chart_name
    this.send_data.loan.chart_master_name = chart.master_name
    this.send_data.loan.chart_group = chart.group
    this.send_data.loan.purchase_amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.purchaseAmountInputTarget.value))
    this.send_data.loan.amortization_amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amortizationAmountInputTarget.value))
    this.send_data.loan.status = this.statusInputTarget.dataset.status

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/payables/loans/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/payables/loans/update"
      var method = "PUT"  
    } else if (this.actionMode == `delete`) {
      var url = "/financials/books/payables/loans/destroy"
      var method = "DELETE"  
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var loan = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.loans[controller.application.loans.length] = loan
          } else if (this.actionMode == `edit`) {
            controller.application.loans.forEach((element, i) => {
              if (element.id == loan.id) {
                controller.application.loans.splice(controller.application.loans.indexOf(element), 1, loan)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.loans.forEach((element, i) => {
              if (element.id == loan.id) {
                controller.application.loans.splice(controller.application.loans.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_payable_loans.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTransaction" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="bank_loan" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Empréstimo Bancário</li>
                                      <li data-kind="taxes" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Parcelamentos</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="purchaseDateForm">Data Contratação</label>
                                <input aria-describedby="purchaseDateFormHelp" class="form-control form-valid-control" id="purchaseDateForm" data-${this.controllerName}-target="purchaseDate" placeholder="Data Contratação" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="purchaseAmountForm">Valor Contrato</label>
                                <input aria-describedby="purchaseAmountFormHelp" class="form-control f-065" id="purchaseAmountForm" data-${this.controllerName}-target="purchaseAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Contrato" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amortizationAmountForm">Valor Parcela</label>
                                <input aria-describedby="amortizationAmountFormHelp" class="form-control f-065" id="amortizationAmountForm" data-${this.controllerName}-target="amortizationAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueDayForm">Dia Pagamento</label>
                                <input aria-describedby="dueDayFormHelp" class="form-control form-valid-control" id="dueDayForm" data-${this.controllerName}-target="dueDay" placeholder="Dia Pagamento" type="number" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="installmentsForm"># Parcelas</label>
                                <input aria-describedby="installmentsFormHelp" class="form-control f-065" id="installmentsForm" data-${this.controllerName}-target="installmentsInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask keyup->${this.controllerName}#numberMask" placeholder="# Parcelas" type="tel" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="adjustedPaidInstallmentsForm"># Parcelas Já Pagas</label>
                                <input aria-describedby="adjustedPaidInstallmentsFormHelp" class="form-control f-065" id="adjustedPaidInstallmentsForm" data-${this.controllerName}-target="adjustedPaidInstallmentsInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask keyup->${this.controllerName}#numberMask" placeholder="# Parcelas Já Pagas" type="tel" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-center px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-3 px-0">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="chartInput" data-${this.controllerName}-target="chartAccountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                            </button>
                          </div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Plano de Contas</label>
                                <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" type="text" placeholder="Plano de Contas" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showProviders" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Fornecedor</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="paying" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Dia</li>
                                      <li data-status="defaulting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indimplente</li>
                                      <li data-status="finished" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Finalizado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--loans--dashboard").showTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.purchaseDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando ${controller.current_loan.description}`

        controller.medInputTarget.dataset.medId = controller.current_loan.med_id
        controller.medInputTarget.value = controller.current_loan.med_name
        controller.channelInputTarget.dataset.channelId = controller.current_loan.channel_id
        controller.channelInputTarget.value = controller.current_loan.channel_name
        controller.providerInputTarget.dataset.providerId = controller.current_loan.provider_id
        controller.providerInputTarget.value = controller.current_loan.provider_name
        controller.purchaseDateTarget.value = controller.current_loan.purchase_pretty
        controller.dueDayTarget.value = controller.current_loan.due_day
        controller.installmentsInputTarget.value = controller.current_loan.installments
        controller.adjustedPaidInstallmentsInputTarget.value = controller.current_loan.adjusted_paid_installments
        controller.descriptionInputTarget.value = controller.current_loan.description
        controller.chartInputTarget.dataset.filter = controller.current_loan.chart_id
        controller.chartInputTarget.dataset.text = controller.current_loan.chart_name
        controller.chartInputTarget.value = controller.current_loan.chart_name
        controller.purchaseAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_loan.purchase_amount)
        controller.amortizationAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_loan.amortization_amount)
        
        controller.kindDropdownTarget.value = controller.current_loan.kind
        controller.kindInputTarget.dataset.kind = controller.current_loan.kind
        controller.kindInputTarget.innerText = controller.current_loan.kind_pretty
        
        controller.statusDropdownTarget.value = controller.current_loan.status
        controller.statusInputTarget.dataset.status = controller.current_loan.status
        controller.statusInputTarget.innerText = controller.current_loan.status_pretty

        controller.application.channels.forEach(element => {
          if (element.id == controller.current_loan.channel_id) {
            controller.setMethodList(element)
          }
        })

        controller.methodDropdownTarget.value = controller.current_loan.method
        controller.methodInputTarget.dataset.method = controller.current_loan.method
        controller.methodInputTarget.innerText = controller.current_loan.method_pretty

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Empréstimo/Parcelamento`
        controller.purchaseAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.amortizationAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.installmentsInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").numberMask(0)
        controller.adjustedPaidInstallmentsInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").numberMask(0)

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `paying`
        controller.statusInputTarget.dataset.status = `paying`
        controller.statusInputTarget.innerText = `Em Dia`

        if (controller.application.current_med.id) {
          controller.current_med = controller.application.current_med
          controller.medInputTarget.dataset.medId = controller.application.current_med.id
          controller.medInputTarget.value = controller.application.current_med.name  
        }
        
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  numberMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToNumber(ev)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (channel.kind == `bank_account`) {
      methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
      methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
      methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

      this.methodDropdownTarget.value = ``
      this.methodInputTarget.innerText = ``
      this.methodInputTarget.dataset.method = ``
    } else if (channel.kind == `credit_card`) {
      methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Cartão de Crédito`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    } else if (channel.kind == `cash`) {
      methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Dinheiro`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    }

    this.methodListTarget.innerHTML = methodList
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = [`bank_account`]
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payable_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_payable_providers`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = false
      controller.getControllerByIdentifier(modalName).permission = `financial_payable_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.purchaseDateTarget.value == ``) {
        len += 1
      }

      if (controller.dueDayTarget.value == ``) {
        len += 1
      }

      if (controller.purchaseAmountInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.installmentsInputTarget.value == ``) {
        len += 1
      }

      if (controller.amortizationAmountInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      if (controller.adjustedPaidInstallmentsInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.providerInputTarget.value == ``) {
        len += 1
      }

      if (controller.methodInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.medInputTarget.value == ``) {
        len += 1
      }

      if (controller.chartInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
