import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable",
    "body", "searchInput", "searchTable", "originInput"]

  connect() {
    this.controllerName = `operations--products--tax-filings--settings--update-date-plan`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `desc`, field: `created_at_to_time` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doListHtml()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doListHtml() {

    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Selecione o Plano</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable keyup->${this.controllerName}#enterCloseModal" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Planos</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                              <tr class="itemRow" data-plan="light">
                                <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#closeModalEv">
                                  <span class="mb-0 f-065 mc-tooltip">
                                    <span>Light</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Selecionar</span>
                                  </span>
                                </td>
                              </tr>
                              <tr class="itemRow" data-plan="pro">
                                <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#closeModalEv">
                                  <span class="mb-0 f-065 mc-tooltip">
                                    <span>Pro</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Selecionar</span>
                                  </span>
                                </td>
                              </tr>
                              <tr class="itemRow" data-plan="premium">
                                <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#closeModalEv">
                                  <span class="mb-0 f-065 mc-tooltip">
                                    <span>Premium</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Selecionar</span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.dashboardController.listTarget.innerHTML = html)
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.45) + "px"

      controller.searchInputTarget.focus()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.close()
  }

  enterCloseModal(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var origins = []

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          plans[plans.length] = tr[i].dataset.plan
        }
      }

      if (plans.length) {
        this.saveDate(plans[0])
      }
    }
  }

  closeModalEv(ev) {
    var plan = ev.currentTarget.closest(".itemRow").dataset.plan

    this.saveDate(plan)
  }

  saveDate(plan) {
    this.actionMode = "edit"
    this.send_data = { current_user: {}, date: {}, tracker: { manual: true } }

    this.send_data.date.id = this.application.current_date.id
    this.send_data.date.product_id = this.application.tax_filing.id
    this.send_data.date.product_plan = plan
    
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `Atualizou o Plano da Competência ${this.application.current_date.financial_year}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.date_permission

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`

    var url = "/operations/products/tax_filings/dates/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {

          var date = response.data.cln
          date.data_loaded = true

          controller.application.dates.forEach((element, i) => {
            if (element.id == date.id) {
              controller.application.dates.splice(controller.application.dates.indexOf(element), 1, date)
            }
          })

          controller.application.current_date = response.data.cln
          controller.getControllerByIdentifier(`operations--products--tax-filings--show--main`).setTaxFilingName()
        } else {
          // this.nameTarget(`saveBtn-${data.date.id}`).classList.remove("d-none")
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        // controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.close()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }
  
  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}