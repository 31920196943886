import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "dueAtInput", "paidAtInput", "channelDropdown", "channelDropdownBtn", "channelInput",
                    "channelList", "sendBtn", "modalBody"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--shared--send-email`
    this.logController = this.getControllerByIdentifier(`operations--products--clinics--managements--logs--save`)

    // this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.setPartners()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sendEmail(ev) {
    this.send_data = { current_user: {}, tracker: {} }
    var func = ev.currentTarget.dataset.func

    var receivers = []

    this.current_partners.forEach(partner => {
      if (partner.status && partner.operational && this.nameTarget(`partnerCheckbox-${partner.id}`).checked) {
        receivers[receivers.length] = partner.id
      }
    })

    this.send_data.tracker.receivers = receivers
    this.send_data.tracker.sender_id = this.application.current_user.account_id
    this.send_data.tracker.sender_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.tracker.domain_id = this.current_clinic.id
    this.send_data.tracker.domain_type = `operation_products`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    eval(func)
    this.close()
  }

  sendMainApurationEmail() {
    this.send_data.main_apuration = {}
    this.send_data.main_apuration.id = this.current_obj.id
    this.send_data.main_apuration.clinic_id = this.application.clinic.id

    var url = "/operations/products/clinics/financials/main_apurations/send_main_apuration_email"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        var objName = `a Apuração Mensal Total da ${controller.application.clinic.company_name}`
        controller.saveEmailLog(url, objName)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendTaxEmail() {
    this.send_data.tax = {}
    this.send_data.tax.id = this.current_obj.id
    this.send_data.tax.clinic_id = this.application.clinic.id

    var url = "/operations/products/clinics/financials/taxes/send_tax_email"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        var objName = `a ${controller.current_obj.description}] da ${controller.application.clinic.company_name} com vencimento em ${controller.current_obj.due_at_pretty} no valor de ${controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(Math.round(controller.current_obj.principal * 100))}`
        controller.saveEmailLog(url, objName)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setPartners() {

    var partnerHtml = ``
    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_partners, `name`)

    partners.forEach(partner => {
      if (partner.status && partner.operational && partner.councils.length > 0) {
        partnerHtml += `<div class="row mb-3">
                          <div class="col-12">
                            <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                              <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}">
                              <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                            </div>
                          </div>
                        </div>`
      } else if (partner.status && partner.operational && partner.doctor == false) {
        partnerHtml += `<div class="row mb-3">
                          <div class="col-12">
                            <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                              <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}">
                              <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                            </div>
                          </div>
                        </div>`

      } else {
        var message = `Se o Médico Sócio não aparece ou (i) ele não é operacional ou (ii) está faltando alguma informação, como o CRM.
                        Favor voltar na Regularização e atualizar os dados`
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 5000)
      }
    })

    this.modalBodyTarget.innerHTML = partnerHtml
  }

  saveEmailLog(url, objName) {
    this.logController.log_note.action = `send`
    this.logController.log_note.obj_name = objName
    this.logController.log_note.body = `URL: ${url} | Parâmetros: ${JSON.stringify(this.send_data)}`
    this.logController.log_note.domain_id = this.current_clinic.id
    this.logController.log_note.domain_name = `taxes ${this.current_clinic.company_name}`
    this.logController.log_note.date_id = this.current_date.id
    this.logController.saveLogNote()
  }
  
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}