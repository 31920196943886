import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "save", "filters", "removeBoardFilterBtn", "today", "previous", "next", "chipDue",
                    "chipBoard", "removeDueFilterBtn", "removeFilterBtn", "chipFilter", "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "personalTicketCount"]

  connect() {
    this.controllerName = `users--works--activities--tickets--filter`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()
    this.hasBoardFilter = false
    this.hasDueFilter = false
    this.hasPriorityFilter = false
    this.feature = `list`
    this.typePreloader()
  }

  filterTicket(ev) {
    var filter = ev.currentTarget.dataset.filter
    var filterKind = ev.currentTarget.dataset.filterKind
    var filter_tickets = []
    filter_tickets = this.application.tickets

    if (["today", "previous", "next"].includes(filter)) {
      this.application.personal_board = false
      this.application.non_personal_board = true
      var date = new Date()
      var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      var data = []

      if (filter == `today`) {
        filter_tickets.forEach(element => {
          if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
            data[data.length] = element
          }
        })
        this.getControllerByIdentifier(`users--works--activities--tickets--list`).sort.mode = `desc`
      } else if (filter == `previous`) {
        filter_tickets.forEach(element => {
          if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
            data[data.length] = element
          }
        })
        this.getControllerByIdentifier(`users--works--activities--tickets--list`).sort.mode = `desc`
      } else if (filter == `next`) {
        filter_tickets.forEach(element => {
          if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
            data[data.length] = element
          }
        })
        this.getControllerByIdentifier(`users--works--activities--tickets--list`).sort.mode = `asc`
      }
      this.application.filter_tickets = data
    } else if (["low", "medium", "high", "maximum"].includes(filter)) {
      this.application.personal_board = false
      this.application.non_personal_board = true

      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (element.priority == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })

    } else if (filter == `personal_tickets`) {
      this.application.personal_board = true
      this.application.non_personal_board = false

      this.application.filter_tickets = this.application.personal_tickets
    } else {
      
    }

    if (filterKind == `board`) {
      this.application.personal_board = false
      this.application.non_personal_board = true
      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (element.board_type == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })
    } else if (filterKind == `owner`) {
      this.application.personal_board = false
      this.application.non_personal_board = true
      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (`owner_${element.owner_id}` == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })
    } else if (filterKind == `performer`) {
      this.application.personal_board = false
      this.application.non_personal_board = true
      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (`performer_${element.performer_id}` == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })
    }

    this.toggleFilter(filter)
    if (this.feature == `board`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardGrid()
    } else if (this.feature == `list` || this.feature == `resume`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--dashboard`).feature = `list`
      this.getControllerByIdentifier(`users--works--activities--tickets--dashboard`).toggleTicket()
      this.getControllerByIdentifier(`users--works--activities--tickets--list`).doListHtml()
    }
  }

  toggleFilter(filter) {
    this.removeFilterBtnTarget.classList.remove("d-none")
    this.chipFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.nameTarget(filter).classList.add("bg-primary")
  }

  removeFilter() {
    this.removeFilterBtnTarget.classList.add("d-none")
    this.chipFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.personal_board = true
    this.application.non_personal_board = true
    this.application.filter_tickets = this.application.tickets
    
    if (this.feature == `board`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardGrid()
    } else if (this.feature == `list`) {
      this.getControllerByIdentifier(`users--works--activities--tickets--list`).doListHtml()
    }
  }

  filterBoard(ev) {
    this.hasBoardFilter = true
    var filter = ev.currentTarget.dataset.filter
    var filter_tickets = []
    if (this.hasDueFilter) {
      filter_tickets = this.application.filter_tickets
    } else if (this.hasPriorityFilter) {
      filter_tickets = this.application.filter_tickets
    }

    filter_tickets = this.application.tickets
    this.application.filter_tickets = []
    filter_tickets.forEach(element => {
      if (element.board_type == filter) {
        this.application.filter_tickets[this.application.filter_tickets.length] = element
      }
    })

    this.toggleBoard(filter)
    this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardHeader()
  }

  toggleBoard(filter) {
    this.removeBoardFilterBtnTarget.classList.remove("d-none")
    this.chipBoardTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.nameTarget(filter).classList.add("bg-primary")
  }

  removeBoardFilter() {
    this.hasBoardFilter = false
    this.removeBoardFilterBtnTarget.classList.add("d-none")
    this.chipBoardTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.filter_tickets = this.application.tickets
    this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardHeader()
  }

  removeDueFilter() {
    this.hasDueFilter = false
    this.removeDueFilterBtnTarget.classList.add("d-none")
    this.chipDueTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.filter_tickets = this.application.tickets
    this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardHeader()
  }

  removePriorityFilter() {
    this.hasPriorityFilter = false
    this.removePriorityFilterBtnTarget.classList.add("d-none")
    this.chipPriorityTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.filter_tickets = this.application.tickets
    this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardHeader()
  }

  doFilterHeader() {
    var title = `<div class="col-12 text-center d-flex">
                    <span class="mx-auto">Filtros</span>
                    <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeFilterBtn">
                      <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeFilter">cancel</span>
                      <span class="mc-tooltiptext">Remover Filtros</span>
                    </span>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.titleTarget.innerHTML = title)
    }).then(() => {
      controller.doFilterData()
    })
  }

  doFilterData() {
    this.board_filters = []
    this.owner_filters = []
    this.performer_filters = []

    var date = new Date()
    var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)

    this.application.uniq_owner_ids.forEach((element, i) => {
      var filter = {}

      filter.board = `owner_${element}`
      filter.board_pretty = this.application.uniq_owner_names[i]
      filter.board_total = 0

      this.application.tickets.forEach(ticket => {
        if (ticket.owner_id == element) {
          filter.board_total += 1
        }
      })

      this.owner_filters[this.owner_filters.length] = filter
    })

    this.application.uniq_performer_ids.forEach((element, i) => {
      var filter = {}

      filter.board = `performer_${element}`
      filter.board_pretty = this.application.uniq_performer_names[i]
      filter.board_total = 0

      this.application.tickets.forEach(ticket => {
        if (ticket.performer_id == element) {
          filter.board_total += 1
        }
      })

      this.performer_filters[this.performer_filters.length] = filter
    })

    this.application.uniq_board_types.forEach((element, i) => {
      var filter = {}
      filter.board = element
      filter.board_pretty = this.application.uniq_board_types_pretty[i]
      filter.board_total = 0
      filter.board_today = 0
      filter.board_previous = 0
      filter.board_next = 0
      filter.board_low = 0
      filter.board_medium = 0
      filter.board_high = 0
      filter.board_maximum = 0

      this.application.tickets.forEach(ticket => {
        if (ticket.board_type == element) {
          filter.board_total += 1
        }
      })
      if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
        filter.board_today += 1
      }
      if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
        filter.board_previous += 1
      }
      if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
        filter.board_next += 1
      }

      if (element.priority == `low`) {
        filter.board_low += 1
      } else if (element.priority == `medium`) {
        filter.board_medium += 1
      } else if (element.priority == `high`) {
        filter.board_high += 1
      } else if (element.priority == `maximum`) {
        filter.board_maximum += 1
      }

      this.board_filters[this.board_filters.length] = filter
    })

    this.board_today = 0
    this.board_previous = 0
    this.board_next = 0

    this.board_low = 0
    this.board_medium = 0
    this.board_high = 0
    this.board_maximum = 0

    this.application.tickets.forEach(element => {
      if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
        this.board_today += 1
      }
      if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
        this.board_previous += 1
      }
      if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
        this.board_next += 1
      }

      if (element.priority == `low`) {
        this.board_low += 1
      } else if (element.priority == `medium`) {
        this.board_medium += 1
      } else if (element.priority == `high`) {
        this.board_high += 1
      } else if (element.priority == `maximum`) {
        this.board_maximum += 1
      }
    })

    this.doFilterHtml()
  }

  doFilterHtml() {
    //var board_filters = this.board_filters
    var board_filters = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.board_filters, `board_pretty`)
    var owner_filters = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.owner_filters, `board_pretty`)
    var performer_filters = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.performer_filters, `board_pretty`)
    var html = ``

    html += `<div class="col-12 text-center mt-3">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Prioridade</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removePriorityFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removePriorityFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    html += this.priorityPartial()

    html += `<div class="col-12 text-center mt-3">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Data</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeDueFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeDueFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    html += this.duePartial()

    html += `<div class="col-12 text-center">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Boards</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeBoardFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeBoardFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    if (this.application.personal_board) {
      html += `<div class="col-12 text-center px-0">
                <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="personal_tickets" data-${this.controllerName}-target="personal_tickets chipFilter" data-action="click->${this.controllerName}#filterTicket">
                  <span class="f-075">Meu Board</span>
                  <span class="f-075 text-bold ml-auto badge badge-secondary-dark" data-${this.controllerName}-target="personalTicketCount"></span>
                </div>
              </div>`
    } else {
      
    }

    if (board_filters == [] || board_filters.length == 0 || board_filters == undefined) {
      html += `<div class="col-12 my-2 text-center">
                  <span class="f-075 align-middle">Não há Boards</span>
                </div>`
    } else {
      board_filters.forEach(element => {
        html += this.boardPartial(element)
      })
    }

    html += `<div class="col-12 text-center">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Donos</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeOwnerFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeOwnerFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    owner_filters.forEach(element => {
      html += this.ownerPartial(element)
    })

    html += `<div class="col-12 text-center">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Executores</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removePerformerFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removePerformerFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    performer_filters.forEach(element => {
      html += this.performerPartial(element)
    })

    this.mainTarget.innerHTML = html
  }

  boardPartial(element) {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="${element.board}" data-filter-kind="board" data-${this.controllerName}-target="${element.board} chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">${element.board_pretty}</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${element.board_total}</span>
                  </div>
                </div>`

    return html
  }

  ownerPartial(element) {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="${element.board}" data-filter-kind="owner" data-${this.controllerName}-target="${element.board} chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">${element.board_pretty}</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${element.board_total}</span>
                  </div>
                </div>`

    return html
  }

  performerPartial(element) {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="${element.board}" data-filter-kind="performer" data-${this.controllerName}-target="${element.board} chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">${element.board_pretty}</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${element.board_total}</span>
                  </div>
                </div>`

    return html
  }

  duePartial() {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex pointer" data-filter="today" data-${this.controllerName}-target="today chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Hoje</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_today}</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex pointer" data-filter="previous" data-${this.controllerName}-target="previous chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Atrasadas</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_previous}</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex pointer" data-filter="next" data-${this.controllerName}-target="next chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Próximas</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_next}</span>
                  </div>
                </div>`

    return html
  }

  priorityPartial() {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip pointer" data-filter="low" data-${this.controllerName}-target="low chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Baixa</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_low}</span>
                    <span class="mc-tooltiptext">Não Urgente | Não Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip pointer" data-filter="medium" data-${this.controllerName}-target="medium chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Média</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_medium}</span>
                    <span class="mc-tooltiptext">Urgente | Não Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip pointer" data-filter="high" data-${this.controllerName}-target="high chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Alta</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_high}</span>
                    <span class="mc-tooltiptext">Não Urgente | Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip pointer" data-filter="maximum" data-${this.controllerName}-target="maximum chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">Máxima</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_maximum}</span>
                    <span class="mc-tooltiptext">Urgente | Importante</span>
                  </div>
                </div>`

    return html
  }
  
  typePreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
