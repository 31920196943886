import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["title", "board", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `financials--books--statements--dashboard--results`
  }

  doResultdsCards() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span data-action="click->${this.controllerName}#refreshCompilation">Resultados ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.titleTarget.innerHTML = title
    }
    
    if (this.application.current_med.id) {
      this.application.current_date.statement_calculations.forEach(calculation => {
        if (calculation.med_id == this.application.current_med.id) {
          this.calculation = calculation
        }
      })
    } else {
      this.calculation = this.application.current_date.statement_compilation
    }

    var html = `<div class="col-6 offset-3 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Receitas | <strong>${this.numberController.currencyOptionMask(this.calculation.total_revenues)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>
                <div class="col-6 offset-3 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Despesas | <strong>${this.numberController.currencyOptionMask(this.calculation.total_expenses)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>
                <div class="col-6 offset-3 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Impostos | <strong>${this.numberController.currencyOptionMask(this.calculation.total_taxes)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>
                <div class="col-6 offset-3 my-2 px-2">
                  <hr>
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Resultado | <strong>${this.numberController.currencyOptionMask(this.calculation.total_result)}</strong></span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      
                    </div>
                  </div>
                </div>`

    this.boardTarget.innerHTML = html
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`financials--books--statements--dashboard--main`).refreshCompilation()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
