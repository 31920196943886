import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["usersRow", "actions", "users", "viewNoUserCard", "mainCard", "5555", "userChip", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--real-time--users`
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()
    this.doIndexListHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Usuários Real Time | Últimos ${this.getControllerByIdentifier(`app--stats--trackers--real-time--actions`).range} minutos</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row pt-5" data-${this.controllerName}-target="usersRow" style="overflow:auto;"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--stats--trackers--real-time--dashboard").usersTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      
      if (controller.application.users_real_time.length == 0) {
        controller.squadPreloader()
      } else {
        controller.doDataTable()
      }
    })
  }

  doDataTable() {
    this.listData(this.application.users_real_time)
  }

  listData(data) {
    this.usersRowTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="card pt-5" style="width:100%;display:relative;" data-${this.controllerName}-target="viewNoUserCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-2 f-card110"><strong>Nenhum Usuário Online</strong></h6>
                      </div>
                    </div>`

      this.usersRowTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.usersRowTarget.insertAdjacentHTML("beforeend", this.userPartial(element))
      });
    }
  }

  userPartial(element) {

    var html = `<div class="col-6 text-center">
                  <span class="mc-tooltip grab">
                    <div class="chip fade show mb-3 chipSquad w-100 pointer" id="chipDismissible-${element[0]}" data-id="${element[0]}" data-${this.controllerName}-target="userChip" data-action="click->${this.controllerName}#filterByUser">
                      <span class="material-icons mr-1">face</span>
                      <span class="mr-1">${element[1]}</span>
                    </div>
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${element[2]}</span>
                  </span>
                  
                </div>`

    return html
  }

  filterByUser(ev) {

    this.userChipTargets.forEach(element => {
      element.classList.remove(`bg-light-primary`)
    })

    var chip = ev.currentTarget
    var userId = chip.dataset.id
    chip.classList.add(`bg-light-primary`)

    this.getControllerByIdentifier(`app--stats--trackers--real-time--actions`).hasFilter = true
    this.getControllerByIdentifier(`app--stats--trackers--real-time--actions`).user_id = userId
    this.getControllerByIdentifier(`app--stats--trackers--real-time--actions`).doDataTable()

  }
  
  squadPreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>`

    this.usersRowTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
