import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "receivers"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--emails--pre-email`
  }

  closeEmailModal() {
    if (this.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`)) {
      this.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).close()
    }
  }

  openEmailModal(title = `Selecione o(s) Sócio(s) para enviar o Email!`) {
    this.doEmailModalHtml(title)
  }

  doEmailModalHtml(title) {
    var controllerName = `operations--products--clinics--managements--emails--send`

    var html = `<div class="modal fade" data-hour="bottom" data-controller="${controllerName}" data-${controllerName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">${title}</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-controller="${this.sendControllerName}">
                        <div class="row my-2">
                          <div class="col-6 text-center" data-${controllerName}-target="receivers"></div>
                          <div class="col-6 text-center" data-${this.sendControllerName}-target="main"></div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${controllerName}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${controllerName}#sendEmail" data-${controllerName}-target="sendBtn">Enviar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerName).sendControllerName = controller.sendControllerName
    })
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}