import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `web--commercial--marketing--content--search--broadcast`

    if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)
    } else if (this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)
    }

    this.application.site_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.siteUpdateChannel = createChannel({ channel: 'Commercial::Marketing::Site::PrincipalChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        // controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "principal") {

          controller.application.site = data.cln
          if (controller.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
            controller.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).setLayout()
          } else if (controller.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)) {
            controller.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`).contentTarget.innerHTML = ``
            controller.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`).loadMobilePage()

          }

        } else if (data.kind == "apuration") {

        }
      },
      disconnected() {
        // controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  updateListen() {
    if (this.siteUpdateChannel) {
      this.siteUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
