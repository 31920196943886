import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "repository", "doing", "done", "field", "description", "performer",
                    "editField", "editDescription", "processName", "processWhom", "startedAt", "dueDate", "editProcessName",
                    "editProcessNameInput", "editProcessWhom", "editProcessWhomInput", "editDueDate", "editDueDateInput",
                    "editStartedAt", "editStartedAtInput", "editPerformer", "editPerformerDropdown",
                    "editPerformerInput", "performerSelected", "editDescription", "editDescriptionInput",
                    "doneJobs", "totalJobs", "checkboxPriority", "priority", "priorityDiv", "shareTask",
                    "editShareTask", "editShareTaskDropdown", "editShareTaskInput", "collapsePriorityElement",
                    "performerEdit", "jobInputField", "checkboxJob", "jobInput", "priorityHeader", "performerList",
    "sharingList", "delayedWarning", "checkboxDuration", "collapseDurationElement", "durationDiv", "durationSpan", "duration"]

  connect() {
    this.controllerName = `users--tasks--board--unit`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this

    if (this.id == window.location.href.split("#")[1]) {
      location.hash = ''
      location.hash.replace('#', '')
      const originalUrl = window.location.href.split("#")[0]
      const thisElement = this.element
      new Promise(function (resolve) {
        resolve(thisElement.classList.add("card-attention"))
      }).then(() => {
        setTimeout(function () { thisElement.classList.remove("card-attention"); location.hash = ''; }, 4000)
      })
    }
  }

  new() {
    this.doTaskPreloader()
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "stage") {
      var controller = document.querySelector('#stageContentIndexRow').usersTasksBoardStage
      var targetElement = controller.repositoryTarget
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "calendar") {
      var controller = document.querySelector('#calendarContentIndexRow').usersTasksBoardCalendar
      var targetElement = controller.daysTarget
    }
    const token = $('meta[name=csrf-token]').attr('content');
    const data = {
                    task: { owner_id: currentUser.id, performer_id: currentUser.id, board_id: board.id, priority: "low", process_name: "Tipo Processo",
                            process_whom: "Qual Processo", description: "Coloque aqui a Descrição da Atividade", started_at: new Date(), due_date: new Date(), stage: "repository",
                            duration: "quick" },
                    current_user: { current_user_id: currentUser.id }
                  }
    const url = "/users/tasks/entities/create"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var element = response.data.cln
          allBoardTasks[allBoardTasks.length] = element
          const html = this.taskPartial(element)
          controller.taskPreloaderTarget.remove()
          targetElement.insertAdjacentHTML("beforeend", html)
          setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setPriority(element.priority, "load"); }, 100)
          setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setDuration(element.duration, "load"); }, 100)
          tooltip()
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  saveProcessName(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.editProcessNameInputTarget.value = this.processNameTarget.innerText
      this.inlineEditorProcessName(ev)
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur") {
      const id = this.id
      const value = this.editProcessNameInputTarget.value.trim()
      this.inlineEditorProcessName(ev)
      if (value != this.processNameTarget.innerText) {
        this.processNameTarget.innerText = value
        const data = { task: { id: id, process_name: value }, current_user: { current_user_id: currentUser.id } }
        var controller = this
        var element = this.element
        this.requestUpdate(data)
      }
    }
  }

  saveProcessWhom(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.editProcessWhomInputTarget.value = this.processWhomTarget.innerText
      this.inlineEditorProcessWhom(ev)
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur") {
      const id = this.id
      const value = this.editProcessWhomInputTarget.value.trim()
      this.inlineEditorProcessWhom(ev)
      if (value != this.processWhomTarget.innerText) {
        this.processWhomTarget.innerText = value
        const data = { task: { id: id, process_whom: value }, current_user: { current_user_id: currentUser.id } }
        var controller = this
        var element = this.element
        this.requestUpdate(data)
      }
    }
  }

  saveStartedAt(ev) {
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      const id = this.id
      const value = this.editStartedAtInputTarget.value
      this.startedAtTarget.innerText = value
      this.inlineEditorStartedAt()
      const data = { task: { id: id, started_at: value }, current_user: { current_user_id: currentUser.id } }
      var controller = this
      var element = this.element
      this.requestUpdate(data)
    } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.inlineEditorStartedAt()
    }
  }

  saveDueDate() {
    const id = this.id
    const value = this.editDueDateInputTarget.value
    this.dueDateTarget.innerText = value
    if (value == "" || value == null) {
      processingSnackbar("danger", "A data não pode ficar em branco", 3000)
    } else {
      this.inlineEditorDueDate()
      const data = { task: { id: id, due_date: value }, current_user: { current_user_id: currentUser.id } }
      var controller = this
      var field = "due_date"
      this.requestUpdate(data, field, controller)
    }
  }

  savePerformer(e) {
    const id = this.id
    const performer = e.target.innerText
    const performerId = e.target.getAttribute('data-id')
    const html = `E: <span class="material-icons md-sm" data-toggle="tooltip" data-placement="top" title data-original-title="${performer}">face</span>`
    this.performerTarget.innerHTML = html
    this.performerTarget.dataset.name = performer
    this.inlineEditorPerformer()
    const data = { task: { id: id, performer_id: performerId }, current_user: { current_user_id: currentUser.id } }
    var controller = this
    var element = this.element
    this.requestUpdate(data)
    tooltip()
  }

  saveDescription(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.editDescriptionInputTarget.value = this.descriptionTarget.innerText
      this.inlineEditorDescription(ev)
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur") {
      const id = this.id
      const value = this.editDescriptionInputTarget.value.trim()
      this.inlineEditorDescription(ev)
      if (value != this.descriptionTarget.innerText) {
        this.descriptionTarget.innerText = value
        const data = { task: { id: id, description: value }, current_user: { current_user_id: currentUser.id } }
        var controller = this
        var element = this.element
        this.requestUpdate(data)
      }
    }
  }

  saveSharing(e) {
    const id = this.id
    const userId = parseInt(e.target.getAttribute('data-id'))
    const data = { sharing: { task_id: id, user_id: userId }, current_user: { current_user_id: currentUser.id } }
    this.editShareTaskInputTarget.innerText = ""
    this.inlineEditorShare()
    this.requestShared(data)
    tooltip()
  }

  inlineEditor() {
    if (this.userCanUpdate()) {
      var value = this.descriptionTarget.innerText
      this.fieldTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editFieldTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editDescriptionTarget.focus()
      this.editDescriptionTarget.value = value
    }
  }

  inlineEditorProcessName(ev) {
    if (this.userCanUpdate()) {
      if (ev.type == "click") {
        var value = this.processNameTarget.innerText
        this.processNameTarget.classList.add("d-none")
        this.editProcessNameTarget.classList.remove("d-none")
        this.editProcessNameInputTarget.value = value
        this.editProcessNameInputTarget.focus()  
      } else {
        this.processNameTarget.classList.remove("d-none")
        this.editProcessNameTarget.classList.add("d-none")
      }
    }
  }

  inlineEditorProcessWhom(ev) {
    if (this.userCanUpdate()) {
      if (ev.type == "click") {
        var value = this.processWhomTarget.innerText
        this.processWhomTarget.classList.add("d-none")
        this.editProcessWhomTarget.classList.remove("d-none")
        this.editProcessWhomInputTarget.value = value
        this.editProcessWhomInputTarget.focus()
      } else {
        this.processWhomTarget.classList.remove("d-none")
        this.editProcessWhomTarget.classList.add("d-none")
      }
    }
  }

  inlineEditorStartedAt() {
    if (this.userCanUpdate()) {
      pickDateStimulus($(`#card-${this.id} .form-pick-date`))
      var value = this.startedAtTarget.innerText
      this.startedAtTarget.classList.toggle("d-none")
      this.editStartedAtTarget.classList.toggle("d-none")
      this.editStartedAtInputTarget.focus()
      this.editStartedAtInputTarget.value = value
    }
  }

  inlineEditorDueDate() {
    if (this.userCanUpdate()) {
      pickDateStimulus($(`#card-${this.id} .form-pick-date`), this.id)
      var value = this.dueDateTarget.innerText
      this.dueDateTarget.classList.toggle("d-none")
      this.editDueDateTarget.classList.toggle("d-none")
      this.editDueDateInputTarget.focus()
      this.editDueDateInputTarget.value = value
    }
  }

  inlineEditorPerformer() {
    if (this.userCanUpdate()) {
      var value = this.performerTarget.dataset.name
      this.performerEditTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editPerformerTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editPerformerInputTarget.focus()
      this.editPerformerDropdownTarget.value = value
      this.editPerformerInputTarget.innerText = value
      var html = ``
      allUsers.forEach(user => {
        html += `<li data-action="click->${this.controllerName}#savePerformer" data-id="${user.id}" class="li-selector dark">${user.nickname}</li>`
      })
      this.performerListTarget.innerHTML = html
      floatingLabel();
      selector()
    }
  }

  inlineEditorDescription(ev) {
    if (this.userCanUpdate()) {
      if (ev.type == "click") {
        var value = this.descriptionTarget.innerText
        this.descriptionTargets.forEach((element) => {
          element.classList.add("d-none")
        })
        this.editDescriptionTargets.forEach((element) => {
          element.classList.remove("d-none")
        })
        this.editDescriptionInputTarget.value = value
        this.editDescriptionInputTarget.focus()
      } else {
        this.descriptionTargets.forEach((element) => {
          element.classList.remove("d-none")
        })
        this.editDescriptionTargets.forEach((element) => {
          element.classList.add("d-none")
        })
      }
    }
  }

  inlineEditorShare(e) {
    if (this.userCanUpdate()) {
      this.shareTaskTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editShareTaskTargets.forEach((element) => {
        element.classList.toggle("d-none")
      })
      this.editShareTaskInputTarget.focus()
      var html = ``
      const performerName = this.data.get("performerName")
      const currentUserName = this.getControllerByIdentifier("app--helpers--strings").titleize(currentUser.email.split("@")[0].split(".").join(" "))
      allUsers.forEach(user => {
        if ((user.nickname != performerName) && (user.nickname != currentUserName)) { 
          html += `<li data-action="click->${this.controllerName}#saveSharing" data-id="${user.id}" class="li-selector dark">${user.nickname}</li>`
        }
      });
      this.sharingListTarget.innerHTML = html
      floatingLabel();
      selector()
    }
  }

  cancelPerformerEdit() {
    this.inlineEditorPerformer()
  }

  cancelShareEdit() {
    this.inlineEditorShare()
  }

  cancelDescriptionEdit() {
    this.inlineEditorDescription()
  }

  canDrag(e) {
    if (this.userCanUpdate() == false) {
      e.preventDefault()
    }
  }

  saveStage() {
    const id = this.id
    var data = {}
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "stage") {
      var stage = this.element.parentElement.getAttribute("stage")
      data = { task: { id: id, stage: stage }, current_user: { current_user_id: currentUser.id } }
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "calendar") {
      var dueDate = this.element.parentElement.getAttribute("date")
      var dueDateAbbr = this.element.parentElement.dataset.abbr
      this.dueDateTarget.innerText = dueDateAbbr
      data = { task: { id: id, due_date: dueDate }, current_user: { current_user_id: currentUser.id } }
    }
    this.requestUpdate(data)
  }

  inactiveTask() {
    // if (this.userCanUpdate()) {
    if (currentUser.id == this.ownerId) {
      var result = confirm("Tem certeza que deseja apagar a atividade?")
      if (result) {
        $('[data-toggle="tooltip"]').each(function () {
          $(this).tooltip('hide');
        });
        const id = this.id
        var data = { task: { id: id, active: false }, current_user: { current_user_id: currentUser.id } }
        this.requestDelete(data, this.element)
      }
    }
  }

  setPriority(priority, state) {
    if (priority == "low") {
      this.priorityTarget.innerHTML = `<span class="material-icons md-150 pointer" data-priority="${priority}">minimize</span>`
      this.priorityDivTarget.dataset.originalTitle = "Baixa Prioridade"
      this.priorityHeaderTarget.className = `card-header p-0 border-top-move-priority-${priority}`
    } else if (priority == "medium") {
      this.priorityTarget.innerHTML = `<span class="material-icons md-150 pointer" data-priority="${priority}">drag_handle</span>`
      this.priorityDivTarget.dataset.originalTitle = "Média Prioridade"
      this.priorityHeaderTarget.className = `card-header p-0 border-top-move-priority-${priority}`
    } else if (priority == "high") {
      this.priorityTarget.innerHTML = `<span class="material-icons md-150 pointer" data-priority="${priority}">menu</span>`
      this.priorityDivTarget.dataset.originalTitle = "Alta Prioridade"
      this.priorityHeaderTarget.className = `card-header p-0 border-top-move-priority-${priority}`
    } else if (priority == "maximum") {
      this.priorityTarget.innerHTML = `<span class="material-icons md-150 pointer" data-priority="${priority}">reorder</span>`
      this.priorityDivTarget.dataset.originalTitle = "Máxima Prioridade"
      this.priorityHeaderTarget.className = `card-header p-0 border-top-move-priority-${priority}`
    }
    
    if (state == "update") {
      const id = this.id
      const data = { task: { id: id, priority: priority }, current_user: { current_user_id: currentUser.id } }
      this.requestUpdate(data)
    }
  }

  setDuration(duration, state) {
    this.durationTarget.innerHTML = `<span class="material-icons md-150 pointer badge badge-pill badge-${duration}" data-duration="${duration}">timer</span>`
    if (duration == "quick") {
      this.durationDivTarget.dataset.originalTitle = "15 Min"
    } else if (duration == "fast") {
      this.durationDivTarget.dataset.originalTitle = "30 Min"
    } else if (duration == "normal") {
      this.durationDivTarget.dataset.originalTitle = "1 Hora"
    } else if (duration == "long") {
      this.durationDivTarget.dataset.originalTitle = "3 Horas"
    } else if (duration == "large") {
      this.durationDivTarget.dataset.originalTitle = "6 Horas"
    }

    if (state == "update") {
      const id = this.id
      const data = { task: { id: id, duration: duration }, current_user: { current_user_id: currentUser.id } }
      this.requestUpdate(data)
    }
  }

  collapseJob(e) {
    if (this.userCanUpdate() == false) {
      if (this.hasJobInputFieldTarget) {
        this.jobInputFieldTarget.remove()
      }
      e.preventDefault()
    }
  }

  collapsePriority(e) {
    if (this.userCanUpdate()) {
      this.checkboxPriorityTargets.forEach(element => {
        if (element.dataset.priority == e.target.dataset.priority) {
          element.checked = true
        }
      });
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  collapseDuration(e) {
    if (this.userCanUpdate()) {
      this.checkboxDurationTargets.forEach(element => {
        if (element.dataset.duration == e.target.dataset.duration) {
          element.checked = true
        }
      });
    } else {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  choosePriority(e) {
    if (this.userCanUpdate()) {
      if (e.target.checked) {
        this.checkboxPriorityTargets.forEach(element => {
          if (element.id != e.target.id) {
            element.checked = false
          } else {
            this.setPriority(e.target.dataset.priority, "update")
          }
        })
      } else {
        e.target.checked = true
      }
    }
  }

  chooseDuration(e) {
    if (this.userCanUpdate()) {
      if (e.target.checked) {
        this.checkboxDurationTargets.forEach(element => {
          if (element.id != e.target.id) {
            element.checked = false
          } else {
            this.setDuration(e.target.dataset.duration, "update")
          }
        })
      } else {
        e.target.checked = true
      }
    }
  }

  countDoneJobs() {
    var countJobs = 0
    if (this.hasCheckboxJobTarget) {
      this.checkboxJobTargets.forEach(element => {
        if (element.checked) {
          countJobs++
        }
      }) 
    }
    this.doneJobs(countJobs)
  }

  requestUpdate(data, field, controller) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/entities/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var task = response.data.cln
          allBoardTasks.forEach((element, i) => {
            if (element.id == task.id) {
              allBoardTasks.splice(allBoardTasks.indexOf(element), 1, task)
            }
          });

          if (field == "due_date") {
            var today = new Date()
            var yesterday = today.setDate(today.getDate() - 1)
            if (Date.parse(task.due_date) > yesterday) {
              controller.delayedWarningTarget.innerHTML = ``
            } else {
              controller.delayedWarningTarget.innerHTML = `<span  class="py-1 px-2 bg-danger w-100">ATIVIDADE ATRASADA<span>`
            }
          }
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  requestDelete(data, domElement) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/entities/delete"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var task = response.data.cln
          allBoardTasks.forEach((element, i) => {
            if (element.id == task.id) {
              allBoardTasks.splice(allBoardTasks.indexOf(element), 1)
            }
          });
          domElement.remove()
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  requestShared(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/sharings/save"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        processingSnackbar(response.type, response.message, device, 3000)
      })
  }
  

  taskPartial(element) {
    var html = `<div id="card-${element.id}" class="card p-0 my-2 board-flag-${element.flag}" data-controller="${this.controllerName}" draggable="true" ondragstart="dragStart(event)" data-action="dragend->${this.controllerName}#saveStage dragstart->${this.controllerName}#canDrag" data-${this.controllerName}-id="${element.id}" data-${this.controllerName}-performer-name="${element.performer_name}" data-${this.controllerName}-owner-id="${element.owner_id}" data-${this.controllerName}-performer-id="${element.performer_id}" data-${this.controllerName}-stage="${element.stage}">
                    <div class="card-header p-0 border-top-move-priority-${element.priority}" data-${this.controllerName}-target="priorityHeader"></div>`
                    if (element.delayed) {
                      // html += `<div class="row py-1 px-2 bg-danger" data-${this.controllerName}-target="delayedWarning"><span  class="py-1 px-2 bg-danger" data-${this.controllerName}-target="delayedWarning">ATIVIDADE ATRASADA<span></div>`
                      html += `<div class="row" data-${this.controllerName}-target="delayedWarning"><span  class="py-1 px-2 bg-danger w-100">ATIVIDADE ATRASADA<span></div>`
                    }
            html += `<div class="row py-1 px-2">
                      <div class="col-4 px-0 d-flex align-items-center pointer" data-${this.controllerName}-target="processName" data-action="click->${this.controllerName}#inlineEditorProcessName" data-toggle="tooltip" data-placement="top" title data-original-title="Tipo de Processo">${element.process_name}</div>
                      <div class="col-4 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="editProcessName">
                        <input autofocus data-${this.controllerName}-target="editProcessNameInput" data-action="keyup->${this.controllerName}#saveProcessName change->${this.controllerName}#saveProcessName blur->${this.controllerName}#saveProcessName" class="form-control p-1 s-title-0p6rem" type="text" required>
                      </div>
                      <div class="col-7 px-0 d-flex align-items-center pointer" data-${this.controllerName}-target="processWhom" data-action="click->${this.controllerName}#inlineEditorProcessWhom" data-toggle="tooltip" data-placement="top" title data-original-title="A qual processo se refere">${element.process_whom}</div>
                      <div class="col-7 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="editProcessWhom">
                        <input autofocus data-${this.controllerName}-target="editProcessWhomInput" data-action="keyup->${this.controllerName}#saveProcessWhom change->${this.controllerName}#saveProcessWhom blur->${this.controllerName}#saveProcessWhom" class="form-control p-1 s-title-0p6rem" type="text" required>
                      </div>
                      <div class="col-1 px-0 d-flex align-items-center text-right"><span class="material-icons md-sm ml-auto pointer" data-action="click->${this.controllerName}#inactiveTask" data-toggle="tooltip" data-placement="top" title data-original-title="Apagar Atividade?">delete</span></div>
                    </div>

                    <div class="row py-1 px-2 d-flex align-items-center">
                      <div class="col-3 px-0 d-flex align-items-center pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Data Início" data-action="click->${this.controllerName}#inlineEditorStartedAt" data-${this.controllerName}-target="startedAt">${element.started_at_pretty}</div>
                      <div class="col-3 px-0 d-flex align-items-center d-none" data-${this.controllerName}-target="editStartedAt">
                        <input autofocus data-${this.controllerName}-target="editStartedAtInput" data-action="keyup->${this.controllerName}#saveStartedAt" class="form-control p-1 s-title-0p6rem form-pick-date" type="text" required>
                        <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#saveStartedAt" id="btnSaveTask">save</span>
                      </div>
                      <div class="col-3 px-0 d-flex align-items-center pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Prazo Entrega" data-action="click->${this.controllerName}#inlineEditorDueDate" data-${this.controllerName}-target="dueDate">${element.due_date_pretty}</div>
                      <div class="col-3 px-0 d-flex align-items-center d-none" data-${this.controllerName}-target="editDueDate">
                        <input autofocus data-${this.controllerName}-target="editDueDateInput" data-action="keyup->${this.controllerName}#saveDueDate" class="form-control p-1 s-title-0p6rem form-pick-date" type="text" required>
                        <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#saveDueDate" id="btnSaveTask">save</span>
                      </div>
                      <div class="col-2 px-0 text-center pointer" data-${this.controllerName}-target="shareTask performerEdit">D: <span class="material-icons md-sm" data-toggle="tooltip" data-placement="top" title data-original-title="${element.owner_name}">face</span></div>
                      <div class="col-2 px-0 text-center pointer" data-action="click->${this.controllerName}#inlineEditorPerformer" data-name=${element.performer_name} data-${this.controllerName}-target="performer shareTask performerEdit">E: <span class="material-icons md-sm" data-toggle="tooltip" data-placement="top" title data-original-title="${element.performer_name}">face</span></div>
                      <div class="col-4 px-0 d-none" data-${this.controllerName}-target="editPerformer">
                        <div class="form-group board-task-filter my-0">
                          <div class="floating-label floating-label-sm">
                            <label>Executor</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" id="operatorFilterDropdownSelector" data-${this.controllerName}-target="editPerformerDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" id="operatorFilterDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="performerDropdownSelectedItem" data-${this.controllerName}-target="editPerformerInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector" aria-labelledby="operatorFilterDropdownMenuButton">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="performerList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1 px-0 d-flex align-items-center text-right d-none" data-action="click->${this.controllerName}#cancelPerformerEdit" data-${this.controllerName}-target="editPerformer"><span class="material-icons md-sm ml-auto pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Cancelar Ação">clear</span></div>
                      <div class="col-2 px-0 text-center pointer" data-action="click->${this.controllerName}#inlineEditorShare" data-${this.controllerName}-target="shareTask performerEdit"><span class="material-icons md-sm" data-toggle="tooltip" data-placement="top" title data-original-title="Compartilhar Atividade">person_add</span></div>
                      <div class="col-4 px-0 d-none" data-${this.controllerName}-target="editShareTask">
                        <div class="form-group board-task-filter my-0">
                          <div class="floating-label floating-label-sm">
                            <label>Compartilhar</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector multiple" data-${this.controllerName}-target="editShareTaskDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" data-${this.controllerName}-target="editShareTaskInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sharingList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-1 px-0 d-flex align-items-center text-right d-none" data-action="click->${this.controllerName}#cancelShareEdit" data-${this.controllerName}-target="editShareTask"><span class="material-icons md-sm ml-auto pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Cancelar Ação">clear</span></div>
                    </div>

                    <div class="row p-2">
                      <div class="col-7 px-0 d-flex align-items-center pointer text-left" data-${this.controllerName}-target="description" data-action="click->${this.controllerName}#inlineEditorDescription">
                        ${element.description}
                      </div>
                      <div class="col-12 px-0 d-flex align-items-center d-none" data-${this.controllerName}-target="editDescription">
                        <textarea autofocus data-${this.controllerName}-target="editDescriptionInput" data-action="keyup->${this.controllerName}#saveDescription blur->${this.controllerName}#saveDescription" class="form-control textarea p-1 s-title-0p6rem" type="text" required rows="3"></textarea>
                      </div>
                      <div class="col-2 px-0 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title data-original-title="Tarefas" data-${this.controllerName}-target="description" data-action="click->${this.controllerName}#collapseJob">
                        <span><span class="material-icons md-150 pointer" data-toggle="collapse" href="#collapseJobs-${element.id}">check_box</span><span data-${this.controllerName}-target="doneJobs">0</span>/<span data-${this.controllerName}-target="totalJobs">${element.jobs[0].length}</span></span>
                      </div>
                      <div class="col-1 px-0 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title data-original-title="Comentários" data-${this.controllerName}-target="description">
                        <span class="material-icons md-150 pointer" data-toggle="collapse" href="#collapseComments-${element.id}">comment</span>
                      </div>
                      <div class="col-1 px-0 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title data-original-title="" data-${this.controllerName}-target="description priorityDiv" data-action="click->${this.controllerName}#collapsePriority">
                        <span data-toggle="collapse" href="#collapsePriorities-${element.id}">
                          <span data-${this.controllerName}-target="priority" data-priority="${element.priority}"></span>
                        </span>
                      </div>

                      <div class="col-1 px-0 d-flex align-items-center justify-content-center" data-toggle="tooltip" data-placement="top" title data-original-title="" data-${this.controllerName}-target="description durationDiv" data-action="click->${this.controllerName}#collapseDuration">
                        <span data-toggle="collapse" href="#collapseDuration-${element.id}">
                          <span data-${this.controllerName}-target="duration" data-duration="quick"></span>
                        </span>
                        
                      </div>`
                      // `<span class="material-icons md-150 pointer badge badge-pill badge-quick" data-toggle="collapse" href="#collapseDuration-${element.id}" data-${this.controllerName}-target="durationSpan">timer</span>`
                      // <div class="col-1 px-1 d-flex align-items-start flex-column justify-content-center d-none" data-${this.controllerName}-target="editDescription">
                      //   <span data-action="click->${this.controllerName}#saveDescription"><span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Salvar">save</span></span>
                      //   <span data-action="click->${this.controllerName}#cancelDescriptionEdit"><span class="material-icons md-sm ml-auto pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Cancelar Ação">clear</span></span>
                      // </div>
            html += `</div>
                    <div id="collapseComments-${element.id}" class="collapse" data-users--tasks--board--comment-task-id="${element.id}" data-parent="#card-${element.id}" data-controller="users--tasks--board--comment" data-action="collapse->users--tasks--board--comment#rollMessages">
                      <div class="card m-2">
                        <div class="card-body comment-card px-1 py-0" id="commentBody-${element.id}" data-users--tasks--board--comment-target="list">`
                          element.comments[0].forEach(comment => {
                            html += document.querySelector(`#newTaskBtn`).usersTasksBoardComment.commentPartial(comment)
                          })
              html += `</div>
                        <div class="card-footer p-0 pb-1">
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-10 px-1 text-left">
                              <input type="text" class="form-control s-title-0p6rem" placeholder="Digite seu comentário ..." aria-label="Digite seu comentário ..." data-users--tasks--board--comment-target="input" data-action="keyup->users--tasks--board--comment#save keyup->users--tasks--board--comment#characterCount">
                              <span data-users--tasks--board--comment-target="characterCount"></span>
                            </div>
                            <div class="col-1 px-1">
                              <span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Anexar Documento" data-action="click->users--tasks--board--comment#attach">attach_file</span>
                            </div>
                            <div class="col-1 px-1">
                              <span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Enviar" data-action="click->users--tasks--board--comment#save">send</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="collapseJobs-${element.id}" class="collapse" data-users--tasks--board--job-task-id="${element.id}" data-parent="#card-${element.id}" data-controller="users--tasks--board--job">
                      <div class="card m-2">
                        <div class="card-body job-card px-1 py-0" id="jobBody-${element.id}" data-users--tasks--board--job-target="list">`
                          element.jobs[0].forEach(job => {
                            html += document.querySelector(`#newTaskBtn`).usersTasksBoardJob.jobPartial(job)
                          })
                html += `</div>
                        <div class="card-footer p-0 pb-1" data-${this.controllerName}-target="jobInputField">
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-11 px-1 text-left">
                              <input autofocus type="text" class="form-control s-title-0p6rem" placeholder="Adicione uma Tarefa ..." aria-label="Adicione uma Tarefa ..." data-users--tasks--board--job-target="input" data-${this.controllerName}-target="jobInput" data-action="keyup->users--tasks--board--job#save keyup->users--tasks--board--job#characterCount">
                              <span data-users--tasks--board--job-target="characterCount"></span>
                            </div>
                            <div class="col-1 px-1">
                              <span class="material-icons md-150" data-toggle="tooltip" data-placement="top" title data-original-title="Salvar" data-action="click->users--tasks--board--job#save">save</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="collapsePriorities-${element.id}" class="collapse" data-parent="#card-${element.id}" data-${this.controllerName}-target="collapsePriorityElement">
                      <div class="card m-2">
                        <div class="card-body job-card px-1 py-0">
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="lowpriority-${element.id}" data-priority="low" data-${this.controllerName}-target="checkboxPriority" data-action="click->${this.controllerName}#choosePriority">
                                <label class="custom-control-label pointer" for="lowpriority-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">Baixa Prioridade</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="mediumpriority-${element.id}" data-priority="medium" data-${this.controllerName}-target="checkboxPriority" data-action="click->${this.controllerName}#choosePriority">
                                <label class="custom-control-label pointer" for="mediumpriority-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">Média Prioridade</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="highpriority-${element.id}" data-priority="high" data-${this.controllerName}-target="checkboxPriority" data-action="click->${this.controllerName}#choosePriority">
                                <label class="custom-control-label pointer" for="highpriority-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">Alta Prioridade</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="maximumpriority-${element.id}" data-priority="maximum" data-${this.controllerName}-target="checkboxPriority" data-action="click->${this.controllerName}#choosePriority">
                                <label class="custom-control-label pointer" for="maximumpriority-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">Máxima Prioridade</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div id="collapseDuration-${element.id}" class="collapse" data-parent="#card-${element.id}" data-${this.controllerName}-target="collapseDurationElement">
                      <div class="card m-2">
                        <div class="card-body job-card px-1 py-0">
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="quickduration-${element.id}" data-duration="quick" data-${this.controllerName}-target="checkboxDuration" data-action="click->${this.controllerName}#chooseDuration">
                                <label class="custom-control-label pointer" for="quickduration-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">15 min</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="fastduration-${element.id}" data-duration="fast" data-${this.controllerName}-target="checkboxDuration" data-action="click->${this.controllerName}#chooseDuration">
                                <label class="custom-control-label pointer" for="fastduration-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">30 min</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="normalduration-${element.id}" data-duration="normal" data-${this.controllerName}-target="checkboxDuration" data-action="click->${this.controllerName}#chooseDuration">
                                <label class="custom-control-label pointer" for="normalduration-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">1 Hora</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="longduration-${element.id}" data-duration="long" data-${this.controllerName}-target="checkboxDuration" data-action="click->${this.controllerName}#chooseDuration">
                                <label class="custom-control-label pointer" for="longduration-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">3 Horas</div>
                          </div>
                          <div class="row my-1 py-1 job-item">
                            <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                              <div class="custom-control custom-checkbox pl-1">
                                <input type="checkbox" class="custom-control-input" id="largeduration-${element.id}" data-duration="large" data-${this.controllerName}-target="checkboxDuration" data-action="click->${this.controllerName}#chooseDuration">
                                <label class="custom-control-label pointer" for="largeduration-${element.id}"></label>
                              </div>
                            </div>           
                            <div class="col-10 px-1 d-flex align-items-center">6 Horas</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`
    return html
  }

  get id() {
    return parseInt(this.data.get("id"))
  }

  get field() {
    return parseInt(this.data.get("field"))
  }

  get performerId() {
    return parseInt(this.data.get("performerId"))
  }

  get ownerId() {
    return parseInt(this.data.get("ownerId"))
  }

  get stage() {
    return this.data.get("stage")
  }

  userCanUpdate() {
    const allowed = [this.ownerId, this.performerId]
    if (allowed.includes(currentUser.id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  doneJobs(value) {
    this.doneJobsTarget.innerText = parseInt(value) + parseInt(this.doneJobsTarget.innerText)
  }

  totalJobs(value) {
    this.totalJobsTarget.innerText = parseInt(value) + parseInt(this.totalJobsTarget.innerText)
  }

  doTaskPreloader() {
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "stage") {
      var targetElement = document.querySelector('#stageContentIndexRow').usersTasksBoardStage.repositoryTarget
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "calendar") {
      var targetElement = document.querySelector('#calendarContentIndexRow').usersTasksBoardCalendar.daysTarget
    }
    const html = `<div class='card timeline-item my-2' data-users--tasks--board--stage-target="taskPreloader" data-users--tasks--board--calendar-target="taskPreloader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>`
    targetElement.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}