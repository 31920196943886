import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "step", "form", "view", "stepIcon", "confirmBtn", "emailCode", "forwardBtn", "optInEvent",
                    "admissionName", "admissionPhone", "errorEmailCode", "stepText", "contactBtn"]

  connect() {
    this.controllerName = `mobile--commercial--marketing--events--live--meeting`
    this.canConfirm = false
  }

  doCheckinHtml() {

    // `<div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="2">
    //   <div class="text-center w-100">
    //     <span class="material-icons" data-${this.controllerName}-target="stepIcon">portrait</span>
    //   </div>
    //   <span class="stepper-text">Avatar</span>
    // </div>`

    var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center" data-${this.controllerName}-target="step" data-step="0"">
                    <p class="mb-0"><span class="material-icons md-primary md-lg" data-${this.controllerName}-target="stepIcon">lock</span></p>
                    <p class="mb-0"><span class="stepper-text f-1p25" data-${this.controllerName}-target="stepText">Ingresso</span></p>
                  </div>
                </div>
                <div class="row my-5">
                  <div class="col-10 offset-1 d-flex align-items-center justify-content-center" data-${this.controllerName}-target="form">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--dashboard").mainTarget.innerHTML = html)
    }).then(() => {
      controller.doStepCode()
    })
  }

  forward(ev) {
    var step = ev.currentTarget.closest(".card-live").dataset.step

    if (step == 0) {
      this.doStepInfo()
    } else if (step == 1) {
      // this.doStepAvatar()
      this.doStepLive()
    } else if (step == 2) {
      // this.doStepLive()
    } else if (step == 3) {
      this.saveMeeting()
    }
  }

  doStepCode() {
    var html = `<div class="card card-live" data-step="0" data-${this.controllerName}-target="cardStep-0">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 my-4 text-center px-0">
                        <h4>Insira no campo abaixo o E-mail que você utilizou para a inscrição:</h4>
                        <p>Se ainda não se inscreveu, <span class="badge badge-secondary-primary pointer" data-action="click->${this.controllerName}#goToEnrollment">clique aqui</span> para se inscrever</p>
                      </div>
                      <div class="col-12 my-4 px-0">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="med-input-icon"><span class="material-icons md-sm md-primary">alternate_email</span></span>
                            </div>
                            <input type="text" class="form-control med-input ml-3" placeholder="Insira o seu e-mail aqui" data-${this.controllerName}-target="emailCode" data-action="keyup->${this.controllerName}#validateEmail focus->${this.controllerName}#removeInvalid">
                            <div data-${this.controllerName}-target="errorEmailCode"></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                        <div class="card none w-100 bg-disabled" data-action="click->${this.controllerName}#confirmInfo" data-${this.controllerName}-target="confirmBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Confirmar
                          </div>
                        </div>
                        <div class="card pointer w-100 bg-success d-none" data-action="click->${this.controllerName}#forward" data-${this.controllerName}-target="forwardBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Avançar
                          </div>
                        </div>
                        <div class="card pointer w-100 bg-danger d-none" data-step="1" data-action="click->${this.controllerName}#contactWhatsApp" data-${this.controllerName}-target="contactBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Favor entrar em contato com o nosso WhatsApp
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
    this.stepTarget.dataset.step = `0`
    this.stepIconTarget.innerText = `lock`
    this.stepTextTarget.innerText = `Ingresso`
  }

  contactWhatsApp() {
    var url = `https://api.whatsapp.com/send?phone=5531996421912&text=Olá,%20preciso%20de%20ajuda%20!`
    window.open(url, `_blank`)
  }

  validateEmail(ev) {
    if (ev.type == "keyup") {
      var email = ev.currentTarget.value
      if (email.includes("@") && email.length > 11) {
        this.toggleBtns(true)
      } else {
        this.toggleBtns(false)
      }
    }
  }

  removeInvalid(ev) {
    this.errorEmailCodeTarget.classList.remove("invalid-warning", "my-1", "py-1", "f-1")
    this.errorEmailCodeTarget.style.zIndex = 0
    this.emailCodeTarget.classList.remove("invalid-field")
    this.errorEmailCodeTarget.innerText = ""
  }

  confirmInfo() {
    if (this.canConfirm) {
      this.canConfirm = false
      this.send_data = { admission: {}, event: {} }

      this.send_data.admission.email = this.emailCodeTarget.value.trim().toLowerCase()
      this.send_data.event.id = this.application.event.id

      this.getAdmission()
    }
  }

  getAdmission() {
    const url = "/commercial/marketing/events/admissions/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.canConfirm = true
        if (response.process) {
          controller.application.admission = response.data.cln.admission
          controller.application.attendee = response.data.cln.attendee

          if (controller.application.attendee.is_valid) {
            controller.confirmBtnTarget.classList.add("d-none")
            controller.forwardBtnTarget.classList.remove("d-none")
          } else {
            controller.confirmBtnTarget.classList.add("d-none")
            controller.contactBtnTarget.classList.remove("d-none")
          }
        } else {
          controller.errorEmailCodeTarget.classList.add("invalid-warning", "my-1", "py-1", "f-1")
          controller.errorEmailCodeTarget.style.zIndex = 10
          controller.emailCodeTarget.classList.add("invalid-field")
          controller.errorEmailCodeTarget.innerText = response.message

          controller.confirmBtnTarget.classList.add("d-none")
          controller.contactBtnTarget.classList.remove("d-none")
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doStepInfo() {
    var html = `<div class="card card-live" data-step="1" data-${this.controllerName}-target="cardStep-1">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 my-4 text-center px-0">
                        <h3>Insira no campo abaixo o E-mail que você utilizou para a inscrição:</h3>
                      </div>
                      <div class="col-12 my-4 px-0">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="med-input-icon"><span class="material-icons md-sm md-primary">perm_identity</span></span>
                            </div>
                            <input type="text" class="form-control med-input f-1p25 ml-3" placeholder="Nome" data-${this.controllerName}-target="admissionName">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-4 px-0">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="med-input-icon"><span class="material-icons md-sm md-primary">local_phone</span></span>
                            </div>
                            <input type="text" class="form-control med-input f-1p25 ml-3" placeholder="Nome" data-${this.controllerName}-target="admissionPhone" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask">
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="form-group form-check d-flex align-items-center">
                          <input type="checkbox" class="form-check-input" name="optInEvent" data-${this.controllerName}-target="optInEvent" data-confirm="false" data-action="click->${this.controllerName}#confirmOptIn">
                          <label class="form-check-label" for="optInEvent" style="margin-left:1rem;"> Eu concordo com os <a class="a-white" href="/termos-de-uso-e-politica-privacidade" target="blank">Termos de Uso e Política de Privacidade</a></label>
                        </div>
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                        <div class="card none w-100 bg-disabled" data-action="click->${this.controllerName}#saveAttendee" data-${this.controllerName}-target="confirmBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Confirmar
                          </div>
                        </div>
                        <div class="card pointer w-100 bg-success d-none" data-action="click->${this.controllerName}#forward" data-${this.controllerName}-target="forwardBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Avançar
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.stepTarget.dataset.step = `1`
      controller.stepIconTarget.innerText = `fingerprint`
      controller.stepTextTarget.innerText = `Informações`
      controller.refreshInfoConfirmBtn()
      if (controller.application.attendee) {
        if (controller.application.attendee.last_name == null || controller.application.attendee.last_name == undefined) {
          controller.admissionNameTarget.value = `${controller.application.attendee.name}`
        } else {
          controller.admissionNameTarget.value = `${controller.application.attendee.name} ${controller.application.attendee.last_name}`
        }
        controller.admissionPhoneTarget.value = controller.application.attendee.phone_pretty
      }
    })
  }

  confirmOptIn(ev) {
    this.send_data = { current_visit: {}, opt: {} }

    this.send_data.current_visit.current_visit_id = this.application.current_visit_id
    this.send_data.current_visit.current_visit_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visit")
    this.send_data.current_visit.has_data = true
    this.send_data.opt.id = ""
    this.send_data.opt.opt_in = true
    this.send_data.opt.opt_in_at = new Date()
    this.send_data.opt.name = this.application.event.title
    this.send_data.opt.obj_id = this.application.event.id
    this.send_data.opt.obj_type = "event_entity"
    this.send_data.opt.user_id = this.application.attendee.id
    this.send_data.opt.user_type = "event_attendee"

    this.saveOptIn()
  }

  saveOptIn() {
    const url = "/users/opts/save"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.canConfirm = true
        if (response.process) {
          controller.optInEventTarget.disabled = true
          controller.optInEventTarget.dataset.confirm = true
        } else {
          // controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--meeting").doCheckinHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveAttendee() {
    if (this.canConfirm == true) {
      this.canConfirm = false
      this.send_data = { attendee: {} }

      var phone = this.admissionPhoneTarget.value.trim()

      if (phone.length == 16) {
        var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(0, 2)
        var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(2, 11)
      } else {
        var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
        var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
      }

      this.send_data.attendee.id = this.application.attendee.id
      this.send_data.attendee.name = this.admissionNameTarget.value.trim()
      this.send_data.attendee.last_name = null
      this.send_data.attendee.ddd = ddd
      this.send_data.attendee.number = number

      this.requestSaveAttendee()
    }
  }

  requestSaveAttendee() {
    this.stopRefreshing()
    const url = "/commercial/marketing/events/attendees/save_public"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.canConfirm = true
          controller.confirmBtnTarget.classList.add("d-none")
          controller.forwardBtnTarget.classList.remove("d-none")
        } else {
          controller.toggleBtns(false)
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToFullPhone(ev)
  }

  doStepLive() {
    var html = `<div class="card card-live" data-step="3" data-${this.controllerName}-target="cardStep-3">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 my-4 text-center px-0">
                        <h3>Pronto!</h3>
                      </div>
                      <div class="col-12 my-4 px-0">
                        <h4>Bom evento para você! O seu feedback é muito importante para nós!</h4>
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                        <div class="card pointer w-100 bg-primary" data-action="click->${this.controllerName}#forward" data-${this.controllerName}-target="forwardBtn">
                          <div class="card-header p-1 text-center f-1p25 f-bold">
                            Ir para o Evento!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
    this.stepTarget.dataset.step = `3`
    this.stepIconTarget.innerText = `live_tv`
    this.stepTextTarget.innerText = `Go Live!`
  }

  saveMeeting() {
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    if (this.canConfirm == true) {
      this.send_data = { current_visit: {}, meeting: {} }

      this.send_data.current_visit.current_visit_id = this.application.current_visit_id
      this.send_data.current_visit.visitor_token = this.application.current_visitor_token
      this.send_data.meeting.event_id = this.application.event.id
      this.send_data.meeting.attendee_id = this.application.attendee.id
      this.send_data.meeting.ticket_id = this.application.admission.id
      this.send_data.meeting.name = this.application.event.title
      this.send_data.meeting.duration = this.application.event.id
      this.send_data.meeting.started_at = new Date()

      this.requestSaveMeeting()
    }
  }

  requestSaveMeeting() {
    const url = "/commercial/marketing/events/meetings/save_public"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.canConfirm = true
        if (response.save) {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--dashboard").doPublicPageGrid()
        } else {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--meeting").doCheckinHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  toggleBtns(value) {
    if (value) {
      this.confirmBtnTarget.classList.remove("bg-disabled")
      this.confirmBtnTarget.classList.remove("none")
      this.confirmBtnTarget.classList.add("bg-primary")
      this.confirmBtnTarget.classList.add("pointer")
      this.canConfirm = true
    } else {
      this.confirmBtnTarget.classList.add("bg-disabled")
      this.confirmBtnTarget.classList.add("none")
      this.confirmBtnTarget.classList.remove("bg-primary")
      this.confirmBtnTarget.classList.remove("pointer")
      this.canConfirm = false
    }
  }

  goToEnrollment() {
    var url = this.application.event.enrollment_link
    window.open(url, `_self`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshInfoConfirmBtn() {
    var controller = this
    this.refreshInfoTimer = setInterval(function () {
      var len = 0

      if (controller.admissionNameTarget.value == ``) {
        len += 1
      }

      if (controller.admissionPhoneTarget.value == `` || controller.admissionPhoneTarget.length < 16) {
        len += 1
      }

      if (controller.optInEventTarget.dataset.confirm == true || controller.optInEventTarget.dataset.confirm == "true") {
        len += 0
      } else {
        len += 1
      }


      if (len == 0) {
        controller.toggleBtns(true)
      } else {
        controller.toggleBtns(false)
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshInfoTimer) {
      clearInterval(this.refreshInfoTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  inputValidation(element, validation, message) {
    if (validation) {
      element.classList.remove("invalid-warning", "my-1", "py-1", "f-1")
      element.style.zIndex = 0
      element.classList.remove("invalid-field")
      element.innerText = message
    } else {
      element.classList.add("invalid-warning", "my-1", "py-1", "f-1")
      element.style.zIndex = 10
      element.classList.add("invalid-field")
      element.innerText = message
    }
    // controller.errorEmailCodeTarget.classList.add("invalid-warning", "my-1", "py-1", "f-1")
    // controller.errorEmailCodeTarget.style.zIndex = 10
    // controller.emailCodeTarget.classList.add("invalid-field")
    // controller.errorEmailCodeTarget.innerText = response.message
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
