import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "loader", "mainRow"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `financials--books--receivables--conciliations--modal--main`
    this.controllerBilling = `financials--books--receivables--conciliations--modal--billing`
    this.controllerClient = `financials--books--receivables--conciliations--modal--client`
    this.controllerSaveClient = `financials--books--clients--entities--save`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  setCurrentBilling() {
    this.current_billing = {}
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--days`).iugu_billings.forEach(billing => {
    var hasFound = false
    this.iugu_billings.forEach(billing => {
      if (billing.invoice_id_iugu == this.invoice_id_iugu) {
        this.current_billing = billing
        hasFound = true
      }
    })

    if (hasFound) {
      this.doModalHtml()
    } else {
      alert(`Favor carregar a Fatura!`)
      this.close()
    }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setCurrentBilling()
    })
  }

  close() {
    if (this.getControllerByIdentifier(`financials--books--receivables--billings--index`)) {
      this.getControllerByIdentifier(`financials--books--receivables--billings--index`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--dashboard--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--dashboard--billings`).doDataTable()
    } else if (this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`)) {
      this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).doDataTable()
    }

    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doModalHtml() {
    
    var currentBillingCustomerIdHtml = `<small class="mb-0 f-065 mc-tooltip grab">
                                          <span class="f-bold" data-controller="app--helpers--copy" data-copy="${this.current_billing.client_id_iugu}" data-action="click->app--helpers--copy#copy">${this.current_billing.client_id_iugu}</span>
                                          <span class="mc-tooltiptext">Clique para Copiar</span>
                                        </small>`
    
    var currentBillingCustomerNameHtml = `<small class="mb-0 f-065 mc-tooltip grab">
                                            <span class="f-bold" data-controller="app--helpers--copy" data-copy="${this.current_billing.customer_name}" data-action="click->app--helpers--copy#copy">${this.current_billing.customer_name}</span>
                                            <span class="mc-tooltiptext">Clique para Copiar</span>
                                          </small>`
    
    var currentBillingCustomerEmailHtml = `<small class="mb-0 f-065 mc-tooltip grab">
                                            <span class="f-bold" data-controller="app--helpers--copy" data-copy="${this.current_billing.email_iugu}" data-action="click->app--helpers--copy#copy">${this.current_billing.email_iugu}</span>
                                            <span class="mc-tooltiptext">Clique para Copiar</span>
                                          </small>`
    
    var currentBillingIdNumberHtml = `<small class="mb-0 f-065 mc-tooltip grab">
                                        <span class="f-bold" data-controller="app--helpers--copy" data-copy="${this.current_billing.payer_cpf_cnpj}" data-action="click->app--helpers--copy#copy">${this.current_billing.payer_cpf_cnpj_pretty}</span>
                                        <span class="mc-tooltiptext">Clique para Copiar</span>
                                      </small>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Conciliação Cliente: <strong>${currentBillingCustomerNameHtml}</strong> | ${this.current_billing.payer_cpf_cnpj_type_pretty}: <strong>${currentBillingIdNumberHtml}</strong> | ID Iugu: #${currentBillingCustomerIdHtml} | Email: <strong>${currentBillingCustomerEmailHtml}</strong></h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row" data-${this.controllerName}-target="mainRow">
                      <div class="col-4 mt-1" data-${this.controllerBilling}-target="main" data-controller="${this.controllerBilling}" style="overflow:auto;"></div>
                      <div class="col-8 mt-1" data-${this.controllerClient}-target="main" data-controller="${this.controllerClient} ${this.controllerSaveClient}" style="overflow:auto;"></div>
                      <div class="col-8 mt-1" data-${this.controllerName}-target="loader"></div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      controller.getControllerByIdentifier(this.controllerBilling).billing_permission = `financial_receivable_billings`
      controller.getControllerByIdentifier(this.controllerBilling).billing_item_permission = `financial_receivable_billings`
      controller.getControllerByIdentifier(this.controllerBilling).billing_date_permission = `financial_receivable_billing_dates`
      controller.getControllerByIdentifier(this.controllerBilling).current_billing = controller.current_billing
      controller.getControllerByIdentifier(this.controllerBilling).doBillingHtml()
      controller.getControllerByIdentifier(this.controllerClient).current_billing = controller.current_billing
      controller.getControllerByIdentifier(this.controllerClient).doClientLoader()
      controller.setClient()
    })
  }

  setClient() {
    this.current_client = false

    this.application.clients.forEach(client => {
      // if (client.id_iugu == this.current_billing.client_id_iugu) {
      var clientIuguIds = this.mapElements(client.accounts, `iugu_id`)

      // if (client.iugu_ids.includes(this.current_billing.client_id_iugu)) {
      if (clientIuguIds.includes(this.current_billing.client_id_iugu)) {
        this.current_client = client
      }
    })

    if (this.current_client) {
      this.getControllerByIdentifier(this.controllerClient).current_client = this.current_client
      this.getControllerByIdentifier(this.controllerClient).doClient()
    } else {
      this.getControllerByIdentifier(this.controllerClient).doNoClient()
    }
  }

  addLoader() {
    var html = `<div class="card-body py-0" style="overflow:auto;">
                  <div class="row mt-3">
                    <div class="col-6">${this.loader}</div>
                    <div class="col-6">${this.loader}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">${this.loader}</div>
                    <div class="col-6">${this.loader}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">${this.loader}</div>
                    <div class="col-6">${this.loader}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">${this.loader}</div>
                    <div class="col-6">${this.loader}</div>
                  </div>
                </div>`

    this.loaderTarget.innerHTML = html
    this.loaderTarget.classList.remove(`d-none`)
    this.mainRowTarget.classList.add(`d-flex`, `align-items-center`)
    this.getControllerByIdentifier(this.controllerClient).mainTarget.classList.add(`d-none`)
  }
  
  removeLoader() {
    this.loaderTarget.innerHTML = ``
    this.loaderTarget.classList.add(`d-none`)
    this.mainRowTarget.classList.remove(`d-flex`, `align-items-center`)
    this.getControllerByIdentifier(this.controllerClient).mainTarget.classList.remove(`d-none`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }
}