import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.chat_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastChatChannel() {
    var controller = this;
    this.application.chatChannel = createChannel({ channel: 'Commercial::Marketing::Visit::ChatChannel', id: this.chat_room.id, user_id: this.application.current_user_id, visit_id: this.application.current_visit_id }, {
      connected() {
        controller.chatListen()
        controller.application.chatController.connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.application.startedChat) {
          if (controller.getControllerByIdentifier("app--communications--chat--visits--entities").chatFormTarget.classList.contains("d-none")) {
            controller.getControllerByIdentifier("app--communications--chat--visits--entities").openForm()
          } else {
            if (controller.getControllerByIdentifier("app--communications--chat--visits--bot").bot) {
              controller.getControllerByIdentifier("app--communications--chat--visits--bot").insertMessagesAndWait(data)
              controller.getControllerByIdentifier("app--communications--chat--visits--bot").botChat()
            } else {
              controller.getControllerByIdentifier("app--communications--chat--visits--bot").insertMessagesAndStop(data)
            }
          }
        } else {
          controller.getControllerByIdentifier("app--communications--chat--visits--entities").openForm()
        }
        controller.getControllerByIdentifier("app--communications--chat--visits--entities").request(true)
      },
      disconnected() {
        controller.application.chatController.connectionOff()
      }
    });
  }

  getRoom() {
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    if (this.application.current_user_id == null || this.application.current_user_id == undefined) {
      this.application.current_user_id = false
    }
    var data = { current_visitor: { current_visit_id: this.application.current_visit_id, visitor_token: this.application.current_visitor_token }, current_user: { current_user_id: this.application.current_user_id }}
    const url = "/commercial/marketing/visits/broadcasts/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.chat_room = response.data.cln
          controller.application.chat_room = response.data.cln
          if (controller.application.chat_room.status) {
            this.application.startedChat = true
          }

          controller.broadcastChatChannel()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  chatListen() {
    if (this.application.chatChannel) {
      this.application.chatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
