import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "saveBtn", "cpfInput", "nameInput", "birthdateInput", "sexDropdown", "sexDropdownBtn", "modal", "body",
                    "sexInput", "sexList", "dddInput", "numberInput", "addressInput", "patientCheck", "sponsorCheck", "errorMessage"]


 

  connect() {
    this.controllerName = `operations--products--bookings--receipts--patient`
    this.send_data = { current_user: {}, account: {}, patient: {} }
  }

  cancelSave() {
    this.current_receipt = {}
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doReceiptsDashboard()
  }

  savePatient() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.account.id) {
      this.send_data.account.id = this.account.id
    } else {
      this.send_data.account.name = this.nameInputTarget.value
      this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.account.sex = this.sexInputTarget.dataset.sex
      this.send_data.account.kind = `patient`
      this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformNumberDate(this.birthdateInputTarget.value)
      this.send_data.account.ddd = this.dddInputTarget.value
      this.send_data.account.number = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.numberInputTarget.value)
      this.send_data.account.address = this.addressInputTarget.value
    }

    this.send_data.patient.booking_id = this.application.booking.id
    this.send_data.patient.sponsor = this.sponsorCheckTarget.checked
    this.send_data.patient.patient = this.patientCheckTarget.checked

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/bookings/patients/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var patient = response.data.cln
          controller.application.patients[controller.application.patients.length] = patient
          controller.getControllerByIdentifier("operations--products--bookings--receipts--save").listPatients()
          controller.getControllerByIdentifier("operations--products--bookings--receipts--save").listSponsors()
          controller.close()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  modalHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medbooking_patients.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#savePatient" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="modal fade" data-controller="${this.controllerName}" data-${this.controllerName}-target="modal" id="patientModal" tabindex="-1" role="dialog" aria-labelledby="patientModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0 pl-0">
                        <h6 class="modal-title display-4 card-title-table-list"><strong class="f-075">Novo Paciente e/ou Responsável Financeiro</strong></h6><br>
                        <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body p-3" data-${this.controllerName}-target="body">
                        <div class="row my-2">
                          <div class="col-4 pl-1 pr-0">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cpfForm">CPF Paciente</label>
                                <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount" placeholder="CPF Paciente" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome Paciente</label>
                                <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Paciente" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2">
                          <div class="col-4 pl-1 pr-0">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="birthdateForm">Data Nascimento</label>
                                <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Sexo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                      <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                      <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row my-2">
                            <div class="col-1 pl-1 pr-0">
                              <div class="form-group form-valid-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="dddInput">DDD</label>
                                  <input id="dddInput" data-${this.controllerName}-target="dddInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#dddMask keyup->${this.controllerName}#dddMask" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-3 pr-0">
                              <div class="form-group form-valid-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="numberInput">Número</label>
                                  <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-8">
                              <div class="form-group form-valid-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="addressInput">E-mail</label>
                                  <input id="addressInput" data-${this.controllerName}-target="addressInput" class="form-control p-1 s-title-0p6rem" type="email" required>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="custom-control custom-switch d-flex align-items-center">
                              <input class="custom-control-input" id="isPatientModal" data-${this.controllerName}-target="patientCheck" type="checkbox">
                              <span class="custom-control-track"></span>
                              <label class="custom-control-label" for="isPatientModal"></label>
                            </div>
                            <span class="f-065">Paciente?</span>
                          </div>
                          
                          <div class="col-6">
                            <div class="custom-control custom-switch d-flex align-items-center">
                              <input class="custom-control-input" id="isSponsorModal" data-${this.controllerName}-target="sponsorCheck" type="checkbox">
                              <span class="custom-control-track"></span>
                              <label class="custom-control-label" for="isSponsorModal"></label>
                            </div>
                            <span class="f-065">Financeiro?</span>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").open()
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").refreshSaveBtn()
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").sponsorCheckTarget.checked = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").patientCheckTarget.checked = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").nameInputTarget.disabled = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").birthdateInputTarget.disabled = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").sexDropdownBtnTarget.disabled = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").addressInputTarget.disabled = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").dddInputTarget.disabled = true
      controller.application.getControllerForElementAndIdentifier(document.querySelector('#patientModal'), "operations--products--bookings--receipts--patient").numberInputTarget.disabled = true
    })
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    floatingLabel();
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""
      this.dddInputTarget.value = ""
      this.numberInputTarget.value = ""
      this.addressInputTarget.value = ""

      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "patient" }, current_user: { current_user_id: this.application.current_user.id, feature: "medbooking_patient_accounts" } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
        this.setErrorMessage(this.cpf_result.message)
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
        fetch(url, init)
          .then(response => response.json())
          .then(response => {
            if (response.process) {
              controller.account = response.data.cln
              controller.nameInputTarget.disabled = false
              if (controller.account.id) {
                controller.nameInputTarget.value = controller.account.name
                controller.birthdateInputTarget.value = controller.account.birthdate_pretty
                controller.sexInputTarget.innerText = controller.account.sex_pretty
                controller.sexInputTarget.dataset.sex = controller.account.sex
                controller.sexDropdownTarget.value = controller.account.sex

                controller.nameInputTarget.disabled = true
                controller.birthdateInputTarget.disabled = true
                controller.sexDropdownBtnTarget.disabled = true
                

                if (controller.account.email.address) {
                  controller.addressInputTarget.value = controller.account.email.address
                  controller.addressInputTarget.disabled = true
                } else {
                  controller.addressInputTarget.disabled = false
                }

                if (controller.account.phone.number) {
                  controller.dddInputTarget.value = controller.account.phone.ddd
                  controller.numberInputTarget.value = controller.account.phone.number
                  controller.dddInputTarget.disabled = true
                  controller.numberInputTarget.disabled = true
                } else {
                  controller.dddInputTarget.disabled = false
                  controller.numberInputTarget.disabled = false
                }

                controller.checkIfHasPatient(controller.account)
              } else {
                controller.nameInputTarget.value = ""
                controller.nameInputTarget.disabled = false
                controller.birthdateInputTarget.disabled = false
                controller.sexDropdownBtnTarget.disabled = false
                controller.addressInputTarget.disabled = false
                controller.dddInputTarget.disabled = false
                controller.numberInputTarget.disabled = false
              }
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            }
          })
          .catch(error => {
            controller.getControllerByIdentifier("app--helpers--console").console(error)
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
          })
      }
    }
  }

  checkIfHasPatient(account) {
    var diffPatient = true
    this.application.patients.forEach(element => {
      if (element.account_cpf == account.cpf) {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O Paciente já está cadastrado", 2000)
        this.setErrorMessage("O Paciente já está cadastrado")
        diffPatient = false
      } else {
        if (diffPatient) {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
      }
    });
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  dddMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 2000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.cpf_result && controller.cpf_result.invalid) {
        len += 1
      }

      if (controller.cpfInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.birthdateInputTarget.value == ``) {
        len += 1
      }

      if (controller.sexInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.addressInputTarget.value == ``) {
        // len += 1
      }

      if (controller.dddInputTarget.value == ``) {
        // len += 1
      }

      if (controller.numberInputTarget.value == ``) {
        // len += 1
      }



      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.refreshPreviewDate) {
      clearInterval(this.refreshPreviewDate)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
