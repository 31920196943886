import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "revenueCard", "legalCard", "individualCard", "taxesCard", "withheldCard", "payableCard",
    "expensesCard", "payrollCard", "operationalExpensesCard", "transferCard", "laborCard", "distributionCard"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--apurations--partner`
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)

    if (this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--managements--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--managements--apurations--dashboard")
    }
  }

  doPartnerHtml() {
    var html = `<div class="row mb-2">
                  <div class="col-6">
                    ${this.doRevenuesHtml()}
                  </div>
                  <div class="col-6 px-0">
                    ${this.doExpensesHtml()}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    ${this.doTaxesHtml()}
                  </div>
                  <div class="col-6 px-0">
                  ${this.doTransferHtml()}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.revenueCardTarget.style.height = ($(window).height() * 0.26) + "px"
      controller.legalCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.individualCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.taxesCardTarget.style.height = ($(window).height() * 0.26) + "px"
      controller.withheldCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.payableCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.expensesCardTarget.style.height = ($(window).height() * 0.26) + "px"
      controller.payrollCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.operationalExpensesCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.transferCardTarget.style.height = ($(window).height() * 0.26) + "px"
      controller.laborCardTarget.style.height = ($(window).height() * 0.125) + "px"
      controller.distributionCardTarget.style.height = ($(window).height() * 0.125) + "px"
    })
  }

  doRevenuesHtml() {
    var returnPath = `/a/${this.current_apuration.partner_return.product_path}/${this.current_apuration.partner_return.token}`

    var cardBody = ``
    cardBody += `<tr>
                  <th colspan="1" class="f-065 align-middle p-0"></th>
                  <th colspan="5" class="f-065 align-middle px-0">
                    Consulta / Plantão <br>
                  </th>
                  <td colspan="3" class="f-065 align-middle px-0 text-center f-bold">
                    ${this.numberController.currencyOptionMask(this.current_apuration.total_practice_revenues)} <br>
                  </td>
                </tr>
                <tr>
                  <th colspan="1" class="f-065 align-middle p-0"></th>
                  <th colspan="5" class="f-065 align-middle px-0">
                    Procedimento / Tratamento <br>
                  </th>
                  <td colspan="3" class="f-065 align-middle px-0 text-center f-bold">
                    ${this.numberController.currencyOptionMask(this.current_apuration.total_procedure_revenues)} <br>
                  </td>
                </tr>`

    var cardShow = `<table class="table table-sm table-xs table-borderless t-white table-no-bg" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                        </tr>
                        ${cardBody}
                      </tbody>
                    </table>`

    var html = `<div class="row">
                  <div class="col-6 px-0">
                    <div class="card" data-${this.controllerName}-target="revenueCard">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0 pointer" data-action="click->${this.controllerName}#goToURL" data-return-path="${returnPath}">Total Receitas</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <div class="card-show-dropdown">
                              <span class="pointer">${this.numberController.currencyOptionMask(this.current_apuration.total_revenues)}</span>
                              <div class="card-show-dropdown-content card-show-dropdown-bg-primary text-left">
                                ${cardShow}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="legalCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Receitas PJ</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.total_legal_revenues)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="individualCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Receitas PF</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.total_individual_revenues)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doTaxesHtml() {

    if (this.current_apuration.tax_regime == `presumed_profit`) {
      // var taxes = ["irpj", "csll", "pis", "cofins", "iss", "irrf", "inss"]
      var taxes = ["irpj", "csll", "pis", "cofins", "iss"]
    } else if (this.current_apuration.tax_regime == `simple_national`) {
      var taxes = ["simple"]
    }
    var taxesTranslated = {
      iss: "ISSQN",
      csll: "CSLL",
      irpj: "IRPJ",
      cofins: "COFINS",
      pis: "PIS",
      simple: "Simples Nacional",
      irrf: "Imposto de Renda Retido na Fonte - de Sócios",
      inss: "Contribuições Previdenciárias",
    }

    var cardBodyDue = ``
    var cardBodyWithheld = ``
    var cardBodyPayable = ``
    taxes.forEach(tax => {
      cardBodyDue += `<tr>
                      <th colspan="1" class="f-065 align-middle p-0"></th>
                      <th colspan="2" class="f-065 align-middle p-0 text-left">
                        ${taxesTranslated[tax]} <br>
                      </th>
                      <td colspan="2" class="f-065 align-middle p-0 text-left f-bold">
                        ${this.numberController.currencyOptionMask(this.current_apuration[`due_${tax}`])} <br>
                      </td>
                    </tr>`
      cardBodyWithheld += `<tr>
                      <th colspan="1" class="f-065 align-middle p-0"></th>
                      <th colspan="2" class="f-065 align-middle p-0 text-left">
                        ${taxesTranslated[tax]} <br>
                      </th>
                      <td colspan="2" class="f-065 align-middle p-0 text-left f-bold">
                        ${this.numberController.currencyOptionMask(this.current_apuration[`withheld_${tax}`])} <br>
                      </td>
                    </tr>`
      cardBodyPayable += `<tr>
                      <th colspan="1" class="f-065 align-middle p-0"></th>
                      <th colspan="2" class="f-065 align-middle p-0 text-left">
                        ${taxesTranslated[tax]} <br>
                      </th>
                      <td colspan="2" class="f-065 align-middle p-0 text-left f-bold">
                        ${this.numberController.currencyOptionMask(this.current_apuration[`payable_${tax}`])} <br>
                      </td>
                    </tr>`
    });

    var cardShowDue = `<table class="table table-sm table-xs table-borderless t-white table-no-bg" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                            </tr>
                            ${cardBodyDue}
                          </tbody>
                        </table>`

    var cardShowWithheld = `<table class="table table-sm table-xs table-borderless t-white table-no-bg" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                            </tr>
                            ${cardBodyWithheld}
                          </tbody>
                        </table>`

    var cardShowPayable = `<table class="table table-sm table-xs table-borderless t-white table-no-bg" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                            </tr>
                            ${cardBodyPayable}
                          </tbody>
                        </table>`

    var html = `<div class="row">
                  <div class="col-6 px-0">
                    <div class="card" data-${this.controllerName}-target="taxesCard">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Total de Impostos <small>[${this.current_apuration.tax_regime_pretty}]</small></div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <div class="card-show-dropdown w-100">
                              <span class="pointer">${this.numberController.currencyOptionMask(this.current_apuration.total_due)}</span>
                              <div class="card-show-dropdown-content card-show-dropdown-bg-primary text-left w-100">
                                ${cardShowDue}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="withheldCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Impostos Retidos</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                <div class="card-show-dropdown w-100">
                                  <span class="pointer">${this.numberController.currencyOptionMask(this.current_apuration.total_withheld)}</span>
                                  <div class="card-show-dropdown-content card-show-dropdown-bg-primary text-left w-100">
                                    ${cardShowWithheld}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="payableCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Impostos à Pagar</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                <div class="card-show-dropdown w-100">
                                  <span class="pointer">${this.numberController.currencyOptionMask(this.current_apuration.total_payable)}</span>
                                  <div class="card-show-dropdown-content card-show-dropdown-bg-primary text-left w-100">
                                    ${cardShowPayable}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doExpensesHtml() {

    var cardBody = ``
    this.application.expenses.forEach(element => {
      if (element.date_id == this.application.current_date.id && element.partner_ids.includes(this.current_apuration.partner_id.toString())) {
        var partnerAmount = Number(element.amount / element.partner_ids.length)

        cardBody += `<tr>
                      <th colspan="1" class="f-065 align-middle p-0"></th>
                      <th colspan="5" class="f-065 align-middle px-0">
                        ${element.description} <br>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-center f-bold">
                        ${this.numberController.currencyOptionMask(partnerAmount)} <br>
                      </td>
                    </tr>`
      }
    });

    var cardShow = `<table class="table table-sm table-xs table-borderless t-white table-no-bg" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            
                            ${cardBody}
                            
                          </tbody>
                        </table>`

    var html = `<div class="row">
                  <div class="col-6 px-0">
                    <div class="card" data-${this.controllerName}-target="expensesCard">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Rateio de Despesas</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            <div class="card-show-dropdown">
                              <span class="pointer">${this.numberController.currencyOptionMask(this.current_apuration.expenses)}</span>
                              <div class="card-show-dropdown-content card-show-dropdown-bg-primary text-left">
                                ${cardShow}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="payrollCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Folha de Pagamento</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.payroll)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="operationalExpensesCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Despesas Operacionais</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.operational_expenses)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doTransferHtml() {
    var html = `<div class="row">
                  <div class="col-6 px-0">
                    <div class="card" data-${this.controllerName}-target="transferCard">
                      <div class="card-header p-0">
                        <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Total Repasse</div></div>
                      </div>
                      <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                        <div class="row my-1">
                          <div class="col-12 px-0">
                            ${this.numberController.currencyOptionMask(this.current_apuration.transfer)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="laborCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Pró-Labore Líquido</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.labor)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="distributionCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Distribuição Dividendos</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.current_apuration.distribution)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.returnPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
