import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--commercial--marketing--events--live--streaming`
    this.setStreaming()
  }


  setStreaming() {
    var event = this.application.event
    var html = ``

    var link = event.links[0]
    if (event.finished) {
      event.links.forEach(link => {
        html += `<div class="row my-3 w-100 h-100">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/${link.code}?rel=0&modestbranding=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>`
      })
      
    } else if (event.started) {

      if (link == null || link == undefined) {
        html += `<div class="row w-100 h-100">
                    <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                      <img src="${event.begin_banner_url}" alt="${event.title}" style="width:100%">
                    </div>
                  </div>`
      } else {
        if (event.broadcast) {
          html += `<div class="row my-3 w-100 h-100">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/${link.code}?rel=0&modestbranding=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>`
        } else {
          html += `<div class="row w-100 h-100">
                    <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                      <img src="${event.error_banner_url}" alt="${event.title}" style="width:100%">
                    </div>
                  </div>`
        }
      }
    } else {
      html += `<div class="row w-100 h-100">
                    <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                      <img src="${event.begin_banner_url}" alt="${event.title}" style="width:100%">
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.mainTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
