import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "kindDropdown", "cpfCheckbox", "saveBtn",
                    "cnpjCheckbox", "idNumberInput", "cnpjInput", "cnpj", "cpfInput", "cpf", "nicknameInput", 
                    "bankAccountInput", "pixKeyInput", "pixKindDropdown", "pixKindDropdownBtn", "pixKindInput", "pixKindList",
                    "notesInput"]


  connect() {
    this.controllerName = `financials--books--dashboards--providers--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`financials--books--dashboards--providers--dashboard`).doProvidersDashboard()
  }

  saveProvider() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, provider: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.provider.id = this.current_provider.id
    }

    if (this.cpfCheckboxTarget.checked) {
      this.send_data.provider.id_type = "individual"
      this.send_data.provider.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfTarget.value)
    } else if (this.cnpjCheckboxTarget.checked) {
      this.send_data.provider.id_type = "legal"
      this.send_data.provider.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cnpjTarget.value)
    }
    this.send_data.provider.name = this.nameInputTarget.value.trim()
    this.send_data.provider.nickname = this.nicknameInputTarget.value.trim()
    this.send_data.provider.bank_account = this.bankAccountInputTarget.value.trim()
    this.send_data.provider.pix_kind = this.pixKindInputTarget.dataset.kind
    this.send_data.provider.pix_key = this.pixKeyInputTarget.value.trim()
    this.send_data.provider.notes = this.notesInputTarget.value.trim()
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_providers`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/statements/providers/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/statements/providers/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/statements/providers/destroy"
      var method = "DELETE"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var provider = response.data.cln
          controller.stopRefreshing()
          if (controller.actionMode == `new`) {
            controller.application.providers[controller.application.providers.length] = provider
          } else if (controller.actionMode == `edit`) {
            controller.application.providers.forEach((element, i) => {
              if (element.id == provider.id) {
                controller.application.providers.splice(controller.application.providers.indexOf(element), 1, provider)
              }
            })
          } else if (controller.actionMode == `destroy`) {
            controller.application.providers.forEach((element, i) => {
              if (element.id == provider.id) {
                controller.application.providers.splice(controller.application.providers.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_settings_providers.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveProvider" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-10 offset-1">
                      <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row">
                          <div class="col-6 my-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 my-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nicknameForm">Apelido</label>
                                <input aria-describedby="nicknameFormHelp" class="form-control" id="nicknameForm" data-${this.controllerName}-target="nicknameInput" placeholder="Apelido" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 d-flex my-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mx-auto">
                              <input type="checkbox" class="custom-control-input" id="paymentCpfCheck" data-kind="cpf" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheck">
                              <label class="custom-control-label f-065" for="paymentCpfCheck">Pessoa</label>
                            </div>
                          </div>
                          <div class="col-6 d-flex my-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mx-auto">
                              <input type="checkbox" class="custom-control-input" id="paymentCnpjCheck" data-kind="cnpj" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheck">
                              <label class="custom-control-label f-065" for="paymentCnpjCheck">Empresa</label>
                            </div>
                          </div>
                          <div class="col-12 my-2 d-flex align-items-center" data-${this.controllerName}-target="idNumberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                          <div class="col-12 d-none" data-${this.controllerName}-target="cnpjInput">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cnpjForm">CNPJ do Fornecedor</label>
                                <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="CNPJ do Fornecedor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 d-none" data-${this.controllerName}-target="cpfInput">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cpfForm">CPF do Fornecedor</label>
                                <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF do Fornecedor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="bankAccountForm">Dados Bancários</label>
                                <input aria-describedby="bankAccountFormHelp" class="form-control" id="bankAccountForm" data-${this.controllerName}-target="bankAccountInput" placeholder="Dados Bancários" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo PIX</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="pixKindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="pixKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="pixKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="pixKindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="id_number" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">CPF/CNPJ</li>
                                      <li data-kind="email" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">E-mail</li>
                                      <li data-kind="phone" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Celular</li>
                                      <li data-kind="random" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chave Aleatória</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="pixKeyForm">Chave PIX</label>
                                <input aria-describedby="pixKeyFormHelp" class="form-control" id="pixKeyForm" data-${this.controllerName}-target="pixKeyInput" placeholder="Chave PIX" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 my-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <input aria-describedby="notesFormHelp" class="form-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--providers--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Fornecedor`
        controller.nameInputTarget.value = controller.current_provider.name
        controller.notesInputTarget.value = controller.current_provider.notes
        controller.nicknameInputTarget.value = controller.current_provider.nickname
        controller.bankAccountInputTarget.value = controller.current_provider.bank_account
        controller.pixKeyInputTarget.value = controller.current_provider.pix_key

        if (controller.current_provider.pix_kind) {
          controller.pixKindDropdownTarget.value = controller.current_provider.pix_kind
          controller.pixKindInputTarget.dataset.kind = controller.current_provider.pix_kind
          controller.pixKindInputTarget.innerText = controller.current_provider.pix_kind_pretty
        }

        controller.cpfCheckboxTarget.disabled = true
        controller.cnpjCheckboxTarget.disabled = true
        
        if (controller.current_provider.id_type == `individual`) {
          controller.cpfCheckboxTarget.checked = true
          controller.cnpjCheckboxTarget.checked = false
          controller.idNumberInputTarget.classList.add("d-none")
          controller.cnpjInputTarget.classList.add("d-none")
          controller.cpfInputTarget.classList.remove("d-none")
          controller.cnpjTarget.value = ``
          if (controller.current_provider.id_number) {
            controller.cpfTarget.disabled = true
          }
          controller.cpfTarget.value = controller.current_provider.id_number_pretty
        } else if (controller.current_provider.id_type == `legal`) {
          controller.cpfCheckboxTarget.checked = false
          controller.cnpjCheckboxTarget.checked = true
          controller.idNumberInputTarget.classList.add("d-none")
          controller.cnpjInputTarget.classList.remove("d-none")
          controller.cpfInputTarget.classList.add("d-none")
          controller.cpfTarget.value = ``
          if (controller.current_provider.id_number) {
            controller.cnpjTarget.disabled = true
          }
          controller.cnpjTarget.value = controller.current_provider.id_number_pretty
        }
        
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Fornecedor`
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  kindCheck(ev) {
    if (this.hasIdNumberInputTarget) {
      this.idNumberInputTarget.remove()
    }
    if (ev.target.dataset.kind == "cpf") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (ev.target.dataset.kind == "cnpj") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfTarget.classList.add("f-danger")
        this.cpfTarget.classList.remove("f-normal")
      } else {
        this.cpfTarget.classList.add("f-normal")
        this.cpfTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfTarget.classList.add("f-normal")
      this.cpfTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjTarget.classList.add("f-danger")
        this.cnpjTarget.classList.remove("f-normal")
      } else {
        this.cnpjTarget.classList.add("f-normal")
        this.cnpjTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjTarget.classList.add("f-normal")
      this.cnpjTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.cpfCheckboxTarget.checked) {
        // if (controller.cpfTarget.value == ``) {
        //   len += 1
        // }
      } else if (controller.cnpjCheckboxTarget.checked) {
        // if (controller.cnpjTarget.value == ``) {
        //   len += 1
        // }
      } else {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
