import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formPaymentCard", "formTitle", "formPaymentBody", "paymentDropdown", "paymentInput", "paymentList", "amount", "numberInput",
                    "date", "social", "cpfCheckbox", "cnpjCheckbox", "grossInput", "withheldInput", "socialInput", "saveBtn", "deleteBtn", "cnpjInput", "cpfInput",
                    "upload", "labelUpload", "fileName", "progressUpload", "progressUploadBar", "progressUploadCounter", "uploadFile",
                    "paymentName", "description", "cpf", "cnpj", "showUpload", "fileNameUploadShow", "uploadUrl", "cancelUploadBtn", "paymentNameFilter",
                    "paymentMember", "memberCheckbox", "dependentSelect", "dependentDropdown", "dependentInput", "dependentList", "paymentNameFilterItem"]

  connect() {
    this.controllerName = `operations--products--tax-returns--payments--save`
    this.send_data = { current_user: {}, payment: {}, notification: {} }
  }

  kindCheck(ev) {
    if (this.hasNumberInputTarget) {
      this.numberInputTarget.remove()
    }
    if (ev.target.dataset.kind == "cpf") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (ev.target.dataset.kind == "cnpj") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--index").cleanCheckBox()
    this.getControllerByIdentifier("operations--products--tax-returns--payments--show").doViewHtml()
  }

  saveUpload(ev) {
    if (ev.target.files.length > 0) {
      this.file = ev.target.files[0]
      this.uploadFileTarget.classList.remove("d-none")
      this.fileNameTarget.innerText = ev.target.files[0].name
    }
  }

  savePayment() {
    this.send_data = { current_user: {}, payment: {}, notification: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.current_user.current_user_id = this.application.current_user.id

    if (this.actionMode == "edit") { this.send_data.payment.id = this.current_payment.id }

    this.send_data.payment.tax_return_id = this.application.tax_return.id
    this.send_data.payment.date_id = this.application.current_date.id
    this.send_data.payment.chart_id = this.paymentInputTarget.dataset.id
    this.send_data.payment.chart_group = this.paymentInputTarget.dataset.group
    this.send_data.payment.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateTarget.value)
    this.send_data.payment.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountTarget.value)
    this.send_data.payment.id_name = this.paymentNameTarget.value.trim()
    this.send_data.payment.description = this.descriptionTarget.value.trim()
    this.send_data.payment.member_id = this.dependentInputTarget.dataset.dependentid

    if (this.cpfCheckboxTarget.checked) {
      this.send_data.payment.id_type = "individual"
      this.send_data.payment.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfTarget.value)
      var idNumber = this.cpfTarget.value
    } else if (this.cnpjCheckboxTarget.checked) {
      this.send_data.payment.id_type = "legal"
      this.send_data.payment.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cnpjTarget.value)
      var idNumber = this.cnpjTarget.value
    }

    this.send_data.notification.domain_id = this.application.tax_return.id
    this.send_data.notification.domain_type = "operation_products"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "operation_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um pagamento ${this.paymentInputTarget.dataset.chart} do beneficiário ${idNumber} pago em ${this.dateTarget.value}`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id
    
    this.requestSave()
    if (this.current_payment && this.current_payment.token_tree) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Este Pagamento deve ser atualizado diretamente no Livro-Caixa`, 5000)
    } else {
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/payments/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/payments/create"
      var method = "POST"
    }

    this.progressUploadTarget.classList.remove("d-none")
    this.progressUploadCounterTarget.classList.remove("d-none")
    this.labelUploadTarget.classList.remove("btn-primary")
    this.labelUploadTarget.classList.add("btn-disabled")
    this.progressCount(0)

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var payment = response.data.cln
          if (controller.file) {
            controller.requestUpload(payment)
          } else {
            controller.progressCount(0)
            if (controller.actionMode == "new") {
              controller.application.payments[controller.application.payments.length] = payment
            } else if (controller.actionMode == "edit") {
              if (payment.active) {
                controller.application.payments.forEach((element, i) => {
                  if (element.id == payment.id) {
                    controller.application.payments.splice(controller.application.payments.indexOf(element), 1, payment)
                  }
                })
              } else {
                controller.application.payments.forEach((element, i) => {
                  if (element.id == payment.id) {
                    controller.application.payments.splice(controller.application.payments.indexOf(element), 1)
                  }
                })
              }
            }
            controller.cancelSave()
            controller.getControllerByIdentifier("operations--products--tax-returns--payments--index").doDataTable()
          }

        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestUpload(payment) {
    var formData = new FormData()
    formData.append('file', this.file)
    formData.append('payment_id', payment.id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/operations/products/tax_returns/payments/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

    
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          var payment = response.data.cln
          if (controller.actionMode == "new") {
            controller.application.payments[controller.application.payments.length] = payment
          } else if (controller.actionMode == "edit") {
            if (payment.active) {
              controller.application.payments.forEach((element, i) => {
                if (element.id == payment.id) {
                  controller.application.payments.splice(controller.application.payments.indexOf(element), 1, payment)
                }
              })
            } else {
              controller.application.payments.forEach((element, i) => {
                if (element.id == payment.id) {
                  controller.application.payments.splice(controller.application.payments.indexOf(element), 1)
                }
              })
            }
          }
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--tax-returns--payments--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  deletePayment() {
    this.send_data = { current_user: {}, payment: {}, notification: {} }
    this.actionMode = `delete`
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    this.saveBtnTarget.classList.add("d-none")
    if (this.hasDeleteBtnTarget) {
      this.deleteBtnTarget.disabled = true
      this.deleteBtnTarget.classList.add("d-none")
    }

    if (this.current_payment.token_tree) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Este Pagamento deve ser apagado diretamente no seu Livro-Caixa`, 5000)
    } else {
      this.send_data.payment.id = this.current_payment.id
      this.send_data.payment.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.notification.domain_id = this.application.tax_return.id
      this.send_data.notification.domain_type = "operation_products"
      this.send_data.notification.date_id = this.application.current_date.id
      this.send_data.notification.date_type = "operation_dates"
      this.send_data.notification.kind = `mode_${this.actionMode}`
      this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% um pagamento ${this.current_payment.chart_pretty} do beneficiário ${this.current_payment.id_number_pretty} pago em ${this.current_payment.date_pretty}`
      this.send_data.notification.account_name = this.application.current_user.name
      this.send_data.notification.account_id = this.application.current_user.account_id

      var url = "/operations/products/tax_returns/payments/destroy"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            this.stopRefreshing()
            this.cancelSave()
            var payment = response.data.cln
            this.application.payments.forEach((element, i) => {
              if (element.id == payment.id) {
                this.application.payments.splice(this.application.payments.indexOf(element), 1)
              }
            })
            this.getControllerByIdentifier("operations--products--tax-returns--payments--index").doDataTable()
          } else {
            this.saveBtnTarget.disabled = false
            if (this.hasDeleteBtnTarget) {
              this.deleteBtnTarget.disabled = true
            }
          }
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        })
        .catch(error => {
          this.getControllerByIdentifier("app--helpers--console").console(error)
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  doFormHtml() {

    if (this.application.medreturn_payments.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayment" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.medreturn_payments.can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deletePayment" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formPaymentCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="formPaymentBody">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-7 pr-1 pl-0">
                        <div class="form-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome do Beneficiário</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-filter-mode="simple" data-${this.controllerName}-target="paymentName" data-action="focus->${this.controllerName}#paymentNameFilter keyup->${this.controllerName}#paymentNameFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#entrePaymentName" placeholder="Nome do Beneficiário" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="paymentNameFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="paymentCpfCheck" data-kind="cpf" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="paymentCpfCheck">CPF</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="paymentCnpjCheck" data-kind="cnpj" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="paymentCnpjCheck">CNPJ</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4 pr-1 pl-0 col-4 pr-1 pl-0 d-flex align-items-center" data-${this.controllerName}-target="numberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                      <div class="col-4 pr-1 pl-0 d-none" data-${this.controllerName}-target="cnpjInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ do Beneficiário</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="CNPJ do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pr-1 pl-0 d-none" data-${this.controllerName}-target="cpfInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF do Beneficiário</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="grossForm">Valor Pago</label>
                            <input aria-describedby="grossFormHelp" class="form-control form-valid-control" id="grossForm" data-${this.controllerName}-target="amount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Pago" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pr-1">
                        <div class="form-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="datePaymentForm">Data</label>
                            <input aria-describedby="datePaymentFormHelp" class="form-control form-valid-control" id="datePaymentForm" data-${this.controllerName}-target="date" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 pr-1 pl-0">
                        <div class="form-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="description" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 pr-1 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Classifique o Pagamento</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="paymentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="paymentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="paymentList">
                                  <li data-source="" class="li-selector dark">Classifique o Pagamento Realizado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="upload">
                        <div class="form-group my-0 text-center">
                          <input type="file" id="payment" name="payment" class="file-input" data-action="change->${this.controllerName}#saveUpload">
                          <label for="payment" id="paymentLabelForm" class="btn btn-primary btn-sm text-left px-1 f-065" data-${this.controllerName}-target="labelUpload" style="font-size:80%;">Comprovante</label>
                        </div>
                      </div>
                      <div class="col-7 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="uploadFile">
                        <span data-${this.controllerName}-target="fileName" class="ml-2 mr-auto f-065 my-auto" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"></span>
                      </div>
                      <div class="col-1 my-1 px-0 d-flex align-items-center justify-content-between">
                        <button type="button" class="close ml-3 d-none" data-dismiss="modal" data-action="click->${this.controllerName}#cancelUpload" data-${this.controllerName}-target="cancelUploadBtn" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div class="col-12 px-0 d-flex align-items-center justify-content-between w-100">
                        <div class="progress w-100 d-none" style="height: 6px;" data-${this.controllerName}-target="progressUpload">
                          <div class="progress-bar" role="progressbar" style="height: 6px;width:20%;border-bottom:0.5rem solid #053B5E;" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                        </div>
                        <span class="d-none" data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                      </div>

                      <div class="col-12 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="showUpload">
                        <span data-${this.controllerName}-target="fileNameUploadShow" class="d-block f-065"></span>
                        <button data-action="click->${this.controllerName}#goToUpload" data-${this.controllerName}-target="uploadUrl" data-url="" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>
                        <button data-action="click->${this.controllerName}#editUpload" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>
                      </div>
                    </div>

                    <div class="row my-3 d-flex align-items-center d-none" data-${this.controllerName}-target="paymentMember">
                      <div class="col-4 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0">
                          <input type="checkbox" class="custom-control-input" id="paymentMemberCheck" data-${this.controllerName}-target="memberCheckbox" data-action="click->${this.controllerName}#showDependents">
                          <label class="custom-control-label" for="paymentMemberCheck">Titular</label>
                        </div>
                      </div>
                      <div class="col-8 d-none" data-${this.controllerName}-target="dependentSelect">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Dependente</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="dependentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="dependentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dependentList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      // controller.formPaymentCardTarget.style.height = ($(window).height() * 0.5) + "px"
      pickDateLimit($('#datePaymentForm'), "yearly", this.application.current_date.year);
      controller.listDependents()
      controller.doChartAccountList()
      controller.listPaymentName()
      if (controller.actionMode == "new") {
        controller.formTitleTarget.innerText = "Novo Pagamento"
        controller.amountTarget.value = "R$ 0,00"
        controller.uploadTarget.classList.remove("d-none")
        controller.showUploadTarget.classList.add("d-none")
      } else if (controller.actionMode == "edit") {
        controller.formTitleTarget.innerText = "Editando Pagamento"
        controller.dateTarget.value = controller.current_payment.date_pretty
        controller.paymentInputTarget.dataset.id = controller.current_payment.chart_id
        controller.paymentInputTarget.dataset.group = controller.current_payment.chart_group
        controller.paymentDropdownTarget.value = controller.current_payment.chart_group
        controller.paymentInputTarget.innerText = controller.current_payment.chart_pretty
        controller.paymentInputTarget.dataset.chart = controller.current_payment.chart_pretty
        controller.amountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_payment.amount * 100))
        controller.numberInputTarget.remove()
        controller.descriptionTarget.value = controller.current_payment.description
        controller.paymentNameTarget.value = controller.current_payment.id_name
        if (controller.current_payment.id_type == "individual") {
          controller.cpfCheckboxTarget.checked = true
          controller.cnpjCheckboxTarget.checked = false
          controller.cnpjInputTarget.classList.add("d-none")
          controller.cpfInputTarget.classList.remove("d-none")
          controller.cpfTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cpfMask(controller.current_payment.id_number)
        } else if (controller.current_payment.id_type == "legal") {
          controller.cpfCheckboxTarget.checked = false
          controller.cnpjCheckboxTarget.checked = true
          controller.cnpjInputTarget.classList.remove("d-none")
          controller.cpfInputTarget.classList.add("d-none")
          controller.cnpjTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cnpjMask(controller.current_payment.id_number)
        }
        if (controller.current_payment.has_receipt) {
          controller.uploadTarget.classList.add("d-none")
          controller.cancelUploadBtnTarget.classList.add("d-none")
          controller.showUploadTarget.classList.remove("d-none")
          controller.fileNameUploadShowTarget.innerText = controller.current_payment.receipt_name
          controller.uploadUrlTarget.dataset.url = controller.current_payment.receipt_url
        } else {
          controller.uploadTarget.classList.remove("d-none")
          controller.cancelUploadBtnTarget.classList.remove("d-none")
          controller.showUploadTarget.classList.add("d-none")
        }
        if (controller.current_payment.member_kind == "dependent") {
          controller.dependentInputTarget.dataset.dependentid = controller.current_payment.member_id
          controller.dependentDropdownTarget.value = controller.current_payment.member_name
          controller.dependentInputTarget.innerText = controller.current_payment.member_name
          controller.dependentSelectTarget.classList.remove("d-none")
          controller.memberCheckboxTarget.checked = false
        }
      }
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      selector()
    })

  }

  doChartAccountList() {
    var listHtml = ``
    if (this.application.products.has_booking) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Despesas de Livro-Caixa devem ser lançados no Livro-Caixa", 3000)
      this.application.chart_accounts.forEach(element => {
        if (element.master == "expenses" && element.group != "booking") {
          listHtml += `<li data-id="${element.id}" data-group="${element.group}" data-chart="${element.chart}" class="li-selector dark">${element.chart}</li>`
        }
      });
    } else {
      this.application.chart_accounts.forEach(element => {
        if (element.master == "expenses") {
          listHtml += `<li data-id="${element.id}" data-group="${element.group}" data-chart="${element.chart}" class="li-selector dark">${element.chart}</li>`
        }
      });
    }
    
    this.paymentListTarget.innerHTML = listHtml
  }

  listPaymentName() {
    var html = ``
    if (this.application.payments.length == 0) {
      html += `<li class="li-selector dark f-065"">Não existem pagamentos realizados</li>`
    } else {
      var uniqPayments = []
      var uniqPaymentsElements = []

      this.application.payments.forEach(element => {
        if (uniqPaymentsElements.indexOf(element.id_name) === -1) {
          uniqPaymentsElements[uniqPaymentsElements.length] = element.id_name
          uniqPayments[uniqPayments.length] = element
        }
      });

      uniqPayments.forEach(element => {
        html += `<li data-filter="${element.id}" data-text="${element.id_name}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectPaymentName" data-${this.controllerName}-target="paymentNameFilterItem">${element.id_name}</li>`
      });
    }

    this.paymentNameFilterTarget.innerHTML = html
  }

  selectPaymentName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.setPaymentName()

    // var payment = ``
    // this.application.payments.forEach(element => {
    //   if (element.id == ev.target.dataset.filter) {
    //     payment = element
    //   }
    // });
    // if (this.hasNumberInputTarget) {
    //   this.numberInputTarget.remove()
    // }
    // if (payment.id_type == "individual") {
    //   this.cpfTarget.value = payment.id_number_pretty
    //   this.cpfCheckboxTarget.checked = true
    //   this.cnpjCheckboxTarget.checked = false
    //   this.cnpjInputTarget.classList.add("d-none")
    //   this.cpfInputTarget.classList.remove("d-none")
    // } else if (payment.id_type == "legal") {
    //   this.cnpjTarget.value = payment.id_number_pretty
    //   this.cpfCheckboxTarget.checked = false
    //   this.cnpjCheckboxTarget.checked = true
    //   this.cnpjInputTarget.classList.remove("d-none")
    //   this.cpfInputTarget.classList.add("d-none")
    // }
    // this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  entrePaymentName(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.setPaymentName()
    }
  }

  setPaymentName() {
    var payment = ``
    this.application.payments.forEach(element => {
      if (element.id == this.paymentNameTarget.dataset.filter) {
        payment = element
      }
    });

    if (this.hasNumberInputTarget) {
      this.numberInputTarget.remove()
    }

    if (payment.id_type == "individual") {
      this.cpfTarget.value = payment.id_number_pretty
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (payment.id_type == "legal") {
      this.cnpjTarget.value = payment.id_number_pretty
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }

    this.amountTarget.focus()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  paymentNameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  goToUpload(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  editUpload(ev) {
    this.uploadTarget.classList.remove("d-none")
    this.cancelUploadBtnTarget.classList.remove("d-none")
    this.showUploadTarget.classList.add("d-none")
  }

  cancelUpload(ev) {
    this.uploadTarget.classList.add("d-none")
    this.cancelUploadBtnTarget.classList.add("d-none")
    this.showUploadTarget.classList.remove("d-none")
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfTarget.classList.add("f-danger")
        this.cpfTarget.classList.remove("f-normal")
      } else {
        this.cpfTarget.classList.add("f-normal")
        this.cpfTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfTarget.classList.add("f-normal")
      this.cpfTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjTarget.classList.add("f-danger")
        this.cnpjTarget.classList.remove("f-normal")
      } else {
        this.cnpjTarget.classList.add("f-normal")
        this.cnpjTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjTarget.classList.add("f-normal")
      this.cnpjTarget.classList.remove("f-danger")
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasNumberInputTarget) {
        len += 1
      }

      if (controller.cpfCheckboxTarget.checked == false && controller.cnpjCheckboxTarget.checked == false) {
        len += 1
      }

      if (controller.hasCpfCheckboxTarget) {
        if (controller.cpfTarget.value == "") {
          len += 1  
        }
      } else if (controller.hasCnpjCheckboxTarget) {
        if (controller.cnpjTarget.value == "") {
          len += 1
        }
      }
      if (controller.paymentDropdownTarget.value == "" || controller.paymentDropdownTarget.value == undefined) {
        len += 1
      }
      if (fromCurrencyToNumber(controller.amountTarget.value) == 0) {
        len += 1
      }
      if (controller.paymentInputTarget.innerText == "") {
        len += 1
      }
      if (controller.file == undefined && this.actionMode == `new`) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  formErrorsMessage() {
    if (fromCurrencyToNumber(this.grossTarget.value) < fromCurrencyToNumber(this.withheldTarget.value)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Valor de Retenção maior que Valor Bruto", 2000)
    }
    if (fromCurrencyToNumber(this.grossTarget.value) < fromCurrencyToNumber(this.socialTarget.value)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Valor de Contribuição maior que Valor Bruto", 2000)
    }
  }

  listDependents() {
    this.current_dependent_members = []
    this.current_members = []

    this.application.members.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        if (element.kind == "dependent") {
          this.current_dependent_members[this.current_dependent_members.length] = element
        } else {
          this.member = element
        }
        this.current_members[this.current_members.length] = element
      }
    });

    if (this.current_dependent_members.length > 0) {
      var listHtml = ``
      this.current_dependent_members.forEach(element => {
        listHtml += `<li data-dependent-id="${element.id}" class="li-selector dark">${element.account_name}</li>`
      })
      this.dependentListTarget.innerHTML = listHtml
      this.dependentInputTarget.dataset.dependentid = this.member.id
      this.memberCheckboxTarget.checked = true
      this.paymentMemberTarget.classList.remove("d-none")
    } else {
      this.dependentInputTarget.dataset.dependentid = this.member.id
      this.paymentMemberTarget.classList.add("d-none")
    }
  }

  showDependents(ev) {
    if (ev.target.checked) {
      this.dependentSelectTarget.classList.add("d-none")
      this.dependentInputTarget.dataset.dependentid = this.member.id
    } else {
      this.dependentSelectTarget.classList.remove("d-none")
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
