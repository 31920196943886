import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--recruitments--vacancies--index--dashboard`
  }

  doVacanciesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="main" data-controller="users--works--recruitments--vacancies--index--list users--works--recruitments--vacancies--index--save"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--index--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--recruitments--vacancies--index--list`).permission = `user_works_recruitments_vacancies`
      controller.getControllerByIdentifier(`users--works--recruitments--vacancies--index--save`).permission = `user_works_recruitments_vacancies`

      controller.getControllerByIdentifier(`users--works--recruitments--vacancies--index--list`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
