import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--oncalls--add--contacts`
    this.doAccountForm()
  }

  doAccountForm() {
    var html = `<div class="col-12 pl-1" data-users--accounts--entities--save-shared-target="main" data-controller="users--accounts--entities--save-shared"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--oncalls--add--dashboard`).formTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`users--accounts--entities--save-shared`).controllerDashboard = controller.controllerDashboard
      controller.getControllerByIdentifier(`users--accounts--entities--save-shared`).products = controller.application.oncalls
      controller.getControllerByIdentifier(`users--accounts--entities--save-shared`).doAccountFormHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
