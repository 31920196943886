import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "mainDashboard", "contentDashboard", "indicadorDashboard", "calculatorsDashboard",
                    "linksDashboard", "postCardBody", "productCardBody", "tutorials", "productsDashboard"]

  connect() {
    this.controllerName = `users--dashboards--main--doctor`
    this.doctorPreloader()
    this.getAccountProduct()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  doLinksCard() {
    var cardHtml = ``

    // if (this.product.has_tax_return) {
    //   cardHtml += `<div class="col-12 px-2 my-2">
    //                 <div class="card">
    //                   <div class="card-header p-1 text-center f-065">
    //                     <span>Planner MedCapital</span>
    //                   </div>
    //                   <div class="card-body text-center px-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
    //                     <span class="material-icons md-primary pointer" data-url="/d/planner-anual/${this.product.tax_return_token}" data-action="click->${this.controllerName}#goToURL">outlined_flag</span>
    //                     <span class="mc-tooltiptext">Ir para Planner MedCapital</span>
    //                   </div>
    //                 </div>
    //               </div>`
    // }

    if (this.product.has_tax_filing) {
      cardHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>Declaração IRPF</span>
                      </div>
                      <div class="card-body text-center px-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                        <span class="material-icons md-primary pointer" data-url="/d/declaracao-de-ajuste/${this.product.tax_filing_token}" data-action="click->${this.controllerName}#goToURL">receipt</span>
                        <span class="mc-tooltiptext">Ir para Declaração IRPF</span>
                      </div>
                    </div>
                  </div>`
    }

    if (this.product.has_booking) {
      cardHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>Livro-Caixa</span>
                      </div>
                      <div class="card-body text-center px-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                        <span class="material-icons md-primary pointer" data-url="/d/livro-caixa/${this.product.booking_token}" data-action="click->${this.controllerName}#goToURL">receipt_long</span>
                        <span class="mc-tooltiptext">Ir para Livro-Caixa</span>
                      </div>
                    </div>
                  </div>`
    }

    if (this.product.has_receivement) {
      cardHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>Gestão de Recebimentos</span>
                      </div>
                      <div class="card-body text-center px-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                        <span class="material-icons md-primary pointer" data-url="/d/recebimentos/${this.product.receivement_token}" data-action="click->${this.controllerName}#goToURL">account_balance_wallet</span>
                        <span class="mc-tooltiptext">Ir para Gestão de Recebimentos</span>
                      </div>
                    </div>
                  </div>`
    }

    if (this.product.has_clinic) {
      if (this.application.permissions.medclinic_doctor_clients.can_show) {
        this.product.clinics.forEach(element => {
          cardHtml += `<div class="col-12 px-2 my-2">
                      <div class="card">
                        <div class="card-header p-1 text-center f-065">
                          <span>${element.company_name}</span>
                        </div>
                        <div class="card-body text-center px-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                          <span class="material-icons md-primary pointer" data-url="/d/pj-medica/${element.clinic_token}" data-action="click->${this.controllerName}#goToURL">business</span>
                          <span class="mc-tooltiptext">Ir para ${element.company_name}</span>
                        </div>
                      </div>
                    </div>`
        })
      }
    }



    var html = `<h6 class="mb-0 text-center">Meus Serviços</h6>
                  <hr class="mt-1">
                  <div class="row">
                    ${cardHtml}
                  </div>`

    this.linksDashboardTarget.innerHTML = html
  }

  doTutorialCard() {

    var html = `<h6 class="mb-0 text-center f-bold f-085">Descanso Médico</h6>
                <hr class="mt-1">`

    this.tutorials.forEach(element => {
      html += `<div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.title}</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${element.url}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">manage_search</span>
                        <span class="mc-tooltiptext">${element.title}</span>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.tutorialsTarget.innerHTML = html
  }

  doProductsCard() {
    console.log(this.tax_filing_calculation)
    var html = `<h6 class="mb-0 text-center f-bold f-085">Minha ${this.tax_filing_calculation.name}</h6>
                <hr class="mt-1">`

    html += `<div class="row">
              <div class="col-10 offset-1 px-2 my-2">
                <div class="card">
                  <div class="card-header p-1 text-center f-065">
                    <span>Status</span>
                  </div>
                  <div class="card-body text-center py-2 pointer" data-url="/d/declaracao-de-ajuste/${this.tax_filing_calculation.uniq_product}" data-action="click->${this.controllerName}#goToURL">
                    <span class="f-075 f-bold">${this.tax_filing_calculation.journey_status_pretty}</span>
                  </div>
                </div>
              </div>
            </div>`

    var receiptFileHtml = ``
    if (this.tax_filing_calculation.has_receipt) {
      receiptFileHtml += `<div class="card">
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${this.tax_filing_calculation.receipt_url}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-primary pointer">receipt_long</span>
                              <span class="mc-tooltiptext">Transmitida dia ${this.tax_filing_calculation.file_time_pretty}</span>
                            </div>
                          </div>`
    }

    var receiptHtml = `<div class="col-5 offset-1 px-2 my-2">
                        <h6 class="mb-0 text-center f-bold f-085">Recibo Declaração</h6>
                        <hr class="mt-1">
                        ${receiptFileHtml}
                      </div>`

    var filingFileHtml = ``
    if (this.tax_filing_calculation.has_filing) {
      filingFileHtml += `<div class="card">
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${this.tax_filing_calculation.filing_url}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-primary pointer">receipt_long</span>
                              <span class="mc-tooltiptext">Transmitida dia ${this.tax_filing_calculation.file_time_pretty}</span>
                            </div>
                        </div>`
    }

    var filingHtml = `<div class="col-5 px-2 my-2">
                        <h6 class="mb-0 text-center f-bold f-085">Declaração</h6>
                        <hr class="mt-1">
                        ${filingFileHtml}
                      </div>`

    html += `<div class="row">
              ${filingHtml}
              ${receiptHtml}
            </div>`
    

    this.productsDashboardTarget.innerHTML = html
  }

  getAccountProduct() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    
    const url = "/operations/accounts/products/read_with_calculations"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.product = response.data.cln.product
          controller.tax_filing_calculation = response.data.cln.tax_filing_calculation

          controller.getTutorials()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTutorials() {
    var data = { post: { active: true, account_kind: this.application.current_user.account_kind }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/contents/posts/entities/list_tutorials"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.tutorials = response.data.cln
          controller.doLinksCard()
          controller.doProductsCard()
          controller.doTutorialCard()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  doctorPreloader() {

    var loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    var cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="linksDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                  </div>
                  <div class="col-4" data-${this.controllerName}-target="productsDashboard"></div>
                  <div class="col-4"></div>
                  <div class="col-2" data-${this.controllerName}-target="tutorials"></div>
                </div>`

    this.contentTarget.innerHTML = html

  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
