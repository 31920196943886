import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewRegimeCard", "viewTitle", "viewRegimeCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--add--show`
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.spaceRow = `<td colspan="5" style="height:0rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--dashboard`)) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)
    }
  }

  doRevisionFormHtml() {
    //invoice_number_pretty
    // this.current_receivable.token

    if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)) {
      this.dashboardController.actionViewTarget.innerHTML = ``
    }

    if (this.current_receivable.main_invoice.flow_message) {
      var invoiceStatusFlowMessage = `<span class="mc-tooltip help">
                                        <span class="help">Status da NF: ${this.current_receivable.main_invoice.full_status_message}</span>
                                        <span class="mc-tooltiptext">${this.current_receivable.main_invoice.flow_message}</span>
                                      </span>`
    } else {
      var invoiceStatusFlowMessage = `<span class="default">Status da NF: ${this.current_receivable.main_invoice.full_status_message}</span>`
    }

    var addCancelReasonHtml = ``
    if (this.current_receivable.invoice_status == `canceled`) {
      if (this.current_receivable.cancel_reason) {
        invoiceStatusFlowMessage += ` | Motivo Cancelamento: ${this.current_receivable.cancel_reason_pretty} | ${this.current_receivable.cancel_description}`
      } else {
        addCancelReasonHtml += `<div class="card-actions py-0">
                                  <span class="mc-tooltip">
                                    <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#doCancelModalHtml" type="button">
                                      <span class="material-icons md-dark">add</span>
                                    </button>
                                    <span class="mc-tooltiptext">Adicionar Motivo Cancelamento</span>
                                  </span>
                                </div>`
      }
    }

    if (this.current_receivable.main_invoice.invoice_number_pretty) {
      var invoiceNumberText = this.current_receivable.main_invoice.invoice_number_pretty
    } else {
      var invoiceNumberText = `Sem Número`
    }

    var invoiceNumber = `<span class="mb-0 f-065 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_receivable.token}" data-action="click->app--helpers--copy#copy">Nota Fiscal #${invoiceNumberText}</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar o Token</span>
                        </span>`

    var html = `<div class="row">
                  <div class="col-11 pl-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard-${this.current_receivable.id}" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 pointer" style="padding:1rem;font-size:110%;margin-bottom:0px;">
                          ${invoiceNumber}
                        </h6>
                        <h6 class="ml-auto card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">
                          ${invoiceStatusFlowMessage}
                        </h6>
                        ${addCancelReasonHtml}
                      </div>
                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                        <div class="row my-3">
                          <div class="col-4">
                            ${this.doInfoHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doPerformersHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doTaxesHtml()}
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#goBack">Fechar</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center pr-0" data-controller="operations--products--clinics--financials--receivables--action" data-operations--products--clinics--financials--receivables--action-target="action">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).permission = this.integration_permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).doActionHtml()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  goBack() {
    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)) {
      this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard").doReceivablesDashboard()
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--dashboard`)) {
      this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--list").doIndexListHtml()
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)) {
      this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).doReceivablesDashboard()
    }

    // this.dashboardController.doReceivablesDashboard()
  }

  doTaxesHtml() {
    var otherDeductions = Number(this.current_receivable.unconditioned_amount) + Number(this.current_receivable.conditioned_amount) + Number(this.current_receivable.others_amount_withheld)
    // `${this.numberController.currencyOptionMask(this.revision_data.receivable.others_amount_withheld, { scale: 2 })}`

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Valor dos Serviços e Valores de Impostos
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left px-0 f-065 f-bold">
                        Regime Tributário: ${this.current_receivable.tax_regime_pretty}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Condicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.conditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Incondicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.unconditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Base de Cálculo</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.calculation_basis, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (x) <strong>Alíquota ISS</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.percentOptionMask(this.current_receivable.iss_rate, { scale: 4 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Valor do ISS</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.iss_tax_amount, { scale: 4 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>Retenções Federais</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;"></td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) PIS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.pis_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) COFINS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.cofins_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) IRPJ
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.irpj_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) CSLL
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.csll_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Outras Retenções / Descontos
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(otherDeductions, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Retenções Federais
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) ISS Retido
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.municipal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Valor Líquido</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.net_amount, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total Devido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_tax_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(-) Total Retido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask((this.current_receivable.total_tax_amount - this.current_receivable.total_tax_payable))}
                      </td>
                    </tr>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total à Pagar de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_tax_payable)}
                      </td>
                    </tr>


                  </tbody>
                </table>`

    return html
  }

  doPerformersHtml() {
    var performerHtml = ``

    this.current_receivable.performers.forEach(element => {
      performerHtml += `<tr>
                        <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          ${element.name}
                        </td>
                        <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          <strong>Valor</strong>: ${this.numberController.currencyOptionMask(element.amount)}
                        </td>
                      </tr>`
    })

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="10" class="text-left f-bold f-085 px-0">
                        Prestador de Serviços
                      </td>
                    </tr>
                    <tr>
                      <td colspan="10" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                        ${this.current_receivable.provider_name} <br>
                        ${this.current_receivable.provider_number} <br>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Médicos
                      </td>
                    </tr>

                    ${performerHtml}
                  </tbody>
                </table>`

    return html
  }

  doInfoHtml() {
    if (this.current_receivable.taker_type == `legal`) {
      if (this.current_receivable.taker_municipal_tax_number) {
        var municipalNumber = `IM: ${this.current_receivable.taker_municipal_tax_number}`
      } else {
        var municipalNumber = `Sem Inscrição Municipal`
      }
      var federalNumber = `CNPJ: ${this.current_receivable.taker_federal_tax_number_pretty}`
    } else if (this.current_receivable.taker_type == `individual`) {
      var municipalNumber = ``
      var federalNumber = `CPF: ${this.current_receivable.taker_federal_tax_number_pretty}`
    }

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Competência</strong>: ${this.current_receivable.date_pretty} <br>
                        <strong>Data</strong>: ${this.current_receivable.issued_on_pretty}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Tomador
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                        ${this.current_receivable.taker_name} <br>
                        ${federalNumber} <br>
                        ${municipalNumber} <br>
                        <small>
                          ${this.current_receivable.taker_street}, ${this.current_receivable.taker_number}, ${this.current_receivable.taker_complement},
                          ${this.current_receivable.taker_district}, <br> ${this.current_receivable.taker_city_name}/${this.current_receivable.taker_state},
                          CEP: ${this.current_receivable.taker_postal_code_pretty}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Descrição</strong>: ${this.current_receivable.description}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        CTISS
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Código</strong>: ${this.current_receivable.ctiss_code} <br>
                        <strong>Descrição</strong>: ${this.current_receivable.ctiss_description}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Natureza Operação
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left">
                        ${this.current_receivable.taker_taxation_type_pretty}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Cidade Tributação
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left">
                        ${this.current_receivable.service_city_name}
                      </td>
                    </tr>
                    
                  </tbody>
                </table>`

    return html
  }

  doCancelModalHtml() {
    var controllerName = `operations--products--clinics--financials--receivables--cancel`

    var html = `<div class="modal fade" data-controller="${controllerName}" data-${controllerName}-target="modal" tabindex="-1" role="dialog" aria-labelledby="closingModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Selecione o Motivo do Cancelamento</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${controllerName}-target="body">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-2 d-flex align-items-center">
                            <div class="form-group w-100 mb-1">
                              <div class="floating-label floating-label-sm">
                                <label>Motivo Cancelamento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${controllerName}-target="reasonDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${controllerName}-target="reasonDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${controllerName}-target="reasonInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${controllerName}-target="reasonList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center d-none" data-${controllerName}-target="reasonDescriptionDiv">
                          <div class="col-12 px-2 d-flex align-items-center">
                            <div class="form-group w-100 mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="reasonDescriptionForm">Descrição</label>
                                <input id="reasonDescriptionForm" class="form-control f-075 pt-0" data-${controllerName}-target="reasonDescription" type="text" placeholder="Descrição" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${controllerName}#closeModal">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-${controllerName}-target="saveBtn" data-action="click->${controllerName}#confirmCancelInvoice">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerName).current_receivable = controller.current_receivable
      controller.getControllerByIdentifier(controllerName).permission = controller.permission
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
