import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "accountCard", "editBtn", "cancelBtn", "saveBtn", "sexInput", "main", "7777", "8888", "9999",
                    "nameDiv", "nameInputDiv", "nameInput",
                    "cpfDiv", "cpfInputDiv", "cpfInput", "birthdateDiv", "birthdateInputDiv", "birthdateInput",
                    "accountKind", "accountDoctor", "accountNonDoctor", "accountTaker", "accountPatient", "accountHelper",
                    "accountTeam"]

  connect() {
    this.controllerName = `users--accounts--entities--principal`
    this.current_account = this.getControllerByIdentifier(`users--accounts--entities--show`).current_account
    this.cpf_result = { invalid: false }
    this.doAccountCard()
  }

  doAccountCard() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.account_entities.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editAccount" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    // `<tr data-field="cpf" data-${this.controllerName}-target="cpfRow">
    //   <th colspan="3" class="px-0 f-065 align-middle"><strong>CPF</strong></th>
    //   <td colspan="6" class="px-0 f-065 align-middle">
    //     <div data-${this.controllerName}-target="cpfDiv">
    //       ${this.current_account.cpf_pretty}
    //     </div>
    //     <div class="d-none" data-${this.controllerName}-target="cpfInputDiv">
    //       <input data-${this.controllerName}-target="cpfInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" required>
    //     </div>
    //   </td>
    //   <td colspan="1" class="align-middle text-center">
    //     ${edit}
    //     ${cancel}
    //   </td>
    // </tr>`

    var html = `<div class="row my-1">
                  <div class="col-12 px-2">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          
                        </tr>

                        <tr data-field="name" data-${this.controllerName}-target="nameRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Nome</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="nameDiv">
                              ${this.current_account.name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                              <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr>
                          <th colspan="3" class="f-065 align-middle px-0">
                            <strong>CPF</strong> <br>
                          </th>
                          <td colspan="7" class="f-065 align-middle px-0 text-left">
                            <span class="mb-0 f-065 mc-tooltip grab">
                              <span data-controller="app--helpers--copy" data-copy="${this.current_account.cpf}" data-action="click->app--helpers--copy#copy">${this.current_account.cpf_pretty}</span>
                              <span class="mc-tooltiptext">Copiar</span>
                            </span>
                          </td>
                        </tr>


                        <tr data-field="birthdate" data-${this.controllerName}-target="birthdateRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Nascimento</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="birthdateDiv">
                              ${this.current_account.birthdate_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="birthdateInputDiv">
                              <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate keyup->${this.controllerName}#enterInput" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>


                        <tr>
                          <th colspan="3" class="f-065 align-middle px-0">
                            <strong>Sexo</strong> <br>
                          </th>
                          <td colspan="7" class="f-065 align-middle px-0 text-left" data-${this.controllerName}-target="sexInput" data-sex="${this.current_account.sex}">
                            ${this.current_account.sex_pretty}
                          </td>
                        </tr>

                        <tr>
                          ${spaceRow}
                        </tr>

                        <tr>
                          <th colspan="1" class="f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountTeam" data-${this.controllerName}-target="accountKind accountTeam" data-kind="team" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountTeam">Time</label>
                              <span class="mc-tooltiptext">É do Time MedCapital?</span>
                            </div>
                          </th>
                          <th colspan="2" class="f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountDoctor" data-${this.controllerName}-target="accountKind accountDoctor" data-kind="doctor" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountDoctor">Médico</label>
                              <span class="mc-tooltiptext">É Médico, com CRM?</span>
                            </div>
                          </th>
                          <th colspan="2" class="px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountNonDoctor" data-${this.controllerName}-target="accountKind accountNonDoctor" data-kind="non_doctor" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountNonDoctor">Não-Médico</label>
                              <span class="mc-tooltiptext">Não é Médico, sem CRM?</span>
                            </div>
                          </th>
                          <th colspan="2" class="f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountHelper" data-${this.controllerName}-target="accountKind accountHelper" data-kind="helper" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountHelper">Assistente</label>
                              <span class="mc-tooltiptext">É Secretário(a)/Assistente?</span>
                            </div>
                          </th>
                          <th colspan="2" class="f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountPatient" data-${this.controllerName}-target="accountKind accountPatient" data-kind="patient" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountPatient">Paciente</label>
                              <span class="mc-tooltiptext">É Paciente?</span>
                            </div>
                          </th>
                          <th colspan="2" class="px-0 f-065 align-middle">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                              <input type="checkbox" class="custom-control-input" id="accountTaker" data-${this.controllerName}-target="accountKind accountTaker" data-kind="taker" data-action="click->${this.controllerName}#clickCheckbox">
                              <label class="custom-control-label f-065 pointer" for="accountTaker">Tomador</label>
                              <span class="mc-tooltiptext">É Tomador de Serviços?</span>
                            </div>
                          </th>
                        </tr>
                        
                        
                        <tr>
                          <td colspan="10" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveAccount" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.kind = controller.mainTarget.dataset.kind
      
      if (controller.current_account.kind.includes(`team`)) {
        controller.accountTeamTarget.checked = true
      } else {
        controller.accountTeamTarget.checked = false
      }
      if (controller.current_account.kind.includes(`doctor`)) {
        controller.accountDoctorTarget.checked = true
      } else {
        controller.accountDoctorTarget.checked = false
      }
      if (controller.current_account.kind.includes(`taker`)) {
        controller.accountTakerTarget.checked = true
      } else {
        controller.accountTakerTarget.checked = false
      }
      if (controller.current_account.kind.includes(`patient`)) {
        controller.accountPatientTarget.checked = true
      } else {
        controller.accountPatientTarget.checked = false
      }
      if (controller.current_account.kind.includes(`helper`)) {
        controller.accountHelperTarget.checked = true
      } else {
        controller.accountHelperTarget.checked = false
      }
      if (controller.current_account.kind.includes(`non_doctor`)) {
        controller.accountNonDoctorTarget.checked = true
      } else {
        controller.accountNonDoctorTarget.checked = false
      }

      controller.accountKindTargets.forEach(element => {
        if (controller.application.permissions.account_entity_profiles.can_manage) {
          element.disabled = false
        } else {
          element.disabled = true
        }
      })

      if (controller.application.current_user.super_admin) {
        controller.accountTeamTarget.disabled = false
      } else {
        controller.accountTeamTarget.disabled = true
      }
    })
  }

  clickCheckbox() {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()
  }

  editAccount(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    // } else if (this.field == "cpf") {
    //   this.cpfDivTarget.classList.add("d-none")
    //   this.cpfInputDivTarget.classList.remove("d-none")
    //   this.cpfInputTarget.value = this.cpfDivTarget.innerText.trim()
    //   this.cpfInputTarget.focus()
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.add("d-none")
      this.birthdateInputDivTarget.classList.remove("d-none")
      this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
      this.birthdateInputTarget.focus()
      // this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    }

  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.current_account.name
    // } else if (this.field == "cpf") {
    //   this.cpfDivTarget.classList.remove("d-none")
    //   this.cpfInputDivTarget.classList.add("d-none")
    //   this.cpfDivTarget.innerText = this.current_account.cpf_pretty
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.current_account.birthdate_pretty
    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    // if (this.cpfDivTarget.classList.contains("d-none")) {
    //   this.cpfDivTarget.classList.remove("d-none")
    //   this.cpfInputDivTarget.classList.add("d-none")
    //   this.cpfDivTarget.innerText = this.cpfInputTarget.value
    // }

    if (this.nameDivTarget.classList.contains("d-none")) {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.nameInputTarget.value
    }

    if (this.birthdateDivTarget.classList.contains("d-none")) {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.birthdateInputTarget.value
    }

  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  enterInput(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.saveAccount()
    }
  }

  saveAccount() {
    this.send_data = { current_user: {}, account: {}, kind: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = controller.application.current_user.id
      controller.send_data.current_user.feature = `account_entities`

      controller.send_data.kind.kind = controller.getControllerByIdentifier("users--accounts--entities--dashboard").kind

      controller.send_data.account.id = controller.current_account.id
      controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
      controller.send_data.account.sex = controller.sexInputTarget.dataset.sex
      controller.send_data.account.birthdate = controller.getControllerByIdentifier("app--helpers--date").transformNumberDate(controller.birthdateDivTarget.innerText.trim())
      controller.send_data.account.kind = []

      controller.accountKindTargets.forEach(element => {
        if (element.checked) {
          controller.send_data.account.kind[controller.send_data.account.kind.length] = element.dataset.kind
        }
      })

      controller.stopRefreshing()
      if (controller.cpf_result.invalid == false) {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        controller.requestSave()
      }
    })

  }

  requestSave() {
    var url = "/users/accounts/entities/update_entity"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var account = response.data.cln
          controller.current_account = account

          controller.application.accounts.forEach((element, i) => {
            if (element.id == account.id) {
              controller.application.accounts.splice(controller.application.accounts.indexOf(element), 1, account)
            }
          })

          controller.doAccountCard()
          controller.getControllerByIdentifier(`users--accounts--entities--show`).viewTitleTarget.innerText = controller.current_account.name
          controller.getControllerByIdentifier(`users--accounts--entities--index`).doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
        len += 1
      }

      // if (controller.cpfInputTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") {
      //   len += 1
      // }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}