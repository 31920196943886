import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["media", "name", "title", "loader"]

  connect() {
    this.controllerName = `users--dashboards--main--greeting`
    this.greet()
  }

  greet() {

    const rowHtml = `<div class='card timeline-item m-0 p-1 w-10' data-${this.controllerName}-target="loader" style="box-shadow:none;">
                        <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                        </div>
                      </div>`

    this.mediaTarget.innerHTML = rowHtml
    var controller = this
    setTimeout(function () {

      if (controller.application.current_user.account_kind == "admin" || controller.application.current_user.account_kind == "team") {
        var speak = `<h5 style="margin-bottom:0px;" data-${this.controllerName}-target="name"><strong>Olá ${controller.application.current_user.name.split(" ")[0]}!</strong></h5>`
      } else if (controller.application.current_user.account_kind == "doctor") {
        if (controller.application.current_user.sex == "male") {
          var speak = `<h5 style="margin-bottom:0px;" data-${this.controllerName}-target="name"><strong>Olá Dr. ${controller.application.current_user.name.split(" ")[0]}!</strong></h5>`
        } else if (controller.application.current_user.sex == "female") {
          var speak = `<h5 style="margin-bottom:0px;" data-${this.controllerName}-target="name"><strong>Olá Dra. ${controller.application.current_user.name.split(" ")[0]}!</strong></h5>`
        }
      } else if (controller.application.current_user.account_kind == "helper") {
        var speak = `<h5 style="margin-bottom:0px;" data-${this.controllerName}-target="name"><strong>Olá ${controller.application.current_user.name.split(" ")[0]}!</strong></h5>`
      }

      controller.loaderTarget.remove()
      controller.mediaTarget.insertAdjacentHTML("beforeend", speak)
      controller.mediaTarget.insertAdjacentHTML("beforeend", rowHtml)
      setTimeout(function () {
        var speak = `<span class="text-black-secondary" data-${this.controllerName}-target="title">Esse é o seu Dashboard MedCapital</span>`
        controller.loaderTarget.remove()
        controller.mediaTarget.insertAdjacentHTML("beforeend", speak)
      }, 1000)
    }, 1000)
  }


}
