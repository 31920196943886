import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "cardBody", "footerTable", "bodyTable", "list", "form", "titleCard", "saveBtn", "saveCard",
      "code", "deductibility", "chartName", "groupDropdown", "groupDropdownBtn", "groupInput", "groupList"]

  connect() {
    this.controllerName = `operations--products--tax-returns--entities--chart-account`
    this.doCompomentGrid()
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
  }

  doCompomentGrid() {
    var html = `<div class="row">
                  <div class="col-8 px-1" data-${this.controllerName}-target="list"></div>
                  <div class="col-4 px-1" data-${this.controllerName}-target="form"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--entities--settings").chartAccountTarget.innerHTML = html)
    }).then(() => {
      this.doChartAccountListHtml()
    })
  }

  newChartAccount() {
    this.actionMode = "new"
    this.doFormHtml()
  }

  editChartAccount(ev) {
    this.actionMode = "edit"
    this.application.chart_accounts.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        this.chart_account = element
      }
    })

    this.doFormHtml()
  }

  cancelSave() {
    this.stopRefreshing()
    this.saveCardTarget.remove()
  }

  saveChartAccount() {
    this.send_data = { current_user: {}, chart_account: {}, notification: {} }

    if (this.actionMode == "edit") { this.send_data.chart_account.id = this.chart_account.id }
    this.send_data.chart_account.code = this.codeTarget.value.trim()
    this.send_data.chart_account.name = this.chartNameTarget.value.trim()
    this.send_data.chart_account.group = this.groupInputTarget.dataset.group
    this.send_data.chart_account.deductibility = this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.deductibilityTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id

    // if (this.actionMode == "edit") {
    //   this.send_data.chart_account.id = this.chart_account.id
    //   var data = { chart_account: { id: this.chart_account.id, code: this.codeTarget.value.trim(), name: this.chartNameTarget.value, group: this.groupInputTarget.dataset.group,
    //                                 deductibility: this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.deductibilityTarget.value)
    //                             },
    //              current_user: { current_user_id: this.application.current_user.id }
    //             }
    // } else if (this.actionMode == "new") {
    //   var data = { chart_account: { code: this.codeTarget.value, name: this.chartNameTarget.value.trim(), group: this.groupInputTarget.dataset.group,
    //                                 deductibility: this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.deductibilityTarget.value)
    //                             },
    //              current_user: { current_user_id: this.application.current_user.id }
    //             }
    // }

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/chart_accounts/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/chart_accounts/create"
      var method = "POST"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var chartAccount = response.data.cln
          this.stopRefreshing()
          this.cancelSave()
          if (this.actionMode == "edit") {
            if (chartAccount.active) {
              this.application.chart_accounts.forEach((element, i) => {
                if (element.id == chartAccount.id) {
                  this.application.chart_accounts.splice(this.application.chart_accounts.indexOf(element), 1, chartAccount)
                }
              })
            } else {
              this.application.chart_accounts.forEach((element, i) => {
                if (element.id == chartAccount.id) {
                  this.application.chart_accounts.splice(this.application.chart_accounts.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.chart_accounts[this.application.chart_accounts.length] = chartAccount
          }
          this.doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.medreturn_chart_accounts.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveChartAccount" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }
      // if (this.application.medreturn_chart_accounts.can_delete && this.actionMode == "edit") {
      //   var deleteBtnHtml = `<button disabled type="button" class="btn btn-danger btn-sm btn-table f-065" data-action="click->${this.controllerName}#deleteChartAccount" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
      // } else {
      //   var deleteBtnHtml = ``
      // }

    var deleteBtnHtml = ``


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="saveCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="titleCard"></h6>
                  </div>
                  <div class="card-body p-0">
                    <div class="row my-3">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="codeForm">Código</label>
                            <input aria-describedby="codeFormHelp" class="form-control form-valid-control" id="codeForm" data-${this.controllerName}-target="code" placeholder="Código" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="deductibilityForm">Dedutibilidade</label>
                            <input aria-describedby="deductibilityFormHelp" class="form-control form-valid-control" id="deductibilityForm" data-${this.controllerName}-target="deductibility" data-action="blur->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask" placeholder="Dedutibilidade" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome da Conta</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="chartName" placeholder="Nome da Conta" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Grupo de Conta</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="groupDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-${this.controllerName}-target="groupDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="groupInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="groupList">
                                  <li data-group="legal_income" class="li-selector dark">Rendimento Tributável PJ</li>
                                  <li data-group="individual_income" class="li-selector dark">Rendimento Tributável PF</li>
                                  <li data-group="exempt_income" class="li-selector dark">Rendimento Isento</li>
                                  <li data-group="definitive_income" class="li-selector dark">Rendimento Com Tributação Exclusiva</li>
                                  <li data-group="booking" class="li-selector dark">Livro-Caixa</li>
                                  <li data-group="pension" class="li-selector dark">Previdência Privada</li>
                                  <li data-group="health" class="li-selector dark">Saúde</li>
                                  <li data-group="education" class="li-selector dark">Educação</li>
                                  <li data-group="withheld_tax" class="li-selector dark">Imposto de Renda Retido na Fonte</li>
                                  <li data-group="paid_tax" class="li-selector dark">Imposto Pago</li>
                                  <li data-group="withheld_social" class="li-selector dark">Previdência Social Retida na Fonte</li>
                                  <li data-group="paid_social" class="li-selector dark">Previdência Paga</li>
                                  <li data-group="cash_assets" class="li-selector dark">Ativos Líquidos/Investimentos</li>
                                  <li data-group="property_assets" class="li-selector dark">Ativos Imobilizados/Bens</li>
                                  <li data-group="others" class="li-selector dark">Outros</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.saveCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.refreshSaveBtn()
      if (controller.actionMode == "edit") {
        controller.titleCardTarget.innerText = `Editando Conta`
        controller.codeTarget.value = controller.chart_account.code
        controller.chartNameTarget.value = controller.chart_account.name
        controller.deductibilityTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(controller.chart_account.deductibility * 100).toString())
        controller.groupDropdownTarget.value = controller.chart_account.group
        controller.groupInputTarget.innerText = controller.chart_account.group_pretty
      } else if (controller.actionMode == "new") {
        controller.titleCardTarget.innerText = `Nova Conta`
        controller.deductibilityTarget.value = `0,00 %`
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      selector()

    })
  }

  doChartAccountListHtml() {
    if (this.application.medreturn_chart_accounts.can_create) {
      var addBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#newChartAccount" type="button">
                          <span class="material-icons">add</span>
                        </button>
                        <span class="mc-tooltiptext">Adicionar Conta</span>`
    } else {
      var addBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Plano de Contas</h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${addBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="cardBody" style="overflow: auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">Código</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle">Conta</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle">Grupo</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">% Dedução</th>
                                <th style="font-size:80%;" scope="col" class="table-3"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.doDataTable()
    })
  }

  doDataTable() {
    var chartAccounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.chart_accounts, "code")
    if (chartAccounts.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(chartAccounts.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (chartAccounts[x] !== undefined) {
            internPage[internPage.length] = chartAccounts[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: chartAccounts.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="7" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contas Lançadas</span>
                      </td>
                    </tr>`
      
      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.chartAccountTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  chartAccountTablePartial(element, length) {

    if (this.application.medreturn_chart_accounts.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.medreturn_chart_accounts.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editChartAccount" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.code}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${element.code}">${copyPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.group_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(element.deductibility * 100).toString())}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.codeTarget.value == "") {
        len += 1
      }
      if (controller.deductibilityTarget.value == "") {
        len += 1
      }
      if (controller.chartNameTarget.value == "") {
        len += 1
      }
      if (controller.groupInputTarget.innerText == "") {
        len += 1
      }
      if (controller.hasSaveBtnTarget) {
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200); 
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
