import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "contactsCard", "product", "contacts", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--entities--contacts`
    this.doClinicCard()
  }

  doClinicCard() {
    var address = this.application.clinic.address_list[0]
    var phone = this.application.clinic.phone_list[0]

    var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editContacts" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Pedir para Editar</span>
                  </button>`

    if (this.application.clinic.address_list[0]) {
      var address = this.application.clinic.address_list[0]
      var addressHtml = `<th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="f-065 px-0 align-middle pb-0">
                            <strong>Endereço</strong> <br>
                          </th>
                          <td colspan="5" class="f-065 align-middle px-0 pb-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                            ${address.street}, ${address.number}, ${address.complement}
                          </td>
                        </tr>
                        <tr>
                          <th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="px-0 align-middle"></th>
                          <td colspan="5" class="f-065 align-middle p-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                            ${address.district},
                          </td>
                        </tr>
                        <tr>
                          <th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="px-0 align-middle"></th>
                          <td colspan="5" class="f-065 align-middle p-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                            ${address.city}/${address.state}
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                          </td>
                        </tr>`
    } else {
      var address = { street: `Não possui Endereço` }
      var addressHtml = `<th colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="f-065 px-0 align-middle pb-0">
                            <strong>Endereço</strong> <br>
                          </th>
                          <td colspan="5" class="f-065 align-middle px-0 pb-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                            ${address.street}
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                          </td>
                        </tr>`
    }

    if (this.application.clinic.phone_list[0]) {
      var phone = this.application.clinic.phone_list[0]
    } else {
      var phone = { uniq_number: `Não possui Telefone`, phone_number: `Não possui Telefone` }
    }

    if (this.application.clinic.email_list[0]) {
      var email = this.application.clinic.email_list[0]
    } else {
      var email = { address: `Não possui E-mail` }
    }

    var html = `<div class="card" data-${this.controllerName}-target="contactsCard">
                        <div class="card-header py-0">
                          <div class="row my-2"><div class="col f-bold">Contatos da PJ Médica</div></div>  
                        </div>
                        <div class="card-body py-0 px-2" style="overflow:auto;">
                          <div class="col-12 px-2">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                
                              </tr>
                              <tr>
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>Telefone</strong>
                                </th>
                                <td colspan="5" class="f-065 align-middle px-0 text-left">
                                  <span class="mb-0 f-065 mc-tooltip grab">
                                    <span data-controller="app--helpers--copy" data-copy="${phone.uniq_number}" data-action="click->app--helpers--copy#copy">${phone.phone_number}</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                  </span>
                                </td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                </td>
                              </tr>
                              <tr>
                                <th colspan="3" class="f-065 align-middle px-0">
                                  <strong>Email</strong>
                                </th>
                                <td colspan="5" class="f-065 align-middle px-0 text-left">
                                  <span class="mb-0 f-065 mc-tooltip grab">
                                    <span data-controller="app--helpers--copy" data-copy="${email.address}" data-action="click->app--helpers--copy#copy">${email.address}</span>
                                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                  </span>
                                </td>
                                <td colspan="1" class="align-middle text-center">
                                  ${edit}
                                </td>
                              </tr>
                              <tr>
                                ${addressHtml}
                              </tr>

                            </tbody>
                          </table>
                        </div>
                        </div>
                      </div>`

    // this.contactsTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--dashboard`).contactsTarget.innerHTML = html)
    }).then(() => {
      controller.contactsCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  editContacts() {
    alert(`Pedir para o CS atualizar os contatos na página Contas Empresas!`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}