import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loader", "messageBody", "messageInput", "nameInput", "cpfInput", "cepInput"]

  connect() {
    this.controllerName = `shared--intelligence--main`
    this.welcome()
  }

  welcome() {
    this.preLoader()
    var speak = `<div class="row"><div class="col"><h6>Olá, eu sou a Med, a sua assistente virtual na MedCapital e vou te ajudar no processo.</h6></div></div>`
    var controller = this
    setTimeout(function () {
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
      controller.preLoader()
      setTimeout(function () {
        var speak = `<div class="row"><div class="col"><p>Para começarmos, me diga qual o seu nome?</p></div></div>`
        controller.loaderTarget.remove()
        controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
        controller.nameInput()
        controller.rollMessages()
      }, 1000)
    }, 1000)
    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

  nameInput() {
    var html = `<div class="col-10 text-left">
                  <input type="text" class="form-control s-title-0p85rem" placeholder="Digite seu nome ..." aria-label="Digite seu nome ..." data-${this.controllerName}-target="nameInput" data-action="keyup->${this.controllerName}#saveName">
                  <span data-users--tasks--board--comment-target="characterCount"></span>
                </div>
                <div class="col-1 ">
                  <span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Enviar" data-action="click->${this.controllerName}#saveName">send</span>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.messageInputTarget.innerHTML = html)
    }).then(() => {
      tooltip()
      controller.nameInputTarget.focus()
    }) 
  }

  saveName(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.nameInputTarget.value = ""
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      this.setMessageData("name", this.nameInputTarget.value.trim())
      const userInput = `<div class="row my-2 br-primary"><div class="col text-right"><p>${this.getMessageData("name")}</p></div></div>`
      this.messageBodyTarget.insertAdjacentHTML("beforeend", userInput)
      this.preLoader()
      var controller = this
      setTimeout(function () {
        // var speak = `<div class="row"><div class="col"><p>Obrigada ${controller.getMessageData("name").split(" ")[0]}!</p></div></div>`
        // controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
        controller.loaderTarget.remove()
        controller.preLoader()
        controller.rollMessages()
        setTimeout(function () {
          var speak = `<div class="row"><div class="col"><p>Qual o seu CPF?</p></div></div>`
          controller.loaderTarget.remove()
          controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
          controller.cpfInput()
          controller.rollMessages()
        }, 1000)
      }, 1000)
    }
    
  }

  cpfInput() {
    var html = `<div class="col-10 text-left">
                  <input type="text" class="form-control s-title-0p85rem" placeholder="Digite seu CPF ..." aria-label="Digite seu CPF ..." data-${this.controllerName}-target="cpfInput" id="cpfInput" data-action="keyup->${this.controllerName}#saveCpf">
                  <span data-users--tasks--board--comment-target="characterCount"></span>
                </div>
                <div class="col-1 ">
                  <span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Enviar" data-action="click->${this.controllerName}#saveCpf">send</span>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.messageInputTarget.innerHTML = html)
    }).then(() => {
      tooltip()
      controller.cpfInputTarget.focus()
      changeNumberToCpf($("#cpfInput"))
    })
  }

  saveCpf(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.cpfInputTarget.value = ""
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      this.setMessageData("cpf", this.cpfInputTarget.value.trim())
      const userInput = `<div class="row my-2"><div class="col text-right"><p>${this.getMessageData("cpf")}</p></div></div>`
      this.messageBodyTarget.insertAdjacentHTML("beforeend", userInput)
      this.preLoader()
      var controller = this
      setTimeout(function () {
        // var speak = `<div class="row"><div class="col"><p>Obrigada ${controller.getMessageData("name").split(" ")[0]}!</p></div></div>`
        // controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
        controller.loaderTarget.remove()
        controller.preLoader()
        controller.rollMessages()
        setTimeout(function () {
          var speak = `<div class="row"><div class="col"><p>Qual o seu CEP?</p></div></div>`
          controller.loaderTarget.remove()
          controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
          controller.cepInput()
          controller.rollMessages()
        }, 1000)
      }, 1000)
    }
  }

  cepInput() {
    var html = `<div class="col-10 text-left">
                  <input type="text" class="form-control s-title-0p85rem" placeholder="Digite seu CEP ..." aria-label="Digite seu CEP ..." data-${this.controllerName}-target="cepInput" id="cepInput" data-action="keyup->${this.controllerName}#saveCep">
                  <span data-users--tasks--board--comment-target="characterCount"></span>
                </div>
                <div class="col-1 ">
                  <span class="material-icons md-150 pointer" data-toggle="tooltip" data-placement="top" title data-original-title="Enviar" data-action="click->${this.controllerName}#saveCep">send</span>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.messageInputTarget.innerHTML = html)
    }).then(() => {
      tooltip()
      controller.cepInputTarget.focus()
      changeNumberToCep($("#cepInput"))
    })
  }

  saveCep(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.cepInputTarget.value = ""
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      this.setMessageData("cep", this.cepInputTarget.value.trim())
      const userInput = `<div class="row my-2"><div class="col text-right"><p>${this.getMessageData("cep")}</p></div></div>`
      this.messageBodyTarget.insertAdjacentHTML("beforeend", userInput)
      this.preLoader()
      this.rollMessages()
      var controller = this
      setTimeout(function () {
        // var speak = `<div class="row"><div class="col"><p>Obrigada ${controller.getMessageData("name").split(" ")[0]}!</p></div></div>`
        // controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
        controller.loaderTarget.remove()
        controller.preLoader()
        controller.rollMessages()
        setTimeout(function () {
          var speak = `<div class="row"><div class="col"><p>Estou buscando o seu endereço, aguarde só um momento, por favor.</p></div></div>`
          controller.loaderTarget.remove()
          controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
          controller.preLoader()
          controller.rollMessages()
        }, 1000)
        // setTimeout(function () {
        //   var speak = `<div class="row"><div class="col"><p>Estou buscando o seu endereço, aguarde só um momento, por favor.</p></div></div>`
        //   controller.loaderTarget.remove()
        //   controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
        // }, 1000)
      }, 1000)
    }
  }

  preLoader() {
    const rowHtml = `<div class='card timeline-item my-2' data-${this.controllerName}-target="loader">
                        <div class='animated-background animated-background-20'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>`

    this.messageBodyTarget.insertAdjacentHTML("beforeend", rowHtml)
  }

  rollMessages() {
    $("#messageBody").stop().animate({ scrollTop: $("#messageBody")[0].scrollHeight }, 1000);
  }

  getMessageData(data) {
    return $("#messageBody").data(data)
  }

  getMessageAllData() {
    return $("#messageBody").data()
  }

  setMessageData(data, value) {
    return $("#messageBody").data(data, value)
  }

}
