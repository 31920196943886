import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--visits--chats--dashboard`
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-4">
                  <div class="col-4 px-2" data-controller="commercial--marketing--visits--chats--index" data-${this.controllerName}-target="list"></div>
                  <div class="col-8 px-2" data-controller="commercial--marketing--visits--chats--show">
                    <div class="row" data-${this.controllerName}-target="view"></div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
