import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "notifyInput", "passwordInput", "expirationInput", "pixInput", "saveBtn",
                    "fileDiv", "labelUpload", "fileName", "fileUpload",
                    "progressUpload", "progressUploadBar", "progressUploadCounter"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--certificates--save`
    this.upload_files = []
    // this.filesPermittedTypes = ["pdf", "png", "jpeg", "DEC", "REC", ".DEC", ".REC", "PDF", "PNG", "JPEG", "pfx", ".pfx"]
    // this.filesPermittedTypes = ["pfx", ".pfx"]
    this.filesPermittedTypes = ["pfx", ".pfx", ".x-pkcs12", "x-pkcs12", "p12", ".p12"]
    this.uploadReady = false
    this.uploadFile = false
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--index").clearList()
  }

  saveCertificate() {
    if (this.uploadFile) {
      this.progressCount(0)
    }

    this.send_data = { current_user: {}, certificate: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.certificate.id = this.current_certificate.id
    }
    this.send_data.certificate.clinic_id = this.application.clinic.id
    this.send_data.certificate.status = this.statusInputTarget.dataset.status
    this.send_data.certificate.kind = this.kindInputTarget.dataset.kind
    this.send_data.certificate.name = this.nameInputTarget.value.trim()
    this.send_data.certificate.notify_with = this.notifyInputTarget.value.trim()
    this.send_data.certificate.certificate_pass = this.passwordInputTarget.value.trim()
    this.send_data.certificate.expiration_date = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.expirationInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/clinics/regularizations/certificates/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/clinics/regularizations/certificates/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var certificate = response.data.cln
          if (controller.uploadFile) {
            controller.requestUpload(certificate.id, this.upload_files)
          } else {
            controller.stopRefreshing()
            if (this.actionMode == `new`) {
              controller.application.certificates[controller.application.certificates.length] = certificate
            } else if (this.actionMode == `edit`) {
              controller.application.certificates.forEach((element, i) => {
                if (element.id == certificate.id) {
                  controller.application.certificates.splice(controller.application.certificates.indexOf(element), 1, certificate)
                }
              })
            }
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--index").doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestUpload(certificate_id, files) {
    var i = 1
    files.forEach(element => {
      var formData = new FormData()
      formData.append('file', element.file)
      formData.append('certificate_id', certificate_id)
      formData.append('current_user_id', this.application.current_user.id)
      formData.append('feature', this.permission)

      var url = "/operations/products/clinics/regularizations/certificates/save_upload"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var certificate = response.data.cln
            if (this.actionMode == `new`) {
              controller.application.certificates[controller.application.certificates.length] = certificate
            } else if (this.actionMode == `edit`) {
              controller.application.certificates.forEach((element, i) => {
                if (element.id == certificate.id) {
                  controller.application.certificates.splice(controller.application.certificates.indexOf(element), 1, certificate)
                }
              })
            }
            if (i == files.length) {
            }
            controller.stopRefreshing()
            controller.progressCount(100)
            controller.uploadSwitch(false)
            i += 1
            controller.cancelSave()
            controller.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--index").doDataTable()
          }
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    })
  }

  doFormHtml() {
    this.stopRefreshing()
    
    if (this.application.permissions[this.permission].can_create && this.current_certificate.status == `active`) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCertificate" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Certificado</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Certificado" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="expirationForm">Vencimento</label>
                            <input aria-describedby="expirationFormHelp" class="form-control form-valid-control" id="expirationForm" data-${this.controllerName}-target="expirationInput" placeholder="Vencimento" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notifyForm">Avisar com [dias]</label>
                            <input aria-describedby="notifyFormHelp" class="form-control form-valid-control" id="notifyForm" data-${this.controllerName}-target="notifyInput" placeholder="Avisar com [dias]" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="passwordForm">Senha</label>
                            <input aria-describedby="passwordFormHelp" class="form-control form-valid-control" id="passwordForm" data-${this.controllerName}-target="passwordInput" placeholder="Senha" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                  <li data-status="overdue" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vencido</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="a1_digital" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">A1 Digital</li>
                                  <li data-kind="a3_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">A3 Cartão</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center rowFile" data-${this.controllerName}-target="fileDiv">
                      <div class="col-6">
                        <span class="f-065">Arquivo Certificado Digital</span>
                        <label class="drop-area copy" for="fileDropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">https</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="fileDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-6 px-0">
                        <span data-${this.controllerName}-target="fileName"></span>
                      </div>
                    </div>
                    <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
                      <div class="col-12">
                        <div class="form-group my-0 text-center w-100">
                          <h7 class="progress">
                            <span class="progress_count"></span>
                          </h7>
                          <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                          </div>
                          <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                        </div>
                      </div>
                    </div>
                    
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pickNormal($(this.expirationInputTarget), { max: true, months: true, years: 60 })
      
      
      if (controller.current_certificate.has_file) {
        controller.fileNameTarget.innerText = controller.current_certificate.file_name
      }

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Certificado Digital`
        controller.notifyInputTarget.value = 30

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `active`
        controller.statusInputTarget.innerText = `Ativo`
        controller.statusInputTarget.dataset.status = `active`
        controller.refreshSaveBtn()

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Certificado Digital`

        controller.nameInputTarget.value = controller.current_certificate.name
        controller.expirationInputTarget.value = controller.current_certificate.expiration_date_pretty
        controller.passwordInputTarget.value = controller.current_certificate.certificate_pass
        controller.notifyInputTarget.value = controller.current_certificate.notify_with
        
        controller.statusDropdownTarget.value = controller.current_certificate.status
        controller.statusInputTarget.innerText = controller.current_certificate.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_certificate.status
        
        controller.kindDropdownTarget.value = controller.current_certificate.kind
        controller.kindInputTarget.innerText = controller.current_certificate.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_certificate.kind
        
        controller.expirationInputTarget.disabled = true
        controller.statusDropdownBtnTarget.disabled = true
        controller.kindDropdownBtnTarget.disabled = true

        if (controller.current_certificate.status == `overdue`) {
          controller.nameInputTarget.disabled = true
          controller.expirationInputTarget.disabled = true
          controller.passwordInputTarget.disabled = true
          controller.notifyInputTarget.disabled = true
          controller.fileDivTarget.remove()
        } else if (controller.application.permissions[this.permission].can_upload) {
          controller.fileDivTarget.classList.remove(`d-none`)
          controller.fileUploadTarget.classList.remove(`d-none`)
          controller.refreshSaveBtn()
        } else {
          controller.refreshSaveBtn()
        }

        if (controller.application.permissions[this.permission].can_manage) {
          controller.passwordInputTarget.type = `text`
        } else {
          controller.passwordInputTarget.type = `password`
        }
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.type == "change") {
      if (ev.target.files && ev.target.files[0]) {
        this.file = ev.target.files[0]
        this.setUploadFile()
      }
    } else if (ev.type == "drop") {
      if (ev.dataTransfer.items) {
        if (ev.dataTransfer.items[0] && ev.dataTransfer.items[0].kind === 'file') {
          this.file = ev.dataTransfer.items[0].getAsFile()
          this.setUploadFile()
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  setUploadFile() {
    var element = this.file
    var obj = {}
    var date = new Date()
    obj.file_name = element.name
    obj.file_size = element.size
    obj.file_type = element.type
    obj.file_last_modified = element.lastModified
    // obj.field = ev.currentTarget.closest(".rowFile").dataset.field
    obj.uploaded = false
    obj.message_erro = ""
    obj.created_at_time = parseInt(date.getTime() / 1000)
    if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
      obj.can_upload = true
      obj.file = element
      this.uploadReady = true
      this.fileUploadTarget.classList.remove("d-none")
      this.fileNameTarget.innerText = obj.file_name

      this.upload_files[this.upload_files.length] = obj
      this.uploadFile = true
    } else {
      if ((element.size / 1000000).toFixed(2) > 5) {
        obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
      }

      if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
        obj.message_erro += "Formato do arquivo não é permitido."
      }
      obj.can_upload = false
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
    }
  }

  uploadSwitch(value) {
    if (this.hasUploadBtnTarget) {
      if (value) {
        this.uploadBtnTarget.classList.remove("bg-disabled")
        this.uploadBtnTarget.classList.add("pointer")
        this.uploadBtnTarget.classList.add("bg-primary")
        this.canUploadFile = true
      } else {
        this.uploadBtnTarget.classList.add("bg-disabled")
        this.uploadBtnTarget.classList.remove("pointer")
        this.uploadBtnTarget.classList.remove("bg-primary")
        this.canUploadFile = false
      }
    }
  }

  progressCount(value) {
    var controller = this
    if (this.hasProgressUploadBarTarget) {
      this.progressUploadBarTarget.style.width = value + `%`
    }
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        if (controller.hasProgressUploadBarTarget) {
          controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
          controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        }
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      if (this.hasProgressUploadCounterTarget) {
        this.progressUploadCounterTarget.innerText = value + `%`
      }
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.notifyInputTarget.value == ``) {
        len += 1
      }

      if (controller.passwordInputTarget.value == ``) {
        len += 1
      }

      if (controller.expirationInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.actionMode == "new" && controller.uploadFile == false) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

}
