import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["listPartners", "infoPartner", "infoHeaderPartner", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--transfers--partners`
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    
    if (this.getControllerByIdentifier("operations--products--clinics--financials--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--dashboard`)
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--transfers--dashboard`)
    }
  }

  doListHtml() {
    var html = ``

    var partners_apurations = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.current_main_apuration.partner_apurations, `partner_name`)

    html += `<div class="row mb-3 w-100">
               <div class="col-12 px-0">
                 <div class="card">
                   <div class="card-body py-2 px-2 f-bold bg-primary text-center">
                    <div class="card-show-dropdown">
                      <span class="pointer">${this.application.current_regime.tax_regime_pretty}</span>
                      <div class="card-show-dropdown-content text-left">
                        <span class="px-1 f-normal f-065 mb-3">${this.application.current_regime.sub_tax_regime_pretty}</span><br>
                        <span class="px-1 f-normal f-065 mb-3">${this.application.current_regime.special_tax_regime_pretty}</span>
                      </div>
                    </div>
                   </div>
                 </div>
               </div>
             </div>`

    partners_apurations.forEach(apuration => {

      if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
        html += this.doApurationCard(apuration)
      } else if (this.application.current_user.account_kind == `doctor`) {
        if (apuration.partner_id == this.application.current_partner.id) {
          html += this.doApurationCard(apuration)
        }
      } else if (this.application.current_user.account_kind == `helper`) {
        
      }
      

    })

    var listHtml = `<div class="card-body py-1 px-1" data-${this.controllerName}-target="listPartners" style="overflow:auto;">
                      ${html}
                    </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.partnersTarget.innerHTML = listHtml)
    }).then(() => {
      controller.listPartnersTarget.style.height = ($(window).height() * 0.6) + "px"
    })
  }

  doApurationCard(apuration) {
    var html = ``

    if (apuration.partner_administrative || apuration.partner_operational) {
      var labor = 0

      if (apuration.partner_administrative) {
        var administrativePartner = `Sócio Administrador`
      } else {
        var administrativePartner = ``
      }

      if (apuration.partner_operational) {
        var operationalPartner = `Sócio Operacional`
      } else {
        var operationalPartner = ``
      }


      if (this.application.current_regime.tax_regime == `presumed_profit`) {
        if (apuration.partner_administrative) {
          labor = Number(1212)
        }
      } else if (this.application.current_regime.tax_regime == `simple_national`) {
        if (apuration.partner_administrative || apuration.partner_operational) {
          if (this.application.current_regime.sub_tax_regime == `attachment_3`) {
            if (Number(0.28) * apuration.total_revenues < Number(1212)) {
              labor = Number(1212)
            } else {
              labor = Number(0.28) * apuration.total_revenues
            }
          } else if (this.application.current_regime.sub_tax_regime == `attachment_5`) {
            labor = Number(1212)
          }
        }
      }

      var distribution = 0

      distribution = Number(apuration.total_revenues) - Number(apuration.total_due) - Number(apuration.expenses) - Number(labor)

      if (distribution < 0) {
        distribution = 0
      }

      html += `<div class="row mb-2 w-100" data-${this.controllerName}-target="infoPartner-${apuration.partner_id} infoPartner">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header p-0" data-${this.controllerName}-target="infoHeaderPartner-${apuration.partner_id} infoHeaderPartner">
                        <div class="row py-1">
                          <div class="col-12 pointer f-bold px-1">
                            <div class="card-show-dropdown">
                              <span class="pointer">${apuration.partner_name}</span>
                              <div class="card-show-dropdown-content text-left">
                                <span class="px-1 f-normal f-065 mb-3">${operationalPartner}</span><br>
                                <span class="px-1 f-normal f-065 mb-3">${administrativePartner}</span><br>
                                <span class="px-1 f-normal f-065 mb-5"></span><br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body py-0 px-2">
                        <div class="row mb-2">
                          <div class="col-12 px-0 text-left">
                            <span class="f-075">Faturamento Individual</span>
                          </div>
                          <div class="col-12 px-0 text-left">
                            <span class="f-075 f-bold">${this.numberController.currencyOptionMask(apuration.total_revenues)}</span>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12 px-0 text-left">
                            <span class="f-075">Estimativa Pró-Labore</span>
                          </div>
                          <div class="col-12 px-0 text-left">
                            <div class="card-show-dropdown">
                              <span class="pointer"><span class="f-075 f-bold">${this.numberController.currencyOptionMask(labor)}</span></span>
                              <div class="card-show-dropdown-content text-left">
                                <span class="px-1 f-normal f-065 mb-3">Retenção IRRF: ${this.numberController.currencyOptionMask(this.calculateTaxes(labor))}</span><br>
                                <span class="px-1 f-normal f-065 mb-3">Retenção INSS: ${this.numberController.currencyOptionMask(this.calculateSocial(labor))}</span><br>
                                <span class="px-1 f-normal f-065 mb-5"></span><br>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12 px-0 text-left">
                            <span class="f-075">Estimativa Dividendos</span>
                          </div>
                          <div class="col-12 px-0 text-left">
                            <div class="card-show-dropdown">
                              <span class="pointer"><span class="f-075 f-bold">${this.numberController.currencyOptionMask(distribution)}</span></span>
                              <div class="card-show-dropdown-content text-left">
                                <span class="px-1 f-normal f-065 mb-3">(-) Despesas ${this.numberController.currencyOptionMask(apuration.expenses)}</span><br>
                                <span class="px-1 f-normal f-065 mb-3">(-) Impostos ${this.numberController.currencyOptionMask(apuration.total_due)}</span><br>
                                <span class="px-1 f-normal f-065 mb-3">(-) Pró-Labore ${this.numberController.currencyOptionMask(labor)}</span><br>
                                <span class="px-1 f-normal f-065 mb-5"></span><br>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>`
    }

    return html
  }

  calculateTaxes(base) {
    var due = 0

    if (base > Number(1903.98) && base <= Number(2826.65)) {
      due = Number(0.075 * base) - Number(142.80)
    } else if (base > Number(2826.65) && base <= Number(3751.05)) {
      due = Number(0.15 * base) - Number(354.80)
    } else if (base > Number(3751.05) && base <= Number(4664.68)) {
      due = Number(0.225 * base) - Number(636.13)
    } else if (base > Number(4664.68)) {
      due = Number(0.275 * base) - Number(869.36)
    } else if (base <= Number(1903.98)) {
      due = 0
    }

    return due
  }

  calculateSocial(base) {
    var due = 0
    var ceil = 7087.22

    if (this.application.current_regime.tax_regime == `presumed_profit`) {
      if (Number(base) < Number(ceil)) {
        due = Number(base * 0.11)
      } else {
        due = Number(ceil * 0.11)
      }

      // due += Number(base * 0.20)
    } else if (this.application.current_regime.tax_regime == `simple_national`) {
      if (Number(base) < Number(ceil)) {
        due = Number(base * 0.11)
      } else {
        due = Number(ceil * 0.11)
      }
    }

    return due
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
