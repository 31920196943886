import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["calculation", "receivables", "contracts", "xxxx", "xxxx", "xxxx", "7777", "8888", "9999"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `financials--books--clients--dashboard--compilation`
  }

  doCompilationCard() {

    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Contas à Receber ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.getControllerByIdentifier(`financials--books--clients--dashboard--main`).compilationTitleTarget.innerHTML = title
    }

    if (this.application.current_med.id) {
      this.application.current_date.receivable_calculations.forEach(calculation => {
        if (calculation.med_id == this.application.current_med.id) {
          this.calculation = calculation
        }
      })
    } else {
      this.calculation = this.application.current_date.receivable_compilation
    }

    var title = `<h6 class="mb-0 text-center pointer">Contas à Receber - ${this.application.current_date.name}</h6>`
    // this.getControllerByIdentifier(`financials--books--clients--dashboard--main`).compilationTitleTarget.innerHTML = title

    var html = `<div class="row w-100">
                  <div class="col-12 px-0">
                    <div class="row">
                      <div class="col-12 my-2 px-2 text-center">
                        <span class="f-075 f-bold">
                          Recebimentos - ${this.application.current_date.name}
                        </span>
                      </div>
                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Recebimentos</span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                Gerado
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_accrual_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                Conciliado
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_received_conciliated_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                Recebido
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_received_amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Total Recebido</span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                CC
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_received_status_paid_with_credit_card_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                Boleto
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_received_status_paid_with_bank_slip_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-5 text-left f-065 px-1">
                                PIX
                              </div>
                              <div class="col-7 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billings_received_status_paid_with_pix_amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span class="mc-tooltip help">
                              <span>Liquidação de Valores</span>
                              <span class="mc-tooltiptext">Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_status_executed_amount_free)}</span>
                            </span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                CC
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_status_executed_cc_amount_free)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Boleto
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_status_executed_bs_amount_free)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                PIX
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_status_executed_pix_amount_free)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Prazos</span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Competência
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_acrrual_status_inside_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Atrasado
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_acrrual_status_delay_amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Tipo</span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Produtos
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_revenue_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Reembolsos
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_refund_amount)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 my-2 px-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Cartão de Crédito</span>
                          </div>
                          <div class="card-body text-center px-1 py-2">
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Total
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_amount)}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center mb-1">
                              <div class="col-6 text-left f-065 px-1">
                                Líquido
                              </div>
                              <div class="col-6 text-right f-065 px-1">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_billing_date_amount_free)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 my-2 px-2 text-center">
                        <span class="f-075 f-bold">Receitas - ${this.application.current_date.name}</span>
                      </div>
                      <div class="col-6 my-2 px-2">
                        <div class="row">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-header p-1 text-center f-070">
                                <span class="mc-tooltip help">
                                  <span>Produtos/Serviços</span>
                                  <span class="mc-tooltiptext">Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_revenue_amount)}</span>
                                </span>
                              </div>
                              <div class="card-body text-center px-1 py-2">
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    PJ Médica
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medclinic_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Imposto de Renda
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medfiling_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Livro-Caixa
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medbooking_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Folha de Pagamento
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medpayroll_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Faturamento
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medbiling_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Gestão de Recebimento
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medreceivement_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Consultoria e Avulsos
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_medconsulting_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-3">
                                  
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    <strong>Total Receitas</strong>
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_revenue_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    <strong>Total Receitas Líquidas</strong>
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(Number(this.calculation.total_receivable_revenue_amount) - Number(this.calculation.total_receivable_discount_amount))}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    <strong>Total Receitas Fiscal</strong>
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_issued_invoice_amount)}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6 my-2 px-2">
                        <div class="row">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-header p-1 text-center f-070">
                                <span class="mc-tooltip help">
                                  <span>Descontos e Outras Reduções</span>
                                  <span class="mc-tooltiptext">Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_discount_amount)}</span>
                                </span>
                              </div>
                              <div class="card-body text-center px-1 py-2">
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Comercial
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_commercial_discount_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Retenção
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_retention_discount_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Erro Operacional
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_error_discount_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Financeiro
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_item_financial_discount_amount)}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="col-12 px-0 mt-3">
                            <div class="card">
                              <div class="card-header p-1 text-center f-070">
                                <span>Recorrência</span>
                              </div>
                              <div class="card-body text-center px-1 py-2">
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Mensal
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_interval_monthly_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Anual
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_interval_yearly_amount)}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center mb-1">
                                  <div class="col-6 text-left f-065 px-1">
                                    Avulso
                                  </div>
                                  <div class="col-6 text-right f-065 px-1">
                                    ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_receivable_interval_unique_amount)}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--clients--dashboard--main`).compilationBoardTarget.innerHTML = html
  }

  doCalculationPreloader() {

    var title = `<h6 class="mb-0 text-center pointer">${this.loader}</h6>`
    this.getControllerByIdentifier(`financials--books--clients--dashboard--main`).compilationTitleTarget.innerHTML = title

    var html = `<div class="row my-2 w-100">
                  <div class="col-12">
                    <div class="row my-2">
                      ${this.cardGridPreloader()}
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--clients--dashboard--main`).compilationBoardTarget.innerHTML = html
  }

  cardGridPreloader() {
    var html = `<div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    const data = { compilation: { date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_compilations` } }
    const url = "/financials/books/receivables/compilations/refresh"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var compilation = response.data.cln.compilation
          var calculations = response.data.cln.calculations

          controller.application.dates.forEach(date => {
            if (date.id == compilation.date_id) {
              date.receivable_calculations = calculations
              date.receivable_compilation = compilation
            }
          })

          if (controller.application.current_med.id) {
            controller.application.current_date.receivable_calculations.forEach(calculation => {
              if (calculation.med_id == controller.application.current_med.id) {
                controller.calculation = calculation
              }
            })
          } else {
            controller.calculation = controller.application.current_date.receivable_compilation
          }

          controller.doCompilationCard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
