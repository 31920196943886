import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxShow", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--payments--index`
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.doIndexListHtml()
  }

  addPayment() {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--save").cancelSave()
    if (this.application.current_date.open) {
      this.cleanCheckBox()
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").actionMode = "new"
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }
  }

  showPayment(ev) {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--save").stopRefreshing()
    this.checked_id = ev.target.closest(".itemRow").dataset.id

    var checkBoxElement = ``
    if (ev.target.tagName == "INPUT") {
      checkBoxElement = ev.target
    } else if (ev.target.tagName == "TD") {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          checkBoxElement = element
        }
      })

      if (checkBoxElement.checked) {
        checkBoxElement.checked = false
      } else {
        checkBoxElement.checked = true
      }
    }

    if (checkBoxElement.checked) {
      this.checkboxShowTargets.forEach(element => {
        if (checkBoxElement != element) {
          element.checked = false
        }
      })
      this.application.payments.forEach(element => {
        if (element.id == this.checked_id) {
          this.getControllerByIdentifier("operations--products--tax-returns--payments--show").current_payment = element
        }
      })
      this.getControllerByIdentifier("operations--products--tax-returns--payments--show").doPaymentViewHtml()
    } else {
      this.getControllerByIdentifier("operations--products--tax-returns--payments--show").doViewHtml()
    }
    this.checked_id = ``

  }

  editPayment(ev) {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--save").cancelSave()
    var mode = "edit"
    var payment = ``
    this.application.payments.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        payment = element
      }
    });

    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").saveFormHtml(mode, payment)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }
  }


  doIndexListHtml() {
    // `<div class="dropdown">
    //   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
    //   <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
    // </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Pagamentos e Deduções ${this.application.current_date.year}</h6>
                    <input class="form-control f-070 w-40 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#addPayment" type="button">
                        <span class="material-icons">add</span>
                      </button>
                      <span class="mc-tooltiptext">Adicionar Pagamento</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th class="table-3"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Data</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">Descrição</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">ID</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Valor</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_payments = []
    this.application.payments.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_payments[current_payments.length] = element
      }
    });
    var payments = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_payments, "date")

    if (payments.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(payments.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (payments[x] !== undefined) {
            internPage[internPage.length] = payments[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: payments.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Pagamentos na Competência</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.paymentTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  paymentTablePartial(element, length) {
    if (this.application.medreturn_payments.can_select) {
      var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showPayment">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`
    } else {
      var check = ''
    }


    if (this.application.medreturn_payments.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.medreturn_payments.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editPayment" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td class="table-3 align-middle">${check}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_group_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${element.id_name}">${copyPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.id_number_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${element.id_number}">${copyPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.member_kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(element.amount)}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--save").stopRefreshing()
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
