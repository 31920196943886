import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "featureDropdown", "featureDropdownBtn", "featureInput", "featureList",
                    "accountDropdown", "accountDropdownBtn", "accountInput", "accountList",
                    "pathInput", "humanizePathInput", "nameInput", "notesInput", "saveBtn", "accountKindCheckbox"]


  connect() {
    this.controllerName = `users--works--permissions--paths--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("users--works--permissions--paths--index").clearList()
  }

  saveFeature() {
    this.send_data = { current_user: {}, path: {} }
    // this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    // this.saveBtnTarget.disabled = true
    // this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.path.id = this.current_path.id
    }
    this.send_data.path.feature_id = this.featureInputTarget.dataset.featureId
    this.send_data.path.feature_name = this.featureInputTarget.dataset.featureName
    this.send_data.path.account_kind = this.accountInputTarget.dataset.accountKind
    this.send_data.path.path = this.pathInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_permissions_paths`

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    
    if (this.actionMode == `new`) {
      var url = "/users/permissions/paths/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/permissions/paths/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/permissions/paths/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var path = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.paths[controller.application.paths.length] = path
          } else if (this.actionMode == `edit`) {
            controller.application.paths.forEach((element, i) => {
              if (element.id == path.id) {
                controller.application.paths.splice(controller.application.paths.indexOf(element), 1, path)
              }
            })
          } else if (this.actionMode == `delete`) {
            controller.application.paths.forEach((element, i) => {
              if (element.id == path.id) {
                controller.application.paths.splice(controller.application.paths.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveFeature" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">

                    <div class="row my-2 d-flex align-items-center" data-${this.controllerName}-target="addPolicyDiv">
                      <div class="col-12 mb-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Funcionalidade</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="featureDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="featureDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="featureInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="featureList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo de Conta</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="accountDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="accountDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="accountInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="accountList" data-app--helpers--search-target="searchList">
                                  <li data-account-kind="admin" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Administrador</li>
                                  <li data-account-kind="team" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Time</li>
                                  <li data-account-kind="doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Médico</li>
                                  <li data-account-kind="helper" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assistente</li>
                                  <li data-account-kind="patient" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Paciente</li>
                                  <li data-account-kind="taker" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Tomador</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="pathForm">URL</label>
                            <input aria-describedby="pathFormHelp" class="form-control form-valid-control" id="pathForm" data-${this.controllerName}-target="pathInput" placeholder="URL" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--paths--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova URL`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando URL`

        controller.accountDropdownTarget.value = controller.current_path.account_kind
        controller.accountInputTarget.dataset.accountKind = controller.current_path.account_kind
        controller.accountInputTarget.innerText = controller.current_path.account_kind_pretty
        
        controller.featureDropdownTarget.value = controller.current_path.feature_id
        controller.featureInputTarget.dataset.featureId = controller.current_path.feature_id
        controller.featureInputTarget.innerText = controller.current_path.feature_humanize

        controller.pathInputTarget.value = controller.current_path.path
      }

      controller.setFeatures()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  setFeatures() {
    var html = ``

    this.application.features.forEach(feature => {
      html += `<li data-feature-id="${feature.id}" data-feature-name="${feature.name}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setDataset" data-app--helpers--selector-target="select" class="li-selector dark f-065">${feature.humanize} | ${feature.name}</li>`
    });

    this.featureListTarget.innerHTML = html
  }

  setDataset(ev) {
    this.featureInputTarget.dataset.featureId = ev.currentTarget.dataset.featureId
    this.featureInputTarget.dataset.featureName = ev.currentTarget.dataset.featureName
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.featureInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.accountInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.pathInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
