import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "name", "title", "path", "contentDownloadDropdown", "contentDownloadInput", "contentList",
                    "formatDownloadDropdown", "formatDownloadInput", "formatList", "sharingDownloadDropdown",
                    "sharingDownloadInput", "sharingList", "description", "copy", "notes", "saveBtn", "input",
    "output", "validGroup"]

  connect() {
    // this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    this.controllerName = `marketing--downloads--entities--modal`
  }

  saveDownload() {
    if (this.validateSelectors().valid) {
      this.stopRefreshing()
      this.saveBtnTarget.disabled = true
      if (this.hasDeleteBtnTarget) {
        this.deleteBtnTarget.disabled = true
      }
      var data = {
                  download: {
                              id: this.element.dataset.id,
                              name: this.nameTarget.value,
                              title: this.titleTarget.value,
                              path: this.pathTarget.value,
                              description: this.descriptionTarget.value,
                              copy: this.copyTarget.value,
                              content: this.contentDownloadInputTarget.dataset.content,
                              format: this.formatDownloadInputTarget.dataset.format,
                              sharing: this.sharingDownloadInputTarget.dataset.sharing,
                              notes: this.notesTarget.value
                            },
                  current_user: { current_user_id: currentUser.id }
      }

      this.requestSave(data)
    } else {
      alert(this.validateSelectors().error)
    }
  }

  deleteDownload() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    if (this.hasDeleteBtnTarget) {
      this.deleteBtnTarget.disabled = true
    }
    var data = { download: { id: this.element.dataset.id, active: false }, current_user: { current_user_id: currentUser.id } }
    this.requestSave(data)
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    if (data.download.id) {
      var url = "/marketing/downloads/entities/update"
      var method = "PUT"
    } else {
      var url = "/marketing/downloads/entities/create"
      var method = "POST"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var download = response.data.cln
          this.close()
          if (this.getControllerByIdentifier("marketing--downloads--entities--index").nameTarget("viewDownloadBody")) {
            this.getControllerByIdentifier("marketing--downloads--entities--index").nameTarget("viewDownloadBody").remove()
            this.getControllerByIdentifier("marketing--downloads--entities--index").nameTarget("viewTitle").innerText = "Nenhum Download Selecionado"
          }
          if (data.download.id) {
            if (download.active) {
              this.application.downloads.forEach((element, i) => {
                if (element.id == download.id) {
                  this.application.downloads.splice(this.application.downloads.indexOf(element), 1, download)
                }
              })
            } else {
              this.application.downloads.forEach((element, i) => {
                if (element.id == download.id) {
                  this.application.downloads.splice(this.application.downloads.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.downloads[this.application.downloads.length] = download
          }
          this.getControllerByIdentifier("marketing--downloads--entities--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        processingSnackbar(response.type, response.message, device)
      })
  }
  

  modalHtml(mode, downloadId) {

    if (this.application.download_entities.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary" data-action="click->${this.controllerName}#saveDownload" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.download_entities.can_delete && mode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger" data-action="click->${this.controllerName}#deleteDownload" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="modal fade" data-controller="${this.controllerName}" data-${this.controllerName}-target="modal" id="downloadModal" tabindex="-1" role="dialog" aria-labelledby="downloadModalTitle" aria-hidden="true" data-id="${downloadId}" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom">
                        <h6 class="modal-title"><strong>Novo Download</strong></h6><br>
                        <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body p-3" data-${this.controllerName}-target="body">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                              <div class="floating-label floating-label-sm">
                                <label for="nameDownloadForm">Nome</label>
                                <input aria-describedby="nameDownloadFormHelp" class="form-control form-valid-control" id="nameDownloadForm" data-${this.controllerName}-target="name" data-action="blur->${this.controllerName}#validateField focus->${this.controllerName}#cleanValidations" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                              <div class="floating-label floating-label-sm">
                                <label for="titleDownloadForm">Título</label>
                                <input aria-describedby="titleDownloadFormHelp" class="form-control form-valid-control" id="titleDownloadForm" data-action="keydown->${this.controllerName}#removeValidation keyup->${this.controllerName}#bindInput blur->${this.controllerName}#validateField focus->${this.controllerName}#cleanValidations" data-${this.controllerName}-target="title output" placeholder="Título" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                              <div class="input-group input-group-sm">
                                <div class="floating-label floating-label-sm">
                                  <label for="pathDownloadForm">URL para Download</label>
                                  <input readonly aria-describedby="pathDownloadFormHelp" class="form-control form-valid-control" id="pathDownloadForm" data-${this.controllerName}-target="path input" data-action="blur->${this.controllerName}#validateField focus->${this.controllerName}#cleanValidations" placeholder="URL para Download" type="text" required>
                                </div>
                                <span class="input-group-icon mr-2">/</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionDownloadForm">Descrição</label>
                                <textarea aria-describedby="descriptionDownloadFormHelp" class="form-control textarea form-valid-control" id="descriptionDownloadForm" placeholder="Descrição" type="text" required rows="3" data-${this.controllerName}-target="description" data-action="blur->${this.controllerName}#validateField focus->${this.controllerName}#cleanValidations"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                              <div class="floating-label floating-label-sm">
                                <label for="copyDownloadForm">Copy</label>
                                <textarea aria-describedby="copyDownloadFormHelp" class="form-control textarea form-valid-control" id="copyDownloadForm" placeholder="Copy" type="text" required rows="3" data-${this.controllerName}-target="copy" data-action="blur->${this.controllerName}#validateField focus->${this.controllerName}#cleanValidations"></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Conteúdo</label>
                                <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="contentDownloadDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="contentDownloadInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="contentList">
                                      <li data-content="clinic" class="li-selector dark">PJ Médica</li>
                                      <li data-content="booking" class="li-selector dark">Livro-Caixa</li>
                                      <li data-content="taxes" class="li-selector dark">Imposto de Renda</li>
                                      <li data-content="billing" class="li-selector dark">Recebimento</li>
                                      <li data-content="insurance" class="li-selector dark">Seguros</li>
                                      <li data-content="education" class="li-selector dark">Educação</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Formato</label>
                                <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="formatDownloadDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="formatDownloadInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="formatList">
                                      <li data-format="ebook" class="li-selector dark">E-Book</li>
                                      <li data-format="info" class="li-selector dark">Infográfico</li>
                                      <li data-format="handbook" class="li-selector dark">Cartilha</li>
                                      <li data-format="paper" class="li-selector dark">Paper</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Compartilhamento</label>
                                <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="sharingDownloadDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="sharingDownloadInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="sharingList">
                                      <li data-sharing="public" class="li-selector dark">Público</li>
                                      <li data-sharing="client" class="li-selector dark">Clientes</li>
                                      <li data-sharing="intern" class="li-selector dark">Interno</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesDownloadForm">Anotações</label>
                                <input aria-describedby="notesDownloadFormHelp" class="form-control" id="notesDownloadForm" data-${this.controllerName}-target="notes" placeholder="Anotações" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>
                        ${deleteBtnHtml}
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve($('body').append(html))
    }).then(() => {
      this.application.getControllerForElementAndIdentifier(document.querySelector('#downloadModal'), "marketing--downloads--entities--modal").open()
      this.application.getControllerForElementAndIdentifier(document.querySelector('#downloadModal'), "marketing--downloads--entities--modal").refreshSaveBtn()
      this.application.getControllerForElementAndIdentifier(document.querySelector('#downloadModal'), "marketing--downloads--entities--modal").dataValidation(downloadId)
      if (mode == "edit") {
        this.application.getControllerForElementAndIdentifier(document.querySelector('#downloadModal'), "marketing--downloads--entities--modal").fetchDownload(downloadId)
      }
      changeStringToPath($('#pathDownloadForm'))
      selector()
    })
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    floatingLabel();
    selector()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  removeValidation() {

  }

  bindInput() {
    var value = this.outputTarget.value
    if (value.length >= 1) {
      this.inputTarget.focus()
      this.inputTarget.value = fromStringToPath(value)
      this.inputTarget.closest('.floating-label').classList.add("has-value")
      this.inputTarget.closest('.floating-label').classList.add("is-focused")
      this.inputTarget.blur()
      this.outputTarget.focus()
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.getControllerByIdentifier("app--helpers--strings").fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  fetchDownload(downloadId) {
    var download = ``
    this.application.downloads.forEach(element => {
      if (element.id == downloadId) {
        download = element
      }
    });


    this.nameTarget.value = download.name
    this.titleTarget.value = download.title
    this.pathTarget.value = download.path
    this.descriptionTarget.value = download.description
    this.copyTarget.value = download.copy
    this.notesTarget.value = download.notes
    this.contentDownloadDropdownTarget.value = download.content
    this.contentDownloadInputTarget.innerText = download.content_pretty
    this.formatDownloadDropdownTarget.value = download.format
    this.formatDownloadInputTarget.innerText = download.format_pretty
    this.sharingDownloadDropdownTarget.value = download.sharing
    this.sharingDownloadInputTarget.innerText = download.sharing_pretty

    this.validGroupTargets.forEach(element => {
      element.dataset.valid = true
    });

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  dataValidation(downloadId) {
    this.nameTarget.dataset.objColumn = "name"
    this.nameTarget.dataset.filterColumn = "id"
    this.nameTarget.dataset.filterValue = downloadId
    this.titleTarget.dataset.objColumn = "title"
    this.titleTarget.dataset.filterColumn = "id"
    this.titleTarget.dataset.filterValue = downloadId
    this.pathTarget.dataset.objColumn = "path"
    this.pathTarget.dataset.filterColumn = "id"
    this.pathTarget.dataset.filterValue = downloadId
    this.descriptionTarget.dataset.objColumn = "description"
    this.descriptionTarget.dataset.filterColumn = "id"
    this.descriptionTarget.dataset.filterValue = downloadId
    this.copyTarget.dataset.objColumn = "copy"
    this.copyTarget.dataset.filterColumn = "id"
    this.copyTarget.dataset.filterValue = downloadId 
  }

  cleanValidations(ev) {
    ev.target.classList.remove("invalid-field")
    if (ev.target.nextElementSibling) {
      ev.target.nextElementSibling.remove()
    }
  }

  validateField(ev) {
    var data = {
                  validations: {
                                  obj_column: ev.target.dataset.objColumn,
                                  obj_value: ev.target.value,
                                  filter_column: ev.target.dataset.filterColumn,
                                  filter_value: ev.target.dataset.filterValue,
                                }
                }


    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/marketing/downloads/entities/validate_field"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.valid) {
          ev.target.closest(".form-valid-group").dataset.valid = true
        } else {
          ev.target.closest(".form-valid-group").dataset.valid = false    
          ev.target.classList.add("invalid-field")
          const html = `<div class="invalid-warning my-1 py-1" data-${this.controllerName}-target="invalid"></div>`
          ev.target.parentElement.insertAdjacentHTML("beforeend", html);
          response.messages.forEach(message => {
            ev.target.nextElementSibling.insertAdjacentHTML("beforeend", message);
          });
        }
      })
  }

  validateSelectors() {
    if (this.contentDownloadInputTarget.innerText == "") {
      return {valid: false, error: "Conteúdo não pode ficar em branco"}
    } else if (this.formatDownloadInputTarget.innerText == "") {
      return {valid: false, error: "Formato não pode ficar em branco"}
    } else if (this.sharingDownloadInputTarget.innerText == "") {
      return {valid: false, error: "Compartilhamento não pode ficar em branco"}
    } else {
      return {valid: true}
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}
