import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pfPartnerCheckbox", "pjPartnerCheckbox", "chooseType", "cpfInputDiv", "cpfInput", "cnpjInputDiv",
    "cnpjInput", "sexDropdown", "sexInput", "saveAccountBtn",
    "nameInput", "birthdateInput", "sexDropdownBtn", "sameCityCheckbox", "notesInput",
    "issuedOnInput", "clinicInfo", "pfClinicInfo", "errorMessage", "addressInput",
    "mainCard", "footerTable", "descriptionInput", "chooseType", "cpfInputDiv", "ibgeInput",
    "zipInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "streetInput", "numberInput",
    "complementInput", "districtInput", "stateInput", "stateFilter", "cityInput", "cityFilter", "saveBtn",
    "individualBtn", "legalBtn", "list", "bodyTable", "formCard"]

  connect() {
    this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    this.tableCols = 4
    this.controllerName = "operations--products--clinics--financials--index--receivables--clinics"
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `company_name` }

    this.saveController.nextBtnTarget.classList.add(`d-none`)
  }

  doClinicsListHtml() {
    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as PJ Médicas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">CNPJ</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Cidade</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Ambiente</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Fiscal</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Certificado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer text-center">
                                Regime Tributário
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer text-center">
                                Natureza Legal
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="asc">north</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--save").formBodyTarget.innerHTML = html)
      // resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--save").formTitleTarget.innerText = `Selecione a PJ Médica`
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_clinics = []

    this.application.clinics.forEach(element => {
      if (element.has_tax_regimes) {
        current_clinics[current_clinics.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_clinics, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_clinics, this.sort.field)
    }

    if (clinics.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(clinics.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (clinics[x] !== undefined) {
            internPage[internPage.length] = clinics[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: clinics.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tomadores</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.clinicTablePartial(element, data.length))
      });
    }
  }

  clinicTablePartial(element, length) {
    
    if (element.integration_company) {
      if (element.integration_company.fiscal_status_pretty) {
        var fiscalStatus = element.integration_company.fiscal_status_pretty
      } else {
        var fiscalStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      }

      if (element.integration_company.certificate_status_pretty) {
        var certificateStatus = element.integration_company.certificate_status_pretty
      } else {
        var certificateStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      }

      if (element.integration_company.environment == `development`) {
        var environmentStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-primary default">code</span><span class="mc-tooltiptext">${element.integration_company.environment_pretty}</span></span>`
      } else if (element.integration_company.environment == `production`) {
        var environmentStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-success default">check_circle</span><span class="mc-tooltiptext">${element.integration_company.environment_pretty}</span></span>`
      } else {
        var environmentStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      }
    } else {
      var fiscalStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      var certificateStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      var environmentStatus = `<span class="mc-tooltip"><span class="material-icons md-150 md-danger default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#selectClinic">
                      <span class="mc-tooltip">
                        <span>${element.company_name}</span>
                        <span class="mc-tooltiptext">Clique para Selecionar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#selectClinic">
                      <span class="mc-tooltip">
                        <span>${element.company_cnpj_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Selecionar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.company_city}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${environmentStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${fiscalStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${certificateStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.tax_regime_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.legal_nature_pretty}</td>
                    
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }


  selectClinic(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Aguarde um segundinho!` })
    var clinicId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var data = { clinic: { clinic_id: clinicId }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = `/operations/products/clinics/managements/verifications/check_can_invoice`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var checkCanInvoice = response.data.cln
        if (checkCanInvoice.final_receivable_check && checkCanInvoice.final_invoice_check) {
          controller.setClinic(clinicId)
        } else if (checkCanInvoice.final_receivable_check) {
          controller.setClinic(clinicId)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`success`, `A PJ pode emitir apenas Recibo PJ!`, 5000)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, checkCanInvoice.final_invoice_message, 5000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, checkCanInvoice.final_invoice_message, 5000)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, checkCanInvoice.final_invoice_message, 5000)
        }

        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  setClinic(clinicId) {

    var notFindClinic = true

    this.application.clinics.forEach(clinic => {
      if (clinic.id == clinicId) {
        notFindClinic = false

        this.saveController.current_clinic = clinic
        this.saveController.send_data.receivable.clinic_id = clinic.id
        this.saveController.send_data.receivable.provider_name = clinic.company_name
        this.saveController.send_data.receivable.provider_number = clinic.company_cnpj_pretty
        this.saveController.send_data.receivable.invoice_status = `not_transmitted`
        this.saveController.send_data.receivable.receivement_status = `not_received`

      }
    })

    this.getControllerByIdentifier("operations--products--clinics--financials--index--broadcast").permission = this.permission
    this.getControllerByIdentifier("operations--products--clinics--financials--index--broadcast").getRoom()
  }

  changeStep() {
    this.saveController.nextBtnTarget.classList.remove(`d-none`)
    var event = { target: { innerText: `PRÓXIMO` } }
    this.saveController.changeStep(event)
    this.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
  }

  getData() {}

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--clinics--financials--index--receivables--clinics"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}

// parcela
// city_service_code = ctiss
// federal_service_code = inicio ctiss
// cnae_code = cnae

// taxation_type = comparar cidades
// additional_information ? 
