import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--logs--dashboard`
  }

  doLogsDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="list" data-controller="app--stats--trackers--logs--index"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.submenuController) {
        controller.submenuController.dashboardToggleBtn(`LOGS`)
      }

      controller.getControllerByIdentifier("app--stats--trackers--logs--index").obj_filter = controller.obj_filter
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").record_id = controller.record_id
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").record_type = controller.record_type
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").obj_id = controller.obj_id
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").obj_type = controller.obj_type
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").permission = controller.permission
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").noLogsMessage = controller.noLogsMessage
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").title = controller.title
      controller.getControllerByIdentifier("app--stats--trackers--logs--index").doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
