import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["productAmount", "productGain", "productLost", "modal", "body", "saveBtn", "reasonDropdown", "reasonDropdownBtn", "reasonInput", "reasonList"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--closing`
    this.stage = ``
    this.open()
  }

  setValues() {
    this.stage = this.getControllerByIdentifier("commercial--sales--opportunities--entities--show").send_data.product.stage
    if (this.stage == `gain`) {
      this.application.opportunity.products.forEach(product => {
        this.nameTarget(`productAmount-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.amount * 100))
        this.nameTarget(`productGain-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.amount * 100))
        this.nameTarget(`productLost-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.lost * 100))
        this.nameTarget(`productLost-${product.id}`).disabled = true
        // this.nameTarget(`reasonDropdownBtn-${product.id}`).disabled = true
        this.nameTarget(`reasonDiv-${product.id}`).classList.add(`d-none`)
      })
    } else if (this.stage == `lost`) {
      this.application.opportunity.products.forEach(product => {
        this.nameTarget(`productAmount-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.amount * 100))
        this.nameTarget(`productGain-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.gain * 100))
        this.nameTarget(`productGain-${product.id}`).disabled = true
        this.nameTarget(`productLost-${product.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(product.amount * 100))
        // this.nameTarget(`reasonDropdownBtn-${product.id}`).disabled = false
        this.nameTarget(`reasonDiv-${product.id}`).classList.remove(`d-none`)
      })
    }

    // this.refreshSaveBtn()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  saveProducts() {
    var products = []
    this.application.opportunity.products.forEach(element => {
      var product = {}
      product.id = element.id
      product.gain_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`productGain-${element.id}`).value)
      product.lost_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`productLost-${element.id}`).value)
      // product.reason = this.nameTarget(`productReason-${element.id}`).value
      product.reason = this.nameTarget(`reasonInput-${element.id}`).dataset.reason
      if (this.stage == `gain`) {
        product.gain = true
        product.lost = false
      } else if (this.stage == `lost`) {
        product.gain = false
        product.lost = true
      }
      products[products.length] = product
    })
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--show").send_data.product.products = products
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--show").requestSaveJourney()
    this.stopRefreshing()
    this.close()
  }

  closeModal() {
    this.close()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.stage == `gain`) {
        if (controller.nameTarget(`productGain-${product.id}`).value == `` || controller.nameTarget(`productGain-${product.id}`).value == `R$ 0,00`) {
          len += 1
        }
      } else if (controller.stage == `lost`) {
        controller.application.opportunity.products.forEach(product => {
          if (controller.nameTarget(`reasonInput-${product.id}`).innerText == ``) {
            len += 1
          }

          if (controller.nameTarget(`productLost-${product.id}`).value == `` || controller.nameTarget(`productLost-${product.id}`).value == `R$ 0,00`) {
            len += 1
          }
        })
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 400);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
