import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--emails--dashboard`
  }

  doEmailsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-7 pr-1" data-${this.controllerName}-target="list" data-controller="app--stats--trackers--emails--index"></div>
                  <div class="col-5 pl-1" data-${this.controllerName}-target="view" data-controller="app--stats--trackers--emails--show"></div>
                </div>`

    // this.getControllerByIdentifier("app--stats--trackers--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.submenuController) {
        controller.submenuController.dashboardToggleBtn(`EMAILS`)
      }

      controller.getControllerByIdentifier("app--stats--trackers--emails--index").date_filter = controller.date_filter
      controller.getControllerByIdentifier("app--stats--trackers--emails--index").domain_id = controller.domain_id
      controller.getControllerByIdentifier("app--stats--trackers--emails--index").domain_type = controller.domain_type
      controller.getControllerByIdentifier("app--stats--trackers--emails--index").permission = controller.permission
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
