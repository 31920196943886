import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dates", "view", "listMembers", "showMember", "listGoals", "showGoal", "mainDashboard", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--settings--dashboard`
  }

  doSettingsDashboard() {
    // `<div class="col-3 px-1" data-controller="operations--products--tax-returns--settings--goals">
    //   <div class="row">
    //     <div class="col-12 px-1" data-${this.controllerName}-target="listGoals"></div>
    //   </div>
    //   <div class="row my-2">
    //     <div class="col-12 px-1" data-${this.controllerName}-target="showGoal"></div>
    //   </div>
    // </div>`
    // `<div class="col-3 px-1" data-controller="operations--products--tax-returns--settings--achievements">
    //   <div class="row">
    //     <div class="col-12 px-1" data-${this.controllerName}-target="listGoals"></div>
    //   </div>
    // </div>`

    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var datesHtml = `<div class="col-4 pr-1" data-${this.controllerName}-target="dates" data-controller="operations--products--tax-returns--settings--dates"></div>`
    } else {
      var datesHtml = ``
    }

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  ${datesHtml}
                  <div class="col-4 px-1" data-controller="operations--products--tax-returns--settings--members">
                    <div class="row">
                      <div class="col-12 px-1" data-${this.controllerName}-target="listMembers"></div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-1" data-${this.controllerName}-target="showMember"></div>
                    </div>
                  </div>
                </div>`
    this.getControllerByIdentifier("operations--products--tax-returns--entities--show").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-returns--settings--dashboard"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
