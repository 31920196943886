import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewDocumentCard", "summaryTitle", "totalDocuments", "deniedDocuments", "approvedDocuments", "processedDocuments", "totalTerm", "partialTerm", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--process--summary`
    this.doSummaryHtml()
  }

  doSummaryHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewDocumentCard">
                  <div class="card-header card-header-title f-065 text-center">
                    <h6 class="card-title px-0" style="padding:1rem;font-size:110%;margin-bottom:0px;">Resumo Processamento: <span data-${this.controllerName}-target="summaryTitle"></span></h6>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pr-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Total Documentos</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="totalDocuments"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Documentos Negados</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="deniedDocuments"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pr-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Documentos Aprovados</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="approvedDocuments"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Documentos Processados</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="processedDocuments"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pr-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Tempo Total</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="totalTerm"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Tempo Gasto</span>
                      </div>
                      <div class="card-body text-center f-065 px-1">
                        <span data-${this.controllerName}-target="partialTerm"></span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").summaryTarget.innerHTML = html)
    }).then(() => {
      controller.doSummaryCalculation()
      controller.summaryTitleTarget.innerText = controller.application.current_tax_filing_calculation.journey_status_pretty
    })
  }

  doSummaryCalculation() {
    this.current_files = []
    var totalDocuments = 0
    var deniedDocuments = 0
    var approvedDocuments = 0
    var processedDocuments = 0
    var totalTerm = 0
    var partialTerm = 0

    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        totalDocuments += 1
        if (element.status == `denied`) {
          deniedDocuments += 1
        }
        if (element.status == `approved`) {
          this.current_files[this.current_files.length] = element
          approvedDocuments += 1
        }
        if (element.status == `processed`) {
          this.current_files[this.current_files.length] = element
          processedDocuments += 1
        }
      }
    })

    this.current_files.forEach(element => {
      if (element.term == `short`) {
        totalTerm += 5
        if (element.status == `processed`) { partialTerm += 5 }
      } else if (element.term == `medium`) {
        totalTerm += 10
        if (element.status == `processed`) { partialTerm += 10 }
      } else if (element.term == `long`) {
        totalTerm += 25
        if (element.status == `processed`) { partialTerm += 25 }
      } else if (element.term == `costly`) {
        totalTerm += 50
        if (element.status == `processed`) { partialTerm += 50 }
      }
    })


    this.totalDocumentsTarget.innerText = totalDocuments
    this.deniedDocumentsTarget.innerText = deniedDocuments
    this.approvedDocumentsTarget.innerText = approvedDocuments
    this.processedDocumentsTarget.innerText = processedDocuments
    this.totalTermTarget.innerText = this.getControllerByIdentifier("app--helpers--time").doSimpleDurationFormat(totalTerm * 60)
    this.partialTermTarget.innerText = this.getControllerByIdentifier("app--helpers--time").doSimpleDurationFormat(partialTerm * 60)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
