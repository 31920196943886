import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "tableUploads", "bodyUploadsTable", "uploadBtn", "tableHandlingFeedback",
                    "errorHandling", "uploadRow", "uploadColumn", "tableFinishedFeedback", "newUploadFeedback",
                    "editUploadFeedback", "failUploadFeedback", "tableUploadsFeedback", "downloadModel", "totalUploadFeedback", "closeBtn"]

  connect() {
    this.controllerName = `app--helpers--upload`
    this.request_next_index = 1
    this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.spinHour()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
    window.location.reload()
  }

  upload() {

    this.tableHandlingFeedbackTarget.classList.add(`d-none`)
    this.tableUploadsFeedbackTarget.classList.remove(`d-none`)
    this.uploadBtnTarget.disabled = true
    this.closeBtnTarget.disabled = true

    this.uploadFeedbacks()

    var fileInput = document.querySelector('input[type=file]');
    var file = fileInput.files[0];

    var formData = new FormData();
    formData.append('current_user_id', currentUserId);
    formData.append('file', file);

    var rows = this.uploadRowTargets.length
    var columns = this.uploadColumnTargets.length

    this.requests = []
    var dataRow = []
    for (var rowIndex = 1; rowIndex < rows + 1; rowIndex++) {
      var values = []
      for (var columnIndex = 0; columnIndex < columns; columnIndex++) {
        values[values.length] = this.nameTarget(`upload-r-${rowIndex}-c-${columnIndex}`).innerText
      }

      dataRow[dataRow.length] = { headers: this.headers, values: values }
    }

    for (var index = 0; index < rows; index++) {
      this.send_data = { current_user: {}, model: {}, index: {} }
      this.send_data.index = index
      this.send_data.model = dataRow[index]
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var init = { method: this.method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      this.requests[this.requests.length] = init
    }
    
    this.requestNext()
  }

  requestNext() {

    var controller = this
    if (this.requests.length) {
      
      fetch(this.url_upload, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          
          if (response.save) {
            if (response.new_record) {
              controller.nameTarget(`row-${Number(controller.request_next_index)}`).classList.add(`upload-success`)
              controller.nameTarget(`feedback-${Number(controller.request_next_index)}`).innerHTML = `<span class="mc-tooltip">
                                                                                                        <span class="material-icons md-dark help">done</span>
                                                                                                        <span class="mc-tooltiptext mc-tooltiptext-right">${response.message}</span>
                                                                                                      </span>`
            } else {
              controller.nameTarget(`row-${Number(controller.request_next_index)}`).classList.add(`upload-edited`)
              controller.nameTarget(`feedback-${Number(controller.request_next_index)}`).innerHTML = `<span class="mc-tooltip">
                                                                                                        <span class="material-icons md-dark help">done</span>
                                                                                                        <span class="mc-tooltiptext mc-tooltiptext-right">${response.message}</span>
                                                                                                      </span>`
            }
          } else {
            controller.nameTarget(`row-${Number(controller.request_next_index)}`).classList.add(`upload-failed`)
            controller.nameTarget(`feedback-${Number(controller.request_next_index)}`).innerHTML = `<span class="mc-tooltip">
                                                                                                      <span class="material-icons md-dark help">pan_tool</span>
                                                                                                      <span class="mc-tooltiptext mc-tooltiptext-right">${response.message}</span>
                                                                                                    </span>`
          }

          controller.getControllerByIdentifier("app--helpers--elements").tooltip()
          controller.request_next_index += 1
          controller.requestNext()
        })
        .catch(error => {
          this.closeBtnTarget.disabled = false
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      this.uploadBtnTarget.classList.add(`d-none`)
      this.closeBtnTarget.disabled = false
      this.tableFinishedFeedbackTarget.classList.remove(`d-none`)
      setTimeout(() => {
        this.stopFeedbacks()
      }, 500);
    }

  }

  downloadModel() {
    this.downloadModelTarget.disabled = true

    this.send_data = { current_user: {}, model: {} }

    this.send_data.model.mockup = true
    this.send_data.model.fields = this.model_fields
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = this.url_download_model
    
    const init = { method: `POST`, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        var date = new Date()
        var fileName = `planilha-modelo-${this.feature}-${date.getTime()}`
        var fileExtension = `csv`

        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }

  dropHandler(ev) {
    ev.preventDefault()
    var fileInput = document.querySelector('input[type=file]')

    var reader = new FileReader()
    var controller = this
    reader.onload = function () {
      var dataUrl = reader.result
      controller.doTable(dataUrl)
    }
    reader.readAsDataURL(fileInput.files[0])
  }

  doTable(dataUrl) {

    var controller = this
    $.ajax({
      url: dataUrl,
      dataType: `text`,
      success: function (data) {
        var csv_data = data.split(/\r?\n|\r/);
        var tbody = ``
        var thead = `<th></th>`
        controller.headers = csv_data[0].split(",");
        for (var index = 0; index < controller.headers.length; index++) {
          thead += `<th scope="col" class="text-center upload-column" data-${controller.controllerName}-target="uploadColumn">${controller.headers[index]}</th>`
        }
        var html = `<div class="table-responsive">
                        <table class="table table-sm table-hover table-search" style="font-size:80%;">
                          <thead><tr>${thead}</tr></thead>
                          <tbody data-${controller.controllerName}-target="bodyUploadsTable"></tbody>
                        </table>
                      </div>`

        controller.tableUploadsTarget.innerHTML = html

        var errorPrev = `Primeiro`
        var error = ``
        for (var rowIndex = 1; rowIndex < csv_data.length; rowIndex++) {
          // var tbody = ``
          var element = controller.CSVtoArray(csv_data[rowIndex]);
          try {
            error = element[1]
          }
          catch (err) {
            error = errorPrev
          }
          tbody += `<tr class="upload-row itemRow" style="font-size:40%;" data-${controller.controllerName}-target="row-${rowIndex} uploadRow">`
          tbody += `<td class="text-center p-0 align-middle" style="font-size:200%" data-${controller.controllerName}-target="feedback-${rowIndex}"></td>`
          try {
            for (var columnIndex = 0; columnIndex < element.length; columnIndex++) {
              tbody += `<td class="text-center p-0 align-middle" style="font-size:200%" data-field="${controller.headers[columnIndex]}" data-${controller.controllerName}-target="upload-r-${rowIndex}-c-${columnIndex}">${element[columnIndex]}</td>`
            }
          }
          catch (err) {
            tbody += `<td colspan="14" class="text-center p-0 align-middle error-handling cell-danger" data-${controller.controllerName}-target="errorHandling" style="font-size:200%" data-sibling-error="${errorPrev}">Erro na Linha de Parametrização</td>`
          }
          errorPrev = error
          tbody += '</tr>'
          controller.bodyUploadsTableTarget.innerHTML = tbody
        }

        // var errorLength = controller.tableUploadsTarget.find(".error-handling").length
        var errorLength = controller.errorHandlingTargets.length

        if (errorLength == 0) {
          message = `<span>Planilha Tratada Corretamente</span>`
          controller.uploadBtnTarget.disabled = false
        } else {
          var message = `<span>`
          message += `Planilha com seguintes erros: `
          // controller.tableUploadsTarget.find(".error-handling").forEach((element, i) => {
          controller.errorHandlingTargets.forEach((element, i) => {
            var error = element.dataset.siblingError
            message += `<div class="chip fade show" id="chipDismissible-${i}">Próximo ao Item: ${error}<button class="close" data-dismiss="alert" data-target="#chipDismissible-${i}" type="button"><span class="material-icons md-sm md-danger default">cancel</span></button></div>`
          })
          message += `</span><br>`
          controller.uploadBtnTarget.disabled = true
        }

        controller.tableHandlingFeedbackTarget.innerHTML = message
        controller.tableHandlingFeedbackTarget.classList.remove(`d-none`)
      },
      error: function (data) {}
    });

    this.tableUploadsTarget.style.height = ($(window).height() * 0.6) + "px"
  }

  CSVtoArray(text) {
    var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/
    var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g
    // Return NULL if input string is not well formed CSV string.
    if (!re_valid.test(text)) return null
    var a = []                     // Initialize array to receive values.
    text.replace(re_value, // "Walk" the string using replace with callback.
      function (m0, m1, m2, m3) {
        // Remove backslash from \' in single quoted values.
        if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"))
        // Remove backslash from \" in double quoted values.
        else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'))
        else if (m3 !== undefined) a.push(m3)
        return ''; // Return empty string.
      });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) a.push('')
    return a
  }

  uploadFeedbacks() {

    var controller = this

    var newRecords = 0
    var editRecords = 0
    var failRecords = 0

    this.uploadRowTargets.forEach(element => {
      if (element.classList.contains(`upload-success`)) {
        newRecords += 1
      }
      if (element.classList.contains(`upload-edited`)) {
        editRecords += 1
      }
      if (element.classList.contains(`upload-failed`)) {
        failRecords += 1
      }
    })

    this.newUploadFeedbackTarget.innerText = newRecords
    this.editUploadFeedbackTarget.innerText = editRecords
    this.failUploadFeedbackTarget.innerText = failRecords
    this.totalUploadFeedbackTarget.innerText = Number(newRecords) + Number(editRecords) + Number(failRecords)
    
    this.uploadFeedbackInterval = setInterval(function () {
      
      var newRecords = 0
      var editRecords = 0
      var failRecords = 0

      controller.uploadRowTargets.forEach(element => {
        if (element.classList.contains(`upload-success`)) {
          newRecords += 1
        }
        if (element.classList.contains(`upload-edited`)) {
          editRecords += 1
        }
        if (element.classList.contains(`upload-failed`)) {
          failRecords += 1
        }
      })

      controller.newUploadFeedbackTarget.innerText = newRecords
      controller.editUploadFeedbackTarget.innerText = editRecords
      controller.failUploadFeedbackTarget.innerText = failRecords
      controller.totalUploadFeedbackTarget.innerText = Number(newRecords) + Number(editRecords) + Number(failRecords)
    }, 100);

  }

  filterUpload(ev) {

    var filter = ev.currentTarget.dataset.filter

    this.uploadRowTargets.forEach(element => {
      if (element.classList.contains(filter)) {
        element.classList.remove(`d-none`)
      } else {
        element.classList.add(`d-none`)
      }
    })
  }

  totalUpload() {
    this.uploadRowTargets.forEach(element => {
      element.classList.remove(`d-none`)
    })
  }

  stopFeedbacks() {
    if (this.uploadFeedbackInterval) {
      clearInterval(this.uploadFeedbackInterval)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}