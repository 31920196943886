import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "headTable", "opportunityLeadName",
                    "countOneCheckbox", "countOne", "countTwoCheckbox", "receivableHeader"]

  connect() {
    this.controllerName = `financials--books--receivables--annual--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
    this.hasShow = false
    this.tableCols = 14
    this.doAccounts()
  }

  doIndexListHtml() {

    var clearBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" style="font-size:80%;">
                          <thead data-${this.controllerName}-target="headTable"></thead>
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--annual--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doTableHead()
      controller.doDataTable()
    })
  }

  doTableHead() {

    this.application.dates.forEach((element, index) => {
      if (this.application.current_date.id == element.id) {
        this.application.dates.slice(index, 12 + index).forEach(element => {
          this.application.annual_receivables[this.application.annual_receivables.length] = element
        })
      }
    })

    var monthsHtml = ``

    this.application.annual_receivables.forEach(element => {
      monthsHtml += `<th scope="col" class="pointer p-1 table-7 align-middle text-center">${element.date_pretty}</th>`
    })
    // style="font-size:80%;"

    this.headTableTarget.innerHTML = `<tr>
                                        <th colspan="2" scope="col" class="pointer p-1 align-middle table-8">
                                          Contas
                                        </th>
                                        ${monthsHtml}
                                      </tr>`

  }

  doDataTable() {
    var accountsHtml = ``

    this.accounts.forEach(accountElement => {
      accountsHtml += this.receivableTablePartial(accountElement)
    })

    this.bodyTableTarget.innerHTML = accountsHtml

  }

  receivableTablePartial(accountElement) {
    var receivablesHtml = ``

    var highlight = ``
    var spaceRow = ``
    var borderHighlight = ``
    if (accountElement.highlight) {
      highlight = `f-bold`
      spaceRow = `<tr><td colspan="14" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td></tr>`
      borderHighlight = `border-highlight`
    }

    this.application.annual_receivables.forEach(dateElement => {
      receivablesHtml += `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-center ${highlight}">${this.controllerNumber.currencyOptionMask(dateElement.receivable_compilation[accountElement.name])}</td>`
    })

    var rowHtml = `${spaceRow}
                  <tr class="itemRow ${borderHighlight}">
                    <td colspan="2" style="font-size:100%;" scope="col" class="p-1 align-middle ${highlight}">${accountElement.name_pretty}</td>
                    ${receivablesHtml}
                  </tr>
                  ${spaceRow}`

    return rowHtml
  }

  doAccounts() {
    this.accounts = [
                      {
                        name: `total_receivable_medclinic_amount`,
                        name_pretty: `PJ Médica`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medfiling_amount`,
                        name_pretty: `Imposto de Renda`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medbooking_amount`,
                        name_pretty: `Livro-Caixa`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medpayroll_amount`,
                        name_pretty: `Folha de Pagamento`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medbiling_amount`,
                        name_pretty: `Faturamento`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medreceivement_amount`,
                        name_pretty: `Gestão Recebimento`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_medconsulting_amount`,
                        name_pretty: `Consultorias/Avulsos`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_revenue_amount`,
                        name_pretty: `Total Receitas`,
                        highlight: true,
                      },
                      {
                        name: `total_receivable_item_commercial_discount_amount`,
                        name_pretty: `Desconto Comercial`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_item_retention_discount_amount`,
                        name_pretty: `Desconto Retenção`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_item_error_discount_amount`,
                        name_pretty: `Desconto Financeiro`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_item_financial_discount_amount`,
                        name_pretty: `Desconto Erro Operacional`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_discount_amount`,
                        name_pretty: `Total Descontos`,
                        highlight: true,
                      },
                      {
                        name: `total_receivable_interval_monthly_amount`,
                        name_pretty: `Total Recorrente`,
                        highlight: false,
                      },
                      {
                        name: `total_receivable_interval_unique_amount`,
                        name_pretty: `Total Avulso`,
                        highlight: false,
                      },
                      
      
    ]
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
