import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.preloaderDashboard()
    this.getBookingHelpers()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  doBookingDashboard() {
    var cardHtml = ``

    this.bookings.forEach(element => {
      cardHtml += `<div class="col-6">
                    <div class="card card-products-mobile">
                      <figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip" data-url="${element.booking_url}" data-action="click->mobile--users--dashboards--helper#goToURL">
                        <span class="material-icons md-primary md-400">local_atm</span>
                        <figcaption class="figure-caption" style="white-space: pre-line;">
                          ${element.doctor_name}
                        </figcaption>
                        <span class="mc-tooltiptext">Ir para Livro-Caixa</span>
                      </figure>
                    </div>
                  </div>`
    })


    var html = `<div class="row mt-3">
                  <div class="col-12">
                    <h5>${this.application.user_name}, esse é o seu Dashboard MedCapital</h5>
                  </div>
                </div>
                <div class="row dashboard-content mt-3">
                  <div class="col-12">
                    <h6>Plataforma Livro-Caixa</h6>
                    <hr>
                  </div>
                  ${cardHtml}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getBookingHelpers() {
    var data = { helper: { account_token: this.application.current_user.token }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/helpers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.bookings = response.data.cln
          controller.doBookingDashboard()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  preloaderDashboard() {
    var html = `<div class="row" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
