import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "sendInstructionsBtn", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--entities--users`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    this.doIndexListHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos as Contas</h6>
                    <input class="form-control f-065 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th class="table-5"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Ver Conta</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Permissiões</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Usuário</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--entities--settings").usersTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(4, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    if (this.sort.mode == `asc`) {
      var users = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.booking_users, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var users = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.booking_users, this.sort.field)
    }

    if (users.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(users.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (users[x] !== undefined) {
            internPage[internPage.length] = users[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: users.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="11" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Aguarde mais um momento para carregar a Lista</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.userTablePartial(element))
      });
    }
  }

  userTablePartial(element) {
    if (element.has_permissions) {
      var permissionStatus = `<span class="mc-tooltip">
                                <span class="material-icons md-success md-150 pointer">done_all</span>
                                <span class="mc-tooltiptext">Tem Permissões</span>
                              </span>`
    } else {
      if (this.application.medbooking.can_assign && element.account_user) {
        var permissionStatus = `<button data-action="click->${this.controllerName}#savePermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">slideshow</span>
                                  <span class="mc-tooltiptext">Gerar Permissões</span>
                                </button>`
      } else {
        var permissionStatus = `<span class="mc-tooltip">
                                  <span class="material-icons md-danger md-150 pointer">clear</span>
                                  <span class="mc-tooltiptext">Sem Permissões</span>
                                </span>`
      }      
    }

    if (element.account_user) {
      var accountPath = `<button data-action="click->${this.controllerName}#goToUserPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Ver Conta ${element.account_name}</span>
                        </button>`
    } else {  
      var accountPath = `<span class="mc-tooltip">
                          <span class="material-icons md-danger md-150 pointer">clear</span>
                          <span class="mc-tooltiptext">Sem Conta</span>
                        </span>`
    }

    if (element.is_confirmed.has_user) {
      if (element.is_confirmed.confirmed) {
        var userConfirmed = `<span class="mc-tooltip">
                              <span class="material-icons md-success md-150 pointer">done_all</span>
                              <span class="mc-tooltiptext">Confirmado</span>
                            </span>`
      } else {
        var userConfirmed = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-user-id="${element.is_confirmed.user_id}" data-action="click->${this.controllerName}#sendConfirmationEmail" data-${this.controllerName}-target="sendInstructionsBtn">
                                <span class="material-icons md-sm md-dark">send</span>
                                <span class="mc-tooltiptext">Enviar Email de Instruções</span>
                              </button>`
      }
    } else {
      var userConfirmed = `<span class="mc-tooltip">
                             <span class="material-icons md-danger md-150 pointer">clear</span>
                             <span class="mc-tooltiptext">Não tem Usuário</span>
                           </span`
    }

    

    var rowHtml = `<tr class="itemRow" data-account-id="${element.account_id}" data-account-path="${element.account_path}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToUserPage">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center"></td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${accountPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${permissionStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${userConfirmed}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  savePermissions(ev) {
    var accountId = ev.currentTarget.closest(".itemRow").dataset.accountId
    const data = { product: { kind: `medbooking` }, account: { account_id: accountId }, current_user: { current_user_id: this.application.current_user.id, permission: "assign", feature: "medbooking"} }
    const url = "/users/permissions/save_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`operations--products--bookings--entities--index`).checkSinglePermissions(accountId)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  sendConfirmationEmail(ev) {
    this.sendInstructionsBtnTarget.disabled = true
    this.send_data = { current_user: {}, user: {} }

    this.send_data.user.id = ev.currentTarget.dataset.userId
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking`

    var url = "/users/send_confirmation_email"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.sendInstructionsBtnTarget.disabled = false
        if (response.save) {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToUserPage(ev) {
    var url = ev.target.closest(".itemRow").dataset.accountPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
