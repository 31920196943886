import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dates", "view", "listMembers", "showMember", "listGoals", "opportunities", "mainDashboard", "agent"]

  connect() {
    this.controllerName = `operations--products--tax-filings--settings--dashboard`
  }

  doSettingsDashboard() {
    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var datesHtml = `<div class="row"><div class="col-12 px-1" data-${this.controllerName}-target="dates" data-controller="operations--products--tax-filings--settings--dates"></div></div>`
    } else {
      var datesHtml = ``
    }

    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var agentHtml = `<div class="row my-2"><div class="col-12 px-1" data-${this.controllerName}-target="agent" data-controller="operations--products--tax-filings--settings--agent"></div></div>`
    } else {
      var agentHtml = ``
    }

    // var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
    //               <div class="col-4 pr-1">
    //                 ${datesHtml}
    //                 ${agentHtml}
    //               </div>
    //             </div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-3 px-1" data-${this.controllerName}-target="dates" data-controller="operations--products--tax-filings--settings--dates"></div>
                  <div class="col-3 px-1" data-${this.controllerName}-target="agent" data-controller="operations--products--tax-filings--settings--agent"></div>
                  <div class="col-6 px-1" data-${this.controllerName}-target="opportunities" data-controller="operations--products--tax-filings--settings--opportunities"></div>
                </div>`

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`).dashboardToggleBtn(`CONFIGURAÇÕES`)
    }) 
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-filings--settings--dashboard"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
