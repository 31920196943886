import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "content", "actionView", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--receivables--dashboard`
    this.setAccountPermission()
  }

  doReceivablesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="actionView"></div>
                  <div class="col-10" data-${this.controllerName}-target="content" data-controller="operations--products--clinics--financials--receivables--index
                                                                                                    operations--products--clinics--financials--receivables--update-status
                                                                                                    operations--products--clinics--financials--add--show"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--financials--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--clients--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.setReceivables()
      controller.getControllerByIdentifier(`operations--products--clinics--clients--show--submenu`).dashboardToggleBtn(`NOTA FISCAL`)
      controller.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`).doActionCard()

      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--index`).permission = this.permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).permission = this.permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).integration_permission = this.integration_permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--update-status`).permission = this.permission

      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--index`).doIndexListHtml()
    })
  }

  emitReceivable() {
    this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`).emitReceivable()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  setReceivables() {
    this.application.receivables = []
    this.application.canceled_receivables = []

    this.application.all_receivables.forEach(element => {
      if (element.invoice_status == "canceled" || element.invoice_status == "substituted") {
        this.application.canceled_receivables[this.application.canceled_receivables.length] = element
      } else {
        this.application.receivables[this.application.receivables.length] = element
      }
    })
  }

  setAccountPermission() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      this.permission = `medclinic_client_receivables`
      this.integration_permission = `medclinic_client_integration_invoices`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.permission = `medclinic_doctor_client_receivables`
      this.integration_permission = `medclinic_doctor_client_integration_invoices`
    } else if (this.application.current_user.account_kind == `helper`) {
      this.permission = `medclinic_helper_client_receivables`
      this.integration_permission = `medclinic_helper_client_integration_invoices`
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
