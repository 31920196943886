import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "save", "view", "list", "content", "reportCard", "dateList", "onboardCard", "followupCard", "pipelineCard",
    "cancelCard", "general", "dailyChart", "accrualChart", "cancel", "reasonCancel", "sourceCancel", "dateCard", "reportContent", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `operations--products--tax-filings--reports--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reportCard = `followup`
  }

  doPageGrid() {

    var html = `<div class="row my-4 w-100">
                  <div class="col-2"></div>
                  <div class="col-2 pl-0 pr-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard followupCard" data-report="followup" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Acompanhamento</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard onboardCard" data-report="onboard" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Onboards x IRPFs</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard pipelineCard" data-report="pipeline" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Pipeline</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" data-${this.controllerName}-target="dateList" style="overflow:auto;"></div>
                  <div class="col-10 px-0" data-${this.controllerName}-target="reportContent">
                    
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.dateListTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.reportCardTargets[0].classList.add(`bg-primary`)

      controller.doDateList()
      controller.setReport()
    })
  }

  doPageGridLoader() {

    var html = `<div class="row my-4 w-100">
                  <div class="col-2"></div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 pr-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" data-${this.controllerName}-target="dateList" style="overflow:auto;">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.dates = []
      controller.setDateData()
    })
  }

  setDateData() {
    var firstYear = 2021
    var currentDate = new Date()

    for (var index = firstYear; index <= currentDate.getFullYear(); index++) {
      var date = new Date(index, 0, 1)
      this.dates.push(
        {
          year: date.getFullYear(),
          month: 0,
          name_short: `${date.getFullYear()}`,
          token: `${date.getFullYear()}`
        }
      )

    }

    this.current_date = this.dates[this.dates.length - 1]
    this.doPageGrid()
  }

  doDateList() {
    var listHtml = ``

    this.dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.dates, `token`)

    this.dates.forEach(element => {
      listHtml += `<div class="card pointer mb-2" data-${this.controllerName}-target="dateCard" data-year="${element.year}" data-month="${element.month}" data-token="${element.token}" data-action="click->${this.controllerName}#toggleDate">
                    <div class="card-header py-1 f-065 text-center">
                      <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${element.name_short}</strong></h6>
                    </div>
                  </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dateListTarget.innerHTML = listHtml)
    }).then(() => {
      controller.dateCardTargets[0].classList.add(`bg-primary`)
    })
  }

  toggleDate(ev) {
    this.dateCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.dates.forEach(element => {
      if (ev.currentTarget.dataset.token == element.token) {
        this.current_date = element
      }
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.setReport()
  }

  toggleReport(ev) {
    this.reportCard = ev.currentTarget.dataset.report

    this.reportCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.setReport()
  }

  setReport() {
    // this.getControllerByIdentifier(`operations--products--tax-filings--reports--dashboard`)
    this.reportContentTarget.dataset.controller = ``

    var controller = this
    if (this.reportCard == `followup`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--tax-filings--reports--followup`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--followup`).current_date = this.current_date
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--followup`).getCalculations()
      })
    } else if (this.reportCard == `onboard`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--tax-filings--reports--onboards`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--onboards`).current_date = this.current_date
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--onboards`).getOnboards()
      })
    } else if (this.reportCard == `pipeline`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--tax-filings--reports--pipeline`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--pipeline`).current_date = this.current_date
        controller.getControllerByIdentifier(`operations--products--tax-filings--reports--pipeline`).doPipelineHtml()
      })
    } else if (this.reportCard == `cancel`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--tax-filings--reports--cancellation`)
      }).then(() => {
        // controller.getControllerByIdentifier(`operations--products--tax-filings--reports--cancellation`).doReport()
      })
    }
  }

  doMainDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8" data-operations--products--tax-filings--reports--receivables--dashboard-target="content" data-controller="operations--products--tax-filings--reports--receivables--dashboard"></div>
                  <div class="col-4" data-operations--products--tax-filings--reports--logs--dashboard-target="content" data-controller="operations--products--tax-filings--reports--logs--dashboard"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--tax-filings--index--dashboard").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--submenu`).dashboardToggleBtn(`RELATÓRIOS`)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
