import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "asdf", "pendencyCount", "sideBarReport",
    "refreshListBtn", "optionBtn", "searchTable"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--resume`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  doResumeHtml() {

    var titles = this.setTitles()
    var remunerations = this.setAmounts()

    var html = `<div class="card pointer">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>${titles.wins}</strong></h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center">
                    <span class="mb-0 f-075">
                      ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(remunerations.wins)}
                    </span>
                  </div>
                </div>
                <div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>${titles.discounts}</strong></h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center">
                    <span class="mb-0 f-075">
                      ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(remunerations.discounts)}
                    </span>
                  </div>
                </div>
                <div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>${titles.net}</strong></h6>
                  </div>
                  <div class="card-body py-2 px-2 text-center">
                    <span class="mb-0 f-075">
                      ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(remunerations.net)}
                    </span>
                  </div>
                </div>`

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").resumeTarget.innerHTML = html
  }

  setTitles() {
    var titles = {}

    if (this.current_contract.kind == `intern`) {
      titles.wins = `Bolsa + Auxílios`
      titles.discounts = `Descontos`
      titles.net = `Valor Líquido`
    } else if (this.current_contract.kind == `labor_law`) {
      titles.wins = `Salário + Auxílios`
      titles.discounts = `Descontos`
      titles.net = `Valor Líquido`
    } else if (this.current_contract.kind == `self_employed`) {
      titles.wins = `Remuneração + Reembolsos`
      titles.discounts = `Descontos`
      titles.net = `Líquido à Receber`
    }

    return titles
  }

  setAmounts() {
    var remunerations = { wins: 0, discounts: 0, net: 0}

    var current_remunerations = []
    this.application.remunerations.forEach(element => {
      if (element.contract_id == this.current_contract.id && element.date_id == this.application.current_date.id) {
        current_remunerations[current_remunerations.length] = element
      }
    })

    current_remunerations.forEach(element => {
      if (element.kind_input == `inflow` || element.kind_input == `prepaidflow`) {
        remunerations.wins += Number(element.amount)
      } else if (element.kind_input == `outflow`) {
        remunerations.discounts += Number(element.amount)
      }
    })

    remunerations.net = Number(remunerations.wins) - Number(remunerations.discounts)

    return remunerations
  }

  doNoContractHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewContractCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Contrato Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewContractCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").resumeTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

}
