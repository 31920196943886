import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "addCouncilBtn", "bodyTable", "list", "saveBtn", "mainCheckbox", "destroyBtn",
                    "councilNameInput", "councilNameFilter", "councilStateInput", "councilStateFilter", "councilNumberInput",]

  connect() {

    // not in use

    this.controllerName = `operations--products--clinics--regularizations--partners--council`
    this.current_partner = this.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--show`).current_partner
    this.doCouncilsHtml()
  }

  disconnect() {
    this.stopRefreshing()
  }

  addCouncil() {
    this.actionMode = "new"
    this.formHtml()
  }

  editCouncil(ev) {
    this.actionMode = "edit"
    var councilId = ev.currentTarget.closest(".itemRow").dataset.id

    this.current_partner.councils.forEach(element => {
      if (element.id == councilId) {
        this.current_council = element
      }
    })
    this.formHtml()
    this.addCouncilBtnTarget.classList.add("d-none")
  }

  cancelForm() {
    this.doTableHtml()
    this.stopRefreshing()
  }

  destroyCouncil() {
    this.send_data = { current_user: {}, council: {} }
    this.actionMode = `destroy`

    this.send_data.council.id = this.current_council.id
    this.send_data.council.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_partner_councils`
    
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.requestSave()
  }

  saveCouncil() {
    this.send_data = { current_user: {}, council: {} }
    if (this.actionMode == "edit") { this.send_data.council.id = this.current_council.id }

    this.send_data.council.account_id = this.current_partner.record_id
    this.send_data.council.name = this.councilNameInputTarget.value.trim()
    this.send_data.council.state = this.councilStateInputTarget.value.trim()
    this.send_data.council.number = this.getControllerByIdentifier("app--helpers--numbers").fromCrmToNumber(this.councilNumberInputTarget.value.trim())
    this.send_data.council.main = this.mainCheckboxTarget.checked

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_partner_councils`

    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/accounts/councils/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/accounts/councils/update"
      var method = "PUT"
    } else if (this.actionMode == "destroy") {
      var url = "/users/accounts/councils/destroy"
      var method = "DELETE"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var councils = response.data.cln
          controller.current_partner.councils = councils
          // if (controller.actionMode == "new") {
          //   controller.current_partner.councils[controller.current_partner.councils.length] = council
          // } else if (controller.actionMode == "edit") {
          //   if (council.active) {
          //     controller.current_partner.councils.forEach((element, i) => {
          //       if (element.id == council.id) {
          //         controller.current_partner.councils.splice(controller.current_partner.councils.indexOf(element), 1, council)
          //       }
          //     })
          //   } else {
          //     controller.current_partner.councils.forEach((element, i) => {
          //       if (element.id == council.id) {
          //         controller.current_partner.councils.splice(controller.current_partner.councils.indexOf(element), 1)
          //       }
          //     })
          //   }
          // }
          controller.doTableHtml()
          controller.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--index`).doIndexListHtml()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doCouncilsHtml() {
    if (this.application.permissions.medclinic_partner_councils.can_create) {
      var createCouncil = `<div class="col-2 d-flex align-items-center px-0 mc-tooltip">
                            <button data-${this.controllerName}-target="addCouncilBtn" data-action="click->${this.controllerName}#addCouncil" type="button" class="btn btn-sm btn-table p-0">
                              <span class="material-icons md-sm md-dark">add</span>
                            </button>
                            <span class="mc-tooltiptext">Adicionar Registro</span>
                          </div>`
    } else {
      var createCouncil = `<div class="col-1"></div>`
    }

    var html = `<div class="row my-1">
                  ${createCouncil}
                  <div class="col-10 px-0 my-3" data-${this.controllerName}-target="list"></div>
                </div>`

    this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doTableHtml()
    })
  }

  formHtml() {
    this.stopRefreshing()
    var html = `<table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody>
                      <tr class="itemRow" data-${this.controllerName}-target="form">
                        <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="councilNameForm">Conselho</label>
                              <input class="form-control p-1 f-065" data-filter-mode="simple" data-${this.controllerName}-target="councilNameInput" data-action="focus->${this.controllerName}#nameFilter keyup->${this.controllerName}#nameFilter keyup->${this.controllerName}#enterName blur->${this.controllerName}#hideList" placeholder="Conselho" type="text" required>
                              <ul class="ul-filter filter-list d-none" style="z-index:1" data-${this.controllerName}-target="councilNameFilter">
                                <li data-filter="CRM" data-text="CRM" data-state="CRM" data-action="click->${this.controllerName}#selectName" class="pl-1 pr-2 pointer f-065 dark">CRM</li>
                                <li data-filter="CRO" data-text="CRO" data-state="CRO" data-action="click->${this.controllerName}#selectName" class="pl-1 pr-2 pointer f-065 dark">CRO</li>
                              </ul>
                            </div>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="councilStateForm">Estado XX</label>
                              <input class="form-control p-1 f-065" data-filter-mode="simple" data-${this.controllerName}-target="councilStateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter keyup->${this.controllerName}#enterState blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                              <ul class="ul-filter filter-list d-none" style="z-index:1" data-${this.controllerName}-target="councilStateFilter">
                                  <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MG</li>
                                  <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SP</li>
                                  <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AC</li>
                                  <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AL</li>
                                  <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AP</li>
                                  <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AM</li>
                                  <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">BA</li>
                                  <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">CE</li>
                                  <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">DF</li>
                                  <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">ES</li>
                                  <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">GO</li>
                                  <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MA</li>
                                  <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MT</li>
                                  <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MS</li>
                                  <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PA</li>
                                  <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PB</li>
                                  <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PR</li>
                                  <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PE</li>
                                  <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PI</li>
                                  <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RJ</li>
                                  <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RN</li>
                                  <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RS</li>
                                  <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RO</li>
                                  <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RR</li>
                                  <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SC</li>
                                  <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SE</li>
                                  <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">TO</li>
                                </ul>
                            </div>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Número</label>
                              <input class="form-control p-1 f-065" data-${this.controllerName}-target="councilNumberInput" placeholder="Número" type="text" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" required>
                            </div>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle">
                          <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center mc-tooltip ml-3">
                            <input type="checkbox" class="custom-control-input" id="checkCouncilMain" data-${this.controllerName}-target="mainCheckbox">
                            <label class="custom-control-label f-065 pointer" for="checkCouncilMain"></label>
                            <span class="mc-tooltiptext">É o Registro principal?</span>
                          </div>
                        </td>
                        <td style="font-size:80%;" scope="col" class="table-10 p-1 align-middle">
                          <button data-action="click->${this.controllerName}#destroyCouncil" type="button" class="btn btn-sm btn-table p-0 mc-tooltip d-none" data-${this.controllerName}-target="destroyBtn">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar</span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" class="text-right px-0 pt-0">
                          <button type="button" class="btn btn-secondary btn-sm f-065 py-2" data-action="click->${this.controllerName}#cancelForm" data-${this.controllerName}-target="cancelBtn">Cancelar</button>
                          <button disabled type="button" class="btn btn-primary btn-sm f-065 py-2" data-action="click->${this.controllerName}#saveCouncil" data-${this.controllerName}-target="saveBtn">Salvar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      if (controller.actionMode == "new") {

      } else if (controller.actionMode == "edit") {
        controller.destroyBtnTarget.classList.remove("d-none")

        controller.councilNameInputTarget.value = controller.current_council.name
        controller.councilStateInputTarget.value = controller.current_council.state
        controller.councilNumberInputTarget.value = controller.current_council.number_pretty
        controller.mainCheckboxTarget.checked = controller.current_council.main
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  crmMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCrm(ev)
  }

  nameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterName(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterState(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  doTableHtml() {
    this.addCouncilBtnTarget.classList.remove("d-none")
    var html = `<div class="table-responsive">
                  <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody data-${this.controllerName}-target="bodyTable">
                    </tbody>
                  </table>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.listData(controller.current_partner.councils)
    })
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="px-0 align-middle text-left" style="font-size:80%;">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-065">Sem Registro de Conselho cadastrado</span>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {

      var councils = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(data, `token`)

      councils.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.rowTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  rowTablePartial(element, length) {
    if (this.application.permissions.medclinic_partner_councils.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editCouncil" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.main) {
      var main = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Principal</span></span>`
    } else {
      var main = ``
    }

    var rowHtml = `<tr>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                    <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                  </tr>
                  <tr class="itemRow" data-id="${element.id}">
                    <td colspan="3" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle pointer text-center">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span data-controller="app--helpers--copy" data-copy="${element.number_pretty}" data-action="click->app--helpers--copy#copy">${element.number_full_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${main}</td>
                    <td colspan="1" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      
      if (controller.councilNameInputTarget.value == "") {
        len += 1
      }

      if (controller.councilStateInputTarget.value == "") {
        len += 1
      }

      if (controller.councilNumberInputTarget.value == "") {
        len += 1
      }
      

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
