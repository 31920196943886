import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "goalDashboard", "bookingDashboard", "printElement", "printCardElement", "subtitle", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--entities--show`
    this.account_kind = this.application.current_user.account_kind
    this.time_in = Date.now()
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.booking_token = location.pathname.split("/").pop()
    this.application.chart_accounts = []
    this.application.patients = []
    this.application.helpers = []
    this.application.offices = []
    this.application.signatures = []
    this.application.receipts = []
    this.application.payments = []
    this.application.provisions = []
    this.application.tickets = []
    this.application.tax_return_calculations = []
    this.doBookingGrid()

    // getCurrentUserPermissions
    // getBooking
    // getAccountProduct
    // getBookingDates
    // getReceipts
    // getTaxReturnCalculations
    // getBookingCalculations
    // getPayments
  }

  doBookingGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--bookings--entities--dashboard operations--products--bookings--receipts--dashboard operations--products--bookings--payments--dashboard operations--products--bookings--provisions--dashboard operations--products--bookings--documents--dashboard operations--products--bookings--personalizations--dashboard operations--products--bookings--settings--dashboard operations--products--bookings--patients--dashboard operations--products--bookings--helpers--dashboard operations--products--bookings--tickets--dashboard operations--products--bookings--notes--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").doBookingDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  doSubtitle() {
    this.subtitleTarget.innerText = `Gestão do Livro-Caixa Consultório | ${this.application.current_date.name}`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getBooking() {
    var data = { product: { token: this.application.booking_token, name: "medbooking" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.booking = response.data.cln
          controller.application.domain_type = "Livro-Caixa"
          controller.application.domain.type = "Livro-Caixa"
          controller.application.domain.id = controller.application.booking.id
          controller.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = controller.application.booking.account_name
          if (this.account_kind == `helper`) {
            controller.getBookingDates()
          } else {
            controller.getAccountProduct()
          }
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getAccountProduct() {
    var data = { account: { account_id: this.application.booking.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_account_products` } }
    const url = "/operations/accounts/products/read_with_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getBookingDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getBookingDates() {
    var data = { date: { product_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.doSubtitle()
            if (this.account_kind == `helper`) {
              controller.getBookingCalculations()
            } else {
              controller.getBookingCalculations()
              controller.getTaxReturnCalculations()
              controller.getHelpers()
            }

            
            if (controller.application.current_date.open == false) {
              // controller.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }            
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar a Competência, no Submenu > Configurações > Competências`, 3000)
          }
          controller.getControllerByIdentifier("operations--products--bookings--entities--dates").doCalendarHTML()
          
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)  
        }
        controller.getControllerByIdentifier("operations--products--bookings--entities--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getBookingCalculations() {
    var data = { calculation: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/calculations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.booking_calculations = response.data.cln
          controller.getReceipts()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").doBookingCalculationsHtml()
        controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").doBookingChatHtml()
        controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").doAliasDashboardHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxReturnCalculations() {
    var data = { calculation: { tax_return_id: this.application.products.tax_return_id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/tax_returns/calculations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_return_calculations = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").doTaxReturnCalculationsHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getPatients() {
    var data = { patient: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/patients/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.patients = response.data.cln
          controller.updateAllReceipts()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getHelpers() {
    var data = { helper: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/helpers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.helpers = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getOffices() {
    var data = { office: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/offices/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.offices = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSignatures() {
    var data = { signature: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/signatures/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.signatures = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }

        if (this.account_kind == `admin` || this.account_kind == `team`) {
          controller.getTickets()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getReceipts() {
    const data = { receipt: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/receipts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.receipts = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getPayments()
        controller.getOffices()
        controller.getSignatures()
      })
  }

  getPayments() {
    const data = { payment: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/payments/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.payments = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getProvisions()
      })
  }

  getProvisions() {
    const data = { provision: { booking_id: this.application.booking.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/provisions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.provisions = response.data.cln
          var has_provision = false
          controller.application.provisions.forEach(element => {
            if (controller.application.current_date.month == element.month && controller.application.current_date.year == element.year) {
              has_provision = true
            }
          })
          if (has_provision) {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Há provisões de pagamento para a competência", 2000)
          }
        }
        controller.getPatients()
      })
  }

  getBookingTeam() {
    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/config/teams/list_booking_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.booking_team = response.data.cln
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTickets() {
    var data = { ticket: { board_id: this.application.booking.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
          controller.getBookingTeam()
          controller.getNotes()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getNotes() {
    var data = { note: { domain_id: this.application.booking.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/notes/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getChartAccounts() {
    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/bookings/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.chart_accounts = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }

        controller.getBooking()
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["medbooking", "medbooking_dates", "medbooking_calculations", "medbooking_patients", "medbooking_patient_contacts", "medbooking_patient_infos",
                          "medbooking_patient_healthcares", "medbooking_patient_accounts", "medbooking_offices", "medbooking_signatures", "medbooking_chart_accounts",
                          "medbooking_receipts", "medbooking_payments", "medbooking_notes", "medbooking_documents","medbooking_provisions", "medbooking_helpers",
                          "medbooking_helper_accounts", "medbooking_helper_contacts", "medbooking_helper_infos", "medbooking_helper_profiles", "medbooking_tickets",
                          "medbooking_comments"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getChartAccounts()
        controller.getControllerByIdentifier("operations--products--bookings--entities--submenu").doSubmenuHtml()
      })
  }

  updateAllReceipts() {
    this.application.receipts.forEach(receipt => {
      this.updateReceipt(receipt)
    });
  }

  updateReceipt(receipt) {
    var sponsor = ``
    var patient = ``
    var office = ``
    var signature = ``
    this.application.patients.forEach(element => {
      if (element.id == receipt.patient_id) {
        patient = element
      }
      if (element.id == receipt.sponsor_id) {
        sponsor = element
      }
    })

    this.application.offices.forEach(element => {
      if (element.id == receipt.office_id) {
        office = element
      }
    })

    this.application.signatures.forEach(element => {
      if (element.id == receipt.signature_id) {
        signature = element
      }
    })

    receipt.doctor_name = this.application.booking.account_name
    receipt.doctor_cpf_pretty = this.application.booking.account_cpf_pretty

    receipt.sponsor_name = sponsor.account_name
    receipt.sponsor_cpf = sponsor.account_cpf
    receipt.sponsor_cpf_pretty = sponsor.account_cpf_pretty

    if (sponsor.account_sex == "male") {
      receipt.sponsor_sex = `do Responsável Financeiro`
    } else if (sponsor.account_sex == "female") {
      receipt.sponsor_sex = `da Responsável Financeira`
    }

    receipt.patient_name = patient.account_name
    receipt.patient_cpf = patient.account_cpf
    receipt.patient_cpf_pretty = patient.account_cpf_pretty

    if (patient.account_sex == "male") {
      receipt.patient_sex = `do Paciente`
    } else if (patient.account_sex == "female") {
      receipt.patient_sex = `da Paciente`
    }

    receipt.office_name = office.name
    receipt.office_street = office.street
    receipt.office_number = office.number
    receipt.office_complement = office.complement
    receipt.office_district = office.district
    receipt.office_city = office.city
    receipt.office_state = office.state
    receipt.office_postal_code_pretty = office.postal_code_pretty

    receipt.signature_name = signature.name
    receipt.signature_title = signature.title
    receipt.signature_number = signature.number

    if (receipt.split > 1) {
      receipt.split_pretty = `${receipt.split} parcelas`
    } else {
      receipt.split_pretty = `1 parcela`
    }

    return receipt
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
