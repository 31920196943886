import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "renewals", "bodyTable", "footerTable", "body", "searchTable", "searchInput", "form"]

  connect() {
    this.controllerName = `users--works--schedules--entities--video-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open")
    this.element.setAttribute("style", "display: block;")
    this.element.classList.add("show")
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open")
    this.element.removeAttribute("style")
    this.element.classList.remove("show")
    document.getElementsByClassName("modal-backdrop")[0].remove()
    this.modalTarget.remove()
  }

  doFormHtml() {
    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Vídeo Agendamento</h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col-12 text-center">
                        <div class="embed-responsive embed-responsive-16by9">
                          <iframe src="${this.current_schedule.midia_link}" width="640" height="480" allow="autoplay"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.bodyTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}