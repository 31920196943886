import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "headTable", "opportunityLeadName",
                    "countOneCheckbox", "countOne", "countTwoCheckbox", "headCard"]

  connect() {
    this.controllerName = `financials--books--cash-flows--compilations--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
    this.hasShow = false
    this.tableCols = 14
    this.doAccounts()
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="headCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover mb-0" style="font-size:80%;">
                          <thead data-${this.controllerName}-target="headTable"></thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" style="font-size:80%;">
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--cash-flows--compilations--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.application.annual_balances = []
      controller.headCardTarget.style.height = ($(window).height() * 0.05) + "px"
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doTableHead()
      controller.doDataTable()
    })
  }

  doTableHead() {

    if (this.application.permissions.financial_statement_cash_flows.can_download) {
      var downloadHtml = `<button class="btn btn-outline my-0 btn-table btn-sm py-2 px-0 mc-tooltip" type="button">
                          <span class="material-icons md-dark" data-action="click->${this.controllerName}#downloadSheet">download</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Baixar Planilha</span>
                        </button>`
    } else {
      var downloadHtml = ``
    }

    this.application.dates.forEach((element, index) => {
      if (this.application.current_date.id == element.id) {
        this.application.dates.slice(index, 12 + index).forEach(element => {
          this.application.annual_balances[this.application.annual_balances.length] = element
        })
      }
    })

    var monthsHtml = ``

    this.application.annual_balances.forEach(element => {
      monthsHtml += `<th scope="col" class="pointer p-1 table-5 align-middle text-center">${element.date_pretty}</th>`
    })
    // style="font-size:80%;"

    var title = `<span class="mc-tooltip">
                    <span data-action="click->${this.controllerName}#refreshCompilation">Fluxo de Caixa</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Atualizar Fluxo de Caixa</span>
                  </span>`

    this.headTableTarget.innerHTML = `<tr>
                                        <th colspan="1" scope="col" class="pointer p-1 align-middle table-5">
                                          ${title}
                                        </th>
                                        <th colspan="1" scope="col" class="pointer p-1 align-middle table-3">
                                          ${downloadHtml}
                                        </th>
                                        ${monthsHtml}
                                      </tr>`

  }

  doDataTable() {
    var accountsHtml = ``
    var monthsHtml = ``
    this.application.annual_balances.forEach(element => {
      monthsHtml += `<th scope="col" class="pointer p-1 table-5 align-middle text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>`
    })

    accountsHtml += `<tr>
                       <th colspan="2" scope="col" class="pointer p-1 align-middle table-8" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>
                       ${monthsHtml}
                     </tr>`

    this.accounts.forEach(accountElement => {
      accountsHtml += this.balanceTablePartial(accountElement)
    })

    this.bodyTableTarget.innerHTML = accountsHtml

  }

  balanceTablePartial(accountElement) {
    var balancesHtml = ``

    var highlight = ``
    var spaceRow = ``
    var borderHighlight = ``
    var separatorRow = ``

    if (accountElement.highlight) {
      highlight = `f-bold`
      spaceRow = `<tr><td colspan="14" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td></tr>`
      borderHighlight = `border-highlight`
    }
    if (accountElement.separator) {
      separatorRow = `<tr><td colspan="14" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td></tr>`
    }

    this.application.annual_balances.forEach(dateElement => {
      if (accountElement.format == `money`) {
        balancesHtml += `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-center ${highlight}">${this.controllerNumber.currencyOptionMask(dateElement.cash_flow_compilation[accountElement.name])}</td>`
      } else if (accountElement.format == `percent`) {
        balancesHtml += `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-center ${highlight}">${this.controllerNumber.percentOptionMask(dateElement.cash_flow_compilation[accountElement.name])}</td>`
      }
      // balancesHtml += `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-center ${highlight}">${this.controllerNumber.currencyOptionMask(10000000)}</td>`
    })

    if (accountElement.showDetail) {
      var accountName = `<td colspan="2" style="font-size:100%;" scope="col" class="p-1 align-middle ${highlight}">
                          <small class="mb-0 f-065 mc-tooltip pointer">
                            <span data-action="click->${this.controllerName}#showDetailModal" data-name="${accountElement.name}">(${accountElement.sign}) ${accountElement.name_pretty}</span>
                            <span class="mc-tooltiptext">Clique para Ver Subcontas</span>
                          </small>
                        </td>`
    } else {
      var accountName = `<td colspan="2" style="font-size:100%;" scope="col" class="p-1 align-middle ${highlight}">(${accountElement.sign}) ${accountElement.name_pretty}</td>`      
    }

    var rowHtml = `${spaceRow}
                  ${separatorRow}
                  <tr class="itemRow ${borderHighlight}">
                    ${accountName} 
                    ${balancesHtml}
                  </tr>
                  ${spaceRow}`

    return rowHtml
  }

  downloadSheet() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, compilation: {} }

    var dateIds = this.mapElements(this.application.annual_balances, `id`)

    this.send_data.compilation.date_ids = dateIds
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-fluxo-caixa-livre`
      this.send_data.compilation.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-fluxo-caixa-livre`
      this.send_data.compilation.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_cash_flows`

    var fileExtension = `csv`
    var url = "/financials/books/cash_flows/compilations/download"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showDetailModal(ev) {
    var modalController = `financials--books--cash-flows--compilations--detail-modal`
    var accountName = ev.currentTarget.dataset.name
    var accountElement = ``
    this.accounts.forEach(element => {
      if (element.name == accountName) {
        accountElement = element
      }
    })

    var html = `<div class="modal fade" data-hour="bottom" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalController}-target="body">
                        <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${modalController}-target="mainCard">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4 card-title-table-list" data-${modalController}-target="titleCard">Detalhamento Fluxo de Caixa</h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${modalController}#close" type="button">
                                <span class="material-icons md-dark">clear</span>
                              </button>
                              <span class="mc-tooltiptext">Fechar</span>
                            </div>
                          </div>
                          <div class="card-body p-0" style="overflow:auto;" data-${modalController}-target="bodyCard"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`



    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      controller.getControllerByIdentifier(modalController).viewMode = `monthly`
      controller.getControllerByIdentifier(modalController).accountElement = accountElement
      controller.getControllerByIdentifier(modalController).open()
    })
  }

  showDetails(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var accountName = ev.currentTarget.dataset.name
    var accountElement = ``
    this.accounts.forEach(element => {
      if (element.name == accountName) {
        accountElement = element
      }
    })

    this.send_data = { current_user: {}, compilation: {} }

    var dateIds = this.mapElements(this.application.annual_balances, `id`)

    this.send_data.compilation.main_account = accountElement.name
    this.send_data.compilation.date_ids = dateIds
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-fluxo-caixa-livre`
      this.send_data.compilation.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-${this.toKebabCase(accountElement.name_pretty)}`
      this.send_data.compilation.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_cash_flows`

    var fileExtension = `csv`
    var url = "/financials/books/cash_flows/compilations/download_subaccounts"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  toKebabCase(str) {
    return this.getControllerByIdentifier(`app--helpers--strings`).toKebabCase(str)
  }

  doAccounts() {
    this.accounts = [
      {
        name: `total_gross_operational_revenues`,
        name_pretty: `Receita Operacional Bruta`,
        showDetail: true,
        sign: `=`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_operational_discounts`,
        name_pretty: `Deduções/Descontos Operacionais`,
        showDetail: false,
        sign: `-`,
        format: `money`,
        highlight: false,
        separator: false,
      },
      {
        name: `total_net_operational_revenues`,
        name_pretty: `Receita Operacional Líquida`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_operational_expenses`,
        name_pretty: `Despesas Operacionais`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_gross_profit`,
        name_pretty: `Lucro Bruto`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_sales_expenses`,
        name_pretty: `Despesas Comerciais`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_administrative_expenses`,
        name_pretty: `Despesas Administrativas`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_general_expenses`,
        name_pretty: `Despesas Gerais`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_operational_profit`,
        name_pretty: `EBITDA`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_depreciation`,
        name_pretty: `Depreciação`,
        showDetail: false,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_profit_before_interests_taxes`,
        name_pretty: `EBIT`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_financial_incomes`,
        name_pretty: `Receitas Não-Operacionais`,
        showDetail: true,
        sign: `+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_financial_expenses`,
        name_pretty: `Despesas Financeiras`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_profit_before_taxes`,
        name_pretty: `Lucro Antes dos Impostos`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_taxes`,
        name_pretty: `Impostos`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_net_profit`,
        name_pretty: `Lucro Líquido`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_depreciation`,
        name_pretty: `Depreciação`,
        showDetail: false,
        sign: `+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_net_working_capital`,
        name_pretty: `VNCCL`,
        showDetail: true,
        sign: `-/+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_operational_cash_flow`,
        name_pretty: `Fluxo de Caixa Operacional`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_asset_profits`,
        name_pretty: `Vendas Bens e Ativos`,
        showDetail: true,
        sign: `+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_asset_investments`,
        name_pretty: `Investimentos`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_investment_cash_flow`,
        name_pretty: `Fluxo de Caixa Investimentos`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_debt_issued`,
        name_pretty: `Contratação Empréstimos`,
        showDetail: true,
        sign: `+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_debt_amortization`,
        name_pretty: `Amortização Empréstimos`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_tax_debt_issued`,
        name_pretty: `Dívidas Tributárias`,
        showDetail: true,
        sign: `+`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_tax_debt_amortization`,
        name_pretty: `Amortização Parcelamentos`,
        showDetail: true,
        sign: `-`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_financing_cash_flow`,
        name_pretty: `Fluxo de Caixa Financeiro`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `total_free_cash_flow`,
        name_pretty: `Fluxo de Caixa Livre`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: true,
      },
      {
        name: `cash_begin_of_period`,
        name_pretty: `Caixa BoP`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: false,
      },
      {
        name: `cash_balance_adjust`,
        name_pretty: `Ajuste Saldo Caixa`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: false,
      },
      {
        name: `cash_end_of_period`,
        name_pretty: `Caixa EoP`,
        showDetail: false,
        sign: `=`,
        format: `money`,
        highlight: false,
      },
      {
        name: `total_operational_discounts_margin`,
        name_pretty: `Margem de Deduções/Descontos`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
        separator: true,
      },
      {
        name: `total_gross_profit_margin`,
        name_pretty: `Margem Lucro Bruto`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_operational_profit_margin`,
        name_pretty: `Margem EBITDA`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_taxes_margin`,
        name_pretty: `Alíquota Impostos`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_net_profit_margin`,
        name_pretty: `Margem Lucro Líquido`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `current_liquidity_index`,
        name_pretty: `Índice de Liquidez Corrente`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
        separator: true,
      },
      {
        name: `immediate_liquidity_index`,
        name_pretty: `Índice de Liquidez Imediata`,
        showDetail: false,
        format: `percent`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_asset`,
        name_pretty: `Ativo`,
        showDetail: false,
        format: `money`,
        sign: `=`,
        highlight: false,
        separator: true,
      },
      {
        name: `total_liability`,
        name_pretty: `Passivo`,
        showDetail: false,
        format: `money`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_revenues`,
        name_pretty: `Receitas`,
        showDetail: false,
        format: `money`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_expenses`,
        name_pretty: `Despesas`,
        showDetail: false,
        format: `money`,
        sign: `=`,
        highlight: false,
      },
      {
        name: `total_result`,
        name_pretty: `Resultado`,
        showDetail: false,
        format: `money`,
        sign: `=`,
        highlight: false,
      },

    ]
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`financials--books--statements--dashboard--main`).refreshCompilation()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
