import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["inputSearch", "btnSearch", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--operations--products--bookings--validations--search`
  }

  doHtml() {

    `<p class="ws-text-action">
      Digite ao lado o código de validação do Recibo do Livro-Caixa
    </p>`

    var html = `<section class="ws-section-principal ws-bg-circle d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex align-items-center col-lg-5 col-sm-12">
                        <div class="columns">
                          <h1 class="ws-title-action">Facilitamos a vida financeira do médico de ponta a ponta.</h1>
                        </div>
                      </div>
                      <div class="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center">
                        <div class="input-group justify-content-center mb-3">
                          <input type="text" class="form-control form-blog" id="inputSearch" placeholder="Digite o código de validação do Recibo do Livro-Caixa" autocomplete="on" data-action="keyup->${this.controllerName}#doSearch" data-${this.controllerName}-target="inputSearch"> 
                          <div class="input-group-append">
                            <button id="btnSearch" class="btn blog-btn" type="button" data-action="click->${this.controllerName}#doSearch" data-${this.controllerName}-target="btnSearch">  
                              <i class="material-icons">search</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--operations--products--bookings--validations--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  doSearch(ev) {
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
      // this.btnSearchTarget.disabled = true
      var search = this.inputSearchTarget.value

      // this.send_data = { query: {}, tracker: { log: true } }
      this.send_data = { query: {} }

      this.send_data.query.token = search

      // this.send_data.tracker.record_id = ``
      // this.send_data.tracker.record_type = `operation_products`
      // this.send_data.tracker.obj_id = ``
      // this.send_data.tracker.obj_type = `operation_dates`
      // this.send_data.tracker.action = `created`
      // this.send_data.tracker.observations = `Consulta ao Recibo Livro-Caixa #${search}`

      this.requestSearch()
    }
  }

  requestSearch(data) {

    const url = "/operations/products/bookings/validations/public_receipt"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.inputSearchTarget.value = ``
        if (response.status === "forbidden") {
          controller.noResults()
        } else {

          if (response.process) {
            var receipt = response.data.cln
            response.message = `Validação com Sucesso. Veracidade confirmada pela MedCapital.
                                Recibo para Paciente ${receipt.patient_name} CPF: ${receipt.patient_cpf_pretty} e
                                Responsável Financeiro ${receipt.sponsor_name} CPF: ${receipt.sponsor_cpf_pretty},
                                realizado no dia ${receipt.date_pretty} no valor de ${this.controllerNumber.currencyOptionMask(receipt.amount)}.`
          } else {
            
          }
          
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response, 20000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  noResults() {
    console.log(`Validação não permitida. Código não permitido!`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
