
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "payableCount", "totalReceivables",
                    "receivableCount", "receivableCountTooltip", "submenuBtn", "totalTickets", "asdf"]

  connect() {
    this.controllerName = `commercial--marketing--events--index--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank')
  }

  goToDashboard() {
    this.getControllerByIdentifier("commercial--marketing--events--index--dashboard").doPageGrid()
    this.getControllerByIdentifier("commercial--marketing--events--index--filter").removeSearchHtml()
  }

  goToDealflow() {
    this.getControllerByIdentifier("commercial--marketing--events--dealflow--dashboard").doFlowGrid()
    this.getControllerByIdentifier("commercial--marketing--events--index--filter").doSearchHtml()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "commercial_marketing_events"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)

    this.getControllerByIdentifier(`commercial--marketing--events--index--submenu`).dashboardToggleBtn(`PERMISSÕES`)
    this.getControllerByIdentifier("commercial--marketing--events--index--filter").removeSearchHtml()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Eventos</button>`

    if (this.application.permissions.commercial_event_permissions.can_assign) {
      this.application.domain_permission = this.application.permissions[`commercial_event_permissions`]
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    if (this.application.permissions.commercial_event_dealflows.can_index) {
      var dealflowBtn = `<button type="button" data-action="${this.controllerName}#goToDealflow ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Pipeline Eventos</button>`
    } else {
      var dealflowBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${dealflowBtn}
                  ${permissionBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  doBadgeCounts() {
    // this.doTicketsCount()
  }

  toggleBtn(ev) {
    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        ticketCount += 1
        if (element.stage == `in_process`) {
          ticketInProcessCount += 1
        }
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketInProcessCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
