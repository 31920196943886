import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewChatCard", "viewTitle", "viewChatCardBody", "mainCard", "elementCard", "9999"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.controllerName = `commercial--marketing--visits--chats--show`
    this.send_data = { current_user: {}, opportunity: {} }
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewChatCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Chat Ativo</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewChatCardBody"></div>
                </div>`

    this.getControllerByIdentifier("commercial--marketing--visits--chats--dashboard").viewTarget.innerHTML = html
  }

  tryReconnect() {

  }

  connectionOn(chatId) {
    this.nameTarget(`connectionIcon-${chatId}`).innerText = `wifi`
    this.nameTarget(`connectionIcon-${chatId}`).classList.add("md-success")
    this.nameTarget(`connectionIcon-${chatId}`).classList.remove("md-danger")
    this.nameTarget(`connectionIcon-${chatId}`).dataset.connection = true
    this.nameTarget(`connectionText-${chatId}`).innerText = "Conectado"
  }

  connectionOff(chatId) {
    this.nameTarget(`connectionIcon-${chatId}`).innerText = `wifi_off`
    this.nameTarget(`connectionIcon-${chatId}`).classList.add("md-danger")
    this.nameTarget(`connectionIcon-${chatId}`).classList.remove("md-success")
    this.nameTarget(`connectionIcon-${chatId}`).dataset.connection = false
    this.nameTarget(`connectionText-${chatId}`).innerText = "Sem Conexão"
  }

  doChatHtml() {
    this[`requestPermission-${this.current_chat.id}`] = true
    this.application[`messages-${this.current_chat.id}`] = []
    if (this.hasViewChatCardTarget) {
      this.viewChatCardTarget.remove()
    }

    var html = `<div class="col-4 px-1 mb-3" data-${this.controllerName}-target="elementCard-${this.current_chat.id} elementCard">
                  <div class="card cardChat" data-${this.controllerName}-target="mainCard-${this.current_chat.id}" data-id="${this.current_chat.id}" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center py-2">
                      <h6 class="card-title mb-0 py-2 f-075 pointer card-show-dropdown" data-action="click->${this.controllerName}#getPageviews">
                        ${this.current_chat.visit_name}
                        <div class="card-show-dropdown-content text-left o-scroll" data-${this.controllerName}-target="pageviewCard-${this.current_chat.id}">
                          <span class="my-2">${this.loader}</span>
                          <p class="my-2">Clique para mais infos</p>
                          <span class="my-2">${this.loader}</span>
                        </div>
                      </h6>
                      <span class="mc-tooltip ml-auto" data-action="click->${this.controllerName}#tryReconnect">
                        <span class="material-icons md-sm pointer" data-${this.controllerName}-target="connectionIcon-${this.current_chat.id}"></span>
                        <span class="mc-tooltiptext" data-${this.controllerName}-target="connectionText-${this.current_chat.id}"></span>
                      </span>
                      <button type="button" class="close ml-3" data-action="click->${this.controllerName}#closeForm" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="messagesCardBody-${this.current_chat.id}">
                      <div class="row my-3">
                        <div class="col p-0">
                          <div class="table-responsive">
                            <table class="table table-chat-sm table-xs table-borderless mb-0" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                              <tbody data-${this.controllerName}-target="messagesBody-${this.current_chat.id}"></tbody>
                            </table>
                          </div>
                          <div data-${this.controllerName}-target="messagesPreloaderBody-${this.current_chat.id}"></div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer py-0 px-0" data-${this.controllerName}-target="footerTable">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-10 px-2 text-left">
                          <textarea autofocus type="text" class="form-control f-065 py-1 px-0 m-0 d-none" placeholder="Digite ..." aria-label="Digite ..." data-${this.controllerName}-target="textarea-${this.current_chat.id}" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount"></textarea>
                          <input type="text" class="form-control f-065 py-1 px-0" placeholder="Digite ..." aria-label="Digite ..." data-${this.controllerName}-target="input-${this.current_chat.id}" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount">
                          <span class="f-065" data-${this.controllerName}-target="characterCount-${this.current_chat.id}">0 caracteres</span>
                        </div>
                        <div class="col-2 px-1 text-center">
                          <span class="mc-tooltip" data-action="click->${this.controllerName}#saveComment" data-${this.controllerName}-target="saveBtn-${this.current_chat.id}">
                            <span class="material-icons md-sm md-primary pointer">send</span>
                            <span class="mc-tooltiptext">Enviar</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--visits--chats--dashboard").viewTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.nameTarget(`mainCard-${controller.current_chat.id}`).style.height = ($(window).height() * 0.35) + "px"
    })
  }

  doMessageTable(chatId) {
    var messages = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application[`messages-${chatId}`], "created_at_to_time")

    this.listMessages(messages, chatId)
  }

  listMessages(data, chatId) {
    this.nameTarget(`messagesBody-${chatId}`).innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="10" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Mensagens</span>
                      </td>
                    </tr>`

      this.nameTarget(`messagesBody-${chatId}`).innerHTML = noData
    } else {
      data.forEach(element => {
        this.nameTarget(`messagesBody-${chatId}`).insertAdjacentHTML("beforeend", this.messageTablePartial(element))
      });
    }
    this.rollMessages(chatId)
  }

  messageTablePartial(element) {

    var hours = new Date(element.created_at).getHours()
    var minutes = new Date(element.created_at).getMinutes()
    if (hours < 10) { hours = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }

    var pin = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#pinMessage" data-${this.controllerName}-target="pinMessageBtn-${element.id}">
                  <span class="material-icons md-sm md-dark pointer">push_pin</span>
                  <span class="mc-tooltiptext">Fixar Mensagem</span>
                </span>`
    if (element.starred) {
      var star = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#starMessage" data-${this.controllerName}-target="starMessageBtn-${element.id}">
                      <span class="material-icons md-sm md-dark pointer">star</span>
                      <span class="mc-tooltiptext">Favoritar Mensagem</span>
                    </span>`
    } else {
      var star = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#unstarMessage" data-${this.controllerName}-target="starMessageBtn-${element.id}">
                      <span class="material-icons md-sm md-dark pointer">star_border</span>
                      <span class="mc-tooltiptext">Remover Favorito</span>
                    </span>`
    }

    pin = ``
    star = ``
                // `<tr>
                //   <td style="height:0px;padding-top:0px;padding-bottom:0px;" class=" table-5"></td>
                //   <td style="height:0px;padding-top:0px;padding-bottom:0px;" class=" table-15"></td>
                //   <td style="height:0px;padding-top:0px;padding-bottom:0px;" class=" table-40"></td>
                //   <td style="height:0px;padding-top:0px;padding-bottom:0px;" class=""></td>
                //   <td style="height:0px;padding-top:0px;padding-bottom:0px;" class=""></td>
                // </tr>
                // <tr>
                //   <td colspan="4" class="p-0"></td>
                // </tr>`
    // `<tr class="chat-line" data-id="${element.id}" data-action="mouseover->${this.controllerName}#showAction mouseout->${this.controllerName}#showAction">`
    var html = `
                <tr>
                  <td class="p-0 text-left align-middle f-065 table-30" style="font-size:50%;">${element.created_at_date_pretty} - ${hours}:${minutes}</td>
                  <td class="p-0 text-left align-middle f-065 table-60" colspan="2" style="font-size:50%;">[${element.sender_name}]</td>
                  <td class="p-0 text-left align-middle f-065 table-5" style="font-size:50%;"></td>
                  <td class="p-0 text-left align-middle f-065 table-5" style="font-size:50%;"></td>
                </tr>
                <tr class="chat-line" data-id="${element.id}">
                  <td colspan="2" class="border-left-primary pl-2 p-1 f-065 pointer align-middle" data-action="click->${this.controllerName}#selectLine">${element.content}</td>
                  <td class="p-0 text-center">${pin}</td>
                  <td class="p-0 text-center">${star}</td>
                </tr>
                <tr>
                  <td colspan="4" class="p-0"></td>
                </tr>`

    return html
  }
  selectLine() {

  }

  saveComment(ev) {
    var chatId = ev.currentTarget.closest(".cardChat").dataset.id
    this.send_data = { current_user: {}, message: {} }
    this.actionMode = `new`

    if (this.count > 250) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && this.count > 0) {

        if (this.count > 70) {
          var content = this.nameTarget(`textarea-${chatId}`).value
        } else {
          var content = this.nameTarget(`input-${chatId}`).value
        }

        this.send_data.message.room_id = chatId
        this.send_data.message.created_at = new Date(),
        this.send_data.message.content = content
        this.send_data.message.starred = false
        // this.send_data.message.status = true
        this.send_data.message.sender_id = this.application.current_user.id
        this.send_data.message.sender_name = this.getControllerByIdentifier("app--visits--users").getNickname(this.application.current_user)
        this.send_data.message.sender_type = `user`
        this.send_data.current_user.current_user_id = this.application.current_user.id

        this.cleanTextBox(chatId)
        if (this[`requestPermission-${this.current_chat.id}`]) {
          this.request(false, chatId)
          // this.insertMessagesHtml(this.send_data.message, chatId)
          this.application[`chatChannel-${chatId}`].send(this.send_data)
        }
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        this.nameTarget(`textarea-${chatId}`).value = ``
        this.nameTarget(`input-${chatId}`).value = ``
      }
    }
  }

  insertMessagesHtml(message, chatId) {
    this.application[`messages-${chatId}`][this.application[`messages-${chatId}`].length] = message
    if (this.nameTarget(`loader-${chatId}`)) {
      this.nameTarget(`loader-${chatId}`).remove()
    }
    var html = this.messageTablePartial(message)
    this.nameTarget(`messagesBody-${chatId}`).insertAdjacentHTML("beforeend", html)
    this.rollMessages(chatId)
    this.request(true, chatId)
  }

  doPageviewCard(chat) {
    var html = ``
    html += `<p class="mb-0 f-065">LP: ${chat.pageviews[0].landing_page}</p>`
    chat.pageviews.forEach(element => {
      html += `<p class="mb-0 f-065">${element.path}</p>`
    })

    this.nameTarget(`pageviewCard-${chat.id}`).innerHTML = html
    this.nameTarget(`pageviewCard-${chat.id}`).style.height = ($(window).height() * 0.20) + "px"
  }

  getPageviews(ev) {
    var chatId = ev.currentTarget.closest(".cardChat").dataset.id
    
    this.application.chats.forEach(element => {
      if (element.id == chatId) {
        this.current_pageview_chat = element
      }
    })
    
    this.send_data = { pageview: {}, current_user: {} }

    this.send_data.pageview.visitor_id = this.current_pageview_chat.obj_id
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestPageviews()

  }

  requestPageviews() {
    const url = "/commercial/marketing/visits/pageviews/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.current_pageview_chat.pageviews = response.data.cln
          controller.doPageviewCard(this.current_pageview_chat)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getMessages(chatId) {
    var data = { room: { id: chatId } }
    const url = "/commercial/marketing/visits/messages/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application[`messages-${chatId}`] = response.data.cln
          controller.doMessageTable(chatId)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  request(permission, chatId) {
    this[`requestPermission-${this.current_chat.id}`] = permission
    if (permission) {
      this.nameTarget(`saveBtn-${chatId}`).classList.add("md-primary")
      this.nameTarget(`saveBtn-${chatId}`).classList.remove("md-dark")
    } else {
      this.nameTarget(`saveBtn-${chatId}`).classList.remove("md-primary")
      this.nameTarget(`saveBtn-${chatId}`).classList.add("md-dark")
    }
  }

  cleanTextBox(chatId) {
    this.count = 0
    this.nameTarget(`input-${chatId}`).value = ``
    this.nameTarget(`textarea-${chatId}`).value = ``
    this.nameTarget(`textarea-${chatId}`).classList.add("d-none")
    this.nameTarget(`input-${chatId}`).classList.remove("d-none")
    this.nameTarget(`input-${chatId}`).focus()
    this.nameTarget(`characterCount-${chatId}`).textContent = `0 caracteres`
  }

  characterCount(ev) {
    this.count = ev.target.value.length
    var chatId = ev.currentTarget.closest(".cardChat").dataset.id

    if (this.count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${this.count} caracteres`
    }

    this.nameTarget(`characterCount-${chatId}`).textContent = message

    if (this.count > 250) {
      this.nameTarget(`characterCount-${chatId}`).classList.add("text-danger")
    } else {
      this.nameTarget(`characterCount-${chatId}`).classList.remove("text-danger")
    }

    this.nameTarget(`input-${chatId}`).value = ev.target.value
    this.nameTarget(`textarea-${chatId}`).value = ev.target.value
    if (this.count > 50) {
      this.nameTarget(`input-${chatId}`).classList.add("d-none")
      this.nameTarget(`textarea-${chatId}`).classList.remove("d-none")
      this.nameTarget(`textarea-${chatId}`).focus()
    } else {
      this.nameTarget(`textarea-${chatId}`).classList.add("d-none")
      this.nameTarget(`input-${chatId}`).classList.remove("d-none")
      this.nameTarget(`input-${chatId}`).focus()
    }
  }

  rollMessages(chatId) {
    $(this.nameTarget(`messagesCardBody-${chatId}`)).stop().animate({ scrollTop: $(this.nameTarget(`messagesCardBody-${chatId}`))[0].scrollHeight }, 1000);
  }

  closeForm(ev) {
    var chatId = ev.currentTarget.closest(".cardChat").dataset.id
    this.nameTarget(`elementCard-${chatId}`).remove()

    this.application.openChatIds.splice(this.application.openChatIds.indexOf(chatId), 1)

    if (this.hasElementCardTarget == false) {
      this.doViewHtml()
    }
  }

  pinMessage(ev) {
    var id = ev.currentTarget.dataset.id
  }

  starMessage(ev) {
    var id = ev.currentTarget.dataset.id
  }

  unstarMessage(ev) {

    var id = ev.currentTarget.dataset.id
  }


  showAction(ev) {
    var id = ev.currentTarget.dataset.id
    if (ev.type == `mouseover`) {
      this.nameTarget(`pinMessageBtn-${id}`).classList.remove("d-none")
      this.nameTarget(`starMessageBtn-${id}`).classList.remove("d-none")
    } else if (ev.type == `mouseout`) {
      this.nameTarget(`pinMessageBtn-${id}`).classList.add("d-none")
      this.nameTarget(`starMessageBtn-${id}`).classList.add("d-none")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

  requestSave() {
    var url = "/operations/messages/entities/create"
    var method = "POST"
    this.request(false, this.send_data.message.room_id)
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {

          // var message = response.data.cln
          // if (controller.actionMode == `new`) {
          //   controller.application.messages[controller.application.messages.length] = message
          // } else {
          //   if (message.active) {
          //     controller.application.messages.forEach((element, i) => {
          //       if (element.id == message.id) {
          //         controller.application.messages.splice(controller.application.messages.indexOf(element), 1, message)
          //       }
          //     })
          //   } else {
          //     controller.application.messages.forEach((element, i) => {
          //       if (element.id == message.id) {
          //         controller.application.messages.splice(controller.application.messages.indexOf(element), 1)
          //       }
          //     })
          //   }
          // }
          // controller.doMessageTable()
        }
        controller.request(true, controller.send_data.message.room_id)
        // controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

}
