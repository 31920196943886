import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "view"]

  connect() {
    this.controllerName = `financials--products--purchases--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.application.policies = []
    this.application.commissions = []
    this.application.purchases = []
    this.application.accounts = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--products--purchases--dashboard--index
                                                            financials--products--commissions--entities--dashboard
                                                            financials--products--commissions--policies--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("financials--products--purchases--dashboard--index").doPageGrid()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.stopLoadRefreshing()
    this.getControllerByIdentifier(`financials--products--purchases--dashboard--index`).doIndexListHtml()
    this.getControllerByIdentifier(`financials--products--purchases--dashboard--index`).doCalculation()
    if (this.reload) {
      var response = { type: `success`, message: `Contratações Atualizadas!` }
    } else {
      var response = { type: `success`, message: `Página carregada com sucesso!` }
      this.getControllerByIdentifier(`financials--products--purchases--dashboard--submenu`).doSubmenuHtml()
    }

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getPurchases() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contratações`, value: 45 })
    this.refreshLoadValue(45)

    const data = { purchase: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_entities` } }
    const url = "/financials/products/purchases/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.purchases = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPolicies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Políticas de Comissão`, value: 40 })

    const data = { policy: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_commission_policies` } }
    const url = "/financials/products/commissions/policies/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.policies = response.data.cln

        controller.getPurchases()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCommissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Comissões`, value: 35 })

    if (this.application.permissions.financial_commission_entities.can_manage) {
      var data = { commission: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_commission_entities` } }
      var url = "/financials/products/commissions/entities/list"
    } else {
      var data = { commission: { active: true, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_commission_entities` } }
      var url = "/financials/products/commissions/entities/list_by_account"
    }
    
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.commissions = response.data.cln

        if (controller.application.permissions.financial_commission_policies.can_manage) {
          controller.getPolicies()
        } else {
          controller.getPurchases()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentFinancialDate() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competência`, value: 25 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/financials/config/dates/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.current_date = response.data.cln
        controller.getCommissions()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canal de Broadcast`, value: 20 })
    var broadcastController = this.getControllerByIdentifier("financials--products--purchases--dashboard--broadcast")

    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const url = "/financials/config/broadcasts/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          broadcastController.update_room = response.data.cln.update
          broadcastController.broadcastUpdateChannel()
        }

        controller.getCurrentFinancialDate()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomains: [`financial_purchases`, `financial_commissions`] }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domains"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getRoom()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshLoadValue(value) {
    var controller = this
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contratações`, value: value })
      value += 1
      if (value >= 95) {
        controller.stopLoadRefreshing()
        window.location.reload()
      }
    }, 200);
  }

  stopLoadRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  goToDashboard() {
    this.doPageGrid()
    this.doCalculation()
    this.doIndexListHtml()
  }

  goToRecords() {
    var url = `/a/todas-contratacoes`
    window.open(url, `_self`)
  }

  doCalculation() {
    this.calculation.total_to_send_contract = 0
    this.calculation.setup_to_send_contract = 0
    this.calculation.monthly_to_send_contract = 0
    this.calculation.yearly_to_send_contract = 0
    this.calculation.total_sent_contract = 0
    this.calculation.setup_sent_contract = 0
    this.calculation.monthly_sent_contract = 0
    this.calculation.yearly_sent_contract = 0
    this.calculation.total_signed_contract = 0
    this.calculation.setup_signed_contract = 0
    this.calculation.monthly_signed_contract = 0
    this.calculation.yearly_signed_contract = 0
    this.calculation.total_to_send_invoice = 0
    this.calculation.setup_to_send_invoice = 0
    this.calculation.monthly_to_send_invoice = 0
    this.calculation.yearly_to_send_invoice = 0
    this.calculation.total_sent_invoice = 0
    this.calculation.setup_sent_invoice = 0
    this.calculation.monthly_sent_invoice = 0
    this.calculation.yearly_sent_invoice = 0
    this.calculation.total_paid_invoice = 0
    this.calculation.setup_paid_invoice = 0
    this.calculation.monthly_paid_invoice = 0
    this.calculation.yearly_paid_invoice = 0

    this.application.purchases.forEach(purchase => {
      if (purchase.journey_status == `to_send_contract`) {
        this.calculation.total_to_send_contract += 1
        this.calculation.setup_to_send_contract += Number(purchase.total_setup)
        this.calculation.monthly_to_send_contract += Number(purchase.total_monthly)
        this.calculation.yearly_to_send_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `sent_contract`) {
        this.calculation.total_sent_contract += 1
        this.calculation.setup_sent_contract += Number(purchase.total_setup)
        this.calculation.monthly_sent_contract += Number(purchase.total_monthly)
        this.calculation.yearly_sent_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `signed_contract`) {
        this.calculation.total_signed_contract += 1
        this.calculation.setup_signed_contract += Number(purchase.total_setup)
        this.calculation.monthly_signed_contract += Number(purchase.total_monthly)
        this.calculation.yearly_signed_contract += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `to_send_invoice`) {
        this.calculation.total_to_send_invoice += 1
        this.calculation.setup_to_send_invoice += Number(purchase.total_setup)
        this.calculation.monthly_to_send_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_to_send_invoice += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `sent_invoice`) {
        this.calculation.total_sent_invoice += 1
        this.calculation.setup_sent_invoice += Number(purchase.total_setup)
        this.calculation.monthly_sent_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_sent_invoice += Number(purchase.total_yearly)
      } else if (purchase.journey_status == `paid_invoice`) {
        this.calculation.total_paid_invoice += 1
        this.calculation.setup_paid_invoice += Number(purchase.total_setup)
        this.calculation.monthly_paid_invoice += Number(purchase.total_monthly)
        this.calculation.yearly_paid_invoice += Number(purchase.total_yearly)
      }

    })

    this.doIndicatorsHtml()
  }


  // to_send_contract
  // sent_contract
  // signed_contract
  // to_send_invoice
  // sent_invoice
  // paid_invoice
  // finished
  // cancel

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
