import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "multipleCheck",
                    "clearListBtn", "optionBtn", "bindSum", "payableCount", "payBtn", "masterMultipleCheck", "masterMultipleCheckDiv",
                    "searchInput"]

  connect() {
    this.controllerName = `financials--books--payables--entities--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 14
    this.sort = { mode: `asc`, field: `due_date_to_time` }
    this.hasCheck = false
    this.dayFilter = false
  }

  addPayable() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = {}
      this.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  editPayable(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.payables.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = element
        this.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "edit"
        this.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
      }
    })
  }

  // uploadPayable(ev) {
  //   var id = ev.currentTarget.closest(".itemRow").dataset.id
  //   this.application.payables.forEach(element => {
  //     if (element.id == id) {
  //       this.getControllerByIdentifier("financials--books--payables--entities--upload").current_payable = element
  //       this.getControllerByIdentifier("financials--books--payables--entities--upload").actionMode = "edit"
  //       this.getControllerByIdentifier("financials--books--payables--entities--upload").doFormHtml()
  //     }
  //   })
  // }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    // `<th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
    //   Fornecedor
    //   <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="provider_name" data-mode="desc">south</span>
    //   <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="provider_name" data-mode="asc">north</span>
    // </th>`

    var notPaid = `<span class="mc-tooltip badge badge-secondary-dark pointer" data-action="click->${this.controllerName}#filterNotPaid">
                      <span class="material-icons md-sm">money_off_csred</span>
                      <span class="mc-tooltiptext">Filtrar Não Pagos</span>
                    </span>`

    if (this.application.permissions.financial_payable_entities.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addPayable" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contas à Pagar</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_payable_entities.can_list) {
      var refreshLine = `<span data-action="click->${this.controllerName}#refreshPayable" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contas à Pagar</span>`
    } else {
      var refreshLine = ``
    }

    if (this.application.permissions.financial_payable_entities.can_delete) {
      var deleteLine = `<span data-action="click->${this.controllerName}#checkForDeletePayables" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Múltiplos Pagamentos</span>`
    } else {
      var deleteLine = ``
    }

    if (this.application.permissions.financial_payable_entities.can_manage) {
      var payLine = `<span data-action="click->${this.controllerName}#checkToPayPayables" class="dropdown-item py-1 pointer dropdown-submenu-item">Pagar Múltiplos Pagamentos</span>`
    } else {
      var payLine = ``
    }

    var bindSumLine = `<span data-action="click->${this.controllerName}#checkForBindSum" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Pagamentos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                          ${bindSumLine}
                          ${payLine}
                          ${deleteLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <div class="custom-control custom-checkbox px-2 d-none" data-${this.controllerName}-target="masterMultipleCheckDiv">
                      <input type="checkbox" class="custom-control-input" id="masterCheck" data-${this.controllerName}-target="masterMultipleCheck" data-action="click->${this.controllerName}#checkAll">
                      <label class="custom-control-label f-065" for="masterCheck"></label>
                    </div>
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="payableCount">Todos os Pagamentos | ${this.application.current_med.name}</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    ${notPaid}
                    <div class="card-actions py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Med</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Lanc.
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="accrual_date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="accrual_date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Venc.
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="due_date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Pgto
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="paid_date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="paid_date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Conta
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">Método</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Valor</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--entities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.hasMainCardTarget) {
        controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
        controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
        controller.doDataTable()
      }
    })
  }

  doDataTable() {
    this.current_payables = []
    this.current_filter_payables = []

    // this.application.payables.forEach(element => {
    //   if (element.due_date_id == this.application.current_date.id) {
    //     this.current_payables[this.current_payables.length] = element
    //   }
    // })

    this.application.payables.forEach(element => {
      if (this.application.current_med.id) {
        if (element.due_date_id == this.application.current_date.id && element.med_id == this.application.current_med.id) {
          this.current_payables[this.current_payables.length] = element
        }
      } else {
        if (element.due_date_id == this.application.current_date.id) {
          this.current_payables[this.current_payables.length] = element
        }
      }

      if (this.hasFilterNotPaid) {
        this.current_filter_payables_not_paid = []

        this.current_payables.forEach(element => {
          if (element.paid == false) {
            this.current_filter_payables_not_paid[this.current_filter_payables_not_paid.length] = element
          }
        })

        this.current_payables = this.current_filter_payables_not_paid
      }
    })

    if (this.dayFilter) {
      if (this.application.current_date.month < 10) {
        var currentMonth = `0${this.application.current_date.month}`
      } else {
        var currentMonth = `${this.application.current_date.month}`
      }

      if (this.current_day < 10) {
        var currentDay = `0${this.current_day}`
      } else {
        var currentDay = `${this.current_day}`
      }

      var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

      this.current_payables.forEach(element => {
        if (element.due_date_token == currentToken) {
          this.current_filter_payables[this.current_filter_payables.length] = element
        }
      })

      if (this.hasFilterNotPaid) {
        this.current_filter_payables_not_paid = []

        this.current_filter_payables.forEach(element => {
          if (element.paid == false) {
            this.current_filter_payables_not_paid[this.current_filter_payables_not_paid.length] = element
          }
        })

        this.current_payables = this.current_filter_payables_not_paid
      } else {
        this.current_payables = this.current_filter_payables
      }

    }

    this.hasFilterNotPaid = false

    if (this.sort.mode == `asc`) {
      var payables = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_payables, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var payables = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_payables, this.sort.field)
    }

    if (payables.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(payables.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (payables[x] !== undefined) {
            internPage[internPage.length] = payables[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: payables.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }

    this.setTotalPayables()
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var message = ``
      if (this.dayFilter) {
        message = `Não há Pagamentos com Vencimento em ${this.current_day}/${this.application.current_date.short_due_name}`
      } else {
        message = `Não há Pagamentos com ${this.application.current_date.due_name}`
      }
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.payableTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  payableTablePartial(element, length) {

    // verificar se pagamento vence no dia
    // if (element.journeys[0] && element.journeys[0].stage_alert) {
    //   var alert = `<div class="col-1 px-0 mc-tooltip pointer">
    //                 <span class="material-icons md-sm md-danger">warning</span>
    //                 <span class="mc-tooltiptext">Atenção! Oportunidade vai esfriar!</span>
    //               </div>`
    // } else {
    //   var alert = ``
    // }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `destroy_payables`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `pay_payables`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `sum_payables`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    if (this.application.permissions.financial_payable_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editPayable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }
    var edit = ``

    if (this.application.permissions.financial_payable_entities.can_update) {
      var file = `<button data-action="click->${this.controllerName}#uploadPayable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">attach_file</span>
                    <span class="mc-tooltiptext">NF/Comprovante</span>
                  </button>`
    } else {
      var file = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.paid) {
      var paid = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-sm md-white default">price_check</span>
                      <span class="mc-tooltiptext">Pago</span>
                    </span>`
    } else {
      var paid = `<span class="mc-tooltip badge badge-danger">
                    <span class="material-icons md-sm md-white default">money_off_csred</span>
                    <span class="mc-tooltiptext">Não Pago</span>
                  </span>`
    }

    if (element.method == `bank_slip`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `credit_card`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `transfer`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `bank_debt`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    } else if (element.method == `cash`) {
      var method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${element.method_pretty}</span>
                    </span>`
    }

    if (element.method == `bank_slip`) {
      var barcode = `<span class="mc-tooltip grab">
                      <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${element.bank_line}" data-action="click->app--helpers--copy#copy">align_vertical_center</span>
                      <span class="mc-tooltiptext">Copiar Código de Barras</span>
                    </span>`
    } else if (element.method == `transfer`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">Transferência: ${element.bank_account}</span>
                    </span>`
    } else if (element.method == `pix`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">pix</span>
                      <span class="mc-tooltiptext">PIX: ${element.pix_kind_pretty} | ${element.pix_key}</span>
                    </span>`
    } else if (element.method == `credit_card`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">credit_card</span>
                      <span class="mc-tooltiptext">Cartão: ${element.channel_name}</span>
                    </span>`
    } else if (element.method == `bank_debt`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">Conta: ${element.channel_name}</span>
                    </span>`
    } else if (element.method == `cash`) {
      var barcode = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">Caixa/Dinheiro</span>
                    </span>`
    }

    // <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${method}</td>
    // <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.provider_name}</td>
    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">${element.accrual_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">${element.due_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">${element.paid_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">
                      <small class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.subkind_pretty}" data-action="click->app--helpers--copy#copy">${element.subkind_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">
                      <small class="mb-0 f-065 mc-tooltip">
                        <span>${element.description}</span>
                        <span class="mc-tooltiptext">${element.provider_name}</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPayable">
                      <small class="mb-0 f-065 mc-tooltip">
                        <span>${element.chart_name}</span>
                        <span class="mc-tooltiptext">${element.channel_name}</span>
                      </small>
                    </td>
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${barcode}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${paid}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${file}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-4 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  setTotalPayables() {
    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (this.current_day < 10) {
      var currentDay = `0${this.current_day}`
    } else {
      var currentDay = `${this.current_day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    var current_payables = []
    this.application.payables.forEach(element => {
      if (this.current_day) {
        if (element.due_date_token == currentToken) {
          current_payables[current_payables.length] = element
        }
      } else {
        if (element.due_date_id == this.application.current_date.id) {
          current_payables[current_payables.length] = element
        }
      }
    })

    var totalPayables = 0
    var paidPayables = 0
    current_payables.forEach(element => {
      totalPayables += 1
      if (element.paid) {
        paidPayables += 1
      }
    })

    this.payableCountTarget.innerText = `Todas as Contas à Pagar ${totalPayables}/${paidPayables} Contas Pagas | ${this.application.current_med.name}`
    this.getControllerByIdentifier("financials--books--payables--dashboard--submenu").doBadgeCounts()
  }

  checkAll(ev) {
    var checkElement = ev.currentTarget
    
    this.multipleCheckTargets.forEach(element => {
      if (this.searchInputTarget.value == ``) {
        element.checked = checkElement.checked
      } else {
        if (element.closest(`.itemRow`).classList.contains(`filter-row`)) {
          element.checked = checkElement.checked
        }
      }
    })
    
    this.bindSum()
  }

  doCheckAll(checked) {
    
  }

  bindSum() {
    var bindSum = 0
    var payablesArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        payablesArray[payablesArray.length] = Number(check.dataset.id)
      }
    });

    this.application.payables.forEach(payable => {
      if (payablesArray.includes(payable.id)) {
        bindSum += Number(payable.amount)
      }
    })

    this.bindSumTarget.innerText = `Valor Total dos Pagamentos Selecionados: ${this.controllerNumber.currencyOptionMask(bindSum)}`
  }

  clearList() {
    this.masterMultipleCheckDivTarget.classList.add(`d-none`)
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  checkToPayPayables() {
    this.kind = `pay_payables`
    this.doDataTable()
    this.doPayMultiplePayablesFooter()

    this.masterMultipleCheckDivTarget.classList.remove(`d-none`)
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForDeletePayables() {
    this.kind = `destroy_payables`
    this.doDataTable()
    this.doDestroyMultiplePayablesFooter()

    this.masterMultipleCheckDivTarget.classList.remove(`d-none`)
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForBindSum() {
    this.kind = `sum_payables`
    this.doDataTable()

    this.masterMultipleCheckDivTarget.classList.remove(`d-none`)
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  doDestroyMultiplePayablesFooter() {
    // var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065 pointer text-right w-100" data-mode="delete" data-action="click->${this.controllerName}#setPayables" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    var deleteBtnHtml = `<div class="row">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-8 d-flex justify-content-end">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065 pointer text-right w-100" data-mode="delete" data-action="click->${this.controllerName}#setPayables" data-${this.controllerName}-target="deleteBtn">Apagar</button>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = deleteBtnHtml
  }

  doPayMultiplePayablesFooter() {
    var payBtnHtml = `<div class="row">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-8 d-flex justify-content-end">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <button type="button" class="btn btn-flat-primary btn-sm py-2 btn-table f-065 pointer text-right w-100" data-mode="pay" data-action="click->${this.controllerName}#setPayables" data-${this.controllerName}-target="payBtn">Pagar</button>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = payBtnHtml
  }

  setPayables(ev) {
    this.action = ev.currentTarget.dataset.mode
    
    if (this.application.current_date.open) {

      if (this.action == `delete`) {
        var confirmationMessage = `Tem certeza que deseja Apagar os Pagamentos selecionados?`
        var method = `DELETE`
        this.url = "/financials/books/payables/entities/destroy"
      } else if (this.action == `pay`) {
        var confirmationMessage = `Tem certeza que deseja Pagar os Pagamentos selecionados?`
        var method = `PUT`
        this.url = "/financials/books/payables/entities/update_paid"
      }


      var r = confirm(confirmationMessage)
      if (r) {

        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.requests = []
        var payablesArray = []
        this.multipleCheckTargets.forEach(check => {
          if (check.checked) {
            payablesArray[payablesArray.length] = check.dataset.id
          }
        })

        for (var index = 0; index < payablesArray.length; index++) {
          this.send_data = { current_user: {}, payable: {} }
          if (this.action == `delete`) {
            this.send_data.payable.id = payablesArray[index]
            this.send_data.payable.active = false
          } else if (this.action == `pay`) {
            var current_payable = {}
            this.application.payables.forEach((element, i) => {
              if (element.id == payablesArray[index]) {
                current_payable = element
              }
            })

            this.send_data.payable.id = current_payable.id
            this.send_data.payable.paid = true
            this.send_data.payable.paid_date = current_payable.due_date
            this.send_data.payable.channel_id = current_payable.channel_id
            this.send_data.payable.channel_name = current_payable.channel_name
            this.send_data.payable.amount = current_payable.amount
            this.send_data.payable.interests = current_payable.interests
            this.send_data.payable.fines = current_payable.fines
            this.send_data.payable.total_amount = current_payable.total_amount

            this.send_data.tracker = {}
            this.send_data.tracker.log = true
            this.send_data.tracker.record_id = current_payable.id
            this.send_data.tracker.record_type = `financial_payables`
            this.send_data.tracker.action = `paid`
            this.send_data.tracker.observations = `Pagamento ${current_payable.description} pago, sendo Principal: ${current_payable.amount}, Juros: ${current_payable.interests} e Total: ${current_payable.total_amount}`
          }

          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `financial_payable_entities`

          var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
          this.requests[this.requests.length] = init
        }

        this.requestNext()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  requestNext() {
    var controller = this

    if (this.requests.length) {
      fetch(this.url, this.requests.shift())
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var payable = response.data.cln
            controller.application.payables.forEach((element, i) => {
              if (element.id == payable.id) {
                if (this.action == `delete`) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
                } else if (this.action == `pay`) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
                }
              }
            })

            controller.requestNext()
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      var response = { type: `success`, message: `Processo Finalizado` }
      this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      this.clearList()
    }
  }

  refreshPayable() {
    var element = this.bodyTableTarget
    var cols = this.tableCols
    this.dayFilter = false
    this.getControllerByIdentifier(`financials--books--payables--entities--days`).dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })
    this.getControllerByIdentifier(`financials--books--payables--dashboard--main`).refreshPayable(element, cols)
  }

  showPayable(ev) {
    var payableId = ev.target.closest(".itemRow").dataset.id
    
    var html = `<div class="modal fade" data-controller="financials--books--payables--entities--show-modal" data-financials--books--payables--entities--show-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--payables--entities--show-modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.application.payables.forEach(payable => {
        if (payable.id == payableId) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).current_payable = payable
        }
      })
      controller.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).open()
    })
  }

  uploadPayable(ev) {
    var payableId = ev.currentTarget.closest(".itemRow").dataset.id
    
    var html = `<div class="modal fade" data-controller="financials--books--payables--entities--upload-modal" data-financials--books--payables--entities--upload-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-ml" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--payables--entities--upload-modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.application.payables.forEach(payable => {
        if (payable.id == payableId) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--upload-modal`).current_payable = payable
          controller.getControllerByIdentifier(`financials--books--payables--entities--upload-modal`).open()
        }
      })
    })
  }

  filterNotPaid() {
    this.hasFilterNotPaid = true
    this.doDataTable()
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
