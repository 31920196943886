import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["validGroup", "cpfInput", "userPassword", "signInBtn", "cpfErrorInput", "main", "passwordInput", "signInBtn", "passwordLength"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--users--auth--passwords--forgotten`
    this.canValidateCpf = true
    this.valid_cpf = false

    this.doHtml()
  }

  doHtml() {

    var html = `<div class="col-12 d-flex align-items-center justify-content-center" style=" position: initial; height:90%;background-color:#FBFCFF;">
                  <div class="row">
                    <div class="col-12 text-center py-4 my-4">
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" style="width: 25rem;" alt="Logo MedCapital">
                      
                    </div>
                    <div class="col-12 text-center">
                      <h2 class="s-title-2rem s-title-053B5E">Facilitando sua vida financeira de ponta a ponta</h2>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <p class="mws-text-action">Recupere a sua Senha</p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <p class="text-muted"><small>Se esqueceu a sua senha, não tem problemas, nos informe o seu CPF:</small></p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <div class="row">
                    <div class="col-12 text-center mb-3">
                      <div class="forms-group">
                        <div class="floating-label">
                          <input data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask
                                                                                            keypress->${this.controllerName}#cpfMask
                                                                                            keyup->${this.controllerName}#cpfValidate
                                                                                            blur->${this.controllerName}#cpfValidate
                                                                                            focus->${this.controllerName}#cleanValidations
                                                                                            keyup->${this.controllerName}#validateCpf
                                                                                            keyup->${this.controllerName}#sendNewPassword
                                                                                            blur->${this.controllerName}#validateCpf" type="tel" class="form-no-border ws-input-form" placeholder="Digite seu CPF" required>
                          <span class="material-icons ws-input-icon">account_circle</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-center mb-3" data-${this.controllerName}-target="cpfErrorInput"></div>
                    <div class="col-12 text-center mb-3">
                      <p class="text-muted text-center"><small><a class="a-white" href="/entrar">Fazer Login</a></small></p>
                    </div>
                    
                    <div class="col-12 text-center mb-3">
                      <a class="ws-button-disabled mx-auto default" data-${this.controllerName}-target="signInBtn" data-action="click->${this.controllerName}#sendNewPassword">Recuperar Minha Senha</a>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.cpfInputTarget.focus()

      controller.refreshSignInBtn()
      controller.getAfterLogin()
    })
  }

  validateCpf(ev) {
    if (this.canValidateCpf && ((ev.type == "keyup" && ev.target.value.length == 14) || ev.type == "blur")) {
      this.canValidateCpf = false
      this.send_data = { validation: {} }

      this.send_data.validation.value = this.controllerNumber.fromCpfToNumber(this.cpfInputTarget.value)

      var url = "/api/v1/users/sessions/logins/validate"
      var method = "POST"

      var controller = this
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          controller.valid_cpf = response.valid

          if (response.valid) {

          } else {
            controller.cpfInputTarget.classList.add(`ws-invalid-field`)
            controller.cpfErrorInputTarget.innerHTML = response.message
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
          }

          controller.canValidateCpf = true


        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    } else {
      this.cleanValidations(ev)
    }
  }

  sendNewPassword(ev) {
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && ev.target.value.length == 14) || ev.type == "click") {
      this.stopRefreshing()
      this.signInBtnTarget.disabled = true
      var cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)

      var url = "/users/update_password_public"
      var method = "PUT"
      var data = { user: { cpf: cpf } }
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.password) {
            window.open(`/descanso-medico`, `_self`)
          } else {
            controller.signInBtnTarget.disabled = false
          }

          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    }
  }

  cpfMask(ev) {
    this.controllerNumber.changeNumberToCpf(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.controllerNumber.fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 2000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  refreshSignInBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.valid_cpf == false) {
        len += 1
      }

      if (len == 0) {
        controller.toggleSignInBtn(true)
      } else {
        controller.toggleSignInBtn(false)
      }
    }, 200);
  }

  toggleSignInBtn(value) {
    this.signInBtnTarget.dataset.valid = value

    if (value) {
      this.signInBtnTarget.classList.remove(`default`)
      this.signInBtnTarget.classList.add(`pointer`)

      this.signInBtnTarget.classList.remove(`ws-button-disabled`)
      this.signInBtnTarget.classList.add(`ws-button`)
    } else {
      this.signInBtnTarget.classList.add(`default`)
      this.signInBtnTarget.classList.remove(`pointer`)

      this.signInBtnTarget.classList.add(`ws-button-disabled`)
      this.signInBtnTarget.classList.remove(`ws-button`)
    }

  }

  cleanValidations(ev) {
    ev.target.classList.remove("invalid-field")

    this.cpfErrorInputTarget.innerHTML = ``
    this.toggleSignInBtn(false)
    this.cpfInputTarget.classList.remove(`ws-invalid-field`)
    // while (ev.target.nextElementSibling) {
    //   ev.target.nextElementSibling.remove()
    // }
  }

  getAfterLogin() {
    if (window.location.href.split("?")[1]) {
      if (window.location.href.split("?")[1].split("=")) {
        this.afterLogin = decodeURIComponent(window.location.href.split("?")[1].split("=")[1])
      }
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  fakeAction() { }

}
