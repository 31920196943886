
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["ticketCard", "noteCard", "ticketRow", "noteRow", "accountCard", "accountRow", "clientCard", "clientRow",
                    "cardRow", "cardBtn", "logRow", "logCard", "renewalCard", "renewalRow"]

  connect() {
    this.controllerName = `financials--books--clients--entities--show`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doMenuHtml() {

    // `<div class="card bg-primary pointer" data-${this.controllerName}-target="ticketCard" data-feature="ticket" data-action="click->${this.controllerName}#toggleCard">`
    // `<div class="card pointer" data-${this.controllerName}-target="noteCard" data-feature="note" data-action="click->${this.controllerName}#toggleCard">`

    var html = `<div class="row mb-3">
                  <div class="col-2 px-2">
                    <div class="card bg-primary pointer" data-${this.controllerName}-target="clientCard cardBtn" data-feature="client" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Cliente</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="accountCard cardBtn" data-feature="account" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Contas</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="renewalCard cardBtn" data-feature="renewal" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Renovações</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card bg-light-primary" data-${this.controllerName}-target="ticketCard cardBtn" data-feature="ticket">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card bg-light-primary" data-${this.controllerName}-target="noteCard cardBtn" data-feature="note">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="logCard cardBtn" data-feature="log" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Logs</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-0">
                    <div class="row" data-controller="financials--books--clients--entities--save" data-${this.controllerName}-target="clientRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--contracts--tickets" data-${this.controllerName}-target="ticketRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--renewals--list" data-${this.controllerName}-target="renewalRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--contracts--notes" data-${this.controllerName}-target="noteRow cardRow"></div>
                    <div class="row d-none" data-controller="financials--books--clients--accounts--dashboard" data-${this.controllerName}-target="accountRow cardRow"></div>
                    <div class="row d-none" data-controller="app--stats--trackers--logs--dashboard" data-${this.controllerName}-target="logRow cardRow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).current_contract = controller.current_contract
      // controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).current_client = controller.current_client
      // controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).current_contract = controller.current_contract
      // controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).current_client = controller.current_client

      // controller.getControllerByIdentifier(`financials--books--clients--contracts--tickets`).doTicketsDashboard()
      // controller.getControllerByIdentifier(`financials--books--clients--contracts--notes`).doNotesDashboard()

      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).current_client = controller.current_client
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).gridElement = controller.accountRowTarget
      controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).clientDash = true
      if (controller.current_client.id) {
        controller.getControllerByIdentifier(`financials--books--clients--accounts--dashboard`).doAccountsDashboard()
      }

      controller.getControllerByIdentifier("financials--books--clients--entities--save").current_client = controller.current_client
      controller.getControllerByIdentifier("financials--books--clients--entities--save").actionMode = controller.actionMode
      controller.getControllerByIdentifier("financials--books--clients--entities--save").permission = `financial_client_entities`
      controller.getControllerByIdentifier("financials--books--clients--entities--save").gridElement = controller.clientRowTarget
      if (controller.actionMode == `edit`) {
        controller.getControllerByIdentifier("financials--books--clients--entities--save").getClient()
      } else if (controller.actionMode == `new`) {
        controller.getControllerByIdentifier("financials--books--clients--entities--save").doFormHtml()
      }

      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).controllerDashboard = controller
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).permission = `financial_client_renewals`
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).gridElement = controller.renewalRowTarget
      

      controller.getClientRenewals()
      controller.goToLogs()
    })

  }

  toggleCard(ev) {
    var feature = ev.currentTarget.dataset.feature

    this.cardRowTargets.forEach(element => {
      element.classList.add(`d-none`)
    })
    this.cardBtnTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.nameTarget(`${feature}Card`).classList.add(`bg-primary`)
    this.nameTarget(`${feature}Row`).classList.remove(`d-none`)
  }

  getClientRenewals() {

    const data = { renewal: { active: true, client_id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_renewals` } }
    const url = "/financials/books/clients/renewals/list_by_client"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var renewals = []
        if (response.process) {
          renewals = response.data.cln
          // controller.application.webhooks.forEach((element, i) => {
          //   if (element.id == webhook.id) {
          //     controller.application.webhooks.splice(controller.application.webhooks.indexOf(element), 1, webhook)
          //   }
          // })
        }

        controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).current_renewals = renewals
        controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).doRenewalList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToLogs() {
    this.application.logs = []
    
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_filter = false
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_id = this.current_client.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_type = `financial_client_entities`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").permission = `financial_client_entities`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").gridElement = this.logRowTarget
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").title = `${this.current_client.record_name}`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").noLogsMessage = `Não há Logs para ${this.current_client.record_name}`

    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").doLogsDashboard()
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Informações carregadas com sucesso!` }

    this.doMenuHtml()
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    // this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.current_contract.id, domain_type: `financial_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    // this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 60 })
    this.application.tickets = []

    var data = { ticket: { board_id: this.current_contract.id, board_type: `financial_contracts` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAddons() {
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).current_contract = this.current_contract
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).getAddons()

    this.getTickets()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}