import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "loanCard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--loans--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doLoanList() {
    var html = ``
    var current_loans = []
    var date = new Date(this.application.current_date.year, this.application.current_date.month, 1)
    var dateToTime = Number(Date.parse(date.toDateString())/1000)

    if (this.application.current_med.id) {
      this.application.loans.forEach(loan => {
        if (loan.status == `finished`) {
          if ((loan.finished_date_token >= this.application.current_date.token && loan.purchase_date_token <= this.application.current_date.token) && (loan.med_id == this.application.current_med.id)) {
            current_loans[current_loans.length] = loan
          }
        } else {
          if ((loan.med_id == this.application.current_med.id)) {
            current_loans[current_loans.length] = loan
          }
        }
      })
    } else {
      this.application.loans.forEach(loan => {
        if (loan.status == `finished`) {
          if (loan.finished_date_token >= this.application.current_date.token && loan.purchase_date_token <= this.application.current_date.token) {
            current_loans[current_loans.length] = loan
          }
        } else {
          current_loans[current_loans.length] = loan
        }
        // if (loan.purchase_to_time < dateToTime && loan.finished_to_time > dateToTime) {
      })
    }
    
    current_loans = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_loans, `description`)

    if (this.application.permissions.financial_payable_loans.can_create) {
      var addBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline btn-sm m-0" data-${this.controllerName}-target="addLoanBtn" data-action="click->${this.controllerName}#addLoan" type="button">
                            <span class="material-icons md-dark">add</span>
                          </button>
                          <span class="mc-tooltiptext">Novo Empréstimo/Parcelamento</span>
                        </span>`
    } else {
      var addBtnHtml = ``
    }

    html += `<div class="card mt-3">
              <div class="card-header px-2 py-0 f-065 text-center d-flex align-items-center">
                <strong>Todos | ${this.application.current_med.name}</strong>
                <div class="card-actions py-0 mc-tooltip ml-auto">
                  ${addBtnHtml}
                </div>
              </div>
            </div>
             <hr class="mt-0">`

    if (current_loans.length > 0) {
      current_loans.forEach(loan => {
        html += `<div class="card pointer mt-3" data-${this.controllerName}-target="loanCard" data-loan-id="${loan.id}" data-action="click->${this.controllerName}#toggleLoan click->${this.controllerName}#showLoan">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>${loan.description}</strong></h6>
                  </div>
                </div>`
      })
    } else {
      html += `<div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhum Empréstimo/Parcelamento para Selecionar</strong></h6>
                  </div>
                </div>`
    }

    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").listTarget.innerHTML = html
  }

  toggleLoan(ev) {
    this.loanCardTargets.forEach(card => {
      card.classList.remove(`bg-primary`)
    })

    if (ev) {
      var loanCard = ev.currentTarget
      loanCard.classList.add(`bg-primary`)
    }
  }

  showLoan(ev) {
    var loanId = ev.currentTarget.dataset.loanId

    this.application.loans.forEach(loan => {
      if (loan.id == loanId) {
        this.getControllerByIdentifier(`financials--books--payables--loans--show`).showLoanLoader()
        this.getControllerByIdentifier(`financials--books--payables--installments--show`).getInstallments(loan.id)

        this.getControllerByIdentifier(`financials--books--payables--loans--save`).stopRefreshing()
        this.getControllerByIdentifier(`financials--books--payables--installments--save`).stopRefreshing()
      }
    })
  }

  addLoan() {
    if (this.application.current_date.open) {
      this.toggleLoan()
      this.getControllerByIdentifier("financials--books--payables--loans--save").current_loan = {}
      this.getControllerByIdentifier("financials--books--payables--loans--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--payables--loans--save").doFormHtml()

      this.getControllerByIdentifier("financials--books--payables--installments--save").stopRefreshing()
      this.getControllerByIdentifier("financials--books--payables--installments--show").doNoInstallment()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

  // if (this.application.current_date.open) {
  // } else {
  //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está fechada. Favor selecionar a Competência atual.`, 5000)
  // }

}
