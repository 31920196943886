import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "compilationTitle", "compilationBoard", "billingsTitle", "billingsTable", "mainCard",
    "bodyTable", "footerTable", "content", "goalLeadInput", "mainDashboard"]

  connect() {
    this.controllerName = `app--communications--interactions--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reload = false
    this.load = false
    this.application.permissions = {}
    this.application.permissions = []
    this.application.interactions = []
    this.application.dates = []
    this.application.accounts = []
    this.application.record_report = []
    this.application.kind_report = []
    this.application.current_day = new Date()

    this.doMainGrid()

  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doPageGrid() {
    var controllerCompilation = `app--communications--interactions--dashboard--compilation`
    var controllerAccounts = `app--communications--interactions--dashboard--accounts`

    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 px-2">
                    <div class="row my-2 w-100" data-controller="${controllerAccounts}">
                      <div class="col-12">
                        <h6 class="mb-0 text-center" data-${controllerAccounts}-target="accountsTitle">${this.loader}</h6>
                        <hr class="my-1">
                        <div class="row" data-${controllerAccounts}-target="accountsBoard">
                          ${this.cardGridPreloader(2, 12)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-10 px-2">
                    <div class="row my-2 w-100" data-controller="${controllerCompilation}">
                      <div class="col-6 px-2">
                        <div class="row w-100">
                          <div class="col-12">
                            <h6 class="mb-0 text-center pointer" data-action="click->${controllerCompilation}#refreshReports" data-${controllerCompilation}-target="recordTitle">${this.loader}</h6>
                            <hr class="my-1">
                            <div class="row" data-${controllerCompilation}-target="recordBoard">
                              ${this.cardGridPreloader(6, 4)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="row w-100">
                          <div class="col-12 px-0">
                            <h6 class="mb-0 text-center pointer" data-action="click->${controllerCompilation}#refreshReports" data-${controllerCompilation}-target="kindTitle">${this.loader}</h6>
                            <hr class="mt-1">
                            <div class="row my-2" data-${controllerCompilation}-target="kindBoard">
                              ${this.cardGridPreloader(6, 4)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.load) {
        controller.getControllerByIdentifier(controllerAccounts).permission = `app_comunication_interaction_entities`
        controller.getControllerByIdentifier(controllerAccounts).grid = `interaction_compilation`
        controller.getControllerByIdentifier(controllerAccounts).doAccountsCard()
        controller.getControllerByIdentifier(controllerCompilation).permission = `app_comunication_interaction_entities`
        controller.getControllerByIdentifier(controllerCompilation).doCompilationsCard()
      }
    })
  }

  cardGridPreloader(cols, size) {

    var html = ``
    for (let index = 0; index < cols; index++) {
      html += `<div class="col-${size} my-2">
                  ${this.cardLoader}
                </div>`

    }

    return html
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="app--communications--interactions--entities--dashboard
                                                            app--communications--interactions--accounts--dashboard

                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doMainDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  setAccounts() {
    var accounts = []

    this.application.interactions.forEach(element => {
      accounts[accounts.length] = { id: element.account_id, name: element.account_name }
    })

    accounts = accounts.filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index)
    this.application.accounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(accounts, `name`)
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    if (this.reload) {
      this.getControllerByIdentifier("app--communications--interactions--dashboard--dates").runDashboardsHtml()
    } else {
      this.getControllerByIdentifier("app--communications--interactions--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("app--communications--interactions--dashboard--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("app--communications--interactions--dashboard--dates").doDatesHTML()
    }
    this.setAccounts()
    this.application.current_date.data_loaded = true

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getInteractions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Interações`, value: 75 })

    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      var data = { interaction: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      var url = "/app/communications/interactions/entities/list_by_date"
    } else {
      var data = { interaction: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      var url = "/app/communications/interactions/entities/list_by_date_and_account"
    }

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentInteractionIds = controller.mapElements(controller.application.interactions, `id`)
          var refreshInteractionIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(interaction => {
            if (currentInteractionIds.includes(interaction.id) && refreshInteractionIds.includes(interaction.id)) {
              controller.application.interactions.forEach((element, i) => {
                if (element.id == interaction.id) {
                  controller.application.interactions.splice(controller.application.interactions.indexOf(element), 1, interaction)
                }
              })
            } else if (!currentInteractionIds.includes(interaction.id) && refreshInteractionIds.includes(interaction.id)) {
              controller.application.interactions[controller.application.interactions.length] = interaction
            }
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRecordReport() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Relatórios`, value: 40 })

    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    }
    var url = "/app/communications/interactions/reports/list_monthly_record_report"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentRecordReportIds = controller.mapElements(controller.application.record_report, `date_id`)

          if (currentRecordReportIds.includes(response.data.cln.date_id)) {
            controller.application.record_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.record_report.splice(controller.application.record_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentRecordReportIds.includes(response.data.cln.date_id)) {
            controller.application.record_report[controller.application.record_report.length] = response.data.cln
          }
        }
        
        controller.getInteractions()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getKindReport() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Relatórios`, value: 30 })

    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    }
    var url = "/app/communications/interactions/reports/list_monthly_kind_report"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentKindReportIds = controller.mapElements(controller.application.kind_report, `date_id`)

          if (currentKindReportIds.includes(response.data.cln.date_id)) {
            controller.application.kind_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.kind_report.splice(controller.application.kind_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentKindReportIds.includes(response.data.cln.date_id)) {
            controller.application.kind_report[controller.application.kind_report.length] = response.data.cln
          }
        }
        
        controller.getRecordReport()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
      }

      this.getKindReport()
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Finalizando`, value: 50 })
      this.finishLoadPage()
    }
  }

  getDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_dates` } }
    const url = "/app/communications/interactions/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        })

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]

        controller.loadDateData()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `app_communication_interactions` }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        if (controller.application.permissions.app_comunication_interaction_entities.can_manage) {
          controller.application.current_account_id = false
        } else {
          controller.application.current_account_id = controller.application.current_user.account_id
        }

        controller.getDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }


  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------


  refreshReports(identifier) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.refreshRecordReport(identifier)
  }

  refreshRecordReport(identifier) {
    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      if (this.application.current_account_id) {
        var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      } else {
        var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      }  
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    }
    var url = "/app/communications/interactions/reports/list_monthly_record_report"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentRecordReportIds = controller.mapElements(controller.application.record_report, `date_id`)

          if (currentRecordReportIds.includes(response.data.cln.date_id)) {
            controller.application.record_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.record_report.splice(controller.application.record_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentRecordReportIds.includes(response.data.cln.date_id)) {
            controller.application.record_report[controller.application.record_report.length] = response.data.cln
          }
        }

        controller.refreshKindReport(identifier)
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshKindReport(identifier) {
    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      if (this.application.current_account_id) {
        var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      } else {
        var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      }
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
    }
    var url = "/app/communications/interactions/reports/list_monthly_kind_report"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentKindReportIds = controller.mapElements(controller.application.kind_report, `date_id`)

          if (currentKindReportIds.includes(response.data.cln.date_id)) {
            controller.application.kind_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.kind_report.splice(controller.application.kind_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentKindReportIds.includes(response.data.cln.date_id)) {
            controller.application.kind_report[controller.application.kind_report.length] = response.data.cln
          }
        }

        if (identifier == `interaction_table`) {
          controller.getControllerByIdentifier(`app--communications--interactions--entities--index`).doDataTable()
        } else if (identifier == `interaction_compilation`) {
          controller.getControllerByIdentifier(`app--communications--interactions--dashboard--compilation`).doCompilationsCard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }  


  refreshInteractions(identifier) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      var data = { interaction: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      var url = "/app/communications/interactions/entities/list_by_date"
    } else {
      var data = { interaction: { active: true, date_id: this.application.current_date.id, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_interaction_entities` } }
      var url = "/app/communications/interactions/entities/list_by_date_and_account"
    }

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentInteractionIds = controller.mapElements(controller.application.interactions, `id`)
          var refreshInteractionIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(interaction => {
            if (currentInteractionIds.includes(interaction.id) && refreshInteractionIds.includes(interaction.id)) {
              controller.application.interactions.forEach((element, i) => {
                if (element.id == interaction.id) {
                  controller.application.interactions.splice(controller.application.interactions.indexOf(element), 1, interaction)
                }
              })
            } else if (!currentInteractionIds.includes(interaction.id) && refreshInteractionIds.includes(interaction.id)) {
              controller.application.interactions[controller.application.interactions.length] = interaction
            }
          })

          controller.application.interactions.forEach(element => {
            if (currentInteractionIds.includes(element.id) && !refreshInteractionIds.includes(element.id) && element.date_id == controller.application.current_date.id) {
              controller.application.interactions.splice(controller.application.interactions.indexOf(element), 1)
            }
          })
        }

        if (identifier == `interaction_table`) {
          controller.getControllerByIdentifier(`app--communications--interactions--entities--index`).doDataTable()
        } else if (identifier == `interaction_compilation`) {
          controller.getControllerByIdentifier(`app--communications--interactions--dashboard--compilation`).doCompilationsCard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }




  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------



  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `app--communications--interactions--dashboard--main`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.updatePayble) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}