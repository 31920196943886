import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "title", "metric", "event", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--show--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.event_slug = location.pathname.split("/").pop()
    this.application.permissions = {}
    this.application.attendees = []
    this.application.tickets = []
    this.application.notes = []
    this.application.admissions = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12" data-${this.controllerName}-target="content"
                                      data-controller="commercial--marketing--events--show--dashboard
                                                       commercial--marketing--events--admissions--dashboard
                                                       commercial--marketing--events--attendees--dashboard
                                                       commercial--marketing--events--show--meeting
                                                       commercial--marketing--events--show--submenu
                                                       commercial--marketing--events--show--landing
                                                       commercial--marketing--events--show--links
                                                       commercial--marketing--events--show--creative
                                                       users--works--permissions--entities--list-users">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  setPageTitle() {
    this.titleTarget.innerHTML = `<span class="mb-0 mc-tooltip grab">
                                    <span data-controller="app--helpers--copy" data-copy="${this.application.event.title}" data-action="click->app--helpers--copy#copy">${this.application.event.title}</span>
                                    <span class="mc-tooltiptext">Clique para Copiar</span>
                                  </span>`
    
    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.event.title
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.event.title
      }, 1000);
    }
  }

  setCurrentSquad() {
    this.application.squads.forEach(element => {
      if (element.team_id == this.application.current_user.account_id) {
        this.application.current_squad = element
      }
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    this.setPageTitle()
    this.setCurrentSquad()

    this.getControllerByIdentifier(`commercial--marketing--events--show--dashboard`).doDashboardHtml()
    this.getControllerByIdentifier(`commercial--marketing--events--show--submenu`).doSubmenuHtml()
    // this.getControllerByIdentifier(`commercial--marketing--events--show--tasks`).doDataTable()

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 95 })

    var data = { note: { domain_id: this.application.event.id, domain_type: `marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 90 })

    var data = { ticket: { board_id: this.application.event.id, board_type: "marketing_events" }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAdmissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Participantes`, value: 80 })

    var data = { admission: { event_id: this.application.event.id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/marketing/events/admissions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.admissions = response.data.cln
        }

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEventTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time`, value: 60 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/config/teams/list_event_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team = response.data.cln
        controller.application.team_list = response.data.cln

        controller.getAdmissions()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squad`, value: 40 })

    const data = { squad: { event_id: this.application.event.id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_squads` } }
    const url = "/commercial/marketing/events/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.squads = response.data.cln

        controller.getEventTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEvent() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Evento`, value: 30 })

    const data = { event: { slug: this.application.event_slug }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/marketing/events/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.event = response.data.cln

        controller.getSquads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });

        controller.getEvent()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
