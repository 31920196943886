
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "saveBtn"]

  connect() {
    this.controllerName = `users--profile--sidebar`
    this.getUserSidebarPermissions()
  }

  changePermission(ev) {
    if (ev.target.checked) {
      ev.target.value = true
    } else {
      ev.target.value = false
    }
    if (this.hasSaveBtnTarget) {
      this.saveBtnTarget.classList.remove("d-none")
    }
  }
  
  savePermission() {
    
    var can_dashboard = $('#switchPermissionDashboard').attr("value")
    var can_tasks = $('#switchPermissionTasks').attr("value")
    var can_financials = $('#switchPermissionFinancials').attr("value")
    var can_sales = $('#switchPermissionSales').attr("value")
    var can_marketing = $('#switchPermissionMarketing').attr("value")
    var can_operations = $('#switchPermissionOperations').attr("value")
    var can_content = $('#switchPermissionContent').attr("value")
    var can_school = $('#switchPermissionSchool').attr("value")
    var can_profile = $('#switchPermissionProfile').attr("value")
    var can_settings = $('#switchPermissionSettings').attr("value")
    
    if (this.application.current_user.super_admin) {
      var can_generators = $('#switchPermissionGenerators').attr("value")
      var can_develop = $('#switchPermissionDevelop').attr("value")
      var can_report = $('#switchPermissionDashboard').attr("value")
      var can_products = $('#switchPermissionDashboard').attr("value")
      var can_notifications = $('#switchPermissionDashboard').attr("value")
      var can_banking = $('#switchPermissionDashboard').attr("value")
    }
    this.saveBtnTarget.classList.add("d-none")

    const data = {
                    sidebar: { can_dashboard: can_dashboard, can_tasks: can_tasks, can_sales: can_sales, can_marketing: can_marketing,
                               can_operations: can_operations, can_content: can_content, can_school: can_school, can_profile: can_profile,
                               can_develop: can_develop, can_generators: can_generators, can_financials: can_financials,
                               can_settings: can_settings, name: "sidebar_permissions", user_id: this.application.user.id,
                               id: $("#sidebar").data("sidebar_permissions").id
                             },
                    current_user: { current_user_id: currentUser.id }
                  }

    this.requestUpdate(data)
  }

  requestUpdate(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/sidebars/save"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var permission = response.data.cln
          $("#sidebar").data(permission.name, permission.data)
          controller.doPermissionCardHtml()
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  doPermissionCardHtml() {

    if ($("#sidebar").data("sidebar_permissions").can_dashboard) {
      var dashboard = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionDashboard" type="checkbox" value="true" checked>`
    } else {
      var dashboard = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionDashboard" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_tasks) {
      var tasks = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionTasks" type="checkbox" value="true" checked>`
    } else {
      var tasks = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionTasks" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_financials) {
      var financials = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionFinancials" type="checkbox" value="true" checked>`
    } else {
      var financials = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionFinancials" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_marketing) {
      var marketing = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionMarketing" type="checkbox" value="true" checked>`
    } else {
      var marketing = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionMarketing" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_sales) {
      var sales = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSales" type="checkbox" value="true" checked>`
    } else {
      var sales = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSales" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_operations) {
      var operations = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionOperations" type="checkbox" value="true" checked>`
    } else {
      var operations = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionOperations" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_content) {
      var content = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionContent" type="checkbox" value="true" checked>`
    } else {
      var content = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionContent" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_school) {
      var school = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSchool" type="checkbox" value="true" checked>`
    } else {
      var school = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSchool" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_profile) {
      var profile = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionProfile" type="checkbox" value="true" checked>`
    } else {
      var profile = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionProfile" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_settings) {
      var settings = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSettings" type="checkbox" value="true" checked>`
    } else {
      var settings = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionSettings" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_generators) {
      var generators = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionGenerators" type="checkbox" value="true" checked>`
    } else {
      var generators = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionGenerators" type="checkbox" value="false">`
    }

    if ($("#sidebar").data("sidebar_permissions").can_develop) {
      var develop = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionDevelop" type="checkbox" value="true" checked>`
    } else {
      var develop = `<input class="custom-control-input custom-control-input-sidebar" data-action="click->${this.controllerName}#changePermission" id="switchPermissionDevelop" type="checkbox" value="false">`
    }

    if (this.application.sidebar_permissions.can_update) {
      var saveBtn = `<button class="btn btn-sm btn-flat-success my-1 py-2 d-none" type="button" data-action="click->${this.controllerName}#savePermission" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtn = ``
    }

    var html = `<div class="card-body p-0 s-title-0p7rem">
                  <div class="row my-1 py-1">
                    <div class="table-responsive">
                      <table class="table table-borderless table-sm">
                        <thead>
                          <tr>
                            <th colspan="4" class="text-left align-middle permissionHeader" style="padding-top:1px;padding-bottom:1px;">
                              <strong>Permissões Sidebar</strong>
                              ${saveBtn}
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" class="td-w-10">Dashboard</th>
                            <th scope="col" class="td-w-10">Atividades</th>
                            <th scope="col" class="td-w-10">Financeiro</th>
                            <th scope="col" class="td-w-10">Marketing</th>
                            <th scope="col" class="td-w-10">Vendas</th>
                            <th scope="col" class="td-w-10">Operações</th>
                            <th scope="col" class="td-w-10">Conteúdo</th>
                            <th scope="col" class="td-w-10">Educação</th>
                            <th scope="col" class="td-w-10">Perfil</th>
                            <th scope="col" class="td-w-10">Configurações</th>
                            <th scope="col" class="td-w-10">Geradores</th>
                            <th scope="col" class="td-w-10">Develop</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="custom-control custom-switch">
                                ${dashboard}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionDashboard"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${tasks}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionTasks"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${financials}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionFinancials"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${marketing}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionMarketing"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${sales}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionSales"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${operations}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionOperations"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${content}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionContent"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${school}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionSchool"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${profile}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionProfile"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${settings}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionSettings"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${generators}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionGenerators"></label>
                              </div>
                            </td>
                            <td>
                              <div class="custom-control custom-switch">
                                ${develop}
                                <span class="custom-control-track"></span>
                                <label class="custom-control-label" for="switchPermissionDevelop"></label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>`

    this.listTarget.innerHTML = html
  }

  getUserSidebarPermissions() {
    const data = { current_user: { current_user_id: this.application.current_user.id }, user: { user_id: this.application.user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/sidebars/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var permission = response.data.cln
        $("#sidebar").data(permission.name, permission.data)
        controller.doPermissionCardHtml()
      })
  }

  
}
