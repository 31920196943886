import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "boardsFilter", "ticketBoard", "postRow",
                    "removeFilterBtn", "chipFilter", "removeDueFilterBtn", "chipDue", "ticketBoardRow"]

  connect() {
    this.controllerName = `users--dashboards--main--tickets`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()

    this.application.account_id = this.application.current_user.account_id
    this.hasBoardFilter = false
    this.hasDueFilter = false
  }

  doFilterHeader() {
    var title = `<h6 class="mb-0 text-center f-bold f-085 d-flex">
                    <span class="mr-auto pointer" data-action="click->${this.controllerName}#goToTickets">Meus Tickets</span>
                    <span class="mc-tooltip d-none" data-${this.controllerName}-target="removeFilterBtn">
                      <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeFilter">cancel</span>
                      <span class="mc-tooltiptext">Remover Filtros</span>
                    </span>
                  </h6>
                  <hr class="my-1">
                  <div class="row">
                    <div class="col-3 card-body py-0" style="overflow:auto;">
                      <div class="row" data-${this.controllerName}-target="boardsFilter"></div>
                    </div>
                    <div class="col-9 card-body py-1" style="overflow:auto;" data-${this.controllerName}-target="ticketBoard">
                      
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = title)
    }).then(() => {
      controller.doFilterData()
      controller.boardsFilterTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.ticketBoardTarget.style.height = ($(window).height() * 0.6) + "px"

        // `<div class="card-body py-1 px-1" data-${this.controllerName}-target="listWeeklies" style="overflow:auto;">`
    })
  }

  doFilterData() {
    this.board_filters = []
    var date = new Date()
    var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)

    this.application.uniq_board_types.forEach((element, i) => {
      var filter = {}
      filter.board = element
      filter.board_pretty = this.application.uniq_board_types_pretty[i]
      filter.board_total = 0
      filter.board_today = 0
      filter.board_previous = 0
      filter.board_next = 0
      this.application.tickets.forEach(ticket => {
        if (ticket.board_type == element) {
          filter.board_total += 1
        }
      })
      if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
        filter.board_today += 1
      }
      if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
        filter.board_previous += 1
      }
      if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
        filter.board_next += 1
      }

      this.board_filters[this.board_filters.length] = filter
    })

    this.board_today = 0
    this.board_previous = 0
    this.board_next = 0

    this.board_low = 0
    this.board_medium = 0
    this.board_high = 0
    this.board_maximum = 0

    this.application.tickets.forEach(element => {
      if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
        this.board_today += 1
      }
      if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
        this.board_previous += 1
      }
      if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
        this.board_next += 1
      }

      if (element.priority == `low`) {
        this.board_low += 1
      } else if (element.priority == `medium`) {
        this.board_medium += 1
      } else if (element.priority == `high`) {
        this.board_high += 1
      } else if (element.priority == `maximum`) {
        this.board_maximum += 1
      }
    })

    this.doFilterHtml()
  }

  doFilterHtml() {
    //var board_filters = this.board_filters
    var board_filters = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.board_filters, `board_pretty`)

    var html = ``
    html += `<div class="col-12 text-center mt-3">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Data</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeDueFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeDueFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    html += this.duePartial()

    html += `<div class="col-12 text-center mt-3">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Prioridade</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removePriorityFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removePriorityFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    html += this.priorityPartial()

    html += `<div class="col-12 text-center">
                <h6 class="mb-0 text-center f-065 d-flex">
                  <span class="mx-auto">Boards</span>
                  <span class="ml-auto mc-tooltip d-none" data-${this.controllerName}-target="removeBoardFilterBtn">
                    <span class="material-icons md-sm pointer" data-action="click->${this.controllerName}#removeBoardFilter">cancel</span>
                    <span class="mc-tooltiptext">Remover Filtros</span>
                  </span>
                </h6>
                <hr class="mt-1">
              </div>`

    if (this.application.personal_board) {
      html += `<div class="col-12 text-center px-0">
                <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="personal_tickets" data-${this.controllerName}-target="personal_tickets chipFilter" data-action="click->${this.controllerName}#filterTicket">
                  <span class="f-075">Meu Board</span>
                  <span class="f-075 text-bold ml-auto badge badge-secondary-dark" data-${this.controllerName}-target="personalTicketCount"></span>
                </div>
              </div>`
    } else {

    }

    if (board_filters == [] || board_filters.length == 0 || board_filters == undefined) {
      html += `<div class="col-12 my-2 text-center">
                  <span class="f-075 align-middle">Não há Boards</span>
                </div>`
    } else {
      board_filters.forEach(element => {
        html += this.boardPartial(element)
      });
    }

    this.boardsFilterTarget.innerHTML = html
  }

  boardPartial(element) {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipFilter d-flex pointer" data-filter="${element.board}" data-${this.controllerName}-target="${element.board} chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="f-075">${element.board_pretty}</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${element.board_total}</span>
                  </div>
                </div>`

    return html
  }

  duePartial() {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex" data-filter="today" data-${this.controllerName}-target="today chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Hoje</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_today}</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex" data-filter="previous" data-${this.controllerName}-target="previous chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Atrasadas</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_previous}</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex" data-filter="next" data-${this.controllerName}-target="next chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Próximas</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_next}</span>
                  </div>
                </div>`

    return html
  }

  priorityPartial() {
    var html = `<div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip" data-filter="low" data-${this.controllerName}-target="low chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Baixa</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_low}</span>
                    <span class="mc-tooltiptext">Não Urgente | Não Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip" data-filter="medium" data-${this.controllerName}-target="medium chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Média</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_medium}</span>
                    <span class="mc-tooltiptext">Urgente | Não Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip" data-filter="high" data-${this.controllerName}-target="high chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Alta</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_high}</span>
                    <span class="mc-tooltiptext">Não Urgente | Importante</span>
                  </div>
                </div>
                <div class="col-12 text-center px-0">
                  <div class="chip fade show mr-2 mb-2 chipBoard d-flex mc-tooltip" data-filter="maximum" data-${this.controllerName}-target="maximum chipFilter" data-action="click->${this.controllerName}#filterTicket">
                    <span class="pointer f-075">Máxima</span>
                    <span class="f-075 text-bold ml-auto badge badge-secondary-dark">${this.board_maximum}</span>
                    <span class="mc-tooltiptext">Urgente | Importante</span>
                  </div>
                </div>`

    return html
  }

  doTicketBoard() {
    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.filter_tickets, `due_at_to_time`)
    this.listData(tickets)
  }

  listData(data) {
    this.ticketBoardTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="row" style="height:200px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tickets</span>
                      </div>
                    </div>`

      this.ticketBoardTarget.innerHTML = noData
    } else {
      var rowHtml = `<div class="row" data-${this.controllerName}-target="ticketBoardRow"></div>`
      var place = 1
      var elements = []
      var columns = 2
      var rows = Math.floor(data.length / columns) + 1
      var row = 1

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.ticketBoardTarget.innerHTML = rowHtml)
      }).then(() => {
        if (data.length <= columns) {
          data.forEach(element => {
            elements[elements.length] = element
          })
          controller.ticketBoardRowTarget.insertAdjacentHTML("beforeend", controller.ticketPartial(elements))
        } else {
          data.forEach(element => {
            elements[elements.length] = element
            place += 1

            if (place > columns) {
              controller.ticketBoardRowTarget.insertAdjacentHTML("beforeend", controller.ticketPartial(elements))
              place = 1
              elements = []
              row += 1
            }
          })
          if (row == rows) {
            controller.ticketBoardRowTarget.insertAdjacentHTML("beforeend", controller.ticketPartial(elements))
          }
        }
      })

      
    }
  }

  ticketPartial(elements) {
    var elementHtml = ``
    elements.forEach(element => {

      var dateBtn = `<div class="col-6 mr-auto px-1 d-flex align-items-center pointer bg-shadow mc-tooltip">
                        <span>${element.due_at_pretty}</span>
                        <span class="mc-tooltiptext">${element.board_name}</span>
                      </div>`

      var performerBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow pointer">
                            <span class="material-icons md-150 md-dark">person</span>
                            <span class="mc-tooltiptext">${element.performer_name}</span>
                          </div>`
      
      if (element.total_comments > 0) {
        var commentBadge = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
      } else {
        var commentBadge = `<span class="badge badge-danger notice-badge d-none" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
      }
      var commentBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow default">
                            <span class="material-icons md-150 md-dark">comment</span>
                            ${commentBadge}
                            <span class="mc-tooltiptext">Comentários</span>
                          </div>`

      elementHtml += `<div class="col-6 px-1 mb-2">
                        <div class="card border-flag-${element.flag} pointer" data-id="${element.id}" data-path="${element.board_path}" data-action="click->${this.controllerName}#goToBoard">
                          <div class="card-header p-1 text-center f-065 d-flex" style="height:40px;">
                            ${dateBtn}
                            ${performerBtn}
                            ${commentBtn}
                          </div>
                          <div class="card-body p-1 text-center f-065" data-${this.controllerName}-target="ticketCardBody">
                            <div class="row py-1 px-2">
                              <div class="col-12 px-0 d-flex align-items-center text-left ticket-content" data-${this.controllerName}-target="bodyShow-${element.id}">${element.body}</div>
                            </div>
                          </div>
                          <div class="card-footer border-top text-right py-1 px-2">
                            
                          </div>
                        </div>
                      </div>`

    })

    // ${deleteBtnHtml}
    // ${resolvedBtnHtml}
    // ${saveBodyBtnHtml}

    return elementHtml
  }

  filterTicket(ev) {
    var filter = ev.currentTarget.dataset.filter
    var filter_tickets = []
    filter_tickets = this.application.tickets

    if (["today", "previous", "next"].includes(filter)) {
      this.application.personal_board = false
      this.application.non_personal_board = true
      var date = new Date()
      var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      var data = []

      if (filter == `today`) {
        filter_tickets.forEach(element => {
          if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
            data[data.length] = element
          }
        })
      } else if (filter == `previous`) {
        filter_tickets.forEach(element => {
          if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
            data[data.length] = element
          }
        })
      } else if (filter == `next`) {
        filter_tickets.forEach(element => {
          if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
            data[data.length] = element
          }
        })
      }
      this.application.filter_tickets = data
    } else if (["low", "medium", "high", "maximum"].includes(filter)) {
      this.application.personal_board = false
      this.application.non_personal_board = true

      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (element.priority == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })

    } else if (filter == `personal_tickets`) {
      this.application.personal_board = true
      this.application.non_personal_board = false
    } else {
      this.application.personal_board = false
      this.application.non_personal_board = true
      this.application.filter_tickets = []
      filter_tickets.forEach(element => {
        if (element.board_type == filter) {
          this.application.filter_tickets[this.application.filter_tickets.length] = element
        }
      })


    }

    this.toggleFilter(filter)
    this.doTicketBoard()
  }

  toggleFilter(filter) {
    this.removeFilterBtnTarget.classList.remove("d-none")
    this.chipFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.nameTarget(filter).classList.add("bg-primary")
  }

  removeFilter() {
    this.removeFilterBtnTarget.classList.add("d-none")
    this.chipFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.personal_board = true
    this.application.non_personal_board = true
    this.application.filter_tickets = this.application.tickets
    this.getControllerByIdentifier(`users--works--activities--tickets--resume`).doResumeHtml(`col-12 mb-3`)
    // this.doTicketBoard()
  }

  removeDueFilter() {
    this.hasDueFilter = false
    this.removeDueFilterBtnTarget.classList.add("d-none")
    this.chipDueTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.application.filter_tickets = this.application.tickets
    this.doTicketBoard()
  }

  typePreloader() {
    var html = `<h6 class="mb-0 text-center f-bold f-085">Meus Tickets</h6>
                <hr class="mt-1">
                <div class="row">
                  <div class="col-6">
                    <div class="col-12 text-center mb-3">
                      ${this.chipLoader}
                    </div>
                    <div class="col-12 text-center mb-3">
                      ${this.chipLoader}
                    </div>
                    <div class="col-12 text-center mb-3">
                      ${this.chipLoader}
                    </div>
                    <div class="col-12 text-center mb-3">
                      ${this.chipLoader}
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="col-12 text-center mb-3">
                      ${this.cardLoader}
                    </div>
                    <div class="col-12 text-center mb-3">
                      ${this.cardLoader}
                    </div>
                    <div class="col-12 text-center mb-3">
                      ${this.cardLoader}
                    </div>
                  </div>
                </div>`

    // this.gridElement.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.getTickets()
    }) 
  }

  getTickets() {
    const data = { ticket: { performer_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_work_activity_tickets` } }
    const url = "/users/works/activities/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.tickets = []
        controller.application.all_tickets = response.data.cln

        if (controller.application.account_id == controller.application.current_user.account_id) {
          controller.application.tickets = controller.application.all_tickets
        } else {
          controller.application.all_tickets.forEach(element => {
            if (controller.application.current_squad.board_types.includes(element.board_type)) {
              controller.application.tickets[controller.application.tickets.length] = element
            }
          })
        }

        controller.application.uniq_board_ids = controller.mapElements(controller.application.tickets, `board_id`).filter(controller.onlyUnique)
        controller.application.uniq_board_types = controller.mapElements(controller.application.tickets, `board_type`).filter(controller.onlyUnique)
        controller.application.uniq_board_types_pretty = controller.mapElements(controller.application.tickets, `board_type_pretty`).filter(controller.onlyUnique)
        controller.application.uniq_board_names = controller.mapElements(controller.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(controller.application.tickets, `board_name`), `board_name`).filter(controller.onlyUnique)
        controller.application.uniq_owner_ids = controller.mapElements(controller.application.tickets, `owner_id`).filter(controller.onlyUnique)
        controller.application.uniq_owner_names = controller.mapElements(controller.application.tickets, `owner_name`).filter(controller.onlyUnique)
        controller.application.uniq_filters = controller.mapElements(controller.application.tickets, `filters`).filter(controller.onlyUnique)

        controller.application.filter_tickets = controller.application.tickets

        controller.doFilterHeader()
        // controller.doTicketBoard()
        controller.getControllerByIdentifier(`users--works--activities--tickets--resume`).gridElement = controller.ticketBoardTarget
        controller.getControllerByIdentifier(`users--works--activities--tickets--resume`).doResumeHtml(`col-12 mb-3`)
        controller.getControllerByIdentifier(`users--dashboards--main--alias`).doAliasCard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToBoard(ev) {
    var url = ev.currentTarget.closest(`.card`).dataset.path
    window.open(url, `_blank`)
  }

  goToTickets() {
    var url = `/a/tickets`
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
