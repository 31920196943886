import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "view", "viewTitle", "form", "saveBtn", "nameInput", "emailInput", "councilInput", "councilStateInput",
                    "prefixInput", "phoneInput", "notesInput", "addNewLeadBtn", "checklist", "linkInput",
                    "sourceDropdown", "sourceDropdownBtn", "sourceInput", "sourceList", "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "chekListName", "checkListDescriptionName", "checkListEmail", "checkListDescriptionEmail",
                    "checkListPhone", "checkListDescriptionPhone", "checkListCouncilState", "checkListDescriptionCouncilState",
                    "checkListSource", "checkListDescriptionSource", "checkListNotes", "checkListDescriptionNotes",
                    "checkListOffice", "checkListDescriptionOffice", "checkListSignature", "checkListDescriptionSignature",
                    "checkListCouncil", "checkListDescriptionCouncil", "checkListPrefix", "checkListDescriptionPrefix",
                    "eventsCard", "landingsCard", "visitsCard", "menuCard", "menu", "menuRows", "list"]


  connect() {
    this.controllerName = `commercial--sales--leads--sources--dashboard`
    this.current_lead = {}
    this.application.permissions = {}
    this.application.event_leads = []
    this.application.landing_leads = []
    this.application.visit_leads = []
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.doPageGrid()
  }

  doPageGrid() {
    this.stopRefreshing()
    var html = `<div class="row">
                  <div class="col-12 pt-3" data-${this.controllerName}-target="menu"></div>
                </div>
                <div class="row">
                  <div class="col-8" data-${this.controllerName}-target="list"></div>
                  <div class="col-4" data-${this.controllerName}-target="form" data-controller="commercial--sales--leads--sources--save-lead web--commercial--marketing--events--attendees--save"></div>
                </div>`

    var html2 = `<div class="row my-4">
                  <div class="col-4 pr-1" data-controller="commercial--sales--leads--sources--events" data-commercial--sales--leads--sources--events-target="main"></div>
                  <div class="col-4 px-1" data-controller="commercial--sales--leads--sources--landings" data-commercial--sales--leads--sources--landings-target="main"></div>
                  <div class="col-4 pl-1" data-controller="commercial--sales--leads--sources--visits" data-commercial--sales--leads--sources--visits-target="main"></div>
                </div>
                <div class="row my-4">
                  <div class="col-4">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="view">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Selecione um Lead e gere um novo SQL</h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addNewLeadBtn" data-action="click->${this.controllerName}#addNewLead" type="button">
                                <span class="material-icons">add</span>
                              </button>
                              <span class="mc-tooltiptext">Adicionar Novo Lead</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-4" data-${this.controllerName}-target="checklist">
                    </div>
                  </div>
                  <div class="col-8" data-${this.controllerName}-target="form"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--sales--leads--sources--save-lead`).gridElement = controller.formTarget
      controller.getControllerByIdentifier(`web--commercial--marketing--events--attendees--save`).controllerDashboard = controller
      
      controller.getControllerByIdentifier(`web--commercial--marketing--events--attendees--save`).gridElement = controller.formTarget
      controller.getControllerByIdentifier(`web--commercial--marketing--events--attendees--save`).actionMode = `edit`
      controller.getControllerByIdentifier(`web--commercial--marketing--events--attendees--save`).permission = `commercial_event_attendees`

      controller.getCurrentUserPermissions()
    })
  }

  menuHtml() {
    var html = `<div class="row w-100 d-flex justify-content-center">
                  <div class="col-3 px-2">
                    <div class="card bg-primary pointer" data-${this.controllerName}-target="eventsCard menuCard" data-feature="events" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Eventos</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="landingsCard menuCard" data-feature="landings" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Landings</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="visitsCard menuCard" data-feature="visits" data-action="click->${this.controllerName}#toggleCard">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1 mb-0 f-075"><strong>Visitas</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>`

    this.menuTarget.innerHTML = html
  }

  toggleCard(ev) {
    var element = ev.currentTarget
    var feature = element.dataset.feature

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.menuRowsTargets.forEach(element => {
      // element.classList.add(`d-none`)
      element.remove()
    })

    element.classList.add(`bg-primary`)

    this.doListHtml(feature)
    // this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)
  }

  doListHtml(feature) {
    var html = ``
    if (feature == `events`) {
      html += `<div class="row mt-4" data-controller="commercial--sales--leads--sources--events" data-commercial--sales--leads--sources--events-target="main" data-${this.controllerName}-target="menuRows"></div>`
    } else if (feature == `landings`) {
      html += `<div class="row mt-4" data-controller="commercial--sales--leads--sources--landings" data-commercial--sales--leads--sources--landings-target="main" data-${this.controllerName}-target="menuRows"></div>`
    } else if (feature == `visits`) {
      html += `<div class="row mt-4" data-controller="commercial--sales--leads--sources--visits" data-commercial--sales--leads--sources--visits-target="main" data-${this.controllerName}-target="menuRows"></div>`
    }
    
    this.listTarget.innerHTML = html
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)

    this.getControllerByIdentifier(`commercial--sales--leads--sources--save-lead`).doViewHtml()
    this.menuHtml()
    this.doListHtml(`events`)

    // if (this.application.permissions.sales_qualified_leads.can_create == false) {
    //   this.addNewLeadBtnTarget.classList.add("d-none")
    // }

    // this.getControllerByIdentifier("commercial--sales--leads--sources--events").doDataTable()
    // this.getControllerByIdentifier("commercial--sales--leads--sources--landings").doDataTable()
    // this.getControllerByIdentifier("commercial--sales--leads--sources--visits").doDataTable()
    this.getControllerByIdentifier(`commercial--sales--leads--entities--submenu`).doSubmenuHtml()
  }

  getVisitLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/marketing/leads/generations/list_visits"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.visit_leads = response.data.cln
        controller.getControllerByIdentifier("commercial--sales--leads--sources--visits").doDataTable()

        // controller.getControllerByIdentifier("commercial--sales--leads--sources--visits").leads = response.data.cln
        // controller.finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getLandingLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/marketing/leads/generations/list_landings"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.landing_leads = response.data.cln
        controller.getControllerByIdentifier("commercial--sales--leads--sources--landings").doDataTable()

        // controller.getControllerByIdentifier("commercial--sales--leads--sources--landings").leads = response.data.cln
        // controller.getVisitLeads()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEventsLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { attendee: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/marketing/leads/generations/list_attendees"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.event_leads = response.data.cln
        controller.getControllerByIdentifier("commercial--sales--leads--sources--events").doDataTable()

        // controller.getControllerByIdentifier("commercial--sales--leads--sources--events").leads = response.data.cln
        // controller.getLandingLeads()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const featureNames = ["sales_opportunities_entities", "sales_opportunities_leads", "sales_qualified_leads", "sales_qualified_lead_permissions", "commercial_event_attendees"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        // controller.getControllerByIdentifier("commercial--sales--leads--sources--events").getLeads()
        // controller.getEventsLeads()
        controller.finishLoadPage()

      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
        controller.chekListNameTarget.checked = false
        controller.checkListDescriptionNameTarget.innerHTML = `<span>Nome do Lead</span>`
      } else {
        controller.chekListNameTarget.checked = true
        controller.checkListDescriptionNameTarget.innerHTML = `<span><s>Nome do Lead</s></span>`
      }

      if (controller.emailInputTarget.value == ``) {
        len += 1
        controller.checkListEmailTarget.checked = false
        controller.checkListDescriptionEmailTarget.innerHTML = `<span>E-mail do Lead</span>`
      } else {
        controller.checkListEmailTarget.checked = true
        controller.checkListDescriptionEmailTarget.innerHTML = `<span><s>E-mail do Lead</s></span>`
      }

      if (controller.councilInputTarget.value == ``) {
        len += 1
        controller.checkListCouncilTarget.checked = false
        controller.checkListDescriptionCouncilTarget.innerHTML = `<span>Número do Conselho</span>`
      } else {
        controller.checkListCouncilTarget.checked = true
        controller.checkListDescriptionCouncilTarget.innerHTML = `<span><s>Número do Conselho</s></span>`
      }
      if (controller.councilStateInputTarget.value == ``) {
        len += 1
        controller.checkListCouncilStateTarget.checked = false
        controller.checkListDescriptionCouncilStateTarget.innerHTML = `<span>UF do Conselho</span>`
      } else {
        controller.checkListCouncilStateTarget.checked = true
        controller.checkListDescriptionCouncilStateTarget.innerHTML = `<span><s>UF do Conselho</s></span>`
      }

      if (controller.prefixInputTarget.value == ``) {
        len += 1
        controller.checkListPrefixTarget.checked = false
        controller.checkListDescriptionPrefixTarget.innerHTML = `<span>DDD</span>`
      } else {
        controller.checkListPrefixTarget.checked = true
        controller.checkListDescriptionPrefixTarget.innerHTML = `<span><s>DDD</s></span>`
      }
      if (controller.phoneInputTarget.value == ``) {
        len += 1
        controller.checkListPhoneTarget.checked = false
        controller.checkListDescriptionPhoneTarget.innerHTML = `<span>Telefone</span>`
      } else {
        controller.checkListPhoneTarget.checked = true
        controller.checkListDescriptionPhoneTarget.innerHTML = `<span><s>Telefone</s></span>`
      }

      if (controller.sourceInputTarget.innerText == ``) {
        len += 1
        controller.checkListSourceTarget.checked = false
        controller.checkListDescriptionSourceTarget.innerHTML = `<span>Fonte Primária</span>`
      } else {
        controller.checkListSourceTarget.checked = true
        controller.checkListDescriptionSourceTarget.innerHTML = `<span><s>Fonte Primária</s></span>`
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.notesInputTarget.value == ``) {
        len += 1
        controller.checkListNotesTarget.checked = false
        controller.checkListDescriptionNotesTarget.innerHTML = `<span>Anotações</span>`
      } else {
        controller.checkListNotesTarget.checked = true
        controller.checkListDescriptionNotesTarget.innerHTML = `<span><s>Anotações</s></span>`
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
