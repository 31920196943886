import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `financials--books--payables--dashboard--compilation`
  }

  doCompilationCard() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Despesas ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
      this.getControllerByIdentifier(`financials--books--payables--dashboard--main`).compilationTitleTarget.innerHTML = title
    }

    if (this.application.current_med.id) {
      this.application.current_date.payable_calculations.forEach(calculation => {
        if (calculation.med_id == this.application.current_med.id) {
          this.calculation = calculation
        }
      })
    } else {
      this.calculation = this.application.current_date.payable_compilation
    }

    var html = `<div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Total de Despesas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.total_amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Total Pago</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.total_paid_amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Total à Pagar</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(Number(this.calculation.total_amount) - Number(this.calculation.total_paid_amount))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Despesas Comerciais</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Pessoal</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.commercial_staff_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Despesas de Vendas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.sales_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Despesas de Marketing</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.marketing_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Despesas Operacionais</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Pessoal</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.operation_staff_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Despesas com Serviços</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.services_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2"></div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Despesas com Tecnologia</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Pessoal</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.technology_staff_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Despesas com Tecnologia</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.technology_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2"></div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Despesas Administrativas/Gerais</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Pessoal Administrativo</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.administrative_staff_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Administrativas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.administrative_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Financeiras</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.financial_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Pessoal Geral</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.general_staff_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Gerais</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(this.calculation.general_expenses)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Depreciação + Perdas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.currencyOptionMask(Number(this.calculation.grant_losses_realization) + Number(this.calculation.grant_depreciation_expenses))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--payables--dashboard--main`).compilationBoardTarget.innerHTML = html
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    const data = { compilation: { date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_compilations` } }
    const url = "/financials/books/payables/compilations/refresh"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var compilation = response.data.cln.compilation
          var calculations = response.data.cln.calculations

          controller.application.dates.forEach(date => {
            if (date.id == compilation.date_id) {
              date.payable_calculations = calculations
              date.payable_compilation = compilation
            }
          })

          if (controller.application.current_med.id) {
            controller.application.current_date.payable_calculations.forEach(calculation => {
              if (calculation.med_id == controller.application.current_med.id) {
                controller.calculation = calculation
              }
            })
          } else {
            controller.calculation = controller.application.current_date.payable_compilation
          }

          controller.doCompilationCard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
