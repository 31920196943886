import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "chatCard", "admissionsCard", "chatRow", "admissionsRow", "actionCard", "actionsRow", "8888", "9999"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.controllerName = `commercial--marketing--events--live--panel`
    this.doPanelHtml()
  }

  togglePanel(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "admission") {
      this.admissionsCardTarget.classList.add("bg-primary")
      this.chatCardTarget.classList.remove("bg-primary")
      this.actionCardTarget.classList.remove("bg-primary")
      this.admissionsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.chatRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.actionsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    } else if (feature == "chat") {
      this.admissionsCardTarget.classList.remove("bg-primary")
      this.chatCardTarget.classList.add("bg-primary")
      this.actionCardTarget.classList.remove("bg-primary")
      this.admissionsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.chatRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.actionsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    } else if (feature == "action") {
      this.admissionsCardTarget.classList.remove("bg-primary")
      this.chatCardTarget.classList.remove("bg-primary")
      this.actionCardTarget.classList.add("bg-primary")
      this.admissionsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.chatRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.actionsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
    }
  }

  doPanelHtml() {
    var html = `<div class="row my-2 w-100">
                  <div class="col-4 pl-0 pr-2">
                    <div class="card bg-primary pointer" data-${this.controllerName}-target="chatCard" data-feature="chat" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Chat</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="admissionsCard" data-feature="admission" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Participantes</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 pl-2 pr-0">
                    <div class="card pointer" data-${this.controllerName}-target="actionCard" data-feature="action" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Controle</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-${this.controllerName}-target="chatRow" data-commercial--marketing--events--live--chat-target="main" data-controller="commercial--marketing--events--live--chat">
                  <span class="w-100">${this.loader}</span>
                </div>
                <div class="d-none" data-${this.controllerName}-target="admissionsRow" data-commercial--marketing--events--live--admissions-target="main" data-controller="commercial--marketing--events--live--admissions">
                  <span class="w-100">${this.loader}</span>
                </div>
                <div class="d-none" data-${this.controllerName}-target="actionsRow" data-commercial--marketing--events--live--action-target="main" data-controller="commercial--marketing--events--live--action">
                  <span class="w-100">${this.loader}</span>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
