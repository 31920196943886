import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formBodyShop", "errorMessage", "finishBtn", "setupPartyBodyTable", "recurringPartyBodyTable", "formSetupPartyBody",
                    "formRecurringPartyBody", "setupCard", "recurringCard", "setupRow", "recurringRow", "dueAtInput", "amountInput",
                    "formDropdown", "formDropdownBtn", "formInput", "formList",
                    "descriptionInput", "shopParty"]

  connect() {
    this.controllerName = `financials--products--purchases--add--shop`
    this.feature = "setup"
    this.parties = []
    // this.doPartiesFormHtml()
  }

  disconnect() {
    this.stopRefreshing()
  }

  doShopCard() {
    if (this.hasSetup && this.hasRecurring == false) {
      this.setupCardTarget.classList.add("bg-primary")
      this.setupCardTarget.classList.add("pointer")
      this.setupRowTarget.classList.remove("d-none")
      this.recurringCardTarget.classList.add("bg-disabled")
      this.recurringCardTarget.classList.add("none")
      this.recurringCardTarget.classList.remove("bg-primary")
      this.recurringRowTarget.classList.add("d-none")
    } else if (this.hasSetup == false && this.hasRecurring) {
      this.recurringCardTarget.classList.add("bg-primary")
      this.recurringCardTarget.classList.add("pointer")
      this.recurringRowTarget.classList.remove("d-none")
      this.setupCardTarget.classList.add("bg-disabled")
      this.setupCardTarget.classList.add("none")
      this.setupCardTarget.classList.remove("bg-primary")
      this.setupRowTarget.classList.add("d-none")
    }
  }

  toggleSetupRecurring(ev) {
    this.feature = ev.currentTarget.dataset.feature

    if (this.hasSetup && this.hasRecurring) {
      if (this.feature == "setup") {
        this.setupCardTarget.classList.add("bg-primary")
        this.recurringCardTarget.classList.remove("bg-primary")
        this.setupRowTarget.classList.remove("d-none")
        this.recurringRowTarget.classList.add("d-none")
      } else if (this.feature == "recurring") {
        this.setupCardTarget.classList.remove("bg-primary")
        this.recurringCardTarget.classList.add("bg-primary")
        this.setupRowTarget.classList.add("d-none")
        this.recurringRowTarget.classList.remove("d-none")
      }
    }
  }

  doShopFormHtml() {
    var html = `<div class="col-10 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center py-2 f-065">
                      <div class="row w-100">
                        <div class="col-3 px-1">
                          <div class="card bg-primary pointer" data-${this.controllerName}-target="setupCard" data-feature="setup" data-action="click->${this.controllerName}#toggleSetupRecurring">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Setup</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 px-1">
                          <div class="card pointer" data-${this.controllerName}-target="recurringCard" data-feature="recurring" data-action="click->${this.controllerName}#toggleSetupRecurring">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Recorrência</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formBodyShop">

                      <div class="row" data-${this.controllerName}-target="setupRow">
                        <div class="col-12 my-3">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody data-${this.controllerName}-target="setupPartyBodyTable"></tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row d-none" data-${this.controllerName}-target="recurringRow">
                        <div class="col-12 my-3">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody data-${this.controllerName}-target="recurringPartyBodyTable"></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#previousStep">Voltar</button>
                      <button disabled type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveJourney" data-${this.controllerName}-target="finishBtn">Finalizar</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.hasSetup = controller.application.purchase.has_setup
      controller.hasRecurring = controller.application.purchase.has_recurring
      controller.doDataTable()
      controller.doShopCard()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doDataTable() {
    var parties = []

    this.application.parties.forEach(element => {
      if (element.party_financial == true) {
        parties[parties.length] = element
      }
    })

    parties = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(parties, `party_kind`)
    this.listData(parties)
  }

  listData(data) {
    this.setupPartyBodyTableTarget.innerHTML = ""
    this.recurringPartyBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<tr>
                      <td colspan="2" class="p-3 align-middle text-center" style="font-size:80%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-065 align-middle">Não há Partes na Contratação</span>
                      </td>
                    </tr>`

      this.setupPartyBodyTableTarget.innerHTML = noData
      this.recurringPartyBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        
        var controller = this
        if (this.hasSetup) {
          new Promise(function (resolve) {
            resolve(controller.setupPartyBodyTableTarget.insertAdjacentHTML("beforeend", controller.setupTablePartial(element, data.length)))
          }).then(() => {
            var date = new Date()
            controller.fillInDetails(element)
            controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.nameTarget(`dueAtSetupInput-${element.id}`)), "yearly", date.getFullYear())
          })
        }
        
        if (this.hasRecurring) {
          new Promise(function (resolve) {
            resolve(controller.recurringPartyBodyTableTarget.insertAdjacentHTML("beforeend", controller.recurringTablePartial(element, data.length)))
          }).then(() => {
            var date = new Date()
            controller.fillInDetails(element)
            controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.nameTarget(`startedAtRecurringInput-${element.id}`)), "yearly", date.getFullYear())
          })
        }
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  fillInDetails(party) {
    if (party.details_fill_in) {
      if (this.hasSetup) {
        this.nameTarget(`amountSetupInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(party.amount * 100)
        this.nameTarget(`dueAtSetupInput-${party.id}`).value = party.due_at_pretty
        this.nameTarget(`instalmentSetupInput-${party.id}`).value = party.instalment
        this.nameTarget(`chartSetupDropdown-${party.id}`).value = party.chart
        this.nameTarget(`chartSetupInput-${party.id}`).dataset.chart = party.chart
        this.nameTarget(`chartSetupInput-${party.id}`).innerText = party.chart_pretty
        this.nameTarget(`formSetupDropdown-${party.id}`).value = party.form
        this.nameTarget(`formSetupInput-${party.id}`).dataset.form = party.form
        this.nameTarget(`formSetupInput-${party.id}`).innerText = party.form_pretty
        this.nameTarget(`descriptionSetupInput-${party.id}`).value = party.description
      } else if (this.hasRecurring) {
        this.nameTarget(`startedAtRecurringInput-${party.id}`).value = party.started_at_pretty
        // input this.nameTarget(`dueDayRecurringInput-${party.id}`).value = party.due_day
        this.nameTarget(`dueDayRecurringDropdown-${party.id}`).value = party.due_day
        this.nameTarget(`dueDayRecurringInput-${party.id}`).dataset.day = party.due_day
        this.nameTarget(`dueDayRecurringInput-${party.id}`).innerText = party.due_day
        this.nameTarget(`loyaltyTermRecurringInput-${party.id}`).value = party.loyalty_term
        this.nameTarget(`descriptionRecurringInput-${party.id}`).value = party.description
        this.nameTarget(`loyaltyCheckbox-${party.id}`).checked = party.loyalty
        this.nameTarget(`amountRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(party.amount * 100)
        this.nameTarget(`monthlyRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(party.monthly * 100)
        this.nameTarget(`yearlyRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(party.yearly * 100)
        this.nameTarget(`chartRecurringDropdown-${party.id}`).value = party.chart
        this.nameTarget(`chartRecurringInput-${party.id}`).dataset.chart = party.chart
        this.nameTarget(`chartRecurringInput-${party.id}`).innerText = party.chart_pretty
        this.nameTarget(`formRecurringDropdown-${party.id}`).value = party.form
        this.nameTarget(`formRecurringInput-${party.id}`).dataset.form = party.form
        this.nameTarget(`formRecurringInput-${party.id}`).innerText = party.form_pretty
        this.nameTarget(`planDropdown-${party.id}`).value = party.plan
        this.nameTarget(`planRecurringInput-${party.id}`).dataset.plan = party.plan
        this.nameTarget(`planRecurringInput-${party.id}`).innerText = party.plan_pretty
        this.nameTarget(`mrrDropdown-${party.id}`).value = party.party_mrr
        this.nameTarget(`mrrInput-${party.id}`).dataset.party_mrr = party.party_mrr
        this.nameTarget(`mrrInput-${party.id}`).innerText = party.party_mrr_pretty
      }
      this.nameTarget(`shopParty-${party.id}`).dataset.shop = `set`
    } else {
      if (this.hasSetup) {
        this.nameTarget(`amountSetupInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        this.nameTarget(`dueAtSetupInput-${party.id}`).value = ``
        this.nameTarget(`instalmentSetupInput-${party.id}`).value = 1
        this.nameTarget(`chartSetupDropdown-${party.id}`).value = ``
        this.nameTarget(`chartSetupInput-${party.id}`).dataset.chart = ``
        this.nameTarget(`chartSetupInput-${party.id}`).innerText = ``
        this.nameTarget(`formSetupDropdown-${party.id}`).value = ``
        this.nameTarget(`formSetupInput-${party.id}`).dataset.form = ``
        this.nameTarget(`formSetupInput-${party.id}`).innerText = ``
        this.nameTarget(`descriptionSetupInput-${party.id}`).value = ``
      } else if (this.hasRecurring) {
        this.nameTarget(`startedAtRecurringInput-${party.id}`).value = ``
        // input this.nameTarget(`dueDayRecurringInput-${party.id}`).value = ``
        this.nameTarget(`dueDayRecurringDropdown-${party.id}`).value = ``
        this.nameTarget(`dueDayRecurringInput-${party.id}`).dataset.day = ``
        this.nameTarget(`dueDayRecurringInput-${party.id}`).innerText = ``
        this.nameTarget(`loyaltyTermRecurringInput-${party.id}`).value = ``
        this.nameTarget(`descriptionRecurringInput-${party.id}`).value = ``
        this.nameTarget(`loyaltyCheckbox-${party.id}`).checked = false
        this.nameTarget(`amountRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        this.nameTarget(`monthlyRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        this.nameTarget(`yearlyRecurringInput-${party.id}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        this.nameTarget(`chartRecurringDropdown-${party.id}`).value = ``
        this.nameTarget(`chartRecurringInput-${party.id}`).dataset.chart = ``
        this.nameTarget(`chartRecurringInput-${party.id}`).innerText = ``
        this.nameTarget(`formRecurringDropdown-${party.id}`).value = ``
        this.nameTarget(`formRecurringInput-${party.id}`).dataset.form = ``
        this.nameTarget(`formRecurringInput-${party.id}`).innerText = ``
        this.nameTarget(`planDropdown-${party.id}`).value = ``
        this.nameTarget(`planRecurringInput-${party.id}`).dataset.plan = ``
        this.nameTarget(`planRecurringInput-${party.id}`).innerText = ``
        this.nameTarget(`mrrDropdown-${party.id}`).value = ``
        this.nameTarget(`mrrInput-${party.id}`).dataset.party_mrr = ``
        this.nameTarget(`mrrInput-${party.id}`).innerText = ``
      }
      this.nameTarget(`shopParty-${party.id}`).dataset.shop = `not_set`
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.refreshSaveBtn()
  }

  setupTablePartial(element, length) {
    var saveBtn = `<button data-action="click->${this.controllerName}#saveSetup" data-${this.controllerName}-target="saveSetup-${element.id}" type="button" class="btn btn-sm btn-w-4 btn-primary p-1 my-2 mc-tooltip f-065">
                    <span>Salvar</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    var deleteBtn = `<button data-action="click->${this.controllerName}#deleteSetup" data-${this.controllerName}-target="deleteSetup-${element.id}" type="button" class="btn btn-sm btn-w-4 btn-secondary p-1 my-2 mc-tooltip f-065">
                      <span>Limpar</span>
                      <span class="mc-tooltiptext">Limpar</span>
                    </button>`


    if (length == 1) {
      var tableRow = `<tr class="itemRow tr-border-top" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow tr-border-top" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td rowspan="2" style="font-size:80%;" scope="col" class="table-20 p-1 align-middle pointer">${element.party_name}</td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="dueAtForm1">Data Vencimento</label>
                          <input aria-describedby="dueAtForm1Help" class="form-control f-065" id="dueAtForm1-${element.id}" data-${this.controllerName}-target="dueAtSetupInput-${element.id}" placeholder="Data Vencimento" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="amountForm1">Valor</label>
                          <input aria-describedby="amountForm1Help" class="form-control f-065" id="amountForm1-${element.id}" data-${this.controllerName}-target="amountSetupInput-${element.id}" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="instalmentForm1">Parcelas</label>
                          <input aria-describedby="instalmentForm1Help" class="form-control f-065" id="instalmentForm1-${element.id}" data-${this.controllerName}-target="instalmentSetupInput-${element.id}" placeholder="Valor" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="chartForm1">Tipo</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="chartSetupDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="chartSetupInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="chartSetupList" data-app--helpers--search-target="searchList">
                                <li data-chart="income" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Receita</li>
                                <li data-chart="refund" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reembolso</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="formForm">Forma</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="formSetupDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="formSetupInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="formSetupList" data-app--helpers--search-target="searchList">
                                <li data-form="all" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Todos</li>
                                <li data-form="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>
                                <li data-form="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>
                                <li data-form="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td rowspan="2" style="font-size:80%;" scope="col" class="table-5 p-1 align-middle pointer text-center" data-${this.controllerName}-target="shopParty shopParty-${element.id}" data-shop="not_set">
                      ${saveBtn}
                      ${deleteBtn}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="descriptionForm1">Descrição</label>
                          <input aria-describedby="descriptionForm1Help" class="form-control f-065" id="descriptionForm1-${element.id}" data-${this.controllerName}-target="descriptionSetupInput-${element.id}" placeholder="Descrição" type="text" required>
                        </div>
                      </div>
                    </td>
                  </tr>`

    return rowHtml
  }

  saveSetup(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id
    this.send_data = { current_user: {}, party: {} }
    
    this.send_data.party.id = partyId
    this.send_data.party.details_fill_in = true
    this.send_data.party.due_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.nameTarget(`dueAtSetupInput-${partyId}`).value)
    this.send_data.party.instalment = this.nameTarget(`instalmentSetupInput-${partyId}`).value
    this.send_data.party.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`amountSetupInput-${partyId}`).value)
    this.send_data.party.setup = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`amountSetupInput-${partyId}`).value)
    this.send_data.party.chart = this.nameTarget(`chartSetupInput-${partyId}`).dataset.chart
    this.send_data.party.form = this.nameTarget(`formSetupInput-${partyId}`).dataset.form
    this.send_data.party.description = this.nameTarget(`descriptionSetupInput-${partyId}`).value

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveSetup()
  }

  deleteSetup(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id
    this.send_data = { current_user: {}, party: {} }

    this.send_data.party.id = partyId
    this.send_data.party.details_fill_in = false
    this.send_data.party.due_at = null
    this.send_data.party.amount = 0
    this.send_data.party.setup = 0
    this.send_data.party.chart = null
    this.send_data.party.form = null
    this.send_data.party.description = ``

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveRecurring()
  }

  requestSaveSetup() {
    var url = "/financials/products/purchases/parties/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var party = response.data.cln
          if (party.details_fill_in) {
            controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `set`
          } else {
            controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `not_set`
          }
          controller.fillInDetails(party)
          controller.application.parties.forEach((element, i) => {
            if (element.id == party.id) {
              controller.application.parties.splice(controller.application.parties.indexOf(element), 1, party)
            }
          })
          controller.application.purchase.parties = controller.application.parties
        } else {
          controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `not_set`
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  recurringTablePartial(element, length) {
    var saveBtn = `<button data-action="click->${this.controllerName}#saveRecurring" data-${this.controllerName}-target="saveRecurring-${element.id}" type="button" class="btn btn-sm btn-w-4 btn-primary p-1 my-2 mc-tooltip f-065">
                    <span>Salvar</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    var deleteBtn = `<button data-action="click->${this.controllerName}#deleteRecurring" data-${this.controllerName}-target="deleteRecurring-${element.id}" type="button" class="btn btn-sm btn-w-4 btn-secondary p-1 my-2 mc-tooltip f-065">
                      <span>Limpar</span>
                      <span class="mc-tooltiptext">Limpar</span>
                    </button>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow tr-border-top" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow tr-border-top" data-id="${element.id}">`
    }

    var dueDay = `<div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="dueDayForm">Dia Vencimento</label>
                      <input aria-describedby="dueDayFormHelp" class="form-control f-065" id="dueDayForm-${element.id}" data-${this.controllerName}-target="dueDayRecurringInput-${element.id}" data-digit="2" data-action="blur->${this.controllerName}#onlyNumbers keypress->${this.controllerName}#onlyNumbers keyup->${this.controllerName}#onlyNumbers" placeholder="Dia Vencimento" type="text" required>
                    </div>
                  </div>`


    var rowHtml = `${tableRow}
                    <td rowspan="4" style="font-size:80%;" scope="col" class="table-20 p-1 align-middle pointer">${element.party_name}</td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="startedAtForm">Início</label>
                          <input aria-describedby="startedAtFormHelp" class="form-control f-065" id="startedAtForm-${element.id}" data-${this.controllerName}-target="startedAtRecurringInput-${element.id}" placeholder="Início" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="chartForm1">Dia Vencimento</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dueDayRecurringDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dueDayRecurringInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="dueDayRecurringList" data-app--helpers--search-target="searchList">
                                <li data-day="5" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">5</li>
                                <li data-day="10" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">10</li>
                                <li data-day="15" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">15</li>
                                <li data-day="20" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">20</li>
                                <li data-day="25" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">25</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="chartForm1">Tipo</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="chartRecurringDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="chartRecurringInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="chartRecurringList" data-app--helpers--search-target="searchList">
                                <li data-chart="income" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Receita</li>
                                <li data-chart="refund" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reembolso</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="formForm">Forma</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="formRecurringDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="formRecurringInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="formRecurringList" data-app--helpers--search-target="searchList">
                                <li data-form="all" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Todos</li>
                                <li data-form="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>
                                <li data-form="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>
                                <li data-form="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td rowspan="4" style="font-size:80%;" scope="col" class="table-5 p-1 align-middle pointer text-center" data-${this.controllerName}-target="shopParty shopParty-${element.id}" data-shop="not_set">
                      ${saveBtn}
                      ${deleteBtn}
                    </td>
                  </tr>
                  <tr class="itemRow" data-id="${element.id}">
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="planForm">Plano</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="planDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="planRecurringInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="planList app--helpers--search.searchList">
                                <li data-plan="monthly" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mensal</li>
                                <li data-plan="yearly" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anual</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="amountForm">Valor</label>
                          <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm-${element.id}" data-${this.controllerName}-target="amountRecurringInput-${element.id}" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#setBase"  placeholder="Valor" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="monthlyForm">Base Mensal</label>
                          <input disabled aria-describedby="monthlyFormHelp" class="form-control f-065" id="monthlyForm-${element.id}" data-${this.controllerName}-target="monthlyRecurringInput-${element.id}" placeholder="Base Mensal" type="text" required>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="yearlyForm">Base Anual</label>
                          <input disabled aria-describedby="yearlyFormHelp" class="form-control f-065" id="yearlyForm-${element.id}" data-${this.controllerName}-target="yearlyRecurringInput-${element.id}" placeholder="Base Anual" type="text" required>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label>MRR</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="mrrDropdown-${element.id}">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="mrrInput-${element.id}"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="mrrList" data-app--helpers--search-target="searchList">
                                <li data-mrr="gain" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Novo</li>
                                <li data-mrr="increase" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Expansão</li>
                                <li data-mrr="decrease" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Redução</li>
                                <li data-mrr="lost" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Perda</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                        <input type="checkbox" class="custom-control-input" id="loyaltyCheck-${element.id}" data-kind="setup" data-${this.controllerName}-target="loyaltyCheckbox-${element.id}">
                        <label class="custom-control-label f-065" for="loyaltyCheck-${element.id}">Fidelidade</label>
                      </div>
                    </td>
                    <td colspan="2" style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="loyaltyTermForm">Termos Fidelidade</label>
                          <input disabled aria-describedby="loyaltyTermFormHelp" class="form-control f-065" id="loyaltyTermForm-${element.id}" data-${this.controllerName}-target="loyaltyTermRecurringInput-${element.id}" placeholder="Termos Fidelidade" type="text" required>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="font-size:80%;" scope="col" class="table-15 p-1 align-middle pointer">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="descriptionForm">Descrição</label>
                          <input aria-describedby="descriptionFormHelp" class="form-control f-065" id="descriptionForm-${element.id}" data-${this.controllerName}-target="descriptionRecurringInput-${element.id}" placeholder="Descrição" type="text" required>
                        </div>
                      </div>
                    </td>
                  </tr>`

    return rowHtml
  }

  saveRecurring(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id
    this.send_data = { current_user: {}, party: {} }

    this.send_data.party.id = partyId
    this.send_data.party.details_fill_in = true
    // input this.send_data.party.due_day = this.nameTarget(`dueDayRecurringInput-${partyId}`).value
    this.send_data.party.due_day = this.nameTarget(`dueDayRecurringInput-${partyId}`).dataset.day
    this.send_data.party.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.nameTarget(`startedAtRecurringInput-${partyId}`).value)
    this.send_data.party.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`amountRecurringInput-${partyId}`).value)
    this.send_data.party.monthly = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`monthlyRecurringInput-${partyId}`).value)
    this.send_data.party.yearly = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`yearlyRecurringInput-${partyId}`).value)
    this.send_data.party.chart = this.nameTarget(`chartRecurringInput-${partyId}`).dataset.chart
    this.send_data.party.form = this.nameTarget(`formRecurringInput-${partyId}`).dataset.form
    this.send_data.party.plan = this.nameTarget(`planRecurringInput-${partyId}`).dataset.plan
    this.send_data.party.description = this.nameTarget(`descriptionRecurringInput-${partyId}`).value
    this.send_data.party.loyalty = this.nameTarget(`loyaltyCheckbox-${partyId}`).checked
    this.send_data.party.loyalty_term = this.nameTarget(`loyaltyTermRecurringInput-${partyId}`).value
    this.send_data.party.party_mrr = this.nameTarget(`mrrInput-${partyId}`).dataset.mrr

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveRecurring()
  }

  deleteRecurring(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id
    this.send_data = { current_user: {}, party: {} }

    this.send_data.party.id = partyId
    this.send_data.party.details_fill_in = false
    this.send_data.party.due_day = null
    this.send_data.party.started_at = null
    this.send_data.party.amount = 0
    this.send_data.party.monthly = 0
    this.send_data.party.yearly = 0
    this.send_data.party.chart = null
    this.send_data.party.form = null
    this.send_data.party.plan = null
    this.send_data.party.description = ``
    this.send_data.party.loyalty = null
    this.send_data.party.loyalty_term = ``
    this.send_data.party.party_mrr = null

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSaveRecurring()
  }

  requestSaveRecurring() {
    var url = "/financials/products/purchases/parties/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var party = response.data.cln
          if (party.details_fill_in) {
            controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `set`
          } else {
            controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `not_set`
          }
          controller.fillInDetails(party)
          controller.application.parties.forEach((element, i) => {
            if (element.id == party.id) {
              controller.application.parties.splice(controller.application.parties.indexOf(element), 1, party)
            }
          })
          controller.application.purchase.parties = controller.application.parties
        } else {
          controller.nameTarget(`shopParty-${party.id}`).dataset.shop = `not_set`
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setBase(ev) {
    var partyId = ev.currentTarget.closest(".itemRow").dataset.id
    var plan = this.nameTarget(`planRecurringInput-${partyId}`).dataset.plan
    var amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.nameTarget(`amountRecurringInput-${partyId}`).value))

    if (plan == `monthly`) {
      this.nameTarget(`monthlyRecurringInput-${partyId}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(amount * 100)
      this.nameTarget(`yearlyRecurringInput-${partyId}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(amount * 12 * 100)
    } else if (plan == `yearly`) {
      this.nameTarget(`monthlyRecurringInput-${partyId}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(amount / 12 * 100)
      this.nameTarget(`yearlyRecurringInput-${partyId}`).value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(amount * 100)
    }
  }

  saveJourney() {
    this.send_data = { current_user: {}, journey: {}, purchase: {}, note: {} }
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_journeys`

    this.send_data.journey.purchase_id = this.application.purchase.id
    this.send_data.journey.stage = `to_send_contract`
    this.send_data.journey.date = this.application.purchase.started_at
    this.send_data.purchase.journey_status = `to_send_contract`
    this.send_data.purchase.purchases = []

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.purchase.id
    this.send_data.note.domain_type = `products_purchases`
    this.send_data.note.domain_name = `products_purchases`
    this.send_data.note.date_id = this.application.purchase.date_id
    this.send_data.note.date_type = `financials_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} atualizou a Jornada da Contratação para Enviar Contrato`
    this.send_data.note.title = `Atualização`

    if (this.application.purchase.journey_size == 0) {
      this.requestSaveJourney()
    } else {
      this.finishStep()
    }

  }

  requestSaveJourney() {
    var url = "/financials/products/purchases/journeys/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          controller.application.purchase.journey_status = journey.stage
          controller.application.purchase.journey_status_pretty = journey.stage_pretty
          controller.application.purchase.journey_size += 1
          controller.finishStep()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  onlyNumbers(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").onlyNumbers(ev)
  }

  previousStep() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--products--purchases--add--parties").doPartiesFormHtml()
    this.getControllerByIdentifier("financials--products--purchases--add--dashboard").current_step = 1
    this.getControllerByIdentifier("financials--products--purchases--add--dashboard").setStep()
  }

  finishStep() {
    this.finishBtnTarget.disabled = true
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--products--purchases--dashboard--index").goToDashboard()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this

    this.application.parties.forEach(element => {
      
      controller[`refreshShopTimer-${element.id}`] = setInterval(function () {
        if (controller.nameTarget(`saveRecurring-${element.id}`) != undefined ) {
          var len = 0
          
          if (controller.hasSetup) {
            if (controller.nameTarget(`amountSetupInput-${element.id}`).value == `R$ 0,00`) {
              len += 1
            }
            if (controller.nameTarget(`dueAtSetupInput-${element.id}`).value == ``) {
              len += 1
            }
            if (controller.nameTarget(`instalmentSetupInput-${element.id}`).value == 1) {
              len += 1
            }
            if (controller.nameTarget(`chartSetupInput-${element.id}`).innerText == ``) {
              len += 1
            }
            if (controller.nameTarget(`formSetupInput-${element.id}`).innerText == ``) {
              len += 1
            }
            if (controller.nameTarget(`descriptionSetupInput-${element.id}`).value == ``) {
              len += 1
            }
          } else if (controller.hasRecurring) {
            if (controller.nameTarget(`startedAtRecurringInput-${element.id}`).value == ``) {
              len += 1
            }
            // input if (controller.nameTarget(`dueDayRecurringInput-${element.id}`).value == ``) {
            //   len += 1
            // }
            if (controller.nameTarget(`dueDayRecurringInput-${element.id}`).innerText == ``) {
              len += 1
            }
            // if (controller.nameTarget(`loyaltyTermRecurringInput-${element.id}`).value == ``) {
            //   len += 1
            // }
            if (controller.nameTarget(`descriptionRecurringInput-${element.id}`).value == ``) {
              len += 1
            }
            if (controller.nameTarget(`amountRecurringInput-${element.id}`).value == `R$ 0,00`) {
              len += 1
            }
            if (controller.nameTarget(`monthlyRecurringInput-${element.id}`).value == `R$ 0,00`) {
              len += 1
            }
            if (controller.nameTarget(`yearlyRecurringInput-${element.id}`).value == `R$ 0,00`) {
              len += 1
            }
            if (controller.nameTarget(`chartRecurringInput-${element.id}`).innerText == ``) {
              len += 1
            }
            if (controller.nameTarget(`formRecurringInput-${element.id}`).innerText == ``) {
              len += 1
            }
            if (controller.nameTarget(`planRecurringInput-${element.id}`).innerText == ``) {
              len += 1
            }
            if (controller.nameTarget(`mrrInput-${element.id}`).innerText == ``) {
              len += 1
            }
          }

          if (len == 0) {
            controller.nameTarget(`saveRecurring-${element.id}`).disabled = false
          } else {
            controller.nameTarget(`saveRecurring-${element.id}`).disabled = true
          }

        }
      }, 200);
    });    


    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFinishBtnTarget) {

        controller.shopPartyTargets.forEach(element => {
          if (element.dataset.shop == `not_set`) {
            len += 1  
          }
        })

        if (controller.application.parties.length == 0) {
          len += 1
        }

        if (len == 0) {
          controller.finishBtnTarget.disabled = false
        } else {
          controller.finishBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }
    }, 200);
  }

  stopRefreshing() {

    this.application.parties.forEach(element => {
      if (this[`refreshShopTimer-${element.id}`]) {
        clearInterval(this[`refreshShopTimer-${element.id}`])
      }
    })

    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
