import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "saveBtn", "sourceDropdown", "sourceDropdownBtn", "sourceInput", "sourceList", "nameInput",
                    "checkAtInput", "stageDropdown", "stageDropdownBtn", "stageInput", "stageList", "descriptionInput", "formCardBody",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "amountInput",
                    "campaignDropdown", "campaignDropdownBtn", "campaignInput", "campaignList", "mainCard",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList", "productAccrualDiv",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "statusCol"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--radars--save`
  }

  doFormHtml() {

    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveRadar" data-${this.controllerName}-target="saveBtn">Salvar</button>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-8 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="checkAtForm">Data de Check</label>
                            <input aria-describedby="checkAtFormHelp" class="form-control" id="checkAtForm" data-${this.controllerName}-target="checkAtInput" placeholder="Data de Check" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Fonte</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sourceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sourceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sourceList" data-app--helpers--search-target="searchList">
                                  <li data-source="base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Base</li>
                                  <li data-source="referrer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                  <li data-source="landing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing</li>
                                  <li data-source="event" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Evento</li>
                                  <li data-source="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Direto</li>
                                  <li data-source="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                  <li data-source="google" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Google</li>
                                  <li data-source="facebook" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Facebook</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Persona</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="graduated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recém-Formado</li>
                                  <li data-kind="resident" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Residente</li>
                                  <li data-kind="market" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Especialista</li>
                                  <li data-kind="consulting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>
                                  <li data-kind="clinic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clínica</li>
                                  <li data-kind="non_doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Médico</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2 d-none">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Estágio</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="stageDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="stageDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="stageInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="stageList" data-app--helpers--search-target="searchList">
                                  <li data-stage="prospecting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Prospecção</li>
                                  <li data-stage="qualification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Qualificação</li>
                                  <li data-stage="booking" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendamento</li>
                                  <li data-stage="meeting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultoria</li>
                                  <li data-stage="proposal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Proposta</li>
                                  <li data-stage="closing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Fechamento</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Campanha</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="campaignDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="campaignDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="campaignInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="campaignList" data-app--helpers--search-target="searchList">
                                  <li data-campaign="upsell_base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Upsell Base</li>
                                  <li data-campaign="recommendation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                  <li data-campaign="reactivation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reativação de Leads</li>
                                  <li data-campaign="graduation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recém-Formados</li>
                                  <li data-campaign="outbounding" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                  <li data-campaign="direct_contact" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contato Direto</li>
                                  <li data-campaign="market_events" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eventos de Mercado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control" id="amountForm" data-${this.controllerName}-target="amountInput" placeholder="Valor" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6 pl-0 pr-2 d-none" data-${this.controllerName}-target="statusCol">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                  <li data-status="realized" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Realizado</li>
                                  <li data-status="canceled" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Produto</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productNameDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productNameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productNameInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productNameList" data-app--helpers--search-target="searchList">
                                  <li data-product-name="medclinic" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                  <li data-product-name="medbooking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                  <li data-product-name="medfiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Declaração IRPF</li>
                                  <li data-product-name="medreceivement" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestão de Recebimentos</li>
                                  <li data-product-name="medbiling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                  <li data-product-name="medemployee" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Folha de Pagamento</li>
                                  <li data-product-name="medinsurance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                  <li data-product-name="medprev" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Previdência Privada</li>
                                  <li data-product-name="medloan" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito</li>
                                  <li data-product-name="medoutsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">BPO</li>
                                  <li data-product-name="medreturn" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Planner</li>
                                  <li data-product-name="medconsulting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProductName" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço Avulso</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-none">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">

                      <div class="col-4 d-none">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Serviço</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 d-none" data-${this.controllerName}-target="productPlanDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Plano</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 d-none" data-${this.controllerName}-target="productAccrualDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Competência</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>

                    <div class="row my-2">
                      <div class="col-12 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Anotações</label>
                            <textarea aria-describedby="descriptionFormHelp" class="form-control textarea px-0" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Anotações" type="text" required rows="4"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--dashboard`).viewTarget.innerHTML = html)
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.checkAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)

      if (controller.actionMode == `edit`) {
        controller.viewTitleTarget.innerText = `Editando Radar de Oportunidade para ${controller.current_radar.lead_name}`

        controller.nameInputTarget.value = controller.current_radar.lead_name
        controller.nameInputTarget.disabled = true

        controller.checkAtInputTarget.value = controller.current_radar.check_at_pretty
        controller.descriptionInputTarget.value = controller.current_radar.description
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_radar.amount)

        controller.sourceInputTarget.innerText = controller.current_radar.source_pretty
        controller.sourceInputTarget.dataset.source = controller.current_radar.source
        controller.sourceDropdownTarget.value = controller.current_radar.source
        controller.sourceDropdownBtnTarget.disabled = true

        controller.kindInputTarget.innerText = controller.current_radar.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_radar.kind
        controller.kindDropdownTarget.value = controller.current_radar.kind

        controller.campaignInputTarget.innerText = controller.current_radar.campaign_pretty
        controller.campaignInputTarget.dataset.campaign = controller.current_radar.campaign
        controller.campaignDropdownTarget.value = controller.current_radar.campaign

        controller.productNameInputTarget.innerText = controller.current_radar.product_name_pretty
        controller.productNameInputTarget.dataset.productName = controller.current_radar.product_name
        controller.productNameDropdownTarget.value = controller.current_radar.product_name

        controller.statusColTarget.classList.remove(`d-none`)
        controller.statusInputTarget.innerText = controller.current_radar.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_radar.status
        controller.statusDropdownTarget.value = controller.current_radar.status

      } else if (controller.actionMode == `new`) {
        controller.viewTitleTarget.innerText = `Novo Radar de Oportunidade para ${controller.current_lead.name}`

        controller.nameInputTarget.value = controller.current_lead.name
        controller.nameInputTarget.disabled = true
        
        // controller.checkAtInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.checkAtInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
      } else if (controller.actionMode == `opportunity`) {
        controller.viewTitleTarget.innerText = `Adicionando Radar de Oportunidade para ${controller.current_opportunity.leads[0].lead_name}`

        controller.nameInputTarget.value = controller.current_opportunity.leads[0].lead_name
        controller.nameInputTarget.disabled = true

        controller.sourceInputTarget.innerText = controller.current_opportunity.source_pretty
        controller.sourceInputTarget.dataset.source = controller.current_opportunity.source
        controller.sourceDropdownTarget.value = controller.current_opportunity.source
        controller.sourceDropdownBtnTarget.disabled = true

        controller.kindInputTarget.innerText = controller.current_opportunity.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_opportunity.kind
        controller.kindDropdownTarget.value = controller.current_opportunity.kind_pretty

        controller.campaignInputTarget.innerText = controller.current_opportunity.campaign_pretty
        controller.campaignInputTarget.dataset.campaign = controller.current_opportunity.campaign
        controller.campaignDropdownTarget.value = controller.current_opportunity.kind_pretty

        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
        controller.checkAtInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
      }
      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  selectProductName() {

  }

  cancelSave() {
    this.stopRefreshing()
    if (this.controllerDashboard.context.identifier == `commercial--sales--opportunities--radars--dashboard`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--radars--index`).clearList()
    } else if (this.controllerDashboard.context.identifier == `commercial--sales--leads--entities--dashboard`) {
      this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).clearList()
    } else if (this.controllerDashboard.context.identifier == `commercial--sales--opportunities--radars--save-modal`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--radars--save-modal`).close()
    }
  }

  saveRadar() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    this.send_data = { current_user: {}, radar: {} }

    if (this.actionMode == `edit`) {
      this.send_data.radar.id = this.current_radar.id
      this.send_data.radar.lead_id = this.current_radar.lead_id
      
    } else if (this.actionMode == `new`) {
      this.send_data.radar.status = `active`
      this.send_data.radar.lead_id = this.current_lead.id
    } else if (this.actionMode == `opportunity`) {
      this.send_data.radar.status = `active`
      this.send_data.radar.lead_id = this.current_opportunity.leads[0].lead_id
    }


    this.send_data.radar.stage = `prospecting`
    this.send_data.radar.kind = this.kindInputTarget.dataset.kind
    this.send_data.radar.campaign = this.campaignInputTarget.dataset.campaign
    this.send_data.radar.source = this.sourceInputTarget.dataset.source
    this.send_data.radar.check_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.checkAtInputTarget.value)
    this.send_data.radar.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.radar.description = this.descriptionInputTarget.value.trim()
    this.send_data.radar.product_name = this.productNameInputTarget.dataset.productName

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new` || this.actionMode == `opportunity`) {
      var url = "/commercial/sales/opportunities/radars/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/sales/opportunities/radars/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/commercial/sales/opportunities/radars/destroy"
      var method = "DELETE"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var radar = response.data.cln

          if (controller.controllerDashboard.context.identifier == `commercial--sales--opportunities--radars--dashboard`) {
            if (controller.actionMode == `new`) {
              controller.application.radars[controller.application.radars.length] = radar
            } else if (controller.actionMode == `edit`) {
              controller.application.radars.forEach((element, i) => {
                if (element.id == radar.id) {
                  controller.application.radars.splice(controller.application.radars.indexOf(element), 1, radar)
                }
              })
            } else if (controller.actionMode == `destroy`) {
              controller.application.radars.forEach((element, i) => {
                if (element.id == radar.id) {
                  controller.application.radars.splice(controller.application.radars.indexOf(element), 1)
                }
              })
            }
          // } else if (controller.controllerForm == controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--dashboard`)) {
          }

        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasFormCardBodyTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.checkAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == `` || controller.kindInputTarget.innerText == `Não Definido`) {
          len += 1
        }

        if (controller.actionMode == `edit` && controller.statusInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.campaignInputTarget.innerText == `` || controller.campaignInputTarget.innerText == `Não Definido`) {
          len += 1
        }

        if (controller.productNameInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
