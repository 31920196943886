import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn", "footerInfo"]

  connect() {
    this.controllerName = `app--communications--interactions--entities--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 11
    this.sort = { mode: `desc`, field: `interacted_at_to_time` }
    this.hasShow = false
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshInteractions" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Interações</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Interações</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Data</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">MedCapital</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Destinatário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Domínio</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Subtipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--communications--interactions--entities--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_interactions = []
    this.current_filter_interactions = []

    this.application.interactions.forEach(element => {
      if (this.application.current_account_id) {
        if (element.date_id == this.application.current_date.id && element.account_id == this.application.current_account_id) {
          this.current_interactions[this.current_interactions.length] = element
        }
      } else {
        if (element.date_id == this.application.current_date.id) {
          this.current_interactions[this.current_interactions.length] = element
        }
      }
    })

    if (this.dayFilter) {
      if (this.application.current_date.month < 10) {
        var currentMonth = `0${this.application.current_date.month}`
      } else {
        var currentMonth = `${this.application.current_date.month}`
      }

      if (this.current_day < 10) {
        var currentDay = `0${this.current_day}`
      } else {
        var currentDay = `${this.current_day}`
      }

      var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

      this.current_interactions.forEach(element => {
        if (element.interacted_at_token == currentToken) {
          this.current_filter_interactions[this.current_filter_interactions.length] = element
        }
      })

      this.current_interactions = this.current_filter_interactions
    }

    this.getControllerByIdentifier(`app--communications--interactions--entities--resume`).current_interactions = this.current_interactions
    this.uniq_persons = this.mapElements(this.current_interactions, `person_id`).filter(this.onlyUnique)
    

    if (this.sort.mode == `asc`) {
      var interactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_interactions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var interactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_interactions, this.sort.field)
    }

    if (interactions.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(interactions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (interactions[x] !== undefined) {
            internPage[internPage.length] = interactions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: interactions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Interações</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.interactionTablePartial(element, data.length))
      });
    }
  }

  interactionTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_delete) {
      var destroyBtn = `<button data-action="click->${this.controllerName}#destroyInteraction" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar Interação</span>
                        </button>`
    } else {
      var destroyBtn = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.interacted_at_full_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.person_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.channel_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.record_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.subkind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.channel_kind}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${destroyBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable" data-${this.controllerName}-target="footerInfo"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    // this.footerTableTarget.innerHTML = footerHtml
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.footerTableTarget.innerHTML = footerHtml)
    }).then(() => {
      controller.footerInfoTarget.innerHTML = `Interações Únicas: ${controller.uniq_persons.length}`
    })

  }

  destroyInteraction(ev) {
    var interactionId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.send_data = { current_user: {}, interaction: {} }
    this.actionMode = `delete`

    this.send_data.interaction.id = interactionId

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar a Interação?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.actionMode == `new`) {
      var url = "/app/communications/interactions/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/app/communications/interactions/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/app/communications/interactions/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var interaction = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.interactions[controller.application.interactions.length] = interaction
          } else if (controller.actionMode == `edit`) {
            controller.application.interactions.forEach((element, i) => {
              if (element.id == interaction.id) {
                controller.application.interactions.splice(controller.application.interactions.indexOf(element), 1, interaction)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.interactions.forEach((element, i) => {
              if (element.id == interaction.id) {
                controller.application.interactions.splice(controller.application.interactions.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    this.doDataTable()
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("app--communications--interactions--entities--dashboard").doInteractionsDashboard()
  }

  refreshInteractions() {
    this.getControllerByIdentifier(`app--communications--interactions--dashboard--main`).refreshInteractions(`interaction_table`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
