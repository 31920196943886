import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["serviceValue", "calculationBasis", "defaultFederalWithheld", "issWithheld", "totalWithheld", "irpjWithheld",
                    "csllWithheld", "pisWithheld", "cofinsWithheld", "irTotal", "csllTotal", "pisTotal", "cofinsTotal",
                    "issTotal", "totalAmount", "irDue", "csllDue", "pisDue", "cofinsDue", "issDue", "totalPayable",
                    "inssWithheld", "inssToPay", "inssTotalAmount", "unconditioned", "conditioned", "otherAmountWithheld",
                    "totalTaxWithheld", "netReceivable", "issAliquot", "issFixed", "totalTaxAmount", "inssWithheldCheck",
                    "defaultIssWithheld", "defaultIrpjWithheld", "allowWithheldCheck"]


  connect() {
    this.controllerName = "operations--products--clinics--financials--add--presumed-profit"
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    
    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }
    
    // this.saveController.send_data = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`).send_data
    this.doPresumedProfitFormHtml()
  }

  getData() {
    this.saveController.send_data.receivable.calculation_basis = this.calculationBasis
    this.saveController.send_data.receivable.irpj_amount_withheld = this.irpjWithheld
    this.saveController.send_data.receivable.pis_amount_withheld = this.pisWithheld
    this.saveController.send_data.receivable.cofins_amount_withheld = this.cofinsWithheld
    this.saveController.send_data.receivable.csll_amount_withheld = this.csllWithheld
    this.saveController.send_data.receivable.inss_amount_withheld = this.inssWithheld
    this.saveController.send_data.receivable.iss_amount_withheld = this.issWithheld
    this.saveController.send_data.receivable.iss_rate = this.saveController.current_regime.iss_rate
    this.saveController.send_data.receivable.iss_tax_amount = Number(this.saveController.current_regime.iss_rate) * Number(this.saveController.send_data.receivable.total_amount) / 100
    this.saveController.send_data.receivable.federal_withheld = this.federalWithheld
    this.saveController.send_data.receivable.municipal_withheld = this.municipalWithheld
    this.saveController.send_data.receivable.deductions_amount = this.totalWithheld
    this.saveController.send_data.receivable.unconditioned_amount = this.unconditioned
    this.saveController.send_data.receivable.conditioned_amount = this.conditioned
    this.saveController.send_data.receivable.others_amount_withheld = this.otherAmountWithheld
    this.saveController.send_data.receivable.net_amount = this.netReceivable
    this.saveController.send_data.receivable.total_tax_amount = this.totalTaxAmount
    this.saveController.send_data.receivable.total_tax_withheld = this.totalTaxWithheld
    this.saveController.send_data.receivable.total_tax_payable = this.totalPayable
    this.saveController.send_data.receivable.total_practice_tax_amount = this.normalTaxAmount
    this.saveController.send_data.receivable.total_procedure_tax_amount = this.hospitalTaxAmount
    this.saveController.send_data.receivable.regime_name = this.saveController.current_regime.tax_regime

    var performers = []
    this.saveController.send_data.performers.performers.forEach(element => {
      var performer = {}
      var rate = element.amount / this.saveController.send_data.receivable.total_amount
      if (this.saveController.send_data.receivable.total_practice_revenues > 0) {
        var normalRate = element.total_practice_revenues / this.saveController.send_data.receivable.total_practice_revenues
      } else {
        var normalRate = 0
      }
      if (this.saveController.send_data.receivable.total_procedure_revenues > 0) {
        var hospitalRate = element.total_procedure_revenues / this.saveController.send_data.receivable.total_procedure_revenues
      } else {
        var hospitalRate = 0
      }

      performer = element
      performer.irpj_amount_withheld = this.saveController.send_data.receivable.irpj_amount_withheld * rate
      performer.pis_amount_withheld = this.saveController.send_data.receivable.pis_amount_withheld * rate
      performer.cofins_amount_withheld = this.saveController.send_data.receivable.cofins_amount_withheld * rate
      performer.csll_amount_withheld = this.saveController.send_data.receivable.csll_amount_withheld * rate
      performer.inss_amount_withheld = this.saveController.send_data.receivable.inss_amount_withheld * rate
      performer.iss_amount_withheld = this.saveController.send_data.receivable.iss_amount_withheld * rate
      performer.total_amount_withheld = this.saveController.send_data.receivable.total_tax_withheld * rate
      performer.net_amount = this.saveController.send_data.receivable.net_amount * rate

      performer.total_practice_tax_amount = this.saveController.send_data.receivable.total_practice_tax_amount * normalRate
      performer.total_procedure_tax_amount = this.saveController.send_data.receivable.total_procedure_tax_amount * hospitalRate
      performer.tax_amount = Number(performer.total_practice_tax_amount) + Number(performer.total_procedure_tax_amount)

      performers[performers.length] = performer
    })

    this.saveController.send_data.performers.performers = performers

  }

  doPresumedProfitFormHtml() {

    // `<div class="col-2 my-3 d-flex align-items-center">
    //   <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
    //     <input disabled type="checkbox" class="custom-control-input" id="issAliquot" data-action="click->${this.controllerName}#bindTax" data-${this.controllerName}-target="issAliquot">
    //     <label class="custom-control-label f-065 pointer ml-2" for="issAliquot">ISS Alíquota</label>
    //   </div>
    // </div>
    // <div class="col-2 my-3 d-flex align-items-center">
    //   <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
    //     <input disabled type="checkbox" class="custom-control-input" id="issFixed" data-action="click->${this.controllerName}#bindTax" data-${this.controllerName}-target="issFixed">
    //     <label class="custom-control-label f-065 pointer ml-2" for="issFixed">ISS Fixo</label>
    //   </div>
    // </div>`

    var html = `<div class="row my-3">
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="serviceValue">Valor Serviços</label>
                        <input aria-describedby="serviceValueHelp" class="form-control" id="serviceValue" data-${this.controllerName}-target="serviceValue" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="calculationBasis">Base de Cálculo</label>
                        <input aria-describedby="calculationBasisHelp" class="form-control" id="calculationBasis" data-${this.controllerName}-target="calculationBasis" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalTaxAmount">Total de Impostos</label>
                        <input aria-describedby="totalTaxAmountHelp" class="form-control" id="totalTaxAmount" data-${this.controllerName}-target="totalTaxAmount" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="defaultFederalWithheld" data-action="click->${this.controllerName}#defaultFederalWithheld" data-${this.controllerName}-target="defaultFederalWithheld">
                      <label class="custom-control-label f-065 pointer ml-2" for="defaultFederalWithheld">Ret. Contribuições</label>
                    </div>
                  </div>
                  <div class="col-2 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="defaultIrpjWithheld" data-action="click->${this.controllerName}#defaultIrpjWithheld" data-${this.controllerName}-target="defaultIrpjWithheld">
                      <label class="custom-control-label f-065 pointer ml-2" for="defaultIrpjWithheld">Ret. IRPJ</label>
                    </div>
                  </div>
                  <div class="col-2 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="defaultIssWithheld" data-action="click->${this.controllerName}#defaultIssWithheld" data-${this.controllerName}-target="defaultIssWithheld">
                      <label class="custom-control-label f-065 pointer ml-2" for="defaultIssWithheld">Ret. ISS</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="allowWithheld" data-action="click->${this.controllerName}#checkAllowWithheld" data-${this.controllerName}-target="allowWithheldCheck">
                      <label class="custom-control-label f-065 pointer ml-2" for="allowWithheld">S/ Retenções</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="pisWithheld">PIS Retido</label>
                        <input aria-describedby="pisWithheldHelp" class="form-control" id="pisWithheld" data-${this.controllerName}-target="pisWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="cofinsWithheld">COFINS Retido</label>
                        <input aria-describedby="cofinsWithheldHelp" class="form-control" id="cofinsWithheld" data-${this.controllerName}-target="cofinsWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="csllWithheld">CSLL Retido</label>
                        <input aria-describedby="csllWithheldHelp" class="form-control" id="csllWithheld" data-${this.controllerName}-target="csllWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="irpjWithheld">IRPJ Retido</label>
                        <input aria-describedby="irpjWithheldHelp" class="form-control" id="irpjWithheld" data-${this.controllerName}-target="irpjWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="issWithheld">ISS Retido</label>
                        <input aria-describedby="issWithheldHelp" class="form-control" id="issWithheld" data-${this.controllerName}-target="issWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row">
                  <div class="col-2 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="inssWithheldCheck" data-action="click->${this.controllerName}#inssWithheldCheck" data-${this.controllerName}-target="inssWithheldCheck">
                      <label class="custom-control-label f-065 pointer ml-2" for="inssWithheldCheck">Retenção INSS</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="inssWithheld">INSS Retido</label>
                        <input aria-describedby="inssWithheldHelp" class="form-control" id="inssWithheld" data-${this.controllerName}-target="inssWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="inssToPay">INSS Devido</label>
                        <input aria-describedby="inssToPayHelp" class="form-control" id="inssToPay" data-${this.controllerName}-target="inssToPay" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="inssTotalAmount">INSS Total</label>
                        <input aria-describedby="inssTotalAmountHelp" class="form-control" id="inssTotalAmount" data-${this.controllerName}-target="inssTotalAmount" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalTaxWithheld">Impostos Retidos</label>
                        <input aria-describedby="totalTaxWithheldHelp" class="form-control" id="totalTaxWithheld" data-${this.controllerName}-target="totalTaxWithheld" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="unconditioned">D. Incondicional</label>
                        <input aria-describedby="unconditionedHelp" class="form-control" id="unconditioned" data-${this.controllerName}-target="unconditioned" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="conditioned">D. Condicional</label>
                        <input aria-describedby="conditionedHelp" class="form-control" id="conditioned" data-${this.controllerName}-target="conditioned" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="otherAmountWithheld">Retenções / Descontos</label>
                        <input aria-describedby="otherAmountWithheldHelp" class="form-control" id="otherAmountWithheld" data-${this.controllerName}-target="otherAmountWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalWithheld">Total Retido</label>
                        <input aria-describedby="totalWithheld" class="form-control" id="totalWithheld" data-${this.controllerName}-target="totalWithheld" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalPayable">Total Impostos à Pagar</label>
                        <input aria-describedby="totalPayableHelp" class="form-control" id="totalPayable" data-${this.controllerName}-target="totalPayable" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="netReceivable">Líquido a Receber</label>
                        <input aria-describedby="netReceivableHelp" class="form-control" id="netReceivable" data-${this.controllerName}-target="netReceivable" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").formTarget.innerHTML = html)
    }).then(() => {
      controller.setFieldValues()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setFieldValues() {    
    this.serviceValueTarget.value = this.numberController.currencyOptionMask(this.saveController.send_data.receivable.total_amount, { scale: 2 })
    this.calculationBasis = this.saveController.send_data.receivable.total_amount
    this.calculationBasisTarget.value = this.numberController.currencyOptionMask(this.calculationBasis, { scale: 2 })

    // if (this.saveController.current_regime.iss_type == `aliquot`) {
    //   this.issAliquotTarget.checked = true
    //   this.issFixedTarget.checked = false
    // } else if (this.saveController.current_regime.iss_type == `fixed`) {
    //   this.issAliquotTarget.checked = false
    //   this.issFixedTarget.checked = true
    // }

    if (this.saveController.send_data.receivable.taker_type == `legal`) {
      this.defaultIrpjWithheldTarget.checked = true
      this.defaultIrpjWithheldTarget.disabled = false
      this.defaultFederalWithheldTarget.checked = true
      this.defaultFederalWithheldTarget.disabled = false
      this.defaultIssWithheldTarget.checked = true
      this.defaultIssWithheldTarget.disabled = false
      this.inssWithheldCheckTarget.checked = false
      this.inssWithheldCheckTarget.disabled = false
      // this.defaultIssWithheld.checked = true
      // this.defaultIssWithheld.disabled = false
      this.defaulLegalIrpjWithheld()
      this.defaulLegalFederalWithheld()
      this.defaulLegalIssWithheld()
    } else if (this.saveController.send_data.receivable.taker_type == `individual`) {
      this.defaultIrpjWithheldTarget.checked = false
      this.defaultIrpjWithheldTarget.disabled = true
      this.defaultFederalWithheldTarget.checked = false
      this.defaultFederalWithheldTarget.disabled = true
      this.defaultIssWithheldTarget.checked = false
      this.defaultIssWithheldTarget.disabled = true
      this.inssWithheldCheckTarget.checked = false
      this.inssWithheldCheckTarget.disabled = true
      this.allowWithheldCheckTarget.checked = false
      this.allowWithheldCheckTarget.disabled = true
      this.unconditionedTarget.disabled = true
      this.conditionedTarget.disabled = true
      this.otherAmountWithheldTarget.disabled = true
      // this.defaultIssWithheld.checked = false
      // this.defaultIssWithheld.disabled = true
      this.defaulIndividualWithheld()
    }
    
    this.setTotalTaxAmount()

    this.unconditioned = 0
    this.unconditionedTarget.value = this.numberController.currencyOptionMask(this.unconditioned, { scale: 2 })
    this.conditioned = 0
    this.conditionedTarget.value = this.numberController.currencyOptionMask(this.conditioned)
    this.otherAmountWithheld = 0
    this.otherAmountWithheldTarget.value = this.numberController.currencyOptionMask(this.otherAmountWithheld)

    // this.numberController.percentOptionMask(element.iss_rate, { scale: 5 })
    // this.numberController.currencyOptionMask(element.iss_rate, { scale: 5 })
    // numberController.currencyMask
    this.bindInssCheck(false)
    this.bindTax()
  }

  setTotalTaxAmount() {
    this.totalTaxAmount = 0

    if (this.saveController.current_regime.sub_tax_regime == `hospital_match`) {
      this.normalTaxAmount = 0
      this.hospitalTaxAmount = 0
      var normalTax = 0
      var hospitalTax = 0

      normalTax = Number(11.33) + Number(Number(this.saveController.current_regime.effective_iss))
      hospitalTax = Number(5.93) + Number(Number(this.saveController.current_regime.effective_iss))

      this.normalTaxAmount = Number(normalTax) * Number(this.saveController.send_data.receivable.total_practice_revenues) / 100
      this.hospitalTaxAmount = Number(hospitalTax) * Number(this.saveController.send_data.receivable.total_procedure_revenues) / 100
      this.totalTaxAmount = Number(this.normalTaxAmount) + Number(this.hospitalTaxAmount)

    } else if (this.saveController.current_regime.sub_tax_regime == `normal`) {
      this.normalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_practice_revenues) / 100
      this.hospitalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_procedure_revenues) / 100

      this.totalTaxAmount = Number(this.normalTaxAmount) + Number(this.hospitalTaxAmount)
    }

    this.totalTaxAmountTarget.value = this.numberController.currencyOptionMask(this.totalTaxAmount)
  }

  bindTax() {

    this.irpjWithheld = this.numberController.fromCurrencyToNumber(this.irpjWithheldTarget.value)
    this.csllWithheld = this.numberController.fromCurrencyToNumber(this.csllWithheldTarget.value)
    this.pisWithheld = this.numberController.fromCurrencyToNumber(this.pisWithheldTarget.value)
    this.cofinsWithheld = this.numberController.fromCurrencyToNumber(this.cofinsWithheldTarget.value)
    this.issWithheld = this.numberController.fromCurrencyToNumber(this.issWithheldTarget.value)
    this.inssWithheld = this.numberController.fromCurrencyToNumber(this.inssWithheldTarget.value)
    this.unconditioned = this.numberController.fromCurrencyToNumber(this.unconditionedTarget.value)
    this.conditioned = this.numberController.fromCurrencyToNumber(this.conditionedTarget.value)

    this.totalTaxWithheld = Number(this.irpjWithheld) + Number(this.csllWithheld) + Number(this.pisWithheld) + Number(this.cofinsWithheld) + Number(this.issWithheld) + Number(this.inssWithheld)
    this.totalTaxWithheldTarget.value = this.numberController.currencyOptionMask(this.totalTaxWithheld, { scale: 2 })

    this.federalWithheld = Number(this.irpjWithheld) + Number(this.csllWithheld) + Number(this.pisWithheld) + Number(this.cofinsWithheld) + Number(this.inssWithheld)
    this.municipalWithheld = Number(this.issWithheld)

    this.otherAmountWithheld = this.numberController.fromCurrencyToNumber(this.otherAmountWithheldTarget.value)
    this.totalWithheld = Number(this.totalTaxWithheld) + Number(this.otherAmountWithheld) + Number(this.unconditioned) + Number(this.conditioned)
    this.totalWithheldTarget.value = this.numberController.currencyOptionMask(this.totalWithheld, { scale: 2 })

    this.totalPayable = Number(this.totalTaxAmount) - Number(this.totalTaxWithheld)
    this.totalPayableTarget.value = this.numberController.currencyOptionMask(this.totalPayable, { scale: 2 })

    this.netReceivable = Number(this.saveController.send_data.receivable.total_amount) - Number(this.totalWithheld)
    this.netReceivableTarget.value = this.numberController.currencyOptionMask(this.netReceivable, { scale: 2 })
  }

  defaultIrpjWithheld(ev) {
    var defaultIrpjWithheld = ev.currentTarget.checked

    if (this.saveController.send_data.receivable.taker_type == `legal`) {
      this.defaulLegalIrpjWithheld()
    }
  }

  defaultFederalWithheld(ev) {
    var defaultFederalWithheld = ev.currentTarget.checked

    if (this.saveController.send_data.receivable.taker_type == `legal`) {
      this.defaulLegalFederalWithheld()
    }
  }

  defaultIssWithheld(ev) {
    var defaultIssWithheld = ev.currentTarget.checked

    if (this.saveController.send_data.receivable.taker_type == `legal`) {
      this.defaulLegalIssWithheld()
    }
  }

  defaulLegalIrpjWithheld() {
    if (this.defaultIrpjWithheldTarget.checked) {
      this.irpjWithheldTarget.disabled = true
      this.irpjWithheld = Number(this.saveController.send_data.receivable.total_amount) * Number(this.saveController.current_regime.presumed_withheld_irpj) / 100
    } else {
      this.irpjWithheldTarget.disabled = false
      this.irpjWithheld = 0
    }

    this.irpjWithheldTarget.value = this.numberController.currencyOptionMask(this.irpjWithheld, { scale: 2 })
    this.bindTax()
  }

  defaulLegalFederalWithheld() {
    if (this.defaultFederalWithheldTarget.checked) {
      this.csllWithheldTarget.disabled = true
      this.pisWithheldTarget.disabled = true
      this.cofinsWithheldTarget.disabled = true

      this.csllWithheld = Number(this.saveController.send_data.receivable.total_amount) * Number(this.saveController.current_regime.presumed_withheld_csll) / 100
      this.cofinsWithheld = Number(this.saveController.send_data.receivable.total_amount) * Number(this.saveController.current_regime.presumed_withheld_cofins) / 100
      this.pisWithheld = Number(this.saveController.send_data.receivable.total_amount) * Number(this.saveController.current_regime.presumed_withheld_pis) / 100
    } else {
      this.csllWithheldTarget.disabled = false
      this.pisWithheldTarget.disabled = false
      this.cofinsWithheldTarget.disabled = false

      this.csllWithheld = 0
      this.pisWithheld = 0
      this.cofinsWithheld = 0
    }

    this.csllWithheldTarget.value = this.numberController.currencyOptionMask(this.csllWithheld, { scale: 2 })
    this.pisWithheldTarget.value = this.numberController.currencyOptionMask(this.pisWithheld, { scale: 2 })
    this.cofinsWithheldTarget.value = this.numberController.currencyOptionMask(this.cofinsWithheld, { scale: 2 })

    this.bindTax()
  }

  defaulLegalIssWithheld() {
    if (this.defaultIssWithheldTarget.checked) {
      this.issWithheldTarget.disabled = true
      this.issWithheld = Number(this.saveController.send_data.receivable.total_amount) * Number(this.saveController.current_regime.presumed_withheld_iss) / 100
    } else {
      this.issWithheldTarget.disabled = false
      this.issWithheld = 0
    }

    if (this.saveController.current_regime.iss_type == `fixed`) {
      this.issWithheldTarget.disabled = true
      this.defaultIssWithheldTarget.checked = false
      this.defaultIssWithheldTarget.disabled = true
      this.issWithheld = 0
    }

    this.issWithheldTarget.value = this.numberController.currencyOptionMask(this.issWithheld, { scale: 2 })
    this.bindTax()
  }

  checkAllowWithheld(ev) {
    var value = ev.currentTarget.checked
    this.allowWithheld(value)
  }

  allowWithheld(value) {
    if (value) {
      this.defaultFederalWithheldTarget.checked = false
      this.defaultFederalWithheldTarget.disabled = true
      this.defaultIrpjWithheldTarget.checked = false
      this.defaultIrpjWithheldTarget.disabled = true
      this.defaultIssWithheldTarget.checked = false
      this.defaultIssWithheldTarget.disabled = true
      this.inssWithheldCheckTarget.checked = false
      this.inssWithheldCheckTarget.disabled = true

      this.irpjWithheldTarget.disabled = true
      this.csllWithheldTarget.disabled = true
      this.pisWithheldTarget.disabled = true
      this.cofinsWithheldTarget.disabled = true
      this.issWithheldTarget.disabled = true
      this.inssWithheldTarget.disabled = true

      this.irpjWithheld = 0
      this.csllWithheld = 0
      this.pisWithheld = 0
      this.cofinsWithheld = 0
      this.issWithheld = 0
      this.inssWithheld = 0
      this.unconditioned = 0
      this.conditioned = 0
      this.otherAmountWithheld = 0

      this.irpjWithheldTarget.value = this.numberController.currencyOptionMask(this.irpjWithheld, { scale: 2 })
      this.csllWithheldTarget.value = this.numberController.currencyOptionMask(this.csllWithheld, { scale: 2 })
      this.pisWithheldTarget.value = this.numberController.currencyOptionMask(this.pisWithheld, { scale: 2 })
      this.cofinsWithheldTarget.value = this.numberController.currencyOptionMask(this.cofinsWithheld, { scale: 2 })
      this.issWithheldTarget.value = this.numberController.currencyOptionMask(this.issWithheld, { scale: 2 })
      this.inssWithheldTarget.value = this.numberController.currencyOptionMask(this.issWithheld, { scale: 2 })
      this.unconditionedTarget.value = this.numberController.currencyOptionMask(this.unconditioned, { scale: 2 })
      this.conditionedTarget.value = this.numberController.currencyOptionMask(this.conditioned, { scale: 2 })
      this.otherAmountWithheldTarget.value = this.numberController.currencyOptionMask(this.otherAmountWithheld, { scale: 2 })

      this.unconditionedTarget.disabled = true
      this.conditionedTarget.disabled = true
      this.otherAmountWithheldTarget.disabled = true
    } else {
      this.defaultFederalWithheldTarget.checked = false
      this.defaultFederalWithheldTarget.disabled = false
      this.defaultIrpjWithheldTarget.checked = false
      this.defaultIrpjWithheldTarget.disabled = false
      this.defaultIssWithheldTarget.checked = false
      this.defaultIssWithheldTarget.disabled = false
      this.inssWithheldCheckTarget.checked = false
      this.inssWithheldCheckTarget.disabled = false

      this.unconditionedTarget.disabled = false
      this.conditionedTarget.disabled = false
      this.otherAmountWithheldTarget.disabled = false
    }
    this.bindTax()
  }

  defaulIndividualWithheld() {
    this.irpjWithheldTarget.disabled = true
    this.csllWithheldTarget.disabled = true
    this.pisWithheldTarget.disabled = true
    this.cofinsWithheldTarget.disabled = true
    this.issWithheldTarget.disabled = true
    this.inssWithheldTarget.disabled = true

    this.irpjWithheld = 0
    this.csllWithheld = 0
    this.pisWithheld = 0
    this.cofinsWithheld = 0
    this.issWithheld = 0
    this.inssWithheld = 0
    
    this.irpjWithheldTarget.value = this.numberController.currencyOptionMask(this.irpjWithheld, { scale: 2 })
    this.csllWithheldTarget.value = this.numberController.currencyOptionMask(this.csllWithheld, { scale: 2 })
    this.pisWithheldTarget.value = this.numberController.currencyOptionMask(this.pisWithheld, { scale: 2 })
    this.cofinsWithheldTarget.value = this.numberController.currencyOptionMask(this.cofinsWithheld, { scale: 2 })
    this.issWithheldTarget.value = this.numberController.currencyOptionMask(this.issWithheld, { scale: 2 })
    this.inssWithheldTarget.value = this.numberController.currencyOptionMask(this.issWithheld, { scale: 2 })

    this.bindTax()
  }

  inssWithheldCheck(ev) {
    var inssCheck = ev.currentTarget.checked

    this.bindInssCheck(inssCheck)  
  }

  bindInssCheck(inssCheck) {

    if (inssCheck) {
      this.inssWithheldTarget.disabled = false
      this.inssToPayTarget.disabled = true
      this.inssTotalAmountTarget.disabled = true
    } else {
      this.inssWithheldTarget.disabled = true
      this.inssToPayTarget.disabled = true
      this.inssTotalAmountTarget.disabled = true

      this.inssWithheld = 0
      this.inssToPay = 0
      this.inssTotalAmount = 0
      this.inssWithheldTarget.value = this.numberController.currencyOptionMask(this.inssWithheld, { scale: 2 })
      this.inssToPayTarget.value = this.numberController.currencyOptionMask(this.inssToPay, { scale: 2 })
      this.inssTotalAmountTarget.value = this.numberController.currencyOptionMask(this.inssTotalAmount, { scale: 2 })
    }

  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}


// deductions_amount = ?

// source = ?
// version = ?
// total_rate = ?
