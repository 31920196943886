import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewContractCard", "viewTitle", "viewContractCardBody", "previewCard", "editContractBtn",
    "deleteContractBtn", "asdf", "9999"]

  connect() {
    this.controllerName = `financials--books--payrolls--contracts--show`
  }

  doViewHtml() {
    if (this.getControllerByIdentifier("financials--books--payrolls--contracts--save")) {
      this.getControllerByIdentifier("financials--books--payrolls--contracts--save").stopRefreshing()
    }
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewContractCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Contrato Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewContractCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--payrolls--contracts--dashboard").viewTarget.innerHTML = html
  }

  editContract() {
    this.getControllerByIdentifier("financials--books--payrolls--contracts--save").current_contract = this.current_contract
    this.getControllerByIdentifier("financials--books--payrolls--contracts--save").actionMode = `edit`
    this.getControllerByIdentifier("financials--books--payrolls--contracts--save").doFormHtml()
  }

  deleteContract() {
    var r = confirm("Tem certeza que deseja apagar o Contrato?")
    this.deleteContractBtnTarget.disabled = true

    if (r) {
      this.send_data = { current_user: {}, contract: {} }

      this.send_data.contract.id = this.current_contract.id
      this.send_data.contract.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.getControllerByIdentifier("financials--books--payrolls--contracts--save").current_contract = this.current_contract
      this.getControllerByIdentifier("financials--books--payrolls--contracts--save").send_data = this.send_data
      this.getControllerByIdentifier("financials--books--payrolls--contracts--save").actionMode = `destroy`
      this.getControllerByIdentifier("financials--books--payrolls--contracts--save").requestSave()

    }
  }

  doContractHtml() {
    this.getControllerByIdentifier(`financials--books--payrolls--contracts--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_delete && this.application.current_user.super_admin) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteContractBtn" data-action="click->${this.controllerName}#deleteContract" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Contrato</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editContractBtn" data-action="click->${this.controllerName}#editContract" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Contrato</span>`
    } else {
      var editBtnHtml = ``
    }

    var finishedHtml = ``
    if (this.current_contract.finished) {
      finishedHtml = `<tr>
                        <th colspan="3" class="f-065 align-middle px-0">
                          <strong>Término:</strong> <br>
                        </th>
                        <td colspan="5" class="f-065 align-middle px-0 text-left">
                          ${this.current_contract.finished_at_pretty}
                        </td>
                      </tr>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Contrato de Trabalho</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Nome:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_contract.account_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Início:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_contract.started_at_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Área:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_contract.role_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Contrato:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_contract.name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Atual:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_contract.current_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Status:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_contract.status_pretty}
                              </td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payrolls--contracts--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.65) + "px"

      if (controller.current_contract.has_renewal) {
        controller.deleteContractBtnTarget.remove()
        controller.editContractBtnTarget.remove()
      }

      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getClientsContracts() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var data = { contract: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    var url = "/financials/books/clients/contracts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.contracts = response.data.cln
        controller.showRenewalModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showRenewalModal() {
    this.current_renewal = {}
    var modal = `financials--books--clients--renewals--save-modal`

    this.application.contracts.forEach(element => {
      if (element.id == this.current_contract.contract_id) {
        this.current_contract = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).current_client = controller.current_client
      controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
      controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
      controller.getControllerByIdentifier(modal).actionMode = "new"
      controller.getControllerByIdentifier(modal).open()
    })
  }

  saveRenewal() {
    if (this.current_renewal.id) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.send_data = { current_user: {}, contract: {}, tracker: { manual: false } }

      this.send_data.contract.id = this.current_contract.id
      this.send_data.contract.renewal_id = this.current_renewal.id

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.requestSave()
    }
  }

  requestSave() {

    var url = "/financials/books/clients/contracts/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.current_contract = response.data.cln
          controller.application.contracts.forEach((element, i) => {
            if (element.id == controller.current_contract.id) {
              controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, controller.current_contract)
            }
          })
        }

        controller.doContractHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
