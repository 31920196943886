import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "labelUpload", "uploadBtn", "fileName", "fileUpload", "saveBtn",
    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "billLink", "invoiceLink", "receiptLink",
    "progressUpload", "progressUploadBar", "progressUploadCounter", "body", "modal"]


  connect() {
    this.controllerName = `operations--products--clinics--financials--expenses--upload`
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadFeature = true
    this.uploadReady = false

    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
    }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--financials--expenses--show").doViewHtml()
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.current_expense.has_bill) {
      var bill = `<span class="f-085 text-bold">Boleto</span>
                          <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_expense.bill_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Boleto</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.current_expense.id}" data-field="bill" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Boleto</span>
                          </button>`
    } else {
      var bill = `<span class="f-085 text-bold">Sem Arquivo Boleto</span>`
    }

    if (this.current_expense.has_invoice) {
      var invoice = `<span class="f-085 text-bold">Nota Fiscal</span>
                         <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_expense.invoice_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Nota Fiscal</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.current_expense.id}" data-field="invoice" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Nota Fiscal</span>
                          </button>`
    } else {
      var invoice = `<span class="f-085 text-bold">Sem Arquivo Nota Fiscal</span>`
    }

    if (this.current_expense.has_receipt) {
      var receipt = `<span class="f-085 text-bold">Comprovante</span>
                         <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_expense.receipt_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Comprovante</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.current_expense.id}" data-field="receipt" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Comprovante</span>
                          </button>`
    } else {
      var receipt = `<span class="f-085 text-bold">Sem Arquivo Comprovante</span>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-3">
                      <div class="col-6 d-flex align-items-center justify-content-center">
                        <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-6 d-flex align-items-center justify-content-center">
                        <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                          <div class="row my-1">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-white">cloud_upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
                      <div class="col-6 pr-2">
                        <span data-${this.controllerName}-target="fileName"></span>
                      </div>
                      <div class="col-6 pl-2">
                        <div class="form-group mb-0">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="bill" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto</li>
                                  <li data-kind="invoice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nota Fiscal</li>
                                  <li data-kind="receipt" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comprovante</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row w-100 mt-5">
                      <div class="col-12 d-flex align-items-center">
                        <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                          <div class="col-12 my-1 d-flex px-0" data-${this.controllerName}-target="billLink">
                            ${bill}
                          </div>
                          <div class="col-12 my-1 d-flex px-0" data-${this.controllerName}-target="invoiceLink">
                            ${invoice}
                          </div>
                          <div class="col-12 my-1 d-flex px-0" data-${this.controllerName}-target="receiptLink">
                            ${receipt}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.formTitleTarget.innerText = `Boleto | NF | Comprovante | ${this.current_expense.description}`


      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
        obj.file = element
        this.upload_file = obj
        this.uploadReady = true
        this.fileUploadTarget.classList.remove("d-none")
        this.fileNameTarget.innerText = this.upload_file.file_name
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`

      var controller = this
      if (this.kindInputTarget.dataset.kind == `bill`) {
        new Promise(function (resolve) {
          resolve(controller.billLinkTarget.innerHTML = progressSpan)
        }).then(() => {
          controller.progressCount(0)
          controller.upload_file.id = controller.current_expense.id
          controller.requestUpload(controller.current_expense.id, controller.upload_file.file, controller.kindInputTarget.dataset.kind)
        })
      } else if (this.kindInputTarget.dataset.kind == `invoice`) {
        new Promise(function (resolve) {
          resolve(controller.invoiceLinkTarget.innerHTML = progressSpan)
        }).then(() => {
          controller.progressCount(0)
          controller.upload_file.id = controller.current_expense.id
          controller.requestUpload(controller.current_expense.id, controller.upload_file.file, controller.kindInputTarget.dataset.kind)
        })
      } else if (this.kindInputTarget.dataset.kind == `receipt`) {
        new Promise(function (resolve) {
          resolve(controller.receiptLinkTarget.innerHTML = progressSpan)
        }).then(() => {
          controller.progressCount(0)
          controller.upload_file.id = controller.current_expense.id
          controller.requestUpload(controller.current_expense.id, controller.upload_file.file, controller.kindInputTarget.dataset.kind)
        })
      }

    }
  }

  requestUpload(expense_id, file, kind) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', kind)
    formData.append('feature', this.permission)
    formData.append('expense_id', expense_id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/operations/products/clinics/financials/expenses/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()

          var expense = response.data.cln
          controller.current_expense = expense

          controller.application.expenses.forEach(element => {
            if (element.id == expense.id) {
              controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, expense)
            }
          })

          controller.doFormHtml()
        }

        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile(ev) {
    var expenseId = ev.currentTarget.dataset.id
    var field = ev.currentTarget.dataset.field

    this.send_data = { current_user: {}, expense: {}, note: {} }

    this.send_data.expense.id = expenseId
    this.send_data.expense.field = field

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/financials/expenses/destroy_upload"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var expense = response.data.cln
          controller.current_expense = expense

          controller.application.expenses.forEach(element => {
            if (element.id == expense.id) {
              controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, expense)
            }
          })
        }

        controller.doFormHtml()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getExpense() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { expense: { active: true, id: this.current_expense.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/operations/products/clinics/financials/expenses/read_attached"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.current_expense = response.data.cln
          controller.doFormHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.uploadReady == false) {
        len += 1
      }

      if (controller.hasKindInputTarget && controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.hasUploadBtnTarget) {
        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      } else {
        controller.stopRefreshing()
      }
      
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
