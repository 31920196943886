import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "journey", "call", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--calls`
    this.blockedAdd = false
  }

  doCallsDashboard() {
    var html = `<div class="col-12 pl-1" data-app--communications--voips--calls--list-target="main" data-controller="app--communications--voips--calls--list"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--show`).callsRowTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).permission = `sales_opportunities_calls`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordId = this.application.opportunity.id
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordType = `sales_opportunities`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).dataMode = `record`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
