import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "titleInput", "bodyInput", "saveBtn", "idNameInput", "idNumberInput",
    "dateInput", "bodyInput", "bodyInputTrix"]


  connect() {
    this.controllerName = `operations--products--clinics--managements--logs--save`
    this.log_note = { title: {}, body: {} }
  }

  saveLogNote() {
    this.send_data = { note: {}, current_user: {}}
    
    var owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = owner_name
    
    this.send_data.note.domain_type = `operation_products`
    this.send_data.note.date_type = `operation_dates`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `log`
    
    var today = new Date()
    var orderedDate = this.getControllerByIdentifier("app--helpers--date").transformOrderedDate(today)
    this.send_data.note.date = today
    // this.send_data.note.date = this.getControllerByIdentifier("app--helpers--date").transformPrettyDate(today)
    
    this.log_note.title = `${orderedDate} - ${owner_name} ${this.translateAction(this.log_note.action)} ${this.log_note.obj_name}`
    this.send_data.note.title = this.log_note.title
    this.send_data.note.body = this.log_note.body
    this.send_data.note.domain_id = this.log_note.domain_id
    this.send_data.note.domain_name = this.log_note.domain_name
    this.send_data.note.date_id = this.log_note.date_id

    this.send_data.current_user.current_user_id = this.application.current_user.id

    // this.requestSave()
  }

  requestSave() {

    var url = "/operations/products/clinics/financials/notes/create"
    var method = "POST"

    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var note = response.data.cln

          if (controller.application.notes) {
            controller.application.notes[controller.application.notes.length] = note
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  translateAction(action) {
    if (action == `new`) {
      return `criou`
    } else if (action == `transmit`) {
      return `transmitiu`
    } else if (action == `edit`) {
      return `atualizou`
    } else if (action == `delete`) {
      return `apagou`
    } else if (action == `refresh`) {
      return `atualizou`
    } else if (action == `cancel`) {
      return `cancelou`
    } else if (action == `send`) {
      return `enviou`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
