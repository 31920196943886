import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewIncorporationCard", "viewTitle", "viewIncorporationCardBody", "previewCard", "previewTitle", "9999",
                    "deleteIncorporationBtn", "editIncorporationBtn", "previewCard", "previewTitle", "previewCardBody", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--incorporations--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewIncorporationCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Contrato Social Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewIncorporationCardBody"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--dashboard").viewTarget.innerHTML = html
  }

  doIncorporationHtml() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--incorporations--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteIncorporationBtn" data-action="click->${this.controllerName}#deleteIncorporation" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Contrato Social</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editIncorporationBtn" data-action="click->${this.controllerName}#editIncorporation" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Contrato Social</span>`
    } else {
      var editBtnHtml = ``
    }


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Contrato Social</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Capital Social</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(Math.round(this.current_incorporation.social_capital * 100))} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Cotas</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier(`app--helpers--numbers`).numberMask(this.current_incorporation.shares)} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Data Processo</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_incorporation.signed_at_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Código Registro</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_incorporation.registration_code}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Cláusula Objeto Social</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_incorporation.object_clause}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Cláusula Administração</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_incorporation.administration_clause}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Cláusula Distribuição Lucros</strong> <br>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                ${this.current_incorporation.profit_clause}
                              </td>
                            </tr>
                            

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Contrato Social | Versão #${controller.current_incorporation.version}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  editIncorporation() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--index").showClearBtn()

    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--save").current_incorporation = this.current_incorporation
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--save").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--save").doFormHtml()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
