import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "form", "title", "info", "confirmBtn", "navbarSpacing", "preloadCheckInCard",
                    "mainRow", "content", "phone", "errorEmailCode", "email", "mainFrame",
                    "midiaFrame", "questionFrame", "setAnswer", "footerFrame", "checkboxAcademic", "checkboxDoctor",
                    "graduationDropdown", "graduationDropdownBtn", "graduationInput", "graduationList", "graduation",
                    "checkboxNotDoctor", "academicRow", "doctorRow", "crmInput", "school", "liveBtn"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `web--commercial--marketing--events--attendees--main`
    this.application.event_token = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.first_interaction = true
    this.searchArray = []
    if (window.location.search.split("?")[1]) {
      this.searchArray = window.location.search.split("?")[1].split("&")
    }
    this.doPageGrid()

    // window.onbeforeunload = function () {
    //   return "Do you really want to close?";
    // }
  }

  loadPage() {
    this.getControllerByIdentifier("web--site--principal--frames--header").doHtml()
    this.getControllerByIdentifier("web--commercial--marketing--events--attendees--enrollment").doCheckInPreload()
    this.getControllerByIdentifier("web--site--principal--frames--footer").doHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  loadMobilePage() {
    this.getControllerByIdentifier("mobile--site--principal--frames--header").doHtml()
    this.getControllerByIdentifier("mobile--commercial--marketing--events--attendees--enrollment").doCheckInPreload()
    this.getControllerByIdentifier("mobile--site--principal--frames--footer").doHtml()

    if (this.getControllerByIdentifier(`web--site--principal--frames--preloader`)) {
      this.getControllerByIdentifier(`web--site--principal--frames--preloader`).close()
    }
  }

  doPageGrid() {
    var html = `<div class="row" data-action="resize@window->${this.controllerName}#setLayout" data-${this.controllerName}-target="mainRow"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doLoadModalHtml()
      controller.getSite()
    })
  }

  setLayout() {
    var x = window.matchMedia("(max-width: 576px)");

    if (x.matches) { // If media query matches
      this.application.size = `mobile`
    } else {
      x = window.matchMedia("(max-width: 768px)");
      if (x.matches) { // If media query matches
        this.application.size = `small`
      } else {
        x = window.matchMedia("(max-width: 992px)");
        if (x.matches) { // If media query matches
          this.application.size = `medium`
        } else {
          this.application.size = `large`
        }
      }
    }
    this.doSizeHtml()
  }

  doSizeHtml() {
    var html = ``
    if (this.application.size == `mobile`) {
      html += `<div class="col-12 px-0" data-controller="mobile--site--principal--frames--header
                                                         mobile--commercial--marketing--events--attendees--enrollment
                                                         mobile--site--principal--frames--footer" data-${this.controllerName}-target="content"></div>`
    } else {
      html += `<div class="col-12 px-0" data-controller="web--site--principal--frames--header
                                                         web--commercial--marketing--events--attendees--enrollment
                                                         web--site--principal--frames--footer" data-${this.controllerName}-target="content"></div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainRowTarget.innerHTML = html)
    }).then(() => {
      controller.contentTarget.innerHTML = ``
      if (controller.application.size == `mobile`) {
        if (controller.application.site) {
          controller.loadMobilePage()
        } else {
          controller.loadMobileBackupPage()
        }
      } else {
        if (controller.application.site) {
          controller.loadPage()
        } else {
          controller.loadBackupPage()
        }
      }
    })
  }

  setLink(originalLink) {
    var link = ``

    if (originalLink.includes("https://")) {
      link = originalLink
    } else {
      link = `https://${originalLink}`
    }

    return link
  }

  getSite() {
    this.send_data = { site: {} }
    this.send_data.site.kind = `principal`

    var url = "/commercial/marketing/sites/entities/read_principal_public"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.site = response.data.cln
          controller.setLayout()
        } else {
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doLoadModalHtml() {
    var controller = `web--site--principal--frames--preloader`

    var html = `<div class="modal fade" data-hour="bottom" data-controller="${controller}" data-${controller}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-${controller}-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Carregando a melhor experiência!</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <img class="mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/favicon.png" alt="logo-medcapital" style="width:5rem;">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.mainTarget.style.height = ($(window).height()) + "px"
    this.mainFrameTarget.style.height = ($(window).height()) + "px"
    this.midiaFrameTarget.style.height = ($(window).height()) + "px"
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshInfoTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.emailTarget.value == ``) {
        len += 1
      }

      if (controller.phoneTarget.value == `` || controller.phoneTarget.length < 16) {
        len += 1
      }

      if (controller.optInEventTarget.dataset.confirm == true || controller.optInEventTarget.dataset.confirm == "true") {
        len += 0
      } else {
        len += 1
      }


      if (len == 0) {
        controller.toggleBtns(true)
      } else {
        controller.toggleBtns(false)
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshInfoTimer) {
      clearInterval(this.refreshInfoTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })




  // `?utm_campaign=geral&utm_source=facebook&utm_content=pj-medica&utm_medium=banner&utm_term=pj-medica`

  // `?utm_mc_campaign=imposto-de-renda-para-medicos
  //   &utm_mc_source=google
  //   &utm_mc_content=display
  //   &utm_mc_medium=banner
  //   &utm_mc_term=imposto-de-renda-para-medicos
  //   &utm_medium=cpc
  //   &utm_campaign=
  //   &utm_source=google
  //   &utm_term=
  //   &gclid=CjwKCAiAkan9BRAqEiwAP9X6UVKAuuCJ6kqfLzzMEohwyiQovw0MEOfJk0MSe2J-aaPz6hfsqaBKMxoCpaEQAvD_BwE`

}
