import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "indicatorsTable", "indicatorBoard", "indicatorCard", "indicadorDateRow"]

  connect() {
    this.controllerName = `users--works--meetings--reports--indicators`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.date = new Date()
    this.year = this.date.getFullYear()
    this.month = this.date.getMonth()
    this.setDates()
  }

  doIndicatorsLoaderHTML() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`
    var rowLoader = `<div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>`

    var html = `<div class="row my-3">
                  
                  <div class="col-12 px-2">
                    <div class="row mb-4" data-${this.controllerName}-target="indicatorBoard">
                      ${rowLoader}
                      ${rowLoader}
                      ${rowLoader}
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorsTableTarget.innerHTML = html)
      // resolve(controller.getControllerByIdentifier("operations--onboards--reports--main").purchasesTableTarget.innerHTML = html)
    }).then(() => {
      controller.getReport()
    })
  }

  doDateRow() {

    var html = ``
    this.indicadorDates.forEach(date => {
      if (date.current_date) {
        html += `<div class="card pointer bg-primary mx-2" data-${this.controllerName}-target="${date.token}-Card" data-token="${date.token}" data-year="${date.year}" data-month="${date.month}" data-action="click->${this.controllerName}#toggleDates">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 mb-0 f-075"><strong>${date.name_pretty}</strong></h6>
                    </div>
                  </div>`
      } else {
        html += `<div class="card pointer mx-2" data-${this.controllerName}-target="${date.token}-Card" data-token="${date.token}" data-year="${date.year}" data-month="${date.month}" data-action="click->${this.controllerName}#toggleDates">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 mb-0 f-075"><strong>${date.name_pretty}</strong></h6>
                    </div>
                  </div>`
      }

    });

    // this.getControllerByIdentifier("operations--onboards--reports--main").indicadorDateRowTarget.innerHTML = html
    this.indicadorDateRowTarget.innerHTML = html
  }

  doIndicatorReportHtml() {
    var html = `<div class="col-12 px-2">
                  <h6 class="mb-0 d-flex align-items-center">
                    <span class="mr-5">Esforço e Engajamento</span>
                  </h6>
                  <hr class="my-1">
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="indicatorCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Reuniões</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.application.reports.weeklies}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="indicatorCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Tópicos</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.application.reports.topics}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="indicatorCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Tickets</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.application.reports.tickets}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="indicatorCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Anotações</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.application.reports.notes}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorBoardTarget.innerHTML = html)
    }).then(() => {
      controller.indicatorCardTargets.forEach(card => {
        card.style.height = ($(window).height() * 0.15) + "px"
      });
    })
  }

  toggleDates(ev) {
    var token = ev.currentTarget.dataset.token

    this.indicadorDates.forEach(date => {
      if (date.token == token) {
        this.nameTarget(`${date.token}-Card`).classList.add("bg-primary")
        date.current_date = true
      } else {
        this.nameTarget(`${date.token}-Card`).classList.remove("bg-primary")
        date.current_date = false
      }
    });

    this.getNewReport(ev)

  }

  getNewReport(ev) {
    // this.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.year = Number(ev.currentTarget.dataset.year)
    this.month = Number(ev.currentTarget.dataset.month)
    this.getReport()
  }

  getReport() {
    const data = { board: { date: new Date(this.year, this.month, 1) }, current_user: { current_user_id: this.application.current_user.id, feature: `work_meeting_boards` } }
    const url = "/users/works/meetings/boards/get_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.reports = response.data.cln
        controller.doDateRow()
        controller.doIndicatorReportHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setDates() {

    this.indicadorDates = [
                            {
                              name_pretty: "Mai/22",
                              year: 2022,
                              month: 4,
                              token: 202204,
                              current_date: true
                            },
                            {
                              name_pretty: "Abr/22",
                              year: 2022,
                              month: 3,
                              token: 202203,
                              current_date: false
                            },
                            {
                              name_pretty: "Mar/22",
                              year: 2022,
                              month: 2,
                              token: 202202,
                              current_date: false
                            },
                            {
                              name_pretty: "Fev/22",
                              year: 2022,
                              month: 1,
                              token: 202201,
                              current_date: false
                            },
                            {
                              name_pretty: "Jan/22",
                              year: 2022,
                              month: 0,
                              token: 202200,
                              current_date: false
                            },
                          ]

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
