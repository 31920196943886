import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "save", "view", "list", "content", "receivables", "invoices", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--reports--logs--dashboard`
    this.doMainDashboard()
  }

  doMainDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="receivables" data-controller="operations--products--clinics--financials--index--reports--logs--receivables"></div>
                  <div class="col-12" data-${this.controllerName}-target="invoices" data-controller="operations--products--clinics--financials--index--reports--logs--invoices"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--financials--index--dashboard").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--dashboard`).contentTarget.innerHTML = html)
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--logs--invoices`).doInvoicesArea()
      controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--logs--receivables`).doReceivablesArea()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
