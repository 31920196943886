import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "receivement", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    // this.setTopMargin()
    if (this.application.current_user.account_kind == "doctor") {
      this.doctorDashboard()
    } else if (this.application.current_user.account_kind == "helper") {
      this.helperDashboard()
    } else if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      // if (firstLogin) {
      //   this.welcomeChat()
      // } else {
      //   this.teamDashboard()
      // }
    }
  }

  doctorDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12 px-0" data-controller="mobile--users--dashboards--doctor" data-mobile--users--dashboards--doctor-target="main">
                    </div>
                  </div>`

    this.mainTarget.innerHTML = html
  }

  helperDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12 px-0" data-controller="mobile--users--dashboards--helper" data-mobile--users--dashboards--helper-target="main">
                    </div>
                  </div>`

    this.mainTarget.innerHTML = html
  }

  setTopMargin() {
    var height = this.getControllerByIdentifier(`mobile--app--navigation--navbar`).headerTarget.style.height
    this.mainTarget.style = `90px`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
