import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenu", "squadDropdown", "squadDropdownBtn", "squadInput", "squadList", "squad", "content", "9999"]

  connect() {
    this.controllerName = `users--works--activities--tickets--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.permissions = {}
    this.application.all_tickets = []
    this.application.tickets = []
    this.application.team = []
    this.application.squads = []
    this.application.my_squads = []
    this.application.personal_tickets = []
    this.sort = `started_at_to_time`
    this.hasFilter = false
    this.filter = ``
    this.application.current_squad = this.application.current_user
    this.application.account_id = this.application.current_user.account_id
    this.application.personal_board = true
    this.application.non_personal_board = true
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--activities--squads--dashboard
                                                            users--works--activities--tickets--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--activities--tickets--dashboard`).doPageGrid()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    
    this.application.uniq_board_ids = this.mapElements(this.application.tickets, `board_id`).filter(this.onlyUnique)
    this.application.uniq_board_types = this.mapElements(this.application.tickets, `board_type`).filter(this.onlyUnique)
    this.application.uniq_board_types_pretty = this.mapElements(this.application.tickets, `board_type_pretty`).filter(this.onlyUnique)
    this.application.uniq_board_names = this.mapElements(this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.tickets, `board_name`), `board_name`).filter(this.onlyUnique)
    this.application.uniq_owner_ids = this.mapElements(this.application.tickets, `owner_id`).filter(this.onlyUnique)
    this.application.uniq_owner_names = this.mapElements(this.application.tickets, `owner_name`).filter(this.onlyUnique)
    this.application.uniq_performer_ids = this.mapElements(this.application.tickets, `performer_id`).filter(this.onlyUnique)
    this.application.uniq_performer_names = this.mapElements(this.application.tickets, `performer_name`).filter(this.onlyUnique)
    this.application.uniq_filters = this.mapElements(this.application.tickets, `filters`).filter(this.onlyUnique)
    this.application.filter_tickets = this.application.tickets

    this.getControllerByIdentifier(`users--works--activities--tickets--submenu`).doSubmenuHtml()
    this.getControllerByIdentifier(`users--works--activities--tickets--filter`).doFilterHeader()
    // this.getControllerByIdentifier(`users--works--activities--tickets--board`).doBoardGrid()
    // this.getControllerByIdentifier(`users--works--activities--tickets--list`).doListHtml()
    this.getControllerByIdentifier(`users--works--activities--tickets--resume`).doResumeHtml(`col-4 mt-5 pt-5`)

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    const data = { ticket: { performer_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_work_activity_tickets` } }
    const url = "/users/works/activities/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.tickets = []
        controller.application.all_tickets = response.data.cln

        if (controller.application.account_id == controller.application.current_user.account_id) {
          controller.application.tickets = controller.application.all_tickets
        } else {
          controller.application.all_tickets.forEach(element => {
            if (controller.application.current_squad.board_types.includes(element.board_type)) {
              controller.application.tickets[controller.application.tickets.length] = element
            }
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time`, value: 20 })

    const url = "/users/works/activities/squads/list_team"
    const data = { user: { account_kind: "team" }, current_user: { current_user_id: this.application.current_user.id } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team = response.data.cln
        controller.application.team_list = response.data.cln
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squads`, value: 20 })

    const data = { squad: { active: true }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/works/activities/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.squads = response.data.cln
        controller.getTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMySquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Minhas Squads`, value: 30 })

    const data = { squad: { active: true, leader_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/works/activities/squads/list_by_leader"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.my_squads = response.data.cln
        controller.application.my_squads.unshift({ team_id: controller.application.current_user.account_id, team_name: controller.application.current_user.name })
        controller.getControllerByIdentifier(`users--works--activities--tickets--squads`).doSquadHTML()

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPersonalTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets Pessoais`, value: 20 })

    var data = { ticket: { board_id: this.application.current_user.account_id, board_type: "personal_tickets" }, current_user: { current_user_id: this.application.current_user.id, feature: `user_work_activity_tickets` } }
    const url = "/users/works/activities/tickets/personal_list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.personal_tickets = response.data.cln
        }

        if (controller.application.permissions.user_work_activity_squads.can_list) {
          controller.getMySquads()
        } else {
          controller.getTickets()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_tickets` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_work_activity_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getPersonalTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
