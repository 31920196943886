import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addRenewalBtn", "pendencyCount", "sideBarReport",
                    "clearListBtn", "optionBtn", "searchTable",
                    "clearListBtn", "optionBtn", "searchTable"]

  connect() {
    this.controllerName = `financials--books--clients--renewals--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 750
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 12
    this.sort = { mode: `asc`, field: `record_name` }
  }

  addRenewal() {
    this.getControllerByIdentifier("financials--books--clients--renewals--show").current_renewal = {}
    this.getControllerByIdentifier("financials--books--clients--renewals--show").actionMode = `new`
    this.getControllerByIdentifier("financials--books--clients--renewals--show").doMenuHtml()
  }

  editReweal(ev) {
    this.optionBtnTarget.classList.add(`d-none`)
    this.clearListBtnTarget.classList.remove(`d-none`)
    var renewalId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var current_renewal = {}
    // this.application.renewals.forEach(renewal => {
    this.current_renewals.forEach(renewal => {
      if (renewal.id == renewalId) {
        current_renewal = renewal
      }
    })

    var current_client = {}
    this.application.clients.forEach(client => {
      if (client.id == current_renewal.client_id) {
        current_client = client
      }
    })

    var modal = `financials--books--clients--renewals--save-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).current_client = current_client
      controller.getControllerByIdentifier(modal).current_contract = current_renewal.current_contract
      controller.getControllerByIdentifier(modal).current_renewal = current_renewal
      controller.getControllerByIdentifier(modal).actionMode = "edit"
      controller.getControllerByIdentifier(modal).open()
    })
  }

  destroyReweal(ev) {
    var renewalId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var current_renewal = {}
    // this.application.renewals.forEach(renewal => {
    this.current_renewals.forEach(renewal => {
      if (renewal.id == renewalId) {
        current_renewal = renewal
      }
    })

    this.send_data = { current_user: {}, renewal: {}, tracker: { manual: true } }

    this.send_data.renewal.id = current_renewal.id
    this.send_data.renewal.active = false

    this.send_data.tracker.action = `destroyed`
    this.send_data.tracker.observations = `Apagou a Renovação do Contrato ${current_renewal.contract_name}`
    this.send_data.tracker.obj_id = current_renewal.id
    this.send_data.tracker.obj_type = `financial_client_renewals`
    this.send_data.tracker.record_id = current_renewal.client_id
    this.send_data.tracker.record_type = `financial_client_entities`

    var r = confirm(`Tem certeza que deseja apagar a Renovação?`)
    if (r) { 
      this.requestDestroy()
    } else {
      this.clearList()
    }
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/financials/books/clients/renewals/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var renewal = response.data.cln

          controller.application.renewals.forEach((element, i) => {
            if (element.id == renewal.id) {
              controller.application.renewals.splice(controller.application.renewals.indexOf(element), 1)
            }
          })
        }

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doRenewalList() {
    // this.current_renewals = this.application.renewals

    if (this.application.permissions[this.permission].can_delete) {
      var destroyLine = `<span data-action="click->${this.controllerName}#checkForRenewal" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Renovação</span>`
    } else {
      var destroyLine = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addRenewal" class="dropdown-item py-1 pointer dropdown-submenu-item">Nova Renovação</span>`
    } else {
      var addLine = ``
    }
    var addLine = ``
    var editLine = `<span data-action="click->${this.controllerName}#checkForEdit" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Renovação</span>`

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshRenewals" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Renovações</span>`
    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                          ${editLine}
                          ${destroyLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="row w-100">
                  <div class="col-12 px-0">
                    <div class="card" style="" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title f-065 mb-0 mr-auto">Todas as Renovações</h6>
                        <input class="form-control f-070 w-50 mx-auto my-2 py-2" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable keyup->${this.controllerName}#enterShowRenewal" placeholder="Buscar ..." type="text">
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${clearBtnHtml}
                        </div>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;">
                        <div class="row">
                          <div class="col p-0">
                            <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                              <thead>
                                <tr>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-2 align-middle text-left"></th>
                                  <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">
                                    Cliente
                                    <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="record_name" data-mode="desc">south</span>
                                    <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="record_name" data-mode="asc">north</span>
                                  </th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-left">Contrato</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-left">Descrição</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Plano</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Status</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Valor Atual</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Valor Objetivo</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Valor Renovado</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Desconto Atual</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Desconto Objetivo</th>
                                  <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle text-center">Desconto Renovado</th>
                                </tr>
                              </thead>
                              <tbody data-${this.controllerName}-target="bodyTable">
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.hasMainCardTarget) {
        controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
        controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 10))
        controller.doDataTable()
      }
    })
  }

  doDataTable() {
    if (this.sort.mode == `asc`) {
      var renewals = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_renewals, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var renewals = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_renewals, this.sort.field)
    }

    if (renewals.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(renewals.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (renewals[x] !== undefined) {
            internPage[internPage.length] = renewals[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }
2
      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: renewals.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Renovações</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.renewalTablePartial(element, data.length))
      })
    }
  }

  renewalTablePartial(element, length) {
    
    if (element.status == `active`) {
      var amount = this.controllerNumber.currencyOptionMask(element.goal_amount)
      var discount = this.controllerNumber.currencyOptionMask(element.goal_discount)
    } else {
      var amount = this.controllerNumber.currencyOptionMask(element.renewed_amount)
      var discount = this.controllerNumber.currencyOptionMask(element.renewed_discount)
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_renewal`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="check-${element.id}" data-action="click->${this.controllerName}#editReweal">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `destroy_renewal`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="check-${element.id}" data-action="click->${this.controllerName}#destroyReweal">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showRenewalEv">${element.client_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showRenewalEv">${element.contract_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showRenewalEv">${element.description}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${element.current_plan_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.current_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.goal_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.renewed_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.current_discount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.goal_discount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showRenewalEv">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.renewed_discount)}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1500">1.500</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  checkForEdit() {
    this.kind = `edit_renewal`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForRenewal() {
    this.kind = `destroy_renewal`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  enterShowRenewal(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var renewalIds = []

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          renewalIds[renewalIds.length] = tr[i].dataset.id
        }
      }

      if (renewalIds.length) {
        this.showRenewal(renewalIds[0])
      }
    }
  }

  showRenewalEv(ev) {
    var renewalId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.showRenewal(renewalId)
  }


  showRenewal(renewalId) {
    this.optionBtnTarget.classList.add(`d-none`)
    this.clearListBtnTarget.classList.remove(`d-none`)

    var current_renewal = {}
    // this.application.renewals.forEach(renewal => {
    this.current_renewals.forEach(renewal => {
      if (renewal.id == renewalId) {
        current_renewal = renewal
      }
    })

    if (this.controllerDashboard.context.identifier == `financials--books--clients--renewals--dashboard`) {
      this.getControllerByIdentifier("financials--books--clients--renewals--show").current_renewal = current_renewal
      this.getControllerByIdentifier("financials--books--clients--renewals--show").actionMode = `edit`
      this.getControllerByIdentifier("financials--books--clients--renewals--show").doGridHtml()
    } else if (this.controllerDashboard.context.identifier == `financials--books--clients--entities--show`) {
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier("financials--books--clients--renewals--dashboard").doRenewalsDashboard())
      }).then(() => {
        controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).mainCardTarget.remove()

        controller.getControllerByIdentifier("financials--books--clients--renewals--show").current_renewal = current_renewal
        controller.getControllerByIdentifier("financials--books--clients--renewals--show").actionMode = `edit`
        controller.getControllerByIdentifier("financials--books--clients--renewals--show").doGridHtml()
      })
    }

  }

  clearList() {
    this.optionBtnTarget.classList.remove(`d-none`)
    this.clearListBtnTarget.classList.add(`d-none`)
    // this.current_renewals = this.application.renewals
    this.doDataTable()

    this.getControllerByIdentifier("financials--books--clients--renewals--dashboard").doRenewalsDashboard()
  }

  refreshRenewals() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 10)

    const data = { renewal: { active: true, status: `active` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/clients/renewals/list_by_status"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.renewals = response.data.cln
        }

        controller.doRenewalList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
