import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "searchTable", "searchInput",
                    "cardBody", "emailDropdown", "emailDropdownBtn", "emailInput", "emailList"]

  connect() {
    this.controllerName = `financials--books--receivables--entities--list-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getReceivables()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doModalHtml() {

    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`
    var refreshLine = `<span data-action="click->${this.controllerName}#getReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Recebíveis</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">${this.title}</h6>
                    <div class="form-group w-30 mx-auto">
                      <div class="floating-label floating-label-sm">
                        <label>Médico</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="emailDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="emailDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="emailInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="emailList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row" data-${this.controllerName}-target="mainRow">
                      <div class="col-12 mt-1" data-${this.controllerName}-target="cardBody"></div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      controller.doReceivablesList()
      controller.doEmailsList()
    })
  }

  doEmailsList() {
    var uniq_receivables = this.all_receivables.filter((value, index, self) => self.map(x => x.client_iugu_email).indexOf(value.client_iugu_email) == index)
    var uniq_emails = this.mapElements(uniq_receivables, `client_iugu_email`)
    var emailsHtml = ``

    uniq_emails.forEach(email => {
      emailsHtml += `<li data-email="${email}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setReceivable" data-app--helpers--selector-target="select" class="li-selector dark f-065">${email}</li>`
    })

    this.emailListTarget.innerHTML = emailsHtml
  }

  setReceivable(ev) {

    var currentEmail = ev.currentTarget.dataset.email
    var currentReceivables = []

    this.all_receivables.forEach(receivable => {
      if (receivable.client_iugu_email == currentEmail) {
        currentReceivables[currentReceivables.length] = receivable
      }
    })

    this.current_receivales = currentReceivables
    this.doReceivablesList()
  }

  doReceivablesList() {
    var html = ``
    var activeReceivableHtml = ``

    if (this.current_receivales.length > 0) {

      var orderedReceivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_receivales, `due_at_to_time`)

      orderedReceivables.forEach(receivable => {
        var receivable_billing = receivable.billings[0]

        var destroyBtn = ``
        // if (!receivable.conciliated && this.application.permissions[this.permission].can_delete) {
        if (this.application.permissions[this.permission].can_delete) {
          var destroyBtn = `<span class="mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-id="${receivable.id}" data-${this.controllerName}-target="destroyBtn-${receivable.id}" data-action="click->${this.controllerName}#deleteReceivableEv" type="button">
                                <span class="material-icons f-1p25">delete</span>
                              </button>
                              <span class="mc-tooltiptext">Apagar Recebível</span>
                            </span>`
        }

        var clientIuguId = `<small class="mb-0 f-065 mc-tooltip grab">
                              <span class="f-bold" data-controller="app--helpers--copy" data-copy="${receivable.client_iugu_id}" data-action="click->app--helpers--copy#copy">${receivable.client_iugu_id}</span>
                              <span class="mc-tooltiptext">Clique para Copiar</span>
                            </small>`

        var clientIuguEmail = `<small class="mb-0 f-065 mc-tooltip grab">
                              <span class="f-bold" data-controller="app--helpers--copy" data-copy="${receivable.client_iugu_email}" data-action="click->app--helpers--copy#copy">${receivable.client_iugu_email}</span>
                              <span class="mc-tooltiptext">Clique para Copiar</span>
                            </small>`

        if (receivable_billing) {
          var invoiceIuguId = `<small class="mb-0 f-065 mc-tooltip grab">
                              <span class="f-bold" data-controller="app--helpers--copy" data-copy="${receivable_billing.invoice_id_iugu}" data-action="click->app--helpers--copy#copy">${receivable_billing.invoice_id_iugu}</span>
                              <span class="mc-tooltiptext">Clique para Copiar</span>
                            </small>`          
        }
        var cardShow = `<p class="mb-0 f-065">ID Cliente Iugu: ${clientIuguId} | ID Fatura Iugu: ${invoiceIuguId}</p>`
        cardShow += `<p class="mb-0 f-065">Email Cliente Iugu: ${clientIuguEmail}</p>`
        // cardShow += `<p class="mb-0 f-065"></p>`
        receivable.items.forEach(item => {
          cardShow += `<p class="mb-0 f-065">${item.description}</p>
                       <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(item.amount)}</p>`

        })

        this.application.dates.forEach(date => {
          if (receivable.date_id == date.id) {
            receivable.date_name = date.name
          }
        })

        if (receivable.status == `paid`) {

          var statusPretty = `<span class="f-070 badge badge-secondary-success">${receivable.status_pretty}</span>`
          var methodPretty = receivable.method_pretty

          if (receivable.late_payment) {
            var latePayment = `<span class="f-070 badge badge-secondary-danger">Atrasado</span>`
          } else {
            var latePayment = `<span class="f-070 badge badge-secondary-success">Em Dia</span>`
          }
        } else {
          var statusPretty = `<span class="f-070 badge badge-secondary-danger">${receivable.status_pretty}</span>`
          var latePayment = `<span class="f-070 badge badge-secondary-warning">Pendente</span>`
          var methodPretty = `Sem Pagamento`
        }

        if (receivable.returned_status == `returned`) {
          var returnedStatusPretty = `<span class="f-070 badge badge-secondary-success">Liquidado</span>`
        } else {
          var returnedStatusPretty = `<span class="f-070 badge badge-secondary-danger">Pendente</span>`
        }

        if (receivable.acrrual_status == `inside`) {
          var accrualStatusPretty = `<span class="f-070 badge badge-secondary-success">${receivable.acrrual_status_pretty}</span>`
        } else {
          var accrualStatusPretty = `<span class="f-070 badge badge-secondary-danger">${receivable.acrrual_status_pretty}</span>`
        }

        if (receivable.conciliated) {
          var conciliatedPretty = `<span class="f-070 badge badge-secondary-success">Conciliado</span>`
        } else {
          var conciliatedPretty = `<span class="f-070 badge badge-secondary-danger">Não Conciliado</span>`
        }

        var totalAmountCharged = `Valor Cobrado: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_charged)}`
        var totalAmountInvoice = `Valor Fiscal: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_invoice)}`
        var totalAmountRevenue = `Valor Receita: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_revenue)}`
        var totalAmountRefund = `Valor Reembolso: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_refund)}`
        var totalAmountDiscount = `Valor Desconto: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_discount)}`
        var totalAmountAdvance = `Valor Antecipado: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_advance)}`
        var totalAmountReversal = `Valor Estorno: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_reversal)}`
        var totalAmountFinesAndInterests = `Valor Multa/Juros: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(receivable.total_amount_fines_and_interests)}`

        activeReceivableHtml += `<div class="col-12">
                                  <div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="receivableCard-${receivable.id}">
                                    <div class="card-body p-0 f-065">
                                      <div class="row d-flex align-items-center">
                                        <div class="col-10">
                                          <div class="card-show-dropdown w-100">
                                            <p class="my-1 f-065">${receivable.description} | Status: ${statusPretty} | Liquidação: ${returnedStatusPretty} | Método: <strong>${methodPretty}</strong></p>
                                            <p class="my-1 f-065">${receivable.date_name} | ${conciliatedPretty} | Ocorrência: ${accrualStatusPretty} | ${latePayment}</p>
                                            <p class="my-1 f-065">Dt Vencimento: <strong>${receivable.due_at_pretty}</strong> | Dt Recebimento: <strong>${receivable.received_at_pretty}</strong> | Dt Fiscal: <strong>${receivable.issued_on_pretty}</strong> | Dt Liquidação: <strong>${receivable.returned_at_pretty}</strong></p>
                                            <p class="my-1 f-065">${totalAmountCharged} | ${totalAmountInvoice} | ${totalAmountRevenue} | ${totalAmountRefund} | ${totalAmountDiscount} | ${totalAmountAdvance} | ${totalAmountFinesAndInterests}</p>
                                            <div class="card-show-dropdown-content text-left w-100">
                                              ${cardShow}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-2">
                                          ${destroyBtn}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>`
      })
    } else {
      activeReceivableHtml += `<div class="col-12 text-center">
                                  <span class="f-085"><strong>Não há Recebíveis para o Item de Contrato</strong></span>
                                </div>`
    }

    html += `<div class="row mt-3 d-flex align-items-center h-100" style="height:100%;">
              ${activeReceivableHtml}
            </div>`

    this.cardBodyTarget.innerHTML = html
  }

  deleteReceivableEv(ev) {
    var receivableId = ev.currentTarget.dataset.id

    var r = confirm(`Tem certeza que deseja apagar o Recebível?`)
    if (r) {
      this.deleteReceivable(receivableId)    
    }
  }

  deleteReceivable(receivableId) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, receivable: {} }

    this.send_data.receivable.id = receivableId
    this.send_data.receivable.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_entities`

    var url = "/financials/books/receivables/entities/destroy"
    var method = "DELETE"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var receivable = response.data.cln

        controller.all_receivables.forEach(element => {
          if (element.id == receivable.id) {
            controller.all_receivables.splice(controller.all_receivables.indexOf(element), 1)
          }
        })

        controller.application.receivables.forEach(element => {
          if (element.id == receivable.id) {
            controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1)
          }
        })

        controller.application.addons.forEach((addon, i) => {
          addon.receivables.forEach(element => {
            if (element.id == receivable.id) {
              addon.receivables.splice(addon.receivables.indexOf(element), 1)
            }
          })
        })

        if (controller.getControllerByIdentifier(`financials--books--receivables--entities--index`)) {
          controller.getControllerByIdentifier(`financials--books--receivables--entities--index`).doDataTable()
        }

        if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--receivables`)) {
          var controllerModalReceivables = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--receivables`)

          controllerModalReceivables.current_contract_receivables.forEach((element, i) => {
            if (element.id == receivable.id) {
              controllerModalReceivables.current_contract_receivables.splice(controllerModalReceivables.current_contract_receivables.indexOf(element), 1)
            }
          })
          
          controllerModalReceivables.doReceivablesList()
        }

        controller.doReceivablesList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.obj_type == `addon`) {
      var data = { addon: { active: true, addon_id: this.obj_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/financials/books/clients/addons/list_receivables"
    } else if (this.obj_type == `contract`) {
      var data = { contract: { active: true, contract_id: this.obj_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/financials/books/clients/contracts/list_receivables"
    }

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.current_receivales = response.data.cln
        controller.all_receivables = response.data.cln
        
        controller.doModalHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }
}