import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "saveBtn", "8888", "9999",
    "sexDropdown", "sexDropdownBtn", "sexInput", "sexList", "helperCheck", "sponsorCheck",
    "roleDropdown", "roleDropdownBtn", "roleInput", "roleList", "employmentDropdown", "employmentDropdownBtn", "employmentInput", "employmentList"]


  connect() {
    this.controllerName = `operations--products--clinics--managements--helpers--save`
    this.controllerDash = this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard")
    this.send_data = { current_user: {}, account: {}, helper: {} }

    if (this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--managements--helpers--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--helpers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--helpers--dashboard")
    }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--managements--helpers--index").clearList()
  }

  saveHelper() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.account.id) {
      this.send_data.account.id = this.account.id
    } else {
      this.send_data.account.name = this.nameInputTarget.value
      this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.account.sex = this.sexInputTarget.dataset.sex
      this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformNumberDate(this.birthdateInputTarget.value)
    }

    this.send_data.account.kind = `helper`
    this.send_data.helper.clinic_id = this.application.clinic.id
    this.send_data.helper.role = this.roleInputTarget.dataset.role
    this.send_data.helper.employment = this.employmentInputTarget.dataset.employment
    this.send_data.helper.employer_id = this.application.clinic.company_id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.controllerDash.mainPermission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/managements/helpers/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var helper = response.data.cln
          controller.application.helpers[controller.application.helpers.length] = helper
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--clinics--managements--helpers--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveHelper" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Assistente</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF Assistente</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount" placeholder="CPF Assistente" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Assistente</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Assistente" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="birthdateForm">Data Nascimento</label>
                            <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                            
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sexo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                  <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                  <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Função</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                                  <li data-role="manager" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gerente</li>
                                  <li data-role="biller" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturista</li>
                                  <li data-role="receptionist" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recepcionista</li>
                                  <li data-role="technician" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Técnica</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Vínculo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="employmentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="employmentDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="employmentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="employmentList app--helpers--search.searchList">
                                  <li data-employment="wage" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assalariado</li>
                                  <li data-employment="selfworker" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Autônomo</li>
                                  <li data-employment="labor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pró-Labore</li>
                                  <li data-employment="partner" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sócio</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {

      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
      controller.nameInputTarget.disabled = true
      controller.birthdateInputTarget.disabled = true
      controller.sexDropdownBtnTarget.disabled = true
    })

  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""

      this.nameInputTarget.disabled = false
      this.birthdateInputTarget.disabled = false
      this.sexDropdownBtnTarget.disabled = false


    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "") {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "helper" }, current_user: { current_user_id: this.application.current_user.id, feature: "medclinic_management_helpers" } }
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
        fetch(url, init)
          .then(response => response.json())
          .then(response => {
            if (response.process) {
              this.account = response.data.cln
              controller.nameInputTarget.disabled = false
              if (this.account.id) {
                controller.nameInputTarget.value = this.account.name
                controller.birthdateInputTarget.value = this.account.birthdate_pretty
                controller.sexInputTarget.innerText = this.account.sex_pretty
                controller.sexInputTarget.dataset.sex = this.account.sex
                controller.sexDropdownTarget.value = this.account.sex

                controller.nameInputTarget.disabled = true
                controller.birthdateInputTarget.disabled = true
                controller.sexDropdownBtnTarget.disabled = true

                controller.checkIfHasHelper(this.account)
              } else {
                controller.nameInputTarget.value = "Favor adicionar o Nome do Assistente"

                controller.nameInputTarget.disabled = false
                controller.birthdateInputTarget.disabled = false
                controller.sexDropdownBtnTarget.disabled = false
              }
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            }
          })
          .catch(error => {
            controller.getControllerByIdentifier("app--helpers--console").console(error)
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
          })
      }



    }
  }

  checkIfHasHelper(account) {
    var diffHelper = true
    this.application.helpers.forEach(element => {
      if (element.account_cpf == account.cpf) {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O Assistente já está cadastrado", 2000)
        diffHelper = false
      } else {
        if (diffHelper) {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
      }
    });
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.cpf_result && controller.cpf_result.invalid) {
        len += 1
      }

      if (controller.cpfInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == `` || controller.nameInputTarget.value == `Favor adicionar o Nome do Assistente`) {
        len += 1
      }

      if (controller.birthdateInputTarget.value == ``) {
        len += 1
      }

      if (controller.sexInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
