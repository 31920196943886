import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "dueAtInput", "paidAtInput", "channelDropdown", "channelDropdownBtn", "channelInput",
                    "channelList", "saveBtn", "principalAmountInput", "interestsAmountInput", "finesAmountInput",
                    "totalAmountInput"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--expenses--paid-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)

    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")
    }
    
  }

  open(expenseId) {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.setCurrentTax(expenseId)
    this.setChannels()

    var date = new Date(this.application.current_date.year, this.application.current_date.month, 0)
    // this.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.issuedOnInputTarget), { kind: "monthly", year: date.getFullYear(), month: date.getMonth() + 1 })
    this.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.paidAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
    // this.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.paidAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

    this.paidAtInputTarget.value = this.current_expense.due_date_pretty
    this.dueAtInputTarget.value = this.current_expense.due_date_pretty
    this.principalAmountInputTarget.value = this.controllerNumber.currencyOptionMask(this.current_expense.amount)
    this.interestsAmountInputTarget.value = this.controllerNumber.currencyOptionMask(this.current_expense.interests)
    this.finesAmountInputTarget.value = this.controllerNumber.currencyOptionMask(this.current_expense.fines)
    this.totalAmountInputTarget.value = this.controllerNumber.currencyOptionMask(this.current_expense.amount)

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  setCurrentTax(expenseId) {
    this.application.expenses.forEach(element => {
      if (element.id == expenseId) {
        this.current_expense = element
      }
    })
  }

  updatePaid() {
    this.send_data = { current_user: {}, expense: {} }

    this.send_data.expense.id = this.current_expense.id
    this.send_data.expense.paid_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.paidAtInputTarget.value)
    this.send_data.expense.paid = true
    this.send_data.expense.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.expense.channel_name = this.channelInputTarget.innerText
    this.send_data.expense.amount = this.controllerNumber.fromCurrencyToNumber(this.principalAmountInputTarget.value)
    this.send_data.expense.interests = this.controllerNumber.fromCurrencyToNumber(this.interestsAmountInputTarget.value)
    this.send_data.expense.fines = this.controllerNumber.fromCurrencyToNumber(this.finesAmountInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission


    var r = confirm(`A Despesa foi Paga?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/operations/products/clinics/financials/expenses/update_paid"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var expense = response.data.cln
          controller.application.expenses.forEach((element, i) => {
            if (element.id == expense.id) {
              controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, expense)
            }
          })
        }
        controller.close()
        controller.getControllerByIdentifier("operations--products--clinics--financials--expenses--index").clearList()
        controller.getControllerByIdentifier(`operations--products--clinics--financials--expenses--index`).submenuController.doExpensesCount()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para atualizar o pagamento da Despesa`)
      })
  }

  setChannels() {
    var html = ``

    this.application.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectBank click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`
        
        if (channel.kind == `main`) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }
      }
    })

    this.channelListTarget.innerHTML = html
  }

  selectBank(ev) {

    var channelId = ev.currentTarget.dataset.channelId

    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  bindTotalAmount(ev) {
    var amount = Number(this.current_expense.amount)
    var fines = Number(this.controllerNumber.fromCurrencyToNumber(this.finesAmountInputTarget.value))
    var interests = Number(this.controllerNumber.fromCurrencyToNumber(this.interestsAmountInputTarget.value))

    var totalAmount = amount + interests + fines
    this.totalAmountInputTarget.value = this.controllerNumber.currencyOptionMask(totalAmount)
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}