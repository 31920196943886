import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "mainCard", "bodyTable", "footerTable", "refreshCalculationBtn", "dateInput", "dateDropdown", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--reports--onboards`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    // this.doCalculationListHtml()
  }

  getOnboards() {
    var html = ``

    this.getControllerByIdentifier(`operations--products--tax-filings--reports--dashboard`).reportContentTarget.innerHTML = html
  }

  goToShowPage(ev) {
    var url = ev.target.closest(".itemRow").dataset.productPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}