import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "asdf", "asdf", "body", "asdf", "asdf"]

  connect() {
    this.controllerName = `users--works--notes--save-check-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 750
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doGrid()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doGrid() {

    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 px-0" data-${this.controllerName}-target="view" data-controller="users--works--notes--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      var saveController = controller.getControllerByIdentifier(`users--works--notes--save`)
      saveController.dashboardController = controller
      saveController.note_board = {
        account_id: controller.note_board.account_id,
        domain_id: controller.note_board.domain_id,
        domain_type: controller.note_board.domain_type,
        domain_name: controller.note_board.domain_name,
      }

      saveController.current_obj = controller.current_obj
      saveController.title = controller.title
      saveController.permission = controller.permission
      saveController.noteTitle = controller.noteTitle
      saveController.actionMode = `check`
      saveController.doFormHtml()
    })
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}