import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
                    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
                    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx",
                    "xxxxxxxxxxxxxx", "xxxxxxxxxxxxxx"]

  connect() {
    this.controllerName = "operations--products--clinics--financials--add--taxes"

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }
  }

  getData() {
    if (this.saveController.current_regime.tax_regime == `simple_national`) {
      this.getControllerByIdentifier(`operations--products--clinics--financials--add--simple-national`).getData()
    } else if (this.saveController.current_regime.tax_regime == `presumed_profit`) {
      this.getControllerByIdentifier(`operations--products--clinics--financials--add--presumed-profit`).getData()
    }
  }

  doTaxsFormHtml() {

    if (this.saveController.current_regime.tax_regime == `simple_national`) {
      var html = `<div data-controller="operations--products--clinics--financials--add--simple-national" data-${this.controllerName}-target="form"></div>`
    } else if (this.saveController.current_regime.tax_regime == `presumed_profit`) {
      var html = `<div data-controller="operations--products--clinics--financials--add--presumed-profit" data-${this.controllerName}-target="form"></div>`
    }


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.saveController.formTitleTarget.innerText = `Confirme os valores de retenção e ISS da Nota Fiscal | Regime ${this.saveController.current_regime.tax_regime_pretty}`

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  checkPartner(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId

    if (ev.currentTarget.checked) {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(0)
    } else {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(0)
    }

    this.bindTotalAmount()
  }

  bindTotalAmount() {
    var totalAmount = 0

    this.saveController.partners.forEach(partner => {
      totalAmount += Number(this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value))
    })

    this.totalAmountTarget.innerText = this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(parseInt(totalAmount * 100))
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}
