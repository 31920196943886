import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["contentCol", "contentRow", "listLoader", "days", "todo", "doing", "done", "taskPreloader"]

  connect() {
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  doCalendarConnection() {
    document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view = "calendar"
    var thisController = this
    new Promise(function (resolve) {
      resolve(thisController.doCalendarHTML())
    }).then(() => {
      thisController.doBoardListPreloader()
      thisController.doBoardLoader()
    })
  }

  disconnect() {
  }

  doBoardLoader() {
    const data = { filter: { due_date: "2020-07-01" }, current_user: { current_user_id: currentUser.id } }
    // this.request(data)
    this.listTasks(allBoardTasks)
  }

  request(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/entities/list_calendar"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.listLoaderTargets.forEach(listLoader => {
          listLoader.remove()
        }),
        allCalendarTasks = response.data.cln,
        this.listTasks(allCalendarTasks)
        
      })
  }

  listTasks(tasks) {

    this.listLoaderTargets.forEach(listLoader => {
      listLoader.remove()
    }),

    this.daysTargets.forEach((target, i) => {
      tasks.forEach(element => {
        var targetDate = target.dataset.abbr
        if (element.due_date_pretty == targetDate) {
          target.insertAdjacentHTML("beforeend", document.querySelector('#newTaskBtn').usersTasksBoardUnit.taskPartial(element))
          setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setPriority(element.priority); }, 100)
          setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.setDuration(element.duration); }, 100)
          setTimeout(function () { document.querySelector('#card-' + element.id).usersTasksBoardUnit.countDoneJobs(); }, 100)
        }
      });
      
    })
    tooltip()
  }

  doCalendarHTML() {
    const days = 12
    const d = new Date()
    const day = d.getDate()
    d.setDate(day)
    // var labelDate = this.getCurrentDate(new Date(d.getFullYear(), d.getMonth(), day), "abbr")
    var labelDate = this.getCurrentDate(new Date(d), "abbr")
    var labelDay = this.getCurrentDate(new Date(d), "week")
    var cardDate = this.getCurrentDate(new Date(d), "full")
    var dayCols = `<div id="day0" class="calendar-task-list" date="${cardDate}" date-abbr="${labelDate}" data-abbr="${labelDate}" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-users--tasks--board--calendar-target="days">
                      <div class="board-task-list-title d-flex justify-content-between">${labelDate} | ${labelDay}<span class="material-icons pointer" data-action="click->users--tasks--board--unit#new" id="newTaskBtn" data-controller="users--tasks--board--unit users--tasks--board--comment users--tasks--board--job users--tasks--board--modal">add</span></div>
                    </div>`
    for (var i = 1; i < days; i++) {
      const d = new Date()
      d.setDate(day + i)
      var labelDate = this.getCurrentDate(new Date(d), "abbr" )
      var labelDay = this.getCurrentDate(new Date(d), "week")
      var cardDate = this.getCurrentDate(new Date(d), "full")
      dayCols += `<div id="day${i}" class="calendar-task-list" date="${cardDate}" date-abbr="${labelDate}" data-abbr="${labelDate}" ondrop="dropIt(event)" ondragover="allowDrop(event)" data-users--tasks--board--calendar-target="days">
                    <div class="board-task-list-title d-flex justify-content-between">${labelDate} | ${labelDay}</div>
                  </div>`
    }
    var html = `<div>
                    <section class="grid-board-task grid-template-columns-board-task">`
                    html += dayCols
            html += `</section>
                  </div>`
    this.contentColTarget.insertAdjacentHTML("beforeend", html)
  }


  cleanTasks() {
    this.daysTargets.forEach(target => {
      while (target.children.length > 1) {
        target.removeChild(target.lastChild)
      }
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  fetchAllLabels() {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/labels/list"
    const data = {}
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(data => {
        allLabels = data.cln
      })
  }

  doBoardListPreloader() {
    const items = 4
    var rowHtml = ''
    for (var i = 0; i < items; i++) {
      rowHtml += `<div class='card timeline-item my-2' data-users--tasks--board--calendar-target="listLoader">
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                    <div class='animated-background animated-background-20'>
                      <div class='background-masker title'></div>
                    </div>
                  </div>`
    }
    this.daysTargets.forEach(element => {
      element.insertAdjacentHTML("beforeend", rowHtml)
    })
  }

  getCurrentDate(currentDate, kind) {
    const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
    const week = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

    if (kind == "full") {
      if (currentDate.getDate() < 10) {
        return `0${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear()}`
      } else {
        return `${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear()}`
      }
    } else if (kind == "abbr") {
      if (currentDate.getDate() < 10) {
        return `0${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear().toString().substr(-2)}`
      } else {
        return `${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear().toString().substr(-2)}`
      }
    } else if (kind == "week") {
      return `${week[currentDate.getDay()]}`
    }

  }
}