import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
  }

  copy(ev, permission) {
    var itemValue = ev.currentTarget.dataset.copy
    var $temp = $('<input id="inputCopyElement">');
    $("body").append($temp);
    $temp.val(itemValue).select();
    document.execCommand("copy");
    $temp.remove();

    var tooltiptextElement = ev.currentTarget.nextElementSibling
    var parentElement = ev.currentTarget.parentElement
    tooltiptextElement.innerText = `Copiado com sucesso`

    if (parentElement.classList.contains(`grab`)) {
      parentElement.classList.remove(`grab`)
      parentElement.classList.add(`grabbing`)
      setTimeout(() => {
        parentElement.classList.remove(`grabbing`)
        parentElement.classList.add(`grab`)
      }, 200);
    }

    setTimeout(() => {
      tooltiptextElement.innerText = `Clique para Copiar`
    }, 2000);

    // if (permission && (permission.can_copy || permission.can_copy_text)) {
    // } else {
    //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Não foi possível copiar o texto!", 3000)
    // }

  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}