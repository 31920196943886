import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--services`
    this.pageNumber = 0
    this.numberPages = []
  }

  doHtml() {
    var html = `<section class="ws-section-services d-flex align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="ws-title-section mb-5">${this.application.site.services_title}</h1>
                    </div>
                    <div class="row" data-${this.controllerName}-target="cards"></div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setServices()
    })
  }

  setServices() {
    var current_services = []

    this.application.site.services.forEach(service => {
      if (service.status) {
        current_services[current_services.length] = service
      }
    })
    current_services = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_services, `order`)

    if (this.application.size == `large`) {
      this.itemsOnPage = 6
    } else if (this.application.size == `medium`) {
      this.itemsOnPage = 4
    } else if (this.application.size == `small`) {
      this.itemsOnPage = 2
    }

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_services.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_services[x] !== undefined) {
          internPage[internPage.length] = current_services[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    this.doCarouselData(pages[pageNumber])
  }

  doCarouselData(data) {
    var html = ``

    data.forEach(service => {
      var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(service.link_cta)
      var colClass = ``

      if (this.application.size == `large`) {
        colClass += `col-4`
      } else if (this.application.size == `medium`) {
        colClass += `col-6`
      } else if (this.application.size == `small`) {
        colClass += `col-6`
      }

      html += `<div class="${colClass}">
                  <div class="ws-card-services pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">
                    <div class="container">
                      <h3 class="ws-card-title">${service.title}</h3>
                      <p class="ws-card-text">
                        ${service.description}
                      </p>
                      <a class="ws-card-link">${service.cta}</a>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5">
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.carouselIndicatorTargets[this.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = ev.currentTarget.dataset.slideTo

    this.setServices()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-section-services d-flex align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="ws-title-section mb-5">Serviços para o médico e o profissional de saúde</h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Controle de Pessoa Jurídica</h3>
                            <p class="ws-card-text">
                              Da abertura e regularização da sua empresa ao 
                              controle de obrigações recorrentes. Tudo de forma simples, 
                              com uma equipe especializada.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Faturamento de Operadoras de Saúde</h3>
                            <p class="ws-card-text">
                              Nosso time de especialistas cuida dos processos burocráticos da sua empresa para que você se 
                              dedique ao que sabe fazer melhor: cuidar das pessoas.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Controle Pessoa Física e Livro-Caixa</h3>
                            <p class="ws-card-text">
                              Nosso time especializado na vida financeira e contábil do médico tem 
                              conhecimento profundo sobre suas obrigações, despesas e receitas.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Declaração de Imposto de Renda</h3>
                            <p class="ws-card-text">
                              Te mostramos como usar a nossa plataforma para guardar as suas documentações e, 
                              então, nós fazemos sua Declaração de Imposto de Renda de forma prática e complicações.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Seguros e Proteção</h3>
                            <p class="ws-card-text">
                              Ao entender seu cenário pessoal e profissional, buscamos as melhores opções disponíveis 
                              no mercado para proteger você, sua família e a sua profissão.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="ws-card-services pointer">
                          <div class="container">
                            <h3 class="ws-card-title">Departamento Pessoal</h3>
                            <p class="ws-card-text">
                              Nossos contadores especializados em Departamento Pessoal cuidam de todos os processos que envolvem as 
                              obrigações com seus assistentes ou funcionários domésticos.
                            </p>
                            <a class="ws-card-link" href="">Saiba mais</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
