
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "scheduleLeadName", "team", "9999"]

  connect() {
    this.controllerName = `commercial--sales--leads--meetings--index`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `schedule_at_to_time` }
    this.doIndexListHtml()
  }

  showSchedule(ev) {
    var schedule = true
    if (schedule) {
      var controllerShow = this.getControllerByIdentifier("commercial--sales--leads--meetings--show")
      if (this.checked_id == ev.target.closest(".itemRow").dataset.id) {
        this.hasShow = false
      } else {
        this.hasShow = true
        this.checked_id = ev.target.closest(".itemRow").dataset.id
      }
      
      if (this.hasShow) {
        this.checkboxShowTargets.forEach(element => {
          if (checkBoxElement != element) {
            element.checked = false
          }
        })
        this.application.schedules.forEach(element => {
          if (element.id == this.checked_id) {
            controllerShow.current_schedule = element
          }
        })
        controllerShow.doScheduleHtml()
      } else {
        if (controllerShow.hasPreviewCardTarget) {
          controllerShow.doViewHtml()
        } else {
          controllerShow.doScheduleHtml()
        }
      }
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  blockSchedule() {
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").current_schedule = {}
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").actionMode = "block"
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").doFormHtml()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Consultorias</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="blockScheduleBtn" data-action="click->${this.controllerName}#blockSchedule" type="button">
                        <span class="material-icons">event_busy</span>
                      </button>
                      <span class="mc-tooltiptext">Bloquear Horário</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="schedule_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="schedule_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Lead
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="guest_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="guest_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                MedCapital
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:60%;" scope="col" class="pointer p-1 table-15 align-middle">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.application.schedules.length > 0) {
        controller.doDataTable()
      } else {
        controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(4, 6))
      }
    })
  }

  doDataTable() {
    var current_schedules = []


    if (this.application.all_team) {
      current_schedules = this.application.schedules
    } else {
      this.application.schedules.forEach(element => {
        if (element.account_id == this.application.account_id) {
          current_schedules[current_schedules.length] = element
        }
      })
    }

    if (this.sort.mode == `asc`) {
      var schedules = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_schedules, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var schedules = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_schedules, this.sort.field)
    }

    if (schedules.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(schedules.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (schedules[x] !== undefined) {
            internPage[internPage.length] = schedules[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: schedules.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Consultorias</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.scheduleTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  scheduleTablePartial(element, length) {

    // if (element.journeys[0] && element.journeys[0].stage_alert) {
    //   var alert = `<div class="col-1 px-0 mc-tooltip pointer">
    //                 <span class="material-icons md-sm md-danger">warning</span>
    //                 <span class="mc-tooltiptext">Atenção! Oportunidade vai esfriar!</span>
    //               </div>`
    // } else {
    //   var alert = ``
    // }

    if (this.application.permissions.schedules.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editSchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.account_name == null || element.account_name == undefined) {
      element.account_name = `Não Definido`
    }

    if (element.guest_name == null || element.guest_name == undefined) {
      element.guest_name = `Não Definido`
    }

    var rowHtml = `${tableRow}
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.schedule_at_full_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showSchedule">${element.guest_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.status_pretty}</td>
                  </tr>`

    return rowHtml

      // `<td style="font-size:60%;" scope="col" class="p-1 align-middle pointer">
      //   <p class="mb-0 f-065 mc-tooltip">
      //     <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
      //     <span class="mc-tooltiptext">Clique para Copiar</span>
      //   </p>
      // </td>`
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

}
