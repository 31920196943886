import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "mainCard", "bodyTable", "footerTable", "masterPermissions", "btnPermissions", "kind",
                    "kindCard", "kindHeaderCard", "9999"]

  connect() {
    this.controllerName = `users--works--permissions--entities--user`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.itemsOnPage = 50
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    this.hasFilter = false
    this.filter = ``
    this.tableRow = 6

    this.get_route = "/users/permissions/entities/list_accounts_permissions"
    this.new_route = "/users/permissions/entities/create_account_permissions"
    this.destroy_route = "/users/permissions/entities/destroy_account_permissions"
    this.current_account_kind = "team"
  }

  doPageGrid(element) {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row my-3">
                  <div class="col-2" data-${this.controllerName}-target="kind">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${this.cardLoader}
                      </div>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12" data-${this.controllerName}-target="list">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${loader}</div></div>
                            <div class="row my-2"><div class="col">${loader}</div></div>
                            <div class="row my-2"><div class="col">${loader}</div></div>
                          </div>
                          <div class="card-footer py-0">
                            <div class="row my-2"><div class="col">${loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(element.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.doIndexListHtml()
      controller.doAccountKindListHtml()
    })
  }

  doAccountKindListHtml() {
    var html = ``

    this.account_kinds.forEach(element => {
      var account_kind = element
      var account_kind_pretty = this.kindTranslate(element)

      html += `<div class="row mb-3">
                <div class="col-12 px-0">
                  <div class="card pointer" data-kind="${account_kind}" data-action="click->${this.controllerName}#changeKind" data-${this.controllerName}-target="kindCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="kindHeaderCard">
                      <span>${account_kind_pretty}</span>
                    </div>
                  </div>
                </div>
              </div>`
    });

    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.kindTarget.innerHTML = html)
    }).then(() => {
      this.kindCardTargets[0].classList.add(`bg-primary`)
    })
  }

  changeKind(ev) {

    this.kindCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });

    ev.currentTarget.classList.add(`bg-primary`)

    this.current_account_kind = ev.currentTarget.closest(`.card`).dataset.kind
    this.getPermissions()

  }

  doIndexListHtml() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-2 f-065">
                    <h6 class="card-title display-4 py-0 mb-0 f-085">Todos os Usuários</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-2 d-flex align-items-center">
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">
                                  Nome
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Perfil
                                </th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Permissões Simples
                                </th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Permissões Avançadas
                                </th>
                                <th colspan="2" style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center d-none" data-${this.controllerName}-target="masterPermissions">
                                  Permissões Master
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                    <div class="row my-2"><div class="col">${loader}</div></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableRow, 4))
      controller.getPermissions()
    })
  }

  doDataTable() {
    var accounts_permissions = []

    this.application.accounts_permissions.forEach(element => {
      if (element.account_kind == this.current_account_kind) {
        accounts_permissions[accounts_permissions.length] = element
      } else if (element.account_kind == `admin` && this.current_account_kind == `team`)  {
        accounts_permissions[accounts_permissions.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      accounts_permissions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(accounts_permissions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      accounts_permissions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(accounts_permissions, this.sort.field)
    }



    if (accounts_permissions.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(accounts_permissions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (accounts_permissions[x] !== undefined) {
            internPage[internPage.length] = accounts_permissions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: accounts_permissions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableRow}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.permissionTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

    if (this.application.domain_permission.can_manage) {
      this.masterPermissionsTargets.forEach(element => {
        element.classList.remove("d-none")
      });
    }
  }

  permissionTablePartial(element) {
    if (element.has_simple_permissions) {
      var simplePermissions = `<span class="mc-tooltip">
                                 <span class="material-icons md-sm md-success pointer">done</span>
                                 <span class="mc-tooltiptext">Tem Permissões</span>
                               </span>`
      var simpleBtnPermissions = `<button data-action="click->${this.controllerName}#destroySimplePermissions" data-${this.controllerName}-target="btnPermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                   <span class="material-icons md-sm pointer">delete</span>
                                   <span class="mc-tooltiptext">Apagar Permissões</span>
                                 </button>`
    } else {
      var simplePermissions = `<span class="mc-tooltip">
                                <span class="material-icons md-sm md-danger pointer">clear</span>
                                <span class="mc-tooltiptext">Não tem Permissões</span>
                              </span>`
      var simpleBtnPermissions = `<button data-action="click->${this.controllerName}#saveSimplePermissions" type="button" data-${this.controllerName}-target="btnPermissions" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm pointer">slideshow</span>
                                  <span class="mc-tooltiptext">Gerar Permissões</span>
                                </button>`
    }

    if (element.has_advanced_permissions) {
      var advancedPermissions = `<span class="mc-tooltip">
                                  <span class="material-icons md-sm md-success pointer">done</span>
                                  <span class="mc-tooltiptext">Tem Permissões</span>
                                </span>`
      var advancedBtnPermissions = `<button data-action="click->${this.controllerName}#destroyAdvancedPermissions" type="button" data-${this.controllerName}-target="btnPermissions" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm pointer">delete</span>
                                    <span class="mc-tooltiptext">Apagar Permissões</span>
                                  </button>`
    } else {
      var advancedPermissions = `<span class="mc-tooltip">
                                  <span class="material-icons md-sm md-danger pointer">clear</span>
                                  <span class="mc-tooltiptext">Não tem Permissões</span>
                                </span>`
      var advancedBtnPermissions = `<button data-action="click->${this.controllerName}#saveAdvancedPermissions" type="button" data-${this.controllerName}-target="btnPermissions" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm pointer">slideshow</span>
                                  <span class="mc-tooltiptext">Gerar Permissões</span>
                                </button>`
    }

    if (element.has_master_permissions) {
      var masterPermissions = `<span class="mc-tooltip">
                                  <span class="material-icons md-sm md-success pointer">done</span>
                                  <span class="mc-tooltiptext">Tem Permissões</span>
                                </span>`
      var masterBtnPermissions = `<button data-action="click->${this.controllerName}#destroyMasterPermissions" type="button" data-${this.controllerName}-target="btnPermissions" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm pointer">delete</span>
                                    <span class="mc-tooltiptext">Apagar Permissões</span>
                                  </button>`
    } else {
      var masterPermissions = `<span class="mc-tooltip">
                                  <span class="material-icons md-sm md-danger pointer">clear</span>
                                  <span class="mc-tooltiptext">Não tem Permissões</span>
                                </span>`
      var masterBtnPermissions = `<button data-action="click->${this.controllerName}#saveMasterPermissions" type="button" data-${this.controllerName}-target="btnPermissions" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm pointer">slideshow</span>
                                  <span class="mc-tooltiptext">Gerar Permissões</span>
                                </button>`
    }

    var accountPath = `<button data-action="click->${this.controllerName}#goToURL" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Perfil Conta</span>
                        </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.account_id}" data-account-path="${element.account_path}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToURL">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${accountPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${simplePermissions}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${simpleBtnPermissions}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${advancedPermissions}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${advancedBtnPermissions}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center d-none" data-${this.controllerName}-target="masterPermissions">${masterPermissions}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center d-none" data-${this.controllerName}-target="masterPermissions">${masterBtnPermissions}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="200">200</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  toggleBtnPermissions(mode) {

    if (mode == `on`) {
      this.btnPermissionsTargets.forEach(element => {
        element.disabled = false
      });
    } else if (mode == `off`) {
      this.btnPermissionsTargets.forEach(element => {
        element.disabled = true
      });
    }

  }

  saveSimplePermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `new`
    this.send_data.permissions.kind = `simple`

    this.prepareRequest(ev)

  }

  destroySimplePermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `destroy`
    this.send_data.permissions.kind = `simple`

    this.prepareRequest(ev)
  }

  saveAdvancedPermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `new`
    this.send_data.permissions.kind = `advanced`

    this.prepareRequest(ev)
  }

  destroyAdvancedPermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `destroy`
    this.send_data.permissions.kind = `advanced`

    this.prepareRequest(ev)
  }

  saveMasterPermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `new`
    this.send_data.permissions.kind = `master`

    this.prepareRequest(ev)
  }

  destroyMasterPermissions(ev) {
    this.toggleBtnPermissions(`off`)
    this.send_data = { current_user: {}, permissions: {} }

    this.actionMode = `destroy`
    this.send_data.permissions.kind = `master`

    this.prepareRequest(ev)
  }

  prepareRequest(ev) {
    var account_id = ev.currentTarget.closest(".itemRow").dataset.id
    var account = {}
    this.application.accounts_permissions.forEach(element => {
      if (element.account_id == account_id) {
        account = element
      }
    });

    this.send_data.permissions.account_id = account.account_id
    this.send_data.permissions.account_kind = account.account_kind
    this.send_data.permissions.user_id = account.user_id
    this.send_data.permissions.subdomain = this.subdomain
    this.send_data.permissions.domain_permission = this.application.domain_permission.feature_name

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = this.new_route
      var method = "POST"
    } else if (this.actionMode == `destroy`) {
      var url = this.destroy_route
      var method = "DELETE"
    }

    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var team_permission = response.data.cln[0]
          controller.application.accounts_permissions.forEach((element, i) => {
            if (element.account_id == team_permission.account_id) {
              controller.application.accounts_permissions.splice(controller.application.accounts_permissions.indexOf(element), 1, team_permission)
            }
          })
          
          controller.doDataTable()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        controller.toggleBtnPermissions(`on`)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.toggleBtnPermissions(`on`)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getPermissions() {
    
    const data = { current_user: { current_user_id: this.application.current_user.id }, permission: { subdomain: this.subdomain, domain_permission: this.application.domain_permission.feature_name, account_kind: this.current_account_kind } }
    const url = this.get_route
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    // var element = this.getControllerByIdentifier("operations--onboards--dashboard--main").contentTarget
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.accounts_permissions = response.data.cln
        controller.doDataTable()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    // var targets = ["mainCard", "medclinicCard", "medbookingCard", "medfilingCard", "medpayrollCard", "medreceivementCard", "medbilingCard",]
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  kindTranslate(kind) {
    if (kind == `team`) {
      return `Time`
    } else if (kind == `doctor`) {
      return `Médico`
    } else if (kind == `helper`) {
      return `Assistente`
    } else if (kind == `patient`) {
      return `Paciente`
    } else if (kind == `taker`) {
      return `Tomador`
    } else if (kind == `admin`) {
      return `Administrador`
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.closest(".itemRow").dataset.accountPath
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}