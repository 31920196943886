import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "text", "image", "editBtn", "cancelBtn", "saveBtn", "6666", "7777", "8888", "9999",
                    "titleRow", "titleDiv", "titleInputDiv", "titleInput",
                    "subtitleRow", "subtitleDiv", "subtitleInputDiv", "subtitleInput",
                    "descriptionRow", "descriptionDiv", "descriptionInputDiv", "descriptionInput",
                    "ctaRow", "ctaDiv", "ctaInputDiv", "ctaInput",
                    "linkCtaRow", "linkCtaDiv", "linkCtaInputDiv", "linkCtaInput",
                    "imageRow", "imageDiv", "imageInputDiv", "imageInput"]

  connect() {
    this.controllerName = `commercial--marketing--sites--platform--dashboard`
  }

  doPlatformDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 offset-2" data-${this.controllerName}-target="text"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--sites--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doCardHtml()
    })
  }

  doCardHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.marketing_site_entity.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editSite" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12 px-2">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          
                        </tr>

                        <tr data-field="title" data-${this.controllerName}-target="titleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Plataforma</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="titleDiv">
                              ${this.application.site.platform_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="titleInputDiv">
                              <input data-${this.controllerName}-target="titleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="subtitle" data-${this.controllerName}-target="subtitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Subtítulo Plataforma</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="subtitleDiv">
                              ${this.application.site.platform_subtitle}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="subtitleInputDiv">
                              <input data-${this.controllerName}-target="subtitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="description" data-${this.controllerName}-target="descriptionRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Descrição Plataforma</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="descriptionDiv">
                              ${this.application.site.platform_description}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="descriptionInputDiv">
                              <input data-${this.controllerName}-target="descriptionInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="cta" data-${this.controllerName}-target="ctaRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>CTA Plataforma</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="ctaDiv">
                              ${this.application.site.platform_cta}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="ctaInputDiv">
                              <input data-${this.controllerName}-target="ctaInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="linkCta" data-${this.controllerName}-target="linkCtaRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Link CTA Plataforma</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="linkCtaDiv">
                              ${this.application.site.platform_link_cta}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="linkCtaInputDiv">
                              <input data-${this.controllerName}-target="linkCtaInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="cta" data-${this.controllerName}-target="imageRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Link Imagem</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="imageDiv">
                              ${this.application.site.platform_image}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="imageInputDiv">
                              <input data-${this.controllerName}-target="imageInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        
                        
                        <tr>
                          <td colspan="10" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveSite" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.textTarget.innerHTML = html)
    }).then(() => {

      // controller.titleRowTarget.classList.add(`d-none`)
      controller.subtitleRowTarget.classList.add(`d-none`)
      // controller.descriptionRowTarget.classList.add(`d-none`)
      controller.imageRowTarget.classList.add(`d-none`)

    })
  }

  editSite(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }

    if (this.field == "title") {
      this.titleDivTarget.classList.add("d-none")
      this.titleInputDivTarget.classList.remove("d-none")
      this.titleInputTarget.value = this.titleDivTarget.innerText.trim()
      this.titleInputTarget.focus()
    } else if (this.field == "subtitle") {
      this.subtitleDivTarget.classList.add("d-none")
      this.subtitleInputDivTarget.classList.remove("d-none")
      this.subtitleInputTarget.value = this.subtitleDivTarget.innerText.trim()
      this.subtitleInputTarget.focus()
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.add("d-none")
      this.descriptionInputDivTarget.classList.remove("d-none")
      this.descriptionInputTarget.value = this.descriptionDivTarget.innerText.trim()
      this.descriptionInputTarget.focus()
    } else if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cta") {
      this.ctaDivTarget.classList.add("d-none")
      this.ctaInputDivTarget.classList.remove("d-none")
      this.ctaInputTarget.value = this.ctaDivTarget.innerText.trim()
      this.ctaInputTarget.focus()
    } else if (this.field == "linkCta") {
      this.linkCtaDivTarget.classList.add("d-none")
      this.linkCtaInputDivTarget.classList.remove("d-none")
      this.linkCtaInputTarget.value = this.linkCtaDivTarget.innerText.trim()
      this.linkCtaInputTarget.focus()
    } else if (this.field == "image") {
      this.imageDivTarget.classList.add("d-none")
      this.imageInputDivTarget.classList.remove("d-none")
      this.imageInputTarget.value = this.imageDivTarget.innerText.trim()
      this.imageInputTarget.focus()

    }

  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "title") {
      this.titleDivTarget.classList.remove("d-none")
      this.titleInputDivTarget.classList.add("d-none")
      this.titleDivTarget.innerText = this.application.site.platform_title

    } else if (this.field == "subtitle") {
      this.subtitleDivTarget.classList.remove("d-none")
      this.subtitleInputDivTarget.classList.add("d-none")
      this.subtitleDivTarget.innerText = this.application.site.platform_subtitle
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.application.site.platform_description
    } else if (this.field == "cta") {
      this.ctaDivTarget.classList.remove("d-none")
      this.ctaInputDivTarget.classList.add("d-none")
      this.ctaDivTarget.innerText = this.application.site.platform_cta
    } else if (this.field == "linkCta") {
      this.linkCtaDivTarget.classList.remove("d-none")
      this.linkCtaInputDivTarget.classList.add("d-none")
      this.linkCtaDivTarget.innerText = this.application.site.platform_link_cta
    } else if (this.field == "image") {
      this.imageDivTarget.classList.remove("d-none")
      this.imageInputDivTarget.classList.add("d-none")
      this.imageDivTarget.innerText = this.application.site.platform_image

    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")


    if (this.titleDivTarget.classList.contains("d-none")) {
      this.titleDivTarget.classList.remove("d-none")
      this.titleInputDivTarget.classList.add("d-none")
      this.titleDivTarget.innerText = this.titleInputTarget.value
    }

    if (this.subtitleDivTarget.classList.contains("d-none")) {
      this.subtitleDivTarget.classList.remove("d-none")
      this.subtitleInputDivTarget.classList.add("d-none")
      this.subtitleDivTarget.innerText = this.subtitleInputTarget.value
    }

    if (this.descriptionDivTarget.classList.contains("d-none")) {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.descriptionInputTarget.value
    }

    if (this.ctaDivTarget.classList.contains("d-none")) {
      this.ctaDivTarget.classList.remove("d-none")
      this.ctaInputDivTarget.classList.add("d-none")
      this.ctaDivTarget.innerText = this.ctaInputTarget.value
    }

    if (this.linkCtaDivTarget.classList.contains("d-none")) {
      this.linkCtaDivTarget.classList.remove("d-none")
      this.linkCtaInputDivTarget.classList.add("d-none")
      this.linkCtaDivTarget.innerText = this.linkCtaInputTarget.value
    }

    if (this.imageDivTarget.classList.contains("d-none")) {
      this.imageDivTarget.classList.remove("d-none")
      this.imageInputDivTarget.classList.add("d-none")
      this.imageDivTarget.innerText = this.imageInputTarget.value
    }

  }

  enterInput(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.saveSite()
    }
  }

  saveSite() {
    this.send_data = { current_user: {}, site: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = controller.application.current_user.id
      controller.send_data.current_user.feature = `marketing_site_entity`


      controller.send_data.site.id = controller.application.site.id
      controller.send_data.site.platform_title = controller.titleDivTarget.innerText.trim()
      controller.send_data.site.platform_subtitle = controller.subtitleDivTarget.innerText.trim()
      controller.send_data.site.platform_description = controller.descriptionDivTarget.innerText.trim()
      controller.send_data.site.platform_image = controller.imageDivTarget.innerText.trim()
      controller.send_data.site.platform_cta = controller.ctaDivTarget.innerText.trim()
      controller.send_data.site.platform_link_cta = controller.linkCtaDivTarget.innerText.trim()

      controller.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/commercial/marketing/sites/entities/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.site = response.data.cln
          controller.doCardHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.titleInputTarget.classList.contains("d-none") && controller.titleInputTarget.value == "") {
        len += 1
      }

      // if (controller.cpfInputTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") {
      //   len += 1
      // }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}