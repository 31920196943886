import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.booking_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.bookingUpdateChannel = createChannel({ channel: 'Operations::Products::BookingChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "booking_calculation") {
          controller.application.booking_calculations.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.booking_calculations.splice(controller.application.booking_calculations.indexOf(element), 1, data.cln)
            }
          })
        } else if (data.kind == "tax_return_calculation") {
          controller.application.tax_return_calculations.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.tax_return_calculations.splice(controller.application.tax_return_calculations.indexOf(element), 1, data.cln)
            }
          })
        } else if (data.kind == "provision") {
          controller.application.provisions = data.cln
        }

        if (controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--bookings--entities--dashboard").reconnect()
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.bookingChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      },
      appear() {
        this.perform('appear')
      },
    });
  }

  getRoom() {
    var data = { product: { id: this.application.booking.id, name: this.application.booking.name }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.update_room = response.data.cln.update
          controller.chat_room = response.data.cln.chat
          controller.application.chat_room = response.data.cln.chat
          controller.broadcastUpdateChannel()
          controller.broadcastChatChannel()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  updateListen() {
    if (this.bookingUpdateChannel) {
      this.bookingUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.bookingChatChannel) {
      this.bookingChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  // openSocket() {
  //   var controller = this
  //   this.application.booking_socket.onopen = function (e) {
  //     var msg = {
  //       command: 'subscribe',
  //       identifier: JSON.stringify({
  //         id: controller.notification_room.id,
  //         channel: 'Operations::Products::BookingChannel'
  //       }),
  //     };
  //     controller.application.booking_socket.send(JSON.stringify(msg));
  //   }
  //   this.application.booking_socket.onmessage = function (e) {
  //     var response = e.data;
  //     var msg = JSON.parse(response);
  //     if (msg.type === "ping") {
  //       return;
  //     }
  //   }
  // }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
