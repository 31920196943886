import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "content", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--visits--pageviews--dashboard`
    this.application.permissions = {}
    this.application.interactions = []
    this.application.visitors = []
    this.doSearchGrid()
  }

  doSearchGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--marketing--visits--pageviews--summary
                                                            commercial--marketing--visits--pageviews--events
                                                            commercial--marketing--visits--pageviews--interactions"
                                           data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--visits--pageviews--summary").doSummaryGrid()
      controller.getCurrentUserPermissions()
    })
  }

  getCurrentUserPermissions() {
    const featureNames = ["marketing_visit_pageviews"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
          controller.getControllerByIdentifier("commercial--marketing--visits--pageviews--submenu").doSubmenuHtml()
        });
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
