
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "clientInput", "contractModal", "contractInput", "submenuDiv", "addContractBtn", "contracts", "contractSaveDiv"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--settings`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.current_contract = {}
  }

  doMenuHtml() {

    var html = `<div class="row">
                  <div class="col-12 px-0" data-${this.controllerName}-target="client">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewClientCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-body py-0">
                        <div class="row d-flex align-items-center">
                          <div class="col-1 text-right px-0" data-${this.controllerName}-target="clientModal">
                            <button data-action="click->${this.controllerName}#showClients" data-element="clientInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Clientes</span>
                            </button>
                          </div>
                          <div class="col-6" data-${this.controllerName}-target="clientModal">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">Selecione o Cliente</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="clientInput" type="text" placeholder="Selecione o Cliente" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 text-right px-0 d-none" data-${this.controllerName}-target="contractModal">
                            <button data-action="click->${this.controllerName}#showContracts" data-element="contractInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Contratos</span>
                            </button>
                          </div>
                          <div class="col-3 d-none" data-${this.controllerName}-target="contractModal">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">Selecione o Contrato</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="contractInput" type="text" placeholder="Selecione o Contrato" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 d-none" data-${this.controllerName}-target="addContractBtn">
                            O Cliente não possui Contratos
                          </div>
                          <div class="col-1 ml-auto" data-${this.controllerName}-target="submenuDiv"></div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-1 ml-auto" data-${this.controllerName}-target="contractSaveDiv"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>`

    

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--clients--contracts--dashboard`).settingsTarget.innerHTML = html)
    }).then(() => {
      controller.setSubmenu()
    })
  }

  setSubmenu() {
    if (this.application.permissions[this.permission].can_create && this.current_client) {
      var addLine = `<span class="dropdown-item py-1 pointer dropdown-submenu-item f-bold">${this.current_client.record_name}</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Contrato</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_pos_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Pós-Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_amendment" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Alteração PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medfiling_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Declaração IRPF</span>`
      addLine += `<div class="dropdown-divider"></div>`
    } else {
      var addLine = `<span class="dropdown-item py-1 pointer dropdown-submenu-item f-bold">Selecione o Cliente</span>`
    }

    if (this.current_contract.id) {
      var contractLine = `<span class="dropdown-item py-1 pointer dropdown-submenu-item f-bold">${this.current_contract.name}</span>`
    } else {
      var contractLine = ``
    }

    if (this.application.permissions[this.radar_permission].can_create && this.current_contract.id && (this.current_contract.recurring_kind == `recurring`)) {
      contractLine += `<span data-action="click->${this.controllerName}#addRadar" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Radar de Renovação</span>`
    }

    if (this.application.permissions[this.renewal_permission].can_update && this.current_contract.id && (this.current_contract.recurring_kind == `recurring`)) {
      contractLine += `<span data-action="click->${this.controllerName}#addRenewal" class="dropdown-item py-1 pointer dropdown-submenu-item">Renovar Contrato</span>`
    }

    if (this.application.permissions[this.permission].can_update && this.current_contract.id) {
      contractLine += `<span data-action="click->${this.controllerName}#editContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Contrato</span>`
    }

    if (this.application.permissions[this.permission].can_upload && this.current_contract.id) {
      contractLine += `<span data-action="click->${this.controllerName}#uploadContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload do Contrato</span>`
    }

    if (this.application.permissions[this.permission].can_delete && this.current_contract.id) {
      contractLine += `<span data-action="click->${this.controllerName}#destroyContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Contrato</span>`
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshContracts" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contratos</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${contractLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    this.submenuDivTarget.innerHTML = submenuHtml
  }

  setContract() {
    this.setSubmenu()
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.getControllerByIdentifier(`financials--books--clients--contracts--workings`).permission = this.permission
    this.getControllerByIdentifier(`financials--books--clients--contracts--workings`).current_client = this.current_client
    this.getControllerByIdentifier(`financials--books--clients--contracts--workings`).current_contract = this.current_contract
    this.getControllerByIdentifier(`financials--books--clients--contracts--workings`).getAddons()
  }

  addContract(ev) {
    if (this.current_client) {
      var modal = `financials--books--clients--contracts--save-modal`
      var kindModel = ev.currentTarget.dataset.model

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).kindModel = kindModel
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = {}
        controller.getControllerByIdentifier(modal).actionMode = "new"
        controller.getControllerByIdentifier(modal).open()
      })
    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  editContract(ev) {
    if (this.current_client) {
      var modal = `financials--books--clients--contracts--save-modal`
      var kindModel = ev.currentTarget.dataset.model

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).kindModel = kindModel
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).actionMode = "edit"
        controller.getControllerByIdentifier(modal).open()
      })

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  addRenewal(ev) {
    if (this.current_client) {
      var modal = `financials--books--clients--renewals--save-modal`

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.renewal_permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).actionMode = "new"
        controller.getControllerByIdentifier(modal).open()
      })

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  addRadar() {
    if (this.current_client) {
      var modal = `financials--books--clients--radars--save-modal`

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.radar_permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).actionMode = "new"
        controller.getControllerByIdentifier(modal).open()
      })

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  uploadContract(ev) {
    if (this.current_client) {
      var modal = `financials--books--clients--contracts--upload-modal`

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).open()
      })

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  destroyContract(ev) {
    if (this.current_client) {
      this.send_data = { current_user: {}, contract: {}, tracker: { manual: true } }

      this.send_data.contract.id = this.current_contract.id
      this.send_data.contract.active = false

      this.send_data.tracker.action = `destroyed`
      this.send_data.tracker.observations = `Apagou o Contrato ${this.current_contract.name}`
      this.send_data.tracker.record_id = this.current_client.id
      this.send_data.tracker.record_type = `financial_client_entities`
      this.send_data.tracker.obj_id = this.current_contract.id
      this.send_data.tracker.obj_type = `financial_client_contracts`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var html = `<div class="col-12 px-0" data-${this.controllerName}-target="contracts" data-controller="financials--books--clients--contracts--save"></div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.contractSaveDivTarget.innerHTML = html)
      }).then(() => {
        controller.controllerSaveContract = controller.getControllerByIdentifier("financials--books--clients--contracts--save")

        controller.controllerSaveContract.controllerDashboard = controller
        controller.controllerSaveContract.actionMode = `destroy`
        controller.controllerSaveContract.send_data = controller.send_data
        controller.controllerSaveContract.permission = controller.permission
        controller.controllerSaveContract.current_client = controller.current_client
        controller.controllerSaveContract.current_contract = controller.current_contract

        var r = confirm(`Tem certeza que deseja apagar o Contrato?`)
        if (r) {
          controller.controllerSaveContract.requestSave()
        } else {
          controller.contractsTarget.remove()
        }
      })

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  showClients(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--books--clients--entities--list-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).permission = `financial_client_entities`
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showContracts(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--books--clients--contracts--list-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).permission = `financial_client_contracts`
      controller.getControllerByIdentifier(modal).current_client = controller.current_client
      controller.getControllerByIdentifier(modal).recurring = false
      controller.getControllerByIdentifier(modal).open()
    })
  }

  getClientContracts() {
    this.setSubmenu()
    var submessage = `Carregando Contratos do Cliente`
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: submessage })

    const data = { contract: { active: true, client_id: this.current_client.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/clients/contracts/list_by_client"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.contracts = response.data.cln
        controller.setContractInput()
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setContractInput() {
    if (this.application.contracts.length > 0) {
      this.toggleContractInput(true)
      this.toggleAddContractBtn(false)
    } else {
      this.toggleContractInput(false)
      this.toggleAddContractBtn(true)
    }

    this.getControllerByIdentifier(`financials--books--clients--contracts--dashboard`).addonsTarget.innerHTML = ``
    this.getControllerByIdentifier(`financials--books--clients--contracts--dashboard`).workingsTarget.innerHTML = ``
  }

  toggleContractInput(value) {
    this.contractInputTarget.dataset.clientId = ``
    this.contractInputTarget.dataset.contractId = ``
    this.contractInputTarget.value = ``
    this.current_contract = {}

    if (value) {
      this.contractModalTargets.forEach(element => {
        element.classList.remove(`d-none`)
      })
    } else {
      this.contractModalTargets.forEach(element => {
        element.classList.add(`d-none`)
      })
    }

    this.setSubmenu()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  toggleAddContractBtn(value) {
    if (value) {
      this.addContractBtnTargets.forEach(element => {
        element.classList.remove(`d-none`)
      })
    } else {
      this.addContractBtnTargets.forEach(element => {
        element.classList.add(`d-none`)
      })
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshLoadValue(day) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Liquidando as Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()
        // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        var response = { type: `danger`, message: `Tivemos um problema para liquidar as Faturas` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 1500);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

  layout() {
    
  }


}
