import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--products--commissions--entities--dashboard`
  }

  doCommissionDashboard() {
    // `<div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="financials--products--commissions--entities--save financials--products--commissions--entities--show"></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="main" data-controller="financials--products--commissions--entities--index financials--products--commissions--entities--save"></div>
                  
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--products--commissions--entities--index`).permission = `financial_commission_entities`
      controller.getControllerByIdentifier(`financials--products--commissions--entities--save`).permission = `financial_commission_entities`
      controller.getControllerByIdentifier(`financials--products--commissions--entities--index`).doIndexListHtml()
      // controller.getControllerByIdentifier(`financials--products--commissions--entities--save`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
