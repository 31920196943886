import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "emailInput", "nameInput", "dddInput", "saveBtn",
    "phoneInput", "checkboxAcademic", "checkboxDoctor", "checkboxNotDoctor", "doctorRow", "crmInput",
    "crmStateDropdown", "crmStateDropdownBtn", "crmStateInput", "academicRow", "medschoolInput", "graduationInput",
    "crmStateList", "searchBtn", "crmStateInput", "validAttendee", "checkValidAttendee"]


  connect() {
    this.controllerName = `web--commercial--marketing--events--attendees--save`
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.controllerDashboard.context.identifier == `commercial--sales--leads--sources--dashboard`) {
      this.getControllerByIdentifier(`commercial--sales--leads--sources--save-lead`).doViewHtml()
      this.getControllerByIdentifier(`commercial--sales--leads--sources--dashboard`).doListHtml(`events`)
    } else {
      
    }
    // this.getControllerByIdentifier(`commercial--marketing--events--attendees--show`).doViewHtml()
    // this.getControllerByIdentifier(`commercial--marketing--events--attendees--list`).doDataTable()
  }

  searchAttendee() {
    var email = this.emailInputTarget.value
    this.stopRefreshing()
    this.refreshSaveBtn()

    var data = { attendee: { email: email }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/commercial/marketing/events/attendees/read"
    var controller = this
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var attendee = response.data.cln
        controller.current_attendee = attendee

        if (response.process) {
          controller.emailInputTarget.disabled = true

          controller.nameInputTarget.value = attendee.name
          controller.dddInputTarget.value = attendee.ddd
          controller.phoneInputTarget.value = attendee.phone_pretty
          if (attendee.kind == `academic`) {
            controller.checkboxAcademicTarget.checked = true
            controller.checkboxDoctorTarget.checked = false
            controller.checkboxNotDoctorTarget.checked = false

            controller.medschoolInputTarget.value = attendee.med_school
            controller.graduationInputTarget.value = attendee.graduation

            controller.academicRowTarget.classList.remove("d-none")
          } else if (attendee.kind == `doctor`) {
            controller.checkboxAcademicTarget.checked = false
            controller.checkboxDoctorTarget.checked = true
            controller.checkboxNotDoctorTarget.checked = false

            controller.crmStateInputTarget.value = attendee.crm_state
            controller.crmInputTarget.value = attendee.crm_pretty

            controller.doctorRowTarget.classList.remove("d-none")
          } else if (attendee.kind == `not_doctor`) {
            controller.checkboxAcademicTarget.checked = false
            controller.checkboxDoctorTarget.checked = false
            controller.checkboxNotDoctorTarget.checked = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
  }

  saveAdmission() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.send_data = { current_user: {}, attendee: {} }

    if (this.actionMode == `new`) {
      
    } else if (this.actionMode == `edit`) {
      this.send_data.attendee.id = this.current_attendee.id
    }


    this.send_data.attendee.name = this.nameInputTarget.value.trim()
    this.send_data.attendee.email = this.emailInputTarget.value.trim().toLowerCase()
    this.send_data.attendee.ddd = this.dddInputTarget.value.trim()
    this.send_data.attendee.number = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value.trim())
    this.send_data.attendee.is_valid = this.checkValidAttendeeTarget.checked
    if (this.kind == `academic`) {
      this.send_data.attendee.med_school = this.medschoolInputTarget.value.trim()
      this.send_data.attendee.graduation = this.graduationInputTarget.value.trim()
    } else if (this.kind == `doctor`) {
      this.send_data.attendee.crm = this.getControllerByIdentifier("app--helpers--numbers").fromCrmToNumber(this.crmInputTarget.value.trim())
      this.send_data.attendee.crm_state = this.crmStateInputTarget.value.trim().toUpperCase()
      this.send_data.attendee.crm_input = `${this.crmStateInputTarget.value.trim().toUpperCase()} ${this.crmInputTarget.value.trim()}`
    }
    this.send_data.attendee.kind = this.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (this.actionMode == `new`) {
      
    } else if (this.actionMode == `edit`) {
      this.requestSave()
    }

  }

  requestSave() {
    this.stopRefreshing()
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/events/attendees/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/events/attendees/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var attendee = response.data.cln
          
          if (this.actionMode == `new`) {
            controller.application.attendees[controller.application.attendees.length] = attendee
          } else if (this.actionMode == `edit`) {

            if (this.controllerDashboard.context.identifier == `commercial--sales--leads--sources--dashboard`) {
              controller.application.event_leads.forEach((element, i) => {
                if (element.id == attendee.id) {
                  controller.application.event_leads.splice(controller.application.event_leads.indexOf(element), 1, attendee)
                }
              })
            } else {

            }

          }
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveAdmission" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-3 px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row d-flex align-items-center">
                          <div class="col-8 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">E-mail</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="emailInput" type="text" placeholder="E-mail" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Nome</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="nameInput" type="text" placeholder="Nome" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">DDD</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="dddInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="DDD" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Telefone</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="phoneInput" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask" placeholder="Telefone" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 my-3 d-flex justify-content-between">
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input type="checkbox" class="form-check-input" name="academicAdmission" data-${this.controllerName}-target="checkboxAcademic" data-kind="academic" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="academicAdmission" style="margin-left:1rem;">Acadêmico</label>
                            </div>
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input type="checkbox" class="form-check-input" name="doctorAdmission" data-${this.controllerName}-target="checkboxDoctor" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="doctorAdmission" style="margin-left:1rem;">Médico</label>
                            </div>
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input type="checkbox" class="form-check-input" name="notDoctorAdmission" data-${this.controllerName}-target="checkboxNotDoctor" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">
                              <label class="form-check-label ml-1" for="notDoctorAdmission" style="margin-left:1rem;">Outro</label>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="academicRow">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Faculdade</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="medschoolInput" type="text" placeholder="Faculdade" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Formatura</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="graduationInput" type="text" placeholder="Formatura" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="doctorRow">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="crmStateForm">UF CRM</label>
                                <input aria-describedby="crmStateFormHelp" class="form-control" id="crmStateForm" data-${this.controllerName}-target="crmStateInput" placeholder="UF CRM" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">CRM</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="crmInput" type="text" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" placeholder="CRM" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-3" data-${this.controllerName}-target="validAttendee">
                          <div class="col-12 my-3 d-flex justify-content-between">
                            <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                              <input type="checkbox" class="form-check-input" name="checkValidAttendee" data-${this.controllerName}-target="checkValidAttendee">
                              <label class="form-check-label ml-1" for="checkValidAttendee" style="margin-left:1rem;">Participante Válido para Eventos</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Inscrição`
        controller.emailInputTarget.disabled = false
        controller.searchBtnTarget.disabled = false

        controller.checkValidAttendeeTarget.checked = true
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Participante | ${controller.current_attendee.name}`
        controller.nameInputTarget.value = controller.current_attendee.name
        controller.emailInputTarget.value = controller.current_attendee.email
        controller.dddInputTarget.value = controller.current_attendee.ddd
        controller.phoneInputTarget.value = controller.current_attendee.number_pretty

        if (controller.current_attendee.is_valid) {
          controller.checkValidAttendeeTarget.checked = true
        } else {
          controller.checkValidAttendeeTarget.checked = false
        }

        if (controller.current_attendee.kind == `academic`) {
          controller.kind = `academic`
          controller.checkboxAcademicTarget.checked = true
          controller.academicRowTarget.classList.remove("d-none")
          controller.medschoolInputTarget.value = controller.current_attendee.med_school
          controller.graduationInputTarget.value = controller.current_attendee.graduation
        } else if (controller.current_attendee.kind == `doctor`) {
          controller.kind = `doctor`
          controller.checkboxDoctorTarget.checked = true
          controller.doctorRowTarget.classList.remove("d-none")

          controller.crmStateInputTarget.value = controller.current_attendee.crm_state
          if (controller.current_attendee.crm) {
            controller.crmInputTarget.value = controller.current_attendee.crm
          } else {
            controller.crmInputTarget.value = controller.current_attendee.crm_input
          }
        } else {
          controller.kind = `not_doctor`
          controller.checkboxNotDoctorTarget.checked = true
        }

      }

      if (controller.application.current_user.super_admin) {
        controller.checkValidAttendeeTarget.disabled = false
      } else {
        controller.checkValidAttendeeTarget.disabled = true
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  crmMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCrm(ev)
  }

  prefixMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  checkKind(ev) {
    this.kind = ev.currentTarget.dataset.kind

    if (this.kind == `academic`) {
      this.checkboxAcademicTarget.checked = true
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.doctorRowTarget.classList.add("d-none")

    } else if (this.kind == `doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = true
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTarget.classList.remove("d-none")

    } else if (this.kind == `not_doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = true

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTarget.classList.add("d-none")
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.emailInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.dddInputTarget.value == ``) {
        len += 1
      }

      if (controller.phoneInputTarget.value == ``) {
        len += 1
      }

      if (controller.kind == `doctor`) {

      } else if (controller.kind == `academic`) {

      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
