import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxProcess", "cardTitle",
    "totalDocuments", "processedDocuments", "termSpan", "cancelBtn",
    "termDropdown", "termDropdownBtn", "termInput", "termList"]

  connect() {
    this.controllerName = `operations--products--tax-filings--process--index`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.doIndexListHtml()
  }

  cancelAllInputs() {
    // this.application.files.forEach(element => {
    //   if (element.date_id == this.application.current_date.id) {
    //     if (element.status == `approved` || element.status == `processed`) {
    //       if (this.nameTarget(`termInput-${element.id}`)) {
    //         this.nameTarget(`termSpan-${element.id}`).classList.remove("d-none")
    //         this.nameTarget(`termSelect-${element.id}`).classList.add("d-none")
    //         this.nameTarget(`termSpan-${element.id}`).innerText = this.nameTarget(`termInput-${element.id}`).innerText
    //       }
    //     }
    //   }
    // })

    this.current_files.forEach(element => {
      if (this.nameTarget(`termInput-${element.id}`) && this.nameTarget(`termInput-${element.id}`).innerText) {
        this.nameTarget(`termSpan-${element.id}`).classList.remove("d-none")
        this.nameTarget(`termSelect-${element.id}`).classList.add("d-none")
        this.nameTarget(`termSpan-${element.id}`).innerText = this.nameTarget(`termInput-${element.id}`).innerText
      }
    })
  }

  cancelInput(ev) {
    var fileId = ev.target.closest(".itemRow").dataset.id

    this.nameTarget(`termSpan-${fileId}`).classList.remove("d-none")
    this.nameTarget(`termSelect-${fileId}`).classList.add("d-none")
    this.nameTarget(`termSpan-${fileId}`).innerText = this.nameTarget(`termInput-${fileId}`).innerText
  }

  editTerm(ev) {
    this.cancelAllInputs()
    var fileId = ev.target.closest(".itemRow").dataset.id
    this.application.files.forEach(element => {
      if (element.id == fileId) {
        this.current_file = element
      }
    })

    this.nameTarget(`termSpan-${fileId}`).classList.add("d-none")
    this.nameTarget(`termSelect-${fileId}`).classList.remove("d-none")
    this.nameTarget(`termInput-${fileId}`).innerText = this.current_file.term_pretty
    this.nameTarget(`termInput-${fileId}`).dataset.term = this.current_file.term
    this.nameTarget(`termDropdown-${fileId}`).value = this.current_file.term
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

  }

  selectTerm(ev) {
    this.cancelAllInputs()
    this.send_data = { current_user: {}, file: {}, tracker: { manual: true } }
    this.application.files.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        this.current_file = element
      }
    })

    this.actionMode = `edit`

    this.send_data.file.id = this.current_file.id

    var checkBoxElement = ``
    checkBoxElement = ev.target

    this.setNotificationPhrases()
    this.send_data.file.term = ev.currentTarget.dataset.term
    this.nameTarget(`termSpan-${this.current_file.id}`).innerText = ev.currentTarget.innerText

    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `Atualizou o Arquivo ${this.current_file.document_name} para o Tempo de Processamento ${ev.currentTarget.innerText}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.files_permission

    this.requestSave()
  }

  processDocument(ev) {
    this.cancelAllInputs()
    var elementId = ev.target.closest(".itemRow").dataset.id
    // this.nameTarget(`checkboxProcess-${elementId}`).disabled = true
    this.send_data = { current_user: {}, file: {}, tracker: { manual: true } }
    this.application.files.forEach(element => {
      if (element.id == elementId) {
        this.current_file = element
      }
    })

    this.actionMode = `edit`

    this.send_data.file.id = this.current_file.id

    var checkBoxElement = ``
    checkBoxElement = ev.target

    if (checkBoxElement.checked) {
      checkBoxElement.disabled = true
      this.setNotificationPhrases()
      this.send_data.file.status = `processed`

      this.send_data.tracker.record_id = this.application.tax_filing.id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.application.current_date.id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Processou o Arquivo ${this.current_file.document_name} na Declaração ${this.application.current_date.financial_year}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.application.files_permission

      this.requestSave()
    } else {
      // this.send_data.file.status = `approved`
      // this.nameTarget(`status-${this.current_file.id}`).innerText = `Aprovado`
    }

    // var r = confirm(`O Arquivo foi Processado na Declaração?`)
    // if (r) {
    // } else {
    //   checkBoxElement.checked = false
    // }

  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/operations/products/tax_filings/files/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var file = response.data.cln
          this.nameTarget(`checkboxProcess-${file.id}`).disabled = false
          controller.application.files.forEach((element, i) => {
            if (element.id == file.id) {
              controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
            }
          })
          controller.filesCount()
        }

        controller.doDataTable()
        controller.getControllerByIdentifier("operations--products--tax-filings--process--summary").doSummaryHtml()
        controller.getControllerByIdentifier("operations--products--tax-filings--show--submenu").doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        controller.doDataTable()
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Processamento da Declaração</h6>
                    <input class="form-control f-070 w-30 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <h6 class="card-title display-4 card-title-table-list">Aprovados: <span data-${this.controllerName}-target="totalDocuments"></span> / <span data-${this.controllerName}-target="processedDocuments"></span> Processados</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                  Capítulo
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chapter_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chapter_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                  Arquivo
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">ID</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center" colspan="2"><span class="material-icons md-sm md-dark">attach_file</span></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">
                                  Esforço
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="term_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="term_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle text-center">Status</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Processado</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.setProcessFeature()
    this.current_files = []
    this.not_revised_files = []
    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        if (element.status == `approved` || element.status == `processed`) {
          this.current_files[this.current_files.length] = element
        }
        if (element.status == `not_revised`) {
          this.not_revised_files[this.not_revised_files.length] = element
        }
      }
    })

    if (this.sort.mode == `asc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_files, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_files, this.sort.field)
    }

    this.filesCount()

    if (files.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(files.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (files[x] !== undefined) {
            internPage[internPage.length] = files[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: files.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Documentos Aprovados para Processamento da Declaração do Ano-Exercício ${this.application.current_date.year + 1}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.fileTablePartial(element, data.length))
      });
    }

    // if (this.checked_id) {
    //   this.checkboxProcessTargets.forEach(element => {
    //     if (element.id == `check-${this.checked_id}`) {
    //       element.checked = true
    //     }
    //   })
    // }
  }

  fileTablePartial(element, length) {
    if (this.application.permissions[this.application.files_permission].can_select) {
      if (element.status == `processed`) {
        var check = `<div class="custom-control custom-checkbox">
                      <input disabled type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxProcess-${element.id}" checked="true">
                      <label class="custom-control-label pointer" for="check-${element.id}"></label>
                    </div>`
      } else if (this.processFeature == false) {
        var check = `<div class="custom-control custom-checkbox">
                      <input disabled type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxProcess-${element.id}">
                      <label class="custom-control-label pointer" for="check-${element.id}"></label>
                    </div>`
      } else {
        var check = `<div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxProcess-${element.id}" data-action="click->${this.controllerName}#processDocument">
                      <label class="custom-control-label pointer" for="check-${element.id}"></label>
                    </div>`
      }

    } else {
      var check = ''
    }

    // if (this.application.permissions[this.application.files_permission].can_show) {
    //   var document = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${element.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
    //                     <span class="material-icons md-sm md-dark">launch</span>
    //                     <span class="mc-tooltiptext">Ver Documento</span>
    //                   </button>`
    //   var refresh = `<button data-action="click->${this.controllerName}#refreshLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
    //                     <span class="material-icons md-sm md-dark">refresh</span>
    //                     <span class="mc-tooltiptext">Atualizar Link</span>
    //                   </button>`
    // } else {
    //   var document = ``
    //   var refresh = ``
    // }

    if (this.application.permissions[this.application.files_permission].can_show) {
      var document = `<button data-kind="document" data-obj-id="${element.id}" data-obj-type="tax_filing_files" data-action="click->${this.controllerName}#goToFile" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Arquivo</span>
                      </button>`
    } else {
      var document = `<span class="mc-tooltip">
                        <span class="material-icons md-200 md-danger default">link_off</span>
                        <span class="mc-tooltiptext">Não há Arquivo</span>
                      </span>`
    }
    var refresh = ``

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;" data-${this.controllerName}-target="row-${element.id}">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-${this.controllerName}-target="row-${element.id}">`
    }

    if (element.chapter_pretty == undefined) { element.chapter_pretty = `Não Definido` }
    if (element.term_pretty == undefined) { element.term_pretty = `Não Definido` }
    if (element.id_number == undefined) { element.id_number_pretty = `Não Definido` }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chapter_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.id_number_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center" data-${this.controllerName}-target="file-${element.id}">${document}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${refresh}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">
                      <span class="" data-${this.controllerName}-target="termSpan-${element.id}" data-action="click->${this.controllerName}#editTerm">
                        ${element.term_pretty}
                      </span>
                      <span class="d-flex align-items-center w-100 d-none" data-${this.controllerName}-target="termSelect-${element.id}">
                        <div class="form-group ml-3 mb-0 w-100">
                          <div class="floating-label floating-label-sm">
                            <label>Tempo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="termDropdown-${element.id}">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item f-065" data-app--helpers--selector-target="input" data-${this.controllerName}-target="termInput-${element.id}"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <ul class="ul-select" data-${this.controllerName}-target="termList" data-app--helpers--search-target="searchList">
                                  <li data-action="click->${this.controllerName}#selectTerm click->app--helpers--selector#select" data-term="short" data-app--helpers--selector-target="select" class="li-selector dark f-065">Rápido</li>
                                  <li data-action="click->${this.controllerName}#selectTerm click->app--helpers--selector#select" data-term="medium" data-app--helpers--selector-target="select" class="li-selector dark f-065">Médio</li>
                                  <li data-action="click->${this.controllerName}#selectTerm click->app--helpers--selector#select" data-term="long" data-app--helpers--selector-target="select" class="li-selector dark f-065">Longo</li>
                                  <li data-action="click->${this.controllerName}#selectTerm click->app--helpers--selector#select" data-term="costly" data-app--helpers--selector-target="select" class="li-selector dark f-065">Custoso</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ml-3 mb-0">
                          <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span aria-hidden="true">&times;</span>
                            <span class="mc-tooltiptext">Cancelar</span>
                          </button>
                        </div>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center" data-${this.controllerName}-target="status-${element.id}">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${check}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="150">150</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="200">200</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  refreshLink(ev) {
    var fileId = ev.currentTarget.closest(".itemRow").dataset.id
    var data = { file: { id: fileId }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/operations/products/tax_filings/files/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var file = response.data.cln
          var document = `<button data-action="click->${this.controllerName}#goToDocument" data-url="${file.document_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Documento</span>
                          </button>`
          controller.nameTarget(`file-${fileId}`).innerHTML = document
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setProcessFeature() {
    if (this.application.current_tax_filing_calculation.journey_status == `in_process`) {
      this.processFeature = true
    } else {
      this.processFeature = false
    }
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.application.files_permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  goToDocument(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  cleanCheckBox() {
    this.checkboxProcessTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  setNotificationPhrases() {
    if (this.current_file.chapter) {
      this.notification_chapter = `do Capítulo ${this.current_file.chapter_pretty}`
    } else {
      this.notification_chapter = `sem Capítulo definido`
    }
  }

  filesCount() {
    var fileProcessed = 0
    this.current_files.forEach(element => {
      if (element.status == `processed`) {
        fileProcessed += 1
      }
    })

    this.totalDocumentsTarget.innerText = this.current_files.length
    this.processedDocumentsTarget.innerText = fileProcessed
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
