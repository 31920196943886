import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "administrationClauseInput", "objectClauseInput", "profitClauseInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "validationCodeInput",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "registrationCodeInput",
                    "signedAtInput", "sharesInput", "socialCapitalInput", "versionInput", "saveBtn"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--incorporations--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--index").clearList()
  }

  saveIncorporation() {
    this.send_data = { current_user: {}, incorporation: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.incorporation.id = this.current_incorporation.id
    }
    
    this.send_data.incorporation.clinic_id = this.application.clinic.id
    this.send_data.incorporation.status = this.statusInputTarget.dataset.status
    this.send_data.incorporation.kind = this.kindInputTarget.dataset.kind
    this.send_data.incorporation.version = this.versionInputTarget.value
    this.send_data.incorporation.shares = this.sharesInputTarget.value
    this.send_data.incorporation.social_capital = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.socialCapitalInputTarget.value)
    this.send_data.incorporation.signed_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.signedAtInputTarget.value)
    this.send_data.incorporation.object_clause = this.objectClauseInputTarget.value.trim()
    this.send_data.incorporation.administration_clause = this.administrationClauseInputTarget.value.trim()
    this.send_data.incorporation.profit_clause = this.profitClauseInputTarget.value.trim()
    // this.send_data.incorporation.name = this.field.Target.value
    this.send_data.incorporation.validation_code = this.validationCodeInputTarget.value.trim()
    this.send_data.incorporation.registration_code = this.registrationCodeInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/clinics/regularizations/incorporations/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/clinics/regularizations/incorporations/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.application.incorporations = response.data.cln
        }

        controller.cancelSave()
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--index").doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveIncorporation" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Versão Atual</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Versão Anterior</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="formation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Constituição</li>
                                  <li data-kind="modification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="versionForm">Versão</label>
                            <input aria-describedby="versionFormHelp" class="form-control" id="versionForm" data-${this.controllerName}-target="versionInput" placeholder="Versão" type="number" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="sharesForm">Total Cotas</label>
                            <input aria-describedby="sharesFormHelp" class="form-control" id="sharesForm" data-${this.controllerName}-target="sharesInput" placeholder="Total Cotas" type="number" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="socialCapitalForm">Capital Social</label>
                            <input aria-describedby="socialCapitalFormHelp" class="form-control" id="socialCapitalForm" data-${this.controllerName}-target="socialCapitalInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Capital Social" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="validationCodeForm">Protocolo</label>
                            <input aria-describedby="validationCodeFormHelp" class="form-control" id="validationCodeForm" data-${this.controllerName}-target="validationCodeInput" placeholder="Protocolo" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="registrationCodeForm">Registro</label>
                            <input aria-describedby="registrationCodeFormHelp" class="form-control" id="registrationCodeForm" data-${this.controllerName}-target="registrationCodeInput" placeholder="Registro" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="signedAtForm">Data Processo</label>
                            <input aria-describedby="signedAtFormHelp" class="form-control" id="signedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="signedAtInput" placeholder="DD/MM/AAAA" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="objectClauseForm">Objeto Social</label>
                            <textarea rows="2" aria-describedby="objectClauseFormHelp" class="form-control form-valid-control" id="objectClauseForm" data-${this.controllerName}-target="objectClauseInput" placeholder="Objeto Social" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="administrationClauseForm">Cláusula Administrativa</label>
                            <textarea rows="2" aria-describedby="administrationClauseFormHelp" class="form-control form-valid-control" id="administrationClauseForm" data-${this.controllerName}-target="administrationClauseInput" placeholder="Cláusula Administrativa" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="profitClauseForm">Distribuição de Lucros</label>
                            <textarea rows="2" aria-describedby="profitClauseFormHelp" class="form-control form-valid-control" id="profitClauseForm" data-${this.controllerName}-target="profitClauseInput" placeholder="Distribuição de Lucros" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Contrato Social`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `active`
        controller.statusInputTarget.innerText = `Versão Atual`
        controller.statusInputTarget.dataset.status = `active`

        if (controller.application.incorporations.length == 0) {
          controller.kindDropdownBtnTarget.disabled = true
          controller.kindDropdownTarget.value = `formation`
          controller.kindInputTarget.innerText = `Constituição`
          controller.kindInputTarget.dataset.kind = `formation`

          // controller.sharesInputTarget.value = 0
          controller.socialCapitalInputTarget.value = `R$ 0,00`
        } else {
          controller.kindDropdownBtnTarget.disabled = true
          controller.kindDropdownTarget.value = `modification`
          controller.kindInputTarget.innerText = `Alteração`
          controller.kindInputTarget.dataset.kind = `modification`

          controller.sharesInputTarget.value = controller.application.incorporations[0].shares
          controller.socialCapitalInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(controller.application.incorporations[0].social_capital * 100)
        }

        controller.versionInputTarget.disabled = true
        controller.versionInputTarget.value = controller.application.incorporations.length + 1        

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Contrato Social`

        controller.statusDropdownBtnTarget.disabled = true
        controller.kindDropdownBtnTarget.disabled = true
        controller.versionInputTarget.disabled = true

        if (controller.current_incorporation.status == `active`) {
          
        } else if (controller.current_incorporation.status == `inactive`) {
          controller.sharesInputTarget.disabled = true
          controller.socialCapitalInputTarget.disabled = true
          controller.signedAtInputTarget.disabled = true
          controller.registrationCodeInputTarget.disabled = true
          controller.validationCodeInputTarget.disabled = true
          controller.objectClauseInputTarget.disabled = true
          controller.administrationClauseInputTarget.disabled = true
          controller.profitClauseInputTarget.disabled = true  
        }
        

        controller.versionInputTarget.value = controller.current_incorporation.version
        controller.sharesInputTarget.value = controller.current_incorporation.shares
        controller.socialCapitalInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(controller.current_incorporation.social_capital * 100)
        controller.signedAtInputTarget.value = controller.current_incorporation.signed_at_pretty
        controller.objectClauseInputTarget.value = controller.current_incorporation.object_clause
        controller.administrationClauseInputTarget.value = controller.current_incorporation.administration_clause
        controller.profitClauseInputTarget.value = controller.current_incorporation.profit_clause
        controller.validationCodeInputTarget.value = controller.current_incorporation.validation_code
        controller.registrationCodeInputTarget.value = controller.current_incorporation.registration_code
        // controller.nameInputTarget.value = controller.current_incorporation.name


        controller.statusDropdownTarget.value = controller.current_incorporation.status
        controller.statusInputTarget.innerText = controller.current_incorporation.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_incorporation.status

        controller.kindDropdownTarget.value = controller.current_incorporation.kind
        controller.kindInputTarget.innerText = controller.current_incorporation.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_incorporation.kind

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.signedAtInputTarget.classList.add("f-normal")
        this.signedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.signedAtInputTarget.classList.add("f-danger")
        this.signedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.signedAtInputTarget.classList.add("f-normal")
      this.signedAtInputTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardBodyTarget && controller.hasSaveBtnTarget) {

        if (controller.statusInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.sharesInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.socialCapitalInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.signedAtInputTarget.value == `` || (controller.checkDate && controller.checkDate.valid == false)) {
          len += 1
        }
        
        if (controller.registrationCodeInputTarget.value == ``) {
          len += 1
        }
 
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
        
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
