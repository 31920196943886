
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenuBtn", "bySubdomain", "allPolicies"]

  connect() {
    this.controllerName = `users--works--permissions--policies--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  bySubdomain() {
    this.getControllerByIdentifier(`users--works--permissions--policies--dashboard`).mainDashboardTarget.innerHTML = ``

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.doSubmenuHtml())
    }).then(() => {

      var html = `<div class="col-2 pr-1" data-users--works--permissions--policies--dashboard-target="subdomains" data-controller="users--works--permissions--subdomains--list"></div>
                  <div class="col-6 pr-1" data-users--works--permissions--policies--dashboard-target="list" data-controller="users--works--permissions--policies--index"></div>
                  <div class="col-4 pl-1" data-users--works--permissions--policies--dashboard-target="view" data-controller="users--works--permissions--policies--save"></div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier(`users--works--permissions--policies--dashboard`).mainDashboardTarget.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).permission = `user_permissions_policies`
        controller.getControllerByIdentifier(`users--works--permissions--policies--save`).permission = `user_permissions_policies`
        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).all_policies = false

        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).doIndexListHtml()
        controller.getControllerByIdentifier(`users--works--permissions--subdomains--list`).doListHtml()

        controller.bySubdomainTarget.classList.remove(`btn-flat-dark`)
        controller.bySubdomainTarget.classList.add(`btn-primary`)
        controller.allPoliciesTarget.classList.add(`btn-flat-dark`)
        controller.allPoliciesTarget.classList.remove(`btn-primary`)
      })
    })

    
  }

  allPolicies(ev) {
    this.getControllerByIdentifier(`users--works--permissions--policies--dashboard`).mainDashboardTarget.innerHTML = ``

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.doSubmenuHtml())
    }).then(() => {
      var html = `<div class="col-12 pr-1" data-users--works--permissions--policies--dashboard-target="list" data-controller="users--works--permissions--policies--index"></div>`
      new Promise(function (resolve) {
        resolve(controller.getControllerByIdentifier(`users--works--permissions--policies--dashboard`).mainDashboardTarget.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).permission = `user_permissions_policies`
        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).all_policies = true
        controller.getControllerByIdentifier(`users--works--permissions--policies--index`).doIndexListHtml()

        controller.bySubdomainTarget.classList.add(`btn-flat-dark`)
        controller.bySubdomainTarget.classList.remove(`btn-primary`)
        controller.allPoliciesTarget.classList.remove(`btn-flat-dark`)
        controller.allPoliciesTarget.classList.add(`btn-primary`)
      })
    })

    
  }

  doSubmenuHtml() {

    var bySubdomainBtn = `<button type="button" data-action="${this.controllerName}#bySubdomain" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn bySubdomain">Por Domínio</button>`
    var allPoliciesBtn = `<button type="button" data-action="${this.controllerName}#allPolicies" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn allPolicies">Todas Políticas</button>`

    var html = `<div class="col-12 mb-3">
                  <div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                    ${bySubdomainBtn}
                    ${allPoliciesBtn}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--permissions--policies--dashboard`).mainDashboardTarget.insertAdjacentHTML("afterbegin", html))
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
