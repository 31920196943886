import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewEmailCard", "viewTitle", "viewEmailCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--emails--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewEmailCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Email Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewEmailCardBody"></div>
                </div>`

    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").viewTarget.innerHTML = html
  }

  doEmailHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-left">
                                ${this.current_email.body_free}
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--stats--trackers--emails--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Email | ${controller.current_email.subject}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
