import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPaymentCard", "viewTitle", "viewPaymentCardBody", "previewCard", "editPaymentBtn", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--payments--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPaymentCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Pagamento Selecionado</h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").viewTarget.innerHTML = html
  }

  editPayment() {

    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").current_payment = this.current_payment
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").actionMode = "edit"
      this.getControllerByIdentifier("operations--products--tax-returns--payments--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }

  }

  doPaymentViewHtml() {

    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.current_payment.id_type == "individual") {
      var idType = `CPF`
    } else if (this.current_payment.id_type == "legal") {
      var idType = `CNPJ`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Rendimento</h6>
                    <div class="card-actions ml-auto py-0">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="tooltip" data-placement="top" data-original-title="Editar Rendimento" data-${this.controllerName}-target="editPaymentBtn" data-action="click->${this.controllerName}#editPayment" type="button"><i class="material-icons f-1p25">edit</i></button>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${this.current_payment.member_kind_pretty}</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.member_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Beneficiário</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.id_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${idType}</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.id_number_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_payment.amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Data</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.date_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Despesa</strong> <br>
                              </th>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${this.current_payment.chart_group_pretty}</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.chart_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Descrição</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.description}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Documento</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.receipt_name}
                              </td>
                              <td colspan="2" class="f-065 align-middle px-0 text-left">
                                <button data-action="click->${this.controllerName}#goToUpload" data-${this.controllerName}-target="uploadUrl" data-url="${this.current_payment.receipt_url}" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  goToUpload(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
