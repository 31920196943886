import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "title", "metric", "landing", "viewFinalCta", "editFinalCta", "8888", "9999",
                    "finalCtaDropdown", "finalCtaDropdownBtn", "finalCtaInput", "finalCtaList",
                    "finalCtaPathDropdown", "finalCtaPathDropdownBtn", "finalCtaPathInput", "finalCtaPathList",
                    "viewFinalCtaPath", "editFinalCtaPath"]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.landing_slug = location.pathname.split("/").pop()
    this.application.permissions = {}
    this.application.conversions = []
    this.application.tickets = []
    this.application.paths = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12" data-${this.controllerName}-target="content"
                                      data-controller="commercial--marketing--landings--tickets--dashboard
                                                       commercial--marketing--landings--show--meeting
                                                       commercial--marketing--landings--show--submenu
                                                       commercial--marketing--landings--show--fields
                                                       commercial--marketing--landings--show--form
                                                       commercial--marketing--landings--show--creative
                                                       commercial--marketing--landings--show--links
                                                       commercial--marketing--landings--conversions--dashboard">
                  show
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDashboardHtml()
      controller.getCurrentUserPermissions()
    })
  }

  publishLanding(ev) {
    this.send_data = { current_user: {}, landing: {} }
    var publish = ev.currentTarget.dataset.publish
    var action = ``
    if (publish == `publish`) {
      this.send_data.landing.published_at = new Date()
      action = `Publicar`
    } else {
      this.send_data.landing.published_at = null
      action = `Despublicar`
    }

    this.send_data.landing.id = this.application.landing.id
    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja ${action} a Landing Page?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/commercial/marketing/landings/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.landing = response.data.cln
          controller.application.field = response.data.cln.field

          controller.setPageTitle()
          controller.doMetricsCards()
          controller.doLandingsCards()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  doMetricsCards() {
    var html = `<div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Visitas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.landing.total_visits)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Interações</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.landing.total_interactions)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Conversões</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-085 f-bold">
                          ${this.getControllerByIdentifier("app--helpers--numbers").numberMask(this.application.landing.conversions_size)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.metricTarget.innerHTML = html
  }

  doLandingsCards() {

    if (this.application.landing.published) {
      var publishedText = `${this.application.landing.published_at_full}`
      if (this.application.landing.finished) {
        var publishedIcon = ``
      } else {
        var publishedIcon = `<span class="mc-tooltip pointer ml-auto" data-publish="unpublish" data-action="click->${this.controllerName}#publishLanding">
                              <span class="material-icons md-sm md-success">check_circle</span>
                              <span class="mc-tooltiptext">Deseja despublicar?</span>
                            </span>`
      }
    } else {
      var publishedText = `<span>Landing Page não publicada</span>`
      if (this.application.landing.finished) {
        var publishedIcon = ``
      } else {
        var publishedIcon = `<span class="mc-tooltip pointer ml-auto" data-publish="publish" data-action="click->${this.controllerName}#publishLanding">
                                <span class="material-icons md-sm md-danger">unpublished</span>
                                <span class="mc-tooltiptext">Deseja publicar?</span>
                              </span>`
      }
    }

    var editCtaPath = `<div class="col-3 text-center f-075 f-bold px-1">
                          <button data-action="click->${this.controllerName}#finalCtaPathEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">edit</span>
                            <span class="mc-tooltiptext">Editar</span>
                          </button>
                        </div>`

    if (this.application.landing.final_cta == `schedule`) {
      var finalCtaPathCard = ``
    } else {
      var finalCtaPathCard = `<div class="card">
                                <div class="card-header p-1 text-center f-070">
                                  <span>CTA Final</span>
                                </div>
                                <div class="card-body text-center px-0 py-2">
                                  <div class="row" data-${this.controllerName}-target="viewFinalCtaPath">
                                    <div class="col-9 text-center f-075 f-bold px-1">
                                      ${this.application.landing.final_cta_path}
                                    </div>
                                    ${editCtaPath}
                                  </div>
                                  <div class="row d-none" data-${this.controllerName}-target="editFinalCtaPath">
                                    <div class="col-12 text-center px-1">
                                      <div class="form-group">
                                        <div class="floating-label floating-label-sm">
                                          <label>CTA</label>
                                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="finalCtaPathDropdown">
                                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="finalCtaPathDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="finalCtaPathInput"></span></button>
                                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                              <ul class="ul-select" data-${this.controllerName}-target="finalCtaPathList" data-app--helpers--search-target="searchList"></ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>`
    }

    var editCta = `<div class="col-3 text-center f-075 f-bold px-1">
                      <button data-action="click->${this.controllerName}#finalCtaEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar</span>
                      </button>
                    </div>`
    var editCta = ``

    var html = `<div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070 d-flex">
                      <span class="ml-auto">Publicação</span>
                      ${publishedIcon}
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          ${publishedText}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Link</span>
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          <p class="mb-0 f-075 mc-tooltip pointer">
                            <span data-controller="app--helpers--copy" data-copy="${this.application.landing.full_path}" data-action="click->app--helpers--copy#copy">Link da Página</span>
                            <span class="mc-tooltiptext">Clique para Copiar</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Tipo CTA</span>
                    </div>
                    <div class="card-body text-center px-0 py-2">
                      <div class="row" data-${this.controllerName}-target="viewFinalCta">
                        <div class="col-12 text-center f-075 f-bold px-1">
                          ${this.application.landing.final_cta_pretty}
                        </div>
                        ${editCta}
                      </div>
                      <div class="row d-none" data-${this.controllerName}-target="editFinalCta">
                        <div class="col-12 text-center px-1">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>CTA</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="finalCtaDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="finalCtaDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="finalCtaInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="finalCtaList" data-app--helpers--search-target="searchList">
                                    <li data-final-cta="schedule" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendamento</li>
                                    <li data-final-cta="download" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Download</li>
                                    <li data-final-cta="calculator" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Calculadora</li>
                                    <li data-final-cta="course" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Curso</li>
                                    <li data-final-cta="lesson" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aula</li>
                                    <li data-final-cta="talk" data-action="click->${this.controllerName}#saveFinalCta click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Conversa</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-0">
                  ${finalCtaPathCard}
                </div>`

    var controller = this
    this.landingTarget.innerHTML = html
    // new Promise(function (resolve) {
    //   resolve(controller.landingTarget.innerHTML = html)
    // }).then(() => {
    //   controller.listCtaPaths()
    // })
  }

  finalCtaEditor() {
    this.viewFinalCtaTarget.classList.add("d-none")
    this.editFinalCtaTarget.classList.remove("d-none")

    this.finalCtaDropdownTarget.value = this.application.landing.final_cta
    this.finalCtaInputTarget.dataset.finalCta = this.application.landing.final_cta
    this.finalCtaInputTarget.innerText = this.application.landing.final_cta_pretty

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  saveFinalCta(ev) {
    this.viewFinalCtaTarget.classList.remove("d-none")
    this.editFinalCtaTarget.classList.add("d-none")

    this.send_data = { current_user: {}, landing: {} }
    var finalCta = ev.currentTarget.dataset.finalCta

    this.send_data.landing.id = this.application.landing.id
    this.send_data.landing.final_cta = finalCta
    this.send_data.landing.final_cta_path = null

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja atualizar o CTA da Landing Page?`)
    if (r) {
      this.requestSave()
    }
  }

  listCtaPaths() {
    var html = ``

    this.application.paths.forEach(element => {
      html += `<li data-path="${element.path}" data-action="click->${this.controllerName}#saveFinalCtaPath click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.path}</li>`
    })

    this.finalCtaPathListTarget.innerHTML = html
  }

  finalCtaPathEditor() {
    this.listCtaPaths()
    this.viewFinalCtaPathTarget.classList.add("d-none")
    this.editFinalCtaPathTarget.classList.remove("d-none")

    this.finalCtaPathDropdownTarget.value = this.application.landing.final_cta_path
    this.finalCtaPathInputTarget.dataset.path = this.application.landing.final_cta_path
    this.finalCtaPathInputTarget.innerText = this.application.landing.final_cta_path

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  saveFinalCtaPath(ev) {
    this.viewFinalCtaPathTarget.classList.remove("d-none")
    this.editFinalCtaPathTarget.classList.add("d-none")

    this.send_data = { current_user: {}, landing: {} }
    var finalCtaPath = ev.currentTarget.dataset.path

    this.send_data.landing.id = this.application.landing.id
    this.send_data.landing.final_cta_path = finalCtaPath

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja atualizar o CTA da Landing Page?`)
    if (r) {
      this.requestSave()
    }
  }

  doDashboardHtml() {
    var html = `<div class="row my-3">
                  <div class="col-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center"><span>Métricas de Inscrição</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="metric">
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center"><span>Landings</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="landing">
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                      <span class="w-100 my-2">${this.bodyCardLoader}</span>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Tickets</span>
                          <span class="f-075 pointer badge badge-secondary ml-auto" data-${this.controllerName}-target="inProcess" data-feature="in_process" data-action="click->${this.controllerName}#toggleTasks">Em Aberto</span>
                          <span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="resolved" data-feature="resolved" data-action="click->${this.controllerName}#toggleTasks">Resolvidos</span>
                          <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#addTask" data-${this.controllerName}-target="addTaskBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Atividade</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listTasks"></div>
                    <div class="row" data-${this.controllerName}-target="listTasks" data-commercial--marketing--landings--show--tickets-target="ticketBoard" data-controller="commercial--marketing--landings--show--tickets">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (this.application.landing) {
        controller.doMetricsCards()
        controller.doLandingsCards()
      }
    })
  }

  goToLive() {
    var url = this.application.landing.live_path
    window.open(url, `_self`)
  }

  addTask() {
    this.getControllerByIdentifier("commercial--marketing--landings--show--tickets").createTask()
  }

  setPageTitle() {
    this.titleTarget.innerText = this.application.landing.name
    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.landing.name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.landing.name
      }, 1000);
    }
  }

  getPaths() {
    var data = { landing: { final_cta: this.application.landing.final_cta }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/landings/entities/list_paths"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.paths = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getConversions() {
    var data = { conversion: { landing_id: this.application.landing.id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/landings/conversions/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.conversions = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTickets() {
    var data = { ticket: { board_id: this.application.landing.id, board_type: "marketing_landings" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/landings/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }
        controller.getPaths()
        controller.getControllerByIdentifier(`commercial--marketing--landings--show--tickets`).doDataTable()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getLandingTeam() {
    const data = { feature: { feature_name: `landings`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.landing_team = response.data.cln
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getLanding() {
    const data = { landing: { slug: this.application.landing_slug }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/landings/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.landing = response.data.cln
        controller.application.field = response.data.cln.field
        controller.setPageTitle()
        controller.doMetricsCards()
        controller.doLandingsCards()
        controller.getConversions()
        controller.getTickets()
        controller.getLandingTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["landings", "landing_tickets", "landing_comments", "landing_conversions" ]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
    .then(response => response.json())
    .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.getLanding()
        controller.getControllerByIdentifier(`commercial--marketing--landings--show--submenu`).doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
