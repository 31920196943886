import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "main", "mainCard", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--index--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doMainDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--clients--index--list"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--clients--index--main").contentTarget.innerHTML = html)
    }).then(() => {
      // controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doClinicsPreloader() {
    var html = `<div class="row">
                  <div class="col-10">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="card" style="width:100%;display:relative;">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--clients--index--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
