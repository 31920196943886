import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "formCard", "formTitle", "formCardBody", "unitDropdown", "unitDropdownBtn", "unitInput", "saveBtn",
                    "unitList", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "projectName",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "kindList", "projectName",
                    "projectDescription", "freezeProjectBtn"]


  connect() {
    this.controllerName = `users--works--trackers--projects--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.doViewHtml()
  }

  saveProject() {
    this.send_data = { current_user: {}, project: {}, squad: {}, note: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `new`) {
      this.send_data.project.status = `in_progress`

      this.send_data.squad.team_id = this.application.current_user.account_id
      this.send_data.squad.team_name = this.application.current_user.name
      this.send_data.squad.role = `owner`

      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_type = `tracker_projects`
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `updating`
      this.send_data.note.date = new Date()
      this.send_data.note.body = this.projectDescriptionTarget.value.trim()
      this.send_data.note.title = `Descrição Projeto`
    } else {
      this.send_data.project.id = this.current_project.id
    }

    this.send_data.project.name = this.projectNameTarget.value.trim()
    this.send_data.project.description = this.projectDescriptionTarget.value.trim()
    this.send_data.project.unit = this.unitInputTarget.dataset.unit
    this.send_data.project.kind = this.kindInputTarget.dataset.kind
    this.send_data.project.status = this.statusInputTarget.dataset.status

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_works_trackers_projects`

    this.requestSave()
  }


  freezeProject() {
    var r = confirm(`Tem certeza que deseja congelar o Projeto?`)

    if (r) {
      this.actionMode = `edit`
      this.send_data = { current_user: {}, project: {}, squad: {}, note: {} }
      this.saveBtnTarget.disabled = true
      this.stopRefreshing()

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `user_works_trackers_projects`
      
      this.send_data.project.id = this.current_project.id
      this.send_data.project.status = `frozen`
      
      var owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_type = `tracker_projects`
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = owner_name
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `updating`
      this.send_data.note.date = new Date()
      this.send_data.note.body = `${owner_name} congelou o projeto`
      this.send_data.note.title = `Congelamento Projeto`
      
      this.requestSave()
    }
  }

  deleteProject(ev) {
    var r = confirm(`Tem certeza que deseja apagar o Projeto?`)
    if (r) {
      this.actionMode = `delete`
      this.send_data = { current_user: {}, project: {}, squad: {}, note: {} }
      this.saveBtnTarget.disabled = true
      this.stopRefreshing()

      this.send_data.project.id = this.current_project.id
      this.send_data.project.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `user_works_trackers_projects`

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/trackers/projects/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/trackers/projects/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/trackers/projects/destroy"
      var method = "DELETE"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var project = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.projects[controller.application.projects.length] = project
          } else if (controller.actionMode == `edit`) {
            controller.application.projects.forEach((element, i) => {
              if (element.id == project.id) {
                controller.application.projects.splice(controller.application.projects.indexOf(element), 1, project)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.projects.forEach((element, i) => {
              if (element.id == project.id) {
                controller.application.projects.splice(controller.application.projects.indexOf(element), 1)
              }
            })
          }
          
          controller.getControllerByIdentifier("users--works--trackers--projects--index").doDataTable()
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.user_works_trackers_projects.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveProject" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions.user_works_trackers_projects.can_manage && this.actionMode == `edit`) {
      var iceBtnHtml = `<span class=" px-3 mc-tooltip ml-auto pointer" data-${this.controllerName}-target="freezeProjectBtn" data-action="click->${this.controllerName}#freezeProject">
                            <span class="material-icons f-1">ac_unit</span>
                            <span class="mc-tooltiptext">Congelar Projeto</span>
                          </span>`
    } else {
      var iceBtnHtml = ``
    }

    if (this.application.permissions.user_works_trackers_projects.can_delete && this.userCanUpdate(this.current_project.id)) {
      var deleteBtnHtml = `<span class=" px-3 mc-tooltip mx-2 pointer" data-${this.controllerName}-target="deleteProjectBtn" data-action="click->${this.controllerName}#deleteProject">
                            <span class="material-icons f-1">delete</span>
                            <span class="mc-tooltiptext">Apagar Projeto</span>
                          </span>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                    ${iceBtnHtml}
                    ${deleteBtnHtml}
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="projectName" type="text" placeholder="Nome" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Descrição</label>
                            <textarea rows="3" autofocus data-${this.controllerName}-target="projectDescription" class="form-control p-1 f-075" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-4 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Área</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="unitDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="unitDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="unitInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="unitList" data-app--helpers--search-target="searchList">
                                  <li data-unit="develop" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Desenvolvimento</li>
                                  <li data-unit="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                  <li data-unit="financial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>
                                  <li data-unit="operations" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Operações</li>
                                  <li data-unit="people" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoas</li>
                                  <li data-unit="strategy" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estratégia</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="implementation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Implantação</li>
                                  <li data-kind="execution" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Execução</li>
                                  <li data-kind="improvement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Melhoria</li>
                                  <li data-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="frozen" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Congelado</li>
                                  <li data-status="in_progress" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Progresso</li>
                                  <li data-status="canceled" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                  <li data-status="finished" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Finalizado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.actionMode == `edit`) {
        controller.projectNameTarget.value = controller.current_project.name
        controller.projectDescriptionTarget.value = controller.current_project.description

        controller.unitDropdownTarget.value = controller.current_project.unit
        controller.unitInputTarget.dataset.unit = controller.current_project.unit
        controller.unitInputTarget.innerText = controller.current_project.unit_pretty

        controller.kindDropdownTarget.value = controller.current_project.kind
        controller.kindInputTarget.dataset.kind = controller.current_project.kind
        controller.kindInputTarget.innerText = controller.current_project.kind_pretty

        controller.statusDropdownTarget.value = controller.current_project.status
        controller.statusInputTarget.dataset.status = controller.current_project.status
        controller.statusInputTarget.innerText = controller.current_project.status_pretty

        controller.formTitleTarget.innerText = `Editando Projeto`
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Projeto`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `in_progress`
        controller.statusInputTarget.dataset.status = `in_progress`
        controller.statusInputTarget.innerText = `Em Progresso`
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewProjectCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Projeto Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewProjectCardBody"></div>
                </div>`

    this.getControllerByIdentifier(`users--works--trackers--projects--dashboard`).viewTarget.innerHTML = html
  }

  userCanUpdate(projectId) {
    var project = {}
    var squad = {}
    this.application.projects.forEach(element => {
      if (element.id == projectId) {
        project = element
      }
    })

    project.squads.forEach(element => {
      if (element.role == `owner`) {
        squad = element
      }
    })

    var allowed = [squad.team_id]
    if (allowed.includes(this.application.current_user.account_id)) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.projectNameTarget.value == ``) {
        len += 1
      }

      if (controller.projectDescriptionTarget.value == ``) {
        len += 1
      }

      if (controller.unitInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
