import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "purchasesCard", "pipelinesCard", "doneCard", "purchasesRow", "pipelinesRow", "donesRow",
                    "calculationsCard", "calculationsRow", "calculationDateRow"]

  connect() {
    this.controllerName = `operations--onboards--reports--main`

    this.calculation = {}
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.setProductStageArray()
  }

  doMainGrid() {
    var html = `<div class="row my-3">
                  <div class="col-1 px-2">Relatórios</div>
                  <div class="col-11 px-2">
                    <div class="row w-100">
                      <div class="col-2 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="purchasesCard" data-feature="purchase" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Contratações x Onboards</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="pipelinesCard" data-feature="pipeline" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Pipeline</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="doneCard" data-feature="done" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Finalizados/Cancelados</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="calculationsCard" data-feature="calculation" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Indicadores</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    <div class="row" data-${this.controllerName}-target="purchasesRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Contratações x Onboards</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="purchasesRow" data-controller="operations--onboards--reports--purchases">
                      <div class="col-12 px-2" data-operations--onboards--reports--purchases-target="purchasesTable">
                        <span class="w-100">${this.loader}</span>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="pipelinesRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Pipeline</span>
                        </h6>
                        <hr class="my-1">
                        </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="pipelinesRow" data-controller="operations--onboards--reports--pipeline">
                      <div class="col-12 px-2" data-operations--onboards--reports--pipeline-target="pipelineTable">
                        <span class="w-100">${this.loader}</span>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="donesRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Finalizados/Cancelados</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="donesRow" data-controller="operations--onboards--reports--dones">
                      <div class="col-12 px-2" data-operations--onboards--reports--dones-target="doneReport">
                        <span class="w-100">${this.loader}</span>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="calculationsRow">
                      
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="calculationsRow" data-controller="operations--onboards--reports--calculations">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span class="mr-5">Indicadores</span>
                          <span class="d-flex" data-operations--onboards--reports--calculations-target="calculationDateRow"></span>
                        </h6>
                        <hr class="my-1">
                      </div>
                      <div class="col-12 px-2" data-operations--onboards--reports--calculations-target="calculationReport">
                        <span class="w-100">${this.loader}</span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--onboards--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--onboards--reports--purchases").doPurchasesReportHTML()
      // controller.getControllerByIdentifier("operations--onboards--reports--pipeline").doPipelineReportHTML()
      // controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      // if (controller.application.onboards) {
      //   controller.doIndexListHtml()
      //   controller.doCalculation()
      // }
    })
  }

  toggleOnboard(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "purchase") {
      this.purchasesCardTarget.classList.add("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "done") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.add("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--dones").doDoneReportHTML()
    } else if (feature == "pipeline") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.add("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--pipeline").doPipelineReportHTML()
    } else if (feature == "calculation") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.add("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--calculations").doCalculationReportHTML()
    }

  }

  setProductStageArray() {
    this.application.product_main = [
                          {
                            name: "medclinic",
                            name_pretty: "PJ Médica",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medclinic_opening_alignment_booking",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medclinic_opening_filling_forms",
                                                     name_pretty: "Formulários da Abertura"
                                                   },
                                                   {
                                                     name: "medclinic_opening_articles_of_association_preparation",
                                                     name_pretty: "Preparação do Contrato Social"
                                                   },
                                                   {
                                                     name: "medclinic_opening_cnpj_approval",
                                                     name_pretty: "Deferimento do CNPJ"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "pos_opening",
                                         name_pretty: "Pós-Abertura",
                                         stages: [
                                                   {
                                                     name: "medclinic_pos_opening_regularization",
                                                     name_pretty: "Regularização"
                                                   },
                                                   {
                                                     name: "medclinic_pos_opening_alignment_taxes",
                                                     name_pretty: "Alinhamento Tributário"
                                                   },
                                                   {
                                                     name: "medclinic_pos_opening_sources_registration",
                                                     name_pretty: "Cadastro nas Fontes"
                                                   },
                                                   {
                                                     name: "medclinic_pos_opening_setting_platform_product",
                                                     name_pretty: "Parametrização Contábil e da Plataforma"
                                                   },
                                                   {
                                                     name: "medclinic_pos_opening_first_invoice_emitted",
                                                     name_pretty: "Emissão da 1ª Nota Fiscal"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "migration",
                                         name_pretty: "Migração",
                                         stages: [
                                                   {
                                                     name: "medclinic_migration_alignment_booking",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medclinic_migration_alignment_service_flow",
                                                     name_pretty: "Alinhamento Fluxo de Serviço"
                                                   },
                                                   {
                                                     name: "medclinic_migration_regularization",
                                                     name_pretty: "Regularização"
                                                   },
                                                   {
                                                     name: "medclinic_migration_setting_platform_product",
                                                     name_pretty: "Parametrização Contábil e da Plataforma"
                                                   },
                                                   {
                                                     name: "medclinic_migration_first_calculation_delivery",
                                                     name_pretty: "Entrega da 1º Apuração"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "amendment",
                                         name_pretty: "Alteração",
                                         stages: [
                                                   {
                                                     name: "medclinic_amendment_alignment_booking",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medclinic_amendment_filling_forms",
                                                     name_pretty: "Formulários da Alteração"
                                                   },
                                                   {
                                                     name: "medclinic_amendment_amendment_preparation",
                                                     name_pretty: "Preparação da Alteração"
                                                   },
                                                   {
                                                     name: "medclinic_amendment_amendment_approval",
                                                     name_pretty: "Deferimento da Alteração"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medclinic_separate_alignment_booking",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medclinic_separate_consultancy",
                                                     name_pretty: "Consultoria"
                                                   },
                                                   {
                                                     name: "medclinic_separate_separate_process_delivery",
                                                     name_pretty: "Entrega do Processo"
                                                   },
                                                 ]
                                       },
                                     ]
                          },
                          {
                            name: "medfiling",
                            name_pretty: "Imposto de Renda",
                            services: [
                                       {
                                         name: "light",
                                         name_pretty: "Light",
                                         stages: [
                                                   {
                                                     name: "medfiling_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medfiling_opening_consultancy",
                                                     name_pretty: "Consultoria IRPF"
                                                   },
                                                   {
                                                     name: "medfiling_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "pro",
                                         name_pretty: "Pro",
                                         stages: [
                                                   {
                                                     name: "medfiling_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medfiling_opening_consultancy",
                                                     name_pretty: "Consultoria IRPF"
                                                   },
                                                   {
                                                     name: "medfiling_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "premium",
                                         name_pretty: "Premium",
                                         stages: [
                                                   {
                                                     name: "medfiling_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medfiling_opening_consultancy",
                                                     name_pretty: "Consultoria IRPF"
                                                   },
                                                   {
                                                     name: "medfiling_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "rectification",
                                         name_pretty: "Retificação",
                                         stages: [
                                                   {
                                                     name: "medfiling_rectification_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medfiling_rectification_consultancy",
                                                     name_pretty: "Consultoria Retificação IRPF"
                                                   },
                                                   {
                                                     name: "medfiling_rectification_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medfiling_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medfiling_separate_consultancy",
                                                     name_pretty: "Consultoria IRPF"
                                                   },
                                                   {
                                                     name: "medfiling_separate_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       },
                                     ]
                          },
                          {
                            name: "medbooking",
                            name_pretty: "Livro-Caixa",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medbooking_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medbooking_opening_consultancy",
                                                     name_pretty: "Consultoria Livro-Caixa"
                                                   },
                                                   {
                                                     name: "medbooking_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                   {
                                                     name: "medbooking_opening_first_calculation_delivery",
                                                     name_pretty: "Entrega da 1º Apuração"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medbooking_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medbooking_separate_service_execution",
                                                     name_pretty: "Execução do Serviço Avulso"
                                                   },
                                                   {
                                                     name: "medbooking_separate_service_delivery",
                                                     name_pretty: "Entrega do Serviço Avulso"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medpayroll",
                            name_pretty: "Funcionários",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medpayroll_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medpayroll_opening_employee_registration",
                                                     name_pretty: "Registro do Funcionário"
                                                   },
                                                   {
                                                     name: "medpayroll_opening_first_payroll_delivery",
                                                     name_pretty: "Entrega da 1ª Folha"
                                                   }
                                                 ]
                                       },
                                       {
                                         name: "migration",
                                         name_pretty: "Migração",
                                         stages: [
                                                   {
                                                     name: "medpayroll_migration_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medpayroll_migration_payroll_migration",
                                                     name_pretty: "Migração da Folha"
                                                   },
                                                   {
                                                     name: "medpayroll_migration_first_payroll_delivery",
                                                     name_pretty: "Entrega da 1ª Folha"
                                                   }
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medpayroll_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medpayroll_separate_service_execution",
                                                     name_pretty: "Execução do Serviço Avulso"
                                                   },
                                                   {
                                                     name: "medpayroll_separate_service_delivery",
                                                     name_pretty: "Entrega do Serviço Avulso"
                                                   }
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medreceivement",
                            name_pretty: "Gestão de Recebimentos",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medreceivement_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medreceivement_opening_consultancy",
                                                     name_pretty: "Consultoria de GR"
                                                   },
                                                   {
                                                     name: "medreceivement_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                   {
                                                     name: "medreceivement_opening_first_calculation_delivery",
                                                     name_pretty: "Entrega do 1º Relatório Recebimento"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medbiling",
                            name_pretty: "Faturamento",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medbiling_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medbiling_opening_consultancy",
                                                     name_pretty: "Consultoria de Faturamento"
                                                   },
                                                   {
                                                     name: "medbiling_opening_setting_parameters",
                                                     name_pretty: "Parametrização"
                                                   },
                                                   {
                                                     name: "medbiling_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                   {
                                                     name: "medbiling_opening_first_calculation_delivery",
                                                     name_pretty: "Entrega do 1º Relatório Repasse"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medconsulting",
                            name_pretty: "Consultoria",
                            services: [
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medconsulting_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medconsulting_separate_service_execution",
                                                     name_pretty: "Execução do Serviço Avulso"
                                                   },
                                                   {
                                                     name: "medconsulting_separate_service_delivery",
                                                     name_pretty: "Entrega do Serviço Avulso"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medreturn",
                            name_pretty: "Planner",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medreturn_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medreturn_opening_consultancy",
                                                     name_pretty: "Consultoria Planner"
                                                   },
                                                   {
                                                     name: "medreturn_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medloan",
                            name_pretty: "Crédito",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medloan_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medloan_opening_service_execution",
                                                     name_pretty: "Alinhamento do Serviço"
                                                   },
                                                   {
                                                     name: "medloan_opening_service_delivery",
                                                     name_pretty: "Pagamento 1º Boleto"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medoutsourcing",
                            name_pretty: "Terceirização",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medoutsourcing_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medoutsourcing_opening_service_execution",
                                                     name_pretty: "Alinhamento do Serviço"
                                                   },
                                                   {
                                                     name: "medoutsourcing_opening_service_delivery",
                                                     name_pretty: "Entrega do Serviço"
                                                   },
                                                 ]
                                       }
                                     ]
                          },
                          {
                            name: "medoncall",
                            name_pretty: "Gestão de Plantões",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medoncall_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medoncall_opening_consultancy",
                                                     name_pretty: "Consultoria de Gestão de Plantões"
                                                   },
                                                   {
                                                     name: "medoncall_opening_setting_platform_account",
                                                     name_pretty: "Liberação da Plataforma"
                                                   },
                                                   {
                                                     name: "medoncall_opening_first_calculation_delivery",
                                                     name_pretty: "Entrega do 1º Relatório de Plantões"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medoncall_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medoncall_separate_service_execution",
                                                     name_pretty: "Execução do Serviço Avulso"
                                                   },
                                                   {
                                                     name: "medoncall_separate_service_delivery",
                                                     name_pretty: "Entrega do Serviço Avulso"
                                                   },
                                                 ]
                                       },
                                     ]
                          },
                          {
                            name: "medcontent",
                            name_pretty: "Mídias Sociais",
                            services: [
                                       {
                                         name: "opening",
                                         name_pretty: "Abertura",
                                         stages: [
                                                   {
                                                     name: "medcontent_opening_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medcontent_opening_consultancy",
                                                     name_pretty: "Consultoria de Mídias Sociais"
                                                   },
                                                   {
                                                     name: "medcontent_opening_social_midia_accounts",
                                                     name_pretty: "Configuração Redes Sociais"
                                                   },
                                                   {
                                                     name: "medcontent_opening_first_social_midia_planning_delivery",
                                                     name_pretty: "Entrega do 1º Planejamento Mensal"
                                                   },
                                                 ]
                                       },
                                       {
                                         name: "separate",
                                         name_pretty: "Avulso",
                                         stages: [
                                                   {
                                                     name: "medcontent_separate_initial_alignment",
                                                     name_pretty: "Alinhamento Inicial"
                                                   },
                                                   {
                                                     name: "medcontent_separate_service_execution",
                                                     name_pretty: "Execução do Serviço Avulso"
                                                   },
                                                   {
                                                     name: "medcontent_separate_service_delivery",
                                                     name_pretty: "Entrega do Serviço Avulso"
                                                   },
                                                 ]
                                       },
                                     ]
                          },
                        ]
  }

  doSidebarHtml(controller) {
    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medclinic" data-action="click->${controller}#productFilter" data-${controller}-target="medclinicCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medclinicHeaderCard">
                        <span>PJ Médica</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medreturn" data-action="click->${controller}#productFilter" data-${controller}-target="medreturnCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medreturnHeaderCard">
                        <span>Planner</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medbooking" data-action="click->${controller}#productFilter" data-${controller}-target="medbookingCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medbookingHeaderCard">
                        <span>Livro-Caixa</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medfiling" data-action="click->${controller}#productFilter" data-${controller}-target="medfilingCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medfilingHeaderCard">
                        <span>Imposto de Renda</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medpayroll" data-action="click->${controller}#productFilter" data-${controller}-target="medpayrollCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medpayrollHeaderCard">
                        <span>Funcionários</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medreceivement" data-action="click->${controller}#productFilter" data-${controller}-target="medreceivementCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medreceivementHeaderCard">
                        <span>Gestão Recebimentos</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medoncall" data-action="click->${controller}#productFilter" data-${controller}-target="medoncallCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medoncallHeaderCard">
                        <span>Gestão de Plantões</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medbiling" data-action="click->${controller}#productFilter" data-${controller}-target="medbilingCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medbilingHeaderCard">
                        <span>Faturamento</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medoutsourcing" data-action="click->${controller}#productFilter" data-${controller}-target="medoutsourcingCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medoutsourcingHeaderCard">
                        <span>Terceirização</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medloan" data-action="click->${controller}#productFilter" data-${controller}-target="medloanCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medloanHeaderCard">
                        <span>Crédito</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medcontent" data-action="click->${controller}#productFilter" data-${controller}-target="medcontentCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medcontentHeaderCard">
                        <span>Mídias Sociais</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card pointer" data-product="medconsulting" data-action="click->${controller}#productFilter" data-${controller}-target="medconsultingCard">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${controller}-target="medconsultingHeaderCard">
                        <span>Consultoria</span>
                      </div>
                    </div>
                  </div>
                </div>`


    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
