import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "journey", "call", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--recruitments--schedules--dashboard`
    this.blockedAdd = false
  }

  doSchedulesDashboard() {
    var html = `<div class="col-12" data-users--works--schedules--entities--list-target="main" data-controller="users--works--schedules--entities--list"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).schedulesRowTarget.innerHTML = html)
    }).then(() => {

      var accountList = []
      var accountOwner = {}
      var guestList = []
      controller.application.squads.forEach(element => {
        accountList[accountList.length] = { id: element.team_id, name: element.team_name }

        if (element.role == `owner`) {
          accountOwner = { id: element.team_id, name: element.team_name }
        }
      })

      controller.application.applicants.forEach(element => {
        guestList[guestList.length] = { id: element.id, name: element.name }
      })

      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).controllerForm = controller
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).permission = `user_works_recruitments_schedules`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).recordId = this.application.vacancy.id
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).recordType = `recruitment_vacancies`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).refreshMode = `record`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountList = accountList
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountId = accountOwner.id
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountName = accountOwner.name
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestList = guestList
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestType = `recruitment_applicants`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
