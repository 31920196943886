
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    // this.doSubmenuHtml()
    this.controllerName = `operations--products--tax-filings--entities--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--tax-filings--entities--dashboard").reconnect()
  }

  goToUploads() {
    this.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").doUploadsDashboard()
  }

  goToDocuments() {
    this.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").doDocumentsDashboard()
  }

  goToJourney() {
    this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").doJourneyDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--tax-filings--notes--dashboard").doNotesDashboard()
  }

  goToProcess() {
    this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").doProcessDashboard()
  }

  goToSetttings() {
    this.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").doSettingsDashboard()
  }

  goToChat() {
    this.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").doChatDashboard()
  }

  goToTicket() {
    this.getControllerByIdentifier("operations--products--tax-filings--tickets--dashboard").doTicketsDashboard()
  }


  doSubmenuHtml() {

    var chatLink = `<span data-action="click->${this.controllerName}#goToChat" class="dropdown-item py-1 pointer dropdown-submenu-item">Suporte</span>`

    var dashboardLink = `<span data-action="click->${this.controllerName}#goToDashboard" class="dropdown-item py-1 pointer dropdown-submenu-item">Dashboard</span>`

    if (this.application.permissions.medfiling_files.can_upload) {
      var uploadLink = `<span data-action="click->${this.controllerName}#goToUploads" class="dropdown-item py-1 pointer dropdown-submenu-item">Uploads</span>`
    } else {
      var uploadLink = ``
    }

    if (this.application.permissions.medfiling_files.can_index) {
      var processLink = `<span data-action="click->${this.controllerName}#goToProcess" class="dropdown-item py-1 pointer dropdown-submenu-item">Processamento</span>`
    } else {
      var processLink = ``
    }

    if (this.application.permissions.medfiling_files.can_index) {
      var fileLink = `<span data-action="click->${this.controllerName}#goToDocuments" class="dropdown-item py-1 pointer dropdown-submenu-item">Documentos</span>`
    } else {
      var fileLink = ``
    }

    if (this.application.permissions.medfiling_notes.can_index) {
      var notesLink = `<span data-action="click->${this.controllerName}#goToNotes" class="dropdown-item py-1 pointer dropdown-submenu-item">Anotações</span>`
    } else {
      var notesLink = ``
    }

    if (this.application.permissions.medfiling_journeys.can_index) {
      var journeyLink = `<span data-action="click->${this.controllerName}#goToJourney" class="dropdown-item py-1 pointer dropdown-submenu-item">Jornada</span>`
    } else {
      var journeyLink = ``
    }

    if (this.application.permissions.medfiling_tickets.can_index) {
      var ticketLink = `<span data-action="click->${this.controllerName}#goToTicket" class="dropdown-item py-1 pointer dropdown-submenu-item">Tickets</span>`
    } else {
      var ticketLink = ``
    }

    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var settingsLink = `<span data-action="click->${this.controllerName}#goToSetttings" class="dropdown-item py-1 pointer dropdown-submenu-item">Configurações</span>`
    } else {
      var settingsLink = ``
    }

    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      // `${chatLink}`

      var submenuBody = `<div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${dashboardLink}
                          ${processLink}
                          ${fileLink}
                          ${uploadLink}
                          ${journeyLink}
                          ${ticketLink}
                          ${notesLink}
                          ${settingsLink}
                          
                        </div>`
    } else if (this.application.current_user.account_kind == "doctor") {
      var submenuBody = `<div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${dashboardLink}
                          ${uploadLink}
                          ${journeyLink}
                          ${notesLink}
                          
                        </div>`
    }

    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        ${submenuBody}
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  cleanSubmenu() {
    this.mainTarget.innerHTML = ``
    this.doSubmenuHtml()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
