import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `users--works--recruitments--applicants--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 8
    this.sort = { mode: `desc`, field: `score` }
    this.hasShow = false
  }

  addApplicant() {
    this.showClearBtn()

    this.getControllerByIdentifier("users--works--recruitments--applicants--save").actionMode = "new"
    this.getControllerByIdentifier("users--works--recruitments--applicants--save").current_applicant = {}
    this.getControllerByIdentifier("users--works--recruitments--applicants--save").doFormHtml()
  }


  editApplicant(ev) {
    var applicantId = ev.target.closest(".itemRow").dataset.id
    var current_applicant = {}

    this.application.applicants.forEach(element => {
      if (element.id == applicantId) {
        current_applicant = element
      }
    })

    this.showClearBtn()

    this.getControllerByIdentifier("users--works--recruitments--applicants--save").actionMode = "edit"
    this.getControllerByIdentifier("users--works--recruitments--applicants--save").current_applicant = current_applicant
    this.getControllerByIdentifier("users--works--recruitments--applicants--save").doFormHtml()
  }

  showApplicant(ev) {
    var slug = ev.currentTarget.closest(".itemRow").dataset.slug
    window.open(`/a/recrutamento-selecao/${slug}`, `_blank`)
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addApplicant" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Candidato</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshApplicants" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Candidatos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Candidatos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Relevância
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="score" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="score" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Telefone</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Email</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                Cidade
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="city" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="city" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                                Expectativa
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="compensation_claim" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="compensation_claim" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Posição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                                Jornada
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="journey_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                                Fonte
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="source" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="source" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--recruitments--applicants--dashboard").tableRowTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_applicants = this.application.applicants

    if (this.sort.mode == `asc`) {
      var applicants = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_applicants, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var applicants = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_applicants, this.sort.field)
    }

    if (applicants.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(applicants.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (applicants[x] !== undefined) {
            internPage[internPage.length] = applicants[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: applicants.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Candidatos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.applicantTablePartial(element, data.length))
      });
    }
  }

  applicantTablePartial(element, length) {

    var scoreHtml = ``
    for (let index = 0; index < element.score; index++) {
      scoreHtml += `<span class="material-icons md-sm md-dark">grade</span>`
    }

    if (element.status == `active`) {
      var status = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">${element.status_pretty}</span></span>`
    } else if (element.status == `inactive`) {
      var status = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">${element.status_pretty}</span></span>`
    }

    if (this.application.current_user.super_admin) {
      var editBtn = `<button data-action="click->${this.controllerName}#editApplicant" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">edit</span>
                      <span class="mc-tooltiptext">Copiar Transação</span>
                    </button>`
    } else {
      var editBtn = ``
    }

    var linksBtn = `<button data-action="click->${this.controllerName}#showLinks" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">share</span>
                      <span class="mc-tooltiptext">Links Externos</span>
                    </button>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    // if (length == 1) {
    //   var tableRow = `<tr class="itemRow" data-action="mouseover->${this.controllerName}#showPopover mouseout->${this.controllerName}#hidePopover" data-id="${element.id}" data-slug="${element.slug}" style="height:50px;">`
    // } else {
    //   var tableRow = `<tr class="itemRow" data-action="mouseover->${this.controllerName}#showPopover mouseout->${this.controllerName}#hidePopover" data-id="${element.id}" data-slug="${element.slug}">`
    // }

    // `<td style="font-size:60%;" scope="col" class="p-1 align-middle pointer">
    //   <p class="mb-0 f-065 mc-tooltip">
    //     <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
    //     <span class="mc-tooltiptext">Clique para Copiar</span>
    //   </p>
    // </td>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editApplicant">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editApplicant">${scoreHtml}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#phoneAction">${element.full_phone_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="p-0 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.email}" data-action="click->app--helpers--copy#copy">${element.email}</span>
                        <span class="mc-tooltiptext">Copiar E-mail</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.city}/${element.state}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.controllerNumber.currencyOptionMask(element.compensation_claim)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.position_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.journey_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.source}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${linksBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  showPopover(ev) {
    var element = ev.currentTarget
    var applicantId = element.dataset.id

    element.dataset.toggle = `popover`
    element.dataset.trigger = `hover`
    element.dataset.content = `Hover over me ID ${applicantId}`

    new Promise(function (resolve) {
      resolve(element.dataset.placement = `top`)
    }).then(() => {
      $('[data-toggle="popover"]').popover()
    })


    console.log(applicantId)


  }

  hidePopover(ev) {
    var element = ev.currentTarget

    element.dataset.toggle = ``
    element.dataset.trigger = ``
    element.dataset.content = ``
    element.dataset.placement = ``
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("users--works--recruitments--applicants--save").stopRefreshing()
    this.doDataTable()
  }

  showLinks(ev) {
    var modal = `users--works--recruitments--applicants--links-modal`

    var currentApplicant = {}
    var applicantId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.application.applicants.forEach(element => {
      if (element.id == applicantId) {
        currentApplicant = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-ssm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).current_applicant = currentApplicant
      controller.getControllerByIdentifier(modal).open()
    })
  }

  finishRefresh() {
    var response = { type: `success`, message: `Candidatos atualizados com sucesso!` }

    this.doDataTable()
    this.getControllerByIdentifier("users--works--recruitments--applicants--pipeline--flow").doFlowHtml()
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getApplicants() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Candidatos`, value: 50 })

    var data = { applicant: { vacancy_id: this.application.vacancy.id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_applicants` } }
    const url = "/users/works/recruitments/applicants/list_by_vacancy"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.applicants = response.data.cln
        controller.finishRefresh()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshApplicants() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Atualizando Candidatos` })

    this.getApplicants()
  }

  phoneAction(ev) {
    var applicantId = ev.target.closest(".itemRow").dataset.id
    var current_applicant = {}

    this.application.applicants.forEach(element => {
      if (element.id == applicantId) {
        current_applicant = element
      }
    })

    var modal = `users--works--recruitments--applicants--phone-action`
    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).current_applicant = current_applicant
      controller.getControllerByIdentifier(modal).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
