import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "cardBody", "footerTable", "checkboxShow", "addTransferBtn", "9999",
                    "dropdownNewDate", "dropdownNewDateMenu", "yearDropdown", "yearInput", "yearList", "monthDropdown", "monthInput", "monthList", "saveBtn"]





  connect() {
    this.controllerName = `operations--products--clinics--managements--dates--index`
    this.tableCols = 4
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `status_pretty` }
    this.hasShow = false
    this.doIndexListHtml()
  }

  newDate() {
    this.actionMode = "new"
    var date = new Date();
    var newMonth = this.monthInputTarget.dataset.month
    var newYear = this.yearInputTarget.dataset.year
    var saveMode = true
    var message = ``
    if (this.application.dates.length == 0) {
      saveMode = true
    } else {
      this.application.dates.forEach(element => {
        if (element.month.toString() == newMonth && element.year.toString() == newYear) {
          saveMode = false
          message = `A Competência ${this.getControllerByIdentifier("app--helpers--date").mountDate(element.month, element.year)} já existe.`
        }
      });
    }

    var data = {
      product: { id: this.application.clinic.id },
      date: { open: true, month: newMonth, year: newYear },
      current_user: { current_user_id: this.application.current_user.id }
    }

    this.send_data = { product: {}, date: {}, current_user: {} }

    this.send_data.product.id = this.application.clinic.id

    this.send_data.date.open = true
    this.send_data.date.month = newMonth
    this.send_data.date.year = newYear

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_dates`

    if (saveMode) {
      this.requestSave()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 2000)
    }
  }

  changeDateOpen(ev) {
    var id = ev.target.closest(".row").dataset.id
    if (ev.target.checked) {
      this.nameTarget(`dateStatus-${id}`).innerText = `Aberto`
    } else {
      this.nameTarget(`dateStatus-${id}`).innerText = `Fechado`
    }
    this.nameTarget(`saveBtn-${id}`).classList.remove("d-none")
  }

  editDate(ev) {
    this.actionMode = "edit"
    var dateId = ev.target.closest(".row").dataset.id
    this.nameTarget(`saveBtn-${dateId}`).classList.add("d-none")

    // var data = {
    //   product: { id: this.application.clinic.id },
    //   date: { id: id, open: this.nameTarget(`dateOpen-${dateId}`).checked },
    //   current_user: { current_user_id: this.application.current_user.id }
    // }

    this.send_data = { product: {}, date: {}, current_user: {} }

    this.send_data.product.id = this.application.clinic.id
    this.send_data.date.id = dateId
    this.send_data.date.open = this.nameTarget(`dateOpen-${dateId}`).checked

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_dates`

    this.requestSave()
  }

  requestSave() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    if (this.actionMode == "edit") {
      var url = "/operations/products/clinics/managements/dates/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/clinics/managements/dates/create"
      var method = "POST"
    }

    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var date = response.data.cln
          if (this.actionMode == "edit") {
            if (date.active) {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1, date)
                }
              })
              if (this.application.current_date.id == date.id) {
                this.application.current_date = date
                if (this.application.current_date.open == false) {
                  this.application.current_date.message = `A Competência está fechado`
                }
              }
            } else {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1)
                }
              })
              if (this.application.current_date.id == date.id) {
                this.application.current_date = {}
              }
            }
          } else if (this.actionMode == "new") {
            controller.application.dates[this.application.dates.length] = date
            controller.closeNewDate()
            window.location.reload()
          }
          this.listData()
        } else {
          // this.nameTarget(`saveBtn-${data.date.id}`).classList.remove("d-none")
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  dropdownNewDate() {
    this.dropdownNewDateTarget.classList.add("show")
    this.dropdownNewDateMenuTarget.classList.add("show")
    this.listYear()
  }

  closeNewDate() {
    this.dropdownNewDateTarget.classList.remove("show")
    this.dropdownNewDateMenuTarget.classList.remove("show")
    this.yearListTarget.innerHTML = ``
    this.monthListTarget.innerHTML = ``

    this.yearInputTarget.innerText = ``
    this.monthInputTarget.innerText = ``

    this.yearDropdownTarget.parentElement.classList.remove("has-value")
    this.monthDropdownTarget.parentElement.classList.remove("has-value")
  }

  listYear() {
    var date = new Date();
    var currentYear = date.getFullYear();

    var listHtml = ``
    var all_years = [currentYear, currentYear - 1, currentYear - 2]
    all_years.forEach(element => {
      listHtml += `<li data-year="${element}" data-app--helpers--selector-target="select" data-action="click->app--helpers--selector#select click->${this.controllerName}#listMonth" class="li-selector dark">${element}</li>`
    });
    this.yearListTarget.innerHTML = listHtml
  }

  listMonth(ev) {
    var date = new Date();
    var currentYear = date.getFullYear();
    var currentMonth = date.getMonth();
    var selectYear = ev.target.dataset.year

    var all_months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    var listHtml = ``
    if (currentYear == selectYear) {
      for (let index = 0; index <= currentMonth; index++) {
        listHtml += `<li data-month="${index + 1}" data-app--helpers--selector-target="select" data-action="click->app--helpers--selector#select click->${this.controllerName}#refreshSaveBtn" class="li-selector dark">${all_months[index]}</li>`
      }
    } else {
      for (let index = 0; index < 12; index++) {
        listHtml += `<li data-month="${index + 1}" data-app--helpers--selector-target="select" data-action="click->app--helpers--selector#select click->${this.controllerName}#refreshSaveBtn" class="li-selector dark">${all_months[index]}</li>`
      }
    }

    this.monthListTarget.innerHTML = listHtml
  }

  closeAllDates() {
    var r = confirm(`Tem certeza que deseja fechar todas as Competências?`)
    if (r) {
      this.send_data = { current_user: {}, product: {} }

      this.send_data.product.id = this.application.clinic.id

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `medclinic_management_dates`

      var url = "/operations/products/clinics/managements/dates/close_all_dates"
      var method = "PUT"
      
      var controller = this
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.dates = response.data.cln
          this.listData()
          this.setCurrentDate()
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
    }
  }

  setCurrentDate() {
    this.application.dates.forEach(element => {
      if (element.id == this.application.current_date.id) {
        this.application.current_date = element
      }
    });
  }

  doIndexListHtml() {

    if (this.application.permissions.medclinic_management_dates.can_update) {
      var closeAll = `<div class="mc-tooltip ml-auto">
                      <button id="createDateBtn" data-action="click->${this.controllerName}#closeAllDates" aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0">
                        <span class="material-icons">event_busy</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar todas as Competências</span>
                    </div>`
    } else {
      var closeAll = ``
    }
    


    if (this.application.permissions.medclinic_management_dates.can_create) {
      var createBtn = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="dropdownNewDate">
                        <button id="createDateBtn" data-action="click->${this.controllerName}#dropdownNewDate" aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0">
                          <span class="material-icons">add</span>
                        </button>
                        <span class="mc-tooltiptext">Adicionar nova Competência</span>
                        <div aria-labelledby="createDateBtn" class="dropdown-menu dropdown-menu-right menu" data-${this.controllerName}-target="dropdownNewDateMenu">
                          <span class="default dropdown-item py-1">Nova Competência</span>
                          <div class="row my-2">
                            <div class="col px-2">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label>Ano de Referência</label>
                                  <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="yearDropdown">
                                    <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="yearInput"></span></button>
                                    <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                      <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                      <ul class="ul-select" data-${this.controllerName}-target="yearList"></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col px-2">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label>Mês de Referência</label>
                                  <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="monthDropdown">
                                    <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="monthInput"></span></button>
                                    <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                      <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                      <ul class="ul-select" data-${this.controllerName}-target="monthList"></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col px-2 text-center d-flex">
                              <button disabled type="button" class="btn btn-primary btn-sm btn-table py-2 f-065" data-action="click->${this.controllerName}#newDate" data-${this.controllerName}-target="saveBtn" data-mode="new">Criar</button>
                              <button type="button" class="close ml-auto f-065" data-dismiss="alert" data-action="click->${this.controllerName}#closeNewDate" aria-label="Close">
                                <span class="text-left f-065">Fechar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>`
    } else {
      var createBtn = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Competências</h6>
                    ${closeAll}
                    ${createBtn}
                  </div>
                  <div class="card-body py-0 px-1" data-${this.controllerName}-target="cardBody" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--dates--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.listData()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  listData() {
    var dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.dates, "year")
    this.cardBodyTarget.innerHTML = ""
    if (dates == undefined || dates == [] || dates.length == 0) {
      var noData = `<div class="row d-flex align-items-center">
                      <div class="col-12 my-3 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <h5 class="mb-0 f-065">Não existem Competências</h5>
                      </div>
                    </div>`

      this.cardBodyTarget.innerHTML = noData
    } else {
      dates.forEach(element => {
        this.cardBodyTarget.insertAdjacentHTML("beforeend", this.datePartial(element))
      });
    }
  }

  datePartial(element) {
    if (element.open) {
      if (this.application.permissions.medclinic_management_dates.can_update) {
        var inputHtml = `<input class="custom-control-input" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      } else {
        var inputHtml = `<input disabled class="custom-control-input" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      }
      var status = `Aberto`
    } else {
      if (this.application.permissions.medclinic_management_dates.can_update) {
        var inputHtml = `<input class="custom-control-input" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      } else {
        var inputHtml = `<input disabled class="custom-control-input" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      }
      var status = `Fechado`
    }

    if (this.application.permissions.medclinic_management_dates.can_delete) {
      var deleteBtn = `<button data-action="click->${this.controllerName}#deleteDate" data-${this.controllerName}-target="deleteBtn-${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">delete</span>
                        <span class="mc-tooltiptext">Apagar</span>
                      </button>`
    } else {
      var deleteBtn = ``
    }
    var deleteBtn = ``
    // <span>${this.getControllerByIdentifier("app--helpers--date").mountDate(element.month, element.year)}</span>
    var html = `<div class="row d-flex align-items-center" data-id="${element.id}">
                  <div class="col-2 my-3 px-1 f-065">
                    <span>${element.date_pretty}</span>
                  </div>
                  <div class="col-6 my-3 d-flex align-items-center">
                    <div class="custom-control custom-switch d-flex align-items-center">
                      ${inputHtml}
                      <span class="custom-control-track"></span>
                      <label class="custom-control-label" for="switchDateOpen-${element.id}"></label>
                    </div>
                    <span class="f-065" data-${this.controllerName}-target="dateStatus-${element.id}">${status}</span>
                  </div>
                  <div class="col-2 my-3 px-1 text-right">
                    <button data-action="click->${this.controllerName}#editDate" data-${this.controllerName}-target="saveBtn-${element.id}" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                      <span class="material-icons md-sm md-dark">save</span>
                      <span class="mc-tooltiptext">Salvar</span>
                    </button>
                  </div>
                  <div class="col-2 my-3 px-1 text-right">
                    ${deleteBtn}
                  </div>
                </div>`

    return html
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.monthInputTarget.innerText == "") {
        len += 1
      }
      if (controller.yearInputTarget.innerText == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
