import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "accounts", "list", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--cash-flows--compilations--dashboard`
  }

  doCashFlowDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 px-0" data-${this.controllerName}-target="list" data-controller="financials--books--cash-flows--compilations--index"></div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("financials--books--cash-flows--compilations--index").doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
