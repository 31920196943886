import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hourlyTitle", "hourlyBoard", "callsTitle", "callsBoard", "callersTitle", "bodyTable", "callersBoard", "callerCard", "allCard"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `app--communications--voips--dashboard--compilation`
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `hour` }
  }

  doCompilationCard() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Relatório Ligações - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`
                  
      this.callsTitleTarget.innerHTML = title
    }

    this.calculation = {}
    this.calculation.totalCalls = 0
    this.calculation.normalClearing = 0
    this.calculation.originatorCancel = 0
    this.calculation.numberChanged = 0
    this.calculation.recoveryOnTimerExpire = 0
    this.calculation.exchangeRoutingError = 0
    this.calculation.unallocatedNumber = 0

    this.current_calls = []
    
    if (this.application.current_caller_id) {
      this.application.calls.forEach(element => {
        if (element.caller_id == this.application.current_caller_id) {
          this.current_calls[this.current_calls.length] = element
        }
      })
    } else {
      this.current_calls = this.application.calls
    }

    this.current_calls.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.calculation.totalCalls += 1
        if (element.hangup_cause == `NORMAL_CLEARING`) {
          this.calculation.normalClearing += 1
        } else if (element.hangup_cause == `ORIGINATOR_CANCEL`) {
          this.calculation.originatorCancel += 1
        } else if (element.hangup_cause == `NUMBER_CHANGED`) {
          this.calculation.numberChanged += 1
        } else if (element.hangup_cause == `RECOVERY_ON_TIMER_EXPIRE`) {
          this.calculation.recoveryOnTimerExpire += 1
        } else if (element.hangup_cause == `EXCHANGE_ROUTING_ERROR`) {
          this.calculation.exchangeRoutingError += 1
        } else if (element.hangup_cause == `UNALLOCATED_NUMBER`) {
          this.calculation.unallocatedNumber += 1
        }
      }
    })

    var html = `<div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Total de Ligações</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.totalCalls)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.totalCalls * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Atendidas</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.normalClearing)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.normalClearing * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4"></div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Motivos Desligamentos</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Cancelamento da Ligação</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.originatorCancel)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.originatorCancel * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Caixa Postal</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.numberChanged)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.numberChanged * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Tempo Expirado</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.recoveryOnTimerExpire)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.recoveryOnTimerExpire * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Erro de Conexão</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.exchangeRoutingError)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.exchangeRoutingError * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Número Não Encontrado</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          <strong>${this.numberController.numberMask(this.calculation.unallocatedNumber)}</strong>
                          <small>${this.numberController.percentOptionMask((this.calculation.unallocatedNumber * 100 / this.calculation.totalCalls))}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.callsBoardTarget.innerHTML = html
  }

  doHourlyCard() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Atendimentos por Horário - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`

      this.hourlyTitleTarget.innerHTML = title
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Ligações Atendidas por Horário</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle text-center">Horário</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-50 align-middle text-center">Ligações Atendidas</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    this.hourlyBoardTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.hourlyBoardTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_report = []

    this.application.clearing_report.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        // this.current_report[this.current_report.length] = element
        this.current_report = element.data
      }
    })

    if (this.sort.mode == `asc`) {
      var calls = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_report, `hour`)
    } else if (this.sort.mode == `desc`) {
      var calls = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_report, `hour`)
    }

    if (calls.length == 0) {
      this.listData()
    } else {
      this.listData(calls)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Ligações Atendidas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.callTablePartial(element, data.length))
      })
    }
  }

  callTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.hour}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.calls}</td>
                  </tr>`

    return rowHtml
  }

  refreshHourly() {
    this.getControllerByIdentifier(`app--communications--voips--dashboard--main`).refreshCalls(this.permission, `hourly_table`)
  }

  refreshCalls() {
    this.getControllerByIdentifier(`app--communications--voips--dashboard--main`).refreshCalls(this.permission, `compilation_table`)
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
