import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `users--works--recruitments--vacancies--index--main`
    this.account_kind = this.application.current_user.account_kind
    this.application.permissions = {}
    this.application.vacancies = []
    this.application.meds = []

    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--recruitments--vacancies--index--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    this.getControllerByIdentifier(`users--works--recruitments--vacancies--index--dashboard`).doVacanciesDashboard()
    this.getControllerByIdentifier(`users--works--recruitments--vacancies--index--submenu`).doSubmenuHtml()

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  // getNotes() {
  //   this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 95 })
  //   
  //   var data = { note: { domain_ids: this.application.vacancy_ids, domain_type: `marketing_vacancys` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_notes` } }
  //   const url = "/users/accounts/notes/list_check_by_domains"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.process) {
  //         controller.application.notes = response.data.cln
  //       }
  //       controller.finishLoadPage()
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
  //     })
  // }

  // getEventTeam() {
  //   this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time`, value: 60 })
  //   
  //   const data = { current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
  //   const url = "/commercial/config/teams/list_event_team"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       controller.application.team_list = response.data.cln
  //       controller.getNotes()
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
  //     })
  // }

  getVacancies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Vagas`, value: 75 })

    const data = { vacancy: { active: true, vacancy_ids: this.application.vacancy_ids }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_vacancies` } }
    const url = "/users/works/recruitments/vacancies/list_by_ids"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.vacancies = response.data.cln
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squads`, value: 40 })

    const data = { squad: { team_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_squads` } }
    const url = "/users/works/recruitments/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.squads = response.data.cln
          controller.application.vacancy_ids = controller.mapElements(controller.application.squads, `vacancy_id`)
          controller.getVacancies()
        } else {
          controller.application.vacancys = []
          controller.finishLoadPage()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 30 })

    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_vacancies` } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }

        controller.getSquads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_recruitments` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`user_works_recruitments_vacancies`]

        controller.getMeds()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }
  
  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}