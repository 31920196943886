import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "save", "filters", "header", "subheader", "squad", "saveBtn", "9999",
    "squadEdit", "squadInput", "squadFilter", "cancelBtn", "roleDropdown", "roleDropdownBtn", "roleInput", "roleList", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--squads--show`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()
    this.squadPreloader()
  }

  doSquadsHeader() {
    if (this.application.permissions.commercial_event_squads.can_create) {
      var title = `<span class="ml-auto">Squad</span>
                    <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addSquad" data-${this.controllerName}-target="addSquadBtn">
                      <span class="material-icons md-sm md-dark">add</span>
                      <span class="mc-tooltiptext">Adicionar Time</span>
                    </span>`
    } else {
      var title = `<span class="mx-auto">Squad</span>`
    }

    this.titleTarget.innerHTML = title
  }

  addSquad() {
    this.stopRefreshing()

    var html = `<div class="col-12 px-1 d-flex align-items-center" data-${this.controllerName}-target="squadEdit">
                  <div class="form-group w-100 mb-3">
                    <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="squadInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#squadFilter keyup->${this.controllerName}#squadFilter keyup->${this.controllerName}#enterSquad" type="text" placeholder="Time" required>
                    <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="squadFilter"></ul>
                  </div>
                </div>
                <div class="col-12 px-1 d-flex align-items-center" data-${this.controllerName}-target="squadEdit">
                  <div class="form-group w-100">
                    <div class="floating-label floating-label-sm">
                      <label>Função</label>
                      <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                        <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                        <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                          <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                          <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                            <li data-role="editor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Editor</li>
                            <li data-role="viewer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Visualizar</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 px-1 d-flex align-items-center mb-4" data-${this.controllerName}-target="squadEdit">
                  <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#closeEdit">Fechar</button>
                  <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#createSquad" data-${this.controllerName}-target="saveBtn">Salvar</button>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.insertAdjacentHTML("afterbegin", html))
    }).then(() => {
      controller.refreshSaveBtn()
      controller.listSquad()
    })
  }

  closeEdit() {
    this.squadEditTargets.forEach(element => {
      element.remove()
    })
  }

  listSquad() {
    var html = ``
    this.application.team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectSquad" data-${this.controllerName}-target="squadFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${element.nickname}</li>`
    })

    this.squadFilterTarget.innerHTML = html
  }

  squadFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectSquad(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterSquad(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  createSquad() {
    this.actionMode = `new`
    this.stopRefreshing()
    // controller.saveBtnTarget.disabled = true
    this.send_data = { current_user: {}, squad: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_squads`

    this.send_data.squad.event_id = this.application.event.id
    this.send_data.squad.team_id = this.squadInputTarget.dataset.filter
    this.send_data.squad.team_name = this.squadInputTarget.dataset.text
    this.send_data.squad.role = this.roleInputTarget.dataset.role

    this.requestSave()
  }

  removeTeam(ev) {
    this.actionMode = `delete`
    var squadId = ev.currentTarget.closest(".chipSquad").dataset.id

    this.send_data = { current_user: {}, squad: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `commercial_event_squads`

    this.send_data.squad.id = squadId
    this.send_data.squad.active = false

    var r = confirm(`Tem certeza que deseja retirar o participante?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/events/squads/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/events/squads/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/commercial/marketing/events/squads/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var squad = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.squads[controller.application.squads.length] = squad
          } else if (controller.actionMode == `edit`) {
            controller.application.squads.forEach((element, i) => {
              if (element.id == squad.id) {
                controller.application.squads.splice(controller.application.squads.indexOf(element), 1, squad)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.squads.forEach((element, i) => {
              if (element.id == squad.id) {
                controller.application.squads.splice(controller.application.squads.indexOf(element), 1)
              }
            })
          }

          controller.closeEdit()
          controller.doSquadsHtml()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doSquadsHtml() {
    var current_squads = []
    if (this.hasFilter) {
      this.application.squads.forEach(element => {
        if (element.stage == this.filter) {
          current_squads[current_squads.length] = element
        }
      })
    } else {
      current_squads = this.application.squads
    }

    var squads = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_squads, `role_enum`)

    if (squads == undefined || squads == [] || squads.length == 0) {
      var noData = `<div class="col-12 my-5 text-center">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-075 align-middle">Não há Time</span>
                    </div>`

      this.mainTarget.innerHTML = noData
    } else {
      this.mainTarget.innerHTML = ``

      squads.forEach(element => {
        this.mainTarget.insertAdjacentHTML("beforeend", this.squadPartial(element))
      });
    }
  }

  squadPartial(squad) {

    // `data-target="#chipDismissible-${squad.id}"`
    // `id="chipDismissible-${squad.id}"`

    if (this.application.permissions.commercial_event_squads.can_delete && squad.role != `owner` && this.application.current_squad.role == `owner`) {
      var removeBtnHtml = `<button class="close mc-tooltip" data-dismiss="alert" type="button">
                              <span class="material-icons md-150" data-id="${squad.id}" data-action="click->${this.controllerName}#removeTeam">cancel</span>
                              <span class="mc-tooltiptext">Remover do Projeto</span>
                            </button>`
    } else {
      var removeBtnHtml = ``
    }


    var html = `<div class="col-12 text-center">
                  <div class="chip fade show mr-2 mb-2 chipSquad" data-id="${squad.id}">
                    <span class="mc-tooltip">
                      <span class="material-icons mr-1 default">face</span>
                      <span class="mr-1 default">${this.getControllerByIdentifier("app--visits--users").getInitials(squad.team_name)}</span>
                      <span class="mc-tooltiptext">${squad.team_name}</span>
                    </span>
                    ${removeBtnHtml}
                  </div>
                </div>`

    return html
  }

  squadPreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.squadInputTarget.value == ``) {
        len += 1
      }
      if (controller.roleInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
