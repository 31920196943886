import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "rgNumberDiv", "rgNumberInputDiv", "rgNumberInput", "editBtn", "cancelBtn", "emailKindDropdown", "emailKindDropdownBtn",
                    "emailKindInput", "emailKindList", "emailMainCheckbox", "emailForm", "cancelEmailBtn", "rgStateDiv", "rgStateDropdown",
                    "rgStateDropdownBtn", "rgStateInput", "rgStateList", "saveBtn", "rgStateInputDiv", "rgStateFilter",
                    "rgIssueDateDiv", "rgIssueDateInputDiv", "rgIssueDateInput", "rgIssueDate",
                    "rgProviderDiv", "rgProviderInputDiv", "rgProviderInput", "rgProvider",
                    "pisNumberDiv", "pisNumberInputDiv", "pisNumberInput", "pisNumber",
                    "ctpsNumberDiv", "ctpsNumberInputDiv", "ctpsNumberInput", "ctpsNumber",
                    "nameOfMotherDiv", "nameOfMotherInputDiv", "nameOfMotherInput", "nameOfMother",
                    "nameOfFatherDiv", "nameOfFatherInputDiv", "nameOfFatherInput", "nameOfFather",
                    "stateOfBirthDiv", "stateOfBirthInputDiv", "stateOfBirthDropdown", "stateOfBirthDropdownBtn", "stateOfBirthInput", "stateOfBirthList", "stateOfBirthFilter",
                    "cityOfBirthDiv", "cityOfBirthInputDiv", "cityOfBirthDropdown", "cityOfBirthDropdownBtn", "cityOfBirthInput", "cityOfBirthList", "cityOfBirthFilter",
                    "occupationDiv", "occupationInputDiv", "occupationInput", "occupation",
                    "sourceDiv", "sourceInputDiv", "sourceInput", "source",
                    "maritalStatusDiv", "maritalStatusInputDiv", "maritalStatusDropdown", "maritalStatusDropdownBtn", "maritalStatusInput", "maritalStatusList", "maritalStatus"]


  connect() {
    this.controllerName = `operations--products--bookings--patients--info`
    this.send_data = { current_user: {}, account: {}, document: {} }
    this.feature = "medbooking_patient_contacts"
    this.setCurrentInfo()
    this.doInfoHtml()
    this.refreshPatientInfo()
  }

  disconnect() {
    if (this.refreshInfoTimer) {
      clearInterval(this.refreshInfoTimer)
    }
  }

  editInfo(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    // this.refreshSaveBtn()

    if (ev.currentTarget.closest("td").dataset) {
      this.field = ev.currentTarget.closest("td").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }

    if (this.field == "rgNumber") {
      this.rgNumberDivTarget.classList.add("d-none")
      this.rgNumberInputDivTarget.classList.remove("d-none")
      this.rgNumberInputTarget.value = this.rgNumberDivTarget.innerText.trim()
      this.rgNumberInputTarget.focus()
    } else if (this.field == "rgState") {
      this.rgStateDivTarget.classList.add("d-none")
      this.rgStateInputDivTarget.classList.remove("d-none")
      this.rgStateInputTarget.value = this.rgStateDivTarget.innerText.trim()
      this.rgStateInputTarget.focus()
    } else if (this.field == "rgIssueDate") {
      this.rgIssueDateDivTarget.classList.add("d-none")
      this.rgIssueDateInputDivTarget.classList.remove("d-none")
      this.rgIssueDateInputTarget.value = this.rgIssueDateDivTarget.innerText.trim()
      this.rgIssueDateInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.rgIssueDateInputTarget), { max: true, months: true, years: 15 })
    } else if (this.field == "rgProvider") {
      this.rgProviderDivTarget.classList.add("d-none")
      this.rgProviderInputDivTarget.classList.remove("d-none")
      this.rgProviderInputTarget.value = this.rgProviderDivTarget.innerText.trim()
      this.rgProviderInputTarget.focus()
    } else if (this.field == "pisNumber") {
      this.pisNumberDivTarget.classList.add("d-none")
      this.pisNumberInputDivTarget.classList.remove("d-none")
      this.pisNumberInputTarget.value = this.pisNumberDivTarget.innerText.trim()
      this.pisNumberInputTarget.focus()
    } else if (this.field == "ctpsNumber") {
      this.ctpsNumberDivTarget.classList.add("d-none")
      this.ctpsNumberInputDivTarget.classList.remove("d-none")
      this.ctpsNumberInputTarget.value = this.ctpsNumberDivTarget.innerText.trim()
      this.ctpsNumberInputTarget.focus()
    } else if (this.field == "nameOfMother") {
      this.nameOfMotherDivTarget.classList.add("d-none")
      this.nameOfMotherInputDivTarget.classList.remove("d-none")
      this.nameOfMotherInputTarget.value = this.nameOfMotherDivTarget.innerText.trim()
      this.nameOfMotherInputTarget.focus()
    } else if (this.field == "nameOfFather") {
      this.nameOfFatherDivTarget.classList.add("d-none")
      this.nameOfFatherInputDivTarget.classList.remove("d-none")
      this.nameOfFatherInputTarget.value = this.nameOfFatherDivTarget.innerText.trim()
      this.nameOfFatherInputTarget.focus()
    } else if (this.field == "placeOfBirth") {
      this.stateOfBirthDivTarget.classList.add("d-none")
      this.stateOfBirthInputDivTarget.classList.remove("d-none")
      this.stateOfBirthInputTarget.value = this.stateOfBirthDivTarget.innerText.trim()
      this.stateOfBirthInputTarget.focus()
      
      
      this.cityOfBirthDivTarget.classList.add("d-none")
      this.cityOfBirthInputDivTarget.classList.remove("d-none")
      this.cityOfBirthInputTarget.value = this.cityOfBirthDivTarget.innerText.trim()
      this.cityOfBirthInputTarget.focus()
    } else if (this.field == "occupation") {
      this.occupationDivTarget.classList.add("d-none")
      this.occupationInputDivTarget.classList.remove("d-none")
      this.occupationInputTarget.value = this.occupationDivTarget.innerText.trim()
      this.occupationInputTarget.focus()
    } else if (this.field == "source") {
      this.sourceDivTarget.classList.add("d-none")
      this.sourceInputDivTarget.classList.remove("d-none")
      this.sourceInputTarget.value = this.sourceDivTarget.innerText.trim()
      this.sourceInputTarget.focus()
    } else if (this.field == "maritalStatus") {
      this.maritalStatusDivTarget.classList.add("d-none")
      this.maritalStatusInputDivTarget.classList.remove("d-none")
      this.maritalStatusInputTarget.innerText = this.maritalStatusDivTarget.innerText.trim()
      this.maritalStatusInputTarget.focus()
    }

    


  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "rgNumber") {
      this.rgNumberDivTarget.classList.remove("d-none")
      this.rgNumberInputDivTarget.classList.add("d-none")
      this.rgNumberDivTarget.innerText = this.current_info.rg_number
    } else if (this.field == "rgState") {
      this.rgStateDivTarget.classList.remove("d-none")
      this.rgStateInputDivTarget.classList.add("d-none")
      this.rgStateDivTarget.innerText = this.current_info.rg_state
    } else if (this.field == "rgIssueDate") {
      this.rgIssueDateDivTarget.classList.remove("d-none")
      this.rgIssueDateInputDivTarget.classList.add("d-none")
      this.rgIssueDateDivTarget.innerText = this.current_info.rg_issue_date
    } else if (this.field == "rgProvider") {
      this.rgProviderDivTarget.classList.remove("d-none")
      this.rgProviderInputDivTarget.classList.add("d-none")
      this.rgProviderDivTarget.innerText = this.current_info.rg_provider
    } else if (this.field == "pisNumber") {
      this.pisNumberDivTarget.classList.remove("d-none")
      this.pisNumberInputDivTarget.classList.add("d-none")
      this.pisNumberDivTarget.innerText = this.current_info.pis_number
    } else if (this.field == "ctpsNumber") {
      this.ctpsNumberDivTarget.classList.remove("d-none")
      this.ctpsNumberInputDivTarget.classList.add("d-none")
      this.ctpsNumberDivTarget.innerText = this.current_info.ctps_number
    } else if (this.field == "nameOfMother") {
      this.nameOfMotherDivTarget.classList.remove("d-none")
      this.nameOfMotherInputDivTarget.classList.add("d-none")
      this.nameOfMotherDivTarget.innerText = this.current_info.name_of_mother
    } else if (this.field == "nameOfFather") {
      this.nameOfFatherDivTarget.classList.remove("d-none")
      this.nameOfFatherInputDivTarget.classList.add("d-none")
      this.nameOfFatherDivTarget.innerText = this.current_info.name_of_mother
    } else if (this.field == "placeOfBirth") {
      this.stateOfBirthDivTarget.classList.remove("d-none")
      this.stateOfBirthInputDivTarget.classList.add("d-none")
      this.stateOfBirthDivTarget.innerText = this.current_info.state_of_birth

      this.cityOfBirthDivTarget.classList.remove("d-none")
      this.cityOfBirthInputDivTarget.classList.add("d-none")
      this.cityOfBirthDivTarget.innerText = this.current_info.city_of_birth
    } else if (this.field == "occupation") {
      this.occupationDivTarget.classList.remove("d-none")
      this.occupationInputDivTarget.classList.add("d-none")
      this.occupationDivTarget.innerText = this.current_info.occupation
    } else if (this.field == "source") {
      this.sourceDivTarget.classList.remove("d-none")
      this.sourceInputDivTarget.classList.add("d-none")
      this.sourceDivTarget.innerText = this.current_info.source
    } else if (this.field == "maritalStatus") {
      this.maritalStatusDivTarget.classList.remove("d-none")
      this.maritalStatusInputDivTarget.classList.add("d-none")
      this.maritalStatusDivTarget.innerText = this.current_info.marital_status
    }
    
  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.rgNumberDivTarget.classList.contains("d-none")) {
      this.rgNumberDivTarget.classList.remove("d-none")
      this.rgNumberInputDivTarget.classList.add("d-none")
      this.rgNumberDivTarget.innerText = this.rgNumberInputTarget.value
    }

    if (this.rgStateDivTarget.classList.contains("d-none")) {
      this.rgStateDivTarget.classList.remove("d-none")
      this.rgStateInputDivTarget.classList.add("d-none")
      this.rgStateDivTarget.innerText = this.rgStateInputTarget.value
    }

    if (this.rgIssueDateDivTarget.classList.contains("d-none")) {
      this.rgIssueDateDivTarget.classList.remove("d-none")
      this.rgIssueDateInputDivTarget.classList.add("d-none")
      this.rgIssueDateDivTarget.innerText = this.rgIssueDateInputTarget.value
    }

    if (this.rgProviderDivTarget.classList.contains("d-none")) {
      this.rgProviderDivTarget.classList.remove("d-none")
      this.rgProviderInputDivTarget.classList.add("d-none")
      this.rgProviderDivTarget.innerText = this.rgProviderInputTarget.value
    }

    if (this.pisNumberDivTarget.classList.contains("d-none")) {
      this.pisNumberDivTarget.classList.remove("d-none")
      this.pisNumberInputDivTarget.classList.add("d-none")
      this.pisNumberDivTarget.innerText = this.pisNumberInputTarget.value
    }

    if (this.ctpsNumberDivTarget.classList.contains("d-none")) {
      this.ctpsNumberDivTarget.classList.remove("d-none")
      this.ctpsNumberInputDivTarget.classList.add("d-none")
      this.ctpsNumberDivTarget.innerText = this.ctpsNumberInputTarget.value
    }

    if (this.nameOfMotherDivTarget.classList.contains("d-none")) {
      this.nameOfMotherDivTarget.classList.remove("d-none")
      this.nameOfMotherInputDivTarget.classList.add("d-none")
      this.nameOfMotherDivTarget.innerText = this.nameOfMotherInputTarget.value
    }

    if (this.nameOfFatherDivTarget.classList.contains("d-none")) {
      this.nameOfFatherDivTarget.classList.remove("d-none")
      this.nameOfFatherInputDivTarget.classList.add("d-none")
      this.nameOfFatherDivTarget.innerText = this.nameOfFatherInputTarget.value
    }

    if (this.stateOfBirthDivTarget.classList.contains("d-none")) {
      this.stateOfBirthDivTarget.classList.remove("d-none")
      this.stateOfBirthInputDivTarget.classList.add("d-none")
      this.stateOfBirthDivTarget.innerText = this.stateOfBirthInputTarget.value
      
      this.cityOfBirthDivTarget.classList.remove("d-none")
      this.cityOfBirthInputDivTarget.classList.add("d-none")
      this.cityOfBirthDivTarget.innerText = this.cityOfBirthInputTarget.value
    }

    if (this.occupationDivTarget.classList.contains("d-none")) {
      this.occupationDivTarget.classList.remove("d-none")
      this.occupationInputDivTarget.classList.add("d-none")
      this.occupationDivTarget.innerText = this.occupationInputTarget.value
    }

    if (this.sourceDivTarget.classList.contains("d-none")) {
      this.sourceDivTarget.classList.remove("d-none")
      this.sourceInputDivTarget.classList.add("d-none")
      this.sourceDivTarget.innerText = this.sourceInputTarget.value
    }

    if (this.maritalStatusDivTarget.classList.contains("d-none")) {
      this.maritalStatusDivTarget.classList.remove("d-none")
      this.maritalStatusInputDivTarget.classList.add("d-none")
      this.maritalStatusDivTarget.innerText = this.maritalStatusInputTarget.innerText
    }

    
  }

  saveInfo() {

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.current_user.feature = controller.feature
      controller.send_data.account.account_id = this.application.current_patient.account_id

      controller.send_data.document.id = controller.current_info.id
      // controller.send_data.document.elector_number = controller.electorNumberDivTarget.innerText.trim()
      controller.send_data.document.pis_number = controller.pisNumberDivTarget.innerText.trim()
      controller.send_data.document.ctps_number = controller.ctpsNumberDivTarget.innerText.trim()
      controller.send_data.document.rg_number = controller.rgNumberDivTarget.innerText.trim()
      controller.send_data.document.rg_state = controller.rgStateDivTarget.innerText.trim()
      controller.send_data.document.rg_issue_date = controller.getControllerByIdentifier("app--helpers--date").transformFullDate(controller.rgIssueDateDivTarget.innerText.trim())
      controller.send_data.document.rg_provider = controller.rgProviderDivTarget.innerText.trim()
      controller.send_data.document.city_of_birth = controller.cityOfBirthDivTarget.innerText.trim()
      controller.send_data.document.state_of_birth = controller.stateOfBirthDivTarget.innerText.trim()
      controller.send_data.document.name_of_mother = controller.nameOfMotherDivTarget.innerText.trim()
      controller.send_data.document.name_of_father = controller.nameOfFatherDivTarget.innerText.trim()
      controller.send_data.document.occupation = controller.occupationDivTarget.innerText.trim()
      controller.send_data.document.source = controller.sourceDivTarget.innerText.trim()
      controller.send_data.document.marital_status = controller.maritalStatusInputTarget.dataset.maritalStatus
      // controller.send_data.document.notes = controller.notesDivTarget.innerText.trim()

      
      controller.stopRefreshing()
      controller.saveBtnTarget.disabled = true
      
      controller.requestSave()
    })
    .catch(error => {
      controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
    })
  }

  requestSave() {
    var url = "/users/accounts/documents/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.current_info = response.data.cln
          controller.doInfoHtml()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doInfoHtml() {
    if (this.application.permissions.medbooking_patient_infos.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions.medbooking_patient_infos.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editInfo" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                      <span aria-hidden="true">&times;</span>
                      <span class="mc-tooltiptext">Cancelar</span>
                    </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    if (this.current_info.rg_issue_date_pretty == null) { this.current_info.rg_issue_date_pretty = `` }
    if (this.current_info.state_of_birth == null) { this.current_info.state_of_birth = `` }
    if (this.current_info.city_of_birth == null) { this.current_info.city_of_birth = `` }
    if (this.current_info.occupation == null) { this.current_info.occupation = `` }
    if (this.current_info.source == null) { this.current_info.source = `` }
    if (this.current_info.marital_status_pretty == null) { this.current_info.marital_status_pretty = `` }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                        </tr>
                        
                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Número RG</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="rgNumberDiv">
                              ${this.current_info.rg_number}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="rgNumberInputDiv">
                              <input data-${this.controllerName}-target="rgNumberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="rgNumber">
                            ${edit}
                            ${cancel}
                          </td>

                          <th colspan="2" class="f-065 align-middle px-0"><strong>Estado RG</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="rgStateDiv">
                              ${this.current_info.rg_state}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="rgStateInputDiv">
                              <input class="form-control p-1 s-title-0p6rem" data-filter-mode="simple" data-${this.controllerName}-target="rgStateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                              <ul class="ul-filter filter-list d-none" style="z-index:1" data-${this.controllerName}-target="rgStateFilter">
                                <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MG</li>
                                <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SP</li>
                                <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AC</li>
                                <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AL</li>
                                <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AP</li>
                                <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">AM</li>
                                <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">BA</li>
                                <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">CE</li>
                                <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">DF</li>
                                <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">ES</li>
                                <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">GO</li>
                                <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MA</li>
                                <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MT</li>
                                <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">MS</li>
                                <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PA</li>
                                <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PB</li>
                                <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PR</li>
                                <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PE</li>
                                <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">PI</li>
                                <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RJ</li>
                                <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RN</li>
                                <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RS</li>
                                <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RO</li>
                                <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">RR</li>
                                <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SC</li>
                                <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">SE</li>
                                <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState" class="pl-1 pr-2 pointer f-065 dark">TO</li>
                              </ul>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="rgState">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Data RG</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="rgIssueDateDiv">
                              ${this.current_info.rg_issue_date_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="rgIssueDateInputDiv">
                              <input data-${this.controllerName}-target="rgIssueDateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="rgIssueDate">
                            ${edit}
                            ${cancel}
                          </td>

                          <th colspan="2" class="f-065 align-middle px-0"><strong>Órgão RG</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="rgProviderDiv">
                              ${this.current_info.rg_provider}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="rgProviderInputDiv">
                              <input data-${this.controllerName}-target="rgProviderInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="rgProvider">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Naturalidade</strong></th>
                          <td colspan="1" class="f-065 align-middle px-1 text-right">
                            <div data-${this.controllerName}-target="stateOfBirthDiv">
                              ${this.current_info.state_of_birth}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="stateOfBirthInputDiv">
                              <input class="form-control p-1 s-title-0p6rem" data-filter-mode="simple" data-${this.controllerName}-target="stateOfBirthInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                              <ul class="ul-filter filter-list d-none" style="z-index:1" data-${this.controllerName}-target="stateOfBirthFilter">
                                <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">MG</li>
                                <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">SP</li>
                                <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">AC</li>
                                <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">AL</li>
                                <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">AP</li>
                                <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">AM</li>
                                <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">BA</li>
                                <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">CE</li>
                                <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">DF</li>
                                <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">ES</li>
                                <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">GO</li>
                                <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">MA</li>
                                <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">MT</li>
                                <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">MS</li>
                                <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">PA</li>
                                <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">PB</li>
                                <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">PR</li>
                                <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">PE</li>
                                <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">PI</li>
                                <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">RJ</li>
                                <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">RN</li>
                                <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">RS</li>
                                <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">RO</li>
                                <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">RR</li>
                                <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">SC</li>
                                <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">SE</li>
                                <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="pl-1 pr-2 pointer f-065 dark">TO</li>
                              </ul>
                            </div>
                          </td>
                          <td colspan="6" class="f-065 align-middle px-1 text-left">
                            <div data-${this.controllerName}-target="cityOfBirthDiv">
                              ${this.current_info.city_of_birth}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="cityOfBirthInputDiv">
                              <input class="form-control p-1 s-title-0p6rem" data-filter-mode="simple" data-${this.controllerName}-target="cityOfBirthInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                              <ul class="ul-filter filter-list d-none w-50" style="z-index:1" data-${this.controllerName}-target="cityOfBirthFilter"></ul>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="placeOfBirth">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Nome Mãe</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="nameOfMotherDiv">
                              ${this.current_info.name_of_mother}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameOfMotherInputDiv">
                              <input data-${this.controllerName}-target="nameOfMotherInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="nameOfMother">
                            ${edit}
                            ${cancel}
                          </td>

                          <th colspan="2" class="f-065 align-middle px-0"><strong>Nome Pai</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="nameOfFatherDiv">
                              ${this.current_info.name_of_father}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameOfFatherInputDiv">
                              <input data-${this.controllerName}-target="nameOfFatherInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="nameOfFather">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Ocupação</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="occupationDiv">
                              ${this.current_info.occupation}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="occupationInputDiv">
                              <input data-${this.controllerName}-target="occupationInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="occupation">
                            ${edit}
                            ${cancel}
                          </td>

                          <th colspan="2" class="f-065 align-middle px-0"><strong>Indicação</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="sourceDiv">
                              ${this.current_info.source}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="sourceInputDiv">
                              <input data-${this.controllerName}-target="sourceInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="source">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>Estado Civil</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="maritalStatusDiv">
                              ${this.current_info.marital_status_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="maritalStatusInputDiv">
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="maritalStatusDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="maritalStatusDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="maritalStatusInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="maritalStatusList" data-app--helpers--search-target="searchList">
                                    <li data-marital-status="single" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">Solteiro(a)</li>
                                    <li data-marital-status="cohabitation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">União Estável</li>
                                    <li data-marital-status="married" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">Casado(a)</li>
                                    <li data-marital-status="divorced" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">Divorciado(a)</li>
                                    <li data-marital-status="separated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">Separado(a)</li>
                                    <li data-marital-status="widow" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">Viúvo(a)</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="maritalStatus">
                            ${edit}
                            ${cancel}
                          </td>
                          <td colspan="5" class="f-065 align-middle text-center"></td>
                        </tr>

                        <tr>
                          <th colspan="2" class="f-065 align-middle px-0"><strong>PIS</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="pisNumberDiv">
                              ${this.current_info.pis_number}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="pisNumberInputDiv">
                              <input data-${this.controllerName}-target="pisNumberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="pisNumber">
                            ${edit}
                            ${cancel}
                          </td>

                          <th colspan="2" class="f-065 align-middle px-0"><strong>CTPS</strong></th>
                          <td colspan="2" class="f-065 align-middle px-0">
                            <div data-${this.controllerName}-target="ctpsNumberDiv">
                              ${this.current_info.ctps_number}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="ctpsNumberInputDiv">
                              <input data-${this.controllerName}-target="ctpsNumberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle text-center" data-field="ctpsNumber">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        
                        <tr>
                          <td colspan="10" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveInfo" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      if (controller.current_info.marital_status) { controller.maritalStatusInputTarget.dataset.maritalstatus = controller.current_info.marital_status }
    })
  }

  refreshPatientInfo() {
    var controller = this
    this.refreshInfoTimer = setInterval(function () {
      controller.application.current_patient.info = controller.current_info
    }, 200);
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="pl-3 pr-5 pointer f-065 dark">${element}</li>`
          });
          this.cityOfBirthFilterTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setCurrentInfo() {
    this.current_info = this.application.current_patient.info

    if (this.current_info.rg_number == null) { this.current_info.rg_number = `` }
    if (this.current_info.rg_state == null) { this.current_info.rg_state = `` }
    if (this.current_info.rg_issue_date_pretty == null) { this.current_info.rg_issue_date_pretty = `` }
    if (this.current_info.rg_provider == null) { this.current_info.rg_provider = `` }
    if (this.current_info.state_of_birth == null) { this.current_info.state_of_birth = `` }
    if (this.current_info.city_of_birth == null) { this.current_info.city_of_birth = `` }
    if (this.current_info.name_of_mother == null) { this.current_info.name_of_mother = `` }
    if (this.current_info.name_of_father == null) { this.current_info.name_of_father = `` }
    if (this.current_info.occupation == null) { this.current_info.occupation = `` }
    if (this.current_info.source == null) { this.current_info.source = `` }
    if (this.current_info.marital_status_pretty == null) { this.current_info.marital_status_pretty = `` }
    if (this.current_info.pis_number == null) { this.current_info.pis_number = `` }
    if (this.current_info.ctps_number == null) { this.current_info.ctps_number = `` }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })




  // `<div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="rgStateDropdown">
  //   <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="rgStateDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="rgStateInput"></span></button>
  //   <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
  //     <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
  //     <ul class="ul-select" data-${this.controllerName}-target="rgStateList" data-app--helpers--search-target="searchList">
  //       <li data-state="MG" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MG</li>
  //       <li data-state="SP" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SP</li>
  //       <li data-state="AC" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AC</li>
  //       <li data-state="AL" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AL</li>
  //       <li data-state="AP" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AP</li>
  //       <li data-state="AM" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">AM</li>
  //       <li data-state="BA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">BA</li>
  //       <li data-state="CE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">CE</li>
  //       <li data-state="DF" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">DF</li>
  //       <li data-state="ES" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">ES</li>
  //       <li data-state="GO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">GO</li>
  //       <li data-state="MA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MA</li>
  //       <li data-state="MT" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MT</li>
  //       <li data-state="MS" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">MS</li>
  //       <li data-state="PA" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PA</li>
  //       <li data-state="PB" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PB</li>
  //       <li data-state="PR" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PR</li>
  //       <li data-state="PE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PE</li>
  //       <li data-state="PI" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">PI</li>
  //       <li data-state="RJ" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RJ</li>
  //       <li data-state="RN" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RN</li>
  //       <li data-state="RS" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RS</li>
  //       <li data-state="RO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RO</li>
  //       <li data-state="RR" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">RR</li>
  //       <li data-state="SC" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SC</li>
  //       <li data-state="SE" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">SE</li>
  //       <li data-state="TO" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark">TO</li>
  //     </ul>
  //   </div>
  // </div>`

  // `<div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="stateOfBirthDropdown">
  //   <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="stateOfBirthDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="stateOfBirthInput"></span></button>
  //   <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
  //     <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
  //     <ul class="ul-select" data-${this.controllerName}-target="stateOfBirthList" data-app--helpers--search-target="searchList">
  //       <li data-state="MG" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MG</li>
  //       <li data-state="SP" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SP</li>
  //       <li data-state="AC" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AC</li>
  //       <li data-state="AL" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AL</li>
  //       <li data-state="AP" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AP</li>
  //       <li data-state="AM" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">AM</li>
  //       <li data-state="BA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">BA</li>
  //       <li data-state="CE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">CE</li>
  //       <li data-state="DF" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">DF</li>
  //       <li data-state="ES" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">ES</li>
  //       <li data-state="GO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">GO</li>
  //       <li data-state="MA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MA</li>
  //       <li data-state="MT" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MT</li>
  //       <li data-state="MS" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">MS</li>
  //       <li data-state="PA" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PA</li>
  //       <li data-state="PB" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PB</li>
  //       <li data-state="PR" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PR</li>
  //       <li data-state="PE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PE</li>
  //       <li data-state="PI" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">PI</li>
  //       <li data-state="RJ" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RJ</li>
  //       <li data-state="RN" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RN</li>
  //       <li data-state="RS" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RS</li>
  //       <li data-state="RO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RO</li>
  //       <li data-state="RR" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">RR</li>
  //       <li data-state="SC" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SC</li>
  //       <li data-state="SE" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">SE</li>
  //       <li data-state="TO" data-action="click->app--helpers--selector#select click->${this.controllerName}#getCities" data-app--helpers--selector-target="select" class="li-selector dark">TO</li>
  //     </ul>
  //   </div>
  // </div>`

  // `<div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="cityOfBirthDropdown">
  //   <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="cityOfBirthDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="cityOfBirthInput"></span></button>
  //   <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
  //     <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
  //     <ul class="ul-select" data-${this.controllerName}-target="cityOfBirthList" data-app--helpers--search-target="searchList"></ul>
  //   </div>
  // </div>`

}
