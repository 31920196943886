import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "table", "content", "main", "mainCard", "teamList", "team", "teamDropdown", "teamInput", "index", "indicators"]

  connect() {
    this.controllerName = `financials--books--clients--radars--main`
    this.application.account_id = this.application.current_user.account_id
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.all_team = false
    this.application.tickets = []
    this.application.permissions = []
    this.application.radars = []
    this.application.contracts = []
    this.application.renewals = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--books--clients--radars--dashboard
                                                            financials--books--clients--radars--indicators
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doRadarLoader()
    })
  }

  doRadarGrid() {

    var html = `<div class="row" data-${this.controllerName}-target="indicators"></div>
                <div class="row">
                  <div class="col-12 px-0" data-${this.controllerName}-target="table"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--clients--radars--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`financials--books--clients--radars--indicators`).doIndicatorsCalculation()
      controller.getControllerByIdentifier(`financials--books--clients--radars--list`).allRadar()
    })
  }

  doRadarLoader() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var indicatorsHtml = `<div class="row" data-${this.controllerName}-target="indicators">
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                          </div>`

    indicatorsHtml = ``
    var html = `${indicatorsHtml}
                <div class="row">
                  <div class="col-12 px-0 my-2" data-${this.controllerName}-target="table">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)

    // this.getControllerByIdentifier(`financials--books--clients--radars--indicators`).doIndicatorsCalculation()
    this.getControllerByIdentifier(`financials--books--clients--radars--dashboard`).doRadarDashboard()
    this.getControllerByIdentifier(`financials--books--clients--radars--submenu`).doSubmenuHtml()
  }

  getCurrentFinancialDate() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competência`, value: 85 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/financials/config/dates/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.current_date = response.data.cln
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRadars() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Radar de Renovações`, value: 75 })

    const data = { radar: { active: true, status: `active` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_radar_main` } }
    const url = "/financials/books/clients/radars/list_by_status"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.radars = response.data.cln
        controller.getCurrentFinancialDate()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClientTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Clientes`, value: 60 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_radar_main` } }
    const url = "/financials/config/teams/list_client_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team_list = response.data.cln
        controller.getRadars()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClients() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Clientes`, value: 55 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_radar_clients` } }
    const url = "/financials/books/clients/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clients = response.data.cln
        }
        controller.getClientTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_client_radars` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_radar_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getClients()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
