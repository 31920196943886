import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["vacancyStatus", "vacancyKind", "vacancyPosition", "vacancyRole", "vacancyStartedAt",
                    "vacancyOpenDays", "vacancyMed", "vacancyBudget", "vacancyApplicants", "vacancyExpenses",
                    "vacancyApplicants", "vacancyCompensation", "asdf", "asdf", "asdf",
                    "menuCard", "menuRows","ticketsRow", "applicantsRow", "notesRow", "applicantsRow", "callsRow", "schedulesRow",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `users--works--recruitments--vacancies--show--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doDashboardHtml() {
    var html = `<div class="row">
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Status</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyStatus">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Início</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyStartedAt">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Dias</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyOpenDays">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Contratação</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyKind">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Posição</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyPosition">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Área</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyRole">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-4">
                        <h6 class="mb-0 text-center">MedCapital</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyMed">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Orçamento</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyBudget">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">Despesas</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyExpenses">
                          ${this.loader}
                        </span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12">
                        <h6 class="mb-0 text-center">Candidatos</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyApplicants">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12">
                        <h6 class="mb-0 text-center">Remuneração</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="vacancyCompensation">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                    <div class="row" data-controller="users--works--recruitments--squads--show">
                      <div class="col-12">
                        <h6 class="mb-0 text-center d-flex" data-users--works--recruitments--squads--show-target="title">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-users--works--recruitments--squads--show-target="main"></div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-10">
                    <div class="row w-100">
                      <div class="col-2 px-2">
                        <div class="card pointer bg-primary" data-${this.controllerName}-target="menuCard" data-feature="applicants" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Candidatos</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="menuCard" data-feature="tickets" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="menuCard" data-feature="calls" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Ligações</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="menuCard" data-feature="schedules" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Agendas</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="menuCard" data-feature="notes" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-0">
                        <div class="row" data-controller="users--works--recruitments--applicants--dashboard" data-${this.controllerName}-target="applicantsRow menuRows"></div>
                        <div class="row d-none" data-controller="users--works--recruitments--tickets--dashboard" data-${this.controllerName}-target="ticketsRow menuRows"></div>
                        <div class="row d-none" data-controller="users--works--recruitments--calls--dashboard" data-${this.controllerName}-target="callsRow menuRows"></div>
                        <div class="row d-none" data-controller="users--works--recruitments--schedules--dashboard" data-${this.controllerName}-target="schedulesRow menuRows"></div>
                        <div class="row d-none" data-controller="users--works--recruitments--notes--dashboard" data-${this.controllerName}-target="notesRow menuRows"></div>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier(`users--works--recruitments--tickets--dashboard`).doTicketsDashboard()
      // controller.getControllerByIdentifier(`users--works--recruitments--show--notes`).doNotesDashboard()
      // controller.getControllerByIdentifier(`users--works--recruitments--squads--show`).doSquadsHeader()
      // controller.getControllerByIdentifier(`users--works--recruitments--squads--show`).doSquadsHtml()
      // controller.getControllerByIdentifier(`users--works--recruitments--squads--show`).doPageHeader()


      // data-controller="users--works--recruitments--tickets--dashboard"
      // data-controller="users--works--recruitments--calls--dashboard"
      // data-controller="users--works--recruitments--schedules--dashboard"
      // data-controller="users--works--recruitments--notes--dashboard"
    })
  }

  toggleCard(ev) {
    var element = ev.currentTarget

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
      // this.nameTarget(`${element.dataset.feature}Row`).dataset.controller = ``
      this.nameTarget(`${element.dataset.feature}Row`).innerHTML = ``
    })

    this.menuRowsTargets.forEach(element => {
      element.classList.add(`d-none`)
    })

    element.classList.add(`bg-primary`)
    this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.nameTarget(`${element.dataset.feature}Row`).dataset.controller = `users--works--recruitments--${element.dataset.feature}--dashboard`)
    }).then(() => {

      if (element.dataset.feature == `applicants`) {
        this.getControllerByIdentifier(`users--works--recruitments--applicants--dashboard`).doApplicantsDashboard()
      } else if (element.dataset.feature == `tickets`) {
        this.getControllerByIdentifier(`users--works--recruitments--tickets--dashboard`).doTicketsDashboard()
      } else if (element.dataset.feature == `calls`) {
        this.getControllerByIdentifier(`users--works--recruitments--calls--dashboard`).doCallsDashboard()
      } else if (element.dataset.feature == `schedules`) {
        this.getControllerByIdentifier(`users--works--recruitments--schedules--dashboard`).doSchedulesDashboard()
      } else if (element.dataset.feature == `notes`) {
        this.getControllerByIdentifier(`users--works--recruitments--notes--dashboard`).doNotesDashboard()
      }
    })
    
  }

  doFinishedPage() {
    // this.getControllerByIdentifier(`users--works--recruitments--tickets--dashboard`).doTicketsDashboard()
    // this.getControllerByIdentifier(`users--works--recruitments--notes--dashboard`)[`doNotesDashboard()`]
    // this.getControllerByIdentifier(`users--works--recruitments--notes--dashboard`).doNotesDashboard()
    // this.getControllerByIdentifier(`users--works--recruitments--schedules--dashboard`).doSchedulesDashboard()
    // this.getControllerByIdentifier(`users--works--recruitments--calls--dashboard`).doCallsDashboard()
    this.getControllerByIdentifier(`users--works--recruitments--applicants--dashboard`).doApplicantsDashboard()
    this.getControllerByIdentifier(`users--works--recruitments--squads--show`).doSquadsHeader()
    this.getControllerByIdentifier(`users--works--recruitments--squads--show`).doSquadsHtml()
    this.doPageHeader()
  }

  doPageHeader() {
    this.vacancyStatusTarget.innerHTML = `<p class="text-center">${this.application.vacancy.status_pretty}</p>`
    this.vacancyKindTarget.innerHTML = `<p class="text-center">${this.application.vacancy.kind_pretty}</p>`
    this.vacancyPositionTarget.innerHTML = `<p class="text-center">${this.application.vacancy.position_pretty}</p>`
    this.vacancyRoleTarget.innerHTML = `<p class="text-center">${this.application.vacancy.role_pretty}</p>`
    this.vacancyStartedAtTarget.innerHTML = `<p class="text-center">${this.application.vacancy.started_at_pretty}</p>`
    this.vacancyOpenDaysTarget.innerHTML = `<p class="text-center">${this.application.vacancy.open_days}</p>`
    this.vacancyMedTarget.innerHTML = `<p class="text-center">${this.application.vacancy.med_name}</p>`
    this.vacancyBudgetTarget.innerHTML = `<p class="text-center">${this.controllerNumber.currencyOptionMask(this.application.vacancy.budget)}</p>`
    this.vacancyCompensationTarget.innerHTML = `<p class="text-center">${this.controllerNumber.currencyOptionMask(this.application.vacancy.compensation)}</p>`
    this.vacancyExpensesTarget.innerHTML = `<p class="text-center">${this.controllerNumber.currencyOptionMask(this.application.vacancy.expenses)}</p>`
    this.vacancyApplicantsTarget.innerHTML = `<p class="text-center">${this.application.vacancy.total_applicants}</p>`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

  // if (this.application.current_date.open) {
  // } else {
  //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
  // }
}
