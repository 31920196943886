import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "step", "stepIcon", "form", "formCard", "formTitle", "formBody", "cancelBtn",
    "nextBtn", "issueInvoice", "receivableBtn", "errorMessage"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--receivables--save`
    this.send_data = { current_user: {}, receivable: {}, performers: {}, tracker: { log: true }, replace_receivable: { mode: false } }
    this.current_clinic = {}
    this.current_date = {}
    this.current_regime = {}
    this.channels = []
    this.activities = []

    // this.send_data.receivable.clinic_id = this.application.clinic.id
    // this.send_data.receivable.provider_name = this.application.clinic.company_name
    // this.send_data.receivable.provider_number = this.application.clinic.company_cnpj_pretty
    // this.send_data.receivable.invoice_status = `not_transmitted`
    // this.send_data.receivable.receivement_status = `not_received`

    this.current_step = 0


    // this.current_clinic = this.application.clinic
    this.current_date = this.application.current_date
    this.current_regime = this.application.current_regime
    this.takers = this.application.takers
    this.activities = this.application.activities
    this.channels = this.application.channels
    this.partners = this.application.partners
    this.current_taker = {}

    this.takers = []
    this.current_taker = {}
    this.activities = []
    // this.doMainFormHtml()
  }

  doMainFormHtml() {
    var html = `<div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper stepper-padding-075 active" data-${this.controllerName}-target="step" data-step="0">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary" data-${this.controllerName}-target="stepIcon">work</span>
                        </div>
                        <span class="stepper-text">PJ Médica</span>
                      </div>
                      <div class="stepper stepper-padding-075" data-${this.controllerName}-target="step" data-step="1">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">person_add_alt</span>
                        </div>
                        <span class="stepper-text">Tomador</span>
                      </div>
                      <div class="stepper stepper-padding-075" data-${this.controllerName}-target="step" data-step="2">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">article</span>
                        </div>
                        <span class="stepper-text">Informações</span>
                      </div>
                      <div class="stepper stepper-padding-075" data-${this.controllerName}-target="step" data-step="3">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">group_work</span>
                        </div>
                        <span class="stepper-text">Sócios e Valores</span>
                      </div>
                      <div class="stepper stepper-padding-075" data-${this.controllerName}-target="step" data-step="4">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">add_shopping_cart</span>
                        </div>
                        <span class="stepper-text">Impostos</span>
                      </div>
                      <div class="stepper stepper-padding-075" data-${this.controllerName}-target="step" data-step="5">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">preview</span>
                        </div>
                        <span class="stepper-text">Revisão</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="form" 
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doFlowHtml()
    })
  }

  doFlowHtml() {
    var html = `<div class="col-11 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Informações</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-controller="operations--products--clinics--financials--add--informations
                                                                                        operations--products--clinics--financials--add--partners
                                                                                        operations--products--clinics--financials--add--taxes
                                                                                        operations--products--clinics--financials--add--takers
                                                                                        operations--products--clinics--financials--add--revision
                                                                                        operations--products--clinics--financials--index--receivables--clinics" data-${this.controllerName}-target="formBody">
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#changeStep" data-${this.controllerName}-target="cancelBtn">Cancelar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#changeStep" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--clinics").permission = controller.permission
      controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--clinics").doClinicsListHtml()
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.current_step = 0
    })
    
  }

  changeStep(ev) {
    if (ev.target.innerText == "VOLTAR" && this.current_step >= 1 && this.current_step <= 3) {
      this.current_step--
      if (this.current_step == 0) { // informations

        this.getControllerByIdentifier("operations--products--clinics--financials--add--partners").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--informations").doInformationsFormHtml()
        this.cancelBtnTarget.innerText = "Cancelar"

      } else if (this.current_step == 1) { // partners and values

        this.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--partners").doPartnersPreloaderHtml()

      }
      else if (this.current_step == 2) { // tax

        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").doTaxsFormHtml()
        this.nextBtnTarget.innerText = "Próximo"

      }
    } else if (ev.target.innerText == "PRÓXIMO" && this.current_step >= 0 && this.current_step <= 4) {

      this.current_step++

      if (this.current_step == 1) { // clinics
        this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--clinics").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--takers").permission = `medclinic_financial_takers`
        this.getControllerByIdentifier("operations--products--clinics--financials--add--takers").doTakersFormHtml()
        
      } else if (this.current_step == 2) { // informations

        this.getControllerByIdentifier("operations--products--clinics--financials--add--takers").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--informations").doInformationsPreloaderHtml()

      } else if (this.current_step == 3) { // partners and values

        this.getControllerByIdentifier("operations--products--clinics--financials--add--informations").stopRefreshing()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--informations").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--partners").doPartnersPreloaderHtml()

      } else if (this.current_step == 4) { // tax

        this.getControllerByIdentifier("operations--products--clinics--financials--add--partners").stopRefreshing()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--partners").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").doTaxsFormHtml()

      } else if (this.current_step == 5) { // revision

        this.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").getData()
        this.setStep()
        this.getControllerByIdentifier("operations--products--clinics--financials--add--revision").doRevisionFormHtml()
        this.nextBtnTarget.innerText = "Salvar"

      }
    } else if (ev.target.innerText == "SALVAR") {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.saveReceipt()
      ev.target.innerText = "EM PROCESSO"
      ev.target.disabled = true
    } else if (ev.target.innerText == "CANCELAR") {
      this.goToReceivables()
    }

  }

  setStep() {
    this.stepTargets.forEach((element, i) => {
      if (element.dataset.step == this.current_step) {
        element.classList.add("active")
        this.stepIconTargets[i].classList.add("md-primary")
      } else {
        element.classList.remove("active")
        this.stepIconTargets[i].classList.remove("md-primary")
      }
    });
  }

  goToReceivables() {
    this.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--dashboard").doReceivablesDashboard()
  }

  saveReceipt() {
    var controller = this

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_financial_receivables`

    this.send_data.tracker.record_id = this.current_clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `Recibo PJ da ${controller.send_data.receivable.provider_name} para ${controller.send_data.receivable.taker_name} no valor de ${controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(Math.round(controller.send_data.receivable.total_amount * 100))}`

    var url = "/operations/products/clinics/financials/receivables/create"
    var method = "POST"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receivable = response.data.cln.receivable
          controller.application.all_receivables[controller.application.all_receivables.length] = receivable

          controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").current_receivable = receivable
          controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").doRevisionFormHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para salvar o recibo`)
      })
  }

  getTakers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tomadores`, value: 15 })

    var data = { taker: { clinic_id: this.current_clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_takers` } }
    const url = "/operations/products/clinics/regularizations/takers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.takers = response.data.cln
        }
        
        controller.getClinicAddress()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicAddress() {
    var data = { address: { record_id: this.current_clinic.company_id, record_type: `company_entities` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financials` } }
    const url = `/users/accounts/addresses/read_by_record`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.current_clinic.address_list = [response.data.cln]

        controller.getControllerByIdentifier("operations--products--clinics--financials--add--takers").doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 


}
