import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--differentials`
    this.pageNumber = 0
    this.numberPages = []
  }

  doHtml() {
    var html = `<section class="ws-section-differentials d-flex align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="ws-title-section mb-5">${this.application.site.differentials_title}</h1>
                    </div>
                    <div class="row" data-${this.controllerName}-target="cards"></div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setServices()
    })
  }

  setServices() {
    var current_differentials = []

    this.application.site.differentials.forEach(differential => {
      if (differential.status) {
        current_differentials[current_differentials.length] = differential
      }
    })
    current_differentials = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_differentials, `order`)

    if (this.application.size == `large`) {
      this.itemsOnPage = 4
    } else if (this.application.size == `medium`) {
      this.itemsOnPage = 3
    } else if (this.application.size == `small`) {
      this.itemsOnPage = 2
    }

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_differentials.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_differentials[x] !== undefined) {
          internPage[internPage.length] = current_differentials[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    this.doCarouselData(pages[pageNumber])
  }

  doCarouselData(data) {
    var html = ``

    data.forEach(differential => {
      var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(differential.link_cta)
      var colClass = ``

      if (this.application.size == `large`) {
        colClass += `col-3`
      } else if (this.application.size == `medium`) {
        colClass += `col-4`
      } else if (this.application.size == `small`) {
        colClass += `col-6`
      }

      html += `<div class="${colClass}">
                  <div class="ws-card-services pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">
                    <div class="container">
                      <h3 class="ws-card-title">${differential.title}</h3>
                      <p class="ws-card-text">
                        ${differential.description}
                      </p>
                      <a class="ws-card-link">${differential.cta}</a>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5">
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.carouselIndicatorTargets[this.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = ev.currentTarget.dataset.slideTo

    this.setServices()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
