import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", 
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "numberForm",
                    "numberInput", "branchInput", "accountInput", "pixInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--banks--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--banks--index").clearList()
  }

  saveBank() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!`})
    
    this.send_data = { current_user: {}, bank: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.bank.id = this.current_bank.id
    }
    this.send_data.bank.clinic_id = this.application.clinic.id
    this.send_data.bank.name = this.nameInputTarget.value
    this.send_data.bank.status = this.statusInputTarget.dataset.status
    this.send_data.bank.kind = this.kindInputTarget.dataset.kind
    this.send_data.bank.number = this.numberInputTarget.value
    this.send_data.bank.branch = this.branchInputTarget.value
    this.send_data.bank.account = this.accountInputTarget.value
    this.send_data.bank.pix = this.pixInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/products/clinics/regularizations/banks/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/products/clinics/regularizations/banks/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/operations/products/clinics/regularizations/banks/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.application.banks = response.data.cln
        }

        // if (controller.actionMode == `new`) {
        //   controller.application.banks[controller.application.banks.length] = bank
        // } else if (controller.actionMode == `edit`) {
        //   controller.application.banks.forEach((element, i) => {
        //     if (element.id == bank.id) {
        //       controller.application.banks.splice(controller.application.banks.indexOf(element), 1, bank)
        //     }
        //   })
        // }
        
        controller.cancelSave()
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--banks--index`).doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    var html1 = `<div class="col-8">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Banco</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="nameDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="nameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="nameInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="nameList" data-app--helpers--search-target="searchList">
                                  <li data-number="001" data-name="Banco do Brasil S/A." data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco do Brasil S/A.</li>
                                  <li data-number="341" data-name="Banco Itau S/A." data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Itau S/A.</li>
                                  <li data-number="237" data-name="Banco Bradesco" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Bradesco</li>
                                  <li data-number="756" data-name="Sicoob Credicom" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sicoob Credicom</li>
                                  <li data-number="033" data-name="Banco Santander" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Santander</li>
                                  <li data-number="104" data-name="Caixa Econômica Federal " data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Caixa Econômica Federal </li>
                                  <li data-number="077" data-name="Banco Inter" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Inter</li>
                                  <li data-number="260" data-name="Banco Nubank" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Nubank</li>
                                  <li data-number="212" data-name="Banco Original" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Banco Original</li>
                                  <li data-number="403" data-name="Cora SCD S/A." data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cora SCD S/A.</li>
                                  <li data-number="336" data-name="C6 Bank S/A." data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">C6 Bank S/A.</li>
                                  <li data-number="336" data-name="Unicred" data-action="click->${this.controllerName}#setNumber click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">C6 Bank S/A.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>`

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveBank" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 px-1">
                        <button data-action="click->${this.controllerName}#showBanks" data-${this.controllerName}-target="nameModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Bancos</span>
                        </button>
                      </div>
                      <div class="col-7 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Banco</label>
                            <input class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="nameInput" data-filter-mode="simple" type="text" placeholder="Banco" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="numberForm">Número Banco</label>
                            <input disabled aria-describedby="numberFormHelp" class="form-control form-valid-control" id="numberForm" data-${this.controllerName}-target="numberInput" placeholder="Número Banco" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="branchForm">Agência</label>
                            <input aria-describedby="branchFormHelp" class="form-control form-valid-control" id="branchForm" data-${this.controllerName}-target="branchInput" placeholder="Agência" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="accountForm">Conta</label>
                            <input aria-describedby="accountFormHelp" class="form-control form-valid-control" id="accountForm" data-${this.controllerName}-target="accountInput" placeholder="Conta" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="pixForm">PIX</label>
                            <input aria-describedby="pixFormHelp" class="form-control form-valid-control" id="pixForm" data-${this.controllerName}-target="pixInput" placeholder="PIX" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aberta</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Encerrada</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="main" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Principal</li>
                                  <li data-kind="secondary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Secundária</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--banks--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Conta Bancária`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `active`
        controller.statusInputTarget.innerText = `Aberta`
        controller.statusInputTarget.dataset.status = `active`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Conta Bancária`

        controller.nameInputTarget.value = controller.current_bank.name

        // controller.nameDropdownTarget.value = controller.current_bank.name
        // controller.nameInputTarget.dataset.name = controller.current_bank.name

        controller.numberInputTarget.value = controller.current_bank.number
        controller.branchInputTarget.value = controller.current_bank.branch
        controller.accountInputTarget.value = controller.current_bank.account
        controller.pixInputTarget.value = controller.current_bank.pix

        controller.statusDropdownTarget.value = controller.current_bank.status
        controller.statusInputTarget.innerText = controller.current_bank.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_bank.status

        controller.kindDropdownTarget.value = controller.current_bank.kind
        controller.kindInputTarget.innerText = controller.current_bank.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_bank.kind

        controller.nameModalTarget.disabled = true
        controller.nameInputTarget.disabled = true
        controller.numberInputTarget.disabled = true
        controller.branchInputTarget.disabled = false
        controller.accountInputTarget.disabled = false
        controller.statusDropdownBtnTarget.disabled = true

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showBanks() {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="operations--products--clinics--regularizations--banks--modal" data-operations--products--clinics--regularizations--banks--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-operations--products--clinics--regularizations--banks--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }
 
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.numberInputTarget.value == ``) {
        len += 1
      }

      if (controller.branchInputTarget.value == ``) {
        len += 1
      }

      if (controller.accountInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
