import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "sidebar", "indicadorBoard", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu",
                    "content", "calculationDateRow", "calculationReport", "calculationCard", "7777", "8888", "9999",
                    "ticketCard", "channelCard", "medfilingHeaderCard",
                    "medpayrollHeaderCard", "medreceivementHeaderCard", "medbilingHeaderCard"]

  connect() {
    this.controllerName = `operations--onboards--reports--calculations`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doCalculationReportHTML() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`
    var rowLoader = `<div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3 mb-4">
                        ${loader}
                      </div>`

    var html = `<div class="row my-3">
                  
                  <div class="col-12 px-2">
                    <div class="row mb-4" data-${this.controllerName}-target="indicadorBoard">
                      ${rowLoader}
                      ${rowLoader}
                      ${rowLoader}
                      ${rowLoader}
                      ${rowLoader}
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.calculationReportTarget.innerHTML = html)
      // resolve(controller.getControllerByIdentifier("operations--onboards--reports--main").purchasesTableTarget.innerHTML = html)
    }).then(() => {
      controller.getCalculationsDates()
    })
  }

  setCurrentDate(token) {

    this.application.dates.forEach(date => {
      if (date.token == token) {
        date.current_date = true
        this.current_date = date
        this.current_calculation = date.onboard_calculation
      } else {
        date.current_date = false
      }
    });

    this.doProductCalculation()
    this.doIndicadorBoardHtml()

  }

  doDateRow() {

    var html = ``
    this.application.dates.forEach(date => {
      if (date.current_date) {
        html += `<div class="card pointer bg-primary mx-2" data-${this.controllerName}-target="${date.token}-Card" data-token="${date.token}" data-action="click->${this.controllerName}#toggleDates">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 mb-0 f-075"><strong>${date.short_name}</strong></h6>
                    </div>
                  </div>`
      } else {
        html += `<div class="card pointer mx-2" data-${this.controllerName}-target="${date.token}-Card" data-token="${date.token}" data-action="click->${this.controllerName}#toggleDates">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 mb-0 f-075"><strong>${date.short_name}</strong></h6>
                    </div>
                  </div>`
      }

    });

    // this.getControllerByIdentifier("operations--onboards--reports--main").calculationDateRowTarget.innerHTML = html
    this.calculationDateRowTarget.innerHTML = html
  }

  toggleDates(ev) {
    var token = ev.currentTarget.dataset.token

    this.application.dates.forEach(date => {
      if (date.token == token) {
        this.nameTarget(`${date.token}-Card`).classList.add("bg-primary")
      } else {
        this.nameTarget(`${date.token}-Card`).classList.remove("bg-primary")
      }
    });

    this.setCurrentDate(token)

  }

  doIndicadorBoardHtml() {
    var html = `<div class="col-12 px-2">
                  <h6 class="mb-0 d-flex align-items-center">
                    <span class="mr-5">Produtos e Serviços</span>
                  </h6>
                  <hr class="my-1">
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medclinicCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>PJ Médica</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medclinic.opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Pós-Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medclinic.pos_opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Migração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medclinic.migration}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Alteração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medclinic.amendment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medbookingCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Livro-Caixa</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medbooking.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medfilingCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Imposto de Renda</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Acompanhamento
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medfiling.opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medfiling.separate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medpayrollCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Funcionários</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Registro
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medpayroll.opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Migração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medpayroll.migration}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medreceivementCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Gestão Recebimentos</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medreceivement.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="medbilingCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Faturamento</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.product_calculation.medbiling.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 px-2">
                  <h6 class="mb-0 d-flex align-items-center">
                    <span class="mr-5">Esforço e Engajamento</span>
                  </h6>
                  <hr class="my-1">
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-${this.controllerName}-target="ticketCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Tickets</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_tickets}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Em Processo
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_in_process}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Atrasados
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_delays}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Comentários
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_comments}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-product="medclinic" data-${this.controllerName}-target="channelCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Anotações</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Total
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_notes}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 px-2">
                  <h6 class="mb-0 d-flex align-items-center">
                    <span class="mr-5">Vendas e Financeiro</span>
                  </h6>
                  <hr class="my-1">
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-product="medclinic" data-${this.controllerName}-target="channelCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Canal de Vendas</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Base
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_base}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Site/Inbound
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_site}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Indicação
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_referrer}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Outbound
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.current_calculation.total_outbound}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2 mb-3">
                  <div class="card pointer" data-product="medclinic" data-${this.controllerName}-target="channelCard calculationCard">
                    <div class="card-header p-1 text-center f-065 f-bold">
                      <span>Financeiro</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-5 text-right px-1">
                          Recorrência
                        </div>
                        <div class="col-6 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_calculation.total_recurring_amount_accrual * 100))}
                        </div>
                        <div class="col-5 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-6 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_calculation.total_setup_amount_accrual * 100))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.indicadorBoardTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicadorBoardTarget.innerHTML = html)
    }).then(() => {
      controller.calculationCardTargets.forEach(card => {
        card.style.height = ($(window).height() * 0.15) + "px"
      });
      // controller.medbookingCardTarget.style.height = ($(window).height() * 0.12) + "px"
      // controller.medfilingCardTarget.style.height = ($(window).height() * 0.12) + "px"
      // controller.medpayrollCardTarget.style.height = ($(window).height() * 0.12) + "px"
      // controller.medreceivementCardTarget.style.height = ($(window).height() * 0.12) + "px"
      // controller.medbilingCardTarget.style.height = ($(window).height() * 0.12) + "px"
    })
  }

  doProductCalculation() {
    this.product_calculation = { medclinic: {}, medbooking: {}, medfiling: {}, medpayroll: {}, medreceivement: {}, medbiling: {} }


    this.product_calculation.medclinic.opening = 0
    this.product_calculation.medclinic.pos_opening = 0
    this.product_calculation.medclinic.migration = 0
    this.product_calculation.medclinic.amendment = 0
    this.product_calculation.medclinic.separate = 0

    this.product_calculation.medbooking.opening = 0

    this.product_calculation.medfiling.opening = 0
    this.product_calculation.medfiling.separate = 0

    this.product_calculation.medpayroll.opening = 0
    this.product_calculation.medpayroll.migration = 0

    this.product_calculation.medreceivement.opening = 0

    this.product_calculation.medbiling.opening = 0

    this.application.onboards.forEach(onboard => {
      if (onboard.date_id == this.current_date.id) {
        if (onboard.product_name == `medclinic`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medclinic.opening += 1
          } else if (onboard.product_service == `pos_opening`) {
            this.product_calculation.medclinic.pos_opening += 1
          } else if (onboard.product_service == `migration`) {
            this.product_calculation.medclinic.migration += 1
          } else if (onboard.product_service == `amendment`) {
            this.product_calculation.medclinic.amendment += 1
          } else if (onboard.product_service == `separate`) {
            this.product_calculation.medclinic.separate += 1
          }
        } else if (onboard.product_name == `medbooking`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medbooking.opening += 1
          }
        } else if (onboard.product_name == `medfiling`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medfiling.opening += 1
          } else if (onboard.product_service == `separate`) {
            this.product_calculation.medfiling.separate += 1
          }
        } else if (onboard.product_name == `medpayroll`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medpayroll.opening += 1
          } else if (onboard.product_service == `migration`) {
            this.product_calculation.medpayroll.migration += 1
          }
        } else if (onboard.product_name == `medreceivement`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medreceivement.opening += 1
          }
        } else if (onboard.product_name == `medbiling`) {
          if (onboard.product_service == `opening`) {
            this.product_calculation.medbiling.opening += 1
          }
        }
      }
    });

    return this.product_calculation
  }

  getCalculationsDates() {
    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_dates` } }
    const url = "/operations/onboards/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.dates = response.data.cln
        if (controller.application.dates) {
          controller.setCurrentDate(controller.application.dates[0].token)
          controller.doDateRow()
          controller.doIndicadorBoardHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToShowPage(ev) {
    var token = ev.currentTarget.closest(".card").dataset.slug
    window.open(`/a/onboards/${token}`, `_blank`)
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
