import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "dateInput", "slotDropdown", "slotDropdownBtn", "slotInput", "slotList",
                    "guestInput", "accountInput", "accountFilter", "guestDiv", "stageFilter", "saveBtn",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "opportunityInput"]


  connect() {
    this.controllerName = `commercial--sales--leads--meetings--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("commercial--sales--leads--meetings--show").current_schedule = {}
    this.getControllerByIdentifier("commercial--sales--leads--meetings--show").doViewHtml()
    this.getControllerByIdentifier("commercial--sales--leads--meetings--index").checked_id = ``
    this.getControllerByIdentifier("commercial--sales--leads--meetings--index").doDataTable()
  }

  saveSchedule() {
    this.send_data = { current_user: {}, schedule: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.schedule.id = this.current_schedule.id
      this.send_data.schedule.account_id = this.accountInputTarget.dataset.filter
      this.send_data.schedule.slot_id = this.slotInputTarget.dataset.id
      this.send_data.schedule.schedule_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
      this.send_data.schedule.timetable = this.slotInputTarget.innerText
      this.send_data.schedule.day = new Date(this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)).getDay()
      this.send_data.schedule.status = this.statusInputTarget.dataset.status
      this.send_data.schedule.opportunity_token = this.opportunityInputTarget.value
    } else if (this.actionMode == `block`) {
      this.send_data.schedule.account_id = this.accountInputTarget.dataset.filter
      this.send_data.schedule.slot_id = this.slotInputTarget.dataset.id
      this.send_data.schedule.schedule_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
      this.send_data.schedule.timetable = this.slotInputTarget.innerText
      this.send_data.schedule.day = new Date(this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)).getDay()
      this.send_data.schedule.status = this.statusInputTarget.dataset.status
      this.send_data.schedule.kind = `sales`
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `block`) {
      var url = "/users/works/schedules/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/schedules/entities/update"
      var method = "PUT"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var schedule = response.data.cln
          if (this.actionMode == `block`) {
            controller.application.schedules[controller.application.schedules.length] = schedule
          } else if (this.actionMode == `edit`) {
            controller.application.schedules.forEach((element, i) => {
              if (element.id == schedule.id) {
                controller.application.schedules.splice(controller.application.schedules.indexOf(element), 1, schedule)
              }
            })
          }
          
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.schedules.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSchedule" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-12" data-${this.controllerName}-target="guestDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="guestForm">Lead</label>
                            <input disabled class="form-control f-075 pt-0" data-${this.controllerName}-target="guestInput" type="text" placeholder="Lead" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">MedCapital</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="accountInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#accountFilter keyup->${this.controllerName}#accountFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterAccount" type="text" placeholder="MedCapital" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="accountFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="dateInput" type="text" placeholder="Data" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="booked" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendado</li>
                                  <li data-status="done" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Realizada</li>
                                  <li data-status="canceled" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelada</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Horário</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="slotDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="slotDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="slotInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="slotList app--helpers--search.searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="guestForm">Oportunidade</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="opportunityInput" type="text" placeholder="Oportunidade" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Agendamento`
        controller.guestInputTarget.value = controller.current_schedule.guest_name
        controller.accountInputTarget.value = controller.current_schedule.account_name
        controller.accountInputTarget.dataset.filter = controller.current_schedule.account_id
        controller.accountInputTarget.dataset.text = controller.current_schedule.account_name
        controller.opportunityInputTarget.value = controller.current_schedule.opportunity_token

        controller.dateInputTarget.value = controller.current_schedule.schedule_at_pretty
        controller.slotDropdownTarget.value = controller.current_schedule.timetable
        controller.slotInputTarget.dataset.id = controller.current_schedule.slot_id
        controller.slotInputTarget.innerText = controller.current_schedule.timetable

        controller.statusDropdownTarget.value = controller.current_schedule.status
        controller.statusInputTarget.dataset.status = controller.current_schedule.status
        controller.statusInputTarget.innerText = controller.current_schedule.status_pretty
      } else if (controller.actionMode == `block`) {

        controller.guestDivTarget.classList.add("d-none")

        controller.accountInputTarget.value = controller.application.current_user.name
        controller.accountInputTarget.dataset.filter = controller.application.current_user.id
        controller.accountInputTarget.dataset.text = controller.application.current_user.name
        controller.statusDropdownTarget.value = `blocked`
        controller.statusInputTarget.dataset.status = `blocked`
        controller.statusInputTarget.innerText = `Bloqueado`
        controller.statusDropdownBtnTarget.disabled = true
      }
      
      controller.pickOnlyFuture($(controller.dateInputTarget))
      controller.listAccount()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listAccount() {
    var html = ``
    this.application.sales_team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectAccount" data-${this.controllerName}-target="accountFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.accountFilterTarget.innerHTML = html
  }

  accountFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectAccount(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterAccount(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listSlots() {
    var html = ``
    this.application.slots.forEach(slot => {
      html += `<li data-id="${slot.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${slot.timetable}</li>`
    })

    this.slotListTarget.innerHTML = html
    this.slotDropdownTarget.value = ``
    this.slotInputTarget.dataset.id = ``
    this.slotInputTarget.innerText = ``
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  pickOnlyFuture(element) {

    var controller = this

    element.pickdate({
      today: 'Hoje',
      ok: 'Selecionar',
      cancel: 'Fechar',
      closeOnCancel: true,
      closeOnSelect: true,
      container: 'body',
      containerHidden: 'body',
      firstDay: 0,
      format: 'dd/mmm/yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenPrefix: 'pickdate-input',
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      labelMonthSelect: 'Escolha um mês do menu',
      labelYearSelect: 'Escolha um ano do menu',
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      showMonthsFull: true,
      showMonthsShort: true,
      showWeekdaysFull: true,
      showWeekdaysShort: true,
      selectMonths: true,
      selectYears: true,
      min: true,
      disable: [1,
        7,
        new Date(2022, 5, 3),
        new Date(2022, 7, 15),
        new Date(2022, 8, 7),
        new Date(2022, 9, 12),
        new Date(2022, 10, 2),
        new Date(2022, 10, 15),
        new Date(2022, 11, 8),
        new Date(2022, 11, 24),
        new Date(2022, 11, 25),
        new Date(2022, 11, 31),
        new Date(2023, 0, 1),],
      onClose: function (e) {
        // funcao para guardar a data selecionada pelo cliente
        controller.changeDate();
      },
    });

    element.on('mousedown', function (e) {
      e.preventDefault();
      $(this).dblclick()
    });
  }

  changeDate() {
    this.send_slot_data = { current_user: {}, slot: {} }

    var selectedDate = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    selectedDate = new Date(selectedDate)

    this.selectedDate = { date: this.dateInputTarget.value, day: this.getControllerByIdentifier("app--helpers--date").getWeekDay(selectedDate.getDay()) }
    this.send_slot_data.slot.date = selectedDate
    this.send_slot_data.slot.account_id = this.accountInputTarget.dataset.filter
    this.send_slot_data.current_user.current_user_id = this.application.current_user.id

    if (selectedDate != `Invalid Date`) {
      var url = "/users/works/schedules/slots/list_by_account"
      var method = "POST"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_slot_data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          controller.application.slots = response.data.cln
          controller.listSlots()
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Selecione uma data", 4000)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.accountInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
