import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "bindSum", "clearListBtn", "optionBtn",
                    "multipleCheck"]

  connect() {
    this.controllerName = `financials--books--statements--transactions--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `date_to_time` }
    this.hasShow = false
    this.dayFilter = false
    this.tableCols = 8
  }

  addTransaction() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("financials--books--statements--transactions--save").actionMode = "new"
      this.getControllerByIdentifier("financials--books--statements--transactions--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  copyTransaction(ev) {
    if (this.application.current_date.open) {
      var current_transactions = []
      var transationId = ev.currentTarget.closest(".itemRow").dataset.id

      this.application.transactions.forEach(transaction => {
        if (this.application.current_date.id == transaction.date_id) {
          current_transactions = transaction.data
        }
      })
      
      current_transactions.forEach(element => {
        if (element.id == transationId) {
          this.getControllerByIdentifier("financials--books--statements--transactions--save").current_transaction = element
        }
      })
      this.getControllerByIdentifier("financials--books--statements--transactions--save").actionMode = "copy"
      this.getControllerByIdentifier("financials--books--statements--transactions--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  editTransaction(ev) {
    if (this.application.current_date.open) {
      var current_transactions = []
      var id = ev.currentTarget.closest(".itemRow").dataset.id

      this.application.transactions.forEach(transaction => {
        if (this.application.current_date.id == transaction.date_id) {
          current_transactions = transaction.data
        }
      })
      
      current_transactions.forEach(element => {
        if (element.id == id) {
          this.getControllerByIdentifier("financials--books--statements--transactions--save").current_transaction = element
        }
      })
      this.getControllerByIdentifier("financials--books--statements--transactions--save").actionMode = "edit"
      this.getControllerByIdentifier("financials--books--statements--transactions--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência está Fechada. Favor selecionar a Competência atual ou abrir a Competência.`, 5000)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  refreshTransaction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 10)

    const data = { transaction: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_transactions` } }
    const url = "/financials/books/statements/transactions/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.transactions.forEach(element => {
            if (element.date_id == controller.application.current_date.id) {
              element.data = response.data.cln.collection
            }
          })
        }

        controller.dayFilter = false
        controller.getControllerByIdentifier(`financials--books--statements--transactions--days`).dayBtnTargets.forEach(element => {
          element.classList.add(`btn-flat-dark`)
          element.classList.remove(`btn-primary`)
        })

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyTransaction(ev) {
    var r = confirm("Tem certeza que deseja apagar a Transação?")
    if (r) {

      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      var transaction = ``
      var transactionId = ev.currentTarget.closest(".itemRow").dataset.id

      this.application.transactions.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          element.data.forEach(element => {
            if (element.id == transactionId) {
              transaction = element
            }
          })
        }
      })

      this.send_data = { current_user: {}, transaction: {} }

      this.send_data.transaction.id = transaction.id
      this.send_data.transaction.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_statement_transactions`

      var url = "/financials/books/statements/transactions/destroy"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            controller.application.transactions.forEach((array, i) => {
              if (array.date_id == controller.application.current_date.id) {
                var transactions = response.data.cln
                transactions.forEach(transaction => {
                  array.data.forEach((element, i) => {
                    if (element.id == transaction.id) {
                      array.data.splice(array.data.indexOf(element), 1)
                    }
                  })
                })  
              }
            })
            
            controller.doDataTable()
          }
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })

    }
  }

  downloadAccountingTransactions() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, transaction: {} }

    this.send_data.transaction.date_id = this.application.current_date.id
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-transacoes-contabeis`
      this.send_data.transaction.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-transacoes-contabeis`
      this.send_data.transaction.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_transactions`

    var fileExtension = `csv`
    var url = "/financials/books/statements/transactions/download_accounting_transactions"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  downloadFinancialTransactions() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, transaction: {} }

    this.send_data.transaction.date_id = this.application.current_date.id
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-transacoes-financeiras`
      this.send_data.transaction.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-transacoes-financeiras`
      this.send_data.transaction.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_transactions`

    var fileExtension = `csv`
    var url = "/financials/books/statements/transactions/download_financial_transactions"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doIndexListHtml() {

    if (this.application.permissions.financial_statement_transactions.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addTransaction" class="dropdown-item py-1 pointer dropdown-submenu-item">Nova Transação</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions.financial_statement_transactions.can_update) {
      var editLine = `<span data-action="click->${this.controllerName}#checkForEditTransaction" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Transação</span>`
    } else {
      var editLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshTransaction" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Transações</span>`

    if (this.application.permissions.financial_statement_transactions.can_download) {
      var accountDownloadLine = `<span data-action="click->${this.controllerName}#downloadAccountingTransactions" class="dropdown-item py-1 pointer dropdown-submenu-item">Download das Transações Contábeis</span>`
      var financialDownloadLine = `<span data-action="click->${this.controllerName}#downloadFinancialTransactions" class="dropdown-item py-1 pointer dropdown-submenu-item">Download das Transações Financeiras</span>`
    } else {
      var accountDownloadLine = ``
      var financialDownloadLine = ``
    }

    var bindLine = `<span data-action="click->${this.controllerName}#checkForBindSum" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Transações</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                          ${addLine}
                          ${editLine}
                          ${accountDownloadLine}
                          ${financialDownloadLine}
                          ${bindLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Transações de Contas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Empresa</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">Descrição</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Origem</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Destino</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Valor</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Tipo de Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--transactions--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_transactions = []
    var current_filter_transactions = []

    this.application.transactions.forEach(transaction => {
      if (this.application.current_med.id) {
        if (this.application.current_date.id == transaction.date_id) {
          transaction.data.forEach(element => {
            if (element.med_id == this.application.current_med.id) {
              current_transactions[current_transactions.length] = element
            }
          })
        }
      } else {
        if (this.application.current_date.id == transaction.date_id) {
          current_transactions = transaction.data
        }
      }
    })

    if (this.dayFilter) {
      if (this.application.current_date.month < 10) {
        var currentMonth = `0${this.application.current_date.month}`
      } else {
        var currentMonth = `${this.application.current_date.month}`
      }

      if (this.current_day < 10) {
        var currentDay = `0${this.current_day}`
      } else {
        var currentDay = `${this.current_day}`
      }

      var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

      current_transactions.forEach(element => {
        if (element.transaction_date_token == currentToken) {
          current_filter_transactions[current_filter_transactions.length] = element
        }
      })

      current_transactions = current_filter_transactions
    }

    // this.application.transactions.forEach(transaction => {
    //   if (this.application.current_date.id == transaction.date_id) {
    //     current_transactions = transaction.data
    //   }
    // })

    if (this.sort.mode == `asc`) {
      var transactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_transactions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var transactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_transactions, this.sort.field)
    }

    if (transactions.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(transactions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (transactions[x] !== undefined) {
            internPage[internPage.length] = transactions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: transactions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var message = ``
      if (this.dayFilter) {
        message = `Não há Transações de Contas ${this.current_day}/${this.application.current_date.short_name}`
      } else {
        message = `Não há Transações de Contas ${this.application.current_date.name}`
      }
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.transactionTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  transactionTablePartial(element, length) {

    if (this.application.current_user.super_admin && element.intern == false) {
      var destroyBtn = `<button data-action="click->${this.controllerName}#destroyTransaction" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar Transação</span>
                        </button>`
    } else {
      var destroyBtn = ``
    }

    if (this.application.current_user.super_admin && element.intern == false) {
      var copyBtn = `<button data-action="click->${this.controllerName}#copyTransaction" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">copy_all</span>
                          <span class="mc-tooltiptext">Copiar Transação</span>
                        </button>`
    } else {
      var copyBtn = ``
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `edit_transaction`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#editTransaction">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    } else if (this.kind == `sum_transactions`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle help">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.description}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">${element.provider_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <small class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.from_name_pretty}" data-action="click->app--helpers--copy#copy">${element.from_name_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <small class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.to_name_pretty}" data-action="click->app--helpers--copy#copy">${element.to_name_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </small>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.amount)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${destroyBtn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${copyBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-4 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-4 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  checkForEditTransaction() {
    this.kind = `edit_transaction`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  checkForBindSum() {
    this.kind = `sum_transactions`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  bindSum() {
    var bindSum = 0
    var transactionsArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        transactionsArray[transactionsArray.length] = Number(check.dataset.id)
      }
    })

    this.application.transactions.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        element.data.forEach(transaction => {
          if (transactionsArray.includes(transaction.id)) {
            bindSum += Number(transaction.amount)
          }
        })
      }
    })

    this.bindSumTarget.innerText = `Valor Total Selecionado: ${this.controllerNumber.currencyOptionMask(bindSum)}`
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
