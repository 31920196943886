import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "titleInput", "nameInput", "pathInput", "saveBtn",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "productDropdown", "productDropdownBtn", 
                    "productInput", "productList", "finalCtaDropdown", "finalCtaDropdownBtn", "finalCtaInput", "finalCtaList", "descriptionInput", 
                    "copyInput"]


  connect() {
    this.controllerName = `commercial--marketing--landings--index--add`
    this.send_data = { current_user: {}, landing: {} }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`commercial--marketing--landings--index--list`).doIndexListHtml()
  }

  saveLanding() {
    // this.saveBtnTarget.disabled = true
    // this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.landing.id = this.current_landing.id
    }
    this.send_data.landing.title = this.titleInputTarget.value
    this.send_data.landing.name = this.nameInputTarget.value
    this.send_data.landing.path = this.pathInputTarget.value
    this.send_data.landing.kind = this.kindInputTarget.dataset.kind
    this.send_data.landing.product = this.productInputTarget.dataset.product
    this.send_data.landing.final_cta = this.finalCtaInputTarget.dataset.finalCta
    
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/landings/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/landings/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var landing = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.landings[controller.application.landings.length] = landing
          } else {
            controller.application.landings.forEach((element, i) => {
              if (element.id == landing.id) {
                controller.application.landings.splice(controller.application.landings.indexOf(element), 1, landing)
              }
            })
          }
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.landings.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveLanding" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row my-2 d-flex">
                  <div class="col-5 mx-auto">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-3 px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Título da Landing Page</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="titleInput" type="text" placeholder="Título da Landing Page" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Nome da Landing Page</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="nameInput" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="Nome da Landing Page" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mt-2">
                            <div class="form-group">
                              <div class="input-group input-group-sm">
                                <input disabled class="form-control f-075 pt-0" data-${this.controllerName}-target="pathInput" type="text" placeholder="URL da Landing Page" required>
                                <span class="input-group-icon mr-2">/</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="service" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviço</li>
                                      <li data-kind="campaign" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Campanha</li>
                                      <li data-kind="content" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Conteúdo</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Produto</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="productList" data-app--helpers--search-target="searchList">
                                      <li data-product="clinic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                      <li data-product="booking" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                      <li data-product="taxing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Imposto de Renda</li>
                                      <li data-product="invoicing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                      <li data-product="insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                      <li data-product="education" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Educação</li>
                                      <li data-product="general" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Geral</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>CTA</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="finalCtaDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="finalCtaDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="finalCtaInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="finalCtaList" data-app--helpers--search-target="searchList">
                                      <li data-final-cta="schedule" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendamento</li>
                                      <li data-final-cta="download" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Download</li>
                                      <li data-final-cta="calculator" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Calculadora</li>
                                      <li data-final-cta="course" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Curso</li>
                                      <li data-final-cta="lesson" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aula</li>
                                      <li data-final-cta="talk" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Conversa</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--landings--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Landing Page`
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Landing Page | ${controller.current_landing.name}`

        controller.titleInputTarget.value = controller.current_landing.title
        controller.nameInputTarget.value = controller.current_landing.name
        controller.pathInputTarget.value = controller.getControllerByIdentifier("app--helpers--input").toPath(controller.current_landing.name)
        controller.kindInputTarget.innerText = controller.current_landing.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_landing.kind
        controller.kindDropdownTarget.value = controller.current_landing.kind_pretty
        controller.productInputTarget.innerText = controller.current_landing.product_pretty
        controller.productInputTarget.dataset.product = controller.current_landing.product
        controller.productDropdownTarget.value = controller.current_landing.product
        controller.finalCtaInputTarget.innerText = controller.current_landing.final_cta_pretty
        controller.finalCtaInputTarget.dataset.finalCta = controller.current_landing.final_cta
        controller.finalCtaDropdownTarget.value = controller.current_landing.final_cta
      }
      
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  bindName(ev) {
    var input = this.pathInputTarget
    var output = this.nameInputTarget

    this.getControllerByIdentifier("app--helpers--input").bindValues(ev, input, output)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.titleInputTarget.value == ``) {
        len += 1
      }
      
      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.pathInputTarget.value == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.productInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.finalCtaInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}

