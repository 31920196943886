import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `app--stats--trackers--dashboard--main`
    this.account_kind = this.application.current_user.account_kind
    this.application.permissions = {}
    this.application.requests_real_time = []
    this.application.users_real_time = []
    this.application.requests_by_duration = []
    this.application.requests = []
    
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="app--stats--trackers--requests--dashboard
                                                            app--stats--trackers--real-time--dashboard
                                                            app--stats--builders--dashboard

                                                            app--stats--trackers--emails--report-list" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier("operations--products--clinics--financials--show--dashboard").doPageDashboardPreloader()
      // controller.getControllerByIdentifier("app--stats--trackers--emails--dashboard").dashboardController = controller
      // controller.getControllerByIdentifier("app--stats--trackers--emails--dashboard").submenuController = controller.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier("app--stats--builders--dashboard").doBuildersDashboard()
    this.getControllerByIdentifier("app--stats--trackers--dashboard--submenu").doSubmenuHtml()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }
  
  getRequests() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Requests`, value: 80 })

    var data = { tracker_request: { days_ago: 7 }, current_user: { current_user_id: this.application.current_user.id, feature: `app_stats_builders_requests` } }
    const url = "/app/stats/builders/requests/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.requests = response.data.cln

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRequestsRealTime() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Requests em Tempo Real`, value: 60 })

    var data = { tracker_request: { last_minutes: 10 }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/app/stats/trackers/requests/list_real_time"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.requests_real_time = response.data.cln.requests
        controller.application.users_real_time = response.data.cln.users
        
        controller.getRequests()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRequestsByDuration() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Requests por Duração`, value: 30 })

    var data = { tracker_request: { days_ago: 7 }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/app/stats/trackers/requests/list_by_duration_by_controller"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    // fetch(url, init)
    //   .then(response => response.json())
    //   .then(response => {
    //     controller.application.requests_by_duration = response.data.cln
    //     controller.getRequestsRealTime()
    //     controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
    //   })
    //   .catch(error => {
    //     controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
    //   })
  }

  getRoom() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canal de Broadcast`, value: 20 })
    this.controllerBroadcast = this.getControllerByIdentifier(`app--stats--trackers--dashboard--broadcast`)

    var data = { current_user: { current_user_id: this.application.current_user.id, feature: `app_stats_tracker_general` } }
    const url = "/app/stats/trackers/broadcasts/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.controllerBroadcast.update_room = response.data.cln.update
          controller.controllerBroadcast.broadcastUpdateChannel()
        }

        // controller.getRequestsByDuration()
        controller.getRequestsRealTime()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `app_stats_trackers` }, current_user: { current_user_id: this.application.current_user.id, feature: `app_stats_tracker_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`marketing_site_permissions`]

        controller.getRoom()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}