import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "accrualDate", "sameDayCheckbox", "dueDate", "saveBtn",
                    "allowanceInput", "chartAccountModal", "chartInput", "kindInputCheckbox",
                    "channelModal", "channelInput", "asdf", "asdf",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "contractStatusForm",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList",
                    "amountInput", "startedAt", "finishedForm", "finishedAt",
                    "amountInput", "allowanceModal", "providerModal", "asdf",
                    "cardDiv", "totalAmountInput", "installmentInput", "amountSplitInput", "bankLineDiv",
                    "bankLineInput", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKindDropdown",
                    "pixKindDropdownBtn", "pixKindInput", "pixKindList", "pixKeyInput"]


  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
    this.kind_input = ``
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`financials--books--payrolls--remunerations--list`).doRemunerationList()
    // this.getControllerByIdentifier("financials--books--payrolls--remunerations--resume").doResumeHtml()
  }

  saveRemuneration() {
    // this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, remuneration: {}, tracker: { manual: true } }

    if (this.actionMode == `edit`) {
      this.send_data.remuneration.id = this.current_remuneration.id
      // this.send_data.remuneration.status = this.statusInputTarget.dataset.status

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou o item ${this.allowanceInputTarget.dataset.allowance} da Remuneração ${this.current_contract.account_name}`
      this.send_data.tracker.obj_id = this.current_remuneration.id

    } else if (this.actionMode == `new` || this.actionMode == `copy`) {

      this.send_data.remuneration.contract_id = this.current_contract.id
      this.send_data.remuneration.status = `not_done`

      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou item de Remuneração ${this.allowanceInputTarget.dataset.allowance} para ${this.current_contract.account_name}`
    }

    var chart = {}
    var chartId = this.chartInputTarget.dataset.filter
    this.application.chart_accounts.forEach(element => {
      if (element.id == chartId) {
        chart = element
      }
    })

    this.send_data.remuneration.chart_id = chart.id
    this.send_data.remuneration.chart_account = chart.chart_account
    this.send_data.remuneration.chart_name = chart.chart_name
    this.send_data.remuneration.chart_master_name = chart.master_name
    this.send_data.remuneration.chart_group = chart.group

    this.send_data.remuneration.description = `${this.allowanceInputTarget.dataset.allowance} ${this.current_date.name}`
    // this.send_data.remuneration.notes = this.notesInputTarget.value.trim()

    this.send_data.remuneration.allowance = this.allowanceInputTarget.dataset.allowance
    this.send_data.remuneration.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.remuneration.channel_name = this.channelInputTarget.value
    this.send_data.remuneration.med_id = this.current_contract.med_id
    this.send_data.remuneration.due_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.remuneration.accrual_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.remuneration.amount = this.controllerNumber.fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.remuneration.method = this.methodInputTarget.dataset.method
    
    
    this.send_data.remuneration.kind_input = this.kind_input
    if (this.kind_input == `inflow` || this.kind_input == `prepaidflow` || this.kind_input == `refundflow` || this.kind_input == `laborflow`) {
      this.send_data.remuneration.kind = `payable`
    } else if (this.kind_input == `outflow`) {
      this.send_data.remuneration.kind = `to_provision`
    }
    
    if (this.methodInputTarget.dataset.method == `pix`) {
      this.send_data.remuneration.pix_key = this.pixKeyInputTarget.value
      this.send_data.remuneration.pix_kind = this.pixKindInputTarget.dataset.kind
    }
    this.send_data.remuneration.bank_line = this.bankLineInputTarget.value
    this.send_data.remuneration.bank_account = this.bankAccountInputTarget.value
    this.send_data.remuneration.installment = this.installmentInputTarget.value

    this.send_data.tracker.record_id = this.current_contract.account_id
    this.send_data.tracker.record_type = `account_entities`
    this.send_data.tracker.obj_type = `financial_payroll_remunerations`

    this.requestSave()
  }

  requestSave() {
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission
    
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new` || this.actionMode == `copy`) {
      var url = "/financials/books/payrolls/remunerations/create"
      var method = "POST"
    } else if (this.actionMode == `edit` || this.actionMode == `closing`) {
      var url = "/financials/books/payrolls/remunerations/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/payrolls/remunerations/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var remuneration = response.data.cln

          if (controller.actionMode == `new` || controller.actionMode == `copy`) {
            controller.application.remunerations[controller.application.remunerations.length] = remuneration
          } else if (controller.actionMode == `edit`) {
            controller.application.remunerations.forEach((element, i) => {
              if (element.id == remuneration.id) {
                controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1, remuneration)
              }
            })

          } else if (controller.actionMode == `destroy`) {
            controller.application.remunerations.forEach((element, i) => {
              if (element.id == remuneration.id) {
                controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1)
              }
            })
          }

        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    // `<div class="row my-2">
    //   <div class="col-12">
    //     <div class="form-group">
    //       <div class="floating-label floating-label-sm">
    //         <label for="descriptionForm">Descrição</label>
    //         <input aria-describedby="descriptionFormHelp" class="form-control textarea px-0" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // <div class="row my-2">
    //   <div class="col-12">
    //     <div class="form-group">
    //       <div class="floating-label floating-label-sm">
    //         <label for="notesForm">Anotações</label>
    //         <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="4"></textarea>
    //       </div>
    //     </div>
    //   </div>
    // </div>`

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRemuneration" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accrualDateForm">Lançamento</label>
                                <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="sameDayCheck" data-${this.controllerName}-target="sameDayCheckbox" data-action="click->${this.controllerName}#sameDayCheck">
                              <label class="custom-control-label f-065" for="sameDayCheck">Mesmo Dia</label>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueDateForm">Vencimento</label>
                                <input aria-describedby="dueDateFormHelp" class="form-control form-valid-control" id="dueDateForm" data-${this.controllerName}-target="dueDate" placeholder="Vencimento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0" data-${this.controllerName}-target="allowanceModalCol">
                            <button data-action="click->${this.controllerName}#showAllowance" data-element="allowanceInput" data-${this.controllerName}-target="allowanceModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Tipos</span>
                            </button>
                          </div>
                          <div class="col-3" data-${this.controllerName}-target="allowanceModalCol">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="allowanceInput" type="text" placeholder="Tipo" required>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="chartInput" data-${this.controllerName}-target="chartAccountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Plano de Conta</label>
                                <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" type="text" placeholder="Plano de Conta" required>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        
                        <div class="row my-2 mt-3 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="kindInputReceiveCheck" data-${this.controllerName}-target="kindInputCheckbox" data-input="inflow" data-action="click->${this.controllerName}#kindInputCheck">
                              <label class="custom-control-label f-065" for="kindInputReceiveCheck">Recebimento</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="kindInputDiscountCheck" data-${this.controllerName}-target="kindInputCheckbox" data-input="outflow" data-action="click->${this.controllerName}#kindInputCheck">
                              <label class="custom-control-label f-065" for="kindInputDiscountCheck">Desconto</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="kindInputLaborChargeCheck" data-${this.controllerName}-target="kindInputCheckbox" data-input="laborflow" data-action="click->${this.controllerName}#kindInputCheck">
                              <label class="custom-control-label f-065" for="kindInputLaborChargeCheck">Encargos
                              </label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="kindInputPrepaidCheck" data-${this.controllerName}-target="kindInputCheckbox" data-input="prepaidflow" data-action="click->${this.controllerName}#kindInputCheck">
                              <label class="custom-control-label f-065" for="kindInputPrepaidCheck">Antecipação</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="kindInputRefundCheck" data-${this.controllerName}-target="kindInputCheckbox" data-input="refundflow" data-action="click->${this.controllerName}#kindInputCheck">
                              <label class="custom-control-label f-065" for="kindInputRefundCheck">Reembolso</label>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2 mt-3 d-flex align-items-center">

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal Pagamento</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal Pagamento" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="row d-none" data-${this.controllerName}-target="cardDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="totalAmountForm">Valor Total</label>
                                <input aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Total" type="tel" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="splitForm"># Parcelas</label>
                                <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="installmentInput" data-action="blur->${this.controllerName}#splitAmountEv keyup->${this.controllerName}#splitAmountEv" placeholder="Parcelas" type="tel" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountSplitForm">Valor Parcela</label>
                                <input disabled aria-describedby="amountSplitFormHelp" class="form-control form-valid-control" id="amountSplitForm" data-${this.controllerName}-target="amountSplitInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="tel" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="bankLineDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="bankLineForm">Linha Digitável Boleto (somente números, sem pontos)</label>
                                <input aria-describedby="bankLineFormHelp" class="form-control" id="bankLineForm" data-${this.controllerName}-target="bankLineInput" placeholder="Linha Digitável Boleto (somente números, sem pontos)" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none"  data-${this.controllerName}-target="bankAccountDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="bankAccountForm">Dados Bancários</label>
                                <input aria-describedby="bankAccountFormHelp" class="form-control" id="bankAccountForm" data-${this.controllerName}-target="bankAccountInput" placeholder="Dados Bancários" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="pixKeyDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo PIX</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="pixKindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="pixKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="pixKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="pixKindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="id_number" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">CPF/CNPJ</li>
                                      <li data-kind="email" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">E-mail</li>
                                      <li data-kind="phone" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Celular</li>
                                      <li data-kind="random" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chave Aleatória</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-7">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="pixKeyForm">Chave PIX</label>
                                <input aria-describedby="pixKeyFormHelp" class="form-control" id="pixKeyForm" data-${this.controllerName}-target="pixKeyInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--remunerations--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      var date = new Date(controller.current_date.year, controller.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(controller.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }


      controller.application.meds.forEach(element => {
        if (element.id == controller.current_contract.med_id) {
          controller.current_med = element
          controller.setMainChannel(element)
        }
      })

      if (controller.actionMode == `edit` || controller.actionMode == `copy`) {

        if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Remuneração`
          if (controller.current_remuneration.status == `done`) {
            controller.saveBtnTarget.remove()
          }
        } else if (controller.actionMode == `copy`) {
          controller.formTitleTarget.innerText = `Nova Remuneração`
        }

        controller.accrualDateTarget.value = controller.current_remuneration.accrual_date_pretty
        controller.dueDateTarget.value = controller.current_remuneration.due_date_pretty

        controller.methodDropdownTarget.value = controller.current_remuneration.method
        controller.methodInputTarget.dataset.method = controller.current_remuneration.method
        controller.methodInputTarget.innerText = controller.current_remuneration.method_pretty

        controller.allowanceInputTarget.value = controller.current_remuneration.allowance
        controller.allowanceInputTarget.dataset.allowance = controller.current_remuneration.allowance

        controller.chartInputTarget.value = controller.current_remuneration.chart_name
        controller.chartInputTarget.dataset.filter = controller.current_remuneration.chart_id
        controller.chartInputTarget.dataset.text = controller.current_remuneration.chart_name

        controller.channelInputTarget.value = controller.current_remuneration.channel_name
        controller.channelInputTarget.dataset.channelId = controller.current_remuneration.channel_id
        // controller.channelInputTarget.dataset.channelKind = controller.current_remuneration.channel_name        

        controller.kind_input = controller.current_remuneration.kind_input
        controller.kindInputCheckboxTargets.forEach(element => {
          if (element.dataset.input == controller.current_remuneration.kind_input) {
            element.checked = true
          }
        })

        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_remuneration.amount)
        controller.setBankData(controller.current_remuneration.method)

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Remuneração`
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)


        var method = `pix`
        var methodPretty = `Pix`
        controller.methodDropdownTarget.value = method
        controller.methodInputTarget.dataset.method = method
        controller.methodInputTarget.innerText = methodPretty

        // controller.pixKindDropdownTarget.value = controller.current_contract.provider.pix_kind
        // controller.pixKindInputTarget.dataset.pixKind = controller.current_contract.provider.pix_kind
        // controller.pixKindInputTarget.innerText = controller.current_contract.provider.pix_kind_pretty

        controller.setBankData(method)
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setMainChannel(med) {
    var mainChannel = {}
    this.application.channels.forEach(channel => {
      if (channel.med_id == med.id && channel.subkind == `main`) {
        mainChannel = channel
      }
    })

    this.channelInputTarget.value = mainChannel.name
    this.channelInputTarget.dataset.channelId = mainChannel.id
    this.setMethodList(mainChannel)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (this.subkind == `transfers`) {
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`

      this.methodDropdownTarget.value = `transfer`
      this.methodInputTarget.innerText = `Transferência`
      this.methodInputTarget.dataset.method = `transfer`
      this.setBankData(`transfer`)
      this.bankAccountInputTarget.value = channel.name
    } else {
      if (channel.kind == `bank_account`) {
        methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
        methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
        methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = ``
        this.methodInputTarget.innerText = ``
        this.methodInputTarget.dataset.method = ``
      } else if (channel.kind == `credit_card`) {
        methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Cartão de Crédito`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `cash`) {
        methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Dinheiro`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `payment`) {
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = `bank_debt`
        this.methodInputTarget.innerText = `Débito em Conta`
        this.methodInputTarget.dataset.method = `bank_debt`
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      }
    }

    this.methodListTarget.innerHTML = methodList
  }

  kindInputCheck(ev) {
    var kindElement = ev.currentTarget
    this.kind_input = kindElement.dataset.input

    this.kindInputCheckboxTargets.forEach(element => {
      element.checked = false
    })

    kindElement.checked = true
  }

  selectMethod(ev) {
    var method = ev.currentTarget.dataset.method

    this.setBankData(method)
  }

  setBankData(method) {
    if (method == `bank_slip`) {
      this.bankLineDivTarget.classList.remove("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    } else if (method == `transfer`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.remove("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
      this.setContractProviderPaymentMethod(method)
    } else if (method == `pix`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.remove("d-none")
      this.cardDivTarget.classList.add("d-none")
      this.setContractProviderPaymentMethod(method)
    } else if (method == `credit_card`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.remove("d-none")

      this.totalAmountInputTarget.disabled = true
      this.totalAmountInputTarget.value = this.amountInputTarget.value
      this.amountSplitInputTarget.disabled = true
      this.installmentInputTarget.value = 1
      this.amountSplitInputTarget.value = this.amountInputTarget.value

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    } else {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setContractProviderPaymentMethod(method) {
    if (method == `transfer`) {
      this.bankAccountInputTarget.value = this.current_contract.provider.bank_account
    } else if (method == `pix`) {
      this.pixKeyInputTarget.value = this.current_contract.provider.pix_key

      this.pixKindDropdownTarget.value = this.current_contract.provider.pix_kind
      this.pixKindInputTarget.dataset.pixKind = this.current_contract.provider.pix_kind
      this.pixKindInputTarget.innerText = this.current_contract.provider.pix_kind_pretty
    }
  }

  showAllowance(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--books--payrolls--remunerations--allowance-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = []
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payroll_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  sameDayCheck(ev) {
    var check = ev.currentTarget.checked

    if (check) {
      this.dueDateTarget.value = this.accrualDateTarget.value
    } else {
      this.dueDateTarget.value = ``
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = true
      controller.getControllerByIdentifier(modalName).permission = `financial_payroll_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  showProviders() {
    var modal = `financials--books--dashboards--providers--modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).feature = `financial_payroll_providers`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    this.controllerNumber.changeNumberToDate(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {

        if (controller.hasAccrualDateInputTarget && controller.accrualDateInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasDueDateInputTarget && controller.dueDateInputTarget.value == ``) {
          len += 1
        }

        if (controller.allowanceInputTarget.value == ``) {
          len += 1
        }

        if (controller.chartInputTarget.value == ``) {
          len += 1
        }

        if (controller.channelInputTarget.value == ``) {
          len += 1
        }

        if (controller.methodInputTarget.innerText == ``) {
          len += 1
        }

        // if (controller.descriptionInputTarget.value == ``) {
        //   len += 1
        // }

        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.kind_input == ``) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  checkChartAccount(chartKind) {
    // var chartArray = [
    //   "staff_payables",
    //   "labor_obligations_payables",
    //   "benefits_payables",
    // ]

    if (this.current_contract.role == `technology`) {
      var chartArray = [
        "staff_technology",
      ]
    } else if (this.current_contract.role == `accounting`) {
      var chartArray = [
        "staff_accounting",
      ]
    } else if (this.current_contract.role == `investment`) {
      var chartArray = [
        "staff_investment",
      ]
    } else if (this.current_contract.role == `protection`) {
      var chartArray = [
        "staff_protection",
      ]
    } else if (this.current_contract.role == `legal`) {
      var chartArray = [
        "staff_legal",
      ]
    } else if (this.current_contract.role == `customer_success`) {
      var chartArray = [
        "staff_customer_success",
      ]
    } else if (this.current_contract.role == `performance`) {
      var chartArray = [
        "staff_performance",
      ]
    } else if (this.current_contract.role == `administrative`) {
      var chartArray = [
        "staff_administrative",
      ]
    } else if (this.current_contract.role == `board`) {
      var chartArray = [
        "staff_board",
        "staff_technology",
        "staff_accounting",
        "staff_customer_success",
        "people_sales",
        "people_marketing",
      ]
    } else if (this.current_contract.role == `general`) {
      var chartArray = [
        "staff_general",
      ]
    } else if (this.current_contract.role == `sales`) {
      var chartArray = [
        "people_sales",
      ]
    } else if (this.current_contract.role == `marketing`) {
      var chartArray = [
        "people_marketing",
      ]
    } else if (this.current_contract.role == `biling`) {
      var chartArray = [
        "staff_biling",
      ]
    }

    return chartArray.includes(chartKind)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
