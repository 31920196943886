import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewSquadCard", "viewTitle", "viewSquadCardBody", "previewCard", "editSquadBtn", "deleteSquadBtn", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--activities--squads--show`
    this.doViewHtml()
  }

  doViewHtml() {
    if (this.getControllerByIdentifier("users--works--activities--squads--save")) {
      this.getControllerByIdentifier("users--works--activities--squads--save").stopRefreshing()
    }
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewSquadCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Squad Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewSquadCardBody"></div>
                </div>`

    this.getControllerByIdentifier("users--works--activities--squads--dashboard").viewTarget.innerHTML = html
  }

  editSquad() {
    this.getControllerByIdentifier("users--works--activities--squads--save").current_squad = this.current_squad
    this.getControllerByIdentifier("users--works--activities--squads--save").actionMode = "edit"
    this.getControllerByIdentifier("users--works--activities--squads--save").doFormHtml()
  }

  deleteSquad() {
    this.send_data = { current_user: {}, squad: {} }
    var r = confirm("Tem certeza que deseja apagar a Squad?")
    this.deleteSquadBtnTarget.disabled = true

    if (r) {
      this.actionMode = "delete"

      this.send_data.squad.id = this.current_squad.id
      this.send_data.squad.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.requestDestroy()
    } else {
      this.deleteSquadBtnTarget.disabled = false
    }

  }

  requestDestroy() {
    var url = "/users/works/activities/squads/destroy"
    var method = "DELETE"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var squad = response.data.cln
          if (squad.active) {
            controller.application.squads.forEach((element, i) => {
              if (element.id == squad.id) {
                controller.application.squads.splice(controller.application.squads.indexOf(element), 1, squad)
              }
            })
          } else {
            controller.application.squads.forEach((element, i) => {
              if (element.id == squad.id) {
                controller.application.squads.splice(controller.application.squads.indexOf(element), 1)
              }
            })
          }
          controller.getControllerByIdentifier("users--works--activities--squads--index").doDataTable()
          controller.doViewHtml()
          controller.send_data = { current_user: {}, squad: {} }
        } else {
          controller.deleteSquadBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }

  goToSquad(ev) {
    var url = ev.currentTarget.dataset.url

    window.open(url, `_blank`)
  }


  doSquadHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.user_work_activity_squads.can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteSquadBtn" data-action="click->${this.controllerName}#deleteSquad" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Squad</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.user_work_activity_squads.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editSquadBtn" data-action="click->${this.controllerName}#editSquad" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Squad</span>`
    } else {
      var editBtnHtml = ``
    }

    var boardHtml = ``
    this.current_squad.board_types_pretty.forEach(board => {
      if (board) {
        boardHtml += `<p class="mb-0 f-065">${board}</p>`
      } else {
        boardHtml += `Sem Boards`
      }
    })

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Squad</h6>
                    <div class="card-actions py-0 mc-tooltip ml-auto">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Líder:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_squad.leader_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Closer:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_squad.team_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Boards:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${boardHtml}
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--activities--squads--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
