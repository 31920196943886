import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "cardBody", "footerTable", "addOfficeBtn", "viewCard", "viewTitleCard", "viewCardBody", "saveBtn", "bodyTable",
                    "nameInput", "zipInput", "streetInput", "numberInput", "complementInput", "districtInput", "stateFilter", "cityFilter",
                    "stateDropdown", "stateDropdownBtn", "stateInput", "stateList", "cityDropdown", "cityDropdownBtn", "cityInput", "cityList"]




  connect() {
    this.controllerName = `operations--products--bookings--personalizations--offices`
    this.send_data = { current_user: {}, office: {} }
    this.doOfficesListHtml()
  }

  addOffice() {
    this.actionMode = "new"
    this.doOfficeHtml()
  }

  editOffice(ev) {

    this.application.offices.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_office = element
      }
    });

    this.actionMode = "edit"
    this.doOfficeHtml()
  }

  deleteOffice(ev) {
    this.application.offices.forEach(element => {
      if (element.id == ev.currentTarget.closest(".itemRow").dataset.id) {
        this.current_office = element
      }
    });

    this.actionMode = "edit"
    this.send_data.office.id = this.current_office.id
    this.send_data.office.active = false
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  cancelSave() {
    this.send_data = { current_user: {}, office: {} }
    this.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showOfficeTarget.innerHTML = ``
  }

  saveOffice() {
    if (this.actionMode == "edit") { this.send_data.office.id = this.current_office.id }
    this.send_data.office.name = this.nameInputTarget.value
    this.send_data.office.postal_code = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value)
    this.send_data.office.street = this.streetInputTarget.value
    this.send_data.office.number = this.numberInputTarget.value
    this.send_data.office.complement = this.complementInputTarget.value
    this.send_data.office.district = this.districtInputTarget.value
    // this.send_data.office.city = this.cityInputTarget.dataset.city
    this.send_data.office.city = this.cityInputTarget.dataset.filter
    // this.send_data.office.state = this.stateInputTarget.dataset.state
    this.send_data.office.state = this.stateInputTarget.dataset.filter
    this.send_data.office.country = `Brasil`
    this.send_data.office.booking_id = this.application.booking.id
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/bookings/offices/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/bookings/offices/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var office = response.data.cln
          if (controller.actionMode == "new") {
            controller.application.offices[controller.application.offices.length] = office
          } else if (controller.actionMode == "edit") {
            if (office.active) {
              controller.application.offices.forEach((element, i) => {
                if (element.id == office.id) {
                  controller.application.offices.splice(controller.application.offices.indexOf(element), 1, office)
                }
              })
            } else {
              controller.application.offices.forEach((element, i) => {
                if (element.id == office.id) {
                  controller.application.offices.splice(controller.application.offices.indexOf(element), 1)
                }
              })
            }
          }
          controller.doTableHtml()
          controller.send_data = { current_user: {}, office: {} }
          controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showOfficeTarget.innerHTML = ``
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doOfficeHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="viewTitleCard"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="viewCardBody">
                    <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                      <tbody>
                        <tr class="itemRow">
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                        </tr>
                        <tr class="itemRow">
                          <td colspan="7" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="nameInput">Nome</label>
                              <input id="nameInput" data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="zipInput">CEP</label>
                              <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                            </div>
                          </td>
                        </tr>
                        <tr class="itemRow">
                          <td colspan="5" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="streetInput">Logradouro</label>
                              <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="numberInput">Número</label>
                              <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="complementInput">Complemento</label>
                              <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                        </tr>
                        <tr class="itemRow">
                          <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="districtInput">Bairro</label>
                              <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="stateForm">UF</label>
                              <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                                <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                                <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                                <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                                <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                                <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                                <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                                <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                                <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                                <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                                <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                                <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                                <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                                <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                                <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                                <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                                <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                                <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                                <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                                <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                                <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                                <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                                <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                                <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                                <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                                <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                                <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                                <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                              </ul>
                            </div>
                          </td>
                          <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <div class="floating-label floating-label-sm">
                              <label for="cityForm">Cidade</label>
                              <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer py-1 text-right">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveOffice" data-${this.controllerName}-target="saveBtn">Salvar</button>
                  </div>
                </div>`


                // `<div class="floating-label floating-label-sm" data-controller="app--helpers--search">
                //   <label>UF</label>
                //   <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="stateDropdown">
                //     <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-${this.controllerName}-target="stateDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-${this.controllerName}-target="stateInput"></span></button>
                //     <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                //       <input class="form-control form-control-selector f-065" type="text" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" placeholder="Buscar ...">
                //       <ul class="ul-select" data-${this.controllerName}-target="stateList" data-app--helpers--search-target="searchList">
                //         <li data-state="MG" data-action="click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                //         <li data-state="SP" data-action="click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                //         <li data-state="AC" data-action="click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                //         <li data-state="AL" data-action="click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                //         <li data-state="AP" data-action="click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                //         <li data-state="AM" data-action="click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                //         <li data-state="BA" data-action="click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                //         <li data-state="CE" data-action="click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                //         <li data-state="DF" data-action="click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                //         <li data-state="ES" data-action="click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                //         <li data-state="GO" data-action="click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                //         <li data-state="MA" data-action="click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                //         <li data-state="MT" data-action="click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                //         <li data-state="MS" data-action="click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                //         <li data-state="PA" data-action="click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                //         <li data-state="PB" data-action="click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                //         <li data-state="PR" data-action="click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                //         <li data-state="PE" data-action="click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                //         <li data-state="PI" data-action="click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                //         <li data-state="RJ" data-action="click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                //         <li data-state="RN" data-action="click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                //         <li data-state="RS" data-action="click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                //         <li data-state="RO" data-action="click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                //         <li data-state="RR" data-action="click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                //         <li data-state="SC" data-action="click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                //         <li data-state="SE" data-action="click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                //         <li data-state="TO" data-action="click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                //       </ul>
                //     </div>
                //   </div>
                // </div>`
                // `<div class="floating-label floating-label-sm" data-controller="app--helpers--search">
                //   <label>Cidade</label>
                //   <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="cityDropdown">
                //     <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-${this.controllerName}-target="cityDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-${this.controllerName}-target="cityInput"></span></button>
                //     <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                //       <input class="form-control form-control-selector dropdown-search-input f-065" type="text" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" placeholder="Buscar ...">
                //       <ul class="ul-select" data-${this.controllerName}-target="cityList" data-app--helpers--search-target="searchList"></ul>
                //     </div>
                //   </div>
                // </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").showOfficeTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.40) + "px"

      if (controller.actionMode == "new") {
        controller.viewTitleCardTarget.innerText = "Novo Consultório"
      } else if (controller.actionMode == "edit") {
        controller.viewTitleCardTarget.innerText = "Editando Consultório"
        controller.nameInputTarget.value = controller.current_office.name
        controller.zipInputTarget.value = controller.current_office.postal_code_pretty
        controller.streetInputTarget.value = controller.current_office.street
        controller.numberInputTarget.value = controller.current_office.number
        controller.complementInputTarget.value = controller.current_office.complement
        controller.districtInputTarget.value = controller.current_office.district
        // controller.cityDropdownTarget.value = controller.current_office.city
        // controller.cityInputTarget.innerText = controller.current_office.city
        // controller.cityInputTarget.dataset.city = controller.current_office.city
        controller.cityInputTarget.value = controller.current_office.city
        controller.cityInputTarget.dataset.filter = controller.current_office.city
        // controller.stateDropdownTarget.value = controller.current_office.state
        // controller.stateInputTarget.innerText = controller.current_office.state
        // controller.stateInputTarget.dataset.state = controller.current_office.state
        controller.stateInputTarget.value = controller.current_office.state
        controller.stateInputTarget.dataset.filter = controller.current_office.state

      }
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doOfficesListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Consultórios</h6>
                    <div class="card-actions ml-auto py-0">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 mc-tooltip" data-${this.controllerName}-target="addOfficeBtn" data-action="click->${this.controllerName}#addOffice" type="button">
                        <span class="material-icons">add</span>
                        <span class="mc-tooltiptext">Novo Consultório</span>
                      </button>
                    </div>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="cardBody" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--personalizations--dashboard").listOfficesTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.mainCardTarget.style.height = ($(window).height() * 0.25) + "px"
      controller.doTableHtml()
      if (!controller.application.permissions.medbooking_offices.can_create) {
        controller.addOfficeBtnTarget.remove()
      }
    })
  }

  doTableHtml() {
    var html = `<div class="table-responsive">
                  <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                    <tbody data-${this.controllerName}-target="bodyTable">
                    </tbody>
                  </table>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.listData(controller.application.offices)
    })
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="px-0 align-middle text-center" style="font-size:80%;">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-065 align-middle">Sem consultório cadastrado</span>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.rowTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  rowTablePartial(element, length) {
    if (this.application.permissions.medbooking_offices.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editOffice" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.medbooking_offices.can_delete) {
      var trash = `<button data-action="click->${this.controllerName}#deleteOffice" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else {
      var trash = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="table-45 p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="table-35 p-1 align-middle">${element.city}</td>
                    <td style="font-size:80%;" scope="col" class="table-10 p-1 align-middle text-center">${edit}</td>
                    <td style="font-size:80%;" scope="col" class="table-10 p-1 align-middle text-center">${trash}</td>
                  </tr>`

    return rowHtml
  }

  zipMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    // this.stateDropdownBtnTarget.disabled = false
    this.stateInputTarget.disabled = false
    // this.cityDropdownBtnTarget.disabled = false
    this.cityInputTarget.disabled = false

    this.send_data.office.postal_code = ``
    this.send_data.office.street = ``
    this.send_data.office.number = ``
    this.send_data.office.complement = ``
    this.send_data.office.district = ``
    this.send_data.office.city = ``
    this.send_data.office.state = ``
    this.send_data.office.country = ``
    this.send_data.office.ibge = ``
  }

  searchZip(ev) {

    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
                controller.send_data.office.street = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
                controller.send_data.office.district = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                // controller.stateDropdownTarget.value = dados.uf
                // controller.stateInputTarget.innerText = dados.uf
                // controller.stateInputTarget.dataset.state = dados.uf
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
                controller.send_data.office.state = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                // controller.cityDropdownTarget.value = dados.localidade
                // controller.cityInputTarget.innerText = dados.localidade
                // controller.cityInputTarget.dataset.city = dados.localidade
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
                controller.send_data.office.city = dados.localidade
              }
              if (dados.ibge === "") {
                controller.ibgeInputTarget.disabled = false
              } else {
                controller.send_data.office.ibge = dados.ibge
              }
              controller.send_data.office.country = `Brasil`
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        controller.cleanForm();
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard", "viewCard"]
    var identifier = this.identifier
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
