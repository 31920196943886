import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "subTitle", "dates"]

  connect() {
    this.controllerName = `users--companies--entities--main`
    this.time_in = Date.now()
    this.application.canEmitInvoice = false
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.companies = []

    this.doCompanyGrid()

    // getCurrentUserPermissions
    // getCompany


  }

  doCompanyGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--companies--entities--dashboard
                                                            users--companies--entities--xxxxx
                                                            
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--companies--entities--dashboard").doCompaniesPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  setCompanyName() {
    var html = `<span class="mb-0 mc-tooltip pointer">
                  <strong>
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </strong>
                </span>
                <span> | CNPJ: </span>
                <span class="mb-0 mc-tooltip pointer">
                  <strong>
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </strong>
                </span>`

    this.clinicNameTarget.innerHTML = html
  }

  getCompanies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Empresas | PJ's Médicas`, value: 20 })

    const data = { company: { active: true, kind: `clinic` }, current_user: { current_user_id: this.application.current_user.id, feature: "company_entities" } }
    const url = "/users/companies/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.companies = response.data.cln

        controller.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
        controller.getControllerByIdentifier("users--companies--entities--submenu").kindLoaded.clinic = true
        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `company_entities` }, current_user: { current_user_id: this.application.current_user.id, feature: `company_entity_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    // const data = { permissions: { subdomain: `company_entities` }, current_user: { current_user_id: this.application.current_user.id, feature: `company_entity_permissions`, account_kind: this.application.current_user.account_kind } }
    // const url = "/users/permissions/entities/list_by_policies"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          });
          controller.getControllerByIdentifier(`users--companies--entities--submenu`).doSubmenuHtml()
          controller.application.domain_permission = controller.application.permissions[`company_entity_permissions`]

          controller.getCompanies()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}