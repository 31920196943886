import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--footer`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)
    } else if (this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--commercial--marketing--content--search--main`)
    } else if (this.getControllerByIdentifier(`mobile--commercial--marketing--events--publics--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--commercial--marketing--events--publics--main`)
    }

  }

  doHtml() {
    var serviceHtml = ``
    var colClass = ``
    var colLogoClass = ``
    var current_services = []

    this.application.site.services.forEach(service => {
      if (service.status) {
        current_services[current_services.length] = service
      }
    })
    current_services = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_services, `order`)

    current_services.forEach(service => {
      var link = this.siteController.setLink(service.link_footer)

      serviceHtml += `<p> <a class="ws-text-footer pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${service.title}</a> </p>`
    })

    colClass += `col-12 text-center my-3`
    colLogoClass += `col-12 text-center my-5`

    //<p> <a class="ws-text-footer pointer" href="/vagas" target="blank">Faça parte do time</a> </p>

    var html = `<section class="mws-footer">
                  <div class="container">
                    <div class="row">
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Serviços</h1>
                          ${serviceHtml}
                      </div>
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Contatos</h1>
                        <p> <a class="ws-text-footer pointer" href="mailto:atendimento@medcapital.com.br?Subject=Olá!">atendimento@medcapital.com.br</a> </p>
                        <p> <a class="ws-text-footer pointer" data-action="click->${this.controllerName}#goToWhatsApp">Converse conosco no WhatsApp</a> </p>
                        
                      </div>
                      <div class="${colClass}">
                        <h1 class="ws-title-footer">Conteúdos</h1>
                        <p> <a class="ws-text-footer" target="blank" href="/descanso-medico">Descanso Médico</a> </p>
                        <p> <a class="ws-text-footer" target="blank" href="/medscholl">MedSchool</a> </p>
                      </div>
                      <div class="${colLogoClass}">
                        <a class="navbar-brand mr-5 " href="/">
                          <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                        </a>
                      </div>
                    </div>
                    <div class="ws-cop">
                      <div class="row">
                        <p class="ws-cop-text"> Copyright © 2016-2024 MedCapital CNPJ 23.448.747/0001-02. Todos os direitos reservados. </p> <br>
                        <p class="ws-cop-text"> Feito com <span class="material-icons md-danger">favorite</span> e <span class="material-icons-two-tone">coffee</span> em Belo Horizonte-MG. </p>
                        <div>
                          <a class="mws-icon-footer" href="/descanso-medico" target="blank"><img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/Prancheta+1.png" alt="descancomedico" style="width:7%;cursor:pointer;"></a>
                          <a class="mws-icon-footer" href="https://www.facebook.com/medcap/" target="blank"><i class="fab fa-facebook-f white-text mr-2"> </i> </a>
                          <a class="mws-icon-footer" href="https://www.linkedin.com/company/medcapital/" target="blank"><i class="fab fa-linkedin-in white-text mr-2"> </i> </a>
                          <a class="mws-icon-footer" href="https://www.instagram.com/medcapital/" target="blank"><i class="fab fa-instagram white-tex mr-2"> </i> </a>
                          <a class="mws-icon-footer" href="https://api.whatsapp.com/send?phone=5531995181912&amp;text=Olá!" target="blank"><i class="fab fa-whatsapp white-text"></i> </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`

    this.siteController.contentTarget.insertAdjacentHTML("beforeend", html)
  }

  goToWhatsApp() {
    var url = `https://api.whatsapp.com/send?phone=5531995181912&text=Olá!`
    window.open(url, `_blank`)
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
