import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--entities--dates`
  }

  changeDate(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var id = ev.target.dataset.id
      this.setBookingDate(id)
    }
  }

  setBookingDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        if (this.application.current_date.open == false) {
          this.application.current_date.message = `A Competência está fechada`
        }
      }
    });

    this.application.booking_calculations.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_booking_calculation = element
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }
    this.cleanTaxReturnForms()
    this.runDashboardHtml()
    this.runReceiptsHtml()
    this.runPaymentsHtml()
    this.runProvisionsHtml()
    this.getControllerByIdentifier("operations--products--bookings--entities--show").doSubtitle()
    // this.runAchievementsHtml()
    // this.runMembersHtml()
  }

  cleanTaxReturnForms() {
    if (this.getControllerByIdentifier("operations--products--bookings--receipts--save")) {
      this.getControllerByIdentifier("operations--products--bookings--receipts--save").cancelSave()
    }
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--entities--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--entities--dashboard").reconnect()
    }
  }

  runReceiptsHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doReceiptsDashboard()
    }
  }

  runPaymentsHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").doPaymentsDashboard()
    }
  }

  runProvisionsHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--provisions--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--provisions--dashboard").doProvisionsDashboard()
    }
  }

  runAchievementsHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--achievements--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--achievements--dashboard").doAchievementsDashboard()
    }
  }

  runMembersHtml() {
    if (this.getControllerByIdentifier("operations--products--bookings--settings--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--bookings--settings--members").doMembersListHtml()
    }
  }

  doCalendarHTML() {
    var date = new Date()
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Competência</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="dateList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.current_date) { 
        controller.dateDropdownTarget.value = controller.application.current_date.name
        controller.dateInputTarget.innerText = controller.application.current_date.name
        controller.doDateList()
      } else {
        controller.dateDropdownTarget.value = "Favor Adicionar uma Competência"
        controller.dateInputTarget.innerText = "Favor Adicionar uma Competência"
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doDateList() {
    var listHtml = ``
    this.application.dates.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-year="${element.year}" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeDate" class="li-selector dark f-065">${element.name}</li>`
    });
    this.dateListTarget.innerHTML = listHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
