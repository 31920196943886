import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "purchasesCard", "pipelinesCard", "doneCard", "purchasesRow", "pipelinesRow", "donesRow",
    "calculationsCard", "calculationsRow", "calculationDateRow"]

  connect() {
    this.controllerName = `operations--ongoings--customers--reports--main`

    this.calculation = {}
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doMainGrid() {
    var html = ``

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--ongoings--customers--index--main`).contentTarget.innerHTML = html)
    }).then(() => {

    })
  }

  toggleOnboard(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "purchase") {
      this.purchasesCardTarget.classList.add("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "done") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.add("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--dones").doDoneReportHTML()
    } else if (feature == "pipeline") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.add("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--pipeline").doPipelineReportHTML()
    } else if (feature == "calculation") {
      this.purchasesCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.add("bg-primary")
      this.purchasesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

      this.getControllerByIdentifier("operations--onboards--reports--calculations").doCalculationReportHTML()
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
