import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "balanceTitle", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--balances--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 150
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `token` }
    this.hasShow = false
    this.tableCols = 10
  }

  editBalance(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.balances.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--managements--balances--save").current_balance = element
      }
    })
    this.getControllerByIdentifier("operations--products--clinics--managements--balances--save").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--managements--balances--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  refreshBalances() {
    var element = this.bodyTableTarget
    var cols = this.tableCols
    this.getControllerByIdentifier(`operations--products--clinics--managements--balances--dashboard`).refreshBalances(element, cols)
  }

  destroyBalancesEv() {
    var r = confirm(`Tem certeza que deseja apagar todas as Contas de Balanço da ${this.application.current_date.name}?`)
    if (r) {
      this.destroyBalances()    
    }
  }

  destroyBalances() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 10)
    this.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).canSetClosing = false
    this.send_data = { current_user: {}, balance: {} }

    this.send_data.balance.clinic_id = this.application.clinic.id
    this.send_data.balance.date_id = this.application.current_date.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_balances`

    var url = "/operations/products/clinics/financials/balances/destroy_by_date"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.balances.forEach(element => {
            if (element.date_id == this.application.current_date.id) {
              element.data = response.data.cln.collection
            }
          })  
          
          controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).canSetClosing = true
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doIndexListHtml() {

    if (this.application.permissions.medclinic_management_balances.can_download) {
      var downloadLine = `<span data-action="click->${this.controllerName}#doDownloadModalHtml" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Contas de Balanço</span>`
    } else {
      var downloadLine = ``
    }

    if (this.application.permissions.medclinic_management_balances.can_delete) {
      var destroyLine = `<span data-action="click->${this.controllerName}#destroyBalancesEv" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Todas as Contas de Balanço</span>`
    } else {
      var destroyLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshBalances" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contas de Balanço</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                          ${downloadLine}
                          ${destroyLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="balanceTitle"></h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-30 align-middle">
                                Conta de Balanço
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="chart_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Grupo de Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Saldo Inicial</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Origem</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Destino</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Saldo Final</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Tipo de Conta</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--balances--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
      controller.balanceTitleTarget.innerHTML = `Contas de Balanço e Resultado ${controller.application.current_date.name}`
    })
  }

  doDataTable() {
    var current_balances = []
    // var current_balances = this.application.balances

    this.application.balances.forEach(balance => {
      if (this.application.current_date.id == balance.date_id) {
        current_balances = balance.data
      }
    })

    if (this.sort.mode == `asc`) {
      var balances = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_balances, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var balances = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_balances, this.sort.field)
    }

    if (balances.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(balances.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (balances[x] !== undefined) {
            internPage[internPage.length] = balances[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: balances.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contas de Balanço</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.balanceTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  balanceTablePartial(element, length) {

    // if (this.application.permissions.medclinic_management_balances.can_update) {
    if (this.application.current_user.super_admin) {
      var edit = `<button data-action="click->${this.controllerName}#editBalance" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.clinic_name}</td>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.chart_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.group_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.master_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.previous_balance)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.from_amount)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.to_amount)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      ${this.controllerNumber.currencyOptionMask(element.balance)}
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doDownloadModalHtml(ev) {
    var modalController = `operations--products--clinics--managements--balances--download-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Escolha a linguagem para Formatar os Números</strong></h6><br>
                      </div>
                      <div class="modal-body p-3">
                        <div class="row my-2">
                          <div class="col-6">
                            <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-lang="pt" data-${modalController}-target="langBtn" data-action="click->${modalController}#chooseLang">Português</button>
                          </div>
                          <div class="col-6">
                            <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-lang="en" data-${modalController}-target="langBtn" data-action="click->${modalController}#chooseLang">Inglês</button>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.getControllerByIdentifier(modalController).open()
    })

    // document.body.insertAdjacentHTML("beforeend", html)
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
