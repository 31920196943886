
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dayBtn", "totalBillings", "payableCount", "payableCountTooltip"]

  connect() {
    this.controllerName = `financials--books--payables--entities--days`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doMenuHtml() {

    var daysHtml = ``
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)

    for (var day = 1; day <= lastDay; day++) {

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, day)
      var weekDay = date.getDay()
      var weekDayName = this.controllerDate.getWeekDay(weekDay)

      daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn payableCount-${day}">
                    ${day}-${this.application.current_date.month_short}
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
                  </button>`

    }
    var html = `<div class="btn-group btn-group-sm" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${daysHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payables--entities--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      if (controller.application.current_date.year == date.getFullYear() && controller.application.current_date.month == (date.getMonth() + 1)) {
        var day = date.getDate()
        controller.current_day = date.getDate()
      } else {
        var day = 1
        controller.current_day = 1
      }
    })
  }

  doBadgeCount() {
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)
    var day = this.current_day
    var currentFilterPayables = []
    var currentPayables = []

    for (var indexDay = 1; indexDay <= lastDay; indexDay++) {
      if (this.nameTarget(`totalPayables-${indexDay}`)) {
        this.nameTarget(`totalPayables-${indexDay}`).remove()
      }
    }

    this.application.payables.forEach(element => {
      if (this.application.current_med.id) {
        if (element.due_date_id == this.application.current_date.id && element.med_id == this.application.current_med.id) {
          currentPayables[currentPayables.length] = element
        }
      } else {
        if (element.due_date_id == this.application.current_date.id) {
          currentPayables[currentPayables.length] = element
        }
      }
    })

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (day < 10) {
      var currentDay = `0${day}`
    } else {
      var currentDay = `${day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    currentPayables.forEach(element => {
      if (element.due_date_token == currentToken) {
        currentFilterPayables[currentFilterPayables.length] = element
      }
    })

    var paidPayables = 0
    currentFilterPayables.forEach(payable => {
      if (payable.paid) {
        paidPayables += 1
      }
    })

    if (this.nameTarget(`payableCount-${day}`)) {
      var payableBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalPayables-${day}">${Number(currentFilterPayables.length) - paidPayables}</span>`
      if (this.nameTarget(`totalPayables-${day}`)) {
        this.nameTarget(`totalPayables-${day}`).remove()
      }
      this.nameTarget(`payableCount-${day}`).insertAdjacentHTML("beforeend", payableBadgeHtml)
    }
  }

  goToDayEv(ev) {
    var day = ev.currentTarget.dataset.day

    this.current_day = day
    this.doBadgeCount()
    this.goToDay()
  }

  goToDay() {
    
    this.getControllerByIdentifier(`financials--books--payables--entities--index`).dayFilter = true
    this.getControllerByIdentifier(`financials--books--payables--entities--index`).current_day = this.current_day
    this.getControllerByIdentifier(`financials--books--payables--entities--index`).doDataTable()

  }

  toggleBtn(ev) {

    this.dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  doMenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payables--entities--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      controller.doMenuHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshLoadValue(day) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()
        // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        var response = { type: `danger`, message: `Tivemos um problema para carrega as Faturas` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 500);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
