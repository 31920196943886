import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.notes = []
    this.controllerName = `users--works--meetings--boards--notes`
    this.canRequest = true
  }

  setNotes() {
    var html = ``
    this.current_notes = []
    this.application.notes.forEach(element => {
      if (element.domain_id == this.application.current_weekly.id) {
        this.current_notes[this.current_notes.length] = element
      }
    })
    var notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_notes, `date_time`)

    if (notes.length == 0) {
      var html = `<div class="row w-100" style="height:100px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Anotações</span>
                      </div>
                    </div>`

      this.listTarget.innerHTML = html
    } else {
      notes.forEach(element => {

        if (this.application.permissions.work_meeting_notes.can_delete && this.userCanUpdate(element.id)) {
          var deleteBtn = `<button data-action="click->${this.controllerName}#destroyNote" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar</span>
                        </button>`
        } else {
          var deleteBtn = ``
        }

        if (this.application.permissions.work_meeting_notes.can_update) {
          var editBtn = `<button data-action="click->${this.controllerName}#editNote" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">edit</span>
                          <span class="mc-tooltiptext">Editar</span>
                        </button>`
        } else {
          var editBtn = ``
        }

        html += `<div class="row my-2 w-100 noteCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-header p-0 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-9 pl-2 pr-0 d-flex align-items-center">
                            <div class="card-show-dropdown w-100">
                              ${element.title}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065"><strong>Em ${element.date_pretty}, ${element.owner_name} adicionou a seguinte nota:</strong></p> 
                                <div class="trix-content px-3">
                                  ${element.body}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-1">
                            ${editBtn}
                          </div>
                          <div class="col-1">
                            ${deleteBtn}
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-0 f-065 pointer d-none" data-${this.controllerName}-target="editBody-${element.id}">
                        <div class="row my-2">
                          <div class="col-4 pr-2">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dateForm-${element.id}">Data</label>
                                <input disabled aria-describedby="dateFormHelp-${element.id}" class="form-control form-valid-control" id="dateForm-${element.id}" data-${this.controllerName}-target="dateInput-${element.id}" placeholder="Data" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="titleForm-${element.id}">Título</label>
                                <input aria-describedby="titleFormHelp-${element.id}" class="form-control form-valid-control" id="titleForm-${element.id}" data-${this.controllerName}-target="titleInput-${element.id}" placeholder="Título" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <input aria-describedby="notesFormHelp-${element.id}" type="hidden" class="form-control" id="notesForm-${element.id}" placeholder="Texto" data-${this.controllerName}-target="bodyInput-${element.id}">
                                <trix-editor input="notesForm-${element.id}" data-trix="true" data-field="description" class="trix-content trix-med trix-editor-weekly textarea p-1 f-075" rows="15" placeholder="Faça aqui as anotações ... " data-${this.controllerName}-target="bodyInputTrix-${element.id}"></trix-editor>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1 d-none" data-${this.controllerName}-target="editFooter-${element.id}">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveNote" data-${this.controllerName}-target="saveBtn-${element.id}">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`
      })

      this.listTarget.innerHTML = html
    }
  }

  cancelSave(ev) {
    var id = ev.currentTarget.closest(".noteCard").dataset.id
    this.nameTarget(`editBody-${id}`).classList.add("d-none")
    this.nameTarget(`editFooter-${id}`).classList.add("d-none")
  }

  editNote(ev) {
    var id = ev.currentTarget.closest(".noteCard").dataset.id
    var note = {}
    this.application.notes.forEach(element => {
      if (element.id == id) {
        note = element
      }
    })

    this.nameTarget(`editBody-${id}`).classList.remove("d-none")
    this.nameTarget(`editFooter-${id}`).classList.remove("d-none")
    this.nameTarget(`dateInput-${id}`).value = note.date_pretty
    this.nameTarget(`titleInput-${id}`).value = note.title
    this.nameTarget(`bodyInputTrix-${id}`).value = note.body

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  createNote() {

    if (this.canRequest) {
      this.actionMode = `new`
      this.canRequest = false
      this.send_data = { current_user: {}, note: {}, notification: {} }

      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_id = this.application.current_weekly.id
      this.send_data.note.domain_type = `work_meetings`
      this.send_data.note.domain_name = this.application.current_weekly.name
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `minutes`
      this.send_data.note.date = new Date()
      this.send_data.note.body = `Nova Anotação`
      this.send_data.note.title = `Nova Anotação`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `work_meeting_notes`

      this.requestSave()
    }
  }

  saveNote(ev) {
    this.send_data = { current_user: {}, note: {}, notification: {} }
    var id = ev.currentTarget.closest(".noteCard").dataset.id

    if (this.canRequest) {
      this.actionMode = `edit`
      this.canRequest = false
      this.send_data.note.id = id
      this.send_data.note.title = this.nameTarget(`titleInput-${id}`).value
      this.send_data.note.body = this.nameTarget(`bodyInput-${id}`).value
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `work_meeting_notes`

      this.requestSave()
    }
  }

  destroyNote(ev) {
    this.send_data = { current_user: {}, note: {}, notification: {} }
    var id = ev.currentTarget.closest(".noteCard").dataset.id

    if (this.canRequest) {
      this.actionMode = `edit`
      this.canRequest = false
      this.send_data.note.id = id
      this.send_data.note.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `work_meeting_notes`

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/accounts/notes/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/accounts/notes/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var note = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.notes[controller.application.notes.length] = note
          } else {
            if (note.active) {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1, note)
                }
              })
            } else {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1)
                }
              })
            }
          }
          controller.setNotes()
          controller.canRequest = true
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  userCanUpdate(noteId) {
    var note = {}
    this.application.notes.forEach(element => {
      if (element.id == noteId) {
        note = element
      }
    })

    var allowed = [note.owner_id]
    if (allowed.includes(this.application.current_user.account_id)) {
      return true
    } else {
      return false
    }
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
