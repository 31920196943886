import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--helpers--fetch-response`
  }

  catchError(error, message, controller) {
    // error.text() // 5
    //   .then(function (bodyText) {
    //     console.log('Received the following instead of valid JSON:', bodyText); // 6
    //   });
    if (message) {
      var message = message
    } else {
      var message = this.getControllerByIdentifier("app--helpers--messages").generalError()
    }

    if (controller && controller.hasSaveBtnTarget) {
      controller.saveBtnTarget.disabled = false
    }

    this.getControllerByIdentifier("app--helpers--console").console(error)
    this.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
    this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 4000)
  }

  finalResponse(response, time = 5000) {

    if (time == undefined) {
      var time = 5000
    }

    // if (controller && controller.hasSaveBtnTarget) {
    //   controller.saveBtnTarget.disabled = false
    // }

    this.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
    if (response) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, time)
      setTimeout(() => {
        if (response.api_reload_page) {
          window.location.reload()
        }
      }, 1500)

      if (response.redirected) {
        window.open(response.url, `_self`)
      }

    }
    
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}
