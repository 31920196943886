import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--activities--squads--dashboard`
    // this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-4">
                  <div class="col-6 px-2" data-${this.controllerName}-target="list" data-controller="users--works--activities--squads--index"></div>
                  <div class="col-4 px-2" data-${this.controllerName}-target="view" data-controller="users--works--activities--squads--show users--works--activities--squads--save"></div>
                </div>`

    // this.mainTarget.innerHTML = html
    this.getControllerByIdentifier(`users--works--activities--tickets--main`).contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
