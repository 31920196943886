import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `web--site--principal--frames--preloader`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  connect() {
    this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.spinHour()
    })
  }

  close() {
    // this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doHtml() {
    var html = `<header>
                  <nav class="navbar navbar-expand-lg ws-nav-bar">
                    <div class="container">
                      <a class="navbar-brand mr-5 w-100" href="/">
                        ${this.loader}
                      </a>
                      <div class="collapse navbar-collapse">
                        <ul class="navbar-nav w-100">
                          <li class="nav-item w-100">
                            ${this.loader}
                          </li>
                        </ul>
                        <div>
                          <ul class="navbar-nav d-flex align-items-center">
                            
                            <a class="ws-button" href="">${this.loader}</a>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </header>
                <section class="ws-section-principal ws-bg-circle d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex align-items-center col-lg-5 col-sm-12">
                        <div class="columns w-100">
                          ${this.loader}
                          ${this.loader}
                          ${this.loader}
                          ${this.loader}
                        </div>
                      </div>
                      <div class="col-lg-7 col-sm-12 d-flex justify-content-center align-items-center">
                        ${this.loader}
                      </div>
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
