
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `financials--products--purchases--dashboard--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.dataLoaded = { clinic: false }
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("financials--products--purchases--dashboard--index").goToDashboard()
    // this.getControllerByIdentifier(`financials--products--purchases--dashboard--index`).doIndexListHtml()
    // this.getControllerByIdentifier(`financials--products--purchases--dashboard--index`).doCalculation()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("financials--products--purchases--dashboard--main").contentTarget

    var subdomains = [
                       { name: `financial_purchases`, name_pretty: `Produtos - Contratações` },
                       { name: `financial_commissions`, name_pretty: `Produtos - Comissões` },
                     ]

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).has_subdomains = true
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomains = subdomains
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = `financial_products`
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)
    this.getControllerByIdentifier(`financials--products--purchases--dashboard--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  goToReports() {
    this.getControllerByIdentifier(`financials--products--purchases--dashboard--reports`).doMainDashboard()
  }

  goToCommission() {
    this.getControllerByIdentifier(`financials--products--commissions--entities--dashboard`).doCommissionDashboard()
  }

  goToPolicies() {
    this.getControllerByIdentifier(`financials--products--commissions--policies--dashboard`).doPoliciesDashboard()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contratações</button>`

    if (this.application.permissions.financial_purchase_permissions.can_assign) {
      this.application.domain_permission = this.application.permissions[`financial_purchase_permissions`]
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    if (this.application.permissions.financial_commission_entities.can_index) {
      var commissionsBtn = `<button type="button" data-action="${this.controllerName}#goToCommission ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Comissões</button>`
    } else {
      var commissionsBtn = ``
    }

    if (this.application.permissions.financial_commission_policies.can_manage) {
      var policiesBtn = `<button type="button" data-action="${this.controllerName}#goToPolicies ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Políticas</button>`
    } else {
      var policiesBtn = ``
    }

    // if (this.application.permissions.operations_main_dashboard_reports.can_index) {
    //   var reportBtn = `<button type="button" data-action="${this.controllerName}#goToReports ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Relatórios</button>`
    // } else {
    //   var reportBtn = ``
    // }
    var reportBtn = ``

    var html = `<div class="btn-group ml-auto" role="group" style="box-shadow:none">
                  ${dashboardBtn}
                  ${commissionsBtn}
                  ${policiesBtn}
                  ${reportBtn}
                  ${permissionBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
