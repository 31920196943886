import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "view", "list", "subdomains", "5555", "submenuBtn", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--permissions--policies--dashboard`
  }

  doPoliciesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 pr-1" data-${this.controllerName}-target="subdomains" data-controller="users--works--permissions--subdomains--list"></div>
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--permissions--policies--index"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--permissions--policies--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--permissions--entities--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--permissions--policies--index`).permission = `user_permissions_policies`
      controller.getControllerByIdentifier(`users--works--permissions--policies--save`).permission = `user_permissions_policies`
      controller.getControllerByIdentifier(`users--works--permissions--policies--index`).all_policies = false

      controller.getControllerByIdentifier(`users--works--permissions--policies--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--permissions--subdomains--list`).doListHtml()

      if (controller.application.permissions.user_permissions_policies.can_manage) {
        controller.getControllerByIdentifier(`users--works--permissions--policies--submenu`).doSubmenuHtml()
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
