import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "productAmount", "flowAmount", "team", "teamList", "teamDropdown", "teamInput", "teamChange"]

  connect() {
    this.controllerName = `commercial--dashboards--report`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reports = {}
  }

  doReportPage() {
    var productHtml = ``
    this.reports.report_by_product.forEach(report => {
      productHtml += `<div class="col-2 px-1 my-2">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-065">
                            <span>${report.name}</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer">
                            <span class="f-085">${report.total}</span><br>
                            <span class="f-085 f-bold">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount)}</span>
                          </div>
                        </div>
                      </div>`
    })

    var flowHtml = ``
    this.reports.report_by_flow.forEach(report => {
      flowHtml += `<div class="col-2 px-1 my-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-065">
                          <span>${report.name}</span>
                        </div>
                        <div class="card-body text-center py-2 mc-tooltip pointer">
                          <span class="f-085">${report.total}</span><br>
                          <span class="f-085 f-bold">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(report.amount)}</span>
                        </div>
                      </div>
                    </div>`
    })


    // this.getControllerByIdentifier(`commercial--dashboards--main`).reportTarget.innerHTML = html
    this.productAmountTarget.innerHTML = productHtml
    this.flowAmountTarget.innerHTML = flowHtml
  }

  doTeamHTML() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Time</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="teamDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="teamInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="teamList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.teamTarget.innerHTML = html)
    }).then(() => {
      controller.teamDropdownTarget.value = controller.application.current_user.name
      controller.teamInputTarget.innerText = controller.application.current_user.name
      controller.doTeamList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doTeamList() {
    var listHtml = ``
    listHtml += `<li data-app--helpers--selector-target="select" data-team="team" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">Todos</li>`
    this.application.sales_team.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-team="individual" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">${element.name}</li>`
    });

    this.teamListTarget.innerHTML = listHtml
  }

  changeTeam(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var team = ev.target.dataset.team
      if (team == "individual") {
        this.application.account_id = ev.target.dataset.id
        this.all_team = false
      } else if (team == "team") {
        this.application.account_id = null
        this.all_team = true
      }
      this.getControllerByIdentifier(`commercial--dashboards--main`).getReport()
    }
  }

  doReportGrid() {
    var html = `<div class="row my-4" data-${this.controllerName}-target="teamChange">
                  <div class="col-2 text-right d-flex align-items-center justify-content-end">
                    <span>Time</span>
                  </div>
                  <div class="col-4 text-left" data-${this.controllerName}-target="team"></div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center">Oportunidades por Produto</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row" data-${this.controllerName}-target="productAmount">
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center">Fluxo de Oportunidades</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row" data-${this.controllerName}-target="flowAmount">
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                  <div class="col-2">${this.cardLoader}</div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--dashboards--main`).reportTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.permissions.commercial_main_dashboard.can_manage == false) {
        controller.teamChangeTarget.remove()
      } else {
        controller.doTeamHTML()
      }
      controller.doReportPage()
    })
  }

  stageFilter(ev) {
    var stage = ev.currentTarget.dataset.stage
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).filter = stage
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).hasFilter = true
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doDataTable()

    this.indicatorHeaderTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });

    this.nameTarget(stage).classList.add(`bg-primary`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}