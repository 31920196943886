import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["listDomains", "domainCard", "searchInput", "domainRow"]

  connect() {
    this.controllerName = `users--works--permissions--subdomains--list`
    this.sort = { mode: `asc`, field: `humanize` }
  }

  doListHtml() {
    var html = ``
    var domains = []

    var htmlTitle = `<div class="row w-100">
                      <div class="col-12 px-0">
                        <h6 class="mb-0">Domínios</h6>
                        <hr class="mt-0">
                      </div>
                    </div>`

    var htmlSearch = `<div class="row mb-3 w-100">
                        <div class="col-12 px-0">
                          <input class="form-control f-070 w-100 mx-auto" data-${this.controllerName}-target="searchInput" data-action="keyup->${this.controllerName}#doSearch" placeholder="Buscar ..." type="text">
                        </div>
                      </div>`
      
    var currentDomains = []
    if (this.application.permissions.user_permissions_policies.can_manage) {
      currentDomains = this.application.subdomains
    } else {
      this.application.subdomains.forEach(subdomain => {
        //if (subdomain.account_kind.includes(`doctor`) || subdomain.account_kind.includes(`helper`)) {
        if (subdomain.humanize.includes(`Operações`)) {
          currentDomains[currentDomains.length] = subdomain
        }
      })
    }
    
    
    var orderedDomains = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(currentDomains, this.sort.field)
    orderedDomains.forEach(subdomain => {
      html += `<div class="row mb-2 w-100" data-${this.controllerName}-target="domainRow">
                <div class="col-12 px-0">
                  <div class="card" data-subdomain-id="${subdomain.id}" data-${this.controllerName}-target="card-${subdomain.id} domainCard" data-action="click->${this.controllerName}#showPolicies">
                    <div class="card-header pointer py-2">
                      <h6 class="mb-0 f-065">${subdomain.humanize}</h6>
                    </div>
                  </div>
                </div>
              </div>`
    })

    var listHtml = `${htmlTitle}
                    ${htmlSearch}
                    <div class="card-body py-1 px-1" data-${this.controllerName}-target="listDomains" style="overflow:auto;">
                      ${html}
                    </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--policies--dashboard").subdomainsTarget.innerHTML = listHtml)
    }).then(() => {
      controller.listDomainsTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  showPolicies(ev) {

    var subdomainId = ev.currentTarget.dataset.subdomainId
    this.application.subdomains.forEach(subdomain => {
      if (subdomain.id == subdomainId) {
        this.current_subdomain = subdomain
      }
    });

    this.getControllerByIdentifier("users--works--permissions--policies--index").current_subdomain = this.current_subdomain
    this.getControllerByIdentifier("users--works--permissions--policies--index").doDataTable()
    this.getControllerByIdentifier("users--works--permissions--policies--save").cancelSave()

    this.domainCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });

    ev.currentTarget.classList.add(`bg-primary`)
  }

  doSearch(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      ev.currentTarget.value = ""
    }

    var filter = ev.currentTarget.value.toUpperCase()
    var filterUnaccent = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    for (var i = 0; i < this.domainRowTargets.length; i++) {
      var h6 = this.domainRowTargets[i].getElementsByTagName("h6")
      var show = false
      for (var x = 0; x < h6.length; x++) {
        if (h6[x]) {
          var txtValue = h6[x].textContent || h6[x].innerText;
          var txtValueUnaccent = txtValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          if ((txtValue.toUpperCase().indexOf(filter) > -1) || (txtValueUnaccent.toUpperCase().indexOf(filterUnaccent) > -1)) {
            show = true;
          }
        }
      }
      if (show == true) {
        this.domainRowTargets[i].style.display = "";
        this.domainRowTargets[i].classList.add("filter-row")
      } else {
        this.domainRowTargets[i].style.display = "none";
        this.domainRowTargets[i].classList.remove("filter-row")
      }
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
