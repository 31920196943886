import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "boardDropdown", "boardDropdownBtn", "saveBtn", "boardInput", "boardList",
                    "squadLeaderInput", "leaderFilter", "squadTeamInput", "teamFilter", "squadBoardInput", "boardChips"]


  connect() {
    this.controllerName = `users--works--activities--squads--save`
    this.boards = []
  }

  cancelSave() {
    this.stopRefreshing()
    this.boards = []
    this.getControllerByIdentifier("users--works--activities--squads--show").doViewHtml()
    this.getControllerByIdentifier("users--works--activities--squads--index").cleanCheckBox()
  }

  saveSquad() {
    this.send_data = { current_user: {}, squad: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.squad.id = this.current_squad.id
    }
    this.send_data.squad.leader_id = this.squadLeaderInputTarget.dataset.filter
    this.send_data.squad.leader_name = this.squadLeaderInputTarget.dataset.text
    this.send_data.squad.team_id = this.squadTeamInputTarget.dataset.filter
    this.send_data.squad.team_name = this.squadTeamInputTarget.dataset.text
    this.send_data.squad.board_types = this.boards
    
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/activities/squads/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/activities/squads/update"
      var method = "PUT"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var squad = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.squads[controller.application.squads.length] = squad
          } else if (controller.actionMode == `edit`) {
            controller.application.squads.forEach((element, i) => {
              if (element.id == squad.id) {
                controller.application.squads.splice(controller.application.squads.indexOf(element), 1, squad)
              }
            })
          }
          controller.cancelSave()
          controller.getControllerByIdentifier("users--works--activities--squads--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.user_work_activity_squads.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSquad" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions.user_work_activity_squads.can_manage && this.application.current_user.account_kind == `admin`) {
      var meetingOption = `<li data-board="work_meetings" data-board-pretty="Semanais" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Semanais</li>`
    } else {
      var meetingOption = ``
    }

    

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-3">
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="leaderForm">Líder</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="squadLeaderInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#leaderFilter keyup->${this.controllerName}#leaderFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterLeader" type="text" placeholder="Líder" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="leaderFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="teamForm">Time</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="squadTeamInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#teamFilter keyup->${this.controllerName}#teamFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterTeam" type="text" placeholder="Time" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="teamFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 my-2 px-2">
                      <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label>Boards</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="boardDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="boardDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="boardInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="boardList" data-app--helpers--search-target="searchList">
                                <li data-board="operation_products" data-board-pretty="Produtos" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Produtos</li>
                                <li data-board="operation_onboards" data-board-pretty="Onboards" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Onboards</li>
                                <li data-board="sales_opportunities" data-board-pretty="Oportunidades" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Oportunidades</li>
                                <li data-board="tracker_stories" data-board-pretty="Projetos" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Projetos</li>
                                <li data-board="products_purchases" data-board-pretty="Contratações" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contratações</li>
                                <li data-board="financial_payables" data-board-pretty="Contas à Pagar" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contas à Pagar</li>
                                <li data-board="financial_receivables" data-board-pretty="Contas à Receber" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contas à Receber</li>
                                <li data-board="financial_contracts" data-board-pretty="Contas à Receber" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contratos Financeiros</li>
                                <li data-board="marketing_events" data-board-pretty="Eventos" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eventos</li>
                                <li data-board="marketing_landings" data-board-pretty="Eventos" data-action="click->app--helpers--selector#select click->${this.controllerName}#addBoard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing Pages</li>
                                ${meetingOption}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2" data-${this.controllerName}-target="boardChips"></div>                 
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--activities--squads--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Squad`
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Squad`
        controller.squadLeaderInputTarget.value = controller.current_squad.leader_name
        controller.squadLeaderInputTarget.dataset.filter = controller.current_squad.leader_id
        controller.squadLeaderInputTarget.dataset.text = controller.current_squad.leader_name
        controller.squadTeamInputTarget.value = controller.current_squad.team_name
        controller.squadTeamInputTarget.dataset.filter = controller.current_squad.team_id
        controller.squadTeamInputTarget.dataset.text = controller.current_squad.team_name

        controller.squadLeaderInputTarget.disabled = true
        controller.squadTeamInputTarget.disabled = true

        controller.current_squad.board_types.forEach((element, i) => {
          controller.boards[controller.boards.length] = element
          controller.boardChipsTarget.insertAdjacentHTML("beforeend", controller.boardChipPartial(element, controller.current_squad.board_types_pretty[i]))
        })


      }

      controller.listTeam()
      controller.listLeader()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  addBoard(ev) {
    var board = ev.currentTarget.dataset.board
    var boardPretty = ev.currentTarget.dataset.boardPretty

    this.boards[this.boards.length] = board
    this.boardChipsTarget.insertAdjacentHTML("beforeend", this.boardChipPartial(board, boardPretty))
  }

  boardChipPartial(board, boardPretty) {
    var html = `<div class="chip fade show mr-2 mb-2" id="chipDismissible-${board}">
                  <span class="mr-1 f-065 default">${boardPretty}</span>
                  <button class="close mc-tooltip" data-dismiss="alert" data-target="#chipDismissible-${board}" type="button">
                    <span class="material-icons md-150" data-board="${board}" data-action="click->${this.controllerName}#removeBoard">cancel</span>
                    <span class="mc-tooltiptext">Remover Board</span>
                  </button>
                </div>`

    return html
  }

  removeBoard(ev) {
    var board = ev.currentTarget.dataset.board

    this.boards.forEach((element, i) => {
      if (element == board) {
        this.boards.splice(this.boards.indexOf(element), 1)
      }
    })
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listTeam() {
    var html = ``
    this.application.team.forEach(element => {
      if (element.kind.includes(`admin`) == false) {
        html += `<li data-action="click->${this.controllerName}#selectLeader" data-${this.controllerName}-target="leaderFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
      }
    })

    this.teamFilterTarget.innerHTML = html
  }

  teamFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectTeam(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterTeam(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listLeader() {
    var html = ``
    this.application.team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectLeader" data-${this.controllerName}-target="leaderFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.leaderFilterTarget.innerHTML = html
  }

  leaderFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectLeader(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterLeader(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.squadLeaderInputTarget.value == ``) {
        len += 1
      }

      if (controller.squadTeamInputTarget.value == ``) {
        len += 1
      }

      if (controller.boards.length == 0) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
