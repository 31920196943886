import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "billCard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--cards--bills--list`
  }

  doBillList() {
    var html = ``
    var current_bills = []
    
    if (this.application.current_med.id) {
      this.application.bills.forEach(bill => {
        if (bill.med_id == this.application.current_med.id && bill.month == this.application.current_date.month && bill.year == this.application.current_date.year) {
          current_bills[current_bills.length] = bill
        }  
      })
    } else {
      this.application.bills.forEach(bill => {
        if (bill.month == this.application.current_date.month && bill.year == this.application.current_date.year) {
          current_bills[current_bills.length] = bill
        }
      })
    }
      
    current_bills = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_bills, `name`)
    
    if (current_bills.length > 0) {
      current_bills.forEach(bill => {
        html += `<div class="card pointer mt-3" data-${this.controllerName}-target="billCard" data-bill-id="${bill.id}" data-action="click->${this.controllerName}#toggleBill click->${this.controllerName}#showBill">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>${bill.name}</strong></h6>
                  </div>
                </div>`
      })
    } else {
      html += `<div class="card pointer mt-3">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-2 f-card110"><strong>Nenhuma Fatura para Selecionar</strong></h6>
                  </div>
                </div>`
    }

    this.getControllerByIdentifier("financials--books--cards--bills--dashboard").listTarget.innerHTML = html
  }

  toggleBill(ev) {
    var billCard = ev.currentTarget
    this.billCardTargets.forEach(card => {
      card.classList.remove(`bg-primary`)
    })

    billCard.classList.add(`bg-primary`)
  }

  showBill(ev) {
    var billId = ev.currentTarget.dataset.billId

    this.application.bills.forEach(bill => {
      if (bill.id == billId) {
        this.getControllerByIdentifier(`financials--books--cards--bills--show`).showBillLoader()
        // this.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill(bill)
        // this.getControllerByIdentifier(`financials--books--cards--transactions--list`).current_bill = bill
        this.getControllerByIdentifier(`financials--books--cards--transactions--list`).getTransactions(bill.id)
      }  
    })

  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
