import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "mainDashboard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--files--list`
    this.sort = { mode: `desc`, field: `created_at_time` }
  }

  goToDashboard() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  doListDashboard() {
    // `<div class="col-5">
    //   <span class="f-bold pointer badge badge-secondary-dark f-1p5">Ano Exercício ${this.application.current_date.year + 1}</span>
    // </div>
    // <div class="col-5">
    //   <button type="submit" class="btn btn-flat-primary btn-sm my-3 f-1 w-100" data-action="click->${this.controllerName}#changeYear">Mudar o Ano</button>
    // </div>`
    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Arquivos Carregados IRPF ${this.application.current_date.year + 1}</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-10 offset-1 px-0" data-${this.controllerName}-target="body">
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    }) 
  }

  doDataTable() {
    this.current_files = []
    this.application.files.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_files[this.current_files.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_files, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var files = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_files, this.sort.field)
    }

    this.listData(files)
  }

  listData(data) {
    this.bodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="card my-3">
                      <div class="media d-flex align-items-center py-3">
                        <span class="material-icons md-lg md-primary mx-3">description</span>
                        <div class="media-body">
                          <h5 class="mb-0">Não há Arquivos para a Declaração do Ano-Exercício ${this.application.current_date.year + 1}.</h5>
                        </div>
                      </div>
                    </div>`

      this.bodyTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTarget.insertAdjacentHTML("beforeend", this.fileTablePartial(element, data.length))
      });
    }
  }

  fileTablePartial(element, length) {
    var index = element.index

    if (this.current_files.length == 1) {
      var tableRow = `<tr data-index="${index}" data-id="${element.id}" data-${this.controllerName}-target="tableRow-${index}" class="itemRow" style="height:50px;">`
    } else {
      var tableRow = `<tr data-index="${index}" data-id="${element.id}" data-${this.controllerName}-target="tableRow-${index}" class="itemRow">`
    }

    if (element.id && element.uploaded && element.has_document) {
      var uploadSpan = `Arquivo já salvo`
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-sm md-bolder md-primary">done_all</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Documento Salvo</span>
                      </span>`
      if (element.name) {
        var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = element.document_name.substr(element.document_name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
    } else if (element.id && element.has_document == false) {
      var uploadSpan = `Arquivo não foi salvo`
      var spanError = `<span class="mc-tooltip">
                        <span class="material-icons md-sm md-bolder md-danger">clear</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Documento Não Salvo, favor Apagar</span>
                      </span>`
      if (element.name) {
        var nameSpan = `<span class="text-bold justify">${element.name}</span>`
      } else {
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = ``
    } else {
      if (element.can_upload) {
        var uploadSpan = `Arquivo pronto para Upload`
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-bolder md-success">done</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Documento Ok</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify pointer" data-${this.controllerName}-target="spanName-${index}" data-action="click->${this.controllerName}#editFileName">${element.file_name}</span>`
      } else {
        var uploadSpan = `O arquivo não pode ser salvo`
        var spanError = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-bolder md-danger">clear</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">${element.message_erro}</span>
                        </span>`
        var nameSpan = `<span class="text-bold justify">${element.file_name}</span>`
      }
      var type = element.file.name.substr(element.file.name.lastIndexOf('.') + 1) // GET ONLY EXTENSION
    }

    var size = ((element.file_size / 1000000).toFixed(2) + "MB") // CALCULATE SIZE

    // picture_as_pdf
    // file_present
    // bar_chart
    // table_chart
    // format_color_text
    // image

    var rowHtml = `<div class="card my-3">
                    <div class="media d-flex align-items-center py-3">
                      <span class="material-icons md-lg md-primary mx-3">description</span>
                      <div class="media-body">
                        <h5 class="mb-0">${nameSpan}</h5>
                        <span class="text-black-secondary">${uploadSpan} / ${element.status_pretty}</span>
                      </div>
                    </div>
                  </div>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
