import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "listAllowances", "asdf", "pendencyCount", "sideBarReport",
                    "refreshListBtn", "optionBtn", "searchTable", "allowanceCard"]

  connect() {
    this.controllerName = `financials--books--payrolls--compilations--allowances`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  doAllowanceList() {
    var html = `<h6 class="card-title py-1 f-card90 text-center mt-3"><strong>Tipo de Conta</strong></h6>
                <hr class="mt-0">
                <div class="row">
                  <div class="col-12" data-${this.controllerName}-target="listAllowances" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--compilations--dashboard`).allowancesTarget.innerHTML = html)
    }).then(() => {
      controller.listAllowancesTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.orderDataTable()
  }

  orderDataTable() {
    var allowances = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.allowances, `name`)
    this.listData(allowances)
  }

  listData(data) {
    this.listAllowancesTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="card pointer mt-3">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 f-card110"><strong>Não há Contas</strong></h6>
                    </div>
                  </div>`

      this.listAllowancesTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.listAllowancesTarget.insertAdjacentHTML("beforeend", this.allowanceTablePartial(element, data.length))
      })
    }
  }

  allowanceTablePartial(element) {
    var rowHtml = `<div class="card pointer mt-3" data-${this.controllerName}-target="allowanceCard" data-allowance="${element.name}" data-action="click->${this.controllerName}#toggleAllowancesEv">
                    <div class="card-header py-0 f-065 text-center">
                      <h6 class="card-title py-1 f-card110"><strong>${element.name}</strong></h6>
                    </div>
                  </div>`

    return rowHtml
  }

  toggleAllowancesEv(ev) {
    var currentElement = ev.currentTarget

    this.allowanceCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    currentElement.classList.add(`bg-primary`)
    this.current_allowance = currentElement.dataset.allowance

    this.showRemunerations()
  }

  showRemunerations() {
    this.getControllerByIdentifier("financials--books--payrolls--compilations--list").current_allowance = this.current_allowance
    this.getControllerByIdentifier("financials--books--payrolls--compilations--list").doRemunerationList()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
