import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewExpenseCard", "viewTitle", "viewExpenseCardBody", "previewCard", "previewTitle",
                    "viewExpenseCard", "viewTitle", "viewExpenseCardBody", "previewCard", "editExpenseBtn", "deleteExpenseBtn", "previewTitle"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--expenses--show`

    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
    }

  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewExpenseCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Despesa Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewExpenseCardBody"></div>
                </div>`

    this.dashboardController.viewTarget.innerHTML = html
  }

  editExpense() {
    if (this.application.current_date.open) {
      if (this.checkExpense()) {
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").current_expense = this.current_expense
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").actionMode = "edit"
        this.getControllerByIdentifier("operations--products--clinics--financials--expenses--save").doFormHtml()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 2000)
    }
  }

  deleteExpense() {
    if (this.checkExpense()) {
      this.send_data = { current_user: {}, expense: {}, ticket: {}, notification: {} }
      
      this.send_data.expense.id = this.current_expense.id
      this.send_data.expense.active = false
      
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var r = confirm("Tem certeza que deseja Apagar a Despesa?")
      if (r) {
        this.requestDestroy()
      }
    }
  }

  refactExpense() {
    if (this.checkExpense()) {
      this.send_data = { current_user: {}, expense: {}, ticket: {}, notification: {} }
      
      this.send_data.expense.id = this.current_expense.id
      this.send_data.expense.active = false
      
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var r = confirm("Tem certeza que deseja Refazer as Transações da Despesa?")
      if (r) {
        this.requestRefact()
      }
    }
  }

  doExpenseHtml() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--expenses--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_update && this.application.current_date.open && this.current_expense.paid == false) {
      var editLine = `<span data-action="click->${this.controllerName}#editExpense" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Despesa</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.application.current_date.open) {
      var deleteLine = `<span data-action="click->${this.controllerName}#deleteExpense" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Despesa</span>`
    } else {
      var deleteLine = ``
    }

    if (this.application.permissions[this.permission].can_manage && this.application.current_date.open) {
      var refactLine = `<span data-action="click->${this.controllerName}#refactExpense" class="dropdown-item py-1 pointer dropdown-submenu-item">Refazer Transações da Despesa</span>`
    } else {
      var refactLine = ``
    }


    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${editLine}
                          ${deleteLine}
                          ${refactLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    if (this.current_expense.method == `bank_slip`) {
      var methodTitle = `Boleto`
      var methodDescription = this.current_expense.bank_line
    } else if (this.current_expense.method == `transfer`) {
      var methodTitle = `Transferência`

      var methodDescription = this.current_expense.bank_account
    } else if (this.current_expense.method == `pix`) {
      var methodTitle = `Chave Pix`
      var methodDescription = this.current_expense.pix_key
    } else if (this.current_expense.method == `credit_card`) {
      var methodTitle = `Cartão Crédito`
      var methodDescription = `${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_expense.accrual_month)} em ${this.current_expense.installment}x`
    }

    var partnersHtml = ``
    this.application.partners.forEach(element => {
      if (this.current_expense.partner_ids.includes(element.id.toString())) {
        partnersHtml += `<h6 class="mb-0 f-065" data-${this.controllerName}-target="partnerName-${element.id}">${element.name}</h6>`
      }
    })

      // `<div class="card-actions ml-auto py-0 mc-tooltip">
      //   ${editBtnHtml}
      // </div>
      // <div class="card-actions py-0 mc-tooltip">
      //   ${deleteBtnHtml}
      // </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Lançamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.accrual_date_pretty} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Vencimento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.due_date_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Bruto</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_expense.amount)}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Líquido</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_expense.net_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Descrição</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.description}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Conta</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.chart_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Fornecedor</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.provider_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Canal</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_expense.channel_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${methodTitle}</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${methodDescription}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Sócio(s)</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${partnersHtml}
                              </td>
                            </tr>
                            

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Despesa ${controller.current_expense.chart_name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  checkExpense() {
    if (this.current_expense.token_tree == null && this.current_expense.token_tree == undefined) {
      return true
    } else {
      if (this.current_expense.token_tree[0] == `r`) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Despesa em questão não pode ser editada!`, 5000)
      } else if (this.current_expense.token_tree[0] == `t`) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Despesa deve ser atualizado/editado na área de Repasses!`, 5000)
      }
      return false
    }
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    
    var url = "/operations/products/clinics/financials/expenses/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var expense = response.data.cln
          controller.application.expenses.forEach((element, i) => {
            if (element.id == expense.id) {
              controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1)
            }
          })

          controller.doViewHtml()
          controller.submenuController.doExpensesCount()
          controller.getControllerByIdentifier("operations--products--clinics--financials--expenses--index").doDataTable()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestRefact() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var url = "/operations/products/clinics/financials/expenses/refact_transactions"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var expense = response.data.cln
          controller.application.expenses.forEach((element, i) => {
            if (element.id == expense.id) {
              controller.application.expenses.splice(controller.application.expenses.indexOf(element), 1, expense)
            }
          })

          controller.doViewHtml()
          controller.getControllerByIdentifier("operations--products--clinics--financials--expenses--index").doDataTable()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
