import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "form", "title", "info", "confirmBtn", "navbarSpacing", "preloadCheckInCard",
                    "optInEvent", "nameInput", "phone", "errorEmailCode", "email", "mainFrame",
                    "midiaFrame", "questionFrame", "setAnswer", "footerFrame", "checkboxAcademic", "checkboxDoctor",
                    "graduationDropdown", "graduationDropdownBtn", "graduationInput", "graduationList", "graduation",
                    "checkboxNotDoctor", "academicRow", "doctorRow", "crmInput", "school", "liveBtn", "crmStateInput"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `web--commercial--marketing--events--attendees--enrollment`
    this.application.event_token = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.first_interaction = true
    this.searchArray = []
    if (window.location.search.split("?")[1]) {
      this.searchArray = window.location.search.split("?")[1].split("&")
    }
  }

  doFormHtml() {
    
    var html = `<div class="card-lp-bg-primary card-lp-radius lp-card">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 my-4 text-center px-0">
                        <h4 class="lp-card-title">Confirme a sua inscrição:</h4>
                        <p class="d-none" data-${this.controllerName}-target="liveBtn">Se já se inscreveu, <span class="badge badge-secondary-primary pointer" data-action="click->${this.controllerName}#goToLive">clique aqui</span> para ir ao Evento.</p>
                      </div>
                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form" placeholder="Nome" required data-${this.controllerName}-target="nameInput" data-field="name" data-action="blur->${this.controllerName}#saveInteraction">
                            <span class="material-icons lp-input-icon">account_circle</span>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="mail" class="form-no-border lp-input-form" placeholder="E-mail" required data-${this.controllerName}-target="email" data-field="email" data-email-valid="false" data-action="blur->${this.controllerName}#saveInteraction blur->${this.controllerName}#validateEmail focus->${this.controllerName}#removeInvalid">
                            <span class="material-icons lp-input-icon ">email</span>
                            <div data-${this.controllerName}-target="errorEmailCode"></div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form" placeholder="(XX) X XXXX-XXXX" data-field="uniq_number" data-${this.controllerName}-target="phone" data-action="blur->${this.controllerName}#saveInteraction blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask">
                            <span class="material-icons lp-input-icon">phone</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-3 d-flex justify-content-between">
                        <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                          <input type="checkbox" class="form-check-input" name="doctorLanding" data-${this.controllerName}-target="checkboxDoctor" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="doctorLanding" style="margin-left:1rem;" data-kind="doctor" data-action="click->${this.controllerName}#checkKind">Médico</label>
                        </div>
                        <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                          <input type="checkbox" class="form-check-input" name="academicLanding" data-${this.controllerName}-target="checkboxAcademic" data-kind="academic" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="academicLanding" style="margin-left:1rem;" data-kind="academic" data-action="click->${this.controllerName}#checkKind">Acadêmico</label>
                        </div>
                        <div class="form-group form-check d-flex align-items-center mr-3 mb-0">
                          <input type="checkbox" class="form-check-input" name="notDoctorLanding" data-${this.controllerName}-target="checkboxNotDoctor" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">
                          <label class="form-check-label ml-1 pointer" for="notDoctorLanding" style="margin-left:1rem;" data-kind="not_doctor" data-action="click->${this.controllerName}#checkKind">Outro</label>
                        </div>
                      </div>
                      <div class="col-6 my-1 px-0 d-none" data-${this.controllerName}-target="academicRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form lp-input-form-w-90" placeholder="Faculdade" data-${this.controllerName}-target="school">
                            <span class="material-icons lp-input-icon">school</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 px-0 d-none" data-${this.controllerName}-target="academicRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form lp-input-form-w-90 lp-input-form-pl-10" placeholder="Ano Formatura" data-${this.controllerName}-target="graduation">
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 pr-0 d-none" data-${this.controllerName}-target="doctorRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="text" class="form-no-border lp-input-form lp-input-form-w-90" placeholder="UF" data-field="crm_state" data-${this.controllerName}-target="crmStateInput" data-action="blur->${this.controllerName}#saveInteraction keyup->${this.controllerName}#upcase">
                            <span class="material-icons lp-input-icon">medication</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-1 pl-0 d-none" data-${this.controllerName}-target="doctorRow">
                        <div class="forms-group">
                          <div class="floating-label">
                            <input type="tel" class="form-no-border lp-input-form lp-input-form-w-90 lp-input-form-pl-10" placeholder="CRM" data-field="crm" data-${this.controllerName}-target="crmInput" data-action="blur->${this.controllerName}#saveInteraction keyup->${this.controllerName}#upcase">
                          </div>
                        </div>
                      </div>
                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group ">
                          <div class="form-check lp-check mt-4">
                            <input type="checkbox" class="form-check-input" name="optInEvent" data-${this.controllerName}-target="optInEvent" data-confirm="false" data-action="click->${this.controllerName}#confirmOptIn">
                            <label class="form-check-label" for="optInEvent" style="margin-left:1rem;"> Eu concordo com os <a class="a-white" href="/termos-de-uso-e-politica-privacidade" target="blank">Termos de Uso e Política de Privacidade</a> e com o <a class="a-white" href="/termos-de-consentimento-de-imagem" target="blank">Termo de Consentimento de Imagem</a></label>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group">
                          <button disabled type="submit" class="lp-btn-primary" href="#" data-${this.controllerName}-target="confirmBtn" data-action="click->${this.controllerName}#saveAdmission">
                            Confirmar
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      if (this.application.event.started || !this.application.event.blocked) {
        controller.liveBtnTarget.classList.remove("d-none")
      }
    })
  }

  doFinishedHtml() {
    var html = `<div class="card-lp-bg-primary card-lp-radius lp-card">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <div class="row">
                      <div class="col-12 my-4 text-center px-0">
                        <h4 class="lp-card-title">Inscrições Encerradas</h4>
                        <p class="d-none" data-${this.controllerName}-target="liveBtn">Inscrições se encerraram, favor entrar em contato pelo WhatsApp abaixo para acessar o Evento.</p>
                      </div>

                      <div class="col-12 d-flex justify-content-between">
                        <div class="forms-group">
                          <button type="submit" class="lp-btn-primary" href="#" data-${this.controllerName}-target="contactBtn" data-action="click->${this.controllerName}#contactWhatsApp">
                            Entrar em Contato
                          </button>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      if (this.application.event.started || !this.application.event.blocked) {
        controller.liveBtnTarget.classList.remove("d-none")
      }
    })
  }

  contactWhatsApp() {
    var url = `https://api.whatsapp.com/send?phone=5531996421912&text=Olá,%20quero%20me%20inscrever!`
    window.open(url, `_blank`)
  }

  goToLive() {
    var url = this.application.event.live_path
    window.open(url, `_self`)
  }

  checkKind(ev) {
    this.kind = ev.currentTarget.dataset.kind

    if (this.kind == `academic`) {
      this.checkboxAcademicTarget.checked = true
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (this.kind == `doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = true
      this.checkboxNotDoctorTarget.checked = false

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

    } else if (this.kind == `not_doctor`) {
      this.checkboxAcademicTarget.checked = false
      this.checkboxDoctorTarget.checked = false
      this.checkboxNotDoctorTarget.checked = true

      this.academicRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.doctorRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    }
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToFullPhone(ev)
  }

  validateEmail(ev) {
    if (ev.type == "keyup") {
      var email = ev.currentTarget.value
      if (!email.includes("@") && !email.length > 11) {
        ev.currentTarget.dataset.emailValid = false
      } else {
        ev.currentTarget.dataset.emailValid = true
      }
    }
  }

  removeInvalid(ev) {
    this.errorEmailCodeTarget.classList.remove("invalid-warning", "my-1", "py-1", "f-1")
    this.errorEmailCodeTarget.style.zIndex = 0
    this.emailTarget.classList.remove("invalid-field")
    this.errorEmailCodeTarget.innerText = ""
  }

  confirmOptIn(ev) {
    this.send_data = { current_visit: {}, opt: {} }

    this.send_data.current_visit.current_visit_id = this.application.current_visit_id
    this.send_data.current_visit.current_visit_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visit")
    this.send_data.current_visit.has_data = true
    this.send_data.opt.id = ""
    this.send_data.opt.opt_in = true
    this.send_data.opt.opt_in_at = new Date()
    this.send_data.opt.name = this.application.event.title
    this.send_data.opt.obj_id = this.application.event.id
    this.send_data.opt.obj_type = "event_entity"
    this.send_data.opt.user_id = this.application.current_visit_id
    this.send_data.opt.user_type = "enrollment_attendee"

    this.saveOptIn()
  }

  saveOptIn() {
    const url = "/users/opts/save"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.canConfirm = true
        if (response.process) {
          controller.optInEventTarget.disabled = true
          controller.optInEventTarget.dataset.confirm = true
        } else {
          // controller.getControllerByIdentifier("commercial--marketing--events--live--meeting").doCheckinHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doTitleHtml() {
    
    // `<div class="row" data-${this.controllerName}-target="main">
    //   <div class="col-12 text-center">
    //     <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
    //   </div>
    // </div>`

    var html = `<div class="row pb-5 mb-5">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                </div>
                <div class="row w-100 my-3">
                  <div class="col-12 text-center">
                    <h1 class="display-4 s-title-053B5E s-title-bold s-title-2rem text-center mb-4">
                      ${this.application.event.frame_one}
                    </h1>
                  </div>
                </div>
                <div class="row w-100 my-3">
                  <div class="col-12 text-center">
                    <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                      ${this.application.event.subframe_one}
                    </p>
                  </div>
                </div>`

    this.titleTarget.innerHTML = html
  }

  doMidiaHtml() {

    if (this.application.event.midia == `video`) {
      var midia = `<div class="embed-responsive embed-responsive-16by9">
                    <iframe id="video" class="embed-responsive-item" src="https://www.youtube.com/embed/${this.application.event.video_link}" allowfullscreen></iframe>
                  </div>`
    } else if (this.application.event.midia == `cover`) {
      // var midia = `<img src="${this.application.event.cover_url}" alt="${this.application.event.title}" class="imagem-head w-100" style="width:25rem">`
      var midia = `<img src="${this.application.event.cover_url}" alt="${this.application.event.title}" class="imagem-head" style="width:25rem">`
    }

    var html = `<div class="col-6 d-flex justify-content-center">
                  ${midia}
                </div>
                <div class="col-6 text-center">
                  <div class="row w-100 my-3">
                    <div class="col-12 text-center">
                      <h1 class="display-4 s-title-FBFCFF s-title-bold s-title-2rem text-center mb-4">
                        ${this.application.event.frame_two}
                      </h1>
                    </div>
                  </div>
                  <div class="row w-100 my-3">
                    <div class="col-12 text-center">
                      <p class="s-title-FBFCFF s-title-500 s-title-1rem mb-4">
                        ${this.application.event.subframe_two}
                      </p>
                    </div>
                  </div>
                </div>`

    this.midiaFrameTarget.innerHTML = html
  }

  doQuestionHtml() {
    var html = `<div class="col-6 my-5">
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center f-1p25 f-bold pointer" data-question="question_one" data-action="click->${this.controllerName}#changeAnswer">
                          ${this.application.event.question_one}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center f-1p25 f-bold pointer" data-question="question_two" data-action="click->${this.controllerName}#changeAnswer">
                          ${this.application.event.question_two}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-2">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 text-center f-1p25 f-bold pointer" data-question="question_three" data-action="click->${this.controllerName}#changeAnswer">
                          ${this.application.event.question_three}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 text-center my-5" data-${this.controllerName}-target="setAnswer">
                  <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                    Escolha a pergunta!
                  </p>
                </div>`

    this.questionFrameTarget.insertAdjacentHTML("beforeend", html)
  }

  changeAnswer(ev) {

    var question = ev.currentTarget.dataset.question

    var html = ``
    if (question == `question_one`) {
      html += `<p class="s-title-6F706F s-title-500 f-1p15 mb-4">
                 ${this.application.event.question_one}
               </p>
               <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                 ${this.application.event.answer_one}
               </p>`
    } else if (question == `question_two`) {
      html += `<p class="s-title-6F706F s-title-500 f-1p15 mb-4">
                 ${this.application.event.question_two}
               </p>
               <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                 ${this.application.event.answer_two}
               </p>`
    } else if (question == `question_three`) {
      html += `<p class="s-title-6F706F s-title-500 f-1p15 mb-4">
                 ${this.application.event.question_three}
               </p>
               <p class="s-title-6F706F s-title-500 s-title-1rem mb-4">
                 ${this.application.event.answer_three}
               </p>`
    }

    this.setAnswerTarget.innerHTML = html
  }

  saveInteraction(ev) {
    if (this.application.current_visit_id) {
      this.send_data = { admission: {}, event: {}, current_visit: {} }

      this.send_data.current_visit.id = this.application.current_visit_id
      this.send_data.event.id = this.application.event.id
      this.send_data.admission.first_interaction = this.first_interaction
      this.send_data.admission.field = ev.currentTarget.dataset.field
      this.send_data.admission.value = ev.currentTarget.value

      const url = "/commercial/marketing/events/admissions/save_interaction"
      const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      var element = ev.currentTarget
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (!response.check) {
            element.value = ``
          }
          controller.first_interaction = false
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  saveAdmission() {
    if (this.canConfirm) {
      this.send_data = { event: {}, attendee: {}, admission: {} }

      var phone = this.phoneTarget.value.trim()

      if (phone.length == 16) {
        var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(0, 2)
        var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "").slice(2, 11)
      } else {
        var ddd = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
        var number = phone.replace("(", "").replace(")", "").replace(" ", "").replace(" ", "").replace("-", "")
      }

      this.searchArray.forEach(element => {
        var utm = element.split("=")

        this.send_data.admission[utm[0]] = utm[1]
      })

      this.send_data.event.id = this.application.event.id
      this.send_data.event.event_token = this.application.event.token

      this.send_data.admission.event_id = this.application.event.id
      this.send_data.admission.event_token = this.application.event.token
      this.send_data.admission.date = new Date()

      this.send_data.attendee.name = this.nameInputTarget.value.trim()
      this.send_data.attendee.email = this.emailTarget.value.trim().toLowerCase()
      this.send_data.attendee.ddd = ddd
      this.send_data.attendee.number = number
      this.send_data.attendee.crm = this.crmInputTarget.value.trim()
      this.send_data.attendee.crm_state = this.crmStateInputTarget.value.trim()
      this.send_data.attendee.crm_input = `${this.crmStateInputTarget.value.trim()} ${this.crmInputTarget.value.trim()}`
      this.send_data.attendee.med_school = this.schoolTarget.value.trim()
      this.send_data.attendee.graduation = this.graduationTarget.value.trim()
      this.send_data.attendee.kind = this.kind

      this.requestSaveAdmission()
    }
  }

  requestSaveAdmission() {
    this.stopRefreshing()
    const url = "/commercial/marketing/events/admissions/save_public"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.event = response.data.cln
          if (controller.application.event.started || !controller.application.event.blocked) {
            window.open(controller.application.event.live_path, `_self`)
          } else {
            window.open(controller.application.event.thank_you_path, `_self`)
          }
        } else {
          // controller.toggleBtns(false)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doCheckInPreload() {

    // `<div class="row" data-${this.controllerName}-target="main">
    //   <div class="col-12 text-center">
    //     <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
    //   </div>
    // </div>`

    var html = `<div class="row d-flex align-items-center h-50 mt-5" data-${this.controllerName}-target="mainFrame">
                  <div class="col-6" data-${this.controllerName}-target="title">
                    ${this.loader}
                    ${this.loader}
                  </div>
                  <div class="col-4 offset-1" data-${this.controllerName}-target="form">
                    <div class="card w-100" data-${this.controllerName}-target="preloadCheckInCard">
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <div class="row w-100">
                          <div class="col-12 my-4 text-center px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 my-4 px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                            ${this.loader}
                            ${this.loader}
                            <div class="card pointer w-100 bg-primary d-none">
                              ${this.loader}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex align-items-center h-30 frame-site-footer" data-${this.controllerName}-target="midiaFrame"></div>
                <div class="row d-flex align-items-center h-30" data-${this.controllerName}-target="questionFrame">
                  <div class="col-12 mt-5">
                    <h1 class="display-4 title-two text-center mb-0">
                      Perguntas <span style="color:#26C485;"> Frequentes</span>
                    </h1>
                  </div>
                </div>
                <div class="row d-flex align-items-center" data-${this.controllerName}-target="footerFrame">
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.mainTarget.innerHTML = html)
      resolve(controller.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.mainTarget.style.height = ($(window).height()) + "px"
      // controller.mainFrameTarget.style.height = ($(window).height() * 0.5) + "px"
      // controller.midiaFrameTarget.style.height = ($(window).height() * 0.5) + "px"
      // controller.questionFrameTarget.style.height = ($(window).height() * 0.5) + "px"
      // controller.preloadCheckInCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getEvent()
    })
  }

  getEvent() {
    var data = { event: { slug: this.application.event_token } }
    const url = "/commercial/marketing/events/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.event = response.data.cln
          // if (controller.application.event.finished) {
          //   controller.doFinishedHtml()
          // } else {
          //   controller.doFormHtml()
          // }
          controller.doFormHtml()
          controller.doTitleHtml()
          controller.doMidiaHtml()
          controller.doQuestionHtml()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        // controller.application.token = $('meta[name=csrf-token]').attr('content')
        // window.location.reload()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  toggleBtns(value) {
    if (value) {
      this.confirmBtnTarget.classList.remove("bg-disabled")
      this.confirmBtnTarget.classList.remove("none")
      this.confirmBtnTarget.classList.add("bg-primary")
      this.confirmBtnTarget.classList.add("pointer")
      // this.canConfirm = true
    } else {
      this.confirmBtnTarget.classList.add("bg-disabled")
      this.confirmBtnTarget.classList.add("none")
      this.confirmBtnTarget.classList.remove("bg-primary")
      this.confirmBtnTarget.classList.remove("pointer")
      // this.canConfirm = false
    }
  }

  setHeader() {
    this.application.site = {}
    this.getControllerByIdentifier("web--site--principal--frames--header").doHtml()
  }

  setNavbarSpacing() {
    var navbarHeight = Number($('.navbar').height())
    var navbarPadding = 0

    if (Number($('.navbar').css('padding-top')) && Number($('.navbar').css('padding-bottom'))) {
      var navbarPadding = Number($('.navbar').css('padding-top').replace("px", "")) + Number($('.navbar').css('padding-bottom').replace("px", ""))
    } else if (Number($('.navbar').css('padding-top'))) {
      var navbarPadding = Number($('.navbar').css('padding-top').replace("px", ""))
    } else if (Number($('.navbar').css('padding-bottom'))) {
      var navbarPadding = Number($('.navbar').css('padding-bottom').replace("px", ""))
    }

    var navbarSpacing = navbarHeight + navbarPadding
    this.navbarSpacingTarget.style.marginTop = navbarSpacing + "px"
    this.doCheckInPreload()
    this.getEvent()
  }

  upcase(ev) {
    var value = ev.currentTarget.value
    ev.currentTarget.value = value.toUpperCase()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.mainTarget.style.height = ($(window).height()) + "px"
    this.mainFrameTarget.style.height = ($(window).height()) + "px"
    this.midiaFrameTarget.style.height = ($(window).height()) + "px"
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshInfoTimer = setInterval(function () {
      var len = 0

      if (controller.hasConfirmBtnTarget) {
        if (controller.nameInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.emailTarget.value == `` && controller.getControllerByIdentifier(`app--helpers--strings`).fromStringToBoolean(controller.emailTarget.dataset.emailValid)) {
          len += 1
        }
        
        if (controller.phoneTarget.value == `` || controller.phoneTarget.length < 16) {
          len += 1
        }
        
        if (controller.optInEventTarget.dataset.confirm == true || controller.optInEventTarget.dataset.confirm == "true") {
          len += 0
        } else {
          len += 1
        }
        
        
        if (len == 0) {
          controller.confirmBtnTarget.disabled = false
        } else {
          controller.confirmBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshInfoTimer) {
      clearInterval(this.refreshInfoTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })




  // `?utm_campaign=geral&utm_source=facebook&utm_content=pj-medica&utm_medium=banner&utm_term=pj-medica`

  // `?utm_mc_campaign=imposto-de-renda-para-medicos
  //   &utm_mc_source=google
  //   &utm_mc_content=display
  //   &utm_mc_medium=banner
  //   &utm_mc_term=imposto-de-renda-para-medicos
  //   &utm_medium=cpc
  //   &utm_campaign=
  //   &utm_source=google
  //   &utm_term=
  //   &gclid=CjwKCAiAkan9BRAqEiwAP9X6UVKAuuCJ6kqfLzzMEohwyiQovw0MEOfJk0MSe2J-aaPz6hfsqaBKMxoCpaEQAvD_BwE`

}
