import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--logs--dashboard`
  }

  doLogsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--managements--logs--index">index</div>
                  <div class="col-6 pl-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--managements--logs--show"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--financials--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--show--submenu`).dashboardToggleBtn(`LOGS`)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
