import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "bodyTable", "contracts", "receivables", "client", "sideBarReport", "XXXBtn",
                    "clearListBtn", "optionBtn", "viewClientCard", "content"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--modal--client`
    this.controllerMain = `financials--books--receivables--conciliations--modal--main`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doClient() {

    if (this.application.permissions.financial_receivable_contracts.can_create && this.current_billing.conciliated == false) {
      var addLine = `<span data-action="click->${this.controllerName}#addContract" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Contrato</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_pos_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Pós-Abertura PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medclinic_amendment" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Alteração PJ</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medfiling_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Declaração IRPF</span>`
      addLine += `<span data-action="click->${this.controllerName}#addContract" data-model="medpayroll_opening" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Contrato Folha de Pagamento</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshContracts" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Contratos</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="row mt-3">
                  <div class="col-12 px-0" data-${this.controllerName}-target="client">
                    <div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewClientCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header py-0 f-065 d-flex align-items-center">
                        <h6 class="card-title py-2 f-card110"><strong>${this.current_client.record_name}</strong> | Início em: <strong>${this.current_client.started_at_pretty}</strong></h6>
                        <div class="card-actions ml-auto py-0">
                          ${submenuHtml}
                          ${refreshBtnHtml}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" data-${this.controllerName}-target="contracts" data-controller="financials--books--receivables--conciliations--modal--contracts financials--books--clients--contracts--save"></div>
                  <div class="col-12" data-${this.controllerName}-target="receivables" data-controller="financials--books--receivables--conciliations--modal--receivables"></div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).current_billing = controller.current_billing
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).current_client = controller.current_client
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).stopRefreshing()
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).getClientContracts()
    })
  }

  refreshContracts() {
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).stopRefreshing()
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).getClientContracts()
  }

  doNoClient() {
    if (this.application.permissions.financial_receivable_clients.can_create) {
      var addHtml = `<div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline btn-sm my-0" data-${this.controllerName}-target="addClientBtn" data-action="click->${this.controllerName}#addClient" type="button">
                        <span class="material-icons md-dark">add</span>
                      </button>
                      <span class="mc-tooltiptext">Adicionar Cliente</span>
                    </div>`
    } else {
      var addHtml = ``
    }

    if (this.application.permissions.financial_receivable_clients.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addClient" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Cliente</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshClients" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Clientes</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext">Limpar</span>
                          </span>`

    var html = `<div class="card mt-4" style="width:100%;display:relative;" data-${this.controllerName}-target="viewClientCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header py-0 f-065 d-flex align-items-center">
                    <h6 class="card-title py-2 f-card110"><strong>O cliente não está cadastrado</strong></h6>
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${refreshBtnHtml}
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  addClient() {
    this.controllerSaveClient = this.getControllerByIdentifier("financials--books--clients--entities--save")

    this.controllerSaveClient.gridElement = this.mainTarget
    this.controllerSaveClient.permission = `financial_receivable_clients`
    this.controllerSaveClient.current_client = {}
    this.controllerSaveClient.actionMode = `new`
    this.controllerSaveClient.controllerDashboard = this
    this.controllerSaveClient.doFormHtml()
  }

  refreshClients() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_clients` } }
    const url = "/financials/books/clients/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clients = response.data.cln
        }

        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--main`).setClient()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  addContract(ev) {
    var kindModel = ev.currentTarget.dataset.model

    this.getControllerByIdentifier("financials--books--receivables--conciliations--modal--contracts").kindModel = kindModel
    this.getControllerByIdentifier("financials--books--receivables--conciliations--modal--contracts").addContract()
  }

  copyClient() {

    // this.controllerSaveClient.zipInputTarget.value = this.current_billing.payer_address_zip_code
    // this.controllerSaveClient.streetInputTarget.value = this.current_billing.payer_address_street
    // this.controllerSaveClient.numberInputTarget.value = this.current_billing.payer_address_number
    // this.controllerSaveClient.complementInputTarget.value = this.current_billing.payer_address_complement
    // this.controllerSaveClient.districtInputTarget.value = this.current_billing.payer_address_district
    // this.controllerSaveClient.cityInputTarget.value = this.current_billing.payer_address_city
    // this.controllerSaveClient.stateInputTarget.dataset.filter = this.current_billing.payer_address_state

    this.controllerSaveClient.actionMode = `new`
    this.controllerSaveClient.iuguId = this.current_billing.client_id_iugu

    if (this.current_billing.payer_cpf_cnpj_type == `legal`) {
      this.controllerSaveClient.cpfCheckboxTarget.checked = false
      this.controllerSaveClient.cnpjCheckboxTarget.checked = true
      this.controllerSaveClient.cnpjTarget.value = this.current_billing.payer_cpf_cnpj_pretty
    } else if (this.current_billing.payer_cpf_cnpj_type == `individual`) {
      this.controllerSaveClient.cpfCheckboxTarget.checked = true
      this.controllerSaveClient.cnpjCheckboxTarget.checked = false
      this.controllerSaveClient.cpfTarget.value = this.current_billing.payer_cpf_cnpj_pretty
    }
    
    this.controllerSaveClient.kindCheck(this.current_billing.payer_cpf_cnpj_type)
    this.controllerSaveClient.searchAccount(this.current_billing.payer_cpf_cnpj)

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

  }

  doClientLoader() {
    var html = `<div class="card-body py-0" style="overflow:auto;">
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
                <div class="row">
                  <div class="col-6">${this.loader}</div>
                  <div class="col-6">${this.loader}</div>
                </div>
              </div>`

    this.mainTarget.innerHTML = html
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}