import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "printCardElement", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--ongoings--customers--index--dashboard`
  }

  doCustomersDashboard() {
    var html = `<div class="row mt-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="list" data-controller="operations--ongoings--customers--index--list"></div>
                  <div class="col-4 pr-1" data-${this.controllerName}-target="view" data-controller="operations--ongoings--customers--index--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--ongoings--customers--index--main`).contentTarget.innerHTML = html)
    }).then(() => {

      // controller.getControllerByIdentifier(`operations--ongoings--customers--index--submenu`).dashboardToggleBtn(`APURAÇÕES`)
      // controller.getControllerByIdentifier(`operations--ongoings--customers--index--apurations--list`).permission = `medclinic_financial_main_apurations`
      controller.getControllerByIdentifier(`operations--ongoings--customers--index--list`).doListHtml()
      controller.getControllerByIdentifier(`operations--ongoings--customers--index--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
