import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pfPartnerCheckbox", "pjPartnerCheckbox", "chooseType", "cpfInputDiv", "cpfInput", "cnpjInputDiv",
                    "cnpjInput", "takerNameInput", "takerDescriptionInput", "takerNotesInput",
                    "descriptionDiv", "descriptionInput", "infoDiv", "regimeInput", "productDateInput", "totalAmount",
                    "partnerTableBody", "partnerDropdown", "partnerDropdownBtn", "partnerInput", "partnerList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "partnerAmountInput", "kindValuesCheckbox"]

  

  connect() {
    this.controllerName = "operations--products--clinics--financials--add--partners"
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.performerCount = 0
    this.performerCountArray = []

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }
  }

  getData() {
    var performers = []

    // this.saveController.partners.forEach(partner => {
    //   if (partner.operational) {
    //     var performer = {}
    //     if (this.nameTarget(`partnerCheckbox-${partner.id}`).checked) {
    //       performer.partner_id = partner.id
    //       performer.name = partner.name
    //       performer.amount = this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value)
    //       var info = this.nameTarget(`partnerInfoSpan-${partner.id}`).dataset.info
    //       if (info == `show`) {
    //         performer.show_info = true
    //       } else if (info == `not_show`) {
    //         performer.show_info = false
    //       }
    //       performer.clinic_id = this.saveController.send_data.receivable.clinic_id
    //       performer.date_id = this.saveController.send_data.receivable.date_id
    //       performer.information = this.nameTarget(`partnerData-${partner.id}`).innerText
    //       performer.taker_id = this.saveController.send_data.receivable.taker_id
    //       performer.taker_name = this.saveController.send_data.receivable.taker_name
    //       performer.taker_type = this.saveController.send_data.receivable.taker_type
    //       
    //       if (performer.amount > 0) {
    //         if (performer.show_info) {
    //           this.saveController.send_data.receivable.description += ` | ${partner.name} ${performer.information}`
    //         }
    //         performers[performers.length] = performer
    //       }
    //     }
    //   }
    // })

    var totalReceivablePractice = 0
    var totalReceivableProcedure = 0
    var receivableProcedure = false
    this.saveController.partners.forEach(partner => {
      if (partner.operational) {
        var performer = { amount: 0, total_practice_revenues: 0, total_procedure_revenues: 0 }

        var performerProcedure = false
        this.performerCountArray.forEach(count => {
          var current_amount = 0

          if (this.nameTarget(`partnerRow-${count}`)) {
            var current_partner_id = this.nameTarget(`partnerInput-${count}`).dataset.partnerId
            if (partner.id == current_partner_id) {
              
              performer.partner_id = this.nameTarget(`partnerInput-${count}`).dataset.partnerId
              performer.name = this.nameTarget(`partnerInput-${count}`).innerText
              var info = this.nameTarget(`partnerInfoSpan-${count}`).dataset.info
              var cpfInfo = this.nameTarget(`partnerCpfInfoSpan-${count}`).dataset.cpfInfo
              if (info == `show`) {
                performer.show_info = true
              } else if (info == `not_show`) {
                performer.show_info = false
              }
              if (cpfInfo == `show`) {
                performer.show_cpf_info = true
              } else if (cpfInfo == `not_show`) {
                performer.show_cpf_info = false
              }

              performer.information = this.nameTarget(`partnerData-${count}`).innerText
              current_amount = this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmountInput-${count}`).value)
              performer.amount += Number(current_amount)
              performer.kind = this.nameTarget(`kindInput-${count}`).dataset.kind
              if (performer.kind == `practice`) {
                performer.total_practice_revenues += Number(current_amount)
                totalReceivablePractice += Number(current_amount)
              } else if (performer.kind == `procedure`) {
                performer.total_procedure_revenues += Number(current_amount)
                totalReceivableProcedure += Number(current_amount)
                receivableProcedure = true
                performerProcedure = true
              }

              performer.clinic_id = this.saveController.send_data.receivable.clinic_id
              performer.date_id = this.saveController.send_data.receivable.date_id
              performer.taker_id = this.saveController.send_data.receivable.taker_id
              performer.taker_name = this.saveController.send_data.receivable.taker_name
              performer.taker_type = this.saveController.send_data.receivable.taker_type
            }
          }
        })
        
        
        if (performer.amount > 0) {
          if (receivableProcedure) {
            performer.kind = `procedure`
          }
          if (performer.show_info && performer.show_cpf_info) {
            this.saveController.send_data.receivable.description += ` | ${performer.name} ${performer.information} CPF: ${partner.id_number_pretty}`
          } else if (performer.show_info) {
            this.saveController.send_data.receivable.description += ` | ${performer.name} ${performer.information}`
          } else if (performer.show_cpf_info) {
            this.saveController.send_data.receivable.description += ` | ${performer.name} CPF: ${partner.id_number_pretty}`
          }
          
          performers[performers.length] = performer
        }
      }
    })
    
    if (receivableProcedure) {
      this.saveController.send_data.receivable.kind = `procedure`
    } else {
      this.saveController.send_data.receivable.kind = `practice`
    }
    
    this.saveController.send_data.receivable.total_amount = this.numberController.fromCurrencyToNumber(this.totalAmountTarget.innerText)
    this.saveController.send_data.receivable.total_practice_revenues = totalReceivablePractice
    this.saveController.send_data.receivable.total_procedure_revenues = totalReceivableProcedure

    if (this.kindValuesCheckboxTarget.checked) {
      this.saveController.send_data.receivable.description += ` | Valor Consulta/Plantão Médico: ${this.numberController.currencyOptionMask(totalReceivablePractice)}`
      this.saveController.send_data.receivable.description += ` | Valor Procedimento/Tratamento Médico: ${this.numberController.currencyOptionMask(totalReceivableProcedure)}`
    }

    this.saveController.send_data.performers.performers = performers

  }

  doPartnersFormHtml() {
    // `<input class="form-control f-065" data-${this.controllerName}-target="partnerData-${partner.id}" placeholder="Registro" data-action="keyup->${this.controllerName}#upcase" type="text" required>`
    var partnerHtml = ``
    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.saveController.partners, `name`)

    partners.forEach(partner => {
      if (partner.status && partner.operational && partner.councils.length > 0) {
        var check = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                      <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}" data-action="click->${this.controllerName}#checkPartner">
                      <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                    </div>`
        
        partnerHtml += `<tr class="itemRow" data-partner-id="${partner.id}">
                          <td colspan="3" class="align-middle p-0">${check}</td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none" data-${this.controllerName}-target="partnerAmountForm-${partner.id}">
                              <input class="form-control f-065" data-${this.controllerName}-target="partnerAmount-${partner.id}" data-action="blur->${this.controllerName}#bindTotalAmount blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0 text-center">
                            <span class="mc-tooltip pointer d-none" data-info="show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setInfo">
                              <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerInfo-${partner.id}">visibility</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Colocar Info na Descrição da NF</span>
                            </span>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none mb-0" data-${this.controllerName}-target="partnerDataForm-${partner.id}">
                              <span class="f-065" data-${this.controllerName}-target="partnerData-${partner.id}">${partner.councils[0].number_full_pretty}</span>
                            </div>
                          </td>
                        </tr>`
      } else if (partner.status && partner.operational && partner.doctor == false) {
        var check = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                      <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}" data-action="click->${this.controllerName}#checkPartner">
                      <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                    </div>`

        partnerHtml += `<tr class="itemRow" data-partner-id="${partner.id}">
                          <td colspan="3" class="align-middle p-0">${check}</td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none" data-${this.controllerName}-target="partnerAmountForm-${partner.id}">
                              <input class="form-control f-065" data-${this.controllerName}-target="partnerAmount-${partner.id}" data-action="blur->${this.controllerName}#bindTotalAmount blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0 text-center">
                            <span class="mc-tooltip pointer d-none" data-info="show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setInfo">
                              <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerInfo-${partner.id}">visibility</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Colocar Info na Descrição da NF</span>
                            </span>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none mb-0" data-${this.controllerName}-target="partnerDataForm-${partner.id}">
                              <span class="f-065" data-${this.controllerName}-target="partnerData-${partner.id}"></span>
                            </div>
                          </td>
                        </tr>`
      } else {
        var message = `Se o Médico Sócio não aparece ou (i) ele não é operacional ou (ii) está faltando alguma informação, como o CRM.
                        Favor voltar na Regularização e atualizar os dados`
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 5000)
      }
    })


    var html = `<div class="row my-3">
                  <div class="col-9 px-0">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                          <td rowspan="${this.saveController.partners.length}" style="height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle text-center">
                            
                          </td>
                        </tr>
                        ${partnerHtml}

                      </tbody>
                    </table>
                  </div>
                  <div class="col-3 px-0">
                    <div class="row my-3">
                      <div class="col-12 text-center">
                        <span class="f-bold">Valor Bruto da Nota Fiscal</span>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 f-1 f-bold text-center">
                        <span data-${this.controllerName}-target="totalAmount">R$ 0,00</span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.saveController.formTitleTarget.innerText = `Selecione os sócios e os respectivos valores bruto para emissão da Nota Fiscal.`
      if (controller.saveController.partners.length == 1) {
        var partner = controller.saveController.partners[0]
        controller.nameTarget(`partnerCheckbox-${partner.id}`).checked = true
        controller.showPartner(partner.id, true)
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  doPartnersLineHtml() {
    var html = `<div class="row my-3">
                  <div class="col-10 px-0" data-${this.controllerName}-target="partnerTableBody">
                    <div class="row mb-3 d-flex align-items-center">
                      <div class="col-3">
                        <span class="f-075 f-bold">Adicionar Sócio</span>
                        <button data-action="click->${this.controllerName}#addPartnerRow" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-5">
                          <span class="material-icons md-sm md-dark">add</span>
                          <span class="mc-tooltiptext">Adicionar Sócio</span>
                        </button>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                          <input type="checkbox" class="custom-control-input" id="showKindDescription" data-${this.controllerName}-target="kindValuesCheckbox">
                          <label class="custom-control-label f-065 pointer ml-3" for="showKindDescription">Adicionar Valores do Tipo de Atendimento na Descrição</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-0">
                    <div class="row mb-3">
                      <div class="col-12 text-center">
                        <span class="f-bold">Valor Bruto da Nota Fiscal</span>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 f-1 f-bold text-center">
                        <span data-${this.controllerName}-target="totalAmount">R$ 0,00</span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.saveController.formTitleTarget.innerText = `Selecione os sócios e os respectivos valores bruto para emissão da Nota Fiscal.`
      // if (controller.saveController.partners.length == 1) {
      //   var partner = controller.saveController.partners[0]
      //   controller.nameTarget(`partnerCheckbox-${partner.id}`).checked = true
      //   controller.showPartner(partner.id, true)
      // }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()

      controller.replace_performer = {}
      if (controller.saveController.replaceMode) {
        controller.saveController.replace_receivable.performers.forEach(element => {
          controller.replace_performer = element
          new Promise(function (resolve) {
            resolve(controller.addPartnerRow())
          }).then(() => {
            controller.setPartners()
          })
        })
      }
    })
  }

  addPartnerRow() {
    this.performerCount += 1
    this.performerCountArray[this.performerCountArray.length] = this.performerCount
    var partnerHtml = ``

    partnerHtml += `<div class="row d-flex align-items-center" data-${this.controllerName}-target="partnerRow-${this.performerCount}">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sócio</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="partnerDropdown-${this.performerCount}">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="partnerDropdownBtn-${this.performerCount}"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="partnerInput-${this.performerCount}"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="partnerList-${this.performerCount}" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="form-group mb-0" data-${this.controllerName}-target="partnerAmountForm-${this.performerCount}">
                          <input class="form-control f-065" data-${this.controllerName}-target="partnerAmountInput-${this.performerCount}" data-action="blur->${this.controllerName}#bindCountTotalAmount blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                        </div>
                      </div>
                      <div class="col-3 pl-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo de Atendimento</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown-${this.performerCount}">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn-${this.performerCount}"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput-${this.performerCount}"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList-${this.performerCount}" data-app--helpers--search-target="searchList">
                                  <li data-kind="practice" data-count="${this.performerCount}" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consulta/Plantão Médico</li>
                                  <li data-kind="procedure" data-count="${this.performerCount}" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Procedimento/Tratamento Médico</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <span class="mc-tooltip pointer" data-cpf-info="not_show" data-count="${this.performerCount}" data-${this.controllerName}-target="partnerCpfInfoSpan-${this.performerCount}" data-action="click->${this.controllerName}#setCpfInfo">
                          <span class="material-icons md-dark md-150" data-${this.controllerName}-target="partnerCpfInfo-${this.performerCount}">no_accounts</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Colocar CPF na Descrição da NF</span>
                        </span>
                        <span class="mc-tooltip pointer" data-count="${this.performerCount}" data-info="show" data-${this.controllerName}-target="partnerInfoSpan-${this.performerCount}" data-action="click->${this.controllerName}#setCountInfo">
                          <span class="material-icons md-dark md-150" data-${this.controllerName}-target="partnerInfo-${this.performerCount}">visibility</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Colocar Info na Descrição da NF</span>
                        </span>
                        <span class="f-065" data-${this.controllerName}-target="partnerData-${this.performerCount}"></span>
                      </div>
                      <div class="col-1">
                        <button data-count="${this.performerCount}" data-action="click->${this.controllerName}#removePartnerRow" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">clear</span>
                          <span class="mc-tooltiptext">Remover Linha</span>
                        </button>
                      </div>
                    </div>`
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.partnerTableBodyTarget.insertAdjacentHTML("beforeend", partnerHtml))
    }).then(() => {
      controller.setPartners()
      if (controller.saveController.replaceMode) {
        controller.nameTarget(`partnerAmountInput-${this.performerCount}`).value = controller.numberController.currencyOptionMask(controller.replace_performer.amount)

        controller.nameTarget(`kindInput-${controller.performerCount}`).innerText = controller.replace_performer.kind_pretty
        controller.nameTarget(`kindInput-${controller.performerCount}`).dataset.kind = controller.replace_performer.kind
        controller.nameTarget(`kindDropdown-${controller.performerCount}`).value = controller.replace_performer.kind
      } else {
        controller.nameTarget(`partnerAmountInput-${this.performerCount}`).value = `R$ 0,00`

        controller.nameTarget(`kindInput-${controller.performerCount}`).innerText = `Consulta/Plantão Médico`
        controller.nameTarget(`kindInput-${controller.performerCount}`).dataset.kind = `practice`
        controller.nameTarget(`kindDropdown-${controller.performerCount}`).value = `practice`
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setPartners() {
    var partnerHtml = ``
    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.saveController.partners, `name`)

    var current_partners = []
    var replace_partner = {}
    partners.forEach(partner => {
      if (partner.status && partner.operational && partner.councils.length > 0) {
        current_partners[current_partners.length] = partner
      } else if (partner.status && partner.operational && partner.doctor == false) {
        current_partners[current_partners.length] = partner
      } else {
        var message = `Se o Médico Sócio não aparece ou (i) ele não é operacional ou (ii) está faltando alguma informação, como o CRM.
                        Favor voltar na Regularização e atualizar os dados`
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 5000)
      }

      if (this.saveController.replaceMode && (this.replace_performer.partner_id == partner.id)) {
        replace_partner = partner
      }

    })

    console.log(current_partners)

    current_partners.forEach(partner => {
      partnerHtml += `<li data-partner-id="${partner.id}" data-count="${this.performerCount}" data-action="click->${this.controllerName}#selectPartner click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${partner.name}</li>`
    })

    this.nameTarget(`partnerList-${this.performerCount}`).innerHTML = partnerHtml
    var mainCouncil = {}

    if (current_partners.length == 1) {

      current_partners[0].councils.forEach(council => {
        if (council.main) {
          mainCouncil = council
        }
      })

      this.nameTarget(`partnerDropdown-${this.performerCount}`).value = current_partners[0].id
      this.nameTarget(`partnerInput-${this.performerCount}`).dataset.partnerId = current_partners[0].id
      this.nameTarget(`partnerInput-${this.performerCount}`).innerText = current_partners[0].name
      // this.nameTarget(`partnerData-${this.performerCount}`).innerText = current_partners[0].councils[0].number_full_pretty
      this.nameTarget(`partnerData-${this.performerCount}`).innerText = mainCouncil.number_full_pretty
      this.nameTarget(`partnerAmountInput-${this.performerCount}`).focus()
    }

    if (this.saveController.replaceMode) {

      replace_partner.councils.forEach(council => {
        if (council.main) {
          mainCouncil = council
        }
      })

      this.nameTarget(`partnerDropdown-${this.performerCount}`).value = replace_partner.id
      this.nameTarget(`partnerInput-${this.performerCount}`).dataset.partnerId = replace_partner.id
      this.nameTarget(`partnerInput-${this.performerCount}`).innerText = replace_partner.name
      // this.nameTarget(`partnerData-${this.performerCount}`).innerText = replace_partner.councils[0].number_full_pretty
      this.nameTarget(`partnerData-${this.performerCount}`).innerText = mainCouncil.number_full_pretty
      this.nameTarget(`partnerAmountInput-${this.performerCount}`).focus()
    }
  }

  selectPartner(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var count = ev.currentTarget.dataset.count

    this.saveController.partners.forEach(partner => {
      if (partner.id == partnerId) {

        var mainCouncil = {}
        partner.councils.forEach(council => {
          if (council.main) {
            mainCouncil = council
          }
        })

        this.nameTarget(`partnerDropdown-${count}`).value = partner.id
        this.nameTarget(`partnerInput-${count}`).dataset.partnerId = partner.id
        this.nameTarget(`partnerInput-${count}`).innerText = partner.name
        // this.nameTarget(`partnerData-${count}`).innerText = partner.councils[0].number_full_pretty
        this.nameTarget(`partnerData-${count}`).innerText = mainCouncil.number_full_pretty
        this.nameTarget(`partnerAmountInput-${count}`).focus()
      }
    })
  }

  selectKind(ev) {
    var kind = ev.currentTarget.dataset.kind
    var count = ev.currentTarget.dataset.count
    this.nameTarget(`kindInput-${count}`).innerText = kind
  }

  removePartnerRow(ev) {
    var count = ev.currentTarget.dataset.count

    var r = confirm("Certeza que deseja remover a Linha?")
    if (r) {
      this.nameTarget(`partnerRow-${count}`).remove()

      this.bindCountTotalAmount()
    }
  }

  checkPartner(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var value = ev.currentTarget.checked
    this.showPartner(partnerId, value)
  }

  showPartner(partnerId, value) {

    if (value) {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerCpfInfoSpan-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.numberController.currencyOptionMask(0)
      this.nameTarget(`partnerAmount-${partnerId}`).focus()
    } else {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerCpfInfoSpan-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.numberController.currencyOptionMask(0)
    }

    this.bindTotalAmount()
  }

  bindCountTotalAmount() {
    var totalAmount = 0

    this.performerCountArray.forEach(count => {
      if (this.nameTarget(`partnerAmountInput-${count}`)) {
        totalAmount += Number(this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmountInput-${count}`).value))
      }
    })

    this.totalAmountTarget.innerText = this.numberController.currencyOptionMask(totalAmount)
  }

  bindTotalAmount() {
    var totalAmount = 0

    this.saveController.partners.forEach(partner => {
      if (partner.operational) {
        totalAmount += Number(this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value))
      }
    })

    this.totalAmountTarget.innerText = this.numberController.currencyOptionMask(totalAmount)
  }

  setCountInfo(ev) {
    var count = ev.currentTarget.dataset.count
    var info = ev.currentTarget.dataset.info

    if (info == `show`) {
      this.nameTarget(`partnerInfo-${count}`).innerText = `visibility_off`
      this.nameTarget(`partnerData-${count}`).classList.add("d-none")
      ev.currentTarget.dataset.info = `not_show`
    } else if (info == `not_show`) {
      this.nameTarget(`partnerInfo-${count}`).innerText = `visibility`
      this.nameTarget(`partnerData-${count}`).classList.remove("d-none")
      ev.currentTarget.dataset.info = `show`
    }
  }

  setCpfInfo(ev) {
    var count = ev.currentTarget.dataset.count
    var cpfInfo = ev.currentTarget.dataset.cpfInfo

    if (cpfInfo == `show`) {
      this.nameTarget(`partnerCpfInfo-${count}`).innerText = `no_accounts`
      ev.currentTarget.dataset.cpfInfo = `not_show`
    } else if (cpfInfo == `not_show`) {
      this.nameTarget(`partnerCpfInfo-${count}`).innerText = `how_to_reg`
      ev.currentTarget.dataset.cpfInfo = `show`
    }
  }

  setInfo(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var info = ev.currentTarget.dataset.info

    if (info == `show`) {
      this.nameTarget(`partnerInfo-${partnerId}`).innerText = `visibility_off`
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.add("d-none")
      ev.currentTarget.dataset.info = `not_show`
    } else if (info == `not_show`) {
      this.nameTarget(`partnerInfo-${partnerId}`).innerText = `visibility`
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.remove("d-none")
      ev.currentTarget.dataset.info = `show`
    }
  }

  upcase(ev) {
    var value = ev.currentTarget.value
    ev.currentTarget.value = value.toUpperCase()
  }

  percentMask(ev) {
    this.numberController.changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  getPartners() {
    var data = { partner: { clinic_id: this.saveController.current_clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_partners` } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.saveController.partners = response.data.cln
          // controller.doPartnersFormHtml()
          controller.doPartnersLineHtml()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doPartnersPreloaderHtml() {
    var html = `<div class="row mt-3 d-flex align-items-center" style="height:60px;">
                  <div class="col-2">
                    ${this.loader}
                  </div>
                  <div class="col-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-flex align-items-center" data-${this.controllerName}-target="chooseType">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                    ${this.loader}
                  </div>
                  <div class="col-4 px-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 pl-2">
                    ${this.loader}
                  </div>
                </div>

                <div class="row" data-${this.controllerName}-target="descriptionDiv">
                  <div class="col-5 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    ${this.loader}
                  </div>
                  <div class="col-6 pl-2">
                    ${this.loader}
                  </div>
                  <div class="col-10 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 pl-2">
                    ${this.loader}
                  </div>
                </div>

                <div class="row d-flex align-items-center" data-${this.controllerName}-target="infoDiv">
                  <div class="col-6 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>

                  <div class="col-3 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--save").formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.getPartners()
    })
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.saveController.hasNextBtnTarget) {
        if (controller.totalAmountTarget.innerText == `R$ 0,00`) {
          len += 1
        }

        controller.performerCountArray.forEach(count => {
          if (controller.nameTarget(`kindInput-${count}`) && controller.nameTarget(`kindInput-${count}`).innerText == ``) {
            len += 1
          } 
        })

        if (len == 0) {
          controller.saveController.nextBtnTarget.disabled = false
        } else {
          controller.saveController.nextBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}
