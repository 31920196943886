import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "subtitle", "list", "alias", "show", "content", "8888", "9999"]

  connect() {
    this.controllerName = `operations--dashboards--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.titleTarget.innerText = `Dashboard Operações & Serviços`
    this.subtitleTarget.innerText = `Tudo de Operações & Serviços da MedCapital`
    this.application.permissions = {}
    this.application.doctors = []

    this.doMainGrid()
  }

  doMainGrid() {

    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--dashboards--dashboard
                                                            operations--dashboards--reports--dashboard
                                                            
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier(`operations--dashboards--dashboard`).doMainDashboard()
    this.getControllerByIdentifier(`operations--dashboards--submenu`).doSubmenuHtml()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getDoctorsProducts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Lista de Clientes`, value: 55 })

    const data = { account: { account: true }, current_user: { current_user_id: this.application.current_user.id, feature: `operations_main_dashboard_accounts` } }
    const url = "/operations/accounts/products/list_with_accounts"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.doctors = response.data.cln

        controller.finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_main_dashboard` }, current_user: { current_user_id: this.application.current_user.id, feature: `operations_main_dashboard_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`operations_main_dashboard_permissions`]

        controller.getDoctorsProducts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
