
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "payableCount", "totalTickets", "ticketCountTooltip",
                    "totalPayables", "payableCountTooltip"]

  connect() {
    this.controllerName = `financials--books--payables--dashboard--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank');
  }

  goToDashboard() {
    this.getControllerByIdentifier("financials--books--payables--dashboard--main").doMainDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("financials--books--payables--tickets--dashboard").doTicketsDashboard()
  }

  goToPayables() {
    this.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard()
  }

  goToBills() {
    this.getControllerByIdentifier("financials--books--cards--bills--dashboard").doBillsDashboard()
  }

  goToLoans() {
    this.getControllerByIdentifier("financials--books--payables--loans--dashboard").doLoansDashboard()
  }

  goToAnnual() {
    this.getControllerByIdentifier("financials--books--payables--annual--dashboard").doPayablesDashboard()
  }

  goToPermissions() {
    this.getControllerByIdentifier("financials--books--permissions--dashboard").doPermissionsDashboard()
  }



  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var statementsBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/demonstrativos-financeiros" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Demonstrativos</button>`

    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    if (this.application.permissions.financial_payable_entities.can_index) {
      var payableBtn = `<span class="mc-tooltip">
                          <button type="button" data-action="${this.controllerName}#goToPayables ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn payableCount">Pagamentos</button>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="payableCountTooltip"></span>
                        </span>`
      // var payableBtn = `<button type="button" data-action="${this.controllerName}#goToPayables ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn payableCount">Pagamentos</button>`
    } else {
      var payableBtn = ``
    }

    if (this.application.permissions.financial_payable_loans.can_index) {
      var loanBtn = `<button type="button" data-action="${this.controllerName}#goToLoans ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Empréstimos/Parcelamentos</button>`
    } else {
      var loanBtn = ``
    }

    if (this.application.permissions.financial_payable_card_bills.can_index) {
      var billBtn = `<button type="button" data-action="${this.controllerName}#goToBills ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Faturas CC</button>`
    } else {
      var billBtn = ``
    }

    var annualBtn = `<button type="button" data-action="${this.controllerName}#goToAnnual ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anual</button>`

    // if (this.application.permissions.financial_card_bills.can_index) {
    //   var ticketBtn = `<span data-action="click->${this.controllerName}#goToBills" class="dropdown-item py-1 pointer dropdown-submenu-item">Faturas de Cartão</span>`
    // } else {
    //   var ticketBtn = ``
    // }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${annualBtn}
                  ${payableBtn}
                  ${billBtn}
                  ${loanBtn}
                  ${ticketBtn}
                  ${statementsBtn}
                </div>`

    // this.mainTarget.innerHTML = submenuHtml
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doBadgeCounts()
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
    this.doPayablesCount()
  }

  toggleBtn(ev) {
    if (this.checkSubmenu(ev.currentTarget.innerText)) {
      this.submenuBtnTargets.forEach(element => {
        element.classList.add(`btn-flat-dark`)
        element.classList.remove(`btn-primary`)
      })
      
      ev.currentTarget.classList.remove(`btn-flat-dark`)
      ev.currentTarget.classList.add(`btn-primary`)
    }
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (this.hasPayableCountTarget && element == this.payableCountTarget && [`PAGAMENTOS`].includes(title)) {
        btn = element
      // } else if (this.hasReceivableCountTarget && element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
      //   btn = element
      // } else if (this.hasTaxCountTarget && element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
      //   btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        ticketCount += 1
        if (element.stage == `in_process`) {
          ticketInProcessCount += 1
        }
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketInProcessCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doPayablesCount() {
    var payableCount = 0
    var payableUnpaidCount = 0

    var datesIds = this.mapElements(this.application.payables, `date_id`)
    var dueDatesIds = this.mapElements(this.application.payables, `due_date_id`)

    this.application.payables.forEach(element => {
      if (this.application.current_date.id == element.due_date_id) {
        payableCount += 1
        if (element.paid == false) {
          payableUnpaidCount += 1
        }
      }
    })

    if (this.hasPayableCountTarget) {
      var payableBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalPayables">${payableUnpaidCount}</span>`
      if (this.totalPayablesTargets[0]) {
        this.totalPayablesTargets[0].remove()
      }
      this.payableCountTarget.insertAdjacentHTML("beforeend", payableBadgeHtml)
      this.payableCountTooltipTarget.innerText = `${payableUnpaidCount}/${payableCount} Despesas(s) não Pagas na ${this.application.current_date.name}`
    }
  }

  checkSubmenu(text) {
    var menu = [`DEMONSTRATIVOS`]

    if (menu.includes(text)) {
      return false
    } else {
      return true
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
