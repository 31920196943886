import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "preview", "previewCard", "previewTitle", "previewCardBody", "patientInput", "patientFilter", "patientFilterItem", "9999",
                    "officeDropdown", "officeInput", "officeList", "signatureDropdown", "signatureInput", "signatureList", "officeDropdownBtn", "signatureDropdownBtn",
                    "sponsorInput", "sponsorFilter", "sponsorFilterItem", "patientCheck", "amountInput", "descriptionInput", "dateInput",
                    "procedureDropdown", "procedureDropdownBtn", "procedureInput", "procedureList", "saveBtn", "addPatientBtn", "modalPatient",
                    "formDropdown", "formDropdownBtn", "formInput", "formList", "splitInput",
                    "checkListSponsor", "checkListDescriptionSponsor", "checkListPatient", "checkListDescriptionPatient",
                    "checkListForm", "checkListDescriptionForm", "checkListSplit", "checkListDescriptionSplit",
                    "checkListProcedure", "checkListDescriptionProcedure", "checkListDescription", "checkListDescriptionDescription",
                    "checkListOffice", "checkListDescriptionOffice", "checkListSignature", "checkListDescriptionSignature",
                    "checkListDate", "checkListDescriptionDate", "checkListAmount", "checkListDescriptionAmount"]

  connect() {
    this.current_receipt = {}
    this.error = 0
    this.controllerName = `operations--products--bookings--receipts--save`
    this.setCurrentReceipt()
    this.doFormHtml()
    this.doPreviewHtml()
  }

  cancelSave() {
    this.current_receipt = {}
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doReceiptsDashboard()
  }

  saveReceipt() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()
    this.send_data = { current_user: {}, receipt: {}, notification: {}, tracker: { log: true } }
    this.saveBtnTarget.disabled = true
    if (this.actionMode == "new") {
      this.application.chart_accounts.forEach(element => {
        if (element.code == "1000") {
          this.send_data.receipt.chart_id = element.id
          this.send_data.receipt.chart = element.chart
        }
      })
    } else if (this.actionMode == "edit") {
      this.send_data.receipt.id = this.current_receipt.id
    }

    this.send_data.receipt.booking_id = this.application.booking.id
    this.send_data.receipt.date_id = this.application.current_date.id
    this.send_data.receipt.patient_id = this.patientInputTarget.dataset.id
    this.send_data.receipt.sponsor_id = this.sponsorInputTarget.dataset.id
    this.send_data.receipt.date = this.getControllerByIdentifier("app--helpers--date").transformFullMobileDate(this.dateInputTarget.value)
    this.send_data.receipt.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.receipt.description = this.descriptionInputTarget.value.trim()
    this.send_data.receipt.procedure = this.procedureInputTarget.dataset.procedure
    this.send_data.receipt.form = this.formInputTarget.dataset.form
    this.send_data.receipt.split = this.splitInputTarget.value
    this.send_data.receipt.office_id = this.officeInputTarget.dataset.id
    this.send_data.receipt.signature_id = this.signatureInputTarget.dataset.id
    
    this.send_data.current_user.current_user_id = this.application.current_user.id

    var sponsorName = this.sponsorInputTarget.value
    var patientName = this.patientInputTarget.value

    this.send_data.tracker.record_id = this.application.booking.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = this.actionMode
    this.send_data.tracker.observations = `${this.send_data.receipt.description} no valor de ${this.send_data.receipt.amount} do Resp. Financeiro ${sponsorName} e Paciente ${patientName} | Livro-Caixa ${this.application.booking.account_name} na ${this.application.current_date.name}`

    // this.send_data.notification.domain_id = this.application.booking.id
    // this.send_data.notification.domain_type = "operation_products"
    // this.send_data.notification.date_id = this.application.current_date.id
    // this.send_data.notification.date_type = "operation_dates"
    // this.send_data.notification.kind = `mode_${this.actionMode}`
    // this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o recibo ${this.current_receipt.sponsor_sex} ${this.current_receipt.sponsor_name}, no valor de ${this.amountInputTarget.value} na competência ${this.application.current_date.month_pretty}`
    // this.send_data.notification.account_name = this.application.current_user.name
    // this.send_data.notification.account_id = this.application.current_user.account_id

    this.requestSave()

  }

  requestSave() {

    if (this.actionMode == "new") {
      var url = "/operations/products/bookings/receipts/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/bookings/receipts/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var receipt = response.data.cln
          receipt = this.getControllerByIdentifier("operations--products--bookings--entities--show").updateReceipt(receipt)
          if (controller.actionMode == "new") {
            controller.application.receipts[controller.application.receipts.length] = receipt
          } else if (controller.actionMode == "edit") {
            if (receipt.active) {
              controller.application.receipts.forEach((element, i) => {
                if (element.id == receipt.id) {
                  controller.application.receipts.splice(controller.application.receipts.indexOf(element), 1, receipt)
                }
              })
            } else {
              controller.application.receipts.forEach((element, i) => {
                if (element.id == receipt.id) {
                  controller.application.receipts.splice(controller.application.receipts.indexOf(element), 1)
                }
              })
            }
          }
          controller.send_data = { current_user: {}, receipt: {} }
          controller.cancelSave()
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }

  addPatient() {
    this.application.getControllerForElementAndIdentifier(this.modalPatientTarget, "operations--products--bookings--receipts--patient").modalHtml()
  }


  doFormHtml() {

    this.stopRefreshing()

    if (this.application.permissions.medbooking_receipts.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveReceipt" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Recibo</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 pr-0">
                        <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-controller="operations--products--bookings--receipts--patient" data-action="click->${this.controllerName}#addPatient" data-${this.controllerName}-target="modalPatient addPatientBtn">
                          <span class="material-icons md-sm md-dark">person_add</span>
                          <span class="mc-tooltiptext">Adicionar Paciente</span>
                        </button>
                      </div>
                      <div class="col-4 pr-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="sponsorForm">Responsável Financeiro</label>
                            <input aria-describedby="sponsorFormHelp" class="form-control form-valid-control" id="sponsorForm" data-filter-mode="simple" data-${this.controllerName}-target="sponsorInput" data-action="focus->${this.controllerName}#sponsorFilter keyup->${this.controllerName}#sponsorFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterSponsor" placeholder="Responsável Financeiro" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="sponsorFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-3 my-3 d-flex align-items-center">
                        <div class="custom-control custom-checkbox px-0">
                          <input disabled type="checkbox" class="custom-control-input" id="checkPatientForm" data-${this.controllerName}-target="patientCheck" data-action="click->${this.controllerName}#checkIsPatient">
                          <label class="custom-control-label pointer" for="checkPatientForm"></label>
                        </div>
                        <span class="f-065 ml-3">É o Paciente?</span>
                      </div>
                      <div class="col-4 pl-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="patientForm">Paciente</label>
                            <input disabled aria-describedby="patientFormHelp" class="form-control form-valid-control" id="patientForm" data-filter-mode="simple" data-${this.controllerName}-target="patientInput" data-action="focus->${this.controllerName}#patientFilter keyup->${this.controllerName}#patientFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterPatient" placeholder="Paciente" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="patientFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    <div class="row my-2">
                      <div class="col-3">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input aria-describedby="dateFormHelp" class="form-control form-valid-control" id="dateForm" data-filter-mode="simple" data-${this.controllerName}-target="dateInput" data-action="click->${this.controllerName}#previewDate"  placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control form-valid-control" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask  blur->${this.controllerName}#previewAmount keyup->${this.controllerName}#previewAmount keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask"  placeholder="Valor" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="splitForm">Parcelas</label>
                            <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="splitInput" data-action="blur->${this.controllerName}#previewQuota"  placeholder="Parcelas" type="number" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Forma</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="formDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="formDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="formInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="formList" data-app--helpers--search-target="searchList">
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectForm" data-app--helpers--selector-target="select" data-form-pretty="Dinheiro" data-form="money" class="li-selector dark f-065">Dinheiro</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectForm" data-app--helpers--selector-target="select" data-form-pretty="Transferência Bancária" data-form="bank" class="li-selector dark f-065">Transferência Bancária</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectForm" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Débito" data-form="debt" class="li-selector dark f-065">Cartão de Débito</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectForm" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Crédito" data-form="credit" class="li-selector dark f-065">Cartão de Crédito</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Procedimento</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="procedureDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="procedureDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="procedureInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="procedureList app--helpers--search.searchList">
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProcedure" data-app--helpers--selector-target="select" data-procedure-pretty="Consulta Médica" data-procedure="medical_appointment" class="li-selector dark f-065">Consulta Médica</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProcedure" data-app--helpers--selector-target="select" data-procedure-pretty="Consulta Retorno" data-procedure="medical_return" class="li-selector dark f-065">Consulta Retorno</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProcedure" data-app--helpers--selector-target="select" data-procedure-pretty="Procedimento Invasivo" data-procedure="invasive_procedure" class="li-selector dark f-065">Procedimento Invasivo</li>
                                  <li data-action="click->app--helpers--selector#select click->${this.controllerName}#selectProcedure" data-app--helpers--selector-target="select" data-procedure-pretty="Procedimento Não-Invasivo" data-procedure="non_invasive_procedure" class="li-selector dark f-065">Procedimento Não-Invasivo</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-filter-mode="simple" data-${this.controllerName}-target="descriptionInput" data-action="blur->${this.controllerName}#previewDescription keyup->${this.controllerName}#previewDescription"  placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Consultório</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="officeDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="officeDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="officeInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="officeList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Assinatura</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="signatureDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="signatureDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="signatureInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="signatureList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                       
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListSponsor" data-${this.controllerName}-target="checkListSponsor">
                      <label class="custom-control-label pointer" for="checkListSponsor"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionSponsor"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListPatient" data-${this.controllerName}-target="checkListPatient">
                      <label class="custom-control-label pointer" for="checkListPatient"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionPatient"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListDate" data-${this.controllerName}-target="checkListDate">
                      <label class="custom-control-label pointer" for="checkListDate"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionDate"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListAmount" data-${this.controllerName}-target="checkListAmount">
                      <label class="custom-control-label pointer" for="checkListAmount"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionAmount"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListSplit" data-${this.controllerName}-target="checkListSplit">
                      <label class="custom-control-label pointer" for="checkListSplit"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionSplit"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListForm" data-${this.controllerName}-target="checkListForm">
                      <label class="custom-control-label pointer" for="checkListForm"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionForm"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListProcedure" data-${this.controllerName}-target="checkListProcedure">
                      <label class="custom-control-label pointer" for="checkListProcedure"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionProcedure"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListDescription" data-${this.controllerName}-target="checkListDescription">
                      <label class="custom-control-label pointer" for="checkListDescription"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionDescription"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListOffice" data-${this.controllerName}-target="checkListOffice">
                      <label class="custom-control-label pointer" for="checkListOffice"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionOffice"></span>
                  </div>
                  <div class="col-6 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListSignature" data-${this.controllerName}-target="checkListSignature">
                      <label class="custom-control-label pointer" for="checkListSignature"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionSignature"></span>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dateInputTarget), "monthly", this.application.current_date.year, this.application.current_date.month)
      controller.doOfficeList()
      controller.doSignatureList()
      controller.listPatients()
      controller.listSponsors()
      
      if (controller.actionMode == "edit") {
        controller.patientCheckTarget.disabled = false

        if (controller.current_receipt.patient_id == controller.current_receipt.sponsor_id) {
          controller.patientCheckTarget.checked = true
        } else {
          controller.patientCheckTarget.checked = false
        }

        controller.patientInputTarget.value = controller.current_receipt.patient_name
        controller.patientInputTarget.dataset.id = controller.current_receipt.patient_id
        controller.sponsorInputTarget.value = controller.current_receipt.sponsor_name
        controller.sponsorInputTarget.dataset.id = controller.current_receipt.sponsor_id
        controller.dateInputTarget.value = controller.current_receipt.date_pretty
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_receipt.amount * 100))
        controller.splitInputTarget.value = controller.current_receipt.split
        controller.descriptionInputTarget.value = controller.current_receipt.description
        controller.procedureDropdownTarget.value = controller.current_receipt.procedure
        controller.procedureInputTarget.innerText = controller.current_receipt.procedure_pretty
        controller.procedureInputTarget.dataset.procedure = controller.current_receipt.procedure
        controller.formDropdownTarget.value = controller.current_receipt.form
        controller.formInputTarget.innerText = controller.current_receipt.form_pretty
        controller.formInputTarget.dataset.form = controller.current_receipt.form
        controller.officeDropdownTarget.value = controller.current_receipt.office_name
        controller.officeInputTarget.innerText = controller.current_receipt.office_name
        controller.officeInputTarget.dataset.id = controller.current_receipt.office_id
        controller.signatureDropdownTarget.value = controller.current_receipt.signature_name
        controller.signatureInputTarget.innerText = controller.current_receipt.signature_name
        controller.signatureInputTarget.dataset.id = controller.current_receipt.signature_id
      } else if (controller.actionMode == "new") {
        controller.splitInputTarget.value = 1
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }


  doPreviewHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Pré-visualização do Novo Recibo</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>

                            <tr>
                              <td colspan="10" class="text-center s-title-6F706F s-title-500 s-title-1rem">
                                Recibo Médico
                              </td>
                            </tr>

                            <tr>
                              <th colspan="1" class="f-065 align-middle px-0">
                                <strong>Médico</strong> <br>
                                <strong>CPF</strong>
                              </th>
                              <td colspan="9" class="f-065 align-middle px-0 text-left">
                                ${this.current_receipt.doctor_name} <br>
                                ${this.current_receipt.doctor_cpf_pretty}
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-left">
                                Recebi a importância de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_receipt.amount)},
                                em ${this.current_receipt.split_pretty}, por meio de ${this.current_receipt.form_pretty},
                                ${this.current_receipt.sponsor_sex} ${this.current_receipt.sponsor_name}, CPF: ${this.current_receipt.sponsor_cpf_pretty}
                                referente a ${this.current_receipt.procedure_pretty}, conforme descrição abaixo, ${this.current_receipt.patient_sex} ${this.current_receipt.patient_name}, CPF: ${this.current_receipt.patient_cpf_pretty}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-left">
                                <strong>Descrição</strong>: ${this.current_receipt.description}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-left">
                                ${this.current_receipt.office_city}, ${this.current_receipt.date_full_extension}
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <td colspan="3" class="f-065 align-middle px-0"></td>
                              <td colspan="4" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                <hr class="m-0"> <br>
                                ${this.current_receipt.signature_name} <br>
                                ${this.current_receipt.signature_title} <br>
                                ${this.current_receipt.signature_number}
                              </td>
                              <td colspan="3" class="f-065 align-middle px-0"></td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                <small>${this.current_receipt.office_name}</small> <br>
                                <small>
                                  ${this.current_receipt.office_street}, ${this.current_receipt.office_number}, ${this.current_receipt.office_complement},
                                  ${this.current_receipt.office_district}, ${this.current_receipt.office_city}/${this.current_receipt.office_state},
                                  CEP: ${this.current_receipt.office_postal_code_pretty}
                                </small>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                <small>Documento <strong>com validade</strong> para comprovante de gasto com saúde apenas na Declaração de Ajuste Anual Ano-Exercício ${this.application.current_date.year + 1}.</small>
                              </td>
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    this.previewTarget.innerHTML = html
  }


  doOfficeList() {
    var html = ``
    if (this.application.offices.length == 0) {
      this.officeDropdownBtnTarget.disabled = true
      this.officeError = true
      this.officeInputTarget.innerText = `Configurar consultório`
      this.officeDropdownTarget.value = `Configurar consultório`
    } else if (this.application.offices.length == 1) {
      this.officeDropdownBtnTarget.disabled = true
      this.officeInputTarget.innerText = this.application.offices[0].name
      this.officeInputTarget.dataset.id = this.application.offices[0].id
      this.officeDropdownTarget.value = this.application.offices[0].id

      this.officeError = false
      this.current_receipt.office_id = this.application.offices[0].id
      this.current_receipt.office_name = this.application.offices[0].name
      this.current_receipt.office_street = this.application.offices[0].street
      this.current_receipt.office_number = this.application.offices[0].number
      this.current_receipt.office_complement = this.application.offices[0].complement
      this.current_receipt.office_district = this.application.offices[0].district
      this.current_receipt.office_city = this.application.offices[0].city
      this.current_receipt.office_state = this.application.offices[0].state
      this.current_receipt.office_postal_code_pretty = this.application.offices[0].postal_code_pretty
      this.doPreviewHtml()
    } else {
      this.officeError = false
      this.application.offices.forEach(element => {
        html += `<li data-id="${element.id}" class="li-selector dark f-065" data-app--helpers--selector-target="select" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectOffice">${element.name} | ${element.district}</li>`
      });
    }

    this.officeListTarget.innerHTML = html
  }

  doSignatureList() {
    var html = ``
    // signatureDropdownBtn
    if (this.application.signatures.length == 0) {
      this.signatureDropdownBtnTarget.disabled = true
      this.signatureError = true
      this.signatureInputTarget.innerText = `Configurar assinatura`
      this.signatureDropdownTarget.value = `Configurar assinatura`
    } else if (this.application.signatures.length == 1) {
      this.signatureDropdownBtnTarget.disabled = true
      this.signatureInputTarget.innerText = this.application.signatures[0].name
      this.signatureInputTarget.dataset.id = this.application.signatures[0].id
      this.signatureDropdownTarget.value = this.application.signatures[0].id

      this.signatureError = false
      this.current_receipt.signature_id = this.application.signatures[0].id
      this.current_receipt.signature_name = this.application.signatures[0].name
      this.current_receipt.signature_title = this.application.signatures[0].title
      this.current_receipt.signature_number = this.application.signatures[0].number
      this.doPreviewHtml()

    } else {
      this.signatureError = false
      this.application.signatures.forEach(element => {
        html += `<li data-id="${element.id}" class="li-selector dark f-065" data-app--helpers--selector-target="select" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectSignature">${element.name} | ${element.title} | ${element.number}</li>`
      });
    }

    this.signatureListTarget.innerHTML = html
  }

  listPatients() {
    var html = ``
    if (this.application.patients.length == 0) {
      html += `<li class="li-selector dark f-065"">Não existem pacientes</li>`
    } else {
      this.application.patients.forEach(element => {
        html += `<li data-id="${element.id}" data-filter="${element.id}" data-text="${element.account_name}" data-id="${element.id}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectPatient" data-${this.controllerName}-target="patientFilterItem">${element.account_name}</li>`
      });
    }

    this.patientFilterTarget.innerHTML = html
  }

  selectPatient(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.setPatient()

    // var patient = ``
    // this.application.patients.forEach(element => {
    //   if (element.id == ev.target.dataset.id) {
    //     patient = element
    //   }
    // });
    // this.patientInputTarget.dataset.id = patient.id
    // if (patient.account_sex == "male") {
    //   this.current_receipt.patient_sex = `do Paciente`
    // } else if (patient.account_sex == "female") {
    //   this.current_receipt.patient_sex = `da Paciente`
    // }
    // this.current_receipt.patient_id = patient.account_name
    // this.current_receipt.patient_name = patient.account_name
    // this.current_receipt.patient_cpf_pretty = patient.account_cpf_pretty
    // this.doPreviewHtml()
  }

  enterPatient(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.setPatient()
    }
  }

  setPatient() {
    var patient = ``
    this.application.patients.forEach(element => {
      if (element.id == this.patientInputTarget.dataset.filter) {
        patient = element
      }
    });
    this.patientInputTarget.dataset.id = patient.id
    if (patient.account_sex == "male") {
      this.current_receipt.patient_sex = `do Paciente`
    } else if (patient.account_sex == "female") {
      this.current_receipt.patient_sex = `da Paciente`
    }
    this.current_receipt.patient_id = patient.account_name
    this.current_receipt.patient_name = patient.account_name
    this.current_receipt.patient_cpf_pretty = patient.account_cpf_pretty
    this.doPreviewHtml()
  }

  listSponsors() {
    var html = ``
    if (this.application.patients.length == 0) {
      html += `<li class="li-selector dark f-065"">Não existem Responsáveis Financeiros</li>`
    } else {
      this.application.patients.forEach(element => {
        if (element.sponsor) {
          html += `<li data-id="${element.id}" data-filter="${element.id}" data-text="${element.account_name}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectSponsor" data-${this.controllerName}-target="sponsorFilterItem">${element.account_name}</li>`
        }
      });
    }
    this.sponsorFilterTarget.innerHTML = html
  }

  selectSponsor(ev) {
    this.patientCheckTarget.disabled = false
    this.patientInputTarget.disabled = false
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.setSponsor()
  }

  enterSponsor(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.patientCheckTarget.disabled = false
      this.patientInputTarget.disabled = false
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.setSponsor()
    }
  }

  setSponsor() {
    var sponsor = ``
    this.application.patients.forEach(element => {
      if (element.id == this.sponsorInputTarget.dataset.filter) {
        sponsor = element
      }
    });
    this.sponsorInputTarget.dataset.id = sponsor.id
    if (sponsor.account_sex == "male") {
      this.current_receipt.sponsor_sex = `do Responsável Financeiro`
    } else if (sponsor.account_sex == "female") {
      this.current_receipt.sponsor_sex = `da Responsável Financeira`
    }
    this.current_receipt.sponsor_id = sponsor.id
    this.current_receipt.sponsor_name = sponsor.account_name
    this.current_receipt.sponsor_cpf_pretty = sponsor.account_cpf_pretty
    this.doPreviewHtml()
  }

  selectOffice(ev) {
    var office = ``
    this.application.offices.forEach(element => {
      if (element.id == ev.target.dataset.id) {
        office = element
      }
    });

    this.current_receipt.office_name = office.name
    this.current_receipt.office_street = office.street
    this.current_receipt.office_number = office.number
    this.current_receipt.office_complement = office.complement
    this.current_receipt.office_district = office.district
    this.current_receipt.office_city = office.city
    this.current_receipt.office_state = office.state
    this.current_receipt.office_postal_code_pretty = office.postal_code_pretty
    this.doPreviewHtml()
  }

  selectSignature(ev) {

    var signature = ``
    this.application.signatures.forEach(element => {
      if (element.id == ev.target.dataset.id) {
        signature = element
      }
    });

    this.current_receipt.signature_name = signature.name
    this.current_receipt.signature_title = signature.title
    this.current_receipt.signature_number = signature.number
    this.doPreviewHtml()
  }
  
  patientFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  sponsorFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  checkIsPatient(ev) {

    if (ev.currentTarget.checked) {
      var patient = ``
      this.application.patients.forEach(element => {
        if (element.id == this.current_receipt.sponsor_id) {
          patient = element
        }
      });

      this.patientInputTarget.value = patient.account_name
      this.patientInputTarget.dataset.id = patient.id
      this.patientInputTarget.disabled = true

      if (patient.account_sex == "male") {
        this.current_receipt.patient_sex = `do Paciente`
      } else if (patient.account_sex == "female") {
        this.current_receipt.patient_sex = `da Paciente`
      }
      this.current_receipt.patient_name = patient.account_name
      this.current_receipt.patient_cpf_pretty = patient.account_cpf_pretty
      this.doPreviewHtml()
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    } else {
      this.patientInputTarget.value = ``
      this.patientInputTarget.disabled = false
      this.current_receipt.patient_sex = ``
      this.current_receipt.patient_name = ``
      this.current_receipt.patient_cpf_pretty = ``
      this.doPreviewHtml()
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }

  }

  setCurrentReceipt() {
    this.current_receipt.doctor_name = this.application.booking.account_name
    this.current_receipt.doctor_cpf_pretty = this.application.booking.account_cpf_pretty
    this.current_receipt.date_pretty = ``
    this.current_receipt.date_full_extension = ``
    this.current_receipt.amount = ``
    this.current_receipt.sponsor_name = ``
    this.current_receipt.sponsor_sex = ``
    this.current_receipt.sponsor_cpf_pretty = ``
    this.current_receipt.patient_name = ``
    this.current_receipt.patient_sex = ``
    this.current_receipt.patient_cpf_pretty = ``
    this.current_receipt.form_pretty = ``
    this.current_receipt.split_pretty = ``
    this.current_receipt.procedure_pretty = ``
    this.current_receipt.description = ``
    this.current_receipt.signature_name = ``
    this.current_receipt.signature_title = ``
    this.current_receipt.signature_number = ``
    this.current_receipt.office_name = ``
    this.current_receipt.office_street = ``
    this.current_receipt.office_number = ``
    this.current_receipt.office_complement = ``
    this.current_receipt.office_district = ``
    this.current_receipt.office_city = ``
    this.current_receipt.office_state = ``
    this.current_receipt.office_postal_code_pretty = ``
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  previewAmount(ev) {
    this.current_receipt.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(ev.target.value)
    this.doPreviewHtml()
  }

  previewQuota(ev) {
    this.current_receipt.split = ev.target.value
    if (ev.target.value > 1) {
      this.current_receipt.split_pretty = `${ev.target.value} parcelas`
    } else {
      this.current_receipt.split_pretty = `1 parcela`
    }
    this.doPreviewHtml()
  }

  selectForm(ev) {
    this.current_receipt.form = ev.target.dataset.form
    this.current_receipt.form_pretty = ev.target.dataset.formPretty
    this.doPreviewHtml()
  }

  selectProcedure(ev) {
    this.current_receipt.procedure = ev.target.dataset.procedure
    this.current_receipt.procedure_pretty = ev.target.dataset.procedurePretty
    this.doPreviewHtml()

    this.descriptionInputTarget.value = ev.target.dataset.procedurePretty
    this.descriptionInputTarget.focus()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  previewDescription(ev) {
    this.current_receipt.description = ev.target.value
    this.doPreviewHtml()
  }

  previewDate(ev) {
    clearInterval(this.refreshPreviewDate)
    ev.target.value = ""
    var controller  = this
    this.refreshPreviewDate = setInterval(function () {
      if (ev.target.value) {
        controller.current_receipt.date_pretty = ev.target.value
        controller.current_receipt.date_full_extension = controller.getControllerByIdentifier("app--helpers--date").fullExtension(ev.target.value)
        controller.doPreviewHtml()
        clearInterval(controller.refreshPreviewDate)
      }
    }, 200);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      controller.error = 0

      if (controller.sponsorInputTarget.dataset.id == "" || controller.sponsorInputTarget.dataset.id == undefined) {
        controller.error += 1
        controller.checkListSponsorTarget.checked = false
        controller.checkListDescriptionSponsorTarget.innerHTML = `<span>Preenchimento do Responsável Financeiro</span>`
      } else {
        controller.checkListSponsorTarget.checked = true
        controller.checkListDescriptionSponsorTarget.innerHTML = `<span><s>Preenchimento do Responsável Financeiro</s></span>`
      }

      if (controller.patientInputTarget.dataset.id == "" || controller.patientInputTarget.dataset.id == undefined) {
        controller.error += 1
        controller.checkListPatientTarget.checked = false
        controller.checkListDescriptionPatientTarget.innerHTML = `<span>Preenchimento do Paciente</span>`
      } else {
        controller.checkListPatientTarget.checked = true
        controller.checkListDescriptionPatientTarget.innerHTML = `<span><s>Preenchimento do Paciente</s></span>`
      }

      if (controller.dateInputTarget.value == "") {
        controller.error += 1
        controller.checkListDateTarget.checked = false
        controller.checkListDescriptionDateTarget.innerHTML = `<span>Preenchimento da Data de Atendimento</span>`
      } else {
        controller.checkListDateTarget.checked = true
        controller.checkListDescriptionDateTarget.innerHTML = `<span><s>Preenchimento da Data de Atendimento</s></span>`
      }

      if (controller.splitInputTarget.value == "" || controller.splitInputTarget.value == 0) {
        controller.error += 1
        controller.checkListSplitTarget.checked = false
        controller.checkListDescriptionSplitTarget.innerHTML = `<span>Preenchimento do Número de Parcelas</span>`
      } else {
        controller.checkListSplitTarget.checked = true
        controller.checkListDescriptionSplitTarget.innerHTML = `<span><s>Preenchimento do Número de Parcelas</s></span>`
      }

      if (controller.amountInputTarget.value == "" || controller.amountInputTarget.value == `R$ 0,00`) {
        controller.error += 1
        controller.checkListAmountTarget.checked = false
        controller.checkListDescriptionAmountTarget.innerHTML = `<span>Preenchimento do Valor do Atendimento</span>`
      } else {
        controller.checkListAmountTarget.checked = true
        controller.checkListDescriptionAmountTarget.innerHTML = `<span><s>Preenchimento do Valor do Atendimento</s></span>`
      }

      if (controller.formInputTarget.dataset.form == "" || controller.formInputTarget.dataset.form == undefined) {
        controller.error += 1
        controller.checkListFormTarget.checked = false
        controller.checkListDescriptionFormTarget.innerHTML = `<span>Preenchimento da Forma de Pagamento</span>`
      } else {
        controller.checkListFormTarget.checked = true
        controller.checkListDescriptionFormTarget.innerHTML = `<span><s>Preenchimento da Forma de Pagamento</s></span>`
      }

      if (controller.procedureInputTarget.dataset.procedure == "" || controller.procedureInputTarget.dataset.procedure == undefined) {
        controller.error += 1
        controller.checkListProcedureTarget.checked = false
        controller.checkListDescriptionProcedureTarget.innerHTML = `<span>Preenchimento da Forma de Pagamento</span>`
      } else {
        controller.checkListProcedureTarget.checked = true
        controller.checkListDescriptionProcedureTarget.innerHTML = `<span><s>Preenchimento da Forma de Pagamento</s></span>`
      }

      if (controller.descriptionInputTarget.value == "") {
        controller.error += 1
        controller.checkListDescriptionTarget.checked = false
        controller.checkListDescriptionDescriptionTarget.innerHTML = `<span>Preenchimento da Descrição</span>`
      } else {
        controller.checkListDescriptionTarget.checked = true
        controller.checkListDescriptionDescriptionTarget.innerHTML = `<span><s>Preenchimento da Descrição</s></span>`
      }

      if (controller.officeError || controller.officeInputTarget.dataset.id == "" || controller.officeInputTarget.dataset.id == undefined) {
        controller.error += 1
        controller.checkListOfficeTarget.checked = false
        controller.checkListDescriptionOfficeTarget.innerHTML = `<span>Preenchimento do Consultório</span>`
      } else {
        controller.checkListOfficeTarget.checked = true
        controller.checkListDescriptionOfficeTarget.innerHTML = `<span><s>Preenchimento do Consultório</s></span>`
      }

      if (controller.signatureError || controller.signatureInputTarget.dataset.id == "" || controller.signatureInputTarget.dataset.id == undefined) {
        controller.error += 1
        controller.checkListSignatureTarget.checked = false
        controller.checkListDescriptionSignatureTarget.innerHTML = `<span>Preenchimento da Assinatura</span>`
      } else {
        controller.checkListSignatureTarget.checked = true
        controller.checkListDescriptionSignatureTarget.innerHTML = `<span><s>Preenchimento da Assinatura</s></span>`
      }
      
      if (controller.error == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }


    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.refreshPreviewDate) {
      clearInterval(this.refreshPreviewDate)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
