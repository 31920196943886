import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pfPartnerCheckbox", "pjPartnerCheckbox", "chooseType", "cpfInputDiv", "cpfInput", "cnpjInputDiv",
                    "cnpjInput", "takerNameInput", "takerDescriptionInput", "takerNotesInput",
                    "descriptionDiv", "descriptionInput", "infoDiv", "regimeInput", "productDateInput",
                    "issuedOnInput", "takerDescriptionCopy", "changeTaxationTypeCheckbox",
                    "ctissDropdown", "ctissDropdownBtn", "ctissInput", "ctissList", "ctissLabel", "showChannelCheckbox",

                    "takerTaxationDropdown", "takerTaxationDropdownBtn", "takerTaxationInput", "takerTaxationList",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList"]

  connect() {
    this.controllerName = "operations--products--clinics--financials--add--informations"
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.changedTaxationType = false

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  getData() {
    // var saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)

    this.saveController.send_data.receivable.taker_id = this.takerNameInputTarget.dataset.takerId
    this.saveController.send_data.receivable.activity_id = this.ctissInputTarget.dataset.activityId
    this.saveController.send_data.receivable.cnae_code = this.ctissInputTarget.dataset.cnaeCode
    this.saveController.send_data.receivable.cnae_description = this.ctissInputTarget.innerText
    this.saveController.send_data.receivable.ctiss_code = this.ctissInputTarget.dataset.ctissCode
    this.saveController.send_data.receivable.ctiss_description = this.ctissInputTarget.dataset.ctissDescription
    this.saveController.send_data.receivable.city_service_code = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.ctissInputTarget.dataset.cityServiceCode)
    this.saveController.send_data.receivable.federal_service_code = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.ctissInputTarget.dataset.federalServiceCode)
    this.saveController.send_data.receivable.regime_id = this.regimeInputTarget.dataset.regimeId
    this.saveController.send_data.receivable.date_pretty = this.saveController.current_date.name
    this.saveController.send_data.receivable.date_id = this.saveController.current_date.id
    this.saveController.send_data.receivable.issued_on = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.issuedOnInputTarget.value)
    this.saveController.send_data.receivable.issued_on_pretty = this.issuedOnInputTarget.value
    // this.saveController.send_data.receivable.description = this.descriptionInputTarget.value
    this.saveController.send_data.receivable.channel_id = this.channelInputTarget.dataset.channelId
    this.saveController.send_data.receivable.channel_name = this.channelInputTarget.innerText
    this.saveController.send_data.receivable.method = `bank_transfer`
    // this.saveController.send_data.receivable.kind = this.kindInputTarget.dataset.kind

    if (this.showChannelCheckboxTarget.checked == true) {
      this.saveController.send_data.receivable.description = `${this.descriptionInputTarget.value} | ${this.channelInputTarget.innerText}`
    } else if (this.showChannelCheckboxTarget.checked == false) {
      this.saveController.send_data.receivable.description = this.descriptionInputTarget.value
    }

    if (this.changedTaxationType) {
      if (this.takerTaxationInputTarget.dataset.taxationType == `within_city`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      } else if (this.takerTaxationInputTarget.dataset.taxationType == `outside_city`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_taker.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_taker.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_taker.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `outside_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Cidade Diferente`

      } else if (this.takerTaxationInputTarget.dataset.taxationType == `none`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      }
      
    } else {
      if (this.saveController.current_taker.id_type == `individual`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      } else if (this.saveController.current_taker.id_type == `legal`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_taker.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_taker.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_taker.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = this.saveController.current_taker.taxation_type
        this.saveController.send_data.receivable.taker_taxation_type_pretty = this.saveController.current_taker.taxation_type_pretty
        
        // "within_city" => "Mesma Cidade",
        // "outside_city" => "Cidade Diferente",
      }
    }

  }

  doInformationsFormHtml() {

    // <div class="form-group">
    //   <div class="floating-label floating-label-sm">
    //     <label for="takerTaxationForm">Natureza da Operação</label>
    //     <input aria-describedby="takerTaxationFormHelp" class="form-control" id="takerTaxationForm" data-${this.controllerName}-target="takerTaxationInput" placeholder="Natureza da Operação" type="text" required>
    //   </div>
    // </div>
    // `<input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição Geral da NF" type="text" required>`

    // `<div class="col-3">
    //   <div class="form-group">
    //     <div class="floating-label floating-label-sm">
    //       <label>Tipo de Atendimento</label>
    //       <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
    //         <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
    //         <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
    //           <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
    //           <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
    //             <li data-kind="practice" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consulta Médica</li>
    //             <li data-kind="procedure" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Procedimento Médico</li>
    //             <li data-kind="oncall" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Plantão Médico</li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>`


    var html = `<div class="row mt-3 d-flex align-items-center" style="height:60px;">
                  <div class="col-2">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="pfPartnerCheck" data-${this.controllerName}-target="pfPartnerCheckbox" data-type="individual">
                      <label class="custom-control-label f-065 pointer" for="pfPartnerCheck">Tomador PF</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="pjPartnerCheck" data-${this.controllerName}-target="pjPartnerCheckbox" data-type="legal">
                      <label class="custom-control-label f-065 pointer" for="pjPartnerCheck">Tomador PJ</label>
                    </div>
                  </div>
                  <div class="col-2 px-2 d-flex align-items-center" data-${this.controllerName}-target="chooseType">
                    <span class="f-065">Escolha o Tipo de Tomador</span>
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="cpfForm">CPF Tomador</label>
                        <input aria-describedby="cpfFormHelp" class="form-control" id="cpfForm" data-type="individual" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate keyup->${this.controllerName}#searchTaker" placeholder="CPF Tomador" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="cnpjForm">CNPJ Tomador</label>
                        <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-type="legal" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate keyup->${this.controllerName}#searchTaker" placeholder="CNPJ Tomador" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="nameForm">Nome Tomador</label>
                        <input disabled aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="takerNameInput" placeholder="Nome Tomador" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pl-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="productDateForm">Competência</label>
                        <input disabled aria-describedby="productDateFormHelp" class="form-control" id="productDateForm" data-${this.controllerName}-target="productDateInput" placeholder="Competência" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-none" data-${this.controllerName}-target="descriptionDiv">
                  <div class="col-5 pr-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="takerDescriptionForm">Descrição de referência para emissão da NF</label>
                        <input disabled aria-describedby="takerDescriptionFormHelp" class="form-control" id="takerDescriptionForm" data-${this.controllerName}-target="takerDescriptionInput" placeholder="Descrição de referência para emissão da NF" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    <span class="mb-0 f-065 mc-tooltip">
                      <span class="material-icons md-150 md-light pointer" data-copy="" data-${this.controllerName}-target="takerDescriptionCopy" data-action="click->${this.controllerName}#copyToDescription">file_copy</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar a Descrição</span>
                    </span>
                  </div>
                  <div class="col-6 pl-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="takerNotesForm">Observações para emissão da NF</label>
                        <input disabled aria-describedby="takerNotesFormHelp" class="form-control" id="takerNotesForm" data-${this.controllerName}-target="takerNotesInput" placeholder="Observações para emissão da NF" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-10 pr-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="descriptionForm">Descrição Geral da NF</label>
                        <textarea aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição Geral da NF" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pl-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="issuedOnForm">Data Emissão</label>
                        <input aria-describedby="issuedOnFormHelp" class="form-control form-valid-control" id="issuedOnForm" data-${this.controllerName}-target="issuedOnInput" placeholder="Data Emissão" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="infoDiv">
                  <div class="col-9 pr-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Atividade Econômica | Código de tributação do Município</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="ctissDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="ctissDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="ctissInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="ctissList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="regimeForm">Regime Especial de Tributação</label>
                        <input disabled aria-describedby="regimeFormHelp" class="form-control" id="regimeForm" data-${this.controllerName}-target="regimeInput" placeholder="Regime Especial de Tributação" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="infoDiv">
                  <div class="col-3 px-2">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="changeTaxationTypeCheck" data-${this.controllerName}-target="changeTaxationTypeCheckbox" data-action="click->${this.controllerName}#changeTaxationTypeCheckbox" data-type="legal">
                      <label class="custom-control-label f-065 pointer" for="changeTaxationTypeCheck">Alterar Natureza da Operação</label>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Natureza da Operação</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="takerTaxationDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="takerTaxationDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="takerTaxationInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="takerTaxationList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-3 pr-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Banco</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="showChannelCheck" data-${this.controllerName}-target="showChannelCheckbox" data-type="legal">
                      <label class="custom-control-label f-065 pointer" for="showChannelCheck">Mostrar Banco na Descrição</label>
                    </div>
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      var date = new Date(controller.saveController.current_date.year, controller.saveController.current_date.month, 0)
      var today = new Date()
      
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.issuedOnInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1 )
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.issuedOnInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1 )

      if (date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
        controller.issuedOnInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(today)
      }

      controller.saveController.formTitleTarget.innerText = `Informações para Emissão da Nota Fiscal da ${this.saveController.current_clinic.company_name}`
      controller.productDateInputTarget.value = controller.saveController.current_date.name
      controller.productDateInputTarget.dataset.dateId = controller.saveController.current_date.id

      controller.showChannelCheckboxTarget.checked = false

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
      controller.setInformationForm()
    })
  }

  copyToDescription(ev) {
    var copy = ev.currentTarget.dataset.copy
    this.descriptionInputTarget.value = copy
    this.descriptionInputTarget.focus()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setInformationForm() {

    var type = this.saveController.current_taker.id_type

    if (type == "individual") {
      this.pfPartnerCheckboxTarget.checked = true
      this.pjPartnerCheckboxTarget.checked = false
      this.pfPartnerCheckboxTarget.disabled = true
      this.pjPartnerCheckboxTarget.disabled = true
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")
      this.takerNameInputTarget.value = ``
      this.cnpjInputTarget.value = ``
      this.cpfInputTarget.disabled = true
      this.cpfInputTarget.value = this.saveController.current_taker.id_number_pretty
    } else if (type == "legal") {
      this.pfPartnerCheckboxTarget.checked = false
      this.pjPartnerCheckboxTarget.checked = true
      this.pfPartnerCheckboxTarget.disabled = true
      this.pjPartnerCheckboxTarget.disabled = true
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")
      this.takerNameInputTarget.value = ``
      this.cpfInputTarget.value = ``
      this.cnpjInputTarget.disabled = true
      this.cnpjInputTarget.value = this.saveController.current_taker.id_number_pretty
    }

    this.takerNameInputTarget.dataset.takerId = this.saveController.current_taker.id
    this.takerNameInputTarget.value = this.saveController.current_taker.name
    this.takerDescriptionInputTarget.value = this.saveController.current_taker.description
    this.takerDescriptionCopyTarget.dataset.copy = this.saveController.current_taker.description
    this.descriptionInputTarget.value = this.saveController.current_taker.description
    this.takerNotesInputTarget.value = this.saveController.current_taker.notes
    // this.takerTaxationInputTarget.value = this.saveController.current_taker.taxation_type_pretty
    this.descriptionDivTargets.forEach(taker => {
      taker.classList.remove("d-none")
    })
    this.infoDivTargets.forEach(taker => {
      taker.classList.remove("d-none")
    })
    this.setActivities()
    this.setChannels()
    this.setRegime()
    this.setTaxationType()

    this.descriptionInputTarget.focus()

    if (this.saveController.replaceMode) {
      this.descriptionInputTarget.value = `Substituição NF ${this.saveController.replace_receivable.main_invoice.invoice_number_pretty} | ${this.saveController.replace_receivable.description}`
      this.issuedOnInputTarget.value = this.saveController.replace_receivable.issued_on_pretty
    }


    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectKind(ev) {
    this.kindInputTarget.innerText = ev.currentTarget.innerText
  }

  setActivities() {
    var html = ``
    var replaceActivity = {}
    this.saveController.activities.forEach(activity => {

      if (activity.status == `active`) {
        html += `<li data-cnae="${activity.cnae_code}" data-activity-id="${activity.id}" data-action="click->${this.controllerName}#selectActivity click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${activity.cnae_code} | ${activity.cnae_description}</li>`
        
        if (activity.kind == `main`) {
          this.ctissDropdownTarget.value = activity.id
          this.ctissInputTarget.innerText = `${activity.cnae_code} | ${activity.cnae_description}`
          this.ctissInputTarget.dataset.activityId = activity.id
          this.ctissInputTarget.dataset.cnaeCode = activity.cnae_code
          this.ctissInputTarget.dataset.ctissCode = activity.ctiss_code
          this.ctissInputTarget.dataset.ctissDescription = activity.ctiss_description
          this.ctissInputTarget.dataset.cityServiceCode = activity.city_service_code
          this.ctissInputTarget.dataset.federalServiceCode = activity.federal_service_code
        }

        if (this.saveController.replaceMode && (activity.id == this.saveController.replace_receivable.activity_id)) {
          replaceActivity = activity
        }
      }

    })

    if (this.saveController.replaceMode) {
      this.ctissDropdownTarget.value = replaceActivity.id
      this.ctissInputTarget.innerText = `${replaceActivity.cnae_code} | ${replaceActivity.cnae_description}`
      this.ctissInputTarget.dataset.replaceActivityId = replaceActivity.id
      this.ctissInputTarget.dataset.cnaeCode = replaceActivity.cnae_code
      this.ctissInputTarget.dataset.ctissCode = replaceActivity.ctiss_code
      this.ctissInputTarget.dataset.ctissDescription = replaceActivity.ctiss_description
      this.ctissInputTarget.dataset.cityServiceCode = replaceActivity.city_service_code
      this.ctissInputTarget.dataset.federalServiceCode = replaceActivity.federal_service_code
    }

    this.ctissListTarget.innerHTML = html
  }

  selectActivity(ev) {

    var activityId = ev.currentTarget.dataset.activityId

    this.saveController.activities.forEach(element => {
      if (element.id == activityId) {
        this.ctissDropdownTarget.value = element.id
        this.ctissInputTarget.innerText = element.cnae_description
        this.ctissInputTarget.dataset.activityId = element.id
        this.ctissInputTarget.dataset.cnaeCode = element.cnae_code
        this.ctissInputTarget.dataset.ctissCode = element.ctiss_code
        this.ctissInputTarget.dataset.ctissDescription = element.ctiss_description
        this.ctissInputTarget.dataset.cityServiceCode = element.city_service_code
        this.ctissInputTarget.dataset.federalServiceCode = element.federal_service_code
      }
    })
    
  }

  setRegime() {
    this.regimeInputTarget.value = this.saveController.current_regime.special_tax_regime_pretty
    this.regimeInputTarget.dataset.regimeId = this.saveController.current_regime.id
  }

  setChannels() {
    var html = ``
    var replaceChannel = {}
    this.saveController.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectChannel click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`
        
        if (channel.kind == `main`) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }

        if (this.saveController.replaceMode && (channel.id == this.saveController.replace_receivable.channel_id)) {
          replaceChannel = channel
        }
      }
    })

    if (this.saveController.replaceMode) {
      this.channelDropdownTarget.value = replaceChannel.id
      this.channelInputTarget.innerText = `${replaceChannel.name}`
      this.channelInputTarget.dataset.channelId = replaceChannel.id
    }

    this.channelListTarget.innerHTML = html
  }

  selectChannel(ev) {
    var channelId = ev.currentTarget.dataset.channelId

    this.saveController.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  setTaxationType() {
    var html = ``
    html += `<li data-taxation-type="within_city" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesma Cidade</li>`
    html += `<li data-taxation-type="outside_city" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cidade Diferente</li>`
    html += `<li data-taxation-type="none" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`

    this.takerTaxationDropdownTarget.value = this.saveController.current_taker.taxation_type
    this.takerTaxationInputTarget.innerText = this.saveController.current_taker.taxation_type_pretty
    this.takerTaxationInputTarget.dataset.taxationType = this.saveController.current_taker.taxation_type

    this.takerTaxationListTarget.innerHTML = html

    if (this.saveController.current_taker.id_type == `individual`) {
      this.changeTaxationTypeCheckboxTarget.disabled = true
    } else if (this.saveController.current_taker.id_type == `legal`) {
      this.changeTaxationTypeCheckboxTarget.disabled = false
    }
    this.takerTaxationDropdownBtnTarget.disabled = true
  }

  selectTaxationType(ev) {

    var taxationType = ev.currentTarget.dataset.taxationType
    var taxationTypePretty = ev.currentTarget.innerText

    this.takerTaxationDropdownTarget.value = taxationType
    this.takerTaxationInputTarget.innerText = taxationTypePretty
    this.takerTaxationInputTarget.dataset.taxationType = taxationType

    this.changedTaxationType = true

  }

  changeTaxationTypeCheckbox(ev) {
    var change = ev.currentTarget.checked

    if (change) {
      this.takerTaxationDropdownBtnTarget.disabled = false
    } else {
      this.takerTaxationDropdownBtnTarget.disabled = true
    }
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 60 })

    var data = { channel: { clinic_id: this.saveController.current_clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_channels` } }
    const url = "/operations/products/clinics/financials/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.saveController.channels = response.data.cln
          controller.doInformationsFormHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Atividades Econômicas`, value: 15 })
    
    var data = { activity: { clinic_id: this.saveController.current_clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_activities` } }
    const url = "/operations/products/clinics/regularizations/activities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.saveController.activities = response.data.cln
        }

        controller.getChannels()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRegime() {
    var data = { regime: { clinic_id: this.saveController.current_clinic.id, date_id: this.saveController.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_regimes` } }
    const url = `/operations/products/clinics/financials/regimes/read_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.saveController.current_regime = response.data.cln

        controller.getActivities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getDate() {
    var data = { date: { clinic_id: this.saveController.current_clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_dates` } }
    const url = `/operations/products/clinics/managements/dates/read_current_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.saveController.current_date = response.data.cln
        controller.getRegime()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doInformationsPreloaderHtml() {
    var html = `<div class="row mt-3 d-flex align-items-center" style="height:60px;">
                  <div class="col-2">
                    ${this.loader}
                  </div>
                  <div class="col-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-flex align-items-center" data-${this.controllerName}-target="chooseType">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                    ${this.loader}
                  </div>
                  <div class="col-2 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                    ${this.loader}
                  </div>
                  <div class="col-4 px-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 pl-2">
                    ${this.loader}
                  </div>
                </div>

                <div class="row" data-${this.controllerName}-target="descriptionDiv">
                  <div class="col-5 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    ${this.loader}
                  </div>
                  <div class="col-6 pl-2">
                    ${this.loader}
                  </div>
                  <div class="col-10 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-2 pl-2">
                    ${this.loader}
                  </div>
                </div>

                <div class="row d-flex align-items-center" data-${this.controllerName}-target="infoDiv">
                  <div class="col-6 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>

                  <div class="col-3 pr-2">
                    ${this.loader}
                  </div>
                  <div class="col-3 px-2">
                    ${this.loader}
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--save").formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.getDate()
    })
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.saveController.hasNextBtnTarget) {
        if (controller.hasDescriptionInputTarget && controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasIssuedOnInputTarget && controller.issuedOnInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasCtissInputTarget && controller.ctissInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.hasTakerNameInputTarget && (controller.takerNameInputTarget.value == `` || controller.takerNameInputTarget.value == undefined)) {
          len += 1
        }

        if (controller.saveController.current_taker.id_type == undefined) {
          len += 1
        }

        if (controller.saveController.current_date.name == undefined) {
          len += 1
        }

        if (controller.saveController.current_regime.special_tax_regime_pretty == undefined) {
          len += 1
        }

        if (len == 0) {
          controller.saveController.nextBtnTarget.disabled = false
        } else {
          controller.saveController.nextBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}

// parcela
// city_service_code = ctiss
// federal_service_code = inicio ctiss
// cnae_code = cnae

// taxation_type = comparar cidades
// additional_information ? 
