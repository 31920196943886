import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "token", "info", "listLeads", "listProducts", "opportunityProspector", "opportunityProspectorName", "opportunityProspectorEdit",
                    "opportunityProspectorInput", "prospectorFilter", "prospectorFilterItem", "opportunityCloser", "opportunityCloserName", "addLeadBtn", "addProductBtn",
                    "opportunityCloserEdit", "opportunityCloserInput", "closerFilter", "closerFilterItem", "opportunityStage", "opportunityStageName",
                    "opportunityStageEdit", "opportunityStageInput", "stageFilter", "stageFilterItem", "opportunityStartedAt", "opportunityOpenDays", "opportunityStatus",
                    "opportunityStatus", "opportunityStatusName", "opportunityStatusEdit", "opportunityStatusInput", "statusFilter", "statusFilterItem",
                    "opportunityLead", "opportunityLeadName", "opportunityLeadEdit", "opportunityLeadInput", "leadFilter", "leadFilterItem", "addTicketBtn", "addNoteBtn",
                    "opportunityProductAddCard", "opportunityProductName", "opportunityProductNameEdit", "opportunityProductNameInput", "productNameFilter", "listJourneys",
                    "saveBtn", "opportunityProductPlan", "opportunityProductPlanEdit", "opportunityProductPlanInput", "productPlanFilter", "listTickets",
                    "opportunityProduct", "opportunityProductKind", "opportunityProductKindEdit", "opportunityProductKindInput", "productKindFilter", "opportunityLeadAddCard",
                    "opportunityProduct", "opportunityProductAmount", "opportunityProductAmountEdit", "opportunityProductAmountInput", "productAmountFilter", "productFilterItem",
                    "listNotes", "destroyLeadBtn", "destroyProductBtn", "checkboxRecurring", "opportunityProductDescriptionEdit", "opportunityProductDescriptionInput",
                    "inProcess", "resolved", "forwardOpportunityBtn", "ticketTasks", "ticketCalls", "ticketMails", "ticketMessages", "listProposals", "addProposalBtn",
                    "labelUpload", "uploadBtn", "fileName", "progressUpload", "progressUploadBar", "progressUploadCounter", "proposalLink", "cancelProposalBtn",
                    "opportunityKindName", "opportunityKindEdit", "opportunityKindInput", "kindFilter", "cancelBtn", "kindFilterItem", "opportunityKind",
                    "opportunityCampaignName", "opportunityCampaignEdit", "opportunityCampaignInput", "campaignFilter", "cancelBtn", "campaignFilterItem", "opportunityCampaign",
                    "opportunityOriginName", "opportunityOriginEdit", "opportunityOriginInput", "originFilter", "cancelBtn", "originFilterItem", "opportunityOrigin",
                    "opportunityCampaign", "deleteOpportunityBtn", "schedulesCard", "schedulesRow", "actionMenu", "actionRow",
                    "ticketsCard", "ticketsRow", "notesCard", "notesRow", "callsCard", "callsRow", "proposalLink", "cancelProposalBtn"]



  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--show`
    this.application.permissions = {}
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.application.opportunity_token = location.pathname.split("/").pop()
    this.application.opportunity = {}
    this.application.leads = []
    this.application.tickets = []
    this.application.purchase = []
    this.application.calls = []
    this.application.origins = []
    this.application.schedules = []
    this.caller = {}
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "PDF"]
    this.uploadFeature = true
    this.uploadReady = false
    this.doGridHtml()
  }

  doGridHtml() {
    var html = `<div class="row">
                  <div class="col-2 px-2">
                    <h6 class="mb-0 text-center">SDR</h6>
                    <hr class="my-1">
                    <span class="f-065 pointer" data-${this.controllerName}-target="opportunityProspector">
                      ${this.loader}
                    </span>
                  </div>
                  <div class="col-2 px-2">
                    <h6 class="mb-0 text-center">Closer</h6>
                    <hr class="my-1">
                    <span class="f-065 pointer" data-${this.controllerName}-target="opportunityCloser">
                      ${this.loader}
                    </span>
                  </div>
                  <div class="col-2 px-2">
                    <h6 class="mb-0 text-center">Estágio</h6>
                    <hr class="my-1">
                    <span class="f-065 pointer" data-${this.controllerName}-target="opportunityStage">
                      ${this.loader}
                    </span>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-3 px-2">
                        <h6 class="mb-0 text-center">Status</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityStatus">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2">
                        <h6 class="mb-0 text-center">Persona</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityKind">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2">
                        <h6 class="mb-0 text-center">Campanha</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityCampaign">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2">
                        <h6 class="mb-0 text-center">Origem</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityOrigin">
                          ${this.loader}
                        </span>
                      </div>
                      



                    </div>
                  </div>
                </div>



                <div class="row my-3">
                  <div class="col-4 px-2"></div>
                  <div class="col-8 px-2">
                    <div class="row w-100">
                      <div class="col-3 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard actionMenu" data-feature="tickets" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard actionMenu" data-feature="notes" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="callsCard actionMenu" data-feature="calls" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Ligações</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="schedulesCard actionMenu" data-feature="schedules" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Agendamentos</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Médicos</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addLead" data-${this.controllerName}-target="addLeadBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Médico</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listLeads">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row" style="margin-top:5rem;">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Produtos</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addProduct" data-${this.controllerName}-target="addProductBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Produto</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listProducts">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row" style="margin-top:5rem;">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Proposta Comercial</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addProposal" data-${this.controllerName}-target="addProposalBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Proposta Comercial</span>
                          </span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#cancelProposal" data-${this.controllerName}-target="cancelProposalBtn">
                            <span class="material-icons md-sm md-dark">clear</span>
                            <span class="mc-tooltiptext">Cancelar</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listProposals">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0 mb-3">
                        <h6 class="mb-0 text-center">Data Início</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityStartedAt">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-12 px-0 mb-3">
                        <h6 class="mb-0 text-center">Dias em Aberto</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="opportunityOpenDays">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center">Jornada</h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listJourneys">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-8 px-2">
                    <div class="row" data-controller="commercial--sales--opportunities--entities--tickets" data-${this.controllerName}-target="ticketsRow actionRow"></div>
                    <div class="row d-none" data-controller="commercial--sales--opportunities--entities--notes" data-${this.controllerName}-target="notesRow actionRow"></div>
                    <div class="row d-none" data-controller="commercial--sales--opportunities--entities--calls" data-${this.controllerName}-target="callsRow actionRow"></div>
                    <div class="row d-none" data-controller="commercial--sales--opportunities--entities--schedules" data-${this.controllerName}-target="schedulesRow actionRow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    }) 
  }

  toggleCard(ev) {
    var feature = ev.currentTarget.dataset.feature

    this.actionMenuTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })
    this.actionRowTargets.forEach(element => {
      element.classList.add(`d-none`)
    })

    this.nameTarget(`${feature}Card`).classList.add(`bg-primary`)
    this.nameTarget(`${feature}Row`).classList.remove(`d-none`)
  }

  toggleTickets(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "in_process") {
      this.inProcessTarget.classList.add("badge-secondary")
      this.inProcessTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary")

      this.getControllerByIdentifier(`commercial--sales--opportunities--entities--tickets`).listData(this.application.in_process_tickets)
    } else if (feature == "resolved") {
      this.inProcessTarget.classList.remove("badge-secondary")
      this.inProcessTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary")

      this.getControllerByIdentifier(`commercial--sales--opportunities--entities--tickets`).listData(this.application.resolved_tickets)
    }
  }

  setPageTitle() {
    // this.tokenTarget.innerText = this.application.opportunity.token
    var lostReasonHtml = ``
    var addReasonHtml = ``
    if (this.application.opportunity.stage == `lost`) {
      if (this.application.opportunity.products[0]) {
        var lostReason = this.application.opportunity.products[0].reason_pretty
      } else {
        var lostReason = `N/A`
      }

      if (this.application.opportunity.products[0] == undefined) {
        addReasonHtml += ` | Adicionar Motivo da Perda: <span class="mc-tooltip pointer">
                                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#doLostModalHtml">post_add</span>
                                                    <span class="mc-tooltiptext">Adicionar Motivo de Perda</span>
                                                  </span>`
      } else if (this.application.opportunity.products[0] && this.application.opportunity.products[0].reason_pretty == null) {
        addReasonHtml += ` | Adicionar Motivo da Perda: <span class="mc-tooltip pointer">
                                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#doLostModalHtml">post_add</span>
                                                    <span class="mc-tooltiptext">Adicionar Motivo de Perda</span>
                                                  </span>`
      } else {
        lostReasonHtml += ` | Motivo da Perda: <span class="f-75 f-bold mr-2">${lostReason}</span>
                                              <span class="mc-tooltip pointer">
                                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#tryNewOpportunitity">restart_alt</span>
                                                <span class="mc-tooltiptext">Tentar nova Oportunitidade</span>
                                              </span>`
      }

    }

    if (this.application.permissions.sales_opportunities_entities.can_delete && this.application.opportunity.stage != `gain` && this.application.current_user.super_admin) {
      var deleteBtnHtml = ` | Apagar Oportunidade: <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#deleteOpportunity" data-${this.controllerName}-target="deleteOpportunityBtn" type="button">
                                                      <span class="material-icons f-1p25">delete</span>
                                                      <span class="mc-tooltiptext">Apagar Oportunidade</span>
                                                    </button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.sales_opportunities_radars.can_create && this.application.opportunity.stage == `prospecting`) {
      var radarBtnHtml = ` | Radar de Oportunidade: <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#addRadarModal" type="button">
                                                      <span class="material-icons f-1p25">undo</span>
                                                      <span class="mc-tooltiptext">Adicionar ao Radar</span>
                                                    </button>`
    } else {
      var radarBtnHtml = ``
    }

    this.infoTarget.innerHTML = `Valor Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.opportunity.total_amount_recurring)}`
    this.infoTarget.innerHTML += ` | Total Médicos: ${this.application.opportunity.total_leads}`
    this.infoTarget.innerHTML += ` | Fonte Primária: ${this.application.opportunity.source_pretty}`
    this.infoTarget.innerHTML += ` | Token: <span class="mc-tooltip pointer">
                                              <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${this.application.opportunity.token}" data-action="click->app--helpers--copy#copy">file_copy</span>
                                              <span class="mc-tooltiptext">Copiar</span>
                                            </span>`
    // if (this.application.purchase.id) {
    if (this.application.has_purchase) {
      this.infoTarget.innerHTML += ` | Contratação: <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.purchase.purchase_path}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                                        <span class="material-icons md-sm md-dark">launch</span>
                                                        <span class="mc-tooltiptext">Ir para Contratação</span>
                                                      </button>`
    }

    this.infoTarget.innerHTML += lostReasonHtml
    this.infoTarget.innerHTML += addReasonHtml
    this.infoTarget.innerHTML += deleteBtnHtml
    this.infoTarget.innerHTML += radarBtnHtml
  }

  setPageHeader() {
    this.setProspector()
    this.setCloser()
    this.setStage()
    this.setStatus()
    this.setKind()
    this.setCampaign()
    this.setOrigin()
    this.setStartedAt()
    this.setOpenDays()
  }

  showInlineEditor(ev) {
    if (this.userCanUpdate() && this.application.permissions.sales_opportunities_entities.can_update) {
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.nextElementSibling.firstElementChild.firstElementChild.focus()
    }
  }

  hideInlineEditor(ev) {
    if (this.application.permissions.sales_opportunities_entities.can_update) {
      ev.currentTarget.closest(".editInput").classList.add("d-none")
      ev.currentTarget.closest(".editInput").previousElementSibling.classList.remove("d-none")
    }
  }

  setProspector() {

    if (this.application.opportunity.prospector_id == null || this.application.opportunity.prospector_id == undefined) {
      this.application.opportunity.prospector_name = `Não Definido`
    }

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 mc-tooltip text-center">
                      ${this.application.opportunity.prospector_name}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityProspectorName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.prospector_name}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityProspectorEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProspectorInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#prospectorFilter keyup->${this.controllerName}#prospectorFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterProspector" type="text" placeholder="Prospector" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="prospectorFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityProspectorTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listProspector()
      }
    })
  }

  listProspector() {
    var html = ``
    this.application.sales_team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectProspector" data-${this.controllerName}-target="prospectorFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.prospectorFilterTarget.innerHTML = html
  }

  prospectorFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectProspector(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.opportunity.prospector_id = this.opportunityProspectorInputTarget.dataset.filter
    this.send_data.opportunity.prospector_name = this.opportunityProspectorInputTarget.dataset.text

    this.requestSaveOpportunity()
  }

  enterProspector(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.prospector_id = this.opportunityProspectorInputTarget.dataset.filter
      this.send_data.opportunity.prospector_name = this.opportunityProspectorInputTarget.dataset.text

      this.requestSaveOpportunity()
    }
  }

  setCloser() {

    if (this.application.opportunity.closer_id == null || this.application.opportunity.closer_id == undefined) {
      this.application.opportunity.closer_name = `Não Definido`
    }

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 mc-tooltip text-center">
                      ${this.application.opportunity.closer_name}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityCloserName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.closer_name}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityCloserEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityCloserInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#closerFilter keyup->${this.controllerName}#closerFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterCloser" type="text" placeholder="Closer" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="closerFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityCloserTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listCloser()
      }
    })
  }

  listCloser() {
    var html = ``
    this.application.sales_team.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectCloser" data-${this.controllerName}-target="closerFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.closerFilterTarget.innerHTML = html
  }

  closerFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectCloser(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)

    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.opportunity.closer_id = this.opportunityCloserInputTarget.dataset.filter
    this.send_data.opportunity.closer_name = this.opportunityCloserInputTarget.dataset.text

    this.requestSaveOpportunity()
  }

  enterCloser(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.closer_id = this.opportunityCloserInputTarget.dataset.filter
      this.send_data.opportunity.closer_name = this.opportunityCloserInputTarget.dataset.text

      this.requestSaveOpportunity()
    }
  }

  setStage() {

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 text-center">
                      ${this.application.opportunity.stage_pretty}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#lostOpportunity" data-stage="lost" data-stage-pretty="Perdido">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Oportunidade Perdida?</span>
                      </span>
                    </div>
                    <div class="col-8 px-0 mc-tooltip text-center">
                      ${this.application.opportunity.stage_pretty}
                    </div>
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#forwardOpportunity" data-stage="" data-stage-pretty="" data-${this.controllerName}-target="forwardOpportunityBtn">
                        <span class="material-icons md-sm md-dark">arrow_forward_ios</span>
                        <span class="mc-tooltiptext">Avançar Oportunidade</span>
                      </span>
                    </div>
                  </div>`

      var html1 = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityStageName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.stage_pretty}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityStageEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityStageInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#stageFilter keyup->${this.controllerName}#stageFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterStage" type="text" placeholder="Estágio" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="stageFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityStageTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listStage()
      }
    })
  }

  listStage() {
    // var html = `<li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Prospecção" data-filter="prospecting" class="li-selector dark f-065">Prospecção</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Qualificação" data-filter="qualification" class="li-selector dark f-065">Qualificação</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Agendamento" data-filter="booking" class="li-selector dark f-065">Agendamento</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Consultoria" data-filter="meeting" class="li-selector dark f-065">Consultoria</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Proposta" data-filter="proposal" class="li-selector dark f-065">Proposta</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Fechamento" data-filter="closing" class="li-selector dark f-065">Fechamento</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Ganho" data-filter="gain" class="li-selector dark f-065">Ganho</li>
    //             <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Perdido" data-filter="lost" class="li-selector dark f-065">Perdido</li>`
    // this.stageFilterTarget.innerHTML = html

    if (this.application.opportunity.stage == `prospecting`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `qualification`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Qualificação`
    } else if (this.application.opportunity.stage == `qualification`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `booking`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Agendamento`
    } else if (this.application.opportunity.stage == `booking`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `meeting`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Consultoria`
    } else if (this.application.opportunity.stage == `meeting`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `proposal`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Proposta`
    } else if (this.application.opportunity.stage == `proposal`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `closing`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Fechamento`
    } else if (this.application.opportunity.stage == `closing`) {
      this.forwardOpportunityBtnTarget.dataset.stage = `gain`
      this.forwardOpportunityBtnTarget.dataset.stagePretty = `Ganho`
    }
  }

  forwardOpportunity(ev) {
    if (this.hasOpportunityOwners()) {
      if (this.checkOpportunityJourneys(ev.currentTarget.dataset.stage)) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Oportunidade já passou por esse Estágio de Jornada! :/`, 5000)
      } else {
        var obj = this.checkProductOpportunity(ev.currentTarget.dataset.stage)
        if (obj.canForward) {
          var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para a Oportunidade?`)

          if (r) {
            this.send_data = { current_user: {}, journey: {}, product: {} }
            this.send_data.current_user.current_user_id = this.application.current_user.id
            this.send_data.current_user.feature = `sales_opportunities_journeys`

            this.send_data.journey.opportunity_id = this.application.opportunity.id
            this.send_data.journey.stage = ev.currentTarget.dataset.stage
            this.send_data.journey.date = new Date()
            this.send_data.product.stage = ev.currentTarget.dataset.stage
            this.send_data.product.products = []

            this.changeStage()
          } else {
            this.setStage()
          }
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, obj.message, 5000)
        }
      }
    }
  }

  lostOpportunity(ev) {
    if (this.hasOpportunityOwners()) {
      if (this.application.opportunity.products.length == 0) {
        alert(`Favor adicionar um produto para Atualizar a Oportunidade`)
      } else {
        var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para a Oportunidade?`)
        
        if (r) {
          this.send_data = { current_user: {}, journey: {}, product: {} }
          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `sales_opportunities_journeys`
          
          this.send_data.journey.opportunity_id = this.application.opportunity.id
          this.send_data.journey.stage = ev.currentTarget.dataset.stage
          this.send_data.journey.date = new Date()
          this.send_data.product.stage = ev.currentTarget.dataset.stage
          this.send_data.product.products = []
          
          this.changeStage()
        } else {
          this.setStage()
        }
      }
    }
  }

  changeStage() {
    if (this.send_data.journey.stage == `gain` || this.send_data.journey.stage == `lost`) {
      if (this.application.opportunity.products.length == 0) {
        alert(`Favor adicionar um produto para Atualizar a Oportunidade`)
      } else {
        if (this.application.in_process_tickets.length == 0) {
          this.doClosingModalHtml()
        } else {
          this.setStage()
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos finalizar a Oportunidade com Tickets em Aberto. :/`, 5000)
        }
      }
    } else {
      this.requestSaveJourney()
    }
  }

  stageFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectStage(ev) {
    if (this.checkOpportunityJourneys(ev.currentTarget.dataset.filter)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Oportunidade já passou por esse Estágio de Jornada! :/`, 5000)
    } else {
      var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.text} para a Oportunidade?`)

      if (r) {
        this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
        this.send_data = { current_user: {}, journey: {}, product: {} }
        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `sales_opportunities_journeys`

        this.send_data.journey.opportunity_id = this.application.opportunity.id
        this.send_data.journey.stage = this.opportunityStageInputTarget.dataset.filter
        this.send_data.journey.date = new Date()
        this.send_data.product.stage = this.opportunityStageInputTarget.dataset.filter
        this.send_data.product.products = []

        if (this.send_data.journey.stage == `gain` || this.send_data.journey.stage == `lost`) {
          if (this.application.in_process_tickets.length == 0) {
            this.doClosingModalHtml()
          } else {
            this.setStage()
            this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos finalizar a Oportunidade com Tickets em Aberto. :/`, 5000)
          }
        } else {
          this.requestSaveJourney()
        }
      } else {
        this.setStage()
      }
    }
  }

  enterStage(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      if (this.checkOpportunityJourneys(ev.currentTarget.dataset.filter)) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Oportunidade já passou por esse Estágio de Jornada! :/`, 5000)
      } else {
        var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.text} para a Oportunidade?`)

        if (r) {
          this.send_data = { current_user: {}, journey: {}, product: {} }
          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `sales_opportunities_journeys`

          this.send_data.journey.opportunity_id = this.application.opportunity.id
          this.send_data.journey.stage = this.opportunityStageInputTarget.dataset.filter
          this.send_data.journey.date = new Date()
          this.send_data.product.stage = this.opportunityStageInputTarget.dataset.filter
          this.send_data.product.products = []

          if (this.send_data.journey.stage == `gain` || this.send_data.journey.stage == `lost`) {
            if (this.application.in_process_tickets.length == 0) {
              this.doClosingModalHtml()
            } else {
              this.setStage()
              this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos finalizar a Oportunidade com Tickets em Aberto. :/`, 5000)
            }
          } else {
            this.requestSaveJourney()
          }
        } else {
          this.setStage()
        }
      }
    }
  }

  setStatus() {

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 mc-tooltip text-center">
                      ${this.application.opportunity.status_pretty}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityStatusName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.status_pretty}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityStatusEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityStatusInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#statusFilter keyup->${this.controllerName}#statusFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterStatus" type="text" placeholder="Status" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="statusFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityStatusTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listStatus()
      }
    })
  }

  listStatus() {

    var html = `<li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Quente" data-filter="hot" class="li-selector dark f-065">Quente</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Morno" data-filter="warn" class="li-selector dark f-065">Morno</li>
                <li data-action="click->${this.controllerName}#selectStatus" data-${this.controllerName}-target="statusFilterItem" data-text="Frio" data-filter="cold" class="li-selector dark f-065">Frio</li>`

    this.statusFilterTarget.innerHTML = html
  }

  statusFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectStatus(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.opportunity.status = this.opportunityStatusInputTarget.dataset.filter

    this.requestSaveOpportunity()
  }

  enterStatus(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.status = this.opportunityStatusInputTarget.dataset.filter

      this.requestSaveOpportunity()
    }
  }

  setKind() {

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                      <div class="col-12 px-0 mc-tooltip text-center">
                        ${this.application.opportunity.kind_pretty}
                      </div>
                    </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityKindName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.kind_pretty}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityKindEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityKindInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#kindFilter keyup->${this.controllerName}#kindFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterKind" type="text" placeholder="Persona" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="kindFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityKindTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listKind()
      }
    })
  }

  listKind() {

    var html = `<li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Recém-Formado" data-filter="graduated" class="li-selector dark f-065">Recém-Formado</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Residente" data-filter="resident" class="li-selector dark f-065">Residente</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Especialista" data-filter="market" class="li-selector dark f-065">Especialista</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Consultório" data-filter="consulting" class="li-selector dark f-065">Consultório</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Clínica" data-filter="clinic" class="li-selector dark f-065">Clínica</li>
                <li data-action="click->${this.controllerName}#selectKind" data-${this.controllerName}-target="kindFilterItem" data-text="Não Médico" data-filter="non_doctor" class="li-selector dark f-065">Não Médico</li>`

    this.kindFilterTarget.innerHTML = html
  }

  kindFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectKind(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.opportunity.kind = this.opportunityKindInputTarget.dataset.filter

    this.requestSaveOpportunity()
  }

  enterKind(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.kind = this.opportunityKindInputTarget.dataset.filter

      this.requestSaveOpportunity()
    }
  }


  // --------
  setOrigin() {

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                      <div class="col-12 px-0 mc-tooltip text-center">
                        ${this.application.opportunity.origin}
                      </div>
                    </div>`
    } else {

      if (this.application.opportunity.origin == `Não Definido`) {
        var html = `<div class="row d-flex align-items-center">
                      <div class="col-12 px-0 pointer mc-tooltip text-center">
                        <button data-action="click->${this.controllerName}#updateOrigin" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">add</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Adicionar Origem</span>
                        </button>
                      </div>
                    </div>`
      } else {
        var html = `<div class="row d-flex align-items-center">
                      <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityOriginName" data-action="click->${this.controllerName}#updateOrigin">
                        ${this.application.opportunity.origin}
                      </div>
                    </div>`
      }

      
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityOriginTarget.innerHTML = html)
    }).then(() => {
      // if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
      //   controller.listCampaign()
      // }
    })
  }

  updateOrigin() {
    var modal = `commercial--sales--opportunities--entities--origin-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller
      
      controller.getControllerByIdentifier(modal).opportunity_id = controller.application.opportunity.id
      controller.getControllerByIdentifier(modal).permission = `sales_opportunities_entities`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  // Campaign
  // campaign

  // --------
  setCampaign() {

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost`) {
      var html = `<div class="row d-flex align-items-center">
                      <div class="col-12 px-0 mc-tooltip text-center">
                        ${this.application.opportunity.campaign_pretty}
                      </div>
                    </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityCampaignName" data-action="click->${this.controllerName}#showInlineEditor">
                      ${this.application.opportunity.campaign_pretty}
                    </div>
                    <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityCampaignEdit">
                      <div class="form-group w-100 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityCampaignInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#campaignFilter keyup->${this.controllerName}#campaignFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterCampaign" type="text" placeholder="Campanha" required>
                        <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="campaignFilter"></ul>
                      </div>
                      <div class="ml-3 mb-0">
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span aria-hidden="true">&times;</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.opportunityCampaignTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage != `gain` && controller.application.opportunity.stage != `lost`) {
        controller.listCampaign()
      }
    })
  }

  listCampaign() {

    var html = `<li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Upsell Base" data-filter="upsell_base" class="li-selector dark f-065">Upsell Base</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Indicação" data-filter="recommendation" class="li-selector dark f-065">Indicação</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Reativação de Leads" data-filter="reactivation" class="li-selector dark f-065">Reativação de Leads</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Recém-Formados" data-filter="graduation" class="li-selector dark f-065">Recém-Formados</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Outbound" data-filter="outbounding" class="li-selector dark f-065">Outbound</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Contato Direto" data-filter="direct_contact" class="li-selector dark f-065">Contato Direto</li>
                <li data-action="click->${this.controllerName}#selectCampaign" data-${this.controllerName}-target="campaignFilterItem" data-text="Eventos de Mercado" data-filter="market_events" class="li-selector dark f-065">Eventos de Mercado</li>`

    this.campaignFilterTarget.innerHTML = html
  }

  campaignFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectCampaign(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.opportunity.campaign = this.opportunityCampaignInputTarget.dataset.filter

    this.requestSaveOpportunity()
  }

  enterCampaign(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.campaign = this.opportunityCampaignInputTarget.dataset.filter

      this.requestSaveOpportunity()
    }
  }


  // Kind
  // kind
  // --------
  setStartedAt() {
    this.opportunityStartedAtTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.opportunity.started_at_pretty}
                                                  </div>
                                                </div>`
  }

  setOpenDays() {
    if (this.application.opportunity.open_days == 1) {
      this.opportunityOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.opportunity.open_days} dia
                                                  </div>
                                                </div>`
    } else {
      this.opportunityOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.opportunity.open_days} dias
                                                  </div>
                                                </div>`
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  setLeads() {
    var html = ``

    this.application.opportunity.leads.forEach(element => {
      if (this.application.permissions.sales_opportunities_calls.can_create && this.caller.id && this.caller.status == `active`) {
        var callLine = `<p class="mb-0 f-065">
                        ${element.lead_phone}
                        <span class="ml-3 mc-tooltip pointer">
                          <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#makeCall">call</span>
                          <span class="mc-tooltiptext">Fazer Ligação</span>
                        </span>
                      </p>`
      } else {
        var callLine = ``
      }

      var profileHtml = ``
      if (element.has_profile) {
        profileHtml = `<p class="mb-0 f-065 mc-tooltip">
                          ${element.profile_note}
                          <span class="ml-3 pointer">
                            <span class="material-icons md-sm md-dark" data-url="${element.profile_url}" data-action="click->${this.controllerName}#goToURL">launch</span>
                          </span>
                          <span class="mc-tooltiptext">Ver Perfil do Cliente</span>
                        </p>`
      } else {
        profileHtml = `<p class="mb-0 f-065">
                          ${element.profile_note}
                        </p>`
      }


      html += `<div class="row my-2 w-100 leadCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-2 f-065">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-9 px-1">
                            <div class="card-show-dropdown">
                              ${element.lead_name}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">
                                  ${element.lead_phone}
                                  <span class="ml-3 mc-tooltip pointer">
                                    <span data-action="click->${this.controllerName}#sendWhatsAppApi"><i class="fab fa-whatsapp md-dark md-150"></i></span>
                                    <span class="mc-tooltiptext">Enviar WhatsApp</span>
                                  </span>
                                </p>
                                ${callLine}
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.lead_email}" data-action="click->app--helpers--copy#copy">${element.lead_email}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                <p class="mb-0 f-065 mc-tooltip">
                                  ${element.lead_council}
                                  <span class="ml-3 pointer">
                                    <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#searchCRM">search</span>
                                  </span>
                                  <span class="mc-tooltiptext">Procurar CRM</span>
                                </p>
                                ${profileHtml}
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-1">
                            <button class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#destroyLead" data-${this.controllerName}-target="destroyLeadBtn" data-id="${element.id}" type="button">
                              <span class="material-icons md-sm md-dark">delete</span>
                              <span class="mc-tooltiptext">Apagar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listLeadsTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage == `gain` || controller.application.opportunity.stage == `lost` || controller.userCanUpdate() == false) {
        controller.destroyLeadBtnTargets.forEach(element => {
          element.remove()
        })
      }
    })
  }

  searchCRM(ev) {
    var sqlId = ev.currentTarget.closest(".leadCard").dataset.id

    var lead = {}
    this.application.opportunity.leads.forEach(element => {
      if (element.id == sqlId) {
        lead = element
      }
    })

    lead.name = lead.lead_name
    lead.crm_state = lead.lead_council.replace("CRM: ", "").split(" ")[0]
    lead.crm = lead.lead_council.replace("CRM: ", "").split(" ")[1].replace(".", "")

    if (lead.crm && lead.crm_state) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=&uf=${lead.crm_state}&crm=${lead.crm}&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.crm) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=&uf=&crm=${lead.crm}&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.name && lead.crm_state) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=${lead.name}&uf=${lead.crm_state}&crm=&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    } else if (lead.name) {
      var url = `https://portal.cfm.org.br/busca-medicos/?nome=${lead.name}&uf=&crm=&pageNumber=1&pageSize=100`
      window.open(url, `_blank`)
    }
  }

  sendWhatsAppApi(ev) {
    var id = ev.currentTarget.closest(".leadCard").dataset.id
    var lead = {}
    this.application.opportunity.leads.forEach(element => {
      if (element.id == id) {
        lead = element
      }
    })

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.application.opportunity.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `sales_opportunities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Oportunidade com ${lead.lead_name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.application.opportunity.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `sales_opportunities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = lead.lead_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `sales_leads`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = lead.lead_name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = lead.whatsapp.slice(0,2)
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = lead.whatsapp.slice(2,11)
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = lead.whatsapp
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `sales_opportunities_calls`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  makeCall(ev) {
    var id = ev.currentTarget.closest(".leadCard").dataset.id
    var lead = {}
    this.application.opportunity.leads.forEach(element => {
      if (element.id == id) {
        lead = element
      }
    })

    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_id = this.application.opportunity.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_type = `sales_opportunities`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_name = `Oportunidade com ${lead.lead_name}`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_id = this.application.opportunity.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_type = `sales_opportunities`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_id = lead.lead_id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_type = `sales_leads`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_name = lead.lead_name
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).dial_number = lead.whatsapp
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).phone_number = lead.lead_phone
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).caller_id = this.caller.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).kind = `sales`
    // this.getControllerByIdentifier(`app--helpers--pre-call-modal`).started_at = this.getControllerByIdentifier(`app--helpers--date`).transformAnualDateToISO(new Date())
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).permission = `sales_opportunities_calls`
    

    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).openModal({ message: `Iniciar Chamada`, phone: lead.lead_phone })
  }

  addRadarModal() {
    var modal = `commercial--sales--opportunities--radars--save-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).permission = `sales_opportunities_radars`
      controller.getControllerByIdentifier(modal).current_opportunity = controller.application.opportunity
      controller.getControllerByIdentifier(modal).actionMode = `opportunity`

      controller.getControllerByIdentifier(modal).open()
    })
  }

  addLead() {
    if (this.hasOpportunityOwners()) {
      this.actionMode = `new`

      var html = `<div class="row my-2 w-100"  data-${this.controllerName}-target="opportunityLeadAddCard">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-2 f-065 text-center pointer">
                        <div class="row my-2">
                          <div class="col-12 px-1">
                            <div class="card-show-dropdown">
                              <div class="row d-flex align-items-center">
                                <div class="col-12 px-0 pointer mc-tooltip text-center" data-${this.controllerName}-target="opportunityLeadName" data-action="click->${this.controllerName}#showInlineEditor">
                                  <div class="row d-flex align-items-center">
                                    <div class="col-9 px-0">
                                      <span>Clique aqui para Selecionar</span>
                                    </div>
                                    <div class="col-3 px-0">
                                      <button data-action="click->${this.controllerName}#cancelLead" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                        <span aria-hidden="true">&times;</span>
                                        <span class="mc-tooltiptext">Cancelar</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 px-1 d-flex align-items-center d-none editInput" data-${this.controllerName}-target="opportunityLeadEdit">
                                  <div class="form-group w-100 mb-0">
                                    <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityLeadInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#leadFilter keyup->${this.controllerName}#leadFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterLead" type="text" placeholder="Lead" required>
                                    <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="leadFilter"></ul>
                                  </div>
                                  <div class="ml-3 mb-0">
                                    <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideInlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                      <span aria-hidden="true">&times;</span>
                                      <span class="mc-tooltiptext">Cancelar</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.listLeadsTarget.insertAdjacentHTML("afterbegin", html))
      }).then(() => {
        if (controller.application.leads.length > 0) {
          controller.listLead()
        } else {
          controller.getSalesLeads()
        }
      })
    }
  }

  getSalesLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    // this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Leads de Vendas` })
    // this.refreshLoadValue()
    // const url = "https://portal.cfm.org.br/busca-medicos/?nome=&uf=MG&crm=84779&municipio=&inscricao=&tipoSituacao=&situacao=&especialidade=&areaAtuacao=&pageNumber=1&pageSize=10"
    // window.open(url, `_blank`)


    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.leads = response.data.cln
          controller.application.opportunity.leads.forEach(opportunity_lead => {
            controller.application.leads.forEach((element, i) => {
              if (element.id == opportunity_lead.lead_id) {
                controller.application.leads.splice(controller.application.leads.indexOf(element), 1)
              }
            })
          })
        }

        controller.stopRefreshingLoadValue()
        controller.listLead()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelLead() {
    this.stopRefreshing()
    this.opportunityLeadAddCardTargets.forEach(element => {
      element.remove()
    })
  }

  refreshLoadValue() {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Leads de Vendas`, value: value })
      value += 1
    }, 200);
  }

  stopRefreshingLoadValue() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  listLead() {

    var html = ``
    this.application.leads.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectLead" data-${this.controllerName}-target="leadFilterItem" data-text="${element.name}" data-filter="${element.id}" class="li-selector dark f-065">${element.name}</li>`
    })

    this.leadFilterTarget.innerHTML = html
  }

  leadFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectLead(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.send_data = { current_user: {}, lead: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.lead.opportunity_id = this.application.opportunity.id
    this.send_data.lead.lead_id = this.opportunityLeadInputTarget.dataset.filter

    this.requestSaveLead()
  }

  enterLead(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.send_data = { current_user: {}, lead: {} }

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.lead.opportunity_id = this.application.opportunity.id
      this.send_data.lead.lead_id = this.opportunityLeadInputTarget.dataset.filter

      this.requestSaveLead()
    }
  }

  destroyLead(ev) {
    var id = ev.currentTarget.dataset.id
    this.send_data = { current_user: {}, lead: {} }

    var r = confirm(`Tem certeza que deseja apagar o Lead da Oportunidade?`)
    if (r) {
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.lead.id = id
      this.send_data.lead.active = false

      this.requestDestroyLead() 
    }
  }

  setProducts() {
    var html = ``
    this.application.opportunity.products.forEach(element => {

      if (element.recurring) {
        var cardShow = `<p class="mb-0 f-065">${element.recurring_pretty}</p>
                        <p class="mb-0 f-065">${element.kind_pretty}</p>
                        <p class="mb-0 f-065">${element.plan_pretty}</p>
                        <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.amount)}</p>`
      } else {
        var cardShow = `<p class="mb-0 f-065">${element.recurring_pretty}</p>
                        <p class="mb-0 f-065">${element.kind_pretty}</p>
                        <p class="mb-0 f-065">${element.description}</p>
                        <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.amount)}</p>`
      }

      html += `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body p-0 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-9 px-1">
                            <div class="card-show-dropdown">
                              ${element.name_pretty}
                              <div class="card-show-dropdown-content text-left">
                                ${cardShow}
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-0">
                            <button class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#destroyProduct" data-${this.controllerName}-target="destroyProductBtn" data-id="${element.id}" type="button">
                              <span class="material-icons md-sm md-dark">delete</span>
                              <span class="mc-tooltiptext">Apagar</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    if (this.application.opportunity.products.length > 0) {
      this.addProductBtnTarget.classList.add(`d-none`)
    } else {
      this.addProductBtnTarget.classList.remove(`d-none`)
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listProductsTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.opportunity.stage == `gain` || controller.application.opportunity.stage == `lost` || controller.userCanUpdate() == false) {
        controller.destroyProductBtnTargets.forEach(element => {
          element.remove()
        })
      }
    })
  }

  addProduct() {
    if (this.hasOpportunityOwners()) {
      this.actionMode = `new`

      var html = `<div class="row my-2 w-100" data-${this.controllerName}-target="opportunityProductAddCard">
                    <div class="col-12 px-1">
                      <div class="card">
                        <div class="card-body p-0 f-065 pointer">
                          <div class="row my-1 mt-3">
                            <div class="col-12 px-2 pointer">
                              <div class="custom-control custom-checkbox pl-3 d-flex align-items-center">
                                <input type="checkbox" class="custom-control-input" id="checkRecurring" data-${this.controllerName}-target="checkboxRecurring" data-action="click->${this.controllerName}#opportunityRecurringField">
                                <label class="custom-control-label pointer f-075" for="checkRecurring">Recorrente</label>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1">
                            <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductNameEdit">
                              <div class="form-group w-100 mb-0">
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductNameInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Nome" required>
                                <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productNameFilter"></ul>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductPlanEdit">
                              <div class="form-group w-100 mb-0">
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductPlanInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Plano" required>
                                <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productPlanFilter"></ul>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductKindEdit">
                              <div class="form-group w-100 mb-0">
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductKindInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#productFilter keyup->${this.controllerName}#productFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterItem" type="text" placeholder="Tipo" required>
                                <ul class="ul-filter filter-list d-none w-75" style="z-index:1" data-${this.controllerName}-target="productKindFilter"></ul>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-12 px-3 d-flex align-items-center d-none" data-${this.controllerName}-target="opportunityProductDescriptionEdit">
                              <div class="form-group w-100 mb-0">
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductDescriptionInput" type="text" placeholder="Descrição Avulso" required>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-12 px-3 d-flex align-items-center editInput" data-${this.controllerName}-target="opportunityProductAmountEdit">
                              <div class="form-group w-100 mb-0">
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="opportunityProductAmountInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" type="tel" placeholder="Valor" required>
                              </div>
                            </div>
                          </div>
                          <div class="row my-1 d-flex align-items-center">
                            <div class="col-12 px-3 pointer text-right">
                              <button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${this.controllerName}#cancelProduct">Fechar</button>
                              <button disabled type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-action="click->${this.controllerName}#saveProduct" data-${this.controllerName}-target="saveBtn">Salvar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.listProductsTarget.insertAdjacentHTML("afterbegin", html))
      }).then(() => {
        controller.checkboxRecurringTarget.checked = true
        controller.listProduct()
        controller.opportunityProductAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        controller.refreshSaveBtn()
      })
    }
  }

  cancelProduct() {
    this.stopRefreshing()
    this.opportunityProductAddCardTargets.forEach(element => {
      element.remove()
    })
  }

  opportunityRecurringField(ev) {
    var checked = ev.currentTarget.checked

    if (checked) {
      this.opportunityProductPlanEditTarget.classList.remove("d-none")
      this.opportunityProductKindEditTarget.classList.remove("d-none")
      this.opportunityProductDescriptionEditTarget.classList.add("d-none")
    } else {
      this.opportunityProductPlanEditTarget.classList.add("d-none")
      this.opportunityProductKindEditTarget.classList.add("d-none") 
      this.opportunityProductDescriptionEditTarget.classList.remove("d-none")
    }
  }

  listProduct() {

    var productHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="PJ Médica" data-filter="medclinic" class="li-selector dark f-065">PJ Médica</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Funcionário" data-filter="medemployee" class="li-selector dark f-065">Funcionário</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Faturamento" data-filter="medbiling" class="li-selector dark f-065">Faturamento</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Gestão de Recebimentos" data-filter="medreceivement" class="li-selector dark f-065">Gestão de Recebimentos</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Gestão de Plantões" data-filter="medoncall" class="li-selector dark f-065">Gestão de Plantões</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Seguros" data-filter="medseg" class="li-selector dark f-065">Seguros</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Seguros" data-filter="medprev" class="li-selector dark f-065">Previdência Privada</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Planner" data-filter="medreturn" class="li-selector dark f-065">Planner</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Livro-Caixa" data-filter="medbooking" class="li-selector dark f-065">Livro-Caixa</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Terceirização" data-filter="medoutsourcing" class="li-selector dark f-065">Terceirização</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Mídias Sociais" data-filter="medcontent" class="li-selector dark f-065">Mídias Sociais</li>
                        <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Declaração IRPF" data-filter="medfiling" class="li-selector dark f-065">Declaração IRPF</li>`

    this.productNameFilterTarget.innerHTML = productHtml

    var planHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Recebimento" data-filter="receivement" class="li-selector dark f-065">Recebimento</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Consultório" data-filter="practice" class="li-selector dark f-065">Consultório</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="BPO" data-filter="finance_outsourcing" class="li-selector dark f-065">BPO</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Escritório Virtual" data-filter="virtual_office" class="li-selector dark f-065">Escritório Virtual</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="PGBL" data-filter="pgbl" class="li-selector dark f-065">PGBL</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="VGBL" data-filter="vgbl" class="li-selector dark f-065">VGBL</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Vida" data-filter="life" class="li-selector dark f-065">Vida</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="DIT" data-filter="income" class="li-selector dark f-065">DIT</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Responsabilidade Civil" data-filter="responsability" class="li-selector dark f-065">Responsabilidade Civil</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Saúde" data-filter="health" class="li-selector dark f-065">Saúde</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Light" data-filter="light" class="li-selector dark f-065">Light</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Basic" data-filter="basic" class="li-selector dark f-065">Basic</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Pro" data-filter="pro" class="li-selector dark f-065">Pro</li>
                    <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Premium" data-filter="premium" class="li-selector dark f-065">Premium</li>`

    this.productPlanFilterTarget.innerHTML = planHtml

    var kindHtml = `<li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Abertura" data-filter="opening" class="li-selector dark f-065">Abertura</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Migração" data-filter="migration" class="li-selector dark f-065">Migração</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Alteração" data-filter="amendment" class="li-selector dark f-065">Alteração</li>
                <li data-action="click->${this.controllerName}#selectItem" data-${this.controllerName}-target="statusFilterItem" data-text="Regularização" data-filter="regularization" class="li-selector dark f-065">Regularização</li>`

    this.productKindFilterTarget.innerHTML = kindHtml
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  productFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectItem(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterItem(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  saveProduct(ev) {
    this.stopRefreshing()
    this.send_data = { current_user: {}, product: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.product.opportunity_id = this.application.opportunity.id
    this.send_data.product.recurring = this.checkboxRecurringTarget.checked
    this.send_data.product.name = this.opportunityProductNameInputTarget.dataset.filter
    if (this.checkboxRecurringTarget.checked) {
      this.send_data.product.plan = this.opportunityProductPlanInputTarget.dataset.filter
      this.send_data.product.kind = this.opportunityProductKindInputTarget.dataset.filter 
      this.send_data.product.description = ``
    } else {
      this.send_data.product.plan = `none`
      this.send_data.product.kind = `separate`
      this.send_data.product.description = this.opportunityProductDescriptionInputTarget.value
    }
    this.send_data.product.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.opportunityProductAmountInputTarget.value)

    this.requestSaveProduct()
  }

  destroyProduct(ev) {
    var id = ev.currentTarget.dataset.id
    this.send_data = { current_user: {}, product: {} }

    var r = confirm(`Tem certeza que deseja apagar o Produto da Oportunidade?`)
    if (r) {
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.product.id = id
      this.send_data.product.active = false

      this.requestDestroyProduct()
    }
  }

  setProposal() {
    var html = ``

    if (this.application.opportunity.has_proposal) {
      var proposal = `<span class="f-085 text-bold">Proposta</span>
                          <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.opportunity.proposal_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Proposta</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.application.opportunity.id}" data-field="proposal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Proposta</span>
                          </button>`
    } else {
      var proposal = `<span class="f-085 text-bold">Sem Arquivo Proposta</span>`
    }

    html += `<div class="row w-100 mt-3">
              <div class="col-12 d-flex align-items-center">
                <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                  <div class="col-12 my-1 d-flex px-0">
                    ${proposal}
                  </div>
                </div>
              </div>
            </div>`

    this.listProposalsTarget.innerHTML = html
  }

  addProposal() {
    this.uploadFeature = true
    this.canUploadFile = false
    this.uploadReady = false

    var html = `<div class="row my-3">
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-2">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                      <div class="row my-1">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-white">cloud_upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                    <span data-${this.controllerName}-target="fileName"></span>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center" data-${this.controllerName}-target="proposalLink">
                  </div>
                </div>`


    if (this.application.opportunity.products.length == 0) {
      alert(`Favor adicionar um produto para Atualizar a Oportunidade`)
    } else {
      this.listProposalsTarget.innerHTML = html
      this.addProposalBtnTarget.classList.add(`d-none`)
      this.cancelProposalBtnTarget.classList.remove(`d-none`)
      this.refreshProposalBtn()      
    }
  }

  cancelProposal() {
    this.addProposalBtnTarget.classList.remove(`d-none`)
    this.cancelProposalBtnTarget.classList.add(`d-none`)
    this.setProposal()
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault()

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true

          obj.file = element
          this.upload_file = obj
          this.uploadReady = true
          this.fileNameTarget.innerText = this.upload_file.file_name
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.uploadReady = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`
      
      this.upload_file.kind = `proposal`
      
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.proposalLinkTarget.innerHTML = progressSpan)
      }).then(() => {
        controller.progressCount(0)
        controller.requestUpload(controller.application.opportunity.id, controller.upload_file.file, controller.upload_file.kind)
      })
    }
  }

  requestUpload(opportunity_id, file, kind) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', kind)
    formData.append('opportunity_id', opportunity_id)
    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', `sales_opportunities_entities`)

    var url = "/commercial/sales/opportunities/entities/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.opportunity = response.data.cln

          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} fez o Upload da Proposta Comercial da Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)

          controller.progressCount(100)
          controller.stopRefreshing()
          controller.uploadSwitch(false)
          controller.cancelProposal()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile(ev) {
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.application.opportunity.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    var url = "/commercial/sales/opportunities/entities/destroy_upload"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.opportunity = response.data.cln

          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} apagou a Proposta Comercial da Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)

          controller.stopRefreshing()
          controller.cancelProposal()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressCountTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  setJourneys() {
    var html = ``
    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.opportunity.journeys, `date_time`)
    journeys.forEach(element => {

      if (element.period == 1) {
        var period = `${element.period} dia`
      } else {
        var period = `${element.period} dias`
      }

      if (element.stage_alert) {
        var alert = `<div class="col-2 px-0 mc-tooltip">
                        <span class="material-icons md-sm md-danger">warning</span>
                        <span class="mc-tooltiptext">Atenção!</span>
                      </div>`
      } else {
        var alert = ``
      }

      html += `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body p-0 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-10 px-1">
                            <div class="card-show-dropdown">
                              ${element.stage_pretty}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">Data: ${element.date_pretty}</p>
                                <p class="mb-0 f-065">Tempo: ${period}</p>
                              </div>
                            </div>
                          </div>
                          ${alert}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.listJourneysTarget.innerHTML = html
  }

  addTicket() {
    if (this.hasOpportunityOwners()) {
      this.getControllerByIdentifier("commercial--sales--opportunities--entities--tickets").createTicket()
    }
  }

  addNote() {
    if (this.hasOpportunityOwners()) {
      this.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").createNote()
    }

    // if (this.application.opportunity.prospector_id == undefined || this.application.opportunity.closer_id == undefined) {
    //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor definir o SDR e o Closer da Oportunidade`, 5000)
    // } else {
    //   this.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").createNote()
    // }
  }

  doClosingModalHtml() {
    var controllerName = `commercial--sales--opportunities--entities--closing`
    var productHtml = ``

    // `<div class="col-2 px-2 d-flex align-items-center">
    //   <div class="form-group w-100 mb-0">
    //     <div class="floating-label floating-label-sm">
    //       <label for="reasonForm-${product.id}">Motivo Perda</label>
    //       <input id="reasonForm-${product.id}" class="form-control f-075 pt-0" data-${controllerName}-target="productReason-${product.id}" type="text" placeholder="Motivo Perda" required>
    //     </div>
    //   </div>
    // </div>`

    this.application.opportunity.products.forEach(product => {
      
      productHtml += `<div class="row my-2 d-flex align-items-center">
                        <div class="col-3 px-2 f-075">
                          ${product.name_pretty} | ${product.recurring_pretty}
                        </div>
                        <div class="col-2 px-2 d-flex align-items-center">
                          <div class="form-group w-100 mb-0">
                            <div class="floating-label floating-label-sm">
                              <label for="amountForm-${product.id}">Valor Oportunidade</label>
                              <input disabled id="amountForm-${product.id}" class="form-control f-075 pt-0" data-${controllerName}-target="productAmount-${product.id}" data-action="blur->${controllerName}#currencyMask keyup->${controllerName}#currencyMask keypress->${controllerName}#currencyMask" type="tel" placeholder="Valor" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 px-2 d-flex align-items-center">
                          <div class="form-group w-100 mb-0">
                            <div class="floating-label floating-label-sm">
                              <label for="gainForm-${product.id}">Valor Ganho</label>
                              <input id="gainForm-${product.id}" class="form-control f-075 pt-0" data-${controllerName}-target="productGain-${product.id}" data-action="blur->${controllerName}#currencyMask keyup->${controllerName}#currencyMask keypress->${controllerName}#currencyMask" type="tel" placeholder="Valor" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 px-2 d-flex align-items-center">
                          <div class="form-group w-100 mb-0">
                            <div class="floating-label floating-label-sm">
                              <label for="lostForm-${product.id}">Valor Perdido</label>
                              <input id="lostForm-${product.id}" class="form-control f-075 pt-0" data-${controllerName}-target="productLost-${product.id}" data-action="blur->${controllerName}#currencyMask keyup->${controllerName}#currencyMask keypress->${controllerName}#currencyMask" type="tel" placeholder="Valor" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-3 px-2 d-flex align-items-center" data-${controllerName}-target="reasonDiv-${product.id}">
                          <div class="form-group w-100 mb-1">
                            <div class="floating-label floating-label-sm">
                              <label>Motivo Perda</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${controllerName}-target="reasonDropdown-${product.id}">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${controllerName}-target="reasonDropdownBtn-${product.id}"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${controllerName}-target="reasonInput-${product.id}"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${controllerName}-target="reasonList-${product.id}" data-app--helpers--search-target="searchList">
                                    <li data-reason="never_answer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nunca Atendeu</li>
                                    <li data-reason="stop_answer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Parou de Atender</li>
                                    <li data-reason="price_objection" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Objeção por Preço</li>
                                    <li data-reason="non_complain" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Satisfeito com Contador Atual</li>
                                    <li data-reason="non_profile" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Perfil Imediato</li>
                                    <li data-reason="non_interest" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Interesse</li>
                                    <li data-reason="non_doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não é Médico</li>
                                    <li data-reason="duplicated_opportunity" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Oportunidade Duplicada</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>`
    })

    var html = `<div class="modal fade" data-controller="${controllerName}" data-${controllerName}-target="modal" tabindex="-1" role="dialog" aria-labelledby="closingModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Ajuste dos Valores!</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${controllerName}-target="body">
                        ${productHtml}
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${controllerName}#closeModal">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-${controllerName}-target="saveBtn" data-action="click->${controllerName}#saveProducts">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--closing`).setValues()
    })
  }

  doLostModalHtml() {
    var controllerName = `commercial--sales--opportunities--entities--lost`

    var html = `<div class="modal fade" data-controller="${controllerName}" data-${controllerName}-target="modal" tabindex="-1" role="dialog" aria-labelledby="closingModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Selecione o Motivo da Perda</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${controllerName}-target="body">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-2 d-flex align-items-center">
                            <div class="form-group w-100 mb-1">
                              <div class="floating-label floating-label-sm">
                                <label>Motivo Perda</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${controllerName}-target="reasonDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${controllerName}-target="reasonDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${controllerName}-target="reasonInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${controllerName}-target="reasonList" data-app--helpers--search-target="searchList">
                                      <li data-reason="never_answer" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nunca Atendeu</li>
                                      <li data-reason="stop_answer" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Parou de Atender</li>
                                      <li data-reason="price_objection" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Objeção por Preço</li>
                                      <li data-reason="non_complain" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Satisfeito com Contador Atual</li>
                                      <li data-reason="non_profile" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Perfil Imediato</li>
                                      <li data-reason="non_interest" data-action="click->app--helpers--selector#select click->${controllerName}#selectReason" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sem Interesse</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${controllerName}#closeModal">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-${controllerName}-target="saveBtn" data-action="click->${controllerName}#saveOpportunity">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  updateTicketsIndicators() {
    this.application.opportunity.total_task = 0
    this.application.opportunity.total_call = 0
    this.application.opportunity.total_mail = 0
    this.application.opportunity.total_message = 0

    this.application.tickets.forEach(element => {
      if (element.kind == `call`) {
        this.application.opportunity.total_call += 1
      } else if (element.kind == `task`) {
        this.application.opportunity.total_task += 1
      } else if (element.kind == `mail`) {
        this.application.opportunity.total_mail += 1
      } else if (element.kind == `message`) {
        this.application.opportunity.total_message += 1
      }
    })

    this.setTicketIndicators()
  }

  setTicketIndicators() {
    this.ticketTasksTarget.innerHTML = `<span>Tarefas: ${this.application.opportunity.total_task}</span>`
    this.ticketCallsTarget.innerHTML = `<span>Ligações: ${this.application.opportunity.total_call}</span>`
    this.ticketMailsTarget.innerHTML = `<span>E-mails: ${this.application.opportunity.total_mail}</span>`
    this.ticketMessagesTarget.innerHTML = `<span>Mensagens: ${this.application.opportunity.total_message}</span>`
  }

  userCanUpdate() {
    // var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]
    var allowed = this.mapElements(this.application.sales_team, `id`)
    
    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  hasOpportunityOwners() {
    if (this.application.opportunity.prospector_id == undefined || this.application.opportunity.closer_id == undefined) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor definir o SDR e o Closer da Oportunidade`, 5000)
      return false
    } else {
      return true
    }
  }

  deleteOpportunity() {
    var r = confirm("Tem certeza que deseja apagar a Oportunidade?")
    this.deleteOpportunityBtnTarget.disabled = true

    if (r) {
      this.send_data = { current_user: {}, opportunity: {} }

      this.send_data.opportunity.id = this.application.opportunity.id
      this.send_data.opportunity.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `sales_opportunities_entities`

      this.requestDestroyOpportunity()
    } else {
      this.deleteOpportunityBtnTarget.disabled = false
    }

  }

  requestDestroyOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/commercial/sales/opportunities/entities/destroy"
    var method = "DELETE"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {          
          window.open(`/a/oportunidades`, `_self`)
        } else {
          controller.deleteOpportunityBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  requestSaveOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`
    
    var url = "/commercial/sales/opportunities/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.application.opportunity = response.data.cln
          controller.setPageHeader()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestSaveJourney() {
    var url = "/commercial/sales/opportunities/journeys/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} avançou a Oportunidade de Venda para ${journey.stage_pretty}`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)
          controller.application.opportunity.stage = journey.stage
          controller.application.opportunity.stage_pretty = journey.stage_pretty
          controller.application.opportunity.journeys[controller.application.opportunity.journeys.length] = journey
          controller.application.opportunity.journeys.forEach(element => {
            element.stage_alert = false
          })
          if (journey.stage == `gain` || journey.stage == `lost`) {
            window.location.reload()
          } else {
            controller.setPageHeader()
            controller.setJourneys()
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestSaveLead() {
    var url = "/commercial/sales/opportunities/leads/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var lead = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} adicionou o Lead ${lead.lead_name} para a Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)
          if (controller.actionMode == "new") {
            controller.application.opportunity.leads[controller.application.opportunity.leads.length] = lead
            controller.application.opportunity.total_leads += 1
          } else if (controller.actionMode == "edit") {
            if (lead.active) {
              controller.application.opportunity.leads.forEach((element, i) => {
                if (element.id == lead.id) {
                  controller.application.opportunity.leads.splice(controller.application.opportunity.leads.indexOf(element), 1, lead)
                }
              })
            } else {
              controller.application.opportunity.leads.forEach((element, i) => {
                if (element.id == lead.id) {
                  controller.application.opportunity.leads.splice(controller.application.opportunity.leads.indexOf(element), 1)
                  controller.application.opportunity.total_leads -= 1
                }
              })
            }
          }
        }
        controller.setLeads()
        controller.setPageTitle()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestDestroyLead() {
    var url = "/commercial/sales/opportunities/leads/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var lead = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} retirou o Lead ${lead.lead_name} da Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)
          controller.application.opportunity.leads.forEach((element, i) => {
            if (element.id == lead.id) {
              controller.application.opportunity.leads.splice(controller.application.opportunity.leads.indexOf(element), 1)
              controller.application.opportunity.total_leads -= 1
            }
          })
        }
        controller.setLeads()
        controller.setPageTitle()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestSaveProduct() {
    var url = "/commercial/sales/opportunities/products/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var product = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} adicionou o Produto ${product.name_pretty} para a Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)
          if (controller.actionMode == "new") {
            controller.application.opportunity.products[controller.application.opportunity.products.length] = product
            if (product.recurring) {
              controller.application.opportunity.total_amount_recurring = Number(controller.application.opportunity.total_amount_recurring) + Number(product.amount)
            }
          } else if (controller.actionMode == "edit") {
            if (product.active) {
              controller.application.opportunity.products.forEach((element, i) => {
                if (element.id == product.id) {
                  controller.application.opportunity.products.splice(controller.application.opportunity.products.indexOf(element), 1, product)
                }
              })
            } else {
              controller.application.opportunity.products.forEach((element, i) => {
                if (element.id == product.id) {
                  controller.application.opportunity.products.splice(controller.application.opportunity.products.indexOf(element), 1)
                  if (product.recurring) {
                    controller.application.opportunity.total_amount_recurring = Number(controller.application.opportunity.total_amount_recurring) - Number(product.amount)
                  }
                }
              })
            }
          }
        }
        controller.cancelProduct()
        controller.setProducts()
        controller.setPageTitle()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestDestroyProduct() {
    var url = "/commercial/sales/opportunities/products/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var product = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} retirou o Produto ${product.name_pretty} da Oportunidade de Venda`
          controller.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").saveUpdateNote(message)
          controller.application.opportunity.products.forEach((element, i) => {
            if (element.id == product.id) {
              controller.application.opportunity.products.splice(controller.application.opportunity.products.indexOf(element), 1)
              controller.application.opportunity.total_amount_recurring = Number(controller.application.opportunity.total_amount_recurring) - Number(product.amount)
            }
          })
        }
        controller.setProducts()
        controller.setPageTitle()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  tryNewOpportunitity() {
    var r = confirm(`Tem certeza que deseja criar uma Nova Oportunidade?`)
    if (r) {
      this.saveNewOpportunitity()    
    }
  }

  saveNewOpportunitity() {

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var notes = `Nova Oportunidade após a perda por [${this.application.opportunity.products[0].reason_pretty}]. Oportunidade anterior: ${this.application.opportunity.token}`
    this.send_data = { current_user: {}, lead: {}, opportunity: {}, notification: {}, note: {} }

    this.send_data.lead.id = this.application.opportunity.leads[0].lead_id
    this.actionMode = `new`

    this.send_data.opportunity.status = `hot`
    this.send_data.opportunity.stage = `prospecting`
    this.send_data.opportunity.source = this.application.opportunity.source
    this.send_data.opportunity.started_at = new Date()
    this.send_data.opportunity.notes = notes
    this.send_data.opportunity.prospector_id = this.application.opportunity.prospector_id
    this.send_data.opportunity.closer_id = this.application.opportunity.closer_id
    this.send_data.opportunity.prospector_name = this.application.opportunity.prospector_name
    this.send_data.opportunity.closer_name = this.application.opportunity.closer_name
    this.send_data.opportunity.kind = this.application.opportunity.kind
    this.send_data.opportunity.campaign = `reactivation`

    this.send_data.notification.domain_id = ``
    this.send_data.notification.domain_type = "sales_opportunities"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "commercial_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% uma nova Oportunidade de Venda com o Lead ${this.application.opportunity.leads[0].lead_name}`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    this.send_data.note.account_id = this.application.current_user.account_id
    // this.send_data.note.domain_id = this.application.opportunity.id
    this.send_data.note.domain_type = `sales_opportunities`
    this.send_data.note.domain_name = `sales_opportunities`
    // this.send_data.note.date_id = this.application.opportunity.date_id
    this.send_data.note.date_type = `commercial_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} criou uma nova Oportunidade de Venda com Lead ${this.application.opportunity.leads[0].lead_name}. Observações: ${notes}`
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    var url = "/commercial/sales/opportunities/entities/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln
          window.open(opportunity.url, `_blank`)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  finishLoadPage() {
    this.setPageTitle()
    this.setPageHeader()
    this.setLeads()
    this.setProducts()
    this.setProposal()
    this.setJourneys()
    // this.setTicketIndicators()
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--tickets").doTicketsDashboard()
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--notes").doNotesDashboard()
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--calls").doCallsDashboard()
    this.getControllerByIdentifier("commercial--sales--opportunities--entities--schedules").doSchedulesDashboard()

    if (this.application.permissions.sales_opportunities_leads.can_create) {
      this.addLeadBtnTarget.classList.remove("d-none")
    }
    if (this.application.permissions.sales_opportunities_products.can_create) {
      this.addProductBtnTarget.classList.remove("d-none")
    }
    if (this.application.permissions.sales_opportunities_products.can_update) {
      this.addProposalBtnTarget.classList.remove("d-none")
    }
        // if (this.application.permissions.sales_opportunities_tickets.can_create) {
        //   this.addTicketBtnTarget.classList.remove("d-none")
        // }
        // if (this.application.permissions.sales_opportunities_notes.can_create) {
        //   this.addNoteBtnTarget.classList.remove("d-none")
        // }

    if (this.application.opportunity.stage == `gain` || this.application.opportunity.stage == `lost` || this.userCanUpdate() == false) {
      this.addLeadBtnTarget.remove()
      this.addProductBtnTarget.remove()
      this.addProposalBtnTarget.remove()
      // this.addTicketBtnTarget.remove()
      // this.addNoteBtnTarget.remove()
    }

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getCalls() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ligações`, value: 95 })

    var data = { call: { record_id: this.application.opportunity.id, record_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_calls` } }
    const url = "/app/communications/voip/calls/list_by_record"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.calls = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSchedules() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Agendamentos`, value: 93 })

    var data = { schedule: { record_id: this.application.opportunity.id, record_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_schedules` } }
    const url = "/users/works/schedules/entities/list_by_record"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.schedules = response.data.cln

        controller.getCalls()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 90 })

    var data = { note: { domain_id: this.application.opportunity.id, domain_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.getSchedules()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 80 })

    var data = { ticket: { board_id: this.application.opportunity.id, board_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPurchase() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contratação`, value: 50 })

    const data = { purchase: { opportunity_token: this.application.opportunity_token }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_entities` } }
    const url = "/financials/products/purchases/entities/read_by_opportunity"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.purchase = response.data.cln
          controller.application.has_purchase = response.data.has_purchase
        }
        controller.getTickets()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Oportunidade`, value: 40 })

    var data = { opportunity: { token: this.application.opportunity_token }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.opportunity = response.data.cln
          
          controller.getPurchase()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOrigins() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Origens`, value: 40 })

    var data = { origin: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/origins/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.origins = response.data.cln
        controller.getOpportunity()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSalesTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Vendas`, value: 35 })

    const data = { feature: { feature_name: `sales_opportunities_entities`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sales_team = response.data.cln
        controller.application.team_list = response.data.cln
        controller.getOrigins()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCommercialDate() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 30 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.current_date = response.data.cln
        controller.application.current_calculation = response.data.cln.calculation
        controller.getSalesTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ramal`, value: 20 })

    const data = { caller: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_calls` } }
    const url = "/app/communications/voip/callers/read_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.caller = response.data.cln
        }

        controller.getCommercialDate()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_opportunitites` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        // controller.getCommercialDate()
        controller.getCaller()
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  checkOpportunityJourneys(newJourney) {
    var journeys = this.mapElements(this.application.opportunity.journeys, `stage`)

    const hasJourney = (currentValue) => currentValue != undefined

    if ((!journeys.every(hasJourney)) || (journeys.includes(newJourney))) {
      return true
    } else {
      return false
    }
  }

  checkProductOpportunity(newJourney) {
    var obj = {}

    if (newJourney == `proposal` || newJourney == `closing` || newJourney == `gain`) {
      if ((this.application.opportunity.products.length == 0) && (this.application.opportunity.has_proposal == false)) {
        obj.canForward = false
        obj.message = `Para avançar a Oportunidade, favor Inserir o Produto e anexar a Proposta Comercial`
      } else if (this.application.opportunity.products.length == 0) {
        obj.canForward = false
        obj.message = `Para avançar a Oportunidade, favor Inserir o Produto`
      } else if (this.application.opportunity.has_proposal == false) {
        obj.canForward = false
        obj.message = `Para avançar a Oportunidade, favor anexar a Proposta Comercial`
      } else {
        obj.canForward = true
      }
    } else {
      obj.canForward = true
    }

    return obj

  }

  refreshSaveBtn() {
    var controller = this
    this.refreshProductTimer = setInterval(function () {
      var len = 0

      if (controller.opportunityProductNameInputTarget.value == ``) {
        len += 1
      }

      if (controller.checkboxRecurringTarget.checked && controller.opportunityProductPlanInputTarget.value == ``) {
        len += 1
      }

      if (controller.checkboxRecurringTarget.checked && controller.opportunityProductKindInputTarget.value == ``) {
        len += 1
      }

      // if (controller.opportunityProductAmountInputTarget.value == `` || controller.opportunityProductAmountInputTarget.value == `R$ 0,00`) {
      if (controller.opportunityProductAmountInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshProductTimer) {
      clearInterval(this.refreshProductTimer)
    }
    if (this.refreshProposalTimer) {
      clearInterval(this.refreshProposalTimer)
    }
    if (this.progressCountTimer) {
      clearInterval(this.progressCountTimer)
    }
  }

  refreshProposalBtn() {
    var controller = this
    this.refreshProposalTimer = setInterval(function () {
      var len = 0

      if (controller.uploadReady == false) {
        len += 1
      }

      if (controller.hasUploadBtnTarget) {
        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 


  // "", "", "", "sales_opportunities_comments", "",
  // "", "sales_opportunities_notes", "sales_opportunity_prospection_flow", "sales_opportunity_closing_flow"

  // sales_opportunities_comments

}