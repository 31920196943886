import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["companiesCard", "companies", "bodyTable", "3333", "4444", "5555",
                    "addIntegrationBtn", "destroyIntegrationBtn", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--integrations--companies`
    this.tableCols = 7
  }

  addIntegration() {
    this.actionMode = "new"
    // this.current_integration = {}
    // this.doFormHtml()
    this.saveCompany()
  }

  saveCompany() {
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }

    this.send_data.company.clinic_id = this.application.clinic.id
    this.send_data.company.cnpj = this.application.clinic.company_cnpj
    this.send_data.company.integration_type = `nfeio`
    this.send_data.company.integration_kind = `invoice`
    this.send_data.company.environment = `development`
    this.send_data.company.rps_number = 1
    this.send_data.company.clinic_name = this.application.clinic.company_name

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`

    this.requestSave()
  }

  destroyIntegrationCompany(ev) {
    this.actionMode = "destroy"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }

    this.send_data.company.id = ev.currentTarget.closest(`.itemRow`).dataset.id
    this.send_data.company.clinic_id = this.application.clinic.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `destroyed`

    var r = confirm(`Tem certeza que deseja apagar a Integração?`)
    if (r) {
      this.requestSave()
    }
  }

  updateIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.application.clinic.company_name
    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    var url = "/operations/products/clinics/integrations/accountings/companies/update_integration"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.application.integration_companies.forEach((element, i) => {
            if (element.id == integration_company.id) {
              controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1, integration_company)
            }
          })
          controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  createIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(`.itemRow`).dataset.id

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.application.clinic.company_name
    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `created`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    var url = "/operations/products/clinics/integrations/accountings/companies/create_integration"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.application.integration_companies.forEach((element, i) => {
            if (element.id == integration_company.id) {
              controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1, integration_company)
            }
          })
          controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateEnvironment(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "destroy"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var environment = ev.currentTarget.dataset.environment

    this.send_data.company.id = integrationId
    this.send_data.company.environment = environment
    this.send_data.company.clinic_name = this.application.clinic.company_name
    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    var url = "/operations/products/clinics/integrations/accountings/companies/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.application.integration_companies.forEach((element, i) => {
            if (element.id == integration_company.id) {
              controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1, integration_company)
            }
          })
          controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyIntegration(ev) {
    var r = confirm(`Tem certeza que deseja Apagar a Empresa no NFe.io?`)
    if (r) {
      this.doDestroyIntegration(ev)
    }
  }

  doDestroyIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var environment = ev.currentTarget.dataset.environment

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.application.clinic.company_name
    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `destroyed`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_integration_companies`

    var url = "/operations/products/clinics/integrations/accountings/companies/destroy_integration"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.application.integration_companies.forEach((element, i) => {
            if (element.id == integration_company.id) {
              controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1, integration_company)
            }
          })
          controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = "/operations/products/clinics/integrations/accountings/companies/create"
      var method = "POST"      
    } else if (this.actionMode == `destroy`) {
      var url = "/operations/products/clinics/integrations/accountings/companies/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.integration_companies[controller.application.integration_companies.length] = integration_company
          } else if (controller.actionMode == `edit`) {
            controller.application.integration_companies.forEach((element, i) => {
              if (element.id == integration_company.id) {
                controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1, integration_company)
              }
            })
          } else if (controller.actionMode == `destroy`) {
            controller.application.integration_companies.forEach((element, i) => {
              if (element.id == integration_company.id) {
                controller.application.integration_companies.splice(controller.application.integration_companies.indexOf(element), 1)
              }
            })
          }

          controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doCompaniesCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="companiesCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Integrações de Empresas</h6>
                    <div class="card-actions ml-auto py-0">
                      <span class="mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 mc-tooltip" data-${this.controllerName}-target="addIntegrationBtn" data-action="click->${this.controllerName}#addIntegration" type="button">
                          <span class="material-icons md-dark">add</span>
                        </button>
                        <span class="mc-tooltiptext">Adicionar Integração</span>
                      </span>
                    </div>
                  </div>
                  <div class="card-body py-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">Integração</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Ambiente</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Fiscal</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Certificado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center">RPS</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center">Integração</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--integrations--dashboard").companiesTarget.innerHTML = html)
    }).then(() => {
      controller.companiesCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
      if (controller.application.integration_companies.length > 0) {
        controller.addIntegrationBtnTarget.remove()
      }
    })
  }

  doDataTable() {

    this.listData(this.application.integration_companies)

  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Integrações</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.takerTablePartial(element, data.length))
      });
    }
  }

  takerTablePartial(element, length) {
    if (this.application.permissions.medclinic_management_integration_companies.can_update && element.certificate_status == `active_certificate`) {
      if (element.environment == `development`) {
        var environmentStatus = `<button data-action="click->${this.controllerName}#updateEnvironment" data-environment="production" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">code</span>
                                  <span class="mc-tooltiptext">${element.environment_pretty}</span>
                                </button>`
      } else if (element.environment == `production`) {
        if (this.application.current_user.super_admin) {
          var environmentStatus = `<button data-action="click->${this.controllerName}#updateEnvironment" data-environment="development" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">check_circle</span>
                                  <span class="mc-tooltiptext">${element.environment_pretty}</span>
                                </button>`  
        } else {
          var environmentStatus = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-150 md-white default">check_circle</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
        }
        
      }
    } else {
      if (element.environment == `development`) {
        var environmentStatus = `<span class="mc-tooltip badge badge-primary"><span class="material-icons md-150 md-white default">code</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
      } else if (element.environment == `production`) {
        var environmentStatus = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-150 md-white default">check_circle</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
      } else {
        var environmentStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      }
    }

    if (element.fiscal_status_pretty) {
      var fiscalStatus = element.fiscal_status_pretty
    } else {
      var fiscalStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
    }

    if (element.certificate_status_pretty) {
      var certificateStatus = element.certificate_status_pretty
    } else {
      var certificateStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
    }

    if (element.integration_id) {
      if (this.application.current_user.super_admin) {
        var destroyIntegration = `<button data-action="click->${this.controllerName}#destroyIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">clear</span>
                                    <span class="mc-tooltiptext">Apagar Empresa no NFe.io</span>
                                  </button>`
      } else {
        var destroyIntegration = ``
      }

      var integrationName = `<span class="mc-tooltip pointer">
                              <span data-controller="app--helpers--copy" data-copy="${element.integration_id}" data-action="click->app--helpers--copy#copy">${element.integration_type_pretty}</span>
                              <span class="mc-tooltiptext">Clique para copiar</span>
                            </span>`

      var integrationStatus = `<span class="mc-tooltip badge badge-success"> 
                                <span class="material-icons md-200 md-white default">domain_verification</span>
                                <span class="mc-tooltiptext">Integrado</span>
                              </span>
                              <button data-action="click->${this.controllerName}#updateIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">refresh</span>
                                <span class="mc-tooltiptext">Clique para Atualizar</span>
                              </button>
                              ${destroyIntegration}`
    } else {
      var integrationName = `${element.integration_type_pretty}`

      if (this.application.current_user.super_admin) {
        var destroyIntegrationCompany = `<button data-action="click->${this.controllerName}#destroyIntegrationCompany" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                          <span class="material-icons md-sm md-dark">clear</span>
                                          <span class="mc-tooltiptext">Apagar Integração</span>
                                        </button>`
      } else {
        var destroyIntegrationCompany = ``
      }

      var integrationStatus = `<button data-action="click->${this.controllerName}#createIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">launch</span>
                                <span class="mc-tooltiptext">Clique para Integrar</span>
                              </button>
                              ${destroyIntegrationCompany}`
    }

    // integrationStatus = ``
    // environmentStatus = ``

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:75px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${integrationName}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${environmentStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${fiscalStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${certificateStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier(`app--helpers--numbers`).numberMask(element.rps_number)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${integrationStatus}</td>
                  </tr>`

    return rowHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
