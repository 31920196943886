import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--dashboard--broadcast`
    this.application.commercial_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.commercialUpdateChannel = createChannel({ channel: 'Commercial::Config::MainChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        // controller.application.commercial_calculations.splice(controller.application.commercial_calculations.indexOf(element), 1, data.cln)
        if (data.kind == "commercial_calculation") {
          var calculation = data.cln
          controller.application.dates.forEach(element => {
            if (element.id == calculation.date_id) {
              element.calculation = calculation
            }
          })

          if (controller.application.current_calculation.id == calculation.id) {
            controller.application.current_calculation = calculation
          }

          controller.getControllerByIdentifier("commercial--dashboards--main").doCalculationCards()
          // controller.getControllerByIdentifier("commercial--sales--leads--entities--list").doIndexListHtml()
          // controller.getControllerByIdentifier("commercial--sales--leads--entities--list").getLeads()
          // controller.getControllerByIdentifier("commercial--sales--leads--entities--list").doCalculationCards()

        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  updateListen() {
    if (this.commercialUpdateChannel) {
      this.commercialUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
