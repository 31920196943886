import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "vacancyName", "metric", "vacancy", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--recruitments--vacancies--show--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.vacancy_slug = location.pathname.split("/").pop()
    this.application.permissions = {}
    this.application.attendees = []
    this.application.tickets = []
    this.application.notes = []
    this.application.applicants = []
    this.application.calls = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-${this.controllerName}-target="content"
                                      data-controller="users--works--recruitments--vacancies--show--dashboard
                                                       users--works--permissions--entities--list-users">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).doDashboardHtml()
      controller.getCurrentUserPermissions()
    })
  }

  setPageTitle() {
    this.vacancyNameTarget.innerHTML = `<span class="mb-0 mc-tooltip grab">
                                          <span data-controller="app--helpers--copy" data-copy="${this.application.vacancy.name}" data-action="click->app--helpers--copy#copy">Vaga ${this.application.vacancy.name}</span>
                                          <span class="mc-tooltiptext">Clique para Copiar</span>
                                        </span>`

    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = `Vaga ${this.application.vacancy.name}`
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = `Vaga ${this.application.vacancy.name}`
      }, 1000);
    }
  }

  setCurrentSquad() {
    this.application.squads.forEach(element => {
      if (element.team_id == this.application.current_user.account_id) {
        this.application.current_squad = element
      }
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    this.setPageTitle()
    this.setCurrentSquad()

    this.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).doFinishedPage()
    // this.getControllerByIdentifier(`users--works--recruitments--vacancies--show--submenu`).doSubmenuHtml()

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getCalls() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ligações`, value: 98 })

    var data = { call: { record_id: this.application.vacancy.id, record_type: `recruitment_vacancies` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_calls` } }
    const url = "/app/communications/voip/calls/list_by_record"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.calls = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSchedules() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Agendamentos`, value: 95 })

    var data = { schedule: { record_id: this.application.vacancy.id, record_type: `recruitment_vacancies` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_schedules` } }
    const url = "/users/works/schedules/entities/list_by_record"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.schedules = response.data.cln

        controller.getCalls()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 90 })

    var data = { note: { domain_id: this.application.vacancy.id, domain_type: `recruitment_vacancies` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.getSchedules()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 80 })

    var data = { ticket: { board_id: this.application.vacancy.id, board_type: `recruitment_vacancies` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ramal`, value: 75 })

    const data = { caller: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_calls` } }
    const url = "/app/communications/voip/callers/read_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.caller = response.data.cln
        }

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getApplicants() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Candidatos`, value: 70 })

    var data = { applicant: { vacancy_id: this.application.vacancy.id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_applicants` } }
    const url = "/users/works/recruitments/applicants/list_by_vacancy"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.applicants = response.data.cln
        }

        controller.getCaller()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getVacancyTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time`, value: 60 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_vacancies` } }
    const url = "/users/works/recruitments/vacancies/list_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team = response.data.cln
        controller.application.team_list = response.data.cln

        controller.getApplicants()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSources() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fontes`, value: 50 })

    const data = { source: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_applicants` } }
    const url = "/users/works/recruitments/applicants/list_source"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sources = response.data.cln

        controller.getVacancyTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squad`, value: 40 })

    const data = { squad: { vacancy_id: this.application.vacancy.id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_squads` } }
    const url = "/users/works/recruitments/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.squads = response.data.cln

        controller.getSources()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getVacancy() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Vaga`, value: 30 })

    const data = { vacancy: { id: this.application.vacancy_slug }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_vacancies` } }
    const url = "/users/works/recruitments/vacancies/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.vacancy = response.data.cln

        controller.getSquads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_recruitments` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_recruitments_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getVacancy()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
