import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["jobsPageBtn", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  // connect() {
  // }

  goTojobsPage() {
    var url = `https://jobs.solides.com/medcapital`
    window.open(url, `_blank`)
  }

}
