import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "company", "product", "contacts", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--entities--dashboard`
  }

  doEntitiesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4" data-${this.controllerName}-target="company" data-controller="operations--products--clinics--regularizations--entities--company"></div>
                  <div class="col-4" data-${this.controllerName}-target="product" data-controller="operations--products--clinics--regularizations--entities--product"></div>
                  <div class="col-4" data-${this.controllerName}-target="contacts" data-controller="operations--products--clinics--regularizations--entities--contacts"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--regularizations--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--show--submenu`).dashboardToggleBtn(`PJ MÉDICA`)

      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--company`).permission = `medclinic_regularizations`
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--company`).doClinicCard()
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--product`).permission = `medclinic_regularizations`
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--entities--product`).doClinicCard()
    }) 
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
