
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `commercial--marketing--events--show--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    
    this.getControllerByIdentifier("commercial--marketing--events--show--dashboard").doDashboardHtml()
    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.getControllerByIdentifier("commercial--marketing--events--show--dashboard").doDashboardHtml())
    // }).then(() => {
    //   controller.getControllerByIdentifier("commercial--marketing--events--show--tasks").doDataTable()
    // })
  }

  goToAdmissions() {
    this.getControllerByIdentifier("commercial--marketing--events--admissions--dashboard").doAdmissionGrid()
  }

  goToCreative() {
    this.getControllerByIdentifier("commercial--marketing--events--show--creative").doCreativeGrid()
  }

  goToLanding() {
    this.getControllerByIdentifier("commercial--marketing--events--show--landing").doLandingGrid()
  }

  goToLinks() {
    this.getControllerByIdentifier("commercial--marketing--events--show--links").doLinkGrid()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var attendeeBtn = `<button type="button" data-action="${this.controllerName}#goToAdmissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Participantes</button>`
    var creativeBtn = `<button type="button" data-action="${this.controllerName}#goToCreative ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Criativo</button>`
    var landingBtn = `<button type="button" data-action="${this.controllerName}#goToLanding ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Landing</button>`
    var linkBtn = `<button type="button" data-action="${this.controllerName}#goToLinks ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Links</button>`

    var html = `<div class="btn-group ml-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${attendeeBtn}
                  ${creativeBtn}
                  ${landingBtn}
                  ${linkBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      // controller.doBadgeCounts()
    })
  }

  toggleBtn(ev) {
    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
