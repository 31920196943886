import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--recruitments--tickets--dashboard`
  }

  doTicketsDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 pl-1" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).ticketsRowTarget.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier(`users--works--recruitments--dashboard--submenu`).dashboardToggleBtn(`TICKETS`)

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = `user_works_recruitments_tickets`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 4
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).kind = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).term = true
      // controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_count_controller = controller.getControllerByIdentifier(`users--works--recruitments--dashboard--submenu`)
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: this.application.vacancy.id,
        board_type: `recruitment_vacancies`,
        board_subtype: `recruitment_vacancies`,
        board_path: window.location.pathname,
        board_name: `R&S Vaga - ${this.application.vacancy.name}`,
        // date_id: this.application.current_date.id,
        // date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: this.application.vacancy.id,
        board_type: `recruitment_vacancies`,
        // date_id: this.application.vacancy.id,
        // date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).doIndexListHtml()

    })
  }

  createTicket(ticketBody) {
    this.actionMode = `new`
    if (this.hasAddTicketBtnTarget) {
      this.addTicketBtnTarget.disabled = true
    }
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }

    this.send_data.ticket.board_id = this.application.vacancy.id
    this.send_data.ticket.board_type = `recruitment_vacancies`
    this.send_data.ticket.board_subtype = `recruitment_vacancies`
    this.send_data.ticket.board_path = window.location.pathname
    this.send_data.ticket.board_name = `R&S Vaga - ${this.application.vacancy.name}`
    // this.send_data.ticket.date_id = this.ticket_board.date_id
    // this.send_data.ticket.date_type = this.ticket_board.date_type
    this.send_data.ticket.owner_id = this.application.current_user.account_id
    this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.performer_id = this.application.current_user.account_id
    this.send_data.ticket.performer_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.stage = `in_process`
    this.send_data.ticket.status = `in_time`
    this.send_data.ticket.sharing = `internal`
    this.send_data.ticket.term = `quick`
    this.send_data.ticket.priority = `maximum`
    this.send_data.ticket.due_at = new Date()
    this.send_data.ticket.started_at = new Date()
    this.send_data.ticket.flag = `blue`
    this.send_data.ticket.kind = `task`
    // this.send_data.ticket.obj_id = this.ticket_board.obj_id
    // this.send_data.ticket.obj_type = this.ticket_board.obj_type

    this.send_data.ticket.body = ticketBody
    

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_works_recruitments_tickets`

    this.send_data.tracker.action = `created`

    this.requestSave()
  }

  requestSave() {
    var url = `/users/works/activities/tickets/create`
    var method = "POST"

    this.send_data.tracker.log = true
    this.send_data.tracker.record_id = this.application.vacancy.id
    this.send_data.tracker.record_type = `recruitment_vacancies`


    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var ticket = response.data.cln
          controller.application.tickets[controller.application.tickets.length] = ticket
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
