import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "addProductBtn",
    "sideBarReport"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--index--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `company_name` }
    this.tableCols = 8
  }

  addProduct() {
    var html = `<div class="row">
                  <div class="col-12" data-controller="operations--products--clinics--regularizations--add--dashboard" data-operations--products--clinics--regularizations--add--dashboard-target="main"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      // controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--regime").doRegimeFormHtml()
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").step = `company`
    })
  }

  editProduct(ev) {
    this.send_data = { current_user: {}, product: {} }
    this.application.clinics.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        this.current_product = element
      }
    })

    var html = `<div class="row">
                  <div class="col-12" data-controller="operations--products--clinics--regularizations--add--dashboard" data-operations--products--clinics--regularizations--add--dashboard-target="main"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").current_product = controller.current_product
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").step = `regime`
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addProduct" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar PJ Médica</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshProducts" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar PJ's Médicas</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as PJ's Médicas</h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                    ${clearBtnHtml}
                    ${submenuHtml}
                  </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">CNPJ</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Data Início</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer text-center">
                                  Status
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle pointer text-center">
                                  Regime Tributário
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer text-center">
                                  Natureza Legal
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="legal_nature_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="legal_nature_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer text-center">
                                  Cidade
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="company_city" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="company_city" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 4))
      controller.doDataTable()
      // if (controller.application.clinics.length > 0) {
      // }
    })
  }

  doDataTable() {
    // var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.clinics, "company_name")

    if (this.sort.mode == `asc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.clinics, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.clinics, this.sort.field)
    }

    if (clinics.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(clinics.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (clinics[x] !== undefined) {
            internPage[internPage.length] = clinics[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: clinics.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há PJ Médicas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.clinicTablePartial(element))
      });
    }
  }

  clinicTablePartial(element) {
    var notificationsCount = 0

    this.application.notifications.forEach(notification => {
      if (notification.domain_id == element.id) {
        notificationsCount++
      }
    });
    element.notification_count = notificationsCount

    if (this.application.permissions[this.permission].can_select) {
      var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showTaxReturn">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`
    } else {
      var check = ''
    }

    if (this.application.permissions[this.permission].can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (element.blocked) {
      var statusBlocked = `<span style="color:#F25F5C;" class="mc-tooltip">
                            <span class="material-icons md-sm">clear</span>
                            <span class="mc-tooltiptext">Bloqueado</span>
                          </span>`
    } else {
      var statusBlocked = `<span style="color:#26C485;" class="mc-tooltip">
                            <span class="material-icons md-sm">done_all</span>
                            <span class="mc-tooltiptext">Status Ok</span>
                          </span>`
    }

    if (this.application.permissions[this.permission].can_delete) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyProduct" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else {
      var destroy = ``
    }

    if (this.application.permissions[this.permission].can_update && element.has_tax_regimes) {
      var edit = ``
    } else {
      var edit = `<button data-action="click->${this.controllerName}#editProduct" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">edit</span>
                      <span class="mc-tooltiptext">Editar</span>
                    </button>`
    }

    if (element.user) {
      var statusUser = `<span style="color:#26C485;" class="mc-tooltip">
                          <span class="material-icons md-sm">done_all</span>
                          <span class="mc-tooltiptext">Usuário Ok</span>
                        </span>`
    } else {
      var statusUser = `<span style="color:#F25F5C;" class="mc-tooltip">
                          <span class="material-icons md-sm">clear</span>
                          <span class="mc-tooltiptext">Sem Conta</span>
                        </span>`
    }

    var productPath = `<button data-action="click->${this.controllerName}#goToShowPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ir para PJ Médica ${element.company_name}</span>
                      </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.id}" data-company-path="${element.company_path}" data-product-path="${element.product_path}">
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.company_name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ir para PJ Médica ${element.company_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">
                      <span class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.company_cnpj}" data-action="click->app--helpers--copy#copy">${element.company_cnpj_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.started_at_pick_date}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer">
                      <span class="" data-${this.controllerName}-target="statusSpan-${element.id}" data-action="click->${this.controllerName}#editStatus">
                        ${element.status_pretty}
                      </span>
                      <span class="d-flex align-items-center w-100 d-none" data-${this.controllerName}-target="statusSelect-${element.id}">
                        <div class="form-group ml-3 mb-0 w-100">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown-${element.id}">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn-${element.id}">
                                <span class="mr-auto w-100 selected-item f-065" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput-${element.id}"></span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="going" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="onboard" data-app--helpers--selector-target="select" class="li-selector dark f-065">Onboard</li>
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="renewal" data-app--helpers--selector-target="select" class="li-selector dark f-065">Renovação</li>
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="blocked" data-app--helpers--selector-target="select" class="li-selector dark f-065">Bloqueado</li>
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="cancel" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelado</li>
                                  <li data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-status="closed" data-app--helpers--selector-target="select" class="li-selector dark f-065">Baixado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ml-3 mb-0">
                          <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span aria-hidden="true">&times;</span>
                            <span class="mc-tooltiptext">Cancelar</span>
                          </button>
                        </div>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.tax_regime_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.legal_nature_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.company_city}/${element.company_state}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${edit}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doIndexListHtml()
  }

  cancelAllInputs() {
    this.application.clinics.forEach(element => {
      if (this.nameTarget(`statusInput-${element.id}`) && this.nameTarget(`statusInput-${element.id}`).innerText) {
        this.nameTarget(`statusSpan-${element.id}`).classList.remove("d-none")
        this.nameTarget(`statusSelect-${element.id}`).classList.add("d-none")
        this.nameTarget(`statusSpan-${element.id}`).innerText = this.nameTarget(`statusInput-${element.id}`).innerText
      }
    })
  }

  cancelInput(ev) {
    var clinicId = ev.target.closest(".itemRow").dataset.id

    this.nameTarget(`statusSpan-${clinicId}`).classList.remove("d-none")
    this.nameTarget(`statusSelect-${clinicId}`).classList.add("d-none")
    this.nameTarget(`statusSpan-${clinicId}`).innerText = this.nameTarget(`statusInput-${clinicId}`).innerText
  }

  editStatus(ev) {
    this.cancelAllInputs()
    var clinicId = ev.target.closest(".itemRow").dataset.id
    this.application.clinics.forEach(element => {
      if (element.id == clinicId) {
        this.current_clinic = element
      }
    })

    this.nameTarget(`statusSpan-${clinicId}`).classList.add("d-none")
    this.nameTarget(`statusSelect-${clinicId}`).classList.remove("d-none")
    this.nameTarget(`statusInput-${clinicId}`).innerText = this.current_clinic.status_pretty
    this.nameTarget(`statusInput-${clinicId}`).dataset.status = this.current_clinic.status
    this.nameTarget(`statusDropdown-${clinicId}`).value = this.current_clinic.status
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

  }

  selectStatus(ev) {
    this.cancelAllInputs()
    this.send_data = { current_user: {}, product: {} }
    this.application.clinics.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        this.current_clinic = element
      }
    })

    this.actionMode = `edit`

    this.send_data.product.id = this.current_clinic.id
    this.send_data.product.name = `medclinic`
    this.send_data.product.status = ev.currentTarget.dataset.status

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.nameTarget(`statusSpan-${this.current_clinic.id}`).innerText = ev.currentTarget.innerText

    this.requestSave()
  }

  requestSave() {

    if (this.actionMode == `edit`) {
      var url = "/operations/products/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/operations/products/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var clinic = response.data.cln
          if (controller.actionMode == `edit`) {
            controller.application.clinics.forEach((element, i) => {
              if (element.id == clinic.id) {
                controller.application.clinics.splice(controller.application.clinics.indexOf(element), 1, clinic)
              }
            })
          } else if (controller.actionMode == `destroy`) {
            controller.application.clinics.forEach((element, i) => {
              if (element.id == clinic.id) {
                controller.application.clinics.splice(controller.application.clinics.indexOf(element), 1)
              }
            })
          }
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyProduct(ev) {
    this.send_data = { current_user: {}, product: {} }
    this.application.clinics.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        this.current_clinic = element
      }
    })

    this.actionMode = `destroy`

    this.send_data.product.id = this.current_clinic.id
    this.send_data.product.name = `medclinic`
    this.send_data.product.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja apagar a PJ Médica?`)
    if (r) {
      this.requestSave()
    }
  }

  goToShowPage(ev) {
    var url = ev.target.closest(".itemRow").dataset.productPath
    window.open(url, `_blank`)
  }

  getProductsCalculations() {
    const data = { product: { name: "medclinic", kind: "" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_calculations"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    // fetch(url, init)
    //   .then(response => response.json())
    //   .then(response => {
    //     this.application.report = response.data.cln
    //     controller.doSideReportHtml()
    //   })
    //   .catch(error => {
    //     controller.getControllerByIdentifier("app--helpers--console").console(error)
    //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
    //   })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--clinics--regularizations--index--list"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
