
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "expenseCount", "expenseCountTooltip",
    "receivableCount", "receivableCountTooltip", "taxCount", "taxCountTooltip",
    "totalReceivables", "totalTaxes", "totalExpenses", "totalTickets"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank')
  }

  goToDashboardQualified() {
    this.getControllerByIdentifier("commercial--sales--leads--sources--dashboard").doPageGrid()
  }

  goToDashboardGenerate() {
    this.getControllerByIdentifier("commercial--sales--leads--entities--dashboard").doPageGrid()
  }

  goToPermissions() {
    if (window.location.pathname == `/a/leads-qualificados`) {
      var element = this.getControllerByIdentifier("commercial--sales--leads--sources--dashboard").mainTarget
    } else if (window.location.pathname == `/a/leads-gerados`) {
      var element = this.getControllerByIdentifier("commercial--sales--leads--entities--dashboard").mainTarget
    }
    

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "commercial_leads"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)

    this.getControllerByIdentifier(`commercial--sales--leads--entities--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  doSubmenuHtml() {

    var opportunitiesBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/oportunidades" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Oportunidades</button>`
    var radarsBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/radar-oportunidades" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Radar</button>`

    if (this.application.permissions.sales_qualified_lead_permissions.can_assign) {
      this.application.domain_permission = this.application.permissions[`sales_qualified_lead_permissions`]
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    if (window.location.pathname == `/a/leads-qualificados`) {
      var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboardQualified ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Leads</button>`
      if (this.application.permissions.sales_opportunities_leads.can_index) {
        var leadsBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/leads-gerados" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Leads Gerados</button>`
      } else {
        var leadsBtn = ``
      }
    } else if (window.location.pathname == `/a/leads-gerados`) {
      var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboardGenerate ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Leads</button>`
      if (this.application.permissions.sales_opportunities_leads.can_index) {
        var leadsBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/leads-qualificados" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Leads Qualificados</button>`
      } else {
        var leadsBtn = ``
      }
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}              
                  ${opportunitiesBtn}         
                  ${radarsBtn}
                  ${leadsBtn}
                  ${permissionBtn}
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  toggleBtn(ev) {
    if (this.checkSubmenu(ev.currentTarget.innerText)) {
      this.submenuBtnTargets.forEach(element => {
        element.classList.add(`btn-flat-dark`)
        element.classList.remove(`btn-primary`)
      })

      ev.currentTarget.classList.remove(`btn-flat-dark`)
      ev.currentTarget.classList.add(`btn-primary`)
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  checkSubmenu(text) {
    var menu = [`LEADS GERADOS`, `LEADS QUALIFICADOS`, `RADAR`, `OPORTUNIDADES`]

    if (menu.includes(text)) {
      return false
    } else {
      return true
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
