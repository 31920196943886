import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["visitInput", "contentRow", "search", "searchCard", "visitName", "email", "crmState", "utmSource", "list",
                    "utmMedium", "utmCampaign", "utmContent", "device", "crm", "landingPage", "lastInteractionAt", "phone",
                    "interactionCard", "interactionBodyTable", "duration", "os", "browser", "referrer", "totalInteractions", "footerTable"]


  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--visits--pageviews--interactions`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `last_interaction_to_time` }
    this.send_data = { current_user: {}, visit: {} }
    this.getVisitInteractions()
  }

  doInteractionGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8" data-${this.controllerName}-target="list"></div>
                  <div class="col-4" data-${this.controllerName}-target="search"></div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--visits--pageviews--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doVisitSearchHtml()
      controller.doVisitInteractionHtml()
    })
  }

  getVisitInteractions() {
    this.send_data.visit.active = true
    this.send_data.current_user.current_user_id = this.application.current_user.id

    const url = "/marketing/leads/visits/entities/list_interactions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.interactions = response.data.cln
      })
  }

  searchVisit(ev) {
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      if (this.visitInputTarget.value) {
        this.send_data.visit.id = this.visitInputTarget.value
        this.send_data.current_user.current_user_id = this.application.current_user.id

        const url = "/marketing/leads/visits/entities/read"
        const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
        var controller = this
        fetch(url, init)
          .then(response => response.json())
          .then(response => {
            var visit = response.data.cln[0]
            if (visit) {
              controller.visitNameTarget.innerText = visit.name
              controller.emailTarget.innerText = visit.email
              controller.utmSourceTarget.innerText = visit.utm_source
              controller.utmMediumTarget.innerText = visit.utm_medium
              controller.utmCampaignTarget.innerText = visit.utm_campaign
              controller.utmContentTarget.innerText = visit.utm_content
              controller.landingPageTarget.innerText = visit.landing_page
              controller.deviceTarget.innerText = visit.device_type
              controller.lastInteractionAtTarget.innerText = visit.last_interaction_at_pretty
              controller.durationTarget.innerText = controller.getControllerByIdentifier(`app--helpers--time`).doDurationFormat(visit.duration)
              controller.osTarget.innerText = visit.os
              controller.browserTarget.innerText = visit.browser
              controller.referrerTarget.innerText = visit.referrer
              controller.phoneTarget.innerText = visit.uniq_number
              controller.crmTarget.innerText = visit.uniq_crm
            } else {
              controller.visitNameTarget.innerText = `Sessão Inexistente`
              controller.emailTarget.innerText = ``
              controller.utmSourceTarget.innerText = ``
              controller.utmMediumTarget.innerText = ``
              controller.utmCampaignTarget.innerText = ``
              controller.utmContentTarget.innerText = ``
              controller.landingPageTarget.innerText = ``
              controller.deviceTarget.innerText = ``
              controller.lastInteractionAtTarget.innerText = ``
              controller.durationTarget.innerText = ``
              controller.osTarget.innerText = ``
              controller.browserTarget.innerText = ``
              controller.referrerTarget.innerText = ``
              controller.phoneTarget.innerText = ``
              controller.crmTarget.innerText = ``
            }
          })
      }
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doVisitInteractionHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="interactionCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title card-title-table-list">Todas as <span data-${this.controllerName}-target="totalInteractions"></span> Interações</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle pointer">
                                  ID
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="id" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="id" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Última Interação
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="last_interaction_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="last_interaction_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Campanha
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="utm_campaign" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="utm_campaign" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-30 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">CRM</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">Duração</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="interactionBodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.interactionCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.interactionBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(6, 15))
      controller.doDataTable()
      controller.totalInteractionsTarget.innerText = controller.application.interactions.length
    })
  }

  doDataTable() {
    if (this.sort.mode == `asc`) {
      var interactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.interactions, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var interactions = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.interactions, this.sort.field)
    }

    if (interactions.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(interactions.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (interactions[x] !== undefined) {
            internPage[internPage.length] = interactions[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: interactions.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.interactionBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="p-5 align-middle text-center" style="font-size:200%;">
                      <span class="fa-stack fa-2x">
                        <i class="fas fa-list fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <h5>Não existem Interações</h5>
                    </td>`

      this.interactionBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.interactionBodyTableTarget.insertAdjacentHTML("beforeend", this.interactionTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  interactionTablePartial(element) {

    if (element.name == undefined || element.name == null) {
      element.name = `Não Fornecido`
    }

    var rowHtml = `<tr class="itemRow" data-id="${element.id}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.id}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.last_interaction_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.utm_campaign}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.uniq_crm}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier(`app--helpers--time`).doDurationFormat(element.duration)}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }


  doVisitSearchHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="searchCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title card-title-table-list">Busque a Sessão</h6>
                    <input class="form-control f-070 w-50 ml-auto" placeholder="Buscar ..." type="text" data-${this.controllerName}-target="visitInput" data-action="keyup->${this.controllerName}#searchVisit">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button type="button" class="btn btn-sm btn-table p-0" data-action="click->${this.controllerName}#searchVisit">
                        <span class="material-icons md-sm md-dark">search</span>
                      </button>
                      <span class="mc-tooltiptext">Buscar</span>
                    </div>
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover table-search" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <th class="table-30">Nome</th>
                                <td class="table-70"><span data-${this.controllerName}-target="visitName"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">E-mail</th>
                                <td class="table-70"><span data-${this.controllerName}-target="email"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Telefone</th>
                                <td class="table-70"><span data-${this.controllerName}-target="phone"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">CRM</th>
                                <td class="table-70"><span data-${this.controllerName}-target="crm"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Fonte</th>
                                <td class="table-70"><span data-${this.controllerName}-target="utmSource"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Mídia</th>
                                <td class="table-70"><span data-${this.controllerName}-target="utmMedium"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Campanha</th>
                                <td class="table-70"><span data-${this.controllerName}-target="utmCampaign"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Conteúdo</th>
                                <td class="table-70"><span data-${this.controllerName}-target="utmContent"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Landing Page</th>
                                <td class="table-70"><span data-${this.controllerName}-target="landingPage"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Referência</th>
                                <td class="table-70"><span data-${this.controllerName}-target="referrer"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Última Interação</th>
                                <td class="table-70 align-middle"><span data-${this.controllerName}-target="lastInteractionAt"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Duração</th>
                                <td class="table-70"><span data-${this.controllerName}-target="duration"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Dispositivo</th>
                                <td class="table-70"><span data-${this.controllerName}-target="device"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">OS</th>
                                <td class="table-70"><span data-${this.controllerName}-target="os"></span></td>
                              </tr>
                              <tr>
                                <th class="table-30">Navegador</th>
                                <td class="table-70"><span data-${this.controllerName}-target="browser"></span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.searchTarget.innerHTML = html)
    }).then(() => {
      controller.searchCardTarget.style.height = ($(window).height() * 0.65) + "px"
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
