import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxShow", "addHelperBtn", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--helpers--index`
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    this.doIndexListHtml()
  }

  showHelper(ev) {
    this.getControllerByIdentifier("operations--products--bookings--helpers--show").doViewHtml()

    this.checked_id = ev.target.closest(".itemRow").dataset.id

    var checkBoxElement = ``
    if (ev.target.tagName == "INPUT") {
      checkBoxElement = ev.target
    } else if (ev.target.tagName == "TD") {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          checkBoxElement = element
        }
      })

      if (checkBoxElement.checked) {
        checkBoxElement.checked = false
      } else {
        checkBoxElement.checked = true
      }
    }

    if (checkBoxElement.checked) {
      this.checkboxShowTargets.forEach(element => {
        if (checkBoxElement != element) {
          element.checked = false
        }
      })
      this.application.helpers.forEach(element => {
        if (element.id == this.checked_id) {
          this.application.current_helper = element
          this.application.account_contact = {}
          this.application.account_contact.account_id = element.account_id
          this.application.account_contact.record_type = `account_entities`
        }
      })
      this.getControllerByIdentifier("operations--products--bookings--helpers--show").doShowHtml()
    } else {
      this.getControllerByIdentifier("operations--products--bookings--helpers--show").doViewHtml()
    }
  }

  addHelper() {
    this.getControllerByIdentifier("operations--products--bookings--helpers--save").doFormHtml()
    this.cleanCheckBox()
  }

  editHelper(ev) {
    this.getControllerByIdentifier("operations--products--bookings--helpers--save").cancelSave()
    var mode = "edit"
    var helper = ``
    this.application.helpers.forEach(element => {
      if (element.id == ev.target.closest(".itemRow").dataset.id) {
        helper = element
      }
    });

    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--bookings--helpers--save").saveFormHtml(mode, helper)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    // `<div class="dropdown">
    //   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
    //   <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
    // </div>`
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Assistentes</h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addHelperBtn" data-action="click->${this.controllerName}#addHelper" type="button">
                        <span class="material-icons">add</span>
                      </button>
                      <span class="mc-tooltiptext">Novo Assistente</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-35 align-middle">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">CPF</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">
                                  Nascimento
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_birthdate_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_birthdate_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Função</th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Vínculo</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--helpers--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 4))
      controller.doDataTable()
      if (!controller.application.permissions.medbooking_helpers.can_create) {
        controller.addHelperBtnTarget.remove()
      }
    })
  }

  doDataTable() {
    var current_helpers = this.application.helpers

    if (this.sort.mode == `asc`) {
      var helpers = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_helpers, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var helpers = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_helpers, this.sort.field)
    }

    if (helpers.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(helpers.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (helpers[x] !== undefined) {
            internPage[internPage.length] = helpers[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: helpers.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Assistentes Cadastrados</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.helperTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  helperTablePartial(element, length) {
    if (this.application.permissions.medbooking_helpers.can_select) {
      var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showHelper">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`
    } else {
      var check = ''
    }


    if (this.application.permissions.medbooking_helpers.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions.medbooking_helpers.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editHelper" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="table-3 align-middle">${check}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showHelper">${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${element.account_name}">${copyPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.account_cpf_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${element.account_cpf}">${copyPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.account_birthdate_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.role_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.employment_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--bookings--helpers--index"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
