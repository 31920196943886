import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["info", "main", "submenu", "onboardJourney", "onboardStartedAt", "onboardOpenDays", "onboardProductName", "onboardProductPlan",
                    "onboardProductKind", "onboardKind", "onboardStatus", "destroyPartyBtn", "onboardJourneyName", "onboardJourneyEdit",
                    "onboardJourneyInput", "journeyFilter", "cancelBtn", "addPartyBtn", "listParties", "listJourneys", "ticketsRow",
                    "ticketsCard", "agentCard", "notesCard", "addTicketBtn", "agentsRow", "notesRow", "addNoteBtn", "agentsRowElement",
                    "inProcess", "resolved", "forwardOnboardBtn", "addAgentBtn", "partiesRow", "partiesCard"]

  connect() {
    this.controllerName = `operations--onboards--show--entity`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.application.permissions = {}
    this.application.onboard = {}
    this.application.onboard_token = location.pathname.split("/").pop()
    this.application.tickets = []
    this.application.notes = []
    this.application.agents = []
    this.doGridHtml()
  }

  toggleOnboard(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "ticket") {
      this.ticketsCardTarget.classList.add("bg-primary")
      this.agentCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.partiesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.agentsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.partiesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "agent") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.agentCardTarget.classList.add("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.partiesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.agentsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.partiesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "note") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.agentCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.add("bg-primary")
      this.partiesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.agentsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.partiesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    } else if (feature == "party") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.agentCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.partiesCardTarget.classList.add("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.agentsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.partiesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

    }

  }

  doGridHtml() {

    // `<div class="row" data-${this.controllerName}-target="ticketsRow">
    //   <div class="col-12 px-2">
    //     <h6 class="mb-0 d-flex align-items-center">
    //       <span>Tickets</span>
    //       <span class="f-075 pointer badge badge-secondary ml-auto" data-${this.controllerName}-target="inProcess" data-feature="in_process" data-action="click->${this.controllerName}#toggleTickets">Em Aberto</span>
    //       <span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="resolved" data-feature="resolved" data-action="click->${this.controllerName}#toggleTickets">Resolvidos</span>
    //       <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#addTicket" data-${this.controllerName}-target="addTicketBtn">
    //         <span class="material-icons md-sm md-dark">add</span>
    //         <span class="mc-tooltiptext">Adicionar Ticket</span>
    //       </span>
    //     </h6>
    //     <hr class="my-1">
    //   </div>
    // </div>
    // <div class="row" data-${this.controllerName}-target="ticketsRow" data-operations--onboards--show--tickets-target="ticketBoard" data-controller="operations--onboards--show--tickets">
    //   <span class="w-100">${this.loader}</span>
    // </div>`

  
    // `<div class="row d-none" data-${this.controllerName}-target="notesRow">
    //   <div class="col-12 px-2">
    //     <h6 class="mb-0 d-flex align-items-center">
    //       <span>Anotações</span>
    //       <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addNote" data-${this.controllerName}-target="addNoteBtn">
    //         <span class="material-icons md-sm md-dark">add</span>
    //         <span class="mc-tooltiptext">Adicionar Anotação</span>
    //       </span>
    //     </h6>
    //     <hr class="my-1">
    //     </div>
    // </div>
    // <div class="row d-none" data-${this.controllerName}-target="notesRow" data-operations--onboards--show--notes-target="list" data-controller="operations--onboards--show--notes">
    //   <span class="w-100">${this.loader}</span>
    // </div>`

    var html = `<div class="row">
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Jornada</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardJourney">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Data Início</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardStartedAt">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Dias</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardOpenDays">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Produto</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardProductName">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-0">
                    <div class="row">
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Tipo/Plano</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardProductPlan">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Serviço</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardProductKind">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Tipo</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardKind">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Status</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="onboardStatus">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-4 px-2"></div>
                  <div class="col-8 px-2">
                    <div class="row w-100">
                      <div class="col-3 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard" data-feature="ticket" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard" data-feature="note" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="partiesCard" data-feature="party" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Partes</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="agentCard" data-feature="agent" data-action="click->${this.controllerName}#toggleOnboard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Agentes</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Partes Envolvidas</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addParty" data-${this.controllerName}-target="addPartyBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Parte</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listParties">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Jornada</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listJourneys">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-8 px-2">
                    <div class="row" data-controller="operations--onboards--show--tickets" data-${this.controllerName}-target="ticketsRow"></div>
                    <div class="row d-none" data-controller="operations--onboards--show--notes" data-${this.controllerName}-target="notesRow"></div>
                    <div class="row d-none" data-controller="operations--onboards--show--parties" data-${this.controllerName}-target="partiesRow"></div>
                    
                    
                    <div class="row d-none" data-${this.controllerName}-target="agentsRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Agentes</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addAgent" data-${this.controllerName}-target="addAgentBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Agente</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                        </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="agentsRow" data-operations--onboards--show--agents-target="list" data-controller="operations--onboards--show--agents">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  setPageTitle() {
    this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = `Onboard #${this.application.onboard.token}`
    this.infoTarget.innerHTML = ``
    this.infoTarget.innerHTML += ` | Total Médicos: ${this.application.onboard.total_doctors}`
    this.infoTarget.innerHTML += ` | Fonte Primária: ${this.application.onboard.source_pretty}`
    this.infoTarget.innerHTML += ` | Token: <span class="mc-tooltip pointer">
                                              <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${this.application.onboard.token}" data-action="click->app--helpers--copy#copy">file_copy</span>
                                              <span class="mc-tooltiptext">Copiar</span>
                                            </span>`
    if (this.application.onboard.purchase_id && this.application.permissions.operation_onboard_entities.can_manage) {
      this.infoTarget.innerHTML += ` | Contratação: <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.onboard.purchase_path}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                                        <span class="material-icons md-sm md-dark">launch</span>
                                                        <span class="mc-tooltiptext">Ir para Contratação</span>
                                                      </button>`
    }
  }

  setPageHeader() {
    this.setOpenDays()
    this.setStartedAt()
    this.setStatus()
    this.setKind()
    this.setProductService()
    this.setProductName()
    this.setProductPlan()
    this.setMainJourney()
  }

  setMainJourney() {

    if (this.application.onboard.journey_status == `finished` || this.application.onboard.journey_status == `canceled`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 text-center">
                      ${this.application.onboard.journey_status_pretty}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#lostOnboard" data-stage="canceled" data-stage-pretty="Cancelada">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Onboard Cancelado?</span>
                      </span>
                    </div>
                    <div class="col-8 px-0 mc-tooltip text-center">
                      ${this.application.onboard.journey_status_pretty}
                    </div>
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#forwardOnboard" data-stage="" data-stage-pretty="" data-${this.controllerName}-target="forwardOnboardBtn">
                        <span class="material-icons md-sm md-dark">arrow_forward_ios</span>
                        <span class="mc-tooltiptext">Avançar Onboard</span>
                      </span>
                    </div>
                  </div>`

    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.onboardJourneyTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.onboard.journey_status != `finished` || controller.application.onboard.journey_status != `canceled`) {
        controller.listJourney()
      }
    })
  }

  forwardOnboard(ev) {
    if (this.application.permissions.operation_onboard_entities.can_manage) {
      if (this.checkOnboardJourneys(ev.currentTarget.dataset.stage)) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `O Onboard já passou por esse Estágio de Jornada! :/`, 5000)
      } else {
        var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para o Onboard?`)

        if (r) {
          this.send_data = { current_user: {}, journey: {}, onboard: {}, note: {} }
          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `operation_onboard_journeys`

          this.send_data.journey.onboard_id = this.application.onboard.id
          this.send_data.journey.stage = ev.currentTarget.dataset.stage
          this.send_data.journey.date = new Date()
          this.send_data.onboard.journey_status = ev.currentTarget.dataset.stage
          this.send_data.onboard.onboards = []

          this.changeStage()
        } else {
          this.setMainJourney()
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Você não pode atualizar o estágio do Onboard. :/`, 5000)
    }
  }

  lostOnboard(ev) {
    if (this.application.permissions.operation_onboard_entities.can_manage) {
      var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para o Onboard?`)

      if (r) {
        this.send_data = { current_user: {}, journey: {}, onboard: {} }
        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = `operation_onboard_journeys`

        this.send_data.journey.onboard_id = this.application.onboard.id
        this.send_data.journey.stage = ev.currentTarget.dataset.stage
        this.send_data.journey.date = new Date()
        this.send_data.onboard.journey_status = ev.currentTarget.dataset.stage
        this.send_data.onboard.onboards = []

        this.changeStage()
      } else {
        this.setMainJourney()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Você não pode atualizar o estágio do Onboard. :/`, 5000)
    }
  }

  changeStage() {
    if (this.send_data.onboard.journey_status == `finished` || this.send_data.onboard.journey_status == `canceled`) {
      if (this.application.in_process_tickets.length == 0) {
        this.requestSaveJourney()
      } else {
        this.setMainJourney()
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos Finalizar com Tickets em Aberto. :/`, 5000)
      }
    } else {
      this.requestSaveJourney()
    }
  }

  listJourney() {    
    if (this.application.onboard.product_name == `medclinic`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medclinic_opening_alignment_booking`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_opening_filling_forms`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Formulários da Abertura`
        } else if (this.application.onboard.journey_status == `medclinic_opening_filling_forms`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_opening_articles_of_association_preparation`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Preparação Contrato Social`
        } else if (this.application.onboard.journey_status == `medclinic_opening_articles_of_association_preparation`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_opening_cnpj_approval`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Deferimento do CNPJ`
        } else if (this.application.onboard.journey_status == `medclinic_opening_cnpj_approval`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `pos_opening`) {

        if (this.application.onboard.journey_status == `medclinic_pos_opening_banking_and_certificate`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_pos_opening_regularization`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Regularização`
        } else if (this.application.onboard.journey_status == `medclinic_pos_opening_regularization`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_pos_opening_alignment_taxes`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Alinhamento Tributário`
        } else if (this.application.onboard.journey_status == `medclinic_pos_opening_alignment_taxes`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_pos_opening_sources_registration`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Cadastro nas Fontes`
        } else if (this.application.onboard.journey_status == `medclinic_pos_opening_sources_registration`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_pos_opening_setting_platform_product`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Parametrização Contábil e da Plataforma`
        } else if (this.application.onboard.journey_status == `medclinic_pos_opening_setting_platform_product`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_pos_opening_first_invoice_emitted`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Emissão da 1ª Nota Fiscal`
        } else if (this.application.onboard.journey_status == `medclinic_pos_opening_first_invoice_emitted`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `migration`) {

        if (this.application.onboard.journey_status == `medclinic_migration_alignment_booking`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_migration_alignment_service_flow`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Alinhamento Fluxo de Serviço`
        } else if (this.application.onboard.journey_status == `medclinic_migration_alignment_service_flow`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_migration_regularization`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Regularização`
        } else if (this.application.onboard.journey_status == `medclinic_migration_regularization`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_migration_setting_platform_product`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Parametrização Contábil e da Plataforma`
        } else if (this.application.onboard.journey_status == `medclinic_migration_setting_platform_product`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_migration_first_calculation_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega da 1º Apuração`
        } else if (this.application.onboard.journey_status == `medclinic_migration_first_calculation_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `amendment`) {
        
        if (this.application.onboard.journey_status == `medclinic_amendment_alignment_booking`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_amendment_filling_forms`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Formulários da Alteração`
        } else if (this.application.onboard.journey_status == `medclinic_amendment_filling_forms`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_amendment_amendment_preparation`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Preparação da Alteração`
        } else if (this.application.onboard.journey_status == `medclinic_amendment_amendment_preparation`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medclinic_amendment_amendment_approval`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Deferimento da Alteração`
        } else if (this.application.onboard.journey_status == `medclinic_amendment_amendment_approval`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }
      
    } else if (this.application.onboard.product_name == `medfiling`) {

      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medfiling_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria IRPF`
        } else if (this.application.onboard.journey_status == `medfiling_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medfiling_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medfiling_separate_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_separate_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria IRPF`
        } else if (this.application.onboard.journey_status == `medfiling_separate_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_separate_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medfiling_separate_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `rectification`) {

        if (this.application.onboard.journey_status == `medfiling_rectification_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_rectification_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria Retificação IRPF`
        } else if (this.application.onboard.journey_status == `medfiling_rectification_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medfiling_rectification_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medfiling_rectification_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }
      }

    } else if (this.application.onboard.product_name == `medbooking`) {

      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medbooking_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbooking_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria Livro-Caixa`
        } else if (this.application.onboard.journey_status == `medbooking_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbooking_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medbooking_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbooking_opening_first_calculation_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega da 1º Apuração`
        } else if (this.application.onboard.journey_status == `medbooking_opening_first_calculation_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medbooking_separate_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbooking_separate_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Execução do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medbooking_separate_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbooking_separate_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medbooking_separate_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medpayroll`) {

      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medpayroll_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_opening_employee_registration`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Registro do Funcionário`
        } else if (this.application.onboard.journey_status == `medpayroll_opening_employee_registration`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_opening_first_payroll_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega da 1ª Folha`
        } else if (this.application.onboard.journey_status == `medpayroll_opening_first_payroll_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `migration`) {

        if (this.application.onboard.journey_status == `medpayroll_migration_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_migration_payroll_migration`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Migração da Folha`
        } else if (this.application.onboard.journey_status == `medpayroll_migration_payroll_migration`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_migration_first_payroll_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega da 1ª Folha`
        } else if (this.application.onboard.journey_status == `medpayroll_migration_first_payroll_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medpayroll_separate_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_separate_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Execução do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medpayroll_separate_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medpayroll_separate_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medpayroll_separate_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medreceivement`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medreceivement_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medreceivement_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria de GR`
        } else if (this.application.onboard.journey_status == `medreceivement_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medreceivement_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medreceivement_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medreceivement_opening_first_calculation_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do 1º Relatório Recebimento`
        } else if (this.application.onboard.journey_status == `medreceivement_opening_first_calculation_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {
      }
    } else if (this.application.onboard.product_name == `medbiling`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medbiling_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbiling_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria de Faturamento`
        } else if (this.application.onboard.journey_status == `medbiling_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbiling_opening_setting_parameters`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Parametrização`
        } else if (this.application.onboard.journey_status == `medbiling_opening_setting_parameters`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbiling_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medbiling_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medbiling_opening_first_calculation_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do 1º Relatório Repasse`
        } else if (this.application.onboard.journey_status == `medbiling_opening_first_calculation_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }


      } else if (this.application.onboard.product_service == `migration`) {
      }

    } else if (this.application.onboard.product_name == `medconsulting`) {
      if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medconsulting_separate_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medconsulting_separate_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Execução do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medconsulting_separate_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medconsulting_separate_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medconsulting_separate_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medreturn`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medreturn_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medreturn_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria Planner`
        } else if (this.application.onboard.journey_status == `medreturn_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medreturn_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medreturn_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medoutsourcing`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medoutsourcing_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoutsourcing_opening_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Alinhamento do Serviço`
        } else if (this.application.onboard.journey_status == `medoutsourcing_opening_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoutsourcing_opening_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço`
        } else if (this.application.onboard.journey_status == `medoutsourcing_opening_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medloan`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medloan_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medloan_opening_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Alinhamento do Serviço`
        } else if (this.application.onboard.journey_status == `medloan_opening_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medloan_opening_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Pagamento 1º Boleto`
        } else if (this.application.onboard.journey_status == `medloan_opening_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medoncall`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medoncall_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoncall_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria de Gestão de Plantões`
        } else if (this.application.onboard.journey_status == `medoncall_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoncall_opening_setting_platform_account`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Liberação da Plataforma`
        } else if (this.application.onboard.journey_status == `medoncall_opening_setting_platform_account`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoncall_opening_first_calculation_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do 1º Relatório de Plantões`
        } else if (this.application.onboard.journey_status == `medoncall_opening_first_calculation_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medoncall_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoncall_separate_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Execução do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medoncall_separate_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medoncall_separate_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medoncall_separate_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    } else if (this.application.onboard.product_name == `medcontent`) {
      if (this.application.onboard.product_service == `opening`) {

        if (this.application.onboard.journey_status == `medcontent_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medcontent_opening_consultancy`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Consultoria de Mídias Sociais`
        } else if (this.application.onboard.journey_status == `medcontent_opening_consultancy`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medcontent_opening_social_midia_accounts`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Configuração Redes Sociais`
        } else if (this.application.onboard.journey_status == `medcontent_opening_social_midia_accounts`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medcontent_opening_first_social_midia_planning_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do 1º Planejamento Mensal`
        } else if (this.application.onboard.journey_status == `medcontent_opening_first_social_midia_planning_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      } else if (this.application.onboard.product_service == `separate`) {

        if (this.application.onboard.journey_status == `medcontent_opening_initial_alignment`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medcontent_separate_service_execution`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Execução do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medcontent_separate_service_execution`) {
          this.forwardOnboardBtnTarget.dataset.stage = `medcontent_separate_service_delivery`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Entrega do Serviço Avulso`
        } else if (this.application.onboard.journey_status == `medcontent_separate_service_delivery`) {
          this.forwardOnboardBtnTarget.dataset.stage = `finished`
          this.forwardOnboardBtnTarget.dataset.stagePretty = `Finalizado`
        }

      }

    }
  }

  requestSaveJourney() {
    var url = "/operations/onboards/journeys/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln

          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} avançou o Onboard para ${journey.stage_pretty}`
          controller.getControllerByIdentifier("operations--onboards--show--notes").saveUpdateNote(message)

          controller.application.onboard.journey_status = journey.stage
          controller.application.onboard.journey_status_pretty = journey.stage_pretty
          controller.application.onboard.journeys[controller.application.onboard.journeys.length] = journey
          controller.application.onboard.journeys.forEach(element => {
            element.stage_alert = false
          })

          if (journey.stage == `finished` || journey.stage == `canceled`) {
            window.location.reload()
          } else {
            controller.setPageHeader()
            controller.setJourneys()
          }

          controller.sendJourneyEmailNotification(journey)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendJourneyEmailNotification(journey) {
    var onboard = this.application.onboard
    this.send_data = { current_user: {}, notification: {} }

    var partyName = ``
    this.application.onboard.parties.forEach(party => {
      partyName += `| ${party.party_name} `
    })

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `operation_onboard_journeys`

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.onboard_id = onboard.id
    this.send_data.notification.onboard_token = onboard.token
    this.send_data.notification.onboard_name = `${onboard.product_service_pretty} ${onboard.product_accrual_name_pretty} ${onboard.product_kind_pretty}`
    this.send_data.notification.onboard_parties = partyName.trim()
    this.send_data.notification.receiver_id = onboard.generator_id
    this.send_data.notification.receiver_name = onboard.generator_name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status do Onboard ${onboard.product_service_pretty} ${onboard.product_accrual_name_pretty} ${onboard.product_kind_pretty} para`
    this.send_data.notification.body = journey.stage_pretty

    var url = "/operations/onboards/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setProductName() {
    this.onboardProductNameTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.onboard.product_accrual_name_pretty}
                                                  </div>
                                                </div>`
  }

  setProductPlan() {
    this.onboardProductPlanTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.onboard.product_plan_pretty}
                                                  </div>
                                                </div>`
  }

  setProductService() {
    this.onboardProductKindTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.onboard.product_service_pretty} - ${this.application.onboard.product_kind_pretty}
                                                  </div>
                                                </div>`
  }

  setKind() {
    this.onboardKindTarget.innerHTML = `<div class="row d-flex align-items-center">
                                            <div class="col-12 px-0 mc-tooltip text-center">
                                              ${this.application.onboard.kind_pretty}
                                            </div>
                                          </div>`
  }

  setStatus() {
    this.onboardStatusTarget.innerHTML = `<div class="row d-flex align-items-center">
                                              <div class="col-12 px-0 mc-tooltip text-center">
                                                ${this.application.onboard.status_pretty}
                                              </div>
                                            </div>`
  }

  setStartedAt() {
    this.onboardStartedAtTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                <div class="col-12 px-0 mc-tooltip text-center">
                                                  ${this.application.onboard.started_at_pretty}
                                                </div>
                                              </div>`
  }

  setOpenDays() {
    if (this.application.onboard.open_days == 1) {
      this.onboardOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.onboard.open_days} dia
                                                  </div>
                                                </div>`
    } else {
      this.onboardOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.onboard.open_days} dias
                                                  </div>
                                                </div>`
    }
  }

  setParties() {
    var html = ``
    this.application.onboard.parties.forEach(element => {

      // `<td style="font-size:60%;" scope="col" class="p-1 align-middle pointer">
      //   <p class="mb-0 f-065 mc-tooltip">
      //     <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
      //     <span class="mc-tooltiptext">Clique para Copiar</span>
      //   </p>
      // </td>`

      if (element.party_whatsapp) {
        var whatsappBtn = `<i class="fab fa-whatsapp md-dark md-150" data-action="click->${this.controllerName}#sendWhatsAppApi"></i>`
      } else {
        var whatsappBtn = ``
      }

      if (element.party_financial) {
        var financialIcon = `<span class="mc-tooltip">
                              <span class="material-icons md-sm md-dark pointer">attach_money</span>
                              <span class="mc-tooltiptext">Financeiro</span>
                            </span>`
      } else {
        var financialIcon = ``
      }

      if (element.party_signatory) {
        var signatoryIcon = `<span class="mc-tooltip">
                              <span class="material-icons md-sm md-dark pointer">border_color</span>
                              <span class="mc-tooltiptext">Contratante</span>
                            </span>`
      } else {
        var signatoryIcon = ``
      }

      if (element.party_invoice) {
        var invoiceIcon = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-dark pointer">receipt</span>
                            <span class="mc-tooltiptext">Tomador de Serviço</span>
                          </span>`
      } else {
        var invoiceIcon = ``
      }


      if (element.kind == `setup`) {
        var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.setup * 100))}`
        var formSpan = element.kind_pretty
      } else if (element.kind == `recurring`) {
        if (element.details_fill_in) {
          if (element.plan == `monthly`) {
            var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.monthly * 100))}`
          } else {
            var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.yearly * 100))}`
          }
          var formSpan = `${element.kind_pretty} | ${element.plan_pretty}`
        } else {
          var valueSpan = ``
          var formSpan = ``
        }
      }

      var address = element.party_address
      var addressHtml = ``
      
      if (address) {
        addressHtml += `<p class="mb-0 f-065">${address.street}, ${address.number}, ${address.complement}, ${address.district}, ${address.city}/${address.state}</p>`
      } else {
        addressHtml += ``
      }

      html += `<div class="row my-2 w-100 partyCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-0 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-1">
                            <div class="card-show-dropdown">
                              <p class="mb-1">${element.party_kind_pretty}</p>
                              <p class="mb-1">${element.party_name}</p>
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">${element.party_phone_pretty} <span class="ml-3">${whatsappBtn}</span></p>
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.party_name}" data-action="click->app--helpers--copy#copy">${element.party_name}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.party_email_pretty}" data-action="click->app--helpers--copy#copy">${element.party_email_pretty}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.party_number}" data-action="click->app--helpers--copy#copy">${element.party_number_pretty}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                ${addressHtml}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listPartiesTarget.innerHTML = html)
    }).then(() => {

    })
  }

  sendWhatsAppApi(ev) {
    var partyId = ev.currentTarget.closest(".partyCard").dataset.id
    var currentParty = {}
    this.application.onboard.parties.forEach(element => {
      if (element.id == partyId) {
        currentParty = element
      }
    })
    var currentOnboard = this.application.onboard

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = currentOnboard.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `operation_onboards`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Onboard ${currentOnboard.product_service_pretty} ${currentOnboard.product_accrual_name_pretty} ${currentOnboard.product_kind_plan_pretty}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = currentOnboard.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `operation_onboards`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = currentParty.party_kind_obj.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = currentParty.party_type
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = currentParty.party_kind_obj.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = currentParty.party_phone.ddd
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = currentParty.party_phone.number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = currentParty.party_phone.uniq_number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `operation_onboard_tickets`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  setJourneys() {
    var html = ``
    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.onboard.journeys, `date_time`)
    journeys.forEach(element => {

      if (element.period == 1) {
        var period = `${element.period} dia`
      } else {
        var period = `${element.period} dias`
      }

      if (element.stage_alert) {
        var alert = `<div class="col-2 px-0 mc-tooltip">
                        <span class="material-icons md-sm md-danger">warning</span>
                        <span class="mc-tooltiptext">Atenção!</span>
                      </div>`
      } else {
        var alert = ``
      }

      html += `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-1 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-10 px-1">
                            <div class="card-show-dropdown">
                              ${element.stage_pretty}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">Data: ${element.date_pretty}</p>
                                <p class="mb-0 f-065">Tempo: ${period}</p>
                              </div>
                            </div>
                          </div>
                          ${alert}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.listJourneysTarget.innerHTML = html
  }

  addTicket() {
    if (this.userCanUpdate()) {
      this.getControllerByIdentifier("operations--onboards--show--tickets").createTicket()
    }
  }

  toggleTickets(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "in_process") {
      this.inProcessTarget.classList.add("badge-secondary")
      this.inProcessTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.in_process_tickets, `due_at`)
      this.getControllerByIdentifier(`operations--onboards--show--tickets`).listData(tickets)
    } else if (feature == "resolved") {
      this.inProcessTarget.classList.remove("badge-secondary")
      this.inProcessTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.resolved_tickets, `due_at`)
      this.getControllerByIdentifier(`operations--onboards--show--tickets`).listData(tickets)
    }
  }

  addNote() {
    this.getControllerByIdentifier("operations--onboards--show--notes").createNote()
  }

  addAgent() {
    this.getControllerByIdentifier("operations--onboards--show--agents").createAgentHtml()
  }

  showInlineEditor(ev) {
    if (this.application.permissions.operation_onboard_entities.can_update) {
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.nextElementSibling.firstElementChild.firstElementChild.focus()
    }
  }

  hideInlineEditor(ev) {
    if (this.application.permissions.operation_onboard_entities.can_update) {
      ev.currentTarget.closest(".editInput").classList.add("d-none")
      ev.currentTarget.closest(".editInput").previousElementSibling.classList.remove("d-none")
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  finishLoadPage() {
    this.setPageTitle()
    this.setPageHeader()
    this.setParties()
    this.setJourneys()

    if (this.application.permissions.operation_onboard_agents.can_create) {
      this.addAgentBtnTarget.classList.remove("d-none")
    } else {
      this.addAgentBtnTarget.remove()
    }

    if (this.application.onboard.journey_status == `finished` || this.application.onboard.journey_status == `canceled`) {
      if (this.hasAddAgentBtnTarget) {
        this.addAgentBtnTarget.remove()
      }

      this.getControllerByIdentifier("operations--onboards--show--tickets").blockedAdd = true
      this.getControllerByIdentifier("operations--onboards--show--notes").blockedAdd = true
    }

    this.getControllerByIdentifier("operations--onboards--show--parties").doPartiesFormHtml()
    this.getControllerByIdentifier("operations--onboards--show--tickets").doTicketsDashboard()
    this.getControllerByIdentifier("operations--onboards--show--notes").doNotesDashboard()
    this.getControllerByIdentifier("operations--onboards--show--agents").setAgents()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.application.onboard.id, domain_type: `operation_onboards` }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    var data = { ticket: { board_id: this.application.onboard.id, board_type: "operation_onboards" }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOnboard() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Onboard`, value: 60 })

    const data = { onboard: { token: this.application.onboard_token }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_entities` } }
    const url = "/operations/onboards/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        console.log(response.data.cln)
        controller.application.onboard = response.data.cln
        controller.application.agents = controller.application.onboard.agents
        controller.application.parties = controller.application.onboard.parties

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOnboardTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Onboard`, value: 35 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_entities` } }
    const url = "/operations/onboards/teams/list_onboard_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.onboard_team = response.data.cln
        controller.application.team_list = response.data.cln

        controller.getOnboard()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_onboards` }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.application.domain_permission = controller.application.permissions[`operation_onboard_permissions`]

        controller.getOnboardTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  goToSetttings() {
    var html = `<div class="row">
                  <div class="col-12" data-controller="operations--onboards--show--settings" data-operations--onboards--show--settings-target="main"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  doIndexSubmenuHtml() {
    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span data-action="click->${this.controllerName}#goToDashboard" class="dropdown-item py-1 pointer">Dashboard</span>
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.submenuTarget.innerHTML = submenuHtml
    tooltip()
  }

  checkOnboardJourneys(newJourney) {
    var journeys = this.mapElements(this.application.onboard.journeys, `stage`)

    const hasJourney = (currentValue) => currentValue != undefined

    if ((!journeys.every(hasJourney)) || (journeys.includes(newJourney))) {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = this.mapElements(this.application.onboard_team, `id`)
    const hasOnboardTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasOnboardTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.onboard.journey_status != "finished" && this.application.onboard.journey_status != "canceled")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

}
