import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hourlyTitle", "hourlyBoard", "callsTitle", "callsBoard", "accountsTitle", "bodyTable", "accountsBoard", "accountCard", "allCard"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `app--communications--interactions--dashboard--accounts`
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `hour` }
  }

  doAccountsCard() {
    this.accountsTitleTarget.innerHTML = `MedCapital`

    if (this.application.permissions.app_comunication_interaction_entities.can_manage) {
      var listHtml = `<div class="col-12 px-0">
                        <div class="card pointer mb-2" data-${this.controllerName}-target="accountCard" data-kind="team" data-action="click->${this.controllerName}#toggleAccount">
                          <div class="card-header py-1 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Todos</strong></h6>
                          </div>
                        </div>
                      </div>`
      this.application.accounts.forEach(element => {
        listHtml += `<div class="col-12 px-0">
                      <div class="card pointer mb-2" data-${this.controllerName}-target="accountCard account-${element.id}" data-kind="individual" data-id="${element.id}" data-action="click->${this.controllerName}#toggleAccount">
                        <div class="card-header py-1 f-065 text-center">
                          <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${element.name}</strong></h6>
                        </div>
                      </div>
                    </div>`
      })
    } else {
      var listHtml = `<div class="col-12 px-0">
                        <div class="card pointer mb-2" data-${this.controllerName}-target="accountCard">
                          <div class="card-header py-1 f-065 text-center bg-primary">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.application.accounts[0].name}</strong></h6>
                          </div>
                        </div>
                      </div>`
    }


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.accountsBoardTarget.innerHTML = listHtml)
    }).then(() => {
      controller.accountCardTargets[0].classList.add(`bg-primary`)
    })
  }

  toggleAccount(ev) {
    var toggleCard = ev.currentTarget

    this.accountCardTargets.forEach(card => {
      card.classList.remove(`bg-primary`)
    })

    toggleCard.classList.add(`bg-primary`)

    var accountId = toggleCard.dataset.id
    var accountKind = toggleCard.dataset.kind
    this.changeAccount(accountId, accountKind)
  }

  changeAccount(accountId, accountKind) {
    if (accountKind == `team`) {
      this.application.current_account_id = false
    } else if (accountKind == `individual`) {
      this.application.current_account_id = accountId
    }

    this.updateAccountsData()
  }

  updateAccountsData() {
    
    if (this.grid == `interaction_compilation`) {
      this.getControllerByIdentifier(`app--communications--interactions--dashboard--compilation`).refreshReports()
    } else if (this.grid == `interaction_table`) {
      this.getControllerByIdentifier(`app--communications--interactions--entities--days`).checkBadgeCount()
      this.getControllerByIdentifier(`app--communications--interactions--entities--index`).doDataTable()
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
