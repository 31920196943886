import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "step", "form", "view", "stepIcon", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.purchase = {}
    this.application.parties = []
    this.controllerName = `financials--products--purchases--add--dashboard`
    this.send_data = { current_user: {}, accounts: [], companies: [], purchase: {}, journey: {} }
    this.current_step = 0
    this.doMainFormHtml()
    this.setStep()
  }

  changeStep(step) {
    this.current_step = step
    this.setStep()
  }

  setStep() {
    this.stepTargets.forEach((element, i) => {
      if (element.dataset.step == this.current_step) {
        element.classList.add("active")
        this.stepIconTargets[i].classList.add("md-primary")
      } else {
        element.classList.remove("active")
        this.stepIconTargets[i].classList.remove("md-primary")
      }
    });
  }

  doMainFormHtml() {
    var html = `<div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper active" data-${this.controllerName}-target="step" data-step="0">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary" data-${this.controllerName}-target="stepIcon">category</span>
                        </div>
                        <span class="stepper-text">Produto</span>
                      </div>
                      <div class="stepper" data-${this.controllerName}-target="step" data-step="1">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">perm_identity</span>
                        </div>
                        <span class="stepper-text">Médicos & PJs Médicas</span>
                      </div>
                      <div class="stepper" data-${this.controllerName}-target="step" data-step="2">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">add_shopping_cart</span>
                        </div>
                        <span class="stepper-text">Avulso & Recorrência</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="form" data-controller="financials--products--purchases--add--product
                                                                                                 financials--products--purchases--add--parties
                                                                                                 financials--products--purchases--add--shop">
                  
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}