import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "step", "form", "view", "stepIcon", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--meds--add--save`
    this.med_data = { company: {}, med: {} }
    this.current_step = 0
  }

  doFormHtml() {
    this.doMainFormHtml()
    this.setStep()
  }

  changeStep(step) {
    this.current_step = step
    this.setStep()
  }

  setStep() {
    this.stepTargets.forEach((element, i) => {
      if (element.dataset.step == this.current_step) {
        element.classList.add("active")
        this.stepIconTargets[i].classList.add("md-primary")
      } else {
        element.classList.remove("active")
        this.stepIconTargets[i].classList.remove("md-primary")
      }
    });
  }

  doMainFormHtml() {
    var html = `<div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper mc-stepper active" data-${this.controllerName}-target="step" data-step="company">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary" data-${this.controllerName}-target="stepIcon">business</span>
                        </div>
                        <span class="stepper-text">MedCapital</span>
                      </div>
                      <div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="contacts">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">import_contacts</span>
                        </div>
                        <span class="stepper-text">Contatos</span>
                      </div>
                      <div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="regime">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">point_of_sale</span>
                        </div>
                        <span class="stepper-text">Tributário</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="form" data-controller="financials--books--dashboards--meds--add--company
                                                                                                 financials--books--dashboards--meds--add--contacts
                                                                                                 financials--books--dashboards--meds--add--regime">
                  
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--dashboards--meds--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {

      if (controller.step == `company`) {
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--company").actionMode = controller.actionMode
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--company").current_med = controller.current_med
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--company").doCompanyFormHtml()
      } else if (controller.step == `regime`) {
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").current_regime = {}
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").current_med = controller.current_med
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").actionMode = controller.actionMode
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").mode = `batch`
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").actionMode = `new`
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").current_med = controller.current_med
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").gridElement = controller.formTarget
        controller.getControllerByIdentifier("financials--books--dashboards--meds--add--regime").doRegimeFormHtml()
      }
      controller.changeStep(controller.step)

    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}