import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "descriptionInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "startedAtInput", "closedAtInput", "recurringInput", "setupInput", "saveBtn", "closedForm"]


  connect() {
    this.controllerName = `financials--products--commissions--policies--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.doViewHtml()
    this.getControllerByIdentifier("financials--products--commissions--policies--index").clearList()
  }

  savePolicy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, policy: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.policy.id = this.current_policy.id
    }

    this.send_data.policy.name = this.nameInputTarget.value
    this.send_data.policy.description = this.descriptionInputTarget.value
    this.send_data.policy.started_at = this.startedAtInputTarget.value
    this.send_data.policy.closed_at = this.closedAtInputTarget.value
    this.send_data.policy.setup_percentage = this.controllerNumber.fromPercentToNumber(this.setupInputTarget.value)
    this.send_data.policy.recurring_percentage = this.controllerNumber.fromPercentToNumber(this.recurringInputTarget.value)
    this.send_data.policy.role = this.roleInputTarget.dataset.role
    this.send_data.policy.status = this.statusInputTarget.dataset.status

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyPolicy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, policy: {} }
    this.actionMode = `delete`

    this.send_data.policy.id = this.current_policy.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar a Política de Comissão?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/products/commissions/policies/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/products/commissions/policies/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/financials/products/commissions/policies/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var policy = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.policies[controller.application.policies.length] = policy
          } else if (controller.actionMode == `edit`) {
            controller.application.policies.forEach((element, i) => {
              if (element.id == policy.id) {
                controller.application.policies.splice(controller.application.policies.indexOf(element), 1, policy)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.policies.forEach((element, i) => {
              if (element.id == policy.id) {
                controller.application.policies.splice(controller.application.policies.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()


    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyPolicy" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePolicy" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6">
                        <div class="form-group mb-0">
                          <div class="floating-label floating-label-sm">
                            <label for="startedAtForm">Início</label>
                            <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Início" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-none" data-${this.controllerName}-target="closedForm">
                        <div class="form-group mb-0">
                          <div class="floating-label floating-label-sm">
                            <label for="closedAtForm">Finalização</label>
                            <input aria-describedby="closedAtFormHelp" class="form-control form-valid-control" id="closedAtForm" data-${this.controllerName}-target="closedAtInput" placeholder="Finalização" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aberta</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Encerrada</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Área</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                                  <li data-role="sales" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                  <li data-role="customer_success" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sucesso do Cliente</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="recurringForm">% Recorrente</label>
                            <input aria-describedby="recurringFormHelp" class="form-control" id="recurringForm" data-${this.controllerName}-target="recurringInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="setupForm">% Avulso</label>
                            <input aria-describedby="setupFormHelp" class="form-control" id="setupForm" data-${this.controllerName}-target="setupInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--commissions--policies--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.startedAtInputTarget), { max: true, months: true, years: 1 })
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.closedAtInputTarget), { max: true, months: true, years: 1 })


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Política de Comissão`

        controller.recurringInputTarget.value = controller.controllerNumber.percentMask(0)
        controller.setupInputTarget.value = controller.controllerNumber.percentMask(0)

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `active`
        controller.statusInputTarget.innerText = `Ativo`
        controller.statusInputTarget.dataset.status = `active`


      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Política de Comissão`

        controller.recurringInputTarget.value = controller.controllerNumber.percentOptionMask(controller.current_policy.recurring_percentage)
        controller.setupInputTarget.value = controller.controllerNumber.percentOptionMask(controller.current_policy.setup_percentage)
        controller.startedAtInputTarget.value = controller.current_policy.started_at_pretty
        controller.closedAtInputTarget.value = controller.current_policy.closed_at_pretty
        controller.nameInputTarget.value = controller.current_policy.name
        controller.descriptionInputTarget.value = controller.current_policy.description
        
        controller.statusDropdownTarget.value = controller.current_policy.status
        controller.statusInputTarget.innerText = controller.current_policy.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_policy.status
        
        controller.roleDropdownTarget.value = controller.current_policy.role
        controller.roleInputTarget.innerText = controller.current_policy.role_pretty
        controller.roleInputTarget.dataset.role = controller.current_policy.role
        
        controller.closedFormTarget.classList.remove(`d-none`)
        controller.nameInputTarget.disabled = true
        controller.descriptionInputTarget.disabled = true
        controller.startedAtInputTarget.disabled = true
        controller.roleDropdownBtnTarget.disabled = true
        controller.recurringInputTarget.disabled = true
        controller.setupInputTarget.disabled = true
        
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  percentMask(ev) {
    this.controllerNumber.changeNumberToPercent(ev)
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewBankCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Política de Comissão Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewBankCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--products--commissions--policies--dashboard").viewTarget.innerHTML = html
  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.startedAtInputTarget.value == ``) {
        len += 1
      }

      // if (controller.closedAtInputTarget.value == ``) {
      //   len += 1
      // }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.roleInputTarget.innerText == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
