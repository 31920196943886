import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "step", "form", "view", "stepIcon", "6666", "7777", "8888", "9999"]

  connect() {
    this.product_data = { company: {}, product: {} }
    this.current_step = 0
    this.controllerName = `operations--products--clinics--regularizations--add--dashboard`
    this.doMainFormHtml()
    this.setStep()
  }

  changeStep(step) {
    this.current_step = step
    this.setStep()
  }

  setStep() {
    this.stepTargets.forEach((element, i) => {
      if (element.dataset.step == this.current_step) {
        element.classList.add("active")
        this.stepIconTargets[i].classList.add("md-primary")
      } else {
        element.classList.remove("active")
        this.stepIconTargets[i].classList.remove("md-primary")
      }
    });
  }

  doMainFormHtml() {
    var html = `<div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper mc-stepper active" data-${this.controllerName}-target="step" data-step="company">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary" data-${this.controllerName}-target="stepIcon">business</span>
                        </div>
                        <span class="stepper-text">PJ Médica</span>
                      </div>
                      <div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="contacts">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">import_contacts</span>
                        </div>
                        <span class="stepper-text">Contatos</span>
                      </div>
                      <div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="product">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">description</span>
                        </div>
                        <span class="stepper-text">Produto</span>
                      </div>
                      <div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="regime">
                        <div class="text-center w-100">
                          <span class="material-icons" data-${this.controllerName}-target="stepIcon">point_of_sale</span>
                        </div>
                        <span class="stepper-text">Tributário</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="form" data-controller="operations--products--clinics--regularizations--add--company
                                                                                                 operations--products--clinics--regularizations--add--contacts
                                                                                                 operations--products--clinics--regularizations--add--product
                                                                                                 operations--products--clinics--regularizations--add--regime
                                                                                                 operations--products--clinics--regularizations--add--partners">
                  
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {

      if (controller.step == `company`) {
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--company").doCompanyFormHtml()
      } else if (controller.step == `regime`) {
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--regime").doRegimeFormHtml()
      }
      controller.changeStep(controller.step)

    })

      // `<div class="stepper mc-stepper" data-${this.controllerName}-target="step" data-step="4">
      //   <div class="text-center w-100">
      //     <span class="material-icons" data-${this.controllerName}-target="stepIcon">group_work</span>
      //   </div>
      //   <span class="stepper-text">Societário</span>
      // </div>`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}