import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["list", "input", "characterCount", "comment", "collapse", "saveBtn"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--management--comment`
    this.requestPermission = true
  }

  characterCount() {
    var count = this.nameTarget(`input-${this.ticket_id}`).value.length
    if (count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${count} caracteres`
    }

    this.nameTarget(`characterCount-${this.ticket_id}`).textContent = message

    if (count > 140) {
      this.nameTarget(`characterCount-${this.ticket_id}`).classList.add("text-danger")
    } else {
      this.nameTarget(`characterCount-${this.ticket_id}`).classList.remove("text-danger")
    }
  }

  clickLink(e) {
    var link = e.target.innerText
    if (e.target.innerText.includes("http")) {
      window.open(link, '_blank')
    } else if (e.target.innerText.includes("www")) {
      window.open('https://' + link, '_blank')
    }
  }

  saveComment(ev) {
    this.send_data = { current_user: {}, comment: {} }
    var count = this.nameTarget(`input-${this.ticket_id}`).value.length

    if (count > 140) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && count > 0) {
        this.send_data.comment.board_id = this.application.tax_filing.id
        this.send_data.comment.board_type = `operation_products`
        this.send_data.comment.date_id = this.application.current_date.id
        this.send_data.comment.date_type = `operation_dates`
        this.send_data.comment.ticket_id = this.nameTarget(`collapse-${this.ticket_id}`).dataset.ticketId
        this.send_data.comment.account_id = this.application.current_user.account_id
        this.send_data.comment.account_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
        this.send_data.comment.body = this.nameTarget(`input-${this.ticket_id}`).value

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.application.ticket_permission
        
        if (this.requestPermission) {
          this.requestSave()
        }
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        this.nameTarget(`input-${this.ticket_id}`).value = ""
      }
    }

  }

  requestSave() {
    var url = "/users/works/activities/comments/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var comment = response.data.cln
          this.application.tickets.forEach(element => {
            if (element.id == comment.ticket_id) {
              element.comments[element.comments.length] = comment
              element.total_comments += 1
              controller.getTargetByIdentifier(`totalComments-${this.ticket_id}`).innerText = element.total_comments
              controller.getTargetByIdentifier(`totalComments-${this.ticket_id}`).classList.remove("d-none")
            }
          })

          var html = this.commentPartial(comment)
          this.nameTarget(`list-${this.ticket_id}`).insertAdjacentHTML("beforeend", html)
          this.nameTarget(`input-${this.ticket_id}`).value = ""
          this.nameTarget(`characterCount-${this.ticket_id}`).textContent = ""
          this.rollComments()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doCommentHtml() {

    if (this.application.permissions[this.application.ticket_permission].can_create) {
      var createField = `<div class="row my-1 d-flex align-items-center">
                          <div class="col-10 px-1 text-left">
                            <input type="text" class="form-control f-1" placeholder="Digite seu comentário ..." aria-label="Digite seu comentário ..." data-${this.controllerName}-target="input-${this.ticket_id}" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount">
                            <span class="f-1" data-${this.controllerName}-target="characterCount-${this.ticket_id}"></span>
                          </div>
                          <div class="col-2 px-1 mc-tooltip">
                            <span class="material-icons md-sm md-primary pointer" data-action="click->${this.controllerName}#saveComment" data-${this.controllerName}-target="saveBtn-${this.ticket_id}">send</span>
                            <span class="mc-tooltiptext">Enviar</span>
                          </div>
                        </div>`
    } else {
      var createField = ``
    }

    var html = `<div class="card m-1">
                  <div class="card-body comment-card px-1 py-0" id="commentBody-${this.ticket_id}" data-${this.controllerName}-target="list-${this.ticket_id}"></div>
                  <div class="card-footer p-0 pb-1">
                    ${createField}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.nameTarget(`collapse-${controller.ticket_id}`).innerHTML = html)
    }).then(() => {
      controller.doCommentTable()
      controller.characterCount()
    })
  }

  doCommentTable() {

    this.ticket = {}

    this.application.tickets.forEach(element => {
      if (element.id == this.ticket_id) {
        this.ticket = element
      }
    })

    var commentsHtml = ``
    this.ticket.comments.forEach(comment => {
      commentsHtml += this.commentPartial(comment)
    })

    this.nameTarget(`list-${this.ticket_id}`).innerHTML = commentsHtml
    this.rollComments()


    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.nameTarget(`list-${controller.ticket_id}`).innerHTML = commentsHtml)
    // }).then(() => {
    //   controller.rollComments()
    // }) 
  }

  commentPartial(comment) {
    if (comment.body.includes("http") || comment.body.includes("www")) {
      var commentHtml = `<span class="text-scroll text-left pointer f-1" style="overflow:auto;" data-action="click->${this.controllerName}#clickLink">${comment.body}</span>`
    } else {
      var commentHtml = `<span class="text-scroll text-left f-1" style="overflow:auto;" data-action="click->${this.controllerName}#clickLink">${comment.body}</span>`
    }
    var html = `<div class="row my-1 py-1">
                  <div class="col-9 px-1 d-flex align-items-center">${commentHtml}</div>
                  <div class="col-3 px-0 text-center my-auto mc-tooltip">
                    <span class="d-block f-1">${comment.created_at_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-bottom">${comment.account_name}</span>
                  </div>           
                </div>`

    return html
  }

  rollComments() {
    $(this.nameTarget(`list-${this.ticket_id}`)).stop().animate({ scrollTop: $(this.nameTarget(`list-${this.ticket_id}`))[0].scrollHeight }, 1000);
  }

  request(permission) {
    this.requestPermission = permission
    if (permission) {
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.add("md-primary")
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.remove("md-dark")
    } else {
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.remove("md-primary")
      this.nameTarget(`saveBtn-${this.ticket_id}`).classList.add("md-dark")
    }
  }

  getCurrentDate(date) {
    const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]

    if (date.getDate() < 10) {
      return `0${date.getDate()}/${month[date.getMonth()]}`
    } else {
      return `${date.getDate()}/${month[date.getMonth()]}`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

}