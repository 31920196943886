import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--meetings--boards--broadcast`
    this.application.board_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.boardUpdateChannel = createChannel({ channel: 'Users::Works::Meetings::BoardChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "topic") {
          var topic = data.cln
          var topicIds = []
          controller.application.topics.forEach(element => {
            topicIds[topicIds.length] = element.id
          })

          if (topicIds.includes(topic.id)) {
            if (topic.active) {
              controller.application.topics.forEach((element, i) => {
                if (element.id == topic.id) {
                  controller.application.topics.splice(controller.application.topics.indexOf(element), 1, topic)
                }
              })
            } else {
              controller.application.topics.forEach((element, i) => {
                if (element.id == topic.id) {
                  controller.application.topics.splice(controller.application.topics.indexOf(element), 1)
                }
              })
            }
          } else {
            if (topic.active) {
              controller.application.topics[controller.application.topics.length] = topic
            }
          }

          if (controller.getControllerByIdentifier(`users--works--meetings--boards--topics`)) {
            controller.getControllerByIdentifier(`users--works--meetings--boards--topics`).doDataTable()
          }
        } else if (data.kind == "weekly") {
          var weekly = data.cln
          var weeklyIds = []
          controller.application.weeklies.forEach(element => {
            weeklyIds[weeklyIds.length] = element.id
          })

          if (weeklyIds.includes(weekly.id)) {
            if (weekly.active) {
              controller.application.weeklies.forEach((element, i) => {
                if (element.id == weekly.id) {
                  controller.application.weeklies.splice(controller.application.weeklies.indexOf(element), 1, weekly)
                }
              })
            } else {
              controller.application.weeklies.forEach((element, i) => {
                if (element.id == weekly.id) {
                  controller.application.weeklies.splice(controller.application.weeklies.indexOf(element), 1)
                }
              })
            }
          } else {
            controller.application.weeklies[controller.application.weeklies.length] = weekly
          }

          if (controller.getControllerByIdentifier(`users--works--meetings--boards--dashboard`)) {
            controller.getControllerByIdentifier(`users--works--meetings--boards--dashboard`).updateCurrentWeekly(weekly.id)
          }
        } else if (data.kind == "ticket" || data.kind == "comment") {
          var ticket = data.cln
          var ticketIds = []
          controller.application.tickets.forEach(element => {
            ticketIds[ticketIds.length] = element.id
          })

          if (ticketIds.includes(ticket.id)) {
            if (ticket.active) {
              controller.application.tickets.forEach((element, i) => {
                if (element.id == ticket.id) {
                  controller.application.tickets.splice(controller.application.tickets.indexOf(element), 1, ticket)
                }
              })
            } else {
              controller.application.tickets.forEach((element, i) => {
                if (element.id == ticket.id) {
                  controller.application.tickets.splice(controller.application.tickets.indexOf(element), 1)
                }
              })
            }
          } else {
            if (ticket.active) {
              controller.application.tickets[controller.application.tickets.length] = ticket
            }
          }

          if (controller.getControllerByIdentifier(`users--works--meetings--boards--tickets`)) {
            // controller.getControllerByIdentifier(`users--works--meetings--boards--tickets`).doDataTable()
          }
        } else if (data.kind == "note") {
          var note = data.cln
          var noteIds = []
          controller.application.notes.forEach(element => {
            noteIds[noteIds.length] = element.id
          })

          if (noteIds.includes(note.id)) {
            if (note.active) {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1, note)
                }
              })
            } else {
              controller.application.notes.forEach((element, i) => {
                if (element.id == note.id) {
                  controller.application.notes.splice(controller.application.notes.indexOf(element), 1)
                }
              })
            }
          } else {
            if (note.active) {
              controller.application.notes[controller.application.notes.length] = note
            }
          }

          if (controller.getControllerByIdentifier(`users--works--meetings--boards--notes`)) {
            // controller.getControllerByIdentifier(`users--works--meetings--boards--notes`).setNotes()
          }
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  // getRoom() {
  //   var data = { board: { id: this.application.board.id }, current_user: { current_user_id: this.application.current_user.id } }
  //   const url = "/users/works/meetings/boards/get_room"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       if (response.process) {
  //         controller.application.rooms[controller.application.rooms.length] = controller.identifier
  //         controller.update_room = response.data.cln
  //         controller.broadcastUpdateChannel()
  //       } else {
  //         controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
  //       }
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier("app--helpers--console").console(error)
  //       controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
  //     })
  // }

  updateListen() {
    if (this.boardUpdateChannel) {
      this.boardUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
