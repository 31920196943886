import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    if (currentUser.account_kind == "doctor") {
      this.doctorDashboard()
    } else if (currentUser.account_kind == "helper") {
      this.helperDashboard()
    } else if (currentUser.account_kind == "admin" || currentUser.account_kind == "team") {
      this.doOperationsHtml()
    }
  }

  doctorDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12" data-controller="users--dashboards--main--product" data-users--dashboards--main--product-target="content"></div>
                  </div>`

    this.mainTarget.insertAdjacentHTML("beforeend", html)
  }

  helperDashboard() {
    const html = `<div class="row my-2">
                    <div class="col-12" data-controller="users--dashboards--main--helper" data-users--dashboards--main--helper-target="content"></div>
                  </div>`

    this.mainTarget.insertAdjacentHTML("beforeend", html)
  }

  doOperationsHtml() {
    var html = `<div class="row mb-3 d-flex align-items-center">
                  <div class="col-sm-8">
                    <div class="media">
                      <i class="material-icons md-lg md-primary mr-2">category</i>
                      <div class="media-body">
                        <h5 class="mt-2" style="margin-bottom:0px;"><strong>Dashboard Operações</strong></h5>
                        <span class="text-black-secondary">Operações MedCapital</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 ml-auto"></div>
                  <div class="col-sm-1" id="operationsSubMenu"></div>
                </div>

                <div class="row my-3">
                  <div class="col-sm-4 text-center">
                    <div class="card border-left-primary">
                      <div class="card-header d-flex align-items-center">
                        <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;"></h6>
                        PF Médica
                        </h6>
                      </div>
                      <div class="card-body p-0 text-center">
                        <div class="row">
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center" data-url="/a/declaracao-de-ajuste" data-action="click->pages--operations#goToProduct" data-toggle="tooltip" data-placement="top" title data-original-title="Declarações IRPF"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Declarações IRPF</figcaption>
                            </figure>
                          </div>
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center" data-url="/a/planner-anual" data-action="click->pages--operations#goToProduct" data-toggle="tooltip" data-placement="top" title data-original-title="Planner Financeiro"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Planner Financeiro</figcaption>
                            </figure>
                          </div>
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center" data-url="/a/livro-caixa" data-action="click->pages--operations#goToProduct" data-toggle="tooltip" data-placement="top" title data-original-title="Livro-Caixa Consultório"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Livro-Caixa Consultório</figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 text-center">
                    <div class="card border-left-primary">
                      <div class="card-header d-flex align-items-center">
                        <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;"></h6>
                        PJ Médica
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 text-center">
                    <div class="card border-left-primary">
                      <div class="card-header d-flex align-items-center">
                        <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;"></h6>
                        Faturamento
                        </h6>
                      </div>
                      <div class="card-body p-0 text-center">
                        <div class="row">
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center disabled" data-toggle="tooltip" data-placement="top" title data-original-title="Credenciamento"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Credenciamento</figcaption>
                            </figure>
                          </div>
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center disabled" data-toggle="tooltip" data-placement="top" title data-original-title="Faturamento"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Faturamento</figcaption>
                            </figure>
                          </div>
                          <div class="col-sm-6 my-3 px-2">
                            <figure class="w-100 figure btn btn-flat text-center" data-url="/a/recebimentos" data-action="click->pages--operations#goToProduct" data-toggle="tooltip" data-placement="top" title data-original-title="Recebimentos"><i class="fab fa-wpforms md-primary"></i>
                              <figcaption class="figure-caption s-title-0p5rem" style="white-space:break-spaces;">Recebimentos</figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  goToProduct(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, '_self')
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
