import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "carouselIndicator", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--testimonials`
    this.pageNumber = 0
    this.numberPages = []
  }

  doHtml() {
    var html = `<section class="ws-section-testimonials d-flex justify-content-center align-items-center">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="ws-title-section mb-5">${this.application.site.testimonials_title}</h1>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-center align-items-center">
                      <div class="row w-100" data-${this.controllerName}-target="cards"></div>
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setTestimonials()
    })
  }

  setTestimonials() {
    var current_testimonials = []

    this.application.site.testimonials.forEach(testimonial => {
      if (testimonial.status) {
        current_testimonials[current_testimonials.length] = testimonial
      }
    })
    current_testimonials = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_testimonials, `order`)

    if (this.application.size == `large`) {
      this.itemsOnPage = 2
    } else if (this.application.size == `medium`) {
      this.itemsOnPage = 1
    } else if (this.application.size == `small`) {
      this.itemsOnPage = 1
    }

    var itemsOnPage = this.itemsOnPage
    var pageNumber = this.pageNumber
    var numberPages = []
    var pages = []
    var itemLoop = Math.ceil(current_testimonials.length / itemsOnPage)

    var x = 0
    var i = 1
    for (i; i < itemLoop + 1; i++) {
      var internPage = []
      for (x; x < itemsOnPage * i; x++) {
        if (current_testimonials[x] !== undefined) {
          internPage[internPage.length] = current_testimonials[x]
        }
      }
      pages[pages.length] = internPage
      numberPages[numberPages.length] = i - 1
    }

    this.numberPages = numberPages
    if (pages.length > 0) {
      this.doCarouselData(pages[pageNumber])
    }
  }

  doCarouselData(data) {
    var html = ``
    
    data.forEach(testimonial => {
      var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(testimonial.link_cta)
      var colClass = ``
      var cardClass = ``

      if (this.application.size == `large`) {
        colClass += `col-6 d-flex justify-content-center text-center`
        cardClass += `ws-card-depo ws-card-depo-small`
      } else if (this.application.size == `medium`) {
        colClass += `col-8 offset-2 d-flex justify-content-center text-center`
        cardClass += `ws-card-depo`
      } else if (this.application.size == `small`) {
        colClass += `col-8 offset-2 d-flex justify-content-center text-center`
        cardClass += `ws-card-depo`
      }
      // <div class="${cardClass} pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">

      html += `<div class="${colClass}">
                  <div class="${cardClass} pointer">
                    <div class="container">
                      <h3 class="ws-card-title">${testimonial.title}</h3>
                      <p class="ws-card-text-small">
                        ${testimonial.description}
                      </p>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doCarouselIndicators()
    })
  }

  doCarouselIndicators() {

    var indicatorsHtml = ``
    this.numberPages.forEach(element => {
      indicatorsHtml += `<li data-action="click->${this.controllerName}#changeCarousel" data-${this.controllerName}-target="carouselIndicator" data-slide-to="${element}" class="carousel-indicator-icon"></li>`
    })

    var html = `<div class="col-lg-12 text-center mt-5">
                  <ol class="carousel-indicators">
                    ${indicatorsHtml}
                  </ol>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.carouselIndicatorTargets[this.pageNumber].classList.add(`active`)
    })
  }

  changeCarousel(ev) {
    this.cardsTarget.innerHTML = ``
    this.pageNumber = ev.currentTarget.dataset.slideTo

    this.setTestimonials()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    var html = `<section class="ws-section-testimonials">
                  <div class="container">
                    <div class="col-lg-12 text-center my-3">
                      <h1 class="ws-title-section mb-5">Depoimentos de nossos Médicos</h1>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="swiper-container ws-depo-client" style="height: 50vh;">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              <div class="ws-card-depo">
                                <div class="container">
                                  <h3 class="ws-card-title">Dr. Gerson Marques</h3>
                                  <p class="ws-card-text-small">
                                    Excelente atendimento, profissionalismo sem igual e com um olhar completamente diferente das outras empresas.  
                                    A MedCapital consegue entender as particularidades da vida do médico e sabe como o ajudar a dominar a vida financeira. 
                                    Assessoria “full time” passando grande segurança e conforto ao cliente.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="swiper-slide">
                              <div class="ws-card-depo">
                                <div class="container">
                                  <h3 class="ws-card-title">Dr. Leonardo Ornelas</h3>
                                  <p class="ws-card-text-small">
                                    Há pouco mais de 2 anos optei por mudar minhas PJ e PF para a MedCapital. 
                                    Mudança acertada. Desde então pude concentrar energia e manter o foco na Medicina. 
                                    Confiança de que a MedCapital está atenta nas melhores práticas para redução lícita 
                                    dos impostos e no crescimento do meu patrimônio financeiro.
                                </div>
                              </div>
                            </div>
                            <div class="swiper-slide">
                              <div class="ws-card-depo">
                                <div class="container">
                                  <h3 class="ws-card-title">Dr. Bruno e Dra. Viviane</h3>
                                  <p class="ws-card-text-small">
                                    Acreditamos ser um dos primeiros clientes da MedCapital. 
                                    Ótima escolha! Temos uma assessoria de qualidade e muito honesta na qual 
                                    podemos confiar a qualquer hora, em qualquer lugar. Um grande parceiro. Com seus serviços 
                                    podemos nos dedicar mais ao nosso trabalho, acumular patrimônio e ter paz.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="swiper-pagination"></div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </section>`

    this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
