import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "calculationDashboard", "ticketsDashboard", "ticketsDashboardBody", "journeyDashboard",
    "journeyDashboardBody", "ticketIndexBtn", "journeyIndexBtn", "finishIndexBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--show--dashboard`
    this.loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`
  }

  reconnect() {
    this.doTaxFilingDashboard()
    if (this.application.tax_filing_calculations) {
      this.setCurrentCalculation()
      this.doJourneyDashboardHtml()
      this.doTicketDashboardHtml()
    }
  }

  setCurrentCalculation() {
    if (this.application.tax_filing_calculations.length > 0) {
      this.application.tax_filing_calculations.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.application.current_tax_filing_calculation = element
        }
      })
      this.doCalculationDashboardHtml()
    } else {
      this.application.current_tax_filing_calculation = { name: "Não Definido", journey_status_pretty: "Não Definido" }
    }
  }

  goToUpload() {
    if (this.application.permissions[this.application.files_permission].can_upload) {
      this.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").doUploadsDashboard()
    }
  }

  goToJourneyIndex() {
    if (this.application.permissions[this.application.journey_permission].can_index) {
      this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").doJourneyDashboard()
    }
  }

  goToTicketIndex() {
    if (this.application.permissions[this.application.ticket_permission].can_index) {
      this.getControllerByIdentifier("operations--products--tax-filings--tickets--dashboard").doTicketsDashboard()
    }
  }

  goToFinishIndex() {

  }

  doJourneyDashboardHtml() {

    this.current_journeys = []
    this.application.journeys.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_journeys[this.current_journeys.length] = element
      }
    })

    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_journeys, `date_to_time`)

    var bodyHtml = ""
    if (journeys == undefined || journeys == [] || journeys.length == 0) {
      var noData = `</tr>
                      <td colspan="10" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">A Jornada ainda não começou</span>
                      </td>
                    </tr>`

      bodyHtml = noData
    } else {
      var i = 0
      journeys.forEach(element => {
        if (length == 1) {
          var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
        } else {
          var tableRow = `<tr class="itemRow" data-id="${element.id}">`
        }
        if (i == 0) {
          bodyHtml += `${tableRow}
                      <td style="font-size:130%;font-weight:bold;" scope="col" class="p-1 align-middle text-bold">${element.date_pretty}  ${element.date_time}</td>
                      <td style="font-size:130%;font-weight:bold;" scope="col" class="p-1 align-middle text-bold pointer" data-action="click->${this.controllerName}#goToJourneyIndex">${element.status_pretty}</td>
                    </tr>`
        } else {
          bodyHtml += `${tableRow}
                      <td style="font-size:100%;" scope="col" class="p-1 align-middle">${element.date_pretty}  ${element.date_time}</td>
                      <td style="font-size:100%;" scope="col" class="p-1 align-middle">${element.status_pretty}</td>
                    </tr>`
        }
        i += 1
      })
    }

    var cardBodyHTml = `<div class="row">
                          <div class="col p-0">
                            <div class="table-responsive">
                              <table class="table table-sm table-hover table-borderless" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                                <tbody>
                                  ${bodyHtml}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>`

    // if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
    //   var title = ` <h6 class="text-bold mb-0">Jornada IRPF ${this.application.current_date.year + 1}</h6>
    //                 <div class="card-actions ml-auto py-0 mc-tooltip">
    //                   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="journeyIndexBtn" data-action="click->${this.controllerName}#goToJourneyIndex" type="button">
    //                     <span class="material-icons">add_road</span>
    //                   </button>
    //                   <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
    //                 </div>`
    // } else if (this.application.current_user.account_kind == `doctor`) {
    //   var title = `<h6 class="text-bold mb-0 mr-auto">Jornada IRPF ${this.application.current_date.year + 1}</h6>
    //                 <span class="mc-tooltip context-menu p-3">
    //                   <span class="material-icons">add_road</span>
    //                   <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
    //                 </span>`
    // }

    var title = ` <h6 class="text-bold mb-0">Jornada IRPF ${this.application.current_date.year + 1}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="journeyIndexBtn" data-action="click->${this.controllerName}#goToJourneyIndex" type="button">
                        <span class="material-icons">add_road</span>
                      </button>
                      <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
                    </div>`

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        ${title}
                      </div>
                      <div class="card-body text-center f-065 py-0" data-${this.controllerName}-target="journeyDashboardBody" style="overflow-y:scroll;">
                        ${cardBodyHTml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.journeyDashboardTarget.innerHTML = html)
    }).then(() => {
      controller.journeyDashboardBodyTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.application.permissions[controller.application.journey_permission].can_index == false) {
        // controller.journeyIndexBtnTarget.classList.add("d-none")
      }
    })
  }

  doTicketDashboardHtml() {
    this.in_process_tickets = []
    this.resolved_tickets = []
    this.application.tickets.forEach(element => {
      if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
        if (element.date_id == this.application.current_date.id) {
          if (element.stage == `in_process`) {
            this.in_process_tickets[this.in_process_tickets.length] = element
          } else if (element.stage == `resolved`) {
            this.resolved_tickets[this.resolved_tickets.length] = element
          }
        }
      } else if (this.application.current_user.account_kind == `doctor`) {
        if (element.date_id == this.application.current_date.id && element.sharing == `external`) {
          if (element.stage == `in_process`) {
            this.in_process_tickets[this.in_process_tickets.length] = element
          } else if (element.stage == `resolved`) {
            this.resolved_tickets[this.resolved_tickets.length] = element
          }
        }
      }
    })

    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)

    var bodyHtml = ""
    if (tickets == undefined || tickets == [] || tickets.length == 0) {
      var noData = `<div class="row my-5">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075">Sem Tickets em Processo</span>
                      </div>
                    </div>`

      bodyHtml = noData
    } else {
      var i = 0
      tickets.forEach(element => {

        if (this.application.permissions[this.application.ticket_permission].can_list) {
          if (element.total_comments > 0) {
            var commentBadge = `<span class="badge badge-danger notice-badge-comment" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
          } else {
            var commentBadge = `<span class="badge badge-danger notice-badge-comment d-none" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
          }
          var commentBtn = `<div class="col-2 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-150 md-dark pointer" data-toggle="collapse" href="#collapseComments-${element.id}" data-ticket-id="${element.id}" data-action="click->${this.controllerName}#doCommentHtml">comment</span>
                              ${commentBadge}
                              <span class="mc-tooltiptext">Comentários</span>  
                            </div>`
          var commentBody = `<div id="collapseComments-${element.id}" class="collapse" data-ticket-id="${element.id}" data-controller="users--works--activities--shared--comments" data-users--works--activities--shared--comments-target="collapse-${element.id}"></div>`
        } else {
          var commentBtn = ``
          var commentBody = ``
        }

        if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
          var uploadBtn = `<div class="col-2 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-150 md-dark pointer" data-action="click->${this.controllerName}#goToUpload">cloud_upload</span>
                              <span class="mc-tooltiptext">Fazer upload de Arquivos</span>
                          </div>`
        } else {
          var uploadBtn = ``
        }

        bodyHtml += `<div class="row my-3">
                      <div class="col-12 p-0">
                        <div class="card border-flag-${element.flag}">
                          <div class="card-header p-1 text-center f-065 d-flex" style="height:40px;">
                            <div class="col-8 px-1 mr-auto d-flex align-items-center">${element.due_at_pretty}</div>
                            ${uploadBtn}
                            ${commentBtn}
                          </div>
                          <div class="card-body p-1 text-center f-065">
                            <div class="row py-1 px-2">
                              <div class="col-12 px-0 d-flex align-items-center text-left">${element.body}</div>
                            </div>
                          </div>
                          ${commentBody}
                        </div>
                      </div>
                    </div>`
      });
    }

    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      var title = ` <h6 class="text-bold mb-0">Tickets IRPF ${this.application.current_date.year + 1}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="ticketIndexBtn" data-action="click->${this.controllerName}#goToTicketIndex" type="button">
                        <span class="material-icons">task_alt</span>
                      </button>
                      <span class="mc-tooltiptext">Tickets IRPF ${this.application.current_date.year + 1}</span>
                    </div>`
    } else if (this.application.current_user.account_kind == `doctor`) {
      var title = ` <h6 class="text-bold mb-0">Tickets IRPF ${this.application.current_date.year + 1}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="ticketIndexBtn" data-action="click->${this.controllerName}#goToTicketIndex" type="button">
                        <span class="material-icons">task_alt</span>
                      </button>
                      <span class="mc-tooltiptext">Tickets IRPF ${this.application.current_date.year + 1}</span>
                    </div>`
      // var title = `<h6 class="text-bold mb-0 mr-auto">Tickets IRPF ${this.application.current_date.year + 1}</h6>
      //               <span class="mc-tooltip context-menu p-1">
      //                 <span class="material-icons">task_alt</span>
      //                 <span class="mc-tooltiptext">Tickets IRPF ${this.application.current_date.year + 1}</span>
      //               </span>`
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header d-flex align-items-center py-0 f-065">
                        ${title}
                      </div>
                      <div class="card-body text-center f-065 py-0" data-${this.controllerName}-target="ticketsDashboardBody" data-controller="users--works--activities--shared--tickets" style="overflow-y:scroll;">
                        ${bodyHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.ticketsDashboardTarget.innerHTML = html)
    }).then(() => {
      controller.ticketsDashboardBodyTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.application.permissions[this.application.ticket_permission].can_index == false) {
        // controller.ticketIndexBtnTarget.classList.add("d-none")
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = controller.application.ticket_permission
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 5
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).sharing = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_count_controller = controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`)
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: controller.application.tax_filing.id,
        board_type: `operation_products`,
        board_subtype: `operation_tax_filings`,
        board_path: window.location.pathname,
        board_name: `IRPF ${controller.application.current_date.name} ${controller.application.tax_filing.account_name}`,
        date_id: controller.application.current_date.id,
        date_type: `operation_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: controller.application.tax_filing.id,
        board_type: `operation_products`,
        date_id: controller.application.current_date.id,
        date_type: `operation_dates`
      }
    })
  }

  doCommentHtml(ev) {
    this.getControllerByIdentifier(`users--works--activities--shared--tickets`).doCommentHtml(ev)
  }

  doCalculationDashboardHtml() {

    // if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
    //   var title = `<h6 class="text-bold mb-0">${this.application.current_tax_filing_calculation.name}</h6>`
    // } else if (this.application.current_user.account_kind == `doctor`) {
    //   var title = `<h6 class="text-bold mb-0">${this.application.current_tax_filing_calculation.name}</h6>`
    // }

    var journeyHtml = ``
    if (this.application.current_user.account_kind == `doctor`) {
      if (this.application.current_tax_filing_calculation.journey_status == `draft_sent`) {
        journeyHtml = `<div class="card bg-outline-primary pointer">
                        <div class="card-header p-1 text-center f-065 mc-tooltip" data-action="click->${this.controllerName}#goToJourneyIndex">
                          <h6 class="text-bold my-0">${this.application.current_tax_filing_calculation.journey_status_pretty}</h6>
                          <p class="my-0">Clique Aqui para Aprovar o Rascunho</p>
                          <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
                        </div>
                      </div>`
      } else {
        journeyHtml = `<div class="card pointer">
                        <div class="card-header p-1 text-center f-065 mc-tooltip" data-action="click->${this.controllerName}#goToJourneyIndex">
                          <h6 class="text-bold my-0">${this.application.current_tax_filing_calculation.journey_status_pretty}</h6>
                          <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
                        </div>
                      </div>`
      }
    } else if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      journeyHtml = `<div class="card pointer">
                        <div class="card-header p-1 text-center f-065 mc-tooltip" data-action="click->${this.controllerName}#goToJourneyIndex">
                          <h6 class="text-bold my-0">${this.application.current_tax_filing_calculation.journey_status_pretty}</h6>
                          <span class="mc-tooltiptext">Jornada IRPF ${this.application.current_date.year + 1}</span>
                        </div>
                      </div>`
    }

    var html = `<div class="row mb-5">
                      <div class="col-12">
                        <div class="card py-3">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="text-bold mb-0">${this.application.current_tax_filing_calculation.name}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <h6 class="mb-0 text-center text-bold">Status</h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        ${journeyHtml}
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-4 pr-1 text-center">
                        <span class="mb-0 f-065"># Documentos Aprovados</span>
                        <hr class="mt-1">
                        <h6 class="mb-0 text-center">${Number(this.application.current_tax_filing_calculation.files_approved) + Number(this.application.current_tax_filing_calculation.files_processed)}</h6>
                      </div>
                      <div class="col-4 pr-1 text-center">
                        <span class="mb-0 f-065"># Documentos Processados</span>
                        <hr class="mt-1">
                        <h6 class="mb-0 text-center">${this.application.current_tax_filing_calculation.files_processed}</h6>
                      </div>
                      <div class="col-4 text-center">
                        <h6 class="mb-0 f-1p5 text-bold">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.application.current_tax_filing_calculation.process_percentage * 10000).toString())}</h6>
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        <div class="card pointer" data-action="click->${this.controllerName}#goToUpload">
                          <div class="card-header p-1 text-center f-1p5 bg-primary">
                            <h4 class="text-bold my-3">Faça aqui o Upload dos seus Documentos</h4>
                          </div>
                        </div>
                      </div>
                    </div>`

    this.calculationDashboardTarget.innerHTML = html
  }

  doTaxFilingDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6" data-${this.controllerName}-target="calculationDashboard">
                    <div class="row mb-5">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <h6 class="mb-0 text-center">${this.loader}</h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-4">
                        <h6 class="mb-0 text-center">${this.loader}</h6>
                        <hr class="mt-1">
                        <h6 class="mb-0 text-center">${this.loader}</h6>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center">${this.loader}</h6>
                        <hr class="mt-1">
                        <h6 class="mb-0 text-center">${this.loader}</h6>
                      </div>
                      <div class="col-4">
                        <h6 class="mb-0 text-center my-3">${this.loader}</h6>
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pr-1" data-${this.controllerName}-target="ticketsDashboard">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>${this.loader}</span>
                          </div>
                          <div class="card-body text-center f-065 py-0" data-${this.controllerName}-target="ticketsDashboardBody">
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 pl-1" data-${this.controllerName}-target="journeyDashboard">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>${this.loader}</span>
                          </div>
                          <div class="card-body text-center f-065 py-0" data-${this.controllerName}-target="journeyDashboardBody">
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row my-3">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>${this.loader}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.ticketsDashboardBodyTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.journeyDashboardBodyTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
