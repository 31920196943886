import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "content", "main", "mainCard", "teamList", "team", "teamDropdown", "teamInput", "index", "indicators"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--index--dashboard`
    this.application.account_id = this.application.current_user.account_id
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.all_team = false
    this.application.tickets = []
    this.application.permissions = []
    this.application.schedules = []
    this.application.all_opportunities = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--sales--opportunities--index--report
                                                            commercial--sales--opportunities--index--tickets
                                                            commercial--sales--opportunities--index--list
                                                            commercial--sales--opportunities--index--indicators
                                                            commercial--sales--opportunities--index--schedules
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doOpportunitiesLoader()
    })
  }

  doOpportunitiesGrid() {
    var html = `<div class="row" data-commercial--sales--opportunities--index--indicators-target="indicators"></div>
                <div class="row my-3">
                  <div class="col-12 px-2" data-commercial--sales--opportunities--index--list-target="table"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`commercial--sales--opportunities--index--indicators`).doIndicatorsCalculation()
      controller.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).allOpportunities()
    })
  }

  doOpportunitiesLoader() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row" data-commercial--sales--opportunities--index--indicators-target="indicators">
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2" data-commercial--sales--opportunities--index--list-target="table">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getCurrentUserPermissions()
    })
  }

  doTeamHTML() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Time</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="teamDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="teamInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="teamList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.teamTarget.innerHTML = html)
    }).then(() => {
      controller.teamDropdownTarget.value = controller.application.current_user.name
      controller.teamInputTarget.innerText = controller.application.current_user.name
      controller.doTeamList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doTeamList() {
    var listHtml = ``
    listHtml += `<li data-app--helpers--selector-target="select" data-team="team" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">Todos</li>`
    this.application.sales_team.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-team="individual" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">${element.name}</li>`
    });
    this.teamListTarget.innerHTML = listHtml
  }

  changeTeam(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var team = ev.target.dataset.team
      if (team == "individual") {
        this.application.account_id = ev.target.dataset.id
        this.all_team = false
      } else if (team == "team") {
        this.application.account_id = null
        this.all_team = true
      }
      this.setOpportunities()
      this.setReport()
    }
  }

  setOpportunities() {
    if (this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`) && this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).hasMainCardTarget) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).allOpportunities()
    }
  }

  setReport() {
    if (this.getControllerByIdentifier(`commercial--sales--opportunities--index--report`) && this.getControllerByIdentifier(`commercial--sales--opportunities--index--report`).hasReportPageTarget) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--index--report`).setRequest()
    }
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doIndexListHtml()
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--indicators`).doIndicatorsCalculation()
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--submenu`).doSubmenuHtml()

    if (this.application.permissions.sales_opportunities_entities.can_manage) {
      this.doTeamHTML()
    }
  }

  getSalesTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Vendas`, value: 70 })

    const data = { feature: { feature_name: `sales_opportunities_entities`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sales_team = response.data.cln
        controller.finishLoadPage()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSchedules() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Agendamentos`, value: 60 })

    var data = { schedule: { active: true, kind: `sales`, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_schedules` } }
    const url = "/users/works/schedules/entities/list_by_kind_and_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            if (element.has_opportunity) {
              controller.application.all_opportunities.forEach(opportunity => {
                if (opportunity.id == element.record_id) {
                  element.opportunity = opportunity
                }
              })
              controller.application.schedules[controller.application.schedules.length] = element
            }
          })
        }

        if (controller.application.permissions.sales_opportunities_entities.can_manage) {
          controller.getSalesTeam()
        } else {
          controller.finishLoadPage()
        }
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOpportunities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Oportunitidades`, value: 50 })

    const data = { opportunity: { active: true, account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/entities/list_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.all_opportunities = response.data.cln
        controller.getSchedules()
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getOrigins() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Origens`, value: 40 })

    var data = { origin: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/sales/opportunities/origins/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.origins = response.data.cln
        controller.getOpportunities()
      })
      .catch(error => {
        controller.getControllerByIdtentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCommercialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.dates = response.data.cln
        controller.application.current_date = response.data.cln[0]
        controller.application.current_calculation = response.data.cln[0].calculation

        controller.getOrigins()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_opportunitites` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getCommercialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
