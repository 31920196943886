import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["info", "main", "submenu", "purchaseJourney", "purchaseStartedAt", "purchaseOpenDays", "purchaseProductName", "purchaseProductPlan",
                    "purchaseProductKind", "purchaseKind", "purchaseStatus", "destroyPartyBtn", "purchaseJourneyName", "purchaseJourneyEdit",
                    "purchaseJourneyInput", "journeyFilter", "cancelBtn", "addPartyBtn", "listParties", "listJourneys", "ticketsRow",
                    "ticketsCard", "contractCard", "notesCard", "addTicketBtn", "contractsRow", "notesRow", "addNoteBtn", "contractsRowElement",
                    "inProcess", "resolved", "forwardPurchaseBtn", "purchaseProductService", "purchaseProductAmount"]

  connect() {
    this.controllerName = `financials--products--purchases--entities--show`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.application.permissions = {}
    this.application.purchase = {}
    this.application.purchase_token = location.pathname.split("/").pop()
    this.application.tickets = []
    this.doGridHtml()
  }

  togglePurchase(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "ticket") {
      this.ticketsCardTarget.classList.add("bg-primary")
      this.contractCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.contractsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "contract") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.contractCardTarget.classList.add("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.contractsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
    } else if (feature == "note") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.contractCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.add("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.contractsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
    }
    
  }

  doGridHtml() {
    var html = `<div class="row">
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Jornada</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseJourney">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Data Início</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseStartedAt">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Dias</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseOpenDays">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Produto</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseProductName">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Plano</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseProductPlan">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Serviço</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseProductService">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-3 px-2 text-center">
                        <h6 class="mb-0">Tipo</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseProductKind">
                          ${this.loader}
                        </span>
                      </div>
                      
                      
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Valor</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseProductAmount">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Recorrência</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseKind">
                          ${this.loader}
                        </span>
                      </div>
                      <div class="col-4 px-2 text-center">
                        <h6 class="mb-0">Status</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="purchaseStatus">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-4 px-2"></div>
                  <div class="col-8 px-2">
                    <div class="row w-100">
                      <div class="col-4 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard" data-feature="ticket" data-action="click->${this.controllerName}#togglePurchase">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="contractCard" data-feature="contract" data-action="click->${this.controllerName}#togglePurchase">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Contrato</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard" data-feature="note" data-action="click->${this.controllerName}#togglePurchase">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Partes Envolvidas</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addParty" data-${this.controllerName}-target="addPartyBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Parte</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listParties">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Jornada</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listJourneys">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-8 px-2">
                    <div class="row" data-${this.controllerName}-target="ticketsRow" data-financials--products--purchases--entities--tickets-target="ticketBoard" data-controller="financials--products--purchases--entities--tickets">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="contractsRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Contrato</span>
                        </h6>
                        <hr class="my-1">
                        </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="contractsRow" data-financials--products--purchases--entities--contracts-target="main" data-controller="financials--products--purchases--entities--contracts">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="notesRow" data-financials--products--purchases--entities--notes-target="main" data-controller="financials--products--purchases--entities--notes">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  setPageTitle() {
    this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = `Contratação #${this.application.purchase.token}`
    this.infoTarget.innerHTML = `Valor Total: ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.application.purchase.total_amount * 100))}`
    this.infoTarget.innerHTML += ` | Total Médicos: ${this.application.purchase.total_doctors}`
    this.infoTarget.innerHTML += ` | Fonte Primária: ${this.application.purchase.source_pretty}`
    this.infoTarget.innerHTML += ` | Token: <span class="mc-tooltip grab">
                                              <span class="material-icons md-sm md-dark" data-controller="app--helpers--copy" data-copy="${this.application.purchase.token}" data-action="click->app--helpers--copy#copy">file_copy</span>
                                              <span class="mc-tooltiptext">Copiar</span>
                                            </span>`
    if (this.application.purchase.opportunity_id) {
      this.infoTarget.innerHTML += ` | Oportunidade: <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.purchase.opportunity_path}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                                        <span class="material-icons md-sm md-dark">launch</span>
                                                        <span class="mc-tooltiptext">Ir para Oportunidade</span>
                                                      </button>`
    }

    // if ([`signed_contract`, `to_send_invoice`, `sent_invoice`, `paid_invoice`, `commission_payment`, `finished`].includes(this.application.purchase.journey_status)) {
    if (this.application.has_onboard) {
      this.infoTarget.innerHTML += ` | Onboard: <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.purchase.onboard_path}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                                  <span class="material-icons md-sm md-dark">launch</span>
                                                  <span class="mc-tooltiptext">Ir para Onboard</span>
                                                </button>`      
    }

    if (!this.application.purchase.has_commissions && this.checkProductServiceCommissions()) {
      this.infoTarget.innerHTML += ` | Comissão: <button data-action="click->${this.controllerName}#requestCreateCommission" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                                  <span class="material-icons md-sm md-dark">add</span>
                                                  <span class="mc-tooltiptext">Criar Commissão</span>
                                                </button>`      
    }
  }

  requestCreateCommission() {
    var r = confirm(`Confirma a criação da Comissão?`)

    if (r) {
      var url = "/financials/products/commissions/entities/create_from_purchase"
      var method = "POST"
      this.send_data = { current_user: {}, commission: {} }
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_commission_entities`
      
      this.send_data.commission.purchase_id = this.application.purchase.id
      
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      var controller = this
      
      fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.purchase.has_commissions = response.save
          controller.setPageTitle()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
    }
  }

  setPageHeader() {
    this.setOpenDays()
    this.setStartedAt()
    this.setStatus()
    this.setKind()
    this.setProductService()
    this.setProductKind()
    this.setProductAmount()
    this.setProductName()
    this.setProductPlan()
    this.setMainJourney()
  }

  setMainJourney() {

    if (this.application.purchase.journey_status == `finished` || this.application.purchase.journey_status == `canceled`) {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-12 px-0 text-center">
                      ${this.application.purchase.journey_status_pretty}
                    </div>
                  </div>`
    } else {
      var html = `<div class="row d-flex align-items-center">
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#lostPurchase" data-stage="canceled" data-stage-pretty="Cancelada">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Contratação Cancelada?</span>
                      </span>
                    </div>
                    <div class="col-8 px-0 mc-tooltip text-center">
                      ${this.application.purchase.journey_status_pretty}
                    </div>
                    <div class="col-2 px-0 pointer mc-tooltip text-center">
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#forwardPurchase" data-stage="" data-stage-pretty="" data-${this.controllerName}-target="forwardPurchaseBtn">
                        <span class="material-icons md-sm md-dark">arrow_forward_ios</span>
                        <span class="mc-tooltiptext">Avançar Contratação</span>
                      </span>
                    </div>
                  </div>`

    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.purchaseJourneyTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.purchase.journey_status != `finished` && controller.application.purchase.journey_status != `canceled`) {
        controller.listJourney()
      }
    })
  }

  forwardPurchase(ev) {
    var stage = ev.currentTarget.dataset.stage
    
    if (this.checkPurchaseJourneys(stage)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Contratação já passou por esse Estágio de Jornada! :/`, 5000)
    } else {

      if (this.checkUploadContrats(stage)) {
        var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para a Contratação?`)

        if (r) {
          this.send_data = { current_user: {}, journey: {}, purchase: {}, note: {} }
          this.send_data.current_user.current_user_id = this.application.current_user.id

          this.send_data.journey.purchase_id = this.application.purchase.id
          this.send_data.journey.stage = stage
          this.send_data.journey.date = new Date()
          this.send_data.purchase.journey_status = stage
          this.send_data.purchase.purchases = []

          this.changeStage()
        } else {
          this.setMainJourney()
        }
        
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor fazer o Upload do Contrato Assinado e do Sumário de Assinaturas!`, 5000)
      }
      
    }
  }

  lostPurchase(ev) {
    var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.stagePretty} para a Contratação?`)

    if (r) {
      this.send_data = { current_user: {}, journey: {}, purchase: {} }
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.journey.purchase_id = this.application.purchase.id
      this.send_data.journey.stage = ev.currentTarget.dataset.stage
      this.send_data.journey.date = new Date()
      this.send_data.purchase.journey_status = ev.currentTarget.dataset.stage
      this.send_data.purchase.purchases = []

      this.changeStage()
    } else {
      this.setMainJourney()
    }
  }

  changeStage() {
    if (this.send_data.purchase.journey_status == `finished` || this.send_data.purchase.journey_status == `canceled`) {
      if (this.application.in_process_tickets.length == 0) {
        this.requestSaveJourney()
      } else {
        this.setMainJourney()
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos Finalizar com Tickets em Aberto. :/`, 5000)
      }
    } else {
      this.requestSaveJourney()
    }
  }

  listJourney() {
    // var html = `<li data-action="click->${this.controllerName}#selectJourney" data-text="Enviar Contrato" data-filter="to_send_contract" class="li-selector dark f-065 text-left">Enviar Contrato</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Contrato Enviado" data-filter="sent_contract" class="li-selector dark f-065 text-left">Contrato Enviado</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Contrato Assinado" data-filter="signed_contract" class="li-selector dark f-065 text-left">Contrato Assinado</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Enviar Fatura" data-filter="to_send_invoice" class="li-selector dark f-065 text-left">Enviar Fatura</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Fatura Enviada" data-filter="sent_invoice" class="li-selector dark f-065 text-left">Fatura Enviada</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Fatura Paga" data-filter="paid_invoice" class="li-selector dark f-065 text-left">Fatura Paga</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Finalizado" data-filter="finished" class="li-selector dark f-065 text-left">Finalizado</li>
    //             <li data-action="click->${this.controllerName}#selectJourney" data-text="Cancelado" data-filter="canceled" class="li-selector dark f-065 text-left">Cancelado</li>`
    // this.journeyFilterTarget.innerHTML = html

    if (this.application.purchase.journey_status == `to_send_contract`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `sent_contract`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Contrato Enviado`
    } else if (this.application.purchase.journey_status == `sent_contract`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `signed_contract`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Contrato Assinado`
    } else if (this.application.purchase.journey_status == `signed_contract`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `to_send_invoice`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Enviar Fatura`
    } else if (this.application.purchase.journey_status == `to_send_invoice`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `sent_invoice`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Fatura Enviada`
    } else if (this.application.purchase.journey_status == `sent_invoice`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `paid_invoice`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Fatura Paga`
    } else if (this.application.purchase.journey_status == `paid_invoice`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `commission_payment`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Pagamento de Comissão`
    } else if (this.application.purchase.journey_status == `commission_payment`) {
      this.forwardPurchaseBtnTarget.dataset.stage = `finished`
      this.forwardPurchaseBtnTarget.dataset.stagePretty = `Finalizado`
    }
  }

  journeyFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectJourney(ev) {
    if (this.checkPurchaseJourneys(ev.currentTarget.dataset.filter)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Contratação já passou por esse Estágio de Jornada! :/`, 5000)
    } else {
      var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.text} para a Contratação?`)

      if (r) {
        this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
        this.send_data = { current_user: {}, journey: {}, purchase: {}, note: {} }

        this.send_data.journey.purchase_id = this.application.purchase.id
        this.send_data.journey.stage = this.purchaseJourneyInputTarget.dataset.filter
        this.send_data.journey.date = new Date()
        this.send_data.purchase.journey_status = this.purchaseJourneyInputTarget.dataset.filter
        this.send_data.purchase.purchases = []

        this.send_data.note.account_id = this.application.current_user.account_id
        this.send_data.note.domain_id = this.application.purchase.id
        this.send_data.note.domain_type = `products_purchases`
        this.send_data.note.domain_name = `products_purchases`
        this.send_data.note.date_id = this.application.purchase.date_id
        this.send_data.note.date_type = `financials_dates`
        this.send_data.note.owner_id = this.application.current_user.account_id
        this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
        this.send_data.note.sharing = `internal`
        this.send_data.note.kind = `updating`
        this.send_data.note.date = new Date()
        this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} atualizou a Jornada da Contratação para ${this.purchaseJourneyInputTarget.dataset.text}`
        this.send_data.note.title = `Atualização`

        if (this.send_data.journey.journey_status == `finished` || this.send_data.journey.journey_status == `canceled`) {
          if (this.application.in_process_tickets.length == 0) {
            this.doClosingModalHtml()
          } else {
            this.setMainJourney()
            this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos Finalizar com Tickets em Aberto. :/`, 5000)
          }
        } else {
          this.requestSaveJourney()
        }
      } else {
        this.setMainJourney()
      }
    }
    
  }

  enterJourney(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      if (this.checkPurchaseJourneys(ev.currentTarget.dataset.filter)) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Contratação já passou por esse Estágio de Jornada! :/`, 5000)
      } else {
        var r = confirm(`Confirma o novo estágio de ${ev.currentTarget.dataset.text} para a Contratação?`)

        if (r) {
          this.send_data = { current_user: {}, journey: {}, purchase: {}, note: {} }

          this.send_data.journey.purchase_id = this.application.purchase.id
          this.send_data.journey.stage = this.purchaseJourneyInputTarget.dataset.filter
          this.send_data.journey.date = new Date()
          this.send_data.purchase.journey_status = this.purchaseJourneyInputTarget.dataset.filter
          this.send_data.purchase.purchases = []

          // this.send_data.note.account_id = this.application.current_user.account_id
          // this.send_data.note.domain_id = this.application.purchase.id
          // this.send_data.note.domain_type = `products_purchases`
          // this.send_data.note.domain_name = `products_purchases`
          // this.send_data.note.date_id = this.application.purchase.date_id
          // this.send_data.note.date_type = `financials_dates`
          // this.send_data.note.owner_id = this.application.current_user.account_id
          // this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
          // this.send_data.note.sharing = `internal`
          // this.send_data.note.kind = `updating`
          // this.send_data.note.date = new Date()
          // this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} atualizou a Jornada da Contratação para ${this.purchaseJourneyInputTarget.dataset.text}`
          // this.send_data.note.title = `Atualização`

          if (this.send_data.journey.journey_status == `finished` || this.send_data.journey.journey_status == `canceled`) {
            if (this.application.in_process_tickets.length == 0) {
              this.doClosingModalHtml()
            } else {
              this.setMainJourney()
              this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos Finalizar com Tickets em Aberto. :/`, 5000)
            }
          } else {
            this.requestSaveJourney()
          }
        } else {
          this.setMainJourney()
        }
      }
    }
  }

  requestSaveJourney() {
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_journeys`

    var url = "/financials/products/purchases/journeys/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln

          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} avançou a Contratação para ${journey.stage_pretty}`
          controller.getControllerByIdentifier("financials--products--purchases--entities--notes").saveUpdateNote(message)

          controller.application.purchase.journey_status = journey.stage
          controller.application.purchase.journey_status_pretty = journey.stage_pretty
          controller.application.purchase.journeys[controller.application.purchase.journeys.length] = journey
          controller.application.purchase.journeys.forEach(element => {
            element.stage_alert = false
          })

          if (journey.stage == `finished` || journey.stage == `canceled`) {
            window.location.reload()
          } else if (journey.stage == `signed_contract`) {
            if (controller.checkProductServiceOnboards()) {
              controller.requestCreateOnboard()
            } 
          }

          controller.setPageHeader()
          controller.setJourneys()
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
          controller.sendJourneyEmailNotification(journey)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestCreateOnboard() {
    var url = "/operations/onboards/entities/create_from_purchase"
    var method = "POST"
    this.send_data = { current_user: {}, purchase: {} }

    this.send_data.purchase.id = this.application.purchase.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_entities`

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.onboard = response.data.cln
          controller.application.has_onboard = response.save

          controller.setPageTitle()
          controller.sendOnboardEmailNotification()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendOnboardEmailNotification() {
    var purchase = this.application.purchase
    this.send_data = { current_user: {}, notification: {} }

    var partyName = ``
    this.application.purchase.parties.forEach(party => {
      // partyName += `<h6 class="mb-0 f-065">${party.party_name}</h6>`
      partyName += `| ${party.party_name} `
    })

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.onboard_id = this.application.onboard.id
    this.send_data.notification.onboard_token = this.application.onboard.token
    this.send_data.notification.onboard_name = `${purchase.product_service_pretty} ${purchase.product_accrual_name_pretty} ${purchase.product_kind_plan_pretty}`
    this.send_data.notification.onboard_parties = partyName.trim()
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `criou um novo Onboard: `
    this.send_data.notification.body = `${purchase.product_service_pretty} ${purchase.product_accrual_name_pretty} ${purchase.product_kind_plan_pretty}`

    var url = "/operations/onboards/entities/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendJourneyEmailNotification(journey) {
    var purchase = this.application.purchase
    this.send_data = { current_user: {}, notification: {} }

    var partyName = ``
    this.application.purchase.parties.forEach(party => {
      // partyName += `<h6 class="mb-0 f-065">${party.party_name}</h6>`
      partyName += `| ${party.party_name} `
    })

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.purchase_id = purchase.id
    this.send_data.notification.purchase_token = purchase.token
    this.send_data.notification.purchase_name = `${purchase.product_service_pretty} ${purchase.product_accrual_name_pretty} ${purchase.product_kind_plan_pretty}`
    this.send_data.notification.purchase_parties = partyName.trim()
    this.send_data.notification.receiver_id = purchase.generator_id
    this.send_data.notification.receiver_name = purchase.generator_name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da Contratação ${purchase.product_service_pretty} ${purchase.product_accrual_name_pretty} ${purchase.product_kind_plan_pretty} para`
    this.send_data.notification.body = journey.stage_pretty

    var url = "/financials/products/purchases/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setProductName() {
    this.purchaseProductNameTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.product_accrual_name_pretty}
                                                  </div>
                                                </div>`
  }

  setProductPlan() {
    this.purchaseProductPlanTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.product_plan_pretty}
                                                  </div>
                                                </div>`
  }

  setProductKind() {
    this.purchaseProductKindTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.product_kind_pretty}
                                                  </div>
                                                </div>`
  }

  setProductAmount() {
    this.purchaseProductAmountTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                    <div class="col-12 px-0 mc-tooltip text-center">
                                                      ${this.controllerNumber.currencyOptionMask(this.application.purchase.total_amount)}
                                                    </div>
                                                  </div>`
  }

  setProductService() {
    this.purchaseProductServiceTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.product_service_pretty}
                                                  </div>
                                                </div>`
  }

  setKind() {
    this.purchaseKindTarget.innerHTML = `<div class="row d-flex align-items-center">
                                            <div class="col-12 px-0 mc-tooltip text-center">
                                              ${this.application.purchase.kind_pretty}
                                            </div>
                                          </div>`
  }

  setStatus() {
    this.purchaseStatusTarget.innerHTML = `<div class="row d-flex align-items-center">
                                              <div class="col-12 px-0 mc-tooltip text-center">
                                                ${this.application.purchase.status_pretty}
                                              </div>
                                            </div>`
  }

  setStartedAt() {
    this.purchaseStartedAtTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                <div class="col-12 px-0 mc-tooltip text-center">
                                                  ${this.application.purchase.started_at_pretty}
                                                </div>
                                              </div>`
  }

  setOpenDays() {
    if (this.application.purchase.open_days == 1) {
      this.purchaseOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.open_days} dia
                                                  </div>
                                                </div>`
    } else {
      this.purchaseOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.application.purchase.open_days} dias
                                                  </div>
                                                </div>`
    }
  }

  setParties() {
    var html = ``
    this.application.purchase.parties.forEach(element => {

      // `<td style="font-size:60%;" scope="col" class="p-1 align-middle pointer">
      //   <p class="mb-0 f-065 mc-tooltip">
      //     <span data-controller="app--helpers--copy" data-copy="${element.token}" data-action="click->app--helpers--copy#copy">${element.token}</span>
      //     <span class="mc-tooltiptext">Clique para Copiar</span>
      //   </p>
      // </td>`

      if (element.party_whatsapp) {
        var whatsappBtn = `<i class="fab fa-whatsapp md-dark md-150" data-action="click->${this.controllerName}#sendWhatsAppApi"></i>`
      } else {
        var whatsappBtn = ``
      }

      if (element.party_financial) {
        var financialIcon = `<span class="mc-tooltip">
                              <span class="material-icons md-sm md-dark pointer">attach_money</span>
                              <span class="mc-tooltiptext">Financeiro</span>
                            </span>`
      } else {
        var financialIcon = ``
      }

      if (element.party_signatory) {
        var signatoryIcon = `<span class="mc-tooltip">
                              <span class="material-icons md-sm md-dark pointer">border_color</span>
                              <span class="mc-tooltiptext">Contratante</span>
                            </span>`
      } else {
        var signatoryIcon = ``
      }

      if (element.party_invoice) {
        var invoiceIcon = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-dark pointer">receipt</span>
                            <span class="mc-tooltiptext">Tomador de Serviço</span>
                          </span>`
      } else {
        var invoiceIcon = ``
      }
      

      if (element.kind == `setup`) {
        var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.setup * 100))}`
        var formSpan = element.kind_pretty
      } else if (element.kind == `recurring`) {
        if (element.details_fill_in) {
          if (element.plan == `monthly`) {
            var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.monthly * 100))}`
          } else {
            var valueSpan = `${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.yearly * 100))}`
          }
          var formSpan = `${element.kind_pretty} | ${element.plan_pretty}`
        } else {
          var valueSpan = ``
          var formSpan = ``
        }
      }

      html += `<div class="row my-2 w-100 partyCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-1 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-1">
                            <div class="card-show-dropdown">
                              ${element.party_name}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">${element.party_phone_pretty} <span class="ml-3">${whatsappBtn}</span></p>
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.party_name}" data-action="click->app--helpers--copy#copy">${element.party_name}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                <p class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${element.party_email_pretty}" data-action="click->app--helpers--copy#copy">${element.party_email_pretty}</span>
                                  <span class="mc-tooltiptext">Copiar</span>
                                </p>
                                <p class="mb-0 f-065">${element.party_financial_pretty}</p>
                                <p class="mb-0 f-065">${element.party_signatory_pretty}</p>
                                <p class="mb-0 f-065">${element.party_invoice_pretty}</p>
                                <p class="mb-0 f-065">${valueSpan}</p>
                                <p class="mb-0 f-065">${formSpan}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 px-1 mt-2">
                            <span class="ml-3"></span>
                            ${signatoryIcon}
                            ${financialIcon}
                            ${invoiceIcon}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listPartiesTarget.innerHTML = html)
    }).then(() => {
      
    })
  }

  sendWhatsAppApi(ev) {
    var partyId = ev.currentTarget.closest(".partyCard").dataset.id
    var currentParty = {}
    this.application.purchase.parties.forEach(element => {
      if (element.id == partyId) {
        currentParty = element
      }
    })
    var currentPurchase = this.application.purchase

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = currentPurchase.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `products_purchases`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Contratação ${currentPurchase.product_service_pretty} ${currentPurchase.product_accrual_name_pretty} ${currentPurchase.product_kind_plan_pretty}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = currentPurchase.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `products_purchases`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = currentParty.party_kind_obj.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = currentParty.party_type
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = currentParty.party_kind_obj.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = currentParty.party_phone.ddd
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = currentParty.party_phone.number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = currentParty.party_phone.uniq_number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `financial_purchase_tickets`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  setJourneys() {
    var html = ``
    var journeys = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.purchase.journeys, `date_time`)
    journeys.forEach(element => {

      if (element.period == 1) {
        var period = `${element.period} dia`
      } else {
        var period = `${element.period} dias`
      }

      if (element.stage_alert) {
        var alert = `<div class="col-2 px-0 mc-tooltip">
                        <span class="material-icons md-sm md-danger">warning</span>
                        <span class="mc-tooltiptext">Atenção!</span>
                      </div>`
      } else {
        var alert = ``
      }

      html += `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-1 f-065 pointer">
                        <div class="row my-2">
                          <div class="col-10 px-1">
                            <div class="card-show-dropdown">
                              ${element.stage_pretty}
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065">Data: ${element.date_pretty}</p>
                                <p class="mb-0 f-065">Tempo: ${period}</p>
                              </div>
                            </div>
                          </div>
                          ${alert}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.listJourneysTarget.innerHTML = html
  }

  addTicket() {
    if (this.userCanUpdate()) {
      this.getControllerByIdentifier("financials--products--purchases--entities--tickets").createTicket()
    }
  }

  toggleTickets(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "in_process") {
      this.inProcessTarget.classList.add("badge-secondary")
      this.inProcessTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.in_process_tickets, `due_at`)
      this.getControllerByIdentifier(`financials--products--purchases--entities--tickets`).listData(tickets)
    } else if (feature == "resolved") {
      this.inProcessTarget.classList.remove("badge-secondary")
      this.inProcessTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.resolved_tickets, `due_at`)
      this.getControllerByIdentifier(`financials--products--purchases--entities--tickets`).listData(tickets)
    }
  }

  addNote() {
    this.getControllerByIdentifier("financials--products--purchases--entities--notes").createNote()
  }

  showInlineEditor(ev) {
    if (this.application.permissions.financial_purchase_entities.can_update) {
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.nextElementSibling.firstElementChild.firstElementChild.focus()
    }
  }

  hideInlineEditor(ev) {
    if (this.application.permissions.financial_purchase_entities.can_update) {
      ev.currentTarget.closest(".editInput").classList.add("d-none")
      ev.currentTarget.closest(".editInput").previousElementSibling.classList.remove("d-none")
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  finishLoadPage() {
    if (this.application.purchase.journey_status == `finished` || this.application.purchase.journey_status == `canceled` || this.userCanUpdate() == false) {
      this.getControllerByIdentifier("financials--products--purchases--entities--tickets").blockedAdd = true
      this.getControllerByIdentifier("financials--products--purchases--entities--notes").blockedAdd = true
    }

    this.setPageTitle()
    this.setPageHeader()
    this.setParties()
    this.setJourneys()
    this.getControllerByIdentifier("financials--products--purchases--entities--contracts").doContractsHtml()
    this.getControllerByIdentifier("financials--products--purchases--entities--tickets").doTicketsDashboard()
    this.getControllerByIdentifier("financials--products--purchases--entities--notes").doNotesDashboard()
    
    

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getPurchaseOnboard() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Onboard da Contratação`, value: 90 })

    const data = { purchase: { id: this.application.purchase.id }, current_user: { current_user_id: this.application.current_user.id, feature: `operation_onboard_entities` } }
    const url = "/operations/onboards/entities/read_by_purchase"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.onboard = response.data.cln
        controller.application.has_onboard = response.process

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.application.purchase.id, domain_type: `products_purchases` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.getPurchaseOnboard()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    var data = { ticket: { board_id: this.application.purchase.id, board_type: "products_purchases" }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPurchase() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contratação`, value: 60 })

    const data = { purchase: { token: this.application.purchase_token }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_entities` } }
    const url = "/financials/products/purchases/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.purchase = response.data.cln
        controller.getTickets()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPurchaseTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Contratações`, value: 35 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/financials/config/teams/list_purchase_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.purchase_team = response.data.cln
        controller.application.team_list = response.data.cln

        controller.getPurchase()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_purchases` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_purchase_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getPurchaseTeam()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToDashboard() {
    this.doPageGrid()
    this.doIndexListHtml()
  }

  goToSetttings() {
    var html = `<div class="row">
                  <div class="col-12" data-controller="financials--products--purchases--entities--settings" data-financials--products--purchases--entities--settings-target="main"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  doIndexSubmenuHtml() {
    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span data-action="click->${this.controllerName}#goToDashboard" class="dropdown-item py-1 pointer">Dashboard</span>
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.submenuTarget.innerHTML = submenuHtml
    tooltip()
  }

  checkPurchaseJourneys(newJourney) {
    var journeys = this.mapElements(this.application.purchase.journeys, `stage`)

    const hasJourney = (currentValue) => currentValue != undefined

    if ((!journeys.every(hasJourney)) || (journeys.includes(newJourney))) {
      return true
    } else {
      return false
    }
  }

  checkUploadContrats(newJourney) {
    var non_contract_journeys = ["to_send_contract", "sent_contract", "canceled"]
    var contract_journeys = ["signed_contract", "to_send_invoice", "sent_invoice", "paid_invoice", "commission_payment", "finished"]

    var agreementFile = {}
    var summaryFile = {}

    this.application.purchase.contracts.forEach(element => {
      if (element.kind == `agreement`) {
        agreementFile = element
      } else if (element.kind == `summary`) {
        summaryFile = element
      }
    })

    const hasJourney = (currentValue) => currentValue != undefined


    if ((!contract_journeys.every(hasJourney)) || (contract_journeys.includes(newJourney))) {
      if (agreementFile.has_agreement && summaryFile.has_summary) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }

  }

  checkProductServiceOnboards() {
    var productServices = ["cancellation", "additive", "renewal"]

    if (productServices.includes(this.application.purchase.product_service)) {
      return false
    } else {
      return true
    }
  }

  checkProductServiceCommissions() {
    var productServices = ["cancellation", "additive"]

    if (productServices.includes(this.application.purchase.product_service)) {
      return false
    } else {
      return true
    }
  }

  userCanUpdate() {
    var allowed = this.mapElements(this.application.purchase_team, `id`)
    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.purchase.journey_status != "finished" && this.application.purchase.journey_status != "canceled")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = this.controllerName
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

}
