import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "cardBody", "cardFooter", "cardTitle",
                    "body", "loader", "mainRow", "chartAccounts", "resume", "economy",
                    "totalTaxableIncome", "totalExemptAndNonTaxableIncome", "totalIncomeExclusiveTaxation", "totalIncome",
                    "officialSocialContribution", "supplementaryPension", "dependents", "instructionExpense", "medicalExpenses", "alimony", "booking",
                    "totalIncome", "totalDeductions", "calculationBasis", "taxDue", "incentiveDeduction", "totalWithholdingIncomeTax", "taxDueRra",
                    "adjustedIncomeTaxAmount", "finalAdjusted", "finalAdjustedLabel", "saveBtn", "journeyCol", "totalWithholdingIncomeTax", "taxDueRra",
                    "simplifiedCheckbox", "completeCheckbox"]

  connect() {
    this.controllerName = `pages--print-modal`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    var html = `<div class="modal-backdrop fade show"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doModalHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doModalHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Print</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBody"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      controller.showPrint()
    })
  }

  showPrint() {
    // this.link = `https://medcapital-site-development.s3.sa-east-1.amazonaws.com/7mrWYgFyybiTJkQ4uhvrQzjs?response-content-disposition=inline%3B%20filename%3D%22PDF%20Nota%20Fiscal%20NFSE_00000058_NTGY.pdf%22%3B%20filename%2A%3DUTF-8%27%27PDF%2520Nota%2520Fiscal%2520NFSE_00000058_NTGY.pdf&response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJZPCNI7NQFZIPOMA%2F20230120%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230120T040825Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Signature=a781f077a95775eb3c3a92d629deba38fdbef0827c47eeb75a1c38d4ec648040`
    var html = `<div class="card pointer" style="width:100%;display:relative;">
                  <img src="${this.link}" alt="">
                </div>`

    this.cardBodyTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
