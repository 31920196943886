import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navBtn", "content", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `web--site--principal--frames--header`
  }

  doHtml() {
    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      var link = this.getControllerByIdentifier(`web--site--principal--main`).setLink(this.application.site.header_link_cta)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`)) {
      var link = this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`).setLink(this.application.site.header_link_cta)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`)) {
      var link = this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`).setLink(this.application.site.header_link_cta)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      var link = this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).setLink(this.application.site.header_link_cta)
    }
    var navBar = ``

    // `<li class="nav-item mr-4">
    //   <a class="ws-nav-link" href="/medschool" target="_blank" rel="noopener noreferrer">MedSchool</a>
    // </li>`

    if (this.application.size == `large`) {
      navBar = `<nav class="navbar navbar-expand-sm ws-nav-bar">
                  <div class="container">
                    <a class="navbar-brand mr-5 " href="/">
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                    </a>
                    <div class="collapse navbar-collapse">
                      <ul class="navbar-nav mr-auto">
                        
                        <li class="nav-item">
                          <a class="ws-nav-link" href="/descanso-medico" target="_blank" rel="noopener noreferrer">Descanso Médico</a>
                        </li>
                        <li class="nav-item mx-5">
                          <a class="ws-nav-link" href="/medschool" target="_blank" rel="noopener noreferrer">MedSchool</a>
                        </li>
                      </ul>
                      <div>
                        <ul class="navbar-nav d-flex align-items-center">
                          <li class="nav-item mr-4">
                            <a class="ws-nav-link" href="/entrar" target="_blank" rel="noopener noreferrer">Sou Cliente</a>
                          </li>
                          <a class="ws-button pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.header_cta}</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </nav>`
    } else if (this.application.size == `medium` || this.application.size == `small`) {
      navBar = `<nav class="navbar box-shadow ws-nav-bar">
                  <a class="navbar-brand mr-5 " href="/">
                    <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                  </a>
                  <a class="nav-button" data-action="click->${this.controllerName}#toggleClass"><span id="nav-icon3"><span></span><span></span><span></span><span></span></span></a>
                </div>
                <div class="fixed-top main-menu">
                  <div class="flex-center p-5">
                    <ul class="nav flex-column">
                      <li class="nav-item delay-3"><a class="nav-link a-dark" href="/medschool">MedSchool</a></li><br>
                      <li class="nav-item delay-3"><a class="nav-link a-dark" href="/descanso-medico">Descanso Médico</a></li><br>
                      <li class="nav-item delay-3"><a class="nav-link a-dark" href="/entrar">Entrar</a></li><br>

                      <li class="nav-item delay-4">

                        <a class="ws-button mws-button pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${this.application.site.header_cta}</a>

                      </li>
                    </ul>
                  </div>
                </nav>`
    }

    var html = `<header>
                  ${navBar}
                </header>`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--events--attendees--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--events--publics--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    }
  }

  toggleClass() {
    if (document.body.classList.contains(`nav-open`)) {
      document.body.classList.remove(`nav-open`)
    } else {
      document.body.classList.add(`nav-open`)
    }
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  doBackupHtml() {
    // `<li class="nav-item mr-4">
    //   <a class="ws-nav-link" href="/medschool" target="_blank" rel="noopener noreferrer">MedSchool</a>
    // </li>`

    var html = `<header>
                  <nav class="navbar navbar-expand-lg ws-nav-bar">
                    <div class="container">
                      <a class="navbar-brand mr-5 " href="/">
                        <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/logoMedcapital.png" width="200" alt="Logo MedCapital">
                      </a>
                      <div class="collapse navbar-collapse">
                        <ul class="navbar-nav mr-auto">
                          
                          <li class="nav-item">
                            <a class="ws-nav-link" href="/descanso-medico" target="_blank" rel="noopener noreferrer">Descanso Médico</a>
                          </li>
                        </ul>
                        <div>
                          <ul class="navbar-nav d-flex align-items-center">
                            <li class="nav-item mr-4">
                              <a class="ws-nav-link" href="/entrar" target="_blank" rel="noopener noreferrer">Sou Cliente</a>
                            </li>
                            <a class="ws-button" href="">Agendar Consultoria</a>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </header>`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.getControllerByIdentifier(`web--site--principal--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`)) {
      this.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    } else if (this.getControllerByIdentifier(`web--operations--products--bookings--validations--main`)) {
      this.getControllerByIdentifier(`web--operations--products--bookings--validations--main`).contentTarget.insertAdjacentHTML("beforeend", html)
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
