import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "save", "view", "content", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--add--dashboard`
  }

  doReceivablesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 px-0" data-${this.controllerName}-target="content" data-controller="operations--products--clinics--financials--add--show
                                                                                                         operations--products--clinics--financials--add--save"></div>
                </div>`
                
    // this.getControllerByIdentifier("operations--products--clinics--financials--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--show--submenu`).dashboardToggleBtn(`NOTA FISCAL`)

      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`).permission = `medclinic_financial_receivables`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`).taker_permission = `medclinic_financial_takers`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).permission = `medclinic_financial_receivables`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--add--show`).integration_permission = `medclinic_financial_integration_invoices`
    })
  }

  emitReceivable() {
    this.doReceivablesDashboard()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
