import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.event_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.application.eventUpdateChannel = createChannel({ channel: 'Commercial::Marketing::Event::LiveChannel', id: this.update_room.id, user_id: this.application.current_user_id, visit_id: this.application.current_visit_id }, {
      connected() {
        controller.updateListen()
        if (controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat")) {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").connectionOn()
        }
      },
      received(data) {
        if (data.kind == "event") {
          controller.application.event = data.cln
          if (controller.application.event.finished) {
            window.open(controller.application.event.thank_you_path, '_self')
          } else {
            controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--dashboard").setLink()
            controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--streaming").setStreaming()
          }
        }
      },
      disconnected() {
        if (controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat")) {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").connectionOff()
        }
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.application.eventChatChannel = createChannel({ channel: 'Commercial::Marketing::Event::ChatChannel', id: this.chat_room.id, user_id: this.application.current_user_id, visit_id: this.application.current_visit_id }, {
      connected() {
        controller.chatListen()
        if (controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat")) {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").connectionOn()
        }
      },
      received(data) {
        controller.application.messages = data.cln
        controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").doMessageTable()
        controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").request(true)
      },
      disconnected() {
        if (controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat")) {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").connectionOff()
        }
      }
    });
  }

  getRoom() {
    var data = { event: { id: this.application.event.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/commercial/marketing/events/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.update_room = response.data.cln.update
          controller.chat_room = response.data.cln.chat
          controller.application.chat_room = response.data.cln.chat
          controller.application.update_room = response.data.cln.update
          controller.broadcastUpdateChannel()
          controller.broadcastChatChannel()
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--dashboard").getMessages(controller.chat_room.id)
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  updateListen() {
    if (this.application.eventUpdateChannel) {
      this.application.eventUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.application.eventChatChannel) {
      this.application.eventChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
