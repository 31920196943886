import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "saveBtn", "nameInput", "emailInput", "councilInput", "councilStateInput",
                    "prefixInput", "phoneInput", "notesInput", "linkInput", "sourceDropdown", "sourceDropdownBtn", "sourceInput", "sourceList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "formCardBody", "landlineCheckbox", "mobileCheckbox",
                    "errorMessage", "accountInput", "accountModal"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--save`
  }

  doFormHtml() {
    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065 pointer" data-action="click->${this.controllerName}#saveSalesLead" data-${this.controllerName}-target="saveBtn">Salvar SQL</button>`

    var html = `<div class="card" style="width:100%;display:relative;">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-8 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Fonte</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sourceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sourceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sourceList" data-app--helpers--search-target="searchList">
                                  <li data-source="base" data-source-type="base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Base</li>
                                  <li data-source="landing" data-source-type="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing Page</li>
                                  <li data-source="referrer" data-source-type="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                  <li data-source="visit" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Site</li>
                                  <li data-source="event" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Evento</li>
                                  <li data-source="direct" data-source-type="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Direto</li>
                                  <li data-source="outbound" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                  <li data-source="google" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Google</li>
                                  <li data-source="facebook" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Facebook</li>
                                  <li data-source="google_ads" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Google Ads</li>
                                  <li data-source="facebook_ads" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Facebook Ads</li>
                                  <li data-source="linkedin" data-source-type="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">LinkedIn</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-8 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="emailForm">E-mail</label>
                            <input aria-describedby="emailFormHelp" class="form-control" id="emailForm" data-${this.controllerName}-target="emailInput" placeholder="E-mail" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Estágio</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="in_process" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Processo</li>
                                  <li data-status="gain" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ganho</li>
                                  <li data-status="lost" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Perdido</li>
                                  <li data-status="not_contact" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Contactado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-3 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="councilForm">CRM</label>
                            <input aria-describedby="councilFormHelp" class="form-control" id="councilForm" data-${this.controllerName}-target="councilInput" data-action="blur->${this.controllerName}#crmMask  keypress->${this.controllerName}#crmMask keyup->${this.controllerName}#crmMask" placeholder="CRM" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pl-2 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="councilStateForm">UF CRM</label>
                            <input aria-describedby="councilStateFormHelp" class="form-control" id="councilStateForm" data-${this.controllerName}-target="councilStateInput" placeholder="UF CRM" type="text" required>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div class="row my-2">
                      <div class="col-6 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                          <input type="checkbox" class="custom-control-input" id="landlineCheck" data-${this.controllerName}-target="landlineCheckbox" data-kind="landline" data-action="click->${this.controllerName}#checkPhone">
                          <label class="custom-control-label f-065" for="landlineCheck">Fixo</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                          <input type="checkbox" class="custom-control-input" id="mobileCheck" data-${this.controllerName}-target="mobileCheckbox" data-kind="mobile" data-action="click->${this.controllerName}#checkPhone">
                          <label class="custom-control-label f-065" for="mobileCheck">Celular</label>
                        </div>
                      </div>
                      <div class="col-2 pl-2 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="prefixForm">DDD</label>
                            <input aria-describedby="prefixFormHelp" class="form-control" id="prefixForm" data-${this.controllerName}-target="prefixInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="DDD" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="phoneForm">Telefone</label>
                            <input aria-describedby="phoneFormHelp" class="form-control" id="phoneForm" data-${this.controllerName}-target="phoneInput" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask" placeholder="Número" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2 d-flex align-items-center">
                      <div class="col-1 text-right px-0">
                        <button data-action="click->${this.controllerName}#showAccounts" data-element="accountInput" data-${this.controllerName}-target="accountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Centros de Custos</span>
                        </button>
                      </div>
                      <div class="col-11">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="accountForm">Cliente</label>
                            <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="accountInput" type="text" placeholder="Cliente" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notesForm">Anotações</label>
                            <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="3"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>       
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <span class="mr-auto text-left f-danger my-auto w-50" data-${this.controllerName}-target="errorMessage"></span>
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065 pointer" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (this.actionMode == `new` || this.actionMode == `admisssion`) {
        controller.viewTitleTarget.innerText = `Novo SQL`
        controller.sourceInputTarget.innerText = ``
        controller.sourceInputTarget.dataset.source = ``
        controller.sourceDropdownTarget.value = ``

        controller.statusInputTarget.innerText = `Não Contactado`
        controller.statusInputTarget.dataset.status = `not_contact`
        controller.statusDropdownTarget.value = `not_contact`
        controller.statusDropdownBtnTarget.disabled = true

        if (controller.actionMode == `admisssion`) {
          controller.sourceInputTarget.innerText = `Evento`
          controller.sourceInputTarget.dataset.source = `event`
          controller.sourceDropdownTarget.value = `event`  
          controller.sourceDropdownBtnTarget.disabled = true

          controller.nameInputTarget.value = controller.current_admission.name
          controller.emailInputTarget.value = controller.current_admission.email

          if (controller.current_admission.kind == `kind` && controller.current_admission.crm) {
            controller.councilInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").crmMask(controller.current_admission.crm)
            controller.councilStateInputTarget.value = controller.current_admission.crm_state
          }

          controller.prefixInputTarget.value = controller.current_admission.ddd
          controller.phoneInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").phoneMask(controller.current_admission.number)
          controller.notesInputTarget.value = `Evento ${controller.application.event.title} realizado no dia ${controller.application.event.date_pretty}`
        }

      } else if (controller.actionMode == `edit`) {
        controller.viewTitleTarget.innerText = `Editando SQL: ${controller.current_lead.name}`

        controller.sourceInputTarget.innerText = controller.current_lead.primary_source_pretty
        controller.sourceInputTarget.dataset.source = controller.current_lead.primary_source
        controller.sourceDropdownTarget.value = controller.current_lead.primary_source
        // controller.sourceDropdownBtnTarget.disabled = true

        controller.nameInputTarget.value = controller.current_lead.name
        controller.emailInputTarget.value = controller.current_lead.email
        controller.councilInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").crmMask(controller.current_lead.council_number)
        controller.councilStateInputTarget.value = controller.current_lead.council_state
        controller.prefixInputTarget.value = controller.current_lead.prefix
        controller.phoneInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").phoneMask(controller.current_lead.phone)
        // controller.linkInputTarget.value = controller.current_lead.link
        controller.notesInputTarget.value = controller.current_lead.notes

        controller.statusInputTarget.innerText = `Não Contactado`
        controller.statusInputTarget.dataset.status = `not_contact`
        controller.statusDropdownTarget.value = `not_contact`
        controller.statusDropdownBtnTarget.disabled = true

        if (controller.current_lead.has_account) {
          controller.accountInputTarget.value = controller.current_lead.account_name
          controller.accountInputTarget.dataset.accountId = controller.current_lead.account_id
        }

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  checkPhone(ev) {
    this.phoneKind = ev.currentTarget.dataset.kind

    if (this.phoneKind == `landline`) {
      this.phoneLen = 10
      this.landlineCheckboxTarget.checked = true
      this.mobileCheckboxTarget.checked = false
    } else if (this.phoneKind == `mobile`) {
      this.phoneLen = 11
      this.landlineCheckboxTarget.checked = false
      this.mobileCheckboxTarget.checked = true
    }
  }

  crmMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCrm(ev)
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.getControllerByIdentifier(`commercial--sales--leads--entities--list`)) {
      this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).clearList()
    } else if (this.getControllerByIdentifier(`commercial--marketing--events--admissions--list`)) {
      this.getControllerByIdentifier(`commercial--marketing--events--admissions--list`).clearList()
    }
  }

  prefixMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  showAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `operations--products--accounts--products--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).feature = `sales_opportunities_leads`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  saveSalesLead() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    this.send_data = { current_user: {}, lead: {}, source: {} }

    if (this.actionMode == `new` || this.actionMode == `admisssion`) {
      this.send_data.source.source = this.sourceInputTarget.dataset.source
      this.send_data.source.source_type = this.sourceInputTarget.dataset.sourceType
      this.send_data.lead.status = this.statusInputTarget.dataset.status
      
      if (this.actionMode == `admisssion`) {
        this.send_data.source.kind = `event_admission`
      }


    } else if (this.actionMode == `edit`) {
      this.send_data.lead.id = this.current_lead.id
    }
    

    this.send_data.lead.name = this.nameInputTarget.value.trim()
    this.send_data.lead.primary_source = this.sourceInputTarget.dataset.source
    this.send_data.lead.email = this.emailInputTarget.value.trim().toLowerCase()
    this.send_data.lead.council_type = `CRM`
    this.send_data.lead.council_number = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.councilInputTarget.value.trim())
    this.send_data.lead.council_state = this.councilStateInputTarget.value.trim().toUpperCase()
    this.send_data.lead.prefix = this.prefixInputTarget.value.trim()
    this.send_data.lead.phone = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value.trim())
    this.send_data.lead.notes = this.notesInputTarget.value.trim()
    this.send_data.lead.link = ``
    this.send_data.lead.account_id = this.accountInputTarget.dataset.accountId

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new` || this.actionMode == `admisssion`) {
      var url = "/commercial/sales/leads/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {  
      var url = "/commercial/sales/leads/entities/update"
      var method = "PUT"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var lead = response.data.cln

          if (controller.actionMode == "new") {
            controller.application.leads[controller.application.leads.length] = lead
          } else if (controller.actionMode == "edit") {
            if (lead.active) {
              controller.application.leads.forEach((element, i) => {
                if (element.id == lead.id) {
                  controller.application.leads.splice(controller.application.leads.indexOf(element), 1, lead)
                }
              })
            } else {
              controller.application.leads.forEach((element, i) => {
                if (element.id == lead.id) {
                  controller.application.leads.splice(controller.application.leads.indexOf(element), 1)
                }
              })
            }
          }

          controller.cancelSave()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasFormCardBodyTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.emailInputTarget.value == ``) {
          len += 1
        }

        if (controller.councilInputTarget.value == ``) {
          len += 1
        }
        if (controller.councilStateInputTarget.value == ``) {
          len += 1
        }

        if (controller.prefixInputTarget.value == ``) {
          len += 1
        }
        if (controller.phoneInputTarget.value == ``) {
          len += 1
        } else {
          var uniqPhone = `${controller.prefixInputTarget.value}${controller.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(controller.phoneInputTarget.value)}`
          var uniqPhoneLen = uniqPhone.length
          if (controller.phoneKind == `landline`) {
            if (uniqPhoneLen != 10) {
              len += 1
              controller.setErrorMessage(`Telefone está errado`)
            } else {
              controller.setErrorMessage(``)
            }
          } else if (controller.phoneKind == `mobile`) {
            if (uniqPhoneLen != 11) {
              len += 1
              controller.setErrorMessage(`Telefone está errado`)
            } else {
              controller.setErrorMessage(``)
            }
          }
        }

        if (controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.statusInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.notesInputTarget.value == ``) {
          len += 1
        }

        if (controller.landlineCheckboxTarget.checked == false && controller.mobileCheckboxTarget.checked == false) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
