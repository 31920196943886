import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "saveBtn", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "cnaeCodeDropdown", "cnaeCodeDropdownBtn", "cnaeCodeInput", "cnaeCodeList", "ctissShortInput",
                    "medDropdown", "medDropdownBtn", "medInput", "medList",
                    "cnaeCodeNumberInput", "ctissCodeInput", "cnaeDescriptionInput", "ctissDescriptionInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "cityCodeInput", "federalCodeInput",
                    "medCity"]


  connect() {
    this.controllerName = `financials--books--dashboards--activities--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    // this.getControllerByIdentifier("financials--books--dashboards--activities--index").clearList()
  }

  saveActivity() {
    this.send_data = { current_user: {}, activity: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.activity.id = this.current_activity.id
    }

    this.send_data.activity.med_id = this.current_med.id
    this.send_data.activity.cnae_code = this.cnaeCodeNumberInputTarget.value
    this.send_data.activity.cnae_description = this.cnaeDescriptionInputTarget.value
    this.send_data.activity.ctiss_code = this.cityCodeInputTarget.value
    this.send_data.activity.ctiss_short = this.cityCodeInputTarget.value
    this.send_data.activity.ctiss_description = this.ctissDescriptionInputTarget.value
    this.send_data.activity.kind = this.kindInputTarget.dataset.kind
    this.send_data.activity.status = this.statusInputTarget.dataset.status
    this.send_data.activity.city_service_code = this.cityCodeInputTarget.value.trim()
    this.send_data.activity.federal_service_code = this.federalCodeInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/financials/config/activities/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/financials/config/activities/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.activities = response.data.cln
        }

        controller.cancelSave()
        controller.getControllerByIdentifier("financials--books--dashboards--activities--index").doIndexListHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    var html1 = `<div class="col-12 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Atividade Econômica</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="cnaeCodeDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="cnaeCodeDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="cnaeCodeInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="cnaeCodeList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>`

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveActivity" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">

                    <div class="row my-2">
                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>MedCapital</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="medDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="medDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="medInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="medList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="main" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Principal</li>
                                  <li data-kind="secondary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Secundário</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Inativo</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 px-1">
                        <button data-action="click->${this.controllerName}#showActivities" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Atividades Econômicas</span>
                        </button>
                      </div>
                      <div class="col-11 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Atividades Econômicas</label>
                            <input class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="cnaeCodeInput" data-filter-mode="simple" type="text" placeholder="Atividades Econômicas" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnaeCodeNumberForm">Código CNAE</label>
                            <input disabled aria-describedby="cnaeCodeNumberFormHelp" class="form-control form-valid-control" id="cnaeCodeNumberForm" data-${this.controllerName}-target="cnaeCodeNumberInput" placeholder="Código CNAE" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-none">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ctissCodeForm">Código CTISS</label>
                            <input disabled aria-describedby="ctissCodeFormHelp" class="form-control form-valid-control" id="ctissCodeForm" data-${this.controllerName}-target="ctissCodeInput" placeholder="Código CTISS" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-none">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ctissShortForm">Código CTISS</label>
                            <input disabled aria-describedby="ctissShortFormHelp" class="form-control form-valid-control" id="ctissShortForm" data-${this.controllerName}-target="ctissShortInput" placeholder="Código CTISS" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-8 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnaeDescriptionForm">Descrição CNAE</label>
                            <input disabled aria-describedby="cnaeDescriptionFormHelp" class="form-control form-valid-control" id="cnaeDescriptionForm" data-${this.controllerName}-target="cnaeDescriptionInput" placeholder="Descrição CNAE" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <span class="f-075 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="medCity"></span>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cityCodeForm">Código Municipal</label>
                            <input disabled aria-describedby="cityCodeFormHelp" class="form-control form-valid-control" id="cityCodeForm" data-${this.controllerName}-target="cityCodeInput" placeholder="Código Municipal" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="federalCodeForm">Código Federal</label>
                            <input disabled aria-describedby="federalCodeFormHelp" class="form-control form-valid-control" id="federalCodeForm" data-${this.controllerName}-target="federalCodeInput" placeholder="Código Federal" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ctissDescriptionForm">Descrição</label>
                            <input disabled aria-describedby="ctissDescriptionFormHelp" class="form-control form-valid-control" id="ctissDescriptionForm" data-${this.controllerName}-target="ctissDescriptionInput" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--activities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Atividade Econômica`

        controller.statusDropdownTarget.value = `active`
        controller.statusInputTarget.dataset.status = `active`
        controller.statusInputTarget.innerText = `Ativo`

        controller.statusDropdownBtnTarget.disabled = true

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Atividade Econômica`
        controller.kindDropdownTarget.value = controller.current_activity.kind
        controller.kindInputTarget.dataset.kind = controller.current_activity.kind
        controller.kindInputTarget.innerText = controller.current_activity.kind_pretty

        controller.statusDropdownTarget.value = controller.current_activity.status
        controller.statusInputTarget.dataset.status = controller.current_activity.status
        controller.statusInputTarget.innerText = controller.current_activity.status_pretty

        // controller.cnaeCodeDropdownTarget.value = controller.current_activity.cnae_code
        // controller.cnaeCodeInputTarget.dataset.cnaeCode = controller.current_activity.cnae_code
        // controller.cnaeCodeInputTarget.innerText = `${controller.current_activity.cnae_code} - ${controller.current_activity.cnae_description}`
        controller.cnaeCodeInputTarget.value = `${controller.current_activity.cnae_code} - ${controller.current_activity.cnae_description}`

        controller.cnaeCodeNumberInputTarget.value = controller.current_activity.cnae_code
        // controller.ctissCodeInputTarget.value = controller.current_activity.ctiss_code
        // controller.ctissShortInputTarget.value = controller.current_activity.ctiss_short
        controller.cnaeDescriptionInputTarget.value = controller.current_activity.cnae_description
        controller.ctissDescriptionInputTarget.value = controller.current_activity.ctiss_description
        controller.cityCodeInputTarget.value = controller.current_activity.city_service_code
        controller.federalCodeInputTarget.value = controller.current_activity.federal_service_code
      }

      controller.ctissDescriptionInputTarget.disabled = true
      controller.cityCodeInputTarget.disabled = true
      controller.federalCodeInputTarget.disabled = true

      controller.setMedList()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setMedList() {
    var listHtml = ``

    this.application.meds.forEach(element => {
      listHtml += `<li data-id="${element.id}" data-action="click->${this.controllerName}#setMed click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
    })

    this.medListTarget.innerHTML = listHtml
  }

  setMed(ev) {
    var medId = ev.currentTarget.dataset.id

    this.application.meds.forEach(element => {
      if (element.id == medId) {
        this.current_med = element
      }
    })

    var address = this.current_med.address_list[0]
    this.medCityTarget.innerText = `Município de ${address.city}/${address.state}`
  }


  // listActivities() {
  //   var listHtml = ``
  //   var orderedActivities = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.all_activities, `cnae_code`)
  //   orderedActivities.forEach(element => {
  //     listHtml += `<li data-cnae-code="${element.cnae_code}" data-action="click->${this.controllerName}#setActitivies click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.cnae_code} - ${element.cnae_description}</li>`
  //   })
  //   this.cnaeCodeListTarget.innerHTML = listHtml
  // }

  // setActitivies(ev) {
  //   var cnaeCode = ev.currentTarget.dataset.cnaeCode
  //   var activity = {}
  //   this.application.all_activities.forEach(element => {
  //     if (element.cnae_code == cnaeCode) {
  //       activity = element
  //     }
  //   })
  //   this.cnaeCodeNumberInputTarget.value = activity.cnae_code
  //   this.ctissCodeInputTarget.value = activity.ctiss_code
  //   this.ctissShortInputTarget.value = activity.ctiss_short
  //   this.cnaeDescriptionInputTarget.value = activity.cnae_description
  //   this.ctissDescriptionInputTarget.value = activity.ctiss_description
  //   this.cityCodeInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(activity.city_service_code)
  //   this.federalCodeInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(activity.federal_service_code)
  //   this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  // }

  showActivities() {
    var modalName = `operations--products--clinics--regularizations--cnaes--modal`

    var html = `<div class="modal fade" data-hour="bottom" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).address = this.current_med.address_list[0]
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.cnaeCodeInputTarget.value == ``) {
        len += 1
      }

      if (controller.cityCodeInputTarget.value == ``) {
        len += 1
      }

      if (controller.federalCodeInputTarget.value == ``) {
        len += 1
      }

      if (controller.ctissDescriptionInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }
  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
