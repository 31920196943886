import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--partners--index`
    this.tableCols = 13
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    this.hasShow = false
  }

  showPartner(ev) {
    var controllerShow = this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--show")
    var partnerId = ev.target.closest(".itemRow").dataset.id

    this.application.partners.forEach(element => {
      if (element.id == partnerId) {
        controllerShow.current_partner = element
        this.application.account_contact = {}
        if (element.id_type == `individual`) {
          this.application.account_contact.account_id = element.record_id
          this.application.account_contact.record_type = `account_entities`
        } else if (element.id_type == `legal`) {
          this.application.account_contact.company_id = element.record_id
          this.application.account_contact.record_type = `company_entities`
        }
      }
    })

    controllerShow.doPartnerHtml()
  }

  addPartner() {
    this.showClearBtn()

    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").actionMode = "new"
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").current_partner = {}
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").doFormHtml()
  }

  editPartner(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.partners.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").current_partner = element
      }
    })
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addPartner" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Sócio</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshPartners" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Sócios</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Sócios</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">CPF</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Data de Entrada</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Cotas</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">emergency</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">É Médico?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">manage_accounts</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">É Administrador?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">account_balance</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">É Representante Legal?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">engineering</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">É Operacional?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">price_check</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">Tem Acesso Financeiro?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">
                                <span class="mc-tooltip">
                                  <span class="material-icons md-sm">biotech</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-bottom">É Responsável Técnico?</span>
                                </span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Conselho</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_partners = this.application.partners
    
    if (this.sort.mode == `asc`) {
      var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_partners, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_partners, this.sort.field)
    }

    partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(partners, `status`)

    if (partners.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(partners.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (partners[x] !== undefined) {
            internPage[internPage.length] = partners[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: partners.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Sócios</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.partnerTablePartial(element, data.length))
      });
    }

  }

  partnerTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }


    if (element.councils.length > 0) {
      var councilStatus = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Tem Conselho Ativo</span></span>`
    } else {
      var councilStatus = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Sem Conselho Cadastrado</span></span>`
    }

    if (element.doctor) {
      // var doctor = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Médico</span></span>`
      var doctor = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Médico</span></span>`
    } else {
      // var doctor = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">Não Médico</span></span>`
      var doctor = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não Médico</span></span>`
    }

    if (element.administrative) {
      var administrative = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Administrador</span></span>`
    } else {
      var administrative = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não é Administrador</span></span>`
    }

    if (element.legal) {
      var legal = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Representante Legal</span></span>`
    } else {
      var legal = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não é Representante Legal</span></span>`
    }

    if (element.operational) {
      var operational = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Emite NF</span></span>`
    } else {
      var operational = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não Emite NF</span></span>`
    }

    if (element.financial) {
      var financial = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Tem Acesso Financeiro</span></span>`
    } else {
      var financial = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não Tem Acesso Financeiro</span></span>`
    }

    if (element.technical) {
      var technical = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">Responsável Técnico</span></span>`
    } else {
      var technical = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">Não é Responsável Técnico</span></span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showPartner">
                      <span class="mc-tooltip">
                        <span>${element.name}</span>
                        <span class="mc-tooltiptext">Clique para selecionar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.id_number}" data-action="click->app--helpers--copy#copy">${element.id_number_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.entried_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").numberMask(element.shares)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${doctor}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${administrative}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${legal}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${operational}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${financial}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${technical}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${councilStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.status_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--show").doViewHtml()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--save").stopRefreshing()
    this.doDataTable()
  }

  refreshPartners() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    
    var data = { partner: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_partners` } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.partners = response.data.cln
        }

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
