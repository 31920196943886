import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "saveBtn", "cpfCheckbox", "cnpjCheckbox", "amount", "date", "description",
                    "chartAccountDropdown", "chartAccountDropdownBtn", "chartAccountInput", "chartAccountList", "totalAmount", "split",
                    "idNameInput", "idNumberInput", "cnpjInput", "cnpj", "cpfInput", "cpf", "idNameFilter", "idNameFilterItem",
                    "upload", "labelUpload", "progressUpload", "progressUploadBar", "progressUploadCounter",
                    "fileName", "cancelUploadBtn", "showUpload", "fileNameUploadShow", "uploadUrl", "installment",
                    "formDropdown", "formDropdownBtn", "formInput", "formList", "uploadFile"]


  connect() {
    this.controllerName = `operations--products--bookings--payments--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--bookings--payments--show").doViewHtml()
    this.getControllerByIdentifier("operations--products--bookings--payments--index").cleanCheckBox()
  }

  kindCheck(ev) {
    if (this.hasIdNumberInputTarget) {
      this.idNumberInputTarget.remove()
    }
    if (ev.target.dataset.kind == "cpf") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (ev.target.dataset.kind == "cnpj") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  savePayment() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, payment: {}, notification: {}, tracker: { log: true } }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    
    if (this.actionMode == "edit") { this.send_data.payment.id = this.current_payment.id }
    this.send_data.payment.booking_id = this.application.booking.id
    this.send_data.payment.date_id = this.application.current_date.id
    this.send_data.payment.chart_id = this.chartAccountInputTarget.dataset.id
    this.send_data.payment.chart = this.chartAccountInputTarget.innerText
    this.send_data.payment.date = this.getControllerByIdentifier("app--helpers--date").transformFullMobileDate(this.dateTarget.value)
    this.send_data.payment.total_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountTarget.value)
    this.send_data.payment.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountTarget.value)
    this.send_data.payment.split = this.splitTarget.value
    this.send_data.payment.installment = this.installmentTarget.value
    this.send_data.payment.form = this.formInputTarget.dataset.form
    this.send_data.payment.description = this.descriptionTarget.value

    this.send_data.payment.id_name = this.idNameInputTarget.value
    if (this.cpfCheckboxTarget.checked) {
      this.send_data.payment.id_type = "individual"
      this.send_data.payment.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfTarget.value)
    } else if (this.cnpjCheckboxTarget.checked) {
      this.send_data.payment.id_type = "legal"
      this.send_data.payment.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cnpjTarget.value)
    }

    this.send_data.tracker.record_id = this.application.booking.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = this.actionMode
    this.send_data.tracker.observations = `${this.send_data.payment.description} no valor de ${this.send_data.payment.total_amount} do Beneficiário ${this.send_data.payment.id_name} | Livro-Caixa ${this.application.booking.account_name} na ${this.application.current_date.name}`

    this.send_data.notification.domain_id = this.application.booking.id
    this.send_data.notification.domain_type = "operation_products"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "operation_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o pagamento ${this.chartAccountInputTarget.innerText}, no valor de ${this.amountTarget.value} para a competência de ${this.application.current_date.month_pretty}.`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/bookings/payments/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/bookings/payments/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var payment = response.data.cln

          if (controller.file) {
            controller.requestUpload(payment)
          } else {
            if (controller.actionMode == "new") {
              controller.application.payments[controller.application.payments.length] = payment
            } else if (controller.actionMode == "edit") {
              if (payment.active) {
                controller.application.payments.forEach((element, i) => {
                  if (element.id == payment.id) {
                    controller.application.payments.splice(controller.application.payments.indexOf(element), 1, payment)
                  }
                })
              } else {
                controller.application.payments.forEach((element, i) => {
                  if (element.id == payment.id) {
                    controller.application.payments.splice(controller.application.payments.indexOf(element), 1)
                  }
                })
              }
            }
            controller.cancelSave()
            controller.getControllerByIdentifier("operations--products--bookings--payments--index").doDataTable()
          }

        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestUpload(payment) {
    var formData = new FormData()
    formData.append('file', this.file)
    formData.append('payment_id', payment.id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/operations/products/bookings/payments/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

    this.progressUploadTarget.classList.remove("d-none")
    this.progressUploadCounterTarget.classList.remove("d-none")
    this.labelUploadTarget.classList.remove("btn-primary")
    this.labelUploadTarget.classList.add("btn-disabled")
    this.progressCount(0)
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          var payment = response.data.cln
          if (controller.actionMode == "new") {
            controller.application.payments[controller.application.payments.length] = payment
          } else if (controller.actionMode == "edit") {
            if (payment.active) {
              controller.application.payments.forEach((element, i) => {
                if (element.id == payment.id) {
                  controller.application.payments.splice(controller.application.payments.indexOf(element), 1, payment)
                }
              })
            } else {
              controller.application.payments.forEach((element, i) => {
                if (element.id == payment.id) {
                  controller.application.payments.splice(controller.application.payments.indexOf(element), 1)
                }
              })
            }
          }
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--bookings--payments--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medbooking_payments.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayment" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions.medbooking_payments.can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deletePayment" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }
    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6 pr-2 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome do Beneficiário</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-filter-mode="simple" data-${this.controllerName}-target="idNameInput" data-action="focus->${this.controllerName}#idNameFilter keyup->${this.controllerName}#idNameFilter blur->${this.controllerName}#hideList" placeholder="Nome do Beneficiário" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="idNameFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="paymentCpfCheck" data-kind="cpf" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="paymentCpfCheck">CPF</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="paymentCnpjCheck" data-kind="cnpj" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="paymentCnpjCheck">CNPJ</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-5 pr-2 pl-0 d-flex align-items-center" data-${this.controllerName}-target="idNumberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                      <div class="col-5 pr-2 pl-0 d-none" data-${this.controllerName}-target="cnpjInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ do Beneficiário</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="CNPJ do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 pr-2 pl-0 d-none" data-${this.controllerName}-target="cpfInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF do Beneficiário</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pl-2 pr-2">
                        <div class="form-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="datePaymentForm">Data</label>
                            <input aria-describedby="datePaymentFormHelp" class="form-control form-valid-control" id="datePaymentForm" data-${this.controllerName}-target="date" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pr-0 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Forma</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="formDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="formDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="formInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="formList" data-app--helpers--search-target="searchList">
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Dinheiro" data-form="money" class="li-selector dark f-065">Dinheiro</li>
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Débito" data-form="debt" class="li-selector dark f-065">Cartão de Débito</li>
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Crédito" data-form="credit" class="li-selector dark f-065">Cartão de Crédito</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-3 pr-2 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalAmountForm">Valor Total</label>
                            <input aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Total" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pr-2 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="splitForm"># Parcelas</label>
                            <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="split" data-action="blur->${this.controllerName}#splitAmount keyup->${this.controllerName}#splitAmount" placeholder="Parcelas" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pr-2 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor Parcela</label>
                            <input disabled aria-describedby="amountFormHelp" class="form-control form-valid-control" id="amountForm" data-${this.controllerName}-target="amount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Pago" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pr-0 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="installmentForm">Parcela</label>
                            <input disabled aria-describedby="installmentFormHelp" class="form-control form-valid-control" id="installmentForm" data-${this.controllerName}-target="installment" placeholder="Parcelas" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      
                      <div class="col-12 pr-0 pl-0">
                        <div class="form-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="description" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 pr-0 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Classifique o Pagamento</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="chartAccountDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="chartAccountDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="chartAccountInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="chartAccountList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="upload">
                        <div class="form-group my-0 text-center">
                          <input type="file" id="payment" name="payment" class="file-input" data-action="change->${this.controllerName}#saveUpload">
                          <label for="payment" id="paymentLabelForm" class="btn btn-primary btn-sm text-left px-1 f-065" data-${this.controllerName}-target="labelUpload" style="font-size:80%;">Comprovante</label>
                        </div>
                      </div>
                      <div class="col-7 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="uploadFile">
                        <span data-${this.controllerName}-target="fileName" class="ml-2 mr-auto f-065 my-auto" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"></span>
                      </div>
                      <div class="col-1 my-1 px-0 d-flex align-items-center justify-content-between">
                        <button type="button" class="close ml-3 d-none" data-dismiss="modal" data-action="click->${this.controllerName}#cancelUpload" data-${this.controllerName}-target="cancelUploadBtn" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div class="col-12 px-0 d-flex align-items-center justify-content-between w-100">
                        <div class="progress w-100 d-none" style="height: 6px;" data-${this.controllerName}-target="progressUpload">
                          <div class="progress-bar" role="progressbar" style="height: 6px;width:20%;border-bottom:0.5rem solid #053B5E;" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                        </div>
                        <span class="d-none" data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                      </div>


                      <div class="col-12 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="showUpload">
                        <span data-${this.controllerName}-target="fileNameUploadShow" class="d-block f-065"></span>
                        <button data-action="click->${this.controllerName}#goToUpload" data-${this.controllerName}-target="uploadUrl" data-url="" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>
                        <button data-action="click->${this.controllerName}#editUpload" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">edit</span>
                          <span class="mc-tooltiptext">Editar</span>
                        </button>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--payments--dashboard").viewTarget.innerHTML = html)
    }).then(() => {

      // controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(this.dateTarget), { kind: "monthly", max: true, months: true, years: 60 })
      controller.doChartAccountList()
      controller.listIdName()
      if (controller.actionMode == "new") {
        controller.formTitleTarget.innerText = "Novo Pagamento"
        controller.amountTarget.value = "R$ 0,00"
        controller.totalAmountTarget.value = "R$ 0,00"
        controller.splitTarget.value = 1
        controller.installmentTarget.value = 1
        controller.uploadTarget.classList.remove("d-none")
        controller.uploadFileTarget.classList.remove("d-none")
        controller.showUploadTarget.classList.add("d-none")
      } else if (controller.actionMode == "edit") {
        controller.formTitleTarget.innerText = "Editando Pagamento"
        controller.idNumberInputTarget.remove()

        controller.idNameInputTarget.value = controller.current_payment.id_name
        controller.dateTarget.value = controller.current_payment.date_pretty
        controller.amountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_payment.amount * 100))
        controller.totalAmountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_payment.total_amount * 100))
        controller.splitTarget.value = controller.current_payment.split
        controller.installmentTarget.value = controller.current_payment.installment
        controller.descriptionTarget.value = controller.current_payment.description
        controller.chartAccountDropdownTarget.value = controller.current_payment.chart
        controller.chartAccountInputTarget.innerText = controller.current_payment.chart
        controller.chartAccountInputTarget.dataset.id = controller.current_payment.chart_id
        controller.chartAccountInputTarget.dataset.chart = controller.current_payment.chart
        controller.formDropdownTarget.value = controller.current_payment.form
        controller.formInputTarget.innerText = controller.current_payment.form_pretty
        controller.formInputTarget.dataset.form = controller.current_payment.form

        controller.dateTarget.disabled = true
        controller.idNameInputTarget.disabled = true
        controller.splitTarget.disabled = true
        controller.totalAmountTarget.disabled = true
        controller.cpfCheckboxTarget.disabled = true
        controller.cnpjCheckboxTarget.disabled = true
        controller.formDropdownBtnTarget.disabled = true
        controller.chartAccountDropdownBtnTarget.disabled = true

        if (controller.current_payment.id_type == "individual") {
          controller.cpfCheckboxTarget.checked = true
          controller.cnpjCheckboxTarget.checked = false
          controller.cpfTarget.disabled = true
          controller.cnpjInputTarget.classList.add("d-none")
          controller.cpfInputTarget.classList.remove("d-none")
          controller.cpfTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cpfMask(controller.current_payment.id_number)
        } else if (controller.current_payment.id_type == "legal") {
          controller.cpfCheckboxTarget.checked = false
          controller.cnpjCheckboxTarget.checked = true
          controller.cnpjTarget.disabled = true
          controller.cnpjInputTarget.classList.remove("d-none")
          controller.cpfInputTarget.classList.add("d-none")
          controller.cnpjTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cnpjMask(controller.current_payment.id_number)
        }
        
        if (controller.current_payment.has_document) {
          controller.uploadTarget.classList.add("d-none")
          controller.cancelUploadBtnTarget.classList.add("d-none")
          controller.showUploadTarget.classList.remove("d-none")
          controller.fileNameUploadShowTarget.innerText = controller.current_payment.document_name
          controller.uploadUrlTarget.dataset.url = controller.current_payment.document_url
        } else {
          controller.uploadTarget.classList.remove("d-none")
          controller.uploadFileTarget.classList.remove("d-none")
          controller.cancelUploadBtnTarget.classList.remove("d-none")
          controller.showUploadTarget.classList.add("d-none")
        }
        
      }
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
 
  }

  doChartAccountList() {
    var listHtml = ``
    var chartAccounts = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.chart_accounts, "code")
    chartAccounts.forEach(element => {
      if (element.master == "expenses") {
        listHtml += `<li data-id="${element.id}" data-group="${element.group}" data-chart="${element.chart}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.chart}</li>`
      }
    });
    this.chartAccountListTarget.innerHTML = listHtml
  }

  goToUpload(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  editUpload(ev) {
    this.uploadTarget.classList.remove("d-none")
    this.cancelUploadBtnTarget.classList.remove("d-none")
    this.showUploadTarget.classList.add("d-none")
  }

  cancelUpload(ev) {
    this.uploadTarget.classList.add("d-none")
    this.cancelUploadBtnTarget.classList.add("d-none")
    this.showUploadTarget.classList.remove("d-none")
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  splitAmount(ev) {
    var split = ev.target.value

    if (split) {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountTarget.value) / split
    } else {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountTarget.value)
    }

    this.amountTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(amount * 100))
  }

  listIdName() {
    var html = ``
    if (this.application.payments.length == 0) {
      html += `<li class="li-selector dark f-065">Não existem pagamentos realizados</li>`
    } else {
      var uniqPayments = []
      var uniqPaymentsElements = []

      this.application.payments.forEach(element => {
        if (uniqPaymentsElements.indexOf(element.id_name) === -1) {
          uniqPaymentsElements[uniqPaymentsElements.length] = element.id_name
          uniqPayments[uniqPayments.length] = element
        }
      });

      uniqPayments.forEach(element => {
        html += `<li data-filter="${element.id}" data-text="${element.id_name}" class="li-selector dark f-065" data-action="click->${this.controllerName}#selectIdName" data-${this.controllerName}-target="idNameFilterItem">${element.id_name}</li>`
      });
    }

    this.idNameFilterTarget.innerHTML = html
  }

  saveUpload(ev) {
    if (ev.target.files.length > 0) {
      this.file = ev.target.files[0]
      this.fileNameTarget.innerText = ev.target.files[0].name
    }
  }

  selectIdName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)

    var payment = ``
    this.application.payments.forEach(element => {
      if (element.id == ev.target.dataset.filter) {
        payment = element
      }
    });
    if (this.hasIdNumberInputTarget) {
      this.idNumberInputTarget.remove()
    }

    if (payment.id_type == "individual") {
      this.cpfTarget.value = payment.id_number_pretty
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      this.cnpjInputTarget.classList.add("d-none")
      this.cpfInputTarget.classList.remove("d-none")
    } else if (payment.id_type == "legal") {
      this.cnpjTarget.value = payment.id_number_pretty
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.cnpjInputTarget.classList.remove("d-none")
      this.cpfInputTarget.classList.add("d-none")
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  idNameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfTarget.classList.add("f-danger")
        this.cpfTarget.classList.remove("f-normal")
      } else {
        this.cpfTarget.classList.add("f-normal")
        this.cpfTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfTarget.classList.add("f-normal")
      this.cpfTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjTarget.classList.add("f-danger")
        this.cnpjTarget.classList.remove("f-normal")
      } else {
        this.cnpjTarget.classList.add("f-normal")
        this.cnpjTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjTarget.classList.add("f-normal")
      this.cnpjTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasIdNumberInputTarget) {
        len += 1
      }

      if (controller.idNameInputTarget.value == ``) {
        len += 1  
      }

      if (controller.cnpjCheckboxTarget.checked && controller.cnpjTarget.value == ``) {
        len += 1  
      }

      if (controller.cpfCheckboxTarget.checked && controller.cpfTarget.value == ``) {
        len += 1  
      }

      if (controller.amountTarget.value == ``) {
        len += 1  
      }

      if (controller.totalAmountTarget.value == ``) {
        len += 1
      }

      if (controller.dateTarget.value == ``) {
        len += 1  
      }

      if (controller.formInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.descriptionTarget.value == ``) {
        len += 1  
      }

      if (controller.chartAccountInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.actionMode == "new" && controller.file == undefined) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
