import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "previewCard", "previewTitle", "body"]

  connect() {
    this.controllerName = `financials--books--payables--entities--show-modal`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doPayableHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    this.modalTarget.remove()
  }

  doPayableHtml() {

    this.getControllerByIdentifier(`financials--books--payables--entities--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    var closeBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-action="click->${this.controllerName}#close" type="button">
                            <span class="material-icons f-1p25">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>`

    if ((this.application.permissions.financial_payable_entities.can_delete && this.application.current_date.open && this.current_payable.paid == false) || (this.current_payable.paid && this.application.current_user.super_admin)) {
      var deleteBtnHtml = `<span class="mc-tooltip">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="deletePayableBtn" data-action="click->${this.controllerName}#deletePayable" type="button">
                              <span class="material-icons f-1p25">delete</span>
                            </button>
                            <span class="mc-tooltiptext">Apagar Despesa</span>
                          </span>`
    } else {
      var deleteBtnHtml = ``
    }

    // var deleteBtnHtml = `<span class="mc-tooltip">
    //                         <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="deletePayableBtn" data-action="click->${this.controllerName}#deletePayable" type="button">
    //                           <span class="material-icons f-1p25">delete</span>
    //                         </button>
    //                         <span class="mc-tooltiptext">Apagar Despesa</span>
    //                       </span>`

    var editSubKinds = [`expenses`, `transfers`, `miscellaneous_credits`, `invoice_card`]

    if (this.current_payable.paid == false && editSubKinds.includes(this.current_payable.subkind)) {
      if ((this.application.permissions.financial_payable_entities.can_update && this.application.current_date.open && this.current_payable.paid == false) || (this.current_payable.paid && this.application.current_user.super_admin)) {
        var editBtnHtml = `<span class="mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 px-0" data-${this.controllerName}-target="editPayableBtn" data-action="click->${this.controllerName}#editPayable" type="button">
                              <span class="material-icons f-1p25">edit</span>
                              </button>
                              <span class="mc-tooltiptext">Editar Despesa</span>
                            </span>`
      } else {
        var editBtnHtml = ``
      }
    } else {
      var editBtnHtml = ``
    }

    var copySubKinds = [`expenses`, `transfers`, `miscellaneous_credits`, `refund`, `loan_expenses`]

    if (copySubKinds.includes(this.current_payable.subkind)) {
      var copyBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline btn-sm my-0" data-action="click->${this.controllerName}#copyPayable" type="button">
                            <span class="material-icons md-dark">copy_all</span>
                          </button>
                          <span class="mc-tooltiptext">Duplicar Despesa</span>
                        </span>`
    } else {
      var copyBtnHtml = ``
    }

    if (this.current_payable.method == `bank_slip`) {
      var methodTitle = `Linha Boleto`
      var methodDescription = this.current_payable.bank_line
    } else if (this.current_payable.method == `transfer`) {
      var methodTitle = `Transferência`
      var methodDescription = this.current_payable.bank_account
    } else if (this.current_payable.method == `pix`) {
      var methodTitle = `Chave Pix`
      var methodDescription = `${this.current_payable.pix_kind_pretty} | ${this.current_payable.pix_key}`
    } else if (this.current_payable.method == `credit_card`) {
      var methodTitle = `Cartão Crédito`
      var splitAmount = Number(this.current_payable.amount) / Number(this.current_payable.installment)
      var methodDescription = `${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.amount)} em ${this.current_payable.installment}x de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(splitAmount)}`
    } else if (this.current_payable.method == `cash`) {
      var methodTitle = `Caixa`
      var methodDescription = `Conta Caixa`
    } else if (this.current_payable.method == `bank_debt`) {
      var methodTitle = `Forma`
      var methodDescription = `Débito em Conta`
    } else if (this.current_payable.method == `provision`) {
      var methodTitle = `Provisão Caixa`
      var methodDescription = ``
    }
    
    var borrowerHtml = ``
    if (this.current_payable.subkind == `loan_expenses` || this.current_payable.subkind == `loan_transfer` || this.current_payable.subkind == `loan_prepaid`) {
      borrowerHtml += `<tr>
                        <th colspan="2" class="f-065 align-middle px-0">
                          <strong>Med Mutuária</strong> <br>
                        </th>
                        <td colspan="8" class="f-065 align-middle px-0 text-left">
                          ${this.current_payable.borrower_name}
                        </td>
                      </tr>`
    } else {
      
    }

    var clientsHtml = ``
    // this.application.partners.forEach(element => {
    //   if (this.current_payable.partner_ids.includes(element.id.toString())) {
    //     clientsHtml += `<h6 class="mb-0 f-065" data-${this.controllerName}-target="partnerName-${element.id}">${element.name}</h6>`
    //   }
    // })

    if (this.current_payable.paid) {
      if ((this.current_payable.subkind == `prepaid_expenses` || this.current_payable.subkind == `loan_prepaid`) && this.current_payable.recognized == false) {
        var action = `<span class="f-070 badge badge-danger pointer" data-action="click->${this.controllerName}#recognizePayable" data-id="${this.current_payable.id}">${this.current_payable.recognized_action}</span>`
      } else if (this.current_payable.subkind == `prepaid_expenses` || this.current_payable.subkind == `loan_prepaid`) {
        var action = `<span class="f-070 badge badge-success">${this.current_payable.recognized_action}</span>`
      } else {
        var action = `<span class="f-070 badge badge-success">${this.current_payable.subkind_status}</span>`
      }
    } else {
      var action = `<span class="f-070 badge badge-danger pointer" data-action="click->${this.controllerName}#paidPayable" data-id="${this.current_payable.id}">Não ${this.current_payable.subkind_status}</span>`
    }

    var projectName = ``
    var projectDestroy = ``
    if (this.current_payable.project_id) {
      var projectName = this.current_payable.project_name 
      var projectBtn = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="project" data-mode="edit" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                           <span class="material-icons md-sm md-dark">edit</span>
                           <span class="mc-tooltiptext">Editar Projeto</span>
                         </button>`
      var projectDestroy = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="project" data-mode="delete" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">clear</span>
                              <span class="mc-tooltiptext">Apagar</span>
                            </button>`
    } else {
      var projectBtn = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="project" data-mode="new" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">add</span>
                          <span class="mc-tooltiptext">Adicionar Projeto</span>
                        </button>`
    }

    var costName = ``
    var costDestroy = ``
    if (this.current_payable.cost_id) {
      var costName = this.current_payable.cost_name
      var costBtn = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="cost_center" data-mode="edit" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar Centro de Custo</span>
                      </button>`
      var costDestroy = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="cost_center" data-mode="delete" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Apagar</span>
                      </button>`
    } else {
      var costBtn = `<button data-action="click->${this.controllerName}#updateCosts" data-kind="cost_center" data-mode="new" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">add</span>
                        <span class="mc-tooltiptext">Adicionar Centro de Custo</span>
                      </button>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${copyBtnHtml}
                      ${editBtnHtml}
                      ${deleteBtnHtml}
                      ${closeBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Lançamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.accrual_date_name} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Dt Lançamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.accrual_date_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Vencimento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.due_date_name} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Dt Vencimento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.due_date_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Pagamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.paid_date_name} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Dt Pagamento</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_payable.paid_date_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Principal</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.amount)}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor Total</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_payable.total_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Descrição</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <small class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_payable.description}" data-action="click->app--helpers--copy#copy">${this.current_payable.description}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Conta</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <small class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_payable.chart_name}" data-action="click->app--helpers--copy#copy">${this.current_payable.chart_name}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Fornecedor</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <small class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_payable.provider_name}" data-action="click->app--helpers--copy#copy">${this.current_payable.provider_name}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </small>
                              </td>
                            </tr>
                            ${borrowerHtml}
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Med</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <small class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_payable.med_name}" data-action="click->app--helpers--copy#copy">${this.current_payable.med_name}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Canal</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <small class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_payable.channel_name}" data-action="click->app--helpers--copy#copy">${this.current_payable.channel_name}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </small>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${methodTitle}</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${methodDescription}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="1" class="f-065 align-middle px-0">
                                <strong>Centro de Custo</strong>
                              </th>
                              <th colspan="1" class="f-065 align-middle px-0 text-center">
                                ${costBtn}
                              </th>
                              <td colspan="2" class="f-065 align-middle px-0 text-left">
                                ${costName}
                              </td>
                              <td colspan="1" class="f-065 align-middle px-0 text-center">
                                ${costDestroy}
                              </td>
                              <th colspan="1" class="f-065 align-middle px-0">
                                <strong>Projeto</strong>
                              </th>
                              <th colspan="1" class="f-065 align-middle px-0 text-center">
                                ${projectBtn}
                              </th>
                              <td colspan="2" class="f-065 align-middle px-0 text-left">
                                ${projectName}
                              </td>
                              <td colspan="1" class="f-065 align-middle px-0 text-center">
                                ${projectDestroy}
                              </td>
                            </tr>
                            
                            

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      if (controller.current_payable.paid) {
        var paidPretty = `<span class="f-070 badge badge-secondary-success">Pago</span>`
      } else {
        var paidPretty = `<span class="f-070 badge badge-secondary-danger">Não Pago</span>`
      }

      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerHTML = `<strong>${controller.current_payable.subkind_pretty}</strong> | <strong>${paidPretty}</strong> | ${controller.current_payable.description} | ${action}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  editPayable() {
    this.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = this.current_payable
    this.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "edit"
    this.close()
    this.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
  }

  copyPayable() {
    this.showDate()
    // this.getControllerByIdentifier("financials--books--payables--entities--save").current_payable = this.current_payable
    // this.getControllerByIdentifier("financials--books--payables--entities--save").actionMode = "copy"
    // this.close()
    // this.getControllerByIdentifier("financials--books--payables--entities--save").doFormGridHtml()
  }

  deletePayable() {
    if (this.checkPayable()) {
      this.send_data = { current_user: {}, payable: {} }

      this.send_data.payable.id = this.current_payable.id
      this.send_data.payable.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `financial_payable_entities`

      var r = confirm("Tem certeza que deseja Apagar a Conta a Pagar?")
      if (r) {
        this.requestDestroy()
      }

    }
  }

  checkPayable() {
    return true
  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var url = "/financials/books/payables/entities/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          
          var payable = response.data.cln
          controller.application.payables.forEach((element, i) => {
            if (element.id == payable.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
            }
          })
        }

        controller.getControllerByIdentifier(`financials--books--payables--entities--index`).doDataTable()
        controller.getControllerByIdentifier(`financials--books--payables--dashboard--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.close()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateCosts(ev) {
    var modal = `financials--books--payables--entities--update-costs`
    var kind = ev.currentTarget.dataset.kind
    var mode = ev.currentTarget.dataset.mode

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).kind = kind
      controller.getControllerByIdentifier(modal).feature = `financial_payable_entities`
      controller.getControllerByIdentifier(modal).current_payable = this.current_payable

      if (mode == `delete`) {
        controller.getControllerByIdentifier(modal).savePayable(``, ``)
      } else {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).open()
      }
    })
  }

  paidPayable(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      this.findObj = true
      var payableId = ev.currentTarget.dataset.id
    }

    if (this.application.permissions.financial_payable_entities.can_manage) {
      this.doUpdatePaidModalHtml(payableId)
    }
  }

  doUpdatePaidModalHtml(payableId) {
    var modalController = `financials--books--payables--entities--paid-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Pagamento Contas à Pagar</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updatePaid" data-${modalController}-target="saveBtn"></button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payables--entities--paid-modal`).open(payableId)
    })
  }

  recognizePayable(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      var payableId = ev.currentTarget.dataset.id

      if (this.application.permissions.financial_payable_entities.can_manage) {
        this.doUpdateRecognizedModalHtml(payableId)
      }
    }
  }

  doUpdateRecognizedModalHtml(payableId) {
    var modalController = `financials--books--payables--entities--recognized-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Reconhecimento de Despesa Antecipada</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updateRecognized" data-${modalController}-target="saveBtn">Reconhecer</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--payables--entities--recognized-modal`).open(payableId)
    })
  }

  showDate() {
    var modalController = `financials--books--dashboards--dates--modal`
    // var modalController = `financials--books--payables--dashboard--dates-modal`

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Escolha a Competência</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${modalController}-target="body"></div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalController).controllerForm = controller
      controller.getControllerByIdentifier(modalController).current_obj = this.current_payable
      controller.getControllerByIdentifier(modalController).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}