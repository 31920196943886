import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "text", "image", "editBtn", "cancelBtn", "saveBtn", "mainCard", "7777", "8888", "9999",
                    "titleRow","titleDiv", "titleInputDiv", "titleInput",
                    "subtitleRow","subtitleDiv", "subtitleInputDiv", "subtitleInput",
                    "descriptionRow","descriptionDiv", "descriptionInputDiv", "descriptionInput",
                    "ctaRow","ctaDiv", "ctaInputDiv", "ctaInput",
                    "imageRow","imageDiv", "imageInputDiv", "imageInput",
                    "linkCtaRow","linkCtaDiv", "linkCtaInputDiv", "linkCtaInput",
                    "questionsTitleRow","questionsTitleDiv", "questionsTitleInputDiv", "questionsTitleInput",
                    "questionsSubtitleRow","questionsSubtitleDiv", "questionsSubtitleInputDiv", "questionsSubtitleInput",
                    "testimonialsTitleRow","testimonialsTitleDiv", "testimonialsTitleInputDiv", "testimonialsTitleInput",
                    "testimonialsSubtitleRow","testimonialsSubtitleDiv", "testimonialsSubtitleInputDiv", "testimonialsSubtitleInput",
                    "testimonialsDescriptionRow","testimonialsDescriptionDiv", "testimonialsDescriptionInputDiv", "testimonialsDescriptionInput",
                    "differentialsTitleRow","differentialsTitleDiv", "differentialsTitleInputDiv", "differentialsTitleInput",
                    "differentialsSubtitleRow","differentialsSubtitleDiv", "differentialsSubtitleInputDiv", "differentialsSubtitleInput",
                    "differentialsDescriptionRow","differentialsDescriptionDiv", "differentialsDescriptionInputDiv", "differentialsDescriptionInput",
                    "serviceRow","serviceDiv", "serviceInputDiv", "serviceInput"]

  connect() {
    this.controllerName = `commercial--marketing--sites--principal--dashboard`
  }

  doPrincipalDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 offset-2" data-${this.controllerName}-target="text"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--sites--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doCardHtml()
    })
  }

  doCardHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.marketing_site_entity.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editSite" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12 px-2" style="overflow:auto;" data-${this.controllerName}-target="mainCard">
                    <table class="table table-sm table-borderless" style="font-size:80%;">
                      <tbody>
                        <tr>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                          
                        </tr>

                        <tr data-field="title" data-${this.controllerName}-target="titleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="titleDiv">
                              ${this.application.site.principal_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="titleInputDiv">
                              <input data-${this.controllerName}-target="titleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="subtitle" data-${this.controllerName}-target="subtitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Subtítulo Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="subtitleDiv">
                              ${this.application.site.principal_subtitle}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="subtitleInputDiv">
                              <input data-${this.controllerName}-target="subtitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="description" data-${this.controllerName}-target="descriptionRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Descrição Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="descriptionDiv">
                              ${this.application.site.principal_description}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="descriptionInputDiv">
                              <input data-${this.controllerName}-target="descriptionInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="cta" data-${this.controllerName}-target="ctaRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>CTA Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="ctaDiv">
                              ${this.application.site.principal_cta}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="ctaInputDiv">
                              <input data-${this.controllerName}-target="ctaInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="linkCta" data-${this.controllerName}-target="linkCtaRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Link CTA Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="linkCtaDiv">
                              ${this.application.site.principal_link_cta}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="linkCtaInputDiv">
                              <input data-${this.controllerName}-target="linkCtaInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="image" data-${this.controllerName}-target="imageRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Link Imagem Principal</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="imageDiv">
                              ${this.application.site.principal_image}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="imageInputDiv">
                              <input data-${this.controllerName}-target="imageInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        
                        
                        <tr>
                          ${spaceRow}
                        </tr>

                        <tr data-field="service" data-${this.controllerName}-target="serviceRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Serviços</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="serviceDiv">
                              ${this.application.site.services_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="serviceInputDiv">
                              <input data-${this.controllerName}-target="serviceInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          ${spaceRow}
                        </tr>

                        <tr data-field="questionsTitle" data-${this.controllerName}-target="questionsTitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Perguntas</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="questionsTitleDiv">
                              ${this.application.site.questions_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="questionsTitleInputDiv">
                              <input data-${this.controllerName}-target="questionsTitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="questionsSubtitle" data-${this.controllerName}-target="questionsSubtitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Subtítulo Perguntas</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="questionsSubtitleDiv">
                              ${this.application.site.questions_subtitle}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="questionsSubtitleInputDiv">
                              <input data-${this.controllerName}-target="questionsSubtitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          ${spaceRow}
                        </tr>

                        <tr data-field="testimonialsTitle" data-${this.controllerName}-target="testimonialsTitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Depoimentos</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="testimonialsTitleDiv">
                              ${this.application.site.testimonials_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="testimonialsTitleInputDiv">
                              <input data-${this.controllerName}-target="testimonialsTitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="testimonialsSubtitle" data-${this.controllerName}-target="testimonialsSubtitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Subtítulo Depoimentos</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="testimonialsSubtitleDiv">
                              ${this.application.site.testimonials_subtitle}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="testimonialsSubtitleInputDiv">
                              <input data-${this.controllerName}-target="testimonialsSubtitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="testimonialsDescription" data-${this.controllerName}-target="testimonialsDescriptionRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Descrição Depoimentos</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="testimonialsDescriptionDiv">
                              ${this.application.site.testimonials_description}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="testimonialsDescriptionInputDiv">
                              <input data-${this.controllerName}-target="testimonialsDescriptionInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          ${spaceRow}
                        </tr>

                        <tr data-field="differentialsTitle" data-${this.controllerName}-target="differentialsTitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Título Diferenciais</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="differentialsTitleDiv">
                              ${this.application.site.differentials_title}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="differentialsTitleInputDiv">
                              <input data-${this.controllerName}-target="differentialsTitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="differentialsSubtitle" data-${this.controllerName}-target="differentialsSubtitleRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Subtítulo Diferenciais</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="differentialsSubtitleDiv">
                              ${this.application.site.differentials_subtitle}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="differentialsSubtitleInputDiv">
                              <input data-${this.controllerName}-target="differentialsSubtitleInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="differentialsDescription" data-${this.controllerName}-target="differentialsDescriptionRow">
                          <th colspan="3" class="px-0 f-065 align-middle"><strong>Descrição Diferenciais</strong></th>
                          <td colspan="6" class="px-0 f-065 align-middle">
                            <div data-${this.controllerName}-target="differentialsDescriptionDiv">
                              ${this.application.site.differentials_description}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="differentialsDescriptionInputDiv">
                              <input data-${this.controllerName}-target="differentialsDescriptionInput" class="form-control p-1 s-title-0p6rem" type="text" data-action="keyup->${this.controllerName}#enterInput" required>
                            </div>
                          </td>
                          <td colspan="1" class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          <td colspan="10" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveSite" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                        

                      </tbody>
                    </table>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.textTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.subtitleRowTarget.classList.add(`d-none`)
    })
  }

  editSite(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }



    if (this.field == "title") {
      this.titleDivTarget.classList.add("d-none")
      this.titleInputDivTarget.classList.remove("d-none")
      this.titleInputTarget.value = this.titleDivTarget.innerText.trim()
      this.titleInputTarget.focus()
    } else if (this.field == "subtitle") {
      this.subtitleDivTarget.classList.add("d-none")
      this.subtitleInputDivTarget.classList.remove("d-none")
      this.subtitleInputTarget.value = this.subtitleDivTarget.innerText.trim()
      this.subtitleInputTarget.focus()
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.add("d-none")
      this.descriptionInputDivTarget.classList.remove("d-none")
      this.descriptionInputTarget.value = this.descriptionDivTarget.innerText.trim()
      this.descriptionInputTarget.focus()
    } else if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cta") {
      this.ctaDivTarget.classList.add("d-none")
      this.ctaInputDivTarget.classList.remove("d-none")
      this.ctaInputTarget.value = this.ctaDivTarget.innerText.trim()
      this.ctaInputTarget.focus()
    } else if (this.field == "image") {
      this.imageDivTarget.classList.add("d-none")
      this.imageInputDivTarget.classList.remove("d-none")
      this.imageInputTarget.value = this.imageDivTarget.innerText.trim()
      this.imageInputTarget.focus()
    } else if (this.field == "service") {
      this.serviceDivTarget.classList.add("d-none")
      this.serviceInputDivTarget.classList.remove("d-none")
      this.serviceInputTarget.value = this.serviceDivTarget.innerText.trim()
      this.serviceInputTarget.focus()
    } else if (this.field == "linkCta") {
      this.linkCtaDivTarget.classList.add("d-none")
      this.linkCtaInputDivTarget.classList.remove("d-none")
      this.linkCtaInputTarget.value = this.linkCtaDivTarget.innerText.trim()
      this.linkCtaInputTarget.focus()
    } else if (this.field == "questionsTitle") {
      this.questionsTitleDivTarget.classList.add("d-none")
      this.questionsTitleInputDivTarget.classList.remove("d-none")
      this.questionsTitleInputTarget.value = this.questionsTitleDivTarget.innerText.trim()
      this.questionsTitleInputTarget.focus()
    } else if (this.field == "questionsSubtitle") {
      this.questionsSubtitleDivTarget.classList.add("d-none")
      this.questionsSubtitleInputDivTarget.classList.remove("d-none")
      this.questionsSubtitleInputTarget.value = this.questionsSubtitleDivTarget.innerText.trim()
      this.questionsSubtitleInputTarget.focus()
    } else if (this.field == "testimonialsTitle") {
      this.testimonialsTitleDivTarget.classList.add("d-none")
      this.testimonialsTitleInputDivTarget.classList.remove("d-none")
      this.testimonialsTitleInputTarget.value = this.testimonialsTitleDivTarget.innerText.trim()
      this.testimonialsTitleInputTarget.focus()
    } else if (this.field == "testimonialsSubtitle") {
      this.testimonialsSubtitleDivTarget.classList.add("d-none")
      this.testimonialsSubtitleInputDivTarget.classList.remove("d-none")
      this.testimonialsSubtitleInputTarget.value = this.testimonialsSubtitleDivTarget.innerText.trim()
      this.testimonialsSubtitleInputTarget.focus()
    } else if (this.field == "testimonialsDescription") {
      this.testimonialsDescriptionDivTarget.classList.add("d-none")
      this.testimonialsDescriptionInputDivTarget.classList.remove("d-none")
      this.testimonialsDescriptionInputTarget.value = this.testimonialsDescriptionDivTarget.innerText.trim()
      this.testimonialsDescriptionInputTarget.focus()
    } else if (this.field == "differentialsTitle") {
      this.differentialsTitleDivTarget.classList.add("d-none")
      this.differentialsTitleInputDivTarget.classList.remove("d-none")
      this.differentialsTitleInputTarget.value = this.differentialsTitleDivTarget.innerText.trim()
      this.differentialsTitleInputTarget.focus()
    } else if (this.field == "differentialsSubtitle") {
      this.differentialsSubtitleDivTarget.classList.add("d-none")
      this.differentialsSubtitleInputDivTarget.classList.remove("d-none")
      this.differentialsSubtitleInputTarget.value = this.differentialsSubtitleDivTarget.innerText.trim()
      this.differentialsSubtitleInputTarget.focus()
    } else if (this.field == "differentialsDescription") {
      this.differentialsDescriptionDivTarget.classList.add("d-none")
      this.differentialsDescriptionInputDivTarget.classList.remove("d-none")
      this.differentialsDescriptionInputTarget.value = this.differentialsDescriptionDivTarget.innerText.trim()
      this.differentialsDescriptionInputTarget.focus()

    }

  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "title") {
      this.titleDivTarget.classList.remove("d-none")
      this.titleInputDivTarget.classList.add("d-none")
      this.titleDivTarget.innerText = this.application.site.principal_title

    } else if (this.field == "title") {
      this.titleDivTarget.classList.remove("d-none")
      this.titleInputDivTarget.classList.add("d-none")
      this.titleDivTarget.innerText = this.application.site.principal_title
    } else if (this.field == "subtitle") {
      this.subtitleDivTarget.classList.remove("d-none")
      this.subtitleInputDivTarget.classList.add("d-none")
      this.subtitleDivTarget.innerText = this.application.site.principal_subtitle
    } else if (this.field == "description") {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.application.site.principal_description
    } else if (this.field == "cta") {
      this.ctaDivTarget.classList.remove("d-none")
      this.ctaInputDivTarget.classList.add("d-none")
      this.ctaDivTarget.innerText = this.application.site.principal_cta
    } else if (this.field == "image") {
      this.imageDivTarget.classList.remove("d-none")
      this.imageInputDivTarget.classList.add("d-none")
      this.imageDivTarget.innerText = this.application.site.principal_image
    } else if (this.field == "service") {
      this.serviceDivTarget.classList.remove("d-none")
      this.serviceInputDivTarget.classList.add("d-none")
      this.serviceDivTarget.innerText = this.application.site.services_title
    } else if (this.field == "linkCta") {
      this.linkCtaDivTarget.classList.remove("d-none")
      this.linkCtaInputDivTarget.classList.add("d-none")
      this.linkCtaDivTarget.innerText = this.application.site.principal_link_cta
    } else if (this.field == "questionsTitle") {
      this.questionsTitleDivTarget.classList.remove("d-none")
      this.questionsTitleInputDivTarget.classList.add("d-none")
      this.questionsTitleDivTarget.innerText = this.application.site.questions_title
    } else if (this.field == "questionsSubtitle") {
      this.questionsSubtitleDivTarget.classList.remove("d-none")
      this.questionsSubtitleInputDivTarget.classList.add("d-none")
      this.questionsSubtitleDivTarget.innerText = this.application.site.questions_subtitle
    } else if (this.field == "testimonialsTitle") {
      this.testimonialsTitleDivTarget.classList.remove("d-none")
      this.testimonialsTitleInputDivTarget.classList.add("d-none")
      this.testimonialsTitleDivTarget.innerText = this.application.site.testimonials_title
    } else if (this.field == "testimonialsSubtitle") {
      this.testimonialsSubtitleDivTarget.classList.remove("d-none")
      this.testimonialsSubtitleInputDivTarget.classList.add("d-none")
      this.testimonialsSubtitleDivTarget.innerText = this.application.site.testimonials_subtitle
    } else if (this.field == "testimonialsDescription") {
      this.testimonialsDescriptionDivTarget.classList.remove("d-none")
      this.testimonialsDescriptionInputDivTarget.classList.add("d-none")
      this.testimonialsDescriptionDivTarget.innerText = this.application.site.testimonials_description
    } else if (this.field == "differentialsTitle") {
      this.differentialsTitleDivTarget.classList.remove("d-none")
      this.differentialsTitleInputDivTarget.classList.add("d-none")
      this.differentialsTitleDivTarget.innerText = this.application.site.differentials_title
    } else if (this.field == "differentialsSubtitle") {
      this.differentialsSubtitleDivTarget.classList.remove("d-none")
      this.differentialsSubtitleInputDivTarget.classList.add("d-none")
      this.differentialsSubtitleDivTarget.innerText = this.application.site.differentials_subtitle
    } else if (this.field == "differentialsDescription") {
      this.differentialsDescriptionDivTarget.classList.remove("d-none")
      this.differentialsDescriptionInputDivTarget.classList.add("d-none")
      this.differentialsDescriptionDivTarget.innerText = this.application.site.differentials_description

    }

  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")


    if (this.titleDivTarget.classList.contains("d-none")) {
      this.titleDivTarget.classList.remove("d-none")
      this.titleInputDivTarget.classList.add("d-none")
      this.titleDivTarget.innerText = this.titleInputTarget.value
    }

    if (this.subtitleDivTarget.classList.contains("d-none")) {
      this.subtitleDivTarget.classList.remove("d-none")
      this.subtitleInputDivTarget.classList.add("d-none")
      this.subtitleDivTarget.innerText = this.subtitleInputTarget.value
    }

    if (this.descriptionDivTarget.classList.contains("d-none")) {
      this.descriptionDivTarget.classList.remove("d-none")
      this.descriptionInputDivTarget.classList.add("d-none")
      this.descriptionDivTarget.innerText = this.descriptionInputTarget.value
    }

    if (this.ctaDivTarget.classList.contains("d-none")) {
      this.ctaDivTarget.classList.remove("d-none")
      this.ctaInputDivTarget.classList.add("d-none")
      this.ctaDivTarget.innerText = this.ctaInputTarget.value
    }

    if (this.imageDivTarget.classList.contains("d-none")) {
      this.imageDivTarget.classList.remove("d-none")
      this.imageInputDivTarget.classList.add("d-none")
      this.imageDivTarget.innerText = this.imageInputTarget.value
    }

    if (this.serviceDivTarget.classList.contains("d-none")) {
      this.serviceDivTarget.classList.remove("d-none")
      this.serviceInputDivTarget.classList.add("d-none")
      this.serviceDivTarget.innerText = this.serviceInputTarget.value
    }

    if (this.linkCtaDivTarget.classList.contains("d-none")) {
      this.linkCtaDivTarget.classList.remove("d-none")
      this.linkCtaInputDivTarget.classList.add("d-none")
      this.linkCtaDivTarget.innerText = this.linkCtaInputTarget.value
    }

    if (this.questionsTitleDivTarget.classList.contains("d-none")) {
      this.questionsTitleDivTarget.classList.remove("d-none")
      this.questionsTitleInputDivTarget.classList.add("d-none")
      this.questionsTitleDivTarget.innerText = this.questionsTitleInputTarget.value
    }

    if (this.questionsSubtitleDivTarget.classList.contains("d-none")) {
      this.questionsSubtitleDivTarget.classList.remove("d-none")
      this.questionsSubtitleInputDivTarget.classList.add("d-none")
      this.questionsSubtitleDivTarget.innerText = this.questionsSubtitleInputTarget.value
    }

    if (this.testimonialsTitleDivTarget.classList.contains("d-none")) {
      this.testimonialsTitleDivTarget.classList.remove("d-none")
      this.testimonialsTitleInputDivTarget.classList.add("d-none")
      this.testimonialsTitleDivTarget.innerText = this.testimonialsTitleInputTarget.value
    }

    if (this.testimonialsSubtitleDivTarget.classList.contains("d-none")) {
      this.testimonialsSubtitleDivTarget.classList.remove("d-none")
      this.testimonialsSubtitleInputDivTarget.classList.add("d-none")
      this.testimonialsSubtitleDivTarget.innerText = this.testimonialsSubtitleInputTarget.value
    }

    if (this.testimonialsDescriptionDivTarget.classList.contains("d-none")) {
      this.testimonialsDescriptionDivTarget.classList.remove("d-none")
      this.testimonialsDescriptionInputDivTarget.classList.add("d-none")
      this.testimonialsDescriptionDivTarget.innerText = this.testimonialsDescriptionInputTarget.value
    }

    if (this.differentialsTitleDivTarget.classList.contains("d-none")) {
      this.differentialsTitleDivTarget.classList.remove("d-none")
      this.differentialsTitleInputDivTarget.classList.add("d-none")
      this.differentialsTitleDivTarget.innerText = this.differentialsTitleInputTarget.value
    }

    if (this.differentialsSubtitleDivTarget.classList.contains("d-none")) {
      this.differentialsSubtitleDivTarget.classList.remove("d-none")
      this.differentialsSubtitleInputDivTarget.classList.add("d-none")
      this.differentialsSubtitleDivTarget.innerText = this.differentialsSubtitleInputTarget.value
    }

    if (this.differentialsDescriptionDivTarget.classList.contains("d-none")) {
      this.differentialsDescriptionDivTarget.classList.remove("d-none")
      this.differentialsDescriptionInputDivTarget.classList.add("d-none")
      this.differentialsDescriptionDivTarget.innerText = this.differentialsDescriptionInputTarget.value
    }

  }

  enterInput(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.saveSite()
    }
  }

  saveSite() {
    this.send_data = { current_user: {}, site: {} }

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = controller.application.current_user.id
      controller.send_data.current_user.feature = `marketing_site_entity`


      controller.send_data.site.id = controller.application.site.id
      controller.send_data.site.principal_title = controller.titleDivTarget.innerText.trim()
      controller.send_data.site.principal_subtitle = controller.subtitleDivTarget.innerText.trim()
      controller.send_data.site.principal_description = controller.descriptionDivTarget.innerText.trim()
      controller.send_data.site.principal_image = controller.imageDivTarget.innerText.trim()
      controller.send_data.site.principal_cta = controller.ctaDivTarget.innerText.trim()
      controller.send_data.site.principal_link_cta = controller.linkCtaDivTarget.innerText.trim()
      controller.send_data.site.services_title = controller.serviceDivTarget.innerText.trim()
      controller.send_data.site.questions_title = controller.questionsTitleDivTarget.innerText.trim()
      controller.send_data.site.questions_subtitle = controller.questionsSubtitleDivTarget.innerText.trim()
      controller.send_data.site.testimonials_title = controller.testimonialsTitleDivTarget.innerText.trim()
      controller.send_data.site.testimonials_subtitle = controller.testimonialsSubtitleDivTarget.innerText.trim()
      controller.send_data.site.testimonials_description = controller.testimonialsDescriptionDivTarget.innerText.trim()
      controller.send_data.site.differentials_title = controller.differentialsTitleDivTarget.innerText.trim()
      controller.send_data.site.differentials_subtitle = controller.differentialsSubtitleDivTarget.innerText.trim()
      controller.send_data.site.differentials_description = controller.differentialsDescriptionDivTarget.innerText.trim()

      controller.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/commercial/marketing/sites/entities/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.site = response.data.cln
          controller.doCardHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.titleInputTarget.classList.contains("d-none") && controller.titleInputTarget.value == "") {
        len += 1
      }

      // if (controller.cpfInputTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") {
      //   len += 1
      // }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}