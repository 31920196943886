import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--settings--opportunities`
    this.tableCols = 5
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.hasShow = false

    this.doIndexListHtml()
  }

  showOpportunity(ev) {
    // var controllerShow = this.getControllerByIdentifier("operations--products--tax-filings--settings--show")
    // var opportunityId = ev.target.closest(".itemRow").dataset.id
    // this.application.opportunities.forEach(element => {
    //   if (element.id == opportunityId) {
    //     controllerShow.current_opportunity = element
    //   }
    // })
    // controllerShow.doOpportunityHtml()
  }

  addOpportunity() {
    this.showClearBtn()

    var modalName = `commercial--sales--opportunities--entities--save-modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      var current_obj = {}
      current_obj.id = this.application.tax_filing.id
      current_obj.type = `operation_products`
      current_obj.account_id = this.application.tax_filing.account_id
      current_obj.account_email = this.application.tax_filing.account_email.address
      current_obj.account_name = this.application.tax_filing.account_name
      current_obj.date_id = this.application.current_date.id
      current_obj.observations = `a partir do IRPF ${this.application.current_date.financial_year}`

      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).current_lead = {}
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).current_obj = current_obj
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).permission = controller.application.product_permission
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).title = `Nova Oportunidade para ${controller.application.tax_filing.account_name} CPF: ${controller.application.tax_filing.account_cpf_pretty}`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).open()
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.application.product_permission].can_manage) {
      var addLine = `<span data-action="click->${this.controllerName}#addOpportunity" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Oportunidade</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshOpportunities" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Oportunidades</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Oportunidades</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Closer</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Produto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Estágio</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").opportunitiesTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.35) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_opportunities = this.application.opportunities

    if (this.sort.mode == `asc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_opportunities, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_opportunities, this.sort.field)
    }

    if (opportunities.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(opportunities.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (opportunities[x] !== undefined) {
            internPage[internPage.length] = opportunities[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: opportunities.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Oportunidades</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.opportunityTablePartial(element, data.length))
      });
    }
  }

  opportunityTablePartial(element, length) {

    if (element.status == `hot`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer">
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    } else if (element.status == `warn`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer">
                      <span class="material-icons md-sm md-warning">local_fire_department</span>
                      <span class="material-icons md-sm md-warning">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    } else if (element.status == `cold`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer">
                      <span class="material-icons md-sm md-primary">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    }

    if (element.prospector_name == null || element.prospector_name == undefined) {
      element.prospector_name = `Não Definido`
    }

    if (element.closer_name == null || element.closer_name == undefined) {
      element.closer_name = `Não Definido`
    }

    if (element.products && element.products.length > 0) {
      element.products.forEach(product => {
        element.product_pretty = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.name_pretty}</p>`
        element.kind_pretty = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.kind_pretty}</p>`
        element.plan_pretty = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.plan_pretty}</p>`
      })
    } else {
      element.product_pretty = `Sem Produto`
      element.kind_pretty = `Sem Produto`
      element.plan_pretty = `Sem Produto`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showOpportunity">${element.closer_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showOpportunity">${element.product_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.plan_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.stage_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.doDataTable()
  }

  refreshOpportunities() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { opportunity: { obj_id: this.application.tax_filing.id, obj_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.product_permission } }
    const url = "/commercial/sales/opportunities/entities/list_by_obj"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.opportunities = response.data.cln
        }

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
