import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--radars--dashboard`
  }

  doRadarDashboard() {
    var html = `<div class="row ">
                  <div class="col-8 px-2" data-controller="commercial--sales--opportunities--radars--index" data-${this.controllerName}-target="list"></div>
                  <div class="col-4 px-2" data-controller="commercial--sales--opportunities--radars--show
                                                           commercial--sales--opportunities--radars--save
                                                           commercial--sales--opportunities--entities--save" data-${this.controllerName}-target="view"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--opportunities--radars--main").tableTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--index`).permission = `sales_opportunities_radars`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--index`).doIndexListHtml()

      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).permission = `sales_opportunities_radars`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).gridElement = controller.viewTarget
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).controllerDashboard = controller

      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).permission = `sales_opportunities_radars`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).gridElement = controller.viewTarget
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).controllerDashboard = controller
      
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--show`).permission = `sales_opportunities_radars`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--show`).doViewHtml()

    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
