import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "dateStatus", "saveBtn", "deleteBtn", "saveCard", "titleCard", "goalName",
    "dependent", "goalDropdown", "goalInput", "goalList", "goalDropdownBtn", "amount", "shortCheckbox", "longCheckbox"]

  connect() {
    this.controllerName = `operations--products--tax-returns--achievements--save`
    if (this.application.achievements) {
      this.application.achievements.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_achievement = element
        }
      });
    } else {
      this.current_achievement = { total_work_incomes: 0, saving_rate_goal: 0, saving_rate_real: 0, saving_goal: 0, saving_real: 0 }
    }

    this.doViewHtml()
  }

  kindCheck(ev) {
    if (ev.target.dataset.kind == "short") {
      this.shortCheckboxTarget.checked = true
      this.longCheckboxTarget.checked = false
      this.term = "short"
    } else if (ev.target.dataset.kind == "long") {
      this.shortCheckboxTarget.checked = false
      this.longCheckboxTarget.checked = true
      this.term = "long"
    }
  }

  saveGoal() {

    this.goal.tax_return_id = this.application.tax_return.id
    this.goal.date_id = this.application.current_date.id
    this.goal.achievement_id = this.current_achievement.id
    this.goal.name = this.goalNameTarget.value.trim()
    this.goal.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountTarget.value)
    this.goal.term = this.term

    var data = { goal: this.goal, current_user: { current_user_id: this.application.current_user.id } }

    this.requestSave(data)
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/goals/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/goals/create"
      var method = "POST"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          this.cancelSave()
          var goal = response.data.cln
          if (this.actionMode == "edit") {
            if (goal.active) {
              this.application.goals.forEach((element, i) => {
                if (element.id == goal.id) {
                  this.application.goals.splice(this.application.goals.indexOf(element), 1, goal)
                }
              })
            } else {
              this.application.goals.forEach((element, i) => {
                if (element.id == goal.id) {
                  this.application.goals.splice(this.application.goals.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.goals[this.application.goals.length] = goal
          }
          this.getControllerByIdentifier("operations--products--tax-returns--achievements--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()
    if (this.application.medreturn_goals.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#saveGoal" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }
    // if (this.application.medreturn_goals.can_delete && this.actionMode == "edit") {
    //   var deleteBtnHtml = `<button disabled type="button" class="btn btn-danger btn-sm" data-action="click->${this.controllerName}#deletePatient" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    // } else {
    //   var deleteBtnHtml = ``
    // }

    var deleteBtnHtml = ``


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="saveCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="titleCard"></h6>
                  </div>
                  <div class="card-body py-0">
                    <div class="row my-2">
                      <div class="col-12 pl-0 pr-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome do Objetivo</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="goalName" placeholder="Nome do Objetivo" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-4 pl-0 pr-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor</label>
                            <input aria-describedby="amountFormHelp" class="form-control form-valid-control" id="amountForm" data-${this.controllerName}-target="amount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                          <input type="checkbox" class="custom-control-input" id="shortTermCheck" data-kind="short" data-${this.controllerName}-target="shortCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label" for="shortTermCheck">Curto</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                          <input type="checkbox" class="custom-control-input" id="longTermCheck" data-kind="long" data-${this.controllerName}-target="longCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label" for="longTermCheck">Longo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.saveCardTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.actionMode == "new") {
        controller.titleCardTarget.innerText = `Novo Objetivo`
        controller.amountTarget.value = "R$ 0,00"
      } else if (controller.actionMode == "edit") {
        controller.titleCardTarget.innerText = `Editando Objetivo`
        controller.goalNameTarget.value = controller.goal.name
        controller.amountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.goal.amount * 100))
        if (controller.goal.term == "short") {
          controller.shortCheckboxTarget.checked = true
          controller.term = "short"
        } else if (controller.goal.term == "long") {
          controller.longCheckboxTarget.checked = true
          controller.term = "long"
        }
      }
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    })
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewGoalCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Objetivo Selecionado</h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").viewTarget.innerHTML = html
  }

  

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cancelSave() {
    this.stopRefreshing()
    this.doViewHtml()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.goalNameTarget.value == "") {
        len += 1
      }
      if (controller.amountTarget.value == "R$ 0,00") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
