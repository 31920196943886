import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pfPartnerCheckbox", "pjPartnerCheckbox", "chooseType", "cpfInputDiv", "cpfInput", "cnpjInputDiv",
                    "cnpjInput", "sexDropdown", "sexInput", "saveAccountBtn",
                    "nameInput", "birthdateInput", "sexDropdownBtn", "sameCityCheckbox", "notesInput",
                    "issuedOnInput", "takerInfo", "pfTakerInfo", "errorMessage", "addressInput",
                    "mainCard", "footerTable", "descriptionInput", "chooseType", "cpfInputDiv", "ibgeInput",
                    "zipInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "streetInput", "numberInput",
                    "complementInput", "districtInput", "stateInput", "stateFilter", "cityInput", "cityFilter", "saveBtn",
                    "individualBtn", "legalBtn", "list", "bodyTable", "formCard", "pjTakerInfo",
                    "fantasyNameInput", "tradeNameInput", "municipalNumberInput", "xxxxx", "xxxxx"]

  connect() {
    this.controllerName = "operations--products--clinics--financials--add--takers"
    this.tableCols = 4
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.numbersController = this.getControllerByIdentifier("app--helpers--numbers")
    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
      this.saveController.takers = this.application.takers
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)
      this.saveController.takers = this.application.takers
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }

    this.saveController.nextBtnTarget.classList.add(`d-none`)

    // email_first = email_list[0]
    // address_first = address_list[0]
  }

  getData() {}

  doTakersFormHtml() {
    var html = `<div class="row w-100">
                  <div class="col-2">
                    <div class="row mt-5 w-100">
                      <div class="col-12 px-0">
                        <div class="card">
                          <div class="card-body py-0 px-2 f-bold pointer" data-action="click->${this.controllerName}#showTakers" data-kind="individual" data-${this.controllerName}-target="individualBtn">
                            <div class="row my-2">
                              <div class="col-12 px-0 text-center">
                                Tomador PF
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5 w-100">
                      <div class="col-12 px-0">
                        <div class="card">
                          <div class="card-body py-0 px-2 f-bold pointer" data-action="click->${this.controllerName}#showTakers" data-kind="legal" data-${this.controllerName}-target="legalBtn">
                            <div class="row my-2">
                              <div class="col-12 px-0 text-center">
                                Tomador PJ
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-10" data-${this.controllerName}-target="list">
                  </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      controller.saveController.formTitleTarget.innerText = `Selecione o Tomador de Serviço da ${this.saveController.current_clinic.company_name}`
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.saveController.nextBtnTarget.disabled = true
    })
  }

  showTakers(ev) {
    this.kind = ev.currentTarget.dataset.kind

    if (this.kind == `individual`) {
      this.individualBtnTarget.classList.add(`bg-primary`)
      this.legalBtnTarget.classList.remove(`bg-primary`)
      this.doTakerListHtml()
    } else if (this.kind == `legal`) {
      this.individualBtnTarget.classList.remove(`bg-primary`)
      this.legalBtnTarget.classList.add(`bg-primary`)
      if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
        this.doClientViewLegalListHtml()
      } else {
        this.doTakerListHtml()
      }
    }
  }

  doClientViewLegalListHtml() {

    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row my-5 d-flex align-items-center justify-content-between">
                      <div class="col-12 py-3 text-center">
                        <span class="f-1 f-bold">Favor enviar a solicitação para nota.fiscal@medcapital.com.br</span>
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  doTakerListHtml() {
    var type = ``

    if (this.kind == `individual`) {
      type = `CPF`
    } else if (this.kind == `legal`) {
      type = `CNPJ`
    }

    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Tomadores</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addTakerBtn" data-action="click->${this.controllerName}#addTaker" type="button">
                        <span class="material-icons md-dark">add</span>
                      </button>
                      <span class="mc-tooltiptext">Adicionar Tomador</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Nome
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">${type}</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
        controller.doDataTable()
      } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
        controller.doDataTable()
      } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
        if (controller.saveController.takers.length == 0) {
          controller.saveController.getTakers()
        } else {
          controller.doDataTable()
        }
      }
    })
  }

  doDataTable() {
    var current_takers = []

    this.saveController.takers.forEach(element => {
      if (element.id_type == this.kind && element.status) {
        current_takers[current_takers.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var takers = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_takers, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var takers = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_takers, this.sort.field)
    }

    if (takers.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(takers.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (takers[x] !== undefined) {
            internPage[internPage.length] = takers[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: takers.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tomadores</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.takerTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  takerTablePartial(element, length) {
    
    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#selectTaker">
                      <span class="mc-tooltip">
                        <span>${element.name}</span>
                        <span class="mc-tooltiptext">Clique para Selecionar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#selectTaker">
                      <span class="mc-tooltip">
                        <span>${element.id_number_pretty}</span>
                        <span class="mc-tooltiptext">Clique para Selecionar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.id_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.status_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }


  selectTaker(ev) {
    var takerId = ev.currentTarget.closest(`.itemRow`).dataset.id
    this.reviewTaker(takerId)
  }
  
  setTaker(takerId) {
    var notFindTaker = true
    
    this.saveController.takers.forEach(taker => {
      if (taker.id == takerId) {
        notFindTaker = false
        // this.getControllerByIdentifier("operations--products--clinics--financials--add--informations").taker = taker
        this.saveController.current_taker = taker

        if (taker.id_type == `individual`) {
          this.application.chart_accounts.forEach(chart => {
            if (chart.master_name == `services_individual_revenues`) {
              this.saveController.send_data.receivable.chart_id = chart.id
              this.saveController.send_data.receivable.chart_master_name = chart.master_name
            }
          })

          this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
          this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
          this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
          this.saveController.send_data.receivable.taker_taxation_type = `within_city`
          this.saveController.send_data.receivable.taker_taxation_type_nfe = `Mesma Cidade`
          this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
        } else if (taker.id_type == `legal`) {
          this.application.chart_accounts.forEach(chart => {
            if (chart.master_name == `services_legal_revenues`) {
              this.saveController.send_data.receivable.chart_id = chart.id
              this.saveController.send_data.receivable.chart_master_name = chart.master_name
            }
          })

          this.saveController.send_data.receivable.service_state = taker.address_list[0].state
          this.saveController.send_data.receivable.service_city_code = taker.address_list[0].ibge
          this.saveController.send_data.receivable.service_city_name = taker.address_list[0].city
          this.saveController.send_data.receivable.taker_taxation_type = taker.taxation_type
          this.saveController.send_data.receivable.taker_taxation_type_nfe = taker.taxation_type_nfe
          this.saveController.send_data.receivable.taker_taxation_type_pretty = taker.taxation_type_pretty

          // "within_city" => "Mesma Cidade",
          // "outside_city" => "Cidade Diferente",
        }
        this.saveController.send_data.receivable.taker_name = taker.name
        // this.saveController.send_data.receivable.service_state = taker.address_list[0].state
        // this.saveController.send_data.receivable.service_city_code = taker.address_list[0].ibge
        // this.saveController.send_data.receivable.service_city_name = taker.address_list[0].city
        this.saveController.send_data.receivable.taker_federal_tax_number = taker.id_number
        this.saveController.send_data.receivable.taker_federal_tax_number_pretty = taker.id_number_pretty
        this.saveController.send_data.receivable.taker_municipal_tax_number = taker.company_municipal_number
        this.saveController.send_data.receivable.taker_type = taker.id_type
        this.saveController.send_data.receivable.taker_type_pretty = taker.id_type_pretty
        this.saveController.send_data.receivable.taker_type_nfe = taker.id_type_nfe
        this.saveController.send_data.receivable.taker_email = `nfe@medcapital.com.br`
        this.saveController.send_data.receivable.taker_country = taker.address_list[0].country
        this.saveController.send_data.receivable.taker_postal_code = taker.address_list[0].postal_code
        this.saveController.send_data.receivable.taker_postal_code_pretty = taker.address_list[0].postal_code_pretty
        this.saveController.send_data.receivable.taker_street = taker.address_list[0].street
        this.saveController.send_data.receivable.taker_number = taker.address_list[0].number
        this.saveController.send_data.receivable.taker_complement = taker.address_list[0].complement
        this.saveController.send_data.receivable.taker_district = taker.address_list[0].district
        this.saveController.send_data.receivable.taker_city_code = taker.address_list[0].ibge
        this.saveController.send_data.receivable.taker_city_name = taker.address_list[0].city
        this.saveController.send_data.receivable.taker_state = taker.address_list[0].state
        // this.saveController.send_data.receivable.taker_taxation_type = taker.taxation_type
        // this.saveController.send_data.receivable.taker_taxation_type_nfe = taker.taxation_type_nfe
      }
    })

    this.saveController.nextBtnTarget.classList.remove(`d-none`)
    this.saveController.nextBtnTarget.disabled = false

    var event = { target: { innerText: `PRÓXIMO` } }
    this.saveController.changeStep(event)

    // this.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
  }

  addTaker() {
    this.actionMode = `new`
    this.doTakerForm()
  }

  reviewTaker(takerId) {

    this.saveController.takers.forEach(taker => {
      if (taker.id == takerId) {
        this.current_taker = taker
      }
    });

    this.actionMode = `edit`
    this.doTakerForm()
  }

  saveTaker() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveAccountBtnTarget.disabled = true
    this.send_data = { current_user: {}, account: {}, company: {}, address: {}, email: {}, taker: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (this.actionMode == `edit`) { this.send_data.taker.id = this.current_taker.id}

    this.send_data.taker.clinic_id = this.saveController.current_clinic.id
    this.send_data.taker.description = this.descriptionInputTarget.value
    this.send_data.taker.notes = ``

    this.send_data.address.postal_code = this.numbersController.fromZipToNumber(this.zipInputTarget.value.trim())

    if (this.kind == `individual`) {
      this.send_data.taker.id_type = "individual"
      this.send_data.taker.id_number = this.numbersController.fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.taker.name = this.nameInputTarget.value.trim()
      this.send_data.taker.taxation_type = `within_city`
      this.send_data.taker.taxation_type_pretty = `Mesma Cidade`

      this.send_data.company.is_company = false
      this.send_data.account.is_account = true
      this.send_data.account.postal_code = this.numbersController.fromZipToNumber(this.zipInputTarget.value.trim())
      this.send_data.account.street = this.streetInputTarget.value.trim()
      this.send_data.account.ibge = this.ibgeInputTarget.value.trim()
      this.send_data.account.number = this.numberInputTarget.value.trim()
      this.send_data.account.complement = this.complementInputTarget.value.trim()
      this.send_data.account.district = this.districtInputTarget.value.trim()
      this.send_data.account.city = this.cityInputTarget.value
      this.send_data.account.state = this.stateInputTarget.dataset.filter
      this.send_data.account.country = `Brasil`
      this.send_data.account.kind_address = this.kindInputTarget.dataset.kind
      this.send_data.account.is_main = true
      this.send_data.account.notes = ``
      this.send_data.account.name = this.nameInputTarget.value.trim()
      this.send_data.account.cpf = this.numbersController.fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.account.sex = this.sexInputTarget.dataset.sex
      if (this.birthdateInputTarget.value == `` || this.birthdateInputTarget.value == `Não Definida`) {
        this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/01/1900`)
      } else {
        this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.birthdateInputTarget.value)
      }
      this.send_data.account.kind = `patient`

      if (this.account.id) {
        this.send_data.account.id = this.account.id
        this.send_data.taker.record_id = this.account.id
      }

      if (this.account.address) { this.send_data.address.id = this.account.address.id }
      this.send_data.account.account_id = this.account.id
      this.send_data.account.record_type = `account_entities`

      if (this.account.email && this.account.email.id) {
        this.send_data.email.id = this.account.email.id
        if (this.addressInputTarget.dataset.previousValue != this.addressInputTarget.value.trim()) {
          this.send_data.email.action = `update`
          this.send_data.account.email_address = this.addressInputTarget.value.trim()
        } else {
          this.send_data.email.action = `do_nothing`
        }
      } else {
        if (this.addressInputTarget.value != ``) {
          this.send_data.email.action = `create`
          this.send_data.account.email_address = this.addressInputTarget.value.trim()
          this.send_data.account.kind_email = `personal`
          this.send_data.account.is_main_email = true
        } else {
          this.send_data.email.action = `do_nothing`
        }
      }
      
    } else if (this.kind == `legal`) {
      this.send_data.taker.id_type = "legal"
      this.send_data.taker.id_number = this.numbersController.fromCnpjToNumber(this.cnpjInputTarget.value)
      this.send_data.taker.name = this.tradeNameInputTarget.value.trim()
      if (this.sameCityCheckboxTarget.checked) {
        this.send_data.taker.taxation_type = `within_city`
        this.send_data.taker.taxation_type_pretty = `Mesma Cidade`
      } else {
        this.send_data.taker.taxation_type = `outside_city`
        this.send_data.taker.taxation_type_pretty = `Cidade Diferente`
      }

      this.send_data.account.is_account = false
      this.send_data.company.is_company = true
      this.send_data.company.postal_code = this.numbersController.fromZipToNumber(this.zipInputTarget.value.trim())
      this.send_data.company.street = this.streetInputTarget.value.trim()
      this.send_data.company.ibge = this.ibgeInputTarget.value.trim()
      this.send_data.company.number = this.numberInputTarget.value.trim()
      this.send_data.company.complement = this.complementInputTarget.value.trim()
      this.send_data.company.district = this.districtInputTarget.value.trim()
      this.send_data.company.city = this.cityInputTarget.value
      this.send_data.company.state = this.stateInputTarget.dataset.filter
      this.send_data.company.country = `Brasil`
      this.send_data.company.kind_address = this.kindInputTarget.dataset.kind
      this.send_data.company.is_main = true
      this.send_data.company.notes = ``
      this.send_data.company.cnpj = this.numbersController.fromCnpjToNumber(this.cnpjInputTarget.value)
      this.send_data.company.trade_name = this.tradeNameInputTarget.value.trim()
      this.send_data.company.name = this.fantasyNameInputTarget.value.trim()
      this.send_data.company.municipal_number = this.municipalNumberInputTarget.value.trim()
      this.send_data.company.opened_at = ``
      // if (this.openedAtInputTarget.value == ``) {
      //   this.send_data.company.opened_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`01/01/1900`)
      // } else {
      //   this.send_data.company.opened_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.openedAtInputTarget.value)
      // }
      this.send_data.company.kind = `taker`

      if (this.company.id) {
        this.send_data.company.id = this.company.id
        this.send_data.taker.record_id = this.company.id
      }

      if (this.company.address) { this.send_data.address.id = this.company.address.id }
      this.send_data.company.company_id = this.company.id
      this.send_data.company.record_type = `company_entities`

      if (this.company.email && this.company.email.id) {
        this.send_data.email.id = this.company.email.id
        if (this.addressInputTarget.dataset.previousValue != this.addressInputTarget.value.trim()) {
          this.send_data.email.action = `update`
          this.send_data.company.email_address = this.addressInputTarget.value.trim()
        } else {
          this.send_data.email.action = `do_nothing`
        }
      } else {
        if (this.addressInputTarget.value != ``) {
          this.send_data.email.action = `create`
          this.send_data.company.email_address = this.addressInputTarget.value.trim()
          this.send_data.company.kind_email = `personal`
          this.send_data.company.is_main_email = true
        } else {
          this.send_data.email.action = `do_nothing`
        }
      }
    }

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/products/clinics/regularizations/takers/create_from_receivable"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/products/clinics/regularizations/takers/update_from_receivable"
      var method = "PUT"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        controller.saveAccountBtnTarget.disabled = false

        if (response.save) {
          var taker = response.data.cln
          if (controller.actionMode == `new`) {
            if (controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
              controller.application.takers[controller.application.takers.length] = taker
            } else if (controller.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
              controller.saveController.takers[controller.saveController.takers.length] = taker
            } else if (controller.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
              controller.saveController.takers[controller.saveController.takers.length] = taker
            }
          } else if (controller.actionMode == `edit`) {
            if (controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
              controller.application.takers.forEach((element, i) => {
                if (element.id == taker.id) {
                  controller.application.takers.splice(controller.application.takers.indexOf(element), 1, taker)
                }
              })
            } else if (controller.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
              controller.saveController.takers.forEach((element, i) => {
                if (element.id == taker.id) {
                  controller.saveController.takers.splice(controller.saveController.takers.indexOf(element), 1, taker)
                }
              })
            } else if (controller.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
              controller.saveController.takers.forEach((element, i) => {
                if (element.id == taker.id) {
                  controller.saveController.takers.splice(controller.saveController.takers.indexOf(element), 1, taker)
                }
              })
            }
          }
          
          controller.setTaker(taker.id)
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doTakerForm() {
    var spaceRow = `<td colspan="10" style="height:1rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col"></td>`
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveAccountBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTaker" data-${this.controllerName}-target="saveAccountBtn">Salvar e Avançar</button>`
    } else {
      var saveAccountBtnHtml = ``
    }

    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Tomador</h6>
                  </div>
                  <div class="card-body p-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row d-flex align-items-top">
                      <div class="col-6 p-0">
                        <div class="row mt-3 d-flex align-items-center">

                          <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cpfForm">CPF Tomador</label>
                                <input tabindex="1" aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate blur->${this.controllerName}#searchAccount" placeholder="CPF Tomador" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cnpjForm">CNPJ Tomador</label>
                                <input tabindex="1" aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate blur->${this.controllerName}#searchCompany" placeholder="CNPJ Tomador" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8 px-2 d-none" data-${this.controllerName}-target="pfTakerInfo">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome Tomador</label>
                                <input tabindex="2" aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Tomador" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8 px-2 d-none" data-${this.controllerName}-target="pjTakerInfo">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="tradeNameForm">Razão Social</label>
                                <input aria-describedby="tradeNameFormHelp" class="form-control form-valid-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="pfTakerInfo">
                          <div class="col-6 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="birthdateForm">Data Nascimento</label>
                                <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Sexo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                      <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                      <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row d-none" data-${this.controllerName}-target="pjTakerInfo">
                          <div class="col-8 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="fantasyNameForm">Nome Fantasia</label>
                                <input aria-describedby="fantasyNameFormHelp" class="form-control form-valid-control" id="fantasyNameForm" data-${this.controllerName}-target="fantasyNameInput" placeholder="Nome Fantasia" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="municipalNumberForm">Inscrição Municipal</label>
                                <input aria-describedby="municipalNumberFormHelp" class="form-control form-valid-control" id="municipalNumberForm" data-${this.controllerName}-target="municipalNumberInput" placeholder="Inscrição Municipal" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row d-none" data-${this.controllerName}-target="takerInfo">
                          <div class="col-12 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição NF</label>
                                <input tabindex="3" aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição NF" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 px-2" data-${this.controllerName}-target="pjTakerInfo">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Observações Contrato</label>
                                <textarea rows="3" aria-describedby="notesFormHelp" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Observações Contrato" type="text" required></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-6 px-2 d-none" data-${this.controllerName}-target="takerInfo">
                        <table class="table table-sm table-borderless my-0" style="font-size:80%;">
                          <tbody>
                            <tr class="itemRow">
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr class="itemRow" data-${this.controllerName}-target="form">
                              <td colspan="10" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="addressInput">E-mail</label>
                                  <input id="addressInput" data-${this.controllerName}-target="addressInput" data-action="blur->${this.controllerName}#validateEmail keypress->${this.controllerName}#validateEmail" class="form-control p-1 s-title-0p6rem" type="email" required>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              ${spaceRow}
                            </tr>
                            <tr class="itemRow" data-${this.controllerName}-target="form">
                              <td colspan="10" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex mc-tooltip">
                                  <input type="checkbox" class="custom-control-input" id="sameCityCheck" data-${this.controllerName}-target="sameCityCheckbox">
                                  <label class="custom-control-label f-065 pointer" for="sameCityCheck">Mesma Cidade</label>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Mesma cidade?</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              ${spaceRow}
                            </tr>
                            <tr class="itemRow">
                              <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="zipInput">CEP</label>
                                  <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask focus->${this.controllerName}#searchZip blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                                </div>
                              </td>
                              <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label>Tipo</label>
                                  <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                    <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn">
                                      <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                      <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                      <ul class="ul-select" data-${this.controllerName}-target="kindList">
                                        <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                        <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle pl-4">
                                <div class="floating-label floating-label-sm">
                                  <label for="ibgeInput">IBGE</label>
                                  <input id="ibgeInput" data-${this.controllerName}-target="ibgeInput" data-action="blur->${this.controllerName}#validateSameCity keypress->${this.controllerName}#validateSameCity" class="form-control p-1 s-title-0p6rem" type="tel" required>
                                </div>
                              </td>
                              <td colspan="1" style="font-size:80%;" scope="col" class="p-1 align-middle">
                              </td>
                            </tr>
                            <tr class="itemRow">
                              <td colspan="5" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="streetInput">Logradouro</label>
                                  <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </td>
                              <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="numberInput">Número</label>
                                  <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </td>
                              <td colspan="3" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="complementInput">Complemento</label>
                                  <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </td>
                            </tr>
                            <tr class="itemRow">
                              <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="districtInput">Bairro</label>
                                  <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </td>
                              <td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="stateForm">UF</label>
                                  <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter keyup->${this.controllerName}#enterState blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                                  <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                                    <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                                    <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                                    <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                                    <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                                    <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                                    <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                                    <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                                    <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                                    <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                                    <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                                    <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                                    <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                                    <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                                    <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                                    <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                                    <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                                    <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                                    <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                                    <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                                    <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                                    <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                                    <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                                    <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                                    <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                                    <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                                    <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                                    <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                                  </ul>
                                </div>
                              </td>
                              <td colspan="4" style="font-size:80%;" scope="col" class="p-1 align-middle">
                                <div class="floating-label floating-label-sm">
                                  <label for="cityForm">Cidade</label>
                                  <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter keyup->${this.controllerName}#enterCity blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                                  <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                    ${saveAccountBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.invalidEmail = false
      controller.refreshSaveBtn()
      
      if (controller.actionMode == `new`) {
        
        if (controller.kind == `individual`) {
          controller.sameCityCheckboxTarget.disabled = true
          controller.sameCityCheckboxTarget.checked = true
          controller.cpfInputDivTarget.classList.remove("d-none")
          controller.descriptionInputTarget.value = `Consulta Médica`
          controller.sexDropdownBtnTarget.disabled = true
          controller.birthdateInputTarget.disabled = true
          controller.nameInputTarget.disabled = true

        } else if (controller.kind == `legal`) {
          controller.descriptionInputTarget.value = `Prestação de Serviços Médicos`
          controller.cnpjInputDivTarget.classList.remove("d-none")
        }

        
      } else if (controller.actionMode == `edit`) {

        if (controller.kind == `individual`) {
          controller.cpfInputDivTarget.classList.remove("d-none")
          controller.cpfInputTarget.disabled = true
          controller.descriptionInputTarget.value = `Consulta Médica`
          controller.sexDropdownBtnTarget.disabled = false
          controller.birthdateInputTarget.disabled = false
          controller.nameInputTarget.disabled = false

          controller.pfTakerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })

        } else if (controller.kind == `legal`) {
          controller.descriptionInputTarget.value = `Prestação de Serviços Médicos`
          controller.cnpjInputDivTarget.classList.remove("d-none")
          controller.cnpjInputDivTarget.disabled = true

          controller.pjTakerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })
        }

        controller.takerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        
        controller.setTakerInformations()
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setTakerInformations() {
    
    if (this.kind == `individual`) {
      this.account = this.current_taker.record
      this.account.email = this.current_taker.email_list[0]
      this.account.address = this.current_taker.address_list[0]

      this.cpfInputTarget.value = this.current_taker.id_number_pretty
      this.nameInputTarget.value = this.current_taker.name
      if (this.current_taker.account_birthdate_time <= 0) {
        this.birthdateInputTarget.value = ``
      } else {
        this.birthdateInputTarget.value = this.current_taker.account_birthdate_pretty
      }

      this.sexInputTarget.dataset.sex = this.current_taker.account_sex
      this.sexInputTarget.innerText = this.current_taker.account_sex_pretty
      this.sexDropdownTarget.value = this.current_taker.account_sex

      this.sameCityCheckboxTarget.disabled = true
      this.sameCityCheckboxTarget.checked = true
      
    } else if (this.kind == `legal`) {
      this.company = this.current_taker.record
      this.company.email = this.current_taker.email_list[0]
      this.company.address = this.current_taker.address_list[0]

      this.cnpjInputTarget.value = this.current_taker.id_number_pretty
      this.fantasyNameInputTarget.value = this.current_taker.company_fantasy_name
      this.tradeNameInputTarget.value = this.current_taker.company_trade_name
      this.municipalNumberInputTarget.value = this.current_taker.company_municipal_number

      if (this.current_taker.taxation_type == `within_city`) {
        this.sameCityCheckboxTarget.checked = true
      } else if (this.current_taker.taxation_type == `outside_city`) {
        this.sameCityCheckboxTarget.checked = false
      }
    }

    
    this.descriptionInputTarget.value = this.current_taker.description

    if (this.current_taker.address_list[0]) {
      this.zipInputTarget.value = this.numbersController.zipMask(this.current_taker.address_list[0].postal_code)
      this.streetInputTarget.value = this.current_taker.address_list[0].street
      this.ibgeInputTarget.value = this.current_taker.address_list[0].ibge
      this.numberInputTarget.value = this.current_taker.address_list[0].number
      this.complementInputTarget.value = this.current_taker.address_list[0].complement
      this.districtInputTarget.value = this.current_taker.address_list[0].district
      this.cityInputTarget.value = this.current_taker.address_list[0].city
      this.cityInputTarget.dataset.filter = this.current_taker.address_list[0].city
      this.stateInputTarget.value = this.current_taker.address_list[0].state
      this.stateInputTarget.dataset.filter = this.current_taker.address_list[0].state
      this.kindInputTarget.innerText = this.current_taker.address_list[0].kind_pretty
      this.kindInputTarget.dataset.kind = this.current_taker.address_list[0].kind
      this.kindDropdownTarget.value = this.current_taker.address_list[0].kind
    }

    // this.send_data.account.is_main = true
    // this.send_data.account.notes = ``
    // this.send_data.account.account_id = this.account.id
    // this.send_data.account.record_type = `account_entities`
    if (this.current_taker.email_list[0]) {
      this.addressInputTarget.value = this.current_taker.email_list[0].address
      this.addressInputTarget.dataset.previousValue = this.current_taker.email_list[0].address
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.numbersController.fromCpfToNumber(ev.target.value)
    this.search = true
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""

      this.nameInputTarget.disabled = false
      this.birthdateInputTarget.disabled = false
      this.sexDropdownBtnTarget.disabled = false
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "" && this.search) {
    } else if (ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "" && this.search) {
    // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11 && this.search)) {
      this.search = false
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "doctor" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
        var hasTaker = this.checkIfHasTaker(`individual`, cleanCpf)
        if (hasTaker) {
          // this.setErrorMessage("message")
        } else {
          controller.takerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })
          controller.pfTakerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              controller.search = true
              if (response.process) {
                controller.account = response.data.cln
                controller.nameInputTarget.disabled = false
                if (controller.account.id) {
                  controller.nameInputTarget.value = controller.account.name
                  controller.birthdateInputTarget.value = controller.account.birthdate_pretty
                  controller.sexInputTarget.innerText = controller.account.sex_pretty
                  controller.sexInputTarget.dataset.sex = controller.account.sex
                  controller.sexDropdownTarget.value = controller.account.sex

                  controller.nameInputTarget.disabled = false
                  controller.birthdateInputTarget.disabled = false
                  controller.sexDropdownBtnTarget.disabled = false

                  if (controller.account.email.id) {
                    controller.addressInputTarget.value = controller.account.email.address
                    controller.addressInputTarget.dataset.previousValue = controller.account.email.address
                  } else {
                    controller.addressInputTarget.value = ``
                    controller.addressInputTarget.dataset.previousValue = ``
                  }

                  if (controller.account.address.id) {
                    controller.zipInputTarget.value = controller.account.address.postal_code_pretty
                    controller.ibgeInputTarget.value = controller.account.address.ibge
                    controller.streetInputTarget.value = controller.account.address.street
                    controller.numberInputTarget.value = controller.account.address.number
                    controller.complementInputTarget.value = controller.account.address.complement
                    controller.districtInputTarget.value = controller.account.address.district
                    controller.cityInputTarget.value = controller.account.address.city
                    controller.cityInputTarget.dataset.filter = controller.account.address.filter
                    controller.stateInputTarget.value = controller.account.address.state
                    controller.stateInputTarget.dataset.filter = controller.account.address.state

                    controller.kindInputTarget.dataset.kind = controller.account.address.kind
                    controller.kindInputTarget.innerText = controller.account.address.kind_pretty
                    controller.kindDropdownTarget.value = controller.account.address.kind_pretty

                    // if (controller.account.address.ibge == controller.saveController.current_clinic.address_list[0].ibge) {
                    //   controller.sameCityCheckboxTarget.checked = true
                    // } else {
                    //   controller.sameCityCheckboxTarget.checked = false
                    // }

                  } else {
                    controller.zipInputTarget.value = ``
                    controller.ibgeInputTarget.value = ``
                    controller.streetInputTarget.value = ``
                    controller.numberInputTarget.value = ``
                    controller.complementInputTarget.value = ``
                    controller.districtInputTarget.value = ``
                    controller.cityInputTarget.value = ``
                    controller.cityInputTarget.dataset.filter = ``
                    controller.stateInputTarget.value = ``
                    controller.stateInputTarget.dataset.filter = ``

                    controller.kindInputTarget.dataset.kind = `personal`
                    controller.kindInputTarget.innerText = `Pessoal`
                    controller.kindDropdownTarget.value = `personal`

                    // controller.sameCityCheckboxTarget.checked = false
                  }
                  

                } else {
                  controller.nameInputTarget.value = "Favor adicionar o Nome do Tomador"

                  controller.nameInputTarget.disabled = false
                  controller.birthdateInputTarget.disabled = false
                  controller.sexDropdownBtnTarget.disabled = false

                  controller.zipInputTarget.value = ``
                  controller.ibgeInputTarget.value = ``
                  controller.streetInputTarget.value = ``
                  controller.numberInputTarget.value = ``
                  controller.complementInputTarget.value = ``
                  controller.districtInputTarget.value = ``
                  controller.cityInputTarget.value = ``
                  controller.cityInputTarget.dataset.filter = ``
                  controller.stateInputTarget.value = ``
                  controller.stateInputTarget.dataset.filter = ``

                  controller.kindInputTarget.dataset.kind = `personal`
                  controller.kindInputTarget.innerText = `Pessoal`
                  controller.kindDropdownTarget.value = `personal`

                  // controller.sameCityCheckboxTarget.checked = false
                }
                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }

      }
    }
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.numbersController.fromCnpjToNumber(ev.target.value)
    this.search = true
    if (ev.type == "keyup" && cleanCnpj.length < 14) {
      this.fantasyNameInputTarget.value = ""
      this.tradeNameInputTarget.value = ""
      this.municipalNumberInputTarget.value = ""
      // this.openedAtTarget.value = ""

      this.fantasyNameInputTarget.disabled = true
      this.tradeNameInputTarget.disabled = true
      this.municipalNumberInputTarget.disabled = true
      // this.openedAtTarget.disabled = true

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "") {
    // } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) {
    } else if (ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "" && this.search) {
      this.search = false
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      var data = { company: { cnpj: cleanCnpj, kind: "" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      if (this.cnpj_result.invalid == false) {
        var hasTaker = this.checkIfHasTaker(`legal`, cleanCnpj)
        if (hasTaker) {
          // this.setErrorMessage("O PJ Médica já possui o produto Declaração de Ajuste Anual")
        } else {
          var controller = this
          controller.takerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })
          controller.pjTakerInfoTargets.forEach(element => {
            element.classList.remove("d-none")
          })
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              if (response.process) {
                controller.company = response.data.cln

                if (controller.company.id) {
                  controller.fantasyNameInputTarget.value = controller.company.name
                  controller.tradeNameInputTarget.value = controller.company.trade_name
                  controller.municipalNumberInputTarget.value = controller.company.municipal_number
                  if (controller.company.opened_at_pretty) {
                    // controller.openedAtTarget.value = controller.company.opened_at_pretty
                  } else {
                    // controller.openedAtTarget.value = ``
                  }

                  // controller.fantasyNameInputTarget.disabled = true
                  // controller.tradeNameInputTarget.disabled = true
                  // controller.municipalNumberInputTarget.disabled = true
                  // controller.openedAtTarget.disabled = false

                  if (controller.company.email.id) {
                    controller.addressInputTarget.value = controller.company.email.address
                    controller.addressInputTarget.dataset.previousValue = controller.company.email.address
                  } else {
                    controller.addressInputTarget.value = ``
                    controller.addressInputTarget.dataset.previousValue = ``
                  }

                  if (controller.company.address.id) {
                    controller.zipInputTarget.value = controller.company.address.postal_code_pretty
                    controller.ibgeInputTarget.value = controller.company.address.ibge
                    controller.streetInputTarget.value = controller.company.address.street
                    controller.numberInputTarget.value = controller.company.address.number
                    controller.complementInputTarget.value = controller.company.address.complement
                    controller.districtInputTarget.value = controller.company.address.district
                    controller.cityInputTarget.value = controller.company.address.city
                    controller.cityInputTarget.dataset.filter = controller.company.address.filter
                    controller.stateInputTarget.value = controller.company.address.state
                    controller.stateInputTarget.dataset.filter = controller.company.address.state

                    controller.kindInputTarget.dataset.kind = controller.company.address.kind
                    controller.kindInputTarget.innerText = controller.company.address.kind_pretty
                    controller.kindDropdownTarget.value = controller.company.address.kind_pretty

                    // if (controller.company.address.ibge == controller.saveController.current_clinic.address_list[0].ibge) {
                    //   controller.sameCityCheckboxTarget.checked = true
                    // } else {
                    //   controller.sameCityCheckboxTarget.checked = false
                    // }

                  } else {
                    controller.zipInputTarget.value = ``
                    controller.ibgeInputTarget.value = ``
                    controller.streetInputTarget.value = ``
                    controller.numberInputTarget.value = ``
                    controller.complementInputTarget.value = ``
                    controller.districtInputTarget.value = ``
                    controller.cityInputTarget.value = ``
                    controller.cityInputTarget.dataset.filter = ``
                    controller.stateInputTarget.value = ``
                    controller.stateInputTarget.dataset.filter = ``

                    controller.kindInputTarget.dataset.kind = `personal`
                    controller.kindInputTarget.innerText = `Pessoal`
                    controller.kindDropdownTarget.value = `personal`

                    // controller.sameCityCheckboxTarget.checked = false
                  }

                } else {
                  controller.tradeNameInputTarget.value = "Favor adicionar a Razão Social do Tomador"
                  controller.fantasyNameInputTarget.value = "Favor adicionar o Nome Fantasia"
                  controller.municipalNumberInputTarget.value = "Favor adicionar a Inscrição Municipal"
                  // controller.openedAtTarget.value = ""

                  controller.fantasyNameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalNumberInputTarget.disabled = false

                  controller.zipInputTarget.value = ``
                  controller.ibgeInputTarget.value = ``
                  controller.streetInputTarget.value = ``
                  controller.numberInputTarget.value = ``
                  controller.complementInputTarget.value = ``
                  controller.districtInputTarget.value = ``
                  controller.cityInputTarget.value = ``
                  controller.cityInputTarget.dataset.filter = ``
                  controller.stateInputTarget.value = ``
                  controller.stateInputTarget.dataset.filter = ``

                  controller.kindInputTarget.dataset.kind = `personal`
                  controller.kindInputTarget.innerText = `Pessoal`
                  controller.kindDropdownTarget.value = `personal`
                  

                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }
      }
    }
  }

  dateMask(ev) {
    this.numbersController.changeNumberToDate(ev)
  }

  numberMask(ev) {
    // this.numbersController.changeNumberToNumber(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.numbersController.fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  checkIfHasTaker(type, number) {
    var diffHelper = true
    this.saveController.takers.forEach(element => {
      if (element.id_number == number) {
        this.saveAccountBtnTarget.disabled = true
        this.saveAccountBtnTarget.classList.add("d-none")
        var message = `O Tomador já está cadastrado`
        this.setErrorMessage(message)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 2000)
        diffHelper = false
      } else {
        if (diffHelper) {
          this.saveAccountBtnTarget.disabled = false
          this.saveAccountBtnTarget.classList.remove("d-none")
        }
      }
    });
  }

  validateSameCity(ev) {

    var ibge = ev.currentTarget.value

    // if (ibge == this.saveController.current_clinic.address_list[0].ibge) {
    //   this.sameCityCheckboxTarget.checked = true
    // } else {
    //   this.sameCityCheckboxTarget.checked = false
    // }
  }

  validateEmail(ev) {
    var mail = ev.currentTarget.value

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail) || mail == ``) {
      this.invalidEmail = false
    } else {
      this.setErrorMessage(`O e-mail digitado é inválido!`)
      this.invalidEmail = true
    }
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      if (controller.hasErrorMessageTarget) {
        controller.errorMessageTarget.innerText = ``
      }
    }, 5000);
  }

  zipMask(ev) {
    this.numbersController.changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.ibgeInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    // this.stateDropdownBtnTarget.disabled = false
    // this.cityDropdownBtnTarget.disabled = false
    this.stateInputTarget.disabled = false
    this.cityInputTarget.disabled = false
    this.ibgeInputTarget.disabled = false
    // this.sameCityCheckboxTarget.checked = false
  }

  closeForm() {
    this.streetInputTarget.value = ``
    this.ibgeInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    // this.cityDropdownTarget.value = ``
    // this.cityInputTarget.innerText = ``
    // this.cityInputTarget.dataset.city = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    // this.stateDropdownTarget.value = ``
    // this.stateInputTarget.innerText = ``
    // this.stateInputTarget.dataset.state = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = true
    this.districtInputTarget.disabled = true
    // this.stateDropdownBtnTarget.disabled = true
    // this.cityDropdownBtnTarget.disabled = true
    this.stateInputTarget.disabled = true
    this.cityInputTarget.disabled = true

    // this.sameCityCheckboxTarget.checked = false
  }

  searchZip(ev) {

    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.numbersController.fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                // controller.stateDropdownTarget.value = dados.uf
                // controller.stateInputTarget.innerText = dados.uf
                // controller.stateInputTarget.dataset.state = dados.uf
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                // controller.cityDropdownTarget.value = dados.localidade
                // controller.cityInputTarget.innerText = dados.localidade
                // controller.cityInputTarget.dataset.city = dados.localidade
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
              }
              if (dados.ibge == "") {
                controller.ibgeInputTarget.disabled = false
                // controller.sameCityCheckboxTarget.checked = false
              } else {
                controller.ibgeInputTarget.value = dados.ibge
                controller.ibgeInputTarget.disabled = true

                // if (dados.ibge == controller.saveController.current_clinic.address_list[0].ibge) {
                //   controller.sameCityCheckboxTarget.checked = true
                // } else {
                //   controller.sameCityCheckboxTarget.checked = false
                // }

              }
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        this.cleanForm();
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    } else if (ev.type == "focus" && this.zipInputTarget.value.length < 10) {
      this.closeForm();
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/accounts/addresses/cities_ibge_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element[0]}" data-ibge="${element[1]}" data-filter="${element[1]}" data-text="${element[0]}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element[0]}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
          // this.cityListTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterCity(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      this.ibgeInputTarget.value = ev.currentTarget.dataset.filter
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterState(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)

      var state = ev.currentTarget.value
      this.getCitiesByState(state)
    }
  }

  cpfMask(ev) {
    this.numbersController.changeNumberToCpf(ev)
  }

  cnpjMask(ev) {
    this.numbersController.changeNumberToCnpj(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.numbersController.fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.numbersController.fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveAccountBtnTarget) {

        if (controller.kind == `individual`) {
          if (controller.cpf_result && controller.cpf_result.invalid) {
            len += 1
          }

          if (controller.hasCpfInput && controller.cpfInputTarget.value == ``) {
            len += 1
          }

          if (controller.hasNameInputTarget && (controller.nameInputTarget.value == undefined || controller.nameInputTarget.value == `` || controller.nameInputTarget.value == `Favor adicionar o Nome do Tomador`)) {
            len += 1
          }

          if (controller.hasSexInputTarget && controller.sexInputTarget.innerText == ``) {
            len += 1
          }
        } else if (controller.kind == `legal`) {
          if (controller.cnpj_result && controller.cnpj_result.invalid) {
            len += 1
          }

          if (controller.hasCnpjInput && controller.cnpjInputTarget.value == ``) {
            len += 1
          }

          if (controller.hasTradeNameInputTarget && (controller.tradeNameInputTarget.value == undefined || controller.tradeNameInputTarget.value == `` || controller.tradeNameInputTarget.value == `Favor adicionar a Razão Social do Tomador`)) {
            len += 1
          }

          if (controller.hasFantasyNameInputTarget && (controller.fantasyNameInputTarget.value == undefined || controller.fantasyNameInputTarget.value == `` || controller.fantasyNameInputTarget.value == `Favor adicionar o Nome Fantasia`)) {
            len += 1
          }

          if (controller.hasMunicipalNumberInputTarget && controller.municipalNumberInputTarget.value == `Favor adicionar a Inscrição Municipal`) {
            len += 1
          }
        }

        if (controller.hasZipInputTarget && controller.zipInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasIbgeInputTarget && (controller.ibgeInputTarget.value == `` || controller.ibgeInputTarget.value.length < 7)) {
          len += 1
        }

        if (controller.hasNumberInputTarget && controller.numberInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasCityInputTarget && controller.cityInputTarget.value == ``) {
          len += 1
        }

        if (controller.invalidEmail) {
          len += 1
        }

        if (len == 0) {
          controller.saveAccountBtnTarget.disabled = false
        } else {
          controller.saveAccountBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  layout() {

  }

}

// parcela
// city_service_code = ctiss
// federal_service_code = inicio ctiss
// cnae_code = cnae

// taxation_type = comparar cidades
// additional_information ? 
