import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "masterDropdown", "masterDropdownBtn", "masterInput", "masterList",
                    "groupDropdown", "groupDropdownBtn", "groupInput", "groupList",
                    "numberInput", "humanizeInput", "nameInput", "notesInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `users--works--permissions--features--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.getControllerByIdentifier("users--works--permissions--features--index").clearList()
  }

  saveFeature() {
    this.send_data = { current_user: {}, feature: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.feature.id = this.current_feature.id
    }
    
    this.send_data.feature.master = this.masterInputTarget.dataset.master
    this.send_data.feature.group = this.groupInputTarget.dataset.group
    this.send_data.feature.humanize = this.humanizeInputTarget.value
    this.send_data.feature.name = this.nameInputTarget.value
    this.send_data.feature.notes = this.notesInputTarget.value

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.actionMode == `new` || this.actionMode == `copy`) {
      var url = "/users/permissions/features/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/permissions/features/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/permissions/features/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {          
          var feature = response.data.cln
          if (controller.actionMode == `new` || controller.actionMode == `copy`) {
            controller.application.features[controller.application.features.length] = feature
          } else if (controller.actionMode == `edit`) {
            controller.application.features.forEach((element, i) => {
              if (element.id == feature.id) {
                controller.application.features.splice(controller.application.features.indexOf(element), 1, feature)
              }
            })
          }

          controller.cancelSave()
          controller.getControllerByIdentifier("users--works--permissions--features--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.user_permissions_features.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveFeature" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Master</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="masterDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="masterDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="masterInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="masterList" data-app--helpers--search-target="searchList">
                                  <li data-master="App" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">App</li>
                                  <li data-master="Usuários" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Usuários</li>
                                  <li data-master="Financeiro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>
                                  <li data-master="Comercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                  <li data-master="Operações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Operações</li>
                                  <li data-master="Desenvolvimento" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Desenvolvimento</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Grupo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="groupDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="groupDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="groupInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="groupList" data-app--helpers--search-target="searchList">
                                  <li data-group="Usuários" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Usuários</li>
                                  <li data-group="Trabalho" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Trabalho</li>
                                  <li data-group="Contas" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contas</li>
                                  <li data-group="Empresas" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Empresas</li>
                                  <li data-group="Páginas" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Páginas</li>
                                  <li data-group="Conteúdo" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Conteúdo</li>
                                  <li data-group="Marketing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Marketing</li>
                                  <li data-group="Vendas" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                  <li data-group="Sidebar" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sidebar</li>
                                  <li data-group="Produtos" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Produtos</li>
                                  <li data-group="Onboards" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Onboards</li>
                                  <li data-group="Ongoings" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ongoings</li>
                                  <li data-group="Contratações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contratações</li>
                                  <li data-group="Clientes" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clientes</li>
                                  <li data-group="Demonstrações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Demonstrações</li>
                                  <li data-group="Recebíveis" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebíveis</li>
                                  <li data-group="Pagáveis" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pagáveis</li>
                                  <li data-group="Apurações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Apurações</li>
                                  <li data-group="Configurações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Configurações</li>
                                  <li data-group="Desenvolvimento" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Desenvolvimento</li>
                                  <li data-group="Performance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Performance</li>
                                  <li data-group="Atividades" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Atividades</li>
                                  <li data-group="Comunicação" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comunicação</li>
                                  <li data-group="Remunerações" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Remunerações</li>
                                  <li data-group="Recrutamento" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recrutamento</li>
                                  <li data-group="Chamados" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chamados</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Enum</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Enum" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="humanizeForm">Nome</label>
                            <input aria-describedby="humanizeFormHelp" class="form-control form-valid-control" id="humanizeForm" data-${this.controllerName}-target="humanizeInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notesForm">Anotações</label>
                            <input aria-describedby="notesFormHelp" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--features--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Funcionalidade`

      } else if (controller.actionMode == `edit` || controller.actionMode == `copy`) {
        if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Funcionalidade`
        } else if (controller.actionMode == `copy`) {
          controller.formTitleTarget.innerText = `Nova Funcionalidade`
        }

        controller.humanizeInputTarget.value = controller.current_feature.humanize
        controller.nameInputTarget.value = controller.current_feature.name
        controller.notesInputTarget.value = controller.current_feature.notes

        controller.masterDropdownTarget.value = controller.current_feature.master
        controller.masterInputTarget.innerText = controller.current_feature.master
        controller.masterInputTarget.dataset.master = controller.current_feature.master

        controller.groupDropdownTarget.value = controller.current_feature.group
        controller.groupInputTarget.innerText = controller.current_feature.group
        controller.groupInputTarget.dataset.group = controller.current_feature.group

        controller.masterDropdownBtnTarget.disabled = false
        controller.groupDropdownBtnTarget.disabled = false
        controller.nameInputTarget.disabled = false

        controller.humanizeInputTarget.disabled = false
        controller.notesInputTarget.disabled = false

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.masterInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.groupInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.humanizeInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
