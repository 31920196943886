import { Controller } from "stimulus"
import { jsPDF } from "jspdf";

export default class extends Controller {
  static targets = ["viewReceiptCard", "viewTitle", "viewReceiptCardBody", "printReceiptBtn", "previewCard", "editReceiptBtn", "printCardView", "printElement", "deleteReceiptBtn"]

  connect() {
    this.controllerName = `operations--products--bookings--receipts--show`
    this.send_data = { current_user: {}, receipt: {}, notification: {} }
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewReceiptCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Recibo Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewReceiptCardBody"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").showTarget.innerHTML = html
  }
  
  printReceipt() {
    var html = `<div class="row my-5" data-operations--products--bookings--entities--show-target="printCardElement">
                  <div class="col-md-6 mx-auto">
                    ${this.printCardViewTarget.innerHTML}
                  </div>
                </div>`
    

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--entities--show").contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      var element = controller.getControllerByIdentifier("operations--products--bookings--entities--show").printElementTarget
      var filename = `Recibo ${controller.current_receipt.sponsor_name}.pdf`

      var pdf = new jsPDF('p', 'pt', 'a4')

      pdf.html(element, {
        callback: function (pdf) {
          pdf.save(filename)
          controller.getControllerByIdentifier("operations--products--bookings--entities--show").printCardElementTarget.remove()
        }
      });
    })

    this.getControllerByIdentifier("operations--products--bookings--receipts--index").cleanCheckBox()
    this.getControllerByIdentifier("operations--products--bookings--receipts--show").doViewHtml()
  }

  editReceipt() {
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").current_receipt = this.current_receipt
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doEditReceipt()
  }

  deleteReceipt() {
    var r = confirm("Desejar apagar o Recibo?")
    this.deleteReceiptBtnTarget.disabled = true
    
    if (r) {
      this.actionMode = "delete"

      this.send_data.receipt.id = this.current_receipt.id
      this.send_data.receipt.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.notification.domain_id = this.application.booking.id
      this.send_data.notification.domain_type = "operation_products"
      this.send_data.notification.date_id = this.application.current_date.id
      this.send_data.notification.date_type = "operation_dates"
      this.send_data.notification.kind = `mode_${this.actionMode}`
      this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o recibo ${this.current_receipt.sponsor_sex} ${this.current_receipt.sponsor_name}, no valor de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_receipt.amount)} na competência ${this.application.current_date.month_pretty}`
      this.send_data.notification.account_name = this.application.current_user.name
      this.send_data.notification.account_id = this.application.current_user.account_id

      this.requestSave()
    } else {
      this.deleteReceiptBtnTarget.disabled = false
    }
    
  }

  requestSave() {

    var url = "/operations/products/bookings/receipts/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receipt = response.data.cln
          receipt = controller.getControllerByIdentifier("operations--products--bookings--entities--show").updateReceipt(receipt)

          if (receipt.active) {
            controller.application.receipts.forEach((element, i) => {
              if (element.id == receipt.id) {
                controller.application.receipts.splice(controller.application.receipts.indexOf(element), 1, receipt)
              }
            })
          } else {
            controller.application.receipts.forEach((element, i) => {
              if (element.id == receipt.id) {
                controller.application.receipts.splice(controller.application.receipts.indexOf(element), 1)
              }
            })
          }
          controller.getControllerByIdentifier("operations--products--bookings--receipts--index").doDataTable()
          controller.doViewHtml()
          controller.send_data = { current_user: {}, receipt: {}, notification: {} }
        } else {
          controller.deleteReceiptBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }


  doReceiptViewHtml() {

    if (this.application.permissions.medbooking_receipts.can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteReceiptBtn" data-action="click->${this.controllerName}#deleteReceipt" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Recibo</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.medbooking_receipts.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editReceiptBtn" data-action="click->${this.controllerName}#editReceipt" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Recibo</span>`
    } else {
      var editBtnHtml = ``
    }
    var printBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="printReceiptBtn" data-action="click->${this.controllerName}#printReceipt" type="button">
                          <span class="material-icons f-1p25">print</span>
                        </button>
                        <span class="mc-tooltiptext">Imprimir Recibo</span>`

    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    var html = `<div data-${this.controllerName}-target="printCardView">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" id="printReceipt" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Recibo</h6>
                      <div class="card-actions ml-auto py-0 mc-tooltip">
                        ${printBtnHtml}
                      </div>
                      <div class="card-actions py-0 mc-tooltip">
                        ${editBtnHtml}
                      </div>
                      <div class="card-actions py-0 mc-tooltip">
                        ${deleteBtnHtml}
                      </div>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-operations--products--bookings--entities--show-target="printElement">
                      <div class="row my-1">
                        <div class="col-12">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              </tr>

                              <tr>
                                <td colspan="10" class="text-center s-title-6F706F s-title-500 s-title-1rem">
                                  Recibo Médico
                                </td>
                              </tr>

                              <tr>
                                <th colspan="1" class="f-065 align-middle px-0">
                                  <strong>Médico</strong> <br>
                                  <strong>CPF</strong>
                                </th>
                                <td colspan="9" class="f-065 align-middle px-0 text-left">
                                  ${this.current_receipt.doctor_name} <br>
                                  ${this.current_receipt.doctor_cpf_pretty}
                                </td>
                              </tr>

                              <tr>
                                ${spaceRow}
                              </tr>

                              <tr>
                                <td colspan="10" class="f-065 align-middle px-0 text-left">
                                  Recebi a importância de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_receipt.amount)},
                                  em ${this.current_receipt.split_pretty}, por meio de ${this.current_receipt.form_pretty},
                                  ${this.current_receipt.sponsor_sex} ${this.current_receipt.sponsor_name}, CPF: ${this.current_receipt.sponsor_cpf_pretty}
                                  referente a ${this.current_receipt.procedure_pretty}, conforme descrição abaixo, ${this.current_receipt.patient_sex} ${this.current_receipt.patient_name}, CPF: ${this.current_receipt.patient_cpf_pretty}
                                </td>
                              </tr>

                              <tr>
                                <td colspan="10" class="f-065 align-middle px-0 text-left">
                                  <strong>Descrição</strong>: ${this.current_receipt.description}
                                </td>
                              </tr>

                              <tr>
                                <td colspan="10" class="f-065 align-middle px-0 text-left">
                                  ${this.current_receipt.office_city}, ${this.current_receipt.date_full_extension}
                                </td>
                              </tr>
                              
                              <tr>
                                ${spaceRow}
                              </tr>

                              <tr>
                                <td colspan="3" class="f-065 align-middle px-0"></td>
                                <td colspan="4" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                  <hr class="m-0"> <br>
                                  ${this.current_receipt.signature_name} <br>
                                  ${this.current_receipt.signature_title} <br>
                                  ${this.current_receipt.signature_number}
                                </td>
                                <td colspan="3" class="f-065 align-middle px-0"></td>
                              </tr>

                              <tr>
                                ${spaceRow}
                              </tr>

                              <tr>
                                <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                  <small>${this.current_receipt.office_name}</small> <br>
                                  <small>
                                    ${this.current_receipt.office_street}, ${this.current_receipt.office_number}, ${this.current_receipt.office_complement},
                                    ${this.current_receipt.office_district}, ${this.current_receipt.office_city}/${this.current_receipt.office_state},
                                    CEP: ${this.current_receipt.office_postal_code_pretty}
                                  </small>
                                </td>
                              </tr>

                              <tr style="border-top: 0.5px solid rgba(80, 81, 79, 0.5)">
                                <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                  <small>Documento <strong>com validade</strong> para comprovante de gasto com saúde apenas na Declaração de Ajuste Anual Ano-Exercício ${this.application.current_date.year + 1}.</small>
                                </td>
                              </tr>

                              <tr>
                                <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                  <small>Código de Validação: ${this.current_receipt.token_validation} <br> Acesse www.medcapital.com.br/v/validar-meu-recibo para validar o recibo.</small>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").showTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
