import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "expenseCount", "expenseCountTooltip",
    "receivableCount", "receivableCountTooltip", "taxCount", "taxCountTooltip"]

  connect() {
    this.controllerName = `commercial--marketing--campaigns--dashboard--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--main").doMainDashboard()
  }

  goToTickets() {
    
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--user`).doPageGrid(element)
    this.getControllerByIdentifier(`users--works--permissions--entities--user`).get_route = "/users/permissions/entities/list_team_permissions"
    this.getControllerByIdentifier(`users--works--permissions--entities--user`).new_route = "/users/permissions/entities/create_permissions"
    this.getControllerByIdentifier(`users--works--permissions--entities--user`).destroy_route = "/users/permissions/entities/destroy_permissions"
    this.getControllerByIdentifier(`users--works--permissions--entities--user`).subdomain = "marketing_campaigns"
  }


  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var ticketBtn = `<button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>`

    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    if (this.application.permissions.marketing_campaign_permissions.can_assign) {
      var permissionsBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionsBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="submenuBtnGroup">
                  ${dashboardBtn}
                  ${ticketBtn}
                  ${permissionsBtn}
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      // controller.doTicketsCount()
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0

    this.application.tickets.forEach(element => {
      if (element.stage == `in_process`) {
        ticketCount += 1
      }
    })

    var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
    this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
    this.ticketCountTooltipTarget.innerText = `${ticketCount} Ticket(s) em Aberto`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}