import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPartnerCard", "viewTitle", "viewPartnerCardBody", "principalShow", "contactShow", "principalTab", "infoTab", "contactTab", "profileTab",
                    "infoShow", "profileShow", "councilShow", "councilTab"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--partners--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPartnerCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Sócio Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewPartnerCardBody" id="cardContactBody">
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").viewTarget.innerHTML = html
  }

  doPartnerHtml() {
    var html = `<ul class="nav nav-justified nav-tabs" role="tablist">
                  <li class="nav-item mc-tooltip" data-${this.controllerName}-target="principalTab">
                    <a data-action="click->${this.controllerName}#showPrincipal" aria-controls="principal" aria-selected="true" class="f-065 nav-link nav-dark px-0 active" data-toggle="tab" href="#principal" id="principal-tab" role="tab">
                      <span class="mx-0">
                        <span class="material-icons md-sm mx-0">account_box</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom" style="height:15px;">Principal</span>
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mc-tooltip" data-${this.controllerName}-target="councilTab">
                    <a data-action="click->${this.controllerName}#showCouncil" aria-controls="council" aria-selected="true" class="f-065 nav-link nav-dark px-0" data-toggle="tab" href="#council" id="council-tab" role="tab">
                      <span class="mx-0">
                        <span class="material-icons md-sm mx-0">medical_information</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom" style="height:15px;">Registro Conselho</span>
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mc-tooltip" data-${this.controllerName}-target="infoTab">
                    <a data-action="click->${this.controllerName}#showInfo" aria-controls="info" aria-selected="true" class="f-065 nav-link nav-dark px-0" data-toggle="tab" href="#info" id="info-tab" role="tab">
                      <span class="mx-0">
                        <span class="material-icons md-sm mx-0">badge</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom" style="height:15px;">Informações</span>
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mc-tooltip" data-${this.controllerName}-target="contactTab">
                    <a data-action="click->${this.controllerName}#showContact" aria-controls="contact" aria-selected="true" class="f-065 nav-link nav-dark px-0" data-toggle="tab" href="#contact" id="contact-tab" role="tab">
                      <span class="mx-0">
                        <span class="material-icons md-sm mx-0">import_contacts</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom" style="height:15px;">Contatos</span>
                      </span>
                    </a>
                  </li>
                  <li class="nav-item mc-tooltip" data-${this.controllerName}-target="profileTab">
                    <a data-action="click->${this.controllerName}#showProfile" aria-controls="profile" aria-selected="true" class="f-065 nav-link nav-dark px-0" data-toggle="tab" href="#profile" id="profile-tab" role="tab">
                      <span class="mx-0">
                        <span class="material-icons md-sm mx-0">manage_accounts</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom" style="height:15px;">Perfil</span>
                      </span>
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div aria-labelledby="principal-tab" class="tab-pane fade show active" role="tabpanel" id="principal"><div class="list-group" data-${this.controllerName}-target="principalShow"></div></div>
                  <div aria-labelledby="council-tab" class="tab-pane fade" role="tabpanel" id="council"><div class="list-group" data-${this.controllerName}-target="councilShow"></div></div>
                  <div aria-labelledby="info-tab" class="tab-pane fade" role="tabpanel" id="info"><div class="list-group" data-${this.controllerName}-target="infoShow"></div></div>
                  <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel" id="contact"><div class="list-group" data-${this.controllerName}-target="contactShow"></div></div>
                  <div aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel" id="profile"><div class="list-group" data-${this.controllerName}-target="profileShow"></div></div>
                </div>`

    this.viewTitleTarget.innerText = this.current_partner.name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewPartnerCardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewPartnerCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.showPrincipal()
      if (!controller.application.permissions[controller.info_permission].can_index) {
        controller.councilTabTarget.children[0].classList.add("disabled")
        controller.councilTabTarget.children[0].innerHTML = `<s>Registros</s>`
      }
      if (!controller.application.permissions[controller.info_permission].can_index) {
        controller.infoTabTarget.children[0].classList.add("disabled")
        controller.infoTabTarget.children[0].innerHTML = `<s>Informações</s>`
      }
      if (!controller.application.permissions[controller.contact_permission].can_index) {
        controller.contactTabTarget.children[0].classList.add("disabled")
        controller.contactTabTarget.children[0].innerHTML = `<s>Contatos</s>`
      }
      if (!controller.application.permissions[controller.profile_permission].can_index) {
        controller.profileTabTarget.children[0].classList.add("disabled")
        controller.profileTabTarget.children[0].innerHTML = `<s>Perfil</s>`
      }
    })
  }

  showPrincipal() {
    var html = `<div data-controller="operations--products--clinics--regularizations--partners--principal" data-operations--products--clinics--regularizations--partners--principal-target="main"></div>`
    $(".nav-tabs-indicator").addClass("nav-dark")

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.principalShowTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--principal").permission = controller.permission
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--principal").doPrincipalHtml()
    })
  }

  showCouncil() {
    var html = `<div data-controller="users--accounts--councils--info" data-users--accounts--councils--info-target="main"></div>`
    if (this.application.permissions[this.info_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.councilShowTarget.innerHTML = html)
      }).then(() => {
        controller.getControllerByIdentifier(`users--accounts--councils--info`).current_account = controller.current_partner
        controller.getControllerByIdentifier(`users--accounts--councils--info`).permission = controller.info_permission
        controller.getControllerByIdentifier(`users--accounts--councils--info`).doCouncilsHtml()
      })
    }
  }

  showInfo() {
    var html = `<div data-controller="users--accounts--documents--info" data-users--accounts--documents--info-target="main"></div>`
    if (this.application.permissions[this.info_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.infoShowTarget.innerHTML = html)
      }).then(() => {
        controller.getControllerByIdentifier(`users--accounts--documents--info`).current_account = controller.current_partner
        controller.getControllerByIdentifier(`users--accounts--documents--info`).permission = controller.info_permission
        controller.getControllerByIdentifier(`users--accounts--documents--info`).setCurrentInfo()
      })
    }
  }

  showContact() {
    var html = `<div data-controller="operations--products--clinics--regularizations--partners--contact" data-operations--products--clinics--regularizations--partners--contact-target="main"></div>`
    if (this.application.permissions[this.contact_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.contactShowTarget.innerHTML = html)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--contact`).permission = controller.contact_permission
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--contact`).doContactsHtml()
      })
    }
  }

  showProfile() {
    var html = `<div data-controller="operations--products--clinics--regularizations--partners--profile" data-operations--products--clinics--regularizations--partners--profile-target="main"></div>`
    if (this.application.permissions[this.profile_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.profileShowTarget.innerHTML = html)
      }).then(() => {
        var html = `<div class="row my-5 d-flex align-items-center justify-content-between">
                      <div class="col-12 py-3 text-center">
                        <span class="f-1 f-bold">Verificar o Perfil do Sócio com o Sucesso do Cliente</span>
                      </div>
                    </div>`

        controller.profileShowTarget.innerHTML = html
        // controller.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--contact`).permission = controller.profile_permission
        // controller.getControllerByIdentifier(`operations--products--clinics--regularizations--partners--contact`).doContactsHtml()
      })
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewPartnerCard"]
    var identifier = `${this.controllerName}`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
