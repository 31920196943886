
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "main", "team", "9999",
                    "allStageBtn", "todayStageBtn"]

  connect() {
    this.controllerName = `users--works--schedules--entities--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []    
    this.tableCols = 11    
    this.sort = { mode: `desc`, field: `date_token` }
    this.dateKind = `all`
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doListHtml() {
    var allBtn = `<span class="badge badge-secondary-primary mx-2 pointer" data-date-kind="all" data-action="click->${this.controllerName}#setSchedulesEv" data-${this.controllerName}-target="allStageBtn">Todos</span>`
    var todayBtn = `<span class="badge badge-secondary-success mx-2 pointer" data-date-kind="today" data-action="click->${this.controllerName}#setSchedulesEv" data-${this.controllerName}-target="todayStageBtn">Hoje</span>`

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addSchedule" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Agendamento</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshSchedules" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Agendamentos</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var opportunityHtml = ``
    if (this.controllerForm && this.controllerForm.identifier == `commercial--sales--opportunities--index--schedules`) {
      addLine = ``
      opportunityHtml += `<th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                            Produto
                            <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="product_name" data-mode="desc">south</span>
                            <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_name" data-mode="asc">north</span>
                          </th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                            Tipo
                            <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="kind_name" data-mode="desc">south</span>
                            <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_name" data-mode="asc">north</span>
                          </th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                            Estágio
                            <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="stage_number" data-mode="desc">south</span>
                            <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="stage_number" data-mode="asc">north</span>
                          </th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Fonte</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Campanha</th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                            Origem
                            <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="origin" data-mode="desc">south</span>
                            <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="origin" data-mode="asc">north</span>
                          </th>
                          <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Valor</th>`
    } else if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      opportunityHtml += `<th style="font-size:60%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                          <th style="font-size:60%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                          <th style="font-size:60%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                          <th style="font-size:60%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                          <th style="font-size:60%;" scope="col" class="pointer p-1 table-3 align-middle"></th>`
    }

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Agendamentos | ${allBtn} | ${todayBtn}</h6>
                    <input class="form-control f-070 w-30 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Data
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="schedule_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="schedule_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                Lead
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="guest_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="guest_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">
                                MedCapital
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:60%;" scope="col" class="pointer p-1 table-5 align-middle">Status</th>
                              ${opportunityHtml}
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      // controller.doDataTable()
      controller.setSchedulesDate()
    })
  }

  setSchedulesEv(ev) {
    this.dateKind = ev.currentTarget.dataset.dateKind

    this.setSchedulesDate()
  }

  setSchedulesDate() {
    if (this.dateKind == `all`) {
      this.date_kind_pretty = `Todos`
      this.allStageBtnTarget.classList.remove(`badge-secondary-primary`)
      this.allStageBtnTarget.classList.add(`badge-primary`)
      this.todayStageBtnTarget.classList.add(`badge-secondary-success`)
      this.todayStageBtnTarget.classList.remove(`badge-success`)
    } else if (this.dateKind == `today`) {
      this.date_kind_pretty = `Hoje`
      this.allStageBtnTarget.classList.add(`badge-secondary-primary`)
      this.allStageBtnTarget.classList.remove(`badge-primary`)
      this.todayStageBtnTarget.classList.remove(`badge-secondary-success`)
      this.todayStageBtnTarget.classList.add(`badge-success`)
    }

    this.doDataTable()
  }

  doDataTable() {
    var current_schedules = []

    if (this.dateKind == `all`) {
      current_schedules = this.application.schedules
    } else if (this.dateKind == `today`) {
      var date = new Date()
      var datePretty = this.getControllerByIdentifier(`app--helpers--date`).transformPrettyDate(date)
      this.application.schedules.forEach(element => {
        if (element.schedule_at_pretty == datePretty) {
          current_schedules[current_schedules.length] = element
        }
      })
    }

    if (this.sort.mode == `asc`) {
      var schedules = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_schedules, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var schedules = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_schedules, this.sort.field)
    }

    if (schedules.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(schedules.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (schedules[x] !== undefined) {
            internPage[internPage.length] = schedules[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: schedules.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Agendamentos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.scheduleTablePartial(element, data.length))
      })
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  scheduleTablePartial(element, length) {
    if (this.application.permissions[this.permission].can_update && (element.status == `booked`)) {
      var action = `data-action="click->${this.controllerName}#editSchedule"`
      var doneBtn = `<button data-action="click->${this.controllerName}#doneSchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-success">done</span>
                      <span class="mc-tooltiptext">Agendamento Realizada?</span>
                    </button>`
      var cancelBtn = `<button data-action="click->${this.controllerName}#cancelSchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-danger">clear</span>
                      <span class="mc-tooltiptext">Agendamento Cancelada?</span>
                    </button>`
      var refreshBtn = `<button data-action="click->${this.controllerName}#refreshSchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">refresh</span>
                          <span class="mc-tooltiptext">Agendamento Remarcado?</span>
                        </button>`
    } else {
      var action = ``
      var doneBtn = ``
      var cancelBtn = ``
      var refreshBtn = ``
    }

    if (element.midia == `video` && element.midia_link) {
      var videoBtn = `<button data-action="click->${this.controllerName}#videoSchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">ondemand_video</span>
                        <span class="mc-tooltiptext">Vídeo</span>
                      </button>`
    } else {
      var videoBtn = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var destroyBtn = `<button data-action="click->${this.controllerName}#destroySchedule" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar Agendamento?</span>
                        </button>`
    } else {
      var destroyBtn = ``
    }

    if (element.account_name == null || element.account_name == undefined) {
      element.account_name = `Não Definido`
    }

    if (element.guest_name == null || element.guest_name == undefined) {
      element.guest_name = `Não Definido`
    }

    var opportunityHtml = ``
    var opportunityId = ``
    if (this.controllerForm && this.controllerForm.identifier == `commercial--sales--opportunities--index--schedules` && element.opportunity) {
      opportunityId = element.opportunity.id
      opportunityHtml += `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.opportunity.product_name}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.opportunity.kind_name}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                            <span class="mb-0 mc-tooltip">
                              <span>${element.opportunity.stage_pretty}</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Estágio ${element.opportunity.stage_pretty}</span>
                            </span>
                          </td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.opportunity.source_pretty}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.opportunity.campaign_pretty}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.opportunity.origin}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.opportunity.total_amount_recurring)}</td>`
    } else if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      opportunityHtml += `<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${doneBtn}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${cancelBtn}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${refreshBtn}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${videoBtn}</td>
                          <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${destroyBtn}</td>`
    }


    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-opportunity-id="${opportunityId}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-opportunity-id="${opportunityId}">`
    }
    // data-action="click->${this.controllerName}#editSchedule"

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-left" ${action}>${element.schedule_at_full_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" ${action}><p class="mb-0 f-065 f-bold">${element.guest_name}</p></td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" ${action}>${element.account_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" ${action}>${element.status_pretty}</td>
                    ${opportunityHtml}
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  addSchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var options = { scheduleId: ``, actionMode: `new` }
      this.doSaveModal(options)
    }
  }

  editSchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id

      var options = { scheduleId: scheduleId, actionMode: `edit` }
      this.doSaveModal(options)
    } else if (this.controllerForm && this.controllerForm.identifier == `commercial--sales--opportunities--index--schedules`) {
      var opportunityId = ev.target.closest(".itemRow").dataset.opportunityId
      var obj = {}

      this.application.all_opportunities.forEach(element => {
        if (element.id == opportunityId) {
          obj = element
        }
      })
      window.open(obj.opportunity_path, `_blank`)
    }
  }

  doneSchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id

      var options = { scheduleId: scheduleId, actionMode: `done` }
      this.doSaveModal(options)
    }
  }

  cancelSchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id
      var options = { scheduleId: scheduleId, actionMode: `canceled` }

      this.doSaveModal(options)
    }
  }

  refreshSchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id
      
      var options = { scheduleId: scheduleId, actionMode: `postponed` }
      this.doSaveModal(options)
    }
  }

  destroySchedule(ev) {
    if (this.controllerForm && this.allowedControllers(this.controllerForm.identifier)) {
      var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id
      
      var options = { scheduleId: scheduleId, actionMode: `delete` }

      var r = confirm(`Tem certeza que deseja Apagar o Agendamento?`)
      if (r) { 
        this.doSaveModal(options)
      }
      
    }
  }

  videoSchedule(ev) {
    var modal = `users--works--schedules--entities--video-modal`
    var scheduleId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var currentSchedule = {}

    this.application.schedules.forEach(element => {
      if (element.id == scheduleId) {
        currentSchedule = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller.controllerForm
      controller.getControllerByIdentifier(modal).current_schedule = currentSchedule
      controller.getControllerByIdentifier(modal).open()
    })
  }

  doSaveModal(options) {
    var modal = `users--works--schedules--entities--save-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller.controllerForm
      controller.getControllerByIdentifier(modal).permission = controller.permission
      controller.getControllerByIdentifier(modal).accountList = controller.accountList
      controller.getControllerByIdentifier(modal).accountId = controller.accountId
      controller.getControllerByIdentifier(modal).accountName = controller.accountName
      controller.getControllerByIdentifier(modal).guestList = controller.guestList
      controller.getControllerByIdentifier(modal).guestId = controller.guestId
      controller.getControllerByIdentifier(modal).guestName = controller.guestName
      controller.getControllerByIdentifier(modal).guestType = controller.guestType
      controller.getControllerByIdentifier(modal).recordId = controller.recordId
      controller.getControllerByIdentifier(modal).recordType = controller.recordType
      controller.getControllerByIdentifier(modal).scheduleId = options.scheduleId
      controller.getControllerByIdentifier(modal).actionMode = options.actionMode
      if (controller.guestList.length > 0) {
        controller.getControllerByIdentifier(modal).open()
      } else {
        alert(`Favor adicionar os Participantes para o Agendamento`)
      }
    })
  }

  refreshSchedules(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.refreshMode == `record`) {
      var data = { schedule: { record_id: this.recordId, record_type: this.recordType }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      var url = "/users/works/schedules/entities/list_by_record"
    } else if (this.refreshMode == `kind`) {
      if (this.application.permissions[this.permission].can_manage) {
        var data = { schedule: { kind: this.kind }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
        var url = "/users/works/schedules/entities/list_by_kind"
      } else {
        var data = { schedule: { kind: this.kind, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
        var url = "/users/works/schedules/entities/list_by_kind_and_account"
      }
    }

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        if (controller.controllerForm && controller.allowedControllers(controller.controllerForm.identifier)) {
          controller.application.schedules = response.data.cln
        } else if (controller.controllerForm && controller.controllerForm.identifier == `commercial--sales--opportunities--index--schedules`) {
          controller.application.schedules = []
          response.data.cln.forEach(element => {
            if (element.has_opportunity) {
              controller.application.all_opportunities.forEach(opportunity => {
                if (opportunity.id == element.record_id) {
                  element.opportunity = opportunity
                }
              })
              controller.application.schedules[controller.application.schedules.length] = element
            }
          })
        }

        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  allowedControllers(identifier) {
    var allowedControllers = [
                              `commercial--sales--opportunities--entities--schedules`,
                              `users--works--recruitments--schedules--dashboard`,
                             ]

    if (allowedControllers.includes(identifier)) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

}
