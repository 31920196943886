import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "settings", "workings", "filter", "addons", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--dashboard`
  }

  doContractsDashboard() {
    var html = `<div data-${this.controllerName}-target="mainDashboard">
                  <div class="row">
                    <div class="col-12" data-${this.controllerName}-target="settings" data-controller="financials--books--clients--contracts--settings"></div>
                  </div>
                  <div class="row my-3">
                    <div class="col-4" data-${this.controllerName}-target="addons" data-controller="financials--books--clients--addons--list
                                                                                                    financials--books--clients--addons--save
                                                                                                    financials--books--clients--addons--upload"></div>
                    <div class="col-8" data-${this.controllerName}-target="workings" data-controller="financials--books--clients--contracts--workings"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--clients--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--books--clients--contracts--settings`).permission = `financial_client_contracts`
      controller.getControllerByIdentifier(`financials--books--clients--contracts--settings`).renewal_permission = `financial_client_renewals`
      controller.getControllerByIdentifier(`financials--books--clients--contracts--settings`).radar_permission = `financial_client_radars`
      controller.getControllerByIdentifier(`financials--books--clients--contracts--settings`).doMenuHtml()

      controller.getControllerByIdentifier(`financials--books--clients--addons--list`).controllerDashboard = controller
      controller.getControllerByIdentifier(`financials--books--clients--addons--list`).permission = `financial_client_addons`
      controller.getControllerByIdentifier(`financials--books--clients--addons--list`).gridElement = controller.addonsTarget

      controller.getControllerByIdentifier(`financials--books--clients--addons--save`).permission = `financial_client_addons`
      // controller.getControllerByIdentifier(`financials--books--clients--addons--list`).gridElement = controller.addonTarget
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}