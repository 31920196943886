import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "cardBody", "footerTable", "body"]

  connect() {
    this.controllerName = `users--works--activities--shared--modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.tableCols = 3
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doTicketHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doTicketHtml() {

    if (this.title) {
      var title = `Novo Ticket: <strong>${this.title}</strong>`
    } else {
      var title = `Novo Ticket`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">${title}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBody">
                    
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      // controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      controller.doTicketsDashboard()
      
    })
  }

  doTicketsDashboard() {
    var html = `<div class="row pt-4" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 text-center d-flex justify-content-center" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardBodyTarget.innerHTML = html)
    }).then(() => {
      
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = controller.permission
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 1
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).viewMode = `modal`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_count_controller = controller.ticket_count_controller
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: controller.ticket_board.board_id,
        board_type: controller.ticket_board.board_type,
        board_subtype: controller.ticket_board.board_subtype,
        board_body: controller.ticket_board.board_body,
        board_path: controller.ticket_board.board_path,
        board_name: controller.ticket_board.board_name,
        date_id: controller.ticket_board.date_id,
        date_type: controller.ticket_board.date_type
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: controller.comment_board.board_id,
        board_type: controller.comment_board.board_type,
        date_id: controller.comment_board.date_id,
        date_type: controller.comment_board.date_type
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).createTicket()

    })
  }

  

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}