import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "view", "3333", "4444", "5555", "menuCard", "tableRow", "flowRow", "menuRows"]

  connect() {
    this.controllerName = `users--works--recruitments--applicants--dashboard`
  }

  doApplicantsDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 px-0">
                    <div class="row w-100">
                      <div class="col-2 px-2">
                        <div class="card pointer bg-primary" data-${this.controllerName}-target="menuCard" data-feature="table" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Lista</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="menuCard" data-feature="flow" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Pipeline</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        <div class="row" data-controller="users--works--recruitments--applicants--list users--works--recruitments--applicants--save" data-${this.controllerName}-target="tableRow menuRows"></div>
                        <div class="row d-none" data-controller="users--works--recruitments--applicants--pipeline--dashboard" data-${this.controllerName}-target="flowRow menuRows"></div>
                      </div>
                    </div>
                  </div>
                </div>`

    // `<div class="col-12" data-${this.controllerName}-target="main" data-controller="users--works--recruitments--applicants--list users--works--recruitments--applicants--save"></div>`
    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).applicantsRowTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--list`).permission = `user_works_recruitments_applicants`
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--save`).permission = `user_works_recruitments_applicants`
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--pipeline--dashboard`).permission = `user_works_recruitments_applicants`
      
      
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--pipeline--dashboard`).doFlowGrid()
    })
  }

  toggleCard(ev) {
    var element = ev.currentTarget

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.menuRowsTargets.forEach(element => {
      element.classList.add(`d-none`)
    })

    element.classList.add(`bg-primary`)
    this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
