import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "duration", "endBtn", "finishBtn", "callBtn", "titleCard"]

  connect() {
    this.controllerName = `app--helpers--call-modal`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.progressCountCall()
    })
  }

  close() {
    this.stopCountRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    this.modalTarget.remove()
  }

  doCall() {
    this.titleCardTarget.innerText = `Iniciando Chamada`
    this.send_data = { current_user: {}, call: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission
    
    this.send_data.call.record_id = this.record_id
    this.send_data.call.record_type = this.record_type
    this.send_data.call.record_name = this.record_name
    this.send_data.call.obj_id = this.obj_id
    this.send_data.call.obj_type = this.obj_type
    this.send_data.call.person_id = this.person_id
    this.send_data.call.person_type = this.person_type
    this.send_data.call.person_name = this.person_name
    this.send_data.call.dial_number = this.dial_number
    this.send_data.call.phone_number = this.phone_number
    this.send_data.call.status = `ongoing`
    this.send_data.call.caller_id = this.caller_id
    this.send_data.call.kind = this.kind

    var url = "/app/communications/voip/calls/make_call"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.call = response.data.cln
          controller.titleCardTarget.innerText = `Chamada em Andamento`
          controller.progressCountCall()
          controller.toggleBtns(true)
        } else {
          controller.close()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  forceEndCall() {
    this.titleCardTarget.innerText = `Finalizando Chamada`
    this.send_data = { current_user: {}, call: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.send_data.call.id = this.call.id

    var url = "/app/communications/voip/calls/hang_up_call"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopCountRefreshing()
          controller.titleCardTarget.innerText = `Chamada Finalizada`
          controller.call = response.data.cln
          controller.toggleBtns(false)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  toggleBtns(value) {
    if (value) {
      this.endBtnTarget.classList.remove(`d-none`)
      this.finishBtnTarget.classList.remove(`d-none`)
      this.callBtnTarget.classList.add(`d-none`)
    } else {
      this.endBtnTarget.classList.add(`d-none`)
      this.finishBtnTarget.classList.add(`d-none`)
      this.callBtnTarget.classList.remove(`d-none`)
    }
  }

  progressCountCall(value) {
    this.stopCountRefreshing()
    this.application.callCount = 0

    var controller = this
    this.refreshCountTimer = setInterval(() => {
      controller.application.callCount += 1
      controller.doCountCall()
    }, 1000)
  }

  doCountCall() {
    var distance = this.application.callCount
    var minutes = Math.floor((distance % (60 * 60)) / 60);
    var seconds = Math.floor(distance % (60));

    if (minutes < 0) {
      minutes = `00`
    } else if (minutes < 10) {
      minutes = `0${minutes}`
    }

    if (seconds < 0) {
      seconds = `00`
    } else if (seconds < 10) {
      seconds = `0${seconds}`
    }

    var countUp = `${minutes}:${seconds}`

    this.durationTarget.innerText = countUp

  }

  stopCountRefreshing() {
    if (this.refreshCountTimer) {
      clearInterval(this.refreshCountTimer)
      this.durationTarget.innerText = ``
    }
  }

  
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}