import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "subtitle", "cardFilter", "search", "searchInput",
                    "screeningFlow", "screeningTotal", "bookingFlow", "bookingTotal", "testingFlow", "testingTotal",
                    "interviewFlow", "interviewTotal", "closingFlow", "closingTotal", "onboardingFlow", "onboardingTotal"]



  connect() {
    this.controllerName = `users--works--recruitments--applicants--pipeline--dashboard`
    this.account_id = this.application.current_user.account_id
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.totalColumns = 5
    this.journeys = this.applicantJourneys()
  }

  doApplicantFlowGrid() {

    var htmlCol = ``

    this.journeys.forEach(element => {
      htmlCol += `<div class="col-${Math.round(12 / this.totalColumns)} px-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${element.name_pretty}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1 f-065">
                            Total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="${element.name}Total">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="${element.name}Flow"></div>
                  </div>`
    })

    var html = `<div class="col-12 px-0" data-controller="users--works--recruitments--applicants--pipeline--flow">
                  <div class="row">
                    ${htmlCol}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--recruitments--applicants--dashboard").flowRowTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--recruitments--applicants--pipeline--flow`).permission = `user_works_recruitments_applicants`
      controller.getControllerByIdentifier("users--works--recruitments--applicants--pipeline--flow").doFlowHtml()
    })
  }

  setApplicants() {
    this.doApplicantFlowGrid()
  }

  doFlowGrid() {

    var htmlCol = ``
    for (let index = 0; index < this.totalColumns; index++) {
      htmlCol += `<div class="col-${Math.round(12 / this.totalColumns)}">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                  </div>`

    }

    var html = `<div class="col-12 px-0">
                  <div class="row w-100">
                    ${htmlCol}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--recruitments--applicants--dashboard").flowRowTarget.innerHTML = html)
    }).then(() => {
      controller.setApplicants()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  applicantJourneys() {

    var journeys = [
      {
        name: `screening`,
        name_pretty: `Triagem`,
        next: `booking`,
        next_pretty: `Agendamento`,
      },
      {
        name: `booking`,
        name_pretty: `Agendamento`,
        next: `testing`,
        next_pretty: `Testes`,
      },
      {
        name: `testing`,
        name_pretty: `Testes`,
        next: `interview`,
        next_pretty: `Entrevista`,
      },
      {
        name: `interview`,
        name_pretty: `Entrevista`,
        next: `closing`,
        next_pretty: `Fechamento`,
      },
      {
        name: `closing`,
        name_pretty: `Fechamento`,
        next: `onboarding`,
        next_pretty: `Onboarding`,
      },
    ]

    return journeys
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
