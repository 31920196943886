import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "addTaxBtn", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--taxes--index`
    this.tableCols = 9
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `description` }
    this.hasShow = false

    if (this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--taxes--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--taxes--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
    }
  }

  showTax(ev) {
    var controllerShow = this.getControllerByIdentifier("operations--products--clinics--financials--taxes--show")
    var taxId = ev.target.closest(".itemRow").dataset.id

    this.application.taxes.forEach(element => {
      if (element.id == this.checked_id) {
        controllerShow.current_tax = element
      }
    })

    controllerShow.doTaxHtml()
  }

  addTax() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    var date = new Date()

    if (this.application.current_date.open) {
      if (this.application.current_date.year == date.getFullYear() && this.application.current_date.month == date.getMonth() + 1) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência não foi Encerrada!`, 2000)
      } else {
        this.getControllerByIdentifier("operations--products--clinics--financials--taxes--create").actionMode = "new"
        this.getControllerByIdentifier("operations--products--clinics--financials--taxes--create").current_transfer = {}
        this.getControllerByIdentifier("operations--products--clinics--financials--taxes--create").doFormHtml()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 4000)
    }
  }

  editTax(ev) {
    if (this.application.current_date.open) {
      var id = ev.currentTarget.closest(".itemRow").dataset.id
      this.application.taxes.forEach(element => {
        if (element.id == id) {
          this.getControllerByIdentifier("operations--products--clinics--financials--taxes--update").current_tax = element
        }
      })
      this.getControllerByIdentifier("operations--products--clinics--financials--taxes--update").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 4000)
    }
  }

  checkDate(ev) {
    if (this.application.current_date.open == false) {
      alert(`Competência já está fechada. Favor abrí-la para confirmar o pagamento.`)
    } else {
      this.doUpdatePaidModalHtml(ev)
    }
  }

  doUpdatePaidModalHtml(ev) {
    var modalController = `operations--products--clinics--financials--taxes--paid-modal`
    var taxId = ev.currentTarget.closest(".itemRow").dataset.id

    var html = `<div class="modal fade" data-controller="${modalController}" data-${modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Pagamento de Guia de Imposto</strong></h6><br>
                      </div>
                      <div class="modal-body p-3">
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${modalController}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="totalAmountForm">Vlr Total</label>
                                <input disabled aria-describedby="totalAmountFormHelp" class="form-control" id="totalAmountForm" data-${modalController}-target="totalAmountInput" placeholder="Valor Total" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueAtForm">Vencimento</label>
                                <input disabled aria-describedby="dueAtFormHelp" class="form-control" id="dueAtForm" data-${modalController}-target="dueAtInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="paidAtForm">Pagamento</label>
                                <input aria-describedby="paidAtFormHelp" class="form-control" id="paidAtForm" data-${modalController}-target="paidAtInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Canal Pagamento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${modalController}-target="channelDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${modalController}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${modalController}-target="channelInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${modalController}-target="channelList" data-app--helpers--search-target="searchList">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${modalController}#close">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${modalController}#updatePaid" data-${modalController}-target="saveBtn">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--taxes--paid-modal`).permission = this.permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--taxes--paid-modal`).open(taxId)
    })

    // document.body.insertAdjacentHTML("beforeend", html)
  }

  refreshTax() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, tax: {} }

    this.send_data.tax.clinic_id = this.application.clinic.id
    this.send_data.tax.date_id = this.application.current_date.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/financials/taxes/refresh"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var currentIds = controller.mapElements(controller.application.taxes, `id`)
          var responseIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(new_tax => {
            if (currentIds.includes(new_tax.id) && responseIds.includes(new_tax.id)) {
              controller.application.taxes.forEach((element, i) => {
                if (element.id == new_tax.id) {
                  controller.application.taxes.splice(controller.application.taxes.indexOf(element), 1, new_tax)
                }
              })
            } else if (currentIds.includes(new_tax.id)) {
              controller.application.taxes.forEach((element, i) => {
                if (element.id == new_tax.id) {
                  controller.application.taxes.splice(controller.application.taxes.indexOf(element), 1)
                }
              })
            } else if (responseIds.includes(new_tax.id)) {
              controller.application.taxes[controller.application.taxes.length] = new_tax
            }
          })

          controller.doDataTable()
          controller.submenuController.doTaxesCount()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para atualizar os Impostos`)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addTax" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Impostos</span>`
    } else {
      var addLine = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var refreshLine = `<span data-action="click->${this.controllerName}#refreshTax" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Impostos ${this.application.current_date.name}</span>`
    } else {
      var refreshLine = ``
    }


    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`


    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Impostos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">Imposto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Competência</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Vencimento</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Valor Principal</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Multa+Juros</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Valor Total</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Guia</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Comp.</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
      
    })
  }

  doDataTable() {
    var current_taxes = []

    this.application.taxes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_taxes[current_taxes.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var taxes = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_taxes, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var taxes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_taxes, this.sort.field)
    }

    if (taxes.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(taxes.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (taxes[x] !== undefined) {
            internPage[internPage.length] = taxes[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: taxes.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Impostos na ${this.application.current_date.name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.taxTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  taxTablePartial(element, length) {

    if (this.application.permissions[this.permission].can_update && element.status == `active`) {
      var edit = `<button data-action="click->${this.controllerName}#editTax" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.status == `active`) {
      var status = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">${element.status_pretty}</span></span>`
    } else if (element.status == `inactive`) {
      var status = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">${element.status_pretty}</span></span>`
    }

    if (element.status == `paid`) {
      var paidStatus = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">price_check</span>
                      <span class="mc-tooltiptext">Pago dia ${element.paid_at_pretty}</span>
                    </span>`
    } else if (element.status == `not_paid`) {
      var paidStatus = `<span class="mc-tooltip badge badge-danger">
                              <span class="material-icons md-200 md-white pointer" data-action="click->${this.controllerName}#checkDate">money_off_csred</span>
                              <span class="mc-tooltiptext">Não Pago</span>
                            </span>`
    }

    if (element.has_bill) {
      var hasBill = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
    } else {
      var hasBill = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
    }

    if (element.has_receipt) {
      var hasReceipt = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default help">done</span><span class="mc-tooltiptext">Tem Arquivo</span></span>`
    } else {
      var hasReceipt = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default help">clear</span><span class="mc-tooltiptext">Não Tem Arquivo</span></span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editTax">${element.description}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editTax">${element.date_accrual}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#editTax">${element.due_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${paidStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.principal)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(Number(element.interests) + Number(element.fine))}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${hasBill}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${hasReceipt}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    
    this.getControllerByIdentifier("operations--products--clinics--financials--taxes--show").doViewHtml()
    this.getControllerByIdentifier("operations--products--clinics--financials--taxes--create").stopRefreshing()

    this.doDataTable()
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
