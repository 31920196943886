import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "addNewTaxFilingBtn",
                    "calculation", "goalLead", "goalLeadInput", "goalLeadInputSpan", "goalAmount", "goalAmountInput", "goalAmountInputSpan",
                    "goalGain", "goalGainInput", "goalGainInputSpan", "salesLeads", "sideCard", "report", "content"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--dashboards--main`
    this.application.domain = {}
    this.application.permissions = {}
    this.pageLoaded = false
    this.application.account_id = this.application.current_user.account_id
    this.doPageGrid()
    // this.addNewTaxFiling()
    // this.doIndexSubmenuHtml()
    // this.getCurrentUserPermissions()
    // this.getCommercialDates()
    // this.getOpportunities()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  changeGoalLead() {
    this.goalLeadTarget.classList.add("d-none")
    this.goalLeadInputSpanTarget.classList.remove("d-none")
  }

  changeGoalAmount() {
    this.goalAmountTarget.classList.add("d-none")
    this.goalAmountInputSpanTarget.classList.remove("d-none")
  }

  changeGoalGain() {
    this.goalGainTarget.classList.add("d-none")
    this.goalGainInputSpanTarget.classList.remove("d-none")
  }

  updateCalculation(ev) {
    ev.currentTarget.disabled = true
    var field = ev.currentTarget.dataset.field

    if (field == `goal_leads`) {
      var newValue = this.goalLeadInputTarget.value
      var oldValue = this.application.current_calculation.goal_leads
    } else if (field == `goal_amount_recurring_cash`) {
      var newValue = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.goalAmountInputTarget.value)
      var oldValue = this.application.current_calculation.goal_amount_recurring_cash
    } else if (field == `goal_gain_recurring_cash`) {
      var newValue = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.goalGainInputTarget.value)
      var oldValue = this.application.current_calculation.goal_gain_recurring_cash
    }

    this.requestFetchParams = { url: "/commercial/config/calculations/update", method: "PUT", data: { current_user: {}, calculation: {} } }

    this.requestFetchParams.data.current_user.current_user_id = this.application.current_user.id
    this.requestFetchParams.data.calculation.id = this.application.current_calculation.id
    this.requestFetchParams.data.calculation.field = field
    this.requestFetchParams.data.calculation.value = newValue

    if (parseInt(newValue * 100) != parseInt(oldValue * 100)) {
      this.requestFetch()
    } else {
      this.doCalculationCards()
    }
  }

  requestFetch() {
    const init = { method: this.requestFetchParams.method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.requestFetchParams.data) }
    var controller = this
    fetch(this.requestFetchParams.url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.application.current_calculation = response.data.cln
        }
        controller.doCalculationCards()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        controller.doCalculationCards()
      })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cancelEdit(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      var field = ev.currentTarget.dataset.field

      if (field == `goal_leads`) {
        this.goalLeadTarget.classList.remove("d-none")
        this.goalLeadInputSpanTarget.classList.add("d-none")
      } else if (field == `goal_amount`) {
        this.goalAmountTarget.classList.remove("d-none")
        this.goalAmountInputSpanTarget.classList.add("d-none")
      } else if (field == `goal_gain`) {
        this.goalGainTarget.classList.remove("d-none")
        this.goalGainInputSpanTarget.classList.add("d-none")
      }
    }
  }

  doCalculationCards() {

    if (this.application.permissions.commercial_main_dashboard.can_update) {
      var goalLeads = `<span class="pointer" data-${this.controllerName}-target="goalLead" data-action="click->${this.controllerName}#changeGoalLead">
                        ${this.application.current_calculation.goal_leads}
                      </span>
                      <span class="d-none" data-${this.controllerName}-target="goalLeadInputSpan">
                        <div class="row d-flex align-items-center">
                          <div class="col-8 pr-0">
                            <div class="form-group w-100">
                              <div class="floating-label floating-label-sm">
                                <label for="goalLeadForm">Objetivo</label>
                                <input id="goalLeadForm" aria-describedby="goalLeadFormHelp" class="form-control f-1p25" data-field="goal_leads" autofocus data-${this.controllerName}-target="goalLeadInput" data-action="blur->${this.controllerName}#cancelEdit keyup->${this.controllerName}#cancelEdit" type="number" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <button data-action="click->${this.controllerName}#updateCalculation" data-field="goal_leads" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">save</span>
                              <span class="mc-tooltiptext">Salvar</span>
                            </button>
                          </div>
                        </div>
                      </span>`
      var goalAmount = `<span class="pointer" data-${this.controllerName}-target="goalAmount" data-action="click->${this.controllerName}#changeGoalAmount">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.goal_amount_recurring_cash)}
                        </span>
                        <span class="d-none" data-${this.controllerName}-target="goalAmountInputSpan">
                          <div class="row d-flex align-items-center">
                            <div class="col-8 pr-0">
                              <div class="form-group w-100">
                                <div class="floating-label floating-label-sm">
                                  <label for="goalAmountForm">Objetivo</label>
                                  <input id="goalAmountForm" aria-describedby="goalAmountFormHelp" class="form-control f-1p25" data-field="goal_amount_recurring_cash" autofocus data-${this.controllerName}-target="goalAmountInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask blur->${this.controllerName}#cancelEdit keyup->${this.controllerName}#cancelEdit" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <button data-action="click->${this.controllerName}#updateCalculation" data-field="goal_amount_recurring_cash" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">save</span>
                                <span class="mc-tooltiptext">Salvar</span>
                              </button>
                            </div>
                          </div>
                        </span>`
      var goalGain = `<span class="pointer" data-${this.controllerName}-target="goalGain" data-action="click->${this.controllerName}#changeGoalGain">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.goal_gain_recurring_cash)}
                        </span>
                        <span class="d-none" data-${this.controllerName}-target="goalGainInputSpan">
                          <div class="row d-flex align-items-center">
                            <div class="col-8 pr-0">
                              <div class="form-group w-100">
                                <div class="floating-label floating-label-sm">
                                  <label for="goalGainForm">Objetivo</label>
                                  <input id="goalGainForm" aria-describedby="goalGainFormHelp" class="form-control f-1p25" data-field="goal_gain_recurring_cash" autofocus data-${this.controllerName}-target="goalGainInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask blur->${this.controllerName}#cancelEdit keyup->${this.controllerName}#cancelEdit" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-2">
                              <button data-action="click->${this.controllerName}#updateCalculation" data-field="goal_gain_recurring_cash" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">save</span>
                                <span class="mc-tooltiptext">Salvar</span>
                              </button>
                            </div>
                          </div>
                        </span>`
    } else {
      var goalLeads = `${this.application.current_calculation.goal_leads}`
      var goalAmount = `${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.goal_amount_recurring_cash)}`
      var goalGain = `${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.goal_gain_recurring_cash)}`
    }

    if (this.application.current_calculation.leads_rate < 100) {
      var leadsRate = `<span class="f-bold pointer badge badge-secondary-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.leads_rate)}</span>`
    } else {
      var leadsRate = `<span class="f-bold pointer badge badge-secondary-success">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.leads_rate)}</span>`
    }

    if (this.application.current_calculation.amount_rate < 100) {
      var amountRate = `<span class="f-bold pointer badge badge-secondary-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.amount_rate)}</span>`
    } else {
      var amountRate = `<span class="f-bold pointer badge badge-secondary-success">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.amount_rate)}</span>`
    }

    if (this.application.current_calculation.gain_rate < 100) {
      var gainRate = `<span class="f-bold pointer badge badge-secondary-danger">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.gain_rate)}</span>`
    } else {
      var gainRate = `<span class="f-bold pointer badge badge-secondary-success">${this.getControllerByIdentifier("app--helpers--numbers").percentOptionMask(this.application.current_calculation.gain_rate)}</span>`
    }

    var html = `<div class="col-4 pr-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span>Novos Leads</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          novos
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${this.application.current_calculation.marketing_leads}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          objetivo
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${goalLeads}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 offset-5 text-left f-085 f-bold px-1 py-0">
                          ${leadsRate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span>Novas Oportunidades</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          novos
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.total_amount_recurring_accrual)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          objetivo
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${goalAmount}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 offset-5 text-left f-085 f-bold px-1 py-0">
                          ${amountRate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 pl-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-075 f-bold">
                      <span>Ganhos</span>
                    </div>
                    <div class="card-body text-center f-075 px-0 py-1">
                      <div class="row my-1 d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          novos
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.application.current_calculation.gain_amount_cash)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          objetivo
                        </div>
                        <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                          ${goalGain}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 offset-5 text-left f-085 f-bold px-1 py-0">
                          ${gainRate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.calculationTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.permissions.commercial_main_dashboard.can_update) {
        controller.goalLeadInputTarget.value = controller.application.current_calculation.goal_leads
        controller.goalAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.application.current_calculation.goal_amount_recurring_cash)
        controller.goalGainInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.application.current_calculation.goal_gain_recurring_cash)
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  doSideCardHtml() {

    var siteAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Site</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/conteudo-site" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">language</span>
                              <span class="mc-tooltiptext">Site</span>
                            </div>
                          </div>
                        </div>`

    var eventAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Eventos</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/eventos" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">online_prediction</span>
                              <span class="mc-tooltiptext">Eventos</span>
                            </div>
                          </div>
                        </div>`

    var radarAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Radar</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/radar-oportunidades" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">radar</span>
                              <span class="mc-tooltiptext">Radar</span>
                            </div>
                          </div>
                        </div>`

    var calculatorsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Calculadoras</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/calculadoras" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">calculate</span>
                              <span class="mc-tooltiptext">Calculadoras</span>
                            </div>
                          </div>
                        </div>`

    var downloadsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Downloads</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/downloads" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">cloud_download</span>
                              <span class="mc-tooltiptext">Downloads</span>
                            </div>
                          </div>
                        </div>`

    var callsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Ligações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/ligacoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">call</span>
                              <span class="mc-tooltiptext">Ligações</span>
                            </div>
                          </div>
                        </div>`

    // if (this.application.permissions.leads.can_index) {
    //   var leadsLPAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Leads de LPs</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/leads" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">how_to_reg</span>
    //                           <span class="mc-tooltiptext">Leads de LPs</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var leadsLPAlias = ``
    // }
    var leadsLPAlias = ``

    // if (this.application.permissions.marketing_campaign_entities.can_index) {
    //   var campaignsAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Campanhas</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/campanhas" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">campaign</span>
    //                           <span class="mc-tooltiptext">Campanhas</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var campaignsAlias = ``
    // }
    var campaignsAlias = ``

    var landingsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Landings Pages</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/landings" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">flight_land</span>
                              <span class="mc-tooltiptext">Landings Pages</span>
                            </div>
                          </div>
                        </div>`

    // if (this.application.permissions.course_entities.can_index) {
    //   var coursesAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Cursos & Aulas</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/cursos" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">school</span>
    //                           <span class="mc-tooltiptext">Cursos & Aulas</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var coursesAlias = ``
    // }
    var coursesAlias = ``

    var schedulesAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Consultorias</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/consultorias" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">event</span>
                              <span class="mc-tooltiptext">Consultorias</span>
                            </div>
                          </div>
                        </div>`

    // if (this.application.permissions.ahoy_visit.can_index) {
    //   var visitsAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Visitas & Sessões</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/visitas-sessoes" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">http</span>
    //                           <span class="mc-tooltiptext">Visitas & Sessões</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var visitsAlias = ``
    // }
    var visitsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Visitas & Sessões</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/visitas-sessoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">http</span>
                              <span class="mc-tooltiptext">Visitas & Sessões</span>
                            </div>
                          </div>
                        </div>`
//     var visitsAlias = ``
    

    var prospectingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Fluxo Prospecção</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/fluxo-prospeccao" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">phone_enabled</span>
                              <span class="mc-tooltiptext">Fluxo Prospecção</span>
                            </div>
                          </div>
                        </div>`

    var closingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Fluxo Fechamento</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/fluxo-fechamento" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">shopping_cart_checkout</span>
                              <span class="mc-tooltiptext">Fluxo Fechamento</span>
                            </div>
                          </div>
                        </div>`

    var opportunityAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Oportunidades</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/oportunidades" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">local_atm</span>
                              <span class="mc-tooltiptext">Oportunidades</span>
                            </div>
                          </div>
                        </div>`

    var leadsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Leads Gerados</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/leads-gerados" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">emoji_people</span>
                              <span class="mc-tooltiptext">Leads Gerados</span>
                            </div>
                          </div>
                        </div>`

    var mktLeadsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>SQLs</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/leads-qualificados" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">follow_the_signs</span>
                              <span class="mc-tooltiptext">SQLs</span>
                            </div>
                          </div>
                        </div>`


    var contentCard = `<div class="col-4 px-1 my-2">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-065">
                            <span>Descanso Médico</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/conteudo" data-action="click->${this.controllerName}#goToURL">
                            <img class="ml-2 mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/icon-descanso-invert.png" alt="descancomedico" style="width:0.8rem;cursor:pointer;">
                            <span class="mc-tooltiptext">Descanso Médico</span>
                          </div>
                        </div>
                      </div>`

    // if (this.application.permissions.marketing_visit_chats.can_index) {
    //   var chatAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Chat Online</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/visitas-chats" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">chat</span>
    //                           <span class="mc-tooltiptext">Chat Online</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var chatAlias = ``
    // }
    var chatAlias = ``

    // if (this.application.permissions.marketing_searchs_entities.can_index) {
    //   var searchAlias = `<div class="col-4 px-1 my-2">
    //                       <div class="card border-top-primary">
    //                         <div class="card-header p-1 text-center f-065">
    //                           <span>Buscador</span>
    //                         </div>
    //                         <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/buscador-descanso-medico" data-action="click->${this.controllerName}#goToURL">
    //                           <span class="material-icons md-dark">screen_search_desktop</span>
    //                           <span class="mc-tooltiptext">Buscador Descanso Médico</span>
    //                         </div>
    //                       </div>
    //                     </div>`
    // } else {
    //   var searchAlias = ``
    // }
    var searchAlias = ``


    var html = `<div class="row">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center">Acesso Rápido</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row">
                  ${opportunityAlias}
                  ${prospectingAlias}
                  ${closingAlias}
                  ${radarAlias}
                  ${callsAlias}
                  ${schedulesAlias}
                  ${leadsAlias}
                  ${mktLeadsAlias}
                  ${siteAlias}
                  ${contentCard}
                  ${chatAlias}
                  ${visitsAlias}
                  ${searchAlias}
                  ${leadsLPAlias}
                  ${eventAlias}
                  ${calculatorsAlias}
                  ${downloadsAlias}
                  ${campaignsAlias}
                  ${landingsAlias}
                  ${coursesAlias}
                </div>`

    this.sideCardTarget.innerHTML = html
  }


  doPageGrid() {

    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDashboard()
    })
  }


  doDashboard() {

    var html = `<div class="row">
                  <div class="col-8">
                    <div class="row my-3 d-flex justify-content-between">
                      <div class="col-12" data-controller="commercial--dashboards--dates" data-commercial--dashboards--dates-target="main" style="overflow: auto;"></div>
                    </div>
                    <div class="row my-3 d-flex justify-content-between" data-${this.controllerName}-target="calculation">
                      <div class="col-3">
                        ${this.cardLoader}
                      </div>
                      <div class="col-3">
                        ${this.cardLoader}
                      </div>
                      <div class="col-3">
                        ${this.cardLoader}
                      </div>
                    </div>
                    <div class="my-4" data-${this.controllerName}-target="report" data-controller="commercial--dashboards--report">
                      
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="row my-4">
                      <div class="col-12 px-0" data-controller="" data-${this.controllerName}-target="sideCard">
                        <div class="row my-2">
                          ${this.loader}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    this.doCalculationCards()
    this.doSideCardHtml()
    this.getControllerByIdentifier("commercial--dashboards--dates").doDatesHTML()
    this.getControllerByIdentifier("commercial--dashboards--submenu").doSubmenuHtml()
    
    
    if (this.application.permissions.commercial_main_dashboard.can_report) {
      this.getControllerByIdentifier("commercial--dashboards--report").doReportGrid()
    }

    this.pageLoaded = true
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getSalesTeam() {
    const data = { feature: { feature_name: `sales_opportunities_entities`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_main_dashboard` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sales_team = response.data.cln
        controller.finishLoadPage()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getReport() {
    const data = { opportunity: { account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_main_dashboard` } }
    const url = "/commercial/sales/opportunities/reports/product_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(report => {
          controller.getControllerByIdentifier("commercial--dashboards--report").reports[report.name] = report.data
        })
        
        if (controller.pageLoaded) {
          controller.getControllerByIdentifier("commercial--dashboards--report").doReportPage()
        } else {
          if (controller.application.permissions.commercial_main_dashboard.can_manage) {
            controller.getSalesTeam()
          } else {
            controller.finishLoadPage()
          }  
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    var broadcastController = this.getCommercialDates(`commercial--dashboard--broadcast`)

    var data = { current_user: { current_user_id: this.application.current_user.id, feature: `commercial_main_dashboard` } }
    const url = "/commercial/config/broadcasts/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.broadcastController.update_room = response.data.cln.update
          controller.broadcastController.broadcastUpdateChannel()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCommercialDates() {
    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.dates = response.data.cln
        controller.application.current_date = response.data.cln[0]
        controller.application.current_calculation = response.data.cln[0].calculation
        
        if (controller.application.permissions.commercial_main_dashboard.can_report) {
          controller.getReport()
        } else {
          controller.finishLoadPage()
        }
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_main_dashboard` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_main_dashboard_permission`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })
          controller.application.domain_permission = controller.application.permissions[`commercial_main_dashboard_permission`]

          controller.getCommercialDates()
        } else {
          window.open(`/dashboard`, `_self`)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `commercial--dashboards--main`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}