import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["validGroup", "userNewPassword", "userConfirmationNewPassword", "btnChangePassword", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.refreshChangePasswordBtn()
    this.userNewPasswordTarget.focus()
  }

  validateUserPassword(ev) {
    var passwordLength = this.userNewPasswordTarget.value.length
    if ((ev.type == "keyup" && ev.key != "Enter") || ev.type == "blur") {
      var password = this.userNewPasswordTarget.value
      var passwordConfirmation = this.userConfirmationNewPasswordTarget.value
      var token = $('meta[name=csrf-token]').attr('content');
      var url = "/validations/forms/validate_user_password"
      var method = "POST"
      var data = { validations: { password: password, password_confirmation: passwordConfirmation } }
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.valid) {
            this.cleanValidations(ev)
            ev.target.closest(".form-valid-group").dataset.valid = true
          } else {
            ev.target.closest(".form-valid-group").dataset.valid = false
            ev.target.classList.add("invalid-field")
            const html = `<div class="invalid-warning my-1 py-1" data-users--auth--forgotten-target="invalid"></div>`
            response.message.forEach(message => {
              if (ev.target.nextElementSibling) {
                ev.target.nextElementSibling.innerText = message
              } else {
                ev.target.parentElement.insertAdjacentHTML("beforeend", html);
                ev.target.nextElementSibling.insertAdjacentHTML("beforeend", message);
              }
            });
          }
        })
    } else {
      this.cleanValidations(ev)
    }
  }

  changePassword(ev) {
    var passwordLength = this.userNewPasswordTarget.value.length
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && ev.target.value.length == passwordLength) || ev.type == "click") {
      this.stopRefreshing()
      this.btnChangePasswordTarget.disabled = true
      var passwordParams = new URLSearchParams(window.location.search)
      var resetPasswordToken = passwordParams.get('reset_password_token')
      var password = this.userNewPasswordTarget.value
      var passwordConfirmation = this.userConfirmationNewPasswordTarget.value
      var data = { user: { password: password, password_confirmation: passwordConfirmation, reset_password_token: resetPasswordToken } }
      var token = $('meta[name=csrf-token]').attr('content');
      var url = "/senha"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            window.open(response.location, `_self`)
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
            controller.refreshChangePasswordBtn()
            controller.btnChangePasswordTarget.disabled = false
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 2000)
        })
    }
  }

  refreshChangePasswordBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (controller.getControllerByIdentifier("app--helpers--strings").fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.btnChangePasswordTarget.disabled = false
      } else {
        controller.btnChangePasswordTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  cleanValidations(ev) {
    ev.target.classList.remove("invalid-field")
    while (ev.target.nextElementSibling) {
      ev.target.nextElementSibling.remove()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
