import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--tickets`
    this.blockedAdd = false
  }

  doTicketsDashboard() {
    var html = `<div class="col-12 pl-1" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--show`).ticketsRowTarget.innerHTML = html)
    }).then(() => {

      var leadName = ``
      controller.application.opportunity.leads.forEach(lead => {
        leadName += `| ${lead.lead_name} `
      })

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = `sales_opportunities_tickets`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).blockedAdd = controller.blockedAdd
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).sharing = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).kind = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).term = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 4
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: controller.application.opportunity.id,
        board_type: `sales_opportunities`,
        // board_subtype: `${controller.application.opportunity.product_name}`,
        board_path: window.location.pathname,
        board_name: `Oportunidade com ${leadName}`,
        date_id: controller.application.opportunity.date_id,
        date_type: `commercial_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: controller.application.opportunity.id,
        board_type: `sales_opportunities`,
        date_id: controller.application.opportunity.date_id,
        date_type: `commercial_dates`
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
