import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["serviceValue", "calculationBasis", "revenueTwelveMonths", "rangeInput", "nominalAliquot", "effectiveAliquot", "irAliquot",
                    "csllAliquot", "pisAliquot", "cofinsAliquot", "inssAliquot", "issAliquot", "irDue", "csllDue", "pisDue", "cofinsDue",
                    "inssDue", "issDue", "irEffectiveAliquot", "csllEffectiveAliquot", "pisEffectiveAliquot", "cofinsEffectiveAliquot",
                    "inssEffectiveAliquot", "issEffectiveAliquot", "issWithheld", "totalWithheldIss", "totalAmountIss", "totalPayable",
                    "unconditioned", "conditioned", "otherAmountWithheld", "totalTaxWithheld", "netReceivable", "effectiveIssAliquot",
                    "totalPayableIss", "totalTaxAmount"]

  connect() {
    this.controllerName = "operations--products--clinics--financials--add--simple-national"
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    }
    
    this.doSimpleNationalFormHtml()
  }

  getData() {

    this.saveController.send_data.receivable.calculation_basis = this.calculationBasis
    this.saveController.send_data.receivable.irpj_amount_withheld = 0
    this.saveController.send_data.receivable.pis_amount_withheld = 0
    this.saveController.send_data.receivable.cofins_amount_withheld = 0
    this.saveController.send_data.receivable.csll_amount_withheld = 0
    this.saveController.send_data.receivable.inss_amount_withheld = 0
    this.saveController.send_data.receivable.iss_amount_withheld = this.totalWithheldIss
    this.saveController.send_data.receivable.iss_rate = this.effectiveIss
    this.saveController.send_data.receivable.iss_tax_amount = this.totalAmountIss
    this.saveController.send_data.receivable.federal_withheld = this.federalWithheld
    this.saveController.send_data.receivable.municipal_withheld = this.municipalWithheld
    this.saveController.send_data.receivable.deductions_amount = this.totalWithheld
    this.saveController.send_data.receivable.unconditioned_amount = this.unconditioned
    this.saveController.send_data.receivable.conditioned_amount = this.conditioned
    this.saveController.send_data.receivable.others_amount_withheld = this.otherAmountWithheld
    this.saveController.send_data.receivable.net_amount = this.netReceivable
    this.saveController.send_data.receivable.total_tax_amount = this.totalTaxAmount
    this.saveController.send_data.receivable.total_tax_withheld = this.totalTaxWithheld
    this.saveController.send_data.receivable.total_tax_payable = this.totalPayable
    this.saveController.send_data.receivable.total_practice_tax_amount = this.normalTaxAmount
    this.saveController.send_data.receivable.total_procedure_tax_amount = this.hospitalTaxAmount
    this.saveController.send_data.receivable.regime_name = this.saveController.current_regime.tax_regime


    var performers = []
    this.saveController.send_data.performers.performers.forEach(element => {
      var performer = {}
      var rate = element.amount / this.saveController.send_data.receivable.total_amount
      if (this.saveController.send_data.receivable.total_practice_revenues > 0) {
        var normalRate = element.total_practice_revenues / this.saveController.send_data.receivable.total_practice_revenues
      } else {
        var normalRate = 0
      }
      if (this.saveController.send_data.receivable.total_procedure_revenues > 0) {
        var hospitalRate = element.total_procedure_revenues / this.saveController.send_data.receivable.total_procedure_revenues
      } else {
        var hospitalRate = 0
      }

      performer = element
      performer.irpj_amount_withheld = this.saveController.send_data.receivable.irpj_amount_withheld * rate
      performer.pis_amount_withheld = this.saveController.send_data.receivable.pis_amount_withheld * rate
      performer.cofins_amount_withheld = this.saveController.send_data.receivable.cofins_amount_withheld * rate
      performer.csll_amount_withheld = this.saveController.send_data.receivable.csll_amount_withheld * rate
      performer.inss_amount_withheld = this.saveController.send_data.receivable.inss_amount_withheld * rate
      performer.iss_amount_withheld = this.saveController.send_data.receivable.iss_amount_withheld * rate
      performer.total_amount_withheld = this.saveController.send_data.receivable.total_tax_withheld * rate
      performer.net_amount = this.saveController.send_data.receivable.net_amount * rate

      performer.total_practice_tax_amount = this.saveController.send_data.receivable.total_practice_tax_amount * normalRate
      performer.total_procedure_tax_amount = this.saveController.send_data.receivable.total_procedure_tax_amount * hospitalRate
      performer.tax_amount = Number(performer.total_practice_tax_amount) + Number(performer.total_procedure_tax_amount)

      performers[performers.length] = performer
    })

    this.saveController.send_data.performers.performers = performers

  }

  doSimpleNationalFormHtml() {

    // <input aria-describedby="otherAmountWithheldHelp" class="form-control" id="otherAmountWithheld" data-${this.controllerName}-target="otherAmountWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>

    var html = `<div class="row my-3">
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="serviceValue">Valor Serviços</label>
                        <input aria-describedby="serviceValueHelp" class="form-control" id="serviceValue" data-${this.controllerName}-target="serviceValue" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="calculationBasis">Base de Cálculo</label>
                        <input aria-describedby="calculationBasisHelp" class="form-control" id="calculationBasis" data-${this.controllerName}-target="calculationBasis" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="revenueTwelveMonths">Receita 12M</label>
                        <input aria-describedby="revenueTwelveMonthsHelp" class="form-control" id="revenueTwelveMonths" data-${this.controllerName}-target="revenueTwelveMonths" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="rangeInput">Faixa</label>
                        <input aria-describedby="rangeInputHelp" class="form-control" id="rangeInput" data-${this.controllerName}-target="rangeInput" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="nominalAliquot">Alíq. Nominal</label>
                        <input aria-describedby="nominalAliquotHelp" class="form-control" id="nominalAliquot" data-${this.controllerName}-target="nominalAliquot" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="effectiveAliquot">Alíq. Efetiva</label>
                        <input aria-describedby="effectiveAliquotHelp" class="form-control" id="effectiveAliquot" data-${this.controllerName}-target="effectiveAliquot" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="effectiveIssAliquot">Alíq. Efetiva ISS</label>
                        <input aria-describedby="effectiveIssAliquotHelp" class="form-control" id="effectiveIssAliquot" data-${this.controllerName}-target="effectiveIssAliquot" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalAmountIss">Total ISS</label>
                        <input aria-describedby="totalAmountIssHelp" class="form-control" id="totalAmountIss" data-${this.controllerName}-target="totalAmountIss" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0 my-3 d-flex align-items-center">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                      <input type="checkbox" class="custom-control-input" id="issWithheld" data-action="click->${this.controllerName}#bindTax" data-${this.controllerName}-target="issWithheld">
                      <label class="custom-control-label f-065 pointer ml-2" for="issWithheld">Retenção ISS</label>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalWithheldIss">Total ISS Retido</label>
                        <input aria-describedby="totalWithheldIssHelp" class="form-control" id="totalWithheldIss" data-${this.controllerName}-target="totalWithheldIss" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text">
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalPayableIss">Total ISS à Pagar</label>
                        <input aria-describedby="totalPayableIssHelp" class="form-control" id="totalPayableIss" data-${this.controllerName}-target="totalPayableIss" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalTaxAmount">Total de Impostos</label>
                        <input aria-describedby="totalTaxAmountHelp" class="form-control" id="totalTaxAmount" data-${this.controllerName}-target="totalTaxAmount" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="unconditioned">D. Incondicional</label>
                        <input aria-describedby="unconditionedHelp" class="form-control" id="unconditioned" data-${this.controllerName}-target="unconditioned" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="conditioned">D. Condicional</label>
                        <input aria-describedby="conditionedHelp" class="form-control" id="conditioned" data-${this.controllerName}-target="conditioned" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="otherAmountWithheld">Retenções / Descontos</label>
                        <input aria-describedby="otherAmountWithheldHelp" class="form-control" id="otherAmountWithheld" data-${this.controllerName}-target="otherAmountWithheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindTax" placeholder="" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalTaxWithheld">Total Impostos Retido</label>
                        <input aria-describedby="totalWithheldHelp" class="form-control" id="totalTaxWithheld" data-${this.controllerName}-target="totalTaxWithheld" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalPayable">Total Impostos à Pagar</label>
                        <input aria-describedby="totalPayableHelp" class="form-control" id="totalPayable" data-${this.controllerName}-target="totalPayable" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-0">
                    <div class="form-group form-valid-group">
                      <div class="floating-label floating-label-sm">
                        <label for="netReceivable">Liquido a receber</label>
                        <input aria-describedby="netReceivableHelp" class="form-control" id="netReceivable" data-${this.controllerName}-target="netReceivable" placeholder="" type="text" readonly>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--financials--add--taxes").formTarget.innerHTML = html)
    }).then(() => {
      
      controller.setFieldValues()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setFieldValues() {
    this.serviceValueTarget.value = this.numberController.currencyOptionMask(this.saveController.send_data.receivable.total_amount)
    this.calculationBasis = this.saveController.send_data.receivable.total_amount
    this.calculationBasisTarget.value = this.numberController.currencyOptionMask(this.calculationBasis)
    
    this.revenueTwelveMonthsTarget.value = this.numberController.currencyOptionMask(this.saveController.current_regime.last_twelve_months_sum)
    this.rangeInputTarget.value = this.saveController.current_regime.range
    this.nominalAliquotTarget.value = this.numberController.percentOptionMask(this.saveController.current_regime.nominal_total_tax, { scale: 4 })
    this.effectiveAliquotTarget.value = this.numberController.percentOptionMask(this.saveController.current_regime.effective_total_tax, { scale: 4 })

    this.normalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_practice_revenues) / 100
    this.hospitalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_procedure_revenues) / 100

    this.totalTaxAmount = Number(this.normalTaxAmount) + Number(this.hospitalTaxAmount)
    // this.totalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_amount) / 100
    this.totalTaxAmountTarget.value = this.numberController.currencyOptionMask(this.totalTaxAmount)

    // this.effectiveIss = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.current_regime.nominal_iss)
    this.effectiveIss = Number(this.saveController.current_regime.iss_rate)
    this.effectiveIssAliquotTarget.value = this.numberController.percentOptionMask(this.effectiveIss, { scale: 4 })

    this.totalAmountIss = Number(this.effectiveIss) * Number(this.saveController.send_data.receivable.total_amount) / 100
    this.totalAmountIssTarget.value = this.numberController.currencyOptionMask(this.totalAmountIss)
    this.totalWithheldIss = 0
    this.totalWithheldIssTarget.value = this.numberController.currencyOptionMask(this.totalWithheldIss)
    this.unconditioned = 0
    this.unconditionedTarget.value = this.numberController.currencyOptionMask(this.unconditioned)
    this.conditioned = 0
    this.conditionedTarget.value = this.numberController.currencyOptionMask(this.conditioned)
    this.otherAmountWithheld = 0
    this.otherAmountWithheldTarget.value = this.numberController.currencyOptionMask(this.otherAmountWithheld)

    if (this.saveController.send_data.receivable.taker_type == `legal`) {
      this.issWithheldTarget.checked = true
    } else if (this.saveController.send_data.receivable.taker_type == `individual`) {
      this.issWithheldTarget.checked = false
      this.issWithheldTarget.disabled = true
      this.unconditionedTarget.disabled = true
      this.conditionedTarget.disabled = true
      this.otherAmountWithheldTarget.disabled = true
    }

    if (this.issWithheldTarget.checked) {
      this.totalWithheldIss = Number(this.totalAmountIss)
    } else {
      this.totalWithheldIss = 0
    }
    this.totalWithheldIssTarget.value = this.numberController.currencyOptionMask(this.totalWithheldIss)
    

    this.bindTax()
  }

  bindTax() {

    if (this.issWithheldTarget.checked) {
      // this.totalWithheldIss = Number(this.totalAmountIss)
      this.totalWithheldIss = this.numberController.fromCurrencyToNumber(this.totalWithheldIssTarget.value)
    } else {
      this.totalWithheldIss = 0
    }
    this.totalWithheldIssTarget.value = this.numberController.currencyOptionMask(this.totalWithheldIss)

    this.federalWithheld = 0
    this.municipalWithheld = Number(this.totalWithheldIss)

    this.totalPayableIss = Number(this.totalAmountIss) - Number(this.totalWithheldIss)
    this.totalPayableIssTarget.value = this.numberController.currencyOptionMask(this.totalPayableIss)

    this.unconditioned = this.numberController.fromCurrencyToNumber(this.unconditionedTarget.value)
    this.conditioned = this.numberController.fromCurrencyToNumber(this.conditionedTarget.value)
    this.otherAmountWithheld = this.numberController.fromCurrencyToNumber(this.otherAmountWithheldTarget.value)
    // this.totalWithheld = Number(this.totalWithheldIss) + Number(this.otherAmountWithheld)
    this.totalTaxWithheld = Number(this.totalWithheldIss)
    this.totalTaxWithheldTarget.value = this.numberController.currencyOptionMask(this.totalTaxWithheld)

    // this.totalPayable = Number(this.totalTaxAmount) - Number(this.totalWithheld)
    this.totalPayable = Number(this.totalTaxAmount) - Number(this.totalWithheldIss)
    this.totalPayableTarget.value = this.numberController.currencyOptionMask(this.totalPayable)

    this.netReceivable = Number(this.saveController.send_data.receivable.total_amount) - Number(this.totalTaxWithheld) - Number(this.otherAmountWithheld) - Number(this.unconditioned) - Number(this.conditioned)
    this.netReceivableTarget.value = this.numberController.currencyOptionMask(this.netReceivable)

  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}


// deductions_amount = ?

// source = ?
// version = ?
// total_rate = ?
