import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "dueAtInput", "paidAtInput", "channelDropdown", "channelDropdownBtn", "channelInput",
                    "channelList", "saveBtn", "totalAmountInput", "descriptionInput"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--taxes--paid-modal`
    // this.open()
  }

  open(taxId) {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.setCurrentTax(taxId)
    this.setChannels()
    
    var date = new Date(this.application.current_date.year, this.application.current_date.month - 1 , 1)
    // this.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.issuedOnInputTarget), { kind: "monthly", year: date.getFullYear(), month: date.getMonth() + 1 })
    // this.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.paidAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
    this.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.paidAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
    
    this.descriptionInputTarget.value = this.current_tax.description
    this.paidAtInputTarget.value = this.current_tax.due_at_pretty
    this.dueAtInputTarget.value = this.current_tax.due_at_pretty
    this.totalAmountInputTarget.value = this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(Math.round(this.current_tax.total_amount * 100))

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  setCurrentTax(taxId) {
    this.application.taxes.forEach(element => {
      if (element.id == taxId) {
        this.current_tax = element
      }
    })
  }

  updatePaid() {
    this.send_data = { current_user: {}, tax: {}, tracker: { log: true } }

    this.send_data.tax.id = this.current_tax.id
    this.send_data.tax.status = `paid`
    this.send_data.tax.paid_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.paidAtInputTarget.value)
    this.send_data.tax.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.tax.channel_name = this.channelInputTarget.innerText

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `paid`
    this.send_data.tracker.observations = `Pago ${this.current_tax.description} da competência ${this.current_tax.date_accrual} da PJ ${this.application.clinic.company_name}, sendo Principal: ${this.current_tax.principal}, Juros: ${this.current_tax.interests} e Total: ${this.current_tax.total_amount}`

    var r = confirm(`A Guia de Imposto foi Paga?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/operations/products/clinics/financials/taxes/update_paid"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var tax = response.data.cln
          controller.application.taxes.forEach((element, i) => {
            if (element.id == tax.id) {
              controller.application.taxes.splice(controller.application.taxes.indexOf(element), 1, tax)
            }
          })
        }
        controller.close()
        controller.getControllerByIdentifier(`operations--products--clinics--financials--taxes--index`).doDataTable()
        controller.getControllerByIdentifier("operations--products--clinics--financials--taxes--show").doViewHtml()
        controller.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doTaxesCount()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para atualizar o pagamento do Imposto`)
      })
  }

  setChannels() {
    var html = ``

    this.application.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectBank click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`
        
        if (channel.kind == `main`) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }
      }
    })

    this.channelListTarget.innerHTML = html
  }

  selectBank(ev) {

    var channelId = ev.currentTarget.dataset.channelId

    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}