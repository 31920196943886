import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "passwordInput", "confirmationInput", "saveBtn", "emailInput", "errorMessage"]

  connect() {
    this.controllerName = `operations--products--tax-filings--add--user`
    this.account = {}
    this.passwordConfirmationValue = false
  }

  previousStep() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-filings--add--product").doProductFormHtml()
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").current_step = 2
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").setStep()
  }

  cancelSave() {
    var r = confirm(`Tem certeza que deseja Cancelar o processo?`)
    if (r) {
      this.getControllerByIdentifier("operations--products--tax-filings--index--list").doIndexListHtml()
    }
  }

  saveProduct() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    var controller = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard")

    controller.send_product_data.user.password = this.passwordInputTarget.value
    controller.send_product_data.user.password_confirmation = this.confirmationInputTarget.value
  }

  createUser() {
    this.stopRefreshing()
    
    this.saveBtnTarget.disabled = true
    this.actionMode = `new`
    this.send_data = { current_user: {}, user: {}, account: {} }

    var account_controller = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard")

    this.send_data.user.password = this.passwordInputTarget.value
    this.send_data.user.password_confirmation = this.confirmationInputTarget.value
    this.send_data.account.account_id = account_controller.account.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medfiling_team_accounts`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.getControllerByIdentifier("operations--products--tax-filings--index--list").doIndexListHtml()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doUserFormHtml() {
    var html = this.stopRefreshing()

    var html = `<div class="col-6 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando ou Editando Médico</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="nameForm">Nome</label>
                              <input aria-describedby="nameFormHelp" id="nameForm" data-${this.controllerName}-target="nameInput" class="form-control" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="emailForm">E-mail</label>
                              <input aria-describedby="emailFormHelp" id="emailForm" data-${this.controllerName}-target="emailInput" class="form-control" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="passwordUserForm">Senha</label>
                              <input aria-describedby="passwordUserFormHelp" class="form-control" id="passwordUserForm" data-${this.controllerName}-target="passwordInput" placeholder="Senha" type="password" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="passwordConfirmationUserForm">Confirmação de Senha</label>
                              <input aria-describedby="passwordConfirmationUserFormHelp" class="form-control" id="passwordConfirmationUserForm" data-action="keyup->${this.controllerName}#checkPassword" data-${this.controllerName}-target="confirmationInput"  placeholder="Confirmação de Senha" type="password" required>
                            </div>
                          </div>
                        </div>
                      </div>                  
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Pular</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#createUser" data-${this.controllerName}-target="saveBtn">Salvar</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(this.startedAtInputTarget), { max: true, months: true, years: 60 })
      controller.formCardTarget.style.height = ($(window).height() * 0.4) + "px"

      var product_data = controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").send_product_data
      controller.nameInputTarget.value = product_data.account.name
      controller.emailInputTarget.value = product_data.account.email
      controller.nameInputTarget.disabled = true
      controller.emailInputTarget.disabled = true

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 2000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  checkPassword(ev) {

    var password = this.passwordInputTarget.value
    var confirmation = ev.currentTarget.value

    if (password == confirmation) {
      this.passwordConfirmationValue = true
    } else {
      this.passwordConfirmationValue = false

      this.setErrorMessage("As senhas não conferem")
    }

  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.passwordInputTarget.value == ``) {
        len += 1
      }

      if (controller.confirmationInputTarget.value == ``) {
        len += 1
      }

      if (len == 0 && controller.passwordConfirmationValue) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
