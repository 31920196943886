import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "renewals", "bodyTable", "footerTable", "body", "searchTable", "searchInput", "form"]

  connect() {
    this.controllerName = `users--works--schedules--entities--save-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open")
    this.element.setAttribute("style", "display: block;")
    this.element.classList.add("show")
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    if (this.allowedControllers(this.controllerForm.identifier)) {
      this.getControllerByIdentifier("users--works--schedules--entities--list").doDataTable()
    }

    document.body.classList.remove("modal-open")
    this.element.removeAttribute("style")
    this.element.classList.remove("show")
    document.getElementsByClassName("modal-backdrop")[0].remove()
    this.modalTarget.remove()
  }

  doFormHtml() {
    var html = `<div class="col-12 px-0" data-${this.controllerName}-target="form" data-controller="users--works--schedules--entities--save"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.controllerSave = controller.getControllerByIdentifier("users--works--schedules--entities--save")
      controller.controllerSave.controllerDashboard = controller
      controller.controllerSave.controllerForm = controller.controllerForm
      controller.controllerSave.permission = controller.permission
      controller.controllerSave.gridElement = controller.formTarget
      controller.controllerSave.accountList = controller.accountList
      controller.controllerSave.accountId = controller.accountId
      controller.controllerSave.accountName = controller.accountName
      controller.controllerSave.guestList = controller.guestList
      controller.controllerSave.guestId = controller.guestId
      controller.controllerSave.guestName = controller.guestName
      controller.controllerSave.guestType = controller.guestType
      controller.controllerSave.recordId = controller.recordId
      controller.controllerSave.recordType = controller.recordType
      controller.controllerSave.scheduleId = controller.scheduleId
      controller.controllerSave.actionMode = controller.actionMode

      if (controller.actionMode == `delete`) {
        controller.controllerSave.send_data = { current_user: {}, schedule: {} }
        controller.controllerSave.send_data.schedule.id = controller.scheduleId
        controller.controllerSave.send_data.schedule.active = false

        controller.controllerSave.requestSave()
      } else {
        controller.controllerSave.doFormHtml()
      }
    })
  }

  allowedControllers(identifier) {
    var allowedControllers = [
      `commercial--sales--opportunities--entities--schedules`,
      `users--works--recruitments--schedules--dashboard`,
    ]

    if (allowedControllers.includes(identifier)) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}