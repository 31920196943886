import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "list", "show", "save", "saveDashboard", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--receipts--dashboard`
  }

  doReceiptsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--bookings--receipts--index"></div>
                  <div class="col-6 pl-1" data-${this.controllerName}-target="show" data-controller="operations--products--bookings--receipts--show"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--entities--show").contentTarget.innerHTML = html
  }

  doNewReceipt() {
    var html = `<div class="col-12 px-0">
                  <div class="row" data-controller="operations--products--bookings--receipts--save">
                    <div class="col-6 pr-1" data-operations--products--bookings--receipts--save-target="form"></div>
                    <div class="col-6 pl-1" data-operations--products--bookings--receipts--save-target="preview"></div>
                  </div>
                </div>`

    // this.mainDashboardTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainDashboardTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--bookings--receipts--save").actionMode = "new"
    })
  }

  doEditReceipt() {
    var html = `<div class="col-12 px-0">
                  <div class="row" data-controller="operations--products--bookings--receipts--save">
                    <div class="col-6 pr-1" data-operations--products--bookings--receipts--save-target="form"></div>
                    <div class="col-6 pl-1" data-operations--products--bookings--receipts--save-target="preview"></div>
                  </div>
                </div>`

    // this.mainDashboardTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainDashboardTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--bookings--receipts--save").actionMode = "edit"
      controller.getControllerByIdentifier("operations--products--bookings--receipts--save").current_receipt = this.current_receipt
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
