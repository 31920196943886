import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "cardBody", "cardFooter", "cardTitle",
                    "emailDropdown", "emailDropdownBtn", "emailInput", "emailList", "body", "saveBtn"]

  connect() {
    this.controllerName = `users--accounts--entities--email-modal`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    var html = `<div class="modal-backdrop fade show"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doModalHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doModalHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Print</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#close" type="button">
                        <span class="material-icons md-dark">clear</span>
                      </button>
                      <span class="mc-tooltiptext">Fechar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBody">
                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Email</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="emailDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="emailDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="emailInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="emailList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveUserEmail" data-${this.controllerName}-target="saveBtn">Salvar</button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.35) + "px"
      controller.setEmailList()

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setEmailList() {
    var html = ``

    this.account.email_list.forEach(element => {
      html += `<li data-email="${element.address}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.address}</li>`

      if (element.address == this.user.email) {
        this.emailInputTarget.innerText = element.address
        this.emailInputTarget.dataset.email = element.address
        this.emailDropdownTarget.value = element.address
      }
    })

    this.emailListTarget.innerHTML = html
  }

  saveUserEmail() {
    this.send_data = { current_user: {}, user: {}, tracker: { log: true } }

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.saveBtnTarget.disabled = true

    this.send_data.user.id = this.user.id
    this.send_data.user.email = this.emailInputTarget.innerText

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `account_entity_profiles`

    this.send_data.tracker.record_id = this.account.id
    this.send_data.tracker.record_type = `account_entities`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `Atualizou o Email da conta ${this.user.name} para ${this.send_data.user.email}`

    this.profileController.send_data = this.send_data

    if (this.send_data.user.email == this.user.email) {
      alert(`Esse já é o Email cadastrado!`)
    } else {
      var r = confirm(`Tem certeza que deseja alterar o Email?`)
      if (r) {
        this.profileController.requestSave()
        this.close()
      }
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
