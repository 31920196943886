import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "cpfInput", "birthdateInput",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "sourceInput",
                    "positionDropdown", "positionDropdownBtn", "positionInput", "positionList",
                    "journeyDropdown", "journeyDropdownBtn", "journeyInput", "journeyList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "prefixInput", "appliedAtInput", "emailInput", "ageInput", "saveBtn", "phoneInput",
                    "addressInput", "neighborhoodInput", "stateInput", "stateFilter", "cityInput", "cityFilter",
                    "linkedinInput", "instagramInput", "facebookInput", "behanceInput",
                    "figmaInput", "githubInput", "networkInput", "ratingStar",
                    "compensationClaimInput", "transportClaimInput", "totalClaimInput", "notesInput"]





  connect() {
    this.controllerName = `users--works--recruitments--applicants--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerDate = this.getControllerByIdentifier(`app--helpers--date`)
    this.score = 0
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`users--works--recruitments--applicants--list`).doIndexListHtml()
  }

  saveVacancy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, applicant: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.applicant.id = this.current_applicant.id
    } else if (this.actionMode == `new`) {
      this.send_data.applicant.vacancy_id = this.application.vacancy.id
    }

    this.send_data.applicant.name = this.nameInputTarget.value.trim()
    this.send_data.applicant.prefix = this.prefixInputTarget.value.trim()
    this.send_data.applicant.phone = this.controllerNumber.fromPhoneToNumber(this.phoneInputTarget.value.trim())
    this.send_data.applicant.uniq_phone = `${this.prefixInputTarget.value.trim()}${this.controllerNumber.fromPhoneToNumber(this.phoneInputTarget.value.trim())}`
    this.send_data.applicant.cpf = this.controllerNumber.fromCpfToNumber(this.cpfInputTarget.value.trim())
    this.send_data.applicant.email = this.emailInputTarget.value.trim()
    this.send_data.applicant.age = this.ageInputTarget.value.trim()
    this.send_data.applicant.birthdate = this.controllerDate.transformAllKindDate(this.birthdateInputTarget.value)
    this.send_data.applicant.applied_at = this.controllerDate.transformFullDate(this.appliedAtInputTarget.value)
    this.send_data.applicant.status = this.statusInputTarget.dataset.status
    this.send_data.applicant.journey = this.journeyInputTarget.dataset.journey
    this.send_data.applicant.position = this.positionInputTarget.dataset.position
    this.send_data.applicant.source = this.sourceInputTarget.value.trim()
    this.send_data.applicant.address = this.addressInputTarget.value.trim()
    this.send_data.applicant.neighborhood = this.neighborhoodInputTarget.value.trim()
    this.send_data.applicant.city = this.cityInputTarget.dataset.filter
    this.send_data.applicant.state = this.stateInputTarget.dataset.filter
    this.send_data.applicant.linkedin = this.linkedinInputTarget.value.trim()
    this.send_data.applicant.instagram = this.instagramInputTarget.value.trim()
    this.send_data.applicant.facebook = this.facebookInputTarget.value.trim()
    this.send_data.applicant.behance = this.behanceInputTarget.value.trim()
    this.send_data.applicant.figma = this.figmaInputTarget.value.trim()
    this.send_data.applicant.github = this.githubInputTarget.value.trim()
    this.send_data.applicant.network = this.networkInputTarget.value.trim()
    this.send_data.applicant.notes = this.notesInputTarget.value.trim()
    this.send_data.applicant.compensation_claim = this.controllerNumber.fromCurrencyToNumber(this.compensationClaimInputTarget.value)
    this.send_data.applicant.transport_claim = this.controllerNumber.fromCurrencyToNumber(this.transportClaimInputTarget.value)
    this.send_data.applicant.total_claim = this.controllerNumber.fromCurrencyToNumber(this.totalClaimInputTarget.value)
    this.send_data.applicant.score = this.score

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyVacancy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, applicant: {} }
    this.actionMode = `delete`

    this.send_data.applicant.id = this.current_applicant.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar o Candidato?`)
    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/recruitments/applicants/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/recruitments/applicants/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/recruitments/applicants/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var applicant = response.data.cln.applicant
          var vacancy = response.data.cln.vacancy

          controller.application.vacancy = vacancy
          controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).vacancyApplicantsTarget.innerHTML = `<p class="text-center">${controller.application.vacancy.total_applicants}</p>`
          controller.getControllerByIdentifier(`users--works--recruitments--schedules--dashboard`).doSchedulesDashboard()

          if (controller.actionMode == `new`) {
            controller.application.applicants[controller.application.applicants.length] = applicant
            controller.getControllerByIdentifier(`users--works--recruitments--tickets--dashboard`).createTicket(`Agendar testes/entrevista com ${applicant.name}`)
          } else if (controller.actionMode == `edit`) {
            controller.application.applicants.forEach((element, i) => {
              if (element.id == applicant.id) {
                controller.application.applicants.splice(controller.application.applicants.indexOf(element), 1, applicant)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.applicants.forEach((element, i) => {
              if (element.id == applicant.id) {
                controller.application.applicants.splice(controller.application.applicants.indexOf(element), 1)
              }
            })
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()


    if (this.application.permissions[this.permission].can_delete && this.actionMode == `edit`) {
      var deleteBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyVacancy" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveVacancy" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-10">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>

                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
      
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-2 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="appliedAtForm">Candidatou-se</label>
                                <input aria-describedby="appliedAtFormHelp" class="form-control form-valid-control" id="appliedAtForm" data-${this.controllerName}-target="appliedAtInput" placeholder="Candidatou-se" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-5 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input id="nameForm" aria-describedby="nameFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">E-mail</label>
                                <input class="form-control f-075" data-${this.controllerName}-target="emailInput" type="text" placeholder="E-mail" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-1 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">DDD</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="prefixInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="DDD" type="text" required>
                              </div>
                            </div>
                          </div>                          
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Telefone</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="phoneInput" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask keypress->${this.controllerName}#phoneMask" placeholder="Telefone" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cpfForm">CPF</label>
                                <input id="cpfForm" aria-describedby="cpfFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate" placeholder="CPF" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Idade</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="ageInput" data-action="blur->${this.controllerName}#prefixMask keyup->${this.controllerName}#prefixMask keypress->${this.controllerName}#prefixMask" placeholder="Idade" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="birthdateForm">DN</label>
                                <input aria-describedby="birthdateFormHelp" class="form-control form-valid-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showSources" data-channel-setter="true" data-element="sourceInput" data-${this.controllerName}-target="sourceModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Fontes</span>
                            </button>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="sourceForm">Fonte</label>
                                <input disabled id="sourceForm" aria-describedby="sourceFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="sourceInput" placeholder="Fonte" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="addressForm">Endereço</label>
                                <input id="addressForm" aria-describedby="addressFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="addressInput" placeholder="Endereço" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="neighborhoodForm">Bairro</label>
                                <input id="neighborhoodForm" aria-describedby="neighborhoodFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="neighborhoodInput" placeholder="Bairro" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="stateForm">UF</label>
                                <input id="stateForm" aria-describedby="stateFormHelp" class="form-control form-valid-control" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                                <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                                  <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                                  <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                                  <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                                  <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                                  <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                                  <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                                  <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                                  <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                                  <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                                  <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                                  <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                                  <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                                  <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                                  <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                                  <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                                  <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                                  <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                                  <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                                  <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                                  <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                                  <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                                  <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                                  <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                                  <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                                  <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                                  <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                                  <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="cityForm">Cidade</label>
                                <input id="cityForm" aria-describedby="cityFormHelp" class="form-control form-valid-control" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                                <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="compensationClaimForm">Expectativa</label>
                                <input aria-describedby="compensationClaimFormHelp" class="form-control f-065" id="compensationClaimForm" data-${this.controllerName}-target="compensationClaimInput" data-action="blur->${this.controllerName}#bindTotal blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="transportClaimForm">Transporte</label>
                                <input aria-describedby="transportClaimFormHelp" class="form-control f-065" id="transportClaimForm" data-${this.controllerName}-target="transportClaimInput" data-action="blur->${this.controllerName}#bindTotal blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="totalClaimForm">Total</label>
                                <input disabled aria-describedby="totalClaimFormHelp" class="form-control f-065" id="totalClaimForm" data-${this.controllerName}-target="totalClaimInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 d-flex align-items-center">
                            <span class="mr-3">Relevância</span>
                            <div class="rating-star">
                              <span data-rating="5" data-action="click->${this.controllerName}#ratingApplicant" data-${this.controllerName}-target="ratingStar">☆</span>
                              <span data-rating="4" data-action="click->${this.controllerName}#ratingApplicant" data-${this.controllerName}-target="ratingStar">☆</span>
                              <span data-rating="3" data-action="click->${this.controllerName}#ratingApplicant" data-${this.controllerName}-target="ratingStar">☆</span>
                              <span data-rating="2" data-action="click->${this.controllerName}#ratingApplicant" data-${this.controllerName}-target="ratingStar">☆</span>
                              <span data-rating="1" data-action="click->${this.controllerName}#ratingApplicant" data-${this.controllerName}-target="ratingStar">☆</span>
                            </div>
                          </div>
                          
                        </div>
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="in_process" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Em Processo</li>
                                      <li data-status="hired" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contratado</li>
                                      <li data-status="almost" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boa Candidatura</li>
                                      <li data-status="rejected" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reprovado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Jornada</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="journeyDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="journeyDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="journeyInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="journeyList" data-app--helpers--search-target="searchList">
                                      <li data-journey="screening" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Triagem</li>
                                      <li data-journey="booking" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendamento</li>
                                      <li data-journey="testing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Testes</li>
                                      <li data-journey="interview" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Entrevista</li>
                                      <li data-journey="closing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Fechamento</li>
                                      <li data-journey="onboarding" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Onboarding</li>
                                      <li data-journey="rejected" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reprovado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Posição</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="positionDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="positionDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="positionInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="positionList" data-app--helpers--search-target="searchList">
                                      <li data-position="intern_job" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estágio</li>
                                      <li data-position="assistant" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assistente</li>
                                      <li data-position="analist" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Analista</li>
                                      <li data-position="manager" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gestor</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        

                        <div class="row my-2">
                          <div class="col-12 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="notesForm">Anotações</label>
                                <textarea rows="3" id="notesForm" aria-describedby="notesFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="linkedinForm">LinkedIn</label>
                                <input id="linkedinForm" aria-describedby="linkedinFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="linkedinInput" placeholder="LinkedIn" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="instagramForm">Instagram</label>
                                <input id="instagramForm" aria-describedby="instagramFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="instagramInput" placeholder="Instagram" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="facebookForm">Facebook</label>
                                <input id="facebookForm" aria-describedby="facebookFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="facebookInput" placeholder="Facebook" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="behanceForm">Behance</label>
                                <input id="behanceForm" aria-describedby="behanceFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="behanceInput" placeholder="Behance" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="figmaForm">Figma</label>
                                <input id="figmaForm" aria-describedby="figmaFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="figmaInput" placeholder="Figma" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="githubForm">Github</label>
                                <input id="githubForm" aria-describedby="githubFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="githubInput" placeholder="Github" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="networkForm">Outra Rede Social</label>
                                <input id="networkForm" aria-describedby="networkFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="networkInput" placeholder="Outra Rede Social" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      

                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${deleteBtnHtml}
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--recruitments--applicants--dashboard").tableRowTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickUntilDate($(controller.appliedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Candidato`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `in_process`
        controller.statusInputTarget.innerText = `Em Processo`
        controller.statusInputTarget.dataset.status = `in_process`

        controller.journeyDropdownBtnTarget.disabled = true
        controller.journeyDropdownTarget.value = `screening`
        controller.journeyInputTarget.innerText = `Triagem`
        controller.journeyInputTarget.dataset.journey = `screening`

        controller.compensationClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(0)
        controller.transportClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(0)
        controller.totalClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(0)


      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Candidato`

        controller.appliedAtInputTarget.value = controller.current_applicant.applied_at_pretty
        controller.nameInputTarget.value = controller.current_applicant.name
        controller.emailInputTarget.value = controller.current_applicant.email
        controller.prefixInputTarget.value = controller.current_applicant.prefix
        controller.phoneInputTarget.value = controller.current_applicant.phone_pretty
        
        if (controller.current_applicant.cpf) { controller.cpfInputTarget.value = controller.current_applicant.cpf_pretty }
        if (controller.current_applicant.birthdate) { controller.birthdateInputTarget.value = controller.current_applicant.birthdate_pretty }
        if (controller.current_applicant.age) { controller.ageInputTarget.value = controller.current_applicant.age }
        if (controller.current_applicant.address) { controller.addressInputTarget.value = controller.current_applicant.address }
        if (controller.current_applicant.neighborhood) { controller.neighborhoodInputTarget.value = controller.current_applicant.neighborhood }
        
        controller.sourceInputTarget.value = controller.current_applicant.source
        controller.stateInputTarget.value = controller.current_applicant.state
        controller.cityInputTarget.value = controller.current_applicant.city
        controller.linkedinInputTarget.value = controller.current_applicant.linkedin
        controller.instagramInputTarget.value = controller.current_applicant.instagram
        controller.facebookInputTarget.value = controller.current_applicant.facebook
        controller.behanceInputTarget.value = controller.current_applicant.behance
        controller.figmaInputTarget.value = controller.current_applicant.figma
        controller.githubInputTarget.value = controller.current_applicant.github
        controller.networkInputTarget.value = controller.current_applicant.network
        controller.notesInputTarget.value = controller.current_applicant.notes

        controller.statusDropdownTarget.value = controller.current_applicant.status
        controller.statusInputTarget.innerText = controller.current_applicant.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_applicant.status

        controller.positionDropdownTarget.value = controller.current_applicant.position
        controller.positionInputTarget.innerText = controller.current_applicant.position_pretty
        controller.positionInputTarget.dataset.position = controller.current_applicant.position

        controller.journeyDropdownTarget.value = controller.current_applicant.journey
        controller.journeyInputTarget.innerText = controller.current_applicant.journey_pretty
        controller.journeyInputTarget.dataset.journey = controller.current_applicant.journey
        controller.compensationClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_applicant.compensation_claim)
        controller.transportClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_applicant.transport_claim)
        controller.totalClaimInputTarget.value = controller.controllerNumber.currencyOptionMask(controller.current_applicant.total_claim)

        if (controller.current_applicant.score > 0) {
          controller.score = controller.current_applicant.score
          controller.setApplicantRating(controller.current_applicant.score)
        }

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  selectRole() {}

  ratingApplicant(ev) {
    var rating = ev.target.dataset.rating

    this.setApplicantRating(rating)
    this.score = rating
    //★
    //☆
  }

  setApplicantRating(rating) {
    this.ratingStarTargets.forEach(element => {
      if (element.dataset.rating <= rating) {
        element.innerHTML = `★`
      } else {
        element.innerHTML = `☆`
      }
    })
  }

  bindTotal() {
    
    var compensation = this.controllerNumber.fromCurrencyToNumber(this.compensationClaimInputTarget.value)
    var transport = this.controllerNumber.fromCurrencyToNumber(this.transportClaimInputTarget.value)
    var total = 0

    total = Number(compensation) + Number(transport)
    this.totalClaimInputTarget.value = this.controllerNumber.currencyOptionMask(total)
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    this.controllerNumber.changeNumberToDate(ev)
  }

  prefixMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          })
          this.cityFilterTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length > 11) || ev.type == "blur" && cleanCpf.length > 11) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CPF Inválido`, 3000)
      this.cpfInputTarget.classList.add("f-danger")
      this.cpfInputTarget.classList.remove("f-normal")
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.controllerDate.checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  showSources(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `users--works--recruitments--applicants--source-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).permission = this.permission
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  setNumber(ev) {
    var number = ev.currentTarget.dataset.number

    this.numberInputTarget.value = number
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.appliedAtInputTarget.value == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.journeyInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.positionInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.prefixInputTarget.value == ``) {
        len += 1
      }

      if (controller.phoneInputTarget.value == ``) {
        len += 1
      }

      if (controller.cityInputTarget.value == ``) {
        len += 1
      }

      if (controller.sourceInputTarget.value == ``) {
        len += 1
      }

      if (controller.score == 0) {
        len += 1
      }



      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
