import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["list", "input", "characterCount", "checkbox", "body"]

  connect() {
    this.controllerName = `users--tasks--board--job`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  characterCount() {
    var count = this.inputTarget.value.length
    if (count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${count} caracteres`
    }

    this.characterCountTarget.textContent = message

    if (count > 140) {
      this.characterCountTarget.classList.add("text-danger")
    } else {
      this.characterCountTarget.classList.remove("text-danger")
    }
  }

  clickLink(e) {
    var link = e.target.innerText
    if (e.target.innerText.includes("http")) {
      window.open(link, '_blank')
    } else if (e.target.innerText.includes("www")) {
      window.open('https://' + link, '_blank')
    }
  }

  save(ev) {
    const task_id = this.taskId
    if (document.querySelector(`#card-${task_id}`).usersTasksBoardUnit.userCanUpdate()) {
      var body = this.inputTarget.value
      var count = this.inputTarget.value.length
      if (count > 140) {
        ev.preventDefault()
      } else {
        if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && count > 0) {
          const data = { job: { task_id: task_id, body: body }, current_user: { current_user_id: currentUser.id } }
          this.requestCreate(data)
        } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
          this.inputTarget.value = ""
        }
      }
    }
  }

  check(e) {
    const task_id = this.taskId
    if (document.querySelector(`#card-${task_id}`).usersTasksBoardUnit.userCanUpdate()) {
      var body = ""
      var thisBodyTarget = ""
      this.bodyTargets.forEach(element => {
        if (element.dataset.jobId == e.target.dataset.jobId) {
          thisBodyTarget = element
          body = element.innerText
        }
      });
      if (e.target.checked) {
        var data = { job: { id: e.target.dataset.jobId, task_id: task_id, status: e.target.checked, done_at: new Date() }, current_user: { current_user_id: currentUser.id } }
      } else {
        var data = { job: { id: e.target.dataset.jobId, task_id: task_id, status: e.target.checked, done_at: null }, current_user: { current_user_id: currentUser.id } }
      }
      this.requestUpdate(data, thisBodyTarget)
    } else {
      e.preventDefault()
    }
  }

  inactiveJob(e) {
    const task_id = this.taskId
    if (document.querySelector(`#card-${task_id}`).usersTasksBoardUnit.userCanUpdate()) {
      const id = e.target.dataset.jobId
      const element = e.target.closest('.job-item')
      const data = { job: { id: id, active: false }, current_user: { current_user_id: currentUser.id } }
      if (e.target.closest('.job-item').querySelector('#job-' + id).checked) {
        document.querySelector(`#card-${task_id}`).usersTasksBoardUnit.doneJobs(-1)
      }
      document.querySelector(`#card-${task_id}`).usersTasksBoardUnit.totalJobs(-1)
      this.requestDelete(data, element)
    }
  }

  requestCreate(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/jobs/create"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          
          const html = this.jobPartial(response.data.cln)
          document.querySelector(`#card-${response.data.cln.task_id}`).usersTasksBoardUnit.totalJobs(1)
          this.listTarget.insertAdjacentHTML("beforeend", html)
          this.inputTarget.value = ""
          this.characterCountTarget.textContent = ""
          this.rollJobs()

          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  requestUpdate(data, target) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/jobs/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var job = response.data.cln
          if (job.status) {
            document.querySelector(`#card-${job.task_id}`).usersTasksBoardUnit.doneJobs(1)
            target.innerHTML = `<s>${job.body}<s>`
          } else {
            document.querySelector(`#card-${job.task_id}`).usersTasksBoardUnit.doneJobs(-1)
            target.innerHTML = `${job.body}`
          }
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  requestDelete(data, domElement) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/jobs/delete"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          domElement.remove()
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  jobPartial(job) {
    var html = `<div class="row my-1 py-1 job-item">
                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                      <div class="custom-control custom-checkbox pl-1">`
                        if (job.status) {
                          html += `<input type="checkbox" checked class="custom-control-input" id="job-${job.id}" data-job-id="${job.id}" data-${this.controllerName}-target="checkbox" data-users--tasks--board--unit-target="checkboxJob" data-action="click->${this.controllerName}#check">`
                        } else { 
                          html += `<input type="checkbox" class="custom-control-input" id="job-${job.id}" data-job-id="${job.id}" data-${this.controllerName}-target="checkbox" data-users--tasks--board--unit-target="checkboxJob" data-action="click->${this.controllerName}#check">`
                        }
                html += `<label class="custom-control-label pointer" for="job-${job.id}"></label>
                      </div>
                    </div>`
                    if (job.status) {
                      html += `<div class="col-10 px-1 d-flex align-items-center text-left" data-${this.controllerName}-target="body" data-job-id="${job.id}"><s>${job.body}</s></div>`
                    } else { 
                      html += `<div class="col-10 px-1 d-flex align-items-center text-left" data-${this.controllerName}-target="body" data-job-id="${job.id}">${job.body}</div>`
                    }        
            html += `<div class="col-1 px-0 d-flex align-items-center text-rigth"><span class="material-icons md-sm ml-auto pointer" data-job-id="${job.id}" data-action="click->${this.controllerName}#inactiveJob">clear</span></div>
                  </div>`

    return html
  }

  rollJobs() {
    const task_id = this.taskId
    $("#jobBody-" + task_id).stop().animate({ scrollTop: $("#jobBody-" + task_id)[0].scrollHeight }, 1000);
  }

  get taskId() {
    return parseInt(this.data.get("taskId"))
  }

}