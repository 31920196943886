import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["name", "link", "contentRow", "figure", "uploadCover", "uploadMaterial", "fileNameCover",
    "fileNameMaterial", "figureCover", "figureMaterial", "progressUploadCover", "progressUploadCoverBar", "labelUploadCover",
    "newUploadedCover", "newUploadedMaterial", "btnCta", "videoLink", "seo", "frames", "questions", "material",
    "progressUploadCoverCounter", "progressUploadMaterial", "progressUploadMaterialBar", "labelUploadMaterial",
    "progressUploadMaterialCounter"]

  connect() {
    this.controllerName = `marketing--downloads--entities--show`
    this.downloadSlug = location.pathname.split("/").pop()
    this.pagePreloader()
    this.getCurrentUserPermissions()
  }

  editDownload(ev) {
    var span = ev.target
    var input = ev.target.nextElementSibling
    span.classList.add("d-none")
    input.classList.remove("d-none")
    if (input.dataset.field == "video") {
      input.value = ""
    } else {
      input.value = span.innerText
    }
    input.focus()
  }

  saveDownload(ev) {
    var span = ev.target.previousElementSibling
    var input = ev.target

    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      span.classList.remove("d-none")
      input.classList.add("d-none")
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur") {
      const value = input.value.trim()
      const field = input.dataset.field
      span.classList.remove("d-none")
      input.classList.add("d-none")
      if (value != span.innerText) {
        this.bindOutput(span, field, value)
        const data = { download: { id: this.download.id, value: value, field: field }, current_user: { current_user_id: currentUser.id } }
        this.requestUpdate(data)
      }
    }
  }

  bindOutput(span, field, value) {
    if (field == "btn_cta") {
      this.btnCtaTarget.innerText = value
      span.innerText = value
    } else if (field == "video") {
      span.innerText = "library_add"
      this.videoLinkTarget.innerHTML = `<div class="embed-responsive embed-responsive-16by9">
                                          <iframe width="700" SameSite=None class="embed-responsive-item" height="400" src="https://www.youtube.com/embed/${value}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>`
    }  else {
      span.innerText = value
    }
  }

  downloadMaterial(ev) {
    var url = ev.target.dataset.url
    window.open(url, `_blank`)
  }

  requestUpdate(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/marketing/downloads/entities/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.download = response.data.cln
        }
        processingSnackbar(response.type, response.message, device)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  uploadFile(ev) {
    if (ev.target.closest(".figureCover")) {
      this.uploadCoverTarget.classList.remove("d-none")
    } else if (ev.target.closest(".figureMaterial")) {
      this.uploadMaterialTarget.classList.remove("d-none")
    }
  }

  cancelUpload() {
    this.uploadMaterialTarget.classList.add("d-none")
    this.uploadCoverTarget.classList.add("d-none")
  }

  progressCount(value, field) {
    var controller = this

    if (field == "cover") {
      this.progressUploadCoverBarTarget.style.width = value + `%`
      var i = 0
      if (value != 100) {
        this.refreshTimer = setInterval(function () {
          controller.progressUploadCoverBarTarget.style.width = Math.floor(i + 1) + `%`
          controller.progressUploadCoverCounterTarget.innerText = Math.floor(i + 1) + `%`
          i++
          if (i == 95) {
            i = 94
          }
        }, 500);
      }
    } else if (field == "material") {
      this.progressUploadMaterialBarTarget.style.width = value + `%`
      var i = 0
      if (value != 100) {
        this.refreshTimer = setInterval(function () {
          controller.progressUploadMaterialBarTarget.style.width = Math.floor(i + 1) + `%`
          controller.progressUploadMaterialCounterTarget.innerText = Math.floor(i + 1) + `%`
          i++
          if (i == 95) {
            i = 94
          }
        }, 500);
      }
    }
  }

  saveUpload(ev) {
    if (ev.type == "click" && ev.target.type == "submit") {
      
      var formData = new FormData();
      var field = ev.target.dataset.field
      formData.append('download_id', this.download.id);
      formData.append('file', this.file);
      formData.append('file_name', this.file.name);
      formData.append('user_id', currentUser.id);
      formData.append('field', field);

      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/marketing/downloads/entities/save_upload"
      const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

      if (field == "cover") {
        this.progressUploadCoverTarget.classList.remove("d-none")
        this.progressUploadCoverCounterTarget.classList.remove("d-none")
        this.labelUploadCoverTarget.classList.remove("btn-primary")
        this.labelUploadCoverTarget.classList.add("btn-disabled")
      } else if (field == "material") {
        this.progressUploadMaterialTarget.classList.remove("d-none")
        this.progressUploadMaterialCounterTarget.classList.remove("d-none")
        this.labelUploadMaterialTarget.classList.remove("btn-primary")
        this.labelUploadMaterialTarget.classList.add("btn-disabled")
      }
      this.progressCount(0, field)
      ev.target.type = "file"
      ev.preventDefault()
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          controller.progressCount(100, field)
          controller.stopRefreshing()
          if (response.save) {
            this.download = response.data.cln
            if (field == "cover") {
              controller.uploadCoverTarget.remove()
              controller.figureCoverTarget.remove()
              controller.newUploadedCoverTarget.classList.remove("d-none")
              controller.newUploadedCoverTarget.innerHTML = `<figure class="figure btn btn-flat text-center s-title-1rem">
                                                              <img src="${this.download.cover_url}" alt="cover" width="100%;">
                                                              <figcaption class="figure-caption mt-3">Cover</figcaption>
                                                            </figure>`
            } else if (field == "material") {
              controller.uploadMaterialTarget.remove()
              controller.figureMaterialTarget.remove()
              controller.newUploadedMaterialTarget.classList.remove("d-none")
              controller.newUploadedMaterialTarget.innerHTML = `<figure class="figure btn btn-flat text-center s-title-1rem">
                                                                  <span class="material-icons" data-url="${this.download.material_url}" alt="material" data-action="click->${this.controllerName}#downloadMaterial">get_app</span>
                                                                  <figcaption class="figure-caption mt-3">Material</figcaption>
                                                                </figure>`
            }
          } else {
          }
          processingSnackbar(response.type, response.message, device, 3000)
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })

    } else if (ev.type == "change" && ev.target.type == "file") {
      this.file = ev.target.files[0]
      if (ev.target.dataset.field == "material") {
        this.fileNameMaterialTarget.innerText = ev.target.files[0].name
      } else if (ev.target.dataset.field == "cover") {
        this.fileNameCoverTarget.innerText = ev.target.files[0].name
      }
      ev.target.type = "submit"
      ev.target.nextElementSibling.innerText = "SALVAR IMAGEM"
    }
  }

  doShowPage() {
    var html = `<ul class="nav nav-justified nav-tabs" id="justifiedSettingsTab" role="tablist">
                  <li class="nav-item">
                    <a aria-controls="seo" aria-selected="true" class="nav-link active" data-toggle="tab" href="#seo" id="seo-tab" role="tab">SEO</a>
                  </li>
                  <li class="nav-item">
                    <a aria-controls="frames" aria-selected="true" class="nav-link" data-toggle="tab" href="#frames" id="frames-tab" role="tab">Sessões</a>
                  </li>
                  <li class="nav-item">
                    <a aria-controls="questions" aria-selected="true" class="nav-link" data-toggle="tab" href="#questions" id="questions-tab" role="tab">Perguntas</a>
                  </li>
                  <li class="nav-item">
                    <a aria-controls="material" aria-selected="true" class="nav-link" data-toggle="tab" href="#material" id="material-tab" role="tab">Material</a>
                  </li>
                </ul>
                <div class="tab-content" id="justifiedSettingsTabContent">
                  <div aria-labelledby="seo-tab" class="tab-pane fade show active" role="tabpanel" id="seo"><div class="list-group" data-${this.controllerName}-target="seo"></div></div>
                  <div aria-labelledby="frames-tab" class="tab-pane fade" role="tabpanel" id="frames"><div class="list-group" data-${this.controllerName}-target="frames"></div></div>
                  <div aria-labelledby="questions-tab" class="tab-pane fade" role="tabpanel" id="questions"><div class="list-group" data-${this.controllerName}-target="questions"></div></div>
                  <div aria-labelledby="material-tab" class="tab-pane fade" role="tabpanel" id="material"><div class="list-group" data-${this.controllerName}-target="material"></div></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentRowTarget.innerHTML = html)
    }).then(() => {
      controller.doHeaderInfoHtml()
      controller.doSEOEditHtml()
      controller.doMainFrameHtml()
      controller.doSecondaryFrameHtml()
      controller.doCTAFrameHtml()
      controller.doQuestionsFrameHtml()
      controller.doUploadMaterial()

      this.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  doHeaderInfoHtml() {
    if (this.application.download_entities.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">share</i></button>`
    } else {
      var copyPath = ``
    }
    this.nameTarget.innerText = this.download.name
    this.linkTarget.innerHTML = `Link para Download: <span data-copy="${this.download.public_path}">${copyPath}</span>`
  }

  doSEOEditHtml() {
    var download = this.download

    if (download.landings.length == 0) {
      var landingsHtml = `Não tem LP atribuída`    
    } else {
      var landingsHtml = `<ul class="ul-select">`
      download.landings.forEach(landing => {
        landingsHtml += `<li>${landing.name}</li>`
      });
      landingsHtml += `</ul>` 
    }
    


    var html = `<div class="row my-3 d-flex justify-content-center">
                  <div class="col-3">
                    <div class="card border-top-primary">
                      <div class="card-body">
                        <h5 class="card-title">Meta Título</h5>
                        <span class="card-text">${download.meta_title}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card border-top-primary">
                      <div class="card-body">
                        <h5 class="card-title">Meta Descrição</h5>
                        <div class="card-text pointer" data-action="click->${this.controllerName}#editDownload">${download.meta_description}</div>
                        <textarea autofocus data-field="meta_description" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card border-top-primary">
                      <div class="card-body">
                        <h5 class="card-title">Meta Keywords</h5>
                        <div class="card-text pointer" data-action="click->${this.controllerName}#editDownload">${download.meta_keywords}</div>
                        <textarea autofocus data-field="meta_keywords" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card border-top-primary">
                      <div class="card-body">
                        <h5 class="card-title">LP's</h5>
                        <div class="card-text">${landingsHtml}</div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.seoTarget.insertAdjacentHTML("beforeend", html)
  }

  doMainFrameHtml() {
    var download = this.download

    if (download.cover_url) {
      var coverHtml = `<img src="${download.cover_url}" alt="cover" width="100%;">
                      <figcaption class="figure-caption mt-3">Cover</figcaption>` 
    } else {
      var coverHtml = `<span class="fa-stack">
                        <i class="far fa-bookmark fa-stack-2x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <figcaption class="figure-caption mt-3">Sem Cover</figcaption>` 
    }

    var html = `<div class="row my-3" style="height:20rem;border:1px solid #50514F;">
                  <div class="col-6">
                    <h4 class="text-center text-bold"># Frame 1</h4>
                    <div class="row h-50">
                      <div class="col-12 d-flex align-items-end justify-content-center">
                        <h4 class="title-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.title_one}</h4>
                        <textarea autofocus data-field="title_one" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                    <div class="row h-50">
                      <div class="col-12 py-3 d-flex align-items-start justify-content-center">
                        <p class="subtitle-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.subtitle_one}</p>
                        <textarea autofocus data-field="subtitle_one" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-4 py-3 d-flex align-items-center justify-content-center figureCover" data-${this.controllerName}-target="figureCover" data-field="cover">
                        <figure class="figure btn btn-flat text-center s-title-1rem" data-action="click->${this.controllerName}#uploadFile" style="border:1px dashed #50514F;">
                          ${coverHtml}
                        </figure>
                      </div>
                      <div class="col-8 py-2 d-flex align-items-center justify-content-center d-none" data-${this.controllerName}-target="uploadCover">
                        <div class="form-group form-valid-group my-0 text-center">
                          <input data-direct-upload-url="http://localhost:3000/rails/active_storage/direct_uploads" type="file" id="downloadCover" name="downloadCover" class="file-input" data-action="change->${this.controllerName}#saveUpload click->${this.controllerName}#saveUpload" data-field="cover">
                          <label for="downloadCover" id="downloadCoverLabelForm" class="btn btn-primary" data-${this.controllerName}-target="labelUploadCover">Selecione uma Imagem</label>
                          <span class="fileNameForm" class="mx-2"></span>
                          <h4 class="progress" style="display: none">Progress: <span class="progress_count"></span>%</h4>
                          <span data-${this.controllerName}-target="fileNameCover" class="d-block"></span>
                          <div class="progress d-none" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUploadCover">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadCoverBar"></div>
                          </div>
                          <span class="d-none" data-${this.controllerName}-target="progressUploadCoverCounter" class="d-block"></span>
                        </div>
                        <button type="button" class="close ml-3" data-dismiss="modal" data-action="click->${this.controllerName}#cancelUpload" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="col-4 py-3 d-flex align-items-center justify-content-center d-none" data-${this.controllerName}-target="newUploadedCover"></div>
                    </div>
                  </div>
                </div>`

    this.framesTarget.insertAdjacentHTML("beforeend", html)
  }

  doSecondaryFrameHtml() {
    var download = this.download

    if (download.video) {
      var videoHtml = `<div class="embed-responsive embed-responsive-16by9">
                        <iframe width="700" SameSite=None class="embed-responsive-item" height="400" src="https://www.youtube.com/embed/${download.video}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>`
    } else {
      var videoHtml = `<figure class="figure btn btn-flat text-center s-title-1rem" style="border:1px dashed #50514F;">
                        <span class="fa-stack">
                          <i class="fab fa-youtube fa-stack-2x"></i>
                          <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                        </span>
                        <figcaption class="figure-caption mt-3">Sem Video</figcaption>
                      </figure>`
    }

    var html = `<div class="row my-3" style="height:20rem;border:1px solid #50514F;">
                  <div class="col-6">
                    <h4 class="text-center text-bold"># Frame 2</h4>
                    <div class="row h-50">
                      <div class="col-12 d-flex align-items-end justify-content-center">
                        <h4 class="title-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.title_two}</h4>
                        <textarea autofocus data-field="title_two" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                    <div class="row h-50">
                      <div class="col-12 py-3 d-flex align-items-start justify-content-center">
                        <p class="subtitle-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.subtitle_two}</p>
                        <textarea autofocus data-field="subtitle_two" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 d-flex align-items-center">
                    <div class="col-4 py-3 d-flex align-items-center justify-content-center video" data-${this.controllerName}-target="video" data-field="video">
                      <span class="material-icons md-200 md-light pointer" data-action="click->${this.controllerName}#editDownload" data-toggle="tooltip" data-placement="top" title data-original-title="Adicionar Vídeo">library_add</span>
                      <input autofocus data-field="video" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required>
                    </div>
                    <div class="col-8 py-2 d-flex align-items-center justify-content-center" data-${this.controllerName}-target="videoLink">
                      ${videoHtml}
                    </div>
                  </div>
                </div>`

    this.framesTarget.insertAdjacentHTML("beforeend", html)
    // <button type="button" class="btn btn-sm btn-table pointer p-0" data-action="click->${this.controllerName}#editDownload" data-toggle="tooltip" data-placement="top" title data-original-title="Adicionar Vídeo"><span class="material-icons md-sm md-light">library_add</span></button>
  }

  doCTAFrameHtml() {
    var download = this.download

    var html = `<div class="row my-3" style="height:10rem;border:1px solid #50514F;">
                  <div class="col-6">
                    <h4 class="text-center text-bold"># Frase CTA</h4>
                    <h4 class="title-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.setence_cta}</h4>
                    <textarea autofocus data-field="setence_cta" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                  </div>
                  <div class="col-6 text-center">
                    <h4 class="text-center text-bold"># Btn CTA</h4>
                    <p class="subtitle-two text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.btn_cta}</p>
                    <textarea autofocus data-field="btn_cta" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                    <label class="btn btn-primary" data-${this.controllerName}-target="btnCta">${download.btn_cta}</label>
                  </div>
                </div>`

    this.framesTarget.insertAdjacentHTML("beforeend", html)
  }

  doQuestionsFrameHtml() {
    var download = this.download

    var html = `<div class="row my-3" style="height:20rem;border:1px solid #50514F;">
                  <div class="col-4">
                    <div class="row h-15">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h4 class="text-center text-bold"># Pergunta 1</h4>
                      </div>
                    </div>
                    <div class="row h-30">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h5 class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.question_one}</h5>
                        <textarea autofocus data-field="question_one" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                    <div class="row h-50">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <p class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.answer_one}</p>
                        <textarea autofocus data-field="answer_one" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="row h-15">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h4 class="text-center text-bold"># Pergunta 2</h4>
                      </div>
                    </div>
                    <div class="row h-30">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h5 class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.question_two}</h5>
                        <textarea autofocus data-field="question_two" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                    <div class="row h-50">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <p class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.answer_two}</p>
                        <textarea autofocus data-field="answer_two" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="row h-15">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h4 class="text-center text-bold"># Pergunta 3</h4>
                      </div>
                    </div>
                    <div class="row h-30">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h5 class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.question_three}</h5>
                        <textarea autofocus data-field="question_three" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                    <div class="row h-50">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <p class="text-center pointer" data-action="click->${this.controllerName}#editDownload">${download.answer_three}</p>
                        <textarea autofocus data-field="answer_three" data-action="keyup->${this.controllerName}#saveDownload change->${this.controllerName}#saveDownload blur->${this.controllerName}#saveDownload" class="form-control textarea p-1 s-title-0p85rem d-none" type="text" required></textarea>
                      </div>
                    </div>
                  </div>
                  
                </div>`

    this.questionsTarget.insertAdjacentHTML("beforeend", html)
  }

  doUploadMaterial() {
    var download = this.download

    if (download.material_url) {
      var materialHtml = `<span class="material-icons" data-url="${this.download.material_url}" alt="material" data-action="click->${this.controllerName}#downloadMaterial">get_app</span>
                          <figcaption class="figure-caption mt-3">Material</figcaption>`
    } else {
      var materialHtml = `<span class="fa-stack">
                            <i class="fas fa-book fa-stack-2x"></i>
                            <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                          </span>
                          <figcaption class="figure-caption mt-3">Sem Material</figcaption>`
    }

    var html = `<div class="row my-3" style="height:20rem;border:1px solid #50514F;">
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-12 d-flex align-items-center justify-content-center">
                        <h4 class="title-two text-center">Material para Download</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-4 py-3 d-flex align-items-center justify-content-center figureMaterial" data-${this.controllerName}-target="figureMaterial" data-field="material">
                        <figure class="figure btn btn-flat text-center s-title-1rem" data-action="click->${this.controllerName}#uploadFile" style="border:1px dashed #50514F;">
                          ${materialHtml}
                        </figure>
                      </div>
                      <div class="col-8 py-2 d-flex align-items-center justify-content-center d-none" data-${this.controllerName}-target="uploadMaterial">
                        <div class="form-group form-valid-group my-0 text-center">
                          <input data-direct-upload-url="http://localhost:3000/rails/active_storage/direct_uploads" type="file" id="downloadMaterial" name="downloadMaterial" class="file-input" data-action="change->${this.controllerName}#saveUpload click->${this.controllerName}#saveUpload" data-field="material">
                          <label for="downloadMaterial" id="downloadMaterialLabelForm" class="btn btn-primary" data-${this.controllerName}-target="labelUploadMaterial">Selecione o Material</label>
                          <span class="fileNameForm" class="mx-2"></span>
                          <h4 class="progress" style="display: none">Progress: <span class="progress_count"></span>%</h4>
                          <span data-${this.controllerName}-target="fileNameMaterial" class="d-block"></span>
                          <div class="progress d-none" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUploadMaterial">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadMaterialBar"></div>
                          </div>
                          <span class="d-none" data-${this.controllerName}-target="progressUploadMaterialCounter" class="d-block"></span>
                        </div>
                        <button type="button" class="close ml-3" data-dismiss="modal" data-action="click->${this.controllerName}#cancelUpload" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="col-4 py-3 d-flex align-items-center justify-content-center d-none" data-${this.controllerName}-target="newUploadedMaterial"></div>
                    </div>
                  </div>
                </div>`

    this.materialTarget.insertAdjacentHTML("beforeend", html)
  }


  getDownload() {
    var data = { download: { slug: this.downloadSlug }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/marketing/downloads/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.download = response.data.cln
          $('#breadcrumbName').html(controller.download.name)
          controller.doShowPage()
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["download_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });

        if (controller.application.download_entities.can_read) {
          controller.getDownload()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  pagePreloader() {
    const headerHtml = `<div class='card timeline-item m-0 p-1 w-10' style="box-shadow:none;">
                          <div class='animated-background animated-background-5'>
                            <div class='background-masker title'></div>
                          </div>
                        </div>`

    var contentHtml = `<ul class="nav nav-justified nav-tabs timeline-item-nav-tab" id="justifiedSettingsTab" role="tablist">
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li>
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li> 
                        <li class="nav-item">
                          <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                            <div class='animated-background animated-background-5'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </li>                       
                      </ul>
                      <div class="row">
                        <div class="col-12">
                          <div class='card timeline-item-100 my-2'>
                            <div class='animated-background animated-background-20'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <div class='card timeline-item-100 my-2'>
                            <div class='animated-background animated-background-20'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class='card timeline-item-100 my-2'>
                            <div class='animated-background animated-background-20'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class='card timeline-item-100 my-2'>
                            <div class='animated-background animated-background-20'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3">
                          <div class='card timeline-item-100 my-2'>
                            <div class='animated-background animated-background-20'>
                              <div class='background-masker title'></div>
                            </div>
                          </div>
                        </div>
                      </div>`

    this.nameTarget.innerHTML = headerHtml
    this.linkTarget.innerHTML = headerHtml
    this.contentRowTarget.innerHTML = contentHtml
  }

}