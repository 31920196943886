import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "addEmailBtn", "addPhoneBtn", "addAddressBtn", "bodyEmailTable", "emailAddressInput", "emailKindDropdown", "emailKindDropdownBtn", "emailKindInput",
    "emailKindList", "emailMainCheckbox", "emailForm", "cancelEmailBtn"]

  connect() {
    this.controllerName = `users--accounts--entities--contact`
    this.current_account = this.getControllerByIdentifier(`users--accounts--entities--show`).current_account
    this.doContactsHtml()
  }

  disconnect() {
    if (this.refreshContactTimer) {
      clearInterval(this.refreshContactTimer)
    }
  }

  addEmail() {
    this.getControllerByIdentifier("users--accounts--contacts--email").actionMode = "new"
    this.getControllerByIdentifier("users--accounts--contacts--email").formHtml()
  }

  addPhone() {
    this.getControllerByIdentifier("users--accounts--contacts--phone").actionMode = "new"
    this.getControllerByIdentifier("users--accounts--contacts--phone").formHtml()
  }

  addAddress() {
    this.getControllerByIdentifier("users--accounts--contacts--address").actionMode = "new"
    this.getControllerByIdentifier("users--accounts--contacts--address").formHtml()
  }

  doContactsHtml() {
    if (this.application.permissions.account_entity_contacts.can_create) {
      var createEmail = `<div class="col-1 d-flex align-items-center px-0 mc-tooltip">
                          <button data-${this.controllerName}-target="addEmailBtn" data-action="click->${this.controllerName}#addEmail" type="button" class="btn btn-sm btn-table p-0">
                            <span class="material-icons md-sm md-dark">add</span>
                          </button>
                          <span class="mc-tooltiptext">Adicionar Email</span>
                        </div>`
      var createPhone = `<div class="col-1 d-flex align-items-center px-0 mc-tooltip">
                          <button data-${this.controllerName}-target="addPhoneBtn" data-action="click->${this.controllerName}#addPhone" type="button" class="btn btn-sm btn-table p-0">
                            <span class="material-icons md-sm md-dark">add</span>
                          </button>
                          <span class="mc-tooltiptext">Adicionar Telefone</span>
                        </div>`
      var createAddress = `<div class="col-1 d-flex align-items-center px-0 mc-tooltip">
                            <button data-${this.controllerName}-target="addAddressBtn" data-action="click->${this.controllerName}#addAddress" type="button" class="btn btn-sm btn-table p-0">
                              <span class="material-icons md-sm md-dark">add</span>
                            </button>
                            <span class="mc-tooltiptext">Adicionar Endereço</span>
                          </div>`
    } else {
      var createEmail = `<div class="col-1"></div>`
      var createPhone = `<div class="col-1"></div>`
      var createAddress = `<div class="col-1"></div>`
    }

    var html = `<div class="row my-1">
                  ${createEmail}
                  <div class="col-2 px-0 d-flex align-items-center f-065"><i class="material-icons s-title-1rem mr-1">contact_mail</i> E-mail: </div>
                  <div class="col-8 px-0" data-controller="users--accounts--contacts--email" data-users--accounts--contacts--email-target="list"></div>
                </div>
                <div class="row my-1">
                  ${createPhone}
                  <div class="col-2 px-0 d-flex align-items-center f-065"><i class="material-icons s-title-1rem mr-1">contact_phone</i> Telefone: </div>
                  <div class="col-8 px-0" data-controller="users--accounts--contacts--phone" data-users--accounts--contacts--phone-target="list"></div>
                </div>
                <div class="row my-1">
                  ${createAddress}
                  <div class="col-2 px-0 d-flex align-items-center f-065"><i class="material-icons s-title-1rem mr-1">home</i> Endereço: </div>
                  <div class="col-8 px-0" data-controller="users--accounts--contacts--address" data-users--accounts--contacts--address-target="list"></div>
                </div>`

    this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--accounts--contacts--email").email_list = controller.current_account.email_list
      controller.getControllerByIdentifier("users--accounts--contacts--phone").phone_list = controller.current_account.phone_list
      controller.getControllerByIdentifier("users--accounts--contacts--address").address_list = controller.current_account.address_list
      controller.getControllerByIdentifier("users--accounts--contacts--email").permissions = this.application.permissions.account_entity_contacts
      controller.getControllerByIdentifier("users--accounts--contacts--phone").permissions = this.application.permissions.account_entity_contacts
      controller.getControllerByIdentifier("users--accounts--contacts--address").permissions = this.application.permissions.account_entity_contacts
      controller.refreshContactList()
    })
  }

  refreshContactList() {
    var controller = this
    this.refreshContactTimer = setInterval(function () {
      if (controller.getControllerByIdentifier("users--accounts--contacts--email")) {
        controller.current_account.email_list = controller.getControllerByIdentifier("users--accounts--contacts--email").email_list
        controller.current_account.email = controller.getControllerByIdentifier("users--accounts--contacts--email").email_list[0]
      }
      if (controller.getControllerByIdentifier("users--accounts--contacts--phone")) {
        controller.current_account.phone_list = controller.getControllerByIdentifier("users--accounts--contacts--phone").phone_list
      }
      if (controller.getControllerByIdentifier("users--accounts--contacts--address")) {
        controller.current_account.address_list = controller.getControllerByIdentifier("users--accounts--contacts--address").address_list
      }
    }, 200);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
