import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "nameDiv", "nameInputDiv", "nameInput", "cpfDiv", "cpfInputDiv", "cpfInput",
                    "birthdateDiv", "birthdateInputDiv", "birthdateInput", "saveBtn", "editBtn", "cancelBtn",
                    "sexDiv", "sexInputDiv", "sexDropdown", "sexDropdownBtn", "sexInput", "sexList",
                    "roleDiv", "roleInputDiv", "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "employmentDiv", "employmentInputDiv", "employmentDropdown", "employmentDropdownBtn", "employmentInput", "employmentList",]


  connect() {
    this.controllerName = `operations--products--bookings--helpers--principal`
    this.send_data = { current_user: {}, helper: {}, account: {} }
    this.doPrincipalHtml()
  }

  editHelper(ev) {
    this.saveBtnTarget.classList.remove("d-none")
    this.refreshSaveBtn()

    if (ev.currentTarget.closest("tr").dataset) {
      this.field = ev.currentTarget.closest("tr").dataset.field
      ev.currentTarget.classList.add("d-none")
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
    } else {
      this.field = "checkbox"
    }

    if (this.field == "name") {
      this.nameDivTarget.classList.add("d-none")
      this.nameInputDivTarget.classList.remove("d-none")
      this.nameInputTarget.value = this.nameDivTarget.innerText.trim()
      this.nameInputTarget.focus()
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cpfInputTarget.value = this.cpfDivTarget.innerText.trim()
      this.cpfInputTarget.focus()
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.add("d-none")
      this.birthdateInputDivTarget.classList.remove("d-none")
      this.birthdateInputTarget.value = this.birthdateDivTarget.innerText.trim()
      this.birthdateInputTarget.focus()
      this.getControllerByIdentifier("app--helpers--pickdate").pick($(this.birthdateInputTarget), { max: true, months: true, years: 60 })
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.add("d-none")
      this.sexInputDivTarget.classList.remove("d-none")
      this.sexInputTarget.innerText = this.sexDivTarget.innerText.trim()
      this.sexInputTarget.focus()
    } else if (this.field == "role") {
      this.roleDivTarget.classList.add("d-none")
      this.roleInputDivTarget.classList.remove("d-none")
      this.roleInputTarget.innerText = this.roleDivTarget.innerText.trim()
      this.roleInputTarget.focus()
    } else if (this.field == "employment") {
      this.employmentDivTarget.classList.add("d-none")
      this.employmentInputDivTarget.classList.remove("d-none")
      this.employmentInputTarget.innerText = this.employmentDivTarget.innerText.trim()
      this.employmentInputTarget.focus()
    }
  }

  cancelInput(ev) {
    ev.currentTarget.classList.add("d-none")
    ev.currentTarget.previousElementSibling.classList.remove("d-none")

    if (this.field == "name") {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.application.current_helper.account_name
    } else if (this.field == "cpf") {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.application.current_helper.account_cpf_pretty
    } else if (this.field == "birthdate") {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.application.current_helper.account_birthdate_pretty
    } else if (this.field == "sex") {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.application.current_helper.account_sex_pretty
    } else if (this.field == "role") {
      this.roleDivTarget.classList.remove("d-none")
      this.roleInputDivTarget.classList.add("d-none")
      this.roleDivTarget.innerText = this.application.current_helper.role_pretty
    } else if (this.field == "employment") {
      this.employmentDivTarget.classList.remove("d-none")
      this.employmentInputDivTarget.classList.add("d-none")
      this.employmentDivTarget.innerText = this.application.current_helper.employment_pretty
    }
    
  }

  closeInput() {
    this.saveBtnTarget.classList.add("d-none")

    if (this.nameDivTarget.classList.contains("d-none")) {
      this.nameDivTarget.classList.remove("d-none")
      this.nameInputDivTarget.classList.add("d-none")
      this.nameDivTarget.innerText = this.nameInputTarget.value
    }

    if (this.cpfDivTarget.classList.contains("d-none")) {
      this.cpfDivTarget.classList.remove("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cpfDivTarget.innerText = this.cpfInputTarget.value
    }

    if (this.birthdateDivTarget.classList.contains("d-none")) {
      this.birthdateDivTarget.classList.remove("d-none")
      this.birthdateInputDivTarget.classList.add("d-none")
      this.birthdateDivTarget.innerText = this.birthdateInputTarget.value
    }

    if (this.sexDivTarget.classList.contains("d-none")) {
      this.sexDivTarget.classList.remove("d-none")
      this.sexInputDivTarget.classList.add("d-none")
      this.sexDivTarget.innerText = this.sexInputTarget.innerText
    }

    if (this.roleDivTarget.classList.contains("d-none")) {
      this.roleDivTarget.classList.remove("d-none")
      this.roleInputDivTarget.classList.add("d-none")
      this.roleDivTarget.innerText = this.roleInputTarget.innerText
    }

    if (this.employmentDivTarget.classList.contains("d-none")) {
      this.employmentDivTarget.classList.remove("d-none")
      this.employmentInputDivTarget.classList.add("d-none")
      this.employmentDivTarget.innerText = this.employmentInputTarget.innerText
    }
  }

  saveHelper() {

    this.editBtnTargets.forEach(element => {
      element.classList.remove("d-none")
    });

    this.cancelBtnTargets.forEach(element => {
      element.classList.add("d-none")
    });

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closeInput())
    }).then(() => {
      controller.send_data.current_user.current_user_id = this.application.current_user.id
      controller.send_data.helper.id = this.application.current_helper.id
      controller.send_data.helper.role = controller.roleInputTarget.dataset.role
      controller.send_data.helper.employment = controller.employmentInputTarget.dataset.employment
      controller.send_data.account.id = this.application.current_helper.account_id
      controller.send_data.account.name = controller.nameDivTarget.innerText.trim()
      controller.send_data.account.cpf = controller.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(controller.cpfDivTarget.innerText.trim())
      controller.send_data.account.birthdate = controller.getControllerByIdentifier("app--helpers--date").transformFullDate(controller.birthdateDivTarget.innerText.trim())
      controller.send_data.account.sex = controller.sexInputTarget.dataset.sex
      controller.stopRefreshing()
      controller.requestSave()
    })

  }

  requestSave() {
    var url = "/operations/products/bookings/helpers/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          var helper = response.data.cln
          this.application.current_helper = helper
          this.getControllerByIdentifier("operations--products--bookings--helpers--show").viewTitleTarget.innerText = this.application.current_helper.account_name
          if (helper.active) {
            this.application.helpers.forEach((element, i) => {
              if (element.id == helper.id) {
                this.application.helpers.splice(this.application.helpers.indexOf(element), 1, helper)
              }
            })
          } else {
            this.application.helpers.forEach((element, i) => {
              if (element.id == helper.id) {
                this.application.helpers.splice(this.application.helpers.indexOf(element), 1)
              }
            })
          }
          this.getControllerByIdentifier("operations--products--bookings--helpers--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  doPrincipalHtml() {

    if (this.application.permissions.medbooking_helpers.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyPath = ``
    }

    if (this.application.permissions.medbooking_helpers.can_update) {
      var edit = `<button data-${this.controllerName}-target="editBtn" data-action="click->${this.controllerName}#editHelper" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
      var cancel = `<button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#cancelInput" type="button" class="btn btn-sm btn-table p-0 d-none mc-tooltip">
                    <span aria-hidden="true">&times;</span>
                    <span class="mc-tooltiptext">Cancelar</span>
                  </button>`
    } else {
      var edit = ``
      var cancel = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search" style="font-size:80%;">
                      <tbody>
                        <tr data-field="name">
                          <th class="table-25 px-0 f-065 align-middle"><strong>Nome</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="nameDiv">
                              ${this.application.current_helper.account_name}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="nameInputDiv">
                              <input data-${this.controllerName}-target="nameInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_helper.account_name}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        <tr data-field="cpf">
                          <th class="table-30 px-0 f-065 align-middle"><strong>CPF</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="cpfDiv">
                              ${this.application.current_helper.account_cpf_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="cpfInputDiv">
                              <input data-${this.controllerName}-target="cpfInput" class="form-control p-1 s-title-0p6rem" type="tel" required data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask">
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_helper.account_cpf}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit} 
                            ${cancel} 
                          </td>
                        </tr>
                        <tr data-field="birthdate">
                          <th class="table-30 px-0 f-065 align-middle"><strong>DN</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="birthdateDiv">
                              ${this.application.current_helper.account_birthdate_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="birthdateInputDiv">
                              <input data-${this.controllerName}-target="birthdateInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                            </div>
                          </td>
                          <td class="align-middle text-center" data-copy="${this.application.current_helper.account_cpf}">${copyPath}</td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        <tr data-field="sex">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Sexo</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="sexDiv">
                              ${this.application.current_helper.account_sex_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="sexInputDiv">
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="sexList">
                                    <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                    <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="role">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Função</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="roleDiv">
                              ${this.application.current_helper.role_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="roleInputDiv">
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="roleList">
                                    <li data-role="manager" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gerente</li>
                                    <li data-role="biller" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturista</li>
                                    <li data-role="receptionist" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recepcionista</li>
                                    <li data-role="technician" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Técnica</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>

                        <tr data-field="employment">
                          <th class="table-30 px-0 f-065 align-middle"><strong>Vínculo</strong></th>
                          <td class="table-45 f-065 align-middle">
                            <div data-${this.controllerName}-target="employmentDiv">
                              ${this.application.current_helper.employment_pretty}
                            </div>
                            <div class="d-none" data-${this.controllerName}-target="employmentInputDiv">
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="employmentDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:25px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="employmentDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="employmentInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="employmentList">
                                    <li data-employment="wage" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assalariado</li>
                                    <li data-employment="selfworker" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Autônomo</li>
                                    <li data-employment="labor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pró-Labore</li>
                                    <li data-employment="partner" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sócio</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td class="align-middle text-center">
                            ${edit}
                            ${cancel}
                          </td>
                        </tr>
                        
                        <tr>
                          <td colspan="4" class="text-right">
                            <button type="button" class="btn btn-primary btn-sm f-065 d-none" data-action="click->${this.controllerName}#saveHelper" data-${this.controllerName}-target="saveBtn">Salvar</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.sexInputTarget.dataset.sex = controller.application.current_helper.account_sex
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
    if (ev.type == "blur") {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    }
  }

  refreshSaveBtn() {
    this.stopRefreshing()
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameDivTarget.classList.contains("d-none") && controller.nameInputTarget.value == "") {
        len += 1
      }
      if ((controller.cpfDivTarget.classList.contains("d-none") && controller.cpfInputTarget.value == "") || (controller.cpfDivTarget.classList.contains("d-none") && controller.cpf_result && controller.cpf_result.invalid)) {
        len += 1
      }
      if (controller.birthdateDivTarget.classList.contains("d-none") && controller.birthdateInputTarget.value == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
