import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["weeklySearchs", "view", "main", "chartPreloader", "weeklySearchsTitleChart", "weeklySearchsChart", "submenu", "indicatorsCards",
    "searchsBodyTable", "mainCard", "9999", "9999", "9999", "9999", "9999"]



  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--searchs--entities--summary`
    this.sort = { mode: `desc`, field: `time` }
  }

  doSummaryGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8" data-${this.controllerName}-target="weeklySearchs"></div>
                  <div class="col-4" data-${this.controllerName}-target="indicatorsCards">
                    <div class="col-12 mb-3 px-1">
                      ${this.cardLoader}
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--searchs--entities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doWeeklySearchsArea()
    })
  }

  doWeeklySearchsArea() {
    var html = `<div class="row w-100">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="weeklySearchsTitleChart"></h6>
                            <div class="d-flex align-items-center justify-content-center" style="height:250px;" data-${this.controllerName}-target="weeklySearchsChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="dailyChartBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.weeklySearchsTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  refreshSearchs() {
    this.getMostSearched()
  }

  doMostSearchedCards() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center py-2 f-065">
                    <h6 class="card-title mb-0 py-2 f-075">Mais Buscadas</h6>
                    <input class="form-control f-070 w-100 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions py-0 mc-tooltip px-0">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 py-0" data-${this.controllerName}-target="refreshSearchsBtn" data-action="click->${this.controllerName}#refreshSearchs" type="button">
                        <span class="material-icons md-dark md-sm">refresh</span>
                      </button>
                      <span class="mc-tooltiptext">Atualizar</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-80 align-middle">
                                Keywords
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="keyword" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="keyword" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                Buscas
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="time" data-mode="asc">north</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="searchsBodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorsCardsTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.searchsBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(2, 6))
      controller.getMostSearched()
    })
  }

  doDataTable() {
    if (this.sort.mode == `asc`) {
      var seachs = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.most_searched, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var seachs = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.most_searched, this.sort.field)
    }

    this.listData(seachs)
  }

  listData(data) {
    this.searchsBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Mais Buscadas</span>
                      </td>
                    </tr>`

      this.searchsBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.searchsBodyTableTarget.insertAdjacentHTML("beforeend", this.searchTablePartial(element, data.length))
      });
    }
  }

  searchTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.keyword}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.time}</td>
                  </tr>`

    return rowHtml
  }

  doChartPreloader() {
    this.weeklySearchsTitleChartTarget.innerHTML = `${this.loader}`
    var chartPreloader = `<div class="loading" data-${this.controllerName}-target="chartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.weeklySearchsChartTarget.innerHTML = chartPreloader)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  requestChartData() {
    const data = { chart: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/searchs/entities/searchs_week_comparison"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.chartPreloaderTarget.remove()
        controller.weeklySearchsTitleChartTarget.innerText = `Buscas Semanais Descanso Médico`
        if (response.process) {
          new Chartkick["LineChart"](controller.weeklySearchsChartTarget, [response.data.cln[0], response.data.cln[1]], {
            "colors": ["#086BAC", "#84D2F6"],
            "download": false,
            "prefix": "",
            "thousands": ".",
            "decimal": ","
          })
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
  }

  getMostSearched() {
    var data = { search: { active: true, permitted: true }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/commercial/marketing/searchs/entities/most_searched"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.most_searched = response.data.cln
        controller.doDataTable()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["marketing_content_searchs"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.requestChartData()
        controller.doMostSearchedCards()
        controller.getControllerByIdentifier("commercial--marketing--searchs--entities--list").getSearchs()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

}
