import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--index--dashboard`
    this.application.permissions = {}
    this.application.notes = []
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row">
                  <div class="col-12" data-${this.controllerName}-target="content" data-controller="commercial--marketing--events--index--list
                                                                                                    commercial--marketing--events--index--add
                                                                                                    commercial--marketing--events--dealflow--dashboard
                                                                                                    users--works--permissions--entities--list-users">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--marketing--events--index--add`).permission = `commercial_event_entities`
      controller.getControllerByIdentifier(`commercial--marketing--events--index--list`).permission = `commercial_event_entities`
      controller.getControllerByIdentifier(`commercial--marketing--events--index--list`).doPreloaderHtml()

      if (controller.application.permissions[`commercial_event_permissions`]) {
        controller.finishLoadPage()
      } else {
        controller.getCurrentUserPermissions()
      }
    }) 

  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    this.getControllerByIdentifier(`commercial--marketing--events--index--list`).doIndexListHtml()
    this.getControllerByIdentifier(`commercial--marketing--events--index--submenu`).doSubmenuHtml()

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 95 })
    // var event_ids = this.mapElements(this.application.squads, `event_id`)

    var data = { note: { domain_ids: this.application.event_ids, domain_type: `marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_notes` } }
    const url = "/users/accounts/notes/list_check_by_domains"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEventTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time`, value: 60 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/config/teams/list_event_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team_list = response.data.cln

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEvents() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Eventos`, value: 50 })

    const data = { event: { active: true, event_ids: this.application.event_ids }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_entities` } }
    const url = "/commercial/marketing/events/entities/list_by_ids"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.events = response.data.cln
        controller.getEventTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squad`, value: 30 })

    const data = { squad: { team_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_squads` } }
    const url = "/commercial/marketing/events/squads/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.squads = response.data.cln
          controller.application.event_ids = controller.mapElements(controller.application.squads, `event_id`)
          controller.getEvents()
        } else {
          controller.application.events = []
          controller.finishLoadPage()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getSquads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
