import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "mainDashboard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--management--tickets`
  }

  goToDashboard() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  goToUpload() {
    if (this.application.permissions[this.application.files_permission].can_upload) {
      this.getControllerByIdentifier("mobile--operations--products--tax-filings--files--add").doAddDashboard()
    }
  }

  doTicketDashboard() {
    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Tickets IRPF ${this.application.current_date.year + 1}</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="body">
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.in_process_tickets = []
    this.resolved_tickets = []
    this.application.tickets.forEach(element => {
      if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
        if (element.date_id == this.application.current_date.id) {
          if (element.stage == `in_process`) {
            this.in_process_tickets[this.in_process_tickets.length] = element
          } else if (element.stage == `resolved`) {
            this.resolved_tickets[this.resolved_tickets.length] = element
          }
        }
      } else if (this.application.current_user.account_kind == `doctor`) {
        if (element.date_id == this.application.current_date.id && element.sharing == `external`) {
          if (element.stage == `in_process`) {
            this.in_process_tickets[this.in_process_tickets.length] = element
          } else if (element.stage == `resolved`) {
            this.resolved_tickets[this.resolved_tickets.length] = element
          }
        }
      }
    })

    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)

    this.listData(tickets)
  }

  listData(tickets) {
    this.bodyTarget.innerHTML = ""
    var rowHtml = ``
    if (tickets == undefined || tickets == [] || tickets.length == 0) {
      var noData = `<div class="col-10 offset-1 d-flex align-items-center justify-content-center">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-1p5">Sem Tickets em Processo</span>
                    </div>`

      this.bodyTarget.innerHTML = noData
    } else {
      var i = 0
      tickets.forEach(element => {

        if (this.application.permissions[this.application.ticket_permission].can_list) {
          if (element.total_comments > 0) {
            var commentBadge = `<span class="badge badge-danger notice-badge-comment-mobile" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
          } else {
            var commentBadge = `<span class="badge badge-danger notice-badge-comment-mobile d-none" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
          }
          var commentBtn = `<div class="col-2 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-200 md-dark pointer" data-toggle="collapse" href="#collapseComments-${element.id}" data-ticket-id="${element.id}" data-action="click->${this.controllerName}#doCommentHtml">comment</span>
                              ${commentBadge}
                              <span class="mc-tooltiptext">Comentários</span>  
                            </div>`
          var commentBody = `<div id="collapseComments-${element.id}" class="collapse" data-ticket-id="${element.id}" data-controller="mobile--operations--products--tax-filings--management--comment" data-mobile--operations--products--tax-filings--management--comment-target="collapse-${element.id}"></div>`
        } else {
          var commentBtn = ``
          var commentBody = ``
        }

        if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
          var uploadBtn = `<div class="col-2 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-200 md-dark pointer" data-action="click->${this.controllerName}#goToUpload">cloud_upload</span>
                              <span class="mc-tooltiptext">Fazer upload de Arquivos</span>
                          </div>`
        } else {
          var uploadBtn = ``
        }

        rowHtml += `<div class="col-10 offset-1 text-center">
                      <div class="card my-3 border-flag-${element.flag}">
                        <div class="card-header p-1 text-center f-085 d-flex" style="height:40px;">
                          <div class="col-8 px-1 mr-auto d-flex align-items-center">${element.due_at_pretty}</div>
                          ${uploadBtn}
                          ${commentBtn}
                        </div>
                        <div class="card-body p-1 text-center f-085">
                          <div class="row py-3 px-2">
                            <div class="col-12 px-0 d-flex align-items-center text-left">${element.body}</div>
                          </div>
                        </div>
                        ${commentBody}
                      </div>
                    </div>`
      });
      this.bodyTarget.innerHTML = rowHtml
    }
  }

  doCommentHtml(ev) {
    var ticketId = ev.currentTarget.dataset.ticketId
    this.application.controllers.find(controller => {
      if (controller.context.identifier == `mobile--operations--products--tax-filings--management--comment` && controller.element.id == `collapseComments-${ticketId}`) {
        controller.ticket_id = ticketId
        controller.doCommentHtml()
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
