import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "title", "body", "saveBtn", "fileName"]

  connect() {
    this.controllerName = `users--tasks--board--modal`
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  changeInput() {
    var fileInput = document.querySelector('input[type=file]');
    var images = fileInput.files;
    for (var i = 0; i < images.length; i++) {
      $('.fileNameForm').append('<span class="mx-2">(' + (i + 1) + ') ' + images[i].name + ' | ' + parseInt(images[i].size / 1000000) + ' Mb</span><br>')
    }
  }

  saveAttach() {
    this.saveBtnTarget.disabled = true
    var fileInput = document.querySelector('input[type=file]');
    var file = fileInput.files[0];

    var formData = new FormData();
    formData.append('task_id', this.taskId);
    formData.append('attach', file);
    formData.append('attach_name', this.fileNameTarget.value);
    formData.append('user_id', currentUser.id);

    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/comments/save_attach"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.close()
          var controller = document.querySelector(`#collapseComments-${this.taskId}`).usersTasksBoardComment
          const html = controller.commentPartial(response.data.cln)
          controller.listTarget.insertAdjacentHTML("beforeend", html)
          controller.inputTarget.value = ""
          controller.characterCountTarget.textContent = ""
          controller.rollMessages()
          tooltip()
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  open(field) {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.doModalBody(field)
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  modalHtml(field, task_id) {
    var html = `<div class="modal fade" data-controller="${this.controllerName}" data-${this.controllerName}-target="modal" data-${this.controllerName}-task-id="${task_id}" id="taskModal" tabindex="-1" role="dialog" aria-labelledby="taskModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header border-bottom">
                    <h6 class="modal-title"><strong data-${this.controllerName}-target="title"></strong></h6><br>
                    <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body p-3" data-${this.controllerName}-target="body">
                   teste
                  </div>
                  <div class="modal-footer border-top">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>`
                    if (field == "attach") {
              html += `<button type="button" class="btn btn-primary" data-action="click->${this.controllerName}#saveAttach" data-${this.controllerName}-target="saveBtn">Salvar</button>`
                    } else {
              html += `<button type="button" class="btn btn-primary" data-action="click->${this.controllerName}#saveField" data-${this.controllerName}-target="saveBtn">Salvar</button>`
                    }
          html += `</div>
                </div>
              </div>
            </div>`
    new Promise(function (resolve) {
      resolve($('body').append(html))
    }).then(() => {
      document.querySelector('#taskModal').usersTasksBoardModal.open(field)
    })
  }

  doLabelModal() {
    this.modalHtml("label")
  }

  doTargetModal() {
    this.modalHtml("target")
  }

  doDueDateModal() {
    this.modalHtml("dueDate")
  }

  doPerformerModal() {
    this.modalHtml("performer")
  }

  doAttachModal() {
    this.modalHtml("attach")
  }

  doModalBody(field) {
    if (field == "label") {
      this.doLabelField()
    } else if (field == "target") {
      this.doTargetField()
    } else if (field == "dueDate") {
      this.doDueDateField()
    } else if (field == "performer") {
      this.doPerformerField()
    } else if (field == "attach") {
      this.doAttachField()
    }
  }

  doLabelField() {
    this.titleTarget.innerText = "Label"
    html = ``
  }

  doTargetField() {
    this.titleTarget.innerText = "Target"
    html = ``
  }

  doDueDateField() {
    this.titleTarget.innerText = "Prazo de Entrega"
    html = ``
  }

  doPerformerField() {
    this.titleTarget.innerText = "Executante"
    html = ``
  }

  doAttachField() {
    this.titleTarget.innerText = "Anexar Documento"
    const html = `<div class="row my-3">
                    <div class="col-12 text-center">
                      <div class="form-group form-valid-group my-0" is-valid="false">
                        <input data-direct-upload-url="http://localhost:3000/rails/active_storage/direct_uploads" type="file" id="attendeeAvatarForm" name="attendeeAvatarForm" class="file-input" multiple data-action="change->${this.controllerName}#changeInput">
                        <label for="attendeeAvatarForm" id="attendeeAvatarLabelForm" class="btn btn-primary">Selecione uma Imagem</label>
                        <h4 class="progress" style="display: none">Progress: <span class="progress_count"></span>%</h4>
                      </div>
                      </div>
                    <div class="col-12 text-center">
                      <span class="fileNameForm" class="mx-2"></span>
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="col-6 offset-sm-3 text-center">
                      <div class="form-group board-task-filter">
                        <div class="floating-label floating-label-sm">
                          <label for="commentFileName">Nome Arquivo</label>
                          <input class="form-control" id="commentFileName" placeholder="Nome Arquivo" type="text" required data-${this.controllerName}-target="fileName">
                        </div>
                      </div>
                    </div>
                  </div>`

    this.bodyTarget.innerHTML = html
  }

  get taskId() {
    return parseInt(this.data.get("taskId"))
  }

}