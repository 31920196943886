import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "printCardElement", "indexCard", "showCard"]

  connect() {
    this.controllerName = `users--works--permissions--entities--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.permissions = {}
    this.application.features = []
    this.application.subdomains = []
    this.application.policies = []
    this.application.paths = []
    
    this.doFeatureGrid()
  }

  doFeatureGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--permissions--features--dashboard
                                                            users--works--permissions--policies--dashboard
                                                            users--works--permissions--policies--submenu
                                                            users--works--permissions--subdomains--dashboard
                                                            users--works--permissions--paths--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  doDashboardPreloader() {
    var html = `<div class="row">
                  <div class="col-6" data-${this.controllerName}-target="infoView">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="indexCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" data-${this.controllerName}-target="partnerView">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="showCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.indexCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.showCardTarget.style.height = ($(window).height() * 0.5) + "px"
    })

    this.contentTarget.innerHTML = html
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
    this.getControllerByIdentifier(`users--works--permissions--entities--submenu`).doSubmenuHtml()
    // this.getControllerByIdentifier(`users--works--permissions--features--dashboard`).doFeaturesDashboard()
    this.getControllerByIdentifier("users--works--permissions--policies--dashboard").doPoliciesDashboard()
  }

  getSubdomains() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Domínios`, value: 90 })

    var data = { subdomain: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions_subdomains` } }
    const url = "/users/permissions/subdomains/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.subdomains = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPaths() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando URLs`, value: 90 })

    var data = { path: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions_paths` } }
    const url = "/users/permissions/paths/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.paths = response.data.cln
        }

        controller.getSubdomains()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPolicies() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Políticas`, value: 60 })

    var data = { policy: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions_policies` } }
    const url = "/users/permissions/policies/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.policies = response.data.cln
        }

        controller.getPaths()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getFeatures() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Funcionalidades`, value: 40 })

    var data = { feature: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions_features` } }
    const url = "/users/permissions/features/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.features = response.data.cln
        }

        controller.getPolicies()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions2() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `features_and_permissions` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`user_permissions`]

        controller.getFeatures()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const featureNames = ["user_permissions_features", "user_permissions", "user_permissions_policies", "user_permissions_subdomains", "user_permissions_paths"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`user_permissions`]

        controller.getFeatures()
        
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
