import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "masterDropdown", "masterDropdownBtn", "masterInput", "masterList",
                    "groupDropdown", "groupDropdownBtn", "groupInput", "groupList",
                    "numberInput", "humanizeInput", "nameInput", "notesInput", "saveBtn", "accountKindCheckbox"]


  connect() {
    this.controllerName = `users--works--permissions--subdomains--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("users--works--permissions--subdomains--index").clearList()
  }

  saveFeature() {
    this.send_data = { current_user: {}, subdomain: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    var accountKinds = []

    if (this.actionMode == `edit`) {
      this.send_data.subdomain.id = this.current_subdomain.id
    }
    this.send_data.subdomain.humanize = this.humanizeInputTarget.value
    this.send_data.subdomain.name = this.nameInputTarget.value
    this.send_data.subdomain.notes = this.notesInputTarget.value
    
    this.accountKindCheckboxTargets.forEach(element => {
      if (element.checked) {
        accountKinds[accountKinds.length] = element.dataset.kind
      }
    })
    this.send_data.subdomain.account_kind = accountKinds

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_permissions_subdomains`

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    
    if (this.actionMode == `new`) {
      var url = "/users/permissions/subdomains/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/permissions/subdomains/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/permissions/subdomains/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var subdomain = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.subdomains[controller.application.subdomains.length] = subdomain
          } else if (this.actionMode == `edit`) {
            controller.application.subdomains.forEach((element, i) => {
              if (element.id == subdomain.id) {
                controller.application.subdomains.splice(controller.application.subdomains.indexOf(element), 1, subdomain)
              }
            })
          }

          controller.cancelSave()
          controller.getControllerByIdentifier("users--works--permissions--subdomains--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveFeature" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Enum</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Enum" type="text" required>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="humanizeForm">Nome</label>
                            <input aria-describedby="humanizeFormHelp" class="form-control form-valid-control" id="humanizeForm" data-${this.controllerName}-target="humanizeInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notesForm">Anotações</label>
                            <input aria-describedby="notesFormHelp" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-admin" data-${this.controllerName}-target="accountKindCheckbox" data-kind="admin">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-admin">Admin</label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-team" data-${this.controllerName}-target="accountKindCheckbox" data-kind="team">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-team">Time</label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-doctor" data-${this.controllerName}-target="accountKindCheckbox" data-kind="doctor">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-doctor">Médico</label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-helper" data-${this.controllerName}-target="accountKindCheckbox" data-kind="helper">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-helper">Assistente</label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-taker" data-${this.controllerName}-target="accountKindCheckbox" data-kind="taker">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-taker">Tomador</label>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="accountKindCheck-patient" data-${this.controllerName}-target="accountKindCheckbox" data-kind="patient">
                          <label class="custom-control-label f-065 pointer" for="accountKindCheck-patient">Paciente</label>
                        </div>
                      </div>
                    </div>

                    
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--subdomains--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Domínio`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Domínio`

        controller.humanizeInputTarget.value = controller.current_subdomain.humanize
        controller.nameInputTarget.value = controller.current_subdomain.name
        controller.notesInputTarget.value = controller.current_subdomain.notes

        controller.humanizeInputTarget.disabled = false
        controller.notesInputTarget.disabled = false

        controller.accountKindCheckboxTargets.forEach(element => {
          var kind = element.dataset.kind
          if (controller.current_subdomain.account_kind.includes(kind)) {
            element.checked = true
          }
        });

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.humanizeInputTarget.value == ``) {
        len += 1
      }

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
