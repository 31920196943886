import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "submessage", "duration", "progressUploadBar", "progressUploadCounter"]

  connect() {
    this.modalController = `app--helpers--audio-modal`
    this.controllerName = `app--helpers--pre-audio-modal`
  }

  openModal(options = { message: `Áudio`, submessage: `` }) {

    if (options.message == undefined) {
      options.message = `Áudio`
    }
    if (options.submessage == undefined) {
      options.submessage = ``
    }
    if (options.value == undefined) {
      options.value = 0
    }

    this.doProcessModalHtml(options)
  }

  doProcessModalHtml(options) {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="${this.modalController}" data-${this.modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${this.modalController}-target="body">
                        <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.modalController}-target="mainCard">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4 card-title-table-list" data-${this.modalController}-target="titleCard">${options.message}</h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.modalController}#close" type="button">
                                <span class="material-icons md-dark">clear</span>
                              </button>
                              <span class="mc-tooltiptext">Fechar</span>
                            </div>
                          </div>
                          <div class="card-body py-3" style="overflow:auto;" data-${this.modalController}-target="bodyCard"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`



    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      controller.getControllerByIdentifier(controller.modalController).current_audio = controller.current_audio
      controller.getControllerByIdentifier(controller.modalController).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}