import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "footerTable", "input", "characterCount", "saveBtn", "textarea", "messagesBody", "messagesCardBody",
                    "pinMessageBtn", "starMessageBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--chat--room`
    this.count = 0
    this.requestPermission = true
    this.doIndexListHtml()
  }

  saveComment(ev) {
    this.send_data = { current_user: {}, message: {} }
    this.actionMode = `new`

    if (this.count > 250) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && this.count > 0) {

        if (this.count > 70) {
          var content = this.textareaTarget.value
        } else {
          var content = this.inputTarget.value
        }

        this.send_data.message.room_id = this.application.chat_room.id
        this.send_data.message.content = content
        this.send_data.message.sender_id = this.application.current_user.id
        this.send_data.message.sender_type = `user`

        this.send_data.current_user.current_user_id = this.application.current_user.id
        
        this.cleanTextBox()
        if (this.requestPermission) {  
          this.requestSave()
        }
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        this.inputTarget.value = ""
        this.textareaTarget.value = ""
      }
    } 
  }

  requestSave() {
    var url = "/operations/messages/entities/create"
    var method = "POST"
    this.request(false)
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          
          // var message = response.data.cln
          // if (controller.actionMode == `new`) {
          //   controller.application.messages[controller.application.messages.length] = message
          // } else {
          //   if (message.active) {
          //     controller.application.messages.forEach((element, i) => {
          //       if (element.id == message.id) {
          //         controller.application.messages.splice(controller.application.messages.indexOf(element), 1, message)
          //       }
          //     })
          //   } else {
          //     controller.application.messages.forEach((element, i) => {
          //       if (element.id == message.id) {
          //         controller.application.messages.splice(controller.application.messages.indexOf(element), 1)
          //       }
          //     })
          //   }
          // }
          // controller.doMessageTable()
        }
        this.request(true)
        // controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Chat</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="messagesCardBody">
                    <div class="row my-3">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-chat-sm table-xs table-borderless" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <tbody data-${this.controllerName}-target="messagesBody"></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                    <div class="row my-1 d-flex align-items-center">
                      <div class="col-10 px-1 text-left">
                        <textarea autofocus type="text" class="form-control f-065 p-1 d-none" placeholder="Digite ..." aria-label="Digite ..." data-${this.controllerName}-target="textarea" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount"></textarea>
                        <input type="text" class="form-control f-065 p-1 " placeholder="Digite ..." aria-label="Digite ..." data-${this.controllerName}-target="input" data-action="keyup->${this.controllerName}#saveComment keyup->${this.controllerName}#characterCount">
                        <span class="f-065" data-${this.controllerName}-target="characterCount">0 caracteres</span>
                      </div>
                      <div class="col-2 px-1 text-center">
                        <span class="mc-tooltip" data-action="click->${this.controllerName}#saveComment" data-${this.controllerName}-target="saveBtn">
                          <span class="material-icons md-sm md-primary pointer">send</span>
                          <span class="mc-tooltiptext">Enviar</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").roomTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.doMessageTable()
    })
  }

  request(permission) {
    this.requestPermission = permission
    if (permission) {
      this.saveBtnTarget.classList.add("md-primary")
      this.saveBtnTarget.classList.remove("md-dark")
    } else {
      this.saveBtnTarget.classList.remove("md-primary")
      this.saveBtnTarget.classList.add("md-dark")
    }
  }

  doMessageTable() {
    var messages = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.messages, "created_at")

    this.listMessages(messages)
  }

  listMessages(data) {
    this.messagesBodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="10" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Mensagens</span>
                      </td>
                    </tr>`

      this.messagesBodyTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.messagesBodyTarget.insertAdjacentHTML("beforeend", this.messageTablePartial(element))
      });
    }
    this.rollMessages()
  }

  messageTablePartial(element) {

    var hours = new Date(element.created_at).getHours()
    var minutes = new Date(element.created_at).getMinutes()
    if (hours < 10) { hours = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }

    if (this.application.permissions.medfiling_messages.can_update) {
      var pin = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#pinMessage" data-${this.controllerName}-target="pinMessageBtn-${element.id}">
                  <span class="material-icons md-sm md-dark pointer">push_pin</span>
                  <span class="mc-tooltiptext">Fixar Mensagem</span>
                </span>`
      if (element.starred) {
        var star = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#starMessage" data-${this.controllerName}-target="starMessageBtn-${element.id}">
                      <span class="material-icons md-sm md-dark pointer">star</span>
                      <span class="mc-tooltiptext">Favoritar Mensagem</span>
                    </span>`
      } else {
        var star = `<span class="mc-tooltip d-none" data-action="click->${this.controllerName}#unstarMessage" data-${this.controllerName}-target="starMessageBtn-${element.id}">
                      <span class="material-icons md-sm md-dark pointer">star_border</span>
                      <span class="mc-tooltiptext">Remover Favorito</span>
                    </span>`
      }
    } else {
      var star = ``
      var pin = ``
    }

    var html = `<tr>
                  <td class="p-0 table-20 text-left align-middle f-065" style="font-size:50%;">${hours}:${minutes}</td>
                  <td class="p-0 table-60 text-left align-middle f-065" style="font-size:50%;">[${element.sender_name}]</td>
                  <td class="p-0 table-10 text-center align-middle" style="font-size:50%;"></td>
                  <td class="p-0 table-10 text-center align-middle" style="font-size:50%;"></td>
                </tr>
                <tr class="chat-line" data-id="${element.id}" data-action="mouseover->${this.controllerName}#showAction mouseout->${this.controllerName}#showAction">
                  <td class="p-0 border-left-primary table-3"></td>
                  <td class="p-1 f-065 pointer align-middle" data-action="click->${this.controllerName}#selectLine">${element.content}</td>
                  <td class="p-0 text-center">${pin}</td>
                  <td class="p-0 text-center">${star}</td>
                </tr>
                <tr>
                  <td colspan="4" class="p-0"></td>
                </tr>`

    return html
  }

  selectLine() {

  }

  pinMessage(ev) {
    var id = ev.currentTarget.dataset.id
  } 

  starMessage(ev) {
    var id = ev.currentTarget.dataset.id
  }

  unstarMessage(ev) {

    var id = ev.currentTarget.dataset.id
  }


  showAction(ev) {
    var id = ev.currentTarget.dataset.id
    if (this.application.permissions.medfiling_messages.can_update) {
      if (ev.type == `mouseover`) {
        this.nameTarget(`pinMessageBtn-${id}`).classList.remove("d-none")
        this.nameTarget(`starMessageBtn-${id}`).classList.remove("d-none")
      } else if (ev.type == `mouseout`) {
        this.nameTarget(`pinMessageBtn-${id}`).classList.add("d-none")
        this.nameTarget(`starMessageBtn-${id}`).classList.add("d-none")
      }
    }
  }

  cleanTextBox() {
    this.count = 0
    this.inputTarget.value = ``
    this.textareaTarget.value = ``
    this.textareaTarget.classList.add("d-none")
    this.inputTarget.classList.remove("d-none")
    this.inputTarget.focus()
    this.characterCountTarget.textContent = `0 caracteres`
  }

  characterCount(ev) {
    this.count = ev.target.value.length

    if (this.count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${this.count} caracteres`
    }

    this.characterCountTarget.textContent = message

    if (this.count > 250) {
      this.characterCountTarget.classList.add("text-danger")
    } else {
      this.characterCountTarget.classList.remove("text-danger")
    }

    this.inputTarget.value = ev.target.value
    this.textareaTarget.value = ev.target.value
    if (this.count > 70) {
      this.inputTarget.classList.add("d-none")
      this.textareaTarget.classList.remove("d-none")
      this.textareaTarget.focus()
    } else {
      this.textareaTarget.classList.add("d-none")
      this.inputTarget.classList.remove("d-none")
      this.inputTarget.focus()
    }
  }

  rollMessages() {
    $(this.messagesCardBodyTarget).stop().animate({ scrollTop: $(this.messagesCardBodyTarget)[0].scrollHeight }, 1000);
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
