import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewScheduleCard", "viewTitle", "viewScheduleCardBody", "previewCard", "editScheduleBtn", "deleteScheduleBtn", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--leads--meetings--show`
    this.send_data = { current_user: {}, schedule: {} }
    this.doViewHtml()
  }

  doViewHtml() {
    if (this.getControllerByIdentifier("commercial--sales--leads--meetings--save")) {
      this.getControllerByIdentifier("commercial--sales--leads--meetings--save").stopRefreshing()
    }
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewScheduleCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Agendamento Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewScheduleCardBody"></div>
                </div>`

    this.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").viewTarget.innerHTML = html
  }

  editSchedule() {
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").current_schedule = this.current_schedule
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").actionMode = "edit"
    this.getControllerByIdentifier("commercial--sales--leads--meetings--save").doFormHtml()
  }

  deleteSchedule() {
    var r = confirm("Tem certeza que deseja apagar o Agendamento?")
    this.deleteScheduleBtnTarget.disabled = true

    if (r) {
      this.actionMode = "delete"

      this.send_data.schedule.id = this.current_schedule.id
      this.send_data.schedule.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      // this.send_data.current_user.feature = this.permission
      this.send_data.current_user.feature = `schedules`

      this.requestDestroy()
    } else {
      this.deleteScheduleBtnTarget.disabled = false
    }

  }

  requestDestroy() {

    var url = "/users/works/schedules/entities/destroy"
    var method = "DELETE"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var schedule = response.data.cln
          if (schedule.active) {
            controller.application.schedules.forEach((element, i) => {
              if (element.id == schedule.id) {
                controller.application.schedules.splice(controller.application.schedules.indexOf(element), 1, schedule)
              }
            })
          } else {
            controller.application.schedules.forEach((element, i) => {
              if (element.id == schedule.id) {
                controller.application.schedules.splice(controller.application.schedules.indexOf(element), 1)
              }
            })
          }
          controller.getControllerByIdentifier("commercial--sales--leads--meetings--index").doDataTable()
          controller.doViewHtml()
          controller.send_data = { current_user: {}, schedule: {} }
        } else {
          controller.deleteScheduleBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }

  doScheduleHtml() {
    this.getControllerByIdentifier(`commercial--sales--leads--meetings--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.schedules.can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteScheduleBtn" data-action="click->${this.controllerName}#deleteSchedule" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Agendamento</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.schedules.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editScheduleBtn" data-action="click->${this.controllerName}#editSchedule" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Agendamento</span>`
    } else {
      var editBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Agendamento</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Lead:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_schedule.guest_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>MedCapital:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_schedule.account_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Dia:</strong><br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_schedule.date_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Semana:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_schedule.date_week}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Horário:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_schedule.timetable}
                              </td>
                            </tr>

                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Oportunidade</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left pointer" data-action="click->${this.controllerName}#showOpportunity" data-token="${this.current_schedule.opportunity_token}">
                                Ver Oportunidade
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  showOpportunity(ev) {
    var token = ev.currentTarget.dataset.token

    if (token != `null` && token != `undefined`) {
      var url = `/a/oportunidades/${token}`
      window.open(url, `_blank`)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `Sem Oportunidade declarada.`, 3000)
    }
  }

  sendMessage() {
    var url = `https://api.whatsapp.com/send?phone=5531995181912&amp;text=Olá,%20quero%20agendar%20uma%20consultoria`
    window.open(url, '_blank')
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
