import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cnpjInput", "nameInput", "tradeNameInput", "openedAt",
                    "nextBtn", "errorMessage", "saveCompanyBtn", "municipalInput", "saveCompanyBtnTooltip"]

  connect() {
    this.company = {}
    this.controllerName = `operations--products--clinics--regularizations--add--company`
    this.search = true
    this.checkDateSaveBtn = false
    // this.doCompanyFormHtml()
  }

  saveCompany() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopCompanyRefreshing()
    this.saveCompanyBtnTarget.disabled = true
    this.send_data = { current_user: {}, company: {}, kind: {} }

    if (this.actionMode == "edit") {
      this.send_data.company.id = this.company.id
    }
    this.send_data.company.name = this.nameInputTarget.value.trim()
    this.send_data.company.trade_name = this.tradeNameInputTarget.value.trim()
    this.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value.trim())
    this.send_data.company.municipal_number = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.municipalInputTarget.value.trim())
    this.send_data.company.opened_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.openedAtTarget.value)
    this.send_data.kind.kind = `clinic`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_regularizations`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/users/companies/entities/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/companies/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var company = response.data.cln
          controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").company = company
          controller.saveCompanyBtnTarget.innerText = `Confirmado`
          controller.saveCompanyBtnTarget.classList.remove("help")
          controller.saveCompanyBtnTarget.classList.remove("mc-tooltip")
          controller.saveCompanyBtnTarget.disabled = true
          controller.companyConfirmed = true
        } else {
          controller.saveCompanyBtnTarget.innerText = `Salvar`
          controller.saveCompanyBtnTarget.disabled = false
          controller.companyConfirmed = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    var r = confirm(`Tem certeza que deseja encerrar?`)
    if (r) {
      this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").doMainGrid()
    }
  }

  nextStep() {
    this.nextBtnTarget.disabled = true
    this.stopRefreshing()

    var controller = this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard")

    if (this.company.id) {
      controller.product_data.company.id = this.company.id
    }
    controller.product_data.company.name = this.nameInputTarget.value.trim()
    controller.product_data.company.trade_name = this.tradeNameInputTarget.value.trim()
    controller.product_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value.trim())
    controller.product_data.company.cnpj_pretty = this.cnpjInputTarget.value.trim()
    controller.product_data.company.kind = `clinic`
    controller.product_data.company.opened_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.openedAtTarget.value)
    controller.product_data.company.opened_at_pretty = this.openedAtTarget.value.trim()

    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--contacts").doContactFormHtml()
    controller.changeStep(`contacts`)
  }

  doCompanyFormHtml() {
    this.stopRefreshing()

    var html = `<div class="col-6 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando ou Editando PJ Médica</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="cnpjForm">Número CNPJ</label>
                              <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="Número CNPJ" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="tradeNameForm">Razão Social</label>
                              <input aria-describedby="tradeNameFormHelp" class="form-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="nameForm">Nome Fantasia</label>
                              <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Fantasia" type="text" required>
                            </div>
                          </div>
                        </div>

                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="municipalForm">Inscrição Municipal</label>
                              <input aria-describedby="municipalFormHelp" class="form-control" id="municipalForm" data-${this.controllerName}-target="municipalInput" placeholder="Inscrição Municipal" type="text" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="openedAtForm">Data de Abertura</label>
                              <input aria-describedby="openedAtFormHelp" class="form-control" id="openedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="openedAt" placeholder="DD/MM/AAAA" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-2 mb-4">
                        <div class="col-12 px-1 text-right">
                          <button type="button" class="btn btn-primary btn-sm py-2 f-065 d-none mc-tooltip" data-action="click->${this.controllerName}#saveCompany" data-${this.controllerName}-target="saveCompanyBtn"></button>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->operations--products--clinics--regularizations--add--dashboard#changeStep click->${this.controllerName}#nextStep" data-next="1" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.nameInputTarget.disabled = true
      controller.tradeNameInputTarget.disabled = true
      controller.municipalInputTarget.disabled = true
      controller.openedAtTarget.disabled = true

      var product_data = controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").product_data

      if (product_data) {
        if (product_data.company.name != undefined) { controller.nameInputTarget.value = product_data.company.name }
        if (product_data.company.tradeName != undefined) { controller.tradeNameInputTarget.value = product_data.company.tradeName }
        if (product_data.company.cnpj != undefined) { controller.cnpjInputTarget.value = product_data.company.cnpj_pretty }
        if (product_data.company.municipal_number != undefined) { controller.municipalInputTarget.value = product_data.company.municipal_number }
        if (product_data.company.opened_at != undefined) { controller.openedAtTarget.value = product_data.company.opened_at_numbers_pretty }
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      this.checkDateSaveBtn = this.checkDate.valid
      if (this.checkDate.valid) {
        this.openedAtTarget.classList.add("f-normal")
        this.openedAtTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.openedAtTarget.classList.add("f-danger")
        this.openedAtTarget.classList.remove("f-normal")
      }
    } else {
      this.openedAtTarget.classList.add("f-normal")
      this.openedAtTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.setErrorMessage(this.cnpj_result.message)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 14) {
      this.nameInputTarget.value = ""
      this.tradeNameInputTarget.value = ""
      this.openedAtTarget.value = ""

      this.nameInputTarget.disabled = true
      this.tradeNameInputTarget.disabled = true
      this.openedAtTarget.disabled = true


      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "") {
    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14 && this.search) {
      this.search = false
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      // this.cnpj_result = {}
      // this.cnpj_result.invalid = false
      var data = { company: { cnpj: cleanCnpj, kind: "" }, current_user: { current_user_id: this.application.current_user.id, feature: "medclinic_regularizations" } }
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      if (this.cnpj_result.invalid == false) {
        var hasProduct = this.checkIfHasProduct(cleanCnpj)
        if (hasProduct) {
          this.setErrorMessage("O PJ Médica já está cadastrada como cliente!")
        } else {
          this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              this.search = true
              if (response.process) {
                controller.company = response.data.cln

                if (controller.company.id) {
                  controller.nameInputTarget.value = controller.company.name
                  controller.tradeNameInputTarget.value = controller.company.trade_name
                  controller.municipalInputTarget.value = controller.company.municipal_number
                  if (controller.company.opened_at_numbers_pretty) {
                    controller.openedAtTarget.value = controller.company.opened_at_numbers_pretty
                  } else {
                    controller.openedAtTarget.value = ``
                  }

                  controller.nameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalInputTarget.disabled = false
                  controller.openedAtTarget.disabled = false

                  controller.formTitleTarget.innerText = `Editando PJ Médica`
                  controller.saveCompanyBtnTarget.classList.remove("d-none")
                  controller.saveCompanyBtnTarget.classList.add("help")
                  controller.saveCompanyBtnTarget.classList.add("mc-tooltip")
                  controller.saveCompanyBtnTarget.innerHTML = `Confirmar Data de Abertura <span class="mc-tooltiptext" data-${this.controllerName}-target="saveCompanyBtnTooltip"></span>`
                  controller.actionMode = `edit`
                } else {
                  controller.formTitleTarget.innerText = `Criando PJ Médica`
                  controller.tradeNameInputTarget.value = "Favor adicionar a Razão Social da PJ Médica"
                  controller.nameInputTarget.value = "Favor adicionar o Nome Fantasia"
                  controller.municipalInputTarget.value = "Favor adicionar a Inscrição Municipal"
                  controller.openedAtTarget.value = ""

                  controller.nameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  controller.municipalInputTarget.disabled = false
                  controller.openedAtTarget.disabled = false

                  controller.saveCompanyBtnTarget.classList.remove("d-none")
                  controller.saveCompanyBtnTarget.classList.add("help")
                  controller.saveCompanyBtnTarget.classList.add("mc-tooltip")
                  controller.saveCompanyBtnTarget.innerHTML = `Salvar <span class="mc-tooltiptext" data-${this.controllerName}-target="saveCompanyBtnTooltip"></span>`
                  controller.actionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              }
              
              controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
            })
            .catch(error => {
              controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
            })
        }

      }

    }
  }

  checkIfHasProduct(cnpj) {
    var diffCompany = true
    var hasProduct = false
    this.application.clinics.forEach(element => {
      if (element.company_cnpj == cnpj) {
        this.nameInputTarget.value = element.company_name
        this.tradeNameInputTarget.value = element.company_trade_name
        this.municipalInputTarget.value = element.company_municipal_number
        this.openedAtTarget.value = element.company_opened_at_numbers_pretty
        this.nextBtnTarget.disabled = true
        this.setErrorMessage("A PJ Médica já possui o Produto")
        this.nextBtnTarget.classList.add("d-none")
        diffCompany = false
        hasProduct = true
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      } else {
        if (diffCompany) {
          this.nextBtnTarget.disabled = false
          this.nextBtnTarget.classList.remove("d-none")
        }
      }
    });

    return hasProduct
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshCompanyTimer = setInterval(function () {
      var len = 0
      var message = ``

      if (controller.cnpj_result && controller.cnpj_result.invalid) {
        len += 1
      }

      if (controller.hasCnpjInputTarget && controller.cnpjInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasNameInputTarget && (controller.nameInputTarget.value == `` || controller.nameInputTarget.value == `Favor adicionar o Nome Fantasia`)) {
        len += 1
      }

      if (controller.hasTradeNameInputTarget && (controller.tradeNameInputTarget.value == `` || controller.tradeNameInputTarget.value == `Favor adicionar a Razão Social da PJ Médica`)) {
        len += 1
      }

      if (controller.hasOpenedAtTarget && (controller.checkDateInput())) {
        message += `| Data de Abertura inválida`
        len += 1
      }

      if (len == 0) {
        controller.saveCompanyBtnTarget.disabled = false
        controller.saveCompanyBtnTarget.classList.remove(`help`)
        controller.saveCompanyBtnTarget.classList.remove(`pointer`)
        controller.saveCompanyBtnTarget.classList.remove(`mc-tooltip`)
        if (controller.hasSaveCompanyBtnTooltipTarget) {
          controller.saveCompanyBtnTooltipTarget.innerText = ``
        }
      } else {
        controller.saveCompanyBtnTarget.disabled = true
        if (controller.hasSaveCompanyBtnTooltipTarget) {
          controller.saveCompanyBtnTooltipTarget.innerText = message
        }
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.companyConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  checkDateInput() {
    if (this.openedAtTarget.value == ``) {
      return true
    } else if (this.openedAtTarget.value == `Não Definida`) {
      return true
    } else if (!this.openedAtTarget.value.includes(`/`)) {
      return true
    } else if (this.checkDateSaveBtn) {
      return false
    } else {
      return true
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopCompanyRefreshing() {
    if (this.refreshCompanyTimer) {
      clearInterval(this.refreshCompanyTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
