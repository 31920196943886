import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "issuedOnInput",
                    "amountInput", "netAmountInput", "amountCheck", "pixInput", "saveBtn",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList",
                    "withheldInput", "socialInput", "grossInput", "otherDeductionsInput",
                    "progressUpload", "progressUploadBar", "progressUploadCounter", "progressUploadDiv",
                    "receiptDiv", "receiptLabelUpload", "receiptName", "editReceiptDiv"]


  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `operations--products--clinics--financials--transfers--save`
    
    this.upload_files = []
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadReady = false
    this.uploadFile = false

    if (this.getControllerByIdentifier("operations--products--clinics--financials--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--dashboard`)
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--transfers--dashboard`)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.showAllPartners()
    this.getControllerByIdentifier("operations--products--clinics--financials--transfers--index").clearList()
  }

  saveTransfer() {
    this.send_data = { current_user: {}, transfer: {}, tracker: { log: false } }
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.transfer.id = this.current_transfer.id
    }
    this.send_data.transfer.clinic_id = this.application.clinic.id
    this.send_data.transfer.date_id = this.application.current_date.id
    this.send_data.transfer.date_token = this.application.current_date.date_token
    this.send_data.transfer.partner_id = this.nameInputTarget.dataset.partnerId
    this.send_data.transfer.partner_name = this.nameInputTarget.innerText
    this.send_data.transfer.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.transfer.channel_name = this.channelInputTarget.innerText
    this.send_data.transfer.status = this.statusInputTarget.dataset.status
    this.send_data.transfer.kind = this.kindInputTarget.dataset.kind
    this.send_data.transfer.kind_pretty = this.kindInputTarget.innerText
    this.send_data.transfer.amount = this.numberController.fromCurrencyToNumber(this.grossInputTarget.value)
    this.send_data.transfer.gross = this.numberController.fromCurrencyToNumber(this.grossInputTarget.value)
    this.send_data.transfer.withheld = this.numberController.fromCurrencyToNumber(this.withheldInputTarget.value)
    this.send_data.transfer.social = this.numberController.fromCurrencyToNumber(this.socialInputTarget.value)
    this.send_data.transfer.others_deductions = this.numberController.fromCurrencyToNumber(this.otherDeductionsInputTarget.value)
    this.send_data.transfer.net_amount = this.numberController.fromCurrencyToNumber(this.netAmountInputTarget.value)

    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = this.actionMode
    this.send_data.tracker.observations = `${this.kindInputTarget.innerText} da ${this.application.clinic.company_name} para ${this.nameInputTarget.innerText} no valor bruto de ${this.numberController.fromCurrencyToNumber(this.grossInputTarget.value)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == "new") {
      var url = "/operations/products/clinics/financials/transfers/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/operations/products/clinics/financials/transfers/update"
      var method = "PUT"
    } else if (this.actionMode == "delete") {
      var url = "/operations/products/clinics/financials/transfers/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var transfer = response.data.cln
          if (controller.uploadFile) {
            controller.requestUpload(transfer.id, controller.upload_files)
          } else {
            controller.stopRefreshing()
            controller.updateArray(transfer)
            controller.cancelSave()
            controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
          }
          
          controller.getControllerByIdentifier("operations--products--clinics--financials--transfers--index").doDataTable()
          controller.cancelSave()
        } else {
          controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestUpload(transfer_id, files) {
    var i = 1
    files.forEach(element => {
      var formData = new FormData()
      formData.append('file', element.file)
      formData.append('field', element.field)
      formData.append('transfer_id', transfer_id)
      formData.append('current_user_id', this.application.current_user.id)
      formData.append('feature', this.permission)

      formData.append('tracker_log', true)
      formData.append('tracker_action', `uploaded`)
      formData.append('tracker_record_id', this.application.clinic.id)
      formData.append('tracker_record_type', `operation_products`)
      formData.append('tracker_observations', `Upload ${element.field_pretty} referente a ${this.application.current_date.name} da PJ ${this.application.clinic.company_name}, sócio ${this.nameInputTarget.innerText} no valor total de ${this.grossInputTarget.value}`)

      var url = "/operations/products/clinics/financials/transfers/save_upload"
      var method = "PUT"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            var transfer = response.data.cln
            controller.updateArray(transfer)
            if (i == files.length) {
              controller.stopRefreshing()
              controller.progressCount(100)
              controller.getControllerByIdentifier("operations--products--clinics--financials--transfers--index").doDataTable()
            }
            controller.uploadSwitch(false)
            i += 1
          }
          controller.cancelSave()
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    })
  }

  updateArray(transfer) {

    if (this.actionMode == `new`) {
      this.application.transfers[this.application.transfers.length] = transfer
    } else if (this.actionMode == `edit`) {
      this.application.transfers.forEach((element, i) => {
        if (element.id == transfer.id) {
          this.application.transfers.splice(this.application.transfers.indexOf(element), 1, transfer)
        }
      })
    } else if (this.actionMode == `delete`) {
      this.application.transfers.forEach((element, i) => {
        if (element.id == transfer.id) {
          this.application.transfers.splice(this.application.transfers.indexOf(element), 1)
        }
      })
    }

  }

  doFormHtml() {
    this.stopRefreshing()
    var t = `<div class="col-4 d-flex align-items-center">
                <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex">
                  <input type="checkbox" class="custom-control-input" id="checkAmountForm" data-${this.controllerName}-target="amountCheck" data-action="click->${this.controllerName}#checkIsAmount">
                  <label class="custom-control-label pointer f-065 ml-3" for="checkAmountForm">Mesmo Valor</label>
                </div>
              </div>`

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTransfer" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete) {
      var deleteBtnHtml = `<button data-action="click->${this.controllerName}#deleteFile" data-${this.controllerName}-target="deleteBtn" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Documento</span>
                          </button>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.current_transfer.has_receipt) {
      var receipt = `<span class="f-085 text-bold mr-5">Comprovante ${this.current_transfer.kind_pretty}</span>
                      <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-kind="receipt" data-obj-id="${this.current_transfer.id}" data-obj-type="clinic_fin_tranfers" data-action="click->${this.controllerName}#goToFile">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ver Comprovante</span>
                      </button>
                      <button data-action="click->${this.controllerName}#editFile" data-${this.controllerName}-target="editBtn" data-field="receipt" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Upload de Novo Comprovante</span>
                      </button>
                      ${deleteBtnHtml}`
    } else {
      var receipt = `<span class="f-075 text-bold">Sem Comprovante</span>`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sócio</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="nameDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="nameDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="nameInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="nameList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="issuedOnForm">Competência</label>
                            <input aria-describedby="issuedOnFormHelp" class="form-control form-valid-control" id="issuedOnForm" data-${this.controllerName}-target="issuedOnInput" placeholder="Data Emissão" type="text" required>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                    <div class="row my-2">
                      
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Banco</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="labor" data-action="click->app--helpers--selector#select click->${this.controllerName}#setKind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pró-Labore</li>
                                  <li data-kind="distribution" data-action="click->app--helpers--selector#select click->${this.controllerName}#setKind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Distribuiçao Dividendos</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="grossForm">Valor Bruto</label>
                            <input aria-describedby="grossFormHelp" class="form-control form-valid-control" id="grossForm" data-${this.controllerName}-target="grossInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="R$ 0,00" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="withheldForm">Retido IRPF</label>
                            <input aria-describedby="withheldFormHelp" class="form-control form-valid-control" id="withheldForm" data-${this.controllerName}-target="withheldInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="R$ 0,00" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="socialForm">Retido INSS</label>
                            <input aria-describedby="socialFormHelp" class="form-control form-valid-control" id="socialForm" data-${this.controllerName}-target="socialInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="R$ 0,00" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="otherDeductionsForm">Outras Deduções</label>
                            <input aria-describedby="otherDeductionsFormHelp" class="form-control form-valid-control" id="otherDeductionsForm" data-${this.controllerName}-target="otherDeductionsInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask ${this.controllerName}#bindAmount keyup->${this.controllerName}#bindAmount keypress->${this.controllerName}#bindAmount" placeholder="R$ 0,00" type="text" required>
                          </div>
                        </div>
                      </div>


                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="netAmountForm">Valor Líquido</label>
                            <input aria-describedby="netAmountFormHelp" class="form-control form-valid-control" id="netAmountForm" data-${this.controllerName}-target="netAmountInput" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="R$ 0,00" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="paid" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pago</li>
                                  <li data-status="not_paid" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Pago</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center rowFile d-none" data-${this.controllerName}-target="editReceiptDiv" data-field="receipt" data-field-pretty="Comprovante de Pró-Labore">
                      <div class="col-6">
                        <span class="f-065 f-bold">Comprovante de Pró-Labore</span>
                        <label class="drop-area copy" for="receiptDropzoneArea" data-${this.controllerName}-target="receiptLabelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                          <div class="row my-2">
                            <div class="col p-0 d-flex align-items-center justify-content-center">
                              <span class="material-icons md-lg md-dark mx-3">receipt_long</span>
                            </div>
                          </div>
                        </label>
                        <input class="file-input mt-0" type="file" id="receiptDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                      </div>
                      <div class="col-6 px-0">
                        <span data-${this.controllerName}-target="receiptName"></span>
                      </div>
                    </div>

                    <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="receiptDiv">
                      <div class="col-12 my-3" data-field="receipt">
                        ${receipt}
                      </div>
                    </div>

                    <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="progressUploadDiv">
                      <div class="col-12">
                        <div class="form-group my-0 text-center w-100">
                          <h7 class="progress">
                            <span class="progress_count"></span>
                          </h7>
                          <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                          </div>
                          <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      var date = new Date(controller.application.current_date.year, controller.application.current_date.month, 0)
      controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.issuedOnInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

      controller.issuedOnInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").mountDate(controller.application.current_date.month, controller.application.current_date.year)
      controller.issuedOnInputTarget.disabled = true


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Repasse para ${controller.application.current_date.name}`

        controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = `not_paid`
        controller.statusInputTarget.innerText = `Não Pago`
        controller.statusInputTarget.dataset.status = `not_paid`

        // controller.amountInputTarget.value = `R$ 0,00`
        controller.grossInputTarget.value = `R$ 0,00`
        controller.withheldInputTarget.value = `R$ 0,00`
        controller.socialInputTarget.value = `R$ 0,00`
        controller.otherDeductionsInputTarget.value = `R$ 0,00`
        controller.netAmountInputTarget.value = `R$ 0,00`
        controller.withheldInputTarget.disabled = true
        controller.socialInputTarget.disabled = true
        controller.netAmountInputTarget.disabled = true
        // controller.amountCheckTarget.checked = true
        controller.bindAmount()

        if (controller.application.partners.length == 1) {
          var partner = controller.application.partners[0]
          controller.nameDropdownTarget.value = partner.name
          controller.nameInputTarget.innerText = partner.name
          controller.nameInputTarget.dataset.partnerName = partner.name
          controller.nameInputTarget.dataset.partnerId = partner.id
        }

        controller.setChannels()

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Repasse`

        controller.nameDropdownBtnTarget.disabled = true
        controller.nameDropdownTarget.value = controller.current_transfer.partner_name
        controller.nameInputTarget.innerText = controller.current_transfer.partner_name
        controller.nameInputTarget.dataset.partnerName = controller.current_transfer.partner_name

        controller.kindDropdownBtnTarget.disabled = true
        controller.kindDropdownTarget.value = controller.current_transfer.kind
        controller.kindInputTarget.innerText = controller.current_transfer.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_transfer.kind

        controller.channelDropdownTarget.value = controller.current_transfer.channel_id
        controller.channelInputTarget.innerText = `${controller.current_transfer.channel_name}`
        controller.channelInputTarget.dataset.channelId = controller.current_transfer.channel_id

        // controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.amount * 100)
        controller.grossInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.gross * 100)
        controller.withheldInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.withheld * 100)
        controller.socialInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.social * 100)
        controller.otherDeductionsInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.others_deductions * 100)
        controller.netAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(controller.current_transfer.net_amount * 100)

        // controller.statusDropdownBtnTarget.disabled = true
        controller.statusDropdownTarget.value = controller.current_transfer.status
        controller.statusInputTarget.innerText = controller.current_transfer.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_transfer.status

        if (controller.current_transfer.kind == `labor`) {
          controller.withheldInputTarget.disabled = false
          controller.socialInputTarget.disabled = false

          controller.editReceiptDivTarget.classList.remove(`d-none`)
          controller.receiptDivTarget.classList.remove(`d-none`)
        } else if (controller.current_transfer.kind == `distribution`) {
          controller.withheldInputTarget.disabled = true
          controller.socialInputTarget.disabled = true

          controller.editReceiptDivTarget.classList.add(`d-none`)
          controller.receiptDivTarget.classList.add(`d-none`)
        }

        if (controller.current_transfer.has_receipt) {
          controller.editReceiptDivTarget.classList.add(`d-none`)
        } else {
          controller.editReceiptDivTarget.classList.remove(`d-none`)
        }

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.listPartners()
      controller.refreshSaveBtn()
    })
  }

  listPartners() {
    var listHtml = ``

    this.application.partners.forEach(element => {
      if (element.operational) {
        listHtml += `<li data-app--helpers--selector-target="select" data-partner-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPartner" class="li-selector dark f-065">${element.name}</li>`
      }
    });

    this.nameListTarget.innerHTML = listHtml
  }

  setChannels() {
    var html = ``

    this.application.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectBank click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`
        
        if (channel.kind == `main` && this.actionMode == `new`) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }
      }
    })

    this.channelListTarget.innerHTML = html
  }

  setKind(ev) {

    var kind = ev.currentTarget.dataset.kind
    this.withheldInputTarget.value = `R$ 0,00`
    this.socialInputTarget.value = `R$ 0,00`

    if (kind == `labor`) {
      this.withheldInputTarget.disabled = false
      this.socialInputTarget.disabled = false

      this.editReceiptDivTarget.classList.remove(`d-none`)
      this.receiptDivTarget.classList.remove(`d-none`)
    } else if (kind == `distribution`) {
      this.withheldInputTarget.disabled = true
      this.socialInputTarget.disabled = true

      this.editReceiptDivTarget.classList.add(`d-none`)
      this.receiptDivTarget.classList.add(`d-none`)
    }

    this.bindAmount()

  }

  selectPartner(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    
    var partnersController = this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--partners`)

    partnersController.infoPartnerTargets.forEach(element => {
      element.classList.add(`d-none`)
    });

    partnersController.nameTarget(`infoPartner-${partnerId}`).classList.remove(`d-none`)
    partnersController.nameTarget(`infoHeaderPartner-${partnerId}`).classList.add(`bg-primary`)
  }

  showAllPartners() {
    var partnersController = this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--partners`)

    partnersController.infoPartnerTargets.forEach(element => {
      element.classList.remove(`d-none`)
    });

    partnersController.infoHeaderPartnerTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });
  }
  
  bindAmount() {
    // var check = this.amountCheckTarget.checked
    // if (check) {
    //   this.netAmountInputTarget.value = this.amountInputTarget.value
    //   this.netAmountInputTarget.disabled = true
    // } else {
    //   this.netAmountInputTarget.value = `R$ 0,00`
    //   this.netAmountInputTarget.disabled = false
    // }

    var amount = 0
    var gross = Number(this.numberController.fromCurrencyToNumber(this.grossInputTarget.value))
    var withheld = Number(this.numberController.fromCurrencyToNumber(this.withheldInputTarget.value))
    var social = Number(this.numberController.fromCurrencyToNumber(this.socialInputTarget.value))
    var otherDeductions = Number(this.numberController.fromCurrencyToNumber(this.otherDeductionsInputTarget.value))
    var netAmount = 0

    amount = gross
    netAmount = Number(gross) - Number(withheld) - Number(social) - Number(otherDeductions)

    this.netAmountInputTarget.value = this.numberController.currencyOptionMask(netAmount)
  }

  checkIsAmount(ev) {
    this.bindAmount()
  }

  percentMask(ev) {
    this.numberController.changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  editFile(ev) {
    var field = ev.currentTarget.dataset.field

    if (field == `receipt`) {
      this.editReceiptDivTarget.classList.remove(`d-none`)
      this.receiptDivTarget.classList.add(`d-none`)
    }
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    this.upload_files = []
    ev.preventDefault();

    if (ev.target.files.length > 0) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.field = ev.currentTarget.closest(".rowFile").dataset.field
        obj.field_pretty = ev.currentTarget.closest(".rowFile").dataset.fieldPretty
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
          obj.file = element
          this.uploadReady = true
          if (obj.field == `bill`) {
            this.progressUploadDivTarget.classList.remove("d-none")
            this.billNameTarget.innerText = obj.file_name
          } else if (obj.field == `receipt`) {
            this.progressUploadDivTarget.classList.remove("d-none")
            this.receiptNameTarget.innerText = obj.file_name
          }

          this.upload_files[this.upload_files.length] = obj
          this.uploadFile = true
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (this.hasUploadBtnTarget) {
      if (value) {
        this.uploadBtnTarget.classList.remove("bg-disabled")
        this.uploadBtnTarget.classList.add("pointer")
        this.uploadBtnTarget.classList.add("bg-primary")
        this.canUploadFile = true
      } else {
        this.uploadBtnTarget.classList.add("bg-disabled")
        this.uploadBtnTarget.classList.remove("pointer")
        this.uploadBtnTarget.classList.remove("bg-primary")
        this.canUploadFile = false
      }
    }
  }

  progressCount(value) {
    var controller = this
    if (this.hasProgressUploadBarTarget) {
      this.progressUploadBarTarget.style.width = value + `%`
      var i = 0
      if (value != 100) {
        this.progressTimer = setInterval(function () {
          controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
          controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
          i++
          if (i == 95) {
            i = 94
          }
        }, 500);
      } else {
        this.stopRefreshing()
        this.progressUploadCounterTarget.innerText = value + `%`
      }
    }
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = `medclinic_transfers`
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.hasGrossInputTarget && controller.grossInputTarget.value == `R$ 0,00`) {
        len += 1
      }

      // if (controller.hasWithheldInputTarget && controller.withheldInputTarget.value == `R$ 0,00`) {
      //   len += 1
      // }

      // if (controller.hasSocialInputTarget && controller.socialInputTarget.value == `R$ 0,00`) {
      //   len += 1
      // }

      if (controller.netAmountInputTarget.value == `R$ 0,00`) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
