import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--products--purchases--entities--notes`
    this.blockedAdd = false
  }

  doNotesDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-5 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--notes--index"></div>
                  <div class="col-7 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--notes--show users--works--notes--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`users--works--notes--index`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--index`).sharing = false
      controller.getControllerByIdentifier(`users--works--notes--index`).blockedAdd = this.blockedAdd
      controller.getControllerByIdentifier(`users--works--notes--show`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--show`).blockedAdd = this.blockedAdd
      controller.getControllerByIdentifier(`users--works--notes--save`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.purchase.id,
        domain_type: `products_purchases`,
        domain_name: `products_purchases`,
        date_id: this.application.purchase.id,
        date_type: `financials_dates`,
      }
      controller.getControllerByIdentifier(`users--works--notes--index`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.purchase.id,
        domain_type: `products_purchases`,
        domain_name: `products_purchases`,
        date_id: this.application.purchase.id,
        date_type: `financials_dates`,
      }

      controller.getControllerByIdentifier(`users--works--notes--index`).permission = `financial_purchase_notes`
      controller.getControllerByIdentifier(`users--works--notes--save`).permission = `financial_purchase_notes`
      controller.getControllerByIdentifier(`users--works--notes--show`).permission = `financial_purchase_notes`

      controller.getControllerByIdentifier(`users--works--notes--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--notes--show`).doNoteViewHtml()
    })
  }

  saveUpdateNote(message) {
    this.actionMode = `new`
    this.canRequest = false
    this.send_data = { current_user: {}, note: {}, notification: {} }

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.purchase.id
    this.send_data.note.domain_type = `products_purchases`
    this.send_data.note.domain_name = `products_purchases`,
    this.send_data.note.date_id = this.application.purchase.date_id
    this.send_data.note.date_type = `financials_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = message
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_notes`

    this.getControllerByIdentifier(`users--works--notes--save`).actionMode = `new`
    this.getControllerByIdentifier(`users--works--notes--save`).send_data = this.send_data
    this.getControllerByIdentifier(`users--works--notes--save`).requestSave()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
