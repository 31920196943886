import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "periodInput", "periodList", "content", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--show--main`
    this.application.taxFilingToken = location.pathname.split("/").pop()
    this.application.domain = {}
    this.application.permissions = {}
    this.application.tax_filing = {}
    this.application.tax_filing_calculations = []
    this.application.files = []
    this.application.dates = []
    this.application.agents = []
    this.application.tickets = []
    this.application.journeys = []
    this.application.notes = []
    this.application.summaries = []

    this.setAccountPermission()
    this.doTaxFilingGrid()
  }

  doTaxFilingGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="mobile--operations--products--tax-filings--show--dashboard
                                                            mobile--operations--products--tax-filings--files--list
                                                            mobile--operations--products--tax-filings--files--add
                                                            mobile--operations--products--tax-filings--management--journeys
                                                            mobile--operations--products--tax-filings--management--process
                                                            mobile--operations--products--tax-filings--management--tickets
                                                            mobile--operations--products--tax-filings--show--dates
                                                            mobile--operations--products--tax-filings--management--notes" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").preloaderDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    // if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
    //   this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.tax_filing.account_name
    // } else {
    //   setTimeout(() => {
    //     this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.tax_filing.account_name
    //   }, 500)
    // }
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dates").setCurrents()

    if (this.reload) {
      this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dates").runDashboardsHtml()
    } else {
      this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").setCurrentCalculation()
      // this.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").doJourneyDashboardHtml()
      // this.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").doTicketDashboardHtml()
      // this.getControllerByIdentifier("operations--products--tax-filings--show--submenu").doSubmenuHtml()
      // this.getControllerByIdentifier("operations--products--tax-filings--show--dates").doDatesHTML()
    }
    this.application.current_date.data_loaded = true


    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getFiles() {
    this.pageLoadMessage = `Carregando Arquivos IRPF ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 95 })

    var data = { file: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.files_permission } }
    const url = "/operations/products/tax_filings/files/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.files[controller.application.files.length] = element
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxFilingCalculations() {
    this.pageLoadMessage = `Carregando Indicadores IRPF ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 90 })

    var data = { calculation: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user_id, feature: this.application.date_permission } }
    const url = "/operations/products/tax_filings/calculations/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          // controller.application.tax_filing_calculations = response.data.cln
          response.data.cln.forEach(element => {
            controller.application.tax_filing_calculations[controller.application.tax_filing_calculations.length] = element
          })
        }

        controller.getFiles()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAgents() {
    this.pageLoadMessage = `Carregando Gestor de Conta IRPF ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 85 })

    var data = { agent: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.agent_permission } }
    const url = "/operations/products/tax_filings/agents/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          // controller.application.agents = response.data.cln
          response.data.cln.forEach(element => {
            controller.application.agents[controller.application.agents.length] = element
          })
        }

        controller.getTaxFilingCalculations()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getJourneys() {
    this.pageLoadMessage = `Carregando Jornadas IRPF ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 80 })

    var data = { journey: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.journey_permission } }
    const url = "/operations/products/tax_filings/journeys/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.journeys[controller.application.journeys.length] = element
          })
        }

        controller.getAgents()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSummaries() {
    this.pageLoadMessage = `Carregando Sumário IRPF ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: this.pageLoadMessage, value: 70 })

    var data = { summary: { tax_filing_id: this.application.tax_filing.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.summary_permission } }
    const url = "/operations/products/tax_filings/summaries/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.summaries[controller.application.summaries.length] = element
          })
        }

        controller.getJourneys()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.pageLoadMessage = `Carregando Informações da Competência ${this.application.current_date.name}`
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: this.pageLoadMessage })
      }
      this.getSummaries()
    } else {
      this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dates").setCurrents()
      this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dates").runDashboardsHtml()
    }
  }

  getOpportunities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Oportunidades`, value: 65 })

    var data = { opportunity: { obj_id: this.application.tax_filing.id, obj_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.product_permission } }
    const url = "/commercial/sales/opportunities/entities/list_by_obj"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.opportunities = response.data.cln
        }

        controller.loadDateData()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 60 })

    var data = { note: { domain_id: this.application.tax_filing.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.note_permission } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.getOpportunities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 50 })

    var data = { ticket: { board_id: this.application.tax_filing.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.ticket_permission } }
    const url = "/users/works/activities/tickets/list_by_board"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getAccountProduct() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Conta Produto`, value: 40 })

    var data = { account: { account_id: this.application.tax_filing.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.account_product_permission } }
    const url = "/operations/accounts/products/read_with_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln
        }

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxFilingTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Operações`, value: 30 })

    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.product_permission } }
    const url = "/operations/products/config/teams/list_tax_filing_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.team_list = response.data.cln
        }

        if (controller.application.account_product_permission) {
          controller.getAccountProduct()
        } else {
          controller.getTickets()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxFilingDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    var data = { date: { tax_filing_id: this.application.tax_filing.id }, current_user: { current_user_id: this.application.current_user_id, feature: this.application.date_permission } }
    const url = "/operations/products/tax_filings/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.application.main_current_date = response.data.cln[0]
            // controller.setTaxFilingName()
            // controller.doSubtitle()

            if (controller.application.current_date.open == false) {
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }

            if (controller.application.permissions[this.application.product_permission].can_manage) {
              controller.getTaxFilingTeam()
            } else {
              if (controller.application.account_product_permission) {
                controller.getAccountProduct()
              } else {
                controller.getTickets()
              }
            }
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar a Competência, no Submenu > Competências`, 3000)
          }

        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canal de Transmissão`, value: 15 })
    var controllerBroadcast = this.getControllerByIdentifier(`mobile--operations--products--tax-filings--show--broadcast`)

    var data = { product: { id: this.application.tax_filing.id, name: this.application.product_permission }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controllerBroadcast.application.rooms[controllerBroadcast.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln.update
          controllerBroadcast.chat_room = response.data.cln.chat
          controllerBroadcast.application.chat_room = response.data.cln.chat
          controllerBroadcast.broadcastUpdateChannel()
          controllerBroadcast.broadcastChatChannel()
        }

        controller.getTaxFilingDates()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxFiling() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Declaração IRPF`, value: 10 })

    var data = { product: { token: this.application.taxFilingToken }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.product_permission } }
    const url = `/operations/products/entities/read_show`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_filing = response.data.cln


          controller.getRoom()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_tax_filings` }, current_user: { current_user_id: this.application.current_user.id, feature: this.application.permissions_permission, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getTaxFiling()
        } else {
          window.open(`/dashboard`, `_self`)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  setAccountPermission() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      this.application.product_permission = `medfiling_teams`
      this.application.permissions_permission = `medfiling_team_permissions`
      this.application.date_permission = `medfiling_team_dates`
      this.application.files_permission = `medfiling_team_files`
      this.application.account_product_permission = `medfiling_team_account_products`
      this.application.journey_permission = `medfiling_team_journeys`
      this.application.summary_permission = `medfiling_team_summaries`
      this.application.note_permission = `medfiling_team_notes`
      this.application.ticket_permission = `medfiling_team_tickets`
      this.application.agent_permission = `medfiling_team_agents`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.application.product_permission = `medfiling_doctors`
      this.application.date_permission = `medfiling_doctor_dates`
      this.application.permissions_permission = `medfiling_doctor_permissions`
      this.application.ticket_permission = `medfiling_doctor_tickets`
      this.application.note_permission = `medfiling_doctor_notes`
      this.application.journey_permission = `medfiling_doctor_journeys`
      this.application.summary_permission = `medfiling_doctor_summaries`
      this.application.files_permission = `medfiling_doctor_files`
      this.application.agent_permission = `medfiling_doctor_agents`
    } else if (this.application.current_user.account_kind == `helper`) {
      this.application.product_permission = `medfiling_helpers`
      this.application.permissions_permission = `medfiling_helper_permissions`
    }
  }

  refreshLoadValue() {
    var controller = this
    var value = 60
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: controller.pageLoadMessage })
      value += 1
    }, 500)
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
