
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "expenseCount", "expenseCountTooltip",
                    "receivableCount", "receivableCountTooltip", "taxCount", "taxCountTooltip",
                    "totalReceivables", "totalTaxes", "totalExpenses", "totalTickets"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--show--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.setAccountPermission()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--clinics--clients--show--dashboard").doClinicDashboard()
  }

  goToApurations() {
    this.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard").doApurationsDashboard()
  }

  goToReceivables() {
    this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard").doReceivablesDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("operations--products--clinics--clients--tickets--dashboard").doTicketsDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--clinics--clients--notes--dashboard").doNotesDashboard()
  }

  goToIntegrations() {
    this.getControllerByIdentifier("operations--products--clinics--clients--integrations--dashboard").doIntegrationsDashboard()
  }

  goToTransfers() {
    this.getControllerByIdentifier("operations--products--clinics--clients--transfers--dashboard").doTransfersDashboard()
  }

  goToExpenses() {
    this.getControllerByIdentifier("operations--products--clinics--clients--expenses--dashboard").doExpensesDashboard()
  }

  goToBalances() {
    this.getControllerByIdentifier("operations--products--clinics--clients--balances--dashboard").doBalancesDashboard()
  }

  goToTaxes() {
    this.getControllerByIdentifier("operations--products--clinics--clients--taxes--dashboard").doTaxesDashboard()
  }

  goToDates() {
    this.getControllerByIdentifier("operations--products--clinics--clients--dates--dashboard").doDatesDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier("operations--products--clinics--clients--regimes--dashboard").doRegimesDashboard()
  }

  goToTakers() {
    this.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard").doTakersDashboard()
  }

  goToHelpers() {
    this.getControllerByIdentifier("operations--products--clinics--clients--helpers--dashboard").doHelpersDashboard()
  }

  goToTransfers() {
    this.getControllerByIdentifier("operations--products--clinics--clients--transfers--dashboard").doTransfersDashboard()
  }



  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`

    if (this.application.permissions[this.main_apuration_permission].can_index) {
      var apurationBtn = `<button type="button" data-action="${this.controllerName}#goToApurations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Apurações</button>`
    } else {
      var apurationBtn = ``
    }

    if (this.application.permissions[this.receivable_permission].can_index) {
      var receivableBtn = `<span class="mc-tooltip">
                              <button type="button" data-action="${this.controllerName}#goToReceivables ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn receivableCount">Nota Fiscal</button>
                              <span class="mc-tooltiptext" data-${this.controllerName}-target="receivableCountTooltip"></span>
                            </span>`
    } else {
      var receivableBtn = ``
    }

    if (this.application.permissions[this.expense_permission].can_index) {
      var expensesBtn = `<span class="mc-tooltip">
                            <button type="button" data-action="${this.controllerName}#goToExpenses ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn expenseCount">Despesas</button>
                            <span class="mc-tooltiptext" data-${this.controllerName}-target="expenseCountTooltip"></span>
                          </span>`
    } else {
      var expensesBtn = ``
    }

    if (this.application.permissions[this.taxes_permission].can_index) {
      var taxesBtn = `<span class="mc-tooltip">
                        <button type="button" data-action="${this.controllerName}#goToTaxes ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn taxCount">Impostos</button>
                        <span class="mc-tooltiptext" data-${this.controllerName}-target="taxCountTooltip"></span>
                      </span>`
    } else {
      var taxesBtn = ``
    }

    if (this.application.permissions[this.taker_permission].can_index) {
      var takersBtn = `<button type="button" data-action="${this.controllerName}#goToTakers ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tomadores</button>`
    } else {
      var takersBtn = ``
    }

    if (this.application.permissions[this.helper_permission].can_index) {
      var helpersBtn = `<button type="button" data-action="${this.controllerName}#goToHelpers ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Assistentes</button>`
    } else {
      var helpersBtn = ``
    }

    if (this.application.permissions[this.transfer_permission].can_index) {
      var transfersBtn = `<button type="button" data-action="${this.controllerName}#goToTransfers ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Repasses</button>`
    } else {
      var transfersBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${apurationBtn}
                  ${receivableBtn}
                  ${taxesBtn}
                  ${expensesBtn}
                  ${transfersBtn}
                  ${takersBtn}
                  ${helpersBtn}

                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doBadgeCounts()    
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
    this.doExpensesCount()
    this.doTaxesCount()
    this.doReceivablesCount()
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (this.hasExpenseCountTarget && element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (this.hasReceivableCountTarget && element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (this.hasTaxCountTarget && element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0

    this.application.tickets.forEach(element => {
      if (element.stage == `in_process`) {
        ticketCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketCount} Ticket(s) em Aberto`
    }
  }

  doExpensesCount() {
    var expenseCount = 0
    var expenseUnpaidCount = 0

    this.application.expenses.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
          expenseCount += 1
          if (element.paid == false) {
            expenseUnpaidCount += 1
          }
        } else if (this.application.current_user.account_kind == `doctor`) {
          if (element.partner_ids.includes(this.application.current_partner.id.toString())) {
            expenseCount += 1
            if (element.paid == false) {
              expenseUnpaidCount += 1
            }
          }
        } else if (this.application.current_user.account_kind == `helper`) {
          expenseCount += 1
          if (element.paid == false) {
            expenseUnpaidCount += 1
          }
        }
      }
    })

    if (this.hasExpenseCountTarget) {
      var expenseBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalExpenses">${expenseUnpaidCount}</span>`
      if (this.totalExpensesTargets[0]) {
        this.totalExpensesTargets[0].remove()
      }
      this.expenseCountTarget.insertAdjacentHTML("beforeend", expenseBadgeHtml)
      this.expenseCountTooltipTarget.innerText = `${expenseUnpaidCount}/${expenseCount} Despesas(s) não Pagas na ${this.application.current_date.name}`
    }
  }

  doTaxesCount() {
    var taxCount = 0
    var taxUnpaidCount = 0

    this.application.taxes.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        taxCount += 1
        if (element.status == `not_paid`) {
          taxUnpaidCount += 1
        }
      }
    })

    if (this.hasTaxCountTarget) {
      var taxBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTaxes">${taxUnpaidCount}</span>`
      if (this.totalTaxesTargets[0]) {
        this.totalTaxesTargets[0].remove()
      }
      this.taxCountTarget.insertAdjacentHTML("beforeend", taxBadgeHtml)
      this.taxCountTooltipTarget.innerText = `${taxUnpaidCount}/${taxCount} Imposto(s) não Pagos na ${this.application.current_date.name}`
    }
  }

  doReceivablesCount() {
    var receivableCount = 0
    var receivableUnreceivedCount = 0
    this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).setReceivables()

    this.application.receivables.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
          receivableCount += 1
          if (element.receivement_status == `not_received` && element.invoice_status != `canceled`) {
            receivableUnreceivedCount += 1
          }
        } else if (this.application.current_user.account_kind == `doctor`) {
          var partnerIds = this.mapElements(element.performers, `partner_id`)
          if (partnerIds.includes(this.application.current_partner.id)) {
            receivableCount += 1
            if (element.receivement_status == `not_received` && element.invoice_status != `canceled`) {
              receivableUnreceivedCount += 1
            }
          }
        } else if (this.application.current_user.account_kind == `helper`) {
          if (element.taker_type == `individual`) {
            receivableCount += 1
            if (element.receivement_status == `not_received` && element.invoice_status != `canceled`) {
              receivableUnreceivedCount += 1
            }
          }
        }
      }
    })

    if (this.hasReceivableCountTarget) {
      var receivableBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalReceivables">${receivableUnreceivedCount}</span>`
      if (this.totalReceivablesTargets[0]) {
        this.totalReceivablesTargets[0].remove()
      }
      this.receivableCountTarget.insertAdjacentHTML("beforeend", receivableBadgeHtml)
      this.receivableCountTooltipTarget.innerText = `${receivableUnreceivedCount}/${receivableCount} NF(s) não Recebidas na ${this.application.current_date.name}`
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  setAccountPermission() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      this.product_permission = `medclinic_clients`
      this.permissions_permission = `medclinic_client_permissions`
      this.date_permission = `medclinic_client_dates`
      this.channel_permission = `medclinic_client_channels`
      this.activities_permission = `medclinic_client_activities`
      this.taker_permission = `medclinic_client_takers`
      this.partner_permission = `medclinic_client_partners`
      this.chart_account_permission = `medclinic_client_chart_accounts`
      this.helper_permission = `medclinic_client_helpers`
      this.provider_permission = `medclinic_client_providers`
      this.main_apuration_permission = `medclinic_client_main_apurations`
      this.regime_permission = `medclinic_client_regimes`
      this.receivable_permission = `medclinic_client_receivables`
      this.taxes_permission = `medclinic_client_taxes`
      this.expense_permission = `medclinic_client_expenses`
      this.transfer_permission = `medclinic_client_transfers`
    } else if (this.application.current_user.account_kind == `doctor`) {
      this.product_permission = `medclinic_doctor_clients`
      this.permissions_permission = `medclinic_doctor_client_permissions`
      this.date_permission = `medclinic_doctor_client_dates`
      this.channel_permission = `medclinic_doctor_client_channels`
      this.activities_permission = `medclinic_doctor_client_activities`
      this.taker_permission = `medclinic_doctor_client_takers`
      this.partner_permission = `medclinic_doctor_client_partners`
      this.chart_account_permission = `medclinic_doctor_client_chart_accounts`
      this.helper_permission = `medclinic_doctor_client_helpers`
      this.provider_permission = `medclinic_doctor_client_providers`
      this.main_apuration_permission = `medclinic_doctor_client_main_apurations`
      this.regime_permission = `medclinic_doctor_client_regimes`
      this.receivable_permission = `medclinic_doctor_client_receivables`
      this.taxes_permission = `medclinic_doctor_client_taxes`
      this.expense_permission = `medclinic_doctor_client_expenses`
      this.transfer_permission = `medclinic_doctor_client_transfers`
    } else if (this.application.current_user.account_kind == `helper`) {
      this.product_permission = `medclinic_helper_clients`
      this.permissions_permission = `medclinic_helper_client_permissions`
      this.date_permission = `medclinic_helper_client_dates`
      this.channel_permission = `medclinic_helper_client_channels`
      this.activities_permission = `medclinic_helper_client_activities`
      this.taker_permission = `medclinic_helper_client_takers`
      this.partner_permission = `medclinic_helper_client_partners`
      this.chart_account_permission = `medclinic_helper_client_chart_accounts`
      this.helper_permission = `medclinic_helper_client_helpers`
      this.provider_permission = `medclinic_helper_client_providers`
      this.main_apuration_permission = `medclinic_helper_client_main_apurations`
      this.regime_permission = `medclinic_helper_client_regimes`
      this.receivable_permission = `medclinic_helper_client_receivables`
      this.taxes_permission = `medclinic_helper_client_taxes`
      this.expense_permission = `medclinic_helper_client_expenses`
      this.transfer_permission = `medclinic_helper_client_transfers`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
