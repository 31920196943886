import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "setupCheckbox", "recurringCheckbox", "saveBtn",
                    "dueAtInput", "expiresAtInput", "descriptionInput", "amountInput", "addonsDiv",

                    "addonCheckbox", "channelInput", "kindInput", "kindList",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "planDropdown", "planDropdownBtn", "planInput", "planList", "startedAtInput",
                    "errorMessage", "financialStatusDropdownBtn", "financialStatusInput", "financialStatusList",
                    "dueDayDropdown", "dueDayDropdownBtn", "dueDayInput", "dueDayList", "bindSum",
                    "setFineInterestsCheckbox"]


  connect() {
    this.controllerName = `financials--books--receivables--entities--save-from-conciliation`
    this.canRequest = true
  }

  cancelSave() {
    this.stopRefreshing()

    if (this.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
      this.getControllerByIdentifier(`financials--books--receivables--contracts--list`).doDataTable()
      this.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()
    } else if (this.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--receivables`) {
      this.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--contracts`).doContractShow()
    }

  }

  saveReceivable() {
    this.send_data = { current_user: {}, receivable: {}, items: {}, billing: {} }

    // var chart = {}
    // var chartId = this.chartInputTarget.dataset.filter
    // this.application.chart_accounts.forEach(element => {
    //   if (element.id == chartId) {
    //     chart = element
    //   }
    // })

    this.application.current_regimes.forEach(element => {
      if (element.med_id == this.current_med.id) {
        this.current_regime = element
      }
    })

    this.send_data.receivable.med_id = this.current_med.id
    this.send_data.receivable.med_name = this.current_med.name
    this.send_data.receivable.client_id = this.current_client.id
    this.send_data.receivable.client_name = this.current_client.record_name
    // this.send_data.receivable.provider_name = this.current_client.record_name
    // this.send_data.receivable.provider_number = this.current_client.record_number
    // this.send_data.receivable.regime_id = this.current_regime.id
    // this.send_data.receivable.regime_name = this.current_regime.tax_regime
    this.send_data.receivable.contract_id = this.current_contract.id
    this.send_data.receivable.description = this.current_contract.name
    this.send_data.receivable.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.receivable.channel_name = this.channelInputTarget.value
    this.send_data.receivable.channel_kind = this.channelInputTarget.dataset.channelKind
    this.send_data.receivable.product_name = this.current_contract.product_name
    this.send_data.receivable.product_service = this.current_contract.product_service
    this.send_data.receivable.product_kind = this.current_contract.product_kind
    this.send_data.receivable.product_plan = this.current_contract.product_plan
    this.send_data.receivable.product_interval = this.current_contract.product_interval
    this.send_data.receivable.recurring_kind = this.current_contract.recurring_kind
    this.send_data.receivable.status = `pending`
    this.send_data.receivable.returned_status = `waiting`
    this.send_data.receivable.received_status = `not_received`
    this.send_data.receivable.invoice_status = `not_transmitted`
    this.send_data.receivable.taker_taxation_type = `within_city`
    this.send_data.receivable.due_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(`${this.current_contract.due_day}/${this.dueAtInputTarget.value}`)

    if (this.current_contract.recurring_kind == `recurring`) {
      this.send_data.receivable.set_fine_interests = this.setFineInterestsCheckboxTarget.checked
    } else {
      this.send_data.receivable.set_fine_interests = false
    }
    
    this.send_data.receivable.client_iugu_id = this.current_billing.client_id_iugu
    this.send_data.receivable.client_iugu_email = this.current_billing.email_iugu

    var items = []
    this.addonCheckboxTargets.forEach(addonCheckbox => {
      if (addonCheckbox.checked) {
        var item = {}
        var addon = {}
        var addonId = addonCheckbox.dataset.id

        this.current_contract_addons.forEach(element => {
          if (element.id == addonId) {
            addon = element
          }
        })

        item.med_id = this.current_med.id
        item.med_name = this.current_med.name
        item.client_name = this.current_client.record_name
        item.addon_id = addon.id
        item.contract_id = this.current_contract.id
        item.chart_id = addon.chart_id
        item.chart_account = addon.chart_account
        item.chart_name = addon.chart_name
        item.chart_master_name = addon.chart_master_name
        item.chart_group = addon.chart_group
        item.chart_kind = addon.chart_kind
        item.contract_name = this.current_contract.name
        item.description = addon.description
        item.kind = addon.kind
        item.subkind = addon.subkind
        item.amount = addon.amount
        item.notes = addon.notes
        item.client_iugu_id = this.current_billing.client_iugu_id
        item.client_iugu_email = this.current_billing.client_iugu_email
      }

      items[items.length] = item
    })

    this.send_data.items.items = items

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_entities`

    var contract_date = this.getControllerByIdentifier("app--helpers--date").transformToTokenDate(this.current_contract.started_at_pretty)
    var token_date = this.getControllerByIdentifier("app--helpers--date").transformToTokenDate(this.dueAtInputTarget.value)

    if (token_date < contract_date) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência do Recebível está inferior ao início do Contrato!`, 3000)
    } else {
      this.saveBtnTarget.disabled = true
      this.stopRefreshing()

      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = "/financials/books/receivables/entities/create_from_conciliation"
      var method = "POST"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/receivables/entities/destroy"
      var method = "DELETE"
    }
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var receivable = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.receivables[controller.application.receivables.length] = receivable
          } else if (controller.actionMode == `destroy`) {
            controller.application.receivables.forEach((element, i) => {
              if (element.id == receivable.id) {
                controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1)
              }
            })
          }

          if (controller.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
            
          } else if (controller.controllerDashboard.context.identifier == `financials--books--receivables--conciliations--modal--receivables`) {
            var controllerModalReceivables = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--receivables`)

            if (controller.actionMode == `new`) {

              controllerModalReceivables.current_contract_receivables[controllerModalReceivables.current_contract_receivables.length] = receivable

              receivable.items.forEach(item => {
                controller.application.addons.forEach(addon => {
                  if (item.addon_id == addon.id) {
                    addon.receivables[addon.receivables.length] = receivable
                  }
                })
              })

            } else if (controller.actionMode == `destroy`) {

              controllerModalReceivables.current_contract_receivables.forEach((element, i) => {
                if (element.id == receivable.id) {
                  controllerModalReceivables.current_contract_receivables.splice(controllerModalReceivables.current_contract_receivables.indexOf(element), 1)
                }
              })

              controller.application.addons.forEach((addon, i) => {
                addon.receivables.forEach(element => {
                  if (element.id == receivable.id) {
                    addon.receivables.splice(addon.receivables.indexOf(element), 1)
                  }
                })
              })
              
            }
            
            controllerModalReceivables.doReceivablesList()
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveReceivable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    // if (this.application.permissions[this.permission].can_create) {
    //   var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveReceivable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    // } else {
    //   var saveBtnHtml = ``
    // }

    var setFineInteretsHtml = ``
    if (this.current_contract.recurring_kind == `recurring`) {
      setFineInteretsHtml = `<div class="col-4">
                              <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                                <input type="checkbox" class="custom-control-input" id="setFineInterestsCheckbox" data-${this.controllerName}-target="setFineInterestsCheckbox">
                                <label class="custom-control-label f-065" for="setFineInterestsCheckbox">Gerar Multa/Juros se atraso?</label>
                              </div>
                            </div>`
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                        ${setFineInteretsHtml}
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-2 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueAtForm">Competência</label>
                                <input aria-describedby="dueAtFormHelp" class="form-control" id="dueAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="dueAtInput" data-check-future="true" placeholder="MM/AAAA" type="text" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição Contrato</label>
                                <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal Recebimento</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal Recebimento" required>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                        <div class="row my-2 d-flex align-items-center" data-${this.controllerName}-target="addonsDiv"></div>
                        
                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span data-${this.controllerName}-target="bindSum" class="px-5 mx-5 f-075"></span>
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier("financials--books--receivables--contracts--dashboard").viewTarget.innerHTML = html)
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--modal--receivables`)) {
        controller.formCardTarget.classList.add(`mt-4`)
      } else {
        controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      }

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Recebível`

        controller.dueAtInputTarget.value = controller.current_contract.due_at_pretty
        
      } else if (controller.actionMode == `new`) {
        // controller.formTitleTarget.innerText = `Novo Recebível | Cliente ${controller.current_billing.email_iugu} ID #${controller.current_billing.client_id_iugu}`
        controller.formTitleTarget.innerHTML = `<span class="p-0 mc-tooltip help">
                                                  <span>Novo Recebível</span>
                                                  <span class="mc-tooltiptext">Cliente ${controller.current_billing.email_iugu} | ID #${controller.current_billing.client_id_iugu}</span>
                                                </span>`
        
        if (controller.hasSetFineInterestsCheckboxTarget) {
          controller.setFineInterestsCheckboxTarget.checked = true  
        }

        controller.descriptionInputTarget.value = controller.current_contract.name

        controller.application.channels.forEach(channel => {
          if (channel.med_id == controller.current_med.id && controller.channel_kind.includes(channel.kind)) {
            controller.channelInputTarget.value = channel.name
            controller.channelInputTarget.dataset.channelId = channel.id
            controller.channelInputTarget.dataset.channelKind = channel.kind
          }
        })

        controller.dueAtInputTarget.value = controller.application.current_date.date_accrual

        // controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

      }

      controller.refreshSaveBtn()
      controller.doAddonsList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doAddonsList() {
    var html = ``
    this.actionMode = `new`

    var orderedAddons = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_contract_addons, `kind_number`)

    var hasNoClientAddon = true
    orderedAddons.forEach(addon => {
      if (addon.status == `active` && addon.client_iugu_id == this.current_billing.client_id_iugu) {

        if (addon.notes) {
          var addonNotes = addon.notes
        } else {
          var addonNotes = addon.contract_name
        }

        var writeOffBtn = ``
        if (addon.kind == `discount` || addon.kind == `fine_interests`) {
          writeOffBtn = `<button data-action="click->${this.controllerName}#writeOffAddon" data-id="${addon.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">edit_off</span>
                            <span class="mc-tooltiptext mc-tooltiptext-right">Anular Complemento</span>
                          </button>`
        }

        hasNoClientAddon = false
        html += `<div class="col-1">
                  <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                    <input type="checkbox" class="custom-control-input" id="addonCheck-${addon.id}" data-${this.controllerName}-target="addonCheckbox-${addon.id} addonCheckbox" data-id="${addon.id}" data-amount="${addon.amount}" data-kind="${addon.kind}" data-action="click->${this.controllerName}#selectAddon ${this.controllerName}#bindSum">
                    <label class="custom-control-label f-065" for="addonCheck-${addon.id}"></label>
                  </div>
                </div>

                <div class="col-5 px-2">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="descriptionForm">Descrição</label>
                      <input disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput-${addon.id}" placeholder="Descrição" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="col-2 text-center">
                  <span class="p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark help">help_center</span>
                    <span class="mc-tooltiptext">${addonNotes}</span>
                  </span>

                  ${writeOffBtn}
                </div>

                <div class="col-2">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="amountForm">Valor</label>
                      <input disabled aria-describedby="amountFormHelp" class="form-control" id="amountForm" data-${this.controllerName}-target="amountInput-${addon.id}" placeholder="Valor" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="startedAtForm">Início</label>
                      <input disabled aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput-${addon.id}" placeholder="Início" type="text" required>
                    </div>
                  </div>
                </div>`
      }
    })

    if (hasNoClientAddon) {
      html += `<div class="col-12 text-center">
                  <span class="f-085"><strong>Favor adicionar o complemento para este Contrato referente ao Cliente ${this.current_billing.client_id_iugu}</strong></span>
                </div>`
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.addonsDivTarget.innerHTML = html)
    }).then(() => {      
      controller.current_contract_addons.forEach(addon => {
        if (addon.status == `active` && addon.client_iugu_id == this.current_billing.client_id_iugu) {
          controller.nameTarget(`descriptionInput-${addon.id}`).value = addon.description
          controller.nameTarget(`amountInput-${addon.id}`).value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)
          controller.nameTarget(`startedAtInput-${addon.id}`).value = addon.started_at_pretty
        }
      })

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  selectAddon() {

  }
  
  bindSum() {
    var totalAmount = 0

    this.addonCheckboxTargets.forEach(addonCheckbox => {
      if (addonCheckbox.checked) {

        if (addonCheckbox.dataset.kind == `revenue` || addonCheckbox.dataset.kind == `fine_interests` || addonCheckbox.dataset.kind == `refund` || addonCheckbox.dataset.kind == `advance`) {
          totalAmount += Number(addonCheckbox.dataset.amount)
        } else if (addonCheckbox.dataset.kind == `discount` || addonCheckbox.dataset.kind == `reversal`) {
          totalAmount -= Number(addonCheckbox.dataset.amount)
        }
        // @receivable.total_amount_invoice = @receivable.total_amount_revenue + @receivable.total_amount_fines_and_interests - @receivable.total_amount_discount
        // @receivable.total_amount_charged = @receivable.total_amount_invoice + @receivable.total_amount_refund

      }
    })

    this.bindSumTarget.innerText = `Total Recebível: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(totalAmount)}`
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToMonthDate(ev)
  }

  dateValidate(ev) {
    var gridElement = ev.currentTarget
    var checkFuture = this.getControllerByIdentifier("app--helpers--boolean").transformStringToBoolean(gridElement.dataset.checkFuture)

    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(`01/${ev.target.value}`)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(`01/${ev.target.value}`, checkFuture)
      if (this.checkDate.valid) {
        gridElement.classList.add("f-normal")
        gridElement.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.errorMessageTarget.innerText = this.checkDate.message
        this.clearErrorMessage()

        gridElement.classList.add("f-danger")
        gridElement.classList.remove("f-normal")
      }
    } else {
      gridElement.classList.add("f-normal")
      gridElement.classList.remove("f-danger")
    }
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = this.channel_kind
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_receivable_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  writeOffAddon(ev) {
    var elementTarget = ev.currentTarget
    var addonId = elementTarget.dataset.id
    var addonDescription = ``
    var addonAmount = ``

    this.current_contract_addons.forEach(element => {
      if (element.id == addonId) {
        addonDescription = element.description
        addonAmount = element.amount  
      }
    })

    var r = confirm(`Tem certeza que deseja Anular o Item de Contrato ${addonDescription} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addonAmount)}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, addon: {} }
      this.actionMode = `update`

      this.send_data.addon.id = addonId
      this.send_data.addon.status = `write_off`

      this.requestSaveAddon()
    }
  }

  requestSaveAddon() {

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_addons`

    if (this.actionMode == `destroy`) {
      var url = "/financials/books/clients/addons/destroy"
      var method = "DELETE"
    } else if (this.actionMode == `update`) {
      var url = "/financials/books/clients/addons/update"
      var method = "PUT"
    } else if (this.actionMode == `duplicate`) {
      var url = "/financials/books/clients/addons/duplicate_addon"
      var method = "POST"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var addon = response.data.cln.addon
          var contract = response.data.cln.contract

          if (controller.actionMode == `destroy`) {
            controller.current_contract_addons.forEach((element, i) => {
              if (element.id == addon.id) {
                controller.current_contract_addons.splice(controller.current_contract_addons.indexOf(element), 1)
              }
            })
          } else if (controller.actionMode == `update`) {
            controller.current_contract_addons.forEach((element, i) => {
              if (element.id == addon.id) {
                controller.current_contract_addons.splice(controller.current_contract_addons.indexOf(element), 1, addon)
              }
            })
          } else if (controller.actionMode == `duplicate`) {
            controller.current_contract_addons[controller.current_contract_addons.length] = addon
          }

          controller.doAddonsList()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  clearErrorMessage() {
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {


        if (controller.hasDueAtInputTarget && controller.dueAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasChannelInputTarget && controller.channelInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
