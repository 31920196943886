import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "filter", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--entities--dashboard`
  }

  doClientsDashboard() {
    var html = `<div data-${this.controllerName}-target="mainDashboard">
                  <div class="row">
                    <div class="col-4" data-${this.controllerName}-target="list" data-controller="financials--books--clients--entities--list"></div>
                    <div class="col-8" data-${this.controllerName}-target="view" data-controller="financials--books--clients--entities--show"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--clients--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier("financials--books--clients--entities--list").permission = `financial_client_entities`
      controller.getControllerByIdentifier("financials--books--clients--entities--list").controllerDashboard = controller.getControllerByIdentifier(`financials--books--clients--entities--dashboard`)
      controller.getControllerByIdentifier("financials--books--clients--entities--list").gridElement = controller.getControllerByIdentifier(`financials--books--clients--entities--dashboard`).listTarget
      controller.getControllerByIdentifier("financials--books--clients--entities--list").doClientList()

      controller.getControllerByIdentifier("financials--books--clients--entities--show").gridElement = controller.getControllerByIdentifier(`financials--books--clients--entities--dashboard`).viewTarget
      
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}