
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    this.doSubmenuHtml()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").reconnect()
  }
  
  goToAchievements() {
    this.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").doAchievementsDashboard()
  }

  goToIncomes() {
    this.getControllerByIdentifier("operations--products--tax-returns--incomes--dashboard").doIncomesDashboard()
  }

  goToPayments() {
    this.getControllerByIdentifier("operations--products--tax-returns--payments--dashboard").doPaymentsDashboard()
  }

  goToDocuments() {
    this.getControllerByIdentifier("operations--products--tax-returns--documents--dashboard").doDocumentsDashboard()
  }

  goToNotes() {

  }

  goToSources() {
    this.getControllerByIdentifier("operations--products--tax-returns--sources--dashboard").doSourcesDashboard()
  }

  goToSetttings() {
    this.getControllerByIdentifier("operations--products--tax-returns--settings--dashboard").doSettingsDashboard()
  }


  doSubmenuHtml() {
    // `<span data-action="click->operations--products--tax-returns--entities--submenu#goToDocuments" class="dropdown-item py-1 pointer">Documentos</span>`
    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToDashboard" class="dropdown-item py-1 pointer dropdown-submenu-item">Dashboard</span>
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToAchievements" class="dropdown-item py-1 pointer dropdown-submenu-item">Planner</span>
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToIncomes" class="dropdown-item py-1 pointer dropdown-submenu-item">Rendimentos</span>
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToPayments" class="dropdown-item py-1 pointer dropdown-submenu-item">Pagamentos</span>
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToSources" class="dropdown-item py-1 pointer dropdown-submenu-item">Fontes</span>
                          <span data-action="click->operations--products--tax-returns--entities--submenu#goToSetttings" class="dropdown-item py-1 pointer dropdown-submenu-item">Configurações</span>
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
    tooltip()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
