import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "subTitle", "dates"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--show--main`
    this.account_kind = this.application.current_user.account_kind
    this.application.canEmitInvoice = false
    this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.clinic_token = location.pathname.split("/").pop()
    this.application.dates = []
    this.application.main_apurations = []
    this.application.partners = []
    this.application.takers = []
    this.application.regimes = []
    this.application.all_activities = []
    this.application.activities = []
    this.application.notes = []
    this.application.tickets = []
    this.application.banks = []
    this.application.all_receivables = []
    this.application.receivables = []
    this.application.canceled_receivables = []
    this.application.channels = []
    this.application.chart_accounts = []
    this.application.transfers = []
    this.application.balances = []
    this.application.taxes = []
    this.application.expenses = []
    this.application.providers = []
    this.application.agents = []
    this.application.transactions = []
    this.application.emails = []
    this.doClinicGrid()

    // getCurrentUserPermissions
    // getClinic
    // getClinicDates
    // getMainApurations
    // getClinicTeam
    // getReceivables
    // getPartners
    // getTransfers
    // getTakers
    // getRegimes
    // getActivities
    // getBanks
    // getCertificates
    // getIntegrationCompanies
    // getChartAccounts
    // getChannels
    // getBalances
    // getTaxes
    // getTickets
    // getNotes
  }

  doClinicGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--financials--show--dashboard
                                                            operations--products--clinics--financials--apurations--dashboard
                                                            operations--products--clinics--financials--transfers--dashboard
                                                            operations--products--clinics--financials--add--dashboard
                                                            operations--products--clinics--regularizations--show--check-invoice
                                                            operations--products--clinics--financials--receivables--dashboard
                                                            operations--products--clinics--financials--regimes--dashboard
                                                            operations--products--clinics--financials--activities--dashboard
                                                            operations--products--clinics--financials--tickets--dashboard
                                                            operations--products--clinics--financials--notes--dashboard
                                                            operations--products--clinics--financials--taxes--dashboard
                                                            operations--products--clinics--financials--expenses--dashboard
                                                            operations--products--clinics--financials--takers--dashboard
                                                            operations--products--clinics--managements--emails--pre-email

                                                            operations--products--clinics--managements--logs--dashboard
                                                            app--stats--trackers--emails--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--financials--show--dashboard").doClinicDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
      }, 500);
    }

    this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").setCurrents()
    if (this.reload) {
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").runDashboardsHtml()
      this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dashboard").doClinicDashboard()
      this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").doDatesHTML()
    }
    this.application.current_date.data_loaded = true
    this.load = true

    this.stopRefreshing()
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getExpenses() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Despesas ${this.application.current_date.name}`, value: 99 })

    var data = { expense: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_expenses` } }
    const url = `/operations/products/clinics/financials/expenses/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.expenses[controller.application.expenses.length] = element
          })
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTaxes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Impostos ${this.application.current_date.name}`, value: 97 })

    var data = { tax: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_taxes` } }
    const url = "/operations/products/clinics/financials/taxes/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.taxes[controller.application.taxes.length] = element
          })
        }

        controller.getExpenses()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Recibos e NFs ${this.application.current_date.name}`, value: 95 })

    var data = { receivable: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_receivables` } }
    const url = "/operations/products/clinics/financials/receivables/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.all_receivables[controller.application.all_receivables.length] = element
          })
        }

        controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).setReceivables()
        controller.getTaxes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTransfers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Repasses da PJ ${this.application.current_date.name}`, value: 90 })

    var data = { transfer: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_transfers` } }
    const url = "/operations/products/clinics/financials/transfers/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.transfers[controller.application.transfers.length] = element
          })
        }

        controller.getReceivables()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMainApurations() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Apurações ${this.application.current_date.name}`, value: 85 })

    const data = { apuration: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_main_apurations` } }
    const url = `/operations/products/clinics/financials/main_apurations/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.main_apurations[controller.application.main_apurations.length] = element
          })
        }
        controller.getTransfers()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRegimes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Regimes Tributários ${this.application.current_date.name}`, value: 80 })

    var data = { regime: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_regimes` } }
    const url = `/operations/products/clinics/financials/regimes/list_by_date`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.regimes[controller.application.regimes.length] = element
          })
          controller.application.regimes.forEach(element => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.current_regime = element
            }
          })
        }

        controller.getMainApurations()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
        // this.refreshLoadValue()
      }
      this.getRegimes()
    } else {
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").setCurrents()
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dates").runDashboardsHtml()
    }
  }

  getProviders() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 75 })

    var data = { provider: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_providers` } }
    const url = "/operations/products/clinics/financials/expenses/list_providers"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.providers = response.data.cln
        }

        controller.loadDateData()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.application.clinic.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.getProviders()        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    var data = { ticket: { board_id: this.application.clinic.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 60 })

    var data = { channel: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_channels` } }
    const url = "/operations/products/clinics/financials/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.channels = response.data.cln
        }

        controller.getTickets()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Contas`, value: 50 })

    var data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_chart_accounts` } }
    const url = "/operations/products/clinics/financials/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.chart_accounts = response.data.cln
        }

        controller.getChannels()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Atividades Econômicas`, value: 40 })

    var data = { activity: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_activities` } }
    const url = "/operations/products/clinics/regularizations/activities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.activities = response.data.cln

        controller.getChartAccounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTakers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tomadores`, value: 35 })

    var data = { taker: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_takers` } }
    const url = "/operations/products/clinics/regularizations/takers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.takers = response.data.cln

        controller.getActivities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas`, value: 30 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financials` } }
    const url = "/operations/products/config/teams/list_clinic_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.team_list = response.data.cln
        controller.getTakers()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPartners() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Sócios`, value: 25 })

    var data = { partner: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_partners` } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.partners = response.data.cln
        controller.getClinicTeam()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkInvoice() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Check para Emissão de NF`, value: 20 })

    var data = { clinic: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financials` } }
    const url = `/operations/products/clinics/managements/verifications/check_can_invoice`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.check_invoice = response.data.cln
          controller.getPartners()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 15 })

    var data = { date: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_dates` } }
    const url = `/operations/products/clinics/managements/dates/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.application.main_current_date = response.data.cln[0]
            controller.setClinicName()
            controller.doSubtitle()

            controller.checkInvoice()

            if (controller.application.current_date.open == false) {
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }
          } else {
            controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
            var response = { type: `danger`, message: `Favor adicionar a Competência, no Submenu > Competências!` }
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRoom() {
    var controllerBroadcast = this.getControllerByIdentifier(`operations--products--clinics--financials--show--broadcast`)

    var data = { product: { id: this.application.clinic.id, name: `medclinic_financials` }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controllerBroadcast.application.rooms[controller.application.rooms.length] = controllerBroadcast.identifier
          controllerBroadcast.update_room = response.data.cln.update
          controllerBroadcast.chat_room = response.data.cln.chat
          controllerBroadcast.application.chat_room = response.data.cln.chat
          controllerBroadcast.broadcastUpdateChannel()
          controllerBroadcast.broadcastChatChannel()

          controller.getClinicDates()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinic() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando PJ Médica`, value: 10 })

    var data = { product: { token: this.application.clinic_token }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financials` } }
    const url = `/operations/products/entities/read_show`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clinic = response.data.cln

          controller.getRoom()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_financials` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financial_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(permission => {
            controller.application.permissions[permission.name] = permission.data
          })

          controller.getClinic()
        } else {
          window.open(`/dashboard`, `_self`)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  setClinicName() {
    var html = `<strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>
                <span> | CNPJ: </span>
                <strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>`

    this.clinicNameTarget.innerHTML = html
  }

  doSubtitle() {
    this.subTitleTarget.innerText = `Emissão de NF's e Apuração | ${this.application.current_date.name}`
  }

  refreshLoadValue() {
    var controller = this
    var value = 10
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Informações da Competência ${controller.application.current_date.name}`, value: value })
      value += 1
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }


}