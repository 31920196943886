import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "addAgentCard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    // this.application.agents = []
    this.controllerName = `operations--onboards--show--agents`
    this.canRequest = true
  }

  setAgents() {
    var html = ``
    var agents = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.agents, `account_name`)

    if (agents.length == 0) {
      var html = `<div class="row w-100" style="height:100px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Agentes no Onboard</span>
                      </div>
                    </div>`

      // this.listTarget.innerHTML = html
      // this.getControllerByIdentifier("operations--onboards--show--entity").listAgentsTarget.innerHTML = html
    } else {
      agents.forEach(element => {

        if (this.userCanUpdate() && this.application.permissions.operation_onboard_agents.can_delete && this.application.onboard.journey_status != `finished` && this.application.onboard.journey_status != `canceled`) {
          var deleteBtn = `<button data-action="click->${this.controllerName}#destroyAgent" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar</span>
                          </button>`
        } else {
          var deleteBtn = ``
        }

        html += `<div class="col-12 pl-2 pr-2">
                  <div class="card my-2" style="width:100%;display:relative;" data-agent-id="${element.id}">
                    <div class="card-body p-0">
                      <div class="row my-2">
                        <div class="col-10">${element.account_name}</div>
                        <div class="col-2 d-flex align-items-center justify-content-center">${deleteBtn}</div>
                      </div>
                    </div>
                  </div>
                </div>`

        

      })

      // this.getControllerByIdentifier("operations--onboards--show--entity").listAgentsTarget.innerHTML = html
    }
    this.listTarget.innerHTML = html
  }

  createAgentHtml() {
    var saveBtn = ``
    var cancelBtn = `<button data-action="click->${this.controllerName}#cancelSave" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">clear</span>
                        <span class="mc-tooltiptext">Cancelar</span>
                      </button>`
    var html = `<div class="col-12 pl-2 pr-2" data-${this.controllerName}-target="addAgentCard">
                  <div class="card my-2" style="width:100%;display:relative;">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-10 px-1 d-flex align-items-center">
                          <div class="form-group w-100">
                            <div class="floating-label floating-label-sm">
                              <label for="agentNameForm">Nome Responsável</label>
                              <input id="agentNameForm" aria-describedby="agentNameForm-Help" class="form-control f-075" autofocus data-${this.controllerName}-target="agentNameEditInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#agentNameFilter keyup->${this.controllerName}#agentNameFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterAgentName" type="text" required>
                              <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="agentNameFilter"></ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center">${cancelBtn}</div>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.listTarget.innerHTML = html
    
    if (this.hasAddAgentCardTarget == false) {      
      var controller = this
      new Promise(function (resolve) {
        resolve(controller.listTarget.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier("app--helpers--elements").tooltip()
        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
        controller.listAgentName()
      })
    }
  }

  listAgentName() {
    var html = ``
    // this.application.onboard_team.forEach(element => {
    this.application.onboard_team.forEach(element => {
      html += `<li data-agent-id="" data-action="click->${this.controllerName}#selectAgentName" data-${this.controllerName}-target="userNameFilterItem" data-text="${element.nickname}" data-filter="${element.id}" class="li-selector dark f-065">${element.nickname}</li>`
      // html += `<li data-agent-id="" data-action="click->${this.controllerName}#selectAgentName" data-${this.controllerName}-target="userNameFilterItem" data-text="${element.account_name}" data-filter="${element.account_id}" class="li-selector dark f-065">${element.account_name}</li>`
    })

    this.nameTarget(`agentNameFilter`).innerHTML = html
  }

  agentNameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectAgentName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.saveAgent(ev)
  }

  enterAgentName(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.saveAgent(ev)
    }
  }

  cancelSave(ev) {
    this.addAgentCardTarget.remove()
  }

  saveAgent(ev) {
    this.send_data = { current_user: {}, agent: {} }
    var allowed = this.mapElements(this.application.agents, `account_id`)
    var agent_id = ev.currentTarget.dataset.filter
    var agent_name = ev.currentTarget.dataset.text

    if ((!allowed.includes(Number(agent_id)) && this.application.onboard.stage != "finished" && this.application.onboard.stage != "cancel")) {
      if (this.canRequest) {
        this.actionMode = `new`
        this.canRequest = false
        this.send_data.agent.onboard_id = this.application.onboard.id
        this.send_data.agent.account_id = agent_id
        this.send_data.agent.account_name = agent_name
        this.send_data.agent.kind = `secondary`

        this.send_data.current_user.current_user_id = this.application.current_user.id

        this.requestSave()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `${agent_name} já está no Onboard!`, 3000)
      this.cancelSave()
    }
  }

  destroyAgent(ev) {
    this.send_data = { current_user: {}, agent: {} }
    var id = ev.currentTarget.dataset.id

    if (this.canRequest) {
      this.actionMode = `delete`
      this.canRequest = false
      this.send_data.agent.id = id
      this.send_data.agent.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/operations/onboards/agents/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/operations/onboards/agents/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/operations/onboards/agents/destroy"
      var method = "DELETE"
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var agent = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.agents[controller.application.agents.length] = agent
            controller.cancelSave()
          } else if (controller.actionMode == `edit`) {
            if (agent.active) {
              controller.application.agents.forEach((element, i) => {
                if (element.id == agent.id) {
                  controller.application.agents.splice(controller.application.agents.indexOf(element), 1, agent)
                }
              })
            } else {
              controller.application.agents.forEach((element, i) => {
                if (element.id == agent.id) {
                  controller.application.agents.splice(controller.application.agents.indexOf(element), 1)
                }
              })
            }
          } else if (controller.actionMode == `delete`) {
            controller.application.agents.forEach((element, i) => {
              if (element.id == agent.id) {
                controller.application.agents.splice(controller.application.agents.indexOf(element), 1)
              }
            })
          }
          controller.setAgents()
          controller.canRequest = true
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  userCanUpdate() {
    var allowed = this.mapElements(this.application.onboard_team, `id`)
    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.onboard.stage != "finished" && this.application.onboard.stage != "cancel")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
