import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "show", "installments", "list", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--payables--loans--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doLoansDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-controller="financials--books--payables--loans--list">
                    <div class="card-body py-1 px-1" data-${this.controllerName}-target="list" style="overflow:auto;">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-5" data-${this.controllerName}-target="show" data-controller="financials--books--payables--loans--show
                                                                                                financials--books--payables--loans--save"></div>
                  <div class="col-5" data-${this.controllerName}-target="installments" data-controller="financials--books--payables--installments--show
                                                                                                        financials--books--payables--installments--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.listTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getControllerByIdentifier("financials--books--payables--loans--list").doLoanList()
      controller.getControllerByIdentifier("financials--books--payables--loans--show").doNoLoan()
      controller.getControllerByIdentifier("financials--books--payables--installments--show").doNoInstallment()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
