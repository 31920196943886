import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["midia", "images", "title", "submenu", "uploadBtn", "fileUpload", "fileName", "kindInput", "bookLink", "coverLink",
                    "beginBannerLink", "errorBannerLink", "progressUploadBar", "progressUploadCounter",
                    "midiaDropdown", "midiaDropdownBtn", "midiaInput", "midiaList", "videoLinkCard", "coverCard"]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--creative`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadFeature = true
    this.uploadReady = false
  }

  doCreativeGrid() {
    var html = `<div class="row">
                  <div class="col-6" data-${this.controllerName}-target="midia"></div>
                  <div class="col-6" data-${this.controllerName}-target="images"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--landings--show--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doMidiaHtml()
      controller.doImagesHtml()
    })
  }

  inlineDisplay(ev) {
    var editor = ev.currentTarget.closest(".editorRow")
    var display = ev.currentTarget.closest(".editorRow").previousElementSibling

    display.classList.remove("d-none")
    editor.classList.add("d-none")

  }

  inlineEditor(ev) {
    var display = ev.currentTarget.closest(".displayRow")
    var editor = ev.currentTarget.closest(".displayRow").nextElementSibling
    var field = editor.dataset.field
    this.nameTarget(`${field}Input`).value = this.application.field[field]
    
    display.classList.add("d-none")
    editor.classList.remove("d-none")
  }

  saveField(ev) {
    this.send_data = { current_user: {}, field: {} }
    this.inlineDisplay(ev)
    var field = ev.currentTarget.closest(".editorRow").dataset.field


    this.send_data.field.id = this.application.field.id
    this.send_data.field.field = field
    this.send_data.field.value = this.nameTarget(`${field}Input`).value

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/marketing/landings/fields/update_attribute"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.landing = response.data.cln
          controller.application.field = response.data.cln.field
          controller.doMidiaHtml()
          controller.doImagesHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doMidiaHtml() {
    if (this.application.permissions.landings.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.landings.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveField" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>SEO e Mídias</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="midia">
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Meta Título</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.meta_title}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="meta_title">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="meta_titleInput" type="text" placeholder="Meta Título" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Meta Descrição</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.meta_description}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="meta_description">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="meta_descriptionInput" type="text" placeholder="Meta Descrição" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Palavras-Chave</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.meta_keywords}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="meta_keywords">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="meta_keywordsInput" type="text" placeholder="Palavras-Chave" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Open Graph Título</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.og_title}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="og_title">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="og_titleInput" type="text" placeholder="Open Graph Título" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Open Graph Descrição</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.og_description}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="og_description">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="og_descriptionInput" type="text" placeholder="Open Graph Descrição" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.midiaTarget.innerHTML = html
  }

  doImagesHtml() {

    if (this.application.field.has_cover) {
      var cover = `<span class="f-085 text-bold">Cover</span>
                         <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.field.cover_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Cover</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.application.field.id}" data-field="cover" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Cover</span>
                          </button>`
    } else {
      var cover = `<span class="f-085 text-bold">Sem Cover</span>`
    }

    if (this.application.permissions.landings.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.landings.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveField" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>Mídia</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row w-100">
                      <div class="col-12 px-0">
                        <div class="row d-flex align-items-center">
                          <div class="col-6 f-075 px-2 text-left">
                            Selecione o Tipo de Mídia
                          </div>
                          <div class="col-6 f-075 px-2 text-center">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Mídia</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="midiaDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="midiaDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="midiaInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="midiaList" data-app--helpers--search-target="searchList">
                                      <li data-midia="video" data-action="click->${this.controllerName}#selectMidia click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vídeo</li>
                                      <li data-midia="cover" data-action="click->${this.controllerName}#selectMidia click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cover</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row w-100 my-5" data-${this.controllerName}-target="videoLinkCard">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-header p-1 text-center f-070">
                                <span>Vídeo Link</span>
                              </div>
                              <div class="card-body text-center px-0 py-2">
                                <div class="row d-flex align-items-center displayRow">
                                  <div class="col-10 f-075 px-2 text-left">
                                    ${this.application.field.video}
                                  </div>
                                  <div class="col-2 f-075 px-2 text-center">
                                    ${edit}
                                  </div>
                                </div>
                                <div class="row d-flex align-items-center editorRow d-none" data-field="video">
                                  <div class="col-10 f-075 px-2 text-left">
                                    <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="videoInput" type="text" placeholder="Mídia Frame #2" required></textarea>
                                  </div>
                                  <div class="col-2 f-075 px-2 text-center">
                                    ${save}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row w-100 my-5" data-${this.controllerName}-target="coverCard">
                          <div class="col-12 px-0">
                            <div class="row">
                              <div class="col-4 offset-2 d-flex align-items-center justify-content-center">
                                <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                                  <div class="row my-2">
                                    <div class="col p-0 d-flex align-items-center justify-content-center">
                                      <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                                    </div>
                                  </div>
                                </label>
                                <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                              </div>
                              <div class="col-4 d-flex align-items-center justify-content-center">
                                <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                                  <div class="row my-1">
                                    <div class="col-12 d-flex align-items-center justify-content-center">
                                      <span class="material-icons md-white">cloud_upload</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row w-100 d-flex align-items-end d-none my-3" data-${this.controllerName}-target="fileUpload">
                              <div class="col-6">
                                <span data-${this.controllerName}-target="fileName"></span>
                              </div>
                              <div class="col-6">
                                <div class="form-group mb-0">
                                  <div class="floating-label floating-label-sm">
                                    <label>Tipo</label>
                                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                        <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                        <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                          <li data-kind="cover" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cover</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row w-100 my-3">
                              <div class="col-12 d-flex align-items-center">
                                <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                                  <div class="col-12 my-2 d-flex" data-${this.controllerName}-target="coverLink">
                                    ${cover}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.imagesTarget.innerHTML = html)
    }).then(() => {

      if (controller.application.field.midia == `video`) {
        controller.videoLinkCardTarget.classList.remove("d-none")
        controller.coverCardTarget.classList.add("d-none")
      } else if (controller.application.field.midia == `cover`) {
        controller.videoLinkCardTarget.classList.add("d-none")
        controller.coverCardTarget.classList.remove("d-none")
      } else {
        controller.videoLinkCardTarget.classList.add("d-none")
        controller.coverCardTarget.classList.add("d-none")
      }

      controller.midiaDropdownTarget.value = controller.application.field.midia
      controller.midiaInputTarget.dataset.midia = controller.application.field.midia
      controller.midiaInputTarget.innerText = controller.application.field.midia_pretty

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  selectMidia(ev) {
    this.send_data = { current_user: {}, field: {} }

    this.send_data.field.id = this.application.field.id
    this.send_data.field.field = `midia`
    this.send_data.field.value = ev.currentTarget.dataset.midia

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
        obj.file = element
        this.upload_file = obj
        this.uploadReady = true
        this.fileUploadTarget.classList.remove("d-none")
        this.fileNameTarget.innerText = this.upload_file.file_name
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`


      var kind = this.kindInputTarget.dataset.kind
      this.coverLinkTarget.innerHTML = progressSpan
      this.upload_file.kind = kind

      this.progressCount(0)
      this.requestUpload()

    }
  }

  requestUpload() {
    this.actionMode = `new`
    var formData = new FormData()
    formData.append('file', this.upload_file.file)
    formData.append('attached_field', this.upload_file.kind)
    formData.append('landing_id', this.application.landing.id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/commercial/marketing/landings/entities/save_cover"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          controller.application.landing = response.data.cln
          controller.application.field = response.data.cln.field
          
          controller.uploadSwitch(false)
          controller.doImagesHtml()
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  deleteFile(ev) {
    var fileId = ev.currentTarget.dataset.id
    var field = ev.currentTarget.dataset.field

    this.send_data = { current_user: {}, contract: {}, note: {} }

    this.send_data.contract.id = fileId
    this.send_data.contract.field = field

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var url = "/financials/products/purchases/contracts/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    // fetch(url, init)
    //   .then(response => response.json())
    //   .then(response => {
    //     if (response.save) {
    //       var file = response.data.cln
    //       controller.application.purchase.contracts.forEach(element => {
    //         if (element.id == file.id) {
    //           controller.application.purchase.contracts.splice(controller.application.purchase.contracts.indexOf(element), 1, file)
    //         }
    //       })
    //       controller.doImagesHtml()
    //     } else {
    //       controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
    //     }
    //   })
    //   .catch(error => {
    //     controller.getControllerByIdentifier("app--helpers--console").console(error)
    //     controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
    //   })

  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasKindInputTarget) {
        if (controller.uploadReady == false) {
          len += 1
        }
        
        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }
        
        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
