import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    this.application.rooms = []
    this.setBrowser()
  }

  setBrowser() {

    var userAgent = navigator.userAgent
    var browserName = ``

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }

    this.application.browserName = browserName
  }

  checkClick() {
    if (this.application.loginCount > 1500) {
      this.application.loginCount = 0
      this.renewalLogin()
      if (this.getControllerByIdentifier("app--communications--connection--login-modal")) {
        this.getControllerByIdentifier("app--communications--connection--login-modal").close()
      }
    }
  }

  renewalLogin() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/check_user_log_in"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.loginCount = 0
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}