import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "sidebar", "pipeflow", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu",
                    "content", "doneReport", "list", "6666", "7777", "8888", "9999",
                    "medclinicHeaderCard", "medbookingHeaderCard", "medfilingHeaderCard",
                    "medpayrollHeaderCard", "medreceivementHeaderCard", "medbilingHeaderCard", 
                    "medconsultingHeaderCard", "medreturnHeaderCard", "medoncallHeaderCard", "medoutsourcingHeaderCard",
                    "medloanHeaderCard", "medcontentHeaderCard"]

  connect() {
    this.controllerName = `operations--onboards--reports--dones`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.done_onboards = []
    this.tableRow = 9
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `started_at_to_time` }
    // this.setProductStageArray()

  }

  doDoneReportHTML() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row my-3">
                  <div class="col-2 px-2" data-${this.controllerName}-target="sidebar">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                  </div>
                  <div class="col-10 px-2">
                    <div class="row">
                      <div class="col-12 pl-2 pr-2" data-${this.controllerName}-target="list">
                        <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${loader}</div></div>
                            <div class="row my-2"><div class="col">${loader}</div></div>
                            <div class="row my-2"><div class="col">${loader}</div></div>
                          </div>
                          <div class="card-footer py-0">
                            <div class="row my-2"><div class="col">${loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.doneReportTarget.innerHTML = html)
      // resolve(controller.getControllerByIdentifier("operations--onboards--reports--main").purchasesTableTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.doSidebarHtml()
      controller.doIndexListHtml()
      controller.has_done_report = false
      if (controller.application.done_onboards) {
      }
    })
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-2 f-065">
                    <h6 class="card-title display-4 py-0 mb-0 f-085">Todos os Onboards Finalizados/Cancelados</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-2 d-flex align-items-center">

                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Início
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">
                                  Partes Envolvidas
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Tipo
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Produto
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Serviço
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Canal
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Status
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Finalização
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="finished_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="finished_at_to_time" data-mode="asc">north</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableRow, 9))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_onboards = []
    // if (this.hasFilter) {
    //   this.application.done_onboards.forEach(element => {
    //     if (element.product_name == this.filter) {
    //       current_onboards[current_onboards.length] = element
    //     }
    //   })
    // } else {
    //   current_onboards = this.application.done_onboards
    // }
    current_onboards = this.application.done_onboards

    if (this.sort.mode == `asc`) {
      var onboards = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_onboards, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var onboards = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_onboards, this.sort.field)
    }

    if (onboards.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(onboards.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (onboards[x] !== undefined) {
            internPage[internPage.length] = onboards[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: onboards.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""

    if (data == undefined || data == [] || data.length == 0) {


      if (this.has_done_report) {
        var tableText = `<span class="f-075 align-middle">Não há Onboards Finalizados/Cancelados para ${this.service_name_pretty} ${this.product_name_pretty}</span>`
      } else {
        var tableText = `<span class="f-075 align-middle">Escolha o Produto e o Tipo de Serviço para gerar o relatório</span>`
      }


      var noData = `</tr>
                      <td colspan="${this.tableRow}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        ${tableText}
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.onboardTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  onboardTablePartial(element) {
    element.name = ``
    element.parties.forEach(party => {
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="onboardPartyName">${party.party_name}</p>`
    })

    var onboardPath = `<button data-action="click->${this.controllerName}#goToShowPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Ir para Onboard</span>
                        </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.id}" data-slug="${element.slug}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${onboardPath}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_accrual_name_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_service_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.channel_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.finished_at_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToShowPage(ev) {
    var token = ev.currentTarget.closest(".itemRow").dataset.slug
    window.open(`/a/onboards/${token}`, `_blank`)
  }

  doSidebarHtml() {
    this.sidebarTarget.innerHTML = this.getControllerByIdentifier(`operations--onboards--reports--main`).doSidebarHtml(this.controllerName)
  }

  productFilter(ev) {
    this.product_name = ev.currentTarget.dataset.product

    this.application.product_main.forEach(product => {
      if (this.product_name == product.name) {
        this.product_name_pretty = product.name_pretty
        this.product_services = product.services
      }
    });

    var servicesHtml = ``
    this.product_services.forEach(service => {
      servicesHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="card pointer" data-service="${service.name}" data-action="click->${this.controllerName}#productDone" data-${this.controllerName}-target="${this.product_name}-${service.name}-Card">
                            <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="${this.product_name}-${service.name}-HeaderCard">
                              <span>${service.name_pretty}</span>
                            </div>
                          </div>
                        </div>
                      </div>`
    });

    servicesHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="pointer bg-outline-primary" data-action="click->${this.controllerName}#cancelProduct">
                            <div class="card-header p-1 text-center f-065 f-bold">
                              <span>Voltar</span>
                            </div>
                          </div>
                        </div>
                      </div>`


    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" data-${this.controllerName}-target="medclinicCard">
                      <div class="card-header p-1 text-center f-065 f-bold bg-primary" data-${this.controllerName}-target="medclinicHeaderCard">
                        <span>${this.product_name_pretty}</span>
                      </div>
                    </div>
                  </div>
                </div>
                ${servicesHtml}`

    this.sidebarTarget.innerHTML = html

  }

  cancelProduct() {
    this.doSidebarHtml()
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    this.application.done_onboards = []
    this.has_done_report = false
    this.doDataTable()
  }

  productDone(ev) {
    this.service_name = ev.currentTarget.dataset.service
    this.getDoneOnboards()

    this.product_services.forEach(service => {
      if (service.name == this.service_name) {
        this.service = service
        this.service_name_pretty = service.name_pretty
        this.nameTarget(`${this.product_name}-${this.service_name}-Card`).classList.add("bg-primary")
      } else {
        this.nameTarget(`${this.product_name}-${service.name}-Card`).classList.remove("bg-primary")
      }
    })
  }

  getDoneOnboards() {

    if (this.product_name == `medfiling`) {
      if (this.service_name == `light` || this.service_name == `basic` || this.service_name == `premium`) {
        this.plan_name = this.service_name
        var service_name = `opening`
      } else {
        var service_name = this.service_name
      }
    } else {
      this.plan_name = ``
      var service_name = this.service_name
    }
    
    const data = { onboard: { product_name: this.product_name, product_service: service_name, product_plan: this.plan_name, status: ["finished", "canceled"] }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/onboards/reports/list_by_status"
    // const url = "/operations/onboards/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        controller.application.done_onboards = response.data.cln
        controller.has_done_report = true
        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
