import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "view", "viewCard", "viewTitle", "alias", "viewDoctorCard", "viewDoctorCardBody"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--dashboards--show`
  }

  doDoctorHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewDoctorCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3 mr-auto" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                    <span class="mc-tooltip">
                      <span class="my-0 px-3 pointer" data-${this.controllerName}-target="closeDoctorCardBtn" data-action="click->${this.controllerName}#closeDoctorCard">
                        <span class="material-icons md-dark md-200">clear</span>
                      </span>
                      <span class="mc-tooltiptext">Fechar</span>
                    </span>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewDoctorCardBody">
                    <div class="row mt-3">
                      <div class="col-12 px-1">
                        <h6 class="mb-0 text-center f-075">${this.loader}</h6>
                        <hr class="m-1">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                    </div>
                    <div class="row">
                      <div class="col-12 px-1">
                        <h6 class="mb-0 text-center f-075">${this.loader}</h6>
                        <hr class="m-1">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewTarget.innerHTML = html)
    }).then(() => {
      controller.viewDoctorCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.viewTitleTarget.innerText = `${this.current_doctor.account_name} - ${this.current_doctor.account_cpf_pretty}`
      controller.readDoctorProducts()
    })
  }

  closeDoctorCard() {
    this.doAliasCardHtml()
  }

  doDoctorAlias() {

    if (this.current_doctor.has_tax_return || this.current_doctor.has_booking || this.current_doctor.has_tax_filing) {
      var individualTitle = `<div class="row mt-3">
                              <div class="col-12 px-1">
                                <h6 class="mb-0 text-center f-075">PF Médica</h6>
                                <hr class="m-1">
                              </div>
                            </div>`
    } else {
      var individualTitle = ``
    }

    var individualTitle = `<div class="row mt-3">
                              <div class="col-12 px-1">
                                <h6 class="mb-0 text-center f-075">PF Médica</h6>
                                <hr class="m-1">
                              </div>
                            </div>`

    if (this.current_doctor.has_tax_return) {
      var returnAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Planner</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/planner-anual/${this.current_doctor.tax_return_token}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">outlined_flag</span>
                              <span class="mc-tooltiptext">Planner</span>
                            </div>
                          </div>
                        </div>`
    } else {
      var returnAlias = ``
      var hasNoReturn = true
    }

    if (this.current_doctor.has_booking) {
      var bookingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Livro-Caixa</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/livro-caixa/${this.current_doctor.booking_token}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt_long</span>
                              <span class="mc-tooltiptext">Livro-Caixa</span>
                            </div>
                          </div>
                        </div>`
    } else {
      var bookingAlias = ``
      var hasNoBooking = true
    }

    if (this.current_doctor.has_tax_filing) {
      var filingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Declaração IRPF</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/declaracao-de-ajuste/${this.current_doctor.tax_filing_token}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt</span>
                              <span class="mc-tooltiptext">Declaração IRPF</span>
                            </div>
                          </div>
                        </div>`
    } else {
      var filingAlias = ``
      var hasNoFiling = true
    }

    if (hasNoReturn && hasNoBooking && hasNoFiling) {
      var hasNoPersonalProductHtml = `<div class="col-12 text-center my-4">
                                        <span class="f-075 f-bold">Não possui Produto para PF Médica</span>
                                      </div>`
    } else {
      var hasNoPersonalProductHtml = ``
    }

    var legalAlias = ``
    if (this.current_doctor.clinic_ids.length > 0) {
      this.current_doctor.clinics.forEach(clinic => {
        var legalTitle = `<div class="row mt-3">
                              <div class="col-12 px-1">
                                <h6 class="mb-0 text-center f-075">PJ Médica - ${clinic.company_name}</h6>
                                <hr class="m-1">
                              </div>
                            </div>`

        var invoiceAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>NF & Apurações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/nfs-e-apuracoes/${clinic.clinic_token}" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">price_change</span>
                              <span class="mc-tooltiptext">NF & Apurações</span>
                            </div>
                          </div>
                        </div>`
        var clinicAlias = `<div class="col-4 px-1 my-2">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-065">
                            <span>Regularização</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/pjs-medicas/${clinic.clinic_token}" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">business</span>
                            <span class="mc-tooltiptext">Regularização</span>
                          </div>
                        </div>
                      </div>`
        var clientAlias = `<div class="col-4 px-1 my-2">
                              <div class="card border-top-primary">
                                <div class="card-header p-1 text-center f-065">
                                  <span>Cliente</span>
                                </div>
                                <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/pj-medica/${clinic.clinic_token}" data-action="click->${this.controllerName}#goToURL">
                                  <span class="material-icons md-dark">summarize</span>
                                  <span class="mc-tooltiptext">Visão do Médico</span>
                                </div>
                              </div>
                            </div>`


        legalAlias += `${legalTitle}
                      <div class="row mb-3">
                        ${invoiceAlias}
                        ${clinicAlias}
                        ${clientAlias}
                      </div>`

      })
      
    } else {
      var legalTitle = `<div class="row mt-3">
                        <div class="col-12 px-1">
                          <h6 class="mb-0 text-center f-075">PJ Médica</h6>
                          <hr class="m-1">
                        </div>
                      </div>`
      var invoiceAlias = ``
      var clinicAlias = ``
      var clientAlias = ``

      legalAlias += `${legalTitle}
                      <div class="row mb-3">
                        <div class="col-12 text-center my-4">
                          <span class="f-075 f-bold">Não possui Produto PJ Médica</span>
                        </div>
                      </div>`
    }

    var html = `${individualTitle}
                <div class="row mb-3">
                  ${returnAlias}
                  ${filingAlias}
                  ${bookingAlias}
                  ${hasNoPersonalProductHtml}
                </div>
                ${legalAlias}`

    this.viewDoctorCardBodyTarget.innerHTML = html
  }

  doAliasCardHtml() {

    var onboardAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Onboard</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/onboards" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">flight_land</span>
                              <span class="mc-tooltiptext">Onboard</span>
                            </div>
                          </div>
                        </div>`

    var ongoingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Ongoing</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/relacionamento-base" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">connecting_airports</span>
                              <span class="mc-tooltiptext">Ongoing</span>
                            </div>
                          </div>
                        </div>`

    var callsAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Chamados</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/chamados" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">build_circle</span>
                              <span class="mc-tooltiptext">Chamados</span>
                            </div>
                          </div>
                        </div>`

    var bookingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Livro-Caixa</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/livro-caixa" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt_long</span>
                              <span class="mc-tooltiptext">Livro-Caixa</span>
                            </div>
                          </div>
                        </div>`

    var returnAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Planner</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/planner-anual" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">outlined_flag</span>
                              <span class="mc-tooltiptext">Planner</span>
                            </div>
                          </div>
                        </div>`

    var filingAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Declaração IRPF</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/declaracao-de-ajuste" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt</span>
                              <span class="mc-tooltiptext">Declaração IRPF</span>
                            </div>
                          </div>
                        </div>`

    // if (this.application.permissions.medfat.can_index) {
    //   var receivementAlias = `<div class="col-4 px-1 my-2">
    //                             <div class="card border-top-primary">
    //                               <div class="card-header p-1 text-center f-065">
    //                                 <span>Recebimento</span>
    //                               </div>
    //                               <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/recebimentos" data-action="click->${this.controllerName}#goToURL">
    //                                 <span class="material-icons md-dark">account_balance_wallet</span>
    //                                 <span class="mc-tooltiptext">Recebimento</span>
    //                               </div>
    //                             </div>
    //                           </div>`
    // } else {
    //   var receivementAlias = ``
    // }

    // `<div class="row mt-3">
    //   <div class="col-12 px-1">
    //     <h6 class="mb-0 text-center f-075">Faturamento Médico</h6>
    //     <hr class="m-1">
    //   </div>
    // </div>
    // <div class="row">
    //   ${registrationAlias}
    //   ${bilingAlias}
    //   ${receivementAlias}
    // </div>`

    var invoiceAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>NF & Apurações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/nfs-e-apuracoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">price_change</span>
                              <span class="mc-tooltiptext">NF & Apurações</span>
                            </div>
                          </div>
                        </div>`

    var clinicAlias = `<div class="col-4 px-1 my-2">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-065">
                            <span>Regularização</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/pjs-medicas" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">business</span>
                            <span class="mc-tooltiptext">Regularização</span>
                          </div>
                        </div>
                      </div>`

    var clientAlias = `<div class="col-4 px-1 my-2">
                          <div class="card border-top-primary">
                            <div class="card-header p-1 text-center f-065">
                              <span>Cliente</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/pj-medica" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">summarize</span>
                              <span class="mc-tooltiptext">Visão do Médico</span>
                            </div>
                          </div>
                        </div>`

    var managementAlias = `<div class="col-4 px-1 my-2">
                            <div class="card border-top-primary">
                              <div class="card-header p-1 text-center f-065">
                                <span>Controle e Integrações</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/controle-pjs-medicas" data-action="click->${this.controllerName}#goToURL">
                                <span class="material-icons md-dark">room_preferences</span>
                                <span class="mc-tooltiptext">Gestão, Controle e Integrações</span>
                              </div>
                            </div>
                          </div>`

    var bilingAlias = `<div class="col-4 px-1 my-2">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-065">
                            <span>Faturamento</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip">
                            <span class="material-icons md-dark">request_quote</span>
                            <span class="mc-tooltiptext">Faturamento</span>
                          </div>
                        </div>
                      </div>`

    var registrationAlias = `<div class="col-4 px-1 my-2">
                                <div class="card border-top-primary">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>Credenciamento</span>
                                  </div>
                                  <div class="card-body text-center py-2 mc-tooltip">
                                    <span class="material-icons md-dark">app_registration</span>
                                    <span class="mc-tooltiptext">Credenciamento</span>
                                  </div>
                                </div>
                              </div>`

    var oncallsAlias = `<div class="col-4 px-1 my-2">
                                <div class="card border-top-primary">
                                  <div class="card-header p-1 text-center f-065">
                                    <span>Gestão de Plantões</span>
                                  </div>
                                  <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/gestao-de-plantoes" data-action="click->${this.controllerName}#goToURL">
                                    <span class="material-icons md-dark">local_hospital</span>
                                    <span class="mc-tooltiptext">Gestão de Plantões</span>
                                  </div>
                                </div>
                              </div>`

    var oncallsAlias = ``
    var callsAlias = ``

    var html = `<div class="row">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center f-075">Relacionamento Base</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row">
                  ${callsAlias}
                  ${onboardAlias}
                  ${ongoingAlias}
                </div>
                <div class="row">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center f-075">PF Médica</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row">
                  ${returnAlias}
                  ${filingAlias}
                  ${bookingAlias}
                </div>
                <div class="row mt-3">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center f-075">PJ Médica</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row">
                  ${clinicAlias}
                  ${invoiceAlias}
                  ${managementAlias}
                  ${clientAlias}
                </div>
                <div class="row mt-3">
                  <div class="col-12 px-1">
                    <h6 class="mb-0 text-center f-075">Faturamento Médico</h6>
                    <hr class="m-1">
                  </div>
                </div>
                <div class="row">
                  ${oncallsAlias}
                </div>`

    this.aliasTarget.innerHTML = html
  }

  readDoctorProducts() {
    const data = { account: { account_id: this.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `operations_main_dashboard_accounts` } }
    const url = "/operations/accounts/products/read_with_account"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.current_doctor = response.data.cln

        controller.doDoctorAlias()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewDoctorCard"]
    var identifier = `operations--dashboards--show`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}