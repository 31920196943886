import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "list", "mainCard", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--dashboards--dashboard`
    this.application.permissions = {}
    this.application.chart_accounts = []
    this.application.team_list = []
    this.application.receivables = []
    this.application.clinics = []

    this.doMainDashboard()

    // 1 - this.getCurrentUserPermissions
    // 2 - this.getProducts

  }

  doMainDashboard() {
    var html = `<div class="row my-3">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="row" data-operations--dashboards--indicators-target="indicators" data-controller="operations--dashboards--indicators">
                          <div class="col-2">${this.cardLoader}</div>
                          <div class="col-2">${this.cardLoader}</div>
                          <div class="col-2">${this.cardLoader}</div>
                          <div class="col-2">${this.cardLoader}</div>
                          <div class="col-2">${this.cardLoader}</div>
                          <div class="col-2">${this.cardLoader}</div>
                        </div>
                      </div>
                      <div class="col-12 px-0 mt-3" data-operations--dashboards--list-target="list" data-controller="operations--dashboards--list">
                        <div class="card" style="width:100%;display:relative;">
                          <div class="card-header py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>  
                          </div>
                          <div class="card-body">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                          <div class="card-footer py-0">
                            <div class="row my-2"><div class="col">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4" data-operations--dashboards--show-target="alias view" data-controller="operations--dashboards--show">
                    <div class="row my-2">
                      ${this.loader}
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--dashboards--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--dashboards--list`).dataLoaded = true
      controller.getControllerByIdentifier(`operations--dashboards--list`).doIndexListHtml()
      controller.getControllerByIdentifier(`operations--dashboards--indicators`).doIndicatorsCalculation()
      controller.getControllerByIdentifier(`operations--dashboards--show`).doAliasCardHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}