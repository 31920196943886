import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--statements--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.permissions = {}
    this.application.balances = []
    this.application.daily_balances = []
    this.application.transactions = []
    this.application.chart_accounts = []
    this.application.channels = []
    this.application.meds = []
    this.application.providers = []
    this.application.med = {}
    this.doMainGrid()
  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doCalculationCards() {
    this.getControllerByIdentifier("financials--books--statements--dashboard--balance").doBalanceCards()
    this.getControllerByIdentifier("financials--books--statements--dashboard--results").doResultdsCards()
  }

  doPageGrid() {
    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 px-2">
                    <div class="row my-2 w-100" data-controller="financials--books--statements--dashboard--balance">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center pointer" data-financials--books--statements--dashboard--balance-target="title">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-financials--books--statements--dashboard--balance-target="board">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="row my-2 w-100" data-controller="financials--books--statements--dashboard--results">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center pointer" data-financials--books--statements--dashboard--results-target="title">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-financials--books--statements--dashboard--results-target="board">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.current_date) {
        controller.doCalculationCards()
      }
    })
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--books--statements--balances--dashboard
                                                            financials--books--statements--transactions--dashboard
                                                            financials--books--statements--daily--dashboard
                                                            financials--books--cash-flows--compilations--dashboard" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doPageGrid()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    if (this.reload) {
      this.getControllerByIdentifier("financials--books--statements--dashboard--dates").runDashboardsHtml()
      // this.getControllerByIdentifier("financials--books--statements--dashboard--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("financials--books--statements--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("financials--books--statements--dashboard--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("financials--books--statements--dashboard--meds").doMedHtml()
    }
    this.application.current_date.data_loaded = true

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getTransactions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Transações`, value: 80 })
    const data = { transaction: { active: true, date_id:this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_transactions` } }
    const url = "/financials/books/statements/transactions/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var application_data = { date_id: response.data.cln.date_id, data: response.data.cln.collection }
          controller.application.transactions[controller.application.transactions.length] = application_data 
        }
        
        controller.finishLoadPage()
        controller.application.current_date.data_loaded = true
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getBalances() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Balanços`, value: 70 })
    const data = { balance: { active: true, date_id:this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_balances` } }
    const url = "/financials/books/statements/balances/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.balances[controller.application.balances.length] = element
          })
        }
        
        controller.getTransactions()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
      }
      
      this.getBalances()
    } else {
      this.getControllerByIdentifier("financials--books--statements--dashboard--dates").setCurrents()
      this.getControllerByIdentifier("financials--books--statements--dashboard--dates").runDashboardsHtml()
    }
  }

  getFinancialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 60 })
    const data = { date: { active: true, subdomain: `statements` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_dates` } }
    const url = "/financials/config/dates/list_with_subdomain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        });

        // controller.application.dates = response.data.cln
        // controller.application.current_date = response.data.cln[0]
        // controller.application.current_payable_compilation = response.data.cln[0].payable_compilation

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]
        controller.application.current_payable_compilation = currentDates[0].payable_compilation

        controller.getControllerByIdentifier("financials--books--statements--dashboard--dates").doDatesHTML()
        controller.loadDateData()
        // controller.getControllerByIdentifier("commercial--dashboards--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_chart_accounts` } }
    const url = "/financials/books/statements/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.chart_accounts = response.data.cln
        }
        
        controller.getFinancialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getProviders() {
    const data = { provider: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_providers` } }
    const url = "/financials/books/statements/providers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.providers = response.data.cln
        }

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Conta`, value: 50 })
        controller.getChartAccounts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    const data = { channel: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_channels` } }
    const url = "/financials/books/statements/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.channels = response.data.cln
        }

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 40 })
        controller.getProviders()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_meds` } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
          controller.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 30 })
        controller.getChannels()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_book_statements` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statements_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 20 })
        controller.getMeds()
        // controller.getControllerByIdentifier("financials--books--statements--dashboard--submenu").doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshCompilation() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { compilation: { date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_statement_compilations` } }
    const url = "/financials/books/statements/compilations/refresh"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var statement_calculations = response.data.cln.statement_calculations
          var statement_compilation = response.data.cln.statement_compilation
          var cash_flow_calculations = response.data.cln.cash_flow_calculations
          var cash_flow_compilation = response.data.cln.cash_flow_compilation

          controller.application.dates.forEach(date => {
            if (date.id == statement_compilation.date_id) {
              date.statement_calculations = statement_calculations
              date.statement_compilation = statement_compilation
              date.cash_flow_calculations = cash_flow_calculations
              date.cash_flow_compilation = cash_flow_compilation
            }
          })

          if (controller.application.current_med.id) {
            controller.application.current_date.statement_calculations.forEach(calculation => {
              if (calculation.med_id == controller.application.current_med.id) {
                controller.calculation = calculation
              }
            })
          } else {
            controller.calculation = controller.application.current_date.statement_compilation
          }

          if (controller.getControllerByIdentifier(`financials--books--statements--dashboard--results`) && controller.getControllerByIdentifier(`financials--books--statements--dashboard--balance`)) {
            controller.getControllerByIdentifier(`financials--books--statements--dashboard--results`).doResultdsCards()
            controller.getControllerByIdentifier(`financials--books--statements--dashboard--balance`).doBalanceCards()  
          } else if (controller.getControllerByIdentifier(`financials--books--cash-flows--compilations--index`)) {
            controller.getControllerByIdentifier("financials--books--cash-flows--compilations--index").doIndexListHtml()
          }
          
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cardGridPreloader() {
    var html = `<div class="col-12 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-12 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
