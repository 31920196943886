import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "certificatesBodyTable", "footerTable", "body", "companies", "certificates",
                    "companiesCard", "companies", "med", "companiesBodyTable", "certificatesCard", "addIntegrationBtn", "7777", "8888", "9999",
                    "certificatesCardBody", "4444", "5555", "addIntegrationBtn",
                    "assignCertificateDiv", "assignDropdown", "assignDropdownBtn", "assignInput", "assignList"]

  connect() {
    this.controllerName = `financials--books--dashboards--meds--show-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.companiesTableCols = 7
    this.certificateTableCols = 6
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.bodyTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.setCurrentMed()
      controller.doCards()
    })
  }

  close() {
    this.stopSpin()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doCards() {
    this.doCompaniesCard()
    this.doCertificatesCard()
  }

  setCurrentMed() {
    this.application.meds.forEach(element => {
      if (element.id == this.medId) {
        this.current_med = element
      }
    })

    var html = `<strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.current_med.company_trade_name}" data-action="click->app--helpers--copy#copy">${this.current_med.company_trade_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>
                <span> | CNPJ: </span>
                <strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.current_med.cnpj}" data-action="click->app--helpers--copy#copy">${this.current_med.cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>`

    this.medTarget.innerHTML = html
  }

  addIntegration() {
    this.actionMode = "new"
    this.saveCompany()
  }

  saveCompany() {
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }

    this.send_data.company.med_id = this.current_med.id
    this.send_data.company.cnpj = this.current_med.cnpj
    this.send_data.company.integration_type = `nfeio`
    this.send_data.company.integration_kind = `invoice`
    this.send_data.company.environment = `development`
    this.send_data.company.rps_number = 0

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_integration_meds`

    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = `created`

    this.requestSave()
  }

  updateIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.current_med.company_name
    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_integration_meds`

    var url = "/financials/integrations/accountings/companies/update_integration"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.current_med.integration_company = integration_company

          controller.doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  createIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.current_med.company_name
    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = `created`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_integration_meds`

    var url = "/financials/integrations/accountings/companies/create_integration"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.current_med.integration_company = integration_company

          controller.doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateEnvironment(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "destroy"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(".itemRow").dataset.id
    var environment = ev.currentTarget.dataset.environment

    this.send_data.company.id = integrationId
    this.send_data.company.environment = environment
    this.send_data.company.clinic_name = this.current_med.company_name
    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_integration_meds`

    var url = "/financials/integrations/accountings/companies/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.current_med.integration_company = integration_company

          controller.doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyIntegration(ev) {
    var r = confirm(`Tem certeza que deseja Apagar a Integração?`)
    if (r) {
      // this.doDestroyIntegration(ev)
    }
  }

  doDestroyIntegration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.actionMode = "edit"
    this.send_data = { current_user: {}, company: {}, tracker: { log: true } }
    var integrationId = ev.currentTarget.closest(".itemRow").dataset.id
    var environment = ev.currentTarget.dataset.environment

    this.send_data.company.id = integrationId
    this.send_data.company.clinic_name = this.current_med.company_name
    this.send_data.tracker.record_id = this.current_med.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.action = `destroyed`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_integration_meds`

    var url = "/financials/integrations/accountings/companies/destroy_integration"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.current_med.integration_company = integration_company

          controller.doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  requestSave() {
    // this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var url = "/financials/integrations/accountings/companies/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var integration_company = response.data.cln
          controller.current_med.integration_company = integration_company

          controller.doCards()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doCompaniesCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="companiesCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Integrações de Empresas</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="addIntegrationBtn" data-action="click->${this.controllerName}#addIntegration" type="button">
                        <span class="material-icons md-dark">add</span>
                      </button>
                      <span class="mc-tooltiptext">Adicionar Integração</span>
                    </div>
                  </div>
                  <div class="card-body py-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Integração</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Ambiente</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Fiscal</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Certificado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle text-center">RPS</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center">Integração</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="companiesBodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.companiesTarget.innerHTML = html)
    }).then(() => {
      controller.companiesCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.companiesBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.companiesTableCols, 10))
      controller.doCompaniesDataTable()
      if (controller.current_med.integration_company) {
        controller.addIntegrationBtnTarget.remove()
      }
    })
  }

  doCompaniesDataTable() {

    this.companiesListData([this.current_med.integration_company])

  }

  companiesListData(data) {
    this.companiesBodyTableTarget.innerHTML = ""
    if (data == undefined || data[0] == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.companiesTableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Integrações</span>
                      </td>
                    </tr>`

      this.companiesBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.companiesBodyTableTarget.insertAdjacentHTML("beforeend", this.companyTablePartial(element, data.length))
      });
    }
  }

  companyTablePartial(element, length) {
    if (this.application.permissions.financial_settings_integration_meds.can_update && element.certificate_status == `active_certificate`) {
      if (element.environment == `development`) {
        var environmentStatus = `<button data-action="click->${this.controllerName}#updateEnvironment" data-environment="production" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">code</span>
                                  <span class="mc-tooltiptext">${element.environment_pretty}</span>
                                </button>`
      } else if (element.environment == `production`) {
        if (this.application.current_user.super_admin) {
          var environmentStatus = `<button data-action="click->${this.controllerName}#updateEnvironment" data-environment="development" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">check_circle</span>
                                  <span class="mc-tooltiptext">${element.environment_pretty}</span>
                                </button>`
        } else {
          var environmentStatus = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-150 md-white default">check_circle</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
        }

      }
    } else {
      if (element.environment == `development`) {
        var environmentStatus = `<span class="mc-tooltip badge badge-primary"><span class="material-icons md-150 md-white default">code</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
      } else if (element.environment == `production`) {
        var environmentStatus = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-150 md-white default">check_circle</span><span class="mc-tooltiptext">${element.environment_pretty}</span></span>`
      } else {
        var environmentStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
      }
    }

    if (element.fiscal_status_pretty) {
      var fiscalStatus = element.fiscal_status_pretty
    } else {
      var fiscalStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
    }

    if (element.certificate_status_pretty) {
      var certificateStatus = element.certificate_status_pretty
    } else {
      var certificateStatus = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-150 md-white default">clear</span><span class="mc-tooltiptext">Não Integrado</span></span>`
    }

    if (element.integration_id) {
      if (this.application.current_user.super_admin) {
        var destroyIntegration = `<button data-action="click->${this.controllerName}#destroyIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">clear</span>
                                    <span class="mc-tooltiptext">Apagar Integração</span>
                                  </button>`
      } else {
        var destroyIntegration = ``
      }

      var integrationName = `<span class="mc-tooltip pointer">
                              <span data-controller="app--helpers--copy" data-copy="${element.integration_id}" data-action="click->app--helpers--copy#copy">${element.integration_type_pretty}</span>
                              <span class="mc-tooltiptext">Clique para copiar</span>
                            </span>`

      var integrationStatus = `<span class="mc-tooltip badge badge-success"> 
                                <span class="material-icons md-200 md-white default">domain_verification</span>
                                <span class="mc-tooltiptext">Integrado</span>
                              </span>
                              <button data-action="click->${this.controllerName}#updateIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">refresh</span>
                                <span class="mc-tooltiptext">Clique para Atualizar</span>
                              </button>
                              ${destroyIntegration}`
    } else {
      var integrationName = `${element.integration_type_pretty}`

      var integrationStatus = `<button data-action="click->${this.controllerName}#createIntegration" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">launch</span>
                                <span class="mc-tooltiptext">Clique para Integrar</span>
                              </button>`
    }

    // integrationStatus = ``
    // environmentStatus = ``

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:75px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${integrationName}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${environmentStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${fiscalStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${certificateStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier(`app--helpers--numbers`).numberMask(element.rps_number)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${integrationStatus}</td>
                  </tr>`

    return rowHtml
  }

  doCertificatesCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="certificatesCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Integrações de Certificados</h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="certificatesCardBody">
                    <div class="row">
                      <div class="col-12 p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Integração</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-50 align-middle text-center" colspan="2">Certificado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle text-center" colspan="2">Ação</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="certificatesBodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.certificatesTarget.innerHTML = html)
    }).then(() => {
      controller.certificatesCardTarget.style.height = ($(window).height() * 0.3) + "px"
      controller.certificatesBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.certificateTableCols, 10))
      controller.doCertificatesDataTable()
    })
  }

  doCertificatesDataTable() {

    this.certificatesListData([this.current_med.integration_company])

  }

  certificatesListData(data) {
    this.certificatesBodyTableTarget.innerHTML = ""
    if (data == undefined || data[0] == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.certificateTableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Integrações</span>
                      </td>
                    </tr>`

      this.certificatesBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.certificatesBodyTableTarget.insertAdjacentHTML("beforeend", this.certificateTablePartial(element, data.length))
      });
    }
  }

  certificateTablePartial(integration_company, length) {
    var element = integration_company.certificate

    if (element.status_pretty) {
      var certificateStatus = element.status_pretty
      // var certificateStatus = `<span class="mc-tooltip badge badge-success">
      //                           <span class="material-icons md-150 md-white default">done</span>
      //                           <span class="mc-tooltiptext">${element.status_pretty}</span>
      //                         </span>`
    } else {
      var certificateStatus = `<span class="mc-tooltip badge badge-danger">
                                <span class="material-icons md-150 md-white default">clear</span>
                                <span class="mc-tooltiptext">Não Integrado</span>
                              </span>`
    }
    var fiscalStatus = ``

    if (element.certificate_id && element.status == "active_certificate") {
      var actionStatus = `<span class="mc-tooltip badge badge-success">
                            <span class="material-icons md-200 md-white default">domain_verification</span>
                            <span class="mc-tooltiptext">Integrado</span>
                          </span>`
    } else if (element.certificate_id && element.status != "active_certificate") {
      if (integration_company.integration_id) {
        var actionStatus = `<button data-action="click->${this.controllerName}#uploadCertificate" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">file_upload</span>
                            <span class="mc-tooltiptext">Upload Certificado</span>
                          </button>`
      } else {
        var actionStatus = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">hourglass_empty</span>
                              <span class="mc-tooltiptext">Aguardando Integração NFe.io</span>
                            </button>`
      }
    } else {
      var actionStatus = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">hourglass_empty</span>
                            <span class="mc-tooltiptext">Aguardando Atribuição</span>
                          </button>`
    }

    var assignAction = `<button data-action="click->${this.controllerName}#assignCertificate" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                            <span class="material-icons md-sm md-dark">post_add</span>
                            <span class="mc-tooltiptext">Atribuir Certificado</span>
                          </button>`


    if (element.certificate_id) {
      var certificateName = `<span class="mc-tooltip">
                                ${element.certificate_name}
                                <span class="mc-tooltiptext">${element.certificate_kind_pretty} | Venc.: ${element.expiration_date_pretty}</span>
                              </span>`
    } else {
      var certificateName = `Aguardando Atribuição`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:75px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.integration_kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center default">${certificateName}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${certificateStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${actionStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${assignAction}</td>
                  </tr>`

    return rowHtml
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}