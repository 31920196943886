import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["list", "input", "characterCount", "comment", "attachFile"]

  connect() {
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  characterCount() {
    var count = this.inputTarget.value.length
    if (count == 1) {
      var message = "1 caractere"
    } else {
      var message = `${count} caracteres`
    }

    this.characterCountTarget.textContent = message

    if (count > 140) {
      this.characterCountTarget.classList.add("text-danger")
    } else {
      this.characterCountTarget.classList.remove("text-danger")
    }
  }

  clickLink(e) {
    var link = e.target.innerText
    if (e.target.innerText.includes("http")) {
      window.open(link, '_blank')
    } else if (e.target.innerText.includes("www")) {
      window.open('https://' + link, '_blank')
    }
  }

  save(ev) {
    var body = this.inputTarget.value
    var count = this.inputTarget.value.length
    if (count > 140) {
      ev.preventDefault()
    } else {
      if (((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") && count > 0) {
        const task_id = this.taskId
        const created_at = new Date()
        const created_at_pretty = this.getCurrentDate(created_at)
        const data = { comment: { task_id: task_id, body: body, user_id: currentUser.id }, current_user: { current_user_id: currentUser.id } }
        this.requestCreate(data)
      } else if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
        this.inputTarget.value = ""
      }
    }
  }

  downloadAttach() {
    const url = this.attachFileTarget.dataset.url
    window.open(url, "_blank")
  }

  attach() {
    document.querySelector(`#newTaskBtn`).usersTasksBoardModal.modalHtml("attach", this.taskId)
  }

  requestCreate(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/comments/create"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          const html = this.commentPartial(response.data.cln)
          this.listTarget.insertAdjacentHTML("beforeend", html)
          this.inputTarget.value = ""
          this.characterCountTarget.textContent = ""
          this.rollMessages()
          tooltip()
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }

  commentPartial(comment) {
    if (comment.body.includes("http") || comment.body.includes("www")) {
      var commentHtml = `<span class="text-scroll text-left pointer" style="overflow:auto;" data-action="click->users--tasks--board--comment#clickLink">${comment.body}</span>`
    } else {
      var commentHtml = `<span class="text-scroll text-left" style="overflow:auto;" data-action="click->users--tasks--board--comment#clickLink">${comment.body}</span>`
    }
    var html = `<div class="row my-1 py-1">`
                    if (comment.has_attach) {
              html += `<div class="col-9 px-1 d-flex align-items-center">${commentHtml}</div>        
                      <div class="col-1 px-1 d-flex align-items-center">
                        <span class="material-icons md-sm pointer" data-action="click->users--tasks--board--comment#downloadAttach" data-users--tasks--board--comment-target="attachFile" data-url=${comment.attach_url}>cloud_download</span>
                      </div>`
                        
                      } else {
              html += `<div class="col-10 px-1 d-flex align-items-center">${commentHtml}</div>`
                      }
              html += `<div class="col-2 px-0 text-center my-auto">
                      <span class="d-block">${comment.created_at_pretty}</span>
                      <span class="material-icons md-sm" data-toggle="tooltip" data-placement="top" title data-original-title="${comment.user_name}">face</span>
                    </div>           
                  </div>`
    
    return html
  }

  rollMessages() {
    const task_id = this.taskId
    $("#commentBody-" + task_id).stop().animate({ scrollTop: $("#commentBody-" + task_id)[0].scrollHeight }, 1000);
  }

  getCurrentDate(date) {
    const month = [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez" ]

    if (date.getDate() < 10) {
      return `0${date.getDate()}/${month[date.getMonth()]}`
    } else {
      return `${date.getDate()}/${month[date.getMonth()]}`
    }
  }

  get taskId() {
    return parseInt(this.data.get("taskId"))
  }

}