import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName =`mobile--operations--products--tax-filings--show--broadcast`
    this.application.tax_filing_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.taxFilingUpdateChannel = createChannel({ channel: 'Operations::Products::TaxFilingChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        if (controller.getControllerByIdentifier("app--navigation--desktop--navbar")) {
          controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
        }
      },
      received(data) {
        if (data.kind == "calculation") {
          controller.application.tax_filing_calculations.forEach(element => {
            if (element.id == data.cln.id) {
              controller.application.tax_filing_calculations.splice(controller.application.tax_filing_calculations.indexOf(element), 1, data.cln)
              controller.application.current_tax_filing_calculation = data.cln
            }
          })

          if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--files--add").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("mobile--operations--products--tax-filings--files--add").doAddDashboard()
          }

          if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--files--list").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("mobile--operations--products--tax-filings--files--list").doListDashboard()
          }

          if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--journeys").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--journeys").doJourneyDashboard()
          }

          if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--tickets").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--tickets").doTicketDashboard()
          }

        } else if (data.kind == "journey") {
          controller.application.journeys = data.cln

          if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--journeys").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("mobile--operations--products--tax-filings--management--journeys").doJourneyDashboard()
          }
        }

        if (controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
        }
      },
      disconnected() {
        if (controller.getControllerByIdentifier("app--navigation--desktop--navbar")) {
          controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
        }
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.taxFilingChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        if (controller.getControllerByIdentifier("app--navigation--desktop--navbar")) {
          controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
        }
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        if (controller.getControllerByIdentifier("app--navigation--desktop--navbar")) {
          controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
        }
      },
      appear() {
        this.perform('appear')
      },
    });
  }

  updateListen() {
    if (this.taxFilingUpdateChannel) {
      this.taxFilingUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.taxFilingChatChannel) {
      this.taxFilingChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
