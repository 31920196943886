import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "receivement", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.preloaderDashboard()
    this.getAccountProduct()
  }

  goToReceivement() {
    var url = `/d/recebimentos/${this.application.products.receivement_token}`
    window.open(url, `_self`)
  }

  goToTaxFiling() {
    var url = `/d/declaracao-de-ajuste/${this.application.products.tax_filing_token}`
    window.open(url, `_self`)
  }

  goToBooking() {
    var url = `/d/livro-caixa/${this.application.products.booking_token}`
    window.open(url, `_self`)
  }

  doDashboard() {

    if (this.application.products.has_tax_return) {
      var taxReturnHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip">`
    } else {
      var taxReturnHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    if (this.application.products.has_tax_filing) {
      var taxFilingHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip" data-action="click->mobile--users--dashboards--doctor#goToTaxFiling">`
    } else {
      var taxFilingHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    if (this.application.products.has_billing) {
      var billingHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip">`
    } else {
      var billingHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    if (this.application.products.has_booking) {
      var bookingHtml = `<figure class="figure btn btn-flat text-center f-070 p-1 mc-tooltip" data-action="click->mobile--users--dashboards--doctor#goToBooking">`
    } else {
      var bookingHtml = `<figure class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    if (this.application.products.has_clinic) {
      var legalHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip">`
    } else {
      var legalHtml = `<figure disabled class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    if (this.application.products.has_receivement) {
      var receivementHtml = `<figure class="figure btn btn-flat text-center f-070 p-1 mc-tooltip" data-action="click->mobile--users--dashboards--doctor#goToReceivement">`
    } else {
      var receivementHtml = `<figure class="figure btn btn-flat text-center f-070 p-1 mc-tooltip disabled">`
    }

    var html = `<div class="row mt-3">
                  <div class="col-12">
                    <h5>${this.application.user_name}, esse é o seu Dashboard MedCapital</h5>
                  </div>
                </div>
                <div class="row dashboard-content mt-3">
                  <div class="col-12">
                    <h6>Minha PF Médica</h6>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      ${taxFilingHtml}
                        <span class="material-icons md-primary md-400">receipt</span>
                        <figcaption class="figure-caption f-1" style="white-space: pre-line;">
                          Minhas Declarações
                        </figcaption>
                        <span class="mc-tooltiptext">Minhas Declarações IRPF</span>
                      </figure>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      ${bookingHtml}
                        <span class="material-icons md-primary md-400">receipt_long</span>
                        <figcaption class="figure-caption f-1" style="white-space: pre-line;">
                          Meu Livro-Caixa
                        </figcaption>
                        <span class="mc-tooltiptext">Meu Livro-Caixa</span>
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content mt-3">
                  <div class="col-12">
                    <h6>Minha(s) PJ(s) Médica(s)</h6>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      ${legalHtml}
                        <span class="material-icons md-primary md-400">local_atm</span>
                        <figcaption class="figure-caption f-1" style="white-space: pre-line;">
                          Minha(s) PJ(s)
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content my-3">
                  <div class="col-12">
                    <h6>Meu Faturamento Médico e Recebimentos</h6>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      ${billingHtml}
                        <span class="material-icons md-primary md-400">local_atm</span>
                        <figcaption class="figure-caption f-1" style="white-space: pre-line;">
                          Meu Faturamento
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card card-products-mobile">
                      ${receivementHtml}
                        <span class="material-icons md-primary md-400">local_atm</span>
                        <figcaption class="figure-caption f-1" style="white-space: pre-line;">
                          Meus Recebimentos
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getAccountProduct() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/accounts/products/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.doDashboard()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  preloaderDashboard() {
    var html = `<div class="row" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

}
