import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "preloaderVideoCard", "title", "info", "liveMeeting", "navbarSpacing", "preloadCheckInCard", "totalMeeting",
                    "streamingsCard", "chatCard", "streamingsRow", "chatRow", "showArea"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `mobile--commercial--marketing--events--live--dashboard`
    this.application.event_token = location.pathname.split("/").pop()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    this.application.tickets = []
    this.application.messages = []
    if (this.application.current_user_id != null || this.application.current_user_id != undefined) {
      this.application.public_view = false
    } else {
      this.application.public_view = true
    }
    this.doCheckInPreload()
    // this.setNavbarSpacing()
    // window.onbeforeunload = function () {
    //   return "Do you really want to close?";
    // }
  }

  doCheckInPreload() {
    var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                </div>
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-10">
                    <div class="stepper-horiz">
                      <div class="stepper mc-stepper active">
                        <div class="text-center w-100">
                          <span class="material-icons md-primary">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                      <div class="stepper mc-stepper">
                        <div class="text-center w-100">
                          <span class="material-icons">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                      
                      <div class="stepper mc-stepper">
                        <div class="text-center w-100">
                          <span class="material-icons">${this.loader}</span>
                        </div>
                        <span class="stepper-text">${this.loader}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-5" data-${this.controllerName}-target="showArea">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="card card-live w-100" data-step="0" data-${this.controllerName}-target="preloadCheckInCard">
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <div class="row w-100">
                          <div class="col-12 my-4 text-center px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 my-4 px-0">
                            ${this.loader}
                            ${this.loader}
                          </div>
                          <div class="col-12 d-flex align-items-center justify-content-center mt-5 my-3">
                            ${this.loader}
                            ${this.loader}
                            <div class="card pointer w-100 bg-primary d-none">
                              ${this.loader}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.preloadCheckInCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getEvent()
    })
  }

  togglePanel(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "streaming") {
      this.streamingsCardTarget.classList.add("bg-primary")
      this.streamingsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      if (this.application.event.finished == false) {
        this.chatCardTarget.classList.remove("bg-primary")
        this.chatRowTargets.forEach(element => {
          element.classList.add("d-none")
        })
      }
    } else if (feature == "chat") {
      this.streamingsCardTarget.classList.remove("bg-primary")
      this.chatCardTarget.classList.add("bg-primary")
      this.streamingsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.chatRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
    }
  }

  goToMeet() {
    window.open(`https://${this.application.event.midia_link}`, `_blank`)
  }

  doPublicPageGrid() {
    var html = ``

    if (this.application.event.finished) {
      var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                  <div class="col-12 text-center">
                    <h5 class="display-4">${this.application.event.title}</h5>
                    <h5>${this.application.event.copy}</h5>
                  </div>
                </div>
                <div class="row my-2 w-100">
                  <div class="col-12">
                    <div class="card pointer bg-primary" data-${this.controllerName}-target="streamingsCard" data-feature="streaming" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-1p25 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Streaming</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-${this.controllerName}-target="streamingsRow">
                  <div class="row my-3">
                    <div class="col-12 px-0 d-flex align-items-center justify-content-center" data-controller="mobile--commercial--marketing--events--live--streaming" data-mobile--commercial--marketing--events--live--streaming-target="main"></div>
                  </div>
                </div>`
    } else {
      if (this.application.event.midia_kind == `virtual_meet`) {
        window.open(`https://${this.application.event.midia_link}`, `_blank`)

        var html = ` <div class="col-12 my-4 text-center px-0">
                    <h2 class="lp-card-title">A aba da Video Conferência não abriu, clique no botão abaixo:</h2>
                  </div>
                  <div class="col-12">
                    <div class="forms-group">
                      <button type="submit" class="lp-btn-primary" data-${this.controllerName}-target="goToMeetBtn" data-action="click->${this.controllerName}#goToMeet">
                        Vídeo Conferência
                      </button>
                    </div>
                  </div>`

        if (this.hasShowAreaTarget) {
          this.showAreaTarget.innerHTML = html
        }
      } else if (this.application.event.midia_kind == `streaming`) {
        var html = `<div class="row mb-4">
                  <div class="col-12 text-center">
                    <img class="card-img-top live-img my-0" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/MedSchool-Azul.png" alt="MedSchool" style="width:15rem;">
                  </div>
                  <div class="col-12 text-center">
                    <h5 class="display-4">${this.application.event.title}</h5>
                    <h5>${this.application.event.copy}</h5>
                  </div>
                </div>
                <div class="row my-2 w-100">
                  <div class="col-6 pl-0 pr-2">
                    <div class="card pointer bg-primary" data-${this.controllerName}-target="streamingsCard" data-feature="streaming" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-1p25 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Streaming</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pl-2 pr-0">
                    <div class="card pointer" data-${this.controllerName}-target="chatCard" data-feature="chat" data-action="click->${this.controllerName}#togglePanel">
                      <div class="card-header py-0 f-1p25 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Chat</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-${this.controllerName}-target="streamingsRow">
                  <div class="row my-3">
                    <div class="col-12 px-0 d-flex align-items-center justify-content-center" data-controller="mobile--commercial--marketing--events--live--streaming" data-mobile--commercial--marketing--events--live--streaming-target="main"></div>
                  </div>
                </div>
                <div class="d-none" data-${this.controllerName}-target="chatRow">
                  <div class="row my-3">
                    <div class="col-12 px-0" data-controller="mobile--commercial--marketing--events--live--chat" data-mobile--commercial--marketing--events--live--chat-target="main"></div>
                  </div>
                </div>`

      }
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--broadcast").getRoom()
    })
  }

  getMessages(chatId) {
    var data = { room: { id: chatId } }
    const url = "/commercial/marketing/events/messages/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.messages = response.data.cln
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--chat").doMessageTable()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getMeeting() {
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
    var data = { meeting: { event_id: this.application.event.id, visitor_token: this.application.current_visitor_token } }
    const url = "/commercial/marketing/events/meetings/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meeting = response.data.cln.meeting
          controller.application.ticket = response.data.cln.ticket
          controller.application.attendee = response.data.cln.attendee
          controller.doPublicPageGrid()
        } else {
          controller.getControllerByIdentifier("mobile--commercial--marketing--events--live--meeting").doCheckinHtml()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getEvent() {
    var data = { event: { slug: this.application.event_token } }
    const url = "/commercial/marketing/events/entities/read_public"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.event = response.data.cln
          controller.setLink()
          controller.getMeeting()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  setLink() {
    this.application.event.links.forEach(element => {
      if (element.status == true) {
        this.application.link = element
      }
    })
  }

  setNavbarSpacing() {
    var navbarHeight = Number($('#navMobile').height());
    var navbarPadding = Number($('#navMobile').css('padding-top').replace("px", "")) + Number($('#navMobile').css('padding-bottom').replace("px", ""));
    var navbarSpacing = navbarHeight + navbarPadding
    this.navbarSpacingTarget.style.marginTop = navbarSpacing + "px"
    this.doCheckInPreload()
    
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }
}