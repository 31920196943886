import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "navBtn", "header", "navbarMain", "sessionTimer", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--app--navigation--navbar`
    this.setUserName()
    this.doNavbar()
  }

  setUserName() {
    if (this.application.current_user.account_kind == "doctor") {
      if (this.application.current_user.sex == "male") {
        this.application.user_name = `Dr. ${this.application.current_user.name.split(" ")[0]}`
      } else {
        this.application.user_name = `Dra. ${this.application.current_user.name.split(" ")[0]}`
      }
    } else if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      this.application.user_name = this.application.current_user.name.split(" ")[0]
    } else if (this.application.current_user.account_kind == "helper") {
      this.application.user_name = this.application.current_user.name.split(" ")[0]
    }
  }

  nameNavbar() {
    if (location.pathname == `/dashboard`) {
      var greetings = this.greetings()
      var html = `<p class="mt-3"> Olá, ${greetings} </p>
                  <h1> ${this.application.user_name}</h1>`
      this.navbarMainTarget.innerHTML = html
    } else if (location.pathname.split("/")[1] == `descanso-medico`) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Descanso Médico</h1>`
    } else if (location.pathname.split("/")[1] == `medschool`) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">MedSchool</h1>`
    } else if (location.pathname.split("/")[1] == `c`) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Calculadora MedCapital</h1>`
    } else if (location.pathname.includes(`recebimentos`)) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Meus Recebimentos</h1>`
    } else if (location.pathname.includes(`declaracao-de-ajuste`)) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Minha Declaração</h1>`
    } else if (location.pathname.includes(`livro-caixa`)) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Meu Livro-Caixa</h1>`
    } else if (location.pathname.includes(`minha-conta`)) {
      this.navbarMainTarget.innerHTML = `<h1 class="mb-0">Minha Conta</h1>`
    }
    
  }

  toggleNav(ev) {
    $('body').toggleClass('nav-dashboard')
  }

  refreshApp() {
    var url = location.pathname
    window.open(url, `_self`)
  }

  setTopMargin() {
    this.mainTarget.style.heigth = `90px`
  }

  doNavbar() {
    if (this.application.current_user.account_kind == "admin" || this.application.current_user.account_kind == "team") {
      var profileLink = `/a/minha-conta/${this.application.current_user.token}`
    } else if (this.application.current_user.account_kind == "doctor") {
      var profileLink = `/d/minha-conta/${this.application.current_user.token}`
    } else if (this.application.current_user.account_kind == "helper") {
      var profileLink = `/h/minha-conta/${this.application.current_user.token}`
    }
    var html = `<header class="user-dashboard fixed-top" data-${this.controllerName}-target="header">
                  <div class="col-2">
                    <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/favicon.png" width="35" alt="medcapital">
                  </div>
                  <div class="col-7 title-bar" data-${this.controllerName}-target="navbarMain">
                    
                  </div>
                  <div class="col-2">
                    <a class="nav-button nav-mobile-dash" data-${this.controllerName}-target="navBtn" data-action="click->${this.controllerName}#toggleNav">
                      <span class="material-icons">menu</span>
                    </a>
                  </div>
                  <div class="fixed-top main-menu">
                    <div class="my-5 p-5 d-flex justify-content-end">
                      <ul class="nav flex-column d-flex justify-content-end align-items-start w-100">
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" data-action="click->${this.controllerName}#toggleNav">
                            <span class="material-icons">keyboard_arrow_left</span>
                            Voltar
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" data-action="click->${this.controllerName}#renewalLogin">
                            <span class="material-icons">more_time</span>
                            <span data-${this.controllerName}-target="sessionTimer"></span>
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" data-action="click->${this.controllerName}#refreshApp">
                            <span class="material-icons">refresh</span>
                            Refresh
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" href="/dashboard">
                            <span class="material-icons">dashboard</span>
                            Meu Dashboard
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" href="/descanso-medico">
                            <img class="mr-2" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/icon-descanso-invert.png" alt="descancomedico" style="width:0.8rem;cursor:pointer;">
                            Descanso Médico
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" href="${profileLink}">
                            <span class="material-icons">account_circle</span>
                            Minha Conta
                          </a>
                        </li>
                        <li class="nav-item my-4">
                          <a class="nav-link m-nav-link" data-method="delete" href="/sair">
                            <span class="material-icons">exit_to_app</span>
                            Sair
                          </a>
                        </li>
                        <li class="nav-item">
                        </li>
                      </ul>
                    </div>
                  </div>
                </header>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameNavbar()
    }) 
  }

  renewalLogin() {
    this.getControllerByIdentifier(`app--communications--connection--login`).renewalLogin()
    this.toggleNav()
  }

  greetings() {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return `Bom Dia`
    } else if (curHr < 18) {
      return `Boa Tarde`
    } else {
      return `Boa Noite`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
