import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "workings", "filter", "addons", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--accounts--dashboard`
  }

  doAccountsDashboard() {
    var html = `<div class="col-12" data-${this.controllerName}-target="main" data-controller="financials--books--clients--accounts--index
                                                                                               financials--books--clients--accounts--save"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--books--clients--accounts--index`).permission = `financial_client_accounts`
      controller.getControllerByIdentifier(`financials--books--clients--accounts--index`).gridElement = controller.mainTarget
      controller.getControllerByIdentifier(`financials--books--clients--accounts--index`).current_client = controller.current_client
      controller.getControllerByIdentifier(`financials--books--clients--accounts--index`).clientDash = controller.clientDash
      controller.getControllerByIdentifier(`financials--books--clients--accounts--index`).doIndexHtml()
      
      controller.getControllerByIdentifier(`financials--books--clients--accounts--save`).permission = `financial_client_accounts`
      controller.getControllerByIdentifier(`financials--books--clients--accounts--save`).gridElement = controller.mainTarget
      controller.getControllerByIdentifier(`financials--books--clients--accounts--save`).current_client = controller.current_client

    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}