import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "body", "bottom", "top", "formCard", "formTitle", "formCardBody",
                    "accrualDate", "sameDayCheckbox", "dueDate", "saveBtn",
                    "allowanceInput", "subkindCheckbox", "borrowerCol", "medModal",
                    "channelModal", "channelInput", "borrowerInput", "descriptionInput",
                    "recurringCheckbox", "variableCheckbox", "ticketCheckbox", "statusList", "contractStatusForm",
                    "costModal", "costInput", "projectModal", "projectInput",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList",
                    "amountInput", "borrowerModal", "medInput", "finishedAt",
                    "amountInput", "allowanceModal", "providerModal", "asdf",
                    "cardDiv", "totalAmountInput", "installmentInput", "amountSplitInput", "bankLineDiv",
                    "bankLineInput", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKindDropdown",
                    "pixKindDropdownBtn", "pixKindInput", "pixKindList", "pixKeyInput"]

  connect() {
    this.controllerName = `financials--books--payrolls--remunerations--payable-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 3
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getCosts()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  cancelSave() {
    this.close()
  }

  savePayable() {
    this.saveBtnTarget.disabled = true
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()
    this.send_data = { current_user: {}, payable: {}, ticket: {}, tracker: { manual: true } }

    this.send_data.payable.date_id = this.current_date.id

    var chart = {}
    var chartId = this.current_remuneration.chart_id
    this.application.chart_accounts.forEach(element => {
      if (element.id == chartId) {
        chart = element
      }
    })

    this.send_data.payable.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.payable.channel_name = this.channelInputTarget.value
    this.send_data.payable.provider_id = this.current_contract.provider_id
    this.send_data.payable.provider_name = this.current_contract.provider_name
    this.send_data.payable.due_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.payable.accrual_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.payable.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.payable.total_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.payable.chart_id = chart.id
    this.send_data.payable.chart_account = chart.chart_account
    this.send_data.payable.chart_name = chart.chart_name
    this.send_data.payable.chart_master_name = chart.master_name
    this.send_data.payable.chart_group = chart.group
    this.send_data.payable.kind = chart.kind
    this.send_data.payable.method = this.methodInputTarget.dataset.method
    this.send_data.payable.bank_line = this.bankLineInputTarget.value
    this.send_data.payable.bank_account = this.bankAccountInputTarget.value

    this.send_data.payable.subkind = this.subkind
    this.send_data.payable.variable = this.variableCheckboxTarget.checked
    this.send_data.payable.recurring = this.recurringCheckboxTarget.checked
    this.send_data.payable.splited = false
    this.send_data.payable.split = this.installmentInputTarget.value
    this.send_data.payable.installment = this.installmentInputTarget.value

    if (this.methodInputTarget.dataset.method == `pix`) {
      this.send_data.payable.pix_key = this.pixKeyInputTarget.value
      this.send_data.payable.pix_kind = this.pixKindInputTarget.dataset.pixKind
    }


    this.send_data.payable.cost_id = this.costInputTarget.dataset.costId
    this.send_data.payable.cost_name = this.costInputTarget.value
    this.send_data.payable.project_id = this.projectInputTarget.dataset.projectId
    this.send_data.payable.project_name = this.projectInputTarget.value
    this.send_data.payable.record_id = this.current_remuneration.id
    this.send_data.payable.record_type = `financial_payroll_remunerations`


    if (this.subkind == `loan_expenses`) {
      this.send_data.payable.borrower_id = Number(this.borrowerInputTarget.dataset.medId)
    } else if (this.subkind == `loan_prepaid`) {
      this.send_data.payable.borrower_id = Number(this.borrowerInputTarget.dataset.medId)
      this.send_data.payable.prepaid_kind = `prepaid_people`
    } else if (this.subkind == `prepaid_expenses`) {
      this.send_data.payable.prepaid_kind = `prepaid_people`
    } else if (this.subkind == `refund`) {
      this.send_data.payable.refund_kind = this.refund_kind
    }

    this.send_data.payable.account_ids = [this.current_contract.account_id]
    // this.send_data.payable.record_id = this.current_contract.account_id
    // this.send_data.payable.record_type = `account_entities`
    this.send_data.payable.description = this.descriptionInputTarget.value.trim()

    this.send_data.payable.med_id = Number(this.medInputTarget.dataset.medId)
    this.send_data.payable.med_name = this.medInputTarget.value

    this.send_data.ticket.board_id = this.current_date.id
    this.send_data.ticket.board_type = `financial_payables`
    this.send_data.ticket.board_subtype = ``
    this.send_data.ticket.board_path = `/a/contas-a-pagar`
    this.send_data.ticket.board_name = `Contas à Pagar - ${this.current_date.name}`
    this.send_data.ticket.date_id = this.current_date.id
    this.send_data.ticket.date_type = `financials_dates`
    this.send_data.ticket.owner_id = this.application.current_user.account_id
    this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    // this.send_data.ticket.performer_id = this.application.current_user.account_id
    // this.send_data.ticket.performer_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.stage = `in_process`
    this.send_data.ticket.status = `in_time`
    this.send_data.ticket.sharing = `internal`
    this.send_data.ticket.term = `quick`
    this.send_data.ticket.priority = `maximum`
    // this.send_data.ticket.body = `Pgt. Ref. ${this.descriptionInputTarget.value.trim()} | ${this.controllerNumber.currencyOptionMask(this.amountInputTarget.value) } | ${chart.chart_name} | ${this.channelInputTarget.innerText}`
    this.send_data.ticket.body = `Pgt. Ref. ${this.descriptionInputTarget.value.trim()} | ${this.amountInputTarget.value} | ${chart.chart_name} | ${this.channelInputTarget.innerText}`
    this.send_data.ticket.due_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.ticket.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.ticket.flag = `blue`
    this.send_data.ticket.kind = `task`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission
    this.send_data.current_user.has_ticket = this.ticketCheckboxTarget.checked

    this.send_data.tracker.record_id = this.current_date.id
    this.send_data.tracker.record_type = `financials_dates`
    this.send_data.tracker.action = `created`
    this.send_data.tracker.observations = `Lançamento Pgt. Ref. ${this.descriptionInputTarget.value.trim()} | ${this.amountInputTarget.value} | ${chart.chart_name} | ${this.channelInputTarget.innerText}`

    this.requestSave()
  }

  requestSave() {
    var url = "/financials/books/payables/entities/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var payable = response.data.cln

          controller.updateRemuneration(payable)
        } else {
          controller.saveBtnTarget.disabled = false
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.cancelSave()
      })
      .catch(error => {
        controller.saveBtnTarget.disabled = false
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateRemuneration(payable) {
    var send_data = { current_user: {}, remuneration: {}, tracker: { manual: true } }

    send_data.tracker.action = `updated`
    send_data.tracker.observations = `Adicionou Contas à Pagar do item ${this.current_remuneration.allowance} da Remuneração ${this.current_contract.account_name}`
    send_data.tracker.record_id = this.current_contract.account_id
    send_data.tracker.record_type = `account_entities`
    send_data.tracker.obj_id = this.current_remuneration.id
    send_data.tracker.obj_type = `financial_payroll_remunerations`

    send_data.remuneration.id = this.current_remuneration.id
    send_data.remuneration.obj_id = payable.id
    send_data.remuneration.obj_type = `financial_payable_entities`

    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").send_data = send_data
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").actionMode = `edit`
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--save").requestSave()
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accrualDateForm">Lançamento</label>
                                <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="sameDayCheck" data-${this.controllerName}-target="sameDayCheckbox" data-action="click->${this.controllerName}#sameDayCheck">
                              <label class="custom-control-label f-065" for="sameDayCheck">Mesmo Dia</label>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dueDateForm">Vencimento</label>
                                <input aria-describedby="dueDateFormHelp" class="form-control form-valid-control" id="dueDateForm" data-${this.controllerName}-target="dueDate" placeholder="Vencimento" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div class="row my-3 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-8 d-flex">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="recurringCheck" data-${this.controllerName}-target="recurringCheckbox" data-kind="recurring">
                              <label class="custom-control-label f-065" for="recurringCheck">Recorrente</label>
                            </div>
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="variableCheck" data-${this.controllerName}-target="variableCheckbox" data-kind="variable">
                              <label class="custom-control-label f-065" for="variableCheck">Variável</label>
                            </div>
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="ticketCheck" data-${this.controllerName}-target="ticketCheckbox">
                              <label class="custom-control-label f-065" for="ticketCheck">Gerar Ticket</label>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-3 d-flex align-items-center">
                          <div class="col-1"></div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="expensesCheck" data-${this.controllerName}-target="subkindCheckbox" data-subkind="expenses" data-action="click->${this.controllerName}#toggleSubkind">
                              <label class="custom-control-label f-065" for="expensesCheck">Despesa</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="prepaid_expensesCheck" data-${this.controllerName}-target="subkindCheckbox" data-subkind="prepaid_expenses" data-action="click->${this.controllerName}#toggleSubkind">
                              <label class="custom-control-label f-065" for="prepaid_expensesCheck">Antecipação</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="refundCheck" data-${this.controllerName}-target="subkindCheckbox" data-subkind="refund" data-action="click->${this.controllerName}#toggleSubkind">
                              <label class="custom-control-label f-065" for="refundCheck">Reembolso</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="loan_expensesCheck" data-${this.controllerName}-target="subkindCheckbox" data-subkind="loan_expenses" data-action="click->${this.controllerName}#toggleSubkind">
                              <label class="custom-control-label f-065" for="loan_expensesCheck">Mútuo Despesa</label>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                              <input type="checkbox" class="custom-control-input" id="loan_prepaidCheck" data-${this.controllerName}-target="subkindCheckbox" data-subkind="loan_prepaid" data-action="click->${this.controllerName}#toggleSubkind">
                              <label class="custom-control-label f-065" for="loan_prepaidCheck">Mútuo Antecipação</label>
                            </div>
                          </div>

                        </div>
                        <div class="row my-2 d-flex align-items-center">

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal Pagamento</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal Pagamento" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          
                        </div>
                        
                        <div class="row my-2 mt-3 d-flex align-items-center">

                          <div class="col-1 text-right px-0 d-none" data-${this.controllerName}-target="borrowerCol">
                            <button data-action="click->${this.controllerName}#showMeds" data-element="borrowerInput" data-${this.controllerName}-target="borrowerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Tomadores de Mútuos</span>
                            </button>
                          </div>
                          <div class="col-4 d-none" data-${this.controllerName}-target="borrowerCol">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">Tomador de Mútuo</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="borrowerInput" type="text" placeholder="Tomador de Mútuo" required>
                              </div>
                            </div>
                          </div>

                        </div>
                        
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showCosts" data-element="costInput" data-${this.controllerName}-target="costModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Centros de Custos</span>
                            </button>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="costForm">Centro de Custo</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="costInput" type="text" placeholder="Centro de Custo" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showProjects" data-element="projectInput" data-${this.controllerName}-target="projectModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Projetos</span>
                            </button>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="projectForm">Projeto</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="projectInput" type="text" placeholder="Projeto" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        

                        <div class="row d-none" data-${this.controllerName}-target="cardDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="totalAmountForm">Valor Total</label>
                                <input aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Total" type="tel" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="splitForm"># Parcelas</label>
                                <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="installmentInput" data-action="blur->${this.controllerName}#splitAmountEv keyup->${this.controllerName}#splitAmountEv" placeholder="Parcelas" type="tel" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountSplitForm">Valor Parcela</label>
                                <input disabled aria-describedby="amountSplitFormHelp" class="form-control form-valid-control" id="amountSplitForm" data-${this.controllerName}-target="amountSplitInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="tel" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="bankLineDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="bankLineForm">Linha Digitável Boleto (somente números, sem pontos)</label>
                                <input aria-describedby="bankLineFormHelp" class="form-control" id="bankLineForm" data-${this.controllerName}-target="bankLineInput" placeholder="Linha Digitável Boleto (somente números, sem pontos)" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none"  data-${this.controllerName}-target="bankAccountDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="bankAccountForm">Dados Bancários</label>
                                <input aria-describedby="bankAccountFormHelp" class="form-control" id="bankAccountForm" data-${this.controllerName}-target="bankAccountInput" placeholder="Dados Bancários" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="pixKeyDiv">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo PIX</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="pixKindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="pixKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="pixKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="pixKindList" data-app--helpers--search-target="searchList">
                                      <li data-pix-kind="id_number" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">CPF/CNPJ</li>
                                      <li data-pix-kind="email" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">E-mail</li>
                                      <li data-pix-kind="phone" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Celular</li>
                                      <li data-pix-kind="random" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chave Aleatória</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-7">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="pixKeyForm">Chave PIX</label>
                                <input aria-describedby="pixKeyFormHelp" class="form-control" id="pixKeyForm" data-${this.controllerName}-target="pixKeyInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.formTitleTarget.innerText = `Novo Contas à Pagar`

      var date = new Date(controller.current_date.year, controller.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(controller.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }

      controller.accrualDateTarget.value = controller.current_remuneration.accrual_date_pretty
      controller.dueDateTarget.value = controller.current_remuneration.due_date_pretty
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_remuneration.amount)

      controller.application.meds.forEach(element => {
        if (element.id == controller.current_contract.med_id) {
          controller.current_contract_med = element
          controller.current_med = element
          controller.setMainChannel(element)
        }
      })

      controller.application.costs.forEach(element => {
        if (element.name == `Pessoal`) {
          controller.costInputTarget.value = element.name
          controller.costInputTarget.dataset.costId = element.id    
        }
      })

      controller.recurringCheckboxTarget.checked = true
      controller.ticketCheckboxTarget.checked = true

      controller.channelInputTarget.value = controller.current_remuneration.channel_name
      controller.channelInputTarget.dataset.channelId = controller.current_remuneration.channel_id

      controller.methodDropdownBtnTarget.disabled = false

      // controller.medModalTarget.disabled = true
      controller.medInputTarget.dataset.medId = controller.current_contract_med.id
      controller.medInputTarget.value = controller.current_contract_med.name

      controller.subkindCheckboxTargets[0].checked = true
      controller.subkindCheckboxTargets.forEach(element => {
        if (controller.current_remuneration.kind_input == `prepaidflow`) {
          if (element.dataset.subkind == `prepaid_expenses`) {
            element.checked = true
            controller.subkindCheckboxTargets[0].checked = false
          }
        } else if (controller.current_remuneration.kind_input == `refundflow`) {
          if (element.dataset.subkind == `refund`) {
            element.checked = true
            controller.subkindCheckboxTargets[0].checked = false
          }
        }
      })

      controller.descriptionInputTarget.value = `${controller.current_remuneration.kind_input_pretty} ${controller.current_remuneration.allowance} - @${controller.current_remuneration.account_name}`
      controller.installmentInputTarget.value = 1

      var method = controller.current_remuneration.method
      var methodPretty = controller.current_remuneration.method_pretty
      controller.methodDropdownTarget.value = method
      controller.methodInputTarget.dataset.method = method
      controller.methodInputTarget.innerText = methodPretty
      controller.setBankData(method)

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  toggleSubkind(ev) {
    var currentElement = ev.currentTarget

    this.subkindCheckboxTargets.forEach(element => {
      element.checked = false
    })

    currentElement.checked = true
    this.subkind = currentElement.dataset.subkind

    if (this.subkind == `expenses` || this.subkind == `prepaid_expenses` || this.subkind == `refund`) {
      this.borrowerColTargets.forEach(element => {
        element.classList.add(`d-none`)
      })
    } else if (this.subkind == `loan_expenses` || this.subkind == `loan_prepaid`) {
      this.borrowerColTargets.forEach(element => {
        element.classList.remove(`d-none`)
      })
    }
  }

  setMainChannel(med) {
    var mainChannel = {}
    this.application.channels.forEach(channel => {
      if (channel.med_id == med.id && channel.subkind == `main`) {
        mainChannel = channel
      }
    })

    this.channelInputTarget.value = mainChannel.name
    this.channelInputTarget.dataset.channelId = mainChannel.id
    this.setMethodList(mainChannel)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (this.subkind == `transfers`) {
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`

      this.methodDropdownTarget.value = `transfer`
      this.methodInputTarget.innerText = `Transferência`
      this.methodInputTarget.dataset.method = `transfer`
      this.setBankData(`transfer`)
      this.bankAccountInputTarget.value = channel.name
    } else {
      if (channel.kind == `bank_account`) {
        methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
        methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
        methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = ``
        this.methodInputTarget.innerText = ``
        this.methodInputTarget.dataset.method = ``
      } else if (channel.kind == `credit_card`) {
        methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Cartão de Crédito`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `cash`) {
        methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Dinheiro`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `payment`) {
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = `bank_debt`
        this.methodInputTarget.innerText = `Débito em Conta`
        this.methodInputTarget.dataset.method = `bank_debt`
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      }
    }

    this.methodListTarget.innerHTML = methodList
  }

  selectMethod(ev) {
    var method = ev.currentTarget.dataset.method

    this.setBankData(method)
  }

  setBankData(method) {
    if (method == `bank_slip`) {
      this.bankLineDivTarget.classList.remove("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    } else if (method == `transfer`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.remove("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
      this.setContractProviderPaymentMethod(method)
    } else if (method == `pix`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.remove("d-none")
      this.cardDivTarget.classList.add("d-none")
      this.setContractProviderPaymentMethod(method)
    } else if (method == `credit_card`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.remove("d-none")

      this.totalAmountInputTarget.disabled = true
      this.totalAmountInputTarget.value = this.amountInputTarget.value
      this.amountSplitInputTarget.disabled = true
      this.installmentInputTarget.value = 1
      this.amountSplitInputTarget.value = this.amountInputTarget.value

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    } else {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setContractProviderPaymentMethod(method) {
    if (method == `transfer`) {
      this.bankAccountInputTarget.value = this.current_contract.provider.bank_account
    } else if (method == `pix`) {
      this.pixKeyInputTarget.value = this.current_contract.provider.pix_key

      this.pixKindDropdownTarget.value = this.current_contract.provider.pix_kind
      this.pixKindInputTarget.dataset.pixKind = this.current_contract.provider.pix_kind
      this.pixKindInputTarget.innerText = this.current_contract.provider.pix_kind_pretty
    }
  }

  sameDayCheck(ev) {
    var check = ev.currentTarget.checked

    if (check) {
      this.dueDateTarget.value = this.accrualDateTarget.value
    } else {
      this.dueDateTarget.value = ``
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showCosts(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--costs--modal" data-financials--books--dashboards--costs--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--costs--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).kind = `cost_center`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).feature = controller.permission
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).open()
    })
  }

  showProjects(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--costs--modal" data-financials--books--dashboards--costs--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--costs--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).kind = `project`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).feature = controller.permission
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = []
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payroll_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  finishLoadPage() {
    this.subkind = `expenses`
    this.doFormHtml()

    var response = { type: `success`, message: `Informações carregadas com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getCosts() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/payables/costs/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.costs = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {

        if (controller.hasAccrualDateInputTarget && controller.accrualDateInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasDueDateInputTarget && controller.dueDateInputTarget.value == ``) {
          len += 1
        }

        if (controller.channelInputTarget.value == ``) {
          len += 1
        }

        if (controller.methodInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.methodInputTarget.innerText == `PIX`) {
          if (controller.pixKindInputTarget.innerText == ``) {
            len += 1
          }
        }

        // if (controller.descriptionInputTarget.value == ``) {
        //   len += 1
        // }

        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}