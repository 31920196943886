import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameDropdown", "nameDropdownBtn", "nameInput", "nameList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "numberForm",
                    "teamInput", "lineInput", "extensionInput", "pixInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `app--communications--voips--callers--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("app--communications--voips--callers--index").clearList()
  }

  saveCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, caller: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.caller.id = this.current_caller.id
    }

    var callerName = this.teamInputTarget.value.split(" | ")[0]

    this.send_data.caller.account_id = this.teamInputTarget.dataset.teamId
    this.send_data.caller.account_name = callerName
    this.send_data.caller.line = this.lineInputTarget.value
    this.send_data.caller.extension = this.extensionInputTarget.value
    this.send_data.caller.kind = this.kindInputTarget.dataset.kind
    this.send_data.caller.status = this.statusInputTarget.dataset.status

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  destroyCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    this.send_data = { current_user: {}, caller: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.actionMode = `delete`
    this.send_data.caller.id = this.current_caller.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var r = confirm(`Tem certeza que deseja apagar o Ramal?`)
    if (r) {  
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/app/communications/voip/callers/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/app/communications/voip/callers/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/app/communications/voip/callers/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()

        if (response.save) {
          var caller = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.callers[controller.application.callers.length] = caller
          } else if (controller.actionMode == `edit`) {
            controller.application.callers.forEach((element, i) => {
              if (element.id == caller.id) {
                controller.application.callers.splice(controller.application.callers.indexOf(element), 1, caller)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.callers.forEach((element, i) => {
              if (element.id == caller.id) {
                controller.application.callers.splice(controller.application.callers.indexOf(element), 1)
              }
            })
          }
        }
        

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCaller" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.actionMode == "delete") {
      var destroyBtnHtml = `<button type="button" class="btn btn-flat-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#destroyCaller" data-${this.controllerName}-target="destroyBtn">Apagar</button>`
    } else {
      var destroyBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>

                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
  
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 text-right px-0" data-${this.controllerName}-target="teamModalCol">
                        <button data-action="click->${this.controllerName}#showTeams" data-element="teamInput" data-${this.controllerName}-target="teamModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Parceiros</span>
                        </button>
                      </div>
                      <div class="col-11" data-${this.controllerName}-target="teamModalCol">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="teamForm">Parceiro</label>
                            <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="teamInput" type="text" placeholder="Parceiro" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-1 text-right px-0"></div>
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="lineForm">Ramal</label>
                            <input aria-describedby="lineFormHelp" class="form-control form-valid-control" id="lineForm" data-action="blur->${this.controllerName}#setExtension" data-${this.controllerName}-target="lineInput" placeholder="Ramal" type="text" required>
                          </div>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="extensionForm">Extensão</label>
                            <input readonly aria-describedby="extensionFormHelp" class="form-control form-valid-control" id="extensionForm" data-${this.controllerName}-target="extensionInput" placeholder="Extensão" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row my-2">
                      <div class="col-1 text-right px-0"></div>
                      <div class="col-5">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativo</li>
                                  <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Inativo</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-5">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                  <li data-kind="service" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Atendimento</li>
                                  <li data-kind="operation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Operação</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${destroyBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("app--communications--voips--callers--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"


      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Ramal`

        // controller.statusDropdownBtnTarget.disabled = true
        // controller.statusDropdownTarget.value = `active`
        // controller.statusInputTarget.innerText = `Aberta`
        // controller.statusInputTarget.dataset.status = `active`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Ramal`

        controller.lineInputTarget.value = controller.current_caller.line
        controller.extensionInputTarget.value = controller.current_caller.extension
        controller.teamInputTarget.value = controller.current_caller.account_name
        controller.teamInputTarget.dataset.id = controller.current_caller.account_id

        controller.statusDropdownTarget.value = controller.current_caller.status
        controller.statusInputTarget.innerText = controller.current_caller.status_pretty
        controller.statusInputTarget.dataset.status = controller.current_caller.status

        controller.kindDropdownTarget.value = controller.current_caller.kind
        controller.kindInputTarget.innerText = controller.current_caller.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_caller.kind

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  setExtension(ev) {
    var caller = Number(ev.currentTarget.value)
    if (caller >= 1000 && caller <= 9999) {
      this.extensionInputTarget.value = caller
    } else {
      alert(`O Ramal deve ser configurado entre 1000 e 9999`)
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showTeams(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="users--accounts--entities--list-modal" data-users--accounts--entities--list-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-users--accounts--entities--list-modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).kind = `team`
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).permission = controller.permission
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.teamInputTarget.value == ``) {
        len += 1
      }

      // if (controller.statusInputTarget.innerText == ``) {
      //   len += 1
      // }

      // if (controller.kindInputTarget.innerText == ``) {
      //   len += 1
      // }

      if (controller.lineInputTarget.value == ``) {
        len += 1
      }

      if (controller.extensionInputTarget.value == ``) {
        len += 1
      }

      if (controller.extensionInputTarget.value != controller.lineInputTarget.value) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
