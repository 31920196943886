
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "searchInput", "asdf"]

  connect() {
    this.controllerName = `commercial--marketing--events--index--filter`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doSearchHtml() {
    var html = `<input class="form-control f-075" data-${this.controllerName}-target="searchInput" data-action="keyup->${this.controllerName}#doSearch" id="" placeholder="Buscar Evento..." type="text">`
    this.mainTarget.innerHTML = html
  }

  removeSearchHtml() {
    this.mainTarget.innerHTML = ``
  }


  doSearch(ev) {
    var filter = ev.target.value.toUpperCase()
    var filterUnaccent = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.getControllerByIdentifier("commercial--marketing--events--dealflow--flow").eventCardTargets.forEach(element => {
      var txtValue = element.innerText.toUpperCase()
      var txtValueUnaccent = txtValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      var show = false
      if ((txtValue.indexOf(filter) > -1) || (txtValueUnaccent.indexOf(filterUnaccent) > -1)) {
        show = true;
      }
      if (show == true) {
        element.closest(".cardRow").classList.remove("d-none")
      } else {
        element.closest(".cardRow").classList.add("d-none")
      }
    })
  }
  

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
