import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewCompanyCard", "viewTitle", "viewCompanyCardBody", "previewCard", "previewTitle", "principalShow",
    "contactShow", "principalTab", "infoTab", "contactTab", "profileTab", "permissionsTab",
    "viewPatientCardFooter", "infoShow", "profileShow", "permissionsShow"]

  connect() {
    this.controllerName = `users--companies--entities--show`
    this.showCompany = false
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCompanyCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Empresa Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewCompanyCardBody"></div>
                </div>`

    // this.getControllerByIdentifier("users--companies--entities--dashboard").viewTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--companies--entities--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      if (controller.showCompany) {
        controller.doCompanyHtml()
      }
    })
  }

  doCompanyHtml() {
    var html = `<ul class="nav nav-justified nav-tabs" role="tablist">
                  <li class="nav-item" data-${this.controllerName}-target="principalTab">
                    <a data-action="click->${this.controllerName}#showPrincipal" aria-controls="principal" aria-selected="true" class="f-065 nav-link nav-dark px-0 active" data-toggle="tab" href="#principal" id="principal-tab" role="tab">
                      <span class="mc-tooltip mx-0">
                        <span class="material-icons md-sm mx-0">account_box</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom">Principal</span>
                      </span>
                    </a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="contactTab">
                    <a data-action="click->${this.controllerName}#showContact" aria-controls="contact" aria-selected="true" class="f-065 nav-link nav-dark px-0" data-toggle="tab" href="#contact" id="contact-tab" role="tab">
                      <span class="mc-tooltip mx-0">
                        <span class="material-icons md-sm mx-0">import_contacts</span>
                        <span class="mc-tooltiptext mc-tooltiptext-bottom">Contatos</span>
                      </span>
                    </a>
                  </li>
                  
                </ul>
                <div class="tab-content">
                  <div aria-labelledby="principal-tab" class="tab-pane fade show active" role="tabpanel" id="principal"><div class="list-group" data-${this.controllerName}-target="principalShow"></div></div>
                  <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel" id="contact"><div class="list-group" data-${this.controllerName}-target="contactShow"></div></div>
                </div>`

    this.viewTitleTarget.innerText = this.current_company.trade_name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewCompanyCardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewCompanyCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.showPrincipal()
      if (!controller.application.permissions.company_entity_contacts.can_index) {
        controller.contactTabTarget.children[0].classList.add("disabled")
      }
    })
  }

  showPrincipal() {
    var html = `<div data-controller="users--companies--entities--principal" data-users--companies--entities--principal-target="main"></div>`
    $(".nav-tabs-indicator").addClass("nav-dark")
    this.principalShowTarget.innerHTML = html
  }

  showContact() {
    var html = `<div data-controller="users--companies--entities--contact" data-users--companies--entities--contact-target="main"></div>`
    if (this.application.permissions.company_entity_contacts.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.contactShowTarget.innerHTML = html
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
