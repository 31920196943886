import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "settings", "alias", "postRow", "5555", "tutorials", "7777", "8888", "9999",
                    "purchasesCards", "financialCards", "settingCards"]

  connect() {
    this.controllerName = `financials--books--dashboards--main--page`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.permissions = []
    this.application.meds = []
    this.application.chart_accounts = []
    this.application.channels = []
    this.application.providers = []
    this.application.cards = []
    this.application.clients = []
    this.application.costs = []
    this.application.regimes = []
    this.application.activities = []
    this.application.all_activities = []

    this.doPageGrid()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  doPageGrid() {

    var html = `<div class="row">
                  <div class="col-4" data-${this.controllerName}-target="alias"></div>
                  <div class="col-8" data-${this.controllerName}-target="settings" data-controller="financials--books--cards--entities--dashboard
                                                                                                    financials--books--dashboards--meds--dashboard
                                                                                                    financials--books--dashboards--chart-accounts--dashboard
                                                                                                    financials--books--dashboards--providers--dashboard
                                                                                                    financials--books--dashboards--dates--dashboard
                                                                                                    financials--books--dashboards--clients--dashboard
                                                                                                    financials--books--dashboards--costs--dashboard
                                                                                                    financials--books--apurations--regimes--dashboard
                                                                                                    financials--books--dashboards--activities--dashboard
                                                                                                    financials--books--dashboards--channels--dashboard"></div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.aliasPreloader()
    }) 

    // `<div class="col-2" data-users--dashboards--main--content-target="calculator"></div>`

  }

  doAliasCard() {

    var operationalCards = ``
    var purchaseCards = ``
    var settingsCards = ``
    

    var purchasesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Contratações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contratacoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">gavel</span>
                              <span class="mc-tooltiptext">Contratações</span>
                            </div>
                          </div>
                        </div>`

    var clientsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Clientes</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToClients">
                              <span class="material-icons md-dark">account_circle</span>
                              <span class="mc-tooltiptext">Clientes</span>
                            </div>
                          </div>
                        </div>`

    var payablesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Contas à Pagar</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contas-a-pagar" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">monetization_on</span>
                              <span class="mc-tooltiptext">Contas à Pagar</span>
                            </div>
                          </div>
                        </div>`

    var receivablesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Contas à Receber</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contas-a-receber" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt</span>
                              <span class="mc-tooltiptext">Contas à Receber</span>
                            </div>
                          </div>
                        </div>`

    var statementsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Demonstrações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/demonstrativos-financeiros" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">list</span>
                              <span class="mc-tooltiptext">Demonstrações</span>
                            </div>
                          </div>
                        </div>`

    var apurationsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Tributário</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToRegimes">
                              <span class="material-icons md-dark">price_change</span>
                              <span class="mc-tooltiptext">Tributário</span>
                            </div>
                          </div>
                        </div>`

    var contractsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Clientes & Contratos</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contratos-financeiros" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">gavel</span>
                              <span class="mc-tooltiptext">Clientes & Contratos</span>
                            </div>
                          </div>
                        </div>`

    var medsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Meds</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToMeds">
                              <img class="ml-2 mr-3 md-dark" src="https://s3-sa-east-1.amazonaws.com/medcapital/images/logo/logo_bc_site_64x64_b.png" alt="meds" style="width:1.5rem;cursor:pointer;">
                              <span class="mc-tooltiptext">Meds</span>
                            </div>
                          </div>
                        </div>`

    var chartAccountsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Plano de Contas</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToChartAccounts">
                                <span class="material-icons md-dark">account_tree</span>
                                <span class="mc-tooltiptext">Plano de Contas</span>
                              </div>
                            </div>
                          </div>`

    var datesCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Competências</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToDates">
                                <span class="material-icons md-dark">calendar_month</span>
                                <span class="mc-tooltiptext">Competências</span>
                              </div>
                            </div>
                          </div>`

    var cardsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Cartão de Crédito</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToCards">
                                <span class="material-icons md-dark">credit_card</span>
                                <span class="mc-tooltiptext">Cartão de Crédito</span>
                              </div>
                            </div>
                          </div>`

    var channelsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Canais</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToChannels">
                                <span class="material-icons md-dark">payments</span>
                                <span class="mc-tooltiptext">Canais</span>
                              </div>
                            </div>
                          </div>`

    var providersCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Fornecedores</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToProviders">
                                <span class="material-icons md-dark">payments</span>
                                <span class="mc-tooltiptext">Fornecedores</span>
                              </div>
                            </div>
                          </div>`

    var projectsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Projetos</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToProjects">
                                <span class="material-icons md-dark">request_quote</span>
                                <span class="mc-tooltiptext">Projetos</span>
                              </div>
                            </div>
                          </div>`

    var costsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Centros de Custo</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToCosts">
                                <span class="material-icons md-dark">request_quote</span>
                                <span class="mc-tooltiptext">Centros de Custo</span>
                              </div>
                            </div>
                          </div>`

    var activitiesCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Atividades Econômicas</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-action="click->${this.controllerName}#goToActivities">
                                <span class="material-icons md-dark">list</span>
                                <span class="mc-tooltiptext">Atividades Econômicas</span>
                              </div>
                            </div>
                          </div>`

    var radarsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Radar Renovações</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/radar-renovacoes" data-action="click->${this.controllerName}#goToURL">
                                <span class="material-icons md-dark">radar</span>
                                <span class="mc-tooltiptext">Radar Renovações</span>
                              </div>
                            </div>
                          </div>`

    var payrollsCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Remunerações</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contratos-e-remuneracoes" data-action="click->${this.controllerName}#goToURL">
                                <span class="material-icons md-dark">connect_without_contact</span>
                                <span class="mc-tooltiptext">Remunerações</span>
                              </div>
                            </div>
                          </div>`
    


    if (this.application.permissions.financial_settings_purchases.can_index) {
      purchaseCards += purchasesCard
    }

    if (this.application.permissions.financial_settings_clients.can_index) {
      purchaseCards += clientsCard
    }

    if (this.application.permissions.financial_settings_clients.can_index) {
      purchaseCards += radarsCard
    }

    if (this.application.permissions.financial_settings_receivables.can_index) {
      operationalCards += receivablesCard
    }

    if (this.application.permissions.financial_settings_payables.can_index) {
      operationalCards += payablesCard
    }

    if (this.application.permissions.financial_settings_statements.can_index) {
      operationalCards += statementsCard
    }

    if (this.application.permissions.financial_settings_apurations.can_index) {
      operationalCards += apurationsCard
    }

    if (this.application.permissions.financial_settings_receivables.can_index) {
      operationalCards += contractsCard
    }

    if (this.application.permissions.financial_settings_payables.can_index) {
      operationalCards += payrollsCard
    }

    if (this.application.permissions.financial_meds.can_index) {
      settingsCards += medsCard
    }

    if (this.application.permissions.financial_settings_providers.can_index) {
      settingsCards += providersCard
    }

    if (this.application.permissions.financial_settings_chart_accounts.can_index) {
      settingsCards += chartAccountsCard
    }

    if (this.application.permissions.financial_dates.can_index) {
      settingsCards += datesCard
    }

    if (this.application.permissions.financial_settings_card_entities.can_index) {
      settingsCards += cardsCard
    }

    if (this.application.permissions.financial_settings_channels.can_index) {
      settingsCards += channelsCard
    }

    if (this.application.permissions.financial_settings_costs.can_index) {
      settingsCards += costsCard
    }

    if (this.application.permissions.financial_settings_activities.can_index) {
      settingsCards += activitiesCard
    }

    // meds
    // dates
    // cards
    // chart_accounts - ok
    // channels
    // providers

    var html = `<h6 class="mb-0 text-center f-bold f-085">Acesso Rápido</h6>
                <hr class="mt-1">
                <h6 class="mb-0 text-center f-065">Contratações</h6>
                <hr class="mt-1">
                <div class="row mb-2" data-${this.controllerName}-target="purchasesCards" style="overflow:auto;">
                  ${purchaseCards}
                </div>
                <h6 class="mb-0 text-center f-065">Financeiro</h6>
                <hr class="mt-1">
                <div class="row mb-2" data-${this.controllerName}-target="financialCards" style="overflow:auto;">
                  ${operationalCards}
                </div>
                <h6 class="mb-0 text-center f-065">Configurações</h6>
                <hr class="mt-1">
                <div class="row mb-2" data-${this.controllerName}-target="settingCards" style="overflow:auto;">
                  ${settingsCards}
                </div>`

    // this.aliasTarget.innerHTML = html
    // this.finishLoadPage()
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.aliasTarget.innerHTML = html)
    }).then(() => {
      controller.purchasesCardsTarget.style.height = ($(window).height() * 0.10) + "px"
      controller.financialCardsTarget.style.height = ($(window).height() * 0.20) + "px"
      controller.settingCardsTarget.style.height = ($(window).height() * 0.20) + "px"
      controller.finishLoadPage()
    }) 
  }

  goToChartAccounts() {
    this.getControllerByIdentifier(`financials--books--dashboards--chart-accounts--dashboard`).doChartAccountsDashboard()
  }

  goToMeds() {
    this.getControllerByIdentifier(`financials--books--dashboards--meds--dashboard`).doMedsDashboard()
  }

  goToClients() {
    this.getControllerByIdentifier(`financials--books--dashboards--clients--dashboard`).doClientsDashboard()

  }

  goToChannels() {
    this.getControllerByIdentifier(`financials--books--dashboards--channels--dashboard`).doChannelsDashboard()
  }

  goToProviders() {
    this.getControllerByIdentifier(`financials--books--dashboards--providers--dashboard`).doProvidersDashboard()
  }

  goToCosts() {
    this.getControllerByIdentifier(`financials--books--dashboards--costs--dashboard`).doCostsDashboard()
  }

  goToCards() {
    this.getControllerByIdentifier(`financials--books--cards--entities--dashboard`).doCardsDashboard()
  }

  goToDates() {
    this.getControllerByIdentifier(`financials--books--dashboards--dates--dashboard`).doDatesDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier(`financials--books--apurations--regimes--dashboard`).doRegimesDashboard()
  }

  goToActivities() {
    this.getControllerByIdentifier(`financials--books--dashboards--activities--dashboard`).doActivitiesDashboard()
  }

  aliasPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.aliasTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    }) 
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getEconomicActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Todas as Atividades Econômicas`, value: 99 })

    var data = { cnae: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_activities` } }
    const url = "/app/datas/cnaes/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.all_activities = response.data.cln
        }

        controller.doAliasCard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 95 })

    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: "financial_meds" } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }

        controller.getEconomicActivities()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCosts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Projetos/Centros de Custos`, value: 90 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_costs` } }
    const url = "/financials/books/payables/costs/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.costs = response.data.cln
        }

        controller.getMeds()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCards() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Cartões de Crédito`, value: 80 })

    const data = { card: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_card_entities` } }
    const url = "/financials/books/cards/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.cards = response.data.cln

        controller.getCosts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 75 })

    const data = { channel: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_channels` } }
    const url = "/financials/books/statements/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.channels = response.data.cln
        }

        controller.getCards()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getProviders() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 60 })

    const data = { provider: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_providers` } }
    const url = "/financials/books/statements/providers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.providers = response.data.cln
        }

        controller.getChannels()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClients() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Clientes`, value: 50 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_clients` } }
    const url = "/financials/books/clients/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clients = response.data.cln
        }

        controller.getProviders()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_settings_chart_accounts` } }
    const url = "/financials/books/statements/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.chart_accounts = response.data.cln

        controller.getClients()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getFinancialDates() {
    const data = { date: { active: true, subdomain: `settings` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_dates` } }
    const url = "/financials/config/dates/list_with_subdomain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        })

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]

        // controller.application.dates = response.data.cln
        // controller.application.current_date = response.data.cln[0]
        controller.getControllerByIdentifier(`financials--books--apurations--regimes--dashboard`).current_date = currentDates[0]

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Contas`, value: 40 })
        controller.getChartAccounts()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_book_settings` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_book_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })
        controller.getFinancialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
