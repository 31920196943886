import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pfPartnerCheckbox", "pjPartnerCheckbox", "chooseType", "cpfInputDiv", "cpfInput", "cnpjInputDiv",
                    "cnpjInput", "takerNameInput", "takerDescriptionInput", "takerNotesInput",
                    "descriptionDiv", "descriptionInput", "infoDiv", "regimeInput", "productDateInput",
                    "issuedOnInput", "takerDescriptionCopy", "changeTaxationTypeCheckbox",
                    "ctissDropdown", "ctissDropdownBtn", "ctissInput", "ctissList", "ctissLabel", "showChannelCheckbox",
                    "totalAmount", "totalTaxAmount",
                    "takerTaxationDropdown", "takerTaxationDropdownBtn", "takerTaxationInput", "takerTaxationList",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "checkListTakerDescription", "checkListDescriptionTakerDescription", "checkListIssuedOn",
                    "checkListDescriptionIssuedOn", "checkListKind", "checkListDescriptionKind", "checkListTotalAmount",
                    "checkListDescriptionTotalAmount"]



  connect() {
    this.controllerName = "operations--products--clinics--clients--receivables--add--informations"
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.changedTaxationType = false

    if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--save`)) {
      this.saveController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--save`)
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  getData() {
    // var saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--save`)

    
    this.saveController.send_data.receivable.issued_on = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.issuedOnInputTarget.value)
    this.saveController.send_data.receivable.taker_id = this.takerNameInputTarget.dataset.takerId
    this.saveController.send_data.receivable.activity_id = this.ctissInputTarget.dataset.activityId
    this.saveController.send_data.receivable.cnae_code = this.ctissInputTarget.dataset.cnaeCode
    this.saveController.send_data.receivable.cnae_description = this.ctissInputTarget.innerText
    this.saveController.send_data.receivable.ctiss_code = this.ctissInputTarget.dataset.ctissCode
    this.saveController.send_data.receivable.ctiss_description = this.ctissInputTarget.dataset.ctissDescription
    this.saveController.send_data.receivable.city_service_code = this.numberController.cleanNumber(this.ctissInputTarget.dataset.cityServiceCode)
    this.saveController.send_data.receivable.federal_service_code = this.numberController.cleanNumber(this.ctissInputTarget.dataset.federalServiceCode)
    this.saveController.send_data.receivable.regime_id = this.saveController.current_regime.id
    this.saveController.send_data.receivable.date_pretty = this.saveController.current_date.name
    this.saveController.send_data.receivable.date_id = this.saveController.current_date.id
    this.saveController.send_data.receivable.issued_on_pretty = this.issuedOnInputTarget.value
    this.saveController.send_data.receivable.description = this.descriptionInputTarget.value
    this.saveController.send_data.receivable.channel_id = this.channelInputTarget.dataset.channelId
    this.saveController.send_data.receivable.channel_name = this.channelInputTarget.innerText
    this.saveController.send_data.receivable.method = `bank_transfer`
    this.saveController.send_data.receivable.kind = this.kindInputTarget.dataset.kind

    if (this.changedTaxationType) {
      if (this.takerTaxationInputTarget.dataset.taxationType == `within_city`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      } else if (this.takerTaxationInputTarget.dataset.taxationType == `outside_city`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_taker.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_taker.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_taker.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `outside_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Cidade Diferente`

      } else if (this.takerTaxationInputTarget.dataset.taxationType == `none`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      }

    } else {
      if (this.saveController.current_taker.id_type == `individual`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_clinic.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_clinic.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_clinic.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = `within_city`
        this.saveController.send_data.receivable.taker_taxation_type_pretty = `Mesma Cidade`
      } else if (this.saveController.current_taker.id_type == `legal`) {
        this.saveController.send_data.receivable.service_state = this.saveController.current_taker.address_list[0].state
        this.saveController.send_data.receivable.service_city_code = this.saveController.current_taker.address_list[0].ibge
        this.saveController.send_data.receivable.service_city_name = this.saveController.current_taker.address_list[0].city
        this.saveController.send_data.receivable.taker_taxation_type = this.saveController.current_taker.taxation_type
        this.saveController.send_data.receivable.taker_taxation_type_pretty = this.saveController.current_taker.taxation_type_pretty
      }
    }

    var performers = []

    var totalReceivablePractice = 0
    var totalReceivableProcedure = 0
    var receivableProcedure = false
    this.saveController.partners.forEach(partner => {
      if (partner.operational) {
        var performer = { amount: 0, total_practice_revenues: 0, total_procedure_revenues: 0 }
        var current_amount = 0
        if (this.nameTarget(`partnerCheckbox-${partner.id}`).checked) {

          performer.partner_id = partner.id
          performer.name = partner.name
          current_amount = this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value)
          performer.amount += Number(current_amount)

          performer.kind = this.kindInputTarget.dataset.kind
          if (performer.kind == `practice`) {
            performer.total_practice_revenues += Number(current_amount)
            totalReceivablePractice += Number(current_amount)
          } else if (performer.kind == `procedure`) {
            performer.total_procedure_revenues += Number(current_amount)
            totalReceivableProcedure += Number(current_amount)
          }
          performer.information = this.nameTarget(`partnerData-${partner.id}`).innerText

          performer.clinic_id = this.saveController.send_data.receivable.clinic_id
          performer.date_id = this.saveController.send_data.receivable.date_id
          performer.taker_id = this.saveController.send_data.receivable.taker_id
          performer.taker_name = this.saveController.send_data.receivable.taker_name
          performer.taker_type = this.saveController.send_data.receivable.taker_type

          if (performer.amount > 0) {
            performers[performers.length] = performer
          }
        }
      }
    })

    // if (this.saveController.send_data.receivable.kind == `procedure`) {
    //   this.saveController.send_data.receivable.description += ` | Valor Procedimento/Tratamento Médico: ${this.numberController.currencyOptionMask(totalReceivableProcedure)}`
    // } else if (this.saveController.send_data.receivable.kind == `practice`) {
    //   this.saveController.send_data.receivable.description += ` | Valor Consulta Médica: ${this.numberController.currencyOptionMask(totalReceivablePractice)}`
    // }

    this.saveController.send_data.receivable.total_practice_revenues = totalReceivablePractice
    this.saveController.send_data.receivable.total_procedure_revenues = totalReceivableProcedure
    this.saveController.send_data.performers.performers = performers

    if (this.saveController.current_regime.tax_regime == `simple_national`) {
      this.saveController.send_data.receivable.calculation_basis = this.calculationBasis
      this.saveController.send_data.receivable.irpj_amount_withheld = 0
      this.saveController.send_data.receivable.pis_amount_withheld = 0
      this.saveController.send_data.receivable.cofins_amount_withheld = 0
      this.saveController.send_data.receivable.csll_amount_withheld = 0
      this.saveController.send_data.receivable.inss_amount_withheld = 0
      this.saveController.send_data.receivable.iss_amount_withheld = this.totalWithheldIss
      this.saveController.send_data.receivable.iss_rate = this.effectiveIss
      this.saveController.send_data.receivable.iss_tax_amount = this.totalAmountIss
      this.saveController.send_data.receivable.federal_withheld = this.federalWithheld
      this.saveController.send_data.receivable.municipal_withheld = this.municipalWithheld
      this.saveController.send_data.receivable.deductions_amount = 0
      this.saveController.send_data.receivable.unconditioned_amount = this.unconditioned
      this.saveController.send_data.receivable.conditioned_amount = this.conditioned
      this.saveController.send_data.receivable.others_amount_withheld = this.otherAmountWithheld
      this.saveController.send_data.receivable.net_amount = this.netReceivable
      this.saveController.send_data.receivable.total_tax_amount = this.totalTaxAmount
      this.saveController.send_data.receivable.total_tax_payable = this.totalPayable
      this.saveController.send_data.receivable.total_tax_withheld = this.totalTaxWithheld
      this.saveController.send_data.receivable.total_practice_tax_amount = this.normalTaxAmount
      this.saveController.send_data.receivable.total_procedure_tax_amount = this.hospitalTaxAmount
      // this.saveController.send_data.others_amount_withheld = this.numberController.fromCurrencyToNumber(this.otherAmountWithheldTarget.value)

      var performers = []
      this.saveController.send_data.performers.performers.forEach(element => {
        var performer = {}
        var rate = element.amount / this.saveController.send_data.receivable.total_amount
        if (this.saveController.send_data.receivable.total_practice_revenues > 0) {
          var normalRate = element.total_practice_revenues / this.saveController.send_data.receivable.total_practice_revenues
        } else {
          var normalRate = 0
        }
        if (this.saveController.send_data.receivable.total_procedure_revenues > 0) {
          var hospitalRate = element.total_procedure_revenues / this.saveController.send_data.receivable.total_procedure_revenues
        } else {
          var hospitalRate = 0
        }

        performer = element
        performer.irpj_amount_withheld = this.saveController.send_data.receivable.irpj_amount_withheld * rate
        performer.pis_amount_withheld = this.saveController.send_data.receivable.pis_amount_withheld * rate
        performer.cofins_amount_withheld = this.saveController.send_data.receivable.cofins_amount_withheld * rate
        performer.csll_amount_withheld = this.saveController.send_data.receivable.csll_amount_withheld * rate
        performer.inss_amount_withheld = this.saveController.send_data.receivable.inss_amount_withheld * rate
        performer.iss_amount_withheld = this.saveController.send_data.receivable.iss_amount_withheld * rate
        performer.total_amount_withheld = this.saveController.send_data.receivable.total_tax_withheld * rate
        performer.net_amount = this.saveController.send_data.receivable.net_amount * rate

        performer.total_practice_tax_amount = this.saveController.send_data.receivable.total_practice_tax_amount * normalRate
        performer.total_procedure_tax_amount = this.saveController.send_data.receivable.total_procedure_tax_amount * hospitalRate
        performer.tax_amount = Number(performer.total_practice_tax_amount) + Number(performer.total_procedure_tax_amount)

        performers[performers.length] = performer
      })

      this.saveController.send_data.performers.performers = performers  
    } else if (this.saveController.current_regime.tax_regime == `presumed_profit`) {
      this.saveController.send_data.receivable.calculation_basis = this.calculationBasis
      this.saveController.send_data.receivable.irpj_amount_withheld = 0
      this.saveController.send_data.receivable.pis_amount_withheld = 0
      this.saveController.send_data.receivable.cofins_amount_withheld = 0
      this.saveController.send_data.receivable.csll_amount_withheld = 0
      this.saveController.send_data.receivable.inss_amount_withheld = 0
      this.saveController.send_data.receivable.iss_amount_withheld = this.totalWithheldIss
      this.saveController.send_data.receivable.iss_rate = this.effectiveIss
      this.saveController.send_data.receivable.iss_tax_amount = this.totalAmountIss
      this.saveController.send_data.receivable.federal_withheld = this.federalWithheld
      this.saveController.send_data.receivable.municipal_withheld = this.municipalWithheld
      this.saveController.send_data.receivable.deductions_amount = 0
      this.saveController.send_data.receivable.unconditioned_amount = this.unconditioned
      this.saveController.send_data.receivable.conditioned_amount = this.conditioned
      this.saveController.send_data.receivable.others_amount_withheld = this.otherAmountWithheld
      this.saveController.send_data.receivable.net_amount = this.netReceivable
      this.saveController.send_data.receivable.total_tax_amount = this.totalTaxAmount
      this.saveController.send_data.receivable.total_tax_payable = this.totalPayable
      this.saveController.send_data.receivable.total_tax_withheld = this.totalTaxWithheld
      this.saveController.send_data.receivable.total_practice_tax_amount = this.normalTaxAmount
      this.saveController.send_data.receivable.total_procedure_tax_amount = this.hospitalTaxAmount
      // this.saveController.send_data.others_amount_withheld = this.numberController.fromCurrencyToNumber(this.otherAmountWithheldTarget.value)

      var performers = []
      this.saveController.send_data.performers.performers.forEach(element => {
        var performer = {}
        var rate = element.amount / this.saveController.send_data.receivable.total_amount
        if (this.saveController.send_data.receivable.total_practice_revenues > 0) {
          var normalRate = element.total_practice_revenues / this.saveController.send_data.receivable.total_practice_revenues
        } else {
          var normalRate = 0
        }
        if (this.saveController.send_data.receivable.total_procedure_revenues > 0) {
          var hospitalRate = element.total_procedure_revenues / this.saveController.send_data.receivable.total_procedure_revenues
        } else {
          var hospitalRate = 0
        }

        performer = element
        performer.irpj_amount_withheld = this.saveController.send_data.receivable.irpj_amount_withheld * rate
        performer.pis_amount_withheld = this.saveController.send_data.receivable.pis_amount_withheld * rate
        performer.cofins_amount_withheld = this.saveController.send_data.receivable.cofins_amount_withheld * rate
        performer.csll_amount_withheld = this.saveController.send_data.receivable.csll_amount_withheld * rate
        performer.inss_amount_withheld = this.saveController.send_data.receivable.inss_amount_withheld * rate
        performer.iss_amount_withheld = this.saveController.send_data.receivable.iss_amount_withheld * rate
        performer.total_amount_withheld = this.saveController.send_data.receivable.total_tax_withheld * rate
        performer.net_amount = this.saveController.send_data.receivable.net_amount * rate

        performer.total_practice_tax_amount = this.saveController.send_data.receivable.total_practice_tax_amount * normalRate
        performer.total_procedure_tax_amount = this.saveController.send_data.receivable.total_procedure_tax_amount * hospitalRate
        performer.tax_amount = Number(performer.total_practice_tax_amount) + Number(performer.total_procedure_tax_amount)

        performers[performers.length] = performer
      })

      this.saveController.send_data.performers.performers = performers  
    }
    
  }

  doInformationsFormHtml() {

    var partnerLineHtml = ``
    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.saveController.partners, `name`)

    partners.forEach(partner => {
      if (partner.status && partner.operational && partner.councils.length > 0) {
        var check = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                      <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}" data-action="click->${this.controllerName}#checkPartner">
                      <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                    </div>`

        partnerLineHtml += `<tr class="itemRow" data-partner-id="${partner.id}">
                          <td colspan="2" class="align-middle p-0">${check}</td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none" data-${this.controllerName}-target="partnerAmountForm-${partner.id}">
                              <input class="form-control f-065" data-${this.controllerName}-target="partnerAmount-${partner.id}" data-action="blur->${this.controllerName}#bindTotalAmount blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                            </div>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0 text-center">
                            <span class="mc-tooltip pointer d-none" data-cpf-info="not_show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerCpfInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setCpfInfo">
                              <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerCpfInfo-${partner.id}">no_accounts</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Colocar CPF na Descrição da NF</span>
                            </span>
                            <span class="mc-tooltip pointer d-none" data-info="show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setInfo">
                              <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerInfo-${partner.id}">visibility</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Colocar Info na Descrição da NF</span>
                            </span>
                          </td>
                          <td colspan="1" class="f-065 align-middle p-0">
                            <div class="form-group d-none mb-0" data-${this.controllerName}-target="partnerDataForm-${partner.id}">
                              <span class="f-065" data-${this.controllerName}-target="partnerData-${partner.id}">${partner.councils[0].number_full_pretty}</span>
                            </div>
                          </td>
                        </tr>`
      } else if (partner.status && partner.operational && partner.doctor == false) {
        var check = `<div class="custom-control custom-checkbox custom-control-table pl-1 d-flex align-items-center">
                      <input type="checkbox" class="custom-control-input" data-partner-id="${partner.id}" id="check-${partner.id}" data-${this.controllerName}-target="partnerCheckbox-${partner.id}" data-action="click->${this.controllerName}#checkPartner">
                      <label class="custom-control-label f-065 pointer ml-3" for="check-${partner.id}">${partner.name}</label>
                    </div>`

        partnerLineHtml += `<tr class="itemRow" data-partner-id="${partner.id}">
                              <td colspan="2" class="align-middle p-0">${check}</td>
                              <td colspan="1" class="f-065 align-middle p-0">
                                <div class="form-group d-none" data-${this.controllerName}-target="partnerAmountForm-${partner.id}">
                                  <input class="form-control f-065" data-${this.controllerName}-target="partnerAmount-${partner.id}" data-action="blur->${this.controllerName}#bindTotalAmount blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                                </div>
                              </td>
                              <td colspan="1" class="f-065 align-middle p-0 text-center">
                                <span class="mc-tooltip pointer d-none" data-cpf-info="not_show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerCpfInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setCpfInfo">
                                  <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerCpfInfo-${partner.id}">no_accounts</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Colocar CPF na Descrição da NF</span>
                                </span>
                                <span class="mc-tooltip pointer d-none" data-info="show" data-partner-id="${partner.id}" data-${this.controllerName}-target="partnerInfoSpan-${partner.id}" data-action="click->${this.controllerName}#setInfo">
                                  <span class="material-icons md-dark md-200" data-${this.controllerName}-target="partnerInfo-${partner.id}">visibility</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Colocar Info na Descrição da NF</span>
                                </span>
                              </td>
                              <td colspan="1" class="f-065 align-middle p-0">
                                <div class="form-group d-none mb-0" data-${this.controllerName}-target="partnerDataForm-${partner.id}">
                                  <span class="f-065" data-${this.controllerName}-target="partnerData-${partner.id}"></span>
                                </div>
                              </td>
                            </tr>`
      } else {
        var message = `Se o Médico Sócio não aparece ou (i) ele não é operacional ou (ii) está faltando alguma informação, como o CRM.
                        Favor voltar na Regularização e atualizar os dados`
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 5000)
      }
    })

      // `<div class="col-3 px-0">
      //   <div class="row my-3">
      //     <div class="col-12 pl-1">
      //       <span class="f-bold">Valor Total da NF</span>
      //     </div>
      //   </div>
      //   <div class="row my-3">
      //     <div class="col-12 f-1 f-bold">
      //       <span data-${this.controllerName}-target="totalAmount">R$ 0,00</span>
      //     </div>
      //   </div>
      // </div>`


    var partnerHtml = `<div class="row my-3">
                        <div class="col-12 px-0">
                          <table class="table table-sm table-borderless" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                                  
                                </td>
                              </tr>
                              ${partnerLineHtml}

                            </tbody>
                          </table>
                        </div>
                      </div>`
    
    var html = `<div class="row mt-3 d-flex align-items-top">
                  <div class="col-8">
                    <div class="row">
                      <div class="col-3 pr-1 d-none" data-${this.controllerName}-target="cpfInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF Tomador</label>
                            <input aria-describedby="cpfFormHelp" class="form-control" id="cpfForm" data-type="individual" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate keyup->${this.controllerName}#searchTaker" placeholder="CPF Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 pr-1 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ Tomador</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-type="legal" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate keyup->${this.controllerName}#searchTaker" placeholder="CNPJ Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Tomador</label>
                            <input disabled aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="takerNameInput" placeholder="Nome Tomador" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="issuedOnForm">Data Emissão</label>
                            <input aria-describedby="issuedOnFormHelp" class="form-control form-valid-control" id="issuedOnForm" data-${this.controllerName}-target="issuedOnInput" placeholder="Data Emissão" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        ${partnerHtml}
                      </div>
                    </div>

                    <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="infoDiv">

                      <div class="col-3 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo de Atendimento</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="practice" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consulta Médica</li>
                                  <li data-kind="procedure" data-action="click->${this.controllerName}#selectKind click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Procedimento Médico</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="takerDescriptionForm">Descrição</label>
                            <input aria-describedby="takerDescriptionFormHelp" class="form-control" id="takerDescriptionForm" data-${this.controllerName}-target="takerDescriptionInput" data-action="keyup->${this.controllerName}#bindGeneralDescription blur->${this.controllerName}#bindGeneralDescription" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="infoDiv">
                      <div class="col-3 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Natureza da Operação</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="takerTaxationDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="takerTaxationDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="takerTaxationInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="takerTaxationList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Banco</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="showChannelCheck" data-${this.controllerName}-target="showChannelCheckbox" data-action="click->${this.controllerName}#showChannelCheckbox" data-type="legal">
                          <label class="custom-control-label f-065 pointer" for="showChannelCheck">Mostrar Banco na Descrição</label>
                        </div>
                      </div>


                    </div>

                    <div class="row d-flex align-items-center d-none" data-${this.controllerName}-target="infoDiv">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Atividade Econômica | Código de tributação do Município</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="ctissDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="ctissDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="ctissInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="ctissList" data-app--helpers--search-target="searchList">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="row">
                      <div class="col-6 pr-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalAmount">Valor Total NF</label>
                            <input aria-describedby="totalAmountHelp" class="form-control" id="totalAmount" data-${this.controllerName}-target="totalAmount" placeholder="" type="text" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="totalTaxAmount">Total Impostos</label>
                            <input aria-describedby="totalTaxAmountHelp" class="form-control" id="totalTaxAmount" data-${this.controllerName}-target="totalTaxAmount" placeholder="" type="text" readonly>
                          </div>
                        </div>
                      </div>
                      
                    </div>

                    <div class="row d-none" data-${this.controllerName}-target="descriptionDiv">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição Geral da NF</label>
                            <textarea rows="10" disabled aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição Geral da NF" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <h6 class="mb-0 text-center f-065 d-flex"><span>Checklist Formulário para Emissão NF</span></h6>
                    <hr class="mt-1">
                  </div>

                  <div class="col-3 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListIssuedOn" data-${this.controllerName}-target="checkListIssuedOn">
                      <label class="custom-control-label pointer" for="checkListIssuedOn"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionIssuedOn"></span>
                  </div>
                  <div class="col-3 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListTotalAmount" data-${this.controllerName}-target="checkListTotalAmount">
                      <label class="custom-control-label pointer" for="checkListTotalAmount"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionTotalAmount"></span>
                  </div>
                  <div class="col-3 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListKind" data-${this.controllerName}-target="checkListKind">
                      <label class="custom-control-label pointer" for="checkListKind"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionKind"></span>
                  </div>
                  <div class="col-3 d-flex align-items-center my-1">
                    <div class="custom-control custom-checkbox px-0">
                      <input disabled type="checkbox" class="custom-control-input" id="checkListTakerDescription" data-${this.controllerName}-target="checkListTakerDescription">
                      <label class="custom-control-label pointer" for="checkListTakerDescription"></label>
                    </div>
                    <span class="f-065 ml-3" data-${this.controllerName}-target="checkListDescriptionTakerDescription"></span>
                  </div>
                </div>`

                // takerDescription

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.saveController.formBodyTarget.innerHTML = html)
    }).then(() => {
      var date = new Date(controller.saveController.current_date.year, controller.saveController.current_date.month, 0)
      var today = new Date()

      // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.issuedOnInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1 )
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(controller.issuedOnInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      controller.issuedOnInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(today)

      if (date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
        controller.issuedOnInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(today)
      }

      controller.saveController.formTitleTarget.innerText = `Informações para Emissão da Nota Fiscal da ${controller.saveController.current_clinic.company_name}`

      controller.showChannelCheckboxTarget.checked = false

      controller.refreshSaveBtn()
      controller.setInformationForm()
      if (controller.saveController.partners.length == 1) {
        var partner = controller.saveController.partners[0]
        controller.nameTarget(`partnerCheckbox-${partner.id}`).checked = true
        controller.showPartner(partner.id, true)
      }

      controller.totalAmountTarget.value = this.numberController.currencyOptionMask(0)
      controller.totalTaxAmountTarget.value = this.numberController.currencyOptionMask(0)
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  copyToDescription(ev) {
    var copy = ev.currentTarget.dataset.copy
    this.descriptionInputTarget.value = copy
    this.descriptionInputTarget.focus()
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setInformationForm() {

    var type = this.saveController.current_taker.id_type

    if (type == "individual") {
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")
      this.takerNameInputTarget.value = ``
      this.cnpjInputTarget.value = ``
      this.cpfInputTarget.disabled = true
      this.cpfInputTarget.value = this.saveController.current_taker.id_number_pretty
    } else if (type == "legal") {
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")
      this.takerNameInputTarget.value = ``
      this.cpfInputTarget.value = ``
      this.cnpjInputTarget.disabled = true
      this.cnpjInputTarget.value = this.saveController.current_taker.id_number_pretty
    }

    this.takerNameInputTarget.dataset.takerId = this.saveController.current_taker.id
    this.takerNameInputTarget.value = this.saveController.current_taker.name

    this.takerDescriptionInputTarget.value = this.saveController.current_taker.description

    this.descriptionDivTargets.forEach(taker => {
      taker.classList.remove("d-none")
    })
    this.infoDivTargets.forEach(taker => {
      taker.classList.remove("d-none")
    })

    this.kindInputTarget.innerText = `Consulta Médica`
    this.kindDropdownTarget.value = `practice`
    this.kindInputTarget.dataset.kind = `practice`

    this.setActivities()
    this.setChannels()
    this.setTaxationType()
    this.bindGeneralDescription()

    this.descriptionInputTarget.focus()

    if (this.saveController.replaceMode) {
      this.takerDescriptionInputTarget.value = `Substituição NF ${this.saveController.replace_receivable.main_invoice.invoice_number_pretty} | ${this.saveController.replace_receivable.description}`
      this.issuedOnInputTarget.value = this.saveController.replace_receivable.issued_on_pretty

      this.saveController.replace_receivable.performers.forEach(element => {
        this.showPartner(element.partner_id, true)
        this.nameTarget(`partnerCheckbox-${element.partner_id}`).checked = true
        this.nameTarget(`partnerAmount-${element.partner_id}`).value = this.numberController.currencyOptionMask(element.amount)
        this.nameTarget(`partnerAmount-${element.partner_id}`).focus()
      })
    }

    

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setActivities() {
    var html = ``
    var replaceActivity = {}
    this.saveController.activities.forEach(activity => {

      if (activity.status == `active`) {
        html += `<li data-cnae="${activity.cnae_code}" data-activity-id="${activity.id}" data-action="click->${this.controllerName}#selectActivity click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${activity.cnae_code} | ${activity.cnae_description}</li>`

        if (activity.kind == `main`) {
          this.ctissDropdownTarget.value = activity.id
          this.ctissInputTarget.innerText = `${activity.cnae_code} | ${activity.cnae_description}`
          this.ctissInputTarget.dataset.activityId = activity.id
          this.ctissInputTarget.dataset.cnaeCode = activity.cnae_code
          this.ctissInputTarget.dataset.ctissCode = activity.ctiss_code
          this.ctissInputTarget.dataset.ctissDescription = activity.ctiss_description
          this.ctissInputTarget.dataset.cityServiceCode = activity.city_service_code
          this.ctissInputTarget.dataset.federalServiceCode = activity.federal_service_code
        }

        if (this.saveController.replaceMode && (activity.id == this.saveController.replace_receivable.activity_id)) {
          replaceActivity = activity
        }
      }

    })

    if (this.saveController.replaceMode) {
      this.ctissDropdownTarget.value = replaceActivity.id
      this.ctissInputTarget.innerText = `${replaceActivity.cnae_code} | ${replaceActivity.cnae_description}`
      this.ctissInputTarget.dataset.replaceActivityId = replaceActivity.id
      this.ctissInputTarget.dataset.cnaeCode = replaceActivity.cnae_code
      this.ctissInputTarget.dataset.ctissCode = replaceActivity.ctiss_code
      this.ctissInputTarget.dataset.ctissDescription = replaceActivity.ctiss_description
      this.ctissInputTarget.dataset.cityServiceCode = replaceActivity.city_service_code
      this.ctissInputTarget.dataset.federalServiceCode = replaceActivity.federal_service_code
    }

    this.ctissListTarget.innerHTML = html
  }

  bindGeneralDescription() {

    this.descriptionInputTarget.value = ``

    var takerDescription = this.takerDescriptionInputTarget.value
    var kindReceivable = this.kindInputTarget.innerText
    // this.descriptionInputTarget.value = `${kindReceivable} | ${takerDescription}`
    this.descriptionInputTarget.value = `${takerDescription}`

    this.saveController.partners.forEach(partner => {
      if (partner.operational) {
        var performer = {}
        if (this.nameTarget(`partnerCheckbox-${partner.id}`).checked) {
          var info = this.nameTarget(`partnerInfoSpan-${partner.id}`).dataset.info
          var cpfInfo = this.nameTarget(`partnerCpfInfoSpan-${partner.id}`).dataset.cpfInfo
          performer.name = partner.name
          performer.amount = this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value)
          if (info == `show`) {
            performer.show_info = true
          } else if (info == `not_show`) {
            performer.show_info = false
          }
          if (cpfInfo == `show`) {
            performer.show_cpf_info = true
          } else if (cpfInfo == `not_show`) {
            performer.show_cpf_info = false
          }
          performer.information = this.nameTarget(`partnerData-${partner.id}`).innerText
          
          if (performer.amount > 0) {
            if (performer.show_info && performer.show_cpf_info) {
              this.descriptionInputTarget.value += ` | ${performer.name} ${performer.information} CPF: ${partner.id_number_pretty}`
            } else if (performer.show_info) {
              this.descriptionInputTarget.value += ` | ${performer.name} ${performer.information}`
            } else if (performer.show_cpf_info) {
              this.descriptionInputTarget.value += ` | ${performer.name} CPF: ${partner.id_number_pretty}`
            }
          }
        }
      }

    })

    if (this.showChannelCheckboxTarget.checked == true) {
      this.descriptionInputTarget.value += ` | ${this.channelInputTarget.innerText}`
    }

  }

  selectActivity(ev) {

    var activityId = ev.currentTarget.dataset.activityId

    this.saveController.activities.forEach(element => {
      if (element.id == activityId) {
        this.ctissDropdownTarget.value = element.id
        this.ctissInputTarget.innerText = element.cnae_description
        this.ctissInputTarget.dataset.activityId = element.id
        this.ctissInputTarget.dataset.cnaeCode = element.cnae_code
        this.ctissInputTarget.dataset.ctissCode = element.ctiss_code
        this.ctissInputTarget.dataset.ctissDescription = element.ctiss_description
        this.ctissInputTarget.dataset.cityServiceCode = element.city_service_code
        this.ctissInputTarget.dataset.federalServiceCode = element.federal_service_code
      }
    })
  }

  selectKind(ev) {
    this.kindInputTarget.innerText = ev.currentTarget.innerText

    if (this.saveController.current_regime.tax_regime == `simple_national`) {
      this.setSimpleTaxValues()
    } else if (this.saveController.current_regime.tax_regime == `presumed_profit`) {
      this.setPresumedTaxValues()
    }
  }

  showChannelCheckbox(ev) {
    this.bindGeneralDescription()
  }

  setChannels() {
    var html = ``
    var replaceChannel = {}

    this.saveController.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectChannel click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`

        if (channel.kind == `main`) {
          this.channelDropdownTarget.value = channel.id
          this.channelInputTarget.innerText = `${channel.name}`
          this.channelInputTarget.dataset.channelId = channel.id
        }

        if (this.saveController.replaceMode && (channel.id == this.saveController.replace_receivable.channel_id)) {
          replaceChannel = channel
        }
      }
    })

    if (this.saveController.replaceMode) {
      this.channelDropdownTarget.value = replaceChannel.id
      this.channelInputTarget.innerText = `${replaceChannel.name}`
      this.channelInputTarget.dataset.channelId = replaceChannel.id
    }

    this.channelListTarget.innerHTML = html
  }

  selectChannel(ev) {
    var channelId = ev.currentTarget.dataset.channelId

    this.saveController.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })

    this.bindGeneralDescription()
  }

  setTaxationType() {
    var html = ``
    html += `<li data-taxation-type="within_city" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesma Cidade</li>`
    html += `<li data-taxation-type="outside_city" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cidade Diferente</li>`
    html += `<li data-taxation-type="none" data-action="click->${this.controllerName}#selectTaxationType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`

    this.takerTaxationDropdownTarget.value = this.saveController.current_taker.taxation_type
    this.takerTaxationInputTarget.innerText = this.saveController.current_taker.taxation_type_pretty
    this.takerTaxationInputTarget.dataset.taxationType = this.saveController.current_taker.taxation_type

    this.takerTaxationListTarget.innerHTML = html
    this.takerTaxationDropdownBtnTarget.disabled = true
  }

  selectTaxationType(ev) {

    var taxationType = ev.currentTarget.dataset.taxationType
    var taxationTypePretty = ev.currentTarget.innerText

    this.takerTaxationDropdownTarget.value = taxationType
    this.takerTaxationInputTarget.innerText = taxationTypePretty
    this.takerTaxationInputTarget.dataset.taxationType = taxationType

    this.changedTaxationType = true

  }

  changeTaxationTypeCheckbox(ev) {
    var change = ev.currentTarget.checked

    if (change) {
      this.takerTaxationDropdownBtnTarget.disabled = false
    } else {
      this.takerTaxationDropdownBtnTarget.disabled = true
    }
  }

  checkPartner(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var value = ev.currentTarget.checked
    this.showPartner(partnerId, value)
  }

  showPartner(partnerId, value) {

    if (value) {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerCpfInfoSpan-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.remove("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.numberController.currencyOptionMask(0)
      this.nameTarget(`partnerAmount-${partnerId}`).focus()
    } else {
      this.nameTarget(`partnerAmountForm-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerInfoSpan-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerCpfInfoSpan-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.add("d-none")
      this.nameTarget(`partnerAmount-${partnerId}`).value = this.numberController.currencyOptionMask(0)
    }

    this.bindTotalAmount()
  }

  bindTotalAmount() {
    var totalAmount = 0

    this.saveController.partners.forEach(partner => {
      if (partner.operational) {
        totalAmount += Number(this.numberController.fromCurrencyToNumber(this.nameTarget(`partnerAmount-${partner.id}`).value))
      }
    })

    this.totalAmountTarget.value = this.numberController.currencyOptionMask(totalAmount)
    this.saveController.send_data.receivable.total_amount = totalAmount
    //this.saveController.send_data.receivable.total_amount = this.numberController.fromCurrencyToNumber(this.totalAmountTarget.value)
    this.bindGeneralDescription()

    if (this.saveController.current_regime.tax_regime == `simple_national`) {
      this.setSimpleTaxValues()
    } else if (this.saveController.current_regime.tax_regime == `presumed_profit`) {
      this.setPresumedTaxValues()
    }
  }

  setInfo(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var info = ev.currentTarget.dataset.info

    if (info == `show`) {
      this.nameTarget(`partnerInfo-${partnerId}`).innerText = `visibility_off`
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.add("d-none")
      ev.currentTarget.dataset.info = `not_show`
    } else if (info == `not_show`) {
      this.nameTarget(`partnerInfo-${partnerId}`).innerText = `visibility`
      this.nameTarget(`partnerDataForm-${partnerId}`).classList.remove("d-none")
      ev.currentTarget.dataset.info = `show`
    }

    this.bindGeneralDescription()
  }

  setCpfInfo(ev) {
    var partnerId = ev.currentTarget.dataset.partnerId
    var cpfInfo = ev.currentTarget.dataset.cpfInfo

    if (cpfInfo == `show`) {
      this.nameTarget(`partnerCpfInfo-${partnerId}`).innerText = `no_accounts`
      ev.currentTarget.dataset.cpfInfo = `not_show`
    } else if (cpfInfo == `not_show`) {
      this.nameTarget(`partnerCpfInfo-${partnerId}`).innerText = `how_to_reg`
      ev.currentTarget.dataset.cpfInfo = `show`
    }

    this.bindGeneralDescription()
  }

  upcase(ev) {
    var value = ev.currentTarget.value
    ev.currentTarget.value = value.toUpperCase()
  }

  percentMask(ev) {
    this.numberController.changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.numberController.changeNumberToCurrency(ev)
  }

  cpfMask(ev) {
    this.numberController.changeNumberToCpf(ev)
  }

  cnpjMask(ev) {
    this.numberController.changeNumberToCnpj(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.numberController.fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.numberController.fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  setSimpleTaxValues() {
    this.calculationBasis = this.saveController.send_data.receivable.total_amount

    this.effectiveIss = Number(this.saveController.current_regime.iss_rate)
    this.totalAmountIss = Number(this.effectiveIss) * Number(this.saveController.send_data.receivable.total_amount) / 100
    this.totalWithheldIss = 0
    this.totalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_amount) / 100
    this.unconditioned = 0
    this.conditioned = 0
    this.otherAmountWithheld = 0

    this.normalTaxAmount = 0
    this.hospitalTaxAmount = 0
    if (this.kindInputTarget.innerText == `Consulta Médica`) {
      this.normalTaxAmount = this.totalTaxAmount
    } else if (this.kindInputTarget.innerText == `Procedimento Médico`) {
      this.hospitalTaxAmount = this.totalTaxAmount
    }

    this.totalTaxAmountTarget.value = this.numberController.currencyOptionMask(this.totalTaxAmount)
    this.bindSimpleTax()
  }

  bindSimpleTax() {
    this.totalWithheldIss = 0
    this.federalWithheld = 0
    this.municipalWithheld = Number(this.totalWithheldIss)

    this.totalPayableIss = Number(this.totalAmountIss) - Number(this.totalWithheldIss)
    this.totalTaxWithheld = Number(this.totalWithheldIss)

    this.totalPayable = Number(this.totalTaxAmount) - Number(this.totalWithheldIss)
    this.netReceivable = Number(this.saveController.send_data.receivable.total_amount) - Number(this.totalTaxWithheld)
  }

  setPresumedTaxValues() {
    this.calculationBasis = this.saveController.send_data.receivable.total_amount

    if (this.saveController.current_regime.iss_type == `fixed`) {
      this.effectiveIss = 0
      this.totalAmountIss = 0
    } else if (this.saveController.current_regime.iss_type == `aliquot`) {
      this.effectiveIss = Number(this.saveController.current_regime.iss_rate)
      this.totalAmountIss = Number(this.effectiveIss) * Number(this.saveController.send_data.receivable.total_amount) / 100
    }

    this.totalWithheldIss = 0
    this.irpjWithheld = 0
    this.csllWithheld = 0
    this.pisWithheld = 0
    this.cofinsWithheld = 0
    this.issWithheld = 0
    this.inssWithheld = 0
    this.unconditioned = 0
    this.conditioned = 0
    this.otherAmountWithheld = 0

    if (this.saveController.current_regime.sub_tax_regime == `hospital_match`) {
      this.normalTaxAmount = 0
      this.hospitalTaxAmount = 0
      var normalTax = 0
      var hospitalTax = 0
     if (this.kindInputTarget.innerText == `Consulta Médica`) {
       normalTax = Number(11.33) + Number(Number(this.saveController.current_regime.effective_iss))
       this.normalTaxAmount = Number(normalTax) * Number(this.saveController.send_data.receivable.total_amount) / 100
      } else if (this.kindInputTarget.innerText == `Procedimento Médico`) {
        hospitalTax = Number(5.93) + Number(Number(this.saveController.current_regime.effective_iss))
        this.hospitalTaxAmount = Number(hospitalTax) * Number(this.saveController.send_data.receivable.total_amount) / 100
      }

      this.totalTaxAmount = Number(this.normalTaxAmount) + Number(this.hospitalTaxAmount)
    } else if (this.saveController.current_regime.sub_tax_regime == `normal`) { 
      this.totalTaxAmount = Number(this.saveController.current_regime.effective_total_tax) * Number(this.saveController.send_data.receivable.total_amount) / 100
      this.normalTaxAmount = 0
      this.hospitalTaxAmount = 0
      if (this.kindInputTarget.innerText == `Consulta Médica`) {
        this.normalTaxAmount = this.totalTaxAmount
      } else if (this.kindInputTarget.innerText == `Procedimento Médico`) {
        this.hospitalTaxAmount = this.totalTaxAmount
      }
    }
    
    this.totalTaxAmountTarget.value = this.numberController.currencyOptionMask(this.totalTaxAmount)
    this.bindPresumedTax()
  }

  bindPresumedTax() {
    this.totalWithheldIss = 0
    this.federalWithheld = 0
    this.municipalWithheld = Number(this.totalWithheldIss)

    this.totalPayableIss = Number(this.totalAmountIss) - Number(this.totalWithheldIss)
    this.totalTaxWithheld = 0

    this.totalPayable = Number(this.totalTaxAmount) - Number(this.totalWithheldIss)
    this.netReceivable = Number(this.saveController.send_data.receivable.total_amount) - Number(this.totalTaxWithheld)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.saveController.hasNextBtnTarget) {
        if (controller.hasDescriptionInputTarget && controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasTakerDescriptionInputTarget && controller.takerDescriptionInputTarget.value == ``) {
          len += 1
          controller.checkListTakerDescriptionTarget.checked = false
          controller.checkListDescriptionTakerDescriptionTarget.innerHTML = `<span>Descrição Procedimento</span>`
        } else {
          controller.checkListTakerDescriptionTarget.checked = true
          controller.checkListDescriptionTakerDescriptionTarget.innerHTML = `<span><s>Descrição Procedimento</s></span>`
        }

        if (controller.hasIssuedOnInputTarget && controller.issuedOnInputTarget.value == ``) {
          len += 1
          controller.checkListIssuedOnTarget.checked = false
          controller.checkListDescriptionIssuedOnTarget.innerHTML = `<span>Data de Emissão</span>`
        } else {
          controller.checkListIssuedOnTarget.checked = true
          controller.checkListDescriptionIssuedOnTarget.innerHTML = `<span><s>Data de Emissão</s></span>`
        }

        if (controller.hasTakerNameInputTarget && (controller.takerNameInputTarget.value == `` || controller.takerNameInputTarget.value == undefined)) {
          len += 1
        }

        if (controller.hasKindInputTarget && (controller.kindInputTarget.innerText == `` || controller.kindInputTarget.innerText == undefined)) {
          len += 1
          controller.checkListKindTarget.checked = false
          controller.checkListDescriptionKindTarget.innerHTML = `<span>Tipo de Atendimento</span>`
        } else {
          controller.checkListKindTarget.checked = true
          controller.checkListDescriptionKindTarget.innerHTML = `<span><s>Tipo de Atendimento</s></span>`
        }

        if (controller.saveController.current_taker.id_type == undefined) {
          len += 1
        }

        if (controller.saveController.current_date.name == undefined) {
          len += 1
        }

        if (controller.hasTotalAmountTarget && controller.totalAmountTarget.value == `R$ 0,00`) {
          len += 1
          controller.checkListTotalAmountTarget.checked = false
          controller.checkListDescriptionTotalAmountTarget.innerHTML = `<span>Valor da NF</span>`
        } else {
          controller.checkListTotalAmountTarget.checked = true
          controller.checkListDescriptionTotalAmountTarget.innerHTML = `<span><s>Valor da NF</s></span>`
        }

        if (controller.saveController.current_regime.special_tax_regime_pretty == undefined) {
          len += 1
        }

        if (len == 0) {
          controller.saveController.nextBtnTarget.disabled = false
        } else {
          controller.saveController.nextBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}

// parcela
// city_service_code = ctiss
// federal_service_code = inicio ctiss
// cnae_code = cnae

// taxation_type = comparar cidades
// additional_information ? 
