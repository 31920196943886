import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "opportunityLeadName", "table", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--index--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.hasFilter = false
    this.filter = ``
    this.tableCols = 13
    this.sort = { mode: `desc`, field: `started_at_to_time` }
  }

  showOpportunity(ev) {
    var opportunityId = ev.target.closest(".itemRow").dataset.id
    var obj = {}

    this.application.all_opportunities.forEach(element => {
      if (element.id == opportunityId) {
        obj = element
      }
    })
    window.open(obj.opportunity_path, `_blank`)
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var refreshLine = `<span data-action="click->${this.controllerName}#allOpportunities" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Oportunidades</span>`
    var prospectingLine = `<span data-action="click->${this.controllerName}#goToURL" data-url="/a/fluxo-prospeccao" class="dropdown-item py-1 pointer dropdown-submenu-item">Fluxo Prospecção</span>`
    var closingLine = `<span data-action="click->${this.controllerName}#goToURL" data-url="/a/fluxo-fechamento" class="dropdown-item py-1 pointer dropdown-submenu-item">Fluxo Fechamento</span>`
    var leadsLine = `<span data-action="click->${this.controllerName}#goToURL" data-url="/a/leads-gerados" class="dropdown-item py-1 pointer dropdown-submenu-item">Leads Gerados</span>`
    var qualifiedLine = `<span data-action="click->${this.controllerName}#goToURL" data-url="/a/leads-qualificados" class="dropdown-item py-1 pointer dropdown-submenu-item">Leads Qualificados</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                          ${prospectingLine}
                          ${closingLine}
                          ${leadsLine}
                          ${qualifiedLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    // `<span class="mc-tooltip ml-auto pointer" data-${this.controllerName}-target="addNewPurchaseBtn" data-action="click->${this.controllerName}#addNewPurchase">
    //   <span class="material-icons md-200 md-dark">add</span>
    //   <span class="mc-tooltiptext">Adicionar Nova Contratação</span>
    // </span>`

    // `<th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
    //   Plano
    //   <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="plan_name" data-mode="desc">south</span>
    //   <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="plan_name" data-mode="asc">north</span>
    // </th>`
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as Oportunidades</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 d-flex align-items-center">
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Início
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="asc">north</span>
                              </th>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle">
                                Leads
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle">
                                SDR
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="prospector_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="prospector_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-7 align-middle">
                                Closer
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="closer_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="closer_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">
                                Produto
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="product_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Tipo
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="kind_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Estágio
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="stage_number" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="stage_number" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Fonte</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Campanha</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Origem
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="origin" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="origin" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Valor</th>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">
                                Próximo Ticket
                                <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="asc">north</span>
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier("commercial--sales--opportunities--index--dashboard").indexTarget.innerHTML = html)
      resolve(controller.tableTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 6))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_opportunities = []
    if (this.hasFilter) {
      this.application.all_opportunities.forEach(element => {
        if (element.stage == this.filter) {
          current_opportunities[current_opportunities.length] = element
        }
      })
    } else {
      current_opportunities = this.application.all_opportunities
    }

    if (this.sort.mode == `asc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_opportunities, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var opportunities = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_opportunities, this.sort.field)
    }

    if (opportunities.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(opportunities.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (opportunities[x] !== undefined) {
            internPage[internPage.length] = opportunities[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: opportunities.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Oportunidades</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.opportunityTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  opportunityTablePartial(element, length) {

    element.name = ``
    element.ticket_name = ``
    element.leads.forEach(lead => {
      element.ticket_name += `<p class="mb-0 f-065">${lead.lead_name}</p>`
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityLeadName">${lead.lead_name}</p>`
    })

    if (element.products.length > 0) {
      element.products.forEach(product => {
        element.product_name = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.name_pretty}</p>`
        element.kind_name = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.kind_pretty}</p>`
        element.plan_name = `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="opportunityProductName">${product.plan_pretty}</p>`
      })
    } else {
      element.product_name = `Sem Produto`
      element.kind_name = `Sem Produto`
      element.plan_name = `Sem Produto`
    }

    if (element.journeys[0] && element.journeys[0].stage_alert) {
      var alert = `<div class="col-1 px-0 mc-tooltip pointer">
                    <span class="material-icons md-sm md-danger">warning</span>
                    <span class="mc-tooltiptext">Atenção! Oportunidade vai esfriar!</span>
                  </div>`
    } else {
      var alert = ``
    }

    if (element.status == `hot`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer" data-action="click->${this.controllerName}#udpateStatus">
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="material-icons md-sm md-danger">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    } else if (element.status == `warn`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer" data-action="click->${this.controllerName}#udpateStatus">
                      <span class="material-icons md-sm md-warning">local_fire_department</span>
                      <span class="material-icons md-sm md-warning">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    } else if (element.status == `cold`) {
      var status = `<span class="mb-0 f-065 mc-tooltip pointer" data-action="click->${this.controllerName}#udpateStatus">
                      <span class="material-icons md-sm md-primary">local_fire_department</span>
                      <span class="mc-tooltiptext mc-tooltiptext">${element.status_pretty}</span>
                    </span>`
    }

    if (element.next_ticket.has_ticket) {
      if (element.next_ticket.delay) {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-danger">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      } else {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-dark">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      }
    } else {
      // var nextTicket = `Não há Próximo Ticket`
      var nextTicket = `N/D`
    }

    if (this.application.permissions.sales_opportunities_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editOpportunity" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.prospector_name == null || element.prospector_name == undefined) {
      element.prospector_name = `Não Definido`
    }

    if (element.closer_name == null || element.closer_name == undefined) {
      element.closer_name = `Não Definido`
    }

    // `<td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.plan_name}</td>`

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showOpportunity">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ver Oportunidade</span>
                      </span>
                    </td>
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.prospector_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.closer_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.product_name}</td>
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.kind_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mb-0 mc-tooltip">
                        <span>${element.stage_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Estágio ${element.stage_pretty}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.source_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#udpateCampaign">
                      <span class="mb-0 mc-tooltip">
                        <span>${element.campaign_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Atualizar Campanha</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#udpateOrigin">
                      <span class="mb-0 mc-tooltip">
                        <span>${element.origin}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Atualizar Origem</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount_recurring)}</td>  
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${nextTicket}</td>
                    
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  allOpportunities() {
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.hasFilter = false
    this.filter = ``
    var data = {}

    if (this.getControllerByIdentifier(`commercial--sales--opportunities--index--dashboard`).all_team) {
      data = { opportunity: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    } else {
      // data = { opportunity: { active: true, account_id: this.getControllerByIdentifier(`commercial--sales--opportunities--index--dashboard`).account_id }, current_user: { current_user_id: this.application.current_user.id } }
      data = { opportunity: { active: true, account_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities`} }
    }
    const url = "/commercial/sales/opportunities/entities/list_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.all_opportunities = response.data.cln
        controller.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doIndexListHtml()
        controller.getControllerByIdentifier(`commercial--sales--opportunities--index--indicators`).doIndicatorsCalculation()
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  udpateStatus(ev) {
    var modal = `commercial--sales--opportunities--index--update-status-modal`
    var opportunityId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).opportunity_id = opportunityId
      controller.getControllerByIdentifier(modal).feature = `sales_opportunities_entities`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  udpateOrigin(ev) {
    var modal = `commercial--sales--opportunities--entities--origin-modal`
    var opportunityId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller

      controller.getControllerByIdentifier(modal).opportunity_id = opportunityId
      controller.getControllerByIdentifier(modal).permission = `sales_opportunities_entities`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  udpateCampaign(ev) {
    var modal = `commercial--sales--opportunities--index--update-campaign-modal`
    var opportunityId = ev.currentTarget.closest(`.itemRow`).dataset.id

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).opportunity_id = opportunityId
      controller.getControllerByIdentifier(modal).feature = `sales_opportunities_entities`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
