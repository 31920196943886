import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewTransferCard", "viewTitle", "viewTransferCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--transfers--show`

    if (this.getControllerByIdentifier("operations--products--clinics--financials--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--dashboard`)
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--transfers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--transfers--dashboard`)
    }
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewTransferCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Repasse Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewTransferCardBody"></div>
                </div>`

    this.dashboardController.viewTarget.innerHTML = html
  }

  editTransfer() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").current_transfer = this.current_transfer
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").actionMode = "edit"
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Competência já foi Fechada!`, 4000)
    }
  }

  deleteTransfer() {
    this.send_data = { current_user: {}, transfer: {} }

    this.actionMode = `delete`
    this.send_data.transfer.id = this.current_transfer.id
    this.send_data.transfer.active = false
    this.send_data.transfer.kind_pretty = this.current_transfer.kind_pretty
    this.send_data.transfer.partner_name = this.current_transfer.partner_name
    this.send_data.transfer.gross = this.current_transfer.gross
    this.send_data.transfer.date_token = this.current_transfer.date_token
    this.send_data.transfer.clinic_id = this.current_transfer.clinic_id
    this.send_data.transfer.date_id = this.current_transfer.date_id

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja apagar o Repasse?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").send_data = this.send_data
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").actionMode = `delete`
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--save").requestSave()
    }
  }

  doTransferHtml() {
    this.getControllerByIdentifier(`operations--products--clinics--financials--transfers--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_update && this.application.current_date.open) {
      var editLine = `<span data-action="click->${this.controllerName}#editTransfer" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Despesa</span>`
    } else {
      var editLine = ``
    }

    if (this.application.permissions[this.permission].can_delete && this.application.current_date.open) {
      var deleteLine = `<span data-action="click->${this.controllerName}#deleteTransfer" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Despesa</span>`
    } else {
      var deleteLine = ``
    }


    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${editLine}
                          ${deleteLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Sócio</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_transfer.partner_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Competência</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--date").mountDate(this.application.current_date.month, this.application.current_date.year)} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Tipo</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_transfer.kind_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Total</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(this.current_transfer.gross * 100)}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>IRRF</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(this.current_transfer.withheld * 100)}
                              </td>
                            </tr>
                            <tr>

                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>INSS</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(this.current_transfer.social * 100)}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Outros</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(this.current_transfer.others_deductions * 100)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Líquido</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(this.current_transfer.net_amount * 100)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Canal</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_transfer.channel_name} <br>
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Repasse ${controller.current_transfer.partner_name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
