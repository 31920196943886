import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "table", "content", "main", "mainCard", "teamList", "team", "teamDropdown", "teamInput", "index", "indicators"]

  connect() {
    this.controllerName = `financials--books--payrolls--dashboard--main`
    this.application.account_id = this.application.current_user.account_id
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reload = false
    this.application.tickets = []
    this.application.permissions = []
    this.application.accounts = []
    this.application.contracts = []
    this.application.providers = []
    this.application.renewals = []
    this.application.allowances = []
    this.application.chart_accounts = []
    this.application.remunerations = []
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--books--payrolls--dashboard--dashboard
                                                            financials--books--payrolls--dashboard--indicators
                                                            financials--books--payrolls--contracts--dashboard
                                                            financials--books--payrolls--remunerations--dashboard
                                                            financials--books--payrolls--compilations--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doPageGrid()
    })
  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doPageGrid() {
    var contractsController = `financials--books--payrolls--dashboard--contracts`
    var remunerationsController = `financials--books--payrolls--dashboard--remunerations`
    
    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6 px-2" data-controller="${contractsController}">
                    <div class="row my-2 w-100">
                      <div class="col-12">
                        <h6 class="mb-0 text-center pointer" data-action="click->${contractsController}#goToContracts" data-${contractsController}-target="contractTitle">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-${contractsController}-target="contractBoard">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 px-2" data-controller="${remunerationsController}">
                    <div class="row my-2 w-100">
                      <div class="col-12">
                        <h6 class="mb-0 text-center pointer" data-action="click->${remunerationsController}#goToRemunerations" data-${remunerationsController}-target="remunerationTitle">${this.loader}</h6>
                        <hr class="mt-1">
                        <div class="row my-2" data-${remunerationsController}-target="remunerationBoard">
                          ${this.cardGridPreloader()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.load) {
        controller.getControllerByIdentifier(contractsController).setContractCalculation()
        controller.getControllerByIdentifier(remunerationsController).setRemunerationCalculation()
      } else {
        controller.getCurrentUserPermissions()
      }
    })
  }

  doDashboardLoader() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var indicatorsHtml = `<div class="row" data-${this.controllerName}-target="indicators">
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                            <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                          </div>`

    indicatorsHtml = ``
    var html = `${indicatorsHtml}
                <div class="row">
                  <div class="col-12 px-0 my-2" data-${this.controllerName}-target="table">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getCurrentUserPermissions()
    })
  }

  cardGridPreloader() {
    var html = `<div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  finishLoadPage() {
    this.load = true
    // this.doPageGrid()
    // this.getControllerByIdentifier(`financials--books--payrolls--dashboard--indicators`).doIndicatorsCalculation()
    // this.getControllerByIdentifier(`financials--books--payrolls--dashboard--indicators`).doIndicatorsDashboard()
    // this.getControllerByIdentifier(`financials--books--payrolls--dashboard--submenu`).doSubmenuHtml()
    // this.getControllerByIdentifier(`financials--books--payrolls--dashboard--meds`).doMedHtml()

    if (this.reload) {
      this.getControllerByIdentifier("financials--books--payrolls--dashboard--dates").runDashboardsHtml()
      // this.getControllerByIdentifier("financials--books--payrolls--dashboard--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("financials--books--payrolls--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("financials--books--payrolls--dashboard--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("financials--books--payrolls--dashboard--meds").doMedHtml()
    }
    
    this.application.current_date.data_loaded = true
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getRemunerations() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Remunerações`, value: 80 })

    const data = { remuneration: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_remunerations` } }
    // const url = "/financials/books/payrolls/remunerations/list_by_due_date"
    const url = "/financials/books/payrolls/remunerations/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentRemunerationIds = controller.mapElements(controller.application.remunerations, `id`)
          var refreshRemunerationIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(remuneration => {
            if (currentRemunerationIds.includes(remuneration.id) && refreshRemunerationIds.includes(remuneration.id)) {
              controller.application.remunerations.forEach((element, i) => {
                if (element.id == remuneration.id) {
                  controller.application.remunerations.splice(controller.application.remunerations.indexOf(element), 1, remuneration)
                }
              })
            } else if (!currentRemunerationIds.includes(remuneration.id) && refreshRemunerationIds.includes(remuneration.id)) {
              controller.application.remunerations[controller.application.remunerations.length] = remuneration
            }
          })
        }
        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da ${this.application.current_date.name}` })
      }

      this.getRemunerations()
    } else {
      this.finishLoadPage()
    }
  }

  getAllowances() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Remunerações`, value: 65 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_remunerations` } }
    const url = "/financials/books/payrolls/allowances/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.allowances = response.data.cln
        }
        controller.loadDateData()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getProviders() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 60 })

    const data = { provider: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_providers` } }
    const url = "/financials/books/statements/providers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.providers = response.data.cln
        controller.getAllowances()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Conta`, value: 55 })

    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_chart_accounts` } }
    const url = "/financials/books/statements/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.chart_accounts = response.data.cln
        controller.getProviders()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getContracts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Remunerações`, value: 50 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_contracts` } }
    const url = "/financials/books/payrolls/contracts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.contracts = response.data.cln
        }
        controller.getChartAccounts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 45 })

    const data = { channel: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_channels` } }
    const url = "/financials/books/statements/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.channels = response.data.cln
        controller.getContracts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 40 })

    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_meds` } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }

        controller.getChannels()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getFinancialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 30 })

    const data = { date: { active: true, subdomain: `settings` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_dates` } }
    const url = "/financials/config/dates/list_with_subdomain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        })

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]

        controller.getControllerByIdentifier("financials--books--payrolls--dashboard--dates").doDatesHTML()
        controller.getMeds()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_book_payrolls` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payroll_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getFinancialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
