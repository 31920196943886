import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "team", "teamDropdown", "teamDropdownBtn", "teamInput", "teamList", "table"]

  connect() {
    this.controllerName = `commercial--sales--leads--meetings--dashboard`
    this.application.schedules = []
    this.application.slots = []
    this.application.permissions = {}
    this.application.all_team = false
    this.application.account_id = this.application.current_user.account_id
    this.getCurrentUserPermissions()
  }

  doMeetingGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8 px-2" data-controller="commercial--sales--leads--meetings--index" data-${this.controllerName}-target="list"></div>
                  <div class="col-4 px-2" data-controller="commercial--sales--leads--meetings--show commercial--sales--leads--meetings--save" data-${this.controllerName}-target="view"></div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  doSlotGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8 px-2" data-controller="commercial--sales--leads--meetings--slots" data-${this.controllerName}-target="table">SLOTS</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  doTeamHTML() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Time</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="teamDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="teamInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="teamList app--helpers--search.searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.teamTarget.innerHTML = html)
    }).then(() => {
      controller.teamDropdownTarget.value = controller.application.current_user.name
      controller.teamInputTarget.innerText = controller.application.current_user.name
      controller.doTeamList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doTeamList() {
    var listHtml = ``
    listHtml += `<li data-app--helpers--selector-target="select" data-team="team" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">Todos</li>`
    this.application.sales_team.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-team="individual" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">${element.name}</li>`
    });
    this.teamListTarget.innerHTML = listHtml
  }

  changeTeam(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var team = ev.target.dataset.team
      if (team == "individual") {
        this.application.account_id = ev.target.dataset.id
        this.application.all_team = false
      } else if (team == "team") {
        this.application.all_team = true
      }
      if (this.getControllerByIdentifier("commercial--sales--leads--meetings--index")) {
        this.getControllerByIdentifier("commercial--sales--leads--meetings--index").doDataTable()
      } else if (this.getControllerByIdentifier("commercial--sales--leads--meetings--slots")) {
        this.getControllerByIdentifier("commercial--sales--leads--meetings--slots").account_to_s = this.leadingZeros(parseInt(this.application.account_id).toString(36).toUpperCase(), 5)
        this.getControllerByIdentifier("commercial--sales--leads--meetings--slots").doSlotData()
      }
    }
  }

  getSalesTeam() {
    const data = { feature: { feature_name: `sales_opportunities_entities`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sales_team = response.data.cln
        controller.getSlots()
        controller.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").doTeamHTML()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSlots() {
    const data = { slot: { active: true, account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/works/schedules/slots/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.slots = response.data.cln
        controller.getControllerByIdentifier("commercial--sales--leads--meetings--index").doDataTable()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSchedules() {
    const data = { schedule: { active: true, kind: `sales` }, current_user: { current_user_id: this.application.current_user.id, feature: `schedules` } }
    const url = "/users/works/schedules/entities/list_by_kind"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.schedules = response.data.cln

        controller.getSalesTeam()
        controller.getControllerByIdentifier("commercial--sales--leads--meetings--submenu").doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["schedules"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.getSchedules()
        controller.doMeetingGrid()
        // controller.doCardArea()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
