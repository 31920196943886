import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--helpers--whatsapp-api`
  }

  sendWhatsApp(ev) {
    var shortName = ``

    if (this.person_name) {
      shortName = this.person_name.split(" ")[0]
    } else {
      shortName = ``
    }

    var url = `https://api.whatsapp.com/send?phone=55${this.uniq_number}&text=Olá ${shortName}!`
    window.open(url, "_blank")
  }

  requestSaveInteraction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.send_data = { current_user: {}, interaction: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.send_data.interaction.interacted_at = new Date()
    this.send_data.interaction.record_id = this.record_id
    this.send_data.interaction.record_type = this.record_type
    this.send_data.interaction.record_name = this.record_name
    this.send_data.interaction.obj_id = this.obj_id
    this.send_data.interaction.obj_type = this.obj_type
    this.send_data.interaction.person_id = this.person_id
    this.send_data.interaction.person_type = this.person_type
    this.send_data.interaction.person_name = this.person_name
    this.send_data.interaction.account_id = this.account_id
    this.send_data.interaction.account_name = this.account_name
    this.send_data.interaction.ddd = this.ddd
    this.send_data.interaction.number = this.number
    this.send_data.interaction.uniq_number = this.uniq_number
    this.send_data.interaction.kind = this.kind
    this.send_data.interaction.subkind = this.subkind

    var url = "/app/communications/interactions/entities/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        // if (response.save) {
        // }
        
        controller.sendWhatsApp()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
  
}