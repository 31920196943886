import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "changeYear", "periodInput", "periodList", "mainDashboard", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--show--dashboard`
  }

  reconnect() {
    // this.doTaxFilingDashboard()
    if (this.application.tax_filing_calculations) {
      this.setCurrentCalculation()
    }
  }

  setCurrentCalculation() {
    if (this.application.tax_filing_calculations.length > 0) {
      this.application.tax_filing_calculations.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.application.current_tax_filing_calculation = element
        }
      })
      this.doTaxFilingDashboard()
    } else {
      this.application.current_tax_filing_calculation = { name: "Não Definido", journey_status_pretty: "Não Definido" }
    }
  }

  changeYear() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dates").doDatesDashboard()
  }

  goToFiles() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--files--list").doListDashboard()
  }

  goToUploads() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--files--add").doAddDashboard()
  }

  goToProcess() {

  }

  goToJourneys() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--management--journeys").doJourneyDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--management--tickets").doTicketDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--management--notes").doNoteDashboard()
  }


  doTaxFilingDashboard() {
    var commentBadge = `<span class="badge badge-danger notice-badge-comment-mobile-dashboard">${this.application.current_tax_filing_calculation.tickets_in_process}</span>`
    var cardLoader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                        <div class='animated-background animated-background-5'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>`

    var html = `<div class="row mt-4" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12">
                    <h5>${this.application.tax_filing.account_name}, esse será o seu Dashboard com as suas Declarações de Ajuste Anual. Escolha o <strong>Ano de Exercício</strong> e tenha a sua <strong>Declaração</strong> aqui no celular.</h5>
                  </div>
                </div>
                <div class="row d-flex align-items-center">
                  <div class="col-6">
                    <span class="f-bold pointer badge badge-secondary-dark f-1p5">Ano Exercício ${this.application.current_date.year + 1}</span>
                  </div>
                  <div class="col-6">
                    <button type="submit" class="btn btn-flat-primary btn-sm my-3 f-1 w-100" data-${this.controllerName}-target="changeYear" data-action="click->${this.controllerName}#changeYear">Mudar o Ano</button>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 text-center">
                    <h3>Status: <span>${this.application.current_tax_filing_calculation.journey_status_pretty}</span></h3>
                    <h5>Arquivos Processados <span>${this.application.current_tax_filing_calculation.files_processed}</span> / <span>${this.application.current_tax_filing_calculation.files_approved}</span> Arquivos Aprovados</h5>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class="card" data-action="click->${this.controllerName}#goToUploads">
                      <div class="row my-3">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-primary md-lg">cloud_upload</span>
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Upload de Arquivos</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card" data-action="click->${this.controllerName}#goToFiles">
                      <div class="row my-3">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-primary md-lg">file_present</span>
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Arquivos Carregados</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <div class="card" data-action="click->${this.controllerName}#goToProcess">
                      <div class="row my-3">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-dark md-lg">receipt</span>
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Processamento</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card">
                      <div class="row my-3" data-action="click->${this.controllerName}#goToJourneys">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-primary md-lg">add_road</span>
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Jornada</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6">
                    <div class="card" data-action="click->${this.controllerName}#goToTickets">
                      <div class="row my-3">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-primary md-lg">task_alt</span>
                          ${commentBadge}
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Tickets</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="card" data-action="click->${this.controllerName}#goToNotes">
                      <div class="row my-3">
                        <div class="col-12 text-center my-1 px-0">
                          <span class="material-icons md-primary md-lg">notes</span>
                        </div>
                        <div class="col-12 text-center my-1 px-0">
                          <span class="my-1 f-1">Anotações</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html
  }


  preloaderDashboard() {
    var html = `<div class="row" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:3rem">
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html
  }

  translateJourney(journey) {
    if (journey == `awaiting_documentation`) {
      return `Aguardando Documentação`
    } else if (journey == `documentation_sent`) {
      return `Documentação Enviada`
    } else if (journey == `in_process`) {
      return `Em Processo`
    } else if (journey == `draft_sent`) {
      return `Rascunho Enviado`
    } else if (journey == `awaiting_transmission`) {
      return `Aguardando Transmissão`
    } else if (journey == `transmitted`) {
      return `Transmitido`
    } else if (journey == `rectification`) {
      return `Retificado`
    } else if (journey == `enquiry`) {
      return `Consulta Processamento`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
