import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--index--schedules`
  }

  doOpportunitiesGrid() {
    var html = `<div class="row" data-commercial--sales--opportunities--index--indicators-target="indicators"></div>
                <div class="row my-3">
                  <div class="col-12 px-2" data-commercial--sales--opportunities--index--list-target="table"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doSchedulesDashboard()
    })
  }

  doSchedulesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 px-0" data-users--works--schedules--entities--list-target="main" data-controller="users--works--schedules--entities--list"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--index--dashboard`).contentTarget.innerHTML = html)
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).tableTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`commercial--sales--opportunities--index--submenu`).dashboardToggleBtn(`AGENDAMENTOS`)
      controller.getControllerByIdentifier(`commercial--sales--opportunities--index--indicators`).doIndicatorsCalculation()

      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).controllerForm = controller
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).permission = `sales_opportunities_schedules`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).refreshMode = `kind`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).kind = `sales`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
