import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--notes--dashboard`
  }

  doNotesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--notes--index"></div>
                  <div class="col-8 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--notes--show users--works--notes--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`).dashboardToggleBtn(`ANOTAÇÕES`)

      controller.getControllerByIdentifier(`users--works--notes--index`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--index`).sharing = false
      controller.getControllerByIdentifier(`users--works--notes--show`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.tax_filing.id,
        domain_type: `operation_products`,
        domain_name: `IRPF ${this.application.current_date.name} | ${this.application.tax_filing.account_name}`,
        date_id: this.application.current_date.id,
        date_type: `operation_dates`,
      }
      controller.getControllerByIdentifier(`users--works--notes--index`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.tax_filing.id,
        domain_type: `operation_products`,
        domain_subtype: `operation_tax_filings`,
        domain_name: `IRPF ${this.application.current_date.name} | ${this.application.tax_filing.account_name}`,
        date_id: this.application.current_date.id,
        date_type: `operation_dates`,
      }

      controller.getControllerByIdentifier(`users--works--notes--index`).permission = this.application.note_permission
      controller.getControllerByIdentifier(`users--works--notes--save`).permission = this.application.note_permission
      controller.getControllerByIdentifier(`users--works--notes--show`).permission = this.application.note_permission

      controller.getControllerByIdentifier(`users--works--notes--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--notes--show`).doNoteViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
