
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageBody", "messageInput", "loader"]

  connect() {
    this.welcome()
  }

  welcome() {
    this.preLoader()
    var name = $("#currentUserDataContainer").data("currentUser").name
    var sex = $("#currentUserDataContainer").data("currentUser").sex
    var firstName = $("#currentUserDataContainer").data("currentUser").name.split(" ")[0]
    var speak = `<div class="row"><div class="col"><h5>Olá ${firstName}! Eu sou a Med, a sua assistente virtual na MedCapital!</h5></div></div>`
    if (sex == "male") {
    } else {
    }
    var controller = this
    setTimeout(function () {
      controller.loadMessages(controller, speak)
      setTimeout(function () {
        if (sex == "male") {
          var speak = `<div class="row"><div class="col"><p>Prazer em tê-lo aqui, seja bem-vindo!</p></div></div>`
        } else {
          var speak = `<div class="row"><div class="col"><p>Prazer em tê-la aqui, seja bem-vinda!</p></div></div>`
        }
        controller.loadMessages(controller, speak)
        setTimeout(function () {
          var speak = `<div class="row"><div class="col"><p>Eu também sou nova por aqui, também estou aprendendo. Nós vamos nos falar muito, principalmente por email.</p></div></div>`
          controller.loadMessages(controller, speak)
          setTimeout(function () {
            var speak = `<div class="row"><div class="col"><p>Essa nova plataforma foi feita pensando em nós. Juntos, vamos ganhar eficiência e ter mais produtividade. Veja algumas coisas que já faço hoje:</p></div></div>`
            controller.loadMessages(controller, speak)
            setTimeout(function () {
              var speak = `<div class="row"><div class="col"><p> 1 - Captura de <strong>leads</strong> e agendamento de <strong>consultorias</strong></p></div></div>`
              controller.loadMessages(controller, speak)
              setTimeout(function () {
                var speak = `<div class="row"><div class="col"><p>2 - <strong>Aulas</strong> ao vivo e online com o <strong>MedSchool</strong></p></div></div>`
                controller.loadMessages(controller, speak)
                setTimeout(function () {
                  var speak = `<div class="row"><div class="col"><p>3 - Diversos <strong>conteúdos</strong> sobre finanças com o <strong>Descanso Médico</strong></p></div></div>`
                  controller.loadMessages(controller, speak)
                  setTimeout(function () {
                    var speak = `<div class="row"><div class="col"><p>4 - Lançamentos e <strong>Gestão de Recebimentos</strong> médicos</p></div></div>`
                    controller.loadMessages(controller, speak)
                    setTimeout(function () {
                      var speak = `<div class="row"><div class="col"><p>5 - Ajudo você a organizar as suas <strong>atividades</strong> e tarefas diárias</p></div></div>`
                      controller.loadMessages(controller, speak)
                      setTimeout(function () {
                        var speak = `<div class="row"><div class="col"><p>Futuramente vou fazer:</p></div></div>`
                        controller.loadMessages(controller, speak)
                        setTimeout(function () {
                          var speak = `<div class="row"><div class="col"><p>1 - <strong>Livro-Caixa</strong> para os médicos</p></div></div>`
                          controller.loadMessages(controller, speak)
                          setTimeout(function () {
                            var speak = `<div class="row"><div class="col"><p>2 - A organização da abertura de <strong>PJs Médicas</strong></p></div></div>`
                            controller.loadMessages(controller, speak)
                            setTimeout(function () {
                              var speak = `<div class="row"><div class="col"><p>3 - A emissão de <strong>Nota Fiscal</strong> de forma Online</p></div></div>`
                              controller.loadMessages(controller, speak)
                              setTimeout(function () {
                                var speak = `<div class="row"><div class="col"><p>4 - A apuração de <strong>Impostos</strong> e o envio de relatórios</p></div></div>`
                                controller.loadMessages(controller, speak)
                                setTimeout(function () {
                                  var speak = `<div class="row"><div class="col"><p>5 - E muito mais!</p></div></div>`
                                  controller.loadMessages(controller, speak)
                                  setTimeout(function () {
                                    var speak = `<div class="row"><div class="col"><p>Dúvidas, sugestões e comentários, podem entrar em contato com o nosso Time de Desenvolvimento ou no email develop@medcapital.com.br.</p></div></div>`
                                    controller.loadMessages(controller, speak)
                                    setTimeout(function () {
                                      if (sex == "male") {
                                        var speak = `<div class="row"><div class="col"><p>Mais um vez seja bem-vindo! Conta com a @medcapital</p></div></div>`
                                      } else {
                                        var speak = `<div class="row"><div class="col"><p>Mais um vez seja bem-vinda! Conta com a @medcapital</p></div></div>`
                                      }
                                      controller.loadMessages(controller, speak)
                                      setTimeout(function () {
                                        var speak = `<div class="row my-3"><div class="col">Abraços,<br>Med</div></div>`
                                        controller.loaderTarget.remove()
                                        controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
                                        var finalBtn = `<div class="row my-3"><div class="col"><button data-action="users--notifications--welcome#goToDashboard" class="btn btn-primary btn-sm my-2" style="width:100%;">Começar</button></div></div>`
                                        controller.messageBodyTarget.insertAdjacentHTML("beforeend", finalBtn)
                                        controller.rollMessages()
                                      }, 3000)
                                    }, 5000)
                                  }, 3000)
                                }, 2000)
                              }, 2000)
                            }, 2000)
                          }, 2000)
                        }, 2000)
                      }, 2000)
                    }, 2000)
                  }, 2000)
                }, 2000)
              }, 2000)
            }, 5000)
          }, 5000)
        }, 3000)
      }, 2000)
    }, 2000)
  }

  goToDashboard() {
    firstLogin = false
    const html = `<div data-controller="users--dashboards--main--account" data-users--dashboards--main--account-target="content"></div>`
    this.element.parentElement.innerHTML = html
    // this.remove()
  }

  loadMessages(controller, speak) {
    controller.loaderTarget.remove()
    controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
    controller.preLoader()
    controller.rollMessages()
  }
  
  preLoader() {
    const rowHtml = `<div class='card timeline-item my-2' data-users--notifications--welcome-target="loader">
                        <div class='animated-background animated-background-20'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>`

    this.messageBodyTarget.insertAdjacentHTML("beforeend", rowHtml)
  }

  rollMessages() {
    $("#messageBody").stop().animate({ scrollTop: $("#messageBody")[0].scrollHeight }, 1000);
  }
  
}
