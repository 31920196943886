import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "mainDashboard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `mobile--operations--products--tax-filings--management--notes`
  }

  goToDashboard() {
    this.getControllerByIdentifier("mobile--operations--products--tax-filings--show--dashboard").reconnect()
  }

  doNoteDashboard() {
    var html = `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 text-center px-0">
                    <span class="material-icons md-primary md-lg" data-action="click->${this.controllerName}#goToDashboard">dashboard</span>
                    <p class="mb-0 f-065">Dashboard</p>
                  </div>
                  <div class="col-10 text-center">
                    <h3><strong>Anotações IRPF ${this.application.current_date.year + 1}</strong></h3>
                    <hr class="mb-0">
                  </div>
                </div>
                <div class="row my-3" data-${this.controllerName}-target="body">
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("mobile--operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.current_notes = []
    this.application.notes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_notes[this.current_notes.length] = element
      }
    })

    var notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_notes, `date_time`)

    this.listData(notes)
  }

  listData(data) {
    this.bodyTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="col-10 offset-1 text-center">
                      <div class="card my-3">
                        <div class="media d-flex align-items-center py-3">
                          <span class="material-icons md-lg md-primary mx-3">notes</span>
                          <div class="media-body">
                            <h5 class="mb-0 f-1p5">Não temos Anotações!</h5>
                          </div>
                        </div>
                      </div>
                    </div>`

      this.bodyTarget.innerHTML = noData
    } else {

      var rowHtml = ``
      data.forEach(element => {
        rowHtml += `<div class="col-10 offset-1 text-center">
                        <div class="card my-3">
                          <div class="media d-flex align-items-center py-3" data-toggle="collapse" href="#collapseNotes-${element.id}">
                            <span class="material-icons md-lg md-primary mx-3">notes</span>
                            <div class="media-body text-left">
                              <h5 class="mb-0 f-1">${element.title}</h5>
                              <span class="text-black-secondary">${element.date_pretty}</span>
                            </div>
                          </div>
                          <div id="collapseNotes-${element.id}" class="collapse">
                            <div class="m-1">
                              <div class="card-body px-1 py-0 text-left">
                                ${element.body}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>`
      })

      this.bodyTarget.innerHTML = rowHtml
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
