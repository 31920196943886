import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPaymentCard", "viewTitle", "viewPaymentCardBody", "previewCard", "editPaymentBtn", "deletePaymentBtn", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--payments--show`
    this.send_data = { current_user: {}, payment: {}, notification: {} }
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPaymentCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Pagamento Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewPaymentCardBody"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").viewTarget.innerHTML = html
  }

  editPayment() {

    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--bookings--payments--save").current_payment = this.current_payment
      this.getControllerByIdentifier("operations--products--bookings--payments--save").actionMode = "edit"
      this.getControllerByIdentifier("operations--products--bookings--payments--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }

  }

  deletePayment() {
    var r = confirm("Desejar apagar o Pagamento?")
    this.deletePaymentBtnTarget.disabled = true

    if (r) {
      this.actionMode = "delete"

      this.send_data.payment.id = this.current_payment.id
      this.send_data.payment.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.notification.domain_id = this.application.booking.id
      this.send_data.notification.domain_type = "operation_products"
      this.send_data.notification.date_id = this.application.current_date.id
      this.send_data.notification.date_type = "operation_dates"
      this.send_data.notification.kind = `mode_${this.actionMode}`
      this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o pagamento ${this.current_payment.chart}, no valor de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_payment.amount)} para a competência de ${this.application.current_date.month_pretty}.`
      this.send_data.notification.account_name = this.application.current_user.name
      this.send_data.notification.account_id = this.application.current_user.account_id

      this.requestSave()
    } else {
      this.deletePaymentBtnTarget.disabled = false
    }
    
  }

  requestSave() {

    var url = "/operations/products/bookings/payments/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var payment = response.data.cln

          if (payment.active) {
            controller.application.payments.forEach((element, i) => {
              if (element.id == payment.id) {
                controller.application.payments.splice(controller.application.payments.indexOf(element), 1, payment)
              }
            })
          } else {
            controller.application.payments.forEach((element, i) => {
              if (element.id == payment.id) {
                controller.application.payments.splice(controller.application.payments.indexOf(element), 1)
              }
            })
          }
          controller.getControllerByIdentifier("operations--products--bookings--payments--index").doDataTable()
          controller.doViewHtml()
          controller.send_data = { current_user: {}, payment: {}, notification: {} }
        } else {
          controller.deletePaymentBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })

  }


  doPaymentViewHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.medbooking_payments.can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deletePaymentBtn" data-action="click->${this.controllerName}#deletePayment" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Pagamento</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.medbooking_payments.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editPaymentBtn" data-action="click->${this.controllerName}#editPayment" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Pagamento</span>`
    } else {
      var editBtnHtml = ``
    }

    if (this.current_payment.id_type == "individual") {
      var idType = `CPF`
    } else if (this.current_payment.id_type == "legal") {
      var idType = `CNPJ`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Pagamento Consultório</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Beneficiário</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.id_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>${idType}</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.id_number_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Valor</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_payment.amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Data</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.date_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Despesa</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.chart}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Descrição</strong> <br>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_payment.description}
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>

                            

                            <tr>
                              <td colspan="10" class="f-065 align-middle px-0 text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                                <small>Documento <strong>com validade</strong> para comprovante de despesa dedutível de Livro-Caixa Médico apenas na Declaração de Ajuste Anual Ano-Exercício ${this.application.current_date.year + 1}.</small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.viewPaymentCardBodyTarget.innerHTML = html
    // this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").showTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--payments--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
