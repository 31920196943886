import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "medDropdown", "medDropdownBtn", "medInput", "medList", "chartInput", "chartFilter", "statusCheck",
                    "cardDropdown", "cardDropdownBtn", "cardInput", "cardList", "cardDiv", "saveBtn", "subkindCheck",
                    "providerModal", "providerInput"]


  connect() {
    this.controllerName = `financials--books--dashboards--channels--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--dashboards--channels--dashboard").doChannelsDashboard()
  }

  saveChannel() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    
    this.send_data = { current_user: {}, channel: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.channel.id = this.current_channel.id
    }
    this.send_data.channel.chart_id = this.chartInputTarget.dataset.filter
    this.send_data.channel.med_id = this.medInputTarget.dataset.medId
    this.send_data.channel.provider_id = this.providerInputTarget.dataset.providerId
    this.send_data.channel.provider_name = this.providerInputTarget.value.trim()
    this.send_data.channel.name = this.nameInputTarget.value.trim()
    this.send_data.channel.kind = this.kindInputTarget.dataset.kind
    this.send_data.channel.status = this.statusCheckTarget.checked
    if (this.subkindCheckTarget.checked) {
      this.send_data.channel.subkind = `main`
    } else {
      this.send_data.channel.subkind = `secondary`
    }
    
    if (this.kindInputTarget.dataset.kind == `credit_card`) {
      this.send_data.channel.card_id = this.cardInputTarget.dataset.cardId
    } else {
      this.send_data.channel.card_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_channels`

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/financials/books/statements/channels/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/statements/channels/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var channelIds = controller.mapElements(controller.application.channels, `id`)
          response.data.cln.forEach(channel => {
            if (channelIds.includes(channel.id)) {
              controller.application.channels.forEach((element, i) => {
                if (element.id == channel.id) {
                  controller.application.channels.splice(controller.application.channels.indexOf(element), 1, channel)
                }
              })
            } else {
              controller.application.channels[controller.application.channels.length] = channel
            }
          })

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    // `<input readonly="readonly" class="form-control f-075" data-${this.controllerName}-target="chartInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#chartFilter keyup->${this.controllerName}#chartFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterChart" type="text" placeholder="Plano de Conta" required>
    // <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="chartFilter"></ul>`
    this.stopRefreshing()

    if (this.application.permissions.financial_settings_channels.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveChannel" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-8 offset-2">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-3">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="cash" data-action="click->${this.controllerName}#listCards click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Caixa/Dinheiro</li>
                                      <li data-kind="bank_account" data-action="click->${this.controllerName}#listCards click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Conta Bancária</li>
                                      <li data-kind="payment" data-action="click->${this.controllerName}#listCards click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Instituição Pagamento</li>
                                      <li data-kind="credit_card" data-action="click->${this.controllerName}#listCards click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>MedCapital</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="medDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="medDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="medInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="medList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-1 pr-1">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="chartInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Contas</span>
                            </button>
                          </div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Plano de Conta</label>
                                <input readonly="readonly" class="form-control f-075" data-${this.controllerName}-target="chartInput" type="text" placeholder="Plano de Conta" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showProviders" data-element="providerInput" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                            </button>
                          </div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Fornecedor</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">

                          <div class="col-6 d-none" data-${this.controllerName}-target="cardDiv">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Cartão de Crédito</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="cardDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="cardDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="cardInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="cardList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input disabled aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">

                          <div class="col-6">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="subkindCheck" data-${this.controllerName}-target="subkindCheck">
                              <label class="custom-control-label f-065 pointer" for="subkindCheck">Principal Canal de Pagamento</label>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                              <input type="checkbox" class="custom-control-input" id="statusCheck" data-${this.controllerName}-target="statusCheck">
                              <label class="custom-control-label f-065 pointer" for="statusCheck">Canal Ativo</label>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--dashboards--channels--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Canal`
        controller.nameInputTarget.value = controller.current_channel.name

        controller.kindInputTarget.innerText = controller.current_channel.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_channel.kind
        controller.kindDropdownTarget.value = controller.current_channel.kind

        if (controller.current_channel.kind == `credit_card`) {
          this.cardDivTarget.classList.remove("d-none")
          controller.cardInputTarget.innerText = controller.current_channel.card_name
          controller.cardInputTarget.dataset.cardId = controller.current_channel.card_id
          controller.cardDropdownTarget.value = controller.current_channel.card_name
        } else {
          this.cardDivTarget.classList.add("d-none")
          controller.cardInputTarget.innerText = ``
          controller.cardInputTarget.dataset.cardId = ``
          controller.cardDropdownTarget.value = ``
        }

        if (controller.current_channel.subkind == `main`) {
          controller.subkindCheckTarget.checked = true
        } else {
          controller.subkindCheckTarget.checked = false
        }
        controller.statusCheckTarget.checked = this.current_channel.status

        controller.medInputTarget.innerText = controller.current_channel.med_name
        controller.medInputTarget.dataset.medId = controller.current_channel.med_id
        controller.medDropdownTarget.value = controller.current_channel.med_id

        controller.chartInputTarget.value = controller.current_channel.chart_name
        controller.chartInputTarget.dataset.filter = controller.current_channel.chart_id

        controller.providerInputTarget.value = controller.current_channel.provider_name
        controller.providerInputTarget.dataset.providerId = controller.current_channel.provider_id
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Canal`
        controller.statusCheckTarget.checked = true
      }
      
      // controller.listChart()
      controller.listMeds()
      controller.channelNameInterval()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listMeds() {
    var html = ``

    this.application.meds.forEach(element => {
      html += `<li data-med-id="${element.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
    })
    
    this.medListTarget.innerHTML = html
  }

  listChart() {
    var html = ``

    this.application.chart_accounts.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectChart" data-${this.controllerName}-target="chartFilterItem" data-text="${element.chart_name}" data-filter="${element.id}" class="li-selector dark f-065">${element.chart_name}</li>`
    })
    
    this.chartFilterTarget.innerHTML = html
  }

  listCards(ev) {
    var kind = ev.currentTarget.dataset.kind

    if (kind == `credit_card`) {
      this.cardDivTarget.classList.remove("d-none")
      var html = ``

      this.application.cards.forEach(element => {
        html += `<li data-card-id="${element.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${element.name}</li>`
      })

      this.cardListTarget.innerHTML = html
    } else {
      this.cardDivTarget.classList.add("d-none")
    }
    
  }

  chartFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectChart(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterChart(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = true
      controller.getControllerByIdentifier(modalName).permission = `financial_settings_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_payable_providers`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  checkChartAccount(group) {
    var groupArray = ["credit_card",
                      "availabilities",]


    return groupArray.includes(group)
  }

  setChannelName() {
    var chartId = this.chartInputTarget.dataset.filter
    var medName = this.medInputTarget.innerText
    var chartName = ``

    this.application.chart_accounts.forEach(chart => {
      if (chart.id == chartId) {
        chartName = chart.name
      }
    })

    this.nameInputTarget.value = `${medName} - ${chartName}`
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.medInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.chartInputTarget.value == ``) {
        len += 1
      }

      if (controller.providerInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  channelNameInterval() {
    var controller = this
    this.channelNameTimer = setInterval(function () {
      if (controller.chartInputTarget.value != ``) {
        controller.setChannelName()
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    if (this.channelNameTimer) {
      clearInterval(this.channelNameTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }


  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
