import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "subtitle", "ticketsInProcess", "ticketsFlow", "ticketsTotal", "cardFilter", "search", "searchInput",
                    "prospectingFlow", "planningFlow", "bookingFlow", "meetingFlow", "proposalFlow", "closingFlow",
                    "prospectingTotal", "planningTotal", "bookingTotal", "meetingTotal", "proposalTotal", "closingTotal",
                    "prospectingAdmission", "planningAdmission", "bookingAdmission", "meetingAdmission", "proposalAdmission", "closingAdmission",
                    "team", "teamDropdown", "teamDropdownBtn", "teamInput", "teamList",
                    "ticketsTotal", "ticketsInProcess", "cardFilter", "ticketsFlow", "search"]

  connect() {
    this.controllerName = `commercial--marketing--events--dealflow--dashboard`
    this.account_id = this.application.current_user.account_id
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.totalColumns = 4
    this.journeys = this.eventJourneys()
  }

  doSearchHtml() {
    var html = `<input class="form-control f-075" data-${this.controllerName}-target="searchInput" data-action="keyup->${this.controllerName}#doSearch" id="" placeholder="Buscar Evento..." type="text">`
    // this.searchTarget.innerHTML = html
  }

  doEventFlowGrid() {

    var uniqJourneys = this.journeys.filter((value, index, self) => self.map(x => x.name).indexOf(value.target) == index)
    var htmlCol = ``

    this.journeys.forEach(element => {
      htmlCol += `<div class="col-${Math.round(12 / this.totalColumns)}">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${element.name_pretty}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1 f-065">
                            Total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="${element.name}Total">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1 f-065">
                            Pessoas
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="${element.name}Admission">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="${element.name}Flow"></div>
                  </div>`
    })

    var html = `<div class="row my-2" data-controller="commercial--marketing--events--dealflow--flow">
                  <div class="col-9">
                    <div class="row">
                      ${htmlCol}
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Tickets</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            Total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            Em Aberto
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsInProcess">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="today">
                            <span>Hoje</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="previous">
                            <span>Atrasadas</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="next">
                            <span>Próximas</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsFlow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--events--dealflow--flow").doDataFlow()
      controller.getControllerByIdentifier("commercial--marketing--events--dealflow--flow").doDataIndicator()
    })
  }

  doTicketsHtml() {
    this.in_process_tickets = []
    this.resolved_tickets = []
    this.application.tickets.forEach(element => {
      if (element.stage == `in_process`) {
        this.in_process_tickets[this.in_process_tickets.length] = element
      } else if (element.stage == `resolved`) {
        this.resolved_tickets[this.resolved_tickets.length] = element
      }
    })
    this.ticketsTotalTarget.innerText = this.application.tickets.length
    this.ticketsInProcessTarget.innerText = this.in_process_tickets.length
    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)

    this.listData(tickets)
  }

  filterTicketDueAt(ev) {
    var due = ev.currentTarget.dataset.due
    var date = new Date()
    var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    var data = []

    if (due == `today`) {
      this.in_process_tickets.forEach(element => {
        if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
          data[data.length] = element
        }
      })
    } else if (due == `previous`) {
      this.in_process_tickets.forEach(element => {
        // if (element.status == "delay") {
        //   data[data.length] = element
        // }
        if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
          data[data.length] = element
        }
      })
    } else if (due == `next`) {
      this.in_process_tickets.forEach(element => {
        if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
          data[data.length] = element
        }
      })
    }

    this.cardFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    ev.currentTarget.classList.add("bg-primary")

    this.listData(data)
  }

  listData(data) {
    this.ticketsFlowTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="row w-100" style="height:100px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tickets</span>
                      </div>
                    </div>`

      this.ticketsFlowTarget.innerHTML = noData
    } else {
      var place = 1
      var elements = []
      var columns = 4
      var rows = Math.floor(data.length / columns) + 1
      var row = 1
      if (data.length <= columns) {
        data.forEach(element => {
          elements[elements.length] = element
        })
        this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
      } else {
        data.forEach(element => {
          elements[elements.length] = element
          place += 1

          if (place > columns) {
            this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
            place = 1
            elements = []
            row += 1
          }
        })
        if (row == rows) {
          this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
        }
      }
    }
  }

  ticketPartial(elements) {
    var elementHtml = ``
    elements.forEach(element => {
      var viewBtn = `<div class="col-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                      <span class="material-icons md-150 md-dark pointer" data-ticket-id="${element.id}" data-action="click->${this.controllerName}#goToURL" data-url="${element.url}">launch</span>
                      <span class="mc-tooltiptext">Ver no Evento</span>
                    </div>`

      var dateBtn = `<div class="col-6 mr-auto px-1 d-flex align-items-center mc-tooltip">
                      <span>${element.due_at_pretty}</span>
                      <span class="mc-tooltiptext">Data de Entrega</span>
                    </div>`
      var performerBtn = `<div class="col-1 ml-auto px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 md-dark pointer">person</span>
                            <span class="mc-tooltiptext">${element.performer_name}</span>
                          </div>`

      elementHtml += `<div class="col-12 px-1 mb-2">
                        <div class="card border-flag-${element.flag}" data-id="${element.id}">
                          <div class="card-header p-1 text-center f-065 d-flex">
                            ${dateBtn}
                            ${performerBtn}
                            ${viewBtn}
                          </div>
                          <div class="card-body p-1 text-center f-065" data-${this.controllerName}-target="ticketCardBody">
                            <div class="row py-0 px-0">
                              <div class="col-6 pl-0 pr-2 d-flex align-items-center text-left ticket-content" data-${this.controllerName}-target="bodyShow-${element.id}">${element.body}</div>
                              <div class="col-6 pl-2 pr-0 text-left ticket-content border-left-primary-sm">${element.event}</div>
                            </div>
                          </div>
                        </div>
                      </div>`
    })

    var html = elementHtml

    return html
  }

  doSearch(ev) {
    var filter = ev.target.value.toUpperCase()
    var filterUnaccent = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").eventCardTargets.forEach(element => {
      var txtValue = element.innerText.toUpperCase()
      var txtValueUnaccent = txtValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      var show = false
      if ((txtValue.indexOf(filter) > -1) || (txtValueUnaccent.indexOf(filterUnaccent) > -1)) {
        show = true;
      }
      if (show == true) {
        element.closest(".cardRow").classList.remove("d-none")
      } else {
        element.closest(".cardRow").classList.add("d-none")
      }
    })
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  setEvents() {
    this.doSearchHtml()
    this.doEventFlowGrid()
    this.setTickets()
  }

  setTickets() {
    this.application.tickets = []

    this.application.events.forEach(event => {
      event.tickets.forEach(ticket => {
        if (ticket.owner_id == this.account_id || ticket.performer_id == this.account_id) {
          ticket.url = `/a/eventos/${event.path}`
          ticket.event = event.title
          this.application.tickets[this.application.tickets.length] = ticket
        }
      })
    })

    this.doTicketsHtml()
  }



  doFlowGrid() {

    var htmlCol = ``
    for (let index = 0; index < this.totalColumns; index++) {
      htmlCol += `<div class="col-${Math.round(12/this.totalColumns)}">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                  </div>`
      
    }

    var html = `<div class="row my-2">
                  <div class="col-9">
                    <div class="row">
                      ${htmlCol}
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsFlow">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.setEvents()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  eventJourneys() {

    var journeys = [
                      {
                        name: `planning`,
                        name_pretty: `Planejamento`,
                        next: `prospecting`,
                        next_pretty: `Prospecção`,
                      },
                      {
                        name: `prospecting`,
                        name_pretty: `Prospecção`,
                        next: `proposal`,
                        next_pretty: `Proposta/Agendamento`,
                      },
                      {
                        name: `proposal`,
                        name_pretty: `Proposta/Agendamento`,
                        next: `meeting`,
                        next_pretty: `Agendado`,
                      },
                      {
                        name: `meeting`,
                        name_pretty: `Agendado`,
                        next: `finished`,
                        next_pretty: `Realizado`,
                      },
                    ]

    return journeys
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
