
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card", "result"]

  connect() {
    this.controllerName = `users--profile--account`
    this.doAccountHtml()
  }

  doAccountHtml() {
    var name = this.application.user.name
    var cpf = fromNumberToCpf(this.application.user.cpf)
    var email = this.application.user.email

    if (this.application.users.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">file_copy</i></button>`
    } else {
      var copyPath = ``
    }

    var html = `<div class="row my-4">
                    <div class="col-10">
                      <div class="card border-top-primary">
                        <div class="card-body" data-${this.controllerName}-target="body">
                          <table class="table table-sm table-hover table-search" style="font-size:80%;">
                            <tbody>
                              <tr>
                                <th><strong>Nome</strong></th>
                                <td>${name}</td>
                                <td class="p-1 align-middle" data-copy="${name}">${copyPath}</td>
                              </tr>
                              <tr>
                                <th><strong>CPF</strong></th>
                                <td>${cpf}</td>
                                <td class="p-1 align-middle" data-copy="${cpf}">${copyPath}</td>
                                <td></td>
                              </tr>
                              <tr>
                                <th><strong>E-mail</strong></th>
                                <td>${email}</td>
                                <td class="p-1 align-middle" data-copy="${email}">${copyPath}</td>
                              </tr>
                              <tr>
                                <th><strong>Senha</strong></th>
                                <td>****</td>
                                <td><button type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Trocar Senha" data-action="click->${this.controllerName}#updatePassword"><span class="material-icons md-sm md-dark">vpn_key</span></button></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>`


    this.cardTarget.innerHTML = html
    var height = $(window).height();
    // $('.card').css('height', height * 0.5);
    tooltip()
  }

  updatePassword() {
    var userId = this.application.user.id
    if (userId == this.application.current_user.id) {
      const data = { user: { id: userId }, current_user: { current_user_id: this.application.current_user.id, feature: "users" } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/update_password"
      const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            processingSnackbar(response.type, response.message, device, 2000)
          } else {
            processingSnackbar(response.type, response.message, device, 2000)
          }
        })

    }
  }

  

}
