import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "clients", "list", "view", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--contracts--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doContractsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4 px-2" data-${this.controllerName}-target="clients" data-controller="financials--books--clients--entities--list"></div>
                  <div class="col-4 px-2" data-${this.controllerName}-target="list" data-controller="financials--books--receivables--contracts--list"></div>
                  <div class="col-4 px-2" data-${this.controllerName}-target="view" data-controller="financials--books--receivables--contracts--show
                                                                                                     financials--books--receivables--contracts--save
                                                                                                     financials--books--clients--addons--list
                                                                                                     financials--books--clients--addons--save
                                                                                                     financials--books--clients--addons--upload" style="overflow:auto;"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.viewTarget.style.height = ($(window).height() * 0.65) + "px"

      controller.getControllerByIdentifier(`financials--books--clients--entities--list`).gridElement = controller.getControllerByIdentifier("financials--books--receivables--contracts--dashboard").clientsTarget
      controller.getControllerByIdentifier(`financials--books--clients--entities--list`).controllerDashboard = controller
      controller.getControllerByIdentifier(`financials--books--clients--entities--list`).permission = `financial_receivable_clients`
      controller.getControllerByIdentifier(`financials--books--clients--entities--list`).doClientList()
      controller.getControllerByIdentifier("financials--books--receivables--contracts--list").doNoContract()
      controller.getControllerByIdentifier(`financials--books--receivables--contracts--show`).doNoContract()

      controller.getControllerByIdentifier(`financials--books--clients--addons--list`).controllerDashboard = controller.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

}
