import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "passwordInput", "confirmationInput", "saveBtn", "formCard", "formTitle", "formCardBody", "nameInput",
                    "emailInput", "sendPasswordBtn", "sendInstructionsBtn"]

  connect() {
    this.controllerName = `operations--products--bookings--helpers--profile`
    this.doHtml()
  }

  doHtml() {
    if (this.application.current_helper.has_user) {
      this.getHelperUser()
    } else {

      if (this.application.permissions.medbooking_helper_profiles.can_create) {
        var createBtn = `<figure class="figure btn btn-flat text-center mc-tooltip" data-action="click->${this.controllerName}#doFormHtml">
                          <span class="material-icons md-primary md-300">person_add</span>
                          <span class="mc-tooltiptext">Criar Usuário</span>
                        </figure>`
      } else {
        var createBtn = ``
      }

      var html = `<div class="row my-5 d-flex align-items-center justify-content-between">
                    <div class="col-6 py-3 text-right">
                      <span class="f-1 f-bold">Conta sem Usuário</span>
                    </div>
                    <div class="col-6 py-3 text-left">
                      ${createBtn}
                    </div>
                  </div>`

      this.mainTarget.innerHTML = html
    }
  }

  doFormHtml() {
    var html = `<div class="col-12 px-0 mx-auto">
                  <div class="" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando Usuário para ${this.application.current_helper.account_name}</h6>
                    </div>
                    <div class="card-body px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="nameForm">Nome</label>
                              <input aria-describedby="nameFormHelp" id="nameForm" data-${this.controllerName}-target="nameInput" class="form-control" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="emailForm">E-mail</label>
                              <input aria-describedby="emailFormHelp" id="emailForm" data-${this.controllerName}-target="emailInput" class="form-control" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="passwordUserForm">Senha</label>
                              <input aria-describedby="passwordUserFormHelp" class="form-control" id="passwordUserForm" data-${this.controllerName}-target="passwordInput" placeholder="Senha" type="password" required>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="passwordConfirmationUserForm">Confirmação de Senha</label>
                              <input aria-describedby="passwordConfirmationUserFormHelp" class="form-control" id="passwordConfirmationUserForm"data-${this.controllerName}-target="confirmationInput"  placeholder="Confirmação de Senha" type="password" required>
                            </div>
                          </div>
                        </div>
                      </div>                  
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#createUser" data-${this.controllerName}-target="saveBtn">Salvar</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()

      controller.nameInputTarget.value = controller.application.current_helper.account_name
      // controller.emailInputTarget.value = product_data.account.email
      controller.nameInputTarget.disabled = true
      controller.emailInputTarget.disabled = true

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  getHelperUser() {
    this.send_data = { current_user: {}, user: {}, account: {} }
    this.send_data.account.id = this.application.current_helper.account_id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking_helper_profiles`

    var url = "/users/accounts/entities/read_user"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.user = response.data.cln.user
          controller.checkHelperPermissions()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  checkHelperPermissions() {
    const data = { product: { kind: `medbooking` }, account: { account_id: this.user.account_id, account_kind: this.user.account_kind }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/check_if_has_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    this.request = { url: url, init: init }
    this.requestPermissions()
  }

  requestPermissions() {
    var controller = this
    fetch(this.request.url, this.request.init)
      .then(response => response.json())
      .then(response => {
        var has_permissions = response.data.cln.has_permissions
        controller.user.has_permissions = has_permissions
        controller.doProfileHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  savePermissions() {
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
    const data = { product: { kind: `medbooking` }, account: { account_id: this.user.account_id }, current_user: { current_user_id: this.application.current_user.id, permission: "assign", feature: "medbooking_helper_profiles" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/save_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.checkHelperPermissions()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  destroyPermissions(ev) {
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
    const data = { product: { kind: `medbooking` }, account: { account_id: this.user.account_id }, current_user: { current_user_id: this.application.current_user.id, permission: "assign", feature: "medbooking_helper_profiles" } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/delete_product_permissions"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.checkHelperPermissions()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  doProfileHtml() {
    if (this.user.blocked) {
      var blockedBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-blocked="${this.user.blocked}" data-action="click->${this.controllerName}#blockUser">
                          <span class="material-icons md-sm md-danger">toggle_off</span>
                          <span class="mc-tooltiptext">Deseja Desbloquear?</span>
                        </button>`
    } else {
      var blockedBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-blocked="${this.user.blocked}" data-action="click->${this.controllerName}#blockUser">
                          <span class="material-icons md-sm md-success">toggle_on</span>
                          <span class="mc-tooltiptext">Deseja Bloquear?</span>
                        </button>`
    }

    if (this.application.permissions.medbooking_helper_profiles.can_update) {
      var sendPasswordBtn = `<button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#sendPasswordInstructions" data-${this.controllerName}-target="sendPasswordBtn">
                          <span class="material-icons md-sm md-dark">send</span>
                          <span class="mc-tooltiptext">Enviar Link Para Nova Senha</span>
                        </button>`
    } else {
      var sendPasswordBtn = ``
    }

    if (this.application.permissions.medbooking_helper_profiles.can_copy) {
      var copyBtn = `<button type="button" class="btn btn-sm btn-table copy p-0 mc-tooltip">
                        <span class="material-icons md-sm md-light">file_copy</span>
                        <span class="mc-tooltiptext">Copiar</span>
                      </button>`
    } else {
      var copyBtn = ``
    }

    if (this.user.has_unconfirmed_email) {
      this.user.email = this.user.unconfirmed_email
    }

    if (this.user.has_unconfirmed_email || this.user.has_unconfirmed) {
      var emailConfirmation = `<td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%">Confirmado: </td>
                                <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">E-mail não confirmado, copiar Link Confirmação</td>
                                <td class="table-15 align-middle"></td>
                                <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                                  <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#sendConfirmationEmail" data-${this.controllerName}-target="sendInstructionsBtn">
                                    <span class="material-icons md-sm md-dark">send</span>
                                    <span class="mc-tooltiptext">Enviar Email de Instruções</span>
                                  </button>
                                </td>`
    } else {
      var emailConfirmation = `<td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%">Confirmado: </td>
                              <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.confirmed_at_full}</td>`
    }

    if (this.application.permissions.medbooking_helper_profiles.can_assign) {
      if (this.user.has_permissions) {
        var permissionStatus = `<span class="mc-tooltip">
                                <span class="material-icons md-success md-150 pointer">done_all</span>
                                <span class="mc-tooltiptext">Tem Permissões</span>
                              </span>`
      } else {
        var permissionStatus = `<button data-action="click->${this.controllerName}#savePermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                  <span class="material-icons md-sm md-dark">slideshow</span>
                                  <span class="mc-tooltiptext">Gerar Permissões</span>
                                </button>`
      }
      var destroyPermission = `<button data-action="click->${this.controllerName}#destroyPermissions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                    <span class="material-icons md-sm md-dark">delete</span>
                                    <span class="mc-tooltiptext">Apagar Permissões</span>
                                  </button>`
    } else {
      if (this.user.has_permissions) {
        var permissionStatus = `<span class="mc-tooltip">
                                <span class="material-icons md-success md-150 pointer">done_all</span>
                                <span class="mc-tooltiptext">Tem Permissões</span>
                              </span>`
      } else {
        var permissionStatus = `<span class="mc-tooltip">
                                  <span class="material-icons md-danger md-150 pointer">clear</span>
                                  <span class="mc-tooltiptext">Sem Permissões</span>
                                </span>`
      }
      var destroyPermission = ``
    }

    var html = `<div class="row my-1">
                  <div class="col-12">
                    <table class="table table-sm table-search" style="font-size:80%;">
                      <tbody>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">folder_shared</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%">Página Perfil: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                            <button type="button" class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#goToProfile">
                              <span class="material-icons md-sm md-dark">visibility</span>
                              <span class="mc-tooltiptext">Ver Página Perfil</span>
                            </button>
                          </td>
                          <td class="table-15 align-middle"></td>
                          <td class="align-middle"></td>
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">verified_user</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%">Status: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.blocked_pretty}</td>
                          <td class="table-15 align-middle"></td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                            ${blockedBtn}
                          </td>
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td rowspan="2" nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">contact_mail</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%">E-mail: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.email}</td>
                          <td class="table-15 align-middle"></td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%" data-copy="${this.user.email}">
                            ${copyBtn}
                          </td>
                        </tr>
                        <tr class="" style="font-size:40%;">
                          ${emailConfirmation}
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">account_box</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Login: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.cpf_pretty}</td>
                          <td class="table-15 align-middle"></td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%" data-copy="${this.user.cpf}">
                            ${copyBtn}
                          </td>
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">vpn_key</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Senha: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%"> **** </td>
                          <td class="table-15 align-middle"></td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">
                            ${sendPasswordBtn}
                          </td>
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td rowspan="3" nowrap class="table-3 p-0 pl-2  align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">query_builder</i></td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Criado em: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.created_at_full}</td>
                          <td rowspan="3" class="table-15 align-middle"></td>
                          <td rowspan="3" class="align-middle"></td>
                        </tr>
                        <tr class="" style="font-size:40%;">
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Último acesso em: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.last_sign_in_at_full}</td>
                        </tr>
                        <tr class="" style="font-size:40%;">
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Total Acessos: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%">${this.user.sign_in_count}</td>
                        </tr>
                        <tr class="itemRow" style="font-size:40%;" data-user-id="${this.user.id}">
                          <td nowrap class="table-3 p-0 pl-2 align-middle" scope="row" style="font-size:200%"><i class="material-icons s-title-1rem">visibility</i> </td>
                          <td nowrap class="table-10 pl-2 align-middle" scope="row" style="font-size:200%"> Permissiões: </td>
                          <td nowrap class="table-40 align-middle" scope="row" style="font-size:200%"></td>
                          <td class="table-15 align-middle">
                            ${permissionStatus}
                          </td>
                          <td class="table-15 align-middle">
                            ${destroyPermission}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  goToProfile() {
    if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) { 
      window.open(`/a/minha-conta/${this.user.slug}`, '_self')
    } else if (this.application.current_user.account_kind == `doctor`) {
      window.open(`/d/minha-conta/${this.user.slug}`, '_self')
    }
  }

  sendConfirmationEmail() {
    this.sendInstructionsBtnTarget.disabled = true
    this.send_data = { current_user: {}, user: {} }

    this.send_data.user.id = this.user.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking_helper_profiles`

    var url = "/users/send_confirmation_email"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.sendInstructionsBtnTarget.disabled = false
        if (response.save) {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendPasswordInstructions() {
    this.sendPasswordBtnTarget.disabled = true
    this.send_data = { current_user: {}, user: {} }

    this.send_data.user.id = this.user.id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking_helper_profiles`

    var url = "/users/update_password"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.sendPasswordBtnTarget.disabled = false
        if (response.save) {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  createUser() {
    this.stopRefreshing()
    this.actionMode = `new`
    this.send_data = { current_user: {}, user: {}, account: {} }

    this.send_data.user.password = this.passwordInputTarget.value
    this.send_data.user.password_confirmation = this.confirmationInputTarget.value
    this.send_data.account.account_id = this.application.current_helper.account_id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking_helper_profiles`

    this.requestSave()
  }

  blockUser(ev) {
    var actualBlocked = this.getControllerByIdentifier("app--helpers--strings").fromStringToBoolean(ev.currentTarget.dataset.blocked)
    this.actionMode = `edit`
    this.send_data = { current_user: {}, user: {}, account: {} }

    this.send_data.user.id = this.user.id
    if (actualBlocked) {
      this.send_data.user.blocked = false
      this.send_data.user.blocked_at = null
      var r = confirm(`Tem certeza que deseja desbloquear o ${this.user.name}?`)
    } else {
      this.send_data.user.blocked = true
      this.send_data.user.blocked_at = new Date()
      var r = confirm(`Tem certeza que deseja bloquear o ${this.user.name}?`)
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medbooking_helper_profiles`

    if (r) {
      this.requestSave()
    }
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.current_helper.has_user = true
          controller.doHtml()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  cancelSave() {
    this.doHtml()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.passwordInputTarget.value == ``) {
        len += 1
      }

      if (controller.confirmationInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
