import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "ticketBoard", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--show--tickets`
  }

  doTicketsDashboard() {
    var html = `<div class="col-12 pl-1" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--events--show--dashboard`).ticketsRowTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = `commercial_event_tickets`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 4
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).kind = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).term = true
      // controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = {
        board_id: this.application.event.id,
        board_type: `marketing_events`,
        board_subtype: `marketing_events`,
        board_path: window.location.pathname,
        board_name: `Evento | ${this.application.event.title}`,
        // date_id: this.application.current_date.id,
        // date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = {
        board_id: this.application.event.id,
        board_type: `marketing_events`,
        // date_id: this.application.current_date.id,
        // date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).doIndexListHtml()

    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
