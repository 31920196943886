import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "journey", "call", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--recruitments--schedules--dashboard`
    this.blockedAdd = false
  }

  doCallsDashboard() {
    var html = `<div class="col-12" data-app--communications--voips--calls--list-target="main" data-controller="app--communications--voips--calls--list"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--recruitments--vacancies--show--dashboard`).callsRowTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).permission = `user_works_recruitments_calls`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordId = this.application.vacancy.id
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).recordType = `recruitment_vacancies`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).dataMode = `record`
      controller.getControllerByIdentifier(`app--communications--voips--calls--list`).doListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
