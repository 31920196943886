import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "card", "addNewOpportunityBtn", "4444", "5555", "opportunityCard", "opportunityLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--dealflow--flow`
    this.controllerDashboard = this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--dashboard")
  }

  doDataFlow() {

    this.application.opportunities.forEach(element => {
      this.doSimpleDataFlow(element)
    })
    this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--dashboard").doTicketsHtml()
  }

  doSimpleDataFlow(element) {
    // `<div class="col-3 mb-1 px-1 d-flex align-items-center justify-content-center">
    //   <span class="mc-tooltip pointer">
    //     <span class="material-icons md-sm md-danger">warning</span>
    //     <span class="mc-tooltiptext">Ticket(s) em Atraso</span>
    //   </span>
    //   <span class="mc-tooltip mx-auto pointer" data-action="click->${this.controllerName}#addTicket">
    //     <span class="material-icons md-sm md-dark">add</span>
    //     <span class="mc-tooltiptext">Novo Ticket</span>
    //   </span>
    // </div>`
    
    element.card_name = ``
    element.ticket_name = ``
    element.leads.forEach(lead => {
      element.ticket_name += `<h6 class="mb-0 f-065">${lead.lead_name}</h6>`
      element.card_name += `<h6 class="mb-0 f-065" data-${this.controllerName}-target="opportunityLeadName">${lead.lead_name}</h6>`
    })

    if (element.stage_alert) {
      var alert = `<div class="col-1 px-0 mc-tooltip pointer">
                    <span class="material-icons md-sm md-danger">warning</span>
                    <span class="mc-tooltiptext">Atenção! Oportunidade vai esfriar!</span>
                  </div>`
    } else {
      var alert = ``
    }

    if (element.products[0]) {
      if (element.products[0].recurring) {
        var amount = `<span class="mb-0 f-065">${element.products[0].name_pretty}</span>
                      <span class="mb-0 f-065 ml-auto">Recorrente: ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_amount_recurring * 100))}</span>`
      } else {
        var amount = `<span class="mb-0 f-065">${element.products[0].name_pretty}</span>
                      <span class="mb-0 f-065 ml-auto">Avulso: ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.total_amount_separate * 100))}</span>`
      }
    } else {
      var amount = `Produto Não Definido`
    }

    var campaign = `<span class="mb-0 f-065">${element.campaign_pretty}</span>
                    <span class="mb-0 f-065 ml-auto">${element.origin}</span>`

    var html = `<div class="row my-2 cardRow" data-id="${element.id}" data-stage="${element.stage}" data-stage-number="${element.stage_number}" data-url="${element.url}" data-${this.controllerName}-target="card-${element.id} opportunityCard">
                 <div class="col-12 px-1">
                   <div class="card">
                     <div class="card-body px-0 pt-1 pb-0 f-065">
                       <div class="row my-2 d-flex align-items-center">
                         <div class="col-12 px-1 pointer" data-action="click->${this.controllerName}#goToURL"> 
                           ${element.card_name}
                         </div>
                         <div class="col-12 px-1 pointer d-flex"> 
                           ${amount}
                         </div>
                         <div class="col-12 px-1 pointer d-flex"> 
                           ${campaign}
                         </div>
                         ${alert}
                       </div>
                       <div class="row">
                         <div class="col-2 px-2">
                           <button data-action="click->${this.controllerName}#showTickets" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">add_task</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Adicionar Ticket</span>
                           </button>
                         </div>
                         <div class="col-2 px-2">
                           <button data-action="click->${this.controllerName}#addCheckNote" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">note_add</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Adicionar Nota de Check</span>
                           </button>
                         </div>
                         <div class="col-2 px-2">
                           <button data-action="click->${this.controllerName}#listCheckNote" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">checklist</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Notas de Check</span>
                           </button>
                         </div>
                         <div class="col-6 p-0">
                           <button data-action="click->${this.controllerName}#changeStage" type="button" class="btn btn-sm btn-table p-0 w-100 mc-tooltip">
                             <span class="material-icons md-sm md-dark">trending_flat</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Avançar</span>
                           </button>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>`



    if (window.location.pathname == `/a/fluxo-prospeccao`) {
      if (element.stage == `prospecting`) {
        this.controllerDashboard.prospectingFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      } else if (element.stage == `qualification`) {
        this.controllerDashboard.qualificationFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      } else if (element.stage == `booking`) {
        this.controllerDashboard.bookingFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      }
    } else if (window.location.pathname == `/a/fluxo-fechamento`) {
      if (element.stage == `meeting`) {
        this.controllerDashboard.meetingFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      } else if (element.stage == `proposal`) {
        this.controllerDashboard.proposalFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      } else if (element.stage == `closing`) {
        this.controllerDashboard.closingFlowTarget.insertAdjacentHTML("afterbegin", html)
        // this.setTickets(element)
      }
    }
  }

  setTickets(element) {
    element.tickets.forEach(ticket => {
      if (ticket.performer_id == this.application.current_user.account_id) {
        ticket.url = element.url
        ticket.opportunity = element.ticket_name
        this.application.tickets[this.application.tickets.length] = ticket
      }
    })
  }

  doDataIndicator() {
    var prospectingTotal = 0
    var qualificationTotal = 0
    var bookingTotal = 0
    var meetingTotal = 0
    var proposalTotal = 0
    var closingTotal = 0

    var prospectingAmount = 0
    var qualificationAmount = 0
    var bookingAmount = 0
    var meetingAmount = 0
    var proposalAmount = 0
    var closingAmount = 0

    if (this.application.opportunities.length == 0) {
      if (window.location.pathname == `/a/fluxo-prospeccao`) {
        this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
        this.controllerDashboard.prospectingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(prospectingAmount * 100))
        this.controllerDashboard.qualificationTotalTarget.innerText = qualificationTotal
        this.controllerDashboard.qualificationAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(qualificationAmount * 100))
        this.controllerDashboard.bookingTotalTarget.innerText = bookingTotal
        this.controllerDashboard.bookingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(bookingAmount * 100))
      } else if (window.location.pathname == `/a/fluxo-fechamento`) {
        this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
        this.controllerDashboard.meetingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(meetingAmount * 100))
        this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal
        this.controllerDashboard.proposalAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(proposalAmount * 100))
        this.controllerDashboard.closingTotalTarget.innerText = closingTotal
        this.controllerDashboard.closingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(closingAmount * 100))
      }
    } else {
      this.application.opportunities.forEach(element => {

        if (window.location.pathname == `/a/fluxo-prospeccao`) {
          this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
          this.controllerDashboard.qualificationTotalTarget.innerText = qualificationTotal
          this.controllerDashboard.bookingTotalTarget.innerText = bookingTotal

          this.controllerDashboard.prospectingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(prospectingAmount * 100))
          this.controllerDashboard.qualificationAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(qualificationAmount * 100))
          this.controllerDashboard.bookingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(bookingAmount * 100))

          if (element.stage == `prospecting`) {
            prospectingTotal += 1
            this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
            prospectingAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.prospectingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(prospectingAmount * 100))
          } else if (element.stage == `qualification`) {
            qualificationTotal += 1
            this.controllerDashboard.qualificationTotalTarget.innerText = qualificationTotal
            qualificationAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.qualificationAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(qualificationAmount * 100))
          } else if (element.stage == `booking`) {
            bookingTotal += 1
            this.controllerDashboard.bookingTotalTarget.innerText = bookingTotal
            bookingAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.bookingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(bookingAmount * 100))
          }
        } else if (window.location.pathname == `/a/fluxo-fechamento`) {
          this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
          this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal
          this.controllerDashboard.closingTotalTarget.innerText = closingTotal

          this.controllerDashboard.meetingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(meetingAmount * 100))
          this.controllerDashboard.proposalAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(proposalAmount * 100))
          this.controllerDashboard.closingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(closingAmount * 100))

          if (element.stage == `meeting`) {
            meetingTotal += 1
            this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
            meetingAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.meetingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(meetingAmount * 100))
          } else if (element.stage == `proposal`) {
            proposalTotal += 1
            this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal
            proposalAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.proposalAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(proposalAmount * 100))
          } else if (element.stage == `closing`) {
            closingTotal += 1
            this.controllerDashboard.closingTotalTarget.innerText = closingTotal
            closingAmount += Number(element.total_amount_recurring)
            this.controllerDashboard.closingAmountTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(closingAmount * 100))
          }
        }
      })      
    }
  }

  showTickets(ev) {
    var opportunityId = ev.currentTarget.closest(".cardRow").dataset.id
    this.current_opportunity = {}

    this.application.opportunities.forEach(element => {
      if (element.id == opportunityId) {
        this.current_opportunity = element
      }
    })

    this.getTickets()
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.contract_tickets = []

    var data = { ticket: { board_id: this.current_opportunity.id, board_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.contract_tickets[controller.contract_tickets.length] = element
        })

        controller.showTicketsBoard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showTicketsBoard() {
    var controllerModal = `users--works--activities--shared--board-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {

      var leadName = ``
      controller.current_opportunity.leads.forEach(lead => {
        leadName += `| ${lead.lead_name} `
      })

      controller.getControllerByIdentifier(controllerModal).current_tickets = controller.contract_tickets
      controller.getControllerByIdentifier(controllerModal).obj_type = `sales_opportunity`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.current_opportunity.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.current_opportunity.name} ${leadName}`
      controller.getControllerByIdentifier(controllerModal).permission = `sales_opportunities_tickets`
      // controller.getControllerByIdentifier(controllerModal).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(controllerModal).ticket_board = {
        board_id: controller.current_opportunity.id,
        board_type: `sales_opportunities`,
        board_subtype: `sales_opportunities`,
        board_path: controller.current_opportunity.url,
        board_name: `Oportunidade com ${leadName}`,
        date_id: controller.current_opportunity.date_id,
        date_type: `commercial_dates`
      }
      controller.getControllerByIdentifier(controllerModal).comment_board = {
        board_id: controller.current_opportunity.id,
        board_type: `sales_opportunities`,
        date_id: controller.current_opportunity.date_id,
        date_type: `commercial_dates`
      }

      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  addCheckNote(ev) {
    var modal = `users--works--notes--save-check-modal`
    var opportunityId = ev.currentTarget.closest(".cardRow").dataset.id
    var currentOpportunity = {}

    this.application.opportunities.forEach(element => {
      if (element.id == opportunityId) {
        currentOpportunity = element
      }
    })

    var leadName = ``
    currentOpportunity.leads.forEach(lead => {
      leadName += `| ${lead.lead_name} `
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller
      controller.getControllerByIdentifier(modal).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: currentOpportunity.id,
        domain_type: `sales_opportunities`,
        domain_name: `Oportunidade com ${leadName}`,
        date_id: controller.application.current_date.id,
        date_type: `commercial_dates`,
      }

      controller.getControllerByIdentifier(modal).current_obj = currentOpportunity
      controller.getControllerByIdentifier(modal).title = `Check ${currentOpportunity.name}`
      controller.getControllerByIdentifier(modal).permission = `sales_opportunities_notes`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  listCheckNote(ev) {
    var modal = `users--works--notes--list-check-modal`
    var opportunityId = ev.currentTarget.closest(".cardRow").dataset.id
    var currentOpportunity = {}

    this.application.opportunities.forEach(element => {
      if (element.id == opportunityId) {
        currentOpportunity = element
      }
    })

    var leadName = ``
    currentOpportunity.leads.forEach(lead => {
      leadName += `| ${lead.lead_name} `
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller
      controller.getControllerByIdentifier(modal).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: currentOpportunity.id,
        domain_type: `sales_opportunities`,
        domain_name: `Oportunidade com ${leadName}`,
        date_id: controller.application.current_date.id,
        date_type: `commercial_dates`,
      }

      controller.getControllerByIdentifier(modal).current_obj = currentOpportunity
      controller.getControllerByIdentifier(modal).title = `Check ${currentOpportunity.name}`
      controller.getControllerByIdentifier(modal).permission = `sales_opportunities_notes`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  goToURL(ev) {
    var url = ev.currentTarget.closest(".cardRow").dataset.url
    window.open(url, `_blank`)
  }

  addTicket(ev) {
    var id = ev.currentTarget.closest(".cardRow").dataset.id
  }

  changeStage(ev) {

    var stageNumber = Number(ev.currentTarget.closest(".cardRow").dataset.stageNumber)
    var newStage = this.setNewStage(stageNumber)

    if (newStage == `gain`) {
      var r = confirm("Confirma o Ganho da Oportunidade? Vamos para a página da Oportunidade atualizar os Ganhos!")
    } else {
      var r = confirm("Confirma o Avanço da Oportunidade?")
    }

    if (r && newStage != `gain`) {
      var id = ev.currentTarget.closest(".cardRow").dataset.id
      var stage = ev.currentTarget.closest(".cardRow").dataset.stage

      this.send_data = { current_user: {}, journey: {}, product: {} }

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `sales_opportunities_journeys`

      this.send_data.journey.opportunity_id = id
      this.send_data.journey.stage = this.setNewStage(stageNumber)
      this.send_data.journey.date = new Date()
      this.send_data.product.stage = this.setNewStage(stageNumber)

      this.requestSaveJourney()
    } else if (r && newStage == `gain`) {
      this.goToURL(ev)
    }
  }

  setNewStage(stageNumber) {

    if (window.location.pathname == `/a/fluxo-prospeccao`) {
      var all_stages = ["qualification", "booking", "meeting"]
      // this.controllerDashboard.prospectingFlowTarget.innerHTML = ``
      // this.controllerDashboard.qualificationFlowTarget.innerHTML = ``
      // this.controllerDashboard.bookingFlowTarget.innerHTML = ``
    } else if (window.location.pathname == `/a/fluxo-fechamento`) {
      var all_stages = ["qualification", "booking", "meeting", "proposal", "closing", "gain"]
      // this.controllerDashboard.meetingFlowTarget.innerHTML = ``
      // this.controllerDashboard.proposalFlowTarget.innerHTML = ``
      // this.controllerDashboard.closingFlowTarget.innerHTML = ``
    }

    return all_stages[stageNumber]
  }

  requestSaveJourney() {
    var url = "/commercial/sales/opportunities/journeys/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          this.application.opportunities.forEach(element => {
            if (element.id == journey.opportunity_id) {
              controller.nameTarget(`card-${element.id}`).remove()
              element.stage = journey.stage
              element.stage_alert = false
              element.stage_pretty = journey.stage
              element.stage_number = element.stage_number + 1
              element.journeys[element.journeys.length] = journey
              controller.doSimpleDataFlow(element)
              return
            }
          })
          controller.doDataIndicator()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
