import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewPatientCard", "viewTitle", "viewPatientCardBody", "principalShow", "contactShow", "principalTab", "infoTab", "contactTab", "profileTab",
                    "viewPatientCardFooter", "infoShow", "profileShow"]

  connect() {
    this.controllerName = `operations--products--bookings--helpers--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewPatientCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Assistente Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewPatientCardBody" id="cardContactBody">
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--helpers--dashboard").viewTarget.innerHTML = html
  }

  doShowHtml() {
    var html = `<ul class="nav nav-justified nav-tabs" role="tablist">
                  <li class="nav-item" data-${this.controllerName}-target="principalTab">
                    <a data-action="click->${this.controllerName}#showPrincipal" aria-controls="principal" aria-selected="true" class="f-065 nav-link nav-dark active" data-toggle="tab" href="#principal" id="principal-tab" role="tab">Principal</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="infoTab">
                    <a data-action="click->${this.controllerName}#showInfo" aria-controls="info" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#info" id="info-tab" role="tab">Informações</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="contactTab">
                    <a data-action="click->${this.controllerName}#showContact" aria-controls="contact" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#contact" id="contact-tab" role="tab">Contatos</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="profileTab">
                    <a data-action="click->${this.controllerName}#showProfile" aria-controls="profile" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#profile" id="profile-tab" role="tab">Perfil</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div aria-labelledby="principal-tab" class="tab-pane fade show active" role="tabpanel" id="principal"><div class="list-group" data-${this.controllerName}-target="principalShow"></div></div>
                  <div aria-labelledby="info-tab" class="tab-pane fade" role="tabpanel" id="info"><div class="list-group" data-${this.controllerName}-target="infoShow"></div></div>
                  <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel" id="contact"><div class="list-group" data-${this.controllerName}-target="contactShow"></div></div>
                  <div aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel" id="profile"><div class="list-group" data-${this.controllerName}-target="profileShow"></div></div>
                </div>`

    this.viewTitleTarget.innerText = this.application.current_helper.account_name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewPatientCardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewPatientCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.showPrincipal()
      if (!controller.application.permissions.medbooking_helper_infos.can_index) {
        controller.infoTabTarget.children[0].classList.add("disabled")
        controller.infoTabTarget.children[0].innerHTML = `<s>Informações</s>`
      }
      if (!controller.application.permissions.medbooking_helper_contacts.can_index) {
        controller.contactTabTarget.children[0].classList.add("disabled")
        controller.contactTabTarget.children[0].innerHTML = `<s>Contatos</s>`
      }
      if (!controller.application.permissions.medbooking_helper_profiles.can_index) {
        controller.profileTabTarget.children[0].classList.add("disabled")
        controller.profileTabTarget.children[0].innerHTML = `<s>Perfil</s>`
      }
    })
  }

  showPrincipal() {
    var html = `<div data-controller="operations--products--bookings--helpers--principal" data-operations--products--bookings--helpers--principal-target="main"></div>`
    $(".nav-tabs-indicator").addClass("nav-dark")
    this.principalShowTarget.innerHTML = html
  }

  showInfo() {
    var html = `<div data-controller="operations--products--bookings--helpers--info" data-operations--products--bookings--helpers--info-target="main"></div>`
    if (this.application.permissions.medbooking_helper_infos.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.infoShowTarget.innerHTML = html
    }
  }

  showContact() {
    var html = `<div data-controller="operations--products--bookings--helpers--contact" data-operations--products--bookings--helpers--contact-target="main"></div>`
    if (this.application.permissions.medbooking_helper_contacts.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.contactShowTarget.innerHTML = html
    }
  }

  showProfile() {
    var html = `<div data-controller="operations--products--bookings--helpers--profile" data-operations--products--bookings--helpers--profile-target="main"></div>`
    if (this.application.permissions.medbooking_helper_profiles.can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")
      this.profileShowTarget.innerHTML = html
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewPatientCard"]
    var identifier = "operations--products--bookings--helpers--show"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
