
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["contentRow", "listCol", "showCol", "bodyTable", "bodyTablePreloader", "footerTable", "viewDownloadCard",
                    "modal", "cardBody", "checkboxShow", "viewDownloadBody", "viewTitle", "publishStatus"]

  connect() {
    this.controllerName = `marketing--downloads--entities--index`

    this.getCurrentUserPermissions()
    this.application.downloads = []
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
  }

  newDownloadModal() {
    const downloadId = ""
    this.application.getControllerForElementAndIdentifier(this.modalTarget, "marketing--downloads--entities--modal").modalHtml("new", downloadId)
  }

  editDownloadModal(ev) {
    var downloadId = ev.target.closest(".itemRow").dataset.id
    this.application.getControllerForElementAndIdentifier(this.modalTarget, "marketing--downloads--entities--modal").modalHtml("edit", downloadId)
  }

  goToEditPage() {
    var slug = this.viewDownloadBodyTarget.dataset.slug
    window.open(`/a/downloads/${slug}`, `_self`)
  }

  publishPage() {
    var id = this.viewDownloadBodyTarget.dataset.id
    var download = {}
    this.application.downloads.forEach(element => {
      if (element.id == id) {
        download = element
      }
    });
    if (download.is_published) {
      var data = { download: { id: download.id, published_at: null}, current_user: { current_user_id: currentUser.id } }
      var publishHtml = `<span class="material-icons mr-1 pointer md-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Publicar Página?" data-action="click->${this.controllerName}#publishPage">publish</span>
                        <figcaption class="figure-caption" style="white-space:pre-wrap;">Página Não Publicada</figcaption>`
      this.publishStatusTarget.innerHTML = publishHtml
      this.requestSave(data)
    } else {
      var data = { download: { id: download.id, published_at: new Date() }, current_user: { current_user_id: currentUser.id } }
      var publishHtml = `<span class="material-icons mr-1 pointer md-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Despublicar Página" data-action="click->${this.controllerName}#publishPage">highlight_off</span>
                        <figcaption class="figure-caption" style="white-space:pre-wrap;">Página Publicada</figcaption>`
      this.publishStatusTarget.innerHTML = publishHtml
      this.requestSave(data)
    }
    this.getControllerByIdentifier("app--helpers--elements").untooltip()
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  showDownload(ev) {
    this.checkboxShowTargets.forEach(target => {
      if (ev.target.id != target.id) {
        target.checked = false
      }
    });

    if (ev.target.checked) {
      if (this.hasViewDownloadBodyTarget) {
        this.viewDownloadBodyTarget.remove()
        this.viewTitleTarget.innerText = ""
      }
      var id = ev.target.closest(".itemRow").dataset.id
      var download = ``
      this.application.downloads.forEach(element => {
        if (element.id == id) {
          download = element
        }
      });
      var controller = this
      this.viewTitleTarget.innerText = download.name

      new Promise(function (resolve) {
        resolve(controller.viewDownloadCardTarget.insertAdjacentHTML("beforeend", controller.viewDownloadHtml(download)))
      }).then(() => {
        tooltip()
      })
    } else {
      this.viewTitleTarget.innerText = "Nenhuma Download Selecionada"
      this.viewDownloadBodyTarget.remove()
    }
  }

  viewDownloadHtml(element) {

    if (element.is_published) {
      var publishHtml = `<span class="material-icons mr-1 pointer md-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Despublicar Página" data-action="click->${this.controllerName}#publishPage">highlight_off</span>
                        <figcaption class="figure-caption" style="white-space:pre-wrap;">Página Publicada</figcaption>`
    } else {
      var publishHtml = `<span class="material-icons mr-1 pointer md-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Publicar Página?" data-action="click->${this.controllerName}#publishPage">publish</span>
                        <figcaption class="figure-caption" style="white-space:pre-wrap;">Página Não Publicada</figcaption>`
    }
    
    var html = `<div class="card-body pt-0" data-${this.controllerName}-target="viewDownloadBody" data-slug="${element.slug}" data-id="${element.id}">
                  <div class="row d-flex align-items-center" style="height:4rem">
                    <div class="col-6 d-flex align-items-center">
                      <figure class="figure btn btn-flat text-center s-title-1rem">
                        <span class="material-icons mr-1 pointer md-primary" data-toggle="tooltip" data-placement="top" title data-original-title="Editar Página" data-action="click->${this.controllerName}#goToEditPage">web</span>
                        <figcaption class="figure-caption">Editar Página</figcaption>
                      </figure>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                      <figure class="figure btn btn-flat text-center s-title-1rem" data-${this.controllerName}-target="publishStatus">
                        ${publishHtml}
                      </figure>
                    </div>
                  </div>
                </div>`

    return html
  }

  doIndexListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="cardBody">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <div class="custom-control custom-checkbox pl-2"><input type="checkbox" class="custom-control-input handling-all-rows-selector" id="handlingAllRowsSelector">
                      <label class="custom-control-label pointer" for="handlingAllRowsSelector" id="handlingAllRowsSelectorLabelCheck"></label>
                    </div>
                    <h6 class="card-title display-4 card-title-table-list">Todos os Downloads</h6>
                    <input class="form-control f-070 w-50 ml-auto input-search-table" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      <div class="dropdown" id="cardFilterEventDropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
                        <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
                      </div>
                      <div class="dropdown" id="eventsActionDropdown">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="actionEventDrop" type="button"><i class="material-icons">more_vert</i></button>
                        <div aria-labelledby="actionEventDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          <span class="dropdown-item pointer" id="newDownloadModal" data-action="click->${this.controllerName}#newDownloadModal" data-controller="marketing--downloads--entities--modal" data-${this.controllerName}-target="modal">Novo Download</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                        <table class="table table-sm table-hover table-search" style="font-size:80%;" id="searchEventTable">
                          <thead>
                            <tr>
                              <th class="table-3"></th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">Nome</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle">Link</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Conteúdo</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">Formato</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Publicado</th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center"># Downloads</th>
                              <th class="table-3"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listColTarget.innerHTML = html)
    }).then(() => {
      controller.cardBodyTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.doTablePreloader())
      controller.getDownloads()
      
    })
  }

  getDownloads() {
    var data = { current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/marketing/downloads/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.downloads = response.data.cln.collection
        } else {
          processingSnackbar(response.type, response.message, device)
        }
        controller.doDataTable()
      })
      .catch(error => {
        processingSnackbar("danger", "Aconteceu um erro, favor atualizar a página. Se o erro persistir, favor entrar em contato no develop@medcapital.com.br", device)
        // form.innerHTML = error.response.data.template
      })
  }

  doDataTable() {
    var downloads = this.application.downloads

    if (downloads.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(downloads.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (downloads[x] !== undefined) {
            internPage[internPage.length] = downloads[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }
      
      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: downloads.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }
  
  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="9" class="p-5 align-middle text-center" style="font-size:200%;">
                      <span class="fa-stack fa-2x">
                        <i class="fas fa-list fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <h5>Não existem Downloads</h5>
                    </td>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.downloadTablePartial(element))
      })
      this.getControllerByIdentifier("app--helpers--elements").tooltip()
    }
  }

  downloadTablePartial(element) {
    if (this.application.download_entities.can_select) {
      var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#showDownload">
                    <label class="custom-control-label pointer" for="check-${element.id}"></label>
                  </div>`
    } else {
      var check = ''
    }

    if (this.application.download_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editDownloadModal" type="button" class="btn btn-sm btn-table editAuthor p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>`
    } else {
      var edit = ``
    }

    if (this.application.download_entities.can_copy) {
      var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">share</i></button>`
    } else {
      var copyPath = ``
    }

    if (element.is_published) {
      var statusPublished = `<span style="color:#26C485;"><i class="fas fa-check-double" data-toggle="tooltip" data-placement="right" title data-original-title="Página Publicado">`
    } else {
      var statusPublished = `<span style="color:#F25F5C;"><i class="fas fa-times-circle" data-toggle="tooltip" data-placement="right" title data-original-title="Página Não Publicado">`
    }

    var rowHtml = `<tr class="itemRow" data-slug="${element.slug}" data-id="${element.id}">
                    <th class="table-3">${check}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle">${element.name}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle" data-copy="${element.public_path}">${copyPath}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">${element.content_pretty}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">${element.format_pretty}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">${statusPublished}</th>
                    <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">${element.download_counter_pretty}</th>
                    <th class="table-3">${edit}</th>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }


  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doViewListHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewDownloadCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Download Selecionado</h6>
                  </div>
                </div>`

    this.showColTarget.innerHTML = html
  }

  doTablePreloader() {
    const columns = 8
    const rows = 4
    var columnPreloaderHtml = ''
    for (var i = 0; i < columns; i++) {
      columnPreloaderHtml += `<td class='animated-background animated-background-td'></td>`
    }
    var tablePreloaderHtml = ''
    for (var i = 0; i < rows; i++) {
      tablePreloaderHtml += `<tr class='timeline-item' data-${this.controllerName}-target="bodyTablePreloader">` + columnPreloaderHtml + `</tr>`
    }
    return tablePreloaderHtml
  }

  getCurrentUserPermissions() {
    const featureNames = ["download_entities"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });
        controller.doIndexListHtml()
        controller.doViewListHtml()
      })
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/marketing/downloads/entities/update"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var download = response.data.cln
          this.application.downloads.forEach((element, i) => {
            if (element.id == download.id) {
              this.application.downloads.splice(this.application.downloads.indexOf(element), 1, download)
            }
          });
          this.doDataTable()
        }
        processingSnackbar(response.type, response.message, device)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
