import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "searchTable", "searchInput"]

  connect() {
    this.controllerName = `financials--products--purchases--add--products-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doIndexListHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doIndexListHtml() {
    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#close" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Fechar</span>
                        </span>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Produtos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-${this.controllerName}-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable keyup->${this.controllerName}#enterCloseModal" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" data-${this.controllerName}-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 align-middle">Produto</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.doDataTable()
    })
  }

  doDataTable() {
    var products = this.productList()

    this.listData(products)
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Produtos</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.productTablePartial(element, data.length))
      })
    }

    this.searchInputTarget.focus()
  }

  productTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-product-name="${element.name}" data-product-name-pretty="${element.name_pretty}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-product-name="${element.name}" data-product-name-pretty="${element.name_pretty}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#closeModalEv">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.name_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Selecionar</span>
                      </span>
                    </td>
                  </tr>`

    return rowHtml
  }

  enterCloseModal(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {

      var table = this.searchTableTarget
      var tr = table.getElementsByTagName("tr")
      var productNames = []

      for (var i = 1; i < tr.length; i++) {
        if (tr[i].matches(`.filter-row`)) {
          productNames[productNames.length] = { name: tr[i].dataset.productName, name_pretty: tr[i].dataset.productNamePretty }
        }
      }

      if (productNames.length) {
        this.closeModal(productNames[0])
      }
    }
  }

  closeModalEv(ev) {
    var productName = ev.currentTarget.closest(".itemRow").dataset.productName
    var productNamePretty = ev.currentTarget.closest(".itemRow").dataset.productNamePretty

    this.closeModal({ name: productName, name_pretty: productNamePretty })
  }

  closeModal(product) {
    var provider = {}


    // selectProductName

    if (typeof this.controllerForm.selectProductName === "function") {
      this.controllerForm.productNameInputTarget.dataset.productName = product.name
      
      this.controllerForm.productNameInputTarget.value = product.name_pretty

      this.controllerForm.selectProductName(product.name)
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.close()
  }

  productList() {
    var products = [
                     {
                       name: `medclinic`,
                       name_pretty: `PJ Médica`
                     },
                     {
                       name: `medfiling`,
                       name_pretty: `Declaração IRPF`
                     },
                     {
                       name: `medbooking`,
                       name_pretty: `Livro-Caixa`
                     },
                     {
                       name: `medreceivement`,
                       name_pretty: `Gestão de Recebimentos`
                     },
                     {
                       name: `medoncall`,
                       name_pretty: `Gestão de Plantões`
                     },
                     {
                       name: `medbiling`,
                       name_pretty: `Faturamento`
                     },
                     {
                       name: `medpayroll`,
                       name_pretty: `Folha de Pagamento`
                     },
                     {
                       name: `medoutsourcing`,
                       name_pretty: `Terceirização`
                     },
                     {
                       name: `medconsulting`,
                       name_pretty: `Serviço Avulso`
                     },
                     {
                       name: `medreturn`,
                       name_pretty: `Planner`
                     },
                     {
                       name: `medprev`,
                       name_pretty: `Previdência Privada`
                     },
                     {
                       name: `medinsurance`,
                       name_pretty: `Seguros`
                     },
                     {
                       name: `medbanking`,
                       name_pretty: `Conta Digital`
                     },
                     {
                       name: `medloan`,
                       name_pretty: `Crédito`
                     },
                     {
                       name: `medcontent`,
                       name_pretty: `Mídias Sociais`
                     },
                   ]


    return products
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}