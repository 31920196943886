import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "compilationTitle", "compilationBoard", "payableListTitle", "payableList", "mainCard",
                    "bodyTable", "footerTable", "content", "goalLeadInput", "mainDashboard"]

  connect() {
    this.controllerName = `financials--books--payables--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reload = false
    this.load = false
    this.application.permissions = {}
    this.application.payables = []
    this.application.meds = []
    this.application.channels = []
    this.application.chart_accounts = []
    this.application.providers = []
    this.application.tickets = []
    this.application.all_tickets = []
    this.application.permissions = []
    this.application.cards = []
    this.application.bills = []
    this.application.loans = []
    this.application.clients = []
    this.application.contracts = []
    this.application.accounts = []
    this.application.card_transactions = []
    this.application.costs = []
    this.doMainGrid()

    // 1 - this.getCurrentUserPermissions
    // 2 - this.getFinancialDates
    // 3 - this.getPayables
    // 4 - this.getProviders
    // 5 - this.getTickets
    // 6 - this.getPayableTeam
    // 7 - this.getMeds
    // 8 - this.getManageTeam
    // 9 - this.getChartAccounts
    // 10 - this.getChannels
    // 11 - this.getBills
    // 12 - this.getPermissions
    // 13 - this.Notes
  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                    <div class="col-6 px-2">
                      <div class="row my-2 w-100">
                        <div class="col-12">
                          <h6 class="mb-0 text-center pointer" data-action="click->${this.controllerName}#refreshCompilation" data-${this.controllerName}-target="compilationTitle">${this.loader}</h6>
                          <hr class="mt-1">
                          <div class="row my-2" data-${this.controllerName}-target="compilationBoard" data-controller="financials--books--payables--dashboard--compilation">
                            ${this.cardGridPreloader()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 px-2">
                      <div class="row my-2 w-100">
                        <div class="col-12 px-0">
                          <h6 class="mb-0 text-center pointer" data-action="click->${this.controllerName}#goToPayables" data-${this.controllerName}-target="payableListTitle">${this.loader}</h6>
                          <hr class="mt-1">
                          <div class="row my-2" data-${this.controllerName}-target="payableList" data-controller="financials--books--payables--dashboard--list">
                            ${this.cardGridPreloader()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.load) {
        controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doPayableList()
        controller.getControllerByIdentifier(`financials--books--payables--dashboard--compilation`).doCompilationCard()
      }
    })
  }

  cardGridPreloader() {
    var html = `<div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-4 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="financials--books--payables--entities--dashboard
                                                            financials--books--cards--bills--dashboard
                                                            financials--books--payables--loans--dashboard
                                                            financials--books--payables--tickets--dashboard
                                                            financials--books--payables--annual--dashboard" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doMainDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  goToPayables() {
    this.getControllerByIdentifier("financials--books--payables--entities--dashboard").doPayablesDashboard()
  }

  refreshCompilation() {
    this.getControllerByIdentifier("financials--books--payables--dashboard--compilation").refreshCompilation()
  }

  finishLoadPage() {
    this.load = true

    // this.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doPayableList()
    // this.getControllerByIdentifier(`financials--books--payables--dashboard--compilation`).doCompilationCard()
    // this.payableListTitleTarget.innerText = `Todos os Pagamentos - ${this.application.current_date.due_name}`
    // this.compilationTitleTarget.innerText = `Despesas - ${this.application.current_date.name}`
    
    // this.doMainDashboard()
    // this.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doDataTable()

    if (this.reload) {
      this.getControllerByIdentifier("financials--books--payables--dashboard--dates").runDashboardsHtml()
      this.getControllerByIdentifier("financials--books--payables--dashboard--submenu").doBadgeCounts()
    } else {
      this.getControllerByIdentifier("financials--books--payables--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("financials--books--payables--dashboard--submenu").doSubmenuHtml()
      this.getControllerByIdentifier("financials--books--payables--dashboard--meds").doMedHtml()
    }
    this.getControllerByIdentifier("financials--books--payables--dashboard--dates").setCurrents()
    this.application.current_date.data_loaded = true

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getBills() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas de Cartão de Crédito`, value: 85 })

    const data = { bill: { active: true, month: this.application.current_date.month, year: this.application.current_date.year }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_card_bills` } }
    const url = "/financials/books/cards/bills/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          response.data.cln.forEach(element => {
            controller.application.bills[controller.application.bills.length] = element
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 75 })

    var data = { ticket: { board_id: this.application.current_date.id, board_type: "financial_payables" }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })

        controller.getBills()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPayables() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Pagamentos`, value: 70 })

    // var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
    // const data = { payable: { active: true, date: date }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    // const url = "/financials/books/payables/entities/list_by_range"

    const data = { payable: { active: true, due_date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    const url = "/financials/books/payables/entities/list_by_due_date"

    // const data = { payable: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    // const url = "/financials/books/payables/entities/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          // response.data.cln.forEach(element => {
          //   controller.application.payables[controller.application.payables.length] = element
          // })

          var currentPayableIds = controller.mapElements(controller.application.payables, `id`)
          var refreshPayableIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(payable => {
            if (currentPayableIds.includes(payable.id) && refreshPayableIds.includes(payable.id)) {
              controller.application.payables.forEach((element, i) => {
                if (element.id == payable.id) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
                }
              })
            } else if (!currentPayableIds.includes(payable.id) && refreshPayableIds.includes(payable.id)) {
              controller.application.payables[controller.application.payables.length] = payable
            }
          })
        }
        
        controller.getTickets()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da ${this.application.current_date.name}` })
      }
      
      this.getPayables()
    } else {
      this.finishLoadPage()
    }
  }

  getFinancialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 65 })

    const data = { date: { active: true, subdomain: `payables` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_dates` } }
    const url = "/financials/config/dates/list_with_subdomain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        });

        // controller.application.dates = response.data.cln
        // controller.application.current_date = response.data.cln[0]
        // controller.application.current_payable_compilation = response.data.cln[0].payable_compilation

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]
        controller.application.current_payable_compilation = currentDates[0].payable_compilation

        controller.getControllerByIdentifier("financials--books--payables--dashboard--dates").doDatesHTML()
        controller.loadDateData()
        // controller.getControllerByIdentifier("commercial--dashboards--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPayableTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 60 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    const url = "/financials/config/teams/list_payable_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.application.team_list = response.data.cln
        controller.getFinancialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCosts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Centros de Custo`, value: 55 })

    const data = { client: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_costs` } }
    const url = "/financials/books/payables/costs/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.costs = response.data.cln
        }

        controller.getPayableTeam()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getLoans() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Empréstimos/Parcelamentos`, value: 45 })

    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_loans` } }
    const url = "/financials/books/payables/loans/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        controller.application.loans = response.data.cln
        controller.getCosts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChartAccounts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Plano de Conta`, value: 35 })

    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_chart_accounts` } }
    const url = "/financials/books/statements/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        
        controller.application.chart_accounts = response.data.cln
        controller.getLoans()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getProviders() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Fornecedores`, value: 30 })

    const data = { provider: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_providers` } }
    const url = "/financials/books/statements/providers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.providers = response.data.cln
        controller.getChartAccounts()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getChannels() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Canais de Pagamento`, value: 20 })

    const data = { channel: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_channels` } }
    const url = "/financials/books/statements/channels/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.channels = response.data.cln
        controller.getProviders()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getMeds() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Meds`, value: 10 })

    var data = { med: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_meds` } }
    const url = "/financials/config/meds/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.meds = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
        controller.application.current_med = { id: ``, cnpj: ``, name: `Grupo` }

        controller.getChannels()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `financial_book_payables` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getMeds()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }


  // ------------------------------------------------------------------------------------------


  refreshPayable(element, cols) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    element.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(cols, 10)

    // var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
    // const data = { payable: { active: true, date: date }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    // const url = "/financials/books/payables/entities/list_by_range"

    const data = { payable: { active: true, due_date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_entities` } }
    const url = "/financials/books/payables/entities/list_by_due_date"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentPayableIds = controller.mapElements(controller.application.payables, `id`)
        var refreshPayableIds = controller.mapElements(response.data.cln, `id`)
        
        if (response.process) {
          controller.application.payables.forEach(payable => {
            if (currentPayableIds.includes(payable.id) && !refreshPayableIds.includes(payable.id) && (payable.due_date_id == this.application.current_date.id)) {
              controller.application.payables.forEach((element, i) => {
                if (element.id == payable.id) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
                }
              })
            }
          })

          response.data.cln.forEach(payable => {
            if (currentPayableIds.includes(payable.id) && refreshPayableIds.includes(payable.id)) {
              controller.application.payables.forEach((element, i) => {
                if (element.id == payable.id) {
                  controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
                }
              })
            } else if (!currentPayableIds.includes(payable.id) && refreshPayableIds.includes(payable.id)) {
              controller.application.payables[controller.application.payables.length] = payable
            }
          })
        } else if (response.data.cln.length == 0) {
          controller.application.payables.forEach((element, i) => {
            if (element.due_date_id == controller.application.current_date.id) {
              controller.application.payables.splice(controller.application.payables.indexOf(element), 1)
            }
          })
        }

        if (controller.getControllerByIdentifier(`financials--books--payables--entities--index`)) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--index`).doDataTable()
        } else if (controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`)) {
          controller.getControllerByIdentifier(`financials--books--payables--dashboard--list`).doDataTable()
        }
        
        controller.getControllerByIdentifier(`financials--books--payables--dashboard--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClients() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { contract: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_payable_clients` } }
    const url = "/financials/books/clients/contracts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.contracts = response.data.cln

        if (controller.getControllerByIdentifier(`financials--books--payables--entities--save`)) {
          controller.getControllerByIdentifier(`financials--books--payables--entities--save`).setContractName()

          // var saveController = controller.getControllerByIdentifier(`financials--books--payables--entities--save`)
          // var contract = {}
          // controller.application.contracts.forEach(element => {
          //   if (element.id == saveController.current_payable.contract_id) {
          //     contract = element
          //   }
          // })
          // saveController.contractInputTarget.value = `${contract.client_name} - ${contract.client_number_pretty} - ${contract.name}`
          // saveController.contractInputTarget.dataset.clientId = contract.client_id
          // saveController.contractInputTarget.dataset.contractId = contract.id
        }

        controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

    // this.doDataTable()
  }



  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------

  getManageTeam() {
    const data = { date: { date: new Date() }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/financials/config/teams/list_payable_manage_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getChartAccounts()
        controller.application.manage_team = response.data.cln
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `financials--books--payables--dashboard--main`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.updatePayble) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}