import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "list", "mainCard", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--clinics--regularizations--index--dashboard`
    this.application.permissions = {}
    this.application.clinics = []
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []

    this.sort = { mode: `desc`, field: `due_date_to_time` }
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--regularizations--index--list
                                                            operations--products--clinics--regularizations--index--settings
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      if (controller.application.clinics.length == 0) {
        controller.getCurrentUserPermissions()
      } else {
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--list").permission = `medclinic_regularizations`
        controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--list").doIndexListHtml()
      }
    })
  }

  finishLoadPage() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--submenu").doSubmenuHtml()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--list").permission = `medclinic_regularizations`
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--list").doIndexListHtml()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  // getClinicTeam() {
  //   const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic` } }
  //   const url = "/operations/products/config/teams/list_clinic_team"
  //   const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  //   var controller = this
  //   fetch(url, init)
  //     .then(response => response.json())
  //     .then(response => {
  //       controller.application.team_list = response.data.cln
  //       controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
  //     })
  //     .catch(error => {
  //       controller.getControllerByIdentifier("app--helpers--console").console(error)
  //       controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
  //     })
  // }

  getProducts() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Lista de PJs Médicas`, value: 55 })

    const data = { product: { name: `medclinic`, kind: `` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularizations` } }
    const url = `/operations/products/entities/list_by_company`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.clinics = response.data.cln
        
        controller.finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_regularizations` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        controller.application.domain_permission = controller.application.permissions[`medclinic_regularization_permissions`]

        controller.getProducts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}