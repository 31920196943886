
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenuBtn"]

  connect() {
    this.controllerName = `users--works--permissions--entities--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  // goToDashboard() {
  //   this.getControllerByIdentifier("users--works--permissions--entities--dashboard").doClinicDashboard()
  // }

  goToFeatures() {
    this.getControllerByIdentifier("users--works--permissions--features--dashboard").doFeaturesDashboard()
  }

  goToSubdomains() {
    this.getControllerByIdentifier("users--works--permissions--subdomains--dashboard").doSubdomainsDashboard()
  }

  goToPaths() {
    this.getControllerByIdentifier("users--works--permissions--paths--dashboard").doPathsDashboard()
  }

  goToPolicies() {
    this.getControllerByIdentifier("users--works--permissions--policies--dashboard").doPoliciesDashboard()
  }

  goToAccounts() {
    var url = `/a/contas-pessoas`
    window.open(url, '_blank');
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("users--works--permissions--entities--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "features_and_permissions"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)
    this.getControllerByIdentifier(`users--works--permissions--entities--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var dashboardBtn = ``
    var accountBtn = `<button type="button" data-action="${this.controllerName}#goToAccounts ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contas</button>`

    if (this.application.permissions.user_permissions_features.can_index) {
      var featuresBtn = `<button type="button" data-action="${this.controllerName}#goToFeatures ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Funcionalidades</button>`
    } else {
      var featuresBtn = ``
    }

    if (this.application.permissions.user_permissions_subdomains.can_index) {
      var subdomainsBtn = `<button type="button" data-action="${this.controllerName}#goToSubdomains ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Domínios</button>`
    } else {
      var subdomainsBtn = ``
    }

    if (this.application.permissions.user_permissions_paths.can_index) {
      var pathsBtn = `<button type="button" data-action="${this.controllerName}#goToPaths ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">URLs</button>`
    } else {
      var pathsBtn = ``
    }

    if (this.application.permissions.user_permissions_policies.can_index) {
      var policiesBtn = `<button type="button" data-action="${this.controllerName}#goToPolicies ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Políticas</button>`
    } else {
      var policiesBtn = ``
    }

    if (this.application.permissions.user_permissions.can_index) {
      var permissionsBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionsBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${policiesBtn}
                  ${featuresBtn}
                  ${pathsBtn}
                  ${subdomainsBtn}
                  ${permissionsBtn}
                  ${accountBtn}
                  

                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (this.hasExpenseCountTarget && element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (this.hasReceivableCountTarget && element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (this.hasTaxCountTarget && element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
