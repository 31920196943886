import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewProviderCard", "viewTitle", "viewProviderCardBody", "previewCard", "editProviderBtn", "deleteProviderBtn", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--providers--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewProviderCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Fornecedor Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewProviderCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--dashboards--providers--dashboard").viewTarget.innerHTML = html
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
