import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dates", "office", "listOffices", "showOffice", "listSignatures", "showSignature", "mainDashboard", "9999"]

  connect() {
    this.controllerName = `operations--products--bookings--personalizations--dashboard`
  }

  doPersonalizationsDashboard() {
    
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">                  
                  <div class="col-8 px-1" data-controller="operations--products--bookings--personalizations--offices">
                    <div class="row">
                      <div class="col-12 px-1" data-${this.controllerName}-target="listOffices"></div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-1" data-${this.controllerName}-target="showOffice"></div>
                    </div>
                  </div>

                  <div class="col-4 px-1" data-controller="operations--products--bookings--personalizations--signatures">
                    <div class="row">
                      <div class="col-12 px-1" data-${this.controllerName}-target="listSignatures"></div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-1" data-${this.controllerName}-target="showSignature"></div>
                    </div>
                  </div>
                </div>`
    this.getControllerByIdentifier("operations--products--bookings--entities--show").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--bookings--personalizations--dashboard"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
