import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "room", "summary", "board", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--chat--dashboard`
  }

  doChatDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4 pr-1" data-${this.controllerName}-target="room" data-controller="operations--products--tax-filings--chat--room"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-filings--entities--show").contentTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
