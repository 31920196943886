import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--show--broadcast`
    this.application.clinic_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.clinicUpdateChannel = createChannel({ channel: 'Operations::Products::ClinicChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "receivable") {
          var receivable = data.cln

          controller.application.all_receivables.forEach(element => {
            if (element.id == receivable.id) {
              controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1, receivable)
            }
          })

          if (controller.getControllerByIdentifier("operations--products--clinics--financials--add--show") && controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").nameTarget(`previewCard-${receivable.id}`)) {
            controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").current_receivable = receivable
            controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").doRevisionFormHtml()
          }
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.clinicChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      },
      appear() {
        this.perform('appear')
      },
    });
  }

  getRoom() {
    this.saveController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--save`)
    var data = { product: { id: this.saveController.current_clinic.id, name: this.permission }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/products/entities/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.update_room = response.data.cln.update
          controller.chat_room = response.data.cln.chat
          controller.application.chat_room = response.data.cln.chat
          controller.broadcastUpdateChannel()
          controller.getControllerByIdentifier("operations--products--clinics--financials--index--receivables--clinics").changeStep()
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  updateListen() {
    if (this.clinicUpdateChannel) {
      this.clinicUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.clinicChatChannel) {
      this.clinicChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
