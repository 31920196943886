import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "body", "saveBtn", "viewTitle", "modal", "sourceInput", "sourceList", "nameInput",
                    "startedAtInput", "stageDropdown", "stageDropdownBtn", "stageInput", "stageList", "notesInput", "formCardBody",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--save-kind-modal`
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    var html = `<div class="modal-backdrop fade show"></div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doFormHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doFormHtml() {

    var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveOpportunity" data-${this.controllerName}-target="saveBtn">Criar Oportunidade</button>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="main">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    
                    
                    <div class="row mt-5 mb-5 pb-5">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Persona</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="graduated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recém-Formado</li>
                                  <li data-kind="resident" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Residente</li>
                                  <li data-kind="market" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Especialista</li>
                                  <li data-kind="consulting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>
                                  <li data-kind="clinic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clínica</li>
                                  <li data-kind="non_doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Médico</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                     
                    </div>                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#close">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewTitleTarget.innerText = `Editando Persona para Oportunidade`

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  saveOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.actionMode = `edit`

    this.saveBtnTarget.disabled = true
    this.send_data = { current_user: {}, opportunity: {} }

    this.send_data.opportunity.id = this.opportunity_id
    this.send_data.opportunity.kind = this.kindInputTarget.dataset.kind

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    this.requestSave()
  }

  requestSave() {

    var url = "/commercial/sales/opportunities/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        controller.close()
        controller.getControllerByIdentifier("commercial--sales--opportunities--index--report").requestOpportunities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasFormCardBodyTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.stageInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
