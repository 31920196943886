import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "mainDashboard", "productDashboard", "postCardBody", "productCardBody", "contentDashboard", "tutorials", "8888", "9999"]

  connect() {
    this.controllerName = `users--dashboards--main--helper`
    this.products = []
    this.helpersPreloader()
    this.getProductHelpers()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  doProductsCard() {
    var bookingHtml = ``
    var clinicHtml = ``

    var bookings = []
    var clinics = []

    this.products.forEach(element => {
      if (element.product_name == `medbooking`) {
        bookings[bookings.length] = element
      } else if (element.product_name == `medclinic`) {
        clinics[clinics.length] = element
      }
    })

    bookings.forEach(element => {
      bookingHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.client_name}</span>
                      </div>
                      <div class="card-body text-center p-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                        <span class="material-icons md-primary pointer py-2" data-url="${element.product_url}" data-action="click->${this.controllerName}#goToURL">receipt_long</span>
                        <span class="mc-tooltiptext">Ir para Livro-Caixa</span>
                      </div>
                    </div>
                  </div>`
    })

    clinics.forEach(element => {
      clinicHtml += `<div class="col-12 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.client_name}</span>
                      </div>
                      <div class="card-body text-center p-1 mc-tooltip" data-${this.controllerName}-target="productCardBody">
                        <span class="material-icons md-primary pointer py-2" data-url="${element.product_url}" data-action="click->${this.controllerName}#goToURL">business</span>
                        <span class="mc-tooltiptext">Ir para PJ Médica</span>
                      </div>
                    </div>
                  </div>`
    })


    var html = `<h6 class="mb-0 text-center">Plataforma Livro-Caixa</h6>
                <hr class="mt-1">
                <div class="row mb-5">
                  ${bookingHtml}
                </div>
                <h6 class="mb-0 text-center">Plataforma PJ Médica</h6>
                <hr class="mt-1">
                <div class="row mb-5">
                  ${clinicHtml}
                </div>`

    this.productDashboardTarget.innerHTML = html
  }

  doTutorialCard() {

    var html = `<h6 class="mb-0 text-center f-bold f-085">Descanso Médico</h6>
                <hr class="mt-1">`

    this.tutorials.forEach(element => {
      html += `<div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.title}</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${element.url}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">manage_search</span>
                        <span class="mc-tooltiptext">${element.title}</span>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.tutorialsTarget.innerHTML = html
  }

  getTutorials() {
    var data = { post: { active: true, account_kind: this.application.current_user.account_kind }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/contents/posts/entities/list_tutorials"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.tutorials = response.data.cln
          controller.doProductsCard()
          controller.doTutorialCard()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getProductHelpers() {
    var data = { helper: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/accounts/products/list_helpers"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.products = response.data.cln
          controller.getTutorials()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  helpersPreloader() {

    var loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    var cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()

    // var html = `<div class="row" data-controller="users--dashboards--main--content" data-${this.controllerName}-target="mainDashboard">
    var html = `<div class="row">
                  <div class="col-2 pr-0" data-${this.controllerName}-target="productDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-4">
                      <div class="col-12">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12">
                        ${cardLoader}
                      </div>
                    </div>
                  </div>
                  <div class="col-8" data-${this.controllerName}-target="indicadorDashboard"></div>
                  <div class="col-2" data-${this.controllerName}-target="tutorials"></div>
                </div>`

    this.contentTarget.innerHTML = html

  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
