import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["saveCard", "titleCard", "cnpjInput", "cpfInput", "sourceDetailsInput", "sourceDropdown", "sourceInput", "sourceList", "sourceDropdownBtn",
    "sourceName", "cnpj", "cpf", "startedAt", "saveBtn", "deleteBtn", "patientDetailsInput", "patientDropdown", "patientInput", "patientList", "sponsor",
    "sponsorStatus", "patientDropdownBtn"]

  connect() {
    this.controllerName = `operations--products--tax-returns--sources--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.kind == "source") {
      if (this.application.medreturn_companies.can_create) {
        var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSource" data-${this.controllerName}-target="saveBtn">Salvar</button>`
      } else {
        var saveBtnHtml = ``
      }
      if (this.application.medreturn_companies.can_delete && this.actionMode == "edit") {
        var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deleteSource" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
      } else {
        var deleteBtnHtml = ``
      }
    } else if (this.kind == "patient") {
      if (this.application.medreturn_patients.can_create) {
        var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePatient" data-${this.controllerName}-target="saveBtn">Salvar</button>`
      } else {
        var saveBtnHtml = ``
      }
      if (this.application.medreturn_patients.can_delete && this.actionMode == "edit") {
        var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deletePatient" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
      } else {
        var deleteBtnHtml = ``
      }
    }    

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="saveCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="titleCard"></h6>
                  </div>
                  <div class="card-body py-0">
                    <div class="row my-3">
                      <div class="col-12 d-none" data-${this.controllerName}-target="cnpjInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ da Fonte</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany" placeholder="CNPJ da Fonte" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 d-none" data-${this.controllerName}-target="cpfInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF da Fonte</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount" placeholder="CPF da Fonte" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome da Fonte</label>
                            <input disabled aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="sourceName" placeholder="Nome da Fonte" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3 d-none" data-${this.controllerName}-target="sourceDetailsInput">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo de Vínculo</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="sourceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="sourceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sourceList">
                                  <li data-employment="wage" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">CLT</li>
                                  <li data-employment="tender" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">Concursado</li>
                                  <li data-employment="selfworker" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">Autônomo</li>
                                  <li data-employment="labor" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">Pró-Labore</li>
                                  <li data-employment="partner" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">Sócio</li>
                                  <li data-employment="grant" data-action="click->${this.controllerName}#checkIfHasSource" class="li-selector dark">Bolsa Estudos</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="startedAtForm">Data Início</label>
                            <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" id="startedAtForm" data-${this.controllerName}-target="startedAt" placeholder="Data Início" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3 d-none" data-${this.controllerName}-target="patientDetailsInput">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sexo Paciente</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="patientDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-${this.controllerName}-target="patientDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="patientInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="patientList">
                                  <li data-sex="male" class="li-selector dark">Masculino</li>
                                  <li data-sex="female" class="li-selector dark">Feminino</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 my-3 d-flex align-items-center">
                        <div class="custom-control custom-switch d-flex align-items-center">
                          <input class="custom-control-input custom-control-input-permission" id="switchDateOpen" data-action="click->${this.controllerName}#changeSponsor" data-${this.controllerName}-target="sponsor" type="checkbox" value="false">
                          <span class="custom-control-track"></span>
                          <label class="custom-control-label" for="switchDateOpen"></label>
                        </div>
                        <span class="f60" data-${this.controllerName}-target="sponsorStatus">Não é Responsável Financeiro</span>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--sources--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.saveCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.refreshSaveBtn()
      if (controller.kind == "source") {
        controller.cnpjInputTarget.classList.remove("d-none")
        controller.sourceDetailsInputTarget.classList.remove("d-none")
        pickDateStimulus($('#startedAtForm'));
        if (controller.actionMode == "edit") {
          controller.cnpjTarget.value = controller.source.company_cnpj_pretty
          controller.cnpjTarget.disabled = true
          controller.sourceNameTarget.value = controller.source.company_name
          controller.sourceDropdownTarget.value = controller.source.employment
          controller.sourceInputTarget.innerText = controller.source.employment_pretty
          controller.sourceInputTarget.dataset.employment = controller.source.employment
          controller.startedAtTarget.value = controller.source.started_at_pretty
          controller.sourceDropdownBtnTarget.classList.add("disabled")
          controller.titleCardTarget.innerText = `Editando Fonte Jurídica`
        } else if (controller.actionMode == "new") {
          controller.titleCardTarget.innerText = `Nova Fonte Jurídica`
        }
      } else if (controller.kind == "patient") {
        controller.cpfInputTarget.classList.remove("d-none")
        controller.patientDetailsInputTarget.classList.remove("d-none")
        if (controller.actionMode == "edit") {
          controller.cpfTarget.value = controller.patient.account_cpf_pretty
          controller.cpfTarget.disabled = true
          controller.sourceNameTarget.value = controller.patient.account_name
          controller.patientDropdownTarget.value = controller.patient.account_sex
          controller.patientInputTarget.innerText = controller.patient.account_sex_pretty
          controller.patientInputTarget.dataset.sex = controller.patient.account_sex
          controller.patientDropdownBtnTarget.classList.add("disabled")
          controller.sponsorTarget.checked = controller.patient.sponsor
          controller.titleCardTarget.innerText = `Editando Fonte Fisíca`
        } else if (controller.actionMode == "new") {
          controller.titleCardTarget.innerText = `Nova Fonte Física`
        }
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      
    })
  }

  changeSponsor(ev) {
    if (ev.target.checked) {
      this.sponsorStatusTarget.innerText = `Responsável Financeiro`
    } else {
      this.sponsorStatusTarget.innerText = `Não é Responsável Financeiro`
    }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-returns--sources--dashboard").viewTarget.innerHTML = ""
  }

  deleteSource() {
    this.send_data = { current_user: {}, source: {}, company: {} }

    this.send_data.source.id = this.source.id
    this.send_data.source.active = false
    this.send_data.current_user.current_user_id = this.application.current_user.id

    var url = "/operations/products/tax_returns/sources/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          this.cancelSave()
          var source = response.data.cln
          this.application.sources.forEach((element, i) => {
            if (element.id == source.id) {
              this.application.sources.splice(this.application.sources.indexOf(element), 1)
            }
          })
          this.getControllerByIdentifier("operations--products--tax-returns--sources--legal").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        this.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  deletePatient() {
    if (this.patient.booking_token) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `O paciente deve ser apagado diretamente no Livro-Caixa`, 5000)
    } else {
      this.send_data = { current_user: {}, patient: {}, account: {} }

      this.send_data.patient.id = this.patient.id
      this.send_data.patient.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id

      var url = "/operations/products/tax_returns/patients/destroy"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            this.stopRefreshing()
            this.cancelSave()
            var patient = response.data.cln
            this.application.patients.forEach((element, i) => {
              if (element.id == patient.id) {
                this.application.patients.splice(this.application.patients.indexOf(element), 1)
              }
            })
            this.getControllerByIdentifier("operations--products--tax-returns--sources--individual").doDataTable()
          } else {
            this.saveBtnTarget.disabled = false
            if (this.hasDeleteBtnTarget) {
              this.deleteBtnTarget.disabled = true
            }
          }
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        })
        .catch(error => {
          this.getControllerByIdentifier("app--helpers--console").console(error)
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  saveSource() {
    this.send_data = { current_user: {}, source: {}, company: {} }

    if (this.actionMode == "new") {
      if (this.company.id) {
        this.send_data.company.id = this.company.id
      } else {
        this.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjTarget.value)
        this.send_data.company.name = this.sourceNameTarget.value.trim()
        this.send_data.company.kind = "pjtaker"
      }
      this.send_data.source.tax_return_id = this.application.tax_return.id
    } else if (this.actionMode == "edit") {
      this.send_data.source.id = this.source.id
    }
    this.send_data.source.employment = this.sourceInputTarget.dataset.employment
    this.send_data.source.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtTarget.value)
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  savePatient() {
    this.send_data = { current_user: {}, account: {}, patient: {} }

    if (this.actionMode == "new") {
      if (this.account.id) {
        this.send_data.account.id = this.account.id
        this.send_data.account.kind = "patient"
      } else {
        this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfTarget.value)
        this.send_data.account.name = this.sourceNameTarget.value.trim()
        this.send_data.account.sex = this.patientInputTarget.dataset.sex
        this.send_data.account.kind = "patient"
      }
      this.send_data.patient.tax_return_id = this.application.tax_return.id
    } else if (this.actionMode == "edit") {
      this.send_data.patient.id = this.patient.id
    }

    this.send_data.patient.sponsor = this.sponsorTarget.checked
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    if (this.kind == "source") {
      if (this.actionMode == "edit") {
        var url = "/operations/products/tax_returns/sources/update"
        var method = "PUT"
      } else if (this.actionMode == "new") {
        var url = "/operations/products/tax_returns/sources/create"
        var method = "POST"
      }
    } else if (this.kind == "patient") {
      if (this.actionMode == "edit") {
        var url = "/operations/products/tax_returns/patients/update"
        var method = "PUT"
      } else if (this.actionMode == "new") {
        var url = "/operations/products/tax_returns/patients/create"
        var method = "POST"
      }
    }
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          this.stopRefreshing()
          this.cancelSave()
          if (this.kind == "source") {
            var source = response.data.cln
            if (this.actionMode == "edit") {
              if (source.active) {
                this.application.sources.forEach((element, i) => {
                  if (element.id == source.id) {
                    this.application.sources.splice(this.application.sources.indexOf(element), 1, source)
                  }
                })
              } else {
                this.application.sources.forEach((element, i) => {
                  if (element.id == source.id) {
                    this.application.sources.splice(this.application.sources.indexOf(element), 1)
                  }
                })
              }
            } else {
              this.application.sources[this.application.sources.length] = source
            }
            this.getControllerByIdentifier("operations--products--tax-returns--sources--legal").doDataTable()
          } else if (this.kind == "patient") {
            var patient = response.data.cln
            if (this.actionMode == "edit") {
              if (patient.active) {
                this.application.patients.forEach((element, i) => {
                  if (element.id == patient.id) {
                    this.application.patients.splice(this.application.patients.indexOf(element), 1, patient)
                  }
                })
              } else {
                this.application.patients.forEach((element, i) => {
                  if (element.id == patient.id) {
                    this.application.patients.splice(this.application.patients.indexOf(element), 1)
                  }
                })
              }
            } else {
              this.application.patients[this.application.patients.length] = patient
            }
            this.getControllerByIdentifier("operations--products--tax-returns--sources--individual").doDataTable()
          }
        } else {
          this.saveBtnTarget.disabled = false
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = true
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 18) {
      this.sourceNameTarget.value = ""
    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.sourceNameTarget.value == "") {
      var data = { company: { cnpj: cleanCnpj, kind: "pjtaker" }, current_user: { current_user_id: this.application.current_user.id, feature: "medreturn_company_accounts" } }
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            this.company = response.data.cln
            controller.sourceNameTarget.disabled = false
            if (this.company.name) {
              controller.sourceNameTarget.value = this.company.name
            } else {
              controller.sourceNameTarget.value = ""
            }
            controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 12) {
      this.sourceNameTarget.value = ""
      this.patientInputTarget.innerText = ""
      this.patientInputTarget.dataset.sex = ""
      this.patientDropdownTarget.value = ""
    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.sourceNameTarget.value == "") {
      var data = { account: { cpf: cleanCpf, kind: "patient" }, current_user: { current_user_id: this.application.current_user.id, feature: "medreturn_patient_accounts" } }
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.process) {
            this.account = response.data.cln
            controller.sourceNameTarget.disabled = false
            if (this.account.name) {
              controller.sourceNameTarget.value = this.account.name
              controller.patientInputTarget.innerText = this.account.sex_pretty
              controller.patientInputTarget.dataset.sex = this.account.sex
              controller.patientDropdownTarget.value = this.account.sex
              controller.checkIfHasPatient(this.account)
            } else {
              controller.sourceNameTarget.value = ""
            }
            controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
          }
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    }
  }

  refreshSaveBtn() {
    var controller = this
    if (this.kind == "source") {
      this.refreshTimer = setInterval(function () {
        var len = 0
        if (controller.sourceNameTarget.value == "") {
          len += 1
        }
        if (controller.cnpjTarget.value == "") {
          len += 1
        }
        if (controller.startedAtTarget.value == "") {
          len += 1
        }
        if (controller.sourceInputTarget.value == "") {
          len += 1
        }
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }, 200); 
    } else if (this.kind == "patient") {
      this.refreshTimer = setInterval(function () {
        var len = 0
        if (controller.sourceNameTarget.value == "") {
          len += 1
        }
        if (controller.cpfTarget.value == "") {
          len += 1
        }
        if (controller.patientInputTarget.innerText == "") {
          len += 1
        }
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }, 200); 
    }
  }

  checkIfHasSource(ev) {
    for (var element of this.application.sources) {
      if (element.company_cnpj == this.company.cnpj) {
        if (element.employment == ev.target.dataset.employment) {
          this.saveBtnTarget.disabled = true
          this.saveBtnTarget.classList.add("d-none")
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "A Fonte Jurídica já está cadastrada", 2000)
          break
        } else {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
      }
    }
    setTimeout(() => {
      delete this.sourceInputTarget.dataset.action
    }, 500)
  }

  checkIfHasPatient(account) {
    this.application.patients.forEach(element => {
      if (element.account_cpf == account.cpf) {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O Paciente já está cadastrado", 2000)
      }
    });
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["saveCard"]
    var identifier = "operations--products--tax-returns--sources--save"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
