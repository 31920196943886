import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "clinicName", "clinicDashboard", "printElement", "printCardElement", "subTitle", "dates"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--show--main`
    this.account_kind = this.application.current_user.account_kind
    this.time_in = Date.now()
    // this.application.current_date = {}
    this.application.permissions = {}
    this.application.domain = {}
    this.application.clinic_token = location.pathname.split("/").pop()
    this.application.chart_accounts = []
    this.application.partners = []
    this.application.takers = []
    this.application.all_activities = []
    this.application.activities = []
    this.application.notes = []
    this.application.tickets = []
    this.application.banks = []
    this.application.certificates = []
    this.application.incorporations = []
    this.application.agents = []
    this.application.helpers = []
    this.application.all_banks = []
    this.doClinicGrid()

    // getCurrentUserPermissions
    // getClinic
    // getClinicDates
    // getClinicTeam
    // getPartners
    // getTakers
    // getActivities
    // getBanks
    // getCertificates
    // getIncorporations
    // getEconomicActivities
    // getTickets
    // getNotes

    
  }

  doClinicGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--clinics--regularizations--show--dashboard
                                                            operations--products--clinics--regularizations--show--check-invoice
                                                            operations--products--clinics--regularizations--partners--dashboard
                                                            operations--products--clinics--regularizations--takers--dashboard
                                                            operations--products--clinics--regularizations--activities--dashboard
                                                            operations--products--clinics--regularizations--tickets--dashboard
                                                            operations--products--clinics--regularizations--notes--dashboard
                                                            operations--products--clinics--regularizations--banks--dashboard
                                                            operations--products--clinics--regularizations--certificates--dashboard
                                                            operations--products--clinics--regularizations--incorporations--dashboard
                                                            operations--products--clinics--regularizations--entities--dashboard
                                                            operations--products--clinics--managements--agents--dashboard
                                                            operations--products--clinics--managements--helpers--dashboard
                                                            operations--products--clinics--notes--dashboard" data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--show--dashboard").doClinicDashboardPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  setClinicName() {
    var html = `<strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_name}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_name}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>
                <span> | CNPJ: </span>
                <strong>
                  <span class="mb-0 mc-tooltip grab">
                    <span data-controller="app--helpers--copy" data-copy="${this.application.clinic.company_cnpj}" data-action="click->app--helpers--copy#copy">${this.application.clinic.company_cnpj_pretty}</span>
                    <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                  </span>
                </strong>`

    this.clinicNameTarget.innerHTML = html
  }

  doSubtitle() {
    this.subTitleTarget.innerText = `Regularização PJ Médica`
  }

  finishLoadPage() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Finalizando Página`, value: 80 })

    if (this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).hasBreadcrumbNameTarget) {
      this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
    } else {
      setTimeout(() => {
        this.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = this.application.clinic.company_name
      }, 500);
    }

    this.getControllerByIdentifier("operations--products--clinics--regularizations--show--dashboard").doClinicDashboard()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--show--submenu").doSubmenuHtml()
    
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  setBanks() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Bancos`, value: 75 })

    var banks = [
                  { number: "001", name: "Banco do Brasil S/A." },
                  { number: "341", name: "Banco Itau S/A." },
                  { number: "237", name: "Banco Bradesco" },
                  { number: "756", name: "Sicoob Credicom" },
                  { number: "033", name: "Banco Santander" },
                  { number: "104", name: "Caixa Econômica Federal" },
                  { number: "077", name: "Banco Inter" },
                  { number: "260", name: "Banco Nubank" },
                  { number: "212", name: "Banco Original" },
                  { number: "403", name: "Cora SCD S/A." },
                  { number: "336", name: "C6 Bank S/A." },
                  { number: "136", name: "Unicred" },
                  { number: "218", name: "Banco BS2 S.A." },
                  { number: "208", name: "Banco BTG Pactual" },
                  { number: "290", name: "Pagseguro Internet S.A." },
                  { number: "748", name: "Banco Cooperativo Sicredi S.A." },
                ]


    this.application.all_banks = banks
    this.finishLoadPage()
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.application.clinic.id, domain_type: `operation_products` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.setBanks()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    var data = { ticket: { board_id: this.application.clinic.id, board_type: "operation_products" }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getEconomicActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Todas as Atividades Econômicas`, value: 60 })

    var data = { cnae: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_activities` } }
    const url = "/app/datas/cnaes/entities/list"
    // const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_activities` } }
    // const url = "/operations/products/clinics/regularizations/activities/list_all_activities"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.all_activities = response.data.cln
        }

        controller.getTickets()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getIncorporations() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contratos Sociais da PJ`, value: 55 })

    var data = { incorporation: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_incorporations` } }
    const url = "/operations/products/clinics/regularizations/incorporations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.incorporations = response.data.cln
        }

        controller.getEconomicActivities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getBanks() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas Bancárias da PJ`, value: 50 })

    var data = { bank: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_banks` } }
    const url = "/operations/products/clinics/regularizations/banks/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.banks = response.data.cln
        }

        controller.getIncorporations()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Atividades Econômicas da PJ`, value: 45 })

    var data = { activity: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_activities` } }
    const url = "/operations/products/clinics/regularizations/activities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.activities = response.data.cln
        }

        controller.getBanks()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTakers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tomadores`, value: 40 })
    
    var data = { taker: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_takers` } }
    const url = "/operations/products/clinics/regularizations/takers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.takers = response.data.cln
        }

        controller.getActivities()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Operação`, value: 35 })

    const data = { current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularizations` } }
    const url = "/operations/products/config/teams/list_clinic_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.team_list = response.data.cln
        }

        controller.getTakers()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCertificates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Certificado Digital`, value: 30 })

    var data = { certificate: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_certificates` } }
    const url = "/operations/products/clinics/regularizations/certificates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.certificates = response.data.cln
        }

        controller.getClinicTeam()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPartners() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Sócios`, value: 25 })

    var data = { partner: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_partners` } }
    const url = `/operations/products/clinics/regularizations/partners/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.partners = response.data.cln
        }

        controller.getCertificates()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkInvoice() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Check para Emissão de NF`, value: 20 })

    var data = { clinic: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularizations` } }
    const url = `/operations/products/clinics/managements/verifications/check_can_invoice`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.check_invoice = response.data.cln
          controller.getPartners()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinicDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 15 })

    var data = { date: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_dates` } }
    const url = `/operations/products/clinics/managements/dates/list`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln

          if (controller.application.dates.length > 0) {
            controller.application.current_date = response.data.cln[0]
            controller.setClinicName()
            controller.doSubtitle()

            controller.checkInvoice()

            if (controller.application.current_date.open == false) {
              controller.application.current_date.open = false
              controller.application.current_date.not_defined = true
              controller.application.current_date.message = `A Competência está fechada`
            }
          } else {
            controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
            var response = { type: `danger`, message: `Favor adicionar a Competência, no Submenu > Competências!` }
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClinic() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando PJ Médica`, value: 10 })

    var data = { product: { token: this.application.clinic_token }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularizations` } }
    const url = `/operations/products/entities/read_show`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.clinic = response.data.cln

          // controller.getRoom()
          controller.getClinicDates()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `operation_clinic_regularizations` }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getClinic()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
