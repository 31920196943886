import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top"]

  connect() {
  }

  closeUploadModal() {
    if (this.getControllerByIdentifier("app--helpers--upload")) {
      this.getControllerByIdentifier("app--helpers--upload").close()
    }
  }

  openUploadModal() {
    this.doUploadModalHtml()
  }

  downloadData() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, model: {} }

    this.send_data.model.mockup = false
    this.send_data.model.fields = this.model_fields
    this.send_data.model.obj_ids = this.objIds
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = this.url_download
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        var date = new Date()
        var fileName = `data-${this.feature}-${date.getTime()}`
        var fileExtension = `csv`

        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doUploadModalHtml() {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="app--helpers--upload" data-app--helpers--upload-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-app--helpers--upload-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span><small>Baixe <span class="pointer" data-action="click->app--helpers--upload#downloadModel" data-app--helpers--upload-target="downloadModel">aqui</span> a planilha modelo</small></span>
                          </div>
                        </div>
                        <div class="row text-center">
                          <div class="col-12">
                            <div class="form-group">
                              <input class="file-input mt-0" type="file" id="fileFieldForm" multiple data-action="change->app--helpers--upload#dropHandler">
                              <label for="fileFieldForm" class="btn btn-primary btn-sm mb-3">Selecione o Arquivo para Upload de ${this.feature}</label><br>
                              <span id="" class="fileNameFieldForm"></span>
                              <h4 class="progress" style="display: none">Progress: <span class="progress_count"></span>%</h4>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 text-center d-none mb-4" data-app--helpers--upload-target="tableFinishedFeedback">
                            <h6><strong>Upload finalizado!</strong></h6
                          </div>
                          <div class="col-12" data-app--helpers--upload-target="tableHandlingFeedback"></div>
                        </div>
                        <div class="row my-2 w-100 d-none" data-app--helpers--upload-target="tableUploadsFeedback">
                          <div class="col-3 uploadsFilter pointer text-center" data-filter="upload-success">
                            <a class="chip chip-action blog-chip uploadsFilter pointer text-center" data-action="click->app--helpers--upload#filterUpload" data-filter="upload-success"><i class="chip-icon" data-app--helpers--upload-target="newUploadFeedback"></i>Novos</a>
                          </div>
                          <div class="col-3 uploadsFilter pointer text-center" data-filter="upload-edited">
                            <a class="chip chip-action blog-chip uploadsFilter pointer text-center" data-action="click->app--helpers--upload#filterUpload" data-filter="upload-edited"><i class="chip-icon" data-app--helpers--upload-target="editUploadFeedback"></i>Editados</a>
                          </div>
                          <div class="col-3 uploadsFilter pointer text-center" data-filter="upload-failed">
                            <a class="chip chip-action blog-chip uploadsFilter pointer text-center" data-action="click->app--helpers--upload#filterUpload" data-filter="upload-failed"><i class="chip-icon" data-app--helpers--upload-target="failUploadFeedback"></i>Erros</a>
                          </div>
                          <div class="col-3 uploadsFilter pointer text-center">
                            <a class="chip chip-action blog-chip uploadsFilter pointer text-center" data-action="click->app--helpers--upload#totalUpload"><i class="chip-icon" data-app--helpers--upload-target="totalUploadFeedback"></i>Total</a>
                          </div>
                        </div>
                        <div class="row w-100">
                          <div class="col-12" data-app--helpers--upload-target="tableUploads" style="overflow:scroll;"></div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-app--helpers--upload-target="closeBtn" data-action="click->app--helpers--upload#close">Fechar</button>
                        <button type="button" class="btn btn-primary" data-app--helpers--upload-target="uploadBtn" data-action="click->app--helpers--upload#upload">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--upload").url_upload = controller.url_upload
      controller.getControllerByIdentifier("app--helpers--upload").url_download = controller.url_download
      controller.getControllerByIdentifier("app--helpers--upload").url_download_model = controller.url_download_model
      controller.getControllerByIdentifier("app--helpers--upload").method = controller.method
      controller.getControllerByIdentifier("app--helpers--upload").feature = controller.feature
      controller.getControllerByIdentifier("app--helpers--upload").permission = controller.permission
      controller.getControllerByIdentifier("app--helpers--upload").model_fields = controller.model_fields
    })
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}