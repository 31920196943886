import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--show--broadcast`
    this.application.clinic_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.clinicUpdateChannel = createChannel({ channel: 'Operations::Products::ClinicChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "receivable") {
          var receivale = data.cln
          controller.application.receivables.forEach(element => {
            if (element.id == receivale.id) {
              controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1, receivale)
            }
          })

          controller.getControllerByIdentifier("operations--products--clinics--clients--show--submenu").doReceivablesCount()

          if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show") && controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").nameTarget(`previewCard-${receivale.id}`)) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").current_receivable = receivale
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").doRevisionFormHtml()
          } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show") && controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").nameTarget(`previewCard-${receivale.id}`)) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").current_receivable = receivale
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").doRevisionFormHtml()
          }
        } else if (data.kind == "apuration") {
          var apuration = data.cln
          controller.application.main_apurations.forEach(element => {
            if (element.id == apuration.id) {
              controller.application.main_apurations.splice(controller.application.main_apurations.indexOf(element), 1, apuration)
            }

            if (element.date_id == controller.application.current_date.id) {
              controller.application.current_main_apuration = apuration
            }
          })

          if (controller.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard").hasMainDashboardTarget) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard").doApurationsDashboard()
          }
        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  broadcastChatChannel() {
    var controller = this;
    this.clinicChatChannel = createChannel({ channel: 'RoomChannel', id: this.chat_room.id, user_id: this.application.current_user.id }, {
      connected() {
        controller.chatListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        controller.application.messages[controller.application.messages.length] = data
        if (controller.getControllerByIdentifier("operations--products--tax-filings--chat--dashboard").hasMainDashboardTarget) {
          controller.getControllerByIdentifier("operations--products--tax-filings--chat--room").doMessageTable()
        }
        this.appear()
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      },
      appear() {
        this.perform('appear')
      },
    });
  }

  updateListen() {
    if (this.clinicUpdateChannel) {
      this.clinicUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen() {
    if (this.clinicChatChannel) {
      this.clinicChatChannel.perform('follow', { id: this.chat_room.id })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
