import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hourlyTitle", "hourlyBoard", "callsTitle", "callsBoard", "callersTitle", "bodyTable", "callersBoard", "callerCard", "allCard"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `app--communications--voips--dashboard--callers`
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `hour` }
  }

  doCallersCard() {
    this.callersTitleTarget.innerHTML = `Ligadores`

    if (this.application.permissions.app_comunication_voip_callers.can_manage) {
      var listHtml = `<div class="col-12 px-0">
                        <div class="card pointer mb-2" data-${this.controllerName}-target="callerCard" data-kind="team" data-action="click->${this.controllerName}#toggleCaller">
                          <div class="card-header py-1 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Todos</strong></h6>
                          </div>
                        </div>
                      </div>`
      this.application.callers.forEach(element => {
        listHtml += `<div class="col-12 px-0">
                      <div class="card pointer mb-2" data-${this.controllerName}-target="callerCard caller-${element.id}" data-kind="individual" data-id="${element.id}" data-action="click->${this.controllerName}#toggleCaller">
                        <div class="card-header py-1 f-065 text-center">
                          <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${element.nickname}</strong></h6>
                        </div>
                      </div>
                    </div>`
      })
    } else {
      var listHtml = `<div class="col-12 px-0">
                        <div class="card pointer mb-2" data-${this.controllerName}-target="callerCard">
                          <div class="card-header py-1 f-065 text-center bg-primary">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.application.caller.nickname}</strong></h6>
                          </div>
                        </div>
                      </div>`
    }


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.callersBoardTarget.innerHTML = listHtml)
    }).then(() => {
      controller.callerCardTargets[0].classList.add(`bg-primary`)
    })
  }

  toggleCaller(ev) {
    var toggleCard = ev.currentTarget

    this.callerCardTargets.forEach(card => {
      card.classList.remove(`bg-primary`)
    })

    toggleCard.classList.add(`bg-primary`)

    var callerId = toggleCard.dataset.id
    var callerKind = toggleCard.dataset.kind
    this.changeCaller(callerId, callerKind)
  }

  changeCaller(callerId, callerKind) {
    if (callerKind == `team`) {
      this.application.current_caller_id = false
    } else if (callerKind == `individual`) {
      this.application.current_caller_id = callerId
    }

    this.getClearingByHour()
  }

  getClearingByHour() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()


    if (this.application.current_caller_id) {
      var data = { report: { active: true, date_id: this.application.current_date.id, caller_id: this.application.current_caller_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    }

    const url = "/app/communications/voip/calls/clearing_report_by_hour"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        if (response.process) {
          var currentClearingIds = controller.mapElements(controller.application.clearing_report, `date_id`)

          if (currentClearingIds.includes(response.data.cln.date_id)) {
            controller.application.clearing_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.clearing_report.splice(controller.application.clearing_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentClearingIds.includes(response.data.cln.date_id)) {
            controller.application.clearing_report[controller.application.clearing_report.length] = response.data.cln
          }
        }

        if (controller.grid == `call_compilation`) {
          controller.getControllerByIdentifier(`app--communications--voips--dashboard--compilation`).doCompilationCard()
          controller.getControllerByIdentifier(`app--communications--voips--dashboard--compilation`).doHourlyCard()
        } else if (controller.grid == `call_table`) {
          controller.getControllerByIdentifier(`app--communications--voips--calls--days`).checkBadgeCount()
          controller.getControllerByIdentifier(`app--communications--voips--calls--index`).doDataTable()
        }
        
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
