
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dayBtn", "totalBillings", "billingCount", "billingCountTooltip"]

  connect() {
    this.controllerName = `financials--books--receivables--billings--days`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doMenuHtml() {

    var daysHtml = ``
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)

    for (var day = 1; day <= lastDay; day++) {

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, day)
      var weekDay = date.getDay()
      var weekDayName = this.controllerDate.getWeekDay(weekDay)

      daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn billingCount-${day}">
                    ${day}-${this.application.current_date.month_short}
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
                  </button>`

      // daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn billingCount">
      //               ${day}-${this.application.current_date.month_short}
      //               <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
      //             </button>`

    }
    var html = `<div class="btn-group btn-group-sm" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${daysHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--billings--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      if (controller.application.current_date.year == date.getFullYear() && controller.application.current_date.month == (date.getMonth() + 1)) {
        var day = date.getDate()
        controller.current_day = date.getDate()
      } else {
        var day = 1
        controller.current_day = 1
      }
    })
  }

  doBadgeCount() {
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)
    var day = this.current_day
    var currentFilterBillings = []
    var currentBillings = []

    for (var indexDay = 1; indexDay <= lastDay; indexDay++) {
      if (this.nameTarget(`totalBillings-${indexDay}`)) {
        this.nameTarget(`totalBillings-${indexDay}`).remove()
      }
    }

    this.application.billings.forEach(element => {
      if (this.application.current_med.id) {
        if (element.received_date_id == this.application.current_date.id && element.med_id == this.application.current_med.id) {
          currentBillings[currentBillings.length] = element
        }
      } else {
        if (element.received_date_id == this.application.current_date.id) {
          currentBillings[currentBillings.length] = element
        }
      }
    })

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (day < 10) {
      var currentDay = `0${day}`
    } else {
      var currentDay = `${day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    currentBillings.forEach(element => {
      if (element.paid_date_token == currentToken) {
        currentFilterBillings[currentFilterBillings.length] = element
      }
    })

    var conciliatedBillings = 0
    currentFilterBillings.forEach(billing => {
      if (billing.conciliated) {
        conciliatedBillings += 1
      }
    })

    if (this.nameTarget(`billingCount-${day}`)) {
      var billingBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalBillings-${day}">${Number(currentFilterBillings.length) - conciliatedBillings}</span>`
      if (this.nameTarget(`totalBillings-${day}`)) {
        this.nameTarget(`totalBillings-${day}`).remove()
      }
      this.nameTarget(`billingCount-${day}`).insertAdjacentHTML("beforeend", billingBadgeHtml)
    }
  }

  goToDayEv(ev) {
    var day = ev.currentTarget.dataset.day

    this.current_day = day
    this.doBadgeCount()
    this.goToDay()
  }

  goToDay() {

    this.getControllerByIdentifier(`financials--books--receivables--billings--index`).dayFilter = true
    this.getControllerByIdentifier(`financials--books--receivables--billings--index`).current_day = this.current_day
    this.getControllerByIdentifier(`financials--books--receivables--billings--index`).doDataTable()

  }

  toggleBtn(ev) {

    this.dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.dayBtnTargets.forEach(element => {

      if (this.hasBillingCountTarget && element == this.billingCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doBillingsCount() {
    var billingCount = 0
    var billingUnconciliatedCount = 0

    this.application.billings.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        billingCount += 1
        if (element.conciliated == false) {
          billingUnconciliatedCount += 1
        }
      }
    })

    if (this.hasBillingCountTarget) {
      var billingBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalBillings">${billingUnconciliatedCount}</span>`
      if (this.totalBillingsTargets[0]) {
        this.totalBillingsTargets[0].remove()
      }
      this.billingCountTarget.insertAdjacentHTML("beforeend", billingBadgeHtml)
      this.billingCountTooltipTarget.innerText = `${billingUnconciliatedCount}/${billingCount} Fatura(s) não Conciliadas`
    }
  }

  doMenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    // this.getControllerByIdentifier(`financials--books--receivables--billings--dashboard`).daysTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--billings--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      controller.doMenuHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
