import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "main", "mainCard", "indicatorHeader", "indicators", "teamDropdown", "teamInput", "index"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--index--indicators`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.calculation = {}
  }


  doIndicatorsCalculation() {
    this.calculation.total_prospecting = 0
    this.calculation.total_qualification = 0
    this.calculation.total_booking = 0
    this.calculation.total_meeting = 0
    this.calculation.total_proposal = 0
    this.calculation.total_closing = 0
    this.calculation.amount_recurring_prospecting = 0
    this.calculation.amount_recurring_qualification = 0
    this.calculation.amount_recurring_booking = 0
    this.calculation.amount_recurring_meeting = 0
    this.calculation.amount_recurring_proposal = 0
    this.calculation.amount_recurring_closing = 0
    this.calculation.amount_separate_prospecting = 0
    this.calculation.amount_separate_qualification = 0
    this.calculation.amount_separate_booking = 0
    this.calculation.amount_separate_meeting = 0
    this.calculation.amount_separate_proposal = 0
    this.calculation.amount_separate_closing = 0


    this.application.all_opportunities.forEach(opportunity => {
      this.calculation[`total_${opportunity.stage}`] += 1
      this.calculation[`amount_recurring_${opportunity.stage}`] += Number(opportunity.total_amount_recurring)
      this.calculation[`amount_separate_${opportunity.stage}`] += Number(opportunity.total_amount_separate)
    });
    this.doIndicatorsHtml()
  }

  doIndicatorsHtml() {
    var html = `<div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="prospecting" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader prospecting">
                      <span>Prospecção</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_prospecting}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_prospecting)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_prospecting)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="qualification" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader qualification">
                      <span>Qualificação</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_qualification}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_qualification)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_qualification)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="booking" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader booking">
                      <span>Agendamento</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_booking}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_booking)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_booking)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="meeting" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader meeting">
                      <span>Consultoria</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_meeting}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_meeting)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_meeting)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="proposal" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader proposal">
                      <span>Proposta</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_proposal}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_proposal)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_proposal)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-stage="closing" data-action="click->${this.controllerName}#stageFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader closing">
                      <span>Fechamento</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Total
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.calculation.total_closing}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Avulso
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_separate_closing)}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-4 text-right px-1">
                          Recorrente
                        </div>
                        <div class="col-7 offset-1 text-left f-075 px-1">
                          ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.amount_recurring_closing)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      // resolve(controller.getControllerByIdentifier("commercial--sales--opportunities--index--dashboard").indicatorsTarget.innerHTML = html)
      resolve(controller.indicatorsTarget.innerHTML = html)
    }).then(() => {
      // controller.indicatorHeaderTargets[0].classList.add(`bg-primary`)
    })
  }

  stageFilter(ev) {
    var stage = ev.currentTarget.dataset.stage
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).filter = stage
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).hasFilter = true
    this.getControllerByIdentifier(`commercial--sales--opportunities--index--list`).doDataTable()

    this.indicatorHeaderTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });

    this.nameTarget(stage).classList.add(`bg-primary`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}