import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "main", "team", "teamDropdown", "teamDropdownBtn", "teamInput", "teamList", "9999"]

  connect() {
    this.controllerName = `commercial--sales--leads--meetings--slots`
    this.account_to_s = this.leadingZeros(parseInt(this.application.account_id).toString(36).toUpperCase(), 5)
    this.doSlotTableHtml()
  }

  doSlotTableHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Horários</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">Segunda-Feira</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">Terça-Feira</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">Quarta-Feira</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">Quinta-Feira</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">Sexta-Feira</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--leads--meetings--dashboard").tableTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(5, 10))
      controller.doSlotData()
    })
  }

  doSlotData() {
    var current_slots = []

    var days = [1, 2, 3, 4, 5]
    var timetables = ["08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00",
                      "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30",
                      "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00",
                      "18:30", "19:00", "19:30", "20:00", "20:30"]

    var trHtml = ``
    timetables.forEach(timetable => {

      var tdHtml = ``
      days.forEach(day => {
        var check = `<div class="custom-control custom-checkbox">
                      <input data-action="click->${this.controllerName}#saveSlot" type="checkbox" class="custom-control-input" id="check-${this.account_to_s}-${day}-${timetable}" data-${this.controllerName}-target="check-${this.account_to_s}-${day}-${timetable}" data-token="${this.account_to_s}-${day}-${timetable}" data-day="${day}" data-timetable="${timetable}">
                      <label class="custom-control-label pointer" for="check-${this.account_to_s}-${day}-${timetable}">${timetable}</label>
                    </div>`

        tdHtml += `<td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${check}</td>`
      })

      trHtml += `<tr>${tdHtml}</tr>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTableTarget.innerHTML = trHtml)
    }).then(() => {
      controller.application.slots.forEach(slot => {
        if (controller.nameTarget(`check-${slot.account_to_s}-${slot.day}-${slot.timetable}`)) {
          controller.nameTarget(`check-${slot.account_to_s}-${slot.day}-${slot.timetable}`).checked = true
        }
      })
    }) 
  }

  saveSlot(ev) {
    this.send_data = { slot: {}, current_user: {} }

    if (ev.currentTarget.checked) {
      var url = "/users/works/schedules/slots/create"
      var method = "POST"
      this.actionMode = `new`
      this.send_data.slot.account_id = parseInt(this.account_to_s, 36)
      this.send_data.slot.kind = `public`
      this.send_data.slot.timetable = ev.currentTarget.dataset.timetable
      this.send_data.slot.day = ev.currentTarget.dataset.day

    } else {
      var url = "/users/works/schedules/slots/destroy"
      var method = "DELETE"
      this.actionMode = `destroy`
      this.send_data.slot.token = ev.currentTarget.dataset.token
      this.send_data.slot.active = false      
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var slot = response.data.cln
        if (controller.actionMode == `new`) {
          controller.application.slots[controller.application.slots.length] = slot
        } else {
          controller.application.slots.forEach((element, i) => {
            if (element.id == slot.id) {
              controller.application.slots.splice(controller.application.slots.indexOf(element), 1)
            }
          })
        }
        controller.doSlotData()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        // window.open(window.location.origin, `_self`)
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })

  leadingZeros(number, size) {

    // Number.prototype.pad = function (size) {
    //   var s = String(this);
    //   while (s.length < (size || 2)) { s = "0" + s; }
    //   return s;
    // }

    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

}
