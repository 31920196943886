import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "main", "content", "mainCard", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--campaigns--show--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.permissions = {}
    this.application.campaigns = []
    this.application.feature_names = ["marketing_campaign_entities", "marketing_campaign_ads", "marketing_campaign_permissions",
                                      "marketing_campaign_conversions"]
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--marketing--campaigns--show--index
                                                            commercial--marketing--campaigns--reports--main
                                                            commercial--marketing--campaigns--ads--main
                                                            commercial--marketing--campaigns--conversions--main
                                                            users--works--permissions--entities--user" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doPreloader()
      controller.getCurrentUserPermissions()
    })
  }

  doPreloader() {
    // var html = `<div class="col-5 pl-1" data-${this.controllerName}-target="view" data-controller="commercial--marketing--campaigns--show--save commercial--marketing--campaigns--show--show"></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${this.loader}</div></div>
                      </div>
                    </div>
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
    })
  }

  getCampaigns() {
    const data = { campaign: { active: true }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/campaigns/entities/list"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.campaigns = response.data.cln
        // controller.application.team_list = response.data.cln
        // controller.getClinicReport()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const data = { permission: { features: this.application.feature_names }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });
        controller.application.domain_permission = controller.application.permissions[`marketing_campaign_permissions`]
        controller.getCampaigns()
        // controller.getCampaignTeam()
        // controller.getControllerByIdentifier("operations--campaigns--show--broadcast").getRoom()


        controller.getControllerByIdentifier("commercial--marketing--campaigns--show--submenu").doSubmenuHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
