import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "token", "info", "listAccounts", "listProducts", "renewalProspector", "renewalProspectorName", "renewalProspectorEdit",
                    "renewalProspectorInput", "prospectorFilter", "prospectorFilterItem", "renewalCloser", "renewalCloserName", "addLeadBtn", "addProductBtn",
                    "renewalCloserEdit", "renewalCloserInput", "closerFilter", "closerFilterItem", "renewalStage", "renewalStageName",
                    "renewalStageEdit", "renewalStageInput", "stageFilter", "stageFilterItem", "renewalStartedAt", "renewalOpenDays", "renewalStatus",
                    "renewalStatus", "renewalStatusName", "renewalStatusEdit", "renewalStatusInput", "statusFilter", "statusFilterItem",
                    "renewalLead", "renewalLeadName", "renewalLeadEdit", "renewalLeadInput", "leadFilter", "leadFilterItem", "addTicketBtn", "addNoteBtn",
                    "renewalProductAddCard", "renewalProductName", "renewalProductNameEdit", "renewalProductNameInput", "productNameFilter", "showRenewal",
                    "saveBtn", "renewalProductPlan", "renewalProductPlanEdit", "renewalProductPlanInput", "productPlanFilter", "listTickets",
                    "renewalProduct", "renewalProductKind", "renewalProductKindEdit", "renewalProductKindInput", "productKindFilter", "renewalLeadAddCard",
                    "renewalProduct", "menuCard", "menuRows", "renewalProductAmountInput", "productAmountFilter", "productFilterItem",
                    "listNotes", "destroyLeadBtn", "destroyProductBtn", "checkboxRecurring", "renewalProductDescriptionEdit", "renewalProductDescriptionInput",
                    "inProcess", "resolved", "forwardOpportunityBtn", "ticketTasks", "ticketCalls", "ticketMails", "ticketMessages", "listProposals", "addProposalBtn",
                    "labelUpload", "uploadBtn", "fileName", "progressUpload", "progressUploadBar", "progressUploadCounter", "proposalLink", "cancelProposalBtn",
                    "renewalKindName", "renewalKindEdit", "renewalKindInput", "kindFilter", "cancelBtn", "kindFilterItem", "renewalKind",

                    "ticketsCard", "ticketsRow", "notesCard", "notesRow", "callsCard", "callsRow", "proposalLink", "cancelProposalBtn"]



  connect() {
    this.controllerName = `financials--books--clients--renewals--show`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()

    this.leads = []
    this.tickets = []
    this.purchase = []
    this.calls = []
    this.caller = {}
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "PDF"]
    this.uploadFeature = true
    this.uploadReady = false
  }

  doGridHtml() {
    var html = `<div class="row my-3">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0">Data Início</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="renewalStartedAt">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 px-0">
                        <h6 class="mb-0">Dias em Aberto</h6>
                        <hr class="my-1">
                        <span class="f-065 pointer" data-${this.controllerName}-target="renewalOpenDays">
                          ${this.loader}
                        </span>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Médicos</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listAccounts">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row mt-5">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Proposta Comercial</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addProposal" data-${this.controllerName}-target="addProposalBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Proposta Comercial</span>
                          </span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#cancelProposal" data-${this.controllerName}-target="cancelProposalBtn">
                            <span class="material-icons md-sm md-dark">clear</span>
                            <span class="mc-tooltiptext">Cancelar</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="listProposals">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Renovação</span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="showRenewal">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-8 px-2">
                    <div class="row w-100">
                      <div class="col-3 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard menuCard" data-feature="tickets" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard menuCard" data-feature="notes" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="callsCard menuCard" data-feature="calls" data-action="click->${this.controllerName}#toggleCard">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Ligações</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 px-2">
                        <div class="row" data-controller="financials--books--clients--renewals--tickets" data-${this.controllerName}-target="ticketsRow menuRows"></div>
                        <div class="row d-none" data-controller="financials--books--clients--renewals--notes" data-${this.controllerName}-target="notesRow menuRows"></div>
                        <div class="row d-none" data-controller="financials--books--clients--renewals--calls" data-${this.controllerName}-target="callsRow menuRows"></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.getRenewal()
    })
  }

  toggleCard(ev) {
    var element = ev.currentTarget

    this.menuCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.menuRowsTargets.forEach(element => {
      element.classList.add(`d-none`)
    })

    element.classList.add(`bg-primary`)
    this.nameTarget(`${element.dataset.feature}Row`).classList.remove(`d-none`)
  }

  setAccounts() {
    var html = ``

    this.current_renewal.accounts.forEach(element => {
      if (this.application.permissions[this.permission].can_create && this.caller.id && this.caller.status == `active`) {
        if (element.phone && element.phone.phone_pretty) {
          var whatsappLine = `<p class="mb-0 f-065">
                                  ${element.phone.phone_pretty}
                                  <span class="ml-3 mc-tooltip pointer">
                                    <i class="fab fa-whatsapp md-dark md-150" data-account-id="${element.id}" data-action="click->${this.controllerName}#sendWhatsAppApi"></i>
                                    <span class="mc-tooltiptext">Enviar WhatsApp</span>
                                  </span>
                                </p>`
          var callLine = `<p class="mb-0 f-065">
                            ${element.phone.phone_pretty}
                            <span class="ml-3 mc-tooltip pointer">
                              <span class="material-icons md-sm md-dark" data-account-id="${element.id}" data-action="click->${this.controllerName}#makeCall">call</span>
                              <span class="mc-tooltiptext">Fazer Ligação</span>
                            </span>
                          </p>`
        } else {
          var whatsappLine = `<p class="mb-0 f-065">Favor Atualizar Telefone</p>`
          var callLine = `<p class="mb-0 f-065">Favor Atualizar Telefone</p>`
        }
      } else {
        var whatsappLine = ``
        var callLine = ``
      }

      if (element.email && element.email.address) {
        var emailLine = `<p class="mb-0 f-065 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${element.email.address}" data-action="click->app--helpers--copy#copy">${element.email.address}</span>
                          <span class="mc-tooltiptext">Copiar</span>
                        </p>`
      } else {
        var emailLine = `<p class="mb-0 f-065">Favor Atualizar Email</p>`
      }

      html += `<div class="row my-2 w-100" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-2 f-065">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-1">
                            <div class="card-show-dropdown">
                              ${element.name}
                              <div class="card-show-dropdown-content text-left">
                                ${whatsappLine}
                                ${callLine}
                                ${emailLine}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.listAccountsTarget.innerHTML = html
  }

  showRenewal() {
    var html = ``
    var openRenewalHtml = ``

    if (this.current_renewal.status == `active`) {
      var amount = this.controllerNumber.currencyOptionMask(this.current_renewal.goal_amount)
      var discount = this.controllerNumber.currencyOptionMask(this.current_renewal.goal_discount)
      openRenewalHtml += `<span class="mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-outline my-0 py-0" data-action="click->${this.controllerName}#closeRenewal" type="button">
                        <span class="material-icons md-dark">lock_open</span>
                      </button>
                      <span class="mc-tooltiptext">Encerrar Renovação</span>
                    </span>`
    } else {
      var amount = this.controllerNumber.currencyOptionMask(this.current_renewal.renewed_amount)
      var discount = this.controllerNumber.currencyOptionMask(this.current_renewal.renewed_discount)
    }

    html += `<div class="card pointer mt-3 w-100">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Status</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075 mc-tooltip">
                  <span><span class="badge badge-${this.current_renewal.status_css_class} mx-2">${this.current_renewal.status_pretty}</span></span>
                  <span class="mc-tooltiptext mc-tooltiptext-right">${this.current_renewal.status_next_pretty}</span>
                </span>
                ${openRenewalHtml}
              </div>
            </div>
            <div class="card pointer mt-3 w-100">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Produto</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.current_renewal.product_name_pretty}
                </span>
              </div>
            </div>
            <div class="card pointer mt-3 w-100">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Mudança Plano</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.current_renewal.current_plan_pretty}<span class="material-icons md-dark mx-2">arrow_forward</span>${this.current_renewal.new_plan_pretty}
                </span>
              </div>
            </div>
            <div class="card pointer mt-3 w-100">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Ajuste de Valor</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.controllerNumber.currencyOptionMask(this.current_renewal.current_amount)}
                  <span class="material-icons md-dark mx-2">arrow_forward</span>
                  ${amount}
                </span>
              </div>
            </div>
            <div class="card pointer mt-3 w-100">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-1 f-card110"><strong>Ajuste de Desconto</strong></h6>
              </div>
              <div class="card-body py-2 px-2 text-center">
                <span class="mb-0 f-075">
                  ${this.controllerNumber.currencyOptionMask(this.current_renewal.current_discount)}
                  <span class="material-icons md-dark mx-2">arrow_forward</span>
                  ${discount}
                </span>
              </div>
            </div>`

    this.showRenewalTarget.innerHTML = html
  }

  closeRenewal() {
    if (this.checkTickets()) {
      var modal = `financials--books--clients--renewals--save-modal`

      var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_renewal.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_renewal.current_contract
        controller.getControllerByIdentifier(modal).current_renewal = controller.current_renewal
        controller.getControllerByIdentifier(modal).actionMode = "closing"
        controller.getControllerByIdentifier(modal).open()
      })
    }
  }

  addRenewal(ev) {
    if (this.current_client) {
      

    } else {
      alert(`Selecione o cliente, por favor!`)
    }
  }

  setStartedAt() {
    this.renewalStartedAtTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.current_renewal.started_at_full_pretty}
                                                  </div>
                                                </div>`
  }

  setOpenDays() {
    if (this.current_renewal.open_days == 1) {
      this.renewalOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.current_renewal.open_days} dia
                                                  </div>
                                                </div>`
    } else {
      this.renewalOpenDaysTarget.innerHTML = `<div class="row d-flex align-items-center">
                                                  <div class="col-12 px-0 mc-tooltip text-center">
                                                    ${this.current_renewal.open_days} dias
                                                  </div>
                                                </div>`
    }
  }

  sendWhatsAppApi(ev) {
    var accountId = ev.currentTarget.dataset.accountId
    var account = {}
    this.current_renewal.accounts.forEach(element => {
      if (element.id == accountId) {
        account = element
      }
    })

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.current_renewal.client_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `financial_client_entities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Renovação Contrato ${this.current_renewal.contract_name} do cliente ${this.current_renewal.client_name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.current_renewal.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `financial_client_renewals`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = account.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `account_entities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = account.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = account.phone.ddd
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = account.phone.number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = account.phone.uniq_number
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = this.permission
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  makeCall(ev) {
    var accountId = ev.currentTarget.dataset.accountId
    var account = {}
    this.current_renewal.accounts.forEach(element => {
      if (element.id == accountId) {
        account = element
      }
    })

    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_id = this.current_renewal.client_id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_type = `financial_client_entities`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).record_name = `Renovação Contrato ${this.current_renewal.contract_name} do cliente ${this.current_renewal.client_name}`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_id = this.current_renewal.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).obj_type = `financial_client_renewals`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_id = account.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_type = `account_entities`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).person_name = account.name
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).dial_number = account.phone.uniq_number
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).phone_number = account.phone.phone_pretty
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).caller_id = this.caller.id
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).kind = `customer_success`
    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).permission = this.permission


    this.getControllerByIdentifier(`app--helpers--pre-call-modal`).openModal({ message: `Iniciar Chamada`, phone: account.phone.phone_pretty })
  }

  setProposal() {
    var html = ``

    if (this.current_renewal.has_proposal) {
      var proposal = `<span class="f-085 text-bold">Proposta</span>
                          <button data-action="click->${this.controllerName}#goToURL" data-url="${this.current_renewal.proposal_url}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                            <span class="material-icons md-sm md-dark">launch</span>
                            <span class="mc-tooltiptext">Ver Proposta</span>
                          </button>
                          <button data-action="click->${this.controllerName}#deleteFile" data-id="${this.current_renewal.id}" data-field="proposal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                            <span class="material-icons md-sm md-dark">delete</span>
                            <span class="mc-tooltiptext">Apagar Proposta</span>
                          </button>`
    } else {
      var proposal = `<span class="f-085 text-bold">Sem Arquivo Proposta</span>`
    }

    html += `<div class="row w-100 mt-3">
              <div class="col-12 d-flex align-items-center">
                <div class="row w-100" data-${this.controllerName}-target="fileLinks">
                  <div class="col-12 my-1 d-flex px-0">
                    ${proposal}
                  </div>
                </div>
              </div>
            </div>`

    this.listProposalsTarget.innerHTML = html
  }

  addProposal() {
    this.uploadFeature = true
    this.canUploadFile = false
    this.uploadReady = false

    var html = `<div class="row my-3">
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <label class="drop-area copy" for="dropzoneArea" data-${this.controllerName}-target="labelUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-2">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-lg md-dark mx-3">add_a_photo</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="dropzoneArea" multiple data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  <div class="col-6 px-0 d-flex align-items-center justify-content-center">
                    <div class="card bg-disabled w-50 h-50" data-action="click->${this.controllerName}#uploadFile" data-${this.controllerName}-target="uploadBtn">
                      <div class="row my-1">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-white">cloud_upload</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                    <span data-${this.controllerName}-target="fileName"></span>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center justify-content-center" data-${this.controllerName}-target="proposalLink">
                  </div>
                </div>`


    this.listProposalsTarget.innerHTML = html
    this.addProposalBtnTarget.classList.add(`d-none`)
    this.cancelProposalBtnTarget.classList.remove(`d-none`)
    this.refreshProposalBtn()
  }

  cancelProposal() {
    if (this.current_renewal.has_proposal) {
      this.addProposalBtnTarget.classList.add(`d-none`)
    } else {
      this.addProposalBtnTarget.classList.remove(`d-none`)
    }
    this.cancelProposalBtnTarget.classList.add(`d-none`)
    this.setProposal()
  }

  uploadSwitch(value) {
    if (value) {
      this.uploadBtnTarget.classList.remove("bg-disabled")
      this.uploadBtnTarget.classList.add("pointer")
      this.uploadBtnTarget.classList.add("bg-primary")
      this.uploadFeature = false
      this.canUploadFile = true
    } else {
      this.uploadBtnTarget.classList.add("bg-disabled")
      this.uploadBtnTarget.classList.remove("pointer")
      this.uploadBtnTarget.classList.remove("bg-primary")
      this.uploadFeature = true
      this.canUploadFile = false
    }
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault()

    if (ev.target.files.length > 0 && this.uploadFeature) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true

          obj.file = element
          this.upload_file = obj
          this.uploadReady = true
          this.fileNameTarget.innerText = this.upload_file.file_name
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.uploadReady = false
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  uploadFile() {
    if (this.canUploadFile) {
      var progressSpan = `<div class="form-group my-0 text-center w-100">
                            <h7 class="progress">
                              <span class="progress_count"></span>
                            </h7>
                            <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>`

      this.upload_file.kind = `proposal`

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.proposalLinkTarget.innerHTML = progressSpan)
      }).then(() => {
        controller.progressCount(0)
        controller.requestUpload(controller.current_renewal.id, controller.upload_file.file, controller.upload_file.kind)
      })
    }
  }

  requestUpload(renewal_id, file, kind) {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('field', kind)
    formData.append('renewal_id', renewal_id)
    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', this.permission)

    var url = "/financials/books/clients/renewals/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.current_renewal = response.data.cln

          controller.progressCount(100)
          controller.stopRefreshing()
          controller.uploadSwitch(false)
          controller.cancelProposal()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  deleteFile() {
    var r = confirm(`Tem certeza que deseja apagar a Proposta?`)
    if (r) {
      this.send_data = { current_user: {}, renewal: {} }

      this.send_data.renewal.id = this.current_renewal.id
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var url = "/financials/books/clients/renewals/destroy_upload"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            controller.current_renewal = response.data.cln

            controller.stopRefreshing()
            controller.cancelProposal()
          }

          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        })
        .catch(error => {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        })
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressCountTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  finishLoadPage() {
    this.setAccounts()
    this.setStartedAt()
    this.setOpenDays()
    this.setProposal()
    this.showRenewal()

    if (this.application.permissions[this.permission].can_upload) {
      this.addProposalBtnTarget.classList.remove("d-none")
    }

    this.getControllerByIdentifier("financials--books--clients--renewals--tickets").current_renewal = this.current_renewal
    this.getControllerByIdentifier("financials--books--clients--renewals--tickets").doTicketsDashboard()
    this.getControllerByIdentifier("financials--books--clients--renewals--notes").current_renewal = this.current_renewal
    this.getControllerByIdentifier("financials--books--clients--renewals--notes").doNotesDashboard()
    this.getControllerByIdentifier("financials--books--clients--renewals--calls").current_renewal = this.current_renewal
    this.getControllerByIdentifier("financials--books--clients--renewals--calls").doCallsDashboard()

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ramal`, value: 95 })

    const data = { caller: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/app/communications/voip/callers/read_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.caller = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCalls() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ligações`, value: 80 })

    var data = { call: { obj_id: this.current_renewal.id, obj_type: `financial_client_renewals` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/app/communications/voip/calls/list_by_obj"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.calls = response.data.cln

        controller.getCaller()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { obj_id: this.current_renewal.id, obj_type: `financial_client_renewals` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/accounts/notes/list_by_obj"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.getCalls()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 40 })
    this.application.tickets = []

    var data = { ticket: { obj_id: this.current_renewal.id, obj_type: `financial_client_renewals` }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/users/works/activities/tickets/list_by_obj"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.application.tickets[controller.application.tickets.length] = element
        })

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getRenewal() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando...` })

    const data = { renewal: { id: this.current_renewal.id }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_client_renewals` } }
    const url = "/financials/books/clients/renewals/read_show"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.current_renewal = response.data.cln
        }
        
        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  checkTickets() {
    if (this.application.in_process_tickets.length == 0) {
      return true
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Não podemos finalizar a Renovação com Tickets em Aberto. :/`, 5000)
      return false
    }
  }

  refreshProposalBtn() {
    var controller = this
    this.refreshProposalTimer = setInterval(function () {
      var len = 0

      if (controller.uploadReady == false) {
        len += 1
      }

      if (controller.hasUploadBtnTarget) {
        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshProductTimer) {
      clearInterval(this.refreshProductTimer)
    }
    if (this.refreshProposalTimer) {
      clearInterval(this.refreshProposalTimer)
    }
    if (this.progressCountTimer) {
      clearInterval(this.progressCountTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}