import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "dueAtInput", "paidAtInput", "channelDropdown", "channelDropdownBtn", "channelInput",
    "channelList", "langBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--balances--download-modal`
    this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)    
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  chooseLang(ev) {
    this.langBtnTargets.forEach(element => {
      element.disabled = true
    });
    var lang = ev.currentTarget.dataset.lang

    this.downloadBalance(lang)
  }

  downloadBalance(lang) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, download: {} }

    this.send_data.download.clinic_id = this.application.clinic.id
    this.send_data.download.date_id = this.application.current_date.id
    this.send_data.download.lang = lang

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_balances`

    var url = "/operations/products/clinics/financials/balances/download_balances"
    var fileName = `${this.application.clinic.company_cnpj}-${this.application.current_date.date_token}-balanco-formato-${lang}`
    var fileExtension = `csv`

    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        controller.close()
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}