import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainCard", "bodyTable", "footerTable", "checkboxShow", "cardTitle", "addNoteBtn"]

  connect() {
    this.controllerName = `operations--products--bookings--notes--index`
    this.itemsOnPage = 20
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
    this.doIndexListHtml()
  }

  addNote() {
    if (this.application.current_date.open) {
      this.getControllerByIdentifier("operations--products--bookings--notes--save").doFormHtml()
      this.getControllerByIdentifier("operations--products--bookings--notes--save").actionMode = `new`
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }
  }

  showNote(ev) {
    var noteId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.notes.forEach(element => {
      if (element.id == noteId) {
        this.getControllerByIdentifier("operations--products--bookings--notes--show").current_note = element
      }
    })

    this.getControllerByIdentifier("operations--products--bookings--notes--show").doViewHtml()
  }

  saveSharing(ev) {
    var noteId = ev.currentTarget.closest(".itemRow").dataset.id

    this.send_data = { current_user: {}, note: {} }
    this.send_data.note.id = noteId
    this.send_data.note.sharing = ev.currentTarget.dataset.sharing
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/bookings/notes/update"
    var method = "PUT"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var note = response.data.cln
          controller.application.notes.forEach((element, i) => {
            if (element.id == note.id) {
              controller.application.notes.splice(controller.application.notes.indexOf(element), 1, note)
            }
          })
          controller.doDataTable()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    // `<div class="dropdown">
    //   <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="cardFilterEventBtn" type="button"><i class="material-icons">filter_list</i></button>
    //   <div aria-labelledby="cardFilterEventBtn" class="dropdown-menu dropdown-menu-right menu" id="cardFilterEventDiv"></div>
    // </div>`
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list" data-${this.controllerName}-target="cardTitle">Anotações</h6>
                    <input class="form-control f-070 w-50 ml-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="addNoteBtn" data-action="click->${this.controllerName}#addNote" type="button">
                        <span class="material-icons md-dark">add</span>
                      </button>
                      <span class="mc-tooltiptext">Nova Anotação</span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle">
                                  Data
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-70 align-middle">
                                  Título
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="title" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="title" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="pointer p-1 table-3"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--notes--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(3, 4))
      controller.doDataTable()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()

      if (controller.application.permissions.medbooking_notes.can_create) {
        controller.addNoteBtnTarget.classList.remove("d-none")
      }
    })
  }

  doDataTable() {
    this.current_notes = []
    this.application.notes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.current_notes[this.current_notes.length] = element
      }
    });

    if (this.sort.mode == `asc`) {
      var notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_notes, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var notes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_notes, this.sort.field)
    }

    if (notes.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {

      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(notes.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (notes[x] !== undefined) {
            internPage[internPage.length] = notes[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: notes.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Anotações para a Declaração do Ano-Exercício ${this.application.current_date.year + 1}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.noteTablePartial(element, data.length))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  noteTablePartial(element, length) {
    if (this.application.permissions.medbooking_notes.can_update) {
      var view = `<button data-action="click->${this.controllerName}#showNote" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">arrow_forward</span>
                    <span class="mc-tooltiptext">Ver Anotação</span>
                  </button>`
      if (element.sharing == `internal`) {
        var sharing = `<button data-action="click->${this.controllerName}#saveSharing" data-sharing="external" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">visibility_off</span>
                        <span class="mc-tooltiptext">Anotação Interna</span>
                      </button>`
      } else if (element.sharing == `external`) {
        var sharing = `<button data-action="click->${this.controllerName}#saveSharing" data-sharing="internal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">visibility</span>
                        <span class="mc-tooltiptext">Anotação Externa</span>
                      </button>`
      }
    } else {
      var sharing = ``
      var view = ``
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.chapter_pretty == undefined) { element.chapter_pretty = `Não Definido` }
    if (element.term_pretty == undefined) { element.term_pretty = `Não Definido` }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showNote">${element.date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showNote">${element.title}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${sharing}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${view}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="20">20</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="50">50</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToNote(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
