import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "journey", "ticket", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--tickets--dashboard`
  }

  doTicketsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 pl-1" data-users--works--activities--shared--tickets-target="main" data-controller="users--works--activities--shared--tickets"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--regularizations--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--show--submenu`).dashboardToggleBtn(`TICKETS`)
      
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).permission = `medclinic_regularization_tickets`
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).itemsOnRow = 5
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).sharing = false
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).kind = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).priority = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).term = true
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_count_controller = controller.getControllerByIdentifier(`operations--products--clinics--regularizations--show--submenu`)
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).ticket_board = { 
        board_id: this.application.clinic.id,
        board_type: `operation_products`,
        board_subtype: `medclinic_regularizations`,
        board_path: window.location.pathname,
        board_name: `Regularização ${this.application.clinic.company_name}`,
        date_id: this.application.current_date.id,
        date_type: `operation_dates`
      }
      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).comment_board = { 
        board_id: this.application.clinic.id,
        board_type: `operation_products`,
        date_id: this.application.current_date.id,
        date_type: `operation_dates`
      }

      controller.getControllerByIdentifier(`users--works--activities--shared--tickets`).doIndexListHtml()
    }) 

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
