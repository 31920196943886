import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["title", "board", "balanceHeader", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `users--works--activities--tickets--resume`
  }

  doResumeHtml(colClass) {
    this.calculation = {}
    var date = new Date()
    var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)

    this.calculation.board_today = 0
    this.calculation.board_today_term = 0
    this.calculation.board_previous = 0
    this.calculation.board_previous_term = 0
    this.calculation.board_next = 0
    this.calculation.board_next_term = 0

    this.calculation.board_today_low = 0
    this.calculation.board_today_low_term = 0
    this.calculation.board_today_medium = 0
    this.calculation.board_today_medium_term = 0
    this.calculation.board_today_high = 0
    this.calculation.board_today_high_term = 0
    this.calculation.board_today_maximum = 0
    this.calculation.board_today_maximum_term = 0

    this.calculation.board_previous_low = 0
    this.calculation.board_previous_low_term = 0
    this.calculation.board_previous_medium = 0
    this.calculation.board_previous_medium_term = 0
    this.calculation.board_previous_high = 0
    this.calculation.board_previous_high_term = 0
    this.calculation.board_previous_maximum = 0
    this.calculation.board_previous_maximum_term = 0

    this.calculation.board_next_low = 0
    this.calculation.board_next_low_term = 0
    this.calculation.board_next_medium = 0
    this.calculation.board_next_medium_term = 0
    this.calculation.board_next_high = 0
    this.calculation.board_next_high_term = 0
    this.calculation.board_next_maximum = 0
    this.calculation.board_next_maximum_term = 0
    
    this.application.tickets.forEach(element => {
      
      if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
        this.calculation.board_today += 1
        this.calculation.board_today_term += this.getTicketTerm(element.term)
        if (element.priority == `low`) {
          this.calculation.board_today_low += 1
          this.calculation.board_today_low_term += this.getTicketTerm(element.term)
        } else if (element.priority == `medium`) {
          this.calculation.board_today_medium += 1
          this.calculation.board_today_medium_term += this.getTicketTerm(element.term)
        } else if (element.priority == `high`) {
          this.calculation.board_today_high += 1
          this.calculation.board_today_high_term += this.getTicketTerm(element.term)
        } else if (element.priority == `maximum`) {
          this.calculation.board_today_maximum += 1
          this.calculation.board_today_maximum_term += this.getTicketTerm(element.term)
        }
      }
      if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
        this.calculation.board_previous += 1
        this.calculation.board_previous_term += this.getTicketTerm(element.term)
        if (element.priority == `low`) {
          this.calculation.board_previous_low += 1
          this.calculation.board_previous_low_term += this.getTicketTerm(element.term)
        } else if (element.priority == `medium`) {
          this.calculation.board_previous_medium += 1
          this.calculation.board_previous_medium_term += this.getTicketTerm(element.term)
        } else if (element.priority == `high`) {
          this.calculation.board_previous_high += 1
          this.calculation.board_previous_high_term += this.getTicketTerm(element.term)
        } else if (element.priority == `maximum`) {
          this.calculation.board_previous_maximum += 1
          this.calculation.board_previous_maximum_term += this.getTicketTerm(element.term)
        }
      }
      if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
        this.calculation.board_next += 1
        this.calculation.board_next_term += this.getTicketTerm(element.term)
        if (element.priority == `low`) {
          this.calculation.board_next_low += 1
          this.calculation.board_next_low_term += this.getTicketTerm(element.term)
        } else if (element.priority == `medium`) {
          this.calculation.board_next_medium += 1
          this.calculation.board_next_medium_term += this.getTicketTerm(element.term)
        } else if (element.priority == `high`) {
          this.calculation.board_next_high += 1
          this.calculation.board_next_high_term += this.getTicketTerm(element.term)
        } else if (element.priority == `maximum`) {
          this.calculation.board_next_maximum += 1
          this.calculation.board_next_maximum_term += this.getTicketTerm(element.term)
        }
      }

    })

    var html = ``
    html += `<div class="row">
              <div class="${colClass}">
                <div class="card">
                  <div class="card-header p-1 text-center f-070">
                    <span>Atrasadas: <strong>${this.calculation.board_previous}</strong> | <strong>${this.setTermTime(this.calculation.board_previous_term)}</strong></span>
                  </div>
                  <div class="card-body text-center f-070 px-1 py-2">
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_previous_maximum}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_previous_maximum_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_previous_high}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_previous_high_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_previous_medium}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_previous_medium_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_previous_low}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_previous_low_term)}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="${colClass}">
                <div class="card">
                  <div class="card-header p-1 text-center f-070 bg-light-dark">
                    <span>Hoje: <strong>${this.calculation.board_today}</strong> | <strong>${this.setTermTime(this.calculation.board_today_term)}</strong></span>
                  </div>
                  <div class="card-body text-center f-070 px-1 py-2">
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_today_maximum}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_today_maximum_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_today_high}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_today_high_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_today_medium}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_today_medium_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_today_low}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_today_low_term)}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="${colClass}">
                <div class="card">
                  <div class="card-header p-1 text-center f-070">
                    <span>Próximas: <strong>${this.calculation.board_next}</strong> | <strong>${this.setTermTime(this.calculation.board_next_term)}</strong></span>
                  </div>
                  <div class="card-body text-center f-070 px-1 py-2">
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_next_maximum}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_next_maximum_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_next_high}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_next_high_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_next_medium}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_next_medium_term)}</strong></span>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="class-6 mx-auto">
                        <span>Não Urgente | Não Importante:</span>
                      </div>
                      <div class="class-6 mx-auto">
                        <span><strong>${this.calculation.board_next_low}</strong> Tickets | <strong>${this.setTermTime(this.calculation.board_next_low_term)}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
      // resolve(controller.getControllerByIdentifier(`users--works--activities--tickets--dashboard`).mainTarget.innerHTML = html)
    }).then(() => {

      // if (this.calculation.total_asset != this.calculation.total_liability) {
      //   controller.balanceHeaderTargets.forEach(element => {
      //     element.classList.add(`bg-light-danger`)
      //   })
      // }
    })
  }

  getTicketTerm(term) {

    var time = 0
    if (term == `quick`) {
      time = 1
    } else if (term == `normal`) {
      time = 3
    } else if (term == `long`) {
      time = 5
    } else if (term == `large`) {
      time = 10
    }

    return time
  }

  setTermTime(time) {
    var distance = time
    // var hour = Math.floor((distance) / (60 * 60));
    // var minutes = Math.floor((distance % (60 * 60)) / 60);
    // var seconds = Math.floor(distance % (60));

    var hour = Math.floor((distance % (60 * 60)) / 60);
    var minutes = Math.floor(distance % (60));

    if (hour < 0) {
      hour = `00`
    } else if (hour < 10) {
      hour = `0${hour}`
    }

    if (minutes < 0) {
      minutes = `00`
    } else if (minutes < 10) {
      minutes = `0${minutes}`
    }

    // if (seconds < 0) {
    //   seconds = `00`
    // } else if (seconds < 10) {
    //   seconds = `0${seconds}`
    // }

    return `${hour}hrs:${minutes}min`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
