import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "dateYearDropdown", "dateYearDropdownBtn", "dateYearInput", "dateYearList",
    "taxRegimeDropdown", "taxRegimeDropdownBtn", "taxRegimeInput", "taxRegimeList", "specialTaxRegime", "specialTaxRegimeDropdown",
    "specialTaxRegimeDropdownBtn", "specialTaxRegimeInput", "specialTaxRegimeList", "", "aliquotCheckbox", "fixedCheckbox",
    "issAliquotDiv", "issFixedDiv", "issAliquot", "issFixed", "legalNatureDropdown", "legalNatureDropdownBtn", "saveRegimeBtn",

    "dateMonthDropdown", "dateMonthDropdownBtn", "dateMonthInput", "dateMonthList", "legalNatureInput", "legalNatureList",
    "subRegimeDropdown", "subRegimeDropdownBtn", "subRegimeInput", "subRegimeList", "accrualDate",

    "totalInvoiceRow", "lastTwelveMonthsInput", "accrualMonthInput", "nextBtn"]

  connect() {
    this.regime = {}
    this.controllerName = `financials--books--dashboards--meds--add--regime`
  }

  saveRegime() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, regime: {} }
    this.saveRegimeBtnTarget.disabled = true
    this.stopRegimeRefreshing()

    this.send_data.regime.med_id = this.current_med.id
    this.send_data.regime.tax_regime = this.taxRegimeInputTarget.dataset.regime
    this.send_data.regime.sub_tax_regime = this.subRegimeInputTarget.dataset.subRegime
    this.send_data.regime.special_tax_regime = this.specialTaxRegimeInputTarget.dataset.specialRegime
    this.send_data.regime.legal_nature = this.legalNatureInputTarget.dataset.legalNature
    this.send_data.regime.year = this.dateYearInputTarget.dataset.year
    this.send_data.regime.month = this.dateMonthInputTarget.dataset.month

    if (this.aliquotCheckboxTarget.checked) {
      this.send_data.regime.iss_type = `aliquot`
      this.send_data.regime.iss_rate = this.getControllerByIdentifier(`app--helpers--numbers`).fromPercentToNumber(this.issAliquotTarget.value)
      this.send_data.regime.iss_amount = 0
    } else if (this.fixedCheckboxTarget.checked) {
      this.send_data.regime.iss_type = `fixed`
      this.send_data.regime.iss_rate = 0
      this.send_data.regime.iss_amount = this.getControllerByIdentifier(`app--helpers--numbers`).fromCurrencyToNumber(this.issFixedTarget.value)
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_settings_regimes`

    this.requestSave()
  }

  requestSave() {
    var url = "/financials/books/apurations/regimes/create_batch"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var regimes = response.data.cln
          
          controller.getControllerByIdentifier(`financials--books--dashboards--main--page`).getMeds()
          controller.saveRegimeBtnTarget.innerText = `Confirmado`
          controller.saveRegimeBtnTarget.disabled = true
          controller.regimeConfirmed = true
        } else {
          controller.saveRegimeBtnTarget.innerText = `Salvar`
          controller.saveRegimeBtnTarget.disabled = false
          controller.regimeConfirmed = false
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    var r = confirm(`Tem certeza que deseja encerrar?`)
    if (r) {
      this.getControllerByIdentifier("financials--books--dashboards--meds--index").doIndexListHtml()
    }
  }

  nextStep() {
    this.nextBtnTarget.disabled = true
    this.stopRefreshing()

    this.getControllerByIdentifier("financials--books--dashboards--meds--index").doIndexListHtml()
    
  }

  doRegimeFormHtml() {
    this.stopRefreshing()
    // this.current_med = this.getControllerByIdentifier("financials--books--dashboards--meds--add--save").current_med

    if (this.mode == `single`) {
      var colDiv = `<div class="col-12 px-0">`
    } else if (this.mode == `batch`) {
      var colDiv = `<div class="col-10 offset-1">`
    }

    var html = `${colDiv}
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando Regimes Tributários</h6>
                    </div>

                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row mt-2">
                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Ano</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateYearDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateYearDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateYearInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="dateYearList" data-app--helpers--search-target="searchList">
                                    <li data-year="2021" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>
                                    <li data-year="2020" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>
                                    <li data-year="2019" data-action="click->${this.controllerName}#listMonths click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-4">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Mês</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="dateMonthDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="dateMonthDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="dateMonthInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="dateMonthList" data-app--helpers--search-target="searchList">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-4">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="accrualForm">Competência</label>
                              <input aria-describedby="accrualFormHelp" class="form-control form-valid-control" id="accrualForm" data-${this.controllerName}-target="accrualDate" placeholder="Competência" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Regime Tributário</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="taxRegimeDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="taxRegimeDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="taxRegimeInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="taxRegimeList" data-app--helpers--search-target="searchList">
                                    <li data-regime="simple_national" data-action="click->${this.controllerName}#chooseSubRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Simples Nacional</li>
                                    <li data-regime="presumed_profit" data-action="click->${this.controllerName}#chooseSubRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Lucro Presumido</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Sub-regime</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subRegimeDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subRegimeDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subRegimeInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="subRegimeList" data-app--helpers--search-target="searchList">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Natureza Legal</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="legalNatureDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="legalNatureDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="legalNatureInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="legalNatureList" data-app--helpers--search-target="searchList">
                                    <li data-legal-nature="pure_simple_society" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Simples Pura</li>
                                    <li data-legal-nature="limited_simple_society" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Simples Limitada</li>
                                    <li data-legal-nature="limited_business_company" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade Empresaria Limitada</li>
                                    <li data-legal-nature="eireli_natureza_empresaria" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eireli Natureza Empresaria</li>
                                    <li data-legal-nature="eireli_natureza_simples" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eireli Natureza Simples</li>
                                    <li data-legal-nature="entrepreneur" data-action="click->${this.controllerName}#setSpecialRegime click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Empresário Individual</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label>Regime Especial</label>
                              <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="specialTaxRegimeDropdown">
                                <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="specialTaxRegimeDropdownBtn">
                                  <span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="specialTaxRegimeInput"></span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                  <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                  <ul class="ul-select" data-${this.controllerName}-target="specialTaxRegimeList" data-app--helpers--search-target="searchList"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row d-flex align-items-center" style="height:60px;">
                        <div class="col-6 pr-1 d-flex">
                          <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 mc-tooltip d-flex align-items center">
                            <input disabled type="checkbox" class="custom-control-input" id="issAliquotCheckbox" data-kind="aliquot" data-${this.controllerName}-target="aliquotCheckbox" data-action="click->${this.controllerName}#checkIssType">
                            <label class="custom-control-label f-065 pointer" for="issAliquotCheckbox">Alíquota</label>
                            <span class="mc-tooltiptext">ISS por Alíquota?</span>
                          </div>
                          <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 mc-tooltip d-flex align-items center">
                            <input disabled type="checkbox" class="custom-control-input" id="issFixedCheckbox" data-kind="fixed" data-${this.controllerName}-target="fixedCheckbox" data-action="click->${this.controllerName}#checkIssType">
                            <label class="custom-control-label f-065 pointer" for="issFixedCheckbox">Fixo</label>
                            <span class="mc-tooltiptext">ISS Fixo?</span>
                          </div>
                        </div>

                        <div class="col-6 d-none" data-${this.controllerName}-target="issAliquotDiv">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="issAliquotForm">ISS Porcentagem</label>
                              <input aria-describedby="issAliquotFormHelp" class="form-control form-valid-control" id="issAliquotForm" data-${this.controllerName}-target="issAliquot" data-action="blur->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask" placeholder="ISS Porcentagem" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 d-none" data-${this.controllerName}-target="issFixedDiv">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="issFixedForm">ISS Fixo</label>
                              <input aria-describedby="issFixedFormHelp" class="form-control form-valid-control" id="issFixedForm" data-${this.controllerName}-target="issFixed" data-action="blur->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask" placeholder="ISS Fixo" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-2 mb-4">
                        <div class="col-12 px-1 text-right">
                          <button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRegime" data-${this.controllerName}-target="saveRegimeBtn">Salvar</button>
                        </div>
                      </div>

                      
                    </div>

                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->financials--books--dashboards--meds--add--save#changeStep click->${this.controllerName}#nextStep" data-next="4" data-${this.controllerName}-target="nextBtn">Finalizar</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {

      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      

      if (controller.mode == `batch`) {
        if (controller.actionMode == `new`) {
          controller.formTitleTarget.innerText = `Novo Regime Tributário`
        } else if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Regime Tributário`
        }

        controller.dateYearDropdownTarget.value = controller.current_med.company_year_opened_at
        controller.dateYearDropdownBtnTarget.value = controller.current_med.company_year_opened_at
        controller.dateYearInputTarget.dataset.year = controller.current_med.company_year_opened_at
        controller.dateYearInputTarget.innerText = controller.current_med.company_year_opened_at

        controller.dateMonthDropdownTarget.value = controller.current_med.company_month_opened_at
        controller.dateMonthDropdownBtnTarget.value = controller.current_med.company_month_opened_at
        controller.dateMonthInputTarget.dataset.month = controller.current_med.company_month_opened_at
        controller.dateMonthInputTarget.innerText = controller.current_med.company_month_opened_at

        controller.accrualDateTarget.value = controller.current_med.company_opened_at_pretty

        controller.issAliquotTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).percentMask(0)
        controller.issFixedTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(0)
      } else {
        if (controller.actionMode == `new`) {
          controller.formTitleTarget.innerText = `Novo Regime Tributário`
        } else if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Regime Tributário`
          
          controller.dateYearDropdownTarget.value = controller.current_regime.year
          controller.dateYearDropdownBtnTarget.value = controller.current_regime.year
          controller.dateYearInputTarget.dataset.year = controller.current_regime.year
          controller.dateYearInputTarget.innerText = controller.current_regime.year

          controller.dateMonthDropdownTarget.value = controller.current_regime.month
          controller.dateMonthDropdownBtnTarget.value = controller.current_regime.month
          controller.dateMonthInputTarget.dataset.month = controller.current_regime.month
          controller.dateMonthInputTarget.innerText = controller.current_regime.month

          controller.accrualDateTarget.value = controller.current_regime.accrual_date_pretty

          controller.taxRegimeDropdownTarget.value = controller.current_regime.tax_regime
          controller.taxRegimeInputTarget.dataset.regime = controller.current_regime.tax_regime
          controller.taxRegimeInputTarget.innerText = controller.current_regime.tax_regime_pretty

          if (controller.application.current_date.open) {
            controller.taxRegimeDropdownBtnTarget.disabled = false
            controller.subRegimeDropdownBtnTarget.disabled = false
            controller.specialTaxRegimeDropdownBtnTarget.disabled = false
            controller.legalNatureDropdownBtnTarget.disabled = false
          } else {
            controller.taxRegimeDropdownBtnTarget.disabled = true
            controller.subRegimeDropdownBtnTarget.disabled = true
            controller.specialTaxRegimeDropdownBtnTarget.disabled = true
            controller.legalNatureDropdownBtnTarget.disabled = true
          }

          controller.lastTwelveMonthsInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_regime.last_twelve_months_sum)
          controller.accrualMonthInputTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyOptionMask(controller.current_regime.accrual_month)

          controller.issAliquotTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).percentMask(controller.current_regime.iss_rate)
          controller.issFixedTarget.value = controller.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(controller.current_regime.iss_amount)

          controller.setSubRegime(controller.current_regime.tax_regime)
        }
      }

      
      

      controller.dateYearDropdownBtnTarget.disabled = true
      controller.dateMonthDropdownBtnTarget.disabled = true
      controller.accrualDateTarget.disabled = true

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  chooseSubRegime(ev) {
    var regime = ev.currentTarget.dataset.regime

    this.setSubRegime(regime)
  }

  setSubRegime(regime) {
    // var html1 = `<li data-special-regime="automatic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Automatico</li>
    //              <li data-special-regime="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
    //              <li data-special-regime="professional_society" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade De Profissionais</li>
    //              <li data-special-regime="microenterprise_individual" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempreendedor Individual</li>
    //              <li data-special-regime="microenterprise_small_company" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempresario Empresa Pequeno Porte</li>`

    this.regime = regime
    var listHtml = ``
    var specialHtml = ``

    this.subRegimeDropdownTarget.value = ``
    this.subRegimeInputTarget.dataset.month = ``
    this.subRegimeInputTarget.innerText = ``

    this.legalNatureInputTarget.dataset.specialRegime = ``
    this.legalNatureDropdownTarget.value = ``
    this.legalNatureInputTarget.innerText = ``

    if (this.regime == `simple_national`) {
      listHtml += `<li data-sub-regime="attachment_3" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anexo 3</li>
                   <li data-sub-regime="attachment_5" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Anexo 5</li>`

      this.setIssType(`aliquot`)
      this.aliquotCheckboxTarget.disabled = true
      this.fixedCheckboxTarget.disabled = true
      this.issAliquotTarget.disabled = true

      specialHtml += `<li data-special-regime="microenterprise_small_company" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Microempresario Empresa Pequeno Porte</li>`
      this.specialTaxRegimeInputTarget.dataset.specialRegime = `microenterprise_small_company`
      this.specialTaxRegimeDropdownTarget.value = `microenterprise_small_company`
      this.specialTaxRegimeInputTarget.innerText = `Microempresario Empresa Pequeno Porte`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true


    } else if (this.regime == `presumed_profit`) {
      listHtml += `<li data-sub-regime="normal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Presunção Normal</li>
                   <li data-sub-regime="hospital_match" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Equiparação Hospitalar</li>`

      this.setIssType(`none`)
      // this.aliquotCheckboxTarget.disabled = false
      // this.fixedCheckboxTarget.disabled = false
      // this.issAliquotTarget.disabled = false

      specialHtml += `<li data-kind="aliquot" data-special-regime="none" data-action="click->${this.controllerName}#checkIssType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>
                      <li data-kind="fixed" data-special-regime="professional_society" data-action="click->${this.controllerName}#checkIssType click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sociedade De Profissionais</li>`

      this.specialTaxRegimeInputTarget.dataset.specialRegime = ``
      this.specialTaxRegimeDropdownTarget.value = ``
      this.specialTaxRegimeInputTarget.innerText = ``
      this.specialTaxRegimeDropdownBtnTarget.disabled = false
    }

    this.subRegimeListTarget.innerHTML = listHtml
    this.specialTaxRegimeListTarget.innerHTML = specialHtml
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setSpecialRegime(ev) {

    var legalNature = ev.currentTarget.dataset.legalNature

    if (this.regime == `presumed_profit` && (legalNature == `limited_business_company` || legalNature == `eireli_natureza_empresaria` || legalNature == `entrepreneur`)) {
      this.setIssType(`aliquot`)
      // this.aliquotCheckboxTarget.disabled = true
      // this.fixedCheckboxTarget.disabled = true
      this.specialTaxRegimeInputTarget.dataset.specialRegime = `none`
      this.specialTaxRegimeDropdownTarget.value = `none`
      this.specialTaxRegimeInputTarget.innerText = `Nenhum`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true
      this.issAliquotTarget.disabled = false
    } else if (this.regime == `presumed_profit`) {
      this.setIssType(`none`)
      // this.aliquotCheckboxTarget.disabled = false
      // this.fixedCheckboxTarget.disabled = false
      this.specialTaxRegimeInputTarget.dataset.specialRegime = ``
      this.specialTaxRegimeDropdownTarget.value = ``
      this.specialTaxRegimeInputTarget.innerText = ``
      this.specialTaxRegimeDropdownBtnTarget.disabled = false
    } else if (this.regime == `simple_national`) {
      this.setIssType(`aliquot`)
      this.aliquotCheckboxTarget.disabled = true
      this.fixedCheckboxTarget.disabled = true
      this.issAliquotTarget.disabled = true

      this.specialTaxRegimeInputTarget.dataset.specialRegime = `microenterprise_small_company`
      this.specialTaxRegimeDropdownTarget.value = `microenterprise_small_company`
      this.specialTaxRegimeInputTarget.innerText = `Microempresario Empresa Pequeno Porte`
      this.specialTaxRegimeDropdownBtnTarget.disabled = true
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  checkIssType(ev) {
    var kind = ev.target.dataset.kind
    this.setIssType(kind)
  }

  setIssType(kind) {
    if (kind == "aliquot") {
      this.aliquotCheckboxTarget.checked = true
      this.fixedCheckboxTarget.checked = false
      this.issFixedDivTarget.classList.add("d-none")
      this.issAliquotDivTarget.classList.remove("d-none")
      this.issAliquotTarget.value = `0,00 %`
      this.issAliquotTarget.focus()
    } else if (kind == "fixed") {
      this.aliquotCheckboxTarget.checked = false
      this.fixedCheckboxTarget.checked = true
      this.issAliquotDivTarget.classList.add("d-none")
      this.issFixedDivTarget.classList.remove("d-none")
      this.issFixedTarget.value = `R$ 0,00`
      this.issFixedTarget.focus()
    } else if (kind == "none") {
      this.aliquotCheckboxTarget.checked = false
      this.fixedCheckboxTarget.checked = false
      this.issAliquotDivTarget.classList.add("d-none")
      this.issFixedDivTarget.classList.add("d-none")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshRegimeTimer = setInterval(function () {
      var len = 0

      if (controller.taxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.subRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.specialTaxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.legalNatureInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateYearInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateMonthInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked == false && controller.fixedCheckboxTarget.checked == false) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked && controller.issAliquotTarget.value == ``) {
        len += 1
      }

      if (controller.fixedCheckboxTarget.checked && controller.issFixedTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveRegimeBtnTarget.disabled = false
      } else {
        controller.saveRegimeBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.regimeConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopRegimeRefreshing() {
    if (this.refreshRegimeTimer) {
      clearInterval(this.refreshRegimeTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
