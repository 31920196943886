import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "view", "closing", "action", "accountBtn", "resumeBtn", "transactionBtn", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--balances--dashboard`
  }

  doBalancesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2" data-${this.controllerName}-target="action"></div>
                  <div class="col-10" data-${this.controllerName}-target="content" data-controller="operations--products--clinics--managements--balances--index
                                                                                                    operations--products--clinics--managements--balances--save
                                                                                                    operations--products--clinics--managements--balances--transactions
                                                                                                    operations--products--clinics--managements--balances--closing"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--show--submenu`).dashboardToggleBtn(`CONTAS DE BALANÇO`)
      controller.getControllerByIdentifier("operations--products--clinics--managements--balances--closing").doClosingGrid()
      controller.doActionHtml()
    })
  }

  doActionHtml() {
    var actionHtml = `<div class="row mt-3 w-100">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-body py-0 px-2 f-bold bg-primary pointer" data-action="click->${this.controllerName}#toggleActionBtn" data-kind="resume" data-${this.controllerName}-target="resumeBtn">
                                <div class="row my-2">
                                  <div class="col-12 px-0 text-center">
                                    Ver Resumo
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-5 w-100">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-body py-0 px-2 f-bold pointer" data-action="click->${this.controllerName}#toggleActionBtn" data-kind="account" data-${this.controllerName}-target="accountBtn">
                                <div class="row my-2">
                                  <div class="col-12 px-0 text-center">
                                    Ver Contas
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`

    this.actionTarget.innerHTML = actionHtml
  }

  toggleActionBtn(ev) {
    
    var kind = ev.currentTarget.dataset.kind
    var kinds = ["account", "resume"]

    kinds.forEach(element => {
      this.nameTarget(`${element}Btn`).classList.remove(`bg-primary`)
    });

    this.nameTarget(`${kind}Btn`).classList.add(`bg-primary`)
    
    if (kind == `account`) {
      this.getControllerByIdentifier("operations--products--clinics--managements--balances--index").doIndexListHtml()
    } else if (kind == `resume`) {
      this.getControllerByIdentifier("operations--products--clinics--managements--balances--closing").doClosingGrid()
    }

  }

  changeActionBtn(ev) {
    var kind = ev.currentTarget.dataset.kind
    this.doActionHtml(kind)
  }

  refreshBalances(element, cols) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).canSetClosing = false

    if (element) {
      element.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(cols, 10)
    }

    this.send_data = { current_user: {}, balance: {} }

    this.send_data.balance.clinic_id = this.application.clinic.id
    this.send_data.balance.date_id = this.application.current_date.id

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medclinic_management_balances`

    var url = "/operations/products/clinics/financials/balances/refresh"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          response.data.cln.forEach(application_data => {
            controller.application.balances.forEach(element => {
              if (element.date_id == application_data.date_id) {
                element.data = application_data.data
              }
            })
          })

          controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).canSetClosing = true

          if (controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).hasMainDashboardTarget) {
            controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--closing`).doCalculationCards()
          } else if (controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--index`).hasMainCardTarget) {
            controller.getControllerByIdentifier(`operations--products--clinics--managements--balances--index`).doDataTable()
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
