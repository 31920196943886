import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn",
                    "bindSum", "multipleCheck", "generalCheck", "generalCheckDiv",
                    "dueReceivables", "receivedReceivables", "issuedReceivables", "generalCheckDiv"]

  connect() {
    this.controllerName = `financials--books--receivables--entities--index`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.tableCols = 8
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `client_name` }
    this.filter = false
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {
    if (this.application.permissions[this.permission].can_download) {
      var downloadReceivedLine = `<span data-action="click->${this.controllerName}#downloadReceivedReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Recebíveis Recebimento</span>`
    } else {
      var downloadReceivedLine = ``
    }

    if (this.application.permissions[this.permission].can_download) {
      var downloadAccrualLine = `<span data-action="click->${this.controllerName}#downloadAccrualReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Recebíveis Competência</span>`
    } else {
      var downloadAccrualLine = ``
    }

    if (this.application.permissions[this.permission].can_download) {
      var downloadIssuedLine = `<span data-action="click->${this.controllerName}#downloadIssuedReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Recebíveis Fiscal</span>`
    } else {
      var downloadIssuedLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshAddons" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Recebíveis</span>`
    var bindSumLine = `<span data-action="click->${this.controllerName}#checkForBindSum" class="dropdown-item py-1 pointer dropdown-submenu-item">Somar Faturas</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                          ${bindSumLine}
                          ${downloadIssuedLine}
                          ${downloadReceivedLine}
                          ${downloadAccrualLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Recebíveis</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle"></th>
                              <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle">
                                Cliente
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="client_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="client_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-20 align-middle text-center">
                                Contrato
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="contract_name" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="contract_name" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Tipo
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Subtipo
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="subkind_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="subkind_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Ocorrência
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="plan_pretty" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="plan_pretty" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">
                                Início
                                <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="desc">south</span>
                                <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="asc">north</span>
                              </th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle text-center">Vlr Contrato</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center" colspan="2">Adimplência</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center" colspan="2">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--entities--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    this.current_addons = []

    if (this.filter) {
      if (this.filter_field == `has_receivable`) {
        this.application.addons.forEach(addon => {

          var addonReceivableDateIds = this.mapElements(addon.receivables, `date_id`)

          if (this.filter_option == `with`) {
            if (addon.started_token <= this.application.current_date.token && addonReceivableDateIds.includes(this.application.current_date.id)) {
              this.current_addons[this.current_addons.length] = addon
            }
          } else if (this.filter_option == `without`) {
            if (addon.started_token <= this.application.current_date.token && !addonReceivableDateIds.includes(this.application.current_date.id)) {
              this.current_addons[this.current_addons.length] = addon
            }
          }
        })
      } else {
        this.application.addons.forEach(addon => {
          // if (addon[this.filter_field] == this.getControllerByIdentifier(`app--helpers--strings`).fromStringToBoolean(this.filter_option)) {
          if (addon[this.filter_field] == this.filter_option) {
            if (addon.started_token <= this.application.current_date.token) {
              this.current_addons[this.current_addons.length] = addon
            }
          }
        })
      }
    } else {
      this.application.addons.forEach(addon => {
        if (addon.started_token <= this.application.current_date.token) {
          this.current_addons[this.current_addons.length] = addon
        }
      })
    }

    if (this.sort.mode == `asc`) {
      var addons = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.current_addons, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var addons = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_addons, this.sort.field)
    }

    this.totalReceivables = 0
    this.dueAmount = 0
    this.receivedAmount = 0
    this.issuedAmount = 0

    addons.forEach(addon => {
      if (addon.receivables.length > 0) {
        addon.receivables.forEach(receivable => {
          this.totalReceivables += 1

          if (receivable.received_date_id == this.application.current_date.id) {
            this.receivedAmount += Number(receivable.total_amount_revenue)
          }
          if (receivable.issued_date_id == this.application.current_date.id) {
            this.issuedAmount += Number(receivable.total_amount_invoice)
          }
          if (receivable.date_id == this.application.current_date.id) {
            this.dueAmount += Number(receivable.total_amount_charged)
          }
        })
      }
    })

    if (addons.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(addons.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (addons[x] !== undefined) {
            internPage[internPage.length] = addons[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: addons.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)

      this.dueReceivablesTarget.innerHTML = `<strong>Cobrado</strong>: <br> ${this.controllerNumber.currencyOptionMask(this.dueAmount)}`
      this.receivedReceivablesTarget.innerHTML = `<strong>Receita</strong>: <br> ${this.controllerNumber.currencyOptionMask(this.receivedAmount)}`
      this.issuedReceivablesTarget.innerHTML = `<strong>Fiscal</strong>: <br> ${this.controllerNumber.currencyOptionMask(this.issuedAmount)}`
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""

    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Recebíveis na ${this.application.current_date.name}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.receivableTablePartial(element, data.length))
      })
    }

  }

  receivableTablePartial(element, length) {

    var status = ``
    var latePayment = ``
    var conciliated = ``
    var method = ``
    var product_interval_pretty = `Sem Recebível`
    var acrrual_status_pretty = `Sem Recebível`
    var total_amount_charged = 0
    var total_amount_invoice = 0

    var latePayment = `<button data-action="click->${this.controllerName}#addReceivable" data-kind="cost_center" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">add</span>
                          <span class="mc-tooltiptext">Adicionar Recebível</span>
                        </button>`

    if (element.receivables.length > 0) {
      element.receivables.forEach(receivable => {

        if (receivable.date_id == this.application.current_date.id) {

          if (receivable.status == `paid`) {
            status = `<span class="f-070 badge badge-secondary-success">Pago</span>`
          } else if (receivable.status == `pending`) {
            status = `<span class="f-070 badge badge-secondary-warning">Pendente</span>`
          } else if (receivable.status == `canceled`) {
            status = `<span class="f-070 badge badge-secondary-danger">Cancelado</span>`
          } else if (receivable.status == `expired`) {
            status = `<span class="f-070 badge badge-secondary-dark">Expirado</span>`
          } else {
            status = `<span class="f-070 badge badge-secondary-danger default">Não Pago</span>`
          }

          if (receivable.acrrual_status) {
            if (receivable.late_payment) {
              latePayment = `<span class="f-070 badge badge-secondary-danger default">Atrasado</span>`
            } else {
              latePayment = `<span class="f-070 badge badge-secondary-success">Em Dia</span>`
            }
          } else {
            latePayment = `<span class="f-070 badge badge-secondary-danger">Não Pago</span>`
          }

          if (receivable.conciliated) {
            conciliated = `<span class="f-070 badge badge-secondary-success">Conciliação Ok</span>`
          } else {
            conciliated = `<span class="f-070 badge badge-secondary-danger">Não Conciliado</span>`
          }

          if (receivable.payment_method == `bank_slip`) {
            method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">receipt</span>
                      <span class="mc-tooltiptext">${receivable.method_pretty}</span>
                    </span>`
          } else if (receivable.payment_method == `credit_card`) {
            method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">credit_card</span>
                      <span class="mc-tooltiptext">${receivable.method_pretty}</span>
                    </span>`
          } else if (receivable.payment_method == `pix`) {
            method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark default">pix</span>
                      <span class="mc-tooltiptext">${receivable.method_pretty}</span>
                    </span>`
          } else if (receivable.payment_method == `bank_debt`) {
            method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">account_balance</span>
                      <span class="mc-tooltiptext">${receivable.method_pretty}</span>
                    </span>`
          } else if (receivable.payment_method == `cash`) {
            method = `<span class="mc-tooltip">
                      <span class="material-icons md-sm md-dark pointer">money</span>
                      <span class="mc-tooltiptext">${receivable.method_pretty}</span>
                    </span>`
          } else {
            method = `Sem Pagamento`
          }

          product_interval_pretty = receivable.product_interval_pretty
          acrrual_status_pretty = receivable.acrrual_status_pretty
          total_amount_charged = receivable.total_amount_charged
          total_amount_invoice = receivable.total_amount_invoice
        }
      })
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    var check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    if (this.kind == `sum_addons`) {
      check = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                  <input type="checkbox" class="custom-control-input" id="check-${element.id}" data-id="${element.id}" data-${this.controllerName}-target="multipleCheck check-${element.id}" data-action="click->${this.controllerName}#bindSum">
                  <label class="custom-control-label f-065" for="check-${element.id}"></label>
                </div>
              </td>`
    }

    var rowHtml = `${tableRow}
                    ${check}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-left pointer" data-action="click->${this.controllerName}#showReceivables">
                      <p class="mb-0 mc-tooltip pointer">
                        <span>${element.client_name}</span>
                        <span class="mc-tooltiptext">Ver Recebíveis</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#showReceivables">
                      <p class="mb-0 mc-tooltip pointer">
                        <span>${element.contract_name}</span>
                        <span class="mc-tooltiptext">Ver Recebíveis</span>
                      </p>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.subkind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.plan_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.controllerNumber.currencyOptionMask(element.amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${acrrual_status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${latePayment}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${status}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${conciliated}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-1 px-0 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-1 px-0 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-${this.controllerName}-target="bindSum"></span>
                          </div>
                        </div>
                        <div class="col-6 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="mx-5 text-center" data-${this.controllerName}-target="dueReceivables"></span>
                            <span class="mx-5 text-center" data-${this.controllerName}-target="receivedReceivables"></span>
                            <span class="mx-5 text-center" data-${this.controllerName}-target="issuedReceivables"></span>
                          </div>
                        </div>
                        <div class="col-4 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  concilationModal(ev) {
    var addonId = ev.currentTarget.closest(`.itemRow`).dataset.id
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
    this.getControllerByIdentifier(`financials--books--receivables--entities--filter`).cancelFilter()

    this.doDataTable()
  }

  checkForBindSum() {
    this.kind = `sum_addons`
    this.doDataTable()

    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)

    this.kind = false
  }

  generalBindSum(ev) {

    if (ev.currentTarget.checked) {
      this.multipleCheckTargets.forEach(check => {
        if (check.closest(`.itemRow`).style.display == "") {
          check.checked = true
        }
      })
    } else {
      this.multipleCheckTargets.forEach(check => {
        check.checked = false
      })
    }

    this.bindSum()
  }

  bindSum() {
    var bindSum = 0
    var addonsArray = []

    this.multipleCheckTargets.forEach(check => {
      if (check.checked) {
        addonsArray[addonsArray.length] = Number(check.dataset.id)
      }
    })

    this.application.addons.forEach(addon => {
      if (addonsArray.includes(addon.id)) {
        bindSum += Number(addon.amount)
      }
    })

    this.bindSumTarget.innerText = `Valor Total Selecionado: ${this.controllerNumber.currencyOptionMask(bindSum)}`
  }

  showReceivables(ev) {
    var addonId = ev.currentTarget.closest(`.itemRow`).dataset.id
    var currentAddon = {}

    this.application.addons.forEach(element => {
      if (element.id == addonId) {
        currentAddon = element
      }
    })

    var controllerModal = `financials--books--receivables--entities--list-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).obj_type = `addon`
      controller.getControllerByIdentifier(controllerModal).obj_id = currentAddon.id
      controller.getControllerByIdentifier(controllerModal).title = `${currentAddon.contract_name} | Início: ${currentAddon.started_at_pretty}`
      controller.getControllerByIdentifier(controllerModal).permission = `financial_receivable_entities`
      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  refreshAddons() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { addon: { active: true, status: `active` }, current_user: { current_user_id: this.application.current_user.id, feature: `financial_receivable_addons` } }
    const url = "/financials/books/clients/addons/list_by_status"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentAddonIds = controller.mapElements(controller.application.addons, `id`)
        var refreshAddonIds = controller.mapElements(response.data.cln, `id`)

        if (response.process) {
          controller.application.addons.forEach(addon => {
            if (currentAddonIds.includes(addon.id) && !refreshAddonIds.includes(addon.id)) {
              controller.application.addons.forEach((element, i) => {
                if (element.id == addon.id) {
                  controller.application.addons.splice(controller.application.addons.indexOf(element), 1)
                }
              })
            }
          })

          response.data.cln.forEach(addon => {
            if (currentAddonIds.includes(addon.id) && refreshAddonIds.includes(addon.id)) {
              controller.application.addons.forEach((element, i) => {
                if (element.id == addon.id) {
                  controller.application.addons.splice(controller.application.addons.indexOf(element), 1, addon)
                }
              })
            } else if (!currentAddonIds.includes(addon.id) && refreshAddonIds.includes(addon.id)) {
              controller.application.addons[controller.application.addons.length] = addon
            }
          })
        } else if (response.data.cln.length == 0) {
          controller.application.addons.forEach((element, i) => {
            if (element.date_id == controller.application.current_date.id) {
              controller.application.addons.splice(controller.application.addons.indexOf(element), 1)
            }
          })
        }

        controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`).doBadgeCounts()
        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  downloadReceivedReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, receivable: {} }
    
    this.send_data.receivable.received_date_id = this.application.current_date.id
    this.send_data.receivable.kind = `received`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-recebíveis-recebimento`
      this.send_data.receivable.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-recebíveis-recebimento`
      this.send_data.receivable.med_id = ``
    }

    this.requestDownload(fileName)
  }

  downloadAccrualReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, receivable: {} }
    
    this.send_data.receivable.date_id = this.application.current_date.id
    this.send_data.receivable.kind = `accrual`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-recebíveis-competência`
      this.send_data.receivable.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-recebíveis-competência`
      this.send_data.receivable.med_id = ``
    }

    this.requestDownload(fileName)
  }

  downloadIssuedReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, receivable: {} }
    
    this.send_data.receivable.issued_date_id = this.application.current_date.id
    this.send_data.receivable.kind = `issued`
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-recebíveis-fiscal`
      this.send_data.receivable.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-recebíveis-fiscal`
      this.send_data.receivable.med_id = ``
    }

    this.requestDownload(fileName)
  }

  requestDownload(fileName) {
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/financials/books/receivables/entities/download_receivables"
    var fileExtension = `csv`
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  addReceivable(ev) {
    var addElement = ev.currentTarget
    var currentAddon = {}
    var currentClient = {}

    this.application.addons.forEach(element => {
      if (element.id == addElement.closest(`.itemRow`).dataset.id) {
        currentAddon = element
      }
    })

    this.application.clients.forEach(element => {
      if (element.id == currentAddon.client_id) {
        currentClient = element
      }
    })

    var modalName = `financials--books--receivables--entities--save-modal`
    
    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerDashboard = controller
      controller.getControllerByIdentifier(modalName).current_client = currentClient
      controller.getControllerByIdentifier(modalName).current_addon = currentAddon
      controller.getControllerByIdentifier(modalName).actionMode = `new`
      
      controller.getControllerByIdentifier(modalName).permission = `financial_receivable_entities`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
