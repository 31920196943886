import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "mainCard", "bodyTable", "footerTable", "refreshCalculationBtn", "dateInput", "dateDropdown", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--reports--followup`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `account_name` }
    this.doCalculationListHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doCalculationListHtml() {

    var refreshLine = `<span data-action="click->${this.controllerName}#getCalculations" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Acompanhamento</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Acompanhamento das Declarações</h6>
                    <input class="form-control f-070 w-40 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">
                                  Nome
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">CPF</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-12 align-middle text-center">
                                  Jornada
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  # Arquivos
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="total_files" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="total_files" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  # Não Revisados
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="files_not_revised" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="files_not_revised" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center"># Aprovados</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  # Processados
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="files_processed" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="files_processed" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center"># Negados</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Tempo Total
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="total_term" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="total_term" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Tempo Faltante
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="left_term" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="left_term" data-mode="asc">north</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--tax-filings--reports--dashboard`).reportContentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"

      controller.doDataTable()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // controller.refreshCalculationBtnTarget.classList.add("d-none")
    })
  }

  doDataTable() {
    // var tax_filings = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.tax_filings, "account_name")

    this.calculations = []

    this.application.tax_filings.forEach(elementProduct => {

      var calculation = {}
      calculation.product_id = elementProduct.product_id
      calculation.product_path = elementProduct.product_path
      calculation.account_name = elementProduct.account_name
      calculation.account_cpf = elementProduct.account_cpf
      calculation.account_cpf_pretty = elementProduct.account_cpf_pretty
      if (elementProduct.calculations.length > 0) {
        var notCalculation = true
        elementProduct.calculations.forEach(elementCalc => {
          if (Number(elementCalc.date_token.substr(5, 4)) == Number(this.current_date.year - 1) && notCalculation) {
            calculation.journey_status_pretty = elementCalc.journey_status_pretty
            calculation.files_not_revised = elementCalc.files_not_revised
            calculation.files_approved = elementCalc.files_approved
            calculation.files_denied = elementCalc.files_denied
            calculation.files_processed = elementCalc.files_processed
            calculation.total_files = elementCalc.total_files
            calculation.average_term = elementCalc.average_term
            calculation.total_term = elementCalc.total_term
            calculation.partial_term = elementCalc.partial_term

            notCalculation = false
          } else if (notCalculation) {
            calculation.journey_status_pretty = `N/D`
            calculation.files_not_revised = 0
            calculation.files_approved = 0
            calculation.files_denied = 0
            calculation.files_processed = 0
            calculation.total_files = 0
            calculation.average_term = 0
            calculation.total_term = 0
            calculation.partial_term = 0

            notCalculation = true
          }
        })
      } else {
        calculation.journey_status_pretty = `N/D`
        calculation.files_not_revised = 0
        calculation.files_approved = 0
        calculation.files_denied = 0
        calculation.files_processed = 0
        calculation.total_files = 0
        calculation.average_term = 0
        calculation.total_term = 0
        calculation.partial_term = 0
      }

      calculation.left_term = calculation.total_term - calculation.partial_term

      this.calculations[this.calculations.length] = calculation
    })

    if (this.sort.mode == `asc`) {
      var calculations = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.calculations, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var calculations = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.calculations, this.sort.field)
    }

    if (calculations.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(calculations.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (calculations[x] !== undefined) {
            internPage[internPage.length] = calculations[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: calculations.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="11" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Clientes</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.calculationTablePartial(element))
      });
    }
  }

  calculationTablePartial(element) {

    var rowHtml = `<tr class="itemRow" data-id="${element.product_id}" data-product-path="${element.product_path}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.account_name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ir para IRPF ${element.account_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">
                      <span class="mb-0 f-065 mc-tooltip grab">
                        <span data-controller="app--helpers--copy" data-copy="${element.account_cpf}" data-action="click->app--helpers--copy#copy">${element.account_cpf_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer" data-action="click->${this.controllerName}#goToShowPage">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.journey_status_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ir para IRPF ${element.account_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.total_files}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.files_not_revised}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.files_approved}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.files_processed}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.files_denied}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--time").doSimpleDurationFormat(element.total_term * 60)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--time").doSimpleDurationFormat(element.left_term * 60)}</td>
                    
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
  }

  getCalculations() {
    if (this.hasBodyTableTarget) {
      this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(10, 10)
    }
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { product: { name: "medfiling", kind: "receivement", year: this.current_date.year }, current_user: { current_user_id: this.application.current_user.id, feature: `medfiling_teams` } }
    const url = "/operations/products/tax_filings/calculations/refresh_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        console.log(response)
        if (response.process) {
          controller.application.tax_filings = response.data.cln
        }
        
        controller.doCalculationListHtml()
        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goToShowPage(ev) {
    var url = ev.target.closest(".itemRow").dataset.productPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {

  }
}