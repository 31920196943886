import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--apurations--regimes--index`
    this.numberController = this.getControllerByIdentifier("app--helpers--numbers")
    this.tableCols = 8
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `med_name` }
    this.hasShow = false
  }

  showRegime(ev) {
    var regimeId = ev.target.closest(".itemRow").dataset.id

    var html = `<div class="modal fade" data-controller="financials--books--apurations--regimes--modal" data-financials--books--apurations--regimes--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--apurations--regimes--modal-target="body" data-controller="financials--books--apurations--regimes--save"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.application.regimes.forEach(element => {
        if (element.id == regimeId) {
          controller.getControllerByIdentifier("financials--books--apurations--regimes--modal").current_regime = element

          controller.application.meds.forEach(med => {
            if (med.id == element.med_id) {
              controller.getControllerByIdentifier("financials--books--apurations--regimes--modal").current_med = med
            }
          })
        }
      })

      controller.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).current_date = controller.current_date
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).permission = controller.permission
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).regime_id = regimeId
      controller.getControllerByIdentifier(`financials--books--apurations--regimes--modal`).open()
    })
  }

  addRegime() {
    this.getControllerByIdentifier("financials--books--apurations--regimes--save").actionMode = "new"
    this.getControllerByIdentifier("financials--books--apurations--regimes--save").current_regime = {}
    this.getControllerByIdentifier("financials--books--apurations--regimes--save").doFormHtml()
  }

  editRegime(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.regimes.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("financials--books--apurations--regimes--save").current_regime = element

        this.application.meds.forEach(med => {
          if (med.id == element.med_id) {
            this.getControllerByIdentifier("financials--books--apurations--regimes--save").current_med = med
          }
        })
      }
    })
    this.getControllerByIdentifier("financials--books--apurations--regimes--save").actionMode = "edit"
    this.getControllerByIdentifier("financials--books--apurations--regimes--save").doFormHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addRegime" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Regime Tributário</span>`
    } else {
      var addLine = ``
    }
    var addLine = ``

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshRegimes" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Regimes Tributários</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    // filter_list
    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`


    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Regimes Tributários</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Competência</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">MedCapital</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-12 align-middle">Tipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle">Subtipo</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Tipo de ISS</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Taxa de ISS</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">12 Meses</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Mês Corrente</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Efetiva</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.controllerDash.listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_regimes = []

    this.application.regimes.forEach(element => {
      if (element.date_id == this.current_date.id) {
        current_regimes[current_regimes.length] = element
      }
    })

    if (this.sort.mode == `asc`) {
      var regimes = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_regimes, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var regimes = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_regimes, this.sort.field)
    }

    if (regimes.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(regimes.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (regimes[x] !== undefined) {
            internPage[internPage.length] = regimes[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: regimes.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Regimes Tributários</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.regimeTablePartial(element, data.length))
      });
    }

    if (this.checked_id) {
      this.checkboxShowTargets.forEach(element => {
        if (element.id == `check-${this.checked_id}`) {
          element.checked = true
        }
      })
    }
  }

  regimeTablePartial(element, length) {

    if (element.open) {
      var openStatus = `<span class="mc-tooltip"> 
                          <span class="material-icons md-200 md-success default">done</span>
                          <span class="mc-tooltiptext">Aberto</span>
                        </span>`
    } else {
      var openStatus = `<span class="mc-tooltip"> 
                          <span class="material-icons md-200 md-danger default">clear</span>
                          <span class="mc-tooltiptext">Fechado</span>
                        </span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    //<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.percentMask(Math.round(element.iss_rate * 100))}</td>
    //<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.currencyMask(Math.round(element.last_twelve_months_sum * 100))}</td>

    //<td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.currencyMask(Math.round(element.accrual_month * 100))}</td>

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showRegime">${element.accrual_date_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showRegime">${element.med_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showRegime">${element.tax_regime_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.sub_tax_regime_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.iss_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.percentOptionMask(element.iss_rate, { scale: 5 })}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.currencyOptionMask(element.last_twelve_months_sum, { scale: 2 })}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.currencyOptionMask(element.accrual_month)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.numberController.percentOptionMask(element.effective_total_tax, { scale: 4 })}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${openStatus}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  refreshRegimes() {
    this.getControllerByIdentifier(`financials--books--apurations--regimes--dashboard`).getRegimes()
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
