import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "partners", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--transfers--dashboard`
  }

  doTransfersDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 pr-1" data-${this.controllerName}-target="partners" data-controller="operations--products--clinics--financials--transfers--partners"></div>
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--financials--transfers--index"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--financials--transfers--save operations--products--clinics--financials--transfers--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--show--submenu`).dashboardToggleBtn(`REPASSES`)

      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--index`).permission = `medclinic_financial_transfers`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--save`).permission = `medclinic_financial_transfers`
      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--show`).permission = `medclinic_financial_transfers`

      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--partners`).doListHtml()
      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`operations--products--clinics--financials--transfers--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
