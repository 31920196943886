
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "ticketCountTooltip", "processCount", "processCountTooltip",
                    "uploadCount", "uploadCountTooltip", "documentCount", "documentCountTooltip",
                    "totalDocuments", "totalProcess", "totalUploads", "totalTickets"]

  connect() {
    this.controllerName = `operations--products--tax-filings--show--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").reconnect()
  }

  goToUploads() {
    this.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").doUploadsDashboard()
  }

  goToTickets() {
    this.getControllerByIdentifier("operations--products--tax-filings--tickets--dashboard").doTicketsDashboard()
  }

  goToNotes() {
    this.getControllerByIdentifier("operations--products--tax-filings--notes--dashboard").doNotesDashboard()
  }

  goToDocuments() {
    this.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").doDocumentsDashboard()
  }

  goToJourney() {
    this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").doJourneyDashboard()
  }

  goToProcess() {
    this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").doProcessDashboard()
  }

  goToSettings() {
    this.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").doSettingsDashboard()
  }

  goToLogs() {
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_filter = true
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_id = this.application.tax_filing.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").record_type = `operation_products`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_id = this.application.current_date.id
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").obj_type = `operation_dates`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").permission = `medfiling_teams`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").gridElement = this.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").submenuController = this
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").title = `Declaração do Ano-Exercício ${this.application.current_date.financial_year}`
    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").noLogsMessage = `Não há Logs para a Declaração do Ano-Exercício ${this.application.current_date.financial_year}`

    this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").doLogsDashboard()
  }

  goToEmails() {
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").date_filter = true
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").domain_id = this.application.tax_filing.id
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").domain_type = `operation_products`
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").permission = `medfiling_teams`
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").dashboardController = this.getControllerByIdentifier("operations--products--tax-filings--show--main")
    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").submenuController = this

    this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").doEmailsDashboard()
  }

  goToSummaries() {
    this.getControllerByIdentifier("operations--products--tax-filings--summaries--dashboard").doSummariesDashboard()
  }

  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`

    if (this.application.permissions[this.application.files_permission].can_upload) {
      var uploadBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToUploads ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn uploadCount">Documentos</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="uploadCountTooltip"></span>
                    </span>`
    } else {
      var uploadBtn = ``
    }

    if (this.application.permissions[this.application.files_permission].can_update) {
      var documentBtn = `<span class="mc-tooltip">
                          <button type="button" data-action="${this.controllerName}#goToDocuments ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn documentCount">Revisão</button>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="documentCountTooltip"></span>
                        </span>`
    } else {
      var documentBtn = ``
    }

    if (this.application.permissions[this.application.files_permission].can_upload) {
      var journeyBtn = `<button type="button" data-action="${this.controllerName}#goToJourney ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Jornada</button>`
    } else {
      var journeyBtn = ``
    }

    if (this.application.permissions[this.application.files_permission].can_update) {
      var processBtn = `<span class="mc-tooltip">
                          <button type="button" data-action="${this.controllerName}#goToProcess ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn processCount">Processamento</button>
                          <span class="mc-tooltiptext" data-${this.controllerName}-target="processCountTooltip"></span>
                        </span>`
    } else {
      var processBtn = ``
    }

    if (this.application.permissions[this.application.product_permission].can_manage) {
      var settingBtn = `<button type="button" data-action="${this.controllerName}#goToSettings ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Configurações</button>`
    } else {
      var settingBtn = ``
    }

    if (this.application.permissions[this.application.product_permission].can_manage) {
      var logBtn = `<button type="button" data-action="${this.controllerName}#goToLogs ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Logs</button>`
    } else {
      var logBtn = ``
    }

    if (this.application.permissions[this.application.product_permission].can_manage) {
      var emailBtn = `<button type="button" data-action="${this.controllerName}#goToEmails ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Emails</button>`
    } else {
      var emailBtn = ``
    }

    if (this.application.permissions[this.application.summary_permission].can_index) {
      var summaryBtn = `<button type="button" data-action="${this.controllerName}#goToSummaries ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Resumo IRPF</button>`
    } else {
      var summaryBtn = ``
    }

    if (this.application.permissions[this.application.ticket_permission].can_index) {
      var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`
    } else {
      var ticketBtn = ``
    }

    if (this.application.permissions[this.application.note_permission].can_index) {
      var noteBtn = `<button type="button" data-action="${this.controllerName}#goToNotes ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Anotações</button>`
    } else {
      var noteBtn = ``
    }
    

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${uploadBtn}
                  ${documentBtn}
                  ${processBtn}
                  ${journeyBtn}
                  ${summaryBtn}
                  ${settingBtn}
                  ${ticketBtn}
                  ${noteBtn}
                  ${logBtn}
                  ${emailBtn}

                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      controller.doBadgeCounts()
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
    this.doUploadsCount()
    this.doDocumentsCount()
    this.doProcessCount()
  }

  toggleBtn(ev) {

    this.checkCurrentAgent()

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (this.hasUploadCountTarget && element == this.uploadCountTarget && [`DOCUMENTOS`].includes(title)) {
        btn = element
      } else if (this.hasDocumentCountTarget && element == this.documentCountTarget && [`REVISÃO`].includes(title)) {
        btn = element
      } else if (this.hasProcessCountTarget && element == this.processCountTarget && [`PROCESSAMENTO`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0

    this.application.tickets.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        if (element.stage == `in_process`) {
          ticketCount += 1
        }
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketCount} Ticket(s) em Aberto`
    }
  }

  doUploadsCount() {
    var uploadCount = 0
    var uploadUnpaidCount = 0

    this.application.files.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        uploadCount += 1
      }
    })

    if (this.hasUploadCountTarget) {
      var uploadBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalUploads">${uploadCount}</span>`
      if (this.totalUploadsTargets[0]) {
        this.totalUploadsTargets[0].remove()
      }
      this.uploadCountTarget.insertAdjacentHTML("beforeend", uploadBadgeHtml)
      this.uploadCountTooltipTarget.innerText = `${uploadCount} Arquivo(s) Upload ${this.application.current_date.name}`
    }
  }

  doDocumentsCount() {
    var documentCount = 0
    var documentUnrevisedCount = 0

    this.application.files.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        documentCount += 1
        if (element.status == `not_revised`) {
          documentUnrevisedCount += 1
        }
      }
    })

    if (this.hasDocumentCountTarget) {
      var documentBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalDocuments">${documentUnrevisedCount}</span>`
      if (this.totalDocumentsTargets[0]) {
        this.totalDocumentsTargets[0].remove()
      }
      this.documentCountTarget.insertAdjacentHTML("beforeend", documentBadgeHtml)
      this.documentCountTooltipTarget.innerText = `${documentUnrevisedCount}/${documentCount} Arquivo(s) não Revisado(s) na ${this.application.current_date.name}`
    }
  }

  doProcessCount() {
    var processCount = 0
    var processUnrevisedCount = 0

    this.application.files.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        if (element.status != `not_revised` && element.status != `denied`) {
          processCount += 1
          if (element.status != `processed`) {
            processUnrevisedCount += 1
          }
        }
      }
    })

    if (this.hasProcessCountTarget) {
      var processBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalProcess">${processUnrevisedCount}</span>`
      if (this.totalProcessTargets[0]) {
        this.totalProcessTargets[0].remove()
      }
      this.processCountTarget.insertAdjacentHTML("beforeend", processBadgeHtml)
      this.processCountTooltipTarget.innerText = `${processUnrevisedCount}/${processCount} Arquivo(s) não Processados(s) na ${this.application.current_date.name}`
    }
  }

  checkCurrentAgent() {
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
      if (!this.application.current_agent.account_id) {
        alert(`Favor atualizar o Responsável do IRPF ${this.application.current_date.financial_year} na Aba Configurações`)
      }
    }
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
