import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "save", "filters", "header", "subheader", "squad", "stage", "filterCard",
                    "stageDropdown", "stageDropdownBtn", "stageInput", "stageList", "header", "subheader", "squad", "stageTitle", "9999"]

  connect() {
    this.controllerName = `users--works--trackers--stories--show`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.application.permissions = {}
    this.application.tickets = []
    this.application.story_token = location.pathname.split("/").pop()
    this.sort = `started_at_to_time`
    this.hasFilter = false
    this.filter = ``
    this.storiesDashboard()
  }

  setHeaderPage() {
    this.getControllerByIdentifier("app--navigation--desktop--breadcrumb").breadcrumbNameTarget.innerText = `${this.application.story.name}`
    this.getControllerByIdentifier("app--navigation--desktop--breadcrumb").breadcrumbNestNameTarget.innerText = `Projeto ${this.application.story.project.name}`
    this.getControllerByIdentifier("app--navigation--desktop--breadcrumb").breadcrumbNestLinkTarget.href = this.application.story.project.project_path
    this.headerTarget.innerText = `Entregável ${this.application.story.name}`
    this.subheaderTarget.innerText = `Crie os entregáveis e acompanhe as atividades.`
  }

  storiesDashboard() {
    var html = `<div class="row my-2">
                    <div class="col-2 px-2">
                      <div class="row my-2" data-controller="users--works--trackers--stories--relations">
                        <div class="col-12">
                          <h6 class="mb-0 text-center d-flex" data-users--works--trackers--stories--relations-target="title">${this.loader}</h6>
                          <hr class="mt-1">
                          <div class="row my-2" data-users--works--trackers--stories--relations-target="main"></div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 px-0" data-${this.controllerName}-target="stage">
                          <h6 class="mb-0 text-center d-flex">${this.loader}</h6>
                          <hr class="mt-1">
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 px-0" data-controller="users--works--trackers--units--show" data-users--works--trackers--units--show-target="main">
                          
                        </div>
                      </div>
                    </div>
                    <div class="col-6 px-2">
                      <div class="row my-2" data-controller="users--works--trackers--stories--tickets">
                        <div class="col-12">
                          <h6 class="mb-0 text-center d-flex mx-auto" data-users--works--trackers--stories--tickets-target="title">${this.loader}</h6>
                          <div class="row my-2" data-users--works--trackers--stories--tickets-target="main"></div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 px-2">
                      <div class="row my-2" data-controller="users--works--trackers--stories--notes">
                        <div class="col-12">
                          <h6 class="mb-0 text-center d-flex" data-users--works--trackers--stories--notes-target="title">${this.loader}</h6>
                          <hr class="mt-1">
                          <div class="row my-2" data-users--works--trackers--stories--notes-target="main"></div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  doStageHTML() {

    var html = `<h6 class="mb-0 text-center d-flex" data-${this.controllerName}-target="stageTitle"><span class="mx-auto">Estágios</span></h6>
                <hr class="mt-1">
                <div class="row mb-3 w-100">
                  <div class="col-12 mb-1 px-5">
                    <div class="card pointer" data-${this.controllerName}-target="filterCard" data-stage="icebox" data-stage-pretty="Geladeira" data-action="click->${this.controllerName}#changeStage">
                      <div class="card-body text-center f-075 p-2">
                        <span>Geladeira</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-1 px-5">
                    <div class="card pointer" data-${this.controllerName}-target="filterCard" data-stage="backlog" data-stage-pretty="Para Fazer" data-action="click->${this.controllerName}#changeStage">
                      <div class="card-body text-center f-075 p-2">
                        <span>Para Fazer</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-1 px-5">
                    <div class="card pointer" data-${this.controllerName}-target="filterCard" data-stage="current" data-stage-pretty="Fazendo" data-action="click->${this.controllerName}#changeStage">
                      <div class="card-body text-center f-075 p-2">
                        <span>Fazendo</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mb-1 px-5">
                    <div class="card pointer" data-${this.controllerName}-target="filterCard" data-stage="done" data-stage-pretty="Finalizado" data-action="click->${this.controllerName}#changeStage">
                      <div class="card-body text-center f-075 p-2">
                        <span>Finalizado</span>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.stageTarget.innerHTML = html)
    }).then(() => {
      controller.filterCardTargets.forEach(element => {
        if (element.dataset.stage == controller.application.story.stage) {
          element.classList.add(`bg-primary`)
        }
      })
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  changeStage(ev) {

    if (this.squadCanUpdate()) {
      var stage = ev.currentTarget.dataset.stage
      var stagePretty = ev.currentTarget.dataset.stagePretty
      var check = this.checkStoryDone(stage)

      if ((this.application.story.stage != stage) && check.status) {
        var r = confirm(`Confirma a mudança de Estágio do Entregável`)
        if (r) {
          this.send_data = { current_user: {}, story: {}, note: {} }

          this.send_data.story.id = this.application.story.id
          this.send_data.story.stage = stage

          this.send_data.note.account_id = this.application.current_user.account_id
          this.send_data.note.domain_type = `tracker_stories`
          this.send_data.note.owner_id = this.application.current_user.account_id
          this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
          this.send_data.note.sharing = `internal`
          this.send_data.note.kind = `updating`
          this.send_data.note.date = new Date()
          this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} mudou o estágio do Entregável para ${stagePretty}`
          this.send_data.note.title = `Atualização`

          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = `user_works_trackers_stories`

          this.requestSave()
        }
      } else {
        ev.preventDefault()
        ev.stopPropagation()
        alert(check.message)
      }
    } else {
      alert(`Você não tem autorização para Editar o Estágio.`)
    }
    
  }

  checkStoryDone(stage) {
    var done = false
    var check = {}

    // if (this.stageInputTarget.dataset.stage == `done`) {
    if (stage == `done`) {
      if (this.application.in_process_tickets.length == 0) {
        done = true
      } else {
        check = { message: `Ainda tem atividade em aberto!`, status: false}
      }
      
      if (this.application.story.precedents.length > 0) {
        this.application.story.precedents.forEach(element => {
          if (element.status && done) {
            check = { message: ``, status: true }
          } else {
            check = { message: `Existem Tickets em aberto que ainda não foram concluídos!`, status: false }
          }
        })
      } else {
        check = { message: ``, status: true }
      }
      
    } else {
      check = { message: ``, status: true }
    }

    return check
  }

  requestSave() {
    var url = "/users/works/trackers/stories/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var story = response.data.cln
          controller.application.story = story
          controller.sendEmailNotification(story)
          controller.getControllerByIdentifier(`users--works--trackers--stories--relations`).doRelationsHtml()
          controller.doStageHTML()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendEmailNotification(story) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.project_id = story.project_id
    this.send_data.notification.story_id = story.id
    this.send_data.notification.story_path = story.story_path
    this.send_data.notification.story_name = story.name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.sender_email = this.application.current_user.email
    this.send_data.notification.action = `mudou o status do Entregável ${story.name} para`
    this.send_data.notification.body = story.stage_pretty

    var url = "/users/works/trackers/stories/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  finishLoadPage() {
    this.setHeaderPage()
    this.doStageHTML()

    var currentOwner = false
    this.application.project.squads.forEach(squad => {
      if (squad.role == `owner` && squad.team_id == this.application.current_user.account_id) {
        currentOwner = true
      }
    })
    
    if (currentOwner) {
      this.getControllerByIdentifier(`users--works--trackers--units--show`).doUnitsHtml()
    }
    this.getControllerByIdentifier(`users--works--trackers--stories--tickets`).doTicketsDashboard()
    this.getControllerByIdentifier(`users--works--trackers--stories--notes`).doNotesHeader()
    this.getControllerByIdentifier(`users--works--trackers--stories--notes`).setNotes()
    this.getControllerByIdentifier(`users--works--trackers--stories--relations`).doRelationsHeader()
    this.getControllerByIdentifier(`users--works--trackers--stories--relations`).doRelationsHtml()
    

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 70 })

    var data = { note: { domain_id: this.application.story.id, domain_type: `tracker_stories` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_notes` } }
    const url = "/users/accounts/notes/list_by_domain"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.notes = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 65 })

    var data = { ticket: { board_id: this.application.story.id, board_type: "tracker_stories" }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tickets = response.data.cln
        }

        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getPrecedents() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Relações`, value: 40 })

    var data = { story: { project_id: this.application.story.project_id }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/works/trackers/stories/list_names"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.precedents = response.data.cln

        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getProject() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Projeto`, value: 35 })

    var data = { project: { id: this.application.story.project_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_projects` } }
    const url = "/users/works/trackers/projects/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.project = response.data.cln

        controller.getPrecedents()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getStory() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Entregável`, value: 30 })

    var data = { story: { id: this.application.story_token }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_stories` } }
    const url = "/users/works/trackers/stories/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.story = response.data.cln
        // controller.application.team_list = controller.application.story.project.squads
        
        controller.getProject()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_trackers` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getStory()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  squadCanUpdate() {
    var currentOwner = false
    this.application.project.squads.forEach(squad => {
      if ((squad.role == `owner` || squad.role == `editor`) && squad.team_id == this.application.current_user.account_id) {
        currentOwner = true
      }
    })

    return currentOwner
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
