import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "datesBtnGroup", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--show--dates`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doDatesPreloader()
  }

  changeDate(ev) {
    // if (ev.target.classList.contains("li-selector")) {
    //   var id = ev.target.dataset.id
    //   this.setClinicDate(id)
    // }

    var id = ev.currentTarget.dataset.id
    this.setClinicDate(id)
    this.toggleDateBtn(id)
  }

  setClinicDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        if (this.application.current_date.open == false) {
          this.application.current_date.message = `A Competência está fechada`
        }

        this.application.current_date.has_no_receivables = true
        this.application.receivables.forEach(element => {
          if (element.date_id == this.application.current_date.id) {
            this.application.current_date.has_no_receivables = false
          }
        })
      }
    })

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.getControllerByIdentifier("operations--products--clinics--financials--show--main").reload = true
    this.getControllerByIdentifier("operations--products--clinics--financials--show--main").loadDateData()
    this.getControllerByIdentifier("operations--products--clinics--financials--show--main").doSubtitle()
    this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doBadgeCounts()
  }

  setCurrents() {
    this.application.regimes.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_regime = element
      }
    })

    this.application.main_apurations.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_main_apuration = element
      }
    })
  }

  runDashboardsHtml() {
    this.cleanClinicForms()
    this.runDashboardHtml()
    this.runReceivablesHtml()
    this.runApurationsHtml()
    this.runTaxesHtml()
    this.runTransfersHtml()
    this.runExpensesHtml()
    this.runRegimesHtml()
  }

  cleanClinicForms() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--add--save")) {
      this.getControllerByIdentifier("operations--products--clinics--financials--add--save").goToReceivables()
    }
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--show--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--show--dashboard").doClinicDashboard()
    }
  }

  runReceivablesHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard").doReceivablesDashboard()
    }
  }

  runApurationsHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard").doApurationsDashboard()
    }
  }

  runTaxesHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--taxes--dashboard").doTaxesDashboard()
    }
  }

  runTransfersHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--transfers--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--transfers--dashboard").doTransfersDashboard()
    }
  }

  runExpensesHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--expenses--dashboard").doExpensesDashboard()
    }
  }

  runRegimesHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--regimes--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--regimes--dashboard").doRegimesDashboard()
    }
  }

  doDatesHTML() {
    var datesHtml = ``

    this.application.dates.forEach(element => {
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    });

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  rollDates() {
    // $(this.datesBtnGroupTarget).stop().animate({ scrollRight: $(this.datesBtnGroupTarget)[0].style.width }, 1000);
    $(this.datesBtnGroupTarget).animate( $(this.datesBtnGroupTarget)[0].scrollLeft -=100 , 1000);
    // $(this.datesBtnGroupTarget).stop().animate({ scrollTop: $(this.datesBtnGroupTarget)[0].scrollHeight }, 1000);
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)
        
      }
    });
  }

  doDatesPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
