import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    // this.checkConnection()
  }

  checkConnection() {
    var connection = true
    setInterval(() => {
      connection = navigator.onLine
      if (connection) {
        if (this.getControllerByIdentifier("app--communications--connection--check-modal")) {
          this.getControllerByIdentifier("app--communications--connection--check-modal").close()
        }
      } else {
        if (this.getControllerByIdentifier("app--communications--connection--check-modal") == undefined) {
          this.doModalHtml()
        }
      }
    }, 1000);
  }



  doModalHtml() {
    var html = `<div class="modal fade" data-controller="app--communications--connection--check-modal" data-app--communications--connection--check-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Sem conexão</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-app--communications--connection--check-modal-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">Verifique a sua conexão!!</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="material-icons md-dark md-lg">portable_wifi_off</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

}