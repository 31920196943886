import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "list", "filter", "addons", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--renewals--dashboard`
  }

  doRenewalsDashboard() {
    var html = `<div class="col-12" data-${this.controllerName}-target="main" data-controller="financials--books--clients--renewals--list financials--books--clients--renewals--show"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--clients--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).permission = `financial_client_renewals`
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).gridElement = controller.mainTarget
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).controllerDashboard = controller
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).current_renewals = controller.application.renewals
      controller.getControllerByIdentifier(`financials--books--clients--renewals--list`).doRenewalList()

      controller.getControllerByIdentifier(`financials--books--clients--renewals--show`).permission = `financial_client_renewals`
      controller.getControllerByIdentifier(`financials--books--clients--renewals--show`).gridElement = controller.mainTarget
      // controller.getControllerByIdentifier(`financials--books--clients--renewals--save`).current_client = controller.current_client

    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}