import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formIncomeCard", "formTitle", "formIncomeBody", "sourceDropdown", "sourceInput", "sourceList", "gross", "withheld",
    "date", "social", "cpfCheckbox", "cnpjCheckbox", "grossInput", "withheldInput", "socialInput", "saveBtn", "deleteBtn", "memberCheckbox",
    "dependentDropdown", "dependentInput", "dependentList", "dependentSelect", "incomeMember", "sourceDropdownBtn"]

  connect() {
    this.controllerName = `operations--products--tax-returns--incomes--save`
  }

  kindCheck(ev) {
    this.sourceDropdownTarget.value = ""
    this.sourceDropdownBtnTarget.disabled = false
    this.sourceInputTarget.innerText = ""
    this.sourceListTarget.innerHTML = `<li data-source="" class="li-selector dark">Selecione se a Fonte é CPF ou CNPJ</li>`
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    if (ev.target.dataset.kind == "cpf") {
      this.cpfCheckboxTarget.checked = true
      this.cnpjCheckboxTarget.checked = false
      
      if (this.application.products.has_booking) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Recebimentos de Pacientes devem ser lançados no Livro-Caixa", 3000)
        this.sourceDropdownBtnTarget.disabled = true
      } else {
        this.listPatients()
      }

    } else if (ev.target.dataset.kind == "cnpj") {
      this.cpfCheckboxTarget.checked = false
      this.cnpjCheckboxTarget.checked = true
      this.listSources()
    }
  }

  listPatients() {
    var listHtml = ``
    this.application.patients.forEach(element => {
      listHtml += `<li data-source-id="${element.id}" data-source-number="${element.account_cpf_pretty}" data-source-employment="${element.employment}" data-source-employment-pretty="${element.employment_pretty}" data-source-type="patient" data-chart-group="individual_income" class="li-selector dark">${element.account_name}</li>`
    });
    this.sourceListTarget.innerHTML = listHtml
    this.withheldInputTarget.classList.add("d-none")
    this.socialInputTarget.classList.add("d-none")
  }

  listSources() {
    var listHtml = ``
    this.application.sources.forEach(element => {
      if (element.employment == "partner" || element.employment == "grant") {
        var chartAccount = "exempt_income"
      } else {
        var chartAccount = "legal_income"
      }
      listHtml += `<li data-source-id="${element.id}" data-source-number="${element.company_cnpj_pretty}" data-source-employment="${element.employment}" data-source-employment-pretty="${element.employment_pretty}" data-source-type="source" data-chart-group="${chartAccount}" data-action="click->${this.controllerName}#checkSource" class="li-selector dark">${element.employment_pretty} | ${element.company_name}</li>`
    });
    this.sourceListTarget.innerHTML = listHtml
    this.withheldInputTarget.classList.remove("d-none")
    this.socialInputTarget.classList.remove("d-none")
  }

  checkSource(ev) {
    if (ev.target.dataset.chartGroup == "exempt_income") {
      this.withheldInputTarget.classList.add("d-none")
      this.socialInputTarget.classList.add("d-none")
    } else {
      this.withheldInputTarget.classList.remove("d-none")
      this.socialInputTarget.classList.remove("d-none")
    }

    setTimeout(() => {
      delete this.sourceInputTarget.dataset.action
    }, 500)
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--tax-returns--incomes--index").cleanCheckBox()
    this.getControllerByIdentifier("operations--products--tax-returns--incomes--show").doViewHtml()
  }

  deleteIncome() {
    this.send_data = { current_user: {}, income: {}, notification: {} }
    this.actionMode = `delete`
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    this.saveBtnTarget.classList.add("d-none")
    if (this.hasDeleteBtnTarget) {
      this.deleteBtnTarget.disabled = true
      this.deleteBtnTarget.classList.add("d-none")
    }

    if (this.current_income.token_tree && this.current_income.source_type == `patient`) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Este Rendimento de Paciente deve ser apagado diretamente no Livro-Caixa`, 5000)
    } else {
      this.send_data.income.id = this.current_income.id
      this.send_data.income.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.notification.domain_id = this.application.tax_return.id
      this.send_data.notification.domain_type = "operation_products"
      this.send_data.notification.date_id = this.application.current_date.id
      this.send_data.notification.date_type = "operation_dates"
      this.send_data.notification.kind = `mode_${this.actionMode}`
      this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o rendimento ${this.current_income.source_employment_pretty} da fonte ${this.current_income.source_name_pretty} recebido em ${this.current_income.date_pretty}`
      this.send_data.notification.account_name = this.application.current_user.name
      this.send_data.notification.account_id = this.application.current_user.account_id

      var url = "/operations/products/tax_returns/incomes/destroy"
      var method = "DELETE"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          if (response.save) {
            this.stopRefreshing()
            this.cancelSave()
            var income = response.data.cln
            this.application.incomes.forEach((element, i) => {
              if (element.id == income.id) {
                this.application.incomes.splice(this.application.incomes.indexOf(element), 1)
              }
            })
            this.getControllerByIdentifier("operations--products--tax-returns--incomes--index").doDataTable()
          } else {
            this.saveBtnTarget.disabled = false
            if (this.hasDeleteBtnTarget) {
              this.deleteBtnTarget.disabled = true
            }
          }
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
        })
        .catch(error => {
          this.getControllerByIdentifier("app--helpers--console").console(error)
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })      
    }

  }

  saveIncome() {
    this.send_data = { current_user: {}, income: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    if (this.actionMode == `edit`) { this.send_data.income.id = this.current_income.id }
    this.send_data.income.tax_return_id = this.application.tax_return.id
    this.send_data.income.source_id = this.sourceInputTarget.dataset.sourceid,
    this.send_data.income.source_type = this.sourceInputTarget.dataset.sourcetype
    this.send_data.income.source_employment = this.sourceInputTarget.dataset.sourceemployment
    this.send_data.income.date_id = this.application.current_date.id
    this.send_data.income.member_id = this.dependentInputTarget.dataset.dependentid
    this.send_data.income.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateTarget.value)
    this.send_data.income.gross = fromCurrencyToNumber(this.grossTarget.value)
    this.send_data.income.withheld = fromCurrencyToNumber(this.withheldTarget.value)
    this.send_data.income.social = fromCurrencyToNumber(this.socialTarget.value)
    this.send_data.income.chart_group = this.sourceInputTarget.dataset.chartgroup

    this.send_data.notification.domain_id = this.application.tax_return.id
    this.send_data.notification.domain_type = "operation_products"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "operation_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% o rendimento ${this.sourceInputTarget.dataset.sourceemploymentpretty} da fonte ${this.sourceInputTarget.dataset.sourcenumber} recebido em ${this.dateTarget.value}`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    if (this.current_income && this.current_income.token_tree) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Este Rendimento de Paciente deve ser atualizado diretamente no Livro-Caixa`, 5000)
    } else {
      this.requestSave()
    }
  }

  requestSave() {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    this.saveBtnTarget.classList.add("d-none")
    if (this.hasDeleteBtnTarget) {
      this.deleteBtnTarget.disabled = true
      this.deleteBtnTarget.classList.add("d-none")
    }
    
    const token = $('meta[name=csrf-token]').attr('content');
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/incomes/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/incomes/create"
      var method = "POST"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var income = response.data.cln
          this.cancelSave()
          if (this.actionMode == "edit") {
            if (income.active) {
              this.application.incomes.forEach((element, i) => {
                if (element.id == income.id) {
                  this.application.incomes.splice(this.application.incomes.indexOf(element), 1, income)
                }
              })
            } else {
              this.application.incomes.forEach((element, i) => {
                if (element.id == income.id) {
                  this.application.incomes.splice(this.application.incomes.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.incomes[this.application.incomes.length] = income
          }
          this.getControllerByIdentifier("operations--products--tax-returns--incomes--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
          this.refreshSaveBtn()
          if (this.hasDeleteBtnTarget) {
            this.deleteBtnTarget.disabled = false
            this.deleteBtnTarget.classList.remove("d-none")
          }
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        if (this.hasDeleteBtnTarget) {
          this.deleteBtnTarget.disabled = true
          this.deleteBtnTarget.classList.add("d-none")
        }
        this.refreshSaveBtn()
        this.getControllerByIdentifier("app--helpers--console").console(error)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    if (this.application.medreturn_incomes.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveIncome" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.medreturn_incomes.can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#deleteIncome" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formIncomeCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="formIncomeBody">
                    <div class="row my-3 d-flex align-items-center">
                      <div class="col-6 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                          <input type="checkbox" class="custom-control-input" id="incomeCpfCheck" data-kind="cpf" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label" for="incomeCpfCheck">CPF</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0">
                          <input type="checkbox" class="custom-control-input" id="incomeCnpjCheck" data-kind="cnpj" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label" for="incomeCnpjCheck">CNPJ</label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="dateIncomeForm">Data Recebimento</label>
                            <input aria-describedby="dateIncomeFormHelp" class="form-control form-valid-control" id="dateIncomeForm" data-${this.controllerName}-target="date" placeholder="Data Recebimento" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Fonte Pagadora</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="sourceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="sourceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sourceList">
                                  <li data-source="" class="li-selector dark">Selecione se a Fonte é CPF ou CNPJ</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-4 pr-2">
                        <div class="form-group" data-${this.controllerName}-target="grossInput">
                          <div class="floating-label floating-label-sm">
                            <label for="grossForm">Valor Bruto</label>
                            <input aria-describedby="grossFormHelp" class="form-control form-valid-control" id="grossForm" data-${this.controllerName}-target="gross" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Bruto" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group" data-${this.controllerName}-target="withheldInput">
                          <div class="floating-label floating-label-sm">
                            <label for="withheldForm">Imposto Retido</label>
                            <input aria-describedby="withheldFormHelp" class="form-control form-valid-control" id="withheldForm" data-${this.controllerName}-target="withheld" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#formErrorsMessage" placeholder="Imposto Retido" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2">
                        <div class="form-group" data-${this.controllerName}-target="socialInput">
                          <div class="floating-label floating-label-sm">
                            <label for="socialForm">Previdência</label>
                            <input aria-describedby="socialFormHelp" class="form-control form-valid-control" id="socialForm" data-${this.controllerName}-target="social" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask keyup->${this.controllerName}#formErrorsMessage" placeholder="Previdência Retida" type="tel" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3 d-flex align-items-center d-none" data-${this.controllerName}-target="incomeMember">
                      <div class="col-4 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0">
                          <input type="checkbox" class="custom-control-input" id="incomeMemberCheck" data-${this.controllerName}-target="memberCheckbox" data-action="click->${this.controllerName}#showDependents">
                          <label class="custom-control-label" for="incomeMemberCheck">Titular</label>
                        </div>
                      </div>
                      <div class="col-8 d-none" data-${this.controllerName}-target="dependentSelect">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Dependente</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="dependentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="dependentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dependentList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--incomes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      // controller.formIncomeCardTarget.style.height = ($(window).height() * 0.5) + "px"
      pickDateLimit($('#dateIncomeForm'), "yearly", controller.application.current_date.year);
      controller.listDependents()
      if (controller.actionMode == "new") {
        controller.formTitleTarget.innerText = "Novo Rendimento"
        controller.grossTarget.value = "R$ 0,00"
        controller.withheldTarget.value = "R$ 0,00"
        controller.socialTarget.value = "R$ 0,00"
      } else if (controller.actionMode == "edit") {
        controller.formTitleTarget.innerText = "Editando Rendimento"
        controller.dateTarget.value = controller.current_income.date_pretty
        controller.sourceInputTarget.dataset.sourceid = controller.current_income.source_id
        controller.sourceInputTarget.dataset.sourcetype = controller.current_income.source_type
        controller.sourceInputTarget.dataset.sourceemployment = controller.current_income.source_employment
        controller.sourceInputTarget.dataset.sourceemploymentpretty = controller.current_income.source_employment_pretty
        controller.sourceInputTarget.dataset.sourcenumber = controller.current_income.source_number_pretty
        controller.sourceInputTarget.dataset.chartgroup = controller.current_income.chart_group
        controller.sourceDropdownTarget.value = controller.current_income.source_name
        controller.sourceInputTarget.innerText = controller.current_income.source_name
        controller.grossTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_income.gross * 100))
        if (controller.current_income.source_type == "patient") {
          controller.cpfCheckboxTarget.checked = true
          controller.withheldInputTarget.classList.add("d-none")
          controller.socialInputTarget.classList.add("d-none")
        } else if (controller.current_income.source_type == "source") {
          controller.cnpjCheckboxTarget.checked = true
          controller.withheldTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_income.withheld * 100))
          controller.socialTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_income.social * 100))
          controller.withheldInputTarget.classList.remove("d-none")
          controller.socialInputTarget.classList.remove("d-none")
        }
        if (controller.current_income.member_kind == "dependent") {
          controller.dependentInputTarget.dataset.dependentid = controller.current_income.member_id
          controller.dependentDropdownTarget.value = controller.current_income.member_name
          controller.dependentInputTarget.innerText = controller.current_income.member_name
          controller.dependentSelectTarget.classList.remove("d-none")
          controller.memberCheckboxTarget.checked = false
        }
      }
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      selector()
    })
  }
  

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.dateTarget.value == "") {
        len += 1
      } 
      if (controller.sourceDropdownTarget.value == "" || controller.sourceDropdownTarget.value == undefined) {
        len += 1
      }
      if (fromCurrencyToNumber(controller.grossTarget.value) == 0) {
        len += 1
      }
      if (controller.sourceInputTarget.innerText == "Selecione se a Fonte é CPF ou CNPJ") {
        len += 1
      }
      if (fromCurrencyToNumber(controller.grossTarget.value) < fromCurrencyToNumber(controller.withheldTarget.value)) {
        len += 1
      }
      if (fromCurrencyToNumber(controller.grossTarget.value) < fromCurrencyToNumber(controller.socialTarget.value)) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  formErrorsMessage() {
    if (fromCurrencyToNumber(this.grossTarget.value) < fromCurrencyToNumber(this.withheldTarget.value)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Valor de Retenção maior que Valor Bruto", 2000)
    }
    if (fromCurrencyToNumber(this.grossTarget.value) < fromCurrencyToNumber(this.socialTarget.value)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Valor de Contribuição maior que Valor Bruto", 2000)
    }
  }

  listDependents() {
    this.current_dependent_members = []
    this.current_members = []

    this.application.members.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        if (element.kind == "dependent") {
          this.current_dependent_members[this.current_dependent_members.length] = element
        } else {
          this.member = element
        }
        this.current_members[this.current_members.length] = element
      }
    });

    if (this.current_dependent_members.length > 0) {
      var listHtml = ``
      this.current_dependent_members.forEach(element => {
        listHtml += `<li data-dependent-id="${element.id}" class="li-selector dark">${element.account_name}</li>`
      })
      this.dependentListTarget.innerHTML = listHtml
      this.dependentInputTarget.dataset.dependentid = this.member.id
      this.memberCheckboxTarget.checked = true
      this.incomeMemberTarget.classList.remove("d-none")
    } else {
      this.dependentInputTarget.dataset.dependentid = this.application.members[0].id
      this.incomeMemberTarget.classList.add("d-none")
    }    
  }

  showDependents(ev) {
    if (ev.target.checked) {
      this.dependentSelectTarget.classList.add("d-none")
      this.dependentInputTarget.dataset.dependentid = this.member.id
    } else {
      this.dependentSelectTarget.classList.remove("d-none")
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["formIncomeCard"]
    var identifier = "operations--products--tax-returns--incomes--save"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
