import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.doPageGrid()
  }

  doPageGrid() {
    var html = `<div class="row my-4">
                  <div class="col-8 px-2" data-controller="commercial--sales--leads--entities--list" data-${this.controllerName}-target="list">
                    ${this.cardLoader}
                  </div>
                  <div class="col-4 px-2" data-controller="commercial--sales--leads--entities--show
                                                           commercial--sales--leads--entities--save
                                                           commercial--sales--leads--entities--search
                                                           commercial--sales--opportunities--entities--save
                                                           commercial--sales--opportunities--radars--save" data-${this.controllerName}-target="view">
                    ${this.loader}
                  </div>
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`commercial--sales--leads--entities--save`).gridElement = controller.viewTarget
      controller.getControllerByIdentifier(`commercial--sales--leads--entities--save`).permission = `sales_qualified_leads`

      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).permission = `sales_qualified_leads`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).gridElement = controller.viewTarget
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save`).controllerDashboard = controller

      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).gridElement = controller.viewTarget
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).permission = `sales_qualified_leads`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--radars--save`).controllerDashboard = controller

      controller.getCurrentUserPermissions()
      
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)

    this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).doIndexListHtml()
    this.getControllerByIdentifier(`commercial--sales--leads--entities--submenu`).doSubmenuHtml()
  }

  getLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Leads`, value: 50 })

    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.leads = response.data.cln

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCommercialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.dates = response.data.cln
        controller.application.current_date = response.data.cln[0]
        controller.getLeads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const featureNames = ["sales_opportunities_entities", "sales_opportunities_leads", "sales_qualified_lead_permissions"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })
        // controller.application.domain_permission = controller.application.permissions[`account_entity_permissions`]

        controller.getCommercialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
