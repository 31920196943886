import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--dashboard--main`
    this.application.request_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.requestUpdateChannel = createChannel({ channel: 'App::Trackers::RequestChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "request") {
          var request = data.cln
          
          var controllerResponses = controller.getControllerByIdentifier(`app--stats--builders--responses`)
          controllerResponses.responses[controllerResponses.responses.length] = request
          controllerResponses.doResponsesCalculations()

        } else if (data.kind == "calculation") {

        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  updateListen() {
    if (this.requestUpdateChannel) {
      this.requestUpdateChannel.perform('follow', { id: this.update_room.id })
      var response = { type: `success`, message: `Conexão com Sucesso!` }
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
