import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu",
                    "content", "onboardPartyName", "indicators", "4444", "5555", "6666", "7777", "8888", "9999",
                    "medclinicCard", "medbookingCard", "medfilingCard", "medpayrollCard", "medreceivementCard", "medbilingCard",
                    "medclinicHeaderCard", "medbookingHeaderCard", "medfilingHeaderCard", "medpayrollHeaderCard", "medreceivementHeaderCard", "medbilingHeaderCard"]

  connect() {
    this.controllerName = `operations--onboards--dashboard--index`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.tableRow = 11
    this.sort = { mode: `desc`, field: `started_at_to_time` }
    this.hasFilter = false
    this.filter = ``
    this.doPageGrid()
  }

  doPageGrid() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var sidebarHtml = `<div class="col-2" data-${this.controllerName}-target="sideBarReport">
                        <div class="row">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row my-4">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row my-4">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                        <div class="row my-4">
                          <div class="col-12 px-0">
                            ${this.cardLoader}
                          </div>
                        </div>
                      </div>`

    var html = `<div class="row" data-${this.controllerName}-target="indicators">
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                  <div class="col-2 pl-2 pr-2">${this.cardLoader}</div>
                </div>
                <div class="row my-3">
                  <div class="col-12 pl-2 pr-2" data-${this.controllerName}-target="list">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--onboards--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      if (controller.application.onboards) {
        controller.doIndexListHtml()
        controller.doCalculation()
      }
    })
  }

  doIndexListHtml() {
    var refreshLine = `<span data-action="click->${this.controllerName}#allOnboards" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar os Onboards</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${refreshLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-0 f-065">
                    <h6 class="card-title display-4 py-0 mb-0 f-085">Todos os Onboards</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Data Início
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="started_at_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">
                                  Partes Envolvidas
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Tipo
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="kind_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle text-center">
                                  Produto
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Serviço
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Canal
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="channel_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Jornada
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Status
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">
                                  Próximo Ticket
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none pointer" data-action="click->${this.controllerName}#sortTable" data-field="next_ticket_to_time" data-mode="asc">north</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableRow, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_onboards = []
    if (this.hasFilter) {
      this.application.onboards.forEach(element => {
        if (element.product_name == this.filter) {
          current_onboards[current_onboards.length] = element
        }
      })
    } else {
      current_onboards = this.application.onboards
    }

    if (this.sort.mode == `asc`) {
      var onboards = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_onboards, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var onboards = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_onboards, this.sort.field)
    }

    if (onboards.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(onboards.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (onboards[x] !== undefined) {
            internPage[internPage.length] = onboards[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: onboards.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableRow}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Onboards em Andamento</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.onboardTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  onboardTablePartial(element) {
    var notificationsCount = 0

    element.name = ``
    element.parties.forEach(party => {
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="onboardPartyName">${party.party_name}</p>`
    })

    this.application.notifications.forEach(notification => {
      if (notification.domain_id == element.id) {
        notificationsCount++
      }
    });
    if (this.application.notifications.length > 0) {

    }

    if (this.application.permissions.operation_onboard_entities.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editOnboard" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    edit = ``

    if (element.blocked) {
      var statusBlocked = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-success pointer">wifi</span>
                            <span class="mc-tooltiptext">Online</span>
                          </span>`
    } else {
      var statusBlocked = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-success pointer">wifi</span>
                            <span class="mc-tooltiptext">Online</span>
                          </span>`
    }

    if (element.next_ticket.has_ticket) {
      if (element.next_ticket.delay) {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-danger">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      } else {
        var nextTicket = `<span class="mb-0 f-065 mc-tooltip pointer">
                          <span class="badge badge-secondary-dark">${element.next_ticket.ticket.due_at_pretty}</span>
                          <span class="mc-tooltiptext mc-tooltiptext">${element.next_ticket.ticket.body}</span>
                        </span>`
      }
    } else {
      var nextTicket = `Não há Próximo Ticket`
    }

    var onboardPath = `<button data-action="click->${this.controllerName}#goToShowPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Ir para Onboard</span>
                        </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.id}" data-slug="${element.slug}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ir para Onboard</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.kind_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_full_name_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_service_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.channel_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.journey_status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${nextTicket}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToShowPage(ev) {
    var token = ev.target.closest(".itemRow").dataset.slug
    window.open(`/a/onboards/${token}`, `_blank`)
  }

  doIndicatorsHtml() {
    var html = `<div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medclinic" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medclinicCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medclinicHeaderCard">
                      <span>PJ Médica</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medclinic.opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Pós-Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medclinic.pos_opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Migração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medclinic.migration}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Alteração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medclinic.amendment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medbooking" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medbookingCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medbookingHeaderCard">
                      <span>Livro-Caixa</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medbooking.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medfiling" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medfilingCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medfilingHeaderCard">
                      <span>Imposto de Renda</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Light
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medfiling.light}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Pro
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medfiling.pro}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Premium
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medfiling.premium}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medpayroll" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medpayrollCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medpayrollHeaderCard">
                      <span>Funcionários</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Registro
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medpayroll.opening}
                        </div>
                      </div>
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Migração
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medpayroll.migration}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medreceivement" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medreceivementCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medreceivementHeaderCard">
                      <span>Gestão Recebimentos</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medreceivement.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="medbiling" data-action="click->${this.controllerName}#productFilter" data-${this.controllerName}-target="medbilingCard">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="medbilingHeaderCard">
                      <span>Faturamento</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1" style="overflow:auto;">
                      <div class="row d-flex align-items-center">
                        <div class="col-7 text-right px-1">
                          Abertura
                        </div>
                        <div class="col-4 offset-1 text-left f-075 px-1">
                          ${this.calculation.medbiling.opening}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    // this.indicatorsTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorsTarget.innerHTML = html)
    }).then(() => {
      controller.medclinicCardTarget.style.height = ($(window).height() * 0.15) + "px"
      controller.medbookingCardTarget.style.height = ($(window).height() * 0.15) + "px"
      controller.medfilingCardTarget.style.height = ($(window).height() * 0.15) + "px"
      controller.medpayrollCardTarget.style.height = ($(window).height() * 0.15) + "px"
      controller.medreceivementCardTarget.style.height = ($(window).height() * 0.15) + "px"
      controller.medbilingCardTarget.style.height = ($(window).height() * 0.15) + "px"
    })
  }

  productFilter(ev) {
    var product = ev.currentTarget.dataset.product
    this.filter = product
    this.hasFilter = true
    this.pageNumber = 0
    this.doDataTable()

    if (product == `medclinic`) {
      this.medclinicHeaderCardTarget.classList.add("bg-primary")
      this.medbookingHeaderCardTarget.classList.remove("bg-primary")
      this.medfilingHeaderCardTarget.classList.remove("bg-primary")
      this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
      this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
      this.medbilingHeaderCardTarget.classList.remove("bg-primary")
    } else if (product == `medbooking`) {
      this.medclinicHeaderCardTarget.classList.remove("bg-primary")
      this.medbookingHeaderCardTarget.classList.add("bg-primary")
      this.medfilingHeaderCardTarget.classList.remove("bg-primary")
      this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
      this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
      this.medbilingHeaderCardTarget.classList.remove("bg-primary")
    } else if (product == `medfiling`) {
      this.medclinicHeaderCardTarget.classList.remove("bg-primary")
      this.medbookingHeaderCardTarget.classList.remove("bg-primary")
      this.medfilingHeaderCardTarget.classList.add("bg-primary")
      this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
      this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
      this.medbilingHeaderCardTarget.classList.remove("bg-primary")
    } else if (product == `medpayroll`) {
      this.medclinicHeaderCardTarget.classList.remove("bg-primary")
      this.medbookingHeaderCardTarget.classList.remove("bg-primary")
      this.medfilingHeaderCardTarget.classList.remove("bg-primary")
      this.medpayrollHeaderCardTarget.classList.add("bg-primary")
      this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
      this.medbilingHeaderCardTarget.classList.remove("bg-primary")
    } else if (product == `medreceivement`) {
      this.medclinicHeaderCardTarget.classList.remove("bg-primary")
      this.medbookingHeaderCardTarget.classList.remove("bg-primary")
      this.medfilingHeaderCardTarget.classList.remove("bg-primary")
      this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
      this.medreceivementHeaderCardTarget.classList.add("bg-primary")
      this.medbilingHeaderCardTarget.classList.remove("bg-primary")
    } else if (product == `medbiling`) {
      this.medclinicHeaderCardTarget.classList.remove("bg-primary")
      this.medbookingHeaderCardTarget.classList.remove("bg-primary")
      this.medfilingHeaderCardTarget.classList.remove("bg-primary")
      this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
      this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
      this.medbilingHeaderCardTarget.classList.add("bg-primary")
    }
  }

  allOnboards() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Atualizando Onboards` })
    this.hasFilter = false
    this.filter = ``
    this.getControllerByIdentifier(`operations--onboards--dashboard--main`).reload = true
    this.getControllerByIdentifier(`operations--onboards--dashboard--main`).getOnboards()

    // this.medclinicHeaderCardTarget.classList.remove("bg-primary")
    // this.medbookingHeaderCardTarget.classList.remove("bg-primary")
    // this.medfilingHeaderCardTarget.classList.remove("bg-primary")
    // this.medpayrollHeaderCardTarget.classList.remove("bg-primary")
    // this.medreceivementHeaderCardTarget.classList.remove("bg-primary")
    // this.medbilingHeaderCardTarget.classList.remove("bg-primary")
  }

  doCalculation() {
    this.calculation = { medclinic: {}, medbooking: {}, medfiling: {}, medpayroll: {}, medreceivement: {}, medbiling: {} }
    

    this.calculation.medclinic.opening = 0
    this.calculation.medclinic.pos_opening = 0
    this.calculation.medclinic.migration = 0
    this.calculation.medclinic.amendment = 0
    this.calculation.medclinic.separate = 0

    this.calculation.medbooking.opening = 0
    
    this.calculation.medfiling.light = 0
    this.calculation.medfiling.pro = 0
    this.calculation.medfiling.premium = 0
    
    this.calculation.medpayroll.opening = 0
    this.calculation.medpayroll.migration = 0
    
    this.calculation.medreceivement.opening = 0

    this.calculation.medbiling.opening = 0
    
    this.application.onboards.forEach(onboard => {
      if (onboard.product_name == `medclinic`) {
        if (onboard.product_service == `opening`) {
          this.calculation.medclinic.opening += 1
        } else if (onboard.product_service == `pos_opening`) {
          this.calculation.medclinic.pos_opening += 1
        } else if (onboard.product_service == `migration`) {
          this.calculation.medclinic.migration += 1
        } else if (onboard.product_service == `amendment`) {
          this.calculation.medclinic.amendment += 1
        } else if (onboard.product_service == `separate`) {
          this.calculation.medclinic.separate += 1
        }
      } else if (onboard.product_name == `medbooking`) {
        if (onboard.product_service == `opening`) {
          this.calculation.medbooking.opening += 1
        }
      } else if (onboard.product_name == `medfiling`) {
        // if (onboard.product_service == `opening`) {
        //   this.calculation.medfiling.opening += 1
        // } else if (onboard.product_service == `separate`) {
        //   this.calculation.medfiling.separate += 1
        // }
        if (onboard.product_plan == `light`) {
          this.calculation.medfiling.light += 1
        } else if (onboard.product_plan == `pro`) {
          this.calculation.medfiling.pro += 1
        } else if (onboard.product_plan == `premium`) {
          this.calculation.medfiling.premium += 1
        }
      } else if (onboard.product_name == `medpayroll`) {
        if (onboard.product_service == `opening`) {
          this.calculation.medpayroll.opening += 1
        } else if (onboard.product_service == `migration`) {
          this.calculation.medpayroll.migration += 1
        }
      } else if (onboard.product_name == `medreceivement`) {
        if (onboard.product_service == `opening`) {
          this.calculation.medreceivement.opening += 1
        }
      } else if (onboard.product_name == `medbiling`) {
        if (onboard.product_service == `opening`) {
          this.calculation.medbiling.opening += 1
        }
      }
    });

    this.doIndicatorsHtml()
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    // var targets = ["mainCard", "medclinicCard", "medbookingCard", "medfilingCard", "medpayrollCard", "medreceivementCard", "medbilingCard",]
    var targets = ["mainCard"]
    var identifier = "operations--onboards--dashboard--index"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
