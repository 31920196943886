import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--emails--send-tax`

    console.log(`send tax mail`)

    this.mainTarget.innerText = `List which tax to send`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}