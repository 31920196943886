import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["action", "labelPdfUpload", "filePdfName", "labelXmlUpload", "fileXmlName", "xxxx", "xxxx",
                    "fileUpload", "progressUpload", "progressUploadBar", "progressUploadCounter", "fileXmlName",
                    "cancelInvoice", "invoiceNumberInput", "invoiceNumberYearInput", "xxxx"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--receivables--action`
    this.spaceRow = `<td colspan="5" style="height:0rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    this.upload_files = []
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "xml", ".xml", ".XML", "PDF", "PNG", "JPEG"]
    this.uploadReady = false
    if (this.getControllerByIdentifier("operations--products--clinics--financials--add--show")) {
      this.current_receivable = this.getControllerByIdentifier("operations--products--clinics--financials--add--show").current_receivable
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show")) {
      this.current_receivable = this.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").current_receivable
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--show")) {
      this.current_receivable = this.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").current_receivable
    }

    this.snackbarController = this.getControllerByIdentifier("app--helpers--snackbar")
  }

  doShowHtml() {
    if (this.getControllerByIdentifier("operations--products--clinics--financials--add--show")) {
      this.getControllerByIdentifier("operations--products--clinics--financials--add--show").doRevisionFormHtml()
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--show")) {
      this.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").doRevisionFormHtml()
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show")) {
      this.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").doRevisionFormHtml()
    }
  }

  transmitInvoice() {
    var r = confirm(`Tem certeza que deseja Emitir a Nota Fiscal?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.doWatingBoardHtml()
      this.send_data = { invoice: {}, current_user: {}, tracker: { log: true } }

      this.send_data.invoice.receivable_id = this.current_receivable.id

      this.send_data.tracker.action = `transmited`
      this.send_data.tracker.record_id = this.current_receivable.clinic_id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.current_receivable.date_id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.observations = `Nota Fiscal da ${this.current_receivable.provider_name} para ${this.current_receivable.taker_name} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_receivable.total_amount)}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var url = "/operations/products/clinics/integrations/accountings/invoices/transmit_invoice"
      var method = "POST"
      var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
      this.doFetchRequest(url, init)
    }
  }

  checkCanCancel() {
    if (this.getControllerByIdentifier(`operations--products--clinics--financials--index--receivables--dashboard`)) {
      this.snackbarController.doSnackbar(`danger`, `Favor entrar na página da PJ para cancelar a Nota Fiscal!`, 5000)
    } else {
      // if (this.current_receivable.date_id == this.application.main_current_date.id) {
      //   this.doCancelModalHtml()
      // } else {
      //   this.snackbarController.doSnackbar(`danger`, `Nota Fiscal de competências anteriores não podem ser canceladas!`, 5000)
      // }

      if (this.application.current_date.open) {
        this.doCancelModalHtml()
      } else {
        this.snackbarController.doSnackbar(`danger`, `A Competência está fechada para Edição!`, 5000)
      }
    }
  }

  doCancelModalHtml() {
    var controllerName = `operations--products--clinics--financials--receivables--cancel`

    var html = `<div class="modal fade" data-controller="${controllerName}" data-${controllerName}-target="modal" tabindex="-1" role="dialog" aria-labelledby="closingModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <h6 class="modal-title py-2 mx-auto"><strong class="f-075">Selecione o Motivo do Cancelamento</strong></h6><br>
                      </div>
                      <div class="modal-body p-3" data-${controllerName}-target="body">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-12 px-2 d-flex align-items-center">
                            <div class="form-group w-100 mb-1">
                              <div class="floating-label floating-label-sm">
                                <label>Motivo Cancelamento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${controllerName}-target="reasonDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${controllerName}-target="reasonDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${controllerName}-target="reasonInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${controllerName}-target="reasonList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center d-none" data-${controllerName}-target="reasonDescriptionDiv">
                          <div class="col-12 px-2 d-flex align-items-center">
                            <div class="form-group w-100 mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="reasonDescriptionForm">Descrição</label>
                                <input id="reasonDescriptionForm" class="form-control f-075 pt-0" data-${controllerName}-target="reasonDescription" type="text" placeholder="Descrição" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top">
                        <button type="button" class="btn btn-secondary btn-sm btn-table f-065 p-1" data-action="click->${controllerName}#closeModal">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm btn-table f-065 p-1" data-${controllerName}-target="saveBtn" data-action="click->${controllerName}#confirmCancelInvoice">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerName).current_receivable = controller.current_receivable
      controller.getControllerByIdentifier(controllerName).permission = controller.permission
    })
  }

  refreshInvoice(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.doWatingBoardHtml()
    this.send_data = { invoice: {}, current_user: {}, tracker: { log: true } }

    this.send_data.invoice.id = ev.currentTarget.dataset.id

    this.send_data.tracker.action = `refreshed`
    this.send_data.tracker.record_id = this.current_receivable.clinic_id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.current_receivable.date_id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.observations = `Nota Fiscal da ${this.current_receivable.provider_name} para ${this.current_receivable.taker_name} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_receivable.total_amount)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/integrations/accountings/invoices/refresh_invoice"
    var method = "POST"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    this.doFetchRequest(url, init)
  }

  doFetchRequest(url, init) {
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receivable = response.data.cln
          controller.current_receivable = receivable

          if (controller.getControllerByIdentifier("operations--products--clinics--financials--add--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").current_receivable = controller.current_receivable
          } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").current_receivable = controller.current_receivable
          } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").current_receivable = controller.current_receivable
          }

          if (receivable.active) {
            controller.application.all_receivables.forEach((element, i) => {
              if (element.id == receivable.id) {
                controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1, receivable)
              }
            })
          } else {
            controller.application.all_receivables.forEach((element, i) => {
              if (element.id == receivable.id) {
                controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1)
              }
            })
          }
        }
        controller.doShowHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doInvoiceUpload() {
    if (this.upload_files.length > 0) {
    // if (this.upload_files.length == 2) {
      this.progressCount(0)
      this.uploadFilesCount = 0
      this.requestUpload(this.current_receivable.main_invoice.id, this.upload_files[0])
    } else {
      this.snackbarController.doSnackbar(`danger`, `Favor adicionar ou o arquivo PDF ou o arquivo XML da Nota Fiscal.`, 5000)
    }
  }

  requestUpload(invoice_id, element) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    
    var formData = new FormData()
    formData.append('file', element.file)
    formData.append('field', element.field)
    if (this.hasInvoiceNumberYearInputTarget && element.field == `pdf`) {
      formData.append('invoice_number', `${this.invoiceNumberYearInputTarget.value}${this.invoiceNumberInputTarget.value.trim().padStart(6, `0`)}`)
    }
    formData.append('id', invoice_id)
    formData.append('current_user_id', this.application.current_user.id)
    formData.append('feature', this.permission)

    formData.append('tracker_log', true)
    formData.append('tracker_action', `uploaded`)
    formData.append('tracker_record_id', this.current_receivable.clinic_id)
    formData.append('tracker_record_type', `operation_products`)
    formData.append('tracker_observations', `${element.field_pretty} da ${this.current_receivable.provider_name} para ${this.current_receivable.taker_name} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_receivable.total_amount)}`)

    var url = "/operations/products/clinics/integrations/accountings/invoices/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token }, body: formData }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receivable = response.data.cln
          controller.current_receivable = receivable

          if (controller.getControllerByIdentifier("operations--products--clinics--financials--add--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--financials--add--show").current_receivable = controller.current_receivable
          } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--show").current_receivable = controller.current_receivable
          } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show")) {
            controller.getControllerByIdentifier("operations--products--clinics--clients--receivables--show").current_receivable = controller.current_receivable
          }

          controller.application.all_receivables.forEach((element, i) => {
            if (element.id == receivable.id) {
              controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1, receivable)
            }
          })
          controller.uploadFilesCount += 1
          if (controller.uploadFilesCount == controller.upload_files.length) {
            controller.stopRefreshing()
            controller.progressCount(100)
            controller.doShowHtml()
          } else {
            controller.requestUpload(controller.current_receivable.main_invoice.id, controller.upload_files[controller.uploadFilesCount])
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doRevisionFormHtml() {

    // hourglass_empty
    // hourglass_bottom
    // hourglass_disabled
    // <span class="material-icons-two-tone">
    // hourglass_bottom
    // </span>
    // running_with_errors
    // warning_amber
    // sync
    // not_interested
    // do_disturb
    // do_disturb_off
    // receipt
    // receipt_long
    // cancel_presentation
    // check_circle_outline
    // forward_to_inbox



    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.actionTarget.innerHTML = html)
    // }).then(() => {
    //   controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    // })
  }

  doActionHtml() {
    var tryAgain = `<div class="row mb-3">
                      <div class="col-12 px-0">
                        <span class="mc-tooltip pointer">
                          <span class="material-icons-two-tone md-dark md-300" data-action="click->${this.controllerName}#transmitInvoice" data-${this.controllerName}-target="transmitInvoice">redo</span>
                          <span class="mc-tooltiptext">Tentar emitir novamente</span>
                        </span>
                      </div>
                    </div>`
    var html = ``
    // <span class="material-icons-two-tone md-dark md-300" data-id="${this.current_receivable.invoices[0].id}" data-action="click->${this.controllerName}#refreshInvoice">refresh</span>

    // `<div class="row mb-3">
    //   <div class="col-12 px-0 text-center">
    //     <span class="f-075 text-bold">Tomador</span>
    //     <span class="mc-tooltip pointer">
    //       <span class="material-icons-two-tone md-dark md-300" data-action="click->${this.controllerName}#sendEmail" data-kind="taker">send</span>
    //       <span class="mc-tooltiptext">Enviar Email para o Tomador</span>
    //     </span>
    //   </div>
    // </div>
    // <div class="row mb-3">
    //   <div class="col-12 px-0 text-center">
    //     <span class="f-075 text-bold">Médico(s)</span>
    //     <span class="mc-tooltip pointer">
    //       <span class="material-icons-two-tone md-dark md-300" data-action="click->${this.controllerName}#sendEmail" data-kind="performer">send</span>
    //       <span class="mc-tooltiptext">Enviar Email para o Médico</span>
    //     </span>
    //   </div>
    // </div>`
    var updateInvoiceBtn = `<div class="row mb-3">
                              <div class="col-12 px-0">
                                <span class="mc-tooltip pointer">
                                  <span class="material-icons-two-tone md-dark md-300" data-id="${this.current_receivable.main_invoice.id}" data-action="click->${this.controllerName}#refreshInvoice">refresh</span>
                                  <span class="mc-tooltiptext">Atualizar Dados da Nota Fiscal</span>
                                </span>
                              </div>
                            </div>`

    if (this.current_receivable.invoice_status == `canceled_invoice`) {
      
    } else {
      
    }

    var uploadBtnHtml = ``
    if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)) {
      uploadBtnHtml += ``
    } else {
      uploadBtnHtml += `<div class="row mb-3">
                          <div class="col-12 px-0">
                            <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="${this.controllerName}#invoiceUploadHtml">Upload NF</button>
                          </div>
                        </div>`
    }

    if (this.current_receivable.invoices.length == 0) {
      html += `<div class="row mb-3">
                  <div class="col-12 px-0">
                    <button type="button" class="btn btn-success btn-sm py-2 f-065" data-action="${this.controllerName}#issueInvoice" data-${this.controllerName}-target="issueInvoice">Emitir NF</button>
                  </div>
                </div>`

    } else if (this.current_receivable.invoices.length > 0) {
      var invoiceStatus = this.current_receivable.main_invoice.invoice_status
      var flowStatus = this.current_receivable.main_invoice.flow_status

      if (this.current_receivable.main_invoice.final_status) {
        if (flowStatus == `issued` || flowStatus == `uploaded`) {

          if (this.application.permissions[this.permission].can_delete) {
            var cancelBtn = `<div class="col-12 px-0">
                              <span class="mc-tooltip pointer">
                                <span class="material-icons-two-tone md-danger md-300" data-action="click->${this.controllerName}#checkCanCancel" data-${this.controllerName}-target="cancelInvoice">clear</span>
                                <span class="mc-tooltiptext">Cancelar Nota Fiscal</span>
                              </span>
                            </div>`
          } else {
            var cancelBtn = `<div class="col-12 px-0">
                              <span class="mc-tooltip pointer">
                                <span class="material-icons-two-tone md-danger md-300" data-action="click->${this.controllerName}#cancelInvoiceNotification" data-${this.controllerName}-target="cancelInvoice">clear</span>
                                <span class="mc-tooltiptext">Favor entrar em Contato para Cancelar a Nota Fiscal</span>
                              </span>
                            </div>`
          }

          html += `<div class="row mb-3">
                  <div class="col-12 px-0">
                    <span class="mc-tooltip pointer">
                      <span class="material-icons-two-tone md-dark md-300" data-kind="pdf" data-obj-id="${this.current_receivable.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">picture_as_pdf</span>
                      <span class="mc-tooltiptext">PDF Nota Fiscal</span>
                    </span>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-12 px-0">
                    <span class="mc-tooltip pointer">
                      <span class="material-icons-two-tone md-dark md-300" data-kind="xml" data-obj-id="${this.current_receivable.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">code</span>
                      <span class="mc-tooltiptext">XML Nota Fiscal</span>
                    </span>
                  </div>
                </div>
                <div class="row mb-3">
                  ${cancelBtn}
                </div>
                `  
        } else if (flowStatus == `waiting_send_cancel`) {
          html += updateInvoiceBtn
        } else if (flowStatus == `waiting_download`) {
          html += updateInvoiceBtn
        } else if (flowStatus == `waiting_return`) {
          html += updateInvoiceBtn
        } else if (flowStatus == `canceled`) {
          html += `<div class="row mb-3">
                      <div class="col-12 px-0">
                        <span class="mc-tooltip pointer">
                          <span class="material-icons-two-tone md-dark md-300" data-kind="pdf" data-obj-id="${this.current_receivable.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">picture_as_pdf</span>
                          <span class="mc-tooltiptext">PDF Nota Fiscal</span>
                        </span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-12 px-0">
                        <span class="mc-tooltip pointer">
                          <span class="material-icons-two-tone md-dark md-300" data-kind="xml" data-obj-id="${this.current_receivable.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">code</span>
                          <span class="mc-tooltiptext">XML Nota Fiscal</span>
                        </span>
                      </div>
                    </div>`
        } else if (flowStatus == `cancel_failed`) {

          if (this.application.permissions[this.permission].can_delete) {
            var cancelBtn = `<div class="col-12 px-0">
                              <span class="mc-tooltip pointer">
                                <span class="material-icons-two-tone md-danger md-300" data-action="click->${this.controllerName}#checkCanCancel" data-${this.controllerName}-target="cancelInvoice">clear</span>
                                <span class="mc-tooltiptext">Tentar Cancelar Nota Fiscal Novamente</span>
                              </span>
                            </div>`
          } else {
            var cancelBtn = `<div class="col-12 px-0">
                              <span class="mc-tooltip pointer">
                                <span class="material-icons-two-tone md-danger md-300" data-action="click->${this.controllerName}#cancelInvoiceNotification" data-${this.controllerName}-target="cancelInvoice">clear</span>
                                <span class="mc-tooltiptext">Favor entrar em Contato para Cancelar a Nota Fiscal</span>
                              </span>
                            </div>`
          }

          html += `<div class="row mb-3">
                    <div class="col-12 px-0">
                      <span class="mc-tooltip pointer">
                        <span class="material-icons-two-tone md-dark md-300" data-kind="pdf" data-obj-id="${this.current_receivable.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">picture_as_pdf</span>
                        <span class="mc-tooltiptext">PDF Nota Fiscal</span>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    ${cancelBtn}
                  </div>`
        }
        
      } else {
        if (invoiceStatus == `invoice_upload` && flowStatus == `waiting_upload`) {
          html += uploadBtnHtml

        } else if (invoiceStatus == `none_invoice` && flowStatus == `waiting_integration`) {
          html += `<div class="row mb-3">
                    <div class="col-12 px-0">
                      <button type="button" class="btn btn-success btn-sm py-2 f-065" data-action="${this.controllerName}#transmitInvoice" data-${this.controllerName}-target="transmitInvoice">Emitir NF</button>
                    </div>
                  </div>
                  ${uploadBtnHtml}`
        } else if (invoiceStatus == `error` && flowStatus == `issue_failed`) {
          html += `<div class="row mb-3">
                    <div class="col-12 px-0">
                      <span class="mc-tooltip default">
                        <span class="material-icons-two-tone md-danger md-300">error_outline</span>
                        <span class="mc-tooltiptext">Erro ao emitir Nota Fiscal</span>
                      </span>
                    </div>
                  </div>
                  ${tryAgain}
                  ${updateInvoiceBtn}`
        } else if (invoiceStatus == `issued_invoice` && flowStatus == `waiting_send_cancel`) {
          console.log(invoiceStatus)
        } else if (invoiceStatus == `issued_invoice` && flowStatus == `cancel_failed`) {
          console.log(invoiceStatus)
        } else {
          html += updateInvoiceBtn
        }
      }
    }
    
    this.actionTarget.innerHTML = html
  }

  invoiceUploadHtml() {
    var pdfHtml = ``
    var xmlHtml = ``

    if (this.current_receivable.main_invoice.has_pdf == false) {
      pdfHtml += `<div class="row d-flex align-items-center rowFile mb-3" data-field="pdf" data-field-pretty="PDF da Nota Fiscal">
                   <div class="col-12 px-0">
                     <span class="f-065">PDF</span>
                     <label class="drop-area copy" for="pdfDropzoneArea" data-${this.controllerName}-target="labelPdfUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                       <div class="row my-2">
                         <div class="col p-0 d-flex align-items-center justify-content-center">
                           <span class="material-icons md-200 md-dark mx-3">picture_as_pdf</span>
                         </div>
                       </div>
                     </label>
                     <input class="file-input mt-0" type="file" id="pdfDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                   </div>
                   <div class="col-12 px-0">
                     <span data-${this.controllerName}-target="filePdfName"></span>
                   </div>
                   <div class="col-12 px-0 mt-2">
                     <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="invoiceNumberYearForm">Ano</label>
                          <input aria-describedby="invoiceNumberYearFormHelp" class="form-control form-valid-control" id="invoiceNumberYearForm" data-${this.controllerName}-target="invoiceNumberYearInput" required>
                        </div>
                      </div>
                   </div>
                   <div class="col-12 px-0 mt-2">
                     <div class="form-group">
                        <div class="floating-label floating-label-sm">
                          <label for="invoiceNumberForm">Número NF</label>
                          <input aria-describedby="invoiceNumberFormHelp" class="form-control form-valid-control" id="invoiceNumberForm" data-${this.controllerName}-target="invoiceNumberInput" placeholder="00000" type="tel" required>
                        </div>
                      </div>
                   </div>
                 </div>`
    }

    if (this.current_receivable.main_invoice.has_xml == false) {
      xmlHtml += `<div class="row d-flex align-items-center rowFile mb-3" data-field="xml" data-field-pretty="XML da Nota Fiscal">
                  <div class="col-12 px-0">
                    <span class="f-065">XML</span>
                    <label class="drop-area copy" for="xmlDropzoneArea" data-${this.controllerName}-target="labelXmlUpload" data-action="dragover->${this.controllerName}#dragOverHandler drop->${this.controllerName}#dropHandler">
                      <div class="row my-2">
                        <div class="col p-0 d-flex align-items-center justify-content-center">
                          <span class="material-icons md-200 md-dark mx-3">code</span>
                        </div>
                      </div>
                    </label>
                    <input class="file-input mt-0" type="file" id="xmlDropzoneArea" data-action="change->${this.controllerName}#dropHandler"></input>
                  </div>
                  <div class="col-12 px-0">
                    <span data-${this.controllerName}-target="fileXmlName"></span>
                  </div>
                </div>`
    }

    var html = `${pdfHtml}
                ${xmlHtml}
                <div class="row w-100 d-flex align-items-end d-none" data-${this.controllerName}-target="fileUpload">
                  <div class="col-12 px-0">
                    <div class="form-group my-0 text-center w-100">
                      <h7 class="progress">
                        <span class="progress_count"></span>
                      </h7>
                      <div class="progress" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUpload">
                        <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                      </div>
                      <span data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                    </div>
                  </div>
                </div>
                <div class="row w-100 d-flex align-items-end">
                  <div class="col-12 px-0">
                    <span class="mc-tooltip pointer">
                      <span class="material-icons md-primary md-300" data-action="click->${this.controllerName}#doInvoiceUpload" data-${this.controllerName}-target="uploadBtn">cloud_upload</span>
                      <span class="mc-tooltiptext">Upload da Nota Fiscal e XML</span>
                    </span>
                  </div>
                </div>`

    // this.actionTarget.insertAdjacentHTML("beforeend", html)
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.actionTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      var date = new Date()
      if (controller.current_receivable.main_invoice.has_pdf == false) {
        controller.invoiceNumberYearInputTarget.value = date.getFullYear()
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dragOverHandler(ev) {
    ev.preventDefault();
  }

  dropHandler(ev) {
    ev.preventDefault();

    if (ev.target.files.length > 0) {
      if (ev.type == "change") {
        this.file = ev.target.files[0]

        var element = this.file
        var obj = {}
        var date = new Date()
        obj.file_name = element.name
        obj.file_size = element.size
        obj.file_type = element.type
        obj.file_last_modified = element.lastModified
        obj.field = ev.currentTarget.closest(".rowFile").dataset.field
        obj.field_pretty = ev.currentTarget.closest(".rowFile").dataset.fieldPretty
        obj.uploaded = false
        obj.message_erro = ""
        obj.created_at_time = parseInt(date.getTime() / 1000)
        if ((element.size / 1000000).toFixed(2) <= 5 && (this.filesPermittedTypes.includes(element.type.split("/")[1]) || this.filesPermittedTypes.includes(element.name.substr(element.name.lastIndexOf('.') + 1)))) {
          obj.can_upload = true
          obj.file = element
          this.uploadReady = true
          this.fileUploadTarget.classList.remove("d-none")
          if (obj.field == `pdf`) {
            this.filePdfNameTarget.innerText = obj.file_name
          } else if (obj.field == `xml`) {
            this.fileXmlNameTarget.innerText = obj.file_name
          }

          this.upload_files[this.upload_files.length] = obj
        } else {
          if ((element.size / 1000000).toFixed(2) > 5) {
            obj.message_erro += "Arquivo deve ser menor que 5MB.\n"
          }

          if (!this.filesPermittedTypes.includes(element.type.split("/")[1])) {
            obj.message_erro += "Formato do arquivo não é permitido."
          }
          obj.can_upload = false
          this.snackbarController.doSnackbar("danger", obj.message_erro, 5000)
        }
      }
    } else {
      this.snackbarController.doSnackbar("danger", `O Upload de Arquivos está desabilitado`, 3000)
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    } else {
      this.stopRefreshing()
      this.progressUploadCounterTarget.innerText = value + `%`
    }
  }

  doWatingBoardHtml() {

    var html = `<div class="row mb-4">
                  <div class="col px-0 text-center" data-${this.controllerName}-target="waitingBtn">
                    <span class="f-065 text-bold">Aguarde um instante ... </span>
                    <span class="material-icons md-dark md-300">hourglass_empty</span>
                  </div>
                </div>`

    this.actionTarget.innerHTML = html
  }

  cancelInvoiceNotification() {
    this.snackbarController.doSnackbar(`danger`, `Favor entrar em Contato com o nosso Atendimento WhatsApp (31) 2555-1912 para Cancelar a Nota Fiscal`, 7000)
  }

  sendEmail(ev) {
    var current_receivers = []
    var current_obj = this.current_receivable
    var controller = this
    var kind = ev.currentTarget.dataset.kind
    if (kind == `taker`) {
      this.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).sendControllerName = `operations--products--clinics--managements--emails--send-taker-invoice`
      var message = `Selecione o Tomador e os Anexos para enviar o Email!`
      current_receivers = [{ receiver_id: this.current_receivable.taker_id, receiver_type: `clinic_reg_takers`, receiver_name: this.current_receivable.taker_name }]
    } else if (kind == `performer`) {
      this.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).sendControllerName = `operations--products--clinics--managements--emails--send-performer-invoice`
      var message = `Selecione o(s) Sócio(s) Executante(s) e os Anexos para enviar o Email!`
      this.current_receivable.performers.forEach(performer => {
        var receiver = [{ receiver_id: performer.partner_id, receiver_type: `clinic_reg_partners`, receiver_name: this.current_receivable.taker_name }]
        current_receivers[current_receivers.length] = receiver
      })
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).openEmailModal(message))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_obj = current_obj
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_clinic = controller.application.clinic
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_date = controller.application.current_date
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_receivers = current_receivers
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).open()
    })
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  invoiceNumberMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToInvoiceNumber(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
