import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewIncomeCard", "viewTitle", "editIncomeBtn", "previewCard", "previewCardBody", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--incomes--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewIncomeCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Rendimento Selecionado</h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--incomes--dashboard").viewTarget.innerHTML = html
  }

  editIncome() {

    if (this.application.current_date.open) {
      if (this.checkIncome()) {
        this.getControllerByIdentifier("operations--products--tax-returns--incomes--save").current_income = this.current_income
        this.getControllerByIdentifier("operations--products--tax-returns--incomes--save").actionMode = "edit"
        this.getControllerByIdentifier("operations--products--tax-returns--incomes--save").doFormHtml()
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
    }

  }

  doIncomeViewHtml() {
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.current_income.source_type == "source") {
      var idType = `CPF`
    } else if (this.current_income.source_type == "patient") {
      var idType = `CNPJ`
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Pagamento Dedutível</h6>
                    <div class="card-actions ml-auto py-0">
                      <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="tooltip" data-placement="top" data-original-title="Editar Pagamento" data-${this.controllerName}-target="editIncomeBtn" data-action="click->${this.controllerName}#editIncome" type="button"><i class="material-icons f-1p25">edit</i></button>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>${this.current_income.member_kind_pretty}</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.member_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Beneficiário</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.source_name_pretty} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>${idType}</strong>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.source_number_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Data</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.date_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Rendimento</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_income.gross)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Imposto Retido</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_income.withheld)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Contribuição Previdência</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_income.social)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Vínculo</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.source_employment_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="4" class="f-065 align-middle px-0">
                                <strong>Classificação</strong> <br>
                              </th>
                              <td colspan="6" class="f-065 align-middle px-0 text-left">
                                ${this.current_income.chart_group_pretty}
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--incomes--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  checkIncome() {
    var income_source = {}

    this.application.sources.forEach(source => {
      if (source.id == this.current_income.source_id) {
        income_source = source
      }
    })

    if (this.current_income.source_type == `source` && this.application.products.company_ids.includes(income_source.company_id)) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `O Rendimento teve ser atualizado/editado na PJ Médica!`, 5000)
      return false
    } else {
      return true
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
