import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "cardBody", "footerTable", "dateStatus", "saveBtn", "dateOpen", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--settings--dates`
    this.doDatesListHtml()
  }

  newDate() {
    this.actionMode = "new"
    var date = new Date();
    if (this.application.dates.length == 0) {
      var newYear = date.getFullYear();
      var saveMode = true
    } else {
      var maxYear = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.dates, "year")[0].year
      if (maxYear == date.getFullYear()) {
        var saveMode = false
        var message = "Não foi possível criar novo período."
      } else {
        var newYear = maxYear + 1
        var saveMode = true
      }

    }
    
    var data = {
                  product: { id: this.application.tax_return.id },
                  date: { open: true, month: 0, year: newYear },
                  current_user: { current_user_id: this.application.current_user.id }
                }

    if (saveMode) {
      this.requestSave(data)
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 2000)      
    }
  }

  changeDateOpen(ev) {
    var id = ev.target.closest(".row").dataset.id
    if (ev.target.checked) {
      this.nameTarget(`dateStatus-${id}`).innerText = `Aberto`
    } else {
      this.nameTarget(`dateStatus-${id}`).innerText = `Fechado`
    }
    this.nameTarget(`saveBtn-${id}`).classList.remove("d-none")
  }

  editDate(ev) {
    this.actionMode = "edit"
    var id = ev.target.closest(".row").dataset.id
    this.nameTarget(`saveBtn-${id}`).classList.add("d-none")
    
    var data = {
                  product: { id: this.application.tax_return.id },
                  date: { id: id, open: this.nameTarget(`dateOpen-${id}`).checked },
                  current_user: { current_user_id: this.application.current_user.id }
                }

    this.requestSave(data)
  }

  requestSave(data) {
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/dates/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/dates/create"
      var method = "POST"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var date = response.data.cln
          if (this.actionMode == "edit") {
            if (date.active) {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1, date)
                }
              })
              if (this.application.current_date.id == date.id) {
                this.application.current_date = date
                if (this.application.current_date.open == false) {
                  this.application.current_date.message = `A Competência está fechado`
                }
              }
            } else {
              this.application.dates.forEach((element, i) => {
                if (element.id == date.id) {
                  this.application.dates.splice(this.application.dates.indexOf(element), 1)
                }
              })
              if (this.application.current_date.id == date.id) {
                this.application.current_date = {}
              }
            }
          } else if (this.actionMode == "new") {
            this.application.dates[this.application.dates.length] = date
            location.reload()
          }
          this.listData()
        } else {
          // this.nameTarget(`saveBtn-${data.date.id}`).classList.remove("d-none")
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  listData() {
    var dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.dates, "year")
    this.cardBodyTarget.innerHTML = ""
    if (dates == undefined || dates == [] || dates.length == 0) {
      var noData = `<div class="row d-flex align-items-center">
                      <div class="col-12 my-3 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <h5 class="mb-0 f-065">Não há Competências no Planner da ${this.application.tax_return.account_name}</h5>
                      </div>
                    </div>`

      this.cardBodyTarget.innerHTML = noData
    } else {
      dates.forEach(element => {
        this.cardBodyTarget.insertAdjacentHTML("beforeend", this.datePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  datePartial(element) {
    if (element.open) {
      if (this.application.medreturn_dates.can_update) {
        var inputHtml = `<input class="custom-control-input custom-control-input-permission" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      } else {
        var inputHtml = `<input disabled class="custom-control-input custom-control-input-permission" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="true" checked>`
      }
      var status = `Aberto`
    } else {
      if (this.application.medreturn_dates.can_update) {
        var inputHtml = `<input class="custom-control-input custom-control-input-permission" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      } else {
        var inputHtml = `<input disabled class="custom-control-input custom-control-input-permission" id="switchDateOpen-${element.id}" data-action="click->${this.controllerName}#changeDateOpen" data-${this.controllerName}-target="dateOpen-${element.id}" type="checkbox" value="false">`
      }
      var status = `Fechado`
    }

    if (this.application.medreturn_dates.can_delete) {
      var deleteBtn = `<button data-action="click->${this.controllerName}#deleteDate" data-${this.controllerName}-target="deleteBtn-${element.id}" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Apagar"><span class="material-icons md-sm md-dark">delete</span></button>`
    } else {
      var deleteBtn = ``
    }
    var deleteBtn = ``

    var html = `<div class="row d-flex align-items-center" data-id="${element.id}">
                  <div class="col-2 my-3 px-1">
                    <span>${element.year}</span>
                  </div>
                  <div class="col-6 my-3 d-flex">
                    <div class="custom-control custom-switch d-flex align-items-center">
                      ${inputHtml}
                      <span class="custom-control-track"></span>
                      <label class="custom-control-label" for="switchDateOpen-${element.id}"></label>
                    </div>
                    <span data-${this.controllerName}-target="dateStatus-${element.id}">${status}</span>
                  </div>
                  <div class="col-2 my-3 px-1 text-right">
                    <button data-action="click->${this.controllerName}#editDate" data-${this.controllerName}-target="saveBtn-${element.id}" type="button" class="btn btn-sm btn-table p-0 d-none" data-toggle="tooltip" data-placement="top" title data-original-title="Salvar"><span class="material-icons md-sm md-dark">save</span></button>
                  </div>
                  <div class="col-2 my-3 px-1 text-right">
                    ${deleteBtn}
                  </div>
                </div>`

    return html
  }

  doDatesListHtml() {
    if (this.application.medreturn_dates.can_create) {
      var createBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="tooltip" data-placement="top" data-original-title="Adicionar nova Competência" data-action="click->${this.controllerName}#newDate" data-mode="new" type="button"><i class="material-icons">add</i></button>`
    } else {
      var createBtn = ``
    }


    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Anos</h6>
                    <div class="card-actions ml-auto py-0">
                      ${createBtn}
                    </div>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="cardBody"></div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--settings--dashboard").datesTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.66) + "px"
      controller.listData()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-returns--settings--dates"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
