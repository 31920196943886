import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "recognizedAtInput", "saveBtn", "totalAmountInput", "descriptionInput", "recognizedAmountInput", "body"]

  connect() {
    this.controllerName = `financials--books--dashboards--dates--modal`
    // this.open()
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doHtml()
    })
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doHtml() {
    var nextDates = []

    this.application.dates.forEach(element => {
      if (element.date_token >= this.application.current_date.date_token) {
        nextDates[nextDates.length] = element
      }
    })

    var place = 1
    var elements = []
    var columns = 4
    var rows = Math.floor(nextDates.length / columns) + 1
    var row = 1
    if (nextDates.length <= columns) {
      nextDates.forEach(element => {
        elements[elements.length] = element
      })
      this.bodyTarget.insertAdjacentHTML("beforeend", this.datePartial(elements))
    } else {
      nextDates.forEach(element => {
        elements[elements.length] = element
        place += 1

        if (place > columns) {
          this.bodyTarget.insertAdjacentHTML("beforeend", this.datePartial(elements))
          place = 1
          elements = []
          row += 1
        }
      })
      if (row == rows) {
        this.bodyTarget.insertAdjacentHTML("beforeend", this.datePartial(elements))
      }
    }

  }

  datePartial(elements) {
    var datesHtml = ``

    elements.forEach(element => {
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#setDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.date_pretty}</button>`
    })

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    return html
  }

  setDate(ev) {
    var dateId = ev.currentTarget.dataset.id
    var currentDate = {}

    this.application.dates.forEach(element => {
      if (element.id == dateId) {
        currentDate = element
      }
    })

    if (this.controllerForm.context.identifier === `financials--books--payables--entities--show-modal`) {
      this.getControllerByIdentifier(`financials--books--payables--entities--save`).current_payable = this.current_obj
      this.getControllerByIdentifier(`financials--books--payables--entities--save`).actionMode = "copy"
      this.getControllerByIdentifier(`financials--books--payables--entities--save`).current_date = currentDate
      this.getControllerByIdentifier(`financials--books--payables--entities--save`).doFormGridHtml()
      this.getControllerByIdentifier(`financials--books--payables--entities--show-modal`).close()
    } else if (this.controllerForm.context.identifier === `financials--books--payrolls--remunerations--list`) {
      this.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).current_remuneration = this.current_obj
      this.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).current_contract = this.current_contract
      this.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).actionMode = "copy"
      this.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).current_date = currentDate
      this.getControllerByIdentifier(`financials--books--payrolls--remunerations--save`).doFormHtml()
    } else if (this.controllerForm.context.identifier === ``) {
      
    }

    this.close()
  }

  setCurrentPayable(payableId) {
    this.application.payables.forEach(element => {
      if (element.id == payableId) {
        this.current_payable = element
      }
    })
  }



  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}