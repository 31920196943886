import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "help", "form", "patient", "attentionAt", "attention", "introduction", "quantity", "noEntriesCard",
    "code", "codeFilter", "entriesList", "entriesDiv", "entriesCard", "entriesForm", "levelTeamDropdown", "levelTeamInput",
    "levelTeamList", "protocol", "notes", "btnGroupSave", "codeError", "newEntryBtn", "savePaperBtn", "addEntryBtn"]


  connect() {
    this.controllerName = `mobile--operations--products--receivements--papers--new`
    this.paper = {}
    this.paper.receivement_id = this.application.receivement.id
    this.entries = []
    this.doNewForm()
  }

  doNewForm() {
    var html = `<div class="row mt-5" data-${this.controllerName}-target="introduction">
                  <div class="col-12">
                    <h5>${this.application.user_name}, vamos agora fazer um novo lançamento de Folha de Sala ou Guia de Procedimento/Consulta. Vou guiar os próximos passos</h5>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-9 pr-1">
                    <span><small class="text-muted">Ajuda</small></span>
                    <h5 data-${this.controllerName}-target="help"></h5>
                  </div>
                  <div class="col-3 pl-0">
                    <button type="submit" class="btn btn-flat-danger btn-sm my-3 s-title-1rem w-100 px-0 text-left" data-${this.controllerName}-target="newPaper" data-action="click->${this.controllerName}#cancelNewPaper">Cancelar</button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12" data-${this.controllerName}-target="form"></div>
                </div>`

    this.mainTarget.innerHTML = html
    this.doAttentionAtInput()
  }

  cancelNewPaper() {
    this.getControllerByIdentifier("mobile--operations--products--receivements--entities--show").reconnect()
  }

  doAttentionAtInput() {
    var html = `<h6>Data Atendimento</h6>
                <hr>
                <div class="form-group my-4">
                  <div class="floating-label floating-label-md">
                    <label class="f-1p25" for="attentionAtPaper">Data Atendimento</label>
                    <input aria-describedby="attentionAtPaperHelp" class="form-control f-1p5 w-100" id="attentionAtPaper" type="text" data-${this.controllerName}-target="attentionAt">
                  </div>
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#cancelNewPaper"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getAtttentionAt">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                </div>`

    this.formTarget.innerHTML = html
    this.helpTarget.innerText = `O primeiro passo é inserir a Data do Atendimento. O campo é obrigatório.`
    if (this.paper && this.paper.attention_at != undefined) {
      this.attentionAtTarget.value = this.paper.attention_at
      $("#attentionAtPaper").val(this.paper.attention_at)
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }
    pickDateLimit($('#attentionAtPaper'), "monthly", this.application.current_receivement_date.year, this.application.current_receivement_date.month);
  }

  getAtttentionAt(ev) {
    if (this.attentionAtTarget.value != "" && this.attentionAtTarget.value != undefined) {
      this.paper.attention_at = this.attentionAtTarget.value
    }
    if (this.paper.attention_at != "" && this.paper.attention_at != undefined) {
      this.doProviderInput()
    }
  }

  doProviderInput() {
    if (this.hasIntroductionTarget) {
      this.introductionTarget.remove()
    }
    var formsHtml = ``
    this.application.forms.forEach(element => {
      formsHtml += `<div class="col-4 mt-3 px-2">
                      <div class="card w-100 h-100" data-form-id="${element.id}" data-provider-id="${element.provider_id}" data-action="click->${this.controllerName}#getProvider">
                        <div class="card-body p-0 text-center d-flex align-items-center">
                          <figure class="w-100 figure btn btn-flat text-center" data-toggle="tooltip" data-placement="top" title data-original-title="Novo Lançamento">
                            <i class="fab fa-wpforms md-primary"></i>
                            <figcaption class="figure-caption f-1" style="white-space:break-spaces;">${element.provider_name}</figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>`
    });
    var html = `<h6>Prestador de Serviços</h6>
                <hr>
                <div class="row dashboard-content mb-4">
                  ${formsHtml}
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-action="click->${this.controllerName}#doAttentionAtInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                </div>`

    this.formTarget.innerHTML = html
    this.helpTarget.innerText = `Agora precisamos do Provedor de Serivços. Clique no escolhido, por favor.`
  }

  getProvider(ev) {
    this.paper.form_id = ev.target.closest(".card").dataset.formId
    this.paper.provider_id = ev.target.closest(".card").dataset.providerId

    this.application.forms.forEach(element => {
      if (element.id == this.paper.form_id) {
        this.current_form = element
      }
    })
    this.doOperatorInput()
  }

  doOperatorInput() {
    var operatorHtml = ``
    this.application.operators.forEach(element => {
      operatorHtml += `<div class="col-4 mt-3 px-2">
                      <div class="card w-100 h-100" data-operator-id="${element.id}" data-flow="next" data-action="click->${this.controllerName}#getOperator">
                        <div class="card-body p-0 text-center d-flex align-items-center">
                          <figure class="w-100 figure btn btn-flat text-center" data-toggle="tooltip" data-placement="top" title data-original-title="Novo Lançamento">
                            <i class="fab fa-wpforms md-primary"></i>
                            <figcaption class="figure-caption f-1" style="white-space:break-spaces;">${element.name}</figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>`
    });
    var html = `<h6>Operadora de Saúde</h6>
                <hr>
                <div class="row dashboard-content mb-4">
                  ${operatorHtml}
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-action="click->${this.controllerName}#doProviderInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                </div>`

    this.formTarget.innerHTML = html
    this.helpTarget.innerText = `Agora precisamos da Operadora de Saúde. Clique no escolhido, por favor.`
  }

  getOperator(ev) {
    this.paper.operator_id = ev.target.closest(".card").dataset.operatorId
    this.getGuideline()
    var flow = ev.target.closest(".card").dataset.flow
    this.doPatientInput(flow)
  }

  doPatientInput(flow) {
    if (this.current_form.patient) {
      var html = `<h6>Nome do Paciente</h6>
                <hr>
                <div class="form-group my-4">
                  <div class="floating-label floating-label-md">
                    <label class="f-1p25" for="patientPaper">Nome do Paciente</label>
                    <input aria-describedby="patientPaperHelp" class="form-control f-1p5 w-100" id="patientPaper" type="text" data-${this.controllerName}-target="patient">
                  </div>
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#doOperatorInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getPatient">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                </div>`

      this.formTarget.innerHTML = html
      if (this.paper && this.paper.patient != undefined) {
        this.patientTarget.value = this.paper.patient
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
      this.helpTarget.innerText = `Agora precisamos do Nome do Paciente. É ideal ter o nome do paciente para aumentar a acurácia da apuração final.`
    } else {
      this.paper.patient = ""
      if (flow == "previous") {
        this.doOperatorInput()
      } else if (flow == "next") {
        this.doAccomodationInput(flow)
      }
    }
  }

  getPatient(ev) {
    this.paper.patient = this.patientTarget.value.trim()
    var flow = ev.target.dataset.flow
    this.doAccomodationInput(flow)
  }

  doAccomodationInput(flow) {
    if (this.current_form.accomodation) {
      var html = `<h6>Acomodação</h6>
                  <hr>
                  <div class="row dashboard-content mb-4">
                    <div class="col-6 mt-3">
                      <div class="card w-100 h-100" data-accomodation="ward" data-flow="next" data-action="click->${this.controllerName}#getAccomodation">
                        <div class="card-body p-0 text-center d-flex align-items-center">
                          <figure class="w-100 figure btn btn-flat text-center" data-toggle="tooltip" data-placement="top" title data-original-title="Enfermaria">
                            <i class="fas fa-bed md-primary"></i>
                            <figcaption class="figure-caption f-1" style="white-space:break-spaces;">Enfermaria</figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 mt-3">
                      <div class="card w-100 h-100" data-accomodation="bedroom" data-flow="next" data-action="click->${this.controllerName}#getAccomodation">
                        <div class="card-body p-0 text-center d-flex align-items-center">
                          <figure class="w-100 figure btn btn-flat text-center" data-toggle="tooltip" data-placement="top" title data-original-title="Enfermaria">
                            <i class="fas fa-person-booth md-primary"></i>
                            <figcaption class="figure-caption f-1" style="white-space:break-spaces;">Apartamento</figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group w-100" role="group" style="box-shadow:none;">
                    <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#doPatientInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                </div>`

      this.formTarget.innerHTML = html
      this.helpTarget.innerText = `Agora precisamos da Acomodação. Clique no escolhido, por favor.`
    } else {
      this.paper.accomodation = "ward"
      if (flow == "previous") {
        this.doPatientInput(flow)
      } else if (flow == "next") {
        this.doLevelTeamInput(flow)
      }
    }
  }

  getAccomodation(ev) {
    this.paper.accomodation = ev.target.closest(".card").dataset.accomodation
    var flow = ev.target.closest(".card").dataset.flow
    this.doLevelTeamInput(flow)
  }

  doLevelTeamInput(flow) {
    if (this.current_form.level_anesthesic) {
      this.paper.level_team = "anesthesiologist"
      if (flow == "previous") {
        this.doAccomodationInput(flow)
      } else if (flow == "next") {
        this.doProtocolInput(flow)
      }
    } else if (this.current_form.level_team) {
      var html = `<h6>Posição Equipe Médica</h6>
                  <hr>
                  <div class="row dashboard-content mb-4">
                    <div class="col-12 mt-3">
                      <div class="form-group">
                        <div class="floating-label floating-label-md">
                          <label>Posição Equipe</label>
                          <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="levelTeamDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item f-1p25" id="selected" data-${this.controllerName}-target="levelTeamInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="levelTeamList">
                                <li data-level="principal" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">Principal</li>
                                <li data-level="first_aux" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">1ª Auxiliar</li>
                                <li data-level="second_aux" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">2ª Auxiliar</li>
                                <li data-level="third_aux" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">3ª Auxiliar</li>
                                <li data-level="fourth_aux" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">4ª Auxiliar</li>
                                <li data-level="anesthesiologist" data-flow="next" data-action="click->${this.controllerName}#getLevelTeam" class="li-selector dark my-3 f-1p25">Anestesista</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group w-100" role="group" style="box-shadow:none;">
                    <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#doAccomodationInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  </div>`

      this.formTarget.innerHTML = html
      this.helpTarget.innerText = `Agora precisamos da sua posição na Equipe Médica. Clique no escolhido, por favor.`
    } else {
      this.paper.level_team = "principal"
      if (flow == "previous") {
        this.doAccomodationInput(flow)
      } else if (flow == "next") {
        this.doProtocolInput(flow)
      }
    }
  }

  getLevelTeam(ev) {
    this.paper.level_team = ev.target.dataset.level
    var flow = ev.target.dataset.flow
    this.doProtocolInput(flow)
  }

  doProtocolInput(flow) {
    if (this.current_form.protocol) {
      var html = `<h6>Protocolo</h6>
                  <hr>
                  <div class="form-group my-4">
                    <div class="floating-label floating-label-md">
                      <label class="f-1p25" for="protocolPaper">Protocolo</label>
                      <input aria-describedby="protocolPaperHelp" class="form-control f-1p5 w-100" id="protocolPaper" type="tel" data-${this.controllerName}-target="protocol">
                    </div>
                  </div>
                  <div class="btn-group w-100" role="group" style="box-shadow:none;">
                    <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#doLevelTeamInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                    <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getProtocol">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                  </div>`

      this.formTarget.innerHTML = html
      if (this.paper && this.paper.protocol != undefined) {
        this.protocolTarget.value = this.paper.protocol
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
      this.helpTarget.innerText = `Agora precisamos da Protocolo.`
    } else {
      this.paper.protocol = ""
      if (flow == "previous") {
        this.doLevelTeamInput(flow)
      } else if (flow == "next") {
        this.doNotesInput(flow)
      }
    }
  }

  getProtocol(ev) {
    this.paper.protocol = this.protocolTarget.value.trim()
    var flow = ev.target.dataset.set
    this.doNotesInput(flow)
  }

  doNotesInput(flow) {
    if (this.current_form.protocol) {
      var html = `<h6>Anotações</h6>
                  <hr>
                  <div class="form-group my-4">
                    <div class="floating-label floating-label-md">
                      <label class="f-1p25" for="notesPaper">Anotações</label>
                      <input aria-describedby="notesPaperHelp" class="form-control f-1p5 w-100" id="notesPaper" type="text" data-${this.controllerName}-target="notes">
                    </div>
                  </div>
                  <div class="btn-group w-100" role="group" style="box-shadow:none;">
                    <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#doProtocolInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                    <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getNotes">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                  </div>`

      this.formTarget.innerHTML = html
      if (this.paper && this.paper.notes != undefined) {
        this.notesTarget.value = this.paper.notes
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
      this.helpTarget.innerText = `Agora as anotações.`
    } else {
      this.paper.notes = ""
      if (flow == "previous") {
        this.doProtocolInput(flow)
      } else if (flow == "next") {
        this.doAttentionInput()
      }
    }
  }

  getNotes(ev) {
    this.paper.notes = this.notesTarget.value.trim()
    this.doAttentionInput()
  }

  doAttentionInput() {
    if (this.current_form.attention) {
      var html = `<h6>Número Atendimento</h6>
                <hr>
                <div class="form-group my-4">
                  <div class="floating-label floating-label-md">
                    <label class="f-1p25" for="attentionPaper">Número Atendimento</label>
                    <input aria-describedby="attentionPaperHelp" class="form-control f-1p5 w-100" id="attentionPaper" type="tel" data-${this.controllerName}-target="attention">
                  </div>
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#getBackToNotes"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getAttention">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                </div>`

      this.formTarget.innerHTML = html
      if (this.paper && this.paper.attention != undefined) {
        this.attentionTarget.value = this.paper.attention
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
      this.helpTarget.innerText = `Agora precisamos do Número de Atendimento. O campo é obrigatório.`
    } else if (this.current_form.authorization) {
      var html = `<h6>Número Autorização</h6>
                <hr>
                <div class="form-group my-4">
                  <div class="floating-label floating-label-md">
                    <label class="f-1p25" for="authorizationPaper">Número Autorização</label>
                    <input aria-describedby="authorizationPaperHelp" class="form-control f-1p5 w-100" id="authorizationPaper" type="tel" data-${this.controllerName}-target="authorization">
                  </div>
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-flow="previous" data-action="click->${this.controllerName}#getBackToNotes"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-flow="next" data-action="click->${this.controllerName}#getAttention">Próximo<span class="material-icons">arrow_forward_ios</span></button>
                </div>`

      this.formTarget.innerHTML = html
      if (this.paper && this.paper.authorization != undefined) {
        this.authorizationTarget.value = this.paper.authorization
        this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      }
      this.helpTarget.innerText = `Agora precisamos do Número da Autorização. O campo é obrigatório.`
    }
  }

  getBackToNotes(ev) {
    var flow = ev.target.dataset.flow
    this.doNotesInput(flow)
  }

  getAttention(ev) {
    if (this.current_form.attention) {
      this.paper.attention = this.attentionTarget.value.trim()
      this.paper.authorization = ""
    } else if (this.current_form.authorization) {
      this.paper.attention = ""
      this.paper.authorization = this.authorizationTarget.value.trim()
    }
    this.doEntryCards()
  }

  doEntryCards() {
    var entriesHtml = ``
    if (this.entries.length == 0) {
      var saveBtnHtml = `<button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-end disabled">Salvar<span class="material-icons">arrow_forward_ios</span></button>`
      entriesHtml = `<div class="card w-100 h-100" data-${this.controllerName}-target="noEntriesCard">
                      <div class="card-body p-0 text-center d-flex align-items-center">
                        <span class="fa-stack fa-2x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <h5>Favor lançar os Procedimentos</h5>
                      </div>
                    </div>`
    } else {
      var saveBtnHtml = `<button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-action="click->${this.controllerName}#savePaper" data-${this.controllerName}-target="savePaperBtn">Salvar<span class="material-icons">arrow_forward_ios</span></button>`
      this.entries.forEach(element => {
        entriesHtml += `<div class="row d-flex align-items-center my-3" data-${this.controllerName}-target="entriesForm">
                          <div class="col-1 p-1">
                            <div class="card-body p-0 text-center d-flex align-items-center">
                              <h5>${element.quantity} x</h5>
                            </div>
                          </div>
                          <div class="col-10 p-1">
                            <div class="card-body p-0 text-left d-flex align-items-center">
                              <h5>${element.name}</h5>
                            </div>
                          </div>
                          <div class="col-1 p-1">
                            <div class="card-body p-0 text-center d-flex align-items-center">
                              <span class="material-icons md-200" data-code="${element.code}" data-action="click->${this.controllerName}#removeEntry">delete</span>
                            </div>
                          </div>
                        </div>`
      })
    }
    var html = `<h6 class="d-flex justify-content-between align-items-center">
                  Procedimentos
                  <span data-toggle="tooltip" data-placement="top" title data-original-title="Novo Procedimento" class="ml-auto py-0 mr-3"><span class="material-icons pointer" data-action="click->${this.controllerName}#newEntry" data-${this.controllerName}-target="newEntryBtn">add</span></span>
                </h6>
                <hr>
                <div class="row mb-5">
                  <div class="col-12 px-0" data-${this.controllerName}-target="entriesDiv">
                    ${entriesHtml}
                  </div>
                </div>
                <div class="btn-group w-100" role="group" style="box-shadow:none;" data-${this.controllerName}-target="btnGroupSave">
                  <button type="button" class="btn btn-outline-dark w-50 d-flex align-items-center justify-content-start" data-action="click->${this.controllerName}#doAttentionInput"><span class="material-icons">arrow_back_ios</span>Voltar</button>
                  ${saveBtnHtml}
                </div>`

    this.formTarget.innerHTML = html
    this.helpTarget.innerText = `Agora precisamos listar todos os procedimentos realizados.`
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.quantityTarget.value != "" && controller.codeTarget.value != "") {
        controller.addEntryBtnTarget.classList.remove("d-none")
      } else {
        controller.addEntryBtnTarget.classList.add("d-none")
      }
    }, 200);
  }

  newEntry() {
    var html = `<div class="row" data-${this.controllerName}-target="entriesForm">
                  <div class="col-12 px-0">
                    <div class="form-group my-2">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="quantityPaper">Quantidade</label>
                        <input aria-describedby="quantityPaperHelp" class="form-control f-1p5 w-20" id="quantityPaper" type="number" data-${this.controllerName}-target="quantity">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-0">
                    <div class="form-group my-2">
                      <div class="floating-label floating-label-md">
                        <label class="f-1p25" for="codePaper">Código ou Descrição do Procedimento</label>
                        <input aria-describedby="codePaperHelp" class="form-control f-1p5 w-100" id="codePaper" type="text" data-filter-mode="simple" data-${this.controllerName}-target="code" data-action="focus->${this.controllerName}#entryFilterList keyup->${this.controllerName}#entryFilterList blur->${this.controllerName}#hideList">
                        <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="codeFilter">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 my-4">
                    <div class="btn-group w-100" role="group" style="box-shadow:none;">
                      <button type="button" class="btn btn-outline-danger w-50 d-flex align-items-center justify-content-start" data-action="click->${this.controllerName}#cancelEntry">Cancelar</button>
                      <button type="button" class="btn btn-outline-primary w-50 d-flex align-items-center justify-content-end" data-${this.controllerName}-target="addEntryBtn" data-action="click->${this.controllerName}#addEntry">Adicionar</button>
                    </div>
                  </div>
                </div>`

    if (this.hasNoEntriesCardTarget) {
      this.noEntriesCardTarget.remove()      
    }
    if (this.hasEntriesCardTarget) {
      this.entriesCardTarget.classList.add("d-none")
    }
    this.btnGroupSaveTarget.classList.add("d-none")
    this.newEntryBtnTarget.classList.add("d-none")
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.entriesDivTarget.innerHTML = html)
    }).then(() => {
      controller.listProcedures()
      controller.refreshSaveBtn()
      selector()
    })
  }

  listProcedures() {
    var html = ``
    if (this.application.procedures.length == 0) {
      html += `<li class="li-filter-mobile dark my-2 f-1p25">Não existem procedimentos</li>`
    } else {
      this.application.procedures.forEach(element => {
        html += `<li data-filter="${element.code}" data-text="${element.full}" class="li-filter-mobile dark my-3" data-action="click->${this.controllerName}#selectProcedure" data-${this.controllerName}-target="filterItem">${element.full}</li>`
      });
    }

    this.codeFilterTarget.innerHTML = html
  }

  cancelEntry() {
    this.entriesFormTarget.remove()
    this.doEntryCards()
  }

  addEntry() {
    this.stopRefreshing()
    this.entries[this.entries.length] = { code: this.codeTarget.dataset.filter, name: this.codeTarget.dataset.text, quantity: this.quantityTarget.value, error: this.codeTarget.dataset.error }
    this.btnGroupSaveTarget.classList.remove("d-none")
    this.cancelEntry()
  }
  
  removeEntry(ev) {
    this.entries.forEach(element => {
      if (element.code == ev.target.dataset.code) {
        this.entries.splice(this.entries.indexOf(element), 1)
      }
    })
    this.doEntryCards()
  }

  savePaper() {
    this.savePaperBtnTarget.disabled = true
    this.savePaperBtnTarget.classList.add("d-none")

    var codes = []
    var errors = []
    var quantities = []
    var guidelineAmounts = []
    var accesses = []
    var descriptions = []

    this.entries.forEach(element => {
      codes[codes.length] = element.code
      quantities[quantities.length] = element.quantity
      errors[errors.length] = element.error
    });

    var data = {
      current_user: { current_user_id: currentUser.id },
      form: this.current_form,
      paper: {
        receivement_id: this.paper.receivement_id,
        provider_id: this.paper.provider_id,
        operator_id: this.paper.operator_id,
        guideline_id: this.paper.guideline_id,
        transfer_id: this.current_form.transfer_id,
        patient_name: this.paper.patient,
        authorization: this.paper.authorization,
        attention: this.paper.attention,
        attention_at: this.paper.attention_at,
        procedure_table: this.paper.procedure_table,
        accomodation: this.paper.accomodation,
        level_team: this.paper.level_team,
        kind: this.paper.kind,
        protocol: this.paper.protocol,
        notes: this.paper.notes,
        codes: codes,
        quantities: quantities,
        guideline_amounts: guidelineAmounts,
        accesses: accesses,
        descriptions: descriptions,
        errors: errors,
        date_id: this.application.current_receivement_date.id
      }
    }

    this.requestSave(data)
  }

  entryFilterList(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 4)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectProcedure(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.validateEntryCode()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  validateEntryCode() {
    var validations = {
      obj_class: "Operation::Product::Receivement::Entry",
      obj_column: "code",
      obj_value: this.codeTarget.dataset.filter,
      filter_column: "paper_id",
      filter_value: "",
      association_column: "guideline_id",
      association_value: this.paper.guideline_id
    }
    var data = { validations: validations, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/validations/forms/validate_field"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.valid) {
          controller.codeTarget.classList.remove("invalid-field")
          if (controller.hasCodeErrorTarget) {
            controller.codeErrorTarget.remove()
          }
        } else {
          controller.codeTarget.classList.add("invalid-field")
          var html = `<div class="invalid-warning my-1 py-1" data-${this.controllerName}-target="codeError"></div>`
          controller.codeTarget.insertAdjacentHTML("afterend", html)
          controller.codeTarget.nextElementSibling.innerText = response.message
          controller.codeTarget.dataset.error = true
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getGuideline() {
    var data = { guideline: { provider_id: this.paper.provider_id, operator_id: this.paper.operator_id }, current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/receivements/guidelines/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.paper.guideline_id = response.data.cln.collection.id
          controller.paper.kind = response.data.cln.collection.kind
          controller.paper.procedure_table = response.data.cln.collection.procedure_table
        } else {
          processingSnackbar(response.type, response.message, device)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/receivements/papers/create"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.cancelNewPaper()
        } else {
          processingSnackbar(response.type, response.message, device)
          controller.savePaperBtnTarget.classList.remove("d-none")
          controller.savePaperBtnTarget.disabled = false
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
