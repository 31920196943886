import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
  }

  selectedItem() {
    document.querySelector('.dropdown-selector')[0].sharedSelectorElement.outputItem()
  }
}
