import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "ticket", "addJourney", "save", "5555", "summary", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--journey--dashboard`
  }

  doJourneyDashboard() {

    if (this.application.current_user.account_kind == `doctor`) {
      var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6" data-${this.controllerName}-target="list" data-controller="operations--products--tax-filings--journey--index"></div>
                  <div class="col-6" data-${this.controllerName}-target="save" data-controller="operations--products--tax-filings--journey--save"></div>
                </div>`
    } else if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
      var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6" data-${this.controllerName}-target="list" data-controller="operations--products--tax-filings--journey--index"></div>
                  <div class="col-3" data-${this.controllerName}-target="save" data-controller="operations--products--tax-filings--journey--save"></div>
                  <div class="col-3" data-${this.controllerName}-target="summary" data-controller="operations--products--tax-filings--journey--summary"></div>
                </div>`
    }

    // var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
    //               <div class="col-6" data-${this.controllerName}-target="list" data-controller="operations--products--tax-filings--journey--index"></div>
    //               <div class="col-3" data-${this.controllerName}-target="save" data-controller="operations--products--tax-filings--journey--save"></div>
    //               <div class="col-3" data-${this.controllerName}-target="summary" data-controller="operations--products--tax-filings--journey--summary"></div>
    //             </div>`
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--show--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--show--submenu`).dashboardToggleBtn(`JORNADA`)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
