import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cnpjInput", "tradeNameInput", "nextBtn", "errorMessage", "linkInput", "tradeNameInput",
                    "saveCompanyBtn", "dddInput", "phoneInput", "kindPhoneDropdown", "kindPhoneDropdownBtn", "kindPhoneInput", "kindPhoneList",
                    "addressInput", "kindEmailDropdown", "kindEmailDropdownBtn", "kindEmailInput", "kindEmailList", "zipInput", "kindAddressDropdown", "kindAddressDropdownBtn", "kindAddressInput",
                    "kindAddressList", "streetInput", "numberInput", "complementInput", "districtInput", "stateInput", "stateFilter", "cityInput", "cityFilter", "nextBtn",
                    "savePhoneBtn", "saveEmailBtn", "saveAddressBtn", "financialCompanyCheckbox", "signatoryCompanyCheckbox", "invoiceCompanyCheckbox",
                    "nameInput", "municipalInput", "openedAtInput", "ibgeInput", "newCompanyInfo", "asdf"]

  connect() {
    this.controllerName = `financials--products--purchases--add--parties--company--save`
    this.canRequest = true
    this.company_id = ``
    this.partyConfirmed = false
    this.party = {}

    this.company = { phone: {}, email: {}, address: {} }
    this.send_address = { current_user: {}, record: {}, address: {} }
  }

  disconnect() {
    this.stopRefreshing()
    this.stopCompanyRefreshing()
    this.stopPhoneRefreshing()
    this.stopEmailRefreshing()
    this.stopAddressRefreshing()
  }

  saveCompany() {
    this.send_company_data = { current_user: {}, company: {}, kind: {} }
    this.saveCompanyBtnTarget.disabled = true
    this.stopCompanyRefreshing()

    if (this.companyActionMode == "edit") { 
      this.send_company_data.company.id = this.company_id
    } else if (this.companyActionMode == "new") { 
      this.send_company_data.company.name = this.nameInputTarget.value
      this.send_company_data.company.opened_at = this.openedAtInputTarget.value
      this.send_company_data.company.municipal_number = this.municipalInputTarget.value
    }
    this.send_company_data.company.trade_name = this.tradeNameInputTarget.value
    this.send_company_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value)
    this.send_company_data.kind.kind = `clinic`

    this.send_company_data.current_user.current_user_id = this.application.current_user.id
    this.send_company_data.current_user.feature = `financial_purchase_parties`

    this.requestSaveCompany()
  }

  requestSaveCompany() {
    if (this.companyActionMode == "new") {
      var url = "/users/companies/entities/create"
      var method = "POST"
    } else if (this.companyActionMode == "edit") {
      var url = "/users/companies/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_company_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.company = response.data.cln
          controller.company_id = controller.company.id
          controller.savePurchaseParty()
        } else {
          controller.saveCompanyBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  savePurchaseParty() {
    this.send_party_data = { current_user: {}, party: {} }

    if (this.purchaseActionMode == "edit") { this.send_party_data.party.id = this.party.id }
    this.send_party_data.party.purchase_id = this.application.purchase.id
    this.send_party_data.party.party_id = this.company.id
    this.send_party_data.party.party_type = this.company.class_type
    this.send_party_data.party.party_name = this.company.trade_name
    this.send_party_data.party.party_number = this.company.cnpj
    this.send_party_data.party.party_kind = `legal`
    this.send_party_data.party.party_signatory = this.signatoryCompanyCheckboxTarget.checked
    this.send_party_data.party.party_financial = this.financialCompanyCheckboxTarget.checked
    this.send_party_data.party.party_invoice = this.invoiceCompanyCheckboxTarget.checked

    if (this.application.purchase.kind == `recurring`) {
      this.send_party_data.party.kind = `recurring`
    } else if (this.application.purchase.kind == `setup`) {
      this.send_party_data.party.kind = `setup`
    }

    this.send_party_data.current_user.current_user_id = this.application.current_user.id

    this.requestSavePurchaseParty()
  }

  requestSavePurchaseParty() {
    if (this.purchaseActionMode == "new") {
      var url = "/financials/products/purchases/parties/create"
      var method = "POST"
    } else if (this.purchaseActionMode == "edit") {
      var url = "/financials/products/purchases/parties/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_party_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopCompanyRefreshing()
          var party = response.data.cln
          controller.partyConfirmed = true
          if (controller.purchaseActionMode == `new`) {
            controller.application.parties[controller.application.parties.length] = party
          } else if (controller.purchaseActionMode == `edit`) {
            controller.application.parties.forEach((element, i) => {
              if (element.id == party.id) {
                controller.application.parties.splice(controller.application.parties.indexOf(element), 1, party)
              }
            })
          }
          controller.application.purchase.parties = controller.application.parties
          controller.doContactFormHtml()
          controller.getControllerByIdentifier("financials--products--purchases--add--parties--company--index").doDataTable()
        } else {
          controller.partyConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  savePhone() {
    this.stopPhoneRefreshing()

    this.send_phone = { current_user: {}, record: {}, phone: {} }
    if (this.phoneActionMode == "edit") { this.send_phone.phone.id = this.company.phone.id }
    this.send_phone.phone.ddd = this.dddInputTarget.value
    this.send_phone.phone.number = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value)
    this.send_phone.phone.uniq_number = this.dddInputTarget.value + this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value)
    this.send_phone.phone.kind = this.kindPhoneInputTarget.dataset.kind
    this.send_phone.phone.is_main = true
    this.send_phone.current_user.current_user_id = this.application.current_user.id
    this.send_phone.current_user.feature = `financial_purchase_parties`
    this.send_phone.record.company_id = this.company.id
    this.send_phone.record.record_type = `company_entities`

    this.requestPhoneSave()
  }

  requestPhoneSave() {
    if (this.phoneActionMode == "new") {
      var url = "/users/accounts/phones/create"
      var method = "POST"
    } else if (this.phoneActionMode == "edit") {
      var url = "/users/accounts/phones/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_phone) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopPhoneRefreshing()
        if (response.save) {
          var phone = response.data.cln
          controller.company.phone = phone
          controller.savePhoneBtnTarget.innerText = `Confirmado`
          controller.savePhoneBtnTarget.disabled = true
          controller.phoneConfirmed = true
        } else {
          controller.savePhoneBtnTarget.innerText = `Salvar`
          controller.savePhoneBtnTarget.disabled = false
          controller.phoneConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveEmail() {
    this.stopEmailRefreshing()

    this.send_email = { current_user: {}, record: {}, email: {} }
    if (this.emailActionMode == "edit") { this.send_email.email.id = this.company.email.id }
    this.send_email.email.address = this.addressInputTarget.value
    this.send_email.email.kind = this.kindEmailInputTarget.dataset.kind
    this.send_email.email.is_main = true
    this.send_email.current_user.current_user_id = this.application.current_user.id
    this.send_email.current_user.feature = `financial_purchase_parties`
    this.send_email.record.company_id = this.company.id
    this.send_email.record.record_type = `company_entities`

    this.requestEmailSave()
  }

  requestEmailSave() {
    if (this.emailActionMode == "new") {
      var url = "/users/accounts/emails/create"
      var method = "POST"
    } else if (this.emailActionMode == "edit") {
      var url = "/users/accounts/emails/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_email) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopEmailRefreshing()
        if (response.save) {
          var email = response.data.cln
          controller.company.email = email
          controller.saveEmailBtnTarget.innerText = `Confirmado`
          controller.saveEmailBtnTarget.disabled = true
          controller.emailConfirmed = true
        } else {
          controller.saveEmailBtnTarget.innerText = `Salvar`
          controller.saveEmailBtnTarget.disabled = false
          controller.emailConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveAddress() {
    this.stopAddressRefreshing()

    this.send_address = { current_user: {}, record: {}, address: {} }
    if (this.addressActionMode == "edit") { this.send_address.address.id = this.company.address.id }
    this.send_address.address.postal_code = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value)
    this.send_address.address.street = this.streetInputTarget.value
    this.send_address.address.number = this.numberInputTarget.value
    this.send_address.address.complement = this.complementInputTarget.value
    this.send_address.address.district = this.districtInputTarget.value
    this.send_address.address.city = this.cityInputTarget.value
    this.send_address.address.state = this.stateInputTarget.value
    this.send_address.address.ibge = this.ibgeInputTarget.value.trim()
    this.send_address.address.country = `Brasil`
    this.send_address.address.kind = this.kindAddressInputTarget.dataset.kind
    this.send_address.address.is_main = true
    this.send_address.current_user.current_user_id = this.application.current_user.id
    this.send_address.current_user.feature = `financial_purchase_parties`
    this.send_address.record.company_id = this.company.id
    this.send_address.record.record_type = `company_entities`
    

    this.requestAddressSave()
  }

  requestAddressSave() {
    if (this.addressActionMode == "new") {
      var url = "/users/accounts/addresses/create"
      var method = "POST"
    } else if (this.addressActionMode == "edit") {
      var url = "/users/accounts/addresses/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_address) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopAddressRefreshing()
        if (response.save) {
          var address = response.data.cln
          controller.company.address = address
          controller.saveAddressBtnTarget.innerText = `Confirmado`
          controller.saveAddressBtnTarget.disabled = true
          controller.addressConfirmed = true
        } else {
          controller.saveAddressBtnTarget.innerText = `Salvar`
          controller.saveAddressBtnTarget.disabled = false
          controller.addressConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doCompanyFormHtml() {
    this.stopCompanyRefreshing()

    var html = `<div class="" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title f-065 p-1 mb-1" data-${this.controllerName}-target="formTitle">Criando ou Editando Médico</h6>
                  </div>
                  <div class="card-body p-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-1">
                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate" placeholder="CNPJ" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="tradeNameForm">Razão Social</label>
                            <input aria-describedby="tradeNameFormHelp" class="form-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2" data-${this.controllerName}-target="newCompanyInfo">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Fantasia</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Fantasia" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="municipalForm">Inscrição Municipal</label>
                            <input aria-describedby="municipalFormHelp" class="form-control" id="municipalForm" data-${this.controllerName}-target="municipalInput" placeholder="Inscrição Municipal" type="text" required>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-3">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="openedAtForm">Data de Abertura</label>
                            <input aria-describedby="openedAtFormHelp" class="form-control" id="openedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="openedAtInput" placeholder="DD/MM/AAAA" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="signatoryCompanyCheck" data-${this.controllerName}-target="signatoryCompanyCheckbox">
                          <label class="custom-control-label f-065" for="signatoryCompanyCheck">Contratante</label>
                        </div>
                      </div>
                      <div class="col-4 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="financialCompanyCheck" data-kind="financial" data-${this.controllerName}-target="financialCompanyCheckbox">
                          <label class="custom-control-label f-065" for="financialCompanyCheck">Financeiro</label>
                        </div>
                      </div>
                      <div class="col-4 d-flex align-items-center">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="invoiceCompanyCheck" data-${this.controllerName}-target="invoiceCompanyCheckbox">
                          <label class="custom-control-label f-065" for="invoiceCompanyCheck">Fiscal</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2 mb-4">
                      <div class="col-12 text-right">
                        <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#cancelSaveParty">Fechar</button>
                        <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveCompany" data-${this.controllerName}-target="saveCompanyBtn"></button>
                      </div>
                    </div>               
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--add--parties").formCompanyPartyBodyTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveCompanyBtn()
      controller.formCardTarget.style.height = ($(controller.getControllerByIdentifier("financials--products--purchases--add--parties").formBodyTarget)[0].offsetHeight * 0.9) + "px"

      if (controller.party.id) {
        controller.purchaseActionMode = `edit`
        controller.companyActionMode = `edit`
        controller.tradeNameInputTarget.disabled = false
        controller.newCompanyInfoTarget.remove()

        controller.saveCompanyBtnTarget.innerText = `Salvar`
        controller.signatoryCompanyCheckboxTarget.checked = controller.party.party_signatory
        controller.financialCompanyCheckboxTarget.checked = controller.party.party_financial
        controller.invoiceCompanyCheckboxTarget.checked = controller.party.party_invoice
        controller.cnpjInputTarget.value = controller.party.party_number_pretty
        controller.tradeNameInputTarget.value = controller.party.party_name
        controller.company_id = controller.party.party_id
      } else {
        controller.purchaseActionMode = `new`
        controller.tradeNameInputTarget.disabled = true
        controller.nameInputTarget.disabled = true
        controller.municipalInputTarget.disabled = true
        controller.openedAtInputTarget.disabled = true
        
        controller.saveCompanyBtnTarget.innerText = `Salvar`
        controller.signatoryCompanyCheckboxTarget.checked = true
        controller.financialCompanyCheckboxTarget.checked = true
        controller.invoiceCompanyCheckboxTarget.checked = true
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.openedAtInputTarget.classList.add("f-normal")
        this.openedAtInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.openedAtInputTarget.classList.add("f-danger")
        this.openedAtInputTarget.classList.remove("f-normal")
      }
    } else {
      this.openedAtInputTarget.classList.add("f-normal")
      this.openedAtInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  searchCompany(ev) {
    
    ev.preventDefault()
    ev.stopPropagation()
    ev.stopImmediatePropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 11) {
      this.tradeNameInputTarget.value = ""

      this.tradeNameInputTarget.disabled = true


      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.tradeNameInputTarget.value == "") {

    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14 && this.canRequest) {
      this.canRequest = false
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      var data = { company: { cnpj: cleanCnpj, kind: "clinic" }, current_user: { current_user_id: this.application.current_user.id, feature: "financial_purchase_parties" } }
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

      if (this.cnpj_result.invalid == false) {

        var hasProduct = false
        if (hasProduct) {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O Médico já possui o produto Declaração de Ajuste Anual", 2000)
          this.setErrorMessage("O Médico já possui o produto Declaração de Ajuste Anual")
        } else {
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              controller.canRequest = true
              if (response.process) {
                var company = response.data.cln
                controller.company_id = company.id

                if (company.id) {
                  controller.tradeNameInputTarget.value = company.trade_name
                  controller.nameInputTarget.value = company.name
                  controller.municipalInputTarget.value = company.municipal_number
                  controller.openedAtInputTarget.value = company.opened_at
                  
                  controller.tradeNameInputTarget.disabled = true
                  controller.nameInputTarget.disabled = true
                  controller.municipalInputTarget.disabled = true
                  controller.openedAtInputTarget.disabled = true
                  
                  controller.formTitleTarget.innerText = `Editando PJ Médica`
                  controller.saveCompanyBtnTarget.classList.remove("d-none")
                  controller.saveCompanyBtnTarget.innerText = `Confirmar`
                  controller.companyActionMode = `edit`
                } else {
                  controller.formTitleTarget.innerText = `Criando PJ Médica`
                  controller.tradeNameInputTarget.value = "Favor adicionar o Nome do PJ Médica"

                  controller.tradeNameInputTarget.disabled = false
                  controller.nameInputTarget.disabled = false
                  controller.municipalInputTarget.disabled = false
                  controller.openedAtInputTarget.disabled = false
                  
                  controller.saveCompanyBtnTarget.classList.remove("d-none")
                  controller.saveCompanyBtnTarget.innerText = `Salvar`
                  controller.companyActionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }
      }
    }
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }


  doContactFormHtml() {
    this.stopPhoneRefreshing()
    this.stopEmailRefreshing()
    this.stopAddressRefreshing()

    this.phoneConfirmed = false
    this.emailConfirmed = false
    this.addressConfirmed = false

    var html = `<div class="" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title f-065 p-1 mb-1" data-${this.controllerName}-target="formTitle">Contatos da PJ Médica</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-1">
                      <div class="col-3 px-1">
                        <div class="floating-label floating-label-sm">
                          <label>Tipo</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindPhoneDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindPhoneDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindPhoneInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="kindPhoneList">
                                <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dddInput">DDD</label>
                            <input id="dddInput" data-${this.controllerName}-target="dddInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#dddMask keyup->${this.controllerName}#dddMask" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="phoneInput">Número</label>
                            <input id="phoneInput" data-${this.controllerName}-target="phoneInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-flex align-items-center justify-content-end">
                        <button type="button" class="btn btn-primary btn-sm btn-table py-2 px-1 f-065" data-action="click->${this.controllerName}#savePhone" data-${this.controllerName}-target="savePhoneBtn"></button>
                      </div>
                    </div>

                    <div class="row my-1">
                      <div class="col-3 px-1">
                        <div class="floating-label floating-label-sm">
                          <label>Tipo</label>
                          <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindEmailDropdown">
                            <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindEmailDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindEmailInput"></span></button>
                            <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                              <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                              <ul class="ul-select" data-${this.controllerName}-target="kindEmailList">
                                <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="addressInput">E-mail</label>
                            <input id="addressInput" data-${this.controllerName}-target="addressInput" class="form-control p-1 s-title-0p6rem" type="email" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-flex align-items-center justify-content-end">
                        <button type="button" class="btn btn-primary btn-sm btn-table py-2 px-1 f-065" data-action="click->${this.controllerName}#saveEmail" data-${this.controllerName}-target="saveEmailBtn"></button>
                      </div>
                    </div>

                    <div class="row my-1">
                      <div class="col-3 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindAddressDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindAddressDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindAddressInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindAddressList">
                                  <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                  <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="zipInput">CEP</label>
                            <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask focus->${this.controllerName}#searchZip blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="ibgeInput">IBGE</label>
                            <input id="ibgeInput" data-${this.controllerName}-target="ibgeInput" class="form-control p-1 s-title-0p6rem" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-flex align-items-center justify-content-end">
                        <button type="button" class="btn btn-primary btn-sm btn-table py-2 px-1 f-065" data-action="click->${this.controllerName}#saveAddress" data-${this.controllerName}-target="saveAddressBtn"></button>
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="col-6 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="streetInput">Logradouro</label>
                            <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="numberInput">Número</label>
                            <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="complementInput">Complemento</label>
                            <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-1">
                      <div class="col-5 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="districtInput">Bairro</label>
                            <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="stateForm">UF</label>
                            <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                              <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                              <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                              <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                              <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                              <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                              <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                              <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                              <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                              <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                              <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                              <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                              <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                              <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                              <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                              <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                              <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                              <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                              <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                              <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                              <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                              <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                              <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                              <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                              <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                              <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                              <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                              <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-5 px-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cityForm">Cidade</label>
                            <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
           
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--add--parties").formCompanyPartyBodyTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveContactBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.4) + "px"
      if (controller.company.phone.id) {
        controller.phoneActionMode = `edit`
        controller.savePhoneBtnTarget.innerText = `Confirmar`
        controller.dddInputTarget.value = controller.company.phone.ddd
        controller.phoneInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").phoneMask(controller.company.phone.number)
        controller.kindPhoneInputTarget.dataset.kind = controller.company.phone.kind
        controller.kindPhoneInputTarget.innerText = controller.company.phone.kind_pretty
        controller.kindPhoneDropdownTarget.value = controller.company.phone.kind_pretty
      } else {
        controller.phoneActionMode = `new`
        controller.savePhoneBtnTarget.innerText = `Salvar`
      }

      if (controller.company.email.id) {
        controller.emailActionMode = `edit`
        controller.saveEmailBtnTarget.innerText = `Confirmar`
        controller.addressInputTarget.value = controller.company.email.address
        controller.kindEmailInputTarget.dataset.kind = controller.company.email.kind
        controller.kindEmailInputTarget.innerText = controller.company.email.kind_pretty
        controller.kindEmailDropdownTarget.value = controller.company.email.kind_pretty
      } else {
        controller.emailActionMode = `new`
        controller.saveEmailBtnTarget.innerText = `Salvar`
      }

      if (controller.company.address.id) {
        controller.addressActionMode = `edit`
        controller.saveAddressBtnTarget.innerText = `Confirmar`
        controller.zipInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").zipMask(controller.company.address.postal_code)
        controller.streetInputTarget.value = controller.company.address.street
        controller.numberInputTarget.value = controller.company.address.number
        controller.complementInputTarget.value = controller.company.address.complement
        controller.districtInputTarget.value = controller.company.address.district
        controller.cityInputTarget.value = controller.company.address.city
        controller.stateInputTarget.value = controller.company.address.state
        controller.ibgeInputTarget.value = controller.company.address.ibge
        controller.kindAddressInputTarget.dataset.kind = controller.company.address.kind
        controller.kindAddressInputTarget.innerText = controller.company.address.kind_pretty
        controller.kindAddressDropdownTarget.value = controller.company.address.kind_pretty
      } else {
        controller.addressActionMode = `new`
        controller.saveAddressBtnTarget.innerText = `Salvar`
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  dddMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  searchZip(ev) {

    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
                controller.send_address.address.street = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
                controller.send_address.address.district = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
                controller.send_address.address.state = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
                controller.send_address.address.city = dados.localidade
              }
              if (dados.ibge === "") {
                controller.ibgeInputTarget.disabled = false
              } else {
                controller.ibgeInputTarget.value = dados.ibge
                controller.send_address.address.ibge = dados.ibge
              }
              controller.send_address.address.country = `Brasil`
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        this.cleanForm();
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    } else if (ev.type == "focus" && this.zipInputTarget.value.length < 10) {
      this.closeForm();
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
          // this.cityListTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  zipMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    this.stateInputTarget.disabled = false
    this.cityInputTarget.disabled = false

    this.send_address.address.postal_code = ``
    this.send_address.address.street = ``
    this.send_address.address.number = ``
    this.send_address.address.complement = ``
    this.send_address.address.district = ``
    this.send_address.address.city = ``
    this.send_address.address.state = ``
    this.send_address.address.country = ``
    this.send_address.address.ibge = ``
  }

  closeForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = true
    this.districtInputTarget.disabled = true
    this.stateInputTarget.disabled = true
    this.cityInputTarget.disabled = true

    this.send_address.address.postal_code = ``
    this.send_address.address.street = ``
    this.send_address.address.number = ``
    this.send_address.address.complement = ``
    this.send_address.address.district = ``
    this.send_address.address.city = ``
    this.send_address.address.state = ``
    this.send_address.address.country = ``
    this.send_address.address.ibge = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["formCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveCompanyBtn() {
    var controller = this
    this.refreshCompanyTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveCompanyBtnTarget) {
        if (controller.cnpj_result && controller.cnpj_result.invalid) {
          len += 1
        }

        if (controller.hasCnpjInputTarget && controller.cnpjInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasTradeNameInputTarget && controller.tradeNameInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasNameInputTarget && controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasOpenedAtInputTarget && controller.openedAtInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveCompanyBtnTarget.disabled = false
        } else {
          controller.saveCompanyBtnTarget.disabled = true
        }
      } else {
        controller.stopCompanyRefreshing()
      }
      
    }, 200);
  }

  refreshSaveContactBtn() {
    var controller = this

    this.refreshPhoneTimer = setInterval(function () {
      var len = 0

      if (controller.hasSavePhoneBtnTarget) {
        if (controller.hasDddInputTarget && controller.dddInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasPhoneInputTarget && controller.phoneInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.savePhoneBtnTarget.disabled = false
        } else {
          controller.savePhoneBtnTarget.disabled = true
        }
      } else {
        controller.stopPhoneRefreshing()
        controller.stopRefreshing()
      }

    }, 200);

    this.refreshEmailTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveEmailBtnTarget) {
        if (controller.hasAddressInputTarget && controller.addressInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveEmailBtnTarget.disabled = false
        } else {
          controller.saveEmailBtnTarget.disabled = true
        }
      } else {
        controller.stopEmailRefreshing()
        controller.stopRefreshing()
      }

    }, 200);

    this.refreshAddressTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveAddressBtnTarget) {
        if (controller.hasZipInputTarget && controller.zipInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasStreetInputTarget && controller.streetInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasNumberInputTarget && controller.numberInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasDistrictInputTarget && controller.districtInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasCityInputTarget && controller.cityInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasStateInputTarget && controller.stateInputTarget.value == ``) {
          len += 1
        }

        if (controller.hasIbgeInputTarget && controller.ibgeInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveAddressBtnTarget.disabled = false
        } else {
          controller.saveAddressBtnTarget.disabled = true
        }
      } else {
        controller.stopAddressRefreshing()
        controller.stopRefreshing()
      }

    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      // if (!controller.phoneConfirmed) {
      //   len += 1
      // }

      // if (!controller.emailConfirmed) {
      //   len += 1
      // }

      if (!controller.addressConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.cancelSaveParty()
      } else {
        controller.getControllerByIdentifier("financials--products--purchases--add--parties").partiesActions(true)
      }
    }, 200);
  }

  cancelSaveParty() {
    this.stopCompanyRefreshing()
    this.stopPhoneRefreshing()
    this.stopEmailRefreshing()
    this.stopAddressRefreshing()
    this.getControllerByIdentifier("financials--products--purchases--add--parties").partiesActions(false)
    this.stopRefreshing()
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopCompanyRefreshing() {
    if (this.refreshCompanyTimer) {
      clearInterval(this.refreshCompanyTimer)
    }
  }

  stopPhoneRefreshing() {
    if (this.refreshPhoneTimer) {
      clearInterval(this.refreshPhoneTimer)
    }
  }

  stopEmailRefreshing() {
    if (this.refreshEmailTimer) {
      clearInterval(this.refreshEmailTimer)
    }
  }

  stopAddressRefreshing() {
    if (this.refreshAddressTimer) {
      clearInterval(this.refreshAddressTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
