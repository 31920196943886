import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--onboards--show--notes`
    this.blockedAdd = false
  }

  doNotesDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-5 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--notes--index"></div>
                  <div class="col-7 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--notes--show users--works--notes--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--onboards--show--entity`).notesRowTarget.innerHTML = html)
    }).then(() => {

      var partyName = ``
      this.application.onboard.parties.forEach(party => {
        partyName += `| ${party.party_name} `
      })

      controller.getControllerByIdentifier(`users--works--notes--index`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--index`).sharing = false
      controller.getControllerByIdentifier(`users--works--notes--index`).blockedAdd = this.blockedAdd
      controller.getControllerByIdentifier(`users--works--notes--show`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--show`).blockedAdd = this.blockedAdd
      controller.getControllerByIdentifier(`users--works--notes--save`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.onboard.id,
        domain_type: `operation_onboards`,
        domain_name: `Onboard ${this.application.onboard.product_service_pretty} ${this.application.onboard.product_accrual_name_pretty} ${partyName}`,
        date_id: this.application.onboard.id,
        date_type: `onboard_dates`,
      }
      controller.getControllerByIdentifier(`users--works--notes--index`).note_board = {
        account_id: this.application.current_user.account_id,
        domain_id: this.application.onboard.id,
        domain_type: `operation_onboards`,
        domain_name: `Onboard ${this.application.onboard.product_service_pretty} ${this.application.onboard.product_accrual_name_pretty} ${partyName}`,
        date_id: this.application.onboard.id,
        date_type: `onboard_dates`,
      }

      controller.getControllerByIdentifier(`users--works--notes--index`).permission = `operation_onboard_notes`
      controller.getControllerByIdentifier(`users--works--notes--save`).permission = `operation_onboard_notes`
      controller.getControllerByIdentifier(`users--works--notes--show`).permission = `operation_onboard_notes`

      controller.getControllerByIdentifier(`users--works--notes--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--notes--show`).doNoteViewHtml()
    })
  }

  saveUpdateNote(message) {
    this.actionMode = `new`
    this.canRequest = false
    this.send_data = { current_user: {}, note: {}, notification: {} }

    var partyName = ``
    this.application.onboard.parties.forEach(party => {
      partyName += `| ${party.party_name} `
    })

    this.send_data.note.account_id = this.application.current_user.account_id
    this.send_data.note.domain_id = this.application.onboard.id
    this.send_data.note.domain_type = `operation_onboards`
    this.send_data.note.domain_name = `Onboard ${this.application.onboard.product_service_pretty} ${this.application.onboard.product_accrual_name_pretty} ${partyName}`,
    this.send_data.note.date_id = this.application.onboard.date_id
    this.send_data.note.date_type = `onboard_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = message
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `operation_onboard_notes`

    this.getControllerByIdentifier(`users--works--notes--save`).actionMode = `new`
    this.getControllerByIdentifier(`users--works--notes--save`).send_data = this.send_data
    this.getControllerByIdentifier(`users--works--notes--save`).requestSave()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
