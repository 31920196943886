import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `mobile--site--principal--frames--questions`

    if (this.getControllerByIdentifier(`web--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`web--site--principal--main`)
    } else if (this.getControllerByIdentifier(`mobile--site--principal--main`)) {
      this.siteController = this.getControllerByIdentifier(`mobile--site--principal--main`)
    }
  }

  doHtml() {
    var html = `<section class="mws-section-questions">
                  <div class="container">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="lp-content">
                        <p class="lp-text mt-2r text-center">
                          ${this.application.site.questions_subtitle}
                        </p>
                        <h1 class="lp-title text-center mt-m-1r">
                          ${this.application.site.questions_title}
                        </h1>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 ws-questions-div" data-${this.controllerName}-target="cards">

                    
                    
                    </div>
                  </div>
                </section>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.siteController.contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.setQuestions()
    })
  }

  setQuestions() {
    var html = ``
    var current_questions = []

    this.application.site.questions.forEach(question => {
      if (question.status) {
        current_questions[current_questions.length] = question
      }
    })
    current_questions = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_questions, `order`)

    current_questions.forEach(question => {
      var colClass = ``

      if (this.application.size == `large`) {
        colClass += `col-8 offset-2`
      } else if (this.application.size == `medium`) {
        colClass += `col-10 offset-1`
      } else if (this.application.size == `small`) {
        colClass += `col-12`
      }

      html += `<div class="${colClass} mt-5">
                  <div class="mws-card-question pointer" data-question-id="${question.id}"data-action="click->${this.controllerName}#goToAnswer">
                    <div class="container">
                      <h3 class="mws-card-title mb-0">${question.title}</h3>
                    </div>
                  </div>
                </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardsTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.doCarouselIndicators()
    })
  }

  goToAnswer(ev) {
    var questionId = ev.currentTarget.dataset.questionId

    this.application.site.questions.forEach(question => {
      if (question.id == questionId) {
        this.setAnswer(question)
      }
    })
  }

  setAnswer(question) {
    var link = this.siteController.setLink(question.link_cta)
    var colClass = ``

    if (this.application.size == `large`) {
      colClass += `col-8 offset-2`
    } else if (this.application.size == `medium`) {
      colClass += `col-10 offset-1`
    } else if (this.application.size == `small`) {
      colClass += `col-12`
    }

    var html = `<div class="${colClass} mt-5">
                  <div class="mws-card-question">
                    <div class="container">
                      <h3 class="mws-card-title-small d-flex align-items-center mb-0">
                        ${question.title}
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#closeAnswer" type="button">
                          <span class="material-icons md-dark">clear</span>
                        </button>
                      </h3>                      
                    </div>
                  </div>
                </div>
                <div class="${colClass} mt-4">
                  <div class="card-body mws-card-text-small">
                    ${question.description}
                  </div>
                  <div class="card-footer ws-card-text">
                    <a class="mws-card-link pointer" data-link="${link}" data-action="click->${this.controllerName}#goToCTA">${question.cta}</a>
                  </div>
                </div>`

    this.cardsTarget.innerHTML = html
  }

  closeAnswer() {
    this.cardsTarget.innerHTML = ``
    this.setQuestions()
  }

  goToCTA(ev) {
    var url = ev.currentTarget.dataset.link
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

}
