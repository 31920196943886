import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "setupCheckbox", "recurringCheckbox", "saveBtn",
                    "teamInput", "providerInput", "medInput", "medModal",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "contractStatusForm",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "subkindDropdown", "subkindDropdownBtn", "subkindInput", "subkindList",
                    "roleDropdown", "roleDropdownBtn", "roleInput", "roleList",
                    "currentAmountInput", "startedAt", "finishedForm", "finishedAt",
                    "currentAmountInput", "teamModal", "providerModal", "closingForm",
                    "closingAt", "asdf"]


  connect() {
    this.controllerName = `financials--books--payrolls--contracts--save`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.canRequest = true
    this.cpf_result = {}
    this.record = {}
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`financials--books--payrolls--contracts--dashboard`).doContractsDashboard()
  }

  saveRemuneration() {
    // this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data = { current_user: {}, contract: {}, tracker: { manual: true } }
    var contractName = `${this.subkindInputTarget.innerText} | ${this.kindInputTarget.innerText}`

    if (this.actionMode == `edit`) {
      this.send_data.contract.id = this.current_contract.id
      this.send_data.contract.status = this.statusInputTarget.dataset.status

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Atualizou o Contrato ${this.current_contract.account_name}`
      this.send_data.tracker.obj_id = this.current_contract.id

    } else if (this.actionMode == `new`) {

      this.send_data.contract.account_id = this.teamInputTarget.dataset.teamId
      this.send_data.contract.account_name = this.teamInputTarget.value.split(" | ")[0].trim()
      this.send_data.contract.provider_id = this.providerInputTarget.dataset.providerId
      this.send_data.contract.provider_name = this.providerInputTarget.value
      this.send_data.contract.med_id = this.medInputTarget.dataset.medId
      this.send_data.contract.status = `active`
      
      this.send_data.tracker.action = `created`
      this.send_data.tracker.observations = `Adicionou o Contrato ${contractName} para ${this.send_data.contract.account_name}`
    }
    
    this.send_data.contract.role = this.roleInputTarget.dataset.role
    this.send_data.contract.kind = this.kindInputTarget.dataset.kind
    this.send_data.contract.subkind = this.subkindInputTarget.dataset.subkind
    // this.send_data.contract.description = this.descriptionInputTarget.value.trim()
    // this.send_data.contract.notes = this.notesInputTarget.value.trim()
    this.send_data.contract.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtTarget.value)
    this.send_data.contract.finished_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.finishedAtTarget.value)
    this.send_data.contract.closing_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.closingAtTarget.value)
    this.send_data.contract.name = contractName
    this.send_data.contract.current_amount = this.controllerNumber.fromCurrencyToNumber(this.currentAmountInputTarget.value)

    this.send_data.tracker.record_id = this.teamInputTarget.dataset.teamId
    this.send_data.tracker.record_type = `account_entities`
    this.send_data.tracker.obj_type = `financial_payroll_contracts`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.actionMode == `new`) {
      var url = "/financials/books/payrolls/contracts/create"
      var method = "POST"
    } else if (this.actionMode == `edit` || this.actionMode == `closing`) {
      var url = "/financials/books/payrolls/contracts/update"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/financials/books/payrolls/contracts/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var contract = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.contracts[controller.application.contracts.length] = contract
          } else if (controller.actionMode == `edit`) {
            controller.application.contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, contract)
              }
            })

          } else if (controller.actionMode == `destroy`) {
            controller.application.contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1)
              }
            })
          }

        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
        }

        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

      // `<div class="row my-2">
      //   <div class="col-12">
      //     <div class="form-group">
      //       <div class="floating-label floating-label-sm">
      //         <label for="descriptionForm">Descrição</label>
      //         <input aria-describedby="descriptionFormHelp" class="form-control textarea px-0" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      // <div class="row my-2">
      //   <div class="col-12">
      //     <div class="form-group">
      //       <div class="floating-label floating-label-sm">
      //         <label for="notesForm">Anotações</label>
      //         <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="4"></textarea>
      //       </div>
      //     </div>
      //   </div>
      // </div>`

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveRemuneration" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-4">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" id="startedAtForm" data-${this.controllerName}-target="startedAt" placeholder="Início" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 d-none" data-${this.controllerName}-target="finishedForm">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="finishedAtForm">Finalização</label>
                                <input aria-describedby="finishedAtFormHelp" class="form-control form-valid-control" id="finishedAtForm" data-${this.controllerName}-target="finishedAt" placeholder="Finalização" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 d-none" data-${this.controllerName}-target="closingForm">
                            <div class="form-group mb-0">
                              <div class="floating-label floating-label-sm">
                                <label for="closingAtForm">Encerrou</label>
                                <input aria-describedby="closingAtFormHelp" class="form-control form-valid-control" id="closingAtForm" data-${this.controllerName}-target="closingAt" placeholder="Finalização" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0" data-${this.controllerName}-target="teamModalCol">
                            <button data-action="click->${this.controllerName}#showTeams" data-element="teamInput" data-${this.controllerName}-target="teamModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Parceiros</span>
                            </button>
                          </div>
                          <div class="col-11" data-${this.controllerName}-target="teamModalCol">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Parceiro</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="teamInput" type="text" placeholder="Parceiro" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showProviders" data-element="providerInput" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                            </button>
                          </div>
                          <div class="col-11">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Fornecedor</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2 d-flex align-items-center">

                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Área</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="roleList" data-app--helpers--search-target="searchList">
                                      <li data-role="technology" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Tecnologia</li>
                                      <li data-role="accounting" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Controle</li>
                                      <li data-role="investment" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Acumulação</li>
                                      <li data-role="protection" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Proteção</li>
                                      <li data-role="legal" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Jurídico</li>
                                      <li data-role="customer_success" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sucesso do Cliente</li>
                                      <li data-role="sales" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                      <li data-role="marketing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Marketing</li>
                                      <li data-role="performance" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Performance</li>
                                      <li data-role="administrative" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Adminstrativo</li>
                                      <li data-role="board" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Diretoria</li>
                                      <li data-role="general" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Serviços Gerais</li>
                                      <li data-role="biling" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRole" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo Contrato</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="intern" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Estágio</li>
                                      <li data-kind="labor_law" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">CLT</li>
                                      <li data-kind="self_employed" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Autônomo</li>
                                      <li data-kind="partner" data-action="click->app--helpers--selector#select click->${this.controllerName}#setSubkind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sócio</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo Pessoa</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="subkindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="subkindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="subkindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="subkindList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2">
                          <div class="col-6 d-none" data-${this.controllerName}-target="contractStatusForm">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Status</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                      <li data-status="active" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ativa</li>
                                      <li data-status="inactive" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectStatus" data-app--helpers--selector-target="select" class="li-selector dark f-065">Encerrado</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="currentAmountForm">Valor Atual</label>
                                <input aria-describedby="currentAmountFormHelp" class="form-control" id="currentAmountForm" data-${this.controllerName}-target="currentAmountInput" placeholder="Valor Atual" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        
                        

                        
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--payrolls--contracts--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.startedAtTarget), { max: true, months: true, years: 7 })

      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Contrato`
        controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.finishedAtTarget), { max: true, months: true, years: 7 })
        controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.closingAtTarget), { max: true, months: true, years: 7 })

        controller.finishedFormTarget.classList.remove(`d-none`)
        controller.closingFormTarget.classList.remove(`d-none`)
        controller.startedAtTarget.value = controller.current_contract.started_at_pretty
        if (controller.current_contract.finished) {
          controller.finishedAtTarget.value = controller.current_contract.finished_at_pretty
        }
        if (controller.current_contract.closed) {
          controller.closingAtTarget.value = controller.current_contract.closing_at_pretty
        }

        controller.contractStatusFormTarget.classList.remove(`d-none`)
        controller.statusDropdownTarget.value = controller.current_contract.status
        controller.statusInputTarget.dataset.status = controller.current_contract.status
        controller.statusInputTarget.innerText = controller.current_contract.status_pretty

        controller.kindDropdownTarget.value = controller.current_contract.kind
        controller.kindInputTarget.dataset.kind = controller.current_contract.kind
        controller.kindInputTarget.innerText = controller.current_contract.kind_pretty

        controller.subkindDropdownTarget.value = controller.current_contract.subkind
        controller.subkindInputTarget.dataset.subkind = controller.current_contract.subkind
        controller.subkindInputTarget.innerText = controller.current_contract.subkind_pretty

        controller.roleDropdownTarget.value = controller.current_contract.role
        controller.roleInputTarget.dataset.role = controller.current_contract.role
        controller.roleInputTarget.innerText = controller.current_contract.role_pretty

        controller.teamInputTarget.dataset.teamId = controller.current_contract.account_id
        controller.teamInputTarget.value = controller.current_contract.account_name
        controller.providerInputTarget.dataset.providerId = controller.current_contract.provider_id
        controller.providerInputTarget.value = controller.current_contract.provider_name
        controller.medInputTarget.dataset.medId = controller.current_contract.med_id
        controller.medInputTarget.value = controller.current_contract.med_name

        controller.teamModalTarget.disabled = true
        controller.providerModalTarget.disabled = true
        controller.medModalTarget.disabled = true

        controller.currentAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_contract.current_amount)

        
      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Contrato`
        controller.currentAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  selectRole() {

  }

  selectStatus() {

  }

  showTeams(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="users--accounts--entities--list-modal" data-users--accounts--entities--list-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-users--accounts--entities--list-modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).kind = `team`
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).permission = controller.permission
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).open()
    })
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showProviders() {
    var modal = `financials--books--dashboards--providers--modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).feature = `financial_payroll_providers`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  setSubkind(ev) {
    var kind = ev.currentTarget.dataset.kind
    var html = ``

    if (kind == `intern` || kind == `labor_law` || kind == `partner`) {
      html = `<li data-subkind="individual_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Física</li>`

      this.subkindDropdownBtnTarget.disabled = true
      this.subkindDropdownTarget.value = `individual_person`
      this.subkindInputTarget.dataset.subkind = `individual_person`
      this.subkindInputTarget.innerText = `Pessoa Física`
    } else if (kind == `self_employed`) {
      html = `<li data-subkind="individual_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Física</li>
              <li data-subkind="legal_person" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoa Jurídica</li>`

      this.subkindDropdownBtnTarget.disabled = false
      this.subkindDropdownTarget.value = ``
      this.subkindInputTarget.dataset.subkind = ``
      this.subkindInputTarget.innerText = ``
    }

    this.subkindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  dateMask(ev) {
    this.controllerNumber.changeNumberToDate(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {


        if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.actionMode == `edit`) {
          if (controller.hasFinishedAtInputTarget && controller.finishedAtInputTarget.value == `` && controller.statusInputTarget.dataset.status != `active`) {
            len += 1
          }
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.subkindInputTarget.innerText == ``) {
          len += 1
        }

        // if (controller.descriptionInputTarget.value == ``) {
        //   len += 1
        // }

        if (controller.startedAtTarget.value == ``) {
          len += 1
        }

        if (controller.currentAmountInputTarget.value == `R$ 0,00`) {
          len += 1
        }

        if (controller.hasSaveBtnTarget) {
          if (len == 0) {
            controller.saveBtnTarget.disabled = false
          } else {
            controller.saveBtnTarget.disabled = true
          }
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
