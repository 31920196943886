import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "actions", "users", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--stats--trackers--real-time--dashboard`
  }

  doRealTimeDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="actions" data-controller="app--stats--trackers--real-time--actions"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="users" data-controller="app--stats--trackers--real-time--users"></div>
                </div>`

    // this.getControllerByIdentifier("app--stats--trackers--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--stats--trackers--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.submenuController) {
        controller.submenuController.dashboardToggleBtn(`REAL TIME`)
      }
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
