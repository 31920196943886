import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "addNewLeadBtn",
                    "nonContacts", "totalSQLs", "searchLeadBtn", "refreshLeadBtn", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--list`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.application.searchMode = false
    this.numberPages = []
    this.application.leads = []
    this.application.search_leads = []
    this.sort = { mode: `desc`, field: `date_to_time` }
    // this.doIndexListHtml()
    // this.getLeads()
  }

  addNewLead() {
    this.showClearBtn()

    this.getControllerByIdentifier("commercial--sales--leads--entities--save").actionMode = `new`
    this.getControllerByIdentifier("commercial--sales--leads--entities--save").doFormHtml()
  }

  searchLead() {
    this.showClearBtn()

    this.getControllerByIdentifier("commercial--sales--leads--entities--search").doSearchHtml()
  }

  editLead(ev) {
    this.showClearBtn()

    var leadId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.leads.forEach(element => {
      if (element.id == leadId) {
        this.getControllerByIdentifier("commercial--sales--leads--entities--save").current_lead = element
      }
    })

    this.getControllerByIdentifier("commercial--sales--leads--entities--save").actionMode = `edit`
    this.getControllerByIdentifier("commercial--sales--leads--entities--save").doFormHtml()
  }

  addOpportunity(ev) {
    this.showClearBtn()

    var leadId = ev.currentTarget.closest(".itemRow").dataset.id
    this.lead = {}

    this.getControllerByIdentifier("commercial--sales--leads--entities--show").lead_id = leadId
    this.getControllerByIdentifier("commercial--sales--leads--entities--show").readLead()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions.sales_opportunities_leads.can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addNewLead" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Novo Lead</span>`
    } else {
      var addLine = ``
    }

    if (this.application.current_user.super_admin) {
      var downloadLine = `<span data-action="click->${this.controllerName}#downloadLeads" class="dropdown-item py-1 pointer dropdown-submenu-item">Download Leads</span>`
    } else {
      var downloadLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshLeads" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Leads</span>`
    var searchLine = `<span data-action="click->${this.controllerName}#searchLead" class="dropdown-item py-1 pointer dropdown-submenu-item">Procurar Lead</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${searchLine}
                          ${refreshLine}
                          ${downloadLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`


    if (this.application.permissions.sales_opportunities_leads.can_create) {
      var addLeadBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline m-0 p-1" data-${this.controllerName}-target="addNewLeadBtn" data-action="click->${this.controllerName}#addNewLead" type="button">
                          <span class="material-icons md-sm">add</span>
                        </button>
                        <span class="mc-tooltiptext">Adicionar Novo Lead</span>`
    } else {
      var addLeadBtn = ``
    }

    var refreshLeadBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline m-0 p-1" data-${this.controllerName}-target="refreshLeadBtn" data-action="click->${this.controllerName}#refreshLeads" type="button">
                            <span class="material-icons md-sm">refresh</span>
                          </button>
                          <span class="mc-tooltiptext">Atualizar Leads</span>`

    var searchLeadBtn = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline m-0 p-1" data-${this.controllerName}-target="searchLeadBtn" data-action="click->${this.controllerName}#searchLead" type="button">
                          <span class="material-icons md-sm">search</span>
                        </button>
                        <span class="mc-tooltiptext">Procurar Lead</span>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Leads Gerados</h6>
                    <input class="form-control f-075 w-40 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <h6 class="card-title display-4 card-title-table-list">Não Contactos: <span data-${this.controllerName}-target="nonContacts"></span> / <span data-${this.controllerName}-target="totalSQLs"></span> Total</h6>
                    <div class="card-actions py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 card-overflow">
                    <div class="row">
                      <div class="col p-0">
                        <div class="mc-table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer">
                                  Data
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="date_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-40 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle">Fonte</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle">Conselho</th>                                
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle pointer">
                                  Status
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">Cliente</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle text-center"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle text-center"></th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle text-center"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--leads--entities--dashboard`).listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_leads = []
    if (this.application.searchMode) {
      current_leads = this.application.search_leads
    } else {
      current_leads = this.application.leads
    }

    if (this.sort.mode == `asc`) {
      var leads = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_leads, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var leads = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_leads, this.sort.field)
    }

    this.nonContactsCount()

    if (leads.length == 0) {
      this.listData()
      var pagination = { itemsOnPage: this.itemsOnPage, fromItem: 0, toItem: 0, totalItems: 0 }
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(leads.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (leads[x] !== undefined) {
            internPage[internPage.length] = leads[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: leads.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Leads Gerados</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.leadTablePartial(element, data.length))
      });
    }
  }

  leadTablePartial(element, length) {

    if (this.application.permissions.sales_opportunities_entities.can_create) {
      var actionBtnCreate = `<button data-action="click->${this.controllerName}#addOpportunity" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">add_circle_outline</span>
                        <span class="mc-tooltiptext">Criar Oportunidade</span>
                      </button>`
    } else {
      var actionBtnCreate = ``
    }

    if (element.status == `not_contact`) {
      var actionBtn = actionBtnCreate
      var statusIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-warning">new_releases</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    } else if (element.status == `in_process`) {
      var actionBtn = `<button data-action="click->${this.controllerName}#addOpportunity" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">visibility</span>
                        <span class="mc-tooltiptext">Ver Oportunidade</span>
                      </button>`
      var statusIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-primary">phone_in_talk</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    } else if (element.status == `gain`) {
      var actionBtn = actionBtnCreate
      var statusIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">emoji_events</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    } else if (element.status == `lost`) {
      var actionBtn = actionBtnCreate
      var statusIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">${element.status_pretty}</span>
                        </span>`
    }

    if (this.application.permissions.sales_opportunities_leads.can_update) {
      var editBtn = `<button data-action="click->${this.controllerName}#editLead" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar Lead</span>
                      </button>`
    } else {
      var editBtn = ``
    }

    if (element.has_account) {
      var clientStatus = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var clientStatus = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-opportunity-path="${element.opportunity_path}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-opportunity-path="${element.opportunity_path}">`
    }


    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#addOpportunity">${element.created_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#addOpportunity">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#addOpportunity">${element.primary_source_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.council}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center pointer">${clientStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${statusIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 px-3 align-middle pointer">${actionBtn}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer">${editBtn}</td>
                  </tr>`

    return rowHtml
  }

  nonContactsCount() {
    var nonContact = 0
    this.application.leads.forEach(element => {
      if (element.status == `not_contact`) {
        nonContact += 1
      }
    })

    this.totalSQLsTarget.innerText = this.application.leads.length
    this.nonContactsTarget.innerText = nonContact
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-3 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-9 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  showClearBtn() {
    this.clearListBtnTarget.classList.remove(`d-none`)
    this.optionBtnTarget.classList.add(`d-none`)
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)

    this.getControllerByIdentifier("commercial--sales--leads--entities--show").doViewCard()
    this.getControllerByIdentifier("commercial--sales--leads--entities--save").stopRefreshing()
    this.doDataTable()
  }

  refreshLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.insertAdjacentHTML("beforeend", this.getControllerByIdentifier("app--helpers--table").doTablePreloader(8, 4))
    this.application.searchMode = false

    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.leads = response.data.cln

        controller.clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  downloadLeads() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.application.searchMode = false

    const data = { lead: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/download"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var date = new Date()
        var fileName = `base-leads-${date.getTime()}`
        var fileExtension = `csv`

        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--tax-filings--entities--list"
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
