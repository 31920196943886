
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `users--accounts--entities--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.kindLoaded = { team: false, doctor: false, non_doctor: false, helper: false, patient: false }
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  goToTeam() {
    this.kind = `team`
    this.setSubmenu()
  }

  goToDoctors() {
    this.getControllerByIdentifier("users--accounts--entities--dashboard").kind = "doctor"
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  goToNonDoctors() {
    this.getControllerByIdentifier("users--accounts--entities--dashboard").kind = "non_doctor"
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  goToHelpers() {
    this.getControllerByIdentifier("users--accounts--entities--dashboard").kind = "helper"
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  goToPatients() {
    this.getControllerByIdentifier("users--accounts--entities--dashboard").kind = "patient"
    this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("users--accounts--entities--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "account_entities"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)
    this.getControllerByIdentifier(`users--accounts--entities--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  setSubmenu(ev) {
    this.kind = ev.currentTarget.dataset.kind
    this.kind_pretty = ev.currentTarget.dataset.kindPretty

    if (this.kindLoaded[this.kind]) {
      this.getControllerByIdentifier("users--accounts--entities--dashboard").kind = this.kind
      this.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
    } else {
      this.getAccounts()
    }
  }

  goToFeatures() {
    var url = `/a/funcionalidades`
    window.open(url, '_blank');
  }

  doSubmenuHtml() {

    // var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`

    if (this.application.permissions.account_entity_teams.can_index) {
      var teamBtn = `<button type="button" data-kind="team" data-kind-pretty="Time" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Time</button>`
    } else {
      var teamBtn = ``
    }

    if (this.application.permissions.account_entity_doctors.can_index) {
      var doctorBtn = `<button type="button" data-kind="doctor" data-kind-pretty="Médicos" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Médicos</button>`
    } else {
      var doctorBtn = ``
    }

    if (this.application.permissions.account_entity_doctors.can_index) {
      var nonDoctorBtn = `<button type="button" data-kind="non_doctor" data-kind-pretty="Não Médicos" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Não Médicos</button>`
    } else {
      var nonDoctorBtn = ``
    }

    if (this.application.permissions.account_entity_helpers.can_index) {
      var helperBtn = `<button type="button" data-kind="helper" data-kind-pretty="Assistentes" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Assistentes</button>`
    } else {
      var helperBtn = ``
    }

    if (this.application.permissions.account_entity_patients.can_index) {
      var patientBtn = `<button type="button" data-kind="patient" data-kind-pretty="Pacientes" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Pacientes</button>`
    } else {
      var patientBtn = ``
    }

    if (this.application.permissions.account_entity_takers.can_index) {
      var takerBtn = `<button type="button" data-kind="taker" data-kind-pretty="Tomadores" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tomadores</button>`
    } else {
      var takerBtn = ``
    }

    if (this.application.permissions.account_entity_permissions.can_assign) {
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
      var featureBtn = `<button type="button" data-action="${this.controllerName}#goToFeatures ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Funcionalidades</button>`
    } else {
      var permissionBtn = ``
      var featureBtn = ``
    }

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${doctorBtn}
                  ${nonDoctorBtn}
                  ${helperBtn}
                  ${teamBtn}
                  ${patientBtn}
                  ${permissionBtn}

                  ${featureBtn}
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--accounts--entities--dashboard").kind = `doctor`
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)

      // this.submenuBtnTargets.forEach(element => {
      //   element.style.width = 100 + "px"
      // })
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 offset-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getAccounts() {

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Contas | ${this.kind_pretty}` })
    this.refreshLoadValue(this.kind_pretty)

    const data = { account: { active: true, kind: this.kind }, current_user: { current_user_id: this.application.current_user.id, feature: "account_entities" } }
    const url = "/users/accounts/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        // controller.application.accounts = response.data.cln

        // response.data.cln.forEach(account => {
        //   controller.application.accounts[controller.application.accounts.length] = account
        // })

        var accountsCpfs = controller.mapElements(controller.application.accounts, `cpf`)

        response.data.cln.forEach(account => {
          if (!accountsCpfs.includes(account.cpf)) {
            controller.application.accounts[controller.application.accounts.length] = account
          }
        })


        controller.stopRefreshing()
        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        controller.kindLoaded[controller.kind] = true
        controller.getControllerByIdentifier("users--accounts--entities--dashboard").kind = controller.kind
        controller.getControllerByIdentifier("users--accounts--entities--dashboard").doMainDashboard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshLoadValue(kind_pretty) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas | ${kind_pretty}`, value: value })
      value += 1
    }, 400);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
