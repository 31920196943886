import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewCard", "viewTitle", "deleteNoteBtn", "editNoteBtn", "cardBodyNote", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--notes--show`
  }

  editNote() {
    if (this.application.current_date) {
      if (this.application.current_date.open) {
        this.application.notes.forEach(element => {
          if (element.id == this.current_note.id) {
            this.getControllerByIdentifier("users--works--notes--save").current_note = element
          }
        })

        this.getControllerByIdentifier("users--works--notes--save").actionMode = `edit`
        this.getControllerByIdentifier("users--works--notes--save").doFormHtml()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.application.current_date.message, 2000)
      }
    } else {
      this.application.notes.forEach(element => {
        if (element.id == this.current_note.id) {
          this.getControllerByIdentifier("users--works--notes--save").current_note = element

          this.getControllerByIdentifier("users--works--notes--save").actionMode = `edit`
          this.getControllerByIdentifier("users--works--notes--save").doFormHtml()
        }
      })
    }
  }

  deleteNote(ev) {
    var r = confirm("Tem certeza que deseja apagar a Anotação?")
    if (r) {
      this.send_data = { current_user: {}, note: {} }
      this.send_data.note.id = this.current_note.id
      this.send_data.note.active = false
      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.requestDestroy()
    }
  }

  requestDestroy() {
    var url = "/users/accounts/notes/destroy"
    var method = "DELETE"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var note = response.data.cln
          controller.application.notes.forEach((element, i) => {
            if (element.id == note.id) {
              controller.application.notes.splice(controller.application.notes.indexOf(element), 1)
            }
          })
          controller.send_data = { current_user: {}, note: {} }
          controller.getControllerByIdentifier("users--works--notes--index").doDataTable()
        }
        
        controller.cancelSave()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("users--works--notes--show").doNoteViewHtml()
  }

  doNoteViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">Nenhuma Anotação Selecionada</h6>
                  </div>
                </div>`

    this.dashboardController.viewTarget.innerHTML = html
  }

  doViewHtml() {
    if (this.application.permissions[this.permission].can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteNoteBtn" data-action="click->${this.controllerName}#deleteNote" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Anotação</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editNoteBtn" data-action="click->${this.controllerName}#editNote" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Anotação</span>`
    } else {
      var editBtnHtml = ``
    }

    if (!this.application.current_user.super_admin && (this.current_note.kind == `updating` || this.current_note.kind == `check` || this.blockedAdd)) {
      deleteBtnHtml = ``
      editBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">${this.current_note.date_pretty}</h6>
                    <h6 class="card-title display-4 ml-0" style="padding:1rem;font-size:110%;margin-bottom:0px;">${this.current_note.title}</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBodyNote">
                    <div class="row my-3">
                      <div class="col-12 trix-content">
                        ${this.current_note.body}
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.viewTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.55) + "px"
    })
  }

  // `<h6 class="trix-content">${speaker.speaker_bio}</h6>`

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
