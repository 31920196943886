import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewRadarCard", "viewTitle", "viewRadarCardBody", "previewCard", "editRadarBtn",
                    "deleteRadarBtn", "addRenewalBtn", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--radars--show`
    this.doViewHtml()
  }

  doViewHtml() {
    if (this.getControllerByIdentifier("financials--books--clients--radars--save")) {
      this.getControllerByIdentifier("financials--books--clients--radars--save").stopRefreshing()
    }
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewRadarCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Potencial Renovação Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewRadarCardBody"></div>
                </div>`

    this.getControllerByIdentifier("financials--books--clients--radars--dashboard").viewTarget.innerHTML = html
  }

  editRadar() {
    this.getControllerByIdentifier("financials--books--clients--radars--save").current_radar = this.current_radar
    this.getControllerByIdentifier("financials--books--clients--radars--save").actionMode = `edit`
    this.getControllerByIdentifier("financials--books--clients--radars--save").doFormHtml()
  }

  deleteRadar() {
    var r = confirm("Tem certeza que deseja apagar a Potencial Renovação?")
    this.deleteRadarBtnTarget.disabled = true

    if (r) {
      this.send_data = { current_user: {}, radar: {} }

      this.send_data.radar.id = this.current_radar.id
      this.send_data.radar.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.getControllerByIdentifier("financials--books--clients--radars--save").current_radar = this.current_radar
      this.getControllerByIdentifier("financials--books--clients--radars--save").send_data = this.send_data
      this.getControllerByIdentifier("financials--books--clients--radars--save").actionMode = `destroy`
      this.getControllerByIdentifier("financials--books--clients--radars--save").requestSave()

    }
  }

  doRadarHtml() {
    this.getControllerByIdentifier(`financials--books--clients--radars--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_delete && this.current_radar.stage != `gain` && this.application.current_user.super_admin) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteRadarBtn" data-action="click->${this.controllerName}#deleteRadar" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar do Radar</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editRadarBtn" data-action="click->${this.controllerName}#editRadar" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar do Radar</span>`
    } else {
      var editBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Potencial Renovação</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Cliente:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_radar.client_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Contrato:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_radar.contract_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Produto:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_radar.product_name_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Atual:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_radar.current_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor Objetivo:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_radar.goal_amount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Desconto Atual:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_radar.current_discount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Desconto Objetivo:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_radar.goal_discount)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Check em:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_radar.check_at_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Descrição</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_radar.description}
                              </td>
                            </tr>
                            <tr>
                              ${spaceRow}
                            </tr>
                            <tr>
                              <td colspan="8" class="f-065 align-middle px-0 text-left" data-${this.controllerName}-target="addRenewalBtn">
                                <div class="card pointer" data-action="click->${this.controllerName}#addRenewal">
                                  <div class="card-header p-1 text-center f-075 bg-primary">
                                    <span>Adicionar Nova Renovação</span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--clients--radars--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.65) + "px"

      if (controller.current_radar.has_renewal) {
        controller.addRenewalBtnTarget.remove()
        controller.deleteRadarBtnTarget.remove()
        controller.editRadarBtnTarget.remove()
      }

      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  addRenewal(ev) {
    this.application.clients.forEach(element => {
      if (element.id == this.current_radar.client_id) {
        this.current_client = element
      }
    })

    if (this.application.contracts.length > 0) {
      this.showRenewalModal()
    } else {
      this.getClientsContracts()
    }
  }

  getClientsContracts() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var data = { contract: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    var url = "/financials/books/clients/contracts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.contracts = response.data.cln
        controller.showRenewalModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showRenewalModal() {
    this.current_renewal = {}
    var modal = `financials--books--clients--renewals--save-modal`

    this.application.contracts.forEach(element => {
      if (element.id == this.current_radar.contract_id) {
        this.current_contract = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

      var controller = this
      new Promise(function (resolve) {
        resolve(document.body.insertAdjacentHTML("beforeend", html))
      }).then(() => {
        controller.getControllerByIdentifier(modal).controllerForm = controller
        controller.getControllerByIdentifier(modal).permission = controller.permission
        controller.getControllerByIdentifier(modal).current_client = controller.current_client
        controller.getControllerByIdentifier(modal).current_contract = controller.current_contract
        controller.getControllerByIdentifier(modal).current_radar = controller.current_radar
        controller.getControllerByIdentifier(modal).actionMode = "new"
        controller.getControllerByIdentifier(modal).open()
      })
  }

  saveRenewal() {
    if (this.current_renewal.id) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.send_data = { current_user: {}, radar: {}, tracker: { manual: false } }

      this.send_data.radar.id = this.current_radar.id
      this.send_data.radar.renewal_id = this.current_renewal.id

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      this.requestSave()
    }
  }

  requestSave() {

    var url = "/financials/books/clients/radars/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          controller.current_radar = response.data.cln
          controller.application.radars.forEach((element, i) => {
            if (element.id == controller.current_radar.id) {
              controller.application.radars.splice(controller.application.radars.indexOf(element), 1, controller.current_radar)
            }
          })
        }

        controller.doRadarHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, ``, controller)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
