import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submenu", "filter"]

  connect() {
    this.element[(str => { return str.split(/[-_]/).map(w => w.replace(/./, m => m.toUpperCase())).join('').replace(/^\w/, c => c.toLowerCase()) })(this.identifier)] = this
    // this.doSubMenuHtml()
  }

  board() {
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "calendar") {
      document.querySelector(`#stageContentIndexRow`).usersTasksBoardStage.contentRowTarget.innerHTML = `<div class="col px-1" style="overflow:scroll;" id="stageContentIndexCol" data-users--tasks--board--stage-target="contentCol"></div>`
      document.querySelector(`#calendarContentIndexRow`).usersTasksBoardCalendar.contentColTarget.remove()
      document.querySelector(`#stageContentIndexRow`).usersTasksBoardStage.connect()
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "squad") {
      window.open('/a/atividades/' + currentUser.slug, '_self')
    }
  }

  calendar() {
    if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "stage") {
      document.querySelector(`#calendarContentIndexRow`).usersTasksBoardCalendar.contentRowTarget.innerHTML = `<div class="col px-1" style="overflow:scroll;" id="calendarContentIndexCol" data-users--tasks--board--calendar-target="contentCol"></div>`
      document.querySelector(`#stageContentIndexRow`).usersTasksBoardStage.contentColTarget.remove()
      document.querySelector(`#calendarContentIndexRow`).usersTasksBoardCalendar.doCalendarConnection()
    } else if (document.querySelector(`#tasksSubMenu`).usersTasksBoardSubmenu.element.dataset.view == "squad") {
      window.open('/a/atividades/' + currentUser.slug, '_self')
    }
  }

  squads() {
    window.open('/a/minhas-squads/' + currentUser.slug, '_self')
  }

  doSubMenuHtml() {
    if ($("#currentUserPermisisonContainer").data("squad_entities").can_show) {
      var squadSubmenu = `<span class="dropdown-item py-1 pointer" data-action="click->users--tasks--board--submenu#squads">Squads</span>`
    } else {
      var squadSubmenu = ``
    }

    if (this.element.dataset.view == "squad") {
      var calendarSubmenu = ``
    } else {
      var calendarSubmenu = `<span class="dropdown-item py-1 pointer" data-action="click->users--tasks--board--submenu#calendar">Calendário</span>`
    }



    var html = `<div class="dropdown">
                  <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="receivementsMenuDrop" type="button"><i class="material-icons">apps</i></button>
                  <div aria-labelledby="receivementsMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                    <span class="dropdown-item py-1 pointer" data-action="click->users--tasks--board--submenu#board">Quadro Atividades</span>
                    ${calendarSubmenu}
                    ${squadSubmenu}
                  </div>
                </div>`

    this.submenuTarget.innerHTML = html
  }


}