import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewCertificateCard", "viewTitle", "viewCertificateCardBody", "previewCard", "previewTitle", "deleteCertificateBtn", "editCertificateBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--certificates--show`
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCertificateCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Certificado Digital Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewCertificateCardBody"></div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").viewTarget.innerHTML = html
  }

  editCertificate() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--save").current_certificate = this.current_certificate
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--save").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--save").doFormHtml()
  }

  deleteCertificate() {
    this.send_data = { current_user: {}, certificate: {} }

    this.send_data.certificate.id = this.current_certificate.id
    this.send_data.certificate.active = false
    this.send_data.current_user.current_user_id = this.application.current_user.id

      // this.requestDestroy()
  }

  doCertificateHtml() {
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--certificates--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions[this.permission].can_delete) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteCertificateBtn" data-action="click->${this.controllerName}#deleteCertificate" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Certificado</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions[this.permission].can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editCertificateBtn" data-action="click->${this.controllerName}#editCertificate" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Certificado</span>`
    } else {
      var editBtnHtml = ``
    }

    if (this.current_certificate.status == `active`) {
      var status = `<span class="mc-tooltip"><span class="material-icons md-sm md-success default">done</span><span class="mc-tooltiptext">${this.current_certificate.status_pretty}</span></span>`
    } else if (this.current_certificate.status == `overdue`) {
      var status = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger default">clear</span><span class="mc-tooltiptext">${this.current_certificate.status_pretty}</span></span>`
    }

    if (this.current_certificate.notify_alert) {
      var notifyAlert = `<span class="mc-tooltip badge badge-secondary-danger">Alerta de Vencimento</span>`
    } else {
      var notifyAlert = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Nome</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_certificate.name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Expira em:</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_certificate.expiration_date_pretty} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Status</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${status} <br>
                              </td>
                            </tr>
                            
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong></strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${notifyAlert}
                              </td>
                            </tr>
                            
                            

                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Certificado: ${controller.current_certificate.name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
