import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "postCardBody", "alias", "postRow", "5555", "tutorials", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--dashboards--main--alias`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()

    this.aliasPreloader()
    // this.tutorialsPreloader()
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  doAliasCard() {

    var managementCards = ``
    var personalCards = ``
    var operationalCards = ``

    var onboardsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Onboards</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/onboards" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">flight_land</span>
                              <span class="mc-tooltiptext">Onboards</span>
                            </div>
                          </div>
                        </div>`

    var ongoingsCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Ongoings</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/relacionamento-base" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">connecting_airports</span>
                              <span class="mc-tooltiptext">Ongoings</span>
                            </div>
                          </div>
                        </div>`

    var servicesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Produto</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/operacoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">category</span>
                              <span class="mc-tooltiptext">Produto</span>
                            </div>
                          </div>
                        </div>`

    var invoicesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Emissão de NFs</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/nfs-e-apuracoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">receipt_long</span>
                              <span class="mc-tooltiptext">Emissão de NFs</span>
                            </div>
                          </div>
                        </div>`

    var purchasesCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Contratações</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contratacoes" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">gavel</span>
                              <span class="mc-tooltiptext">Contratações</span>
                            </div>
                          </div>
                        </div>`

    var financialCard = `<div class="col-4 px-1 my-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-065">
                              <span>Financeiro</span>
                            </div>
                            <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/financeiro" data-action="click->${this.controllerName}#goToURL">
                              <span class="material-icons md-dark">monetization_on</span>
                              <span class="mc-tooltiptext">Financeiro</span>
                            </div>
                          </div>
                        </div>`
    
    var featureCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Funcionalidades</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/funcionalidades" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">account_box</span>
                            <span class="mc-tooltiptext">Funcionalidades</span>
                          </div>
                        </div>
                      </div>`

    var weeklyCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Semanal 1:1</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/semanal" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">event_available</span>
                            <span class="mc-tooltiptext">Semanal 1:1</span>
                          </div>
                        </div>
                      </div>`

    var ticketsCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Meus Tickets</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/tickets" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">task</span>
                            <span class="mc-tooltiptext">Meus Tickets</span>
                          </div>
                        </div>
                      </div>`

    var recruitmentCard = `<div class="col-4 px-1 my-2">
                            <div class="card">
                              <div class="card-header p-1 text-center f-065">
                                <span>Recrutamento</span>
                              </div>
                              <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/recrutamento-selecao" data-action="click->${this.controllerName}#goToURL">
                                <span class="material-icons md-dark">group_add</span>
                                <span class="mc-tooltiptext">Recrutamento</span>
                              </div>
                            </div>
                          </div>`

    var trackerCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Tracker</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/tracker" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">account_tree</span>
                            <span class="mc-tooltiptext">Tracker</span>
                          </div>
                        </div>
                      </div>`

    var companyCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Contas CNPJ</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contas-empresas" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">local_convenience_store</span>
                            <span class="mc-tooltiptext">Contas CNPJ</span>
                          </div>
                        </div>
                      </div>`

    var accountCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Contas CPF</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/contas-pessoas" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">people_alt</span>
                            <span class="mc-tooltiptext">Contas CPF</span>
                          </div>
                        </div>
                      </div>`

    var commercialCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Marketing & Vendas</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/marketing-vendas" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">stacked_line_chart</span>
                            <span class="mc-tooltiptext">Marketing & Vendas</span>
                          </div>
                        </div>
                      </div>`

    var performanceCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>App Performance</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/app-performance" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">query_stats</span>
                            <span class="mc-tooltiptext">App Performance</span>
                          </div>
                        </div>
                      </div>`

    var callsCard = `<div class="col-4 px-1 my-2">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065">
                            <span>Chamados</span>
                          </div>
                          <div class="card-body text-center py-2 mc-tooltip pointer" data-url="/a/chamados" data-action="click->${this.controllerName}#goToURL">
                            <span class="material-icons md-dark">build_circle</span>
                            <span class="mc-tooltiptext">Chamados</span>
                          </div>
                        </div>
                      </div>`
    
    personalCards += weeklyCard
    personalCards += ticketsCard
    // personalCards += callsCard

    if (this.application.permissions.user_works_trackers_projects.can_index) {
      personalCards += trackerCard
    }
    
    if (this.application.permissions.user_works_recruitments_vacancies.can_index) {
      personalCards += recruitmentCard
    }
    
    if (this.application.permissions.commercial_main_dashboard.can_index) {
      operationalCards += commercialCard
    }

    if (this.application.permissions.financial_settings.can_index) {
      operationalCards += financialCard
    }

    if (this.application.permissions.operations.can_index) {
      operationalCards += servicesCard
    }

    if (this.application.permissions.financial_purchase_entities.can_index) {
      operationalCards += purchasesCard
    }

    if (this.application.permissions.operation_onboard_entities.can_index) {
      operationalCards += onboardsCard
    }

    if (this.application.permissions.operation_ongoings.can_index) {
      operationalCards += ongoingsCard
    }

    if (this.application.permissions.medclinic_financial_receivables.can_index) {
      operationalCards += invoicesCard
    }

    if (this.application.permissions.user_permissions_features.can_index) {
      managementCards += featureCard
    }

    if (this.application.permissions.company_entities.can_index) {
      managementCards += companyCard
    }

    if (this.application.permissions.account_entities.can_index) {
      managementCards += accountCard
    }

    if (this.application.permissions.app_stats_tracker_general.can_index) {
      managementCards += performanceCard
    }

    var html = `<h6 class="mb-0 text-center f-bold f-085">Acesso Rápido</h6>
                <hr class="mt-1">
                <h6 class="mb-0 text-center f-065">Pessoal</h6>
                <hr class="mt-1">
                <div class="row">
                  ${personalCards}
                </div>
                <h6 class="mb-0 text-center f-065">Operações</h6>
                <hr class="mt-1">
                <div class="row">
                  ${operationalCards}
                </div>
                <h6 class="mb-0 text-center f-065">Configurações</h6>
                <hr class="mt-1">
                <div class="row">
                  ${managementCards}
                </div>`

    this.aliasTarget.innerHTML = html
  }

  doTutorialCard() {

    var html = `<h6 class="mb-0 text-center f-bold f-085">Descanso Médico</h6>
                <hr class="mt-1">`

    this.tutorials.forEach(element => {
      html += `<div class="row">
                  <div class="col-8 offset-2 px-2 my-2">
                    <div class="card">
                      <div class="card-header p-1 text-center f-065">
                        <span>${element.title}</span>
                      </div>
                      <div class="card-body text-center py-2 mc-tooltip pointer" data-url="${element.url}" data-action="click->${this.controllerName}#goToURL">
                        <span class="material-icons md-dark">manage_search</span>
                        <span class="mc-tooltiptext">${element.title}</span>
                      </div>
                    </div>
                  </div>
                </div>`
    })

    this.tutorialsTarget.innerHTML = html
  }

  tutorialsPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.tutorialsTarget.innerHTML = html)
    }).then(() => {
      controller.getTutorials()
    }) 
  }

  aliasPreloader() {
    var html = `<h6 class="mb-0 text-center">${this.loader}</h6>
                <hr class="mt-1">
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-12 px-2">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.aliasTarget.innerHTML = html
  }

  getTutorials() {
    var data = { post: { active: true, account_kind: this.application.current_user.account_kind }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/contents/posts/entities/list_tutorials"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.tutorials = response.data.cln
          controller.doTutorialCard()
          controller.doAliasCard()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
