import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "takerNameInput", "issuedOnInput", "channelDropdown", "channelDropdownBtn",
                    "channelInput", "channelList", "receivedAtInput", "amountInput", "netAmountInput", "saveBtn", "amountCheck",
                    "receivedAmountInput"]


  connect() {
    this.controllerName = `operations--products--clinics--financials--receivables--update-status`

    if (this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard")
    }
  }

  cancelSave() {
    if (this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu").doReceivablesCount()
      this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).doReceivablesDashboard()
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu").doReceivablesCount()
      this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).doReceivablesDashboard()
    }
  }

  saveReceivable() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { current_user: {}, receivable: {}, tracker: { log: true } }
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    if (this.actionMode == `edit`) {
      this.send_data.receivable.id = this.current_receivable.id
    }

    this.send_data.receivable.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.receivable.channel_name = this.channelInputTarget.innerText
    this.send_data.receivable.received_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.receivedAtInputTarget.value)
    this.send_data.receivable.received_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.receivedAmountInputTarget.value)
    this.send_data.receivable.receivement_status = `received`

    this.send_data.tracker.action = `updated`
    this.send_data.tracker.record_id = this.current_receivable.clinic_id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.observations = `Recebimento da Nota Fiscal da ${this.current_receivable.provider_name} para ${this.current_receivable.taker_name} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_receivable.total_amount)}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/financials/receivables/update_received_status"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receivable = response.data.cln
          controller.application.all_receivables.forEach((element, i) => {
            if (element.id == receivable.id) {
              controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1, receivable)
            }
          })
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.cancelSave()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_update) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveReceivable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-8 offset-2">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-3">
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="takerNameForm">Nome Tomador</label>
                                <input aria-describedby="takerNameFormHelp" class="form-control" id="takerNameForm" data-${this.controllerName}-target="takerNameInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="issuedOnForm">Data Emissão</label>
                                <input aria-describedby="issuedOnFormHelp" class="form-control form-valid-control" id="issuedOnForm" data-${this.controllerName}-target="issuedOnInput" placeholder="Data Emissão" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-8">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Banco</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="receivedAtForm">Data Recebimento</label>
                                <input aria-describedby="receivedAtFormHelp" class="form-control form-valid-control" id="receivedAtForm" data-${this.controllerName}-target="receivedAtInput" placeholder="Data Emissão" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-3">
                          
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountInputForm">Valor Bruto</label>
                                <input aria-describedby="amountInputFormHelp" class="form-control" id="amountInputForm" data-${this.controllerName}-target="amountInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="netAmountInputForm">Valor Líquido</label>
                                <input aria-describedby="netAmountInputFormHelp" class="form-control" id="netAmountInputForm" data-${this.controllerName}-target="netAmountInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>

                          <div class="col-3 d-flex align-items-center">
                            <div class="custom-control custom-checkbox custom-control-table pl-1 d-flex">
                              <input type="checkbox" class="custom-control-input" id="checkAmountForm" data-${this.controllerName}-target="amountCheck" data-action="click->${this.controllerName}#checkIsAmount">
                              <label class="custom-control-label pointer f-065 ml-3" for="checkAmountForm">Mesmo Valor</label>
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="receivedAmountInputForm">Valor Recebido</label>
                                <input aria-describedby="receivedAmountInputFormHelp" class="form-control" id="receivedAmountInputForm" data-${this.controllerName}-target="receivedAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.contentTarget.innerHTML = html)
    }).then(() => {
      var date = new Date(controller.application.current_date.year, controller.application.current_date.month, 0)
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.receivedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.receivedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, controller.current_receivable.issued_on)
      controller.receivedAtInputTarget.value = controller.current_receivable.issued_on_pretty

      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.formTitleTarget.innerText = `Editando Data Recebimento NF`

      controller.takerNameInputTarget.value = controller.current_receivable.taker_name
      controller.issuedOnInputTarget.value = controller.current_receivable.issued_on_pretty
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_receivable.total_amount)
      controller.netAmountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_receivable.net_amount)

      controller.takerNameInputTarget.disabled = true
      controller.issuedOnInputTarget.disabled = true
      controller.amountInputTarget.disabled = true
      controller.netAmountInputTarget.disabled = true

      controller.amountCheckTarget.checked = true
      controller.bindAmount()

      controller.setChannels()

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setChannels() {
    var html = ``

    this.channelDropdownTarget.value = this.current_receivable.channel_id
    this.channelInputTarget.innerText = this.current_receivable.channel_name
    this.channelInputTarget.dataset.channelId = this.current_receivable.channel_id

    this.application.channels.forEach(channel => {
      if (channel.status == `active`) {
        html += `<li data-channel-id="${channel.id}" data-action="click->${this.controllerName}#selectBank click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${channel.name}</li>`
      }
    })

    this.channelListTarget.innerHTML = html
  }

  selectBank(ev) {

    var channelId = ev.currentTarget.dataset.channelId

    this.application.channels.forEach(element => {
      if (element.id == channelId) {
        this.channelDropdownTarget.value = element.id
        this.channelInputTarget.innerText = `${element.name}`
        this.channelInputTarget.dataset.channelId = element.id
      }
    })
  }

  bindAmount() {
    var check = this.amountCheckTarget.checked

    if (check) {
      this.receivedAmountInputTarget.value = this.netAmountInputTarget.value
      this.receivedAmountInputTarget.disabled = true
    } else {
      this.receivedAmountInputTarget.value = `R$ 0,00`
      this.receivedAmountInputTarget.disabled = false
    }
  }

  checkIsAmount(ev) {
    this.bindAmount()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {
        if (controller.channelInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.receivedAtInputTarget.value == ``) {
          len += 1
        } else {
          var issuedOn = Date.parse(controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.issuedOnInputTarget.value))
          var receivedAt = Date.parse(controller.getControllerByIdentifier("app--helpers--date").transformAllKindDate(controller.receivedAtInputTarget.value))
          if (receivedAt < issuedOn) {
            len += 1
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Data de Recebimento é menor que Data de Emissão da NF.`, 2000)
          }
        }
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
