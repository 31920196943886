import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--webhooks--hooks--dashboard`
  }

  doWebhooksDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-7 pr-1" data-${this.controllerName}-target="list" data-controller="app--webhooks--hooks--index"></div>
                  <div class="col-5 pl-1" data-${this.controllerName}-target="view" data-controller="app--webhooks--hooks--save app--webhooks--hooks--show"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--regularizations--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--webhooks--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`app--webhooks--hooks--index`).permission = `app_webhook_urls`
      controller.getControllerByIdentifier(`app--webhooks--hooks--save`).permission = `app_webhook_urls`

      controller.getControllerByIdentifier(`app--webhooks--hooks--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`app--webhooks--hooks--save`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
