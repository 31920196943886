import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "indicators", "days", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--billings--dashboard`
  }

  doBillingsDashboard() {
    var html = `<div data-${this.controllerName}-target="mainDashboard">
                  <div class="row">
                    <div class="col-12 ml-auto pt-3 pb-1" data-${this.controllerName}-target="days" data-controller="financials--books--receivables--billings--days" style="overflow: auto;"></div>
                    <div class="col-12" data-${this.controllerName}-target="list" data-controller="financials--books--receivables--billings--index">Faturas ${this.application.current_date.name}</div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--receivables--dashboard--main").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--receivables--billings--days`).doMenuPreloader()
      controller.getControllerByIdentifier(`financials--books--receivables--billings--index`).permission = `financial_receivable_billings`
      controller.getControllerByIdentifier(`financials--books--receivables--billings--index`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
