import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "submessage", "progressUploadDiv", "progressUploadBar", "progressUploadCounter"]

  connect() {
    this.modalController = `app--helpers--modal`
    this.controllerName = `app--helpers--pre-modal`
  }

  closeProcessModal() {
    if (this.getControllerByIdentifier(this.modalController)) {
      this.getControllerByIdentifier(this.modalController).close()
    }
  }

  // openProcessModal(message = `Em processamento, aguarde!`) {
  openProcessModal(options = { message: `Em processamento, aguarde!`, submessage: ``, value: 0 }) {

    if (options.message == undefined) {
      options.message = `Em processamento, aguarde!`
    }
    if (options.submessage == undefined) {
      options.submessage = ``
    }
    if (options.value == undefined) {
      options.value = 0
    }

    this.doProcessModalHtml(options)
  }

  openProcessCountModalMessage(options = { message: `Estamos carregando as informações!`, submessage: ``, value: 0 }) {

    if (options.message == undefined) {
      options.message = `Estamos carregando as informações!`
    }
    if (options.submessage == undefined) {
      options.submessage = ``
    }
    if (options.value == undefined) {
      options.value = 0
    }

    this.doLoadModalHtml(options)
  }

  progressCount(value) {
    if (this.getControllerByIdentifier("app--helpers--modal")) {
      this.getControllerByIdentifier("app--helpers--modal").progressCount(value)
    }
  }

  progressCountMessage(options) {
    if (this.getControllerByIdentifier("app--helpers--modal")) {
      this.getControllerByIdentifier("app--helpers--modal").progressCountMessage(options)
    }
  }

  doProcessModalHtml(options) {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="${this.modalController}" data-${this.modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0">
                        <div class="row w-100 text-center">
                          <div class="col-12 px-0">
                            <h6 class="modal-title py-1 mx-auto"><strong class="f-075">${options.message}</strong></h6>
                          </div>
                          <div class="col-12 px-0">
                            <h6 class="mx-auto f-065" data-${this.modalController}-target="submessage">${options.submessage}</h6>
                          </div>
                        </div>
                      </div>
                      <div class="modal-body p-3" data-${this.modalController}-target="body">
                        <div class="row w-100 d-flex align-items-end d-none" data-${this.modalController}-target="progressUploadDiv">
                          <div class="col-12">
                            <div class="form-group my-0 text-center w-100">
                              <h7 class="progress">
                                <span class="progress_count"></span>
                              </h7>
                              <div class="progress" style="height: 6px;overflow: inherit;" data-${this.modalController}-target="progressUpload">
                                <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.modalController}-target="progressUploadBar"></div>
                              </div>
                              <span data-${this.modalController}-target="progressUploadCounter" class="d-block"></span>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="material-icons md-dark md-lg d-none" data-${this.modalController}-target="bottom">hourglass_bottom</span>
                            <span class="material-icons md-dark md-lg d-none" data-${this.modalController}-target="top">hourglass_top</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      if (options.value == 0) {
        if (controller.hasProgressUploadDivTarget) {
          controller.getControllerByIdentifier(controller.modalController).progressUploadDivTarget.remove()
        }
      } else {
        controller.getControllerByIdentifier(controller.modalController).progressCountMessage(options)
      }
    })
  }

  doProcessModalHtmlOld(options) {
    var html = `<div class="modal fade" data-hour="bottom" data-controller="${this.modalController}" data-${this.modalController}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-3" data-${this.modalController}-target="body">
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="f-075">${options.message}</span>
                          </div>
                        </div>
                        <div class="row my-2">
                          <div class="col-12 text-center">
                            <span class="material-icons md-dark md-lg d-none" data-${this.modalController}-target="bottom">hourglass_bottom</span>
                            <span class="material-icons md-dark md-lg d-none" data-${this.modalController}-target="top">hourglass_top</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    document.body.insertAdjacentHTML("beforeend", html)
  }

  stopSpin() {
    if (this.spinTimer) {
      clearInterval(this.spinTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
  
}