import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--helpers--dashboard`
  }

  doHelpersDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--managements--helpers--index"></div>
                  <div class="col-6 pl-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--managements--helpers--save operations--products--clinics--managements--helpers--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--show--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--show--submenu`).dashboardToggleBtn(`ASSISTENTES`)

      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--index`).permission = `medclinic_management_helpers`
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--show`).permission = `medclinic_management_helpers`
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--show`).info_permission = `medclinic_management_helper_infos`
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--show`).contact_permission = `medclinic_management_helper_contacts`
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--show`).profile_permission = `medclinic_management_helper_profiles`
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--save`).permission = `medclinic_management_helpers`

      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`operations--products--clinics--managements--helpers--show`).doViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
