import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "main", "view", "list", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `users--works--permissions--paths--dashboard`
  }

  doPathsDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8 pr-1" data-${this.controllerName}-target="list" data-controller="users--works--permissions--paths--index"></div>
                  <div class="col-4 pl-1" data-${this.controllerName}-target="view" data-controller="users--works--permissions--paths--save users--works--permissions--paths--show"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--permissions--entities--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`users--works--permissions--paths--index`).permission = `user_permissions_paths`
      controller.getControllerByIdentifier(`users--works--permissions--paths--save`).permission = `user_permissions_paths`
      controller.getControllerByIdentifier(`users--works--permissions--paths--show`).permission = `user_permissions_paths`
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
