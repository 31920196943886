import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "save", "filters", "title", "iceboxCard", "backlogCard", "currentCard", "doneCard",
                    "filters", "flow", "save", "filters", "title", "iceboxCard", "backlogCard", "currentCard", "doneCard",
                    "filterCard"]

  connect() {
    this.controllerName = `users--works--trackers--stories--index`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.sort = `started_at_to_time`
    this.doStoriesGrid()
  }

  allStories() {
    this.hasFilter = false
    this.filter = ``
    this.doFiltersHtml()
    this.doStoriesHtml()
  }

  doStoriesHeader() {
    if (this.application.permissions.user_works_trackers_stories.can_create && this.squadCanUpdate()) {
      var title = `<span class="ml-auto">Entregáveis</span>
                    <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#allStories">
                      <span class="material-icons md-sm md-dark">refresh</span>
                      <span class="mc-tooltiptext">Todos os Entregáveis</span>
                    </span>
                    <span class="mc-tooltip ml-2 pointer" data-action="click->${this.controllerName}#addStory" data-${this.controllerName}-target="addStoryBtn">
                      <span class="material-icons md-sm md-dark">add</span>
                      <span class="mc-tooltiptext">Adicionar Entregável</span>
                    </span>`
    } else {
      var title = `<span class="mx-auto">Entregáveis</span>
                    <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#allStories" data-${this.controllerName}-target="addProjectBtn">
                      <span class="material-icons md-sm md-dark">refresh</span>
                      <span class="mc-tooltiptext">Todos os Entregáveis</span>
                    </span>`
    }

    this.titleTarget.innerHTML = title
  }

  addStory() {
    this.getControllerByIdentifier("users--works--trackers--stories--save").actionMode = `new`
    this.getControllerByIdentifier("users--works--trackers--stories--save").doFormHtml()
  }

  doStoriesHtml() {
    var current_stories = []
    if (this.hasFilter) {
      this.application.project.stories.forEach(element => {
        if (element.stage == this.filter) {
          current_stories[current_stories.length] = element
        }
      })
    } else {
      current_stories = this.application.project.stories
    }

    var stories = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_stories, this.sort)

    if (stories == undefined || stories == [] || stories.length == 0) {
      var noData = `<div class="col-12 my-5 text-center">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-075 align-middle">Não há Entregáveis</span>
                    </div>`

      this.flowTarget.innerHTML = noData
    } else {
      this.flowTarget.innerHTML = ``

      stories.forEach(element => {
        this.flowTarget.insertAdjacentHTML("beforeend", this.storyPartial(element))
      })
    }
  }

  storyPartial(element) {

    var currentUnits = this.mapElements(element.units, `team_id`)
    var html = ``

    var currentOwner = false
    this.application.project.squads.forEach(squad => {
      if (squad.role == `owner` && squad.team_id == this.application.current_user.account_id) {
        currentOwner = true
      }
    })

    if (currentUnits.includes(this.application.current_user.account_id) || currentOwner) {
      if (this.application.permissions.user_works_trackers_stories.can_delete && this.application.current_squad.role == `owner`) {
        var removeBtnHtml = `<span class="ml-auto mc-tooltip">
                              <span class="material-icons md-sm pointer" data-id="${element.id}" data-action="click->${this.controllerName}#removeStory">cancel</span>
                              <span class="mc-tooltiptext">Remover Entregável</span>
                            </span>`
      } else {
        var removeBtnHtml = ``
      }


      html = `<div class="col-3 px-2 mb-3 storyCard" data-id="${element.id}" data-stage="${element.stage}" data-stage-number="${element.stage_number}" data-url="${element.story_path}" data-${this.controllerName}-target="card-${element.id} storyCard">
                <div class="card">
                  <div class="card-body px-0 pt-1 pb-0 f-075">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-12 px-2 mb-2 d-flex align-items-center">
                        <span class="f-065">${element.stage_pretty}</span>
                        ${removeBtnHtml}
                      </div>
                      <div class="col-12 px-2 pointer" data-action="click->${this.controllerName}#goToStoryPage">
                        <div class="card-show-dropdown">
                          <span class="f-075">${element.name}</span>
                          <div class="card-show-dropdown-content text-left">
                            <p class="mb-0 f-075">${element.description}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-2 mt-3 d-flex align-items-center mc-tooltip pointer" data-action="click->${this.controllerName}#goToStoryPage">
                        <span class="material-icons md-sm md-dark mr-1">list</span>
                        <span class="md-dark">${element.total_in_process}/${element.total_tickets}</span>
                        <span class="mc-tooltiptext">${element.total_in_process} em Aberto</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>`
    }

    return html
  }
  
  removeStory(ev) {
    var r = confirm(`Tem certeza que deseja remover o Entregável?`)
    if (r) {
      var storyId = ev.currentTarget.closest(".storyCard").dataset.id
      this.send_data = { current_user: {}, story: {} }

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.story.id = storyId
      this.send_data.story.active = false

      this.requestDestroyStory()
    }
  }


  doFiltersHtml() {

    // `<div class="col-12 px-0 mb-3">
    //   <p class="mb-0 text-center d-flex text-small">Filtros</hp>
    //   <hr class="my-1">
    // </div>`

    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-2 mb-3">
                    <div class="card pointer bg-primary" data-${this.controllerName}-target="noneCard filterCard" data-stage="none" data-action="click->${this.controllerName}#stageStory">
                      <div class="card-body text-center f-075 p-2">
                        <span>Todos</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2 mb-3">
                    <div class="card pointer" data-${this.controllerName}-target="iceboxCard filterCard" data-stage="icebox" data-action="click->${this.controllerName}#stageStory">
                      <div class="card-body text-center f-075 p-2">
                        <span>Geladeira</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2 mb-3">
                    <div class="card pointer" data-${this.controllerName}-target="backlogCard filterCard" data-stage="backlog" data-action="click->${this.controllerName}#stageStory">
                      <div class="card-body text-center f-075 p-2">
                        <span>Para Fazer</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2 mb-3">
                    <div class="card pointer" data-${this.controllerName}-target="currentCard filterCard" data-stage="current" data-action="click->${this.controllerName}#stageStory">
                      <div class="card-body text-center f-075 p-2">
                        <span>Fazendo</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2 mb-3">
                    <div class="card pointer" data-${this.controllerName}-target="doneCard filterCard" data-stage="done" data-action="click->${this.controllerName}#stageStory">
                      <div class="card-body text-center f-075 p-2">
                        <span>Finalizado</span>
                      </div>
                    </div>
                  </div>
                </div>`

    this.filtersTarget.innerHTML = html
  }

  stageStory(ev) {
    var stage = ev.currentTarget.dataset.stage
    
    if (stage == `none`) {
      this.hasFilter = false
    } else {
      this.filter = stage
      this.hasFilter = true
    }
    
    this.doStoriesHtml()

    this.filterCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    ev.currentTarget.classList.add(`bg-primary`)

    // if (stage == `icebox`) {
    //   this.iceboxCardTarget.classList.add("bg-primary")
    //   this.backlogCardTarget.classList.remove("bg-primary")
    //   this.currentCardTarget.classList.remove("bg-primary")
    //   this.doneCardTarget.classList.remove("bg-primary")
    // } else if (stage == `backlog`) {
    //   this.iceboxCardTarget.classList.remove("bg-primary")
    //   this.backlogCardTarget.classList.add("bg-primary")
    //   this.currentCardTarget.classList.remove("bg-primary")
    //   this.doneCardTarget.classList.remove("bg-primary")
    // } else if (stage == `current`) {
    //   this.iceboxCardTarget.classList.remove("bg-primary")
    //   this.backlogCardTarget.classList.remove("bg-primary")
    //   this.currentCardTarget.classList.add("bg-primary")
    //   this.doneCardTarget.classList.remove("bg-primary")
    // } else if (stage == `done`) {
    //   this.iceboxCardTarget.classList.remove("bg-primary")
    //   this.backlogCardTarget.classList.remove("bg-primary")
    //   this.currentCardTarget.classList.remove("bg-primary")
    //   this.doneCardTarget.classList.add("bg-primary")
    // }
  }

  doStoriesGrid() {

    var html = `<div class="row w-100">
                  <div class="col-12 mb-3" data-${this.controllerName}-target="filters">
                    ${this.bodyCardLoader}
                  </div>
                </div>
                <div class="row w-100">
                  <div class="col-12 mb-3">
                    <div class="row w-100" data-${this.controllerName}-target="flow">
                      ${this.bodyCardLoader}
                    </div>
                  </div>
                </div>
                <div class="row w-100">
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.storiesPreloader()
      this.hasFilter = false
      this.filter = ``
      if (this.application.project) {
        controller.doFiltersHtml()
        controller.doStoriesHtml()
      }
    }) 
  }

  storiesPreloader() {
    var html = `<div class="col-12 px-2 mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 mb-3">
                  ${this.bodyCardLoader}
                </div>
                <div class="col-12 px-2 mb-3">
                  ${this.bodyCardLoader}
                </div>`

    this.filtersTarget.innerHTML = html

    var html = `<div class="col-3 px-2 mb-3">
                  ${this.cardLoader}
                </div>
                <div class="col-3 px-2 mb-3">
                  ${this.cardLoader}
                </div>
                <div class="col-3 px-2 mb-3">
                  ${this.cardLoader}
                </div>
                <div class="col-3 px-2 mb-3">
                  ${this.cardLoader}
                </div>`

    this.flowTarget.innerHTML = html
  }

  requestDestroyStory() {
    var url = "/users/works/trackers/stories/destroy"
    var method = "DELETE"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var story = response.data.cln
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} apagou o Entregável ${story.name} do Projeto.`
          controller.getControllerByIdentifier(`users--works--trackers--projects--notes`).saveUpdateNote(message)
          controller.sendEmailNotification(story)
          controller.application.project.stories.forEach((element, i) => {
            if (element.id == story.id) {
              controller.application.project.stories.splice(controller.application.project.stories.indexOf(element), 1)
            }
          })
        }
        controller.doStoriesGrid()
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendEmailNotification(story) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.project_id = story.project_id
    this.send_data.notification.story_id = story.id
    this.send_data.notification.story_path = story.project.project_path
    this.send_data.notification.story_name = story.name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.sender_email = this.application.current_user.email
    this.send_data.notification.action = `apagou `
    this.send_data.notification.body = `o Entregável: ${story.name}, do projeto: ${story.project.name}`

    var url = "/users/works/trackers/stories/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  squadCanUpdate() {
    var currentOwner = false
    this.application.project.squads.forEach(squad => {
      if ((squad.role == `owner` || squad.role == `editor`) && squad.team_id == this.application.current_user.account_id) {
        currentOwner = true
      }
    })

    return currentOwner
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  goToStoryPage(ev) {
    var url = ev.currentTarget.closest(".storyCard").dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
