import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formBody", "errorMessage", "nextBtn", "accountPartnerBody", "companyPartnerBody", "formAccountPartnerBody",
                    "formCompanyPartnerBody", "accountCard", "companyCard", "accountRow", "companyRow"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--add--partners`
  }

  cancelSave() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").doMainGrid()
  }

  nextStep() {
    this.nextBtnTarget.disabled = true
    this.stopRefreshing()

    this.getControllerByIdentifier("operations--products--clinics--regularizations--add--contacts").doContactFormHtml()
  }

  doPartnersFormHtml() {
    this.current_product = this.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").current_product
    this.current_product.partners = {}
    var html = `<div class="col-10 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center py-2 f-065">
                      <div class="row w-100">
                        <div class="col-3 px-1">
                          <div class="card bg-primary pointer" data-${this.controllerName}-target="accountCard" data-feature="account" data-action="click->${this.controllerName}#toggleAccountCompany">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>PF Médica</strong></h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 px-1">
                          <div class="card pointer" data-${this.controllerName}-target="companyCard" data-feature="company" data-action="click->${this.controllerName}#toggleAccountCompany">
                            <div class="card-header py-0 f-065 text-center">
                              <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>PJ Médica</strong></h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formBody">

                      <div class="row" data-${this.controllerName}-target="accountRow">
                        <div class="col-4 my-4" data-controller="operations--products--clinics--regularizations--add--partners--account--index" data-${this.controllerName}-target="accountPartnerBody">
                        </div>
                        <div class="col-8 my-4" data-controller="operations--products--clinics--regularizations--add--partners--account--save" data-${this.controllerName}-target="formAccountPartnerBody">
                        </div>
                      </div>
                      <div class="row d-none" data-${this.controllerName}-target="companyRow">
                        <div class="col-4 my-4" data-controller="operations--products--clinics--regularizations--add--partners--company--index" data-${this.controllerName}-target="companyPartnerBody">
                        </div>
                        <div class="col-8 my-4" data-controller="operations--products--clinics--regularizations--add--partners--company--save" data-${this.controllerName}-target="formCompanyPartnerBody">
                        </div>
                      </div>
      
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                      <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#previousStep">Voltar</button>
                      <button disabled type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#nextStep" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      // controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.doPartnerCard()
    })
  }

  doPartnerCard() {
    if ((this.product == "medfat" && this.kind == "practice") || this.product == "medclinic" || this.product == "medpayroll" || this.product == "medconsulting") {
    } else {
      this.accountCardTarget.classList.add("bg-primary")
      this.accountCardTarget.classList.add("pointer")
      this.accountRowTarget.classList.remove("d-none")
      this.companyCardTarget.classList.add("bg-disabled")
      this.companyCardTarget.classList.add("none")
      this.companyCardTarget.classList.remove("bg-primary")
      this.companyRowTarget.classList.add("d-none")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshRegimeTimer = setInterval(function () {
      var len = 0

      if (controller.taxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.subRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.specialTaxRegimeInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.legalNatureInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateYearInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.dateMonthInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked == false && controller.fixedCheckboxTarget.checked == false) {
        len += 1
      }

      if (controller.aliquotCheckboxTarget.checked && controller.issAliquotTarget.value == ``) {
        len += 1
      }

      if (controller.fixedCheckboxTarget.checked && controller.issFixedTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveRegimeBtnTarget.disabled = false
      } else {
        controller.saveRegimeBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.regimeConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopRegimeRefreshing() {
    if (this.refreshRegimeTimer) {
      clearInterval(this.refreshRegimeTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
