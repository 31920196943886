import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewBankCard", "viewTitle", "viewBankCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--campaigns--dashboard--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewBankCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Campanha Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewBankCardBody"></div>
                </div>`

    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--main").viewTarget.innerHTML = html
  }

  editBank() {
    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").current_bank = this.current_bank
    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").actionMode = "edit"
    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").doFormHtml()
    // if (this.application.permissions.marketing_campaign_entities.can_update && this.current_bank.status == `active`) {  
    // } else {
    //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Essa conta está Inativa e não pode ser Editada`, 5000)
    // }
  }

  deleteBank() {
    this.send_data = { current_user: {}, bank: {} }

    this.actionMode = `delete`
    this.send_data.bank.id = this.current_bank.id
    this.send_data.bank.active = false

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja inativar a Campanha ${this.current_bank.name}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").send_data = this.send_data
      this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").actionMode = `delete`
      this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--save").requestSave()
    }
  }

  doBankHtml() {
    this.getControllerByIdentifier(`commercial--marketing--campaigns--dashboard--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.marketing_campaign_entities.can_delete && this.current_bank.status) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteBankBtn" data-action="click->${this.controllerName}#deleteBank" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Despesa</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.marketing_campaign_entities.can_update && this.current_bank.status) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editBankBtn" data-action="click->${this.controllerName}#editBank" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Despesa</span>`
    } else {
      var editBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Banco</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.name}
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Número</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.number}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Agência</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.branch} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Conta</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.account}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>PIX</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.pix} <br>
                              </td>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Status</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.status_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Tipo</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_bank.kind_pretty} <br>
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--main").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Campanha | ${controller.current_bank.name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
