import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "main", "mainCard", "indicatorHeader", "indicators", "teamDropdown", "teamInput", "index"]

  connect() {
    this.controllerName = `operations--dashboards--indicators`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.calculation = {}
  }


  doIndicatorsCalculation() {

    this.calculation.total_tax_return = 0
    this.calculation.total_booking = 0
    this.calculation.total_legal = 0
    this.calculation.total_billing = 0
    this.calculation.total_receivement = 0
    this.calculation.total_tax_filing = 0
    this.calculation.total_payroll = 0


    this.application.doctors.forEach(doctor => {

      if (doctor.has_tax_return) {
        this.calculation.total_tax_return += 1
      }

      if (doctor.has_booking) {
        this.calculation.total_booking += 1
      }

      if (doctor.has_clinic) {
        this.calculation.total_legal += 1
      }

      if (doctor.has_billing) {
        this.calculation.total_billing += 1
      }

      if (doctor.has_receivement) {
        this.calculation.total_receivement += 1
      }

      if (doctor.has_tax_filing) {
        this.calculation.total_tax_filing += 1
      }

      if (doctor.has_payroll) {
        this.calculation.total_payroll += 1
      }
    });
    this.doIndicatorsHtml()
  }

  doIndicatorsHtml() {
    var html = `<div class="col-2 pl-0 pr-2">
                  <div class="card pointer" data-product="tax_filing" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader tax_filing">
                      <span>Declaração IRPF</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_tax_filing}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="booking" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader booking">
                      <span>Livro-Caixa</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_booking}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="legal" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader legal">
                      <span>PJ Médica</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_legal}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="payroll" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader payroll">
                      <span>DP</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_payroll}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-2">
                  <div class="card pointer" data-product="receivement" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader receivement">
                      <span>GR</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_receivement}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 pl-2 pr-0">
                  <div class="card pointer" data-product="billing" data-action="click->${this.controllerName}#productFilter">
                    <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader billing">
                      <span>Faturamento</span>
                    </div>
                    <div class="card-body text-center f-065 px-0 py-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 text-center f-075 py-2">
                          ${this.calculation.total_billing}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                `

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.indicatorsTarget.innerHTML = html)
    }).then(() => {
      // controller.indicatorHeaderTargets[0].classList.add(`bg-primary`)
    })
  }

  productFilter(ev) {
    var product = ev.currentTarget.dataset.product
    this.getControllerByIdentifier(`operations--dashboards--list`).filter = product
    this.getControllerByIdentifier(`operations--dashboards--list`).hasFilter = true
    this.getControllerByIdentifier(`operations--dashboards--list`).doDataTable()

    this.indicatorHeaderTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    });

    this.nameTarget(product).classList.add(`bg-primary`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}