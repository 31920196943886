import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "formCard", "formTitle", "formCardBody", "saveBtn", "storyName", "storyDescription",
                    "precedentDropdown", "precedentDropdownBtn", "precedentInput", "precedentList",
                    "precedentBodyTable", "precedentDropdownBtn", "precedentInput", "precedentList"]

  connect() {
    this.controllerName = `users--works--trackers--stories--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.formTarget.innerHTML = ``
    this.getControllerByIdentifier("users--works--trackers--stories--index").doStoriesGrid()
  }

  saveStory() {
    this.send_data = { current_user: {}, story: {}, relation: {}, note: {}, unit: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.application.project.squads.forEach(squad => {
      if (this.application.current_user.account_id == squad.team_id) {
        this.send_data.unit.squad_id = squad.id
        this.send_data.unit.team_id = squad.team_id
        this.send_data.unit.role = squad.role
      }
    })

    this.send_data.story.project_id = this.application.project.id
    this.send_data.story.name = this.storyNameTarget.value.trim()
    this.send_data.story.description = this.storyDescriptionTarget.value.trim()
    this.send_data.story.status = `in_process`
    this.send_data.story.stage = `icebox`
    this.send_data.story.started_at = new Date()
    this.send_data.relation.has = this.relations.has
    this.send_data.relation.objects = this.relations.objects

    if (this.actionMode == `new`) {
      this.send_data.note.account_id = this.application.current_user.account_id
      this.send_data.note.domain_type = `tracker_stories`
      this.send_data.note.owner_id = this.application.current_user.account_id
      this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
      this.send_data.note.sharing = `internal`
      this.send_data.note.kind = `updating`
      this.send_data.note.date = new Date()
      this.send_data.note.body = this.storyDescriptionTarget.value.trim()
      this.send_data.note.title = `Descrição Entregável`
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_works_trackers_stories`

    this.requestSave()
  }

  requestSave() {
    var url = "/users/works/trackers/stories/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var story = response.data.cln
          controller.application.project.stories[controller.application.project.stories.length] = story
          controller.sendEmailNotification(story)
          var message = `${controller.application.current_user.name.split(" ").shift()} ${controller.application.current_user.name.split(" ").pop()} adicionou o Entregável ${story.name} no Projeto.`
          controller.getControllerByIdentifier(`users--works--trackers--projects--notes`).saveUpdateNote(message)
          // controller.application.project.stories.forEach((element, i) => {
          //   if (element.id == story.id) {
          //     controller.application.project.stories.splice(controller.application.project.stories.indexOf(element), 1, story)
          //   }
          // })
          controller.cancelSave()
          controller.getControllerByIdentifier(`users--works--trackers--projects--show`).getPrecedents()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendEmailNotification(story) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.project_id = story.project_id
    this.send_data.notification.story_id = story.id
    this.send_data.notification.story_path = story.project.project_path
    this.send_data.notification.story_name = story.name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.sender_email = this.application.current_user.email
    this.send_data.notification.action = `adicionou`
    this.send_data.notification.body = `novo Entregável: ${story.name}, no projeto: ${story.project.name}`

    var url = "/users/works/trackers/stories/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.user_works_trackers_stories.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveStory" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Entregável</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="storyName" type="text" placeholder="Nome" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Descrição</label>
                                <textarea rows="5" autofocus data-${this.controllerName}-target="storyDescription" class="form-control p-1 f-075" type="text" required></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pl-2 mt-2 pt-1">
                        <div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search">
                          <div class="card-header d-flex align-items-center f-065 p-0">
                            <input class="form-control f-070 w-100 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar Precedentes" type="text">
                          </div>
                          <div class="card-body p-0" style="overflow:auto;">
                            <div class="row">
                              <div class="col p-0">
                                <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                                  <thead>
                                    <tr>
                                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-90 align-middle"></td>
                                    </tr>
                                  </thead>
                                  <tbody data-${this.controllerName}-target="precedentBodyTable"></tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.relations = { has: false, objects: [] }
      controller.listPrecedents()
      // controller.opportunityProspectorInputTarget.value = controller.current_opportunity.prospector_name
      // controller.opportunityProspectorInputTarget.dataset.filter = controller.current_opportunity.prospector_id
      // controller.opportunityProspectorInputTarget.dataset.text = controller.current_opportunity.prospector_name
      // controller.opportunityCloserInputTarget.value = controller.current_opportunity.closer_name
      // controller.opportunityCloserInputTarget.dataset.filter = controller.current_opportunity.closer_id
      // controller.opportunityCloserInputTarget.dataset.text = controller.current_opportunity.closer_name
      // controller.opportunityStageInputTarget.value = controller.current_opportunity.stage_pretty
      // controller.opportunityStageInputTarget.dataset.filter = controller.current_opportunity.stage
      // controller.listStage()
      // controller.listCloser()
      // controller.listProspector()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  listPrecedents() {
    var data = this.application.precedents

    this.precedentBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Entregáveis</span>
                      </td>
                    </tr>`

      this.precedentBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.precedentBodyTableTarget.insertAdjacentHTML("beforeend", this.precedentTablePartial(element, data.length))
      });
    }
  }

  precedentTablePartial(element, length) {

    var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.precedent_id}" data-${this.controllerName}-target="checkboxPrecedent checkboxPrecedent-${element.precedent_id}" data-action="click->${this.controllerName}#selectPrecedent">
                    <label class="custom-control-label pointer" for="check-${element.precedent_id}"></label>
                  </div>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.precedent_id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.precedent_id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${check}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.precedent_name}</td>
                  </tr>`

    return rowHtml
  }

  selectPrecedent(ev) {
    var precedentId = ev.currentTarget.closest(".itemRow").dataset.id
    var checkbox = this.nameTarget(`checkboxPrecedent-${precedentId}`).checked
    
    this.application.precedents.forEach(element => {
      if (precedentId == element.precedent_id) {
        if (checkbox) {
          this.relations.objects[this.relations.objects.length] = element
        } else {
          this.relations.objects.forEach((element, i) => {
            if (element.precedent_id == precedentId) {
              this.relations.objects.splice(this.relations.objects.indexOf(element), 1)
            }
          })
        }
      }
    })

    if (this.relations.objects.length > 0) {
      this.relations.has = true
    } else {
      this.relations.has = false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.storyNameTarget.value == ``) {
        len += 1
      }

      if (controller.storyDescriptionTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
