import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewFeatureCard", "viewTitle", "viewFeatureCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `users--works--permissions--features--show`
    this.doViewHtml()
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewFeatureCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Funcionalidade Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewFeatureCardBody"></div>
                </div>`

    this.getControllerByIdentifier("users--works--permissions--features--dashboard").viewTarget.innerHTML = html
  }

  editFeature() {
    if (this.application.permissions.user_permissions_features.can_update) {
      this.getControllerByIdentifier("users--works--permissions--features--save").current_feature = this.current_feature
      this.getControllerByIdentifier("users--works--permissions--features--save").actionMode = "edit"
      this.getControllerByIdentifier("users--works--permissions--features--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Ação não permitida`, 5000)
    }
  }

  copyFeature() {
    if (this.application.permissions.user_permissions_features.can_create) {
      this.getControllerByIdentifier("users--works--permissions--features--save").current_feature = this.current_feature
      this.getControllerByIdentifier("users--works--permissions--features--save").actionMode = "copy"
      this.getControllerByIdentifier("users--works--permissions--features--save").doFormHtml()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Ação não permitida`, 5000)
    }
  }

  deleteFeature() {
    this.send_data = { current_user: {}, feature: {} }

    this.actionMode = `delete`
    this.send_data.feature.id = this.current_feature.id
    this.send_data.feature.active = false
    this.send_data.feature.status = `inactive`

    this.send_data.current_user.current_user_id = this.application.current_user.id

    var r = confirm(`Tem certeza que deseja inativar a Funcionalidade ${this.current_feature.name}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.getControllerByIdentifier("users--works--permissions--features--save").send_data = this.send_data
      this.getControllerByIdentifier("users--works--permissions--features--save").actionMode = `delete`
      this.getControllerByIdentifier("users--works--permissions--features--save").requestSave()
    }
  }

  doFeatureHtml() {
    this.getControllerByIdentifier(`users--works--permissions--features--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.application.permissions.user_permissions_features.can_delete && this.current_feature.total_permissions == 0) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteFeatureBtn" data-action="click->${this.controllerName}#deleteFeature" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Funcionalidade</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.user_permissions_features.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editFeatureBtn" data-action="click->${this.controllerName}#editFeature" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Funcionalidade</span>`
    } else {
      var editBtnHtml = ``
    }

    if (this.application.permissions.user_permissions_features.can_create) {
      var copyBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="copyFeatureBtn" data-action="click->${this.controllerName}#copyFeature" type="button">
                            <span class="material-icons f-1p25">copy_all</span>
                          </button>
                          <span class="mc-tooltiptext">Copiar Funcionalidade</span>`
    } else {
      var copyBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle"></h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${copyBtnHtml}
                    </div>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Master</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_feature.master}
                              </td>
                            <tr>
                            </tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Grupo</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                ${this.current_feature.group}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Enum</strong>
                              </th>
                              <td colspan="7" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_feature.name}" data-action="click->app--helpers--copy#copy">${this.current_feature.name}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span> <br>
                              </td>
                            <tr>
                            </tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Nome</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_feature.humanize}" data-action="click->app--helpers--copy#copy">${this.current_feature.humanize}</span>
                                  <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                                </span> <br>
                              </td>
                            </tr>
                            </tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Anotações</strong>
                              </th>
                              <td colspan="8" class="f-065 align-middle px-0 text-left">
                                <span class="mb-0 f-065 mc-tooltip grab">
                                  <span data-controller="app--helpers--copy" data-copy="${this.current_feature.notes}" data-action="click->app--helpers--copy#copy">${this.current_feature.notes}</span>
                                  <span class="mc-tooltiptext">Clique para Copiar</span>
                                </span> <br>
                              </td>
                            </tr>
                            
                            <tr>
                              ${spaceRow}
                            </tr>

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--permissions--features--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.previewTitleTarget.innerText = `Funcionalidade | ${controller.current_feature.name}`
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
