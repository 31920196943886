import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "optionCard", "4444", "totalCount", "count", "countTooltip", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--receivables--entities--filter`
    this.setFilters()
  }

  doFilterHtml() {
    var optionsHtml = `<div class="row mb-4">
                        <div class="col-12 px-0 text-center">
                          <h6 class="mt-0">Filtros</h6>
                          <hr>
                        </div>
                      </div>`

    this.filters.forEach(option => {
      optionsHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="card pointer" data-option="${option.name}" data-action="click->${this.controllerName}#showSuboptions" data-${this.controllerName}-target="${option.name}-Card">
                            <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="${option.name}-HeaderCard">
                              <span>${option.name_pretty}</span>
                            </div>
                          </div>
                        </div>
                      </div>`
    })

    this.getControllerByIdentifier("financials--books--receivables--entities--dashboard").filterTarget.innerHTML = optionsHtml
  }

  showSuboptions(ev) {
    var optionsHtml = ``
    this.option_name = ev.currentTarget.dataset.option

    this.filters.forEach(element => {
      if (element.name == this.option_name) {
        this.option_name_pretty = element.name_pretty
        this.sub_options = element.options
        this.nameTarget(`${this.option_name}-Card`).classList.add("bg-primary")
      } else {
        this.nameTarget(`${this.option_name}-Card`).classList.remove("bg-primary")
      }
    })

    var optionsHtml = `<div class="row mb-4">
                        <div class="col-12 px-0 text-center">
                          <h6 class="mt-0">Filtros</h6>
                          <hr>
                        </div>
                      </div>`

    optionsHtml += `<div class="row my-4">
                      <div class="col-12 px-0">
                        <div class="card pointer bg-primary">
                          <div class="card-header p-1 text-center f-065 f-bold">
                            <span>${this.option_name_pretty}</span>
                          </div>
                        </div>
                      </div>
                    </div>`

    this.sub_options.forEach(option => {
      optionsHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="card pointer" data-option="${option.name}" data-action="click->${this.controllerName}#showData" data-${this.controllerName}-target="optionCard ${option.name}-OptionCard">
                            <div class="card-header p-1 py-2 text-center f-065 f-bold mc-tooltip" data-${this.controllerName}-target="${option.name}-HeaderCard">
                              
                            <span data-${this.controllerName}-target="${option.name}-count">${option.name_pretty}</span>
                              
                              <span class="mc-tooltiptext" data-${this.controllerName}-target="${option.name}-countTooltip"></span>
                            </div>
                          </div>
                        </div>
                      </div>`
    })

    // var ticketBtn = `<span class="mc-tooltip">
    //                   <span data-${this.controllerName}-target="submenuBtn count">Tickets</span>
    //                   <span class="mc-tooltiptext" data-${this.controllerName}-target="countTooltip"></span>
    //                   <span>${option.name_pretty}</span>
    //                 </span>`
                    

    optionsHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="pointer bg-outline-primary" data-action="click->${this.controllerName}#cancelFilter">
                            <div class="card-header p-1 text-center f-065 f-bold">
                              <span>Voltar</span>
                            </div>
                          </div>
                        </div>
                      </div>`

    this.getControllerByIdentifier("financials--books--receivables--entities--dashboard").filterTarget.innerHTML = optionsHtml
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).showClearBtn()
  }

  showData(ev) {
    this.filter_option = ev.currentTarget.dataset.option

    this.optionCardTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    this.sub_options.forEach(element => {
      if (element.name == this.filter_option) {
        this.filter_option_pretty = element.name_pretty
        this.nameTarget(`${this.filter_option}-OptionCard`).classList.add("bg-primary")
      }
    })

    this.doBadgeCounts()

    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter = true
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter_field = this.option_name
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter_option = this.filter_option
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).doDataTable()
    
  }

  doBadgeCounts() {

    var current_receivables = []
    var totalReceivableCount = 0
    var receivableCount = 0

    if (this.option_name == `kind`) {

      this.application.addons.forEach(addon => {
        var addonReceivableDateIds = this.mapElements(addon.receivables, `date_id`)

        if (addon[this.option_name] == this.filter_option) {
          if (addon.started_token <= this.application.current_date.token) {
            totalReceivableCount += 1
            if (addonReceivableDateIds.includes(this.application.current_date.id)) {
              receivableCount += 1
            }
          }
        }
      })

      if (this.nameTarget(`${this.filter_option}-count`)) {
        var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="${this.filter_option}-totalCount">${Number(totalReceivableCount) - Number(receivableCount)}</span>`
        if (this.nameTarget(`${this.filter_option}-totalCount`) && this.nameTarget(`${this.filter_option}-totalCount`)[0]) {
          this.nameTarget(`${this.filter_option}-totalCount`).totalCountTargets[0].remove()
        }
        this.nameTarget(`${this.filter_option}-count`).insertAdjacentHTML("beforeend", ticketBadgeHtml)
        this.nameTarget(`${this.filter_option}-countTooltip`).innerText = `${Number(totalReceivableCount) - Number(receivableCount)}/${totalReceivableCount} Recebíveis(s) em Aberto`
      }

    }

   

  }

  cancelFilter() {
    this.doFilterHtml()

    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter = false
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter_field = ``
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).filter_option = ``
    this.getControllerByIdentifier(`financials--books--receivables--entities--index`).doDataTable()
  }

  setFilters() {
    this.filters = [
                     {
                       name: `product_name`,
                       name_pretty: `Produtos`,
                       options: [
                                  {
                                    name: `medclinic`,
                                    name_pretty: `PJ Médica`,
                                  },
                                  {
                                    name: `medbooking`,
                                    name_pretty: `Livro-Caixa`,
                                  },
                                  {
                                    name: `medfiling`,
                                    name_pretty: `Declaração IRPF`,
                                  },
                                  {
                                    name: `medbiling`,
                                    name_pretty: `Faturamento`,
                                  },
                                  {
                                    name: `medreceivement`,
                                    name_pretty: `Gestão de Recebimentos`,
                                  },
                                  {
                                    name: `medpayroll`,
                                    name_pretty: `Folha de Pagamento`,
                                  },
                                  {
                                    name: `medconsulting`,
                                    name_pretty: `Consultoria`,
                                  },
                                ]
                     },
                     {
                       name: `kind`,
                       name_pretty: `Tipo`,
                       options: [
                                  {
                                    name: `revenue`,
                                    name_pretty: `Receita`,
                                  },
                                  {
                                    name: `refund`,
                                    name_pretty: `Reembolso`,
                                  },
                                  {
                                    name: `discount`,
                                    name_pretty: `Desconto`,
                                  },
                                  {
                                    name: `reversal`,
                                    name_pretty: `Estorno`,
                                  },
                                  {
                                    name: `fine_interests`,
                                    name_pretty: `Multa e Juros`,
                                  },
                                ]
                     },
                     {
                       name: `subkind`,
                       name_pretty: `Subtipo`,
                       options: [
                                  {
                                    name: `revenue_amount`,
                                    name_pretty: `Prestação de Serviços`,
                                  },
                                  {
                                    name: `refund_amount`,
                                    name_pretty: `Reembolso de Valores`,
                                  },
                                  {
                                    name: `commercial_discount`,
                                    name_pretty: `Comercial`,
                                  },
                                  {
                                    name: `retention_discount`,
                                    name_pretty: `Retenção`,
                                  },
                                  {
                                    name: `error_discount`,
                                    name_pretty: `Erro Operacional`,
                                  },
                                  {
                                    name: `financial_discount`,
                                    name_pretty: `Financeiro`,
                                  },
                                  {
                                    name: `reversal_amount`,
                                    name_pretty: `Estorno`,
                                  },
                                  {
                                    name: `fine_amount`,
                                    name_pretty: `Multa`,
                                  },
                                  {
                                    name: `interest_amount`,
                                    name_pretty: `Juros`,
                                  },
                                ]
                     },
                     {
                       name: `plan`,
                       name_pretty: `Ocorrência`,
                       options: [
                                  {
                                    name: `monthly`,
                                    name_pretty: `Mensal`,
                                  },
                                  {
                                    name: `yearly`,
                                    name_pretty: `Anual`,
                                  },
                                  {
                                    name: `unique`,
                                    name_pretty: `Único`,
                                  },
                                ]
                     },
                     {
                       name: `has_receivable`,
                       name_pretty: `Recebível`,
                       options: [
                                  {
                                    name: `without`,
                                    name_pretty: `Sem`,
                                  },
                                  {
                                    name: `with`,
                                    name_pretty: `Ok`,
                                  },
                                ]
                     },
                   ]

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}