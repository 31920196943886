import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "closingInput", "paidDateInput", "channelDropdown", "channelDropdownBtn", "channelInput",
                    "channelList", "saveBtn", "totalAmountInput", "descriptionInput", "principalAmountInput", "interestsAmountInput",
                    "methodType", "methodLine", "finesAmountInput", "chartAccountInput"]

  connect() {
    this.controllerName = `financials--books--cards--bills--update-closing-date`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    // this.open()
  }

  open(billId) {
    this.refreshSaveBtn()
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
    this.setCurrentBill(billId)

    var date = new Date(this.current_bill.year, this.current_bill.month, 1)
    this.getControllerByIdentifier("app--helpers--pickdate").pickUntilDate($(this.closingInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)    

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  setCurrentBill(billId) {
    this.application.bills.forEach(element => {
      if (element.id == billId) {
        this.current_bill = element
      }
    })
  }

  updateClosingDate() {
    this.send_data = { current_user: {}, bill: {}, tracker: { log: true } }

    this.send_data.bill.id = this.current_bill.id
    this.send_data.bill.closing_date = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.closingInputTarget.value)
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_card_bills`

    this.send_data.tracker.record_id = this.current_bill.id
    this.send_data.tracker.record_type = `financial_payable_card_bills`
    this.send_data.tracker.action = `update`
    this.send_data.tracker.observations = `Atualização Data Fechamento Fatura ${this.current_bill.name}`

    var r = confirm(`Confirma a mudança na Data de Fechamento?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
      this.requestSave()
    }
  }

  requestSave() {
    var url = "/financials/books/cards/bills/update"
    var method = "PUT"
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var bill = response.data.cln
          controller.application.bills.forEach((element, i) => {
            if (element.id == bill.id) {
              controller.application.bills.splice(controller.application.bills.indexOf(element), 1, bill)
            }
          })
        }

        controller.getControllerByIdentifier(`financials--books--cards--bills--show`).current_bill = bill
        controller.getControllerByIdentifier(`financials--books--cards--bills--show`).showBill()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        controller.close()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error, `Tivemos problemas para atualizar o Pagamento`)
        controller.close()
      })
  }

  currencyMask(ev) {
    this.controllerNumber.changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.closingInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}