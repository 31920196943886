import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewTakerCard", "viewTitle", "viewTakerCardBody", "principalShow", "contactShow", "principalTab", "infoTab", "contactTab", "profileTab",
    "viewTakerCardFooter", "infoShow", "profileShow"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--takers--show`

    if (this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--financials--takers--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--takers--dashboard")
    }
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewTakerCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum Tomador Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewTakerCardBody" id="cardContactBody">
                  </div>
                </div>`

    this.dashboardController.viewTarget.innerHTML = html
  }

  doTakerHtml() {
    var html = `<ul class="nav nav-justified nav-tabs" role="tablist">
                  <li class="nav-item" data-${this.controllerName}-target="principalTab">
                    <a data-action="click->${this.controllerName}#showPrincipal" aria-controls="principal" aria-selected="true" class="f-065 nav-link nav-dark active" data-toggle="tab" href="#principal" id="principal-tab" role="tab">Principal</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="infoTab">
                    <a data-action="click->${this.controllerName}#showInfo" aria-controls="info" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#info" id="info-tab" role="tab">Informações</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="contactTab">
                    <a data-action="click->${this.controllerName}#showContact" aria-controls="contact" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#contact" id="contact-tab" role="tab">Contatos</a>
                  </li>
                  <li class="nav-item" data-${this.controllerName}-target="profileTab">
                    <a data-action="click->${this.controllerName}#showProfile" aria-controls="profile" aria-selected="true" class="f-065 nav-link nav-dark" data-toggle="tab" href="#profile" id="profile-tab" role="tab">Perfil</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div aria-labelledby="principal-tab" class="tab-pane fade show active" role="tabpanel" id="principal"><div class="list-group" data-${this.controllerName}-target="principalShow"></div></div>
                  <div aria-labelledby="info-tab" class="tab-pane fade" role="tabpanel" id="info"><div class="list-group" data-${this.controllerName}-target="infoShow"></div></div>
                  <div aria-labelledby="contact-tab" class="tab-pane fade" role="tabpanel" id="contact"><div class="list-group" data-${this.controllerName}-target="contactShow"></div></div>
                  <div aria-labelledby="profile-tab" class="tab-pane fade" role="tabpanel" id="profile"><div class="list-group" data-${this.controllerName}-target="profileShow"></div></div>
                </div>`

    this.viewTitleTarget.innerText = this.current_taker.name
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.viewTakerCardBodyTarget.innerHTML = html)
    }).then(() => {
      controller.viewTakerCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.showPrincipal()
      if (!controller.application.permissions[controller.contact_permission].can_index) {
        controller.contactTabTarget.children[0].classList.add("disabled")
        controller.contactTabTarget.children[0].innerHTML = `<s>Contatos</s>`
      }

      if (controller.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")) {
        if (!controller.application.permissions[controller.profile_permission].can_index) {
          controller.profileTabTarget.children[0].classList.add("disabled")
          controller.profileTabTarget.children[0].innerHTML = `<s>Perfil</s>`
        }

        if (!controller.application.permissions[controller.info_permission].can_index) {
          controller.infoTabTarget.children[0].classList.add("disabled")
          controller.infoTabTarget.children[0].innerHTML = `<s>Informações</s>`
        }
      } else if (controller.getControllerByIdentifier("operations--products--clinics--clients--takers--dashboard")) {
        controller.infoTabTarget.remove()
        controller.profileTabTarget.remove()
      } else if (controller.getControllerByIdentifier("operations--products--clinics--financials--takers--dashboard")) {
        // controller.infoTabTarget.remove()
        controller.profileTabTarget.remove()
      }

    })
  }

  showPrincipal() {
    var html = `<div data-controller="operations--products--clinics--regularizations--takers--principal" data-operations--products--clinics--regularizations--takers--principal-target="main"></div>`
    $(".nav-tabs-indicator").addClass("nav-dark")

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.principalShowTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--takers--principal").permission = this.permission
      controller.getControllerByIdentifier("operations--products--clinics--regularizations--takers--principal").doPrincipalHtml()
    })
  }

  showInfo() {
    var html = `<div data-controller="users--accounts--documents--info" data-users--accounts--documents--info-target="main"></div>`
    if (this.application.permissions[this.info_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.infoShowTarget.innerHTML = html)
      }).then(() => {
        controller.getControllerByIdentifier(`users--accounts--documents--info`).current_account = controller.current_taker
        controller.getControllerByIdentifier(`users--accounts--documents--info`).permission = controller.info_permission
        controller.getControllerByIdentifier(`users--accounts--documents--info`).setCurrentInfo()
      })
    }
  }

  showContact() {
    var html = `<div data-controller="operations--products--clinics--regularizations--takers--contact" data-operations--products--clinics--regularizations--takers--contact-target="main"></div>`
    if (this.application.permissions[this.contact_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.contactShowTarget.innerHTML = html)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--takers--contact`).permission = controller.contact_permission
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--takers--contact`).doContactsHtml()
      })
    }
  }

  showProfile() {
    var html = `<div data-controller="operations--products--clinics--regularizations--takers--profile" data-operations--products--clinics--regularizations--takers--profile-target="main"></div>`
    if (this.application.permissions[this.profile_permission].can_index) {
      $(".nav-tabs-indicator").addClass("nav-dark")

      var controller = this
      new Promise(function (resolve) {
        resolve(controller.profileShowTarget.innerHTML = html)
      }).then(() => {
        // this.getControllerByIdentifier(`operations--products--clinics--regularizations--takers--contact`).permission = this.profile_permission
        // this.getControllerByIdentifier(`operations--products--clinics--regularizations--takers--contact`).doContactsHtml()
      })
    }
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewTakerCard"]
    var identifier = `${this.controllerName}`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
