import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--dashboards--activities--dashboard`
  }

  doActivitiesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 pr-1" data-${this.controllerName}-target="content" data-controller="financials--books--dashboards--activities--index
                                                                                                     financials--books--dashboards--activities--save">Atividade3</div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--dashboards--main--page`).settingsTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`financials--books--dashboards--activities--index`).permission = `financial_settings_activities`
      controller.getControllerByIdentifier(`financials--books--dashboards--activities--save`).permission = `financial_settings_activities`

      controller.getActivities()
      
    })
  }

  getActivities() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { activity: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_activities` } }
    const url = "/financials/config/activities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.activities = response.data.cln
        }
        
        controller.getControllerByIdentifier("financials--books--dashboards--activities--index").doIndexListHtml()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
