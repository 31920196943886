import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "main", "content", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--searchs--entities--dashboard`
    this.application.permissions = {}
    this.application.searchs = {}
    this.doSearchGrid()
  }

  doSearchGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="commercial--marketing--searchs--entities--summary
                                                            commercial--marketing--searchs--entities--list"
                                           data-${this.controllerName}-target="content"></div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--searchs--entities--summary").doSummaryGrid()
      // controller.getCurrentUserPermissions()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
