import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "messageBody", "title", "loader"]

  connect() {
    this.controllerName = `users--dashboards--main--task`
    var array = []
    this.taskDashboard(array)
  }

  goToTaskBoard() {
    var url = `/a/atividades/${currentUser.slug}`
    window.open(url, `_self`)
  }

  taskDashboard(array) {
    const rowHtml = `<div class="card border-top-primary">
                        <div class="card-body" data-${this.controllerName}-target="messageBody" id="messageBody" style="overflow:scroll;">
                        </div>
                      </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = rowHtml)
    }).then(() => {
      controller.preLoader()
      var height = $(window).height();
      $('#messageBody').css('height', height * 0.55);
    }) 
    setTimeout(function () {
      var boardEndDate = new Date(boardIndicator.end_date)
      const endDate = new Date(boardEndDate.getFullYear(), boardEndDate.getMonth(), boardEndDate.getDate() + 1)
      var speak = `<h6 style="margin-bottom:0px;" data-users--dashboards--main--greeting-target="name"><strong>Aqui é a Med, sua assistente virtual. Hoje é ${controller.getCurrentDate(new Date(), "month")} e, até ${controller.getCurrentDate(endDate, "month")}, você tem ${boardIndicator.total} atividades programadas, sendo:</strong></h6>`
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
      controller.preLoader()
      controller.tasksPerStage()
    }, 1000)
  }

  tasksPerStage() {
    const speak = `<div class="row my-3">
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                            <h4 class="my-0">${boardIndicator.repository}</h4>
                            <p>no Repositório</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                            <h4 class="my-0">${boardIndicator.todo}</h4>
                            <p>para Fazer</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                              <h4 class="my-0">${boardIndicator.doing}</h4>
                              <p>Fazendo</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>`

    var controller = this
    setTimeout(function () {
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
      controller.preLoader()
      controller.jobsTask()
    }, 2000)
  }

  jobsTask() {
    var controller = this
    setTimeout(function () {
      var word = ""
      if (boardIndicator.jobs == 1) {
        word = "tarefa"
      } else {
        word = "tarefas"
      }
      const speak = `<div class="row my-3">
                        <div class="col px-1">
                          <p style="margin-bottom:0px;">Com um total de ${boardIndicator.jobs} ${word} para serem realizadas.</p>
                        </div>
                      </div>`
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
      controller.preLoader()
      controller.tasksPerDay()
    }, 1000)
  }

  tasksPerDay() {
    const speak1 = `<div class="row my-3">
                      <div class="col px-1">
                        <p style="margin-bottom:0px;">Em questão de prazos, veja como estão as suas atividades:</p>
                      </div>
                    </div>`
    const speak2 = `<div class="row my-3">
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                            <h4 class="my-0">${boardIndicator.delayed}</h4>
                            <p>de dias anteriores</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                            <h4 class="my-0">${boardIndicator.current_day_tasks}</h4>
                            <p>para Hoje</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="media d-flex align-items-center">
                          <div class="media-body text-center">
                            <h4 class="my-0">${boardIndicator.next_days_tasks}</h4>
                            <p>próximos 7 dias</p>
                          </div>
                        </div>
                      </div>
                    </div>`

    var controller = this
    setTimeout(function () {
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak1)
      controller.preLoader()
      setTimeout(function () {
        controller.loaderTarget.remove()
        controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak2)
        controller.preLoader()
        controller.finalSpeak()
      }, 2000)
    }, 1000)
  }

  finalSpeak() {
    var controller = this
    
    var sentence = ""
    if (boardIndicator.delayed == 0) {
      sentence = `<strong>Parabéns ${currentUser.name.split(" ")[0]}!</strong> Continue o bom trabalho!`
    } else {
      sentence = `Cuidado para não acumular atividades atrasadas!`
    }
    const speak = `<div class="row my-3">
                        <div class="col px-1">
                          <p style="margin-bottom:0px;">${sentence}</p>
                        </div>
                      </div>`

    var finalBtn = `<div class="row my-3"><div class="col px-1"><button class="btn btn-primary btn-sm my-2 w-100" data-action="${this.controllerName}#goToTaskBoard"><a class="a-dark">Veja suas Atividades</a></button></div></div>`
    var controller = this
    setTimeout(function () {
      controller.loaderTarget.remove()
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", speak)
      controller.messageBodyTarget.insertAdjacentHTML("beforeend", finalBtn)
    }, 1000)
  }

  

  preLoader() {
    const rowHtml = `<div class='card timeline-item my-2' data-${this.controllerName}-target="loader">
                        <div class='animated-background animated-background-20'>
                        <div class='background-masker title'></div>
                        </div>
                      </div>`

    this.messageBodyTarget.insertAdjacentHTML("beforeend", rowHtml)
  }

  getCurrentDate(currentDate, kind) {
    const month = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
    const week = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
    
    if (kind == "full") {
      if (currentDate.getDate() < 10) {
        return `0${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear()}`
      } else {
        return `${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear()}`
      }
    } else if (kind == "abbr") {
      if (currentDate.getDate() < 10) {
        return `0${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear().toString().substr(-2)}`
      } else {
        return `${currentDate.getDate()}/${month[currentDate.getMonth()]}/${currentDate.getFullYear().toString().substr(-2)}`
      }
    } else if (kind == "week") {
      return `${week[currentDate.getDay()]}`
    } else if (kind == "month") {
      if (currentDate.getDate() < 10) {
        return `0${currentDate.getDate()}/${month[currentDate.getMonth()]}`
      } else {
        return `${currentDate.getDate()}/${month[currentDate.getMonth()]}`
      }
    }

  }

}