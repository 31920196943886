import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["taxReturnDashboard", "bookingDashboard", "mainDashboard", "greetingDashboard", "downloadBtn", "closeMonthBtn", "socialDue", "taxRate", "socialDueForm",
                    "taxDue", "saveBtn", "showProgressUpload", "progressUpload", "progressUploadBar", "progressUploadCounter", "closureDueInput", "saveCalculationBtn",
                    "upload", "labelUpload", "fileName", "cancelUploadBtn", "showUpload", "fileNameUploadShow", "uploadUrl", "uploadFile", "modal",
                    "uploadCashbook", "labelUploadCashbook", "fileNameCashbook", "cancelUploadCashbookBtn", "showUploadCashbook", "fileNameUploadCashbookShow", "uploadCashbookUrl", "uploadCashbookFile",
                    "uploadClosure", "labelUploadClosure", "fileNameClosure", "cancelUploadClosureBtn", "showUploadClosure", "fileNameUploadClosureShow", "uploadClosureUrl", "uploadClosureFile",
                    "closingDocuments", "socialStatement", "taxStatement", "aliasDashboard"]

  connect() {
    this.controllerName = `operations--products--bookings--entities--dashboard`
    this.send_update_data = { current_user: {}, calculation: {}, notification: {} }
  }

  reconnect() {
    this.send_update_data = { current_user: {}, calculation: {}, notification: {} }
    this.doBookingDashboard()
    this.doBookingChatHtml()
    this.doBookingCalculationsHtml()
    if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `doctor`) {
      this.doTaxReturnCalculationsHtml()
    }
    this.doAliasDashboardHtml()
    this.stopRefreshing()
  }

  doAliasDashboardHtml() {
    var html = `<h6 class="mb-0 text-center">Acesso Rápido</h6>
                <hr class="mt-1">
                <div class="row">
                  <div class="col-6 pl-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Recibos</span>
                      </div>
                      <div class="card-body text-center f-065 mc-tooltip">
                        <span class="material-icons md-300 md-primary pointer" data-action="click->${this.controllerName}#goToReceipts">launch</span>
                        <span class="mc-tooltiptext">Ver lista de Recibos</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Pagamentos</span>
                      </div>
                      <div class="card-body text-center f-065 mc-tooltip">
                        <span class="material-icons md-300 md-primary pointer" data-action="click->${this.controllerName}#goToPayments">launch</span>
                        <span class="mc-tooltiptext">Ver lista de Pagamento</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Pacientes</span>
                      </div>
                      <div class="card-body text-center f-065 mc-tooltip">
                        <span class="material-icons md-300 md-primary pointer" data-action="click->${this.controllerName}#goToPatients">launch</span>
                        <span class="mc-tooltiptext">Ver lista de Pacientes</span>
                      </div>
                    </div>
                  </div>
                </div>`

    if (this.hasAliasDashboardTarget) {
      this.aliasDashboardTarget.innerHTML = html
    }
  }

  doBookingCalculationsHtml() {
    if (this.application.booking_calculations) {
      this.application.booking_calculations.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_booking_calculation = element
          this.application.current_booking_calculation = element
        }
      });
    } else {
      this.current_booking_calculation = { total_incomes: 0, total_expenses: 0, tax_due: 0, tax_rate: 0, tax_statement_pretty: "", social_statement_pretty: "", name: "Livro-Caixa" }
    }
    var current_receipts = []
    this.application.receipts.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        current_receipts[current_receipts.length] = element
      }
    });

    if (this.current_booking_calculation.social_paid) {
      this.current_booking_calculation.social_statement_pretty = `Pago`
    } else if (this.current_booking_calculation.social_due > 0) {
      this.current_booking_calculation.social_statement_pretty = `À Pagar`
    } else {
      this.current_booking_calculation.social_statement_pretty = ``
    }

    if (this.current_booking_calculation.tax_paid) {
      this.current_booking_calculation.tax_statement_pretty = `Pago`
    } else if (this.current_booking_calculation.tax_due > 0) {
      this.current_booking_calculation.tax_statement_pretty = `À Pagar`
    } else {
      this.current_booking_calculation.tax_statement_pretty = ``
    }
    
    var html = `<h6 class="mb-0 text-center">
                  ${this.current_booking_calculation.name}
                </h6>
                <hr class="mt-1">
                <div class="row">
                  <div class="col-6 pl-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span># Recibos</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(this.current_booking_calculation.total_receipts))}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Total Recibos</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_booking_calculation.total_incomes)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Total Despesas</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_booking_calculation.total_expenses)}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Remanescente</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span data-${this.controllerName}-target="socialDue">${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_booking_calculation.remaining_expenses)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Contrib. INSS</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span data-${this.controllerName}-target="socialDue">${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_booking_calculation.social_due)}</span>
                        <br>
                        <span class="text-right text-mute mt-5" data-${this.controllerName}-target="socialStatement"><strong>${this.current_booking_calculation.social_statement_pretty}</strong></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Imposto Devido</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span data-${this.controllerName}-target="taxDue">${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_booking_calculation.tax_due * 100))}</span>
                        <br>
                        <span class="text-right text-mute mt-5" data-${this.controllerName}-target="taxStatement"><strong>${this.current_booking_calculation.tax_statement_pretty}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-6 pl-0"></div>
                  <div class="col-6 pr-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-070">
                        <span>Alíquota Média</span>
                      </div>
                      <div class="card-body text-center f-065">
                        <span data-${this.controllerName}-target="taxRate">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_booking_calculation.tax_rate * 100).toString())}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12 px-0">
                    <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#closeMonth" data-${this.controllerName}-target="closeMonthBtn"><a class="a-dark">Fechar Mês</a></button>
                  </div>
                </div>
                <div data-${this.controllerName}-target="closingDocuments">
                </div>`

    var controller = this
    if (this.hasBookingDashboardTarget) {
      new Promise(function (resolve) {
        resolve(controller.bookingDashboardTarget.innerHTML = html)
      }).then(() => {
        if (controller.application.current_date.open == false && controller.current_booking_calculation.closed == false) {
          controller.closeMonthBtnTarget.classList.remove("d-none")
        } else if (controller.application.current_date.open == false && controller.current_booking_calculation.closed == true) {
          controller.closeMonthBtnTarget.classList.add("d-none")
        } else {
          controller.closeMonthBtnTarget.classList.add("d-none")
        }

        if (controller.current_booking_calculation.social_paid) {
          controller.socialStatementTarget.classList.add("f-success")
          controller.socialStatementTarget.classList.remove("f-danger")
        } else if (controller.current_booking_calculation.social_due > 0) {
          controller.socialStatementTarget.classList.remove("f-success")
          controller.socialStatementTarget.classList.add("f-danger")
        }

        if (controller.current_booking_calculation.tax_paid) {
          controller.taxStatementTarget.classList.add("f-success")
          controller.taxStatementTarget.classList.remove("f-danger")
        } else if (controller.current_booking_calculation.tax_due > 0) {
          controller.taxStatementTarget.classList.remove("f-success")
          controller.taxStatementTarget.classList.add("f-danger")
        }
        
        controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      })
    }
  }

  goToUpload(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  editClosing() {

    var html = `<div class="row my-2" data-${this.controllerName}-target="closureDueInput">
                  <div class="col-6 pl-0">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="socialDueForm">Valor INSS</label>
                        <input aria-describedby="socialDueFormHelp" class="form-control form-valid-control" id="socialDueForm" data-${this.controllerName}-target="socialDueForm" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#updateTaxDue" placeholder="Valor INSS" type="tel" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 pr-0">
                    <button disabled class="btn btn-primary btn-sm my-2 w-100 d-none" data-action="click->${this.controllerName}#closingCalculation" data-${this.controllerName}-target="saveCalculationBtn"><a class="a-dark">Salvar</a></button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.closingDocumentsTarget.innerHTML = html)
    }).then(() => {
      
      controller.socialDueFormTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_booking_calculation.social_due * 100))
      controller.closureDueInputTarget.classList.remove("d-none")
      controller.closeMonthBtnTarget.classList.add("d-none")
      controller.saveCalculationBtnTarget.classList.remove("d-none")
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }) 
  }

  closeMonth() {
    this.editClosing()
  }

  updateTaxDue(ev) {

    var socialDue = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(ev.target.value))
    var totalIncomes = Number(this.current_booking_calculation.total_incomes)
    var totalExpenses = Number(this.current_booking_calculation.total_expenses)
    var remainingExpenses = 0
    var taxRate = 0

    this.application.dates.forEach(date => {
      if ((date.year == this.application.current_date.year) && (date.month == this.application.current_date.month - 1)) {
        this.application.booking_calculations.forEach(booking => {
          if (booking.date_id == date.id) {
            remainingExpenses = Number(booking.remaining_expenses)
          }
        })
      }
    })    

    if (totalIncomes > socialDue + totalExpenses) {
      var base = totalIncomes - socialDue - totalExpenses - remainingExpenses
    } else {
      var base = 0
    }

    if (base > 1903.98 && base <= 2826.65) {
      var due = ((7.5 * base) / 100) - 142.80
    } else if (base > 2826.65 && base <= 3751.05) {
      var due = ((15 * base) / 100) - 354.80  
    } else if (base > 3751.05 && base <= 4664.68) {
      var due = ((22.5 * base) / 100) - 636.13
    } else if (base > 4664.68) {
      var due = ((27.5 * base) / 100) - 869.36  
    } else if (base <= 1903.98) {
      var due = 0.0
    }

    if (totalIncomes > 1903.98) {
      taxRate = ( due / totalIncomes ) * 100
    }

    this.taxDueTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(due * 100))
    this.socialDueTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(socialDue * 100))
    this.taxRateTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(taxRate * 100).toString())

  }

  closingCalculation() {
    this.stopRefreshing()
    this.saveCalculationBtnTarget.disabled = true

    this.actionMode = `upload`
    this.send_update_data.current_user.current_user_id = this.application.current_user.id

    this.send_update_data.calculation.id = this.current_booking_calculation.id
    this.send_update_data.calculation.closed = true
    this.send_update_data.calculation.social_due = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.socialDueFormTarget.value)

    this.send_update_data.notification.domain_id = this.application.booking.id
    this.send_update_data.notification.domain_type = "operation_products"
    this.send_update_data.notification.date_id = this.application.current_date.id
    this.send_update_data.notification.date_type = "operation_dates"
    this.send_update_data.notification.kind = `mode_${this.actionMode}`
    this.send_update_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} fez o Fechamento do ${this.current_booking_calculation.name}.`
    this.send_update_data.notification.account_name = this.application.current_user.name
    this.send_update_data.notification.account_id = this.application.current_user.account_id

    var url = "/operations/products/bookings/calculations/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_update_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var calculation = response.data.cln
          controller.application.booking_calculations.forEach((element, i) => {
            if (element.id == calculation.id) {
              controller.application.booking_calculations.splice(controller.application.booking_calculations.indexOf(element), 1, calculation)
            }
          })
          controller.doTaxReturnCalculationsHtml()
          controller.doBookingCalculationsHtml()
          controller.doBookingChatHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToTaxReturn(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  doTaxReturnCalculationsHtml() {
    if (this.application.tax_return_calculations) {
      this.application.tax_return_calculations.forEach(element => {
        if (element.date_year == this.application.current_date.year) {
          this.current_tax_return_calculation = element
          this.current_tax_return_calculation.base = element.total_taxed_income - element.total_tax_deduction
        }
      });
    } else {
      this.current_tax_return_calculation = { legal_income: 0, individual_income: 0, exempt_income: 0, base: 0, booking: 0, health: 0, pension: 0, social: 0, others: 0, total_tax: 0, adjusted_tax: 0, tax_rate: 0, tax_statement_pretty: "", total_tax_deduction: 0 }
    }

    if (this.current_tax_return_calculation.total_tax - this.current_tax_return_calculation.adjusted_tax > 0) {
      var finalTax = this.current_tax_return_calculation.total_tax - this.current_tax_return_calculation.adjusted_tax
    } else {
      var finalTax = this.current_tax_return_calculation.adjusted_tax - this.current_tax_return_calculation.total_tax
    }
    
    var html = `<h6 class="mb-0 text-center">
                  Planner ${this.application.current_date.year} e Declaração IRPF ${this.application.current_date.year + 1}
                  <span data-action="click->${this.controllerName}#goToTaxReturn" data-url="/a/planner-anual/${this.application.products.tax_return_token}" class="ml-3 pointer mc-tooltip">
                    <span class="material-icons md-sm md-dark">launch</span>
                    <span class="mc-tooltiptext">Ver Planner</span>
                  </span>
                </h6>
                  <hr class="mt-1">
                  <div class="row my-3">
                    <div class="col-4 pl-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Rend. Trib. PJ</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.legal_income)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Base de Cálculo</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.base)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 pr-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Ajuste Final</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(finalTax)}</span>
                          <br>
                          <span class="text-right text-mute mt-5"><strong>${this.current_tax_return_calculation.tax_statement_pretty}</strong></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-4 pl-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Rend. Trib. PF</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.individual_income)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Retido/Pago</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.total_tax)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 pr-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Alíquota Efetiva</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_tax_return_calculation.tax_rate * 100).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-4 pl-0">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Total Deduções</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.total_tax_deduction)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="card">
                        <div class="card-header p-1 text-center f-070">
                          <span>Devido</span>
                        </div>
                        <div class="card-body text-center f-065 px-1">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_tax_return_calculation.adjusted_tax)}</span>
                        </div>
                      </div>
                    </div>
                  </div>`

    if (this.hasTaxReturnDashboardTarget) {
      this.taxReturnDashboardTarget.innerHTML = html
    }
  }

  doBookingChatHtml() {

    if (this.application.booking.account_sex == "male") {
      var doctorName = `Dr. ${this.application.booking.account_name.split(" ").shift()}`
    } else if (this.application.booking.account_sex == "female") {
      var doctorName = `Dra. ${this.application.booking.account_name.split(" ").shift()}`
    }

    if (this.application.current_user.account_kind == `helper`) {
      if (this.application.current_date.open) {
        var bookingIO = `<div class="row my-3">
                      <div class="col-12 pl-0">
                        O Livro-Caixa está <span class="f-bold pointer badge badge-secondary-success">aberto</span> para novos registros de receitas e despesas.
                      </div>
                    </div>`
      } else {
        var bookingIO = `<div class="row my-3">
                          <div class="col-12 pl-0">
                            O Livro-Caixa está <span class="f-bold pointer badge badge-secondary-danger">fechado</span> para novos registros de receitas e despesas.
                          </div>
                        </div>`
      }

      var html = `<div class="row my-3">
                    <div class="col-12 pl-0">
                      Olá ${this.application.user_name},
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      Esse é o Livro-Caixa referente ao mês de ${this.application.current_date.month_pretty} do ano de ${this.application.current_date.year}.
                    </div>
                  </div>
                  ${bookingIO}`
    } else {
      var taxInvoice = ``
      var socialInvoice = ``

      var cashbookFile = ``
      var closureFile = ``
      var taxInvoiceFile = ``
      var socialInvoiceFile = ``

      if (this.current_booking_calculation.tax_due > 0) {
        var taxDue = `você teve um total de ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_booking_calculation.tax_due * 100))} de Imposto de Renda Devido`
        if (this.current_booking_calculation.tax_paid == false) {
          taxDue += `. Para acusar o pagamento, <span class="f-bold pointer badge badge-secondary-danger" data-action="click->${this.controllerName}#uploadFile" data-file="tax_receipt">clique aqui</span>`
          if (this.current_booking_calculation.has_tax_invoice) {
            taxInvoice = `Abaixo você também tem a Darf para pagamento do Imposto de Renda .`
            taxInvoiceFile = `<label class="btn btn-primary btn-sm text-center px-1 f-065" data-action="click->${this.controllerName}#goToUpload" data-url="${this.current_booking_calculation.tax_invoice_url}" style="font-size:80%;">DARF</label>`
          }
        }
      } else {
        var taxDue = `você teve um total de R$ 0,00 de Imposto de Renda Devido`
      }

      if (this.current_booking_calculation.social_due > 0) {
        var socialDue = `você teve um total de ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_booking_calculation.social_due * 100))} de Contribuição ao INSS`
        if (this.current_booking_calculation.social_paid == false) {
          socialDue += `. Para acusar o pagamento, <span class="f-bold pointer badge badge-secondary-danger" data-action="click->${this.controllerName}#uploadFile" data-file="social_receipt">clique aqui</span>`
          if (this.current_booking_calculation.has_social_invoice) {
            socialInvoice = `Abaixo você também tem a GPS para recolhimento da Contribuição do INSS.`
            socialInvoiceFile = `<label class="btn btn-primary btn-sm text-center px-1 f-065" data-action="click->${this.controllerName}#goToUpload" data-url="${this.current_booking_calculation.social_invoice_url}" style="font-size:80%;">GPS</label>`
          }
        }
      } else {
        var socialDue = `você teve um total de R$ 0,00 de Contribuição ao INSS`
      }

      if (this.application.current_date.open) {
        var html = `<div class="row my-3">
                    <div class="col-12 pl-0">
                      Olá ${doctorName},
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      Esse é o Livro-Caixa referente ao mês de ${this.application.current_date.month_pretty} do ano de ${this.application.current_date.year}.
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      O Livro-Caixa está aberto para novos registros de receitas e despesas.
                      Para lançar nova receita, <span class="f-bold pointer badge badge-secondary-success" data-action="click->${this.controllerName}#goToReceipts">clique aqui</span>.
                      Para lançar uma nova despesa, <span class="f-bold pointer badge badge-secondary-danger" data-action="click->${this.controllerName}#goToPayments">clique aqui</span>.
                    </div>
                  </div>`
      } else {
        if (this.current_booking_calculation.has_cashbook) {
          cashbookFile = `<label class="btn btn-primary btn-sm text-center px-1 f-065" data-action="click->${this.controllerName}#goToUpload" data-url="${this.current_booking_calculation.cashbook_url}" style="font-size:80%;">Livro-Caixa</label>`
        }
        if (this.current_booking_calculation.has_closure) {
          closureFile = `<label class="btn btn-primary btn-sm text-center px-1 f-065" data-action="click->${this.controllerName}#goToUpload" data-url="${this.current_booking_calculation.closure_url}" style="font-size:80%;">Demonstrativo</label>`
        }

        var html = `<div class="row my-3">
                    <div class="col-12 pl-0">
                      Olá ${doctorName},
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      Esse é o Livro-Caixa referente ao mês de ${this.application.current_date.month_pretty} do ano de ${this.application.current_date.year}.
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      O Livro-Caixa está fechado para novos registros de receitas e despesas.
                      No mês de ${this.application.current_date.month_pretty}, ${taxDue}. Além do Imposto, ${socialDue}.
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-12 pl-0">
                      Abaixo você tem a Apuração do seu Carnê-Leão e o seu demonstrativo de Livro-Caixa. ${taxInvoice} ${socialInvoice}
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6">
                      ${cashbookFile}
                    </div>
                    <div class="col-6">
                      ${closureFile}
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6">
                      ${taxInvoiceFile}
                    </div>
                    <div class="col-6">
                      ${socialInvoiceFile}
                    </div>
                  </div>`
      }
    }            
    
    this.greetingDashboardTarget.innerHTML = html
  }

  goToReceipts() {
    this.getControllerByIdentifier("operations--products--bookings--receipts--dashboard").doReceiptsDashboard()
  }

  goToPayments() {
    this.getControllerByIdentifier("operations--products--bookings--payments--dashboard").doPaymentsDashboard()
  }

  goToPatients() {
    this.getControllerByIdentifier("operations--products--bookings--patients--dashboard").doPatientsDashboard()
  }

  uploadFile(ev) {
    this.current_booking_calculation.field = ev.currentTarget.dataset.file

    this.modalHtml()
  }

  doBookingDashboard() {
    // `<div class='background-masker title'></div>`
    var loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`

    var bookingDashboardHtml = `<div class="col-3" data-${this.controllerName}-target="bookingDashboard">
                                  <h6 class="mb-0 text-center">${loader}</h6>
                                  <hr class="mt-1">
                                  <div class="row">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row my-3">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row my-3">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>`

    if (this.application.current_user.account_kind == `helper`) {
      var taxReturnDashboardHtml = ``
      var aliasDashboardHtml = `<div class="col-3" data-${this.controllerName}-target="aliasDashboard">
                                  <h6 class="mb-0 text-center">${loader}</h6>
                                  <hr class="mt-1">
                                  <div class="row">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row my-3">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row my-3">
                                    <div class="col-6 pl-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6 pr-0">
                                      <div class="card">
                                        <div class="card-header p-1 text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                        <div class="card-body text-center f-065">
                                          <span>${loader}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>`
    } else {
      var aliasDashboardHtml = ``
      var taxReturnDashboardHtml = `<div class="col-5" data-${this.controllerName}-target="taxReturnDashboard">
                                      <h6 class="mb-0 text-center">${loader}</h6>
                                      <hr class="mt-1">
                                      <div class="row">
                                        <div class="col-4 pl-0">
                                          <div class="row">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row my-3">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row my-3">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span >${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-4">
                                          <div class="row">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row my-3">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row my-3">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-4 pr-0">
                                          <div class="row">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                  <br>
                                                  <span class="text-right text-mute mt-5">${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row my-3">
                                            <div class="col-12 px-0">
                                              <div class="card">
                                                <div class="card-header p-1 text-center f-065">
                                                  <span>${loader}</span>
                                                </div>
                                                <div class="card-body text-center f-065 px-1">
                                                  <span>${loader}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>`
    }

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4" data-${this.controllerName}-target="greetingDashboard">
                    <div class="row my-3">
                      <div class="col-12 pl-0">
                        <span>${loader}</span>
                        <span>${loader}</span>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 pl-0">
                        <span>${loader}</span>
                        <span>${loader}</span>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-12 pl-0">
                        <span>${loader}</span>
                        <span>${loader}</span>
                      </div>
                    </div>
                  </div>
                  ${aliasDashboardHtml}
                  ${bookingDashboardHtml}
                  ${taxReturnDashboardHtml}
                </div>`

    this.getControllerByIdentifier("operations--products--bookings--entities--show").contentTarget.innerHTML = html
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  modalHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medbooking_calculations.can_update) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#saveCalculation" data-${this.controllerName}-target="saveCalculationBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="modal fade" data-${this.controllerName}-target="modal" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header border-bottom py-0 pl-0">
                        <h6 class="modal-title display-4 card-title-table-list"><strong class="f-075">Upload ${this.bookingFilePretty(this.current_booking_calculation.field)} | ${this.current_booking_calculation.name}</strong></h6><br>
                        <button type="button" class="close" data-dismiss="modal" data-action="click->${this.controllerName}#close" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body p-3" data-${this.controllerName}-target="body">
                        <div class="row my-2">
                          <div class="col-5 my-1 px-0 d-flex align-items-center justify-content-between d-none w-100" data-${this.controllerName}-target="upload">
                            <div class="form-group form-valid-group my-0 text-center">
                              <input type="file" id="calculationFile" name="calculationFile" class="file-input" data-action="change->${this.controllerName}#saveUpload">
                              <label for="calculationFile" id="calculationFileLabelForm" class="btn btn-primary btn-sm text-left px-1 f-065" data-${this.controllerName}-target="labelUpload" style="font-size:80%;">${this.bookingFilePretty(this.current_booking_calculation.field)}</label>
                            </div>
                            <button type="button" class="close ml-3 d-none" data-action="click->${this.controllerName}#cancelUpload" data-${this.controllerName}-target="cancelUploadBtn" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="col-7 my-1 px-0 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="uploadFile">
                            <span data-${this.controllerName}-target="fileName" class="ml-2 mr-auto f-065 my-auto" style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"></span>
                          </div>

                          <div class="col-12 px-0 d-flex align-items-center justify-content-between w-100" data-${this.controllerName}-target="showProgressUpload">
                            <div class="progress w-100 d-none" style="height: 6px;" data-${this.controllerName}-target="progressUpload">
                              <div class="progress-bar" role="progressbar" style="height: 6px;width:20%;border-bottom:0.5rem solid #053B5E;" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadBar"></div>
                            </div>
                            <span class="d-none" data-${this.controllerName}-target="progressUploadCounter" class="d-block"></span>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="click->${this.controllerName}#close" style="width:85px;">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.greetingDashboardTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.open()
      controller.refreshSaveBtn()
      if (controller.current_booking_calculation.field == "tax_receipt" && controller.current_booking_calculation.has_tax_receipt) {
        controller.fileNameTarget.innerText = controller.current_booking_calculation.tax_receipt_name
      }
      if (controller.current_booking_calculation.field == "social_receipt" && controller.current_booking_calculation.has_social_receipt) {
        controller.fileNameTarget.innerText = controller.current_booking_calculation.social_receipt_name
      }
      
      controller.uploadTarget.classList.remove("d-none")
      controller.uploadFileTarget.classList.remove("d-none")
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  open() {
    document.body.classList.add("modal-open");
    this.modalTarget.setAttribute("style", "display: block;");
    this.modalTarget.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    document.body.insertAdjacentHTML("beforeend", html)
  }

  close() {
    this.stopRefreshing()
    document.body.classList.remove("modal-open");
    this.modalTarget.removeAttribute("style");
    this.modalTarget.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  bookingFilePretty(file) {
    var fileKind = ["tax_receipt", "social_receipt"]
    var fileKindPretty = ["Comprovante DARF", "Comprovante GPS"]

    return fileKindPretty[fileKind.indexOf(file)]
  }

  saveUpload(ev) {
    if (ev.target.files.length > 0) {
      this.current_booking_calculation.file = ev.target.files[0]
      this.fileNameTarget.innerText = ev.target.files[0].name
    }
  }

  saveCalculation() {
    this.stopRefreshing()
    this.saveCalculationBtnTarget.disabled = true
    var formData = new FormData()
    formData.append('file', this.current_booking_calculation.file)
    formData.append('field', this.current_booking_calculation.field)
    formData.append('calculation_id', this.current_booking_calculation.id)
    formData.append('current_user_id', this.application.current_user.id)

    var url = "/operations/products/bookings/calculations/save_upload"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

    this.progressUploadTarget.classList.remove("d-none")
    this.progressUploadCounterTarget.classList.remove("d-none")
    this.labelUploadTarget.classList.remove("btn-primary")
    this.labelUploadTarget.classList.add("btn-disabled")
    this.progressCount(0)
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.progressCount(100)
          controller.stopRefreshing()
          var booking_calculation = response.data.cln
          controller.application.booking_calculations.forEach((element, i) => {
            if (element.id == booking_calculation.id) {
              controller.application.booking_calculations.splice(controller.application.booking_calculations.indexOf(element), 1, booking_calculation)
            }
          })
          controller.close()
          controller.doTaxReturnCalculationsHtml()
          controller.doBookingCalculationsHtml()
          controller.doBookingChatHtml()
        } else {
          controller.saveCalculationBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  progressCount(value) {
    var controller = this
    this.progressUploadBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (len == 0) {
        controller.saveCalculationBtnTarget.disabled = false
      } else {
        controller.saveCalculationBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }
 }