import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "compilationTitle", "compilationBoard", "billingsTitle", "billingsTable", "mainCard",
    "bodyTable", "footerTable", "content", "goalLeadInput", "mainDashboard"]

  connect() {
    this.controllerName = `app--communications--voips--dashboard--main`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reload = false
    this.load = false
    this.application.permissions = {}
    this.application.permissions = []
    this.application.calls = []
    this.application.callers = []
    this.application.dates = []
    this.application.accounts = []
    this.application.clearing_report = []
    this.application.caller = {}
    this.application.current_caller_id = false

    this.doMainGrid()

  }

  doMainDashboard() {
    this.doPageGrid()
  }

  doPageGrid() {
    var controllerCompilation = `app--communications--voips--dashboard--compilation`
    var controllerCallers = `app--communications--voips--dashboard--callers`

    var html = `<div class="row my-2" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 px-2">
                    <div class="row my-2 w-100" data-controller="${controllerCallers}">
                      <div class="col-12">
                        <h6 class="mb-0 text-center" data-${controllerCallers}-target="callersTitle">${this.loader}</h6>
                        <hr class="my-1">
                        <div class="row" data-${controllerCallers}-target="callersBoard">
                          ${this.cardGridPreloader(2, 12)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-10 px-2">
                    <div class="row my-2 w-100" data-controller="${controllerCompilation}">
                      <div class="col-6 px-2">
                        <div class="row w-100">
                          <div class="col-12">
                            <h6 class="mb-0 text-center pointer" data-action="click->${controllerCompilation}#refreshHourly" data-${controllerCompilation}-target="hourlyTitle">${this.loader}</h6>
                            <hr class="my-1">
                            <div class="row" data-${controllerCompilation}-target="hourlyBoard">
                              ${this.cardGridPreloader(6, 4)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-2">
                        <div class="row w-100">
                          <div class="col-12 px-0">
                            <h6 class="mb-0 text-center pointer" data-action="click->${controllerCompilation}#refreshCalls" data-${controllerCompilation}-target="callsTitle">${this.loader}</h6>
                            <hr class="mt-1">
                            <div class="row my-2" data-${controllerCompilation}-target="callsBoard">
                              ${this.cardGridPreloader(6, 4)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.contentTarget.innerHTML = html)
    }).then(() => {
      if (controller.load) {
        controller.getControllerByIdentifier(controllerCallers).permission = `app_comunication_voip_calls`
        controller.getControllerByIdentifier(controllerCallers).grid = `call_compilation`
        controller.getControllerByIdentifier(controllerCallers).doCallersCard()
        controller.getControllerByIdentifier(controllerCompilation).permission = `app_comunication_voip_calls`
        controller.getControllerByIdentifier(controllerCompilation).doCompilationCard()
        controller.getControllerByIdentifier(controllerCompilation).doHourlyCard()
      }
    })
  }

  cardGridPreloader(cols, size) {

    var html = ``
    for (let index = 0; index < cols; index++) {
      html += `<div class="col-${size} my-2">
                  ${this.cardLoader}
                </div>`
      
    }

    return html
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="app--communications--voips--calls--dashboard
                                                            app--communications--voips--callers--dashboard

                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doMainDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  refreshCompilation() {
    this.getControllerByIdentifier("app--communications--voips--dashboard--compilation").refreshCompilation()
  }

  refreshBillings() {
    this.getControllerByIdentifier(`app--communications--voips--dashboard--billings`).refreshBillings()
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    if (this.reload) {
      this.getControllerByIdentifier("app--communications--voips--dashboard--dates").runDashboardsHtml()
    } else {
      // this.getControllerByIdentifier(`app--communications--voips--calls--dashboard`).doCallsDashboard()
      this.getControllerByIdentifier("app--communications--voips--dashboard--main").doMainDashboard()
      this.getControllerByIdentifier("app--communications--voips--dashboard--submenu").doSubmenuHtml()
    }
    this.application.current_date.data_loaded = true

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getCalls() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ligações`, value: 75 })

    if (this.application.permissions.app_comunication_voip_callers.can_manage) {
      var data = { call: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_calls` } }
      var url = "/app/communications/voip/calls/list_by_date"
    } else {
      var data = { call: { active: true, date_id: this.application.current_date.id, caller_id: this.application.caller.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_calls` } }
      var url = "/app/communications/voip/calls/list_by_date_and_caller"
    }
    
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentCallIds = controller.mapElements(controller.application.calls, `id`)
          var refreshCallIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(call => {
            if (currentCallIds.includes(call.id) && refreshCallIds.includes(call.id)) {
              controller.application.calls.forEach((element, i) => {
                if (element.id == call.id) {
                  controller.application.calls.splice(controller.application.calls.indexOf(element), 1, call)
                }
              })
            } else if (!currentCallIds.includes(call.id) && refreshCallIds.includes(call.id)) {
              controller.application.calls[controller.application.calls.length] = call
            }
          })
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getClearingByHour() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Relatório de Horário`, value: 50 })


    if (this.application.permissions.app_comunication_voip_callers.can_manage) {
      if (this.application.current_caller_id) {
        var data = { report: { active: true, date_id: this.application.current_date.id, caller_id: this.application.current_caller_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_calls` } }
      } else {
        var data = { report: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_calls` } }
      }
    } else {
      var data = { report: { active: true, date_id: this.application.current_date.id, caller_id: this.application.caller.id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_calls` } }
    }

    const url = "/app/communications/voip/calls/clearing_report_by_hour"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentClearingIds = controller.mapElements(controller.application.clearing_report, `date_id`)

          if (currentClearingIds.includes(response.data.cln.date_id)) {
            controller.application.clearing_report.forEach((element, i) => {
              if (element.date_id == response.data.cln.date_id) {
                controller.application.clearing_report.splice(controller.application.clearing_report.indexOf(element), 1, response.data.cln)
              }
            })
          } else if (!currentClearingIds.includes(response.data.cln.date_id)) {
            controller.application.clearing_report[controller.application.clearing_report.length] = response.data.cln
          }
        }

        controller.getCalls()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  loadDateData() {
    if (this.application.current_date.data_loaded == false) {
      if (!this.getControllerByIdentifier("app--helpers--page-load")) {
        this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Informações da Competência ${this.application.current_date.name}` })
      }
      
      this.getClearingByHour()
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Finalizando`, value: 50 })
      this.finishLoadPage()
    }
  }

  getCallers() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ramais`, value: 40 })

    const data = { caller: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_callers` } }
    const url = "/app/communications/voip/callers/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.callers = response.data.cln
        }
        
        controller.loadDateData()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCaller() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Ramal`, value: 20 })

    const data = { caller: { account_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_callers` } }
    const url = "/app/communications/voip/callers/read_by_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.caller = response.data.cln
        }

        if (controller.application.permissions.app_comunication_voip_callers.can_manage) {
          controller.getCallers()
        } else {
          controller.loadDateData()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_permissions` } }
    const url = "/app/communications/voip/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var currentDates = []
        var today = new Date()

        response.data.cln.forEach(element => {
          if (today.getFullYear() > element.year) {
            currentDates[currentDates.length] = element
          } else if (today.getFullYear() == element.year && (today.getMonth() + 1) >= element.month) {
            currentDates[currentDates.length] = element
          }
        })

        controller.application.dates = currentDates
        controller.application.current_date = currentDates[0]

        controller.getControllerByIdentifier("app--communications--voips--dashboard--dates").doDatesHTML()
        controller.getCaller()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `app_communication_voips` }, current_user: { current_user_id: this.application.current_user.id, feature: `app_comunication_voip_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        window.location.reload()
      })
  }


  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------


  refreshCalls(permission, identifier) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    if (this.application.permissions[permission].can_manage) {
      var data = { call: { active: true, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: permission } }
      var url = "/app/communications/voip/calls/list_by_date"
    } else {
      var data = { call: { active: true, date_id: this.application.current_date.id, caller_id: this.application.caller.id }, current_user: { current_user_id: this.application.current_user.id, feature: permission } }
      var url = "/app/communications/voip/calls/list_by_date_and_caller"
    }

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var currentCallIds = controller.mapElements(controller.application.calls, `id`)
          var refreshCallIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(call => {
            if (currentCallIds.includes(call.id) && refreshCallIds.includes(call.id)) {
              controller.application.calls.forEach((element, i) => {
                if (element.id == call.id) {
                  controller.application.calls.splice(controller.application.calls.indexOf(element), 1, call)
                }
              })
            } else if (!currentCallIds.includes(call.id) && refreshCallIds.includes(call.id)) {
              controller.application.calls[controller.application.calls.length] = call
            }
          })
        }

        if (identifier == `call_table`) {
          controller.getControllerByIdentifier(`app--communications--voips--calls--index`).doDataTable()
        } else if (identifier == `hourly_table`) {
          controller.getControllerByIdentifier(`app--communications--voips--dashboard--compilation`).doHourlyCard()
        } else if (identifier == `compilation_table`) {
          controller.getControllerByIdentifier(`app--communications--voips--dashboard--compilation`).doCompilationCard()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }





  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------



  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = `app--communications--voips--dashboard--main`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.updatePayble) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}