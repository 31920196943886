import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "paidCard", "dueCard", "indicatorHeader", "indicators", "paidHeader", "dueHeader", "actionCards"]

  connect() {
    this.controllerName = `financials--books--receivables--liquidations--indicators`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.calculation = {}
    this.billings = []
    this.indicatorsPreload()
  }


  doIndicatorsCalculation() {

    this.calculation.total_billings = 0
    this.calculation.total_liquidation = 0
    this.calculation.total_liquidated = 0
    this.calculation.total_fee = 0
    this.calculation.total_credit_card_billings = 0
    this.calculation.total_credit_card_total = 0
    this.calculation.total_credit_card_liquidated = 0
    this.calculation.total_credit_card_fee = 0
    this.calculation.total_bank_slip_billings = 0
    this.calculation.total_bank_slip_total = 0
    this.calculation.total_bank_slip_liquidated = 0
    this.calculation.total_bank_slip_fee = 0

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (this.current_day < 10) {
      var currentDay = `0${this.current_day}`
    } else {
      var currentDay = `${this.current_day}`
    }

    var currentToken = `${this.application.current_date.year}${currentMonth}${currentDay}`

    this.current_billing_dates = []
    this.application.billing_dates.forEach(element => {
      if (this.application.current_med.id) {
        if (element.med_id == this.application.current_med.id) {
          this.current_billing_dates[this.current_billing_dates.length] = element
        }
      } else {
        this.current_billing_dates[this.current_billing_dates.length] = element
      }
    })

    // this.application.billing_dates.forEach(billing => {
    this.current_billing_dates.forEach(billing => {
      if (billing.executed_token == currentToken) {
        this.calculation.total_billings += 1
        this.calculation.total_liquidation += Number(billing.total)

        if (billing.executed) {
          this.calculation.total_liquidated += Number(billing.amount)
          this.calculation.total_fee += Number(billing.fee)
        }

        if (billing.method == `credit_card`) {
          if (billing.executed) {
            this.calculation.total_credit_card_liquidated += Number(billing.amount)
            this.calculation.total_credit_card_fee += Number(billing.fee)
          }
          this.calculation.total_credit_card_total += Number(billing.total)
          this.calculation.total_credit_card_billings += 1

        } else if (billing.method == `bank_slip`) {

          if (billing.executed) {
            this.calculation.total_bank_slip_liquidated += Number(billing.amount)
            this.calculation.total_bank_slip_fee += Number(billing.fee)
          }
          this.calculation.total_bank_slip_total += Number(billing.total)
          this.calculation.total_bank_slip_billings += 1
        }

      }

    })

    this.doIndicatorsHtml()
  }

  doIndicatorsHtml() {
    var html = `<div class="row">
                
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span class="mc-tooltip help">
                          <span>Total Liquidação</span>
                          <span class="mc-tooltiptext">Total Faturas: ${this.calculation.total_billings}</span>
                        </span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Total:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_liquidation)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Liquidado:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_liquidated)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Taxas:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span class="mc-tooltip help">
                          <span>Liquidação Cartão de Crédito</span>
                          <span class="mc-tooltiptext">Total Faturas: ${this.calculation.total_credit_card_billings}</span>
                        </span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Total:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_credit_card_total)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Liquidado:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_credit_card_liquidated)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Taxas:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_credit_card_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span class="mc-tooltip help">
                          <span>Liquidação Boleto</span>
                          <span class="mc-tooltiptext">Total Faturas: ${this.calculation.total_bank_slip_billings}</span>
                        </span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Total:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_bank_slip_total)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Liquidado:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_bank_slip_liquidated)}
                          </div>
                          <div class="col-5 text-left f-065 py-2 px-1">
                            Taxas:
                          </div>
                          <div class="col-7 text-left f-065 py-2 px-1">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_bank_slip_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  
                  
                  
                  
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).indicatorsTarget.innerHTML = html)
    }).then(() => {
      
    })
  }

  doIndicatorsHtml2() {
    var html = `<div class="row">
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total de Faturas: ${this.calculation.total_billings}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total à Liquidar</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_liquidation)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Liquidado</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_liquidated)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span>Total Taxas</span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 text-center f-075 py-2">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span class="mc-tooltip help">
                          <span>Liquidação Cartão de Crédito</span>
                          <span class="mc-tooltiptext">Líquido: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_credit_card_liquidated)}</span>
                        </span>
                      </div>
                      <div class="card-body f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right f-065 py-2 px-0">
                            Total:
                          </div>
                          <div class="col-8 text-right f-065 py-2 px-0">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_credit_card_total)}
                          </div>
                          <div class="col-4 text-right f-065 py-2 px-0">
                            Taxas:
                          </div>
                          <div class="col-8 text-right f-065 py-2 px-0">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_credit_card_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2 mt-3">
                    <div class="card pointer">
                      <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="indicatorHeader">
                        <span class="mc-tooltip help">
                          <span>Liquidação Boleto</span>
                          <span class="mc-tooltiptext">Líquido: ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.calculation.total_bank_slip_liquidated)}</span>
                        </span>
                      </div>
                      <div class="card-body text-center f-065 px-0 py-1">
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-center f-065 py-2 px-0">
                            Total:
                          </div>
                          <div class="col-8 text-center f-065 py-2 px-0">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_bank_slip_total)}
                          </div>
                          <div class="col-4 text-center f-065 py-2 px-0">
                            Taxas:
                          </div>
                          <div class="col-8 text-center f-065 py-2 px-0">
                            ${this.controllerNumber.currencyOptionMask(this.calculation.total_bank_slip_fee)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).indicatorsTarget.innerHTML = html)
    }).then(() => {
      
    })
  }

  indicatorsPreload() {
    var html = `<div class="row">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    ${this.cardLoader}
                  </div>
                </div>`

    this.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).indicatorsTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}