import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loader", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.botController = this
    this.controllerName = `app--communications--chat--visits--bot`
    this.botStage = `welcome`
    this.bot = true
    this.location = location.pathname
    this.title = this.location.split("/").pop().replace(/-/g, " ").charAt(0).toUpperCase() + this.location.split("/").pop().replace(/-/g, " ").slice(1)
    // this.autoChat()
    console.log("this is bot")
  }

  autoChat() {
    console.log("this set auto chat")
    setTimeout(() => {
      if (this.location.includes("/descanso-medico/") || this.location.includes("/descanso-medico") || this.location.includes("/vagas") || this.location.includes("/medschool")) {
        this.getControllerByIdentifier("app--communications--chat--visits--entities").openForm()
      }
    }, 4000);
  }

  welcome() {
    this.inputElements(true)
    this.preLoader()
    this.botChat()
    this.application.current_visitor_token = this.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")
  }

  botChat() {
    if (this.botStage == `welcome`) {
      setTimeout(() => {
        if (this.location.includes("/descanso-medico/")) {
          var content = `<span>Olá! O que está achando do conteúdo ${this.title}?</span>`
        } else if (this.location.includes("/descanso-medico")) {
          var content = `<span>Olá! Conseguiu realizar a busca no Descanso Médico?</span>`
        } else if (this.location.includes("/vagas")) {
          var content = `<span>Olá! Se você quer transformar as relações das pessoas com as suas finanças, você está no lugar certo!</span>`
        } else {
          var content = `<span>Prazer em tê-lo aqui, seja bem-vindo!</span>`
        }
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.botStage = `name`
        this.insertMessagesAndNext(message)
      }, 1500);
    } else if (this.botStage == `name`) {
      setTimeout(() => {
        // var content = `Qual o seu nome?`
        var content = `<span>Qual o seu nome?</span>`
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.insertMessagesAndWait(message)
      }, 1500);
    } else if (this.botStage == `phone`) {
      setTimeout(() => {
        // var content = `Olá ${this.application.current_visit.first_name}, já estou chamando nosso especialista, enquanto isso, qual o seu telefone? Se a conexão cair, iremos te ligar.`
        var content = `<span>Olá ${this.application.current_visit.first_name}, qual o seu telefone? Se a conexão cair ou não conseguirmos falar por agora, iremos te ligar.</span>`
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.insertMessagesAndWait(message)
      }, 1500);
    } else if (this.botStage == `calling`) {
      setTimeout(() => {
        var check = this.checkDatetime()
        if (check) {
          var content = `<span>Obrigado ${this.application.current_visit.first_name}. O nosso especialista entrará em breve aqui no Chat.</span>`
        } else {
          var content = `<span>Obrigado ${this.application.current_visit.first_name}. Estamos fora do nosso horário de atendimento. Deixe a sua mensagem e assim que possível entraremos em contato. Obrigado e conte com a @medcapital!.</span>`
        }
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.insertMessagesAndStop(message)
      }, 1500);
    } else if (this.botStage == `email`) {
      setTimeout(() => {
        // var content = `Obrigado ${this.application.current_visit.first_name}. Qual o seu principal email?`
        var content = `<span>Obrigado ${this.application.current_visit.first_name}. Qual o seu principal email?</span>`
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.insertMessagesAndWait(message)
      }, 1500);
    } else if (this.botStage == `product`) {
      setTimeout(() => {
        // var content = `Obrigado ${this.application.current_visit.first_name}. Qual o seu principal email?`
        var content = `<span>Obrigado ${this.application.current_visit.first_name}. E para qual serviço vamos te ajudar?</span>
                        <p>1 - Declaração IRPF Médico</p>
                        <p>2 - PJ Médica</p>
                        <p>3 - Livro-Caixa Médico</p>
                        <p>4 - Faturamento</p>
                        <p>5 - Seguros</p>
                        <p>6 - Previdência</p>`
        var message = { id: 1, created_at: new Date(), starred: false, sender_name: `MedCapital`, content: content }
        this.insertMessagesAndWait(message)
      }, 1500);
    }
  }

  preLoader() {
    var rowHtml = `<div class="chat-preloader" data-${this.controllerName}-target="loader">
                    <div class="animated-background animated-background-20">
                      <div class="background-masker title"></div>
                    </div>
                  </div>`

    this.application.chatController.messagesPreloaderBodyTarget.insertAdjacentHTML("beforeend", rowHtml)
  }

  insertMessagesAndNext(message) {
    this.application.messages[this.application.messages.length] = message
    if (this.hasLoaderTarget) {
      this.loaderTarget.remove()
    }
    this.application.messages[this.application.messages.length] = message
    var html = this.application.chatController.messageTablePartial(message)
    this.application.chatController.messagesBodyTarget.insertAdjacentHTML("beforeend", html)
    this.preLoader()
    this.inputElements(true)
    this.botChat()
  }

  insertMessagesAndWait(message) {
    this.application.messages[this.application.messages.length] = message
    if (this.hasLoaderTarget) {
      this.loaderTarget.remove()
    }
    var html = this.application.chatController.messageTablePartial(message)
    this.application.chatController.messagesBodyTarget.insertAdjacentHTML("beforeend", html)
    this.inputElements(false)
  }

  insertMessagesAndStop(message) {
    this.application.messages[this.application.messages.length] = message
    if (this.hasLoaderTarget) {
      this.loaderTarget.remove()
    }
    var html = this.application.chatController.messageTablePartial(message)
    this.application.chatController.messagesBodyTarget.insertAdjacentHTML("beforeend", html)
    this.inputElements(false)
    this.botStage == `agent`
    this.bot = false
  }

  inputElements(value) {
    this.application.chatController.textareaTarget.disabled = value
    this.application.chatController.inputTarget.disabled = value
    this.application.chatController.saveBtnTarget.disabled = value
    if (value == false) {
      this.application.chatController.inputTarget.focus()
    }
    this.rollMessages()
  }

  rollMessages() {
    $(this.application.chatController.messagesCardBodyTarget).stop().animate({ scrollTop: $(this.application.chatController.messagesCardBodyTarget)[0].scrollHeight }, 1000);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  checkDatetime() {
    var check = true
    var d = new Date()

    var weekDay = d.getDay()
    var dayHours = d.getHours()

    var chatDays = [1, 2, 3, 4, 5]

    if (chatDays.includes(weekDay)) {
      if (dayHours < 9 || dayHours > 17) {
        check = false
      }
    } else {
      check = false
    }

    return check
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
