import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dropdown", "input", "btn", "select", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--helpers--read-file`
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var fileKind = ev.currentTarget.dataset.kind
    var objId = ev.currentTarget.dataset.objId
    var objType = ev.currentTarget.dataset.objType

    var url = `/app/general/shared/files/read_file_url`
    var data = { record: { record_id: objId, record_type: objType, file_kind: fileKind }, current_user: { current_user_id: this.application.current_user.id, feature: this.feature_name } }
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var url = response.data.cln.file_url
          window.open(url, `_blank`)
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 4000)
        }
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}