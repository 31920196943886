import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewRegimeCard", "viewTitle", "viewRegimeCardBody", "previewCard", "previewTitle", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--receivables--add--show`
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.spaceRow = `<td colspan="5" style="height:0rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--dashboard`)
    }
  }

  doRevisionFormHtml() {

    if (this.current_receivable.main_invoice.invoice_number_pretty) {
      var invoiceNumber = this.current_receivable.main_invoice.invoice_number_pretty
    } else {
      var invoiceNumber = `Sem Número`
    }

    var html = `<div class="row my-3">
                  <div class="col-11 pl-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard-${this.current_receivable.id}" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Nota Fiscal #${invoiceNumber}</h6>
                        <h6 class="ml-auto card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Status da NF: ${this.current_receivable.main_invoice.full_status_message}</h6>
                      </div>
                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                        <div class="row my-3">
                          <div class="col-4">
                            ${this.doInfoHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doPerformersHtml()}
                          </div>
                          <div class="col-4">
                            ${this.doTaxesHtml()}
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-action="click->${this.controllerName}#goBack">Fechar</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-center pr-0" data-controller="operations--products--clinics--financials--receivables--action" data-operations--products--clinics--financials--receivables--action-target="action">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).permission = controller.integration_permission
      controller.getControllerByIdentifier(`operations--products--clinics--financials--receivables--action`).doActionHtml()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  goBack() {
    this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard").doReceivablesDashboard()
    // if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)) {
    //   this.getControllerByIdentifier("operations--products--clinics--clients--receivables--receivables--dashboard").doReceivablesDashboard()
    // } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--index--receivables--dashboard`)) {
    //   this.getControllerByIdentifier("operations--products--clinics--clients--receivables--index--receivables--list").doIndexListHtml()
    // }

  }

  doTaxesHtml() {
    var otherDeductions = Number(this.current_receivable.unconditioned_amount) + Number(this.current_receivable.conditioned_amount) + Number(this.current_receivable.others_amount_withheld)
    // `${this.numberController.currencyOptionMask(this.revision_data.receivable.others_amount_withheld, { scale: 2 })}`

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Valor dos Serviços e Valores de Impostos
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left px-0 f-065 f-bold">
                        Regime Tributário: ${this.current_receivable.tax_regime_pretty}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Condicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.conditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) D Incondicionado
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.unconditioned_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Base de Cálculo</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.calculation_basis, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (x) <strong>Alíquota ISS</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.percentOptionMask(this.current_receivable.iss_rate, { scale: 4 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Valor do ISS</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.iss_tax_amount, { scale: 4 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>Retenções Federais</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;"></td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) PIS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.pis_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) COFINS
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.cofins_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) IRPJ
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.irpj_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (+) CSLL
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.csll_amount_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>Valor dos Serviços</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_amount, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Outras Retenções / Descontos
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(otherDeductions, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) Retenções Federais
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.federal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (-) ISS Retido
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.municipal_withheld, { scale: 2 })}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        (=) <strong>Valor Líquido</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.net_amount, { scale: 2 })}
                      </td>
                    </tr>

                    <tr>
                      ${this.spaceRow}
                    </tr>

                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total Devido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_tax_amount)}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(-) Total Retido de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask((this.current_receivable.total_tax_amount - this.current_receivable.total_tax_payable))}
                      </td>
                    </tr>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        <strong>(=) Total à Pagar de Impostos</strong>
                      </td>
                      <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;">
                        ${this.numberController.currencyOptionMask(this.current_receivable.total_tax_payable)}
                      </td>
                    </tr>


                  </tbody>
                </table>`

    return html
  }

  doPerformersHtml() {
    var performerHtml = ``

    this.current_receivable.performers.forEach(element => {
      performerHtml += `<tr>
                        <td colspan="3" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          ${element.name}
                        </td>
                        <td colspan="2" class="f-065 align-middle px-0 text-left" style="font-size:80%;padding-top:0rem;padding-bottom:0rem;">
                          <strong>Valor</strong>: ${this.getControllerByIdentifier(`app--helpers--numbers`).currencyMask(element.amount * 100)}
                        </td>
                      </tr>`
    })

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Médicos
                      </td>
                    </tr>

                    ${performerHtml}
                  </tbody>
                </table>`

    return html
  }

  doInfoHtml() {
    if (this.current_receivable.taker_type == `legal`) {
      if (this.current_receivable.taker_municipal_tax_number) {
        var municipalNumber = `IM: ${this.current_receivable.taker_municipal_tax_number}`
      } else {
        var municipalNumber = `Sem Inscrição Municipal`
      }
      
      var federalNumber = `CNPJ: ${this.current_receivable.taker_federal_tax_number_pretty}`
    } else if (this.current_receivable.taker_type == `individual`) {
      var municipalNumber = ``
      var federalNumber = `CPF: ${this.current_receivable.taker_federal_tax_number_pretty}`
    }

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Competência</strong>: ${this.current_receivable.date_pretty} <br>
                        <strong>Data</strong>: ${this.current_receivable.issued_on_pretty}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        Tomador
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;">
                        ${this.current_receivable.taker_name} <br>
                        ${federalNumber} <br>
                        ${municipalNumber} <br>
                        <small>
                          ${this.current_receivable.taker_street}, ${this.current_receivable.taker_number}, ${this.current_receivable.taker_complement},
                          ${this.current_receivable.taker_district}, <br> ${this.current_receivable.taker_city_name}/${this.current_receivable.taker_state},
                          CEP: ${this.current_receivable.taker_postal_code_pretty}
                        </small>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Descrição</strong>: ${this.current_receivable.description}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-left f-bold f-085 px-0">
                        CTISS
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5" class="f-065 align-middle px-0 text-left">
                        <strong>Código</strong>: ${this.current_receivable.ctiss_code} <br>
                        <strong>Descrição</strong>: ${this.current_receivable.ctiss_description}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Natureza Operação
                      </td>
                      <td colspan="3" class="text-left f-bold f-085 px-0">
                        Cidade Tributação
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.current_receivable.taker_taxation_type_pretty}
                      </td>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.current_receivable.service_city_name}
                      </td>
                    </tr>
                    
                  </tbody>
                </table>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
