import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewDocumentCard", "viewTitle", "inProcessBtn", "documentationBtn", "draftBtn", "transmittBtn", "waitingBtn"]

  connect() {
    this.controllerName = `operations--products--tax-filings--process--board`
    this.doBoardHtml()
  }

  doWatingBoardHtml() {
    var loaderLg = `<div class='card timeline-item m-0 p-0 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`
    var loaderSm = `<div class='card timeline-item m-0 px-2 py-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`

    var html = `<div class="row mb-4">
                  <div class="col px-0 text-center" data-${this.controllerName}-target="waitingBtn">
                    <span class="f-065 text-bold">Aguarde um instante ... </span>
                    <span class="material-icons md-dark md-300">hourglass_empty</span>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").boardTarget.innerHTML = html
  }

  doBoardHtml() {

    // `<div class="row mb-4">
    //   <div class="col px-0 text-center">
    //     <button data-action="click->${this.controllerName}#goToDraftSent" data-${this.controllerName}-target="draftBtn" type="button" class="btn btn-sm btn-table btn-flat p-0 mc-tooltip">
    //       <span class="material-icons md-dark md-300">drafts</span>
    //       <span class="mc-tooltiptext">Enviar Rascunho</span>
    //     </button>
    //   </div>
    // </div>
    // <div class="row mb-4">
    //   <div class="col px-0 text-center">
    //     <button data-action="click->${this.controllerName}#goToTransmitted" data-${this.controllerName}-target="transmittBtn" type="button" class="btn btn-sm btn-table btn-flat p-0 mc-tooltip">
    //       <span class="material-icons md-dark md-300">done_all</span>
    //       <span class="mc-tooltiptext">Transmitir</span>
    //     </button>
    //   </div>
    // </div>`

    var html = `<div class="row mb-4">
                  <div class="col px-0 text-center">
                    <button data-action="click->${this.controllerName}#goToInProcess" data-${this.controllerName}-target="inProcessBtn" type="button" class="btn btn-sm btn-table btn-flat p-0 mc-tooltip">
                      <span class="material-icons md-dark md-300">play_circle_outline</span>
                      <span class="mc-tooltiptext">Processar Declaração</span>
                    </button>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col px-0 text-center">
                    <button data-action="click->${this.controllerName}#goToAwaitingDocumentation" data-${this.controllerName}-target="documentationBtn" type="button" class="btn btn-sm btn-table btn-flat p-0 mc-tooltip">
                      <span class="material-icons md-dark md-300">note_add</span>
                      <span class="mc-tooltiptext">Pedir Documentação</span>
                    </button>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").boardTarget.innerHTML = html)
    }).then(() => {
      controller.doJourneyProcess()
    })
  }

  doJourneyProcess() {
    if (this.application.current_tax_filing_calculation.journey_status == `awaiting_documentation`) {
      this.inProcessBtnTarget.classList.add("d-none")
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.enquiryBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `documentation_sent`) {
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.enquiryBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `in_process`) {
      this.inProcessBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.enquiryBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `draft_sent`) {
      this.inProcessBtnTarget.classList.add("d-none")
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.enquiryBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `awaiting_transmission`) {
      this.inProcessBtnTarget.classList.add("d-none")
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.enquiryBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `transmitted`) {
      this.inProcessBtnTarget.classList.add("d-none")
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
    } else if (this.application.current_tax_filing_calculation.journey_status == `enquiry`) {
      this.inProcessBtnTarget.classList.add("d-none")
      this.documentationBtnTarget.classList.add("d-none")
      // this.draftBtnTarget.classList.add("d-none")
      // this.retificationBtnTarget.classList.add("d-none")
      // this.transmittBtnTarget.classList.add("d-none")
    }
  }

  goToInProcess() {
    var message = ``
    if (this.getControllerByIdentifier("operations--products--tax-filings--process--index").current_files.length == 0) {
      message = `Favor processar os arquivos antes de processar a Declaração!`
      alert(message)
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, message, 4000)
    } else if (this.getControllerByIdentifier("operations--products--tax-filings--process--index").not_revised_files.length > 0) {
      message = `Existem arquivos que ainda não forma processados! Favor processar os arquivos antes de processar a Declaração!`
      alert(message)
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Existem arquivos que ainda não forma processados! Favor processar os arquivos antes de processar a Declaração!`, 4000)
    } else {
      this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
      this.send_data.journey.status = `in_process`
      var r = confirm(`Tem certeza que deseja Processar a Declaração?`)
      if (r) {
        this.createNewJourney()
      }
    }
  }

  goToAwaitingDocumentation() {
    this.send_data = { current_user: {}, journey: {}, tracker: { manual: true } }
    this.send_data.journey.status = `awaiting_documentation`
    var r = confirm(`Tem certeza que deseja pedir nova documentação?`)
    if (r) {
      this.createNewJourney()
    }
  }

  goToDraftSent() {

  }

  goToTransmitted() {

  }

  createNewJourney() {
    // this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").boardTarget.innerHTML = ``
    this.doWatingBoardHtml()

    this.actionMode = `new`
    this.send_data.journey.tax_filing_id = this.application.tax_filing.id
    this.send_data.journey.date_id = this.application.current_date.id
    this.send_data.journey.date = new Date()

    var newStatus = this.getControllerByIdentifier(`operations--products--tax-filings--process--dashboard`).translateJourney(this.send_data.journey.status)
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `changed`
    this.send_data.tracker.observations = `Mudou a Jornada do IRPF ${this.application.current_date.financial_year} para ${newStatus}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/operations/products/tax_filings/journeys/create"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var journey = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.journeys[controller.application.journeys.length] = journey
          }
          controller.sendJourneyEmailNotification(journey)
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendJourneyEmailNotification(journey) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.journey_permission

    this.send_data.notification.journey_id = journey.id
    this.send_data.notification.product_id = this.application.tax_filing.id
    this.send_data.notification.product_token = this.application.tax_filing.token
    this.send_data.notification.product_name = this.application.tax_filing.product_name
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = `operation_dates`
    this.send_data.notification.receiver_id = this.application.tax_filing.account_id
    this.send_data.notification.receiver_name = this.application.tax_filing.account_name
    this.send_data.notification.receiver_kind = `doctor`
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `mudou o status da sua Declaração IRPF ${this.application.current_date.financial_year} para`
    this.send_data.notification.body = journey.status_pretty

    var url = "/operations/products/tax_filings/journeys/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
