import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "indicatorsCard", "pipelinesCard", "doneCard", "indicatorsRow", "pipelinesRow", "donesRow",
    "calculationsCard", "calculationsRow", "calculationDateRow"]

  connect() {
    this.controllerName = `users--works--meetings--reports--main`

    this.calculation = {}
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doMainGrid() {
    var html = `<div class="row my-3">
                  <div class="col-1 px-2">Relatórios</div>
                  <div class="col-11 px-2">
                    <div class="row w-100">
                      <div class="col-2 px-2">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="indicatorsCard" data-feature="indicator">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1 mb-0 f-075"><strong>Indicadores</strong></h6>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row my-3" data-controller="users--works--meetings--reports--indicators">
                  <div class="col-12 px-2">
                    <div class="row" data-${this.controllerName}-target="indicatorsRow">
                      <div class="col-12 px-2">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Indicadores</span>
                          <span class="d-flex" data-users--works--meetings--reports--indicators-target="indicadorDateRow"></span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="indicatorsRow">
                      <div class="col-12 px-2" data-users--works--meetings--reports--indicators-target="indicatorsTable">
                        <span class="w-100">${this.loader}</span>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("users--works--meetings--dashboard--index").contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--works--meetings--reports--indicators").doIndicatorsLoaderHTML()
    })
  }

  toggleOnboard(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "indicator") {
      this.indicatorsCardTarget.classList.add("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.indicatorsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

    } else if (feature == "done") {
      this.indicatorsCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.add("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.indicatorsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("users--works--meetings--reports--dones").doDoneReportHTML()
    } else if (feature == "pipeline") {
      this.indicatorsCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.add("bg-primary")
      this.calculationsCardTarget.classList.remove("bg-primary")
      this.indicatorsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })

      this.getControllerByIdentifier("users--works--meetings--reports--pipeline").doPipelineReportHTML()
    } else if (feature == "calculation") {
      this.indicatorsCardTarget.classList.remove("bg-primary")
      this.doneCardTarget.classList.remove("bg-primary")
      this.pipelinesCardTarget.classList.remove("bg-primary")
      this.calculationsCardTarget.classList.add("bg-primary")
      this.indicatorsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.donesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pipelinesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.calculationsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })

      this.getControllerByIdentifier("users--works--meetings--reports--calculations").doCalculationReportHTML()
    }

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
