import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "goalDashboard", "taxReturnDashboard", "5555", "subtitle", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-returns--entities--show`
    this.application.domain = {}
    this.application.tax_return_token = location.pathname.split("/").pop()
    this.application.chart_accounts = []
    this.application.patients = []
    this.application.sources = []
    this.application.incomes = []
    this.application.payments = []
    this.application.documents = []
    this.application.members = []
    this.application.goals = []
    this.doTaxReturnGrid()

    // getCurrentUserPermissions
    // getTaxReturn
    // getTaxReturnDates
    // getTaxReturnAchievements
    // getTaxReturnCalculations
    // getIncomes
    // getPayments
    // getDocuments
    // getSources
    // getPatients
    // getMembers
    // getGoals
  }

  doTaxReturnGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="operations--products--tax-returns--entities--dashboard
                                                            operations--products--tax-returns--incomes--dashboard
                                                            operations--products--tax-returns--payments--dashboard
                                                            operations--products--tax-returns--documents--dashboard
                                                            operations--products--tax-returns--settings--dashboard
                                                            operations--products--tax-returns--sources--dashboard
                                                            operations--products--tax-returns--achievements--dashboard" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    this.mainTarget.style.height = ""
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").doTaxReturnDashboard()
      controller.getCurrentUserPermissions()
    })
  }

  doSubtitle() {
    this.subtitleTarget.innerText = `Gestão do meu Planner Financeiro ${this.application.current_date.year} e do Imposto de Renda Anual ${this.application.current_date.year + 1}`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getPayments() {
    var data = { payment: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/payments/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.payments = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getChartAccounts()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getIncomes() {
    var data = { income: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/incomes/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.incomes = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getDocuments() {
    var data = { document: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/documents/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.documents = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSources() {
    var data = { source: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/sources/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.sources = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getPatients() {
    var data = { patient: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/patients/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.patients = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getMembers() {
    var data = { member: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/members/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.members = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getChartAccounts() {
    const data = { chart_account: { active: true }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/chart_accounts/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.application.chart_accounts = response.data.cln
        } else {
          this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
  }

  getTaxReturnAchievements() {
    var data = { achievement: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/achievements/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.achievements = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").doAchievementsHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getGoals() {
    var data = { goal: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/goals/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.goals = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxReturnCalculations() {
    var data = { calculation: { tax_return_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/calculations/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.calculations = response.data.cln
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-returns--entities--dashboard").doTaxReturnCalculationsHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxReturn() {
    var data = { product: { token: this.application.tax_return_token, name: "medreturn" }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.tax_return = response.data.cln
          controller.application.domain_type = "Planner"
          controller.application.domain.type = "Planner"
          controller.application.domain.id = controller.application.tax_return.id
          controller.getControllerByIdentifier(`app--navigation--desktop--breadcrumb`).breadcrumbNameTarget.innerText = controller.application.tax_return.account_name
          controller.getAccountProduct()
          
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getAccountProduct() {
    var data = { account: { account_id: this.application.tax_return.account_id }, current_user: { current_user_id: currentUser.id, feature: `operation_account_products` } }
    const url = "/operations/accounts/products/read_with_account"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln
          controller.getTaxReturnDates()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getTaxReturnDates() {
    var data = { date: { product_id: this.application.tax_return.id }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/products/tax_returns/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.dates = response.data.cln
          controller.application.current_date = response.data.cln[0]
          if (controller.application.current_date) {
            controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            controller.getControllerByIdentifier("operations--products--tax-returns--entities--dates").doCalendarHTML()

            controller.getTaxReturnAchievements()
            controller.getTaxReturnCalculations()
            controller.getIncomes()
            controller.getPayments()
            // controller.getDocuments()
            controller.getSources()
            controller.getPatients()
            controller.getMembers()
            controller.getGoals()
            controller.doSubtitle()
          } else {
            controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor adicionar a Competência, no Submenu > Configurações > Competências`, 3000)
            controller.application.current_date = { open: false, not_defined: true, message: `O Período está fechado` }
          }
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
        controller.getControllerByIdentifier("operations--products--tax-returns--entities--broadcast").getRoom()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    const featureNames = ["medreturn", "medreturn_companies", "medreturn_patients", "medreturn_chart_accounts", "medreturn_members",
                          "medreturn_incomes", "medreturn_payments", "medreturn_documents", "medreturn_goals", "medreturn_achievements",
                          "medreturn_goal_transactions", "medreturn_dates"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user_id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });
        controller.getTaxReturn()
      })
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
