import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "saveBtn", "chooseType", "errorMessage",
                    "sexDropdown", "sexDropdownBtn", "sexInput", "sexList", "helperCheck", "sponsorCheck", "pjPartnerInfo",
                    "financialCheckbox", "administrativeCheckbox", "doctorCheckbox", "legalCheckbox", "technicalCheckbox", "operationalCheckbox",
                    "employmentInput", "employmentList", "entriedAtInput", "sharesInput", "pfPartnerCheckbox", "pjPartnerCheckbox",
                    "pfPartnerInfo", "cnpjInput", "cpfInputDiv", "cnpjInputDiv", "pfPartnerCheckbox", "pjPartnerCheckbox",
                    "tradeNameInput", "fantasyNameInput", "partnerInfo"]


  connect() {
    this.controllerName = `operations--products--clinics--regularizations--partners--save`
    this.search = true
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--index").clearList()
  }

  savePartner() {
    this.send_data = { current_user: {}, account: {}, company: {}, partner: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.pfPartnerCheckboxTarget.checked) {
      this.send_data.account.is_account = true
      this.send_data.company.is_company = false
      this.send_data.partner.id_type = "individual"
      this.send_data.partner.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
      this.send_data.partner.name = this.nameInputTarget.value.trim()

      if (this.account.id) {
        this.send_data.account.id = this.account.id
        this.send_data.partner.record_id = this.account.id
      } else {
        this.send_data.account.name = this.nameInputTarget.value.trim()
        this.send_data.account.cpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.cpfInputTarget.value)
        this.send_data.account.sex = this.sexInputTarget.dataset.sex
        this.send_data.account.birthdate = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.birthdateInputTarget.value)
      }

      if (this.doctorCheckboxTarget.checked) {
        this.send_data.account.kind = `doctor`
      } else {
        this.send_data.account.kind = `non_doctor`
      }

    } else if (this.pjPartnerCheckboxTarget.checked) {
      this.send_data.account.is_account = false
      this.send_data.company.is_company = true
      this.send_data.partner.id_type = "legal"
      this.send_data.partner.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value)
      this.send_data.partner.name = this.tradeNameInputTarget.value.trim()

      if (this.company.id) {
        this.send_data.company.id = this.company.id
        this.send_data.partner.record_id = this.company.id
      } else {
        this.send_data.company.trade_name = this.tradeNameInputTarget.value.trim()
        this.send_data.company.name = this.fantasyNameInputTarget.value.trim()
        this.send_data.company.cnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.cnpjInputTarget.value)
      }

      this.send_data.company.kind = `taker`
    }


    this.send_data.partner.clinic_id = this.application.clinic.id
    this.send_data.partner.entried_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.entriedAtInputTarget.value)
    this.send_data.partner.shares = this.sharesInputTarget.value
    this.send_data.partner.financial = this.financialCheckboxTarget.checked
    this.send_data.partner.administrative = this.administrativeCheckboxTarget.checked
    this.send_data.partner.doctor = this.doctorCheckboxTarget.checked
    this.send_data.partner.legal = this.legalCheckboxTarget.checked
    this.send_data.partner.technical = this.technicalCheckboxTarget.checked
    this.send_data.partner.operational = this.operationalCheckboxTarget.checked

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/clinics/regularizations/partners/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var partner = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.partners[controller.application.partners.length] = partner
          } else if (this.actionMode == `edit`) {
            controller.application.partners.forEach((element, i) => {
              if (element.id == partner.id) {
                controller.application.partners.splice(controller.application.partners.indexOf(element), 1, partner)
              }
            })
          }
          
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePartner" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }
    // if (this.application.medclinic_chart_accounts.can_delete && this.actionMode == "edit") {
    //   var deleteBtnHtml = `<button disabled type="button" class="btn btn-danger btn-sm" data-action="click->${this.controllerName}#deleteHelper" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    // } else {
    //   var deleteBtnHtml = ``
    // }

    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Sócio</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row mt-3 d-flex align-items-center" style="height:60px;">
                      <div class="col-4">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="pfPartnerCheck" data-${this.controllerName}-target="pfPartnerCheckbox" data-type="individual" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="pfPartnerCheck">Sócio PF</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="pjPartnerCheck" data-${this.controllerName}-target="pjPartnerCheckbox" data-type="legal" data-action="click->${this.controllerName}#typeCheck">
                          <label class="custom-control-label f-065 pointer" for="pjPartnerCheck">Sócio PJ</label>
                        </div>
                      </div>
                      <div class="col-4 px-2 d-flex align-items-center" data-${this.controllerName}-target="chooseType">
                        <span class="f-065">Escolha o Tipo de Sócio</span>
                      </div>
                      <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cpfInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF Sócio</label>
                            <input aria-describedby="cpfFormHelp" class="form-control" id="cpfForm" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask keyup->${this.controllerName}#cpfValidate blur->${this.controllerName}#cpfValidate keyup->${this.controllerName}#searchAccount blur->${this.controllerName}#searchAccount" placeholder="CPF Sócio" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2 d-none" data-${this.controllerName}-target="cnpjInputDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ Sócio</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control" id="cnpjForm" data-${this.controllerName}-target="cnpjInput" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask keyup->${this.controllerName}#cnpjValidate blur->${this.controllerName}#cnpjValidate keyup->${this.controllerName}#searchCompany blur->${this.controllerName}#searchCompany" placeholder="CNPJ Sócio" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="pfPartnerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Sócio</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Sócio" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pfPartnerInfo">
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="birthdateForm">Data Nascimento</label>
                            <input aria-describedby="birthdateFormHelp" class="form-control" id="birthdateForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="birthdateInput" placeholder="DD/MM/AAAA" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Sexo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sexDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sexDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sexInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sexList" data-app--helpers--search-target="searchList">
                                  <li data-sex="male" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Masculino</li>
                                  <li data-sex="female" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Feminino</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pjPartnerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="tradeNameForm">Razão Social</label>
                            <input aria-describedby="tradeNameFormHelp" class="form-control" id="tradeNameForm" data-${this.controllerName}-target="tradeNameInput" placeholder="Razão Social" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row d-none" data-${this.controllerName}-target="pjPartnerInfo">
                      <div class="col-8 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="fantasyNameForm">Nome Fantasia</label>
                            <input aria-describedby="fantasyNameFormHelp" class="form-control" id="fantasyNameForm" data-${this.controllerName}-target="fantasyNameInput" placeholder="Nome Fantasia" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-none" data-${this.controllerName}-target="partnerInfo">
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="entriedAtForm">Data de Entrada</label>
                            <input aria-describedby="entriedAtFormHelp" class="form-control" id="entriedAtForm" data-action="blur->${this.controllerName}#dateMask keypress->${this.controllerName}#dateMask keyup->${this.controllerName}#dateValidate blur->${this.controllerName}#dateValidate" data-${this.controllerName}-target="entriedAtInput" placeholder="DD/MM/AAAA" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="sharesInputForm">Cotas</label>
                            <input aria-describedby="sharesInputFormHelp" class="form-control" data-${this.controllerName}-target="sharesInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask keyup->${this.controllerName}#numberMask" placeholder="Cotas" type="text" required>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row my-2 d-none" data-${this.controllerName}-target="partnerInfo">
                      <div class="col-6">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="doctorCheck" data-${this.controllerName}-target="doctorCheckbox">
                          <label class="custom-control-label f-065 pointer" for="doctorCheck">Médico</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">É médico?</span>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="operationalCheck" data-${this.controllerName}-target="operationalCheckbox">
                          <label class="custom-control-label f-065 pointer" for="operationalCheck">Operacional</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Emite NF pela empresa?</span>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="financialCheck" data-${this.controllerName}-target="financialCheckbox">
                          <label class="custom-control-label f-065 pointer" for="financialCheck">Financeiro</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Paga mensalidade?</span>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="administrativeCheck" data-${this.controllerName}-target="administrativeCheckbox">
                          <label class="custom-control-label f-065 pointer" for="administrativeCheck">Administrador</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">É o Sócio-Administrador?</span>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="legalCheck" data-${this.controllerName}-target="legalCheckbox">
                          <label class="custom-control-label f-065 pointer" for="legalCheck">Legal</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">É o Responsável Legal?</span>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
                          <input type="checkbox" class="custom-control-input" id="technicalCheck" data-${this.controllerName}-target="technicalCheckbox">
                          <label class="custom-control-label f-065 pointer" for="technicalCheck">Técnico</label>
                          <span class="mc-tooltiptext mc-tooltiptext-right">É o Responsável Técnico?</span>
                        </div>
                      </div>
                    </div>

                    
                    
                 
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <span class="mr-auto text-left f-danger my-auto" data-${this.controllerName}-target="errorMessage"></span>
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.refreshSaveBtn()
      // controller.pfPartnerCheckboxTarget.disabled = true
      // controller.pjPartnerCheckboxTarget.disabled = true
      // controller.pfPartnerCheckboxTarget.checked = true
      // controller.pjPartnerCheckboxTarget.checked = false
      
      if (controller.pfPartnerCheckboxTarget.checked) {
        controller.chooseTypeTarget.classList.remove("d-none")
        controller.cpfInputDivTarget.classList.remove("d-none")
        controller.cnpjInputDivTarget.classList.add("d-none")
        controller.partnerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pfPartnerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pjPartnerInfoTargets.forEach(element => {
          element.classList.add("d-none")
        })
        controller.nameInputTarget.disabled = true
        controller.birthdateInputTarget.disabled = true
        controller.sexDropdownBtnTarget.disabled = true
        controller.sharesInputTarget.value = 0
      } else if (controller.pjPartnerCheckboxTarget.checked) {
        controller.chooseTypeTarget.classList.remove("d-none")
        controller.partnerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.pfPartnerInfoTargets.forEach(element => {
          element.classList.add("d-none")
        })
        controller.pjPartnerInfoTargets.forEach(element => {
          element.classList.remove("d-none")
        })
        controller.cpfInputDivTarget.classList.add("d-none")
        controller.cnpjInputDivTarget.classList.remove("d-none")
      }

      if (controller.actionMode == `new`) {
        controller.doctorCheckboxTarget.checked = true
        controller.entriedAtInputTarget.value = controller.application.clinic.company_opened_at_pretty
      } else {
        
      }
      
      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  typeCheck(ev) {
    var type = ev.currentTarget.dataset.type

    if (type == "individual") {
      this.pfPartnerCheckboxTarget.checked = true
      this.pjPartnerCheckboxTarget.checked = false
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.remove("d-none")
      this.cnpjInputDivTarget.classList.add("d-none")

      this.partnerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pfPartnerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pjPartnerInfoTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.cpfInputTarget.focus()
    } else if (type == "legal") {
      this.pfPartnerCheckboxTarget.checked = false
      this.pjPartnerCheckboxTarget.checked = true
      this.chooseTypeTarget.classList.add("d-none")
      this.cpfInputDivTarget.classList.add("d-none")
      this.cnpjInputDivTarget.classList.remove("d-none")

      this.partnerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.pfPartnerInfoTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.pjPartnerInfoTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.cnpjInputTarget.focus()
    }
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  numberMask(ev) {
    // this.getControllerByIdentifier("app--helpers--numbers").changeNumberToNumber(ev)
  }

  dateValidate(ev) {
    var cleanDate = this.getControllerByIdentifier("app--helpers--numbers").fromDateToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanDate.length == 8) || ev.type == "blur" && cleanDate.length == 8) {
      this.checkDate = this.getControllerByIdentifier("app--helpers--date").checkDate(ev.target.value)
      if (this.checkDate.valid) {
        this.birthdateInputTarget.classList.add("f-normal")
        this.birthdateInputTarget.classList.remove("f-danger")
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.checkDate.message, 3000)
        this.birthdateInputTarget.classList.add("f-danger")
        this.birthdateInputTarget.classList.remove("f-normal")
      }
    } else {
      this.birthdateInputTarget.classList.add("f-normal")
      this.birthdateInputTarget.classList.remove("f-danger")
    }
  }

  searchAccount(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCpf.length < 11) {
      this.nameInputTarget.value = ""
      this.birthdateInputTarget.value = ""
      this.sexDropdownTarget.value = ""
      this.sexInputTarget.innerText = ""
      this.sexInputTarget.dataset.sex = ""

      this.nameInputTarget.disabled = false
      this.birthdateInputTarget.disabled = false
      this.sexDropdownBtnTarget.disabled = false
      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()

    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11 && this.nameInputTarget.value == "" && this.search) {
      this.search = false
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      var data = { account: { cpf: cleanCpf, kind: "doctor" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/accounts/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      var controller = this

      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
        var hasPartner = this.checkIfHasPartner(`individual`, cleanCpf)
        if (hasPartner) {
          // this.setErrorMessage("message")
        } else {
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              controller.search = true
              if (response.process) {
                controller.account = response.data.cln
                controller.nameInputTarget.disabled = false
                if (controller.account.id) {
                  controller.nameInputTarget.value = controller.account.name
                  controller.birthdateInputTarget.value = controller.account.birthdate_pretty
                  controller.sexInputTarget.innerText = controller.account.sex_pretty
                  controller.sexInputTarget.dataset.sex = controller.account.sex
                  controller.sexDropdownTarget.value = controller.account.sex

                  controller.nameInputTarget.disabled = true
                  controller.birthdateInputTarget.disabled = true
                  controller.sexDropdownBtnTarget.disabled = true

                } else {
                  controller.nameInputTarget.value = "Favor adicionar o Nome do Sócio"

                  controller.nameInputTarget.disabled = false
                  controller.birthdateInputTarget.disabled = false
                  controller.sexDropdownBtnTarget.disabled = false
                }
                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }
        
      }
    }
  }

  searchCompany(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if (ev.type == "keyup" && cleanCnpj.length < 14) {
      this.fantasyNameInputTarget.value = ""
      this.tradeNameInputTarget.value = ""
      // this.openedAtTarget.value = ""

      this.fantasyNameInputTarget.disabled = true
      this.tradeNameInputTarget.disabled = true
      // this.openedAtTarget.disabled = true

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      // } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14 && this.nameInputTarget.value == "") {
    } else if (ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      var data = { company: { cnpj: cleanCnpj, kind: "" }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
      const token = $('meta[name=csrf-token]').attr('content');
      const url = "/users/companies/entities/read"
      const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
      if (this.cnpj_result.invalid == false) {
        var hasPartner = this.checkIfHasPartner(`legal`, cleanCnpj)
        if (hasPartner) {
          // this.setErrorMessage("O PJ Médica já possui o produto Declaração de Ajuste Anual")
        } else {
          var controller = this
          fetch(url, init)
            .then(response => response.json())
            .then(response => {
              if (response.process) {
                controller.company = response.data.cln

                if (controller.company.id) {
                  controller.fantasyNameInputTarget.value = controller.company.name
                  controller.tradeNameInputTarget.value = controller.company.trade_name
                  if (controller.company.opened_at_pretty) {
                    // controller.openedAtTarget.value = controller.company.opened_at_pretty
                  } else {
                    // controller.openedAtTarget.value = ``
                  }

                  controller.fantasyNameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  // controller.openedAtTarget.disabled = false

                  controller.actionMode = `edit`
                } else {
                  controller.tradeNameInputTarget.value = "Favor adicionar a Razão Social da PJ Médica"
                  controller.fantasyNameInputTarget.value = "Favor adicionar o Nome Fantasia"
                  // controller.openedAtTarget.value = ""

                  controller.fantasyNameInputTarget.disabled = false
                  controller.tradeNameInputTarget.disabled = false
                  // controller.openedAtTarget.disabled = false

                  controller.actionMode = `new`
                }

                controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
              } else {
                controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
              }
            })
            .catch(error => {
              controller.getControllerByIdentifier("app--helpers--console").console(error)
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
            })
        }
      }
    }
  }

  checkIfHasPartner(type, number) {
    var diffHelper = true
    this.application.partners.forEach(element => {
      if (element.id_number == number) {
        this.saveBtnTarget.disabled = true
        this.saveBtnTarget.classList.add("d-none")
        var message = `O Sócio já está cadastrado`
        this.setErrorMessage(message)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", message, 2000)
        diffHelper = false
      } else {
        if (diffHelper) {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
      }
    });
  }

  setErrorMessage(message) {
    this.errorMessageTarget.innerText = message
    var controller = this
    setTimeout(() => {
      controller.errorMessageTarget.innerText = ``
    }, 3000);
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length == 11) || ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.cpfInputTarget.classList.add("f-danger")
        this.cpfInputTarget.classList.remove("f-normal")
      } else {
        this.cpfInputTarget.classList.add("f-normal")
        this.cpfInputTarget.classList.remove("f-danger")
      }
    } else if ((ev.type == "keyup" && ev.shiftKey == false && cleanCpf.length > 11) || ev.type == "blur" && cleanCpf.length > 11) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CPF Inválido`, 3000)
      this.cpfInputTarget.classList.add("f-danger")
      this.cpfInputTarget.classList.remove("f-normal")
    } else {
      this.cpfInputTarget.classList.add("f-normal")
      this.cpfInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(ev.target.value)
    if ((ev.type == "keyup" && ev.shiftKey == false && cleanCnpj.length == 14) || ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.cnpjInputTarget.classList.add("f-danger")
        this.cnpjInputTarget.classList.remove("f-normal")
      } else {
        this.cnpjInputTarget.classList.add("f-normal")
        this.cnpjInputTarget.classList.remove("f-danger")
      }
    } else {
      this.cnpjInputTarget.classList.add("f-normal")
      this.cnpjInputTarget.classList.remove("f-danger")
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasSaveBtnTarget) {
        if (controller.pfPartnerCheckboxTarget.checked) {
          if (controller.cpf_result && controller.cpf_result.invalid) {
            len += 1
          }

          if (controller.cpfInputTarget.value == ``) {
            len += 1
          }

          if (controller.hasNameInputTarget && (controller.nameInputTarget.value == `Favor adicionar o Nome do Sócio` || controller.nameInputTarget.value == ``)) {
            len += 1
          }

          if (controller.birthdateInputTarget.value == ``) {
            len += 1
          }

          if (controller.sexInputTarget.innerText == ``) {
            len += 1
          }
        } else if (controller.pjPartnerCheckboxTarget.checked) {
          if (controller.cnpj_result && controller.cnpj_result.invalid) {
            len += 1
          }

          if (controller.cnpjInputTarget.value == ``) {
            len += 1
          }

          if (controller.tradeNameInputTarget.value == ``) {
            len += 1
          }

          if (controller.fantasyNameInputTarget.value == ``) {
            len += 1
          }

        }
        
        
        if (controller.entriedAtInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.sharesInputTarget.value == ``) {
          len += 1
        }
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

  // <div class="row my-2">
  //   <div class="col-6">
  //     <div class="form-group">
  //       <div class="floating-label floating-label-sm">
  //         <label>Função</label>
  //         <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="roleDropdown">
  //           <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="roleDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="roleInput"></span></button>
  //           <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
  //             <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
  //             <ul class="ul-select" data-${this.controllerName}-target="roleList app--helpers--search.searchList">
  //               <li data-role="manager" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Gerente</li>
  //               <li data-role="biller" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturista</li>
  //               <li data-role="receptionist" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recepcionista</li>
  //               <li data-role="technician" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Técnica</li>
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  //   <div class="col-6">
  //     <div class="form-group">
  //       <div class="floating-label floating-label-sm">
  //         <label>Vínculo</label>
  //         <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="employmentDropdown">
  //           <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="employmentDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="employmentInput"></span></button>
  //           <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
  //             <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
  //             <ul class="ul-select" data-${this.controllerName}-target="employmentList app--helpers--search.searchList">
  //               <li data-employment="wage" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Assalariado</li>
  //               <li data-employment="selfworker" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Autônomo</li>
  //               <li data-employment="labor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pró-Labore</li>
  //               <li data-employment="partner" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sócio</li>
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>


  // `<div class="row my-2 d-none">
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="doctorCheck" data-${this.controllerName}-target="doctorCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="doctorCheck">Médico</label>
  //       <span class="mc-tooltiptext">É médico?</span>
  //     </div>
  //   </div>
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="operationalCheck" data-${this.controllerName}-target="operationalCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="operationalCheck">Operacional</label>
  //       <span class="mc-tooltiptext">Emite NF pela empresa?</span>
  //     </div>
  //   </div>
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="financialCheck" data-${this.controllerName}-target="financialCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="financialCheck">Financeiro</label>
  //       <span class="mc-tooltiptext">Paga mensalidade?</span>
  //     </div>
  //   </div>
  // </div>
  // <div class="row my-2 d-none">
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="administrativeCheck" data-${this.controllerName}-target="administrativeCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="administrativeCheck">Administrador</label>
  //       <span class="mc-tooltiptext">É o Sócio-Administrador?</span>
  //     </div>
  //   </div>
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="legalCheck" data-${this.controllerName}-target="legalCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="legalCheck">Legal</label>
  //       <span class="mc-tooltiptext">É o Responsável Legal?</span>
  //     </div>
  //   </div>
  //   <div class="col-4 d-flex">
  //     <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex mc-tooltip">
  //       <input type="checkbox" class="custom-control-input" id="technicalCheck" data-${this.controllerName}-target="technicalCheckbox">
  //       <label class="custom-control-label f-065 pointer" for="technicalCheck">Técnico</label>
  //       <span class="mc-tooltiptext">É o Responsável Técnico?</span>
  //     </div>
  //   </div>
  // </div>`

}
