import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "productInput", "kindInput", "startedAtInput", "nextBtn", "amountInput",
                    "saveProductBtn", "yearDropdown", "yearDropdownBtn", "yearInput", "yearList",
                    "saveProductBtn", "planDropdown", "planDropdownBtn", "planInput", "planList"]

  connect() {
    this.controllerName = `operations--products--tax-filings--add--product`
    this.account = {}
    this.send_data = { current_user: {}, account: {}, product: {} }
  }

  previousStep() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-filings--add--contacts").doContactFormHtml()
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").current_step = 1
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").setStep()
  }

  saveProduct() {
    this.saveProductBtnTarget.disabled = true
    this.stopProductRefreshing()

    this.account = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").account

    this.send_data.account.account_id = this.account.id
    this.send_data.account.token = this.account.token
    this.send_data.product.account_id = this.account.id
    this.send_data.product.name = `medfiling`
    this.send_data.product.kind = `receivement`
    this.send_data.product.status = `onboard`
    // this.send_data.product.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.product.amount = 1
    // this.send_data.product.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtInputTarget.value)
    var started_at = new Date(this.yearInputTarget.dataset.year, 0, 1)
    this.send_data.product.started_at = started_at
    this.send_data.product.plan = this.planInputTarget.dataset.plan

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `medfiling_teams`

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/entities/create"
    var method = "POST"
    
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var product = response.data.cln
          controller.application.tax_filings[controller.application.tax_filings.length] = product
          controller.saveProductBtnTarget.innerText = `Confirmado`
          controller.saveProductBtnTarget.disabled = true
          controller.productConfirmed = true
        } else {
          controller.saveProductBtnTarget.innerText = `Salvar`
          controller.saveProductBtnTarget.disabled = false
          controller.productConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  nextStep() {
    this.stopRefreshing()
    this.stopProductRefreshing()
    this.nextBtnTarget.disabled = true

    var controller = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard")

    controller.send_product_data.product.name = `medfiling`
    controller.send_product_data.product.name_pretty = `Declaração de Ajuste Anual`
    controller.send_product_data.product.kind = `receivement`
    controller.send_product_data.product.kind_pretty = `Recebimento`
    controller.send_product_data.product.started_at = this.send_data.product.started_at
    // controller.send_product_data.product.started_at_pretty = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtInputTarget.value)
    controller.send_product_data.product.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    controller.send_product_data.product.kind_pretty = `Recebimento`
    controller.send_product_data.product.status = `going`

    var account_controller = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard")

    if (account_controller.account.user_id) {
      this.getControllerByIdentifier("operations--products--tax-filings--index--list").doIndexListHtml()
    } else {
      this.getControllerByIdentifier("operations--products--tax-filings--add--user").doUserFormHtml()
    }

  }

  doProductFormHtml() {
    var html = this.stopRefreshing()

      // `<div class="form-group form-valid-group">
      //   <div class="floating-label floating-label-sm">
      //     <label for="startedAtForm">Início Em</label>
      //     <input aria-describedby="startedAtFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="startedAtInput" placeholder="Início Em" type="text" required>
      //   </div>
      // </div>`

    var html = `<div class="col-6 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando Produto IRPF</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="productForm">Produto</label>
                              <input aria-describedby="productFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="productInput" placeholder="Produto" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="kindForm">Tipo</label>
                              <input aria-describedby="kindFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="kindInput" placeholder="Nome Médico" type="text" required>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="floating-label floating-label-sm">
                            <label>Ano-Exercício</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="yearDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="yearDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="yearInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="yearList">
                                  <li data-year="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2024</li>
                                  <li data-year="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>
                                  <li data-year="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>
                                  <li data-year="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>
                                  <li data-year="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>
                                  <li data-year="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>
                                  <li data-year="2024" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2025</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="floating-label floating-label-sm">
                            <label>Plano</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="planDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="planDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="planInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="planList">
                                  <li data-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>
                                  <li data-plan="pro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>
                                  <li data-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>
                                  <li data-plan="enterprise" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Enterprise</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="row my-2 d-none">
                        <div class="col-6">
                          <div class="form-group form-valid-group">
                            <div class="floating-label floating-label-sm">
                              <label for="amountForm">Valor</label>
                              <input aria-describedby="amountFormHelp" class="form-control form-valid-control" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Nome Médico" type="text" required>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2 mb-4">
                        <div class="col-12 px-1 text-right">
                          <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveProduct" data-${this.controllerName}-target="saveProductBtn">Salvar</button>
                        </div>
                      </div>                
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->operations--products--tax-filings--add--dashboard#changeStep click->${this.controllerName}#nextStep" data-next="3" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    // `<button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#previousStep">Voltar</button>`
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      // controller.getControllerByIdentifier("app--helpers--pickdate").pick($(this.startedAtInputTarget), { max: true, months: true, years: 60 })
      controller.formCardTarget.style.height = ($(window).height() * 0.4) + "px"
      controller.productInputTarget.value = `Declaração de Ajuste Anual`
      controller.kindInputTarget.value = `Recebimento`
      controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
      controller.productInputTarget.disabled = true
      controller.kindInputTarget.disabled = true

      var product_data = controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").send_product_data
      if (product_data.product.amount != undefined) { controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(product_data.product.amount) }
      // if (product_data.product.started_at != undefined) { controller.startedAtInputTarget.value = product_data.product.started_at }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshProductTimer = setInterval(function () {
      var len = 0

      if (controller.yearInputTarget.dataset.year == undefined) {
        len += 1
      }

      // if (controller.amountInputTarget.value == `R$ 0,00`) {
      //   len += 1
      // }

      if (len == 0) {
        controller.saveProductBtnTarget.disabled = false
      } else {
        controller.saveProductBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.productConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopProductRefreshing() {
    if (this.refreshProductTimer) {
      clearInterval(this.refreshProductTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
