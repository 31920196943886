
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dayBtn", "totalBillings", "billingCount", "billingCountTooltip"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--days`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.iugu_billings = []
    this.doMenuPreloader()
  }

  doMenuHtml() {

    var daysHtml = ``
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)

    for (var day = 1; day <= lastDay; day++) {

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, day)
      var weekDay = date.getDay()
      var weekDayName = this.controllerDate.getWeekDay(weekDay)
    
      daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn billingCount">
                    ${day}-${this.application.current_date.month_short}
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
                  </button>`
      
    }
    var html = `<div class="btn-group btn-group-sm" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${daysHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      if (controller.application.current_date.year == date.getFullYear() && controller.application.current_date.month == (date.getMonth() + 1)) {
        var day = date.getDate()
        controller.current_day = date.getDate()
      } else {
        var day = 1
        controller.current_day = 1
      }
      controller.dayBtnTargets[day - 1].classList.remove(`btn-flat-dark`)
      controller.dayBtnTargets[day - 1].classList.add(`btn-primary`)
      // controller.goToDay(day)
      // controller.doBadgeCounts()

      controller.doIndicators()
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).doBillingList()
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).current_day = controller.current_day
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).billings = controller.iugu_billings
      controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).doIndicatorsCalculation()
      controller.setHeadersDay()
    })
  }

  doBadgeCounts() {
    this.doBillingsCount()
  }

  goToDayEv(ev) {
    var day = ev.currentTarget.dataset.day

    this.current_day = day
    this.setHeadersDay()
  }

  setHeadersDay() {
    this.iugu_billings = []
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).current_day = this.current_day
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).billings = this.iugu_billings
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).mode = ``
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).paidHeaderTarget.innerText = `Pagas no Dia ${this.current_day}`
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).partiallyPaidHeaderTarget.innerText = `Pagas Parcialmente no Dia ${this.current_day}`
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).externallyPaidHeaderTarget.innerText = `Pagas Externamente no Dia ${this.current_day}`
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).partiallyRefundedHeaderTarget.innerText = `Reembolsadas Parcial no Dia ${this.current_day}`
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).dueHeaderTarget.innerText = `Vencidas no Dia ${this.current_day}`
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).paidCardTarget.classList.remove(`bg-primary`)
    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).dueCardTarget.classList.remove(`bg-primary`)
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).doIndicatorsCalculation()
    this.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).doBillingList()
  }

  goToDay() {
    this.send_data = { billing: {}, current_user: {} }
    this.url = ``

    this.send_data.billing.mode = this.mode
    this.send_data.billing.med_id_iugu = this.med_id_iugu
    this.send_data.billing.limit = 30
    
    if (this.mode == `paid_date`) {
      var paidAt = `${this.application.current_date.year}-${this.application.current_date.month}-${this.current_day}`
      this.send_data.billing.paid_at_from = paidAt
      this.send_data.billing.paid_at_to = paidAt
      this.send_data.billing.status = this.status
      this.url = `/financials/integrations/bankings/iugu/billings/list_by_paid_date`
      
    } else if (this.mode == `due_date`) {
      var dueAt = `${this.application.current_date.year}-${this.application.current_date.month}-${this.current_day}`
      this.send_data.billing.due_date = dueAt
      this.url = `/financials/integrations/bankings/iugu/billings/list_by_due_date`
    } else if (this.mode == `create_date`) {
      var createdAt = `${this.application.current_date.year}-${this.application.current_date.month}-${this.current_day}`
      // this.send_data.billing.due_date = dueAt
      // this.url = `/financials/integrations/bankings/iugu/billings/list_by_due_date`
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_billings`

    this.requestGoToDay()
  }

  requestGoToDay() {
    this.iugu_billings = []

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Carregando Faturas do dia ${this.current_day}-${this.application.current_date.month_short}` })
    this.refreshLoadValue(this.current_day)

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(this.url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        
        if (response.process) {
          
          var currentBillingIds = controller.mapElements(controller.application.billings, `id`)
          var refreshBillingIds = controller.mapElements(response.data.cln, `id`)

          response.data.cln.forEach(billing => {
            controller.iugu_billings[controller.iugu_billings.length] = billing
          })

          response.data.cln.forEach(billing => {
            if (currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings.forEach((element, i) => {
                if (element.id == billing.id) {
                  controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
                }
              })
            } else if (!currentBillingIds.includes(billing.id) && refreshBillingIds.includes(billing.id)) {
              controller.application.billings[controller.application.billings.length] = billing
            }
          })
        }

        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--panel-modal`).close()
        controller.doIndicators()
        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).doBillingList()
        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).current_day = controller.current_day
        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).billings = controller.iugu_billings
        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--indicators`).doIndicatorsCalculation()
        controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`).doBadgeCounts()
        // controller.setHeadersDay()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`financials--books--receivables--conciliations--panel-modal`).close()
        controller.stopRefreshing()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doIndicators() {
    var totalPaid = 0
    this.iugu_billings.forEach(billing => {
      totalPaid += Number(billing.total_paid_cents / 100)
    })
  }

  toggleBtn(ev) {

    this.dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.dayBtnTargets.forEach(element => {

      if (this.hasBillingCountTarget && element == this.billingCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doBillingsCount() {
    var billingCount = 0
    var billingUnconciliatedCount = 0

    this.application.billings.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        billingCount += 1
        if (element.conciliated == false) {
          billingUnconciliatedCount += 1
        }
      }
    })

    if (this.hasBillingCountTarget) {
      var billingBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalBillings">${billingUnconciliatedCount}</span>`
      if (this.totalBillingsTargets[0]) {
        this.totalBillingsTargets[0].remove()
      }
      this.billingCountTarget.insertAdjacentHTML("beforeend", billingBadgeHtml)
      this.billingCountTooltipTarget.innerText = `${billingUnconciliatedCount}/${billingCount} Fatura(s) não Conciliadas`
    }
  }

  doMenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    // this.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).daysTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--conciliations--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      controller.doMenuHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshLoadValue(day) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()
        // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        var response = { type: `danger`, message: `Tivemos um problema para carrega as Faturas` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 500);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
