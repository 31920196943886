import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "save", "filters", "header", "subheader", "unit", "stage", "filterCard"]

  connect() {
    this.controllerName = `users--works--trackers--units--show`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()
    // this.unitPreloader()
  }

  doUnitsHtml() {

    var squads = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.project.squads, `role_enum`)

    if (squads == undefined || squads == [] || squads.length == 0) {
      var noData = `<div class="col-12 my-5 text-center">
                      <span class="fa-stack fa-1x">
                        <i class="fas fa-list fa-stack-1x"></i>
                      </span>
                      <span class="f-075 align-middle">Não há Time</span>
                    </div>`

      this.mainTarget.innerHTML = noData
    } else {
      this.mainTarget.innerHTML = ``

      var html = `<div class="col-12 text-center">
                    <h6 class="mb-0 text-center d-flex"><span class="mx-auto">Squad</span></h6>
                    <hr class="mt-1">
                  </div>`

      this.mainTarget.insertAdjacentHTML("beforeend", html)

      squads.forEach(element => {
        this.mainTarget.insertAdjacentHTML("beforeend", this.squadPartial(element))
      })
    }
  }

  squadPartial(squad) {

    var currentUnits = this.mapElements(this.application.story.units, `squad_id`)

    if (squad.role == `owner`) {
      var background = `bg-primary`
      var action = ``
    } else {
      if (currentUnits.includes(squad.id)) {
        var background = `bg-primary pointer`
        var action = `data-action="click->${this.controllerName}#removeUnit"`
      } else {
        var background = `pointer`
        var action = `data-action="click->${this.controllerName}#createUnit"`
      }
    }

    var html = `<div class="col-12 text-center">
                  <div class="chip fade show mr-2 mb-2 ${background}" data-squad-id="${squad.id}" data-team-id="${squad.team_id}" data-squad-role="${squad.role}" ${action}>
                    <span class="mc-tooltip">
                      <span class="material-icons mr-1">face</span>
                      <span class="mr-1">${this.getControllerByIdentifier("app--visits--users").getInitials(squad.team_name)}</span>
                      <span class="mc-tooltiptext">${squad.team_name}</span>
                    </span>
                  </div>
                </div>`

    return html
  }

  createUnit(ev) {
    var squadId = ev.currentTarget.dataset.squadId
    var teamId = ev.currentTarget.dataset.teamId
    var squadRole = ev.currentTarget.dataset.squadRole
    this.actionMode = `new`
    this.send_data = { current_user: {}, unit: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_works_trackers_units`

    this.send_data.unit.story_id = this.application.story.id
    this.send_data.unit.squad_id = squadId
    this.send_data.unit.team_id = teamId
    this.send_data.unit.role = squadRole

    this.requestSave()
  }

  removeUnit(ev) {
    var squadId = ev.currentTarget.dataset.squadId
    this.actionMode = `delete`

    this.send_data = { current_user: {}, unit: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `user_works_trackers_units`

    this.send_data.unit.story_id = this.application.story.id
    this.send_data.unit.squad_id = squadId
    this.send_data.unit.active = false
    
    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    if (this.actionMode == `new`) {
      var url = "/users/works/trackers/units/create"
      var method = "POST"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/trackers/units/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var unit = response.data.cln

          if (controller.actionMode == `new`) {
            controller.application.story.units[controller.application.story.units.length] = unit
          } else if (controller.actionMode == `delete`) {
            controller.application.story.units.forEach((element, i) => {
              if (element.id == unit.id) {
                controller.application.story.units.splice(controller.application.story.units.indexOf(element), 1)
              }
            })
          }
          
          controller.doUnitsHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  unitPreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
