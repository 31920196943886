import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "footerTable", "addClientBtn", "pendencyCount", "sideBarReport", "refreshListBtn", "destroyAddonBtn",
    "optionBtn", "clearListBtn", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `financials--books--clients--addons--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.tableCols = 3
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `name` }
  }

  addAddon() {
    this.controllerSaveAddon = this.getControllerByIdentifier("financials--books--clients--addons--save")

    this.controllerSaveAddon.controllerDashboard = this.controllerDashboard
    this.controllerSaveAddon.permission = this.permission
    // this.controllerSaveAddon.gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
    this.controllerSaveAddon.gridElement = this.gridElement
    this.controllerSaveAddon.current_contract = this.current_contract
    this.controllerSaveAddon.current_addon = {}
    this.controllerSaveAddon.actionMode = `new`
    this.controllerSaveAddon.doFormHtml()
  }

  editAddon(ev) {
    var addonId = ev.currentTarget.dataset.id
    var currentAddon = {}
    this.application.client_addons.forEach(element => {
      if (element.id == addonId) {
        currentAddon = element
      }
    })
    this.controllerSaveAddon = this.getControllerByIdentifier("financials--books--clients--addons--save")

    this.controllerSaveAddon.controllerDashboard = this.controllerDashboard
    this.controllerSaveAddon.permission = this.permission
    // this.controllerSaveAddon.gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
    this.controllerSaveAddon.gridElement = this.gridElement
    this.controllerSaveAddon.current_contract = this.current_contract
    this.controllerSaveAddon.current_addon = currentAddon
    this.controllerSaveAddon.actionMode = `edit`
    this.controllerSaveAddon.doFormHtml()
  }

  uploadAddon(ev) {
    var addonId = ev.currentTarget.dataset.id
    var currentAddon = {}
    this.application.client_addons.forEach(element => {
      if (element.id == addonId) {
        currentAddon = element
      }
    })
    this.controllerUploadAddon = this.getControllerByIdentifier("financials--books--clients--addons--upload")

    this.controllerUploadAddon.controllerDashboard = this.controllerDashboard
    // this.controllerUploadAddon.gridElement = this.getControllerByIdentifier(`financials--books--receivables--contracts--dashboard`).viewTarget
    this.controllerUploadAddon.permission = this.permission
    this.controllerUploadAddon.gridElement = this.gridElement
    this.controllerUploadAddon.current_contract = this.current_contract
    this.controllerUploadAddon.current_addon = currentAddon
    this.controllerUploadAddon.actionMode = `edit`
    this.controllerUploadAddon.doUploadHtml()
  }

  doAddonsList() {
    if (this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu")) {
      this.getControllerByIdentifier("financials--books--receivables--dashboard--submenu").doBadgeCounts()
    }
    var activeAddons = []
    var nonActiveAddons = []

    var orderedAddons = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.client_addons, `kind_number`)

    orderedAddons.forEach(addon => {
      if (addon.status == `active`) {
        activeAddons[activeAddons.length] = addon
      } else if (addon.status != `active`) {
        nonActiveAddons[nonActiveAddons.length] = addon
      }
    })

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addAddon" class="dropdown-item py-1 pointer dropdown-submenu-item">Novo Item/Complemento</span>`
    } else {
      var addLine = ``
    }

    var listLine = `<span data-action="click->${this.controllerName}#listAddons" class="dropdown-item py-1 pointer dropdown-submenu-item">Listar Item/Complemento Quitados/Cancelados</span>`

    if (this.current_contract.has_agreement) {
      var showContract = `<span data-action="click->${this.controllerName}#goToURL" data-url="${this.current_contract.agreement_url}" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Contrato Anexo</span>`
    } else {
      var showContract = `<span class="dropdown-item py-1 pointer dropdown-submenu-item">Sem Contrato Anexo</span>` 
    }

    if (this.current_contract.has_summary) {
      var showSummary = `<span data-action="click->${this.controllerName}#goToURL" data-url="${this.current_contract.summary_url}" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Sumário Anexo</span>`
    } else {
      var showSummary = `<span class="dropdown-item py-1 pointer dropdown-submenu-item">Sem Sumário Anexo</span>` 
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#getAddons" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Itens/Complementos</span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${listLine}
                          ${addLine}
                          ${refreshLine}
                          ${showContract}
                          ${showSummary}
                        </div>
                        <span class="mc-tooltiptext mc-tooltiptext-middle">Opções</span>
                      </div>`

    var refreshBtnHtml = `<span class="mc-tooltip d-none" data-${this.controllerName}-target="clearListBtn">
                            <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${this.controllerName}#clearList" type="button">
                              <span class="material-icons md-dark">clear</span>
                            </button>
                            <span class="mc-tooltiptext mc-tooltiptext-middle">Limpar</span>
                          </span>`

    var html = `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewAddonCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title py-2 f-card110"><strong>Itens Ativos do Contrato | Início: ${this.current_contract.started_at_pretty}</strong></h6>
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${refreshBtnHtml}
                    </div>
                  </div>
                </div>`


    var activeAddonHtml = ``
    var inactiveAddonHtml = ``

    if (activeAddons.length > 0) {
      //data-action="click->${this.controllerName}#showAddon"

      // `<div class="col-1 px-0">
      //   <button class="btn btn-sm btn-table p-0 mc-tooltip" data-action="click->${this.controllerName}#destroyAddon" data-${this.controllerName}-target="destroyAddonBtn" data-id="${addon.id}" type="button">
      //     <span class="material-icons md-sm md-dark">delete</span>
      //     <span class="mc-tooltiptext">Apagar</span>
      //   </button>
      // </div>`

      activeAddons.forEach(addon => {
        if (addon.notes) {
          var addonNotes = addon.notes
        } else {
          var addonNotes = addon.chart_name
        }

        if (this.application.permissions[this.permission].can_delete) {
          var deleteLine = `<span data-action="click->${this.controllerName}#destroyAddon" data-id="${addon.id}" data-description="${addon.description}" data-amount="${addon.amount}" data-token="${addon.token}" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Complemento</span>`
        } else {
          var deleteLine = ``
        }

        if (this.application.permissions[this.permission].can_manage) {
          var writeOffLine = `<span data-action="click->${this.controllerName}#writeOffAddon" data-id="${addon.id}" data-description="${addon.description}" data-amount="${addon.amount}" data-token="${addon.token}" class="dropdown-item py-1 pointer dropdown-submenu-item">Anular Complemento</span>`
        } else {
          var writeOffLine = ``
        }

        var uploadLine = `<span data-action="click->${this.controllerName}#uploadAddon" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload Contrato</span>`
        var editLine = `<span data-action="click->${this.controllerName}#editAddon" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Editar Complemento</span>`
        var duplicateLine = `<span data-action="click->${this.controllerName}#duplicateAddon" data-id="${addon.id}" data-description="${addon.description}" data-amount="${addon.amount}" data-token="${addon.token}" class="dropdown-item py-1 pointer dropdown-submenu-item">Duplicar Complemento</span>`
        var receivablesLine = `<span data-action="click->${this.controllerName}#showReceivables" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Recebíveis</span>`

        var submenuActiveAddonHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 py-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                                          ${receivablesLine}
                                          ${uploadLine}
                                          ${editLine}
                                          ${duplicateLine}
                                          ${writeOffLine}
                                          ${deleteLine}
                                          
                                        </div>
                                        <span class="mc-tooltiptext">Opções</span>
                                      </div>`

        var cardShow = `<p class="mb-0 f-065">${addon.status_pretty} | ${addon.plan_pretty} | ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)} | Início: ${addon.started_at_pretty} | Término: ${addon.expires_at_pretty}</p>
                        <p class="mb-0 f-065">Data: ${addon.started_at_date_pretty}</p>
                        <p class="mb-0 f-065">Email: ${addon.client_iugu_email}</p>
                        <p class="mb-0 f-065">${addonNotes}</p>
                        <p class="mb-0 f-065">${addon.token}</p>`

        activeAddonHtml += `<div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="addonCard-${addon.id}">
                                <div class="card-body p-0 f-065">
                                  <div class="row my-2 d-flex align-items-center">
                                    <div class="col-10 px-1">
                                      <div class="card-show-dropdown w-100">
                                        <p class="mb-0 f-065 d-flex">
                                          <span class="mr-auto">${addon.description}</span>
                                        </p>
                                        <p class="mb-0 f-065">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)}</p>
                                        <div class="card-show-dropdown-content text-left w-100">
                                          ${cardShow}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 px-1">
                                      ${submenuActiveAddonHtml}
                                    </div>

                                  </div>
                                </div>
                              </div>`
      })
    } else {
      activeAddonHtml += `<div class="row my-2 d-flex align-items-center">
                              <div class="col-12 text-center">
                                <span class="f-065"><strong>Não há Itens Ativos</strong></span>
                              </div>
                            </div>`
    }

    html += `<div class="mt-3">${activeAddonHtml}</div>`


    html += `<div class="card mt-3" style="width:100%;display:relative;" data-${this.controllerName}-target="viewAddonCard">
              <div class="card-header py-0 f-065 text-center">
                <h6 class="card-title py-2 f-card110"><strong>Itens Quitados/Cancelados do Contrato</strong></h6>
              </div>
            </div>`

    if (nonActiveAddons.length > 0) {
      nonActiveAddons.forEach(addon => {
        if (addon.notes) {
          var addonNotes = addon.notes
        } else {
          var addonNotes = addon.chart_name
        }

        var churnDescription = ``
        if (addon.status == `canceled`) {
          churnDescription = addon.changed_description
        }

        if (this.application.permissions[this.permission].can_delete) {
          var deleteLine = `<span data-action="click->${this.controllerName}#destroyAddon" data-id="${addon.id}" data-description="${addon.description}" data-amount="${addon.amount}" data-token="${addon.token}" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Complemento</span>`
        } else {
          var deleteLine = ``
        }

        var uploadLine = `<span data-action="click->${this.controllerName}#uploadAddon" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload Contrato</span>`
        var receivablesLine = `<span data-action="click->${this.controllerName}#showReceivables" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Recebíveis</span>`

        var submenuActiveAddonHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 py-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                                          ${receivablesLine}
                                          ${uploadLine}
                                          ${deleteLine}
                                          
                                        </div>
                                        <span class="mc-tooltiptext">Opções</span>
                                      </div>`

        var cardShow = `<p class="mb-0 f-065">${addon.status_pretty} | Cliente: ${addon.client_iugu_email}</p>
                          <p class="mb-0 f-065">${addon.kind_pretty} | ${addon.subkind_pretty} | ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)} | Início: ${addon.started_at_pretty} | Término: ${addon.expires_at_pretty}</p>
                          <p class="mb-0 f-065">${addonNotes}</p>
                          <p class="mb-0 f-065">${churnDescription}</p>`

        inactiveAddonHtml += `<div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="addonCard-${addon.id}">
                                <div class="card-body p-0 f-065">
                                  <div class="row my-2 d-flex align-items-center">
                                    <div class="col-10 px-1">
                                      <div class="card-show-dropdown w-100">
                                        ${addon.description}
                                        <div class="card-show-dropdown-content text-left w-100">
                                          ${cardShow}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 px-1">
                                      ${submenuActiveAddonHtml}
                                    </div>
                                  </div>
                                </div>
                              </div>`
      })
    } else {
      inactiveAddonHtml += `<div class="row my-2 d-flex align-items-center">
                              <div class="col-12 text-center">
                                <span class="f-065"><strong>Não há Itens Pagos/Suspensos/Cancelados</strong></span>
                              </div>
                            </div>`
    }

    // html += `<div class="mt-3">${inactiveAddonHtml}</div>`

    this.gridElement.innerHTML = html
  }

  getAddons() {
    if (this.hasBodyTableTarget) {
      this.bodyTableTarget.innerHTML = this.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10)
    } else {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    }

    const data = { addon: { active: true, contract_id: this.current_contract.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/financials/books/clients/addons/list_by_contract"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.client_addons = response.data.cln
        controller.doAddonsList()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  destroyAddon(ev) {
    var element = ev.currentTarget
    var addonId = element.dataset.id
    var addonDescription = element.dataset.description
    var addonAmount = element.dataset.amount
    var addonToken = element.dataset.token

    var r = confirm(`Tem certeza que deseja Apagar o Item de Contrato ${addonDescription} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addonAmount)}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, addon: {}, tracker: { manual: true } }
      this.actionMode = `destroy`

      this.send_data.addon.id = addonId
      this.send_data.addon.active = false

      this.send_data.tracker.action = `destroyed`
      this.send_data.tracker.observations = `Apagou o Complemento #${addonToken} do Contrato ${this.current_contract.name}`

      this.requestSave()
    }
  }

  writeOffAddon(ev) {
    var element = ev.currentTarget
    var addonId = element.dataset.id
    var addonDescription = element.dataset.description
    var addonAmount = element.dataset.amount
    var addonToken = element.dataset.token

    var r = confirm(`Tem certeza que deseja Anular o Item de Contrato ${addonDescription} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addonAmount)}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, addon: {}, tracker: { manual: true } }
      this.actionMode = `update`

      this.send_data.addon.id = addonId
      this.send_data.addon.status = `write_off`

      this.send_data.tracker.action = `updated`
      this.send_data.tracker.observations = `Anulou o Complemento #${addonToken} do Contrato ${this.current_contract.name}`

      this.requestSave()
    }
  }

  duplicateAddon(ev) {
    var element = ev.currentTarget
    var addonId = element.dataset.id
    var addonDescription = element.dataset.description
    var addonAmount = element.dataset.amount
    var addonToken = element.dataset.token

    var r = confirm(`Tem certeza que deseja Duplicar o Item de Contrato ${addonDescription} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addonAmount)}?`)
    if (r) {
      this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

      this.send_data = { current_user: {}, addon: {}, tracker: { manual: true } }
      this.actionMode = `duplicate`

      this.send_data.addon.id = addonId
      
      this.send_data.tracker.action = `duplicated`
      this.send_data.tracker.observations = `Duplicatou o Complemento #${addonToken} do Contrato ${this.current_contract.name}`

      this.requestSave()
    }
  }

  requestSave() {

    this.send_data.tracker.record_id = this.current_contract.client_id
    this.send_data.tracker.record_type = `financial_client_entities`
    this.send_data.tracker.obj_id = this.current_contract.id
    this.send_data.tracker.obj_type = `financial_client_contracts`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_client_addons`

    if (this.actionMode == `destroy`) {
      var url = "/financials/books/clients/addons/destroy"
      var method = "DELETE"
    } else if (this.actionMode == `update`) {
      var url = "/financials/books/clients/addons/update"
      var method = "PUT"
    } else if (this.actionMode == `duplicate`) {
      var url = "/financials/books/clients/addons/duplicate_addon"
      var method = "POST"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var addon = response.data.cln.addon
          var contract = response.data.cln.contract

          if (controller.actionMode == `destroy`) {
            controller.application.client_addons.forEach((element, i) => {
              if (element.id == addon.id) {
                controller.application.client_addons.splice(controller.application.client_addons.indexOf(element), 1)
              }
            })
          } else if (controller.actionMode == `update`) {
            controller.application.client_addons.forEach((element, i) => {
              if (element.id == addon.id) {
                controller.application.client_addons.splice(controller.application.client_addons.indexOf(element), 1, addon)
              }
            })
          } else if (controller.actionMode == `duplicate`) {
            controller.application.client_addons[controller.application.client_addons.length] = addon
          }

          if (controller.controllerDashboard.context.identifier == `financials--books--clients--contracts--dashboard`) {
            controller.application.contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.contracts.splice(controller.application.contracts.indexOf(element), 1, contract)
              }
            })

            controller.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()

          } else if (controller.controllerDashboard.context.identifier == `financials--books--receivables--contracts--dashboard`) {
            controller.application.client_contracts.forEach((element, i) => {
              if (element.id == contract.id) {
                controller.application.client_contracts.splice(controller.application.client_contracts.indexOf(element), 1, contract)
              }
            })

            controller.getControllerByIdentifier(`financials--books--receivables--contracts--list`).doDataTable()
            controller.getControllerByIdentifier(`financials--books--clients--addons--list`).doAddonsList()
          }

          if (controller.getControllerByIdentifier(`financials--books--clients--addons--list-modal`)) {
            controller.getControllerByIdentifier(`financials--books--clients--addons--list-modal`).doModalHtml()
          }
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showReceivables(ev) {
    var addonId = ev.currentTarget.dataset.id
    var currentAddon = {}

    this.application.client_addons.forEach(addon => {
      if (addon.id == addonId) {
        currentAddon = addon
      }
    })

    var controllerModal = `financials--books--receivables--entities--list-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).obj_type = `addon`
      controller.getControllerByIdentifier(controllerModal).obj_id = currentAddon.id
      controller.getControllerByIdentifier(controllerModal).title = `${currentAddon.client_name} | ${currentAddon.contract_name} | Início: ${currentAddon.started_at_pretty}`
      controller.getControllerByIdentifier(controllerModal).permission = controller.permission
      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  listAddons() {
    var controllerModal = `financials--books--clients--addons--list-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).title = `Complementos Quitados/Cancelados | ${controller.current_contract.client_name}`
      controller.getControllerByIdentifier(controllerModal).permission = controller.permission
      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  refreshList() {
    this.refreshListBtnTarget.classList.add(`d-none`)
    this.current_clients = this.application.clients
    this.doDataTable()

    this.getControllerByIdentifier(`financials--books--receivables--contracts--list`).doNoContract()
    this.getControllerByIdentifier(`financials--books--receivables--contracts--show`).doNoContract()
  }

  clearList() {
    this.clearListBtnTarget.classList.add(`d-none`)
    this.optionBtnTarget.classList.remove(`d-none`)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
