import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["frames", "questions", "title", "submenu", "uploadBtn", "fileUpload", "fileName", "kindInput", "bookLink", "coverLink",
    "beginBannerLink", "errorBannerLink", "progressUploadBar", "progressUploadCounter", "videoLinkCard",
    "midiaDropdown", "midiaDropdownBtn", "midiaInput", "midiaList", "videoLinkCard"]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--fields`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.upload_file = {}
    this.filesPermittedTypes = ["pdf", "png", "jpeg", "PDF", "PNG", "JPEG"]
    this.uploadFeature = true
    this.uploadReady = false
  }

  doFieldsGrid() {
    var html = `<div class="row">
                  <div class="col-6" data-${this.controllerName}-target="frames"></div>
                  <div class="col-6" data-${this.controllerName}-target="questions"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--landings--show--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doFramesHtml()
      controller.doQuestionsHtml()
    })
  }

  inlineDisplay(ev) {
    var editor = ev.currentTarget.closest(".editorRow")
    var display = ev.currentTarget.closest(".editorRow").previousElementSibling

    display.classList.remove("d-none")
    editor.classList.add("d-none")

  }

  inlineEditor(ev) {
    var display = ev.currentTarget.closest(".displayRow")
    var editor = ev.currentTarget.closest(".displayRow").nextElementSibling
    var field = editor.dataset.field
    this.nameTarget(`${field}Input`).value = this.application.field[field]

    display.classList.add("d-none")
    editor.classList.remove("d-none")
  }

  saveField(ev) {
    this.send_data = { current_user: {}, field: {} }
    this.inlineDisplay(ev)
    var field = ev.currentTarget.closest(".editorRow").dataset.field


    this.send_data.field.id = this.application.field.id
    this.send_data.field.field = field
    this.send_data.field.value = this.nameTarget(`${field}Input`).value

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/marketing/landings/fields/update_attribute"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.landing = response.data.cln
          controller.application.field = response.data.cln.field
          controller.doFramesHtml()
          controller.doQuestionsHtml()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFramesHtml() {
    if (this.application.permissions.landings.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.landings.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveField" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>Frames</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Título Frame #1</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.title_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="title_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="title_oneInput" type="text" placeholder="Título Frame #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Subtítulo Frame #1</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.subtitle_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="subtitle_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="subtitle_oneInput" type="text" placeholder="Subtítulo Frame #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Título Frame #2</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.title_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="title_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="title_twoInput" type="text" placeholder="Título Frame #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <span>Subtítulo Frame #2</span>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                ${this.application.field.subtitle_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="subtitle_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="subtitle_twoInput" type="text" placeholder="Subtítulo Frame #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>`

    var controller = this
    this.framesTarget.innerHTML = html
    // new Promise(function (resolve) {
    //   resolve(controller.framesTarget.innerHTML = html)
    // }).then(() => {
    //   
    // })
  }

  doQuestionsHtml() {
    if (this.application.permissions.landings.can_update) {
      var edit = `<button data-action="click->${this.controllerName}#inlineEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (this.application.permissions.landings.can_update) {
      var save = `<button data-action="click->${this.controllerName}#saveField" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">save</span>
                    <span class="mc-tooltiptext">Salvar</span>
                  </button>`
    } else {
      var save = ``
    }

    var html = `<div class="row my-3 w-100">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 text-center"><span>Perguntas Frequentes</span></h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #1: </span>
                                ${this.application.field.question_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_oneInput" type="text" placeholder="Pergunta #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #1: </span>
                                ${this.application.field.answer_one}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_one">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_oneInput" type="text" placeholder="Resposta #1" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #2: </span>
                                ${this.application.field.question_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_twoInput" type="text" placeholder="Pergunta #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #2: </span>
                                ${this.application.field.answer_two}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_two">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_twoInput" type="text" placeholder="Resposta #2" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #3: </span>
                                ${this.application.field.question_three}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_three">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_threeInput" type="text" placeholder="Pergunta #3" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #3: </span>
                                ${this.application.field.answer_three}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_three">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_threeInput" type="text" placeholder="Resposta #3" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                      <div class="col-12 my-2 px-0">
                        <div class="card">
                          <div class="card-header p-1 text-center f-070">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Pergunta #4: </span>
                                ${this.application.field.question_four}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="question_four">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="question_fourInput" type="text" placeholder="Pergunta #4" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                          <div class="card-body text-center px-0 py-2">
                            <div class="row d-flex align-items-center displayRow">
                              <div class="col-10 f-075 px-2 text-left">
                                <span class="f-bold">Resposta #4: </span>
                                ${this.application.field.answer_four}
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${edit}
                              </div>
                            </div>
                            <div class="row d-flex align-items-center editorRow d-none" data-field="answer_four">
                              <div class="col-10 f-075 px-2 text-left">
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="answer_fourInput" type="text" placeholder="Resposta #4" required></textarea>
                              </div>
                              <div class="col-2 f-075 px-2 text-center">
                                ${save}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>`

    this.questionsTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasKindInputTarget) {
        if (controller.uploadReady == false) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}