import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "bodyTable", "achievementDashboard", "goal", "showGoalCard", "editGoalCard", "showGoal", "editGoal", "mainDashboard"]

  connect() {
    this.controllerName = `operations--products--tax-returns--achievements--resume`
    this.doAchievementLoader()
    this.doAchievementsHtml()
  }

  doAchievementLoader() {
    var loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="achievementDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row">
                      <div class="col-12 px-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-6 pl-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0">
                        <div class="card border-top-primary">
                          <div class="card-header p-1 text-center f-070">
                            <span>${loader}</span>
                          </div>
                          <div class="card-body text-center f-070">
                            <span>${loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    this.getControllerByIdentifier("operations--products--tax-returns--achievements--dashboard").resumeTarget.innerHTML = html
  }

  doAchievementsHtml() {

    if (this.application.achievements) {
      this.application.achievements.forEach(element => {
        if (element.date_id == this.application.current_date.id) {
          this.current_achievement = element
        }
      });
    } else {
      this.current_achievement = { total_work_incomes: 0, saving_rate_goal: 0, saving_rate_real: 0 }
    }

    var html = `<h6 class="mb-0 text-center">Meu Planner Financeiro</h6>
                  <hr class="mt-1">
                  <div class="row">
                    <div class="col-12 px-0">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Total Rendimentos</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_achievement.total_work_incomes)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6 pl-0 pr-1">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Minha Meta</span>
                        </div>
                        <div class="card-body text-center f-070 d-flex justify-content-between" data-${this.controllerName}-target="showGoalCard">
                          <span data-${this.controllerName}-target="showGoal">${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_goal * 10000).toString())}</span>
                          <button data-action="click->${this.controllerName}#editGoal" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>
                        </div>
                        <div class="card-body text-center f-070 d-flex justify-content-between d-none" data-${this.controllerName}-target="editGoalCard">
                          <input class="form-control py-0" data-${this.controllerName}-target="goal" data-action="blur->${this.controllerName}#percentMask keyup->${this.controllerName}#percentMask keypress->${this.controllerName}#percentMask blur->${this.controllerName}#saveGoal keyup->${this.controllerName}#saveGoal" placeholder="Minha Meta" type="text" required>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-0 pl-1">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Aporte Real</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_real * 10000).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-3">
                    <div class="col-6 pl-0 pr-1">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Minha Meta</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_achievement.saving_goal * 100).toString())}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-0 pl-1">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Aporte Real</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.current_achievement.saving_real * 100).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>`

    this.achievementDashboardTarget.innerHTML = html
  }

  editGoal() {
    this.showGoalCardTarget.classList.add("d-none")
    this.editGoalCardTarget.classList.remove("d-none")

    this.goalTarget.value = this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_goal * 10000).toString())
    this.goalTarget.focus()
  }

  saveGoal(ev) {
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.showGoalCardTarget.classList.remove("d-none")
      this.editGoalCardTarget.classList.add("d-none")
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "blur") {
      this.showGoalCardTarget.classList.remove("d-none")
      this.editGoalCardTarget.classList.add("d-none")
      if (this.showGoalTarget.innerText != this.goalTarget.value) {
        this.current_achievement.saving_rate_goal = this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.goalTarget.value)
        this.showGoalTarget.innerText = this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.current_achievement.saving_rate_goal * 100).toString())
        var data = {
          achievement: {
            id: this.current_achievement.id,
            saving_rate_goal: (this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.goalTarget.value) / 100),
            saving_goal: (this.getControllerByIdentifier("app--helpers--numbers").fromPercentToNumber(this.goalTarget.value) * this.current_achievement.total_work_incomes / 100)
          },
          current_user: { current_user_id: this.application.current_user.id }
        }
        this.requestSave(data)
      }
    }
  }

  requestSave(data) {
    const token = $('meta[name=csrf-token]').attr('content');
    var url = "/operations/products/tax_returns/achievements/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var achievement = response.data.cln
          this.application.achievements.forEach((element, i) => {
            if (element.id == achievement.id) {
              this.application.achievements.splice(this.application.achievements.indexOf(element), 1, achievement)
            }
          })
          this.doAchievementsHtml()
        } else {
          this.editGoal()
        }
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
  }

  percentMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPercent(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
