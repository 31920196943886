import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "title", "save", "filters", "header", "subheader", "squad", "saveBtn", "precedentBodyTable",
                    "squadEdit", "squadInput", "squadFilter", "cancelBtn", "roleDropdown", "roleDropdownBtn", "roleInput",
                    "roleList", "9999"]

  connect() {
    this.controllerName = `users--works--trackers--stories--relations`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.chipLoader = this.getControllerByIdentifier("app--helpers--loaders").chipLoader()
    this.relationPreloader()
  }

  doRelationsHeader() {
    if (this.application.permissions.user_works_trackers_squads.can_create) {
      var title = `<span class="ml-auto">Relações</span>
                    <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#addRelation" data-${this.controllerName}-target="addRelationBtn">
                      <span class="material-icons md-sm md-dark">add</span>
                      <span class="mc-tooltiptext">Adicionar Entregável Precedente</span>
                    </span>`
    } else {
      var title = `<span class="mx-auto">Relações</span>`
    }

    this.titleTarget.innerHTML = title
  }

  addRelation() {
    this.stopRefreshing()

    var html = `<div class="col-12 px-1 d-flex align-items-center" data-${this.controllerName}-target="squadEdit">
                  <div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search">
                    <div class="card-header d-flex align-items-center f-065 p-0">
                      <input class="form-control f-070 w-100 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar Precedentes" type="text">
                    </div>
                    <div class="card-body p-0" style="overflow:auto;">
                      <div class="row">
                        <div class="col p-0">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-10 align-middle"></td>
                                <td style="font-size:80%;height:0rem;padding-top:0rem;padding-bottom:0rem;" scope="col" class="table-90 align-middle"></td>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="precedentBodyTable"></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 px-1 d-flex align-items-center mb-4" data-${this.controllerName}-target="squadEdit">
                  <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#closeEdit">Fechar</button>
                  <button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#createRelation" data-${this.controllerName}-target="saveBtn">Salvar</button>
                </div>`

    this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.relations = { has: false, objects: [] }
      controller.listPrecedents()
    })
  }

  closeEdit() {
    this.squadEditTargets.forEach(element => {
      element.remove()
    })
    this.doRelationsHtml()
  }

  listPrecedents() {
    var current_precedents = []

    var dependentsIds = this.mapElements(this.application.story.dependents, `dependent_id`)
    dependentsIds[dependentsIds.length] = this.application.story.id

    this.application.precedents.forEach(element => {
      if (!dependentsIds.includes(element.precedent_id)) {
        current_precedents[current_precedents.length] = element
      }
    })

    this.precedentBodyTableTarget.innerHTML = ""
    if (current_precedents == undefined || current_precedents == [] || current_precedents.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Precedentes</span>
                      </td>
                    </tr>`

      this.precedentBodyTableTarget.innerHTML = noData
    } else {
      current_precedents.forEach(element => {
        this.precedentBodyTableTarget.insertAdjacentHTML("beforeend", this.precedentTablePartial(element, current_precedents.length))
      })

      this.checkPrecedent()
    }
  }

  precedentTablePartial(element, length) {

    var check = `<div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                    <input type="checkbox" class="custom-control-input" id="check-${element.precedent_id}" data-${this.controllerName}-target="checkboxPrecedent checkboxPrecedent-${element.precedent_id}" data-action="click->${this.controllerName}#selectPrecedent">
                    <label class="custom-control-label pointer" for="check-${element.precedent_id}"></label>
                  </div>`

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.precedent_id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.precedent_id}">`
    }

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${check}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.precedent_name}</td>
                  </tr>`

    return rowHtml
  }

  selectPrecedent(ev) {
    var precedentId = ev.currentTarget.closest(".itemRow").dataset.id
    var checkbox = this.nameTarget(`checkboxPrecedent-${precedentId}`).checked

    this.application.precedents.forEach(element => {
      if (precedentId == element.precedent_id) {
        if (checkbox) {
          this.relations.objects[this.relations.objects.length] = element
        } else {
          this.relations.objects.forEach((element, i) => {
            if (element.precedent_id == precedentId) {
              this.relations.objects.splice(this.relations.objects.indexOf(element), 1)
            }
          })
        }
      }
    })

    if (this.relations.objects.length > 0) {
      this.relations.has = true
    } else {
      this.relations.has = false
    }
  }

  checkPrecedent() {
    var precedents = this.application.story.precedents

    precedents.forEach(element => {
      if (this.nameTarget(`checkboxPrecedent-${element.precedent_id}`)) {
        this.nameTarget(`checkboxPrecedent-${element.precedent_id}`).checked = true
        this.relations.has = true
        this.application.precedents.forEach(precedent => {
          if (element.precedent_id == precedent.precedent_id) {
            this.relations.objects[this.relations.objects.length] = precedent
          }
        })
        
      }
    })
  }

  createRelation() {

    if (this.squadCanUpdate()) {
      this.actionMode = `new`
      this.stopRefreshing()
      this.send_data = { current_user: {}, story: {}, relation: {} }

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.story.id = this.application.story.id
      this.send_data.relation.has = this.relations.has
      this.send_data.relation.objects = this.relations.objects

      this.requestSave()
    } else {
      alert(`Você não tem autorização para Editar as Relações.`)
    }

  }

  removeRelation(ev) {
    

    if (this.squadCanUpdate()) {
      this.actionMode = `delete`
      var relationId = ev.currentTarget.dataset.id

      this.send_data = { current_user: {}, story: {}, relation: {} }

      this.send_data.current_user.current_user_id = this.application.current_user.id

      this.send_data.story.id = this.application.story.id
      this.send_data.relation.id = relationId
      this.send_data.relation.active = false

      this.requestSave()
    } else {
      alert(`Você não tem autorização para Editar as Relações.`)
    }
    
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/users/works/trackers/relations/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/users/works/trackers/relations/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/trackers/relations/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.story = response.data.cln
          // var relation = response.data.cln
          // if (controller.actionMode == `new`) {
          //   controller.application.story.precedents[controller.application.story.precedents.length] = relation
          // } else if (controller.actionMode == `edit`) {
          //   controller.application.story.precedents.forEach((element, i) => {
          //     if (element.id == relation.id) {
          //       controller.application.story.precedents.splice(controller.application.story.precedents.indexOf(element), 1, relation)
          //     }
          //   })
          // } else if (controller.actionMode == `delete`) {
          //   controller.application.story.precedents.forEach((element, i) => {
          //     if (element.id == relation.id) {
          //       controller.application.story.precedents.splice(controller.application.story.precedents.indexOf(element), 1)
          //     }
          //   })
          // }

          controller.closeEdit()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doRelationsHtml() {
    var main = this.application.story
    var precedents = this.application.story.precedents
    var dependents = this.application.story.dependents

    if (precedents.length == 0 && dependents.length == 0) {
      var noData = `<div class="col-12 my-5 text-center">
                      <span class="f-075 align-middle">Não há Relações</span>
                    </div>`

      this.mainTarget.innerHTML = noData     
    } else {
      this.mainTarget.innerHTML = ``
      var html = ``

      html += `<span class="mb-0 text-center d-flex f-065">Dependentes</span>`

      if (dependents.length == 0) {
        html += `<div class="col-12 my-4 text-center">
                  <span class="f-075 align-middle">Não há Dependentes</span>
                </div>`
      } else {
        dependents.forEach(element => {
          element.role_pretty = `Dependente`
          html += this.dependentRelationPartial(element)
        });
      }

      html += `<span class="mb-0 text-center d-flex f-065">Entregável</span>`
      html += this.mainPartial(main)

      html += `<span class="mb-0 text-center d-flex f-065">Precedentes</span>`

      if (precedents.length == 0) {
        html += `<div class="col-12 my-4 text-center">
                  <span class="f-075 align-middle">Não há Precedentes</span>
                </div>`
      } else {
        precedents.forEach(element => {
          element.role_pretty = `Precedente`
          html += this.precedentRelationPartial(element)
        });
      }

      this.mainTarget.innerHTML = html
    }
  }

  mainPartial(element) {
    var html = `<div class="card w-100 mb-3 card-light-gray-bg">
                  <div class="card-header p-0 f-065 pointer">
                    <div class="row my-2">
                      <div class="col-12 px-2 d-flex align-items-center">
                        <span class="mc-tooltip">
                          <span class="mr-1 default f-065">${element.name}</span>
                          <span class="mc-tooltiptext">${element.name}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  dependentRelationPartial(element) {
    
    if (element.status) {
      var border = `border-top-success`
    } else {
      var border = `border-top-red`
    }

    var html = `<div class="card w-100 mb-3">
                  <div class="card-header ${border} p-0 f-065">
                    <div class="row my-2">
                      <div class="col-12 px-2 d-flex align-items-center justify-content-center">
                        <span class="mc-tooltip" data-id="${element.id}" data-role="dependent" data-action="click->${this.controllerName}#goToStory">
                          <span class="mr-1 f-065 pointer">${element.dependent_name}</span>
                          <span class="mc-tooltiptext">${element.role_pretty}</span>
                        </span>
                        <span class="ml-auto mc-tooltip">
                          <span class="material-icons md-sm pointer" data-id="${element.id}" data-action="click->${this.controllerName}#removeRelation">cancel</span>
                          <span class="mc-tooltiptext">Remover do Projeto</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  precedentRelationPartial(element) {

    if (element.status) {
      var border = `border-top-success`
    } else {
      var border = `border-top-red`
    }

    var html = `<div class="card w-100 mb-3">
                  <div class="card-header ${border} p-0 f-065">
                    <div class="row my-2">
                      <div class="col-12 px-2 d-flex align-items-center justify-content-center">
                        <span class="mc-tooltip" data-id="${element.id}" data-role="precedent" data-action="click->${this.controllerName}#goToStory">
                          <span class="mr-1 f-065 pointer">${element.precedent_name}</span>
                          <span class="mc-tooltiptext">${element.role_pretty}</span>
                        </span>
                        <span class="ml-auto mc-tooltip">
                          <span class="material-icons md-sm pointer" data-id="${element.id}" data-action="click->${this.controllerName}#removeRelation">cancel</span>
                          <span class="mc-tooltiptext">Remover do Projeto</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  goToStory(ev) {
    var role = ev.currentTarget.dataset.role
    var id = ev.currentTarget.dataset.id
    var relation = ``

    this.application.story.precedents.forEach(element => {
      if (element.id == id) {
        relation = element
      }
    })
    this.application.story.dependents.forEach(element => {
      if (element.id == id) {
        relation = element
      }
    })

    if (role == `dependent`) {
      var url = `${this.application.story.project.project_path}/${relation.dependent_slug}`
    } else if (role == `precedent`) {
      var url = `${this.application.story.project.project_path}/${relation.precedent_slug}`
    }

    window.open(url, `_self`)
  }

  relationPreloader() {
    var html = `<div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>
                <div class="col-12 px-2 text-center mb-3">
                  ${this.chipLoader}
                </div>`

    this.mainTarget.innerHTML = html
  }

  squadCanUpdate() {
    var currentOwner = false
    this.application.project.squads.forEach(squad => {
      if ((squad.role == `owner` || squad.role == `editor`) && squad.team_id == this.application.current_user.account_id) {
        currentOwner = true
      }
    })

    return currentOwner
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.squadInputTarget.value == ``) {
        len += 1
      }
      if (controller.roleInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
