import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "save", "filters", "title", "titleCardsBtn", "7777", "8888", "9999",
    "developCard", "commercialCard", "financialCard", "operationsCard", "peopleCard", "strategyCard"]

  connect() {
    this.controllerName = `users--works--trackers--main--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.application.project_ids = []
    this.application.permissions = {}
    this.application.projects = []
    this.application.in_progress_projects = []
    this.application.frozen_projects = []
    this.doMainGrid()
  }

  doMainGrid() {
    var html = `<div class="row">
                  <div class="col-12 px-0" data-controller="users--works--trackers--projects--dashboard
                                                            users--works--permissions--entities--list-users" data-${this.controllerName}-target="content">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.pageLoader()
      controller.getCurrentUserPermissions()
    })
  }

  finishLoadPage() {
    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.load = true

    this.getControllerByIdentifier(`users--works--trackers--main--submenu`).doSubmenuHtml()
    this.getControllerByIdentifier(`users--works--trackers--projects--dashboard`).doProjectsDashboard()

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getProjects() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Projetos`, value: 65 })

    const data = { project: { active: true, project_ids: this.application.project_ids }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_projects` } }
    const url = "/users/works/trackers/projects/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.projects = response.data.cln

        controller.finishLoadPage()
        // controller.getControllerByIdentifier(`users--works--trackers--projects--dashboard`).doFiltersHtml()
        // controller.getControllerByIdentifier(`users--works--trackers--projects--dashboard`).separateProject()
        // controller.getControllerByIdentifier(`users--works--trackers--projects--dashboard`).setTitle()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getSquads() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Squads`, value: 20 })

    const data = { squad: { active: true, team_id: this.application.current_user.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_squads` } }
    const url = "/users/works/trackers/squads/list_by_team"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.squads = response.data.cln
        controller.application.project_ids = controller.mapElements(controller.application.squads, `project_id`)

        controller.getProjects()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `work_trackers` }, current_user: { current_user_id: this.application.current_user.id, feature: `user_works_trackers_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        })

        controller.getSquads()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  pageLoader() {
    var html = `<div class="row my-2">
                  ${this.cardGridPreloader()}
                  
                </div>`

    this.contentTarget.innerHTML = html
  }

  cardGridPreloader() {
    var html = `<div class="col-6 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-6 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-6 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-6 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-6 my-2">
                  ${this.cardLoader}
                </div>
                <div class="col-6 my-2">
                  ${this.cardLoader}
                </div>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
