
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "dayBtn", "totalBillings", "billingCount", "billingCountTooltip"]

  connect() {
    this.controllerName = `financials--books--receivables--liquidations--days`
    this.controllerDate = this.getControllerByIdentifier("app--helpers--date")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
  }

  doMenuHtml() {

    var daysHtml = ``
    var lastDay = this.controllerDate.getMonthLength(this.application.current_date.month)

    for (var day = 1; day <= lastDay; day++) {

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, day)
      var weekDay = date.getDay()
      var weekDayName = this.controllerDate.getWeekDay(weekDay)

      daysHtml += `<button type="button" data-day="${day}" data-action="${this.controllerName}#goToDayEv ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065 mc-tooltip" data-${this.controllerName}-target="dayBtn billingCount">
                    ${day}-${this.application.current_date.month_short}
                    <span class="mc-tooltiptext mc-tooltiptext-middle">${weekDayName}</span>
                  </button>`

    }
    var html = `<div class="btn-group btn-group-sm" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${daysHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      if (controller.application.current_date.year == date.getFullYear() && controller.application.current_date.month == (date.getMonth() + 1)) {
        var day = date.getDate()
        controller.current_day = date.getDate()
      } else {
        var day = 1
        controller.current_day = 1
      }
      controller.dayBtnTargets[day - 1].classList.remove(`btn-flat-dark`)
      controller.dayBtnTargets[day - 1].classList.add(`btn-primary`)

      // controller.goToDay(controller.current_day)
      controller.setPage(`Favor escolher o dia para Liquidar`)
    })
  }

  goToDayEv(ev) {
    var day = ev.currentTarget.dataset.day

    this.current_day = day
    this.goToDay()
  }

  goToDay() {
    this.send_data = { billing_date: {}, current_user: {} }

    if (this.application.current_date.month < 10) {
      var currentMonth = `0${this.application.current_date.month}`
    } else {
      var currentMonth = `${this.application.current_date.month}`
    }

    if (this.current_day < 10) {
      var currentDay = `0${this.current_day}`
    } else {
      var currentDay = `${this.current_day}`
    }

    // var returnedDate = `${this.application.current_date.year}-${currentMonth}-${currentDay}`
    // this.send_data.billing_date.returned_date = returnedDate
    // this.url = `/financials/integrations/bankings/iugu/billing_dates/list_by_returned_date`

    var executedDate = `${this.application.current_date.year}-${currentMonth}-${currentDay}`
    this.send_data.billing_date.executed_date = executedDate
    this.url = `/financials/integrations/bankings/iugu/billing_dates/list_by_executed_date`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_receivable_billing_dates`

    this.requestGoToDay()
  }

  requestGoToDay() {
    this.iugu_billings = []

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ submessage: `Liquidando Faturas do dia ${this.current_day}-${this.application.current_date.month_short}` })
    this.refreshLoadValue(this.current_day)

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(this.url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()

        if (response.process) {
          controller.application.billing_dates = response.data.cln
        }

        var message = ``
        if (controller.application.billing_dates.length == 0) {
          message = `Não há faturas para Liquidar.`
        }

        controller.setPage(message)
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setPage(message) {
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).current_day = this.current_day
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).message = message
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).doBillingList()
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--indicators`).current_day = this.current_day
    this.getControllerByIdentifier(`financials--books--receivables--liquidations--indicators`).doIndicatorsCalculation()
  }

  toggleBtn(ev) {

    this.dayBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.dayBtnTargets.forEach(element => {

      if (this.hasBillingCountTarget && element == this.billingCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doTicketsCount() {
    var ticketCount = 0
    var ticketInProcessCount = 0

    this.application.tickets.forEach(element => {
      ticketCount += 1
      if (element.stage == `in_process`) {
        ticketInProcessCount += 1
      }
    })

    if (this.hasTicketCountTarget) {
      var ticketBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalTickets">${ticketCount}</span>`
      if (this.totalTicketsTargets[0]) {
        this.totalTicketsTargets[0].remove()
      }
      this.ticketCountTarget.insertAdjacentHTML("beforeend", ticketBadgeHtml)
      this.ticketCountTooltipTarget.innerText = `${ticketInProcessCount}/${ticketCount} Ticket(s) em Aberto`
    }
  }

  doBillingsCount() {
    var billingCount = 0
    var billingUnconciliatedCount = 0

    this.application.billings.forEach(element => {
      if (this.application.current_date.id == element.date_id) {
        billingCount += 1
        if (element.conciliated == false) {
          billingUnconciliatedCount += 1
        }
      }
    })

    if (this.hasBillingCountTarget) {
      var billingBadgeHtml = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalBillings">${billingUnconciliatedCount}</span>`
      if (this.totalBillingsTargets[0]) {
        this.totalBillingsTargets[0].remove()
      }
      this.billingCountTarget.insertAdjacentHTML("beforeend", billingBadgeHtml)
      this.billingCountTooltipTarget.innerText = `${billingUnconciliatedCount}/${billingCount} Fatura(s) não Conciliadas`
    }
  }

  doMenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    // this.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).daysTarget.innerHTML = html

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--receivables--liquidations--dashboard`).daysTarget.innerHTML = html)
    }).then(() => {
      controller.doMenuHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  refreshLoadValue(day) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      controller.getControllerByIdentifier(`app--helpers--pre-modal`).progressCountMessage({ submessage: `Liquidando as Faturas do dia ${day}-${controller.application.current_date.month_short}`, value: value })
      value += 1

      if (value == 99) {
        controller.stopRefreshing()
        // controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        var response = { type: `danger`, message: `Tivemos um problema para liquidar as Faturas` }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }

    }, 1500);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
