import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewOpportunityCard", "viewTitle", "viewOpportunityCardBody", "previewCard", "editOpportunityBtn", "deleteOpportunityBtn", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--records--show`
    this.send_data = { current_user: {}, opportunity: {} }
    this.doViewHtml()
  }

  doViewHtml() {
    if (this.getControllerByIdentifier("commercial--sales--opportunities--records--save")) {
      this.getControllerByIdentifier("commercial--sales--opportunities--records--save").stopRefreshing()
    }
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewOpportunityCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhuma Oportunidade Selecionada</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewOpportunityCardBody"></div>
                </div>`

    this.getControllerByIdentifier("commercial--sales--opportunities--records--dashboard").viewTarget.innerHTML = html
  }

  editOpportunity() {
    this.getControllerByIdentifier("commercial--sales--opportunities--records--save").current_opportunity = this.current_opportunity
    this.getControllerByIdentifier("commercial--sales--opportunities--records--save").actionMode = "edit"
    this.getControllerByIdentifier("commercial--sales--opportunities--records--save").doFormHtml()
  }

  deleteOpportunity() {
    var r = confirm("Tem certeza que deseja apagar a Oportunidade?")
    this.deleteOpportunityBtnTarget.disabled = true

    if (r) {
      this.actionMode = "delete"

      this.send_data.opportunity.id = this.current_opportunity.id
      this.send_data.opportunity.active = false

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `sales_opportunities_entities`

      this.requestDestroy()
    } else {
      this.deleteOpportunityBtnTarget.disabled = false
    }

  }

  requestDestroy() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/commercial/sales/opportunities/entities/destroy"
    var method = "DELETE"
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln
          if (opportunity.active) {
            controller.application.opportunities.forEach((element, i) => {
              if (element.id == opportunity.id) {
                controller.application.opportunities.splice(controller.application.opportunities.indexOf(element), 1, opportunity)
              }
            })
          } else {
            controller.application.opportunities.forEach((element, i) => {
              if (element.id == opportunity.id) {
                controller.application.opportunities.splice(controller.application.opportunities.indexOf(element), 1)
              }
            })
          }
          controller.getControllerByIdentifier("commercial--sales--opportunities--records--index").doDataTable()
          controller.doViewHtml()
          controller.send_data = { current_user: {}, opportunity: {} }
        } else {
          controller.deleteOpportunityBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })

  }

  goToOpportunity(ev) {
    var url = ev.currentTarget.dataset.url

    window.open(url, `_blank`)
  }


  doOpportunityHtml() {
    this.getControllerByIdentifier(`commercial--sales--opportunities--records--save`).stopRefreshing()
    var spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`

    // if (this.application.permissions.sales_opportunities_entities.can_delete && this.current_opportunity.stage != `gain` && this.current_opportunity.stage != `lost`) {
    if (this.application.permissions.sales_opportunities_entities.can_delete && this.current_opportunity.stage != `gain` && this.application.current_user.super_admin) {
      var deleteBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="deleteOpportunityBtn" data-action="click->${this.controllerName}#deleteOpportunity" type="button">
                            <span class="material-icons f-1p25">delete</span>
                          </button>
                          <span class="mc-tooltiptext">Apagar Oportunidade</span>`
    } else {
      var deleteBtnHtml = ``
    }

    if (this.application.permissions.sales_opportunities_entities.can_update) {
      var editBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-${this.controllerName}-target="editOpportunityBtn" data-action="click->${this.controllerName}#editOpportunity" type="button">
                            <span class="material-icons f-1p25">edit</span>
                          </button>
                          <span class="mc-tooltiptext">Editar Oportunidade</span>`
    } else {
      var editBtnHtml = ``
    }

    var showBtnHtml = `<button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-url="${this.current_opportunity.opportunity_path}" data-action="click->${this.controllerName}#goToOpportunity" type="button">
                        <span class="material-icons f-1p25">launch</span>
                      </button>
                      <span class="mc-tooltiptext">Ver Oportunidade</span>`

    var productHtml = ``
    this.current_opportunity.products.forEach(element => {
      if (element) {
        productHtml += `<p class="mb-0 f-065">${element.name_pretty} | ${element.kind_pretty} | ${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(element.amount * 100))}</p>`
      } else {
        productHtml += `Sem Produto`
      }
    })

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Oportunidade</h6>
                    <div class="card-actions ml-auto py-0 mc-tooltip">
                      ${showBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${editBtnHtml}
                    </div>
                    <div class="card-actions py-0 mc-tooltip">
                      ${deleteBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12 px-0">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>SDR:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.prospector_name} <br>
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Closer:</strong>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.closer_name}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Valor:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.current_opportunity.total_amount_recurring)}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Produtos:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${productHtml}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Estágio:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.stage_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Fonte:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.source_pretty}
                              </td>
                            </tr>

                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Início em:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.started_at_pretty}
                              </td>
                            </tr>
                            <tr>
                              <th colspan="3" class="f-065 align-middle px-0">
                                <strong>Finalizado em:</strong> <br>
                              </th>
                              <td colspan="5" class="f-065 align-middle px-0 text-left">
                                ${this.current_opportunity.finished_at_pretty}
                              </td>
                            </tr>

                            <tr>
                              ${spaceRow}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--sales--opportunities--records--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.previewCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })

  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.connect()
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
