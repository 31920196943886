import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "card", "addNewOpportunityBtn", "4444", "5555", "eventCard", "opportunityLeadName", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--marketing--events--dealflow--flow`
    this.controllerDashboard = this.getControllerByIdentifier("commercial--marketing--events--dealflow--dashboard")
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  doDataFlow() {

    this.journeys = this.controllerDashboard.eventJourneys()

    this.controllerDashboard.prospectingFlowTarget.innerHTML = ``
    this.controllerDashboard.planningFlowTarget.innerHTML = ``
    this.controllerDashboard.proposalFlowTarget.innerHTML = ``
    this.controllerDashboard.meetingFlowTarget.innerHTML = ``

    this.application.events.forEach(element => {
      this.doSimpleDataFlow(element)
    })
    // this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--dashboard").doTicketsHtml()
  }

  doSimpleDataFlow(element) {
    element.ticket_name = element.title

    var changeBtn = `Avançar`
    if (element.journey == `meeting`) {
      var changeBtn = `Realizado`
    }

    var html = `<div class="row my-2 cardRow" data-id="${element.id}" data-journey-number="${element.journey_number}" data-url="/a/eventos/${element.path}" data-${this.controllerName}-target="card-${element.id} eventCard">
                 <div class="col-12 px-1">
                   <div class="card">
                     <div class="card-body px-0 pt-1 pb-0 f-065">
                       <div class="row my-2 d-flex align-items-center">
                         <div class="col-12 px-1 pointer" data-action="click->${this.controllerName}#goToURL"> 
                           ${element.title}
                         </div>
                       </div>
                       <div class="row">
                         <div class="col-2 p-0">
                           <button data-action="click->${this.controllerName}#showTickets" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">add_task</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Adicionar Ticket</span>
                           </button>
                         </div>
                         <div class="col-2 p-0">
                           <button data-action="click->${this.controllerName}#addCheckNote" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">note_add</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Adicionar Nota de Check</span>
                           </button>
                         </div>
                         <div class="col-2 p-0">
                           <button data-action="click->${this.controllerName}#listCheckNote" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                             <span class="material-icons md-sm md-dark">checklist</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">Notas de Check</span>
                           </button>
                         </div>
                         <div class="col-6 p-0">
                           <button data-action="click->${this.controllerName}#changeJourney" type="button" class="btn btn-sm btn-table p-0 w-100 mc-tooltip">
                             <span class="material-icons md-sm md-dark">trending_flat</span>
                             <span class="mc-tooltiptext mc-tooltiptext-right">${changeBtn}</span>
                           </button>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>`

    if (element.journey == `prospecting`) {
      this.controllerDashboard.prospectingFlowTarget.insertAdjacentHTML("afterbegin", html)
    } else if (element.journey == `planning`) {
      this.controllerDashboard.planningFlowTarget.insertAdjacentHTML("afterbegin", html)
    } else if (element.journey == `meeting`) {
      this.controllerDashboard.meetingFlowTarget.insertAdjacentHTML("afterbegin", html)
    } else if (element.journey == `proposal`) {
      this.controllerDashboard.proposalFlowTarget.insertAdjacentHTML("afterbegin", html)
    }
  }

  setTickets(element) {
    element.tickets.forEach(ticket => {
      if (ticket.performer_id == this.application.current_user.account_id) {
        ticket.url = element.url
        ticket.opportunity = element.ticket_name
        this.application.tickets[this.application.tickets.length] = ticket
      }
    })
  }

  doDataIndicator() {
    var planningTotal = 0
    var prospectingTotal = 0
    var proposalTotal = 0
    var meetingTotal = 0
    var finishedTotal = 0
    var canceledTotal = 0

    var planningAdmission = 0
    var prospectingAdmission = 0
    var proposalAdmission = 0
    var meetingAdmission = 0
    var finishedAdmission = 0
    var canceledAdmission = 0

    if (this.application.events.length == 0) {
      this.controllerDashboard.planningTotalTarget.innerText = planningTotal
      this.controllerDashboard.planningAdmissionTarget.innerText = planningAdmission
      this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
      this.controllerDashboard.prospectingAdmissionTarget.innerText = prospectingAdmission
      this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
      this.controllerDashboard.meetingAdmissionTarget.innerText = meetingAdmission
      
      this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
      this.controllerDashboard.meetingAdmissionTarget.innerText = meetingAdmission
      this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal
      this.controllerDashboard.proposalAdmissionTarget.innerText = proposalAdmission
    } else {
      this.application.events.forEach(element => {

        this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
        this.controllerDashboard.planningTotalTarget.innerText = planningTotal
        this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal

        this.controllerDashboard.prospectingAdmissionTarget.innerText = prospectingAdmission
        this.controllerDashboard.planningAdmissionTarget.innerText = planningAdmission
        this.controllerDashboard.meetingAdmissionTarget.innerText = meetingAdmission

        if (element.journey == `prospecting`) {
          prospectingTotal += 1
          this.controllerDashboard.prospectingTotalTarget.innerText = prospectingTotal
          prospectingAdmission += Number(element.admissions_count)
          this.controllerDashboard.prospectingAdmissionTarget.innerText = prospectingAdmission
        } else if (element.journey == `planning`) {
          planningTotal += 1
          this.controllerDashboard.planningTotalTarget.innerText = planningTotal
          planningAdmission += Number(element.admissions_count)
          this.controllerDashboard.planningAdmissionTarget.innerText = planningAdmission
        }

        this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
        this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal

        this.controllerDashboard.proposalAdmissionTarget.innerText = proposalAdmission

        if (element.journey == `meeting`) {
          meetingTotal += 1
          this.controllerDashboard.meetingTotalTarget.innerText = meetingTotal
          meetingAdmission += Number(element.admissions_count)
          this.controllerDashboard.meetingAdmissionTarget.innerText = meetingAdmission
        } else if (element.journey == `proposal`) {
          proposalTotal += 1
          this.controllerDashboard.proposalTotalTarget.innerText = proposalTotal
          proposalAdmission += Number(element.admissions_count)
          this.controllerDashboard.proposalAdmissionTarget.innerText = proposalAdmission
        }
        
      })
    }

  }

  addCheckNote(ev) {
    var modal = `users--works--notes--save-check-modal`
    var eventId = ev.currentTarget.closest(".cardRow").dataset.id
    var currentEvent = {}

    this.application.events.forEach(element => {
      if (element.id == eventId) {
        currentEvent = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller
      controller.getControllerByIdentifier(modal).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: currentEvent.id,
        domain_type: `marketing_events`,
        domain_name: `Evento | ${currentEvent.title}`,
      }

      controller.getControllerByIdentifier(modal).current_obj = currentEvent
      controller.getControllerByIdentifier(modal).title = `Check ${currentEvent.title}`
      controller.getControllerByIdentifier(modal).permission = `commercial_event_notes`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showTickets(ev) {
    var eventId = ev.currentTarget.closest(".cardRow").dataset.id
    this.current_event = {}

    this.application.events.forEach(element => {
      if (element.id == eventId) {
        this.current_event = element
      }
    })
    
    this.getTickets()
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.contract_tickets = []

    var data = { ticket: { board_id: this.current_event.id, board_type: `marketing_events` }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_event_tickets` } }
    const url = "/users/works/activities/tickets/list_by_board"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(element => {
          controller.contract_tickets[controller.contract_tickets.length] = element
        })

        controller.showTicketsBoard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showTicketsBoard() {
    var controllerModal = `users--works--activities--shared--board-modal`

    var html = `<div class="modal fade" data-controller="${controllerModal}" data-${controllerModal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${controllerModal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(controllerModal).current_tickets = controller.contract_tickets
      controller.getControllerByIdentifier(controllerModal).obj_type = `marketing_event`
      controller.getControllerByIdentifier(controllerModal).obj_id = controller.current_event.id
      controller.getControllerByIdentifier(controllerModal).title = `${controller.current_event.title}`
      controller.getControllerByIdentifier(controllerModal).permission = `commercial_event_tickets`
      // controller.getControllerByIdentifier(controllerModal).ticket_count_controller = controller.getControllerByIdentifier(`financials--books--receivables--dashboard--submenu`)
      controller.getControllerByIdentifier(controllerModal).ticket_board = {
        board_id: controller.current_event.id,
        board_type: `marketing_events`,
        board_subtype: `marketing_events`,
        board_path: `/a/eventos/${controller.current_event.slug}`,
        board_name: `Evento | ${controller.current_event.title}`,
        // date_id: controller.application.current_date.id,
        // date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(controllerModal).comment_board = {
        board_id: controller.current_event.id,
        board_type: `marketing_events`,
        // date_id: controller.application.current_date.id,
        // date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(controllerModal).open()
    })
  }

  listCheckNote(ev) {
    var modal = `users--works--notes--list-check-modal`
    var eventId = ev.currentTarget.closest(".cardRow").dataset.id
    var currentEvent = {}

    this.application.events.forEach(element => {
      if (element.id == eventId) {
        currentEvent = element
      }
    })

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).dashboardController = controller
      controller.getControllerByIdentifier(modal).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: currentEvent.id,
        domain_type: `marketing_events`,
        domain_name: `Evento | ${currentEvent.title}`,
      }

      controller.getControllerByIdentifier(modal).current_obj = currentEvent
      controller.getControllerByIdentifier(modal).title = `Check ${currentEvent.title}`
      controller.getControllerByIdentifier(modal).permission = `commercial_event_notes`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  goToURL(ev) {
    var url = ev.currentTarget.closest(".cardRow").dataset.url
    window.open(url, `_blank`)
  }

  addTicket(ev) {
    var id = ev.currentTarget.closest(".cardRow").dataset.id
  }

  changeJourney(ev) {

    var journeyNumber = Number(ev.currentTarget.closest(".cardRow").dataset.journeyNumber)
    var currentJourney = this.setNewJourney(journeyNumber)

    this.journeys.forEach(element => {
      if (element.name == currentJourney) {
        currentJourney = element
      }
    })

    if (currentJourney.name == `meeting`) {
      var r = confirm("Confirma a Realização do Evento?")
    } else {
      var r = confirm(`Confirma o Avanço do Evento para ${currentJourney.next_pretty}?`)
    }

    if (r) {
      var eventId = ev.currentTarget.closest(".cardRow").dataset.id
      
      this.send_data = { current_user: {}, event: {}, product: {} }

      this.send_data.event.id = eventId
      this.send_data.event.journey = currentJourney.next

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = `commercial_event_dealflows`

      this.requestSave()
    }
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/commercial/marketing/events/entities/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var event = response.data.cln

          controller.application.events.forEach((element, i) => {
            if (element.id == event.id) {
              controller.application.events.splice(controller.application.events.indexOf(element), 1, event)
            }
          })

          controller.doDataFlow()
          controller.doDataIndicator()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  setNewJourney(journeyNumber) {
    var all_journeys = ["planning", "prospecting", "proposal", "meeting", "finished", "canceled"]

    return all_journeys[journeyNumber]
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
