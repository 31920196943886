import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "btn", "mainTooltip", "partnerPdf", "mainPdf", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--apurations--list`
    if (this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--apurations--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--apurations--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--managements--apurations--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--managements--apurations--dashboard")
    }
  }

  doListHtml() {
    var partnerHtml = ``

    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.current_main_apuration.partner_apurations, `partner_name`)

    partners.forEach(apuration => {
      if (apuration.partner_operational) {

        if (this.application.current_user.account_kind == `doctor`) {
          // if (apuration.partner_record_id == this.application.current_user.account_id) {
          if (apuration.partner_id == this.application.current_partner.id) {
            partnerHtml += this.doPartnerHtml(apuration)
          }
        } else if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
          partnerHtml += this.doPartnerHtml(apuration)
        }
      }
    })

    if (this.application.permissions[this.permission].can_update) {
      var refreshBtn = `<span class="mc-tooltip ml-auto">
                              <span class="material-icons md-white md-150" data-clinic-id="${this.application.current_main_apuration.clinic_id}" data-date-id="${this.application.current_main_apuration.date_id}" data-action="click->${this.controllerName}#refreshMainApuration">refresh</span>
                              <span class="mc-tooltiptext" data-${this.controllerName}-target="mainTooltip">Atualizar Apuração</span>
                            </span>`
    } else {
      var refreshBtn = ``
    }

    var pdfBtn = ``
    var pdfBtn = `<span class="mc-tooltip pointer mr-3" data-${this.controllerName}-target="mainPdf">
                    <span class="material-icons-two-tone md-dark md-200" data-clinic-id="${this.application.current_main_apuration.clinic_id}" data-date-id="${this.application.current_main_apuration.date_id}" data-action="click->${this.controllerName}#generateMainApurationPdf">picture_as_pdf</span>
                    <span class="mc-tooltiptext">Gerar/Atualizar PDF da Apuração da Empresa</span>
                  </span>`
    
    if (this.application.current_main_apuration.has_main_pdf) {

      if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
        var sendEmailHtml = `<span class="mc-tooltip pointer mr-3" data-${this.controllerName}-target="mainPdf">
                              <span class="material-icons-two-tone md-dark md-200" data-id="${this.application.current_main_apuration.id}" data-kind="main" data-action="click->${this.controllerName}#sendEmail">send</span>
                              <span class="mc-tooltiptext">Enviar Email de Apuração</span>
                            </span>`
      } else {
        var sendEmailHtml = ``
      }

      var main_pdf = `<span class="mc-tooltip pointer mr-3" data-${this.controllerName}-target="mainPdf">
                        <span class="material-icons-two-tone md-dark md-200" data-kind="main_pdf" data-obj-id="${this.application.current_main_apuration.id}" data-obj-type="clinic_fin_main_apurations" data-action="click->${this.controllerName}#goToFile">launch</span>
                        <span class="mc-tooltiptext">Ver PDF da Apuração da Empresa</span>
                      </span>
                      ${sendEmailHtml}`
    } else {
      var main_pdf = `<span class="mc-tooltip pointer ml-3" data-${this.controllerName}-target="mainPdf">
                        <span class="material-icons-two-tone md-danger md-200" data-url="${this.application.current_main_apuration.main_pdf_url}" data-action="click->${this.controllerName}#goToURL">hide_image</span>
                        <span class="mc-tooltiptext">Sem PDF da Apuração</span>
                      </span>`
    }

    var html = `<div class="row mb-5">
                  <div class="col-12 px-0">
                    <button type="button" class="btn btn-primary btn-border btn-sm w-100 mb-3 f-065 d-flex align-items-center" data-action="${this.controllerName}#goToMain" data-${this.controllerName}-target="btn">
                      <span class="">Apuração Completa</span>
                      ${refreshBtn}
                    </button>
                  </div>
                  <div class="col-12 px-0 d-flex align-items-center">
                    ${pdfBtn}
                    ${main_pdf}
                  </div>
                </div>
                ${partnerHtml}`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.listTarget.innerHTML = html)
    }).then(() => {
    })
  }

  doPartnerHtml(apuration) {
    var html = ``

    var returnPath = `/a/${apuration.partner_return.product_path}/${apuration.partner_return.token}`
    var partnerBtn = `<button type="button" class="btn btn-flat-primary btn-border btn-sm w-100 mb-3 f-065 t-break" data-action="${this.controllerName}#goToPartner" data-partner-id="${apuration.partner_id}" data-apuration-id="${apuration.id}" data-return-path="${returnPath}" data-${this.controllerName}-target="btn">${apuration.partner_name}</button>`

    var generateHtml = `<span class="mc-tooltip pointer mr-3 d-none" data-${this.controllerName}-target="partnerPdf-${apuration.id} partnerPdf">
                              <span class="material-icons-two-tone md-dark md-200" data-clinic-id="${this.application.current_main_apuration.clinic_id}" data-date-id="${this.application.current_main_apuration.date_id}" data-partner-id="${apuration.partner_id}" data-action="click->${this.controllerName}#generatePartnerApurationPdf">picture_as_pdf</span>
                              <span class="mc-tooltiptext">Gerar/Atualizar PDF da Apuração ${apuration.partner_name}</span>
                            </span>`

    if (apuration.has_partner_pdf) {
      if (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`) {
        var sendEmailHtml = `<span class="mc-tooltip pointer mr-3">
                                <span class="material-icons-two-tone md-dark md-200" data-apuration-id="${apuration.id}" data-partner-id="${apuration.partner_id}" data-kind="partner" data-action="click->${this.controllerName}#sendEmail">send</span>
                                <span class="mc-tooltiptext">Enviar Email para ${apuration.partner_name}</span>
                              </span>`
      } else {
        var sendEmailHtml = ``
      }
      var partnerPdfHtml = `<span class="d-none" data-${this.controllerName}-target="partnerConditionalPdf-${apuration.id} partnerPdf">
                                  <span class="mc-tooltip pointer mr-3">
                                    <span class="material-icons-two-tone md-dark md-200" data-kind="partner_pdf" data-obj-id="${apuration.id}" data-obj-type="clinic_fin_partner_apurations" data-action="click->${this.controllerName}#goToFile">launch</span>
                                    <span class="mc-tooltiptext">Ver PDF da Apuração ${apuration.partner_name}</span>
                                  </span>
                                  ${sendEmailHtml}
                                </span>`
    } else {
      var partnerPdfHtml = `<span class="d-none" data-${this.controllerName}-target="partnerConditionalPdf-${apuration.id} partnerPdf">
                                  <span class="mc-tooltip pointer ml-3">
                                    <span class="material-icons-two-tone md-danger md-200" data-url="${this.application.current_main_apuration.main_pdf_url}" data-action="click->${this.controllerName}#goToURL">hide_image</span>
                                    <span class="mc-tooltiptext">Sem PDF da Apuração</span>
                                  </span>
                                </span>`
    }

    html += `<div class="row mb-2">
                <div class="col-12 px-0">
                  ${partnerBtn}
                </div>
                <div class="col-12 px-0 d-flex align-items-center">
                  ${generateHtml}
                  ${partnerPdfHtml}
                </div>
              </div>`

    return html
  }

  goToMain(ev) {
    this.getControllerByIdentifier("operations--products--clinics--financials--apurations--principal").doPrincipalHtml()
    this.mainTooltipTarget.classList.remove(`d-none`)
    this.mainPdfTargets.forEach(element => {
      element.classList.remove(`d-none`)
    });
    this.partnerPdfTargets.forEach(element => {
      element.classList.add(`d-none`)
    });
    this.switchBtn(ev)
  }

  goToPartner(ev) {
    var apurationId = ev.currentTarget.dataset.apurationId
    var partnerId = ev.currentTarget.dataset.partnerId
    this.mainTooltipTarget.classList.add(`d-none`)

    this.mainPdfTargets.forEach(element => {
      element.classList.add(`d-none`)
    });
    this.partnerPdfTargets.forEach(element => {
      element.classList.add(`d-none`)
    });

    this.nameTarget(`partnerPdf-${apurationId}`).classList.remove(`d-none`)
    this.nameTarget(`partnerConditionalPdf-${apurationId}`).classList.remove(`d-none`)

    this.application.current_main_apuration.partner_apurations.forEach(element => {
      if (element.id == apurationId) {
        this.getControllerByIdentifier(`operations--products--clinics--financials--apurations--partner`).current_apuration = element
        this.getControllerByIdentifier(`operations--products--clinics--financials--apurations--partner`).doPartnerHtml()
      }
    })

    this.switchBtn(ev)
  }

  sendEmail(ev) {
    var current_receivers = []
    var current_obj = {}
    var controller = this
    var kind = ev.currentTarget.dataset.kind
    if (kind == `main`) {
      this.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).sendControllerName = `operations--products--clinics--managements--emails--send-main-apuration`
      var message = `Selecione o(s) Sócio(s) e os Anexos para enviar o Email!`
      current_receivers = this.application.partners
      current_obj = controller.application.current_main_apuration
    } else if (kind == `partner`) {
      this.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).sendControllerName = `operations--products--clinics--managements--emails--send-partner-apuration`
      var message = `Selecione os Anexos para enviar o Email!`
      var partnerId = ev.currentTarget.dataset.partnerId
      this.application.partners.forEach(partner => {
        if (partner.id == partnerId) {
          current_receivers[current_receivers.length] = partner
        }
      })
      var apurationId = ev.currentTarget.dataset.apurationId
      this.application.current_main_apuration.partner_apurations.forEach(apuration => {
        if (apuration.id == apurationId) {
          current_obj = apuration
        }
      })
    }
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--pre-email`).openEmailModal(message))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_obj = current_obj
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_clinic = controller.application.clinic
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_date = controller.application.current_date
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).current_receivers = current_receivers
      controller.getControllerByIdentifier(`operations--products--clinics--managements--emails--send`).open()
    })
  }

  refreshMainApuration(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var clinicId = ev.currentTarget.dataset.clinicId
    var dateId = ev.currentTarget.dataset.dateId

    this.send_data = { apuration: {}, current_user: {} }
    this.send_data.apuration.clinic_id = clinicId
    this.send_data.apuration.date_id = dateId

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/financials/main_apurations/refresh"
    var method = "POST"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.current_main_apuration = response.data.cln
          controller.dashboardController.doApurationsDashboard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  generateMainApurationPdf(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.send_data = { apuration: {}, current_user: {}, tracker: { log: true } }

    this.send_data.apuration.clinic_id = this.application.clinic.id
    this.send_data.apuration.date_id = this.application.current_date.id
    this.send_data.apuration.company_name = this.application.clinic.company_name
    this.send_data.apuration.date_name = this.application.current_date.name
    this.send_data.apuration.observations = `PDF Gerado`

    this.send_data.tracker.action = `generated`
    this.send_data.tracker.record_id = this.application.clinic.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/financials/main_apurations/generate_pdf"
    var method = "POST"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.current_main_apuration = response.data.cln
          controller.dashboardController.doApurationsDashboard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  generatePartnerApurationPdf(ev) {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    var clinicId = ev.currentTarget.dataset.clinicId
    var dateId = ev.currentTarget.dataset.dateId
    var partnerId = ev.currentTarget.dataset.partnerId

    this.send_data = { apuration: {}, current_user: {} }
    this.send_data.apuration.clinic_id = clinicId
    this.send_data.apuration.date_id = dateId
    this.send_data.apuration.partner_id = partnerId

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/clinics/financials/partner_apurations/generate_pdf"
    var method = "POST"
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.application.current_main_apuration = response.data.cln
          controller.dashboardController.doApurationsDashboard()
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  switchBtn(ev) {
    this.btnTargets.forEach(element => {
      element.classList.remove("btn-primary")
      element.classList.add("btn-flat-primary")
    })

    ev.currentTarget.classList.add("btn-primary")
    ev.currentTarget.classList.remove("btn-flat-primary")
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
