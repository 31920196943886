import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "startedAtInput", "nameInput", "tagInput", "descriptionInput",

                    "budgetInput", "goalInput", "statusInput", "statusList",
                    
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "numberForm",
                    "numberInput", "nameInput", "accountInput", "pixInput", "saveBtn", "nameModal"]


  connect() {
    this.controllerName = `commercial--marketing--campaigns--dashboard--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--list").doIndexListHtml()
  }

  saveCampaign() {
    this.send_data = { current_user: {}, campaign: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit`) {
      this.send_data.campaign.id = this.current_campaign.id
    }
    
    this.send_data.campaign.name = this.nameInputTarget.value
    this.send_data.campaign.tag = this.tagInputTarget.value
    this.send_data.campaign.started_at = this.getControllerByIdentifier("app--helpers--date").transformAllKindDate(this.startedAtInputTarget.value)
    this.send_data.campaign.description = this.descriptionInputTarget.value
    this.send_data.campaign.budget = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.budgetInputTarget.value)
    this.send_data.campaign.goal = this.getControllerByIdentifier("app--helpers--numbers").cleanNumber(this.goalInputTarget.value)

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/campaigns/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/campaigns/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/commercial/marketing/campaigns/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var campaign = response.data.cln
          if (this.actionMode == `new`) {
            controller.application.campaigns[controller.application.campaigns.length] = campaign
          } else if (this.actionMode == `edit`) {
            controller.application.campaigns.forEach((element, i) => {
              if (element.id == campaign.id) {
                controller.application.campaigns.splice(controller.application.campaigns.indexOf(element), 1, campaign)
              }
            })
          } else if (this.actionMode == `destroy`) {
            controller.application.campaigns.forEach((element, i) => {
              if (element.id == campaign.id) {
                controller.application.campaigns.splice(controller.application.campaigns.indexOf(element), 1)
              }
            })
          }

          controller.cancelSave()
          controller.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    // `<div class="row my-2">
    //   <div class="col-6">
    //     <div class="form-group">
    //       <div class="floating-label floating-label-sm">
    //         <label>Status</label>
    //         <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
    //           <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
    //           <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
    //             <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
    //             <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
    //               <li data-status="active" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aberta</li>
    //               <li data-status="inactive" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Encerrada</li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div class="col-6">
    //     <div class="form-group">
    //       <div class="floating-label floating-label-sm">
    //         <label>Tipo</label>
    //         <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
    //           <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
    //           <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
    //             <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
    //             <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
    //               <li data-kind="main" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Principal</li>
    //               <li data-kind="secondary" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Secundária</li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>`
    this.stopRefreshing()

    if (this.application.permissions.marketing_campaign_entities.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveCampaign" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var deleteBtnHtml = ``

    var html = `<div class="row">
                  <div class="col-6 offset-3">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>

                      <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
      
                        <div class="row my-2">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Nome</label>
                                <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" data-action="blur->${this.controllerName}#bindTag keyup->${this.controllerName}#bindTag" placeholder="Nome" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="tagForm">Tag</label>
                                <input aria-describedby="tagFormHelp" class="form-control" data-${this.controllerName}-target="tagInput" placeholder="Tag" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-2">

                        </div>
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="startedAtForm">Início</label>
                                <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Início" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="budgetInputForm">Orçamento</label>
                                <input aria-describedby="budgetInputFormHelp" class="form-control" id="budgetInputForm" data-${this.controllerName}-target="budgetInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Orçamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="goalInputForm">Objetivo</label>
                                <input aria-describedby="goalInputFormHelp" class="form-control" id="goalInputForm" data-${this.controllerName}-target="goalInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask keyup->${this.controllerName}#numberMask" placeholder="Objetivo" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="descriptionForm">Descrição</label>
                              <textarea aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required></textarea>
                            </div>
                          </div>
                        </div>

                        
                      </div>

                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${deleteBtnHtml}
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--campaigns--dashboard--main").listTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pickNormal($(this.startedAtInputTarget), {})

      controller.tagInputTarget.disabled = true

      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Campanha`
        controller.budgetInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(0))
        controller.goalInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(0))

        // controller.statusDropdownBtnTarget.disabled = true
        // controller.statusDropdownTarget.value = `active`
        // controller.statusInputTarget.innerText = `Aberta`
        // controller.statusInputTarget.dataset.status = `active`

      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Campanha`

        controller.nameInputTarget.value = controller.current_campaign.name
        controller.tagInputTarget.value = controller.current_campaign.tag
        controller.descriptionInputTarget.value = controller.current_campaign.description
        controller.startedAtInputTarget.value = controller.current_campaign.started_at_pretty
        controller.budgetInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_campaign.budget * 100))
        controller.goalInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(controller.current_campaign.goal * 100))
        
        // controller.kindDropdownTarget.value = controller.current_campaign.kind
        // controller.kindInputTarget.innerText = controller.current_campaign.kind_pretty
        // controller.kindInputTarget.dataset.kind = controller.current_campaign.kind

        controller.nameModalTarget.disabled = true
        controller.nameInputTarget.disabled = true
        controller.tagInputTarget.disabled = true
        controller.descriptionInputTarget.disabled = true
        controller.startedAtInputTarget.disabled = true
        

      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })

  }

  bindTag(ev) {
    var name = ev.currentTarget.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(" ")

    this.tagInputTarget.value = name.join("-")
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  numberMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToNumber(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasStartedAtInputTarget && controller.startedAtInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasNameInputTarget && controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.hasTagInputTarget && controller.tagInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

}
