import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "viewTitle", "subtitle", "ticketsInProcess", "ticketsFlow", "ticketsTotal", "cardFilter", "search", "searchInput",
                    "prospectingFlow", "qualificationFlow", "bookingFlow", "meetingFlow", "proposalFlow", "closingFlow",
                    "prospectingTotal", "qualificationTotal", "bookingTotal", "meetingTotal", "proposalTotal", "closingTotal",
                    "prospectingAmount", "qualificationAmount", "bookingAmount", "meetingAmount", "proposalAmount", "closingAmount",
                    "team", "teamDropdown", "teamDropdownBtn", "teamInput", "teamList"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--dealflow--dashboard`
    this.all_team = false
    this.account_id = this.application.current_user.account_id
    this.application.tickets = []
    this.application.notes = []
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.doFlowGrid()
    this.setFlow()
  }

  setFlow() {
    if (window.location.pathname == `/a/fluxo-prospeccao`) {
      this.flow = `prospecting`
    } else if (window.location.pathname == `/a/fluxo-fechamento`) {
      this.flow = `closing`
    }
  }

  doSearchHtml() {
    var html = `<input class="form-control f-075" data-${this.controllerName}-target="searchInput" data-action="keyup->${this.controllerName}#doSearch" id="" placeholder="Buscar Lead/Produto/Campanha/Origem..." type="text">`
    this.searchTarget.innerHTML = html
  }

  addNewOpportunity() {
    this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--save").current_lead = {}
    this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--save").doFormHtml()
  }

  setGrid() {
    this.doSearchHtml()
    if (window.location.pathname == `/a/fluxo-prospeccao`) {
      // this.flow = `prospecting`
      this.doProspectionGrid()
      this.subtitleTarget.innerText = `Gestão do Pipeline de Negócios | Fluxo de Prospecção`
    } else if (window.location.pathname == `/a/fluxo-fechamento`) {
      // this.flow = `closing`
      this.doClosingGrid()
      this.subtitleTarget.innerText = `Gestão do Pipeline de Negócios | Fluxo de Fechamento`
    }
  }

  doProspectionGrid() {
    var html = `<div class="row my-2" data-controller="commercial--sales--opportunities--dealflow--flow">
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Prospecção</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="prospectingTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="prospectingAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="prospectingFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Qualificação</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="qualificationTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="qualificationAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="qualificationFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Agendamento</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="bookingTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="bookingAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="bookingFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Tickets</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            em aberto
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsInProcess">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="today">
                            <span>Hoje</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="previous">
                            <span>Atrasadas</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="next">
                            <span>Próximas</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsFlow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").doDataFlow()
      controller.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").doDataIndicator()
    })
  }

  doClosingGrid() {
    var html = `<div class="row my-2" data-controller="commercial--sales--opportunities--dealflow--flow">
                  <div class="col-3">
                    <div class="card mb-3">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Consultoria</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="meetingTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="meetingAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="meetingFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Proposta</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="proposalTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="proposalAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="proposalFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Fechamento</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="closingTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            valores
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="closingAmount">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3" data-${this.controllerName}-target="closingFlow"></div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Tickets</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            total
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsTotal">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            em aberto
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1" data-${this.controllerName}-target="ticketsInProcess">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="today">
                            <span>Hoje</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="previous">
                            <span>Atrasadas</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer" data-${this.controllerName}-target="cardFilter" data-action="click->${this.controllerName}#filterTicketDueAt" data-due="next">
                            <span>Próximas</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsFlow"></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").doDataFlow()
      controller.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").doDataIndicator()
    })
  }

  doTicketsHtml() {
    this.in_process_tickets = []
    this.resolved_tickets = []
    this.application.tickets.forEach(element => {
      if (element.stage == `in_process`) {
        this.in_process_tickets[this.in_process_tickets.length] = element
      } else if (element.stage == `resolved`) {
        this.resolved_tickets[this.resolved_tickets.length] = element
      }
    })
    this.ticketsTotalTarget.innerText = this.application.tickets.length
    this.ticketsInProcessTarget.innerText = this.in_process_tickets.length
    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)

    this.listData(tickets)
  }

  filterTicketDueAt(ev) {
    var due = ev.currentTarget.dataset.due
    var date = new Date()
    var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    var data = []

    if (due == `today`) {
      this.in_process_tickets.forEach(element => {
        if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
          data[data.length] = element
        }
      })
    } else if (due == `previous`) {
      this.in_process_tickets.forEach(element => {
        // if (element.status == "delay") {
        //   data[data.length] = element
        // }
        if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
          data[data.length] = element
        }
      })
    } else if (due == `next`) {
      this.in_process_tickets.forEach(element => {
        if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
          data[data.length] = element
        }
      })
    }

    this.cardFilterTargets.forEach(element => {
      element.classList.remove("bg-primary")
    })

    ev.currentTarget.classList.add("bg-primary")

    this.listData(data)
  }

  listData(data) {
    this.ticketsFlowTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="row w-100" style="height:100px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tickets</span>
                      </div>
                    </div>`

      this.ticketsFlowTarget.innerHTML = noData
    } else {
      var place = 1
      var elements = []
      var columns = 4
      var rows = Math.floor(data.length / columns) + 1
      var row = 1
      if (data.length <= columns) {
        data.forEach(element => {
          elements[elements.length] = element
        })
        this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
      } else {
        data.forEach(element => {
          elements[elements.length] = element
          place += 1

          if (place > columns) {
            this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
            place = 1
            elements = []
            row += 1
          }
        })
        if (row == rows) {
          this.ticketsFlowTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
        }
      }
    }
  }

  ticketPartial(elements) {
    var elementHtml = ``
    elements.forEach(element => {
      var viewBtn = `<div class="col-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                      <span class="material-icons md-150 md-dark pointer" data-ticket-id="${element.id}" data-action="click->${this.controllerName}#goToURL" data-url="${element.url}">launch</span>
                      <span class="mc-tooltiptext">Ver na Oportunidade</span>
                    </div>`

      var dateBtn = `<div class="col-6 mr-auto px-1 d-flex align-items-center mc-tooltip">
                      <span>${element.due_at_pretty}</span>
                      <span class="mc-tooltiptext">Data de Entrega</span>
                    </div>`
      var performerBtn = `<div class="col-1 ml-auto px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 md-dark pointer">person</span>
                            <span class="mc-tooltiptext">${element.performer_name}</span>
                          </div>`

      elementHtml += `<div class="col-12 px-1 mb-2">
                        <div class="card border-flag-${element.flag}" data-id="${element.id}">
                          <div class="card-header p-1 text-center f-065 d-flex">
                            ${dateBtn}
                            ${performerBtn}
                            ${viewBtn}
                          </div>
                          <div class="card-body p-1 text-center f-065" data-${this.controllerName}-target="ticketCardBody">
                            <div class="row py-0 px-0">
                              <div class="col-6 pl-0 pr-2 d-flex align-items-center text-left ticket-content" data-${this.controllerName}-target="bodyShow-${element.id}">${element.body}</div>
                              <div class="col-6 pl-2 pr-0 text-left ticket-content border-left-primary-sm">${element.opportunity}</div>
                            </div>
                          </div>
                        </div>
                      </div>`
    })

    var html = elementHtml

    return html
  }

  doTeamHTML() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Time</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="teamDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="teamInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="teamList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.teamTarget.innerHTML = html)
    }).then(() => {
      controller.teamDropdownTarget.value = controller.application.current_user.name
      controller.teamInputTarget.innerText = controller.application.current_user.name
      controller.doTeamList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doTeamList() {
    var listHtml = ``
    listHtml += `<li data-app--helpers--selector-target="select" data-team="team" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">Todos</li>`
    this.application.sales_team.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-team="individual" data-id="${element.id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeTeam" class="li-selector dark f-065">${element.name}</li>`
    });
    this.teamListTarget.innerHTML = listHtml
  }

  changeTeam(ev) {
    if (ev.target.classList.contains("li-selector")) {
      var team = ev.target.dataset.team
      if (team == "individual") {
        this.account_id = ev.target.dataset.id
        this.all_team = false
      } else if (team == "team") {
        this.all_team = true
      }
      this.setOpportunities()
    }
  }

  doSearch(ev) {
    var filter = ev.target.value.toUpperCase()
    var filterUnaccent = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    this.getControllerByIdentifier("commercial--sales--opportunities--dealflow--flow").opportunityCardTargets.forEach(element => {
      var txtValue = element.innerText.toUpperCase()
      var txtValueUnaccent = txtValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      var show = false
      if ((txtValue.indexOf(filter) > -1) || (txtValueUnaccent.indexOf(filterUnaccent) > -1)) {
        show = true;
      }
      if (show == true) {
        element.closest(".cardRow").classList.remove("d-none")
      } else {
        element.closest(".cardRow").classList.add("d-none")
      }
    })
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  setOpportunities() {
    this.application.opportunities = []
    this.application.all_opportunities.forEach(element => {
      if (this.all_team || element.prospector_id == null || element.closer_id == null || element.prospector_id == this.account_id || element.closer_id == this.account_id) {
        element.stage_alert = element.journeys[0].stage_alert
        this.application.opportunities[this.application.opportunities.length] = element
      }
    })
    this.setGrid()
  }

  setTickets() {
    this.application.tickets = []
    this.application.opportunities.forEach(opportunity => {
      this.application.all_tickets.forEach(ticket => {
        opportunity.ticket_name = ``
        opportunity.leads.forEach(lead => {
          opportunity.ticket_name += `<h6 class="mb-0 f-065">${lead.lead_name}</h6>`
        })

        if (this.all_team) {
          if (opportunity.id == ticket.board_id) {
            ticket.url = opportunity.url
            ticket.opportunity = opportunity.ticket_name
            this.application.tickets[this.application.tickets.length] = ticket
          }
        } else {
          if (opportunity.id == ticket.board_id && ticket.performer_id == this.account_id) {
            ticket.url = opportunity.url
            ticket.opportunity = opportunity.ticket_name
            this.application.tickets[this.application.tickets.length] = ticket
          }
        }
      })
    })
    this.doTicketsHtml()
  }

  finishLoadPage() {

    this.setOpportunities()
    this.setTickets()
    if (this.application.permissions.sales_opportunities_entities.can_manage) {
      this.doTeamHTML()
    }

    var response = { type: `success`, message: `Página carregada com sucesso!` }
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
    this.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
  }

  getNotes() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Anotações`, value: 95 })

    var domainIds = this.mapElements(this.application.all_opportunities, `id`)
    var data = { note: { domain_ids: domainIds, domain_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_notes` } }
    const url = "/users/accounts/notes/list_check_by_domains"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.notes = response.data.cln
        }

        controller.finishLoadPage()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Tickets`, value: 85 })

    var boardIds = this.mapElements(this.application.all_opportunities, `id`)
    var data = { ticket: { board_ids: boardIds, board_type: "sales_opportunities" }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/sales/opportunities/tickets/list_flow"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.all_tickets = response.data.cln
        controller.getNotes()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getSalesTeam() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Time de Vendas`, value: 75 })

    const data = { feature: { feature_name: `sales_opportunities_entities`, action: `can_update` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_entities` } }
    const url = "/commercial/config/teams/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.sales_team = response.data.cln
        controller.application.team_list = response.data.cln
        controller.getTickets()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getOpportunities() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Oportunidades`, value: 70 })

    const data = { opportunity: { active: true, flow: this.flow }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/sales/opportunities/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.all_opportunities = response.data.cln
        
        if (controller.application.permissions.sales_opportunities_entities.can_manage) {
          controller.getSalesTeam()
        } else {
          controller.getTickets()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCommercialDates() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Competências`, value: 20 })

    const data = { date: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `commercial_dates` } }
    const url = "/commercial/config/dates/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        this.application.dates = response.data.cln
        this.application.current_date = response.data.cln[0]
        this.application.current_calculation = response.data.cln[0].calculation
        controller.getOpportunities()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getCurrentUserPermissions() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Permissões` })

    const data = { permissions: { subdomain: `commercial_opportunitites` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_permissions`, account_kind: this.application.current_user.account_kind } }
    const url = "/users/permissions/policies/list_by_domain"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application.permissions[permission.name] = permission.data
        });

        controller.getCommercialDates()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
        // window.location.reload()
      })
  }


  doFlowGrid() {
    var html = `<div class="row my-2">
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>${this.loader}</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-4 text-right px-1">
                            ${this.loader}
                          </div>
                          <div class="col-7 offset-1 text-left f-085 f-bold px-1">
                            ${this.loader}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3 mb-2">
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-1">
                        <div class="card">
                          <div class="card-header p-1 text-center f-065 f-bold pointer">
                            <span>${this.loader}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsFlow">
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                      <div class="col-12 my-1">${this.bodyCardLoader}</div>
                    </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getCurrentUserPermissions()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
