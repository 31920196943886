import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "dddInput", "phoneInput", "kindPhoneDropdown", "kindPhoneDropdownBtn", "kindPhoneInput", "kindPhoneList",
    "addressInput", "kindEmailDropdown", "kindEmailDropdownBtn", "kindEmailInput", "kindEmailList", "zipInput", "kindAddressDropdown", "kindAddressDropdownBtn", "kindAddressInput",
    "kindAddressList", "streetInput", "numberInput", "complementInput", "districtInput", "stateInput", "stateFilter", "cityInput", "cityFilter", "nextBtn",
    "savePhoneBtn", "saveEmailBtn", "saveAddressBtn"]

  connect() {
    this.controllerName = `users--accounts--contacts--save-shared`
    this.account = {}
    this.send_data = { address: {} }
    this.send_phone = { current_user: {}, record: {}, phone: {} }
    this.send_email = { current_user: {}, record: {}, email: {} }
    this.send_address = { current_user: {}, record: {}, address: {} }
  }

  disconnect() {
    this.stopRefreshing()
    this.stopPhoneRefreshing()
    this.stopEmailRefreshing()
    this.stopAddressRefreshing()
  }

  previousStep() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-filings--add--account").doAccountFormHtml()
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").current_step = 0
    this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").setStep()
  }

  savePhone() {
    this.stopPhoneRefreshing()
    if (this.phoneActionMode == "edit") { this.send_phone.phone.id = this.account.phone.id }
    this.send_phone.phone.ddd = this.dddInputTarget.value
    this.send_phone.phone.number = this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value)
    this.send_phone.phone.uniq_number = this.dddInputTarget.value + this.getControllerByIdentifier("app--helpers--numbers").fromPhoneToNumber(this.phoneInputTarget.value)
    this.send_phone.phone.kind = this.kindPhoneInputTarget.dataset.kind
    this.send_phone.phone.is_main = true
    this.send_phone.current_user.current_user_id = this.application.current_user.id
    this.send_phone.current_user.feature = `medfiling_team_accounts`
    this.send_phone.record.account_id = this.account.id
    this.send_phone.record.record_type = `account_entities`

    this.requestPhoneSave()
  }

  requestPhoneSave() {
    if (this.phoneActionMode == "new") {
      var url = "/users/accounts/phones/create"
      var method = "POST"
    } else if (this.phoneActionMode == "edit") {
      var url = "/users/accounts/phones/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_phone) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopPhoneRefreshing()
        if (response.save) {
          var phone = response.data.cln
          controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").account.phone = phone
          controller.savePhoneBtnTarget.innerText = `Confirmado`
          controller.savePhoneBtnTarget.disabled = true
          controller.phoneConfirmed = true
        } else {
          controller.savePhoneBtnTarget.innerText = `Salvar`
          controller.savePhoneBtnTarget.disabled = false
          controller.phoneConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveEmail() {
    this.stopEmailRefreshing()
    if (this.emailActionMode == "edit") { this.send_email.email.id = this.account.email.id }
    this.send_email.email.address = this.addressInputTarget.value
    this.send_email.email.kind = this.kindEmailInputTarget.dataset.kind
    this.send_email.email.is_main = true
    this.send_email.current_user.current_user_id = this.application.current_user.id
    this.send_email.current_user.feature = `medfiling_team_accounts`
    this.send_email.record.account_id = this.account.id
    this.send_email.record.record_type = `account_entities`

    this.requestEmailSave()
  }

  requestEmailSave() {
    if (this.emailActionMode == "new") {
      var url = "/users/accounts/emails/create"
      var method = "POST"
    } else if (this.emailActionMode == "edit") {
      var url = "/users/accounts/emails/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_email) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopEmailRefreshing()
        if (response.save) {
          var email = response.data.cln
          controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").account.email = email
          controller.saveEmailBtnTarget.innerText = `Confirmado`
          controller.saveEmailBtnTarget.disabled = true
          controller.emailConfirmed = true
        } else {
          controller.saveEmailBtnTarget.innerText = `Salvar`
          controller.saveEmailBtnTarget.disabled = false
          controller.emailConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  saveAddress() {
    this.stopAddressRefreshing()
    if (this.addressActionMode == "edit") { this.send_address.address.id = this.account.address.id }
    this.send_address.address.postal_code = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value)
    this.send_address.address.street = this.streetInputTarget.value
    this.send_address.address.number = this.numberInputTarget.value
    this.send_address.address.complement = this.complementInputTarget.value
    this.send_address.address.district = this.districtInputTarget.value
    this.send_address.address.city = this.cityInputTarget.value
    this.send_address.address.state = this.stateInputTarget.value
    this.send_address.address.country = `Brasil`
    this.send_address.address.kind = this.kindAddressInputTarget.dataset.kind
    this.send_address.address.is_main = true
    this.send_address.current_user.current_user_id = this.application.current_user.id
    this.send_address.current_user.feature = `medfiling_team_accounts`
    this.send_address.record.account_id = this.account.id
    this.send_address.record.record_type = `account_entities`

    this.requestAddressSave()
  }

  requestAddressSave() {
    if (this.addressActionMode == "new") {
      var url = "/users/accounts/addresses/create"
      var method = "POST"
    } else if (this.addressActionMode == "edit") {
      var url = "/users/accounts/addresses/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_address) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopAddressRefreshing()
        if (response.save) {
          var address = response.data.cln
          controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").account.address = address
          controller.saveAddressBtnTarget.innerText = `Confirmado`
          controller.saveAddressBtnTarget.disabled = true
          controller.addressConfirmed = true
        } else {
          controller.saveAddressBtnTarget.innerText = `Salvar`
          controller.saveAddressBtnTarget.disabled = false
          controller.addressConfirmed = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  nextStep() {
    this.stopRefreshing()
    this.nextBtnTarget.disabled = true

    var controller = this.getControllerByIdentifier("operations--products--tax-filings--add--dashboard")
    controller.send_product_data.account.email = this.addressInputTarget.value

    this.getControllerByIdentifier("operations--products--tax-filings--add--product").doProductFormHtml()
  }

  doContactFormHtml() {
    var html = this.stopRefreshing()

    var html = `<div class="col-9 mx-auto">
                  <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                    <div class="card-header d-flex align-items-center card-header-table-list f-065">
                      <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Criando ou Editando Contato do Médico</h6>
                    </div>
                    <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                      <div class="row my-2">
                        <div class="col-6 pl-0 pr-3">
                          <div class="row my-2">
                            <div class="col-2 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="dddInput">DDD</label>
                                  <input id="dddInput" data-${this.controllerName}-target="dddInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#dddMask keyup->${this.controllerName}#dddMask" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-5 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="phoneInput">Número</label>
                                  <input id="phoneInput" data-${this.controllerName}-target="phoneInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#phoneMask keyup->${this.controllerName}#phoneMask" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-5 px-1">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindPhoneDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindPhoneDropdownBtn"><span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindPhoneInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector f-065" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindPhoneList">
                                      <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                      <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 mb-4">
                            <div class="col-12 px-1 text-right">
                              <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#savePhone" data-${this.controllerName}-target="savePhoneBtn"></button>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col-7 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="addressInput">E-mail</label>
                                  <input id="addressInput" data-${this.controllerName}-target="addressInput" class="form-control p-1 s-title-0p6rem" type="email" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-5 px-1">
                              <div class="floating-label floating-label-sm">
                                <label>Tipo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindEmailDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindEmailDropdownBtn">
                                    <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindEmailInput"></span>
                                  </button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindEmailList">
                                      <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                      <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 mb-4">
                            <div class="col-12 px-1 text-right">
                              <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveEmail" data-${this.controllerName}-target="saveEmailBtn"></button>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 pl-3 pr-0">
                          <div class="row my-2">
                            <div class="col-4 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="zipInput">CEP</label>
                                  <input id="zipInput" data-${this.controllerName}-target="zipInput" class="form-control p-1 s-title-0p6rem" type="tel" data-action="blur->${this.controllerName}#zipMask keyup->${this.controllerName}#zipMask focus->${this.controllerName}#searchZip blur->${this.controllerName}#searchZip keyup->${this.controllerName}#searchZip" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-4 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label>Tipo</label>
                                  <div class="dropdown dropdown-selector" data-controller="app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindAddressDropdown">
                                    <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:29px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindAddressDropdownBtn">
                                      <span class="mr-auto w-100 selected-item f-065" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindAddressInput"></span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                      <input class="form-control form-control-selector dropdown-search-input f-065" type="text" placeholder="Buscar ...">
                                      <ul class="ul-select" data-${this.controllerName}-target="kindAddressList">
                                        <li data-kind="personal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pessoal</li>
                                        <li data-kind="commercial" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Comercial</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col-6 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="streetInput">Logradouro</label>
                                  <input id="streetInput" data-${this.controllerName}-target="streetInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-2 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="numberInput">Número</label>
                                  <input id="numberInput" data-${this.controllerName}-target="numberInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-4 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="complementInput">Complemento</label>
                                  <input id="complementInput" data-${this.controllerName}-target="complementInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row my-2">
                            <div class="col-5 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="districtInput">Bairro</label>
                                  <input id="districtInput" data-${this.controllerName}-target="districtInput" class="form-control p-1 s-title-0p6rem" type="text" required>
                                </div>
                              </div>
                            </div>
                            <div class="col-2 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="stateForm">UF</label>
                                  <input aria-describedby="stateFormHelp" class="form-control p-1 s-title-0p6rem" id="stateForm" data-filter-mode="simple" data-${this.controllerName}-target="stateInput" data-action="focus->${this.controllerName}#stateFilter keyup->${this.controllerName}#stateFilter blur->${this.controllerName}#hideList" placeholder="UF" type="text" required>
                                  <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stateFilter">
                                    <li data-filter="MG" data-text="MG" data-state="MG" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MG</li>
                                    <li data-filter="SP" data-text="SP" data-state="SP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SP</li>
                                    <li data-filter="AC" data-text="AC" data-state="AC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AC</li>
                                    <li data-filter="AL" data-text="AL" data-state="AL" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AL</li>
                                    <li data-filter="AP" data-text="AP" data-state="AP" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AP</li>
                                    <li data-filter="AM" data-text="AM" data-state="AM" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">AM</li>
                                    <li data-filter="BA" data-text="BA" data-state="BA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">BA</li>
                                    <li data-filter="CE" data-text="CE" data-state="CE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">CE</li>
                                    <li data-filter="DF" data-text="DF" data-state="DF" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">DF</li>
                                    <li data-filter="ES" data-text="ES" data-state="ES" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">ES</li>
                                    <li data-filter="GO" data-text="GO" data-state="GO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">GO</li>
                                    <li data-filter="MA" data-text="MA" data-state="MA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MA</li>
                                    <li data-filter="MT" data-text="MT" data-state="MT" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MT</li>
                                    <li data-filter="MS" data-text="MS" data-state="MS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">MS</li>
                                    <li data-filter="PA" data-text="PA" data-state="PA" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PA</li>
                                    <li data-filter="PB" data-text="PB" data-state="PB" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PB</li>
                                    <li data-filter="PR" data-text="PR" data-state="PR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PR</li>
                                    <li data-filter="PE" data-text="PE" data-state="PE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PE</li>
                                    <li data-filter="PI" data-text="PI" data-state="PI" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">PI</li>
                                    <li data-filter="RJ" data-text="RJ" data-state="RJ" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RJ</li>
                                    <li data-filter="RN" data-text="RN" data-state="RN" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RN</li>
                                    <li data-filter="RS" data-text="RS" data-state="RS" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RS</li>
                                    <li data-filter="RO" data-text="RO" data-state="RO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RO</li>
                                    <li data-filter="RR" data-text="RR" data-state="RR" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">RR</li>
                                    <li data-filter="SC" data-text="SC" data-state="SC" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SC</li>
                                    <li data-filter="SE" data-text="SE" data-state="SE" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">SE</li>
                                    <li data-filter="TO" data-text="TO" data-state="TO" data-action="click->${this.controllerName}#selectState click->${this.controllerName}#getCities" class="li-selector dark">TO</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div class="col-5 px-1">
                              <div class="form-group">
                                <div class="floating-label floating-label-sm">
                                  <label for="cityForm">Cidade</label>
                                  <input aria-describedby="cityFormHelp" class="form-control p-1 s-title-0p6rem" id="cityForm" data-filter-mode="simple" data-${this.controllerName}-target="cityInput" data-action="focus->${this.controllerName}#cityFilter keyup->${this.controllerName}#cityFilter blur->${this.controllerName}#hideList" placeholder="Cidade" type="text" required>
                                  <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="cityFilter"></ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 mb-4">
                            <div class="col-12 px-1 text-right">
                              <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->${this.controllerName}#saveAddress" data-${this.controllerName}-target="saveAddressBtn"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                                      
                    </div>
                    <div class="card-footer border-top text-right py-1">
                      <button type="button" class="btn btn-primary btn-sm py-2 f-065" data-action="click->operations--products--tax-filings--add--dashboard#changeStep click->${this.controllerName}#nextStep" data-next="2" data-${this.controllerName}-target="nextBtn">Próximo</button>
                    </div>
                  </div>
                </div>`

    var controller = this
    // `<button type="button" class="btn btn-secondary btn-sm py-2 f-065" data-dismiss="modal" data-action="click->${this.controllerName}#previousStep">Voltar</button>`
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").formTarget.innerHTML = html)
    }).then(() => {
      controller.refreshSaveBtn()
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.account = controller.getControllerByIdentifier("operations--products--tax-filings--add--dashboard").account

      if (controller.account.phone.id) {
        controller.phoneActionMode = `edit`
        controller.savePhoneBtnTarget.innerText = `Confirmar`
        controller.dddInputTarget.value = controller.account.phone.ddd
        controller.phoneInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").phoneMask(controller.account.phone.number)
        controller.kindPhoneInputTarget.dataset.kind = controller.account.phone.kind
        controller.kindPhoneInputTarget.innerText = controller.account.phone.kind_pretty
        controller.kindPhoneDropdownTarget.value = controller.account.phone.kind_pretty
      } else {
        controller.phoneActionMode = `new`
        controller.savePhoneBtnTarget.innerText = `Salvar`
      }

      if (controller.account.email.id) {
        controller.emailActionMode = `edit`
        controller.saveEmailBtnTarget.innerText = `Confirmar`
        controller.addressInputTarget.value = controller.account.email.address
        controller.kindEmailInputTarget.dataset.kind = controller.account.email.kind
        controller.kindEmailInputTarget.innerText = controller.account.email.kind_pretty
        controller.kindEmailDropdownTarget.value = controller.account.email.kind_pretty
      } else {
        controller.emailActionMode = `new`
        controller.saveEmailBtnTarget.innerText = `Salvar`
      }

      if (controller.account.address.id) {
        controller.addressActionMode = `edit`
        controller.saveAddressBtnTarget.innerText = `Confirmar`
        controller.zipInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").zipMask(controller.account.address.postal_code)
        controller.streetInputTarget.value = controller.account.address.street
        controller.numberInputTarget.value = controller.account.address.number
        controller.complementInputTarget.value = controller.account.address.complement
        controller.districtInputTarget.value = controller.account.address.district
        controller.cityInputTarget.value = controller.account.address.city
        controller.stateInputTarget.value = controller.account.address.state
        controller.kindAddressInputTarget.dataset.kind = controller.account.address.kind
        controller.kindAddressInputTarget.innerText = controller.account.address.kind_pretty
        controller.kindAddressDropdownTarget.value = controller.account.address.kind_pretty
      } else {
        controller.addressActionMode = `new`
        controller.saveAddressBtnTarget.innerText = `Salvar`
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  dateMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDate(ev)
  }

  phoneMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToPhone(ev)
  }

  dddMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDDD(ev)
  }

  searchZip(ev) {

    if ((ev.type == "keyup" && this.zipInputTarget.value.length == 10) || (ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false && this.zipInputTarget.value.length == 10) || (ev.type == "blur")) {
      var cep = this.getControllerByIdentifier("app--helpers--numbers").fromZipToNumber(this.zipInputTarget.value).replace(/\D/g, '')
      var controller = this
      if (cep != "") {
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          $.getJSON("//viacep.com.br/ws/" + cep + "/json?callback=?", function (dados) {
            if (!("erro" in dados)) {
              if (dados.logradouro === "") {
                controller.streetInputTarget.disabled = false
              } else {
                controller.streetInputTarget.value = dados.logradouro
                controller.send_data.address.street = dados.logradouro
              }
              if (dados.bairro === "") {
                controller.districtInputTarget.disabled = false
              } else {
                controller.districtInputTarget.value = dados.bairro
                controller.send_data.address.district = dados.bairro
              }
              if (dados.uf === "") {
                controller.stateInputTarget.disabled = false
              } else {
                controller.stateInputTarget.value = dados.uf
                controller.stateInputTarget.dataset.filter = dados.uf
                controller.send_data.address.state = dados.uf
              }
              if (dados.localidade === "") {
                controller.cityInputTarget.disabled = false
              } else {
                controller.cityInputTarget.value = dados.localidade
                controller.cityInputTarget.dataset.filter = dados.localidade
                controller.send_data.address.city = dados.localidade
              }
              if (dados.ibge === "") {
                controller.ibgeInputTarget.disabled = false
              } else {
                controller.send_data.address.ibge = dados.ibge
              }
              controller.send_data.address.country = `Brasil`
              controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
            } else {
              controller.cleanForm();
              controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
            }
          });

        }
        else {
          controller.cleanForm();
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
        }
      }
      else {
        this.cleanForm();
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", `CEP não encontrado, favor preencher as informações manualmente`, 5000)
      }
    } else if (ev.type == "focus" && this.zipInputTarget.value.length < 10) {
      this.closeForm();
    }
  }

  cityFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 3)
  }

  stateFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 0)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  getCities(ev) {
    var state = ev.currentTarget.dataset.state
    this.getCitiesByState(state)
  }

  getCitiesByState(state) {
    var data = { state: { state: state }, current_user: { current_user_id: this.application.current_user_id } }
    const url = "/users/accounts/addresses/cities_by_state"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          var listHtml = ``
          response.data.cln.forEach(element => {
            // listHtml += `<li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-city="${element}" class="li-selector dark">${element}</li>`
            listHtml += `<li data-city="${element}" data-filter="${element}" data-text="${element}" data-action="click->${this.controllerName}#selectCity" class="li-selector dark">${element}</li>`
          });
          this.cityFilterTarget.innerHTML = listHtml
          // this.cityListTarget.innerHTML = listHtml
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  selectCity(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  selectState(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  zipMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToZip(ev)
  }

  cleanForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = false
    this.districtInputTarget.disabled = false
    this.stateInputTarget.disabled = false
    this.cityInputTarget.disabled = false

    this.send_data.address.postal_code = ``
    this.send_data.address.street = ``
    this.send_data.address.number = ``
    this.send_data.address.complement = ``
    this.send_data.address.district = ``
    this.send_data.address.city = ``
    this.send_data.address.state = ``
    this.send_data.address.country = ``
    this.send_data.address.ibge = ``
  }

  closeForm() {
    this.streetInputTarget.value = ``
    this.numberInputTarget.value = ``
    this.complementInputTarget.value = ``
    this.districtInputTarget.value = ``
    this.cityInputTarget.value = ``
    this.cityInputTarget.dataset.filter = ``
    this.stateInputTarget.value = ``
    this.stateInputTarget.dataset.filter = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    this.streetInputTarget.disabled = true
    this.districtInputTarget.disabled = true
    this.stateInputTarget.disabled = true
    this.cityInputTarget.disabled = true

    this.send_data.address.postal_code = ``
    this.send_data.address.street = ``
    this.send_data.address.number = ``
    this.send_data.address.complement = ``
    this.send_data.address.district = ``
    this.send_data.address.city = ``
    this.send_data.address.state = ``
    this.send_data.address.country = ``
    this.send_data.address.ibge = ``
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this

    this.refreshPhoneTimer = setInterval(function () {
      var len = 0

      if (controller.dddInputTarget.value == ``) {
        len += 1
      }

      if (controller.phoneInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.savePhoneBtnTarget.disabled = false
      } else {
        controller.savePhoneBtnTarget.disabled = true
      }
    }, 200);

    this.refreshEmailTimer = setInterval(function () {
      var len = 0

      if (controller.addressInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveEmailBtnTarget.disabled = false
      } else {
        controller.saveEmailBtnTarget.disabled = true
      }
    }, 200);

    this.refreshAddressTimer = setInterval(function () {
      var len = 0

      if (controller.zipInputTarget.value == ``) {
        len += 1
      }

      if (controller.streetInputTarget.value == ``) {
        len += 1
      }

      if (controller.numberInputTarget.value == ``) {
        len += 1
      }

      if (controller.districtInputTarget.value == ``) {
        len += 1
      }

      if (controller.cityInputTarget.value == ``) {
        len += 1
      }

      if (controller.stateInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveAddressBtnTarget.disabled = false
      } else {
        controller.saveAddressBtnTarget.disabled = true
      }
    }, 200);

    this.refreshTimer = setInterval(function () {
      var len = 0

      if (!controller.phoneConfirmed) {
        len += 1
      }

      if (!controller.emailConfirmed) {
        len += 1
      }

      if (!controller.addressConfirmed) {
        len += 1
      }

      if (len == 0) {
        controller.nextBtnTarget.disabled = false
      } else {
        controller.nextBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  stopPhoneRefreshing() {
    if (this.refreshPhoneTimer) {
      clearInterval(this.refreshPhoneTimer)
    }
  }

  stopEmailRefreshing() {
    if (this.refreshEmailTimer) {
      clearInterval(this.refreshEmailTimer)
    }
  }

  stopAddressRefreshing() {
    if (this.refreshAddressTimer) {
      clearInterval(this.refreshAddressTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
