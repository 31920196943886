import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "birthdateInput", "saveBtn", "8888", "9999",
                    "purchaseProspectorInput", "prospectorFilter", "purchaseCloserInput", "closerFilter", "purchaseStageInput", "stageFilter",
                    "productNameDropdown", "productNameDropdownBtn", "productNameInput", "productNameList", "purchaseKind",
                    "productKindDropdown", "productKindDropdownBtn", "productKindInput", "productKindList",
                    "productServiceDropdown", "productServiceDropdownBtn", "productServiceInput", "productServiceList",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList",
                    "productPlanDropdown", "productPlanDropdownBtn", "productPlanInput", "productPlanList", "productPlanDiv",
                    "productAccrualDropdown", "productAccrualDropdownBtn", "productAccrualInput", "productAccrualList", "productAccrualDiv"]


  connect() {
    this.controllerName = `financials--products--purchases--records--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--products--purchases--records--show").doViewHtml()
    this.getControllerByIdentifier("financials--products--purchases--records--index").cleanCheckBox()
  }

  savePurchase() {
    this.send_data = { current_user: {}, purchase: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.purchase.id = this.current_purchase.id
    this.send_data.purchase.journey_status = this.purchaseStageInputTarget.dataset.filter
    this.send_data.purchase.product_name = this.productNameInputTarget.dataset.productName

    if (this.send_data.purchase.product_name == `medfiling`) {
      this.send_data.purchase.product_accrual = this.productAccrualInputTarget.dataset.productAccrual
    }
    this.send_data.purchase.product_plan = this.productPlanInputTarget.dataset.productPlan

    this.send_data.purchase.product_kind = this.productKindInputTarget.dataset.productKind
    this.send_data.purchase.product_service = this.productServiceInputTarget.dataset.productService
    this.send_data.purchase.channel = this.channelInputTarget.dataset.channel

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_purchase_entities`

    this.requestSave()
  }

  requestSave() {
    var url = "/financials/products/purchases/entities/update_record"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var purchase = response.data.cln
          controller.application.purchases.forEach((element, i) => {
            if (element.id == purchase.id) {
              controller.application.purchases.splice(controller.application.purchases.indexOf(element), 1, purchase)
            }
          })
          controller.cancelSave()
          controller.getControllerByIdentifier("financials--products--purchases--records--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_purchase_entities.can_update) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePurchase" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Contratação</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">                                        
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Estágio</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="purchaseStageInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#stageFilter keyup->${this.controllerName}#stageFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterStage" type="text" placeholder="Estágio" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="stageFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Área MedCapital</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="channelDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="channelDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="channelInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="channelList" data-app--helpers--search-target="searchList">
                                  <li data-channel="sales" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vendas</li>
                                  <li data-channel="customer_success" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Sucesso do Cliente</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                 
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-1 text-right px-0">
                        <button data-action="click->${this.controllerName}#showProducts" data-element="productNameInput" data-${this.controllerName}-target="teamModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">help_center</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Produtos</span>
                        </button>
                      </div>
                      <div class="col-5">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Produto</label>
                            <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="productNameInput" type="text" placeholder="Produto" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productKindDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productKindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productKindList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Serviço</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productServiceDropdown">
                              <button disabled class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productServiceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productServiceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productServiceList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6" data-${this.controllerName}-target="productPlanDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Plano</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productPlanDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productPlanDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productPlanInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productPlanList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-6 d-none" data-${this.controllerName}-target="productAccrualDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Competência</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="productAccrualDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="productAccrualDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="productAccrualInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="productAccrualList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--products--purchases--records--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.purchaseStageInputTarget.value = controller.current_purchase.journey_status_pretty
      controller.purchaseStageInputTarget.dataset.filter = controller.current_purchase.journey_status

      controller.productNameInputTarget.dataset.productName = controller.current_purchase.product_name
      controller.productNameInputTarget.value = controller.current_purchase.product_name_pretty

      controller.productKindDropdownTarget.value = controller.current_purchase.product_kind
      controller.productKindInputTarget.dataset.productKind = controller.current_purchase.product_kind
      controller.productKindInputTarget.innerText = controller.current_purchase.product_kind_pretty

      controller.channelDropdownTarget.value = controller.current_purchase.channel
      controller.channelInputTarget.dataset.channel = controller.current_purchase.channel
      controller.channelInputTarget.innerText = controller.current_purchase.channel_pretty

      if (controller.current_purchase.product_plan) {
        controller.productPlanDropdownTarget.value = controller.current_purchase.product_plan_pretty
        controller.productPlanInputTarget.dataset.productPlan = controller.current_purchase.product_plan
        controller.productPlanInputTarget.innerText = controller.current_purchase.product_plan_pretty
      }

      controller.productServiceDropdownTarget.value = controller.current_purchase.product_service
      controller.productServiceInputTarget.dataset.productService = controller.current_purchase.product_service
      controller.productServiceInputTarget.innerText = controller.current_purchase.product_service_pretty

      controller.setProductPlanList()
      if (controller.current_purchase.product_name == `medfiling`) {
        controller.productAccrualDropdownTarget.value = controller.current_purchase.product_accrual
        controller.productAccrualInputTarget.dataset.productAccrual = controller.current_purchase.product_accrual
        controller.productAccrualInputTarget.innerText = controller.current_purchase.product_accrual
        controller.productAccrualDivTarget.classList.remove(`d-none`)
        controller.setProductAccrualList()
      } else {
        controller.productAccrualDivTarget.classList.add(`d-none`)
      }

      controller.product_name = controller.current_purchase.product_name
      controller.listProductServices()
      controller.listStage()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  selectProductName(productName) {
    // this.product_name = ev.currentTarget.dataset.productName
    this.product_name = productName

    this.setProductPlanList()
    if (this.product_name == `medfiling`) {
      this.productAccrualDivTarget.classList.remove(`d-none`)
      this.setProductAccrualList()
    } else {
      this.productAccrualDivTarget.classList.add(`d-none`)
    }

    this.listProductKinds()
    this.listProductServices()
  }

  listProductKinds() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medfiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`

      // this.productKindDropdownBtnTarget.disabled = false
      // this.productKindDropdownTarget.value = ``
      // this.productKindInputTarget.dataset.productKind = ``
      // this.productKindInputTarget.innerText = ``
    } else if (this.product_name == `medbooking`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medclinic`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `receivement`
      this.productKindInputTarget.dataset.productKind = `receivement`
      this.productKindInputTarget.innerText = `Recebimento`
      html += `<li data-product-kind="receivement" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recebimento</li>`
    } else if (this.product_name == `medbiling`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `practice`
      this.productKindInputTarget.dataset.productKind = `practice`
      this.productKindInputTarget.innerText = `Consultório`
      html += `<li data-product-kind="practice" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="home" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">eSocial</li>`
      html += `<li data-product-kind="employee" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Funcionário</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productKindDropdownBtnTarget.disabled = true
      this.productKindDropdownTarget.value = `none`
      this.productKindInputTarget.dataset.productKind = `none`
      this.productKindInputTarget.innerText = `Nenhum`
      html += `<li data-product-kind="none" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Nenhum</li>`
    } else if (this.product_name == `medprev`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="pgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PGBL</li>`
      html += `<li data-product-kind="vgbl_prev" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">VGBL</li>`
    } else if (this.product_name == `medloan`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="alimony" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Mesada</li>`
      html += `<li data-product-kind="debt_refinancing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financiamento</li>`
      html += `<li data-product-kind="prepayment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="finance_outsourcing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Financeiro</li>`
      html += `<li data-product-kind="virtual_office" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Escritório Virtual</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productKindDropdownBtnTarget.disabled = false
      this.productKindDropdownTarget.value = ``
      this.productKindInputTarget.dataset.productKind = ``
      this.productKindInputTarget.innerText = ``
      html += `<li data-product-kind="life_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vida</li>`
      html += `<li data-product-kind="income_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">DIT</li>`
      html += `<li data-product-kind="responsability_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">RCP</li>`
      html += `<li data-product-kind="health_insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Saúde</li>`
    }

    this.productKindListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  listProductServices() {
    var html = ``

    if (this.product_name == `medreturn`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medfiling`) {
      console.log(`medfiling`)
      this.productServiceDropdownBtnTarget.disabled = false
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura Declaração</li>`
      html += `<li data-product-service="rectification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Retificação Declaração</li>`
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medbooking`) {
      this.productServiceDropdownBtnTarget.disabled = false
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medclinic`) {
      this.productServiceDropdownBtnTarget.disabled = false
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="pos_opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pós-Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
      html += `<li data-product-service="amendment" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Alteração</li>`
      // html += `<li data-product-service="regularization" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Regularização</li>`
    } else if (this.product_name == `medreceivement`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medbiling`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medpayroll`) {
      this.productServiceDropdownBtnTarget.disabled = false
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
      html += `<li data-product-service="migration" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Migração</li>`
    } else if (this.product_name == `medconsulting`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="separate" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Avulso</li>`
    } else if (this.product_name == `medprev`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medinsurance`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medloan`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    } else if (this.product_name == `medoutsourcing`) {
      this.productServiceDropdownBtnTarget.disabled = true
      html += `<li data-product-service="opening" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Abertura</li>`
    }

    html += `<li data-product-service="renewal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Renovação</li>`
    this.productServiceListTarget.innerHTML = html
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setProductAccrualList() {
    var html = ``

    html += `<li data-product-accrual="2024" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2024</li>`
    html += `<li data-product-accrual="2023" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2023</li>`
    html += `<li data-product-accrual="2022" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2022</li>`
    html += `<li data-product-accrual="2021" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2021</li>`
    html += `<li data-product-accrual="2020" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2020</li>`
    html += `<li data-product-accrual="2019" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2019</li>`
    html += `<li data-product-accrual="2018" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">2018</li>`

    this.productAccrualListTarget.innerHTML = html
  }

  setProductPlanList() {
    var html = ``

    html += `<li data-product-plan="light" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Light</li>`
    html += `<li data-product-plan="pro" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pro</li>`
    html += `<li data-product-plan="premium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Premium</li>`
    html += `<li data-product-plan="enterprise" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Enterprise</li>`

    this.productPlanListTarget.innerHTML = html
  }

  showProducts(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--products--purchases--add--products-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).open()
    })
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listStage() {
    var html = `<li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Enviar Contrato" data-filter="to_send_contract" class="li-selector dark f-065">Enviar Contrato</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Contrato Enviado" data-filter="sent_contract" class="li-selector dark f-065">Contrato Enviado</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Contrato Assinado" data-filter="signed_contract" class="li-selector dark f-065">Contrato Assinado</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Enviar Fatura" data-filter="to_send_invoice" class="li-selector dark f-065">Enviar Fatura</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Fatura Enviada" data-filter="sent_invoice" class="li-selector dark f-065">Fatura Enviada</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Fatura Paga" data-filter="paid_invoice" class="li-selector dark f-065">Fatura Paga</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Pagamento de Comissão" data-filter="commission_payment" class="li-selector dark f-065">Pagamento de Comissão</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Finalizado" data-filter="finished" class="li-selector dark f-065">Finalizado</li>
                <li data-action="click->${this.controllerName}#selectStage" data-${this.controllerName}-target="stageFilterItem" data-text="Cancelado" data-filter="canceled" class="li-selector dark f-065">Cancelado</li>`

    this.stageFilterTarget.innerHTML = html
  }

  stageFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectStage(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterStage(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.purchaseStageInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
