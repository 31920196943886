import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["body", "bodyCard", "titleCard", "headCard", "mainCard", "headTable", "bodyTable", "pauseBtn", "viewModeBtn", "progressBar", "modal"]

  connect() {
    this.controllerName = `financials--books--cash-flows--compilations--detail-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getDetail()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    this.modalTarget.remove()
  }

  doDetailHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="headCard">
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover mb-0" style="font-size:80%;">
                          <thead data-${this.controllerName}-target="headTable"></thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard">
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" style="font-size:80%;">
                          <tbody data-${this.controllerName}-target="bodyTable"></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyCardTarget.innerHTML = html)
    }).then(() => {
      var monthlyBtn = `<span class="badge badge-primary mx-2 pointer" data-action="click->${this.controllerName}#setViewModeEv" data-${this.controllerName}-target="viewModeBtn" data-view-mode="monthly">Mensal</span>`
      var yearlyBtn = `<span class="badge badge-secondary-primary mx-2 pointer" data-action="click->${this.controllerName}#setViewModeEv" data-${this.controllerName}-target="viewModeBtn" data-view-mode="yearly">Consolidado</span>`

      controller.titleCardTarget.innerHTML = `Detalhamento Fluxo de Caixa - ${controller.accountElement.name_pretty} | ${monthlyBtn} | ${yearlyBtn}`
      controller.headCardTarget.style.height = ($(window).height() * 0.05) + "px"
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.setViewMode(this.viewMode)
    })
  }

  setViewModeEv(ev) {
    var viewMode = ev.currentTarget.dataset.viewMode

    this.viewModeBtnTargets.forEach(element => {
      element.classList.add(`badge-secondary-primary`)
      element.classList.remove(`badge-primary`)
    })

    ev.currentTarget.classList.remove(`badge-secondary-primary`)
    ev.currentTarget.classList.add(`badge-primary`)

    this.setViewMode(viewMode)
  }

  setViewMode(viewMode) {
    if (viewMode == `monthly`) {
      this.doMonthlyTableHead()
      this.doMonthlyDataTable()
    } else if (viewMode == `yearly`) {
      this.doYearlyTableHead()
      this.doYearlyDataTable()
    }
  }

  doMonthlyTableHead() {

    if (this.application.permissions.financial_statement_cash_flows.can_download) {
      var downloadHtml = `<button class="btn btn-outline my-0 btn-table btn-sm py-2 px-0 mc-tooltip" type="button">
                          <span class="material-icons md-dark" data-action="click->${this.controllerName}#downloadDetail">download</span>
                          <span class="mc-tooltiptext mc-tooltiptext-right">Baixar Planilha</span>
                        </button>`
    } else {
      var downloadHtml = ``
    }

    var monthsHtml = ``

    this.application.annual_balances.forEach(element => {
      monthsHtml += `<th scope="col" class="pointer p-1 table-5 align-middle text-center">${element.date_pretty}</th>`
    })

    this.headTableTarget.innerHTML = `<tr>
                                        <th colspan="1" scope="col" class="pointer p-1 align-middle table-5">
                                          Contas
                                        </th>
                                        <th colspan="1" scope="col" class="pointer p-1 align-middle table-3">
                                          ${downloadHtml}
                                        </th>
                                        ${monthsHtml}
                                      </tr>`

  }

  doMonthlyDataTable() {
    var accountsHtml = ``
    var monthsHtml = ``
    this.application.annual_balances.forEach(element => {
      monthsHtml += `<th scope="col" class="pointer p-1 table-5 align-middle text-center" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>`
    })

    accountsHtml += `<tr>
                       <th colspan="2" scope="col" class="pointer p-1 align-middle table-8" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>
                       ${monthsHtml}
                     </tr>`

    var uniqAccountNames = []
    uniqAccountNames = this.mapUniqElements(this.mapElements(this.accounts, `account_name`))

    this.annual_balances = []
    uniqAccountNames.forEach(accountName => {
      accountsHtml += this.monthlyBalanceTablePartial(accountName)
    })

    this.bodyTableTarget.innerHTML = accountsHtml
  }

  monthlyBalanceTablePartial(accountName) {
    var balancesHtml = ``

    var highlight = ``
    var spaceRow = ``
    var borderHighlight = ``
    var annualBalance = 0
    var elementHighlight = false

    var dateIds = this.mapElements(this.application.annual_balances, `id`)
    this.accounts.forEach(accountElement => {
      dateIds.forEach(dateElement => {
        if (accountElement.account_name == accountName && accountElement.date_id == dateElement) {
          elementHighlight = accountElement.highlight
          if (elementHighlight) {
            highlight = `f-bold`
            spaceRow = `<tr><td colspan="14" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td></tr>`
            borderHighlight = `border-highlight`
          }
          balancesHtml += `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-center ${highlight}">${this.controllerNumber.currencyOptionMask(accountElement.amount)}</td>`
          annualBalance += Number(accountElement.amount)
        }
      })
    })

    this.annual_balances[this.annual_balances.length] = { account_name: accountName, balance: annualBalance, highlight: elementHighlight }
    accountName = `<td colspan="2" style="font-size:80%;" scope="col" class="p-1 align-middle ${highlight}">${accountName}</td>`

    var rowHtml = `${spaceRow}
                  <tr class="itemRow ${borderHighlight}">
                    ${accountName} 
                    ${balancesHtml}
                  </tr>
                  ${spaceRow}`

    return rowHtml
  }

  doYearlyTableHead() {

    var monthsHtml = ``
    monthsHtml += `<th scope="col" class="pointer p-1 align-middle text-left">Consolidado 12 Meses</th>`

    this.headTableTarget.innerHTML = `<tr>
                                        <th colspan="1" scope="col" class="pointer p-1 align-middle table-20">
                                          Contas
                                        </th>
                                        ${monthsHtml}
                                      </tr>`
  }

  doYearlyDataTable() {
    var accountsHtml = ``
    var monthsHtml = ``
    monthsHtml += `<th scope="col" class="pointer p-1 align-middle text-left" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>`

    accountsHtml += `<tr>
                       <th colspan="1" scope="col" class="pointer p-1 align-middle table-20" style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;"></th>
                       ${monthsHtml}
                     </tr>`

    this.annual_balances.forEach(accountElement => {
      accountsHtml += this.yearlyBalanceTablePartial(accountElement)
    })

    this.bodyTableTarget.innerHTML = accountsHtml
  }

  yearlyBalanceTablePartial(accountElement) {
    var balancesHtml = ``

    var highlight = ``
    var spaceRow = ``
    var borderHighlight = ``

    if (accountElement.highlight) {
      highlight = `f-bold`
      spaceRow = `<tr><td colspan="2" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td></tr>`
      borderHighlight = `border-highlight`
    }
    balancesHtml = `<td style="font-size:90%;" scope="col" class="p-1 align-middle text-left ${highlight}">${this.controllerNumber.currencyOptionMask(accountElement.balance)}</td>`
    var accountName = `<td colspan="1" style="font-size:80%;" scope="col" class="p-1 align-middle ${highlight}">${accountElement.account_name}</td>`

    var rowHtml = `${spaceRow}
                  <tr class="itemRow ${borderHighlight}">
                    ${accountName} 
                    ${balancesHtml}
                  </tr>
                  ${spaceRow}`

    return rowHtml
  }

  getDetail() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data = { current_user: {}, compilation: {} }

    var dateIds = this.mapElements(this.application.annual_balances, `id`)

    this.send_data.compilation.main_account = this.accountElement.name
    this.send_data.compilation.date_ids = dateIds
    if (this.application.current_med.id) {
      this.send_data.compilation.med_id = this.application.current_med.id
    } else {
      this.send_data.compilation.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_cash_flows`

    var url = "/financials/books/cash_flows/compilations/set_subaccounts"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.accounts = []
        controller.accounts = response.data.cln
        controller.doDetailHtml()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  downloadDetail() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    this.send_data = { current_user: {}, compilation: {} }

    var dateIds = this.mapElements(this.application.annual_balances, `id`)

    this.send_data.compilation.main_account = this.accountElement.name
    this.send_data.compilation.date_ids = dateIds
    if (this.application.current_med.id) {
      var fileName = `${this.application.current_med.cnpj}-${this.application.current_date.date_token}-fluxo-caixa-livre`
      this.send_data.compilation.med_id = this.application.current_med.id
    } else {
      var fileName = `Grupo-${this.application.current_date.date_token}-${this.toKebabCase(this.accountElement.name_pretty)}`
      this.send_data.compilation.med_id = ``
    }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_cash_flows`

    var fileExtension = `csv`
    var url = "/financials/books/cash_flows/compilations/download_subaccounts"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.getControllerByIdentifier("app--helpers--csv").processingCsv(response, fileName, fileExtension)
        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  toKebabCase(str) {
    return this.getControllerByIdentifier(`app--helpers--strings`).toKebabCase(str)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  mapUniqElements(array) {
    var outputArray = [];
    var count = 0
    var start = false

    for (var j = 0; j < array.length; j++) {
      for (var k = 0; k < outputArray.length; k++) {
        if (array[j] == outputArray[k]) {
          start = true
        }
      }
      count++
      if (count == 1 && start == false) {
        outputArray.push(array[j])
      }
      start = false
      count = 0
    }
    return outputArray
  }

}