import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "periodDropdown", "periodInput", "periodList", "7777", "8888", "9999"]

  connect() {
    this.application.booking_token = location.pathname.split("/").pop()
    this.application.current_booking_date = []
    this.preloaderDashboard()
    this.getCurrentUserPermissions()
  }

  reconnect() {
    this.preloaderDashboard()
    this.doBookingDashboard()
  }

  getCurrentUserPermissions() {
    const featureNames = ["medfiling", "medfiling_dates", "medfiling_calculations", "medfiling_files", "medfiling_notes", "medfiling_journeys",
      "medfiling_tickets", "medfiling_agents", "medfiling_messages", "medfiling_comments"]
    const data = { permission: { features: featureNames }, current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/permissions/list_by_features"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        response.data.cln.forEach(permission => {
          controller.application[permission.name] = permission.data
        });
        controller.doBookingDashboard()
      })
  }

  doBookingDashboard() {
    var cardLoader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                        <div class='animated-background animated-background-5'>
                          <div class='background-masker title'></div>
                        </div>
                      </div>`

    var html = `<div class="row mt-5">
                  <div class="col-12">
                    <h5>${this.application.user_name}, esse será o seu Dashboard do seu Livro-Caixa. Em breve, você poderá <strong>Acompanhar</strong> e <strong>Fazer</strong> o seu <strong>Livro-Caixa</strong> aqui no celular.</h5>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }


  preloaderDashboard() {
    var html = `<div class="row" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:3rem">
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row dashboard-content" style="margin-top:3rem">
                  <div class="col-12">
                    <div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;">
                      <div class='animated-background animated-background-5'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                    <hr>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 mt-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 my-3">
                    <div class='card timeline-item m-0 p-1 w-100'>
                      <div class='animated-background animated-background-100'>
                        <div class='background-masker title'></div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
