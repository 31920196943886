import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "slugInput", "titleInput", "saveBtn",
                    "sharingDropdown", "sharingDropdownBtn", "sharingInput", "sharingList", "dateInput", "midiaLinkRow",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList", "descriptionInput", "copyInput", "midiaLinkInput",
                    "midiaKindDropdown", "midiaKindDropdownBtn", "midiaKindInput", "midiaKindList", "descriptionInput", "copyInput"]


  connect() {
    this.controllerName = `commercial--marketing--events--index--add`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`commercial--marketing--events--index--list`).doIndexListHtml()
  }

  saveEvent() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true

    this.send_data = { current_user: {}, event: {}, squad: {} }

    if (this.actionMode == `edit`) {
      this.send_data.event.id = this.current_event.id
    } else {

      this.send_data.squad.team_id = this.application.current_user.account_id
      this.send_data.squad.team_name = this.application.current_user.name
      this.send_data.squad.role = `owner`
      
      this.send_data.event.journey = `planning`

    }
    this.send_data.event.name = this.nameInputTarget.value
    this.send_data.event.title = this.titleInputTarget.value
    this.send_data.event.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.send_data.event.sharing = this.sharingInputTarget.dataset.sharing
    this.send_data.event.kind = this.kindInputTarget.dataset.kind
    this.send_data.event.midia_kind = this.midiaKindInputTarget.dataset.midiaKind
    this.send_data.event.midia_link = this.midiaLinkInputTarget.value
    this.send_data.event.description = this.descriptionInputTarget.value
    this.send_data.event.copy = this.copyInputTarget.value
    
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = "/commercial/marketing/events/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/commercial/marketing/events/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var event = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.events[controller.application.events.length] = event
          } else {
            controller.application.events.forEach((element, i) => {
              if (element.id == event.id) {
                controller.application.events.splice(controller.application.events.indexOf(element), 1, event)
              }
            })
          }
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveEvent" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row my-2 d-flex">
                  <div class="col-8 mx-auto">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-3 px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Título do Evento</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="titleInput" type="text" placeholder="Título do Evento" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Nome e URL do Evento</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="nameInput" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="Nome e URL do Evento" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 mt-3">
                            <div class="form-group">
                              <div class="input-group input-group-sm">
                                <input disabled class="form-control f-075 pt-0" data-${this.controllerName}-target="slugInput" type="text" placeholder="URL do Evento" required>
                                <span class="input-group-icon mr-2">/</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Data do Evento</label>
                                <input class="form-control form-valid-control" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Compartilhamento</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sharingDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sharingDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sharingInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="sharingList" data-app--helpers--search-target="searchList">
                                      <li data-sharing="public" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Público</li>
                                      <li data-sharing="client" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cliente</li>
                                      <li data-sharing="intern" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Interno</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Conteúdo</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                      <li data-kind="clinic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PJ Médica</li>
                                      <li data-kind="booking" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                      <li data-kind="taxing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Imposto de Renda</li>
                                      <li data-kind="invoicing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Faturamento</li>
                                      <li data-kind="insurance" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Seguros</li>
                                      <li data-kind="education" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Educação Financeira</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-3 px-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Plataforma</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="midiaKindDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="midiaKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="midiaKindInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="midiaKindList" data-app--helpers--search-target="searchList">
                                      <li data-midia-kind="virtual_meet" data-action="click->app--helpers--selector#select click->${this.controllerName}#setMidiaKindEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Meet</li>
                                      <li data-midia-kind="streaming" data-action="click->app--helpers--selector#select click->${this.controllerName}#setMidiaKindEv" data-app--helpers--selector-target="select" class="li-selector dark f-065">Streaming</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-6 pr-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Descrição</label>
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="descriptionInput" type="text" placeholder="Descrição" required></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-6 pl-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Copy</label>
                                <textarea rows="3" class="form-control f-075 pt-0 textarea px-0" data-${this.controllerName}-target="copyInput" type="text" placeholder="Copy" required></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          
                        </div>
                        <div class="row d-none" data-${this.controllerName}-target="midiaLinkRow">
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">Link Video Conferência</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="midiaLinkInput" type="text" placeholder="Link Video Conferência" required>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--events--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.dateInputTarget), { kind: false, max: false, months: true, years: true })
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Novo Evento`
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Evento | ${controller.current_event.name}`

        controller.nameInputTarget.value = controller.current_event.name
        controller.slugInputTarget.value = controller.getControllerByIdentifier("app--helpers--input").toPath(controller.current_event.name)
        controller.titleInputTarget.value = controller.current_event.title
        controller.dateInputTarget.value = controller.current_event.date_pretty
        controller.sharingInputTarget.innerText = controller.current_event.sharing_pretty
        controller.sharingInputTarget.dataset.sharing = controller.current_event.sharing
        controller.sharingDropdownTarget.value = controller.current_event.sharing

        controller.kindInputTarget.innerText = controller.current_event.kind_pretty
        controller.kindInputTarget.dataset.kind = controller.current_event.kind
        controller.kindDropdownTarget.value = controller.current_event.kind_pretty

        controller.midiaKindInputTarget.innerText = controller.current_event.midia_kind_pretty
        controller.midiaKindInputTarget.dataset.midiaKind = controller.current_event.midia_kind
        controller.midiaKindDropdownTarget.value = controller.current_event.midia_kind_pretty

        controller.descriptionInputTarget.value = controller.current_event.description
        controller.copyInputTarget.value = controller.current_event.copy
        controller.midiaLinkInputTarget.value = controller.current_event.midia_link

        controller.setMidiaKind(controller.current_event.midia_kind)
      }
      
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setMidiaKindEv(ev) {
    var midiaKind = ev.currentTarget.dataset.midiaKind

    this.setMidiaKind(midiaKind)
  }

  setMidiaKind(midiaKind) {
    if (midiaKind == `virtual_meet`) {
      this.midiaLinkRowTarget.classList.remove(`d-none`)
    } else {
      this.midiaLinkRowTarget.classList.add(`d-none`)
    }
  }

  bindName(ev) {
    var input = this.slugInputTarget
    var output = this.nameInputTarget

    this.getControllerByIdentifier("app--helpers--input").bindValues(ev, input, output)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasSaveBtnTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.slugInputTarget.value == ``) {
          len += 1
        }

        if (controller.titleInputTarget.value == ``) {
          len += 1
        }

        if (controller.dateInputTarget.value == ``) {
          len += 1
        }

        if (controller.sharingInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }

        if (controller.copyInputTarget.value == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }        
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
