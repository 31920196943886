import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "dateInput", "slotDropdown", "slotDropdownBtn", "slotInput", "slotList",
                    "guestInput", "accountInput", "accountFilter", "guestDiv", "stageFilter", "saveBtn", "opportunityRow",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList", "opportunityInput", "timetableInput",
                    "midiaDropdown", "midiaDropdownBtn", "midiaInput", "midiaList", "midiaLinkInput", "timetableInput",
                    "guestFilter"]


  connect() {
    this.controllerName = `users--works--schedules--entities--save`
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.controllerDashboard.identifier == `users--works--schedules--entities--save-modal`) {
      this.getControllerByIdentifier("users--works--schedules--entities--save-modal").close()
    }
  }

  saveSchedule() {
    this.send_data = { current_user: {}, schedule: {} }
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    if (this.actionMode == `edit` || this.actionMode == `done` || this.actionMode == `canceled`) {
      this.send_data.schedule.id = this.current_schedule.id      
    } else if (this.actionMode == `block`) {
      this.send_data.schedule.kind = `sales`
    } else if (this.actionMode == `new` || this.actionMode == `postponed`) {
      this.send_data.schedule.record_id = this.recordId
      this.send_data.schedule.record_type = this.recordType
      this.send_data.schedule.guest_type = this.guestType
      this.send_data.schedule.owner_id = this.application.current_user.account_id
      this.send_data.schedule.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`

      if (this.controllerForm.identifier == `commercial--sales--opportunities--entities--schedules`) {
        this.send_data.schedule.kind = `sales`
        this.send_data.schedule.opportunity_token = this.opportunityInputTarget.value
      } else if (this.controllerForm.identifier == `users--works--recruitments--schedules--dashboard`) {
        this.send_data.schedule.kind = `recruitment`
        this.send_data.schedule.opportunity_token = this.opportunityInputTarget.value
      }
    }

    this.send_data.schedule.guest_id = this.guestInputTarget.dataset.filter
    this.send_data.schedule.guest_name = this.guestInputTarget.value
    
    this.send_data.schedule.midia = this.midiaInputTarget.dataset.midia
    this.send_data.schedule.midia_link = this.midiaLinkInputTarget.value

    this.send_data.schedule.status = this.statusInputTarget.dataset.status
    this.send_data.schedule.day = new Date(this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)).getDay()
    this.send_data.schedule.schedule_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    this.send_data.schedule.account_id = this.accountInputTarget.dataset.filter
    this.send_data.schedule.account_name = this.accountInputTarget.value
    this.send_data.schedule.timetable = this.timetableInputTarget.value

    this.requestSave()
  }

  requestSave() {

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (this.actionMode == `block` || this.actionMode == `new` || this.actionMode == `postponed`) {
      var url = "/users/works/schedules/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit` || this.actionMode == `done` || this.actionMode == `canceled`) {
      var url = "/users/works/schedules/entities/update"
      var method = "PUT"
    } else if (this.actionMode == `delete`) {
      var url = "/users/works/schedules/entities/destroy"
      var method = "DELETE"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var schedule = response.data.cln

          if (controller.actionMode == `block` || controller.actionMode == `new` || controller.actionMode == `postponed`) {
            controller.application.schedules[controller.application.schedules.length] = schedule
          } else if (controller.actionMode == `edit` || controller.actionMode == `done` || controller.actionMode == `canceled`) {
            controller.application.schedules.forEach((element, i) => {
              if (element.id == schedule.id) {
                controller.application.schedules.splice(controller.application.schedules.indexOf(element), 1, schedule)
              }
            })
          } else if (controller.actionMode == `delete`) {
            controller.application.schedules.forEach((element, i) => {
              if (element.id == schedule.id) {
                controller.application.schedules.splice(controller.application.schedules.indexOf(element), 1)
              }
            })
          }

          if (controller.actionMode == `postponed`) {
            controller.actionMode = `edit`
            controller.statusDropdownTarget.value = `postponed`
            controller.statusInputTarget.dataset.status = `postponed`
            controller.statusInputTarget.innerText = `Reagendada`
            controller.dateInputTarget.value = controller.current_schedule.schedule_at_pretty
            controller.timetableInputTarget.value = controller.current_schedule.timetable
            controller.midiaDropdownTarget.value = controller.current_schedule.midia
            controller.midiaInputTarget.dataset.midia = controller.current_schedule.midia
            controller.midiaInputTarget.innerText = controller.current_schedule.midia_pretty
            controller.midiaLinkInputTarget.value = controller.current_schedule.midia_link
            controller.saveSchedule()
          } else {
            controller.cancelSave()
            controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
          }
        } else {
          controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    // `<input class="form-control f-075 pt-0" data-${this.controllerName}-target="guestInput" type="text" placeholder="Lead" required>`
    if (this.application.permissions[this.permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveSchedule" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-12" data-${this.controllerName}-target="guestDiv">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="guestForm">Convidado</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="guestInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#guestFilter keyup->${this.controllerName}#guestFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterAccount" type="text" placeholder="Convidado" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="guestFilter"></ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">MedCapital</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="accountInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#accountFilter keyup->${this.controllerName}#accountFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterAccount" type="text" placeholder="MedCapital" required>
                            <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="accountFilter"></ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="dateForm">Data</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="dateInput" type="text" placeholder="Data" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-6">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="booked" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendado</li>
                                  <li data-status="done" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Realizada</li>
                                  <li data-status="canceled" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cancelada</li>
                                  <li data-status="postponed" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reagendada</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="timetableForm">Horário</label>
                            <input aria-describedby="timetableFormHelp" class="form-control form-valid-control" id="timetableForm" data-filter-mode="simple" data-${this.controllerName}-target="timetableInput" data-action="keypress->${this.controllerName}#changeToTimetable" placeholder="Horário" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2" data-${this.controllerName}-target="opportunityRow">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="guestForm">Token</label>
                            <input disabled class="form-control f-075 pt-0" data-${this.controllerName}-target="opportunityInput" type="text" placeholder="Token" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row my-2">
                      <div class="col-4">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tipo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="midiaDropdown">
                                <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="midiaDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="midiaInput"></span></button>
                                <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="midiaList" data-app--helpers--search-target="searchList">
                                  <li data-midia="chat" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chat</li>
                                  <li data-midia="call" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Ligação</li>
                                  <li data-midia="video" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Vídeo</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Link Vídeo</label>
                            <input class="form-control f-075 pt-0" data-${this.controllerName}-target="midiaLinkInput" type="text" placeholder="Link Vídeo" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      var date = new Date()
      // controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.dateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

      // controller.getControllerByIdentifier("app--helpers--pickdate").pickUntilDate($(controller.dateInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)    
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(controller.dateInputTarget), { months: true, years: true, kind: false })    

      if (controller.scheduleId) {
        controller.application.schedules.forEach(element => {
          if (element.id == controller.scheduleId) {
            controller.current_schedule = element
          }
        })
      }

      if (controller.actionMode == `edit` || controller.actionMode == `done` || controller.actionMode == `canceled`) {

        controller.formTitleTarget.innerText = `Editando Agendamento`
        controller.guestInputTarget.value = controller.current_schedule.guest_name
        controller.accountInputTarget.value = controller.current_schedule.account_name
        controller.accountInputTarget.dataset.filter = controller.current_schedule.account_id
        controller.accountInputTarget.dataset.text = controller.current_schedule.account_name

        controller.dateInputTarget.value = controller.current_schedule.schedule_at_pretty
        controller.timetableInputTarget.value = controller.current_schedule.timetable
        
        controller.statusDropdownTarget.value = controller.current_schedule.status
        controller.statusInputTarget.dataset.status = controller.current_schedule.status
        controller.statusInputTarget.innerText = controller.current_schedule.status_pretty
        
        if (controller.current_schedule.midia) {
          controller.midiaDropdownTarget.value = controller.current_schedule.midia
          controller.midiaInputTarget.dataset.midia = controller.current_schedule.midia
          controller.midiaInputTarget.innerText = controller.current_schedule.midia_pretty
          controller.midiaLinkInputTarget.value = controller.current_schedule.midia_link
        }

        controller.statusDropdownBtnTarget.disabled = true
        controller.dateInputTarget.disabled = true
      } else if (controller.actionMode == `block`) {

        controller.guestDivTarget.classList.add("d-none")

        controller.accountInputTarget.value = controller.application.current_user.name
        controller.accountInputTarget.dataset.filter = controller.application.current_user.id
        controller.accountInputTarget.dataset.text = controller.application.current_user.name
        controller.statusDropdownTarget.value = `blocked`
        controller.statusInputTarget.dataset.status = `blocked`
        controller.statusInputTarget.innerText = `Bloqueado`
        controller.statusDropdownBtnTarget.disabled = true
      } else if (controller.actionMode == `new` || controller.actionMode == `postponed`) {
        controller.formTitleTarget.innerText = `Novo Agendamento`

        controller.accountInputTarget.value = controller.accountName
        controller.accountInputTarget.dataset.filter = controller.accountId
        controller.accountInputTarget.dataset.text = controller.accountName
        controller.statusDropdownTarget.value = `booked`
        controller.statusInputTarget.dataset.status = `booked`
        controller.statusInputTarget.innerText = `Agendado`
        controller.statusDropdownBtnTarget.disabled = true
      }

      if (controller.actionMode == `done`) {
        controller.formTitleTarget.innerText = `Agendamento Realizado`
        controller.statusDropdownTarget.value = `done`
        controller.statusInputTarget.dataset.status = `done`
        controller.statusInputTarget.innerText = `Realizada`
        controller.statusDropdownBtnTarget.disabled = true
        controller.timetableInputTarget.disabled = true
        controller.dateInputTarget.disabled = true
        controller.accountInputTarget.disabled = true
      } else if (controller.actionMode == `postponed`) {
        controller.formTitleTarget.innerText = `Reagendamento`
        // controller.statusDropdownTarget.value = `postponed`
        // controller.statusInputTarget.dataset.status = `postponed`
        // controller.statusInputTarget.innerText = `Reagendada`
        controller.statusDropdownBtnTarget.disabled = true
        controller.timetableInputTarget.disabled = false
        controller.dateInputTarget.disabled = false
        controller.accountInputTarget.disabled = true
      } else if (controller.actionMode == `canceled`) {
        controller.formTitleTarget.innerText = `Cancelamento de Agendamento`
        controller.statusDropdownTarget.value = `canceled`
        controller.statusInputTarget.dataset.status = `canceled`
        controller.statusInputTarget.innerText = `Cancelada`
        controller.statusDropdownBtnTarget.disabled = true
        controller.timetableInputTarget.disabled = true
        controller.dateInputTarget.disabled = true
        controller.accountInputTarget.disabled = true
      }

      if (controller.current_schedule && controller.current_schedule.guest_id && controller.current_schedule.guest_name) {
        controller.guestInputTarget.value = controller.current_schedule.guest_name
        controller.guestInputTarget.dataset.filter = controller.current_schedule.guest_id
        controller.guestInputTarget.dataset.text = controller.current_schedule.guest_name        
      } else if (controller.guestId && controller.guestName) {
        controller.guestInputTarget.value = controller.guestName
        controller.guestInputTarget.dataset.filter = controller.guestId
        controller.guestInputTarget.dataset.text = controller.guestName
      }

      controller.listAccount()
      controller.listGuest()
      controller.setTokenInput()
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setTokenInput() {
    if (this.controllerForm.identifier == `commercial--sales--opportunities--entities--schedules`) {
      this.opportunityInputTarget.value = this.application.opportunity.token
    } else if (this.controllerForm.identifier == `users--works--recruitments--schedules--dashboard`) {
      this.opportunityInputTarget.value = this.application.vacancy.token
    }
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  listAccount() {
    var html = ``
    this.accountList.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectAccount" data-${this.controllerName}-target="accountFilterItem" data-text="${this.getControllerByIdentifier("app--visits--users").getNickname(element)}" data-filter="${element.id}" class="li-selector dark f-065">${this.getControllerByIdentifier("app--visits--users").getNickname(element)}</li>`
    })

    this.accountFilterTarget.innerHTML = html
  }

  accountFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectAccount(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterAccount(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listGuest() {
    var html = ``
    this.guestList.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectGuest" data-${this.controllerName}-target="guestFilterItem" data-text="${element.name}" data-filter="${element.id}" class="li-selector dark f-065">${element.name}</li>`
    })

    this.guestFilterTarget.innerHTML = html
  }

  guestFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectGuest(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterGuest(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  listSlots() {
    var html = ``
    this.application.slots.forEach(slot => {
      html += `<li data-id="${slot.id}" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">${slot.timetable}</li>`
    })

    this.slotListTarget.innerHTML = html
    this.slotDropdownTarget.value = ``
    this.slotInputTarget.dataset.id = ``
    this.slotInputTarget.innerText = ``
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }


  changeToTimetable(ev) {
    this.getControllerByIdentifier(`app--helpers--numbers`).changeNumberToTimetable(ev)
  }

  pickOnlyFuture3(element) {

    var controller = this

    element.pickdate({
      today: 'Hoje',
      ok: 'Selecionar',
      cancel: 'Fechar',
      closeOnCancel: true,
      closeOnSelect: true,
      container: 'body',
      containerHidden: 'body',
      firstDay: 0,
      format: 'dd/mmm/yyyy',
      formatSubmit: 'yyyy-mm-dd',
      hiddenPrefix: 'pickdate-input',
      labelMonthNext: 'Próximo Mês',
      labelMonthPrev: 'Mês Anterior',
      labelMonthSelect: 'Escolha um mês do menu',
      labelYearSelect: 'Escolha um ano do menu',
      monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      weekdaysFull: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      showMonthsFull: true,
      showMonthsShort: true,
      showWeekdaysFull: true,
      showWeekdaysShort: true,
      selectMonths: true,
      selectYears: true,
      min: true,
      disable: [1,
        7,
        new Date(2022, 5, 3),
        new Date(2022, 7, 15),
        new Date(2022, 8, 7),
        new Date(2022, 9, 12),
        new Date(2022, 10, 2),
        new Date(2022, 10, 15),
        new Date(2022, 11, 8),
        new Date(2022, 11, 24),
        new Date(2022, 11, 25),
        new Date(2022, 11, 31),
        new Date(2023, 0, 1),],
      onClose: function (e) {
        // funcao para guardar a data selecionada pelo cliente
        controller.changeDate();
      },
    });

    element.on('mousedown', function (e) {
      e.preventDefault();
      $(this).dblclick()
    });
  }

  changeDate() {
    this.send_slot_data = { current_user: {}, slot: {} }

    var selectedDate = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value)
    selectedDate = new Date(selectedDate)

    this.selectedDate = { date: this.dateInputTarget.value, day: this.getControllerByIdentifier("app--helpers--date").getWeekDay(selectedDate.getDay()) }
    this.send_slot_data.slot.date = selectedDate
    this.send_slot_data.slot.account_id = this.accountInputTarget.dataset.filter
    this.send_slot_data.current_user.current_user_id = this.application.current_user.id

    if (selectedDate != `Invalid Date`) {
      var url = "/users/works/schedules/slots/list_by_account"
      var method = "POST"
      const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_slot_data) }
      var controller = this
      fetch(url, init)
        .then(response => response.json())
        .then(response => {
          controller.application.slots = response.data.cln
          controller.listSlots()
        })
        .catch(error => {
          controller.getControllerByIdentifier("app--helpers--console").console(error)
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
        })
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Selecione uma data", 4000)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.accountInputTarget.value == ``) {
        len += 1
      }

      if (controller.dateInputTarget.value == ``) {
        len += 1
      }

      if (controller.timetableInputTarget.value == ``) {
        len += 1
      }

      if (controller.midiaInputTarget.innerText == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
