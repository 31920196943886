import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "mainDashboard", "infoView", "infoCard", "partnerView", "partnerCard",
                    "regimeView", "regimeCard", "actionView", "actionCard", "checkCanInvoice", "checkListPartner",
                    "checkListDescriptionPartner", "checkListTaker", "checkListDescriptionTaker", "checkListRegime",
                    "checkListDescriptionRegime", "checkListActivity", "checkListDescriptionActivity", "checkListBank",
                    "checkListDescriptionBank", "invoiceBtn", "checkListCertificate", "checkListDescriptionCertificate",
                    "checkListIntegration", "checkListDescriptionIntegration", "checkListIncorporation", "checkListDescriptionIncorporation",
                    "link", "linkEdit", "linkInput", "invoiceBtnText"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--show--check-invoice`
    this.spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.permission = `medclinic`
  }

  doCheckCard() {
    if (this.application.check_invoice) {
      if (this.application.check_invoice.final_receivable_check == false) {
        this.checkInvoice()
      } else {
        this.doCheckCardHtml()
      }
    } else {
      this.checkInvoice()
    }
  }

  doCheckCardHtml() {
    if (this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--managements--show--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--managements--show--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--managements--show--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`)
      this.receivableController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)
      this.receivableController = this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`)
      this.receivableController = this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`)
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).hasMainDashboardTarget) {
      this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)
      this.receivableController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`)
    }

    var actionHtml = ``
    var emitReceivableBtn = `<div class="row mb-3 w-100">
                              <div class="col-12 px-0">
                                <div class="card">
                                  <div class="card-body py-0 px-2 f-bold bg-primary" data-action="click->${this.controllerName}#emitReceivable" data-${this.controllerName}-target="invoiceBtn">
                                    <div class="row my-2">
                                      <div class="col-12 px-0 text-center" data-${this.controllerName}-target="invoiceBtnText">
                                        Emitir Recibo
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>`

    var regularizationBtn = `<div class="row mb-3 w-100">
                              <div class="col-12 px-0 pointer">
                                <div class="card">
                                  <div class="card-body py-0 px-2 f-bold bg-primary" data-action="click->${this.controllerName}#goToRegularization">
                                    <div class="row my-2">
                                      <div class="col-12 px-0 text-center">
                                        Regularização
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>`

    var receivablesBtn = `<div class="row mb-3 w-100">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-body py-0 px-2 f-bold bg-primary pointer" data-action="click->${this.controllerName}#goToInvoice" data-${this.controllerName}-target="apurationBtn">
                                <div class="row my-2">
                                  <div class="col-12 px-0 text-center">
                                    NF e Apuração
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`

    var clientBtn = `<div class="row mb-3 w-100">
                          <div class="col-12 px-0">
                            <div class="card">
                              <div class="card-body py-0 px-2 f-bold bg-primary pointer" data-action="click->${this.controllerName}#goToClients" data-${this.controllerName}-target="clientBtn">
                                <div class="row my-2">
                                  <div class="col-12 px-0 text-center">
                                    Visão do Cliente
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`

    if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`) || (this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget)) {
      var clinicHtml = ``
    } else {
      if (this.application.clinic.link) {
        var clinicHtml = `<div class="row w-100 d-flex align-items-center">
                          <div class="col-12 px-2 d-flex">
                            <span class="f-075 text-bold">Google Drive</span>
                            <button data-action="click->${this.controllerName}#goToURL" data-url="${this.application.clinic.link}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                              <span class="material-icons md-sm md-dark">add_to_drive</span>
                              <span class="mc-tooltiptext">Ver Google Drive</span>
                            </button>
                            <button data-action="click->${this.controllerName}#editLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">edit</span>
                              <span class="mc-tooltiptext">Editar Link</span>
                            </button>
                          </div>
                          <div class="col-12 px-2 d-flex align-items-center d-none" data-${this.controllerName}-target="linkEdit">
                            <div class="form-group w-75 mb-0">
                              <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="linkInput" type="text" placeholder="Link Google Drive" required>
                            </div>
                            <div class="ml-auto mb-0">
                              <button data-action="click->${this.controllerName}#saveLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                                <span class="material-icons md-sm md-dark">save</span>
                                <span class="mc-tooltiptext">Salvar Link</span>
                              </button>
                              <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                                <span class="material-icons md-sm md-dark">clear</span>
                                <span class="mc-tooltiptext">Cancelar</span>
                              </button>
                            </div>
                          </div>
                        </div>`
      } else {
        var clinicHtml = `<div class="row w-100 d-flex align-items-center">
                    <div class="col-12 px-2 d-flex">
                      <span class="f-075 text-bold">Google Drive</span>
                      <button data-action="click->${this.controllerName}#editLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-auto">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar Link</span>
                      </button>
                    </div>
                    <div class="col-12 px-2 my-3 d-flex align-items-center d-none" data-${this.controllerName}-target="linkEdit">
                      <div class="form-group w-75 mb-0">
                        <input class="form-control f-075 pt-0" autofocus data-${this.controllerName}-target="linkInput" type="text" placeholder="Link Contrato" required>
                      </div>
                      <div class="ml-auto mb-0 d-flex align-items-center">
                        <button data-action="click->${this.controllerName}#saveLink" type="button" class="btn btn-sm btn-table p-0 mc-tooltip ml-2">
                          <span class="material-icons md-sm md-dark">save</span>
                          <span class="mc-tooltiptext">Salvar Link</span>
                        </button>
                        <button data-${this.controllerName}-target="cancelBtn" data-action="click->${this.controllerName}#hideEditor" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">clear</span>
                          <span class="mc-tooltiptext">Cancelar</span>
                        </button>
                      </div>
                    </div>
                  </div>`
      }
    }

    if (this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`)) {
      actionHtml += receivablesBtn
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget) {
      actionHtml += emitReceivableBtn
    } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`)) {
      actionHtml += `${emitReceivableBtn}
                    ${regularizationBtn}
                    ${clientBtn}`
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)) {
      actionHtml += emitReceivableBtn

      if (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`) {
        if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).hasMainDashboardTarget) {
          actionHtml += ``
        } else {
          actionHtml += `${receivablesBtn}
                         ${regularizationBtn}`
        }
      }
    }

    this.check_invoice = this.application.check_invoice

    if (this.check_invoice.final_receivable_check) {
      var finalReceivableCheck = `<span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Liberado para Emitir</span></span>`
    } else {
      var finalReceivableCheck = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">Não está liberado para Emitir Recibo</span></span>`
    }

    if (this.check_invoice.final_invoice_check) {
      var finalInvoiceCheck = `<span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Liberado para Emitir</span></span>`
    } else {
      var finalInvoiceCheck = `<span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">Não está liberado para Emitir Nota Fiscal</span></span>`
    }

    // `<div class="row my-2">
    //   <div class="col-12 d-flex align-items-center my-1 px-0 f-065">
    //     <span><strong>Checklist para emissão de NF</strong></span>
    //   </div>
    // </div>`

    var regularizationCheck = 0
    if (this.check_invoice.partners_invoice_check) {
      var partnersCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Quadro Societário</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      regularizationCheck += 1
      var partnersCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Quadro Societário</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.partners_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.takers_invoice_check) {
      var takersCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Tomadores</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      regularizationCheck += 1
      var takersCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Tomadores</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.takers_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.regimes_invoice_check) {
      var regimesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Regime Tributário</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      var regimesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Regime Tributário</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.regimes_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.activities_invoice_check) {
      var activitiesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Atividades Econômicas</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      regularizationCheck += 1
      var activitiesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Atividades Econômicas</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.activities_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.banks_invoice_check) {
      var banksCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Conta Bancária</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      regularizationCheck += 1
      var banksCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Conta Bancária</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.banks_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.integrations_invoice_check) {
      var integrationsCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Integração Emissão NF Online</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      var integrationsCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Integração Emissão NF Online</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.integrations_message}</span></span>
                                </div>
                              </div>`
    }

    if (this.check_invoice.certificates_invoice_check) {
      var certificatesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Certificado Digital</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-success help">done</span><span class="mc-tooltiptext">Ok</span></span>
                                </div>
                              </div>`
    } else {
      var certificatesCheckHtml = `<div class="row my-2">
                                <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                                  <span>Certificado Digital</span>
                                </div>
                                <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                                  <span class="mc-tooltip"><span class="material-icons md-sm md-danger help">clear</span><span class="mc-tooltiptext">${this.check_invoice.certificates_message}</span></span>
                                </div>
                              </div>`
    }

    var html = ``
    if ((this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--dashboard`).hasMainDashboardTarget)) {
      html += `${actionHtml}`
    } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)) {
      html += `${actionHtml}
                <div class="row my-2">
                  <div class="col-12 d-flex align-items-center my-1 px-0 f-065">
                    <span><strong>Checklist</strong></span>
                  </div>
                </div>
                ${partnersCheckHtml}
                ${takersCheckHtml}
                ${activitiesCheckHtml}
                ${banksCheckHtml}
                ${regimesCheckHtml}`
    } else if ((this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`) && this.getControllerByIdentifier(`operations--products--clinics--financials--receivables--dashboard`).hasMainDashboardTarget)) {
      html += `${actionHtml}`
    } else {
      html += `${actionHtml}
                ${clinicHtml}
                <div class="row my-2"></div>
                <div class="row mb-3 w-100">
                  <div class="col-12 px-0 mt-3" data-${this.controllerName}-target="checkCanInvoice">
                    <div class="row my-2">
                      <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                        <span><strong>Emissão de Recibo</strong></span>
                      </div>
                      <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                        ${finalReceivableCheck}
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-10 d-flex align-items-center my-1 px-0 f-065">
                        <span><strong>Emissão de Nota Fiscal</strong></span>
                      </div>
                      <div class="col-2 d-flex align-items-center my-1 px-0 f-065">
                        ${finalInvoiceCheck}
                      </div>
                    </div>
                    <div class="row my-3"></div>
                    <div class="row my-2">
                      <div class="col-12 d-flex align-items-center my-1 px-0 f-065">
                        <span><strong>Checklist</strong></span>
                      </div>
                    </div>
                    ${partnersCheckHtml}
                    ${takersCheckHtml}
                    ${activitiesCheckHtml}
                    ${banksCheckHtml}
                    ${regimesCheckHtml}
                    ${integrationsCheckHtml}
                    ${certificatesCheckHtml}
                  </div>
                </div>`
    }

    // this.dashboardController.actionViewTarget.innerHTML = html
    this.application.canInvoice = this.check_invoice.final_invoice_check
    if (this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`) && regularizationCheck == 0) {
      this.application.canReceivable = true
    } else {
      this.application.canReceivable = this.check_invoice.final_receivable_check
    }

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.actionViewTarget.innerHTML = html)
    }).then(() => {
      if (controller.hasInvoiceBtnTarget) {
        if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)) {
          controller.invoiceBtnTextTarget.innerText = `Emitir Nota Fiscal`
        } else if (this.getControllerByIdentifier(`operations--products--clinics--clients--show--dashboard`)) {
        }

        

        if (controller.application.canReceivable) {
          controller.invoiceBtnTarget.classList.remove("bg-disabled")
          controller.invoiceBtnTarget.classList.add("bg-primary")
          controller.invoiceBtnTarget.classList.add("pointer")
        } else {
          controller.invoiceBtnTarget.classList.add("bg-disabled")
          controller.invoiceBtnTarget.classList.remove("bg-primary")
          controller.invoiceBtnTarget.classList.remove("pointer")
        }
      }
    })
  }

  checkCanInvoice22() {

    if (this.application.partners.length == 0) {
      this.application.check_invoice.partners_invoice_check = false
      this.application.check_invoice.partners_message = `Favor adicionar os sócios da PJ Médica.`
    } else {
      var checkCouncils = true
      this.application.partners.forEach(partner => {
        if (partner.status && partner.doctor && partner.councils.length == 0) {
          checkCouncils = false
          this.application.check_invoice.partners_invoice_check = false
          this.application.check_invoice.partners_message = `Verificar se o CRM está cadastrado para todos os sócios.`
        }
      })

      if (checkCouncils) {
        this.application.check_invoice.takers_invoice_check = true
      }
    }

    if (this.application.takers.length == 0) {
      this.application.check_invoice.takers_invoice_check = false
      this.application.check_invoice.takers_message = `Favor adicionar o Tomador de Serviços da PJ Médica.`
    } else {
      var checkAddress = true
      this.application.takers.forEach(taker => {
        if (taker.status && taker.address_list.length == 0) {
          checkAddress = false
          this.application.check_invoice.takers_invoice_check = false
          this.application.check_invoice.takers_message = `Verificar se todos os Tomadores estão com endereços cadastrados.`
        }
      })

      if (checkAddress) {
        this.application.check_invoice.takers_invoice_check = true
      }
    }

    if (this.application.activities.length == 0) {
      this.application.check_invoice.activities_invoice_check = false
      this.application.check_invoice.activities_message = `Favor adicionar as Atividades Econômicas da PJ Médica.`
    } else {
      this.application.check_invoice.activities_invoice_check = true
    }

    if (this.application.banks.length == 0) {
      this.application.check_invoice.banks_invoice_check = false
      this.application.check_invoice.banks_message = `Favor adicionar a Conta Bancária da PJ Médica.`
    } else {
      this.application.check_invoice.banks_invoice_check = true
    }

    if (this.application.certificates.length == 0) {
      this.application.check_invoice.certificates_invoice_check = false
      this.application.check_invoice.certificates_message = `Favor adicionar o Certificado Digital da PJ Médica.`
    } else if (this.application.certificates[0].status == `overdue`) {
      this.application.check_invoice.certificates_invoice_check = false
      this.application.check_invoice.certificates_message = `O Certificado Digital está expirado.`
    } else {
      this.application.check_invoice.certificates_invoice_check = true
    }

    if (this.getControllerByIdentifier(`operations--products--clinics--financials--show--dashboard`)) {

      if (this.application.regimes[0] == undefined) {
        this.application.check_invoice.regimes_invoice_check = false
        this.application.check_invoice.regimes_message = `Favor adicionar o Regime Tributário de cada competência aberta da PJ Médica.`
      } else if (this.application.regimes[0].iss_type == `aliquot` && this.application.regimes[0].iss_rate == 0) {
        this.application.check_invoice.regimes_invoice_check = false
        this.application.check_invoice.regimes_message = `Favor atualizar o Regime Tributário de cada competência aberta da PJ Médica.`
      } else if (this.application.regimes[0].iss_type == `fixed` && this.application.regimes[0].iss_amount == 0) {
        this.application.check_invoice.regimes_invoice_check = false
        this.application.check_invoice.regimes_message = `Favor atualizar o Regime Tributário de cada competência aberta da PJ Médica.`
      } else if (this.application.regimes[0].effective_total_tax == 0) {
        this.application.check_invoice.regimes_invoice_check = false
        this.application.check_invoice.regimes_message = `Favor atualizar o Regime Tributário de cada competência aberta da PJ Médica.`
      } else {
        this.application.check_invoice.regimes_invoice_check = true
      }

      if (this.application.integration_companies.length == 0) {
        len += 1
        checkIntegrations = false
        messages.integration = `Favor fazer a integração com o Emissor de NF Online.`
      }
    } else if (this.getControllerByIdentifier(`operations--products--clinics--regularizations--show--dashboard`)) {
      if (this.application.incorporations.length == 0) {
        // len += 1
        // checkIncorporation = false
        // messages.incorporation = `Favor adicionar o Contrato Social da PJ Médica.`
      }
    }

  }

  editLink() {
    this.linkEditTarget.classList.remove("d-none")
    this.linkInputTarget.value = this.application.clinic.link
  }

  hideEditor() {
    this.linkEditTarget.classList.add("d-none")
  }

  saveLink() {
    this.send_data = { current_user: {}, product: {} }

    this.send_data.product.id = this.application.clinic.id
    this.send_data.product.name = this.application.clinic.name
    this.send_data.product.link = this.linkInputTarget.value.trim()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    var url = "/operations/products/entities/update"
    var method = "PUT"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var clinic = response.data.cln
          controller.application.clinic = clinic
          controller.doCheckCard()
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  checkInvoice() {
    var data = { clinic: { clinic_id: this.application.clinic.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = `/operations/products/clinics/managements/verifications/check_can_invoice`
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.check_invoice = response.data.cln
          controller.doCheckCardHtml()
        }

        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 5000)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--pre-modal`).closeProcessModal()
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  goToInvoice() {
    if (this.application.canReceivable) {
      var url = this.application.clinic.invoice_path
      window.open(url, `_self`)
    } else {
      alert(`Falta finalizar as parametrizações básicas para acessar a funcionalidade de NFs e Apuração.`)
    }
  }

  goToClients() {
    if (this.application.canReceivable) {
      var url = this.application.clinic.client_path
      window.open(url, `_self`)
    }
  }

  goToPartners() {
    if (this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard")) {
      this.getControllerByIdentifier("operations--products--clinics--regularizations--partners--dashboard").doPartnersDashboard()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor pedir para o Time de Regularização atualizar as Informações.`, 2000)
    }
  }

  goToTakers() {
    if (this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard")) {
      this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard").doTakersDashboard()
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor pedir para o Time de Regularização atualizar as Informações.`, 2000)
    }
  }

  // goToTakers() {
  //   this.getControllerByIdentifier("operations--products--clinics--regularizations--takers--dashboard").doTakersDashboard()
  // }

  goToActivities() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--activities--dashboard").doActivitiesDashboard()
  }

  goToBanks() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--banks--dashboard").doBanksDashboard()
  }

  goToCertificates() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--certificates--dashboard").doCertificatesDashboard()
  }

  goToRegimes() {
    this.getControllerByIdentifier("operations--products--clinics--financials--regimes--dashboard").doRegimesDashboard()
  }

  goToIntegrations() {
    this.getControllerByIdentifier("operations--products--clinics--financials--integrations--dashboard").doIntegrationsDashboard()
  }

  goToReceivables() {
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard").doReceivablesDashboard()
  }

  goToIncorporations() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--incorporations--dashboard").doIncorporationsDashboard()
  }

  emitReceivable() {
    if (this.application.current_date.open == false) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para emitir um novo Recibo/Nota Fiscal.`, 5000)
      alert(`Competência está fechada. Favor abrí-la para emitir um novo Recibo/Nota Fiscal.`)
    } else {
      if (this.application.canReceivable) {
        this.receivableController.emitReceivable()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A PJ não está regularizada para emissão de Nota Fiscal`, 4000)
      }
    }
  }

  goToRegularization() {
    var url = this.application.clinic.product_path
    window.open(url, `_self`)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}