import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
  }

  updateTasks() {
    var data = { current_user: { current_user_id: currentUser.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/users/tasks/settings/update_all_tasks"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          processingSnackbar(response.type, response.message, device, 2000)
        } else {
          processingSnackbar(response.type, response.message, device, 2000)
        }
      })
  }


}
