import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenu", "content", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "sideBarReport"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--products--clinics--regularizations--index--settings`
    this.itemsOnPage = 250
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `company_name` }
    this.tableCols = 8
  }

  doSettingsDashboard() {
    var html = `<div class="row">
                  <div class="col-2 px-0" data-${this.controllerName}-target="submenu"></div>
                  <div class="col-10 px-0" data-${this.controllerName}-target="content" data-controller="operations--products--clinics--regularizations--cnaes--dashboard"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--dashboard`).permission = `medclinic_regularization_cnaes`
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--dashboard`).gridElement = controller.contentTarget
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--dashboard`).doCnaesDashboard()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--clinics--regularizations--index--list"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
