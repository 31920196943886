import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "conciliationCheck",
                    "deleteBillingBtn", "returnBillingBtn", "partiallyRefundSet"]

  connect() {
    this.controllerName = `financials--books--receivables--conciliations--modal--billing`
    this.controllerMain = `financials--books--receivables--conciliations--modal--main`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.spaceRow = `<td colspan="10" style="height:2rem;padding-top:1rem;padding-bottom:1rem;" scope="col"></td>`
    this.billing_item_ids = []
  }


  doBillingHtml() {

    if (this.current_billing.payment_method == `credit_card`) {
      if (this.current_billing.billing_dates[0].executed_at_pretty) {
        var executedDate = `<tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Dt Liberação</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                ${this.current_billing.billing_dates[0].executed_at_pretty}
                              </td>
                            </tr>`
      } else {
        var executedDate = ``
      }

      var returnDate = `<tr>
                          <th colspan="2" class="f-065 align-middle px-0">
                            <strong>Dt Prevista Liberação</strong>
                          </th>
                          <td colspan="3" class="f-065 align-middle px-0 text-left">
                            ${this.current_billing.billing_dates[0].return_at_pretty}
                          </td>
                        </tr>`
    } else {
      var returnDate = ``
      var executedDate = ``
    }

    if (this.current_billing.late_payment) {
      var latePayment = `<tr>
                          <th colspan="2" class="f-065 align-middle px-0">
                            <strong>Pagamento Atrasado</strong>
                          </th>
                          <td colspan="3" class="f-065 align-middle px-0 text-left">
                            ${this.current_billing.late_payment_days} dia(s)
                          </td>
                        </tr>`
    } else {
      var latePayment = ``
    }

    if (this.current_billing.status == `partially_refunded`) {
      var partiallyRefund = `<tr>
                              <th colspan="2" class="f-065 align-middle px-0">
                                <strong>Configurar Reembolso</strong>
                              </th>
                              <td colspan="3" class="f-065 align-middle px-0 text-left">
                                <div class="custom-control custom-checkbox px-2 w-50 mt-2">
                                  <input type="checkbox" class="custom-control-input" id="partiallyRefundSet" data-${this.controllerName}-target="partiallyRefundSet" data-action="click->${this.controllerName}#setPartiallyRefund">
                                  <label class="custom-control-label f-065" for="partiallyRefundSet"></label>
                                </div>
                              </td>
                            </tr>`
    } else {
      var partiallyRefund = ``
    }

    var html = `<table class="table table-sm table-borderless" style="font-size:80%;">
                  <tbody>
                    <tr>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                      <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-20 align-middle"></td>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Iugu ID Fatura</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        <small class="mb-0 f-065 mc-tooltip grab">
                          <span data-controller="app--helpers--copy" data-copy="${this.current_billing.invoice_id_iugu}" data-action="click->app--helpers--copy#copy">${this.current_billing.invoice_id_iugu}</span>
                          <span class="mc-tooltiptext">Clique para Copiar</span>
                        </small> <br>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Valor Total</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.controllerNumber.currencyOptionMask(this.current_billing.total) } <br>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Dt Vencimento</strong>
                      </th>
                      <td colspan="1" class="f-065 align-middle px-0 text-left">
                        <span class="mc-tooltip help">
                          <span><strong><u>${this.current_billing.due_at_iugu_pretty}</u></strong></span>
                          <span class="mc-tooltiptext">${this.current_billing.created_at_iugu_full_pretty}</span>
                        </span>
                      </td>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong><u>Comp.: ${this.current_billing.due_at_accrual}</u></strong>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Dt Pagamento</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        <span class="mc-tooltip help"> 
                          <span>${this.current_billing.occurrence_at_iugu_pretty}</span>
                          <span class="mc-tooltiptext">${this.current_billing.occurrence_at_iugu_full_pretty}</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Dt Compensação</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        <span class="mc-tooltip help"> 
                          <span>${this.current_billing.paid_at_iugu_pretty}</span>
                          <span class="mc-tooltiptext">${this.current_billing.paid_at_iugu_full_pretty}</span>
                        </span>
                      </td>
                    </tr>
                    ${latePayment}
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Forma de Pagamento</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.current_billing.method_pretty}
                      </td>
                    </tr>
                    ${returnDate}
                    ${executedDate}
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Valor Taxas</strong>
                      </th>
                      <td colspan="3" class="f-065 align-middle px-0 text-left">
                        ${this.controllerNumber.currencyOptionMask(this.current_billing.fee_paid) } <br>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2" class="f-065 align-middle px-0">
                        <strong>Fatura</strong> <br>
                      </th>
                      <td colspan="1" class="f-065 align-middle px-0 text-left">
                        <button data-action="click->${this.controllerName}#goTo" data-url="${this.current_billing.secure_url_iugu}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">launch</span>
                          <span class="mc-tooltiptext">Ver Fatura</span>
                        </button>
                      </td>
                      <td colspan="1" class="f-065 align-middle px-0 text-left">
                        <button data-action="click->${this.controllerName}#returnBillingEv" data-id="${this.current_billing.id}" data-${this.controllerName}-target="returnBillingBtn" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">keyboard_return</span>
                          <span class="mc-tooltiptext">Liquidar Fatura</span>
                        </button>
                      </td>
                      <td colspan="1" class="f-065 align-middle px-0 text-left">
                        <button data-action="click->${this.controllerName}#deleteBillingEv" data-id="${this.current_billing.id}" data-${this.controllerName}-target="deleteBillingBtn" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                          <span class="material-icons md-sm md-dark">delete</span>
                          <span class="mc-tooltiptext">Apagar Fatura</span>
                        </button>
                      </td>
                    </tr>
                    ${partiallyRefund}

                    
                  </tbody>
                </table>
                <table class="table table-sm table-hover" style="font-size:80%;">
                  <thead>
                    <tr>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle"></th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-60 align-middle">Descrição</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-25 align-middle text-right">Valor</th>
                      <th style="font-size:80%;" scope="col" class="pointer p-1 table-10 align-middle">Status</th>
                    </tr>
                  </thead>
                  <tbody data-${this.controllerName}-target="bodyTable">
                  </tbody>
                </table>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()

      if (!controller.application.permissions[controller.billing_permission].can_delete) {
        controller.deleteBillingBtnTarget.remove()
      }

      if (controller.application.permissions[controller.billing_date_permission].can_update) {
        if (controller.current_billing.billing_dates[0].executed || !controller.current_billing.conciliated) {
          controller.returnBillingBtnTarget.remove()
        }
      } else {
        controller.returnBillingBtnTarget.remove()
      }
    })
  }

  doDataTable() {
    var billingItems = this.current_billing.items

    this.listData(billingItems)
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="8" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Itens na Fatura</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.billingTablePartial(element, data.length))
      });

      if (this.current_billing.conciliated) {
        var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-success default">done</span>
                      <span class="mc-tooltiptext">Item Conciliado</span>
                    </span>`
      } else {
        var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-danger default">clear</span>
                      <span class="mc-tooltiptext">Item não Conciliado</span>
                    </span>`
      }

      var rowHtml = `<tr>
                      ${this.spaceRow}
                    </tr>
                    <tr>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle" colspan="2"><strong>Valor Pago Total</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-right"><strong>${this.controllerNumber.currencyOptionMask(this.current_billing.total_paid)}</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    </tr>
                    <tr>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle" colspan="2"><strong>Valor Conciliado</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-right"><strong>${this.controllerNumber.currencyOptionMask(this.current_billing.total_conciliated)}</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    </tr>
                    <tr>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle" colspan="2"><strong>Valor à Conciliar</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle text-right"><strong>${this.controllerNumber.currencyOptionMask(Number(this.current_billing.total_paid) - Number(this.current_billing.total_conciliated))}</strong></td>
                      <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    </tr>`

      this.bodyTableTarget.insertAdjacentHTML("beforeend", rowHtml)
    }

    this.current_billing.items.forEach(element => {
      if (this.application.current_user.super_admin) {
        if (element.conciliated && this.current_billing.conciliated) {
          this.nameTarget(`checkBilling-${element.id}`).disabled = true
          this.nameTarget(`checkBilling-${element.id}`).checked = true
        }
      } else {
        if (element.conciliated) {
          this.nameTarget(`checkBilling-${element.id}`).disabled = true
          this.nameTarget(`checkBilling-${element.id}`).checked = true
        }
      }
    })

    this.setRefund = false

  }

  billingTablePartial(element, length) {

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}">`
    }

    if (element.conciliated) {
      var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-success default">done</span>
                      <span class="mc-tooltiptext">Item Conciliado</span>
                    </span>`
    } else {
      // if (condition) {
      //   var status = `<span class="mc-tooltip">
      //                   <span class="material-icons md-250 md-success pointer">replay</span>
      //                   <span class="mc-tooltiptext">Reembolsado</span>
      //                 </span>`
      // } else {
      //   var status = `<span class="mc-tooltip"> 
      //                 <span class="material-icons md-250 md-danger default">clear</span>
      //                 <span class="mc-tooltiptext">Item não Conciliado</span>
      //               </span>`  
      // }
      var status = `<span class="mc-tooltip"> 
                      <span class="material-icons md-250 md-danger default">clear</span>
                      <span class="mc-tooltiptext">Item não Conciliado</span>
                    </span>`
    }

    if (this.setRefund) {
      var refund = `<td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <span class="mc-tooltip"> 
                        <span class="material-icons md-250 md-success pointer" data-id="${element.id}" data-action="click->${this.controllerName}#updateRefundItemEv">replay</span>
                        <span class="mc-tooltiptext">Marcar Reembolso</span>
                      </span>
                    </td>`
    } else {
      var refund = `<td style="font-size:80%;" scope="col" class="p-1 align-middle"></td>`
    }

    if (this.application.current_user.super_admin) {
      if (element.conciliated && this.current_billing.conciliated) {
        var billlingConciliated = true
      } else {
        var billlingConciliated = false
      }
    } else {
      var billlingConciliated = element.conciliated
    }

    

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                      <div class="custom-control custom-checkbox px-2 w-50 mt-3">
                        <input disabled type="checkbox" class="custom-control-input" id="checkBilling-${element.id}" data-${this.controllerName}-target="checkBilling-${element.id} conciliationCheck" data-amount="${element.total_amount}" data-id="${element.id}" data-conciliated="${billlingConciliated}" data-item-conciliated="${element.conciliated}" data-action="click->${this.controllerName}#conciliateCheck ${this.controllerName}#addonCheck">
                        <label class="custom-control-label f-065" for="checkBilling-${element.id}"></label>
                      </div>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.description}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-right">${this.controllerNumber.currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${status}</td>
                    ${refund}
                  </tr>`

    return rowHtml
  }

  deleteBillingEv(ev) {
    var billingId = ev.currentTarget.dataset.id

    var r = confirm(`Tem certeza que deseja Apagar a Fatura?`)
    if (r) { 
      this.deleteBilling(billingId)
    }
  }

  deleteBilling(billingId) {
    this.actionMode = `delete`
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { billing: { id: billingId }, current_user: { current_user_id: this.application.current_user.id, feature: this.billing_permission } }
    const url = "/financials/integrations/bankings/iugu/billings/destroy"
    const init = { method: "DELETE", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

    this.requestFetch(url, init)
  }

  returnBillingEv(ev) {
    var billingId = ev.currentTarget.dataset.id

    if (this.current_billing.billing_dates[0].executed) {
      alert(`Fatura já foi Liquidada!`)
    } else if (this.current_billing.conciliated == false) {
      alert(`Favor Conciliar a Fatura primeiro, antes de liquidá-la!`)
    } else {
      var r = confirm(`Tem certeza que deseja Liquidar a Fatura?`)
      if (r) {
        this.returnBilling(billingId)
      }
    }
  }

  returnBilling(billingId) {
    this.actionMode = `liquidation`
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    const data = { billing: { id: billingId }, current_user: { current_user_id: this.application.current_user.id, feature: this.billing_date_permission } }
    const url = "/financials/integrations/bankings/iugu/billing_dates/liquidation"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
  
    this.requestFetch(url, init)
  }

  requestFetch(url, init) {
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process || response.save) {
          var billing = response.data.cln

          if (controller.actionMode == `delete`) {
            controller.application.billings.forEach(element => {
              if (element.id == billing.id) {
                controller.application.billings.splice(controller.application.billings.indexOf(element), 1)
              }
            })
          } else if (controller.actionMode == `liquidation`) {
            controller.application.billings.forEach(element => {
              if (element.id == billing.id) {
                controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
              }
            })
          }

          if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`) && controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).hasMainCardTarget) {
            var controllerBillings = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)

            if (controller.actionMode == `delete`) {
              controllerBillings.iugu_billings.forEach(element => {
                if (element.id == billing.id) {
                  controllerBillings.iugu_billings.splice(controllerBillings.iugu_billings.indexOf(element), 1)
                }
              })
            } else if (controller.actionMode == `liquidation`) {
              controllerBillings.iugu_billings.forEach(element => {
                if (element.id == billing.id) {
                  controllerBillings.iugu_billings.splice(controllerBillings.iugu_billings.indexOf(element), 1, billing)
                }
              })
            }

            controllerBillings.doDataTable()
          } else if (controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`) && controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`).hasMainCardTarget) {
            controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`).doDataTable()
          } else if (controller.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`)) {

            controller.application.billing_dates.forEach(billing_date => {
              if (billing_date.billing_id == billing.id) {
                if (controller.actionMode == `delete`) {
                  controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(billing_date), 1)
                } else if (controller.actionMode == `liquidation`) {
                  controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(billing_date), 1, billing.billing_dates[0])
                }
              }
            })

            controller.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).doDataTable()
          }

        }
        
        controller.getControllerByIdentifier(controller.controllerMain).close()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  enableItemsCheck() {
    this.conciliationCheckTargets.forEach(element => {
      if (this.getControllerByIdentifier(`app--helpers--boolean`).transformStringToBoolean(element.dataset.conciliated) == false) {
        element.disabled = false
      }
    })
  }

  disableItemsCheck() {
    this.conciliationCheckTargets.forEach(element => {
      if (this.getControllerByIdentifier(`app--helpers--boolean`).transformStringToBoolean(element.dataset.conciliated) == false) {
        element.disabled = true
        element.checked = false
      }
    })
  }

  conciliateCheck(ev) {
    if (this.checkMode == `conciliation`) {
      this.billingItemTarget = ev.currentTarget

      if (this.application.current_user.super_admin) {
        
      } else {
        if (this.conciliationCheckTargets.length > 1) {
          if (this.getControllerByIdentifier(`app--helpers--boolean`).transformStringToBoolean(this.billingItemTarget.dataset.itemConciliated)) {
            alert(`O Item da Fatura já está conciliado`)
            this.billingItemTarget.checked = false
          }
        }
      }
      
      if (this.billingItemTarget.checked) {
        this.billing_item_ids[this.billing_item_ids.length] = this.billingItemTarget.dataset.id
      } else {
        this.billing_item_ids.forEach((element, i) => {
          if (element == this.billingItemTarget.dataset.id) {
            this.billing_item_ids.splice(this.billing_item_ids.indexOf(element), 1)
          }
        })
      }
    }
  }

  addonCheck(ev) {
    if (this.checkMode == `addon`) {
      this.billingItemTarget = ev.currentTarget

      this.current_billing.items.forEach(element => {
        if (element.id == Number(this.billingItemTarget.dataset.id)) {

          var amount = Math.abs(element.total_amount)

          this.getControllerByIdentifier("financials--books--clients--addons--save").amountInputTarget.value = this.controllerNumber.currencyOptionMask(amount)
          this.getControllerByIdentifier("financials--books--clients--addons--save").notesInputTarget.value = element.description
          
        }
      })

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    }
  }

  setPartiallyRefund(ev) {
    var checked = ev.currentTarget.checked

    if (this.application.current_user.super_admin) {
      if (checked) {
        this.setRefund = true
        this.doDataTable()
      } else {
        this.setRefund = false
        this.doDataTable()
      }
    }
  }

  updateRefundItemEv(ev) {
    var itemId = ev.currentTarget.dataset.id

    const data = { item: { id: itemId, refunded: true, conciliated: true }, current_user: { current_user_id: this.application.current_user.id, feature: this.billing_item_permission } }
    const url = "/financials/integrations/bankings/iugu/billing_items/update_refunded"
    const init = { method: "PUT", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }

    var r = confirm(`Tem certeza o Item foi Reembolsado?`)
    if (r) {
      this.requestUpdateRefund(url, init)
    }
  }

  requestUpdateRefund(url, init) {
    
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process || response.save) {
          var billing = response.data.cln

          controller.current_billing = billing

          controller.application.billings.forEach(element => {
            if (element.id == billing.id) {
              controller.application.billings.splice(controller.application.billings.indexOf(element), 1, billing)
            }
          })

          if (controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`) && controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`).hasMainCardTarget) {
            var controllerBillings = controller.getControllerByIdentifier(`financials--books--receivables--conciliations--billings`)

            controllerBillings.iugu_billings.forEach(element => {
              if (element.id == billing.id) {
                controllerBillings.iugu_billings.splice(controllerBillings.iugu_billings.indexOf(element), 1, billing)
              }
            })

            controllerBillings.doDataTable()
          } else if (controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`) && controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`).hasMainCardTarget) {
            controller.getControllerByIdentifier(`financials--books--receivables--dashboard--index`).doDataTable()
          } else if (controller.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`)) {

            controller.application.billing_dates.forEach(billing_date => {
              if (billing_date.billing_id == billing.id) {
                controller.application.billing_dates.splice(controller.application.billing_dates.indexOf(billing_date), 1, billing.billing_dates[0])
              }
            })

            controller.getControllerByIdentifier(`financials--books--receivables--liquidations--billings`).doDataTable()
          }

          controller.setRefund = false
          controller.doBillingHtml()

        }

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  goTo(ev) {
    var url = ev.currentTarget.dataset.url

    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }
}