import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--cnaes--dashboard`
    this.application.cnaes = []
  }

  doCnaesDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-6 pr-1" data-${this.controllerName}-target="list" data-controller="operations--products--clinics--regularizations--cnaes--index"></div>
                  <div class="col-6 pl-1" data-${this.controllerName}-target="view" data-controller="operations--products--clinics--regularizations--cnaes--save
                                                                                                     operations--products--clinics--regularizations--cnaes--show
                                                                                                     operations--products--clinics--regularizations--cnaes--save-city-code"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--index`).permission = controller.permission
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--show`).permission = controller.permission
      controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--save`).permission = controller.permission
      controller.getCnaes()
    })
  }

  getCnaes() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()

    var data = { cnae: { active: true }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_regularization_cnaes` } }
    const url = "/app/datas/cnaes/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.cnaes = response.data.cln
        }

        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--index`).doIndexListHtml()
        controller.getControllerByIdentifier(`operations--products--clinics--regularizations--cnaes--show`).doViewHtml()

        controller.getControllerByIdentifier("operations--products--clinics--regularizations--cnaes--index").clearList()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
