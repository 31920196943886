import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["viewCard", "viewTitle", "cardBodyLog", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--clinics--managements--logs--show`
    this.doLogViewHtml()
  }

  doLogViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">Nenhum Log Selecionada</h6>
                  </div>
                </div>`

    this.getControllerByIdentifier("operations--products--clinics--managements--logs--dashboard").viewTarget.innerHTML = html
  }

  doViewHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;">${this.current_log.date_pretty}</h6>
                    <h6 class="card-title display-4 ml-0" style="padding:1rem;font-size:110%;margin-bottom:0px;">${this.current_log.title}</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="cardBodyLog">
                    <div class="row my-3">
                      <div class="col-12 trix-content">
                        ${this.current_log.body}
                      </div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--managements--logs--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.viewCardTarget.style.height = ($(window).height() * 0.55) + "px"
    })
  }

  // `<h6 class="trix-content">${speaker.speaker_bio}</h6>`

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
