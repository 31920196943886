import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card", "formCard", "formCardBody", "utmSourceInput", "pathInput", "formTitle", "utmContentInput",
                    "utmContentInput", "utmContentInput", "copyBtn", "utmSourceInput", "pathInput", "formTitle", "fileName"]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--links`
    this.source = ``
    this.content = ``
    this.medium = ``
    this.term = ``
  }

  doLinkGrid() {
    var html = `<div class="row">
                  <div class="col-10 offset-1" data-${this.controllerName}-target="card"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--marketing--landings--show--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.doLinkCardHtml()
    })
  }

  doLinkCardHtml() {

    var html = `<div class="row my-2 d-flex">
                  <div class="col-12 px-0">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-3 px-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">UTM Fonte</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="utmSourceInput" data-utm="source" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="UTM Fonte" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">UTM Conteúdo</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="utmContentInput" data-utm="content" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="UTM Conteúdo" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">UTM Mídia</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="utmMediumInput" data-utm="medium" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="UTM Mídia" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="">UTM Termos</label>
                                <input class="form-control f-075 pt-0" data-${this.controllerName}-target="utmTermInput" data-utm="term" data-action="keydown->${this.controllerName}#bindName keyup->${this.controllerName}#bindName" type="text" placeholder="UTM Termos" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row my-3">
                          <div class="col-10 mt-2">
                            <div class="form-group">
                              <div class="input-group input-group-sm">
                                <input disabled class="form-control f-075 pt-0" data-${this.controllerName}-target="pathInput" type="text" placeholder="URL da Landing Page" required>
                                <span class="input-group-icon mr-2">/</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-2 text-center f-075 f-bold px-1">
                            <p class="mb-0 f-075 mc-tooltip pointer">
                              <span data-controller="app--helpers--copy" data-copy="" data-${this.controllerName}-target="copyBtn" data-action="click->app--helpers--copy#copy"><span class="material-icons md-sm md-light">file_copy</span></span>
                              <span class="mc-tooltiptext">Clique para Copiar</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.cardTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.formTitleTarget.innerText = `Criando Link/Ad para Landing Page`
      controller.pathInputTarget.value = this.application.landing.final_cta_path
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  bindName(ev) {
    this[ev.currentTarget.dataset.utm] = ev.currentTarget.value.trim().toLowerCase()

    this.pathInputTarget.value = `?utm_mc_source=${this.source}&utm_mc_content=${this.content}&utm_mc_medium=${this.medium}&utm_mc_term=${this.term}`
    this.copyBtnTarget.dataset.copy = `${this.application.landing.full_path}${this.pathInputTarget.value}`
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.hasKindInputTarget) {
        if (controller.uploadReady == false) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.uploadSwitch(true)
        } else {
          controller.uploadSwitch(false)
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }

    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
