import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["recordTitle", "recordBoard", "kindTitle", "kindBoard", "accountsTitle", "bodyTable", "accountsBoard", "accountCard", "allCard"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `app--communications--interactions--dashboard--compilation`
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `hour` }
  }

  doCompilationsCard() {
    this.recordCards()
    this.kindCards()
  }

  kindCards() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Relatório Interações por Tipo - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`

      this.kindTitleTarget.innerHTML = title
    }


    var currentInteractions = []
    this.application.interactions.forEach(element => {
      if (this.application.current_account_id) {
        if (element.date_id == this.application.current_date.id && element.account_id == this.application.current_account_id) {
          currentInteractions[currentInteractions.length] = element
        }
      } else {
        if (element.date_id == this.application.current_date.id) {
          currentInteractions[currentInteractions.length] = element
        }
      }
    })

    var kindCardHtml = ``
    this.application.kind_report.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        element.data.forEach(element => {

          if (currentInteractions.length == 0) {
            var count = 0
          } else {
            var count = (element.total_month * 100 / currentInteractions.length)
          }

          kindCardHtml += `<div class="col-4 my-2 px-2">
                          <div class="card">
                            <div class="card-header p-1 text-center f-070">
                              <span>${element.kind_pretty}</span>
                            </div>
                            <div class="card-body text-center f-070 px-1 py-2">
                              <div class="row d-flex align-items-center mb-1">
                                <div class="col-12 text-center f-075 px-1">
                                  <strong>${this.numberController.numberMask(element.total_month)}</strong>
                                  <small>${this.numberController.percentOptionMask(count)}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`
        })
      }
    })

    this.kindBoardTarget.innerHTML = kindCardHtml
  }

  recordCards() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Relatório Interações por Domínio - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`

      this.recordTitleTarget.innerHTML = title
    }

    var currentInteractions = []
    this.application.interactions.forEach(element => {
      if (this.application.current_account_id) {
        if (element.date_id == this.application.current_date.id && element.account_id == this.application.current_account_id) {
          currentInteractions[currentInteractions.length] = element
        }
      } else {
        if (element.date_id == this.application.current_date.id) {
          currentInteractions[currentInteractions.length] = element
        }
      }
    })

    var recordCardHtml = ``
    this.application.record_report.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        
        if (element.data.length > 0) {
          element.data.forEach(element => {

            if (currentInteractions.length == 0) {
              var count = 0
            } else {
              var count = (element.total_month * 100 / currentInteractions.length)
            }

            recordCardHtml += `<div class="col-4 my-2 px-2">
                                <div class="card">
                                  <div class="card-header p-1 text-center f-070">
                                    <span>${element.record_type_pretty}</span>
                                  </div>
                                  <div class="card-body text-center f-070 px-1 py-2">
                                    <div class="row d-flex align-items-center mb-1">
                                      <div class="col-12 text-center f-075 px-1">
                                        <strong>${this.numberController.numberMask(element.total_month)}</strong>
                                        <small>${this.numberController.percentOptionMask(count)}</small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>`
          })  
        } else {
          recordCardHtml += `<div class="col-12 text-center f-075 mt-5">
                                <strong>Não há Interações</strong>
                              </div>`
        }
        
      }
    })

    this.recordBoardTarget.innerHTML = recordCardHtml
  }

  refreshReports() {
    this.getControllerByIdentifier(`app--communications--interactions--dashboard--main`).refreshReports(`interaction_compilation`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
