import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "journey", "call", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--schedules`
    this.blockedAdd = false
  }

  doSchedulesDashboard() {
    var html = `<div class="col-12" data-users--works--schedules--entities--list-target="main" data-controller="users--works--schedules--entities--list"></div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--show`).schedulesRowTarget.innerHTML = html)
    }).then(() => {

      var guestList = []
      var guestMain = {}
      controller.application.opportunity.leads.forEach(element => {
        guestList[guestList.length] = { id: element.lead_id, name: element.lead_name }
      })


      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).controllerForm = controller
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).permission = `sales_opportunities_schedules`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).recordId = controller.application.opportunity.id
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).recordType = `sales_opportunities`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).refreshMode = `record`
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountList = controller.application.sales_team
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountId = controller.application.opportunity.closer_id
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).accountName = controller.application.opportunity.closer_name
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestList = guestList
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestId = controller.application.opportunity.leads[0].lead_id
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestName = controller.application.opportunity.leads[0].lead_name
      controller.getControllerByIdentifier(`users--works--schedules--entities--list`).guestType = `sales_leads`

      if (controller.application.opportunity.prospector_id == undefined || controller.application.opportunity.closer_id == undefined) {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Favor definir o SDR e o Closer da Oportunidade`, 5000)
        controller.doNoSalesHtml()
      } else {
        controller.getControllerByIdentifier(`users--works--schedules--entities--list`).doListHtml()
      }
    })
  }

  doNoSalesHtml() {
    var html = `<div class="row">
                  <div class="col-12">
                    <h4 class="f-bold text-center mt-5">Favor definir o SDR e o Closer da Oportunidade</h3>
                  </div>
                </div>`

    this.getControllerByIdentifier(`users--works--schedules--entities--list`).mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
