import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "submenu", "squadDropdown", "squadDropdownBtn", "squadInput", "squadList", "squad", "squadsBtnGroup", "9999"]

  connect() {
    this.controllerName = `users--works--activities--tickets--squads`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSquadsPreloader()
  }

  doSquadHTML1() {
    var html = `<div class="form-group">
                  <div class="floating-label floating-label-sm">
                    <label>Time</label>
                    <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="squadDropdown">
                      <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="squadDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="squadInput"></span></button>
                      <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                        <input class="form-control form-control-selector f-065" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                        <ul class="ul-select" data-${this.controllerName}-target="squadList" data-app--helpers--search-target="searchList"></ul>
                      </div>
                    </div>
                  </div>`
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.squadDropdownTarget.value = controller.application.current_user.name
      controller.squadInputTarget.innerText = controller.application.current_user.name
      controller.doSquadList()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doSquadHTML() {
    var squadsHtml = ``

    this.application.my_squads.forEach(element => {
      squadsHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="click->${this.controllerName}#changeSquad" data-team-id="${element.team_id}" data-${this.controllerName}-target="btn-${element.team_id}" style="white-space:break-spaces;">${element.team_name}</button>`
    })

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="squadsBtnGroup">
                  ${squadsHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      // controller.doSquadList()
      
      controller.nameTarget(`btn-${controller.application.current_user.account_id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${controller.application.current_user.account_id}`).classList.add(`btn-primary`)
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  doSquadList() {
    var listHtml = ``
    listHtml += `<li data-app--helpers--selector-target="select" data-team-id="${this.application.current_user.account_id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeSquad" class="li-selector dark f-065">${this.application.current_user.name}</li>`
    this.application.my_squads.forEach(element => {
      listHtml += `<li data-app--helpers--selector-target="select" data-id="${element.id}" data-team-id="${element.team_id}" data-action="click->app--helpers--selector#select click->${this.controllerName}#changeSquad" class="li-selector dark f-065">${element.team_name}</li>`
    });
    this.squadListTarget.innerHTML = listHtml
  }

  changeSquad(ev) {
    if (this.getControllerByIdentifier(`users--works--activities--tickets--board`)) {
    
      var teamId = ev.target.dataset.teamId
      this.application.account_id = ev.target.dataset.teamId
      this.application.my_squads.forEach(element => {
        if (element.team_id == teamId) {
          this.application.current_squad = element
        }
      })
      if (this.application.current_user.account_id == this.application.account_id) {
        this.application.personal_board = true
        this.application.non_personal_board = true
      } else {
        this.application.personal_board = false
        this.application.non_personal_board = true
      }

      this.toggleDateBtn(teamId)
      // this.getControllerByIdentifier(`users--works--activities--tickets--main`).getTickets()
      this.getTickets()
    }
  }

  getTickets() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    const data = { ticket: { performer_id: this.application.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `user_work_activity_tickets` } }
    const url = "/users/works/activities/tickets/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.tickets = []
        controller.application.all_tickets = response.data.cln

        if (controller.application.account_id == controller.application.current_user.account_id) {
          controller.application.tickets = controller.application.all_tickets
        } else {
          controller.application.all_tickets.forEach(element => {
            if (controller.application.current_squad.board_types.includes(element.board_type)) {
              controller.application.tickets[controller.application.tickets.length] = element
            }
          })
        }

        controller.getControllerByIdentifier(`users--works--activities--tickets--main`).finishLoadPage()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  toggleDateBtn(teamId) {
    this.application.my_squads.forEach(element => {
      if (element.team_id == teamId) {
        this.nameTarget(`btn-${element.team_id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.team_id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.team_id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.team_id}`).classList.remove(`btn-primary`)

      }
    });
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  doSquadsPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
