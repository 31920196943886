import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "content", "form", "preview", "main", "nameInput", "cpfInput", "emailInput", "receivables", "percentRespondInput", 
    "numeratorInput", "denominatorInput", "count", "timer"]

  connect() {
    this.controllerName = `pages--develop`
    this.form = {}
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.doDevelopDashboard()
    
  }

  doDevelopDashboard() {
    // var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
    //               <div class="col-3 pr-1" data-${this.controllerName}-target="form">List</div>
    //               <div class="col-6 pl-1" data-${this.controllerName}-target="receivables" data-controller="financials--books--receivables--entities--save-from-conciliation"></div>
    //             </div>`
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12" data-${this.controllerName}-target="form">List</div>
                  <div class="col-12" data-${this.controllerName}-target="preview">List</div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      // controller.requestTestHtml()
      // controller.formReceivableHtml()
      // controller.cnaeInputHtml()
      // controller.modal()
      // controller.doFormHtml()
      // controller.doPreviewHtml()
      // controller.getSite()
      // controller.readLead()
      // controller.percentInputV2()
      // controller.doWebhook()
      // controller.clickToPreview()
      // controller.getCall()
      controller.doCountHtml()
    })
  }

  doCountHtml() {
    var html = `<div class="card">
                  <div class="card-header p-0">
                    <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Contagem</div></div>
                  </div>
                  <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                    <div class="row my-1">
                      <div class="col-12 px-0" data-${this.controllerName}-target="count">0</div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header p-0">
                    <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Tempo</div></div>
                  </div>
                  <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                    <div class="row my-1">
                      <div class="col-12 px-0" data-${this.controllerName}-target="timer">00:00</div>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
    // this.doTimers()
  }

  doTimers() {
    var count = 0
    var time = 0
    var controller = this

    setInterval(() => {
      count += 1
      controller.countTarget.innerText = count
    }, 100)

    setInterval(() => {
      time += 1
      controller.timerTarget.innerText = controller.timeMask(time)
    }, 1000)
  }

  timeMask(currentTime) {
    var current_hour = parseInt(currentTime / 3600) % 24
    var current_minute = parseInt(currentTime / 60) % 60
    var current_seconds_long = currentTime % 60
    var current_seconds = current_seconds_long.toFixed()
    var current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds)

    return current_time
  }

  doAudioHtml() {
    var html = `<div class="card pointer" style="width:100%;display:relative;">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <button data-kind="audio" data-obj-type="communication_voip_calls" data-action="click->${this.controllerName}#listenToAudio" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">play_circle_filled</span>
                        <span class="mc-tooltiptext">Escutar Ligação</span>
                      </button>
                  </div>
                </div>`

    this.formTarget.innerHTML = html

    
  }

  listenToAudio() {
    this.current_audio = { service_url: this.call.audio_url, content_type: this.call.audio_content_type }

    this.getControllerByIdentifier(`app--helpers--pre-audio-modal`).current_audio = this.current_audio
    this.getControllerByIdentifier(`app--helpers--pre-audio-modal`).openModal({ message: `Escutar Áudio` })

    // var controllerAudio = this.getControllerByIdentifier(`app--helpers--audio`)
    // controllerAudio.current_audio = this.current_audio
    // var html = controllerAudio.doAudioHtml()
    // console.log(this.call)
    // this.previewTarget.innerHTML = html
    // var controller = this
    // new Promise(function (resolve) {
    //   resolve(controller.previewTarget.innerHTML = html)
    // }).then(() => {
    //   // controller.getControllerByIdentifier(`pages--print-modal`).link = this.current_lead.profile_url
    //   // controller.getControllerByIdentifier(`pages--print-modal`).open()
    // })
  }

 
  getCall() {
    var data = { call: { record_id: 59, record_type: `sales_opportunities` }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_opportunities_calls` } }
    const url = "/app/communications/voip/calls/list_by_record"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.calls = response.data.cln

        controller.application.calls.forEach(element => {
          if (element.record_url) {
            controller.call = element
          }
        })
        
        controller.doAudioHtml()

      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  readLead() {
    const data = { lead: { id: 39 }, current_user: { current_user_id: this.application.current_user.id, feature: `sales_qualified_leads` } }
    const url = "/commercial/sales/leads/entities/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.current_lead = response.data.cln

        console.log(controller.current_lead)
        controller.doPrintHtml()

      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doWebhook() {
    var html = `<div class="mc-tooltip">
                  <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${modalName}#close" type="button">
                    <span class="material-icons md-dark">clear</span>
                  </button>
                  <span class="mc-tooltiptext">Fechar</span>
                </div>`

    this.formTarget.innerHTML = html
  }

  doPrintHtml() {

    // var link = this.application.site.principal_image
    var link = this.current_lead.profile_url
    var link = `https://medcapital-site-main.s3.sa-east-1.amazonaws.com/Nova+Apresentac%CC%A7a%CC%83o.pdf`
    var imgWidth = 200

    // <img src="${link}" width="${imgWidth}" alt="" type="application/pdf" rel="preload" crossorigin="use-credentials" />

    var html = `<div class="card pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="click->${this.controllerName}#showPrintModal">
                  <img src="${link}" width="${imgWidth}" alt="">
                </div>`

    this.formTarget.innerHTML = html
  }

  getSite() {
    this.send_data = { site: {} }
    this.send_data.site.kind = `principal`

    var url = "/commercial/marketing/sites/entities/read_principal_public"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.site = response.data.cln
          controller.doPrintHtml()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  showPrintModal() {
    // https://medcapital-site-main.s3.sa-east-1.amazonaws.com/Nova+Apresentac%CC%A7a%CC%83o.pdf
    // https://medcapital-site-main.s3.sa-east-1.amazonaws.com/image_main_sector.png
    var modalName = `pages--print-modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      // controller.getControllerByIdentifier(`pages--print-modal`).link = this.application.site.principal_image
      controller.getControllerByIdentifier(`pages--print-modal`).link = this.current_lead.profile_url
      controller.getControllerByIdentifier(`pages--print-modal`).open()
    })
  }

  requestTestHtml() {
    var html = `<div class="card pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="click->${this.controllerName}#renewalLogin">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Clique Aqui</h6>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  modal() {
    this.application.current_summary = this.currentSumary()
    this.application.summaries = [this.application.current_summary]
    this.application.summary_permission = `medfiling_team_summaries`
    var modalName = `operations--products--tax-filings--summaries--save`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body">
                        <div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${modalName}-target="mainCard">
                          <div class="card-header d-flex align-items-center card-header-table-list f-065">
                            <h6 class="card-title display-4 card-title-table-list">Editando Resumo IRPF 2023</h6>
                            <div class="card-actions ml-auto py-0 mc-tooltip">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-action="click->${modalName}#close" type="button">
                                <span class="material-icons md-dark">clear</span>
                              </button>
                              <span class="mc-tooltiptext">Fechar</span>
                            </div>
                          </div>
                          <div class="card-body py-0" style="overflow:auto;" data-${modalName}-target="cardBody"></div>
                          <div class="card-footer py-2 text-right" data-${modalName}-target="cardFooter"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).controllerForm = controller
      controller.getControllerByIdentifier(`operations--products--tax-filings--summaries--save`).open()
    })
  }

  cnaeInputHtml() {
    var html = `<div class="col-12">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Código</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="codeInput" data-action="blur->${this.controllerName}#codeMask keypress->${this.controllerName}#codeMask" placeholder="Código" type="text" required>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  codeMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCode(ev)
  }

  percentInput() {
    var html = `<div class="col-4">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Número</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="codeInput" data-action="blur->${this.controllerName}#percentMask" placeholder="Número" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Percentual</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="percentRespondInput" placeholder="Percentual" type="text" required>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  percentMask(ev) {
    var percent = this.controllerNumber.percentOptionMask(ev.currentTarget.value)
    this.percentRespondInputTarget.value = percent
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  percentInputV2() {
    var html = `<div class="col-4">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Numerador</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="numeratorInput" placeholder="Numerador" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Denominador</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="denominatorInput" data-action="blur->${this.controllerName}#calcMask" placeholder="Denominador" type="text" required>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <div class="floating-label floating-label-sm">
                      <label for="codeForm">Percentual</label>
                      <input aria-describedby="codeFormHelp" class="form-control" id="codeForm" data-${this.controllerName}-target="percentRespondInput" placeholder="Percentual" type="text" required>
                    </div>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  calcMask(ev) {
    var percent = Number(this.numeratorInputTarget.value) / Number(this.denominatorInputTarget.value)
    percent = this.controllerNumber.percentOptionMask(percent * 100)
    this.percentRespondInputTarget.value = percent
    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  timeoutTestHtml() {
    var html = `<div class="card pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="click->${this.controllerName}#checkSessionTimetout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Clique Aqui</h6>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  modalTestHtml() {
    var html = `<div class="card pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="click->${this.controllerName}#doProcessModal">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Clique Aqui Modal</h6>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  doProcessModal() {

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Carregando Teste!`, value: 10 })

    var controller = this
    setTimeout(() => {
      var response = { type: `success`, message: `Teste Finalizado` }
      controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
    }, 10 * 1000);

  }

  formReceivableHtml() {
    var html = `<div class="card pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="click->${this.controllerName}#addReceivable">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Clique Aqui Adicionar Receivable</h6>
                  </div>
                </div>`

    this.formTarget.innerHTML = html
  }

  addReceivable() {
    console.log(`add receivable`)
    console.log(this.currentInvoice())

    this.controllerSaveReceivable = this.getControllerByIdentifier("financials--books--receivables--entities--save-from-conciliation")

    this.controllerSaveReceivable.controllerDashboard = this
    this.controllerSaveReceivable.permission = `financial_receivable_entities`
    this.controllerSaveReceivable.gridElement = this.receivablesTarget
    this.controllerSaveReceivable.current_client = this.current_client
    this.controllerSaveReceivable.current_contract = {}
    this.controllerSaveReceivable.actionMode = `new`
    this.controllerSaveReceivable.doFormHtml()
  }

  

  renewalLogin() {
    var data = { current_user: { current_user_id: this.application.current_user.id }, tracker: { log: true } }
    const url = "/users/check_user_log_in"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => {
        controller.responseClone = response.clone()
        return response.json()
      })
      .then(response => {

        controller.application.loginCount = 0
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      }, function (rejectionReason) { // 3
        // console.log('Error parsing JSON from response:', rejectionReason, controller.responseClone); // 4
        if (responseClone.status == 401 && responseClone.redirected) {
          // window.location.reload()
        }
        // controller.responseClone.text() // 5
        //   .then(function (bodyText) {
        //     console.log('Received the following instead of valid JSON:', bodyText); // 6
        //   });
        })
      .catch(error => {
        var errorClone = error.clone()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(errorClone)
      })
  }

  checkSessionTimetout() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    // const url = "/users/sessions/timeouts/check_session_timeout"
    const url = "/api/v1/users/sessions/timeouts/check_session_timeout"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Novo Recibo</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                                      
                    
                    <div class="row my-2">
                      
                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-field="name" data-${this.controllerName}-target="nameInput" data-action="blur->${this.controllerName}#previewForm keyup->${this.controllerName}#previewForm"  placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-field="cpf" data-${this.controllerName}-target="cpfInput" data-action="blur->${this.controllerName}#previewForm keyup->${this.controllerName}#previewForm"  placeholder="CPF" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="emailForm">E-mail</label>
                            <input aria-describedby="emailFormHelp" class="form-control form-valid-control" id="emailForm" data-field="email" data-${this.controllerName}-target="emailInput" data-action="blur->${this.controllerName}#previewForm keyup->${this.controllerName}#previewForm"  placeholder="E-mail" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                    
                   
                  </div>
                  
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.formTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })

  }

  doPreviewHtml() {
    
    var copyPath = `<button type="button" class="btn btn-sm btn-table copy p-0" data-toggle="tooltip" data-placement="right" title data-original-title="Copiar"><i class="material-icons md-sm md-light">file_copy</i></button>`
    var copy_element = `CONTRATANTE: ${this.form.name}, CPF nº ${this.form.cpf}, residente e domiciliado na ${this.form.street}, nº ${this.form.number}, ${this.form.complement}, bairro BAIRRO, cidade de CIDADE, Estado UF, CEP CEP, E-mail ${this.form.email}; e`
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="previewCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="previewTitle">Preview Novo Recibo</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="previewCardBody">
                    <div class="row my-1">
                      <div class="col-12">
                        <table class="table table-sm table-borderless" style="font-size:80%;">
                          <tbody>
                            <tr>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                              <td style="font-size:80%;height:0.75rem;padding-top:0.5rem;padding-bottom:0.5rem;" scope="col" class="table-10 align-middle"></td>
                            </tr>

                            <tr>
                              <td colspan="9" class="f-075">
                                <strong>CONTRATANTE</strong>: ${this.form.name}, CPF nº ${this.form.cpf}, residente e domiciliado na ${this.form.street}, nº ${this.form.number}, ${this.form.complement}, bairro BAIRRO, cidade de CIDADE, Estado UF, CEP CEP, E-mail ${this.form.email}; e
                              </td>
                              <td style="font-size:80%;" scope="col" class="p-1 align-middle" data-copy="${copy_element}">${copyPath}</td>
                            </tr>

                            <tr>
                              <td colspan="9" class="f-075">

                              </td>
                            </tr>

                            

                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>`

    this.previewTarget.innerHTML = html
  }

  previewForm(ev) {
    var field = ev.target.dataset.field
    this.form[field] = ev.target.value
    this.doPreviewHtml()
  }  

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  currentSumary() {
    var obj = {
      "id": 17,
      "created_at": "2023-01-17T08:31:09.646-03:00",
      "updated_at": "2023-01-17T08:31:09.646-03:00",
      "active": true,
      "tax_filing_id": 10,
      "date_id": 612,
      "statement": "do_nothing",
      "form": null,
      "bank_code": "",
      "branch_code": "",
      "account_number": "",
      "installments": 1,
      "taxable_income_salaried": "0.0",
      "autonomous_taxable_income": "0.0",
      "booking_income": "0.0",
      "taxable_income": "0.0",
      "income_from_dependents": "0.0",
      "total_taxable_income": "0.0",
      "total_exempt_and_non_taxable_income": "0.0",
      "total_income_exclusive_taxation": "0.0",
      "total_income": "0.0",
      "discount_simplified_declaration": "0.0",
      "official_social_contribution": "0.0",
      "official_social_contribution_dependent": "0.0",
      "supplementary_pension": "0.0",
      "dependents": "0.0",
      "instruction_expense": "0.0",
      "medical_expenses": "0.0",
      "alimony": "0.0",
      "booking": "0.0",
      "total_deductions": "0.0",
      "calculation_basis": "0.0",
      "tax_due": "0.0",
      "incentive_deduction": "0.0",
      "tax_due_rra": "0.0",
      "aliquot": "0.0",
      "total_withholding_income_tax": "0.0",
      "adjusted_income_tax_amount": "0.0",
      "potencial": "0.0",
      "simplified": "0.0",
      "complete": "0.0",
      "pgbl_potencial": "0.0",
      "pgbl_real": "0.0",
      "pgbl_diff": "0.0",
      "pgbl_economy": "0.0",
      "income_tax_real": "0.0",
      "income_tax_potencial": "0.0",
      "taxation": null,
      "suggestion": null,
      "taxation_pretty": null,
      "suggestion_pretty": null,
      "form_pretty": null,
      "statement_pretty": "Sem Ajuste"
    }

    return obj
  }

  currentInvoice() {

    var obj = {
      "id": 1,
      "created_at": "2022-12-29T23:06:56.855-03:00",
      "updated_at": "2022-12-29T23:06:56.855-03:00",
      "active": true,
      "med_id": 3,
      "date_id": 25,
      "received_date_id": null,
      "returned_date_id": null,
      "client_id": null,
      "client_name": null,
      "customer_name": "Roberto Gardone Guimarães Serviços Médicos",
      "invoice_id_iugu": "C81C19EFB0014B518B577DFC2A14A826",
      "med_id_iugu": "5DA1358C8C7944DFA6591A85B80CE496",
      "med_name_iugu": "Financeiro MedCapital",
      "client_id_iugu": "70ABB618E0F34802A76EC8822EDD0590",
      "email_iugu": "robertogardone@yahoo.com.br",
      "payer_address_street": "Rua Engenheiro Albert Scharle",
      "payer_address_number": "30",
      "payer_address_complement": "Apt 1502",
      "payer_address_district": "Luxemburgo",
      "payer_address_city": "Belo Horizonte",
      "payer_address_state": "MG",
      "payer_address_country": null,
      "payer_address_zip_code": "30380370",
      "payer_cpf_cnpj": "35173125000106",
      "payer_email": "robertogardone@yahoo.com.br",
      "payer_name": "Roberto Gardone Guimarães Serviços Médicos",
      "payer_phone": "993838525",
      "payer_phone_prefix": "31",
      "secure_id_iugu": "c81c19ef-b001-4b51-8b57-7dfc2a14a826-9014",
      "secure_url_iugu": "https://faturas.iugu.com/c81c19ef-b001-4b51-8b57-7dfc2a14a826-9014",
      "return_url_iugu": null,
      "expired_url_iugu": "",
      "notification_url_iugu": null,
      "ignore_canceled_email_iugu": null,
      "token": null,
      "token_tree": null,
      "payable_with": [
        "credit_card",
        "bank_slip"
      ],
      "fines_iugu": false,
      "late_payment_fine": 0,
      "late_payment_fine_cents": null,
      "per_day_interest_iugu": false,
      "per_day_interest_value": null,
      "per_day_interest_cents": null,
      "max_installments_value": null,
      "created_at_iugu": "2022-12-28T15:30:56.000-03:00",
      "due_at_iugu": "2022-12-30T00:00:00.000-03:00",
      "paid_at_iugu": null,
      "conciliated": false,
      "payment_method": null,
      "status": "pending",
      "kind": null,
      "subkind": null,
      "paid_cents": null,
      "fee_paid_cents": null,
      "total_cents": 40000,
      "total_on_occurrence_day_cents": 0,
      "total_paid_cents": 0,
      "refunded_cents": 0,
      "paid": "0.0",
      "fee_paid": "0.0",
      "total": "400.0",
      "total_on_occurrence_day": "0.0",
      "total_paid": "0.0",
      "refunded": "0.0",
      "payer_cpf_cnpj_type": "legal",
      "payer_cpf_cnpj_type_pretty": "CNPJ",
      "payer_cpf_cnpj_pretty": "35.173.125/0001-06",
      "due_at_iugu_pretty": "30/Dez/2022",
      "created_at_iugu_to_time": 1672252256,
      "due_at_iugu_to_time": 1672369200,
      "items": [
        {
          "id": 1,
          "created_at": "2022-12-29T23:06:56.943-03:00",
          "updated_at": "2022-12-29T23:06:56.943-03:00",
          "active": true,
          "med_id": 3,
          "date_id": 25,
          "received_date_id": null,
          "returned_date_id": null,
          "client_id": null,
          "receivable_id": null,
          "invoice_id": 1,
          "item_id_iugu": "7D55E527BCC845C786C2FB1425EEC96D",
          "description": "Assinatura: Mensalidade PJ médica",
          "token": "",
          "conciliated": false,
          "quantity": 1,
          "price_cents": 40000,
          "price": "400.0"
        }
      ],
      "return_dates": [],
      "paid_at_iugu_pretty": "Sem Pagamento",
      "paid_at_iugu_full_pretty": "Sem Pagamento",
      "paid_at_iugu_to_time": 0,
      "method_pretty": "Sem Pagamento"
    }


    return obj
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
