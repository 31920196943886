
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--regularizations--index--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").doMainGrid()
  }

  goToSettings() {
    this.getControllerByIdentifier("operations--products--clinics--regularizations--index--settings").doSettingsDashboard()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("operations--products--clinics--regularizations--index--dashboard").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "operation_clinic_regularizations"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)
    this.getControllerByIdentifier(`operations--products--clinics--regularizations--index--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  goToApurations () {
    window.open(`/a/nfs-e-apuracoes`, '_self');
  }


  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">PJ's Médicas</button>`
    var settingsBtn = `<button type="button" data-action="${this.controllerName}#goToSettings ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Configurações</button>`

    if (this.application.permissions.medclinic_regularization_permissions.can_assign) {
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    // if (this.application.permissions.medclinic_invoices.can_index) {
    //   var apurationsBtn = `<button type="button" data-action="${this.controllerName}#goToApurations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">NF's e Apurações</button>`
    // } else {
    //   var apurationsBtn = ``
    // }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  
                  ${settingsBtn}
                  ${permissionBtn}
                </div>`

    // this.mainTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else if (element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
        btn = element
      } else if (element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
        btn = element
      } else if (element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      // if (element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
      //   btn = element
      // } else if (element == this.expenseCountTarget && [`DESPESAS`].includes(title)) {
      //   btn = element
      // } else if (element == this.receivableCountTarget && [`NOTA FISCAL`].includes(title)) {
      //   btn = element
      // } else if (element == this.taxCountTarget && [`IMPOSTOS`].includes(title)) {
      //   btn = element
      // } else {
      //   if (element.innerText == title) {
      //     btn = element
      //   }
      // }

      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
