import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--communications--voips--callers--dashboard`
  }

  doCallersDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-7 pr-1" data-${this.controllerName}-target="list" data-controller="app--communications--voips--callers--index"></div>
                  <div class="col-5 pl-1" data-${this.controllerName}-target="view" data-controller="app--communications--voips--callers--save app--communications--voips--callers--show"></div>
                </div>`

    // this.getControllerByIdentifier("app--communications--voips--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--communications--voips--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`app--communications--voips--dashboard--submenu`).dashboardToggleBtn(`RAMAIS`)

      controller.getControllerByIdentifier(`app--communications--voips--callers--index`).permission = `app_comunication_voip_callers`
      controller.getControllerByIdentifier(`app--communications--voips--callers--save`).permission = `app_comunication_voip_callers`

      controller.getControllerByIdentifier(`app--communications--voips--callers--index`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
