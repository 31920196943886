import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navdrawer"]

  connect() {
    this.application.current_user = currentUser
    this.getCurrentUserSidebarPermissions()
  }


  doSidebarHtml(permission, accountKind) {

    if (permission.can_dashboard) {
      var dashboard = `<ul class="navdrawer-nav my-0">
                        <li class="nav-item mc-tooltip">
                          <a class="nav-link d-flex align-items-center" href="/dashboard" disabled="true">
                            <span class="material-icons mr-3">dashboard</span>
                            <span class="f-065">Dashboard</span>
                          </a>
                          <span class="mc-tooltiptext">Dashboard</span>
                        </li>
                      </ul>` 
    } else {
      var dashboard = ``
    }

    // `<ul class="navdrawer-nav my-0">
    //   <li class="nav-item mc-tooltip">
    //     <a class="nav-link d-flex align-items-center" href="/a/atividades/${this.application.current_user.slug}" disabled="true">
    //       <span class="material-icons mr-3">format_list_numbered</span>
    //       <span class="f-065">Atividades</span>
    //     </a>
    //     <span class="mc-tooltiptext">Atividades</span>
    //   </li>
    // </ul>`

    if ((accountKind == "admin" || accountKind == "team") && permission.can_tasks) {
      var tasks = `<ul class="navdrawer-nav my-0">
                    <li class="nav-item mc-tooltip">
                      <a class="nav-link d-flex align-items-center" href="/a/tickets" disabled="true">
                        <span class="material-icons mr-3">format_list_numbered</span>
                        <span class="f-065">Tickets</span>
                      </a>
                      <span class="mc-tooltiptext">Tickets</span>
                    </li>
                  </ul>`
    } else {
      var tasks = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_sales) {
      var sales = `<ul class="navdrawer-nav my-0">
                    <li class="nav-item">
                      <a class="nav-link" href="/a/vendas"><i class="material-icons" data-toggle="tooltip" data-placement="top" title data-original-title="Vendas">record_voice_over</i></a>
                    </li>
                  </ul>`
    } else {
      var sales = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && (permission.can_sales || permission.can_marketing)) {
      var commercial = `<ul class="navdrawer-nav my-0">
                    <li class="nav-item mc-tooltip">
                      <a class="nav-link d-flex align-items-center" href="/a/marketing-vendas">
                        <span class="material-icons mr-3">stacked_line_chart</span>
                        <span class="f-065">Marketing & Vendas</span>
                      </a>
                      <span class="mc-tooltiptext">Marketing & Vendas</span>
                    </li>
                  </ul>`
    } else {
      var commercial = ``
    }

    if ((accountKind == "admin" || accountKind == "team" || accountKind == "doctor") && permission.can_operations) {
      if (accountKind == "admin" || accountKind == "team") {
        var ref = `/a/operacoes`
      } else if (accountKind == "doctor") {
        var ref = `/d/operacoes`
      }
      var operations = `<ul class="navdrawer-nav my-0">
                          <li class="nav-item mc-tooltip">
                            <a class="nav-link d-flex align-items-center" href="${ref}">
                              <span class="material-icons mr-3">category</span>
                              <span class="f-065">Serviços</span>
                            </a>
                            <span class="mc-tooltiptext">Serviços</span>
                          </li>
                        </ul>`
    } else {
      var operations = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_financials) {
      var financial = `<ul class="navdrawer-nav my-0">
                          <li class="nav-item mc-tooltip">
                            <a class="nav-link d-flex align-items-center" href="/a/financeiro">
                              <span class="material-icons-sharp mr-3">monetization_on</span>
                              <span class="f-065">Financeiro</span>
                            </a>
                            <span class="mc-tooltiptext">Financeiro</span>
                          </li>
                        </ul>`
    } else {
      var financial = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_marketing && permission.can_content) {
      var content = `<ul class="navdrawer-nav my-0">
                      <li class="nav-item mc-tooltip">
                        <a class="nav-link d-flex align-items-center" href="/a/conteudo">
                          <img class="ml-2 mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/icon-descanso-invert.png" alt="descancomedico" style="width:0.8rem;cursor:pointer;">
                          <span class="f-065">Descanso Médico</span>
                        </a>
                        <span class="mc-tooltiptext">Descanso Médico</span>
                      </li>
                    </ul>`
    } else if (permission.can_content) {
      var content = `<ul class="navdrawer-nav my-0">
                      <li class="nav-item mc-tooltip">
                        <a class="nav-link d-flex align-items-center" href="/descanso-medico">
                          <img class="ml-2 mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/icon-descanso-invert.png" alt="descancomedico" style="width:0.8rem;cursor:pointer;">
                          <span class="f-065">Descanso Médico</span>
                        </a>
                        <span class="mc-tooltiptext">Descanso Médico</span>
                      </li>
                    </ul>`
    } else {
      var content = ``
    }

    if (permission.can_profile) {
      if (accountKind == "admin" || accountKind == "team") {
        var ref = `/a/minha-conta/${this.application.current_user.slug}`
      } else if (accountKind == "doctor") {
        var ref = `/d/minha-conta/${this.application.current_user.slug}`
      } else if (accountKind == "helper") {
        var ref = `/h/minha-conta/${this.application.current_user.slug}`
      }
      var profile = `<div class="navdrawer-divider"></div>
                      <ul class="navdrawer-nav my-0">
                        <li class="nav-item mc-tooltip">
                          <a class="nav-link d-flex align-items-center" href="${ref}" disabled="true">
                            <span class="material-icons mr-3">account_box</span>
                            <span class="f-065">Minha Conta</span>
                          </a>
                          <span class="mc-tooltiptext">Minha Conta</span>
                        </li>
                      </ul>
                    <div class="navdrawer-divider"></div>`
    } else {
      var profile = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_settings) {
      var settings = `<ul class="navdrawer-nav my-0">
                        <li class="nav-item mc-tooltip">
                          <a class="nav-link d-flex align-items-center" href="/a/configuracoes" disabled="true">
                            <span class="material-icons mr-3">settings</span>
                            <span class="f-065">Configurações</span>
                          </a>
                          <span class="mc-tooltiptext">Configurações</span>
                        </li>
                      </ul>`
    } else {
      var settings = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_generators && $("#currentUserDataContainer").data("currentUser").super_admin) {
      var generators = `<ul class="navdrawer-nav my-0">
                          <li class="nav-item mc-tooltip">
                            <a class="nav-link d-flex align-items-center" href="/a/geradores" disabled="true" target="_blank">
                              <span class="material-icons mr-3">file_copy</span>
                              <span class="f-065">Geradores</span>
                            </a>
                            <span class="mc-tooltiptext">Geradores</span>
                          </li>
                        </ul>`
    } else {
      var generators = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_develop && $("#currentUserDataContainer").data("currentUser").super_admin) {
      var develop = `<ul class="navdrawer-nav my-0">
                      <li class="nav-item">
                        <a class="nav-link" href="/a/dev-page" disabled="true"><i class="material-icons" data-toggle="tooltip" data-placement="top" title="Página Teste">code</i></a>
                      </li>
                    </ul>`
    } else {
      var develop = ``
    }

    if ((accountKind == "admin" || accountKind == "team") && permission.can_report && $("#currentUserDataContainer").data("currentUser").super_admin) {
      var report = `<ul class="navdrawer-nav my-0">
                      <li class="nav-item">
                        <a class="nav-link" href="/a/relatorios" disabled="true"><i class="material-icons" data-toggle="tooltip" data-placement="top" title="Relatórios">table_chart</i></a>
                      </li>
                    </ul>`
    } else {
      var report = ``
    }


    develop = ``
    report = ``
    var html = `<div class="navdrawer-header" style="background-color:#fbfcff;height:57px;">
                  <a class="navbar-brand px-0"></a>
                </div>
                ${dashboard}
                ${financial}
                ${tasks}
                ${commercial}
                ${operations}
                ${content}
                ${profile}
                ${settings}
                ${generators}
                ${develop}
                ${report}`

    this.navdrawerTarget.innerHTML = html
    tooltip()
  }

  getCurrentUserSidebarPermissions() {
    const data = { current_user: { current_user_id: this.application.current_user.id }, user: { user_id: this.application.current_user.id } }
    const url = "/users/permissions/sidebars/read"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        var permission = response.data.cln
        var sidebarPermission = permission.data
        var accountKind = permission.account_kind
        $("#currentUserSidebarPermisisonContainer").data(permission.name, permission.data)
        controller.doSidebarHtml(sidebarPermission, accountKind)
        this.application.sidebar_permission = sidebarPermission
      })
  }
}
