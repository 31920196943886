import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu", "sideBarReport"]

  connect() {
    this.controllerName = `operations--products--clinics--clients--index--list`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `asc`, field: `company_name` }
    this.tableCols = 7
    this.doIndexListHtml()
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todas as PJ's Médicas</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="company_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">CNPJ</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">Data Início</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle pointer text-center">
                                  Regime Tributário
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer text-center">
                                  Natureza Legal
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="tax_regime_pretty" data-mode="asc">north</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--clinics--clients--index--dashboard").listTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(controller.tableCols, 4))
      if (controller.application.clinics) {
        controller.doDataTable()
      }
    })
  }

  doDataTable() {
    // var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.clinics, "company_name")

    if (this.sort.mode == `asc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.clinics, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.clinics, this.sort.field)
    }

    if (clinics.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(clinics.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (clinics[x] !== undefined) {
            internPage[internPage.length] = clinics[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: clinics.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há PJ Médicas</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.clinicTablePartial(element))
      });
    }
  }

  clinicTablePartial(element) {


    var productPath = `<button data-action="click->${this.controllerName}#goToShowPage" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">launch</span>
                        <span class="mc-tooltiptext">Ir para PJ Médica ${element.company_name}</span>
                      </button>`

    var rowHtml = `<tr class="itemRow" data-id="${element.id}" data-company-path="${element.company_path}" data-product-path="${element.client_path}">
                    
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.company_name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Ir para PJ Médica ${element.company_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span data-controller="app--helpers--copy" data-copy="${element.company_cnpj}" data-action="click->app--helpers--copy#copy">${element.company_cnpj_pretty}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Clique para Copiar</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.started_at_pick_date}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.tax_regime_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.legal_nature_pretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  doSideReportHtml() {

    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span># PJ Médicas</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-12 text-center f-1 px-1">
                            ${this.application.report.products}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Total Arquivos</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-12 text-center f-1 px-1">
                            ${this.application.report.files}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Total Rascunhos</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-12 text-center f-1 px-1">
                            ${this.application.report.drafts}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-header p-1 text-center f-075 f-bold">
                        <span>Total Tickets</span>
                      </div>
                      <div class="card-body text-center f-075 px-0 py-1">
                        <div class="row my-1 d-flex align-items-center">
                          <div class="col-12 text-center f-1 px-1">
                            ${this.application.report.tickets}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.sideBarReportTarget.innerHTML = html
  }

  goToShowPage(ev) {
    var url = ev.target.closest(".itemRow").dataset.productPath
    window.open(url, `_blank`)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = "operations--products--clinics--clients--index--list"
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
