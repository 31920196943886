import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `financials--books--clients--contracts--notes`
  }

  doNotesDashboard() {
    var html = `<div class="row w-100" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-4 px-2" data-${this.controllerName}-target="list" data-controller="users--works--notes--index"></div>
                  <div class="col-8 px-2" data-${this.controllerName}-target="view" data-controller="users--works--notes--show users--works--notes--save"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`financials--books--clients--contracts--workings`).noteRowTarget.innerHTML = html)
    }).then(() => {

      controller.getControllerByIdentifier(`users--works--notes--index`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--index`).sharing = false
      controller.getControllerByIdentifier(`users--works--notes--show`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).dashboardController = controller
      controller.getControllerByIdentifier(`users--works--notes--save`).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: controller.current_contract.id,
        domain_type: `financial_contracts`,
        domain_name: `Contratos | ${controller.current_client.record_name} | ${controller.current_contract.name}`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }
      controller.getControllerByIdentifier(`users--works--notes--index`).note_board = {
        account_id: controller.application.current_user.account_id,
        domain_id: controller.current_contract.id,
        domain_type: `financial_contracts`,
        domain_name: `Contratos | ${controller.current_client.record_name} | ${controller.current_contract.name}`,
        date_id: controller.application.current_date.id,
        date_type: `financials_dates`
      }

      controller.getControllerByIdentifier(`users--works--notes--index`).permission = `financial_client_notes`
      controller.getControllerByIdentifier(`users--works--notes--save`).permission = `financial_client_notes`
      controller.getControllerByIdentifier(`users--works--notes--show`).permission = `financial_client_notes`

      controller.getControllerByIdentifier(`users--works--notes--index`).doIndexListHtml()
      controller.getControllerByIdentifier(`users--works--notes--show`).doNoteViewHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
