import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "mainDashboard", "taxReturnDashboard", "bookingDashboard", "clinicDashboard", "receivementDashboard", "taxFilingDashboard", "8888", "9999"]

  connect() {
    this.controllerName = `users--dashboards--main--product`
    this.application.products = []
    this.productsPreloader()
    this.getAccountProduct()
  }

  goToTaxReturn() {
    if (this.application.products.has_tax_return) {
      if (this.tax_return_calculation.active) {
        var url = `/d/planner-anual/${this.application.products.tax_return_token}`
        window.open(url, `_self`)
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O seu Planner ainda não foi ativado", 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Você não possui o plano Med Planner", 3000)
    }
  }

  goToBooking() {
    if (this.application.products.has_booking) {
      if (this.booking_calculation.active) {
        var url = `/d/livro-caixa/${this.application.products.booking_token}`
        window.open(url, `_self`)
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "O seu Livro-Caixa ainda não foi ativado", 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Você não possui o plano Med Livro-Caixa", 3000)
    }
  }

  goToClinic() {
    // if (this.application.products.has_clinic) {
    //   if (this.clinic_calculation.active) {
    //     var url = `/d/pjs-medicas/${this.application.products.clinic_token}`
    //     window.open(url, `_self`)
    //   } else {
    //     this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "A sua PJ Médica ainda não foi ativada", 3000)
    //   }
    // } else {
    //   this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Você não possui o plano Med PJ", 3000)
    // }
  }

  goToTaxFiling() {
    if (this.application.products.has_tax_filing) {
      if (this.tax_filing_calculation.active) {
        var url = `/d/declaracao-de-ajuste/${this.application.products.tax_filing_token}`
        window.open(url, `_self`)
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "A sua Declaração IRPF ainda não foi ativada", 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Você não possui o plano Med IR", 3000)
    }
  }

  goToReceivement() {
    if (this.application.products.has_receivement) {
      if (this.receivement_calculation.active) {
        var url = `/d/recebimentos/${this.application.products.receivement_token}`
        window.open(url, `_self`)
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "A sua Gestão de Recebimentos ainda não foi ativada", 3000)
      }
    } else {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", "Você não possui o plano Med Gestão de Recebimentos", 3000)
    }
  }
  
  getAccountProduct() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const token = $('meta[name=csrf-token]').attr('content');
    const url = "/operations/accounts/products/read_with_calculations"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.products = response.data.cln.product
          controller.tax_filing_calculation = response.data.cln.tax_filing_calculation
          controller.tax_return_calculation = response.data.cln.tax_return_calculation
          controller.tax_return_achievement = response.data.cln.tax_return_achievement
          controller.booking_calculation = response.data.cln.booking_calculation
          controller.clinic_calculation = response.data.cln.clinic_calculation
          controller.receivement_calculation = response.data.cln.receivement_calculation
        } else {
          processingSnackbar(response.type, response.message, device)
        }
        controller.doTaxFilingCard()
        controller.doTaxReturnCard()
        controller.doBookingCard()
        // controller.doClinicCard()
        controller.doReceivementCard()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  doTaxFilingCard() {

    var html = `<h6 class="mb-0 text-center">${this.tax_filing_calculation.name}</h6>
                <hr class="mt-1">
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Status</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.tax_filing_calculation.journey_status_pretty}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Total Documentos</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.tax_filing_calculation.total_files}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>% Processamento</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.tax_filing_calculation.process_percentage * 10000).toString())}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row my-4">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToTaxFiling"><a class="a-dark f-065 f-wrap">Meu IRPF</a></button>
                  </div>
                </div>`

    this.taxFilingDashboardTarget.innerHTML = html
  }

  doTaxReturnCard() {

    if (this.tax_return_calculation.tax_statement_pretty == `` || this.tax_return_calculation.tax_statement_pretty == undefined) {
      var tax_return_calculation_tax_statement_pretty = ``
    } else {
      var tax_return_calculation_tax_statement_pretty = `| ${this.tax_return_calculation.tax_statement_pretty}`
    }

    if (this.tax_return_calculation.total_tax - this.tax_return_calculation.adjusted_tax > 0) {
      var finalTax = this.tax_return_calculation.total_tax - this.tax_return_calculation.adjusted_tax
    } else {
      var finalTax = this.tax_return_calculation.adjusted_tax - this.tax_return_calculation.total_tax
    }

    var html = `<h6 class="mb-0 text-center">${this.tax_return_calculation.name}</h6>
                  <hr class="mt-1">
                  <div class="row my-4">
                    <div class="col-6 pl-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Rend. Trib. PJ</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.tax_return_calculation.legal_income * 100))}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Rend. Trib. PF</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.tax_return_calculation.individual_income * 100))}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-12 pl-2 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Apuração IR</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(finalTax * 100))}</span>
                          <span class="text-right text-mute mt-5"><strong>${tax_return_calculation_tax_statement_pretty}</strong></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-6 pl-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Minha Meta</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.tax_return_achievement.saving_rate_goal * 10000).toString())}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Aporte Real</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.tax_return_achievement.saving_rate_real * 10000).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row my-4">
                    <div class="col-12">
                      <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToTaxReturn"><a class="a-dark f-065 f-wrap">Meu Planner Anual</a></button>
                    </div>
                  </div>`

    this.taxReturnDashboardTarget.innerHTML = html
  }

  doBookingCard() {

    var html = `<h6 class="mb-0 text-center">${this.booking_calculation.name}</h6>
                <hr class="mt-1">
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Rendimentos</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.booking_calculation.total_incomes * 100))}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Despesas</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.booking_calculation.total_expenses * 100))}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Alíquota Efetiva</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.booking_calculation.tax_rate * 100).toString())}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row my-4">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToBooking"><a class="a-dark f-065 f-wrap">Meu Livro-Caixa</a></button>
                  </div>
                </div>`

    this.bookingDashboardTarget.innerHTML = html
  }

  doClinicCard() {

    var html = `<h6 class="mb-0 text-center">PJ Médica</h6>
                <hr class="mt-1">
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Rendimentos</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.clinic_calculation.incomes * 100))}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Impostos</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.clinic_calculation.taxes * 100))}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4">
                  <div class="col-12 pl-2 pr-2">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-070">
                        <span>Alíquota Efetiva</span>
                      </div>
                      <div class="card-body text-center f-070 px-1">
                        <span>${this.getControllerByIdentifier("app--helpers--numbers").percentMask(parseInt(this.clinic_calculation.real_rate * 10000).toString())}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row my-4">
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToClinic"><a class="a-dark f-065 f-wrap">Minha PJ Médica</a></button>
                  </div>
                </div>`

    this.clinicDashboardTarget.innerHTML = html
  }

  doReceivementCard() {

    if (this.receivement_calculation.paper_due_amount - this.receivement_calculation.paper_transfer_amount > 0) {
      var openValues = this.receivement_calculation.paper_due_amount - this.receivement_calculation.paper_transfer_amount
    } else {
      var openValues = 0
    }

    var html = `<h6 class="mb-0 text-center">Gestão de Recebimentos</h6>
                  <hr class="mt-1">
                  <div class="row my-4">
                    <div class="col-6 pl-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span># Procedimentos</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(this.receivement_calculation.due_papers))}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span># Recebidos</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(this.receivement_calculation.transfer_papers))}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-6 pl-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Valores Devidos</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.receivement_calculation.paper_due_amount * 100))}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Valores Recebidos</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(this.receivement_calculation.paper_transfer_amount * 100))}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row my-4">
                    <div class="col-6 pl-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Valores em Aberto</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(openValues).toString())}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pr-2">
                      <div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>Prazo Médico</span>
                        </div>
                        <div class="card-body text-center f-070 px-1">
                          <span>${this.getControllerByIdentifier("app--helpers--numbers").numberMask(parseInt(this.receivement_calculation.average_term).toString())}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row my-4">
                    <div class="col-12">
                      <button class="btn btn-primary btn-sm my-2 w-100" data-action="click->${this.controllerName}#goToReceivement"><a class="a-dark f-065 f-wrap">Minha Gestão de Recebimentos</a></button>
                    </div>
                  </div>`

    this.receivementDashboardTarget.innerHTML = html
  }


  productsPreloader() {

    var loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`

    var cardLoader = `<div class="card border-top-primary">
                        <div class="card-header p-1 text-center f-070">
                          <span>${loader}</span>
                        </div>
                        <div class="card-body text-center f-070">
                          <span>${loader}</span>
                        </div>
                      </div>`

    var html = `<div class="row my-3" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-2 pl-0" data-${this.controllerName}-target="taxFilingDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        ${loader}
                      </div>
                    </div>
                  </div>
                  <div class="col-4" data-${this.controllerName}-target="taxReturnDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        ${loader}
                      </div>
                    </div>
                  </div>

                  <div class="col-2" data-${this.controllerName}-target="bookingDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        ${loader}
                      </div>
                    </div>
                  </div>

                  <div class="col-4 pr-0" data-${this.controllerName}-target="receivementDashboard">
                    <h6 class="mb-0 text-center">${loader}</h6>
                    <hr class="mt-1">
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-6 pl-2">
                        ${cardLoader}
                      </div>
                      <div class="col-6 pr-2">
                        ${cardLoader}
                      </div>
                    </div>
                    <div class="row my-5">
                      <div class="col-12">
                        ${loader}
                      </div>
                    </div>
                  </div>
                </div>`

    this.contentTarget.innerHTML = html

      // `<div class="col-2 px-0" data-${this.controllerName}-target="clinicDashboard">
      //   <h6 class="mb-0 text-center">${loader}</h6>
      //   <hr class="mt-1">
      //   <div class="row my-4">
      //     <div class="col-12 px-2">
      //       ${cardLoader}
      //     </div>
      //   </div>
      //   <div class="row my-4">
      //     <div class="col-12 px-2">
      //       ${cardLoader}
      //     </div>
      //   </div>
      //   <div class="row my-4">
      //     <div class="col-12 px-2">
      //       ${cardLoader}
      //     </div>
      //   </div>
      //   <div class="row my-5">
      //     <div class="col-12">
      //       ${loader}
      //     </div>
      //   </div>
      // </div>`
  }

  
}
