
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "main", "gain", "lost", "dailyChart", "accrualChart", "typeCard", "area", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "dailyTitleChart", "accrualTitleChart", "dailyChartBodyTable", "accrualChartBodyTable", "bodyDailyChart", "bodyAccrualChart", "accrualChart",
                    "dailyChartPreloader", "accrualChartPreloader", "accrualChartCard", "reportPage", "dailyChartSource",]

  connect() {
    this.controllerName = `commercial--sales--opportunities--reports--chart`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.reports = {}
  }

  doReportHtml() {

    var html = `<div class="row my-2 w-100" data-${this.controllerName}-target="reportPage">
                  <div class="col-12 px-0">
                    <div class="row">

                      <div class="col-2 px-1">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard dailyChartCard" data-feature="daily" data-chart="calls" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Ligações Por Dia</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard accrualChartCard" data-feature="accrual" data-chart="calls" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Ligações Acumuladas</strong></h6>
                          </div>
                        </div>
                      </div>

                      <div class="col-2 px-1">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard dailyChartCard" data-feature="daily" data-chart="schedules" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Agendas Por Dia</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard accrualChartCard" data-feature="accrual" data-chart="schedules" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Agendas Acumuladas</strong></h6>
                          </div>
                        </div>
                      </div>

                      <div class="col-2 px-1">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard dailyChartCard" data-feature="daily" data-chart="opportunities" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Oportunidades Por Dia</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 px-2">
                        <div class="card pointer" data-${this.controllerName}-target="typeCard accrualChartCard" data-feature="accrual" data-chart="opportunities" data-action="click->${this.controllerName}#toggleChart">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Oportunidades Acumuladas</strong></h6>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 px-1">

                    <div class="row"><div class="col-12" data-controller="commercial--sales--opportunities--reports--opportunities
                                                                          commercial--sales--opportunities--reports--calls
                                                                          commercial--sales--opportunities--reports--schedules" data-${this.controllerName}-target="area"></div></div>

                  </div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  toggleChart(ev) {
    this.typeCard = ev.currentTarget.dataset.feature
    this.chartCard = ev.currentTarget.dataset.chart

    this.typeCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })
    ev.currentTarget.classList.add(`bg-primary`)

    if (this.chartCard == `opportunities`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--opportunities`).typeCard = this.typeCard
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--opportunities`).year = this.year
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--opportunities`).month = this.month
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--opportunities`).doChartHtml()
    } else if (this.chartCard == `calls`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--calls`).typeCard = this.typeCard
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--calls`).year = this.year
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--calls`).month = this.month
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--calls`).doChartHtml()
    } else if (this.chartCard == `schedules`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--schedules`).typeCard = this.typeCard
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--schedules`).year = this.year
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--schedules`).month = this.month
      this.getControllerByIdentifier(`commercial--sales--opportunities--reports--schedules`).doChartHtml()
    }
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
