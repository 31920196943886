import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "bottom", "top", "list", "view", "mainCard", "bodyTable", "footerTable", "body", "searchTable", "searchInput",
    "cardBody", "emailDropdown", "emailDropdownBtn", "emailInput", "emailList"]

  connect() {
    this.controllerName = `financials--books--clients--addons--list-modal`
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 500
    this.pageNumber = 0
    this.numberPages = []
    this.tableCols = 2
    this.sort = { mode: `asc`, field: `name` }
  }

  open() {
    document.body.classList.add("modal-open");
    this.element.setAttribute("style", "display: block;");
    this.element.classList.add("show");
    const html = `<div class="modal-backdrop fade show"></div>`
    // document.body.insertAdjacentHTML("beforeend", html)
    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.doModalHtml()
    })
  }

  close() {
    document.body.classList.remove("modal-open");
    this.element.removeAttribute("style");
    this.element.classList.remove("show");
    document.getElementsByClassName("modal-backdrop")[0].remove();
    this.modalTarget.remove()
  }

  doModalHtml() {

    var closeLine = `<span data-action="click->${this.controllerName}#close" class="dropdown-item py-1 pointer dropdown-submenu-item">Fechar</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${closeLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">${this.title}</h6>
                    <div class="form-group w-30 mx-auto">
                      <div class="floating-label floating-label-sm">
                        <label>Médico</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="emailDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="emailDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="emailInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="emailList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-actions ml-auto py-0">
                      ${submenuHtml}
                      ${clearBtnHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row" data-${this.controllerName}-target="mainRow">
                      <div class="col-12 mt-1" data-${this.controllerName}-target="cardBody"></div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable"></div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.8) + "px"
      controller.setAddons()
      controller.doEmailsList()
      controller.doAddonsList()
    })
  }

  setAddons() {
    this.ordered_addons = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.client_addons, `kind_number`)
    this.active_addons = []
    this.inactive_addons = []
    this.inactive_all_addons = []

    this.ordered_addons.forEach(addon => {
      if (addon.status == `active`) {
        this.active_addons[this.active_addons.length] = addon
      } else if (addon.status != `active`) {
        this.inactive_addons[this.inactive_addons.length] = addon
        this.inactive_all_addons[this.inactive_all_addons.length] = addon
      }
    })
  }

  doEmailsList() {
    var uniq_addons = this.ordered_addons.filter((value, index, self) => self.map(x => x.client_iugu_email).indexOf(value.client_iugu_email) == index)
    var uniq_emails = this.mapElements(uniq_addons, `client_iugu_email`)
    var emailsHtml = ``

    uniq_emails.forEach(email => {
      emailsHtml += `<li data-email="${email}" data-action="click->app--helpers--selector#select click->${this.controllerName}#setClientAddons" data-app--helpers--selector-target="select" class="li-selector dark f-065">${email}</li>`
    })

    this.emailListTarget.innerHTML = emailsHtml
  }

  setClientAddons(ev) {

    var currentEmail = ev.currentTarget.dataset.email
    var currentAddons = []

    this.inactive_all_addons.forEach(addon => {
      if (addon.client_iugu_email == currentEmail) {
        currentAddons[currentAddons.length] = addon
      }
    })

    this.inactive_addons = currentAddons
    this.doAddonsList()
  }

  doAddonsList() {
    var html = ``
    var inactiveAddonHtml = ``

    if (this.inactive_addons.length > 0) {
      this.inactive_addons.forEach(addon => {
        if (addon.notes) {
          var addonNotes = addon.notes
        } else {
          var addonNotes = addon.chart_name
        }

        var churnDescription = ``
        if (addon.status == `canceled`) {
          churnDescription = addon.changed_description
        }

        if (this.application.permissions[this.permission].can_delete) {
          var deleteLine = `<span data-action="click->${this.controllerName}#destroyAddon" data-id="${addon.id}" data-description="${addon.description}" data-amount="${addon.amount}" data-token="${addon.token}" class="dropdown-item py-1 pointer dropdown-submenu-item">Apagar Complemento</span>`
        } else {
          var deleteLine = ``
        }

        var uploadLine = `<span data-action="click->${this.controllerName}#uploadAddon" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Upload Contrato</span>`
        var receivablesLine = `<span data-action="click->${this.controllerName}#showReceivables" data-id="${addon.id}" class="dropdown-item py-1 pointer dropdown-submenu-item">Ver Recebíveis</span>`

        var submenuActiveAddonHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 py-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                                          ${receivablesLine}
                                          ${uploadLine}
                                          ${deleteLine}
                                          
                                        </div>
                                        <span class="mc-tooltiptext">Opções</span>
                                      </div>`

        var cardShow = `<p class="mb-0 f-065">${addon.status_pretty} | Cliente: ${addon.client_iugu_email}</p>
                          <p class="mb-0 f-065">${addon.kind_pretty} | ${addon.subkind_pretty} | ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(addon.amount)} | Início: ${addon.started_at_pretty} | Término: ${addon.expires_at_pretty}</p>
                          <p class="mb-0 f-065">${addonNotes}</p>
                          <p class="mb-0 f-065">${churnDescription}</p>`

        inactiveAddonHtml += `<div class="card mt-1 pointer" style="width:100%;display:relative;" data-${this.controllerName}-target="addonCard-${addon.id}">
                                <div class="card-body p-0 f-065">
                                  <div class="row my-2 d-flex align-items-center">
                                    <div class="col-10 px-1">
                                      <div class="card-show-dropdown w-100">
                                        ${addon.started_at_date_pretty} | ${addon.description}
                                        <div class="card-show-dropdown-content text-left w-100">
                                          ${cardShow}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 px-1">
                                      ${submenuActiveAddonHtml}
                                    </div>
                                  </div>
                                </div>
                              </div>`
      })
    } else {
      inactiveAddonHtml += `<div class="row my-2 d-flex align-items-center">
                              <div class="col-12 text-center">
                                <span class="f-065"><strong>Não há Itens Pagos/Suspensos/Cancelados</strong></span>
                              </div>
                            </div>`
    }

    html += `<div class="row mt-3 d-flex align-items-center h-100" style="height:100%;">
              ${inactiveAddonHtml}
            </div>`

    this.cardBodyTarget.innerHTML = html
  }

  destroyAddon(ev) {
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).destroyAddon(ev)
  }

  uploadAddon(ev) {
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).uploadAddon(ev)
  }

  showReceivables(ev) {
    this.getControllerByIdentifier(`financials--books--clients--addons--list`).showReceivables(ev)
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }
}