import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["doubleCard", "singleCard", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--reports--cancellation`
    this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--dashboard`)
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doReport() {
    this.doCardsPreloader()
    this.getReport()
  }

  doPrincipalHtml() {
    var html = `<div data-${this.controllerName}-target="printElement">
                  <div class="row mb-2">
                    <div class="col-12">
                      ${this.doCancellationKindHtml()}
                    </div>
                    
                  </div>
                  <div class="row">
                    <div class="col-12">
                      ${this.doCancellationAmountHtml()}
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.reportContentTarget.innerHTML = html)
    }).then(() => {
      controller.doubleCardTargets.forEach(element => {
        element.style.height = ($(window).height() * 0.26) + "px"
      })
      controller.singleCardTargets.forEach(element => {
        element.style.height = ($(window).height() * 0.125) + "px"
      })
    })
  }

  doCancellationKindHtml() {
    var html = `<div class="row">
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="doubleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0"># Total Recibos</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.numberMask(this.report.total_receivables)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0"># Pedidos do Tomador/Fonte</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.numberMask(this.report.total_taker_request)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0"># Pedidos do Médico</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.numberMask(this.report.total_provider_request)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0"># Erro Interno</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.numberMask(this.report.total_internal_error)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0"># Outras Razões</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.numberMask(this.report.total_others_reasons)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doCancellationAmountHtml() {
    var html = `<div class="row">
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="doubleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Valor Total Recibos</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.report.total_amount_receivables)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Valor Pedidos do Tomador/Fonte</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.report.total_amount_taker_request)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Valor Pedidos do Médico</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.report.total_amount_provider_request)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Valor Erros Internos</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.report.total_amount_internal_error)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">Valor Outras Razões</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p15">
                            <div class="row my-1">
                              <div class="col-12 px-0">
                                ${this.numberController.currencyOptionMask(this.report.total_amount_others_reasons)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doCardsPreloader() {
    var preloadHtml = `<div class="row">
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="doubleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">${this.loader}</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1 w-100"><div class="col-12 px-0">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">${this.loader}</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1 w-100"><div class="col-12 px-0">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">${this.loader}</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1 w-100"><div class="col-12 px-0">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-0">
                    <div class="row">
                      <div class="col-12 mb-2">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">${this.loader}</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1 w-100"><div class="col-12 px-0">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="card" data-${this.controllerName}-target="singleCard">
                          <div class="card-header p-0">
                            <div class="row my-1 px-0"><div class="col-12 f-bold text-center px-0">${this.loader}</div></div>
                          </div>
                          <div class="card-body py-0 px-2 d-flex align-items-center justify-content-center f-1p5 f-bold">
                            <div class="row my-1 w-100"><div class="col-12 px-0">${this.loader}</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var html = `<div class="row mb-2">
                  <div class="col-12">
                    ${preloadHtml}
                  </div>
                  
                </div>
                <div class="row">
                  <div class="col-12">
                    ${preloadHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.reportContentTarget.innerHTML = html)
    }).then(() => {
      controller.doubleCardTargets.forEach(element => {
        element.style.height = ($(window).height() * 0.26) + "px"
      })
      controller.singleCardTargets.forEach(element => {
        element.style.height = ($(window).height() * 0.125) + "px"
      })
    })
  }

  getReport() {
    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Relatório de Cancelamento de Recibos/Notas Fiscais | Competência ${this.dashboardController.current_date.name_short}` })
    this.refreshLoadValue()

    const data = { report: { year: this.dashboardController.current_date.year, month: this.dashboardController.current_date.month }, current_user: { current_user_id: this.application.current_user.id, feature: `medclinic_financials` } }
    const url = "/operations/products/clinics/reports/generals/cancellations"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.report = response.data.cln

          controller.stopRefreshing()
          controller.doPrincipalHtml()
        }

        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshLoadValue() {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Relatório de Cancelamento de Recibos/Notas Fiscais | Competência ${controller.dashboardController.current_date.name_short}`, value: value })
      value += 1
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}