
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn", "ticketCount", "payableCount", "totalReceivables",
    "clientCount", "clientCountTooltip", "submenuBtn", "totalTickets", "payableCount",
    "totalBillings", "billingCount", "billingCountTooltip", "ticketCountTooltip", "asdf"]

  connect() {
    this.controllerName = `financials--books--payrolls--dashboard--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_blank');
  }

  goToDashboard() {
    this.getControllerByIdentifier("financials--books--payrolls--dashboard--main").doMainDashboard()
  }

  goToContracts() {
    this.getControllerByIdentifier("financials--books--payrolls--contracts--dashboard").doContractsDashboard()
  }

  goToRemunerations() {
    this.getControllerByIdentifier("financials--books--payrolls--remunerations--dashboard").doRemunerationsDashboard()
  }

  goToCompilations() {
    this.getControllerByIdentifier("financials--books--payrolls--compilations--dashboard").doCompilationsDashboard()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("financials--books--payrolls--dashboard--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "financial_book_payrolls"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)

    this.getControllerByIdentifier(`financials--books--payrolls--dashboard--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }


  doSubmenuHtml() {

    var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`
    var ticketBtn = `<span class="mc-tooltip">
                      <button type="button" data-action="${this.controllerName}#goToTickets ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn ticketCount">Tickets</button>
                      <span class="mc-tooltiptext" data-${this.controllerName}-target="ticketCountTooltip"></span>
                    </span>`

    if (this.application.permissions.financial_payroll_contracts.can_index) {
      var contractBtn = `<button type="button" data-action="${this.controllerName}#goToContracts ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contratos</button>`
      // var contractBtn = `<button type="button" data-action="${this.controllerName}#goTo ${this.controllerName}#toggleBtn" data-url="/a/contratos-financeiros" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Contratos</button>`
    } else {
      var contractBtn = ``
    }

    if (this.application.permissions.financial_payroll_remunerations.can_index) {
      var remunerationBtn = `<button type="button" data-action="${this.controllerName}#goToRemunerations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Remunerações</button>`
    } else {
      var remunerationBtn = ``
    }

    if (this.application.permissions.financial_payroll_remunerations.can_index) {
      var compilationBtn = `<button type="button" data-action="${this.controllerName}#goToCompilations ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Consolidado</button>`
    } else {
      var compilationBtn = ``
    }

    if (this.application.permissions.financial_payroll_permissions.can_assign) {
      this.application.domain_permission = this.application.permissions[`financial_payroll_permissions`]
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    var html = `<div class="btn-group ml-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${dashboardBtn}
                  ${contractBtn}
                  ${remunerationBtn}
                  ${compilationBtn}
                  ${permissionBtn}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)
      // controller.doBadgeCounts()
    })
  }

  doBadgeCounts() {
    this.doTicketsCount()
    this.doReceivablesCount()
    this.doBillingsCount()
  }

  toggleBtn(ev) {
    if (this.checkSubmenu(ev.currentTarget.innerText)) {
      this.submenuBtnTargets.forEach(element => {
        element.classList.add(`btn-flat-dark`)
        element.classList.remove(`btn-primary`)
      })

      ev.currentTarget.classList.remove(`btn-flat-dark`)
      ev.currentTarget.classList.add(`btn-primary`)
    }
  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (this.hasTicketCountTarget && element == this.ticketCountTarget && [`TICKETS`].includes(title)) {
        btn = element
      } else {
        if (element.innerText == title) {
          btn = element
        }
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)

  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-1 px-1 offset-2">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                  <div class="col-1 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  checkSubmenu(text) {
    var menu = [``]

    if (menu.includes(text)) {
      return false
    } else {
      return true
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
