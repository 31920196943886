
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "searchMain", "btnSearch", "searchDisplay", "inputSearch", "resultDisplay", "resultPreloader",
    "resultElement", "noFilter"]

  connect() {
    this.controllerName = `web--commercial--marketing--content--search--principal`
    // this.gridDisplay()
    // this.mainDisplay()
    // changeStringToQuery($('#inputSearch'))
  }

  doSearch(ev) {
    if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || ev.type == "click") {
      this.btnSearchTarget.disabled = true
      var search = this.inputSearchTarget.value
      // if (this.hasSearchMainTarget) {
      //   this.searchMainTarget.remove()
      // }

      if (window.location.pathname == "/") {
        // this.preloadSearchDisplay()
        window.open(`/descanso-medico?query=${search}`, `_blank`)
        this.btnSearchTarget.disabled = false
      } else {
        if (this.hasSearchMainTarget) {
          this.searchMainTarget.remove()
        }
        var timeSearchStarted = new Date()
        this.searchDisplay()
        this.send_data = { query: {}, current_visit: {}, current_user: {} }

        this.send_data.query.search = search
        this.send_data.query.time_started = timeSearchStarted.getTime()
        
        if (this.application.current_visit) {
          this.send_data.current_visit.current_visit_id = this.application.current_visit.id
        } else {
          this.send_data.current_visit.current_visit_id = null
        }

        if (this.application.current_user) {
          this.send_data.current_user.current_user_id = this.application.current_user.id
        } else {
          this.send_data.current_user.current_user_id = null
        }

        this.doResultsPreloader()
        this.requestSearch()
      }
    }
  }

  requestSearch(data) {
    var timeStarted = Date.now()

    const url = "/commercial/marketing/contents/searchs/posts"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.resultPreloaderTargets.forEach(loader => {
          loader.remove()
        });
        if (response.status === "forbidden") {
          controller.noResults()
        } else {
          controller.mainTarget.style.height = ""
          controller.inputSearchTarget.value = controller.send_data.query.search
          var timeSearch = ((Date.now() - timeStarted) / 1000).toString().replace(".", ",");
          $('#searchStatistics').removeClass("d-none");
          $('#searchResults').html(response.data.cln.compile.total);
          $('#searchSpan').html(timeSearch);

          if (response.data.cln.results.length > 0) {
            var visitorToken = response.data.cln.visitor.token
            if (visitorToken === null || visitorToken === undefined) {
              visitorToken = getCookie("ahoy_visitor");
            }

            var resultHtml = ``
            response.data.cln.results.forEach(element => {
              resultHtml += controller.resultPartial(element, visitorToken)
            });

            controller.resultDisplayTarget.innerHTML = resultHtml
          } else {
            controller.noResults()
          }
        }
        controller.btnSearchTarget.disabled = false
        controller.setResultsDisplayHeight()
      })
  }

  gridDisplay() {
    var html = `<section class="ws-section-principal ws-bg-circle" data-${this.controllerName}-target="main">
                  <div class="frame-search-main frame-white-100" data-${this.controllerName}-target="searchMain"></div>

                  <div class="frame-search-display frame-white" data-${this.controllerName}-target="searchDisplay" style="margin-top:7.5rem;"></div>

                  <div class="frame-result-display frame-white" data-${this.controllerName}-target="resultDisplay"></div>
                </section>`

    // var html = `<div data-${this.controllerName}-target="main" id="">
    //               <section class="frame-search-main frame-white-100" data-${this.controllerName}-target="searchMain"></section>
    //               <section class="frame-search-display frame-white" data-${this.controllerName}-target="searchDisplay"></section>
    //               <section class="frame-result-display frame-white" data-${this.controllerName}-target="resultDisplay"></section>
    //             </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`web--commercial--marketing--content--search--main`).contentTarget.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.mainDisplay()
    })
  }

  mainDisplay() {
    if (window.location.pathname == "/") {
      var divHtml = `<div class="col-sm-12 col-lg-12 text-center" style="display:block;">`
    } else {
      var divHtml = `<div class="col-sm-12 col-lg-6 text-center" style="display:block;">`
    }
    var html = `<div class="row d-flex align-items-center justify-content-center" style="height:inherit;">
                  ${divHtml}
                    <div class="display-4 s-title-053B5E s-title-bold s-title-2rem text-center mb-4"> 
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/logo-animada.gif" alt="MedCapital" class="imagem-head" style="width:25rem">
                    </div>
                    <div class="input-group justify-content-center mb-3">
                      <input type="text" class="form-control form-blog" id="inputSearch" placeholder="O que deseja aprender hoje?" autocomplete="on" data-action="keyup->${this.controllerName}#doSearch" data-${this.controllerName}-target="inputSearch"> 
                      <div class="input-group-append">
                        <button id="btnSearch" class="btn blog-btn" type="button" data-action="click->${this.controllerName}#doSearch" data-${this.controllerName}-target="btnSearch">  
                          <i class="material-icons">search</i>
                        </button>
                      </div>
                    </div>
                    <div class="display-4 s-title-053B5E s-title-bold s-title-2rem text-center mb-4"> 
                      <h1 class="blog-title py-4"> 
                        Assuntos recomendados
                      </h1>
                    </div>
                    <div class="display-3">
                      <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Livro-Caixa</a>
                      <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Imposto de Renda</a>
                      <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">PJ Médica</a>
                      <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Faturamento</a>
                      <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Calculadora</a>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.searchMainTarget.innerHTML = html)
    }).then(() => {
      if (window.location.href.split("?")[1]) {
        var search = decodeURI(window.location.href.split("?")[1].split("=")[1])
        controller.inputSearchTarget.value = search
        controller.btnSearchTarget.click()
      } else {
        if (window.location.pathname != "/") {
          controller.mainTarget.style.height = $(window).height() + "px"
          controller.inputSearchTarget.focus()
        }
      }
    })
  }

  resultPartial(element, token) {
    if (element.kind === "calculator") {
      var refLink = `/c/${element.slug}?token=${token}`
    } else if (element.kind === "landing") {
      var refLink = `/${element.slug}`
    } else if (element.kind === "downloads") {
      var refLink = `/downloads/${element.slug}?token=${token}`
    } else if (element.kind === "lessons") {
      var refLink = `/medschool/${element.slug}?token=${token}`
    } else {
      var refLink = `/descanso-medico/${element.slug}`
    }

    var html = `<div class="offset-1 col-9 mt-4 resultElement" data-${this.controllerName}-target="resultElement" data-action="click->${this.controllerName}#goTo" data-link="${refLink}" data-kind="${element.kind}" data-kind-pretty="${element.kind_pretty}">
                  <div class="card-invisible mt-4 results-cards">
                    <ul class="list-unstyled">
                      <li class="media d-flex align-items-center">
                        <img class="mr-5" src="${element.cover_url}" alt="${element.title}" style="width:5rem">
                        <div class="media-body">
                          <h5 class="mt-0 mb-1">${element.title}</h5>
                          <p class="mb-1">${element.copy}</p>
                          <p class="mb-1 card-text"><small class="text-muted">Atualizado em ${element.updated_at_pretty}</small></p>
                          <p class="mb-1 card-text"><small class="text-category">${element.kind_pretty}</small></p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>`


    return html
  }

  searchDisplay() {
    if (window.location.pathname == "/") {
      var div1Html = `<div class="col-md-4 col-sm-4 text-center">`
      var div2Html = `<div class="col-md-8 col-sm-12">`
    } else {
      var div1Html = `<div class="col-md-2 col-sm-4 text-center">`
      var div2Html = `<div class="col-md-6 col-sm-12">`
    }
    var html = `<div class="row d-flex align-items-center justify-content-start">
                  ${div1Html}
                    <div class="s-title-053B5E s-title-bold s-title-2rem text-center mb-4"> 
                      <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/images/logo-animada.gif" alt="MedCapital" class="imagem-head pointer" style="width:10rem" data-action="click->${this.controllerName}#reloadPage">
                    </div>
                  </div>
                  ${div2Html}
                    <div class="input-group justify-content-center mb-3">
                      <input type="text" class="form-control form-blog" id="inputSearch" placeholder="O que deseja aprender hoje?" autocomplete="off" data-action="keyup->${this.controllerName}#doSearch" data-${this.controllerName}-target="inputSearch">
                      <div class="input-group-append">
                        <button id="btnSearch" class="btn blog-btn" type="button" data-action="click->${this.controllerName}#doSearch" data-${this.controllerName}-target="btnSearch"> 
                          <i class="material-icons">search</i>
                        </button>
                      </div>
                    </div>
                    <small class="d-none" id="searchStatistics">Total de <span class="text-bold" id="searchResults">39
                      </span> resultado(s). Pesquisa realizada em <span class="text-bold" id="searchSpan">1,181</span> segundos
                    </small>
                  </div>
                  <div class="col-lg-12 col-sm-12 scrollmenu">
                    <ul class="nav nav-tabs scrolling-wrapper-flexbox mt-5 nav-tabs-blog" id="myTab" role="tablist">
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100 active" id="all-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter touchstart->${this.controllerName}#blogFilter" data-filter="all" role="tab" aria-controls="all" aria-selected="true">
                          <span class="material-icons"> pageview </span>
                          Todos
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="article-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="text" data-filter-pretty="Artigos" role="tab" aria-controls="text" aria-selected="false">
                        <span class="material-icons"> article </span>
                          Artigos
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="video-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="video" data-filter-pretty="Videos" role="tab" aria-controls="video" aria-selected="false">
                          <span class="material-icons"> ondemand_video </span>
                          Vídeos
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="podcast-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="podcast" data-filter-pretty="Podcasts" role="tab" aria-controls="podcast" aria-selected="false">
                          <span class="material-icons"> graphic_eq </span>
                          Podcast
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="calculator-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="calculator" data-filter-pretty="Calculadoras" role="tab" aria-controls="calculator" aria-selected="false">
                          <span class="material-icons"> calculate </span>
                          Calculadoras
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="lesson-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="lessons" data-filter-pretty="Aulas" role="tab" aria-controls="lesson" aria-selected="false">
                          <span class="material-icons"> school </span>
                          Aulas
                        </a>
                      </li>
                      <li class="nav-item pointer">
                        <a class="nav-link nav-link-100" id="downloads-tab" data-toggle="tab" data-action="click->${this.controllerName}#blogFilter" data-filter="downloads" data-filter-pretty="Downloads" role="tab" aria-controls="downloads" aria-selected="false">
                          <span class="material-icons"> cloud_download </span>
                          Materiais Gratuitos
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>`

    this.searchDisplayTarget.innerHTML = html
    this.inputSearchTarget.focus()
    changeStringToQuery($('#inputSearch'))
  }

  blogSugestion(ev) {
    this.inputSearchTarget.value = ev.target.innerText
    this.btnSearchTarget.click()
  }

  reloadPage() {
    location.search = ""
    // window.location.reload();
  }

  goTo(ev) {
    var link = $(ev.target).parents(".resultElement").data("link")
    window.open(link, "_blank")
  }

  blogFilter(ev) {
    if (ev.target.className == "material-icons") {
      var filter = ev.target.parentElement.dataset.filter
      var filterPretty = ev.target.parentElement.dataset.filterPretty
    } else {
      var filter = ev.target.dataset.filter
      var filterPretty = ev.target.dataset.filterPretty
    }
    var elements = 0
    var results = 0

    if (this.hasNoFilterTarget) {
      this.noFilterTarget.remove()
    }

    this.resultElementTargets.forEach(result => {
      results++
      if (filter == "all") {
        result.classList.remove("d-none")
        elements += 1
      } else {
        if (result.dataset.kind == filter) {
          result.classList.remove("d-none")
          elements += 1
        } else {
          result.classList.add("d-none")
        }
      }
    });
    this.setResultsDisplayHeight()
    if (elements == 0 && results > 0) {
      this.noBlogFilter(filterPretty)
    }
  }

  noResults() {
    this.mainTarget.style.height = $(window).height() + "px"

    var noResultHtml = `<div class="row d-flex align-items-center justify-content-center p-5">
                          <div class="col-sm-12 col-lg-6 text-center">
                            <h1 class="display-4 s-title-053B5E s-title-bold s-title-2rem text-center mb-4">
                              Opss... não tivemos nenhum resultado com a busca! Veja os assuntos mais buscados:
                            </h1>
                            <div class="display-3">
                              <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Livro-Caixa</a>
                              <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Imposto de Renda</a>
                              <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">PJ Médica</a>
                              <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Faturamento</a>
                              <a class="chip chip-action blog-chip" data-action="click->${this.controllerName}#blogSugestion">Calculadoras</a>
                            </div>
                          </div>
                          <div class="col-sm-12 col-lg-6 text-center">
                            <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/NoSearch.png" alt="MedCapital" class="imagem-head" style="width:25rem">
                          </div>
                        </div>`

    this.resultDisplayTarget.innerHTML = noResultHtml
  }

  noBlogFilter(filterPretty) {
    var html = `<div class="row d-flex align-items-center justify-content-center p-5" data-${this.controllerName}-target="noFilter">
                  <div class="col-sm-12 col-lg-6 text-center">
                    <h1 class="display-4 s-title-053B5E s-title-bold s-title-2rem text-center mb-4">
                      Opss... não temos ${filterPretty} para a pesquisa realizada. Veja as outras opções!
                    </h1>
                  </div>
                  <div class="col-sm-12 col-lg-6 text-center">
                    <img src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/NoSearch.png" alt="MedCapital" class="imagem-head" style="width:25rem">
                  </div>
                </div>`


    this.resultDisplayTarget.insertAdjacentHTML("beforeend", html)
  }

  preloadSearchDisplay() {
    var html = `<div class="row style="display:block;">
                  <div class="col-sm-12 text-center">
                    <h6>Carregando Descanso Médico</h6>
                  </div>
                </div>
                <div class="row" style="display:block;">
                  <div class="col-sm-12 text-center d-flex justify-content-center">
                    <div class="loader-stairs">
                      <div class="loader-stairs__bar"></div>
                      <div class="loader-stairs__bar"></div>
                      <div class="loader-stairs__bar"></div>
                      <div class="loader-stairs__bar"></div>
                      <div class="loader-stairs__bar"></div>
                      <div class="loader-stairs__ball"></div>
                    </div>
                  </div>
                </div>`

    this.searchDisplayTarget.innerHTML = html
  }

  doResultsPreloader() {

    var resultPreloaderHtml = ''
    for (var i = 0; i < 5; i++) {
      resultPreloaderHtml += `<div class="offset-1 col-10 mt-4" data-${this.controllerName}-target="resultPreloader">
                                  <div class="card-invisible mt-4 results-cards">
                                    <ul class="list-unstyled">
                                      <li class="media d-flex align-items-center">
                                        <img class="mr-3" src="https://medcapital-site-main.s3-sa-east-1.amazonaws.com/favicon.png" alt="logo-medcapital" style="width:5rem;">
                                        <div class="media-body">
                                          <div class='card timeline-item m-0 p-1 w-100 my-3' style="box-shadow:none;max-width:none;">
                                            <div class='animated-background animated-background-5'>
                                              <div class='background-masker title'></div>
                                            </div>
                                          </div>
                                          <div class='card timeline-item m-0 p-1 w-100 my-3' style="box-shadow:none;max-width:none;">
                                            <div class='animated-background animated-background-5'>
                                              <div class='background-masker title'></div>
                                            </div>
                                          </div>
                                          <div class='card timeline-item m-0 p-1 w-100 my-3' style="box-shadow:none;max-width:none;">
                                            <div class='animated-background animated-background-5'>
                                              <div class='background-masker title'></div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>`

    }

    this.resultDisplayTarget.innerHTML = resultPreloaderHtml
  }

  setResultsDisplayHeight() {

    var childrenHeight = 0

    Array.prototype.forEach.call(this.resultDisplayTarget.children, child => {
      childrenHeight += child.clientHeight
    })

    if (childrenHeight < $(window).height() * 0.6) {
      this.mainTarget.style.height = $(window).height() + "px"
    } else {
      this.mainTarget.style.height = ""
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}
