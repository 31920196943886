
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result", "submenuBtn"]

  connect() {
    this.controllerName = `users--companies--entities--submenu`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.kindLoaded = { clinic: false, taker: false, med: false, provider: false }
    this.doSubmenuPreloader()
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  goToClinics() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").kind = "clinic"
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  goToTakers() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").kind = "taker"
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  goToMeds() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").kind = "med"
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  // goToSuppliers() {
  //   this.getControllerByIdentifier("users--companies--entities--dashboard").kind = "supplier"
  //   this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  // }

  goToProviders() {
    this.getControllerByIdentifier("users--companies--entities--dashboard").kind = "provider"
    this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
  }

  goToPermissions() {
    var element = this.getControllerByIdentifier("users--companies--entities--main").contentTarget

    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).subdomain = "company_entities"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).account_kinds = ["team"]
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).current_account_kind = "team"
    this.getControllerByIdentifier(`users--works--permissions--entities--list-users`).doPageGrid(element)
    this.getControllerByIdentifier(`users--companies--entities--submenu`).dashboardToggleBtn(`PERMISSÕES`)
  }

  setSubmenu(ev) {
    this.kind = ev.currentTarget.dataset.kind
    this.kind_pretty = ev.currentTarget.dataset.kindPretty

    if (this.kindLoaded[this.kind]) {
      this.getControllerByIdentifier("users--companies--entities--dashboard").kind = this.kind
      this.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
    } else {
      this.getCompanies()
    }
  }

  goToShowPage() {
    window.open(`/a/pjs-medicas`, '_self');
  }

  doSubmenuHtml() {

    // var dashboardBtn = `<button type="button" data-action="${this.controllerName}#goToDashboard ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Dashboard</button>`

    if (this.application.permissions.company_entity_clinics.can_index) {
      var clinicBtn = `<button type="button" data-kind="clinic" data-kind-pretty="PJs Médicas" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">PJs Médicas</button>`
    } else {
      var clinicBtn = ``
    }

    if (this.application.permissions.company_entity_takers.can_index) {
      var takerBtn = `<button type="button" data-kind="taker" data-kind-pretty="Tomadores" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Tomadores</button>`
    } else {
      var takerBtn = ``
    }

    if (this.application.permissions.company_entity_meds.can_index) {
      var medBtn = `<button type="button" data-kind="med" data-kind-pretty="Meds" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Meds</button>`
    } else {
      var medBtn = ``
    }

    // if (this.application.permissions.company_entity_providers.can_index) {
    //   var providerBtn = `<button type="button" data-kind="provider" data-kind-pretty="Provedores" data-action="${this.controllerName}#setSubmenu ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Provedores</button>`
    // } else {
    //   var providerBtn = ``
    // }

    var providerBtn = ``
    // var medBtn = ``

    if (this.application.permissions.company_entity_permissions.can_assign) {
      var permissionBtn = `<button type="button" data-action="${this.controllerName}#goToPermissions ${this.controllerName}#toggleBtn" class="btn btn-flat-dark btn-no-shadow btn-no-border btn-sm px-1 mx-1 f-065" data-${this.controllerName}-target="submenuBtn">Permissões</button>`
    } else {
      var permissionBtn = ``
    }

    var html = `<div class="btn-group mx-auto" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  
                  ${clinicBtn}
                  ${takerBtn}
                  ${providerBtn}
                  ${medBtn}

                  ${permissionBtn}
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("users--companies--entities--dashboard").kind = `clinic`
      controller.submenuBtnTargets[0].classList.remove(`btn-flat-dark`)
      controller.submenuBtnTargets[0].classList.add(`btn-primary`)

      this.submenuBtnTargets.forEach(element => {
        element.style.width = 100 + "px"
      })
    })
  }

  toggleBtn(ev) {

    this.submenuBtnTargets.forEach(element => {
      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    ev.currentTarget.classList.remove(`btn-flat-dark`)
    ev.currentTarget.classList.add(`btn-primary`)

  }

  dashboardToggleBtn(title) {

    var btn = ``

    this.submenuBtnTargets.forEach(element => {

      if (element.innerText == title) {
        btn = element
      }

      element.classList.add(`btn-flat-dark`)
      element.classList.remove(`btn-primary`)
    })

    btn.classList.remove(`btn-flat-dark`)
    btn.classList.add(`btn-primary`)
  }

  doSubmenuPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 offset-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                  <div class="col-2">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getCompanies() {

    this.getControllerByIdentifier(`app--helpers--pre-page-load`).openLoadModal({ submessage: `Carregando Empresas | ${this.kind_pretty}` })
    this.refreshLoadValue(this.kind_pretty)

    const data = { company: { active: true, kind: this.kind }, current_user: { current_user_id: this.application.current_user.id, feature: "company_entities" } }
    const url = "/users/companies/entities/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        var companiesCnpjs = controller.mapElements(controller.application.companies, `cnpj`)

        response.data.cln.forEach(company => {
          if (!companiesCnpjs.includes(company.cnpj)) {
            controller.application.companies[controller.application.companies.length] = company
          }
        })

        controller.stopRefreshing()
        controller.getControllerByIdentifier(`app--helpers--pre-page-load`).closeLoadModal()

        controller.kindLoaded[controller.kind] = true
        controller.getControllerByIdentifier("users--companies--entities--dashboard").kind = controller.kind
        controller.getControllerByIdentifier("users--companies--entities--dashboard").doMainDashboard()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  refreshLoadValue(kind_pretty) {
    var controller = this
    var value = 1
    this.refreshLoad = setInterval(function () {
      controller.getControllerByIdentifier(`app--helpers--pre-page-load`).progressCountMessage({ submessage: `Carregando Contas | ${kind_pretty}`, value: value })
      value += 1
    }, 400);
  }

  stopRefreshing() {
    if (this.refreshLoad) {
      clearInterval(this.refreshLoad)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
