import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.controllerName = `commercial--sales--leads--entities--main`
    this.application.permissions = {}
    this.application.opportunities = []
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.bodyCardLoader = this.getControllerByIdentifier("app--helpers--loaders").bodyCardLoader()
    this.setPageLoader()
  }

  doLeadsDashboard() {
    var html = `<div data-controller="commercial--sales--leads--entities--dashboard users--works--permissions--entities--list-users">
                  <div class="row mb-3 d-flex align-items-center">
                    <div class="col-6">
                      <div class="media">
                        <i class="material-icons md-lg md-primary mr-2">description</i>
                        <div class="media-body">
                          <h5 class="mt-2" style="margin-bottom:0px;">Leads Gerados</h5>
                          <span class="text-black-secondary">Leads/Clientes para Vendas</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 mx-auto" data-commercial--sales--leads--entities--dashboard-target="search"></div>
                    <div class="col-5 text-right" data-controller="commercial--sales--leads--entities--submenu" data-commercial--sales--leads--entities--submenu-target="main"></div>
                  </div>
                  <div class="my-2" data-commercial--sales--leads--entities--dashboard-target="main"></div>
                </div>`

    this.contentTarget.innerHTML = html
  }

  doSqlDashboard() {
    var html = `<div data-controller="commercial--sales--leads--sources--dashboard users--works--permissions--entities--list-users">
                  <div class="row mb-3 d-flex align-items-center">
                    <div class="col-6">
                      <div class="media">
                        <i class="material-icons md-lg md-primary mr-2">follow_the_signs</i>
                        <div class="media-body">
                          <h5 class="mt-2" style="margin-bottom:0px;">SQLs</h5>
                          <span class="text-black-secondary">Leads Qualificados para Vendas</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 text-right" data-controller="commercial--sales--leads--entities--submenu" data-commercial--sales--leads--entities--submenu-target="main"></div>
                  </div>
                  <div class="my-2" data-commercial--sales--leads--sources--dashboard-target="main"></div>
                </div>`

    this.contentTarget.innerHTML = html
  }

  setPageLoader() {
    if (window.location.pathname == `/a/leads-qualificados`) {
      this.doSqlDashboard()
    } else if (window.location.pathname == `/a/leads-gerados`) {
      this.doLeadsDashboard()
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
