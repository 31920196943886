import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "list", "view", "days", "resume", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--communications--interactions--entities--dashboard`
  }

  doInteractionsDashboard() {
    var controllerAccounts = `app--communications--interactions--dashboard--accounts`

    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-12 ml-auto pt-3 pb-1" data-${this.controllerName}-target="days" data-controller="app--communications--interactions--entities--days" style="overflow: auto;"></div>
                  <div class="col-12" data-${this.controllerName}-target="resume" data-controller="app--communications--interactions--entities--resume"></div>
                  <div class="col-2" data-${this.controllerName}-target="accounts" data-controller="app--communications--interactions--dashboard--accounts">
                    <h6 class="mb-0 text-center" data-${controllerAccounts}-target="accountsTitle"></h6>
                    <hr class="my-1">
                    <div class="row" data-${controllerAccounts}-target="accountsBoard"></div>
                  </div>
                  <div class="col-10 px-1" data-${this.controllerName}-target="list" data-controller="app--communications--interactions--entities--index"></div>
                </div>`

    // this.getControllerByIdentifier("app--communications--interactions--show--main").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`app--communications--interactions--dashboard--main`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`app--communications--interactions--dashboard--submenu`).dashboardToggleBtn(`INTERAÇÕES`)

      controller.getControllerByIdentifier(controllerAccounts).permission = `app_comunication_interaction_entities`
      controller.getControllerByIdentifier(controllerAccounts).grid = `interaction_table`
      controller.getControllerByIdentifier(controllerAccounts).doAccountsCard()

      controller.getControllerByIdentifier(`app--communications--interactions--entities--days`).doMenuPreloader()
      controller.getControllerByIdentifier(`app--communications--interactions--entities--index`).permission = `app_comunication_interaction_entities`
      controller.getControllerByIdentifier(`app--communications--interactions--entities--index`).doIndexListHtml()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
