import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "saveBtn", "cpfCheckbox", "cnpjCheckbox", "amount", "date", "description",
    "chartAccountDropdown", "chartAccountDropdownBtn", "chartAccountInput", "chartAccountList", "totalAmount", "split",
    "idNameInput", "idNumberInput", "cnpjInput", "cnpj", "cpfInput", "cpf", "idNameFilter", "idNameFilterItem",
    "formDropdown", "formDropdownBtn", "formInput", "formList"]


  connect() {
    this.controllerName = `operations--products--bookings--provisions--save`
    this.send_data = { current_user: {}, provision: {}, notification: {} }
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--bookings--provisions--show").doViewHtml()
    this.getControllerByIdentifier("operations--products--bookings--provisions--index").cleanCheckBox()
  }

  saveProvision() {
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.provision.id = this.current_provision.id
    this.send_data.provision.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateTarget.value)
    this.send_data.provision.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountTarget.value)
    this.send_data.provision.description = this.descriptionTarget.value

    this.send_data.notification.domain_id = this.application.booking.id
    this.send_data.notification.domain_type = "operation_products"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "operation_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% a provisão ${this.current_provision.id_name}, no valor de ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(this.current_provision.amount)} para a competência de ${this.current_provision.accrual_pretty}.`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    this.requestSave()
  }

  requestSave() {
    var url = "/operations/products/bookings/provisions/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var provision = response.data.cln

          controller.application.provisions.forEach((element, i) => {
            if (element.id == provision.id) {
              controller.application.provisions.splice(controller.application.provisions.indexOf(element), 1, provision)
            }
          })
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--bookings--provisions--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.medbooking_provisions.can_update) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveProvision" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2 d-flex align-items-center">
                      <div class="col-4 pl-0">
                        <div class="form-group form-valid-group" data-${this.controllerName}-target="validGroup" data-valid="false">
                          <div class="floating-label floating-label-sm">
                            <label for="dateProvisionForm">Data</label>
                            <input aria-describedby="dateProvisionFormHelp" class="form-control form-valid-control" id="dateProvisionForm" data-${this.controllerName}-target="date" placeholder="Data" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-8 d-flex px-5">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="provisionCpfCheck" data-kind="cpf" data-${this.controllerName}-target="cpfCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="provisionCpfCheck">CPF</label>
                        </div>
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="provisionCnpjCheck" data-kind="cnpj" data-${this.controllerName}-target="cnpjCheckbox" data-action="click->${this.controllerName}#kindCheck">
                          <label class="custom-control-label f-065" for="provisionCnpjCheck">CNPJ</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6 pr-2 pl-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome do Beneficiário</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-filter-mode="simple" data-${this.controllerName}-target="idNameInput" placeholder="Nome do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 d-flex align-items-center" data-${this.controllerName}-target="idNumberInput"><span><small>Escolha se é CPF ou CNPJ</small></span></div>
                      <div class="col-6 pr-0 pl-2 d-none" data-${this.controllerName}-target="cnpjInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cnpjForm">CNPJ do Beneficiário</label>
                            <input aria-describedby="cnpjFormHelp" class="form-control form-valid-control" id="cnpjForm" data-${this.controllerName}-target="cnpj" data-action="blur->${this.controllerName}#cnpjMask keypress->${this.controllerName}#cnpjMask" placeholder="CNPJ do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0 pl-2 d-none" data-${this.controllerName}-target="cpfInput">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="cpfForm">CPF do Beneficiário</label>
                            <input aria-describedby="cpfFormHelp" class="form-control form-valid-control" id="cpfForm" data-${this.controllerName}-target="cpf" data-action="blur->${this.controllerName}#cpfMask keypress->${this.controllerName}#cpfMask" placeholder="CPF do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6 pr-2 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="amountForm">Valor Parcela</label>
                            <input aria-describedby="amountFormHelp" class="form-control form-valid-control" id="amountForm" data-${this.controllerName}-target="amount" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Pago" type="tel" required>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-6 pr-0 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Forma</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="formDropdown" >
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="formDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="formInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="formList" data-app--helpers--search-target="searchList">
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Dinheiro" data-form="money" class="li-selector dark f-065">Dinheiro</li>
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Débito" data-form="debt" class="li-selector dark f-065">Cartão de Débito</li>
                                  <li data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" data-form-pretty="Cartão de Crédito" data-form="credit" class="li-selector dark f-065">Cartão de Crédito</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-6 pr-2 pl-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="descriptionForm">Descrição</label>
                            <input aria-describedby="descriptionFormHelp" class="form-control form-valid-control" id="descriptionForm" data-${this.controllerName}-target="description" placeholder="Descrição" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-0 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Classifique o Pagamento</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="chartAccountDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="chartAccountDropdownBtn"><span class="mr-auto w-100 selected-item" id="selected" data-app--helpers--selector-target="input" data-${this.controllerName}-target="chartAccountInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="chartAccountList" data-app--helpers--search-target="searchList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--bookings--provisions--dashboard").viewTarget.innerHTML = html)
    }).then(() => {

      // controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getControllerByIdentifier("app--helpers--pickdate").pick($(this.dateTarget), { kind: "monthly", range_year: controller.current_provision.year, range_month: controller.current_provision.month, max: true, months: true, years: 60 })
      controller.formTitleTarget.innerText = "Editando Provisão"
      controller.idNumberInputTarget.remove()
      controller.checkDate()

      controller.idNameInputTarget.value = controller.current_provision.id_name
      controller.idNameInputTarget.disabled = true
      controller.amountTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_provision.amount * 100))
      controller.descriptionTarget.value = controller.current_provision.description
      controller.chartAccountDropdownTarget.value = controller.current_provision.chart
      controller.chartAccountInputTarget.innerText = controller.current_provision.chart
      controller.chartAccountInputTarget.dataset.id = controller.current_provision.chart_id
      controller.chartAccountInputTarget.dataset.chart = controller.current_provision.chart
      controller.chartAccountDropdownBtnTarget.disabled = true
      controller.formDropdownTarget.value = controller.current_provision.form
      controller.formInputTarget.innerText = controller.current_provision.form_pretty
      controller.formInputTarget.dataset.form = controller.current_provision.form
      controller.formDropdownBtnTarget.disabled = true

      if (controller.current_provision.id_type == "individual") {
        controller.cpfCheckboxTarget.checked = true
        controller.cnpjCheckboxTarget.checked = false
        controller.cnpjInputTarget.classList.add("d-none")
        controller.cpfInputTarget.classList.remove("d-none")
        controller.cpfTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cpfMask(controller.current_provision.id_number)
        controller.cpfTarget.disabled = true
      } else if (controller.current_provision.id_type == "legal") {
        controller.cpfCheckboxTarget.checked = false
        controller.cnpjCheckboxTarget.checked = true
        controller.cnpjInputTarget.classList.remove("d-none")
        controller.cpfInputTarget.classList.add("d-none")
        controller.cnpjTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").cnpjMask(controller.current_provision.id_number)
        controller.cnpjTarget.disabled = true
      }
      controller.cpfCheckboxTarget.disabled = true
      controller.cnpjCheckboxTarget.disabled = true

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
    })
  }

  checkDate() {

    var hasDate = false
    this.application.dates.forEach(element => {
      if (element.month == this.current_provision.month && element.year == this.current_provision.year && element.open) {
        hasDate = true
      }
    });

    if (hasDate) {
      this.dateTarget.disabled = false
    } else {
      this.dateTarget.disabled = true
    }

    if (this.current_provision.date_pretty) {
      this.dateTarget.value = this.current_provision.date_pretty
    }
    
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  cnpjMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
  }

  cpfMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.cpfInputTarget.value == ``) {
        len += 1
      }

      if (controller.idNameInputTarget.value == ``) {
        len += 1
      }

      if (controller.cnpjCheckboxTarget.checked && controller.cnpjTarget.value == ``) {
        len += 1
      }

      if (controller.cpfCheckboxTarget.checked && controller.cpfTarget.value == ``) {
        len += 1
      }

      if (controller.amountTarget.value == ``) {
        len += 1
      }

      if (controller.dateTarget.value == ``) {
        len += 1
      }

      if (controller.formInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.descriptionTarget.value == ``) {
        len += 1
      }

      if (controller.chartAccountInputTarget.innerText == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
