import { Controller } from "stimulus"
import createChannel from "cables/cable";

export default class extends Controller {
  static targets = ["main", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.application.chat_socket = new WebSocket(this.mainTarget.dataset.webSocketUrl)
  }

  broadcastUpdateChannel() {
    var controller = this;
    this.application.chatUpdateChannel = createChannel({ channel: 'Commercial::Marketing::Visit::ListChannel', id: this.update_room.id }, {
      connected() {
        controller.updateListen()
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOn()
      },
      received(data) {
        if (data.kind == "chat") {
          // controller.application.chats = data.cln
          var chat = data.cln
          var chatIds = []
          controller.application.chats.forEach(element => {
            chatIds[chatIds.length] = element.id
          })
          if (chatIds.includes(chat.id)) {
            if (chat.active) {
              controller.application.chats.forEach((element, i) => {
                if (element.id == chat.id) {
                  controller.application.chats.splice(controller.application.chats.indexOf(element), 1, chat)
                }
              })
            } else {
              controller.application.chats.forEach((element, i) => {
                if (element.id == chat.id) {
                  controller.application.chats.splice(controller.application.chats.indexOf(element), 1)
                }
              })
            }
          } else {
            controller.application.chats[controller.application.chats.length] = chat
          }

          if (controller.getControllerByIdentifier(`commercial--marketing--visits--chats--index`)) {
            controller.getControllerByIdentifier(`commercial--marketing--visits--chats--index`).broadcast_chat = chat
            controller.getControllerByIdentifier(`commercial--marketing--visits--chats--index`).doDataTable()
          }

        }
      },
      disconnected() {
        controller.getControllerByIdentifier("app--navigation--desktop--navbar").connectionOff()
      }
    });
  }

  getRoom() {
    var data = { current_user: { current_user_id: this.application.current_user.id } }
    const url = "/commercial/marketing/visits/chats/get_room"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          controller.application.rooms[controller.application.rooms.length] = controller.identifier
          controller.update_room = response.data.cln.update
          controller.broadcastUpdateChannel()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 3000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  broadcastChatChannel(chatId) {
    var controller = this;
    this.application[`chatChannel-${chatId}`] = createChannel({ channel: 'Commercial::Marketing::Visit::ChatChannel', id: chatId, user_id: this.application.current_user_id }, {
      connected() {
        controller.chatListen(chatId)
        controller.getControllerByIdentifier("commercial--marketing--visits--chats--show").connectionOn(chatId)
        controller.getControllerByIdentifier("commercial--marketing--visits--chats--show").getMessages(chatId)
      },
      received(data) {
        controller.application[`messages-${chatId}`][controller.application[`messages-${chatId}`].length] = data
        controller.getControllerByIdentifier("commercial--marketing--visits--chats--show").insertMessagesHtml(data, chatId)
      },
      disconnected() {
        controller.getControllerByIdentifier("commercial--marketing--visits--chats--show").connectionOff(chatId)
      }
    });
  }

  updateListen() {
    if (this.application.chatUpdateChannel) {
      this.application.chatUpdateChannel.perform('follow', { id: this.update_room.id })
    }
  }

  chatListen(chatId) {
    if (this.application[`chatChannel-${chatId}`]) {
      this.application[`chatChannel-${chatId}`].perform('follow', { id: chatId })
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
