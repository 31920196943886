import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["1111", "2222", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `app--visits--users`
    this.application.current_user_id = this.element.dataset.currentUserId
    this.application.current_visit_id = this.element.dataset.currentVisitId
    this.application.current_user = currentUser
    this.application.token = $('meta[name=csrf-token]').attr('content')
    this.forceAhoy()
  }

  forceAhoy() {
    var controller = this
    this.ahoyTimer = setInterval(function () {
      var len = 1
      
      if (controller.getControllerByIdentifier("app--shared--cookies").getCookie("ahoy_visitor")) {
        len -= 1
      }


      if (len == 0) {
        controller.stopForceAhoy()
      }
    }, 200);
  }

  stopForceAhoy() {
    if (this.ahoyTimer) {
      clearInterval(this.ahoyTimer)
    }
  }

  getUserName(user) {

    if (user.account_kind == "admin" || user.account_kind == "team") {
      var userName = `${user.name.split(" ").shift()} ${user.name.split(" ").pop()}, da MedCapital,`
    } else if (user.account_kind == "doctor") {
      if (user.sex == "male") {
        var userName = `Dr. ${user.name.split(" ").shift()}`
      } else if (user.sex == "female") {
        var userName = `Dra. ${user.name.split(" ").shift()}`
      }
    } else if (user.account_kind == "helper") {
      var userName = `${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
    } else if (user.account_kind == "patient") {
      var userName = `${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
    }

    return userName
  }

  getFullNameTratment(user) {

    if (user.account_kind == "admin" || user.account_kind == "team") {
      if (user.sex == "male") {
        var userName = `o ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}, da MedCapital,`
      } else if (user.sex == "female") {
        var userName = `a ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}, da MedCapital,`
      }
    } else if (user.account_kind == "doctor") {
      if (user.sex == "male") {
        var userName = `o Dr. ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      } else if (user.sex == "female") {
        var userName = `a Dra. ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      }
    } else if (user.account_kind == "helper") {
      if (user.sex == "male") {
        var userName = `o ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      } else if (user.sex == "female") {
        var userName = `a ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      }
    } else if (user.account_kind == "patient") {
      if (user.sex == "male") {
        var userName = `o ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      } else if (user.sex == "female") {
        var userName = `a ${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`
      }
    }

    return userName
  }

  getNickname(user) {
    var nickname = `${user.name.split(" ").shift()} ${user.name.split(" ").pop()}`

    return nickname
  }

  getFirstname(model) {
    var firstname = `${model.name.split(" ").shift()}`

    return firstname
  }

  getInitials(name) {
    var initials = ``
    name.split(" ").forEach(element => {
      initials += element.toString().charAt(0)
    })

    return initials
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  checkUser() {
    if (this.application.current_user == null || this.application.current_user == undefined) {
      return false
    } else {
      return true
    }
  }

}
