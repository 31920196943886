import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["chartPreloader", "invoicesTitleChart", "invoicesChart", "footerData", "footerBodyTable", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf",
    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--reports--accrual`
    this.dashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--dashboard`)
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
  }

  doReport() {
    this.doInvoicesArea()
    // this.getReport()
  }

  doInvoicesArea() {
    var html = `<div class="row w-100 mb-3">
                  <div class="col-12 px-0">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12 text-center px-0">
                            <h6 class="text-center" data-${this.controllerName}-target="invoicesTitleChart"></h6>
                            <div class="d-flex align-items-center justify-content-center" style="height:250px;" data-${this.controllerName}-target="invoicesChart">Carregando...</div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer py-0" data-${this.controllerName}-target="footerData">
                        <div class="row">
                          <div class="col p-0">
                              <table class="table table-sm mb-0" style="font-size:80%;">
                                <tbody data-${this.controllerName}-target="footerBodyTable"></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.reportContentTarget.innerHTML = html)
    }).then(() => {
      controller.doChartPreloader()
    })
  }

  doChartPreloader() {
    this.invoicesTitleChartTarget.innerHTML = `${this.loader}`
    var chartPreloader = `<div class="loading" data-${this.controllerName}-target="chartPreloader">
                            <div class="loading-1"></div>
                            <div class="loading-2"></div>
                            <div class="loading-3"></div>
                            <div class="loading-4"></div>
                          </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.invoicesChartTarget.innerHTML = chartPreloader)
    }).then(() => {
      // controller.doChart()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}