import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "mainCard", "addTicketBtn", "ticketBoard", "resolvedBtn", "bodyShow", "bodyEdit", "bodyEditInput",
                    "dateShow", "dateShowDiv", "dateEdit", "dateEditInput", "saveDateBtn", "ticketCardBody", "collapseDate",
                    "inProcess", "resolved", "cancelDateBtn", "ticketLabelFilter",
                    "lowFilter", "mediumFilter", "highFilter", "maximumFilter",
                    "checkboxPriority", "priorityIcon", "priorityTooltiptext", "checkboxTerm"]

  connect() {
    this.controllerName = `users--works--activities--shared--tickets`
    this.application.in_process_tickets = []
    this.application.resolved_tickets = []
    this.current_tickets = this.application.tickets
  }

  addTicket() {
    this.createTicket()
  }

  doIndexListHtml() {

    // `<span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="ticketLabelFilter" data-feature="high" data-action="click->${this.controllerName}#toggleTickets">Não Urgente & Importante</span>
    // <span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="ticketLabelFilter" data-feature="medium" data-action="click->${this.controllerName}#toggleTickets">Urgente & Não Importante</span>
    // <span class="f-075 pointer badge badge-secondary-dark mr-auto" data-${this.controllerName}-target="ticketLabelFilter" data-feature="low" data-action="click->${this.controllerName}#toggleTickets">Não Urgente & Não Importante</span>`

    var html = `<div class="row">
                  <div class="col-12 px-2">
                    <h6 class="mb-0 d-flex align-items-center">
                      <span>Tickets</span>
                      <span class="f-075 pointer badge badge-secondary-dark ml-auto mr-1" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="maximum" data-feature="priority" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-red2">reorder</span>
                          <span class="mc-tooltiptext">Urgente & Importante</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark mx-1" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="high" data-feature="priority" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-orange2">menu</span>
                          <span class="mc-tooltiptext">Não Urgente & Importante</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark mx-1" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="medium" data-feature="priority" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-yellow2">drag_handle</span>
                          <span class="mc-tooltiptext">Urgente & Não Importante</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark ml-1 mr-auto" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="low" data-feature="priority" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">minimize</span>
                          <span class="mc-tooltiptext">Não Urgente & Não Importante</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark ml-auto mr-1" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="previous" data-feature="due" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-dark">arrow_back_ios</span>
                          <span class="mc-tooltiptext">Atrasadas</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark mx-1" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="today" data-feature="due" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-dark">today</span>
                          <span class="mc-tooltiptext">Hoje</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary-dark ml-1 mr-auto" data-${this.controllerName}-target="ticketLabelFilter" data-subfeature="next" data-feature="due" data-action="click->${this.controllerName}#toggleTickets">
                        <span class="mc-tooltip">
                          <span class="material-icons md-sm md-dark">arrow_forward_ios</span>
                          <span class="mc-tooltiptext">Futuras</span>
                        </span>
                      </span>
                      <span class="f-075 pointer badge badge-secondary ml-auto" data-${this.controllerName}-target="ticketLabelFilter inProcess" data-feature="in_process" data-action="click->${this.controllerName}#toggleTickets">Em Aberto</span>
                      <span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="ticketLabelFilter resolved" data-feature="resolved" data-action="click->${this.controllerName}#toggleTickets">Resolvidos</span>
                      <span class="mc-tooltip pointer" data-action="click->${this.controllerName}#addTicket" data-${this.controllerName}-target="addTicketBtn">
                        <span class="material-icons md-sm md-dark">add</span>
                        <span class="mc-tooltiptext">Adicionar Ticket</span>
                      </span>
                    </h6>
                    <hr class="my-1">
                  </div>
                </div>
                <div class="row" data-${this.controllerName}-target="ticketBoard">
                  <span class="w-100">${this.loader}</span>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
      if (controller.blockedAdd) {
        this.addTicketBtnTarget.remove()
      }
    })
  }

  doModalBoardHtml() {

    var html = `<div class="row" data-${this.controllerName}-target="ticketBoard">
                  <span class="w-100">${this.loader}</span>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.doDataTable()
    })
  }

  toggleTickets(ev) {
    var elementTarget = ev.currentTarget
    var feature = elementTarget.dataset.feature
    var subfeature = elementTarget.dataset.subfeature

    this.ticketLabelFilterTargets.forEach(element => {
      element.classList.remove("badge-secondary")
      element.classList.add("badge-secondary-dark")
    })

    elementTarget.classList.add("badge-secondary")
    elementTarget.classList.remove("badge-secondary-dark")

    if (feature == "in_process") {
      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)
    } else if (feature == "resolved") {
      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.resolved_tickets, `due_at_to_time`)
    } else {
      var tickets = []

      if (feature == `priority`) {
        this.in_process_tickets.forEach(element => {
          if (subfeature == element.priority) {
            tickets[tickets.length] = element
          }
        })
      } else if (feature == `due`) {
        var date = new Date()
        var date_previous = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        var date_next = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)

        if (subfeature == `today`) {
          this.in_process_tickets.forEach(element => {
            if (element.due_at_day == date.getDate() && element.due_at_month == (date.getMonth() + 1) && element.due_at_year == date.getFullYear()) {
              tickets[tickets.length] = element
            }
          })
        } else if (subfeature == `previous`) {
          this.in_process_tickets.forEach(element => {
            if ((element.due_at_to_time * 1000) < Date.parse(date_previous)) {
              tickets[tickets.length] = element
            }
          })
        } else if (subfeature == `next`) {
          this.in_process_tickets.forEach(element => {
            if ((element.due_at_to_time * 1000) >= Date.parse(date_next)) {
              tickets[tickets.length] = element
            }
          })
        }
      }

      tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(tickets, `due_at_to_time`)
    }

    // if (feature == "in_process") {
    //   this.inProcessTarget.classList.add("badge-secondary")
    //   this.inProcessTarget.classList.remove("badge-secondary-dark")
    //   this.resolvedTarget.classList.add("badge-secondary-dark")
    //   this.resolvedTarget.classList.remove("badge-secondary")
    //   var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)
    //   this.listData(tickets)
    // } else if (feature == "resolved") {
    //   this.inProcessTarget.classList.remove("badge-secondary")
    //   this.inProcessTarget.classList.add("badge-secondary-dark")
    //   this.resolvedTarget.classList.remove("badge-secondary-dark")
    //   this.resolvedTarget.classList.add("badge-secondary")
    //   var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.resolved_tickets, `due_at_to_time`)
    // }
    
    this.listData(tickets)
  }

  doTicketsArrays() {
    this.in_process_tickets = []
    this.resolved_tickets = []
    this.application.in_process_tickets = []
    this.application.resolved_tickets = []
    this.current_tickets.forEach(element => {

      if (this.checkDateBoard(this.ticket_board.board_subtype)) {
        if (element.date_id == this.application.current_date.id) {
          if (element.stage == `in_process`) {
            this.in_process_tickets[this.in_process_tickets.length] = element
            this.application.in_process_tickets[this.application.in_process_tickets.length] = element
          } else if (element.stage == `resolved`) {
            this.resolved_tickets[this.resolved_tickets.length] = element
            this.application.resolved_tickets[this.application.resolved_tickets.length] = element
          }
        }
      } else {
        if (element.stage == `in_process`) {
          this.in_process_tickets[this.in_process_tickets.length] = element
          this.application.in_process_tickets[this.application.in_process_tickets.length] = element
        } else if (element.stage == `resolved`) {
          this.resolved_tickets[this.resolved_tickets.length] = element
          this.application.resolved_tickets[this.application.resolved_tickets.length] = element
        }
      }
    })
  }

  doDataTable() {
    this.doTicketsArrays()

    var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.in_process_tickets, `due_at_to_time`)

    this.listData(tickets)
  }

  checkDateBoard(board) {

    var dateBoards = [`operation_tax_filings`, `financial_payables`, `financial_receivables`]

    if (dateBoards.includes(board)) {
      return true
    } else {
      return false
    }

  }

  listData(data) {
    this.ticketBoardTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<div class="row" style="height:200px;">
                      <div class="col-12 px-0 d-flex align-items-center justify-content-center">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Tickets</span>
                      </div>
                    </div>`

      this.ticketBoardTarget.innerHTML = noData
    } else {
      var place = 1
      var elements = []
      var columns = 4
      var rows = Math.floor(data.length / columns) + 1
      var row = 1
      if (data.length <= columns) {
        data.forEach(element => {
          elements[elements.length] = element
        })
        this.ticketBoardTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
      } else {
        data.forEach(element => {
          elements[elements.length] = element
          place += 1

          if (place > columns) {
            this.ticketBoardTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
            place = 1
            elements = []
            row += 1
          }
        })
        if (row == rows) {
          this.ticketBoardTarget.insertAdjacentHTML("beforeend", this.ticketPartial(elements))
        }
      }

    }

    // var bodyHeights = []
    // var maxHeight = 0
    // this.ticketCardBodyTargets.forEach(element => {
    //   if (element.offsetHeight > maxHeight) {
    //     maxHeight = element.offsetHeight  
    //   }
    //   bodyHeights[bodyHeights.length] = element.offsetHeight
    // })

    this.ticketCardBodyTargets.forEach(element => {
      element.style.height = 75 + `px`
    })
  }

  ticketPartial(elements) {
    var elementHtml = ``
    elements.forEach(element => {
      if (this.application.permissions[this.permission].can_update && (element.stage == `in_process`)) {
        var resolvedBtnHtml = `<button type="button" class="btn btn-primary btn-sm btn-table f-065 py-1 px-1" data-action="click->${this.controllerName}#resolvedTicket" data-${this.controllerName}-target="resolvedBtn-${element.id}">Resolvido</button>`
      } else {
        var resolvedBtnHtml = ``
      }

      if (this.application.permissions[this.permission].can_delete && (element.stage == `in_process`)) {
        var deleteBtnHtml = `<button type="button" class="btn btn-secondary btn-sm btn-table f-065 py-1 px-1" data-action="click->${this.controllerName}#cancelTicket" data-${this.controllerName}-target="cancelBtn-${element.id}">Cancelar</button>`
      } else {
        var deleteBtnHtml = ``
      }

      var saveBodyBtnHtml = `<button type="button" class="btn btn-primary btn-sm btn-table f-065 py-1 px-1 d-none" data-action="click->${this.controllerName}#saveBody" data-${this.controllerName}-target="saveBodyBtn-${element.id}">Salvar</button>`

      if (this.application.permissions[this.permission].can_update && (element.stage == `in_process`) && this.sharing) {
        if (element.sharing == `internal`) {
          var sharingBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow" data-action="click->${this.controllerName}#setSharing" data-sharing="external">
                            <span class="material-icons md-150 md-dark pointer">visibility_off</span>
                            <span class="mc-tooltiptext">Atividade ${element.sharing_pretty}</span>
                          </div>`
        } else if (element.sharing == `external`) {
          var sharingBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow" data-action="click->${this.controllerName}#setSharing" data-sharing="internal">
                            <span class="material-icons md-150 md-dark pointer">visibility</span>
                            <span class="mc-tooltiptext">Atividade ${element.sharing_pretty}</span>
                          </div>`
        }
      } else {
        var sharingBtn = ``
      }

      if (this.application.permissions[this.permission].can_update && (element.stage == `in_process`) && this.priority) {
        if (element.priority == "low") {
          var mdIcon = `minimize`
          var mdClass = `md-success`
        } else if (element.priority == "medium") {
          var mdIcon = `drag_handle`
          var mdClass = `md-yellow2`
        } else if (element.priority == "high") {
          var mdIcon = `menu`
          var mdClass = `md-orange2`
        } else if (element.priority == "maximum") {
          var mdIcon = `reorder`
          var mdClass = `md-red2`
        }

        var priorityBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-150 ${mdClass} pointer" data-toggle="collapse" href="#collapsePriority-${element.id}" data-ticket-id="${element.id}" data-priority="${element.priority}" data-action="click->${this.controllerName}#setCurrentPriority">${mdIcon}</span>
                              <span class="mc-tooltiptext">${element.priority_pretty} Prioridade</span>
                            </div>`

        var priorityBody = `<div id="collapsePriority-${element.id}" class="collapse" data-ticket-id="${element.id}" data-${this.controllerName}-target="priorityCollapse-${element.id}">
                              <div class="card m-1">
                                <div class="card-footer p-0">
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="lowpriority-${element.id}" data-id="${element.id}" data-priority="low" data-${this.controllerName}-target="lowpriority-${element.id} checkboxPriority" data-action="click->${this.controllerName}#savePriority">
                                        <label class="custom-control-label f-065" for="lowpriority-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Baixa Prioridade</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="mediumpriority-${element.id}" data-id="${element.id}" data-priority="medium" data-${this.controllerName}-target="mediumpriority-${element.id} checkboxPriority" data-action="click->${this.controllerName}#savePriority">
                                        <label class="custom-control-label f-065" for="mediumpriority-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Média Prioridade</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="highpriority-${element.id}" data-id="${element.id}" data-priority="high" data-${this.controllerName}-target="highpriority-${element.id} checkboxPriority" data-action="click->${this.controllerName}#savePriority">
                                        <label class="custom-control-label f-065" for="highpriority-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Alta Prioridade</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="maximumpriority-${element.id}" data-id="${element.id}" data-priority="maximum" data-${this.controllerName}-target="maximumpriority-${element.id} checkboxPriority" data-action="click->${this.controllerName}#savePriority">
                                        <label class="custom-control-label f-065" for="maximumpriority-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Máxima Prioridade</div>
                                  </div>
                                </div>
                              </div>
                            </div>`
      } else if (element.stage == `resolved`) {
        if (element.priority == "low") {
          var mdIcon = `minimize`
          var mdClass = `md-success`
        } else if (element.priority == "medium") {
          var mdIcon = `drag_handle`
          var mdClass = `md-yellow2`
        } else if (element.priority == "high") {
          var mdIcon = `menu`
          var mdClass = `md-orange2`
        } else if (element.priority == "maximum") {
          var mdIcon = `reorder`
          var mdClass = `md-red2`
        }

        var priorityBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 ${mdClass} md-dark">${mdIcon}</span>
                            <span class="mc-tooltiptext">${element.priority_pretty} Prioridade</span>
                          </div>`

        var priorityBody = ``
      } else {
        var priorityBtn = ``
        var priorityBody = ``
      }

      if (this.application.permissions[this.permission].can_update && (element.stage == `in_process`) && this.term) {
        if (element.term == "quick") {
          var mdIcon = `filter_1`
          var mdClass = `md-blue`
        } else if (element.term == "normal") {
          var mdIcon = `filter_3`
          var mdClass = `md-dark`
        } else if (element.term == "long") {
          var mdIcon = `filter_5`
          var mdClass = `md-warning`
        } else if (element.term == "large") {
          var mdIcon = `filter_9_plus`
          var mdClass = `md-danger`
        }

        var termBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                          <span class="material-icons md-150 ${mdClass} pointer" data-toggle="collapse" href="#collapseTerm-${element.id}" data-ticket-id="${element.id}" data-term="${element.term}" data-action="click->${this.controllerName}#setCurrentTerm">${mdIcon}</span>
                          <span class="mc-tooltiptext">${element.term_pretty}</span>
                        </div>`

        var termBody = `<div id="collapseTerm-${element.id}" class="collapse" data-ticket-id="${element.id}" data-${this.controllerName}-target="termCollapse-${element.id}">
                              <div class="card m-1">
                                <div class="card-footer p-0">
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="quickterm-${element.id}" data-id="${element.id}" data-term="quick" data-${this.controllerName}-target="quickterm-${element.id} checkboxTerm" data-action="click->${this.controllerName}#saveTerm">
                                        <label class="custom-control-label f-065" for="quickterm-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Rapidão | 1 min</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="normalterm-${element.id}" data-id="${element.id}" data-term="normal" data-${this.controllerName}-target="normalterm-${element.id} checkboxTerm" data-action="click->${this.controllerName}#saveTerm">
                                        <label class="custom-control-label f-065" for="normalterm-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Normal | 3 min</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="longterm-${element.id}" data-id="${element.id}" data-term="long" data-${this.controllerName}-target="longterm-${element.id} checkboxTerm" data-action="click->${this.controllerName}#saveTerm">
                                        <label class="custom-control-label f-065" for="longterm-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Longa | 5 min</div>
                                  </div>
                                  <div class="row my-1 py-1 job-item">
                                    <div class="col-1 px-0 text-center my-auto d-flex align-items-center">
                                      <div class="custom-control custom-checkbox px-2 w-50 d-flex">
                                        <input type="checkbox" class="custom-control-input" id="largeterm-${element.id}" data-id="${element.id}" data-term="large" data-${this.controllerName}-target="largeterm-${element.id} checkboxTerm" data-action="click->${this.controllerName}#saveTerm">
                                        <label class="custom-control-label f-065" for="largeterm-${element.id}"></label>
                                      </div>
                                    </div>           
                                    <div class="col-10 f-065 d-flex align-items-center">Demorada | +9 min</div>
                                  </div>
                                </div>
                              </div>
                            </div>`
      } else if (element.stage == `resolved`) {
        if (element.term == "quick") {
          var mdIcon = `filter_1`
          var mdClass = `md-blue`
        } else if (element.term == "normal") {
          var mdIcon = `filter_3`
          var mdClass = `md-dark`
        } else if (element.term == "long") {
          var mdIcon = `menu`
          var mdClass = `md-filter_5`
        } else if (element.term == "large") {
          var mdIcon = `filter_9_plus`
          var mdClass = `md-danger`
        }

        var termBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 ${mdClass} md-dark">${mdIcon}</span>
                            <span class="mc-tooltiptext">${element.term_pretty} Prioridade</span>
                          </div>`

        var termBody = ``
      } else {
        var termBtn = ``
        var termBody = ``
      }

      if (this.application.permissions[this.permission].can_update && (element.stage == `in_process`)) {
        var performerBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                              <span class="material-icons md-150 md-dark pointer" data-toggle="collapse" href="#collapsePerformer-${element.id}" data-ticket-id="${element.id}">person</span>
                              <span class="mc-tooltiptext">Executor da Atividade</span>
                            </div>`

        var performerBody = `<div id="collapsePerformer-${element.id}" class="collapse" data-ticket-id="${element.id}" data-${this.controllerName}-target="performerCollapse-${element.id}">
                              <div class="card m-1">
                                <div class="card-footer p-0">
                                  <div class="row p-1 d-flex align-items-center">
                                    <div class="col-12 px-0 d-flex align-items-center pointer f-065 mc-tooltip" data-${this.controllerName}-target="performerNameShow-${element.id}" data-action="click->${this.controllerName}#inlineEditPerformerName">
                                      <span data-${this.controllerName}-target="performerNameShowSpan-${element.id}">${element.performer_name}</span>
                                      <span class="mc-tooltiptext">Responsável</span>
                                    </div>
                                    <div class="col-12 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="performerNameEdit-${element.id}">
                                      <div class="form-group w-100">
                                        <div class="floating-label floating-label-sm">
                                          <label for="performerNameForm-${element.id}">Nome Responsável</label>
                                          <input id="performerNameForm-${element.id}" aria-describedby="performerNameForm-${element.id}-Help" class="form-control f-075" autofocus data-${this.controllerName}-target="performerNameEditInput-${element.id}" data-filter-mode="simple" data-action="focus->${this.controllerName}#performerNameFilter keyup->${this.controllerName}#performerNameFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterPerformerName" type="text" required>
                                          <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="performerNameFilter-${element.id}"></ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>`
      } else if (element.stage == `resolved`) {
        var performerBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 md-dark">person</span>
                            <span class="mc-tooltiptext">${element.performer_name}</span>
                          </div>`

        var performerBody = ``
      } else {
        var performerBtn = ``
        var performerBody = ``
      }

      if (this.application.permissions[this.permission].can_list) {
        if (element.total_comments > 0) {
          var commentBadge = `<span class="badge badge-danger notice-badge" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
        } else {
          var commentBadge = `<span class="badge badge-danger notice-badge d-none" data-${this.controllerName}-target="totalComments-${element.id}">${element.total_comments}</span>`
        }
        var commentBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                            <span class="material-icons md-150 md-dark pointer" data-toggle="collapse" href="#collapseComments-${element.id}" data-ticket-id="${element.id}" data-action="click->${this.controllerName}#doCommentHtml">comment</span>
                            ${commentBadge}
                            <span class="mc-tooltiptext">Comentários</span>
                          </div>`

        var commentBody = `<div id="collapseComments-${element.id}" class="collapse" data-ticket-id="${element.id}" data-controller="users--works--activities--shared--comments" data-users--works--activities--shared--comments-target="collapse-${element.id}"></div>`
      } else {
        var commentBtn = ``
        var commentBody = ``
      }

      if (element.stage == `in_process`) {
        var dateBtn = `<div class="col-4 mr-auto px-1 d-flex align-items-center pointer bg-shadow mc-tooltip" data-toggle="collapse" href="#collapseDate-${element.id}" data-ticket-id="${element.id}">
                        <span>${element.due_at_pretty}</span>
                        <span class="mc-tooltiptext">Data de Entrega</span>
                      </div>`
        var dateBody = `<div id="collapseDate-${element.id}" class="collapse" data-ticket-id="${element.id}" data-${this.controllerName}-target="collapseDate-${element.id}">
                        <div class="card m-1">
                          <div class="card-footer p-0">
                            <div class="col-12 p-1 d-flex align-items-center pointer f-065" data-ticket-id="${element.id}" data-${this.controllerName}-target="dateShowDiv-${element.id}" data-action="click->${this.controllerName}#inlineEditDate">
                              <span data-${this.controllerName}-target="dateShow-${element.id}">${element.due_at_pretty}</span>
                              <span class="material-icons md-sm pointer ml-auto" data-action="click->${this.controllerName}#inlineEditDate">edit</span>
                            </div>
                            <div class="col-12 p-1 d-flex align-items-center d-none f-065" data-${this.controllerName}-target="dateEdit-${element.id}">
                              <input autofocus data-${this.controllerName}-target="dateEditInput-${element.id}" class="form-control p-1 f-065" type="text" required>
                              <span class="material-icons md-sm pointer mx-2" data-${this.controllerName}-target="saveDateBtn-${element.id}" data-action="click->${this.controllerName}#saveDate">save</span>
                            </div>
                          </div>
                        </div>
                      </div>`
      } else if (element.stage == `resolved`) {
        var dateBtn = `<div class="col-4 mr-auto px-1 d-flex align-items-center pointer bg-shadow mc-tooltip">
                        <span>${element.due_at_pretty}</span>
                        <span class="mc-tooltiptext">Data de Entrega</span>
                      </div>`

        var dateBody = ``
      } else {
        var dateBtn = ``
        var dateBody = ``
      }

      if (element.stage == `in_process` && this.kind) {
        var kindBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                        <span class="material-icons md-150 md-dark pointer" data-toggle="collapse" href="#collapseKind-${element.id}" data-ticket-id="${element.id}">touch_app</span>
                        <span class="mc-tooltiptext">${element.kind_pretty}</span>
                      </div>`
        var kindBody = `<div id="collapseKind-${element.id}" class="collapse" data-ticket-id="${element.id}" data-${this.controllerName}-target="kindCollapse-${element.id}">
                          <div class="card m-1">
                            <div class="card-footer p-0">
                              <div class="row p-1 d-flex align-items-center">
                                <div class="col-12 px-0 d-flex align-items-center pointer f-065 mc-tooltip" data-${this.controllerName}-target="kindShow-${element.id}" data-action="click->${this.controllerName}#inlineEditKind">
                                  <span data-${this.controllerName}-target="kindShowSpan-${element.id}">${element.kind_pretty}</span>
                                  <span class="mc-tooltiptext">Tipo Ticket</span>
                                </div>
                                <div class="col-12 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="kindEdit-${element.id}">
                                  <div class="form-group w-100">
                                    <div class="floating-label floating-label-sm">
                                      <label for="kindForm-${element.id}">Tipo Ticket</label>
                                      <input id="kindForm-${element.id}" aria-describedby="kindForm-${element.id}-Help" class="form-control f-075" autofocus data-${this.controllerName}-target="kindEditInput-${element.id}" data-filter-mode="simple" data-action="focus->${this.controllerName}#kindFilter keyup->${this.controllerName}#kindFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterKind" type="text" required>
                                      <ul class="ul-filter filter-list d-none w-100" style="z-index:1" data-${this.controllerName}-target="kindFilter-${element.id}"></ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>`
      } else if (element.stage == `resolved`) {
        var kindBtn = `<div class="col-1 mx-1 px-0 d-flex align-items-center justify-content-center mc-tooltip bg-shadow">
                        <span class="material-icons md-150 md-dark pointer">touch_app</span>
                        <span class="mc-tooltiptext">${element.kind_pretty}</span>
                      </div>`

        var kindBody = ``
      } else {
        var kindBtn = ``
        var kindBody = ``
      }

      var colClass = ``
      if (this.itemsOnRow == 3) {
        colClass = `col-4`
      } else if (this.itemsOnRow == 4) {
        colClass = `col-3`
      } else if (this.itemsOnRow == 5) {
        colClass = `col-2`
      }

      elementHtml += `<div class="${colClass} px-1 mb-2">
                        <div class="card border-flag-${element.flag}" data-id="${element.id}">
                          <div class="card-header p-1 text-center f-065 d-flex" style="height:40px;">
                            ${dateBtn}
                            ${kindBtn}
                            ${sharingBtn}
                            ${performerBtn}
                            ${priorityBtn}
                            ${termBtn}
                            ${commentBtn}
                          </div>
                          <div class="card-body p-1 text-center f-065" data-${this.controllerName}-target="ticketCardBody">
                            <div class="row py-1 px-2">
                              <div class="col-12 px-0 d-flex align-items-center pointer text-left ticket-content" data-${this.controllerName}-target="bodyShow-${element.id}" data-action="click->${this.controllerName}#inlineEditBody">${element.body}</div>
                              <div class="col-12 px-1 d-flex align-items-center d-none" data-${this.controllerName}-target="bodyEdit-${element.id}">
                                <textarea autofocus data-${this.controllerName}-target="bodyEditInput-${element.id}" data-action="keyup->${this.controllerName}#saveBody change->${this.controllerName}#saveBody blur->${this.controllerName}#saveBody" class="form-control p-1 s-title-0p6rem" type="text" required></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer border-top text-right py-1 px-2">
                            ${deleteBtnHtml}
                            ${resolvedBtnHtml}
                            ${saveBodyBtnHtml}
                          </div>
                          ${dateBody}
                          ${kindBody}
                          ${performerBody}
                          ${priorityBody}
                          ${termBody}
                          ${commentBody}
                        </div>
                      </div>`
    })

    var html = elementHtml

    return html
  }

  setSharing(ev) {
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    if (this.userCanUpdate(ticketId)) {
      if (ev.currentTarget.dataset.sharing == `external`) {
        var r = confirm(`Confirma o compartilhamento do Ticket? Se sim, o cliente receberá um email de aviso.`)
        if (r) {
          this.saveSharing(ev)
        }
      } else {
        this.saveSharing(ev)
      }
    }
  }

  saveSharing(ev) {
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    var ticket = {}
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    this.actionMode = `edit`

    this.current_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })

    this.send_data.ticket.id = ticketId
    this.send_data.ticket.sharing = ev.currentTarget.dataset.sharing

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    if (ev.currentTarget.dataset.sharing == `external` && (this.application.current_user.account_kind == `admin` || this.application.current_user.account_kind == `team`)) {
      this.sendTicketEmailNotification(ticket)
    }

    this.requestSave()
  }

  setCurrentPriority(ev) {
    var checkElement = ev.currentTarget
    var ticketId = checkElement.dataset.ticketId
    var priority = checkElement.dataset.priority

    if (priority == `low`) {
      this.nameTarget(`lowpriority-${ticketId}`).checked = true
    } else if (priority == `medium`) {
      this.nameTarget(`mediumpriority-${ticketId}`).checked = true
    } else if (priority == `high`) {
      this.nameTarget(`highpriority-${ticketId}`).checked = true
    } else if (priority == `maximum`) {
      this.nameTarget(`maximumpriority-${ticketId}`).checked = true
    }
  }

  setCurrentTerm(ev) {
    var checkElement = ev.currentTarget
    var ticketId = checkElement.dataset.ticketId
    var term = checkElement.dataset.term

    if (term == `quick`) {
      this.nameTarget(`quickterm-${ticketId}`).checked = true
    } else if (term == `normal`) {
      this.nameTarget(`normalterm-${ticketId}`).checked = true
    } else if (term == `long`) {
      this.nameTarget(`longterm-${ticketId}`).checked = true
    } else if (term == `large`) {
      this.nameTarget(`largeterm-${ticketId}`).checked = true
    }
  }

  savePriority(ev) {
    var checkElement = ev.currentTarget
    var ticketId = checkElement.dataset.id
    var priority = checkElement.dataset.priority

    this.checkboxPriorityTargets.forEach(element => {
      element.checked = false
    })
    checkElement.checked = true

    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    this.actionMode = `edit`

    this.send_data.ticket.id = ticketId
    this.send_data.ticket.priority = priority
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  saveTerm(ev) {
    var checkElement = ev.currentTarget
    var ticketId = checkElement.dataset.id
    var term = checkElement.dataset.term

    this.checkboxTermTargets.forEach(element => {
      element.checked = false
    })
    checkElement.checked = true

    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    this.actionMode = `edit`

    this.send_data.ticket.id = ticketId
    this.send_data.ticket.term = term
    this.send_data.tracker.action = `updated`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.requestSave()
  }

  listPerformerName(id) {
    var html = ``
    this.application.team_list.forEach(element => {
      html += `<li data-performer-id="${id}" data-action="click->${this.controllerName}#selectPerformerName" data-${this.controllerName}-target="userNameFilterItem-${id}" data-text="${element.name}" data-filter="${element.id}" class="li-selector dark f-065">${element.name}</li>`
    })

    this.nameTarget(`performerNameFilter-${id}`).innerHTML = html
  }

  performerNameFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectPerformerName(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.savePerformerName(ev)
  }

  enterPerformerName(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.savePerformerName(ev)
    }
  }

  inlineEditPerformerName(ev) {
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId

    this.listPerformerName(ticketId)
    if (this.userCanUpdate(ticketId)) {
      if (ev.type == "click") {
        var value = this.nameTarget(`performerNameShowSpan-${ticketId}`).innerText
        this.nameTarget(`performerNameShow-${ticketId}`).classList.add("d-none")
        this.nameTarget(`performerNameEdit-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`performerNameEditInput-${ticketId}`).value = value
        this.nameTarget(`performerNameEditInput-${ticketId}`).focus()
      } else {
        this.nameTarget(`performerNameShow-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`performerNameEdit-${ticketId}`).classList.add("d-none")
      }
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  savePerformerName(ev) {
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId

    var value = this.nameTarget(`performerNameEditInput-${ticketId}`).value.trim()

    if (value != this.nameTarget(`performerNameShowSpan-${ticketId}`).innerText.trim()) {
      this.actionMode = `edit`

      this.send_data.ticket.id = ticketId
      this.send_data.ticket.performer_id = this.nameTarget(`performerNameEditInput-${ticketId}`).dataset.filter
      this.send_data.ticket.performer_name = this.nameTarget(`performerNameEditInput-${ticketId}`).dataset.text

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission
      this.send_data.tracker.action = `updated`
      
      this.requestSave()
    } else {
      var performer = { id: ticketId, performer_name: value }
      this.closeEditPerformerName(performer)
    }
  }

  closeEditPerformerName(performer) {
    this.nameTarget(`performerNameShow-${performer.id}`).classList.remove("d-none")
    this.nameTarget(`performerNameEdit-${performer.id}`).classList.add("d-none")
    this.nameTarget(`performerNameShowSpan-${performer.id}`).innerText = performer.performer_name
  }

  listKind(id) {
    var html = ``

    html += `<li data-kind-id="${id}" data-action="click->${this.controllerName}#selectKind" data-text="Atividade" data-filter="task" class="li-selector dark f-065">Atividade</li>
             <li data-kind-id="${id}" data-action="click->${this.controllerName}#selectKind" data-text="Ligação" data-filter="call" class="li-selector dark f-065">Ligação</li>
             <li data-kind-id="${id}" data-action="click->${this.controllerName}#selectKind" data-text="E-mail" data-filter="mail" class="li-selector dark f-065">E-mail</li>
             <li data-kind-id="${id}" data-action="click->${this.controllerName}#selectKind" data-text="Mensagem" data-filter="message" class="li-selector dark f-065">Mensagem</li>
             <li data-kind-id="${id}" data-action="click->${this.controllerName}#selectKind" data-text="Documento" data-filter="document" class="li-selector dark f-065">Documento</li>`

    this.nameTarget(`kindFilter-${id}`).innerHTML = html
  }

  kindFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  selectKind(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
    this.saveKind(ev)
  }

  enterKind(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
      this.saveKind(ev)
    }
  }

  inlineEditKind(ev) {
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId

    this.listKind(ticketId)
    if (this.userCanUpdate(ticketId)) {
      if (ev.type == "click") {
        var value = this.nameTarget(`kindShowSpan-${ticketId}`).innerText
        this.nameTarget(`kindShow-${ticketId}`).classList.add("d-none")
        this.nameTarget(`kindEdit-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`kindEditInput-${ticketId}`).value = value
        this.nameTarget(`kindEditInput-${ticketId}`).focus()
      } else {
        this.nameTarget(`kindShow-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`kindEdit-${ticketId}`).classList.add("d-none")
      }
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  saveKind(ev) {
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId

    var value = this.nameTarget(`kindEditInput-${ticketId}`).value.trim()

    if (value != this.nameTarget(`kindShow-${ticketId}`).innerText.trim()) {
      this.actionMode = `edit`

      this.send_data.ticket.id = ticketId
      this.send_data.ticket.kind = this.nameTarget(`kindEditInput-${ticketId}`).dataset.filter

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission
      this.send_data.tracker.action = `updated`

      this.requestSave()
    } else {
      var obj = { id: ticketId, value: value }
      this.closeEditKind(obj)
    }
  }

  closeEditKind(obj) {
    this.nameTarget(`kindShow-${obj.id}`).classList.remove("d-none")
    this.nameTarget(`kindEdit-${obj.id}`).classList.add("d-none")
    this.nameTarget(`kindShow-${obj.id}`).innerText = obj.value
  }

  doCommentHtml(ev) {
    var ticketId = ev.currentTarget.dataset.ticketId
    this.application.controllers.find(controller => {
      if (controller.context.identifier == `users--works--activities--shared--comments` && controller.element.id == `collapseComments-${ticketId}`) {
        controller.ticket_id = ticketId
        controller.doCommentHtml()
      }
    })
  }

  inlineEditDate(ev) {
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId
    var date = new Date()
    if (this.userCanUpdate(ticketId)) {
      if (ev.type == "click") {
        var value = this.nameTarget(`dateShow-${ticketId}`).innerText
        this.nameTarget(`dateShowDiv-${ticketId}`).classList.add("d-none")
        this.nameTarget(`dateEdit-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`dateEditInput-${ticketId}`).value = value
        this.nameTarget(`dateEditInput-${ticketId}`).focus()
      } else {
        this.nameTarget(`dateShowDiv-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`dateEdit-${ticketId}`).classList.add("d-none")
      }
    }

    this.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.nameTarget(`dateEditInput-${ticketId}`)), "yearly", date.getFullYear())
  }

  cancelDate(ev) {
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId
    this.nameTarget(`dateShowDiv-${ticketId}`).classList.remove("d-none")
    this.nameTarget(`dateEdit-${ticketId}`).classList.add("d-none")
  }

  saveDate(ev) {
    var ticketId = ev.currentTarget.closest(".collapse").dataset.ticketId
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }
    this.nameTarget(`saveDateBtn-${ticketId}`).disabled = true
    this.actionMode = `edit`
    var value = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.nameTarget(`dateEditInput-${ticketId}`).value)

    if (value == `` || value == undefined) {
      this.cancelDate(ev)
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Data de Entrega não pode ficar em branco`, 2000)
    } else {
      this.send_data.ticket.id = ticketId
      this.send_data.ticket.due_at = value

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission
      this.send_data.tracker.action = `updated`

      this.requestSave()
      this.nameTarget(`dateShow-${ticketId}`).innerText = this.nameTarget(`dateEditInput-${ticketId}`).value
      this.cancelDate(ev)
    }
  }


  inlineEditBody(ev) {
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    if (this.userCanUpdate(ticketId)) {
      if (ev.type == "click") {
        var value = this.nameTarget(`bodyShow-${ticketId}`).innerText
        this.nameTarget(`bodyShow-${ticketId}`).classList.add("d-none")
        this.nameTarget(`bodyEdit-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`bodyEditInput-${ticketId}`).value = value
        this.nameTarget(`bodyEditInput-${ticketId}`).focus()

        this.nameTarget(`resolvedBtn-${ticketId}`).classList.add("d-none")
        this.nameTarget(`cancelBtn-${ticketId}`).classList.add("d-none")
        this.nameTarget(`saveBodyBtn-${ticketId}`).classList.remove("d-none")
      } else {
        this.nameTarget(`bodyShow-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`bodyEdit-${ticketId}`).classList.add("d-none")

        this.nameTarget(`resolvedBtn-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`cancelBtn-${ticketId}`).classList.remove("d-none")
        this.nameTarget(`saveBodyBtn-${ticketId}`).classList.add("d-none")
      }
    }
  }

  saveBody(ev) {
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    this.actionMode = `edit`
    if ((ev.type == "keyup" && ev.key == "Escape" && ev.shiftKey == false)) {
      this.nameTarget(`bodyEditInput-${ticketId}`).value = this.nameTarget(`bodyShow-${ticketId}`).innerText
      this.inlineEditBody(ev)
    } else if ((ev.type == "keyup" && ev.key == "Enter" && ev.shiftKey == false) || (ev.type == "blur" && ev.target.parentElement.classList.contains("d-none") == false)) {
      var value = this.nameTarget(`bodyEditInput-${ticketId}`).value.trim().replace("\n", "")
      if (value == `` || value == undefined) {
        this.inlineEditBody(ev)
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A Descrição não pode ficar em branco`, 2000)
      } else {
        this.inlineEditBody(ev)
        if (value != this.nameTarget(`bodyShow-${ticketId}`).innerText) {
          this.nameTarget(`bodyShow-${ticketId}`).innerText = value
          this.send_data = { current_user: {}, ticket: {}, tracker: {} }

          this.send_data.ticket.id = ticketId
          this.send_data.ticket.body = value

          this.send_data.current_user.current_user_id = this.application.current_user.id
          this.send_data.current_user.feature = this.permission
          this.send_data.tracker.action = `updated`

          this.requestSave()
        }
      }
    }
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) || this.application.current_user.super_admin) {
      return true
    } else {
      return false
    }
  }

  cancelTicket(ev) {
    this.actionMode = `edit`
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    if (this.userCanUpdate(ticketId)) {
      var r = confirm("Certeza que deseja cancelar/apagar o Ticket?")
      if (r) {
        this.send_data = { current_user: {}, ticket: {}, tracker: {} }
        ev.currentTarget.disabled = true

        this.send_data.ticket.id = ticketId
        this.send_data.ticket.active = false
        this.send_data.ticket.stage = `canceled`

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.permission
        this.send_data.tracker.action = `canceled`

        this.requestSave()
      }
    }
  }

  resolvedTicket(ev) {
    this.actionMode = `edit`
    var ticketId = ev.currentTarget.closest(".card").dataset.id
    if (this.userCanUpdate(ticketId)) {
      var r = confirm("Confirma a resolução do Ticket?")
      if (r) {
        this.send_data = { current_user: {}, ticket: {}, tracker: {} }
        ev.currentTarget.disabled = true

        this.send_data.ticket.id = ticketId
        this.send_data.ticket.stage = `resolved`
        this.send_data.ticket.finished_at = new Date()

        this.send_data.current_user.current_user_id = this.application.current_user.id
        this.send_data.current_user.feature = this.permission
        this.send_data.tracker.action = `resolved`

        this.requestSave()
      }
    }
  }

  createTicket() {
    this.actionMode = `new`
    if (this.hasAddTicketBtnTarget) {
      this.addTicketBtnTarget.disabled = true
    }
    this.send_data = { current_user: {}, ticket: {}, tracker: {} }

    this.send_data.ticket.board_id = this.ticket_board.board_id
    this.send_data.ticket.board_type = this.ticket_board.board_type
    this.send_data.ticket.board_subtype = this.ticket_board.board_subtype
    this.send_data.ticket.board_path = this.ticket_board.board_path
    this.send_data.ticket.board_name = this.ticket_board.board_name
    this.send_data.ticket.date_id = this.ticket_board.date_id
    this.send_data.ticket.date_type = this.ticket_board.date_type
    this.send_data.ticket.owner_id = this.application.current_user.account_id
    this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.performer_id = this.application.current_user.account_id
    this.send_data.ticket.performer_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.stage = `in_process`
    this.send_data.ticket.status = `in_time`
    this.send_data.ticket.sharing = `internal`
    this.send_data.ticket.term = `quick`
    this.send_data.ticket.priority = `maximum`
    this.send_data.ticket.due_at = new Date()
    this.send_data.ticket.started_at = new Date()
    this.send_data.ticket.flag = `blue`
    this.send_data.ticket.kind = `task`
    this.send_data.ticket.obj_id = this.ticket_board.obj_id
    this.send_data.ticket.obj_type = this.ticket_board.obj_type

    if (this.ticket_board.board_body) {
      this.send_data.ticket.body = this.ticket_board.board_body
    } else {
      this.send_data.ticket.body = `Novo Ticket`
    }
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.permission

    this.send_data.tracker.action = `created`
    
    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new`) {
      var url = `/users/works/activities/tickets/create`
      var method = "POST"
    } else {
      var url = `/users/works/activities/tickets/update`
      var method = "PUT"
    }

    this.send_data.tracker.log = true
    this.send_data.tracker.record_id = this.ticket_board.board_id
    this.send_data.tracker.record_type = this.ticket_board.board_type
    

    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var ticket = response.data.cln
          if (controller.actionMode == `new`) {
            controller.application.tickets[controller.application.tickets.length] = ticket
          } else {
            if (ticket.active) {
              controller.application.tickets.forEach((element, i) => {
                if (element.id == ticket.id) {
                  controller.application.tickets.splice(controller.application.tickets.indexOf(element), 1, ticket)
                }
              })

              if (controller.application.filter_tickets) {
                controller.application.filter_tickets.forEach((element, i) => {
                  if (element.id == ticket.id) {
                    controller.application.filter_tickets.splice(controller.application.filter_tickets.indexOf(element), 1, ticket)
                  }
                })
              }
            } else {
              controller.application.tickets.forEach((element, i) => {
                if (element.id == ticket.id) {
                  controller.application.tickets.splice(controller.application.tickets.indexOf(element), 1)
                }
              })

              if (controller.application.filter_tickets) {
                controller.application.filter_tickets.forEach((element, i) => {
                  if (element.id == ticket.id) {
                    controller.application.filter_tickets.splice(controller.application.filter_tickets.indexOf(element), 1)
                  }
                })
              }
            }
          }

          if (controller.viewMode == `modal`) {
            if (ticket.active) {
              controller.current_tickets = [ticket]
              controller.doModalBoardHtml()
            } else {
              if (controller.getControllerByIdentifier(`users--works--activities--shared--modal`)) {
                controller.getControllerByIdentifier(`users--works--activities--shared--modal`).close()
              } else if (controller.getControllerByIdentifier(`users--works--activities--shared--show-modal`)) {
                controller.getControllerByIdentifier(`users--works--activities--shared--show-modal`).close()
              }
            }
          } else {
            controller.current_tickets = controller.application.tickets
            controller.addTicketBtnTarget.disabled = false
            controller.doDataTable()
          }

          if (controller.ticket_count_controller) {
            controller.ticket_count_controller.doTicketsCount()
          }

        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendTicketEmailNotification(ticket) {
    this.send_notification_data = { current_user: {}, notification: {} }

    this.send_notification_data.current_user.current_user_id = this.application.current_user.id
    this.send_notification_data.current_user.feature = this.permission
    

    if (this.application.current_date && this.application.current_date.id) {
      this.send_notification_data.notification.date_id = this.application.current_date.id
      this.send_notification_data.notification.date_type = `operation_dates`
    }

    this.send_notification_data.notification.ticket_id = ticket.id
    this.send_notification_data.notification.product_id = this.product.id
    this.send_notification_data.notification.product_token = this.product.token
    this.send_notification_data.notification.product_name = this.product.product_name
    this.send_notification_data.notification.receiver_id = this.product.account_id
    this.send_notification_data.notification.receiver_name = this.product.account_name
    this.send_notification_data.notification.receiver_kind = `doctor`
    this.send_notification_data.notification.sender_id = this.application.current_user.id
    this.send_notification_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_notification_data.notification.action = `adicionou o seguinte Ticket`
    this.send_notification_data.notification.body = ticket.body

    var url = "/users/works/activities/tickets/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_notification_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doTicketPreloader() {
    var loader = `<div class='card timeline-item m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-5'></div></div>`

    var html = `<div class="row my-3">
                  <div class="col-3 px-1">
                    <div class="card border-top-primary">
                      <div class="card-header p-1 text-center f-065">
                        <span>${loader}</span>
                      </div>
                      <div class="card-body p-1 text-center f-065">
                        <span class="my-2">${loader}</span>
                        <span class="my-2">${loader}</span>
                        <span class="my-2">${loader}</span>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      // if (len == 0) {
      //   controller.resolvedBtnTarget.disabled = false
      // } else {
      //   controller.resolvedBtnTarget.disabled = true
      // }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
