import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formDocumentCard", "formTitle", "formDocumentBody", "documentDropdown", "documentInput", "documentList", "amount", "numberInput",
    "date", "social", "reviewedCheckbox", "approvedCheckbox", "grossInput", "withheldInput", "socialInput", "saveBtn", "deleteBtn", "approvedInput", "reviewedInput",
    "uploadFile", "labelUploadFile", "fileNameFile", "progressUploadFile", "progressUploadFileBar", "progressUploadFileCounter",
    "fileNameFile", "idName", "reviewNotes", "reviewed", "approved", "showFile", "fileNameFileShow", "fileUrl", "cancelUploadBtn",
    "processedCheckbox", "processedInput", "processed", "idNumber", "documentActions", "reviewNotesInput", "idNameInput",
    "documentMember", "memberCheckbox", "dependentSelect", "dependentDropdown", "dependentInput", "dependentList"]

  connect() {
    this.controllerName = `operations--products--tax-returns--documents--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-returns--documents--show").doViewHtml()
  }

  saveUpload(ev) {
    if (ev.target.files.length > 0) {
      this.file = ev.target.files[0]
      this.fileNameFileTarget.innerText = ev.target.files[0].name
    }
  }

  saveDocument() {
    var formData = new FormData()
    formData.append('file', this.file)

    formData.append('tax_return_id', this.application.tax_return.id)
    formData.append('date_id', this.application.current_date.id)
    formData.append('chart_id', this.documentInputTarget.dataset.id)
    formData.append('chart_group', this.documentInputTarget.dataset.group)
    formData.append('id_name', this.idNameTarget.value)
    formData.append('review_notes', this.reviewNotesTarget.value)
    formData.append('reviewed', this.reviewedCheckboxTarget.checked)
    formData.append('approved', this.approvedCheckboxTarget.checked)
    formData.append('processed', this.processedCheckboxTarget.checked)
    formData.append('member_id', this.dependentInputTarget.dataset.dependentid)

    if (this.idNumberTarget.value.length == 14) {
      formData.append('id_type', "individual")
      formData.append('id_number', this.getControllerByIdentifier("app--helpers--numbers").fromCpfToNumber(this.idNumberTarget.value))
    } else if (this.idNumberTarget.value.length == 18) {
      formData.append('id_type', "legal")
      formData.append('id_number', this.getControllerByIdentifier("app--helpers--numbers").fromCnpjToNumber(this.idNumberTarget.value))
    }
    
    if (this.actionMode == "edit") {
      formData.append('id', this.document.id)
    }

    formData.append('current_user_id', this.application.current_user.id)

    if (this.actionMode == "new") {
      var action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} adicionou um documento ${this.documentInputTarget.dataset.chart}`
    } else {
      var action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} atualizou o documento ${this.documentInputTarget.dataset.chart}`
    }

    formData.append('domain_id', this.application.tax_return.id)
    formData.append('domain_type', "operation_products")
    formData.append('date_id', this.application.current_date.id)
    formData.append('date_type', "operation_dates")
    formData.append('kind', `mode_${this.actionMode}`)
    formData.append('notification_action', action)
    formData.append('user_name', this.application.current_user.name)
    formData.append('user_id', this.application.current_user.id)

    this.requestSave(formData)
  }

  deleteDocument() {

  }

  requestSave(formData) {
    this.stopRefreshing()
    this.saveBtnTarget.disabled = true
    this.saveBtnTarget.classList.add("d-none")
    const token = $('meta[name=csrf-token]').attr('content');
    if (this.actionMode == "edit") {
      var url = "/operations/products/tax_returns/documents/update"
      var method = "PUT"
    } else if (this.actionMode == "new") {
      var url = "/operations/products/tax_returns/documents/create"
      var method = "POST"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token }, body: formData }

    this.progressUploadFileTarget.classList.remove("d-none")
    this.progressUploadFileCounterTarget.classList.remove("d-none")
    this.labelUploadFileTarget.classList.remove("btn-primary")
    this.labelUploadFileTarget.classList.add("btn-disabled")
    this.progressCount(0)
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.progressCount(100)
        controller.stopRefreshing()
        if (response.save) {
          var document = response.data.cln
          controller.cancelSave()
          if (this.actionMode == "edit") {
            if (document.active) {
              this.application.documents.forEach((element, i) => {
                if (element.id == document.id) {
                  this.application.documents.splice(this.application.documents.indexOf(element), 1, document)
                }
              })
            } else {
              this.application.documents.forEach((element, i) => {
                if (element.id == document.id) {
                  this.application.documents.splice(this.application.documents.indexOf(element), 1)
                }
              })
            }
          } else {
            this.application.documents[this.application.documents.length] = document
          }
          this.getControllerByIdentifier("operations--products--tax-returns--documents--index").doDataTable()
        } else {
          this.saveBtnTarget.disabled = false
          this.saveBtnTarget.classList.remove("d-none")
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }


  saveFormHtml(mode, document) {
    this.actionMode = mode
    this.document = document

    if (this.application.medreturn_documents.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm" data-action="click->${this.controllerName}#saveDocument" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    if (this.application.medreturn_documents.can_delete && this.actionMode == "edit") {
      var deleteBtnHtml = `<button type="button" class="btn btn-danger btn-sm" data-action="click->${this.controllerName}#deleteDocument" data-${this.controllerName}-target="deleteBtn">Apagar</button>`
    } else {
      var deleteBtnHtml = ``
    }
    var deleteBtnHtml = ``

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formDocumentCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-4" data-${this.controllerName}-target="formDocumentBody">
                    <div class="row my-3 d-flex align-items-center" data-${this.controllerName}-target="documentActions">
                      <div class="col-4 px-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="reviewedCheck" data-${this.controllerName}-target="reviewedCheckbox">
                          <label class="custom-control-label f-065" for="reviewedCheck">Revisão</label>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="approvedCheck" data-${this.controllerName}-target="approvedCheckbox">
                          <label class="custom-control-label f-065" for="approvedCheck">Aprovado</label>
                        </div>
                      </div>
                      <div class="col-4 px-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex">
                          <input type="checkbox" class="custom-control-input" id="processedCheck" data-${this.controllerName}-target="processedCheckbox">
                          <label class="custom-control-label f-065" for="processedCheck">Processado</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 pr-1 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Classifique o Pagamento</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="documentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="documentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="documentList">
                                  <li data-source="" class="li-selector dark">Classifique o Documento</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2" data-${this.controllerName}-target="idNameInput">                      
                      <div class="col-6 pl-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome do Beneficiário</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="idName" placeholder="Nome do Beneficiário" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 pr-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="idNumberForm">CPF/CNPJ</label>
                            <input aria-describedby="idNumberFormHelp" class="form-control form-valid-control" id="idNumberForm" data-${this.controllerName}-target="idNumber" data-action="blur->${this.controllerName}#idNumberMask keypress->${this.controllerName}#idNumberMask" placeholder="CPF da Fonte" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2" data-${this.controllerName}-target="reviewNotesInput">
                      <div class="col-12 pr-1 pl-0">
                        <div class="form-group form-valid-group">
                          <div class="floating-label floating-label-sm">
                            <label for="reviewNotesForm">Notas de Revisão</label>
                            <input aria-describedby="reviewNotesFormHelp" class="form-control form-valid-control" id="reviewNotesForm" data-${this.controllerName}-target="reviewNotes" placeholder="Notas de Revisão" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="uploadFile">
                        <div class="form-group form-valid-group my-0 text-center">
                          <input type="file" id="documentFile" name="documentFile" class="file-input" data-action="change->${this.controllerName}#saveUpload">
                          <label for="documentFile" id="documentFileLabelForm" class="btn btn-primary btn-sm" data-${this.controllerName}-target="labelUploadFile" style="font-size: 80%;">Comprovante</label>
                          <span class="fileNameForm" class="mx-2"></span>
                          <h4 class="progress" style="display: none">Progress: <span class="progress_count"></span>%</h4>
                          <div class="progress d-none" style="height: 6px;overflow: inherit;" data-${this.controllerName}-target="progressUploadFile">
                            <div class="progress-bar" role="progressbar" style="width:0%;border-bottom:0.5rem solid #053B5E;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-${this.controllerName}-target="progressUploadFileBar"></div>
                          </div>
                          <span class="d-none" data-${this.controllerName}-target="progressUploadFileCounter" class="d-block"></span>
                        </div>
                        <span data-${this.controllerName}-target="fileNameFile" class="d-block"></span>
                        <button type="button" class="close ml-3 d-none" data-dismiss="modal" data-action="click->${this.controllerName}#cancelUpload" data-${this.controllerName}-target="cancelUploadBtn" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="col-12 d-flex align-items-center justify-content-between d-none" data-${this.controllerName}-target="showFile">
                        <span data-${this.controllerName}-target="fileNameFileShow" class="d-block"></span>
                        <button data-action="click->${this.controllerName}#goToFile" data-${this.controllerName}-target="fileUrl" data-url="" type="button" class="btn btn-sm btn-table p-0"><span class="material-icons md-sm md-dark">launch</span></button>
                        <button data-action="click->${this.controllerName}#editFile" type="button" class="btn btn-sm btn-table p-0" data-toggle="tooltip" data-placement="top" title data-original-title="Editar"><span class="material-icons md-sm md-dark">edit</span></button>
                      </div>
                    </div>
                    <div class="row my-3 d-flex align-items-center d-none" data-${this.controllerName}-target="documentMember">
                      <div class="col-4 pr-1 d-flex">
                        <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0">
                          <input type="checkbox" class="custom-control-input" id="documentMemberCheck" data-${this.controllerName}-target="memberCheckbox" data-action="click->${this.controllerName}#showDependents">
                          <label class="custom-control-label" for="documentMemberCheck">Titular</label>
                        </div>
                      </div>
                      <div class="col-8 d-none" data-${this.controllerName}-target="dependentSelect">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Dependente</label>
                            <div class="dropdown dropdown-selector dropdown-valid-selector" data-${this.controllerName}-target="dependentDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;"><span class="mr-auto w-100 selected-item" id="selected" data-${this.controllerName}-target="dependentInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector dropdown-search-input" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="dependentList"></ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${deleteBtnHtml}
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-returns--documents--dashboard").viewTarget.innerHTML = html)
    }).then(() => {
      controller.listDependents()
      controller.doChartAccountList()
      if (controller.actionMode == "new") {
        controller.formDocumentCardTarget.style.height = ($(window).height() * 0.5) + "px"
        controller.formTitleTarget.innerText = "Novo Documento"
        controller.uploadFileTarget.classList.remove("d-none")
        controller.showFileTarget.classList.add("d-none")
        controller.documentActionsTarget.classList.add("d-none")
        controller.reviewNotesInputTarget.classList.add("d-none")
        controller.idNameInputTarget.classList.add("d-none")
      } else if (controller.actionMode == "edit") {
        controller.formTitleTarget.innerText = "Editando Documento"
        controller.documentActionsTarget.classList.remove("d-none")
        controller.reviewNotesInputTarget.classList.remove("d-none")
        controller.idNameInputTarget.classList.remove("d-none")
        controller.reviewedCheckboxTarget.checked = controller.document.reviewed
        controller.approvedCheckboxTarget.checked = controller.document.approved
        controller.processedCheckboxTarget.checked = controller.document.processed
        controller.documentInputTarget.dataset.id = controller.document.chart_id
        controller.documentInputTarget.dataset.group = controller.document.chart_group
        controller.documentInputTarget.dataset.chart = controller.document.chart_pretty
        controller.documentDropdownTarget.value = controller.document.chart_group
        controller.documentInputTarget.innerText = controller.document.chart_pretty
        controller.reviewNotesTarget.value = controller.document.reviewNotes
        controller.idNameTarget.value = controller.document.id_name
        controller.idNumberTarget.value = controller.document.id_number_pretty
        if (controller.document.has_file) {
          controller.uploadFileTarget.classList.add("d-none")
          controller.cancelUploadBtnTarget.classList.add("d-none")
          controller.showFileTarget.classList.remove("d-none")
          controller.fileNameFileShowTarget.innerText = controller.document.file_name
          controller.fileUrlTarget.dataset.url = controller.document.file_url
        } else {
          controller.uploadFileTarget.classList.remove("d-none")
          controller.cancelUploadBtnTarget.classList.remove("d-none")
          controller.showFileTarget.classList.add("d-none")
        }
        if (controller.document.member_kind == "dependent") {
          controller.dependentInputTarget.dataset.dependentid = controller.document.member_id
          controller.dependentDropdownTarget.value = controller.document.member_name
          controller.dependentInputTarget.innerText = controller.document.member_name
          controller.dependentSelectTarget.classList.remove("d-none")
          controller.memberCheckboxTarget.checked = false
        }
      }
      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      selector()
    })
  }

  doChartAccountList() {
    var listHtml = ``
    this.application.chart_accounts.forEach(element => {
      if (element.master == "expenses" || element.master == "incomes") {
        listHtml += `<li data-id="${element.id}" data-group="${element.group}" data-chart="${element.chart}" class="li-selector dark">${element.chart}</li>`
      }
    });
    this.documentListTarget.innerHTML = listHtml
  }

  goToFile(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }

  editFile(ev) {
    this.uploadFileTarget.classList.remove("d-none")
    this.cancelUploadBtnTarget.classList.remove("d-none")
    this.showFileTarget.classList.add("d-none")
  }

  cancelUpload(ev) {
    this.uploadFileTarget.classList.add("d-none")
    this.cancelUploadBtnTarget.classList.add("d-none")
    this.showFileTarget.classList.remove("d-none")
  }

  idNumberMask(ev) {
    if (ev.type == "blur") {
      if (ev.target.value.length < 15) {
        this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
      } else {
        this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
      }  
    } else {
      if (ev.target.value.length < 14) {
        this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCpf(ev)
      } else {
        this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCnpj(ev)
      } 
    }
  }

  listDependents() {
    this.current_dependent_members = []
    this.current_members = []

    this.application.members.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        if (element.kind == "dependent") {
          this.current_dependent_members[this.current_dependent_members.length] = element
        } else {
          this.member = element
        }
        this.current_members[this.current_members.length] = element
      }
    });

    if (this.current_dependent_members.length > 0) {
      var listHtml = ``
      this.current_dependent_members.forEach(element => {
        listHtml += `<li data-dependent-id="${element.id}" class="li-selector dark">${element.account_name}</li>`
      })
      this.dependentListTarget.innerHTML = listHtml
      this.dependentInputTarget.dataset.dependentid = this.member.id
      this.memberCheckboxTarget.checked = true
      this.documentMemberTarget.classList.remove("d-none")
    } else {
      this.dependentInputTarget.dataset.dependentid = this.application.members[0].id
      this.documentMemberTarget.classList.add("d-none")
    }
  }

  showDependents(ev) {
    if (ev.target.checked) {
      this.dependentSelectTarget.classList.add("d-none")
      this.dependentInputTarget.dataset.dependentid = this.member.id
    } else {
      this.dependentSelectTarget.classList.remove("d-none")
    }
  }

  progressCount(value) {
    var controller = this
    this.progressUploadFileBarTarget.style.width = value + `%`
    var i = 0
    if (value != 100) {
      this.progressTimer = setInterval(function () {
        controller.progressUploadFileBarTarget.style.width = Math.floor(i + 1) + `%`
        controller.progressUploadFileCounterTarget.innerText = Math.floor(i + 1) + `%`
        i++
        if (i == 95) {
          i = 94
        }
      }, 500);
    }
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
    if (this.progressTimer) {
      clearInterval(this.progressTimer)
    }
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      if (controller.documentDropdownTarget.value == "" || controller.documentDropdownTarget.value == undefined) {
        len += 1
      }
      if (controller.documentInputTarget.innerText == "") {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
