import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainDashboard", "save", "view", "list", "content", "reportCard", "dateList", "generalCard", "dailyChartCard", "accrualChartCard",
                    "cancelCard", "general", "dailyChart", "accrualChart", "cancel", "reasonCancel", "sourceCancel", "dateCard", "reportContent", "asdf",
                    "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf", "asdf"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--index--reports--dashboard`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.reportCard = `general`
  }

  doPageGrid() {

    var html = `<div class="row my-4 w-100">
                  <div class="col-2"></div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard generalCard" data-report="general" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Geral</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 pr-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard dailyChartCard" data-report="daily" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Evolução Por Dia</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard accrualChartCard" data-report="accrual" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Evolução Acumulada</strong></h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer" data-${this.controllerName}-target="reportCard cancelCard" data-report="cancel" data-action="click->${this.controllerName}#toggleReport">
                      <div class="card-header py-0 f-065 text-center">
                        <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Análise Cancelamentos</strong></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" data-${this.controllerName}-target="dateList" style="overflow:auto;"></div>
                  <div class="col-10" data-${this.controllerName}-target="reportContent">
                    
                  </div>
                </div>`


                    // `<div class="row d-none" data-${this.controllerName}-target="general">
                    //   <div class="col-12 px-1">
                    //     <h6 class="mb-0 text-center">Dashboard Geral</h6>
                    //     <hr class="m-1">
                    //   </div>
                    // </div>
                    // <div class="row">
                    //   <div class="col-12" data-${this.controllerName}-target="dailyChart"></div>
                    // </div>
                    // <div class="row">
                    //   <div class="col-12 d-none" data-${this.controllerName}-target="accrualChart"></div>
                    // </div>
                    // <div class="row d-none" data-${this.controllerName}-target="cancel">
                    //   <div class="col-12 px-1">
                    //     <h6 class="mb-0 text-center">Cancelamentos por Motivos</h6>
                    //     <hr class="m-1">
                    //   </div>
                    //   <div class="col-12 px-0">
                    //     <div class="row" data-${this.controllerName}-target="reasonCancel"></div>
                    //   </div>
                    //   <div class="col-12 px-1 mt-3">
                    //     <h6 class="mb-0 text-center">Cancelamentos por Tomador</h6>
                    //     <hr class="m-1">
                    //   </div>
                    //   <div class="col-12 px-0">
                    //     <div class="row" data-${this.controllerName}-target="sourceCancel"></div>
                    //   </div>
                    // </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.dateListTarget.style.height = ($(window).height() * 0.5) + "px"
      // controller.itemsOnPage = 100
      // controller.pageNumber = 0
      // controller.numberPages = []
      // controller.sort = { mode: `desc`, field: `finished_at_to_time` }
      controller.reportCardTargets[0].classList.add(`bg-primary`)
      
      controller.doDateList()
      controller.setReport()
    })
  }

  doPageGridLoader() {

    var html = `<div class="row my-4 w-100">
                  <div class="col-2"></div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 pr-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                  <div class="col-2 px-2">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" data-${this.controllerName}-target="dateList" style="overflow:auto;">
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                    <div class="card pointer mb-2"><div class="card-header py-0 f-065 text-center"><h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${this.loader}</strong></h6></div></div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.dates = []
      controller.setDateData()
    })
  }

  setDateData() {
    var firstDate = new Date(2020,10,1)
    var currentDate = new Date()
    var firstMonth = firstDate.getMonth() + 1
    var currentMonth = currentDate.getMonth() + 1
    var firstYear = firstDate.getFullYear()
    var currentYear = currentDate.getFullYear()
    var monthDiff = 0
    var yearDiff = 0

    if (firstDate.getFullYear() == currentDate.getFullYear()) {
      monthDiff = Number(currentMonth - firstMonth)
    } else if (firstDate.getFullYear() < currentDate.getFullYear()) {
      yearDiff = Number(currentYear - firstYear)
      monthDiff = Number(currentMonth - firstMonth + (yearDiff * 12))
    }

    for (var index = 0; index <= monthDiff; index++) {
      var date = new Date(firstYear, firstMonth - 1 + index)
      this.dates.push(
                                   { year: date.getFullYear(),
                                     month: date.getMonth() + 1,
                                     name_short: `${this.getControllerByIdentifier(`app--helpers--date`).getShortMonth(date.getMonth())}/${date.getFullYear()}`,
                                     token: `${date.getFullYear()}${this.getControllerByIdentifier(`app--helpers--date`).getOrderedMonth(date.getMonth()) }`
                                   }
                                  )
                                  
    }

    this.current_date = this.dates[this.dates.length - 1]
    this.doPageGrid()
  }

  doDateList() {
    var listHtml = ``

    this.dates = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.dates, `token`)

    this.dates.forEach(element => {
      listHtml += `<div class="card pointer mb-2" data-${this.controllerName}-target="dateCard" data-year="${element.year}" data-month="${element.month}" data-token="${element.token}" data-action="click->${this.controllerName}#toggleDate">
                    <div class="card-header py-1 f-065 text-center">
                      <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>${element.name_short}</strong></h6>
                    </div>
                  </div>`
    })

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dateListTarget.innerHTML = listHtml)
    }).then(() => {
      controller.dateCardTargets[0].classList.add(`bg-primary`)
    })
  }

  toggleDate(ev) {
    this.dateCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    this.dates.forEach(element => {
      if (ev.currentTarget.dataset.token == element.token) {
        this.current_date = element
      }
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.setReport()
  }

  toggleReport(ev) {
    this.reportCard = ev.currentTarget.dataset.report

    this.reportCardTargets.forEach(element => {
      element.classList.remove(`bg-primary`)
    })

    ev.currentTarget.classList.add(`bg-primary`)

    this.setReport()
  }

  setReport() {
    // this.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--dashboard`)
    this.reportContentTarget.dataset.controller = ``

    var controller = this
    if (this.reportCard == `general`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--clinics--financials--index--reports--general`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--general`).doReport()
      })
    } else if (this.reportCard == `daily`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--clinics--financials--index--reports--daily`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--daily`).doReport()
      })
    } else if (this.reportCard == `accrual`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--clinics--financials--index--reports--accrual`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--accrual`).doReport()
      })
    } else if (this.reportCard == `cancel`) {
      new Promise(function (resolve) {
        resolve(controller.reportContentTarget.dataset.controller = `operations--products--clinics--financials--index--reports--cancellation`)
      }).then(() => {
        controller.getControllerByIdentifier(`operations--products--clinics--financials--index--reports--cancellation`).doReport()
      })
    }
  }

  doMainDashboard() {
    var html = `<div class="row" data-${this.controllerName}-target="mainDashboard">
                  <div class="col-8" data-operations--products--clinics--financials--index--reports--receivables--dashboard-target="content" data-controller="operations--products--clinics--financials--index--reports--receivables--dashboard"></div>
                  <div class="col-4" data-operations--products--clinics--financials--index--reports--logs--dashboard-target="content" data-controller="operations--products--clinics--financials--index--reports--logs--dashboard"></div>
                </div>`

    // this.getControllerByIdentifier("operations--products--clinics--financials--index--dashboard").contentTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--products--clinics--financials--index--dashboard`).contentTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier(`operations--products--clinics--financials--index--submenu`).dashboardToggleBtn(`RELATÓRIOS`)
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
