
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "result"]

  connect() {
    this.controllerName = `commercial--marketing--landings--show--submenu`
  }

  goTo(ev) {
    var url = ev.target.dataset.url
    window.open(url, '_self');
  }

  goToDashboard() {
    
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--landings--show--dashboard").doDashboardHtml())
    }).then(() => {
      controller.getControllerByIdentifier("commercial--marketing--landings--show--tickets").doDataTable()
    })
  }

  goToConversions() {
    this.getControllerByIdentifier("commercial--marketing--landings--conversions--dashboard").doConversionGrid()
  }

  goToCreative() {
    this.getControllerByIdentifier("commercial--marketing--landings--show--creative").doCreativeGrid()
  }

  goToFields() {
    this.getControllerByIdentifier("commercial--marketing--landings--show--fields").doFieldsGrid()
  }

  goToForm() {
    this.getControllerByIdentifier("commercial--marketing--landings--show--form").doFormGrid()
  }

  goToLinks() {
    this.getControllerByIdentifier("commercial--marketing--landings--show--links").doLinkGrid()
  }

  doSubmenuHtml() {

    var dashboardLink = `<span data-action="click->${this.controllerName}#goToDashboard" class="dropdown-item py-1 pointer dropdown-submenu-item">Dashboard</span>`
    var conversionsLink = `<span data-action="click->${this.controllerName}#goToConversions" class="dropdown-item py-1 pointer dropdown-submenu-item">Conversões</span>`
    var creativeLink = `<span data-action="click->${this.controllerName}#goToCreative" class="dropdown-item py-1 pointer dropdown-submenu-item">Criativo</span>`
    var fieldsLink = `<span data-action="click->${this.controllerName}#goToFields" class="dropdown-item py-1 pointer dropdown-submenu-item">Campos</span>`
    var formLink = `<span data-action="click->${this.controllerName}#goToForm" class="dropdown-item py-1 pointer dropdown-submenu-item">Formulário</span>`
    var linkLink = `<span data-action="click->${this.controllerName}#goToLinks" class="dropdown-item py-1 pointer dropdown-submenu-item">Links</span>`


    var submenuHtml = `<div class="dropdown mc-tooltip">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><i class="material-icons">apps</i></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${dashboardLink}
                          ${conversionsLink}
                          ${creativeLink}
                          ${fieldsLink}
                          ${formLink}
                          ${linkLink}
                        </div>
                        <span class="mc-tooltiptext">Submenu</span>
                      </div>`

    this.mainTarget.innerHTML = submenuHtml
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 



}
