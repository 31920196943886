import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["contractTitle", "contractBoard", "3333", "4444", "5555", "6666", "7777", "8888", "9999"]

  connect() {
    this.numberController = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.controllerName = `financials--books--payables--dashboard--contracts`
  }

  doContractCard() {
    if (this.application.current_date) {
      var title = `<span class="mc-tooltip">
                    <span>Contratos ${this.application.current_med.name} - ${this.application.current_date.name}</span>
                    <span class="mc-tooltiptext">Atualizar Indicadores</span>
                  </span>`

      this.contractTitleTarget.innerHTML = title
    }

    // `${this.numberController.currencyOptionMask(this.calculation.total_paid_amount)}`

    var html = `<div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Contratos</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Contratos Ativos</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.numberMask(this.calculation.account_count)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 my-2 px-2 text-center">
                  <span class="f-075 f-bold">Tipos de Contratos - ${this.calculation.partner} sócios +</span>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Estágio</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.numberMask(this.calculation.intern)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>CLT</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.numberMask(this.calculation.labor_law)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 my-2 px-2">
                  <div class="card">
                    <div class="card-header p-1 text-center f-070">
                      <span>Autônomo</span>
                    </div>
                    <div class="card-body text-center f-070 px-1 py-2">
                      <div class="row d-flex align-items-center mb-1">
                        <div class="col-12 text-center f-075 px-1">
                          ${this.numberController.numberMask(this.calculation.self_employed)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    this.contractBoardTarget.innerHTML = html
  }

  setContractCalculation() {
    this.current_contracts = []

    if (this.application.current_med.id) {
      this.application.contracts.forEach(element => {
        if (element.med_id == this.application.current_med.id && element.started_date_token <= this.application.current_date.date_token) {
          if (element.finished && element.closing_date_token >= this.application.current_date.date_token) {
            this.current_contracts[this.current_contracts.length] = element
          } else if (element.finished == false) {
            this.current_contracts[this.current_contracts.length] = element
          }
        }
      })
    } else {
      this.application.contracts.forEach(element => {
        if (element.started_date_token <= this.application.current_date.date_token) {
          if (element.finished && element.closing_date_token >= this.application.current_date.date_token) {
            this.current_contracts[this.current_contracts.length] = element
          } else if (element.finished == false) {
            this.current_contracts[this.current_contracts.length] = element
          }
        }
      })
    }

    this.calculation = {}

    this.calculation.account_count = 0
    var uniq_contract_account_ids = this.mapElements(this.current_contracts, `account_id`).filter(this.onlyUnique)

    this.calculation.intern = 0
    this.calculation.labor_law = 0
    this.calculation.self_employed = 0
    this.calculation.partner = 0

    var intern_contracts = []
    var labor_law_contracts = []
    var self_employed_contracts = []
    var partner_contracts = []
    this.current_contracts.forEach(element => {
      if (element.kind == `intern`) {    
        intern_contracts[intern_contracts.length] = element
      } else if (element.kind == `labor_law`) {
        labor_law_contracts[labor_law_contracts.length] = element
      } else if (element.kind == `self_employed`) {
        self_employed_contracts[self_employed_contracts.length] = element
      } else if (element.kind == `partner`) {
        partner_contracts[partner_contracts.length] = element
      }
    })

    var uniq_contract_intern_account_ids = this.mapElements(intern_contracts, `account_id`).filter(this.onlyUnique)
    var uniq_contract_labor_law_account_ids = this.mapElements(labor_law_contracts, `account_id`).filter(this.onlyUnique)
    var uniq_contract_self_employed_account_ids = this.mapElements(self_employed_contracts, `account_id`).filter(this.onlyUnique)
    var uniq_contract_partner_account_ids = this.mapElements(partner_contracts, `account_id`).filter(this.onlyUnique)

    // this.uniq_board_types = this.all_board_names.filter((value, index, self) => self.map(x => x.type).indexOf(value.type) == index)

    uniq_contract_account_ids.forEach(element => {
      this.calculation.account_count += 1
    })

    uniq_contract_intern_account_ids.forEach(element => {
      this.calculation.intern += 1
    })

    uniq_contract_labor_law_account_ids.forEach(element => {
      this.calculation.labor_law += 1
    })

    uniq_contract_self_employed_account_ids.forEach(element => {
      this.calculation.self_employed += 1
    })

    uniq_contract_partner_account_ids.forEach(element => {
      this.calculation.partner += 1
    })

    this.doContractCard()
  }

  goToContracts() {
    this.getControllerByIdentifier("financials--books--payrolls--contracts--dashboard").doContractsDashboard()
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    })
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  getTargetByIdentifier(target) {
    var targetElement = ``
    this.application.controllers.forEach(controller => {
      if (controller.targets.find(target)) {
        targetElement = controller.targets.find(target)
      }
    })

    return targetElement
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  confirm() {
    var r = confirm("Confirma a resolução do Ticket?")
    if (r) {
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    })
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  leadingZeros(number, size) {
    var s = String(number);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
