import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "view", "mainCard", "bodyTable", "footerTable", "checkboxShow", "clearListBtn", "optionBtn"]

  connect() {
    this.controllerName = `operations--products--clinics--financials--receivables--index`
    this.tableCols = 15
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `issued_on_to_time` }
    this.hasShow = false

    if (this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--financials--receivables--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--financials--show--submenu")
      this.receivableController = this.getControllerByIdentifier("operations--products--clinics--financials--add--dashboard")
    } else if (this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard")) {
      this.dashboardController = this.getControllerByIdentifier("operations--products--clinics--clients--receivables--dashboard")
      this.submenuController = this.getControllerByIdentifier("operations--products--clinics--clients--show--submenu")
      this.receivableController = this.getControllerByIdentifier("operations--products--clinics--clients--receivables--add--dashboard")
    }
  }

  showReceivable(ev) {
    var controllerShow = this.getControllerByIdentifier("operations--products--clinics--financials--add--show")
    var receivableId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.all_receivables.forEach(element => {
      if (element.id == receivableId) {
        controllerShow.current_receivable = element
        controllerShow.doRevisionFormHtml()
      }
    })
  }

  addReceivable() {
    if (this.application.current_date.open == false) {
      alert(`Competência está fechada. Favor abrí-la para emitir um novo Recibo PJ/Nota Fiscal.`)
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para emitir um novo Recibo PJ/Nota Fiscal.`, 5000)
    } else {
      if (this.application.canReceivable) {
        this.receivableController.doReceivablesDashboard()
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A PJ não está regularizada para emissão de Nota Fiscal`, 4000)
      }
    }
  }

  editReceivable(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.receivables.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--financials--receivables--save").current_receivable = element
      }
    })
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--save").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--save").doFormHtml()
  }

  updateStatus(ev) {
    var id = ev.currentTarget.closest(".itemRow").dataset.id
    this.application.receivables.forEach(element => {
      if (element.id == id) {
        this.getControllerByIdentifier("operations--products--clinics--financials--receivables--update-status").current_receivable = element
      }
    })
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--update-status").permission = this.permission
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--update-status").actionMode = "edit"
    this.getControllerByIdentifier("operations--products--clinics--financials--receivables--update-status").doFormHtml()
  }

  refactTransactions(ev) {
    if (this.application.current_date.open == false) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para apagar o Recibo PJ.`, 5000)
      alert(`Competência está fechada. Favor abrí-la para apagar o Recibo PJ.`)
    } else {
      var receivableId = ev.currentTarget.closest(".itemRow").dataset.id
      var providerName = ev.currentTarget.closest(".itemRow").dataset.providerName
      var takerName = ev.currentTarget.closest(".itemRow").dataset.takerName
      var amount = ev.currentTarget.closest(".itemRow").dataset.amount

      this.send_data = { current_user: {}, receivable: {}, tracker: { log: true } }
      this.send_data.receivable.id = receivableId

      this.send_data.tracker.action = `refact`
      this.send_data.tracker.record_id = this.application.clinic.id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.application.current_date.id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.observations = `Transações da Nota Fiscal da ${providerName} para ${takerName} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var r = confirm(`Tem certeza que deseja atualizar as Transações do Recibo PJ?`)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.actionMode = `edit`
        this.requestFecth()
      }
    }
  }

  replaceReceivable22(ev) {
    if (this.application.current_date.open == false) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para substituir a NF.`, 5000)
      alert(`Competência está fechada. Favor abrí-la para substituir a NF.`)
    } else {
      var receivableId = ev.currentTarget.closest(".itemRow").dataset.id
      var providerName = ev.currentTarget.closest(".itemRow").dataset.providerName
      var takerName = ev.currentTarget.closest(".itemRow").dataset.takerName
      var amount = ev.currentTarget.closest(".itemRow").dataset.amount
      var invoiceNumber = ev.currentTarget.closest(".itemRow").dataset.invoiceNumber

      this.send_data = { current_user: {}, receivable: {}, tracker: { log: true } }
      this.send_data.receivable.id = receivableId

      this.send_data.tracker.action = `refact`
      this.send_data.tracker.record_id = this.application.clinic.id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.application.current_date.id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.observations = `Substituição da Nota Fiscal ${invoiceNumber} da ${providerName} para ${takerName} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var r = confirm(`Tem certeza que deseja substituir a Nota Fiscal?`)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.actionMode = `replace`
        this.requestFecth()
      }
    }
  }

  replaceReceivable(ev) {
    if (this.application.current_date.open == false) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para substituir a NF.`, 5000)
      alert(`Competência está fechada. Favor abrí-la para substituir a NF.`)
    } else {
      if (this.application.canReceivable) {
        var receivableId = ev.currentTarget.closest(".itemRow").dataset.id
        var takerId = ev.currentTarget.closest(`.itemRow`).dataset.takerId
        var takerType = ev.currentTarget.closest(`.itemRow`).dataset.takerType

        var replace_receivable = {}
        this.application.canceled_receivables.forEach(element => {
          if (element.id == receivableId) {
            replace_receivable = element
          }
        })

        var addDashboardController = {}
        if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)) {
          var addDashboardController = this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)
        } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)) {
          var addDashboardController = this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)
        }

        var controller = this
        new Promise(function (resolve) {
          resolve(addDashboardController.doReceivablesDashboard())
        }).then(() => {
          setTimeout(() => {
            if (this.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--dashboard`)) {
              controller.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`).replace_receivable = replace_receivable
              controller.getControllerByIdentifier(`operations--products--clinics--clients--receivables--add--save`).replaceMode = true
            } else if (this.getControllerByIdentifier(`operations--products--clinics--financials--add--dashboard`)) {
              controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`).replace_receivable = replace_receivable
              controller.getControllerByIdentifier(`operations--products--clinics--financials--add--save`).replaceMode = true
            }

            controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).actionMode = `edit`
            controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).kind = takerType
            controller.getControllerByIdentifier(`operations--products--clinics--financials--add--takers`).reviewTaker(takerId)
          }, 200);
        })
      } else {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `A PJ não está regularizada para emissão de Nota Fiscal`, 4000)
      }
    }
  }

  destroyReceivable(ev) {
    if (this.application.current_date.open == false) {
      this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(`danger`, `Competência está fechada. Favor abrí-la para apagar o Recibo PJ.`, 5000)
      alert(`Competência está fechada. Favor abrí-la para apagar o Recibo PJ.`)
    } else {
      var receivableId = ev.currentTarget.closest(".itemRow").dataset.id
      var providerName = ev.currentTarget.closest(".itemRow").dataset.providerName
      var takerName = ev.currentTarget.closest(".itemRow").dataset.takerName
      var amount = ev.currentTarget.closest(".itemRow").dataset.amount

      this.send_data = { current_user: {}, receivable: {}, tracker: { log: true } }
      this.send_data.receivable.id = receivableId
      this.send_data.receivable.active = false

      this.send_data.tracker.action = `destroyed`
      this.send_data.tracker.record_id = this.application.clinic.id
      this.send_data.tracker.record_type = `operation_products`
      this.send_data.tracker.obj_id = this.application.current_date.id
      this.send_data.tracker.obj_type = `operation_dates`
      this.send_data.tracker.observations = `Nota Fiscal da ${providerName} para ${takerName} no valor de ${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)}`

      this.send_data.current_user.current_user_id = this.application.current_user.id
      this.send_data.current_user.feature = this.permission

      var r = confirm(`Tem certeza que deseja apagar o Recibo PJ?`)
      if (r) {
        this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
        this.actionMode = `destroy`
        this.requestFecth()
      }
    }
  }

  requestFecth() {
    if (this.actionMode == `edit`) {
      var url = "/operations/products/clinics/financials/receivables/refact_transactions"
      var method = "PUT"
    } else if (this.actionMode == `destroy`) {
      var url = "/operations/products/clinics/financials/receivables/destroy"
      var method = "DELETE"
    }
    var controller = this
    var init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var receivable = response.data.cln
          controller.application.receivables.forEach((element, i) => {
            if (element.id == receivable.id) {
              if (controller.actionMode == `destroy`) {
                controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1)
              } else if (controller.actionMode == `edit`) {
                controller.application.receivables.splice(controller.application.receivables.indexOf(element), 1, receivable)
              } else if (controller.actionMode == `replace`) {
                controller.application.receivables[controller.application.receivables.length] = receivable
              }
            }
          })
        }
        controller.doDataTable()
        controller.submenuController.doReceivablesCount()

        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  doIndexListHtml() {

    if (this.application.permissions[this.permission].can_create) {
      var addLine = `<span data-action="click->${this.controllerName}#addReceivable" class="dropdown-item py-1 pointer dropdown-submenu-item">Adicionar Recibo PJ</span>`
    } else {
      var addLine = ``
    }

    var refreshLine = `<span data-action="click->${this.controllerName}#refreshReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Atualizar Recibos</span>`
    var cancelLine = `<span data-action="click->${this.controllerName}#cancelReceivables" class="dropdown-item py-1 pointer dropdown-submenu-item">Listar Recibos Cancelados</span>`

    var clearBtnHtml = `<span class="mc-tooltip">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0 d-none" data-${this.controllerName}-target="clearListBtn" data-action="click->${this.controllerName}#clearList" type="button">
                            <span class="material-icons md-dark">clear</span>
                          </button>
                          <span class="mc-tooltiptext">Limpar</span>
                        </span>`

    var submenuHtml = `<div class="dropdown mc-tooltip" data-${this.controllerName}-target="optionBtn">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline my-0" data-toggle="dropdown" id="submenuMenuDrop" type="button"><span class="material-icons md-dark">more_vert</span></button>
                        <div aria-labelledby="submenuMenuDrop" class="dropdown-menu dropdown-menu-right menu" style="font-size:80%">
                          ${addLine}
                          ${refreshLine}
                          ${cancelLine}
                        </div>
                        <span class="mc-tooltiptext">Opções</span>
                      </div>`

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 card-title-table-list">Todos os Recibos / Notas Fiscais</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-0">
                      ${clearBtnHtml}
                      ${submenuHtml}
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                          <thead>
                            <tr>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">#</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle">Data</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-15 align-middle">Tomador</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle">Tipo</th>
                              
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr. Bruto</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Imp. Retidos</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Outras Retenções</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-8 align-middle text-center">Vlr. Líquido</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Status</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-5 align-middle text-center">Nota Fiscal</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">PDF</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center">XML</th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                              <th style="font-size:80%;" scope="col" class="pointer p-1 table-3 align-middle text-center"></th>
                            </tr>
                          </thead>
                          <tbody data-${this.controllerName}-target="bodyTable">
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.dashboardController.contentTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.6) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableCols, 10))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_receivables = []
    var selected_receivables = []

    if (this.cancelInvoices) {
      selected_receivables = this.application.canceled_receivables
    } else {
      selected_receivables = this.application.receivables
    }

    if (this.application.current_user.account_kind == `helper`) {
      selected_receivables.forEach(receivable => {
        if (receivable.taker_type == `individual` && receivable.date_id == this.application.current_date.id) {
          current_receivables[current_receivables.length] = receivable
        }
      })
    } else if (this.application.current_user.account_kind == `doctor`) {
      selected_receivables.forEach(receivable => {
        if (receivable.date_id == this.application.current_date.id) {
          var partnerIds = this.mapElements(receivable.performers, `partner_id`)
          if (partnerIds.includes(this.application.current_partner.id)) {
            current_receivables[current_receivables.length] = receivable
          }
        }
      })
    } else {
      selected_receivables.forEach(receivable => {
        if (receivable.date_id == this.application.current_date.id) {
          current_receivables[current_receivables.length] = receivable
        }
      })
    }


    if (this.sort.mode == `asc`) {
      var receivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_receivables, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var receivables = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_receivables, this.sort.field)
    }

    if (receivables.length == 0) {
      var pagination = { itemsOnPage: 0, fromItem: 0, toItem: 0, totalItems: 0 }
      this.listData()
      this.doFooterTable(pagination)
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(receivables.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (receivables[x] !== undefined) {
            internPage[internPage.length] = receivables[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: receivables.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var message = ``
      if (this.cancelInvoices) {
        message += `Não há Recibos / Notas Fiscais Cancelados na ${this.application.current_date.name}`
      } else {
        message += `Não há Recibos / Notas Fiscais na ${this.application.current_date.name}`
      }

      var noData = `</tr>
                      <td colspan="${this.tableCols}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">${message}</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.receivableTablePartial(element, data.length))
      });
    }

    
  }

  receivableTablePartial(element, length) {
    if (this.application.permissions[this.permission].can_update) {
      var edit = `<button data-action="click->${this.controllerName}#editReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">edit</span>
                    <span class="mc-tooltiptext">Editar</span>
                  </button>`
    } else {
      var edit = ``
    }

    if (element.invoice_status == `in_process` || element.invoice_status == `uploaded_invoice`) {
      var destroy = ``
    } else if (this.application.permissions[this.permission].can_manage && (element.invoice_status != `emitted` && element.invoice_status != `canceled`)) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">delete</span>
                      <span class="mc-tooltiptext">Apagar</span>
                    </button>`
    } else if (this.application.permissions[this.permission].can_delete && (element.invoice_status != `emitted` && element.invoice_status != `canceled`) && element.integration_job == `error`) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else if (this.application.current_user.super_admin && element.invoice_status != `emitted`) {
      var destroy = `<button data-action="click->${this.controllerName}#destroyReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                    <span class="material-icons md-sm md-dark">delete</span>
                    <span class="mc-tooltiptext">Apagar</span>
                  </button>`
    } else {
      var destroy = ``
    }

    if (this.application.current_user.super_admin) {
      var refact = `<button data-action="click->${this.controllerName}#refactTransactions" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">redo</span>
                      <span class="mc-tooltiptext">Refazer Transações</span>
                    </button>`
    } else {
      var refact = ``
    }

    if (this.application.permissions[this.permission].can_create) {
      if (element.invoice_status == `canceled` && (this.application.current_user.account_kind == `team` || this.application.current_user.account_kind == `admin`)) {
        var replace = `<button data-action="click->${this.controllerName}#replaceReceivable" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                        <span class="material-icons md-sm md-dark">change_circle</span>
                        <span class="mc-tooltiptext">Substituir NF</span>
                      </button>`
      } else if (element.invoice_status == `substituted`) {
        var replace = `<span class="mc-tooltip">
                        <span class="material-icons md-200 md-success">change_circle</span>
                        <span class="mc-tooltiptext">NF Substituída</span>
                      </span>`
      } else {
        var replace = ``
      }
    } else {
      var replace = ``
    }

    if (element.main_invoice.has_pdf) {
      var mainInvoicePDF = `<span class="mc-tooltip pointer">
                              <span class="material-icons md-200 md-success" data-kind="pdf" data-obj-id="${element.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">launch</span>
                              <span class="mc-tooltiptext">Download PDF</span>
                            </span>`
    } else {
      var mainInvoicePDF = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">link_off</span>
                              <span class="mc-tooltiptext">Não há Arquivo</span>
                            </span>`
    }

    if (element.main_invoice.has_xml) {
      if (element.invoice_status == `canceled` || element.invoice_status == `substituted`) {
        var mainInvoiceXML = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">link_off</span>
                              <span class="mc-tooltiptext">Não há Arquivo</span>
                            </span>`  
      } else {
        var mainInvoiceXML = `<span class="mc-tooltip pointer">
                              <span class="material-icons md-200 md-success" data-kind="xml" data-obj-id="${element.main_invoice.id}" data-obj-type="clinic_fin_integration_invoices" data-action="click->${this.controllerName}#goToFile">launch</span>
                              <span class="mc-tooltiptext">Download XML</span>
                            </span>`
      }
    } else {
      var mainInvoiceXML = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">link_off</span>
                              <span class="mc-tooltiptext">Não há Arquivo</span>
                            </span>`
    }

    if (element.legal) {
      var legal = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Pago</span></span>`
    } else {
      var legal = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">Não Pago</span></span>`
    }

    if (element.operational) {
      var operational = `<span class="mc-tooltip badge badge-success"><span class="material-icons md-sm md-white default">done</span><span class="mc-tooltiptext">Pago</span></span>`
    } else {
      var operational = `<span class="mc-tooltip badge badge-danger"><span class="material-icons md-sm md-white default">clear</span><span class="mc-tooltiptext">Não Pago</span></span>`
    }
    
    if (element.receivement_status == `received`) {
      var receivedStatus = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">price_check</span>
                      <span class="mc-tooltiptext">Recebida</span>
                    </span>`
    } else if (element.receivement_status == `not_received`) {
      var receivedStatus = `<span class="mc-tooltip badge badge-danger">
                              <span class="material-icons md-200 md-white pointer" data-action="click->${this.controllerName}#updateStatus">money_off_csred</span>
                              <span class="mc-tooltiptext">Não Recebida</span>
                            </span>`
    }
    
    if (element.integration_job == `waiting`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-primary default">hourglass_empty</span>
                          <span class="mc-tooltiptext">Aguardando Início da Automação</span>
                        </span>`
    } else if (element.integration_job == `in_process`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">autorenew</span>
                          <span class="mc-tooltiptext">Automatização Em Processo</span>
                        </span>`
    } else if (element.integration_job == `finished`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">sync_disabled</span>
                          <span class="mc-tooltiptext">Automatização Finalizada</span>
                        </span>`
    } else if (element.integration_job == `none`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-success default">thumb_up_off_alt</span>
                          <span class="mc-tooltiptext">Emissão Manual</span>
                        </span>`
    } else if (element.integration_job == `error`) {
      var jobService = `<span class="mc-tooltip">
                          <span class="material-icons md-200 md-danger default">sync_problem</span>
                          <span class="mc-tooltiptext">Erro na Emissão</span>
                        </span>`
    }
    
    if (element.invoice_status == `not_transmitted`) {
      var invoice = `<span class="mc-tooltip badge badge-warning">
                      <span class="material-icons md-200 md-white default">portable_wifi_off</span>
                      <span class="mc-tooltiptext">Não Transmitida</span>
                    </span>`
    } else if (element.invoice_status == `transmitted`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">podcasts</span>
                      <span class="mc-tooltiptext">Transmitida</span>
                    </span>`
    } else if (element.invoice_status == `in_process`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">hourglass_bottom</span>
                      <span class="mc-tooltiptext">Em Processo</span>
                    </span>`
    } else if (element.invoice_status == `emitted`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">done_all</span>
                      <span class="mc-tooltiptext">Emitida</span>
                    </span>`
    } else if (element.invoice_status == `not_emitted`) {
      var invoice = `<span class="mc-tooltip badge badge-danger">
                      <span class="material-icons md-200 md-white default">clear</span>
                      <span class="mc-tooltiptext">Não Emitida</span>
                    </span>`
    } else if (element.invoice_status == `uploaded_invoice`) {
      var invoice = `<span class="mc-tooltip badge badge-success">
                      <span class="material-icons md-200 md-white default">done_all</span>
                      <span class="mc-tooltiptext">Emitida Manualmente</span>
                    </span>`
    } else if (element.invoice_status == `canceled`) {
      var receivedStatus = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">clear</span>
                              <span class="mc-tooltiptext">NF Cancelada</span>
                            </span>`
      var invoice = `<span class="mc-tooltip">
                      <span class="material-icons md-200 md-danger default">clear</span>
                      <span class="mc-tooltiptext">NF Cancelada</span>
                    </span>`
    } else if (element.invoice_status == `substituted`) {
      var receivedStatus = `<span class="mc-tooltip">
                              <span class="material-icons md-200 md-danger default">clear</span>
                              <span class="mc-tooltiptext">NF Substituída</span>
                            </span>`
      var invoice = `<span class="mc-tooltip">
                      <span class="material-icons md-200 md-danger default">clear</span>
                      <span class="mc-tooltiptext">NF Substituída</span>
                    </span>`
    }

    if (element.main_invoice.invoice_number_pretty) {
      var invoiceNumber = element.main_invoice.invoice_number_pretty
    } else {
      var invoiceNumber = `S/N`
    }

    if (length == 1) {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-provider-name="${element.provider_name}" data-taker-name="${element.taker_name}" data-amount="${element.total_amount}" data-invoice-number="${element.invoiceNumber}" data-taker-id="${element.taker_id}" data-taker-type="${element.taker_type}" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow" data-id="${element.id}" data-provider-name="${element.provider_name}" data-taker-name="${element.taker_name}" data-amount="${element.total_amount}" data-invoice-number="${element.invoiceNumber}" data-taker-id="${element.taker_id}" data-taker-type="${element.taker_type}">`
    }

    var otherDeductions = Number(element.unconditioned_amount) + Number(element.conditioned_amount) + Number(element.others_amount_withheld)

    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">
                      <span class="mb-0 mc-tooltip pointer">
                        <span>${invoiceNumber}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">Token: ${element.token}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">
                      <span class="mb-0 mc-tooltip pointer">
                        <span>${element.issued_on_pretty}</span>
                        <span class="mc-tooltiptext">${element.issued_on_full_pretty}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showReceivable">${element.taker_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.taker_type_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.total_tax_withheld)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(otherDeductions)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(element.net_amount)}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${receivedStatus}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${invoice}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${jobService}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${mainInvoicePDF}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${mainInvoiceXML}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${destroy}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${replace}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${refact}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.performers.length}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  refreshReceivables() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.bodyTableTarget.innerHTML = this.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(this.tableCols, 7)

    var data = { receivable: { clinic_id: this.application.clinic.id, date_id: this.application.current_date.id }, current_user: { current_user_id: this.application.current_user.id, feature: this.permission } }
    const url = "/operations/products/clinics/financials/receivables/list_by_date"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {

        var currentIds = controller.mapElements(controller.application.all_receivables, `id`)
        var responseIds = controller.mapElements(response.data.cln, `id`)

        response.data.cln.forEach(responseElement => {
          if (currentIds.includes(responseElement.id) && responseIds.includes(responseElement.id)) {
            controller.application.all_receivables.forEach((element, i) => {
              if (element.id == responseElement.id) {
                controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1, responseElement)
              }
            })
          } else if (currentIds.includes(responseElement.id)) {
            controller.application.all_receivables.forEach((element, i) => {
              if (element.id == responseElement.id) {
                controller.application.all_receivables.splice(controller.application.all_receivables.indexOf(element), 1)
              }
            })
          } else if (responseIds.includes(responseElement.id)) {
            controller.application.all_receivables[controller.application.all_receivables.length] = responseElement
          }
        })
        
        controller.dashboardController.setReceivables()

        controller.submenuController.doReceivablesCount()
        controller.doDataTable()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  cancelReceivables() {
    this.cancelInvoices = true
    this.doDataTable()
    this.cancelInvoices = false
  }

  goToFile(ev) {
    this.getControllerByIdentifier(`app--helpers--read-file`).feature_name = this.permission
    this.getControllerByIdentifier(`app--helpers--read-file`).goToFile(ev)
  }

  cleanCheckBox() {
    this.checkboxShowTargets.forEach(element => {
      element.checked = false
    });
    this.checked_id = ``
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    return results
  }

  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
