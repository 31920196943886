import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "dateDropdown", "dateInput", "dateList", "dateDropdownBtn", "datesBtnGroup", "7777", "8888", "9999"]

  connect() {
    this.controllerName = `operations--products--tax-filings--show--dates`
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.doDatesPreloader()
  }

  changeDate(ev) {
    var id = ev.currentTarget.dataset.id
    this.setTaxFilingDate(id)
    this.toggleDateBtn(id)
  }

  setTaxFilingDate(id) {
    var findElement = false
    this.application.dates.forEach(element => {
      if (element.id == id) {
        findElement = true
        this.application.current_date = element
        if (this.application.current_date.open == false) {
          this.application.current_date.message = `A Competência está fechada`
        }
      }
    });

    if (findElement == false) {
      this.application.current_date = { open: false, not_defined: true, message: `A Competência está fechada` }
    }

    this.getControllerByIdentifier("operations--products--tax-filings--show--main").reload = true
    this.getControllerByIdentifier("operations--products--tax-filings--show--main").doSubtitle()
    this.getControllerByIdentifier("operations--products--tax-filings--show--main").loadDateData()
  }

  setCurrents() {
    this.application.agents.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_agent = element
      }
    })
    
    this.application.tax_filing_calculations.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_tax_filing_calculation = element
      }
    })
    
    this.application.summaries.forEach(element => {
      if (element.date_id == this.application.current_date.id) {
        this.application.current_summary = element
      }
    })
  }

  runDashboardsHtml() {
    this.getControllerByIdentifier("operations--products--tax-filings--show--submenu").doBadgeCounts()
    this.runDashboardHtml()
    this.runTicketsHtml()
    this.runNotesHtml()
    this.runUploadsHtml()
    this.runDocumentsHtml()
    this.runProcessHtml()
    this.runJourneysHtml()
    this.runSettingsHtml()
    this.runSummariesHtml()
    this.runLogsHtml()
    this.runEmailsHtml()
  }

  runDashboardHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--show--dashboard").reconnect()
    }
  }

  runTicketsHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--tickets--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--tickets--dashboard").doTicketsDashboard()
    }
  }

  runNotesHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--notes--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--notes--dashboard").doNotesDashboard()
    }
  }

  runUploadsHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--uploads--dashboard").doUploadsDashboard()
    }
  }

  runDocumentsHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").doDocumentsDashboard()
    }
  }

  runProcessHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--process--dashboard").doProcessDashboard()
    }
  }

  runJourneysHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--journey--dashboard").doJourneyDashboard()
    }
  }

  runSettingsHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--settings--dashboard").doSettingsDashboard()
    }
  }

  runLogsHtml() {
    if (this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").noLogsMessage = `Não há Logs para a Declaração do Ano-Exercício ${this.application.current_date.financial_year}`
      this.getControllerByIdentifier("app--stats--trackers--logs--dashboard").doLogsDashboard()
    }
  }

  runEmailsHtml() {
    if (this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("app--stats--trackers--emails--dashboard").doEmailsDashboard()
    }
  }

  runSummariesHtml() {
    if (this.getControllerByIdentifier("operations--products--tax-filings--summaries--dashboard").hasMainDashboardTarget) {
      this.getControllerByIdentifier("operations--products--tax-filings--summaries--dashboard").doSummariesDashboard()
    }
  }

  doDatesHTML() {

    var datesHtml = ``

    this.application.dates.forEach(element => {
      datesHtml += `<button type="button" class="btn btn-flat-dark btn-no-border btn-sm w-100 px-1 mx-1 f-065" data-action="${this.controllerName}#changeDate" data-id="${element.id}" data-${this.controllerName}-target="btn-${element.id}">${element.financial_year}</button>`
    });

    var html = `<div class="btn-group" role="group" style="box-shadow:none" data-${this.controllerName}-target="datesBtnGroup">
                  ${datesHtml}
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.mainTarget.innerHTML = html)
    }).then(() => {
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.remove(`btn-flat-dark`)
      controller.nameTarget(`btn-${controller.application.dates[0].id}`).classList.add(`btn-primary`)
      // controller.rollDates()
    })
  }

  rollDates() {
    // $(this.datesBtnGroupTarget).stop().animate({ scrollRight: $(this.datesBtnGroupTarget)[0].style.width }, 1000);
    $(this.datesBtnGroupTarget).animate($(this.datesBtnGroupTarget)[0].scrollLeft -= 100, 1000);
    // $(this.datesBtnGroupTarget).stop().animate({ scrollTop: $(this.datesBtnGroupTarget)[0].scrollHeight }, 1000);
  }

  toggleDateBtn(id) {
    this.application.dates.forEach(element => {
      if (element.id == id) {
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-primary`)
      } else {
        this.nameTarget(`btn-${element.id}`).classList.add(`btn-flat-dark`)
        this.nameTarget(`btn-${element.id}`).classList.remove(`btn-primary`)
      }
    });
  }

  doDatesPreloader() {
    var html = `<div class="row mb-3 w-100">
                  <div class="col-2 px-1 offset-2">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                  <div class="col-2 px-1">${this.loader}</div>
                </div>`

    this.mainTarget.innerHTML = html
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

}
