import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["visitInput", "contentRow", "search", "searchCard", "visitName", "email", "crmState", "utmSource", "list",
                    "utmMedium", "utmCampaign", "utmContent", "device", "crm", "landingPage", "lastInteractionAt", "phone",
                    "visitorCard", "visitorBodyTable", "duration", "os", "browser", "referrer", "totalVisitor", "footerTable",
                    "pageviewsBodyTable", "pageviewsBodyTable", "pageviewsTitleTable"]


  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `commercial--marketing--visits--pageviews--events`
    this.itemsOnPage = 100
    this.pageNumber = 0
    this.numberPages = []
    this.sort = { mode: `desc`, field: `last_visit_at_to_time` }
    this.send_data = { current_user: {}, visit: {} }
    this.getVisitors()
  }

  doEventGrid() {
    var html = `<div class="row my-4">
                  <div class="col-5 px-2" data-${this.controllerName}-target="list"></div>
                  <div class="col-7 px-2" data-${this.controllerName}-target="search"></div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("commercial--marketing--visits--pageviews--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.doVisitorHtml()
      controller.doPageviewCard()
    })
  }

  showEvents(ev) {
    var visitorId = ev.currentTarget.closest(".itemRow").dataset.id

    this.application.visitors.forEach(element => {
      if (element.id == visitorId) {
        this.current_visitor = element
      }
    })

    this.send_data = { pageview: {}, current_user: {} }

    this.send_data.pageview.visitor_id = this.current_visitor.id
    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.requestPageviews()
  }

  requestPageviews() {
    const url = "/commercial/marketing/visits/pageviews/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.process) {
          this.current_visitor.pageviews = response.data.cln
          controller.doPageviewDataTable()
        } else {
          controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doVisitorDataTable()
  }

  doVisitorHtml() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="visitorCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title mb-0 py-3 f-075">Todos os <span data-${this.controllerName}-target="totalVisitor"></span> Visitantes</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer">
                                  ID
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="id" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="id" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="visitor_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="visitor_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-15 align-middle pointer">
                                  Última Visita
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="last_visit_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="last_visit_at_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle">Lead</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="visitorBodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.visitorCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.visitorBodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier(`app--helpers--table`).doTablePreloader(4, 15))
      controller.totalVisitorTarget.innerText = controller.application.visitors.length
      controller.doVisitorDataTable()
    })
  }

  doVisitorDataTable() {
    if (this.sort.mode == `asc`) {
      var visitors = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.visitors, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var visitors = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.visitors, this.sort.field)
    }

    if (visitors.length == 0) {
      this.listVisitorData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(visitors.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (visitors[x] !== undefined) {
            internPage[internPage.length] = visitors[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: visitors.length }
      this.listVisitorData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listVisitorData(data) {
    this.visitorBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="4" class="p-5 align-middle text-center" style="font-size:200%;">
                      <span class="fa-stack fa-2x">
                        <i class="fas fa-list fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <h5>Não existem Visitas</h5>
                    </td>`

      this.visitorBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.visitorBodyTableTarget.insertAdjacentHTML("beforeend", this.visitorTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  visitorTablePartial(element) {

    if (element.visitor_name == undefined || element.visitor_name == null) {
      element.visitor_name = `Não Fornecido`
    }

    if (element.converted) {
      var convertedPretty = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-success pointer">done</span>
                            <span class="mc-tooltiptext">Convertido</span>
                          </span>`
    } else {
      var convertedPretty = `<span class="mc-tooltip">
                            <span class="material-icons md-sm md-danger pointer">clear</span>
                            <span class="mc-tooltiptext">Não Convertido</span>
                          </span>`
    }

    var rowHtml = `<tr class="itemRow" data-id="${element.id}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showEvents">${element.id}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#showEvents">${element.visitor_name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.last_visit_at_date_pretty} | ${element.last_visit_at_time_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${convertedPretty}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doVisitorDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doVisitorDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doVisitorDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="card-actions align-items-center justify-content-end py-0 f-065">
                        <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                        <div class="dropdown">
                          <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${pagination.itemsOnPage}</button>
                          <div class="dropdown-menu dropdown-menu-right menu">
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="100">100</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="250">250</span>
                            <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="500">500</span>
                          </div>
                        </div>
                        <span class="align-self-center mb-1 mr-2 text-muted">${pagination.fromItem}-${pagination.toItem} de ${pagination.totalItems}</span>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                        <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }


  doPageviewCard() {
    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="searchCard">
                  <div class="card-header d-flex align-items-center card-header-table-list">
                    <h6 class="card-title mb-0 py-3 f-075" data-${this.controllerName}-target="pageviewsTitleTable">Pageview/Eventos</h6>
                    <input class="form-control f-070 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" placeholder="Buscar ..." type="text">
                  </div>
                  <div class="card-body" style="overflow:scroll;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" data-app--helpers--search-target="searchTable" style="font-size:80%;">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer">Horário</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer">Caminho Chegada</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer">Referênica</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-5 align-middle pointer">Campanha</th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="pageviewsBodyTable"></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.searchTarget.innerHTML = html)
    }).then(() => {
      controller.searchCardTarget.style.height = ($(window).height() * 0.65) + "px"
    })
  }

  doPageviewDataTable() {
    var events = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.current_visitor.pageviews, `time_to_time`)

    this.pageviewsTitleTableTarget.innerText = `Pageview/Eventos: ${this.current_visitor.id}`
    this.listPageviewData(events)
  }

  listPageviewData(data) {
    this.pageviewsBodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `<td colspan="4" class="p-5 align-middle text-center" style="font-size:200%;">
                      <span class="fa-stack fa-2x">
                        <i class="fas fa-list fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:#EFEFEF;"></i>
                      </span>
                      <h5>Não existem Eventos</h5>
                    </td>`

      this.pageviewsBodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.pageviewsBodyTableTarget.insertAdjacentHTML("beforeend", this.pageviewTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  pageviewTablePartial(element) {

    if (element.utm_campaign) {
      var campaign = element.utm_campaign
    } else {
      var campaign = ``
    }

    if (element.referrer) {
      var referrer = element.referrer
    } else {
      var referrer = ``
    }

    var rowHtml = `<tr class="itemRow" data-id="${element.visit_id}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.time_date_pretty} | ${element.time_time_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.path}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${referrer}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${campaign}</td>
                  </tr>`

    return rowHtml
  }

  getVisitors() {
    this.send_data.visit.active = true
    this.send_data.current_user.current_user_id = this.application.current_user.id

    const url = "/commercial/marketing/visits/visitors/list"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.visitors = response.data.cln
      })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)

    // new Promise(function (resolve) {
    //   resolve()
    // }).then(() => {
    // }) 
  }

}
