import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "sidebar", "pipeflow", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu",
                    "content", "pipelineTable", "5555", "6666", "7777", "8888", "9999",
                    "medclinicHeaderCard", "medbookingHeaderCard", "medfilingHeaderCard",
                    "medconsultingHeaderCard", "medreturnHeaderCard", "medoncallHeaderCard", "medoutsourcingHeaderCard",
                    "medloanHeaderCard", "medcontentHeaderCard"]

  connect() {
    this.controllerName = `operations--onboards--reports--pipeline`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    // this.setProductStageArray()

  }

  doPipelineReportHTML() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row my-3">
                  <div class="col-2 px-2" data-${this.controllerName}-target="sidebar">
                    <div class="row">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                    <div class="row my-4">
                      <div class="col-12 px-0">
                        ${loader}
                      </div>
                    </div>
                  </div>
                  <div class="col-10 px-2">
                    <div class="row mb-4" data-${this.controllerName}-target="pipeflow">
                      <div class="col-2 px-0 mx-3">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3">
                        ${loader}
                      </div>
                      <div class="col-2 px-0 mx-3">
                        ${loader}
                      </div>
                      <div class="col-12 px-0 mx-2 mt-5 text-center">
                        Escolha o Produto e o Tipo de Serviço para gerar o relatório
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.pipelineTableTarget.innerHTML = html)
      // resolve(controller.getControllerByIdentifier("operations--onboards--reports--main").purchasesTableTarget.innerHTML = html)
    }).then(() => {
      controller.doSidebarHtml()
      // if (controller.application.purchase_report) {
      //   controller.doIndexListHtml()
      //   controller.doCalculation()
      // }
    })
  }

  doSidebarHtml() {
    this.sidebarTarget.innerHTML = this.getControllerByIdentifier(`operations--onboards--reports--main`).doSidebarHtml(this.controllerName)
  }

  productFilter(ev) {
    this.product_name = ev.currentTarget.dataset.product

    this.application.product_main.forEach(product => {
      if (this.product_name == product.name) {
        this.product_name_pretty = product.name_pretty
        this.product_services = product.services
      }
    });

    var servicesHtml = ``
    this.product_services.forEach(service => {
      servicesHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="card pointer" data-service="${service.name}" data-action="click->${this.controllerName}#productPipeline" data-${this.controllerName}-target="${this.product_name}-${service.name}-Card">
                            <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="${this.product_name}-${service.name}-HeaderCard">
                              <span>${service.name_pretty}</span>
                            </div>
                          </div>
                        </div>
                      </div>`
    });

    servicesHtml += `<div class="row my-4">
                        <div class="col-12 px-0">
                          <div class="pointer bg-outline-primary" data-action="click->${this.controllerName}#cancelProduct">
                            <div class="card-header p-1 text-center f-065 f-bold">
                              <span>Voltar</span>
                            </div>
                          </div>
                        </div>
                      </div>`


    var html = `<div class="row">
                  <div class="col-12 px-0">
                    <div class="card" data-${this.controllerName}-target="medclinicCard">
                      <div class="card-header p-1 text-center f-065 f-bold bg-primary" data-${this.controllerName}-target="medclinicHeaderCard">
                        <span>${this.product_name_pretty}</span>
                      </div>
                    </div>
                  </div>
                </div>
                ${servicesHtml}`

    this.sidebarTarget.innerHTML = html

  }

  cancelProduct() {
    this.doSidebarHtml()
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="col-2 px-0 mx-3">
                  ${loader}
                </div>
                <div class="col-2 px-0 mx-3">
                  ${loader}
                </div>
                <div class="col-2 px-0 mx-3">
                  ${loader}
                </div>
                <div class="col-2 px-0 mx-3">
                  ${loader}
                </div>
                <div class="col-2 px-0 mx-3">
                  ${loader}
                </div>
                <div class="col-12 px-0 mx-2 mt-5 text-center">
                  Escolha o Produto e o Tipo de Serviço para gerar o relatório
                </div>`

    this.pipeflowTarget.innerHTML = html
  }

  productPipeline(ev) {
    this.service_name = ev.currentTarget.dataset.service

    this.product_services.forEach(service => {
      if (service.name == this.service_name) {
        this.service = service
        this.nameTarget(`${this.product_name}-${this.service_name}-Card`).classList.add("bg-primary")
      } else {
        this.nameTarget(`${this.product_name}-${service.name}-Card`).classList.remove("bg-primary")
      }
    });

    var html = ``
    this.service.stages.forEach(stage => {
      html += `<div class="col-2 px-0 mx-3" data-${this.controllerName}-target="${this.product_name}-${this.service_name}-${stage.name}-Col">
                <div class="card pointer" data-stage="${stage.name}" data-action="click->${this.controllerName}#stagePipeline" data-${this.controllerName}-target="${this.product_name}-${this.service_name}-${stage.name}-Card">
                  <div class="card-header p-1 text-center f-065 f-bold" data-${this.controllerName}-target="${this.product_name}-${this.service_name}-${stage.name}-HeaderCard">
                    <span>${stage.name_pretty}</span>
                  </div>
                </div>
              </div>`
    });
    
    this.pipeflowTarget.innerHTML = html
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.pipeflowTarget.innerHTML = html)
    }).then(() => {
      controller.doDataFlow()
    })
  }

  doDataFlow() {

    this.application.onboards.forEach(onboard => {
      if (onboard.product_name == `medfiling`) {
        if (onboard.product_service == `separate` || onboard.product_service == `rectification`) {
          if (onboard.product_name == this.product_name && onboard.product_service == this.service_name) {
            this.doSimpleDataFlow(onboard)
          }
        } else {
          if (onboard.product_name == this.product_name && onboard.product_plan == this.service_name) {
            this.doSimpleDataFlow(onboard)
          }
        }
      } else {
        if (onboard.product_name == this.product_name && onboard.product_service == this.service_name) {
          this.doSimpleDataFlow(onboard)
        }
      }
      
    })
    
  }

  doSimpleDataFlow(element) {
    element.name = ``
    element.parties.forEach(party => {
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="onboardPartyName">${party.party_name}</p>`
    })

    var html = `<div class="card pointer mt-4" data-action="click->${this.controllerName}#goToShowPage" data-slug="${element.slug}" data-${this.controllerName}-target="${this.product_name}-OnboardCard-${element.id}">
                  <div class="card-header p-1 text-center f-065 f-bold">
                    <span>${element.name}</span>
                  </div>
                </div>`

    this.service.stages.forEach(stage => {
      if (stage.name == element.journey_status) {
        this.nameTarget(`${this.product_name}-${this.service_name}-${stage.name}-Col`).insertAdjacentHTML("beforeend", html)
      }
    });

  }

  goToShowPage(ev) {
    var token = ev.currentTarget.closest(".card").dataset.slug
    window.open(`/a/onboards/${token}`, `_blank`)
  }

  stagePipeline() {

  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
