import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "fromInput", "toInput", "saveBtn",
                    "medDropdown", "medDropdownBtn", "medInput", "medList", "providerInput", "providerFilter",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "dueDate", "amountInput",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput", "bankLineInput",
                    "bankLineDiv", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKeyInput", "accrualDate",
                    "recurringCheckbox", "variableCheckbox", "deleteBtn", "cardDiv",
                    "totalAmountInput", "splitInput", "amountSplitInput", "installmentInput",
                    "subkindCard", "subkind", "amountSplitInput", "fromModal",
                    "providerModal", "medModal", "channelModal", "toModal"]


  connect() {
    this.controllerName = `financials--books--statements--transactions--save`
  }

  disconnect() {
    this.stopRefreshing()
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("financials--books--statements--transactions--index").doIndexListHtml()
  }

  saveTransaction() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    
    this.send_data = { current_user: {}, transaction: {} }
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_transactions`

    if (this.actionMode == `edit`) {

      this.send_data.transaction.id = this.current_transaction.id
      this.send_data.transaction.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
      this.send_data.transaction.description = this.descriptionInputTarget.value.trim()

    } else if (this.actionMode == `new` || this.actionMode == `copy`) {
      var fromChart = {}
      var toChart = {}
      
      this.application.chart_accounts.forEach(chart => {
        if (chart.id == this.fromInputTarget.dataset.filter) {
          fromChart = chart
        }
        if (chart.id == this.toInputTarget.dataset.filter) {
          toChart = chart
        }
      })
      
      var amount = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value))
      var fromAmount = 0
      var toAmount = 0
      
      if (fromChart.master == `liability` || fromChart.master == `revenues` || fromChart.master == `result`) {
        fromAmount = Number(amount)
      } else {
        fromAmount = -Number(amount)
      }
      
      if (toChart.master == `asset` || toChart.master == `expenses` || toChart.master == `taxes`) {
        toAmount = Number(amount)
      } else {
        toAmount = -Number(amount)
      }
      
      var transactionKind = ``
      
      if (fromChart.name == `Ajuste de Contas` || toChart.name == `Ajuste de Contas`) {
        transactionKind = `adjust`
      } else {
        if (fromChart.kind == `statement` || toChart.kind == `statement`) {
          transactionKind = `statement`
        } else {
          transactionKind = `balance`
        }
      }
      
      this.send_data.transaction.date_id = this.application.current_date.id
      // this.send_data.transaction.med_id = this.current_med.id
      this.send_data.transaction.med_id = this.medInputTarget.dataset.medId
      this.send_data.transaction.channel_id = this.channelInputTarget.dataset.channelId
      this.send_data.transaction.channel_name = this.channelInputTarget.value
      this.send_data.transaction.date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
      this.send_data.transaction.description = this.descriptionInputTarget.value.trim()
      this.send_data.transaction.method = this.methodInputTarget.dataset.method
      this.send_data.transaction.provider_id = this.providerInputTarget.dataset.providerId
      this.send_data.transaction.provider_name = this.providerInputTarget.value
      this.send_data.transaction.from_id = fromChart.id
      this.send_data.transaction.from_master_name = fromChart.master_name
      this.send_data.transaction.from_group = fromChart.group
      this.send_data.transaction.from_master_group = fromChart.master_group
      this.send_data.transaction.from_master = fromChart.master
      this.send_data.transaction.to_id = toChart.id
      this.send_data.transaction.to_master_name = toChart.master_name
      this.send_data.transaction.to_group = toChart.group
      this.send_data.transaction.to_master_group = toChart.master_group
      this.send_data.transaction.to_master = toChart.master
      this.send_data.transaction.amount = amount
      this.send_data.transaction.from_amount = fromAmount
      this.send_data.transaction.to_amount = toAmount
      this.send_data.transaction.kind = transactionKind
      this.send_data.transaction.source = `direct`
    }

    this.requestSave()
  }

  requestSave() {
    if (this.actionMode == `new` || this.actionMode == `copy`) {
      var url = "/financials/books/statements/transactions/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/statements/transactions/update"
      var method = "PUT"
    }

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var transaction = response.data.cln

          if (controller.actionMode == `new` || controller.actionMode == `copy`) {
            controller.application.transactions.forEach(element => {
              if (element.date_id == controller.application.current_date.id) {
                element.data[element.data.length] = transaction
              }
            })
          } else if (controller.actionMode == `edit`) {
            controller.application.transactions.forEach(array => {
              if (array.date_id == controller.application.current_date.id) {
                array.data.forEach(element => {
                  if (element.id == transaction.id) {
                    array.data.splice(array.data.indexOf(element), 1, transaction)
                  }
                })
              }
            })
          }

          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_statement_transactions.can_create) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveTransaction" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-8 offset-2">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0"></div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="accrualDateForm">Lançamento</label>
                                <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="amountForm">Valor</label>
                                <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-0">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="descriptionForm">Descrição</label>
                                <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-center px-0">
                            <button data-action="click->${this.controllerName}#showProviders" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                            </button>
                          </div>
                          <div class="col-3 pl-0">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Fornecedor</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showMeds" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                            </button>
                          </div>
                          <div class="col-2">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medForm">MedCapital</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-center px-0">
                            <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                            </button>
                          </div>
                          <div class="col-4 px-0">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="channelForm">Canal de Recebimento/Pagamento</label>
                                <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label>Método</label>
                                <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                                  <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                                  <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                    <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                    <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 d-flex align-items-center">
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="fromInput" data-${this.controllerName}-target="fromModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Origem</label>
                                <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="fromInput" type="text" placeholder="Origem" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 text-right px-0">
                            <button data-action="click->${this.controllerName}#showChartAccounts" data-element="toInput" data-${this.controllerName}-target="toModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">help_center</span>
                              <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                            </button>
                          </div>
                          <div class="col-5">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="nameForm">Destino</label>
                                <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="toInput" type="text" placeholder="Destino" required>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--transactions--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.6) + "px"

      var date = new Date(this.application.current_date.year, this.application.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)

      if (controller.actionMode == `edit` || controller.actionMode == `copy`) {
        if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Transação Financeira`

          controller.amountInputTarget.disabled = true
          controller.providerModalTarget.disabled = true
          controller.medModalTarget.disabled = true
          controller.channelModalTarget.disabled = true
          controller.methodDropdownBtnTarget.disabled = true
          controller.fromModalTarget.disabled = true
          controller.toModalTarget.disabled = true
        } else if (controller.actionMode == `copy`) {
          controller.formTitleTarget.innerText = `Nova Transação Financeira`

          if (controller.application.current_med.id) {
            controller.current_med = controller.application.current_med
            controller.medInputTarget.dataset.medId = controller.application.current_med.id
            controller.medInputTarget.value = controller.application.current_med.name
          }
        }

        controller.accrualDateTarget.value = controller.current_transaction.date_pretty
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_transaction.amount)
        controller.descriptionInputTarget.value = controller.current_transaction.description
        controller.providerInputTarget.value = controller.current_transaction.provider_name
        controller.providerInputTarget.dataset.providerId = controller.current_transaction.provider_id
        controller.medInputTarget.value = controller.current_transaction.med_name
        controller.medInputTarget.dataset.medId = controller.current_transaction.med_id
        controller.channelInputTarget.value = controller.current_transaction.channel_name
        controller.channelInputTarget.dataset.channelId = controller.current_transaction.channel_id
        controller.methodDropdownTarget.value = controller.current_transaction.method
        controller.methodInputTarget.dataset.method = controller.current_transaction.method
        controller.methodInputTarget.innerText = controller.current_transaction.method_pretty
        controller.fromInputTarget.value = controller.current_transaction.from_name_pretty
        controller.toInputTarget.value = controller.current_transaction.to_name_pretty
        controller.fromInputTarget.dataset.filter = controller.current_transaction.from_id
        controller.toInputTarget.dataset.filter = controller.current_transaction.to_id

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Transação Financeira`
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        if (controller.application.current_med.id) {
          controller.current_med = controller.application.current_med
          controller.medInputTarget.dataset.medId = controller.application.current_med.id
          controller.medInputTarget.value = controller.application.current_med.name
        }
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (channel.kind == `bank_account`) {
      methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
      methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
      methodList += `<li data-method="provision" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`
      methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`
      methodList += `<li data-method="bank_credit" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Crédito em Conta</li>`

      this.methodDropdownTarget.value = ``
      this.methodInputTarget.innerText = ``
      this.methodInputTarget.dataset.method = ``
    } else if (channel.kind == `credit_card`) {
      methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Cartão de Crédito`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    } else if (channel.kind == `cash`) {
      methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`
      methodList += `<li data-method="provisão" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`

      this.methodDropdownTarget.value = channel.kind
      this.methodInputTarget.innerText = `Dinheiro`
      this.methodInputTarget.dataset.method = channel.kind
      this.methodDropdownBtnTarget.disabled = true
    } else if (channel.kind == `payment`) {
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
      methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
      methodList += `<li data-method="provision" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Provisão</li>`

      this.methodDropdownTarget.value = ``
      this.methodInputTarget.innerText = ``
      this.methodInputTarget.dataset.method = ``
    }

    this.methodListTarget.innerHTML = methodList
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = []
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_statement_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  showProviders() {
    var html = `<div class="modal fade" data-controller="financials--books--dashboards--providers--modal" data-financials--books--dashboards--providers--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--providers--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).feature = `financial_statement_providers`
      controller.getControllerByIdentifier(`financials--books--dashboards--providers--modal`).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = false
      controller.getControllerByIdentifier(modalName).permission = `financial_settings_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.hasFormCardTarget) {
        if (controller.accrualDateTarget.value == ``) {
          len += 1
        }
        
        if (controller.amountInputTarget.value == `R$ 0,00`) {
          len += 1
        }
        
        if (controller.descriptionInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.providerInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.methodInputTarget.innerText == ``) {
          len += 1
        }
        
        if (controller.medInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.fromInputTarget.value == ``) {
          len += 1
        }
        
        if (controller.toInputTarget.value == ``) {
          len += 1
        }
        
        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
